import React from 'react'
import ClearyCard from 'components/common/card'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_assistant.dashboard.left_sidebar')

const Greeting = ({ currentUser, companyLogo }) => (
  <ClearyCard>
    <div className='text-center'>
      <EmployeeThumbnailPhoto employee={currentUser} />
      <h3 className='mt-2'>
        {I18N('greeting')}<br />
        {companyLogo && <img src={companyLogo} className='company-logo mr-2' />}
        {currentUser.preferredFullName}
      </h3>
    </div>
  </ClearyCard>
)

export default Greeting
