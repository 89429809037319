import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import UserCard from 'components/home/widgets/onboarding_buddies_widget/userCard'
import SelectBuddyButton from 'components/home/widgets/onboarding_buddies_widget/selectBuddyButton'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import ReactSelect from 'components/common/react_select'
import PaginationContainer from 'components/common/tables/paginationContainer'
import { useCityTargetingOptions } from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.journeys.onboarding_buddies')

const OtherBuddiesList = ({ ignoredUserIds = [], buddiesPerPage, newHireId }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCity, setSelectedCity] = useState(null)

  const onBuddySelected = () => history.push(newHireId ? '/admin/onboarding_buddies' : '/')

  const { isLoading, queryParams } = useSelector(onboardingBuddySlice.selectors.getMetaData())
  const {
    page, totalPages, total,
  } = queryParams
  const onboardingBuddies = useSelector(onboardingBuddySlice.selectors.getOnboardingBuddies())

  const cityTargetingOptions = useCityTargetingOptions()
  const cityOptions = [{ value: null, label: I18NCommon('all') }, ...cityTargetingOptions]

  const getParams = (newParams) => {
    const params = {
      ignoredUserIds,
      q: searchQuery,
      ...queryParams,
      perPage: buddiesPerPage,
    }

    if (selectedCity) {
      params.filters = { city: selectedCity }
    }

    if (newHireId) {
      params.newHireId = newHireId
    }

    return { ...params, ...newParams }
  }

  const fetchBuddies = params => dispatch(onboardingBuddySlice.asyncActions.fetchAll(getParams(params)))

  const handlePaginationClick = (page) => {
    fetchBuddies({ page })
  }

  useEffect(() => {
    fetchBuddies({ page: 1 })
  }, [searchQuery, selectedCity, newHireId])

  return (
    <section className='OtherBuddiesList'>
      <AsyncSearchInput
        className='mb-0'
        placeholder={I18NCommon('search_by_name')}
        onKeyUp={setSearchQuery}
      />
      <div className='CitySelect d-flex align-items-center'>
        <span className='text-secondary mr-2'>{I18N('city')}</span>
        <ReactSelect
          className=''
          options={cityOptions}
          value={cityOptions.find(city => city.value === selectedCity)}
          onChange={option => setSelectedCity(option.value)}
        />
      </div>
      {isLoading ? (
        <CirclesLoadingIndicator className='mt-5' />
      ) : (
        onboardingBuddies.map(buddy => (
          <UserCard
            key={`other-buddy-${buddy.id}`}
            user={buddy}
            includeStartDate
          >
            <SelectBuddyButton
              className='mt-2 px-2'
              buddy={buddy}
              onSuccess={onBuddySelected}
              newHireId={newHireId}
            />
          </UserCard>
        ))
      )}
      <PaginationContainer
        page={page}
        numPages={totalPages}
        perPage={buddiesPerPage}
        totalCount={total}
        handleClick={handlePaginationClick}
      />
    </section>
  )
}

export default OtherBuddiesList
