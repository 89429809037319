import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import appSignal from 'services/appSignal'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { present } from 'components/common/utils'

const I18N = i18nPath('views.admin.user_skills')

export const initialState = {
  meta: {
    ...defaultMeta,
    queryParams: { ...defaultPaginationParams, perPage: 20 },
    isDeleting: false,
    isSavingUserSkillTaggings: '',
    isLoadingUserSkill: false,
    isLoadingUsers: false,
    userQueryParams: { ...defaultPaginationParams, perPage: 12 }, // selecting 12 because it's divisible by 2, 3, and 4
  },
  userSkillIds: [],
  userIds: [],
}

const userSkillSlice = createSlice({
  name: 'userSkills',
  initialState,
  reducers: {
    ...defaultActions,
    isDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },
    isSavingUserSkillTaggings(state, action) {
      state.meta.isSavingUserSkillTaggings = action.payload
    },
    setIsLoadingUserSkill(state, action) {
      state.meta.isLoadingUserSkill = action.payload
    },
    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },
    setUserSkillIds(state, action) {
      state.userSkillIds = action.payload
    },
    resetUserSkillIds(state, action) {
      state.userSkillIds = []
    },
    isLoadingUsers(state, action) {
      state.meta.isLoadingUsers = action.payload
    },
    setUserQueryParams(state, action) {
      state.meta.userQueryParams = action.payload
    },
    addUserIds(state, action) {
      state.userIds = _.uniq([...state.userIds, ...action.payload])
    },
    reset() {
      return initialState
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(userSkillSlice, {
  asyncActions: {
    admin: {
      createTagging: (userSkill, user) => async (dispatch) => {
        const tagging = {
          userSkillTagging: {
            userSkill: { id: userSkill.id },
            user: { id: user.id },
          },
        }

        try {
          const response = await API.admin.userSkillTaggings.createTagging(tagging)
          dispatch(entitySlice.actions.add({
            data: response.data,
            reverseRelationships: [{ entityId: user.id, entityType: 'user', relationshipName: 'userSkillTaggings' }],
          }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(userSkillSlice.actions.setError('Failed to create tagging'))
        }
      },
      destroyTagging: (userSkill, user) => async (dispatch) => {
        const tagging = {
          userSkillTagging: {
            userSkill: { id: userSkill.id },
            user: { id: user.id },
          },
        }

        try {
          const response = await API.admin.userSkillTaggings.destroyTagging(tagging)
          const taggingId = response?.data?.data?.id
          if (taggingId) {
            dispatch(entitySlice.actions.remove({ id: taggingId, type: 'userSkillTagging' }))
          }
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(userSkillSlice.actions.setError('Failed to destroy tagging'))
        }
      },
      listAll: queryParams => async (dispatch) => {
        dispatch(userSkillSlice.actions.isLoading(true))

        try {
          const response = await API.admin.userSkills.fetchAll({ ...queryParams })
          const newQueryParams = queryParamsFromHeaders(response)
          const userSkillIds = response.data.data.map(userSkill => userSkill.id)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(userSkillSlice.actions.setQueryParams(newQueryParams))
          dispatch(userSkillSlice.actions.setUserSkillIds(userSkillIds))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(userSkillSlice.actions.setError('Failed to list user skills'))
        } finally {
          dispatch(userSkillSlice.actions.isLoading(false))
        }
      },

      createUserSkill: (userSkill, history) => async (dispatch) => {
        dispatch(userSkillSlice.actions.setError(null))
        dispatch(userSkillSlice.actions.isSaving(true))

        try {
          const response = await API.admin.userSkills.create(userSkill)
          dispatch(entitySlice.actions.add({ data: response.data }))

          history.push('/admin/user_skills/')
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(getResponseOrThrow(e))

          dispatch(userSkillSlice.actions.setError(error))
        } finally {
          dispatch(userSkillSlice.actions.isSaving(false))
        }
      },

      updateUserSkill: userSkill => async (dispatch) => {
        dispatch(userSkillSlice.actions.setError(null))
        dispatch(userSkillSlice.actions.isSaving(true))

        try {
          const response = await API.admin.userSkills.update(userSkill)
          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(getResponseOrThrow(e))

          dispatch(userSkillSlice.actions.setError(error))
        } finally {
          dispatch(userSkillSlice.actions.isSaving(false))
        }
      },

      deleteUserSkill: userSkill => async (dispatch) => {
        try {
          await API.admin.userSkills.destroy(userSkill)
          dispatch(entitySlice.actions.remove({ type: 'userSkill', id: userSkill.id }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        }
      },

      fetchUserSkill: userSkillId => async (dispatch) => {
        dispatch(userSkillSlice.actions.isLoading(true))

        try {
          const response = await API.admin.userSkills.fetch(userSkillId)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(userSkillSlice.actions.isLoading(false))
        }
      },
    },
    createTagging: (userSkill, user) => async (dispatch) => {
      const tagging = {
        userSkillTagging: {
          userSkill,
        },
      }

      try {
        const response = await API.userSkills.createTagging(tagging)
        dispatch(entitySlice.actions.add({
          data: response.data,
          reverseRelationships: [
            { entityId: user.id, entityType: 'user', relationshipName: 'userSkillTaggings' },
          ],
        }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(userSkillSlice.actions.setError('Failed to create tagging'))
      }
    },
    destroyTagging: userSkill => async (dispatch) => {
      const tagging = {
        userSkillTagging: {
          userSkill,
        },
      }

      try {
        const response = await API.userSkills.destroyTagging(tagging)
        const taggingId = response?.data?.data?.id
        if (taggingId) {
          dispatch(entitySlice.actions.remove({ id: taggingId, type: 'userSkillTagging' }))
        }
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(userSkillSlice.actions.setError('Failed to destroy tagging'))
      }
    },
    updateTaggings: (skillsToAdd, skillsToRemove, type, user) => async (dispatch) => {
      dispatch(userSkillSlice.actions.isSavingUserSkillTaggings(type))

      try {
        const addDispatches = skillsToAdd.map(skill => dispatch(userSkillSlice.asyncActions.createTagging(skill, user)))
        const removeDispatches = skillsToRemove.map(
          skill => dispatch(userSkillSlice.asyncActions.destroyTagging(skill))
        )

        await Promise.all([...addDispatches, ...removeDispatches])
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(userSkillSlice.actions.setError('Failed to update tagging'))
      } finally {
        dispatch(userSkillSlice.actions.isSavingUserSkillTaggings(''))
      }
    },
    listAll: () => async (dispatch) => {
      dispatch(userSkillSlice.actions.isLoading(true))

      try {
        const response = await API.userSkills.fetchAll()

        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(userSkillSlice.actions.setError('Failed to list user skills'))
      } finally {
        dispatch(userSkillSlice.actions.isLoading(false))
      }
    },
    fetchUserSkill: userSkillId => async (dispatch) => {
      dispatch(userSkillSlice.actions.setIsLoadingUserSkill(true))

      try {
        const response = await API.userSkills.fetch(userSkillId)

        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userSkillSlice.actions.setIsLoadingUserSkill(false))
      }
    },
    fetchUsers: (userSkillId, queryParams) => async (dispatch) => {
      dispatch(userSkillSlice.actions.isLoadingUsers(true))

      try {
        const response = await API.userSkills.fetchUsers(userSkillId, queryParams)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(userSkillSlice.actions.setUserQueryParams(queryParamsFromHeaders(response)))
        dispatch(userSkillSlice.actions.addUserIds(response.data.data.map(user => user.id)))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userSkillSlice.actions.isLoadingUsers(false))
      }
    },
  },
})

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
_.assign(userSkillSlice, {
  selectors: {
    admin: {
      getFilteredSkills: () => state => state.userSkills.userSkillIds.map(id => build(state.entities, 'userSkill', id)),
    },

    getAllSkills: () => (state) => {
      const userSkills = build(state.entities, 'userSkill') || []

      return userSkills
    },

    getUserSkill: userSkillId => state => build(state.entities, 'userSkill', userSkillId) || {},

    getUsers: () => state => state.userSkills.userIds.map(id => build(state.entities, 'simpleUser', id)).filter(present),

    getMetaData: () => state => state.userSkills.meta,
  },
})

export default userSkillSlice
