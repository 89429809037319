import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CardWithTable from 'components/analytics/common/cardWithTable'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import newsAnalyticsSlice from 'redux/slices/analytics/news'
import { EngagingAuthorType } from 'types/analytics/news'
import { toPercentage } from 'utils/analytics'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Link } from 'react-router-dom'
import usePathReusingQueryParams from 'components/common/hooks/usePathReusingQueryParams'
import { TAB_HEADER_QUERY_PARAMS } from 'components/analytics/helpers/useTabHeader'

const I18N = i18nPath('views.platform_analytics.news.most_engaging_authors')
const womanWithComputerPath = '/images/analytics/womanWithComputerIcon.svg'

interface MostEngagingAuthorsProps {
  periodStart: string
  periodEnd?: string
}

const MostEngagingAuthors = ({ periodStart, periodEnd }: MostEngagingAuthorsProps) => {
  const dispatch = useDispatch()

  const { data, totalItems, totalPages } = useSelector(newsAnalyticsSlice.selectors.getMostEngagingAuthors())
  const { mostEngagingAuthors: { isLoading } } = useSelector(newsAnalyticsSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(newsAnalyticsSlice.asyncActions.admin.fetchMostEngagingAuthors(
      {
        periodStart, periodEnd, page: 1, perPage: 10,
      }
    ))
  }, [periodStart, periodEnd])

  const isEmpty = totalItems === 0 && !isLoading

  const columns = [
    {
      header: I18NCommon('author'),
      accessor: (author: EngagingAuthorType) => (
        <div className='d-flex flex-row align-items-center'>
          <EmployeeThumbnailPhoto employee={author} size='25px' />
          <Link to={`/profile/${author.username}`} className='text-truncate w-100 d-block ml-1' target='_blank'>{author.preferredFullName}</Link>
        </div>
      ),
    },
    {
      header: I18N('number_of_articles'),
      style: { width: '15%' },
      accessor: (author: EngagingAuthorType) => (
        author.articlesCount
      ),
    },
    {
      header: I18NCommon('audience_reach'),
      style: { width: '20%' },
      accessor: (author: EngagingAuthorType) => (
        toPercentage(author.reach)
      ),
    },
  ]

  const path = usePathReusingQueryParams({
    path: '/platform_analytics/news/most_engaging_authors',
    paramsToReuse: TAB_HEADER_QUERY_PARAMS,
  })

  if (isEmpty) {
    return (
      <EmptyStateCard
        title={I18N('title')}
        iconPath={womanWithComputerPath}
        text={I18N('empty_state_text')}
        classname='ArticlesTables'
      />
    )
  }


  return (
    <CardWithTable
      title={I18N('title')}
      tableColumns={columns}
      tableData={data}
      classname='ArticlesTables'
      isLoading={isLoading}
      showSeeMoreLink={totalPages > 1}
      seeMorePath={path}
    />
  )
}

export default MostEngagingAuthors
