import React, { useState } from 'react'

import Modal from 'components/common/modal'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import { ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import { AsyncSelect } from 'components/common/react_select'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import CdnSvg from 'components/common/cdnSvg'
import CancelButton from 'components/common/cancelButton'

type Props = {
  isOpen: boolean
  toggle: () => void
  source: ExternalSyncSource
  importDocuments: (sourceId: string, externalFileIds: string[]) => void
}

type ExternalFileType = {
  id: string
  title: string
  subtitle: string
  iconUrl?: string
  iconPath?: string
  emoji?: string
}

const I18N = i18nPath('views.external_sync.external_file_picker_modal')

export const ExternalFilePickerModal = ({
  isOpen, toggle, source, importDocuments,
}: Props) => {
  const [selectedFiles, setSelectedFiles] = useState<ExternalFileType[]>([])
  const removeFile = (fileId: string) => {
    setSelectedFiles(selectedFiles.filter(file => file.id !== fileId))
  }

  const handleImport = () => {
    importDocuments(source.id, selectedFiles.map(file => file.id))
    toggle()
  }

  const [search] = useApi(API.admin.externalSync.externalFiles.search)

  let loadOptions = (query, callback) => {
    if (!query || query.length < 3) {
      callback([])
      return
    }

    search(source.id, query).then((response) => {
      const filteredResponse = response.filter(file => !selectedFiles.some(selectedFile => selectedFile.id === file.id))
      callback(filteredResponse)
    })
  }

  loadOptions = _.debounce(loadOptions, 300)

  return (
    <Modal
      visible={isOpen}
      toggle={toggle}
      title={I18NCommon('import_documents')}
    >
      <AsyncSelect
        name='external-file-picker-modal'
        value={null}
        onChange={file => setSelectedFiles([...selectedFiles, file])}
        loadOptions={loadOptions}
        placeholder={I18NCommon('search')}
        getOptionValue={document => document.id}
        getOptionLabel={document => <ExternalFile file={document} />}
      />
      <hr />
      <p className='text-center text-secondary text-small'>{I18N('sync_info')}</p>
      <p className='text-center text-secondary text-small'>{I18N('sync_warning', { sourceName: source.humanName })}</p>
      {selectedFiles.map(file => (
        <div key={file.id} className='d-flex justify-content-between align-items-center p-2 mb-2 bg-light rounded'>
          <ExternalFile file={file} />
          <div
            className='pointer'
            onClick={() => removeFile(file.id)}
          >
            <CdnSvg className='w-16' src='/images/xIcon.svg' />
          </div>
        </div>
      ))}
      <div className='d-flex justify-content-end align-items-center'>
        <CancelButton className='mr-3 text-secondary font-weight-600' onClick={toggle}>
          {I18NCommon('cancel')}
        </CancelButton>
        <Button onClick={handleImport} disabled={selectedFiles.length === 0}>{I18NCommon('import')}</Button>
      </div>
    </Modal>
  )
}

const ExternalFile = ({ file }: { file: ExternalFileType }) => (
  <div className='d-flex align-items-center gap-2'>
    <ExternalFileIcon file={file} />
    <div className='text-truncate max-w-70'>{file.title}</div>
    <div className='text-secondary text-small text-truncate max-w-20'>{file.subtitle}</div>
  </div>
)

const ExternalFileIcon = ({ file }: { file: ExternalFileType }) => {
  if (file.emoji) {
    return <span className='text-small'>{file.emoji}</span>
  }

  if (file.iconUrl) {
    return <img className='w-16px' src={file.iconUrl} />
  }

  if (file.iconPath) {
    return <CdnSvg className='w-16px' src={file.iconPath} />
  }

  return null
}

export default ExternalFilePickerModal
