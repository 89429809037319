import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { buildStepPayload } from 'redux/slices/journeys/steps'
import {
  DEFAULT_INTRODUCTION_TASK_CALENDAR_EVENT_TITLE,
  DEFAULT_INTRODUCTION_TASK_INSTRUCTIONS,
  DEFAULT_INTRODUCTION_TASK_NAME,
  defaultStepAttributes,
  defaultTaskableAttributes,
  getIntroductionCalendarEventDescriptionText,
  getIntroductionDefaultStepName
} from 'utils/journeys/templatesDefaultHelpers'
import moment from 'moment'

const I18N = i18nPath('views.admin.journeys.introductions')

export const INTRODUCTION_ATTRIBUTES = [
  'allowSilenceNotifications',
  'automaticallyScheduleCalendarInvites',
  'calendarEventDescription',
  'calendarEventTitle',
  'createVideoConference',
  'dueAt',
  'fallbackTimezone',
  'hourWindowEnd',
  'hourWindowStart',
  'instructions',
  'maxInvitesPerDay',
  'meetingDuration',
  'name',
  'notifyViaEmail',
  'notifyViaMsTeams',
  'notifyViaSlack',
  'notifyViaSms',
  'useRecipientNotificationPreferences',
]

export const INTRODUCTION_INTERPOLATED_FIELDS = ['instructions', 'name', 'calendarEventTitle', 'calendarEventDescription']

export const initialState = {
  introductionIds: [],
  meta: {
    ...defaultMeta,
  },
}

export const defaultWorkingCopy = journeyBlueprintType => ({
  ...defaultStepAttributes,
  ...defaultTaskableAttributes,
  name: DEFAULT_INTRODUCTION_TASK_NAME[journeyBlueprintType],
  instructions: DEFAULT_INTRODUCTION_TASK_INSTRUCTIONS[journeyBlueprintType],
  fromCorrespondentAttributes: { role: 'journey_admin' },
  toCorrespondentAttributes: { role: 'manager' },
  meetingDuration: 30,
  calendarEventTitle: DEFAULT_INTRODUCTION_TASK_CALENDAR_EVENT_TITLE[journeyBlueprintType],
  calendarEventDescription: getIntroductionCalendarEventDescriptionText(journeyBlueprintType),
  hourWindowStart: moment.utc('01-01-2020 9:00', 'MM-DD-YYYY h:mm').format(),
  hourWindowEnd: moment.utc('01-01-2020 17:00', 'MM-DD-YYYY h:mm').format(),
  fallbackTimezone: 'America/Los_Angeles',
  automaticallyScheduleCalendarInvites: true,
  maxInvitesPerDay: 2,
  createVideoConference: true,
  stepName: getIntroductionDefaultStepName(journeyBlueprintType),
})

const introductionSlice = createSlice({
  name: 'introductions',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

const buildIntroductionPayload = (introduction) => {
  const newIntroduction = _.pick(introduction, [...INTRODUCTION_ATTRIBUTES, 'id'])

  if (introduction.stepAttributes) {
    newIntroduction.stepAttributes = buildStepPayload(introduction.stepAttributes)
  }

  return newIntroduction
}

const buildIntroductionInvitationsPayload = introductionInvitations => introductionInvitations.map(invitation => _.pick(invitation, ['id', 'inviteeId']))

const asyncActions = {
  admin: {
    fetchIntroduction: id => async (dispatch) => {
      dispatch(introductionSlice.actions.isLoading(true))
      dispatch(introductionSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.introductions.fetch(id)
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(introductionSlice.actions.isNotFound(true))
      } finally {
        dispatch(introductionSlice.actions.isLoading(false))
      }
    },

    updateIntroduction: (workingCopy, onSuccess = () => { }) => async (dispatch) => {
      dispatch(introductionSlice.actions.isSaving(true))

      try {
        const response = await API.admin.introductions.update(buildIntroductionPayload(workingCopy))
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(introductionSlice.actions.setError(error))
      } finally {
        dispatch(introductionSlice.actions.isSaving(false))
      }
    },

    deleteIntroduction: (id, onSuccess = () => { }) => async (dispatch, getState) => {
      try {
        await API.admin.introductions.destroy({ id })
        const introduction = build(getState().entities, 'introduction', id)
        const stepId = introduction?.step?.id
        dispatch(entitySlice.actions.remove({ type: 'introduction', id }))
        dispatch(entitySlice.actions.remove({ type: 'step', id: stepId }))
        dispatch(showToastMessage({ message: I18N('successfully_deleted'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(introductionSlice.actions.setError(error))
      }
    },
  },

  fetchIntroduction: id => async (dispatch) => {
    dispatch(introductionSlice.actions.isLoading(true))
    dispatch(introductionSlice.actions.isNotFound(false))

    try {
      const response = await API.journey.introductions.fetch(id)
      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(introductionSlice.actions.isNotFound(true))
    } finally {
      dispatch(introductionSlice.actions.isLoading(false))
    }
  },

  completeIntroduction: (id, introductionInvitationsAttributes = [], onSuccess = () => { }) => async (dispatch) => {
    dispatch(introductionSlice.actions.isSaving(true))

    try {
      // eslint-disable-next-line max-len
      const response = await API.journey.introductions.complete(id, buildIntroductionInvitationsPayload(introductionInvitationsAttributes))
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('successfully_completed'), type: 'success' }))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(introductionSlice.actions.setError(error))
    } finally {
      dispatch(introductionSlice.actions.isSaving(false))
    }
  },

  reopenIntroduction: (id, onSuccess = () => { }) => async (dispatch) => {
    dispatch(introductionSlice.actions.isSaving(true))

    try {
      const response = await API.journey.introductions.reopen(id)
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('succesfully_reopened'), type: 'success' }))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(introductionSlice.actions.setError(error))
    } finally {
      dispatch(introductionSlice.actions.isSaving(false))
    }
  },
}

const selectors = {
  getMetaData: () => state => state.introductions.meta,

  getIntroduction: id => state => build(state.entities, 'introduction', id),
}

export default {
  ...introductionSlice,
  selectors,
  asyncActions,
}
