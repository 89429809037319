import React, { useEffect } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import QuestionCard from 'components/admin/surveys/questionCard'
import { SurveyQuestionType, QuestionTypeEnum, AddQuestionFunctionType } from 'types/surveys/question'
import { SortableContainer } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import SurveyFormType from 'types/surveys/form'

const I18N = i18nPath('views.admin.surveys')

interface SortableQuestionListProps {
  form: SurveyFormType
  questions: SurveyQuestionType[]
  addQuestion: AddQuestionFunctionType
  updateQuestion: (index: number, changes: Partial<SurveyQuestionType>) => void
  deleteQuestion: (index: number) => void
  disabledEditing: boolean
}

export const defaultQuestion = {
  title: '',
  type: QuestionTypeEnum.ShortText,
  required: true,
  allowAdditionalText: false,
  config: {},
}

const SortableQuestionList = SortableContainer<SortableQuestionListProps>(({
  form,
  questions,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  disabledEditing,
}) => (
  <div>
    {questions.map((question, index) => (
      <QuestionCard
        form={form}
        key={`item-${index}`}
        index={index}
        questionIndex={index}
        className='mb-4'
        question={question}
        addQuestion={addQuestion}
        updateQuestion={changes => updateQuestion(index, changes)}
        deleteQuestion={() => deleteQuestion(index)}
        isDisabled={!!question.id && disabledEditing}
      />
    ))}
  </div>
))


const QuestionsEditSection = ({
  form,
  workingCopy,
  updateWorkingCopy = (changes: any) => {},
}) => {
  const questions = workingCopy.questions || []
  const disabledEditing = _.get(form, 'stats.responsesCount', 0) !== 0

  const addQuestion = (newQuestion: SurveyQuestionType, questionIndex?: number) => {
    if (questionIndex) {
      const newQuestions = [...questions]
      newQuestions.splice(questionIndex, 0, newQuestion)
      updateWorkingCopy({ questions: newQuestions })
    } else {
      // If questionIndex is not provided, add newQuestion to the end of the array
      updateWorkingCopy({ questions: [...questions, newQuestion] })
    }
  }

  const updateQuestion = (index: number, changes: Partial<SurveyQuestionType>) => {
    const newQuestions: SurveyQuestionType[] = [...questions]
    newQuestions[index] = { ...newQuestions[index], ...changes }
    updateWorkingCopy({ questions: newQuestions })
  }

  const deleteQuestion = (index: number) => {
    const newQuestions: SurveyQuestionType[] = [...questions]
    newQuestions.splice(index, 1)
    updateWorkingCopy({ questions: newQuestions })
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newQuestions: SurveyQuestionType[] = [...arrayMove(questions, oldIndex, newIndex) as SurveyQuestionType[]]

      newQuestions.forEach((question, index) => {
        question.orderPosition = index
      })

      updateWorkingCopy({ questions: newQuestions })
    }
  }

  useEffect(() => {
    if (questions.length === 0) {
      addQuestion(defaultQuestion)
    }
  }, [workingCopy?.updatedAt])

  return (
    <>
      <SortableQuestionList
        form={form}
        questions={questions}
        addQuestion={addQuestion}
        updateQuestion={updateQuestion}
        deleteQuestion={deleteQuestion}
        onSortEnd={onSortEnd}
        useDragHandle
        lockAxis='y'
        helperClass='Sortable-isDragging'
        disabledEditing={disabledEditing}
      />
      {questions.length > 0 && (
        <div className='arrow-container w-100 d-flex flex-column align-items-center'>
          <span>&#x007C;</span>
          <span>&#8595;</span>
        </div>
      )}
      <div
        className='add-question-button pointer mt-3 rounded py-4 d-flex align-items-center justify-content-center'
        onClick={() => addQuestion({
          ...defaultQuestion, required: workingCopy.questionsRequiredByDefault,
        })}
      >
        {I18N('add_question')}
      </div>
    </>
  )
}

export default QuestionsEditSection
