import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import InfoTooltip from 'components/common/infoTooltip'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.surveys.responses.stats_banner')

const searchTasksIconPath = '/images/searchTasksIcon.svg'

const FormResponsesStatsBanner = ({
  form,
}) => {
  const audienceSize = form?.stats?.audienceSize || 0
  const responsesCount = form?.stats?.responsesCount || 0
  const responseRate = Math.round((responsesCount / audienceSize) * 100)

  return (
    <Card className='FormResponsesStatsBanner mb-4'>
      <div className='d-flex flex-row justify-content-start align-items-center'>
        <CdnSvg
          className='SearchTasksIcon'
          src={searchTasksIconPath}
        />
        {audienceSize > 0 && (
          <div className='d-flex align-items-center flex-column ml-5'>
            <h3>{responseRate} %</h3>
            <span className='text-secondary'>
              {I18N('response_rate')}
              <InfoTooltip text={I18N('response_rate_tooltip')} />
            </span>
          </div>
        )}
        <div className='d-flex align-items-center flex-column mx-5'>
          <h3>{responsesCount}</h3>
          <span className='text-secondary'>
            {I18N('responses')}
            <InfoTooltip text={I18N('responses_tooltip')} />
          </span>
        </div>
        <div className='d-flex align-items-center flex-column'>
          <h3>{audienceSize}</h3>
          <span className='text-secondary'>
            {I18N('audience_size')}
            <InfoTooltip text={I18N('audience_size_tooltip')} />
          </span>
        </div>
      </div>
    </Card>
  )
}

export default FormResponsesStatsBanner
