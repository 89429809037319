import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import EditableContentTrigger from 'components/common/editable_content/editableContentTrigger'
import profileSlice from 'redux/slices/profiles'
import CdnSvg from 'components/common/cdnSvg'
import OfficeScheduleModal from './officeScheduleModal'

const officeBuildingIconPath = '/images/officeBuildingIcon.svg'
const homeIconPath = '/images/homeIcon.svg'

const I18N = i18nPath('views.profile.office_schedule')

const DisplayText = ({
  className,
  iconPath,
  isEmpty,
  svgClassName,
  user,
  text,
  textLabel,
}) => (
  <span className={classNames('OfficeSchedule d-inline-flex align-items-center', className)}>
    <CdnSvg className={classNames('mr-1', svgClassName)} src={iconPath} />
    <span className='d-inline-flex flex-wrap'>
      {textLabel && (
        <span className='font-weight-500'>{textLabel}&nbsp;</span>
      )}
      <span>{text}</span>
    </span>
  </span>
)

const OfficeSchedule = ({
  canEdit,
  update,
  user,
}) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const isOfficeEmpty = _.isEmpty(user?.workingDaysInOffice)
  const isRemoteEmpty = _.isEmpty(user?.workingDaysRemote)
  const isOfficeAndRemoteEmpty = isOfficeEmpty && isRemoteEmpty
  const daysOfWeek = I18n.t('date.abbr_day_names')

  const prompt = I18N('when_are_you_in_the_office')
  const constructDaysString = daysArray => daysArray.slice().sort().map(i => daysOfWeek[i]).join(', ')
  const officeDisplayString = isOfficeEmpty ? prompt : constructDaysString(user.workingDaysInOffice)
  const remoteDisplayString = isRemoteEmpty ? '' : constructDaysString(user.workingDaysRemote)

  const updateSchedule = (daysInOffice, daysRemote) => {
    const change = {
      workingDaysInOffice: daysInOffice,
      workingDaysRemote: daysRemote,
    }

    return dispatch(profileSlice.asyncActions.update(user, change))
  }

  // if you are looking at someone else's profile and they have not set working hours - show nothing
  if (!canEdit && isOfficeAndRemoteEmpty) {
    return null
  }

  return (
    <>
      <OfficeScheduleModal
        user={user}
        isOpen={isModalOpen}
        toggle={toggleModal}
        update={updateSchedule}
      />
      {(isOfficeAndRemoteEmpty || !isOfficeEmpty) && (
        <EditableContentTrigger
          canEdit={canEdit}
          isEmpty={isOfficeAndRemoteEmpty}
          onClick={canEdit ? toggleModal : null}
          displayElement={(
            <DisplayText
              iconPath={officeBuildingIconPath}
              svgClassName='OfficeBuildingIcon mr-2'
              className={isOfficeAndRemoteEmpty && 'font-weight-500'}
              user={user}
              isEmpty={isOfficeAndRemoteEmpty}
              text={officeDisplayString}
              textLabel={!isOfficeEmpty && I18N('in_the_office')}
            />
          )}
        />
      )}
      {(!isOfficeAndRemoteEmpty && !isRemoteEmpty) && (
        <EditableContentTrigger
          canEdit={canEdit}
          isEmpty={isOfficeAndRemoteEmpty}
          onClick={canEdit ? toggleModal : null}
          displayElement={(
            <DisplayText
              iconPath={homeIconPath}
              svgClassName='HomeIcon mr-2'
              user={user}
              isEmpty={isOfficeAndRemoteEmpty}
              text={remoteDisplayString}
              textLabel={!isRemoteEmpty && I18N('working_remotely')}
            />
          )}
        />
      )}
    </>
  )
}

export default OfficeSchedule
