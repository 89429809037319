import { SimpleUserType } from 'types/user'

export const PENDING = 'pending'
export const ACKNOWLEDGED = 'acknowledged'
export const OVERDUE = 'overdue'

export interface AcknowledgementType {
  id: string
  status: typeof PENDING | typeof ACKNOWLEDGED | typeof OVERDUE
  acknowledgeableId: string
  lastNotificationSentAt: string
  acknowledgedAt: string
  user: SimpleUserType
}
