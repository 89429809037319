import React from 'react'

import HorizontalStackedBarGraph from 'components/analytics/common/horizontalStackedBarGraph'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { tint } from 'utils/color'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import classNames from 'classnames'
import HelpTooltip from 'components/common/tooltip'
import CdnSvg from 'components/common/cdnSvg'

const thumbsUpIconPath = '/images/thumbsUpIcon.svg'
const thumbsDownIconPath = '/images/thumbsDownIcon.svg'
const manWithTabletIconPath = '/images/analytics/manWithTabletIcon.svg'

const I18N = i18nPath('views.platform_analytics.pages.page_feedback')

interface PageFeedbackProps {
  page: {
    helpfulFeedbackCount: number
    notHelpfulFeedbackCount: number
    feedbackEnabled: boolean
    displayPath: string
  }
  isLoading?: boolean
}

const PageFeedback = ({
  page: {
    helpfulFeedbackCount,
    notHelpfulFeedbackCount,
    feedbackEnabled,
    displayPath,
  },
  isLoading = false,
}: PageFeedbackProps) => {
  const currentCompany = useCurrentCompany()

  const { highlightColor: helpfulColor, textColorSecondary } = currentCompany.customStyles.variables.colors
  const notHelpfulColor = tint(helpfulColor, 50)
  const noFeedbackColor = tint(textColorSecondary, 80)

  const total = helpfulFeedbackCount + notHelpfulFeedbackCount
  const hasNoFeedback = total === 0
  const helpfulPercent = Math.round((helpfulFeedbackCount / total) * 100)
  const notHelpfulPercent = Math.round((notHelpfulFeedbackCount / total) * 100)


  const labels = ['Helpful, Not Helpful']
  const datasets = [
    {
      data: [helpfulPercent],
      backgroundColor: helpfulColor,
      hoverBackgroundColor: helpfulColor,
    },
    {
      data: [notHelpfulPercent],
      backgroundColor: notHelpfulColor,
      hoverBackgroundColor: notHelpfulColor,
    },
  ]

  const emptyDataset = [
    {
      data: [100],
      backgroundColor: noFeedbackColor,
      hoverBackgroundColor: noFeedbackColor,
    },
  ]

  if (!feedbackEnabled && !isLoading) {
    return (
      <EmptyStateCard
        title={I18N('title')}
        iconPath={manWithTabletIconPath}
        text={(
          <div className='text-small font-weight-400'>
            {I18N('not_enabled_1')} <a href={displayPath}>{I18NCommon('click_here')}</a> {I18N('not_enabled_2')}
          </div>
        )}
        classname='PageFeedback'
      />
    )
  }

  return (
    <div className='PageFeedback analytics-card'>
      <header className='d-flex flex-row align-items-center'>
        <h3>{I18N('title')}</h3>
        <HelpTooltip
          iconStyle={{ width: '18px', height: '18px' }}
          className='ml-2'
          tooltipClassName=''
          tooltipInnerClassName='text-small px-1 py-2'
        >
          {I18N('tooltip')}
        </HelpTooltip>
      </header>

      {isLoading ? (
        <CirclesLoadingIndicator className='w-100' />
      ) : (
        <>
          {hasNoFeedback && (<p className='NoFeedbackText text-small m-0 w-100 text-center position-relative font-weight-400'>{I18N('no_feedback')}</p>)}
          <div className='d-flex flex-row w-100 justify-content-center align-items-center h-90'>
            <div className={classNames('Label d-flex flex-column align-items-center position-relative mr-2', { hasNoFeedback })}>
              <CdnSvg src={thumbsUpIconPath} className={classNames('ThumbsUp', { GrayBackground: hasNoFeedback })} />
              <h3 className='mt-2'>{helpfulFeedbackCount}</h3>
            </div>

            <HorizontalStackedBarGraph
              labels={labels}
              datasets={hasNoFeedback ? emptyDataset : datasets}
              classname='w-100'
              showDatalabels={!hasNoFeedback}
            />

            <div className={classNames('Label d-flex flex-column align-items-center position-relative ml-2', { hasNoFeedback })}>
              <CdnSvg src={thumbsDownIconPath} className={classNames('ThumbsDown', { GrayBackground: hasNoFeedback })} />
              <h3 className='mt-2'>{notHelpfulFeedbackCount}</h3>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PageFeedback
