import React, { useState, useEffect } from 'react'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import classNames from 'classnames'

import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import FormSelectField from 'components/form_fields/formSelectField'

const I18N = i18nPath('views.groups.group_page')

const MoveToGroupModal = ({
  className,
  onSave,
  showModal,
  closeModal,
  isSaving = false,
  groupTypeLabel = null,
  groupTypeLabels = [],
}) => {
  const [workingCopyLabel, setWorkingCopyLabel] = useState(groupTypeLabel)

  useEffect(() => {
    setWorkingCopyLabel(groupTypeLabel)
  }, [showModal])

  return (
    <Modal size='md' className={classNames('MoveToGroupModal', className)} visible={showModal} toggle={closeModal}>
      <div className='d-flex flex-column p-2'>
        <h4>{I18N('move_to_group')}</h4>
        <div className='text-secondary'>{I18N('move_to_group_secondary')}</div>
        <FormSelectField
          className='mt-5'
          isRequired={false}
          label={I18N('select_group')}
          onChange={label => setWorkingCopyLabel(label)}
          currentValue={workingCopyLabel}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          options={groupTypeLabels}
        />
        <div className='d-flex align-self-end mt-4'>
          <Button className='mr-2' disabled={isSaving} variant='secondary' onClick={closeModal}>
            {I18NCommon('cancel')}
          </Button>
          <Button onClick={() => onSave(workingCopyLabel)} disabled={isSaving} showLoadingSpinner={isSaving}>
            {I18NCommon('save')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default MoveToGroupModal
