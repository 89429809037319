import { ButtonSmallNarrow } from 'components/common/buttons'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import React from 'react'
import { buildPagesFaqPayload } from 'services/api/pagesFAQs'
import { PageFAQType } from 'types/page/faq'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.pages.faqs.form')

type Props = {
  onSave: (payload: Partial<PageFAQType>) => void
  faq?: PageFAQType
  onCancel: () => void
  isSaving: boolean
}

const FAQForm: React.FC<Props> = ({
  faq = { question: '', answer: '' } as PageFAQType,
  onSave,
  onCancel,
  isSaving,
}) => {
  const [workingCopy, _setWorkingCopy, updateWorkingCopy] = useUpdatableState<Partial<PageFAQType>>(
    buildPagesFaqPayload(faq)
  )

  return (
    <>
      <input
        type='text'
        className='w-100 text-xl font-weight-600 p-0 border-none inherit-height'
        value={workingCopy.question}
        onChange={e => updateWorkingCopy({ question: e.target.value })}
        autoFocus
        placeholder={I18N('question_placeholder')}
      />
      <TiptapEditor
        configuration={{
          ...SIMPLE_EDITOR_CONFIGURATION,
          imagesEnabled: true,
          filesEnabled: true,
          userMentionsEnabled: true,
          placeholder: I18N('answer_placeholder'),
        }}
        toolbarItems={SMALL_TOOLBAR}
        onChange={value => updateWorkingCopy({ answer: value })}
        html={workingCopy.answer}
      />
      <div className='d-flex gap-2 justify-content-end mt-3'>
        <ButtonSmallNarrow
          onClick={onCancel}
          variant='secondary'
        >
          {I18NCommon('cancel')}
        </ButtonSmallNarrow>
        <ButtonSmallNarrow
          onClick={() => onSave(workingCopy)}
          disabled={isSaving}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('save')}
        </ButtonSmallNarrow>
      </div>
    </>
  )
}

export default FAQForm
