import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import ReactSelect from 'components/common/react_select'
import searchSlice from 'redux/slices/search'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { i18nPath } from 'utils/i18nHelpers'
import { isEmpty } from 'lodash'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.search.search_results.filters')

const ConfluenceFilter = ({ updateHistory }) => {
  const dispatch = useDispatch()
  const { query, filters: rawFilters = '{}' } = useQueryParams()
  const filters = useMemo(() => JSON.parse(rawFilters), [rawFilters])
  const { space: spaceFilter = '' } = filters

  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const confluenceSpaces = settings.confluenceSearch?.spaces || []

  useEffect(() => {
    dispatch(searchSlice.actions.reset({ searchLocation: 'pageFiltered' }))

    dispatch(searchSlice.asyncActions.searchConfluence({
      query,
      searchConfluence: true,
      searchLocation: 'pageFiltered',
      filters,
    }))
  }, [query, filters])

  const handleSearch = (newFilters, type) => {
    const updatedFilters = { ...filters, ...newFilters }

    if (!newFilters) {
      delete updatedFilters[type]
    }

    updateHistory(updatedFilters)
  }

  const handleSpaceChange = (option) => {
    const filters = !isEmpty(option)
      ? {
        space: option.value,
      }
      : null
    handleSearch(filters, 'space')
  }

  return (
    <div className='ConfluenceFilter MultipleRefinedFilters px-3'>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('confluence_space_filter')}
        </div>
        <ReactSelect
          isClearable
          value={confluenceSpaces.filter(({ value }) => value === spaceFilter)}
          placeholder={(
            <div className='text-secondary'>
              {I18n.t('views.common.react_select.select_or_type')}
            </div>
          )}
          onChange={handleSpaceChange}
          options={confluenceSpaces}
        />
      </div>
    </div>
  )
}

export default ConfluenceFilter
