import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.article_list.private_label')

interface PrivateLabelProps {
  className?: string
}

const PrivateLabel = ({ className }: PrivateLabelProps) => {
  const overlay = (
    <Popover className='PrivateLabelPopover py-2 px-3' id='private-label-popover'>
      <p className='text-smallest text-white m-0'>{I18N('popover_text')}</p>
    </Popover>
  )

  return (
    <OverlayTrigger overlay={overlay} placement='bottom'>
      <div className={classNames('PrivateLabel', className)}>
        <span className='text-secondary text-small mr-1'>{I18N('private')}</span>
        <CdnSvg src='/images/lockIcon.svg' className='LockIcon' />
      </div>
    </OverlayTrigger>
  )
}

export default PrivateLabel
