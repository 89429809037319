import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import QuestionBubbleChatIcon from 'components/icons/qna/questionBubbleChatIcon'

const I18N = i18nPath('views.qna.events')

const EventCardQuestionCount = ({ questionsCount }) => {
  const questionKey = `question_count_${questionsCount === 1 ? 'singular' : 'plural'}`

  return (
    <div className='EventCardQuestionCount mt-1'>
      <QuestionBubbleChatIcon className='mr-1' size='0.957rem' />
      <span className='text-small text-sm-normal'>{I18N(questionKey, { questionsCount })}</span>
    </div>
  )
}

export default EventCardQuestionCount
