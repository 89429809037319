/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import LoadingSpinner from 'components/common/loadingSpinner'
import PictureList from 'components/common/images/pictureList'
import Picture from 'components/common/images/picture'
import VideoIntroductions from 'components/profiles/videoIntroductions'
import CoreFields from 'components/profiles/coreFields'
import TeamsAndRelationships from 'components/profiles/teamsAndRelationships'
import PhotosSlider from 'components/common/images/photosSlider'
import Tenure from 'components/profiles/tenure'
import ContactInfo from 'components/profiles/contact_info'
import Badges from 'components/profiles/badges'
import Schedule from 'components/profiles/core_fields/schedule'
import ViewOrgChartAndSendShoutout from 'components/profiles/viewOrgchartAndSendShoutout'
import TabbedContent from 'components/profiles/tabbedContent'

import profileSlice from 'redux/slices/profiles'
import shoutoutsSlice from 'redux/slices/shoutouts'
import celebrationSlice from 'redux/slices/celebrations'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CelebrationBanner from 'components/profiles/celebrationBanner'
import { trackEvent } from 'services/tracker'


const ProfilePage = () => {
  const { username } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState(false)
  const [modalPhotos, setModalPhotos] = useState([])

  useEffect(() => {
    dispatch(profileSlice.asyncActions.fetchProfile(username))
    dispatch(shoutoutsSlice.asyncActions.fetchCompanyValues())

    return function cleanup() {
      dispatch(profileSlice.actions.clearProfile())
      dispatch(celebrationSlice.actions.clear())
    }
  }, [username])

  const { isLoading, isNotFound } = useSelector(profileSlice.selectors.getMetaData())

  const user = useSelector(profileSlice.selectors.getCurrentProfileUser()) ?? {}
  const primaryPicture = _.find(user.photos, { primary: true })
  const otherPictures = _.filter(user.photos, { primary: false })
  const { defaultPhotoUrl } = user
  const hasLoadedUser = !isLoading && !_.isEmpty(user)

  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const fieldSettings = settings.profile.fields
  const {
    permissions: { clearyAdmin, userManager },
    inMobileApp,
  } = currentUser
  const isCurrentUser = user.id === currentUser.id

  useEffect(() => {
    if (user?.id) {
      trackEvent('profile:view', { user_id: user.id })
    }
  }, [user?.id])

  const openModal = (currentPhoto, photos) => {
    // Prevent the modal to open if currentPhoto is not a real photo object
    if (!currentPhoto?.id) return

    const modalPhotos = [currentPhoto, ...photos.filter(photo => photo.id !== currentPhoto.id)]

    setModalPhotos(modalPhotos)
    setIsPhotosModalOpen(true)
  }

  const closeModal = () => setIsPhotosModalOpen(false)

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isNotFound) {
    dispatch(profileSlice.actions.clearProfile())
    history.push(`/search?filter=people&query=${username}`)
    return null
  }

  if (hasLoadedUser) {
    return (
      <main className='NewProfilePage'>
        <HelmetProvider>
          <Helmet>
            <title>{user.preferredFullName}</title>
          </Helmet>
        </HelmetProvider>
        {!isCurrentUser && fieldSettings.celebrationBanner && (
          <CelebrationBanner user={user} />
        )}
        <section className='user-info-grid'>
          <Picture
            owner={user}
            picture={primaryPicture}
            defaultPhotoUrl={defaultPhotoUrl}
            className='PrimaryPhoto'
            onClick={() => openModal(primaryPicture, otherPictures)}
          />

          {!inMobileApp && (
            <VideoIntroductions
              user={user}
              fieldSettings={fieldSettings}
              canEdit={user.permittedActions?.edit}
            />
          )}

          <PictureList owner={user} pictures={otherPictures} onClick={openModal} />

          <ViewOrgChartAndSendShoutout
            user={user}
            settings={settings}
          />

          <CoreFields
            canEdit={user.permittedActions?.edit}
            showAdminEditLink={clearyAdmin || userManager}
            fieldSettings={fieldSettings}
            user={user}
          />

          <TeamsAndRelationships
            user={user}
            fieldSettings={fieldSettings}
          />

          <ContactInfo
            canEdit={user.permittedActions?.edit}
            fieldSettings={fieldSettings}
            user={user}
          />
        </section>
        <section className='card-grid'>
          <Tenure
            user={user}
            fieldSettings={fieldSettings}
          />
          <Badges
            user={user}
            areBadgesEnabled={settings.badges.enabled}
          />
          <Schedule isScheduleEnabled={fieldSettings.calendar} />
          <TabbedContent
            user={user}
            currentCompany={currentCompany}
            canEdit={user.permittedActions?.edit}
            areShoutoutsEnabled={settings.shoutouts.enabled}
            areNewsEnabled={settings.news.enabled}
          />
        </section>
        {isPhotosModalOpen && (
          <PhotosSlider
            owner={user}
            closeModal={closeModal}
            modalPhotos={modalPhotos}
          />
        )}
      </main>
    )
  }

  return <LoadingSpinner />
}

export default ProfilePage
