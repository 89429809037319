import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import { useMemo } from 'react'
import API from 'services/api'

const useTargetingOptions = () => useGlobalStateBucket(API.audiences.fetchTargetingOptions, 'targetingOptions').data

export const useCityTargetingOptions = () => {
  const targetingOptions = useTargetingOptions()

  const cityOptions = useMemo(
    () => targetingOptions?.find(op => op?.key === 'city')?.options || [],
    [targetingOptions]
  )

  return cityOptions
}

export default useTargetingOptions
