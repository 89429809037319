import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { i18nPath, i18nMomentWithTimezone } from 'utils/i18nHelpers'
import meetingSlice, { MEETING_ATTRIBUTES } from 'redux/slices/journeys/meetings'
import { MEETING_TEMPLATE_INTERPOLATED_FIELDS } from 'redux/slices/journeys/meetingTemplates'
import MeetingForm from 'components/admin/journeys/meetingTemplates/meetingForm'
import StepAdminBanner from 'components/admin/journeys/stepAdminBanner'

import { LoadingContainer } from 'components/common/loadingContainer'

import useStepManagement from 'components/admin/journeys/hooks/useStepManagement'
import EditEntityHeader from 'components/admin/journeys/common/editEntityHeader'
import currentTimezone from 'utils/currentTimezone'

const I18N = i18nPath('views.admin.journeys.meetings.edit_meeting_page')

const EditMeetingPage = () => {
  const { id } = useParams()

  const meeting = useSelector(meetingSlice.selectors.getMeeting(id))
  const meetingTemplate = meeting?.meetingTemplate
  const {
    isLoading, isNotFound, isSaving,
  } = useSelector(meetingSlice.selectors.getMetaData())

  const step = meeting?.step
  const missingVariables = step?.missingVariables
  const scheduledAt = meeting?.scheduledAt
  const journeyBlueprint = step?.journey?.journeyBlueprint
  const completionStatus = meeting?.completionStatus
  const wasSent = completionStatus === 'sent'
  const errorReason = meeting?.errorReason || 'error_creating_event'

  const timezone = step?.triggerTimezone || currentTimezone()

  const {
    workingCopy,
    updateWorkingCopy,
    hasChanges,
    onSave,
  } = useStepManagement({
    id,
    entity: meeting,
    entityTemplate: meetingTemplate,
    entityAttributes: MEETING_ATTRIBUTES,
    fetchEntity: meetingSlice.asyncActions.admin.fetchMeeting,
    updateEntity: meetingSlice.asyncActions.admin.updateMeeting,
    interpolatedFields: MEETING_TEMPLATE_INTERPOLATED_FIELDS,
  })

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='EditMeetingPage'>
        <EditEntityHeader
          type='meeting'
          entity={meeting}
          entityTemplate={meetingTemplate}
          entityI18n={I18N}
        />
        <StepAdminBanner
          step={step}
          triggeredText={I18N('meeting_sent_banner_description',
            {
              sentHour: `${i18nMomentWithTimezone({ date: scheduledAt, timezone, format: 'hh:mm A' })} (${timezone})`,
              sentDate: i18nMomentWithTimezone({ date: scheduledAt, timezone, format: 'll' }),
            })}
          missingVariablesText={I18N('meeting_not_sent_banner_description', { missingVariables })}
          errorText={I18N(`meeting_error_scheduling.${errorReason}`)}
        />
        <main className='AdminContent'>
          {
            meeting && (
              <MeetingForm
                onSave={onSave}
                updateWorkingCopy={updateWorkingCopy}
                workingCopy={workingCopy}
                currentState='active'
                isStepTemplate={false}
                areChangesPresent={hasChanges}
                forUser={step?.forUser}
                isDisabled={wasSent}
                journeyBlueprint={journeyBlueprint}
                isSaving={isSaving}
              />
            )
          }
        </main>
      </div>
    </LoadingContainer>
  )
}

export default EditMeetingPage
