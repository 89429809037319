import React, { useState } from 'react'

import PillTabSelector from 'components/common/pillTabSelector'
import { i18nPath } from 'utils/i18nHelpers'
import EmployeesGrid from 'components/people/employeesGrid'
import { Button } from 'components/common/buttons'
import CelebrationsReportModal from 'components/common/modals/celebrationsReportModal'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18NCommon = i18nPath('views.common')

const DateBasedCelebrationsGrid = ({
  employeesSelector,
  i18nPathStr,
  additionalText,
  dispatchFetchEmployees,
  fetchMeta,
  reportType,
  hideNextWeek = false,
}) => {
  const I18N = i18nPath(i18nPathStr)

  const [filter, setFilter] = useState('this_week')
  const [isDownloadModalShown, setIsDownloadModalShown] = useState(false)

  // show the reports download button only when the user is superAdmin
  const currentUser = useCurrentUser()
  const showDownloadReportButton = currentUser.permissions?.superAdmin && !currentUser.inMobileApp

  const handleFilterSelection = (filter) => {
    setFilter(filter)
    if (_.isEmpty(employeesSelector(filter))) {
      dispatchFetchEmployees(filter)
    }
  }

  return (
    <div className='container PeopleCelebration'>
      <div className='col-md-12 mt-4'>
        <div className='d-flex align-items-center justify-content-between'>
          <h1>{I18N('title')}</h1>
          {showDownloadReportButton && (
            <Button
              className=''
              onClick={() => setIsDownloadModalShown(true)}
              disabled={isDownloadModalShown}
            >
              {I18NCommon('download_report')}
            </Button>
          )}
        </div>
        <div className='mt-4'>
          <PillTabSelector
            pillClasses='text-normal'
            tabs={[
              {
                text: I18NCommon('this_week'),
                onClick: () => {
                  handleFilterSelection('this_week')
                },
              },
              {
                text: I18NCommon('next_week'),
                onClick: () => {
                  handleFilterSelection('next_week')
                },
                hidden: hideNextWeek,
              },
              {
                text: I18NCommon('last_week'),
                onClick: () => {
                  handleFilterSelection('last_week')
                },
              },
            ]}
          />
        </div>

        <CelebrationsReportModal
          reportType={reportType}
          visible={isDownloadModalShown}
          toggle={() => setIsDownloadModalShown(false)}
        />

        <EmployeesGrid
          filter={filter}
          employees={employeesSelector(filter)}
          dispatchFetchEmployees={dispatchFetchEmployees}
          fetchMeta={fetchMeta}
          className='mt-4 row'
          additionalText={additionalText}
          i18nPathStr={i18nPathStr}
        />
      </div>
    </div>
  )
}

export default DateBasedCelebrationsGrid
