import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import TransferPageOwnershipModal from 'components/pages/actions_dropdown/transferPageOwnershipModal'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { InactiveOwnerPageType } from 'types/analytics/pages'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import pageSlice from 'redux/slices/pages'
import BackButton from 'components/common/backButton'
import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import TabHeader from 'components/analytics/helpers/tabHeader'

const I18N = i18nPath('views.platform_analytics.pages.inactive_owners')

const backToText = i18nPath('views.platform_analytics')('title')

const womanWithComputerPath = '/images/analytics/womanWithComputerIcon.svg'

const InactiveOwnerPagesPage = () => {
  const dispatch = useDispatch()

  const {
    targetingRules,
    setTargetingRules,
    completedTargetingRules,
  } = useTabHeader()

  const [pageToReassign, setPageToReassign] = useState<null | InactiveOwnerPageType>(null)

  const {
    data,
    totalItems,
    page,
    totalPages,
  } = useSelector(pageAnalyticsSlice.selectors.getInactiveOwnerPages())

  const { inactiveOwnerPages: { isLoading } } = useSelector(pageAnalyticsSlice.selectors.getMetaData())
  const { isSaving: isSavingOwnerChange } = useSelector(pageSlice.selectors.getPageData())

  const isInactiveOwnerPagesEmpty = totalItems === 0 && !isLoading

  const fetchData = ({ page, skipLoading = false }) => {
    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchInactiveOwnerPages({
      page,
      targetingRules: completedTargetingRules,
    }, skipLoading))
  }

  useEffect(() => {
    fetchData({ page: 1 })
  }, [completedTargetingRules])

  const columns = [
    {
      header: I18N('page'),
      col: 'col-title',
      accessor: (page: InactiveOwnerPageType) => <a href={page.url}>{page.title}</a>,
    },
    {
      header: I18N('owner'),
      col: 'col-owner',
      accessor: (page: InactiveOwnerPageType) => (
        <div className='d-flex flex-row align-items-center'>
          <EmployeeThumbnailPhoto employee={page.owner} size='1.5rem' />
          <span className='font-weight-500 ml-1'>{page.owner.preferredFullName}</span>
        </div>
      ),
    },
    {
      header: I18N('action'),
      col: 'col-action',
      style: { width: '20%' },
      accessor: page => (
        page.permittedActions?.canChangePageOwner ? (
          <span
            className='pointer link-color font-weight-600'
            onClick={() => setPageToReassign(page)}
          >
            {I18N('reassign')}
          </span>
        ) : (
          <span />
        )
      ),
    },
  ]

  const handlePaginationClick = ({ page }) => fetchData({ page })

  return (
    <main className='include-default-grid mb-5'>
      <BackButton
        backToText={backToText}
        url='/platform_analytics/pages'
        className='grid-span-12 my-4'
      />

      <TabHeader
        className='mb-4'
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
      />

      {isInactiveOwnerPagesEmpty ? (
        <EmptyStateCard
          title={I18N('title', { count: 0 })}
          iconPath={womanWithComputerPath}
          text={I18N('empty_state_text')}
          classname='grid-span-12'
        />
      ) : (
        <>
          <CardWithPaginatedTable
            title={I18N('title', { count: totalItems })}
            tableColumns={columns}
            tableData={data}
            classname='grid-span-12'
            isLoading={isLoading}
            showPagination={totalPages > 1}
            page={page}
            totalPages={totalPages}
            onPaginationClick={handlePaginationClick}
          />
          <TransferPageOwnershipModal
            key={`selected-page-${pageToReassign?.id}`}
            page={pageToReassign}
            isOpen={!_.isEmpty(pageToReassign)}
            onClose={() => setPageToReassign(null)}
            onSuccess={() => fetchData({ page, skipLoading: true })}
          />
        </>
      )}
    </main>
  )
}

export default InactiveOwnerPagesPage
