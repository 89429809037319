import React, { useState } from 'react'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'

import AsyncSearchInput from 'components/common/asyncSearchInput'
import JourneysTableWithSteps, { COMPLETE_TAB, IN_PROGRESS_TAB } from 'components/admin/journeys/journeys/journeysTableWithSteps'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import JourneyBlueprintType from 'types/journeys/journeyBlueprint'
import DateRangePicker from 'components/form_fields/dateRangePicker'
import InactiveCorrespondentBanner from 'components/admin/journeys/common/inactiveCorrespondentBanner'

const I18N = i18nPath('views.admin.journeys.journey_blueprints')

interface JourneyBlueprintTableModeContentProps {
  journeyBlueprint: JourneyBlueprintType
}

const JourneyBlueprintTableModeContent = ({
  journeyBlueprint,
}: JourneyBlueprintTableModeContentProps) => {
  const journeyBlueprintType = journeyBlueprint?.type
  const [searchQuery, setSearchQuery] = useState<string>()
  const [selectedDateFilterRange, setSelectedDateFilterRange] = useState<[string?, string?]>([undefined, undefined])

  return (
    <>
      <div className='d-flex justify-content-start w-100'>
        <HistoryChangingPillTabSelector
          tabs={[
            { id: IN_PROGRESS_TAB, text: I18NCommon('in_progress') },
            { id: COMPLETE_TAB, text: I18NCommon('complete') },
          ]}
          pillClasses='text-normal'
        />
      </div>
      <div className='d-flex w-100 justify-content-between mb-3 mt-3'>
        <AsyncSearchInput
          onKeyUp={setSearchQuery}
          className='mb-0 mt-0'
          placeholder={I18N('search_employee')}
        />
        <div className='d-flex flex-column align-items-end'>
          <div className='d-flex justify-content-center align-items-center OnlyDate'>
            <p className='text-secondary mb-0 mr-2'>{I18N(`date_filter.label.${journeyBlueprintType}`)}</p>
            <DateRangePicker
              dateRange={selectedDateFilterRange}
              setDateRange={setSelectedDateFilterRange}
            />
          </div>
        </div>
      </div>

      <JourneysTableWithSteps
        journeyBlueprint={journeyBlueprint}
        searchQuery={searchQuery}
        selectedDateFilterRange={selectedDateFilterRange}
      />
    </>
  )
}

export default JourneyBlueprintTableModeContent
