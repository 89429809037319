import React from 'react'
import { useParams } from 'react-router-dom'

import useStepTemplateCreation from 'components/admin/journeys/hooks/useStepTemplateCreation'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import StepTemplateHeader from 'pages/admin/journeys/step_templates/stepTemplateHeader'

import stepTemplateMap from 'utils/journeys/stepTemplateMap'

const CreateStepTemplatePage = () => {
  const { stepTemplateType } = useParams()
  const stepTemplateConfig = stepTemplateMap[stepTemplateType]
  const currentCompany = useCurrentCompany()

  const {
    FormComponent,
    generateDefaultWorkingCopy,
    entityTemplateAttributes,
    createApiPath,
    interpolatedFields,
    nonRequiredFields,
    postTriggerNames = [],
    HelpCard,
  } = stepTemplateConfig

  const defaultWorkingCopy = (type: string) => generateDefaultWorkingCopy(type, currentCompany)

  const {
    name,
    workingCopy,
    updateWorkingCopy,
    handleSave,
    journeyBlueprint,
    isSaving,
  } = useStepTemplateCreation({
    defaultWorkingCopy,
    entityTemplateAttributes,
    createApiPath,
    interpolatedFields,
  })

  const templatePostTriggerNames = _.isArray(postTriggerNames) ? postTriggerNames : postTriggerNames(workingCopy)

  if (_.isEmpty(workingCopy)) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='CreateStepTemplatePage'>
      <StepTemplateHeader
        journeyBlueprint={journeyBlueprint}
        workingCopy={workingCopy}
        name={name}
        onSave={handleSave}
        isSaving={isSaving}
        nonRequiredFields={nonRequiredFields}
        postTriggerNames={templatePostTriggerNames}
      />

      <main className='AdminContent'>
        <FormComponent
          onSave={handleSave}
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          journeyBlueprint={journeyBlueprint}
          isSaving={isSaving}
          helpCard={HelpCard ? <HelpCard journeyBlueprintType={journeyBlueprint?.type} /> : null}
        />
      </main>
    </div>
  )
}

export default CreateStepTemplatePage
