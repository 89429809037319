import React from 'react'
import { Link } from 'react-router-dom'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import PencilAndPaperIcon from 'components/icons/pencilAndPaperIcon'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.navbar')

interface NewPostButtonProps {
  iconFill: string
}

const NewPostButton = ({ iconFill }: NewPostButtonProps) => {
  const { hasEditableGroups, permissions: { articleCreator } } = useCurrentUser()
  const { teamPostsEnabled, groupDisplayName, settings } = useCurrentCompany()
  const canAddPostToATeam = teamPostsEnabled && hasEditableGroups
  const newsEnabled = settings.news.enabled

  if (!newsEnabled) {
    return null
  }

  const buttons = [
    articleCreator && { linkTo: '/admin/articles/new', title: I18N('company_update') },
    canAddPostToATeam && { linkTo: '/posts/new', title: I18N('group_update', { groupDisplayName }) },
  ].filter(Boolean)

  if (buttons.length === 0) {
    return null
  }

  if (buttons.length === 1) {
    const button = buttons[0]

    return (
      <Link to={button.linkTo}>
        <div className='Navbar-navItemContainer' id='cy_new_post_button'>
          <PencilAndPaperIcon fill={iconFill} />
        </div>
      </Link>
    )
  }

  return (
    <DropdownMenuContainer
      toggleContent={
        (
          <div className='Navbar-navItemContainer'>
            <PencilAndPaperIcon fill={iconFill} />
          </div>
        )
      }
      dropdownOffsetPx={-1}
      navLinks={buttons}
      id='cy_new_post_button'
    />
  )
}

export default NewPostButton
