import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button, ButtonSecondary } from 'components/common/buttons'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import ShoutoutIcon from 'components/icons/shoutoutIcon'
import shoutoutsSlice from 'redux/slices/shoutouts'
import ShoutoutCompanyValue from 'components/shoutouts/shoutoutCompanyValue'
import CompanyValuesInstructionsText from 'components/shoutouts/companyValuesInstructionsText'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'
import GifSelector from 'components/common/tenor/gifSelector'

const I18N = i18nPath('views.shoutouts')

const MAX_TEXT_LENGTH = 1000

const ModalHeader = ({ shoutout }) => (
  <h3 className='d-flex align-items-center justify-content-center mb-4'>
    <span className='ShoutoutIcon'>
      <ShoutoutIcon />
    </span>
    <span className='ml-3 font-weight-bolder'>
      {shoutout.id ? I18N('edit_shoutout_header') : I18N('send_a_shoutout')}
    </span>
  </h3>
)

const CompanyValueList = ({
  companyValues,
  selectedCompanyValues,
  minSelectableCompanyValues,
  maxSelectableCompanyValues,
  generateCompanyValueToggler,
}) => {
  const maxCompanyValuesSelected = selectedCompanyValues.length === maxSelectableCompanyValues

  return (
    <>
      <CompanyValuesInstructionsText
        minSelectableCompanyValues={minSelectableCompanyValues}
        maxSelectableCompanyValues={maxSelectableCompanyValues}
      />

      <div className='ShoutoutCompanyValues d-flex flex-wrap justify-content-center'>
        {companyValues.map((companyValue, index) => {
          const isSelected = _.find(selectedCompanyValues, { id: companyValue.id })
          const isNonSelectable = !isSelected && maxCompanyValuesSelected

          return (
            <ShoutoutCompanyValue
              key={index}
              popoverTimeout={1000}
              onClick={generateCompanyValueToggler(companyValue)}
              showPopover={!isNonSelectable}
              isSelected={isSelected}
              isNonSelectable={isNonSelectable}
              className={classNames({ pointer: true })}
              companyValue={companyValue}
              shoutoutId='shoutout-modal'
            />
          )
        })}
      </div>
    </>
  )
}

const ShoutoutForm = ({
  workingCopy,
  updateWorkingCopy,
  sendShoutout,
  className,
  isSaving,
}) => {
  const dispatch = useDispatch()
  const [showGifSelector, setShowGifSelector] = useState(!!workingCopy.gifUrl)

  const currentCompany = useCurrentCompany()
  const companyValues = useSelector(shoutoutsSlice.selectors.getCompanyValues())

  const shoutoutsSettings = currentCompany.settings.shoutouts
  const maxSelectableCompanyValues = shoutoutsSettings.maxSelectableCompanyValues || 3
  const minSelectableCompanyValues = shoutoutsSettings.minSelectableCompanyValues || 0


  useEffect(() => {
    dispatch(shoutoutsSlice.asyncActions.fetchCompanyValues())
  }, [])


  const onSelectUser = users => updateWorkingCopy({ users })

  const onSubmit = () => sendShoutout(workingCopy)

  const handleShoutoutTextChange = (e) => {
    updateWorkingCopy({
      text: e.target.value.slice(0, MAX_TEXT_LENGTH),
    })
  }

  const generateCompanyValueToggler = companyValue => () => {
    const isAlreadySelected = _.find(workingCopy.companyValues, { id: companyValue.id })
    let newSelectedCompanyValues

    if (isAlreadySelected) {
      newSelectedCompanyValues = _.reject(workingCopy.companyValues, { id: companyValue.id })
    } else if (workingCopy.companyValues.length >= maxSelectableCompanyValues) {
      newSelectedCompanyValues = [...workingCopy.companyValues]
    } else {
      newSelectedCompanyValues = [...workingCopy.companyValues, companyValue]
    }

    updateWorkingCopy({ companyValues: newSelectedCompanyValues })
  }

  const missingRequiredInput = (
    isSaving
    || workingCopy.users.length === 0
    || workingCopy.text.length === 0
    || workingCopy.companyValues.length < minSelectableCompanyValues
  )

  return (
    <div className={className}>
      <ModalHeader shoutout={workingCopy} />

      <p className='font-weight-bold'>{I18N('select_recipients_instruction_text')}</p>

      <EmployeeSearch
        onChange={onSelectUser}
        selectedEmployees={workingCopy.users}
        isMulti
        requiredUserFlags={['shoutouts.enabled']}
      />

      <div className='mt-5 mb-3'>
        <div className='font-weight-bold'>{I18N('what_did_they_do')}</div>
        <div className='text-secondary text-small'>{I18N('call_out_text')}</div>
      </div>

      <textarea
        placeholder={I18N('placeholder_text', { maxCharacters: MAX_TEXT_LENGTH })}
        rows={3}
        onChange={handleShoutoutTextChange}
        value={workingCopy.text}
        style={{ width: '100%' }}
      />

      <div className='text-muted text-small text-right'>{I18N('characters_remaining', { numCharactersRemaining: MAX_TEXT_LENGTH - workingCopy.text.length })}</div>

      <div className='font-weight-bold mt-4 mb-3'>{I18N('select_a_gif')}</div>

      {showGifSelector ? (
        <GifSelector
          gifUrl={workingCopy.gifUrl}
          initialTerm={I18NCommon('great_work')}
          setGifUrl={gifUrl => updateWorkingCopy({ gifUrl })}
          width='100%'
        />
      ) : (
        <ButtonSecondary onClick={() => setShowGifSelector(true)}>
          <CdnSvg src='/images/illustrations/gif.svg' className='mr-2' />

          {I18N('add_gif')}
        </ButtonSecondary>
      )}

      {!_.isEmpty(companyValues) && (
        <CompanyValueList
          companyValues={companyValues}
          selectedCompanyValues={workingCopy.companyValues}
          minSelectableCompanyValues={minSelectableCompanyValues}
          maxSelectableCompanyValues={maxSelectableCompanyValues}
          generateCompanyValueToggler={generateCompanyValueToggler}
        />
      )}

      <Button className='d-block mx-auto my-4' onClick={onSubmit} disabled={missingRequiredInput || isSaving} showLoadingSpinner={isSaving}>
        {workingCopy.id ? I18N('save_shoutout') : I18N('send_shoutout')}
      </Button>
    </div>
  )
}

export default ShoutoutForm
