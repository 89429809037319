import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import MultilineGraph from 'components/analytics/common/multilineGraph'

const I18N = i18nPath('views.admin.article_stats')


const EmailTimeline = ({ articleStat }) => {
  const emailInteractionsByDate = articleStat.emailInteractionsByDate || []
  const currentCompany = useCurrentCompany()
  const { highlightColor, birthdayCardBackgroundColor } = currentCompany.customStyles.variables.colors
  const labels = emailInteractionsByDate.map(counts => counts.date)
  const openedValues = emailInteractionsByDate.map(counts => counts.openedCount)
  const clickedValues = emailInteractionsByDate.map(counts => counts.clickedCount)

  const lines = [{
    label: I18N('labels.email_open'),
    color: highlightColor,
    values: openedValues,
    tooltipLabel: count => `${I18N('labels.email_open')}: ${count}`,
  }, {
    label: I18N('labels.email_click'),
    color: birthdayCardBackgroundColor,
    values: clickedValues,
    tooltipLabel: count => `${I18N('labels.email_click')}: ${count}`,
  }]


  return (
    <Card cardBodyClassName='ArticleStatEmailCard'>
      <h5 className='mb-0 ml-1'>{I18N('email_timeline.title')}</h5>
      <hr />

      <div>
        <MultilineGraph
          xlabels={labels}
          lines={lines}
          height='200px'
        />
      </div>
    </Card>
  )
}

export default EmailTimeline
