import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { trackAmplitudeEvent } from 'services/tracker'
import { i18nPath } from 'utils/i18nHelpers'
import qnaAnswerSlice from 'redux/slices/qnaAnswers'

import Card from 'components/common/card'
import CancelButton from 'components/common/cancelButton'
import { Button } from 'components/common/buttons/button'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { MEDIUM_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'

const I18N = i18nPath('views.qna.questions.question_page')

const AnswerForm = (props) => {
  const { question } = props
  const dispatch = useDispatch()

  const {
    pendingAnswerContent,
    meta: { isLoading },
  } = useSelector(qnaAnswerSlice.selectors.getAnswerFormData())

  const hideAnswerFormHandler = () => dispatch(qnaAnswerSlice.actions.isAnswerFormVisible(false))
  const setAnswerContentHandler = content => dispatch(qnaAnswerSlice.actions.updatePendingAnswerContent(content))
  const createAnswerHandler = () => {
    trackAmplitudeEvent('answer_submitted', {
      eventId: question.event.id,
      title: question.event.title,
      answerable_by: question.event.questionsAnswerableBy,
      questionId: question.id,
    })

    const answerParams = {
      questionId: question.id,
      answer: {
        content: pendingAnswerContent,
      },
    }

    dispatch(qnaAnswerSlice.asyncActions.createAnswer(answerParams, question))
  }

  return (
    <>
      <Card cardBodyClassName='p-0' className={props.className} hasBoxShadow={false} hideBorder={false}>
        <TiptapEditor
          className='TipTapEditor AnswerForm'
          onChange={setAnswerContentHandler}
          html={pendingAnswerContent}
          configuration={{ focusOnInitialize: true }}
          toolbarItems={MEDIUM_TOOLBAR}
          editorContentClassName='p-3'
        />
      </Card>

      <div className='form-group text-right mt-3'>
        <CancelButton onClick={hideAnswerFormHandler} />

        <Button className='ml-3' onClick={createAnswerHandler} disabled={isLoading || !pendingAnswerContent}>
          {I18N('submit_answer')}
        </Button>
      </div>
    </>
  )
}

export default AnswerForm
