import React, { useState } from 'react'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import UserLink from 'components/common/userLink'
import { PageFAQType } from 'types/page/faq'
import RoundedAvatars from 'components/common/roundedAvatars'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import FAQItem from 'components/pages/faqs/faqItem'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { present } from 'components/common/utils'
import CdnSvg from 'components/common/cdnSvg'
import Modal from 'components/common/modal'

const I18N = i18nPath('views.pages.faqs.activity')

type Props = {
  faqId: string
}

const FAQActivity = ({ faqId }: Props) => {
  const { data: faqVersions, isLoading } = useFetch(() => API.pagesFAQs.activity(faqId))

  return (
    <div className='FAQActivity'>
      <h5>
        {I18N('title')}
      </h5>
      {isLoading ? (
        <CirclesLoadingIndicator />
      ) : (
        <div className='d-flex flex-column gap-2'>
          {faqVersions?.map((faq, index) => (
            <ActivityItem
              key={faq.id}
              faq={faq}
              isCreate={index === faqVersions.length - 1}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const ActivityItem = ({ faq, isCreate }: { faq: PageFAQType, isCreate: boolean }) => (
  <div className='d-flex flex-wrap align-items-center gap-1 text-small'>
    {faq.automaticallyGenerated ? (
      <ByBotLine faq={faq} isCreate={isCreate} />
    ) : (
      <ByUserLine faq={faq} isCreate={isCreate} />
    )}
    <span className='text-secondary'>•</span>
    <span className='text-secondary'>{i18nMoment(faq.updatedAt).fromNow()}</span>
    <span className='text-secondary'>•</span>
    <ActivityModalAndLink faq={faq} />
  </div>
)

const ByBotLine = ({ faq, isCreate }: { faq: PageFAQType, isCreate: boolean }) => {
  const sourcesCount = faq.faqSources?.length || 0
  const users = _.uniq(faq.faqSources?.map(fs => fs.user).filter(present) || [])

  return (
    <>
      <CdnSvg src='/images/logos/clearyLogoNoText.svg' className='mr-1' />
      <span>{I18N('cleary_bot')}</span>
      <span className='font-weight-600'>{I18N(isCreate ? 'created_faq' : 'updated_faq')}</span>
      <span>{I18N('based_on_answer', { count: sourcesCount })}</span>
      {users.length <= 1 ? (
        <span><UserLink user={users[0]} customClassName='font-weight-400 text-primary' /></span>
      ) : (
        <RoundedAvatars users={users} avatarSize='22px' />
      )}
      <span className='text-secondary'>{I18NCommon('in')}</span>
      <CdnSvg src='/images/slackLogoIcon.svg' className='SlackLogo' />
    </>
  )
}

const ByUserLine = ({ faq, isCreate }: { faq: PageFAQType, isCreate: boolean }) => (
  <>
    <UserLink customClassName='font-weight-400 text-primary' user={faq.author} />
    <span className='font-weight-600'>{I18N(isCreate ? 'created_faq' : 'updated_faq')}</span>
  </>
)

const ActivityModalAndLink = ({ faq }: { faq: PageFAQType }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className='link-color text-small pointer' onClick={() => setIsOpen(true)}>
        {I18N('view_version')}
      </div>
      <Modal
        visible={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        size='lg'
      >
        <div>
          <FAQItem faq={faq} hasBorder={false} />
        </div>

      </Modal>
    </>
  )
}

export default FAQActivity
