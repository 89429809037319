import React, { MutableRefObject, useEffect, useRef } from 'react'

import NavbarLink from 'components/navbar/navbarLink'
import GlobalSearch from 'components/search/globalSearch'
import { I18NCommon } from 'utils/i18nHelpers'

const MobileLeftNav = ({
  navLinks,
  peopleLinks,
  articleTypes,
  setIsMobileLeftNavOpen,
  inPreboardingExperience,
}) => {
  const mobileNavRef: MutableRefObject<HTMLDivElement | null> = useRef(null)

  const handleClickOutside = (e) => {
    // close navbar when clicking outside the navbar
    if (mobileNavRef.current && !mobileNavRef.current.contains(e.target)) {
      setIsMobileLeftNavOpen(false)
    }
  }

  const handleLinkClick = (e) => {
    // close the nav when a link is clicked.
    const isLinkClick = !!e.target.href

    if (isLinkClick) {
      setIsMobileLeftNavOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    const body = document.body
    body.classList.add('overflow-y-hidden')

    return () => {
      document.removeEventListener('click', handleClickOutside)
      body.classList.remove('overflow-y-hidden')
    }
  }, [])

  const getNewsLinks = () => {
    const newsLinks: React.ReactNode[] = []

    newsLinks.push(
      <NavbarLink key='All' to='/news/all' exact={undefined} onClick={undefined} target={undefined}>
        {I18NCommon('all')}
      </NavbarLink>
    )

    articleTypes.forEach((articleType) => {
      newsLinks.push(
        <NavbarLink key={articleType.name} to={`/news/${articleType.nameParam}`}>
          {articleType.name}
        </NavbarLink>
      )
    })

    return newsLinks
  }

  const newsLinks = getNewsLinks()

  return (
    <div
      className='MobileLeftNav d-flex flex-column px-4 pt-4 align-items-start'
      ref={mobileNavRef}
      onClick={handleLinkClick}
    >
      {!inPreboardingExperience && (
        <div className='w-100'>
          <GlobalSearch />
        </div>
      )}

      {navLinks.map(navlink => (
        <div className='d-flex flex-column w-100' key={navlink.key}>
          <div>{navlink}</div>
          {navlink.key === 'people' && (
            <nav className='Mobile-subnav'>{peopleLinks}</nav>
          )}
          {navlink.key === 'news' && (
            <nav className='Mobile-subnav'>{newsLinks}</nav>
          )}
        </div>
      ))}
    </div>
  )
}

export default MobileLeftNav
