import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import Tag from 'components/common/tag'
import React from 'react'
import { UserType } from 'types/user'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import PageExpirationTextAndModal from 'components/pages/pageExpirationTextAndModal'
import PageType from 'types/page'

const I18N = i18nPath('views.pages')

interface UpdaterAndTimestampProps {
  canEditPage?: boolean
  isEditing: boolean
  areDraftChangesUnpublished: boolean
  page?: PageType
}

const UpdaterAndTimestamp = ({
  canEditPage = false,
  isEditing,
  areDraftChangesUnpublished,
  page,
}: UpdaterAndTimestampProps) => {
  const lastUpdater = page?.lastUpdater
  const updatedAt = page?.updatedAt
  const lastPublishedAt = page?.lastPublishedAt
  const isDraft = page?.draft

  const showLastUpdater = lastUpdater?.username && lastUpdater.username !== 'clearyadmin'
  const showUnpublishedChangesTag = !isDraft && canEditPage && areDraftChangesUnpublished

  // We will show the last published date if the page is not a draft and is not being edited
  const timestamp = (lastPublishedAt && !isDraft && !isEditing) ? lastPublishedAt : updatedAt
  const formattedTimestamp = i18nMoment(timestamp).fromNow()

  return (
    <span className='PageOwnerAndDate mb-4 d-flex flex-wrap align-items-baseline'>
      <div className='mr-2 text-secondary text-small'>
        {showLastUpdater ? (
          I18N('last_updated_by')
        ) : (
          `${I18N('last_updated')} ${formattedTimestamp}`
        )}
      </div>
      {showLastUpdater && (
        <>
          <EmployeeLinkWithPopover user={lastUpdater} />
          <div className='mx-2 text-secondary text-small'>
            {' • '}
            {i18nMoment(timestamp).fromNow()}
          </div>
        </>
      )}
      {!!page && <PageExpirationTextAndModal page={page} isEditing={isEditing} />}
      {showUnpublishedChangesTag && <Tag text={I18N('unpublished_changes')} className='d-none d-sm-block mb-0 mr-0' />}
    </span>
  )
}

export default UpdaterAndTimestamp
