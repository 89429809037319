import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import surveyFormSlice from 'redux/slices/surveys/forms'
import { useHistory } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import SurveyFormType from 'types/surveys/form'

interface SaveFormChangesButtonProps {
  form: SurveyFormType
  workingCopy: Partial<SurveyFormType>
  formState?: string
  disabled?: boolean
  text?: string
  redirectPath?: string | null
}

export const SaveFormChangesButton = ({
  form,
  workingCopy,
  formState = 'draft',
  disabled = false,
  text = '',
  redirectPath = null,
} : SaveFormChangesButtonProps) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { isSaving } = useSelector(surveyFormSlice.selectors.getMetaData())

  const onSaveChanges = () => {
    const newWorkingCopy = {
      ...workingCopy,
      state: formState,
    }

    const onSuccess = () => {
      if (redirectPath) {
        history.replace(redirectPath)
      }
    }

    dispatch(surveyFormSlice.asyncActions.admin.update(newWorkingCopy, form, onSuccess))
  }

  return (
    <Button
      className='mr-2'
      disabled={isSaving || disabled}
      showLoadingSpinner={isSaving}
      onClick={onSaveChanges}
    >
      {text}
    </Button>
  )
}

export default SaveFormChangesButton
