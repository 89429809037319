import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.user_skill_types')

const UserSkillTypeForm = ({ userSkillType, setUserSkillType }) => {
  const handleChange = (fieldName, value) => {
    setUserSkillType({
      ...userSkillType,
      [fieldName]: value,
    })
  }

  const generateChangeHandler = fieldName => (e) => {
    handleChange(fieldName, e.target.value)
  }

  return (
    <div className='w-100'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='form-group col-lg-7 col-md-12'>
              <label className='required-form-label' htmlFor='name'>{I18N('form.name')}</label>
              <input id='name' value={userSkillType.name} onChange={generateChangeHandler('name')} />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='form-group col-lg-7 col-md-12'>
              <label className='required-form-label' htmlFor='slug'>{I18N('form.slug')}</label>
              <input id='slug' value={userSkillType.slug} onChange={generateChangeHandler('slug')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSkillTypeForm
