import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Prompt, useLocation } from 'react-router-dom'
import navigationBlockerSlice from 'redux/slices/navigationBlocker'
import { I18NCommon } from 'utils/i18nHelpers'

type Props = {
  isBlocked: boolean,
  allowChangingQueryParams?: boolean,
}


const InnerNavigationBlocker = ({
  isBlocked: isBlockedProp,
  allowChangingQueryParams = false,
}: Props) => {
  const location = useLocation()
  const skipBlocking = useSelector(navigationBlockerSlice.selectors.skipBlocking())
  const isBlocked = skipBlocking ? false : isBlockedProp

  useEffect(() => {
    if (!isBlocked) return () => {}

    const beforeUnloadHandler = (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    }

    addEventListener('beforeunload', beforeUnloadHandler)

    return () => {
      removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [isBlocked])

  return (
    <Prompt
      when={isBlocked}
      message={(newLocation) => {
        if (allowChangingQueryParams && newLocation.pathname === location.pathname) {
          return true
        }

        return I18NCommon('blocked_navigation_prompt_message')
      }}
    />
  )
}

const NavigationBlocker = (props: Props) => {
  // disable navigation blocker using `DISABLE_NAVIGATION_BLOCKER=true ./bin/shakapacker-dev-server`
  if (process.env.DISABLE_NAVIGATION_BLOCKER) { return null }

  return <InnerNavigationBlocker {...props} />
}

export default NavigationBlocker
