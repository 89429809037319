import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import qnaEvents from 'redux/slices/qnaEvents'
import { i18nPath } from 'utils/i18nHelpers'

import LockedEventIcon from 'components/icons/qna/lockedEventIcon'
import { ButtonSmall } from 'components/common/buttons'

const UnauthorizedEvent = ({ eventId }) => {
  const I18N = i18nPath('views.qna.events.unauthorized')

  const dispatch = useDispatch()

  const meta = useSelector(qnaEvents.selectors.getEventMetaData)

  const { isSaving } = meta

  useEffect(() => () => dispatch(qnaEvents.actions.isUnauthorized(false)), [])

  const requestInvite = () => {
    dispatch(qnaEvents.asyncActions.requestInvite(eventId))
  }

  return (
    <div className='container mt-5'>
      <div className='row pt-5'>
        <div className='col-md-12 d-flex flex-column align-items-center'>
          <LockedEventIcon className='mb-4' />
          <h3 className='mb-3'>{I18N('header')}</h3>
          {/* at least for english, break text into two lines */}
          <p className='text-secondary text-center' style={{ maxWidth: '400px' }}>
            {I18N('sub_header')}
          </p>
          <p>
            <ButtonSmall onClick={() => requestInvite()} disable={isSaving}>{I18N('request_invite')}</ButtonSmall>
          </p>
        </div>
      </div>
    </div>
  )
}

export default UnauthorizedEvent
