import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import qnaEventSlice from 'redux/slices/qnaEvents'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import { trackAmplitudeEvent, trackEvent } from 'services/tracker'

import CreateEventModal from 'components/qna/event_creation_steps/createEventModal'
import HighlightedEvent from 'components/qna/highlightedEvent'
import PillTabSelector from 'components/common/pillTabSelector'
import EventsGrid from 'components/qna/eventsGrid'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import { Button } from 'components/common/buttons'
import RecentQuestions from 'components/qna/recentQuestions'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.qna.events')

const PageTitle = ({
  eventDisplayName = I18N('events'),
  isEventCreator,
  onClickCreateEventButton,
}) => (
  <div className='row'>
    <div className='col-md-12'>
      <div className='d-flex justify-content-between'>
        <h1>{eventDisplayName || I18N('events')}</h1>
        {isEventCreator && (
          <Button onClick={onClickCreateEventButton}>
            {I18N('create_new_event')}
          </Button>
        )}
      </div>
    </div>
  </div>
)

const FilterTabs = ({
  handleFilterSelection,
}) => (
  <div className='row mt-4'>
    <div className='EventsListPageFilters position-relative col-md-12'>
      <PillTabSelector
        tabs={[
          {
            text: I18N('events_filters.all'),
            onClick: () => {
              handleFilterSelection('all')
            },
          },
          {
            text: I18N('events_filters.shared_with_me'),
            onClick: () => {
              handleFilterSelection('shared_with_me')
            },
          },
          {
            text: I18N('events_filters.created_by_me'),
            onClick: () => {
              handleFilterSelection('created_by_me')
            },
          },
          {
            text: I18N('events_filters.archived'),
            onClick: () => {
              handleFilterSelection('archived')
            },
          },
        ]}
      />
    </div>
  </div>
)

const EventsListPage = ({ showCreateFlow = false }) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const [filter, setFilter] = useState('all')
  const [showCreateEventModal, setShowCreateEventModal] = useState(showCreateFlow)

  const {
    highlightedEvent, events, topQuestion, meta,
  } = useSelector(qnaEventSlice.selectors.getEventListData())

  const {
    isHighlightedEventLoading,
    isLoadingEvents,
    queryParams,
    eventsLoaded,
  } = meta

  const recentQuestions = useSelector(qnaQuestionSlice.selectors.getRecentQuestions())
  const isLoadingRecentQuestions = useSelector(qnaQuestionSlice.selectors.getEventsListPageData())
  const isCentered = !isLoadingRecentQuestions && _.isEmpty(recentQuestions)
  const isEventCreator = currentUser.permissions.eventCreator
  const showEntirePageLoadingIndicator = isLoadingRecentQuestions || isHighlightedEventLoading

  const fetchMoreEvents = () => {
    dispatch(qnaEventSlice.asyncActions.fetchAll({ ...queryParams, page: queryParams.page + 1 }))
  }

  const handleFilterSelection = (filter) => {
    setFilter(filter)
    dispatch(qnaEventSlice.asyncActions.fetchAll({ page: 1, perPage: 18, filter }))
  }

  useEffect(() => {
    dispatch(qnaEventSlice.asyncActions.fetchHighlightedEvent())
    dispatch(qnaEventSlice.asyncActions.fetchAll(queryParams))
    dispatch(qnaQuestionSlice.asyncActions.fetchRecentQuestions())
    trackEvent('qna_event:view_all')

    return () => {
      dispatch(qnaEventSlice.actions.clearEventsListPageData())
      dispatch(qnaQuestionSlice.actions.clearEventsListPageData())
    }
  }, [])

  const onClickCreateEventButton = () => {
    trackAmplitudeEvent('create_new_event_clicked', { company_event_count: events.length })
    setShowCreateEventModal(true)
  }

  return (
    <>
      <div className='EventsListPage container mt-5'>
        {showEntirePageLoadingIndicator
          ? <LoadingIndicator />
          : (
            <div className='row'>
              <div className={classNames('col-md-9',
                isCentered ? 'offset-md-1-5' : '')}
              >
                <PageTitle
                  isEventCreator={isEventCreator}
                  eventDisplayName={currentCompany.eventDisplayName}
                  onClickCreateEventButton={onClickCreateEventButton}
                />

                {highlightedEvent && (
                  <div className='row mt-4 mb-6'>
                    <div className='col-md-12'>
                      <HighlightedEvent
                        event={highlightedEvent}
                        topQuestion={topQuestion}
                      />
                    </div>
                  </div>
                )}

                <FilterTabs handleFilterSelection={handleFilterSelection} />

                <EventsGrid
                  events={events}
                  isLoadingEvents={isLoadingEvents}
                  filter={filter}
                  queryParams={queryParams}
                  fetchMoreEvents={fetchMoreEvents}
                  onClickCreateEvent={onClickCreateEventButton}
                  isEventCreator={isEventCreator}
                />
              </div>
              {!isCentered && (<div className='col-md-3'><RecentQuestions /></div>)}
            </div>
          )}
      </div>
      <CreateEventModal
        showModal={showCreateEventModal}
        onCloseModal={() => setShowCreateEventModal(false)}
      />
    </>
  )
}

export default EventsListPage
