import React, { useEffect, useState } from 'react'
import Modal from 'components/common/modal'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import SmartTable from 'components/common/tables/smartTable'
import TruncatedContent from 'components/common/truncatedContent'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import { useDispatch, useSelector } from 'react-redux'
import articleStatsSlice from 'redux/slices/articleStats'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.article_stats.detail_user_actions')
const chevronDownIconPath = '/images/chevronDownOutline.svg'
const chevronUpIconPath = '/images/chevronUpOutline.svg'

type ClicksProps = {
  clicks: any
  onExpand: () => void
  onCollapse: () => void
}

const Clicks = ({ clicks, onCollapse, onExpand }: ClicksProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const clicksLength = clicks.length

  const toggleExpanded = () => {
    if (!isExpanded) {
      onExpand()
    } else {
      onCollapse()
    }
    setIsExpanded(!isExpanded)
  }

  const columns = [{
    header: I18N('email_click_timestamp'),
    accessor: d => i18nMoment(d.time).format('LLL'),
  }, {
    header: I18N('links_clicked'),
    accessor: d => (
      <a href={d.url} target='_blank' rel='noopener noreferrer'>
        <TruncatedContent tooltipSuffix={d.time}>{d.url}</TruncatedContent>
      </a>
    ),
  }]

  return (
    <div className='email-detail-user-clicks'>
      <span onClick={toggleExpanded} role={clicksLength > 0 ? 'button' : undefined}>
        {clicksLength}
        {clicksLength > 0 && (
          <CdnSvg className='ml-1 chevronIcon' src={isExpanded ? chevronUpIconPath : chevronDownIconPath} />
        )}
      </span>

      {clicksLength > 0 && isExpanded && (
        <SmartTable
          className='white-bg-table mb-0'
          data={clicks}
          columns={columns}
        />
      )}
    </div>
  )
}

interface DetailUserActionsModalProps {
  user: {
    id: string
    preferredFullName: string
  },
  articleId: string,
  onClose: () => void,
}

const DetailUserActionsModal = ({ articleId, user, onClose }: DetailUserActionsModalProps) => {
  const dispatch = useDispatch()
  const [expandedRows, setExpandedRows] = useState<any[]>([])
  const { isLoadingEmailOpens } = useSelector(articleStatsSlice.selectors.getMetaData())
  const emailOpens = useSelector(articleStatsSlice.selectors.getEmailOpens())

  useEffect(() => {
    dispatch(articleStatsSlice.asyncActions.fetchEmailOpens(articleId, user.id))
  }, [])

  const columns = [{
    header: I18N('opens'),
    accessor: d => i18nMoment(d.time).format('LLL'),
    style: { width: '35%' },
  }, {
    header: I18N('clicks'),
    accessor: d => (
      <Clicks
        clicks={d.clicks}
        onExpand={() => setExpandedRows([...expandedRows, d])}
        onCollapse={() => setExpandedRows(expandedRows.filter(r => r !== d))}
      />
    ),
    style: { width: '65%' },
  }]

  const rowClassName = d => (expandedRows.includes(d) ? 'is-expanded' : '')

  return (
    <Modal
      visible
      toggle={onClose}
      size='lg'
      title={I18N('title', { user_name: user.preferredFullName })}
      modalTitleClassName='h4 pl-2'
    >
      <TableLoadingContainer isLoading={isLoadingEmailOpens}>
        <SmartTable
          className='white-bg-table email-detail-user-actions-table'
          data={emailOpens}
          columns={columns}
          rowClassName={rowClassName}
        />
      </TableLoadingContainer>
    </Modal>
  )
}

export default DetailUserActionsModal
