import React from 'react'
import { Radio, RadioGroup } from 'react-radio-group'

import FormCheck from 'components/form_fields/formCheck'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.step_templates')

const WeekendTriggerOptions = ({
  workingCopy,
  updateWorkingCopy,
  fieldName = 'weekendTriggerShift',
}) => {
  const fieldValue = workingCopy[fieldName]

  const onChange = ({ target }) => {
    if (target.checked) {
      updateWorkingCopy({ [fieldName]: 'next_weekday' })
    } else {
      updateWorkingCopy({ [fieldName]: null })
    }
  }

  return (
    <>
      <FormCheck
        name={_.snakeCase(fieldName)}
        label={I18N('only_weekdays')}
        checked={!!fieldValue}
        onChange={onChange}
        id={_.snakeCase(fieldName)}
        className='mb-3'
      />

      {!!fieldValue && (
        <>
          <p className='text-small text-secondary mb-0'>{I18N('only_weekdays_label')}</p>
          <RadioGroup
            className='d-flex align-items-center justify-content-start'
            name={_.snakeCase(fieldName)}
            selectedValue={fieldValue}
            onChange={(value: string) => updateWorkingCopy({ [fieldName]: value })}
          >
            <Radio className='mr-2' value='next_weekday' /> {I18N('next')}
            <Radio className='mr-2 ml-3' value='previous_weekday' /> {I18N('previous')}
          </RadioGroup>
        </>
      )}
    </>
  )
}

export default WeekendTriggerOptions
