import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import FollowersLinkAndModal from 'components/groups/followersLinkAndModal'
import EditFollowersModal from 'components/groups/editFollowersModal'
import { ButtonSecondary } from 'components/common/buttons'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.groups.group_page')
const peopleIconPath = '/images/personsIcon.svg'
const pencilIconPath = '/images/pencilIcon.svg'
const womanWithSpyglassOneCirclePath = '/images/illustrations/womanWithSpyglassOneCircle.svg'

const ViewFollowers = ({ groupId }) => (
  <div className='d-flex justify-content-center align-items-center py-1 mt-3'>
    <CdnSvg src={peopleIconPath} className='GroupIcon mr-1' />
    <FollowersLinkAndModal groupId={groupId} />
  </div>
)

const EditFollowers = ({ groupId, onClickLink }) => (
  <div className='d-flex justify-content-center align-items-center py-1 mt-3'>
    <CdnSvg src={pencilIconPath} className='GroupIcon mr-1' />
    <a href='' onClick={onClickLink} className='py-1'>
      {I18N('edit_followers')}
    </a>
  </div>
)

const EmptyState = ({ canEditFollowers = false, onClickAddFollowers }) => (
  <div className='d-flex flex-column align-items-center'>
    <CdnSvg className='spyglass-image' src={womanWithSpyglassOneCirclePath} />
    <span className='text-secondary mt-3 text-center'>{I18N(canEditFollowers ? 'no_followers_editable' : 'no_followers')}</span>
    {canEditFollowers && <ButtonSecondary onClick={onClickAddFollowers} className='mt-3'>{I18N('add_followers')}</ButtonSecondary>}
  </div>
)

// a comma separated list of links to the groups who follow this group
const GroupFollowers = ({ groupFollowers }) => (
  <div>
    {groupFollowers
      .map((groupFollower, index) => (<Link key={index} to={groupFollower.displayPath}>{groupFollower.name}</Link>))
      .reduce((list, element) => (list === null ? element : <>{list}, {element}</>), null)}
  </div>
)


const Followers = ({ group }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    activeUserFollowerIds = [],
    activeGroupFollowers = [],
    permittedActions,
  } = group
  const canEditFollowers = permittedActions.editFollowers
  const canFollow = permittedActions.follow
  const hasUserFollowers = activeUserFollowerIds.length > 0
  const hasGroupFollowers = activeGroupFollowers.length > 0
  const hasFollowers = hasUserFollowers || hasGroupFollowers
  const showTitles = hasGroupFollowers && hasUserFollowers

  const handleLinkClick = (e) => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  if (!canFollow) {
    return null
  }

  return (
    <section className='GroupFollowers mb-4'>
      <Card>
        <h6>{I18N('followers')}</h6>
        <div className={classNames('d-flex flex-column', { 'border-bottom pb-4': hasFollowers })}>
          {!hasFollowers && <EmptyState canEditFollowers={canEditFollowers} onClickAddFollowers={handleLinkClick} />}
          {showTitles && <span className='text-secondary my-2'>{I18N('teams')}</span>}
          {hasGroupFollowers && <GroupFollowers groupFollowers={activeGroupFollowers} />}
          {showTitles && <span className='text-secondary mt-3 mb-2'>{I18N('people')}</span>}
          {hasUserFollowers && <RoundedAvatarsWithAsyncUserFetching userIds={activeUserFollowerIds} />}
        </div>
        {hasFollowers && !canEditFollowers && <ViewFollowers groupId={group.id} />}
        {hasFollowers && canEditFollowers && <EditFollowers groupId={group.id} onClickLink={handleLinkClick} />}
      </Card>
      {isModalOpen && <EditFollowersModal onClose={handleClose} groupId={group.id} />}
    </section>
  )
}

export default Followers
