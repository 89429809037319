import { ButtonSmallNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import useApi from 'components/common/hooks/useApi'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { AsyncSelect } from 'components/common/react_select'
import React, { useCallback, useState } from 'react'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.links')

type Props = {
  link: any
  onSave: (link: any) => void
  isSaving?: boolean
  onCancel: () => void
  labelClassName?: string
  saveButtonVariant?: 'primary' | 'secondary'
}

const LinkForm = ({
  link,
  onSave,
  onCancel,
  labelClassName = '',
  saveButtonVariant = 'primary',
  isSaving = false,
}: Props) => {
  const getOptions = useGetOptions()

  const [name, setName] = useState(link.name || '')
  const [url, setUrl] = useState(link.url || '')
  const [recordId, setRecordId] = useState(link.recordId)
  const [recordType, setRecordType] = useState(link.recordType)
  const [recordDisplayName, setRecordDisplayName] = useState(link.recordId ? link.displayName : '')
  const [recordDisplayUrl, setRecordDisplayUrl] = useState(link.recordId ? link.displayUrl : '')

  const isUrl = url?.startsWith('http://') || url?.startsWith('https://') || url?.startsWith('www.')

  const isLinkValid = () => {
    if (isUrl) {
      return !!(url && name)
    } else {
      return !!(recordId && recordType)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (isUrl) {
      onSave({
        id: link.id,
        name,
        url,
        recordId: null,
        recordType: null,
        displayName: name,
        displayUrl: url,
      })
    } else {
      onSave({
        id: link.id,
        name: null,
        url: null,
        recordId,
        recordType,
        displayName: recordDisplayName,
        displayUrl: recordDisplayUrl,
      })
    }
  }

  const { appName } = useCurrentCompany()

  return (
    <form
      className='LinkForm d-flex flex-column gap-3 mt-3'
      onSubmit={handleSubmit}
    >
      <div>
        <label className={labelClassName}>
          {I18N('url_or_search', { appName })}
        </label>
        <AsyncSelect
          value={isUrl ? { value: url, label: url } : { value: `${recordType}-${recordId}`, label: recordDisplayName }}
          onChange={(val) => {
            if (!isUrl) {
              setRecordId(val.value.id)
              setRecordType(val.value.type)
              setRecordDisplayName(val.label)
              setRecordDisplayUrl(val.value.displayUrl)
            }
          }}
          inputValue={url}
          onInputChange={setUrl}
          onMenuClose={() => {
            // this is a workaround because when this component is blurred onInputChange is called
            // with '' which would remove the url
            // onMenuClose is called immediately after, but before the state has updated
            // so it resets the input value to url
            if (isUrl) setUrl(url)
          }}
          components={{
            DropdownIndicator: null,
            IndicatorSeparator: null,
          }}
          menuIsOpen={isUrl ? false : undefined}
          getOptions={isUrl ? undefined : getOptions}
          autoFocus
        />
      </div>

      {isUrl && (
        <div>
          <label className={labelClassName}>
            {I18N('name')}
          </label>
          <input
            className='w-100'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
      )}

      <div className='w-100 d-flex align-items-center justify-content-end'>
        <CancelButton onClick={onCancel} />
        <ButtonSmallNarrow
          variant={saveButtonVariant}
          type='submit'
          disabled={isSaving || !isLinkValid()}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('save')}
        </ButtonSmallNarrow>
      </div>
    </form>
  )
}

const useGetOptions = () => {
  const [search] = useApi(API.globalSearch)

  const getOptions = useCallback(
    _.debounce(async (inputValue, callback) => {
      const results = await search(inputValue)

      const filteredResults = results.filter(result => ['article', 'page', 'go_link'].includes(result.type))
      const groupedResults = _.groupBy(filteredResults, 'type')

      const pages = groupedResults.page?.map(r => ({ value: { id: r.page.id, type: 'Page', displayUrl: `${window.location.origin}${r.displayPath}` }, label: r.page.title }))
      const articles = groupedResults.article?.map(r => ({ value: { id: r.article.id, type: 'Article', displayUrl: `${window.location.origin}${r.displayPath}` }, label: r.article.title }))
      const goLinks = groupedResults.go_link?.map(r => ({ value: { id: r.goLink.id, type: 'GoLink', displayUrl: r.goLink.url }, label: `go/${r.goLink.name} ${r.goLink.displayName ? ` (${r.goLink.displayName})` : ''}` }))

      const finalResults = [
        ..._.isEmpty(pages) ? [] : [{ label: I18N('pages_label'), options: pages }],
        ..._.isEmpty(articles) ? [] : [{ label: I18N('articles_label'), options: articles }],
        ..._.isEmpty(goLinks) ? [] : [{ label: I18N('go_links_label'), options: goLinks }],
      ]

      callback(finalResults)
    }, 300), [])

  return getOptions
}

export default LinkForm
