import React from 'react'
import classNames from 'classnames'
import { AsyncSelect } from 'components/common/react_select'

import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

import API from 'services/api'

const GolinkSearchSelect = ({
  className, filter, selectedGolinks, autoFocus, onBlur, onChange,
  onFocus, clearable, placeholder, useGoLinksBranding = true,
}) => {
  const selectValue = null

  const golinkOption = ({ name, displayName, id }) => {
    const label = useGoLinksBranding ? `go/${name}` : displayName

    return {
      label,
      value: name,
      id,
      name,
    }
  }

  const getOptions = (inputValue, callback) => {
    // If the user is searching for a golink starting with go/ remove this
    // from the search value
    if (inputValue.startsWith('go/')) {
      inputValue = inputValue.replace('go/', '')
    }

    API.golinks.fetchAll({ ...filter, q: inputValue }).then((response) => {
      const golinks = denormalizedJsonApiResponse(response, 'goLink')

      callback(golinks.map(golinkOption))
    })
  }

  const filterOptions = (options) => {
    const selectedGolinks = selectValue

    if (!_.isEmpty(selectedGolinks)) {
      const selectedGolinksIds = selectedGolinks.map(goLink => goLink.id)
      return options.filter(goLink => !selectedGolinksIds.includes(goLink.id))
    } else {
      return options
    }
  }

  const conditionalProps = {}

  return (
    <div className={classNames('GolinkSearchSelect', className)}>
      <AsyncSelect
        id='cy_featured_golinks_search_select'
        filterOptions={filterOptions}
        name='form-field-name'
        autoload={false}
        value={selectValue}
        onChange={onChange}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        getOptions={getOptions}
        minCharsToSearch={2}
        isClearable={clearable || false}
        placeholder={placeholder || I18n.t('views.golink_search.placeholder')}
        isMulti={!!selectedGolinks}
        {...conditionalProps}
      />
    </div>
  )
}

export default GolinkSearchSelect
