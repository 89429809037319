import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import ErrorSavingBuddyResponsePage from './errorSavingBuddyResponsePage'

const I18N = i18nPath('views.journeys.onboarding_buddies')

const DoNotWantOnboardingBuddyPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { error, isLoading } = useSelector(onboardingBuddySlice.selectors.getMetaData())

  const handleBackToHomeClick = () => {
    history.push('/')
  }

  useEffect(() => {
    dispatch(onboardingBuddySlice.asyncActions.doNotWantOnboardingBuddy())
  }, [dispatch])

  if (isLoading) {
    return <CirclesLoadingIndicator className='mt-4' />
  }

  if (error) {
    return <ErrorSavingBuddyResponsePage />
  }

  return (
    <div className='DoNotWantOnboardingBuddyPage d-flex align-items-center justify-content-center'>
      <div className='container d-flex flex-column align-items-center justify-content-center'>
        <h4 className='text-center my-4'>{I18N('response_saved')}</h4>
        <div className='text-center mb-4 text-secondary'>{I18N('do_not_want_onboarding_buddy_confirmation')}</div>
        <Button onClick={handleBackToHomeClick}>{I18N('back_to_home')}</Button>
      </div>
    </div>
  )
}

export default DoNotWantOnboardingBuddyPage
