import React, { useEffect, useState } from 'react'
import userSlice from 'redux/slices/users'
import { useDispatch } from 'react-redux'
import { I18NCommon } from 'utils/i18nHelpers'


const ToggleUserActiveFlagButton = ({ userId, active }) => {
  const dispatch = useDispatch()
  const [isUserActive, setIsUserActive] = useState(active)

  useEffect(() => {
    setIsUserActive(active)
  }, [active])

  const onClick = () => {
    const newActiveState = !isUserActive
    if (confirm(I18NCommon(newActiveState ? 'activate_user_confirm' : 'deactivate_user_confirm'))) {
      const updatedUser = { id: userId, active: newActiveState }
      dispatch(userSlice.asyncActions.admin.updateUser(updatedUser))
      setIsUserActive(newActiveState)
    }
  }

  return (
    <a onClick={onClick}>{isUserActive ? I18NCommon('deactivate') : I18NCommon('activate')}</a>
  )
}

export default ToggleUserActiveFlagButton
