/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react'
import * as _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { isOrgChartNodeHighlighted } from 'utils/orgChart'
import Tree from 'react-d3-tree'
import EmployeeNode from 'components/org_chart/employeeNode'
import userSlice from 'redux/slices/users'

// This is necessary to render HTML inside SVG: using `foreignObject`,
// which wraps the HTML tags to allow for them to be injected into the SVG namespace.
const CustomSVGNode = ({
  foreignObjectProps,
  hierarchyPointNode,
  isOrientationVertical,
  nodeDatum,
  onNodeClick,
  toggleNode,
  username,
  nodeRef,
  addChildrenToOrgChart,
  addChildren,
  findNodeChildren,
}) => {
  const dispatch = useDispatch()
  const employee = { ...nodeDatum }
  const isHighlighted = isOrgChartNodeHighlighted(hierarchyPointNode, username)
  const isSelected = nodeDatum.u === username
  const fetchingUserIds = useSelector(userSlice.selectors.getFetchingUserIds())
  const users = useSelector(userSlice.selectors.getSimpleUsersByIds([employee.i]))
  const children = useSelector(userSlice.selectors.getSimpleUsersByIds(employee.children.map(c => c.i)))
  const user = users[0]
  const isNodeCollapsed = (nodeDatum.c !== nodeDatum.children.length)
    || (nodeDatum.__rd3t.collapsed && nodeDatum.c === nodeDatum.children.length)

  const expandPathToSelectedNode = () => {
    if (isSelected) {
      let parent = hierarchyPointNode.parent
      while (!_.isEmpty(parent)) {
        Tree.expandNode(parent.data)
        parent = parent.parent
      }
    }
  }

  // On mount, expand all the parents above the currently selected node
  // this allows an expanded tree after clicking "view on org chart" from the profile page
  useEffect(() => {
    expandPathToSelectedNode()
  }, [])

  // we need to monitor the changing employee ID here because the underlying D3 tree library will sometimes
  // reuse nodes and the underlying employee ID changes, we need to potentially fetch a new user
  useEffect(() => {
    const isParentCollapsed = !!hierarchyPointNode?.parent?.data?.__rd3t.collapsed // eslint-disable-line no-underscore-dangle
    if (_.isEmpty(user) && !isParentCollapsed && !_.includes(fetchingUserIds, employee.i)) {
      dispatch(userSlice.asyncActions.simpleFetchAll(
        [employee.i, ...employee.children.map(c => c.i)], () => { }, { includeHiddenUsers: true }))
    }
  }, [employee.i])

  const onToggle = () => {
    if (children.length !== employee.children.length) {
      dispatch(userSlice.asyncActions.simpleFetchAll(
        employee.children.map(c => c.i), () => { }, { includeHiddenUsers: true }))
    }

    if (nodeDatum.children.length !== nodeDatum.c) {
      addChildrenToOrgChart(nodeDatum, () => toggleNode())

      const childrenData = findNodeChildren(nodeDatum)
      addChildren(childrenData.map(oc => ({ ...oc, children: [] })))
    } else {
      toggleNode()
    }
  }

  // render an SVG node containing a foreignObject needed by react-d3-tree
  // foreignObject needs the width and height explicitly set (provided by foreignObjectProps)
  return (
    <g>
      <foreignObject {...foreignObjectProps}>
        <EmployeeNode
          key={employee.i}
          childrenCount={nodeDatum.c}
          employeeId={employee.i}
          isCollapsed={isNodeCollapsed}
          isOrientationVertical={isOrientationVertical}
          isHighlighted={isHighlighted}
          isSelected={isSelected}
          toggleNode={onToggle}
          onNodeClick={onNodeClick}
          nodeRef={isSelected ? nodeRef : null}
        />
      </foreignObject>
    </g>
  )
}

export default CustomSVGNode
