import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import StatusLabel from 'components/common/status_label'

const I18N = i18nPath('views.admin.surveys')

const FormStatusLabel = ({ className = '', status }) => {
  const getStatusColor = () => {
    switch (status) {
    case 'live':
      return 'green'
    case 'closed':
      return 'blue'
    case 'scheduled':
      return 'yellow'
    default:
      return 'gray'
    }
  }

  return <StatusLabel className={className} color={getStatusColor()}>{I18N(`status.${status}`)}</StatusLabel>
}

export default FormStatusLabel
