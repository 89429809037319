import React, { ReactElement } from 'react'
import { PropertyListValue, getPropertyListWithValues } from 'components/admin/customStyles/utils'
import { useVariables, useCustomStyles } from 'pages/admin/custom_styles/context'
import BorderWidthControl from './borderWidthControl'

interface Props {
  borderWidthVariable: string
}

const BorderWidth = ({ borderWidthVariable }: Props): ReactElement => {
  const { handlePropertyChanged } = useCustomStyles()
  const border = useVariables('border')
  const friendlyLabels = { profileAwardedBadgeBorderWidth: 'awarded_badge_border' }
  const defaultValues = { profileAwardedBadgeBorderWidth: '1px' }

  const [variable, value] = getPropertyListWithValues({ variables: border, type: 'border' }).find(
    ([variable, _]) => variable === borderWidthVariable
  ) as PropertyListValue

  return (
    <div className='row'>
      <div className='col-12'>
        <div>
          <BorderWidthControl
            key={variable}
            variable={variable}
            value={value}
            onChange={handlePropertyChanged}
            defaultValues={defaultValues}
            friendlyLabels={friendlyLabels}
          />
        </div>
      </div>
    </div>
  )
}

export default BorderWidth
