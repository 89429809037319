import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import queryParamsFromHeaders, { defaultPaginationParams, PaginationParams } from 'utils/queryParamsFromHeaders'
import { ReduxState } from 'redux/redux'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { checkForError } from 'utils/errorHandling'
import normalizeTargetingRules, { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import buildNestedAttributesPayload from 'redux/slices/utils/buildNestedAttributesPayload'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { showToastMessage } from 'redux/slices/toasts'

const I18N = i18nPath('views.admin.surveys')

export const initialState = {
  formIds: [],
  usersWhoHaveNotRespondedIds: [],
  availableTemplates: [],
  activeFormId: null,
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams,
      perPage: 10,
    },
    usersWhoHaveNotRespondedQueryParams: {
      ...defaultPaginationParams,
      perPage: 10,
    },
    isLoadingNotRespondedUsers: false,
    isExportingCsv: false,
    isLoadingTemplates: false,
  },
}

export const defaultWorkingCopy = (name = '') => ({
  name,
  anonymousResponses: false,
  questionsRequiredByDefault: true,
  startTime: '',
  endTime: '',
  state: 'draft',
  targetingRules: TARGET_ENTIRE_COMPANY,
  questions: [],
  subject: I18N('default_subject', { name }),
  instructions: I18N('default_instructions'),
})

export interface SurveyFormsState {
  activeFormId: string | null,
  formIds: string[],
  availableTemplates: {name: string, id: string}[],
  meta: DefaultMetaType & {
    queryParams: PaginationParams,
    isExportingCsv: boolean,
    usersWhoHaveNotRespondedQueryParams: PaginationParams
    isLoadingNotRespondedUsers: boolean
    isLoadingTemplates: boolean
  },
}

const buildOptionPayload = (option: any) => _.pick(option, [
  'id',
  'value',
  'order',
  'orderPosition',
])

const buildQuestionPayload = (question: any, previousQuestion: any = null) => {
  if (!question) {
    return null
  }

  const payload = _.pick<any, any>(question, [
    'id',
    'type',
    'title',
    'config',
    'required',
    'orderPosition',
    'config',
    'allowAdditionalText',
    'profileField',
  ])

  payload.optionsAttributes = buildNestedAttributesPayload(question?.options, previousQuestion?.options, buildOptionPayload, 'value')

  return payload
}

export const buildFormPayload = (form: any, previousForm: any = null) => {
  if (!form) {
    return null
  }

  const payload = _.pick<any, any>(form, [
    'id',
    'name',
    'anonymousResponses',
    'questionsRequiredByDefault',
    'startTime',
    'endTime',
    'state',
    'targetingRules',
    'subject',
    'instructions',
    'notifyAll',
    'showOnHomePage',
    'customNotification',
    'templateId',
  ])

  if (payload.targetingRules) {
    payload.targetingRules = normalizeTargetingRules(payload.targetingRules)
  }

  // eslint-disable-next-line max-len
  payload.questionsAttributes = buildNestedAttributesPayload(form.questions, previousForm?.questions, buildQuestionPayload)


  if (form?.notificationChannels) {
    payload.notificationChannelIds = form.notificationChannels.map(({ id }) => id)
  }

  return payload
}

const surveyFormSlice = createSlice({
  name: 'surveyForms',
  initialState,
  reducers: {
    ...defaultActions,
    setFormIds(state, action) {
      state.formIds = action.payload
    },

    clearFormIds(state) {
      state.formIds = []
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    isExportingCsv(state, action) {
      state.meta.isExportingCsv = action.payload
    },

    setActiveFormId(state, action) {
      state.activeFormId = action.payload
    },

    clearActiveFormId(state) {
      state.activeFormId = null
    },

    setNotRespondedUserIds(state, action) {
      state.usersWhoHaveNotRespondedIds = action.payload
    },

    clearNotRespondedUserIds(state) {
      state.usersWhoHaveNotRespondedIds = []
    },

    setNotRespondedUserQueryParams(state, action) {
      state.meta.usersWhoHaveNotRespondedQueryParams = {
        ...state.meta.usersWhoHaveNotRespondedQueryParams,
        ...action.payload,
      }
    },

    isLoadingNotRespondedUsers(state, action) {
      state.meta.isLoadingNotRespondedUsers = action.payload
    },

    setAvailableTemplates(state, action) {
      state.availableTemplates = action.payload
    },

    isLoadingTemplates(state, action) {
      state.meta.isLoadingTemplates = action.payload
    },
  },
})

const asyncActions = {
  admin: {
    fetchAll: (params = {}) => async (dispatch) => {
      dispatch(surveyFormSlice.actions.clearFormIds())
      dispatch(surveyFormSlice.actions.isLoading(true))

      try {
        const response = await API.admin.surveyForms.fetchAll(params)

        const newQueryParams = queryParamsFromHeaders(response)
        const formIds = response.data.data.map(surveyForm => surveyForm.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(surveyFormSlice.actions.setFormIds(formIds))
        dispatch(surveyFormSlice.actions.setQueryParams(newQueryParams))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(surveyFormSlice.actions.isLoading(false))
      }
    },

    fetch: (id: string, queryParams = {}) => async (dispatch) => {
      dispatch(surveyFormSlice.actions.isLoading(true))

      try {
        const response = await API.admin.surveyForms.fetch(id, queryParams)

        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(surveyFormSlice.actions.setError(error))
      } finally {
        dispatch(surveyFormSlice.actions.isLoading(false))
      }
    },

    create: (data: any, onSuccess = (id: string) => {}) => async (dispatch) => {
      dispatch(surveyFormSlice.actions.isSaving(true))

      try {
        const response = await API.admin.surveyForms.create(buildFormPayload(data))

        dispatch(entitySlice.actions.add({ data: response.data }))
        onSuccess(response.data.data.id)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(surveyFormSlice.actions.setError(error))
      } finally {
        dispatch(surveyFormSlice.actions.isSaving(false))
      }
    },

    update: (form: any, previousForm: any = null, onSuccess = () => {}) => async (dispatch) => {
      dispatch(surveyFormSlice.actions.isSaving(true))

      try {
        const response = await API.admin.surveyForms.update(buildFormPayload(form, previousForm))

        dispatch(entitySlice.actions.update({ data: response.data }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(surveyFormSlice.actions.setError(error))
      } finally {
        dispatch(surveyFormSlice.actions.isSaving(false))
      }
    },

    delete: (id: string, onSuccess = () => {}) => async (dispatch) => {
      dispatch(surveyFormSlice.actions.isSaving(true))

      try {
        await API.admin.surveyForms.destroy({ id })

        dispatch(entitySlice.actions.remove({ type: 'surveyForm', id }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(surveyFormSlice.actions.isSaving(false))
      }
    },

    copy: (id: string, name: string, onSuccess = (id: string) => {}) => async (dispatch) => {
      dispatch(surveyFormSlice.actions.isSaving(true))

      try {
        const response = await API.admin.surveyForms.copy(id, name)

        dispatch(entitySlice.actions.add({ data: response.data }))
        onSuccess(response.data.data.id)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(surveyFormSlice.actions.isSaving(false))
      }
    },

    exportResponses: (id: string, params = {}) => async (dispatch) => {
      dispatch(surveyFormSlice.actions.isExportingCsv(true))

      try {
        await API.admin.surveyForms.exportResponses(id, params)
        dispatch(showToastMessage({ message: I18NCommon('queue_generate_report'), type: 'success' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(surveyFormSlice.actions.isExportingCsv(false))
      }
    },

    fetchUsersWhoHaveNotResponded: (formId, queryParams = {}) => async (dispatch) => {
      dispatch(surveyFormSlice.actions.clearNotRespondedUserIds())
      dispatch(surveyFormSlice.actions.isLoadingNotRespondedUsers(true))

      try {
        const response = await API.admin.surveyForms.fetchUsersWhoHaveNotResponded(formId, queryParams)

        const newQueryParams = queryParamsFromHeaders(response)
        const userIds = response.data.data.map(user => user.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(surveyFormSlice.actions.setNotRespondedUserIds(userIds))
        dispatch(surveyFormSlice.actions.setNotRespondedUserQueryParams(newQueryParams))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(surveyFormSlice.actions.isLoadingNotRespondedUsers(false))
      }
    },

    sendNotification: (formId, userId, onSuccess = () => {}, onError = (e) => {}) => async (dispatch) => {
      try {
        await API.admin.surveyForms.sendNotification(formId, { userId })

        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        onError(e)
      }
    },

    fetchAvailableTemplates: () => async (dispatch) => {
      dispatch(surveyFormSlice.actions.isLoadingTemplates(true))

      try {
        const response = await API.admin.surveyForms.fetchAvailableTemplates()

        dispatch(surveyFormSlice.actions.setAvailableTemplates(response.data))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(surveyFormSlice.actions.isLoadingTemplates(false))
      }
    },

    resendNotifications: (formId, onSuccess = () => {}, onError = (e) => {}) => async (dispatch) => {
      try {
        await API.admin.surveyForms.resendNotifications(formId)

        dispatch(showToastMessage({ message: I18N('responses.list.reminders_sent'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        onError(e)
      }
    },
  },

  fetchActiveSurvey: () => async (dispatch) => {
    dispatch(surveyFormSlice.actions.clearFormIds())
    dispatch(surveyFormSlice.actions.isLoading(true))

    try {
      const response = await API.surveyForms.fetchActiveSurvey()

      dispatch(entitySlice.actions.add({ data: response.data }))
      const formId = response.data?.data?.id || null
      dispatch(surveyFormSlice.actions.setActiveFormId(formId))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(surveyFormSlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getForms: () => (state: ReduxState) => state.surveyForms.formIds.map(id => build(state.entities, 'surveyForm', id)) || [],
  getForm: (id: string | null) => (state: ReduxState) => build(state.entities, 'surveyForm', id) || {},
  getActiveForm: () => (state: ReduxState) => (state.surveyForms.activeFormId ? build(state.entities, 'surveyForm', state.surveyForms.activeFormId) : null),
  getMetaData: () => (state: ReduxState) => state.surveyForms.meta,
  getUsersWhoHaveNotResponded: () => state => state.surveyForms.usersWhoHaveNotRespondedIds.map(userId => build(state.entities, 'simpleUser', userId)),
  getAvailableTemplates: () => (state: ReduxState) => state.surveyForms.availableTemplates,
}

export default {
  ...surveyFormSlice,
  selectors,
  asyncActions,
}

