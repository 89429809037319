import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import BackButton from 'components/common/backButton'
import searchAnalyticsSlice from 'redux/slices/analytics/search'
import { SelectedResultType } from 'types/analytics/search'
import { toPercentage } from 'utils/analytics'
import { i18nPath } from 'utils/i18nHelpers'

const backToText = i18nPath('views.platform_analytics')('title')
const I18N = i18nPath('views.platform_analytics.search.selected_types')

const manWithTabletPath = '/images/analytics/manWithTabletIcon.svg'

const SelectedResultTypesPage = () => {
  const dispatch = useDispatch()

  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    targetingRules,
    setTargetingRules,
    completedTargetingRules,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const {
    data, totalPages, page, totalItems,
  } = useSelector(searchAnalyticsSlice.selectors.getSelectedResultTypes())
  const { selectedResultTypes: { isLoading } } = useSelector(searchAnalyticsSlice.selectors.getMetaData())
  const isDataEmpty = totalItems === 0 && !isLoading

  const fetchData = ({ page }) => {
    dispatch(searchAnalyticsSlice.asyncActions.admin.fetchSelectedResultTypes(
      {
        periodStart, periodEnd, page, targetingRules: completedTargetingRules,
      }
    ))
  }

  useEffect(() => {
    fetchData({ page: 1 })
  }, [periodStart, periodEnd, completedTargetingRules])

  const columns = [
    {
      header: I18N('result_type'),
      accessor: (result: SelectedResultType) => result.type,
    },
    {
      header: I18N('percentage_selected'),
      style: { width: '40%' },
      accessor: (result: any) => toPercentage(result.percentage),
    },
  ]

  return (
    <main className='include-default-grid mb-5'>
      <BackButton
        backToText={backToText}
        url='/platform_analytics/search'
        className='grid-span-12 my-4'
      />

      <TabHeader
        className='mb-4'
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      {isDataEmpty ? (
        <EmptyStateCard
          title={I18N('title')}
          text={I18N('empty')}
          iconPath={manWithTabletPath}
          classname='grid-span-12'
        />
      ) : (
        <CardWithPaginatedTable
          title={I18N('title')}
          tableColumns={columns}
          tableData={data}
          classname='grid-span-12'
          isLoading={isLoading}
          showPagination={totalPages > 1}
          page={page}
          totalPages={totalPages}
          onPaginationClick={fetchData}
        />
      )}

    </main>
  )
}

export default SelectedResultTypesPage
