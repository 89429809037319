import React from 'react'

import EditTitleSetting from 'components/admin/layout/layout_collection_sidebar/common/editTitleSetting'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.recognition')

const RecognitionWidgetSettingsSidebar = ({ widget, updateWidget }) => (
  <div className='RecognitionWidgetSettingsSidebar'>
    <h5>{I18N('title')}</h5>

    <p className='text-small text-secondary'>{I18N('subtitle')}</p>

    <EditTitleSetting widget={widget} updateWidget={updateWidget} />
  </div>
)

export default RecognitionWidgetSettingsSidebar
