import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'

import PathnameContext from 'components/contexts/pathnameContext'
import BackIcon from 'components/icons/backIcon'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import classNames from 'classnames'
import messageMobileApp from 'utils/messageMobileApp'

type Props = {
  backToText?: string
  url?: string
  className?: string
  onClick?: () => void
}

const BackButton = ({
  backToText, url, className, onClick,
}: Props) => {
  const history = useHistory()

  const { inMobileApp } = useCurrentUser()
  const { currentPathname, previousPathname } = useContext(PathnameContext)

  const showBackButton = (previousPathname && previousPathname !== currentPathname) || url || inMobileApp || onClick

  const handleClick = (e) => {
    if (onClick) {
      onClick()
      e.preventDefault()
      return
    }

    if (!url) {
      // the mobile app uses different webviews for different modules, so the regular history.goBack() doesn't work
      // because it will keep the user in that current webview and maybe they should go to a different one
      if (inMobileApp) {
        messageMobileApp({ action: 'goBack' })
      } else {
        e.preventDefault()
        history.goBack()
      }
    }
  }

  return showBackButton ? (
    <div className={classNames('BackButton', className)}>
      <Link
        className='BackButton-link d-flex align-items-center font-weight-600'
        onClick={handleClick}
        to={onClick ? '#' : url || previousPathname}
      >
        <BackIcon />
        {backToText ? (
          <span className='ml-2'>
            {I18n.t('views.common.back_to_text', { back_text: backToText })}
          </span>
        ) : (
          <span className='ml-2'>{I18n.t('views.common.back_button')}</span>
        )}
      </Link>
    </div>
  ) : null
}

export default BackButton
