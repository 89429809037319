import React from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import homeSlice from 'redux/slices/home'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'

interface Props {
  handleExcludeFromFeed: () => void
  feedItem: any
}

const I18N = i18nPath('views.feed')

const PeopleSettingsCardMenuDropdown = ({ handleExcludeFromFeed, feedItem }: Props) => {
  const dispatch = useDispatch()

  const unsubscribe = () => {
    dispatch(homeSlice.asyncActions.unsubscribeFromFeedItem(feedItem))
  }

  const subscribe = () => {
    dispatch(homeSlice.asyncActions.subscribeToFeedItem(feedItem))
  }

  return (
    <div className='position-absolute top-0 right-0'>
      <DropdownMenuContainer lightColorMenu>
        <CardDropdownMenuItem
          onClick={handleExcludeFromFeed}
          primaryText={I18N('dropdown_menu.exclude_title')}
          subtext={I18N('dropdown_menu.remove_subtext')}
        />

        {feedItem.isSubscribed ? (
          <CardDropdownMenuItem
            onClick={unsubscribe}
            primaryText={I18N('dropdown_menu.unsubscribe_title')}
            subtext={I18N('dropdown_menu.unsubscribe_subtext')}
          />
        ) : (
          <CardDropdownMenuItem
            onClick={subscribe}
            primaryText={I18N('dropdown_menu.subscribe_title')}
            subtext={I18N('dropdown_menu.subscribe_subtext')}
          />
        )}
      </DropdownMenuContainer>
    </div>
  )
}

export default PeopleSettingsCardMenuDropdown
