import { useMemo } from 'react'

const useWidgetRecordAssociations = ({
  widget,
  updateWidget,
  recordType,
}) => {
  const widgetRecordAssociations = widget.widgetRecordAssociations || []
  const records = useMemo(
    () => widgetRecordAssociations.map(({ record }) => record),
    [widgetRecordAssociations]
  )

  const deletedAssociationIds = widget.deletedAssociationIds || []
  const setWidgetRecordAssociations = (newAssociations) => {
    updateWidget({ ...widget, widgetRecordAssociations: newAssociations, forceRefreshAt: Date.now() })
  }

  const onCreate = (record) => {
    const newAssociations = [
      ...widgetRecordAssociations,
      {
        id: `new-${Date.now()}`,
        record,
        recordId: record.id,
        recordType,
      },
    ]

    setWidgetRecordAssociations(newAssociations)
  }

  const onDelete = (recordId) => {
    const toDelete = widgetRecordAssociations.find(association => association.record.id === recordId)
    const updatedAssociations = widgetRecordAssociations.filter(association => association.id !== toDelete.id)

    if (toDelete.id.startsWith('new-')) {
      setWidgetRecordAssociations(updatedAssociations)
    } else {
      updateWidget({
        ...widget,
        widgetRecordAssociations: updatedAssociations,
        deletedAssociationIds: [...deletedAssociationIds, toDelete.id],
        forceRefreshAt: Date.now(),
      })
    }
  }

  return {
    records,
    onCreate,
    onDelete,
  }
}

export default useWidgetRecordAssociations
