import TargetingRulesType from 'types/audience/targetingRules'

export enum PageTypeEnum {
  Home = 'home',
  Preboarding = 'preboarding',
}

export interface LayoutCollectionType {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  targetingRules: TargetingRulesType
  default: boolean
  adminDisplayPath: string
  pageType: PageTypeEnum
}
