import { useMemo } from 'react'
import { useDebounce } from 'usehooks-ts'

// when a lot of dependencies changes in a short period of time, it can be useful to debounce them
// to avoid unnecessary effects or re-renders
const useDebouncedDependencies = (deps, delay = 50) => {
  const memoDependencies = useMemo(() => deps, deps)
  return useDebounce(memoDependencies, delay)
}

export default useDebouncedDependencies
