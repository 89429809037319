import CdnSvg from 'components/common/cdnSvg'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import EmojiPicker from 'components/common/emojiPicker'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import ColorSelect from 'components/common/colorSelect'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.button')

const ButtonWidgetSettingsSidebar = ({ widget, updateWidget }) => {
  const { settings } = widget
  const updateSettings = (key, value) => {
    updateWidget({ ...widget, settings: { ...widget.settings, [key]: value } })
  }

  return (
    <div className='ButtonWidgetSettingsSidebar'>
      <h5>{I18N('title')}</h5>

      <div className='w-100 mb-3'>
        <label>{I18N('text')}</label>
        <input
          className='w-100'
          value={settings.text}
          onChange={e => updateSettings('text', e.target.value)}
        />
      </div>
      <div className='w-100 mb-3'>
        <label>{I18N('secondary_text')}</label>
        <input
          className='w-100'
          value={settings.secondaryText}
          onChange={e => updateSettings('secondaryText', e.target.value)}
        />
      </div>
      <div className='w-100 mb-3'>
        <label>{I18N(_.snakeCase('text_color'))}</label>
        <ColorSelect
          color={settings.textColor}
          onChange={color => updateSettings('textColor', color)}
          onRemove={() => updateSettings('textColor', '#000000')}
        />
      </div>

      <div className='w-100 mb-3'>
        <label>{I18N('icon')}</label>
        <div className='d-flex align-items-center gap-2'>
          <EmojiPicker
            onEmojiSelect={emoji => updateSettings('icon', emoji.native)}
            trigger={<EmojiOrPlaceholder selectedEmoji={settings.icon} />}
            disableCustomReactions
          />
          {settings.icon && (
            <div
              className='pointer'
              onClick={() => updateSettings('icon', null)}
            >
              <CdnSvg className='LayoutCollectionSidebarCloseIcon' src='/images/xIcon.svg' />
            </div>
          )}
        </div>
      </div>

      <div className='w-100 mb-3'>
        <label>{I18N('background_color')}</label>
        <ColorSelect
          color={settings.backgroundColor}
          onChange={color => updateSettings('backgroundColor', color)}
          onRemove={() => updateSettings('backgroundColor', '#000000')}
        />
      </div>

      <div className='w-100 mb-3'>
        <label>{I18N('url')}</label>
        <input
          className='w-100'
          value={settings.linkUrl || ''}
          onChange={e => updateSettings('linkUrl', e.target.value)}
        />
      </div>
    </div>
  )
}

export default ButtonWidgetSettingsSidebar
