/* eslint-disable no-return-assign */
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Popover } from 'reactstrap'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import profileSlice from 'redux/slices/profiles'

import EditableContent from 'components/common/editable_content'
import EditableContentTrigger from 'components/common/editable_content/editableContentTrigger'
import InactiveUserBadge from 'components/profiles/core_fields/inactiveUserBadge'
import { trackAmplitudeEvent } from 'services/tracker'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import NamePronunciationModal from 'components/profiles/namePronunciationModal'
import FollowOrInviteToCleary from 'components/profiles/core_fields/followOrInviteToCleary'
import CdnSvg from 'components/common/cdnSvg'

const briefcaseIconPath = '/images/briefcaseIcon.svg'
const personIconPath = '/images/personIcon.svg'
const leaderboardIconPath = '/images/leaderboardIcon.svg'
const audioIconPath = '/images/audioIcon.svg'

const I18N = i18nPath('views.profile')

const WorkerTypeField = ({ workerType }) => {
  if (!workerType) return null

  return (
    <span className='WorkerTypeField d-flex align-items-center text-secondary font-weight-500 pr-3'>
      <CdnSvg className='BriefcaseIcon mr-1' src={briefcaseIconPath} />
      {workerType}
    </span>
  )
}

const JobLevelField = ({ jobLevel }) => {
  if (!jobLevel) return null

  return (
    <span className='JobLevelField d-flex align-items-center text-secondary font-weight-500 pr-3'>
      <CdnSvg className='LeaderboardIcon mr-1' src={leaderboardIconPath} />
      {jobLevel}
    </span>
  )
}

const DisplayText = ({
  text, iconPath, className, svgClassName,
}) => (
  <span className={classNames('PronounField d-inline-flex align-items-center font-weight-500', className)}>
    <CdnSvg className={classNames('mr-1', svgClassName)} src={iconPath} />
    {text}
  </span>
)

const PronounField = ({
  pronoun,
  canEdit,
  update,
}) => {
  const inputPlaceholder = I18N('pronoun_field_placeholder')

  if (!canEdit && !pronoun) {
    return null
  }

  return (
    <EditableContent
      canEdit={canEdit}
      displayElement={<DisplayText className={canEdit ? '' : 'pr-3'} text={pronoun || inputPlaceholder} iconPath={personIconPath} />}
      inputElement={<input className='ProfilePronounField font-weight-400 p-2' type='text' maxLength='280' placeholder='they/them' />}
      valueToEdit={pronoun}
      saveContent={update}
    />
  )
}

const NamePronunciationField = ({
  recordingUrl,
  writtenPronunciation,
  canEdit,
  update,
}) => {
  const audio = useRef()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { inMobileApp } = useCurrentUser()
  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const playRecording = () => {
    audio.current.play()
    trackAmplitudeEvent('name_pronunciation_listened', { profile_url: window.location.href })
  }
  // if the user didn't set a written pronunciation, but did set a recording, show their name
  const text = writtenPronunciation
               || (canEdit && !recordingUrl && I18N('name_pronunciation_field_placeholder.edit'))
               || I18N('name_pronunciation_field_placeholder.default')

  if (!canEdit && !recordingUrl && !writtenPronunciation) {
    return null
  }

  return (
    <>
      {inMobileApp ? (
        writtenPronunciation && <DisplayText text={writtenPronunciation} iconPath={audioIconPath} svgClassName='mt-1' className='text-color-secondary' />
      ) : (
        <>
          <audio ref={audio} src={recordingUrl} />
          <NamePronunciationModal
            isOpen={isModalOpen}
            toggle={toggleModal}
            recordingUrl={recordingUrl}
            initialWrittenPronunciation={writtenPronunciation}
            update={update}
          />
          <EditableContentTrigger
            canEdit={canEdit}
            isEmpty={!recordingUrl && !writtenPronunciation}
            onClick={canEdit ? toggleModal : recordingUrl && playRecording}
            displayElement={<DisplayText text={text} iconPath={audioIconPath} svgClassName='mt-1' />}
          />
        </>
      )}
    </>
  )
}

const NameField = ({
  showAdminEditLink = false,
  pathToAdminEditPage,
  preferredFullName,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  if (!showAdminEditLink) {
    return <h2>{preferredFullName}</h2>
  }

  return (
    <>
      <Link
        id='showEditTooltip'
        to={pathToAdminEditPage}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <h2 className='mb-0'>{preferredFullName}</h2>
      </Link>
      <Popover className='mr-3' placement='left-start' isOpen={showTooltip} target='showEditTooltip'>
        <div className='p-2'>{I18N('edit_user')}</div>
      </Popover>
    </>
  )
}

const CoreFields = ({
  canEdit = false,
  user,
  showAdminEditLink = false,
  fieldSettings,
}) => {
  const currentUser = useCurrentUser()

  const showPronouns = fieldSettings?.pronouns || false
  const showWorkerType = fieldSettings?.workerType || false
  const showJobLevel = (fieldSettings?.jobLevel && currentUser.manager) || false
  const showNamePronunciation = fieldSettings?.namePronunciation
  const showCity = fieldSettings?.city
  const showTitle = fieldSettings?.title
  const showFields = (showWorkerType && user.workerType)
                     || (showPronouns && (user.pronoun || canEdit))
                     || (showJobLevel && user.jobLevel)
                     || (showNamePronunciation && (user.writtenNamePronunciation
                     || user.namePronunciationRecordingUrl || canEdit))
  const pathToAdminEditPage = `/admin/users/${user.id}`
  const dispatch = useDispatch()

  const updateUser = changes => dispatch(profileSlice.asyncActions.update(user, changes))

  const updateField = fieldName => (value) => {
    if (user[fieldName] === value) {
      return new Promise(resolve => resolve(true))
    } else {
      return updateUser({ [fieldName]: value })
    }
  }

  return (
    <section className='ProfileCoreFields'>
      <span className='d-flex flex-md-row flex-column align-items-center justify-content-between mb-2 pb-1 w-md-100'>
        <div className='d-flex align-items-center'>
          <NameField
            preferredFullName={user.preferredFullName}
            showAdminEditLink={showAdminEditLink}
            pathToAdminEditPage={pathToAdminEditPage}
          />
          <InactiveUserBadge user={user} />
        </div>
        <FollowOrInviteToCleary user={user} />
      </span>
      <span className='mt-1'>
        {showTitle && <h6 className='d-inline mr-1'>{user.title}</h6>}
        {showCity && user?.city && (
          <>
            {showTitle && <span className='text-secondary'>{I18N('in')}</span>}
            <h6 className='d-inline ml-1'>{user.city}</h6>
          </>
        )}

      </span>
      {showFields && (
        <span className='WorkerTypeAndPronouns'>
          {showWorkerType && <WorkerTypeField workerType={user.workerType} />}
          {showJobLevel && <JobLevelField jobLevel={user.jobLevel} />}
          {showPronouns && <PronounField canEdit={canEdit} pronoun={user.pronoun} update={updateField('displayPronoun')} />}
          {showNamePronunciation && (
            <NamePronunciationField
              canEdit={canEdit}
              writtenPronunciation={user.writtenNamePronunciation}
              recordingUrl={user.namePronunciationRecordingUrl}
              update={updateUser}
            />
          )}
        </span>
      )}
    </section>
  )
}

export default CoreFields
