import { useCallback } from 'react'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { transformInterpolatedFieldsForBackend, transformVariablesForUser } from 'utils/journeys/journeyVariablesHelpers'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import API from 'services/api'
import getValueOrTemplateValue from 'utils/journeys/getValueOrTemplateValue'

const useTransformInterpolatedFields = () => {
  const { atsIntegrationName, hrisIntegrationName } = useCurrentCompany()
  const { data: groupTypes, isLoading: isFetchingGroupTypes } = useGlobalStateBucket(API.groupTypes.fetchAll, 'groupTypes')

  const interpolateParamsForBackend = useCallback((
    interpolatedFields, params, questionVariables
  ) => transformInterpolatedFieldsForBackend(
    interpolatedFields,
    params,
    questionVariables,
    atsIntegrationName,
    hrisIntegrationName,
    groupTypes
  ), [atsIntegrationName, hrisIntegrationName, groupTypes])

  const interpolateParamsForUser = useCallback((
    initialState,
    interpolatedFields,
    entityTemplate,
    questionVariables,
    isStepTemplate,
    entity
  ) => {
    interpolatedFields.forEach((attr) => {
      const value = isStepTemplate ? entityTemplate?.[attr] : getValueOrTemplateValue(entity, entityTemplate, attr)
      initialState[attr] = transformVariablesForUser(
        value,
        {
          questionVariables,
          atsIntegrationName,
          hrisIntegrationName,
          groupTypes,
        }
      )
    })
    return initialState
  }, [atsIntegrationName, hrisIntegrationName, groupTypes])

  return { interpolateParamsForBackend, interpolateParamsForUser, isFetchingGroupTypes }
}

export default useTransformInterpolatedFields
