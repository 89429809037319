import React from 'react'

import { Button } from 'components/common/buttons'
import Card from 'components/common/card'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.auth_tokens')

const AuthTokenCard = ({ authToken, onRemove }) => (
  <Card className='mb-4'>
    <div className='d-flex justify-content-between align-items-center'>
      <div>
        {I18N('value')}: {authToken.redactedToken} <br /> {I18N('last_used_at', { datetime: i18nMoment(authToken.updatedAt).format('LLL') })}
      </div>
      <div>
        <Button
          variant='danger'
          onClick={() => onRemove(authToken)}
        >
          {I18N('delete')}
        </Button>
      </div>
    </div>
  </Card>
)

export default AuthTokenCard
