import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import { ButtonNarrow } from 'components/common/buttons'
import userFollowSlice from 'redux/slices/userFollows'
import Tooltip from 'components/common/clearyTooltip'
import { Link } from 'react-router-dom'

const I18N = i18nPath('views.profile.follow_button')

const FollowButton = ({ user }) => {
  const dispatch = useDispatch()

  const { isLoadingFollowingStatus, isSaving } = useSelector(userFollowSlice.selectors.getMetaData())
  const followingStatus = useSelector(userFollowSlice.selectors.getFollowingStatus())

  const [unfollowButtonHovered, setUnfollowButtonHovered] = useState(false)

  const follow = () => {
    dispatch(userFollowSlice.asyncActions.create({ followedUserId: user.id })).then(() => {
      dispatch(userFollowSlice.asyncActions.fetchFollowingStatus(user.id))
    })
  }

  const unfollow = () => {
    dispatch(userFollowSlice.asyncActions.destroy(followingStatus.userFollowId)).then(() => {
      dispatch(userFollowSlice.asyncActions.fetchFollowingStatus(user.id))
    })
  }

  useEffect(() => {
    dispatch(userFollowSlice.asyncActions.fetchFollowingStatus(user.id))
  }, [])

  if (followingStatus.error) {
    return null
  }

  if (isLoadingFollowingStatus || isSaving) {
    return (
      <ButtonNarrow
        className='mr-2'
        showLoadingSpinner={true}
        isDisabled
      />
    )
  }

  if (!followingStatus.isFollowing) {
    return (
      <Tooltip
        id='follow-btn'
        placement='bottom'
        content={(
          <ButtonNarrow
            className='mr-2'
            onClick={follow}
          >
            {I18N('follow')}
          </ButtonNarrow>
        )}
      >
        {I18N('follow_tooltip', { userName: user.preferredName })}
      </Tooltip>
    )
  }

  if (followingStatus.reason === 'user_follow') {
    return (
      <ButtonNarrow
        className='mr-2'
        onClick={unfollow}
        onMouseEnter={() => { setUnfollowButtonHovered(true) }}
        onMouseLeave={() => { setUnfollowButtonHovered(false) }}
        variant={unfollowButtonHovered ? 'danger' : 'primary'}
      >
        {I18N(unfollowButtonHovered ? 'unfollow' : 'following')}
      </ButtonNarrow>
    )
  }

  return (
    <Tooltip
      id='following-btn'
      content={(
        <ButtonNarrow
          className='mr-2'
        >
          {I18N('following')}
        </ButtonNarrow>
      )}
      placement='bottom'
      hide={1000}
    >
      {i18nFormat(
        I18N(`following_tooltip.${followingStatus.reason}`),
        <Link to='/settings/people' className='text-white'>{I18N('settings')}</Link>,
        followingStatus.followedUserName
      )}
    </Tooltip>
  )
}

export default FollowButton
