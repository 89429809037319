import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SmartTable from 'components/common/tables/smartTable'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import AppType from 'types/app'
import appSlice from 'redux/slices/app_launcher/apps'
import FavoriteButton from 'components/app_launcher/favoriteButton'
import SuggestedIcon from 'components/common/suggestedIcon'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import SortableFavoritesTable from 'components/app_launcher/sortableFavoritesTable'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import CdnSvg from 'components/common/cdnSvg'
import { present } from 'components/common/utils'
import useWindowResize from 'components/common/hooks/useWindowResize'

const I18N = i18nPath('views.app_launcher.my_apps_list_page')

const searchIconPath = '/images/searchIcon.svg'

export const appListColumns = ({
  isUpToMediumScreen, dispatch, searchQuery, hideHeaders = false,
}) => ([
  {
    header: hideHeaders ? undefined : I18N('icon'),
    accessor: app => <SuggestedIcon name={app.name} url={app.url} iconUrl={app.iconUrl} height={24} width={24} className='m-0' />,
    style: { width: '10%' },
  },
  !isUpToMediumScreen && {
    header: hideHeaders ? undefined : I18N('name'),
    accessor: app => <p className='truncate-text-at-1-lines'>{app.name}</p>,
    style: { width: '40%' },
  },
  !isUpToMediumScreen && {
    header: hideHeaders ? undefined : I18N('url'),
    accessor: app => <a href={app.url} target='_blank' rel='noopener noreferrer' className='truncate-text-at-1-lines'>{app.url}</a>,
    style: { width: '40%' },
  },
  isUpToMediumScreen && {
    header: hideHeaders ? undefined : I18N('name_and_url'),
    accessor: app => (
      <div className='d-flex flex-column'>
        <p className='truncate-text-at-1-lines'>{app.name}</p>
        <a
          href={app.url}
          target='_blank'
          rel='noopener noreferrer'
          className='truncate-text-at-1-lines'
        >
          {app.url}
        </a>
      </div>
    ),
    style: { width: '80%' },
  },
  {
    header: hideHeaders ? undefined : '',
    accessor: app => <FavoriteButton app={app} dispatch={dispatch} searchQuery={searchQuery} />,
    style: { width: '10%' },
  },
].filter(present))

const MyAppsListPage = () => {
  const dispatch = useDispatch()

  const [searchQuery, setSearchQuery] = useState('')

  const apps = useSelector(appSlice.selectors.getUserApps())

  const nonFavoriteApps = apps.filter(app => !app.favorite)
  const { isLoadingUserApps } = useSelector(appSlice.selectors.getMetaData())

  useEffect(() => {
    const showLoading = searchQuery === ''
    dispatch(appSlice.asyncActions.fetchAll({ q: searchQuery, showLoading }))
  }, [searchQuery])

  const { isUpToMediumScreen } = useWindowResize()

  return (
    <main className='MyAppsListPage'>
      <header className='mt-4 mb-3'>
        <h1>{I18N('title')}</h1>
        <AsyncSearchInput
          placeholder={I18NCommon('search_by_name')}
          icon={<CdnSvg src={searchIconPath} />}
          onKeyUp={setSearchQuery}
          className='Search mt-3'
        />
      </header>
      {isLoadingUserApps ? (
        <CirclesLoadingIndicator />
      ) : (
        <>
          <SortableFavoritesTable apps={apps} searchQuery={searchQuery} />
          <SmartTable
            data={nonFavoriteApps}
            columns={appListColumns({
              isUpToMediumScreen, dispatch, searchQuery, hideHeaders: true,
            })}
            className='AppsTable white-bg-table'
            hideHeaders
            hideWhenEmpty
          />
        </>
      )}
    </main>
  )
}

export default MyAppsListPage
