import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import AsyncSearchInput from 'components/common/asyncSearchInput'
import { ButtonLink } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import RoundedAvatars from 'components/common/roundedAvatars'
import DateCell from 'components/common/tables/dateCell'
import SmartTable from 'components/common/tables/smartTable'
import API from 'services/api'
import EmptyStateTable from 'components/admin/articles/templates/emptyStateTable'
import TemplateDropdown from 'components/admin/articles/templates/templateDropdown'
import { present } from 'components/common/utils'
import useIsCleary from 'components/common/hooks/useIsCleary'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.admin.article_template_list')
const searchIconPath = '/images/searchIcon.svg'

const columns = (isCleary, onTemplateDestroyed) => ([
  {
    header: I18N('table_header.title'),
    col: 'col-title',
    style: { minWidth: '30%' },
    accessor: d => (
      <Link to={`/admin/articles/templates/${d.id}`} className='truncate-text-at-2-lines'>
        {d.title}
      </Link>
    ),
  },
  isCleary && {
    header: I18N('table_header.type'),
    col: 'col-template-type',
    id: 'company_id', // used to sort by company_id
    accessor: d => (d.baseTemplate ? I18N('base_template') : I18N('company_template')),
  },
  {
    header: I18N('table_header.creator'),
    col: 'col-creator',
    accessor: d => d.owner && <div data-cy={d.owner.username}><RoundedAvatars users={[d.owner]} avatarSize='30px' /></div>,
  },
  {
    header: I18N('table_header.updated'),
    col: 'col-updated_at',
    id: 'updated_at',
    accessor: d => <DateCell date={d.updatedAt} />,
  },
  {
    header: '',
    accessor: d => <TemplateDropdown location='TemplateListPage' template={d} onTemplateDestroyed={onTemplateDestroyed} />,
  },
].filter(present))

const defaultPaginationParams = { sortBy: 'updated_at', sortDir: 'desc', page: 1 }

const TemplatesListTable = () => {
  const isCleary = useIsCleary()
  const { permissions } = useCurrentUser()
  const canManageBaseTemplates = permissions.clearyAdmin && isCleary
  const [paginationParams, setPaginationParams] = useState(defaultPaginationParams)

  const [query, setQuery] = useState('')

  // we must reset the pagination params when the query changes
  // note that query is debounced since we're using the AsyncSearchInput
  useEffect(() => {
    setPaginationParams(defaultPaginationParams)
  }, [query])

  const {
    data, isLoading, error, paginationData: { page, totalPages },
    removeItem,
  } = useFetch(() => API.admin.article.templates.fetchAll({
    companyTemplates: true,
    baseTemplates: canManageBaseTemplates,
    query,
    ...paginationParams,
  }), [canManageBaseTemplates, query, paginationParams])

  const onTemplateDestroyed = template => removeItem(template.id)

  const showEmptyState = data?.length === 0 && !isLoading
  const emptyStateType = query === '' ? 'no_templates_created' : 'not_found'

  return (
    <div className='TemplatesListTable row mb-4'>
      <div className='col'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <AsyncSearchInput
            placeholder={I18N('title_search_placeholder')}
            icon={<CdnSvg src={searchIconPath} />}
            minCharsToSearch={3}
            onKeyUp={setQuery}
            className='mb-0'
          />

          <div>
            {canManageBaseTemplates && (
              <ButtonLink to='/admin/article/template_categories' variant='secondary' className='mr-2'>
                {I18N('manage_template_categories')}
              </ButtonLink>
            )}

            <ButtonLink to='/admin/articles/templates/new' variant='secondary'>
              {I18N('create_new_template')}
            </ButtonLink>
          </div>
        </div>

        <TableLoadingContainer isLoading={isLoading} isNotFound={error}>
          <div className='overflow-x-auto'>
            {showEmptyState ? (
              <EmptyStateTable type={emptyStateType} />
            ) : (
              <SmartTable
                data={data}
                sortedAsc={false}
                sortKey='updated_at'
                showPagination={page !== null && totalPages !== null}
                columns={columns(isCleary, onTemplateDestroyed)}
                page={page}
                pages={totalPages}
                onPaginationClick={setPaginationParams}
                onSortChange={setPaginationParams}
                className='SmartTable white-bg-table'
              />
            )}
          </div>
        </TableLoadingContainer>
      </div>
    </div>
  )
}

export default TemplatesListTable
