import React from 'react'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import CalendarIcon from 'components/icons/calendarIcon'
import AnswerByLine from 'components/qna/answerByLine'
import QuestionByLine from 'components/qna/questionByLine'
import CdnSvg from 'components/common/cdnSvg'
import ClearyInternalSearchResultsTemplate from './clearyInternalSearchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const I18N = i18nPath('views.search.search_results.search_results')

const QnaEventResult = ({ result }) => {
  const { qnaEvent, highlights, displayPath } = result
  const { title, eventTime } = { ...qnaEvent, ...highlights }
  // if there is no description in highlights we can't use the one in qnaEvent because it's not a string
  const description = highlights?.description

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={<CdnSvg src='/images/calendarIcon.svg' />}
      pretitle={I18N('event')}
      title={title}
      subtitle={i18nMoment(eventTime).format('llll')}
      description={description}
      result={result}
      descriptionExtraMargin
    />
  )
}

const FromEvent = ({ event }) => (
  <div className='text-small text-secondary d-flex'>
    <span>{I18N('from')}&nbsp;</span>
    <span className='font-weight-600'>{event?.title}</span>
  </div>
)

const questionIconPath = '/images/questionIcon.svg'

const QnaQuestionResult = ({ result }) => {
  const { qnaQuestion, highlights, displayPath } = result
  const { content, event } = { ...qnaQuestion, ...highlights }

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={<CdnSvg src={questionIconPath} />}
      pretitle={I18N('question')}
      title={content}
      subtitle={<FromEvent event={event} />}
      description={<QuestionByLine question={qnaQuestion} className='text-secondary text-small mt-2' />}
      result={result}
      descriptionExtraMargin
    />
  )
}

const answerIconPath = '/images/answerIcon.svg'

const QnaAnswerResult = ({ result }) => {
  const { qnaAnswer, highlights, displayPath } = result
  const { content, question: { event } } = { ...qnaAnswer, ...highlights }

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={<CdnSvg src={answerIconPath} />}
      pretitle={I18N('answer')}
      title={content}
      subtitle={<FromEvent event={event} />}
      description={<AnswerByLine answer={qnaAnswer} className='text-secondary text-small mt-2' />}
      result={result}
      descriptionExtraMargin
    />
  )
}

export const EventResult = ({ result }) => {
  if (result.qnaEvent) {
    return <QnaEventResult result={result} />
  }
  if (result.qnaQuestion) {
    return <QnaQuestionResult result={result} />
  }

  return <QnaAnswerResult result={result} />
}

const EventResults = ({ showLabel = true }) => (
  <ClearyInternalSearchResultsTemplate
    showLabel={showLabel}
    label='qna'
    type='qnaEvent'
    extraTypes={['qnaQuestion', 'qnaAnswer']}
    queryType='QNA::Event'
    searchResultToTemplateMap={result => <EventResult result={result} />}
  />
)

export default EventResults
