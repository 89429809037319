import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import IntroductionDetail from 'components/journeys/introductions/introductionDetail'
import introductionSlice from 'redux/slices/journeys/introductions'
import Error404 from 'components/errors/404'
import LoadingSpinner from 'components/common/loadingSpinner'
import BackToMyTasksButton from 'components/journeys/taskables/backToMyTasksButton'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'
import useQueryParams from 'components/common/hooks/useQueryParams'
import removeQueryParam from 'utils/removeQueryParam'

const circledCheckIconPath = '/images/circledCheckIcon.svg'

const IntroductionDetailPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { introductionId } = useParams()
  const { disableNotifications } = useQueryParams()

  const { isLoading, isNotFound } = useSelector(introductionSlice.selectors.getMetaData())
  const introduction = useSelector(introductionSlice.selectors.getIntroduction(introductionId))

  const [toggleNotifications] = useApi(API.journey.introductions.toggleNotifications, {
    updateEntitySlice: true,
    toastSuccessMessage: I18NCommon('task_notifications_updated'),
    onSuccess: () => removeQueryParam(history, 'disable_notifications'),
  })

  useEffect(() => {
    dispatch(introductionSlice.asyncActions.fetchIntroduction(introductionId))
  }, [])

  useEffect(() => {
    if (disableNotifications === 'true') {
      toggleNotifications(introductionId, { enableNotifications: false })
    }
  }, [introduction?.id])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isNotFound) {
    return <Error404 />
  }

  return (
    <main className='IntroductionDetailPage'>
      <div className='col-12 offset-md-2 col-md-8 mt-4'>
        <BackToMyTasksButton />
        <h2 className='mb-3'>
          <CdnSvg src={circledCheckIconPath} className='mr-2' />
          {introduction?.name}
        </h2>
        <IntroductionDetail introduction={introduction} className='p-4 mb-4' isLoading={isLoading} />
      </div>
    </main>
  )
}

export default IntroductionDetailPage
