import React from 'react'

import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import Event from 'components/common/calendar/event'
import CalendarEventsList from 'components/common/calendar/eventsList'
import { eventsList } from 'components/common/calendar/__tests__/eventsFixture'

const CalendarEventsPage = () => (
  <main className='Styleguide CalendarEventsPage container mt-4'>
    <h1><StyleguideBaseLink /> / Calendar Events</h1>
    <section className='mt-4'>
      <h3>components/common/calendar</h3>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th>Component Name</th>
            <th>Description</th>
            <th>Props</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Event</td>
            <td>Calendar Event Card</td>
            <td>start, end, summary, htmlLink</td>
            <td>
              <Event event={eventsList[0]} />
            </td>
          </tr>
          <tr>
            <td>Events List</td>
            <td>Calendar Events List</td>
            <td>events, emptyCalendarText</td>
            <td>
              <CalendarEventsList events={eventsList} emptyCalendarText='Calendar unavailable' />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
)

export default CalendarEventsPage
