import React from 'react'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_answers_wizard_modal')

interface Props {
  step: number
  children?: React.ReactNode
  description?: string | React.ReactNode
  isLastStep?: boolean
  imageCentered?: boolean
}

const MainContent = ({
  step,
  description,
  children = null,
  imageCentered = false,
  isLastStep = false,
}: Props) => (
  <div>
    <p className='text-center text-secondary text-small mb-4'>{I18N(`step_${step}.subtitle`)}</p>

    <div className='d-flex justify-content-center'>
      <div className='AiAnswersWizardModal__container border-radius card-shadow'>
        <div
          className={
            classNames(
              'AiAnswersWizardModal__illustration d-flex justify-content-center border-radius-top',
              { 'AiAnswersWizardModal__illustration--last-step': isLastStep },
              imageCentered ? 'align-items-center' : 'align-items-end'
            )
          }
        >
          <img src={`/images/illustrations/ai_platform_wizard_0${step}.gif`} />
        </div>

        <div className='text-left p-4'>
          <h4 className='mb-2'>{I18N(`step_${step}.card_title`)}</h4>
          <p className='text-small'>{description || I18N(`step_${step}.card_description`)}</p>

          {children}
        </div>
      </div>
    </div>
  </div>
)

export default MainContent
