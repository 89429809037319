import CreateNewArticleTemplateDropdownMenuItem from 'components/admin/articles/templates/template_dropdown/createNewArticleTemplateDropdownMenuItem'
import CopyTemplateDropdownModal from 'components/admin/articles/templates/template_dropdown/copyTemplateDropdownModal'
import PreviewTemplateDropdownModal from 'components/admin/articles/templates/template_dropdown/previewTemplateDropdownModal'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import DeleteTemplateDropdownModal from './template_dropdown/deleteTemplateDropdownModal'

const DELETE_MODAL = 'DELETE_MODAL'
const COPY_MODAL = 'COPY_MODAL'
const PREVIEW_MODAL = 'PREVIEW_MODAL'
const I18N = i18nPath('views.admin.article_list.item_dropdown.template')

type Props = {
  template?: Record<string, any>
  // we use this in both TemplateListPage and TemplateEditorPage with some different buttons
  location: 'TemplateListPage' | 'TemplateEditorPage'
  onTemplateDestroyed?: (template: any) => void
}

const TemplateDropdown = ({
  template,
  location,
  onTemplateDestroyed,
}: Props) => {
  const inTemplateEditor = location === 'TemplateEditorPage'
  const inTemplateListPage = location === 'TemplateListPage'
  const [currentOpenModal, setCurrentOpenModal] = useState<string | null>(null)

  const closeModal = () => setCurrentOpenModal(null)

  return (
    <>
      <DropdownMenuContainer className='TemplateDropdown default-dropdown' menuType='kebab'>
        {inTemplateListPage && (
          <>
            <section>
              <CardDropdownMenuItem
                onClick={() => setCurrentOpenModal(PREVIEW_MODAL)}
                primaryText={I18N('preview_template')}
                icon={<CdnSvg src='/images/eyeOpenIcon.svg' className='mr-2' />}
              />

              <CardDropdownMenuItem
                link={`/admin/articles/templates/${template?.id}`}
                primaryText={I18N('edit_template')}
                icon={<CdnSvg src='/images/pencilIcon.svg' className='mr-2' />}
              />
            </section>
          </>
        )}
        <section>
          <CardDropdownMenuItem
            onClick={() => setCurrentOpenModal(COPY_MODAL)}
            primaryText={I18N('copy_template')}
            icon={<CdnSvg src='/images/duplicateIcon.svg' className='mr-2' />}
          />
        </section>
        <section>
          <CreateNewArticleTemplateDropdownMenuItem templateId={template?.id} />
        </section>
        <section>
          <CardDropdownMenuItem
            className='Card-dropdownMenuItem--danger'
            onClick={() => setCurrentOpenModal(DELETE_MODAL)}
            primaryText={I18N('delete_template')}
            icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
          />
        </section>
      </DropdownMenuContainer>

      {currentOpenModal === DELETE_MODAL && (
        <DeleteTemplateDropdownModal
          isOpen
          closeModal={closeModal}
          inTemplateEditor={inTemplateEditor}
          onTemplateDestroyed={onTemplateDestroyed}
          template={template}
        />
      )}

      {currentOpenModal === COPY_MODAL && (
        <CopyTemplateDropdownModal
          closeModal={closeModal}
          template={template}
        />
      )}

      {currentOpenModal === PREVIEW_MODAL && (
        <PreviewTemplateDropdownModal
          templateId={template?.id}
          onClose={closeModal}
        />
      )}
    </>
  )
}

export default TemplateDropdown
