import IntegrationCard from 'components/admin/integrations/integrationCard'
import IntegrationInstalledMessage from 'components/admin/integrations/IntegrationInstalledMessage'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import itAdminSlice from 'redux/slices/itAdmin'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const slackLogoPath = '/images/slackLogoIcon.svg'
const I18N = i18nPath('views.integrations_page.integration_card.slack')

const MultipleSlackBody = () => {
  const dispatch = useDispatch()
  const { slacks = [] } = useSelector(itAdminSlice.selectors.getData())
  const [deletingWorkspace, setDeletingWorkspace] = useState(null)
  const [confirmWorkspace, setConfirmWorkspace] = useState('')
  const { appName } = useCurrentCompany()

  const uninstallSlack = () => {
    dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration('slack', { id: deletingWorkspace.id }))
  }

  const openUninstallModal = (id) => {
    setDeletingWorkspace(slacks.find(it => it.id === id))
    setConfirmWorkspace('')
  }

  if (slacks.length === 0) {
    return null
  }

  return (
    <div className='mb-3'>
      {deletingWorkspace && (
        <ConfirmationModal
          title={I18N('delete_workspace.title')}
          toggle={() => setDeletingWorkspace(null)}
          confirmationDisabled={confirmWorkspace !== deletingWorkspace.domain}
          onConfirmClick={uninstallSlack}
          visible
        >
          <>
            <p className='text-secondary text-small'>
              {I18N('delete_workspace.description', { domainName: deletingWorkspace.domain, appName })}
            </p>
            <label className='form-label mb-0 text-left d-block text-secondary text-small mb-2'>
              {I18N('delete_workspace.confirmation_label', { domainName: deletingWorkspace.domain })}
            </label>
            <input
              className='mb-3 w-100'
              value={confirmWorkspace}
              onChange={e => setConfirmWorkspace(e.target.value)}
              placeholder={deletingWorkspace.domain}
              autoFocus
            />
          </>
        </ConfirmationModal>
      )}

      {slacks.map(slack => (
        <IntegrationInstalledMessage key={slack.id} workspace={slack} onUninstall={openUninstallModal} />
      ))}
    </div>
  )
}

const MultipleSlackIntegrationCard = () => {
  const { slackInstallUrl } = useSelector(itAdminSlice.selectors.getData())

  return (
    <IntegrationCard
      id='slack'
      logo={<CdnSvg src={slackLogoPath} />}
      bodyComponent={<MultipleSlackBody />}
      installationComponent={(
        <a className='btn btn-primary btn-sm btn-narrow' href={slackInstallUrl}>
          {I18N('add_or_update')}
        </a>
      )}
    />
  )
}

export default MultipleSlackIntegrationCard
