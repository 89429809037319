import React from 'react'
import { useHistory } from 'react-router-dom'

import { i18nFormat, i18nPath } from 'utils/i18nHelpers'

import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import EmployeeMultiLinkWithPopover from 'components/common/employeeMultiLinkWithPopover'

const I18N = i18nPath('views.common')

const MentionedUsers = ({ article, alwaysShowCount = false }) => {
  const history = useHistory()
  const mentionedUsersPopoverId = `article-${article.id}-mentioned-users`
  const { contentMentionedUsers, cardContentMentionedUsers } = article
  const allMentionedUsers = _.uniqBy([...(contentMentionedUsers || []), ...(cardContentMentionedUsers || [])], 'id')

  const allMentionedUsersCount = allMentionedUsers.length

  if (allMentionedUsersCount === 0) return null

  return (
    <>
      {(allMentionedUsersCount === 1 && !alwaysShowCount) && (
        <span className='MentionedUsers text-secondary bullet-separator'>
          {i18nFormat(
            I18N('was_mentioned_singular'),
            <EmployeeLinkWithPopover
              user={allMentionedUsers[0] || {}}
              id={mentionedUsersPopoverId}
              history={history}
            />
          )}
        </span>
      )}

      {(allMentionedUsersCount > 1 || alwaysShowCount) && (
        <span
          className='MentionedUsers bullet-separator'
        >
          <EmployeeMultiLinkWithPopover
            users={allMentionedUsers}
            id={mentionedUsersPopoverId}
            history={history}
          >
            <span className='font-weight-500'>{I18N('number_of_people_mentioned', { count: allMentionedUsersCount })}</span>
            <span className='text-secondary'>{I18N('was_mentioned_multi', { count: allMentionedUsersCount })}</span>
          </EmployeeMultiLinkWithPopover>
        </span>
      )}
    </>
  )
}

export default MentionedUsers
