import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ReactSelect from 'components/common/react_select'
import { i18nPath } from 'utils/i18nHelpers'
import { isEmpty } from 'lodash'
import useQueryParams from 'components/common/hooks/useQueryParams'
import userSkillSlice from 'redux/slices/userSkills'
import { useCityTargetingOptions } from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.search.search_results.filters')

const PeopleFilter = ({ updateHistory }) => {
  const { filters: rawFilters = '{}' } = useQueryParams()
  const filters = JSON.parse(rawFilters)
  const { city, user_skills: userSkills } = filters
  const allUserSkills = useSelector(userSkillSlice.selectors.getAllSkills())
  const { isLoading } = useSelector(userSkillSlice.selectors.getMetaData())
  const dispatch = useDispatch()

  const cityOptions = useCityTargetingOptions()

  const handleSearch = (newFilters, type) => {
    const updatedFilters = { ...filters, ...newFilters }

    if (!newFilters) {
      delete updatedFilters[type]
    }

    updateHistory(updatedFilters)
  }

  const handleCityFilter = (option) => {
    const filters = !isEmpty(option)
      ? {
        city: option.value,
      }
      : null
    handleSearch(filters, 'city')
  }

  const handleUserSkillFilter = (option) => {
    const filters = !isEmpty(option)
      ? {
        user_skills: option.name,
      }
      : null
    handleSearch(filters, 'user_skills')
  }

  useEffect(() => {
    dispatch(userSkillSlice.asyncActions.listAll())
  }, [])

  return (
    <div className='PeopleFilter MultipleRefinedFilters px-3'>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('people_city_filter')}
        </div>
        <ReactSelect
          isClearable
          placeholder={(
            <div className='text-secondary'>
              {I18n.t('views.common.react_select.select_or_type')}
            </div>
          )}
          value={cityOptions.filter(({ value }) => city === value)}
          onChange={handleCityFilter}
          options={cityOptions}
          getOptionValue={option => option.value}
          getOptionLabel={option => option.label}
        />
      </div>

      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('people_skills_filter')}
        </div>
        <ReactSelect
          isClearable
          isLoading={isLoading}
          placeholder={(
            <div className='text-secondary'>
              {I18n.t('views.common.react_select.select_or_type')}
            </div>
          )}
          value={allUserSkills.filter(({ name }) => userSkills === name)}
          onChange={handleUserSkillFilter}
          options={allUserSkills}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
        />
      </div>
    </div>
  )
}

export default PeopleFilter
