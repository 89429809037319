import build from 'redux-object'
import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'

const initialState = {
  unreadCount: 0,
  meta: {
    isLoading: false,
    isMarkingAsViewed: false,
  },
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUnreadCount(state, action) {
      state.unreadCount = action.payload
    },

    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },

    setIsMarkingAsViewed(state, action) {
      state.meta.isMarkingAsViewed = action.payload
    },
  },
})

//
//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(notificationSlice, {
  asyncActions: {
    fetchNotifications: () => async (dispatch) => {
      dispatch(notificationSlice.actions.setIsLoading(true))

      try {
        const response = await API.notifications.fetchAll()
        const unreadCount = _.filter(response.data.data, o => o.attributes.viewedAt === null).length

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(notificationSlice.actions.setUnreadCount(unreadCount))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(notificationSlice.actions.setIsLoading(false))
      }
    },
    markAsViewed: notifications => async (dispatch) => {
      dispatch(notificationSlice.actions.setIsMarkingAsViewed(true))

      const unreadNotificationIds = _.filter(notifications, { viewedAt: null }).map(notif => notif.id)
      try {
        await API.notifications.markAsViewed(unreadNotificationIds)
        dispatch(notificationSlice.actions.setUnreadCount(0))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(notificationSlice.actions.setIsMarkingAsViewed(false))
      }
    },
  },
})

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------

_.assign(notificationSlice, {
  selectors: {
    getNotifications: () => (state) => {
      let notifications = build(state.entities, 'notification') || []

      // sort notifications by date and reverse, so we show the newest notifications on top
      notifications = notifications.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).reverse()

      return { notifications }
    },

    getMetaData: () => state => state.notifications.meta,

    getUnreadCount: () => state => state.notifications.unreadCount,
  },
})

export default notificationSlice
