import React, { useEffect, useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { Button } from 'components/common/buttons'
import moment from 'moment'
import { showToastMessage } from 'redux/slices/toasts'
import { useDispatch } from 'react-redux'
import useCurrentUserIntegrations from 'components/common/hooks/useCurrentUserIntegrations'

const I18N = i18nPath('views.qna.events')

const EventRecapButton = ({ event }) => {
  const url = event?.videoConferenceLink || ''
  const isZoomLink = url?.includes('zoom.us')
  const currentCompany = useCurrentCompany()
  const eventTime = moment(event?.eventTime)
  // We'll remove the company check once we're ready to launch this feature
  const canManageEvent = currentCompany?.subdomain === 'cleary' && event?.permittedActions?.manage
  const isPastEvent = eventTime.isBefore(moment())
  const dispatch = useDispatch()
  const userIntegrations = useCurrentUserIntegrations()
  const hasZoomInstalled = userIntegrations?.zoomInstalled
  const [hasCreatedArticle, setHasCreatedArticle] = useState(false)

  const onCreateSuccess = (data) => {
    setHasCreatedArticle(true)
    dispatch(showToastMessage({ message: data.message, type: 'success' }))
  }

  const [
    checkEventHasZoomRecording,
    { data: eventHasZoomRecordingResponse, isLoading: isLoadingZoomRecording },
  ] = useApi(API.admin.qna.events.eventHasZoomRecording)

  const [
    onGenerateRecapArticle,
    { isLoading: isGeneratingRecapArticle },
  ] = useApi(API.admin.qna.events.generateRecapArticle, { onSuccess: onCreateSuccess })

  useEffect(() => {
    if (isZoomLink && canManageEvent && hasZoomInstalled && isPastEvent) {
      checkEventHasZoomRecording(event?.id)
    }
  }, [url, hasZoomInstalled])

  const hasZoomRecording = !isLoadingZoomRecording && eventHasZoomRecordingResponse?.result && isZoomLink
  const canCreateRecap = !hasCreatedArticle && isPastEvent && hasZoomRecording && canManageEvent

  if (canCreateRecap) {
    return (
      <div className='EventRecapButton'>
        <Button
          className='btnZoomArticle'
          onClick={() => onGenerateRecapArticle(event?.id)}
          showLoadingSpinner={isGeneratingRecapArticle}
          disabled={isGeneratingRecapArticle}
        >
          {I18N('generate_recap_article')}
        </Button>
      </div>
    )
  }

  return null
}

export default EventRecapButton
