import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { buildCorrespondentPayload } from 'redux/slices/journeys/stepTemplates'

export const STEP_ATTRIBUTES = ['triggerAt', 'triggerIfVariablesMissing', 'triggerTimezone']

export const initialState = {
  stepIds: [],
  meta: {
    ...defaultMeta,
    isRetryingTrigger: false,
    queryParams: {
      ...defaultPaginationParams, perPage: 10, sortBy: 'trigger_at', sortDir: 'asc',
    },
  },
}

export const buildStepPayload = (step) => {
  const newStep = _.pick(step, [...STEP_ATTRIBUTES, 'id', 'journeyId', 'name'])

  const isTriggered = step?.state === 'triggered'

  if (step.fromCorrespondentAttributes) {
    newStep.fromCorrespondentAttributes = buildCorrespondentPayload(step.fromCorrespondentAttributes)

    if (isTriggered && newStep.fromCorrespondentAttributes.role === 'specific_employee') {
      newStep.fromUserId = step.fromCorrespondentAttributes.userId
    }
  }

  if (step.toCorrespondentAttributes) {
    newStep.toCorrespondentAttributes = buildCorrespondentPayload(step.toCorrespondentAttributes)

    if (isTriggered) {
      newStep.toUserId = step.toCorrespondentAttributes.userId
    }
  }

  if (step.ccCorrespondentsAttributes) {
    newStep.ccCorrespondentsAttributes = step.ccCorrespondentsAttributes.map(buildCorrespondentPayload)

    // TODO: if triggered need to add the cc users
  }

  return newStep
}

const stepSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    ...defaultActions,

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    setStepIds(state, action) {
      state.stepIds = action.payload
    },

    clearStepIds(state) {
      state.stepIds = []
    },

    isRetryingTrigger(state, action) {
      state.meta.isRetryingTrigger = action.payload
    },
  },
})

const asyncActions = {
  admin: {
    fetchAll: (params = {}) => async (dispatch) => {
      dispatch(stepSlice.actions.isLoading(true))

      try {
        const response = await API.admin.steps.fetchAll(params)

        const stepIds = response.data.data.map(step => step.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(stepSlice.actions.setStepIds(stepIds))
        dispatch(stepSlice.actions.setQueryParams(queryParamsFromHeaders(response)))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(stepSlice.actions.isLoading(false))
      }
    },

    triggerNow: (id, onSuccess = () => {}, resetData = false) => async (dispatch) => {
      dispatch(stepSlice.actions.isRetryingTrigger(true))

      try {
        const response = await API.admin.steps.triggerNow(id, resetData)
        dispatch(entitySlice.actions.update({ data: response.data }))

        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(stepSlice.actions.isRetryingTrigger(false))
      }
    },

    create: (params = {}, onSuccess = () => {}) => async (dispatch) => {
      dispatch(stepSlice.actions.isSaving(true))

      try {
        const response = await API.admin.steps.create(params)
        dispatch(entitySlice.actions.add({
          data: response.data,
          reverseRelationships: [{ entityId: params.journeyId, entityType: 'journey', relationshipName: 'steps' }],
        }))

        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(stepSlice.actions.isSaving(false))
      }
    },
  },
}

const selectors = {
  getMetaData: () => state => state.steps.meta,

  getSteps: () => state => state.steps.stepIds.map(id => build(state.entities, 'step', id)) || [],
}

export default {
  ...stepSlice,
  selectors,
  asyncActions,
}
