import React, { useEffect, useRef, useState } from 'react'

import Card from 'components/common/card'
import API from 'services/api'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'
import ArticleItem from 'components/home/widgets/common/articles/articleItem'
import useApi from 'components/common/hooks/useApi'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.home.recent_news_widget')

const GAP_HEIGHT = 15 // Gap between articles (gap-3)

const MustSelectArticleIds = () => (
  <div className='d-flex flex-column align-items-center'>
    <CdnSvg className='WomanWithLens' src='/images/illustrations/womanWithLens.svg' />
    <span className='mt-2'>{I18N('select_article_type')}</span>
  </div>
)

const RecentNewsWidget = ({
  EmptyState = null,
  widget,
  resizeWidget,
  isEditing,
}) => {
  const { settings: { allArticles, articleTypeIds } } = widget
  const mustSelectArticleIds = isEditing && !allArticles && _.isEmpty(articleTypeIds)

  const cardRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const articleRefs = useRef<(HTMLDivElement | null)[]>([])

  const [articles, setArticles] = useState<any>([])
  const [fetchArticles, { data: fetchedArticles = [] }] = useApi(API.articles.targeted, {
    onSuccess: setArticles,
  })

  const [isReadyToDisplayArticles, setIsReadyToDisplayArticles] = useState(false)

  useEffect(() => {
    if (!allArticles && articleTypeIds.length === 0) return

    const params = { page: 1, articleTypeIds, allArticles }
    fetchArticles(params)
  }, [allArticles, JSON.stringify(articleTypeIds)])

  useEffect(() => {
    if (!_.isEmpty(articles) && widget.h > 0 && !isReadyToDisplayArticles) {
      // Create an array where each element at index i represents the cumulative height of all article elements up to and
      // including the element at index i, plus the gaps between them.
      const heights = articleRefs.current.reduce((acc, el, index) => {
        if (!el) return acc

        const elementHeight = el.clientHeight
        return [...acc, index === 0 ? elementHeight : acc[index - 1] + elementHeight + GAP_HEIGHT]
      }, [])

      // If the widget allows growing, we will show all articles that fit entirely in the widget, plus one more article if there are more than 50px of remaining space.
      // If the widget does not allow growing, we will show all articles that fit entirely in the widget.
      const buffer = widget.autoResizable ? 50 : 0
      const articlesToShow = heights.findIndex(height => height >= containerRef.current!.clientHeight + buffer)

      setArticles(fetchedArticles.slice(0, Math.max(articlesToShow, 1)))
      setIsReadyToDisplayArticles(true)
    }
  }, [_.isEmpty(articles), widget.h, isReadyToDisplayArticles])

  useEffect(() => {
    if (isEditing) {
      setArticles(fetchedArticles)
      setIsReadyToDisplayArticles(false)
    }
  }, [widget.h, widget.w])


  if (_.isEmpty(articles) && !mustSelectArticleIds) {
    return EmptyState
  }

  return (
    <Card className='RecentNewsWidget HomePageWidget ArticleWidgetType' cardRef={cardRef}>
      <WidgetTitle title={widget.title} />

      {mustSelectArticleIds ? (
        <MustSelectArticleIds />
      ) : (
        <div ref={containerRef} className='overflow-hidden flex-grow-1'>
          <AutoResizableScrollableSection
            widget={widget}
            resizeWidget={resizeWidget}
            isLoaded={isReadyToDisplayArticles && widget.h > 0}
            className='h-100'
            scrollableContentClassName='d-flex flex-column gap-3'
          >
            {articles.map((article, index) => (
              <div
                key={article.id}
                ref={el => articleRefs.current[index] = el}
                className={isReadyToDisplayArticles ? '' : 'visibility-hidden'}
              >
                <ArticleItem key={article.id} article={article} widgetWidth={cardRef.current?.clientWidth} />
              </div>
            ))}
          </AutoResizableScrollableSection>
        </div>
      )}

    </Card>
  )
}

export default RecentNewsWidget
