import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import EditableByModal from 'components/pages/editableByModal'

const I18N = i18nPath('views.pages')

const EditableByLink = ({ page }) => {
  const { editorIdsByType: { total: editorsCount } } = page
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className='Toolbar-link d-flex align-items-center' onClick={() => setIsModalOpen(true)}>
        <CdnSvg src='/images/addPencilIcon.svg' className='mr-1 EditableByLink-addPencilIcon' />
        <span className='font-weight-400'>{I18N('editable_by_people', { count: editorsCount })}</span>
      </div>
      {isModalOpen && (
        <EditableByModal
          visible={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          page={page}
          editorsCount={editorsCount}
        />
      )}
    </>
  )
}

export default EditableByLink
