import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'

export const defaultWorkingCopy = {
  locations: [],
  sendNotifications: true,
  description: '',
  displayName: '',
  imageUrl: '',
  numYears: 1,
  type: 'standard',
}

const defaultQueryParams = { ...defaultPaginationParams, perPage: 18 }

export const initialState = {
  badgeIds: [],
  badgeId: {},
  meta: {
    ...defaultMeta,
    queryParams: defaultQueryParams,
  },
}

const badgeSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    ...defaultActions,
    clearBadge(state) {
      state.badgeId = {}
      state.meta = initialState.meta
    },

    mergeBadgeIds(state, action) {
      state.badgeIds = _.uniq([
        ...state.badgeIds,
        ...action.payload,
      ])
    },

    clearBadgeIds(state) {
      state.badgeIds = []
      state.meta.queryParams = defaultQueryParams
    },

    loadBadgeId(state, action) {
      state.badgeId = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  fetchAll: (queryParams = {}) => async (dispatch) => {
    try {
      dispatch(badgeSlice.actions.isLoading(true))
      const response = await API.badges.fetchAll(queryParams)
      const badgeIds = response.data.data.map(badge => badge.id)
      const newQueryParams = queryParamsFromHeaders(response)

      dispatch(badgeSlice.actions.setQueryParams(newQueryParams))
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(badgeSlice.actions.mergeBadgeIds(badgeIds))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(badgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(badgeSlice.actions.isLoading(false))
    }
  },

  fetchBadge: badgeId => async (dispatch) => {
    try {
      dispatch(badgeSlice.actions.isLoading(true))
      const response = await API.badges.fetch(badgeId)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(badgeSlice.actions.loadBadgeId(badgeId))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      if (error.type === 'not_found') {
        dispatch(badgeSlice.actions.isNotFound(true))
      }
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(badgeSlice.actions.setError(error))
    } finally {
      dispatch(badgeSlice.actions.isLoading(false))
    }
  },

  create: (badge, onSuccess) => async (dispatch) => {
    try {
      dispatch(badgeSlice.actions.isSaving(true))

      const response = await API.badges.create(badge)
      const badgeId = response.data.data.id

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(badgeSlice.actions.loadBadgeId(badgeId))
      onSuccess(badgeId)
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(badgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(badgeSlice.actions.isSaving(false))
    }
  },

  update: (workingCopy, onSuccess = () => null) => async (dispatch) => {
    try {
      dispatch(badgeSlice.actions.isSaving(true))

      const response = await API.badges.update(workingCopy)

      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(badgeSlice.actions.setError(null))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(badgeSlice.actions.setError(error))
    } finally {
      dispatch(badgeSlice.actions.isSaving(false))
    }
  },

  destroy: (id, onSuccess) => async (dispatch) => {
    try {
      dispatch(badgeSlice.actions.isLoading(true))
      await API.badges.destroy({ id })

      dispatch(entitySlice.actions.remove({ id, type: 'badge' }))
      dispatch(badgeSlice.actions.clearBadge())
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(badgeSlice.actions.setError(error))
    } finally {
      dispatch(badgeSlice.actions.isLoading(false))
    }
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getBadges: () => state => state.badges.badgeIds.map(id => build(state.entities, 'badge', id)).filter(Boolean),

  getBadge: () => state => build(state.entities, 'badge', state.badges.badgeId) || {},

  getMetaData: () => state => state.badges.meta,
}

export default {
  ...badgeSlice,
  asyncActions,
  selectors,
}
