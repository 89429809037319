import React, { useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { useDispatch } from 'react-redux'
import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import classNames from 'classnames'

import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'

import EmployeeAudienceSelector from 'components/admin/journeys/audience/employeeAudienceSelector'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.audience_selector')

const RemoveJourneyBlueprintLogicButton = ({
  journeyBlueprint,
  toggle,
}) => {
  const dispatch = useDispatch()

  const howUsersAreAdded = journeyBlueprint?.howUsersAreAdded

  const onRemoveClick = () => {
    const params = {
      id: journeyBlueprint?.id,
      howUsersAreAdded: 'manually',
    }

    dispatch(journeyBlueprintSlice.asyncActions.admin.update(params, toggle))
  }

  if (howUsersAreAdded === 'manually') {
    // We return an empty div to use the space and show the save/cancel buttons to the right
    return <div />
  }

  return (
    <Button
      className='my-4'
      variant='danger'
      onClick={onRemoveClick}
    >
      {I18N('remove_logic')}
    </Button>
  )
}

const JourneyBlueprintTargetingModal = ({
  className = '',
  journeyBlueprint = {},
  visible,
  toggle,
}) => {
  const dispatch = useDispatch()

  const [workingCopy, setWorkingCopy] = useState(journeyBlueprint)
  const updateWorkingCopy = updatedWorkingCopy => setWorkingCopy({ ...workingCopy, ...updatedWorkingCopy })

  const journeyBlueprintType = journeyBlueprint.type

  const handleSave = () => {
    dispatch(journeyBlueprintSlice.asyncActions.admin.update(workingCopy, toggle))
  }

  return (
    <Modal size='lg' className={classNames('JourneyBlueprintTargetingModal', className)} visible={visible} toggle={toggle}>
      <h4 className='mb-3 text-left'>{I18N(`${journeyBlueprintType}.how_are_employees_added_to_journey`)}</h4>
      <p className='text-secondary'>{I18N('add_logic_to_add_people')}</p>
      <EmployeeAudienceSelector
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        journeyBlueprint={journeyBlueprint}
        hasEstimatedUsersStartingJourney={journeyBlueprint.state !== 'active'}
      />
      <div className='d-flex flex-row justify-content-between'>
        <RemoveJourneyBlueprintLogicButton
          journeyBlueprint={journeyBlueprint}
          toggle={toggle}
        />
        <div className='d-flex justify-content-end align-items-center'>
          <div role='button' tabIndex={0} onClick={toggle} className='mr-3 text-muted-dark'>
            {I18NCommon('cancel')}
          </div>
          <Button
            className='my-4'
            onClick={handleSave}
          >
            {I18NCommon('save')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default JourneyBlueprintTargetingModal
