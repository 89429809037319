import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import { Banner } from 'components/banners/banners'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import API from 'services/api'

const I18N = i18nPath('views.restricted_audience_configuration_preview_mode_banner')

const RestrictedAudienceConfigurationPreviewModeBanner = () => {
  const currentUser = useCurrentUser()

  const handleDecline = async () => {
    await API.admin.restrictedAudienceConfigurations.stopPreview()

    window.location.reload()
  }

  if (!currentUser.restrictedAudienceConfigurationPreviewMode) { return null }

  return (
    <Banner
      banner={{
        text: I18N('text'),
      }}
      handleDecline={handleDecline}
    />
  )
}

export default RestrictedAudienceConfigurationPreviewModeBanner
