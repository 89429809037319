import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { Link } from 'react-router-dom'
import Card from 'components/common/card'
import Stat from 'pages/admin/articles/stats/Stat'
import { getStatsValue } from 'pages/admin/articles/stats/helpers'
import StackedBarChart from 'components/common/charts/stackedBarChart'

const I18N = i18nPath('views.admin.article_stats')

const EmailSummary = ({ articleStat, articleId, isGroupPost = false }) => {
  const sent = getStatsValue(articleStat, 'emailTotalSent')
  const clicked = getStatsValue(articleStat, 'emailUniqueClicked')
  const opened = getStatsValue(articleStat, 'emailUniqueOpened')

  let openedPercentageText, clickedPercentageText = 0

  if (sent > 0) {
    openedPercentageText = I18n.toPercentage((opened / sent) * 100, { precision: 1 })
    clickedPercentageText = I18n.toPercentage((clicked / sent) * 100, { precision: 1 })
  }


  return (
    <Card cardBodyClassName='ArticleStatEmailCard' className='w-50'>
      <h5 className='mb-0 ml-1'>{I18N('email_summary.email')}</h5>
      <hr />
      {sent > 0 && (
        <StackedBarChart
          className='mt-4'
          data={[
            clicked > 0 && {
              tooltipText: I18N('email_summary.clicked_tooltip_count', { count: clicked }),
              className: 'clicked-opacity',
              value: clicked,
            },
            opened > 0 && {
              tooltipText: I18N('email_summary.opened_tooltip_count', { count: opened }),
              className: 'opened-opacity',
              value: opened - clicked,
            },
            {
              tooltipText: I18N('email_summary.sent_tooltip_count', { count: sent }),
              value: sent - opened,
            },
          ].filter(Boolean)}
        />
      )}

      <div className='d-flex flex-wrap email-stats mt-4 mb-4'>
        {sent > 0 && (
          <>
            <div>
              <Stat
                className='mr-5'
                value={clickedPercentageText}
                label={I18N('email_summary.clicked')}
                tooltip={I18N('email_summary.clicked_tooltip')}
                leftPart={<span className='legend-square clicked-opacity' />}
              />
            </div>
            <div>
              <Stat
                className='mr-5'
                value={openedPercentageText}
                label={I18N('email_summary.opened')}
                tooltip={I18N('email_summary.opened_tooltip')}
                leftPart={<span className='legend-square opened-opacity' />}
              />
            </div>
          </>
        )}
        <div>
          <Stat
            className='mr-5'
            value={getStatsValue(articleStat, 'emailTotalSent')}
            label={I18N('email_summary.sent')}
            tooltip={I18N('email_summary.sent_tooltip')}
            leftPart={<span className='legend-square' />}
          />
        </div>
      </div>

      <div>
        <Link to={`${isGroupPost ? '/posts/' : '/admin/articles/'}${articleId}/stats/email_engagement`}>
          {I18N('email_summary.email_engagement_link')}
        </Link>
      </div>
    </Card>
  )
}

export default EmailSummary
