import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { FUTURE_ONLY_MILESTONES } from 'components/admin/journeys/triggerSelect'

const I18N = i18nPath('views.admin.journeys.journey_blueprints')

export const customStepTriggerText = step => i18nMoment(step.triggerAt).format('MM-DD-YYYY HH:mm')

const stepTemplateTriggerText = (stepTemplate, stepTemplates = []) => {
  const numDaysAfterStartDate = stepTemplate?.triggerAfterNumDays
  const milestone = stepTemplate?.triggerMilestone

  const parent = stepTemplates.find(st => st?.id === stepTemplate?.parentId)
  const priorStepName = parent?.name || I18N('steps.step_completion.prior_step')

  if (numDaysAfterStartDate < 0) {
    if (FUTURE_ONLY_MILESTONES.includes(milestone)) {
      return I18N(`steps.${milestone}.on_day`, { priorStepName })
    }

    return I18N(`steps.${milestone}.before`, { count: -numDaysAfterStartDate, priorStepName })
  } else if (numDaysAfterStartDate > 0) {
    return I18N(`steps.${milestone}.after`, { count: numDaysAfterStartDate, priorStepName })
  }
  return I18N(`steps.${milestone}.on_day`, { priorStepName })
}

export default stepTemplateTriggerText
