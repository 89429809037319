import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice from 'redux/slices/surveys/forms'
import { useParams } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import surveyResponseSlice from 'redux/slices/surveys/responses'
import AnswerCard from 'components/admin/surveys/answers/answerCard'
import BackButton from 'components/common/backButton'
import UserHeader from 'components/admin/headers/userHeader'

const I18N = i18nPath('views.admin.surveys.responses.detail')

const ResponseHeaderSection = ({ response }) => (
  <div className='d-flex flex-column ml-3'>
    <span>
      <strong className='font-weight-600 mr-1'>
        {I18N('start_date')}:
      </strong>{i18nMoment(response?.createdAt).format('ll')}
    </span>
    <span className='mt-3'>
      <strong className='font-weight-600 mr-1'>
        {I18N('completed_date')}:
      </strong>{i18nMoment(response?.submittedAt).format('ll')}
    </span>
  </div>
)

const ResponseDetailPage = () => {
  const dispatch = useDispatch()
  const { formId, responseId } = useParams()

  const form = useSelector(surveyFormSlice.selectors.getForm(formId))
  const response = useSelector(surveyResponseSlice.selectors.getResponse(responseId))
  const user = response?.user
  const { isLoading } = useSelector(surveyResponseSlice.selectors.getMetaData())

  const questions = form?.questions || []

  useEffect(() => {
    dispatch(surveyResponseSlice.asyncActions.admin.fetch(formId, responseId))
  }, [])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='ResponseDetailPage d-flex align-items-center flex-column justify-content-center w-100'>
      <header className='AdminHeader d-flex flex-column pb-0 w-100'>
        {user ? (
          <UserHeader
            user={user}
            secondSectionChildren={<ResponseHeaderSection response={response} />}
          />
        ) : (
          <h2 className='mb-3'>{I18NCommon('anonymous_user')}</h2>
        )}
      </header>
      <main className='AdminContent w-100'>
        <BackButton
          className='mb-4'
          backToText={I18N('all_responses')}
          url={`${form.adminDisplayPath}?selectedTab=individual_responses`}
        />
        <label className='text-secondary font-weight-600'>{I18N('responses')}</label>
        {
          questions.map((question) => {
            const answer = response?.answers?.find(answer => answer.questionId === question.id)

            return (
              <AnswerCard
                className='mb-4'
                question={question}
                answer={answer}
                isDisabled
              />
            )
          })
        }
      </main>
    </div>
  )
}

export default ResponseDetailPage
