import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import classNames from 'classnames'

import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import linkSlice, { defaultWorkingCopy } from 'redux/slices/links'
import { showToastMessage } from 'redux/slices/toasts'

import Modal from 'components/common/modal'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'
import { AsyncSelect } from 'components/common/react_select'
import GoLinkOption from 'components/search/option_renderers/golinkOption'
import GoLinkForm from 'components/groups/key_links/goLinkForm'
import CdnSvg from 'components/common/cdnSvg'

const plusIconPath = '/images/plusIcon.svg'
const linkIconPath = '/images/linkOutlineIcon.svg'

const I18N = i18nPath('views.groups.add_go_link_modal')

const AddGoLinkToggle = ({
  title, iconSrc, selected, onSelect,
}) => (
  <div onClick={onSelect} className={classNames('toggle d-flex w-100 p-3', { selected })}>
    <CdnSvg src={iconSrc} className='ToggleIcon d-flex align-self-center mr-2' />
    <span className='align-self-end'>{title}</span>
  </div>
)

const AddGoLinkToggleGroup = ({ isNewSelected, onSelectNew }) => (
  <section className='AddGoLinkToggleGroup d-flex my-5 w-100 gap-3'>
    <AddGoLinkToggle
      title={I18N('create_new')}
      iconSrc={plusIconPath}
      selected={isNewSelected}
      onSelect={() => onSelectNew(true)}
    />
    <AddGoLinkToggle
      title={I18N('add_existing')}
      iconSrc={linkIconPath}
      selected={!isNewSelected}
      onSelect={() => onSelectNew(false)}
    />
  </section>
)

const Option = props => (
  <components.Option {...props}>
    <GoLinkOption option={props.data} className='GoLinkOption' />
  </components.Option>
)

const SingleValue = props => (
  <components.SingleValue {...props}>
    <GoLinkOption option={props.data} className='GoLinkOption' />
  </components.SingleValue>
)

const AddGoLinkForm = ({ collectionId, onClose }) => {
  const dispatch = useDispatch()
  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)
  const { isSaving, error } = useSelector(linkSlice.selectors.getMetaData())

  const areFieldsMissing = !workingCopy.name || !workingCopy.url
  const isSaveDisabled = isSaving || areFieldsMissing

  const handleFormChange = (field, value) => {
    setWorkingCopy({ ...workingCopy, [field]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const response = await dispatch(
      linkSlice.asyncActions.createAndAddLinkToCollection(workingCopy, {
        id: collectionId,
        type: 'group',
      })
    )
    if (response) {
      dispatch(showToastMessage({ message: I18N('link_added'), type: 'success' }))
      onClose(true)
    }
  }

  return (
    <GoLinkForm
      workingCopy={workingCopy}
      onChange={handleFormChange}
      onSubmit={handleSubmit}
      isSaving={isSaving}
      isSaveDisabled={isSaveDisabled}
      onCancel={onClose}
      error={error}
    />
  )
}

const SelectExistingGoLink = ({ collectionId, blocklistIds = [], onClose }) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState()
  const { isSaving } = useSelector(linkSlice.selectors.getMetaData())
  const isSaveDisabled = isSaving || !selected

  const getOptions = (inputValue, callback) => {
    API.globalSearch(inputValue, { type: 'go_link' }).then((response) => {
      callback(denormalizedJsonApiResponse(response, 'searchResult'))
    })
  }

  const filterOption = ({ data }) => !blocklistIds.includes(data.goLink.id)

  const handleSave = async () => {
    if (selected?.goLink?.id) {
      await dispatch(linkSlice.asyncActions.addLinkToCollection(selected.goLink.id, { id: collectionId }))
      dispatch(showToastMessage({ message: I18N('link_added'), type: 'success' }))
      onClose(true)
    }
  }

  return (
    <>
      <section className='mb-5'>
        <label className='font-weight-500'>{I18N('select_label')}</label>
        <AsyncSelect
          className='add-link-select'
          placeholder={I18N('select_placeholder')}
          value={selected}
          onChange={setSelected}
          getOptions={getOptions}
          filterOption={filterOption}
          components={{
            Option,
            SingleValue,
          }}
          minCharsToSearch={2}
        />
      </section>

      <div className='d-flex justify-content-end align-items-center'>
        <CancelButton onClick={onClose} />
        <ButtonNarrow onClick={handleSave} disabled={isSaveDisabled} showLoadingSpinner={isSaving}>
          {I18N('save')}
        </ButtonNarrow>
      </div>
    </>
  )
}

const AddGoLinkModal = ({
  groupId, collectionId, blocklistIds = [], onClose,
}) => {
  const dispatch = useDispatch()
  const [isNew, setIsNew] = useState(true)

  const handleClose = (shouldRefetch = false) => {
    if (shouldRefetch) {
      dispatch(linkSlice.asyncActions.fetchGroupGoLinks(groupId))
    }
    onClose()
  }

  return (
    <Modal visible className='AddGoLinkModal' title={I18N('title')} toggle={handleClose}>
      <div className='text-secondary'>{I18N('secondary_text')}</div>

      <AddGoLinkToggleGroup isNewSelected={isNew} onSelectNew={setIsNew} />

      {isNew ? (
        <AddGoLinkForm collectionId={collectionId} onClose={handleClose} />
      ) : (
        <SelectExistingGoLink collectionId={collectionId} blocklistIds={blocklistIds} onClose={handleClose} />
      )}
    </Modal>
  )
}

export default AddGoLinkModal
