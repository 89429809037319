import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import getPeopleTabNames from 'services/getPeopleTabNames'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import NavbarLink from 'components/navbar/navbarLink'
import SubNavbar from 'components/navbar/subNavbar'
import GlobalSearch from 'components/search/globalSearch'
import CompanyLogo from 'components/common/companyLogo'
import NotificationBell from 'components/notifications/notificationBell'
import NewPostButton from 'components/groups/posts/newPostButton'
import Banners from 'components/banners/banners'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useFirstAdminPath from 'components/common/hooks/useFirstAdminPath'
import AppLauncher from 'components/app_launcher/appLauncher'
import ProfileDropdown from 'components/navbar/profileDropdown'
import MobileNav from 'components/navbar/mobileNav'
import useWindowResize from 'components/common/hooks/useWindowResize'
import navbarSlice from 'redux/slices/navbarSlice'
import classNames from 'classnames'
import AskAiNavbarButton from 'components/ai/chat/ask_ai_modal/askAiNavbarButton'
import AskAiModal from 'components/ai/chat/ask_ai_modal/askAiModal'
import { AskAiProvider } from 'components/ai/chat/ask_ai_modal/askAiContext'

const I18N = i18nPath('views.navbar')

const PEOPLE_PATH_REGEX = /^\/people\//
const VIEW_OR_EDIT_CELEBRATION_PATH_REGEX = /^\/people\/celebrations\/.+/

const isNavbarVisible = (location) => {
  const pathName = location.pathname
  const isUnderPeople = PEOPLE_PATH_REGEX.test(pathName)
  const isViewingOrEditingCelebration = VIEW_OR_EDIT_CELEBRATION_PATH_REGEX.test(pathName)

  return isUnderPeople && !isViewingOrEditingCelebration
}

const Navbar = ({ hasBoxShadow }) => {
  const location = useLocation()

  const styles = useSelector(state => state.styles)
  const logo = useSelector(state => state.logo)
  const isHidden = useSelector(navbarSlice.selectors.isNavbarHidden())
  const { isUpToLargeScreen: useMobileNav } = useWindowResize()

  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const isAiPlatform = currentCompany?.type === 'ai_platform'

  const inPreboardingExperience = currentUser.inPreboardingExperience
  const showAppLauncher = currentCompany.settings.appLauncher?.enabled
  const isChatbotEnabled = currentCompany.settings.aiChatbot?.enabled

  const getNavbarColor = () => {
    const { color } = logo
    return color ? { backgroundColor: color } : {}
  }

  const calculateSubnavColor = (hex) => {
    // This function takes the hex value of the main navbar color and extracts the three color components with a regex,
    // then parses them to integers and concatenates them as an rgba value (so we can have a semi-transparent subnav)
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.85)`
    return rgba
  }

  const getSubnavColor = () => {
    const { color, subnavColor } = logo

    // if subnavColor exists, use it, otherwise compute color based off of the main nav color
    if (subnavColor) {
      return { backgroundColor: subnavColor }
    } else if (color) {
      return { backgroundColor: calculateSubnavColor(color) }
    } else {
      return {}
    }
  }

  const firstAdminPath = useFirstAdminPath()

  const getNavLinks = () => {
    const { settings } = currentCompany
    const homeUrl = _.get(settings, 'feed.enabled') ? '/' : settings.home.baseUrl
    const replaceHomeWithAppName = _.get(settings, 'feed.replaceHomeWithAppname')
    const navLinks = []

    if (settings.feed.enabled) {
      navLinks.push(
        <NavbarLink key='home' exact to={homeUrl}>
          {replaceHomeWithAppName ? currentCompany.appName : I18N('home')}
        </NavbarLink>
      )
    }

    // for now we only add home nav link to users in preboarding experience
    if (inPreboardingExperience) {
      return navLinks
    }

    if (isAiPlatform) {
      navLinks.push(
        <NavbarLink key='dashboard' to='/dashboard'>
          {I18NCommon('dashboard')}
        </NavbarLink>
      )
    }

    if (settings.news.enabled) {
      navLinks.push(
        <NavbarLink key='news' to='/news'>
          {I18N('news')}
        </NavbarLink>
      )
    }

    if (settings.people.enabled) {
      navLinks.push(
        <NavbarLink key='people' to='/people'>
          {I18N('people')}
        </NavbarLink>
      )
    }

    if (settings.pages.enabled) {
      navLinks.push(
        <NavbarLink key='pages' to='/pages'>
          {currentCompany.pageDisplayName || I18N('pages')}
        </NavbarLink>
      )
    }

    if (settings.golinks.enabled) {
      navLinks.push(
        <NavbarLink key='golinks' to='/golinks'>
          {I18N('golinks')}
        </NavbarLink>
      )
    }

    if (settings.qna.enabled) {
      navLinks.push(
        <NavbarLink key='qna' to='/events'>
          {currentCompany.eventDisplayName || I18N('events')}
        </NavbarLink>
      )
    }

    // render an 'Admin' tab if user has any admin path available to them and
    // is not in restricted configuration preview mode
    if (firstAdminPath && !currentUser.restrictedAudienceConfigurationPreviewMode) {
      navLinks.push(
        <NavbarLink key='admin' to='/admin'>
          {I18N('admin')}
        </NavbarLink>
      )
    }

    return navLinks
  }

  const showNavbar = isNavbarVisible(location)

  const getSubnavLinks = () => {
    const { settings } = currentCompany


    const subnavLinkTabNames = getPeopleTabNames(settings)
    const baseRoute = 'people'

    return subnavLinkTabNames.map(value => (
      <NavbarLink key={value} to={`/${baseRoute}/${value}`}>
        {value === 'teams' ? currentCompany.pluralGroupDisplayName : I18N(value)}
      </NavbarLink>
    ))
  }

  const { articleCard } = styles

  const navLinks = getNavLinks()
  const subnavLinks = getSubnavLinks()

  return (
    <header
      // position and z-index necessary to show boxshadow everywhere,
      // and is not covered by anything underneath.
      className={classNames('Navbar position-relative zindex-fixed', { isHidden })}
      style={{
        ...(articleCard
          ? { backgroundColor: articleCard.bgColor, color: articleCard.fontColor }
          : {}),
        ...(hasBoxShadow ? {} : { boxShadow: 'none' }),
      }}
    >
      <div className='Navbar-primary' style={getNavbarColor()}>
        {useMobileNav && (
          <div className='w-100'>
            <MobileNav
              navLinks={navLinks}
              peopleLinks={subnavLinks}
              inPreboardingExperience={inPreboardingExperience}
            />
          </div>
        )}
        <div className='container-fluid d-none d-lg-block'>
          <div className='row align-items-center'>
            <div className='col-8 d-flex align-items-center'>
              <div className=''>
                <CompanyLogo className='Navbar-companyImage' />
              </div>

              <div className='d-none d-lg-block ml-3'>
                <nav>{navLinks}</nav>
              </div>
            </div>

            <div className='col-4'>
              <div className='float-right d-flex align-items-center'>
                {!inPreboardingExperience && (
                  <>
                    <div className='New-Navbar-search d-none d-md-block'>
                      <GlobalSearch />
                    </div>

                    {isChatbotEnabled && (
                      <AskAiProvider>
                        <AskAiNavbarButton />
                        <AskAiModal />
                      </AskAiProvider>
                    )}

                    {showAppLauncher && (
                      <div className='ml-2'>
                        <AppLauncher />
                      </div>
                    )}

                    <div>
                      <NotificationBell />
                    </div>
                    <div className='d-none d-sm-block'>
                      <NewPostButton iconFill='var(--nav-text-active-color)' />
                    </div>
                  </>
                )}
                <div className='ml-2'>
                  <ProfileDropdown inPreboardingExperience={inPreboardingExperience} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showNavbar && <SubNavbar style={getSubnavColor()}>{subnavLinks}</SubNavbar>}

      {currentCompany.settings.banners.enabled && !inPreboardingExperience && (
        <Banners />
      )}
    </header>
  )
}

export default Navbar
