import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { i18nPath, i18nMoment, I18NCommon } from 'utils/i18nHelpers'
import surveyResponseSlice from 'redux/slices/surveys/responses'
import { Link } from 'react-router-dom'
import SmartTable from 'components/common/tables/smartTable'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import SurveyResponseType from 'types/surveys/response'
import UserLink from 'components/common/userLink'
import CdnSvg from 'components/common/cdnSvg'

const womanWithSpyglassPath = '/images/illustrations/womanWithSpyglass.svg'

const I18N = i18nPath('views.admin.surveys.responses.list')

const ResponsesTable = ({
  formId,
}) => {
  const dispatch = useDispatch()
  const responses = useSelector(surveyResponseSlice.selectors.getResponses())
  const { queryParams, isLoading } = useSelector(surveyResponseSlice.selectors.getMetaData())

  const {
    page, totalPages, total, perPage,
  } = queryParams

  const columns = [
    {
      header: I18N('name'),
      accessor: (d: SurveyResponseType) => (
        <UserLink
          user={d?.user}
          customClassName='font-weight-500 text-primary-link'
          unknownUserText={I18NCommon('anonymous_user')}
        />
      ),
    },
    {
      header: I18N('submitted_at'),
      accessor: (d: SurveyResponseType) => <span>{i18nMoment(d?.submittedAt).format('ll')}</span>,
    },
    {
      header: '',
      accessor: (d: SurveyResponseType) => <Link to={`/admin/surveys/${formId}/responses/${d?.id}`}>{I18N('view_responses')}</Link>,
    },
  ]

  const fetchResponses = (paginationParams = {}) => {
    const params = {
      page: 1,
      perPage: 10,
      ...paginationParams,
      sortBy: 'submitted_at',
      sortDir: 'desc',
    }

    dispatch(surveyResponseSlice.asyncActions.admin.fetchAll(formId, params))
  }

  useEffect(() => {
    if (formId) {
      fetchResponses()
    }

    return () => {
      dispatch(surveyResponseSlice.actions.clearResponseIds())
    }
  }, [formId])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (_.isEmpty(responses)) {
    return (
      <div className='EmptyState d-flex flex-column align-items-center justify-content-center'>
        <CdnSvg src={womanWithSpyglassPath} />
        <p className='mt-2 text-secondary'>{I18N('no_responses')}</p>
      </div>
    )
  }

  return (
    <SmartTable
      className='white-bg-table FormResponsesTable'
      data={responses}
      columns={columns}
      showPagination={true}
      page={page}
      pages={totalPages}
      totalCount={total}
      perPage={perPage}
      onPaginationClick={fetchResponses}
    />
  )
}

export default ResponsesTable
