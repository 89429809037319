import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useMemo, useState } from 'react'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import { useSelector } from 'react-redux'
import adminArticleSlice from 'redux/slices/admin/articles'
import { present } from 'components/common/utils'
import articleTypeSlice from 'redux/slices/articleTypes'
import useIsGroupPost from 'components/admin/articles/hooks/useIsGroupPost'

const generateNewWorkingCopy = ({
  authors,
  commentable,
  targetEntireCompany,
  group,
  articleType,
  restrictAccess,
}) => ({
  id: null,
  title: '',
  articleType,
  content: null,
  cardContent: null,
  draft: true,
  status: 'draft',
  groupId: group?.id,
  groupInformation: group ? { group } : undefined,
  restrictAccess,
  tags: [],
  authors,
  notificationChannels: [group?.msTeamChannel, group?.slackChannel].filter(present),
  sendSlackNotificationAs: 'bot',
  notificationChannelOverrides: group ? [] : ['email'],
  notificationPreference: group ? 'user_preference' : 'email_audience',
  alwaysShowInFeed: true,
  commentable,
  targetingRules: { ...TARGET_ENTIRE_COMPANY, targetEntireCompany },
  distributionLists: [],
  requireAcknowledgement: false,
})

export const useNewArticleWorkingCopy = ({ groupId = null } = {}) => {
  const currentUser = useCurrentUser()
  const { settings } = useCurrentCompany()
  const isGroupPost = useIsGroupPost({ groupId })
  const editableGroups = useSelector(adminArticleSlice.selectors.getEditableGroups())
  const groupArticleType = useSelector(articleTypeSlice.selectors.getGroupArticleType())

  const [isLoaded, setIsLoaded] = useState(false)

  const { enabled: commentsEnabled, default: commentsDefault } = settings.comments.articles

  const newWorkingCopy = useMemo(() => {
    const group = editableGroups.find(({ id }) => id === groupId) || editableGroups[0]

    setIsLoaded(isGroupPost ? !_.isEmpty(group) && !_.isEmpty(groupArticleType) : true)

    const restrictAccess = settings.news.restrictArticlesByDefault

    return generateNewWorkingCopy({
      authors: [{ ..._.pick(currentUser, ['id', 'preferredFullName', 'primaryPhotoThumbnailUrl', 'title', 'preferredName', 'preferredLastName']), active: true }],
      commentable: commentsEnabled && commentsDefault,
      targetEntireCompany: isGroupPost || restrictAccess ? false : currentUser.permissions.articleManager,
      group: isGroupPost ? group : null,
      articleType: isGroupPost ? groupArticleType : null,
      restrictAccess,
    })
  }, [groupId, groupArticleType?.id, JSON.stringify(editableGroups.map(({ id }) => id))])

  return { newWorkingCopy, isLoaded }
}

export default useNewArticleWorkingCopy
