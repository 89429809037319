import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.profile')

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildProfilePayload = profile => _.pick(profile, [
  'bio',
  'currentLatitude',
  'currentLocation',
  'currentLongitude',
  'currentTimezone',
  'customField1',
  'customField2',
  'customField3',
  'displayPronoun',
  'github',
  'id',
  'linkedin',
  'locale',
  'namePronunciationRecording',
  'phoneMobile',
  'softLaunchAccessRequestedAt',
  'twitter',
  'videoIntroductionRecording',
  'websiteLink',
  'workingDaysInOffice',
  'workingDaysRemote',
  'workingHoursEndTime',
  'workingHoursStartTime',
  'writtenNamePronunciation',
])

export const initialState = {
  currentProfileUserId: 0,
  associatedArticlesIdsOrderedByPublishedAtDesc: [],
  authoredArticlesIdsOrderedByPublishedAtDesc: [],
  associatedOrAuthoredArticlesIdsOrderedByPublishedAtDesc: [],
  events: [],
  editingField: null,
  editingContent: '',
  currentEvent: {
    start: {},
    end: {},
    location: '',
    attendees: [],
    summary: '',
  },
  editingError: false,
  meta: {
    isLoading: false,
    isDestroying: false,
    isPromoting: false,
    isLoadingArticles: false,
    isNotFound: false,
    isSaving: false,
    isFetchingCurrentEvent: false,
    isFetchingEvents: false,
    error: null,
    currentEventError: null,
    profilePhotoError: null,
    queryParams: defaultPaginationParams,
    isPhotoUploading: false,
  },
}

const profileSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    // This is where we want to expand our generic handling of errors from the Rails backend
    //   * Generic string error like 500's
    //   * Form based, return all fields that are populating a model.error and the standardized message per field, using this to create inline error messages
    setError(state, action) {
      state.meta.error = action.payload.message
    },

    setCurrentEventError(state, action) {
      state.meta.currentEventError = action.payload.message
    },

    setProfilePhotoError(state, action) {
      state.meta.profilePhotoError = action.payload.message
    },

    isNotFound(state, action) {
      state.meta.isNotFound = action.payload
    },

    isPromoting(state, action) {
      state.meta.isPromoting = action.payload
    },

    isDestroying(state, action) {
      state.meta.isDestroying = action.payload
    },

    isLoadingArticles(state, action) {
      state.meta.isLoadingArticles = action.payload
    },

    isLoading(state, action) {
      state.meta.isLoading = action.payload
    },

    isSaving(state, action) {
      state.meta.isSaving = action.payload
    },

    isFetchingCurrentEvent(state, action) {
      state.meta.isFetchingCurrentEvent = action.payload
    },

    isPhotoUploading(state, action) {
      state.meta.isPhotoUploading = action.payload
    },

    clearProfile() {
      // state = initialState doesn't work
      return initialState
    },

    clearCurrentEvent(state) {
      state.currentEvent = {
        start: {},
        end: {},
        location: '',
        attendees: [],
        summary: '',
      }

      state.currentEventError = null
    },

    setCurrentEvent(state, action) {
      state.currentEvent = action.payload
    },

    setAssociatedArticlesIdsOrderedByPublishedAtDesc(state, action) {
      const currentArticleIds = state.associatedArticlesIdsOrderedByPublishedAtDesc || []
      const incomingArticleIds = action.payload

      incomingArticleIds.forEach((id) => {
        if (currentArticleIds.indexOf(id) === -1) {
          currentArticleIds.push(id)
        }
      })

      state.associatedArticlesIdsOrderedByPublishedAtDesc = currentArticleIds
    },

    setAuthoredArticlesIdsOrderedByPublishedAtDesc(state, action) {
      const currentArticleIds = state.authoredArticlesIdsOrderedByPublishedAtDesc || []
      const incomingArticleIds = action.payload

      incomingArticleIds.forEach((id) => {
        if (currentArticleIds.indexOf(id) === -1) {
          currentArticleIds.push(id)
        }
      })

      state.authoredArticlesIdsOrderedByPublishedAtDesc = currentArticleIds
    },

    setAssociatedOrAuthoredArticlesIdsOrderedByPublishedAtDesc(state, action) {
      const currentArticleIds = state.associatedOrAuthoredArticlesIdsOrderedByPublishedAtDesc || []
      const incomingArticleIds = action.payload

      incomingArticleIds.forEach((id) => {
        if (currentArticleIds.indexOf(id) === -1) {
          currentArticleIds.push(id)
        }
      })

      state.associatedOrAuthoredArticlesIdsOrderedByPublishedAtDesc = currentArticleIds
    },

    handleEdit(state, action) {
      state.editingContent = action.payload
    },

    cancelEdit(state) {
      state.editingField = null
      state.editingContent = ''
      state.editingError = false
    },

    setCurrentProfileUserId(state, action) {
      state.currentProfileUserId = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    setEvents(state, action) {
      state.events = action.payload
    },

    clearEvents(state, _) {
      state.events = []
    },

    setIsFetchingEvents(state, action) {
      state.meta.isFetchingEvents = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(profileSlice, {
  asyncActions: {
    fetchProfile: (usernameOrId, clearProfile = true) => async (dispatch, getState) => {
      const inPreboardingExperience = getState().currentUser.inPreboardingExperience

      if (clearProfile) {
        dispatch(profileSlice.actions.clearProfile())
        dispatch(profileSlice.actions.isLoading(true))
      }

      try {
        let response
        if (inPreboardingExperience) {
          response = await API.journey.preboarding.users.fetch(usernameOrId)
        } else {
          response = await API.profile.fetch(usernameOrId)
        }
        const currentProfileUserId = response.data.data.id

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(profileSlice.actions.setCurrentProfileUserId(currentProfileUserId))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(profileSlice.actions.setError({ message: I18N('failed_fetch_profile') }))
        dispatch(profileSlice.actions.isNotFound(true))
      } finally {
        if (clearProfile) {
          dispatch(profileSlice.actions.isLoading(false))
        }
      }
    },

    fetchMentionedOrAuthoredArticles: (userId, queryParams) => async (dispatch) => {
      dispatch(profileSlice.actions.isLoadingArticles(true))

      try {
        const response = await API.articles.fetchByMentionedOrAuthoredUser({ userId, ...queryParams })
        const newQueryParams = queryParamsFromHeaders(response)
        const articleIds = response.data.data.map(article => article.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(profileSlice.actions.setQueryParams(newQueryParams))
        dispatch(profileSlice.actions.setAssociatedOrAuthoredArticlesIdsOrderedByPublishedAtDesc(articleIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(profileSlice.actions.setError({ message: I18N('failed_fetch_articles_by_user') }))
      } finally {
        dispatch(profileSlice.actions.isLoadingArticles(false))
      }
    },

    fetchEvents: username => async (dispatch) => {
      dispatch(profileSlice.actions.setIsFetchingEvents(true))

      try {
        const response = await API.calendars.fetchEvents(username)

        dispatch(profileSlice.actions.setEvents(response.data))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(profileSlice.actions.setIsFetchingEvents(false))
      }
    },

    update: (user, change, onSuccess = () => {}) => async (dispatch) => {
      dispatch(profileSlice.actions.isSaving(true))

      try {
        const response = await API.profile.update(user, buildProfilePayload(change))
        const currentProfileUserId = response.data.data.id

        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('save_profile_complete'), type: 'success' }))
        dispatch(profileSlice.actions.setCurrentProfileUserId(currentProfileUserId))
        onSuccess()
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(profileSlice.actions.setError({ message: I18N('failed_update_profile') }))
        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      } finally {
        dispatch(profileSlice.actions.isSaving(false))
      }
    },

    createPhoto: (photo, user, croppedAreaPixels) => async (dispatch) => {
      dispatch(profileSlice.actions.isPhotoUploading(true))

      try {
        const response = await API.profile.photos.create(photo, user, croppedAreaPixels)

        dispatch(entitySlice.actions.add({
          data: response.data,
          reverseRelationships: [{ entityId: user.id, entityType: 'user', relationshipName: 'photos' }],
        }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(profileSlice.actions.setProfilePhotoError({ message: I18N('photos.filetype_error') }))
        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      } finally {
        dispatch(profileSlice.actions.isPhotoUploading(false))
      }
    },

    destroyPhoto: photo => async (dispatch) => {
      dispatch(profileSlice.actions.isDestroying(true))
      try {
        const response = await API.profile.photos.destroy(photo)

        dispatch(entitySlice.actions.remove({ id: photo.id, type: 'photo' }))
        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(profileSlice.actions.setProfilePhotoError({ message: I18N('failed_destroy_photo') }))
      } finally {
        dispatch(profileSlice.actions.isDestroying(false))
      }
    },

    promotePhoto: photo => async (dispatch) => {
      dispatch(profileSlice.actions.isPromoting(true))

      try {
        const response = await API.profile.photos.promote(photo)

        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(profileSlice.actions.setProfilePhotoError({ message: I18N('failed_promote_photo') }))
      } finally {
        dispatch(profileSlice.actions.isPromoting(false))
      }
    },

    updatePhoto: photo => async (dispatch) => {
      dispatch(profileSlice.actions.isPhotoUploading(true))

      try {
        const response = await API.profile.photos.update(photo)

        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(profileSlice.actions.setProfilePhotoError({ message: I18N('failed_update_photo') }))
      } finally {
        dispatch(profileSlice.actions.isPhotoUploading(false))
      }
    },
  },
})

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
_.assign(profileSlice, {
  selectors: {
    getMetaData: () => state => state.profiles.meta,

    getCurrentProfileUser: () => state => build(state.entities, 'user', state.profiles.currentProfileUserId),

    getCurrentEvent: () => state => state.profiles.currentEvent,

    getAssociatedArticles: () => state => state.profiles.associatedArticlesIdsOrderedByPublishedAtDesc.map(id => build(state.entities, 'article', id)),

    getAuthoredArticles: () => state => state.profiles.authoredArticlesIdsOrderedByPublishedAtDesc.map(id => build(state.entities, 'article', id)),

    getAssociatedOrAuthoredArticles: () => state => state.profiles.associatedOrAuthoredArticlesIdsOrderedByPublishedAtDesc.map(id => build(state.entities, 'article', id)),

    getEvents: () => state => state.profiles.events.filter(Boolean),
  },
})

export default profileSlice
