export enum SizeEnum {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface LayoutType {
  id: string
  createdAt: string
  updatedAt: string
  size: SizeEnum
}
