import { Dispatch, SetStateAction, useState } from 'react'

function useUpdatableState<T>(initialState: T): [
  T,
  Dispatch<SetStateAction<T>>,
  (newState: Partial<T>) => void
] {
  const [state, setState] = useState<T>(initialState)
  const updateState = (newState: Partial<T>) => setState(oldState => ({ ...oldState, ...newState }))

  return [state, setState, updateState]
}

export default useUpdatableState
