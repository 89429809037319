import moment from 'moment'
import currentTimezone from 'utils/currentTimezone'
import { i18nPath } from 'utils/i18nHelpers'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import {
  organizerFullNameVariableName,
  recipientFullNameVariableName,
  journeyAdminVariableName,
  newHireFullNameVariableName,
  employeeFullNameVariableName,
  taskAssigneeFirstNameVariableName,
  colleagueFullNameVariableName
} from 'utils/journeys/interpolatedVariableNames'

const introductionTemplatesI18NPath = i18nPath('views.admin.journeys.introduction_templates')
const meetingTemplatesI18NPath = i18nPath('views.admin.journeys.meeting_templates')

export const DEFAULT_MILESTONE = {
  onboarding: 'new_hire_start_date',
  offboarding: 'employee_end_date',
  custom: 'journey_creation',
}

export const DEFAULT_TRIGGER_TIME = {
  onboarding: moment(new Date(2020, 1, 1, 9)).format(), // 9 am
  offboarding: moment(new Date(2020, 1, 1, 9)).format(), // 9 am
  custom: moment(new Date(2020, 1, 1, 0)).format(), // 0 am
}

export const DEFAULT_TRIGGER_AFTER_NUM_DAYS = {
  onboarding: -1,
  offboarding: -7,
  custom: -1,
}

export const DEFAULT_DUE_AFTER_NUM_DAYS = {
  onboarding: 0,
  offboarding: -1,
  custom: 7,
}

export const DEFAULT_TO_CORRESPONDENT_ROLE = {
  onboarding: 'new_hire',
  offboarding: 'offboarding_employee',
  custom: 'employee_on_journey',
}

export const defaultTriggerMilestone = (journeyBlueprintType) => {
  switch (journeyBlueprintType) {
  case 'onboarding':
    return 'new_hire_start_date'
  case 'offboarding':
    return 'employee_end_date'
  case 'custom':
    return 'journey_creation'
  default:
    return 'new_hire_start_date'
  }
}

export const DEFAULT_INTRODUCTION_TASK_NAME = {
  onboarding: introductionTemplatesI18NPath('onboarding.default_task_name', { newHireFullNameVariableName }),
  custom: introductionTemplatesI18NPath('custom.default_task_name', { employeeFullNameVariableName }),
}

export const DEFAULT_INTRODUCTION_TASK_INSTRUCTIONS = {
  onboarding: introductionTemplatesI18NPath('onboarding.default_task_instructions', { newHireFullNameVariableName, taskAssigneeFirstNameVariableName }),
  custom: introductionTemplatesI18NPath('custom.default_task_instructions', { employeeFullNameVariableName, taskAssigneeFirstNameVariableName }),
}

export const DEFAULT_INTRODUCTION_TASK_CALENDAR_EVENT_TITLE = {
  onboarding: introductionTemplatesI18NPath('onboarding.default_calendar_event_title', { newHireFullNameVariableName, colleagueFullNameVariableName }),
  custom: introductionTemplatesI18NPath('custom.default_calendar_event_title', { employeeFullNameVariableName, colleagueFullNameVariableName }),
}

export const defaultStepTemplateAttributes = journeyBlueprintType => ({
  replyToSetting: 'default',
  triggerTime: DEFAULT_TRIGGER_TIME[journeyBlueprintType],
  triggerAfterNumDays: DEFAULT_TRIGGER_AFTER_NUM_DAYS[journeyBlueprintType],
  triggerMilestone: DEFAULT_MILESTONE[journeyBlueprintType],
  triggerTimezone: currentTimezone(),
  triggerImmediatelyIfPastTrigger: true,
  triggerIfVariablesMissing: false,
  fromCorrespondentAttributes: { role: 'app' },
  toCorrespondentAttributes: {},
  targetingRules: TARGET_ENTIRE_COMPANY,
  weekendTriggerShift: null,
})

export const defaultTaskableTemplateAttributes = journeyBlueprintType => ({
  name: '',
  instructions: '',
  dueTime: moment(new Date(2020, 1, 1, 9)).format(), // 9 am
  dueAfterNumDays: DEFAULT_DUE_AFTER_NUM_DAYS[journeyBlueprintType],
  dueMilestone: DEFAULT_MILESTONE[journeyBlueprintType],
  notifyViaEmail: true,
  notifyViaSlack: true,
  notifyViaMsTeams: true,
  notifyViaSms: false,
  isTaskable: true,
  allowSilenceNotifications: true,
  useRecipientNotificationPreferences: false,
})

export const defaultStepAttributes = {
  replyToSetting: 'default',
  triggerAt: moment().format(),
  triggerTimezone: currentTimezone(),
  triggerIfVariablesMissing: false,
  fromCorrespondentAttributes: { role: 'app' },
  toCorrespondentAttributes: {},
}

export const defaultTaskableAttributes = {
  name: '',
  instructions: '',
  dueAt: null,
  notifyViaEmail: true,
  notifyViaSlack: true,
  notifyViaMsTeams: true,
  notifyViaSms: false,
  allowSilenceNotifications: true,
  useRecipientNotificationPreferences: false,
}

export const getMeetingCalendarEventDescriptionText = journeyBlueprintType => meetingTemplatesI18NPath(`${journeyBlueprintType}.default_calendar_event_description`, {
  organizerFullNameVariableName,
  recipientFullNameVariableName,
  journeyAdminVariableName,
})

export const getMeetingCalendarEventTitle = journeyBlueprintType => meetingTemplatesI18NPath(`${journeyBlueprintType}.default_calendar_event_title`, {
  organizerFullNameVariableName,
  recipientFullNameVariableName,
  journeyAdminVariableName,
})

export const getIntroductionCalendarEventDescriptionText = (
  journeyBlueprintType, automaticallyScheduleCalendarInvites = true
) => {
  if (automaticallyScheduleCalendarInvites) {
    return introductionTemplatesI18NPath(`${journeyBlueprintType}.default_calendar_event_description`, {
      newHireFullNameVariableName,
      colleagueFullNameVariableName,
      taskAssigneeFirstNameVariableName,
      employeeFullNameVariableName,
    })
  } else {
    return introductionTemplatesI18NPath(`${journeyBlueprintType}.default_calendar_event_description_not_automatic`, {
      newHireFullNameVariableName,
      colleagueFullNameVariableName,
      taskAssigneeFirstNameVariableName,
      employeeFullNameVariableName,
    })
  }
}

export const getIntroductionDefaultStepName = journeyBlueprintType => introductionTemplatesI18NPath(`${journeyBlueprintType}.default_step_name`)
