import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { i18nPath, i18nFormat } from 'utils/i18nHelpers'
import searchSlice from 'redux/slices/search'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.search.global_search.golink_not_found')

const GolinkNotFound = () => {
  const { query } = useQueryParams()
  const clearySearchResults = useSelector(searchSlice.selectors.getClearySearchResults('pageAll'))
  const { isLoading } = useSelector(searchSlice.selectors.getMetaData('pageAll'))
  const golinkResults = clearySearchResults
    .filter(result => result.goLink)

  if (isLoading || _.isEmpty(query)) return null

  return (
    <div className='GolinkNotFound text-center my-5'>
      <h5 className='mb-3'>{I18N('header')}</h5>
      <div>
        <Link to='/golinks/new'>{i18nFormat(I18N('create_golink'), `go/${query.replace('go/', '')}`)}</Link>{' '}
        <span className='text-secondary'>{I18N('sub_header_similar_results')}</span>
      </div>
    </div>
  )
}

export default GolinkNotFound
