import ArticleMissingFieldsPopover from 'components/admin/articles/articleMissingFieldsPopover'
import TemplateDropdown from 'components/admin/articles/templates/templateDropdown'
import { ButtonLink, ButtonSmallNarrow } from 'components/common/buttons'
import useNewArticleTemplateWorkingCopy from 'components/common/hooks/useNewArticleTemplateWorkingCopy'
import useApi from 'components/common/hooks/useApi'
import useQueryParams from 'components/common/hooks/useQueryParams'
import NavigationBlocker from 'components/common/navigationBlocker'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { buildArticleTemplatePayload } from 'redux/slices/articleTemplates'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import isEqualWithSets from 'utils/isEqualWithSets'
import useHideNavbar from 'components/navbar/hooks/useHideNavbar'
import { useDispatch } from 'react-redux'
import navigationBlocker from 'redux/slices/navigationBlocker'

const I18N = i18nPath('views.admin.article_editor')

const TemplateEditorNavbar = ({
  isLoading,
  isNewTemplate,
  template,
  workingCopy,
  update,
  isUpdating,
}) => {
  useHideNavbar()
  const history = useHistory()
  const dispatch = useDispatch()

  const { selectedTab } = useQueryParams()

  const newWorkingCopy = useNewArticleTemplateWorkingCopy()

  const areChangesPresent = useMemo(() => !isEqualWithSets(
    buildArticleTemplatePayload(template || newWorkingCopy),
    buildArticleTemplatePayload(workingCopy)
  ), [workingCopy, template])

  const [create, {
    isLoading: isCreating,
  }] = useApi(API.admin.article.templates.create, {
    toastErrorMessage: I18N('error_creating_template'),
    toastSuccessMessage: I18N('template_successfully_created'),
    onSuccess: (data) => {
      dispatch(navigationBlocker.asyncActions.performWithoutBlocking(() => {
        history.replace(`/admin/articles/templates/${data.id}?selectedTab=${selectedTab}`)
      }))
    },
  })

  const saveTemplate = (opts = {}) => {
    const params = buildArticleTemplatePayload({ ...workingCopy, ...opts })

    if (isNewTemplate) {
      create(params)
    } else {
      update(params)
    }
  }

  const isSaving = isUpdating || isCreating

  const { title } = workingCopy

  const missingFields = [
    !title && 'title',
  ].filter(Boolean)

  const isMissingFields = !_.isEmpty(missingFields)

  return (
    <div className='ArticleTemplateEditorNavbar replace-navbar'>
      <NavigationBlocker isBlocked={areChangesPresent && !isSaving} allowChangingQueryParams />
      <div className='BackAndStatusButtons'>
        <ButtonLink to='/admin/articles?selectedTab=templates' variant='secondary' className='ExitButton' size='sm'>
          {I18NCommon('exit')}
        </ButtonLink>

        {/* {template && <ArticleStatusLabel article={template} />} */}
      </div>

      {!isLoading && (
        <>
          <h6 className='ArticleTitle mb-0'>{isNewTemplate ? I18N('new_template') : template?.title}</h6>
          <div className='ActionButtons'>
            <ArticleMissingFieldsPopover missingFields={missingFields}>
              <ButtonSmallNarrow
                variant='primary'
                onClick={() => saveTemplate()}
                disabled={isSaving || !areChangesPresent || isMissingFields}
                showLoadingSpinner={isSaving}
              >
                {isNewTemplate ? I18N('create_template') : I18N('update_template')}
              </ButtonSmallNarrow>
            </ArticleMissingFieldsPopover>

            {!isNewTemplate && (
              <TemplateDropdown
                template={template}
                location='TemplateEditorPage'
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default TemplateEditorNavbar
