const getMessage = (toastMessage) => {
  if (typeof toastMessage === 'string') return toastMessage
  if (_.get(toastMessage, 'statusText')) return _.get(toastMessage, 'statusText')

  const { errors } = toastMessage

  let errorMessage = ''

  if (_.isArray(errors) && _.hasIn(errors[0], 'detail')) {
    errors.map(error => (errorMessage += _.get(error, 'detail')))
  } else {
    Object
      .keys(errors)
      .map(errorKey => (errorMessage += `${errorKey}: ${errors[errorKey].join(', ')}`))
  }

  return errorMessage
}

export default getMessage
