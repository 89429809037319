import React from 'react'

import { i18nPath, i18nMoment, I18NCommon } from 'utils/i18nHelpers'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import CdnSvg from 'components/common/cdnSvg'
import ClearyInternalSearchResultsTemplate from './clearyInternalSearchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const I18N = i18nPath('views.search.search_results.search_results')
const bookOpenIconPath = '/images/bookOpenIcon.svg'

const PageWorkspaceResult = ({
  result,
}) => {
  const { pageWorkspace, highlights, displayPath } = result

  const title = highlights?.title || pageWorkspace?.title
  const pagesCount = pageWorkspace?.pagesCount || 0

  return (
    <SearchResultTemplate
      pretitle={I18N('workspace')}
      displayPath={displayPath}
      image={(
        <EmojiOrPlaceholder
          selectedEmoji={pageWorkspace?.emojiCode}
        />
      )}
      title={title}
      subtitle={I18N('workspace_pages_count', { count: pagesCount })}
      descriptionExtraMargin
      result={result}
    />
  )
}

const Subtitle = ({
  page,
  lastUpdatedAt,
  updatedAt,
}) => {
  const lastUpdatedText = I18N('last_updated', { date: i18nMoment(lastUpdatedAt || updatedAt).format('ll') })
  const workspace = page?.workspace

  if (!workspace) {
    return <span className='text-small text-secondary truncate-text-at-1-lines'>{lastUpdatedText}</span>
  }

  return (
    <span className='text-small text-secondary truncate-text-at-1-lines'>
      <span>{I18NCommon('in')} <strong>{workspace?.title}</strong></span>
      <span className='mx-1'>•</span>
      <span>{lastUpdatedText}</span>
    </span>
  )
}

export const PageResult = ({
  result,
}) => {
  const { page, highlights } = result
  const displayPath = result.displayPath
  const { title, lastUpdatedAt, updatedAt } = { ...page, ...highlights }

  const content = highlights?.content

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={<CdnSvg src={bookOpenIconPath} />}
      title={title}
      subtitle={<Subtitle page={page} lastUpdatedAt={lastUpdatedAt} updatedAt={updatedAt} />}
      description={content}
      descriptionExtraMargin
      result={result}
    />
  )
}

const PageResults = ({ showLabel = true }) => {
  const mapPageToSearchResultTemplate = (result) => {
    if (result.pageWorkspace) {
      return <PageWorkspaceResult result={result} />
    }

    return <PageResult result={result} />
  }

  return (
    <ClearyInternalSearchResultsTemplate
      showLabel={showLabel}
      label='pages'
      type='page'
      extraTypes={['pageWorkspace']}
      searchResultToTemplateMap={mapPageToSearchResultTemplate}
    />
  )
}

export default PageResults
