import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import { usePagesNav } from 'components/pages/pagesNav/context'
import DeleteModal from 'components/common/modals/deleteModal'

const I18N = i18nPath('views.pages.workspaces.delete_workspace_modal')

const DeleteWorkspaceModal = ({
  workspace,
  visible = false,
  toggle = () => {},
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isDeleting } = useSelector(pageWorkspaceSlice.selectors.getMetaData())
  const { fetchNavigation } = usePagesNav()

  const afterDelete = () => {
    fetchNavigation()
    dispatch(pageWorkspaceSlice.asyncActions.fetchAll())
    toggle()

    history.push('/pages')
  }

  const deleteWorkspace = () => {
    dispatch(pageWorkspaceSlice.asyncActions.destroy(workspace, afterDelete))
  }

  return (
    <DeleteModal
      deleteText={I18N('delete_workspace')}
      cancelText={I18NCommon('cancel')}
      deleteSecondaryText={I18N('delete_now_text')}
      deleteConfirmText={I18N('confirm_delete')}
      showDeleteModal={visible}
      closeDeleteModal={toggle}
      deleteConfirm={deleteWorkspace}
      isDeleting={isDeleting}
    />
  )
}

export default DeleteWorkspaceModal
