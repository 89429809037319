import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { Bar } from 'react-chartjs-2'
import { surveyQuestionChartOptions as defaultChartOptions } from 'utils/chartVariables'
import StatusLabel from 'components/common/status_label'

const I18N = i18nPath('views.admin.surveys.questions.stats')

// Generate a gradient going from red to yellow to green
export const generateColorGradient = (minValue, maxValue) => {
  const steps = maxValue - minValue + 1
  const darkenFactor = 0.8 // Avoid having too bright colors

  const colors = Array.from({ length: steps }, (_, index) => {
    const ratio = index / (steps - 1)
    const red = ratio < 0.5 ? 255 * darkenFactor : 255 * (1 - (ratio - 0.5) * 2) * darkenFactor
    const green = ratio < 0.5 ? 255 * ratio * 2 * darkenFactor : 255 * darkenFactor
    return `rgba(${Math.round(red)}, ${Math.round(green)}, 0)`
  })

  return colors
}

const ScaleQuestionStats = ({ question, goToDetailPage }) => {
  const { config = {}, stats = {} } = question
  const { data = {}, totalAnswers = 0 } = stats

  const { minValue, maxValue } = config

  const range = _.range(minValue, maxValue + 1, config?.step || 1)

  const getLabelValue = stepValue => config.labels[stepValue] || stepValue

  return (
    <div className='ScaleQuestionStats'>
      <Bar
        options={{
          ...defaultChartOptions,
          scales: {
            x: { grid: { display: false } },
            y: {
              beginAtZero: true,
              grid: { display: false },
              max: 100,
              ticks: {
                stepSize: 10,
                autoSkip: false,
                callback: value => `${value}%`,
              },
            },
          },
          plugins: {
            ...defaultChartOptions.plugins,
            tooltip: {
              ...defaultChartOptions.plugins.tooltip,
              callbacks: {
                title: context => I18N('response_count',
                  { count: Math.round((context[0].parsed.y / 100) * totalAnswers) }
                ),
                label: () => '',
              },
            },
          },
          onClick: (_, elements) => goToDetailPage(range[elements[0]?.index]),
        }}
        data={{
          labels: range.map(step => getLabelValue(step)),
          datasets: [
            {
              data: range.map(step => ((data[step] || 0) / totalAnswers) * 100),
              backgroundColor: generateColorGradient(minValue, maxValue),
            },
          ],
        }}
      />
    </div>
  )
}

export default ScaleQuestionStats
