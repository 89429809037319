import React, { useState } from 'react'
import classNames from 'classnames'
import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { Creatable } from 'components/common/react_select'

const I18N = i18nPath('components.admin.send_preview_chat_message')

const SendPreviewChatMessageButtonAndModal = ({
  areChangesPresent,
  isSending,
  onSend,
  objectName,
  className = '',
}) => {
  const [showPreviewChatMessageModal, setShowPreviewChatMessageModal] = useState(false)

  return (
    <>
      <span className={classNames('link text-small', className)} onClick={() => setShowPreviewChatMessageModal(!showPreviewChatMessageModal)}>
        {I18N('send')}
      </span>
      <SendPreviewChatMessageModal
        visible={showPreviewChatMessageModal}
        closeModal={() => setShowPreviewChatMessageModal(false)}
        onSend={onSend}
        isSending={isSending}
        areChangesPresent={areChangesPresent}
        objectName={objectName}
      />
    </>
  )
}

export default SendPreviewChatMessageButtonAndModal

export const SendPreviewChatMessageModal = ({
  visible,
  closeModal,
  onSend,
  isSending,
  areChangesPresent,
  objectName,
}) => {
  const currentUser = useCurrentUser()
  const [selectedEmployees, setSelectedEmployees] = useState([currentUser])

  const sendPreviewChatMessage = () => {
    const userIds = selectedEmployees.map(user => user.id)
    onSend(userIds, closeModal)
  }

  return (
    <Modal
      visible={visible}
      toggle={closeModal}
    >
      <div className='d-flex flex-column px-3 pb-3'>
        <h3>{I18N('send_preview_slack_message')}</h3>

        {areChangesPresent && (
          <div className='alert alert-danger'>
            {I18N('unsaved_changes_warning', { object: objectName })}
            <br />
            {I18N('unsaved_changes_action')}
          </div>
        )}

        <p>{I18N('to_users_text')}</p>

        <EmployeeSearch
          className='mb-3'
          onChange={setSelectedEmployees}
          isMulti
          selectedEmployees={selectedEmployees}
        />

        <Button
          className='mt-3 align-self-center'
          onClick={sendPreviewChatMessage}
          disabled={isSending}
          dataTestId='send-preview-chat-message-submit-button'
        >
          {I18N('send_preview')}
        </Button>
      </div>
    </Modal>
  )
}
