import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import FormDatePickerField from 'components/form_fields/formDatePickerField'

const I18N = i18nPath('views.admin.users')

const UserDatePickerField = ({
  user,
  setWorkingCopy,
  fieldName,
  placeholder = '',
  isRequired,
  className = '',
  footnote = null,
}) => {
  const currentValue = user[fieldName]

  const onChange = (e) => {
    setWorkingCopy({ ...user, [fieldName]: e ? e.format() : null })
  }

  return (
    <FormDatePickerField
      currentValue={currentValue}
      onChange={onChange}
      fieldName={I18N(_.snakeCase(fieldName))}
      placeholder={placeholder}
      isRequired={isRequired}
      className={className}
      footNote={footnote}
    />
  )
}

export default UserDatePickerField
