import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { Bar } from 'react-chartjs-2'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { surveyQuestionChartOptions as defaultChartOptions } from 'utils/chartVariables'
import { transparent } from 'utils/color'

const I18N = i18nPath('views.admin.surveys.questions.stats')

const MultipleChoiceQuestionStats = ({ question, goToDetailPage }) => {
  const { options = [], stats = {} } = question
  const { data = {}, totalAnswers = 0 } = stats

  const currentCompany = useCurrentCompany()
  const {
    customStyles: {
      variables: {
        colors: {
          highlightColor,
        },
      },
    },
  } = currentCompany

  const getBackgroundColors = () => {
    const opacityDiffPerBar = (1 / options.length)
    return options.map((_l, i) => transparent(highlightColor, 1 - (opacityDiffPerBar * i)))
  }

  return (
    <div className='MultipleChoiceQuestionStats'>
      <Bar
        options={{
          ...defaultChartOptions,
          indexAxis: 'y', // Set the index axis to 'y' to make it a horizontal bar chart
          scales: {
            x: {
              beginAtZero: true,
              ticks: { callback: value => `${value}%` },
              max: 100,
              grid: { display: false },
            },
            y: {
              grid: { display: false },
            },
          },
          plugins: {
            ...defaultChartOptions.plugins,
            tooltip: {
              ...defaultChartOptions.plugins.tooltip,
              callbacks: {
                title: context => I18N('response_count', { count: Math.round((context[0].parsed.x / 100) * totalAnswers) }),
                label: () => '',
              },
            },
          },
          onClick: (_, elements) => goToDetailPage(options[elements[0]?.index]?.id),
        }}
        data={{
          labels: options.map(option => option?.value),
          datasets: [
            {
              data: options.map(option => ((data[option?.id] || 0) / totalAnswers) * 100),
              backgroundColor: getBackgroundColors(),
              maxBarThickness: 40,
            },
          ],
        }}
      />
    </div>
  )
}

export default MultipleChoiceQuestionStats
