
import React, { useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CollapsibleCard from 'components/common/collapsibleCard'
import { useDispatch, useSelector } from 'react-redux'
import externalEmailAuthenticationSlice from 'redux/slices/externalEmailAuthentications'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import DeleteModal from 'components/common/modals/deleteModal'
import { ButtonSmallNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.admin.external_email_authentications')

const IntegratedGmailAccountsTable = () => {
  const dispatch = useDispatch()
  const { otherConnectedAccounts } = useSelector(externalEmailAuthenticationSlice.selectors.getData())
  const [selectedExternalEmailAuthentication, setSelectedExternalEmailAuthentication] = useState(null)
  const { isDeleting } = useSelector(externalEmailAuthenticationSlice.selectors.getMetaData())

  const handleDelete = () => {
    dispatch(externalEmailAuthenticationSlice.asyncActions.admin.destroy(selectedExternalEmailAuthentication.id)).then(
      () => setSelectedExternalEmailAuthentication(null)
    )
  }

  if (_.isEmpty(otherConnectedAccounts)) {
    return null
  }

  const columns = [
    {
      header: I18NCommon('user'),
      accessor: d => <UserLink user={d.user} />,
    },
    {
      header: I18NCommon('actions'),
      accessor: d => (
        <ButtonSmallNarrow
          variant='secondary-danger'
          onClick={() => setSelectedExternalEmailAuthentication(d)}
        >
          {I18NCommon('delete')}
        </ButtonSmallNarrow>
      ),
    },
  ]

  return (
    <CollapsibleCard title={I18N('other_connected_accounts')} className='mb-3' initialIsOpen>
      <section className='IntegratedGmailAccountsTable'>
        <SmartTable
          data={otherConnectedAccounts}
          columns={columns}
          className='white-bg-table'
        />
      </section>
      {selectedExternalEmailAuthentication && (
        <DeleteModal
          deleteText={I18N('delete_modal.title')}
          deleteSecondaryText={I18N('delete_modal.text', { name: selectedExternalEmailAuthentication.user?.preferredFullName })}
          showDeleteModal={!!selectedExternalEmailAuthentication}
          closeDeleteModal={() => setSelectedExternalEmailAuthentication(null)}
          deleteConfirm={handleDelete}
          isDeleting={isDeleting[selectedExternalEmailAuthentication.id]}
        />
      )}
    </CollapsibleCard>
  )
}

export default IntegratedGmailAccountsTable
