import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { transparent } from 'utils/color'
import { i18nPath } from 'utils/i18nHelpers'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import HorizontalStackedBarGraph from 'components/analytics/common/horizontalStackedBarGraph'
import LegendItem from 'components/analytics/common/legendItem'
import TargetingRulesType from 'types/audience/targetingRules'

const I18N = i18nPath('views.platform_analytics.pages.read_time')

interface ReadTimeProps {
  pageId: string
  periodStart: string
  periodEnd: string | undefined
  targetingRules?: TargetingRulesType
}

const ReadTime = ({
  pageId,
  periodStart,
  periodEnd,
  targetingRules,
}: ReadTimeProps) => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()

  const { read, skimmed, glanced } = useSelector(pageAnalyticsSlice.selectors.getReadTime(pageId))
  const { readTime: { isLoading } } = useSelector(pageAnalyticsSlice.selectors.getMetaData())

  const { highlightColor: readColor } = currentCompany.customStyles.variables.colors
  const skimmedColor = transparent(readColor, 0.7)
  const glancedColor = transparent(readColor, 0.4)

  const total = read + skimmed + glanced

  const readPercentage = Math.round((read / total) * 100)
  const skimmedPercentage = Math.round((skimmed / total) * 100)
  const glancedPercentage = Math.round((glanced / total) * 100)

  useEffect(() => {
    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchReadTime(pageId, {
      periodStart,
      periodEnd,
      targetingRules,
    }))
  }, [periodStart, periodEnd, targetingRules])


  const labels = ['Read, Skimmed, Glanced']
  const datasets = [
    {
      label: I18N('read'),
      data: [readPercentage],
      backgroundColor: readColor,
      hoverBackgroundColor: readColor,
      barThickness: 34,
    },
    {
      label: I18N('skimmed'),
      data: [skimmedPercentage],
      backgroundColor: skimmedColor,
      hoverBackgroundColor: skimmedColor,
      barThickness: 34,
    },
    {
      label: I18N('glanced'),
      data: [glancedPercentage],
      backgroundColor: glancedColor,
      hoverBackgroundColor: glancedColor,
      barThickness: 34,
    },
  ]

  return (
    <div className='ReadTime analytics-card'>
      <header>
        <h3>{I18N('title')}</h3>
      </header>

      {isLoading ? (
        <CirclesLoadingIndicator className='w-100' />
      ) : (
        <>
          <HorizontalStackedBarGraph
            labels={labels}
            datasets={datasets}
            classname='w-100'
            generateTooltipLabel={(value, category) => (
              I18N('graph_tooltip_text', { value: `${value}%`, category: category.toLowerCase() })
            )}
          />

          <div className='d-flex flex-row w-100'>
            <LegendItem
              value={read}
              category={I18N('read')}
              backgroundColor={readColor}
              description={I18N('read_description')}
              classname='w-30'
            />
            <LegendItem
              value={skimmed}
              category={I18N('skimmed')}
              backgroundColor={skimmedColor}
              description={I18N('skimmed_description')}
              classname='w-30'
            />
            <LegendItem
              value={glanced}
              category={I18N('glanced')}
              backgroundColor={glancedColor}
              description={I18N('glanced_description')}
              classname='w-30'
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ReadTime
