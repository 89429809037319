import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import arrayMove from 'array-move'
import SortableTable from 'components/common/tables/sortableTable'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import { LayoutCollectionType } from 'types/layout/layoutCollection'
import useApi from 'components/common/hooks/useApi'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import LayoutCollectionDropdown from 'components/admin/layout/layoutCollectionDropdown'
import { ButtonLink } from 'components/common/buttons'

const I18N = i18nPath('views.admin.layout_collections_list')

const LayoutCollectionsListPage = ({ isPreboarding }) => {
  const [layoutCollections, setLayoutCollections] = useState<LayoutCollectionType[]>([])
  const { isLoading } = useFetch(
    () => API.admin.layout.layoutCollections.fetchAll({ pageType: isPreboarding ? 'preboarding' : 'home' }),
    [],
    { onSuccess: setLayoutCollections }
  )

  const [updateLayoutCollection, { isLoading: isSaving }] = useApi(API.admin.layout.layoutCollections.update, {
    toastSuccessMessage: I18N('layout_collection_updated'),
    toastErrorMessage: I18N('error_updating_layout_collection'),
    onSuccess: (data) => {
      setLayoutCollections(prev => prev.map(lc => (lc.id === data.id ? data : lc)))
    },
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const layoutCollection = layoutCollections[oldIndex]
    const newLayoutCollections = arrayMove(layoutCollections, oldIndex, newIndex)
    setLayoutCollections(newLayoutCollections)
    updateLayoutCollection({ id: layoutCollection.id, orderPosition: newIndex })
  }

  const columns = [
    {
      header: I18N('name'),
      accessor: lc => <Link to={lc.adminDisplayPath}>{lc.name}</Link>,
    },
    {
      header: I18N('audience'),
      accessor: lc => lc.audienceSummaryText,
    },
    {
      header: I18N('actions'),
      style: { width: '10%' },
      accessor: lc => (
        <LayoutCollectionDropdown
          layoutCollection={lc}
          onDelete={() => {
            setLayoutCollections(layoutCollections.filter(l => l.id !== lc.id))
          }}
          isListPage
          updateLayoutCollection={updateLayoutCollection}
        />
      ),
    },
  ]

  return (
    <div className='LayoutCollectionsListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N(isPreboarding ? 'preboarding_experience' : 'homepage')}</h3>
        <ButtonLink to={isPreboarding ? '/admin/preboarding_experience/new' : '/admin/homepage_design/new'}>
          {I18N(isPreboarding ? 'add_preboarding_homepage' : 'add_homepage')}
        </ButtonLink>
      </header>
      <main className='AdminContent'>
        {isLoading || _.isEmpty(layoutCollections) ? (
          <CirclesLoadingIndicator />
        ) : (
          <SortableTable
            data={layoutCollections}
            columns={columns}
            onSortEnd={onSortEnd}
            isSaving={isSaving}
          />
        )}
      </main>
    </div>
  )
}

export default LayoutCollectionsListPage
