import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LineGraph from 'components/analytics/common/lineGraph'
import { dateLabels } from 'components/analytics/helpers/dateLabelFormat'
import ReactSelect from 'components/common/react_select'
import newsAnalyticsSlice from 'redux/slices/analytics/news'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.news.content_created')


const FILTER_BY_OPTIONS = [
  {
    value: 'article',
    label: I18N('filter_by.articles'),
  },
  {
    value: 'team_post',
    label: I18N('filter_by.team_posts'),
  },
]


interface ContentCreatedProps {
  periodStart: string
  periodEnd?: string
  groupBy: string
}

const ContentCreated = ({ periodStart, periodEnd, groupBy }: ContentCreatedProps) => {
  const dispatch = useDispatch()
  const [filterBy, setFilterBy] = useState<string | undefined>(undefined)

  const { data } = useSelector(newsAnalyticsSlice.selectors.getArticlesPublished())
  const { articlesPublished: { isLoading } } = useSelector(newsAnalyticsSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(newsAnalyticsSlice.asyncActions.admin.fetchArticlesPublished(
      {
        periodStart, periodEnd, groupBy, articleType: filterBy,
      }
    ))
  }, [periodStart, periodEnd, groupBy, filterBy])

  const dataValues = data.map(({ value }) => value)
  const labels = dateLabels(data, groupBy)
  const articlesPublishedCount = dataValues.reduce((a, b) => a + b, 0)


  return (
    <div className='analytics-card grid-span-12'>
      <header className='pb-1'>
        <h3>{I18N('title')}</h3>
      </header>

      <div className='d-flex justify-content-center align-items-center'>
        <p className='text-secondary mb-0 mr-2'>{I18NCommon('filter_by')}</p>
        <ReactSelect
          className='article-status-select'
          options={FILTER_BY_OPTIONS}
          onChange={o => setFilterBy(o?.value)}
          value={FILTER_BY_OPTIONS.filter(o => o.value === filterBy)}
          isClearable
        />
      </div>

      <div className='d-flex flex-row align-items-start w-100'>
        {!isLoading && (
          <div className='flex-column align-items-start w-15'>
            <h3 className='mb-0'>{articlesPublishedCount}</h3>
            <p className='text-secondary mb-0'>{I18N('articles_published')}</p>
          </div>
        )}

        <div className='flex-column flex-grow-1'>
          <LineGraph
            isLoading={isLoading}
            labels={labels}
            dataValues={dataValues}
            tooltipLabel={I18N('articles')}
          />
        </div>
      </div>
    </div>
  )
}

export default ContentCreated
