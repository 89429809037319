import React, { useMemo } from 'react'
import SelectAudienceCard from 'components/admin/articles/audience_and_settings_editor/selectAudienceCard'
import NotificationsCard from 'components/admin/articles/audience_and_settings_editor/notificationsCard'
import SettingsCard from 'components/admin/articles/audience_and_settings_editor/settingsCard'
import CustomizeChatNotificationsCard from 'components/admin/articles/audience_and_settings_editor/customizeChatNotificationsCard'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ThumbnailCard from 'components/admin/articles/audience_and_settings_editor/thumbnailCard'
import API from 'services/api'
import { MUST_READS_WIDGET_TYPE, RECENT_NEWS_WIDGET_TYPE, SOCIAL_SHARE_WIDGET_TYPE } from 'utils/layout/widgetsMap'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import TemplateTeamsCard from 'components/admin/articles/audience_and_settings_editor/templateTeamsCard'


const hasSocialShareTag = (htmlContent) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlContent, 'text/html')

  const socialShares = doc.querySelectorAll('div.social-share')

  return socialShares.length > 0
}

const shouldShowThumbnailImageSection = (usedWidgets, workingCopy) => {
  if (usedWidgets?.includes(RECENT_NEWS_WIDGET_TYPE)) return true

  if (usedWidgets?.includes(MUST_READS_WIDGET_TYPE) && workingCopy.requireAcknowledgement) return true

  if (usedWidgets?.includes(SOCIAL_SHARE_WIDGET_TYPE)) {
    return hasSocialShareTag(workingCopy?.content) || hasSocialShareTag(workingCopy?.cardContent)
  }

  return false
}

interface Props {
  article?: any
  workingCopy: any
  updateWorkingCopy: any
  isEditingTemplate?: boolean
}

const ArticleAudienceAndSettingsEditor = (
  {
    article, workingCopy, updateWorkingCopy, isEditingTemplate,
  }: Props
) => {
  const { slackEnabled } = useCurrentCompany()
  const showCustomChatMessage = slackEnabled && !isEditingTemplate && (
    workingCopy.notificationPreference !== 'dont_notify' || (workingCopy.notificationChannels || []).length > 0
  )

  const { data: usedWidgets } = useGlobalStateBucket(API.layout.usedWidgets.fetchAll, 'usedWidgets')

  const showThumbnailImageSection = useMemo(
    () => shouldShowThumbnailImageSection(usedWidgets, workingCopy),
    [
      workingCopy.requireAcknowledgement,
      JSON.stringify(usedWidgets),
    ]
  )

  return (
    <div className='ArticleAudienceAndSettingsEditor'>
      <SelectAudienceCard
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
      />

      {!workingCopy.groupId && (
        <SettingsCard
          article={article}
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          isEditingTemplate={isEditingTemplate}
        />
      )}

      {showThumbnailImageSection && (
        <ThumbnailCard workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} />
      )}

      <NotificationsCard
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
      />

      {showCustomChatMessage && (
        <CustomizeChatNotificationsCard
          article={article}
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
        />
      )}

      {isEditingTemplate && (
        <TemplateTeamsCard
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
        />
      )}
    </div>
  )
}

export default ArticleAudienceAndSettingsEditor
