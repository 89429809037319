import React, { useEffect, useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'components/common/react_select'
import stepTemplateSlice from 'redux/slices/journeys/stepTemplates'

const I18N = i18nPath('views.admin.journeys.steps.new_step_type_modal')

const CREATE_FROM_SCRATCH = 'create_from_scratch'
const createFromScratchOption = { label: I18N(CREATE_FROM_SCRATCH), value: CREATE_FROM_SCRATCH }

const PrefilledStepTemplateSelector = ({
  selectedTemplate,
  setSelectedTemplate,
  journeyBlueprintType,
  selectedStepType,
}) => {
  const dispatch = useDispatch()
  const availableTemplates = useSelector(stepTemplateSlice.selectors.getAvailableTemplates())
  // eslint-disable-next-line max-len
  const templates = availableTemplates.filter(template => template.journeyBlueprintTypes.includes(journeyBlueprintType) && template.type === selectedStepType)

  // eslint-disable-next-line max-len
  const options = [createFromScratchOption, ...templates.map(template => ({ label: template.name, value: template.id }))]

  useEffect(() => {
    if (_.isEmpty(availableTemplates)) {
      dispatch(stepTemplateSlice.asyncActions.admin.fetchAvailableTemplates())
    }

    return () => {
      setSelectedTemplate(CREATE_FROM_SCRATCH)
    }
  }, [])

  return (
    <div className='PrefilledStepTemplateSelector my-3'>
      <label className='text-left font-weight-600 mb-1'>{I18N('select_a_template_or_create_your_own')}</label>
      <ReactSelect
        value={options.find(option => option.value === selectedTemplate)}
        options={options}
        onChange={selectedValue => setSelectedTemplate(selectedValue.value)}
      />
    </div>
  )
}

export default PrefilledStepTemplateSelector
