import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'
import API from 'services/api'

import { Button } from 'components/common/buttons'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.authentication.login_form')

const MagicLinkLoginForm = (props) => {
  const { register, handleSubmit } = useForm()
  const [emailFromQueryParam, setEmailFromQueryParam] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successfulEmailSubmission, setSuccessfulEmailSubmission] = useState(null)
  const { redirectUrl } = useQueryParams()
  const [submitError, setSubmitError] = useState(null)

  const { isUserPreboarding } = props

  useEffect(() => {
    const email = new URLSearchParams(window.location.search).get('email')

    if (email) {
      setEmailFromQueryParam(email)
    }
  }, [])

  const onSubmit = async (data) => {
    data.redirectUrl = redirectUrl
    if (isUserPreboarding) {
      data.preboarding = true
    }

    setIsSubmitting(true)

    try {
      setSubmitError(null)
      const response = await API.auth.generateMagicLink(data)

      setSuccessfulEmailSubmission(response.data.email)
    } catch (e) {
      setSubmitError(e.response?.data?.error)
    } finally {
      setIsSubmitting(false)
    }
  }


  return (
    <>
      {successfulEmailSubmission ? (
        <>
          <h3 className='mt-3 text-center'>{I18N('check_your_mailbox')}</h3>
          <div className='mt-3'>{i18nFormat(I18N('confirmation_sent'), <strong>{successfulEmailSubmission}</strong>)}</div>
          <div className='mt-3'>{I18N('expiration_notice')}</div>
        </>
      ) : (
        <>
          <div className='mb-2 text-center'>{I18N('enter_email')}</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group'>
              <input name='email' placeholder='email' defaultValue={emailFromQueryParam} ref={register} />
            </div>

            <div className='form-group'>
              <Button className='w-100' disabled={isSubmitting} type='submit'>
                {I18N('send_magic_link')}
              </Button>
            </div>

            <div className='text-center text-secondary text-small mb-2'>{I18N('magic_link_helper_desc')}</div>
            <div className='text-center text-secondary text-small'>{I18N('magic_link_helper_desc_secondary')}</div>
          </form>
          {submitError && <div className='text-error'>{submitError}</div>}
        </>
      )}
    </>
  )
}

export default MagicLinkLoginForm
