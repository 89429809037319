import useApi from 'components/common/hooks/useApi'
import React from 'react'
import API from 'services/api'
import { PageFAQType } from 'types/page/faq'
import Card from 'components/common/card'
import FAQForm from 'components/pages/faqs/faqForm'

type Props = {
  pageId: string
  onCancel: () => void
  onSave: (faq: PageFAQType) => void
}

const NewFAQ = ({ pageId, onCancel, onSave }: Props) => {
  const [createFAQ, { isLoading: isSaving }] = useApi(newFAQ => API.pagesFAQs.create(pageId, newFAQ), {
    onSuccess: onSave,
  })

  return (
    <Card>
      <FAQForm
        onSave={createFAQ}
        onCancel={onCancel}
        isSaving={isSaving}
      />
    </Card>
  )
}

export default NewFAQ
