import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import { checkForError } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import API from 'services/api'
import appSignal from 'services/appSignal'
import { i18nPath } from 'utils/i18nHelpers'
import queryParamsFromHeaders, { defaultPaginationParams, PaginationParams } from 'utils/queryParamsFromHeaders'

const I18N = i18nPath('views.admin.article_stats')


type EmailOpen = {
  time: string,
  clicks: {
    time: string,
    url: string
  }[]
}
export type EmailClick = {
  userIds: string[]
  uniqueClicksCount: number
  totalClicksCount: number
  url: string
}

export type ArticleStatsState = {
  userIds: string[],
  articleId?: string,
  emailOpens: EmailOpen[],
  emailClicks: EmailClick[],
  meta: DefaultMetaType & {
    queryParams: PaginationParams,
    isLoadingEmailOpens: boolean,
    isLoadingEmailClicks: boolean,
    isLoadingGeneratingCsv: boolean,
  },
}

export const initialState: ArticleStatsState = {
  userIds: [],
  articleId: undefined,
  emailOpens: [],
  emailClicks: [],
  meta: {
    queryParams: defaultPaginationParams,
    isLoadingEmailOpens: false,
    isLoadingEmailClicks: false,
    isLoadingGeneratingCsv: false,
    ...defaultMeta,
  },
}

const adminArticleSlice = createSlice({
  name: 'articleStats',
  initialState,
  reducers: {
    ...defaultActions,

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    setIsLoadingEmailOpens(state, action) {
      state.meta.isLoadingEmailOpens = action.payload
    },

    setIsLoadingEmailClicks(state, action) {
      state.meta.isLoadingEmailClicks = action.payload
    },

    setIsLoadingGeneratingCsv(state, action) {
      state.meta.isLoadingGeneratingCsv = action.payload
    },

    setUserIds(state, action) {
      state.userIds = action.payload
    },

    setEmailOpens(state, action) {
      state.emailOpens = action.payload
    },

    setEmailClicks(state, action) {
      state.emailClicks = action.payload
    },

    setArticleId(state, action) {
      state.articleId = action.payload
    },

    clearArticleId(state) {
      state.articleId = undefined
    },
  },
})

const asyncActions = {
  fetchArticleEmailEngagementUsers: (articleId: string, params?: Record<string, any>) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isLoading(true))

    try {
      const response = await API.admin.articles.fetchEmailEngagementUsers(articleId, params)
      dispatch(entitySlice.actions.add({ data: response.data }))
      const userIds = response.data.data.map(user => user.id)
      dispatch(adminArticleSlice.actions.setUserIds(userIds))
      dispatch(adminArticleSlice.actions.setQueryParams(queryParamsFromHeaders(response)))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.isLoading(false))
    }
  },

  fetchEmailOpens: (articleId: string, userId: string) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.setIsLoadingEmailOpens(true))
    dispatch(adminArticleSlice.actions.setEmailOpens([]))

    try {
      const response = await API.admin.articles.fetchEmailOpens(articleId, userId)
      const emailOpens = response.data
      dispatch(adminArticleSlice.actions.setEmailOpens(emailOpens))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.setIsLoadingEmailOpens(false))
    }
  },

  fetchEmailClicks: (articleId: string) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.setIsLoadingEmailClicks(true))
    dispatch(adminArticleSlice.actions.setEmailClicks([]))

    try {
      const response = await API.admin.articles.fetchEmailClicks(articleId)
      const emailClicks = response.data
      dispatch(adminArticleSlice.actions.setEmailClicks(emailClicks))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.setIsLoadingEmailClicks(false))
    }
  },

  exportEmailEngagementUsersCsv: (articleId: string) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.setIsLoadingGeneratingCsv(true))

    try {
      const response = await API.admin.articles.exportEmailEngagementUsersCsv(articleId)
      dispatch(showToastMessage({ message: response.data, type: 'success' }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(showToastMessage({ message: I18N('something_went_wrong'), type: 'error' }))
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.setIsLoadingGeneratingCsv(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.articleStats.meta,

  getUsers: () => (state: ReduxState) => state.articleStats.userIds.map(id => build(state.entities, 'user', id)).filter(Boolean),

  getEmailOpens: () => (state: ReduxState) => state.articleStats.emailOpens,

  getEmailClicks: () => (state: ReduxState) => state.articleStats.emailClicks,
}

export default {
  ...adminArticleSlice,
  selectors,
  asyncActions,
}
