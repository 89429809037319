import React from 'react'

import EventDraftLabel from 'components/qna/eventDraftLabel'
import EventCardQuestionCount from 'components/qna/eventCardQuestionCount'

const EventDraftIndicatorOrQuestionCount = ({ event }) => {
  const { questionsCount } = event

  if (event.isDraft) return <EventDraftLabel />

  return <EventCardQuestionCount questionsCount={questionsCount} />
}
export default EventDraftIndicatorOrQuestionCount
