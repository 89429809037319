import API from 'services/api'

import CalendarEventForm, {
  nonRequiredFields as calendarEventTemplateNonRequiredFields
} from 'components/admin/journeys/calendarEventTemplates/calendarEventForm'
import {
  CALENDAR_EVENT_TEMPLATE_ATTRIBUTES,
  defaultWorkingCopy as calendarEventTemplateDefaultWorkingCopy
} from 'redux/slices/journeys/calendarEventTemplates'

import CommunicationForm, {
  nonRequiredFields as communicationTemplateNonRequiredFields
} from 'components/admin/journeys/communicationTemplates/communicationForm'
import {
  COMMUNICATION_TEMPLATE_INTERPOLATED_FIELDS,
  defaultWorkingCopy as communicationTemplateDefaultWorkingCopy
} from 'redux/slices/journeys/communicationTemplates'
import { COMMUNICATION_TEMPLATE_ATTRIBUTES } from 'services/api/admin/journey/communicationTemplates'

import IntroductionForm, {
  nonRequiredFields as introductionTemplateNonRequiredFields
} from 'components/admin/journeys/introductionTemplates/introductionForm'
import {
  INTRODUCTION_TEMPLATE_ATTRIBUTES,
  INTRODUCTION_TEMPLATE_INTERPOLATED_FIELDS,
  defaultWorkingCopy as introductionTemplateDefaultWorkingCopy
} from 'redux/slices/journeys/introductionTemplates'

import MeetingForm, {
  MeetingsHelpCard,
  nonRequiredFields as meetingTemplateNonRequiredFields
} from 'components/admin/journeys/meetingTemplates/meetingForm'
import {
  MEETING_TEMPLATE_INTERPOLATED_FIELDS,
  defaultWorkingCopy as meetingTemplateDefaultWorkingCopy
} from 'redux/slices/journeys/meetingTemplates'
import { MEETING_TEMPLATE_ATTRIBUTES } from 'services/api/admin/journey/meetingTemplates'

import TaskForm, {
  nonRequiredFields as taskTemplateNonRequiredFields
} from 'components/admin/journeys/taskTemplates/taskForm'
import {
  TASK_TEMPLATE_ATTRIBUTES,
  TASK_TEMPLATE_INTERPOLATED_FIELDS,
  defaultWorkingCopy as taskTemplateDefaultWorkingCopy,
  buddyAssignmentDefaultWorkingCopy
} from 'redux/slices/journeys/taskTemplates'
import BuddyAssignmentHelpCard from 'components/admin/journeys/taskTemplates/buddyAssignmentHelpCard'


const stepTemplateMap = {
  calendar_event_templates: {
    FormComponent: CalendarEventForm,
    generateDefaultWorkingCopy: calendarEventTemplateDefaultWorkingCopy,
    entityTemplateAttributes: CALENDAR_EVENT_TEMPLATE_ATTRIBUTES,
    createApiPath: API.admin.calendarEventTemplates.create,
    fetchApiPath: API.admin.calendarEventTemplates.fetch,
    updateApiPath: API.admin.calendarEventTemplates.update,
    nonRequiredFields: calendarEventTemplateNonRequiredFields,
  },
  communication_templates: {
    FormComponent: CommunicationForm,
    generateDefaultWorkingCopy: communicationTemplateDefaultWorkingCopy,
    entityTemplateAttributes: COMMUNICATION_TEMPLATE_ATTRIBUTES,
    createApiPath: API.admin.journey.communicationTemplates.create,
    fetchApiPath: API.admin.journey.communicationTemplates.fetch,
    updateApiPath: API.admin.journey.communicationTemplates.update,
    interpolatedFields: COMMUNICATION_TEMPLATE_INTERPOLATED_FIELDS,
    nonRequiredFields: communicationTemplateNonRequiredFields,
  },
  introduction_templates: {
    FormComponent: IntroductionForm,
    generateDefaultWorkingCopy: introductionTemplateDefaultWorkingCopy,
    entityTemplateAttributes: INTRODUCTION_TEMPLATE_ATTRIBUTES,
    createApiPath: API.admin.introductionTemplates.create,
    fetchApiPath: API.admin.introductionTemplates.fetch,
    updateApiPath: API.admin.introductionTemplates.update,
    interpolatedFields: INTRODUCTION_TEMPLATE_INTERPOLATED_FIELDS,
    nonRequiredFields: introductionTemplateNonRequiredFields,
    postTriggerNames: ['due'],
  },
  meeting_templates: {
    FormComponent: MeetingForm,
    generateDefaultWorkingCopy: meetingTemplateDefaultWorkingCopy,
    entityTemplateAttributes: MEETING_TEMPLATE_ATTRIBUTES,
    createApiPath: API.admin.journey.meetingTemplates.create,
    fetchApiPath: API.admin.journey.meetingTemplates.fetch,
    updateApiPath: API.admin.journey.meetingTemplates.update,
    interpolatedFields: MEETING_TEMPLATE_INTERPOLATED_FIELDS,
    nonRequiredFields: meetingTemplateNonRequiredFields,
    postTriggerNames: (workingCopy) => { workingCopy?.scheduleWhenAttendeesAvailable ? ['rangeStart', 'rangeEnd'] : ['scheduleSpecifically'] },
    HelpCard: MeetingsHelpCard,
  },
  task_templates: {
    FormComponent: TaskForm,
    generateDefaultWorkingCopy: taskTemplateDefaultWorkingCopy,
    entityTemplateAttributes: TASK_TEMPLATE_ATTRIBUTES,
    createApiPath: API.admin.taskTemplates.create,
    fetchApiPath: API.admin.taskTemplates.fetch,
    updateApiPath: API.admin.taskTemplates.update,
    interpolatedFields: TASK_TEMPLATE_INTERPOLATED_FIELDS,
    nonRequiredFields: taskTemplateNonRequiredFields,
    postTriggerFields: ['due'],
  },
  buddy_assignment_templates: {
    FormComponent: TaskForm,
    generateDefaultWorkingCopy: buddyAssignmentDefaultWorkingCopy,
    entityTemplateAttributes: TASK_TEMPLATE_ATTRIBUTES,
    createApiPath: API.admin.taskTemplates.create,
    fetchApiPath: API.admin.taskTemplates.fetch,
    updateApiPath: API.admin.taskTemplates.update,
    interpolatedFields: TASK_TEMPLATE_INTERPOLATED_FIELDS,
    nonRequiredFields: taskTemplateNonRequiredFields,
    postTriggerFields: ['due'],
    HelpCard: BuddyAssignmentHelpCard,
  },
}

export default stepTemplateMap
