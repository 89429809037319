import EditAutoResizable from 'components/admin/layout/layout_collection_sidebar/common/editAutoResizable'
import EditTitleSetting from 'components/admin/layout/layout_collection_sidebar/common/editTitleSetting'
import LinkListItem from 'components/admin/layout/layout_collection_sidebar/links_widget_settings/linkListItem'
import { ButtonSmallNarrow } from 'components/common/buttons'
import React from 'react'
import { SortableContainer, arrayMove } from 'react-sortable-hoc'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.links')

const LinksWidgetSettingsSidebar = ({ widget, updateWidget }) => {
  const commonLinks = widget.commonLinks || []
  const deletedLinkIds = widget.deletedLinkIds || []
  const setCommonLinks = newLinks => updateWidget({ ...widget, commonLinks: newLinks })

  const updateLink = (link) => {
    setCommonLinks(commonLinks.map(l => (l.id === link.id ? link : l)))
  }

  const createLink = () => {
    setCommonLinks([
      ...commonLinks,
      {
        id: `new-${Date.now()}`,
        name: '',
        url: '',
        recordId: null,
        recordType: null,
      },
    ])
  }

  const deleteLink = (linkId) => {
    const updatedLinks = commonLinks.filter(link => link.id !== linkId)
    if (linkId.startsWith('new-')) {
      setCommonLinks(updatedLinks)
    } else {
      updateWidget({ ...widget, commonLinks: updatedLinks, deletedLinkIds: [...deletedLinkIds, linkId] })
    }
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return

    const updatedLinks = arrayMove(commonLinks, oldIndex, newIndex)

    setCommonLinks(updatedLinks.map((link: any, index: number) => ({ ...link, orderPosition: index })))
  }

  return (
    <div className='LinksWidgetSettingsSidebar'>
      <h5>{I18N('title')}</h5>

      <EditTitleSetting widget={widget} updateWidget={updateWidget} />

      <hr className='w-100' />

      <div className='w-100 mb-3 d-flex flex-column align-items-center gap-3'>
        <LinksList
          commonLinks={commonLinks}
          updateLink={updateLink}
          deleteLink={deleteLink}
          onSortEnd={onSortEnd}
          useDragHandle
          lockAxis='y'
          helperClass='isDraggingLink'
        />
        <ButtonSmallNarrow
          onClick={createLink}
          variant='secondary'
        >
          {I18N('add_link')}
        </ButtonSmallNarrow>
      </div>

      <hr className='w-100' />

      <EditAutoResizable widget={widget} updateWidget={updateWidget} />
    </div>
  )
}

type LinksListProps = {
  commonLinks: any[]
  updateLink: (link: any) => void
  deleteLink: (linkId: string) => void
}

const LinksList = SortableContainer<LinksListProps>(({ commonLinks, updateLink, deleteLink }) => (
  <div className='w-100 mb-3 d-flex flex-column align-items-center gap-3'>
    {commonLinks.map((link, index) => (
      <LinkListItem
        key={`LinkListItem-${link.id}`}
        index={index}
        link={link}
        onSave={updateLink}
        onDelete={deleteLink}
      />
    ))}
  </div>
))

export default LinksWidgetSettingsSidebar
