import React from 'react'
import { useDispatch } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice, { buildFormPayload, defaultWorkingCopy } from 'redux/slices/surveys/forms'
import { useParams } from 'react-router-dom'
import EditableContent from 'components/common/editable_content'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'

import FormStatusLabel from 'components/admin/surveys/formStatusLabel'
import FormSettingsDropdown from 'components/admin/surveys/formSettingsDropdown'
import FormHeaderPrimaryButton from 'components/admin/surveys/formHeaderPrimaryButton'
import Breadcrumbs from 'components/admin/headers/breadcrumbs'
import isValidQuestion from 'utils/surveys/isValidQuestion'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.admin.surveys')

export const QUESTIONS_TAB = 'questions'
export const RESULTS_TAB = 'results'
export const INDIVIDUAL_RESPONSES_TAB = 'individual_responses'
export const RECIPIENTS_TAB = 'recipients'
export const SCHEDULING_TAB = 'scheduling'
export const POSSIBLE_TABS = [QUESTIONS_TAB, RESULTS_TAB, INDIVIDUAL_RESPONSES_TAB, RECIPIENTS_TAB, SCHEDULING_TAB]

const FormHeader = ({
  form,
  workingCopy,
  isSaving,
}) => {
  const dispatch = useDispatch()
  const { formId } = useParams()

  const name = workingCopy?.name
  const formStatus = form?.status
  const isDraft = formStatus === 'draft'

  const saveName = (name: string) => {
    const changes = { id: formId, name }

    return dispatch(surveyFormSlice.asyncActions.admin.update(changes))
  }

  const workingCopyPayload = buildFormPayload(workingCopy)
  const isWorkingCopyEdited = !_.isEqual(workingCopy.questions, [])
  const hasChanges = isWorkingCopyEdited && !_.isEqual(buildFormPayload(form), workingCopyPayload)

  const saveDraftChanges = () => {
    if (!isDraft) return

    const questions = workingCopy?.questions || []
    const hasValidQuestions = questions.length > 0 && questions.every(q => isValidQuestion(q))

    if (hasChanges && hasValidQuestions) {
      dispatch(surveyFormSlice.asyncActions.admin.update(workingCopy, form))
    }
  }

  return (
    <header className='AdminHeader FormHeader d-flex flex-column pb-0 w-100 mb-0'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <Breadcrumbs
            breadcrumbs={[
              { text: I18N('surveys'), path: '/admin/surveys' },
              {
                text: (
                  <div className='d-flex align-items-center'>
                    <EditableContent
                      canEdit
                      displayElement={<h6 className='mb-0 text-secondary font-weight-600'>{name}</h6>}
                      inputElement={<input />}
                      valueToEdit={name}
                      saveContent={saveName}
                    />

                  </div>
                ),
                path: null,
              },
            ]}
          />
          <FormStatusLabel
            className='h-80 ml-2'
            status={form?.status}
          />
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          {isDraft && (
            <Button
              className='mr-2'
              variant='secondary'
              onClick={saveDraftChanges}
              disabled={!hasChanges}
              showLoadingSpinner={isSaving}
            >
              {I18NCommon('save_draft')}
            </Button>
          )}
          <FormHeaderPrimaryButton
            form={form}
            workingCopy={workingCopy}
          />
          <FormSettingsDropdown
            form={form}
            hasChanges={hasChanges}
          />
        </div>
      </div>
      <HistoryChangingPillTabSelector
        className='mt-3 justify-content-center'
        tabs={[
          { id: QUESTIONS_TAB, text: I18N('tabs.questions'), onClick: saveDraftChanges },
          { id: RECIPIENTS_TAB, text: I18N('tabs.recipients'), onClick: saveDraftChanges },
          { id: SCHEDULING_TAB, text: I18N('tabs.scheduling'), onClick: saveDraftChanges },
          { id: RESULTS_TAB, text: I18N('tabs.results'), hidden: isDraft },
          { id: INDIVIDUAL_RESPONSES_TAB, text: I18N('tabs.individual_responses'), hidden: isDraft },
        ]}
        pillClasses='text-normal'
      />
    </header>
  )
}

export default FormHeader
