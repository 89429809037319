import React, { useState } from 'react'
import moment from 'moment'

import TimeRangeFilter, { DEFAULT_DAILY_OPTION, TimeRangeValue } from 'components/analytics/common/timeRangeFilter'
import { I18NCommon } from 'utils/i18nHelpers'
import DateRangePicker from 'components/form_fields/dateRangePicker'
import DatePicker from 'components/form_fields/datePicker'

interface CustomTimeRangeProps {
  periodStart: string
  setPeriodStart: (periodStart: string) => void
  timeRange?: TimeRangeValue
  setTimeRange: (timeRange: TimeRangeValue) => void
  periodEnd?: string
  setPeriodEnd: (periodEnd?: string) => void
}

const CustomTimeRange = ({
  periodStart,
  setPeriodStart,
  periodEnd,
  setPeriodEnd,
  timeRange = DEFAULT_DAILY_OPTION,
  setTimeRange,
}: CustomTimeRangeProps) => {
  const handleFilterChange = (params) => {
    setTimeRange(params)
    setPeriodEnd(undefined)

    if (!['since', 'between'].includes(params.value)) setPeriodStart(params.value)
  }

  const handleDateRangeChange = (dateRange) => {
    setPeriodStart(moment(dateRange[0]).format('YYYY-MM-DD'))
    setPeriodEnd(moment(dateRange[1]).format('YYYY-MM-DD'))
  }

  return (
    <div className='CustomTimeRange OnlyDate d-flex flex-row'>
      <TimeRangeFilter
        value={timeRange}
        onFilterChange={handleFilterChange}
        showInputOptions
        optionsKey='custom'
        className='TimeRange'
      />
      {timeRange.value === 'since' && (
        <DatePicker
          placeholderText={I18NCommon('select_start_date')}
          showTimeSelect={false}
          selected={moment(periodStart)}
          onChange={newPeriodStart => setPeriodStart(newPeriodStart.format('YYYY-MM-DD'))}
          className='startDate ml-2'
          isClearable={false}
          dateFormat='MMM DD, YYYY'
        />
      )}
      {timeRange.value === 'between' && (
        <div className='ml-2 d-flex flex-row'>
          <DateRangePicker
            dateRange={[periodStart, periodEnd]}
            setDateRange={handleDateRangeChange}
          />
        </div>
      )}
    </div>
  )
}

export default CustomTimeRange
