import normalize from 'json-api-normalizer'

const getIdsFromResponse = (response = {}, entityType) => {
  const entities = normalize(response.data || {})
  const idsArray = Object.keys(entities[entityType] || {})

  return idsArray
}

export default getIdsFromResponse
