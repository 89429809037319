import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import MultipleMsTeamIntegrationCard from 'components/admin/integrations/multipleMsTeamIntegrationCard'
import MultipleSlackIntegrationCard from 'components/admin/integrations/multipleSlackIntegrationCard'
import CollapsibleCard from 'components/common/collapsibleCard'

const I18N = i18nPath('views.integrations_page.messaging_section')

const MessagingSection = () => (
  <CollapsibleCard title={I18N('messaging')} className='mb-3'>
    <section className='MessagingSection mb-3'>
      <MultipleSlackIntegrationCard />
      <MultipleMsTeamIntegrationCard />
    </section>
  </CollapsibleCard>
)

export default MessagingSection
