import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import queryParamsFromHeaders, { defaultPaginationParams, PaginationParams } from 'utils/queryParamsFromHeaders'
import { showToastMessage } from 'redux/slices/toasts'
import { ReduxState } from 'redux/redux'
import type { SimpleUserType } from 'types/user'
import JourneyType from 'types/journeys/journey'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'

export const initialState = {
  journeyIds: [],
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams,
      perPage: 10,
    },
    usersWithErrors: [],
  },
}

export interface JourneysState {
  journeyIds: string[],
  meta: DefaultMetaType & {
    queryParams: PaginationParams,
    isLoadingEmailOpens: boolean,
    isLoadingEmailClicks: boolean,
    isLoadingGeneratingCsv: boolean,
    usersWithErrors: SimpleUserType[],
  },
}

interface JourneySerializerResponse {
  relationships?: {
    journeyBlueprint: {
      data: {
        id: string
      }
    },
    user: {
      data: {
        id: string
      }
    }
  },
  attributes?: {
    state: string
  }
}

const simpleJourneyStructure = (id: string, journey: JourneySerializerResponse = {}) => ({
  id,
  type: 'simpleJourney',
  attributes: {
    userId: journey?.relationships?.user?.data?.id,
    journeyBlueprintId: journey?.relationships?.journeyBlueprint?.data?.id,
    state: journey?.attributes?.state,
  },
})

const journeySlice = createSlice({
  name: 'journeys',
  initialState,
  reducers: {
    ...defaultActions,
    setJourneyIds(state, action) {
      state.journeyIds = action.payload
    },

    clearJourneyIds(state) {
      state.journeyIds = []
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    setUsersWithErrors(state, action) {
      state.meta.usersWithErrors = action.payload
    },

    clearUsersWithErrors(state) {
      state.meta.usersWithErrors = []
    },
  },
})

const asyncActions = {
  admin: {
    fetchAll: (params = {}) => async (dispatch) => {
      dispatch(journeySlice.actions.clearJourneyIds())
      dispatch(journeySlice.actions.isLoading(true))

      try {
        const response = await API.admin.journey.journeys.fetchAll(params)

        const newQueryParams = queryParamsFromHeaders(response)
        const journeyIds = response.data.data.map(journey => journey.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(journeySlice.actions.setJourneyIds(journeyIds))
        dispatch(journeySlice.actions.setQueryParams({ ...params, ...newQueryParams }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(journeySlice.actions.isLoading(false))
      }
    },
    create: (journey: JourneyType, onSuccess = (journeyId: string) => null) => async (dispatch, getState) => {
      try {
        dispatch(journeySlice.actions.isSaving(true))
        const response = await API.admin.journey.journeys.create(journey)
        dispatch(entitySlice.actions.add({ data: response.data }))
        const journeyId = response.data.data.id
        const newJourneyIds = _.uniq([...getState().journeys.journeyIds, journeyId])
        dispatch(journeySlice.actions.setJourneyIds(newJourneyIds))
        onSuccess(journeyId)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(journeySlice.actions.setError(error))

        dispatch(showToastMessage({ message: error?.message, type: 'error' }))
      } finally {
        dispatch(journeySlice.actions.isSaving(false))
      }
    },
    remove: (journeyId: string, onSuccess = () => null) => async (dispatch) => {
      try {
        const params = {
          id: journeyId,
          state: 'removed',
        }

        dispatch(journeySlice.actions.isSaving(true))
        const response = await API.admin.journey.journeys.update(params)
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(entitySlice.actions.remove(simpleJourneyStructure(journeyId)))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(journeySlice.actions.setError(error))
      } finally {
        dispatch(journeySlice.actions.isSaving(false))
      }
    },
    batchCreate: (params, onSuccess = (usersWithErrors: boolean) => {}) => async (dispatch, getState) => {
      dispatch(journeySlice.actions.isSaving(true))

      try {
        const response = await API.admin.journey.journeys.batchCreate(params)

        const journeys = response.data.data
        const journeyIds = journeys.map(journey => journey.id)
        const simpleJourneys = journeys.map(journey => simpleJourneyStructure(journey?.id, journey))
        const newJourneyIds = _.uniq([...getState().journeys.journeyIds, ...journeyIds])

        const usersWithErrors = response.data.meta?.usersWithErrors || []

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(entitySlice.actions.add({ data: { data: simpleJourneys } }))
        dispatch(journeySlice.actions.setJourneyIds(newJourneyIds))
        dispatch(journeySlice.actions.setUsersWithErrors(usersWithErrors))
        onSuccess(usersWithErrors?.length > 0)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(journeySlice.actions.isSaving(false))
      }
    },
  },
}

const selectors = {
  getJourneys: () => (state: ReduxState) => state.journeys.journeyIds.map(id => build(state.entities, 'journey', id)) || [],
  getJourney: (journeyId: string) => (state: ReduxState) => build(state.entities, 'journey', journeyId),
  getMetaData: () => (state: ReduxState) => state.journeys.meta,
}

export default {
  ...journeySlice,
  selectors,
  asyncActions,
}

