import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import GreetingCardHeader from 'components/celebrations/greetingCardHeader'
import GreetingCardComments from 'components/celebrations/greetingCardComments'
import useTrackGreetingCardEvent from 'components/celebrations/hooks/useTrackGreetingCardEvent'
import classNames from 'classnames'
import useIsCelebrationCustomized from 'components/celebrations/hooks/useIsCelebrationCustomized'
import CelebrationModalContext, { useCelebrationModalValue } from 'components/celebrations/greeting_card_actions/celebrationModalContext'
import { Banner } from 'components/banners/banners'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.celebrations.greeting_card')

const CelebrationGreetingCardPage = ({ celebration, isCurrentUser }) => {
  const trackGreetingCardEvent = useTrackGreetingCardEvent()
  const customized = useIsCelebrationCustomized(celebration)
  const { settings } = useCurrentCompany()
  const canCustomize = !isCurrentUser && celebration.upcoming && settings.celebrations.manualCelebrations.enabled
  const history = useHistory()

  useEffect(() => {
    if (celebration && isCurrentUser && !celebration.openedAt) {
      history.replace(`/celebrations/${celebration.id}/open_card`)
    }
  }, [celebration])

  useEffect(() => {
    trackGreetingCardEvent('greeting_card_page_viewed', celebration, { viewer_is_recipient: isCurrentUser })
  }, [])

  const celebrationModalValue = useCelebrationModalValue(celebration)

  return (
    <CelebrationModalContext.Provider value={celebrationModalValue}>
      <main
        className={
          classNames('CelebrationGreetingCardPage')
        }
      >
        {celebration.customNotSent && (
          <Banner
            showDecline={false}
            banner={{
              text: (
                I18N('no_signatures_banner_text')
              ),
            }}
          />
        )}

        <GreetingCardHeader
          celebration={celebration}
          canCustomize={canCustomize}
        />

        <div className='px-2'>
          <GreetingCardComments
            celebration={celebration}
            showCustomizeButton={canCustomize && !customized}
          />
        </div>
      </main>
    </CelebrationModalContext.Provider>
  )
}

export default CelebrationGreetingCardPage
