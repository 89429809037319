import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import newHireSlice from 'redux/slices/journeys/newHires'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.remove_new_hire_modal')

const RemoveNewHireButtonAndModal = ({ user }) => {
  const dispatch = useDispatch()
  const { isSaving } = useSelector(newHireSlice.selectors.getMetaData())

  const [isModalOpen, setIsModalOpen] = useState()

  const removeNewHire = () => {
    const onSuccess = async () => {
      setIsModalOpen(false)
      dispatch(newHireSlice.actions.removeNewHireId(user.id))
    }

    dispatch(newHireSlice.asyncActions.admin.removeFromOnboardingBuddies(
      user.id,
      onSuccess,
      I18N('new_hire_removed')
    ))
  }

  return (
    <>
      <BootstrapModal.Header
        closeButton
        style={{ borderBottom: 'none' }}
        className='RemoveNewHireButtonAndModal p-2'
        onClick={() => setIsModalOpen(true)}
      />
      <Modal size='md' visible={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <div className='p-2 text-center'>
          <h3>{I18N('title', { name: user.preferredName })}</h3>
          <p className='text-secondary'>
            {I18N('help_text', { name: user.preferredFullName })}
          </p>
          <div className='d-flex align-items-center justify-content-center mt-4'>
            <div role='button' tabIndex={0} onClick={() => setIsModalOpen(false)} className='mr-3 text-muted-dark'>
              {I18NCommon('cancel')}
            </div>
            <Button
              disabled={isSaving}
              onClick={removeNewHire}
              showLoadingSpinner={isSaving}
              variant='danger'
            >
              {I18N('remove')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RemoveNewHireButtonAndModal
