import classNames from 'classnames'
import { ButtonSmallNarrow } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Link } from 'react-router-dom'

const I18N = i18nPath('views.celebrations.list_page.widgets')


const CelebrationListPageWidget = ({ type }) => (
  <div className={classNames('CelebrationListPageWidget border-radius position-relative p-3 w-100', type)}>
    <div className='text-container'>
      <h1>{I18N(`${type}.title`)}</h1>
      <p>{I18N(`${type}.description`)}</p>
    </div>

    <Link to={`/people/celebrations/new?type=${type}`}>
      <ButtonSmallNarrow>
        {I18NCommon('create')}
      </ButtonSmallNarrow>
    </Link>

    <CdnSvg src={`/images/illustrations/celebration_widgets/${type}.svg`} className='Illustration' />
  </div>
)

export default CelebrationListPageWidget
