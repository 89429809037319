import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MultilineGraph from 'components/analytics/common/multilineGraph'
import NumberPercentageToggle, { NUMBER, PERCENTAGE } from 'components/analytics/common/numberPercentageToggle'
import { dateLabels } from 'components/analytics/helpers/dateLabelFormat'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import newsAnalyticsSlice from 'redux/slices/analytics/news'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import TargetingRulesType from 'types/audience/targetingRules'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'
import API from 'services/api'


const I18N = i18nPath('views.platform_analytics.news.engagement')

const EXPORT_EVENT_NAMES = [
  'link:visited',
  'email:click',
  'email:open',
  'impression',
  'article:view',
  'article:view_all',
].join(',')

interface NewsEngagementProps {
  periodStart: string
  periodEnd?: string
  groupBy: string
  targetingRules?: TargetingRulesType
}

const NewsEngagement = ({
  periodStart, periodEnd, groupBy, targetingRules,
}: NewsEngagementProps) => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()

  const { data: engagementData } = useSelector(newsAnalyticsSlice.selectors.getEngagement())
  const { data: engagementCombinedData } = useSelector(newsAnalyticsSlice.selectors.getEngagementCombined())
  const { highlightColor, textColorSecondary } = currentCompany.customStyles.variables.colors

  const colors = {
    email: '#EE4D76',
    slack: '#611f69',
    all: highlightColor,
    app: textColorSecondary,
  }

  const { engagement: { isLoading: isLoadingEngagement } } = useSelector(newsAnalyticsSlice.selectors.getMetaData())
  const {
    engagementCombined: { isLoading: isLoadingEngagementCombined },
  } = useSelector(newsAnalyticsSlice.selectors.getMetaData())

  const [selectedType, setSelectedType] = useState(NUMBER)

  useEffect(() => {
    dispatch(newsAnalyticsSlice.asyncActions.admin.fetchEngagement(
      'engagement',
      {
        periodStart, periodEnd, groupBy, groupByEventType: true, type: selectedType, targetingRules,
      }
    ))

    dispatch(newsAnalyticsSlice.asyncActions.admin.fetchEngagement(
      'engagementCombined',
      {
        periodStart, periodEnd, groupBy, type: selectedType, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, groupBy, selectedType, targetingRules])

  const data = [
    ...engagementCombinedData.map(d => ({ ...d, eventType: 'all' })),
    ...engagementData,
  ]

  const dataGrouped = Object.values(_.groupBy(data, 'eventType'))

  const xlabels = dataGrouped.length > 0 ? dateLabels(dataGrouped[0], groupBy) : []

  const lines = dataGrouped.map(data => ({
    label: I18N(`labels.${data[0].eventType}`),
    color: colors[data[0].eventType || 'all'],
    values: data.map(({ value }) => value),
    tooltipLabel: count => `${I18N(`labels.${data[0].eventType}`)}: ${I18NCommon('unique_users_count', { count: selectedType === PERCENTAGE ? count + '%' : count })}`,
  }))

  const isLoading = isLoadingEngagement || isLoadingEngagementCombined

  const yAxisLabelKey = [
    'y_axis_label',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const onExport = (email: string) => {
    const exportXlsxParams = {
      periodStart,
      periodEnd,
      targetingRules,
      groupBy,
      eventNames: EXPORT_EVENT_NAMES,
      email,
      title: I18N('xlsx_title'),
    }

    return API.admin.analytics.engagement.exportXlsx(exportXlsxParams)
  }

  return (
    <div className='analytics-card grid-span-12'>
      <header className='d-flex flex-row justify-content-between pb-1'>
        <h3>{I18N('title')}</h3>
        <div className='d-flex flex-row align-items-center'>
          <NumberPercentageToggle selectedType={selectedType} setSelectedType={setSelectedType} />
          <ExportXlsxButton
            className='ml-1'
            onSubmit={onExport}
          />
        </div>
      </header>

      <MultilineGraph
        xlabels={xlabels}
        lines={lines}
        yAxisLabel={I18N(yAxisLabelKey)}
        isLoading={isLoading}
        asPercentage={selectedType === PERCENTAGE}
        height='250px'
      />
    </div>
  )
}

export default NewsEngagement
