import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import React, { useState } from 'react'

interface Props {
  label: string
  onClick?: () => void
  children: React.ReactNode,
  defaultIsCollapsed?: boolean
}

const CollapsableItem = ({
  label, onClick = () => {}, children, defaultIsCollapsed = true,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultIsCollapsed)

  const handleOnClick = () => {
    setIsCollapsed(value => !value)
    onClick()
  }

  return (
    <div className='CollapsableItem'>
      <div onClick={handleOnClick} className='pointer d-flex justify-content-between align-items-center mb-3'>
        <div className='text-secondary text-smallest font-weight-500'>{label}</div>
        <CdnSvg
          className={classNames('CollapsableItem__CloseIcon', isCollapsed && 'CollapsableItem__CloseIcon--right')}
          src='/images/triangleDown.svg'
        />
      </div>
      {!isCollapsed && children}
    </div>
  )
}

export default CollapsableItem
