const removeQueryParam = (history, key) => {
  const { location } = history
  const { search } = location
  const params = new URLSearchParams(search)

  params.delete(key)
  history.replace({
    ...location,
    search: params.toString(),
  })
}

export const addQueryParam = (history, key, value) => {
  const { location } = history
  const { search } = location
  const params = new URLSearchParams(search)
  params.set(key, value)
  history.push({
    ...location,
    search: params.toString(),
  })
}

export default removeQueryParam
