import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { ChatChannelTypeEnum } from 'types/chat/channel'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CheckBox from 'components/common/buttons/checkBox'

const I18N = i18nPath('views.admin.article_editor.audience_and_settings_editor')

interface Props {
  channels: any[]
  onChange: (any) => void
}

const NotificationChannelsSelector = ({
  channels,
  onChange,
}: Props) => {
  const { slackEnabled, msTeamEnabled } = useCurrentCompany()

  // only change the channels of the type received
  const handleChannelsChange = type => (newChannels) => {
    onChange([
      // lets keep all channels from other types
      ...channels.filter(c => c.type !== type),
      ...newChannels,
    ])
  }

  if (!slackEnabled && !msTeamEnabled) return null

  return (
    <>
      {slackEnabled && (
        <ChannelSelector
          value={channels.filter(c => c.type === ChatChannelTypeEnum.SlackChannel)}
          onChange={handleChannelsChange(ChatChannelTypeEnum.SlackChannel)}
          type={ChatChannelTypeEnum.SlackChannel}
        />
      )}
      {msTeamEnabled && (
        <ChannelSelector
          value={channels.filter(c => c.type === ChatChannelTypeEnum.MsTeamChannel)}
          onChange={handleChannelsChange(ChatChannelTypeEnum.MsTeamChannel)}
          type={ChatChannelTypeEnum.MsTeamChannel}
        />
      )}
    </>
  )
}

export default NotificationChannelsSelector

const ChannelSelector = ({ value, onChange, type }) => {
  const [showSelect, setShowSelect] = useState(value.length > 0)
  const handleToggleChange = () => {
    onChange([])
    setShowSelect(!showSelect)
  }

  const snakeCaseType = _.snakeCase(type)

  return (
    <>
      <CheckBox
        id={`${snakeCaseType}-checkbox`}
        checked={showSelect}
        onChange={handleToggleChange}
        label={I18N(`${snakeCaseType}.label`)}
        infoTooltip={I18N(`${snakeCaseType}.tooltip`)}
      />
      {showSelect && (
        <div className='mb-2'>
          <ChatChannelsAsyncSelect
            value={value}
            onChange={onChange}
            types={[type]}
          />
        </div>
      )}
    </>
  )
}
