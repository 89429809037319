import React, { useState } from 'react'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.common.beta_tag')

const BetaRibbon = ({
  className = '',
}) => (
  <div className={classNames('BetaRibbon text-smallest d-flex align-items-center justify-content-center text-white', className)}>
    {I18N('beta')}
  </div>
)

export default BetaRibbon
