import { useEffect } from 'react'
import API from 'services/api'
import { targetingRulesPresent } from 'utils/audience'
import { i18nPath } from 'utils/i18nHelpers'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'
import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import { useDebounce } from 'usehooks-ts'

const I18N = i18nPath('views.admin.includes.audience_selector')

const buildTargetingRulesPayload = targetingRules => _.omit(normalizeTargetingRules(targetingRules), 'users')

const useAudienceUserIds = ({
  targetingRules,
  allowLoadEntireCompany = false,
  newsSubscribedTagIds = undefined,
  endpoint = API.audiences.fetchEstimatedAudience,
  moduleName,
}) => {
  const [fetchAudience, { isLoading, data }] = useApi(endpoint, {
    toastErrorMessage: I18N('fetch_audience_estimate_error'),
  })

  const targetEntireCompany = targetingRules?.targetEntireCompany
  const noTargetingRules = !targetingRulesPresent(targetingRules)
  const shouldFetchAudience = !allowLoadEntireCompany && (targetEntireCompany || noTargetingRules)
  const audienceUserIds = shouldFetchAudience ? [] : data?.audienceUserIds || []

  const debouncedDependencies = useDebounce(
    [JSON.stringify([buildTargetingRulesPayload(targetingRules), newsSubscribedTagIds])]
  )

  useEffect(() => {
    if (shouldFetchAudience) {
      return
    }

    fetchAudience({
      ...buildTargetingRulesPayload(targetingRules),
      newsSubscribedTagIds,
      moduleName,
    })
  }, debouncedDependencies)

  return {
    isLoading, audienceUserIds,
  }
}

export const useGroupArticleAudienceUserIds = ({
  targetingRules,
  groupId,
}) => {
  const { isLoading, data } = useFetch(
    () => API.audiences.fetchGroupPostEstimatedAudience({ ...buildTargetingRulesPayload(targetingRules), groupId }),
    [JSON.stringify(buildTargetingRulesPayload(targetingRules)), groupId], {
      toastErrorMessage: I18N('fetch_audience_estimate_error'),
    }
  )

  const audienceUserIds = data?.audienceUserIds || []

  return {
    isLoading, audienceUserIds,
  }
}

export default useAudienceUserIds
