import React from 'react'
import { useHistory } from 'react-router-dom'
import { I18NCommon } from 'utils/i18nHelpers'
import CancelButton from 'components/common/cancelButton'
import CompanyLogo from 'components/common/companyLogo'
import EditableByLink from 'components/pages/editor/editableByLink'
import UsersCurrentlyEditing from 'components/pages/usersCurrentlyEditing'
import ViewableByLink from 'components/pages/viewableByLink'
import PublishPageButton from 'components/pages/editor/publishPageButton'
import PageDropdown from 'components/pages/editor/pageDropdown'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import AutosaveTag from 'components/common/autosaveTag'
import { useSelector } from 'react-redux'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'

const PageEditorNavbar = ({ page, setCurrentRightNav }) => {
  const history = useHistory()
  const { draft: isDraft } = page
  const viewPagePath = useWorkspaceUrls().generatePathToPage(page)
  const viewPageSearch = [isDraft && 'isPreviewing=true'].filter(Boolean).join('&')
  const areChangesPresent = useSelector(collaborativeEditorSlice.selectors.getAreChangesPresent())

  return (
    <div className='PageEditorNavbar replace-navbar d-flex align-items-center justify-content-between'>
      <CompanyLogo className='Navbar-companyImage px-3 pb-2 pt-3' />
      <div className='d-flex align-items-center gap-3 pr-3'>
        <UsersCurrentlyEditing />
        <AutosaveTag lastUpdatedAt={page.updatedAt} areChangesPresent={areChangesPresent} />
        {!isDraft && <ViewableByLink page={page} />}
        <EditableByLink page={page} />
        <PublishPageButton page={page} />
        <CancelButton
          onClick={() => history.push({ pathname: viewPagePath, search: viewPageSearch })}
          className='mr-0'
        >
          {I18NCommon('close')}
        </CancelButton>
        <PageDropdown page={page} setCurrentRightNav={setCurrentRightNav} />
      </div>
    </div>
  )
}

export default PageEditorNavbar
