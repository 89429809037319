import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import PillTabSelector from 'components/common/pillTabSelector'

import UsersWhoHaveNotRespondedTable from 'components/admin/surveys/responses/usersWhoHaveNotRespondedTable'
import ResponsesTable from 'components/admin/surveys/responses/responsesTable'
import SurveyFormType from 'types/surveys/form'
import SendFormNotificationButton from 'components/admin/surveys/responses/sendFormNotificationButton'

const RESPONDED = 'responded'
const NOT_RESPONDED = 'not_responded'

const I18N = i18nPath('views.admin.surveys.responses.list')

interface FormResponsesTabsAndTableProps {
  form: SurveyFormType
}

const FormResponsesTabsAndTable = ({
  form,
} : FormResponsesTabsAndTableProps) => {
  const [selectedTab, setSelectedTab] = useState(RESPONDED)

  const { anonymousResponses, stats, taskableForm } = form
  const responsesCount = stats?.responsesCount || 0
  const audienceSize = stats?.audienceSize || 0
  const isLive = form.status === 'live'

  if (anonymousResponses) {
    return (
      <>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <span className='text-secondary'>
            {`${responsesCount}/${audienceSize} ${I18N('responded')}`}
          </span>
          {!taskableForm && isLive && (
            <SendFormNotificationButton
              formId={form.id}
              sendToAllNotResponded
            />
          )}
        </div>
        <ResponsesTable formId={form.id} />
      </>
    )
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <PillTabSelector
          tabs={[
            {
              id: RESPONDED,
              text: I18N(RESPONDED),
              onClick: () => setSelectedTab(RESPONDED),
            },
            !anonymousResponses && {
              id: NOT_RESPONDED,
              text: I18N(NOT_RESPONDED),
              onClick: () => setSelectedTab(NOT_RESPONDED),
            },
          ].filter(Boolean)}
          pillClasses='text-normal'
        />
        {!taskableForm && isLive && (
          <SendFormNotificationButton
            formId={form.id}
            sendToAllNotResponded
          />
        )}
      </div>
      {selectedTab === RESPONDED && <ResponsesTable formId={form.id} />}
      {selectedTab === NOT_RESPONDED && <UsersWhoHaveNotRespondedTable formId={form.id} showActionsColumn={isLive} />}
    </>
  )
}

export default FormResponsesTabsAndTable
