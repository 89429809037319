import React from 'react'

import GlobalSearch from 'components/admin/customStyles/previewComponents/globalSearch'
import TextColorPrimary from 'components/admin/customStyles/previewComponents/textColorPrimary'
import TextColorSecondary from 'components/admin/customStyles/previewComponents/textColorSecondary'
import HighlightColor from 'components/admin/customStyles/previewComponents/highlightColor'
import TextColorMuted from 'components/admin/customStyles/previewComponents/textColorMuted'
import LinkColor from 'components/admin/customStyles/previewComponents/linkColor'
import BorderColor from 'components/admin/customStyles/previewComponents/borderColor'
import DangerColor from 'components/admin/customStyles/previewComponents/dangerColor'
import SuccessColor from 'components/admin/customStyles/previewComponents/successColor'
import CalendarLinkColor from 'components/admin/customStyles/previewComponents/calendarLinkColor'
import CardBackgroundColor from 'components/admin/customStyles/previewComponents/cardBackgroundColor'
import TagBackgroundColor from 'components/admin/customStyles/previewComponents/tagBackgroundColor'
import BannerBackgroundColor from 'components/admin/customStyles/previewComponents/bannerBackgroundColor'
import BannerTextColor from 'components/admin/customStyles/previewComponents/bannerTextColor'
import LikeHighlightColor from 'components/admin/customStyles/previewComponents/likeHighlightColor'
import BtnPrimaryColor from 'components/admin/customStyles/previewComponents/btnPrimaryColor'
import BtnMutedColor from 'components/admin/customStyles/previewComponents/btnMutedColor'
import SkillTagHoverBackgroundColor from 'components/admin/customStyles/previewComponents/skillTagHoverBackgroundColor'
import AwardedBadgeCardHeroColor from 'components/admin/customStyles/previewComponents/awardedBadgeCardHeroColor'
import ShoutoutCardBackgroundColor from 'components/admin/customStyles/previewComponents/shoutoutCardBackgroundColor'
import CelebrationCardBackgroundColor from 'components/admin/customStyles/previewComponents/celebrationCardBackgroundColor'
import BorderRadius from 'components/admin/customStyles/previewComponents/borderRadius'
import ButtonBorderRadius from 'components/admin/customStyles/previewComponents/buttonBorderRadius'
import ProfileImageBorderRadius from 'components/admin/customStyles/previewComponents/profileImageBorderRadius'
import CardBoxShadow from 'components/admin/customStyles/previewComponents/cardBoxShadow'
import NavbarBoxShadow from 'components/admin/customStyles/previewComponents/navbarBoxShadow'
import ProfileAwardedBadgeBorderWidth from 'components/admin/customStyles/previewComponents/profileAwardedBadgeBorderWidth'
import TimezonesCard from 'components/admin/customStyles/previewComponents/timezonesCard'
import CelebrationsLabelPreview from 'components/admin/customStyles/previewComponents/celebrationsLabelPreview'
import CelebrationListPageWidget from 'pages/people/celebrations_list_page/celebrationListPageWidget'

const previewComponentsList = {
  // COLORS
  highlightColor: <HighlightColor />,
  dangerColor: <DangerColor />,
  successColor: <SuccessColor />,
  textColorPrimary: <TextColorPrimary />,
  textColorSecondary: <TextColorSecondary />,
  textColorMuted: <TextColorMuted />,
  linkColor: <LinkColor />,
  calendarLinkColor: <CalendarLinkColor />,

  // COMPONENTS
  tagBackgroundColor: <TagBackgroundColor />,
  skillTagHoverBackgroundColor: <SkillTagHoverBackgroundColor />,
  bannerBackgroundColor: <BannerBackgroundColor />,
  bannerTextColor: <BannerTextColor />,
  likeHighlightColor: <LikeHighlightColor />,
  searchBgColor: <GlobalSearch />,
  timezoneHighlightColor: <TimezonesCard />,
  btnPrimaryColor: <BtnPrimaryColor />,
  btnMutedColor: <BtnMutedColor />,
  borderColor: <BorderColor />,
  squareBorderRadius: <BorderRadius />,
  profileImageBorderRadius: <ProfileImageBorderRadius />,
  buttonBorderRadius: <ButtonBorderRadius />,
  navBoxShadow: <NavbarBoxShadow />,
  cardBoxShadow: <CardBoxShadow />,
  profileAwardedBadgeBorderWidth: <ProfileAwardedBadgeBorderWidth />,

  // FEED
  cardBackgroundColor: <CardBackgroundColor />,
  awardedBadgeCardHeroColor: <AwardedBadgeCardHeroColor />,
  shoutoutCardBackgroundColor: <ShoutoutCardBackgroundColor />,
  birthdayCardBackgroundColor: <CelebrationCardBackgroundColor type='birthday' />,
  upcomingBirthdayColor: <CelebrationCardBackgroundColor type='birthday' upcoming />,
  workAnniversaryBackgroundColor: <CelebrationCardBackgroundColor type='work_anniversary' />,
  upcomingWorkAnniversaryColor: <CelebrationCardBackgroundColor type='work_anniversary' upcoming />,
  newEmployeeWelcomeColor: <CelebrationCardBackgroundColor type='new_employee_welcome' />,
  farewellCardBackgroundColor: <CelebrationListPageWidget type='farewell' />,
  achievementCardBackgroundColor: <CelebrationListPageWidget type='achievement' />,
  newParentCardBackgroundColor: <CelebrationListPageWidget type='new_parent' />,
  upcomingNewEmployeeWelcomeColor: <CelebrationCardBackgroundColor type='new_employee_welcome' upcoming />,
  birthdayCardLabelColor: <CelebrationsLabelPreview type='birthday' />,
  workAnniversaryLabelColor: <CelebrationsLabelPreview type='work_anniversary' />,
  newEmployeeWelcomeLabelColor: <CelebrationsLabelPreview type='new_employee_welcome' />,
  customCelebrationLabelColor: <CelebrationsLabelPreview type='new_parent' customCelebration />,
}

export default previewComponentsList
