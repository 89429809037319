import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Creatable } from 'components/common/react_select'
import { i18nPath } from 'utils/i18nHelpers'
import EditableTagContent from 'components/common/editable_content/editableTagContent'
import UserSkillTag from 'components/profiles/userSkillTag'
import LoadingSpinner from 'components/common/circlesLoadingIndicator'
import userSkillSlice from 'redux/slices/userSkills'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.profile')
const plusIconPath = '/images/plusIcon.svg'

const EmptySkillField = () => (
  <span className='d-inline-flex align-items-center font-weight-500'>
    <CdnSvg className='PlusIcon mr-1' src={plusIconPath} />
    {I18N('add_answer')}
  </span>
)

const UserSkillTags = ({ userSkills }) => {
  const history = useHistory()
  return (
    <div>
      {userSkills.map((userSkill, index) => (
        <UserSkillTag
          text={userSkill.name}
          key={index}
          onClick={() => history.push(`/user-skills/${userSkill.userSkillType.slug}/${userSkill.id}`)}
        />
      ))}
    </div>
  )
}

const DisplayElement = ({ initialSkills, currentValues }) => (
  _.isEmpty(currentValues) ? (
    <EmptySkillField />
  ) : (
    <UserSkillTags userSkills={initialSkills} />
  )
)

const UserSkill = ({
  canEdit,
  user,
  type,
  title,
}) => {
  const allUserSkills = useSelector(userSkillSlice.selectors.getAllSkills())
  const { isSavingUserSkillTaggings } = useSelector(userSkillSlice.selectors.getMetaData())

  // only show a user's skill taggings of the same skill type,
  // and transform it into an array of skill names (eg. ['Spanish'])
  const filteredTaggings = user.userSkillTaggings?.filter(
    tagging => tagging?.userSkill?.userSkillType?.slug === type
  ) || []
  const initialSkills = filteredTaggings.map(tagging => tagging.userSkill) || []
  const initialValues = initialSkills.map(userSkill => userSkill.name) || []

  // construct a list of options (ie. label-value pairs) for the react-select dropdown
  // for all skills belonging to the skill type (not just what the user has selected)
  const filteredUserSkills = allUserSkills.filter(userSkill => userSkill.userSkillType.slug === type)
                                          .map(userSkill => userSkill.name).sort()

  const options = filteredUserSkills.map(userSkillName => ({ label: userSkillName, value: userSkillName }))

  const [currentValues, setCurrentValues] = useState(initialValues)
  const isSaving = isSavingUserSkillTaggings === type
  const dispatch = useDispatch()

  const onChange = (selectedOptions) => {
    setCurrentValues(selectedOptions.map(option => option.value))
  }

  const onBlur = () => {
    const skillsToRemove = _.difference(initialValues, currentValues)
                            .map(name => ({ userSkillType: { slug: type }, name }))
    const skillsToAdd = _.difference(currentValues, initialValues)
                          .map(name => ({ userSkillType: { slug: type }, name }))

    dispatch(userSkillSlice.asyncActions.updateTaggings(skillsToAdd, skillsToRemove, type, user))
  }

  if (_.isEmpty(currentValues) && !canEdit) {
    return null
  }

  return (
    <div className='UserSkillType w-100'>
      <h6>{title}</h6>
      <div className='w-100'>
        {isSaving ? (
          <LoadingSpinner />
        ) : (
          <EditableTagContent
            canEdit={canEdit}
            displayElement={<DisplayElement initialSkills={initialSkills} currentValues={currentValues} />}
            handleBlur={onBlur}
            inputElement={(
              <Creatable
                isMulti
                name='userSkills'
                value={currentValues.map(v => ({ label: v, value: v }))}
                onChange={onChange}
                options={options}
                removeSelected={false}
                placeholder=''
                blurInputOnSelect={false}
              />
            )}
            isEmpty={_.isEmpty(currentValues)}
          />
        )}
      </div>
    </div>
  )
}

export default UserSkill
