import React from 'react'

import SuggestedIcon from 'components/common/suggestedIcon'
import cleanUrl from 'utils/cleanUrl'
import AppType from 'types/app'
import SearchResultOption from './searchResultOption'

const AppOption = ({ option }) => {
  const { app, highlights } : { app: AppType, highlights: Partial<Pick<AppType, 'name' | 'url'>> } = option
  const { name, url } = { ...app, ...highlights }

  return (
    <SearchResultOption
      image={(
        <SuggestedIcon
          name={app.name}
          url={app.url}
          iconUrl={app.iconUrl}
          height={22}
          width={22}
        />
      )}
      title={name}
      secondaryTexts={[cleanUrl(url)]}
    />
  )
}

export default AppOption
