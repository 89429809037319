import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { i18nPath, i18nMomentWithTimezone } from 'utils/i18nHelpers'
import calendarEventSlice, { CALENDAR_EVENT_ATTRIBUTES } from 'redux/slices/journeys/calendarEvents'

import CalendarEventForm from 'components/admin/journeys/calendarEventTemplates/calendarEventForm'

import { LoadingContainer } from 'components/common/loadingContainer'
import useStepManagement from 'components/admin/journeys/hooks/useStepManagement'
import EditEntityHeader from 'components/admin/journeys/common/editEntityHeader'
import StepAdminBanner from 'components/admin/journeys/stepAdminBanner'
import currentTimezone from 'utils/currentTimezone'

const I18N = i18nPath('views.admin.journeys.calendar_events.edit_calendar_event_page')

const EditCalendarEventPage = () => {
  const { id } = useParams()

  const calendarEvent = useSelector(calendarEventSlice.selectors.getCalendarEvent(id))
  const calendarEventTemplate = calendarEvent?.calendarEventTemplate
  const {
    isLoading, isNotFound, isSaving,
  } = useSelector(calendarEventSlice.selectors.getMetaData())

  const step = calendarEvent?.step
  const triggerAt = step?.triggerAt
  const journeyBlueprint = step?.journey?.journeyBlueprint

  const invitationSent = calendarEvent?.completionStatus === 'invited'

  const timezone = step?.triggerTimezone || currentTimezone()

  const {
    workingCopy,
    updateWorkingCopy,
    hasChanges,
    onSave,
  } = useStepManagement({
    id,
    entity: calendarEvent,
    entityTemplate: calendarEventTemplate,
    entityAttributes: CALENDAR_EVENT_ATTRIBUTES,
    fetchEntity: calendarEventSlice.asyncActions.admin.fetchCalendarEvent,
    updateEntity: calendarEventSlice.asyncActions.admin.updateCalendarEvent,
  })

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='EditCalendarEventPage'>
        <EditEntityHeader
          type='calendar_event'
          entity={calendarEvent}
          entityTemplate={calendarEventTemplate}
          entityI18n={I18N}
        />
        <StepAdminBanner
          step={step}
          triggeredText={I18N('calendar_event_invited_banner_description',
            {
              sentHour: `${i18nMomentWithTimezone({ date: triggerAt, timezone, format: 'hh:mm A' })} (${timezone})`,
              sentDate: i18nMomentWithTimezone({ date: triggerAt, timezone, format: 'll' }),
            })}
          errorText={I18N('calendar_event_invitation_error_banner_description')}
        />
        <main className='AdminContent'>
          {
            calendarEvent && (
              <CalendarEventForm
                onSave={onSave}
                updateWorkingCopy={updateWorkingCopy}
                workingCopy={workingCopy}
                currentState='active'
                isStepTemplate={false}
                areChangesPresent={hasChanges}
                forUser={step?.forUser}
                isDisabled={invitationSent}
                journeyBlueprint={journeyBlueprint}
                isSaving={isSaving}
              />
            )
          }
        </main>
      </div>
    </LoadingContainer>
  )
}

export default EditCalendarEventPage
