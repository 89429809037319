import React from 'react'
import Card from 'components/common/card'
import classNames from 'classnames'
import { QuestionTypeEnum } from 'types/surveys/question'
import { useHistory } from 'react-router-dom'
import MultipleChoiceQuestionStats from 'components/admin/surveys/questions/multipleChoiceQuestionStats'
import ScaleQuestionStats from 'components/admin/surveys/questions/scaleQuestionStats'
import ScaleAverageStatusLabel from 'components/admin/surveys/questions/scaleAverageStatusLabel'
import TextQuestionStats from 'components/admin/surveys/questions/textQuestionStats'
import AdditionalFeedbackSection from 'components/admin/surveys/questions/additionalFeedbackSection'
import QuestionTitleWithIndexBubble from 'components/admin/surveys/questions/questionTitleWithIndexBubble'
import { isOptionsQuestion, isTextQuestion } from 'utils/surveys/isValidQuestion'

const QuestionStatsCard = ({
  index,
  className = '',
  question,
}) => {
  const history = useHistory()
  const questionType = question?.type
  const isText = isTextQuestion(question)
  const isChoiceQuestion = isOptionsQuestion(question)
  const isScaleQuestion = questionType === QuestionTypeEnum.Scale

  const goToDetailPage = (selectedValue = null) => {
    history.push(`${question?.adminDisplayPath}${selectedValue ? `?selectedValue=${selectedValue}` : ''}`)
  }

  return (
    <Card
      className={classNames('QuestionStatsCard pointer', className)}
      title={(
        <div
          className='d-flex justify-content-start align-items-start'
          onClick={e => goToDetailPage()}
        >
          <QuestionTitleWithIndexBubble
            question={question}
            questionNumber={index + 1}
          />
          {isScaleQuestion && <ScaleAverageStatusLabel question={question} />}
        </div>
      )}
      hideBorder={false}
    >
      {isText && (
        <TextQuestionStats question={question} goToDetailPage={goToDetailPage} />
      )}
      {isScaleQuestion && (
        <ScaleQuestionStats question={question} goToDetailPage={goToDetailPage} />
      )}
      {isChoiceQuestion && (
        <MultipleChoiceQuestionStats question={question} goToDetailPage={goToDetailPage} />
      )}
      {(isChoiceQuestion || isScaleQuestion) && (
        <AdditionalFeedbackSection question={question} goToDetailPage={goToDetailPage} />
      )}
    </Card>
  )
}

export default QuestionStatsCard
