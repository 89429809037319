import React, { useState } from 'react'

import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.other_settings.organization_tab')


const OrganizationTab = () => {
  const { id: currentCompanyId, ceo } = useCurrentCompany()
  const [selectedCeo, setSelectedCeo] = useState(ceo)

  const [updateCompany, { isLoading: isSaving }] = useApi(API.admin.company.update)

  const handleSaveChanges = () => {
    updateCompany({ id: currentCompanyId, ceoId: selectedCeo.id })
  }

  return (
    <section className='OrganizationTab'>
      <div className='white-card p-3'>
        <h6>{I18N('select_ceo')}</h6>
        <p className='text-small text-secondary'>{I18N('description')}</p>

        <EmployeeSearch
          selectedEmployee={selectedCeo}
          onChange={setSelectedCeo}
          isClearable={false}
          className='w-50'
        />
      </div>

      <div className='d-flex justify-content-end mt-5'>
        <Button
          onClick={handleSaveChanges}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('save_changes')}
        </Button>
      </div>
    </section>
  )
}

export default OrganizationTab
