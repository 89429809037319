import { SIGN_CARD_MODAL } from 'components/celebrations/greeting_card_actions/signCardButton'
import { ButtonSmallNarrow } from 'components/common/buttons'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.celebration_cta')

export const useShowCelebrationCTA = (celebration) => {
  const currentUser = useCurrentUser()
  const isCurrentUser = currentUser.id === celebration.user.id

  return (!isCurrentUser || celebration.commentsCount > 0)
}

const CelebrationCTA = ({ celebration }) => {
  const currentUser = useCurrentUser()
  const showCelebrationCTA = useShowCelebrationCTA(celebration)
  if (!showCelebrationCTA) return null

  const isCurrentUser = currentUser.id === celebration.user.id
  const disableNewComments = moment().isAfter(moment(celebration.eventDate).add(7, 'days'))
  const canSignCard = !isCurrentUser && !celebration.hasCommented && !disableNewComments

  let buttonText = I18N('view_messages')
  let url = `/people/celebrations/${celebration.id}?openModal=${SIGN_CARD_MODAL}`

  if (celebration.upcoming) {
    if (!celebration.hasCommented) {
      buttonText = I18N('customize_and_sign_card')
      url = `/people/celebrations/${celebration.id}/edit`
    }
  } else if (canSignCard) {
    buttonText = I18N('sign_card')
  }

  return (
    <Link to={url} className='w-100'>
      <ButtonSmallNarrow className='w-100'>{buttonText}</ButtonSmallNarrow>
    </Link>
  )
}

export default CelebrationCTA
