import React, { useEffect, useState } from 'react'

import { Button } from 'components/common/buttons'
import CheckBox from 'components/common/buttons/checkBox'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import TagsSelect from 'components/common/tagsSelect'
import { useDispatch } from 'react-redux'
import tagSlice from 'redux/slices/tags'
import { showToastMessage } from 'redux/slices/toasts'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.admin.other_settings.notifications_tab')

const NotificationsTab = () => {
  const dispatch = useDispatch()

  const currentCompany = useCurrentCompany()

  const { isLoading, data: company } = useFetch(() => API.admin.company.fetch(currentCompany.id), [currentCompany.id])

  const [isSaving, setIsSaving] = useState(false)
  const [workingCopy, setWorkingCopy] = useState<any>({})

  useEffect(() => {
    dispatch(tagSlice.asyncActions.fetchTags())
  }, [])

  useEffect(() => {
    if (!company) return

    setWorkingCopy({
      defaultSubscribedTags: company.defaultSubscribedTags,
    })
  }, [company])

  const handleSaveChanges = async () => {
    setIsSaving(true)
    try {
      await API.admin.tagSubscriptions.updateCompanyAndUsersTagsSubscriptions({
        tagIds: workingCopy.defaultSubscribedTags.map(tag => tag.id),
        updateAllUsersTags: workingCopy.updateAllUsersTags,
      })

      dispatch(showToastMessage({ message: I18NCommon('update_successfully'), type: 'success' }))
      window.location.reload()
    } catch (error) {
      dispatch(showToastMessage({ message: I18NCommon('update_failed'), type: 'error' }))
      setIsSaving(false)
    }
  }

  const connect = (fieldName: string) => ({
    onChange: (value) => {
      setWorkingCopy(oldValues => ({
        ...oldValues,
        [fieldName]: value,
      }))
    },
    value: workingCopy[fieldName],
    checked: workingCopy[fieldName],
  })

  if (isLoading) {
    return <section className='NotificationsTab'><CirclesLoadingIndicator /></section>
  }

  return (
    <section className='NotificationsTab'>
      <div className='white-card p-3'>
        <div className='align-items-center mb-2'>
          <h6>{I18N('title')}</h6>
          <p>{I18N('sub_title')}</p>
        </div>

        <TagsSelect {...connect('defaultSubscribedTags')} />
        <p className='text-muted text-small mt-1'>{I18N('tags_hint')}</p>
      </div>

      <div className='d-flex justify-content-end mt-5'>
        <Button
          onClick={handleSaveChanges}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('save_changes')}
        </Button>
      </div>
    </section>
  )
}

export default NotificationsTab
