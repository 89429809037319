import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import useQueryParams from 'components/common/hooks/useQueryParams'
import classNames from 'classnames'

const I18N = i18nPath('views.search.search_results.filters')

const EventFilter = ({ updateHistory }) => {
  const { filters = '{}' } = useQueryParams()
  const { type = 'all' } = JSON.parse(filters)
  const filterOptions = ['all', 'event', 'question', 'answer']

  const handleSearch = (selectedFilter) => {
    const filters = selectedFilter === 'all' ? null : { type: selectedFilter }
    updateHistory(filters)
  }

  return (
    <div className='EventFilter'>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500 px-3'>
          {I18N('qna_content_type')}
        </div>

        {filterOptions.map(filterOption => (
          <div
            className={classNames('EventFilterLink py-2 px-3', {
              isActive: filterOption === type,
            })}
            onClick={() => handleSearch(filterOption)}
          >
            {I18N(`qna_${filterOption}_filter`)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default EventFilter
