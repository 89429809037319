import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import profileSlice from 'redux/slices/profiles'
import VideoIntroductionModal from 'components/profiles/videoIntroductionModal'
import CdnSvg from 'components/common/cdnSvg'

const camcorderPlusCircleIconPath = '/images/camcorderPlusCircleIcon.svg'
const playCircleIconPath = '/images/playCircleIcon.svg'

const VideoIntroductions = ({
  canEdit,
  fieldSettings,
  user,
}) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isRecordingPresent = !!user.videoIntroductionRecordingUrl
  const showVideoButton = fieldSettings.videoIntroductions && (canEdit || (!canEdit && isRecordingPresent))
  const buttonIconPath = (canEdit && !isRecordingPresent) ? camcorderPlusCircleIconPath : playCircleIconPath

  const updateUser = changes => dispatch(profileSlice.asyncActions.update(user, changes))

  if (!showVideoButton) {
    return null
  }

  return (
    <section className='ProfileVideoIntroductions'>
      <VideoIntroductionModal
        canEdit={canEdit}
        isOpen={isModalOpen}
        recordingUrl={user.videoIntroductionRecordingUrl}
        toggle={() => setIsModalOpen(false)}
        update={updateUser}
      />
      <button onClick={() => setIsModalOpen(true)}>
        <CdnSvg src={buttonIconPath} />
      </button>
    </section>
  )
}

export default VideoIntroductions
