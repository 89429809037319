import React, { useEffect, useState } from 'react'
import arrayMove from 'array-move'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import PageType from 'types/page'
import PageWorkspaceType from 'types/pageWorkspace'
import { i18nPath } from 'utils/i18nHelpers'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CreateWorkspaceButtonAndModal from 'components/pages/workspaces/createWorkspaceButtonAndModal'
import { usePagesNav } from 'components/pages/pagesNav/context'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import Workspace from './workspace'

const I18N = i18nPath('views.pages.workspaces')

interface WorkspacesProps {
  currentPage: PageType
  allWorkspaces: PageWorkspaceType[]
  isWorkspaceFocused: boolean
}

const Workspaces = ({
  currentPage,
  isWorkspaceFocused = false,
  allWorkspaces,
}: WorkspacesProps) => {
  const dispatch = useDispatch()
  const [expandedWorkspaces, setExpandedWorkspaces] = useState<string[]>([])
  const currentUser = useCurrentUser()
  const { expandResource } = usePagesNav()
  const { allPages } = useWorkspaceUrls()
  const isPageWorkspaceManager = currentUser.permissions.pageWorkspaceManager

  const expandWorkspace = (workspaceId: string) => {
    if (!expandedWorkspaces.includes(workspaceId)) {
      expandResource('workspace', workspaceId)
      setExpandedWorkspaces([...expandedWorkspaces, workspaceId])
    }
  }

  const collapseWorkspace = (workspaceId: string) => {
    if (expandedWorkspaces.includes(workspaceId)) {
      setExpandedWorkspaces(expandedWorkspaces.filter(id => id !== workspaceId))
    }
  }

  const onToggleWorkspace = (workspaceId: string) => {
    if (expandedWorkspaces.includes(workspaceId)) {
      collapseWorkspace(workspaceId)
    } else {
      expandWorkspace(workspaceId)
    }
  }

  useEffect(() => {
    const currentPageWorkspace = allWorkspaces.find(w => (w.id!) === currentPage?.pageWorkspaceId)

    if (currentPageWorkspace) {
      expandWorkspace(currentPageWorkspace.id!)
    }
  }, [currentPage?.id, currentPage?.pageWorkspaceId, allWorkspaces.length])

  const onDragEnd = (result: any) => {
    const draggableId = result?.draggableId

    const oldIndex = result?.source?.index
    const destinationIndex = result?.destination?.index

    const isDraggingWorkspace = draggableId?.startsWith('workspace-')

    if (isDraggingWorkspace && !_.isNull(destinationIndex) && !_.isNull(oldIndex) && destinationIndex !== oldIndex) {
      const workspaceId = draggableId.split('-')[1]
      dispatch(pageWorkspaceSlice.asyncActions.update({ id: workspaceId, orderPosition: destinationIndex }))

      const newWorkspaceIdsOrder = arrayMove(allWorkspaces.map(w => w.id), oldIndex, destinationIndex)
      dispatch(pageWorkspaceSlice.actions.setWorkspaceIds(newWorkspaceIdsOrder))
    }
  }

  return (
    <div className={classNames('Workspaces', { isWorkspaceFocused })}>
      <header className='WorkspacesNavHeader'>
        <h6 className='text-smallest text-uppercase text-secondary font-weight-500 mb-0'>
          {I18N('workspaces')}
        </h6>
        {isPageWorkspaceManager && <CreateWorkspaceButtonAndModal />}
      </header>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='workspaceList'>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {allWorkspaces.map((workspace, index) => {
                const workspacePages = _.groupBy(allPages, 'pageWorkspaceId')[workspace.id!] || []

                return (
                  <Workspace
                    index={index}
                    currentPage={currentPage}
                    workspacePages={workspacePages}
                    workspace={workspace}
                    showPages={expandedWorkspaces.includes(workspace.id!)}
                    toggleShowPages={() => onToggleWorkspace(workspace.id!)}
                    key={`page-workspace-${workspace.id}`}
                  />
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default Workspaces
