import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import ImageSelector from 'components/common/imageSelector'
import InlineImageCrop from 'components/common/image_crop/inlineImageCrop'
import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const IMAGE_CROP_ASPECT = 850 / 330

const I18N = i18nPath('views.cover_image')

interface Props {
  workingCopy: any
  setWorkingCopy: (workingCopy: any) => void
}

const EditCoverImageButtons = ({
  workingCopy,
  setWorkingCopy,
}: Props) => {
  const [isRepositioning, setIsRepositioning] = useState(false)

  const onImageAdded = (
    coverImage, {
      croppedAreaPixels,
      croppedImageUrl: coverImageUrl,
      imageData: originalCoverImageUrl,
    }) => {
    setWorkingCopy({
      ...workingCopy,
      coverImageCroppedArea: croppedAreaPixels,
      coverImageUrl,
      originalCoverImageUrl,
      coverImage,
    })
  }

  const onClickDeleteImage = async () => {
    setWorkingCopy({
      ...workingCopy,
      coverImage: null,
      coverImageCroppedArea: {},
      coverImageUrl: null,
      originalCoverImageUrl: null,
    })
  }

  const onRepositionSave = async ({ croppedAreaPixels, croppedImageUrl }) => {
    setIsRepositioning(false)
    setWorkingCopy({
      ...workingCopy,
      coverImageCroppedArea: croppedAreaPixels,
      coverImageUrl: croppedImageUrl,
    })
  }

  if (isRepositioning) {
    return (
      <InlineImageCrop
        aspect={IMAGE_CROP_ASPECT}
        imageSrc={workingCopy.originalCoverImageUrl}
        initialCroppedArea={workingCopy.coverImageCroppedArea}
        onCancel={() => setIsRepositioning(false)}
        onSave={onRepositionSave}
      />
    )
  }

  return (
    <div className='EditCoverImageButtons'>
      <ImageSelector
        accept={['image/*']}
        dropzoneClassName='dropzone-wrapper'
        cropImage
        aspect={IMAGE_CROP_ASPECT}
        cropShape='rect'
        onImageAdded={onImageAdded}
        clearAfterCropSave
        imageText={(
          <Button variant='secondary'>
            <span className='mr-2 d-flex align-items-center'>
              <CdnSvg className='ImageIcon d-inline-flex' src='/images/imageIcon.svg' />
            </span>
            <span className='text-normal'>{I18N(workingCopy.coverImageUrl ? 'update_cover' : 'add_cover')}</span>
          </Button>
        )}
      />

      {workingCopy.coverImageUrl && (
        <>
          <Button
            onClick={() => setIsRepositioning(true)}
            variant='secondary'
          >
            {I18N('reposition_cover')}
          </Button>

          <Button
            onClick={onClickDeleteImage}
            variant='danger'
          >
            {I18N('remove_cover')}
          </Button>
        </>
      )}
    </div>
  )
}

export default EditCoverImageButtons
