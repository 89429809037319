import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import Error404 from 'components/errors/404'

import userSlice from 'redux/slices/users'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

import JourneyBlueprintHeader from 'components/admin/journeys/journeyBlueprints/journeyBlueprintHeader'
import { SimpleJourneyType } from 'types/journeys/journey'
import JourneyBlueprintEditModeContent from 'components/admin/journeys/journeyBlueprints/journeyBlueprintEditModeContent'
import JourneyBlueprintTableModeContent from 'components/admin/journeys/journeyBlueprints/journeyBlueprintTableModeContent'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { i18nPath } from 'utils/i18nHelpers'
import InactiveCorrespondentBanner from 'components/admin/journeys/common/inactiveCorrespondentBanner'
import UpdateInactiveCorrespondentsModal from 'components/admin/journeys/modals/updateInactiveCorrespondentsModal'


const REMOVE_JOURNEY_MODAL_OPEN = 'remove_journey_modal'
const UPDATE_INACTIVE_CORRESPONDENTS_MODAL = 'update_inactive_correspondents_modal'

const EDIT_MODE = 'edit'
const TABLE_MODE = 'table'
const POSSIBLE_MODES = [EDIT_MODE, TABLE_MODE]

const I18N = i18nPath('views.admin.journeys.journey_blueprints')

const JourneyBlueprintEditorPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { permissions } = useCurrentUser()

  const { journeyBlueprintId, viewMode } = useParams()
  const journeyBlueprint = useSelector(journeyBlueprintSlice.selectors.getJourneyBlueprint(journeyBlueprintId))
  const journeyBlueprintState = journeyBlueprint?.state

  const [openModal, setOpenModal] = useState<string>('')
  const [journeyToRemove, setJourneyToRemove] = useState<SimpleJourneyType | undefined>(undefined)
  const simpleJourneys = useSelector(journeyBlueprintSlice.selectors.getSimpleJourneys(journeyBlueprintId))
  const userIdsWithJourneys = simpleJourneys.map((item: SimpleJourneyType) => item.userId)
  const currentUsers = useSelector(userSlice.selectors.getSimpleUsersByIds(userIdsWithJourneys))
  const { isLoading, isNotFound } = useSelector(journeyBlueprintSlice.selectors.getMetaData())

  const showTableView = viewMode === TABLE_MODE
  const hideSettings = showTableView || !permissions.journeyCreator

  const stepTemplatesWithInactiveCorrespondents = journeyBlueprint?.stepTemplates?.filter(
    st => st.fromCorrespondent?.user?.active === false || st.toCorrespondent?.user?.active === false
  )

  const hasInactiveCorrespondents = !_.isEmpty(stepTemplatesWithInactiveCorrespondents)

  const onRemoveJourney = (journey: SimpleJourneyType) => {
    setJourneyToRemove(journey)
    setOpenModal(REMOVE_JOURNEY_MODAL_OPEN)
  }

  const onCloseModal = () => {
    setOpenModal('')
  }

  useEffect(() => {
    dispatch(journeyBlueprintSlice.asyncActions.admin.fetch(journeyBlueprintId))
  }, [journeyBlueprintId])

  useEffect(() => {
    if (journeyBlueprintState && !POSSIBLE_MODES.includes(viewMode)) {
      const newPath = journeyBlueprintState === 'active' ? 'table?selectedTab=active' : 'edit'
      history.replace(`/admin/journeys/${journeyBlueprintId}/${newPath}`)
    }
  }, [journeyBlueprintState])

  if (isLoading) {
    return <CirclesLoadingIndicator className='mt-4' />
  }

  if (isNotFound) {
    return <Error404 />
  }

  return (
    <div className='JourneyBlueprintEditorPage'>
      <JourneyBlueprintHeader
        openModal={openModal}
        setOpenModal={setOpenModal}
        journeyToRemove={journeyToRemove}
        onCloseModal={onCloseModal}
        onRemoveJourney={onRemoveJourney}
        currentUsers={currentUsers}
        hideSettings={hideSettings}
        showEditJourneyButton={showTableView}
        hasBackToTableButton={viewMode === 'edit' && journeyBlueprintState === 'active'}
      />
      <main className='AdminContent'>
        {hasInactiveCorrespondents && (
          <InactiveCorrespondentBanner
            text={(
              <div className='d-flex'>
                <span className='text-white'>{I18N('inactive_users')}</span>
                <div
                  onClick={() => setOpenModal(UPDATE_INACTIVE_CORRESPONDENTS_MODAL)}
                  className='text-white pointer font-weight-700 ml-1'
                >
                  {I18N('click_to_reassign')}
                </div>
              </div>
            )}
          />
        )}

        {showTableView ? (
          <JourneyBlueprintTableModeContent
            journeyBlueprint={journeyBlueprint}
          />
        ) : (
          <JourneyBlueprintEditModeContent
            journeyBlueprint={journeyBlueprint}
            currentUsers={currentUsers}
            setOpenModal={setOpenModal}
            onRemoveJourney={onRemoveJourney}
          />
        )}
      </main>

      {openModal === UPDATE_INACTIVE_CORRESPONDENTS_MODAL && (
        <UpdateInactiveCorrespondentsModal
          visible
          closeModal={onCloseModal}
          journeyBlueprintType={journeyBlueprint?.type}
          stepTemplatesWithInactiveCorrespondents={stepTemplatesWithInactiveCorrespondents}
        />
      )}
    </div>
  )
}

export default JourneyBlueprintEditorPage
