import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import EmployeesOnJourney from 'components/admin/journeys/audience/employeesOnJourney'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.settings_modal')

const AudienceSectionSettings = ({
  journeyBlueprint = {},
  onRemoveJourney = () => null,
}) => (
  <>
    <EmployeesOnJourney
      alreadyAssignedText={I18N(`${journeyBlueprint?.type}.employee_currently_completing_the_journey`)}
      journeyBlueprint={journeyBlueprint}
      onRemoveJourney={onRemoveJourney}
    />
  </>
)

export default AudienceSectionSettings
