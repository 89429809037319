import React from 'react'

import { Fade } from 'react-reveal'
import Error404 from 'components/errors/404'
import LoadingSpinner from 'components/common/loadingSpinner'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import classNames from 'classnames'

const loadingContainer = ({
  children, isLoading, isNotFound = false, fadeIn = true, useCirclesLoadingIndicator = false,
}) => {
  if (isNotFound) {
    return <Error404 />
  }

  if (isLoading) {
    if (useCirclesLoadingIndicator) {
      return <CirclesLoadingIndicator />
    } else {
      return <LoadingSpinner />
    }
  }

  let ChildComponent = typeof children === 'function' ? children() : children

  // We need to merge the existing className with the LoadingContainer className
  ChildComponent = React.cloneElement(ChildComponent, {
    className: classNames(ChildComponent.props?.className, 'LoadingContainer--Children'),
  })

  ChildComponent = fadeIn ? <Fade>{ChildComponent}</Fade> : ChildComponent

  return ChildComponent
}

export default loadingContainer
