import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { SurveyQuestionType } from 'types/surveys/question'
import FormCheck from 'components/form_fields/formCheck'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.surveys')

const chevronDownIconPath = '/images/chevronDownOutline.svg'
const chevronUpIconPath = '/images/chevronUpOutline.svg'

interface QuestionCardAdvancedSettingsProps {
  question: SurveyQuestionType,
  updateQuestion: (changes: Partial<SurveyQuestionType>) => void,
}

const QuestionCardAdvancedSettings = ({
  question,
  updateQuestion = (changes: Partial<SurveyQuestionType>) => {},
} : QuestionCardAdvancedSettingsProps) => {
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false)

  return (
    <div className='advanced-settings-container mt-3'>
      <span
        className='link d-flex align-items-center mt-3'
        onClick={() => setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen)}
      >
        {I18N('questions.advanced_settings')}
        <CdnSvg className='ml-1' src={isAdvancedSettingsOpen ? chevronUpIconPath : chevronDownIconPath} />
      </span>

      {isAdvancedSettingsOpen && (
        <>
          <FormCheck
            className='mt-3'
            name='require_this_question'
            label={I18N('questions.require_this_question')}
            checked={question?.required}
            onChange={({ target }) => updateQuestion({ required: target.checked })}
          />
        </>
      )}
    </div>
  )
}

export default QuestionCardAdvancedSettings
