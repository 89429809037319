import { combineReducers } from 'redux'

import acknowledgementSlice from 'redux/slices/acknowledgements'
import adminAlertSlice from 'redux/slices/adminAlerts'
import adminArticleSlice from 'redux/slices/admin/articles'
import adminGroupSlice from 'redux/slices/admin/groups'
import adminGroupTypeSlice from 'redux/slices/admin/groupTypes'
import appPrefereceSlice from 'redux/slices/app_launcher/appPreferences'
import appSlice from 'redux/slices/app_launcher/apps'
import articleStatsSlice from 'redux/slices/articleStats'
import articleTypeSlice from 'redux/slices/articleTypes'
import audienceSlice from 'redux/slices/audiences'
import authTokenSlice from 'redux/slices/authTokens'
import awardedBadgeSlice from 'redux/slices/awardedBadges'
import badgeSlice from 'redux/slices/badges'
import bannerSlice from 'redux/slices/banners'
import calendarEventSlice from 'redux/slices/journeys/calendarEvents'
import calendarEventTemplateSlice from 'redux/slices/journeys/calendarEventTemplates'
import calendarsSlice from 'redux/slices/calendars'
import carouselCardBackgroundImageSlice from 'redux/slices/carouselCardBackgroundImage'
import carouselCardSlice from 'redux/slices/carouselCards'
import commentSlice from 'redux/slices/comments'
import communicationSlice from 'redux/slices/journeys/communications'
import communicationTemplateSlice from 'redux/slices/journeys/communicationTemplates'
import companiesSlice from 'redux/slices/companies'
import companyCalendarEventsSlice from 'redux/slices/companyCalendarEvents'
import companyConfigurationSlice from 'redux/slices/companyConfigurations'
import companyValueSlice from 'redux/slices/companyValues'
import currentCompanySlice from 'redux/slices/currentCompany'
import currentUserSlice from 'redux/slices/currentUser'
import customStylesSlice from 'redux/slices/customStyles'
import defaultPermissionSlice from 'redux/slices/defaultPermissions'
import displayAuthorSlice from 'redux/slices/displayAuthors'
import engagementAnalyticsSlice from 'redux/slices/analytics/engagement'
import entitySlice from 'redux/slices/entities'
import eventCoverImageSlice from 'redux/slices/eventCoverImage'
import externalEmailAuthenticationSlice from 'redux/slices/externalEmailAuthentications'
import feedbackSlice from 'redux/slices/feedback'
import celebrationSlice from 'redux/slices/celebrations'
import groupCoverImageSlice from 'redux/slices/groupCoverImage'
import groupFollowersSlice from 'redux/slices/groupFollowers'
import groupMembershipSlice from 'redux/slices/groupMemberships'
import groupSlice from 'redux/slices/groups'
import groupTypeSlice from 'redux/slices/groupTypes'
import homeSlice from 'redux/slices/home'
import introductionSlice from 'redux/slices/journeys/introductions'
import introductionTemplateSlice from 'redux/slices/journeys/introductionTemplates'
import invitationSlice from 'redux/slices/invitations'
import itAdminSlice from 'redux/slices/itAdmin'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'
import journeySlice from 'redux/slices/journeys'
import linkSlice from 'redux/slices/links'
import managedGroupSlice from 'redux/slices/managedGroups'
import meetingSlice from 'redux/slices/journeys/meetings'
import meetingTemplateSlice from 'redux/slices/journeys/meetingTemplates'
import moduleAnalyticsSlice from 'redux/slices/analytics/modules'
import newHireSlice from 'redux/slices/journeys/newHires'
import newsSlice from 'redux/slices/news'
import notificationSlice from 'redux/slices/notifications'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import orgChartSlice from 'redux/slices/orgChart'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import navbarSlice from 'redux/slices/navbarSlice'
import newsAnalyticsSlice from 'redux/slices/analytics/news'
import cultureAnalyticsSlice from 'redux/slices/analytics/culture'
import pageCoverImageSlice, { pageTemplateCoverImageSlice } from 'redux/slices/pageCoverImage'
import pageSlice from 'redux/slices/pages'
import pageSnapshotSlice from 'redux/slices/pages/snapshots'
import pageTemplateCategorySlice from 'redux/slices/pageTemplateCategories'
import pageTemplateSlice from 'redux/slices/pageTemplates'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import permissionSlice from 'redux/slices/permissions'
import profileSlice from 'redux/slices/profiles'
import qnaAnswerSlice from 'redux/slices/qnaAnswers'
import qnaEventSlice from 'redux/slices/qnaEvents'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import reactionSlice from 'redux/slices/reactions'
import requestedBadgeSlice from 'redux/slices/requestedBadges'
import richTextInlineCommentSlice from 'redux/slices/richTextInlineComments'
import searchAnalyticsSlice from 'redux/slices/analytics/search'
import searchSlice from 'redux/slices/search'
import settingSlice from 'redux/slices/settings'
import shoutoutsSlice from 'redux/slices/shoutouts'
import stepSlice from 'redux/slices/journeys/steps'
import stepTemplateSlice from 'redux/slices/journeys/stepTemplates'
import surveyAnswerSlice from 'redux/slices/surveys/answers'
import surveyFormSlice from 'redux/slices/surveys/forms'
import surveyQuestionSlice from 'redux/slices/surveys/questions'
import surveyResponseSlice from 'redux/slices/surveys/responses'
import tagSlice from 'redux/slices/tags'
import taskableStepSlice from 'redux/slices/journeys/taskableSteps'
import taskSlice from 'redux/slices/journeys/tasks'
import taskTemplateSlice from 'redux/slices/journeys/taskTemplates'
import timezoneSlice from 'redux/slices/timezones'
import toastSlice from 'redux/slices/toasts'
import userAccessManagementSlice from 'redux/slices/userAccessManagement'
import userFollowSlice from 'redux/slices/userFollows'
import userImportSlice from 'redux/slices/userImports'
import userSkillSlice from 'redux/slices/userSkills'
import userSkillTypeSlice from 'redux/slices/userSkillTypes'
import userSlice from 'redux/slices/users'
import videoUploadSlice from 'redux/slices/videoUploads'
import workspaceSlice from 'redux/slices/chats/workspaces'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'
import navigationBlockerSlice from 'redux/slices/navigationBlocker'
import globalStorageSlice from 'redux/slices/globalStorage'
import slackWidgetSlice from 'redux/slices/slackWidgetSlice'
import stylesSlice from 'redux/slices/styles'

// Add the reducer for each module and feature here
const RootReducer = combineReducers({
  // page variables
  currentUser: currentUserSlice.reducer,
  currentCompany: currentCompanySlice.reducer,
  lastLoggedUser: (state = {}) => state,
  environment: (state = {}) => state,
  styles: stylesSlice.reducer,
  logo: (state = {}) => state,
  imageUrls: (state = {}) => state,
  availableLocales: (state = {}) => state,
  cdnDomain: (state = {}) => state,

  // Toast Messages
  toasts: toastSlice.reducer,

  // Module reducers
  acknowledgements: acknowledgementSlice.reducer,
  adminAlerts: adminAlertSlice.reducer,
  apps: appSlice.reducer,
  appPreferences: appPrefereceSlice.reducer,
  articleTypes: articleTypeSlice.reducer,
  audiences: audienceSlice.reducer,
  awardedBadges: awardedBadgeSlice.reducer,
  badges: badgeSlice.reducer,
  banners: bannerSlice.reducer,
  calendars: calendarsSlice.reducer,
  calendarEvents: calendarEventSlice.reducer,
  comments: commentSlice.reducer,
  customStyles: customStylesSlice.reducer,
  companies: companiesSlice.reducer,
  calendarEventTemplates: calendarEventTemplateSlice.reducer,
  collaborativeEditor: collaborativeEditorSlice.reducer,
  communicationTemplates: communicationTemplateSlice.reducer,
  communications: communicationSlice.reducer,
  companyCalendarEvents: companyCalendarEventsSlice.reducer,
  companyConfigurations: companyConfigurationSlice.reducer,
  companyValues: companyValueSlice.reducer,
  userAccessManagement: userAccessManagementSlice.reducer,
  defaultPermission: defaultPermissionSlice.reducer,
  displayAuthors: displayAuthorSlice.reducer,
  entities: entitySlice.reducer,
  externalEmailAuthentications: externalEmailAuthenticationSlice.reducer,
  feedback: feedbackSlice.reducer,
  celebrations: celebrationSlice.reducer,
  globalStorage: globalStorageSlice.reducer,
  groups: groupSlice.reducer,
  groupCoverImage: groupCoverImageSlice.reducer,
  groupFollowers: groupFollowersSlice.reducer,
  groupMemberships: groupMembershipSlice.reducer,
  groupTypes: groupTypeSlice.reducer,
  home: homeSlice.reducer,
  itAdmin: itAdminSlice.reducer,
  introductions: introductionSlice.reducer,
  introductionTemplates: introductionTemplateSlice.reducer,
  invitations: invitationSlice.reducer,
  journeyBlueprints: journeyBlueprintSlice.reducer,
  journeys: journeySlice.reducer,
  links: linkSlice.reducer,
  meetings: meetingSlice.reducer,
  meetingTemplates: meetingTemplateSlice.reducer,
  moduleAnalytics: moduleAnalyticsSlice.reducer,
  navbar: navbarSlice.reducer,
  news: newsSlice.reducer,
  notifications: notificationSlice.reducer,
  newHires: newHireSlice.reducer,
  onboardingBuddies: onboardingBuddySlice.reducer,
  orgChart: orgChartSlice.reducer,
  pageAnalytics: pageAnalyticsSlice.reducer,
  newsAnalytics: newsAnalyticsSlice.reducer,
  cultureAnalytics: cultureAnalyticsSlice.reducer,
  engagementAnalytics: engagementAnalyticsSlice.reducer,
  pages: pageSlice.reducer,
  pageCoverImage: pageCoverImageSlice.reducer,
  pageSnapshots: pageSnapshotSlice.reducer,
  pageTemplateCoverImage: pageTemplateCoverImageSlice.reducer,
  pageTemplateCategories: pageTemplateCategorySlice.reducer,
  pageTemplates: pageTemplateSlice.reducer,
  pageWorkspaces: pageWorkspaceSlice.reducer,
  profiles: profileSlice.reducer,
  qnaAnswers: qnaAnswerSlice.reducer,
  qnaEvents: qnaEventSlice.reducer,
  qnaQuestions: qnaQuestionSlice.reducer,
  eventCoverImage: eventCoverImageSlice.reducer,
  reactions: reactionSlice.reducer,
  requestedBadges: requestedBadgeSlice.reducer,
  richTextInlineComments: richTextInlineCommentSlice.reducer,
  settings: settingSlice.reducer,
  tags: tagSlice.reducer,
  taskableSteps: taskableStepSlice.reducer,
  taskTemplates: taskTemplateSlice.reducer,
  tasks: taskSlice.reducer,
  shoutouts: shoutoutsSlice.reducer,
  userFollows: userFollowSlice.reducer,
  userImports: userImportSlice.reducer,
  users: userSlice.reducer,
  userSkills: userSkillSlice.reducer,
  carouselCards: carouselCardSlice.reducer,
  carouselCardBackgroundImage: carouselCardBackgroundImageSlice.reducer,
  searchAnalytics: searchAnalyticsSlice.reducer,
  search: searchSlice.reducer,
  steps: stepSlice.reducer,
  stepTemplates: stepTemplateSlice.reducer,
  surveyForms: surveyFormSlice.reducer,
  surveyResponses: surveyResponseSlice.reducer,
  surveyAnswers: surveyAnswerSlice.reducer,
  surveyQuestions: surveyQuestionSlice.reducer,
  timezones: timezoneSlice.reducer,
  userSkill: userSkillSlice.reducer,
  userSkillTypes: userSkillTypeSlice.reducer,
  managedGroups: managedGroupSlice.reducer,
  authTokens: authTokenSlice.reducer,
  articleStats: articleStatsSlice.reducer,
  videoUploads: videoUploadSlice.reducer,
  workspaces: workspaceSlice.reducer,
  navigationBlocker: navigationBlockerSlice.reducer,
  slackWidget: slackWidgetSlice.reducer,

  // ADMIN
  adminArticles: adminArticleSlice.reducer,
  adminGroups: adminGroupSlice.reducer,
  adminGroupTypes: adminGroupTypeSlice.reducer,
  adminPermissions: permissionSlice.reducer,
})

export default RootReducer
