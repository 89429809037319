import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ToggleGroup from 'components/common/toggleGroup'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { EstimatedGroupArticleAudienceAvatars } from 'components/common/audience/estimatedAudienceAvatars'
import groupSlice from 'redux/slices/groups'
import GroupSelectField from 'components/form_fields/groupSelectField'
import TrashIcon from 'components/icons/trashIcon'
import { Button } from 'components/common/buttons'
import SpecificUsersSelect from 'components/common/audience/specificUsersSelect'
import GroupType from 'types/group'
import { SimpleUserType } from 'types/user'

const personsIconPath = '/images/personsIcon.svg'

const I18NAudience = i18nPath('views.admin.includes.audience_selector')

const GroupArticleAudienceToggleSelector = ({
  className = '',
  workingCopy,
  updateWorkingCopy,
  translationsPath,
}) => {
  const dispatch = useDispatch()
  const I18N = i18nPath(translationsPath)

  const { targetingRules } = workingCopy

  const audienceRules = targetingRules?.rules || []
  const selectedUsers = targetingRules?.users || []
  const selectedGroupIds = audienceRules.filter(rule => rule.key === 'any' && rule.type === 'group_type' && rule.operator === 'is').map(rule => rule.values).flat()
  const groups = useSelector(groupSlice.selectors.getTeams())
  const selectedGroups = groups.filter(group => selectedGroupIds.includes(group.id))

  const [showGroupSelector, setShowGroupSelector] = useState(!_.isEmpty(selectedGroupIds))
  const [showSpecificUsersSelect, setShowSpecificUsersSelect] = useState(!_.isEmpty(selectedUsers))

  useEffect(() => {
    if (_.isEmpty(groups)) {
      dispatch(groupSlice.asyncActions.fetchAll())
    }
  }, [])

  const handleGroupsChange = (newGroups: GroupType[]) => {
    const newGroupIds = newGroups.map(group => group.id)

    if (_.isEmpty(newGroupIds)) {
      updateWorkingCopy({
        targetingRules: {
          ...targetingRules,
          rules: [],
        },
      })
    } else {
      updateWorkingCopy({
        targetingRules: {
          ...targetingRules,
          rules: [{
            key: 'any',
            type: 'group_type',
            operator: 'is',
            values: newGroupIds,
          }],
        },
      })
    }
  }

  const handleRemoveGroupSelector = () => {
    setShowGroupSelector(false)
    handleGroupsChange([])
  }

  const handleUsersChange = (users: SimpleUserType[]) => {
    updateWorkingCopy({
      targetingRules: {
        ...targetingRules,
        users,
      },
    })
  }

  const handleRemoveSpecificUsers = () => {
    setShowSpecificUsersSelect(false)
    handleUsersChange([])
  }

  return (
    <div className={classNames('AudienceToggleSelector', className)}>
      <ToggleGroup
        className='mb-4'
        onChange={() => {}}
        selectedValue={false}
        toggles={[
          {
            value: false,
            icon: <CdnSvg src={personsIconPath} />,
            title: I18N('specific_audience_title'),
            description: I18N('specific_audience_description'),
          },
        ]}
      />
      {showGroupSelector ? (
        <div className='d-flex w-100 align-items-center'>
          <GroupSelectField onChange={handleGroupsChange} value={selectedGroups} />
          <div className='DeleteRuleButton pointer py-2 px-1' onClick={handleRemoveGroupSelector}>
            <TrashIcon className='TrashIcon mr-1' />
          </div>
        </div>
      ) : (
        <Button variant='tag' className='mt-2 mr-2' onClick={() => setShowGroupSelector(true)}>
          + {I18NAudience('add_specific_groups')}
        </Button>
      )}
      {showSpecificUsersSelect ? (
        <SpecificUsersSelect
          selectedUsers={selectedUsers}
          onUsersChange={handleUsersChange}
          onRemoveSpecificUsers={handleRemoveSpecificUsers}
        />
      ) : (
        <Button onClick={() => setShowSpecificUsersSelect(true)} variant='tag' className='mt-2'>
          + {I18NAudience('add_specific_people')}
        </Button>
      )}
      <div className='EstimatedAudienceAvatars p-3 d-flex justify-content-start align-items-center mt-3'>
        <EstimatedGroupArticleAudienceAvatars
          translationsPath={translationsPath}
          targetingRules={targetingRules}
          groupId={workingCopy.groupId}
          specificallyTargetedGroups={selectedGroups}
          specificallyTargetedUsers={selectedUsers}
        />
      </div>
    </div>
  )
}

export default GroupArticleAudienceToggleSelector
