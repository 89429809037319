import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { i18nPath, i18nMomentWithTimezone } from 'utils/i18nHelpers'
import introductionSlice, { INTRODUCTION_ATTRIBUTES } from 'redux/slices/journeys/introductions'
import { INTRODUCTION_TEMPLATE_INTERPOLATED_FIELDS } from 'redux/slices/journeys/introductionTemplates'
import IntroductionForm from 'components/admin/journeys/introductionTemplates/introductionForm'
import StepAdminBanner from 'components/admin/journeys/stepAdminBanner'

import { LoadingContainer } from 'components/common/loadingContainer'

import useStepManagement from 'components/admin/journeys/hooks/useStepManagement'
import EditEntityHeader from 'components/admin/journeys/common/editEntityHeader'
import currentTimezone from 'utils/currentTimezone'

const I18N = i18nPath('views.admin.journeys.introductions.edit_introduction_page')

const EditIntroductionPage = () => {
  const { id } = useParams()

  const introduction = useSelector(introductionSlice.selectors.getIntroduction(id))
  const introductionTemplate = introduction?.introductionTemplate
  const {
    isLoading, isNotFound, isSaving,
  } = useSelector(introductionSlice.selectors.getMetaData())

  const step = introduction?.step
  const missingVariables = step?.missingVariables
  const completedAt = introduction?.completedAt
  const journeyBlueprint = step?.journey?.journeyBlueprint

  const timezone = step?.triggerTimezone || currentTimezone()

  const {
    workingCopy,
    updateWorkingCopy,
    hasChanges,
    onSave,
  } = useStepManagement({
    id,
    entity: introduction,
    entityTemplate: introductionTemplate,
    entityAttributes: INTRODUCTION_ATTRIBUTES,
    fetchEntity: introductionSlice.asyncActions.admin.fetchIntroduction,
    updateEntity: introductionSlice.asyncActions.admin.updateIntroduction,
    interpolatedFields: INTRODUCTION_TEMPLATE_INTERPOLATED_FIELDS,
  })

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='EditIntroductionPage'>
        <EditEntityHeader
          type='introduction'
          entity={introduction}
          entityTemplate={introductionTemplate}
          entityI18n={I18N}
        />
        <StepAdminBanner
          step={step}
          triggeredText={I18N('introduction_completed_banner_description',
            {
              completedHour: `${i18nMomentWithTimezone({ date: completedAt, timezone, format: 'hh:mm A' })} (${timezone})`,
              completedDate: i18nMomentWithTimezone({ date: completedAt, timezone, format: 'll' }),
              completedBy: introduction?.completedBy?.preferredFullName,
            })}
          missingVariablesText={I18N('introduction_not_assigned_banner_description', { missingVariables })}
          errorText={I18N('introduction_error_banner_description')}
        />
        <main className='AdminContent'>
          {
            introduction && (
              <IntroductionForm
                onSave={onSave}
                updateWorkingCopy={updateWorkingCopy}
                workingCopy={workingCopy}
                currentState='active'
                isStepTemplate={false}
                areChangesPresent={hasChanges}
                forUser={step?.forUser}
                isDisabled={introduction?.completed}
                showHelpCard={false}
                journeyBlueprint={journeyBlueprint}
                isSaving={isSaving}
              />
            )
          }
        </main>
      </div>
    </LoadingContainer>
  )
}

export default EditIntroductionPage
