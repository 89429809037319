import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import RootReducer from 'redux/reducer'
import pageVariables from 'utils/pageVariables'

const middlewares = [thunk]

const { logger } = require('redux-logger')

const { environment } = pageVariables

// disable redux logger using `DISABLE_REDUX_LOGGER=true ./bin/shakapacker-dev-server`
if (!process.env.DISABLE_REDUX_LOGGER) {
  // enable the redux logger for dev, staging, and in production with a query param if provided
  if (environment === 'development'
    || environment === 'staging'
    || environment === 'reviewapp'
    || window.location.search.indexOf('showReduxLogger') > -1) {
    middlewares.push(logger)
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = preloadedState => createStore(
  RootReducer, preloadedState, composeEnhancers(applyMiddleware(...middlewares))
)

export default configureStore
