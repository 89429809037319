import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import itAdminSlice from 'redux/slices/itAdmin'
import FormEditableField from 'components/form_fields/formEditableField'
import FormCheck from 'components/form_fields/formCheck'

const I18N = i18nPath('views.integrations_page.integration_card.bamboohr')

interface BambooHRInstallationComponentInterface {
  isOtherInstalled: boolean,
}

const BambooHRInstallationComponent = ({ isOtherInstalled }: BambooHRInstallationComponentInterface) => {
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)
  const [subdomain, setSubdomain] = useState('')
  const [customReportId, setCustomReportId] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [externalGroupSyncEnabled, setExternalGroupSyncEnabled] = useState(false)
  const { isSaving } = useSelector(itAdminSlice.selectors.getMetaData())

  const installBambooHR = () => {
    dispatch(itAdminSlice.asyncActions.admin.installBambooHR({
      subdomain,
      customReportId,
      apiKey,
      externalGroupSyncEnabled,
    }))
  }

  const isDisabled = isSaving || _.isEmpty(subdomain) || _.isEmpty(customReportId) || _.isEmpty(apiKey)

  if (isOtherInstalled) {
    return (
      <div className='text-secondary text-small'>
        {I18n.t('views.integrations_page.integration_card.only_one_hris_integration')}
      </div>
    )
  }

  if (!showForm) {
    return (
      <div className='mb-2'>
        <ButtonSmallNarrow
          onClick={() => setShowForm(true)}
        >
          {I18N('configure')}
        </ButtonSmallNarrow>
      </div>
    )
  }

  return (
    <>
      <div className='mb-2'>
        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          currentValue={subdomain}
          fieldName='subdomain'
          label={I18N('subdomain')}
          footNote={I18N('subdomain_footer')}
          onChange={({ target }) => setSubdomain(target.value)}
          isRequired
        />

        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          currentValue={customReportId}
          fieldName='customReportId'
          label={I18N('custom_report_id')}
          footNote={I18N('custom_report_id_footer')}
          onChange={({ target }) => setCustomReportId(target.value)}
          isRequired
        />

        <FormEditableField
          className='d-block w-100 mb-2'
          currentValue={apiKey}
          fieldName='apiKey'
          label={I18N('api_key')}
          onChange={({ target }) => setApiKey(target.value)}
          isRequired
        />

        <FormCheck
          name='externalGroupSyncEnabled'
          label={I18N('group_sync')}
          checked={externalGroupSyncEnabled}
          onChange={({ target }) => setExternalGroupSyncEnabled(target.checked)}
          id='externalGroupSyncEnabled'
          className='mb-3'
        />

        <ButtonSmallNarrow
          onClick={installBambooHR}
          disabled={isDisabled}
          showLoadingSpinner={isSaving}
          id='save-bamboohr-btn'
        >
          {I18n.t('views.integrations_page.integration_card.save')}
        </ButtonSmallNarrow>
      </div>

    </>
  )
}

export default BambooHRInstallationComponent
