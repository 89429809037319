import Tooltip from 'components/common/clearyTooltip'
import RoundedSlackAvatars from 'components/common/roundedSlackAvatars'
import useCelebrationInvitedData from 'components/celebrations/hooks/useCelebrationInvitedData'
import invitationHelperText from 'components/celebrations/utils/invitationHelperText'
import InvitedPeopleModal from 'pages/people/celebration_editor_page/invitedPeopleModal'
import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.invited_people')


const InvitedPeopleNavbar = ({ workingCopy }) => {
  const [showInvitedPeopleModal, setShowInvitedPeopleModal] = useState(false)

  const {
    invitedPeople,
    followersById,
  } = useCelebrationInvitedData(workingCopy)

  const helperText = invitationHelperText(workingCopy)

  return (
    <>
      <Tooltip
        content={(
          <div className='pointer' onClick={() => setShowInvitedPeopleModal(true)}>
            <RoundedSlackAvatars
              users={invitedPeople}
              shownAvatars={3}
              avatarSize='25px'
            />
          </div>
        )}
        placement='bottom'
      >
        <span className='text-small'>
          {I18N('tooltip.header', { count: invitedPeople.length })} <br />
          {invitedPeople.length > 0 && (
            <span className='text-secondary'>{helperText}</span>
          )}
        </span>
      </Tooltip>

      {showInvitedPeopleModal && invitedPeople.length > 0 && (
        <InvitedPeopleModal
          users={invitedPeople}
          followersById={followersById}
          onClose={() => setShowInvitedPeopleModal(false)}
          helperText={helperText}
        />
      )}
    </>
  )
}

export default InvitedPeopleNavbar
