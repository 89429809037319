import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice from 'redux/slices/surveys/forms'

import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import SurveyFormType from 'types/surveys/form'
import DeleteModal from 'components/common/modals/deleteModal'
import DuplicateModal from 'components/common/modals/duplicateModal'
import { useHistory } from 'react-router-dom'
import ToggleSwitch from 'components/common/toggleSwitch'
import TrashIcon from 'components/icons/trashIcon'
import LinkIcon from 'components/icons/linkIcon'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.surveys')

const DELETE_MODAL = 'delete'
const DUPLICATE_MODAL = 'duplicate'

const FormSettingsDropdown = ({
  form,
  hasChanges,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [openModal, setOpenModal] = useState('')

  const { isSaving } = useSelector(surveyFormSlice.selectors.getMetaData())

  const anonymousResponses = form?.anonymousResponses
  const status = form?.status
  const isDraft = status === 'draft'
  const isLive = status === 'live'
  const questions = form?.questions || []
  const hasQuestions = !_.isEmpty(questions)
  const hasQuestionsAndInstructions = hasQuestions && !_.isEmpty(form?.subject) && !_.isEmpty(form?.instructions)

  const updateForm = (changes: Partial<SurveyFormType>) => {
    dispatch(surveyFormSlice.asyncActions.admin.update(changes))
  }

  const deleteForm = () => {
    const onSuccess = () => {
      setOpenModal('')
      history.push('/admin/surveys')
    }

    dispatch(surveyFormSlice.asyncActions.admin.delete(form.id, onSuccess))
  }

  const duplicateForm = (name: string) => {
    const onSuccess = (newFormId) => {
      setOpenModal('')
      history.push(`/admin/surveys/${newFormId}`)
    }

    dispatch(surveyFormSlice.asyncActions.admin.copy(form.id, name, onSuccess))
  }

  return (
    <>
      <DropdownMenuContainer className='form-settings-dropdown-container'>
        <CardDropdownMenuItem
          onClick={() => {}}
          primaryText={(
            <ToggleSwitch
              className='mx-2 w-100 justify-content-between'
              checked={anonymousResponses}
              text={I18N('anonymous_results')}
              onChange={() => updateForm({ id: form.id, anonymousResponses: !anonymousResponses })}
            />
          )}
          className='AnonymousResults rounded-top-lg w-100 py-2'
          width='240px'
          icon={<CdnSvg src='/images/personIcon.svg' className='PersonIcon' />}
        />
        {hasQuestionsAndInstructions && (
          <CardDropdownMenuItem
            primaryText={<span className='mx-2'>{I18N('preview_survey')}</span>}
            icon={<CdnSvg src='/images/eyeOpenIcon.svg' className='EyeIcon' />}
            className='rounded-top-lg py-2'
            width='240px'
            onClick={() => history.push(`/admin/surveys/${form.id}/preview`)}
          />
        )}
        {!isDraft && (
          <CardDropdownMenuItem
            onClick={() => updateForm({ id: form.id, state: 'draft' })}
            primaryText={<span className='mx-2'>{I18NCommon('revert_to_draft')}</span>}
            className='rounded-top-lg py-2'
            width='240px'
            icon={<CdnSvg src='/images/eyeStrikethroughIcon.svg' className='EyeStrikethroughIcon' />}
          />
        )}
        {isLive && (
          <CardDropdownMenuItem
            // copy the form url
            onClick={() => navigator.clipboard.writeText(form.displayUrl)}
            primaryText={<span className='mx-2'>{I18N('copy_form_url')}</span>}
            className='rounded-top-lg py-2'
            width='240px'
            icon={<LinkIcon className='LinkIcon' />}
          />
        )}
        <CardDropdownMenuItem
          disabled={hasChanges}
          onClick={() => setOpenModal(DUPLICATE_MODAL)}
          primaryText={<span className='mx-2'>{I18N('duplicate_survey_modal.duplicate_survey')}</span>}
          className='rounded-top-lg py-2'
          width='240px'
          icon={<CdnSvg src='/images/duplicateIcon.svg' className='DuplicateIcon' />}
        />
        <CardDropdownMenuItem
          onClick={() => setOpenModal(DELETE_MODAL)}
          primaryText={<span className='mx-2 text-danger'>{I18N('delete_suvey_modal.delete_survey')}</span>}
          className='delete-dropdown-item rounded-top-lg py-2'
          width='240px'
          icon={<TrashIcon className='TrashIcon' />}
        />
      </DropdownMenuContainer>
      {openModal === DELETE_MODAL && (
        <DeleteModal
          deleteText={I18N('delete_suvey_modal.title')}
          deleteSecondaryText={I18N('delete_suvey_modal.body')}
          deleteConfirmText={I18N('delete_suvey_modal.delete_confirm')}
          deleteConfirm={deleteForm}
          showDeleteModal={openModal === DELETE_MODAL}
          closeDeleteModal={() => setOpenModal('')}
          isDeleting={isSaving}
        />
      )}
      {openModal === DUPLICATE_MODAL && (
        <DuplicateModal
          title={I18N('duplicate_survey_modal.duplicate_survey')}
          label={I18N('duplicate_survey_modal.label')}
          initialName={I18N('duplicate_survey_modal.copy_of', { formName: form.name })}
          visible={openModal === DUPLICATE_MODAL}
          isCopying={isSaving}
          onDuplicate={duplicateForm}
          toggle={() => setOpenModal('')}
        />
      )}
    </>
  )
}


export default FormSettingsDropdown
