import React, { useState, useEffect } from 'react'

import classNames from 'classnames'

const ProgressiveImage = ({
  alt = '',
  className = '',
  imgClassName = '',
  src,
  fullHeight = true,
  placeholderClassName = 'Placeholder h-100 w-100 position-absolute',
}) => {
  const [isLoading, setLoading] = useState(true)
  const [errored, setErrored] = useState(false)

  useEffect(() => {
    let imageToLoad

    if (src) {
      // start fetching original image
      imageToLoad = new Image()
      imageToLoad.src = src
      imageToLoad.onload = () => {
        // Update image source with fetched asset
        setLoading(false)
      }
      imageToLoad.onerror = () => {
        setLoading(false)
        setErrored(true)
      }
    }

    return () => {
      if (imageToLoad) {
        imageToLoad.onload = () => null
      }
    }
  }, [src])

  if (!src) return null

  return (
    <div className={classNames('ProgressiveImage position-relative', { isLoading, 'h-100': fullHeight, errored }, className)}>
      <div className={placeholderClassName} />
      <img className={classNames('w-100 h-100 position-relative', imgClassName)} src={src} alt={alt} />
    </div>
  )
}

export default ProgressiveImage
