export default function getNamesAndEmailFromFailedRecord(rowData) {
  if (!rowData) return {}

  const userData = rowData.record || rowData.userAttributes

  if (!userData || typeof userData !== 'object') return {}

  const {
    preferredName, firstName, lastName, preferredLastName, email, employeeId,
  } = userData
  const emailToShow = employeeId || email

  return {
    firstName: preferredName || firstName,
    lastName: preferredLastName || lastName,
    email: emailToShow,
  }
}
