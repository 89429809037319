import React from 'react'
import classNames from 'classnames'

export const Toggle = ({
  onClick,
  isHighlighted,
  icon,
  title,
  description,
}) => (
  <div
    className={classNames('toggle p-3 mb-3 mb-md-0 d-flex flex-row', { isHighlighted })}
    onClick={onClick}
  >
    <div className={classNames('Icon mr-3 flex-grow-0 flex-shrink-0', { isHighlighted })}>
      {icon}
    </div>
    <div className='d-flex flex-column text-left'>
      <span className='title'>{title}</span>
      {description && <span className='text-secondary text-small'>{description}</span>}
    </div>
  </div>
)

const ToggleGroup = ({
  onChange,
  selectedValue,
  label = undefined,
  className = '',
  toggles,
}) => (
  <section className={classNames('ToggleGroup', className)}>
    {label && <label className='d-block mb-3'>{label}</label>}
    <div className='toggle-group d-flex flex-md-row flex-column'>
      {toggles.map((toggle => (
        <Toggle
          key={`'toggle-${toggle.key || toggle.value}`}
          onClick={() => onChange(toggle.value)}
          isHighlighted={_.isEqual(selectedValue, toggle.value)}
          icon={toggle.icon}
          title={toggle.title}
          description={toggle.description}
        />
      )))}
    </div>
  </section>
)

export default ToggleGroup
