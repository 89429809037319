import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import IceBreakerIcon from 'components/icons/icePickIcon'

const I18N = i18nPath('views.qna.events')

const height = '1.5rem'

/**
 * The fixed heights are necessary for aligning text and icon properly
 */
const IcebreakerLabel = ({ className }) => (
  <div
    className={classNames('d-inline-flex align-items-center text-white border-radius py-1 pl-1 pr-2', className)}
    style={{
      height: '1.571rem',
      backgroundColor: 'var(--icebreaker-label-color)',
      borderRadius: 'var(--square-border-radius-small)',
    }}
  >
    <IceBreakerIcon size={height} fill='white' className='mr-2' />
    <span style={{ height }}>
      {I18N('icebreaker_label')}
    </span>
  </div>
)

export default IcebreakerLabel
