import React, { useEffect, useRef } from 'react'

type Props = {
  setIsDesktopNavOpen: (isDesktopNavOpen: boolean) => void
  isDesktopNavOpen: boolean
  setShowToggleLeftNavButton: (showToggleLeftNavButton: boolean) => void
  children: React.ReactNode
}

const FocusedPageWrapper = ({
  setIsDesktopNavOpen, isDesktopNavOpen, setShowToggleLeftNavButton, children,
}: Props) => {
  const originalIsDesktopNavOpen = useRef(isDesktopNavOpen)

  useEffect(() => {
    setShowToggleLeftNavButton(false)
    setIsDesktopNavOpen(false)

    return () => {
      setShowToggleLeftNavButton(true)
      setIsDesktopNavOpen(originalIsDesktopNavOpen.current)
    }
  }, [])

  return children
}

export default FocusedPageWrapper
