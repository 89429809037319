import React from 'react'

import BackButton from 'components/common/backButton'
import ShoutoutCardHomeFeed from 'components/shoutouts/shoutoutCardHomeFeed'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import SendShoutoutButton from 'components/shoutouts/sendShoutoutButton'

const ShoutoutDetail = ({
  isLoading, shoutout,
}) => {
  const currentUser = useCurrentUser()

  return (
    <>
      <div className='container ShoutoutsDetail'>
        <div className='col-12 offset-md-2 col-md-8 mt-5'>
          <div className='mb-5 d-flex justify-content-between align-items-center'>
            <div>
              <BackButton />
            </div>
            <SendShoutoutButton />
          </div>

          {isLoading && <LoadingIndicator />}
          {!isLoading && !_.isEmpty(shoutout) && (
            <ShoutoutCardHomeFeed
              shoutout={shoutout}
              user={currentUser}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ShoutoutDetail
