import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'

import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { ProfileUserType } from 'types/user'
import ChatUsername from 'components/common/chatUsername'
import CloseIcon from 'components/icons/closeIcon'
import CdnSvg from 'components/common/cdnSvg'
import RichTextView from 'components/common/richTextView'

const I18N = i18nPath('views.profile')
const envelopeOutlineIconPath = '/images/envelopeOutlineIcon.svg'

interface ProfileCompanyType {
  settings: {
    profile: {
      fields: {
        city: boolean,
        slack: boolean,
        msTeam: boolean,
        customField1: boolean,
        customField2: boolean,
        customField3: boolean,
      },
    },
  },
}

interface ProfilePreviewType {
  isProfilePreviewOpen: boolean,
  user?: ProfileUserType,
  currentCompany: ProfileCompanyType,
  setIsProfilePreviewOpen: (isProfilePreviewOpen: boolean) => void,
}


const CustomProfileField = ({
  numberSuffix,
  companyProfileFields,
  currentCompany,
  user,
}) => {
  const profileFields = companyProfileFields
  const company = currentCompany
  const fallbackPlaceholder = I18N('custom_field_placeholder')

  return (
    <div key={numberSuffix}>
      {profileFields[`customField${numberSuffix}`] && (
        <>
          <h6 className='mt-4'>{company[`customField${numberSuffix}Label`]}</h6>
          <div className='mt-1'>
            {user[`customField${numberSuffix}`]
              ? (<RichTextView record={user} recordType='user' richTextKey={`customField${numberSuffix}`} />)
              : (company[`customField${numberSuffix}InputPlaceholder`] || fallbackPlaceholder)}
          </div>
        </>
      )}
    </div>
  )
}

const ProfileTeam = ({
  team,
  index,
  groupsViewableOnOrgChart,
}) => {
  const isNotLast = index !== groupsViewableOnOrgChart.length - 1

  return (
    <div className='d-inline-block' key={`group-${team.id}`}>
      <Link to={`/people/teams/type/${team.groupType.name.toLowerCase()}/team/${team.id}`}>
        {team.name}
      </Link>
      {isNotLast && <span>,&nbsp;</span>}
    </div>
  )
}

const ProfilePreview = ({
  user,
  currentCompany,
  isProfilePreviewOpen,
  setIsProfilePreviewOpen,
}: ProfilePreviewType) => {
  const profilePreviewRef = useRef<HTMLElement | null>(null)


  useEffect(() => {
    if (isProfilePreviewOpen && profilePreviewRef.current) {
      const rect = profilePreviewRef.current.getBoundingClientRect()
      profilePreviewRef.current.style.height = `${window.innerHeight - rect.top}px`
    }
  }, [isProfilePreviewOpen])

  if (_.isEmpty(user)) {
    return (
      <nav className={classNames('ProfilePreview position-relative flex-grow-0 flex-shrink-0 d-flex flex-column', { isProfilePreviewOpen })}>
        <CirclesLoadingIndicator className='mt-2' />
      </nav>
    )
  }

  const { settings: companySettings } = currentCompany
  const companyProfileFields = companySettings.profile.fields
  const showCity = companySettings.profile.fields.city

  const { activeGroups, primaryChatUsers } = user
  const groupsViewableOnOrgChart = activeGroups?.filter(activeGroup => activeGroup.groupType.viewableOnOrgChart)

  const visibleChatUsers = _.sortBy(primaryChatUsers, 'type').filter((u) => {
    if (u.type === 'Chat::SlackUser' && companyProfileFields.slack) {
      return true
    }
    if (u.type === 'Chat::MsTeamUser' && companyProfileFields.msTeam) {
      return true
    }

    return false
  })

  return (
    <nav
      ref={profilePreviewRef}
      className={
        classNames(
          'ProfilePreview position-relative flex-grow-0 flex-shrink-0 d-flex flex-column',
          { isProfilePreviewOpen }
        )
      }
    >
      <div
        className='position-absolute top-0 right-0 d-block d-sm-none'
        onClick={() => setIsProfilePreviewOpen(false)}
      >
        <CloseIcon />
      </div>
      <div className='d-flex flex-column align-items-center mb-5'>
        <EmployeeThumbnailPhoto employee={user} photoUrl='primaryPhotoUrl' />

        <h2 className='text-center'>
          {user.preferredFullName}
        </h2>
        <span className='text-center'>
          <h6 className='d-inline mr-1'>{user.title}</h6>
          {showCity && user?.city && (
            <>
              <span className='text-secondary'>{I18N('in')}</span>
              <h6 className='d-inline ml-1'>{user.city}</h6>
            </>
          )}
        </span>
        <Link to={`/profile/${user.username}`} className='mt-3 btn btn-secondary'>
          {I18N('view_profile')}
        </Link>
      </div>
      <div className='ContactInfo bordered-bottom pb-3'>
        <h6>{I18N('contact_info.header')}</h6>
        {visibleChatUsers.map(u => (
          <ChatUsername
            key={u.id}
            chatUser={u}
            className='d-flex align-items-center mb-2'
          />
        ))}
        <div className='d-flex align-items-center'>
          <CdnSvg className='mr-2' src={envelopeOutlineIconPath} />
          <span className='w-100 break-word'>{user.email}</span>
        </div>
      </div>
      {groupsViewableOnOrgChart.length > 0 && (
        <div className='bordered-bottom pb-3'>
          <h6 className='mt-4'>{I18N('teams')}</h6>
          {groupsViewableOnOrgChart.map((team, i) => (
            <ProfileTeam
              key={i}
              index={i}
              team={team}
              groupsViewableOnOrgChart={groupsViewableOnOrgChart}
            />
          ))}
        </div>
      )}
      <div className='pb-3'>
        {[1, 2, 3].map(number => (
          <CustomProfileField
            key={number}
            numberSuffix={number}
            companyProfileFields={companyProfileFields}
            currentCompany={currentCompany}
            user={user}
          />
        ))}
      </div>
    </nav>
  )
}

export default ProfilePreview
