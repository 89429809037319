import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import classNames from 'classnames'
import RoundedAvatars from 'components/common/roundedAvatars'
import CelebrationIconBanner from 'components/common/celebrationIconBanner'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.success_modal')

const JourneyLaunchSuccessModal = ({
  className = '',
  visible,
  toggle,
  currentUsers = [],
}) => (
  <Modal size='md' className={classNames('JourneyLaunchSuccessModal', className)} visible={visible} toggle={toggle}>
    <CelebrationIconBanner className='pb-4' title={I18N('your_journey_is_live')} />
    {
      currentUsers.length > 0 && (
        <div className='d-flex justify-content-center align-items-center users-in-journey mt-4 mb-4 ml-2 mr-2'>
          <RoundedAvatars avatarSize='30px' users={currentUsers} popOverPlacement='auto' linkTo='journeys' />
          <p className='text-left text-normal ml-3 mb-0'>{I18N('will_automatically_start_this_journey')}</p>
        </div>
      )
    }
  </Modal>
)

export default JourneyLaunchSuccessModal
