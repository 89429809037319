import useIsCelebrationForCurrentUser from 'components/celebrations/hooks/useIsCelebrationForCurrentUser'
import moment from 'moment'

const useCanSignCelebration = (celebration) => {
  const isCelebrationForAnotherUser = !useIsCelebrationForCurrentUser(celebration)
  const isBefore7Days = moment().isBefore(moment(celebration.eventDate).add(7, 'days'))

  return isCelebrationForAnotherUser && isBefore7Days
}

export default useCanSignCelebration
