import React from 'react'

import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.task_templates.buddy_assignment')

const BuddyAssignmentHelpCard = () => (
  <div className='BuddyAssignmentHelpCard px-5 d-flex justify-content-between mb-5'>
    <div className='d-flex flex-column py-3 w-50 justify-content-center'>
      <h3 className='text-white'>{I18N('help_widget.title')}</h3>
      <p className='text-white mb-0'>{I18N('help_widget.description')}</p>
    </div>
    <div className='confetti-background d-flex justify-content-center align-items-center w-50 py-2 position-relative'>
      <CdnSvg src='/images/highFiveImageWithBackground.svg' />
    </div>
  </div>
)

export default BuddyAssignmentHelpCard
