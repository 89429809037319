import { transformCcCorrespondentsForBackend } from 'utils/journeys/journeyVariablesHelpers'

export const checkStepDifferences = (initialState, workingCopy, entityAttributes, sharedAttributes) => {
  const difference = Object.keys(initialState).filter(k => !_.isEqual(workingCopy[k], initialState[k]))

  const entityAttributesChanges = _.intersection(entityAttributes, difference)
  const sharedAttributesChanges = _.intersection(sharedAttributes, difference)
  const hasChanges = entityAttributesChanges.length > 0 || sharedAttributesChanges.length > 0

  return { entityAttributesChanges, sharedAttributesChanges, hasChanges }
}

const buildDifferenceParams = (id,
  sharedType,
  sharedId,
  initialState,
  workingCopy,
  entityAttributes,
  sharedAttributes
) => {
  let params = {
    id,
  }

  const {
    entityAttributesChanges,
    sharedAttributesChanges,
  } = checkStepDifferences(initialState, workingCopy, entityAttributes, sharedAttributes)

  if (entityAttributesChanges.length > 0) {
    const changes = _.pick(workingCopy, entityAttributesChanges)

    params = {
      ...params,
      ...changes,
    }
  }

  if (sharedAttributesChanges.length > 0) {
    const changes = _.pick(workingCopy, sharedAttributesChanges)
    params = {
      ...params,
      [`${sharedType}Attributes`]: {
        id: sharedId,
        ...changes,
      },
    }
  }

  if (sharedAttributesChanges.includes('fromCorrespondentAttributes')) {
    params = {
      ...params,
      [`${sharedType}Attributes`]: {
        ...params[`${sharedType}Attributes`],
        fromCorrespondentAttributes: {
          role: workingCopy.fromCorrespondentAttributes.role,
          userId: workingCopy.fromCorrespondentAttributes.user?.id,
        },
      },
    }
  }

  if (sharedAttributesChanges.includes('toCorrespondentAttributes')) {
    params = {
      ...params,
      [`${sharedType}Attributes`]: {
        ...params[`${sharedType}Attributes`],
        toCorrespondentAttributes: {
          role: workingCopy.toCorrespondentAttributes.role,
          userId: workingCopy.toCorrespondentAttributes.user?.id,
          groupId: workingCopy.toCorrespondentAttributes.group?.id,
        },
      },
    }
  }

  if (sharedAttributesChanges.includes('ccCorrespondentsAttributes')) {
    params = {
      ...params,
      [`${sharedType}Attributes`]: {
        ...params[`${sharedType}Attributes`],
        ccCorrespondentsAttributes: transformCcCorrespondentsForBackend(workingCopy.ccCorrespondentsAttributes),
      },
    }
  }

  return params
}

export const buildStepDifferenceParams = (id, stepId, initialState, workingCopy, entityAttributes, stepAttributes) => buildDifferenceParams(id, 'step', stepId, initialState, workingCopy, entityAttributes, stepAttributes)

export const buildStepTemplateDifferenceParams = (id, stepTemplateId, initialState, workingCopy, entityAttributes, stepTemplateAttributes) => buildDifferenceParams(id, 'stepTemplate', stepTemplateId, initialState, workingCopy, entityAttributes, stepTemplateAttributes)
