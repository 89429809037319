import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import { trackQuestionCreation } from 'components/qna/common'

import Modal from 'components/common/modal'
import LoadingSpinner from 'components/common/loadingSpinner'
import IcebreakerLabel from 'components/qna/icebreakerLabel'
import IcebreakerEditor from 'components/qna/icebreakerEditor'
import IcebreakerTypesList from 'components/qna/icebreakerTypesList'

const I18N = i18nPath('views.qna.events')

const IcebreakerQuestionsModal = ({ showModal, closeModal, event }) => {
  const dispatch = useDispatch()
  const { meta } = useSelector(qnaQuestionSlice.selectors.getQuestionFormData())
  const {
    icebreakerTemplates,
    meta: { isLoadingIcebreakerTemplates },
  } = useSelector(qnaQuestionSlice.selectors.getIcebreakerTemplatesData())
  const [selectedType, setSelectedType] = useState(null)
  const [showTemplateSelector, setShowTemplateSelector] = useState(true)
  const [showTemplateSelectedHeader, setShowTemplateSelectedHeader] = useState(false)
  const [showCreateYourOwnHeader, setShowCreateYourOwnHeader] = useState(false)
  const [questionContent, setQuestionContent] = useState('')

  const isSubmitButtonDisabled = questionContent.length === 0 || meta.isSaving

  const createQuestionHandler = () => {
    const questionParams = {
      eventId: event.id,
      question: {
        content: questionContent,
        anonymous: false,
        type: 'icebreaker',
      },
    }

    dispatch(qnaQuestionSlice.asyncActions.createQuestion(questionParams, event))

    trackQuestionCreation({ question_type: 'icebreaker', event, anonymous: false })
  }

  const resetState = (e) => {
    if (e) {
      e.preventDefault()
    }
    setSelectedType(null)
    setShowTemplateSelector(true)
    setQuestionContent('')
    setShowTemplateSelectedHeader(false)
    setShowCreateYourOwnHeader(false)
  }

  const onCloseModal = () => {
    resetState()
    closeModal()
  }

  const handleTemplateSelection = (text) => {
    setShowTemplateSelector(false)
    setShowTemplateSelectedHeader(true)
    setQuestionContent(text)
  }

  const handleTypeSelection = (type) => {
    setSelectedType(type)
  }

  const handleCreateYourOwnQuestion = (e) => {
    e.preventDefault()
    setShowTemplateSelector(false)
    setQuestionContent('')
    setShowTemplateSelectedHeader(false)
    setShowCreateYourOwnHeader(true)
  }

  const types = _.uniq(icebreakerTemplates.map(icebreaker => icebreaker.attrs.type))

  const questionsOfSelectedType = icebreakerTemplates.filter(icebreaker => icebreaker.attrs.type === selectedType)

  useEffect(() => {
    if (showModal) {
      dispatch(qnaQuestionSlice.asyncActions.fetchIcebreakerTemplates())
    }
  }, [showModal])

  const renderModalTitle = () => {
    // A question template was selected
    if (showTemplateSelectedHeader) return I18N('icebreaker_header_with_template')

    // No question template was chosen
    if (showCreateYourOwnHeader) return I18N('icebreaker_header_without_template')

    return I18N('icebreaker_modal_header')
  }

  return (
    <Modal visible={showModal} toggle={onCloseModal} className='IcebreakerQuestionModal' size='lg'>
      <>
        <IcebreakerLabel className='mb-3' />
        <h4 className='mb-4'>{renderModalTitle()}</h4>
        {showTemplateSelector ? (
          <>
            {isLoadingIcebreakerTemplates ? (
              <LoadingSpinner className='d-flex align-items-center justify-content-center' />
            ) : (
              <>
                <IcebreakerTypesList
                  types={types}
                  handleTypeSelection={handleTypeSelection}
                  selectedType={selectedType}
                  questionsOfSelectedType={questionsOfSelectedType}
                  handleTemplateSelection={handleTemplateSelection}
                />
                <div className='mt-4 mb-3'>
                  {i18nFormat(
                    I18N('not_on_the_list'),
                    <a href='#' className='font-weight-normal' onClick={handleCreateYourOwnQuestion}>
                      {I18N('create_your_own_question')}
                    </a>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <IcebreakerEditor
            setQuestionContent={setQuestionContent}
            questionContent={questionContent}
            resetState={resetState}
            onCloseModal={onCloseModal}
            createQuestionHandler={createQuestionHandler}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
          />
        )}
      </>
    </Modal>
  )
}

export default IcebreakerQuestionsModal
