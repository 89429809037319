import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import Picture from 'components/common/images/picture'
import AddProfilePicture from 'components/common/images/addProfilePicture'
import profileSlice from 'redux/slices/profiles'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.profile.photos')

const eyeOpenIconPath = '/images/eyeOpenIcon.svg'

const ViewEditToggle = ({ isEditing, setIsEditing }) => (
  <a className='ViewEditToggle d-md-none d-flex align-items-center' onClick={() => setIsEditing(!isEditing)}>
    {isEditing ? (
      <div className='d-flex align-items-center'>
        <span className='text-color-primary text-small mr-1'>{I18NCommon('view')}</span>
        <CdnSvg src={eyeOpenIconPath} className='View' />
      </div>
    ) : (
      <div className='d-flex align-items-center'>
        <span className='text-color-primary text-small mr-2'>{I18NCommon('edit')}</span>
        <CdnSvg src='/images/addPencilIcon.svg' className='PictureList-addPencilIcon' />
      </div>
    )}
  </a>
)

const PictureList = ({
  pictures,
  owner,
  onClick,
  slice = profileSlice,
}) => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)

  const { isPhotoUploading } = useSelector(slice.selectors.getMetaData())
  const canManagePhotos = owner.permittedActions?.managePhotos
  const showImageSelector = canManagePhotos && !isPhotoUploading

  const createPhoto = (photo, { croppedAreaPixels }) => {
    dispatch(slice.asyncActions.createPhoto(photo, owner, croppedAreaPixels))
  }

  const showPhotosTitle = showImageSelector || pictures.length > 0

  return (
    <div className='PictureList flex-column align-items-center mt-4'>
      <div className='d-flex justify-content-between align-items-center'>
        {showPhotosTitle && <div className='font-weight-600'>{I18N('photos')}</div>}
        {canManagePhotos && (
          <ViewEditToggle isEditing={isEditing} setIsEditing={setIsEditing} />
        )}
      </div>
      <div className='mt-4'>
        {pictures.map(picture => (
          <Picture
            owner={owner}
            key={picture.id}
            picture={picture}
            onClick={() => onClick(picture, owner.photos)}
            slice={slice}
            isManagingPhotos={isEditing}
          />
        ))}
        {showImageSelector && (
          <AddProfilePicture
            isVisible={showImageSelector}
            isLoading={isPhotoUploading}
            onImageAdded={createPhoto}
          />
        )}
      </div>
    </div>
  )
}

export default PictureList
