import classNames from 'classnames'
import { ButtonSecondarySmall } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import CloseIcon from 'components/icons/closeIcon'
import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'


interface MenuItemProps {
  active?: boolean
  iconPath?: string
  iconComponent?: React.ReactNode
  label: string
  count: number
}

const MenuItem = ({
  active, iconPath, iconComponent, label, count,
}: MenuItemProps) => (
  <ButtonSecondarySmall
    className={
      classNames('Sidebar__MenuItem d-flex mb-1 align-items-center justify-content-between w-100', {
        active,
      })
    }
  >
    <span>
      {iconComponent}
      {iconPath && <CdnSvg src={iconPath} className='mr-1' />}
      {label}
    </span>

    <span className='Sidebar__MenuItem__Count'>
      {count}
    </span>
  </ButtonSecondarySmall>
)


interface TicketListPageSidebarProps {
  toggleSidebarComponent?: React.ReactNode
}

const TicketListPageSidebar = ({ toggleSidebarComponent }: TicketListPageSidebarProps) => (
  <div className='TicketListPageSidebar'>
    <div className='d-flex align-items-center justify-content-between mb-4'>
      <h4 className='mb-0'>{I18NCommon('tickets')}</h4>

      {toggleSidebarComponent}
    </div>

    <ButtonSecondarySmall className='Sidebar__Button w-100 justify-content-start'>
      <CdnSvg src='/images/addPencilIcon.svg' className='mr-1' />
      {I18NCommon('create_new')}
    </ButtonSecondarySmall>

    <div className='mt-3'>
      <MenuItem
        active
        iconPath='/images/personUnderlineIcon.svg'
        label={I18NCommon('inbox')}
        count={6}
      />
      <MenuItem
        iconPath='/images/smileOutlineIcon.svg'
        label={I18NCommon('assigned_to_you')}
        count={0}
      />
      <MenuItem
        iconComponent={<CloseIcon />}
        label={I18NCommon('unassigned')}
        count={1}
      />
    </div>
  </div>
)

export default TicketListPageSidebar
