import React, { useRef } from 'react'
import useAutoResizeWidget from 'components/home/widgets/hooks/useAutoResizeWidget'
import classNames from 'classnames'

const AutoResizableScrollableSection = ({
  widget,
  resizeWidget,
  isLoaded,
  className = '',
  scrollableContentClassName = '',
  children,
  showScrollbarOutside = false,
}) => {
  const scrollableSectionRef = useRef<HTMLDivElement>(null)
  const scrollableContentRef = useRef<HTMLDivElement>(null)

  const { hasScrollbar } = useAutoResizeWidget({
    widget,
    resizeWidget,
    scrollableSectionRef,
    scrollableContentRef,
    isLoaded,
  })

  return (
    <div
      ref={scrollableSectionRef}
      className={
        classNames(
          'HomePageWidget--scrollable-section',
          className,
          { hasOutsideScrollbar: showScrollbarOutside && hasScrollbar }
        )
      }
    >
      <div
        ref={scrollableContentRef}
        className={scrollableContentClassName}
      >
        {children}
      </div>
    </div>
  )
}

export default AutoResizableScrollableSection
