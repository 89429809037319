import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Error404 from 'components/errors/404'
import { Link, useParams, useHistory } from 'react-router-dom'
import snakeCase from 'lodash/snakeCase'
import ReactSelect from 'components/common/react_select'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'
import FormErrorList from 'components/errors/formErrorList'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { showToastMessage } from 'redux/slices/toasts'
import adminGroupTypeSlice from 'redux/slices/admin/groupTypes'
import CheckBox from 'components/form_fields/checkBox'
import { Button } from 'components/common/buttons'
import Card from 'components/common/card'
import GroupTypeLabelForm from 'components/admin/group_types/groupTypeLabelForm'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.group_type_editor')

const PERMISSION_LEVELS = [{ value: 'team_admins' }, { value: 'leads' }, { value: 'members' }, { value: 'anyone' }]

const SETTINGS = [
  'collapsed',
  'viewable',
  'viewableOnOrgChart',
  'targetable',
  'joinable',
  'followable',
  'autoJoinGroupAncestors',
  'useLabelsToCategorizeMembers',
]

const newGroupType = {
  id: null,
  name: '',
  collapsed: true,
  viewable: true,
  viewableOnOrgChart: true,
  targetable: false,
  order_position: null,
  joinable: false,
  followable: false,
  autoJoinGroupAncestors: false,
  fieldsEditableBy: 'team_admins',
  membersEditableBy: 'team_admins',
  followersEditableBy: 'team_admins',
  groupTypeLabelsAttributes: [],
}

const GroupTypeEditorPage = () => {
  const dispatch = useDispatch()
  const { groupDisplayName, pluralGroupDisplayName } = useCurrentCompany()
  const history = useHistory()
  const { groupTypeId } = useParams()
  const [workingCopy, setWorkingCopy] = useState({})
  const {
    isLoading,
    isNotFound,
    isSaving,
    error,
  } = useSelector(adminGroupTypeSlice.selectors.getMetaData())
  const groupType = useSelector(adminGroupTypeSlice.selectors.getGroupType())
  const workingCopyLabels = workingCopy?.groupTypeLabelsAttributes || []
  const groupTypeLabels = groupType?.groupTypeLabels || []
  const hasLabelsChanged = !_.isEqual(workingCopyLabels, groupTypeLabels)
  const changesPresent = !_.isEqual(groupType, workingCopy) || hasLabelsChanged
  const { source, external: isExternal } = workingCopy

  useEffect(() => {
    if (groupTypeId === 'new') {
      setWorkingCopy(newGroupType)
      dispatch(adminGroupTypeSlice.actions.isLoading(false))
    } else {
      setWorkingCopy(groupType || {})
      dispatch(adminGroupTypeSlice.asyncActions.fetchGroupType(groupTypeId))
    }

    return () => dispatch(adminGroupTypeSlice.actions.setGroupTypeId(null))
  }, [])

  useEffect(() => {
    if (groupType && !_.isEqual(groupType, workingCopy)) {
      const newWorkingCopy = {
        ...groupType,
        groupTypeLabelsAttributes: groupTypeLabels,
      }
      delete newWorkingCopy.groupTypeLabels

      setWorkingCopy(newWorkingCopy)
    }
  }, [groupType?.updatedAt])

  const generateSelectChangeHandler = fieldName => (selectedOption) => {
    const changedGroupType = { ...workingCopy, [fieldName]: selectedOption.value }
    setWorkingCopy(changedGroupType)
  }

  const generateBooleanChangeHandler = fieldName => (e) => {
    const changedGroupType = { ...workingCopy, [fieldName]: e.target.checked }
    setWorkingCopy(changedGroupType)
  }

  const generateChangeHandler = fieldName => (e) => {
    const changedGroupType = { ...workingCopy, [fieldName]: e.target.value }
    setWorkingCopy(changedGroupType)
  }

  const onClickAddMoreLabel = () => {
    const newLabelsAttributes = [...workingCopy.groupTypeLabelsAttributes]

    newLabelsAttributes.push({
      name: '',
    })

    setWorkingCopy({
      ...workingCopy,
      groupTypeLabelsAttributes: newLabelsAttributes,
    })
  }

  const saveGroupType = () => {
    const deletedLabels = _.differenceBy(groupTypeLabels, workingCopyLabels, 'id')
    const filteredLabels = workingCopyLabels.filter(label => !_.isEmpty(label?.name))

    workingCopy.groupTypeLabelsAttributes = [
      ...filteredLabels,
      ..._.map(deletedLabels, label => ({ id: label.id, _destroy: true })),
    ]

    const onSuccess = (id) => {
      if (id) {
        history.push(`/admin/team_types/${id}`)
      }
      dispatch(showToastMessage({ message: I18N('group_type_updated'), type: 'success' }))
    }

    if (workingCopy.id) {
      dispatch(adminGroupTypeSlice.asyncActions.updateGroupType(workingCopy, onSuccess))
    } else {
      dispatch(adminGroupTypeSlice.asyncActions.createGroupType(workingCopy, onSuccess))
    }
  }

  if (isLoading) {
    return <CirclesLoadingIndicator className='mt-4' />
  }

  if (isNotFound) {
    return <Error404 />
  }

  return (
    <div className='GroupTypePage'>
      <header className='AdminHeader d-flex justify-content-between flex-column'>
        <h3 className='mb-0'>{I18N('group_type_editor', { groupDisplayName })}</h3>
      </header>

      <main className='AdminContent'>
        {error && <FormErrorList error={error} />}

        <h6 className='text-secondary align-self-start'>{I18N('group_type_details', { groupDisplayName })}</h6>
        <Card className='mb-4'>
          <div className='row form-group '>
            <div className='col-md-4'>
              <label className='required-form-label'>{I18N('name')}</label>
              <input
                value={workingCopy.name || ''}
                disabled={isExternal}
                onChange={generateChangeHandler('name')}
              />
            </div>
            {isExternal && (
              <div className='col-md-12'>
                <div className='text-small text-secondary mt-1'>
                  {I18N('external_name_helper_text', { externalSource: source })}
                </div>
              </div>
            )}
          </div>

          <div className='row form-group'>
            <div className='col-md-4'>
              <label>{I18N('position')}</label>
              <input
                type='number'
                value={workingCopy.orderPosition || ''}
                onChange={generateChangeHandler('orderPosition')}
              />
            </div>
            <div className='col-md-12'>
              <div className='text-small text-secondary mt-1'>{I18N('position_helper_text', { groupDisplayName, pluralGroupDisplayName })}</div>
            </div>
          </div>
        </Card>

        <h6 className='text-secondary align-self-start'>{I18N('settings')}</h6>
        <Card className='mb-4'>
          {
            SETTINGS.map(fieldName => (
              <CheckBox
                fieldName={fieldName}
                attributeValue={workingCopy[fieldName]}
                onChangeHandler={generateBooleanChangeHandler(fieldName)}
                label={I18N(snakeCase(fieldName), {
                  groupDisplayName: groupDisplayName.toLowerCase(),
                  pluralGroupDisplayName: pluralGroupDisplayName.toLowerCase(),
                })}
                helperText={i18nFormat(I18N(`${snakeCase(fieldName)}_helper`, { groupDisplayName: groupDisplayName.toLowerCase(), pluralGroupDisplayName: pluralGroupDisplayName.toLowerCase() }),
                  <Link to='/people/teams'>{I18N('groups_page', { groupDisplayName: pluralGroupDisplayName.toLowerCase() })}</Link>,
                  <Link to='/people/orgchart'>{I18N('org_chart')}</Link>)}
              />
            ))
          }

          {workingCopy?.useLabelsToCategorizeMembers && (
            <>
              {workingCopy?.groupTypeLabelsAttributes?.map((labelAttribute, index) => (
                <GroupTypeLabelForm
                  groupType={workingCopy}
                  groupTypeLabelsAttribute={labelAttribute}
                  index={index}
                  setGroupType={setWorkingCopy}
                />
              ))}
              <span className='AddMoreLink font-weight-500 d-flex mt-2' onClick={onClickAddMoreLabel}>
                <CdnSvg className='PlusIcon mr-1' src='/images/plusIcon.svg' />
                <span className='mt-1'>{I18N('add_more')}</span>
              </span>
            </>
          )}
        </Card>

        <h6 className='text-secondary align-self-start'>{I18N('permissions')}</h6>
        <Card className='mb-4'>
          <div className='row form-group'>
            <div className='col-md-3'>
              <label className='required-form-label'>{I18N('content_editable_by')}</label>
              <ReactSelect
                value={PERMISSION_LEVELS.find(level => level.value === workingCopy.fieldsEditableBy)}
                onChange={generateSelectChangeHandler('fieldsEditableBy')}
                options={PERMISSION_LEVELS}
                isSearchable={false}
                getOptionLabel={option => option.value}
              />
            </div>
            <div className='col-md-12 text-small text-secondary mt-1'>{I18N('content_editable_by_helper', { groupDisplayName })}</div>
          </div>

          <div className='row form-group'>
            <div className='col-md-3'>
              <label className='required-form-label'>{I18N('members_editable_by')}</label>
              <ReactSelect
                value={PERMISSION_LEVELS.find(level => level.value === workingCopy.membersEditableBy)}
                onChange={generateSelectChangeHandler('membersEditableBy')}
                options={PERMISSION_LEVELS}
                isSearchable={false}
                getOptionLabel={option => option.value}
                disabled={isExternal}
              />
            </div>
            <div className='col-md-12 text-small text-secondary mt-1'>{I18N('members_editable_by_helper', { groupDisplayName })}</div>
          </div>

          <div className='row form-group'>
            <div className='col-md-3'>
              <label className='required-form-label'>{I18N('followers_editable_by')}</label>
              <ReactSelect
                value={PERMISSION_LEVELS.find(level => level.value === workingCopy.followersEditableBy)}
                onChange={generateSelectChangeHandler('followersEditableBy')}
                options={PERMISSION_LEVELS}
                isSearchable={false}
                getOptionLabel={option => option.value}
                disabled={isExternal}
              />
            </div>
            <div className='col-md-12 text-small text-secondary mt-1'>{I18N('followers_editable_by_helper', { groupDisplayName })}</div>
          </div>

          {workingCopy.syncExplanationHtml && (
            <div className='row form-group'>
              <div className='col-md-6'>
                <label>{I18N('synced_team_join_explanation_html')}</label>
                <div className='bordered p-2' dangerouslySetInnerHTML={{ __html: workingCopy.syncExplanationHtml }} />
              </div>
              <div className='col-md-12 text-small text-secondary mt-1'>
                {I18N('synced_team_text_helper', { externalSource: source })}
              </div>
            </div>
          )}
        </Card>

        <div className='row mt-3'>
          <div className='form-groupType col'>
            <Button disabled={!changesPresent} showLoadingSpinner={isSaving} onClick={saveGroupType}>
              {I18N('save_group_type', { groupDisplayName })}
            </Button>
          </div>
        </div>
      </main>
    </div>
  )
}

export default GroupTypeEditorPage
