import React from 'react'

const SubNavbar = ({ style, children }) => (
  <div className='Navbar-subnav d-none d-lg-flex' style={style}>
    <div className='container-fluid'>
      <div className='row align-items-center'>
        <div className='col-12'>
          <nav className=''>{children}</nav>
        </div>
      </div>
    </div>
  </div>
)

export default SubNavbar
