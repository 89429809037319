import React from 'react'

import Moderators from './moderators'
import QuestionsAnswerableBy from './questionsAnswerableBy'
import VerifiedOrAcceptedAnswers from './verifiedOrAcceptedAnswers'
import AdvancedSettings from './advancedSettings'
import EventDetails from './eventDetails'

const QNASettings = ({ event, setEvent }) => (
  <div className='QNASettings'>
    <Moderators event={event} setEvent={setEvent} />

    <QuestionsAnswerableBy event={event} setEvent={setEvent} />

    <VerifiedOrAcceptedAnswers event={event} setEvent={setEvent} />

    <AdvancedSettings event={event} setEvent={setEvent} />

    <EventDetails event={event} setEvent={setEvent} />
  </div>
)

export default QNASettings
