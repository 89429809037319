import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import messageMobileApp from 'utils/messageMobileApp'
import useUpdateMobileLastLoggedData from 'components/common/hooks/useUpdateMobileLastLoggedData'

const NAVIGATION_THRESHOLD_MS = 100

const useMobileAppGlobals = () => {
  useUpdateMobileLastLoggedData()

  const history = useHistory()
  const currentUser = useCurrentUser()

  const lastNavigationDateRef = useRef<Date | null>(null)

  const navigate = (newLocation, locationChangeType = null) => {
    const currentTime = new Date().getTime()
    const lastNavigationTime = lastNavigationDateRef.current?.getTime() || 0

    if (currentTime - lastNavigationTime < NAVIGATION_THRESHOLD_MS) {
      // Preventing double navigation in a short period of time
      // For example, when we click in a <Link>, we will trigger a history.push + a click event
      // both will try to navigate to the same location, so we should prevent the second one
      // The first is the history.push, the second is the click event
      return false
    }

    lastNavigationDateRef.current = new Date()
    messageMobileApp({
      action: 'navigate',
      pathname: newLocation.pathname,
      search: newLocation.search,
      locationChangeType,
    })

    return false
  }

  useEffect(() => {
    if (currentUser?.inMobileApp) {
      // setting these global functions so we can use them in the mobile app
      global.historyPush = (route) => {
        history.push(route, {
          fromMobileApp: true,
        })
      }

      document.addEventListener('click', (event: MouseEvent) => {
        let target = event.target as any

        while (target) {
          if (target.tagName === 'A') {
            if (target.href?.includes(window.location.origin)) {
              event.preventDefault()
              return navigate(target)
            } else {
              return null
            }
          }
          target = target.parentElement
        }
        return null
      })
    }
  }, [])

  useEffect(() => {
    let unblock

    if (currentUser?.inMobileApp) {
      unblock = history.block((newLocation, locationChangeType) => {
        if (newLocation.state?.fromMobileApp) {
          // This means the mobile app is telling us to navigate to a new location
          // so we should not block the transition
          return true
        }

        // if the change was triggered by this webview, like a click on a link, we should block it and dispatch
        // a message to the mobile app to handle the navigation
        // Eventually, the mobile app will call the historyPush function to navigate to a new location
        // and this time it will not be blocked since the history.push will have the fromMobileApp flag
        navigate(newLocation, locationChangeType)
        return false
      })
    }
    return unblock
  }, [])
}

export default useMobileAppGlobals
