import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import { ButtonNarrow } from 'components/common/buttons'
import Modal from 'components/common/modal'
import CancelButton from 'components/common/cancelButton'

const I18N = i18nPath('views.pages.actions_dropdown')

interface UnpublishPageModalProps {
  className?: string
  isLoading: boolean
  showUnpublishPageModal: boolean
  closeUnpublishPageModal: () => void
  revertToDraft: () => void
}

const UnpublishPageModal = ({
  className,
  closeUnpublishPageModal,
  showUnpublishPageModal,
  isLoading,
  revertToDraft,
}: UnpublishPageModalProps) => (
  <Modal size='md' className={classNames('UnpublishPageModal', className)} visible={showUnpublishPageModal} toggle={closeUnpublishPageModal}>
    <div className='text-center mb-4'>
      <h4 className='UnpublishPageText text-center mx-auto font-weight-600'>{I18N('unpublish_page')}</h4>
      <div className='block mx-auto text-center mb-4'>
        <p className='text-secondary mb-0 font-weight-400'>Unpublish this page and revert to draft state.</p>
        <p className='text-secondary mt-0 font-weight-400'>The page audience will no longer have access to the page.</p>
      </div>
      <div className='d-flex justify-content-center align-items-center mt-4 mb-2 mx-auto'>
        <CancelButton onClick={closeUnpublishPageModal} className='mr-4 font-weight-600'>
          {I18N('cancel')}
        </CancelButton>
        <ButtonNarrow onClick={revertToDraft} showLoadingSpinner={isLoading} disabled={isLoading}>
          {I18N('unpublish')}
        </ButtonNarrow>
      </div>
    </div>
  </Modal>
)

export default UnpublishPageModal
