import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import newHireSlice, { NEW_HIRES_PER_PAGE } from 'redux/slices/journeys/newHires'
import { i18nPath } from 'utils/i18nHelpers'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.buddies_table')
const I18NReassignModal = i18nPath('views.admin.journeys.onboarding_buddies.buddies_table.reassign_confirmation_modal')
const I18NUnassignModal = i18nPath('views.admin.journeys.onboarding_buddies.buddies_table.unassign_confirmation_modal')
const I18NAssignModal = i18nPath('views.admin.journeys.onboarding_buddies.buddies_table.assign_confirmation_modal')

const ASSIGN_BUDDY_MODAL = 'assign_buddy_confirmation_modal'
const REASSIGN_BUDDY_MODAL = 'reassign_buddy_confirmation_modal'

const AssignedBuddiesActions = ({
  onboardingBuddy,
  newHire,
}) => {
  const { settings } = useCurrentCompany()
  const allowMultipleNewHires = settings?.onboardingBuddySettings?.allowMultipleNewHires
  const dispatch = useDispatch()
  const [openConfirmationModal, setOpenConfirmationModal] = useState<string | null>(null)
  const [newHireToUnassign, setNewHireToUnassign] = useState<any>(null)
  const isUnassignModalOpen = !!newHireToUnassign

  const isAssignModalOpen = openConfirmationModal === ASSIGN_BUDDY_MODAL
  const isReassignModalOpen = openConfirmationModal === REASSIGN_BUDDY_MODAL

  const onSuccess = () => {
    dispatch(newHireSlice.asyncActions.admin.fetchNewHires({ perPage: NEW_HIRES_PER_PAGE }))
    setOpenConfirmationModal(null)
  }

  const [assignBuddy, { isLoading: isAssigning }] = useApi(API.admin.onboardingBuddies.create, {
    onSuccess,
  })

  const [reassignBuddy, { isLoading: isReassigning }] = useApi(API.admin.onboardingBuddies.reassign, {
    onSuccess,
  })

  const [unassignBuddy, { isLoading: isUnassigning }] = useApi(API.admin.onboardingBuddies.unassign, {
    onSuccess: () => {
      onSuccess()
      setNewHireToUnassign(null)
    },
  })


  const onReassignBuddy = () => {
    const params = { onboardingBuddyId: onboardingBuddy.id, newHireId: newHire.id }
    reassignBuddy(params)
  }

  const onAssignBuddy = () => {
    const params = { onboardingBuddyId: onboardingBuddy.id, newHireId: newHire.id }
    assignBuddy(params)
  }

  const onUnassignBuddy = () => {
    const params = { onboardingBuddyId: onboardingBuddy.id, newHireId: newHireToUnassign?.id }
    unassignBuddy(params)
  }

  return (
    <>
      <DropdownMenuContainer menuType='kebab' dropdownHorizontalOffsetPx={30}>
        {newHire && (
          <CardDropdownMenuItem
            primaryText={I18N(allowMultipleNewHires ? 'assign_to' : 'reassign_to', { newHire: newHire.preferredName })}
            width='195px'
            className='p-3'
            onClick={() => setOpenConfirmationModal(allowMultipleNewHires ? ASSIGN_BUDDY_MODAL : REASSIGN_BUDDY_MODAL)}
          />
        )}
        {onboardingBuddy.newHireBuddies.map(newHireBuddy => (
          <CardDropdownMenuItem
            key={`unassign-budy-${newHireBuddy.id}`}
            primaryText={I18N('unassign_buddy', { newHireBuddy: newHireBuddy.preferredName })}
            width='195px'
            className='p-3'
            onClick={() => setNewHireToUnassign(newHireBuddy)}
          />
        ))}
      </DropdownMenuContainer>

      <ConfirmationModal
        visible={isAssignModalOpen}
        title={I18NAssignModal('title')}
        description={I18NAssignModal('help_text', { onboardingBuddy: onboardingBuddy.preferredFullName, newHire: newHire?.preferredFullName })}
        toggle={() => setOpenConfirmationModal(null)}
        onConfirmClick={onAssignBuddy}
        confirmText={I18N('assign')}
        isSaving={isAssigning}
      />

      <ConfirmationModal
        visible={isReassignModalOpen}
        title={I18NReassignModal('title')}
        description={I18NReassignModal('help_text', { onboardingBuddy: onboardingBuddy.preferredFullName, newHire: newHire?.preferredFullName })}
        toggle={() => setOpenConfirmationModal(null)}
        onConfirmClick={onReassignBuddy}
        confirmText={I18N('reassign')}
        isSaving={isReassigning}
      />

      <ConfirmationModal
        visible={isUnassignModalOpen}
        title={I18NUnassignModal('title')}
        description={I18NUnassignModal('help_text', { onboardingBuddy: onboardingBuddy.preferredFullName, newHire: newHireToUnassign?.preferredFullName })}
        toggle={() => setNewHireToUnassign(null)}
        onConfirmClick={onUnassignBuddy}
        confirmText={I18N('unassign')}
        isSaving={isUnassigning}
      />
    </>
  )
}

export default AssignedBuddiesActions
