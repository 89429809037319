import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import journeySlice from 'redux/slices/journeys'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import JourneysDashboardEmptyList from 'components/admin/journeys/dashboard/emptyList'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import JourneysTable, {
  COMPLETE
} from 'components/admin/journeys/journeys/journeysTable'

import { ONBOARDING, OFFBOARDING, CUSTOM } from 'utils/journeys/journeyBlueprintTypes'

import AsyncSearchInput from 'components/common/asyncSearchInput'
import ReactSelect from 'components/common/react_select'
import OnboardingPhaseText from 'components/admin/journeys/onboardingPhaseText'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.journeys.dashboards.journeys_dashboard')

const ONBOARDING_PHASES = ['preboarding', 'week_1', 'month_1', 'month_2', 'month_3', 'post_onboarding']
const COMPLETED_DROPDOWN_OPTIONS = [
  { value: ['completed', 'removed'], label: I18NCommon('all') },
  { value: 'completed', label: I18N('completed') },
  { value: 'removed', label: I18N('removed') },
]

const JourneysDashboardTable = () => {
  const dispatch = useDispatch()
  const { settings } = useCurrentCompany()

  const journeys = useSelector(journeySlice.selectors.getJourneys())

  const {
    isLoading,
    queryParams,
  } = useSelector(journeySlice.selectors.getMetaData())

  const { selectedTab } = useParams()

  const isCompleteTabSelected = selectedTab === COMPLETE
  const isOnboardingTabSelected = selectedTab === ONBOARDING

  const onboardingPhaseOptions = ['all', ...ONBOARDING_PHASES].map(phase => ({ value: phase, label: <OnboardingPhaseText onboardingPhase={phase} /> }))

  const [selectedOnboardingPhaseFilter, setSelectedOnboardingPhaseFilter] = useState('all')
  const [selectedCompletedFilter, setSelectedCompletedFilter] = useState(COMPLETED_DROPDOWN_OPTIONS[0].value)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('created_at')
  const [sortOrder, setSortOrder] = useState('asc')

  const fetchJourneys = (params = {}) => {
    const fullParams = {
      page: 1,
      perPage: 10,
      sortDir: sortOrder || 'asc',
      sortBy: sortBy || 'created_at',
      state: isCompleteTabSelected ? selectedCompletedFilter : ['active'],
      journeyBlueprintType: isCompleteTabSelected ? null : selectedTab,
      q: searchQuery,
      onboardingPhase: isOnboardingTabSelected ? selectedOnboardingPhaseFilter : null,
      ...params,
    }

    dispatch(journeySlice.asyncActions.admin.fetchAll(fullParams))
  }

  const handleNameQueryChange = (name) => {
    setSearchQuery(name)
    fetchJourneys({ q: name })
  }

  const handleFilterChange = (filter) => {
    setSelectedOnboardingPhaseFilter(filter.value)
    fetchJourneys({ onboardingPhase: filter.value })
  }

  const handleCompletedTabFilterChange = (filter) => {
    setSelectedCompletedFilter(filter.value)
    fetchJourneys({ state: filter.value })
  }

  const handleSortChange = (params) => {
    setSortOrder(params.sortDir)
    setSortBy(params.sortBy)
    fetchJourneys(params)
  }

  useEffect(() => {
    setSortOrder('asc')
    setSortBy('created_at')

    if (selectedTab) {
      fetchJourneys({ page: 1, sortBy: 'created_at', sortDir: 'asc' })
    }
  }, [selectedTab])


  return (
    <>
      <HistoryChangingPillTabSelector
        baseUrl='/admin/journeys_dashboard'
        historyKeyToChange='pathname'
        tabs={[
          { id: ONBOARDING, text: I18N(ONBOARDING) },
          { id: OFFBOARDING, text: I18N(OFFBOARDING), hidden: !settings.journeys?.offboarding?.enabled },
          { id: CUSTOM, text: I18N(CUSTOM), hidden: !settings.journeys?.customJourneys?.enabled },
          { id: COMPLETE, text: I18N(COMPLETE) },
        ]}
        pillClasses='text-normal'
      />

      <div className='d-flex justify-content-between mt-3 mb-3'>
        <AsyncSearchInput
          placeholder={I18N('search_by_name')}
          onKeyUp={handleNameQueryChange}
          className='mb-0'
        />
        {isOnboardingTabSelected && (
          <div className='d-flex justify-content-center align-items-center'>
            <p className='text-secondary mb-0 mr-2'>{I18N('phase')}</p>
            <ReactSelect
              className='onboarding-phase-select'
              options={onboardingPhaseOptions}
              onChange={handleFilterChange}
              value={onboardingPhaseOptions.filter(o => o.value === selectedOnboardingPhaseFilter)}
            />
          </div>
        )}

        {isCompleteTabSelected && (
          <div className='d-flex justify-content-center align-items-center'>
            <p className='text-secondary mb-0 mr-2'>{I18N('status')}</p>
            <ReactSelect
              className='CompletedTabSelect'
              options={COMPLETED_DROPDOWN_OPTIONS}
              onChange={handleCompletedTabFilterChange}
              value={COMPLETED_DROPDOWN_OPTIONS.filter(o => o.value === selectedCompletedFilter)}
            />
          </div>
        )}
      </div>
      {_.isEmpty(journeys) && !isLoading ? (
        <JourneysDashboardEmptyList
          selectedTab={selectedTab}
        />
      ) : (
        <JourneysTable
          queryParams={queryParams}
          journeys={journeys}
          onParamsChange={fetchJourneys}
          onSortChange={handleSortChange}
          type={selectedTab}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default JourneysDashboardTable
