import React from 'react'
import { Creatable } from 'components/common/react_select'

// If you need to render the selected option differently,
// provide a valueComponent FUNCTION that renders a React Component
// that function accepts a configuration parameter that will have the option label and value like the following:
// conf = { value: { label, value } }
// so conf.value.label and conf.value.value is how you get the important information
const CreatableMultiSelect = ({
  id,
  values,
  name,
  onChange,
  placeholder,
}) => {
  const handleOnChange = (selectedOptions) => {
    const event = {
      target: {
        name,
        value: selectedOptions.map(option => option.value),
      },
    }
    return onChange(event)
  }

  const formattedValues = (values || []).map(v => ({ label: v, value: v }))

  return (
    <Creatable
      id={id}
      name={name}
      value={formattedValues}
      isMulti
      placeholder={placeholder}
      onChange={handleOnChange}
    />
  )
}

export default CreatableMultiSelect
