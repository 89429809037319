import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'

export const initialState = {
  data: {},
  meta: {
    ...defaultMeta,
    isDeleting: {},
  },
}

const externalEmailAuthenticationSlice = createSlice({
  name: 'externalEmailAuthentications',
  initialState,
  reducers: {
    ...defaultActions,

    setData(state, action) {
      state.data = action.payload
    },

    isDeleting(state, action) {
      state.meta.isDeleting = { ...state.meta.isDeleting, ...action.payload }
    },
  },
})

_.assign(externalEmailAuthenticationSlice, {
  asyncActions: {
    admin: {
      fetchData: () => async (dispatch) => {
        dispatch(externalEmailAuthenticationSlice.actions.isLoading(true))

        try {
          const response = await API.admin.externalEmailAuthentications.fetchAll()
          dispatch(externalEmailAuthenticationSlice.actions.setData(response.data))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(externalEmailAuthenticationSlice.actions.isLoading(false))
        }
      },

      destroy: id => async (dispatch) => {
        dispatch(externalEmailAuthenticationSlice.actions.isDeleting({ [id]: true }))
        try {
          const response = await API.admin.externalEmailAuthentications.destroy({ id })
          dispatch(externalEmailAuthenticationSlice.actions.setData(response.data))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(externalEmailAuthenticationSlice.actions.isDeleting({ [id]: false }))
        }
      },
    },
  },
})

_.assign(externalEmailAuthenticationSlice, {
  selectors: {
    getMetaData: () => state => state.externalEmailAuthentications.meta,

    getData: () => state => state.externalEmailAuthentications.data,
  },
})

export default externalEmailAuthenticationSlice
