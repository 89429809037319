import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import FormEditableField from 'components/form_fields/formEditableField'
import classNames from 'classnames'

const I18N = i18nPath('views.admin.users')

const UserEditableField = ({
  user,
  fieldName,
  label,
  isRequired,
  inputType = 'text',
  textarea = false,
  footNote = null,
  setUser = () => { },
  className = '',
  placeholder = '',
  disabled = false,
}) => {
  const currentValue = user[fieldName] || ''

  const onChange = (e) => {
    setUser({ ...user, [fieldName]: e.target.value })
  }

  return (
    <FormEditableField
      className={classNames('UserEditableField', className)}
      fieldName={I18N(_.snakeCase(fieldName))}
      label={label}
      onChange={onChange}
      currentValue={currentValue}
      isRequired={isRequired}
      inputType={inputType}
      textarea={textarea}
      footNote={footNote}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}


export default UserEditableField
