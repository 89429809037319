import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import userSkillSlice from 'redux/slices/userSkills'

import { LoadingContainer } from 'components/common/loadingContainer'
import BackButton from 'components/common/backButton'
import PersonCard from 'components/common/people/personCard'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import InfiniteScroller from 'components/common/infiniteScroller'

const I18N = i18nPath('views.user_skills')

const UserSkillPage = () => {
  const { userSkillId } = useParams()
  const dispatch = useDispatch()

  const userSkill = useSelector(userSkillSlice.selectors.getUserSkill(userSkillId))
  const users = useSelector(userSkillSlice.selectors.getUsers())
  const { isLoadingUserSkill, userQueryParams, isLoadingUsers } = useSelector(userSkillSlice.selectors.getMetaData())

  const { page, total, totalPages } = userQueryParams
  const currentCompany = useCurrentCompany()
  const userSkillTypeName = userSkill?.userSkillType?.name || ''

  const handleUserFetch = () => {
    dispatch(userSkillSlice.asyncActions.fetchUsers(
      userSkillId,
      { ...userQueryParams, page: page + 1 }
    ))
  }

  const hasMoreUsers = () => page < totalPages

  useEffect(() => {
    dispatch(userSkillSlice.asyncActions.fetchUserSkill(userSkillId))
    dispatch(userSkillSlice.asyncActions.fetchUsers(userSkillId, { ...userQueryParams, page: 1 }))

    return () => {
      dispatch(userSkillSlice.actions.reset())
    }
  }, [userSkillId])

  return (
    !_.isEmpty(userSkill) && (
      <LoadingContainer isLoading={isLoadingUserSkill}>
        <div className='UserSkillPage container'>
          <div className='UserSkillPage-backbutton mt-4 pt-2'>
            <BackButton />
          </div>

          <div className='text-center mt-3 UserSkillPage-header mb-5'>
            <h5 className='mt-3 mb-0 text-secondary'>{userSkillTypeName}</h5>
            <h1 className='mt-0'>{userSkill.name}</h1>
            <div className='text-secondary'>
              {(total !== null) && I18N('people_count_message', {
                companyName: currentCompany.name,
                count: total,
                userSkill: userSkill.name,
              })}
            </div>
          </div>

          <InfiniteScroller
            isFetching={isLoadingUsers}
            onFetch={handleUserFetch}
            hasMoreContent={hasMoreUsers}
            className='UsersList'
          >
            {users.map(user => (
              <PersonCard key={user.id} person={user} />
            ))}
          </InfiniteScroller>

        </div>
      </LoadingContainer>
    )
  )
}

export default UserSkillPage
