import React from 'react'

import classNames from 'classnames'

import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'

const ClearyToggleButtonGroup = ({
  label, name, currentValue, onChange, options, disabled, helpText,
}) => (
  <div className={classNames('ClearyToggleButtonGroup mt-5', { isDisabled: disabled })}>
    <label className='d-block'>{label}</label>
    <div className='w-100 w-lg-25'>
      <ToggleButtonGroup name={name} type='radio' value={currentValue} onChange={onChange}>
        {options.map(option => (
          <ToggleButton key={option.label} variant='toggle' value={option.value}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
    {helpText && <span className='text-small text-secondary mt-1'>{helpText}</span>}
  </div>
)

export default ClearyToggleButtonGroup
