import RoundedAvatars from 'components/common/roundedAvatars'
import moment from 'moment'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.celebration_signers')


const CelebrationSigners = ({ celebration }) => {
  const disableNewComments = moment().isAfter(moment(celebration.eventDate).add(7, 'days'))

  if (celebration.activeAndVisibleSigners.length === 0) {
    return (
      <p className='text-dark-muted celebration-signature-label'>
        {I18N(disableNewComments ? 'no_signatures' : 'no_signatures_yet')}
      </p>
    )
  }

  return (
    <div className='d-flex gap-2 mb-3 align-items-center'>
      <RoundedAvatars
        users={celebration.activeAndVisibleSigners}
        avatarSize='25px'
        shownAvatars={3}
      />

      <span className='text-dark-muted celebration-signature-label'>
        {I18N('have_signed', { count: celebration.activeAndVisibleSigners.length })}
      </span>
    </div>
  )
}

export default CelebrationSigners
