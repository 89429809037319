import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import CurrentLocationForUser from './currentLocationForUser'
import CurrentTimeForUser from './currentTimeForUser'
import WorkingHours from './workingHours'
import OfficeSchedule from './officeSchedule'
import InfoModal from './infoModal'

const I18N = i18nPath('views.profile')
const infoIconPath = '/images/infoIcon.svg'

const WhereToFindMe = ({
  canEdit,
  fieldSettings,
  user,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const hasTimezone = !_.isEmpty(user.currentTimezone)

  if (!fieldSettings?.whereToFindMe || !hasTimezone) {
    return null
  }

  return (
    <section className='ProfileWhereToFindMe'>
      <header className='d-flex'>
        <h6 className='m-0'>{I18N('where_to_find_me.header')}</h6>
        {canEdit && (
          <div onClick={() => setIsModalOpen(true)}>
            <CdnSvg
              className='InfoIcon ml-2'
              src={infoIconPath}
            />
          </div>
        )}
      </header>
      <CurrentLocationForUser user={user} canEdit={canEdit} />
      {hasTimezone && <CurrentTimeForUser user={user} />}
      <WorkingHours user={user} canEdit={canEdit} />
      <OfficeSchedule user={user} canEdit={canEdit} />
      <InfoModal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} />
    </section>
  )
}

export default WhereToFindMe
