import build from 'redux-object'
import { createSlice } from '@reduxjs/toolkit'
import appSignal from 'services/appSignal'

import { i18nPath } from 'utils/i18nHelpers'

import API from 'services/api'

import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { showToastMessage } from 'redux/slices/toasts'
import buildByIdOrSlugFromEntitiesStore from 'redux/slices/utils/buildByIdOrSlugFromEntitiesStore'
import { notifySlackError } from 'utils/slack'
import entitySlice from 'redux/slices/entities'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'

const I18N = i18nPath('views.pages')

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildPagePayload = (page) => {
  const pageAttributes = _.pick(page, [
    'id',
    'title',
    'draftTitle',
    'draft',
    'content',
    'draftContent',
    'orderPosition',
    'ancestryOrderPosition',
    'restrictAccess',
    'parentId',
    'slug',
    'coverImage',
    'moveAfterPageId',
    'coverImageCropeedArea',
    'ownerId',
    'collaboratorIds',
    'showTableOfContents',
    'showFullWidth',
    'draftShowFullWidth',
    'archivedAt',
    'pageWorkspaceId',
    'feedbackEnabled',
    'showFeedbackRatings',
  ])

  if (page?.targetingRules) {
    pageAttributes.targetingRules = normalizeTargetingRules(page.targetingRules)
  }

  return pageAttributes
}

export const buildAutosavePayload = page => _.pick(page, [
  'id',
  'draftTitle',
  'draftContent',
  'draftShowFullWidth',
  'showTableOfContents',
  'contributorIds',
])


export const initialState = {
  isEditing: false,
  archivedPageIds: [],
  upToDatePageIds: [],
  meta: {
    ...defaultMeta,
    isDeleting: false,
    isCopying: false,
    isLoadingAllPages: false,
    hasLoadedAllPages: false,
    isLoadingDraftData: false,
    isPublishing: false,
    isLoadingArchivedPages: false,
    archivedPagesQueryParams: defaultPaginationParams,
    showArchivedPagesLink: false,
  },
}

const pageSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    ...defaultActions,

    hasLoadedAllPages(state, action) {
      state.meta.hasLoadedAllPages = action.payload
    },

    isDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },

    isCopying(state, action) {
      state.meta.isCopying = action.payload
    },

    isLoadingAllPages(state, action) {
      state.meta.isLoadingAllPages = action.payload
    },

    isEditing(state, action) {
      state.isEditing = action.payload
    },

    isPublishing(state, action) {
      state.meta.isPublishing = action.payload
    },

    isLoadingArchivedPages(state, action) {
      state.meta.isLoadingArchivedPages = action.payload
    },

    setArchivedPageIds(state, action) {
      state.archivedPageIds = action.payload
    },

    setArchivedPagesQueryParams(state, action) {
      state.meta.archivedPagesQueryParams = action.payload
    },

    setUpToDatePageIds(state, action) {
      state.upToDatePageIds = action.payload
    },

    setShowArchivedPagesLink(state, action) {
      state.meta.showArchivedPagesLink = action.payload
    },

    isLoadingDraftData(state, action) {
      state.meta.isLoadingDraftData = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  fetch: (pageSlug, params = {}, onSuccess = () => {}) => async (dispatch, getState) => {
    const inPreboardingExperience = getState().currentUser.inPreboardingExperience
    dispatch(pageSlice.actions.isLoading(true))
    dispatch(pageSlice.actions.isNotFound(false))

    try {
      let response
      if (inPreboardingExperience) {
        response = await API.journey.preboarding.pages.fetch(pageSlug)
      } else {
        response = await API.pages.fetch(pageSlug, params)
      }

      dispatch(entitySlice.actions.add({ data: response.data }))
      onSuccess(response.data)
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(pageSlice.actions.setError('Failed to fetch page'))
      dispatch(pageSlice.actions.isNotFound(true))
    } finally {
      dispatch(pageSlice.actions.isLoading(false))
    }
  },

  fetchPageDraftData: (pageId, onSuccess = () => {}) => async (dispatch) => {
    dispatch(pageSlice.actions.isLoadingDraftData(true))

    try {
      const response = await API.pages.fetch(pageId)

      dispatch(entitySlice.actions.update({ data: response.data }))
      onSuccess(response.data)
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isLoadingDraftData(false))
    }
  },

  create: (page, onSuccess = pageSlug => null) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.create(buildPagePayload(page))
      const pageSlug = response.data.data.attributes.slug
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('page_created'), type: 'success' }))

      // Redirect user to the Page page
      onSuccess(pageSlug)
    } catch (e) {
      notifySlackError({
        action: 'create',
        exception: e,
        modelType: 'Page',
      })

      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(pageSlice.actions.setError('Failed to create page'))
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },

  update: (page, onSuccess = (pageSlug) => {}) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.update(buildPagePayload(page))

      const pageSlug = response.data.data.attributes.slug
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('page_updated'), type: 'success' }))
      dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response?.data || {}))
      onSuccess(pageSlug)
    } catch (e) {
      notifySlackError({
        action: 'update',
        exception: e,
        modelType: 'Page',
        modelId: page.id,
      })

      if (e?.response?.data?.error?.errors?.slug) {
        dispatch(showToastMessage({ message: I18N('page_slug_already_exists'), type: 'error' }))
        dispatch(pageSlice.actions.setError(I18N('page_slug_already_exists')))
      } else if (e?.response?.data?.error?.message) {
        const message = e?.response?.data?.error?.message
        dispatch(showToastMessage({ message, type: 'error' }))
        dispatch(pageSlice.actions.setError(message))
      } else {
        dispatch(showToastMessage({ message: I18N('page_failed_to_update'), type: 'error' }))
        dispatch(pageSlice.actions.setError(I18N('page_failed_to_update')))
      }
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },

  destroy: (page, onSuccess = () => null) => async (dispatch) => {
    dispatch(pageSlice.actions.isDeleting(true))

    try {
      await API.pages.destroy(page)

      dispatch(entitySlice.actions.remove({ id: page.id, type: 'page' }))
      onSuccess()
    } catch (e) {
      dispatch(pageSlice.actions.setError(I18N('page_failed_to_destroy')))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isDeleting(false))
    }
  },

  movePage: (pageId, moveAfterPageId, newParentId = null, { fetchNavigation }) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const movePageParams = {
        id: pageId,
        parentId: newParentId || null,
        moveAfterPageId,
        ancestryOrderPosition: null,
        children: null,
        parent: null,
      }

      const response = await API.pages.update(movePageParams)

      // we don't need to update the entity slice here because only the orderPosition is changing
      // and we will fetch the tree after the move
      // but I'm leaving this here so we know why we're not updating the entity slice
      // dispatch(entitySlice.actions.update({ data: response.data }))
      fetchNavigation(pageId)
      dispatch(showToastMessage({ message: I18N('page_updated'), type: 'success' }))
      dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response?.data || {}))
    } catch (e) {
      dispatch(pageSlice.actions.setError(I18N('page_failed_to_move')))
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(pageSlice.actions.setError('Failed to update page'))
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },

  copy: (page, onSuccess = (pageSlug) => {}) => async (dispatch) => {
    dispatch(pageSlice.actions.isCopying(true))

    try {
      const response = await API.pages.copy(_.pick(page, ['id', 'title']))
      const pageSlug = response.data.data.attributes.slug
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('page_copied'), type: 'success' }))
      onSuccess(pageSlug)
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(pageSlice.actions.setError('Failed to copy page'))
      console.error(e)
    } finally {
      dispatch(pageSlice.actions.isCopying(false))
    }
  },

  revertToDraft: (pageId, onSuccess = () => null) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.revertToDraft(pageId)

      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('page_updated'), type: 'success' }))
      dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response?.data || {}))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },

  applyTemplate: (params, onSuccess = () => {}) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.applyTemplate(params)
      dispatch(entitySlice.actions.update({ data: response.data }))


      const newDraftContent = response.data?.data?.attributes?.draftContent

      dispatch(collaborativeEditorSlice.asyncActions.applyContent(newDraftContent))
      dispatch(showToastMessage({ message: I18N('apply_template_success'), type: 'success' }))
      onSuccess()
    } catch (e) {
      dispatch(showToastMessage({ message: I18N('failed_to_apply_template'), type: 'error' }))
      dispatch(pageSlice.actions.setError(I18N('failed_to_apply_template')))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },

  restoreFromSnapshot: (pageId, snapshotId, onSuccess = () => {}) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.restoreFromSnapshot(pageId, snapshotId)
      dispatch(entitySlice.actions.update({ data: response.data }))

      const newDraftContent = response.data?.data?.attributes?.draftContent
      dispatch(collaborativeEditorSlice.asyncActions.applyContent(newDraftContent))
      dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response.data))
      dispatch(showToastMessage({ message: I18N('revert_to_previous_version_success'), type: 'success' }))
      onSuccess()
    } catch (e) {
      dispatch(showToastMessage({ message: I18N('revert_to_previous_version_failed'), type: 'error' }))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },


  autosave: page => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.autosave(buildAutosavePayload(page))

      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response?.data || {}))
    } catch (e) {
      notifySlackError({
        action: 'autosave',
        exception: e,
        modelType: 'Page',
        modelId: page.id,
      })

      if (e?.response?.data?.error?.message) {
        const message = e?.response?.data?.error?.message
        dispatch(showToastMessage({ message, type: 'error' }))
        dispatch(pageSlice.actions.setError(message))
      } else {
        dispatch(showToastMessage({ message: I18N('page_failed_to_autosave'), type: 'error' }))
        dispatch(pageSlice.actions.setError(I18N('page_failed_to_autosave')))
      }

      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },

  publish: (page, onSuccess = (newSlug, meta) => {}) => async (dispatch) => {
    dispatch(pageSlice.actions.isPublishing(true))

    try {
      const response = await API.pages.publish(buildPagePayload(page))

      const newSlug = response.data.data.attributes.slug
      const meta = response.data.meta || {}
      dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response?.data || {}, true))
      onSuccess(newSlug, meta)
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('publish_success'), type: 'success' }))
    } catch (e) {
      notifySlackError({
        action: 'publish',
        exception: e,
        modelType: 'Page',
        modelId: page.id,
      })

      if (e?.response?.data?.error?.errors?.slug) {
        dispatch(showToastMessage({ message: I18N('page_slug_already_exists'), type: 'error' }))
        dispatch(pageSlice.actions.setError(I18N('page_slug_already_exists')))
      } else if (e?.response?.data?.error?.message) {
        const message = e?.response?.data?.error?.message
        dispatch(showToastMessage({ message, type: 'error' }))
        dispatch(pageSlice.actions.setError(message))
      } else {
        dispatch(showToastMessage({ message: I18N('publish_failed'), type: 'error' }))
        dispatch(pageSlice.actions.setError(I18N('publish_failed')))
      }
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isPublishing(false))
    }
  },

  setExpiration: (page, onSuccess = () => {}, isArchiving = false) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.setExpiration(page)

      dispatch(entitySlice.actions.update({ data: response.data }))
      const message = isArchiving ? I18N('page_archived') : I18N('page_expiration_set')
      dispatch(showToastMessage({ message, type: 'success' }))

      if (isArchiving) {
        const workspaceId = response?.data?.data[0]?.attributes?.pageWorkspaceId

        if (workspaceId) {
          // We need to update the workspace in the entity slice to show the archive link
          dispatch(entitySlice.actions.update({
            data: {
              data: {
                id: workspaceId,
                type: 'pageWorkspace',
                attributes: {
                  showArchivedPagesLink: true,
                },
              },
            },
          }))
        }

        dispatch(pageSlice.actions.setShowArchivedPagesLink(true))
      }

      dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response?.data || {}))
      onSuccess()
    } catch (e) {
      dispatch(showToastMessage({ message: I18N('page_failed_to_update'), type: 'error' }))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },

  unarchive: (page, onSuccess = () => null) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.unarchive(page)

      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('page_restored'), type: 'success' }))
      dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response?.data || {}))
      onSuccess()
    } catch (e) {
      dispatch(showToastMessage({ message: I18N('page_failed_to_update'), type: 'error' }))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },

  fetchArchivedPages: (params = {}) => async (dispatch) => {
    dispatch(pageSlice.actions.isLoadingArchivedPages(true))

    try {
      const response = await API.pages.fetchArchivedPages(params)
      const archivedPageIds = response.data.data.map(page => page.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(pageSlice.actions.setArchivedPageIds(archivedPageIds))
      dispatch(pageSlice.actions.setArchivedPagesQueryParams(queryParamsFromHeaders(response)))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isLoadingArchivedPages(false))
    }
  },

  markAsUpToDate: (params = {}) => async (dispatch) => {
    dispatch(pageSlice.actions.isSaving(true))

    try {
      const response = await API.pages.markAsUpToDate(params)
      const pageIds = response.data.data.map(page => page.id)
      dispatch(pageSlice.actions.setUpToDatePageIds(pageIds))
      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(pageSlice.actions.isSaving(false))
    }
  },
}


//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------

const selectors = {
  getAllPages: () => (state) => {
    const pages = build(state.entities, 'page') || []

    const filteredPages = pages.filter(p => _.isNil(p?.archivedAt) || p?.archivedAt > new Date().toISOString())

    return { pages: filteredPages }
  },
  getPageData: idOrSlug => (state) => {
    const { meta, isEditing } = state.pages

    const page = buildByIdOrSlugFromEntitiesStore(idOrSlug, 'page', state) || {}

    return {
      page,
      meta,
      isEditing,
    }
  },

  getPage: idOrSlug => state => buildByIdOrSlugFromEntitiesStore(idOrSlug, 'page', state) || {},

  getPagesMetadata: () => state => state.pages.meta,

  getArchivedPages: () => (state) => {
    const pages = state.pages.archivedPageIds.map(id => build(state.entities, 'page', id)).filter(Boolean) || []

    const archivedPages = pages.filter(p => !_.isNil(p?.archivedAt) && p?.archivedAt <= new Date().toISOString())

    return archivedPages
  },

  getPagesMarkedAsUpToDate: () => state => state.pages.upToDatePageIds.map(id => build(state.entities, 'page', id)) || [],

  getBreadcrumbData: pageId => (state) => {
    const breadcrumbs = []
    let currentPageId = pageId

    while (currentPageId) {
      const page = buildByIdOrSlugFromEntitiesStore(currentPageId, 'page', state) || {}

      breadcrumbs.unshift({
        title: page.title,
        displayPath: page.displayPath,
      })

      currentPageId = page.parentId
    }

    return breadcrumbs
  },
}


export default {
  ...pageSlice,
  selectors,
  asyncActions,
}
