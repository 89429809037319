import React from 'react'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ReactionsAndComments from 'components/common/reactions/reactionsAndComments'

const AwardedBadgeSocial = ({ awardedBadge, showCommentsOnInit = true }) => {
  const { settings } = useCurrentCompany()
  const commentsEnabled = settings.comments.awardedBadges.enabled
  const useReactions = settings.reactions.type === 'full_emoji_library'

  return (
    <ReactionsAndComments
      record={awardedBadge}
      type='awarded_badge'
      disableComments={!commentsEnabled}
      showCommentsOnInit={showCommentsOnInit}
      onlyLike={!useReactions}
    />
  )
}

export default AwardedBadgeSocial
