import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_assistant.dashboard.main_content.banners')

interface DashboardBannerProps {
  illustration: string
  title: string
  description: string | React.ReactNode
  children: React.ReactNode
  className?: string
}

const DashboardBanner = ({
  illustration, title, description, children, className = '',
}: DashboardBannerProps) => (
  <div
    className={classNames(
      'DashboardBanner d-flex flex-column justify-content-center border-radius card-shadow',
      className
    )}
  >
    <div className='text-center p-4'>
      <img src={illustration} />
    </div>

    <div className='content text-left px-4 py-5 border-radius-bottom'>
      <h4 className='mb-2'>{title}</h4>
      <p className='text-small'>{description}</p>

      {children}
    </div>
  </div>
)

interface CompletedIngestionBannerProps {
  channels: string[]
  faqsCount: number
  ingestionId: string
}

export const CompletedIngestionBanner = ({ channels, faqsCount, ingestionId }: CompletedIngestionBannerProps) => (
  <DashboardBanner
    illustration='/images/highFiveImageWithBackground.svg'
    title={I18N('ingestion_completed_banner.title')}
    description={
      i18nFormat(
        I18N('ingestion_completed_banner.description'),
        <span className='bold'>{channels.join(', ')}</span>,
        <span className='bold'>{faqsCount} FAQs</span>
      )
    }
    className='CompletedIngestionBanner'
  >
    <div className='text-center mt-5'>
      <Link to={`/knowledge_ingestions/${ingestionId}`} className='btn btn-primary'>
        {I18N('ingestion_completed_banner.cta')}
      </Link>
    </div>
  </DashboardBanner>
)

interface ScanningChannelsBannerProps {
  channels: string[]
}

export const ScanningChannelsBanner = ({ channels }: ScanningChannelsBannerProps) => (
  <DashboardBanner
    illustration='/images/illustrations/ai_platform_wizard_04.gif'
    title={I18N('scanning_banner.title', { count: channels.length })}
    description={
      i18nFormat(
        I18N('scanning_banner.description'),
        <span className='bold'>{channels.join(', ')}</span>
      )
    }
  >
    <p className='text-small bold'>{I18N('scanning_banner.help_text')}</p>
  </DashboardBanner>
)
