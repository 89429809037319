import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SVG from 'react-inlinesvg'
import { i18nPath } from 'utils/i18nHelpers'
import profileSlice from 'redux/slices/profiles'

import ArticleCard from 'components/articles/articleCard'
import InfiniteScroller from 'components/common/infiniteScroller'

const I18N = i18nPath('views.profile')

const Articles = ({ user }) => {
  const dispatch = useDispatch()
  const allArticles = useSelector(profileSlice.selectors.getAssociatedOrAuthoredArticles())
  const { isLoadingArticles, queryParams } = useSelector(profileSlice.selectors.getMetaData())

  const hasMoreArticles = () => {
    const { page, totalPages } = queryParams
    return page < totalPages
  }

  const dispatchFetchArticles = (paginationParams = { page: 1, perPage: 5 }) => {
    dispatch(profileSlice.asyncActions.fetchMentionedOrAuthoredArticles(user.id, paginationParams))
  }

  const fetchMoreArticles = () => {
    dispatchFetchArticles({ page: queryParams.page + 1, perPage: 5 })
  }

  useEffect(() => {
    dispatchFetchArticles()
  }, [])

  return (
    <InfiniteScroller
      isFetching={isLoadingArticles}
      onFetch={fetchMoreArticles}
      hasMoreContent={hasMoreArticles}
      className='d-flex flex-column align-items-center w-100'
    >
      {allArticles.map(article => (
        <div className='mt-3 w-100' key={`article-${article.id}`}>
          <ArticleCard article={article} tracking={false} readOnly />
        </div>
      ))}
    </InfiniteScroller>
  )
}

export default Articles
