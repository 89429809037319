import { GreetingCardInvitationsContext } from 'components/celebrations/greetingCardInvitations'
import CelebrationModalContext, { useCelebrationModalValue } from 'components/celebrations/greeting_card_actions/celebrationModalContext'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import FullPageEditor from 'components/common/fullPageEditor'
import useHideSlackWidget from 'components/common/hooks/useHideSlackWidget'
import Error404 from 'components/errors/404'
import { useCelebrationForm, useLoadCelebration } from 'pages/people/celebration_editor_page/celebrationEditorHooks'
import Header from 'pages/people/celebration_editor_page/celebrationEditorPageHeader'
import Sidebar from 'pages/people/celebration_editor_page/celebrationEditorPageSidebar'
import EditCelebrationEditor from 'pages/people/celebration_editor_page/editCelebrationEditor'
import NewCelebrationEditor from 'pages/people/celebration_editor_page/newCelebrationEditor'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { trackAmplitudeEvent } from 'services/tracker'


const CelebrationEditorPageInner = () => {
  const [showSidebar, setShowSidebar] = useState(false)

  useHideSlackWidget()

  const {
    showLoadingIndicator,
    showNotFound,
    celebration,
    isNew,
    templates,
    isLoadingTemplates,
  } = useLoadCelebration()

  const {
    workingCopy,
    setWorkingCopy,
    create,
    isCreating,
    areChangesPresent,
    isValid,
    onPeopleInvited,
  } = useCelebrationForm({ celebration, templates, isNew })

  const celebrationModalValue = useCelebrationModalValue()

  const closeSidebar = () => setShowSidebar(false)

  useEffect(() => {
    if (isNew) return

    trackAmplitudeEvent('edit_celebration_page_viewed')
  }, [])

  if (showNotFound) return <Error404 />
  if (showLoadingIndicator || !workingCopy) return <CirclesLoadingIndicator />

  return (
    <GreetingCardInvitationsContext.Provider value={{ onPeopleInvited }}>
      <CelebrationModalContext.Provider value={celebrationModalValue}>
        <FullPageEditor
          header={(
            <Header
              celebration={celebration}
              areChangesPresent={areChangesPresent}
              workingCopy={workingCopy}
            />
          )}
          sidebar={(
            <Sidebar
              show={showSidebar}
              onClose={closeSidebar}
              setWorkingCopy={setWorkingCopy}
              workingCopy={workingCopy}
              templates={templates}
            />
          )}
        >
          <div className='CelebrationEditor__Main'>
            {isNew ? (
              <NewCelebrationEditor
                workingCopy={workingCopy}
                setWorkingCopy={setWorkingCopy}
                create={create}
                isCreating={isCreating}
                isValid={isValid}
                isLoadingTemplates={isLoadingTemplates}
              />
            ) : (
              <EditCelebrationEditor
                workingCopy={workingCopy}
                setWorkingCopy={setWorkingCopy}
                celebration={celebration}
                setShowSidebar={setShowSidebar}
              />
            )}
          </div>
        </FullPageEditor>
      </CelebrationModalContext.Provider>
    </GreetingCardInvitationsContext.Provider>
  )
}

// we want to completely re-render the page when the celebrationId changes
// helpfull to avoid bugs when we create a new celebration since it's redirecting to the same page
// in a different url
const CelebrationEditorPage = () => {
  const { celebrationId } = useParams()

  return <CelebrationEditorPageInner key={celebrationId} />
}

export default CelebrationEditorPage
