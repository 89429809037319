import React, { useEffect } from 'react'
import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath } from 'utils/i18nHelpers'
import UserSelectField from 'components/admin/users/userEditor/userSelectField'
import adminGroupSlice from 'redux/slices/admin/groups'
import { useSelector, useDispatch } from 'react-redux'
import FormSelectField from 'components/form_fields/formSelectField'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.users')

const LOCATION_AND_TEAMS_SECTION_FIELDS = ['city', 'officeLocation', 'country', 'region']

const LocationAndTeamsSection = ({
  user = null,
  setUser = () => { },
  initialIsOpen = false,
}) => {
  const dispatch = useDispatch()

  const currentCompany = useCurrentCompany()
  const { settings, pluralGroupDisplayName } = currentCompany

  const groups = useSelector(adminGroupSlice.selectors.getGroups())

  const userGroups = user && user.groups ? _.uniqBy(user.groups, 'id') : []

  useEffect(() => {
    if (settings.groups.enabled) {
      dispatch(adminGroupSlice.asyncActions.fetchGroups())
    }
  }, [])

  return (
    <CollapsibleCard
      title={I18N('sections.location_and_teams', { pluralGroupDisplayName })}
      initialIsOpen={initialIsOpen}
      className='mt-3'
    >
      <p className='text-secondary'>{I18N('sections.location_and_teams_note')}</p>
      <div className='LocationAndTeamsSection mt-3'>
        {LOCATION_AND_TEAMS_SECTION_FIELDS.map(field => (
          <UserSelectField
            key={field}
            user={user}
            setUser={setUser}
            fieldName={field}
            placeholder={I18N(_.snakeCase(`select_${field}`))}
            isCreatable
          />
        ))}
        {settings.groups.enabled && (
          <FormSelectField
            placeholder={I18N('select_teams', { pluralGroupDisplayName })}
            getOptionValue={group => group.id}
            getOptionLabel={group => `${group?.groupType?.name}: ${group?.name}`}
            fieldName={pluralGroupDisplayName}
            currentValue={userGroups}
            onChange={value => setUser({ ...user, groups: value })}
            options={groups}
            isMulti
          />
        )}
      </div>
    </CollapsibleCard>
  )
}

export default LocationAndTeamsSection
