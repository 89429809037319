import React, { useState } from 'react'

import classNames from 'classnames'

import Modal from 'components/common/modal'
import TaskDetail from 'components/journeys/tasks/taskDetail'
import IntroductionDetail from 'components/journeys/introductions/introductionDetail'
import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import BuddyAssignmentDetail from 'components/journeys/tasks/buddyAssignmentDetail'

const introductionType = 'introduction'
const taskType = 'task'
const buddyAssignmentTaskType = 'onboarding_buddy_assignment'

const I18N = i18nPath('views.journeys.taskable_detail.preview')

export const TaskableTemplatePreviewModal = ({
  taskableTemplateWorkingCopy = null,
  taskableType = '',
  className = '',
  journeyBlueprintType = 'onboarding',
  isModalOpen,
  toggle,
}) => {
  const isIntroduction = taskableType === introductionType
  const isTask = taskableType === taskType
  const isBuddyAssignmentTask = taskableType === buddyAssignmentTaskType

  const taskableTemplateWithStepAttributes = {
    ...taskableTemplateWorkingCopy,
    completionStatus: 'pending',
    completed: false,
    permittedActions: {
      complete: true,
    },
    companyEmailPresentForScheduling: true,
    introductionInvitations: [],
    step: {
      journey: {
        journeyBlueprint: {
          type: journeyBlueprintType,
        },
      },
    },
  }

  return (
    <Modal size='lg' className={classNames('TaskableDetailsModal', className)} visible={isModalOpen} toggle={toggle}>
      {isTask && (
        <TaskDetail
          className='px-3'
          task={taskableTemplateWithStepAttributes}
          hasTitle
          isPreview
        />
      )}
      {isIntroduction && (
        <IntroductionDetail
          className='px-3'
          introduction={taskableTemplateWithStepAttributes}
          hasTitle
          isPreview
        />
      )}
      {isBuddyAssignmentTask && (
        <BuddyAssignmentDetail
          className='px-3'
          task={taskableTemplateWithStepAttributes}
          hasTitle
          isPreview
        />
      )}
    </Modal>
  )
}

const TaskableTemplatePreviewButtonAndModal = ({
  taskableTemplateWorkingCopy = null,
  taskableType = '',
  className,
  journeyBlueprintType = 'onboarding',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className='TaskableTemplatePreviewButtonAndModal d-flex align-items-center'>
        <Button variant='secondary' onClick={() => setIsModalOpen(true)}>
          {I18N('task_preview')}
        </Button>
      </div>

      {isModalOpen && (
        <TaskableTemplatePreviewModal
          taskableTemplateWorkingCopy={taskableTemplateWorkingCopy}
          taskableType={taskableType}
          className={className}
          journeyBlueprintType={journeyBlueprintType}
          isModalOpen={isModalOpen}
          toggle={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default TaskableTemplatePreviewButtonAndModal
