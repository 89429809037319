import { useRef, useEffect } from 'react'

function useDidUpdate(callback, deps) {
  const hasMounted = useRef(false)

  useEffect(() => {
    if (hasMounted.current) {
      callback()
    } else {
      hasMounted.current = true
    }
  }, deps)
}

export default useDidUpdate
