import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import CardWithTable from 'components/analytics/common/cardWithTable'
import searchAnalyticsSlice from 'redux/slices/analytics/search'
import { i18nPath } from 'utils/i18nHelpers'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import { TopSelectedResultType } from 'types/analytics/search'
import TargetingRulesType from 'types/audience/targetingRules'
import usePathReusingQueryParams from 'components/common/hooks/usePathReusingQueryParams'
import { TAB_HEADER_QUERY_PARAMS } from 'components/analytics/helpers/useTabHeader'

const manWithTabletPath = '/images/analytics/manWithTabletIcon.svg'

interface TopSelectedResultsProps {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const I18N = i18nPath('views.platform_analytics.search.top_selected_results')

const TopSelectedResults = ({ periodStart, periodEnd, targetingRules }: TopSelectedResultsProps) => {
  const dispatch = useDispatch()

  const { data, totalPages, totalItems } = useSelector(searchAnalyticsSlice.selectors.getTopSelectedResults())
  const { topSelectedResults: { isLoading } } = useSelector(searchAnalyticsSlice.selectors.getMetaData())
  const isDataEmpty = totalItems === 0 && !isLoading

  useEffect(() => {
    dispatch(searchAnalyticsSlice.asyncActions.admin.fetchTopSelectedResults(
      {
        periodStart, periodEnd, page: 1, perPage: 10, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, targetingRules])

  const columns = [
    {
      style: { width: '55%' },
      header: I18N('result'),
      accessor: (result: TopSelectedResultType) => <Link to={result.path} className='text-truncate w-100 d-block ml-1' target='_blank'>{result.title}</Link>,
    },
    {
      header: I18N('type'),
      accessor: (result: TopSelectedResultType) => result.type,
    },
    {
      header: I18N('total_selected'),
      accessor: (result: TopSelectedResultType) => result.count,
    },
  ]

  const path = usePathReusingQueryParams({
    path: '/platform_analytics/search/top_selected_results',
    paramsToReuse: TAB_HEADER_QUERY_PARAMS,
  })

  if (isDataEmpty) {
    return (
      <EmptyStateCard
        title={I18N('title')}
        text={I18N('empty')}
        iconPath={manWithTabletPath}
        classname='SearchTables'
      />
    )
  }

  return (
    <CardWithTable
      title={I18N('title')}
      tableColumns={columns}
      tableData={data}
      classname='SearchTables'
      isLoading={isLoading}
      showSeeMoreLink={totalPages > 1}
      seeMorePath={path}
    />
  )
}

export default TopSelectedResults
