import React from 'react'
import { SimpleUserType } from 'types/user'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.orgchart')

type Props = {
  setIsProfilePreviewOpen: (value: boolean) => void
  user: SimpleUserType
}

const MobileShowPreviewButton = ({ setIsProfilePreviewOpen, user }: Props) => {
  if (!user?.preferredFullName) {
    return null
  }

  return (
    <div
      className='MobileShowPreviewButton position-absolute d-block d-sm-none text-small text-white text-center py-2 w-100'
      onClick={() => setIsProfilePreviewOpen(true)}
    >
      {I18N('tap_to_preview', { name: user.preferredFullName })}
    </div>
  )
}

export default MobileShowPreviewButton
