import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import LoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.pages')

const DEFAULT_PAGE_TITLE_PLACEHOLDER = I18N('default_page_title_placeholder')

const DEFAULT_PAGE_TITLE = I18N('default_page_title')

const DisplayTitle = ({
  className,
  onClick = () => null,
  value,
  hoverEnabled = false,
}) => (
  <div
    className={classNames('d-flex', { 'hover-background-color': hoverEnabled }, className)}
    onClick={onClick}
  >
    <h1 className='col-md-12 pl-0 EditableTitle EditableTitle-display'>
      {value}
    </h1>
  </div>
)

const CharacterCount = ({
  textOverLimit = false,
  maxLength = 100,
  length = 0,
}) => {
  const className = textOverLimit ? 'text-error' : 'text-muted'

  return (
    <small className={className}>
      {length}/{maxLength}
    </small>
  )
}

const EditableTitle = ({
  canEdit = false,
  className = '',
  editModeOnly = false,
  fieldName = 'title',
  focus = false, // isDraftEmpty
  maxLength = 100,
  onChange = (fields) => {},
  onSave = (fields) => {},
  record,
  showCharacterCount = true,
  showSaveCancelButtons = true,
}) => {
  const inputRef = useRef()

  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [title, setTitle] = useState('')
  const [textOverLimit, setTextOverLimit] = useState(false)
  const [errorClass, setErrorClass] = useState('')
  const [cancelEditing, setCancelEditing] = useState(false)

  const isEditable = canEdit || editModeOnly
  const editModeEnabled = isEditing || editModeOnly
  const showControls = showCharacterCount || showSaveCancelButtons
  const value = record[fieldName]

  useEffect(() => {
    // when textbox shows up, focus on it
    // need to change value to '' and then back
    // to the value to get cursor to show up at the end
    // otherwise the cursor shows up in the beginning
    if (isEditing) {
      const elem = inputRef.current
      elem.focus()
      const val = elem.value
      elem.value = ''
      elem.value = val
    }
  }, [isEditing])

  useEffect(() => {
    if (focus && inputRef?.current) {
      const elem = inputRef.current
      elem.focus()
    }
  }, [focus, inputRef?.current])

  useEffect(() => {
    setTextOverLimit(title?.length > maxLength)
    setErrorClass(title?.length >= maxLength ? 'border-error' : '')
  }, [title])

  useEffect(() => {
    setTitle(value)
  }, [value])

  const handleClick = (e) => {
    // don't do anything if it's not editable
    if (!isEditable) {
      return
    }

    setIsEditing(true)
    setCancelEditing(false)
    setTitle(value)
    e.target.parentElement.classList.remove('hover-background-color')
  }

  const handleChange = (e) => {
    if (e.target.value.length <= maxLength) {
      setTitle(e.target.value)
      if (onChange && typeof onChange === 'function') {
        onChange({ [fieldName]: e.target.value })
      }
    }
  }

  const handleSave = async () => {
    if (!cancelEditing) {
      setIsEditing(false)

      if (title !== record[fieldName]) {
        if (onSave && typeof onSave === 'function') {
          setIsSaving(true)
          await onSave({ [fieldName]: title })
          setIsSaving(false)
        }
      }
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSave()
    }
  }

  const cancelEdit = () => {
    setCancelEditing(true)
    setIsEditing(false)
    setTitle(value)
  }

  const hideTitle = title === DEFAULT_PAGE_TITLE

  return (
    <>
      {isSaving && (
        <LoadingIndicator />
      )}

      {!isSaving && editModeEnabled && (
        <>
          <h1 className={classNames('EditableTitle', className)}>
            <input
              value={hideTitle ? '' : title}
              onChange={handleChange}
              onBlur={handleSave}
              onKeyPress={handleKeyPress}
              className={`InlineEditInput EditedItem font-weight-700 pb-3 ${errorClass}`}
              style={{ color: 'var(--text-color-primary)' }}
              ref={inputRef}
              placeholder={DEFAULT_PAGE_TITLE_PLACEHOLDER}
            />
          </h1>
          {showControls && (

            <div className={`d-flex justify-content-between align-items-center mt-2 mb-4 ${!showSaveCancelButtons && 'py-3'}`}>
              {showCharacterCount && (
                <div className={classNames('CharacterCount', { 'position-relative': !showSaveCancelButtons })}>
                  <CharacterCount
                    textOverLimit={textOverLimit}
                    maxLength={maxLength}
                    length={hideTitle ? 0 : title?.length}
                  />
                </div>
              )}
              {showSaveCancelButtons && (
                <div className='d-flex justify-content-end align-items-center'>
                  <a className='text-muted-dark mr-4' onMouseDown={e => e.preventDefault()} onClick={cancelEdit}>{I18NCommon('cancel')}</a>
                  <button type='submit' className='btn btn-primary rounded-pill' onClick={handleSave}>{I18NCommon('save_changes')}</button>
                </div>
              )}
            </div>

          )}
        </>
      )}

      {!isSaving && !editModeEnabled && (
        <DisplayTitle
          className={className}
          hoverEnabled={isEditable}
          onClick={handleClick}
          value={record[fieldName]}
        />
      )}
    </>
  )
}

export default EditableTitle
