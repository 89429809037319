import React from 'react'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'

const WorkflowPage = () => {
  const { workflowId } = useParams()
  const { data: workflow, isLoading, isLoaded } = useFetch(API.admin.ai.workflows.fetch, [workflowId])

  return <div>{workflow?.name}</div>
}

export default WorkflowPage
