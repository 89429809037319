import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { I18NCommon } from 'utils/i18nHelpers'

export const VALID_SLUG_REGEX = /^[a-z0-9$]+(?:-[a-z0-9$]+)*$/
export const validSlug = slug => slug?.match(VALID_SLUG_REGEX)

type Props = {
  label?: string | React.ReactNode
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  className?: string
  errorMessage?: string | null
}

const SlugInput = ({
  label,
  value,
  onChange,
  disabled = false,
  className = '',
  errorMessage,
}: Props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const handleOnChange = (ev) => {
    onChange(ev.target.value)
    setShowErrorMessage(false)
  }

  useEffect(() => {
    if (errorMessage) {
      setShowErrorMessage(true)
    }
  }, [errorMessage])

  return (
    <div className={classNames('SlugInput', className)}>
      {label}
      <input
        className='w-100'
        type='text'
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
      />
      {!validSlug(value) && !disabled && (
        <div className='mt-2 text-danger'>
          {I18NCommon('invalid_slug')}
        </div>
      )}
      {showErrorMessage && errorMessage && (
        <div className='mt-2 text-danger'>
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default SlugInput
