import React from 'react'
import classNames from 'classnames'
import { ButtonNarrow } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import Controls from 'components/common/aiAnswersWizardModal/controls'

const I18N = i18nPath('views.ai_answers_wizard_modal')

const IndicatorsAndControls = ({
  numberOfSteps,
  currentStep,
  canSubmit,
  isLoading,
  handlePreviousStep,
  handleNextStep,
}) => (
  <div className='d-flex justify-content-center mt-5'>
    <div className='AiAnswersWizardModal__container d-flex align-items-center justify-content-between'>
      <div className='AiAnswersWizardModal__indicators d-flex align-items-center'>
        {Array.from({ length: numberOfSteps }).map((_, index) => (
          <div className='d-flex align-items-center'>
            <div
              className={
                classNames(
                  'AiAnswersWizardModal__indicators__indicator',
                  'd-flex align-items-center justify-content-center',
                  {
                    'AiAnswersWizardModal__indicators__indicator--active': currentStep === index + 1,
                    'AiAnswersWizardModal__indicators__indicator--done': currentStep > index + 1,
                  }
                )
              }
            >
              <div>
                {currentStep > 1 && <CdnSvg src='/images/checkIcon.svg' />}
              </div>
            </div>

            {index < numberOfSteps - 1 && (
              <div
                className={
                  classNames(
                    'AiAnswersWizardModal__indicators__separator',
                    {
                      'AiAnswersWizardModal__indicators__separator--active': currentStep > index + 1,
                    }
                  )
                }
              />
            )}
          </div>
        ))}
      </div>

      <Controls
        handleCancel={handlePreviousStep}
        handleConfirm={handleNextStep}
        isLoading={isLoading}
        cancelLabel={I18NCommon('back_button')}
        confirmLabel={currentStep < numberOfSteps ? I18NCommon('next') : I18N('lets_go')}
        cancelDisabled={currentStep === 1}
        confirmDisabled={(currentStep === numberOfSteps && !canSubmit) || isLoading}
      />
    </div>
  </div>
)

export default IndicatorsAndControls
