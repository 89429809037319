import React from 'react'

import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from './searchResultOption'

const bookOpenIconPath = '/images/bookOpenIcon.svg'

const PageOption = ({ option }) => {
  const { page, highlights } = option
  const { title } = { ...page, ...highlights }
  // if there is no content in highlights it doesn't make sense to use the one in page because it's not a string
  const content = highlights?.content

  return (
    <SearchResultOption
      image={<CdnSvg src={bookOpenIconPath} className='SearchResultOptionImg mr-1' />}
      title={title}
      secondaryTexts={[content]}
    />
  )
}

export default PageOption
