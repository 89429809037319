import React from 'react'
import InfoTooltip from 'components/common/infoTooltip'
import Tooltip from 'components/common/clearyTooltip'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

interface Props {
  value: number | string
  label: string
  className?: string
  tooltip?: string
  leftPart?: React.ReactNode
  iconName?: string
  border?: boolean
  hoverable?: boolean
}

const Stat = ({
  className, value, label, tooltip, leftPart, iconName, border, hoverable,
}: Props) => (
  <div className={classNames('stat', { 'stat--bordered': border, 'stat--hoverable': hoverable }, className)}>
    {leftPart && <div>{leftPart}</div>}
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <span className='stat-value'>
            {value}
          </span>
          <span className='stat-label'>
            {label}
            {tooltip && !iconName && <InfoTooltip text={tooltip} className='stats-info-tooltip' />}
          </span>
        </div>

        {iconName && (
          <Tooltip
            placement='bottom'
            content={(
              <div className='stat-icon'>
                <CdnSvg src={`/images/${iconName}.svg`} />
              </div>
            )}
          >
            <span className='ml-1 text-smallest'>{tooltip}</span>
          </Tooltip>
        )}
      </div>
    </div>
  </div>
)

export default Stat
