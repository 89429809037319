import React from 'react'

import FormEditableField from 'components/form_fields/formEditableField'
import FormTimezoneSelectField from 'components/form_fields/formTimezoneSelectField'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.timezones')

const TimezoneForm = ({ timezone, setTimezone }) => {
  const handleChange = (fieldName, value) => {
    setTimezone({
      ...timezone,
      [fieldName]: value,
    })
  }

  const generateChangeHandler = fieldName => (e) => {
    handleChange(fieldName, e.target.value)
  }

  return (
    <div className='w-100'>
      <div className='col-md-6 col-bg-6 col-sm-12'>
        <FormEditableField
          fieldName='location'
          label={I18N('form.location')}
          onChange={generateChangeHandler('location')}
          currentValue={timezone.location}
          isRequired
        />
        <FormTimezoneSelectField
          label={I18N('form.timezone')}
          placeholder={I18N('select_timezone')}
          currentValue={timezone.timezone}
          onChange={selectedValue => handleChange('timezone', selectedValue.timezone)}
          isClearable={false}
          isRequired
        />
      </div>
    </div>
  )
}

export default TimezoneForm
