import React, { useState } from 'react'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { Line } from 'react-chartjs-2'
import { transparent } from 'utils/color'


const selectedBorderWith = 3
const defaultBorderWith = 1

const CustomLegend = ({
  label,
  color,
  onClick,
  isSelected,
}) => (
  <div
    className='LegendItem d-flex flex-row align-items-center pointer mr-2 py-1 px-2'
    onClick={onClick}
    style={isSelected ? { borderColor: color, borderWidth: '1px' } : {}}
  >
    <div className='Color' style={{ backgroundColor: color }} />
    <div>{label}</div>
  </div>
)


interface LineData {
  label: string
  values: number[]
  color: string
  tooltipLabel: (count: number) => string
}


interface MultilineGraphProps {
  xlabels: string[]
  lines: LineData[]
  yAxisLabel: string
  isLoading: boolean
  asPercentage?: boolean
  height?: string | number
}


const MultilineGraph = ({
  lines, yAxisLabel, xlabels, isLoading, asPercentage, height,
}: MultilineGraphProps) => {
  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<null | number>(null)
  const currentCompany = useCurrentCompany()
  const { textColorSecondary } = currentCompany.customStyles.variables.colors

  const toggleDataset = (i: number) => {
    setSelectedDatasetIndex(i === selectedDatasetIndex ? null : i)
  }

  const dataValues = lines.flatMap(l => l.values.map(n => n * 100))

  const options = {
    onClick: (_event, chartElement) => {
      if (chartElement.length > 0) {
        const { datasetIndex } = chartElement[0]
        setSelectedDatasetIndex(datasetIndex)
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        grid: { display: false },
        min: asPercentage ? 0 : undefined,
        max: asPercentage ? Math.max(100, ...dataValues) : undefined,
        ticks: {
          color: textColorSecondary,
          callback: value => (asPercentage ? `${value}%` : value),
        },
        title: {
          display: !!yAxisLabel && !asPercentage,
          text: yAxisLabel,
          color: textColorSecondary,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        backgroundColor(context) { // Customizes the tooltip color
          const d = lines[context.tooltip.dataPoints[0].datasetIndex]
          return d.color
        },
        displayColors: false,
        titleAlign: 'center' as const,
        titleFont: { size: 15 },
        bodyFont: { size: 13 },
        callbacks: {
          title(context) { // Customizes the tooltip title
            return context[0].label
          },
          label(context) { // Customizes the tooltip body text
            const d = lines[context.datasetIndex]
            return d.tooltipLabel(context.parsed.y)
          },
        },
      },
    },
  }

  const rowColor = (datasetIndex, color) => (
    (selectedDatasetIndex === null || selectedDatasetIndex === datasetIndex)
      ? color : transparent(textColorSecondary, 0.4))

  const datasets = lines.map((data, i) => ({
    label: data.label,
    data: asPercentage ? data.values.map(v => Math.round(v * 100)) : data.values,
    borderColor: rowColor(i, data.color),
    borderWidth: selectedDatasetIndex === i ? selectedBorderWith : defaultBorderWith,
    tension: 0.4,
  }))

  const chartData = {
    labels: xlabels,
    datasets,
  }

  return (
    <section className='w-100'>
      <div className='chart-wrapper' style={{ height }}>
        {isLoading ? (
          <CirclesLoadingIndicator className='w-100 d-flex align-items-center h-100' />
        ) : (
          <Line options={options} data={chartData} />
        )}
      </div>
      {!isLoading && (
        <div className='d-flex flex-row mt-3'>
          {lines.map((d, i) => (
            <CustomLegend
              key={i}
              label={d.label}
              color={d.color}
              onClick={() => toggleDataset(i)}
              isSelected={selectedDatasetIndex === i}
            />
          ))}
        </div>
      )}
    </section>
  )
}

export default MultilineGraph
