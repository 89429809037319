import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import buildNestedAttributesPayload from 'redux/slices/utils/buildNestedAttributesPayload'
import { SurveyAnswerType } from 'types/surveys/answer'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

export const initialState = {
  answerIds: [],
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams,
      perPage: 10,
    },
  },
}

const buildOptionSelectionPayload = (optionSelection: any) => _.pick(optionSelection, ['id', 'answerId', 'questionOptionId'])

export const buildAnswerPayload = (answer: Partial<SurveyAnswerType> | undefined, previousAnswer: any = null) => {
  if (!answer) {
    return null
  }

  const payload = _.pick<Partial<SurveyAnswerType>, any>(answer, [
    'id',
    'type',
    'number',
    'string',
    'questionId',
    'additionalText',
  ])

  payload.optionSelectionsAttributes = buildNestedAttributesPayload(answer.optionSelections, previousAnswer?.optionSelections, buildOptionSelectionPayload, 'questionOptionId')

  return payload
}

const surveyAnswerSlice = createSlice({
  name: 'surveyAnswers',
  initialState,
  reducers: {
    ...defaultActions,
    setAnswerIds(state, action) {
      state.answerIds = action.payload
    },

    clearAnswerIds(state) {
      state.answerIds = []
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },
  },
})

const asyncActions = {
  admin: {
    fetchAll: (formId, questionId, params = {}) => async (dispatch) => {
      dispatch(surveyAnswerSlice.actions.clearAnswerIds())
      dispatch(surveyAnswerSlice.actions.isLoading(true))

      try {
        const response = await API.admin.surveyAnswers.fetchAll(formId, questionId, params)

        const newQueryParams = queryParamsFromHeaders(response)
        const answerIds = response.data.data.map(surveyAnswer => surveyAnswer.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(surveyAnswerSlice.actions.setAnswerIds(answerIds))
        dispatch(surveyAnswerSlice.actions.setQueryParams(newQueryParams))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(surveyAnswerSlice.actions.isLoading(false))
      }
    },
  },
  create: (formId, responseId, newAnswer = {}, previousAnswer = null, onSuccess = () => {}) => async (dispatch) => {
    dispatch(surveyAnswerSlice.actions.isSaving(true))

    try {
      const answer = await API.surveyAnswers.create(formId, responseId, buildAnswerPayload(newAnswer, previousAnswer))

      dispatch(entitySlice.actions.update({ data: answer.data }))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.answer)
      dispatch(surveyAnswerSlice.actions.setError(error))
    } finally {
      dispatch(surveyAnswerSlice.actions.isSaving(false))
    }
  },
}

const selectors = {
  getAnswer: (answerId: string | null) => state => build(state.entities, 'surveyAnswer', answerId) || {},
  getAnswers: () => state => state.surveyAnswers.answerIds.map(id => build(state.entities, 'surveyAnswer', id)) || [],
  getMetaData: () => state => state.surveyAnswers.meta,
}

export default {
  ...surveyAnswerSlice,
  selectors,
  asyncActions,
}
