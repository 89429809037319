import React from 'react'
import { useParams } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import JourneyCommunicationCard from 'components/home/journeyCommunicationCard'
import API from 'services/api'
import BackButton from 'components/common/backButton'
import useFetch from 'components/common/hooks/useFetch'

const CommunicationPage = () => {
  const { communicationId } = useParams()

  const { data, isLoading } = useFetch(API.journey.communications.fetch, [communicationId])


  if (isLoading || !data) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='JourneyCommunicationPage'>
      <div>
        <BackButton className='mb-4' url='/' />
        <JourneyCommunicationCard communication={data} />
      </div>
    </div>
  )
}

export default CommunicationPage
