import React from 'react'
import classNames from 'classnames'
import { ACKNOWLEDGED, PENDING } from 'types/acknowledgement'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const answerIconPath = '/images/answerIcon.svg'

const I18N = i18nPath('views.article.acknowledgement')

const acknowledgementText = ({ status, acknowledgedAt, dueAt }) => {
  if (status === ACKNOWLEDGED) {
    return I18N('acknowledged_on', { acknowledgedAt: i18nMoment(acknowledgedAt).format('MMM D') })
  } else if (status === PENDING) {
    return I18N('requires_acknowledgement', { dueAt: i18nMoment(dueAt).format('MMM D') })
  } else {
    return I18N('overdue_required_acknowledgement', { dueAt: i18nMoment(dueAt).format('MMM D') })
  }
}

const AcknowledgementBanner = ({ article }) => {
  const { acknowledgementInfo } = article
  const status = acknowledgementInfo.status

  return (
    <div className={classNames('AcknowledgementBanner d-flex align-items-center w-100 p-2 mb-3 font-weight-600', status)}>
      <CdnSvg src={answerIconPath} className='AcknowledgementIcon mr-2' />
      <span className='AcknowledgementText mt-1'>
        {acknowledgementText(acknowledgementInfo)}
      </span>
    </div>
  )
}

export default AcknowledgementBanner
