import { QuestionTypeEnum } from 'types/surveys/question'

export const isTextQuestion = question => [
  QuestionTypeEnum.ShortText,
  QuestionTypeEnum.Paragraph,
  QuestionTypeEnum.Html,
].includes(question?.type)

export const isOptionsQuestion = question => [
  QuestionTypeEnum.Checkbox,
  QuestionTypeEnum.MultipleChoice,
].includes(question?.type)

const isValidScaleConfig = (config) => {
  const {
    minValue, maxValue, step, labels,
  } = config || {}

  // minValue can be 0, so we need to check for null
  if (!config || minValue === null || !maxValue || !step || !labels) {
    return false
  }

  if (minValue >= maxValue) {
    return false
  }

  return true
}

const isValidQuestion = (question) => {
  const { title, type, options } = question
  if (title?.trim() === '') {
    return false
  }

  if ([QuestionTypeEnum.Checkbox, QuestionTypeEnum.MultipleChoice].includes(type)) {
    // There cant be empty options
    if (!options || options?.some(option => option?.value?.trim() === '')) {
      return false
    }

    return options.length > 1
  }

  if (type === QuestionTypeEnum.Scale) {
    return isValidScaleConfig(question?.config || {})
  }

  return true
}

export default isValidQuestion
