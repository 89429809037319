import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'

import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.delete_modal')

const BlueprintDeletionModal = ({
  visible,
  toggle,
  closeMainModal,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { journeyBlueprintId } = useParams()
  const [isDeleting, setIsDeleting] = useState(false)

  const onSuccess = () => {
    setIsDeleting(false)
    closeMainModal()
    history.push('/admin/journeys')
  }

  const onDelete = () => {
    setIsDeleting(true)
    dispatch(journeyBlueprintSlice.asyncActions.admin.delete(journeyBlueprintId, onSuccess))
  }

  return (
    <Modal size='md' className='BlueprintDeletionModal' visible={visible} toggle={toggle}>
      <div className='p-2 text-center'>
        <h3>{I18N('title')}</h3>
        <p className='text-secondary'>{I18N('delete_journey_help_text')}</p>
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <div role='button' tabIndex={0} onClick={toggle} className='mr-3 text-muted-dark'>
            {I18NCommon('cancel')}
          </div>
          <Button disabled={isDeleting} onClick={onDelete} variant='danger'>{I18N('delete_journey')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default BlueprintDeletionModal
