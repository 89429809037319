import { useEffect } from 'react'

const useScrollToElement = (
  selector: string, { enabled = true, dependencies = [] }: { enabled?: boolean, dependencies?: any[] } = {}
) => {
  useEffect(() => {
    const element = document.querySelector(selector)

    if (element && enabled) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }, [selector, ...dependencies])
}

export default useScrollToElement
