import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import BackButton from 'components/common/backButton'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { Link } from 'react-router-dom'
import cultureAnalyticsSlice from 'redux/slices/analytics/culture'
import { ActiveSignerType } from 'types/analytics/culture'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const backToText = i18nPath('views.platform_analytics')('title')

const CultureActiveSignersPage = () => {
  const dispatch = useDispatch()

  const { order: queryOrder } = useQueryParams()

  const order = queryOrder === 'least' ? 'least' : 'most'
  const type = order === 'least' ? 'leastActiveSigners' : 'mostActiveSigners'
  const I18N = i18nPath(`views.platform_analytics.culture.${order}`)

  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    targetingRules,
    setTargetingRules,
    completedTargetingRules,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const {
    data, totalPages, page,
  } = useSelector(cultureAnalyticsSlice.selectors.getActiveSigners(type))
  const { [type]: { isLoading } } = useSelector(cultureAnalyticsSlice.selectors.getMetaData())

  const fetchData = ({ page }) => {
    dispatch(cultureAnalyticsSlice.asyncActions.admin.fetchActiveSigners(
      type,
      {
        periodStart, periodEnd, page, order, targetingRules: completedTargetingRules,
      }
    ))
  }

  useEffect(() => {
    fetchData({ page: 1 })
  }, [periodStart, periodEnd, completedTargetingRules])

  const columns = [
    {
      header: I18NCommon('user'),
      accessor: (author: ActiveSignerType) => (
        <div className='d-flex flex-row align-items-center'>
          <EmployeeThumbnailPhoto employee={author} size='25px' />
          <Link to={`/profile/${author.username}`} className='text-truncate w-100 d-block ml-1' target='_blank'>{author.preferredFullName}</Link>
        </div>
      ),
    },
    {
      header: I18N('signed'),
      style: { width: '15%' },
      accessor: (author: ActiveSignerType) => (
        author.cardSignedCount
      ),
    },
  ]
  return (
    <main className='include-default-grid mb-5'>
      <BackButton
        backToText={backToText}
        url='/platform_analytics/culture'
        className='grid-span-12 my-4'
      />

      <TabHeader
        className='mb-4'
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      <CardWithPaginatedTable
        title={I18N('title')}
        tableColumns={columns}
        tableData={data}
        classname='grid-span-12'
        isLoading={isLoading}
        showPagination={totalPages > 1}
        page={page}
        totalPages={totalPages}
        onPaginationClick={fetchData}
      />
    </main>
  )
}

export default CultureActiveSignersPage
