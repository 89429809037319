import React from 'react'

const FoodIcon = ({ fill = 'var(--text-color-primary)' }) => (
  <svg
    width='46px'
    height='35px'
    viewBox='0 0 46 35'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-35.000000, -24.000000)' fill={fill} fillRule='nonzero'>
        <g transform='translate(35.000000, 24.000000)'>
          <path d='M22.8838393,5.24473214 C15.4234821,5.24473214 9.35410714,11.3141071 9.35410714,18.7744643 C9.35410714,26.2348214 15.4235714,32.3042857 22.8838393,32.3042857 C30.3441071,32.3042857 36.4136607,26.2348214 36.4136607,18.7744643 C36.4136607,11.3141071 30.3441964,5.24473214 22.8838393,5.24473214 Z M22.8838393,30.5184821 C16.408125,30.5184821 11.1397321,25.2500893 11.1397321,18.774375 C11.1397321,12.29875 16.4080357,7.03035714 22.8838393,7.03035714 C29.3595536,7.03035714 34.6279464,12.2986607 34.6279464,18.774375 C34.6279464,25.2500893 29.3595536,30.5184821 22.8838393,30.5184821 Z' />
          <path d='M30.8929464,14.4101786 C30.6566071,13.9774107 30.1142857,13.818125 29.6814286,14.054375 C29.6669643,14.0623214 29.6535714,14.0714286 29.6396429,14.08 C29.2391964,14.3277679 29.0972321,14.8475893 29.325625,15.2658929 C29.9091964,16.3347321 30.2175893,17.5480357 30.2175893,18.7744643 C30.2175893,22.8183036 26.9276786,26.1082143 22.8838393,26.1082143 C18.8399107,26.1082143 15.5500893,22.8182143 15.5500893,18.7744643 C15.5500893,14.730625 18.84,11.4407143 22.8838393,11.4407143 C23.271875,11.4407143 23.6613393,11.47125 24.04125,11.5315179 C24.5125,11.6063393 24.9539286,11.2977679 25.0527679,10.8375 C25.05625,10.8215179 25.0602679,10.8058036 25.0628571,10.789375 C25.14,10.3023214 24.8077679,9.84491071 24.3207143,9.76776786 C23.8485714,9.69294643 23.3650893,9.655 22.88375,9.655 C17.8553571,9.655 13.7642857,13.7459821 13.7642857,18.7744643 C13.7642857,23.8029464 17.8552679,27.8939286 22.88375,27.8939286 C27.9122321,27.8939286 32.0033036,23.8028571 32.0033036,18.7744643 C32.0033036,17.2498214 31.619375,15.7407143 30.8929464,14.4101786 Z' />
          <path d='M28.5883929,11.3135714 C28.5473214,11.2725 28.5017857,11.235 28.4526786,11.2028571 C28.4044643,11.1707143 28.3526786,11.1430357 28.2982143,11.1207143 C28.2446429,11.0983929 28.1883929,11.0814286 28.13125,11.0698214 C28.0160714,11.0466071 27.8973214,11.0466071 27.7821429,11.0698214 C27.725,11.0814286 27.6696429,11.0983929 27.6151786,11.1207143 C27.5616071,11.1430357 27.5098214,11.1707143 27.4616071,11.2028571 C27.4125,11.235 27.3669643,11.2725 27.325,11.3135714 C27.2839286,11.3546429 27.2473214,11.4001786 27.2142857,11.4492857 C27.1821429,11.4975 27.1544643,11.5492857 27.1321429,11.60375 C27.1180357,11.6375 27.106875,11.6725 27.0971429,11.7079464 C27.0914286,11.72875 27.085625,11.7495536 27.0813393,11.7707143 C27.0698214,11.8277679 27.064375,11.8866964 27.064375,11.9447321 C27.064375,12.0036607 27.0697321,12.0617857 27.0813393,12.1198214 C27.0929464,12.1769643 27.1099107,12.2323214 27.1322321,12.2866964 C27.1545536,12.3403571 27.1822321,12.3921429 27.214375,12.4403571 C27.2474107,12.4894643 27.2840179,12.535 27.3250893,12.5760714 C27.3670536,12.6171429 27.4125893,12.6546429 27.4616964,12.6876786 C27.5099107,12.7198214 27.5616964,12.7475 27.6152679,12.7698214 C27.6697321,12.7921429 27.7250893,12.8091071 27.7822321,12.8207143 C27.8402679,12.8323214 27.8991964,12.8375893 27.9572321,12.8375893 C28.0152679,12.8375893 28.0741964,12.8322321 28.1313393,12.8207143 C28.1884821,12.8091964 28.2447321,12.7921429 28.2983036,12.7698214 C28.3450893,12.750625 28.3892857,12.726875 28.4315179,12.7003571 C28.4384821,12.6959821 28.4458929,12.6922321 28.4526786,12.6876786 C28.5017857,12.6546429 28.5473214,12.6171429 28.5883929,12.5760714 C28.6294643,12.535 28.6669643,12.4894643 28.6991071,12.4403571 C28.73125,12.3921429 28.7589286,12.3403571 28.78125,12.2866964 C28.8035714,12.2323214 28.8205357,12.1760714 28.8321429,12.1198214 C28.84375,12.0617857 28.85,12.0036607 28.85,11.9447321 C28.85,11.7099107 28.7544643,11.4795536 28.5883929,11.3135714 Z' />
          <path d='M45.6914286,31.5613393 L42.7955357,7.97696429 C42.5940179,6.33553571 42.0785714,4.77348214 41.2633929,3.334375 C40.7630357,2.45089286 40.1551786,1.629375 39.4567857,0.892589286 L39.3788393,0.810357143 C38.7838393,0.182589286 37.8938393,0.0152678571 37.1111607,0.384107143 C36.3286607,0.752857143 35.8911607,1.54598214 35.9966071,2.40455357 L39.6674107,32.3010714 C39.6791964,32.3970536 39.6953571,32.4914286 39.7157143,32.5841071 C40.0207143,33.9736607 41.2617857,34.9660714 42.6772321,34.9660714 C42.8,34.9660714 42.924375,34.9586607 43.0491964,34.9433036 C44.0352679,34.8222321 44.85375,34.2382143 45.3133036,33.4359821 C45.3616964,33.3515179 45.4060714,33.2647321 45.44625,33.1757143 C45.4864286,33.0866964 45.5224107,32.995625 45.5540179,32.9025893 C45.69625,32.4841964 45.74875,32.0283929 45.6914286,31.5613393 Z M37.7691071,2.186875 C37.7647321,2.15107143 37.7530357,2.055625 37.8725893,1.99928571 C37.9053571,1.98383929 37.9341071,1.97785714 37.9591071,1.97785714 C38.0252679,1.97785714 38.065,2.01982143 38.0828571,2.03875 L38.1608036,2.12098214 C38.7597321,2.75276786 39.2808036,3.45705357 39.7097321,4.214375 C40.4085714,5.448125 40.8505357,6.78732143 41.0233036,8.19455357 L42.5666964,20.7641964 L40.0875,21.0685714 L37.7691071,2.186875 Z M43.8417857,32.3877679 C43.6790179,32.7999107 43.3016964,33.113125 42.8317857,33.1708036 C42.1479464,33.2552679 41.5238393,32.7669643 41.4399107,32.0833929 L40.3050893,22.8409821 L42.7842857,22.5366071 L43.9191071,31.7790179 C43.9453571,31.9925 43.915625,32.2003571 43.8417857,32.3877679 Z' />
          <path d='M11.01125,1.04732143 C10.5216071,0.986964286 10.0763393,1.33535714 10.01625,1.82473214 L9.20419643,8.43830357 C9.07758929,9.46901786 8.24464286,10.2466071 7.21383929,10.3094643 L6.62196429,10.2367857 L7.68991071,1.53919643 C7.75,1.04982143 7.40196429,0.604285714 6.9125,0.544107143 C6.42294643,0.483482143 5.97758929,0.832142857 5.9175,1.32151786 L4.84955357,10.0191071 L4.25714286,9.94642857 C3.27232143,9.63589286 2.65241071,8.68 2.77892857,7.649375 L3.59098214,1.03589286 C3.65107143,0.546517857 3.30303571,0.100982143 2.81357143,0.0408035714 C2.32419643,-0.0199107143 1.87866071,0.328839286 1.81857143,0.818214286 L1.00651786,7.43169643 C0.803392857,9.08642857 1.64794643,10.6378571 3.07258929,11.3819643 L0.0350892857,30.9299107 C0.0347321429,30.9320536 0.034375,30.934375 0.0340178571,30.9366071 C-0.0892857143,31.7722321 0.124732143,32.6025 0.636607143,33.274375 C1.14848214,33.94625 1.89214286,34.3730357 2.72982143,34.4758929 L2.74607143,34.4778571 C2.87544643,34.49375 3.00455357,34.5016071 3.13267857,34.5016071 C3.83883929,34.5016071 4.51767857,34.2628571 5.07678571,33.8117857 C5.73741071,33.2788393 6.14366071,32.5198214 6.22071429,31.6745536 L8.01607143,11.9891071 C9.57857143,11.611875 10.7735714,10.3108036 10.9766964,8.65598214 L11.78875,2.04241071 C11.84875,1.55303571 11.5007143,1.1075 11.01125,1.04732143 Z M4.4425,31.5125 C4.40964286,31.8722321 4.23678571,32.1951786 3.955625,32.4220536 C3.67446429,32.6489286 3.3225,32.7501786 2.964375,32.705625 L2.948125,32.7036607 C2.59133929,32.6599107 2.27482143,32.4783036 2.05705357,32.1923214 C1.83973214,31.9071429 1.74857143,31.5549107 1.80017857,31.2002679 L4.81276786,11.8138393 L5.51732143,11.9003571 C5.51767857,11.9003571 5.51794643,11.9004464 5.51830357,11.9005357 C5.51857143,11.9005357 5.51883929,11.9005357 5.51910714,11.9005357 L6.223125,11.9869643 L4.4425,31.5125 Z' />
        </g>
      </g>
    </g>
  </svg>
)

export default FoodIcon
