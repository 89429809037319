import React from 'react'
import { Link } from 'react-router-dom'

const BadgeCard = ({ badge }) => (
  <Link className='BadgeCard pointer' to={`/people/badges/${badge.id}`}>
    <img src={badge.imageUrl} />
    <div className='BadgeInformation'>
      <h5>{badge.displayName}</h5>
      <p className='truncate-text-at-2-lines break-word text-secondary text-smallest'>{badge.description}</p>
    </div>
  </Link>
)

export default BadgeCard
