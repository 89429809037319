import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import { useHistory, useLocation } from 'react-router-dom'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.search.search_results.search_results')

const SORT_BY_OPTIONS = [{
  id: 'relevance',
  name: I18N('relevance'),
}, {
  id: 'most_recent',
  name: I18N('most_recent'),
}]

const DEFAULT_SORT_OPTION = 'relevance'

// what pages should have sort by enabled?
const SECTIONS_WITH_SORT_BY_ENABLED = [
  'articles',
  'pages',
]

const SearchResultsSortBy = () => {
  const history = useHistory()
  const { sortBy: querySortBy, filter } = useQueryParams()
  const location = useLocation()

  // Make sortBy fallback to default if no sortBy is set in the url
  // or sortBy is set to a value that is not in the SORT_BY_OPTIONS
  const sortBy = SORT_BY_OPTIONS.map(({ id }) => id).includes(querySortBy)
    ? querySortBy
    : DEFAULT_SORT_OPTION

  const selectedSortByOption = SORT_BY_OPTIONS.find(option => option.id === sortBy)

  const onSortByChange = (newSortByOption) => {
    const queryParams = new URLSearchParams(location.search)

    if (newSortByOption.id !== DEFAULT_SORT_OPTION) {
      queryParams.set('sort_by', newSortByOption.id)
    } else {
      queryParams.delete('sort_by')
    }

    history.push({ search: queryParams.toString() })
  }

  if (!SECTIONS_WITH_SORT_BY_ENABLED.includes(filter)) {
    return null
  }

  return (
    <div className='SearchResultsSortBy mb-4'>
      <div className='d-flex'>
        <label className='Filter-label label m-0 mr-2 p-1 d-flex align-items-center'>{I18N('sort_by')}</label>

        <ReactSelect
          name='sort_by'
          getOptionValue={({ id }) => id}
          getOptionLabel={({ name }) => name}
          options={SORT_BY_OPTIONS}
          value={selectedSortByOption}
          onChange={onSortByChange}
        />
      </div>
    </div>
  )
}

export default SearchResultsSortBy
