import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.qna.events')

const AnswersCount = ({ answersCount, showAnswers, showCursor = true }) => {
  const pointerClass = showCursor ? 'pointer' : ''

  if (showAnswers) {
    return <span className={pointerClass}>{I18N('hide_answers')}</span>
  }

  if (answersCount === 0) return <span className='text-secondary'>{I18N('no_answers')}</span>

  let answerCountText = I18N('num_answers_plural', { numAnswers: answersCount })

  if (answersCount === 1) {
    answerCountText = I18N('num_answers_singular')
  }

  return <span className={pointerClass}>{answerCountText}</span>
}

export default AnswersCount
