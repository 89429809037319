import React from 'react'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const openFullWidthIconPath = '/images/expandActionIcon.svg'
const closeFullWidthIconPath = '/images/collapseActionIcon.svg'

interface TogglePageWidthProps {
  showFullWidth: boolean
  onClick: () => void
}

const TogglePageWidth = ({
  showFullWidth,
  onClick,
}: TogglePageWidthProps) => (
  <span
    className={classNames('TogglePageWidth position-absolute right-0 rounded-lg p-1 d-flex d-print-none', { showFullWidth })}
    onClick={onClick}
  >
    <CdnSvg src={showFullWidth ? closeFullWidthIconPath : openFullWidthIconPath} />
  </span>
)

export default TogglePageWidth
