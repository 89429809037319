import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'react-bootstrap'

import CloseIcon from 'components/icons/closeIcon'
import IcebreakerQuestionsModal from 'components/qna/icebreakerQuestionsModal'
import IceBreakerIcon from 'components/icons/icePickIcon'

const I18N = i18nPath('views.qna.events')

const IcebreakerAddSection = ({ event, onDismiss }) => {
  const [showIcebreakerModal, setShowIcebreakerModal] = useState(false)

  const handleAddIcebreakerButtonClick = (e) => {
    setShowIcebreakerModal(true)
  }

  return (
    <div
      style={{ padding: '2rem' }}
      className='bordered border-dashed mb-3 d-flex align-items-center justify-content-center position-relative'
    >
      <IcebreakerQuestionsModal
        event={event}
        showModal={showIcebreakerModal}
        closeModal={() => setShowIcebreakerModal(false)}
      />
      {onDismiss && (
        <div className='position-absolute d-flex' style={{ top: 0, right: 0 }} onClick={onDismiss}>
          <CloseIcon />
        </div>
      )}
      <div className='d-flex flex-column align-items-center'>
        <IceBreakerIcon className='mb-1' size='3.571rem' />

        <Button className='btn-muted' size='sm' onClick={handleAddIcebreakerButtonClick}>
          {I18N('add_an_icebreaker')}
        </Button>
      </div>
    </div>
  )
}

export default IcebreakerAddSection
