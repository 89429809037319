import React from 'react'
import CdnSvg from 'components/common/cdnSvg'

const templatesEmptyStatePath = '/images/templatesEmptyState.svg'

export const TemplatesSidebarEmptyState = ({ message }) => (
  <div className='TemplatesSidebarEmptyState text-center'>
    <CdnSvg src={templatesEmptyStatePath} />

    <h5 className='mt-3'>{message}</h5>
  </div>
)

export default TemplatesSidebarEmptyState
