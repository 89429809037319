import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice from 'redux/slices/surveys/forms'
import { useParams } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import BackButton from 'components/common/backButton'
import RespondSurveyComponent from 'components/admin/surveys/respondSurveyComponent'

const I18N = i18nPath('views.admin.surveys')

const FormPreviewPage = () => {
  const dispatch = useDispatch()
  const { formId } = useParams()
  const form = useSelector(surveyFormSlice.selectors.getForm(formId))

  const questions = form?.questions || []
  const [answers, setAnswers] = useState({})

  const { isLoading } = useSelector(surveyFormSlice.selectors.getMetaData())

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const currentQuestion = questions[currentQuestionIndex]
  const currentQuestionId = currentQuestion?.id
  const answer = answers[currentQuestionId] || {}

  const updateAnswer = (changes) => {
    const newAnswers = { ...answers, [currentQuestionId]: { ...answer, ...changes } }

    setAnswers(newAnswers)
  }

  useEffect(() => {
    dispatch(surveyFormSlice.asyncActions.admin.fetch(formId, { interpolateVariables: !form?.taskableForm }))
  }, [formId])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  const subject = () => {
    if (form?.taskableForm) {
      return form?.taskTemplateSubject
    }

    if (form?.customNotification) {
      return form?.subject
    }

    return form?.name
  }

  const instructions = () => {
    if (form?.taskableForm) {
      return form?.taskTemplateInstructions
    }

    if (form?.customNotification) {
      return form?.instructions
    }

    return I18N('default_instructions')
  }


  return (
    <div className='FormPreviewPage d-flex flex-column mt-5 mx-5 align-items-center justify-content-center'>
      <div className='preview-container w-100'>
        <BackButton className='mb-3' backToText={I18N('survey')} url={form.adminDisplayPath} />
        <RespondSurveyComponent
          form={form}
          subject={subject()}
          instructions={instructions()}
          currentQuestionIndex={currentQuestionIndex}
          currentAnswer={answer}
          updateAnswer={updateAnswer}
          onClickBack={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
          onClickNext={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
          isSaving={false}
          showResponseRecordedBanner={currentQuestionIndex > questions?.length - 1}
        />
      </div>
    </div>
  )
}

export default FormPreviewPage
