import SurveyAnswerOptionSelectionType from './answerOptionSelection'

export enum AnswerTypeEnum {
  string = 'string',
  number = 'number',
  optionSelection = 'option_selection',
}

export interface SurveyAnswerType {
  type?: AnswerTypeEnum,
  additionalText?: string,
  string?: string,
  number?: number,
  questionId?: string,
  optionSelections?: SurveyAnswerOptionSelectionType[],
  value?: any,
}
