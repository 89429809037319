import React from 'react'

import CalendarEventsAsyncSelect from 'components/common/calendarEventsAsyncSelect'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'

import {
  i18nPath
} from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.calendar_event_templates')

const MicrosoftCalendarEventSection = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled = false,
}) => {
  const onExternalEventChange = (option) => {
    updateWorkingCopy({
      externalEventId: option?.value || '',
      externalEventName: option?.label || '',
      externalCalendarId: option?.externalCalendarId || '',
      externalCalendarEventUrl: option?.externalEventUrl || '',
    })
  }

  return (
    <div className='MicrosoftCalendarEventSection'>
      <div className='external-calendars-grid'>
        <LabeledFormFieldContainer
          label={I18N('microsoft_outlook_event')}
          isRequired
        >
          <CalendarEventsAsyncSelect
            calendarId={workingCopy.externalCalendarId}
            externalEventId={workingCopy.externalEventId}
            externalEventName={workingCopy.externalEventName}
            onChange={onExternalEventChange}
            isDisabled={isDisabled}
            noOptionsMessage={() => I18N('external_event_placeholder')}
            calendarType='microsoft'
          />
        </LabeledFormFieldContainer>
      </div>
    </div>
  )
}

export default MicrosoftCalendarEventSection
