import Slider from 'components/common/slider'
import React from 'react'
import CarouselCard from 'components/carousel_cards/carouselCard'
import classNames from 'classnames'

const CarouselCards = ({
  fullWidth, EmptyState = null, isEditing, carouselCards,
}) => {
  if (_.isEmpty(carouselCards)) {
    return EmptyState
  }

  return (
    <div className={classNames('CarouselCardsWidget h-100', { fullWidth, isEditing })}>
      <Slider
        settings={{
          slidesToShow: 1,
          dots: false,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 1000,
          lazyLoad: 'progressive',
          pauseOnFocus: true,
        }}
      >
        {carouselCards.map(carouselCard => (
          <CarouselCard key={`carousel-card-${carouselCard.id}`} carouselCard={carouselCard} />
        ))}
      </Slider>
    </div>
  )
}

export default CarouselCards
