import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import useQueryParams from 'components/common/hooks/useQueryParams'
import searchSlice from 'redux/slices/search'
import useGetFilterFromUrl from 'pages/search/hooks/useGetFilterFromUrl'

function usePaginatedSearchResults({
  label, type, extraTypes = [], queryType = null,
}) {
  const location = useLocation()
  const history = useHistory()
  const queryParams = useQueryParams()
  const searchResultsFilter = queryParams?.filter || 'all'
  const selected = searchResultsFilter === label
  const { filters } = useGetFilterFromUrl()

  const page = parseInt(queryParams.page) || 1

  const [searchLocation, setSearchLocation] = useState('pageAll')
  const { totalResultsByType, isLoading } = useSelector(searchSlice.selectors.getMetaData(searchLocation))
  const clearySearchResults = useSelector(searchSlice.selectors.getClearySearchResults(searchLocation))

  const results = clearySearchResults.filter(result => result[type] || extraTypes.some(et => result[et]))

  const dispatch = useDispatch()
  const { query, sortBy } = queryParams

  useEffect(() => {
    if (searchResultsFilter === 'all') {
      setSearchLocation('pageAll')

      return
    }


    if (searchResultsFilter !== label) return

    setSearchLocation('pageFiltered')

    // searchLocation refers to the component that is doing the search, it can either be 'dropdown', 'pageAll', or 'pageFiltered'
    // searchOrigin is used for Amplitude tracking, and it reflects what the user was doing that triggered the search
    // it can be: 'dropdown', 'results_page', or 'golink_not_found'
    const params = {
      query,
      type: queryType || type,
      searchGoogleDrive: false,
      searchLocation: 'pageFiltered',
      searchOrigin: 'results_page',
      page,
      filters,
    }

    if (sortBy) {
      params.sortBy = sortBy
    }

    dispatch(searchSlice.asyncActions.fetchResults(params))
  }, [page, searchResultsFilter, query, filters, sortBy])

  const updatePage = (page) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', page)
    history.push({
      search: queryParams.toString(),
    })
  }

  return {
    results,
    count: totalResultsByType[type],
    page,
    setPage: updatePage,
    isLoading,
    selected,
  }
}

export default usePaginatedSearchResults
