import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import AddWidgetSidebar from 'components/admin/layout/layout_collection_sidebar/addWidgetSidebar'
import widgetsMap from 'utils/layout/widgetsMap'

export const ADD_WIDGET_SIDEBAR = 'addWidgetSidebar'
export const WIDGET_SETTINGS_SIDEBAR = 'widgetSettingsSidebar'


const LayoutCollectionSidebar = ({
  currentlyOpenSidebarName = '',
  closeSidebar,
  setSelectedNewWidget,
  widget,
  updateWidget,
}) => (
  <div className='LayoutCollectionSidebar'>
    <div className='position-relative py-4 px-3'>
      <div
        className='position-absolute top-0 right-0 mt-4 mr-3 pointer'
        onClick={closeSidebar}
      >
        <CdnSvg className='LayoutCollectionSidebarCloseIcon' src='/images/xIcon.svg' />
      </div>
      {currentlyOpenSidebarName === ADD_WIDGET_SIDEBAR && (
        <AddWidgetSidebar setSelectedNewWidget={setSelectedNewWidget} />
      )}
      {currentlyOpenSidebarName === WIDGET_SETTINGS_SIDEBAR && widget && (
        <WidgetSettingsSidebar widget={widget} updateWidget={updateWidget} />
      )}
    </div>
  </div>
)

const WidgetSettingsSidebar = ({ widget, updateWidget }) => {
  const SidebarComponent = widgetsMap[widget.type].SettingsSidebarComponent

  return (
    <SidebarComponent widget={widget} updateWidget={updateWidget} />
  )
}

export default LayoutCollectionSidebar
