import React from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const rightCornerArrowPath = '/images/rightCornerArrowIcon.svg'
const MAX_PAGES_TO_DISPLAY = 3

const PageBreadcrumbs = ({ workspace, page }) => {
  // returning an empty span instead of null since it's used in a flexbox and we don't want the flexbox to be jumpy
  if (!workspace || !page.displayPath || !page.ancestors) return <span />

  const ancestorsToShow = page.ancestors.length >= MAX_PAGES_TO_DISPLAY ? page.ancestors.slice(-1) : page.ancestors
  const ancestorsInPopover = page.ancestors.length >= MAX_PAGES_TO_DISPLAY ? page.ancestors.slice(0, -1) : []

  const BreadcrumbsPopover = (
    <Popover id='breadcrumb-popover' className='BreadcrumbsPopover py-2 px-1'>
      <Popover.Content>
        <div className='d-flex flex-column'>
          {ancestorsInPopover.map((ancestor, index) => (
            <div className='d-flex flex-row' key={ancestor.id}>
              {index !== 0 && <CdnSvg src={rightCornerArrowPath} className='mx-2' />}
              <Link key={ancestor.displayPath} to={ancestor.displayPath} className='font-weight-400 text-secondary'>
                {ancestor.title}
              </Link>
            </div>
          ))}
        </div>
      </Popover.Content>
    </Popover>
  )

  return (
    <div className='PageBreadcrumbs'>
      <p className='text-secondary text-small mb-0 ml-2 mt-1'>
        {workspace.title} /
        {ancestorsInPopover.length > 0 && (
          <OverlayTrigger trigger='click' placement='bottom-start' overlay={BreadcrumbsPopover} rootClose>
            <span className='MorePages pointer mx-1'>... / </span>
          </OverlayTrigger>
        )}
        {ancestorsToShow.map(ancestor => <BreadcrumbLink key={ancestor.id} page={ancestor} isCurrentPage={false} />)}
        <BreadcrumbLink page={page} isCurrentPage />
      </p>
    </div>
  )
}

const BreadcrumbLink = ({ page, isCurrentPage = false }) => (
  <span>
    <Link
      to={page.displayPath}
      className={classNames('ml-1 text-small font-weight-400', { 'text-secondary': !isCurrentPage })}
    >
      {page.title}
    </Link>
    {isCurrentPage ? '' : ' / '}
  </span>
)

export default PageBreadcrumbs
