import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import profileSlice from 'redux/slices/profiles'

import LoadingSpinner from 'components/common/circlesLoadingIndicator'
import Card from 'components/common/card'
import CalendarEventsList from 'components/common/calendar/eventsList'

const I18N = i18nPath('views.profile.schedule')

const Schedule = ({ isScheduleEnabled }) => {
  const dispatch = useDispatch()

  const { username } = useParams()

  const { isFetchingEvents } = useSelector(profileSlice.selectors.getMetaData())
  const events = useSelector(profileSlice.selectors.getEvents())

  useEffect(() => {
    if (isScheduleEnabled) {
      dispatch(profileSlice.asyncActions.fetchEvents(username))
    }

    return () => dispatch(profileSlice.actions.clearEvents())
  }, [])

  if (!isScheduleEnabled) return null

  return (
    <section className='ProfileSchedule'>
      <Card className='Card--scrollable'>
        <h6 className='mb-3'>{I18N('schedule')}</h6>

        <div>{isFetchingEvents ? <LoadingSpinner /> : <CalendarEventsList events={events} emptyCalendarText={I18N('calendar_unavailable')} />}</div>
      </Card>
    </section>
  )
}

export default Schedule
