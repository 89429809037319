import React from 'react'

import IntegrationCard from 'components/admin/integrations/integrationCard'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.integrations_page.integration_card.one_drive')

interface Props {
  isInstalled: boolean
  afterUninstalled: () => void
}

const OneDriveIntegrationCard = ({ isInstalled, afterUninstalled }: Props) => {
  const [onUninstall, { isLoading: isUninstalling }] = useApi(API.userIntegrations.uninstallOneDriveIntegration, {
    onSuccess: afterUninstalled,
  })

  return (
    <IntegrationCard
      id='one_drive'
      logo={<CdnSvg src='/images/integrations/oneDriveLogo.svg' />}
      isInstalled={isInstalled}
      docLink='https://gocleary.zendesk.com/hc/en-us/articles/20763908846491-OneDrive-SharePoint'
      docTitle={I18N('doc_title')}
      installationComponent={(
        <a className='btn btn-primary btn-sm btn-narrow' href='/auth/microsoft_azure_app/one_drive_user_consent_authorize_url'>
          {I18NCommon('authorize')}
        </a>
      )}
      onUninstall={onUninstall}
      isUninstalling={isUninstalling}
    />
  )
}

export default OneDriveIntegrationCard
