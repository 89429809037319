import React from 'react'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import CheckIcon from 'components/icons/checkIcon'
import InfoTooltip from 'components/common/infoTooltip'
import Tooltip from 'components/common/clearyTooltip'

const I18N = i18nPath('views.journeys.introduction_detail')

const IntroductionInvitationSelector = ({
  introduction,
  invitation,
  onUpdateInvitation,
  onRemoveInvitation,
  currentIndex,
}) => {
  const {
    completed,
    permittedActions,
    companyEmailPresentForScheduling: forUserHasCompanyEmail,
    calendarEventsScheduledAutomatically,
  } = introduction

  const {
    id: invitationId,
    invitee: selectedEmployee,
    calendarEventStartDate,
    sentAt,
    status,
    errorReason,
  } = invitation

  const getErrorMessage = () => {
    if (!forUserHasCompanyEmail) {
      return I18N('invitation_error.no_company_email_tooltip')
    }

    if (status !== 'invitation_error') {
      return null
    }

    if (calendarEventsScheduledAutomatically) {
      return I18N(`invitation_error.${errorReason || 'error_creating_event'}`)
    } else {
      return I18N('invitation_error.tooltip_no_calendar', { inviteePreferredName })
    }
  }


  const inviteePreferredName = selectedEmployee?.preferredFullName
  const isDisabled = completed || invitationId || !permittedActions?.complete
  const showErrorMessage = !forUserHasCompanyEmail || status === 'invitation_error'
  const errorMessage = getErrorMessage()
  const eventDate = i18nMoment(calendarEventStartDate).format('ll hh:mm A')
  const notificationDate = i18nMoment(sentAt).format('ll hh:mm A')
  const sentMessage = calendarEventsScheduledAutomatically ? I18N('sent_tooltip', { calendarEventStartDate: eventDate }) : I18N('sent_no_calendar_invite_tooltip', { inviteePreferredName, notificationDate })

  const onSelectedColleagueChange = (colleague) => {
    if (colleague) {
      onUpdateInvitation(currentIndex, colleague)
    } else {
      onRemoveInvitation(currentIndex)
    }
  }

  return (
    <div className='IntroductionInvitationSelector d-flex align-items-center mb-2'>
      <EmployeeSearch
        onChange={onSelectedColleagueChange}
        selectedEmployee={selectedEmployee}
        isDisabled={isDisabled}
        className='w-100'
      />
      {status === 'sent' && !showErrorMessage && (
        <Tooltip
          placement='bottom'
          content={<CheckIcon className='CheckIcon ml-2' />}
        >
          <span className='ml-1'>{sentMessage}</span>
        </Tooltip>
      )}
      {showErrorMessage && (
        <InfoTooltip
          text={errorMessage}
        />
      )}
    </div>
  )
}

export default IntroductionInvitationSelector
