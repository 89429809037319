import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice from 'redux/slices/surveys/forms'
import { showToastMessage } from 'redux/slices/toasts'
import { Button } from 'components/common/buttons'
import type { SimpleUserType } from 'types/user'

const I18N = i18nPath('views.admin.surveys.responses.list')

interface SendFormNotificationButtonProps {
  user?: SimpleUserType | null
  sendToAllNotResponded?: boolean
  formId: string
}

const SendFormNotificationButton = ({
  user = null,
  sendToAllNotResponded = false,
  formId,
} : SendFormNotificationButtonProps) => {
  const dispatch = useDispatch()
  const [notificationSent, setNotificationSent] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const handleSendNotification = () => {
    const onSuccess = () => {
      setIsSending(false)
      setNotificationSent(true)
    }

    const onError = (e) => {
      setIsSending(false)

      const message = e.response?.data?.error?.message || I18N('error_sending_notification')
      dispatch(showToastMessage({ message, type: 'error' }))
    }

    setIsSending(true)

    if (sendToAllNotResponded) {
      dispatch(surveyFormSlice.asyncActions.admin.resendNotifications(formId, onSuccess, onError))
    } else {
      dispatch(surveyFormSlice.asyncActions.admin.sendNotification(formId, user?.id, onSuccess, onError))
    }
  }

  if (sendToAllNotResponded) {
    return (
      <Button
        variant='secondary'
        onClick={handleSendNotification}
        showLoadingSpinner={isSending}
        disabled={isSending}
      >
        {I18N('send_reminders')}
      </Button>
    )
  }

  if (isSending) {
    return <span>...</span>
  }

  if (notificationSent) {
    return <span>{I18N('notification_sent')}</span>
  }

  return (
    <a
      className='link-color text-primary'
      onClick={handleSendNotification}
    >
      {I18N('send_notification')}
    </a>
  )
}

export default SendFormNotificationButton
