import React, { useMemo } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import TrashIcon from 'components/icons/trashIcon'
import buildAncestryTree from 'utils/buildAncestryTree'
import classNames from 'classnames'
import { AudienceRuleType, OperatorEnum, TypeEnum } from 'types/audience/targetingRules'
import { present } from 'components/common/utils'
import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import EmployeeSearch from 'components/form_fields/employeeSearch'

const thinPlusIconPath = '/images/thinPlusIcon.svg'

const I18N = i18nPath('views.admin.includes.audience_selector')

interface OptionType {
  key: string
  label: string
  value: string
}

interface RuleSelectProps {
  rule: AudienceRuleType,
  onRemoveRule: () => void,
  updateRule: (newRule: AudienceRuleType) => void
  isDisabled?: boolean,
  targetingOptions?: any,
  hasChangesOperators?: boolean
  onAddRule?: () => void,
}

const getOperatorOptions = (ruleType, hasChangesOperators = false) => {
  if (ruleType === TypeEnum.Reports) {
    return [
      { value: OperatorEnum.Into, label: I18N('into') },
      { value: OperatorEnum.DirectlyTo, label: I18N('directly_to') },
    ]
  }

  if (ruleType === TypeEnum.Tenure) {
    return [
      { value: OperatorEnum.GreaterThan, label: I18N('greater_than') },
      { value: OperatorEnum.LessThan, label: I18N('less_than') },
    ]
  }

  return [
    { value: OperatorEnum.Is, label: I18N('is') },
    { value: OperatorEnum.Not, label: I18N('not') },
    hasChangesOperators && { value: OperatorEnum.ChangesFrom, label: I18N('changes_from') },
    hasChangesOperators && { value: OperatorEnum.ChangesTo, label: I18N('changes_to') },
  ].filter(present)
}

const RuleSelect = ({
  rule,
  onRemoveRule,
  updateRule,
  isDisabled = false,
  targetingOptions,
  hasChangesOperators = false,
  onAddRule,
}: RuleSelectProps) => {
  const selectedRule = targetingOptions?.find(setting => setting.key === rule?.key && setting.type === rule?.type)

  let valueOptions = selectedRule?.options || []
  const groupsTree = rule?.type === TypeEnum.GroupType ? buildAncestryTree(valueOptions) : null
  const selectedValues = rule?.values?.map((val) => {
    let value = valueOptions.find((option: OptionType) => option.value === val)

    if (!value) {
      value = { value: val, label: val }
      valueOptions = [...valueOptions, value]
    }

    return value
  }).filter(present) || []

  const operator = rule?.operator
  const operatorOptions = getOperatorOptions(rule?.type, hasChangesOperators)

  const handleRuleKeyChange = (newField: AudienceRuleType) => {
    const operator = getOperatorOptions(newField?.type)[0]?.value

    updateRule({
      key: newField?.key,
      values: [],
      type: newField?.type,
      operator,
    })
  }

  const handleValueChange = (selectedOptions: OptionType[]) => {
    const newValues = selectedOptions.map(option => option?.value)

    // if we are adding groupTypes we must add all descendants of the added group
    // if adding anything else we just update the values
    if (rule?.type !== TypeEnum.GroupType) {
      updateRule({ values: newValues })

      return
    }

    // find out if we just added or removed a group
    const newlyAddedGroupId = _.difference(newValues, rule.values!, 'value').pop()
    const newlyRemovedGroupId = _.difference(rule.values, newValues).pop()

    if (newlyRemovedGroupId) {
      updateRule({ values: newValues })
    } else if (newlyAddedGroupId) {
      const selectedGroup = groupsTree!.find(group => group.id === newlyAddedGroupId)
      updateRule({ values: _.uniq([...newValues, ...selectedGroup.subtreeIds]) })
    }
  }

  const handleOperatorChange = (newOperator) => {
    updateRule({ operator: newOperator?.value })
  }

  const showEmployeeSelect = rule.type === TypeEnum.Reports
  const showTenureSelect = rule.type === TypeEnum.Tenure
  const showSelect = !showEmployeeSelect && !showTenureSelect

  return (
    <div className='RuleSelect d-flex align-items-start my-3'>
      <ReactSelect
        className='RuleKeySelect mb-2'
        getOptionValue={option => option.key}
        options={targetingOptions?.map(option => _.pick(option, ['key', 'label', 'type']))}
        value={selectedRule && _.pick(selectedRule, ['key', 'label', 'type'])}
        onChange={handleRuleKeyChange}
        components={{ IndicatorSeparator: () => null }}
        placeholder={I18N('rule_key_placeholder')}
        isDisabled={isDisabled}
        menuPlacement='auto'
      />

      <ReactSelect
        className='OperatorSelect mx-1 mb-2'
        options={operatorOptions}
        value={operatorOptions.find(op => op.value === operator)}
        onChange={handleOperatorChange}
        components={{ IndicatorSeparator: () => null }}
        placeholder={I18N('operator_placeholder')}
        isDisabled={isDisabled}
        menuPlacement='auto'
      />

      <div className='d-flex align-items-start flex-grow-1'>
        {showEmployeeSelect && (
          <EmployeeSearch
            className='FieldValuesSelect mr-1 flex-grow-1'
            selectedEmployees={rule.values}
            isMulti
            onChange={employees => handleValueChange(employees.map(e => ({ value: e })))}
          />
        )}
        {showSelect && (
          <ReactSelect
            className='FieldValuesSelect mr-1 flex-grow-1'
            options={valueOptions}
            value={selectedValues}
            onChange={handleValueChange}
            isMulti
            components={{ IndicatorSeparator: () => null }}
            placeholder={I18N('values_placeholder')}
            isDisabled={isDisabled}
            menuPlacement='auto'
          />
        )}
        {showTenureSelect && (
          <div className='FieldValuesSelect mr-1 flex-grow-1 d-flex align-items-center gap-2'>
            <input
              className='flex-grow-1'
              type='number'
              value={rule.values?.[0]}
              onChange={({ target: { value } }) => updateRule({ values: value ? [parseInt(value)] : [] })}
            />
            {}
            <span>{I18N('days')}</span>
          </div>
        )}

        <div className={classNames('DeleteRuleButton py-2 px-1', isDisabled ? 'disabled' : 'pointer')} onClick={isDisabled ? () => {} : onRemoveRule}>
          <TrashIcon className='TrashIcon mr-1' />
        </div>

        {onAddRule && (
          <Button onClick={onAddRule} variant='link' dataTestId='cy_add_rule' className='px-1 AddMoreButton'>
            <CdnSvg className='PlusIcon' src={thinPlusIconPath} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default RuleSelect
