import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import adminGroupSlice from 'redux/slices/admin/groups'

import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import { Button } from 'components/common/buttons'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.group_list')

const TABS = {
  Active: 'active',
  Inactive: 'inactive',
}


const GroupListPage = () => {
  const dispatch = useDispatch()
  const { groupDisplayName, pluralGroupDisplayName } = useCurrentCompany()
  const { selectedTab = 'active' } = useQueryParams()

  const columns = [
    {
      header: I18N('table_header.name'),
      col: 'col-name',
      id: 'name',
      style: { width: '30%' },
      accessor: d => <Link to={`/admin/teams/${d.id}`}>{d.name}</Link>,
    },
    {
      header: I18N('table_header.parent'),
      col: 'col-parent',
      id: 'ancestry',
      style: { width: '30%' },
      accessor: d => (d.parent ? d.parent.name : ''),
    },
    {
      header: I18N('table_header.group_type', { groupDisplayName }),
      col: 'col-group-type',
      id: 'group_type_id',
      style: { width: '20%' },
      accessor: d => (d.groupType ? d.groupType.name : ''),
    },
    {
      header: I18N('table_header.active'),
      col: 'col-active',
      id: 'active',
      style: { width: '15%' },
      accessor: d => (d.active ? I18N('is_true') : I18N('is_false')),
    },
    {
      header: I18N('table_header.classification'),
      col: 'col-classification',
      id: 'classification',
      style: { width: '15%' },
      accessor: d => (d.classification ? I18N('is_true') : I18N('is_false')),
    },
    {
      header: I18N('table_header.created_at'),
      col: 'col-created_at',
      id: 'created_at',
      style: { width: '25%' },
      accessor: d => i18nMoment(d.createdAt).format('LLL'),
    },
  ]

  const { queryParams, isLoading, isNotFound } = useSelector(adminGroupSlice.selectors.getMetaData())
  const groups = useSelector(adminGroupSlice.selectors.getGroups())

  const [nameSearchInput, setNameSearchInput] = useState('')

  const q = nameSearchInput.length >= 3 ? nameSearchInput : undefined

  useEffect(() => {
    dispatch(adminGroupSlice.asyncActions.fetchGroups({
      ...queryParams, filter: selectedTab, q, page: 1,
    }))
  }, [selectedTab, q])

  const handlePaginationClick = (newParams) => {
    const params = {
      ...queryParams, filter: selectedTab, q, ...newParams,
    }
    dispatch(adminGroupSlice.asyncActions.fetchGroups(params))
  }

  const handleSortChange = (sortParams) => {
    const params = {
      ...queryParams, filter: selectedTab, q, ...sortParams,
    }
    dispatch(adminGroupSlice.asyncActions.fetchGroups(params))
  }

  const {
    page, totalPages, total, perPage,
  } = queryParams

  return (
    <div className='GroupListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{pluralGroupDisplayName}</h3>
        <div className='AdminButtonContainer'>
          <Link className='mr-2' to='/admin/team_types'>
            <Button>
              {I18N('view_group_types', { groupDisplayName })}
            </Button>
          </Link>
          <Link to='/admin/teams/new'>
            <Button>
              {I18N('create_new_group', { groupDisplayName })}
            </Button>
          </Link>
        </div>
      </header>

      <main className='AdminContent'>
        <HistoryChangingPillTabSelector
          pillClasses='text-normal'
          className='mb-3'
          tabs={[
            {
              id: TABS.Active,
              text: I18N('active'),
            },
            {
              id: TABS.Inactive,
              text: I18N('Inactive'),
            },
          ]}
        />

        <input
          type='text'
          data-testid='groupSearch'
          placeholder={I18N('search_by_group_name', { groupDisplayName })}
          className='my-4'
          onChange={ev => setNameSearchInput(ev.target.value)}
          value={nameSearchInput}
        />

        <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
          <SmartTable
            data={groups}
            sortKey='created_at'
            className='white-bg-table'
            sortedAsc={false}
            showPagination={page !== null && totalPages !== null}
            columns={columns}
            page={page}
            pages={totalPages}
            totalCount={total}
            perPage={perPage}
            onPaginationClick={handlePaginationClick}
            onSortChange={handleSortChange}
          />
        </TableLoadingContainer>

      </main>
    </div>
  )
}

export default GroupListPage
