import React, { useEffect, useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import { useDispatch, useSelector } from 'react-redux'
import userSlice from 'redux/slices/users'

const I18N = i18nPath('views.admin.includes.audience_selector')

const AudienceModal = ({
  userIds,
  showAudienceModal,
  closeModal,
  summary = I18N('sub_summary'),
  title = I18N('audience_summary'),
}) => {
  const dispatch = useDispatch()

  const [query, setQuery] = useState('')
  const users = useSelector(userSlice.selectors.getSimpleUsersByIds(userIds))
  const filteredUsers = query === '' ? users : users.filter(user => user.preferredFullName.toLowerCase().includes(query.toLowerCase()))

  useEffect(() => {
    const missingUserIds = _.difference(userIds, users.map(u => u.id))

    if (!_.isEmpty(missingUserIds)) {
      dispatch(userSlice.asyncActions.simpleFetchAll(missingUserIds))
    }
  }, [JSON.stringify(userIds)])

  return (
    <Modal
      visible={showAudienceModal}
      toggle={closeModal}
      className='PeopleModal'
      title={title}
      modalTitleClassName='h1'
    >
      {summary && (
        <p>{summary}</p>
      )}
      <input
        type='text'
        placeholder={I18N('search_placeholder')}
        value={query}
        onChange={e => setQuery(e.target.value)}
        className='w-100 mb-3'
      />
      <div className='overflow-auto' style={{ height: '550px' }}>
        {filteredUsers.map(user => (
          <EmployeeMiniCard employee={user} key={user.id} className='bordered my-2' />
        ))}
      </div>
    </Modal>
  )
}

export default AudienceModal
