import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'


interface EnabledInputValue {
  enabled: boolean
}

interface EnabledInputProps {
  value: EnabledInputValue
  setValue: (value: EnabledInputValue) => void
  name: string
}

const EnabledInput = ({ value, setValue, name }: EnabledInputProps) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ enabled: e.target.checked })
  }

  return (
    <div className='mt-1'>
      <div className='form-check mr-3'>
        <input
          id={`${name}-enabled`}
          type='checkbox'
          checked={!!value.enabled}
          onChange={onChangeHandler}
        />
        <label htmlFor={`${name}-enabled`} className='font-weight-normal'>
          {I18NCommon('enabled')}
        </label>
      </div>
    </div>
  )
}

export default EnabledInput
