import React from 'react'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import MultipleEmployeesName from 'components/common/multipleEmployeesName'
import RichTextView from 'components/common/richTextView'
import Truncate from 'react-show-more-text/lib/Truncate'

const I18N = i18nPath('views.ai_assistant.knowledge_ingestion.top_three_faqs')

const TopThreeFAQs = ({ knowledgeIngestionId }) => {
  const { data, isLoading, isLoaded } = useFetch(
    API.ai.assistant.knowledgeIngestions.fetchTopThreeFAQs,
    [knowledgeIngestionId]
  )

  if (!isLoaded || isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (!data || data?.length < 3) {
    return null
  }

  const title = data?.some(faq => faq.repeatedQuestionsCount === 0) ? 'featured_faqs' : 'top_three_faqs'

  return (
    <div>
      <h1 className='text-xl bolder mb-3'>{I18N(title)}</h1>

      <div className='d-flex gap-3'>
        {data?.map(faq => (
          <div
            key={faq.id}
            className='d-flex col flex-column justify-content-between border-radius bg-white card-shadow p-4'
          >
            <div className='TopFAQ-wrapper'>
              <Truncate lines={3}>
                <h2 className='text-large bold'>{faq.question}</h2>
              </Truncate>
              <div className='mb-1' />
              <Truncate lines={7}>
                <RichTextView record={faq} richTextKey='answer' recordType='pagesFAQ' className='text-secondary' />
              </Truncate>
            </div>
            <div>
              <hr />
              <div className='d-flex gap-1'>
                <p className='text-small text-secondary m-0'>
                  {I18N('generated_by')}
                  <MultipleEmployeesName employees={faq.faqSources.flatMap(source => source.user)} />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TopThreeFAQs

