import React, {
  useState,
  useRef,
  useEffect,
  useMemo
} from 'react'
import { Popover } from 'reactstrap'
import hasHover from 'utils/hasHover'
import { randomReactKey } from 'components/common/utils'

const ShoutoutCompanyValuePopover = ({
  companyValue, shoutoutId, className, children, timeout,
}) => {
  const [isMousedOver, setIsMousedOver] = useState(false)

  const hoverTimer = useRef()

  const handleMouseOver = () => {
    if (!hasHover) return

    hoverTimer.current = setTimeout(() => {
      setIsMousedOver(true)
    }, timeout)
  }

  const handleMouseOut = () => {
    if (!hasHover) return

    setIsMousedOver(false)
    clearTimeout(hoverTimer.current)
  }

  useEffect(() => () => {
    clearTimeout(hoverTimer.current)
  }, [])

  const handleOnClick = () => {
    setIsMousedOver(false)
  }

  // Adding a random react key because if there are multiple recognition widgets (highly unlikely), the targetId will be the same for all of them
  const targetId = useMemo(() => `shoutout-${shoutoutId}-company-value-tag-${companyValue.id}-${randomReactKey()}`, [])

  return (
    <span
      onClick={handleOnClick}
      className={className}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      id={targetId}
    >
      {children}
      <Popover placement='bottom' isOpen={isMousedOver} target={targetId} className='Shoutout-companyValueDescription'>
        {companyValue.description}
      </Popover>
    </span>
  )
}

export default ShoutoutCompanyValuePopover
