import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import settingSlice from 'redux/slices/settings'

const I18N = i18nPath('views.settings.grouped_checkboxes')

const SettingsGroupedCheckboxes = ({ name, options, allowUnmarkAll = true }) => {
  const dispatch = useDispatch()
  const setting = useSelector(settingSlice.selectors.getSetting(name))


  const onChange = (option) => {
    const newValue = { ...setting.value }
    newValue[option] = !newValue[option]

    if (allowUnmarkAll || _.values(newValue).includes(true)) {
      dispatch(settingSlice.asyncActions.update(name, newValue))
    }
  }

  return (
    <div className='SettingsGroupedCheckboxes d-md-flex'>
      {options.map((option) => {
        const id = `${name}.${option}`

        return (
          <div key={id} className='d-flex align-items-center mr-md-5'>
            <input
              id={id}
              type='checkbox'
              checked={_.get(setting, `value.${option}`)}
              name={name}
              onChange={() => onChange(option)}
            />
            <label className='ml-2 mb-0' htmlFor={id}>{I18N(id)}</label>
          </div>
        )
      })}
    </div>
  )
}

export default SettingsGroupedCheckboxes
