import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'

const TabSelector = ({
  tabs,
  baseUrl,
  translationsPath,
  keyPrefix,
  centerTabs = false,
  className,
  navLinkClassName = 'px-4',
  showTabs = true,
  children,
}) => {
  const I18N = i18nPath(translationsPath)
  const centeredTabs = centerTabs ? 'd-flex justify-content-center' : ''

  const location = useLocation()
  const { search } = location

  return (
    <div className={classNames('TabSelector', className)} style={{ minHeight: '300px' }}>
      {showTabs && (
        <ul className={`nav nav-tabs list-unstyled mb-3 ${centeredTabs}`}>
          {tabs.map(({
            linkPath, displayName, linkName, tabClassname = '', additionalContent,
          }) => {
            let linkText = translationsPath ? I18N(`${keyPrefix}${linkPath}`) : linkName
            if (displayName) {
              linkText = displayName
            }

            return (
              <li key={linkPath || linkName} className={classNames('nav-item', tabClassname)}>
                <NavLink
                  className={classNames('nav-link d-lg-bl', navLinkClassName)}
                  activeClassName='active border-bottom-highlight text-color-highlight'
                  to={{ pathname: `${baseUrl}${linkPath}`, search }}
                >
                  {linkText}
                  {additionalContent}
                </NavLink>
              </li>
            )
          })}
        </ul>
      )}
      <div className='TabSelector--content'>{children}</div>
    </div>
  )
}

export default TabSelector
