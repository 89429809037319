import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import EventTemplateList from 'components/qna/event_creation_steps/eventTemplateList'

const TemplateSelector = ({ event, setEvent, setEventFormAsActiveStep }) => {
  const I18N = i18nPath('views.qna.events.template_list')

  const handleOnTemplateClick = (template) => {
    if (!template) {
      setEvent({
        ...event,
        template_name: 'all_hands_meeting',
      })
      setEventFormAsActiveStep()
      return
    }

    setEvent({
      ...event,
      title: I18N(template.title),
      ...template.customSettings,
      template_name: template.id,
    })
    setEventFormAsActiveStep()
  }

  return (
    <div className='EventTemplateSelector container'>
      <div className='row'>
        <div className='col'>
          <h2>{I18N('header')}</h2>
          <div className='mt-5'>
            <div className='text-large mb-4'>{I18N('list_header')}</div>
            <EventTemplateList onTemplateClick={handleOnTemplateClick} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateSelector
