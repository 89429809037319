import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import API from 'services/api'

import Tag from 'components/common/tag'
import ArticleContent from 'components/articles/articleContent'
import ArticleByLine from 'components/articles/articleByLine'
import ArticleType from 'components/articles/articleType'
import ArticleDropdownMenu from 'components/articles/articleDropdownMenu'
import ArticleSocial from 'components/articles/articleSocial'
import Card from 'components/common/card'

import isEmpty from 'lodash/isEmpty'
import AcknowledgementBanner from 'components/articles/acknowledgementBanner'
import AcknowledgementSection from 'components/articles/acknowledgementSection'
import CdnSvg from 'components/common/cdnSvg'
import { I18NCommon } from 'utils/i18nHelpers'
import useQueryParams from 'components/common/hooks/useQueryParams'
import DangerousHTML from 'components/common/dangerousHTML'
import { encloseInSearchHighlights } from 'components/common/utils'
import useScrollToElement from 'components/common/hooks/useScrollToElement'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const Article = ({
  article,
  tracking = true,
  onTagClick = undefined,
  onTogglePreview = undefined,
  isPreviewing = false,
}) => {
  const { searchHighlights } = useQueryParams()
  const currentUser = useCurrentUser()

  let trackingComplete = false
  let userActionId = null

  const finishTracking = () => {
    if (tracking) {
      if (trackingComplete) {
        return
      }
      API.articles.trackArticleViewEnd(article, userActionId).then(() => {
        trackingComplete = true
      })
    }
  }

  const startTracking = () => {
    if (tracking) {
      API.articles.trackArticleViewStart(article, window.location.pathname).then((response) => {
        userActionId = response.data.userActionId

        window.addEventListener('beforeunload', finishTracking)
      })
    }
  }

  useScrollToElement('.searchHighlight')

  const {
    title,
    tags,
    articleType,
    acknowledgementInfo,
  } = article

  useEffect(() => {
    startTracking()

    return function cleanup() {
      window.removeEventListener('beforeunload', finishTracking)
      finishTracking()
    }
  }, [])

  article.content = encloseInSearchHighlights(article.content || article.cardContent, searchHighlights)

  return (
    <Card className='mb-4' cardBodyClassName='pb-0'>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
      {acknowledgementInfo?.status && (
        <AcknowledgementBanner article={article} />
      )}
      <div className='position-relative mb-2'>
        {isPreviewing ? (
          <span>{articleType && <ArticleType articleType={articleType} className='text-large' />}</span>
        ) : (
          <Link className='Article-typeLink' to={'/news/' + articleType?.nameParam}>
            {articleType && <ArticleType articleType={articleType} className='text-large' />}
          </Link>
        )}

        {onTogglePreview ? (
          <div className='position-absolute top-0 right-0 d-flex align-items-center pointer' onClick={onTogglePreview}>
            <CdnSvg className='PreviewIcon' src='/images/pencilIcon.svg' />
            <span className='ml-1 link-color text-small'>
              {I18NCommon('edit')}
            </span>
          </div>
        ) : !isPreviewing && (
          <ArticleDropdownMenu article={article} />
        )}
      </div>

      <h1 className='mb-0'>
        <DangerousHTML options={{ ALLOWED_TAGS: ['span'] }}>
          {encloseInSearchHighlights(title, searchHighlights)}
        </DangerousHTML>
      </h1>

      <div className='mt-2 mb-3'>
        <ArticleByLine article={article} />
      </div>

      <ArticleContent article={article} />

      {acknowledgementInfo?.status && (
        <AcknowledgementSection
          article={article}
        />
      )}

      {!isEmpty(tags) && (
        <div className='position-relative mt-3 d-flex align-items-start' style={{ minHeight: '1rem' }}>
          <div className='Articles__TagList text-left'>
            {article.tags.map(tag => (
              <Tag text={tag.name} key={tag.id} onClick={() => onTagClick(tag)} />
            ))}
          </div>
        </div>
      )}

      <ArticleSocial disableActions={isPreviewing} article={article} className='pb-3' />
    </Card>
  )
}

export default Article
