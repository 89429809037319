import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import permissionSlice from 'redux/slices/permissions'
import { Link, useParams } from 'react-router-dom'
import SmartTable from 'components/common/tables/smartTable'
import BackButton from 'components/common/backButton'

const I18N = i18nPath('views.admin.permissions')

const UserWithPermissionListPage = (props) => {
  const dispatch = useDispatch()

  const { permissionName } = useParams()

  const { isLoading, isNotFound, queryParams } = useSelector(permissionSlice.selectors.getMetaData)

  const users = useSelector(permissionSlice.selectors.getUsersByPermission(permissionName))

  useEffect(() => {
    dispatch(permissionSlice.asyncActions.admin.fetchUsers({ permission: permissionName }))
  }, [])

  const columns = [
    {
      header: I18N('table_header.name'),
      col: 'col-name',
      id: 'first_name',
      accessor: d => d.preferredFullName,
    },
    {
      header: I18N('table_header.actions'),
      col: 'col-actions',
      accessor: d => <Link data-testid={`cy-${d.firstName.toLowerCase()}-edit`} to={`/admin/users/${d.id}?selectedTab=user_permissions`}>{I18N('edit')}</Link>,
    },
  ]

  const handlePaginationClick = (sortParams) => {
    const filter = { permission: permissionName, ...sortParams }
    dispatch(permissionSlice.asyncActions.admin.fetchUsers(filter))
  }

  const handleSortChange = (sortParams) => {
    const filter = { permission: permissionName, ...sortParams }
    dispatch(permissionSlice.asyncActions.admin.fetchUsers(filter))
  }

  const handleNameSearch = (ev) => {
    const { keyCode } = ev
    const { value } = ev.target

    // Ignore enter key
    if (keyCode === 13) return

    // Prevent triggering the search if there are less than 3 chars
    // But if there is no text at all we need to bring all results
    if (value.length < 3 && value.length !== 0) return

    const filter = { permission: permissionName, q: value, page: 1 }
    dispatch(permissionSlice.asyncActions.admin.fetchUsers(filter))
  }

  const renderTable = () => {
    const {
      page, totalPages, total, perPage,
    } = queryParams

    return (
      <SmartTable
        data={users}
        sortKey='created_at'
        sortedAsc={false}
        showPagination={page !== null && totalPages !== null}
        columns={columns}
        page={page}
        pages={totalPages}
        totalCount={total}
        perPage={perPage}
        onPaginationClick={handlePaginationClick}
        onSortChange={handleSortChange}
      />
    )
  }

  return (
    <div className='container'>
      <div className='row mt-4'>
        <div className='col-6'>
          <BackButton className='mb-4' />
          <h2>{I18N(permissionName)}</h2>
        </div>
      </div>

      <div className='row mb-4'>
        <div className='col'>
          <input
            data-testid='cy-search-user'
            type='text'
            placeholder={I18N('search_placeholder')}
            className='mb-4'
            onKeyUp={handleNameSearch}
          />
          <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
            {renderTable()}
          </TableLoadingContainer>
        </div>
      </div>
    </div>
  )
}

export default UserWithPermissionListPage
