import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.groups.go_link_form')

const GoLinkForm = ({
  workingCopy = {},
  onSubmit,
  onChange,
  onCancel,
  isSaving,
  isSaveDisabled,
  isNameInputDisabled,
  error,
}) => {
  const handleChange = field => (e) => {
    let value = e.target.value
    if (field === 'name') {
      value = value.trim()
    }
    onChange(field, value)
  }

  const onNameFieldBlur = () => {
    if (!workingCopy.displayName) {
      onChange('displayName', workingCopy.name)
    }
  }

  return (
    <form className='GoLinkForm mt-4' onSubmit={onSubmit}>
      <div className='form-group mb-4'>
        <label htmlFor='name' className='required-form-label'>{I18N('name')}</label>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text'>go/</span>
          </div>
          <input
            type='text'
            name='name'
            value={workingCopy.name}
            onChange={handleChange('name')}
            className='form-control'
            onBlur={onNameFieldBlur}
            disabled={isNameInputDisabled}
          />
        </div>
      </div>

      <div className='form-group mb-4'>
        <label htmlFor='displayName'>{I18N('display_name')}</label>
        <input
          type='text'
          name='displayName'
          value={workingCopy.displayName}
          onChange={handleChange('displayName')}
        />
      </div>

      <div className='form-group mb-4'>
        <label htmlFor='url' className='required-form-label'>{I18N('url')}</label>
        <input
          type='url'
          name='url'
          placeholder={I18N('url_placeholder')}
          value={workingCopy.url}
          onChange={handleChange('url')}
        />
      </div>

      <div className='form-group mb-4'>
        <label htmlFor='description'>{I18N('description')}</label>
        <input
          type='text'
          name='description'
          value={workingCopy.description || ''}
          onChange={handleChange('description')}
        />
      </div>

      <div className='d-flex justify-content-end align-items-center'>
        <CancelButton onClick={onCancel} />
        <ButtonNarrow type='submit' disabled={isSaveDisabled} showLoadingSpinner={isSaving}>
          {I18N('save')}
        </ButtonNarrow>
      </div>

      {error && <div className='p-3 mt-3 alert-danger'>{error?.message}</div>}
    </form>
  )
}


export default GoLinkForm
