import React, { useRef, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import FormSelectField from 'components/form_fields/formSelectField'
import CdnSvg from 'components/common/cdnSvg'
import LoadingSpinnerText from 'components/common/loadingSpinnerText'
import Card from 'components/common/card'
import EffectPlayer from 'components/celebrations/effectPlayer'
import useClickOutside from 'components/common/hooks/useClickOutside'
import RoundedAnimatedButton from 'components/common/buttons/RoundedAnimatedButton'

const I18N = i18nPath('views.celebrations.effect_picker')

const OPTIONS = [
  { value: null, label: I18N('no_effect') },
  { value: 'ballon', label: I18N('ballon') },
  { value: 'confetti_2', label: I18N('confetti_2') },
  { value: 'confetti', label: I18N('confetti') },
  { value: 'fireworks', label: I18N('fireworks') },
  { value: 'flowers', label: I18N('flowers') },
  { value: 'stars', label: I18N('stars') },
  { value: 'thank_you', label: I18N('thank_you') },
  { value: 'trophy', label: I18N('trophy') },
]

const EffectPickerForm = React.forwardRef<any, any>(({ effect, setEffect: setEffectProp }, ref) => {
  const [showEffect, setShowEffect] = useState(false)

  const onShowEffectClick = async (e) => {
    setShowEffect(true)
  }

  const setEffect = (effect) => {
    setEffectProp(effect)
    setShowEffect(!!effect)
  }

  return (
    <Card className='EffectPickerForm mb-2' cardRef={ref}>
      {showEffect && (
        <EffectPlayer effect={effect} onComplete={() => setShowEffect(false)} />
      )}

      <div className='mb-2'>
        {I18N('select_effect')}
      </div>

      <div className='d-flex'>
        <div className='flex-grow-1'>
          <FormSelectField
            currentValue={OPTIONS.find(opt => opt.value === effect) as any}
            options={OPTIONS}
            onChange={opt => setEffect(opt?.value)}
            noOptionsMessage={() => I18N('select_effect')}
            placeholder={I18N('select_effect')}
            isClearable={false}
            menuPlacement='top'
          />
        </div>
        <div>
          <button className='PlayEffectButton ml-2' onClick={onShowEffectClick} disabled={!effect}>
            <LoadingSpinnerText className='SpinnerIcon' showLoadingSpinner={showEffect}>
              <CdnSvg src='/images/playCircleIcon.svg' />
            </LoadingSpinnerText>
          </button>
        </div>
      </div>
    </Card>
  )
})

const EffectPicker = ({
  effect,
  setEffect,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false)
  const formRef = useRef(null)

  useClickOutside({
    ref: formRef,
    onClickOutside: () => setIsFormVisible(false),
    enableClickOutside: isFormVisible,
  })

  const onShowFormClick = () => setIsFormVisible(true)

  return (
    <div className='PickEffectButtonPositioner'>
      <div className='position-relative'>
        <RoundedAnimatedButton
          onClick={onShowFormClick}
          className='DisplayEffectFormButton'
          variant='primary'
          roundedSize={60}
          text={I18N('effects')}
          icon={<CdnSvg src='/images/celebrationIcon2.svg' />}
          initialOpenStateTimeout={5000}
        />

        {isFormVisible && (
          <EffectPickerForm effect={effect} setEffect={setEffect} ref={formRef} />
        )}
      </div>

    </div>
  )
}

export default EffectPicker
