import React, { useState } from 'react'

import API from 'services/api'

import Modal from 'components/common/modal'
import { Button } from 'components/common/buttons'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const LegalNoticesModal = () => {
  const currentUser = useCurrentUser()
  const { settings, legalNoticeHeader, legalNoticeMessage } = useCurrentCompany()
  const [showModal, setShowModal] = useState(!currentUser.legalNoticesAcknowledgedAt)

  const agreeToLegalNotices = () => {
    API.home.acceptLegalNotices().then(() => {
      setShowModal(false)
    })
  }

  if (settings.legalNotices.nuxModal.enabled) {
    return (
      <Modal visible={showModal} toggle={() => null}>
        <h3>{legalNoticeHeader}</h3>
        <p>{legalNoticeMessage}</p>
        <Button className='d-block mx-auto' onClick={agreeToLegalNotices}>
          {I18n.t('views.home.legal_notice_button_text')}
        </Button>
      </Modal>
    )
  }

  return null
}

export default LegalNoticesModal
