import { createSlice } from '@reduxjs/toolkit'
import { checkForError } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { NodeDataType, trimOrgChartForUser } from 'utils/orgChart'
import build from 'redux-object'
import API from 'services/api'
import appSignal from 'services/appSignal'
import entitySlice from 'redux/slices/entities'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'

export type OrgChartState = {
  orgChart: NodeDataType[],
  trimmedOrgChart: (NodeDataType | null)[],
  previewUserId: string,
  meta: DefaultMetaType & {
    isLoadingUsers: boolean,
    isLoadingUserPreview: boolean,
  },
}

export const initialState: OrgChartState = {
  orgChart: [],
  trimmedOrgChart: [],
  previewUserId: '0',
  meta: {
    ...defaultMeta,
    isLoadingUsers: false,
    isLoadingUserPreview: false,
  },
}

const orgChartSlice = createSlice({
  name: 'orgChart',
  initialState,
  reducers: {
    ...defaultActions,
    setOrgChart: (state, action) => {
      state.orgChart = action.payload
    },
    trimOrgChartForUser: (state, action) => {
      if (!_.isEmpty(state.orgChart)) {
        state.trimmedOrgChart = [trimOrgChartForUser(state.orgChart[0], action.payload)]
      }
    },
    isLoadingUsers: (state, action) => {
      state.meta.isLoadingUsers = action.payload
    },
    isLoadingUserPreview: (state, action) => {
      state.meta.isLoadingUserPreview = action.payload
    },
    setPreviewUserId: (state, action) => {
      state.previewUserId = action.payload
    },
    resetPreviewUserId: (state, action) => {
      state.previewUserId = '0'
    },
  },
})

const asyncActions = {
  fetchOrgChart: (onSuccess = () => null) => async (dispatch) => {
    dispatch(orgChartSlice.actions.isLoading(true))

    try {
      const response = await API.orgChart.getOrgChart()

      dispatch(orgChartSlice.actions.setOrgChart(response.data.orgChart))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(orgChartSlice.actions.setError(error))
    } finally {
      dispatch(orgChartSlice.actions.isLoading(false))
    }
  },
  fetchUsersForOrgChart: username => async (dispatch) => {
    dispatch(orgChartSlice.actions.isLoadingUsers(true))

    try {
      const response = await API.orgChart.getOrgChartUsers(username)

      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(orgChartSlice.actions.setError(error))
    } finally {
      dispatch(orgChartSlice.actions.isLoadingUsers(false))
    }
  },
  fetchUserForProfilePreview: username => async (dispatch) => {
    dispatch(orgChartSlice.actions.isLoadingUserPreview(true))

    try {
      const response = await API.orgChart.getProfilePreviewForUser(username)
      const previewUserId = response.data.data.id

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(orgChartSlice.actions.setPreviewUserId(previewUserId))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(orgChartSlice.actions.setError(error))
    } finally {
      dispatch(orgChartSlice.actions.isLoadingUserPreview(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.orgChart.meta,
  getOrgChart: () => (state: ReduxState) => state.orgChart.orgChart,
  getOrgChartForUser: () => (state: ReduxState) => state.orgChart.trimmedOrgChart,
  getProfilePreviewUser: () => (state: ReduxState) => build(state.entities, 'user', state.orgChart.previewUserId),
}

export default {
  ...orgChartSlice,
  selectors,
  asyncActions,
}
