import React, { useState } from 'react'

import { Button, ButtonSecondary } from 'components/common/buttons'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import Modal from 'components/common/modal'
import CdnSvg from 'components/common/cdnSvg'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { Creatable } from 'components/common/react_select'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('components.admin.send_preview_sms')

const SendPreviewSmsModal = ({
  visible,
  closeModal,
  onSend,
  isSending,
}) => {
  const currentUser = useCurrentUser()
  const isClearyAdmin = currentUser.permissions.clearyAdmin

  const [selectedEmployees, setSelectedEmployees] = useState([currentUser])
  const [isSelectingPhoneNumbers, setIsSelectingPhoneNumbers] = useState(false)
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([])

  const sendPreviewSms = () => {
    const userIds = selectedEmployees.map(user => user.id)
    onSend(userIds, closeModal)
  }

  const sendPreviewSmsToPhoneNumbers = () => {
    const phoneNumbers = selectedPhoneNumbers
    onSend(phoneNumbers, closeModal)
  }

  return (
    <Modal visible={visible} toggle={closeModal} className='SendPreviewSmsModal'>
      <div className='d-flex flex-column align-items-center'>
        <CdnSvg src='/images/mobilePhoneIcon.svg' className='MobilePhoneIcon mb-3' />
        <h5>{I18N('title')}</h5>
        <p className='text-secondary'>{I18N('send_to_users')}</p>
        {isSelectingPhoneNumbers ? (
          <>
            <Creatable
              className='w-75'
              value={selectedPhoneNumbers.map(phone => ({ label: phone, value: phone }))}
              onChange={options => setSelectedPhoneNumbers(options.map(option => option.value))}
              isMulti
              isClearable
              placeholder={I18N('create_placeholder')}
            />
            <div className='text-small link-color pointer mb-3' onClick={() => setIsSelectingPhoneNumbers(false)}>
              {I18N('select_users')}
            </div>
          </>
        ) : (
          <>
            <EmployeeSearch
              className={isClearyAdmin ? 'w-75 mb-0' : 'w-75 mb-3'}
              onChange={setSelectedEmployees}
              isMulti
              selectedEmployees={selectedEmployees}
            />

            {isClearyAdmin && (
              <div className='text-small link-color pointer mb-3' onClick={() => setIsSelectingPhoneNumbers(true)}>
                {I18N('select_phone_numbers')}
              </div>
            )}
          </>
        )}

        <Button
          onClick={isSelectingPhoneNumbers ? sendPreviewSmsToPhoneNumbers : sendPreviewSms}
          disabled={isSending}
          showLoadingSpinner={isSending}
        >
          {I18N('send')}
        </Button>
      </div>
    </Modal>
  )
}

interface SendPreviewSmsButtonAndModalProps {
  isSending: boolean
  onSend: (userIds: string[], onSuccess: () => void) => void
  className?: string
  buttonSecondary?: boolean
  buttonLabel?: string
}

const SendPreviewSmsButtonAndModal = ({
  isSending,
  onSend,
  className,
  buttonSecondary = false,
  buttonLabel = I18N('send_preview'),
} : SendPreviewSmsButtonAndModalProps) => {
  const [showPreviewSmsModal, setShowPreviewSmsModal] = useState(false)

  const ButtonComponent = buttonSecondary ? ButtonSecondary : Button

  return (
    <>
      <ButtonComponent className={className} onClick={() => setShowPreviewSmsModal(!showPreviewSmsModal)}>
        {buttonLabel}
      </ButtonComponent>
      <SendPreviewSmsModal
        visible={showPreviewSmsModal}
        closeModal={() => setShowPreviewSmsModal(false)}
        onSend={onSend}
        isSending={isSending}
      />
    </>
  )
}

export default SendPreviewSmsButtonAndModal
