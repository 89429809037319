import React from 'react'
import { useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import GlobeIcon from 'components/icons/globeIcon'
import ToggleGroup from 'components/common/toggleGroup'
import JourneysEstimatedUsers from 'components/admin/journeys/audience/journeysEstimatedUsers'
import AudienceRulesSelector from 'components/common/audience/audienceRulesSelector'
import { hasChangeOperator } from 'utils/audience'
import { MANUALLY, WHEN_TARGETED, WHEN_TARGETING_CHANGES } from 'utils/journeys/journeyBlueprintHowUserAreAdded'
import audienceSlice from 'redux/slices/audiences'
import CdnSvg from 'components/common/cdnSvg'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.audience_selector')

const EmployeeAudienceSelector = ({
  workingCopy,
  updateWorkingCopy,
  journeyBlueprint,
  hasEstimatedUsersStartingJourney = true,
}) => {
  const targetingOptions = useTargetingOptions()

  const journeyBlueprintType = journeyBlueprint?.type
  const isOnboardingJourney = journeyBlueprintType === 'onboarding'
  const isOffboardingJourney = journeyBlueprintType === 'offboarding'
  const isCustomJourney = journeyBlueprintType === 'custom'
  const areUsersAddedManually = workingCopy.howUsersAreAdded === MANUALLY
  const showDaysAfterMilestoneSelector = !areUsersAddedManually && (isOnboardingJourney || isOffboardingJourney)
  const automaticallyAddUsersDaysAfterMilestone = workingCopy.automaticallyAddUsersDaysAfterMilestone

  const targetingRules = workingCopy?.targetingRules

  const handleTargetingRulesChange = (newTargetingRules) => {
    updateWorkingCopy({
      howUsersAreAdded: hasChangeOperator(newTargetingRules) ? WHEN_TARGETING_CHANGES : WHEN_TARGETED,
      targetingRules: newTargetingRules,
    })
  }

  const onToggleChange = ({ targetEntireCompany }) => {
    const params = {
      targetingRules: {
        targetEntireCompany,
        rules: [],
        users: [],
      },
      howUsersAreAdded: WHEN_TARGETED,
    }

    updateWorkingCopy(params)
  }

  const toggles = [
    {
      key: 'entire_company',
      value: { areUsersAddedManually: false, targetEntireCompany: true },
      icon: <GlobeIcon />,
      title: I18N(`${journeyBlueprintType}.all_employees`),
    },
    {
      key: 'specific_groups',
      value: { areUsersAddedManually: false, targetEntireCompany: false },
      icon: <CdnSvg src='/images/personsIcon.svg' />,
      title: I18N(`${journeyBlueprintType}.employees_criteria`),
      description: I18N(`${journeyBlueprintType}.employees_criteria_guidance`),
    },
  ].filter(Boolean)

  return (
    <div className='EmployeeAudienceSelector'>
      <ToggleGroup
        onChange={onToggleChange}
        selectedValue={{ targetEntireCompany: targetingRules?.targetEntireCompany, areUsersAddedManually }}
        toggles={toggles}
      />
      {!areUsersAddedManually && !targetingRules?.targetEntireCompany && (
        <AudienceRulesSelector
          targetingRules={targetingRules}
          className='my-4'
          onChange={handleTargetingRulesChange}
          targetingOptions={targetingOptions}
          canTargetSpecificUsers={false}
          hasChangesOperators={isCustomJourney}
        />
      )}
      {showDaysAfterMilestoneSelector && (
        <div className='mt-1 mb-2'>
          <div className='text-secondary text-small mb-1'>
            {I18N(isOnboardingJourney ? 'onboarding.only_add_new_hires_until' : 'offboarding.only_add_employees_leaving_until')}
          </div>
          <div className='d-md-flex align-items-center'>
            <input
              className='NumberInput mr-md-2 mb-md-0 mb-sm-1'
              name='number-input'
              type='number'
              value={automaticallyAddUsersDaysAfterMilestone}
              onChange={({ target }) => updateWorkingCopy({
                automaticallyAddUsersDaysAfterMilestone: (parseInt(target.value, 10)),
              })}
            />
            <span className='mr-md-2 mb-md-0 mb-sm-1'>
              {I18N(isOnboardingJourney ? 'onboarding.days_after_their_start_date' : 'offboarding.days_left_until_their_end_date')}
            </span>
          </div>
        </div>
      )}
      {
        hasEstimatedUsersStartingJourney && workingCopy.howUsersAreAdded === WHEN_TARGETED && (
          <JourneysEstimatedUsers
            journeyBlueprintType={journeyBlueprintType}
            targetingRules={workingCopy.targetingRules}
            automaticallyAddUsersDaysAfterMilestone={automaticallyAddUsersDaysAfterMilestone}
            usersText={I18N('will_automatically_start_this_journey')}
            noUsersText={I18N(`${journeyBlueprintType}.no_employees_starting_journey`)}
          />
        )
      }
    </div>
  )
}

export default EmployeeAudienceSelector
