import React from 'react'
import EventPage from 'pages/qna/eventPage'
import ModeratorViewNavbar from 'components/qna/moderatorViewNavbar'
import UnauthorizedEvent from 'components/qna/unauthorizedEvent'

const EventParticipantView = ({
  event,
  isLoading,
  isUnauthorized,
  showModeratorNavbar,
  eventId,
}) => (
  <>
    {!isLoading && (
      <ModeratorViewNavbar
        isModerator={showModeratorNavbar}
        view='participant'
        toggleViewURL={`/events/${event.slug}`}
      />
    )}
    {isUnauthorized ? <UnauthorizedEvent eventId={eventId} /> : (
      <EventPage
        isLoading={isLoading}
        event={event}
        canManageEvent={false}
        isPreview={showModeratorNavbar}
      />
    )}
  </>
)

export default EventParticipantView
