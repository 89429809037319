import React from 'react'
import Gif from 'components/common/tenor/gif'

const ShoutoutGif = ({
  shoutout,
}) => {
  if (shoutout.gifUrl) { return <Gif gifUrl={shoutout.gifUrl} className='mt-3' /> }

  return null
}

export default ShoutoutGif
