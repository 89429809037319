/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import { LoadingContainer, TableLoadingContainer } from 'components/common/loadingContainer'

import BackButton from 'components/common/backButton'
import adminArticleSlice from 'redux/slices/admin/articles'
import articleStatsSlice, { EmailClick } from 'redux/slices/articleStats'
import { articlePath } from 'utils/routeHelpers'
import CdnSvg from 'components/common/cdnSvg'
import TruncatedContent from 'components/common/truncatedContent'
import SmartTable from 'components/common/tables/smartTable'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import { safePercentage } from 'pages/admin/articles/stats/helpers'
import DetailUserActionsModal from 'pages/admin/articles/emails/detailUserActionsModal'

const I18N = i18nPath('views.admin.article_stats')

const ArticleStatsEmailClicksPage = ({ isGroupPost = false }) => {
  const dispatch = useDispatch()
  const [sort, setSort] = useState({ sortBy: 'totalClicksCount', sortDir: 'desc' })
  const [selectedUser, setSelectedUser] = useState(null)
  const { isLoading, isNotFound } = useSelector(adminArticleSlice.selectors.getMetaData())
  const { isLoadingEmailClicks } = useSelector(articleStatsSlice.selectors.getMetaData())
  const emailClicks = useSelector(articleStatsSlice.selectors.getEmailClicks())
  const article = useSelector(adminArticleSlice.selectors.getArticle())
  const { articleId } = useParams()

  const total = emailClicks.map(c => c.totalClicksCount).reduce((a, b) => a + b, 0)

  useEffect(() => {
    dispatch(adminArticleSlice.asyncActions.fetchArticle(articleId))
    dispatch(articleStatsSlice.asyncActions.fetchEmailClicks(articleId))
    return () => {
      dispatch(adminArticleSlice.actions.clearArticleId())
    }
  }, [])


  const sortedEmailClicks = useMemo(() => {
    const result = _.sortBy(emailClicks, sort.sortBy)
    return sort.sortDir === 'asc' ? result : result.reverse()
  }, [emailClicks, sort])

  const articleRoute = articlePath(article)

  const columns = [{
    header: I18N('url'),
    accessor: (d: EmailClick) => (
      <a href={d.url} target='_blank' rel='noopener noreferrer'>
        <TruncatedContent>{d.url}</TruncatedContent>
      </a>
    ),
  }, {
    header: I18N('total'),
    id: 'totalClicksCount',
    accessor: (d: EmailClick) => (
      <span>
        {d.totalClicksCount}
        <span className='text-secondary d-inline-block ml-1'>({safePercentage(d.totalClicksCount, total)})</span>
      </span>
    ),
  }, {
    header: I18N('unique'),
    id: 'uniqueClicksCount',
    accessor: (d: EmailClick) => (
      d.uniqueClicksCount
    ),
  }, {
    header: I18N('who_clicked'),
    accessor: (d: EmailClick) => (
      <RoundedAvatarsWithAsyncUserFetching
        userIds={d.userIds}
        onUserClick={setSelectedUser}
      />
    ),
  }]

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='ArticleStatsEmailClicksPage'>
        <header className='AdminHeader pt-4 pb-4'>
          <div className='width-container'>

            <BackButton
              backToText={I18N('article_stats')}
              url={`${isGroupPost ? '/posts/' : '/admin/articles/'}${article?.id}/stats`}
              className='link-color bold mb-4'
            />

            <div className='d-flex justify-content-between'>
              <h4 className='mb-0'>
                {I18N('article_stats_email_clicks_header', { articleTitle: article?.title })}
                <Link to={articleRoute} target='_blank'>
                  <Button variant='link'>
                    <CdnSvg src='/images/externalLinkIcon.svg' />
                  </Button>
                </Link>
              </h4>
            </div>
          </div>
        </header>

        <main className='AdminContent'>
          <div className='width-container'>
            <TableLoadingContainer isLoading={isLoadingEmailClicks}>
              <SmartTable
                className='ArticleEmailsTable white-bg-table'
                data={sortedEmailClicks}
                columns={columns}
                onSortChange={setSort}
                sortKey={sort.sortBy}
                sortedAsc={sort.sortDir === 'asc'}
              />
            </TableLoadingContainer>

            {selectedUser && (
              <DetailUserActionsModal
                user={selectedUser}
                onClose={() => setSelectedUser(null)}
                articleId={articleId}
              />
            )}
          </div>
        </main>
      </div>
    </LoadingContainer>
  )
}

export default ArticleStatsEmailClicksPage
