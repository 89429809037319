import React from 'react'

import classNames from 'classnames'

import RichTextView from 'components/common/richTextView'
import QuestionByLine from 'components/qna/questionByLine'
import IcebreakerLabel from 'components/qna/icebreakerLabel'

const QuestionContent = (props) => {
  const { question } = props
  const isIcebreaker = question.type === 'icebreaker'

  return (
    <div>
      {isIcebreaker && (
        <span className='mr-2'>
          <IcebreakerLabel />
        </span>
      )}

      <QuestionByLine
        question={question}
        className={classNames('text-small text-secondary mb-2', { 'd-inline-block': isIcebreaker })}
      />

      <RichTextView
        record={question}
        recordType='question'
        richTextKey='content'
        className='QuestionContent break-word overflow-x-auto overflow-y-hidden p-1'
      />
    </div>
  )
}

export default QuestionContent
