import API from 'services/api'

const Session = {
  loginWithPassword(email, password, loginErrorCallback) {
    const searchParams = new URLSearchParams(window.location.search)
    const redirectUrlSearchParam = searchParams.get('redirect_url')
    const redirectUrl = redirectUrlSearchParam && encodeURIComponent(redirectUrlSearchParam)

    return API.auth
      .loginWithPassword(email, password, redirectUrl)
      .then((response) => {
        window.location.assign(response.data.redirectUrl)
      })
      .catch((e) => {
        loginErrorCallback(e)
      })
  },

  logout() {
    API.auth
      .logout()
      .then((response) => {
        // directs you to the logout form, which will log you out of google (if logged in)
        // this is necessary because the google JS can only be loaded from
        // the login subdomain (login.gocleary.com, login.staging1.clearytesting.com, login.localhost:3000, etc)
        window.location.assign(response.data.redirectUrl)
      })
      .catch(() => {
        // handle logout error
      })
  },
}

export default Session
