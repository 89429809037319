import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import CloseIcon from 'components/icons/closeIcon'
import TemplateList from 'components/pages/templateList'
import pageTemplateCategorySlice from 'redux/slices/pageTemplateCategories'

import { i18nPath } from 'utils/i18nHelpers'
import { SelectedTemplate } from 'redux/slices/pageTemplates'

const I18N = i18nPath('views.pages')

interface TemplatesNavProps {
  selectedTemplate: SelectedTemplate
  setSelectedTemplate: (template: SelectedTemplate) => void
  onClose?: () => void
  modalView?: boolean
}

const TemplatesNav = ({
  selectedTemplate,
  setSelectedTemplate,
  onClose = () => {},
  modalView = false,
}: TemplatesNavProps) => {
  const pageTemplateCategories = useSelector(pageTemplateCategorySlice.selectors.getPageTemplateCategories())

  return (
    <div className={
      classNames(
        'TemplatesNav position-relative right-0 flex-grow-0 flex-shrink-0 d-flex flex-column overflow-y-auto h-100',
        { modalView }
      )
    }
    >
      <div className='d-flex flex-row justify-content-between'>
        <h5 className='pt-4 pb-2 mb-0 mx-0 px-4 flex-grow-0 flex-shrink-0 font-weight-500'>
          {I18N('template_title')}
        </h5>
        <span className='top-0 right-0' onClick={onClose}>
          <CloseIcon />
        </span>
      </div>
      {pageTemplateCategories.map((category, i) => (
        <TemplateList
          key={i}
          category={category}
          firstInList={!i}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      ))}
    </div>
  )
}

export default TemplatesNav
