import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import groupSlice from 'redux/slices/groups'
import groupTypeSlice from 'redux/slices/groupTypes'
import ReactSelect from 'components/common/react_select'

const I18N = i18nPath('views.groups.edit_followers_modal')

interface GroupSelectFieldProps {
  onChange: (any) => void
  className?: string
  placeholder?: string
  value: any
}

const GroupSelectField = ({
  className = 'GroupSelectField w-100',
  onChange,
  placeholder = I18N('search_for_teams_to_add'),
  value,
}: GroupSelectFieldProps) => {
  const dispatch = useDispatch()
  const groupTypes = useSelector(groupTypeSlice.selectors.getTeamTypes())
  const groups = useSelector(groupSlice.selectors.getTeams())

  const getOptionLabel = (option) => {
    const groupType = groupTypes.filter(groupType => groupType.id === option.groupTypeId)[0]
    return `${groupType?.name} - ${option.name}`
  }

  useEffect(() => {
    dispatch(groupTypeSlice.asyncActions.fetchAll())
    dispatch(groupSlice.asyncActions.fetchAll())
  }, [])

  return (
    <ReactSelect
      className={className}
      getOptionValue={option => option.id}
      getOptionLabel={getOptionLabel}
      options={groups}
      value={value}
      onChange={onChange}
      components={{ IndicatorSeparator: () => null }}
      placeholder={placeholder}
      isDisabled={false}
      isMulti
      menuPlacement='auto'
    />
  )
}

export default GroupSelectField
