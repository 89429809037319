import React from 'react'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import { AiMessageWrapper } from 'components/ai/chat/ask_ai_modal/aiMessage'


type Props = {
  message?: string
}

const GeneratingAiMessage = ({ message }: Props) => (
  <AiMessageWrapper>
    <div className='px-4'>
      {message ? (
        <AiAnswerContent content={message} />
      ) : (
        <CirclesLoadingIndicator />
      )}
    </div>
  </AiMessageWrapper>
)

export default GeneratingAiMessage
