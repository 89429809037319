import React from 'react'
import { useHistory } from 'react-router-dom'

import { isEmpty } from 'lodash'

import useQueryParams from 'components/common/hooks/useQueryParams'
import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import NotionFilter from 'components/search/filters/refined_filters/notionFilter'
import TeamTypeFilter from './teamTypeFilter'
import ArticleFilter from './articleFilter'
import PeopleFilter from './peopleFilter'
import EventFilter from './eventFilter'
import PageFilter from './pageFilter'
import GoogleDriveFilter from './googleDriveFilter'
import ConfluenceFilter from './confluenceFilter'
import BloomfireFilter from './bloomfireFilter'
import OneDriveFilter from './oneDriveFilter'

const I18N = i18nPath('views.search.search_results.filters')

const RefinedFilters = () => {
  const history = useHistory()
  const { query, filter } = useQueryParams()
  const searchResultsFilter = filter || 'all'

  const isActiveTab = tab => searchResultsFilter === tab

  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany

  const handleUpdateHistory = (filters) => {
    const encodedFilters = encodeURIComponent(JSON.stringify(filters))
    if (!isEmpty(filters)) {
      history.push(`/search?filter=${searchResultsFilter}&query=${query}&filters=${encodedFilters}`)
      return
    }

    history.push(`/search?filter=${searchResultsFilter}&query=${query}`)
  }

  // This approach to rendering filters
  // is the only one that works to display one filter
  // at a time and also allows to add filters incrementally.
  const filters = [
    settings.groups.enabled && isActiveTab('groups') && (
      <TeamTypeFilter updateHistory={handleUpdateHistory} key='team-type-filter' />
    ),
    settings.news.enabled && isActiveTab('articles') && (
      <ArticleFilter updateHistory={handleUpdateHistory} key='article-filter' />
    ),
    isActiveTab('people') && (
      <PeopleFilter updateHistory={handleUpdateHistory} key='people-filter' />
    ),
    settings.pages.enabled && isActiveTab('pages') && (
      <PageFilter updateHistory={handleUpdateHistory} key='page-filter' />
    ),
    settings.qna.enabled && isActiveTab('qna') && (
      <EventFilter updateHistory={handleUpdateHistory} key='event-filter' />
    ),
    settings.googleDriveSearch.enabled && isActiveTab('google_drive') && (
      <GoogleDriveFilter updateHistory={handleUpdateHistory} key='google-drive-filter' />
    ),
    settings.oneDrive.searchEnabled && isActiveTab('one_drive') && (
      <OneDriveFilter updateHistory={handleUpdateHistory} key='one-drive-filter' />
    ),
    settings.confluenceSearch.enabled && isActiveTab('confluence') && (
      <ConfluenceFilter updateHistory={handleUpdateHistory} key='confluence-filter' />
    ),
    settings.bloomfireSearch.enabled && isActiveTab('bloomfire') && (
      <BloomfireFilter updateHistory={handleUpdateHistory} key='bloomfire-filter' />
    ),
    settings.notionSearch.enabled && isActiveTab('notion') && (
      <NotionFilter updateHistory={handleUpdateHistory} key='notion-filter' />
    ),
  ].filter(Boolean)

  if (isEmpty(filters)) return null

  // Notion or Bloomfire filters are empty, they are only for triggering the search internally
  if (isActiveTab('notion') || isActiveTab('bloomfire')) {
    return filters
  }

  return (
    <>
      <hr className='m-4' />
      <div className='SearchRefinedFilters mt-4'>
        <div className='text-small text-dark-muted px-3 mb-3'>{I18N('refine_with_filters')}</div>
        <div>{filters}</div>
      </div>
    </>
  )
}

export default RefinedFilters
