import { HocuspocusProvider } from '@hocuspocus/provider'
import { useEffect, useState } from 'react'

interface UseCollaborativeStateParams {
  provider?: HocuspocusProvider
  param: string
  value?: any
  setValueState?: any
  mapName?: string
}

const useCollaborativeState = ({
  provider,
  value,
  setValueState,
  param,
  mapName = '_collaborativeState',
}: UseCollaborativeStateParams) => {
  useEffect(() => {
    if (!provider) { return () => {} }

    const collaborativeStateYMap = provider.document.getMap(mapName)

    const observer = () => {
      const newValue = collaborativeStateYMap.get(param)

      if (newValue === undefined) { return }

      setValueState(newValue)
    }

    collaborativeStateYMap.observe(observer)

    return () => { collaborativeStateYMap.unobserve(observer) }
  }, [provider, setValueState])


  const setValue = (newValue) => {
    if (value === newValue) { return }

    setValueState(newValue)

    if (!provider) { return }

    const collaborativeStateYMap = provider.document.getMap(mapName)

    collaborativeStateYMap.set(param, newValue)
  }

  return {
    setValue,
  }
}

export default useCollaborativeState
