import React from 'react'

const MegaphoneIcon = () => (
  <svg className='MegaphoneIcon' xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 100 100' fill='none'>
    <rect width='100' height='100' rx='50' fill='white' />
    <path
      d='M72.3817 75.6266C77.5484 72.3766 79.1734 63.9183 80.09 55.7516C81.0067 47.5433 81.2567 39.71 77.6734 34.5433C74.0901 29.3766 66.6734 26.96 61.0484 28.8766C55.3817 30.835 51.5067 37.1266 45.1734 38.6266C38.8401 40.085 30.0484 36.7516 25.2984 38.7516C20.5901 40.71 19.9651 48.085 21.0901 55.2516C22.1734 62.4183 25.0067 69.4183 30.1317 72.6683C35.2151 75.8766 42.6317 75.335 50.6317 76.085C58.6317 76.835 67.2567 78.835 72.3817 75.6266Z'
      fill='#E5F0FE'
    />
    <path
      d='M59.2244 21.0421C59.0369 20.5273 58.6157 20.1385 58.0972 20.0043C57.5795 19.87 57.0276 20.0033 56.6245 20.3653L21.4866 51.808L15.5076 52.0709C14.9863 52.0941 14.5075 52.3718 14.2189 52.818C13.9303 53.2643 13.8681 53.8262 14.0494 54.328L19.8552 70.3294C20.0374 70.8312 20.4423 71.2135 20.9446 71.357C21.0889 71.3987 21.2359 71.4181 21.3829 71.4181C21.7463 71.4181 22.1043 71.2931 22.3956 71.0561L27.0868 67.2391L30.2485 67.2521L33.5148 76.2544C34.0433 77.7126 35.0948 78.8717 36.4737 79.5188C37.2267 79.8724 38.0275 80.0493 38.831 80.0493C39.4992 80.0493 40.1693 79.9261 40.815 79.6799L56.2927 73.7594C57.7139 73.216 58.843 72.1365 59.4733 70.721C59.9468 69.6554 60.0947 68.4945 59.9134 67.3641L73.6993 67.4168H73.7219C74.6237 67.4168 75.3532 66.6669 75.3532 65.743C75.3532 65.4514 75.2811 65.1764 75.1521 64.9376L59.2244 21.0421ZM22.1286 67.003L17.8884 55.3167L21.0131 55.1797L22.7969 60.0948L24.5807 65.0098L22.1286 67.003ZM56.5082 69.3304C56.2394 69.9331 55.7597 70.3914 55.1546 70.6229L39.676 76.5433C39.0736 76.7738 38.4171 76.7507 37.83 76.4748C37.2438 76.1998 36.7965 75.7073 36.5711 75.087L33.734 67.2659L56.5425 67.353L56.5731 67.4372C56.7986 68.0556 56.776 68.7296 56.5082 69.3304ZM27.6558 63.8951L24.0486 53.9558L51.3102 29.5612L58.6229 49.7166C58.8673 50.3906 59.4896 50.8063 60.1515 50.8063C60.34 50.8063 60.5329 50.773 60.7205 50.7017C61.5646 50.3776 61.9939 49.4139 61.6791 48.5483L53.9362 27.2116L56.9907 24.4795L71.3564 64.0627L27.6558 63.8951Z'
      fill='#493C73'
    />
    <path
      d='M72.7243 42.6742C72.0642 42.6742 71.442 42.2576 71.1976 41.5836C70.8819 40.718 71.3121 39.7542 72.1562 39.4302L81.241 35.9566C82.0824 35.6354 83.0211 36.0733 83.3368 36.9407C83.6515 37.8064 83.2222 38.7701 82.3782 39.0941L73.2934 42.5677C73.1067 42.6399 72.9146 42.6742 72.7243 42.6742Z'
      fill='#5C9FF8'
    />
    <path
      d='M68.8691 32.0535C68.5111 32.0535 68.1522 31.9341 67.851 31.686C67.1485 31.1092 67.0357 30.0547 67.5976 29.3326L72.5909 22.9317C73.1527 22.2105 74.1816 22.0948 74.8823 22.6715C75.5849 23.2501 75.6976 24.3028 75.1358 25.024L70.1425 31.4249C69.8223 31.8396 69.348 32.0535 68.8691 32.0535Z'
      fill='#5C9FF8'
    />
    <path
      d='M76.8726 49.974L84.7283 51.4609C85.612 51.6284 86.1991 52.5014 86.035 53.4096C85.8907 54.216 85.2053 54.7798 84.4343 54.7798C84.336 54.7798 84.2368 54.7715 84.1367 54.752L76.281 53.2661C75.3973 53.0986 74.8102 52.2256 74.9743 51.3174C75.1376 50.4082 75.978 49.8037 76.8726 49.974Z'
      fill='#5C9FF8'
    />
  </svg>
)

export default MegaphoneIcon
