import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import ShoutoutCardHomeFeed from 'components/shoutouts/shoutoutCardHomeFeed'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import shoutoutIcon from 'assets/shoutoutIcon.svg'

const I18N = i18nPath('views.custom_styles.preview_components')

const ShoutoutCardBackgroundColor = () => {
  const currentUser = useCurrentUser()

  return (
    <ShoutoutCardHomeFeed
      feedItem={{}}
      shoutout={{
        id: 7,
        createdAt: '2019-09-23T05:46:51Z',
        updatedAt: '2019-09-23T05:46:51Z',
        text: 'Way to go!',
        sender: { ...currentUser, active: true },
        liked: true,
        companyValues: [
          {
            id: '2',
            createdAt: '2019-07-24T13:36:50Z',
            updatedAt: '2019-07-24T13:36:50Z',
            orderPosition: 0,
            name: I18N('shoutout_company_value_name'),
            description: I18N('shoutout_company_value_description'),
            imageUrl: shoutoutIcon,
            feedImageUrl: shoutoutIcon,
          },
        ],
        users: [
          { id: '0', preferredFullName: 'Michael Scott', active: true },
        ],
      }}
      user={currentUser}
      blockClicks
    />
  )
}

export default ShoutoutCardBackgroundColor
