import React, { useState } from 'react'

import Modal from 'components/common/modal'
import ReactSelect from 'components/common/react_select'
import { Button } from 'react-bootstrap'
import { WorkspaceType } from 'types/chat/workspace'
import { I18NCommon } from 'utils/i18nHelpers'

interface Props {
  onCancel: () => void
  onWorkspaceSelected: (workspace: WorkspaceType) => void
  visibleWorkspaces: WorkspaceType[]
  title: string
}

const SelectWorkspaceModal = ({
  onCancel, onWorkspaceSelected, title, visibleWorkspaces,
}: Props) => {
  const [workspace, setWorkspace] = useState<WorkspaceType | null>(null)

  const onCreateClick = () => {
    onWorkspaceSelected(workspace!)
  }

  return (
    <Modal size='lg' visible toggle={onCancel}>
      <h4 className='mb-3 text-left'>{title}</h4>
      <ReactSelect
        className='mb-2'
        getOptionValue={option => option.id}
        getOptionLabel={option => option.domain}
        options={visibleWorkspaces}
        value={workspace}
        onChange={setWorkspace}
        menuPlacement='auto'
      />
      <div className='d-flex justify-content-end align-items-center'>
        <div role='button' tabIndex={0} onClick={onCancel} className='mr-3 text-muted-dark'>
          {I18NCommon('cancel')}
        </div>
        <Button
          className='my-4'
          disabled={!workspace}
          onClick={onCreateClick}
        >
          {I18NCommon('select')}
        </Button>
      </div>
    </Modal>
  )
}

export default SelectWorkspaceModal
