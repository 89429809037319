
import React from 'react'

import GmailIntegrationCard from 'components/admin/integrations/gmail_api/gmailIntegrationCard'
import { i18nPath } from 'utils/i18nHelpers'
import CollapsibleCard from 'components/common/collapsibleCard'
import { useSelector } from 'react-redux'
import externalEmailAuthenticationSlice from 'redux/slices/externalEmailAuthentications'

const I18N = i18nPath('views.admin.external_email_authentications')

const GmailIntegrationCards = () => {
  const {
    user: userData, companyDefault: companyDefaultData,
  } = useSelector(externalEmailAuthenticationSlice.selectors.getData())

  return (
    <CollapsibleCard title={I18N('connect_your_account')} className='mb-3' initialIsOpen>
      <section className='GmailIntegrationCards'>
        {userData && (
          <GmailIntegrationCard
            id='user_gmail'
            installUrl={userData.installUrl}
            externalEmailAuthenticationId={userData.externalEmailAuthenticationId}
          />
        )}
        {companyDefaultData && (
          <GmailIntegrationCard
            id='company_default_gmail'
            installUrl={companyDefaultData.installUrl}
            externalEmailAuthenticationId={companyDefaultData.externalEmailAuthenticationId}
          />
        )}
      </section>
    </CollapsibleCard>
  )
}

export default GmailIntegrationCards
