import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'

import adminArticleSlice from 'redux/slices/admin/articles'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import DateCell from 'components/common/tables/dateCell'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import RoundedAvatars from 'components/common/roundedAvatars'
import ReactSelect from 'components/common/react_select'
import EmptyStateTable from 'components/admin/articles/emptyStateTable'
import CdnSvg from 'components/common/cdnSvg'
import ArticleDropdown from 'components/admin/articles/articleDropdown'
import ArticleStatusLabel from 'components/admin/articles/articleStatusLabel'
import PrivateLabel from 'components/admin/articles/privateLabel'

const I18N = i18nPath('views.admin.article_list')
const searchIconPath = '/images/searchIcon.svg'

const STATUS = {
  Published: 'published',
  Scheduled: 'scheduled',
  Pending: 'pending',
  Draft: 'draft',
}

const STATUS_OPTIONS = ['all', ...Object.values(STATUS)].map(status => ({
  value: status,
  label: I18N(`status.${status}`),
}))

const generateColumns = ({ basePath }) => [
  {
    header: I18N('table_header.title'),
    col: 'col-title',
    id: 'title',
    style: { minWidth: '30%' },
    accessor: d => (
      <div className='d-flex align-items-center'>
        {d.privateEditAccess && <PrivateLabel className='mr-2' />}
        <Link to={`${basePath}${d.id}`} className='truncate-text-at-2-lines'>
          {d.title}
        </Link>
      </div>
    ),
  },
  {
    header: I18N('table_header.creator'),
    col: 'col-creator',
    accessor: d => d.creator && <div data-cy={d.creator.username}><RoundedAvatars users={[d.creator]} avatarSize='30px' /></div>,
  },
  {
    header: I18N('table_header.created'),
    col: 'col-created_at',
    id: 'created_at',
    accessor: d => <DateCell date={d.createdAt} />,
  },
  {
    header: I18N('table_header.status'),
    col: 'col-status',
    accessor: d => <ArticleStatusLabel article={d} />,
  },
  {
    header: '',
    accessor: d => <ArticleDropdown article={d} location='list' />,
  },
]

const ArticlesTable = ({ myPosts = false }) => {
  const dispatch = useDispatch()
  const [selectedStatus, setSelectedStatus] = useState('all')
  const [query, setQuery] = useState()

  const { queryParams, isLoading, isNotFound } = useSelector(adminArticleSlice.selectors.getMetaData())
  const {
    page, totalPages,
  } = queryParams

  const fetchArticles = (fetchParams) => {
    dispatch(adminArticleSlice.asyncActions.fetchArticles({
      ...queryParams,
      q: query,
      publishStatus: selectedStatus,
      ...fetchParams,
      myPosts,
    }))
  }

  useEffect(() => {
    fetchArticles()
  }, [])

  const handleTitleSearch = (value) => {
    setQuery(value)
    fetchArticles({ q: value, page: 1 })
  }

  const handleStatusChange = ({ value }) => {
    setSelectedStatus(value)
    fetchArticles({ page: 1, publishStatus: value })
  }

  const articles = useSelector(adminArticleSlice.selectors.getArticles())

  const showEmptyState = articles.length === 0 && !isLoading

  const columns = useMemo(() => (
    generateColumns({ basePath: myPosts ? '/posts/' : '/admin/articles/' })
  ), [myPosts])

  return (
    <div className='ArticlesTable row mb-4'>
      <div className='col'>
        <div className='d-flex justify-content-between mb-3'>
          <AsyncSearchInput
            placeholder={I18N('title_search_placeholder')}
            icon={<CdnSvg src={searchIconPath} />}
            onKeyUp={handleTitleSearch}
            className='mb-0'
          />
          <div className='d-flex justify-content-center align-items-center'>
            <p className='text-secondary mb-0 mr-2'>{I18N('status.label')}</p>
            <ReactSelect
              className='article-status-select'
              options={STATUS_OPTIONS}
              onChange={handleStatusChange}
              value={STATUS_OPTIONS.filter(o => o.value === selectedStatus)}
            />
          </div>
        </div>

        <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
          {showEmptyState ? (
            <EmptyStateTable />
          ) : (
            <SmartTable
              data={articles}
              sortKey='created_at'
              sortedAsc={false}
              showPagination={page !== null && totalPages !== null}
              columns={columns}
              page={page}
              pages={totalPages}
              onPaginationClick={fetchArticles}
              onSortChange={fetchArticles}
              className='white-bg-table'
            />
          )}
        </TableLoadingContainer>
      </div>
    </div>
  )
}

export default ArticlesTable
