import React from 'react'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import FollowButton from 'components/profiles/core_fields/followButton'
import InviteToClearyButton from 'components/profiles/core_fields/inviteToClearyButton'
import { ProfileUserType } from 'types/user'

interface FollowOrInviteToClearyProps {
  user: ProfileUserType,
}

const FollowOrInviteToCleary = ({ user }: FollowOrInviteToClearyProps) => {
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()

  if (currentUser.inPreboardingExperience || !user.active || currentUser.id === user.id) {
    return null
  }

  if (currentCompany.nonAdminUsersCanGrantSoftLaunchAccess && !user.softLaunchAccess) {
    return <InviteToClearyButton user={user} />
  }

  return <FollowButton user={user} />
}

export default FollowOrInviteToCleary
