import React from 'react'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import OrgChartIcon from 'components/icons/orgChartIcon'

const I18N = i18nPath('views.profile')

const ViewInOrgChart = ({ username }) => (
  <div className='d-flex mt-2 py-1 justify-content-center'>
    <div className=''>
      <OrgChartIcon />
    </div>
    <div className='d-flex ml-1'>
      <Link className='text-small d-flex align-items-end' to={`/people/orgchart/${username}`}>{I18N('view_in_org_chart')}</Link>
    </div>
  </div>
)

export default ViewInOrgChart
