import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import groupCoverImageSlice from 'redux/slices/groupCoverImage'

import CoverImageWidget from 'components/common/images/coverImageWidget'

const I18N = i18nPath('views.pages')


const CoverImage = ({
  group: {
    id, coverImageUrl, originalCoverImageUrl, coverImageCroppedArea, permittedActions: { managePhotos },
  },
  coverImageFile,
  setCoverImageFile,
}) => (
  <CoverImageWidget
    withReposition
    className='group-cover-image-container'
    showActions={managePhotos}
    coverImageUrl={coverImageUrl}
    id={id}
    originalCoverImageUrl={originalCoverImageUrl}
    coverImageCroppedArea={coverImageCroppedArea}
    coverImageGuidanceText={<div className='ml-5 mt-2 text-secondary text-small'>{I18N('cover_image_guidance')}</div>}
    slice={groupCoverImageSlice}
    showAddCoverImageButton={false}
    coverImageFile={coverImageFile}
    setCoverImageFile={setCoverImageFile}
    defaultCropParams={{
      aspect: 1579 / 275,
      objectFit: 'horizontal-cover',
    }}
  />
)

export default CoverImage
