import React, { useEffect } from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import ReactSelect from 'components/common/react_select'
import { useDispatch, useSelector } from 'react-redux'
import userSlice from 'redux/slices/users'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.journeys.my_tasks')

const DirectReportsFilter = ({
  selectedDirectReport = null,
  onChange = () => {},
}) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const currentUserDirectReports = currentUser.directReports || []
  const directReportUserIds = currentUserDirectReports.map(user => user.id)
  const directReports = useSelector(userSlice.selectors.getSimpleUsersByIds(directReportUserIds))

  useEffect(() => {
    dispatch(userSlice.asyncActions.simpleFetchAll(directReportUserIds))
  }, [])

  return (
    <div className='direct-report-filter d-flex justify-content-end align-items-center'>
      <p className='text-secondary mb-0 mr-2'>{I18N('filter_by_direct_report')}</p>
      <ReactSelect
        getOptionValue={user => user.id}
        getOptionLabel={user => user.preferredFullName}
        value={selectedDirectReport}
        options={directReports}
        onChange={onChange}
        isClearable
        placeholder={I18N('all_direct_reports')}
      />
    </div>
  )
}

export default DirectReportsFilter
