import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Card from 'components/common/card'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import CheckBox from 'components/common/buttons/checkBox'
import DatePicker from 'components/form_fields/datePicker'
import AcknowledgementsStatusLabel from 'components/admin/acknowledgements/acknowledgementsStatusLabel'
import useIsCleary from 'components/common/hooks/useIsCleary'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import ApiAsyncSelector from 'components/common/apiAsyncSelector'
import API from 'services/api'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.admin.article_editor.audience_and_settings_editor')

const SettingsCard = ({
  article, workingCopy, updateWorkingCopy, isEditingTemplate,
}) => {
  const isEditingArticle = !isEditingTemplate
  const isCleary = useIsCleary()
  const { permissions } = useCurrentUser()

  const {
    requireAcknowledgement, acknowledgementDueAt, publishedAt, baseTemplate, draft,
  } = workingCopy

  const acknowledgementDueAtError = draft && requireAcknowledgement && i18nMoment(acknowledgementDueAt) < i18nMoment()

  const handleBaseTemplateChange = (baseTemplate) => {
    updateWorkingCopy({ baseTemplate, categories: [] })
  }

  const onChangeTemplateCategories = categories => updateWorkingCopy({ categories })

  const handleRequireAcknowledgementChange = (value) => {
    const changes = {
      requireAcknowledgement: value,
      acknowledgementDueAt: value ? moment(publishedAt || new Date()).add(7, 'days').format() : null,
    }

    updateWorkingCopy(changes)
  }

  return (
    <Card className='mb-4'>
      <h5>{I18N('settings')}</h5>
      <h6>{I18N('acknowledgement')}</h6>
      <CheckBox
        id='require-acknowledgement-checkbox'
        checked={requireAcknowledgement}
        onChange={handleRequireAcknowledgementChange}
        label={I18N('require_acknowledgement_label')}
      />

      {isEditingTemplate && isCleary && permissions.clearyAdmin && (
        <>
          <CheckBox
            id='base-template-checkbox'
            checked={baseTemplate}
            onChange={handleBaseTemplateChange}
            label={I18N('base_template_label')}
          />

          {baseTemplate && (
            <LabeledFormFieldContainer
              label={I18N('categories_label')}
              isRequired
            >
              <ApiAsyncSelector
                onChange={onChangeTemplateCategories}
                fetchAll={API.admin.article.templateCategories.fetchAll}
                value={workingCopy.categories}
                isMulti
                isCreatable
                menuPlacement='top'
                className='TemplateCategoriesSelector'
              />
            </LabeledFormFieldContainer>
          )}
        </>
      )}

      {isEditingArticle && (
        <>
          {requireAcknowledgement && (
            <>
              <label className='text-secondary'>{I18N('set_deadline')}</label>
              <DatePicker
                selected={acknowledgementDueAt && i18nMoment(acknowledgementDueAt)}
                onChange={val => updateWorkingCopy({ acknowledgementDueAt: val ? val.format() : null })}
                isClearable
                dateFormat='LLL'
                inputReadOnly={false}
                minDate={publishedAt ? i18nMoment(publishedAt) : i18nMoment()}
              />
              {acknowledgementDueAtError && <small className='form-text text-danger'>{I18N('acknowledgement_due_at_error')}</small>}
            </>
          )}

          {article?.published && article.requireAcknowledgement && (
            <div className='mt-3 d-flex align-items-center justify-content-between'>
              <AcknowledgementsStatusLabel acknowledgeableId={article.id} type='article' />
              <Link
                className='text-small'
                to={`/admin/articles/${article.id}/acknowledgements`}
              >
                {I18N('view_details')}
              </Link>
            </div>
          )}
        </>
      )}
    </Card>
  )
}

export default SettingsCard
