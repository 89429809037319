import React from 'react'
import { Link } from 'react-router-dom'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import RichTextView from 'components/common/richTextView'

const I18N = i18nPath('views.admin.journeys.communications')

const JourneyCommunicationCard = ({ communication }) => {
  const {
    feedTitle, step, displayPath,
  } = communication
  const { fromUserOrFromCorrespondentUser: fromUser, triggerAt } = step
  const fromUserPopoverId = `journey-communication-${communication.id}-from-user-${fromUser?.id}`

  return (
    <div>
      <Card className='JourneyCommunicationCard'>
        <p className='text-secondary mb-2'>{I18N(step?.journey?.journeyBlueprint?.type || 'journeys')}</p>
        <Link to={displayPath}>
          <h3>{feedTitle}</h3>
        </Link>
        <div className='Card-subtext text-small mb-4'>
          {fromUser && (
            <>
              <span className='text-small text-secondary mr-1'>{I18N('by')}</span>
              <EmployeeLinkWithPopover user={fromUser} id={fromUserPopoverId} />
              {' • '}
            </>
          )}
          <span className='text-secondary'>{i18nMoment(triggerAt).fromNow()}</span>
        </div>
        <RichTextView record={communication} recordType='journey_communication' richTextKey='feedContent' />
      </Card>
    </div>
  )
}

export default JourneyCommunicationCard
