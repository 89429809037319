import React from 'react'
import { Button } from 'components/common/buttons/'
import { Link } from 'react-router-dom'
import GearIcon from 'components/icons/gearIcon'
import { i18nPath } from 'utils/i18nHelpers'
import useSetCSSVarOnMount from 'components/common/hooks/useSetCSSVarOnMount'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.qna.events')

const ModeratorViewNavbar = ({
  isModerator = false,
  view = 'moderator',
  toggleViewURL,
  onClickSettings,
  onClickPublish,
  showPublishButton = false,
}) => {
  useSetCSSVarOnMount('--nav-box-shadow', 'none')

  // non-moderators shouldn't see the moderator toolbar
  if (!isModerator) return null

  if (view === 'moderator') {
    return (
      <div className='Moderator-navbar moderator px-0'>
        <div className='d-flex align-items-center justify-content-between h-100'>
          <div className='d-flex align-items-center h-100'>
            <Link className='px-3 mr-3 h-100 d-flex align-items-center justify-content-center' to={toggleViewURL}>
              <CdnSvg src='/images/eyeOpenIcon.svg' className='EyeOpenIcon mr-2' />
              {I18N('view_as_participant')}
            </Link>
          </div>
          <div className='d-flex justify-content-end'>
            <div className='Settings-button d-flex align-items-center' onClick={onClickSettings}>
              <GearIcon className='mr-2' />
              <span>
                {I18N('settings')}
              </span>
            </div>
            {showPublishButton && (
              <Button className='mr-2 mr-md-4' onClick={onClickPublish}>
                {I18N('publish_event')}
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='Moderator-navbar participant d-flex align-items-center'>
        <Link className='px-3 mr-3 h-100 d-flex align-items-center justify-content-center' to={toggleViewURL}>
          <CdnSvg src='/images/eyeOpenIcon.svg' className='EyeOpenIcon mr-2' />
          {I18N('view_as_moderator')}
        </Link>
      </div>
    )
  }
}

export default ModeratorViewNavbar
