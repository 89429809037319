import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import surveyQuestionSlice from 'redux/slices/surveys/questions'
import surveyAnswerSlice from 'redux/slices/surveys/answers'
import { useParams, Link } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import SmartTable from 'components/common/tables/smartTable'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import Card from 'components/common/card'
import BackButton from 'components/common/backButton'
import { QuestionTypeEnum } from 'types/surveys/question'
import QuestionOptionFilter from 'components/admin/surveys/questions/questionOptionFilter'
import useQueryParams from 'components/common/hooks/useQueryParams'
import ExportButton from 'components/common/buttons/exportButton'
import surveyFormSlice from 'redux/slices/surveys/forms'
import AnswerValue from 'components/admin/surveys/answers/answerValue'
import QuestionTitleWithIndexBubble from 'components/admin/surveys/questions/questionTitleWithIndexBubble'
import UserLink from 'components/common/userLink'
import { isOptionsQuestion, isTextQuestion } from 'utils/surveys/isValidQuestion'

const I18N = i18nPath('views.admin.surveys.questions.detail')

const ALL = 'all'

const QuestionDetailPage = () => {
  const dispatch = useDispatch()
  const { formId, questionId } = useParams()

  const { selectedValue = ALL } = useQueryParams()

  const question = useSelector(surveyQuestionSlice.selectors.getQuestion(questionId))
  const answers = useSelector(surveyAnswerSlice.selectors.getAnswers())
  const { isLoading } = useSelector(surveyQuestionSlice.selectors.getMetaData())
  const { isLoading: isLoadingAnswers, queryParams } = useSelector(surveyAnswerSlice.selectors.getMetaData())
  const { isExportingCsv } = useSelector(surveyFormSlice.selectors.getMetaData())

  const isChoiceQuestion = isOptionsQuestion(question)
  const isScaleQuestion = question?.type === QuestionTypeEnum.Scale
  const showAdditionalFeedback = question?.allowAdditionalText
  const isText = isTextQuestion(question)

  const title = question?.title

  const {
    page, totalPages, perPage, total,
  } = queryParams

  const fetchAnswers = (paginationParams = {}) => {
    const params: any = {
      questionId,
      perPage: 10,
      page: 1,
      ...paginationParams,
      sortBy: 'submitted_at',
      sortDir: 'desc',
    }

    if (selectedValue !== ALL) {
      if (isScaleQuestion) {
        params.value = selectedValue
      } else if (isChoiceQuestion) {
        params.question_option_id = selectedValue
      }
    }

    dispatch(surveyAnswerSlice.asyncActions.admin.fetchAll(formId, questionId, params))
  }

  useEffect(() => {
    dispatch(surveyQuestionSlice.asyncActions.admin.fetch(formId, questionId))

    return () => {
      dispatch(surveyAnswerSlice.actions.clearAnswerIds())
    }
  }, [questionId])

  useEffect(() => {
    if (!_.isEmpty(question)) {
      fetchAnswers()
    }
  }, [question?.id, selectedValue])

  const columns = [
    {
      header: I18NCommon('name'),
      accessor: d => (
        <UserLink
          user={d?.response?.user}
          customClassName='font-weight-500 text-primary-link'
          unknownUserText={I18NCommon('anonymous_user')}
        />
      ),
    },
    {
      header: I18N('answer'),
      accessor: d => <AnswerValue answer={d} question={question} />,
    },
    showAdditionalFeedback && {
      header: I18N('additional_feedback'),
      accessor: d => <span className='ws-pre-wrap'>{d?.additionalText}</span>,
    },
    {
      header: I18N('submitted_at'),
      accessor: d => <span>{i18nMoment(d?.response?.submittedAt).format('ll')}</span>,
    },
    {
      header: I18NCommon('actions'),
      accessor: d => <Link to={`/admin/surveys/${formId}/responses/${d?.response?.id}`}>{I18N('view_responses')}</Link>,
    },
  ].filter(Boolean)

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='QuestionDetailPage d-flex align-items-center flex-column justify-content-center w-100'>
      <header className='AdminHeader d-flex justify-content-between w-100'>
        <h3>{I18N('analytics_for', { title })}</h3>
        <ExportButton
          isLoading={isExportingCsv}
          asyncAction={surveyFormSlice.asyncActions.admin.exportResponses(formId,
            { questionId: question?.id, reportType: 'question_responses' }
          )}
        />
      </header>
      <main className='AdminContent w-100'>
        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <BackButton
            backToText={I18N('all_questions')}
            url={`${question.formAdminDisplayPath}?selectedTab=results`}
          />
          {!isText && (
            <QuestionOptionFilter
              question={question}
            />
          )}
        </div>
        <Card
          title={(
            <div className='d-flex align-items-center'>
              <QuestionTitleWithIndexBubble
                question={question}
                questionNumber={question?.orderPosition + 1}
              />
            </div>
          )}
          cardBodyClassName='p-4'
        >
          <TableLoadingContainer isLoading={isLoadingAnswers} useCirclesLoadingIndicator>
            <SmartTable
              className='white-bg-table mb-0'
              data={answers}
              columns={columns}
              showPagination={true}
              onPaginationClick={fetchAnswers}
              page={page}
              pages={totalPages}
              totalCount={total}
              perPage={perPage}
            />
          </TableLoadingContainer>
        </Card>
      </main>
    </div>
  )
}

export default QuestionDetailPage
