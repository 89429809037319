import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import ApiListMultiSelect from 'components/common/apiListMultiSelect'
import useWidgetRecordAssociations from 'hooks/layout/useWidgetRecordAssociations'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.preboarding_carousel_cards')

const PreboardingCarouselCardsWidgetSettingsSection = ({ widget, updateWidget }) => {
  const {
    records: carouselCards,
    onCreate,
    onDelete,
  } = useWidgetRecordAssociations({
    widget,
    updateWidget,
    recordType: 'CarouselCard',
  })

  return (
    <>
      <hr />
      <ApiListMultiSelect
        items={carouselCards}
        isLoading={false}
        isSaving={false}
        onCreate={onCreate}
        onDelete={onDelete}
        labelProp='name'
        search={query => API.admin.carouselCards.fetchAll({ query, filter: 'all' })}
        addButtonText={I18N('add_carousel_card')}
      />
    </>
  )
}

export default PreboardingCarouselCardsWidgetSettingsSection
