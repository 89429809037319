import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { generateColorGradient } from 'components/admin/surveys/questions/scaleQuestionStats'
import StatusLabel from 'components/common/status_label'

const I18N = i18nPath('views.admin.surveys.questions.stats')

const ScaleAverageStatusLabel = ({ question }) => {
  const { stats = {}, config = {} } = question
  const { minValue, maxValue } = config
  const { data = {}, totalAnswers = 0 } = stats

  const getAverage = () => {
    if (!totalAnswers) return 0
    let sum = 0
    Object.keys(data).forEach((key:string) => {
      sum += parseInt(data[key]) * parseInt(key)
    })
    return Math.round((sum / totalAnswers) * 100) / 100
  }

  if (!totalAnswers) return null

  return (
    <StatusLabel
      className='ml-2 text-nowrap'
      style={{
        backgroundColor: generateColorGradient(minValue, maxValue)[Math.round(getAverage()) - minValue],
        color: 'white',
      }}
    >
      {I18N('average')}: {getAverage()}
    </StatusLabel>
  )
}

export default ScaleAverageStatusLabel
