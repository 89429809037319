import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import commentSlice from 'redux/slices/comments'
import GreetingCardComment from 'components/celebrations/greetingCardComment'
import useWindowResize, { WINDOW_SIZE_LG, WINDOW_SIZE_MD } from 'components/common/hooks/useWindowResize'
import splitIntoColumns from 'components/celebrations/utils/splitIntoColumns'
import InfiniteScroller from 'components/common/infiniteScroller'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import CdnSvg from 'components/common/cdnSvg'
import { I18NCommon } from 'utils/i18nHelpers'
import CelebratedCard from 'components/celebrations/celebratedCard'
import useCanSignCelebration from 'components/celebrations/hooks/useCanSignCelebration'

const quotesPath = '/images/illustrations/quotes.svg'
const THANKS_MESSAGE_ID = 'thanks-message'
const CELEBRATED_CARD_ID = 'celebrated-card'

const Comment = ({
  comment, celebration, inEditPage, showCustomizeButton,
}) => {
  if (comment.id === THANKS_MESSAGE_ID) {
    return <ThanksMessage celebration={celebration} />
  }

  if (comment.id === CELEBRATED_CARD_ID) {
    return (
      <CelebratedCard
        celebration={celebration}
        inEditPage={inEditPage}
        showCustomizeButton={showCustomizeButton}
      />
    )
  }

  return <GreetingCardComment comment={comment} />
}

const ThanksMessage = ({ celebration }) => (
  <div className='ThanksMessage mb-3 bordered pt-0 pr-5 pb-5 pl-5'>
    <div className='d-flex justify-content-between align-items-center'>
      <div className='ThankYou text-large'>{I18NCommon('thank_you')}</div>

      <div>
        <CdnSvg src={quotesPath} />
      </div>
    </div>

    <div className='mb-4'>
      <TipTapRichTextView html={celebration.thanksMessage} />
    </div>

    <EmployeeThumbnailPhoto employee={celebration.user} className='mr-2' linkToProfile size='40px' />

    <Link to={`/profile/${celebration.user?.username}`} className='font-weight-600 text-primary'>
      {celebration.user?.preferredFullName}
    </Link>
  </div>
)

const GreetingCardComments = ({
  celebration, inEditPage = false, showCustomizeButton = false,
}) => {
  const dispatch = useDispatch()
  const { queryParams, isLoading } = useSelector(commentSlice.selectors.getMetaData('celebration', celebration.id))
  const {
    page,
    totalPages,
  } = queryParams
  const comments = useSelector(commentSlice.selectors.getComments('celebration', celebration.id))
  const { width } = useWindowResize()

  const canSignCelebration = useCanSignCelebration(celebration)

  if (celebration.thanksMessage) {
    comments.unshift({ id: THANKS_MESSAGE_ID, message: celebration.thanksMessage })
  }

  if (canSignCelebration) {
    comments.unshift({ id: CELEBRATED_CARD_ID })
  }

  const numberOfColumns = () => {
    if (width < WINDOW_SIZE_MD) {
      return 1
    }

    return width < WINDOW_SIZE_LG ? 2 : 3
  }

  const commentsInChunks = splitIntoColumns(comments, numberOfColumns())

  const fetchComments = (params = {}) => {
    dispatch(commentSlice.asyncActions.fetchAll(celebration, 'celebration', { ...queryParams, ...params }))
  }

  useEffect(() => {
    dispatch(commentSlice.asyncActions.fetchAll(celebration, 'celebration', queryParams))

    return () => {
      dispatch(commentSlice.actions.reset('celebration', celebration.id))
    }
  }, [])

  return (
    <InfiniteScroller
      isFetching={isLoading}
      hasMoreContent={() => page < totalPages}
      onFetch={() => fetchComments({ page: page + 1 })}
      className='GreetingCardCommentsContainer'
      usePlaceholderSpace
    >
      <div className='GreetingCardComments mt-3 mt-md-4'>
        {commentsInChunks.map((chunk, index) => (
          <div key={`chunk-${index}`} className='CommentsColumn'>
            {chunk.map(comment => (
              <Comment
                key={comment.id}
                comment={comment}
                celebration={celebration}
                inEditPage={inEditPage}
                showCustomizeButton={showCustomizeButton}
              />
            ))}
          </div>
        ))}
      </div>
    </InfiniteScroller>
  )
}

export default GreetingCardComments
