import useApi from 'components/common/hooks/useApi'
import getMentionedUserIds from 'components/common/tiptap/utils/getMentionedUserIds'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import userSlice from 'redux/slices/users'
import API from 'services/api'

export const getArticleAllMentionedUserIds = article => _.uniq([
  ...getMentionedUserIds(article?.content),
  ...getMentionedUserIds(article?.cardContent),
])

// when previewing we need to fetch the mentioned users and pass them as contentMentionedUsers
// so @ mentions look correct
const usePreviewableArticle = (article, isVisible = true) => {
  const [isPreviewing, setIsPreviewing] = useState(false)
  const newMentionedUserIds = useMemo(() => (
    getArticleAllMentionedUserIds(article)
  ), [article.content, article.cardContent])
  const newMentionedUsers = useSelector(userSlice.selectors.getSimpleUsersByIds(newMentionedUserIds))

  const [fetchUsers] = useApi(API.users.simpleFetchAll, { updateEntitySlice: true })

  const togglePreview = () => {
    if (isPreviewing) {
      setIsPreviewing(false)
    } else {
      setIsPreviewing(true)
    }
  }

  const allMentionedUsers = _.uniqBy([
    ...(article.contentMentionedUsers || []),
    ...(article.cardContentMentionedUsers || []),
    ...newMentionedUsers,
  ], 'id')

  useEffect(() => {
    if (!isVisible) return

    const idsToFetch = _.difference(newMentionedUserIds, allMentionedUsers.map(u => u.id))
    if (idsToFetch.length === 0) return

    fetchUsers(idsToFetch)
  }, [JSON.stringify(newMentionedUserIds), isVisible])

  const previewableRecord = {
    ...article,
    contentMentionedUsers: allMentionedUsers,
    cardContentMentionedUsers: allMentionedUsers,
  }

  return [previewableRecord, { isPreviewing, togglePreview }]
}

export default usePreviewableArticle
