import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import API from 'services/api'
import build from 'redux-object'
import entitySlice from 'redux/slices/entities'

import { getResponseOrThrow } from 'utils/errorHandling'
import { createSlice } from '@reduxjs/toolkit'
import { _ } from 'core-js'


export const initialState = {
  articleIds: [],
  articleTypeIds: [],
  meta: {
    error: null,
    articleTypesError: null,
    queryParams: defaultPaginationParams,
    isLoading: true,
    isLoadingArticleTypes: true,
  },
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // This is where we want to expand our generic handling of errors from the Rails backend
    //   * Generic string error like 500's
    //   * Form based, return all fields that are populating a model.error and the standardized message per field, using this to create inline error messages
    setError(state, action) {
      state.meta.error = action.payload
    },

    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },

    setIsLoadingArticleTypes(state, action) {
      state.meta.isLoadingArticleTypes = action.payload
    },

    setArticleIds(state, action) {
      state.articleIds = action.payload
    },

    clearArticles(state) {
      state.articleIds = []
    },

    clearQueryParams(state) {
      state.meta.queryParams = defaultPaginationParams
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    setArticleTypeIds(state, action) {
      state.articleTypeIds = action.payload
    },

    setArticleTypesError(state, action) {
      state.meta.articleTypesError = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  selectArticleType: (articleTypeNameParam, tagId) => (dispatch, getState) => {
    dispatch(newsSlice.actions.clearArticles())

    const queryParams = { ...defaultPaginationParams, tagId }
    dispatch(asyncActions.fetchArticles(articleTypeNameParam, queryParams))
  },

  fetchArticles: (articleTypeNameParam, queryParams) => async (dispatch, getState) => {
    const articleType = selectors.getArticleTypeByNameParam(articleTypeNameParam)(getState())

    dispatch(newsSlice.actions.setIsLoading(true))

    if (articleType) {
      queryParams = { ...queryParams, articleTypeIds: [articleType.id] }
    }

    try {
      const response = await API.articles.fetchAll(queryParams)
      const newQueryParams = queryParamsFromHeaders(response)
      const currentArticlesIds = selectors.getArticles()(getState()).map(a => a.id)
      const newsIds = _.uniq([...currentArticlesIds, ...response.data.data.map(n => n.id)])

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(newsSlice.actions.setArticleIds(newsIds))
      dispatch(newsSlice.actions.setQueryParams(newQueryParams))
    } catch (e) {
      dispatch(newsSlice.actions.setError(getResponseOrThrow(e)))
    } finally {
      dispatch(newsSlice.actions.setIsLoading(false))
    }
  },

  fetchArticleTypes: () => async (dispatch) => {
    dispatch(newsSlice.actions.setIsLoadingArticleTypes(true))

    try {
      const response = await API.articleTypes.fetchAll()
      const articleTypeIds = response.data.data.map(at => at.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(newsSlice.actions.setArticleTypeIds(articleTypeIds))
    } catch (e) {
      dispatch(newsSlice.actions.setArticleTypesError(getResponseOrThrow(e)))
    } finally {
      dispatch(newsSlice.actions.setIsLoadingArticleTypes(false))
    }
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getMetaData: () => state => state.news.meta,

  getArticleTypes: () => state => state.news.articleTypeIds.map(id => build(state.entities, 'articleType', id)),

  getArticles: () => state => state.news.articleIds.map(id => build(state.entities, 'article', id)),

  getArticleTypeByNameParam: articleTypeNameParam => (state) => {
    const articleTypes = selectors.getArticleTypes()(state)
    return _.find(articleTypes, { nameParam: articleTypeNameParam })
  },
}

export default {
  ...newsSlice,
  asyncActions,
  selectors,
}
