import React, { useEffect, useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import EventCard from 'components/qna/eventCard'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import EmptyEventsNotice from 'components/qna/emptyEventsNotice'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.qna.events')

const EventsGrid = ({
  events,
  filter,
  isCentered = false,
  isLoadingEvents,
  queryParams,
  fetchMoreEvents,
  onClickCreateEvent,
  isEventCreator,
}) => {
  // minHeight equivalent to the height of a card,
  // helps with jumpiness between filtering states.
  const minHeight = '18rem'
  const { page, totalPages } = queryParams
  const hasMoreEventsToLoad = page < totalPages
  const [isFetchMoreClicked, setIsFetchMoreClicked] = useState(false)

  const fetchMore = () => {
    setIsFetchMoreClicked(true)
    fetchMoreEvents()
  }

  useEffect(() => {
    setIsFetchMoreClicked(false)
  }, [filter])

  if (isLoadingEvents && !isFetchMoreClicked) {
    return (
      <div style={{ minHeight }} className='row mt-4 col-md-12'>
        <div className={isCentered ? 'col-md-9 offset-md-1-5' : ''}>{isLoadingEvents && <LoadingIndicator className='position-absolute left-0 right-0 mt-6' />} </div>
      </div>
    )
  }

  return (
    <>
      <div style={{ minHeight }} className='row mt-4'>
        <div className={isCentered ? 'col-md-9 offset-md-1-5' : 'col-md-12'}>
          {_.isEmpty(events) ? (
            <div className='text-normal text-secondary text-center d-flex h-100 align-items-center justify-content-center'>
              <EmptyEventsNotice
                isEventCreator={isEventCreator}
                onClickCreateEvent={onClickCreateEvent}
              />
            </div>
          ) : (
            <>
              {
                // index prop used for distributing space between cards appropriately
                // isLoadingEvents ? null : events.map((event, i) => <EventCard key={event.id} event={event} index={i} />)
                events.map((event, i) => <EventCard key={event.id} event={event} index={i} />)
              }
              {isLoadingEvents && isFetchMoreClicked && <LoadingIndicator />}
            </>
          )}
        </div>
      </div>
      {hasMoreEventsToLoad && !isLoadingEvents && (
        <div className='row mt-5 text-center'>
          <div className={isCentered ? 'col-md-9 offset-md-1-5' : 'col-md-12'}>
            <Button onClick={fetchMore}> {I18N('view_more')}</Button>
          </div>
        </div>
      )}
    </>
  )
}

export default EventsGrid
