import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { I18NCommon } from 'utils/i18nHelpers'
import SmartTable from 'components/common/tables/smartTable'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { SimpleUserType } from 'types/user'
import surveyFormSlice from 'redux/slices/surveys/forms'
import SendFormNotificationButton from 'components/admin/surveys/responses/sendFormNotificationButton'

const UsersWhoHaveNotRespondedTable = ({
  formId,
  showActionsColumn = true,
}) => {
  const dispatch = useDispatch()
  const usersWhoHaveNotResponded = useSelector(surveyFormSlice.selectors.getUsersWhoHaveNotResponded())
  const {
    usersWhoHaveNotRespondedQueryParams,
    isLoadingNotRespondedUsers,
  } = useSelector(surveyFormSlice.selectors.getMetaData())

  const {
    page, totalPages, total, perPage,
  } = usersWhoHaveNotRespondedQueryParams

  const columns = [
    {
      header: I18NCommon('name'),
      accessor: (d: SimpleUserType) => <EmployeeLinkWithPopover user={d} unknownUserText={I18NCommon('anonymous_user')} userLinkClassName='link-color text-primary' />,
    },
    showActionsColumn && {
      header: I18NCommon('actions'),
      accessor: (d: SimpleUserType) => <SendFormNotificationButton user={d} formId={formId} />,
    },
  ].filter(Boolean)

  const fetchUsersWhoHaveNotResponded = (paginationParams = {}) => {
    const params = {
      page: 1,
      perPage: 10,
      ...paginationParams,
    }

    dispatch(surveyFormSlice.asyncActions.admin.fetchUsersWhoHaveNotResponded(formId, params))
  }

  useEffect(() => {
    fetchUsersWhoHaveNotResponded()
  }, [formId])

  if (isLoadingNotRespondedUsers) {
    return <CirclesLoadingIndicator />
  }

  return (
    <SmartTable
      className='white-bg-table FormResponsesTable'
      data={usersWhoHaveNotResponded}
      columns={columns}
      showPagination={true}
      page={page}
      pages={totalPages}
      totalCount={total}
      perPage={perPage}
      onPaginationClick={fetchUsersWhoHaveNotResponded}
    />
  )
}

export default UsersWhoHaveNotRespondedTable
