import React, { useState } from 'react'

import Modal from 'components/common/modal'
import ReactSelect from 'components/common/react_select'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import JourneyType from 'types/journeys/journey'
import CreateStepTypeSelectModal from 'components/admin/journeys/modals/createStepTypeSelectModal'

const I18N = i18nPath('views.admin.journeys.new_hire_detail.select_journey_modal')

const SelectJourneyModal = ({
  visible,
  closeModal,
  journeys,
}) => {
  const [selectedJourney, setSelectedJourney] = useState<JourneyType | null>(null)
  const [showSelectStepTypeModal, setShowSelectStepTypeModal] = useState(false)
  const journeyBlueprint = selectedJourney?.journeyBlueprint

  return (
    <>
      <Modal visible={visible} toggle={closeModal} className='SelectJourneyModal'>
        <div className='px-3'>
          <h4>{I18N('title')}</h4>

          <ReactSelect
            className='my-5'
            getOptionValue={option => option.id}
            getOptionLabel={option => option.journeyBlueprint?.name}
            options={journeys}
            value={selectedJourney}
            onChange={setSelectedJourney}
            menuPlacement='auto'
          />

          <div className='d-flex justify-content-end align-items-center'>
            <CancelButton onClick={closeModal} />
            <Button
              className='mb-2'
              disabled={!selectedJourney}
              onClick={() => setShowSelectStepTypeModal(true)}
            >
              {I18NCommon('next')}
            </Button>
          </div>
        </div>
      </Modal>

      {showSelectStepTypeModal && (
        <CreateStepTypeSelectModal
          visible={showSelectStepTypeModal}
          toggle={closeModal}
          journeyBlueprintId={journeyBlueprint?.id}
          journeyBlueprintType={journeyBlueprint?.type}
          isCustomStep
          journeyId={selectedJourney?.id}
        />
      )}
    </>
  )
}

export default SelectJourneyModal
