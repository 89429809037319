import React from 'react'
import classNames from 'classnames'
import TableOfContents from 'components/pages/tableOfContents'
import LegalNoticesFooter from 'components/common/legalNoticesFooter'
import RichTextView from 'components/common/richTextView'
import PageCoverImage from 'components/pages/pageCoverImage'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import UpdaterAndTimestamp from 'components/pages/updaterAndTimestamp'

const PreboardingPageContent = ({
  page,
}) => {
  const currentCompany = useCurrentCompany()

  const { settings } = currentCompany

  const { showFullWidth, title } = page

  return (
    <div className='PageContent flex-grow-1 overflow-y-auto bg-white'>
      <PageCoverImage page={page} />
      <div className='d-flex justify-content-center px-4 pt-3 pb-4'>
        <div className={classNames('flex-grow-1', showFullWidth ? 'mw-100' : 'mw-page-narrow')}>
          <h1>{title}</h1>
          <UpdaterAndTimestamp
            canEditPage={false}
            isEditing={false}
            areDraftChangesUnpublished={false}
            page={page}
          />
          {page.showTableOfContents && (
            <TableOfContents page={page} isEditing={false} />
          )}
          <RichTextView
            record={page}
            recordType='page'
            richTextKey='content'
          />
        </div>

        {settings.legalNotices.contentFooter.enabled && <LegalNoticesFooter />}
      </div>
    </div>
  )
}

export default PreboardingPageContent
