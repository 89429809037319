import React, { useState } from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import PillTabSelector from 'components/common/pillTabSelector'

import classNames from 'classnames'

const TabsPage = () => {
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <main className='Styleguide TabsPage container mt-4'>
      <h1><StyleguideBaseLink /> / Tabs</h1>
      <section className='mt-4'>
        <h3>components/common/pillTabSelector</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                PillTabSelector
              </td>
              <td>
                Variant: PillTab
              </td>
              <td>
                <PillTabSelector
                  defaultSelectedIndex={currentTab}
                  pillClasses='text-normal'
                  tabs={[
                    {
                      text: 'First Option',
                      onClick: () => {
                        setCurrentTab(0)
                      },
                    },
                    {
                      text: 'Second Option',
                      onClick: () => {
                        setCurrentTab(1)
                      },
                    },
                  ]}
                />
              </td>
            </tr>
            <tr>
              <td>
                PillTabSelector
              </td>
              <td>
                Variant: PageTab
              </td>
              <td>
                <PillTabSelector
                  defaultSelectedIndex={currentTab}
                  pillClasses='text-normal'
                  variant='PageTab'
                  tabs={[
                    {
                      text: 'First Option',
                      onClick: () => {
                        setCurrentTab(0)
                      },
                    },
                    {
                      text: 'Second Option',
                      onClick: () => {
                        setCurrentTab(1)
                      },
                    },
                  ]}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default TabsPage
