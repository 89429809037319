import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { sendErrorToAppSignalAndShowToast } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.home.errors')

export const initialState = {
  feedItemIds: [],
  events: [],
  meta: {
    isFetching: false,
    queryParams: defaultPaginationParams,
  },
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    clearFeed(state, action) {
      state.feedItemIds = []
      state.meta.queryParams = defaultPaginationParams
    },

    isFetching(state, action) {
      state.meta.isFetching = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    addFeedItemIds(state, action) {
      state.feedItemIds = _.uniq([...state.feedItemIds, ...action.payload])
    },

    setEvents(state, action) {
      state.events = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  fetchFeed: (params = {}) => async (dispatch, getState) => {
    const inPreboardingExperience = getState().currentUser.inPreboardingExperience
    dispatch(homeSlice.actions.isFetching(true))
    try {
      let response
      if (inPreboardingExperience) {
        response = await API.journey.preboarding.home.fetchFeed(params)
      } else {
        response = await API.home.fetchFeed(params)
      }

      const feedItemIds = response.data.data.map(feedItem => feedItem.id)
      const newQueryParams = queryParamsFromHeaders(response)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(homeSlice.actions.setQueryParams(newQueryParams))
      dispatch(homeSlice.actions.addFeedItemIds(feedItemIds))
    } catch (e) {
      sendErrorToAppSignalAndShowToast(e, dispatch, I18N('fetch_feed'))
    } finally {
      dispatch(homeSlice.actions.isFetching(false))
    }
  },

  fetchUpcomingEvents: () => async (dispatch) => {
    try {
      const response = await API.calendars.fetchUpcomingEvents()
      dispatch(homeSlice.actions.setEvents(response.data))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    }
  },

  subscribeToFeedItem: feedItem => async (dispatch) => {
    try {
      const response = await API.home.subscribe(feedItem)
      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (e) {
      sendErrorToAppSignalAndShowToast(e, dispatch, I18N('subscribe_to_feed_item'))
    }
  },

  unsubscribeFromFeedItem: feedItem => async (dispatch) => {
    try {
      const response = await API.home.unsubscribe(feedItem)
      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (e) {
      sendErrorToAppSignalAndShowToast(e, dispatch, I18N('unsubscribe_from_feed_item'))
    }
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getMetaData: () => state => state.home.meta,
  getFeedItems: () => state => state.home.feedItemIds.map(id => build(state.entities, 'feedItem', id)).filter(Boolean),
  getEvents: () => state => state.home.events,
}

export default {
  ...homeSlice,
  asyncActions,
  selectors,
}
