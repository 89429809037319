import React from 'react'
import Modal from 'components/common/modal'
import { i18nPath } from 'utils/i18nHelpers'
import LinkForm from 'components/home/widgets/links_widget/linkForm'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

type Props = {
  link: any
  widgetId: string
  isOpen: boolean
  toggle: () => void
  onSave: () => void
}

const I18N = i18nPath('views.home.links_widget.personal_link_modal')

const PersonalLinkModal = ({
  link,
  widgetId,
  isOpen,
  toggle,
  onSave,
}: Props) => {
  const isNew = !link.id

  const [createLink, { isLoading: isCreating }] = useApi(API.layout.links.create, {
    onSuccess: () => {
      toggle()
      onSave()
    },
  })
  const [updateLink, { isLoading: isUpdating }] = useApi(API.layout.links.update, {
    onSuccess: () => {
      toggle()
      onSave()
    },
  })

  const handleSave = (updatedLink) => {
    if (isNew) {
      createLink(widgetId, updatedLink)
    } else {
      updateLink(widgetId, updatedLink)
    }
  }

  return (
    <Modal
      visible={isOpen}
      toggle={toggle}
      className='PersonalLinkModal'
    >
      <h3>{I18N(isNew ? 'add_personal_link' : 'edit_personal_link')}</h3>
      <div className='text-small text-secondary'>{I18N('description')}</div>
      <LinkForm
        link={link}
        onSave={handleSave}
        onCancel={toggle}
        isSaving={isCreating || isUpdating}
      />
    </Modal>
  )
}

export default PersonalLinkModal
