import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import BackButton from 'components/common/backButton'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import AwardedBadgeCard from 'components/home/awardedBadgeCard'
import awardedBadgeSlice from 'redux/slices/awardedBadges'
import { trackEvent } from 'services/tracker'

const AwardedBadgePage = () => {
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()

  const { badgeId, awardedBadgeId } = useParams()
  const awardedBadge = useSelector(awardedBadgeSlice.selectors.getAwardedBadge(awardedBadgeId))
  const { isLoading } = useSelector(awardedBadgeSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(awardedBadgeSlice.asyncActions.fetch(badgeId, awardedBadgeId))
    trackEvent('awarded_badge:view', { badge_id: badgeId, awarded_badge_id: awardedBadgeId })
  }, [badgeId, awardedBadgeId])

  return (
    <div className='AwardedBadgePage mb-5'>
      <div className='container'>
        <div className='col-12 offset-md-2 col-md-8 mt-5'>
          <div className='mb-5 d-flex justify-content-between align-items-center'>
            <BackButton />
          </div>

          {isLoading && <CirclesLoadingIndicator />}
          {!isLoading && !_.isEmpty(awardedBadge) && (
            <AwardedBadgeCard
              awardedBadge={awardedBadge}
              currentUser={currentUser}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AwardedBadgePage
