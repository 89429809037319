import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useQueryParams from 'components/common/hooks/useQueryParams'
import PromptTabContent, { DEFAULT_MODEL, DEFAULT_MAX_TOKENS, DEFAULT_TEMPERATURE } from 'components/admin/ai/playground/promptTabContent'
import ResultTabContent from 'components/admin/ai/playground/resultTabContent'
import useAiTextGenerationEvents from 'components/common/tiptap/hooks/ai/useAiTextGenerationEvents'
import useAiGenerateText from 'components/common/tiptap/hooks/ai/useAiGenerateText'

const I18N = i18nPath('views.admin.ai.playground')

const PROMPT_TAB = 'prompt'
const RESULT_TAB = 'result'

const tabs = [
  { id: PROMPT_TAB, text: I18N('tabs.prompt') },
  { id: RESULT_TAB, text: I18N('tabs.result') },
]

const AiPlaygroundPage = () => {
  const { selectedTab } = useQueryParams()
  const history = useHistory()

  const [customSystemMessage, setCustomSystemMessage] = useState('')
  const [prompt, setPrompt] = useState('')
  const [model, setModel] = useState(DEFAULT_MODEL)
  const [temperature, setTemperature] = useState<any>(DEFAULT_TEMPERATURE)
  const [maxTokens, setMaxTokens] = useState<any>(DEFAULT_MAX_TOKENS)
  const [textGenerationId, setTextGenerationId] = useState<any>()

  const changeTab = (tab) => {
    history.replace(`/admin/ai/playground?selectedTab=${tab}`)
  }

  const onCreateSucess = (id) => {
    setTextGenerationId(id)
    changeTab(RESULT_TAB)
  }

  const {
    result,
    setResult,
    errorReason,
    isGenerating,
    functionCalls,
  } = useAiTextGenerationEvents(textGenerationId)

  const { generateText, isSaving } = useAiGenerateText({
    onCreateSucess,
    prompt,
    extraParams: {
      model,
      temperature,
      maxTokens,
      customSystemMessage,
    },
  })

  return (
    <div className='AiPlaygroundPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('ai_playground')}</h3>
      </header>
      <main className='AdminContent'>
        <HistoryChangingPillTabSelector
          className='mb-4'
          tabs={tabs}
          pillClasses='text-normal'
        />
        {selectedTab === PROMPT_TAB && (
          <PromptTabContent
            prompt={prompt}
            setPrompt={setPrompt}
            customSystemMessage={customSystemMessage}
            setCustomSystemMessage={setCustomSystemMessage}
            model={model}
            setModel={setModel}
            temperature={temperature}
            setTemperature={setTemperature}
            maxTokens={maxTokens}
            setMaxTokens={setMaxTokens}
            onGenerateText={generateText}
            isSaving={isSaving}
          />
        )}
        {selectedTab === RESULT_TAB && (
          <ResultTabContent
            errorReason={errorReason}
            functionCalls={functionCalls}
            isGenerating={isGenerating}
            result={result}
            setResult={setResult}
          />
        )}
      </main>
    </div>
  )
}

export default AiPlaygroundPage
