import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import adminArticleSlice from 'redux/slices/admin/articles'
import acknowledgementSlice from 'redux/slices/acknowledgements'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import BackButton from 'components/common/backButton'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import StatusLabel, { StatusLabelWithTooltip } from 'components/common/status_label'
import { ACKNOWLEDGED, OVERDUE, PENDING } from 'types/acknowledgement'
import ReactSelect from 'components/common/react_select'
import AcknowledgementRemindersButtonAndModal from 'components/admin/acknowledgements/acknowledgementRemindersButtonAndModal'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import ExportButton from 'components/common/buttons/exportButton'
import AcknowledgementSpecificReminderButton from 'components/admin/acknowledgements/acknowledgementSpecificReminderButton'
import CdnSvg from 'components/common/cdnSvg'

const searchIconPath = '/images/searchIcon.svg'
const ALL = 'all'

const I18N = i18nPath('views.admin.article_stats.acknowledgements')

const COLOR_MAP = {
  [PENDING]: 'yellow',
  [ACKNOWLEDGED]: 'green',
  [OVERDUE]: 'red',
}

const STATUS_OPTIONS = [
  { label: I18N('view_all'), value: ALL },
  { label: I18N('acknowledged'), value: ACKNOWLEDGED },
  { label: I18N('pending'), value: PENDING },
  { label: I18N('overdue'), value: OVERDUE },
]

const columns = [
  {
    header: I18N('name'),
    style: { width: '50%' },
    accessor: d => <UserLink user={d.user} avatarSize='30px' customClassName='text-normal font-weight-500 text-primary-link' />,
  },
  {
    header: I18N('status'),
    style: { width: '30%' },
    accessor: d => (
      d.status === ACKNOWLEDGED ? (
        <StatusLabelWithTooltip
          color={COLOR_MAP[d.status]}
          text={d.acknowledgedAt && i18nMoment(d.acknowledgedAt).format('LLL')}
        >
          {I18N(d.status)}
        </StatusLabelWithTooltip>
      ) : (
        <StatusLabel color={COLOR_MAP[d.status]}>{I18N(d.status)}</StatusLabel>
      )
    ),
  },
  {
    header: I18N('action'),
    style: { width: '20%' },
    accessor: d => (
      <AcknowledgementSpecificReminderButton
        acknowledgement={d}
        type='article'
      />
    ),
  },
]

const ArticleAcknowledgementsPage = () => {
  const dispatch = useDispatch()
  const { articleId } = useParams()

  const [selectedStatus, setSelectedStatus] = useState(ALL)
  const [query, setQuery] = useState('')
  const article = useSelector(adminArticleSlice.selectors.getArticle())
  const { isLoading: isLoadingArticle } = useSelector(adminArticleSlice.selectors.getMetaData())
  const acknowledgements = useSelector(acknowledgementSlice.selectors.getAcknowledgements())
  const {
    isLoading,
    queryParams,
    acknowledgedCount,
    pendingCount,
    overdueCount,
    isExporting,
  } = useSelector(acknowledgementSlice.selectors.getMetaData())
  const totalCount = acknowledgedCount + pendingCount + overdueCount
  const hasOutstandingAcknowledgments = pendingCount + overdueCount > 0
  const {
    page, totalPages, total, perPage,
  } = queryParams

  const dueAt = article?.acknowledgementDueAt && i18nMoment(article.acknowledgementDueAt).format('LL')

  const fetchAcknowledgements = (params = {}) => {
    dispatch(acknowledgementSlice.asyncActions.admin.fetchAll(
      articleId,
      'article',
      {
        ...queryParams,
        status: selectedStatus,
        q: query,
        ...params,
      }
    ))
  }

  const handleStatusChange = ({ value }) => {
    setSelectedStatus(value)
    fetchAcknowledgements({ status: value, page: 1 })
  }

  const handleQueryChange = (value: string) => {
    setQuery(value)
    fetchAcknowledgements({ q: value, page: 1 })
  }

  useEffect(() => {
    dispatch(adminArticleSlice.asyncActions.fetchArticle(articleId))
    fetchAcknowledgements({ perPage: 10 })

    return () => {
      dispatch(adminArticleSlice.actions.clearArticleId())
      dispatch(acknowledgementSlice.actions.reset())
    }
  }, [])

  if (isLoadingArticle) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='ArticleAcknowledgementsPage'>
      <header className='AdminHeader'>
        <BackButton
          className='link-color bold mb-4'
        />
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='mb-0'>
            {I18N('page_header', { articleTitle: article?.title })}
          </h4>
          <div>
            <ExportButton
              asyncAction={acknowledgementSlice.asyncActions.admin.export(articleId, 'article')}
              isLoading={isExporting}
              variant='secondary'
            />
            {hasOutstandingAcknowledgments && (
              <AcknowledgementRemindersButtonAndModal
                acknowledgeableId={articleId}
                type='article'
                buttonType='button'
              />
            )}
          </div>
        </div>
      </header>
      <main className='AdminContent'>
        <div className='d-flex justify-content-between align-items-center w-100 mb-4'>
          <AsyncSearchInput
            icon={<CdnSvg src={searchIconPath} />}
            onKeyUp={handleQueryChange}
            className='mb-0'
            placeholder={I18NCommon('search_placeholder')}
          />
          <div className='d-flex align-items-center gap-4'>
            <div className='text-secondary'>{I18N('deadline_by', { date: dueAt })}</div>
            <div className='text-secondary'>{acknowledgedCount}/{totalCount} {I18N('acknowledged')}</div>
            <div className='text-secondary'>{I18N('status')}</div>
            <ReactSelect
              className='ClearyReactSelect StatusFilter'
              options={STATUS_OPTIONS}
              value={STATUS_OPTIONS.find(option => option.value === selectedStatus)}
              onChange={handleStatusChange}
            />
          </div>
        </div>
        <SmartTable
          isLoading={isLoading}
          className='white-bg-table'
          data={acknowledgements}
          columns={columns}
          page={page}
          pages={totalPages}
          totalCount={total}
          perPage={perPage}
          showPagination
          onPaginationClick={fetchAcknowledgements}
        />
      </main>
    </div>
  )
}

export default ArticleAcknowledgementsPage
