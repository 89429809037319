import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import moment from 'moment'

const I18N = i18nPath('views.admin.journeys.tasks')

const PENDING = 'pending'
const DUE_TODAY = 'due_today'
const OVERDUE = 'overdue'

const getDueAtText = (completionStatus, dueAtDate, hasDuePrefix = true) => {
  const dueAt = i18nMoment(dueAtDate)
  const isPending = completionStatus === PENDING
  const isDueToday = completionStatus === DUE_TODAY
  const isOverdue = completionStatus === OVERDUE

  const prefix = hasDuePrefix ? 'due_' : ''
  const I18NWithPrefix = (path, vars = {}) => I18N(`${prefix}${path}`, vars)

  if (isOverdue) {
    if (dueAt.isSame(moment(), 'day')) {
      return I18NWithPrefix('today')
    } else if (dueAt.isSame(moment().subtract(1, 'day'), 'day')) {
      return I18NWithPrefix('yesterday')
    } else {
      return I18NWithPrefix('on_date', { date: dueAt.format('ll') })
    }
  }

  if (!isPending && !isDueToday) {
    return I18N(completionStatus)
  }

  if (DUE_TODAY === completionStatus || dueAt.isSame(moment(), 'day')) {
    return I18NWithPrefix('today')
  }

  if (dueAt.isSame(moment().add(1, 'day'), 'day')) {
    return I18NWithPrefix('tomorrow')
  }

  if (dueAt.isSame(moment(), 'week')) {
    return I18NWithPrefix('on_week_day', { day: dueAt.format('dddd') })
  }

  return I18NWithPrefix('on_date', { date: dueAt.format('ll') })
}

export default getDueAtText
