import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import badgeIcon from 'assets/badgeIcon.png'

const I18N = i18nPath('views.custom_styles.preview_components')

const ProfileAwardedBadgeBorderWidth = () => (
  <div className='ProfileBadge mt-3'>
    <div style={{ width: '100px', border: 'var(--profile-awarded-badge-border-width) solid var(--border-color)' }}>
      <img src={badgeIcon} alt={I18N('awarded_badge_name')} style={{ width: '100%' }} />
    </div>
  </div>
)

export default ProfileAwardedBadgeBorderWidth
