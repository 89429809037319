import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import adminAlertSlice from 'redux/slices/adminAlerts'
import UserLink from 'components/common/userLink'

const I18N = i18nPath('views.admin.admin_alerts')

const columns = [
  {
    header: I18N('name'),
    col: 'col-name',
    id: 'first_name',
    accessor: user => <UserLink user={user} />,
  },
  {
    header: I18NCommon('actions'),
    accessor: user => <Link to={`/admin/users/${user.id}?selectedTab=admin_alerts`}>{I18NCommon('edit')}</Link>,
  },
]

const AdminAlertDetailPage = () => {
  const dispatch = useDispatch()

  const { adminAlertName } = useParams()

  const adminAlerts = useSelector(adminAlertSlice.selectors.getAdminAlerts())
  const users = adminAlerts.map(adminAlert => adminAlert.user)
  const { isLoading, queryParams } = useSelector(adminAlertSlice.selectors.getMetaData())
  const {
    page, totalPages, perPage, total,
  } = queryParams

  const [sortParams, setSortParams] = useState({ sortyBy: null, sortDir: null })


  const fetchAdminAlerts = (params = {}) => {
    dispatch(adminAlertSlice.asyncActions.admin.fetchAll({ ...sortParams, name: adminAlertName, ...params }))
  }

  const handlePaginationClick = (paginationParams) => {
    fetchAdminAlerts(paginationParams)
  }

  useEffect(() => {
    fetchAdminAlerts({ page: 1 })

    return () => {
      dispatch(adminAlertSlice.actions.clearAdminAlertIds())
    }
  }, [JSON.stringify(sortParams)])

  return (
    <>
      <header className='AdminHeader'>
        <h3 className='mb-1'>{I18N('admin_alerts')} - {I18N(adminAlertName)}</h3>
      </header>
      <main className='AdminContent'>
        <TableLoadingContainer isLoading={isLoading}>
          <SmartTable
            className='AdminAlertsList white-bg-table'
            data={users}
            columns={columns}
            onSortChange={setSortParams}
            onPaginationClick={handlePaginationClick}
            page={page}
            pages={totalPages}
            totalCount={total}
            perPage={perPage}
            showPagination
          />
        </TableLoadingContainer>
      </main>
    </>
  )
}

export default AdminAlertDetailPage
