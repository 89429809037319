import React, { useMemo } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CloseIcon from 'components/icons/closeIcon'
import Tooltip from 'components/common/clearyTooltip'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { randomReactKey } from 'components/common/utils'


const I18N = i18nPath('views.pages.table_of_contents')

type Header = {
  id: string
  size: number
  text?: string
  countOfType: number
}

const extractHeaders = (html: string): Array<Header> => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const headers: Header[] = []

  doc.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach((element) => {
    const size = parseInt(element.tagName.substring(1))
    headers.push({
      id: randomReactKey(),
      size,
      text: element.textContent?.trim(),
      countOfType: headers.filter(header => header.size === size).length + 1,
    })
  })

  return headers
}

const scrollToHeader = (header: Header) => {
  const elements = document.querySelectorAll(`.TiptapView h${header.size}`)
  const element = elements[header.countOfType - 1]

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

interface TableOfContentsInterface {
  page: any
  isEditing?: boolean
  removeTableOfContents?: () => void
}

const TableOfContents = ({
  page,
  isEditing = false,
  removeTableOfContents,
}: TableOfContentsInterface) => {
  const { isPreviewing } = useQueryParams()

  const html = isEditing || isPreviewing ? page.draftContent : page.content
  const headers = useMemo(() => extractHeaders(html), [html])

  if (_.isEmpty(headers) && !isEditing) {
    return null
  }

  const smallestHeaderIndent = Math.min(...headers.map(header => header.size))

  return (
    <div id='tableOfContents' className='TableOfContents mb-5 position-relative'>
      {isEditing && (
        <Tooltip
          placement='bottom'
          className='TableOfContentsTooltipContainer position-absolute top-0 right-0 mr-4 rounded'
          tooltipClassName='mt-2 px-1 rounded'
          content={(
            <span onClick={removeTableOfContents}>
              <CloseIcon />
            </span>
          )}
        >
          <p className='text-nowrap my-2 text-light text-small px-1 rounded'>
            {I18N('remove_table_of_contents')}
          </p>
        </Tooltip>
      )}
      <h6 className='text-secondary py-1 font-weight-500'>{I18N('table_of_contents')}</h6>
      {headers.map(header => (
        <h6
          key={header.id}
          className={`header-ml-${header.size - smallestHeaderIndent} lh-lg font-weight-600 link-color pointer`}
          onClick={() => scrollToHeader(header)}
        >
          {header.text}
        </h6>
      ))}
      {isEditing && _.isEmpty(headers) && (
        <h6 className='text-secondary mb-4 font-weight-500 text-small'>
          {I18N('no_headers')}
        </h6>
      )}
    </div>
  )
}

export default TableOfContents
