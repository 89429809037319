import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import getCurrentTimezone from 'utils/currentTimezone'
import currentUserSlice from 'redux/slices/currentUser'

const useUpdateUserTimezone = (location, user, company) => {
  const dispatch = useDispatch()
  const userTimezone = user?.currentTimezone
  const currentTimezone = getCurrentTimezone()
  const shouldUpdateTimezones = company?.settings?.profile?.fields?.whereToFindMe

  useEffect(() => {
    if (shouldUpdateTimezones && currentTimezone && userTimezone !== currentTimezone) {
      dispatch(currentUserSlice.asyncActions.update(user, { currentTimezone }))
    }
  }, [location, userTimezone, shouldUpdateTimezones])
}

export default useUpdateUserTimezone
