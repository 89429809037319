import React, { ReactElement } from 'react'
import { useCustomStyles, useVariables } from 'pages/admin/custom_styles/context'
import { sortArrayBy, getPropertyListWithValues } from 'components/admin/customStyles/utils'
import ColorItem from 'components/admin/customStyles/colorItem'
import BoxShadowSection from 'components/admin/customStyles/boxShadowSection'
import CompanyLogo from './companyLogo'
import TextTransformSection from './textTransformSection'
import FaviconUrlField from './faviconUrlField'

const NavigationSection = (): ReactElement => {
  const { handlePropertyChanged } = useCustomStyles()
  const colors = useVariables('colors')
  const colorVariables = getPropertyListWithValues({ variables: colors, type: 'colors' })
  const navVariables = ['navColor', 'navTextColor', 'navTextActiveColor', 'leftNavbarBackgroundColor']
  const subNavVariables = ['subnavColor', 'subnavTextColor', 'subnavTextActiveColor']

  const navSectionVariables = colorVariables.filter(([variableName]) => navVariables.includes(variableName))
  const subNavSectionVariables = colorVariables.filter(([variableName]) => subNavVariables.includes(variableName))

  navSectionVariables.sort(sortArrayBy(navVariables))
  subNavSectionVariables.sort(sortArrayBy(subNavVariables))

  return (
    <>
      <div className='mb-5'>
        <CompanyLogo />
      </div>

      <div className='d-flex align-items-center justify-content-between'>
        <div className='mb-5 NavbarVariables'>
          <ColorItem
            colorVariables={navSectionVariables}
            handlePropertyChanged={handlePropertyChanged}
          />
        </div>

        <div className='mb-5 SubNavVariables'>
          <ColorItem
            colorVariables={subNavSectionVariables}
            handlePropertyChanged={handlePropertyChanged}
          />
        </div>
      </div>

      <div className='mb-5'><BoxShadowSection type='navBoxShadow' /></div>
      <div className='mb-5'><TextTransformSection /></div>
      <div className='mb-5'><FaviconUrlField /></div>
    </>
  )
}

export default NavigationSection
