import React, { useEffect, useRef } from 'react'
import ClearySlider from 'components/common/slider'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import MonthlyChart from 'pages/ai_platform/knowledgeIngestionPage/chart'

const I18N = i18nPath('views.ai_assistant.knowledge_ingestion.slider')

const TotalQuestions = ({ knowledgeIngestion }) => (
  <div>
    <div className='d-flex gap-4'>
      <div className='d-flex flex-column gap-3'>
        <div className='icon'>
          <CdnSvg src='/images/chatQuestionIcon.svg' className='icon' />
        </div>

        <div className='d-flex flex-column'>
          <span className='text-3xl'>
            {I18N('total_questions.title.line_one')}
          </span>
          <span className='bold text-3xl'>
            {I18N('total_questions.title.line_two', { count: knowledgeIngestion.questionsCount })}
          </span>
        </div>
      </div>
      <div>
        {knowledgeIngestion.questionsCountByMonth ? (
          <MonthlyChart
            data={knowledgeIngestion.questionsCountByMonth.map(([month, count]) => ({
              label: month,
              count: Number(count),
            }))}
          />
        ) : (
          <CdnSvg src='/images/illustrations/fakeChart.svg' />
        )}
      </div>
    </div>
  </div>
)

const UnansweredQuestions = ({ knowledgeIngestion }) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const percentage = Math.floor(
    (knowledgeIngestion.unansweredQuestionsCount / knowledgeIngestion.questionsCount) * 100
  )

  useEffect(() => {
    if (chartRef.current) {
      const percentage = Math.floor(
        (knowledgeIngestion.unansweredQuestionsCount / knowledgeIngestion.questionsCount) * 100
      )

      chartRef.current.style.background = `conic-gradient(
        #A855F7 0% ${percentage / 2}%,
        #dceeff ${percentage / 2}% 100%
      )`
    }
  }, [knowledgeIngestion])

  return (
    <div>
      <div className='d-flex gap-4'>
        <div className='d-flex flex-column gap-3'>
          <div className='icon'>
            <CdnSvg src='/images/chatInfoIcon.svg' className='icon' />
          </div>

          <div className='d-flex flex-column'>
            <span className='text-3xl bold'>
              {I18N('unanswered_questions.title', { count: knowledgeIngestion.unansweredQuestionsCount })}
            </span>
          </div>
        </div>
        <div>
          <div className='unanswered-chart-container'>
            <div className='unanswered-chart' ref={chartRef} />
            <div className='unanswered-chart-label'>
              <div className='text-3xl bold text-center'>
                {percentage}%
              </div>
              <div className='text-small text-secondary'>{I18N('unanswered_questions.chart_label')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ResponseTime = ({ knowledgeIngestion }) => (
  <div>
    <div className='d-flex gap-4'>
      <div className='d-flex flex-column gap-3'>
        <div className='icon'>
          <CdnSvg src='/images/chatDotsIcon.svg' className='icon' />
        </div>

        <div className='d-flex flex-column'>
          <span className='text-3xl'>
            {I18N('response_time.title.line_one')}
          </span>
          <span className='bold text-3xl'>
            {I18N('response_time.title.line_two', { time: knowledgeIngestion.avgTimeToFirstResponse })}
          </span>
          <span>{I18N('response_time.description')}</span>
        </div>
      </div>
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
          <CdnSvg src='/images/illustrations/hotTermometer.svg' />
          <span className='text-small text-secondary'>
            {I18N('response_time.chart_one', { time: knowledgeIngestion.avgTimeToFirstResponse })}
          </span>
        </div>

        <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
          <CdnSvg src='/images/illustrations/coldTermometer.svg' />
          <span className='text-small text-secondary'>
            {I18N('response_time.chart_two')}
          </span>
        </div>
      </div>
    </div>
  </div>
)

const RepeatedQuestions = ({ knowledgeIngestion }) => (
  <div>
    <div className='d-flex gap-4'>
      <div className='d-flex flex-column gap-3'>
        <div className='icon'>
          <CdnSvg src='/images/chatBubblesIcon.svg' className='icon' />
        </div>

        <div className='d-flex flex-column'>
          <span className='bold text-3xl'>
            {I18N('repeated_questions.title.line_one', { count: knowledgeIngestion.repeatedQuestionsCount })}
          </span>
          <span className='text-3xl'>
            {I18N('repeated_questions.title.line_two')}
          </span>
        </div>
      </div>
      <div>
        <CdnSvg src='/images/illustrations/manWithLetter.svg' />
      </div>
    </div>
  </div>
)

const TopThreeTopics = ({ knowledgeIngestion }) => (
  <div>
    <div className='d-flex gap-4'>
      <div className='d-flex flex-column gap-3'>
        <div className='icon'>
          <CdnSvg src='/images/chatBubblesIcon.svg' className='icon' />
        </div>

        <div className='d-flex flex-column gap-2'>
          <span className='text-3xl'>
            {i18nFormat(I18N('top_three_topics.title'), <span className='bold'>top 3</span>)}
          </span>
          <span className='text-primary text-large'>
            {knowledgeIngestion.trendingTopics.map(t => t).join(', ')}
          </span>
        </div>
      </div>
      <div>
        <CdnSvg src='/images/illustrations/podium.svg' className='podium' />
      </div>
    </div>
  </div>
)

const SavedHours = ({ knowledgeIngestion }) => (
  <div>
    <div className='d-flex gap-4'>
      <div className='d-flex flex-column gap-3'>
        <div className='icon'>
          <CdnSvg src='/images/clockIcon.svg' className='icon' />
        </div>

        <div className='d-flex flex-column'>
          <span className='text-3xl'>
            {i18nFormat(
              I18N('saved_hours.title'),
              <span className='bold'>{I18N('saved_hours.helper', { count: knowledgeIngestion.savedTime })}</span>
            )}
          </span>
        </div>
      </div>
      <div>
        <CdnSvg src='/images/illustrations/manAndHourGlass.svg' />
      </div>
    </div>
  </div>
)

const TalkToExpert = () => (
  <div>
    <div className='d-flex gap-4'>
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex flex-column gap-2'>
          <h1 className='text-3xl m-0 link-color'>
            {I18N('talk_to_expert.title')}
          </h1>
          <p className='text-normal m-0'>
            {I18N('talk_to_expert.description')}
          </p>
        </div>
        <div className='BookReview flex-column d-flex gap-3'>
          <div className='d-flex gap-2'>
            <div>
              <img src='/images/thomas.png' alt='Thomas' />
            </div>
            <div className='d-flex flex-column align-items-start'>
              <h3 className='text-normal m-0'>Thomas Kunjappu</h3>
              <p className='text-secondary text-small m-0'>{I18N('talk_to_expert.ceo')}</p>
            </div>
          </div>
          <div>
            <a
              href='https://landing.gocleary.com/meetings/thomask-cleary/cleary-ai-people-ops-pilot-intro-'
              target='_blank'
              rel='noopener noreferrer'
              className='btn btn-primary'
            >
              {I18N('talk_to_expert.cta')}
            </a>
          </div>
        </div>
      </div>
      <div>
        <CdnSvg src='/images/illustrations/peopleInAMeeting.svg' />
      </div>
    </div>
  </div>
)

const Slider = ({ knowledgeIngestion }) => (
  <ClearySlider className='AiPlatformKnowledgeIngestionPage__slider px-5 py-5 card-shadow'>
    {knowledgeIngestion.questionsCount > 0 && (
      <TotalQuestions knowledgeIngestion={knowledgeIngestion} />
    )}

    {knowledgeIngestion.unansweredQuestionsCount > 5 && (
      <UnansweredQuestions knowledgeIngestion={knowledgeIngestion} />
    )}

    {knowledgeIngestion.faqsCreatedCount > 0 && (
      <ResponseTime knowledgeIngestion={knowledgeIngestion} />
    )}

    {knowledgeIngestion.repeatedQuestionsCount > 5 && (
      <RepeatedQuestions knowledgeIngestion={knowledgeIngestion} />
    )}

    {knowledgeIngestion.trendingTopics.length > 0 && (
      <TopThreeTopics knowledgeIngestion={knowledgeIngestion} />
    )}

    {knowledgeIngestion.savedTime > 8 && (
      <SavedHours knowledgeIngestion={knowledgeIngestion} />
    )}

    <TalkToExpert />
  </ClearySlider>
)

export default Slider
