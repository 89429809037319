import React, { useState } from 'react'

import { Button, ButtonLarge } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import ShoutoutModal from 'components/shoutouts/shoutoutModal'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.shoutouts')

const SendShoutoutButton = ({ user = null } : { user: any }) => {
  const currentUser = useCurrentUser()
  const isCurrentUser = currentUser?.id === user?.id
  const { settings } = useCurrentCompany()

  const [showShoutoutsModal, setShowShoutoutsModal] = useState(false)

  if (isCurrentUser || !settings.shoutouts.enabled) return null

  const ButtonComponent = user ? Button : ButtonLarge

  return (
    <>
      <ButtonComponent
        onClick={() => setShowShoutoutsModal(true)}
        variant={user ? 'secondary' : 'primary'}
      >
        {user ? I18N('send_shoutout_to_user', { preferred_name: user.preferredName }) : I18N('send_a_shoutout')}
      </ButtonComponent>

      {showShoutoutsModal && (
        <ShoutoutModal
          visible={showShoutoutsModal}
          closeModal={() => setShowShoutoutsModal(false)}
          user={user}
        />
      )}
    </>
  )
}

export default SendShoutoutButton
