import React from 'react'
import CdnSvg from 'components/common/cdnSvg'

interface Props {
  icon: string
  label: string
  value: string
}

const HeaderDataItem = ({ icon, label, value }: Props) => (
  <div className='data-item'>
    <div>
      <div className='icon'>
        <CdnSvg src={icon} />
      </div>
    </div>
    <div className='data-value'>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  </div>
)

export default HeaderDataItem
