import React, { useState } from 'react'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const pencilIconPath = '/images/pencilIcon.svg'

const EditableTagContent = ({
  canEdit = false,
  displayElement,
  inputElement,
  className,
  handleBlur,
  isEmpty = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const onClickDisplayElement = () => {
    if (canEdit) {
      setIsEditing(true)
    }
  }

  const onBlur = () => {
    handleBlur()
    setIsEditing(false)
  }

  if (!isEditing) {
    return (
      <span
        className={classNames('EditableContent-Display', { canEdit, isEmpty })}
        onClick={onClickDisplayElement}
      >
        {displayElement}
        <CdnSvg src={pencilIconPath} className='pencilIcon ml-1' />
      </span>
    )
  }

  return (
    <>
      <span className={classNames('EditableContent', className)}>
        {React.cloneElement(inputElement, {
          autoFocus: true,
          onBlur,
        })}
      </span>
    </>
  )
}

export default EditableTagContent
