import useQueryParams from 'components/common/hooks/useQueryParams'
import {
  MutableRefObject,
  useEffect, useMemo, useRef
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

interface UseQueryParamStateProps<T> {
  ref?: MutableRefObject<string>
  param: string
  initialValue: T
  asJson?: boolean
}


const useQueryParamState = function <T> ({
  ref: propRef,
  param,
  initialValue,
  asJson,
}: UseQueryParamStateProps<T>): [T, (newValue?: T) => void] {
  const queryParams = useQueryParams()
  const history = useHistory()
  const internalRef = useRef<string>('')

  const location = useLocation()

  const ref = propRef || internalRef

  useEffect(() => {
    ref.current = location.search
  }, [location])

  const rawValue = queryParams[param]

  const value: T = useMemo(() => {
    if (!rawValue) { return initialValue }

    if (!asJson) { return rawValue }

    try {
      return JSON.parse(rawValue)
    } catch (error) {
      return initialValue
    }
  }, [rawValue])

  const updateQueryParam = (param: string, value: string) => {
    const searchParams = new URLSearchParams(ref.current)

    if (value) {
      searchParams.set(param, value)
    } else {
      searchParams.delete(param)
    }

    ref.current = searchParams.toString()

    // Replace the current URL with the updated query parameter
    history.push({ search: searchParams.toString() })
  }

  const setValue = (newValue: T) => {
    const parsedValueString = asJson ? JSON.stringify(newValue) : newValue as string

    updateQueryParam(param, parsedValueString)
  }

  return [value, setValue]
}

export default useQueryParamState
