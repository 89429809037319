import React, { useState } from 'react'
import classNames from 'classnames'
import LoadingSpinner from 'components/common/loadingSpinner'
import PaginationContainer from 'components/common/tables/paginationContainer'
import Table from './table'

interface SmartTableProps {
  data?: any[]
  columns: any[],
  page?: number
  pages?: number
  totalCount?: number
  perPage?: number,
  showPagination?: boolean,
  onPaginationClick?: (data: { page: number, sortBy: string, sortDir: string }) => void
  onSortChange?: (data: { page: number, sortBy: string, sortDir: string }) => void
  sortKey?: string,
  sortedAsc?: boolean,
  children?: any,
  className?: string,
  noResultsText?: string,
  hideWhenEmpty?: boolean,
  isLoading?: boolean
  rowClassName?: (row: any) => string,
  hideHeaders?: boolean,
}

const SmartTable = ({
  data = [],
  columns,
  page = 1,
  pages = 1,
  perPage = undefined,
  totalCount = 0,
  showPagination = true,
  sortKey = '',
  sortedAsc = false,
  isLoading = false,
  hideWhenEmpty = false,
  onPaginationClick = undefined,
  onSortChange = undefined,
  className = undefined,
  rowClassName = undefined,
  noResultsText = undefined,
  hideHeaders = undefined,
  children = null,
}: SmartTableProps) => {
  const [sort, setSort] = useState({ sortKey, sortedAsc })

  const handleSortClick = (newSortKey) => {
    if (!newSortKey) {
      return
    }

    const sortAsc = sort.sortKey === newSortKey ? !sort.sortedAsc : true

    setSort({ sortKey: newSortKey, sortedAsc: sortAsc })

    if (onSortChange) {
      onSortChange({
        sortBy: newSortKey,
        sortDir: sortAsc ? 'asc' : 'desc',
        page: 1,
      })
    }
  }

  const handlePaginationClick = (page) => {
    if (onPaginationClick) {
      onPaginationClick({
        page,
        sortBy: sort.sortKey,
        sortDir: sort.sortedAsc ? 'asc' : 'desc',
      })
    }
  }

  if (hideWhenEmpty && data.length === 0) {
    return null
  }

  return (
    <>
      {isLoading && <LoadingSpinner className='position-absolute my-5' />}
      <div key='filters'>{children}</div>
      <Table
        data={data}
        columns={columns}
        handleSortClick={handleSortClick}
        sortKey={sort.sortKey}
        sortedAsc={sort.sortedAsc}
        className={classNames(className, 'table table-sm', { TableDataLoading: isLoading })}
        noResultsText={noResultsText}
        rowClassName={rowClassName}
        hideHeaders={hideHeaders}
      />
      {showPagination && (
        <PaginationContainer
          handleClick={handlePaginationClick}
          perPage={perPage}
          page={page}
          numPages={pages}
          totalCount={totalCount}
        />
      )}
    </>
  )
}

export { Table }

export default SmartTable
