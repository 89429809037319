import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import qnaEventSlice from 'redux/slices/qnaEvents'

import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import classNames from 'classnames'

const EditableEventField = ({
  event, fieldName, placeholder, children,
  inputStyle, addFieldContent, inputComponent,
  marginClassName = 'ml-sm-0 ml-md-4',
}) => {
  const dispatch = useDispatch()
  const inputRef = useRef()

  const { meta } = useSelector(qnaEventSlice.selectors.getEditEventPageData(event.id))

  const [isEditing, setIsEditing] = useState(false)
  const [updatedEvent, setUpdatedEvent] = useState(null)
  const [inputValue, setInputValue] = useState(null)
  const [isHovered, setIsHovered] = useState(false)

  const { isUpdatingField } = meta

  useEffect(() => {
    // when textbox shows up, focus on the input box
    // need to change value to '' and then back to the value
    // in order to get the cursor to show up at the end
    // otherwise the cursor shows up in the beginning
    if (!isEditing && inputRef.current) {
      const elem = inputRef.current
      elem.focus()
      const val = elem.value
      elem.value = ''
      elem.value = val
    }
  }, [isEditing])

  const onStartEditing = () => {
    setInputValue(event[fieldName])
    setUpdatedEvent(event)
    setIsEditing(true)
  }

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleSave = () => {
    setIsEditing(false)
    if (inputValue !== event[fieldName]) {
      dispatch(qnaEventSlice.asyncActions.admin.update(updatedEvent, fieldName))
    }
    setIsHovered(false)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSave()
    }
  }

  useEffect(() => {
    setUpdatedEvent({ ...updatedEvent, [fieldName]: inputValue })
  }, [inputValue])

  return (
    <>
      {isUpdatingField[fieldName] ? (
        <div className={marginClassName}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {isEditing ? (
            <div className={classNames('text-color-primary', marginClassName)}>
              {inputComponent ? (
                inputComponent({
                  value: inputValue,
                  onBlur: handleSave,
                  onChange: (value) => { handleChange({ target: { value } }) },
                })
              ) : (
                <input
                  className='InlineEditInput'
                  placeholder={placeholder}
                  value={inputValue}
                  style={inputStyle}
                  ref={inputRef}
                  onKeyPress={e => handleKeyPress(e)}
                  onChange={e => handleChange(e)}
                  onBlur={() => handleSave()}
                />
              )}
            </div>
          ) : (
            <>
              {/* this takes a children which calls 2 methods
                 in the parent (onStartEditing, setIsHovered)
                 ex: EditSlackChannel */}
              {children(onStartEditing, setIsHovered)}
              {_.isEmpty(event[fieldName]) && (
                <a className={classNames(marginClassName, 'text-color-primary')}
                  onClick={() => onStartEditing()}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {addFieldContent}
                </a>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default EditableEventField
