import React, { ReactElement } from 'react'
import { useCustomStyles, useVariables } from 'pages/admin/custom_styles/context'
import { getPropertyListWithValues } from 'components/admin/customStyles/utils'
import TextTransformControl from './textTransformControl'

const TextTransformSection = (): ReactElement => {
  const { handlePropertyChanged } = useCustomStyles()
  const textTransform = useVariables('textTransform')
  const textTransformationVariables = getPropertyListWithValues({ variables: textTransform, type: 'textTransform' })

  return (
    <div className='mt-3'>
      {textTransformationVariables.map(([variable, value]) => (
        <TextTransformControl
          key={variable}
          variable={variable}
          value={value}
          onChange={handlePropertyChanged}
        />
      ))}
    </div>
  )
}

export default TextTransformSection
