import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import { isAudienceValid } from 'utils/audience'

import ArticleTypeForm, { defaultFormState } from 'components/admin/articleTypes/articleTypeForm'
import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { buildArticleTypePayload } from 'redux/slices/articleTypes'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.article_type_editor')

const CreateArticleTypePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [articleTypeForm, setArticleTypeForm] = useState(defaultFormState)

  const targetingOptions = useTargetingOptions()

  const [createArticleType, { isLoading: isSaving, error }] = useApi(API.admin.articleTypes.create, {
    onSuccess: (response) => { history.push(`/admin/article_types/${response.id}`) },
    toastSuccessMessage: I18N('article_type_created'),
  })

  const changesPresent = !_.isEqual(defaultFormState, articleTypeForm)
  const isSaveButtonDisabled = !changesPresent || isSaving || !isAudienceValid(articleTypeForm)

  const saveArticleType = () => createArticleType(buildArticleTypePayload(articleTypeForm))

  return (
    <div className='CreateArticleTypePage'>
      <header className='AdminHeader'>
        <h3>{I18N('new_title')}</h3>
      </header>

      <main className='AdminContent'>
        <div className='row'>
          <ArticleTypeForm
            error={error}
            setArticleType={setArticleTypeForm}
            articleType={articleTypeForm}
            targetingOptions={targetingOptions}
          />
        </div>

        <div className='row'>
          <div className='form-group col'>
            <Button disabled={isSaveButtonDisabled} onClick={saveArticleType} showLoadingSpinner={isSaving}>
              {I18N('save_article_type')}
            </Button>
          </div>
        </div>
      </main>

    </div>
  )
}

export default CreateArticleTypePage
