export const DEFAULT_NAVBAR_HEIGHT = 60

// navbar height may include banners or be hidden (z-index: -1)
const navbarHeight = () => {
  const navbarEl = document.querySelector('nav.Navbar') as HTMLElement

  return navbarEl?.offsetHeight || DEFAULT_NAVBAR_HEIGHT
}

export default navbarHeight
