import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import EditableEventField from 'components/qna/event_fields/editableEventField'

import SlackIcon from 'components/icons/slackIcon'
import EditIcon from 'components/icons/editIcon'
import ChatChannel from 'components/common/chatChannel'
import ChatChannelsAsyncSelectForEditableContent from 'components/common/editable_content/chatChannelsAsyncSelectForEditableContent'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import { ButtonSmallNarrow } from 'components/common/buttons'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import TeamsIcon from 'components/icons/teams/teamsIcon'

const I18N = i18nPath('views.qna.events')

const Channels = ({ channels }) => (
  channels.map(channel => (
    <ChatChannel
      key={channel.id}
      className='font-weight-500 text-color-primary align-middle mr-2'
      channel={channel}
    />
  ))
)

const EventChatChannelsSelect = ({
  onChange, value, onBlur, ...props
}) => {
  const { slackEnabled, msTeamEnabled } = useCurrentCompany()

  const autoFocusField = slackEnabled ? 'slack' : 'ms_team'

  const handleChannelsChange = type => (channels) => {
    onChange([
      ...value.filter(c => c.type !== type),
      ...channels,
    ])
  }

  return (
    <div>
      {slackEnabled && (
        <ChatChannelsAsyncSelect
          id='cy_chat_channel_select_slack'
          value={value.filter(c => c.type === 'Chat::SlackChannel')}
          onChange={handleChannelsChange('Chat::SlackChannel')}
          types={['Chat::SlackChannel']}
          autoFocus={autoFocusField === 'slack'}
          className='mb-2'
          {...props}
        />
      )}

      {msTeamEnabled && (
        <ChatChannelsAsyncSelect
          id='cy_chat_channel_select_ms_team'
          value={value.filter(c => c.type === 'Chat::MsTeamChannel')}
          onChange={handleChannelsChange('Chat::MsTeamChannel')}
          types={['Chat::MsTeamChannel']}
          autoFocus={autoFocusField === 'ms_team'}
          className='mb-2'
          {...props}
        />
      )}

      <ButtonSmallNarrow
        onClick={onBlur}
      >
        {I18n.t('views.common.save')}
      </ButtonSmallNarrow>
    </div>
  )
}

const ChannelsAddFieldContent = () => {
  const { slackEnabled, msTeamEnabled } = useCurrentCompany()

  if (slackEnabled && !msTeamEnabled) {
    return (
      <>
        <SlackIcon height='1rem' width='1rem' fillColor='var(--kebab-menu-icon-color)' />
        <span className='font-weight-500 align-middle ml-2 link-color'>{I18N('add_channels.slack')}</span>
      </>
    )
  }

  if (!slackEnabled && msTeamEnabled) {
    return (
      <>
        <span className='position-relative' style={{ top: '0.1em' }}>
          <TeamsIcon width='1.2rem' fill='var(--kebab-menu-icon-color)' />
        </span>
        <span className='font-weight-500 align-middle ml-2 link-color'>{I18N('add_channels.ms_team')}</span>
      </>
    )
  }

  if (slackEnabled && msTeamEnabled) {
    return (
      <>
        <SlackIcon height='1rem' width='1rem' fillColor='var(--kebab-menu-icon-color)' />
        <span className='position-relative ml-1' style={{ top: '0.1em' }}>
          <TeamsIcon width='1.2rem' fill='var(--kebab-menu-icon-color)' />
        </span>
        <span className='font-weight-500 align-middle ml-2 link-color'>{I18N('add_channels.multiple')}</span>
      </>
    )
  }

  return null
}

const EditChatChannels = ({
  event, channelsToShow,
}) => (
  <EditableEventField
    event={event}
    fieldName='notificationChannels'
    placeholder='#SlackChannel'
    marginClassName=''
    inputComponent={props => (
      <EventChatChannelsSelect {...props} />
    )}
    addFieldContent={<ChannelsAddFieldContent />}
  >
    {(onClickEditIconHandler, setIsHovered) => (
      <>
        {channelsToShow.length > 0 && (
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Channels channels={channelsToShow} />
            <a onClick={() => onClickEditIconHandler()}>
              <EditIcon width='1.5rem' height='1rem' />
            </a>
          </div>
        )}
      </>
    )}
  </EditableEventField>
)

const EventChatChannels = ({ event, canEditEvent }) => {
  // notificationChannels can be undefined for list events when we click on then
  // until the whole event details finishes
  const channels = _.sortBy(event.notificationChannels || [], ['type', 'name'])

  if (canEditEvent) {
    return (
      <EditChatChannels
        event={event}
        channelsToShow={channels}
      />
    )
  }

  if (channels.length === 0) {
    return null
  }

  return (
    <Channels channels={channels} />
  )
}

export default EventChatChannels
