import React, { useState } from 'react'

import Card from 'components/common/card'
import { i18nPath } from 'utils/i18nHelpers'
import TaskableDetailsButtonAndModal from 'components/journeys/modals/taskableDetailsButtonAndModal'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import { useSelector } from 'react-redux'
import entitySlice from 'redux/slices/entities'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import ViewAllLink from 'components/home/widgets/common/viewAllLink'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'
import { PREBOARDING_TASKS_WIDGET_TYPE } from 'utils/layout/widgetsMap'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import PillTabSelector from 'components/common/pillTabSelector'
import { present } from 'components/common/utils'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.home.tasks_widget')

const MY_TASKS = 'my_tasks'
const GROUP_TASKS = 'group_tasks'

const TasksWidget = ({ EmptyState = null, widget, resizeWidget = (id, h) => {} }) => {
  const { preboarding } = useCurrentUser()
  const { groupDisplayName } = useCurrentCompany()
  const [stepIds, setStepIds] = useState([])
  const [groupStepIds, setGroupStepIds] = useState([])
  const [selectedTab, setSelectedTab] = useState(MY_TASKS)

  const { isLoaded } = useFetch(() => API.journey.taskableSteps.fetchAll({
    status: 'pending',
    tasksOwner: MY_TASKS,
    sortBy: 'due_at',
    sortDir: 'asc',
    perPage: 5,
  }), [], {
    addEntitySlice: true,
    onSuccess: steps => setStepIds(steps.map(step => step.id)),
  })

  const { isLoaded: areGroupTasksLoaded } = useFetch(() => API.journey.taskableSteps.fetchAll({
    status: 'pending',
    tasksOwner: GROUP_TASKS,
    sortBy: 'due_at',
    sortDir: 'asc',
    perPage: 5,
  }), [], {
    addEntitySlice: true,
    onSuccess: steps => setGroupStepIds(steps.map(step => step.id)),
  })

  const steps = useSelector(entitySlice.selectors.getEntities('step', stepIds))
  const groupSteps = useSelector(entitySlice.selectors.getEntities('step', groupStepIds))
  const stepsToShow = selectedTab === MY_TASKS ? steps : groupSteps

  const tabs = [
    {
      id: MY_TASKS,
      text: I18N('my_tasks'),
      onClick: () => setSelectedTab(MY_TASKS),
    },
    !_.isEmpty(groupSteps) && {
      id: GROUP_TASKS,
      text: (
        <>
          {I18N('group_tasks', { groupDisplayName })}
          <div className='GroupTasksCount ml-2 px-1'>{groupSteps.length}</div>
        </>
      ),
      onClick: () => setSelectedTab(GROUP_TASKS),
    },
  ].filter(present)

  if (_.isEmpty(steps) && _.isEmpty(groupSteps) && widget.type !== PREBOARDING_TASKS_WIDGET_TYPE) {
    return EmptyState
  }

  const inPreboardingPreview = widget.type === PREBOARDING_TASKS_WIDGET_TYPE && !preboarding
  // This will be true when 'My Tasks' is empty, but there are group tasks to show.
  const showMyTasksEmptyState = (_.isEmpty(steps) && isLoaded && selectedTab === MY_TASKS) || (inPreboardingPreview)

  return (
    <Card className='TasksWidget HomePageWidget'>
      <WidgetTitle title={I18N('title')} />

      <PillTabSelector
        tabs={tabs}
        hideIfOnlyOneTab
        className='mb-2'
      />

      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        // we need to wait for widget to have a height before calculating the new height
        isLoaded={isLoaded && areGroupTasksLoaded && widget.h > 0}
        scrollableContentClassName='TasksGrid pb-1'
        showScrollbarOutside
      >
        {showMyTasksEmptyState ? (
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <CdnSvg className='ClipboardIllustration' src='/images/illustrations/clipboard.svg' />
            <div className='text-center mb-2'>{I18N(inPreboardingPreview ? 'preboarding_empty_state' : 'no_open_tasks')}</div>
          </div>
        ) : (
          stepsToShow.map(step => (
            <TaskableDetailsButtonAndModal
              key={step.id}
              step={step}
              displayMode='icon'
            />
          ))
        )}
      </AutoResizableScrollableSection>

      <ViewAllLink to='/my_tasks' />
    </Card>
  )
}

export default TasksWidget
