import React from 'react'
import DOMPurify from 'dompurify'

const DangerousHTML = ({ children, className = '', options = {} }) => (
  <span
    className={className}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children, options) }}
  />
)

export default DangerousHTML
