import React from 'react'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { KnowledgeIngestionTitle } from 'pages/ai_platform/knowledgeIngestionPage'
import Slider from 'pages/ai_platform/knowledgeIngestionPage/slider'
import Charts from './charts'
import Statistics from './statistics'

const Dashboard = ({
  knowledgeIngestions,
  dashboardData,
  hasDashboardData,
  isLoadedDashboardData,
  periodStart,
  setPeriodStart,
  periodEnd,
  setPeriodEnd,
  timeRange,
  setTimeRange,
  setGroupBy,
}) => {
  if (!isLoadedDashboardData) {
    return <CirclesLoadingIndicator />
  }

  // If we don't have data to show in the dashboard yet, we'll try to get the last knowledge ingestion
  // that has data to show. If there's none, we'll show the first one.
  const knowledgeIngestion = knowledgeIngestions
    .reverse()
    .find(ingestion => ingestion.questionsCount > 0) || knowledgeIngestions[0]

  return (
    hasDashboardData ? (
      <>
        <TabHeader
          periodStart={periodStart}
          setPeriodStart={setPeriodStart}
          periodEnd={periodEnd}
          setPeriodEnd={setPeriodEnd}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          setGroupBy={setGroupBy}
        />
        <Statistics dashboardData={dashboardData} />
        <Charts dashboardData={dashboardData} />
      </>
    ) : (
      <>
        {knowledgeIngestion && (
          <>
            {knowledgeIngestion.questionsCount > 0 && (
              <KnowledgeIngestionTitle knowledgeIngestion={knowledgeIngestion} />
            )}
            <Slider knowledgeIngestion={knowledgeIngestion} />
          </>
        )}
      </>
    )
  )
}

export default Dashboard
