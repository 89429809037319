import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import API from 'services/api'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import useApi from 'components/common/hooks/useApi'
import Workspaces from 'components/pages/workspaces'
import FocusedWorkspace from 'components/pages/focusedWorkspace'
import useCurrentPage from 'components/pages/hooks/useCurrentPage'
import PagesNavContext from './pagesNav/context'
import useExpandingResources from './pagesNav/useExpandingResources'

const PagesNav = ({
  isMobileNavOpen = false,
  isDesktopNavOpen = true,
  navigateToRoot = () => {},
}) => {
  const dispatch = useDispatch()
  const { workspaceSlug, pageSlug, pageId } = useParams()
  const currentPage = useCurrentPage()
  const pageWorkspaces = useSelector(pageWorkspaceSlice.selectors.getPageWorkspaces())
  const { isLoadingAllWorkspaces } = useSelector(pageWorkspaceSlice.selectors.getMetaData())
  const { startExpanding, finishExpanding, showExpandingState } = useExpandingResources()

  const [navigateTo, { isLoading: isNavigating }] = useApi(API.pagesNavigation.navigate, {
    addEntitySlice: true,
  })

  const [expandNavigation] = useApi(API.pagesNavigation.expand, {
    addEntitySlice: true,
  })

  const isWorkspaceFocused = !!workspaceSlug
  const focusedWorkspace = pageWorkspaces.find(pw => pw.slug === workspaceSlug)
  const isFinishedLoading = !isLoadingAllWorkspaces

  const expandResource = async (type, id) => {
    startExpanding(type, id)
    await expandNavigation(type, id)
    finishExpanding(type, id)
  }

  const fetchNavigation = (movingPageSlugOrId = null) => {
    if (movingPageSlugOrId || pageSlug || pageId) {
      navigateTo(movingPageSlugOrId || pageSlug || pageId)
    } else if (isWorkspaceFocused) {
      expandResource('workspace', workspaceSlug)
    } else {
      navigateToRoot()
    }
  }

  const isDraggingRef = useRef(false)

  const contextValue = {
    isLoading: isNavigating,
    showExpandingState,
    expandResource,
    fetchNavigation,
    isDraggingRef,
  }

  useEffect(async () => {
    fetchNavigation()

    dispatch(pageWorkspaceSlice.asyncActions.fetchAll())
  }, [])

  return (
    <PagesNavContext.Provider value={contextValue}>
      <nav
        className={
          classNames(
            'LeftNav PagesNav',
            { isMobileNavOpen, isDesktopNavOpen }
          )
        }
      >
        {isFinishedLoading && (
          <FocusedWorkspace
            currentPage={currentPage}
            focusedWorkspace={focusedWorkspace}
            isWorkspaceFocused={isWorkspaceFocused}
          />
        )}

        <Workspaces
          currentPage={currentPage}
          isWorkspaceFocused={isWorkspaceFocused}
          allWorkspaces={pageWorkspaces}
        />
      </nav>
    </PagesNavContext.Provider>
  )
}

export default PagesNav
