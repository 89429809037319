import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import { I18NCommon } from 'utils/i18nHelpers'
import StepDeleteModal from 'components/admin/journeys/modals/stepDeleteModal'
import isStepTemplateValid from 'utils/admin/journeys/isStepTemplateValid'
import SaveTemplateButton, { SaveDraftTemplateButton } from 'pages/admin/journeys/step_templates/saveTemplateButton'

const StepTemplateFormButtonsContainer = ({
  onSave,
  isSaving = false,
  workingCopy,
  currentState = 'draft',
  isStepTemplate = true,
  areChangesPresent = true,
  isDisabled = false,
  forUser,
  nonRequiredFields = [],
  postTriggerNames = [],
  stepType = 'communicaton',
}) => {
  const { id } = useParams()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const isDraft = currentState === 'draft'
  const { isValid, invalidFields } = isStepTemplateValid({
    workingCopy,
    nonRequiredFields,
    isStepTemplate,
    isDraft,
    postTriggerNames,
  })

  return (
    <div className={`StepTemplateFormButtonsContainer mt-3 buttons-container ${isDisabled ? 'd-none' : 'd-flex'}`}>
      <div className='d-flex right-buttons-container'>
        {isStepTemplate && isDraft && (
          <SaveDraftTemplateButton
            onSave={onSave}
            isValid={isValid}
            isDraft={isDraft}
            areChangesPresent={areChangesPresent}
            isSaving={isSaving}
            invalidFields={invalidFields}
          />
        )}

        <SaveTemplateButton
          onSave={onSave}
          isValid={isValid}
          isDraft={isDraft}
          areChangesPresent={areChangesPresent}
          isSaving={isSaving}
          invalidFields={invalidFields}
        />
      </div>
      {id && !isStepTemplate && (
        <>
          <Button
            variant='secondary-danger'
            onClick={() => setIsDeleteModalOpen(true)}
          >
            {I18NCommon('delete')}
          </Button>

          <StepDeleteModal
            stepType={stepType}
            visible={isDeleteModalOpen}
            forUser={forUser}
            toggle={() => setIsDeleteModalOpen(false)}
          />
        </>
      )}
    </div>
  )
}

export default StepTemplateFormButtonsContainer
