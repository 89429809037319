import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import AccessLevelToggleGroup from 'components/common/access_level_toggle_group'
import AudienceDropdownSelector from 'components/common/audience_dropdown_selector'

const I18N = i18nPath('views.qna.events')

const AudienceSettings = ({
  workingCopy,
  setWorkingCopy,
  targetingOptions,
  setIsSaveButtonDisabled,
  colleaguesCount,
}) => (
  <div className='AudienceSettings pb-2'>
    <AccessLevelToggleGroup
      onChange={level => setWorkingCopy({ ...workingCopy, accessLevel: level })}
      selectedValue={workingCopy.accessLevel}
      className='mt-3 mb-5'
      guidanceText={I18N('access_level_toggle.who_can_participate')}
      publicDescription={I18N('access_level_toggle.everyone_description')}
      privateDescription={I18N('access_level_toggle.invite_only_description')}
    />
    {workingCopy?.targetingRules && (
      <>
        <label>{I18N('settings_modal.audience_settings.target_audience_options')}</label>
        <span className='d-block text-small text-secondary mb-3'>
          {I18N('settings_modal.audience_settings.target_audience_description')}
        </span>
        <AudienceDropdownSelector
          targetingRules={workingCopy?.targetingRules || {}}
          updateWorkingCopy={values => setWorkingCopy({ ...workingCopy, ...values })}
          targetingOptions={targetingOptions}
          setDisableButton={isButtonDisabled => setIsSaveButtonDisabled(isButtonDisabled)}
          accessLevel={workingCopy.accessLevel}
          colleaguesCount={colleaguesCount}
        />
      </>
    )}
  </div>
)

export default AudienceSettings
