import React from 'react'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import CdnSvg from 'components/common/cdnSvg'

const personsIconPath = '/images/personsIcon.svg'

const UsersList = ({
  users,
  size = '25px',
}) => users?.map(user => (
  <EmployeeThumbnailPhoto key={user.id} employee={user} size={size} className='thumbnailUserImage' />
))

interface Props {
  users: any[]
  shownAvatars?: number
  avatarSize?: string
}

const RoundedSlackAvatars = ({
  users = [],
  shownAvatars = 3,
  avatarSize = '25px',
}: Props) => {
  const totalUserCount = users.length
  const shownUsers = users.slice(0, shownAvatars)
  const remainingUsersCount = Math.min(totalUserCount - shownAvatars, 99)

  return (
    <div className='RoundedSlackAvatars slack d-flex align-items-center'>
      {totalUserCount === 0 ? (
        <span className='persons-icon-wrapper'
          style={{
            width: avatarSize,
            height: avatarSize,
          }}
        >
          <CdnSvg src={personsIconPath} />
        </span>
      ) : (
        <UsersList
          users={shownUsers}
          size={avatarSize}
        />
      )}

      {remainingUsersCount > 0 && (
        <div
          className='plusUsersContainer'
          style={{ height: avatarSize }}
        >
          +{remainingUsersCount}
        </div>
      )}
    </div>
  )
}

export default RoundedSlackAvatars
