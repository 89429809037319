import React from 'react'
import { useDispatch } from 'react-redux'
import groupSlice from 'redux/slices/groups'
import Description from 'components/groups/description'

import MainInfo from 'components/groups/mainInfo'
import MembershipActions from 'components/groups/membershipActions'
import Hierarchy from 'components/groups/hierarchy'

const GroupInfo = ({ group }) => {
  const dispatch = useDispatch()

  const updateGroup = async (data) => {
    await dispatch(groupSlice.asyncActions.update({ ...group, ...data }))
  }

  return (
    <>
      <MainInfo group={group} updateGroup={updateGroup} />
      <MembershipActions group={group} />
      <div className='description-hierarchy-container'>
        <Description group={group} updateGroup={updateGroup} />
        <Hierarchy group={group} />
      </div>
    </>
  )
}

export default GroupInfo
