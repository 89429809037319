import React, { useEffect, useState } from 'react'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import taskTemplateSlice from 'redux/slices/journeys/taskTemplates'
import { useDispatch } from 'react-redux'
import { defaultStepTemplateAttributes, defaultTaskableTemplateAttributes, DEFAULT_TO_CORRESPONDENT_ROLE } from 'utils/journeys/templatesDefaultHelpers'
import BetaRibbon from 'components/common/tags/betaRibbon'
import PrefilledStepTemplateSelector from 'components/admin/journeys/modals/prefilledStepTemplateSelector'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.journeys.steps.new_step_type_modal')

const CREATE_FROM_SCRATCH = 'create_from_scratch'

const stepTypeMapping = {
  'communications': {
    label: I18N('communication'),
    iconPath: '/images/writeIcon.svg',
    stepTemplateType: 'communication_templates',
    stepType: 'communications',
    showNameInput: true,
  },
  'tasks': {
    label: I18N('task'),
    iconPath: '/images/checkListIcon.svg',
    stepTemplateType: 'task_templates',
    stepType: 'tasks',
    showNameInput: true,
  },
  'surveys': {
    label: I18N('survey'),
    iconPath: '/images/question_mark.svg',
    stepTemplateType: 'survey_templates',
    stepType: 'surveys',
    showTemplateSelector: true,
    showNameInput: true,
    shouldCreateOnNextClick: true,
  },
  'check-ins': {
    label: I18N('check_in'),
    iconPath: '/images/teaCupIcon.svg',
    stepTemplateType: 'check_in_templates',
    showNameInput: true,
  },
  'introductions': {
    label: I18N('introduction'),
    iconPath: '/images/happyFaceIcon.svg',
    stepTemplateType: 'introduction_templates',
    stepType: 'introductions',
    showNameInput: false,
    needsCalendarIntegration: true,
    excludedJourneyBlueprintTypes: ['offboarding'],
  },
  'trainings': {
    label: I18N('training'),
    iconPath: '/images/lightBulbIcon.svg',
    stepTemplateType: 'training_templates',
    showNameInput: true,
  },
  'calendarEvents': {
    label: I18N('calendar_event'),
    iconPath: '/images/calendar.svg',
    stepTemplateType: 'calendar_event_templates',
    stepType: 'calendar_events',
    showNameInput: true,
    needsCalendarIntegration: true,
    excludedJourneyBlueprintTypes: ['offboarding'],
  },
  'meetings': {
    label: {
      default: I18N('meeting'),
      offboarding: I18N('exit_interview'),
    },
    iconPath: '/images/calendar.svg',
    stepTemplateType: 'meeting_templates',
    stepType: 'meetings',
    showNameInput: true,
    needsCalendarIntegration: true,
  },
  'buddyAssignment': {
    label: I18N('buddy_assignment'),
    iconPath: '/images/personsIcon.svg',
    stepTemplateType: 'buddy_assignment_templates',
    stepType: 'buddy_assignments',
    showNameInput: true,
    excludedJourneyBlueprintTypes: ['offboarding', 'custom'],
  },
  'slackMessages': {
    label: I18N('slack_message'),
    iconPath: '/images/slackLogoIcon.svg',
    stepTemplateType: 'slack_messages',
    showNameInput: true,
  },
}

const defaultOrder = ['communications', 'tasks', 'calendarEvents', 'surveys', 'introductions', 'meetings', 'buddyAssignment']

const getLabelName = (labelInfo, journeyBlueprintType) => {
  if (typeof labelInfo === 'string') {
    return labelInfo
  }

  return labelInfo[journeyBlueprintType] || labelInfo.default
}

const StepTypeOption = ({
  type,
  isSelected,
  onClick,
  journeyBlueprintType,
  isDisabled = false,
}) => {
  if (Object.keys(stepTypeMapping).includes(type)) {
    const typeInfo = stepTypeMapping[type]
    return (
      <span
        className={classNames('step-type-container', isSelected ? 'active' : '', isDisabled ? 'cursor-not-allowed' : 'pointer')}
        onClick={isDisabled ? null : () => onClick(type)}
      >
        <CdnSvg src={typeInfo.iconPath} className='ml-1' />
        <span>{getLabelName(typeInfo.label, journeyBlueprintType)}</span>
        {typeInfo.isBetaFeature && <BetaRibbon />}
      </span>
    )
  }
  return null
}


const CreateStepTypeSelectModal = ({
  className = '',
  visible,
  toggle,
  journeyBlueprintId,
  journeyBlueprintType,
  isCustomStep = false,
  journeyId = '',
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentCompany = useCurrentCompany()
  const { hasCalendarIntegration = false } = currentCompany

  const journeySettings = currentCompany.settings.journeys
  const stepTypesSettings = journeySettings.stepTypes
  const [selectedStepType, setSelectedStepType] = useState('')
  const [stepName, setStepName] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(CREATE_FROM_SCRATCH)
  const [isCreating, setIsCreating] = useState(false)

  const checkIfStepTypeIsEnabled = (stepType) => {
    const mapping = stepTypeMapping[stepType]

    if (stepType === 'surveys' && (!currentCompany.settings.surveys.enabled || isCustomStep)) {
      return false
    }

    if (mapping?.needsCalendarIntegration && !hasCalendarIntegration) {
      return false
    }

    if (mapping?.excludedJourneyBlueprintTypes?.includes(journeyBlueprintType)) {
      return false
    }

    if (stepType === 'buddyAssignment' && !currentCompany.settings.journeys.onboardingBuddies.enabled) {
      return false
    }

    return stepTypesSettings[stepType].enabled
  }

  // eslint-disable-next-line max-len

  const sortedTypes = _.sortBy(Object.keys(stepTypesSettings), t => defaultOrder.indexOf(t), 'asc')
  const enabledTypes = sortedTypes.filter(stepType => checkIfStepTypeIsEnabled(stepType))
  const showTemplateSelector = stepTypeMapping[selectedStepType]?.showTemplateSelector
  const showNameInput = stepTypeMapping[selectedStepType]?.showNameInput && selectedTemplate === CREATE_FROM_SCRATCH

  const resetValues = () => {
    setSelectedStepType('')
    setStepName('')
  }

  useEffect(() => {
    if (visible && enabledTypes.length === 1 && !selectedStepType) setSelectedStepType(enabledTypes[0])
  }, [visible])


  const closeModal = () => {
    resetValues()
    toggle()
  }

  const getRedirectPath = (id = null) => {
    const mappedStepType = stepTypeMapping[selectedStepType]
    const stepOrTemplateType = mappedStepType[isCustomStep ? 'stepType' : 'stepTemplateType']

    if (id) {
      return history.push(`/admin/journeys/${journeyBlueprintId}/${stepOrTemplateType}/${id}`)
    }

    let path
    if (isCustomStep) {
      path = `/admin/journey_blueprints/${journeyBlueprintId}/journeys/${journeyId}/${stepOrTemplateType}/new`
    } else {
      path = `/admin/journeys/${journeyBlueprintId}/${stepOrTemplateType}/new`
    }

    if (showNameInput) {
      return `${path}?name=${encodeURIComponent(stepName)}`
    } else {
      return path
    }
  }

  const redirectToCreatePage = (id = null) => {
    history.push(getRedirectPath(id))
    resetValues()
  }

  const onCreateSucess = (id) => {
    setIsCreating(false)
    redirectToCreatePage(id)
  }

  const createStep = () => {
    setIsCreating(true)

    if (selectedStepType === 'surveys') {
      const params = {
        ...defaultTaskableTemplateAttributes(journeyBlueprintType),
        stepTemplateAttributes: {
          ...defaultStepTemplateAttributes(journeyBlueprintType),
          name: stepName,
          toCorrespondentAttributes: { role: DEFAULT_TO_CORRESPONDENT_ROLE[journeyBlueprintType] },
          journeyBlueprintId,
        },
        name: stepName,
        taskableType: 'Survey::Form',
      }

      if (selectedTemplate !== CREATE_FROM_SCRATCH) {
        params.prefilledTemplateId = selectedTemplate
      }

      dispatch(taskTemplateSlice.asyncActions.admin.createTaskTemplate(params, onCreateSucess))
    }
  }

  const onNextClick = () => {
    if (stepTypeMapping[selectedStepType].shouldCreateOnNextClick) {
      createStep()
    } else {
      redirectToCreatePage()
    }
  }

  return (
    <Modal size='lg' className={classNames('CreateStepTypeSelectModal', className)} visible={visible} toggle={closeModal}>
      <h4 className='mb-3 text-left'>{I18N('create_a_new_step')}</h4>
      <p className='text-left text-normal font-weight-600'>{I18N('step_types')}</p>
      <div className='mb-5 step-types'>
        {
          enabledTypes.map(type => (
            <StepTypeOption
              key={type}
              type={type}
              isSelected={selectedStepType === type}
              isDisabled={isCreating}
              onClick={setSelectedStepType}
              journeyBlueprintType={journeyBlueprintType}
            />
          )
          )
        }
      </div>
      {showTemplateSelector && (
        <PrefilledStepTemplateSelector
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          selectedStepType={selectedStepType}
          journeyBlueprintType={journeyBlueprintType}
        />
      )}
      {showNameInput && (
        <>
          <p className='text-left text-normal font-weight-600 mb-1'>{I18N('what_are_you_creating')}</p>
          <input
            value={stepName}
            className='form-control'
            onChange={e => setStepName(e.target.value)}
            placeholder={I18N('placeholder_example')}
          />
        </>
      )}
      <div className='d-flex justify-content-end align-items-center'>
        <div role='button' tabIndex={0} onClick={closeModal} className='mr-3 text-muted-dark'>
          {I18NCommon('cancel')}
        </div>
        <Button
          className='my-4'
          disabled={!selectedStepType || (!stepName && showNameInput) || isCreating}
          showLoadingSpinner={isCreating}
          onClick={onNextClick}
        >
          {I18NCommon('next')}
        </Button>
      </div>
    </Modal>
  )
}

export default CreateStepTypeSelectModal
