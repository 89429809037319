import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { OverlayTrigger, Popover } from 'react-bootstrap'
import { i18nPath } from 'utils/i18nHelpers'
import AdditionalInformationModal from 'components/feedback/additionalInformationModal'
import feedbackSlice from 'redux/slices/feedback'
import CdnSvg from 'components/common/cdnSvg'

const thumbsUpIconPath = '/images/thumbsUpIcon.svg'
const thumbsDownIconPath = '/images/thumbsDownIcon.svg'

const I18N = i18nPath('views.feedback_counter')

export const helpfulParams = {
  helpful: true,
  incomplete: false,
  difficultToUnderstand: false,
  inaccurateOrIrrelevant: false,
  missingOrBrokenLinks: false,
  comment: null,
}

interface VoteOptionProps {
  id: string
  iconPath: string
  count: number
  overlay: any
  onClick: () => void
  classname?: string
  showCount?: boolean
}

const VoteOption = ({
  id,
  iconPath,
  count,
  overlay,
  onClick,
  classname,
  showCount = true,
}: VoteOptionProps) => (
  <div className={classNames(classname, 'd-flex align-items-center')}>
    {showCount && <span className='text-secondary font-weight-400 text-small w-100 text-right'>{count}</span>}
    <OverlayTrigger trigger={['hover', 'focus']} placement='right' overlay={overlay}>
      <div onClick={onClick} id={id} data-testid={id}>
        <CdnSvg src={iconPath} />
      </div>
    </OverlayTrigger>
  </div>
)

interface FeedbackCounterProps {
  feedbackable: {
    id: string
    helpfulFeedbackCount: number
    notHelpfulFeedbackCount: number
    showFeedbackRatings: boolean
    hasUserGivenFeedback: boolean
    isUserFeedbackHelpful: boolean
    currentUserFeedbackId?: string
  }
  feedbackableType: string
  classname?: string
}

const FeedbackCounter = ({
  feedbackable: {
    id,
    helpfulFeedbackCount,
    notHelpfulFeedbackCount,
    showFeedbackRatings,
    currentUserFeedbackId,
    isUserFeedbackHelpful,
    hasUserGivenFeedback,
  },
  feedbackableType,
  classname,
}: FeedbackCounterProps) => {
  const dispatch = useDispatch()

  const [isAdditionalInformationModalOpen, setIsAdditionalInformationModalOpen] = useState(false)

  const createFeedback = (params) => {
    dispatch(feedbackSlice.asyncActions.create({
      feedbackableId: id,
      feedbackableType,
      params,
    }))

    if (!params.helpful) setIsAdditionalInformationModalOpen(true)
  }

  const updateFeedback = (params) => {
    dispatch(feedbackSlice.asyncActions.update({
      feedbackableId: id,
      feedbackableType,
      params: { id: currentUserFeedbackId, ...params },
    }))
  }

  const deleteFeedback = () => {
    dispatch(feedbackSlice.asyncActions.destroy({
      feedbackableId: id,
      feedbackableType,
      feedbackId: currentUserFeedbackId,
    }))
  }

  const onHelpfulClick = () => {
    // User has is clicking same button again -> "unvote"
    if (hasUserGivenFeedback && isUserFeedbackHelpful) {
      deleteFeedback()
    } else {
      createFeedback({ helpful: true })
    }
  }

  const onNotHelpfulClick = () => {
    // User has is clicking same button again -> "unvote"
    if (hasUserGivenFeedback && !isUserFeedbackHelpful) {
      setIsAdditionalInformationModalOpen(false)
      deleteFeedback()
    } else {
      createFeedback({ helpful: false })
    }
  }

  const showCount = showFeedbackRatings && (helpfulFeedbackCount > 0 || notHelpfulFeedbackCount > 0)

  const overlay = useCallback((text: string) => (
    <Popover id={text} className='FeedbackCounterPopover py-2 px-3'>
      <p className='text-smallest text-white m-0'>{text}</p>
    </Popover>
  ), [])

  return (
    <div className={classNames('FeedbackCounter d-flex justify-content-end', classname)}>
      <div className='d-flex flex-column align-items-center'>
        <VoteOption
          id='ThumbsUp'
          iconPath={thumbsUpIconPath}
          count={helpfulFeedbackCount}
          classname={classNames('ThumbsUp', { votedHelpful: hasUserGivenFeedback && isUserFeedbackHelpful })}
          overlay={overlay(I18N('helpful'))}
          onClick={onHelpfulClick}
          showCount={showCount}
        />
        <div className='Divider my-1' />
        <VoteOption
          id='ThumbsDown'
          iconPath={thumbsDownIconPath}
          count={notHelpfulFeedbackCount}
          classname={classNames('ThumbsDown', {
            isAdditionalInformationModalOpen,
            votedNotHelpful: hasUserGivenFeedback && !isUserFeedbackHelpful,
          })}
          overlay={overlay(I18N('not_helpful'))}
          onClick={onNotHelpfulClick}
          showCount={showCount}
        />

        {isAdditionalInformationModalOpen && (
          <AdditionalInformationModal
            target='ThumbsDown'
            isOpen={isAdditionalInformationModalOpen}
            setIsOpen={setIsAdditionalInformationModalOpen}
            updateFeedback={updateFeedback}
          />
        )}
      </div>
    </div>
  )
}

export default FeedbackCounter
