import React from 'react'
import classNames from 'classnames'

interface AsyncSearchInputProps {
  placeholder?: string,
  className?: string,
  minCharsToSearch?: number,
  onKeyUp: (value: string) => void,
  defaultValue?: any,
  icon?: any,
  inputGroupClassName?: string,
  debounceWait?: number
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void
}

const AsyncSearchInput = ({
  placeholder,
  className = 'mb-4',
  minCharsToSearch = 2,
  onKeyUp,
  defaultValue,
  icon,
  inputGroupClassName = '',
  debounceWait = 200,
  onKeyDown,
}: AsyncSearchInputProps) => {
  const debouncedOnKeyUp = _.debounce(value => onKeyUp(value), debounceWait)

  const handleOnKeyUp = (ev) => {
    const { keyCode } = ev
    const { value } = ev.target

    // Ignore enter key
    if (keyCode === 13) return

    // Prevent triggering the search if there are less than min chars
    // But if there is no text at all we need to bring all results
    if (value.length < minCharsToSearch && value.length !== 0) return

    debouncedOnKeyUp(value)
  }

  return (
    <div className={classNames('AsyncSearchInputGroup', inputGroupClassName)}>
      {icon && (
        <div className='AsyncSearchInputIcon'>
          {icon}
        </div>
      )}
      <input
        type='text'
        placeholder={placeholder}
        className={classNames('AsyncSearchInput', className, { hasIcon: !!icon })}
        onKeyUp={handleOnKeyUp}
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}

export default AsyncSearchInput
