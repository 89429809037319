import Article from 'components/articles/article'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Modal from 'components/common/modal'
import React from 'react'
import API from 'services/api'
import { Button, ButtonLink } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.admin.article_editor.templates_sidebar')

const PreviewTemplateModal = ({
  templateId,
  onClose,
  applyTemplate,
  isApplyingTemplate,
}) => {
  const { data: template, isLoading } = useFetch(API.admin.article.templates.fetch, [templateId])

  return (
    <Modal
      visible
      className='PreviewTemplateModal'
      toggle={onClose}
    >
      <div className='pt-3 px-3'>
        <div className='PreviewTemplateModalContent overflow-y-auto'>
          {isLoading || !template ? (
            <CirclesLoadingIndicator />
          ) : (
            <Article
              article={template}
              tracking={false}
              isPreviewing
            />
          )}
        </div>
        <div className='PreviewTemplateModalFooter d-flex align-items-center justify-content-between'>
          <div onClick={onClose} className='mr-3 text-muted-dark pointer'>
            {I18NCommon('close')}
          </div>
          <div>
            <ButtonLink
              to={`/admin/articles/templates/${templateId}`}
              variant='secondary'
              className='mr-3'
            >
              {I18N('edit_template')}
            </ButtonLink>
            <Button
              showLoadingSpinner={isApplyingTemplate}
              disabled={isApplyingTemplate}
              onClick={applyTemplate}
            >
              {I18N('apply_this_template')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PreviewTemplateModal
