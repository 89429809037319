import React, { useEffect, useState, useRef } from 'react'

import classNames from 'classnames'
import { I18NCommon } from 'utils/i18nHelpers'

const TruncatedFadedContent = (props) => {
  const [isContentTruncated, setIsContentTruncated] = useState(true)
  const [doesContentExceedThreshold, setDoesContentExceedThreshold] = useState(false)

  const containerRef = useRef(null)
  // useRef preserves .current value after each render
  // we need this to be able to work with the same value
  // in differente useEffect hooks
  const childComponent = useRef()

  const { children } = props

  const isContentExpanded = !isContentTruncated

  useEffect(() => {
    childComponent.current = containerRef.current.firstChild

    const { height } = childComponent.current.getBoundingClientRect()

    if (height > 210) {
      setDoesContentExceedThreshold(true)
      childComponent.current.style.height = '210px'
      childComponent.current.style.overflow = 'hidden'
    }
  }, [childComponent.current])

  // Takes care toggle state / height sync after the show more
  // button is available
  useEffect(() => {
    if (!doesContentExceedThreshold) return

    if (isContentTruncated) {
      childComponent.current.style.height = '210px'
      childComponent.current.style.overflow = 'hidden'
    } else {
      childComponent.current.style.height = '100%'
      childComponent.current.style.overflow = 'reset'
    }
  }, [isContentTruncated])

  const toggleTruncation = (ev) => {
    ev.preventDefault()
    // we need it to stop bubbling up
    // otherwise clicking on show more triggers
    // onclick handler for the parent
    ev.stopPropagation()
    setIsContentTruncated(!isContentTruncated)
  }

  return (
    <div className='TruncatedFadedContent' ref={containerRef}>
      {children}
      {doesContentExceedThreshold && (
        <div
          className={classNames('Truncation-Overlay d-block text-center', {
            Expanded: isContentExpanded,
          })}
        >
          <a className='position-relative' href='' onClick={toggleTruncation}>
            {isContentExpanded && I18NCommon('show_less')}
            {isContentTruncated && I18NCommon('show_more')}
          </a>
        </div>
      )}
    </div>
  )
}

export default TruncatedFadedContent
