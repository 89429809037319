import { createSlice } from '@reduxjs/toolkit'
import API from 'services/api'
import appSignal from 'services/appSignal'
import build from 'redux-object'
import { trackEvent } from 'services/tracker'
import { extractToastMessage } from 'utils/errorHandling'
import snakeCaseKeysObjectsOnly from 'utils/snakeCaseKeysObjectsOnly'
import toasts from 'redux/slices/toasts'
import entitySlice from './entities'

export const DEFAULT_PAGE_SIZE = 5

export const PAGE_SIZES = {
  dropdown: 5,
  pageAll: 5,
  pageFiltered: 10,
}

export const TOP_RESULTS_PAGE_SIZES = {
  dropdown: 10,
  pageAll: 10,
  pageFiltered: 10,
}

export const baseState = {
  searchTerm: '',
  clearySearchResultIds: [],
  googleSearchResults: [],
  oneDriveSearchResults: [],
  bloomfireSearchResults: [],
  confluenceSearchResults: [],
  notionSearchResults: [],
  meta: {
    isLoading: false,
    isLoadingGoogleDrive: false,
    isLoadingOneDrive: false,
    isLoadingConfluence: false,
    isLoadingBloomfire: false,
    isLoadingNotion: false,
    isNotFound: false,
    totalResults: 0,
    totalResultsByType: {},
    hasMoreByType: {},
    searchStartedAt: null,
  },
}

export const initialState = {
  sessionId: null,
  dropdown: { ...baseState },
  pageAll: { ...baseState },
  pageFiltered: { ...baseState },
}

const EXTERNAL_TYPES = ['google_drive', 'confluence', 'notion', 'bloomfire', 'one_drive']

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    isLoading(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].meta.isLoading = data
    },
    isLoadingGoogleDrive(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].meta.isLoadingGoogleDrive = data
    },
    isLoadingOneDrive(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].meta.isLoadingOneDrive = data
    },
    isLoadingBloomfire(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].meta.isLoadingBloomfire = data
    },
    isLoadingConfluence(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].meta.isLoadingConfluence = data
    },
    isLoadingNotion(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].meta.isLoadingNotion = data
    },
    isNotFound(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].meta.isNotFound = data
    },
    setSearchTerm(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].searchTerm = data
    },
    setGoogleSearchResults(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].googleSearchResults = data
    },
    setOneDriveSearchResults(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].oneDriveSearchResults = data
    },
    setBloomfireSearchResults(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].bloomfireSearchResults = data
    },
    setConfluenceSearchResults(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].confluenceSearchResults = data
    },
    setNotionSearchResults(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].notionSearchResults = data
    },
    setSearchStartedAt(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].meta.searchStartedAt = data
    },
    setClearySearchResultIds(state, action) {
      const { searchLocation, data } = action.payload
      state[searchLocation].clearySearchResultIds = data
    },
    setTotalResults(state, action) {
      const { searchLocation, data } = action.payload
      const currentCount = state[searchLocation].meta.totalResults

      state[searchLocation].meta.totalResults = currentCount + data
    },
    setTotalResultsByType(state, action) {
      const { searchLocation, data } = action.payload
      const currentTotalResultsBytype = state[searchLocation].meta.totalResultsByType
      state[searchLocation].meta.totalResultsByType = { ...currentTotalResultsBytype, ...data }
    },
    setHasMoreByType(state, action) {
      const { searchLocation, data } = action.payload
      const currentHasMore = state[searchLocation].meta.hasMoreByType
      state[searchLocation].meta.hasMoreByType = { ...currentHasMore, ...data }
    },
    reset(state, action) {
      const { searchLocation } = action.payload
      state[searchLocation] = { ...baseState }
    },
    setSessionId(state, action) {
      state.sessionId = action.payload
    },
  },
})

const asyncActions = {
  // searchLocation refers to the component that is doing the search, it can either be 'dropdown', 'pageAll' or 'pageFiltered'
  // searchOrigin is used for Amplitude tracking, and it reflects what the user was doing that triggered the search
  // it can be: 'dropdown', 'results_page', or 'golink_not_found'
  fetchResults: ({
    query,
    type,
    searchGoogleDrive,
    searchConfluence,
    searchBloomfire,
    searchNotion,
    searchOneDrive,
    searchLocation,
    searchOrigin,
    highlightFragmentSize = null,
    page = 1,
    filters,
    sortBy,
  }) => async (dispatch) => {
    dispatch(searchSlice.actions.reset({ searchLocation }))

    if (!query || query.trim().length < 2) return

    dispatch(searchSlice.actions.setSearchTerm({ searchLocation, data: query }))

    dispatch(asyncActions.search({
      query,
      type,
      searchGoogleDrive,
      searchConfluence,
      searchBloomfire,
      searchOneDrive,
      searchNotion,
      searchLocation,
      searchOrigin,
      highlightFragmentSize,
      page,
      filters,
      sortBy,
    }))
  },

  search: ({
    query,
    type,
    searchGoogleDrive,
    searchConfluence,
    searchBloomfire,
    searchNotion,
    searchLocation,
    searchOrigin,
    searchOneDrive,
    highlightFragmentSize,
    page = 1,
    filters,
    sortBy,
  }) => async (dispatch) => {
    try {
      dispatch(searchSlice.actions.setSearchStartedAt({ searchLocation, data: new Date() }))

      await Promise.all([
        dispatch(asyncActions.searchCleary({
          query, type, highlightFragmentSize, searchLocation, page, filters, sortBy,
        })),
        dispatch(asyncActions.searchGoogleDrive({
          query, searchGoogleDrive, searchLocation, filters,
        })),
        dispatch(asyncActions.searchConfluence({
          query, searchConfluence, searchLocation, filters,
        })),
        dispatch(asyncActions.searchBloomfire({
          query, searchBloomfire, searchLocation, filters,
        })),
        dispatch(asyncActions.searchNotion({
          query, searchNotion, searchLocation, filters,
        })),
        dispatch(asyncActions.searchOneDrive({
          query, searchOneDrive, searchLocation, filters,
        })),
      ])

      dispatch(asyncActions.trackSearch(query, searchOrigin, searchLocation, type))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)

      dispatch(searchSlice.actions.isLoading({ searchLocation, data: false }))
      dispatch(searchSlice.actions.isLoadingGoogleDrive({ searchLocation, data: false }))
      dispatch(searchSlice.actions.isLoadingOneDrive({ searchLocation, data: false }))
      dispatch(searchSlice.actions.isLoadingBloomfire({ searchLocation, data: false }))
      dispatch(searchSlice.actions.isLoadingConfluence({ searchLocation, data: false }))
      dispatch(searchSlice.actions.isLoadingNotion({ searchLocation, data: false }))

      const toastMessage = extractToastMessage(e.response)

      if (toastMessage) {
        dispatch(toasts.actions.showToastMessage({ type: 'error', message: toastMessage }))
      }
    }
  },

  searchCleary: ({
    query, type, highlightFragmentSize, searchLocation, page, filters, sortBy,
  }) => async (dispatch, getState) => {
    dispatch(searchSlice.actions.isLoading({ searchLocation, data: true }))

    const clearySearchResults = await API.globalSearch(
      query, {
        type,
        highlightFragmentSize,
        perPage: TOP_RESULTS_PAGE_SIZES[searchLocation],
        page,
        filters,
        sortBy,
        searchLocation,
      }
    )

    const totalCount = clearySearchResults.data?.meta?.totalCounts?.total || clearySearchResults.data.data.length

    const searchResultIds = clearySearchResults.data.data.map(result => result.id)

    dispatch(searchSlice.actions.setTotalResults({ searchLocation, data: totalCount }))

    dispatch(searchSlice.actions.setTotalResultsByType({
      searchLocation,
      data: clearySearchResults.data.meta?.totalCounts,
    }))

    dispatch(entitySlice.actions.update({ data: clearySearchResults.data, keysToReplace: ['highlights'] }))

    dispatch(searchSlice.actions.setClearySearchResultIds({ searchLocation, data: searchResultIds }))
    dispatch(searchSlice.actions.isLoading({ searchLocation, data: false }))
  },

  searchGoogleDrive: ({
    query, searchGoogleDrive, searchLocation, filters, track = false,
  }) => async (dispatch) => {
    if (!searchGoogleDrive) return

    dispatch(searchSlice.actions.isLoadingGoogleDrive({ searchLocation, data: true }))

    const response = await API.googleDriveSearch(query, { filters })

    const { data: { results: googleDriveResults, hasMore = false } } = response

    dispatch(searchSlice.actions.setTotalResults({ searchLocation, data: googleDriveResults.length }))

    dispatch(searchSlice.actions.setTotalResultsByType({
      searchLocation,
      data: {
        googleDrive: googleDriveResults.length,
      },
    }))

    dispatch(searchSlice.actions.setHasMoreByType({ searchLocation, data: { googleDrive: hasMore } }))
    dispatch(searchSlice.actions.setGoogleSearchResults({ searchLocation, data: googleDriveResults }))

    dispatch(searchSlice.actions.isLoadingGoogleDrive({ searchLocation, data: false }))

    if (track) {
      dispatch(asyncActions.trackSearch(query, 'results_page', searchLocation, 'google_drive'))
    }
  },

  searchOneDrive: ({
    query, searchOneDrive, searchLocation, filters, track = false,
  }) => async (dispatch) => {
    if (!searchOneDrive) return

    dispatch(searchSlice.actions.isLoadingOneDrive({ searchLocation, data: true }))

    const response = await API.oneDriveSearch(query, { filters })

    const { data: { results: oneDriveResults } } = response

    dispatch(searchSlice.actions.setTotalResults({ searchLocation, data: oneDriveResults.length }))

    dispatch(searchSlice.actions.setTotalResultsByType({
      searchLocation,
      data: {
        oneDrive: oneDriveResults.length,
      },
    }))

    dispatch(searchSlice.actions.setOneDriveSearchResults({ searchLocation, data: oneDriveResults }))

    dispatch(searchSlice.actions.isLoadingOneDrive({ searchLocation, data: false }))

    if (track) {
      dispatch(asyncActions.trackSearch(query, 'results_page', searchLocation, 'one_drive'))
    }
  },

  searchConfluence: ({
    query, searchConfluence, searchLocation, filters, track = false,
  }) => async (dispatch) => {
    if (!searchConfluence) return

    dispatch(searchSlice.actions.isLoadingConfluence({ searchLocation, data: true }))

    const response = await API.confluenceSearch(query, { filters })

    const { data: { results: confluenceResults } } = response

    dispatch(searchSlice.actions.setTotalResults({ searchLocation, data: confluenceResults.length }))

    dispatch(searchSlice.actions.setTotalResultsByType({
      searchLocation,
      data: {
        confluence: confluenceResults.length,
      },
    }))

    dispatch(searchSlice.actions.setConfluenceSearchResults({ searchLocation, data: confluenceResults }))

    dispatch(searchSlice.actions.isLoadingConfluence({ searchLocation, data: false }))

    if (track) {
      dispatch(asyncActions.trackSearch(query, 'results_page', searchLocation, 'confluence'))
    }
  },

  searchBloomfire: ({
    query, searchBloomfire, searchLocation, filters, track = false,
  }) => async (dispatch) => {
    if (!searchBloomfire) return

    dispatch(searchSlice.actions.isLoadingBloomfire({ searchLocation, data: true }))

    const response = await API.bloomfireSearch(query, { filters })

    const { data: { results: bloomfireResults } } = response

    dispatch(searchSlice.actions.setTotalResults({ searchLocation, data: bloomfireResults.length }))

    dispatch(searchSlice.actions.setTotalResultsByType({
      searchLocation,
      data: {
        bloomfire: bloomfireResults.length,
      },
    }))

    dispatch(searchSlice.actions.setBloomfireSearchResults({ searchLocation, data: bloomfireResults }))

    dispatch(searchSlice.actions.isLoadingBloomfire({ searchLocation, data: false }))

    if (track) {
      dispatch(asyncActions.trackSearch(query, 'results_page', searchLocation, 'bloomfire'))
    }
  },

  searchNotion: ({
    query, searchNotion, searchLocation, track = false,
  }) => async (dispatch) => {
    if (!searchNotion) return

    dispatch(searchSlice.actions.isLoadingNotion({ searchLocation, data: true }))

    const response = await API.notionSearch(query)

    const { data: { results: notionResults } } = response

    dispatch(searchSlice.actions.setTotalResults({ searchLocation, data: notionResults.length }))

    dispatch(searchSlice.actions.setTotalResultsByType({
      searchLocation,
      data: {
        notion: notionResults.length,
      },
    }))

    dispatch(searchSlice.actions.setNotionSearchResults({ searchLocation, data: notionResults }))

    dispatch(searchSlice.actions.isLoadingNotion({ searchLocation, data: false }))

    if (track) {
      dispatch(asyncActions.trackSearch(query, 'results_page', searchLocation, 'notion'))
    }
  },

  trackSearch: (query, searchOrigin, searchLocation, type) => (_, getState) => {
    if (String(query) === 'undefined') { return }

    const state = getState().search[searchLocation]

    let { totalResultsByType } = state.meta
    const { totalResults } = state.meta

    totalResultsByType = snakeCaseKeysObjectsOnly(totalResultsByType)

    totalResultsByType = Object.keys(totalResultsByType)
                                .filter(key => key !== 'total')
                                .filter(key => totalResultsByType[key] > 0)
                                .sort((a, b) => a.localeCompare(b))

    // Using snake case because that's our standard on Amplitude Events' properties
    const searchEventProperties = {
      query,
      type,
      results_count: totalResults,
      results_types: totalResultsByType,
      origin: searchOrigin,
      session_id: getState().search.sessionId,
      top_results_enabled: true,
    }

    trackEvent('search:results', searchEventProperties)
  },

  trackResultClicked: (searchLocation, searchResult) => (_, getState) => {
    const {
      type, id, rawId, rawType,
    } = searchResult
    const { searchTerm, meta: { searchStartedAt } } = getState().search[searchLocation]

    if (String(searchTerm) === 'undefined') { return }

    let searchConversionTime = 0

    if (searchStartedAt) {
      const searchEndedAt = new Date()
      searchConversionTime = searchEndedAt - searchStartedAt
    }

    const trackableType = EXTERNAL_TYPES.includes(type) ? type : rawType
    const trackableId = EXTERNAL_TYPES.includes(type) ? id : rawId

    trackEvent('search:clicked_result', {
      search_conversion_time: searchConversionTime,
      query: searchTerm,
      trackable_id: trackableId,
      trackable_type: trackableType,
      clicked_type: type,
      session_id: getState().search.sessionId,
      top_results_enabled: true,
    })
  },
}

const selectors = {
  getMetaData: searchLocation => state => state.search[searchLocation].meta,
  getClearySearchResults: searchLocation => state => state.search[searchLocation].clearySearchResultIds.map(id => build(state.entities, 'searchResult', id)) || [],
  getGoogleSearchResults: searchLocation => state => state.search[searchLocation].googleSearchResults,
  getOneDriveSearchResults: searchLocation => state => state.search[searchLocation].oneDriveSearchResults,
  getConfluenceSearchResults: searchLocation => state => state.search[searchLocation].confluenceSearchResults,
  getBloomfireSearchResults: searchLocation => state => state.search[searchLocation].bloomfireSearchResults,
  getNotionSearchResults: searchLocation => state => state.search[searchLocation].notionSearchResults,
  getSearchTerm: searchLocation => state => state.search[searchLocation].searchTerm,
  getSessionId: state => state.search.sessionId,
}

export default {
  ...searchSlice,
  asyncActions,
  selectors,
}
