import React from 'react'
import { ExternalSyncDocument } from 'components/admin/integrations/external_sync/types'
import CdnSvg from 'components/common/cdnSvg'

type DocumentProps = {
  document: ExternalSyncDocument
  onClick?: () => void
}

const Document: React.FC<DocumentProps> = ({ document, onClick }) => (
  <div>
    <CdnSvg className='DocumentIcon d-inline-block mr-2' src={document.iconPath} />
    {document.descendantsCount > 0 ? (
      <a onClick={onClick}>{document.title}</a>
    ) : (
      <span>{document.title}</span>
    )}
    <a href={document.externalUrl} target='_blank' rel='noopener noreferrer' className='ml-2 external-link-icon'>
      <CdnSvg src='/images/externalLinkIcon.svg' />
    </a>
  </div>
)


export default Document

