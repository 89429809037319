import React from 'react'
import classNames from 'classnames'
import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import CloseIcon from 'components/icons/closeIcon'
import { Button } from 'react-bootstrap'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.pages.collaborators')

const AdditionalContentCollaborator = ({ removeCollaborator, user }) => (
  <div>
    <span className='text-primary'>
      {I18N('collaborator')}
    </span>

    <Button
      variant='link'
      className='ml-2'
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()

        removeCollaborator(user)
      }}
    >
      <CloseIcon />
    </Button>
  </div>
)

const PageCollaborators = ({
  addCollaborator,
  removeCollaborator,
  collaborators,
  owner,
  workspaceEditors = [],
  workspaceOwners = [],
  editorsCount = 0,
}) => (
  <main className='px-4 pb-2 mb-4'>
    <h5 className='mt-2'>{I18N('editable_by', { count: editorsCount })}</h5>

    <EmployeeSearch
      onChange={addCollaborator}
      selectedEmployee={null}
      placeholder={I18N('add_collaborator')}
    />

    <div className='mt-3 multi-select-users-container pr-4 pl-4'>
      {owner && (
        <EmployeeMiniCardLink
          showEmployeeTitle={false}
          employee={owner}
          key={owner.id}
          className='mb-2'
          additionalContent={(
            <span className='text-secondary'>
              {I18N('owner')}
            </span>
          )}
        />
      )}

      {workspaceOwners.map(user => (
        <EmployeeMiniCardLink
          showEmployeeTitle={false}
          employee={user}
          key={user.id}
          className='mb-2'
          additionalContent={(
            <span className='text-secondary'>
              {I18N('workspace_owner')}
            </span>
          )}
        />
      ))}

      {workspaceEditors.map(user => (
        <EmployeeMiniCardLink
          showEmployeeTitle={false}
          employee={user}
          key={user.id}
          className='mb-2'
          additionalContent={(
            <span className='text-secondary'>
              {I18N('workspace_editor')}
            </span>
          )}
        />
      ))}

      {collaborators.map(user => (
        <EmployeeMiniCardLink
          showEmployeeTitle={false}
          employee={user}
          key={user.id}
          className='mb-2'
          additionalContent={(
            <AdditionalContentCollaborator
              removeCollaborator={removeCollaborator}
              user={user}
            />
          )}
        />
      ))}
    </div>
  </main>
)

export default PageCollaborators
