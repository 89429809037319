import React, { useState, useEffect } from 'react'
import { useReactMediaRecorder } from 'react-media-recorder'

import { i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import { ButtonSecondarySmall, ButtonSmallNarrow } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.name_pronunciation')
const micIconPath = '/images/micIcon.svg'
const learnMorePronunciationUrl = 'https://easypronunciation.com/en/english-phonetic-transcription-converter'

const ModalHeader = () => (
  <div className='mb-4'>
    <h3 className='d-flex align-items-center'>{I18N('title')}</h3>
    <div className='text-secondary'>{I18N('explanation')}</div>
  </div>
)

const WrittenPronunciation = ({ value, setValue }) => (
  <div className='mb-4'>
    <div className='form-group mb-2'>
      <label>{I18N('written_pronunciation')}</label>
      <input
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={I18N('written_pronunciation_placeholder')}
      />
    </div>
    <div className='d-flex'>
      <div className='text-muted text-small'>{I18N('need_help')}</div>
      <a className='text-small ml-1' href={learnMorePronunciationUrl} target='blank'>{I18N('learn_more')}</a>
    </div>
  </div>
)

const AudioPronunciation = ({
  setAudioFile, recordingUrl, willDeleteRecording, setWillDeleteRecording,
}) => {
  const {
    startRecording,
    stopRecording,
    mediaBlobUrl,
    status,
  } = useReactMediaRecorder({
    audio: true,
    video: false,
    onStop: (_blobUrl, blob) => {
      const audioFile = new File([blob], `recording-${new Date().getTime()}.wav`, { type: blob.type })
      setAudioFile(audioFile)
      setWillDeleteRecording(false)
    },
  })

  const isRecording = status === 'recording'
  const currentRecordingUrl = mediaBlobUrl || recordingUrl
  const [recordingDuration, setRecordingDuration] = useState(0)
  const isRecordingAvailable = !isRecording && currentRecordingUrl && !willDeleteRecording

  const startNewRecording = () => {
    setRecordingDuration(0)
    startRecording()
  }

  useEffect(() => {
    setTimeout(() => {
      if (!isRecording) return

      if (recordingDuration >= 9) stopRecording()
      else setRecordingDuration(recordingDuration + 1)
    }, 1000)
  }, [isRecording, recordingDuration])

  return (
    <>
      <div className='mb-2'>
        {I18N('audio_pronunciation')}
      </div>
      {isRecordingAvailable && (
        <div className='mb-2'>
          <audio controls src={currentRecordingUrl} />
        </div>
      )}
      {isRecording ? (
        <>
          <div className='d-flex mb-2'>
            <CdnSvg className='MicIcon' src={micIconPath} />
            <div className='ml-2'>{`0:0${recordingDuration}`}</div>
          </div>
          <ButtonSecondarySmall onClick={stopRecording}>{I18N('stop_recording')}</ButtonSecondarySmall>
        </>
      ) : (
        <div className='d-flex'>
          <ButtonSecondarySmall className='mr-2' onClick={startNewRecording}>
            {I18N(isRecordingAvailable ? 're_record' : 'record_now')}
          </ButtonSecondarySmall>
          {isRecordingAvailable && (
            <ButtonSmallNarrow variant='secondary-danger' onClick={() => setWillDeleteRecording(true)}>
              {I18n.t('views.common.delete')}
            </ButtonSmallNarrow>
          )}
        </div>
      )}
    </>
  )
}

const AudioRecordingNotSupported = () => (
  <>
    <div className='mb-2'>
      {I18N('audio_pronunciation')}
    </div>
    <div className='text-small'>
      {I18N('browser_unsupported_for_audio_recording')}
    </div>
  </>
)

const ModalFooter = ({ toggle, save, isSaving }) => (
  <div className='d-flex justify-content-end align-items-center mt-4'>
    <a className='text-muted-dark mr-4' onClick={toggle}>{I18n.t('views.common.cancel')}</a>
    <ButtonSmallNarrow onClick={save} showLoadingSpinner={isSaving}>{I18n.t('views.common.save')}</ButtonSmallNarrow>
  </div>
)

const NamePronunciationModal = ({
  isOpen, toggle, recordingUrl, initialWrittenPronunciation, update,
}) => {
  const [writtenPronunciation, setWrittenPronunciation] = useState(initialWrittenPronunciation || '')
  const [isSaving, setIsSaving] = useState(false)
  const [audioFile, setAudioFile] = useState(null)
  const [willDeleteRecording, setWillDeleteRecording] = useState(false)
  const supportsMediaRecorder = !!window.MediaRecorder

  const save = async () => {
    setIsSaving(true)
    const changes = {}
    if (writtenPronunciation !== initialWrittenPronunciation) {
      changes.writtenNamePronunciation = writtenPronunciation
    }
    if (audioFile) {
      changes.namePronunciationRecording = audioFile
    }
    if (willDeleteRecording) {
      changes.namePronunciationRecording = null
    }
    if (!_.isEmpty(changes)) {
      await update(changes)
    }

    toggle()
    setIsSaving(false)
  }


  return (
    <Modal className='NamePronunciationModal' visible={isOpen} toggle={toggle}>
      <ModalHeader />
      <WrittenPronunciation value={writtenPronunciation} setValue={setWrittenPronunciation} />
      {supportsMediaRecorder ? (
        <AudioPronunciation
          setAudioFile={setAudioFile}
          recordingUrl={recordingUrl}
          willDeleteRecording={willDeleteRecording}
          setWillDeleteRecording={setWillDeleteRecording}
        />
      ) : (
        <AudioRecordingNotSupported />
      )}
      <ModalFooter toggle={toggle} save={save} isSaving={isSaving} />
    </Modal>
  )
}

export default NamePronunciationModal
