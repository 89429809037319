import React from 'react'
import { useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import PillTabSelector from 'components/common/pillTabSelector'
import ShoutoutsListCard from 'components/shoutouts/shoutoutsListCard'
import InfiniteScroller from 'components/common/infiniteScroller'
import ShoutoutsEmpty from 'components/shoutouts/shoutoutsEmpty'
import shoutoutsSlice from 'redux/slices/shoutouts'
import SendShoutoutButton from 'components/shoutouts/sendShoutoutButton'

const ShoutoutsList = ({
  shoutouts,
  isLoading,
  hasMoreContent,
  handleFetchShoutouts,
  handleFilterSelection,
}) => {
  const I18N = i18nPath('views.shoutouts_feed')
  const newShoutouts = useSelector(shoutoutsSlice.selectors.getNewShoutouts())
  const showSendShoutoutButton = !_.isEmpty(shoutouts) || !_.isEmpty(newShoutouts)
  const newShoutoutsIds = newShoutouts.map(i => i.id)
  const areShoutoutsEmpty = _.isEmpty(shoutouts) && _.isEmpty(newShoutouts) && !isLoading

  return (
    <>
      <div className='ShoutoutsList'>
        <div className='ShoutoutsListHeader d-flex justify-content-between align-items-center'>
          <h1>{I18N('title')}</h1>
          {showSendShoutoutButton && <SendShoutoutButton />}
        </div>

        {/* min height necessary to fix jumpiness when moving between empty and non-empty states */}
        <div className='ShoutoutsListPillTabs my-4 d-flex justify-content-between' style={{ minHeight: '3.429rem' }}>
          <PillTabSelector
            // isLoading used to disable pills when loading data
            pillClasses={classNames('text-normal', { isLoading })}
            tabs={[
              {
                text: I18N('all'),
                onClick: () => handleFilterSelection('all'),
              },
              {
                text: I18N('shoutouts_ive_sent'),
                onClick: () => handleFilterSelection('sent'),
              },
            ]}
          />
        </div>
        <InfiniteScroller
          isFetching={isLoading}
          onFetch={handleFetchShoutouts}
          hasMoreContent={hasMoreContent}
          className='ShoutoutsListInfiniteScroller'
        >
          {newShoutouts.map((shoutout) => {
            // Only animate the last entry
            const isMostRecentShoutout = shoutout.id === newShoutouts[0]?.id
            return (
              <ShoutoutsListCard
                key={shoutout.id}
                className={isMostRecentShoutout && 'AnimateNewlyAddedShoutoutBackground'}
                shoutout={shoutout}
              />
            )
          })}
          {shoutouts
            // Exclude newly added shoutouts from this list
            .filter(shoutout => !newShoutoutsIds.includes(shoutout.id))
            .map(shoutout => (
              <ShoutoutsListCard
                key={shoutout.id}
                shoutout={shoutout}
              />
            ))}
          <ShoutoutsEmpty className={classNames(!areShoutoutsEmpty && 'd-none')} />
        </InfiniteScroller>
      </div>
    </>
  )
}
export default ShoutoutsList
