import React, { useState, useEffect } from 'react'
import TrashIcon from 'components/icons/trashIcon'
import classNames from 'classnames'

const AgendaItem = (props) => {
  const {
    item, canManageItems, deleteItem, updateItem, setEditedAgendaItem, editTextRowCount,
  } = props
  const [isEditing, setIsEditing] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    // when textbox shows up, focus on it
    // need to change value to '' and then back to the value to get cursor to show up at the end
    // otherwise the cursor shows up in the beginning
    if (isEditing) {
      const elem = document.querySelector('.AgendaItemInput')
      elem.focus()
      const val = elem.value
      elem.value = ''
      elem.value = val
    }
  }, [isEditing])

  const handleBlur = (e) => {
    setIsEditing(false)
    updateItem()
    e.target.parentElement.classList.add('hover-background-color')
  }

  const handleEditKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.target.parentElement.classList.add('hover-background-color')
      setIsEditing(false)
      updateItem()
    }
  }

  const handleItemClick = (e) => {
    setIsEditing(true)
    setEditedAgendaItem(item)
    e.target.parentElement.classList.remove('hover-background-color')
  }

  return (
    <li
      key={`agenda-item-${item}`}
      className='mr-1 break-word AgendaItem'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {canManageItems ? (
        <div className='d-flex px-2 hover-background-color justify-content-between position-relative'
          style={{ left: '-.5rem' }}
        >
          {isEditing ? (
            <textarea
              rows={editTextRowCount}
              className='AgendaItemInput'
              onChange={e => setEditedAgendaItem(e.target.value)}
              onKeyPress={e => handleEditKeyPress(e)}
              onBlur={e => handleBlur(e)}
              defaultValue={item}
            />
          ) : (
            <span className='AgendaItemEditable' onClick={e => handleItemClick(e)}>{item}</span>
          )}
          <span
            className={classNames({
              'pointer': isHovered && !isEditing,
              'visibility-hidden': !isHovered,
            })}
            onClick={() => deleteItem()}
          >
            <TrashIcon fill='var(--text-color-primary)' />
          </span>
        </div>
      ) : (
        <span className='AgendaItem'>{item}</span>
      )}
    </li>
  )
}

export default AgendaItem
