import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import { ButtonSecondarySmall } from 'components/common/buttons'

const I18N = i18nPath('views.home.onboarding_buddies')

const SelectBuddyButton = ({
  buddy,
  newHireId,
  className,
  onSuccess = () => {},
}) => {
  const dispatch = useDispatch()

  const [isSaving, setIsSaving] = useState(false)

  const selectBuddy = () => {
    setIsSaving(true)

    if (newHireId) {
      const params = { onboardingBuddyId: buddy.id, newHireId }

      dispatch(onboardingBuddySlice.asyncActions.admin.assign(params, onSuccess))
    } else {
      dispatch(onboardingBuddySlice.asyncActions.create(buddy.id, onSuccess))
    }
  }

  return (
    <ButtonSecondarySmall
      className={classNames('SelectBuddyButton', className)}
      onClick={selectBuddy}
      disabled={isSaving}
      showLoadingSpinner={isSaving}
    >
      {I18N('select_buddy')}
    </ButtonSecondarySmall>
  )
}

export default SelectBuddyButton
