import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import userSlice from 'redux/slices/users'

import RoundedAvatars from 'components/common/roundedAvatars'


const RoundedAvatarsWithAsyncUserFetching = ({
  className = '',
  userIds = [],
  shownAvatars = 5,
  avatarSize = '25px',
  maxUsersListedInDropdown = 99,
  linkTo = 'profile',
  includeInactiveUsers = false,
  includeHiddenUsers = false,
  popOverPlacement = 'bottom-start',
  onUserClick = (user) => {},
  onMoreUsersClick = () => {},
}) => {
  const dispatch = useDispatch()
  const [loadedDropdownUsers, setLoadedDropdownUsers] = useState(false)
  const [showDropdownUsers, setShowDropdownUsers] = useState(false)
  const dropdownUserIds = userIds.slice(shownAvatars, Math.min(userIds.length, shownAvatars + maxUsersListedInDropdown))
  const shownUsersIds = userIds.slice(0, shownAvatars)
  const shownUsers = useSelector(userSlice.selectors.getSimpleUsersByIds(shownUsersIds))
  const dropdownUsers = useSelector(userSlice.selectors.getSimpleUsersByIds(dropdownUserIds))
  const usersByState = loadedDropdownUsers ? shownUsers.concat(dropdownUsers) : shownUsers

  useEffect(() => {
    if (shownUsersIds?.length) {
      dispatch(userSlice.asyncActions.simpleFetchAll(
        shownUsersIds,
        () => null,
        { includeInactiveUsers, includeHiddenUsers }
      ))
    }
  }, [JSON.stringify(shownUsersIds)])

  useEffect(() => {
    if (dropdownUserIds?.length) {
      dispatch(userSlice.asyncActions.simpleFetchAll(
        dropdownUserIds,
        () => setLoadedDropdownUsers(true),
        { includeInactiveUsers, includeHiddenUsers }
      ))
    }
  }, [showDropdownUsers])


  const handleMouseEnter = () => {
    setShowDropdownUsers(true)
  }

  return (
    <RoundedAvatars
      className={className}
      users={usersByState}
      onUserClick={onUserClick}
      fetchMoreUsers={handleMouseEnter}
      shownAvatars={shownAvatars}
      avatarSize={avatarSize}
      maxUsersListedInDropdown={maxUsersListedInDropdown}
      userCount={userIds.length}
      linkTo={linkTo}
      popOverPlacement={popOverPlacement}
      onMoreUsersClick={onMoreUsersClick}
    />
  )
}

export default RoundedAvatarsWithAsyncUserFetching
