import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import StyleguidePage from 'pages/admin/styleguide/styleguidePage'
import TypographyPage from 'pages/admin/styleguide/typographyPage'
import IconsPage from 'pages/admin/styleguide/iconsPage'
import ButtonsPage from 'pages/admin/styleguide/buttonsPage'
import EditableContentPage from 'pages/admin/styleguide/editableContentPage'
import PeoplePage from 'pages/admin/styleguide/peoplePage'
import CalendarEventsPage from 'pages/admin/styleguide/calendarEventsPage'
import TabsPage from 'pages/admin/styleguide/tabsPage'
import TagsPage from 'pages/admin/styleguide/tagsPage'
import OtherComponentsPage from 'pages/admin/styleguide/otherComponentsPage'
import FormFieldsPage from 'pages/admin/styleguide/formFieldsPage'
import BadgesPage from 'pages/admin/styleguide/badgesPage'
import UploadsPage from 'pages/admin/styleguide/uploadsPages'
import GifSelectorPage from 'pages/admin/styleguide/gifSelectorPage'

const StyleguideRouter = () => {
  // url -> /admin/styleguide
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route exact key='style-guide-root' path='/admin/styleguide/' component={StyleguidePage} />
      <Route key='typography' path={`${url}/typography`} component={TypographyPage} />
      <Route key='icons' path={`${url}/icons`} component={IconsPage} />
      <Route key='buttons' path={`${url}/buttons`} component={ButtonsPage} />
      <Route key='content' path={`${url}/editable-content`} component={EditableContentPage} />
      <Route key='people' path={`${url}/people`} component={PeoplePage} />
      <Route key='calendar-events' path={`${url}/calendar-events`} component={CalendarEventsPage} />
      <Route key='tabs' path={`${url}/tabs`} component={TabsPage} />
      <Route key='tags' path={`${url}/tags`} component={TagsPage} />
      <Route key='form-fields' path={`${url}/form-fields`} component={FormFieldsPage} />
      <Route key='badges' path={`${url}/badges`} component={BadgesPage} />
      <Route key='uploads' path={`${url}/uploads`} component={UploadsPage} />
      <Route key='gif-selector' path={`${url}/gif-selector`} component={GifSelectorPage} />
      <Route key='other-components' path={`${url}/other-components`} component={OtherComponentsPage} />
    </Switch>
  )
}

export default StyleguideRouter
