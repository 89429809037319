import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.profile')

const InactiveUserBadge = ({ user }) => {
  if (user.active) return null

  return <span className='InactiveUserBadge text-white ml-3'>{I18N('inactive')}</span>
}

export default InactiveUserBadge
