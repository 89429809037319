import React from 'react'

import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from './searchResultOption'

const I18N = i18nPath('views.search.global_search')


const OneDriveOption = ({ option }) => (
  <SearchResultOption
    image={<CdnSvg src={option.icon} className='SearchResultOptionImg mr-1' />}
    title={option.name}
    secondaryTexts={[I18N('last_modified', { date: i18nMoment(option.lastModifiedDateTime).fromNow() })]}
  />
)

export default OneDriveOption
