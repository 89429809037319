import React from 'react'

import { i18nMoment, i18nPath, i18nFormat } from 'utils/i18nHelpers'

import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from './searchResultOption'
import QnaCreatorContent from './qnaCreatorContent'

const I18N = i18nPath('views.search.global_search')
const questionIconPath = '/images/questionIcon.svg'

const QnaQuestionOption = ({ option }) => {
  const { qnaQuestion, highlights } = option
  const { content, createdAt } = { ...qnaQuestion, ...highlights }

  const questionCreatedAt = i18nMoment(createdAt).fromNow()

  return (
    <SearchResultOption
      image={<CdnSvg src={questionIconPath} className='SearchResultOptionImg mr-1' />}
      title={content}
      secondaryTexts={[i18nFormat(I18N('asked_by'), <QnaCreatorContent questionOrAnswer={qnaQuestion} />, questionCreatedAt)]}
    />
  )
}

export default QnaQuestionOption
