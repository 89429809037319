import React from 'react'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'

import { i18nPath } from 'utils/i18nHelpers'

const ShoutoutUsers = ({ users }) => {
  const I18N = i18nPath('views.shoutouts_feed')

  return (
    /*
      max-width-max-content mx-auto help reduce the non-clickable area
      that is necessary to respond to clicks in order to navigate users
      to the shouout detail page
     */
    <div className='ShoutoutUsers max-width-max-content mx-auto'>
      <div className='text-center'>
        {users.map((user, index) => (
          <span key={index} className='d-inline-block'>
            <EmployeeLinkWithPopover className='ShoutoutUser' userLinkClassName='font-weight-500 link-color text-large' user={user} />
            <span className='mr-2'>{index < users.length - 1 && ','}</span>
          </span>
        ))}
        <h5 className='font-weight-500 text-large cursor-default d-inline-block'>{I18N('received_shoutout')}</h5>
      </div>
    </div>
  )
}

export default ShoutoutUsers
