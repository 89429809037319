import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'

import EditableContent from 'components/common/editable_content'
import LoadingSpinner from 'components/common/loadingSpinner'
import FormErrorList from 'components/errors/formErrorList'
import { Button, ButtonSecondary, ButtonSecondarySmall } from 'components/common/buttons'

import tagSlice from 'redux/slices/tags'
import SmartTable from 'components/common/tables/smartTable'


const I18N = i18nPath('views.admin.tag_list')

const TagListPage = () => {
  const dispatch = useDispatch()
  const [showCreateButton, setShowCreateButton] = useState(false)
  const [newTagName, setNewTagName] = useState('')

  const { isLoading, isSaving, error } = useSelector(tagSlice.selectors.getMetaData())
  const tags = useSelector(tagSlice.selectors.getTags())

  useEffect(() => {
    dispatch(tagSlice.asyncActions.fetchTags())
  }, [])

  const onCreateTag = () => {
    const onSuccess = () => {
      setShowCreateButton(false)
      setNewTagName('')
    }

    dispatch(tagSlice.asyncActions.admin.createTag({ name: newTagName }, onSuccess))
  }

  const onUpdateTag = (tag, name) => {
    const params = {
      id: tag?.id,
      name,
    }

    return dispatch(tagSlice.asyncActions.admin.updateTag(params))
  }

  const onRemoveTag = (tag) => {
    dispatch(tagSlice.asyncActions.admin.deleteTag(tag))
  }

  const columns = [
    {
      header: I18N('name'),
      accessor: d => (
        <EditableContent
          canEdit
          saveContent={tagName => onUpdateTag(d, tagName)}
          displayElement={<span className='text-primary'>{d?.name}</span>}
          valueToEdit={d?.name}
          inputElement={<input type='text' />}
        />
      ),
    },
    {
      header: I18N('delete'),
      accessor: d => (
        <ButtonSecondarySmall onClick={() => onRemoveTag(d)} value={d?.name}>
          {I18N('delete_tag')}
        </ButtonSecondarySmall>
      ),
    },
  ]

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className='TagListPage'>
      <header className='AdminHeader d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <h2>{I18N('tags')}</h2>

          {showCreateButton ? (
            <div className='AdminButtonContainer col-6'>
              {showCreateButton && (
                <input
                  type='text'
                  data-testid='cy_tags_create_input'
                  className='mr-1'
                  onChange={e => setNewTagName(e.target.value)}
                />
              )}

              <div className='btn-group'>
                <ButtonSecondary className='mr-1' onClick={() => setShowCreateButton(false)}>
                  {I18N('cancel')}
                </ButtonSecondary>
                <Button onClick={onCreateTag} showLoadingSpinner={isSaving}>
                  {I18N('save')}
                </Button>
              </div>
            </div>
          ) : (
            <div className='AdminButtonContainer col-6'>
              <Button onClick={() => setShowCreateButton(true)}>
                {I18N('create_new_tag')}
              </Button>
            </div>
          )}
        </div>
        {error && (
          <div className='w-100'>
            <FormErrorList error={error} />
          </div>
        )}
      </header>
      <main className='AdminContent'>
        <SmartTable
          className='TagListPageTable white-bg-table'
          data={tags}
          columns={columns}
        />
      </main>
    </div>
  )
}

export default TagListPage
