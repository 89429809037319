import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import FormErrorList from 'components/errors/formErrorList'
import { i18nPath } from 'utils/i18nHelpers'
import timezoneSlice from 'redux/slices/timezones'
import TimezoneForm from 'components/admin/timezoneForm'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.admin.timezones')

const EditTimezonePage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [workingCopy, setWorkingCopy] = useState({})

  const { timezoneId } = params

  const currentUser = useCurrentUser()
  const timezone = useSelector(timezoneSlice.selectors.getTimezone(timezoneId))
  const { isLoadingAdmin, isSaving, error } = useSelector(timezoneSlice.selectors.getMetaData())

  const isMissingRequiredFields = !workingCopy.location || !workingCopy.timezone
  const changesPresent = !_.isEqual(timezone, workingCopy)
  const showDeleteButton = currentUser.permissions.superAdmin
  const disableSaveButton = isMissingRequiredFields || !changesPresent

  useEffect(() => {
    dispatch(timezoneSlice.asyncActions.admin.fetchTimezone(timezoneId))
  }, [timezoneId])

  useEffect(() => {
    if (timezone?.id) {
      setWorkingCopy(timezone)
    }
  }, [timezone?.id])

  useEffect(() => {
    if (!_.isEmpty(timezone)) {
      setWorkingCopy(timezone)
    }
  }, [timezone?.updatedAt])

  const saveTimezone = () => {
    dispatch(timezoneSlice.asyncActions.admin.updateTimezone(workingCopy))
  }

  const deleteTimezone = () => {
    if (confirm(I18N('delete_confirm'))) {
      dispatch(timezoneSlice.asyncActions.admin.deleteTimezone(timezone)).then(() => {
        history.push('/admin/timezones')
      })
    }
  }

  if (!timezone?.id || isLoadingAdmin) {
    return null
  }

  return (
    <div className='container mb-4'>
      <div className='row mt-4'>
        <div className='col-md-6 col-bg-6 col-sm-12'>
          <h1>{I18N('edit_timezone')}</h1>
          {error && <FormErrorList error={error} />}
        </div>
      </div>
      <div className='row mt-4'>
        <TimezoneForm timezone={workingCopy} setTimezone={setWorkingCopy} />

        <div className='form-group col-12'>
          <Button disabled={disableSaveButton} onClick={saveTimezone} showLoadingSpinner={isSaving}>
            {I18N('update_timezone')}
          </Button>

          {showDeleteButton && (
            <Button variant='danger' className='ml-2' onClick={deleteTimezone}>
              {I18N('delete_timezone')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditTimezonePage
