import React, { useEffect, useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'

import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import { useSelector } from 'react-redux'

const I18N = i18nPath('views.authentication.login_form')

const AuthTypeLookupByEmailForm = () => {
  const [email, setEmail] = useState('')
  const [showError, setShowError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const lastLoggedUser = useSelector(state => state.lastLoggedUser)

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim())
    setShowError(false)
  }

  const lookupAuthTypeByEmail = (e) => {
    e.preventDefault()

    setIsSubmitting(true)
    setShowError(false)

    API.auth.getAuthTypeByEmail(email).then((response) => {
      const { subdomain, isUserPreboarding } = response.data
      const currentSubdomain = window.location.host.split('.')[0]

      const loginPath = isUserPreboarding ? '/preboarding_login' : '/login'
      const baseUrl = window.location.origin.replace(currentSubdomain, 'login')

      window.location = `${baseUrl}${loginPath}?company=${subdomain}&email=${email}&autoLogin=true`
    })
      .catch(() => {
        setShowError(true)
        setIsSubmitting(false)
      })
  }

  useEffect(() => {
    if (lastLoggedUser) setEmail(lastLoggedUser.email)
  }, [lastLoggedUser])


  return (
    <>
      <p className='text-center text-secondary'>{I18N('enter_email')}</p>

      <form onSubmit={lookupAuthTypeByEmail} className='login-form'>
        <div className='form-group position-relative'>
          <CdnSvg className='email-icon' src='/images/envelopeOutlineIcon.svg' />
          <input className='form-control email-input' placeholder='Email' value={email} onChange={handleEmailChange} />
        </div>

        <div className='form-group text-center'>
          <Button disabled={isSubmitting} type='submit'>
            {I18N('next')}
          </Button>
        </div>
      </form>

      {showError && <div className='text-error'>{I18N('no_user_found', { email })}</div>}
    </>
  )
}

export default AuthTypeLookupByEmailForm
