import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import classNames from 'classnames'
import moment from 'moment'

import { Button } from 'components/common/buttons'
import carouselCardSlice from 'redux/slices/carouselCards'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import TabSelector from 'components/common/tabSelector'

const I18N = i18nPath('views.admin.carousel_cards')
const I18NCommon = i18nPath('views.common')

const columns = [
  {
    header: I18N('table_header.name'),
    col: 'col-name',
    accessor: card => <Link to={`/admin/carousel_cards/${card.id}`}>{card.name}</Link>,
  },
  {
    header: I18N('table_header.start_time'),
    col: 'col-start-time',
    accessor: card => i18nMoment(card.startTime).format('LLL'),
  },
  {
    header: I18N('table_header.end_time'),
    col: 'col-end-time',
    accessor: card => i18nMoment(card.endTime).format('LLL'),
  },
]


const SortableCarouselCardRow = SortableElement(({ carouselCard, relativeOrder }) => (
  <tr>
    <td>
      {relativeOrder}
    </td>
    <td>
      <Link to={`/admin/carousel_cards/${carouselCard.id}`}>{carouselCard.name}</Link>
    </td>
    <td>
      {moment(carouselCard.startTime).isBefore() ? I18NCommon('active') : I18NCommon('upcoming')}
    </td>
    <td>
      {i18nMoment(carouselCard.startTime).format('LLL')}
    </td>
    <td>
      {i18nMoment(carouselCard.endTime).format('LLL')}
    </td>
  </tr>
))

const SortableCarouselCardsTable = SortableContainer(({
  carouselCards, isSaving,
}) => (
  <table className={classNames('Sortable cursorGrab white-bg-table', { isSaving })}>
    <thead>
      <tr>
        <th>{I18NCommon('order')}</th>
        <th>{I18NCommon('name')}</th>
        <th>{I18NCommon('status')}</th>
        <th>{I18NCommon('start_date')}</th>
        <th>{I18NCommon('end_date')}</th>
      </tr>
    </thead>
    <tbody>
      {carouselCards.map((carouselCard, index) => (
        <SortableCarouselCardRow
          key={`carousel-card-${carouselCard.id}`}
          carouselCard={carouselCard}
          index={index}
          relativeOrder={index + 1}
        />
      ))}
    </tbody>
  </table>
))

const CarouselCardListPage = () => {
  const { filter } = useParams()

  const dispatch = useDispatch()

  const carouselCards = useSelector(carouselCardSlice.selectors.getAdminCarouselCards())
  const { isLoading, isSaving, queryParams } = useSelector(carouselCardSlice.selectors.getAdminMetaData())

  const {
    page, totalPages, perPage, total,
  } = queryParams

  useEffect(() => {
    // Need to clear out existing cards because the active_or_upcoming filter does NOT use
    // pagination (returns everything that's active or upcoming), but the archived filter needs pagination.
    // If we dont clear it out, it gets in a weird state when you switch between the tabs
    dispatch(carouselCardSlice.actions.clear())
    dispatch(carouselCardSlice.asyncActions.admin.fetchCarouselCards({ filter, ...queryParams }))

    return () => {
      dispatch(carouselCardSlice.actions.clear())
    }
  }, [filter])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(carouselCardSlice.asyncActions.admin.reorderCarouselCards(carouselCards, newIndex, oldIndex))
  }

  const handlePaginationClick = (params) => {
    const newQueryParams = { ...queryParams, page: params.page }

    dispatch(carouselCardSlice.asyncActions.admin.fetchCarouselCards({ filter, ...newQueryParams }))
  }

  const showActiveOrUpcomingCards = filter === 'active_or_upcoming'

  return (
    <div className='CarouselCardListPage'>
      <header className='AdminHeader d-flex justify-content-between align-items-center'>
        <h3 className='mb-0'>{I18N('title')}</h3>
        <div className='AdminButtonContainer'>
          <Link to='/admin/carousel_cards/new'>
            <Button>
              {I18N('create_new_carousel_card')}
            </Button>
          </Link>
        </div>
      </header>
      <main className='AdminContent'>
        <TabSelector
          tabs={[{ linkPath: 'active_or_upcoming' }, { linkPath: 'archived' }]}
          baseUrl='/admin/carousel_cards/filter/'
          keyPrefix='filter_'
          translationsPath='views.admin.carousel_cards'
        >
          <TableLoadingContainer isLoading={isLoading}>
            {showActiveOrUpcomingCards ? (
              <>
                <div className='text-secondary text-small mb-3'>{I18N('drag_to_change_helper_text')}</div>
                <SortableCarouselCardsTable
                  carouselCards={carouselCards}
                  helperClass='Sortable-isDragging'
                  isSaving={isSaving}
                  onSortEnd={onSortEnd}
                  axis='y'
                  lockAxis='y'
                  distance={5}
                />
              </>
            ) : (
              <SmartTable
                className='white-bg-table'
                data={carouselCards}
                columns={columns}
                showPagination={true}
                page={page}
                pages={totalPages}
                perPage={perPage}
                totalCount={total}
                onPaginationClick={handlePaginationClick}
              />
            )}
          </TableLoadingContainer>
        </TabSelector>
      </main>
    </div>
  )
}

export default CarouselCardListPage
