import React, {
  useState, useCallback, useRef, useEffect
} from 'react'
import { ExternalSyncDocument, ExternalSyncImportState, ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import CdnSvg from 'components/common/cdnSvg'
import ResponsivePopover from 'components/common/responsivePopover'
import SyncingIndicator from 'pages/external_sync/syncingIndicator'
import { present } from 'components/common/utils'
import classNames from 'classnames'
import { LeftBlueChevronIcon } from 'components/icons/chevron'

type CollapsedParentsProps = {
  parents: ExternalSyncDocument[]
  onParentClick: (parent: ExternalSyncDocument | null) => void
}

const CollapsedParents: React.FC<CollapsedParentsProps> = ({ parents, onParentClick }) => {
  const [popoverTarget, setPopoverTarget] = useState<HTMLSpanElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(() => {
    setIsOpen(prev => !prev)
  }, [])

  return (
    <h3 className='d-inline-block ml-2'>
      <span className='mr-2'>
        <LeftBlueChevronIcon className='chevron-icon' />
      </span>

      <span
        ref={setPopoverTarget}
        onClick={toggle}
        className='pointer'
      >
        ...
      </span>
      {popoverTarget && (
        <ResponsivePopover
          target={popoverTarget}
          isOpen={isOpen}
          toggle={toggle}
        >
          <ul className='ParentsPopoverList list-unstyled'>
            {parents.map(parent => (
              <li
                key={parent.id}
                onClick={() => { onParentClick(parent) }}
                className='pointer'
              >
                {parent.title}
              </li>
            ))}
          </ul>
        </ResponsivePopover>
      )}
    </h3>
  )
}

type InnerBreadCrumbProps = {
  onlySource?: boolean
  document?: ExternalSyncDocument
  source: ExternalSyncSource
  onParentClick: (parent: ExternalSyncDocument | null) => void
  importedDocumentsCount: number
  totalDocumentsCount: number
  state: ExternalSyncImportState
  shouldCollapse?: boolean
  className?: string
}

const InnerBreadCrumb = ({
  onlySource,
  document,
  source,
  importedDocumentsCount,
  totalDocumentsCount,
  onParentClick,
  state,
  shouldCollapse,
  className,
}: InnerBreadCrumbProps) => {
  const parents = document?.parents ?? []
  const parentsAndSelf = shouldCollapse ? [document].filter(present) : [...parents, document].filter(present)

  return (
    <div className={classNames('d-flex flex-row align-items-center Breadcrumb', className)}>
      <h3>
        <a onClick={() => onParentClick(null)}>
          <CdnSvg src={source.iconPath} className='d-inline-block mr-2 SourcePageIcon' />
          {source.humanName}
        </a>
      </h3>

      {shouldCollapse && (
        <CollapsedParents parents={parents} onParentClick={onParentClick} />
      )}

      {!onlySource && parentsAndSelf.map(parent => (
        <h3 key={parent.id} className='d-inline-block ml-2'>
          <a onClick={() => onParentClick(parent)}>
            <span className='mr-2'>
              <LeftBlueChevronIcon className='chevron-icon' />
            </span>
            <span>{parent.title}</span>
          </a>
        </h3>
      ))}

      {!onlySource && (
        <SyncingIndicator
          importedDocumentsCount={importedDocumentsCount}
          totalDocumentsCount={totalDocumentsCount}
          state={state}
        />
      )}
    </div>
  )
}

type Props = {
  document?: ExternalSyncDocument
  source: ExternalSyncSource
  onParentClick: (parent: ExternalSyncDocument | null) => void
  importedDocumentsCount: number
  totalDocumentsCount: number
  state: ExternalSyncImportState
  forceCollapse?: boolean // used in jest only
  ignoreFakeBreadcrumbs?: boolean // used in jest only
}

const Breadcrumb: React.FC<Props> = ({
  document,
  source,
  onParentClick,
  importedDocumentsCount,
  totalDocumentsCount,
  state,
  forceCollapse,
  ignoreFakeBreadcrumbs,
}) => {
  const [rawShouldCollapse, setRawShouldCollapse] = useState(false)
  const shouldCollapse = forceCollapse ?? rawShouldCollapse

  const fakeFullBreadcrumbRef = useRef<HTMLDivElement>(null)
  const fakeOnlySourceBreadcrumbRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ignoreFakeBreadcrumbs) return () => {}

    // add a observer to the parentRef to check if the height of the fakeFullBreadcrumbRef is greater than the fakeOnlySourceBreadcrumbRef
    const parent = parentRef.current!

    const check = () => {
      const fullHeight = fakeFullBreadcrumbRef.current?.firstElementChild?.clientHeight
      const onlySourceHeight = fakeOnlySourceBreadcrumbRef.current?.firstElementChild?.clientHeight

      if (fullHeight && onlySourceHeight) {
        setRawShouldCollapse(fullHeight > onlySourceHeight)
      }
    }

    const observer = new ResizeObserver(check)
    observer.observe(parent)

    const contentObserver = new MutationObserver(check)
    contentObserver.observe(parent, { childList: true })

    return () => {
      observer.disconnect()
      contentObserver.disconnect()
    }
  }, [document?.parents])

  /*
    let's render 2 fake breadcrumbs. One with only the source and the other with all the parents and the document
    if the height of the second breadcrumb is greater than the first, this mean we should collapse the parents
  */
  return (
    <div ref={parentRef}>
      {!ignoreFakeBreadcrumbs && (
        <>
          <div ref={fakeOnlySourceBreadcrumbRef} style={{ visibility: 'hidden', height: 0 }}>
            <InnerBreadCrumb
              className='FakeBreadcrumb'
              onlySource
              document={document}
              source={source}
              onParentClick={onParentClick}
              importedDocumentsCount={importedDocumentsCount}
              totalDocumentsCount={totalDocumentsCount}
              state={state}
            />
          </div>

          <div ref={fakeFullBreadcrumbRef} style={{ visibility: 'hidden', height: 0 }}>
            <InnerBreadCrumb
              className='FakeBreadcrumb'
              document={document}
              source={source}
              onParentClick={onParentClick}
              importedDocumentsCount={importedDocumentsCount}
              totalDocumentsCount={totalDocumentsCount}
              state={state}
            />
          </div>
        </>
      )}

      <InnerBreadCrumb
        className='RealBreadcrumb'
        document={document}
        source={source}
        onParentClick={onParentClick}
        importedDocumentsCount={importedDocumentsCount}
        totalDocumentsCount={totalDocumentsCount}
        state={state}
        shouldCollapse={shouldCollapse}
      />
    </div>
  )
}

export default Breadcrumb
