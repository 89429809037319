import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.custom_styles.preview_components')

const DangerColor = () => <Button variant='danger' className='btn-sm mr-3'>{I18N('delete')}</Button>

export default DangerColor
