import ReactSelect from 'components/common/react_select'
import React from 'react'
import { I18NCommon, i18nMoment } from 'utils/i18nHelpers'

const today = i18nMoment().startOf('day')

type groupByType = 'days' | 'weeks' | 'months'

export interface TimeRangeValue {
  id: string,
  value: string,
  text: string
}

export const periodStartDate = (amount: number, groupBy: groupByType) => today.clone().subtract(amount, groupBy).format('YYYY-MM-DD')

const DAYS = 'days'
const WEEKS = 'weeks'
const MONTHS = 'months'

export const DEFAULT_DAILY_OPTION: TimeRangeValue = { id: 'last_30_days', value: periodStartDate(30, DAYS), text: I18NCommon('last_x_days', { amount: 30 }) }
export const DEFAULT_WEEKLY_OPTION: TimeRangeValue = { id: 'last_4_weeks', value: periodStartDate(4, WEEKS), text: I18NCommon('last_x_weeks', { amount: 4 }) }
export const DEFAULT_MONTHLY_OPTION: TimeRangeValue = { id: 'last_3_months', value: periodStartDate(3, MONTHS), text: I18NCommon('last_x_months', { amount: 3 }) }


const DAILY_OPTIONS: TimeRangeValue[] = [
  { id: 'last_7_days', value: periodStartDate(7, DAYS), text: I18NCommon('last_x_days', { amount: 7 }) },
  DEFAULT_DAILY_OPTION,
  { id: 'last_60_days', value: periodStartDate(60, DAYS), text: I18NCommon('last_x_days', { amount: 60 }) },
  { id: 'last_90_days', value: periodStartDate(90, DAYS), text: I18NCommon('last_x_days', { amount: 90 }) },
]

const WEEKLY_OPTIONS: TimeRangeValue[] = [
  DEFAULT_WEEKLY_OPTION,
  { id: 'last_8_weeks', value: periodStartDate(8, WEEKS), text: I18NCommon('last_x_weeks', { amount: 8 }) },
  { id: 'last_12_weeks', value: periodStartDate(12, WEEKS), text: I18NCommon('last_x_weeks', { amount: 12 }) },
  { id: 'last_24_weeks', value: periodStartDate(24, WEEKS), text: I18NCommon('last_x_weeks', { amount: 24 }) },
]

const MONTHLY_OPTIONS: TimeRangeValue[] = [
  DEFAULT_MONTHLY_OPTION,
  { id: 'last_6_months', value: periodStartDate(6, MONTHS), text: I18NCommon('last_x_months', { amount: 6 }) },
  { id: 'last_12_months', value: periodStartDate(12, MONTHS), text: I18NCommon('last_x_months', { amount: 12 }) },
]

const INPUT_OPTIONS: TimeRangeValue[] = [
  { id: 'since', value: 'since', text: I18NCommon('since') },
  { id: 'between', value: 'between', text: I18NCommon('between') },
]

const OPTIONS_MAP = {
  day: DAILY_OPTIONS,
  week: WEEKLY_OPTIONS,
  month: MONTHLY_OPTIONS,
  custom: [DEFAULT_DAILY_OPTION, ...MONTHLY_OPTIONS],
}

const TimeRangeFilter = ({
  value,
  onFilterChange,
  optionsKey = 'day',
  showInputOptions = false,
  className = '',
}) => {
  let options = OPTIONS_MAP[optionsKey] || []

  if (showInputOptions) { options = [...options, ...INPUT_OPTIONS] }

  return (
    <ReactSelect
      value={value}
      onChange={onFilterChange}
      options={options}
      getOptionLabel={option => option.text}
      className={className}
    />
  )
}

export default TimeRangeFilter
