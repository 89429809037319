import React from 'react'

import { I18NCommon } from 'utils/i18nHelpers'
import TrashIcon from 'components/icons/trashIcon'

const DeleteWithIcon = ({ className, children }) => (
  <div className='DeleteWithIcon d-flex text-secondary'>
    <div className='d-flex align-items-center'>
      <TrashIcon className='mr-2' /> <p className='m-0'>{children || I18NCommon('delete')}</p>
    </div>
  </div>
)

export default DeleteWithIcon
