import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button, ButtonSecondary } from 'components/common/buttons'
import RichTextView from 'components/common/richTextView'
import AnswerCard from 'components/admin/surveys/answers/answerCard'
import isValidAnswer from 'utils/surveys/isValidAnswer'
import ProgressBar from 'components/common/progressBar'
import CelebrationIconBanner from 'components/common/celebrationIconBanner'

const I18N = i18nPath('views.surveys.responses')

const ResponseRecordedBanner = ({ form }) => (
  <div className='RespondSurveyPage container'>
    <h2 className='mb-4'>{form?.subject}</h2>
    <CelebrationIconBanner className='py-4 rounded' title={I18N('reponse_recorded')} />
  </div>
)

const RespondSurveyComponent = ({
  form,
  subject,
  instructions,
  currentQuestionIndex,
  currentAnswer,
  updateAnswer,
  onClickBack,
  onClickNext,
  isSaving,
  showResponseRecordedBanner = false,
}) => {
  const questions = form?.questions || []
  const currentQuestion = questions[currentQuestionIndex]
  const isLastQuestion = currentQuestionIndex === questions?.length - 1
  const invalidAnswer = currentQuestion?.required && !isValidAnswer(currentAnswer, currentQuestion)
  const anonymousResponses = form?.anonymousResponses

  if (showResponseRecordedBanner) {
    return <ResponseRecordedBanner form={form} />
  }

  return (
    <>
      <h2>{subject}</h2>
      {currentQuestionIndex === 0 && (
        <RichTextView
          record={{ instructions, id: form?.id }}
          richTextKey='instructions'
          recordType='surveyForm'
        />
      )}
      {anonymousResponses && (
        <div className='text-secondary my-2 font-italic'>
          {I18N('your_response_is_anonymous')}
        </div>
      )}
      <ProgressBar
        className='mt-2'
        completedCount={currentQuestionIndex}
        totalCount={questions?.length}
      />
      {currentQuestion && (
        <AnswerCard
          question={currentQuestion}
          answer={currentAnswer}
          updateAnswer={updateAnswer}
          hasIndexBubble={false}
        />
      )}
      <div className='buttons-container d-flex justify-content-between align-items-center mb-4'>
        <div className='back-button-container'>
          {currentQuestionIndex > 0 && (
            <ButtonSecondary
              onClick={onClickBack}
            >
              {I18NCommon('back')}
            </ButtonSecondary>
          )}
        </div>
        <div className='next-button-container'>
          <Button
            onClick={onClickNext}
            showLoadingSpinner={isSaving}
            disabled={invalidAnswer || isSaving}
          >
            {isLastQuestion ? I18NCommon('submit') : I18NCommon('next')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default RespondSurveyComponent
