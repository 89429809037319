import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.preview_components')

const HighlightColor = () => (
  <div className='Navbar-subnav d-flex align-items-baseline' style={{ background: 'inherit' }}>
    <input placeholder={I18N('hover_me')} />
  </div>
)

export default HighlightColor
