import React, { useRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import classNames from 'classnames'
import moment from 'moment'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('components.datepicker')

interface DateClickType {
  target: {
    value: string
  }
}

interface CustomInputProps {
  className?: string,
  id?: string,
  onChange?: (value: DateClickType) => string,
  onClick?: () => void,
  dateFormat: string,
  placeholderText?: string,
  inputReadOnly: boolean,
  isDisabled?: boolean
  value?: any
}

interface DatePickerProps {
  className?: string,
  id?: string,
  selected?: moment.Moment,
  onChange?: (newTime: moment.Moment) => void,
  showTimeSelect?: boolean,
  showTimeSelectOnly?: boolean,
  timeIntervals?: number,
  isClearable?: boolean,
  dateFormat?: string,
  placeholderText?: string,
  inputReadOnly?: boolean,
  locale?: string,
  children?: React.ReactNode,
  readOnly?: boolean,
  isDisabled?: boolean,
  selectsStart?: boolean,
  selectsEnd?: boolean,
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  minDate?: moment.Moment
  maxDate?: moment.Moment
}

// Currently due to being on a 1.x version of the react-datepicker library, every DatePicker component needs to define its locale
// On 2.x they have an API for setting the global default.

// Using this component allows us to set input to readOnly
// and prevent users from change the date using their keboard.
// Users are forced to interact with the datepicker by clicks.
const CustomInput = React.forwardRef((props: CustomInputProps, ref: React.RefObject<HTMLInputElement>) => {
  const {
    placeholderText, className, onChange,
    onClick, value, id, inputReadOnly = true, isDisabled, dateFormat,
  } = props

  // ref instead of state to dont trigger unecessary rerender
  const lastValueRef = useRef()

  const onFocus = () => {
    lastValueRef.current = value
  }

  const onBlur = () => {
    const momentValue = i18nMoment(value)
    let newValue

    if (!value) {
      newValue = ''
    } else if (momentValue.isValid()) {
      // if we can parse the value, call on change with the value parsed in the format
      newValue = momentValue.format(dateFormat)
    } else {
      // otherwise, set the previous value
      newValue = lastValueRef.current
    }

    if (onChange) {
      onChange({
        target: {
          value: newValue,
        },
      })
    }
  }

  return (
    <input
      id={id}
      ref={ref}
      className={classNames(className)}
      onClick={onClick}
      onChange={onChange}
      value={value}
      type='text'
      placeholder={placeholderText}
      readOnly={inputReadOnly}
      disabled={isDisabled}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
})

const DatePicker = React.forwardRef(
  (
    {
      className,
      id,
      selected,
      onChange = () => { },
      showTimeSelect = true,
      showTimeSelectOnly = false,
      timeIntervals = 15,
      isClearable = true,
      dateFormat = 'LLL',
      placeholderText,
      inputReadOnly = true,
      locale = undefined,
      children,
      readOnly = false,
      isDisabled = false,
      selectsStart = false,
      selectsEnd = false,
      startDate,
      endDate,
      minDate,
      maxDate,
    }: DatePickerProps,
    ref
  ) => {
    const currentUser = useCurrentUser()
    const localeToUse = locale || currentUser?.locale || 'en'

    const handleChange = (newTime: moment.Moment) => {
      // eslint-disable-next-line max-len
      const timeNotInInterval = newTime && (newTime.minute() % timeIntervals !== 0)

      if (!showTimeSelectOnly && showTimeSelect && timeNotInInterval) {
        // If the time is not a multiple of the time intervals, we default to 9 AM
        newTime = newTime
          .clone()
          .hour(9)
          .minute(0)
          .second(0)
          .millisecond(0)
      }

      onChange(newTime)
    }

    return (
      <ReactDatePicker
        customInput={(
          <CustomInput
            placeholderText={placeholderText}
            inputReadOnly={inputReadOnly}
            isDisabled={isDisabled}
            dateFormat={dateFormat}
          />
        )}
        ref={ref || React.createRef()}
        id={id}
        className={classNames('DateTimePicker', className)}
        selected={selected}
        onChange={handleChange}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        isClearable={isClearable}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        locale={localeToUse}
        timeCaption={I18N('time_label')}
        readOnly={readOnly}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        popperClassName={classNames({ onlyDate: !showTimeSelect })}
      >
        {children}
      </ReactDatePicker>
    )
  }
)

export default DatePicker
