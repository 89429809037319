import React from 'react'
import { Link } from 'react-router-dom'

interface BreadcrumbsProps {
  breadcrumbs?: {
    text: string | React.ReactNode
    path?: string | null
  }[]
}

const BreadcrumbText = ({
  text,
}) => (
  <h6 className='mb-0 rounded hover-secondary-gray text-secondary font-weight-600 p-1'>
    {text}
  </h6>
)

const Breadcrumbs = ({
  breadcrumbs = [],
}: BreadcrumbsProps) => (
  <div className='Breadcrumbs d-flex align-items-center'>
    {breadcrumbs.map((breadcrumb, index) => (
      <>
        {!breadcrumb.path ? (
          <BreadcrumbText text={breadcrumb.text} />
        ) : (
          <Link to={breadcrumb.path}>
            <BreadcrumbText text={breadcrumb.text} />
          </Link>
        )}
        {index < breadcrumbs.length - 1
          && <h6 className='mb-0 hover-highlight text-secondary font-weight-600 mx-2'>/</h6>}
      </>
    ))}
  </div>
)

export default Breadcrumbs
