import React from 'react'
import ProgressBar from 'components/common/progressBar'
import BlueprintLink from 'components/admin/journeys/blueprintLink'
import JourneyStatusLabel from 'components/admin/journeys/journeyStatusLabel'
import classNames from 'classnames'

const JourneyProgressWithStatus = ({ journey, className }) => {
  const { journeyBlueprint, completedStepsCount = 0, totalStepsCount = 0 } = journey

  return (
    <div className={classNames('JourneyProgressWithStatus d-flex justify-content-between', className)}>
      <BlueprintLink className='d-flex justify-content-between align-items-center mr-1 w-40' journeyBlueprint={journeyBlueprint} />
      <div className='d-flex flex-row w-60'>
        <ProgressBar className='mr-3 w-70' completedCount={completedStepsCount} totalCount={totalStepsCount} />
        <JourneyStatusLabel className='w-30' journey={journey} />
      </div>
    </div>
  )
}

export default JourneyProgressWithStatus
