import React from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'

const I18N = i18nPath('views.search.global_search')

const QnaCreatorContent = ({ questionOrAnswer }) => {
  if (questionOrAnswer.isAnonymous) {
    return <span>{I18N('anonymous')}</span>
  }

  const { creator } = questionOrAnswer

  return (
    <span>
      <EmployeeThumbnailPhoto employee={creator} linkToProfile={false} size='16px' className='mr-1' />
      <span>{creator?.preferredFullName || I18NCommon('unknown_user')}</span>
    </span>
  )
}

export default QnaCreatorContent
