import React from 'react'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { Link } from 'react-router-dom'

import Card from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'

const I18N = i18nPath('views.surveys.home_widget')

const SurveysWidget = ({ EmptyState = null, widget, resizeWidget }) => {
  const { data: forms, isLoaded } = useFetch(() => API.surveyForms.fetchAll())

  if (_.isEmpty(forms)) {
    return EmptyState
  }

  return (
    <Card
      className='SurveysWidget HomePageWidget'
      cardBodyClassName='p-0'
    >
      <h5 className='mb-0 p-4'>{I18N('surveys')}</h5>
      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        isLoaded={isLoaded && widget.h > 0}
        className='bg-highlight-color-tint-90 border-radius-bottom'
        scrollableContentClassName='px-4 d-flex gap-4'
      >
        <div className='flex-grow-1 py-4'>
          <div className='mb-3'>{I18N('complete_survey_below', { count: forms.length })}</div>

          <div className='d-flex flex-column gap-3'>
            {forms.map(form => (
              <Link
                key={form.id}
                to={form.displayPath}
                className='SurveyLink bg-white p-3 d-flex gap-2 align-items-center border-radius'
              >
                <CdnSvg className='SurveyIcon' src='/images/checkedClipboardIcon.svg' />
                <div>
                  <h5 className='mb-0'>{form.name}</h5>
                  <span className='text-success'>
                    {I18N('due', { date: i18nMoment(form.endTime).format('LL') })}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <CdnSvg src='/images/illustrations/completedChecklist.svg' className='CompletedChecklist py-2' />
      </AutoResizableScrollableSection>
    </Card>
  )
}

export default SurveysWidget
