import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import SuggestedBuddies from 'components/journeys/onboardingBuddies/suggestedBuddies'
import OtherBuddiesList from 'components/journeys/onboardingBuddies/otherBuddiesList'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const SUGGESTED_BUDDIES_TO_FETCH = 4
const I18N = i18nPath('views.journeys.onboarding_buddies')

const OPTED_OUT_STATUS = 'opted_out'
const NEEDS_A_BUDDY_STATUS = 'needs_a_buddy'

const SelectOnboardingBuddyPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const currentUser = useCurrentUser()
  const {
    settings: {
      journeys: {
        onboardingBuddies: { enabled: onboardingBuddiesEnabled },
      },
      onboardingBuddySettings: {
        marketplace: { enabled: buddyMarketplaceEnabled },
      },
    },
  } = useCurrentCompany()
  const isJourneyManager = currentUser.permissions.journeyManager

  const [newHire, setNewHire] = useState()

  const { newHireId } = useQueryParams()

  const {
    isLoadingSuggestedOnboardingBuddies,
    totalPossibleBuddies,
    status,
    isLoadingStatus,
  } = useSelector(onboardingBuddySlice.selectors.getMetaData())
  const suggestedBuddies = useSelector(onboardingBuddySlice.selectors.getSuggestedOnboardingBuddies())

  useEffect(() => {
    dispatch(onboardingBuddySlice.asyncActions.assignedBuddy())
  }, [])

  useEffect(() => {
    const params = { perPage: SUGGESTED_BUDDIES_TO_FETCH }
    if (isJourneyManager && newHireId) {
      params.newHireId = newHireId
    }

    dispatch(onboardingBuddySlice.asyncActions.fetchSuggested(params))
  }, [newHireId])

  const handleSelectNewHire = (newHire) => {
    setNewHire(newHire)
    history.push({
      pathname: '/onboarding_buddies',
      search: newHire?.id ? `?new_hire_id=${newHire.id}` : '',
    })
  }

  if (!onboardingBuddiesEnabled || !buddyMarketplaceEnabled) {
    return <Redirect to='/' />
  }

  if (isLoadingStatus) {
    return <CirclesLoadingIndicator className='mt-5' />
  }

  if (!isJourneyManager && ![OPTED_OUT_STATUS, NEEDS_A_BUDDY_STATUS].includes(status?.asANewHire)) {
    return <Redirect to='/' />
  }

  return (
    <div className='SelectOnboardingBuddyPage'>
      <header>
        <section className='py-2'>
          <h1 className='mb-0'>{I18N('onboarding_buddies')}</h1>
          <p className='text-small text-secondary'>{I18N('choose_buddy_to_get_acclimated')}</p>
          {isJourneyManager && (
            <div className='NewHireSelect d-flex align-items-center'>
              <span className='text-secondary mr-2'>{I18N('new_hire')}</span>
              <EmployeeSearch
                className='flex-grow-1'
                onChange={handleSelectNewHire}
                selectedEmployee={newHire}
                initialSelectedEmployeeId={newHireId}
              />
            </div>
          )}
        </section>
      </header>
      <div className='py-4'>
        <SuggestedBuddies
          isLoading={isLoadingSuggestedOnboardingBuddies}
          buddies={suggestedBuddies}
          newHireId={isJourneyManager ? newHireId : null}
          className='mb-5'
        />
        {!isLoadingSuggestedOnboardingBuddies && totalPossibleBuddies > SUGGESTED_BUDDIES_TO_FETCH && (
          <OtherBuddiesList
            ignoredUserIds={suggestedBuddies.map(user => user.id)}
            buddiesPerPage={2 * SUGGESTED_BUDDIES_TO_FETCH}
            newHireId={isJourneyManager ? newHireId : null}
          />
        )}
      </div>
    </div>
  )
}

export default SelectOnboardingBuddyPage
