import React from 'react'
import GreetingCardBannerImage from 'components/celebrations/greetingCardBannerImage'
import { defaultWorkingCopy } from 'pages/people/celebration_editor_page/celebrationEditorHooks'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.greeting_card')


const GreetingCardBannerNewCelebrationDisplay = () => (
  <GreetingCardBannerImage
    forNewCelebration
    celebration={defaultWorkingCopy}
    className='GreetingCardBannerNewCelebrationDisplay'
    headlineDisplay={(
      <div className='CelebrationHeadlineText'>
        <b>{I18N('new_celebration_title')}</b> <br />
        {I18N('new_celebration_subtitle')}
      </div>
    )}
  />
)

export default GreetingCardBannerNewCelebrationDisplay
