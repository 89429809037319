import React from 'react'

import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SearchResultOption from './searchResultOption'

const bloomfireLogo = '/images/bloomfire.svg'

const I18N = i18nPath('views.search.global_search')

const BloomfireOption = ({ option }) => {
  const currentCompany = useCurrentCompany()
  const logo = currentCompany.settings.bloomfireSearch?.integrationIconPath || bloomfireLogo

  return (
    <SearchResultOption
      image={<CdnSvg src={logo} className='SearchResultOptionImg mr-1' />}
      title={option.title}
      secondaryTexts={[I18N('last_modified', { date: i18nMoment(option.updatedAt).fromNow() })]}
    />
  )
}

export default BloomfireOption
