import React from 'react'

import SearchResultsTemplate from './searchResultsTemplate'
import usePaginatedSearchResults from './hooks/usePaginatedSearchResults'

const ClearyInternalSearchResultsTemplate = ({
  label, type, searchResultToTemplateMap, extraTypes = [], queryType, showLabel = true,
}) => {
  const {
    results, count, page, setPage, isLoading, selected,
  } = usePaginatedSearchResults({
    label, type, extraTypes, queryType,
  })

  return (
    <SearchResultsTemplate
      label={label}
      count={count}
      page={page}
      setPage={setPage}
      isLoading={isLoading}
      selected={selected}
      results={results.map(searchResultToTemplateMap)}
      showLabel={showLabel}
    />
  )
}

export default ClearyInternalSearchResultsTemplate
