import React from 'react'
import { Link } from 'react-router-dom'
import ellipsis from 'utils/ellipsis'

const PageHierarchyBreadcrumbs = ({ ancestors = [] }) => {
  const shouldSliceAndTruncate = ancestors.length >= 3

  let closestAncestors = ancestors.map(ancestor => ({
    ...ancestor,
    title: shouldSliceAndTruncate ? ellipsis(ancestor.title, 10) : ancestor.title,
  }))

  // Get last 3 items on ancestor list
  if (shouldSliceAndTruncate) {
    closestAncestors = closestAncestors.slice(ancestors.length - 3)
  }

  return (
    <div className='PageHierarchyBreadcrumbs d-flex align-items-center'>
      {closestAncestors.map((ancestor, index) => (
        <div className='d-flex align-items-center BreadCrumbs-Ancestors' key={ancestor.id}>
          <Link className='py-2 align-self-center' to={`/pages/${ancestor.slug}`}>
            <div className='Page-parent-category'>{ancestor.title}</div>
          </Link>
          {/* Show separator on all items but last */}
          {index !== closestAncestors.length - 1 && <span className='Page-breadcrumb-separator px-2 mb-1 align-self-center'>&#8250;</span>}
        </div>
      ))}
    </div>
  )
}

export default PageHierarchyBreadcrumbs
