import React from 'react'

const TextColorPrimary = () => (
  <div className='text-primary'>
    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada
    erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.
  </div>
)

export default TextColorPrimary
