import React from 'react'

import Card from 'components/common/card'
import ShoutoutCompanyValues from 'components/shoutouts/shoutoutCompanyValues'
import ShoutoutFrom from 'components/shoutouts/shoutoutFrom'
import ShoutoutText from 'components/shoutouts/shoutoutText'
import ShoutoutSocial from 'components/shoutouts/shoutoutSocial'
import ClickWrapper from 'components/common/clickWrapper'
import ShoutoutCardDropdownMenu from 'components/shoutouts/shoutoutCardDropdownMenu'
import ShoutoutGif from 'components/shoutouts/shoutoutGif'


const ShoutoutCardProfile = ({ shoutout }) => {
  const {
    id: shoutoutId, companyValues, text, sender, createdAt, editedAt,
  } = shoutout

  return (
    <ClickWrapper to={`/people/shoutouts/${shoutout.id}`}>
      <Card cardBodyClassName='ClickWrapperTargetContainer'>
        <ShoutoutCardDropdownMenu shoutout={shoutout} />

        <ShoutoutFrom className='d-inline-block mb-3' sender={sender} createdAt={createdAt} edited={!!editedAt} />

        <ShoutoutText text={text} />

        <ShoutoutGif shoutout={shoutout} />

        <ShoutoutCompanyValues companyValues={companyValues} shoutoutId={shoutoutId} />

        <ShoutoutSocial shoutout={shoutout} />
      </Card>
    </ClickWrapper>
  )
}

export default ShoutoutCardProfile
