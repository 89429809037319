import React from 'react'

import classNames from 'classnames'
import { i18nMoment } from 'utils/i18nHelpers'
import moment from 'moment'

const EventTime = ({ event, className }) => (
  <p className={classNames('link-color mb-1', className)}>
    {i18nMoment(moment(event.eventTime)).format('MMM D, YYYY h:mmA')}
  </p>
)

export default EventTime
