import EffectPicker from 'components/celebrations/effectPicker'
import GreetingCardBannerEditor from 'components/celebrations/greetingCardBannerEditor'
import GreetingCardComments from 'components/celebrations/greetingCardComments'
import RoundedAnimatedButton from 'components/common/buttons/RoundedAnimatedButton'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

const EditCelebrationEditor = ({
  workingCopy,
  setWorkingCopy,
  celebration,
  setShowSidebar,
}) => (
  <>
    <GreetingCardBannerEditor
      workingCopy={workingCopy}
      setWorkingCopy={setWorkingCopy}
    />

    <div className='CelebrationEditor__Main__content-wrapper px-2'>
      <GreetingCardComments
        celebration={celebration}
        inEditPage
      />
    </div>

    <RoundedAnimatedButton
      onClick={() => setShowSidebar(true)}
      className='ShowTemplatesButton'
      variant='primary'
      roundedSize={60}
      text={I18NCommon('templates')}
      icon={<CdnSvg src='/images/pageIcon.svg' />}
      initialOpenStateTimeout={5000}
    />

    <EffectPicker
      effect={workingCopy.effect}
      setEffect={effect => setWorkingCopy({ ...workingCopy, effect })}
    />
  </>
)

export default EditCelebrationEditor
