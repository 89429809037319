import AiMessage from 'components/ai/chat/ask_ai_modal/aiMessage'
import { isGeneratingAiMessage, isUserMessage } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import GeneratingAiMessage from 'components/ai/chat/ask_ai_modal/generatingAiMessage'
import { MessageType } from 'components/ai/chat/ask_ai_modal/types'
import UserMessage from 'components/ai/chat/ask_ai_modal/userMessage'
import React from 'react'

interface ChatMessageProps {
  message: MessageType
}

const ChatMessage = ({ message }: ChatMessageProps) => {
  if (isGeneratingAiMessage(message)) {
    return <GeneratingAiMessage key={message.id} message={message.message} />
  }

  if (isUserMessage(message)) {
    return <UserMessage key={message.id} message={message} />
  }

  return <AiMessage key={message.id} message={message} />
}


export default ChatMessage
