import React from 'react'

import { sortByDateAsc } from 'utils/sortByDate'

import classNames from 'classnames'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

import TimelineCircle from './timelineCircle'

const I18N = i18nPath('views.qna.events.settings_modal.event_schedule')

const EventTimeline = ({ eventDates = [] }) => {
  const sortedDates = sortByDateAsc(eventDates, 'date').filter(dateEntry => dateEntry.date)

  return (
    <div className='Timeline position-relative'>
      <p className='mb-4'>{I18N('event_timeline_preview')}</p>
      <div className='Bubbles d-flex justify-content-between'>
        {sortedDates.map(({ name, date }) => (
          <span key={name} className={classNames('TimelineItem d-inline-flex align-items-center flex-column', name)}>
            <span className='TimelineContent text-center'>{I18N(`event_timeline_${_.snakeCase(name)}`)}</span>
            <span className='Head py-3'>
              <TimelineCircle />
            </span>
            <span className='TimelineDateTime text-center text-small text-secondary'>
              {i18nMoment(date).format('MMM D, h:mm A')}
            </span>
          </span>
        ))}
      </div>
      <div className='Line position-absolute w-100' />
    </div>
  )
}

export default EventTimeline
