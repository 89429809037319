import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.groups.group_page')

const constructMemberLabel = (membership, useLabels = false) => {
  if (!useLabels) {
    return membership.lead ? I18N('lead') : membership.role
  }

  if (membership.lead) {
    return [membership.groupTypeLabel?.name, I18N('lead')].filter(Boolean).join(' ')
  } else {
    return membership.role
  }
}

export default constructMemberLabel
