import React from 'react'
import { Link } from 'react-router-dom'

const StyleguidePage = () => (
  <div className='container mt-4'>
    <h1>Styleguide</h1>
    <ul>
      <li><Link to='/admin/styleguide/typography'>Typography</Link></li>
      <li><Link to='/admin/styleguide/buttons'>Buttons</Link></li>
      <li><Link to='/admin/styleguide/icons'>Icons</Link></li>
      <li><Link to='/admin/styleguide/editable-content'>Editable Content</Link></li>
      <li><Link to='/admin/styleguide/people'>People</Link></li>
      <li><Link to='/admin/styleguide/calendar-events'>Calendar Events</Link></li>
      <li><Link to='/admin/styleguide/tabs'>Tabs</Link></li>
      <li><Link to='/admin/styleguide/tags'>Tags</Link></li>
      <li><Link to='/admin/styleguide/form-fields/'>Form Fields</Link></li>
      <li><Link to='/admin/styleguide/badges'>Badges</Link></li>
      <li><Link to='/admin/styleguide/uploads'>Uploads</Link></li>
      <li><Link to='/admin/styleguide/gif-selector'>Gif Selector</Link></li>
      <li><Link to='/admin/styleguide/other-components'>Other Components</Link></li>
    </ul>
  </div>
)

export default StyleguidePage
