import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SingleStatTile from 'components/analytics/common/singleStatTile'
import cultureAnalyticsSlice from 'redux/slices/analytics/culture'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import classNames from 'classnames'
import TargetingRulesType from 'types/audience/targetingRules'

const I18N = i18nPath('views.platform_analytics.culture')

interface CultureSummaryProps {
  periodStart: string
  periodEnd?: string
  areGreetingCardsEnabled: boolean
  areShoutoutsEnabled: boolean
  targetingRules?: TargetingRulesType
}

const CultureSummary = ({
  periodStart,
  periodEnd,
  areGreetingCardsEnabled,
  areShoutoutsEnabled,
  targetingRules,
}: CultureSummaryProps) => {
  const dispatch = useDispatch()
  const { settings } = useCurrentCompany()
  const areBadgesEnabled = settings?.badges?.enabled

  const {
    celebrationsCardSentCount,
    averageSignaturesAtTime,
    shoutoutsReceivedCount,
    badgesReceivedCount,
  } = useSelector(cultureAnalyticsSlice.selectors.getSummary())
  const { summary: { isLoading } } = useSelector(cultureAnalyticsSlice.selectors.getMetaData())

  const tileCount = (areGreetingCardsEnabled ? 2 : 0) + (areShoutoutsEnabled ? 1 : 0) + (areBadgesEnabled ? 1 : 0)


  useEffect(() => {
    if (tileCount < 2) return

    dispatch(cultureAnalyticsSlice.asyncActions.admin.fetchSummary(
      { periodStart, periodEnd, targetingRules }
    ))
  }, [periodStart, periodEnd, targetingRules])

  // We will only show tiles if there are at least 2
  if (tileCount < 2) return null

  const tileClassName = classNames({
    'grid-span-3': tileCount === 4,
    'grid-span-4': tileCount === 3,
    'grid-span-6': tileCount === 2,
  })

  const celebrationsCardSentCountKey = [
    'celebrations_card_sent_count',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const averageSignaturesAtTimeKey = [
    'average_signatures_at_time',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const shoutoutsReceivedCountKey = [
    'shoutouts_received_count',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const badgesReceivedCountKey = [
    'badges_received_count',
    targetingRules ? 'audience' : 'overall',
  ].join('.')


  return (
    <>
      {areGreetingCardsEnabled && (
        <SingleStatTile
          classname={tileClassName}
          value={celebrationsCardSentCount}
          description={I18N(celebrationsCardSentCountKey)}
          isLoading={isLoading}
        />
      )}
      {areGreetingCardsEnabled && (
        <SingleStatTile
          classname={tileClassName}
          value={Number(averageSignaturesAtTime.toFixed(2))}
          description={I18N(averageSignaturesAtTimeKey)}
          isLoading={isLoading}
        />
      )}
      {areShoutoutsEnabled && (
        <SingleStatTile
          classname={tileClassName}
          value={shoutoutsReceivedCount}
          description={I18N(shoutoutsReceivedCountKey)}
          isLoading={isLoading}
        />
      )}
      {areBadgesEnabled && (
        <SingleStatTile
          classname={tileClassName}
          value={badgesReceivedCount}
          description={I18N(badgesReceivedCountKey)}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default CultureSummary
