import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import DateBasedCelebrationsGrid from 'components/people/dateBasedCelebrationsGrid'
import userSlice from 'redux/slices/users'

import { i18nMoment, i18nDisplayDateWithoutYear } from 'utils/i18nHelpers'
import { trackEvent } from 'services/tracker'

// Change the Moment.js locale to display "1 year" instead of "a year"
moment.updateLocale('en', { relativeTime: { y: '1 year' } })

const WorkAnniversariesPage = () => {
  const dispatch = useDispatch()

  const dispatchFetchEmployees = (filter, paginationParams = { page: 1, perPage: 12 }) => {
    dispatch(userSlice.asyncActions.fetchWorkAnniversaries(filter, paginationParams))
  }

  useEffect(() => {
    dispatchFetchEmployees()
    trackEvent('work_anniversary:view_all')
  }, [])

  const nextWeeksWorkAnniversaries = useSelector(userSlice.selectors.getNextWeeksWorkAnniversaries())
  const thisWeeksWorkAnniversaries = useSelector(userSlice.selectors.getThisWeeksWorkAnniversaries())
  const lastWeeksWorkAnniversaries = useSelector(userSlice.selectors.getLastWeeksWorkAnniversaries())

  const employeesSelector = (filter) => {
    if (filter === 'next_week') return nextWeeksWorkAnniversaries
    if (filter === 'last_week') return lastWeeksWorkAnniversaries
    return thisWeeksWorkAnniversaries
  }

  const renderWorkAnniversariesAdditionalText = employee => (
    <>
      {i18nDisplayDateWithoutYear(employee.startDate)} • {i18nMoment(employee.startDate).fromNow(true)}
    </>
  )

  return (
    <DateBasedCelebrationsGrid
      employeesSelector={employeesSelector}
      i18nPathStr='views.work_anniversaries'
      dispatchFetchEmployees={dispatchFetchEmployees}
      fetchMeta={userSlice.selectors.getWorkAnniversariesMetaData}
      additionalText={renderWorkAnniversariesAdditionalText}
      reportType='work_anniversaries'
    />
  )
}

export default WorkAnniversariesPage
