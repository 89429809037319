import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import shoutoutsSlice from 'redux/slices/shoutouts'
import ShoutoutModal from 'components/shoutouts/shoutoutModal'

const I18N = i18nPath('views.shoutouts')


const ShoutoutCardDropdownMenu = ({ shoutout }) => {
  const dispatch = useDispatch()
  const [showShoutoutsModal, setShowShoutoutsModal] = useState(false)

  const deleteShoutout = () => {
    const confirmDelete = confirm(I18NCommon('delete_confirm'))

    if (confirmDelete) {
      dispatch(shoutoutsSlice.asyncActions.deleteShoutout(shoutout))
    }
  }

  if (!shoutout.permittedActions.edit && !shoutout.permittedActions.delete) {
    return null
  }

  return (
    <div className='position-absolute Card-dropdownMenu'>
      <div className='position-absolute top-0 right-0'>
        <DropdownMenuContainer>
          {shoutout.permittedActions.edit && (
            <CardDropdownMenuItem
              width='150px'
              key={1}
              onClick={() => setShowShoutoutsModal(true)}
              primaryText={I18N('edit_shoutout')}
            />
          )}
          {shoutout.permittedActions.delete && (
            <CardDropdownMenuItem
              width='150px'
              key={2}
              onClick={deleteShoutout}
              primaryText={I18N('delete_shoutout')}
            />
          )}
        </DropdownMenuContainer>
      </div>

      {showShoutoutsModal && (
        <ShoutoutModal
          shoutout={shoutout}
          visible={showShoutoutsModal}
          closeModal={() => setShowShoutoutsModal(false)}
        />
      )}
    </div>
  )
}

export default ShoutoutCardDropdownMenu
