import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import BackButton from 'components/common/backButton'
import { Button, ButtonSecondary } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import FormEditableField from 'components/form_fields/formEditableField'
import API from 'services/api'
import { isAudienceValid } from 'utils/audience'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import buildLayoutCollectionPayload from 'utils/layout/buildLayoutCollectionPayload'
import useFetch from 'components/common/hooks/useFetch'
import LayoutCollectionTemplates from 'components/admin/layout/layoutCollectionTemplates'
import LayoutCollectionTemplatePreview from 'components/admin/layout/layoutCollectionTemplatePreview'
import TargetingRulesType from 'types/audience/targetingRules'

const I18N = i18nPath('views.admin.layout_collections_new')

interface WorkingCopy {
  name: string
  targetingRules: TargetingRulesType
  pageType: string
  templateName: string | null
}

const defaultWorkingCopy = (isPreboarding: boolean): WorkingCopy => ({
  name: '',
  targetingRules: TARGET_ENTIRE_COMPANY,
  pageType: isPreboarding ? 'preboarding' : 'home',
  templateName: null,
})

const CreateLayoutCollectionPage = ({ isPreboarding }) => {
  const [step, setStep] = useState(1)
  const [workingCopy, _setWorkingCopy, updateWorkingCopy] = useUpdatableState<WorkingCopy>(
    defaultWorkingCopy(isPreboarding)
  )
  const { data: layoutCollectionTemplates } = useFetch(
    () => API.admin.layout.layoutCollectionTemplates.fetchAll({ pageType: isPreboarding ? 'preboarding' : 'home' })
  )

  const selectedTemplate = useMemo(() => layoutCollectionTemplates?.find(
    template => template.name === workingCopy.templateName
  ), [workingCopy.templateName])

  return (
    <div className='CreateLayoutCollectionPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N(isPreboarding ? 'create_a_new_preboarding_homepage' : 'create_a_new_homepage')}</h3>
      </header>

      <main className='AdminContent'>
        {step === 1 && (
          <TemplateSelect
            updateWorkingCopy={updateWorkingCopy}
            setStep={setStep}
            layoutCollectionTemplates={layoutCollectionTemplates}
            isPreboarding={isPreboarding}
          />
        )}

        {step === 2 && (
          <TemplatePreview
            updateWorkingCopy={updateWorkingCopy}
            setStep={setStep}
            selectedTemplate={selectedTemplate}
          />
        )}

        {step === 3 && (
          <NameAndAudienceSelect workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} setStep={setStep} />
        )}
      </main>
    </div>
  )
}

const TemplateSelect = ({
  updateWorkingCopy,
  setStep,
  layoutCollectionTemplates,
  isPreboarding,
}) => (
  <div>
    <BackButton
      url={isPreboarding ? '/admin/preboarding_experience' : '/admin/homepage_design'}
      backToText={I18N(isPreboarding ? 'preboarding_experience' : 'homepages')}
      className='mb-4'
    />
    <div className='d-flex justify-content-between'>
      <h5 className='mb-4'>{I18N('quickly_create_from_templates')}</h5>
      <ButtonSecondary onClick={() => setStep(3)}>{I18N('create_from_scratch')}</ButtonSecondary>
    </div>

    <LayoutCollectionTemplates
      templates={layoutCollectionTemplates || []}
      updateWorkingCopy={updateWorkingCopy}
      setStep={setStep}
    />
  </div>
)

const TemplatePreview = ({ updateWorkingCopy, setStep, selectedTemplate }) => {
  const onBackClick = () => {
    updateWorkingCopy({ templateName: null })
    setStep(1)
  }

  return (
    <div>
      <BackButton onClick={onBackClick} className='mb-4' />

      <div className='d-flex justify-content-between'>
        <h5 className='mb-4'>{I18N('Template Details')}</h5>
        <Button onClick={() => setStep(3)}>{I18N('use_template')}</Button>
      </div>

      <LayoutCollectionTemplatePreview selectedTemplate={selectedTemplate} />
    </div>
  )
}

const NameAndAudienceSelect = ({ workingCopy, updateWorkingCopy, setStep }) => {
  const history = useHistory()
  const isCreateDisabled = !workingCopy.name || !isAudienceValid(workingCopy)
  const hasTemplate = !!workingCopy.templateName

  const [createLayoutCollection, { isLoading: isSaving }] = useApi(API.admin.layout.layoutCollections.create, {
    onSuccess: layoutCollection => history.push(layoutCollection.adminDisplayPath),
    toastErrorMessage: I18N('error_creating_layout_collection'),
  })

  const goBack = hasTemplate ? () => setStep(2) : () => setStep(1)

  return (
    <div className='w-50'>
      <BackButton onClick={goBack} className='mb-4' />

      <FormEditableField
        fieldName='name'
        label={I18N('name')}
        onChange={e => updateWorkingCopy({ name: e.target.value })}
        currentValue={workingCopy.name}
        isRequired
        placeholder={I18N('name_placeholder')}
        autoFocus
      />

      <AudienceToggleSelector
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        translationsPath='views.admin.layout_collections_audience_toggle_selector'
        className='mb-4'
        moduleName='feed'
      />

      <Button
        onClick={() => createLayoutCollection({
          ...buildLayoutCollectionPayload(workingCopy),
        })}
        disabled={isCreateDisabled || isSaving}
        showLoadingSpinner={isSaving}
      >
        {hasTemplate ? I18N('apply_template') : I18NCommon('create')}
      </Button>
    </div>
  )
}

export default CreateLayoutCollectionPage
