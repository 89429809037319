import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { Button, ButtonLink } from 'components/common/buttons'
import CheckIcon from 'components/icons/checkIcon'
import taskSlice from 'redux/slices/journeys/tasks'

const I18N = i18nPath('views.journeys.task_detail')

const UpdateTaskCompletionStatusButton = ({
  task,
  onCompleteOrReopen,
  isPreview = false,
}) => {
  const dispatch = useDispatch()
  const { isSaving } = useSelector(taskSlice.selectors.getMetaData())

  const permittedActions = task?.permittedActions
  const isTaskCompleted = task?.completed
  const isSurveyTask = task?.surveyTask
  const isSurveyResponseCompleted = task?.surveyCompleted
  const completionPath = task?.completionPath
  const completeAllowed = permittedActions?.complete
  const showMarkAsCompletedButton = completeAllowed && !isTaskCompleted
  const showReopenButton = permittedActions?.reopen && isTaskCompleted

  const onCompleteClick = () => {
    if (isPreview) {
      return
    }

    dispatch(taskSlice.asyncActions.completeTask(task.id, onCompleteOrReopen))
  }

  const onReopenClick = () => {
    dispatch(taskSlice.asyncActions.reopenTask(task.id, onCompleteOrReopen))
  }

  if (isSurveyTask && !isSurveyResponseCompleted) {
    return (
      <>
        {showMarkAsCompletedButton && (
          <ButtonLink
            to={completionPath}
          >
            {I18N('complete_survey')}
          </ButtonLink>
        )}
      </>
    )
  }

  if (showMarkAsCompletedButton) {
    return (
      <Button disabled={isSaving} onClick={onCompleteClick}>
        <CheckIcon className='mr-2' fillColor='#FFFFFF' />
        {I18N('mark_as_completed')}
      </Button>
    )
  }

  if (showReopenButton) {
    return (
      <Button disabled={isSaving} onClick={onReopenClick}>
        {I18N('reopen_task')}
      </Button>
    )
  }

  return null
}

export default UpdateTaskCompletionStatusButton
