import React from 'react'

interface Props {
  title: any
  left?: any
  right?: any
}

const EditorNavbar = ({ title, left, right }: Props) => (
  <div className='EditorNavbar px-2 d-flex justify-content-between align-items-center'>
    <div className='w-100 d-flex justify-content-start align-items-center'>
      {left}
    </div>
    <div className='w-100 d-flex justify-content-center align-items-center'>
      {title}
    </div>
    <div className='w-100 d-flex justify-content-end align-items-center'>
      {right}
    </div>
  </div>
)

export default EditorNavbar
