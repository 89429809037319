import React from 'react'
import { Link } from 'react-router-dom'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import API from 'services/api'
import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.admin.logo_list')

const LogoListPage = () => {
  const { data: logos, isLoading } = useFetch(API.admin.logos.fetchAll)


  return (
    <div className='LogoListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3>{I18N('header')}</h3>
        <div className='AdminButtonContainer'>
          <Link to='/admin/logos/new'>
            <Button>
              {I18N('create_logo')}
            </Button>
          </Link>
        </div>
      </header>

      <main className='AdminContent'>
        <LoadingContainer isLoading={isLoading} useCirclesLoadingIndicator>
          <table className='white-bg-table'>
            <thead>
              <tr>
                <th>{I18N('title')}</th>
                <th>{I18N('start_time')}</th>
                <th>{I18N('end_time')}</th>
                <th>{I18N('created')}</th>
              </tr>
            </thead>
            <tbody>
              {(logos || []).map(logo => (
                <tr key={`badge-${logo.id}`}>
                  <td>
                    <Link to={`/admin/logos/${logo.id}`}>
                      <span>{logo.title}</span>
                    </Link>
                  </td>
                  <td>{i18nMoment(logo.startTime).format('ll')}</td>
                  <td>{i18nMoment(logo.endTime).format('ll')}</td>
                  <td>{i18nMoment(logo.createdAt).format('ll')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default LogoListPage
