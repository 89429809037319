import AiAnswersInsightsTheme from 'components/analytics/ai_answers/aiAnswersInsightsTheme'
import CdnSvg from 'components/common/cdnSvg'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import React from 'react'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.ai_answers.insights')

const AiAnswersInsights = ({ startDate, endDate }) => {
  const { data: themeCollection, isLoading } = useFetch(
    () => API.admin.analytics.aiAnswers.fetchInsights({ startDate, endDate }),
    [startDate, endDate]
  )

  const showLoading = isLoading || !themeCollection
  const showInsights = !isLoading && themeCollection?.themes?.length
  const showEmptyState = !isLoading && !showInsights

  return (
    <div className='AiAnswersInsights analytics-card grid-span-12 visible-scroll'>
      <header className='pb-3'>
        <h3 className='mb-0'>{I18N('title')}</h3>
        <div className='text-secondary'>{I18N('description')}</div>
      </header>
      {showLoading && (
        <div className='w-100 d-flex align-items-center justify-content-center'>
          <CirclesLoadingIndicator />
        </div>
      )}
      {showInsights && (
        <ThemeCollection themeCollection={themeCollection} />
      )}
      {showEmptyState && <EmptyState />}
    </div>
  )
}

const ThemeCollection = ({ themeCollection }) => {
  const themes = themeCollection.themes

  return (
    <div className='AiAnswersInsightsThemeCollection w-100'>
      {themes.map((theme, index) => (
        <div key={theme.id}>
          <AiAnswersInsightsTheme theme={theme} />
          {index < themes.length - 1 && <hr />}
        </div>
      ))}
    </div>
  )
}

const EmptyState = () => (
  <div className='AiInsightsEmptyState w-100 d-flex align-items-center justify-content-center gap-3'>
    <CdnSvg src='/images/illustrations/womanWithSpyglass.svg' />
    <div className='text-small'>{I18N('empty_state')}</div>
  </div>
)

export default AiAnswersInsights
