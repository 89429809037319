import React from 'react'

const QNAManagerEmptyEventsIcon = ({ className }) => (
  <svg
    className={className}
    data-testid='cy_qna_manager_empty_events_icon'
    width='100px'
    height='100px'
    viewBox='0 0 100 100'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-670.000000, -236.000000)'>
        <g transform='translate(670.000000, 236.000000)'>
          <circle fill='#FFFFFF' cx='50' cy='50' r='50' />
          <g transform='translate(27.000000, 29.000000)' fillRule='nonzero'>
            <rect fill='#1461DD' x='36.9642857' y='4.92857143' width='1.64285714' height='1.64285714' />
            <rect fill='#1461DD' x='24.6428571' y='4.92857143' width='1.64285714' height='1.64285714' />
            <rect fill='#2486FC' x='12.3214286' y='4.92857143' width='1.64285714' height='1.64285714' />
            <path
              d='M41.8928571,4.92857143 L4.10714286,4.92857143 C1.83883049,4.92857143 0,6.76740192 0,9.03571429 L0,36.9642857 C0,39.2325981 1.83883049,41.0714286 4.10714286,41.0714286 L41.8928571,41.0714286 C44.1611695,41.0714286 46,39.2325981 46,36.9642857 L46,9.03571429 C46,6.76740192 44.1611695,4.92857143 41.8928571,4.92857143 Z'
              fill='#EEF4FF'
            />
            <path
              d='M46,9.03571429 L46,36.9642857 C45.999184,39.23226 44.1608314,41.0706125 41.8928571,41.0714286 L23,41.0714286 L23,4.92857143 L41.8928571,4.92857143 C44.1608314,4.92938746 45.999184,6.76774002 46,9.03571429 Z'
              fill='#D1DBED'
            />
            <path
              d='M46,9.03571429 L46,13.1428571 L0,13.1428571 L0,9.03571429 C0.00081603016,6.76774002 1.83916859,4.92938746 4.10714286,4.92857143 L41.8928571,4.92857143 C44.1608314,4.92938746 45.999184,6.76774002 46,9.03571429 Z'
              fill='#53A6F9'
            />
            <path
              d='M46,9.03571429 L46,13.1428571 L23,13.1428571 L23,4.92857143 L41.8928571,4.92857143 C44.1608314,4.92938746 45.999184,6.76774002 46,9.03571429 Z'
              fill='#2486FC'
            />
            <path
              d='M36.9642857,4.92857143 L36.9642857,3.28571429 C36.9642857,2.37838934 36.2287535,1.64285714 35.3214286,1.64285714 C34.4141036,1.64285714 33.6785714,2.37838934 33.6785714,3.28571429 L33.6785714,8.21428571 C33.6785714,9.12161066 34.4141036,9.85714286 35.3214286,9.85714286 C36.2287535,9.85714286 36.9642857,9.12161066 36.9642857,8.21428571 L38.6071429,8.21428571 C38.6071429,10.0289356 37.1360785,11.5 35.3214286,11.5 C33.5067787,11.5 32.0357143,10.0289356 32.0357143,8.21428571 L32.0357143,3.28571429 C32.0357143,1.47106439 33.5067787,0 35.3214286,0 C37.1360785,0 38.6071429,1.47106439 38.6071429,3.28571429 L38.6071429,4.92857143 L36.9642857,4.92857143 Z'
              fill='#1461DD'
            />
            <path
              d='M24.6428571,4.92857143 L24.6428571,3.28571429 C24.6428571,2.37838934 23.9073249,1.64285714 23,1.64285714 C22.0926751,1.64285714 21.3571429,2.37838934 21.3571429,3.28571429 L21.3571429,8.21428571 C21.3571429,9.12161066 22.0926751,9.85714286 23,9.85714286 C23.9073249,9.85714286 24.6428571,9.12161066 24.6428571,8.21428571 L26.2857143,8.21428571 C26.2857143,10.0289356 24.8146499,11.5 23,11.5 C21.1853501,11.5 19.7142857,10.0289356 19.7142857,8.21428571 L19.7142857,3.28571429 C19.7142857,1.47106439 21.1853501,0 23,0 C24.8146499,0 26.2857143,1.47106439 26.2857143,3.28571429 L26.2857143,4.92857143 L24.6428571,4.92857143 Z'
              fill='#2486FC'
            />
            <path
              d='M12.3214286,4.92857143 L12.3214286,3.28571429 C12.3214286,2.37838934 11.5858964,1.64285714 10.6785714,1.64285714 C9.77124648,1.64285714 9.03571429,2.37838934 9.03571429,3.28571429 L9.03571429,8.21428571 C9.03571429,9.12161066 9.77124648,9.85714286 10.6785714,9.85714286 C11.5858964,9.85714286 12.3214286,9.12161066 12.3214286,8.21428571 L13.9642857,8.21428571 C13.9642857,10.0289356 12.4932213,11.5 10.6785714,11.5 C8.86392154,11.5 7.39285714,10.0289356 7.39285714,8.21428571 L7.39285714,3.28571429 C7.39285714,1.47106439 8.86392154,0 10.6785714,0 C12.4932213,0 13.9642857,1.47106439 13.9642857,3.28571429 L13.9642857,4.92857143 L12.3214286,4.92857143 Z'
              fill='#2486FC'
            />
            <path
              d='M26.2857144,3.28571429 L26.2857144,4.92857143 L24.6428571,4.92857143 L24.6428571,3.28571429 C24.6401956,2.3794941 23.9062202,1.64551873 23,1.64285714 L23,-1.2448966e-07 C23.8714991,-0.000239761165 24.7073727,0.34585572 25.3236156,0.962098659 C25.9398586,1.5783416 26.285954,2.4142152 26.2857144,3.28571429 Z'
              fill='#1461DD'
            />
            <path
              d='M26.2857144,8.21428571 C26.285954,9.0857848 25.9398586,9.9216584 25.3236156,10.5379013 C24.7073727,11.1541443 23.8714991,11.5002398 23,11.5000001 L23,9.85714286 C23.9062202,9.85448127 24.6401956,9.1205059 24.6428571,8.21428571 L26.2857144,8.21428571 Z'
              fill='#1461DD'
            />
            <path
              d='M45.7617857,29.3332143 L35.0832143,40.0117857 C34.9573087,40.129504 34.8006926,40.2092358 34.6314286,40.2417857 L29.7028571,41.0632143 C29.6593989,41.0700386 29.6153983,41.0727886 29.5714286,41.0714286 C29.3298268,41.0732144 29.0997851,40.9681419 28.9429402,40.7843641 C28.7860954,40.6005863 28.7184813,40.3568903 28.7582143,40.1185714 L29.5796429,35.19 C29.6121928,35.0207359 29.6919246,34.8641199 29.8096429,34.7382143 L37.8514286,26.6964286 L40.4882143,24.0596429 C40.8110422,23.7393039 41.3318149,23.7393039 41.6546429,24.0596429 L45.7617857,28.1667857 C46.0821247,28.4896137 46.0821247,29.0103863 45.7617857,29.3332143 Z'
              fill='#2486FC'
            />
            <path
              d='M35.2557143,39.8392857 L35.0832143,40.0117857 C34.9573087,40.129504 34.8006926,40.2092358 34.6314286,40.2417857 L29.7028571,41.0632143 C29.6593989,41.0700386 29.6153983,41.0727886 29.5714286,41.0714286 C29.3298268,41.0732144 29.0997851,40.9681419 28.9429402,40.7843641 C28.7860954,40.6005863 28.7184813,40.3568903 28.7582143,40.1185714 L29.5796429,35.19 C29.6121928,35.0207359 29.6919246,34.8641199 29.8096429,34.7382143 L29.9821429,34.5657143 L35.2557143,39.8392857 Z'
              fill='#1461DD'
            />
            <path
              d='M45.7617857,29.3332143 L43.125,31.97 L37.8514286,26.6964286 L40.4882143,24.0596429 C40.8110422,23.7393039 41.3318149,23.7393039 41.6546429,24.0596429 L45.7617857,28.1667857 C46.0821247,28.4896137 46.0821247,29.0103863 45.7617857,29.3332143 Z'
              fill='#1461DD'
            />
            <rect fill='#53A6F9' x='21.3571429' y='25.4642857' width='3.28571429' height='3.28571429' />
            <rect fill='#2486FC' x='28.75' y='25.4642857' width='3.28571429' height='3.28571429' />
            <rect fill='#53A6F9' x='21.3571429' y='32.0357143' width='3.28571429' height='3.28571429' />
            <rect fill='#53A6F9' x='21.3571429' y='18.8928571' width='3.28571429' height='3.28571429' />
            <rect fill='#53A6F9' x='13.9642857' y='25.4642857' width='3.28571429' height='3.28571429' />
            <rect fill='#53A6F9' x='13.9642857' y='32.0357143' width='3.28571429' height='3.28571429' />
            <rect fill='#53A6F9' x='13.9642857' y='18.8928571' width='3.28571429' height='3.28571429' />
            <rect fill='#53A6F9' x='6.57142857' y='25.4642857' width='3.28571429' height='3.28571429' />
            <rect fill='#53A6F9' x='6.57142857' y='32.0357143' width='3.28571429' height='3.28571429' />
            <rect fill='#53A6F9' x='6.57142857' y='18.8928571' width='3.28571429' height='3.28571429' />
            <rect
              fill='#2486FC'
              transform='translate(30.392857, 20.535714) rotate(-180.000000) translate(-30.392857, -20.535714) '
              x='28.75'
              y='18.8928571'
              width='3.28571429'
              height='3.28571429'
            />
            <rect
              fill='#2486FC'
              transform='translate(37.785714, 20.535714) rotate(-180.000000) translate(-37.785714, -20.535714) '
              x='36.1428571'
              y='18.8928571'
              width='3.28571429'
              height='3.28571429'
            />
            <rect fill='#2486FC' x='23' y='18.8928571' width='1.64285714' height='3.28571429' />
            <rect fill='#2486FC' x='23' y='25.4642857' width='1.64285714' height='3.28571429' />
            <rect fill='#2486FC' x='23' y='32.0357143' width='1.64285714' height='3.28571429' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default QNAManagerEmptyEventsIcon
