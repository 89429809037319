
import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import companyConfigurationSlice from 'redux/slices/companyConfigurations'

// This hook is used to build a form that manages a single company configuration
// exposes a connect function that can be used to connect form inputs to the working copy of the configuration
// exposes a onSubmit function that can be used to save the working copy to the server
// isLoading and isSaving are booleans that can be used to show loading spinners or disable the save button
// check SocialShareCard.tsx for an example of how to use this hook
// There is no spec for this file since it's tested indirectly by the components that use it
const useCompanyConfigurationForm = (configName: string) => {
  const dispatch = useDispatch()

  const { isSaving, isLoading } = useSelector(companyConfigurationSlice.selectors.getMetaData())
  const config = useSelector(companyConfigurationSlice.selectors.getCompanyConfiguration(configName))

  const [workingCopy, setWorkingCopy] = useState({})

  const onSubmit = () => {
    dispatch(companyConfigurationSlice.asyncActions.admin.update(
      configName,
      workingCopy,
      { onSuccess: () => window.location.reload() }
    ))
  }

  useEffect(() => {
    dispatch(companyConfigurationSlice.asyncActions.admin.fetch(configName))
  }, [])

  useEffect(() => {
    if (config?.value) {
      setWorkingCopy(config.value)
    }
  }, [JSON.stringify(config?.value)])

  const connect = (settingKey: string) => ({
    onChange: (value) => {
      setWorkingCopy((oldValues) => {
        const newValues = _.cloneDeep(oldValues)

        _.set(newValues, settingKey, value)

        return newValues
      })
    },
    checked: _.get(workingCopy, settingKey),
    value: _.get(workingCopy, settingKey),
  })

  return {
    connect,
    onSubmit,
    isLoading,
    isSaving,
  }
}

export default useCompanyConfigurationForm
