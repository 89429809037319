import { useLayoutEffect } from 'react'
import updateCSSVariable from 'utils/updateCSSVariable'

function useSetCSSVarOnMont(variableName, value) {
  // Use useLayoutEffect so changes take effect before rendering.
  // Going with useEffect will trigger an unwanted flash effect.
  useLayoutEffect(() => {
    const currentValue = getComputedStyle(document.documentElement).getPropertyValue(variableName)

    updateCSSVariable({ variableName, value })

    return () => updateCSSVariable({ variableName, value: currentValue })
  }, [])
}

export default useSetCSSVarOnMont
