import React from 'react'
import classNames from 'classnames'

const VideoIcon = ({ className }) => (
  <svg className={classNames('VideoIcon', className)} width='21px' height='14px' viewBox='0 0 21 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Shape' transform='translate(-2.000000, -5.000000)' fill='#C6C6C7'>
        <path d='M21,7.15 C20.3789186,6.87035235 19.6508811,6.98841249 19.15,7.45 L17,9.45 L17,8 C17,6.34314575 15.6568542,5 14,5 L5,5 C3.34314575,5 2,6.34314575 2,8 L2,16 C2,17.6568542 3.34314575,19 5,19 L14,19 C15.6568542,19 17,17.6568542 17,16 L17,14.55 L19.16,16.55 C19.4779868,16.8378457 19.8910861,16.9980997 20.32,17 C20.5580292,16.9994457 20.7932235,16.9483165 21.01,16.85 C21.6133066,16.605952 22.0086817,16.0207969 22.01,15.37 L22.01,8.63 C22.0070389,7.97673903 21.6072337,7.39088575 21,7.15 Z M15,16 C15,16.5522847 14.5522847,17 14,17 L5,17 C4.44771525,17 4,16.5522847 4,16 L4,8 C4,7.44771525 4.44771525,7 5,7 L14,7 C14.5522847,7 15,7.44771525 15,8 L15,16 Z M20,14.6 L17.19,12 L20,9.4 L20,14.6 Z' />
      </g>
    </g>
  </svg>
)

export default VideoIcon
