import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import UserRadioButtonInput from 'components/admin/users/userEditor/userRadioButtonInput'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.users')

const UserSettingRow = ({
  inputClassName = '',
  className = '',
  fieldName,
  user,
  setUser,
  defaultValue = false,
  disabled = false,
  isValueInverted = false,
}) => (
  <div className={classNames('d-flex', className)}>
    <div className='d-flex flex-column w-70'>
      <p className='text-normal mb-2 font-weight-500'>{I18N(`is_user_${_.snakeCase(fieldName)}`)}</p>
      <p className='text-secondary mb-0'>{I18N(`is_user_${_.snakeCase(fieldName)}_note`)}</p>
    </div>
    <div>
      <UserRadioButtonInput
        className={inputClassName}
        user={user}
        setUser={setUser}
        fieldName={fieldName}
        defaultValue={defaultValue}
        isValueInverted={isValueInverted}
        disabled={disabled}
      />
    </div>
  </div>
)

const UserSettingsSection = ({
  user = null,
  setUser = () => { },
}) => {
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany

  const isClearyAdmin = currentUser.permissions.clearyAdmin
  const isUserActive = user.active || false
  const isPreboardingActive = settings.journeys?.preboarding?.enabled
  const isPreboarding = isPreboardingActive && (user.preboarding || false)
  const companyExternalAlertsEnabled = settings.externalAlerts.enabled

  // Only allow Cleary admins to change the user's notifications settings when the company setting is disabled
  const showApproveExternalAlertsCheckbox = isUserActive && isClearyAdmin && !companyExternalAlertsEnabled
  const showSuppressExternalAlertsCheckbox = isUserActive && companyExternalAlertsEnabled

  const onPreboardingChange = (newUser) => {
    if (newUser?.preboarding) {
      setUser({ ...newUser, hidden: true, visibleInOrgChart: false })
    } else {
      setUser({ ...newUser })
    }
  }

  return (
    <>
      <h6 className='text-secondary mt-4 font-weight-600'>{I18N('user_settings')}</h6>
      <div className='UserSettingsSection background-color-white p-4'>
        <UserSettingRow fieldName='active' user={user} setUser={setUser} />
        {settings.journeys?.preboarding?.enabled && (
          <UserSettingRow
            fieldName='preboarding'
            className='mt-4'
            user={user}
            setUser={onPreboardingChange}
          />
        )}
        <UserSettingRow
          fieldName='hidden'
          className='mt-4'
          user={user}
          setUser={newUser => setUser({ ...newUser, visibleInOrgChart: !newUser.hidden })}
          disabled={isPreboarding}
        />
        <UserSettingRow
          fieldName='visibleInOrgChart'
          className='mt-4'
          user={user}
          setUser={setUser}
          disabled={isPreboarding}
        />
        {showApproveExternalAlertsCheckbox && (
          <UserSettingRow
            fieldName='approvedForExternalAlerts'
            className='mt-4'
            user={user}
            setUser={setUser}
            inputClassName='notifications-setting-field'
          />
        )}
        {/* When the company has external alerts enabled, users will receive emails unless suppress alerts is false, so this boolean work the other way around
          Since the option indictes whether the user will receive emails, we need to invert the boolean value */}
        {showSuppressExternalAlertsCheckbox && (
          <UserSettingRow
            fieldName='suppressExternalAlerts'
            className='mt-4'
            user={user}
            setUser={setUser}
            inputClassName='notifications-setting-field'
            isValueInverted
          />
        )}
      </div>
    </>
  )
}

export default UserSettingsSection
