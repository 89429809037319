import React, { useCallback, useEffect } from 'react'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/common/card'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import CheckIcon from 'components/icons/checkIcon'
import LoadingSpinner from 'components/common/loadingSpinner'
import itAdminSlice from 'redux/slices/itAdmin'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const mergedevLogoPath = '/images/integrations/mergedevLogo.svg'

const I18N = i18nPath('views.integrations_page.integration_card')

const MergedevInstallationComponent = ({ linkToken, onSuccessCallback }) => {
  const onSuccess = useCallback((publicToken) => {
    onSuccessCallback(publicToken)
  }, [])

  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
  })

  if (!isReady) return (<LoadingSpinner />)

  return (
    <button className='btn btn-primary mt-4' disabled={!isReady} onClick={open}>
      {I18N('mergedev.integration_button')}
    </button>
  )
}

const MergedevIntegrationCard = ({ isOtherInstalled }) => {
  const dispatch = useDispatch()

  const { name, appName } = useCurrentCompany()
  const { isFetchingMergedevLinkToken } = useSelector(itAdminSlice.selectors.getMetaData())
  const mergedevLinkToken = useSelector(itAdminSlice.selectors.getMergedevLinkToken())
  const { mergedevIntegrations } = useSelector(itAdminSlice.selectors.getData())

  const integrationName = I18N('mergedev.name')

  useEffect(() => {
    if (!isOtherInstalled) {
      dispatch(itAdminSlice.asyncActions.admin.fetchMergedevLinkToken())
    }
  }, [])

  if (isFetchingMergedevLinkToken) return (<LoadingSpinner />)

  const mergedevInstallOnSuccess = publicToken => dispatch(itAdminSlice.asyncActions.admin.setupMergedev(publicToken))

  const uninstallMergedev = (name) => {
    if (confirm(I18N('mergedev.confirm_remove_integration'))) {
      dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration(name))
    }
  }

  return (
    <Card
      className={classNames('IntegrationCard', { isInstalled: mergedevIntegrations?.length })}
      title={(
        <div className='d-flex align-items-center'>
          <div className='IntegrationLogo mr-1'><CdnSvg src={mergedevLogoPath} /></div>
          <h5 className='mb-0'>{integrationName}</h5>
        </div>
      )}
    >
      <div>{I18N('mergedev.description', { appName, companyName: name })}</div>
      <hr />

      {isOtherInstalled ? (
        <div className='text-secondary text-small'>
          {I18n.t('views.integrations_page.integration_card.only_one_hris_integration')}
        </div>
      ) : (
        <>
          {(mergedevIntegrations || []).map((integration, index) => (
            <div key={index} className='d-flex align-items-center mb-1'>
              <CheckIcon fillColor='var(--success-color)' className='CheckIcon mr-1' width='10px' height='10px' />
              <div className='text-success text-small'>
                {I18N('mergedev.successfully_installed', { name: integration.name, type: integration.type.toUpperCase() })}
              </div>
              <button className='btn btn-link' onClick={() => uninstallMergedev(`mergedev${integration.type}`)}>×</button>
            </div>
          ))}
          {mergedevLinkToken && (
            <MergedevInstallationComponent
              linkToken={mergedevLinkToken}
              onSuccessCallback={mergedevInstallOnSuccess}
            />
          )}
        </>
      )}
    </Card>
  )
}

export default MergedevIntegrationCard
