import React, { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick'
import { useSelector, useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'

import profileSlice from 'redux/slices/profiles'
import Modal from 'components/common/modal'
import AddProfilePicture from 'components/common/images/addProfilePicture'
import classNames from 'classnames'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CdnSvg from 'components/common/cdnSvg'

const chevronLeftIconPath = '/images/chevronLeftOutline.svg'
const chevronRighttIconPath = '/images/chevronRightOutline.svg'

const I18N = i18nPath('views.profile.photos')

const SliderArrow = ({ className, onClick, children }) => (
  <span className={className} onClick={onClick}>
    {children}
  </span>
)

const CustomPaging = ({ i, photoList, sliderRef }) => {
  const currentPhoto = photoList.find((_, index) => index === i)

  return <img onClick={() => sliderRef.current.slickGoTo(i)} src={currentPhoto.thumbnailUrl} />
}

const PhotosSlider = ({
  closeModal,
  modalPhotos,
  owner,
  slice = profileSlice,
}) => {
  const dispatch = useDispatch()
  const [photoList, setPhotolist] = useState(modalPhotos)
  const currentUser = useCurrentUser()
  const { photos } = owner ?? {}
  const { isPhotoUploading } = useSelector(slice.selectors.getMetaData())
  const showImageSelector = currentUser.permittedActions.managePhotos && !isPhotoUploading
  const sliderRef = useRef()

  const createPhoto = (photo, { croppedAreaPixels }) => {
    dispatch(slice.asyncActions.createPhoto(photo, owner, croppedAreaPixels))
  }

  useEffect(() => {
    setPhotolist(_.uniqBy(photoList.concat(photos), 'id'))
  }, [photos?.length])

  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <SliderArrow>
        <CdnSvg className='Chevron' src={chevronLeftIconPath} />
      </SliderArrow>
    ),
    nextArrow: (
      <SliderArrow>
        <CdnSvg className='Chevron' src={chevronRighttIconPath} />
      </SliderArrow>
    ),
    dots: true,
    customPaging: i => <CustomPaging i={i} photoList={photoList} photos={photos} sliderRef={sliderRef} />,
    appendDots: (dots) => {
      const DisplayGridLayout = dots.length > 7
      return (
        <div>
          <div className={classNames('DotImageList d-flex', { DisplayGridLayout })}>
            {dots}
            <li>
              <AddProfilePicture
                isVisible={showImageSelector}
                isLoading={isPhotoUploading}
                onImageAdded={createPhoto}
              />
            </li>
          </div>
        </div>
      )
    },
  }

  const modalHeader = owner?.id === currentUser.id ? <h1 className='font-weight-700 d-none d-md-block'>{I18N('your_photos')}</h1> : null

  return (
    <Modal className='PhotosSlider' size='lg' visible toggle={closeModal}>
      {modalHeader}
      <Slider ref={sliderRef} className='PhotosSlider p-4' {...sliderSettings}>
        {photoList.map(photo => (
          <img className='img-fluid' key={photo.id} src={photo.originalImageUrl} />
        ))}
      </Slider>
    </Modal>
  )
}

export default PhotosSlider
