import React, { useEffect, useRef } from 'react'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { askAiI18n } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'

type Props = {
  scrollingDirection: 'up' | 'down'
}

const ChatTabGreetingHeader = ({ scrollingDirection }: Props) => {
  const { preferredName } = useCurrentUser()
  const {
    messages,
  } = useAskAiContext()

  const collapsableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollingDirection === 'up' || messages.length === 0) {
      collapsableRef.current!.style.height = collapsableRef.current!.scrollHeight + 'px'
    } else {
      collapsableRef.current!.style.height = '0'
    }
  }, [scrollingDirection, messages])

  return (
    <div className='AskAiModal-subheader'>
      <div className='collapsable-greeting' ref={collapsableRef}>
        <div className='d-flex justify-content-between align-items-center px-4 py-3'>
          <div>
            <p className='text-white greeting-small my-1'>
              {askAiI18n('greeting_title', { name: preferredName })}
              <span role='img' aria-label='wave' className='ml-1'>
                  👋
              </span>,
            </p>
            <p className='text-white greeting-normal mb-1'>
              {askAiI18n('greeting_subtitle')}
            </p>
          </div>
          <div>
            <CdnSvg src='/images/illustrations/aiStars.svg' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatTabGreetingHeader
