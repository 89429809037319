import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api'
import appSignal from 'services/appSignal'

const initialState = {
  events: [],
  meta: {
    isLoading: true,
  },
}

const companyCalendarEventsSlice = createSlice({
  name: 'companyCalendarEvents',
  initialState,
  reducers: {
    setEvents(state, action) {
      state.events = action.payload
    },
    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },
  },
})

//
//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(companyCalendarEventsSlice, {
  asyncActions: {
    fetchUpcomingEvents: () => async (dispatch) => {
      dispatch(companyCalendarEventsSlice.actions.setIsLoading(true))

      try {
        const response = await API.calendars.fetchUpcomingEvents(25)
        dispatch(companyCalendarEventsSlice.actions.setEvents(response.data))
        dispatch(companyCalendarEventsSlice.actions.setIsLoading(false))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(companyCalendarEventsSlice.actions.setIsLoading(false))
      }
    },
  },
})

export default companyCalendarEventsSlice
