
import React, { useState } from 'react'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import TagsSelect from 'components/common/tagsSelect'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.article_editor')

const ArticleTagsSelect = ({
  value, onChange,
}, ref) => {
  const { permissions } = useCurrentUser()
  const [showSelect, setShowSelect] = useState(!_.isEmpty(value))
  const [autoFocusSelect, setAutoFocusSelect] = useState(false)

  const handleAddTagsClick = () => {
    setShowSelect(true)
    setAutoFocusSelect(true)
  }

  if (showSelect) {
    return (
      <TagsSelect
        ref={ref}
        value={value}
        onChange={onChange}
        autoFocus={autoFocusSelect}
        className='w-60'
        creatable={permissions.articleManager}
      />
    )
  }

  return (
    <div
      ref={ref}
      className='text-secondary text-small pointer font-weight-500'
      onClick={handleAddTagsClick}
    >
      + {I18N('add_tags')}
    </div>
  )
}

export default React.forwardRef(ArticleTagsSelect)
