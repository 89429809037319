import React, {
  useState, useEffect, useRef, ReactElement
} from 'react'
import Session from 'services/session'
import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.authentication.login_form')
const RETRY_LATER_STATUS = 429

interface FormState {
  email: string
  password: string
}

const RETRY_LATER = 'retry_later'
const INVALID_ATTEMPT = 'invalid_attempt'
type FormError = boolean | 'invalid_attempt' | 'retry_later'

const PasswordLoginForm = (): ReactElement => {
  const [form, setForm] = useState<FormState>({
    email: '',
    password: '',
  })

  const [error, setError] = useState<FormError>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { email } = useQueryParams()
  const passwordRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (email) {
      setForm(oldValues => ({ ...oldValues, email }))
      passwordRef.current?.focus()
    }
  }, [email])

  const connect = (fieldName: string) => ({
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm(oldValues => ({
        ...oldValues,
        [fieldName]: e.target.value,
      }))
    },
    value: form[fieldName],
  })

  const handleLoginFailure = (e: any) => {
    const status = e?.response.status

    if (status === RETRY_LATER_STATUS) {
      setError(RETRY_LATER)
    } else {
      setError(INVALID_ATTEMPT)
    }

    setIsSubmitting(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const { email, password } = form

    setIsSubmitting(true)
    setError(false)

    Session.loginWithPassword(email, password, handleLoginFailure)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <input placeholder='email' {...connect('email')} />
        </div>

        <div className='form-group'>
          <input
            placeholder='password'
            type='password'
            ref={passwordRef}
            {...connect('password')}
          />
        </div>

        <div className='form-group'>
          <Button className='w-100' disabled={isSubmitting} type='submit'>
            {I18N('login')}
          </Button>
        </div>
      </form>

      {error === INVALID_ATTEMPT && <div className='text-error'>{I18N('invalid_login_attempt')}</div>}
      {error === RETRY_LATER && <div className='text-error'>{I18N('retry_later')}</div>}
    </>
  )
}

export default PasswordLoginForm
