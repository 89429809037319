import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import BetaTag from 'components/common/tags/betaTag'
import useNavItems from 'components/common/hooks/useNavItems'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.navbar')

// Empty for now, but leaving code because if we ever need to add the beta tag to a nav item we just add here.
const BETA_FEATURES = []


const getActiveAdminNavKey = () => {
  // Gets the URL path after "/admin/"
  const routeMatch = location.pathname.match(/\/([\w-]*)\/([\w-]*)/)
  if (routeMatch) {
    return routeMatch[2]
  }

  return null
}

const AdminNavbarLink = ({ path, isActive = false }) => {
  const { pluralGroupDisplayName } = useCurrentCompany()
  const name = path === 'teams' ? pluralGroupDisplayName : I18N(path)

  return (
    <div className={classNames('AdminNavbarLink py-2 pl-4 d-flex align-items-center justify-content-between', { isActive })}>
      <Link className='text-primary-link' to={`/admin/${path}`}>{name}</Link>
      {BETA_FEATURES.includes(path) && <BetaTag className='mx-3' />}
    </div>
  )
}

const AdminNavbar = ({ isMobileNavOpen = false, isDesktopNavOpen = true }) => {
  const navItems = useNavItems()

  return (
    <nav
      className={
        classNames(
          'LeftNav position-relative flex-grow-0 flex-shrink-0 d-flex flex-column',
          { isMobileNavOpen, isDesktopNavOpen }
        )
      }
    >
      {Object.keys(navItems).map((topLevelNavItem, ind) => {
        const showTopLevelNavItem = Object.entries(navItems[topLevelNavItem]).some(([_, value]) => (value))

        if (!showTopLevelNavItem) return null

        return (
          <div key={ind} className={classNames('mb-2', ind === 0 ? 'mt-3' : 'mt-2')}>
            <p className='text-secondary text-small font-weight-500 pl-4 mb-1'>{topLevelNavItem}</p>
            {Object.entries(navItems[topLevelNavItem]).map(([pagePath, show]) => (
              show && <AdminNavbarLink key={pagePath} path={pagePath} isActive={pagePath === getActiveAdminNavKey()} />)
            )}
          </div>
        )
      })}
    </nav>
  )
}

export default AdminNavbar
