import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import settingSlice from 'redux/slices/settings'


const I18N = i18nPath('views.settings.people_section')

const UserCelebrationRow = ({ id, name }) => {
  const dispatch = useDispatch()

  const setting = useSelector(settingSlice.selectors.getSetting(name))

  const onChange = (updatedValue) => {
    dispatch(settingSlice.asyncActions.update(name, { ...setting?.value, ...updatedValue }))
  }

  return (
    <tr>
      <td className='font-weight-500'>{I18N(id)}</td>
      <td className='text-center'>
        <input
          id={`${name}-alerts`}
          type='checkbox'
          checked={setting?.value?.notifications && setting?.value?.externalAlerts}
          onChange={({ target: { checked } }) => onChange({ notifications: checked, externalAlerts: checked })}
        />
      </td>
      <td className='text-center'>
        <input
          id={`${name}-feed`}
          type='checkbox'
          checked={setting?.value?.feed}
          onChange={({ target: { checked } }) => onChange({ feed: checked })}
        />
      </td>
    </tr>
  )
}

export default UserCelebrationRow
