import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import badgeSlice, { defaultWorkingCopy } from 'redux/slices/badges'

import BackButton from 'components/common/backButton'
import ImageSelector from 'components/common/imageSelector'
import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.badges.create')
const badgeIconPath = '/images/badges/badgeIcon.svg'

const CreateBadgePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)
  const { isSaving } = useSelector(badgeSlice.selectors.getMetaData())

  const isMissingRequiredFields = !workingCopy?.displayName || !workingCopy.description || !workingCopy.image
  const areChangesPresent = !_.isEqual(defaultWorkingCopy, workingCopy)
  const canCreateBadge = !isMissingRequiredFields && areChangesPresent

  const updateWorkingCopy = (values = {}) => {
    setWorkingCopy({ ...workingCopy, ...values })
  }

  const generateChangeHandler = (fieldName: string) => (e) => {
    updateWorkingCopy({ [fieldName]: e.target.value })
  }

  const saveBadge = () => {
    dispatch(badgeSlice.asyncActions.create(workingCopy, onSuccess))
  }

  const onSuccess = (badgeId: string) => {
    history.push(`/people/badges/${badgeId}`)
  }

  return (
    <main className='CreateBadgePage'>
      <BackButton backToText={I18N('badges')} url='/people/badges' />
      <h2>{I18N('header')}</h2>
      <p className='text-secondary'>{I18N('description')}</p>
      <section className='CreateBadgeCard'>
        <ImageSelector
          onImageAdded={image => updateWorkingCopy({ image })}
          imageText={(
            <div>
              <CdnSvg src={badgeIconPath} />
              <p className='text-smallest text-color-primary mt-2 mb-0'>{I18N('add_image')}</p>
            </div>
          )}
        />
        <div className='Title'>
          <input
            className='Input'
            placeholder={I18N('title_placeholder')}
            value={workingCopy.displayName}
            onChange={generateChangeHandler('displayName')}
          />
        </div>
        <textarea
          className='Description'
          placeholder={I18N('description_placeholder')}
          value={workingCopy.description}
          onChange={generateChangeHandler('description')}
        />
        <div className='CreateBadgeButton'>
          <Button
            disabled={!canCreateBadge}
            onClick={saveBadge}
            showLoadingSpinner={isSaving}
          >
            {I18N('create_button')}
          </Button>
        </div>
      </section>
    </main>
  )
}

export default CreateBadgePage
