import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.preview_components')

const TagBackgroundColor = () => (
  <div className='Tag-body mb-1 mr-1'>
    <div className='Tag-text'>{I18N('exec_team')}</div>
  </div>
)

export default TagBackgroundColor
