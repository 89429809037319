import React from 'react'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import { ChatChannelTypeEnum } from 'types/chat/channel'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_answers_wizard_modal')

const Form = ({
  selectedChannels,
  setSelectedChannels,
  termsAndConditionsChecked,
  setTermsAndConditionsChecked,
  isLoadingSuitableChannels,
}) => (
  <>
    <ChatChannelsAsyncSelect
      value={selectedChannels}
      onChange={value => setSelectedChannels(value)}
      isLoadingExternal={isLoadingSuitableChannels}
      types={[ChatChannelTypeEnum.SlackChannel]}
      creatable={false}
    />

    <div className='d-flex align-items-center mt-1'>
      <input
        data-testid='terms-and-conditions-checkbox'
        id='termsAndConditions'
        type='checkbox'
        checked={termsAndConditionsChecked}
        onChange={e => setTermsAndConditionsChecked(e.target.checked)}
      />

      <label className='ml-2 mb-0 text-small' htmlFor='termsAndConditions'>
        {i18nFormat(
          I18N('step_3.terms_and_conditions'),
          <a href='https://gocleary.com/privacy-policy' target='_blank' rel='noopener noreferrer'>
            {I18N('step_3.privacy_policy')}
          </a>,
          <a href='https://gocleary.com/terms-of-service' target='_blank' rel='noopener noreferrer'>
            {I18N('step_3.terms_of_service')}
          </a>
        )}
      </label>
    </div>
  </>
)

export default Form
