import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import FormCheck from 'components/form_fields/formCheck'
import adminAlertSlice from 'redux/slices/adminAlerts'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const ALL_ALERTS = 'all_alerts'
const PREBOARDING_ALERTS = 'preboarding'
const USER_IMPORTS_ALERTS = 'user_imports'
const SOFT_LAUNCH_ACCESS_ALERTS = 'soft_launch_access'
const AI_ASSISTANT_ANSWERS_ALERTS = 'ai_assistant_answers'

const I18N = i18nPath('views.admin.admin_alerts')

const AdminAlertsTab = ({ user }) => {
  const dispatch = useDispatch()

  const { settings } = useCurrentCompany()

  const { isLoading } = useSelector(adminAlertSlice.selectors.getMetaData())
  const adminAlerts = useSelector(adminAlertSlice.selectors.getAdminAlerts())

  const isAllAlertsChecked = !!adminAlerts.find(aa => aa.name === ALL_ALERTS)

  const createAlert = (alertName) => {
    dispatch(adminAlertSlice.asyncActions.admin.createAdminAlert(user.id, alertName))
  }

  const destroyAlert = (adminAlertId) => {
    dispatch(adminAlertSlice.asyncActions.admin.destroyAdminAlert(user.id, adminAlertId))
  }

  const allAdminAlerts = [
    ALL_ALERTS,
    USER_IMPORTS_ALERTS,
    settings.softLaunchWhitelist?.enabled && SOFT_LAUNCH_ACCESS_ALERTS,
    settings.journeys?.preboarding?.enabled && PREBOARDING_ALERTS,
    settings.aiAssistantAnswers?.enabled && AI_ASSISTANT_ANSWERS_ALERTS,
  ].filter(Boolean)

  useEffect(() => {
    dispatch(adminAlertSlice.asyncActions.admin.fetchUserAdminAlerts(user.id))

    return () => {
      dispatch(adminAlertSlice.actions.clearAdminAlertIds())
    }
  }, [])

  return (
    <div className='AdminAlertsTab background-color-white p-4'>
      {allAdminAlerts.map((alertName) => {
        const adminAlert = adminAlerts.find(aa => aa.name === alertName)
        const isAdminAlertPresent = !!adminAlert
        const disabled = isLoading || (isAllAlertsChecked && alertName !== ALL_ALERTS)

        return (
          <div key={`alert-${alertName}`} className='p-3 d-flex justify-content-start align-items-center'>
            <FormCheck
              onChange={() => (isAdminAlertPresent ? destroyAlert(adminAlert.id) : createAlert(alertName))}
              id={`alert-${alertName}`}
              checked={isAdminAlertPresent || isAllAlertsChecked}
              disabled={disabled}
            />
            <div>
              <span>{I18N(alertName)}</span>
              <p className='text-secondary mb-0'>{I18N(`${alertName}_description`)}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AdminAlertsTab
