import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import FormErrorList from 'components/errors/formErrorList'
import UserSkillTypeForm from 'components/admin/userSkillTypeForm'
import userSkillTypeSlice from 'redux/slices/userSkillTypes'

const I18N = i18nPath('views.admin.user_skill_types')

const CreateUserSkillTypePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [userSkillType, setUserSkillType] = useState({
    id: null,
    name: null,
    slug: null,
  })

  const { isSaving, error } = useSelector(userSkillTypeSlice.selectors.getMetaData())
  const disableSaveButton = !userSkillType.name || !userSkillType.slug

  const saveUserSkillType = () => {
    dispatch(userSkillTypeSlice.asyncActions.admin.createUserSkillType(userSkillType, history))
  }

  return (
    <div className='CreateUserSkillTypePage'>
      <header className='AdminHeader'>
        <h3>{I18N('create_user_skill_type')}</h3>
      </header>

      <main className='AdminContent'>
        <UserSkillTypeForm userSkillType={userSkillType} setUserSkillType={setUserSkillType} />

        {error && <FormErrorList error={error} />}

        <Button disabled={disableSaveButton} onClick={saveUserSkillType} showLoadingSpinner={isSaving} className='mt-3'>
          {I18N('save')}
        </Button>
      </main>
    </div>
  )
}

export default CreateUserSkillTypePage
