import React, { useState } from 'react'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import EditableContentTrigger from 'components/common/editable_content/editableContentTrigger'

const EditableUserContent = ({
  canEdit,
  user,
  setUser,
  className = '',
  showEmployeeSearch = false,
}) => {
  const [isEditing, setIsEditing] = useState(!user || showEmployeeSearch)

  const onClickDisplayElement = (e) => {
    if (canEdit) {
      e.preventDefault()
      e.stopPropagation()
      setIsEditing(true)
    }
  }

  if (!isEditing) {
    return (
      <EditableContentTrigger
        className={className}
        displayElement={(
          <div className='bordered EditableUserContent-DisplayElement'>
            <EmployeeMiniCardLink employee={user} padding={2} targetBlank />
          </div>
        )}
        canEdit={canEdit}
        onClick={onClickDisplayElement}
      />
    )
  }

  return (
    <EmployeeSearch
      className='mb-3'
      onChange={setUser}
      selectedEmployee={user}
      isClearable={false}
    />
  )
}

export default EditableUserContent
