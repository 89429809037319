import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StatusLabel from 'components/common/status_label'
import acknowledgementSlice from 'redux/slices/acknowledgements'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.acknowledgements')

const AcknowledgementsStatusLabel = ({ acknowledgeableId, type }) => {
  const dispatch = useDispatch()
  const {
    acknowledgedCount, pendingCount, overdueCount,
  } = useSelector(acknowledgementSlice.selectors.getMetaData())
  const totalCount = acknowledgedCount + pendingCount + overdueCount

  useEffect(() => {
    dispatch(acknowledgementSlice.asyncActions.admin.fetchAll(acknowledgeableId, type, { perPage: 1 }))
  }, [])

  // returning a span here because some components expect a node to be returned for styling purposes
  if (totalCount === 0) return <span />

  return (
    <StatusLabel
      color={acknowledgedCount < totalCount ? 'yellow' : 'green'}
    >
      {I18N('fraction_acknowledged', { acknowledgedCount, totalCount })}
    </StatusLabel>
  )
}

export default AcknowledgementsStatusLabel
