import useNavItems from 'components/common/hooks/useNavItems'

const filteredPaths = navItem => Object.entries(navItem).map(([key, value]) => (value ? key : null)).filter(Boolean)

const useFirstAdminPath = () => {
  const navItems = useNavItems()

  const existingPaths = _.flatten(Object.values(navItems).map(filteredPaths))

  return existingPaths.length > 0 ? existingPaths[0] : null
}

export default useFirstAdminPath
