import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

const CreatedBy = ({ creator, text = I18NCommon('created_by') }) => (
  <div className='d-flex'>
    <span className='text-small text-secondary mr-2'>{text} </span>
    <EmployeeLinkWithPopover user={creator} id={`created-by-${creator.id}`} className='text-small' />
  </div>
)

export default CreatedBy
