import { AiMessageType, UserMessageType } from 'components/ai/chat/ask_ai_modal/types'
import useCursorPagination from 'components/common/hooks/useCursorPagination'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import API from 'services/api'

const useCurrentConversation = () => {
  const [currentConversationId, innerSetCurrentConversationId] = useState<string | undefined>(undefined)

  const {
    data: rawMessages,
    setData: setMessages,
    isLoading: isLoadingMessages,
    onLoadMore: onLoadMoreMessages,
    hasMore: hasMoreMessages,
    resetCursor,
  } = useCursorPagination<AiMessageType | UserMessageType>(
    API.ai.chats.messages.fetchAll,
    [currentConversationId],
    { load: !!currentConversationId }
  )

  const messages = useMemo(
    () => _.sortBy(rawMessages, message => moment(message.createdAt).unix()),
    [rawMessages]
  )

  useEffect(() => {
    // load from local storage
    const conversationId = localStorage.getItem('currentConversationId')
    const lastMessageSentAt = localStorage.getItem('lastMessageSentAt')

    const tenMinutesAgo = Date.now() - 1000 * 60 * 10

    if (conversationId && lastMessageSentAt && parseInt(lastMessageSentAt, 10) > tenMinutesAgo) {
      innerSetCurrentConversationId(conversationId)
    }
  }, [])

  const setCurrentConversationId = (conversationId: string) => {
    if (conversationId === currentConversationId) return

    setMessages([])
    resetCursor()
    localStorage.setItem('currentConversationId', conversationId)
    innerSetCurrentConversationId(conversationId)
  }

  const updateLastMessageSentAt = () => {
    localStorage.setItem('lastMessageSentAt', Date.now().toString())
  }

  const onNewConversation = () => {
    localStorage.removeItem('currentConversationId')
    localStorage.removeItem('lastMessageSentAt')
    setMessages([])
    resetCursor()
    innerSetCurrentConversationId(undefined)
  }

  return {
    currentConversationId,
    setCurrentConversationId,
    updateLastMessageSentAt,
    messages,
    setMessages,
    hasMoreMessages,
    onLoadMoreMessages,
    isLoadingMessages,
    onNewConversation,
  }
}

export default useCurrentConversation
