import React from 'react'

const CheckBox = (props) => {
  const {
    fieldName,
    attributeValue,
    onChangeHandler,
    label,
    helperText,
  } = props

  const htmlId = `${fieldName}-checkBox`

  return (
    <div className='row form-group'>
      <div className='col-md-4'>
        <div className='form-check'>
          <input
            id={htmlId}
            type='checkbox'
            name={fieldName}
            checked={attributeValue}
            onChange={onChangeHandler}
          />
          <label htmlFor={htmlId} className='checkbox-label form-inline-check'>
            {label}
          </label>
        </div>
      </div>
      {helperText && (
        <div className='col-md-12'>
          <div className='text-small text-secondary'>{helperText}</div>
        </div>
      )}
    </div>
  )
}

export default CheckBox
