import React from 'react'
import { useSelector } from 'react-redux'

import searchSlice from 'redux/slices/search'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

import useQueryParams from 'components/common/hooks/useQueryParams'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import NoQueryEntered from 'components/search/search_page_results/noQueryEntered'
import NoResultsFound from 'components/search/search_page_results/noResultsFound'
import SearchResultsSortBy from 'components/search/search_page_results/searchResultsSortBy'
import UserResults, { UserResult } from 'components/search/search_page_results/userResults'
import GolinkResults, { GoLinkResult } from 'components/search/search_page_results/golinkResults'
import PageResults, { PageResult } from 'components/search/search_page_results/pageResults'
import ArticleResults, { ArticleResult } from 'components/search/search_page_results/articleResults'
import UserSkillResults from 'components/search/search_page_results/userSkillResults'
import TeamResults, { TeamResult } from 'components/search/search_page_results/teamResults'
import EventResults, { EventResult } from 'components/search/search_page_results/eventResults'
import AppResults, { AppResult } from 'components/search/search_page_results/appResults'
import GoogleDriveResults from 'components/search/search_page_results/googleDriveResults'
import ConfluenceResults from 'components/search/search_page_results/confluenceResults'
import NotionResults from 'components/search/search_page_results/notionResults'
import BloomfireResults from 'components/search/search_page_results/bloomfireResults'
import OneDriveResults from 'components/search/search_page_results/oneDriveResults'
import ClearyInternalSearchResultsTemplate from 'components/search/search_page_results/clearyInternalSearchResultsTemplate'
import AttachedFileContentResults, { AttachedFileContentResult } from 'components/search/search_page_results/attachedFileContentResults'
import PageFAQResults, { PageFAQResult } from 'components/search/search_page_results/pageFAQResults'

const ComponentMap = {
  'user': UserResult,
  'go_link': GoLinkResult,
  'go_link/alias': GoLinkResult,
  'page': PageResult,
  'article': ArticleResult,
  'group': TeamResult,
  'qna/event': EventResult,
  'qna/question': EventResult,
  'qna/answer': EventResult,
  'app_launcher/app': AppResult,
  'attached_file_content': AttachedFileContentResult,
  'page/faq': PageFAQResult,
}

const types = [
  'user', 'goLink', 'goLinkAlias', 'page', 'article', 'group', 'qnaEvent', 'qnaQuestion', 'app', 'qnaAnswer', 'attachedFileContent', 'pageFaq',
]

const TopResults = () => (
  <ClearyInternalSearchResultsTemplate
    showLabel={false}
    extraTypes={types}
    searchResultToTemplateMap={(result) => {
      const Component = ComponentMap[result.type]

      return <Component result={result} />
    }}
  />
)

const SearchResults = () => {
  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const { query, filter } = useQueryParams()

  const searchResultsFilter = filter || 'all'

  const { isLoading, totalResults } = useSelector(searchSlice.selectors.getMetaData('pageAll'))

  const showAll = searchResultsFilter === 'all'
  const showUserResults = searchResultsFilter === 'people'
  const showGolinks = settings.golinks.enabled && (searchResultsFilter === 'links')
  const showPages = settings.pages.enabled && (searchResultsFilter === 'pages')
  const showArticles = settings.news.enabled && (searchResultsFilter === 'articles')
  const showPageFaq = settings.pages.enabled && settings.pages.faqs.enabled && (searchResultsFilter === 'page_faqs')
  const showUserSkills = settings.profile.fields.userSkills && (searchResultsFilter === 'user_skills')
  const showTeams = settings.groups.enabled && (searchResultsFilter === 'groups')
  const showEvents = settings.qna.enabled && (searchResultsFilter === 'qna')
  const showApps = settings.appLauncher?.enabled && (searchResultsFilter === 'apps')

  const showGoogleDriveResults = settings.googleDriveSearch.enabled && (searchResultsFilter === 'google_drive')
  const showConfluenceResults = settings.confluenceSearch.enabled && (searchResultsFilter === 'confluence')
  const showNotionResults = settings.notionSearch.enabled && (searchResultsFilter === 'notion')
  const showBloomfireResults = settings.bloomfireSearch.enabled && (searchResultsFilter === 'bloomfire')
  const showOneDriveResults = settings.oneDrive.searchEnabled && (searchResultsFilter === 'one_drive')

  const showAttachedFileContents = settings.globalSearch.attachedFileContents.enabled && (searchResultsFilter === 'attached_file_contents')

  if (showAll && isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (!query) {
    return <NoQueryEntered />
  }

  if (showAll && totalResults === 0) {
    return <NoResultsFound />
  }

  return (
    <div className='SearchResults'>
      <SearchResultsSortBy />

      {showAll ? (
        <TopResults />
      ) : (
        <>
          {showUserResults && <UserResults showLabel={showAll} />}
          {showPages && <PageResults showLabel={showAll} />}
          {showGolinks && <GolinkResults showLabel={showAll} />}
          {showArticles && <ArticleResults showLabel={showAll} />}
          {showUserSkills && <UserSkillResults showLabel={showAll} />}
          {showTeams && <TeamResults showLabel={showAll} />}
          {showEvents && <EventResults showLabel={showAll} />}
          {showApps && <AppResults showLabel={showAll} />}
          {showPageFaq && <PageFAQResults showLabel={showAll} />}
          {showGoogleDriveResults && <GoogleDriveResults showLabel={showAll} />}
          {showConfluenceResults && <ConfluenceResults showLabel={showAll} />}
          {showNotionResults && <NotionResults showLabel={showAll} />}
          {showBloomfireResults && <BloomfireResults showLabel={showAll} />}
          {showOneDriveResults && <OneDriveResults showLabel={showAll} />}
          {showAttachedFileContents && <AttachedFileContentResults showLabel={showAll} />}
        </>
      )}

    </div>
  )
}

export default SearchResults
