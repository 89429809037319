import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.article_list')
const womanWithLensPath = '/images/illustrations/womanWithLens.svg'

const EmptyStateTable = () => (
  <div className='empty-content d-flex flex-column w-100 my-5 justify-content-center align-items-center'>
    <CdnSvg src={womanWithLensPath} />
    <span className='description text-center mt-4 font-weight-400 text-secondary'>
      {I18N('empty_table_description')}
    </span>
  </div>
)

export default EmptyStateTable
