import React from 'react'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.journeys.new_hire_detail.steps.list')

const EmployeeStepsEmptyTableState = ({ onClickAssignJourney }) => (
  <div className='EmployeeStepsEmptyTableState mt-5'>
    <div className='empty-content d-flex flex-column w-100 justify-content-center align-items-center'>
      <CdnSvg src='/images/illustrations/backpacker.svg' />
      <span className='description text-center mt-4 font-weight-400 text-secondary'>
        {I18N('steps_empty_list_description')}
      </span>
      <Button className='mt-4' onClick={onClickAssignJourney}>{I18N('assign_a_journey')}</Button>
    </div>
  </div>
)

export default EmployeeStepsEmptyTableState
