import React, { useState } from 'react'
import { ChromePicker } from 'react-color'

import chroma from 'chroma-js'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'

import previewComponents from 'components/admin/customStyles/previewComponents/previewComponentsList'

const I18N = i18nPath('views.custom_styles.color_picker')

const ColorPicker = ({
  variable, color, onChange, required = true, className = 'mt-4',
}) => {
  const [isColorPickerVisible, setColorPickerVisibility] = useState(false)
  const toggle = () => setColorPickerVisibility(!isColorPickerVisible)
  const isAValidColor = new RegExp(/#.{3,7}|(rgba?\(.+\))|(hsla?\(.+\))/)
  const styles = getComputedStyle(document.documentElement)
  let isInvalid = false

  // If the property value is not a valid color (doesn't start with rbg or #)
  // Then we assume it's a variable name and we extract its value
  let colorValue = color
  if (!isAValidColor.test(color)) {
    colorValue = styles.getPropertyValue(`--${color}`).trim()
  }

  // If colorValue is an empty string it means that the color is invalid
  if (colorValue.length === 0) {
    colorValue = '#fff'
    isInvalid = true
  }

  // We need this to be able to recover from an invalid supported color format
  // rgba(255, 255, 255) missing the alpha value
  // rgb(255, 255, 255, 0.5) has alpha value but is just rgb
  try {
    chroma(colorValue)
  } catch (e) {
    colorValue = '#fff'
    isInvalid = true
  }

  // We add dynamic border colors to the color picker buttons to make them look better
  const mixWith = chroma(colorValue).luminance() < 1 ? 'lightgray' : 'black'
  const borderColor = chroma.mix(colorValue, mixWith, 0.2).hex()

  const handleOnChange = (color) => {
    const { hex } = color

    let colorValue = hex

    // If alpha present, we need to store hex with alpha
    if (color.rgb.a < 1) {
      colorValue = chroma(color.rgb).hex()
    }

    onChange('colors', variable, colorValue)
  }

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  return (
    <div className={classNames('pointer ColorPicker position-relative', variable, { isColorPickerVisible }, className)}>
      <span className='d-flex align-items-center justify-content-between gap-2'>
        {variable && (
          <label className='label m-0' style={{ height: '20px' }}>
            {_.startCase(variable)}
            {required && isInvalid && <span className='text-danger'> {I18N('is_invalid')}</span>}
          </label>
        )}
        <span
          className='d-inline-block border-radius'
          onClick={toggle}
          style={{
            background: colorValue,
            width: '36px',
            height: '20px',
            border: `solid 2px ${borderColor}`,
          }}
          data-testid={`cy_${variable}_picker_button`}
        />
      </span>
      {isColorPickerVisible ? (
        <div className='mt-2 ChromePicker zindex-dropdown position-absolute'>
          <div style={cover} onClick={toggle} />
          <ChromePicker color={colorValue} onChange={handleOnChange} />
        </div>
      ) : null}
      <div className='ColorPicker-preview position-relative mt-3 mb-2'>{previewComponents[variable]}</div>
    </div>
  )
}

export default ColorPicker
