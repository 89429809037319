import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import settingSlice from 'redux/slices/settings'

const I18N = i18nPath('views.settings.radio_buttons')

const SettingRadioButtons = ({ name, options }) => {
  const dispatch = useDispatch()
  const setting = useSelector(settingSlice.selectors.getSetting(name))

  const onChange = (value) => {
    dispatch(settingSlice.asyncActions.update(name, value))
  }

  return (
    <div className='SettingRadioButtons d-md-flex'>
      {options.map((option) => {
        const id = `${name}.${option.id}`

        return (
          <div key={`${id}-${option.id}`} className='d-flex align-items-center mr-md-5'>
            <input
              id={id}
              type='radio'
              checked={_.isEqual(setting?.value, option.value)}
              name={name}
              onChange={() => onChange(option.value)}
            />
            <label className='ml-2 mb-0' htmlFor={id}>{option.text || I18N(id)}</label>
          </div>
        )
      })}
    </div>
  )
}

export default SettingRadioButtons
