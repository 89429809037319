import React from 'react'
import { SurveyQuestionType } from 'types/surveys/question'
import { AnswerTypeEnum } from 'types/surveys/answer'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import classNames from 'classnames'

interface ScaleAnswerContentProps {
  question: SurveyQuestionType,
  answer?: any,
  updateAnswer?: (changes: any) => void,
  isDisabled?: boolean,
}

const getLabelValue = (labels, stepValue) => {
  const label = labels[stepValue.toString()] || ''
  if (!_.isEmpty(label.trim())) return label
  return stepValue
}

const ScaleStep = ({
  selected,
  onSelectAnswer,
  stepValue,
  label = '',
  isDisabled = false,
}) => (
  <div
    className='ScaleStep pointer position-relative d-flex flex-column justify-content-center align-items-center'
    onClick={onSelectAnswer}
  >
    <HoverAndClickPopover
      id={`step-${stepValue}`}
      placement='bottom'
      target={(
        <div className={classNames('step-bubble', { selected, isDisabled })} />
      )}
    >
      <div className='m-2'>{label}</div>
    </HoverAndClickPopover>
    <div className='text-align-center'>{stepValue}</div>
  </div>
)


const ScaleAnswerContent = ({
  answer,
  question,
  updateAnswer = (changes: any) => { },
  isDisabled = false,
}: ScaleAnswerContentProps) => {
  const config = question?.config || {}

  const minValue = _.get(config, 'minValue', 1)
  const maxValue = _.get(config, 'maxValue', 5)
  const step = config?.step || 1
  const labels = config?.labels || {}

  const selectedStep = answer?.number || null

  const minLabel = labels[minValue.toString()]
  const maxLabel = labels[maxValue.toString()]
  const range = _.range(minValue, maxValue + 1, step)
  const shouldShowLabelBelowScale = range.length > 8

  const handleStepSelect = (stepValue) => {
    updateAnswer({ number: stepValue, type: AnswerTypeEnum.number })
  }

  return (
    <div className='ScaleAnswerContent my-4'>
      <div className='d-flex justify-content-center'>
        {!shouldShowLabelBelowScale && (
          <div className='d-none d-md-flex mr-2'>
            {minLabel}
          </div>
        )}
        <div
          className={classNames('scale-steps d-flex justify-content-between', {
            'full-width': shouldShowLabelBelowScale,
          })}
        >
          {range.map(i => (
            <ScaleStep
              key={i}
              stepValue={i}
              selected={selectedStep === i}
              onSelectAnswer={() => handleStepSelect(i)}
              label={getLabelValue(labels, i)}
              isDisabled={isDisabled}
            />
          ))}
        </div>
        {!shouldShowLabelBelowScale && (
          <div className='d-none d-md-flex ml-2'>
            {maxLabel}
          </div>
        )}
      </div>
      <div
        className={classNames('d-flex justify-content-between mt-2', { 'd-md-none': !shouldShowLabelBelowScale })}
      >
        <div className=''>
          {minLabel}
        </div>
        <div className=''>
          {maxLabel}
        </div>
      </div>
    </div>
  )
}

export default ScaleAnswerContent
