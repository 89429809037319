import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import Tooltip from 'components/common/clearyTooltip'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.qna.events')

const AccessLevel = ({
  event,
  className = '',
  showLabel = false,
  fillColor = 'var(--text-color-secondary)',
}) => {
  const isPublic = event.accessLevel === 'public_access'

  if (isPublic || !event.accessLevel) return null

  return (
    <span className={classNames('AccessLevel position-relative', className)}>
      <Tooltip
        placement='bottom'
        tooltipClassName='mt-2'
        content={(
          <>
            <CdnSvg src='/images/lockIcon.svg' className='LockIcon mr-1' />
            {showLabel && <span className='ml-1 text-primary'>{I18N('private_event')}</span>}
          </>
        )}
      >
        <span className='text-small'>{I18N('invite_only_event')}</span>
      </Tooltip>
    </span>
  )
}

export default AccessLevel
