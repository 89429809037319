import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import classNames from 'classnames'
import { Tooltip } from 'reactstrap'

import { i18nPath } from 'utils/i18nHelpers'

import useQueryParams from 'components/common/hooks/useQueryParams'
import { LoadingContainer } from 'components/common/loadingContainer'
import GolinkMiniCard from 'components/common/golinkMiniCard'
import GolinkSearchSelect from 'components/common/golinkSearchSelect'
import LinksTabs from 'components/links/linksTabs'
import { showToastMessage } from 'redux/slices/toasts'
import linkSlice from 'redux/slices/links'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { trackEvent } from 'services/tracker'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import isMobile from 'utils/isMobile'

const I18N = i18nPath('views.golinks_list')
const MAX_FEATURED_GO_LINKS = 12

const SortableGoLink = SortableElement(({
  featuredGoLink, isSaving, hasEditAccess, onClick,
}) => {
  const popOverId = `golink-owner-${featuredGoLink.id}`

  return (
    <GolinkMiniCard
      className={classNames('col-md-3 col-sm-12 mb-4 Sortable', { isSaving, cursorGrab: hasEditAccess })}
      golink={featuredGoLink}
      popOverId={popOverId}
      showRemoveButton={hasEditAccess}
      onClick={onClick}
    />
  )
})

const SortableFeaturedGolinks = SortableContainer(({
  items,
  isSaving,
  handleGoLinkRemove,
  hasEditAccess,
}) => (
  <div className='row'>
    {items.map((featuredGoLink, index) => (
      <SortableGoLink
        key={`item-${featuredGoLink.name}`}
        index={index}
        featuredGoLink={featuredGoLink}
        isSaving={isSaving}
        onClick={() => handleGoLinkRemove(featuredGoLink)}
        hasEditAccess={hasEditAccess}
        disabled={!hasEditAccess}
      />
    ))}
  </div>
))

const AddFeaturedGoLink = ({
  shouldShowAddFeaturedGoLinkButton,
  setAddFeaturedGoLinkMode,
  addFeaturedGoLinkMode,
  handleGoLinkSelect,
  disableAddFeaturedGoLink,
}) => {
  const [showMaxFeaturedGolinkTooltip, setShowMaxFeaturedGolinkTooltip] = useState(false)
  const toggleTooltip = () => {
    setShowMaxFeaturedGolinkTooltip(!showMaxFeaturedGolinkTooltip)
  }

  return (
    <div className='AddFeaturedGoLink'>
      <button
        onClick={() => setAddFeaturedGoLinkMode(true)}
        id='addFeaturedLink'
        className={classNames('btn-link', {
          'd-inline-block': shouldShowAddFeaturedGoLinkButton,
          'd-none': !shouldShowAddFeaturedGoLinkButton,
          'cursor-not-allowed': disableAddFeaturedGoLink,
        })}
      >
        {I18N('add_featured_golink')}
      </button>

      {disableAddFeaturedGoLink && (
        <Tooltip target='addFeaturedLink' toggle={toggleTooltip} isOpen={showMaxFeaturedGolinkTooltip}>
          {I18N('max_featured_golink')}
        </Tooltip>
      )}

      {addFeaturedGoLinkMode && (
        <GolinkSearchSelect
          autoFocus
          placeholder={I18N('select_golink')}
          onChange={handleGoLinkSelect}
          filter={{ featured: false }}
          onBlur={() => setAddFeaturedGoLinkMode(false)}
        />
      )}
    </div>
  )
}

const GOLINKS_TABS = {
  ALL: 'all',
  MINE: 'mine',
}

const GoLinksListPage = () => {
  const dispatch = useDispatch()
  const { selectedTab } = useQueryParams()

  const [addFeaturedGoLinkMode, setAddFeaturedGoLinkMode] = useState(false)

  const currentUser = useCurrentUser()
  const {
    isLoadingFeaturedGoLinks,
    isSaving,
  } = useSelector(linkSlice.selectors.getMetaData())
  const featuredGoLinks = useSelector(linkSlice.selectors.getFeaturedGoLinks())

  useEffect(() => {
    dispatch(linkSlice.asyncActions.fetchFeaturedGoLinks())

    trackEvent('go_link:view_all')
  }, [])

  // receives react-select option
  const handleGoLinkSelect = ({ id, name }) => {
    const featuredGoLinksIds = featuredGoLinks.map(goLink => goLink.id)
    const newGoLinkIsDuplicated = _.includes(featuredGoLinksIds, id)

    if (newGoLinkIsDuplicated) {
      dispatch(showToastMessage({ message: I18N('duplicated_featured_golink'), type: 'warn' }))
    } else {
      dispatch(linkSlice.asyncActions.setFeaturedGoLink(name)).then(() => {
        setAddFeaturedGoLinkMode(false)
      })
    }
  }

  const handleGoLinkRemove = (goLink) => {
    dispatch(linkSlice.asyncActions.removeFeaturedGoLink(goLink))
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(linkSlice.asyncActions.setFeaturedGoLinkOrder(featuredGoLinks, oldIndex, newIndex))
  }

  const renderGoLinksList = () => {
    const shouldShowFeaturedGoLink = !!featuredGoLinks.length || currentUser.permissions.linkManager
    const shouldShowAddFeaturedGoLinkButton = currentUser.permissions.linkManager && !addFeaturedGoLinkMode
    const hasEditAccess = currentUser.permissions.linkManager
    const hasMaximumFeaturedGoLinks = featuredGoLinks.length >= MAX_FEATURED_GO_LINKS

    return (
      <div className='GoLinksList container mt-4'>
        <div className='row'>
          <div className='col-12'>
            <h1>{I18N('golinks')}</h1>
          </div>
        </div>

        {shouldShowFeaturedGoLink && (
          <LoadingContainer isLoading={isLoadingFeaturedGoLinks} useCirclesLoadingIndicator>
            <div className='FeaturedGoLinks'>
              <div className='d-flex justify-content-between mb-3'>
                <label className='label'>{I18N('featured')}</label>
                <AddFeaturedGoLink
                  shouldShowAddFeaturedGoLinkButton={shouldShowAddFeaturedGoLinkButton}
                  setAddFeaturedGoLinkMode={setAddFeaturedGoLinkMode}
                  addFeaturedGoLinkMode={addFeaturedGoLinkMode}
                  handleGoLinkSelect={handleGoLinkSelect}
                  disableAddFeaturedGoLink={hasMaximumFeaturedGoLinks}
                />
              </div>

              <SortableFeaturedGolinks
                items={featuredGoLinks}
                onSortEnd={onSortEnd}
                axis='xy'
                helperClass='Sortable-isDragging'
                isSaving={isSaving}
                handleGoLinkRemove={handleGoLinkRemove}
                hasEditAccess={hasEditAccess}
                distance={5}
              />
            </div>
          </LoadingContainer>
        )}

        <HistoryChangingPillTabSelector
          pillClasses='text-normal'
          className='mb-3'
          tabs={[
            {
              id: GOLINKS_TABS.ALL,
              text: I18N('filter_all'),
            },
            {
              id: GOLINKS_TABS.MINE,
              text: I18N('filter_mine'),
            },
          ]}
        />

        <LinksTabs filter={selectedTab} />
      </div>
    )
  }

  return renderGoLinksList()
}

export default GoLinksListPage
