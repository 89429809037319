import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import EmployeeSearch from 'components/form_fields/employeeSearch'

const I18N = i18nPath('views.qna.events.settings_modal.qna')

const Moderators = ({ event, setEvent }) => (
  <div className='Moderators'>
    <label>{I18N('moderators')}</label>
    <EmployeeSearch
      className='w-100 w-lg-50'
      onChange={selectedValues => setEvent({ ...event, moderators: selectedValues })}
      selectedEmployees={event.moderators}
      isMulti
      placeholder={I18N('select_moderators_placeholder')}
    />
    <div className='text-small text-secondary mt-1'>{I18N('moderators_helper')}</div>
  </div>
)

export default Moderators
