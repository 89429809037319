import React, { useState } from 'react'

import {
  I18NCommon,
  i18nFormat,
  i18nLabelWithTimeZone, i18nPath
} from 'utils/i18nHelpers'
import classNames from 'classnames'
import currentTimezone from 'utils/currentTimezone'

import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import Modal from 'components/common/modal'
import moment from 'moment'
import DatePicker from 'components/form_fields/datePicker'
import pageSlice from 'redux/slices/pages'
import { useDispatch, useSelector } from 'react-redux'
import PageType from 'types/page'
import { FormCheck } from 'react-bootstrap'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.pages.page_expiration_warning_modal')

const infoIconPath = '/images/infoIcon.svg'

interface Props {
  className?: string
  visible: boolean
  page: PageType
  toggle: () => void
}

const PageExpirationWarningModal = ({
  className,
  toggle,
  visible,
  page,
}: Props) => {
  const dispatch = useDispatch()
  const { isSaving } = useSelector(pageSlice.selectors.getPagesMetadata())

  const pageArchivedAt = page?.archivedAt

  const dismissedWarningPageIds = JSON.parse(localStorage.getItem('pageExpirationWarningModalDissmissedIds') || '[]') || []
  const [archivedAt, setArchivedAt] = useState(moment(pageArchivedAt))
  const [dismissWarning, setDismissWarning] = useState(false)
  const hasChanges = !_.isEqual(pageArchivedAt, archivedAt?.toISOString()) || dismissWarning

  const onSaveClick = () => {
    if (dismissWarning) {
      const newDismissedWarningPageIds = _.uniq([...dismissedWarningPageIds, page.id])

      localStorage.setItem('pageExpirationWarningModalDissmissedIds', JSON.stringify(newDismissedWarningPageIds))
    }

    if (!_.isEqual(pageArchivedAt, archivedAt?.toISOString())) {
      const params = {
        id: page.id,
        archivedAt: archivedAt?.toISOString() || null,
      }

      const onSuccess = () => {
        toggle()
      }

      dispatch(pageSlice.asyncActions.setExpiration(params, onSuccess))
    } else {
      toggle()
    }
  }

  return (
    <Modal size='md' className={classNames('PageExpirationWarningModal rounded-lg', className)} visible={visible} toggle={toggle}>
      <div className='d-flex flex-column'>
        <header className='d-flex align-items-center flex-column pb-4 pt-1 info-header'>
          <CdnSvg src={infoIconPath} className='InfoIcon' />
          <h2 className='mb-2 text-white'>{I18N('this_page_has_expiration')}</h2>
          <span className='text-white'>
            {i18nFormat(I18N('page_is_set_to_expire_on'), <strong>{moment(pageArchivedAt).format('LLL')}</strong>)}
          </span>
        </header>
        <div className='px-4 mt-4'>
          <label className='required-form-label'>{i18nLabelWithTimeZone(I18N('change_expiration_date'), currentTimezone())}</label>
          <DatePicker
            selected={archivedAt}
            onChange={e => setArchivedAt(e || undefined)}
            showTimeSelect
            isClearable
            dateFormat='LLL'
            isDisabled={isSaving}
            className='mb-3'
            inputReadOnly={false}
          />
          <FormCheck
            className='ml-4'
            name='dismissWarning'
            label={I18N('dismiss_warning')}
            checked={dismissWarning}
            onChange={e => setDismissWarning(e.target.checked)}
            id='dismissWarning'
            disabled={isSaving}
          />
          <div className='d-flex justify-content-end align-items-center mt-3'>
            <CancelButton onClick={toggle} className='mr-3 text-secondary font-weight-600'>
              {I18NCommon('cancel')}
            </CancelButton>
            <ButtonNarrow
              className='set-expiration-button'
              onClick={onSaveClick}
              showLoadingSpinner={isSaving}
              disabled={!hasChanges || isSaving}
            >
              {I18NCommon('save_changes')}
            </ButtonNarrow>
          </div>
        </div>
      </div>
    </Modal>
  )
}


export default PageExpirationWarningModal
