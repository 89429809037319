import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import GmailIntegrationCards from 'components/admin/integrations/gmail_api/gmailIntegrationCards'
import { useDispatch, useSelector } from 'react-redux'
import externalEmailAuthenticationSlice from 'redux/slices/externalEmailAuthentications'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import IntegratedGmailAccountsTable from 'components/admin/integrations/gmail_api/integratedGmailAccountsTable'
import { showToastMessage } from 'redux/slices/toasts'
import useQueryParams from 'components/common/hooks/useQueryParams'


const I18N = i18nPath('views.admin.external_email_authentications')

const ExternalEmailAuthenticationsPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { message, messageType } = useQueryParams()

  const { isLoading } = useSelector(externalEmailAuthenticationSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(externalEmailAuthenticationSlice.asyncActions.admin.fetchData())
  }, [])

  useEffect(() => {
    if (message) {
      dispatch(showToastMessage({ message, type: messageType || 'success' }))
      history.replace({ search: null })
    }
  }, [])

  return (
    <div className='ExternalEmailAuthenticationsPage'>
      <header className='AdminHeader'>
        <h3 className='mb-1'>{I18N('title')}</h3>
      </header>
      <main className='AdminContent'>
        {isLoading ? (
          <CirclesLoadingIndicator />
        ) : (
          <>
            <GmailIntegrationCards />
            <IntegratedGmailAccountsTable />
          </>
        )}
      </main>
    </div>
  )
}

export default ExternalEmailAuthenticationsPage
