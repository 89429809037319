import { isGeneratingAiMessage } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import useSSE from 'components/common/hooks/useSSE'
import { useEffect } from 'react'
import API from 'services/api'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

const useGenerateBotResponse = ({
  isResponding, setIsResponding, setMessages, currentConversationId,
}) => {
  useEffect(() => {
    if (isResponding) {
      setMessages(prev => _.uniqBy([...prev, { id: 'generating', message: '', role: 'assistant' }], 'id'))
    }
  }, [isResponding])

  useSSE(`/api/ai/chat/conversations/${currentConversationId}/messages/generate`, {
    options: {
      headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': API.csrfToken() },
      method: 'POST',
    },
    dependencies: [isResponding],
    listeners: {
      'delta-received': (event) => {
        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1]
          if (!isGeneratingAiMessage(lastMessage)) {
            return [...prev, { id: 'generating', message: event.data, role: 'assistant' }]
          }

          return [
            ...prev.slice(0, -1),
            { ...lastMessage, message: lastMessage.message + event.data },
          ]
        })
      },
      'workflow-finished': (event) => {
        setIsResponding(false)
        const data = JSON.parse(event.data)
        const message = denormalizedJsonApiResponse({ data }, 'aiChatMessage')
        setMessages(prev => _.uniqBy([...prev.filter(msg => msg.id !== 'generating'), message], 'id'))
      },
    },
    listen: isResponding,
  })
}

export default useGenerateBotResponse
