import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import journeySlice from 'redux/slices/journeys'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.remove_from_journey_modal')

const RemoveFromJourneyConfirmationModal = ({
  journeyToRemove,
  visible,
  toggle,
}) => {
  const dispatch = useDispatch()
  const { isSaving } = useSelector(journeySlice.selectors.getMetaData())

  const journeyToRemoveId = journeyToRemove?.id
  const { preferredFullName } = journeyToRemove?.user || {}

  const onRemoveSuccess = () => {
    toggle()
  }

  const onRemoveClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (journeyToRemoveId) {
      dispatch(journeySlice.asyncActions.admin.remove(journeyToRemoveId, onRemoveSuccess))
    }
  }

  return (
    <Modal size='md' className='RemoveFromJourneyConfirmationModal' visible={visible} toggle={toggle}>
      <div className='p-2 text-center'>
        <h3>{I18N('title')}</h3>
        <p className='text-secondary'>{I18N('remove_from_journey_help_text')}</p>
        <p className='text-secondary'>{I18N('remove_from_journey_question', { name: preferredFullName })}</p>
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <div role='button' tabIndex={0} onClick={toggle} className='mr-3 text-muted-dark'>
            {I18NCommon('cancel')}
          </div>
          <Button disabled={isSaving} onClick={onRemoveClick} variant='danger'>{I18NCommon('remove')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default RemoveFromJourneyConfirmationModal
