import useIsCelebrationForCurrentUser from 'components/celebrations/hooks/useIsCelebrationForCurrentUser'
import { SlideControlType } from 'pages/celebrations/slide_show_page/useSlideShow'
import UncontrolledSlideShowPage from 'pages/celebrations/uncontrolledSlideShowPage'
import { useLoadCelebration } from 'pages/people/celebration_editor_page/celebrationEditorHooks'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import commentSlice from 'redux/slices/comments'

const SlideShowPage = () => {
  const dispatch = useDispatch()
  const { celebration } = useLoadCelebration()
  const history = useHistory()
  const comments = useSelector(commentSlice.selectors.getComments('celebration', celebration?.id))
  const isCurrentUser = useIsCelebrationForCurrentUser(celebration)

  useEffect(() => {
    if (!celebration) return
    if (!isCurrentUser) return

    if (!celebration.openedAt || celebration.upcoming) {
      history.replace(`/people/celebrations/${celebration.id}`)
    }
  }, [celebration])

  useEffect(() => {
    if (celebration) {
      dispatch(commentSlice.asyncActions.fetchAll(celebration, 'celebration', {}))
    }

    return () => {
      dispatch(commentSlice.actions.reset('celebration'))
    }
  }, [celebration?.id])

  if (!celebration || !comments) return null

  return (
    <UncontrolledSlideShowPage
      celebration={celebration}
      comments={comments}
      slideControlType={SlideControlType.History}
    />
  )
}

export default SlideShowPage
