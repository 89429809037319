import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import Navbar from 'components/navbar/navbar'
import ToastMessage from 'components/common/toastMessage'
import { PathnameContextProvider } from 'components/contexts/pathnameContext'

import TaskDetailPage from 'pages/journeys/my_tasks/taskDetailPage'
import MyTasksPage from 'pages/journeys/my_tasks/myTasksPage'
import PreboardingProfilePage from 'pages/preboarding/preboardingProfilePage'
import PreboardingPagePage from 'pages/preboarding/preboardingPagePage'
import PreboardingUnauthorized from 'components/errors/preboardingUnauthorized'
import ArticlePage from 'components/articles/articlePage'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { trackAmplitudeEvent } from 'services/tracker'
import RespondSurveyPage from 'pages/surveys/respondSurveyPage'
import CommunicationPage from 'pages/journeys/communications/communicationPage'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CustomHomePage from 'pages/home/customHomePage'
import PreboardingPreviewBanner from 'components/preboarding/preboardingPreviewBanner'

const PreboardingRouter = () => {
  const currentUser = useCurrentUser()

  const { settings } = useCurrentCompany()

  useEffect(() => {
    if (currentUser.preboarding) {
      trackAmplitudeEvent('preboarding_dashboard_viewed')
    }
  }, [])

  return (
    <div className='PreboardingRouter'>
      <ToastMessage />
      <PreboardingPreviewBanner />
      <Navbar hasBoxShadow />

      <PathnameContextProvider>
        <Switch>
          <Route exact path='/' component={CustomHomePage} />
          <Route exact path='/my_tasks' component={MyTasksPage} />
          <Route exact path='/tasks/:taskId' component={TaskDetailPage} />
          <Route exact path='/pages/:pageSlug' component={PreboardingPagePage} />
          <Route exact path='/workspaces/:workspaceSlug/:pageSlug' component={PreboardingPagePage} />
          <Route exact path='/news/:articleType/:articleId' component={ArticlePage} />
          <Route exact path='/profile/:username' component={PreboardingProfilePage} />
          <Route exact path='/surveys/:formId' component={RespondSurveyPage} />
          <Route exact path='/communications/:communicationId' component={CommunicationPage} />

          <Route component={PreboardingUnauthorized} />
        </Switch>
      </PathnameContextProvider>
    </div>
  )
}

export default PreboardingRouter
