import React, { useEffect, useState } from 'react'

import { I18NCommon } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const allOption = { value: null, label: I18NCommon('all') }

const CriteriaFilters = ({ matchingCriteria, setFilters }) => {
  const targetingOptions = useTargetingOptions()
  const [selectedValues, setSelectedValues] = useState(new Array(matchingCriteria.length).fill({ ...allOption }))

  const getOptions = (criteria) => {
    if (criteria.type === 'group_type') {
      return targetingOptions?.find(fs => fs.type === 'group_type' && fs.value === criteria.key)?.options || []
    }

    if (criteria.type === 'extra_user_field') {
      return targetingOptions?.find(fs => fs.type === 'extra_user_field' && fs.value === criteria.key)?.options || []
    }

    return targetingOptions?.find(fs => fs.key === criteria.attribute)?.options || []
  }

  useEffect(() => {
    setFilters(matchingCriteria.reduce((acc, criteria, index) => {
      const value = selectedValues[index]?.value
      if (!value) {
        return acc
      }

      if (criteria.type === 'group_type') {
        return { ...acc, groupIds: [...(acc.groupIds || []), value] }
      }

      if (criteria.type === 'extra_user_field') {
        return { ...acc, extraUserFields: [...(acc.extraUserFields || []), { name: criteria.key, value }] }
      }

      return { ...acc, [criteria.attribute]: value }
    }, {}))
  }, [selectedValues])

  return (
    <div className='d-flex flex-row flex-wrap justify-content-end'>
      {matchingCriteria.map((criteria, index) => (
        <div key={`${criteria.type}-${criteria.key}`} className='d-flex align-items-center ml-2 mb-2'>
          <span className='text-secondary mr-2'>{criteria.label}</span>
          <ReactSelect
            className='CriteriaFilterSelect'
            options={[allOption, ...getOptions(criteria)]}
            value={selectedValues[index]}
            onChange={(option) => {
              setSelectedValues([...selectedValues.slice(0, index), option, ...selectedValues.slice(index + 1)])
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default CriteriaFilters
