import { AiMessageType } from 'components/ai/chat/ask_ai_modal/types'
import React from 'react'

import classNames from 'classnames'
import Card from 'components/common/card'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import Feedback from 'components/search/ai/feedback'
import Sources from 'components/search/ai/sources'
import { AssistantAnswerStatuses } from 'types/ai/assistantAnswer'
import CdnSvg from 'components/common/cdnSvg'


type AiMessageProps = {
  message: AiMessageType
}

export const AiMessageWrapper = ({
  children,
  footer,
}: {
  children: React.ReactNode
  footer?: React.ReactNode
}) => (
  <div className='AiMessage d-flex mb-4'>
    <div className='flex-shrink-0 mr-3'>
      <CdnSvg className='svg-highlight' src='/images/aiIcon.svg' />
    </div>

    <div className='flex-grow-1 AiOverview__container'>
      <Card className={classNames('AiOverview d-inline-block p-3-5 bg-highlight-color-tint-90')}>
        {children}
      </Card>

      {footer}
    </div>
  </div>
)

const AiMessage = ({ message }: AiMessageProps) => (
  <AiMessageWrapper>
    <div className='px-4'>
      <AiAnswerContent content={message.content} />
      {message.aiAssistantAnswer && message.aiAssistantAnswer.status !== AssistantAnswerStatuses.NotAnswered && (
        <>
          <Sources sources={message.aiAssistantAnswer.sources} />

          <div className='mt-4'>
            <Feedback assistantAnswer={message.aiAssistantAnswer} setAssistantAnswer={() => {}} />
          </div>
        </>
      )}
    </div>
  </AiMessageWrapper>
)

export default AiMessage
