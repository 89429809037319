import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import celebrationSlice from 'redux/slices/celebrations'

import CelebrationCard from 'components/celebrations/celebrationCard'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Error404 from 'components/errors/404'
import CelebrationGreetingCardPage from 'pages/people/celebrationGreetingCardPage'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { systemTypes } from 'pages/people/celebration_editor_page/generateWorkingCopyFromTemplate'


const CelebrationCardPage = ({ celebration, currentUser, companyName }) => (
  <div className='CelebrationPage container'>
    <div className='col-12 offset-md-2 col-md-8 mt-5'>
      <CelebrationCard
        celebration={celebration}
        currentUser={currentUser}
        companyName={companyName}
      />
    </div>
  </div>
)

const CelebrationPage = () => {
  const dispatch = useDispatch()
  const { celebrationId } = useParams()

  const currentUser = useCurrentUser()
  const { name: companyName } = useCurrentCompany()
  const celebration = useSelector(celebrationSlice.selectors.getCelebration(celebrationId))
  const { isLoading, isNotFound } = useSelector(celebrationSlice.selectors.getMetaData())
  const isCurrentUser = celebration?.user?.id === currentUser.id

  useEffect(() => {
    dispatch(celebrationSlice.asyncActions.fetchCelebration(celebrationId))
  }, [celebrationId])

  if (isNotFound) {
    return <Error404 />
  }

  if (isLoading || !celebration) {
    return <CirclesLoadingIndicator />
  }

  // a user should also not see the greeting card if there are no greetings :(
  // we force the feed event card in those cases or 404 for custom celebrations
  if (isCurrentUser && celebration.commentsCount === 0) {
    if (!systemTypes.includes(celebration.type)) {
      return <Error404 />
    }

    return (
      <CelebrationCardPage
        celebration={celebration}
        currentUser={currentUser}
        companyName={companyName}
      />
    )
  }

  return (
    <CelebrationGreetingCardPage
      celebration={celebration}
      companyName={companyName}
      isCurrentUser={isCurrentUser}
    />
  )
}

export default CelebrationPage
