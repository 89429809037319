import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import InfoTooltip from 'components/common/infoTooltip'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.admin.users')

const GENERAL_INFORMATION_TAB = 'general_information'
const USER_PERMISSIONS_TAB = 'user_permissions'
const ADMIN_ALERTS_TAB = 'admin_alerts'

const TabHeaderWithTooltip = ({ tabName }) => (
  <div className='d-flex'>
    <span className='text-secondary'>
      {I18N(`tabs.${tabName}`)}
    </span>
    <InfoTooltip
      text={I18N(`tabs.${tabName}_tooltip`)}
      tooltipClassName='mt-2'
    />
  </div>
)

const UserEditorTabs = ({ isNewUser }) => {
  const currentUser = useCurrentUser()
  const isSuperAdmin = currentUser.permissions.superAdmin

  return (
    <HistoryChangingPillTabSelector
      pillClasses='text-normal'
      tabs={[
        {
          text: I18N(`tabs.${GENERAL_INFORMATION_TAB}`),
          id: GENERAL_INFORMATION_TAB,
        },
        {
          text: isNewUser ? <TabHeaderWithTooltip tabName={USER_PERMISSIONS_TAB} /> : I18N(`tabs.${USER_PERMISSIONS_TAB}`),
          id: USER_PERMISSIONS_TAB,
          hidden: !isSuperAdmin,
          disabled: isNewUser,
        },
        {
          text: isNewUser ? <TabHeaderWithTooltip tabName={ADMIN_ALERTS_TAB} /> : I18N(`tabs.${ADMIN_ALERTS_TAB}`),
          id: ADMIN_ALERTS_TAB,
          hidden: !isSuperAdmin,
          disabled: isNewUser,
        },
      ]}
    />
  )
}

export default UserEditorTabs
