import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Card from 'components/common/card'
import AnswersCount from 'components/qna/answersCount'
import commentsCountText from 'components/comments/commentsCount'
import DangerousHTML from 'components/common/dangerousHTML'
import CdnSvg from 'components/common/cdnSvg'

const RecentQuestionCard = ({ question }) => {
  const [answerCountHover, setAnswerCountHover] = useState(false)

  const showAnswerCountHover = () => setAnswerCountHover(true)
  const hideAnswerCountHover = () => setAnswerCountHover(false)

  const [commentCountHover, setCommentCountHover] = useState(false)

  const showCommentCountHover = () => setCommentCountHover(true)
  const hideCommentCountHover = () => setCommentCountHover(false)

  return (
    <Card>
      <span className='text-secondary text-small truncate-text-at-1-lines'>From&nbsp;
        <Link to={question.event.displayPath} className='text-secondary-link font-weight-normal'>
          {question.event.title}
        </Link>
      </span>
      <div className='pt-2'>
        <Link
          to={question.displayPath}
          className='text-primary-link font-weight-normal truncate-text-at-3-lines'
        >
          <DangerousHTML>{question.plainTextContent}</DangerousHTML>
        </Link>
      </div>
      <div className='pt-2 align-items-center d-flex flex-wrap'>
        <Link
          className={classNames('text-small pill-button pl-0', answerCountHover && 'pill-button-active')}
          onMouseEnter={showAnswerCountHover}
          onMouseLeave={hideAnswerCountHover}
          to={question.displayPath}
        >
          <AnswersCount answersCount={question.answersCount} showAnswers={false} />
        </Link>

        <Link
          className={classNames(
            'CommentsWidget--Toggle pill-button',
            commentCountHover && 'pill-button-active'
          )}
          onMouseEnter={showCommentCountHover}
          onMouseLeave={hideCommentCountHover}
          to={question.displayPath}
        >
          <span className={classNames('text-small d-flex align-items-center', question.commentsCount === 0 && 'text-secondary')}>
            <CdnSvg src='/images/commentIcon.svg' className={classNames('mr-1 RecentQuestionCard--CommentIcon', question.commentsCount === 0 && 'svg-secondary')} />
            {commentsCountText(question.commentsCount, true)}
          </span>
        </Link>
      </div>
    </Card>
  )
}

export default RecentQuestionCard
