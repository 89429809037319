import { ButtonSmallNarrow } from 'components/common/buttons'
import EditableRichTextContent from 'components/common/editable_content/editableRichTextContent'
import ImageSelector from 'components/common/imageSelector'
import InlineImageCrop from 'components/common/image_crop/inlineImageCrop'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { CELEBRATIONS_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import GreetingCardBannerImage from 'components/celebrations/greetingCardBannerImage'
import GreetingCardHeadlineDisplay from 'components/celebrations/greetingCardHeadlineDisplay'
import { defaultWorkingCopy } from 'pages/people/celebration_editor_page/celebrationEditorHooks'
import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'

const IMAGE_CROP_ASPECT = 1440 / 370

const I18N = i18nPath('views.celebrations.greeting_card')

interface Props {
  workingCopy: any
  setWorkingCopy: (workingCopy: any) => void
}

const GreetingCardBannerEditor = ({
  workingCopy,
  setWorkingCopy,
}: Props) => {
  const withCustomImage = !!workingCopy.coverImageUrl
  const [isRepositioning, setIsRepositioning] = useState(false)
  const [isCustomTitleEditing, setIsCustomTitleEditing] = useState(false)

  const onImageAdded = (
    coverImage, {
      croppedAreaPixels: coverImageCroppedArea,
      croppedImageUrl: coverImageUrl,
      imageData: originalCoverImageUrl,
    }) => {
    setWorkingCopy({
      ...workingCopy,
      coverImageCroppedArea,
      coverImageUrl,
      feedImage: null,
      feedImageCroppedArea: {},
      originalCoverImageUrl,
      coverImage,
      useDefaultImages: false,
      copyImageFromTemplateId: null,
    })
  }

  const onClickDeleteImage = async () => {
    setWorkingCopy({
      ...workingCopy,
      coverImage: null,
      coverImageCroppedArea: {},
      feedImage: null,
      feedImageCroppedArea: {},
      coverImageUrl: workingCopy.customCelebration ? defaultWorkingCopy.coverImageUrl : null,
      originalCoverImageUrl: workingCopy.customCelebration ? defaultWorkingCopy.originalCoverImageUrl : null,
      useDefaultImages: workingCopy.customCelebration,
      copyImageFromTemplateId: null,
    })
  }


  const onRepositionSave = async ({ croppedAreaPixels, croppedImageUrl }) => {
    setIsRepositioning(false)
    setWorkingCopy({
      ...workingCopy,
      coverImageCroppedArea: croppedAreaPixels,
      coverImageUrl: croppedImageUrl,
    })
  }

  const hideRemoveCardImageButton = workingCopy.customCelebration && workingCopy.useDefaultImages

  if (isRepositioning) {
    return (
      <div className='GreetingCardBannerImage z-index-0 repositioning withCustomImage'>
        <InlineImageCrop
          aspect={IMAGE_CROP_ASPECT}
          imageSrc={workingCopy.originalCoverImageUrl}
          initialCroppedArea={workingCopy.coverImageCroppedArea}
          onCancel={() => setIsRepositioning(false)}
          onSave={onRepositionSave}
        />
      </div>
    )
  }

  const titleEditor = (
    <EditableRichTextContent
      canEdit
      displayElement={<GreetingCardHeadlineDisplay celebration={workingCopy} />}
      toolbarItems={CELEBRATIONS_TOOLBAR}
      inputElement={
        <TiptapEditor className='GreetingCardHeadlineEditor' />
      }
      actionsInToolbar
      onlyCloseAction
      setCurrentValue={customTitle => setWorkingCopy({ ...workingCopy, customTitle })}
      valueToEdit={isHtmlStringEmpty(workingCopy.customTitle) ? workingCopy.defaultTitle : workingCopy.customTitle}
      setIsEditing={setIsCustomTitleEditing}
      enableClickOutside
    />
  )

  return (
    <GreetingCardBannerImage
      celebration={workingCopy}
      headlineDisplay={titleEditor}
    >
      {!isCustomTitleEditing && (
        <div className='actions z-index-1'>
          <div className='buttons mt-3 mr-3'>
            <ImageSelector
              accept={['image/*']}
              dropzoneClassName='dropzone-wrapper'
              style={{ position: 'relative' }}
              cropImage
              aspect={IMAGE_CROP_ASPECT}
              cropShape='rect'
              onImageAdded={onImageAdded}
              clearAfterCropSave
              imageText={(
                <ButtonSmallNarrow className='editImageButton ml-2'>
                  {I18N('change_card_image')}
                </ButtonSmallNarrow>
              )}
            />

            {withCustomImage && (
              <>
                {!workingCopy.useDefaultImages && (
                  <ButtonSmallNarrow
                    onClick={() => setIsRepositioning(true)}
                    className='repositionImageButton ml-2'
                  >
                    {I18N('reposition_image')}
                  </ButtonSmallNarrow>
                )}

                {!hideRemoveCardImageButton && (
                  <ButtonSmallNarrow
                    onClick={onClickDeleteImage}
                    className='deleteImageButton ml-2'
                    variant='danger'
                  >
                    {I18N('remove_card_image')}
                  </ButtonSmallNarrow>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </GreetingCardBannerImage>
  )
}

export default GreetingCardBannerEditor
