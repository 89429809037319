import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import userSkillTypeSlice from 'redux/slices/userSkillTypes'
import userSkillSlice from 'redux/slices/userSkills'
import UserSkill from 'components/profiles/userSkill'

const UserSkills = ({
  canEdit,
  currentCompany,
  user,
}) => {
  const { settings } = currentCompany
  const dispatch = useDispatch()

  useEffect(() => {
    if (settings.profile.fields.userSkills) {
      dispatch(userSkillTypeSlice.asyncActions.fetchUserSkillTypes())

      if (canEdit) {
        dispatch(userSkillSlice.asyncActions.listAll())
      }
    }
  }, [])

  const userSkillTypes = useSelector(userSkillTypeSlice.selectors.getUserSkillTypes())

  // returning earlier in the component is prevented
  // because useEffect/selector ESLint errors (rule of hooks)
  if (!settings.profile.fields.userSkills) {
    return null
  }

  return (
    userSkillTypes.map((skillField, index) => (
      <UserSkill
        key={index}
        canEdit={canEdit}
        user={user}
        type={skillField.slug}
        title={skillField.name}
      />
    ))
  )
}

export default UserSkills
