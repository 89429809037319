import React, { useState } from 'react'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { useDispatch } from 'react-redux'
import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import DatePicker from 'components/form_fields/datePicker'
import API from 'services/api'
import { showToastMessage } from 'redux/slices/toasts'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.common.celebrations_download_report_modal')

const FILTER = {
  birthdays: 'birthday',
  work_anniversaries: 'work_anniversary',
  new_employees: 'start_date',
}

const CelebrationsReportModal = ({
  className,
  reportType,
  visible,
  toggle,
}) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [isCreatingReport, setIsCreatingReport] = useState(false)

  const generateReport = () => {
    setIsCreatingReport(true)

    const params = {
      startDate,
      endDate,
      filter: FILTER[reportType],
    }

    return API.admin.metrics.generateReport(reportType, params).then((response) => {
      setIsCreatingReport(false)
      dispatch(showToastMessage({ message: response.data, type: 'success' }))
      toggle()
    }).catch(({ response: { data } }) => {
      setIsCreatingReport(false)
      dispatch(showToastMessage({ message: data?.error?.message, type: 'error' }))
    })
  }

  return (
    <Modal size='lg' className={className} visible={visible} toggle={toggle}>
      <div className='text-center'>
        <div className='row'>
          <div className='col text-left'>
            <h4>{I18N('title')}</h4>
          </div>
        </div>
        <div className='row'>
          <div className='col text-left'>
            <p className='text-normal text-secondary'>{I18N('secondary_text')}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col text-left'>
            <label className='required-form-label'>{I18N('start_date_label')}</label>
            <DatePicker
              selected={startDate && i18nMoment(startDate)}
              onChange={timeValue => setStartDate(timeValue && timeValue.format())}
              isClearable
              dateFormat='LL'
            />
          </div>
          <div className='col text-left'>
            <label className='required-form-label'>{I18N('end_date_label')}</label>
            <DatePicker
              selected={endDate && i18nMoment(endDate)}
              onChange={timeValue => setEndDate(timeValue && timeValue.format())}
              isClearable
              dateFormat='LL'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col d-flex justify-content-end align-items-center'>
            <div role='button' tabIndex={0} onClick={() => toggle()} className='mr-3 text-muted-dark'>
              {I18N('cancel_text')}
            </div>
            <Button
              className='my-4'
              onClick={generateReport}
              disabled={isCreatingReport}
            >
              {I18N('create_text')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CelebrationsReportModal
