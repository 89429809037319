import React from 'react'
import { useSelector } from 'react-redux'

import classNames from 'classnames'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'

import RichTextView from 'components/common/richTextView'
import AnswerByLine from 'components/qna/answerByLine'
import CircledTickIcon from 'components/icons/circledTickIcon'
import BadgedTickIcon from 'components/icons/badgedTickIcon'

const AnswerContent = ({
  answer, answer: { question }, className, children,
}) => {
  const { isAccepted, isVerified } = answer

  const ApprovedAnswerSection = () => {
    const { acceptedAnswer: questionHasAcceptedAnswer } = useSelector(
      qnaQuestionSlice.selectors.getAcceptedAnswer(question.id)
    )
    const { verifiedAnswer: questionHasVerifiedAnswer } = useSelector(
      qnaQuestionSlice.selectors.getVerifiedAnswer(question.id)
    )

    if (isVerified) {
      return <BadgedTickIcon className='mb-2 mr-2' style={{ minWidth: '28px', minHeight: '28px' }} />
    } else if (isAccepted) {
      return <CircledTickIcon className='mb-2 mr-2' style={{ minWidth: '28px', minHeight: '28px' }} />
    }

    if (questionHasAcceptedAnswer || questionHasVerifiedAnswer) return <div className='mr-2' style={{ width: '28px' }} />

    return null
  }

  return (
    <div className={classNames('AnswerContent d-flex', className)}>
      <ApprovedAnswerSection />

      <div className='d-flex flex-column flex-grow-1 w-100'>
        <div className='overflow-x-auto overflow-y-hidden'>

          <AnswerByLine answer={answer} className='mb-2 text-small text-secondary' />

          <RichTextView
            record={answer}
            recordType='answer'
            richTextKey='content'
            className='break-word p-1'
          />
        </div>

        {children}
      </div>
    </div>
  )
}

export default AnswerContent
