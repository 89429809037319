import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ReactSelect from 'components/common/react_select'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import searchSlice from 'redux/slices/search'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { i18nPath } from 'utils/i18nHelpers'
import { isEmpty } from 'lodash'
import useUserFilter from 'components/search/filters/refined_filters/hooks/useUserFilter'

const I18N = i18nPath('views.search.search_results.filters')

const GoogleDriveFilter = ({ updateHistory }) => {
  const dispatch = useDispatch()
  const { query, filters: rawFilters = '{}' } = useQueryParams()
  const filters = useMemo(() => JSON.parse(rawFilters), [rawFilters])
  const { mime_type: mimeType = '' } = filters
  const { user: owner, setUser: setOwner } = useUserFilter({ filterParam: 'owner_id' })

  // see https://developers.google.com/drive/api/v3/mime-types
  const googleDriveMimeTypeOptions = [
    { label: I18N('type_google_docs'), value: 'application/vnd.google-apps.document' },
    { label: I18N('type_google_sheets'), value: 'application/vnd.google-apps.spreadsheet' },
    { label: I18N('type_google_slides'), value: 'application/vnd.google-apps.presentation' },
    { label: I18N('type_pdf'), value: 'application/pdf' },
    { label: I18N('type_photo'), value: 'application/vnd.google-apps.photo' },
    { label: I18N('type_video'), value: 'application/vnd.google-apps.video' },
    { label: I18N('type_audio'), value: 'application/vnd.google-apps.audio' },
    { label: I18N('type_google_drawing'), value: 'application/vnd.google-apps.drawing' },
    { label: I18N('type_google_drive_file'), value: 'application/vnd.google-apps.file' },
    { label: I18N('type_google_drive_folder'), value: 'application/vnd.google-apps.folder' },
    { label: I18N('type_google_forms'), value: 'application/vnd.google-apps.form' },
    { label: I18N('type_google_fusion_tables'), value: 'application/vnd.google-apps.fusiontable' },
    { label: I18N('type_google_my_maps'), value: 'application/vnd.google-apps.map' },
    { label: I18N('type_google_apps_scripts'), value: 'application/vnd.google-apps.script' },
    { label: I18N('type_shortcut'), value: 'application/vnd.google-apps.shortcut' },
    { label: I18N('type_3rd_party_shortcut'), value: 'application/vnd.google-apps.drive-sdk' },
    { label: I18N('type_unknown'), value: 'application/vnd.google-apps.unknown' },
  ]

  useEffect(() => {
    dispatch(searchSlice.actions.reset({ searchLocation: 'pageFiltered' }))

    dispatch(searchSlice.asyncActions.searchGoogleDrive({
      query,
      searchGoogleDrive: true,
      searchLocation: 'pageFiltered',
      filters,
      track: true,
    }))
  }, [query, filters])

  const handleSearch = (newFilters, type) => {
    const updatedFilters = { ...filters, ...newFilters }

    if (!newFilters) {
      delete updatedFilters[type]
    }

    updateHistory(updatedFilters)
  }

  const handleOwnerChange = (option) => {
    setOwner(option)
    const filters = option
      ? {
        owner_id: option.id,
      }
      : null
    handleSearch(filters, 'owner_id')
  }

  const handleFileTypeChange = (option) => {
    const filters = !isEmpty(option)
      ? {
        mime_type: option.value,
      }
      : null
    handleSearch(filters, 'mime_type')
  }

  return (
    <div className='GoogleDriveFilter MultipleRefinedFilters px-3'>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('drive_file_type_filter')}
        </div>
        <ReactSelect
          isClearable
          value={googleDriveMimeTypeOptions.filter(({ value }) => value === mimeType)}
          placeholder={(
            <div className='text-secondary'>
              {I18n.t('views.common.react_select.select_or_type')}
            </div>
          )}
          onChange={handleFileTypeChange}
          options={googleDriveMimeTypeOptions}
        />
      </div>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('drive_owner_filter')}
        </div>

        <EmployeeSearch
          onChange={handleOwnerChange}
          selectedEmployee={owner}
        />
      </div>
    </div>
  )
}

export default GoogleDriveFilter
