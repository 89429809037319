import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import profileSlice from 'redux/slices/profiles'
import CdnSvg from 'components/common/cdnSvg'
import { showToastMessage } from 'redux/slices/toasts'
import EditableContent from 'components/common/editable_content'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useGetLatLong from 'components/profiles/hooks/useGetLatLong'

const I18N = i18nPath('views.profile')
const locationPinIconPath = '/images/locationPinIcon.svg'

const DisplayElement = ({ user, getLatLong, canEdit }) => (
  <div className='w-100'>
    <span className='CurrentLocation d-inline-flex align-items-center w-100'>
      <CdnSvg className='LocationPinIcon mr-2' src={locationPinIconPath} />
      {user.currentLocation ? user.currentLocation : I18N('where_to_find_me.update_location')}
    </span>
    {canEdit && (
      // this only shows up on hover
      <div
        className='LocationFromBrowser'
        onClick={getLatLong}
      >
        {I18N('where_to_find_me.get_from_browser')}
      </div>
    )}
  </div>
)

const CurrentLocationForUser = ({ user, canEdit }) => {
  const dispatch = useDispatch()
  const isCurrentLocationPresent = !!user.currentLocation

  const { isSaving } = useSelector(profileSlice.selectors.getMetaData())

  const updateLocation = value => dispatch(
    profileSlice.asyncActions.update(user, { currentLocation: value, currentLatitude: null, currentLongitude: null })
  )

  const onReceivedCoordinates = (latitude, longitude) => {
    const change = {
      currentLatitude: latitude,
      currentLongitude: longitude,
    }

    return dispatch(profileSlice.asyncActions.update(user, change))
  }

  const onErrorFetchingCoordinates = () => {
    dispatch(showToastMessage({ message: I18N('failed_location_detection'), type: 'error' }))
  }

  const { getLatLong, isLoading: isLoadingLatLong } = useGetLatLong({
    onSuccess: onReceivedCoordinates,
    onError: onErrorFetchingCoordinates,
  })

  if (!isCurrentLocationPresent && !canEdit) {
    return null
  }

  if (isSaving || isLoadingLatLong) {
    return <CirclesLoadingIndicator className='align-self-center' />
  }

  return (
    <EditableContent
      className='CurrentLocationForUser w-100'
      canEdit={canEdit}
      displayElement={(
        <DisplayElement
          user={user}
          getLatLong={getLatLong}
          canEdit={canEdit}
        />
      )}
      inputElement={(
        <input
          className='font-weight-400 p-2 w-100'
          type='text'
          maxLength='280'
        />
      )}
      valueToEdit={user.currentLocation}
      saveContent={updateLocation}
    />
  )
}

export default CurrentLocationForUser
