import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import MobileViewToolbar from 'components/common/mobileViewToolbar'
import useCurrentPage from 'components/pages/hooks/useCurrentPage'
import PageHierarchyBreadcrumbs from 'components/pages/pageHierarchyBreadcrumbs'

const PageMobileToolbar = () => {
  const { workspaceSlug } = useParams()
  const history = useHistory()
  const page = useCurrentPage()

  return (
    <MobileViewToolbar onClickHamburgerNav={() => history.push(workspaceSlug ? `/workspaces/${workspaceSlug}` : '/pages')}>
      <PageHierarchyBreadcrumbs ancestors={page?.ancestors} />
    </MobileViewToolbar>
  )
}

export default PageMobileToolbar
