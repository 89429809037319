import React from 'react'
import EventNotificationWidget from 'components/qna/eventNotificationWidget'
import AgendaItemWidget from 'components/qna/agendaItemWidget'
import SpeakerWidget from 'components/qna/speakerWidget'

import isMobile from 'utils/isMobile'

const SidebarContent = ({
  agendaEnabled,
  canManageEvent,
  event,
  speakersEnabled,
  onClickPublish,
}) => (
  /**
   * use order-1, order-3, order-4 to change
   * the order of items within flex container.
   */
  <>
    <span className='order-1'>
      <EventNotificationWidget
        event={event}
        onClickPublish={onClickPublish}
      />
    </span>
    <span className='order-3 mt-3 mt-lg-0'>
      {agendaEnabled && <AgendaItemWidget event={event} enableEditing={canManageEvent} />}
    </span>
    <span className='order-4 mt-lg-0'>
      {speakersEnabled && <SpeakerWidget event={event} enableEditing={canManageEvent} />}
    </span>
  </>
)

const EventPageSidebar = ({
  event,
  canManageEvent,
  onClickPublish,
}) => {
  const { settings } = event

  // Return widgets unwrapped on mobile so the
  // widget items can be siblings of other flex items
  if (isMobile()) {
    return (
      <SidebarContent
        event={event}
        agendaEnabled={settings.agendaItems.enabled}
        speakersEnabled={settings.speakers.enabled}
        canManageEvent={canManageEvent}
        onClickPublish={onClickPublish}
      />
    )
  }

  // Desktop event page has a two column view
  return (
    <div className='EventPageSidebar col-lg-4'>
      <SidebarContent
        event={event}
        agendaEnabled={settings.agendaItems.enabled}
        speakersEnabled={settings.speakers.enabled}
        canManageEvent={canManageEvent}
        onClickPublish={onClickPublish}
      />
    </div>
  )
}

export default EventPageSidebar
