import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import { ReduxState } from 'redux/redux'
import API from 'services/api'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { Button } from 'components/common/buttons'
import ImageSelector from 'components/common/imageSelector'
import LogoDimensionsSubsection from './logoDimensionsSubsection'

const I18N = i18nPath('views.custom_styles')

const CompanyLogo = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState({})
  const imageUrls = useSelector((state: ReduxState) => state.imageUrls)
  const currentCompany = useCurrentCompany()
  const { companyLogo: defaultLogoUrl } = imageUrls

  const handleImageInputChange = (image: any, imageName: string) => {
    setImages(oldImages => ({ ...oldImages, [imageName]: image }))
  }

  const saveImage = (imageName: string) => {
    const image = images[imageName]

    if (!image) return

    setIsLoading(true)

    API.admin.customStyles.uploadImage(currentCompany.id, image, imageName).then(() => {
      window.location.reload()
    })
  }

  return (
    <>
      <div className='row my-3'>
        <div className='col-md-12'>
          <h3>{I18N('company_logo_header')}</h3>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='Styles--CompanyLogo d-flex justify-content-between'>
            <div className='LogoImage'>
              <ImageSelector
                className='mb-2 CustomStyles'
                maxSizeInMB={1}
                placeholderUrl={currentCompany.imageUrl || defaultLogoUrl}
                onImageAdded={image => handleImageInputChange(image, 'image')}
                accept={['.png', '.jpg', '.jpeg', '.svg']}
              />
              <Button onClick={() => saveImage('image')} disabled={isLoading}>
                {isLoading ? I18N('logo_uploading') : I18N('logo_upload')}
              </Button>
            </div>
            <div className='LogoDimensions'>
              <LogoDimensionsSubsection />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyLogo
