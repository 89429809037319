import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.preview_components')

const BtnMutedColor = () => (
  <a className='btn-muted' href='#'>
    {I18N('ask_a_question')}
  </a>
)

export default BtnMutedColor
