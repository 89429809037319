import { useEffect, useRef } from 'react'
import { trackEvent } from 'services/tracker'


const useViewDurationTracker = (ahoyEventId: number | undefined) => {
  const startTimeRef = useRef<number | undefined>()

  useEffect(() => {
    if (!ahoyEventId) { return () => {} }

    startTimeRef.current = new Date().getTime()

    const track = () => {
      const duration = new Date().getTime() - startTimeRef.current!

      const params = {
        duration,
        ahoyEventId,
      }

      trackEvent('view_duration', params)
    }

    window.addEventListener('beforeunload', track)

    return () => {
      track()
      window.removeEventListener('beforeunload', track)
    }
  }, [ahoyEventId])
}

export default useViewDurationTracker
