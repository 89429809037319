import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import ShoutoutForm from 'components/shoutouts/shoutoutForm'
import shoutoutsSlice, { defaultWorkingCopy } from 'redux/slices/shoutouts'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { useDispatch } from 'react-redux'

const I18N = i18nPath('views.shoutouts')


const ThanksMessage = ({ selectedUsers, className }) => (
  <div className={classNames('text-center p-5', className)}>
    <h2>{I18N('thanks_header')}</h2>
    <div className='text-secondary'>{I18N('will_be_shared_with', { names: selectedUsers.map(user => user.preferredFullName).join(', ') })}</div>
  </div>
)

const ShoutoutModal = ({
  shoutout = null,
  visible,
  closeModal,
  user = null,
  onCreated = (data) => {},
}) => {
  const dispatch = useDispatch()
  const [workingCopy, setWorkingCopy] = useState(shoutout || defaultWorkingCopy)
  const updateWorkingCopy = (changes = {}) => setWorkingCopy({ ...workingCopy, ...changes })

  const [showThanksMessage, setShowThanksMessage] = useState(false)

  const [createShoutout, { isLoading: isCreating }] = useApi(API.shoutouts.create, {
    updateEntitySlice: true,
    toastSuccessMessage: I18N('shoutout_created'),
    onSuccess: (data) => {
      setShowThanksMessage(true)
      dispatch(shoutoutsSlice.actions.prependNewShoutoutId(data.id))
      onCreated(data)
    },
  })

  const [updateShoutout, { isLoading: isUpdating }] = useApi(API.shoutouts.update, {
    updateEntitySlice: true,
    toastSuccessMessage: I18N('shoutout_updated'),
    onSuccess: closeModal,
  })

  const isSaving = isCreating || isUpdating

  useEffect(() => {
    if (!user) return

    if (workingCopy.users.map(user => user.id).includes(user.id)) return
    updateWorkingCopy({ users: [user] })
  }, [user?.id])

  const sendShoutout = (workingCopy) => {
    if (isSaving) return

    const params = {
      text: workingCopy.text,
      userIds: workingCopy.users.map(user => user.id),
      companyValueIds: workingCopy.companyValues.map(companyValue => companyValue.id),
      gifUrl: workingCopy.gifUrl,
    }

    if (workingCopy.id) {
      updateShoutout({ ...params, id: workingCopy.id })
    } else {
      createShoutout({ ...params, shoutoutSuggestionId: user?.suggestionId })
    }
  }

  return (
    <Modal className='ShoutoutModal' visible={visible} toggle={closeModal}>
      <ThanksMessage selectedUsers={workingCopy.users} className={classNames(!showThanksMessage && 'd-none')} />
      <ShoutoutForm
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        sendShoutout={sendShoutout}
        className={classNames(showThanksMessage && 'd-none')}
        isSaving={isSaving}
      />
    </Modal>
  )
}

export default ShoutoutModal
