import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import { Popover } from 'reactstrap'
import EditableContentTrigger from 'components/common/editable_content/editableContentTrigger'
import profileSlice from 'redux/slices/profiles'
import currentTimezone from 'utils/currentTimezone'
import CdnSvg from 'components/common/cdnSvg'
import WorkingHoursModal from './workingHoursModal'

const clockIconPath = '/images/clockIcon.svg'

const I18N = i18nPath('views.profile.working_hours')

const constructTooltipText = (user) => {
  const browserTimezone = currentTimezone()

  if (user?.workingHoursStartTime == null || user?.workingHoursEndTime == null
    || user?.currentTimezone == null || browserTimezone == null) {
    return null
  }

  // shows a tooltip displaying the user's working hours converted to the timezone of the current user.
  // returns a string like:
  // 'This is 10:30 AM - 5:30 PM in your timezone'
  const start = moment.tz(user.workingHoursStartTime, 'LT', user.currentTimezone).tz(browserTimezone).format('LT')
  const end = moment.tz(user.workingHoursEndTime, 'LT', user.currentTimezone).tz(browserTimezone).format('LT')
  return I18N('working_hours_in_your_timezone', { start, end })
}

const formatTimeString = time => moment(time, 'LT').format('LT')

const DisplayText = ({
  className,
  iconPath,
  isEmpty,
  svgClassName,
  user,
}) => {
  // if times are present, returns a string like:
  // 'Work Hours: 9:30 AM - 5:30 PM EST'
  //
  // if the timezone doesn't have an abbreviation like 'EST', it will use the offset like:
  // 'Work Hours: 9:30 AM - 5:30 PM GMT+05'
  //
  // if the times aren't present, it shows a prompt like:
  // 'What are your working hours?'
  let text = I18N('what_are_your_working_hours')

  if (!isEmpty) {
    const start = formatTimeString(user.workingHoursStartTime)
    const end = formatTimeString(user.workingHoursEndTime)
    let timezoneAbbreviation = moment.tz(user.currentTimezone).zoneAbbr()

    if (timezoneAbbreviation.charAt(0) === '+' || timezoneAbbreviation.charAt(0) === '-') {
      timezoneAbbreviation = `GMT${timezoneAbbreviation}`
    }

    text = `${start} - ${end} ${timezoneAbbreviation}`
  }

  return (
    <span className={classNames('WorkingHours d-inline-flex align-items-center', className)}>
      <CdnSvg className={classNames('mr-1', svgClassName)} src={iconPath} />
      <span className='d-inline-flex flex-wrap'>
        {!isEmpty && (
          <span className='font-weight-500'>{I18N('work_hours')}&nbsp;</span>
        )}
        <span>{text}</span>
      </span>
    </span>
  )
}

const WorkingHours = ({
  canEdit,
  update,
  user,
}) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const tooltipText = constructTooltipText(user)
  const areTimesPresent = !_.isEmpty(user?.workingHoursStartTime)
    && !_.isEmpty(user?.workingHoursEndTime)
  const targetId = `user-working-hours-${user.id}`

  const updateTimes = (start, end) => {
    const change = {
      workingHoursStartTime: start,
      workingHoursEndTime: end,
    }

    return dispatch(profileSlice.asyncActions.update(user, change))
  }

  // if you are looking at someone else's profile and they have not set working hours - show nothing
  if (!canEdit && !areTimesPresent) {
    return null
  }

  return (
    <>
      <WorkingHoursModal
        user={user}
        isOpen={isModalOpen}
        toggle={toggleModal}
        update={updateTimes}
      />
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        id={targetId}
      >
        <EditableContentTrigger
          canEdit={canEdit}
          isEmpty={!areTimesPresent}
          onClick={canEdit ? toggleModal : null}
          displayElement={(
            <DisplayText
              iconPath={clockIconPath}
              svgClassName='ClockIcon mr-2'
              className={!areTimesPresent && 'font-weight-500'}
              user={user}
              isEmpty={!areTimesPresent}
            />
          )}
        />
      </div>
      {!canEdit && tooltipText && (
        <Popover
          placement='bottom'
          isOpen={showTooltip}
          target={targetId}
        >
          <div className='p-3 text-small'>{tooltipText}</div>
        </Popover>
      )}
    </>
  )
}

export default WorkingHours
