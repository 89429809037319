import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { trackAmplitudeEvent } from 'services/tracker'

const PathnameContext = React.createContext()
export default PathnameContext


const PathnameContextProvider = ({ children }) => {
  const [currentPathname, setCurrentPathname] = useState()
  const [previousPathname, setPreviousPathname] = useState()

  const location = useLocation()

  useEffect(() => {
    setPreviousPathname(currentPathname)
    setCurrentPathname(location.pathname)
    trackAmplitudeEvent('page_viewed')
  }, [location])

  return (
    <PathnameContext.Provider value={{ currentPathname, previousPathname }}>
      {children}
    </PathnameContext.Provider>
  )
}

export { PathnameContextProvider }
