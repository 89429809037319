import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import InviteOthersButton, { INVITE_OTHERS_MODAL } from 'components/celebrations/greeting_card_actions/inviteOthersButton'
import SendRemindersButton, { SEND_REMINDERS_MODAL } from 'components/celebrations/greeting_card_actions/sendRemindersButton'
import SignCardButton, { SIGN_CARD_MODAL } from 'components/celebrations/greeting_card_actions/signCardButton'
import { ButtonSecondary } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import useLoadPendingUsers from 'components/celebrations/hooks/useLoadPendingUsers'
import useShowInviteOthersButton from 'components/celebrations/hooks/useShowInviteOthersButton'

const I18N = i18nPath('views.celebrations.greeting_card')


const GreetingCardActions = ({
  celebration,
  short = false,
  legacy = true,
  showCustomizeButton: showCustomizeButtonProp = true,
  showInviteOthersButton: showInviteOthersButtonProp = true,
}) => {
  const { pendingUsers, showSendRemindersButton, removePendingUsers } = useLoadPendingUsers(celebration)
  const showInviteOthersButton = useShowInviteOthersButton(celebration) && showInviteOthersButtonProp

  // Show customize button if it's enabled and either send reminders or invite others button is disabled
  const showCustomizeButton = showCustomizeButtonProp && (!showSendRemindersButton || !showInviteOthersButton)

  return (
    <div className='p-3 d-flex justify-content-center align-items-center flex-column flex-md-row gap-3'>
      <SignCardButton
        celebration={celebration}
        legacy={legacy}
      />

      {showCustomizeButton && (
        <Link to={`/people/celebrations/${celebration.id}/edit`}>
          <ButtonSecondary>
            {I18N('customize_card')}
          </ButtonSecondary>
        </Link>
      )}

      {showInviteOthersButton && (
        <InviteOthersButton
          celebration={celebration}
          short={short}
        />
      )}

      {showSendRemindersButton && (
        <SendRemindersButton
          celebration={celebration}
          pendingUsers={pendingUsers}
          onInviteSuccess={removePendingUsers}
        />
      )}
    </div>
  )
}

export default GreetingCardActions
