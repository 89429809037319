import React, { useState } from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import GifSelector from 'components/common/tenor/gifSelector'

const GifSelectorPage = () => {
  const [gifUrl, setGifUrl] = useState<string | null>(null)
  const [gifUrl2, setGifUrl2] = useState<string | null>(null)
  const [gifUrl3, setGifUrl3] = useState<string | null>('https://media.tenor.com/cq2TgsgZnRMAAAAC/kiss.gif')

  return (
    <main className='Styleguide GifSelectorPage container mt-4'>
      <h1><StyleguideBaseLink /> / GifSelector </h1>
      <section className='mt-4'>
        <h3>client/components/common/tenor/gifSelector</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
              GifSelector
              </td>
              <td>
              Normal selector
              </td>
              <td>
                <GifSelector
                  setGifUrl={setGifUrl}
                  gifUrl={gifUrl}
                />
              </td>
            </tr>

            <tr>
              <td>
              GifSelector
              </td>
              <td>
              With query
              </td>
              <td>
                <GifSelector
                  initialTerm='love'
                  setGifUrl={setGifUrl2}
                  gifUrl={gifUrl2}
                />
              </td>
            </tr>
            <tr>
              <td>
              GifSelector
              </td>
              <td>
              With selected gif
              </td>
              <td>
                <GifSelector
                  setGifUrl={setGifUrl3}
                  gifUrl={gifUrl3}
                />
              </td>
            </tr>
          </tbody>
        </table>

      </section>
    </main>
  )
}

export default GifSelectorPage
