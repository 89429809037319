import React, { useEffect, useState } from 'react'

import MultilineGraph from 'components/analytics/common/multilineGraph'
import NumberPercentageToggle, { NUMBER, PERCENTAGE } from 'components/analytics/common/numberPercentageToggle'
import { dateLabels } from 'components/analytics/helpers/dateLabelFormat'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { useDispatch, useSelector } from 'react-redux'
import cultureAnalyticsSlice from 'redux/slices/analytics/culture'
import TargetingRulesType from 'types/audience/targetingRules'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'
import API from 'services/api'

const I18N = i18nPath('views.platform_analytics.culture.engagement')

interface CultureEngagementProps {
  groupBy: string
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const CultureEngagement = ({
  groupBy, periodEnd, periodStart, targetingRules,
}: CultureEngagementProps) => {
  const [selectedType, setSelectedType] = useState(NUMBER)
  const currentCompany = useCurrentCompany()
  const { highlightColor } = currentCompany.customStyles.variables.colors

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cultureAnalyticsSlice.asyncActions.admin.fetchEngagement(
      'engagementByEventType',
      {
        periodStart, periodEnd, groupBy, groupByEventType: true, type: selectedType, targetingRules,
      }
    ))

    dispatch(cultureAnalyticsSlice.asyncActions.admin.fetchEngagement(
      'allEngagement',
      {
        periodStart, periodEnd, groupBy, type: selectedType, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, groupBy, selectedType, targetingRules])

  const { data: allEngagementData } = useSelector(cultureAnalyticsSlice.selectors.getAllEngagement())
  const { data: engagementByEventTypeData } = useSelector(cultureAnalyticsSlice.selectors.getEngagementByEventType())

  const data = [
    ...allEngagementData.map(d => ({ ...d, eventType: 'all' })),
    ...engagementByEventTypeData,
  ]

  const dataGrouped = Object.values(_.groupBy(data, 'eventType'))

  const xlabels = dataGrouped.length > 0 ? dateLabels(dataGrouped[0], groupBy) : []

  const {
    allEngagement: { isLoading: isLoadingAllEngagement },
    engagementByEventType: { isLoading: isLoadingEngagementByEventType },
  } = useSelector(cultureAnalyticsSlice.selectors.getMetaData())

  const isLoading = isLoadingAllEngagement || isLoadingEngagementByEventType

  const colors = {
    shoutout: '#90AAF9',
    badge: '#45AAF2',
    celebration: '#F7B731',
    culture: '#611f69',
    all: highlightColor,
  }

  const lines = dataGrouped.map(data => ({
    label: I18N(`labels.${data[0].eventType}`),
    color: colors[data[0].eventType || 'all'],
    values: data.map(({ value }) => value),
    tooltipLabel: count => `${I18N(`labels.${data[0].eventType}`)}: ${I18NCommon('unique_users_count', { count: selectedType === PERCENTAGE ? count + '%' : count })}`,
  }))

  const yAxisLabelKey = [
    'y_axis_label',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const onExport = (email: string) => {
    const exportXlsxParams = {
      periodStart,
      periodEnd,
      targetingRules,
      groupBy,
      email,
    }

    return API.admin.analytics.culture.exportEngagementXlsx(exportXlsxParams)
  }


  return (
    <div className='analytics-card grid-span-12'>
      <header className='d-flex flex-row justify-content-between'>
        <h3>{I18N('title')}</h3>
        <div className='d-flex flex-row align-items-center'>
          <NumberPercentageToggle selectedType={selectedType} setSelectedType={setSelectedType} />

          <ExportXlsxButton
            className='ml-1'
            onSubmit={onExport}
          />
        </div>
      </header>

      <MultilineGraph
        xlabels={xlabels}
        lines={lines}
        yAxisLabel={I18N(yAxisLabelKey)}
        isLoading={isLoading}
        asPercentage={selectedType === PERCENTAGE}
        height='250px'
      />
    </div>
  )
}

export default CultureEngagement
