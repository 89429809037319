import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import Stat from 'pages/admin/articles/stats/Stat'
import { getStatsValue, removeSideZerosValues } from 'pages/admin/articles/stats/helpers'
import LineGraph from 'components/analytics/common/lineGraph'

const I18N = i18nPath('views.admin.article_stats')


const FeedImpressions = ({ articleStat }) => {
  const cardImpressionsCount = getStatsValue(articleStat, 'cardImpressionsCount')
  const cardImpressionsUniqueCount = getStatsValue(articleStat, 'cardImpressionsUniqueCount')
  const audienceUniqueCardImpressionsCount = getStatsValue(articleStat, 'audienceUniqueCardImpressionsCount')
  const audienceCardReach = I18n.toPercentage(
    getStatsValue(articleStat, 'audienceCardReach') * 100,
    { precision: 1 }
  )

  const engagementByDate = removeSideZerosValues(articleStat.engagementByDate || [], 'cardImpressionsCount')
  const labels = engagementByDate.map(counts => counts.date)
  const data = engagementByDate.map(counts => counts.cardImpressionsCount)


  return (
    <Card className='h-100'>
      <h5 className='mb-0 ml-1'>{I18N('feed_card_impressions.title')}</h5>

      <hr />

      <div className='row'>
        <div className='col-4 mb-4'>
          <Stat
            value={cardImpressionsCount}
            label={I18N('stat.total_feed_card_impressions')}
            tooltip={I18N('stat.total_feed_card_impressions_tooltip')}
          />
        </div>
        <div className='col-4 mb-4'>
          <Stat
            value={cardImpressionsUniqueCount}
            label={I18N('stat.unique_feed_card_impressions')}
            tooltip={I18N('stat.unique_feed_card_impressions_tooltip')}
          />
        </div>
        <div className='col-4 mb-4'>
          <Stat
            value={audienceUniqueCardImpressionsCount}
            label={I18N('stat.audience_feed_card_views')}
            tooltip={I18N('stat.audience_feed_card_views_tooltip')}
          />
        </div>
        <div className='col-4 mb-4'>
          <Stat
            value={audienceCardReach}
            label={I18N('stat.audience_feed_card_reach')}
            tooltip={I18N('stat.audience_feed_card_reach_tooltip')}
          />
        </div>
      </div>

      <h6 className='mt-2 mb-2'>{I18N('stat.total_feed_card_impressions')}</h6>

      <div style={{ position: 'relative', height: '130px' }} className='relative w-100'>
        <LineGraph
          labels={labels}
          dataValues={data}
          tooltipLabel={I18N('stat.total_page_views')}
          classname='w-100'
        />
      </div>
    </Card>
  )
}

export default FeedImpressions
