import React from 'react'

import Error404 from 'components/errors/404'
import LoadingSpinner from 'components/common/loadingSpinner'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import classNames from 'classnames'

interface TableLoadingContainerProps {
  children?: any
  isNotFound?: boolean
  isLoading?: boolean
  useCirclesLoadingIndicator?: boolean
  loaderClassName?: string
}

const TableLoadingContainer = ({
  children,
  isNotFound = false,
  isLoading = false,
  useCirclesLoadingIndicator = false,
  loaderClassName = 'position-absolute d-flex justify-content-center w-100 left-0 right-0',
}: TableLoadingContainerProps) => {
  if (isNotFound) {
    return <Error404 />
  }

  const Loader = useCirclesLoadingIndicator ? CirclesLoadingIndicator : LoadingSpinner

  let ChildComponent = typeof children === 'function' ? children() : children

  // We need to merge the existing className with the LoadingContainer className
  ChildComponent = React.cloneElement(ChildComponent, {
    className: classNames(ChildComponent.props.className, 'LoadingContainer--Children', { isLoading }),
  })

  return (
    <>
      {isLoading && <Loader className={loaderClassName} />}
      {ChildComponent}
    </>
  )
}

export default TableLoadingContainer
