import React, { useState, useEffect } from 'react'

import CdnSvg from 'components/common/cdnSvg'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import { Button } from 'components/common/buttons'
import CheckIcon from 'components/icons/checkIcon'
import FormCheck from 'components/form_fields/formCheck'
import getCdnUrl from 'utils/getCdnUrl'
import pageVariables from 'utils/pageVariables'

const { cdnDomain } = pageVariables

const I18N = i18nPath('views.settings.authorize_zoom_modal')

const AUTHORIZE_URL = '/auth/zoom/authorize_url'
const CLOSED_CAPTIONS_SETTINGS_URL = 'https://us02web.zoom.us/profile/setting'
const AUTHORIZE_IMAGE_PATH = '/images/integrations/zoom/zoom_authorization_preview.png'
const CAPTIONS_IMAGE_PATH = '/images/integrations/zoom/zoom_captions_setting_preview.png'

const Step1Form = ({
  authorizedThroughModal,
  setAuthorizedThroughModal,
  closeModal,
  setStep,
}) => {
  const [zoomAuthorizationWindow, setZoomAuthorizationWindow] = useState<any>(null)

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'zoomInstalled') {
        setAuthorizedThroughModal(true)
        zoomAuthorizationWindow?.close()
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [zoomAuthorizationWindow])

  const openZoomAuthorizationWindow = () => {
    const width = 600
    const height = 600
    const left = (window.innerWidth - width) / 2
    const top = (window.innerHeight - height) / 2

    const newZoomAuthorizationWindow = window.open(
      AUTHORIZE_URL,
      'AuthorizeZoomWindow',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    )

    setZoomAuthorizationWindow(newZoomAuthorizationWindow)
  }

  return (
    <>
      <div className='w-100'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='mr-3'>
            <div className='font-weight-600'>{I18N('step_1_title')}</div>
            <div className='mb-3 text-secondary text-small'>{I18N('step_1_description')}</div>
          </div>
          {authorizedThroughModal ? (
            <Button variant='success'>
              <CheckIcon className='' fillColor='var(--white-pure)' />
            </Button>
          ) : (
            <Button onClick={openZoomAuthorizationWindow}>
              {I18NCommon('install')}
            </Button>
          )}
        </div>
      </div>
      <div className='w-100 d-flex mb-2 justify-content-center'>
        <img
          src={getCdnUrl(cdnDomain, AUTHORIZE_IMAGE_PATH)}
          className='stepPreviewImage'
        />
      </div>
      <div className='d-flex justify-content-between align-items-center w-100'>
        <p className='text-secondary mb-0'>{I18N('step_number', { step: 1 })}</p>
        <div>
          <Button
            onClick={closeModal}
            className='mr-2 text-secondary'
            variant='outline'
          >
            {I18NCommon('close')}
          </Button>
          <Button
            onClick={() => setStep(2)}
            className='mr-2'
            disabled={!authorizedThroughModal}
          >
            {I18NCommon('next')}
          </Button>
        </div>
        <div />
      </div>
    </>
  )
}

const Step2Form = ({
  closeModal,
  setStep,
}) => {
  const [enableCaptionsButtonClicked, setEnableCaptionsButtonClicked] = useState(false)
  const [hasEnabledCaptions, setHasEnabledCaptions] = useState(false)

  return (
    <>
      <div className='w-100'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='mr-3'>
            <div className='font-weight-600'>{I18N('step_2_title')}</div>
            <div className='mb-3 text-secondary text-small'>{I18N('step_2_description')}</div>
          </div>
          <a
            href={CLOSED_CAPTIONS_SETTINGS_URL}
            onClick={() => setEnableCaptionsButtonClicked(true)}
            target='_blank'
            className='btn btn-narrow btn-primary'
            rel='noopener noreferrer'
          >
            {I18NCommon('enable')}
          </a>
        </div>
      </div>
      <div className='w-100 d-flex mb-2 justify-content-center'>
        <img
          src={getCdnUrl(cdnDomain, CAPTIONS_IMAGE_PATH)}
          className='stepPreviewImage'
        />
      </div>
      {enableCaptionsButtonClicked && (
        <FormCheck
          name='hasEnabledCaptions'
          label={I18N('ive_done_this')}
          checked={hasEnabledCaptions}
          onChange={({ target }) => setHasEnabledCaptions(target.checked)}
          id='hasEnabledCaptions'
          className='mb-3'
        />
      )}
      <div className='d-flex justify-content-between align-items-center w-100'>
        <p className='text-secondary mb-0'>{I18N('step_number', { step: 2 })}</p>
        <div>
          <Button
            onClick={() => setStep(1)}
            className='mr-2 text-secondary'
            variant='outline'
          >
            {I18NCommon('back')}
          </Button>
          <Button
            onClick={closeModal}
            className='mr-2'
            disabled={!hasEnabledCaptions}
          >
            {I18NCommon('finish')}
          </Button>
        </div>
        <div />
      </div>
    </>
  )
}

const AuthorizeZoomIntegrationModal = ({
  visible,
  closeModal,
  authorizedThroughModal,
  setAuthorizedThroughModal,
}) => {
  const [step, setStep] = useState(1)

  return (
    <Modal
      visible={visible}
      toggle={closeModal}
      className='AuthorizeZoomIntegrationModal'
      size='lg'
    >
      <div className='d-flex flex-column align-items-center px-3'>
        <CdnSvg src='/images/zoomIcon.svg' className='ZoomIcon mb-2' />
        <h4>{I18N('authorization_required')}</h4>
        <p className='text-secondary'>{I18N('authorization_required_description')}</p>
        {step === 1 && (
          <Step1Form
            authorizedThroughModal={authorizedThroughModal}
            setAuthorizedThroughModal={setAuthorizedThroughModal}
            closeModal={closeModal}
            setStep={setStep}
          />
        )}
        {step === 2 && <Step2Form closeModal={closeModal} setStep={setStep} />}
      </div>
    </Modal>
  )
}

export default AuthorizeZoomIntegrationModal
