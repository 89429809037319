import React from 'react'

import Card from 'components/common/card'
import { Link } from 'react-router-dom'

const PersonCard = ({ person }) => {
  const {
    primaryPhotoThumbnailUrl, preferredFullName, title, city, username,
  } = person

  return (
    <div className='PersonCard'>
      <Link to={`/profile/${username}`}>
        <Card cardBodyClassName='d-flex align-items-center'>
          <div>
            <img src={primaryPhotoThumbnailUrl} />
          </div>
          <div className='PersonInfo d-flex flex-column'>
            <div className='font-weight-600'>{preferredFullName}</div>
            <div className='text-small'>{title}</div>
            <div className='text-secondary text-small'>{city}</div>
          </div>
        </Card>
      </Link>
    </div>
  )
}

export default PersonCard
