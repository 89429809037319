import React from 'react'
import PreviewTemplateModal from 'components/admin/articles/templates/previewTemplateModal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { useHistory } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.article_list.item_dropdown.template')


const PreviewTemplateDropdownModal = ({ templateId, onClose }) => {
  const history = useHistory()

  const [createArticle, { isLoading }] = useApi(API.admin.articles.create, {
    toastSuccessMessage: I18N('create_article_success_message'),
    toastErrorMessage: I18N('create_article_error_message'),
    onSuccess: (article) => {
      // allow any state to be executed since it'll unmount this component and we'll have a
      // warning in console of type "Can't perform a React state on an unmounted component"
      setTimeout(() => {
        history.push(`/admin/articles/${article.id}`)
      })
    },
  })

  const onApplyTemplate = () => {
    if (isLoading) return

    createArticle({ templateId })
  }

  return (
    <PreviewTemplateModal
      templateId={templateId}
      onClose={onClose}
      applyTemplate={onApplyTemplate}
      isApplyingTemplate={isLoading}
    />
  )
}

export default PreviewTemplateDropdownModal
