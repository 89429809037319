import React, { useState, useEffect } from 'react'
import ReactSelect from 'components/common/react_select'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import { ButtonNarrow } from 'components/common/buttons'
import Modal from 'components/common/modal'
import CancelButton from 'components/common/cancelButton'
import pageTemplateCategorySlice from 'redux/slices/pageTemplateCategories'
import pageTemplateSlice from 'redux/slices/pageTemplates'
import { useHistory } from 'react-router-dom'
import { FormCheck } from 'react-bootstrap'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.pages.actions_dropdown')

const celebrationIconPath = '/images/celebrationIcon.svg'

interface SaveAsTemplateModalProps {
  className?: string
  isOpen: boolean
  onClose: () => void
  page: any
}

const TemplateCategorySelector = ({
  categories,
  selectedCategoryId,
  setSelectedCategoryId,
  isBaseTemplate,
}) => {
  const options = categories.map(category => ({
    label: category.name,
    value: category.id,
    isDisabled: (isBaseTemplate && !category.baseTemplateCategory)
                || (!isBaseTemplate && category.baseTemplateCategory),
  })
  )

  return (
    <div>
      <span className='d-inline-block text-normal font-weight-500 mt-3 mb-2 text-left'>
        {I18N('select_a_category')}
      </span>
      <ReactSelect
        value={options.find(option => option.value === selectedCategoryId)}
        options={options}
        filterOption={option => !option.isDisabled}
        onChange={selectedValue => setSelectedCategoryId(selectedValue.value)}
      />
    </div>
  )
}

const SaveAsTemplateModal = ({
  className,
  onClose,
  isOpen,
  page,
}: SaveAsTemplateModalProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedCategoryId, setSelectedCategoryId] = useState()
  const [isBaseTemplate, setIsBaseTemplate] = useState(false)
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()

  const { permissions: { clearyAdmin } } = currentUser
  // Only cleary admins can create base templates, they must be in cleary subdomain though
  const canCreateBaseTemplate = clearyAdmin && currentCompany?.subdomain === 'cleary'

  const {
    hasLoadedAllTemplateCategories,
  } = useSelector(pageTemplateCategorySlice.selectors.getMetaData())

  const categories = useSelector(pageTemplateCategorySlice.selectors.getPageTemplateCategories())
  const companyCategory = categories.find(category => category.name.includes(currentCompany.name))

  const { isSaving } = useSelector(pageTemplateSlice.selectors.getMetaData())

  const onSaveSuccess = (templateCategoryId: string, templateId: string) => {
    onClose()
    history.push(`/pages/template_categories/${templateCategoryId}/templates/${templateId}/edit`)
  }

  // only showing this modal if title and content present
  const saveTemplate = () => {
    const params: any = {
      templateCategoryId: selectedCategoryId,
      pageId: page?.id,
    }

    if (isBaseTemplate) {
      dispatch(pageTemplateSlice.asyncActions.admin.createBaseTemplate(params, onSaveSuccess))
    } else {
      dispatch(pageTemplateSlice.asyncActions.admin.create(params, onSaveSuccess))
    }
  }

  const setDefaultCategory = () => {
    if (companyCategory) {
      setSelectedCategoryId(companyCategory.id)
      if (!canCreateBaseTemplate) {
        // If we can't create base templates, the template is created directly
        dispatch(pageTemplateSlice.asyncActions.admin.create({
          templateCategoryId: companyCategory.id,
          pageId: page.id,
        }, onSaveSuccess))
      }
    }
  }

  useEffect(() => {
    if (!hasLoadedAllTemplateCategories) {
      dispatch(pageTemplateCategorySlice.asyncActions.fetchAll())
    }
  }, [])

  useEffect(() => {
    if (hasLoadedAllTemplateCategories) setDefaultCategory()
  }, [hasLoadedAllTemplateCategories])

  const handleCheckBaseTemplate = (event) => {
    const { target: { checked } } = event
    setIsBaseTemplate(checked)

    const selectedCategory = categories.find(category => category.id === selectedCategoryId)

    if (!checked && selectedCategory.baseTemplateCategory) {
      setSelectedCategoryId(companyCategory.id)
      return
    }


    // We need to change to a base template category if we are creating a base template
    if (checked && !selectedCategory.baseTemplateCategory) {
      const baseTemplateCategory = categories.find(category => category.baseTemplateCategory)
      setSelectedCategoryId(baseTemplateCategory.id)
    }
  }

  if (!canCreateBaseTemplate) return null

  return (
    <Modal size='md' className={classNames('SaveAsTemplateModal', className)} visible={isOpen} toggle={onClose}>
      <div className='d-flex flex-column align-items-center justify-content-center top-container p-4'>
        <div className='circle-container'>
          <CdnSvg className='CelebrationIcon' src={celebrationIconPath} />
        </div>
        <h4 className='mb-3 mt-3 text-left title text-large'>{I18N('save_your_template')}</h4>
      </div>
      <div className='mb-5'>
        <div className='block mx-4 mt-4 mb-2'>
          <TemplateCategorySelector
            selectedCategoryId={selectedCategoryId}
            setSelectedCategoryId={setSelectedCategoryId}
            categories={categories}
            isBaseTemplate={isBaseTemplate}
          />
          {canCreateBaseTemplate && (
            <FormCheck
              className='mx-4 mt-3'
              type='checkbox'
              id='base-template'
              label={I18N('base_template')}
              checked={isBaseTemplate}
              onChange={handleCheckBaseTemplate}
            />
          )}
        </div>
        {
          isBaseTemplate && (
            <div className='d-flex justify-content-center text-small text-danger mb-5 font-weight-400'>
              {I18N('base_template_category_save_note')}
            </div>
          )
        }
        <div className='d-flex justify-content-end align-items-center mt-4 mb-2 mr-4'>
          <CancelButton
            onClick={onClose}
            className='mr-4 font-weight-400'
          >
            {I18N('cancel')}
          </CancelButton>
          <ButtonNarrow
            onClick={saveTemplate}
            showLoadingSpinner={isSaving}
            disabled={isSaving}
          >
            {I18N('save_template')}
          </ButtonNarrow>
        </div>
      </div>
    </Modal>
  )
}

export default SaveAsTemplateModal
