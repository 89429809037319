import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import UserCard from 'components/home/widgets/onboarding_buddies_widget/userCard'
import SelectBuddyButton from 'components/home/widgets/onboarding_buddies_widget/selectBuddyButton'

const I18N = i18nPath('views.journeys.onboarding_buddies')

const SuggestedBuddies = ({
  isLoading,
  buddies,
  newHireId,
  className,
}) => {
  const history = useHistory()
  const onBuddySelected = () => history.push(newHireId ? '/admin/onboarding_buddies' : '/')

  return (
    <section className={classNames('SuggestedBuddies', className)}>
      <h5>{I18N('suggested_for_you')}</h5>
      {isLoading ? (
        <CirclesLoadingIndicator className='mt-4' />
      ) : (
        <>
          {buddies.map(buddy => (
            <UserCard
              key={`suggested-buddy-${buddy.id}`}
              user={buddy}
              includeStartDate
            >
              <div className='d-flex justify-content-center mt-2'>
                <SelectBuddyButton
                  buddy={buddy}
                  newHireId={newHireId}
                  onSuccess={onBuddySelected}
                />
              </div>
            </UserCard>
          ))}
        </>
      )}
    </section>
  )
}

export default SuggestedBuddies
