import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import FormSelectField from 'components/form_fields/formSelectField'
import classNames from 'classnames'
import userSlice from 'redux/slices/users'
import { useSelector } from 'react-redux'


const I18N = i18nPath('views.admin.users')


const UserSelectField = ({
  user,
  fieldName,
  label,
  isRequired,
  inputType = 'text',
  textarea = false,
  footNote = null,
  setUser = () => { },
  className = '',
  placeholder = '',
  isCreatable = false,
}) => {
  const currentValue = user[fieldName] ? { value: user[fieldName], label: user[fieldName] } : null

  const selectableOptionValues = useSelector(userSlice.selectors.getSelectableOptionValues())
  const { isLoadingSelectableOptionValues } = useSelector(userSlice.selectors.getMeta())

  const onChange = (selectedValue) => {
    setUser({ ...user, [fieldName]: selectedValue?.value || '' })
  }

  return (
    <FormSelectField
      fieldName={I18N(_.snakeCase(fieldName))}
      label={label}
      onChange={onChange}
      currentValue={currentValue}
      isRequired={isRequired}
      inputType={inputType}
      textarea={textarea}
      footNote={footNote}
      placeholder={placeholder}
      className={classNames('UserSelectField', className)}
      options={(selectableOptionValues[fieldName] || []).map(o => ({ value: o, label: o }))}
      isCreatable={isCreatable}
      isLoading={isLoadingSelectableOptionValues}
      disabled={isLoadingSelectableOptionValues}
    />
  )
}


export default UserSelectField
