import React, { useEffect, useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import useApi from 'components/common/hooks/useApi'
import DateCell from 'components/common/tables/dateCell'
import API from 'services/api'
import { arrayMove } from 'react-sortable-hoc'
import CheckBox from 'components/common/buttons/checkBox'
import { ButtonSecondary } from 'components/common/buttons'
import useFetch from 'components/common/hooks/useFetch'
import SortableTable from 'components/common/tables/sortableTable'

const I18N = i18nPath('views.admin.article_template_categories')

const CategoryCollapsedCheckbox = ({ category, update }) => {
  const [isCollapsed, setIsCollapsed] = useState(category.collapsed)

  const toggleCollapsed = async () => {
    const updatedCategory = await update({ id: category.id, collapsed: !isCollapsed })
    setIsCollapsed(updatedCategory.collapsed)
  }

  return (
    <CheckBox
      checked={isCollapsed}
      onChange={toggleCollapsed}
    />
  )
}


const TemplateCategoriesListPage = () => {
  const [newCategoryName, setNewCategoryName] = useState('')

  const {
    data: fetchedData, isLoading, callApi: fetchAll,
  } = useFetch(() => API.admin.article.templateCategories.fetchAll({ sortBy: 'order', sortDir: 'desc' }))
  const [update, { isLoading: isUpdating }] = useApi(API.admin.article.templateCategories.update, {
    toastErrorMessage: I18N('update_error'),
  })
  const [create, { isLoading: isCreating }] = useApi(API.admin.article.templateCategories.create, {
    onSuccess: () => {
      fetchAll({ sortBy: 'order', sortDir: 'desc' })
      setNewCategoryName('')
    },
  })

  const [data, setData] = useState([])

  useEffect(() => {
    setData(fetchedData)
  }, [fetchedData])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    update({ id: data[oldIndex].id, orderPosition: newIndex })
    setData(arrayMove(data, oldIndex, newIndex))
  }

  const columns = [
    {
      header: I18N('name'),
      accessor: category => category.name,
    },
    {
      header: I18N('updated_at'),
      accessor: category => <DateCell date={category.updatedAt} />,
    },
    {
      header: I18N('collapsed'),
      accessor: category => (
        <CategoryCollapsedCheckbox
          category={category}
          update={update}
        />
      ),
    },
  ]


  if (isLoading) return null

  return (
    <div className='TemplateCategoriesListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('template_categories')}</h3>
        <div className='d-flex'>
          <input
            className='mr-2'
            type='text'
            value={newCategoryName}
            onChange={e => setNewCategoryName(e.target.value)}
            placeholder={I18N('enter_category_name')}
          />
          <ButtonSecondary
            onClick={() => create({ name: newCategoryName })}
            disabled={isCreating}
            showLoadingSpinner={isCreating}
          >
            {I18NCommon('create')}
          </ButtonSecondary>
        </div>
      </header>
      <main className='AdminContent'>
        <div className='row mb-4'>
          <div className='col'>
            <SortableTable
              data={data}
              onSortEnd={onSortEnd}
              columns={columns}
              isSaving={isUpdating}
            />
          </div>
        </div>
      </main>
    </div>
  )
}

export default TemplateCategoriesListPage
