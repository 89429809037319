import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import linkSlice from 'redux/slices/links'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.groups.remove_go_link_modal')

const RemoveGoLinkModal = ({ goLink, collectionId, onClose }) => {
  const dispatch = useDispatch()
  const { isDeleting } = useSelector(linkSlice.selectors.getMetaData())

  const handleRemove = async () => {
    await dispatch(linkSlice.asyncActions.removeLinkFromCollection(goLink, { id: collectionId, type: 'group' }))
    dispatch(showToastMessage({ message: I18N('link_removed'), type: 'success' }))
    onClose()
  }

  return (
    <Modal visible className='RemoveGoLinkModal' title={I18N('title')} toggle={onClose}>
      <div className='my-4'>
        {I18N('text')}
      </div>

      <div className='d-flex align-items-center justify-content-end'>
        <CancelButton onClick={onClose} />
        <ButtonNarrow
          onClick={handleRemove}
          variant='danger'
          showLoadingSpinner={isDeleting}
          disabled={isDeleting}
        >
          {I18N('remove')}
        </ButtonNarrow>
      </div>
    </Modal>
  )
}

export default RemoveGoLinkModal
