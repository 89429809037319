import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { i18nPath } from 'utils/i18nHelpers'

import badgeSlice from 'redux/slices/badges'
import BadgeCard from 'components/badges/badgeCard'
import { ButtonLink } from 'components/common/buttons'
import InfiniteScroller from 'components/common/infiniteScroller'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import SortBy from 'components/common/sortBy'
import { trackEvent } from 'services/tracker'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.badges')

const CreateNewBadgeButton = () => (
  <ButtonLink to='/people/badges/new'>
    {I18N('create_new_badge')}
  </ButtonLink>
)

const BadgeListPageEmptyState = () => (
  <div className='EmptyState'>
    <CdnSvg src='/images/badges/badgeIcon.svg' />
    <div>
      <h5>{I18N('no_badges_created')}</h5>
      <span>{I18N('create_badge_cta')}</span>
    </div>
    <CreateNewBadgeButton />
  </div>
)

const SORT_BY_OPTIONS = [
  { id: 'relevance', value: '_score', text: I18N('sort.relevance') },
  { id: 'most_recent', value: 'created_at', text: I18N('sort.most_recent') },
  { id: 'most_awarded', value: 'awardees_count', text: I18N('sort.most_awarded') },
]

const BadgeListPage = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()

  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState(SORT_BY_OPTIONS[0])

  const badges = useSelector(badgeSlice.selectors.getBadges())
  const { isLoading, queryParams } = useSelector(badgeSlice.selectors.getMetaData())
  const noBadgesCreated = queryParams.total === 0 && !isLoading && searchQuery === ''
  const noBadgesFound = badges.length === 0 && !isLoading && searchQuery !== ''
  const canCreateBadge = currentUser.permissions.badgeCreator

  const fetchBadges = (newParams = {}) => {
    const params = {
      ...queryParams,
      q: searchQuery,
      sortBy: sortBy.value,
      sortDir: 'desc',
      ...newParams,
    }

    dispatch(badgeSlice.asyncActions.fetchAll(params))
  }

  useEffect(() => {
    fetchBadges()
    trackEvent('badge:view_all')

    return () => {
      dispatch(badgeSlice.actions.clearBadgeIds())
    }
  }, [])

  useEffect(() => {
    dispatch(badgeSlice.actions.clearBadgeIds())
    fetchBadges({ page: 1 })
  }, [searchQuery, sortBy])

  const hasMoreContent = () => {
    const { page, totalPages } = queryParams
    return page < totalPages
  }

  const handleFetch = () => {
    fetchBadges({ page: queryParams.page + 1 })
  }

  return (
    <main className='BadgeListPage'>
      <header>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <h1>{I18N('header')}</h1>
          {canCreateBadge && (
            <CreateNewBadgeButton />
          )}
        </div>
        <div className='SearchAndSort'>
          <AsyncSearchInput
            placeholder={I18N('search_placeholder')}
            icon={<CdnSvg src='/images/searchIcon.svg' />}
            onKeyUp={setSearchQuery}
            className='BadgeSearch'
          />
          <SortBy
            options={SORT_BY_OPTIONS}
            value={sortBy}
            onChange={setSortBy}
          />
        </div>
      </header>


      <InfiniteScroller
        isFetching={isLoading}
        onFetch={handleFetch}
        hasMoreContent={hasMoreContent}
        className='BadgeList'
      >
        {noBadgesFound && <span className='EmptyState text-secondary'>{I18N('no_badges_found')}</span>}
        {noBadgesCreated
          ? <BadgeListPageEmptyState />
          : badges.map(badge => (<BadgeCard key={`${badge.id}-badge-card`} badge={badge} />))}
      </InfiniteScroller>
    </main>
  )
}

export default BadgeListPage
