import React from 'react'

import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'

import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import { Link } from 'react-router-dom'
import API from 'services/api'
import { Button } from 'components/common/buttons'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { Banner } from 'components/banners/banners'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.admin.restricted_audience_configurations_list')

const columns = onPreview => [
  {
    header: I18N('name'),
    col: 'col-name',
    id: 'name',
    accessor: ({ name, id }) => (
      <Link to={`/admin/restricted_audiences/${id}`}>
        {name}
      </Link>
    ),
  },
  {
    header: I18N('created_at'),
    col: 'col-created_at',
    id: 'created_at',
    accessor: ({ createdAt }) => i18nMoment(createdAt).format('llll'),
  },
  {
    header: I18NCommon('actions'),
    col: 'col-actions',
    accessor: restrictedAudienceConfiguration => (
      <Button variant='secondary' onClick={() => onPreview(restrictedAudienceConfiguration)}>
        {I18NCommon('preview')}
      </Button>
    ),
  },
]

const RestrictedAudienceConfigurationsPage = () => {
  const { permissions: { clearyAdmin } } = useCurrentUser()

  const {
    data, isLoading, paginationData, callApi: fetchAll,
  } = useFetch(
    (paginationParams = { page: 1 }) => API.admin.restrictedAudienceConfigurations.fetchAll(paginationParams)
  )

  const {
    page, totalPages, total, perPage,
  } = paginationData

  const onPreview = async (restrictedAudienceConfiguration) => {
    await API.admin.restrictedAudienceConfigurations.startPreview(restrictedAudienceConfiguration)

    // force update and go to home
    window.open('/', '_blank')
  }

  return (
    <>
      <header className='AdminHeader'>
        <div className='d-flex justify-content-between align-items-end'>
          <h2 className='mb-1'>{I18N('restricted_audiences')}</h2>

          {clearyAdmin && (
            <div>
              <Link to='/admin/restricted_audiences/new'>
                <Button>
                  {I18N('create_new_restricted_audience')}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </header>

      {!clearyAdmin && (
        <Banner
          showDecline={false}
          className='BelowAdminHeader'
          banner={{
            text: I18N('view_and_preview_banner_text'),
          }}
        />
      )}

      <main className='AdminContent'>
        <TableLoadingContainer isLoading={isLoading}>
          <SmartTable
            className='RestrictedAudienceConfigurationsList white-bg-table'
            data={data}
            sortKey='created_at'
            sortedAsc={false}
            showPagination={page !== null && totalPages !== null}
            columns={columns(onPreview)}
            page={page}
            pages={totalPages}
            totalCount={total}
            perPage={perPage}
            onPaginationClick={fetchAll}
            onSortChange={fetchAll}
          />
        </TableLoadingContainer>
      </main>
    </>
  )
}

export default RestrictedAudienceConfigurationsPage
