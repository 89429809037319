import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import { getResponseOrThrow } from 'utils/errorHandling'
import appSignal from 'services/appSignal'
import { ReduxState } from 'redux/redux'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import normalizeTargetingRules, { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.admin.app_list')
export interface AppState {
  appIds: string[]
  userAppIds: string[]
  appId?: string
  meta: DefaultMetaType & {
    isDeleting: boolean
    isLoadingUserApps: boolean
  }
}

export const defaultWorkingCopy = {
  name: '',
  url: '',
  iconUrl: '',
  icon: null,
  orderPosition: 'last',
  targetingRules: TARGET_ENTIRE_COMPANY,
}

export const initialState: AppState = {
  appIds: [],
  userAppIds: [],
  meta: {
    ...defaultMeta,
    isDeleting: false,
    isLoadingUserApps: false,
  },
}

export const buildAppPayload = (app) => {
  const appAttributes = _.pick(app, [
    'id',
    'name',
    'url',
    'icon',
    'orderPosition',
    'targetingRules',
  ])

  if (app?.targetingRules) {
    appAttributes.targetingRules = normalizeTargetingRules(app.targetingRules)
  }

  return appAttributes
}

const appSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    ...defaultActions,

    setAppIds(state, action) {
      state.appIds = action.payload
    },

    clearAppIds(state) {
      state.appIds = []
    },

    setAppId(state, action) {
      state.appId = action.payload
    },

    clearAppId(state) {
      state.appId = undefined
    },

    setUserAppIds(state, action) {
      state.userAppIds = action.payload
    },

    isDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },

    isLoadingUserApps(state, action) {
      state.meta.isLoadingUserApps = action.payload
    },

    removeAppId(state, action) {
      const index = state.appIds.indexOf(action.payload)
      state.appIds.splice(index, 1)
    },
  },
})

const asyncActions = {
  admin: {
    fetchAll: (params = {}) => async (dispatch) => {
      try {
        dispatch(appSlice.actions.isLoading(true))

        const response = await API.admin.appLauncher.apps.fetchAll(params)

        const appIds = response.data.data.map(app => app.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(appSlice.actions.setAppIds(appIds))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(appSlice.actions.setError(getResponseOrThrow(error)))
      } finally {
        dispatch(appSlice.actions.isLoading(false))
      }
    },

    fetch: (id: string) => async (dispatch) => {
      try {
        dispatch(appSlice.actions.isLoading(true))
        const response = await API.admin.appLauncher.apps.fetch(id)

        dispatch(appSlice.actions.setAppId(id))
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(appSlice.actions.setError(getResponseOrThrow(error)))
      } finally {
        dispatch(appSlice.actions.isLoading(false))
      }
    },

    create: (params = {}, onSuccess = () => {}) => async (dispatch) => {
      try {
        dispatch(appSlice.actions.isSaving(true))
        await API.admin.appLauncher.apps.create(buildAppPayload(params))

        // Fetch apps for particular user in order to reflect changes in app launcher
        dispatch(asyncActions.fetchAll({ showLoading: false }))
        onSuccess()
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(appSlice.actions.setError(getResponseOrThrow(error)))
      } finally {
        dispatch(appSlice.actions.isSaving(false))
      }
    },

    update: (params = {}) => async (dispatch) => {
      try {
        dispatch(appSlice.actions.isSaving(true))

        const response = await API.admin.appLauncher.apps.update(buildAppPayload(params))

        dispatch(entitySlice.actions.update({ data: response.data }))

        // Fetch apps for particular user in order to reflect changes in app launcher
        dispatch(asyncActions.fetchAll({ showLoading: false }))
        dispatch(showToastMessage({ message: I18N('update_success'), type: 'success' }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(appSlice.actions.setError(getResponseOrThrow(error)))
      } finally {
        dispatch(appSlice.actions.isSaving(false))
      }
    },

    destroy: (id: string, onSuccess = () => {}) => async (dispatch) => {
      try {
        dispatch(appSlice.actions.isDeleting(true))
        await API.admin.appLauncher.apps.destroy({ id })

        dispatch(appSlice.actions.removeAppId(id.toString()))
        dispatch(entitySlice.actions.remove({ id, type: 'app' }))

        // Fetch apps for particular user in order to reflect changes in app launcher
        dispatch(asyncActions.fetchAll({ showLoading: false }))
        onSuccess()
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(appSlice.actions.setError(getResponseOrThrow(error)))
      } finally {
        dispatch(appSlice.actions.isDeleting(false))
      }
    },
  },

  fetchAll: ({ q = '', showLoading = true } = {}) => async (dispatch) => {
    try {
      dispatch(appSlice.actions.isLoadingUserApps(showLoading))

      const response = await API.appLauncher.apps.fetchAll({ q })

      const userAppIds = response.data.data.map(app => app.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(appSlice.actions.setUserAppIds(userAppIds))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(appSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(appSlice.actions.isLoadingUserApps(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.apps.meta,

  getApps: () => (state: ReduxState) => state.apps.appIds.map(id => build(state.entities, 'app', id)).filter(Boolean) || [],

  getApp: (id: string) => (state: ReduxState) => build(state.entities, 'app', id) || {},

  getUserApps: () => (state: ReduxState) => state.apps.userAppIds.map(id => build(state.entities, 'app', id)).filter(Boolean) || [],
}

export default {
  ...appSlice,
  asyncActions,
  selectors,
}
