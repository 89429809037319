import { NON_REQUIRED_STEP_TEMPLATE_ATTRIBUTES } from 'redux/slices/journeys/stepTemplates'
import { isAudienceValid } from 'utils/audience'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'

interface isStepTemplateValidProps {
  workingCopy: any
  nonRequiredFields?: string[]
  isStepTemplate?: boolean
  postTriggerNames?: string[]
}

export const isCorrespondentValid = (correspondentAttributes) => {
  const { role, user, group } = correspondentAttributes
  const isRoleValid = !!role
  const isUserValid = role === 'specific_employee' ? !!user?.id : true
  const isGroupValid = role === 'specific_group' ? !!group?.id : true

  return isRoleValid && isUserValid && isGroupValid
}

// PostTriggerNames is an array of the names of the triggers that come after the initial trigger
// For example, for a task, the dueAt trigger comes after the triggerAt trigger
// For a meeting, the rangeStart trigger comes after the triggerAt trigger, etc...
const isTriggerValid = (workingCopy, postTriggerNames: string[] = []) => {
  const { triggerAfterNumDays } = workingCopy

  const validAfterTriggers = postTriggerNames.every((triggerAfterName) => {
    const triggerAfterNameNumDays = workingCopy[`${triggerAfterName}AfterNumDays`]

    if (triggerAfterNameNumDays < triggerAfterNumDays) {
      return false
    }

    return true
  })

  return validAfterTriggers && (workingCopy.triggerMilestone !== 'step_completion' || workingCopy.parentId)
}

const areChannelsValid = (workingCopy) => {
  const {
    sendToEmail,
    subject,
    content,
    createFeedCard,
    feedTitle,
    feedContent,
    sendSms,
    smsContent,
  } = workingCopy
  const invalidFields: string[] = []

  if (sendToEmail) {
    if (!subject) invalidFields.push('subject')
    if (isHtmlStringEmpty(content)) invalidFields.push('content')
  }

  if (createFeedCard) {
    if (!feedTitle) invalidFields.push('feedTitle')
    if (isHtmlStringEmpty(feedContent)) invalidFields.push('feedContent')
  }

  if (sendSms) {
    if (!smsContent) invalidFields.push('smsContent')
  }

  return invalidFields
}

const isStepTemplateValid = ({
  workingCopy,
  nonRequiredFields = [],
  postTriggerNames = [],
  isStepTemplate = false,
} : isStepTemplateValidProps) => {
  if (!workingCopy) return { isValid: false, invalidFields: [] }

  const invalidFields: string[] = []

  const {
    fromCorrespondentAttributes = {},
    toCorrespondentAttributes = {},
    targetingRules = {},
  } = workingCopy

  if (!isCorrespondentValid(fromCorrespondentAttributes)) {
    invalidFields.push('fromCorrespondent')
  }

  if (!isCorrespondentValid(toCorrespondentAttributes)) {
    invalidFields.push('toCorrespondent')
  }

  const allNonRequiredFields = [...nonRequiredFields, ...NON_REQUIRED_STEP_TEMPLATE_ATTRIBUTES]

  const missingFields = Object.entries(_.omit(workingCopy, allNonRequiredFields))
    .filter(([_, value]) => !value)
    .map(([key]) => key)

  if (missingFields.length > 0) {
    invalidFields.push(...missingFields)
  }

  const audienceValid = !isStepTemplate || isAudienceValid({ targetingRules })

  if (!audienceValid) {
    invalidFields.push('audience')
  }

  const triggerValid = !isStepTemplate || isTriggerValid(workingCopy, postTriggerNames)

  if (!triggerValid) {
    invalidFields.push('trigger')
  }

  invalidFields.push(...areChannelsValid(workingCopy))

  return {
    isValid: _.isEmpty(invalidFields),
    invalidFields: _.uniq(invalidFields),
  }
}

export default isStepTemplateValid
