import { createSlice } from '@reduxjs/toolkit'
import entitySlice from 'redux/slices/entities'
import build from 'redux-object'

import { i18nPath } from 'utils/i18nHelpers'
import { showToastMessage } from 'redux/slices/toasts'
import appSignal from 'services/appSignal'
import API from 'services/api'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.admin.includes.audience_selector')

export const initialState = {
  userIds: [],
  meta: {
    isLoadingEstimatedAudience: false,
  },
}

const audienceSlice = createSlice({
  name: 'audiences',
  initialState,
  reducers: {
    isLoadingEstimatedAudience(state, action) {
      state.meta.isLoadingEstimatedAudience = action.payload
    },
    setUserIds(state, action) {
      state.userIds = action.payload
    },
    clear(state) {
      state.userIds = []
      state.meta.isLoadingEstimatedAudience = false
    },
  },
})

const asyncActions = {
  fetchEstimatedAudience: ({ targetingRules, moduleName }) => async (dispatch) => {
    dispatch(audienceSlice.actions.isLoadingEstimatedAudience(true))

    try {
      const data = normalizeTargetingRules(targetingRules)
      const response = await API.audiences.fetchEstimatedAudience({ ...data, moduleName })
      const userIds = response.data.audienceUserIds
      dispatch(audienceSlice.actions.setUserIds(userIds))

      if (userIds.length > 0) {
        const userResponse = await API.users.simpleFetchAll(userIds)
        dispatch(entitySlice.actions.add({ data: userResponse.data }))
      }
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(showToastMessage({ message: I18N('fetch_audience_estimate_error'), type: 'warn' }))
    } finally {
      dispatch(audienceSlice.actions.isLoadingEstimatedAudience(false))
    }
  },

  fetchJourneysEstimatedAudience: ({
    targetingRules,
    journeyBlueprintType,
    includeHiddenUsers = false,
    daysAfterMilestone = 0,
    withoutJourney = false,
    journeyBlueprintId = undefined,
  }) => async (dispatch) => {
    dispatch(audienceSlice.actions.isLoadingEstimatedAudience(true))

    try {
      const data = normalizeTargetingRules(targetingRules)
      const response = await API.audiences.fetchJourneysEstimatedAudience({
        ...data, journeyBlueprintType, daysAfterMilestone, withoutJourney, journeyBlueprintId,
      })
      const userIds = response.data.audienceUserIds
      dispatch(audienceSlice.actions.setUserIds(userIds))

      if (userIds.length > 0) {
        const params = { includeHiddenUsers }

        const userResponse = await API.users.simpleFetchAll(userIds, params)
        dispatch(entitySlice.actions.add({ data: userResponse.data }))
      }
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(showToastMessage({ message: I18N('fetch_audience_estimate_error'), type: 'warn' }))
    } finally {
      dispatch(audienceSlice.actions.isLoadingEstimatedAudience(false))
    }
  },

  fetchGroupPostEstimatedAudience: ({
    targetingRules,
    groupId,
  }) => async (dispatch) => {
    dispatch(audienceSlice.actions.isLoadingEstimatedAudience(true))

    try {
      const data = normalizeTargetingRules(targetingRules)
      const response = await API.audiences.fetchGroupPostEstimatedAudience({ ...data, groupId })
      const userIds = response.data.audienceUserIds
      dispatch(audienceSlice.actions.setUserIds(userIds))

      if (userIds.length > 0) {
        const userResponse = await API.users.simpleFetchAll(userIds)
        dispatch(entitySlice.actions.add({ data: userResponse.data }))
      }
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(showToastMessage({ message: I18N('fetch_audience_estimate_error'), type: 'warn' }))
    } finally {
      dispatch(audienceSlice.actions.isLoadingEstimatedAudience(false))
    }
  },
}

const selectors = {
  getMetaData: () => state => state.audiences.meta,
  getUsers: () => state => state.audiences.userIds.map(id => build(state.entities, 'simpleUser', id)).filter(Boolean),
}

export default {
  ...audienceSlice,
  selectors,
  asyncActions,
}
