import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.platform_analytics.ai_answers')

const SlackOrigin = ({ originMetadata }) => (
  <span>
    {originMetadata?.channelName && `#${originMetadata.channelName}`}
    {!originMetadata?.channelName && originMetadata?.channelId && I18NCommon('private_message')}
    {!originMetadata?.channelName && !originMetadata?.channelId && I18NCommon('command')}
  </span>
)

const AiAnswersOrigin = ({ answer }) => {
  const i18nKey = answer.origin
  const icon = answer.origin === 'slack' ? 'slackLogoIcon.svg' : 'searchIcon.svg'

  return (
    <div className='flex items-center gap-2'>
      <CdnSvg src={`/images/${icon}`} className='w-4 h-4' />
      <span className='text-small text-secondary'>
        {i18nKey === 'slack' ? (
          <SlackOrigin originMetadata={answer.originMetadata} />
        ) : (
          <span>{I18N(`origin.${i18nKey}`)}</span>
        )}
      </span>
    </div>
  )
}

export default AiAnswersOrigin
