import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import CardWithTable from 'components/analytics/common/cardWithTable'
import searchAnalyticsSlice from 'redux/slices/analytics/search'
import { i18nPath } from 'utils/i18nHelpers'
import { SearchQueryResultType } from 'types/analytics/search'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import TargetingRulesType from 'types/audience/targetingRules'
import usePathReusingQueryParams from 'components/common/hooks/usePathReusingQueryParams'
import { TAB_HEADER_QUERY_PARAMS } from 'components/analytics/helpers/useTabHeader'

const womanWithComputerPath = '/images/analytics/womanWithComputerIcon.svg'
const womanCelebratingPath = '/images/analytics/womanCelebratingIcon.svg'

interface SearchQueryResultsProps {
  periodStart: string
  periodEnd?: string
  type: 'top' | 'zero'
  targetingRules?: TargetingRulesType
}

const queriesI18N = i18nPath('views.platform_analytics.search.queries')

const SearchQueryResults = ({
  periodStart, periodEnd, type, targetingRules,
}: SearchQueryResultsProps) => {
  const I18N = (path: string) => queriesI18N(`${type}.${path}`)
  const reduxType = type === 'top' ? 'topQueries' : 'queriesWithZeroResults'

  const dispatch = useDispatch()

  const { data, totalPages, totalItems } = useSelector(searchAnalyticsSlice.selectors.getQueries(reduxType))
  const { [reduxType]: { isLoading } } = useSelector(searchAnalyticsSlice.selectors.getMetaData())
  const isDataEmpty = totalItems === 0 && !isLoading

  useEffect(() => {
    const action = type === 'top' ? 'fetchTopQueries' : 'fetchQueriesWithZeroResults'
    dispatch(searchAnalyticsSlice.asyncActions.admin[action](
      {
        periodStart, periodEnd, page: 1, perPage: 10, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, targetingRules])

  const columns = [
    {
      header: queriesI18N('query'),
      accessor: (search: SearchQueryResultType) => <Link to={search.path} className='text-truncate w-100 d-block ml-1' target='_blank'>{search.query}</Link>,
    },
    {
      header: queriesI18N('total_searches'),
      style: { width: '25%' },
      accessor: (search: SearchQueryResultType) => search.count,
    },
  ]

  const path = usePathReusingQueryParams({
    path: '/platform_analytics/search/query_results',
    paramsToReuse: TAB_HEADER_QUERY_PARAMS,
    otherQueryParams: { type },
  })

  if (isDataEmpty) {
    return (
      <EmptyStateCard
        title={I18N('title')}
        text={I18N('empty')}
        iconPath={type === 'top' ? womanWithComputerPath : womanCelebratingPath}
        classname='SearchTables'
      />
    )
  }

  return (
    <CardWithTable
      title={I18N('title')}
      tableColumns={columns}
      tableData={data}
      classname='SearchTables'
      isLoading={isLoading}
      showSeeMoreLink={totalPages > 1}
      seeMorePath={path}
    />
  )
}

export default SearchQueryResults
