import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import ManagedGroupForm from 'components/admin/managed_groups/managedGroupForm'
import managedGroupSlice, { defaultWorkingCopy } from 'redux/slices/managedGroups'
import { Button } from 'components/common/buttons'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isAudienceValid } from 'utils/audience'

const I18N = i18nPath('views.admin.managed_groups')

const CreateManagedGroupPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)
  const { isSaving } = useSelector(managedGroupSlice.selectors.getMetaData())
  const isNameEmpty = _.isEmpty(workingCopy.name)
  const isChannelsEmpty = workingCopy.managedGroupChannels.length === 0

  const isSaveButtonDisabled = isSaving || isNameEmpty || isChannelsEmpty || !isAudienceValid(workingCopy)

  const onSuccess = () => {
    history.push('/admin/managed_groups')
  }

  const onClickCreateManagedGroup = () => {
    dispatch(managedGroupSlice.asyncActions.admin.createManagedGroup(workingCopy, onSuccess))
  }

  return (
    <div className='CreateManagedGroupPage'>
      <header className='AdminHeader d-flex flex-row justify-content-between'>
        <h3>{I18N('manage_group_membership')}</h3>
      </header>
      <main className='AdminContent'>
        <ManagedGroupForm workingCopy={workingCopy} setWorkingCopy={setWorkingCopy} />
        <div className='d-flex justify-content-end'>
          <Button
            className='mt-3'
            disabled={isSaveButtonDisabled}
            onClick={onClickCreateManagedGroup}
            showLoadingSpinner={isSaving}
          >
            {I18N('save_membership')}
          </Button>
        </div>
      </main>
    </div>
  )
}

export default CreateManagedGroupPage
