export default function buildAncestryTree(modelsArray) {
  const modelsHashById = _.keyBy(_.cloneDeep(modelsArray), 'id')

  const tree = Object.values(modelsHashById)

  // populate parent for each model
  tree.forEach((model) => {
    model.subtreeIds = [model.id]

    const parent = modelsHashById[model.parentId]

    // if no parent found, nothing to populate
    if (!parent) return

    if (!parent.children) {
      parent.children = []
    }

    parent.children.push(model)
    model.parent = modelsHashById[model.parentId]
  })

  // do a second pass to populate the subtree ids for each model (subtree = ids of all descendants, plus self id)
  tree.forEach((model) => {
    let ancestor = model.parent

    while (ancestor?.subtreeIds) {
      ancestor.subtreeIds.push(model.id)
      ancestor = ancestor.parent
    }
  })

  return tree
}
