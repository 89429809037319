import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { defaultActions } from 'redux/slices/utils/commonReducers'
import API from 'services/api'

const initialState = {
  displayAuthors: [],
  meta: {
    isLoading: false,
    isNotFound: false,
    isSaving: false,
    error: null,
  },
}

const slice = createSlice({
  name: 'displayAuthors',
  initialState,
  reducers: {
    ...defaultActions,

    setDisplayAuthors: (state, action) => {
      state.displayAuthors = action.payload
    },
  },
})

const asyncActions = {
  admin: {
    fetchAll: () => async (dispatch) => {
      dispatch(slice.actions.isLoading(true))

      try {
        const result = await API.admin.displayAuthors.fetchAll()

        dispatch(slice.actions.setDisplayAuthors(result.data))
      } catch (error) {
        dispatch(slice.actions.setError(getResponseOrThrow(error)))
      } finally {
        dispatch(slice.actions.isLoading(false))
      }
    },
  },
}

const selectors = {
  getDisplayAuthors: state => state.displayAuthors.displayAuthors,

  getMetaData: state => state.displayAuthors.meta,
}

const displayAuthorSlice = {
  ...slice,
  asyncActions,
  selectors,
}

export default displayAuthorSlice
