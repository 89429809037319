import React from 'react'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import { safePercentage } from 'pages/admin/articles/stats/helpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.article_stats')

const TOP_PERFORMERS_COUNT = 4

const EmailClicks = ({ articleStat, articleId, isGroupPost = false }) => {
  const emailUrlsClickedCount = (articleStat.emailUrlsClickedCount || []).slice(0, TOP_PERFORMERS_COUNT)

  const totalCount = emailUrlsClickedCount.map(({ count }) => count).reduce((a, b) => a + b, 0)
  const totalUniqueCount = emailUrlsClickedCount.map(({ uniqueCount }) => uniqueCount).reduce((a, b) => a + b, 0)


  return (
    <Card cardBodyClassName='EmailClicksCard' className='w-50'>
      <h5 className='mb-0 ml-1'>{I18N('email_click_performance')}</h5>

      <hr />

      {emailUrlsClickedCount.length === 0 && (
        <p>{I18N('no_clicked_urls_message')}</p>
      )}

      {emailUrlsClickedCount.length > 0 && (
        <>
          <div className='row'>
            <div className='col-6 text-secondary text-small'>
              {I18N('url')}
            </div>
            <div className='col-3 text-secondary text-small'>
              {I18N('total')}
            </div>
            <div className='col-3 text-secondary text-small'>
              {I18N('unique')}
            </div>
          </div>

          {emailUrlsClickedCount.map(({ url, count, uniqueCount }) => (
            <div className='row mt-2' key={url}>
              <div className='col-6'>
                <a target='_blank' rel='noopener noreferrer' href={url} className='truncate-text-at-1-lines'>{url}</a>
              </div>
              <div className='col-3'>
                {count} <span className='text-secondary'>({safePercentage(count, totalCount, 1)})</span>
              </div>
              <div className='col-3'>
                {uniqueCount} <span className='text-secondary'>({safePercentage(uniqueCount, totalUniqueCount, 1)})</span>
              </div>
            </div>
          ))}

          <div className='mt-4'>
            <Link to={`${isGroupPost ? '/posts/' : '/admin/articles/'}${articleId}/stats/email_clicks`}>
              {I18N('email_summary.email_clicks_link')}
            </Link>
          </div>
        </>
      )}

    </Card>
  )
}

export default EmailClicks
