import useApi from 'components/common/hooks/useApi'
import { useEffect } from 'react'

import API from 'services/api'

interface UserIntegrations {
  slackInstalled: boolean
  zoomInstalled: boolean
}

const useCurrentUserIntegrations = (): UserIntegrations | undefined => {
  const [fetchIntegrations, {
    data,
    isLoading,
  }] = useApi(API.userIntegrations.fetchIntegrations, {
    globalStateBucket: 'currentUserIntegrations',
  })

  useEffect(() => {
    if (data || isLoading) return

    fetchIntegrations()
  }, [])

  return data
}

export default useCurrentUserIntegrations
