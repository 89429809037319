import React from 'react'
import { useSelector } from 'react-redux'
import { ReduxState } from 'redux/redux'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import BookReview from './sidebar/bookReview'
import Channels from './sidebar/channels'
import Greeting from './sidebar/greeting'
import KnowledgeSources from './sidebar/knowledgeSources'

const Sidebar = ({ knowledgeIngestions }) => {
  const currentUser = useCurrentUser()
  const { companyLogo } = useSelector((state: ReduxState) => state.imageUrls)
  const { permissions: { superAdmin } } = currentUser

  return (
    <div className='AiPlatformDashboardPage__sidebar d-flex flex-column gap-4'>
      <Greeting currentUser={currentUser} companyLogo={companyLogo} />
      <Channels knowledgeIngestions={knowledgeIngestions} />
      {superAdmin && <KnowledgeSources />}
      <BookReview />
    </div>
  )
}

export default Sidebar
