import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import RichTextView from 'components/common/richTextView'
import TaskableDetailTabsSection from 'components/journeys/taskables/taskableDetailTabsSection'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import TaskableTitle from 'components/journeys/taskables/taskableTitle'
import TaskableAssignedByLine from 'components/journeys/taskables/taskableAssignedByLine'
import TaskableDueDateAndForLine from 'components/journeys/taskables/taskableDueDateAndForLine'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import taskSlice from 'redux/slices/journeys/tasks'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import UpdateBuddyAssignmnetTaskStatusButton from 'components/journeys/tasks/updateBuddyAssignmentTaskStatusButton'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import ToggleNotificationsButton from 'components/journeys/tasks/toggleNotificationsButton'

const I18N = i18nPath('views.journeys.taskable_detail')

const BuddyAssignmentDetail = ({
  task,
  className,
  hasTitle = false,
  onComplete = () => {},
  isPreview = false,
}) => {
  const isTaskCompleted = task.completed
  const assignedBuddy = task.selectedOnboardingBuddy

  const [selectedBuddy, setSelectedBuddy] = useState(null)
  const [defaultBuddies, setdefaultBuddies] = useState([])

  const onSuccess = (data) => {
    if (_.isEmpty(defaultBuddies)) {
      setdefaultBuddies(data)
    }
  }

  const [fetchAvailableBuddies] = useApi(API.journey.onboardingBuddies.fetchAll, {
    updateEntitySlice: true,
    onSuccess,
  })

  const currentUser = useCurrentUser()
  const step = task?.step
  const triggerAt = step?.triggerAt
  const forUser = step?.forUser
  const toUser = step?.toUserOrToCorrespondentUser
  const fromUser = step?.fromUserOrFromCorrespondentUser
  const fromCorrespondent = step?.fromCorrespondent
  const { isLoading } = useSelector(taskSlice.selectors.getMetaData())

  useEffect(() => {
    if (!isPreview && !isTaskCompleted) {
      fetchAvailableBuddies({ newHireId: forUser.id, status: 'available' })
    }
  }, [])


  if (isLoading) {
    return <CirclesLoadingIndicator className='p-5' />
  }

  if (_.isEmpty(task)) {
    return null
  }

  const customFetchResults = _.debounce(async (searchQuery, setOptions, setIsLoading) => {
    const results = await fetchAvailableBuddies({ newHireId: forUser.id, status: 'available', q: searchQuery })
    const users = results
    .map(result => ({
      ...result,
      value: result.username,
      label: result.preferredFullName,
      type: 'user',
    }))

    setOptions(users)
    setIsLoading(false)
  }, 500)

  return (
    <div className={classNames('BuddyAssignmentDetail d-flex flex-column', className)}>
      {isPreview && <h1>{I18N('preview.task_preview')}</h1>}
      {hasTitle && <TaskableTitle taskable={task} />}
      <TaskableDueDateAndForLine taskable={task} toUser={toUser} currentUser={currentUser} forUser={forUser} />
      <RichTextView className='instructions-rich-view' record={task} recordType='task' richTextKey='instructions' />
      <TaskableAssignedByLine fromUser={fromUser} triggerAt={triggerAt} fromCorrespondent={fromCorrespondent} />
      <div className='w-100 line-break mt-4' />

      <div className='d-block text-left mt-3'>
        <label className='d-block font-weight-500'>{I18N('buddy_assignment_detail.select_buddy')}</label>
        <p className='text-small text-secondary'>{I18N('buddy_assignment_detail.subtext')}</p>
        {isTaskCompleted ? (
          <div className='bordered'>
            <EmployeeMiniCardLink employee={assignedBuddy} targetBlank padding={2 as any} />
          </div>
        ) : (
          <EmployeeSearch
            className='BuddySelect mb-3 w-50'
            onChange={setSelectedBuddy}
            isClearable
            customFetchResults={customFetchResults}
            placeholder={I18N('buddy_assignment_detail.search_placeholder')}
            defaultOptions={defaultBuddies}
          />
        )}
      </div>

      <TaskableDetailTabsSection
        taskable={task}
        taskableType='introduction'
        isPreview={isPreview}
      />
      <div className='d-flex justify-content-end mt-3'>
        <ToggleNotificationsButton taskable={task} />
        <UpdateBuddyAssignmnetTaskStatusButton
          task={task}
          onComplete={onComplete}
          selectedBuddy={selectedBuddy}
          isPreview={isPreview}
        />
      </div>
    </div>
  )
}

export default BuddyAssignmentDetail
