import React from 'react'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const ScrollToSections = ({
  sections, sectionRefs, sectionsContainerRef, onMouseEnter, onTouchStart,
}) => (
  <div className='ScrollToSections px-4 px-lg-5' ref={sectionsContainerRef} onMouseEnter={onMouseEnter} onTouchStart={onTouchStart}>
    {sections.map(section => (
      <div className='mb-5' key={section.id} ref={sectionRefs[section.id]}>
        <h4 className='d-none d-lg-block'>{section.header}</h4>
        <hr className='d-none d-lg-block w-100 mt-0 mb-4' />
        <div className='mb-5'>{section.isLoading ? <CirclesLoadingIndicator className='mt-4' /> : section.content}</div>
      </div>
    ))}
  </div>
)

export default ScrollToSections
