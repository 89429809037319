import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import PageWorkspaceType from 'types/pageWorkspace'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import EmojiPicker from 'components/common/emojiPicker'

const I18N = i18nPath('views.pages.workspaces.rename_workspace_modal')

interface Props {
  workspace: PageWorkspaceType
  visible: boolean
  toggle: () => void
}

const RenameWorkspaceModal = ({
  visible = false,
  toggle = () => { },
  workspace,
}: Props) => {
  const dispatch = useDispatch()

  const { isSaving } = useSelector(pageWorkspaceSlice.selectors.getMetaData())
  const [workingCopy, setWorkingCopy] = useState<PageWorkspaceType>(workspace)

  const updateWorkingCopy = (changes: Partial<PageWorkspaceType>) => {
    setWorkingCopy({ ...workingCopy, ...changes })
  }

  const updateWorkspace = () => {
    dispatch(pageWorkspaceSlice.asyncActions.update(workingCopy, toggle))
  }

  return (
    <Modal
      className='RenameWorkspaceModal'
      visible={visible}
      size='md'
      modalContentClassName='container d-flex flex-column w-100'
      toggle={toggle}
    >
      <>
        <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
          <h3 className='mb-0'>{I18N('title')}</h3>
          <p className='text-secondary m-0 mt-1'>{I18N('subtitle')}</p>
        </div>
        <label className='mb-2'>{I18N('label')}</label>
        <div className='d-flex align-items-center'>
          <EmojiPicker
            onEmojiSelect={emoji => updateWorkingCopy({ emojiCode: emoji.native })}
            trigger={<EmojiOrPlaceholder selectedEmoji={workingCopy.emojiCode} />}
            triggerClassName='mr-2 h-100'
            disableCustomReactions
          />
          <input
            className='w-100'
            value={workingCopy.title}
            onChange={e => updateWorkingCopy({ title: e.target.value })}
            placeholder={I18N('workspace_title_placeholder')}
          />
        </div>
        <div className='d-flex justify-content-end align-items-center mt-5'>
          <>
            <CancelButton
              onClick={toggle}
            />
            <Button
              onClick={updateWorkspace}
              disabled={isSaving}
              showLoadingSpinner={isSaving}
            >
              {I18NCommon('save_changes')}
            </Button>
          </>
        </div>
      </>
    </Modal>
  )
}

export default RenameWorkspaceModal
