import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useQueryParams from 'components/common/hooks/useQueryParams'

import searchSlice, { PAGE_SIZES } from 'redux/slices/search'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { useHistory, useLocation } from 'react-router-dom'
import CdnSvg from 'components/common/cdnSvg'
import SearchResultsTemplate from './searchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const confluenceLogoPath = '/images/confluence.svg'

const I18N = i18nPath('views.search.search_results.search_results')

const ConfluenceResults = ({ showLabel = true }) => {
  const location = useLocation()
  const history = useHistory()
  const queryParams = useQueryParams()
  const searchResultsFilter = queryParams.filter || 'all'
  const page = parseInt(queryParams.page) || 1
  const selected = searchResultsFilter === 'confluence'
  const pageSize = selected ? PAGE_SIZES.pageFiltered : PAGE_SIZES.pageAll

  const searchLocation = selected ? 'pageFiltered' : 'pageAll'

  const { totalResultsByType, isLoadingConfluence } = useSelector(searchSlice.selectors.getMetaData(searchLocation))
  const confluenceSearchResults = useSelector(searchSlice.selectors.getConfluenceSearchResults(searchLocation))

  const [slicedConfluenceSearchResults, setSlicedConfluenceSearchResults] = useState([])

  const setPage = (page) => {
    const newParams = new URLSearchParams(location.search)
    newParams.set('page', page)
    history.push(`${location.pathname}?${newParams.toString()}`)
  }

  useEffect(() => {
    if (selected) {
      setSlicedConfluenceSearchResults(confluenceSearchResults.slice((page - 1) * pageSize, page * pageSize))
    } else {
      setPage(1)
      setSlicedConfluenceSearchResults(confluenceSearchResults.slice(0, pageSize))
    }
  }, [page, selected, confluenceSearchResults])

  return (
    <SearchResultsTemplate
      showLabel={showLabel}
      label='confluence'
      isLoading={isLoadingConfluence}
      count={totalResultsByType.confluence}
      page={page}
      setPage={setPage}
      selected={selected}
      results={slicedConfluenceSearchResults.map(result => (
        <SearchResultTemplate
          key={result.value}
          displayPath={result.url}
          image={<CdnSvg src={confluenceLogoPath} style={{ height: '29px', width: '29px' }} />}
          pretitle={result.space}
          title={result.title}
          subtitle={I18N('edited', { date: i18nMoment(result.updatedAt).fromNow() })}
          description={result.excerpt}
          result={result}
          descriptionExtraMargin
          openNewTab
        />
      ))}
    />
  )
}

export default ConfluenceResults
