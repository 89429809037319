import React, { useState } from 'react'

import badgeSlice from 'redux/slices/badges'

import CreatedBy from 'components/common/createdBy'
import EditableContent from 'components/common/editable_content'
import { useDispatch } from 'react-redux'
import ImageSelector from 'components/common/imageSelector'
import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import RequestBadgeModal from 'components/badges/modals/requestBadgeModal'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.badges')

const STANDARD = 'standard'

const BadgeDetailCard = ({
  badge,
  canManageBadge,
  showCreatedBy,
  currentUserHasAwardedBadge = false,
}) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()

  const updateBadge = (values = {}) => dispatch(badgeSlice.asyncActions.update({ ...badge, ...values }))
  const [showRequestBadgeModal, setShowRequestBadgeModal] = useState(false)
  const currentUserHasRequestedBadge = badge.currentUserHasRequested

  const isStandardBadge = badge.type === STANDARD
  const showRequestButton = isStandardBadge && !currentUserHasAwardedBadge && currentUser.id !== badge.owner?.id

  return (
    <div className='BadgeDetailCard'>
      {!canManageBadge && <img src={badge.imageUrl} />}
      {canManageBadge && (
        <ImageSelector
          placeholderUrl={badge.imageUrl}
          onImageAdded={image => updateBadge({ image })}
        />
      )}

      <EditableContent
        canEdit={canManageBadge}
        displayElement={<h1>{badge.displayName}</h1>}
        inputElement={(
          <input
            className='Input Name'
          />
        )}
        valueToEdit={badge.displayName}
        saveContent={(value: string) => updateBadge({ name: value, displayName: value })}
      />

      <EditableContent
        canEdit={canManageBadge}
        displayElement={<p className='text-secondary break-word'>{badge.description}</p>}
        inputElement={(
          <textarea
            className='Description'
          />
        )}
        valueToEdit={badge.description}
        saveContent={(value: string) => updateBadge({ description: value })}
      />
      {showCreatedBy && <CreatedBy creator={badge.owner} text={I18N('managed_by')} />}
      {showRequestButton && (
        <Button
          onClick={() => setShowRequestBadgeModal(true)}
          className='mt-3'
          disabled={currentUserHasRequestedBadge}
        >
          {currentUserHasRequestedBadge ? I18N('request.badge_requested') : I18N('request.request_badge')}
        </Button>
      )}

      <RequestBadgeModal
        toggle={() => setShowRequestBadgeModal(false)}
        visible={showRequestBadgeModal}
        badgeId={badge.id}
      />
    </div>
  )
}

export default BadgeDetailCard
