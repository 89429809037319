import React, { useEffect, useRef } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import microsoftLogo from 'assets/microsoftLogo.svg'
import oktaLogo from 'assets/oktaLogo.svg'
import googleSignInButton from 'assets/btn_google_signin_dark_normal_web@2x.png'
import { useLocation } from 'react-router-dom'
import useQueryParams from 'components/common/hooks/useQueryParams'
import LoadingSpinner from 'components/common/loadingSpinner'

const SignInImageButton = ({ formId, buttonName, fullButtonImg }) => (
  <button type='submit'
    form={formId}
    value={buttonName}
    className='p-0 border-0 bg-transparent'
  >
    <img src={fullButtonImg} alt={buttonName} width='200' />
  </button>
)

const CustomSignInButton = ({ formId, buttonName, logo }) => (
  <button
    type='submit'
    form={formId}
    value={buttonName}
    className='btn bg-dark text-white'
  >
    {logo && <img className='mr-1' src={logo} alt='Logo' width='21' />}
    {buttonName}
  </button>
)

const OmniauthLoginButton = ({ authType, companySubdomain, samlProvider }) => {
  const formRef = useRef(null)

  const { autoLogin: autoLoginParam } = useQueryParams()
  const autoLogin = autoLoginParam === 'true'

  const getProvider = (authType, samlProvider) => {
    if (authType === 'saml' && samlProvider) {
      return samlProvider
    }

    return _.capitalize(authType)
  }

  const token = document.querySelector('[name="csrf-token"]').content
  const buttonName = i18nPath('views.authentication')('sign_in_with', { provider: getProvider(authType, samlProvider) })
  const formId = 'omniauth_login_form'

  const getLogo = (authType, samlProvider) => {
    if (authType === 'microsoft') {
      return microsoftLogo
    }

    if (authType === 'saml' && samlProvider === 'Okta') {
      return oktaLogo
    }

    return null
  }

  useEffect(() => {
    if (autoLogin) formRef.current.submit()
  }, [])

  const logo = getLogo(authType, samlProvider)
  const fullButtonImg = (authType === 'google' || (authType === 'saml' && samlProvider === 'Google')) && googleSignInButton

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const redirectUrl = searchParams.get('redirect_url')

  // The action path for this form points to the omniauth middlewares defined on config/initializers/omniauth.rb
  // There are no explicit routes for them, it's a omniauth interception
  // the authType matches the ominiauth provider name. E.g.: provider :microsoft -> '/auth/microsoft'
  return (
    <form
      method='post'
      action={`/auth/${authType}/?origin=${encodeURIComponent(redirectUrl)}`}
      id={formId}
      className='text-center'
      ref={formRef}
    >
      <input name='authenticity_token' type='hidden' value={token} />
      <input name='company' type='hidden' value={companySubdomain} />
      <div style={{ display: autoLogin ? 'none' : 'block' }}>
        {fullButtonImg && <SignInImageButton buttonName={buttonName} formId={formId} fullButtonImg={fullButtonImg} />}
        {!fullButtonImg && <CustomSignInButton buttonName={buttonName} formId={formId} logo={logo} />}
      </div>

      {autoLogin && (
        <LoadingSpinner />
      )}
    </form>
  )
}

export default OmniauthLoginButton
