import React from 'react'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import Overview from 'components/analytics/ai_answers/aiAnswersOverview'
import Answers from 'components/analytics/ai_answers/aiAnswers'
import AiAnswersInsights from 'components/analytics/ai_answers/aiAnswersInsights'
import ConversationsTable from 'components/analytics/ai_answers/conversationsTable'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { I18NCommon } from 'utils/i18nHelpers'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useQueryParams from 'components/common/hooks/useQueryParams'

enum Tab {
  ANSWERS = 'answers',
  CHAT = 'chat',
}

const AnswersTab = ({ periodStart, periodEnd }: { periodStart: string, periodEnd?: string }) => (
  <>
    <Overview
      periodStart={periodStart}
      periodEnd={periodEnd}
    />

    <Answers
      params={{
        period: { periodStart, periodEnd },
      }}
    />
  </>
)

const AiAnswersTab = () => {
  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const {
    answerTab,
  } = useQueryParams()

  const currentCompany = useCurrentCompany()
  const isChatbotEnabled = currentCompany.settings.aiChatbot?.enabled

  const tabs = [
    { text: I18NCommon('answers'), id: Tab.ANSWERS },
    { text: I18NCommon('chat'), id: Tab.CHAT },
  ]

  return (
    <main className='AiAnswersTab'>
      <div className='d-flex grid-span-12 justify-content-end align-items-center'>
        <TabHeader
          periodStart={periodStart}
          setPeriodStart={setPeriodStart}
          periodEnd={periodEnd}
          setPeriodEnd={setPeriodEnd}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
        />
      </div>

      <AiAnswersInsights
        startDate={periodStart}
        endDate={periodEnd}
      />

      {isChatbotEnabled ? (
        <>
          <HistoryChangingPillTabSelector
            paramName='answerTab'
            tabs={tabs}
            pillClasses='text-normal flex-shrink-0'
          />

          {answerTab === Tab.CHAT && (
            <ConversationsTable
              periodStart={periodStart}
              periodEnd={periodEnd}
            />
          )}

          {answerTab === Tab.ANSWERS && (
            <AnswersTab
              periodStart={periodStart}
              periodEnd={periodEnd}
            />
          )}
        </>
      ) : (
        <AnswersTab
          periodStart={periodStart}
          periodEnd={periodEnd}
        />
      )}
    </main>
  )
}

export default AiAnswersTab
