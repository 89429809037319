import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import pageSlice from 'redux/slices/pages'
import DeleteModal from 'components/common/modals/deleteModal'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'

const I18N = i18nPath('views.pages.actions_dropdown')

const PageDeleteModal = ({
  page,
  visible = false,
  onClose = () => {},
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pageSlug, pageId } = useParams()
  const { isDeleting } = useSelector(pageSlice.selectors.getPagesMetadata())
  const { generatePathToPage } = useWorkspaceUrls()

  const afterDelete = () => {
    onClose()

    // If the page we deleted is the current page, we need to redirect to the pages list
    if (pageSlug === page.slug || pageId === page.id) {
      history.push(generatePathToPage())
    }

    return null
  }

  const deletePage = () => {
    dispatch(pageSlice.asyncActions.destroy(page, afterDelete))
  }

  return (
    <DeleteModal
      deleteText={I18N('delete_page')}
      cancelText={I18NCommon('cancel')}
      deleteSecondaryText={I18N('delete_now_text')}
      deleteConfirmText={I18N('confirm_delete')}
      showDeleteModal={visible}
      closeDeleteModal={onClose}
      deleteConfirm={deletePage}
      isDeleting={isDeleting}
    />
  )
}

export default PageDeleteModal
