import React, { useEffect, useState } from 'react'
import DeleteIcon from 'components/icons/deleteIcon'

import classNames from 'classnames'

const UserSkillTag = ({
  text,
  onClick,
  showRemoveButton,
  className,
}) => {
  if (showRemoveButton) {
    return (
      <span className={classNames('UserSkillTag', className)} onClick={onClick}>
        {text}
        <DeleteIcon />
      </span>
    )
  }

  return (
    <span className={classNames('UserSkillTag', className)} onClick={onClick}>
      {text}
    </span>
  )
}

export default UserSkillTag
