import React from 'react'
import classNames from 'classnames'
import { Droppable, Draggable } from 'react-beautiful-dnd'

const DroppableSettingsSection = (props) => {
  const {
    className, title, droppableId, items, itemDraggableId,
  } = props

  return (
    <div>
      <h5>{title}</h5>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            style={{
              boxShadow: 'var(--card-box-shadow)',
              borderRadius: 'var(--square-border-radius)',
              padding: '1.25rem',
            }}
            className={classNames({
              'card Settings-droppableSection': true,
              'Settings-droppableSection--isDraggingOver': snapshot.isDraggingOver,
              [className]: className,
            })}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <Draggable
                key={itemDraggableId ? item[itemDraggableId] : item.id}
                draggableId={itemDraggableId ? item[itemDraggableId] : item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames({ 'Draggable--dragging': snapshot.isDragging })}
                  >
                    {props.itemRenderer(item)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default DroppableSettingsSection
