import useNewArticleWorkingCopy from 'components/common/hooks/useNewArticleWorkingCopy'
import { useMemo } from 'react'
import { buildArticlePayload, buildArticleWorkingCopy } from 'redux/slices/admin/articles'
import isEqualWithSets from 'utils/isEqualWithSets'

const useAreChangesPresentInArticle = (article, workingCopy) => {
  const { newWorkingCopy } = useNewArticleWorkingCopy({ groupId: workingCopy.groupId })

  return useMemo(() => {
    const articleToCheck = article ? buildArticleWorkingCopy(article) : newWorkingCopy

    return !isEqualWithSets(
      buildArticlePayload(articleToCheck), buildArticlePayload(workingCopy)
    )
  }, [workingCopy, article, newWorkingCopy])
}

export default useAreChangesPresentInArticle
