import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import RoundedAvatars from 'components/common/roundedAvatars'

const I18N = i18nPath('views.qna.events')

const ParticipantsText = ({
  participantsCount,
  showZeroCount,
  participationKey,
}) => {
  const participantCountKey = `participant_count_${participantsCount === 1 ? 'singular' : 'plural'}`
  const participantKey = `${participationKey}${participantsCount === 1 ? 'singular' : 'plural'}`

  if (participantsCount === 0 && !showZeroCount) {
    return null
  }

  return (
    <div className='EventParticipants d-inline-flex'>
      <div className='text-small text-sm-normal text-secondary d-flex flex-column d-md-block text-right'>
        <span>{I18N(participantCountKey, { participantsCount })}</span>
        {' '}
        <span>{I18N(participantKey)}</span>
      </div>
    </div>
  )
}

const EventParticipants = ({
  event,
  showZeroCount = false,
  participationKey = 'are_participating_',
}) => {
  const participantsCount = event?.participantsCount || 0
  const anonymousQuestionPresent = event?.anonymousQuestionPresent

  if (!event) {
    return null
  }

  return (
    <div>
      {anonymousQuestionPresent ? (
        <RoundedAvatars
          userCount={participantsCount}
          showAnonymousAvatars
        />
      ) : (
        <RoundedAvatarsWithAsyncUserFetching userIds={event.uniqueParticipantIds} />
      )}
      <ParticipantsText
        participantsCount={participantsCount}
        showZeroCount={showZeroCount}
        participationKey={participationKey}
      />
    </div>
  )
}

export default EventParticipants
