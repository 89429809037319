import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import PageWorkspaceType from 'types/pageWorkspace'
import useCreateDraftPage from 'components/pages/hooks/useCreateDraftPage'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import { usePagesNav } from 'components/pages/pagesNav/context'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import BackIcon from 'components/icons/backIcon'
import CdnSvg from 'components/common/cdnSvg'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import PagesNavComplexTree from './pagesNavComplexTree'
import WorkspaceActionsDropdownMenu from './workspaceActionsDropdownMenu'

const plusIconPath = '/images/plusIcon.svg'

const I18N = i18nPath('views.pages.workspaces')

interface FocusedWorkspaceProps {
  isWorkspaceFocused?: boolean
}

const FocusedWorkspace = ({
  isWorkspaceFocused = false,
}: FocusedWorkspaceProps) => {
  const { workspaceSlug, pageSlug } = useParams()
  // We need to have local state for this component, so that it's not empty while we go back to the unfocused view
  const [pagesIds, setPagesIds] = useState<string[]>([])
  const [focusedWorkspace, setFocusedWorkspace] = useState<PageWorkspaceType | null>(null)
  const { showExpandingState } = usePagesNav()
  const { workspacePages = [], allPages = [], currentWorkspace } = useWorkspaceUrls()
  const createPage = useCreateDraftPage({ pageWorkspaceId: focusedWorkspace?.id })

  const canEditWorkspace = focusedWorkspace?.permittedActions?.edit || false
  const canCreatePages = focusedWorkspace?.permittedActions?.canCreateAndMovePagesInWorkspace || false
  const pages = allPages.filter(page => pagesIds.includes(page.id))

  useEffect(() => {
    if (workspaceSlug) {
      setFocusedWorkspace(currentWorkspace)
      setPagesIds(workspacePages.map(page => page.id))
    }
  }, [workspaceSlug, allPages?.length, currentWorkspace?.updatedAt, currentWorkspace?.showArchivedPagesLink])

  const showExpandingIndicator = () => showExpandingState('workspace', currentWorkspace?.id)
    && _.isEmpty(workspacePages)

  const showNothingHere = () => !showExpandingState('workspace', currentWorkspace?.id)
    && _.isEmpty(workspacePages)

  return (
    <div className={classNames('FocusedWorkspace mb-3', { isWorkspaceFocused })}>
      <header className='WorkspacesNavHeader'>
        <Link to={`/pages/${pageSlug || ''}`} className='d-flex align-items-center'>
          <BackIcon />
          <h6 className='text-smallest text-uppercase text-secondary font-weight-500 mb-0'>
            {I18N('workspaces')}
          </h6>
        </Link>
      </header>
      <div className='WorkspaceHeader d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          <EmojiOrPlaceholder selectedEmoji={focusedWorkspace?.emojiCode} className='mr-2' />
          <span className='WorkspaceName truncate-text-at-1-lines'>{focusedWorkspace?.title}</span>
        </div>
        <div className='WorkspaceOwnerActions'>
          {canCreatePages && (
            <IconButtonWithPopover
              id='create-page-button'
              text={I18N('create_page')}
              icon={<CdnSvg src={plusIconPath} className='PlusIcon d-flex align-items-center' />}
              onClick={createPage}
              className='CreatePageButton'
              popoverClassName='CreatePagePopover p-2'
              textClassName='text-white text-smallest'
            />
          )}
          {canEditWorkspace && (
            <WorkspaceActionsDropdownMenu workspace={focusedWorkspace} />
          )}
        </div>
      </div>

      {showNothingHere() && (
        <div className='text-secondary text-small py-2 px-3 ml-4'>{I18N('nothing_here')}</div>
      )}

      {showExpandingIndicator() && (
        <LoadingIndicator />
      )}

      <PagesNavComplexTree
        pages={pages}
        workspace={focusedWorkspace}
        treeId={`focused-${focusedWorkspace?.id}`}
      />
    </div>
  )
}

export default FocusedWorkspace
