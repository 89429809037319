import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import CardWithTable from 'components/analytics/common/cardWithTable'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import { StalePageType } from 'types/analytics/pages'

const I18N = i18nPath('views.platform_analytics.pages.stale')

const womanCelebratingPath = '/images/analytics/womanCelebratingIcon.svg'

const columns = [
  {
    header: I18N('page'),
    col: 'col-title',
    style: { width: '70%' },
    accessor: (page: StalePageType) => <Link to={`/platform_analytics/pages/${page.id}`} className='text-truncate w-100 d-block'>{page.title}</Link>,
  },
  {
    header: I18N('last_updated'),
    col: 'col-last-updated',
    style: { width: '30%' },
    accessor: (page: StalePageType) => i18nMoment(page.lastPublishedAt).format('MMM D, YYYY'),
  },
]

const StalePages = () => {
  const dispatch = useDispatch()

  const { data, totalItems, totalPages } = useSelector(pageAnalyticsSlice.selectors.getStalePages())

  const { stalePages: { isLoading } } = useSelector(pageAnalyticsSlice.selectors.getMetaData())

  const isStalePagesEmpty = totalItems === 0 && !isLoading

  useEffect(() => {
    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchStalePages({
      perPage: 10,
      page: 1,
    }))
  }, [])

  return (
    <>
      {isStalePagesEmpty ? (
        <EmptyStateCard
          title={I18N('title', { count: 0 })}
          iconPath={womanCelebratingPath}
          text={I18N('empty_state_text')}
          classname='PagesTables'
        />
      ) : (
        <CardWithTable
          title={I18N('title', { count: totalItems })}
          tableColumns={columns}
          tableData={data}
          classname='PagesTables'
          isLoading={isLoading}
          showSeeMoreLink={totalPages > 1}
          seeMorePath='/platform_analytics/pages_analytics/stale_pages'
        />
      )}
    </>
  )
}

export default StalePages
