import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import introductionSlice from 'redux/slices/journeys/introductions'

const I18N = i18nPath('views.journeys.introduction_detail')

const UpdateIntroductionCompletionStatusButton = ({
  introduction,
  onCompleteOrReopen,
  invitations,
  isPreview = false,
}) => {
  const dispatch = useDispatch()
  const { isSaving } = useSelector(introductionSlice.selectors.getMetaData())
  const permittedActions = introduction?.permittedActions
  const isIntroductionCompleted = introduction?.completed
  const hasInvitations = invitations?.length > 0
  const showSubmitButton = permittedActions?.complete && !isIntroductionCompleted
  const showReopenButton = permittedActions?.reopen && isIntroductionCompleted

  const onCompleteClick = () => {
    if (isPreview) {
      return
    }

    const filteredInvitations = invitations.filter(invitation => invitation.invitee)

    const introductionInvitationsAttributes = _.map(filteredInvitations, invitation => ({
      id: invitation.id,
      introductionId: invitation.introductionId,
      inviteeId: invitation.invitee.id,
    }))

    // eslint-disable-next-line max-len
    dispatch(introductionSlice.asyncActions.completeIntroduction(introduction.id, introductionInvitationsAttributes, onCompleteOrReopen))
  }

  const onReopenClick = () => {
    dispatch(introductionSlice.asyncActions.reopenIntroduction(introduction.id, onCompleteOrReopen))
  }

  if (showSubmitButton) {
    return (
      <Button
        disabled={isSaving || !hasInvitations}
        onClick={onCompleteClick}
      >
        {I18N('submit')}
      </Button>
    )
  }

  if (showReopenButton) {
    return (
      <Button disabled={isSaving} onClick={onReopenClick}>
        {I18N('reopen_task')}
      </Button>
    )
  }

  return null
}

export default UpdateIntroductionCompletionStatusButton
