import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import userSkillSlice from 'redux/slices/userSkills'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'

const I18N = i18nPath('views.admin.user_skills')

const UserSkillListPage = () => {
  const dispatch = useDispatch()
  const [userSkillSearchInput, setUserSkillSearchInput] = useState('')

  const userSkills = useSelector(userSkillSlice.selectors.admin.getFilteredSkills())
  const { queryParams, isLoading } = useSelector(userSkillSlice.selectors.getMetaData())

  const columns = [
    {
      header: I18N('list.name'),
      col: 'col-name',
      id: 'translations',
      style: { width: '65%' },
      accessor: d => <Link to={`/admin/user_skills/${d.id}`}>{d.name}</Link>,
    },
    {
      header: I18N('list.type'),
      col: 'col-type',
      id: 'user_skill_type_id',
      style: { width: '35%' },
      accessor: d => d.userSkillType.name,
    },
  ]

  useEffect(() => {
    dispatch(userSkillSlice.asyncActions.admin.listAll(queryParams))

    return () => dispatch(userSkillSlice.actions.resetUserSkillIds())
  }, [])

  const handleUserSkillSearch = (ev) => {
    const { keyCode } = ev
    const { value } = ev.target

    // Ignore enter key
    if (keyCode === 13) return

    // Prevent triggering the search if there are less than 3 chars
    // But if there is no text at all we need to bring all results
    if (value.length < 3 && value.length !== 0) return

    dispatch(userSkillSlice.asyncActions.admin.listAll({ ...queryParams, q: value, page: 1 }))
  }

  const handlePaginationClick = (params) => {
    const filter = { ...queryParams, ...params }
    dispatch(userSkillSlice.asyncActions.admin.listAll(filter))
  }

  const handleSortChange = (sortParams) => {
    const filter = { ...queryParams, ...sortParams }
    dispatch(userSkillSlice.asyncActions.admin.listAll(filter))
  }

  const {
    page, totalPages, total, perPage,
  } = queryParams

  return (
    <div className='UserSkillListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3>{I18N('user_skills')}</h3>

        <div className='AdminButtonContainer'>
          <Link className='ml-2' to='/admin/user_skill_types'>
            <Button>{I18N('view_user_skill_types')}</Button>
          </Link>
          <Link className='ml-2' to='/admin/user_skills/new'>
            <Button>{I18N('create_user_skill')}</Button>
          </Link>
        </div>
      </header>

      <main className='AdminContent'>
        <div className='row m-2 d-flex flex-column'>
          <input
            type='text'
            placeholder={I18N('search_by_user_skill_name')}
            className='my-4 w-50'
            onKeyUp={handleUserSkillSearch}
            onChange={ev => setUserSkillSearchInput(ev.target.value)}
            value={userSkillSearchInput}
          />
        </div>
        <div className='row m-2 d-flex flex-column'>
          <TableLoadingContainer isLoading={isLoading}>
            <SmartTable
              className='white-bg-table'
              data={userSkills}
              sortKey='created_at'
              sortedAsc={false}
              showPagination={page !== null && totalPages !== null}
              columns={columns}
              page={page}
              pages={totalPages}
              totalCount={total}
              perPage={perPage}
              onPaginationClick={handlePaginationClick}
              onSortChange={handleSortChange}
            />
          </TableLoadingContainer>
        </div>
      </main>
    </div>
  )
}

export default UserSkillListPage
