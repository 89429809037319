import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Popover } from 'reactstrap'

import { i18nPath } from 'utils/i18nHelpers'
import notificationSlice from 'redux/slices/notifications'

import BellActive from 'components/icons/bellActive'
import NotificationListItem from 'components/notifications/notificationListItem'

const I18N = i18nPath('views.notifications')

const NotificationBell = (props) => {
  const toolTipDOMRef = useRef()
  const bellContainerDOMRef = useRef()
  const alertCountDOMRef = useRef()

  const [showNotifications, setShowNotifications] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const { notifications } = useSelector(notificationSlice.selectors.getNotifications())
  const unreadCount = useSelector(notificationSlice.selectors.getUnreadCount())

  const handleClickOutside = (e) => {
    const tooltipRef = toolTipDOMRef.current
    const bellRef = bellContainerDOMRef.current

    const isOutsideClick = tooltipRef && bellRef && !tooltipRef.contains(e.target) && !bellRef.contains(e.target)

    if (alertCountDOMRef.current) {
      alertCountDOMRef.current = null
    } else if (isOutsideClick) {
      setShowNotifications(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    dispatch(notificationSlice.asyncActions.fetchNotifications())

    return () => document.removeEventListener('click', handleClickOutside)
  }, [])

  const handleNotificationClick = () => {
    if (!notifications || notifications.length === 0) {
      return
    }

    setShowNotifications(false)
  }

  const handleBellClick = () => {
    if (!showNotifications) {
      dispatch(notificationSlice.asyncActions.markAsViewed(notifications))
    }

    setShowNotifications(!showNotifications)
  }

  const renderNotifications = () => {
    if (!notifications || notifications.length === 0) {
      return <div className='text-center p-3'>{I18N('no_notifications')}</div>
    }

    return notifications.map(notification => (
      <NotificationListItem
        key={`notification-${notification.id}`}
        notification={notification}
        history={history}
      />
    ))
  }

  return (
    <div className='Navbar-navItemContainer' onClick={handleBellClick} ref={bellContainerDOMRef}>
      <div className='Notifications-bell' id='notification-bell'>
        <div className='Notifications-container' ref={alertCountDOMRef}>
          {unreadCount > 0 && <div className='Notifications-count'>{unreadCount}</div>}
        </div>
        <BellActive className='NotificationBellIcon' />
      </div>

      <Popover
        placement='bottom'
        isOpen={showNotifications}
        target='notification-bell'
        className='Notifications-popover'
      >
        <div ref={toolTipDOMRef}>
          <h5 className='pl-3 py-3 m-0'>{I18N('header')}</h5>
          <div className='Notifications-list' onClick={handleNotificationClick}>
            {renderNotifications()}
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default NotificationBell
