import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import SearchResultOption from './searchResultOption'

const I18N = i18nPath('views.search.global_search')

const getCountLabel = (activeMembersCount) => {
  const memberCount = activeMembersCount || 0
  if (memberCount === 0) return null

  const countLabelKey = `member_count_${memberCount === 1 ? 'singular' : 'plural'}`

  return I18N(countLabelKey, { memberCount })
}

const TeamOption = ({ option }) => {
  const { group, highlights } = option

  // groupTypeName includes highlights, groupType.name doesn't
  const {
    activeMembersCount, groupType, description, name, groupTypeName,
  } = { ...group, ...highlights }

  return (
    <SearchResultOption
      className='TeamOption'
      image={(
        <img
          alt={group.name}
          className='SearchResultOptionImg bordered-thumbnail mr-2'
          style={{ height: '22px', width: '22px' }}
          src={group.primaryPhotoThumbnailUrl}
        />
      )}
      title={name}
      secondaryTexts={[
        groupTypeName || groupType.name,
        getCountLabel(activeMembersCount),
        description,
      ]}
    />
  )
}

export default TeamOption
