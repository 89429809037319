import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import groupMembershipSlice, { defaultQueryParams } from 'redux/slices/groupMemberships'

import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import InfoTooltip from 'components/common/infoTooltip'
import CSVAudienceModal from 'components/common/csv/csvAudienceModal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.groups.group_page.batch_create')

const BatchCreateMembersButton = ({
  group,
  closeManageMembers,
}) => {
  const dispatch = useDispatch()
  const [isCsvImportModalOpen, setIsCsvImportModalOpen] = useState(false)
  const [batchCreateGroupMemberships] = useApi(API.admin.groupMemberships.batchCreate, {
    toastSuccessMessage: I18N('batch_create_success_message'),
    onSuccess: () => {
      closeManageMembers()
      // let's give a timeout since the backend runs a background job
      setTimeout(() => {
        dispatch(groupMembershipSlice.asyncActions.fetchAll(group.id, defaultQueryParams as any))
      }, 3000)
    },
  })

  const onCreate = (users) => {
    dispatch(groupMembershipSlice.actions.clear())
    dispatch(groupMembershipSlice.actions.setIsLoading(true))
    batchCreateGroupMemberships(group, users.map(u => u.id))
  }

  return (
    <>
      <Button
        className='BatchCreateMembersButton'
        variant='link'
        onClick={() => setIsCsvImportModalOpen(true)}
      >
        {I18N('upload_a_list')}
        <InfoTooltip
          className='link-color'
          text={I18N('csv_description_text')}
          tooltipClassName='mt-2'
          placement='bottom'
        />
      </Button>
      {isCsvImportModalOpen && (
        <CSVAudienceModal
          isOpen={isCsvImportModalOpen}
          toggle={() => setIsCsvImportModalOpen(false)}
          onChange={onCreate}
          description={I18N('csv_description_text')}
        />
      )}
    </>
  )
}

export default BatchCreateMembersButton
