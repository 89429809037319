import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { Link } from 'react-router-dom'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.admin.article_template_list.templates_empty_state')
const templatesEmptyStatePath = '/images/templatesEmptyState.svg'

interface Props {
  type: 'not_found' | 'no_templates_created'
}

const EmptyStateTable = ({ type }: Props) => (
  <div className='ArticleTemplatesEmptyState white-bg-table d-flex justify-content-center align-items-center'>
    <div>
      <CdnSvg src={templatesEmptyStatePath} />
    </div>

    <div className='ml-5'>
      <h3 className='mb-4'>{I18N(`${type}.title`)}</h3>

      <Link to='/admin/articles/templates/new'>
        <Button
          variant='secondary'
        >
          {I18N('create_new_template')}
        </Button>
      </Link>

      <p className='mt-4'>{I18N('steps.title')}</p>

      <ol>
        <li className='mb-3'>
          {I18N('steps.step_1')}
        </li>
        <li className='mb-3'>
          {I18N('steps.step_2')}
        </li>
        <li className='mb-3'>
          {I18N('steps.step_3')}
        </li>
      </ol>
    </div>
  </div>
)

export default EmptyStateTable
