import React from 'react'

import PillTabSelector from 'components/common/pillTabSelector'
import { i18nPath } from 'utils/i18nHelpers'

export const GROUP_BY_DAY = 'day'
export const GROUP_BY_WEEK = 'week'
export const GROUP_BY_MONTH = 'month'

const I18N = i18nPath('views.platform_analytics.common.group_by')

interface GroupByTabsProps {
  onChange: (groupBy: string) => void
}

const GroupByTabs = ({ onChange }: GroupByTabsProps) => {
  const tabs = [
    { text: I18N('daily'), onClick: () => onChange(GROUP_BY_DAY) },
    { text: I18N('weekly'), onClick: () => onChange(GROUP_BY_WEEK) },
    { text: I18N('monthly'), onClick: () => onChange(GROUP_BY_MONTH) },
  ]

  return (
    <PillTabSelector
      tabs={tabs}
      pillClasses='text-normal'
      className='GroupByTabs'
    />
  )
}

export default GroupByTabs
