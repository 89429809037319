import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import useQueryParams from 'components/common/hooks/useQueryParams'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'

import { i18nPath } from 'utils/i18nHelpers'
import ArticlesTable from 'components/admin/articles/articlesTable'
import ArticleStatsTable from 'components/admin/articles/articleStatsTable'

const I18N = i18nPath('views.groups.my_posts_list')

const TABS = {
  Articles: 'my_posts',
  Analytics: 'analytics',
}

const MyPostsListPage = () => {
  const { selectedTab } = useQueryParams()
  const isArticlesTab = !selectedTab || selectedTab === TABS.Articles
  const isAnalyticsTab = selectedTab === TABS.Analytics

  return (
    <main className='MyPostsListPage'>
      <header className='MyPostsHeader'>
        <h2>{I18N('my_posts')}</h2>
        <Link to='/posts/new'>
          <Button>
            {I18N('create_new_post')}
          </Button>
        </Link>
      </header>


      <HistoryChangingPillTabSelector
        pillClasses='text-normal'
        className='mb-3 grid-span-12'
        tabs={[
          {
            id: TABS.Articles,
            text: I18N('my_posts'),
          },
          {
            id: TABS.Analytics,
            text: I18N('analytics'),
          },
        ]}
      />

      {isArticlesTab && (
        <ArticlesTable myPosts />
      )}
      {isAnalyticsTab && (
        <ArticleStatsTable myPosts />
      )}
    </main>
  )
}

export default MyPostsListPage
