import React from 'react'
import classNames from 'classnames'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

import TruncatedContent from 'components/common/truncatedContent'
import SuggestedIcon from 'components/common/suggestedIcon'

const I18N = i18nPath('views.search.search_results')

const GolinkMiniCard = ({
  golink, showRemoveButton, className, onClick,
}) => (
  <div key={`golink_${golink.id}`} className={classNames('GolinkMiniCard', className)}>
    <strong className='GolinkMiniCard-text'>
      <SuggestedIcon name={golink.displayName} url={golink.url} iconUrl={golink.iconUrl} height={24} width={24} />
      <a
        className='text-primary-link'
        href={`/go/${golink.name}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        go/
        {golink.name}
      </a>
    </strong>

    {showRemoveButton && (
      <div className='remove-golink rounded-circle' data-testid='remove-golink' onClick={onClick}>
        <span>x</span>
      </div>
    )}

    {!_.isEmpty(golink.url) && (
      <span className='mr-1 text-secondary d-flex'><TruncatedContent>{golink.url}</TruncatedContent></span>
    )}
  </div>
)

export default GolinkMiniCard
