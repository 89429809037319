import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { ButtonNarrow } from 'components/common/buttons'
import Modal from 'components/common/modal'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import GearIcon from 'components/icons/gearIcon'
import { ONBOARDING, OFFBOARDING, CUSTOM } from 'utils/journeys/journeyBlueprintTypes'
import ReactSelect from 'components/common/react_select'
import ToggleGroup from 'components/common/toggleGroup'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.create_new_journey_blueprint_modal')

const CREATE_FROM_SCRATCH = 'create_from_scratch'

const createFromScratchOption = { label: I18N(CREATE_FROM_SCRATCH), value: CREATE_FROM_SCRATCH }

const JourneyBlueprintTypeSelector = ({
  journeyBlueprintType,
  setJourneyBlueprintType,
}) => {
  const currentCompany = useCurrentCompany()
  const journeySettings = currentCompany.settings?.journeys
  const offboardingEnabled = journeySettings?.offboarding?.enabled
  const customJourneysEnabled = journeySettings?.customJourneys?.enabled

  const options = [
    { name: ONBOARDING, icon: <CdnSvg src='/images/addPersonIcon.svg' /> },
    offboardingEnabled && { name: OFFBOARDING, icon: <CdnSvg src='/images/removePersonIcon.svg' /> },
    customJourneysEnabled && { name: CUSTOM, icon: <GearIcon /> },
  ].filter(Boolean)

  if (options.length === 1) {
    return null
  }

  return (
    <>
      <span className='d-inline-block text-normal font-weight-500 mt-3 mb-2'>{I18N('journey_type_label')}</span>
      <ToggleGroup
        onChange={setJourneyBlueprintType}
        selectedValue={journeyBlueprintType}
        toggles={options.map(option => ({
          value: option.name,
          icon: option.icon,
          title: I18N(`journey_types.${option.name}`),
        }))}
      />
    </>
  )
}

const TemplateSelector = ({
  journeyBlueprintType,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const availableTemplates = useSelector(journeyBlueprintSlice.selectors.getAvailableTemplates())
  const templateOptionsForType = availableTemplates[journeyBlueprintType] || []

  if (templateOptionsForType.length === 0) {
    return null
  }

  // eslint-disable-next-line max-len
  const options = [createFromScratchOption].concat(templateOptionsForType.map(template => ({ label: template.name, value: template.templateId })))

  return (
    <>
      <span className='d-inline-block text-normal font-weight-500 mt-3 mb-2'>{I18N('select_a_template_or_create_your_own')}</span>
      <ReactSelect
        value={options.find(option => option.value === selectedTemplate)}
        options={options}
        onChange={selectedValue => setSelectedTemplate(selectedValue.value)}
      />
    </>
  )
}


const CreateJourneyBlueprintModal = ({
  className,
  visible,
  toggle,
  startingType,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [journeyBlueprintName, setJourneyBlueprintName] = useState('')
  const [journeyBlueprintType, setJourneyBlueprintType] = useState(startingType)
  const [selectedTemplate, setSelectedTemplate] = useState(CREATE_FROM_SCRATCH)
  const { isSaving } = useSelector(journeyBlueprintSlice.selectors.getMetaData())

  const isCreateFromScratchSelected = selectedTemplate === CREATE_FROM_SCRATCH
  const disableCreateButton = isCreateFromScratchSelected && !journeyBlueprintName

  const resetValues = () => {
    setJourneyBlueprintName('')
    setSelectedTemplate(CREATE_FROM_SCRATCH)
  }

  const closeModal = () => {
    resetValues()
    toggle()
  }

  const onSuccess = (id) => {
    resetValues()
    history.push(`/admin/journeys/${id}`)
  }

  const onClickCreate = () => {
    const params = {
      name: journeyBlueprintName,
      type: journeyBlueprintType,
      howUsersAreAdded: 'manually',
      targetingRules: TARGET_ENTIRE_COMPANY,
      automaticallyAddUsersDaysAfterMilestone: journeyBlueprintType === 'offboarding' ? 14 : null,
    }

    if (!isCreateFromScratchSelected) {
      params.templateId = selectedTemplate
    }

    dispatch(journeyBlueprintSlice.asyncActions.admin.create(params, onSuccess))
  }

  useEffect(() => {
    dispatch(journeyBlueprintSlice.asyncActions.admin.fetchJourneyBlueprintAvailableTemplates())
  }, [])


  useEffect(() => {
    setSelectedTemplate(CREATE_FROM_SCRATCH)
  }, [journeyBlueprintType])


  return (
    <Modal
      size='lg'
      className={classNames('CreateJourneyBlueprintModal', className)}
      visible={visible}
      toggle={closeModal}
    >
      <h4 className='mb-3'>{I18N('title')}</h4>
      <JourneyBlueprintTypeSelector
        journeyBlueprintType={journeyBlueprintType}
        setJourneyBlueprintType={setJourneyBlueprintType}
      />
      <TemplateSelector
        journeyBlueprintType={journeyBlueprintType}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
      {isCreateFromScratchSelected && (
        <>
          <span className='d-inline-block text-normal font-weight-500 mt-3 mb-2'>{I18N('name_label')}</span>
          <input
            name='journeyBlueprintName'
            value={journeyBlueprintName}
            className='form-control'
            onChange={e => setJourneyBlueprintName(e.target.value)}
            placeholder={I18N('placeholder')}
          />
        </>
      )}
      <div className='d-flex justify-content-end align-items-center'>
        <div role='button' tabIndex={0} onClick={closeModal} className='mr-3 text-muted-dark'>
          {I18NCommon('cancel')}
        </div>
        <ButtonNarrow
          className='my-4'
          disabled={disableCreateButton}
          onClick={onClickCreate}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('create')}
        </ButtonNarrow>
      </div>
    </Modal>
  )
}

export default CreateJourneyBlueprintModal
