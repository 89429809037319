import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useQueryParams from 'components/common/hooks/useQueryParams'
import searchSlice from 'redux/slices/search'


const NotionFilter = () => {
  const dispatch = useDispatch()
  const { query } = useQueryParams()

  useEffect(() => {
    dispatch(searchSlice.actions.reset({ searchLocation: 'pageFiltered' }))

    dispatch(searchSlice.asyncActions.searchNotion({
      query,
      searchNotion: true,
      searchLocation: 'pageFiltered',
      track: true,
    }))
  }, [query])


  return null
}

export default NotionFilter
