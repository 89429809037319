import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import groupMembershipSlice, { defaultQueryParams } from 'redux/slices/groupMemberships'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { ButtonSmall } from 'components/common/buttons'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import FormCheck from 'components/form_fields/formCheck'
import ReactSelect from 'components/common/react_select'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import BatchCreateMembersButton from 'components/groups/batchCreateMembersButton'

const I18N = i18nPath('views.groups.group_page')

const GroupMembershipRow = ({ user, group, onSuccessAddMember }) => {
  const dispatch = useDispatch()
  const initialMembership = { user, group }
  const [workingCopy, setWorkingCopy] = useState(initialMembership)
  const useLabelsToCategorizeMembers = group.groupType.useLabelsToCategorizeMembers

  const createGroupMembership = async () => {
    await dispatch(groupMembershipSlice.asyncActions.addPerson(
      workingCopy.group,
      workingCopy.user,
      workingCopy.groupTypeLabel?.id,
      workingCopy.lead,
      workingCopy.role
    ))
    onSuccessAddMember()
    dispatch(groupMembershipSlice.actions.clear())
    dispatch(groupMembershipSlice.actions.setQueryParams(defaultQueryParams))
    dispatch(groupMembershipSlice.asyncActions.fetchAll(group.id, defaultQueryParams))
  }

  return (
    <div className='GroupMembershipRow mt-3 d-flex align-items-center flex-wrap'>
      <EmployeeMiniCard employee={user} />
      {useLabelsToCategorizeMembers && (
        <ReactSelect
          id='cy_group_type_label_select'
          value={workingCopy.groupTypeLabel}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          onChange={label => setWorkingCopy({ ...workingCopy, groupTypeLabel: label })}
          isClearable
          options={group.groupType.groupTypeLabels}
        />
      )}
      <FormCheck
        checked={workingCopy.lead}
        onChange={e => setWorkingCopy({ ...workingCopy, lead: e.target.checked })}
        label={I18N('lead')}
      />
      <input
        value={workingCopy.role}
        onChange={e => setWorkingCopy({ ...workingCopy, role: e.target.value })}
        placeholder={I18N('role_placeholder')}
        disabled={workingCopy.lead}
        className='MembershipRole'
      />
      <ButtonSmall
        className='AddButton mr-2'
        onClick={createGroupMembership}
      >
        {I18NCommon('add')}
      </ButtonSmall>
    </div>
  )
}

const ManageMembers = ({
  group,
  closeManageMembers,
}) => {
  const [usersToAdd, setUsersToAdd] = useState([])

  const addUserToList = (user) => {
    if (user?.id) {
      setUsersToAdd([...usersToAdd, user])
    }
  }

  const onSuccessAddMember = (user) => {
    const newUsersToAdd = usersToAdd.filter(u => u.id !== user.id)
    setUsersToAdd(newUsersToAdd)

    if (newUsersToAdd.length === 0) {
      closeManageMembers()
    }
  }

  return (
    <section className='ManageMembers'>
      <div className='SearchOrSelectList'>
        <EmployeeSearch onChange={addUserToList} selectedEmployee={null} />
        <BatchCreateMembersButton group={group} closeManageMembers={closeManageMembers} />
      </div>
      {usersToAdd.map(user => (
        <GroupMembershipRow
          key={`user-${user.id}`}
          user={user}
          group={group}
          onSuccessAddMember={() => onSuccessAddMember(user)}
        />
      ))}
    </section>
  )
}

export default ManageMembers
