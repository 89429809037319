import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import CollapsibleCard from 'components/common/collapsibleCard'
import IntegrationCard from 'components/admin/integrations/integrationCard'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { showToastMessage } from 'redux/slices/toasts'
import AtsSubsection from 'components/admin/integrations/atsSubsection'
import MergedevIntegrationCard from 'components/admin/integrations/mergedevIntegrationCard'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import itAdminSlice from 'redux/slices/itAdmin'
import AdpInstallationComponent from 'components/admin/integrations/adpInstallationComponent'
import BambooHRInstallationComponent from 'components/admin/integrations/bambooHRInstallationComponent'
import CdnSvg from 'components/common/cdnSvg'
import ClearySftpIntegrationCard from 'components/admin/integrations/cleary_sftp/clearySftpIntegrationCard'
import WorkdayInstallationComponent from 'components/admin/integrations/workdayInstallationComponent'

const I18N = i18nPath('views.integrations_page.people_section')

const adpLogoPath = '/images/integrations/adpLogo.svg'
const bamboohrLogoPath = '/images/integrations/bamboohrLogo.svg'
const namelyLogoPath = '/images/integrations/namelyLogo.svg'
const workdayLogoPath = '/images/integrations/workdayLogo.svg'

const HRIS_INTEGRATIONS = [
  {
    id: 'adp',
    name: 'ADP',
    logo: <CdnSvg src={adpLogoPath} />,
    docLink: 'https://support.gocleary.com/hc/en-us/articles/12470883572123-ADP-Integration',
    docTitle: I18N('adp_document'),
    canUninstall: true,
    installationComponent: props => <AdpInstallationComponent {...props} />,
  },
  {
    id: 'bamboohr',
    name: 'BambooHR',
    logo: <CdnSvg src={bamboohrLogoPath} />,
    docLink: 'https://support.gocleary.com/hc/en-us/articles/14861217557275-BambooHR-Integration',
    docTitle: I18N('bamboohr_document'),
    canUninstall: true,
    installationComponent: props => <BambooHRInstallationComponent {...props} />,
  },
  {
    id: 'namely',
    name: 'Namely',
    logo: <CdnSvg src={namelyLogoPath} />,
    installationComponent: props => undefined,
  },
  {
    id: 'workday',
    name: 'Workday',
    logo: <CdnSvg src={workdayLogoPath} />,
    canUninstall: true,
    installationComponent: props => <WorkdayInstallationComponent {...props} />,
  },
]

const PeopleSection = () => {
  const dispatch = useDispatch()

  const { settings } = useCurrentCompany()
  const { leverError } = useQueryParams()

  const { hrisName } = useSelector(itAdminSlice.selectors.getData())

  useEffect(() => {
    if (leverError) {
      dispatch(showToastMessage({ message: I18N('lever_error', { error: leverError }), type: 'error' }))
    }
  }, [leverError])

  const uninstallIntegration = (name) => {
    dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration(name))
  }

  const isOtherInstalled = integrationName => (hrisName && (hrisName !== integrationName))

  return (
    <CollapsibleCard title={I18N('people')} className='mb-3'>
      <section className='PeopleSection'>
        <AtsSubsection />

        <div className='full-width'>{I18N('hris')}</div>
        {HRIS_INTEGRATIONS.map(integration => (
          <IntegrationCard
            key={integration.id}
            id={integration.id}
            logo={integration.logo}
            isInstalled={integration.name === hrisName}
            docLink={integration.docLink}
            docTitle={integration.docTitle}
            onUninstall={integration.canUninstall ? () => uninstallIntegration(integration.id) : null}
            installationComponent={integration.installationComponent({
              isOtherInstalled: isOtherInstalled(integration.name),
            })}
          />
        ))}

        <ClearySftpIntegrationCard />

        {settings?.mergedev?.enabled && (
          <>
            <div className='full-width'>{I18N('additional_ats_hris')}</div>
            <MergedevIntegrationCard isOtherInstalled={isOtherInstalled(settings?.mergedev?.hris?.name)} />
          </>
        )}
      </section>
    </CollapsibleCard>
  )
}

export default PeopleSection
