import React from 'react'
import classNames from 'classnames'
import DeleteIcon from 'components/icons/deleteIcon'

export interface TagProps {
  text: string
  id?: string
  deleteIcon?: boolean
  className?: string
  onClick?: (text: string) => void
}

const Tag = ({
  id, text, onClick, deleteIcon, className = 'mb-1 mr-1',
}: TagProps) => {
  const handleClick = () => {
    if (!onClick) return

    onClick(text)
  }

  return (
    <div className={classNames('Tag-body', className)} onClick={handleClick} data-cy-tagid={id}>
      {deleteIcon && (
        <div className='Tag-delete'>
          <DeleteIcon />
        </div>
      )}
      <div className='Tag-text'>{text}</div>
    </div>
  )
}

export default Tag
