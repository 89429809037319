import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import stepSlice from 'redux/slices/journeys/steps'
import { useDispatch, useSelector } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import { StepType } from 'types/journeys/step'
import classNames from 'classnames'
import ConfirmationModal from 'components/common/modals/confirmationModal'

const I18N = i18nPath('views.admin.journeys.steps.admin_banner')

interface StepTriggerNowLinkProps {
  step: StepType,
  className?: string,
  linkText: string,
  shouldResetData?: boolean,
}

const StepTriggerNowLink = ({
  className = '',
  step,
  linkText,
  shouldResetData = false,
} : StepTriggerNowLinkProps) => {
  const dispatch = useDispatch()
  const id = step?.id
  const [retryRequested, setRetryRequested] = useState(false)
  const [isResendModalOpen, setIsResendModalOpen] = useState(false)

  const { isRetryingTrigger } = useSelector(stepSlice.selectors.getMetaData())

  const onSuccess = () => {
    dispatch(showToastMessage({ message: I18N('triggering_in_a_few_minutes'), type: 'success' }))
    setRetryRequested(true)
  }

  const onClick = () => {
    if (shouldResetData) {
      setIsResendModalOpen(true)
    } else {
      dispatch(stepSlice.asyncActions.admin.triggerNow(id, onSuccess))
    }
  }

  if (retryRequested) {
    return null
  }

  return (
    <>
      <span
        className={classNames('StepTriggerNowLink pointer font-weight-600', className)}
        onClick={onClick}
      >
        {isRetryingTrigger ? '...' : linkText}
      </span>

      <ConfirmationModal
        title={I18N('resend_confirmation_modal.title')}
        description={I18N('resend_confirmation_modal.description')}
        visible={isResendModalOpen}
        toggle={() => setIsResendModalOpen(!isResendModalOpen)}
        onConfirmClick={() => dispatch(stepSlice.asyncActions.admin.triggerNow(id, onSuccess, true))}
        confirmText={I18N('resend_confirmation_modal.confirm')}
      />
    </>
  )
}

export default StepTriggerNowLink
