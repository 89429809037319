import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import UserCelebrationsSettings from 'components/settings/common/userCelebrationsSettings'
import userSlice from 'redux/slices/users'
import userFollowSlice from 'redux/slices/userFollows'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.settings.people_section')

const PeopleSettingsSection = () => {
  const dispatch = useDispatch()
  const { settings } = useCurrentCompany()
  const currentUser = useCurrentUser()
  const parentId = currentUser.parent?.id

  const parent = useSelector(state => parentId && userSlice.selectors.getUser(parentId)(state))
  const directReportIds = currentUser.directReports?.map(direct => direct.id)
  const coworkers = parent?.directReports?.filter(coworker => coworker.id !== currentUser.id)

  const inverseUserFollows = useSelector(userFollowSlice.selectors.getInverseUserFollows())
  const groupedInverseUserFollows = _.groupBy(inverseUserFollows, iuf => (iuf.receiveNotificationsForReports ? 'orgSection' : 'user'))
  const followedOrgSectionUsers = groupedInverseUserFollows.orgSection?.map(iuf => iuf.followedUser) || []
  const followedUsers = groupedInverseUserFollows.user?.map(iuf => iuf.followedUser) || []

  const orgSectionFollowedUserIds = useSelector(userFollowSlice.selectors.getOrgSectionFollowedUserIds())

  const handleFollowedUserChange = ({ updatedUsers, receiveNotificationsForReports }) => {
    const savedUsers = receiveNotificationsForReports ? followedOrgSectionUsers : followedUsers

    const updatedUserIds = updatedUsers.map(user => user.id)
    const savedUserIds = savedUsers.map(user => user.id)

    _.difference(updatedUserIds, savedUserIds).forEach((addedUserId) => {
      dispatch(userFollowSlice.asyncActions.create({ followedUserId: addedUserId, receiveNotificationsForReports }))
    })
    _.difference(savedUserIds, updatedUserIds).forEach((removedUserId) => {
      const removedUserFollow = inverseUserFollows.find(iuf => (
        iuf.followedUser.id === removedUserId && iuf.receiveNotificationsForReports === receiveNotificationsForReports
      ))
      dispatch(userFollowSlice.asyncActions.destroy(removedUserFollow.id))
    })
  }

  useEffect(() => {
    if (parentId) {
      dispatch(userSlice.asyncActions.fetchUser(parentId))
    }
  }, [parentId])

  useEffect(() => {
    dispatch(userFollowSlice.asyncActions.fetchAll())
  }, [])

  useEffect(() => {
    dispatch(userFollowSlice.asyncActions.fetchOrgSectionFollowedUserIds())
  }, [JSON.stringify(followedOrgSectionUsers.map(user => user.id))])

  return (
    <div className='PeopleSettingsSection'>
      <h5>{I18N('receive_notifications_about')}</h5>

      <UserCelebrationsSettings
        id='followed_user'
        userSelector={(
          <EmployeeSearch
            className='cy_followed_user_employee_search'
            selectedEmployees={followedUsers}
            isMulti
            onChange={updatedUsers => handleFollowedUserChange({ updatedUsers, receiveNotificationsForReports: false })}
          />
        )}
      />
      <hr />

      <UserCelebrationsSettings
        id='followed_org_section_user'
        userIds={orgSectionFollowedUserIds}
        userSelector={(
          <EmployeeSearch
            className='mb-3 cy_followed_org_section_user_employee_search'
            selectedEmployees={followedOrgSectionUsers}
            isMulti
            onChange={updatedUsers => handleFollowedUserChange({ updatedUsers, receiveNotificationsForReports: true })}
          />
        )}
      />

      {parentId && (
        <>
          <hr />
          <UserCelebrationsSettings
            id='manager'
            users={[parent]}
          />
        </>
      )}
      {!_.isEmpty(directReportIds) && (
        <>
          <hr />
          <UserCelebrationsSettings
            id='direct'
            userIds={directReportIds}
          />
        </>
      )}
      {!_.isEmpty(coworkers) && (
        <>
          <hr />
          <UserCelebrationsSettings
            id='coworker'
            users={coworkers}
          />
        </>
      )}
    </div>
  )
}

export default PeopleSettingsSection
