import React, { useEffect, useState } from 'react'
import AppModal from 'components/common/modal'
import { I18NCommon, i18nFormat, i18nPath } from 'utils/i18nHelpers'
import Form from 'components/common/aiAnswersWizardModal/form'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import MainContent from 'components/common/aiAnswersWizardModal/mainContent'
import IndicatorsAndControls from 'components/common/aiAnswersWizardModal/indicatorsAndControls'
import Controls from 'components/common/aiAnswersWizardModal/controls'
import { trackAmplitudeEvent } from 'services/tracker'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.ai_answers_wizard_modal')

const NUMBER_OF_STEPS = 3
const SUCCESS_STEP = 4

const AiAnswersWizardModal = () => {
  const [selectedChannels, setSelectedChannels] = useState<any[]>([])
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)

  const isFirstStep = currentStep === 1
  const isLastStep = currentStep === NUMBER_OF_STEPS
  const canSubmit = termsAndConditionsChecked && selectedChannels.length > 0

  const { isLoading: isLoadingSuitableChannels } = useFetch(
    API.chat.aiAssistantSuitableChannels.fetchAll,
    [],
    {
      onSuccess: channels => setSelectedChannels(channels),
    }
  )

  const [createKnowledgeIngestion, { isLoading }] = useApi(
    API.ai.assistant.knowledgeIngestions.create,
    {
      onSuccess: () => setShowSuccess(true),
    }
  )

  const handleNextStep = async () => {
    if (isLastStep) {
      const channels = selectedChannels.map(channel => ({
        id: channel.externalId,
        name: '#' + channel.name,
      }))

      trackAmplitudeEvent('ai_slack_app_wizard_completed', {
        channels: channels.map(channel => channel.name),
      })

      await createKnowledgeIngestion({
        ingestionMetadata: { channels },
        ingestionType: 'slack_channel',
      })
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handlePreviousStep = () => {
    if (isFirstStep) {
      return
    }

    setCurrentStep(currentStep - 1)
  }

  useEffect(() => {
    trackAmplitudeEvent('ai_slack_app_wizard_started')
  }, [])

  return (
    <AppModal
      size='lg'
      closeButton={false}
      title={I18N('title')}
      modalTitleClassName='h4 w-100 text-center'
      modalContentClassName='p-4'
      className='AiAnswersWizardModal'
      visible
    >
      {showSuccess ? (
        <>
          <MainContent
            step={SUCCESS_STEP}
            imageCentered
            description={
              i18nFormat(
                I18N('step_4.card_description'),
                <span className='bold'>{selectedChannels.map(channel => `#${channel.name}`).join(', ')}</span>
              )
            }
          >
            <p className='mt-2 bold'>{I18N('step_4.warning')}</p>
          </MainContent>

          <div className='d-flex justify-content-center mt-5'>
            <div className='AiAnswersWizardModal__container d-flex align-items-center justify-content-between'>
              <div />

              <Controls
                handleConfirm={() => window.location.href = '/'}
                isLoading={isLoading}
                cancelLabel={false}
                confirmLabel={I18N('view_your_dashboard')}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <MainContent step={currentStep} imageCentered={isLastStep} isLastStep={isLastStep}>
            {isLastStep && (
              <Form
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                termsAndConditionsChecked={termsAndConditionsChecked}
                setTermsAndConditionsChecked={setTermsAndConditionsChecked}
                isLoadingSuitableChannels={isLoadingSuitableChannels}
              />
            )}
          </MainContent>

          <IndicatorsAndControls
            currentStep={currentStep}
            numberOfSteps={NUMBER_OF_STEPS}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
            canSubmit={canSubmit}
            isLoading={isLoading}
          />
        </>
      )}
    </AppModal>
  )
}

export default AiAnswersWizardModal
