import React from 'react'
import classNames from 'classnames'
import ShoutoutCompanyValuePopover from 'components/shoutouts/shoutoutCompanyValuePopover'

// Using SVG instead of CdnSVG because companyValue.imageUrl already comes with the CDN url injected by the backend serializer
import SVG from 'react-inlinesvg'

const ShoutoutCompanyValue = ({
  companyValue,
  shoutoutId,
  className,
  isSelected,
  isNonSelectable,
  showPopover = true,
  onClick = () => null,
  popoverTimeout = 300,
}) => {
  const CompanyValue = () => (
    <span className={classNames('ShoutoutCompanyValue mt-1 mr-2 d-inline-flex align-items-center', className, { isSelected, isNonSelectable })} onClick={onClick}>
      <SVG className='ShoutoutCompanyValueImg mr-1 d-inline-flex' src={companyValue.imageUrl} cacheGetRequests />
      <span className='text-smallest text-secondary'>{companyValue.name}</span>
    </span>
  )

  return showPopover ? (
    <ShoutoutCompanyValuePopover
      key={companyValue.id}
      companyValue={companyValue}
      shoutoutId={shoutoutId}
      timeout={popoverTimeout}
    >
      <CompanyValue />
    </ShoutoutCompanyValuePopover>
  ) : (
    <CompanyValue />
  )
}

export default ShoutoutCompanyValue
