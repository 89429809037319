import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'

import companyValueSlice from 'redux/slices/companyValues'
import SortableTable from 'components/common/tables/sortableTable'

const I18N = i18nPath('views.admin.company_value_list')
const I18NCommon = i18nPath('views.common')

const columns = [
  {
    header: I18N('name'),
    accessor: companyValue => (
      <Link to={`/admin/company_values/${companyValue.id}`}>
        <span>{companyValue.name}</span>
      </Link>
    ),
  },
  {
    header: I18N('image'),
    accessor: companyValue => <img style={{ height: '45px' }} src={companyValue.imageUrl} />,
  },
  {
    header: I18N('description'),
    accessor: companyValue => companyValue.description,
  },
  {
    header: I18NCommon('active'),
    accessor: companyValue => I18NCommon(companyValue.active ? 'yes' : 'no'),
  },
  {
    header: I18N('created'),
    accessor: companyValue => i18nMoment(companyValue.createdAt).format('ll'),
  },
]

const CompanyValueList = ({ companyValues, isSaving }) => {
  const dispatch = useDispatch()
  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(companyValueSlice.asyncActions.admin.setOrder({ oldIndex, newIndex }))
  }

  return (
    <div className='container'>
      <div className='row mt-4 mb-4'>
        <div className='white-bg-table col-12'>
          <SortableTable
            data={companyValues}
            columns={columns}
            onSortEnd={onSortEnd}
            isSaving={isSaving}
          />
        </div>
      </div>
    </div>
  )
}

const CompanyValueListPage = () => {
  const dispatch = useDispatch()
  const { isLoading, isSaving } = useSelector(companyValueSlice.selectors.admin.getMetaData())
  const companyValues = useSelector(companyValueSlice.selectors.admin.getCompanyValues())

  useEffect(() => {
    dispatch(companyValueSlice.asyncActions.admin.fetchCompanyValues())

    return () => dispatch(companyValueSlice.actions.clearCompanyValueIds())
  }, [])

  return (
    <div className='CompanyValueListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3>{I18N('company_values')}</h3>
        <div className='AdminButtonContainer'>
          <Link to='/admin/company_values/new'>
            <Button>
              {I18N('create_new_company_value')}
            </Button>
          </Link>
        </div>
      </header>

      <main className='AdminContent'>
        <LoadingContainer isLoading={isLoading}>
          <CompanyValueList companyValues={companyValues} isSaving={isSaving} />
        </LoadingContainer>
      </main>
    </div>
  )
}

export default CompanyValueListPage
