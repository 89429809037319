import { Link } from 'react-router-dom'
import React from 'react'
import { groupPath } from 'utils/routeHelpers'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.groups.group_page')

const Hierarchy = ({ group }) => {
  const { groupType, parent, activeChildren = [] } = group

  if (!parent && !activeChildren.length > 0) return null

  return (
    <section className='GroupHierarchy'>
      {parent && (
        <div className='hierarchy-content mb-3' data-cy='parent-group'>
          <h6>{I18N('parent_label')}</h6>
          <Link to={groupPath(parent, groupType)}>
            {parent.name}
          </Link>
        </div>
      )}

      {activeChildren.length > 0 && (
        <div className='hierarchy-content' data-cy='sub-groups'>
          <h6>{I18N('sub_teams_label')}</h6>
          <div className='sub-teams'>
            {activeChildren.map((childGroup, i) => {
              const isLast = i === activeChildren.length - 1
              return (
                <div key={`childGroup-${childGroup.id}`} className='d-inline-block'>
                  <Link to={groupPath(childGroup, groupType)}>
                    {childGroup.name}
                  </Link>
                  {!isLast && <span>,&nbsp;</span>}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </section>
  )
}

export default Hierarchy
