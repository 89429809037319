import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import React, { ReactElement } from 'react'
import { Placement } from 'react-bootstrap/esm/Overlay'
import { I18NCommon } from 'utils/i18nHelpers'

interface Props {
  title: string
  body: string
  popoverId: string
  placement: Placement
  children: ReactElement
}

const Popover = ({ title, body }) => (
  <>
    <FeatureAnnouncementTrigger />

    <div className='title'>
      {title}
    </div>
    <div className='body'>
      {body}
    </div>
  </>
)

const FeatureAnnouncementBadgeWithPopover = ({
  title, body, placement, popoverId, children,
}: Props) => (
  <HoverAndClickPopover
    target={children}
    id={popoverId}
    popoverClassName='FeatureAnnouncementBadgeWithPopover'
    placement={placement}
  >
    <Popover title={title} body={body} />
  </HoverAndClickPopover>
)

export default FeatureAnnouncementBadgeWithPopover

export const FeatureAnnouncementTrigger = () => (
  <div className='FeatureAnnouncementTrigger'>
    {I18NCommon('new')}
  </div>
)
