import React from 'react'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { i18nPath } from 'utils/i18nHelpers'
import CheckBox from 'components/common/buttons/checkBox'


const I18N = i18nPath('views.admin.other_settings.people_tab')

const GroupsSection = ({
  connect,
  groupDisplayName,
  setGroupDisplayName,
  areGroupsEnabled,
}) => {
  const { pluralGroupDisplayName } = useCurrentCompany()

  return (
    <div>
      <h3 className='mt-5'>{pluralGroupDisplayName}</h3>

      <CheckBox
        id='groupsEnabled'
        label={pluralGroupDisplayName}
        {...connect('groups.enabled')}
      />

      <div>
        <label>{I18N('group_display_name')}</label>
        <input
          value={groupDisplayName}
          onChange={e => setGroupDisplayName(e.target.value)}
          name='groupDisplayName'
          disabled={!areGroupsEnabled}
        />
      </div>
    </div>
  )
}

export default GroupsSection
