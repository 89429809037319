import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import { ButtonSmall } from 'components/common/buttons'

const I18N = i18nPath('views.qna.events')

const IcebreakerTemplateList = ({ selectedType, questionsOfSelectedType, handleTemplateSelection }) => (selectedType ? (
  <div
    className='bordered Icebreaker-template-list mb-3 w-100'
  >
    {questionsOfSelectedType.map((icebreaker, i) => (
      <div
        className='p-2 pointer Icebreaker-question d-flex align-items-center justify-content-between'
        key={`icebreaker-${i}`}
        onClick={() => handleTemplateSelection(icebreaker.attrs.text)}
      >
        {icebreaker.attrs.text}
        <ButtonSmall className='btn-muted Icebreaker-question--select d-inline-block'>{I18N('select')}</ButtonSmall>
      </div>
    ))}
  </div>
) : null)

export default IcebreakerTemplateList
