// This function takes an array of different language codes and return the unique languages in ISO 2 letter code
// Ex: ['en', 'en-US', 'es'] => ['en', 'es']
const convertLanguagesToISOCode = (languageArray) => {
  if (!languageArray) {
    return []
  }

  // remove nul, blank strings and 1 character languages, valid ones will have at least 2 chars
  const validLanguages = languageArray.filter(language => language && (language.length > 1))

  // extracting the first part of the language. en-US -> en
  const convertedLanguages = (validLanguages).map(language => (language).split('-')[0])

  // 'Set' is used for removing duplications
  return [...new Set(convertedLanguages)]
}

// This will return the common languages between the two language array
const checkCommonLanguages = (firstLangArr, secondLangArr) => (
  firstLangArr.filter(language => secondLangArr.includes(language))
)

// Checks if users browser language is different from cleary settings
// Also checks if user's company supports the users browser language
// Returns true if both conditions are met
const recommendChangingLanguage = (browserLanguages, userLanguage, companyLanguages) => {
  // Since languages codes might be different, we normalize everything into 2 letter codes
  const normalizedBrowserLanguages = convertLanguagesToISOCode(browserLanguages)
  const normalizedUserLanguage = convertLanguagesToISOCode([userLanguage])[0]
  const normalizedCompanyLanguages = convertLanguagesToISOCode(companyLanguages)

  return (!normalizedBrowserLanguages.includes(normalizedUserLanguage))
    && checkCommonLanguages(normalizedBrowserLanguages, normalizedCompanyLanguages).length > 0
}

const firstLanguageToSuggest = (firstLangArr, secondLangArr) => {
  const normalizedFirstLangArr = convertLanguagesToISOCode(firstLangArr)
  const normalizedSecondLangArr = convertLanguagesToISOCode(secondLangArr)

  return checkCommonLanguages(normalizedFirstLangArr, normalizedSecondLangArr)[0]
}

// Converts two letter to four letter code
// The Locale library works with the 4 letter code, rather than two
// Ex: 'en' => 'en-EN' || 'en-US' => 'en-US'
const convertLanguageToFourLetterCode = languageCode => (languageCode.length < 3 ? languageCode.concat(`-${languageCode.toUpperCase()}`) : languageCode)

export { recommendChangingLanguage, convertLanguageToFourLetterCode, firstLanguageToSuggest }
