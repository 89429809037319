import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import StatusLabel from 'components/common/status_label/'
import SmartTable from 'components/common/tables/smartTable'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import JourneyBlueprintEmptyList from 'components/admin/journeys/journeyBlueprints/emptyList'
import getJourneyBlueprintStatusColor from 'utils/admin/journeys/statusColors'
import getJourneyBlueprintAudienceText from 'utils/admin/journeys/audience'
import CreateJourneyBlueprintModal from 'components/admin/journeys/modals/createJourneyBlueprintModal'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { ONBOARDING, OFFBOARDING, CUSTOM } from 'utils/journeys/journeyBlueprintTypes'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import SettingsLinkButton from 'components/common/buttons/settingsLinkButton'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'

const I18N = i18nPath('views.admin.journeys.journey_blueprints')


const TableComponent = ({
  onClickCreateNewJourney,
  selectedTab,
}) => {
  const [sortParams, setSortParams] = useState({})
  const [fetchJourneyBlueprints, { data: journeyBlueprints = [], isLoading, paginationData }] = useApi(
    (params = {}) => API.admin.journey.journeyBlueprints.fetchAll({
      includeUsersWithActiveJourneys: true,
      type: selectedTab,
      ...sortParams,
      ...params,
    })
  )

  const { page, totalPages, perPage } = paginationData

  useEffect(() => {
    if (selectedTab) {
      fetchJourneyBlueprints()
    }
  }, [selectedTab, sortParams])

  const isOnboardingTabSelected = selectedTab === ONBOARDING
  const showJourneyBlueprintEmptyList = isOnboardingTabSelected && !isLoading && journeyBlueprints.filter(jb => jb.state === 'active').length === 0

  const columns = [
    {
      header: I18N('list.name'),
      accessor: d => <Link to={`/admin/journeys/${d.id}`}>{d.name}</Link>,
      id: 'name',
    },
    {
      header: I18N(`list.currently_completing.${selectedTab}`),
      accessor: d => (
        <RoundedAvatarsWithAsyncUserFetching
          linkTo='journeys'
          userIds={d.activeJourneysUserIds || []}
          includeInactiveUsers
          includeHiddenUsers
        />
      ),
    },
    {
      header: I18N('list.applies_to'),
      accessor: d => getJourneyBlueprintAudienceText(d),
    },
    {
      header: I18N('list.status'),
      accessor: d => <StatusLabel color={getJourneyBlueprintStatusColor(d.state)}>{_.capitalize(d.state)}</StatusLabel>,
    },
  ]

  if (isLoading) {
    return <CirclesLoadingIndicator className='mt-4' />
  }

  return (
    <>
      {showJourneyBlueprintEmptyList && (
        <JourneyBlueprintEmptyList onClickCreateNewJourney={onClickCreateNewJourney} />
      )}
      {(!_.isEmpty(journeyBlueprints) || !isOnboardingTabSelected) && (
        <SmartTable
          className='white-bg-table JourneyBlueprintsTable'
          data={journeyBlueprints}
          columns={columns}
          page={page}
          pages={totalPages}
          perPage={perPage}
          onPaginationClick={fetchJourneyBlueprints}
          onSortChange={setSortParams}
          sortKey={sortParams.sortBy}
          sortedAsc={sortParams.sortDir === 'asc'}
        />
      )}
    </>
  )
}

const JourneyBlueprintListPage = () => {
  const history = useHistory()
  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const { permissions } = useCurrentUser()
  const showCreateButton = permissions.journeyCreator

  const journeysSettings = settings?.journeys

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)

  const onClickCreateNewJourney = () => setIsCreateModalVisible(true)

  const { selectedTab } = useQueryParams()

  const showJourneySettings = settings.groups.enabled

  return (
    <div className='JourneyBlueprintListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title')}</h3>
        {showCreateButton && <Button onClick={onClickCreateNewJourney}>{I18N('create_new_journey')}</Button>}
      </header>
      <main className='AdminContent'>
        <div className='w-100 d-flex justify-content-between mb-4'>
          <HistoryChangingPillTabSelector
            tabs={[
              {
                text: I18N(`tabs.${ONBOARDING}`),
                id: ONBOARDING,
              },
              {
                text: I18N(`tabs.${OFFBOARDING}`),
                id: OFFBOARDING,
                hidden: !journeysSettings?.offboarding?.enabled,
              },
              {
                text: I18N(`tabs.${CUSTOM}`),
                id: CUSTOM,
                hidden: !journeysSettings?.customJourneys?.enabled,
              },
            ]}
          />
          {showJourneySettings && <SettingsLinkButton onClick={() => history.push('/admin/journeys/settings')} />}
        </div>
        <TableComponent
          onClickCreateNewJourney={onClickCreateNewJourney}
          selectedTab={selectedTab}
        />
      </main>
      {isCreateModalVisible && (
        <CreateJourneyBlueprintModal
          visible={isCreateModalVisible}
          toggle={() => setIsCreateModalVisible(false)}
          startingType={selectedTab}
        />
      )}
    </div>
  )
}

export default JourneyBlueprintListPage
