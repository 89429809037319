import React, { useEffect, useState } from 'react'
import { i18nPath, I18NCommon, i18nMoment } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import { ButtonNarrow } from 'components/common/buttons'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useTrackGreetingCardEvent from 'components/celebrations/hooks/useTrackGreetingCardEvent'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import { CelebrationType } from 'types/celebration'
import { SimpleUserType } from 'types/user'
import moment from 'moment'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.celebrations.send_reminders_modal')

interface Props {
  celebration: CelebrationType
  onClose: () => void
  pendingUsers: SimpleUserType[]
  onInviteSuccess: (inviteeIds: string[]) => void
}

const SendRemindersModal = ({
  celebration, onClose, pendingUsers, onInviteSuccess,
}: Props) => {
  const [selectedEmployees, setSelectedEmployees] = useState(pendingUsers)

  const inviteeIds = selectedEmployees.map(employee => employee.id)
  const trackGreetingCardEvent = useTrackGreetingCardEvent()

  const [invite, { isLoading }] = useApi(API.invitations.batchCreate, {
    onSuccess: () => {
      onInviteSuccess(inviteeIds)
      trackGreetingCardEvent('greeting_card_reminders_sent', celebration, { count: selectedEmployees.length })
      onClose()
    },
    toastSuccessMessage: I18N('reminders_sent'),
    toastErrorMessage: I18N('reminders_send_error'),
  })

  const eventDate = moment(celebration.eventDate)
  const timeKey = eventDate.isSame(moment().add(1, 'day'), 'day') ? 'tomorrow' : 'upcoming'
  const defaultContent = celebration.upcoming ? I18N(`default_content.${celebration.type}.${timeKey}`, { name: celebration.user.preferredName, date: i18nMoment(eventDate).format('MMMM D') }) : ''

  const [content, setContent] = useState(defaultContent)

  const sendReminders = () => {
    invite(celebration, 'celebration', { inviteeIds, force: true, content })
  }

  useEffect(() => {
    setSelectedEmployees(pendingUsers)
  }, [pendingUsers])

  return (
    <Modal
      className='SendRemindersModal'
      size='lg'
      visible
      toggle={onClose}
      modalContentClassName='px-3'
    >
      <h3 className='mb-3'>
        {I18N('send_reminders')}
      </h3>
      <div className='text-secondary mb-3'>
        {I18N('people_have_not_yet_signed', { count: pendingUsers.length, name: celebration.user.preferredName })}
      </div>
      <EmployeeSearch
        className='mb-4'
        isMulti
        selectedEmployees={selectedEmployees}
        onChange={setSelectedEmployees}
      />
      <TiptapEditor
        className='RoundedTiptapEditor w-100 mb-4'
        configuration={SIMPLE_EDITOR_CONFIGURATION}
        toolbarItems={SMALL_TOOLBAR}
        html={content}
        onChange={setContent}
      />
      <div className='d-flex justify-content-end'>
        <ButtonNarrow
          onClick={onClose}
          variant='link'
          className='text-secondary'
        >
          {I18NCommon('cancel')}
        </ButtonNarrow>
        <ButtonNarrow
          disabled={isLoading || selectedEmployees.length === 0 || !content}
          showLoadingSpinner={isLoading}
          onClick={sendReminders}
        >
          {I18N('send_reminders')}
        </ButtonNarrow>
      </div>
    </Modal>
  )
}

export default SendRemindersModal
