import React from 'react'
import CdnSvg from 'components/common/cdnSvg'

const funnelIconPath = '/images/funnelIcon.svg'

const MobileFilterButton = ({ onClick }) => (
  <div className='MobileFilterButton justify-content-center' onClick={onClick}>
    <CdnSvg src={funnelIconPath} />
  </div>
)

export default MobileFilterButton
