import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import DuplicateModal from 'components/common/modals/duplicateModal'
import DeleteModal from 'components/common/modals/deleteModal'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import entitySlice from 'redux/slices/entities'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import { TaskableTemplatePreviewModal } from 'components/journeys/modals/taskableTemplatePreviewButtonAndModal'
import { SendPreviewEmailModal } from 'components/admin/sendPreviewEmailButtonAndModal'
import communicationTemplateSlice from 'redux/slices/journeys/communicationTemplates'

const DELETE_STEP_TEMPLATE_CONFIRMATION_MODAL = 'delete_step_template_confirmation_modal'
const COPY_STEP_TEMPLATE_CONFIRMATION_MODAL = 'copy_step_template_confirmation_modal'
const REVERT_TO_DRAFT_CONFIRMATION_MODAL = 'revert_to_draft_confirmation_modal'
const TASK_PREVIEW_MODAL = 'task_preview_modal'
const SEND_PREVIEW_EMAIL_MODAL = 'send_preview_email_modal'

const TASK_PREVIEWABLE_STEPS = ['task', 'introduction', 'onboarding_buddy_assignment']

const I18N = i18nPath('views.admin.journeys.step_templates')

const StepTemplateActionsDropdown = ({
  stepTemplate,
  workingCopy = null,
  journeyBlueprintType = 'onboarding',
  onRevertToDraft = (state: string) => {},
  isSaving = false,
  redirectOnSuccess = false,
  onlyTableActions = false,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [openModal, setOpenModal] = useState<string | null>(null)
  const { journeyBlueprintId, id } = useParams()
  const stepType = _.snakeCase(stepTemplate.typeText)

  const closeModal = () => setOpenModal(null)
  const isCopyModalOpen = openModal === COPY_STEP_TEMPLATE_CONFIRMATION_MODAL
  const isDeleteModalOpen = openModal === DELETE_STEP_TEMPLATE_CONFIRMATION_MODAL
  const isRevertToDraftModalOpen = openModal === REVERT_TO_DRAFT_CONFIRMATION_MODAL
  const isTaskPreviewModalOpen = openModal === TASK_PREVIEW_MODAL
  const isSendPreviewEmailModalOpen = openModal === SEND_PREVIEW_EMAIL_MODAL

  const isDraft = stepTemplate?.state === 'draft'
  const showTaskPreview = !onlyTableActions && TASK_PREVIEWABLE_STEPS.includes(stepType)
  const showSendPreviewEmail = !onlyTableActions && stepType === 'communication'

  const [copyStepTemplate, { isLoading: isCopying }] = useApi(API.admin.stepTemplates.copy, {
    updateEntitySlice: true,
    onSuccess: closeModal,
    toastSuccessMessage: I18N('copy_modal.success_message'),
  })

  const onDeleteSuccess = () => {
    dispatch(entitySlice.actions.remove({ type: 'stepTemplate', id: stepTemplate?.id }))
    closeModal()

    if (redirectOnSuccess && journeyBlueprintId) {
      history.push(`/admin/journeys/${journeyBlueprintId}/edit`)
    }
  }

  const [deleteStepTemplate, { isLoading: isDeleting }] = useApi(API.admin.stepTemplates.destroy, {
    onSuccess: onDeleteSuccess,
    toastSuccessMessage: I18N('delete_modal.success_message'),
  })

  const onCopyConfirm = async (name: string) => {
    const newStepTemplate = await copyStepTemplate({ id: stepTemplate?.id, name })

    if (redirectOnSuccess && newStepTemplate?.templateId) {
      const newPath = history.location.pathname.replace(`/${id}`, `/${newStepTemplate.templateId}`)
      history.push(newPath)
    }
  }

  const onDeleteConfirm = () => {
    deleteStepTemplate(stepTemplate?.id)
  }

  const onSendPreviewEmail = (userIds, onSuccess) => {
    dispatch(communicationTemplateSlice.asyncActions.admin.sendPreviewEmail(id, userIds, onSuccess))
  }

  const { isSendingPreviewEmail } = useSelector(communicationTemplateSlice.selectors.getMetaData())

  return (
    <div className='StepTemplateActionsDropdown'>
      <DropdownMenuContainer menuType='kebab'>
        <CardDropdownMenuItem
          primaryText={I18N('actions.copy')}
          onClick={() => setOpenModal(COPY_STEP_TEMPLATE_CONFIRMATION_MODAL)}
          width='200px'
          className='CopyAction py-2 px-3'
          icon={<CdnSvg className='mr-2' src='/images/duplicateIcon.svg' />}
        />

        {!isDraft && !onlyTableActions && (
          <CardDropdownMenuItem
            primaryText={I18NCommon('revert_to_draft')}
            onClick={() => setOpenModal(REVERT_TO_DRAFT_CONFIRMATION_MODAL)}
            width='200px'
            className='py-2 px-3'
            icon={<CdnSvg className='mr-2' src='/images/eyeStrikethroughIcon.svg' />}
          />
        )}

        {showTaskPreview && (
          <CardDropdownMenuItem
            primaryText={I18N('actions.task_preview')}
            onClick={() => setOpenModal(TASK_PREVIEW_MODAL)}
            width='200px'
            className='py-2 px-3'
            icon={<CdnSvg className='mr-2' src='/images/eyeOpenIcon.svg' />}
          />
        )}

        {showSendPreviewEmail && (
          <CardDropdownMenuItem
            primaryText={I18N('actions.send_preview_email')}
            onClick={() => setOpenModal(SEND_PREVIEW_EMAIL_MODAL)}
            width='200px'
            className='py-2 px-3'
            icon={<CdnSvg className='mr-2' src='/images/envelopeOutlineIcon.svg' />}
          />
        )}

        <CardDropdownMenuItem
          primaryText={<span className='text-danger'>{I18N('actions.delete')}</span>}
          onClick={() => setOpenModal(DELETE_STEP_TEMPLATE_CONFIRMATION_MODAL)}
          width='200px'
          className='DeleteAction py-2 px-3'
          icon={<CdnSvg className='mr-2' src='/images/trashIcon.svg' />}
        />
      </DropdownMenuContainer>

      {isCopyModalOpen && (
        <DuplicateModal
          className='StepTemplateDuplicateModal'
          title={I18N('copy_modal.title')}
          label={I18N('copy_modal.label')}
          initialName={I18N('copy_modal.initial_name', { name: stepTemplate?.name })}
          visible={isCopyModalOpen}
          isCopying={isCopying}
          onDuplicate={onCopyConfirm}
          toggle={() => setOpenModal(null)}
        />
      )}

      {isRevertToDraftModalOpen && (
        <ConfirmationModal
          title={I18N('revert_to_draft_modal.title')}
          description={I18N(`revert_to_draft_modal.description.${stepType}`)}
          toggle={() => setOpenModal(null)}
          visible={isRevertToDraftModalOpen}
          isSaving={isSaving}
          onConfirmClick={() => {
            onRevertToDraft('draft')
            setOpenModal(null)
          }}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          deleteText={I18N('delete_modal.title')}
          deleteSecondaryText={I18N(`delete_modal.description.${stepType}`)}
          deleteConfirmText={I18N('delete_modal.delete_step')}
          deleteConfirm={onDeleteConfirm}
          showDeleteModal={isDeleteModalOpen}
          closeDeleteModal={() => setOpenModal(null)}
          isDeleting={isDeleting}
        />
      )}

      {showTaskPreview && isTaskPreviewModalOpen && (
        <TaskableTemplatePreviewModal
          taskableTemplateWorkingCopy={workingCopy}
          taskableType={stepType}
          journeyBlueprintType={journeyBlueprintType}
          isModalOpen={isTaskPreviewModalOpen}
          toggle={() => setOpenModal(null)}
        />
      )}

      {showSendPreviewEmail && isSendPreviewEmailModalOpen && (
        <SendPreviewEmailModal
          visible={isSendPreviewEmailModalOpen}
          closeModal={closeModal}
          onSend={onSendPreviewEmail}
          isSending={isSendingPreviewEmail}
          areChangesPresent={false}
          objectName=''
        />
      )}
    </div>
  )
}

export default StepTemplateActionsDropdown
