import CdnSvg from 'components/common/cdnSvg'
import ChatUsername from 'components/common/chatUsername'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import UserLink from 'components/common/userLink'
import React from 'react'
import { UserType } from 'types/user'

type EmployeeSidebarCardProps = {
  user: UserType
}

const EmployeeSidebarCard = ({ user }: EmployeeSidebarCardProps) => (
  <div className='d-flex'>
    <div className='d-flex align-items-center bordered py-3 px-4 gap-3 my-4'>
      <div>
        <EmployeeThumbnailPhoto employee={user} linkToProfile />
      </div>

      <div>
        <UserLink
          user={user}
          hideImage
          customClassName='text-primary'
        />
        <div className='text-secondary text-small'>
          {user.title}
        </div>
      </div>

      <div>
        <a
          className='d-flex gap-1 align-items-center'
          href={`mailto:${user.primaryEmail}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <CdnSvg src='/images/envelopeOutlineIcon.svg' className='MessagingIcon' />
          <span className='font-weight-400 text-small text-primary'>{user.primaryEmail}</span>
        </a>

        {user.primaryChatUsers?.map(chatUser => (
          <div key={chatUser.id}>
            <ChatUsername
              chatUser={chatUser}
              iconClass='MessagingIcon'
              className='text-small font-weight-400'
            />
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default EmployeeSidebarCard
