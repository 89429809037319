import React from 'react'
import HelpTooltip from 'components/common/tooltip'
import classNames from 'classnames'

interface LegendItemProps {
  value: string
  category: string
  backgroundColor: string
  showDescription?: boolean
  description?: string
  showTooltip?: boolean
  tooltipText?: string
  classname?: string
}

export const LegendItem = ({
  value,
  category,
  backgroundColor,
  showDescription = true,
  description,
  showTooltip = false,
  tooltipText,
  classname,
}: LegendItemProps) => (
  <div className={classNames('d-flex flex-row', classname)}>
    <div className='analytics-legend-item-color' style={{ backgroundColor }} />
    <div className='d-flex flex-column'>
      <h3 className='mb-0'>{value}</h3>
      <div className='d-flex flex-row align-items-center'>
        <p className='mb-0'>{category}</p>
        {showTooltip && (
          <HelpTooltip
            iconStyle={{ width: '15px', height: '15px' }}
            className='ml-2'
            tooltipClassName=''
            tooltipInnerClassName='text-small px-1 py-2'
          >
            {tooltipText}
          </HelpTooltip>
        )}
      </div>
      {showDescription && <p className='text-secondary text-small'>{description}</p>}
    </div>
  </div>
)

export default LegendItem
