import React, { useEffect, useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import EmployeeMiniCard from 'components/common/employeeMiniCard'
import Modal from 'components/common/modal'
import PaginationContainer from 'components/common/tables/paginationContainer'
import API from 'services/api'
import { useDebounce } from 'usehooks-ts'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import useApi from 'components/common/hooks/useApi'


const ICON_PATHS = {
  email: '/images/envelopeOutlineIcon.svg',
  slack: '/images/slackLogoIcon.svg',
  // we need to refactor and always use msTeam or msTeams :facepalm:
  msTeam: '/images/microsoftTeamsIcon.svg',
  msTeams: '/images/microsoftTeamsIcon.svg',
  pushNotification: '/images/mobilePhoneIcon.svg',
}

const I18N = i18nPath('views.admin.includes.audience_selector')


const UserRow = ({ user, channelOverrides, includePreferredChannels }) => {
  const notificationTypesToShow = _.keys(ICON_PATHS).filter((notificationType) => {
    if (channelOverrides.includes(_.snakeCase(notificationType))) return true

    return includePreferredChannels && user.alertTypeSetting[notificationType]
  })

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <div>
          <EmployeeMiniCard employee={user} />
        </div>

        <div className='channels-column'>
          {notificationTypesToShow.map(notifType => (
            <CdnSvg
              key={notifType}
              src={ICON_PATHS[notifType]}
              className={classNames('PreferredNotificationTypeIcon')}
            />
          ))}
        </div>
      </div>

      <hr className='my-1' />
    </>
  )
}

const defaultApiParams = {
  page: 1,
  perPage: 10,
  includeAlertTypeSetting: true,
  includeSubscribedTagIdsSetting: true,
}

const AudiencePreferredChannelModal = ({
  userIds,
  showAudienceModal,
  closeModal,
  summary = I18N('sub_summary'),
  title = I18N('audience_summary'),
  channelOverrides = [],
  includePreferredChannels = false,
}) => {
  const [simpleFetchAll, { data = [], paginationData, isLoading }] = useApi(API.users.simpleFetchAll)

  const [query, setQuery] = useState('')

  const debouncedQuery = useDebounce(query, 400)

  useEffect(() => {
    simpleFetchAll(
      userIds,
      { ...defaultApiParams, query: debouncedQuery }
    )
  }, [debouncedQuery])

  const handlePageChange = (page) => {
    simpleFetchAll(
      userIds,
      { ...defaultApiParams, page, query: debouncedQuery }
    )
  }

  return (
    <Modal
      visible={showAudienceModal}
      toggle={closeModal}
      className='PeopleModal'
      title={title}
      modalTitleClassName='h1'
      size='lg'
    >
      {summary && (
        <p>{summary}</p>
      )}
      <input
        type='text'
        placeholder={I18N('search_placeholder')}
        value={query}
        onChange={e => setQuery(e.target.value)}
        className='w-100 mb-3'
      />

      <div className='AudiencePreferredChannelModal'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <b>{I18NCommon('employee')}</b>
          </div>
          <div className='channels-column'>
            <b>{I18NCommon('channels')}</b>
          </div>
        </div>

        <hr className='mb-1' />

        {isLoading && <CirclesLoadingIndicator />}
        {!isLoading && (
          data?.length === 0
            ? <p className='mt-3'>{I18NCommon('no_users_found')}</p>
            : data.map(user => (
              <UserRow
                user={user}
                key={user.id}
                channelOverrides={channelOverrides}
                includePreferredChannels={includePreferredChannels}
              />
            ))
        )}

        {paginationData.page && (
          <PaginationContainer
            page={paginationData.page}
            numPages={paginationData.totalPages}
            perPage={paginationData.perPage}
            totalCount={paginationData.total}
            handleClick={handlePageChange}
          />
        )}
      </div>
    </Modal>
  )
}

export default AudiencePreferredChannelModal
