import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import VideoConferenceLinkDisplay from 'components/qna/videoConferenceLinkDisplay'
import EditableEventField from 'components/qna/event_fields/editableEventField'

import VideoIcon from 'components/icons/videoIcon'
import EditIcon from 'components/icons/editIcon'

const I18N = i18nPath('views.qna.events')

const VideoConferenceLinkEdit = ({ event }) => (
  <EditableEventField
    event={event}
    fieldName='videoConferenceLink'
    placeholder='https://...'
    inputStyle={{ width: '200px' }}
    addFieldContent={(
      <>
        <VideoIcon className='mr-2' />
        <span className='font-weight-500 align-middle link-color'>{I18N('add_video_link')}</span>
      </>
    )}
  >
    {(onClickEditIconHandler, setIsHovered) => (
      <>
        {event.videoConferenceLink && (
          <div className='ml-sm-0 ml-md-4' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} id='video_conference_link'>
            <VideoConferenceLinkDisplay
              link={event.videoConferenceLink}
              generateRecapArticle={event.generateRecapArticle}
              showZoomSettings
              eventId={event.id}
            />
            <a
              onClick={() => onClickEditIconHandler()}
            >
              <EditIcon width='21' height='14' />
            </a>
          </div>
        )}
      </>
    )}
  </EditableEventField>
)

const EventVideoConferenceLink = ({ event, canEditEvent, className }) => (
  <div className={className}>
    {canEditEvent && (
      <VideoConferenceLinkEdit event={event} />
    )}
    {!canEditEvent && event.videoConferenceLink && (
      <div className='ml-4' id='video_conference_link'>
        <VideoConferenceLinkDisplay link={event.videoConferenceLink} />
      </div>
    )}
  </div>
)

export default EventVideoConferenceLink
