import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

const I18N = i18nPath('views.home')

const calendarDayName = (timeDiff, eventTime) => {
  switch (timeDiff) {
  case 0:
    return I18N('today')
  case 1:
    return I18N('tomorrow')
  default:
    return i18nMoment(eventTime).format('dddd')
  }
}

const calendarEventDisplayTime = (start, end, isAllDay = false) => {
  const startEventTime = i18nMoment(start)
  const endEventTime = i18nMoment(end)

  // We need just the day, without time, for calculating day differences
  const startOfToday = i18nMoment().startOf('day')
  const startEventDay = i18nMoment(startEventTime.format('YYYY-MM-DD'))
  const endEventDay = i18nMoment(endEventTime.format('YYYY-MM-DD'))

  const startTimeDiff = startEventDay.diff(startOfToday, 'days')
  const endTimeDiff = endEventDay.diff(startOfToday, 'days')
  const diffInDaysBetweenStartAndEndDates = endEventDay.diff(startEventDay, 'days')
  const isMultiday = diffInDaysBetweenStartAndEndDates > 0
  const inTheNextSixDays = (startTimeDiff <= 6) && (startTimeDiff >= 0) && (endTimeDiff <= 6)

  if (isMultiday) {
    if (inTheNextSixDays) {
      return `${calendarDayName(startTimeDiff, startEventTime)} - ${calendarDayName(endTimeDiff, endEventTime)}`
    } else {
      return `${startEventTime.format('MMM D')} - ${endEventTime.format('MMM D')}`
    }
  } else if (isAllDay) {
    return I18N('all_day')
  } else {
    return startEventTime.format('hh:mm A')
  }
}

export const calendarEventDisplayTimeOld = (start, end, isAllDay = false) => {
  const startEventTime = i18nMoment(start)
  const endEventTime = i18nMoment(end)

  // We need just the day, without time, for calculating day differences
  const startOfToday = i18nMoment().startOf('day')
  const startEventDay = i18nMoment(startEventTime.format('YYYY-MM-DD'))
  const endEventDay = i18nMoment(endEventTime.format('YYYY-MM-DD'))

  const startTimeDiff = startEventDay.diff(startOfToday, 'days')
  const endTimeDiff = endEventDay.diff(startOfToday, 'days')
  const diffInDaysBetweenStartAndEndDates = endEventDay.diff(startEventDay, 'days')
  const isMultiday = diffInDaysBetweenStartAndEndDates > 0
  const inTheNextSixDays = (startTimeDiff <= 6) && (startTimeDiff >= 0) && (endTimeDiff <= 6)

  if (isMultiday) {
    const dates = {
      startDate: '',
      endDate: '',
    }

    if (inTheNextSixDays) {
      dates.startDate = calendarDayName(startTimeDiff, startEventTime)
      dates.endDate = calendarDayName(endTimeDiff, endEventTime)
    } else {
      dates.startDate = startEventTime.format('ll')
      dates.endDate = endEventTime.format('ll')
    }

    return I18N('date_range', dates)
  } else if (isAllDay) {
    if (inTheNextSixDays) {
      return calendarDayName(startTimeDiff, startEventTime)
    } else {
      return startEventTime.format('ll')
    }
  } else {
    if (inTheNextSixDays) {
      return startEventTime.calendar()
    } else {
      return startEventTime.format('llll')
    }
  }
}

export default calendarEventDisplayTime
