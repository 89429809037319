import React from 'react'
import { Link } from 'react-router-dom'
import AdminBanner from 'components/common/adminBanner'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.article_editor.banners')

const articleOrGroupPost = article => (article.groupId ? I18N('group_post') : I18N('article'))

const articleAdminBanners = ({
  article, openScheduleModal, createdTemplateId, closeEditTemplateBanner,
}) => ([
  article?.status === 'published' && <PublishedArticleBanner article={article} key='published' />,
  article?.status === 'scheduled' && <ScheduledArticleBanner article={article} openScheduleModal={openScheduleModal} key='scheduled' />,
  article?.status === 'pending' && <PendingArticleBanner article={article} key='pending' />,
  createdTemplateId && (
    <EditTemplateBanner createdTemplateId={createdTemplateId} closeEditTemplateBanner={closeEditTemplateBanner} key='template' />
  ),
].filter(Boolean))

export const PublishedArticleBanner = ({ article }) => (
  <AdminBanner color='green'>
    <span className='text-small font-weight-600'>
      {I18N('published', { type: articleOrGroupPost(article), date: i18nMoment(article.publishedAt).format('LLL') })}
    </span>
  </AdminBanner>
)

export const PendingArticleBanner = ({ article }) => (
  <AdminBanner color='orange'>
    <span className='text-small font-weight-600'>
      {I18N('pending', { type: articleOrGroupPost(article) })}
    </span>
  </AdminBanner>
)

export const ScheduledArticleBanner = ({ article, openScheduleModal }) => (
  <AdminBanner color='orange'>
    <span className='text-small font-weight-600'>
      {I18N('scheduled', { type: articleOrGroupPost(article), date: i18nMoment(article.publishedAt).format('LLL') })}&nbsp;
      <span
        className='banner-link'
        onClick={openScheduleModal}
      >
        {I18N('click_here_to_reschedule')}
      </span>
    </span>
  </AdminBanner>
)

export const EditTemplateBanner = ({ createdTemplateId, closeEditTemplateBanner }) => (
  <AdminBanner color='blue' onClose={closeEditTemplateBanner}>
    <span className='text-small font-weight-600'>
      {I18N('template_saved')}&nbsp;
      <Link
        to={`/admin/articles/templates/${createdTemplateId}`}
      >
        {I18N('click_here_to_edit')}
      </Link>
    </span>
  </AdminBanner>
)

export default articleAdminBanners
