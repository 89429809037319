import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const useCloseDesktopNavWhenViewingPageHistory = (isDesktopNavOpen, setIsDesktopNavOpen) => {
  const location = useLocation()
  const isViewingPageHistory = location.pathname.endsWith('/history')
  const originalDesktopNavOpen = useRef(isDesktopNavOpen)

  useEffect(() => {
    if (isViewingPageHistory) {
      originalDesktopNavOpen.current = isDesktopNavOpen
      setIsDesktopNavOpen(false)
    } else {
      setIsDesktopNavOpen(originalDesktopNavOpen.current)
    }
  }, [isViewingPageHistory])
}

export default useCloseDesktopNavWhenViewingPageHistory
