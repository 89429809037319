import React from 'react'

import SettingRadioButtons from 'components/settings/common/settingRadioButtons'
import SettingCheckbox from 'components/settings/common/settingCheckbox'
import { i18nPath } from 'utils/i18nHelpers'

const QNA_NEW_QUESTIONS_NOTIFICATION = 'qna.new_questions_notifications'
const QNA_ACTIVITY_INVOLVING_ME = 'qna.activity_involving_me'
const QNA_EVENT_CLOSING_REMINDER_NOTIFICATION = 'qna.event_closing_notification'

const WHEN_SUBMITTED = 'when_submitted'
const DAILY_SUMMARY = 'daily_summary'
const NEVER = 'never'

const I18N = i18nPath('views.settings.events_section')

const options = [
  { id: WHEN_SUBMITTED, value: { notify: WHEN_SUBMITTED }, text: I18N(WHEN_SUBMITTED) },
  { id: DAILY_SUMMARY, value: { notify: DAILY_SUMMARY }, text: I18N(DAILY_SUMMARY) },
  { id: NEVER, value: { notify: NEVER }, text: I18N(NEVER) },
]

const EventsSettingsSection = () => (
  <div className='EventsSettingsSection'>

    <div className='mb-4'>
      <h5 className='mb-3'>{I18N('event_activity')}</h5>
      <div className='font-weight-500'>{I18N(QNA_NEW_QUESTIONS_NOTIFICATION)}</div>
      <SettingRadioButtons
        name={QNA_NEW_QUESTIONS_NOTIFICATION}
        options={options}
      />
      <div className='font-weight-500 mt-3'>{I18N(QNA_ACTIVITY_INVOLVING_ME)}</div>
      <SettingRadioButtons
        name={QNA_ACTIVITY_INVOLVING_ME}
        options={options}
      />
    </div>

    <div>
      <h5 className='mt-4'>{I18N('event_reminders')}</h5>
      <SettingCheckbox name={QNA_EVENT_CLOSING_REMINDER_NOTIFICATION} />
    </div>
  </div>
)

export default EventsSettingsSection
