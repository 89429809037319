import React, { useMemo, useState } from 'react'

import ReactSelect from 'components/common/react_select'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'

const I18N = i18nPath('views.admin.journeys.tasks.task_list_page')

const AssignTaskSelect = ({
  step,
  toCorrespondent,
}) => {
  const currentUser = useCurrentUser()
  const unassignedOption = { id: null, preferredFullName: I18N('unassigned'), primaryPhotoThumbnailUrl: '/images/profile_small-missing_grey.png' }
  const [assignedUser, setAssignedUser] = useState<any>(step.toUser || unassignedOption)

  const updateFunction = step.entityType === 'Journey::Introduction' ? API.admin.introductions.update : API.admin.tasks.update
  const [updateAssignee] = useApi(updateFunction, {
    toastSuccessMessage: I18N('assigned_successfully'),
  })

  const groupAssignmentOptions = useMemo(() => (
    [
      unassignedOption,
      currentUser,
      ...toCorrespondent?.group?.users?.filter(user => user.id !== currentUser.id) || [],
    ]
  ), [toCorrespondent])

  const onAssigneeChange = (selecteOption) => {
    setAssignedUser(selecteOption)
    updateAssignee({ id: step.entity.id, stepAttributes: { id: step.id, toUserId: selecteOption.id } })
  }

  return (
    <ReactSelect
      options={groupAssignmentOptions}
      value={assignedUser}
      onChange={onAssigneeChange}
      getOptionValue={user => user.id}
      getOptionLabel={user => (
        <span className='d-flex align-items-center'>
          <EmployeeThumbnailPhoto employee={user} size='22px' className='mr-1' />
          <span className='d-flex align-items-center h-100 text-normal'>{user.preferredFullName}</span>
        </span>
      )}
    />
  )
}

export default AssignTaskSelect
