import React from 'react'
import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import Picture from 'components/common/images/picture'
import userSlice from 'redux/slices/users'
import ImageSelector from 'components/common/imageSelector'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CdnSvg from 'components/common/cdnSvg'

const thinPlusIconPath = '/images/thinPlusIcon.svg'

const I18N = i18nPath('views.admin.users')

const PhotosSection = ({
  user = null,
  initialIsOpen = false,
}) => {
  const dispatch = useDispatch()

  const { photos = [] } = user

  const {
    isPhotoUploading,
  } = useSelector(userSlice.selectors.getAdminUserMetaData())

  const onImageAdded = (image, { croppedAreaPixels }) => {
    dispatch(userSlice.asyncActions.admin.createPhoto(image, user, { reloadUser: false, croppedAreaPixels }))
  }

  const sortedPhotos = _.orderBy(photos, ['primary', 'id'], ['desc', 'asc'])

  return (
    <CollapsibleCard
      title={I18N('sections.photos')}
      initialIsOpen={initialIsOpen}
      className='mt-3'
    >
      <div className='PhotosSection mt-3'>
        {sortedPhotos.map(picture => (
          <Picture owner={user} key={picture.id} picture={picture} />
        ))}
        {isPhotoUploading ? (
          <CirclesLoadingIndicator className='loading-container d-flex' />
        ) : (
          <ImageSelector
            showLoadedImage={false}
            onImageAdded={onImageAdded}
            imageText={<CdnSvg className='PlusIcon' src={thinPlusIconPath} />}
            cropImage
          />
        )}
      </div>
    </CollapsibleCard>
  )
}

export default PhotosSection
