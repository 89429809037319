import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'

import adminArticleSlice from 'redux/slices/admin/articles'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import DateCell from 'components/common/tables/dateCell'
import EmptyStateTable from 'components/admin/articles/emptyStateTable'

const I18N = i18nPath('views.admin.article_list')

const getStatsValue = (stat, key) => (stat && stat[key] ? stat[key] : 0)

const generateColumns = ({ basePath }) => [
  {
    header: I18N('table_header.title'),
    col: 'col-title',
    id: 'title',
    style: { minWidth: '30%' },
    accessor: d => (
      <Link to={`${basePath}${d.id}/stats`} className='truncate-text-at-2-lines'>
        {d.title}
      </Link>
    ),
  },
  {
    header: I18N('table_header.published'),
    col: 'col-published_at',
    id: 'published_at',
    accessor: d => <DateCell date={d.publishedAt} />,
  },
  {
    header: I18N('table_header.audience_size'),
    col: 'col-audience_size',
    accessor: (d) => {
      const audienceUserIds = getStatsValue(d.articleStat, 'audienceUserIds')
      return audienceUserIds ? audienceUserIds.length : 0
    },
  },
  {
    header: I18N('table_header.audience_reach'),
    col: 'col-audience_reach',
    id: 'article_stats.audience_unique_reach',
    accessor: d => I18n.toPercentage(getStatsValue(d.articleStat, 'audienceUniqueReach') * 100, { precision: 1 }),
  },
  {
    header: I18N('table_header.stats_updated_at'),
    col: 'col-updated_at',
    id: 'article_stats.updated_at',
    accessor: d => <DateCell date={getStatsValue(d.articleStat, 'updatedAt')} />,
  },
]

const ArticleStatsTable = ({ myPosts = false }) => {
  const dispatch = useDispatch()

  const articles = useSelector(adminArticleSlice.selectors.getArticles())
  const { queryParams, isLoading, isNotFound } = useSelector(adminArticleSlice.selectors.getMetaData())
  const {
    page, totalPages, perPage, total,
  } = queryParams

  const fetchArticles = (fetchParams) => {
    dispatch(adminArticleSlice.asyncActions.fetchArticles({
      ...queryParams,
      publishStatus: 'published',
      ...fetchParams,
      myPosts,
    }))
  }

  useEffect(() => {
    fetchArticles()
  }, [])

  const showEmptyState = articles.length === 0 && !isLoading

  const columns = useMemo(() => (
    generateColumns({ basePath: myPosts ? '/posts/' : '/admin/articles/' })
  ), [myPosts])

  return (
    <div className='ArticleStatsTable row mb-4'>
      <div className='col'>
        <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
          <div className='overflow-x-auto'>
            {showEmptyState ? (
              <EmptyStateTable />
            ) : (
              <SmartTable
                data={articles}
                sortKey='created_at'
                sortedAsc={false}
                showPagination={page !== null && totalPages !== null}
                columns={columns}
                page={page}
                pages={totalPages}
                totalCount={total}
                perPage={perPage}
                onPaginationClick={fetchArticles}
                onSortChange={fetchArticles}
                className='ArticleStatsTable white-bg-table'
              />
            )}
          </div>
        </TableLoadingContainer>
      </div>
    </div>
  )
}

export default ArticleStatsTable
