import build from 'redux-object'
import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import queryParamsFromHeaders, { PaginationParams, defaultPaginationParams } from 'utils/queryParamsFromHeaders'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { ReduxState } from 'redux/redux'
import entitySlice from 'redux/slices/entities'

const buildFeedbackPayload = feedback => _.pick(feedback, [
  'id',
  'helpful',
  'comment',
  'incomplete',
  'difficultToUnderstand',
  'inaccurateOrIrrelevant',
  'missingOrBrokenLinks',
])

export interface FeedbackState {
  feedbackIds: string[]
  meta: DefaultMetaType & {
    queryParams: PaginationParams
  }
}

export const initialState: FeedbackState = {
  feedbackIds: [],
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams, perPage: 5,
    },
  },
}

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    ...defaultActions,

    mergeFeedbackIds(state, action) {
      state.feedbackIds = _.uniq([
        ...state.feedbackIds,
        ...action.payload,
      ])
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    clearFeedbackIds(state) {
      state.feedbackIds = []
    },
  },
})

const asyncActions = {
  fetchAll: ({
    feedbackableId,
    feedbackableType,
    queryParams,
  }) => async (dispatch) => {
    try {
      dispatch(feedbackSlice.actions.isLoading(true))

      const response = await API.feedback.fetchAll(feedbackableId, feedbackableType, queryParams)

      dispatch(entitySlice.actions.add({ data: response.data }))

      const feedbackIds = response.data.data.map(feedback => feedback.id)
      dispatch(feedbackSlice.actions.mergeFeedbackIds(feedbackIds))

      const newQueryParams = queryParamsFromHeaders(response)
      dispatch(feedbackSlice.actions.setQueryParams(newQueryParams))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(feedbackSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(feedbackSlice.actions.isLoading(false))
    }
  },

  create: ({
    feedbackableId,
    feedbackableType,
    params,
  }, onSuccess = (id: string) => {}) => async (dispatch) => {
    try {
      dispatch(feedbackSlice.actions.isLoading(true))

      const response = await API.feedback.create(feedbackableId, feedbackableType, buildFeedbackPayload(params))
      dispatch(entitySlice.actions.add({ data: response.data }))
      onSuccess(response.data.data.id)
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(feedbackSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(feedbackSlice.actions.isLoading(false))
    }
  },

  update: ({
    feedbackableId,
    feedbackableType,
    params,
  }, onSuccess = () => {}) => async (dispatch) => {
    try {
      dispatch(feedbackSlice.actions.isLoading(true))

      const response = await API.feedback.update(feedbackableId, feedbackableType, buildFeedbackPayload(params))
      dispatch(entitySlice.actions.update({ data: response.data }))
      onSuccess()
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(feedbackSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(feedbackSlice.actions.isLoading(false))
    }
  },

  destroy: ({
    feedbackableId,
    feedbackableType,
    feedbackId,
  }, onSuccess = () => {}) => async (dispatch) => {
    try {
      dispatch(feedbackSlice.actions.isLoading(true))

      const response = await API.feedback.destroy(feedbackableId, feedbackableType, feedbackId)
      // We need to update because we are receiving the feedbackable counters
      dispatch(entitySlice.actions.update({ data: response.data }))
      onSuccess()
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(feedbackSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(feedbackSlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.feedback.meta,

  getFeedbacks: () => (state: ReduxState) => state.feedback.feedbackIds.map(id => build(state.entities, 'feedback', id)),
}

export default {
  ...feedbackSlice,
  asyncActions,
  selectors,
}
