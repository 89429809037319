import React, { useState } from 'react'

import SuggestedIcon from 'components/common/suggestedIcon'
import CdnSvg from 'components/common/cdnSvg'
import PersonalLinkModal from 'components/home/widgets/links_widget/personalLinkModal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const LinkListItem = ({ link, widgetId, fetchLinks }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteLink] = useApi(() => API.layout.links.delete(widgetId, link.id), {
    onSuccess: fetchLinks,
  })

  return (
    <>
      <a
        className='LinkListItem d-flex align-items-center p-1'
        href={link.displayUrl}
        target='_blank'
        rel='noopener noreferrer'
      >
        <SuggestedIcon name={link.displayName} url={link.displayUrl} />
        <span className='truncate-text-at-1-lines text-primary-link'>
          {link.displayName}
        </span>
        {link.personal && (
          <>
            <div
              className='PencilIcon pointer ml-auto'
              onClick={(e) => {
                e.preventDefault()
                setIsModalOpen(true)
              }}
            >
              <CdnSvg src='/images/pencilIcon.svg' />
            </div>
            <div
              className='XIcon pointer'
              onClick={(e) => {
                e.preventDefault()
                deleteLink()
              }}
            >
              <CdnSvg src='/images/xIcon.svg' />
            </div>
          </>
        )}
      </a>
      {isModalOpen && (
        <PersonalLinkModal
          link={link}
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(!isModalOpen)}
          widgetId={widgetId}
          onSave={fetchLinks}
        />
      )}
    </>
  )
}

export default LinkListItem
