import React from 'react'
import { i18nMoment, I18NCommon } from 'utils/i18nHelpers'
import moment from 'moment'
import DatePicker from 'components/form_fields/datePicker'

interface DateRangePickerProps {
  setDateRange: (newDates: [string?, string?]) => void,
  dateFormat?: string,
  showTimeSelect?: boolean
  dateRange: [string?, string?]
}

const DateRangePicker = ({
  dateRange,
  setDateRange,
  dateFormat = 'LL',
  showTimeSelect = false,
}: DateRangePickerProps) => {
  const [startDate, endDate] = dateRange

  const start = startDate ? i18nMoment(startDate) : undefined
  const end = endDate ? i18nMoment(endDate) : undefined

  const onStartDateChange = (newStartDate: string) => {
    setDateRange([newStartDate, endDate])
  }

  const onEndDateChange = (newEndDate: string) => {
    setDateRange([startDate, newEndDate])
  }

  return (
    <>
      <DatePicker
        selected={start}
        onChange={(date: moment.Moment) => onStartDateChange(date?.format())}
        selectsStart
        startDate={start}
        endDate={start}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        isClearable={!end}
        className='StartDatePicker'
        inputReadOnly={false}
        placeholderText={I18NCommon('select_start_date')}
      />
      {start && (
        <DatePicker
          selected={end}
          onChange={(date: moment.Moment) => onEndDateChange(date?.format())}
          selectsEnd
          startDate={start}
          endDate={end}
          minDate={start}
          dateFormat={dateFormat}
          showTimeSelect={showTimeSelect}
          isClearable={true}
          className='EndDatePicker ml-2'
          inputReadOnly={false}
          placeholderText={I18NCommon('select_end_date')}
        />
      )}
    </>
  )
}


export default DateRangePicker
