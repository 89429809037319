import React, { useState } from 'react'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import CheckIcon from 'components/icons/checkIcon'
import CancelButton from 'components/common/cancelButton'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { LoadingContainer } from 'components/common/loadingContainer'

const I18N = i18nPath('views.integrations_page.integration_card.cleary_sftp')

const PublicSshKeys = ({ keys, removePublicKey }) => {
  const [deletingKeyId, setDeletingKeyId] = useState(null)
  const [confirmKey, setConfirmKey] = useState('')
  const [deleteKey, { isLoading: isDeletingKey }] = useApi(
    API.admin.sftpSshPublicKeys.deleteKey,
    {
      onSuccess: () => {
        removePublicKey(deletingKeyId)
        setDeletingKeyId(null)
      },
    }
  )

  const openUninstallModal = (key) => {
    setDeletingKeyId(key.sshPublicKeyId)
    setConfirmKey('')
  }

  const handleOnDelete = () => {
    deleteKey(deletingKeyId)
  }

  if (keys.length === 0) {
    return null
  }

  return (
    <LoadingContainer isLoading={isDeletingKey}>
      <div className='mb-3'>
        {deletingKeyId && (
          <ConfirmationModal
            title={I18N('delete_keys.title')}
            toggle={() => setDeletingKeyId(null)}
            confirmationDisabled={confirmKey !== deletingKeyId}
            onConfirmClick={handleOnDelete}
            visible
            description=''
          >
            <>
              <label className='form-label mb-0 text-left d-block text-secondary text-small mb-2'>
                {I18N('delete_keys.enter_confirmation', { key: deletingKeyId })}
              </label>
              <input
                className='mb-3 w-100'
                value={confirmKey}
                onChange={e => setConfirmKey(e.target.value)}
                placeholder={deletingKeyId}
                autoFocus
              />
            </>
          </ConfirmationModal>
        )}

        {keys.map(key => (
          <div className='d-flex align-items-center mb-1' key={key.sshPublicKeyId}>
            <CheckIcon fillColor='var(--success-color)' className='CheckIcon mr-1' width='10px' height='10px' />
            <div className='text-success text-small'>
              <span>
                <span className='mr-1 font-weight-bold'>{key.dateImported || (new Date()).toISOString()} - </span>
                  ...{key.sshPublicKeyBody.slice(-30)}
              </span>

              <CancelButton
                className='ml-2 mr-3'
                onClick={() => openUninstallModal(key)}
              >
                {I18NCommon('uninstall')}
              </CancelButton>
            </div>
          </div>
        ))}
      </div>
    </LoadingContainer>
  )
}

export default PublicSshKeys
