import React from 'react'
import Card from 'components/common/card'
import GroupSelectField from 'components/form_fields/groupSelectField'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.template_editor.teams_card')

const TemplateTeamsCard = ({ workingCopy, updateWorkingCopy }) => (
  <Card className='mb-4'>
    <h5>{I18N('title')}</h5>
    <p>{I18N('help_text')}</p>
    <GroupSelectField
      onChange={groupsUsingTemplateAsDefault => updateWorkingCopy({ groupsUsingTemplateAsDefault })}
      value={workingCopy.groupsUsingTemplateAsDefault}
    />
  </Card>
)

export default TemplateTeamsCard
