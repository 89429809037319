import React from 'react'
import { useSelector } from 'react-redux'
import SVG from 'react-inlinesvg'

const DefaultShoutoutIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='72' height='64' viewBox='0 0 52 44' fill='none'>
    <path
      d='M32.5996 0.78405C32.4645 0.413437 32.1613 0.133477 31.7879 0.0368246C31.4152 -0.0598281 31.0179 0.0361589 30.7276 0.296788L5.42829 22.9355L1.12344 23.1248C0.74814 23.1415 0.403362 23.3415 0.195586 23.6627C-0.0121905 23.984 -0.0569927 24.3886 0.0735166 24.7499L4.25371 36.2709C4.38487 36.6322 4.67641 36.9075 5.03807 37.0108C5.14195 37.0408 5.24779 37.0548 5.35363 37.0548C5.61529 37.0548 5.87307 36.9648 6.08279 36.7942L9.46045 34.0459L11.7369 34.0552L14.0887 40.537C14.4692 41.5868 15.2262 42.4214 16.219 42.8873C16.7612 43.1419 17.3378 43.2692 17.9163 43.2692C18.3974 43.2692 18.8798 43.1806 19.3447 43.0033L30.4887 38.7406C31.512 38.3493 32.3249 37.5721 32.7788 36.5529C33.1196 35.7856 33.2261 34.9498 33.0956 34.1359L43.0215 34.1739H43.0377C43.687 34.1739 44.2123 33.634 44.2123 32.9687C44.2123 32.7587 44.1603 32.5608 44.0675 32.3888L32.5996 0.78405ZM5.8906 33.8759L2.83759 25.4618L5.08741 25.3632L6.37173 28.902L7.65604 32.4408L5.8906 33.8759ZM30.6439 35.5517C30.4504 35.9856 30.1049 36.3156 29.6693 36.4822L18.5247 40.7449C18.0909 40.9109 17.6183 40.8942 17.1956 40.6956C16.7735 40.4976 16.4515 40.143 16.2891 39.6964L14.2464 34.0652L30.6685 34.1279L30.6906 34.1885C30.8529 34.6338 30.8367 35.1191 30.6439 35.5517ZM9.87016 31.6383L7.27296 24.4819L26.9013 6.91783L32.1665 21.4297C32.3424 21.915 32.7905 22.2143 33.267 22.2143C33.4027 22.2143 33.5417 22.1903 33.6768 22.139C34.2845 21.9057 34.5936 21.2118 34.367 20.5885L28.7921 5.22607L30.9912 3.25902L41.3346 31.7589L9.87016 31.6383Z'
      fill='white'
    />
    <path
      d='M42.3196 16.3584C41.8444 16.3584 41.3963 16.0585 41.2204 15.5732C40.9931 14.95 41.3028 14.2561 41.9106 14.0228L48.4516 11.5218C49.0574 11.2905 49.7334 11.6058 49.9606 12.2303C50.1872 12.8536 49.8781 13.5475 49.2704 13.7808L42.7293 16.2818C42.5949 16.3338 42.4566 16.3584 42.3196 16.3584Z'
      fill='white'
    />
    <path
      d='M39.5439 8.71152C39.2861 8.71152 39.0277 8.62554 38.8108 8.44689C38.305 8.03162 38.2239 7.2724 38.6284 6.75247L42.2235 2.14381C42.6281 1.62455 43.3689 1.54123 43.8734 1.9565C44.3792 2.37311 44.4604 3.131 44.0559 3.65026L40.4607 8.25892C40.2302 8.55755 39.8887 8.71152 39.5439 8.71152Z'
      fill='white'
    />
    <path
      d='M45.3064 21.6143L50.9625 22.6848C51.5988 22.8055 52.0215 23.4341 51.9033 24.088C51.7994 24.6685 51.306 25.0745 50.7508 25.0745C50.68 25.0745 50.6086 25.0685 50.5365 25.0545L44.8805 23.9846C44.2442 23.864 43.8215 23.2354 43.9396 22.5815C44.0572 21.9269 44.6623 21.4917 45.3064 21.6143Z'
      fill='white'
    />
  </svg>
)

const ShoutoutIcon = () => {
  const imageUrls = useSelector(state => state.imageUrls)
  const customIconUrl = imageUrls.shoutoutIcon

  if (customIconUrl) {
    return <SVG className='CustomShoutoutIcon' src={customIconUrl} cacheGetRequests />
  }

  return <DefaultShoutoutIcon />
}

export default ShoutoutIcon
