import React from 'react'

import ManagedGroupDetailsSection from 'components/admin/managed_groups/managedGroupDetailsSection'
import ManagedGroupChannelSection from 'components/admin/managed_groups/managedGroupChannelSection'

const ManagedGroupForm = ({
  workingCopy, setWorkingCopy,
}) => (
  <div className='ManagedGroupForm w-100'>
    <ManagedGroupChannelSection
      workingCopy={workingCopy}
      setWorkingCopy={setWorkingCopy}
    />
    <ManagedGroupDetailsSection
      workingCopy={workingCopy}
      setWorkingCopy={setWorkingCopy}
    />
  </div>
)

export default ManagedGroupForm
