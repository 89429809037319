import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import API from 'services/api'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

import Card from 'components/common/card'
import ShoutoutCompanyValues from 'components/shoutouts/shoutoutCompanyValues'
import ShoutoutUsers from 'components/shoutouts/shoutoutUsers'
import ShoutoutFrom from 'components/shoutouts/shoutoutFrom'
import ShoutoutText from 'components/shoutouts/shoutoutText'
import ShoutoutSocial from 'components/shoutouts/shoutoutSocial'
import ShoutoutCardDropdownMenu from 'components/shoutouts/shoutoutCardDropdownMenu'
import PeopleSettingsCardMenuDropdown from 'components/home/peopleSettingsCardMenuDropdown'
import ClickWrapper from 'components/common/clickWrapper'
import CdnSvg from 'components/common/cdnSvg'
import ShoutoutGif from 'components/shoutouts/shoutoutGif'

const I18NShoutout = i18nPath('views.shoutout.card')
const I18NDropdown = i18nPath('views.feed.dropdown_menu')

const highFiveImagePath = '/images/highFiveImage.svg'

const ShoutoutDetails = ({ shoutout }) => {
  const {
    id: shoutoutId, text, sender, createdAt, companyValues, editedAt,
  } = shoutout

  return (
    <>
      <ShoutoutFrom sender={sender} createdAt={createdAt} edited={!!editedAt} />
      <ShoutoutText text={text} className='mt-2 ClickWrapperTargetContainer' />
      <ShoutoutGif shoutout={shoutout} />
      <ShoutoutCompanyValues shoutoutId={shoutoutId} companyValues={companyValues} />
    </>
  )
}

const Recipients = ({ isCurrentUser, shoutout, user }) => {
  if (isCurrentUser) {
    return I18NShoutout('you_received_shoutout')
  } else {
    const receivers = _.reject(shoutout.users, e => e.id === user.id)

    return <ShoutoutUsers users={receivers} />
  }
}

const ExcludedCardConfirmation = () => {
  const settingsLink = <Link to='/settings/people'>{I18NDropdown('settings')}</Link>

  return (
    <Card>
      <div>{i18nFormat(I18NDropdown('excluded_confirmation_settings_link'), settingsLink)}</div>
    </Card>
  )
}

const ShoutoutCardHomeFeed = ({
  feedItem,
  shoutout,
  user,
  blockClicks = false,
}) => {
  const cardRef = useRef()

  const [showExcludedConfirmation, setShowExcludedConfirmation] = useState(false)

  const handleExcludeFromFeed = () => {
    API.home.excludeFromFeed(feedItem)
    setShowExcludedConfirmation(true)
  }

  const isCurrentUser = !!_.find(shoutout.users, { id: user.id })

  return (
    <div ref={cardRef}>
      {showExcludedConfirmation ? (
        <ExcludedCardConfirmation />
      ) : (
        <ClickWrapper to={`/people/shoutouts/${shoutout.id}`}>
          <Card className={classNames('ShoutoutCardHomeFeed ClickWrapperTargetContainer', { blockClicks })} cardBodyClassName='ClickWrapperTargetContainer p-0'>
            {!isCurrentUser && feedItem && (
              <div className='position-absolute Card-dropdownMenu'>
                <PeopleSettingsCardMenuDropdown
                  feedItem={feedItem}
                  handleExcludeFromFeed={feedItem && handleExcludeFromFeed}
                />
              </div>
            )}
            {!feedItem && <ShoutoutCardDropdownMenu shoutout={shoutout} />}
            <div className='ClickWrapperTargetContainer d-flex align-items-end justify-content-center Card-hero ShoutoutCard-hero confetti-background position-relative'>
              <CdnSvg src={highFiveImagePath} />
            </div>

            <div className='ClickWrapperTargetContainer p-3'>
              <h5 className='mb-1 text-large text-center'>
                <Recipients isCurrentUser={isCurrentUser} shoutout={shoutout} user={user} />
              </h5>

              <ShoutoutDetails shoutout={shoutout} />

              <ShoutoutSocial shoutout={shoutout} />
            </div>
          </Card>
        </ClickWrapper>
      )}
    </div>
  )
}

export default ShoutoutCardHomeFeed
