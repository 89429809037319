import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import CustomTimeRange from 'components/analytics/common/customTimeRange'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import BackButton from 'components/common/backButton'
import RoundedAvatars from 'components/common/roundedAvatars'
import { Link } from 'react-router-dom'
import newsAnalyticsSlice from 'redux/slices/analytics/news'
import { EngagingArticleType } from 'types/analytics/news'
import { toPercentage } from 'utils/analytics'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.news.most_engaging_articles')

const backToText = i18nPath('views.platform_analytics')('title')

const womanWithComputerPath = '/images/analytics/womanWithComputerIcon.svg'

const MostEngagingArticlesPage = () => {
  const dispatch = useDispatch()

  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const {
    data, totalItems, totalPages, page,
  } = useSelector(newsAnalyticsSlice.selectors.getMostEngagingArticles())
  const { mostEngagingArticles: { isLoading } } = useSelector(newsAnalyticsSlice.selectors.getMetaData())

  const isEmpty = totalItems === 0 && !isLoading

  const fetchData = ({ page }) => {
    dispatch(newsAnalyticsSlice.asyncActions.admin.fetchMostEngagingArticles(
      {
        periodStart, periodEnd, page,
      }
    ))
  }

  useEffect(() => {
    fetchData({ page: 1 })
  }, [periodStart, periodEnd])

  const columns = [
    {
      header: I18NCommon('title'),
      accessor: (article: EngagingArticleType) => <Link to={`/admin/articles/${article.id}/stats`} target='_blank'>{article.title}</Link>,
    },
    {
      header: I18NCommon('authors'),
      style: { width: '15%' },
      accessor: (article: EngagingArticleType) => (
        <div className='d-flex flex-row align-items-center'>
          <RoundedAvatars
            users={article.authors}
            avatarSize='30px'
            className='mr-2'
            shownAvatars={2}
          />
        </div>
      ),
    },
    {
      header: I18NCommon('audience_reach'),
      style: { width: '10%' },
      accessor: (article: EngagingArticleType) => (
        toPercentage(article.reach)
      ),
    },
  ]

  const handlePaginationClick = ({ page }) => fetchData({ page })

  return (
    <main className='include-default-grid mb-5'>
      <header className='grid-span-12 d-flex flex-row justify-content-between my-5'>
        <BackButton
          backToText={backToText}
          url='/platform_analytics/news'
        />
        <CustomTimeRange
          periodStart={periodStart}
          setPeriodStart={setPeriodStart}
          periodEnd={periodEnd}
          setPeriodEnd={setPeriodEnd}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
        />
      </header>

      {isEmpty ? (
        <EmptyStateCard
          title={I18N('title')}
          iconPath={womanWithComputerPath}
          text={I18N('empty_state_text')}
          classname='grid-span-12'
        />
      ) : (
        <CardWithPaginatedTable
          title={I18N('title')}
          tableColumns={columns}
          tableData={data}
          classname='grid-span-12'
          isLoading={isLoading}
          showPagination={totalPages > 1}
          page={page}
          totalPages={totalPages}
          onPaginationClick={handlePaginationClick}
        />
      )}
    </main>
  )
}

export default MostEngagingArticlesPage
