import React from 'react'

import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import DangerousHTML from 'components/common/dangerousHTML'
import ClearyInternalSearchResultsTemplate from './clearyInternalSearchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

export const UserResult = ({ result }) => {
  const { user, highlights, displayPath } = result
  const {
    preferredFullName, title, city,
  } = { ...user, ...highlights }

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={<EmployeeThumbnailPhoto employee={user} size='40px' />}
      title={preferredFullName}
      subtitle={<DangerousHTML className='text-small' options={{ ALLOWED_TAGS: ['em'] }}>{title}</DangerousHTML>}
      description={city}
      result={result}
    />
  )
}

const UserResults = ({ showLabel = true }) => (
  <ClearyInternalSearchResultsTemplate
    showLabel={showLabel}
    label='people'
    type='user'
    searchResultToTemplateMap={result => <UserResult result={result} />}
  />
)

export default UserResults
