import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import camelCaseSplit from 'utils/camelCaseSplit'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

import userImportSlice from 'redux/slices/userImports'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import { ButtonSmallNarrow } from 'components/common/buttons'
import StatusLabel from 'components/common/status_label'
import CreateUserImportButtons from 'components/admin/userImports/createUserImportButtons'

const I18N = i18nPath('views.admin.user_imports_list')
const COMPLETE_STATUS = 'complete'
const FAILED_STATUS = 'failed'

const statusLabelColor = (status) => {
  if (status === COMPLETE_STATUS) {
    return 'green'
  }
  if (status === FAILED_STATUS) {
    return 'red'
  }

  return 'yellow'
}

const columns = [
  {
    header: I18N('created_at'),
    col: 'col-created_at',
    id: 'created_at',
    accessor: userImport => i18nMoment(userImport.createdAt).format('llll'),
  },
  {
    header: I18N('status'),
    col: 'col-status',
    id: 'status',
    accessor: userImport => (
      <StatusLabel color={statusLabelColor(userImport.status)}>
        {I18N(userImport.status)}
      </StatusLabel>
    ),
  },
  {
    header: I18N('type'),
    col: 'col-type',
    id: 'type',
    accessor: userImport => (<span className='text-capitalize d-inline-block'>{userImport.displayType}</span>),
  },
  {
    header: I18N('total_records_processed'),
    col: 'col-total_records_processed',
    id: 'total_records_processed',
    accessor: userImport => userImport.totalRecordsProcessed,
  },
  {
    header: I18N('failed_imports_count'),
    col: 'col-failed_imports_count',
    id: 'failed_imports_count',
    accessor: userImport => userImport.failedImportsCount,
  },
  {
    header: I18N('new_users_count'),
    col: 'col-new_users_count',
    id: 'new_users_count',
    accessor: userImport => userImport.newUsersCount,
  },
  {
    header: I18N('changed_users_count'),
    col: 'col-changed_users_count',
    id: 'changed_users_count',
    accessor: userImport => userImport.changedUsersCount,
  },
  {
    header: I18N('unchanged_users_count'),
    col: 'col-unchanged_users_count',
    id: 'unchanged_users_count',
    accessor: userImport => userImport.unchangedUsersCount,
  },
  {
    header: I18N('view_results_header'),
    col: 'col-view-results',
    accessor: userImport => (
      <Link to={`/admin/user_imports/${userImport.id}`}>
        <ButtonSmallNarrow variant='secondary'>{I18N('view_results')}</ButtonSmallNarrow>
      </Link>
    ),
  },
]

const UserImportsListPage = () => {
  const dispatch = useDispatch()
  const userImports = useSelector(userImportSlice.selectors.getUserImports())
  const { isLoading, isNotFound, queryParams } = useSelector(userImportSlice.selectors.getMetaData())

  const {
    page, totalPages, total, perPage,
  } = queryParams

  useEffect(() => {
    dispatch(userImportSlice.asyncActions.admin.fetchAll())
  }, [])

  const handleSortChange = (sortParams) => {
    dispatch(userImportSlice.asyncActions.admin.fetchAll({ ...queryParams, ...sortParams }))
  }

  const handlePaginationClick = (params) => {
    dispatch(userImportSlice.asyncActions.admin.fetchAll({ ...queryParams, ...params }))
  }

  return (
    <>
      <header className='AdminHeader'>
        <div className='d-flex justify-content-between align-items-end'>
          <h3 className='mb-1'>{I18N('user_imports')}</h3>
          <div className='d-flex flex-nowrap justify-content-between align-items-end'>
            <CreateUserImportButtons />
          </div>
        </div>
      </header>
      <main className='AdminContent'>
        <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
          <SmartTable
            className='UserImportsList white-bg-table'
            data={userImports}
            sortedAsc={false}
            sortKey='created_at'
            showPagination={page !== null && totalPages !== null}
            columns={columns}
            page={page}
            pages={totalPages}
            totalCount={total}
            perPage={perPage}
            onPaginationClick={handlePaginationClick}
            onSortChange={handleSortChange}
          />
        </TableLoadingContainer>
      </main>
    </>
  )
}

export default UserImportsListPage
