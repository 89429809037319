import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import classNames from 'classnames'
import { CONTENT, SUMMARY, SETTINGS } from 'components/admin/articles/articleOrTemplateEditor'

const I18N = i18nPath('views.admin.article_editor.missing_fields')

interface ArticleMissingFieldsPopoverProps {
  missingFields: any[]
  children: ReactElement
  errorFields?: any[]

}

const ArticleMissingFieldsPopover = ({
  missingFields, children, errorFields = [],
} : ArticleMissingFieldsPopoverProps) => {
  const history = useHistory()
  let tab
  const goTo = (field) => {
    switch (field) {
    case 'summary_social_share':
      tab = SUMMARY
      break
    case 'acknowledgement_due_at':
      tab = SETTINGS
      break
    default:
      tab = CONTENT
      break
    }

    history.push(`${history.location.pathname}?selectedTab=${tab}&scrollTo=${field}`)
  }

  const isMissingFields = !_.isEmpty(missingFields)
  const hasErrors = !_.isEmpty(errorFields)

  if (!isMissingFields && !hasErrors) { return children }

  return (
    <HoverAndClickPopover
      target={children}
    >
      <div className='p-2'>
        {isMissingFields && (
          <>
            <div className='text-smallest font-weight-600 mb-2'>
              {I18N('following_fields_are_missing')} <br />
              {I18N('click_below_to_address_them')}
            </div>

            {missingFields.map((field, index) => (
              <div
                key={`missing-${field}`}
                onClick={() => goTo(field)}
                className={classNames('text-small py-2 d-flex align-items-center pointer opacity-on-hover', index !== missingFields.length - 1 && 'bordered-bottom')}
              >
                <CdnSvg className='CircledCrossIcon' src='/images/circledCrossIcon.svg' />
                <span className='ml-2'>{I18N(field)}</span>
              </div>
            ))}
          </>
        )}

        {hasErrors && (
          <>
            <div className='text-smallest font-weight-600 mb-2'>
              {I18N('following_fields_have_errors')} <br />
              {I18N('click_below_to_address_them')}
            </div>

            {errorFields.map(field => (
              <div
                key={`error-${field}`}
                onClick={() => goTo(field)}
                className='text-small py-2 d-flex align-items-center pointer opacity-on-hover'
              >
                <CdnSvg className='CircledCrossIcon' src='/images/circledCrossIcon.svg' />
                <span className='ml-2'>{I18N(field)}</span>
              </div>
            ))}
          </>
        )}

      </div>
    </HoverAndClickPopover>
  )
}

export default ArticleMissingFieldsPopover
