import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import SearchResultOption from './searchResultOption'

const I18N = i18nPath('views.search.search_results.search_results')

const PageWorkspaceOption = ({ option }) => {
  const { pageWorkspace, highlights } = option
  const title = highlights?.title || pageWorkspace?.title
  const pagesCount = pageWorkspace?.pagesCount || 0

  return (
    <SearchResultOption
      image={
        <EmojiOrPlaceholder selectedEmoji={pageWorkspace?.emojiCode} className='mr-1' />
      }
      title={title}
      secondaryTexts={[I18N('workspace_pages_count', { count: pagesCount })]}
    />
  )
}

export default PageWorkspaceOption
