import { MessageType } from 'components/ai/chat/ask_ai_modal/types'
import useApi from 'components/common/hooks/useApi'
import React, { useRef } from 'react'
import API from 'services/api'

interface UseNewMessageProps {
  currentConversationId?: string
  setCurrentConversationId: (conversationId: string) => void
  updateLastMessageSentAt: () => void
  setMessages: React.Dispatch<React.SetStateAction<MessageType[]>>
  setIsResponding: React.Dispatch<React.SetStateAction<boolean>>
}

export const useNewMessage = ({
  currentConversationId,
  setCurrentConversationId,
  updateLastMessageSentAt,
  setMessages,
  setIsResponding,
}: UseNewMessageProps) => {
  const messageToCreateRef = useRef<string>('')

  const [createConversation, { isLoading: isCreatingConversation }] = useApi(
    API.ai.chats.conversations.create, {
      onSuccess: (data) => {
        setCurrentConversationId(data.id)
        if (messageToCreateRef.current) {
          createMessage(data.id, { content: messageToCreateRef.current })
        }
      },
    }
  )

  const [createMessage, { isLoading: isCreatingMessage }] = useApi(
    API.ai.chats.messages.create,
    {
      onSuccess: (data) => {
        updateLastMessageSentAt()
        setMessages(messages => [...messages, data])
        setIsResponding(true)
      },
    }
  )

  const onNewMessage = (content: string, { forceNewConversation = false } = {}) => {
    if (!currentConversationId || forceNewConversation) {
      messageToCreateRef.current = content
      createConversation({ title: content })
    } else {
      createMessage(currentConversationId, { content })
    }
  }

  return {
    onNewMessage,
    isCreatingConversation,
    isCreatingMessage,
  }
}

export default useNewMessage
