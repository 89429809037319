import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import newHireSlice from 'redux/slices/journeys/newHires'
import { ButtonSecondarySmall } from 'components/common/buttons'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.buddies_table')

const AssignButton = ({ onboardingBuddy, newHire }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const assignBuddy = () => {
    setIsLoading(true)

    const onSuccess = () => {
      dispatch(newHireSlice.actions.removeNewHireId(newHire.id))
    }

    const onFinally = () => {
      setIsLoading(false)
    }

    const params = { onboardingBuddyId: onboardingBuddy.id, newHireId: newHire.id }

    dispatch(onboardingBuddySlice.asyncActions.admin.assign(params, onSuccess, onFinally))
  }

  return (
    <ButtonSecondarySmall
      onClick={() => assignBuddy()}
      showLoadingSpinner={isLoading}
      disabled={!newHire}
    >
      {I18N('assign')}
    </ButtonSecondarySmall>
  )
}

export default AssignButton
