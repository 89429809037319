import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import CompanyLogo from 'components/common/companyLogo'
import CancelButton from 'components/common/cancelButton'
import { Button } from 'components/common/buttons'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import pageSlice from 'redux/slices/pages'

const I18N = i18nPath('views.pages.actions_dropdown.version_history')

const RestoreSnapshotButtonAndModal = ({
  page,
  snapshot,
  closeVersionHistoryView,
}) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isSaving } = useSelector(pageSlice.selectors.getPagesMetadata())

  const handleRestore = () => {
    const onSuccess = () => {
      setIsModalOpen(false)
      closeVersionHistoryView()
    }

    dispatch(pageSlice.asyncActions.restoreFromSnapshot(page.id, snapshot.id, onSuccess))
  }

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        disabled={!snapshot}
      >
        {I18N('restore_version')}
      </Button>
      <ConfirmationModal
        visible={isModalOpen}
        title={I18N('restore_version')}
        description={I18N('restore_version_description')}
        toggle={() => setIsModalOpen(false)}
        isSaving={isSaving}
        onConfirmClick={handleRestore}
      />
    </>
  )
}

const VersionHistoryToolbar = ({
  page,
  snapshot,
  closeVersionHistoryView,
}) => (
  <div className='replace-navbar px-0 d-flex flex-row-reverse justify-content-between d-print-none'>
    <div className='pr-2 pr-sm-3 gap-1 gap-sm-3 d-flex align-items-center justify-content-end h-100'>
      <CancelButton onClick={closeVersionHistoryView}>
        {I18N('back_to_editing')}
      </CancelButton>
      <RestoreSnapshotButtonAndModal
        snapshot={snapshot}
        page={page}
        closeVersionHistoryView={closeVersionHistoryView}
      />
    </div>
    <CompanyLogo className='Navbar-companyImage px-3 pb-2 pt-3' />
  </div>
)

export default VersionHistoryToolbar
