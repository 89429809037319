import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice, { defaultWorkingCopy } from 'redux/slices/surveys/forms'
import audienceSlice from 'redux/slices/audiences'
import { useParams, useHistory } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useQueryParams from 'components/common/hooks/useQueryParams'
import QuestionStatsCard from 'components/admin/surveys/questions/questionStatsCard'
import FormResponsesTabsAndTable from 'components/admin/surveys/responses/formResponsesTabsAndTable'
import FormResponsesStatsBanner from 'components/admin/surveys/responses/formResponsesStatsBanner'

import QuestionsEditSection from 'components/admin/surveys/questionsEditSection'
import RecipientsAndInstructionsSection from 'components/admin/surveys/recipientsAndInstructionsSection'
import SchedulingSection from 'components/admin/surveys/schedulingSection'
import FormHeader, {
  POSSIBLE_TABS,
  SCHEDULING_TAB,
  RESULTS_TAB,
  QUESTIONS_TAB,
  INDIVIDUAL_RESPONSES_TAB,
  RECIPIENTS_TAB
}
  from 'components/admin/surveys/formHeader'
import usePrevious from 'components/common/hooks/usePrevious'
import getDiff from 'utils/getDiff'

const I18N = i18nPath('views.admin.surveys')

const EditFormPage = ({
  setIsDesktopNavOpen = (value: boolean) => {},
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { formId } = useParams()

  const { selectedTab } = useQueryParams()
  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)

  const form = useSelector(surveyFormSlice.selectors.getForm(formId))
  const previousForm = usePrevious(form)
  const { isLoading, isSaving, error } = useSelector(surveyFormSlice.selectors.getMetaData())

  const formStatus = form?.status
  const questions = form?.questions || []

  const updateWorkingCopy = (changes: any) => {
    setWorkingCopy({ ...workingCopy, ...changes })
  }

  useEffect(() => {
    setIsDesktopNavOpen(false)
    dispatch(surveyFormSlice.asyncActions.admin.fetch(formId))

    return () => {
      if (!history.location.pathname.includes('/admin/surveys')) {
        setIsDesktopNavOpen(true)
      }
    }
  }, [formId])

  useEffect(() => {
    const diff = getDiff(previousForm, form)
    updateWorkingCopy(diff)
    // We use stringify to refresh the working copy when coming from the List/Preview page
  }, [form?.updatedAt, JSON.stringify(questions), form?.interpolatedContent])


  useEffect(() => {
    if (formStatus && !POSSIBLE_TABS.includes(selectedTab)) {
      const newParams = formStatus !== 'draft' ? '?selectedTab=results' : '?selectedTab=questions'
      history.replace(`/admin/surveys/${formId}${newParams}`)
    }
  }, [formStatus])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (!_.isEmpty(error) && error.type === 'unauthorized') {
    return (
      <div
        className='EditFormPage container mt-5 text-secondary text-center'
      >
        {error.message}
      </div>
    )
  }

  return (
    <div className='EditFormPage d-flex align-items-center flex-column justify-content-center w-100'>
      <FormHeader
        form={form}
        workingCopy={workingCopy}
        isSaving={isSaving}
      />
      <main className='AdminContent w-100'>
        {selectedTab === QUESTIONS_TAB && (
          <QuestionsEditSection
            form={form}
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
          />
        )}
        {selectedTab === RECIPIENTS_TAB && (
          <RecipientsAndInstructionsSection workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} />
        )}
        {selectedTab === SCHEDULING_TAB && (
          <>
            <h6 className='text-secondary'>{I18N('scheduling')}</h6>
            <SchedulingSection
              workingCopy={workingCopy}
              updateWorkingCopy={updateWorkingCopy}
            />
          </>
        )}
        {selectedTab === RESULTS_TAB && (
          <div>
            <FormResponsesStatsBanner
              form={form}
            />
            {(form.questions || []).map((question, index) => (
              <QuestionStatsCard
                className='mb-3'
                key={question.id}
                index={index}
                question={question}
              />
            ))}
          </div>
        )}
        {selectedTab === INDIVIDUAL_RESPONSES_TAB && (
          <FormResponsesTabsAndTable form={form} />
        )}
      </main>
    </div>
  )
}

export default EditFormPage
