import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.box_shadow')

const NavbarBoxShadow = () => (
  <div className='Navbar'>
    <div className='Navbar-subnav'>
      <div className='Navbar-item ml-3'>
        <a aria-current='false' href='#'>
          {I18N('nav_item_1')}
        </a>
      </div>
      <div className='Navbar-item'>
        <a aria-current='false' href='#'>
          {I18N('nav_item_2')}
        </a>
      </div>
      <div className='Navbar-item'>
        <a aria-current='false' href='#'>
          {I18N('nav_item_3')}
        </a>
      </div>
    </div>
  </div>
)

export default NavbarBoxShadow
