import React, { useMemo } from 'react'

import FormErrorList from 'components/errors/formErrorList'
import ReactSelect from 'components/common/react_select'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import EditableUserContent from 'components/common/editable_content/editableUserContent'
import { useCityTargetingOptions } from 'hooks/audience/useTargetingOptions'

const STANDARD = 'standard'
const COMPANY_AGE = 'company_age'
const LOCATION = 'location'

const I18N = i18nPath('views.badges.settings')

const BADGE_TYPES = [
  { id: STANDARD, value: STANDARD, text: I18N('standard') },
  { id: COMPANY_AGE, value: COMPANY_AGE, text: I18N('company_age') },
  { id: LOCATION, value: LOCATION, text: I18N('location') },
]

const ConfigurationSettings = ({
  workingCopy,
  setWorkingCopy,
  isBadgeManager,
  onDelete,
  error,
}) => {
  const selectedBadgeType = BADGE_TYPES.find(badgeType => badgeType.value === workingCopy?.type)
  const isCompanyAgeBadge = workingCopy.type === COMPANY_AGE
  const isLocationBadge = workingCopy.type === LOCATION

  const cityOptions = useCityTargetingOptions()
  const selectedLocations = useMemo(
    () => cityOptions.filter(({ value }) => (workingCopy.locations || []).includes(value)),
    [workingCopy.locations]
  )

  const updateWorkingCopy = (values = {}) => {
    setWorkingCopy({ ...workingCopy, ...values })
  }

  const generateSelectChangeHandler = fieldName => (selectedOption) => {
    const newValue = _.isArray(selectedOption) ? selectedOption.map(obj => obj.value) : selectedOption.value

    updateWorkingCopy({ [fieldName]: newValue })
  }

  const generateChangeHandler = fieldName => (e) => {
    updateWorkingCopy({ [fieldName]: e.target.value })
  }

  const handleSendNotificationsChange = (event) => {
    updateWorkingCopy({ sendNotifications: event.target.checked })
  }

  const handleOwnerChange = (user) => {
    updateWorkingCopy({ owner: user, ownerId: user.id })
  }

  return (
    <div className='BadgeConfigurationSettings'>
      <div className='BadgeOwner'>
        <span className='font-weight-500'>{I18N('badge_owner')}</span>
        <div>
          <EditableUserContent
            setUser={handleOwnerChange}
            user={workingCopy.owner}
            canEdit={true}
          />
        </div>
      </div>

      {isBadgeManager && (
        <>
          <div className='SettingsInfo'>
            {error && <FormErrorList error={error} />}
            <span className='font-weight-500'>{I18N('header')}</span>
            <p>{I18N('description')}</p>
          </div>

          <div className='BadgeType'>
            <div className='form-group BadgeTypeSelect'>
              <label className='font-weight-500'>{I18N('trigger')}</label>
              <ReactSelect
                value={selectedBadgeType}
                onChange={generateSelectChangeHandler('type')}
                options={BADGE_TYPES}
                getOptionLabel={badge => badge.text}
                isSearchable={false}
              />
              {(isCompanyAgeBadge || isLocationBadge) && (
                <span className='text-small text-secondary mt-1'>{I18N('type_information')}</span>
              )}
            </div>
            {isCompanyAgeBadge && (
              <div className='form-group'>
                <label className='font-weight-500'>{I18N('num_years')}</label>
                <input type='number' min={1} value={workingCopy.numYears || ''} onChange={generateChangeHandler('numYears')} />
                <span className='text-small text-secondary mt-1'>{I18N('company_age_information')}</span>
              </div>
            )}
            {isLocationBadge && (
              <div className='form-group LocationSelect'>
                <label className='font-weight-500'>{I18N('location')}</label>
                <ReactSelect
                  value={selectedLocations}
                  isMulti
                  onChange={generateSelectChangeHandler('locations')}
                  options={cityOptions}
                />
              </div>
            )}
          </div>
        </>
      )}

      <div className='Notifications'>
        <span className='font-weight-500'>{I18N('notifications')}</span>
        <label className='form-check'>
          <input type='checkbox' checked={workingCopy.sendNotifications} onChange={handleSendNotificationsChange} />
          {I18N('send_notifications')}
        </label>
      </div>

      <div className='DeleteBadge'>
        <span className='font-weight-500'>{I18N('delete_badge')}</span>
        <p>{I18N('delete_warning')}</p>
        <Button variant='secondary-danger' onClick={onDelete}>{I18NCommon('delete')}</Button>
      </div>
    </div>
  )
}

export default ConfigurationSettings
