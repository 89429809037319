import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import requestedBadgeSlice from 'redux/slices/requestedBadges'

import Modal from 'components/common/modal'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.badges.request')

interface PendingRequestModalProps {
  visible: boolean,
  toggle: () => void,
  badgeId: string,
}

const RequestBadgeModal = ({
  visible = false,
  toggle,
  badgeId,
}: PendingRequestModalProps) => {
  const dispatch = useDispatch()
  const [justification, setJustification] = useState('')

  const { isSaving } = useSelector(requestedBadgeSlice.selectors.getMetaData())

  const requestBadge = () => {
    dispatch(requestedBadgeSlice.asyncActions.create({ badgeId, justification }, toggle))
  }

  return (
    <Modal
      size='md'
      className='RequestBadgeModal'
      visible={visible}
      toggle={toggle}
    >
      <div className='Content'>
        <h4>{I18N('modal_title')}</h4>
        <textarea
          value={justification}
          placeholder={I18N('placeholder')}
          onChange={e => setJustification(e.target.value)}
        />
        <div className='ActionButtons'>
          <div role='button' tabIndex={0} onClick={toggle} className='text-muted-dark'>
            {I18NCommon('cancel')}
          </div>
          <Button onClick={requestBadge} showLoadingSpinner={isSaving}>{I18NCommon('submit')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default RequestBadgeModal
