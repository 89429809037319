import React, { useState } from 'react'

import { Button } from 'components/common/buttons'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { useDispatch } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CheckBox from 'components/common/buttons/checkBox'
import { buildCompanyConfigurationPayload } from 'redux/slices/companyConfigurations'
import GroupsSection from 'components/admin/other_settings/people/groupsSection'
import ShoutoutsSection from 'components/admin/other_settings/people/shoutoutsSection'

const I18N = i18nPath('views.admin.other_settings.people_tab')

const syncedValues = {
  'people.enabled': [
    'orgChart.enabled',
    'celebrations.enabled',
    'groups.enabled',
    'shoutouts.enabled',
    'badges.enabled',
  ],
  'celebrations.enabled': [
    'celebrations.birthdays.enabled',
    'celebrations.socialShare',
    'celebrations.workAnniversaries.enabled',
    'celebrations.manualCelebrations.enabled',
    'celebrations.newEmployees.enabled',
    'celebrations.deliverCardsWithNoSignatures',
  ],
  'celebrations.newEmployees.enabled': [
    'celebrations.newEmployees.sendWelcomeNotificationToNewEmployee',
  ],
  'celebrations.birthdays.enabled': [
    'celebrations.birthdays.showBirthdaysOfUsersWithoutSoftLaunchAccess',
    'celebrations.birthdays.onlyShowBirthdaysOfUsersWhoHaveLoggedIn',
    'celebrations.birthdays.usersMustOptIn',
  ],
  'badges.enabled': [
    'comments.awardedBadges.enabled',
  ],
}

// skip sync truthy values for these keys since the default value is false
const skipSyncTruthyValuesKeys = [
  'celebrations.birthdays.showBirthdaysOfUsersWithoutSoftLaunchAccess',
  'celebrations.birthdays.onlyShowBirthdaysOfUsersWhoHaveLoggedIn',
  'celebrations.birthdays.usersMustOptIn',
  'celebrations.newEmployees.sendWelcomeNotificationToNewEmployee',
]

// { 'orgChart.enabled': 'people.enabled', 'celebrations.enabled': 'people.enabled', ... }
const reverseSyncedValues = _.reduce(syncedValues, (acc, value, key) => {
  value.forEach((v) => {
    acc[v] = key
  })
  return acc
}, {})

const SETTINGS_KEYS_TO_UPDATE = [
  'people',
  'orgChart',
  'celebrations',
  'groups',
  'shoutouts',
  'badges',
  'comments',
]

const PeopleTab = () => {
  const dispatch = useDispatch()

  const currentCompany = useCurrentCompany()

  const [isSaving, setIsSaving] = useState(false)
  const [workingCopy, setWorkingCopy] = useState<any>(currentCompany.settings)
  const [groupDisplayName, setGroupDisplayName] = useState(currentCompany.groupDisplayName)


  const handleSaveChanges = async () => {
    setIsSaving(true)
    try {
      const promises = SETTINGS_KEYS_TO_UPDATE.map(
        key => API.admin.companyConfigurations.update(
          buildCompanyConfigurationPayload(_.snakeCase(key), workingCopy[key])
        )
      )

      if (groupDisplayName !== currentCompany.groupDisplayName) {
        await API.admin.company.update({ id: currentCompany.id, groupDisplayName })
      }

      await Promise.all(promises)

      dispatch(showToastMessage({ message: I18NCommon('update_successfully'), type: 'success' }))
      window.location.reload()
    } catch (error) {
      dispatch(showToastMessage({ message: I18NCommon('update_failed'), type: 'error' }))
      setIsSaving(false)
    }
  }

  const connect = (fieldPath: string) => ({
    onChange: (value) => {
      setWorkingCopy((oldValues) => {
        const newValues = { ...oldValues }
        _.set(newValues, fieldPath, value)

        const syncedKeys: string[] = []

        const generateSyncedKeysRecursively = (key) => {
          if (syncedValues[key]) {
            syncedKeys.push(...syncedValues[key])
            syncedValues[key].forEach(generateSyncedKeysRecursively)
          }
        }

        generateSyncedKeysRecursively(fieldPath)

        syncedKeys.forEach((key) => {
          // we want to sync keys only if value is false (disabling the key)
          // or if value is true but the key is not in skipSyncTruthyValuesKeys
          if (value === false || !skipSyncTruthyValuesKeys.includes(key)) _.set(newValues, key, value)
        })

        return newValues
      })
    },
    value: _.get(workingCopy, fieldPath),
    checked: _.get(workingCopy, fieldPath),
    disabled: reverseSyncedValues[fieldPath] ? !_.get(workingCopy, reverseSyncedValues[fieldPath]) : false,
  })

  return (
    <section className='PeopleTab'>
      <div className='white-card p-3'>
        <CheckBox
          id='peopleEnabled'
          label={I18N('people_enabled')}
          helpText={I18N('people_help_text')}
          helpTextClassName='mb-5 d-block'
          {...connect('people.enabled')}
        />

        <h3 className='mt-5'>{I18N('other_modules')}</h3>

        <CheckBox
          id='orgChartEnabled'
          label={I18N('org_chart')}
          {...connect('orgChart.enabled')}
        />

        <h3 className='mt-5'>{I18N('badges')}</h3>

        <CheckBox
          id='badges'
          label={I18N('badges')}
          {...connect('badges.enabled')}
        />

        <CheckBox
          id='commentsAwardedBadges'
          label={I18N('enable_comments_in_awarded_badges')}
          {...connect('comments.awardedBadges.enabled')}
        />

        <ShoutoutsSection
          connect={connect}
          shoutoutsSettings={workingCopy.shoutouts}
        />

        <GroupsSection
          connect={connect}
          groupDisplayName={groupDisplayName}
          setGroupDisplayName={setGroupDisplayName}
          areGroupsEnabled={workingCopy.groups.enabled}
        />

        <h3 className='mt-5'>{I18N('celebrations')}</h3>

        <CheckBox
          id='celebrations'
          label={I18N('celebrations')}
          {...connect('celebrations.enabled')}
        />

        <CheckBox
          id='manualCelebrationsEnabled'
          label={I18N('manual_celebrations')}
          helpText={I18N('manual_celebrations_help_text')}
          helpTextClassName='mb-3 d-block'
          {...connect('celebrations.manualCelebrations.enabled')}
        />

        <CheckBox
          id='celebrationsSocialShare'
          label={I18N('social_share_on_celebrations')}
          helpText={I18N('social_share_on_celebrations_help_text')}
          helpTextClassName='mb-3 d-block'
          {...connect('celebrations.socialShare')}
        />

        <CheckBox
          id='celebrationsDeliverCardsWithNoSignatures'
          label={I18N('deliver_cards_with_no_signatures')}
          helpText={I18N('deliver_cards_with_no_signatures_help_text')}
          helpTextClassName='mb-3 d-block'
          {...connect('celebrations.deliverCardsWithNoSignatures')}
        />

        <h5 className='mt-4'>{I18N('birthdays')}</h5>

        <CheckBox
          id='birthdays'
          label={I18N('birthdays')}
          helpText={I18N('birthdays_help_text')}
          helpTextClassName='mb-3 d-block'
          {...connect('celebrations.birthdays.enabled')}
        />

        <CheckBox
          id='showBirthdaysOfUsersWithoutSoftLaunchAccess'
          label={I18N('show_birthdays_of_users_without_soft_launch_access')}
          {...connect('celebrations.birthdays.showBirthdaysOfUsersWithoutSoftLaunchAccess')}
        />

        <CheckBox
          id='onlyShowBirthdaysOfUsersWhoHaveLoggedIn'
          label={I18N('only_show_birthdays_of_users_who_have_logged_in')}
          helpText={I18N('only_show_birthdays_of_users_who_have_logged_in_help_text')}
          helpTextClassName='mb-3 d-block'
          {...connect('celebrations.birthdays.onlyShowBirthdaysOfUsersWhoHaveLoggedIn')}
        />

        <CheckBox
          id='birthdaysUsersMustOptIn'
          label={I18N('users_must_opt_in_for_birthdays')}
          helpText={I18N('users_must_opt_in_for_birthdays_help_text')}
          helpTextClassName='mb-3 d-block'
          {...connect('celebrations.birthdays.usersMustOptIn')}
        />

        <h5 className='mt-4'>{I18N('new_employees')}</h5>

        <CheckBox
          id='celebrationsNewEmployees'
          label={I18N('new_employees')}
          helpText={I18N('new_employees_help_text')}
          helpTextClassName='mb-3 d-block'
          {...connect('celebrations.newEmployees.enabled')}
        />

        <CheckBox
          id='sendWelcomeNotificationToNewEmployee'
          label={I18N('send_welcome_notification_to_new_employee')}
          {...connect('celebrations.newEmployees.sendWelcomeNotificationToNewEmployee')}
        />

        <h5 className='mt-4'>{I18N('work_anniversaries')}</h5>

        <CheckBox
          id='workAnniversaries'
          label={I18N('work_anniversaries')}
          helpText={I18N('work_anniversaries_help_text')}
          helpTextClassName='mb-3 d-block'
          {...connect('celebrations.workAnniversaries.enabled')}
        />
      </div>

      <div className='d-flex justify-content-end mt-5'>
        <Button
          onClick={handleSaveChanges}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('save_changes')}
        </Button>
      </div>
    </section>
  )
}

export default PeopleTab
