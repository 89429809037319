import React from 'react'

type Props = {
  title: string
  rightContent?: React.ReactNode
}

const WidgetTitle = ({ title, rightContent }: Props) => {
  if (!title) return null

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <h5 className='mb-0 font-weight-700'>{title}</h5>
        {rightContent}
      </div>
      <hr className='w-100' />
    </>
  )
}

export default WidgetTitle
