import { createSlice } from '@reduxjs/toolkit'
import { checkForError } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import API from 'services/api'
import appSignal from 'services/appSignal'

export type IntegrationCredentialState = {
  meta: DefaultMetaType
}

export const initialState: IntegrationCredentialState = {
  meta: {
    ...defaultMeta,
  },
}

const integrationCredentialSlice = createSlice({
  name: 'integrationCredentials',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

const asyncActions = {
  admin: {
    create: (
      params,
      { onSuccess, onError }: {onSuccess?: () => void, onError?: (e: any) => void} = {}
    ) => async (dispatch) => {
      dispatch(integrationCredentialSlice.actions.isSaving(true))

      try {
        await API.admin.integrationCredentials.create(params)

        if (onSuccess) onSuccess()
      } catch (e) {
        if (onError) onError(e)
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(integrationCredentialSlice.actions.setError(error))
      } finally {
        dispatch(integrationCredentialSlice.actions.isSaving(false))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.integrationCredentials.meta,
}

export default {
  ...integrationCredentialSlice,
  selectors,
  asyncActions,
}
