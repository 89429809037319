import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import itAdminSlice from 'redux/slices/itAdmin'
import FormEditableField from 'components/form_fields/formEditableField'

const I18N = i18nPath('views.integrations_page.integration_card.ashby')

const AshbyInstallationComponent = () => {
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const { isSaving } = useSelector(itAdminSlice.selectors.getMetaData())

  const installAshby = () => {
    dispatch(itAdminSlice.asyncActions.admin.installAshby({
      apiKey,
    }))
  }

  const isDisabled = isSaving || _.isEmpty(apiKey)

  if (!showForm) {
    return (
      <div className='mb-2'>
        <ButtonSmallNarrow
          onClick={() => setShowForm(true)}
        >
          {I18N('configure')}
        </ButtonSmallNarrow>
      </div>
    )
  }

  return (
    <>
      <div className='mb-2'>
        <FormEditableField
          className='d-block w-100 mb-2 AshByFieldContainer'
          currentValue={apiKey}
          fieldName='apiKey'
          label={I18N('api_key')}
          onChange={({ target }) => setApiKey(target.value)}
          isRequired
        />

        <ButtonSmallNarrow
          onClick={installAshby}
          disabled={isDisabled}
          showLoadingSpinner={isSaving}
          id='save-ashby-btn'
        >
          {I18n.t('views.integrations_page.integration_card.save')}
        </ButtonSmallNarrow>
      </div>

    </>
  )
}

export default AshbyInstallationComponent
