import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

interface Props {
  employees: any[]
}

const MultipleEmployeesName: React.FC<Props> = ({ employees }) => {
  const validEmployees = _.uniqBy(employees.filter(e => !!e), 'id')

  return (
    <>
      {validEmployees.length === 0 && <span>{I18NCommon('unknown')}</span>}
      {validEmployees.map((employee, index) => (
        <img
          key={index}
          src={employee.primaryPhotoThumbnailUrl}
          className='bordered-thumbnail mx-1'
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
      ))}
      {validEmployees.length === 1 && (
        <span className='text-small'>{validEmployees[0]?.preferredName}</span>
      )}
    </>
  )
}

export default MultipleEmployeesName
