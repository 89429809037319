import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import pickWithTargetingRules from 'redux/slices/utils/pickWithTargetingRules'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { PaginatedDataType } from 'types/analytics/helpers'
import {
  ArticlesPublishedType, EngagingArticleType, EngagingAuthorType, NewsEngagementDataType, NewsSummaryDataType
} from 'types/analytics/news'

const buildMostEngagingArticlesPayload = params => _.pick(params, [
  'periodStart',
  'periodEnd',
  'perPage',
  'page',
])

const buildMostEngagingAuthorsPayload = params => _.pick(params, [
  'periodStart',
  'periodEnd',
  'perPage',
  'page',
])

const buildSummaryPayload = params => _.pick(params, [
  'periodStart',
  'periodEnd',
])

const buildEngagementPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
  'groupBy',
  'type',
  'groupByEventType',
])

const buildArticlesPublishedPayload = params => _.pick(params, [
  'periodStart',
  'periodEnd',
  'groupBy',
  'articleType',
])

const defaultSummary = {
  articlesPublishedCount: 0,
  usersEngagedWithArticlePercent: 0,
  averageAudienceReachPercent: 0,
  averageAudienceEmailOpensPercent: 0,
}

const defaultEmptyArrayData = {
  data: [],
}

const defaultPaginatedData = {
  data: [],
  page: 0,
  totalItems: 0,
  totalPages: 0,
}

export interface NewsAnalyticsState {
  summary: NewsSummaryDataType,
  mostEngagingArticles: PaginatedDataType<EngagingArticleType>,
  mostEngagingAuthors: PaginatedDataType<EngagingAuthorType>,
  engagement: {
    data: NewsEngagementDataType[]
  }
  engagementCombined: {
    data: NewsEngagementDataType[]
  }
  articlesPublished: {
    data: ArticlesPublishedType[]
  }
  meta: {
    summary: DefaultMetaType
    engagement: DefaultMetaType
    engagementCombined: DefaultMetaType
    mostEngagingArticles: DefaultMetaType
    mostEngagingAuthors: DefaultMetaType
    articlesPublished: DefaultMetaType
  }
}

export const initialState: NewsAnalyticsState = {
  summary: defaultSummary,
  mostEngagingArticles: defaultPaginatedData,
  mostEngagingAuthors: defaultPaginatedData,
  engagement: defaultEmptyArrayData,
  engagementCombined: defaultEmptyArrayData,
  articlesPublished: defaultPaginatedData,
  meta: {
    summary: defaultMeta,
    engagement: defaultMeta,
    engagementCombined: defaultMeta,
    mostEngagingArticles: defaultMeta,
    mostEngagingAuthors: defaultMeta,
    articlesPublished: defaultMeta,
  },
}

const newsAnalyticsSlice = createSlice({
  name: 'newsAnalytics',
  initialState,
  reducers: {
    setData(state, action) {
      const { type, data } = action.payload
      state[type] = data
    },

    setIsLoading(state, action) {
      const { type, isLoading } = action.payload
      state.meta[type].isLoading = isLoading
    },

    setError(state, action) {
      const { type, error } = action.payload
      state.meta[type].error = error
    },
  },
})

const asyncActions = {
  admin: {
    fetchArticlesPublished: (params = {}) => async (dispatch) => {
      try {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type: 'articlesPublished', isLoading: true }))

        const response = await API.admin.analytics.news.articlesPublished(buildArticlesPublishedPayload(params))

        dispatch(newsAnalyticsSlice.actions.setData({ type: 'articlesPublished', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(newsAnalyticsSlice.actions.setError({ type: 'articlesPublished', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type: 'articlesPublished', isLoading: false }))
      }
    },

    fetchMostEngagingArticles: (params = {}) => async (dispatch) => {
      try {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type: 'mostEngagingArticles', isLoading: true }))

        const response = await API.admin.analytics.news.mostEngagingArticles(buildMostEngagingArticlesPayload(params))

        dispatch(newsAnalyticsSlice.actions.setData({ type: 'mostEngagingArticles', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(newsAnalyticsSlice.actions.setError({ type: 'mostEngagingArticles', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type: 'mostEngagingArticles', isLoading: false }))
      }
    },

    fetchMostEngagingAuthors: (params = {}) => async (dispatch) => {
      try {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type: 'mostEngagingAuthors', isLoading: true }))

        const response = await API.admin.analytics.news.mostEngagingAuthors(buildMostEngagingAuthorsPayload(params))

        dispatch(newsAnalyticsSlice.actions.setData({ type: 'mostEngagingAuthors', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(newsAnalyticsSlice.actions.setError({ type: 'mostEngagingAuthors', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type: 'mostEngagingAuthors', isLoading: false }))
      }
    },

    fetchSummary: (params = {}) => async (dispatch) => {
      try {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type: 'summary', isLoading: true }))

        const response = await API.admin.analytics.news.summary(buildSummaryPayload(params))

        dispatch(newsAnalyticsSlice.actions.setData({ type: 'summary', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(newsAnalyticsSlice.actions.setError({ type: 'summary', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type: 'summary', isLoading: false }))
      }
    },

    fetchEngagement: (type: 'engagement' | 'engagementCombined', params: any = {}) => async (dispatch) => {
      try {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type, isLoading: true }))

        const response = await API.admin.analytics.news.engagement(buildEngagementPayload(params))

        dispatch(newsAnalyticsSlice.actions.setData({ type, data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(newsAnalyticsSlice.actions.setError({ type, error: getResponseOrThrow(error) }))
      } finally {
        dispatch(newsAnalyticsSlice.actions.setIsLoading({ type, isLoading: false }))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.newsAnalytics.meta,

  getMostEngagingArticles: () => (state: ReduxState) => state.newsAnalytics.mostEngagingArticles,

  getMostEngagingAuthors: () => (state: ReduxState) => state.newsAnalytics.mostEngagingAuthors,

  getSummary: () => (state: ReduxState) => state.newsAnalytics.summary,

  getArticlesPublished: () => (state: ReduxState) => state.newsAnalytics.articlesPublished,

  getEngagement: () => (state: ReduxState) => state.newsAnalytics.engagement,

  getEngagementCombined: () => (state: ReduxState) => state.newsAnalytics.engagementCombined,
}

export default {
  ...newsAnalyticsSlice,
  selectors,
  asyncActions,
}
