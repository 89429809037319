import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'

const countCharactersInHtmlString = (htmlString) => {
  if (isHtmlStringEmpty(htmlString)) {
    return 0
  }

  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  return doc.body.textContent?.length || 0
}

export default countCharactersInHtmlString
