import React from 'react'
import { useDispatch } from 'react-redux'

import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import { trackAmplitudeEvent } from 'services/tracker'

import QuestionVotingSectionDesktop from 'components/qna/questionVotingSectionDesktop'
import QuestionVotingSectionMobile from 'components/qna/questionVotingSectionMobile'

const QuestionVotingSection = ({ event, question, isMobile }) => {
  const dispatch = useDispatch()

  const isVotingEnabled = event.isActive && question.isApproved

  const { hasUpvoteFromCurrentUser, hasDownvoteFromCurrentUser } = question

  const voteQuestionHandler = async (question, type) => {
    // dont allow voting for inactive events
    if (!question.event.isActive || !question.isApproved) {
      return
    }

    trackAmplitudeEvent(`question_${type}d`, {
      eventId: question.event.id,
      title: question.event.title,
      questionId: question.id,
      question_creator: question.creator?.id || 0, // Anonymous questions don't have a creator object
      upvotes_count: question.upvotesCount,
    })

    // Types: downvoteQuestion, unvoteQuestion, upvoteQuestion
    dispatch(qnaQuestionSlice.asyncActions[`${type}Question`](question))
  }

  const upvoteHandler = e => voteQuestionHandler(question, 'upvote')
  const downvoteHandler = e => voteQuestionHandler(question, 'downvote')
  const unvoteHandler = e => voteQuestionHandler(question, 'unvote')
  const voteScoreText = question.isApproved ? question.voteScore : '...'

  const VotingSection = isMobile ? QuestionVotingSectionMobile : QuestionVotingSectionDesktop

  return (
    <VotingSection
      question={question}
      isVotingEnabled={isVotingEnabled}
      hasUpvoteFromCurrentUser={hasUpvoteFromCurrentUser}
      hasDownvoteFromCurrentUser={hasDownvoteFromCurrentUser}
      upvoteHandler={upvoteHandler}
      downvoteHandler={downvoteHandler}
      unvoteHandler={unvoteHandler}
      voteScoreText={voteScoreText}
    />
  )
}

export default QuestionVotingSection
