import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import { useHistory } from 'react-router-dom'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import React from 'react'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import { useDispatch } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'

const I18N = i18nPath('views.admin.article_list.item_dropdown.template')


const CreateNewArticleTemplateDropdownMenuItem = ({ templateId }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [createArticle, { isLoading }] = useApi(API.admin.articles.create, {
    toastSuccessMessage: I18N('create_article_success_message'),
    onError: (error) => {
      const errorMessage = error === 'no_new_employees_for_article' ? I18N('no_new_employees_for_article') : I18N('create_article_error_message')

      dispatch(showToastMessage({ message: errorMessage, type: 'error' }))
    },
    onSuccess: (data) => {
      // allow any state to be executed since it'll unmount this component and we'll have a
      // warning in console of type "Can't perform a React state on an unmounted component"
      setTimeout(() => {
        history.push(`/admin/articles/${data.id}`)
      })
    },
  })

  const onClick = () => {
    createArticle({ templateId })
  }

  return (
    <CardDropdownMenuItem
      onClick={onClick}
      primaryText={I18N('create_new_article')}
      disabled={isLoading}
      icon={<CdnSvg src='/images/addFileIcon.svg' className='mr-2' />}
    />
  )
}

export default CreateNewArticleTemplateDropdownMenuItem
