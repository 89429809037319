import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import qnaEventSlice from 'redux/slices/qnaEvents'

import Modal from 'components/common/modal'
import CreateEventFields from 'components/qna/event_creation_steps/createEventFields'
import TemplateSelector from 'components/qna/event_creation_steps/templateSelector'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const CREATION_STEPS = {
  TEMPLATE_LIST: 'TEMPLATE_LIST',
  EVENT_FORM: 'EVENT_FORM',
}

const CreateEventModal = ({ showModal = false, onCloseModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { settings } = useCurrentCompany()

  const [event, setEvent] = useState({
    title: '',
    eventTime: null,
    targetingRules: TARGET_ENTIRE_COMPANY,
    accessLevel: 'public_access',
    videoConferenceLink: null,
    generateRecapArticle: false,
  })

  const [activeCreationStep, setActiveCreationStep] = useState(CREATION_STEPS.EVENT_FORM)
  const meta = useSelector(qnaEventSlice.selectors.getEventMetaData())
  const { isSaving } = meta
  const isButtonDisabled = isSaving || event.title === '' || event.eventTime === null

  const saveEvent = async () => {
    dispatch(qnaEventSlice.asyncActions.admin.create(event, history))
  }

  const setEventTimeToNow = (e) => {
    e.preventDefault()
    setEvent({ ...event, eventTime: moment().format() })
  }

  const eventTimeChangeHandler = (eventTimeMomentObject) => {
    const newTimes = { eventTime: eventTimeMomentObject && eventTimeMomentObject.format() }

    setEvent({ ...event, ...newTimes })
  }

  useEffect(() => {
    setActiveCreationStep(CREATION_STEPS.TEMPLATE_LIST)
  }, [])

  const handleCloseModal = () => {
    setActiveCreationStep(CREATION_STEPS.TEMPLATE_LIST)
    onCloseModal()
  }

  return (
    <Modal
      className='CreateEventModal'
      visible={showModal}
      toggle={handleCloseModal}
      fullscreen
    >
      {activeCreationStep === CREATION_STEPS.TEMPLATE_LIST && (
        <TemplateSelector
          event={event}
          setEvent={setEvent}
          setEventFormAsActiveStep={() => setActiveCreationStep(CREATION_STEPS.EVENT_FORM)}
        />
      )}
      {activeCreationStep === CREATION_STEPS.EVENT_FORM && (
        <CreateEventFields
          event={event}
          setEvent={setEvent}
          saveEvent={saveEvent}
          isButtonDisabled={isButtonDisabled}
          setEventTimeToNow={setEventTimeToNow}
          eventTimeChangeHandler={eventTimeChangeHandler}
          setTemplatesAsActiveStep={() => setActiveCreationStep(CREATION_STEPS.TEMPLATE_LIST)}
          showVideoConferenceLink={settings.zoom?.enabled}
        />
      )}
    </Modal>
  )
}

export default CreateEventModal
