import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext } from 'react-beautiful-dnd'

import { i18nPath } from 'utils/i18nHelpers'
import DroppableSettingsSection from 'components/settings/droppableSettingsSection'
import Tag from 'components/common/tag'
import tagSlice from 'redux/slices/tags'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import settingSlice from 'redux/slices/settings'

const I18N = i18nPath('views.settings.news_section')

const EXCLUDED_TAGS_SETTING = 'news.excluded_tag_ids'
const SUBSCRIBED_TAGS_SETTING = 'news.subscribed_tag_ids'

const NewsSettingsSection = () => {
  const dispatch = useDispatch()

  const excludedTagIds = useSelector(settingSlice.selectors.getSetting(EXCLUDED_TAGS_SETTING))?.value || []
  const subscribedTagIds = useSelector(settingSlice.selectors.getSetting(SUBSCRIBED_TAGS_SETTING))?.value || []

  const { isLoading } = useSelector(tagSlice.selectors.getMetaData)
  const tags = useSelector(tagSlice.selectors.getTags())
  const [groupedTags, setGroupedTags] = useState({ subscribed: [], excluded: [], normal: [] })

  useEffect(() => {
    dispatch(tagSlice.asyncActions.fetchTags())
  }, [])

  useEffect(() => {
    const categorizedTags = _.groupBy(tags, (tag) => {
      if (subscribedTagIds.includes(tag.id)) {
        return 'subscribed'
      }

      if (excludedTagIds.includes(tag.id)) {
        return 'excluded'
      }

      return 'normal'
    })

    setGroupedTags({
      subscribed: categorizedTags.subscribed || [],
      excluded: categorizedTags.excluded || [],
      normal: categorizedTags.normal || [],
    })
  }, [tags.map(tag => tag.id).join(',')])

  const onTagDragEnd = ({ source, destination }) => {
    // dropped outside the list
    if (!destination) {
      return
    }

    const sourceIndex = source.index
    const sourceId = source.droppableId
    const destinationIndex = destination.index
    const destinationId = destination.droppableId
    const newGroupedTags = _.cloneDeep(groupedTags)

    // move tag from one list to the other
    const [tagToMove] = newGroupedTags[sourceId].splice(sourceIndex, 1)
    newGroupedTags[destinationId].splice(destinationIndex, 0, tagToMove)

    setGroupedTags(newGroupedTags)

    dispatch(settingSlice.asyncActions.update(EXCLUDED_TAGS_SETTING, newGroupedTags.excluded.map(tag => tag.id)))
    dispatch(settingSlice.asyncActions.update(SUBSCRIBED_TAGS_SETTING, newGroupedTags.subscribed.map(tag => tag.id)))
  }

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='NewsSettingsSection'>
      <div className='d-flex'>
        <DragDropContext onDragEnd={onTagDragEnd}>
          <div className='DraggableCard mr-2'>
            <DroppableSettingsSection
              className='cy_do_not_notify_settings'
              droppableId='excluded'
              title={I18N('do_not_notify')}
              items={groupedTags.excluded}
              itemRenderer={tag => <Tag text={tag.name} id={tag.id} />}
            />
          </div>
          <div className='DraggableCard mr-2'>
            <DroppableSettingsSection
              className='cy_feed_only_settings'
              droppableId='normal'
              title={I18N('feed_only')}
              items={groupedTags.normal}
              itemRenderer={tag => <Tag text={tag.name} id={tag.id} />}
            />
          </div>
          <div className='DraggableCard mr-2'>
            <DroppableSettingsSection
              className='cy_alert_me_settings'
              droppableId='subscribed'
              title={I18N('alert_me')}
              items={groupedTags.subscribed}
              itemRenderer={tag => <Tag text={tag.name} id={tag.id} />}
            />
          </div>
        </DragDropContext>
      </div>
    </div>
  )
}

export default NewsSettingsSection
