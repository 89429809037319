import React, { useMemo } from 'react'

import classNames from 'classnames'
import ProgressiveImage from 'components/common/images/progressiveImage'

const ArticleThumbnailImage = ({ article }) => {
  const randomNumber = useMemo(() => {
    if (article.createdAt) {
      // If the article is already created, we want to show the same image consistently
      const date = new Date(article.createdAt)
      return ((date.getTime() / 1000) % 3) + 1
    } else {
      return _.random(1, 3)
    }
  }, [article.createdAt])

  return (
    <div className={classNames('ArticleThumbnailImage', { [`random-image-${randomNumber} random-image`]: !article.thumbnailImageUrl })}>
      <ProgressiveImage className='w-100' src={article.thumbnailImageUrl} />
    </div>
  )
}

export default ArticleThumbnailImage
