import React from 'react'

import InfoTooltip from 'components/common/infoTooltip'
import { i18nPath } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import classNames from 'classnames'

const I18N = i18nPath('views.admin.journeys.step_templates.reply_to_setting')

const OPTIONS = [
  { label: I18N('default'), value: 'default' },
  { label: I18N('cc'), value: 'cc' },
  { label: I18N('no_reply_to'), value: 'no_reply_to' },
]

const ReplyToSetting = ({
  className = '',
  workingCopy,
  updateWorkingCopy,
  isDisabled = false,
}) => (
  <div className={classNames(className)}>
    <label>{I18N('label')}</label>
    <InfoTooltip text={I18N('tooltip')} />
    <ReactSelect
      isDisabled={isDisabled}
      value={OPTIONS.find(option => option.value === workingCopy.replyToSetting)}
      options={OPTIONS}
      onChange={selectedOption => updateWorkingCopy({ replyToSetting: selectedOption.value })}
    />
  </div>
)

export default ReplyToSetting
