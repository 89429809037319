import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import RoundedAvatars from 'components/common/roundedAvatars'
import audienceSlice from 'redux/slices/audiences'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.journeys_estimated_users')

const JourneysEstimatedUsers = ({
  journeyBlueprintType,
  targetingRules,
  automaticallyAddUsersDaysAfterMilestone,
  usersText = I18N('users_text'),
  noUsersText = I18N('no_users_text'),
  onlyUsersWithoutJourney = false,
  journeyBlueprintId,
}) => {
  const dispatch = useDispatch()
  const users = useSelector(audienceSlice.selectors.getUsers())

  const fetchAudience = () => {
    dispatch(audienceSlice.asyncActions.fetchJourneysEstimatedAudience({
      journeyBlueprintId,
      targetingRules,
      journeyBlueprintType,
      daysAfterMilestone: automaticallyAddUsersDaysAfterMilestone,
      includeHiddenUsers: true,
      withoutJourney: onlyUsersWithoutJourney,
    }))
  }

  useEffect(() => {
    fetchAudience()
  }, [JSON.stringify(targetingRules), automaticallyAddUsersDaysAfterMilestone])

  return (
    <div className='JourneysEstimatedUsers d-flex justify-content-start align-items-center mt-3'>
      {users.length === 0 ? (
        <p className='text-left text-normal text-secondary mb-0'>{noUsersText}</p>
      ) : (
        <>
          <RoundedAvatars avatarSize='30px' users={users} popOverPlacement='auto' linkTo='journeys' />
          <p className='text-left text-normal text-secondary ml-3 mb-0'>{usersText}</p>
        </>
      )}
    </div>
  )
}

export default JourneysEstimatedUsers
