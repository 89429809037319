import { Dispatch } from 'react'
import entitySlice from 'redux/slices/entities'
import build from 'redux-object'
import API from 'services/api'
import appSignal from 'services/appSignal'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { createSlice } from '@reduxjs/toolkit'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { ReduxState } from 'redux/redux'
import { showToastMessage } from 'redux/slices/toasts'
import queryParamsFromHeaders, { PaginationParams, defaultPaginationParams } from 'utils/queryParamsFromHeaders'

const I18N = i18nPath('views.acknowledgement')
const I18NAdmin = i18nPath('views.admin.acknowledgements')

export interface AcknowledgementState {
  acknowledgementIds: string[]
  notAcknowledgedIds: string[]
  meta: DefaultMetaType & {
    queryParams: PaginationParams
    pendingCount: number
    acknowledgedCount: number
    overdueCount: number
    isSendingReminders: boolean
    isExporting: boolean
  }
}

export const initialState = {
  acknowledgementIds: [],
  notAcknowledgedIds: [],
  meta: {
    ...defaultMeta,
    queryParams: defaultPaginationParams,
    pendingCount: 0,
    acknowledgedCount: 0,
    overdueCount: 0,
    isSendingReminders: false,
    isExporting: false,
  },
}

const acknowledgementSlice = createSlice({
  name: 'acknowledgements',
  initialState,
  reducers: {
    ...defaultActions,

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    setAcknowledgementIds: (state, action) => {
      state.acknowledgementIds = action.payload
    },

    setNotAcknowledgedIds: (state, action) => {
      state.notAcknowledgedIds = action.payload
    },

    setPendingCount: (state, action) => {
      state.meta.pendingCount = action.payload
    },

    setAcknowledgedCount: (state, action) => {
      state.meta.acknowledgedCount = action.payload
    },

    setOverdueCount: (state, action) => {
      state.meta.overdueCount = action.payload
    },

    isSendingReminders: (state, action) => {
      state.meta.isSendingReminders = action.payload
    },

    isExporting: (state, action) => {
      state.meta.isExporting = action.payload
    },

    reset() {
      return initialState
    },
  },
})

const asyncActions = {
  acknowledge: (acknowledgeableId: string, type: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(acknowledgementSlice.actions.isSaving(true))

      const response = await API.acknowledgements.acknowledge(acknowledgeableId, type)

      dispatch(entitySlice.actions.update({
        data: {
          data: {
            id: acknowledgeableId,
            type,
            attributes: {
              acknowledgementInfo: {
                status: response.data.data.attributes.status,
                acknowledgedAt: response.data.data.attributes.acknowledgedAt,
              },
            },
          },
        },
      }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(showToastMessage({ type: 'error', message: I18N(`error_acknowledging.${type}`) }))
    } finally {
      dispatch(acknowledgementSlice.actions.isSaving(false))
    }
  },

  admin: {
    fetchAll: (acknowledgeableId: string, type: string, queryParams: any = {}) => async (dispatch: Dispatch<any>) => {
      try {
        dispatch(acknowledgementSlice.actions.isLoading(true))

        const response = await API.admin.acknowledgements.fetchAll(acknowledgeableId, type, queryParams)

        const { data, meta } = response.data
        const acknowledgementIds = data.map((acknowledgement: any) => acknowledgement.id)

        dispatch(acknowledgementSlice.actions.setQueryParams(queryParamsFromHeaders(response)))
        dispatch(acknowledgementSlice.actions.setAcknowledgementIds(acknowledgementIds))
        dispatch(entitySlice.actions.add({ data: response.data }))

        dispatch(acknowledgementSlice.actions.setPendingCount(meta.pendingCount))
        dispatch(acknowledgementSlice.actions.setAcknowledgedCount(meta.acknowledgedCount))
        dispatch(acknowledgementSlice.actions.setOverdueCount(meta.overdueCount))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
      } finally {
        dispatch(acknowledgementSlice.actions.isLoading(false))
      }
    },

    fetchNotAcknowledged: (acknowledgeableId: string, type: string) => async (dispatch: Dispatch<any>) => {
      try {
        // fetching 105 since rounded avatars shows 5 and 99 in the dropdown, so we need only 1 more than that
        const response = await API.admin.acknowledgements.fetchAll(acknowledgeableId, type, { perPage: 105, status: 'not_acknowledged' })

        const acknowledgementIds = response.data.data.map((acknowledgement: any) => acknowledgement.id)
        dispatch(acknowledgementSlice.actions.setNotAcknowledgedIds(acknowledgementIds))
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
      }
    },

    sendReminders: (acknowledgeableId: string, type: string, onSuccess = () => {}) => async (dispatch) => {
      dispatch(acknowledgementSlice.actions.isSendingReminders(true))

      try {
        await API.admin.acknowledgements.sendReminders(acknowledgeableId, type)

        dispatch(showToastMessage({ type: 'success', message: I18NAdmin('reminders_sent') }))
        onSuccess()
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(showToastMessage({ type: 'error', message: I18NAdmin('error_sending_reminders') }))
      } finally {
        dispatch(acknowledgementSlice.actions.isSendingReminders(false))
      }
    },

    sendReminder: (
      acknowledgeableId: string, type: string, userId: string, onSuccess = () => {}
    ) => async (dispatch) => {
      try {
        await API.admin.acknowledgements.sendReminders(acknowledgeableId, type, { userIds: [userId] })

        dispatch(showToastMessage({ type: 'success', message: I18NAdmin('reminder_sent') }))
        onSuccess()
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(showToastMessage({ type: 'error', message: I18NAdmin('error_sending_reminder') }))
      }
    },

    export: (acknowledgeableId: string, type: string) => async (dispatch: Dispatch<any>) => {
      dispatch(acknowledgementSlice.actions.isExporting(true))

      try {
        await API.admin.acknowledgements.export(acknowledgeableId, type)
        dispatch(showToastMessage({ message: I18NCommon('queue_generate_report'), type: 'success' }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
      } finally {
        dispatch(acknowledgementSlice.actions.isExporting(false))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.acknowledgements.meta,

  getAcknowledgements: () => (state: ReduxState) => state.acknowledgements.acknowledgementIds.map(id => build(state.entities, 'acknowledgement', id)).filter(Boolean),

  getNotAcknowledged: () => (state: ReduxState) => state.acknowledgements.notAcknowledgedIds.map(id => build(state.entities, 'acknowledgement', id)).filter(Boolean),
}

export default {
  ...acknowledgementSlice,
  selectors,
  asyncActions,
}
