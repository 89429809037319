import React from 'react'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

import CancelButton from 'components/common/cancelButton'
import Card from 'components/common/card'
import { Button } from 'components/common/buttons'
import { Fade } from 'react-reveal'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { MEDIUM_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'

const I18N = i18nPath('views.qna.events')

const IcebreakerEditor = ({
  resetState,
  onCloseModal,
  createQuestionHandler,
  isSubmitButtonDisabled,
  questionContent,
  setQuestionContent,
}) => (
  <>
    <Fade big>
      <div style={{ minHeight: '10rem' }}>
        <Card cardBodyClassName='p-0' hasBoxShadow={false} hideBorder={false}>
          <TiptapEditor
            onChange={content => setQuestionContent(content)}
            html={questionContent}
            className='TipTapEditor'
            configuration={{ focusOnInitialize: true, placeholder: I18N('icebreaker_form_placeholder') }}
            toolbarItems={MEDIUM_TOOLBAR}
            editorContentClassName='p-3'
          />
        </Card>
      </div>
    </Fade>
    <div className='text-small mt-1'>
      {i18nFormat(
        I18N('need_inspiration'),
        <a href='#' onClick={resetState}>
          {I18N('select_from_icebreaker_library')}
        </a>
      )}
    </div>
    <div className='d-flex align-items-center justify-content-end mt-3'>
      <CancelButton onClick={onCloseModal} />
      <Button onClick={createQuestionHandler} disabled={isSubmitButtonDisabled}>
        {I18N('create_icebreaker_question')}
      </Button>
    </div>
  </>
)

export default IcebreakerEditor
