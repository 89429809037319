import React from 'react'

import CheckBox from 'components/common/buttons/checkBox'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'


const I18N = i18nPath('views.admin.article_settings.social_share_settings')

const SocialMediaCheckbox = ({
  onChange, label, id, checked,
}) => (
  <CheckBox
    id={`social-media-checkbox-${id}`}
    className='mr-4'
    checked={checked}
    onChange={onChange}
    label={label}
  />
)

const SocialShareSettings = ({ connect }) => (
  <div>
    <h6>{I18N('title')}</h6>
    <p className='text-secondary text-small'>{I18N('helper_text')}</p>

    <div className='d-flex'>
      <SocialMediaCheckbox
        id='facebook'
        label={(
          <div className='d-flex align-items-center'>
            <CdnSvg src='/images/logos/facebook.svg' className='mr-2' /> Facebook
          </div>
        )}
        {...connect('socialSharing.facebook')}
      />

      <SocialMediaCheckbox
        id='twitter'
        label={(
          <div className='d-flex align-items-center'>
            <CdnSvg src='/images/logos/x.svg' className='mr-2' /> X
          </div>
        )}
        {...connect('socialSharing.twitter')}
      />

      <SocialMediaCheckbox
        id='linkedin'
        label={(
          <div className='d-flex align-items-center'>
            <CdnSvg src='/images/logos/linkedin.svg' className='mr-2' /> Linkedin
          </div>
        )}
        {...connect('socialSharing.linkedin')}
      />
    </div>
  </div>
)

export default SocialShareSettings
