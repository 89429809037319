import React from 'react'
import moment from 'moment'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { CelebrationListItem } from 'components/home/widgets/celebrationsWidget'

const CelebrationsLabelPreview = ({ type, customCelebration }) => {
  const currentUser = useCurrentUser()

  const celebration = {
    user: currentUser,
    date: moment().add('7', 'days'),
    type,
    customCelebration,
    workAnniversaryYears: 5,
  }

  return (
    <div className='HomePageWidget CelebrationsWidget'>
      <CelebrationListItem
        celebration={celebration}
        width={5}
      />
    </div>
  )
}

export default CelebrationsLabelPreview
