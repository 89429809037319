import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'

import Slider from 'components/common/slider'
import ForwardArrowIcon from 'components/icons/forwardArrowIcon'
import NewHireCarouselCard from './newHireCarouselCard'

const CarouselButton = ({ className, onClick }) => (
  <a className={classNames(className, 'CarouselButton')} onClick={onClick}>
    <ForwardArrowIcon />
  </a>
)

const NewHireCarousel = ({
  newHires,
  setSelectedNewHire,
  selectedNewHire,
  className,
}) => {
  const sliderRef = useRef()

  useEffect(() => {
    if (selectedNewHire?.id) {
      const newHireIndex = _.findIndex(newHires, { id: selectedNewHire.id })
      sliderRef.current.slickGoTo(newHireIndex)
    }
  }, [selectedNewHire?.id])

  return (
    <section className={classNames('NewHireCarousel', className)}>
      <Slider
        ref={sliderRef}
        settings={{
          slidesToShow: 1,
          dots: false,
          autoplay: false,
          speed: 1000,
          lazyLoad: 'progressive',
          prevArrow: <CarouselButton />,
          nextArrow: <CarouselButton />,
        }}
        beforeChange={(_oldIndex, newIndex) => setSelectedNewHire(newHires[newIndex])}
      >
        {newHires.map(user => <NewHireCarouselCard key={`carousel-card-${user.id}`} user={user} />)}
      </Slider>
    </section>
  )
}

export default NewHireCarousel
