import React, { useEffect, useRef, useState } from 'react'

import Card from 'components/common/card'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'
import { useSelector } from 'react-redux'
import entitySlice from 'redux/slices/entities'
import InfiniteScroll from 'react-infinite-scroll-component'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import ShoutoutItem from 'components/home/widgets/recognition_widget/shoutoutItem'
import { ButtonSecondary } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import ShoutoutModal from 'components/shoutouts/shoutoutModal'
import AwardedBadgeItem from 'components/home/widgets/recognition_widget/awardedBadgeItem'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.home.recognition_widget')

const FEED_ITEM_TYPES = ['shoutout', 'awarded_badge']

const RecognitionWidget = ({ widget }) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const cardRef = useRef<HTMLDivElement>(null)
  const [showShoutoutsModal, setShowShoutoutsModal] = useState(false)
  const [newFeedItems, setNewFeedItems] = useState<any[]>([])

  const [feedItemIds, setFeedItemIds] = useState<string[]>([])
  const feedItems = useSelector(entitySlice.selectors.getEntities('feedItem', feedItemIds))
  const allFeedItems = [...newFeedItems, ...feedItems]

  const [fetch, { isLoading, paginationData }] = useApi(API.home.fetchFeed, {
    addEntitySlice: true,
    onSuccess: (data) => {
      setFeedItemIds(prev => _.uniq([...prev, ...data.map(d => d.id)]))
    },
  })

  const {
    page = 0,
    total = 0,
    totalPages = 1,
  } = paginationData || {}

  useEffect(() => {
    fetch({ feedItemTypes: FEED_ITEM_TYPES })
  }, [])

  const next = () => {
    if (isLoading) return

    fetch({ page: page + 1, feedItemTypes: FEED_ITEM_TYPES })
  }

  // subtracting 1 to the height otherwise an additional scrollbar appears in some screens
  const infiniteScrollHeight = (scrollRef.current?.clientHeight || 600) - 1
  const widgetWidth = cardRef.current?.clientWidth || 0

  const onSendShoutout = (data) => {
    const newFeedItem = {
      id: `new-${data.id}`,
      type: 'shoutout',
      shoutout: data,
    }
    setNewFeedItems(prev => [newFeedItem, ...prev])
  }


  return (
    <>
      <Card className='RecognitionWidget HomePageWidget' cardRef={cardRef}>
        <WidgetTitle
          title={widget.title}
          rightContent={(
            <ButtonSecondary className='SendShoutoutButton' onClick={() => setShowShoutoutsModal(true)}>
              <span className='ButtonText mr-2'>{I18N('shoutout')}</span>
              <CdnSvg src='/images/megaphoneIcon.svg' />
            </ButtonSecondary>
          )}
        />

        <div ref={scrollRef} className='HomePageWidget--scrollable-section hasOutsideScrollbar'>
          <InfiniteScroll
            dataLength={total || 0}
            next={next}
            hasMore={page < totalPages}
            loader={<CirclesLoadingIndicator />}
            height={infiniteScrollHeight}
            className='d-flex flex-column gap-3 p-2'
          >
            {allFeedItems?.map(feedItem => (
              feedItem.type === 'shoutout' ? (
                <ShoutoutItem key={feedItem.id} shoutout={feedItem.shoutout} widgetWidth={widgetWidth} />
              ) : (
                <AwardedBadgeItem key={feedItem.id} awardedBadge={feedItem.awardedBadge} widgetWidth={widgetWidth} />
              )
            ))}
          </InfiniteScroll>
        </div>
      </Card>

      {showShoutoutsModal && (
        <ShoutoutModal
          visible={showShoutoutsModal}
          closeModal={() => setShowShoutoutsModal(false)}
          onCreated={onSendShoutout}
        />
      )}
    </>
  )
}

export default RecognitionWidget
