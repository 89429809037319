import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import SmartTable from 'components/common/tables/smartTable'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import permissionSlice from 'redux/slices/permissions'
import { Link } from 'react-router-dom'

const I18N = i18nPath('views.admin.permissions')

const targetablePermissions = [
  'event_creator',
  'badge_creator',
]

const columns = [
  {
    header: I18N('name'),
    col: 'col-name',
    id: 'displayName',
    accessor: permission => (targetablePermissions.includes(permission.name) ? (
      <Link to={`/admin/permissions/manage/${permission.name}`}>{I18N(permission.name)}</Link>
    ) : (
      <>{I18N(permission.name)}</>
    )),
  },
  {
    header: I18N('users'),
    col: 'col-users-count',
    id: 'usersCount',
    accessor: permission => <Link data-testid={`cy-${permission.name}-count`} to={`/admin/permissions/${permission.name}`}>{permission.count}</Link>,
  },
]

const PermissionsTable = () => {
  const dispatch = useDispatch()

  const { isLoading } = useSelector(permissionSlice.selectors.getMetaData)

  const permissions = useSelector(permissionSlice.selectors.getPermissions)

  const permissionsWithEventCreator = [...permissions]

  // if targetable permissions do not have users they typically wont show up on this page
  // however we want to add a link to those permissions so the admins can manage them
  // we therefore add the permissions manually with 0 count
  // ex: if only article and badge managers have any permission the response would be the following
  // [{name: 'article_manager', count: 5}, {name: 'badge_manager', count: 3}]
  // since we want to display targetable permissions even if they do not show up we have to manually add them

  const permissionsNames = permissions.map(permission => permission.name)

  targetablePermissions.forEach((targetablePermission) => {
    if (permissions && !permissionsNames.includes(targetablePermission)) {
      permissionsWithEventCreator.push({ name: targetablePermission, count: 0 })
    }
  })

  useEffect(() => {
    dispatch(permissionSlice.asyncActions.admin.fetchAll())
  }, [])

  return (
    <TableLoadingContainer isLoading={isLoading}>
      <SmartTable
        className='PermissionsTable white-bg-table'
        data={permissionsWithEventCreator}
        columns={columns}
        onSortChange={() => {}}
      />
    </TableLoadingContainer>
  )
}

export default PermissionsTable
