import React from 'react'
import { useDispatch } from 'react-redux'

import groupSlice from 'redux/slices/groups'
import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import EditableContent from 'components/common/editable_content'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ChatChannel from 'components/common/chatChannel'
import ChatChannelsAsyncSelectForEditableContent from 'components/common/editable_content/chatChannelsAsyncSelectForEditableContent'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.groups.group_page')
const plusIconPath = '/images/plusIcon.svg'
const envelopeIconPath = '/images/envelopeOutlineIcon.svg'

const AddEmailPlaceholder = () => (
  <span className='font-weight-600 info-with-icon'>
    <CdnSvg src={plusIconPath} className='GroupIcon empty-state d-flex mr-1' />
    {I18N('add_email')}
  </span>
)

const AddChannelPlaceholder = ({ addText }) => (
  <span className='font-weight-600 info-with-icon'>
    <CdnSvg src={plusIconPath} className='GroupIcon empty-state d-flex mr-1' />
    {addText}
  </span>
)

const EmailLabel = ({ email }) => (
  <span className='info-with-icon'>
    <CdnSvg src={envelopeIconPath} className='GroupIcon mr-1' />
    <span>{email}</span>
  </span>
)

const EmailInfo = ({ email, canEdit, updateGroup }) => (
  <div>
    <EditableContent
      canEdit={canEdit}
      valueToEdit={email}
      inputElement={<input type='email' maxLength={100} className='w-100' data-cy='group-email' />}
      displayElement={email ? <EmailLabel email={email} /> : <AddEmailPlaceholder />}
      saveContent={value => updateGroup({ email: value })}
      className='info-field'
    />
  </div>
)

const ChannelInfo = ({
  canEdit, updateGroup, channel, field, type, addText, cyId,
}) => (
  <div data-cy={cyId}>
    <EditableContent
      valueIsObject
      canEdit={canEdit}
      valueToEdit={channel}
      inputElement={<ChatChannelsAsyncSelectForEditableContent types={[type]} />}
      displayElement={channel
        ? <ChatChannel className='info-with-icon' channel={channel} />
        : <AddChannelPlaceholder addText={addText} />}
      saveContent={value => updateGroup({ [field]: value })}
      className='info-field'
    />
  </div>
)

const Contact = ({ group }) => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()

  const { slackEnabled, msTeamEnabled } = currentCompany

  const {
    email, slackChannel, msTeamChannel, permittedActions,
  } = group
  const canEdit = permittedActions.editFields

  const updateGroup = async (data) => {
    await dispatch(groupSlice.asyncActions.update({ ...group, ...data }))
  }

  return (
    <section className='GroupContact mb-4'>
      <Card>
        <h6>{I18N('contact')}</h6>

        <EmailInfo email={email} canEdit={canEdit} updateGroup={updateGroup} />

        {slackEnabled && (
          <ChannelInfo
            cyId='group-slack-channel-wrapper'
            channel={slackChannel}
            type='Chat::SlackChannel'
            field='slackChannel'
            canEdit={canEdit}
            updateGroup={updateGroup}
            addText={I18N('add_slack')}
          />
        )}

        {msTeamEnabled && (
          <ChannelInfo
            cyId='group-ms-team-channel-wrapper'
            channel={msTeamChannel}
            type='Chat::MsTeamChannel'
            field='msTeamChannel'
            canEdit={canEdit}
            updateGroup={updateGroup}
            addText={I18N('add_ms_team')}
          />
        )}
      </Card>
    </section>
  )
}

export default Contact
