import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import { defaultMeta, defaultActions } from 'redux/slices/utils/commonReducers'
import appSignal from 'services/appSignal'

export const buildUserFollowPayload = userFollow => _.pick(userFollow, [
  'followedUserId',
  'receiveNotificationsForReports',
])

export const initialState = {
  orgSectionFollowedUserIds: [],
  followingStatus: {},
  meta: {
    ...defaultMeta,
    isLoadingOrgSectionFollowedUserIds: false,
    isLoadingFollowingStatus: false,
  },
}

const userFollowSlice = createSlice({
  name: 'userFollows',
  initialState,
  reducers: {
    ...defaultActions,

    isLoadingOrgSectionFollowedUserIds(state, action) {
      state.meta.isLoadingOrgSectionFollowedUserIds = action.payload
    },

    isLoadingFollowingStatus(state, action) {
      state.meta.isLoadingFollowingStatus = action.payload
    },

    setOrgSectionFollowedUserIds(state, action) {
      state.orgSectionFollowedUserIds = action.payload
    },

    setFollowingStatus(state, action) {
      state.followingStatus = action.payload
    },
  },
})

_.assign(userFollowSlice, {
  asyncActions: {
    fetchAll: () => async (dispatch) => {
      dispatch(userFollowSlice.actions.isLoading(true))

      try {
        const response = await API.userFollows.fetchAll()
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userFollowSlice.actions.isLoading(false))
      }
    },

    create: userFollow => async (dispatch) => {
      dispatch(userFollowSlice.actions.isSaving(true))

      try {
        const response = await API.userFollows.create(buildUserFollowPayload(userFollow))
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userFollowSlice.actions.isSaving(false))
      }
    },

    destroy: id => async (dispatch) => {
      dispatch(userFollowSlice.actions.isSaving(true))

      try {
        await API.userFollows.destroy({ id })
        dispatch(entitySlice.actions.remove({ id, type: 'userFollow' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userFollowSlice.actions.isSaving(false))
      }
    },

    fetchOrgSectionFollowedUserIds: () => async (dispatch) => {
      dispatch(userFollowSlice.actions.isLoadingOrgSectionFollowedUserIds(true))

      try {
        const response = await API.userFollows.fetchOrgSectionFollowedUserIds()
        const userIds = response.data.orgSectionFollowedUserIds
        dispatch(userFollowSlice.actions.setOrgSectionFollowedUserIds(userIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(userFollowSlice.actions.isLoadingOrgSectionFollowedUserIds(false))
      }
    },

    fetchFollowingStatus: userId => async (dispatch) => {
      dispatch(userFollowSlice.actions.isLoadingFollowingStatus(true))

      try {
        const response = await API.userFollows.fetchFollowingStatus(userId)
        dispatch(userFollowSlice.actions.setFollowingStatus(response.data))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(userFollowSlice.actions.setFollowingStatus({ error: true }))
      } finally {
        dispatch(userFollowSlice.actions.isLoadingFollowingStatus(false))
      }
    },
  },
})

_.assign(userFollowSlice, {
  selectors: {
    getMetaData: () => state => state.userFollows.meta,

    getInverseUserFollows: () => state => build(state.entities, 'userFollow') ?? [],

    getOrgSectionFollowedUserIds: () => state => state.userFollows.orgSectionFollowedUserIds,

    getFollowingStatus: () => state => state.userFollows.followingStatus,
  },
})

export default userFollowSlice
