import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'
import Modal from 'components/common/modal'

const I18N = i18nPath('views.pages.actions_dropdown')

const CopyPageModal = ({
  className,
  closeCopyPageModal,
  handleCopyPage,
  showCopyPageModal,
  pageTitle,
  isCopying,
}) => {
  const [copyPageTitle, setCopyPageTitle] = useState(`${I18N('copy_of')} ${pageTitle}`)
  return (
    <Modal size='lg' className={classNames('CopyPageModal', className)} visible={showCopyPageModal} toggle={closeCopyPageModal}>
      <div className='px-4 text-center mb-4'>
        <h4 className='text-left mb-4 font-weight-600'>{I18N('copy_page')}</h4>
        <div className='text-left my-5'>
          <label className='pb-2 font-weight-500' htmlFor='copy_page_name_field'>{I18N('copy_page_name_field')}</label>
          <input id='copy_page_name_field' className='w-100' value={copyPageTitle} onChange={ev => setCopyPageTitle(ev.target.value)} disabled={isCopying} />
        </div>

        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={closeCopyPageModal} className='mr-3 text-secondary font-weight-600'>
            {I18N('cancel')}
          </CancelButton>
          <ButtonNarrow className='copySaveButton' onClick={() => handleCopyPage(copyPageTitle)} showLoadingSpinner={isCopying} disabled={isCopying}>
            {I18N('copy_page_save')}
          </ButtonNarrow>
        </div>
      </div>
    </Modal>
  )
}

export default CopyPageModal
