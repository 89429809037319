import { useState } from 'react'

const useExpandingResources = () => {
  const [expandingResources, setExpandingResources] = useState({})

  const startExpanding = (type: string, id: string) => setExpandingResources(oldValue => ({
    ...oldValue,
    [`${type}.${id}`]: {
      ...oldValue[`${type}.${id}`],
      expanding: true,
    },
  }))

  const finishExpanding = (type: string, id: string) => setExpandingResources(oldValue => ({
    ...oldValue,
    [`${type}.${id}`]: {
      ...oldValue[`${type}.${id}`],
      expanding: false,
      hasExpandedBefore: true,
    },
  }))

  return {
    startExpanding,
    finishExpanding,
    showExpandingState: (type: string, id: string) => expandingResources[`${type}.${id}`]?.expanding === true
      && !expandingResources[`${type}.${id}`]?.hasExpandedBefore,
  }
}

export default useExpandingResources
