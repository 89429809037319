import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import pageSlice from 'redux/slices/pages'
import { useHistory } from 'react-router-dom'
import AdminBanner from 'components/common/adminBanner'
import { Button } from 'components/common/buttons'
import PageDeleteModal from 'components/pages/pageDeleteModal'

const I18N = i18nPath('views.pages.archive_banner')

const DELETE_PAGE_MODAL = 'DELETE_PAGE_MODAL'

const PageArchiveBanner = ({
  page,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [openModal, setOpenModal] = useState<null |string>(null)

  const { isSaving, isDeleting } = useSelector(pageSlice.selectors.getPagesMetadata())
  const canArchivePage = page?.permittedActions?.canArchivePage
  const canEditPage = page?.permittedActions?.edit

  const unarchivePage = () => {
    const onSuccess = () => {
      history.push(page.displayPath)
      return null
    }

    dispatch(pageSlice.asyncActions.unarchive(page, onSuccess))
  }

  return (
    <>
      <AdminBanner color='red'>
        <span>
          {I18N('page_is_archived')}
        </span>
        {canArchivePage && (
          <Button
            className='mx-2 bordered text-white bg-transparent'
            showLoadingSpinner={isSaving}
            disabled={isSaving || isDeleting}
            onClick={unarchivePage}
          >
            {I18N('restore_page')}
          </Button>
        )}
        {canEditPage && (
          <Button
            className='bordered text-white bg-transparent'
            showLoadingSpinner={isDeleting}
            disabled={isSaving || isDeleting}
            onClick={() => setOpenModal(DELETE_PAGE_MODAL)}
          >
            {I18N('delete_permanently')}
          </Button>
        )}
      </AdminBanner>
      {openModal === DELETE_PAGE_MODAL && (
        <PageDeleteModal
          page={page}
          visible={openModal === DELETE_PAGE_MODAL}
          onClose={() => setOpenModal(null)}
        />
      )}
    </>
  )
}

export default PageArchiveBanner
