import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { I18NCommon } from 'utils/i18nHelpers'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'

import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DeleteWithIcon from 'components/common/deleteWithIcon'

const DeleteQuestionDropdown = ({ question, hideDeleteOption = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { permittedActions } = question

  // do not allow deletion if moderator is previewing the event
  const showDeleteOption = !hideDeleteOption && permittedActions.delete

  const deleteQuestionHandler = async (e) => {
    e.stopPropagation()

    const confirmDelete = confirm(I18NCommon('delete_confirm'))
    const eventSlug = question.event.slug

    if (confirmDelete) {
      await dispatch(qnaQuestionSlice.asyncActions.deleteQuestion(question))
      history.push(`/events/${eventSlug}`)
    }
  }

  if (!showDeleteOption) return null

  return (
    showDeleteOption && (
      <DropdownMenuContainer className='position-absolute right-0 top-0 mr-2 mt-2'>
        <CardDropdownMenuItem
          className='p-2'
          width='150px'
          primaryText={<DeleteWithIcon />}
          onClick={deleteQuestionHandler}
        />
      </DropdownMenuContainer>
    )
  )
}

export default DeleteQuestionDropdown
