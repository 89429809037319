import React, { useEffect } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import appSignal from 'services/appSignal'

const I18N = i18nPath('views.form_error_list')

const formatErrorKey = (errorKey, useTitleCase = false) => {
  if (!useTitleCase) return errorKey

  return _.snakeCase(errorKey).split('_').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
}

const RawError = ({ error }) => {
  useEffect(() => {
    appSignal.sendErrorUnlessClearyBackendError(error)
  }, [error])

  return (
    <div>{error}</div>
  )
}

const ValidationErrors = ({ errors = {}, useTitleCase = false }) => (
  <ul>
    {Object.keys(errors).map(errorKey => (
      <li key={`form-error-${errorKey}`}>
        {formatErrorKey(errorKey, useTitleCase)}: {errors[errorKey].join(', ')}
      </li>
    ))}
  </ul>
)

const GenericErrors = ({ errors = [] }) => (
  <ul>
    {errors.map((errorObj, i) => (
      <li key={`error-detail-${i}`}>
        {errorObj.detail}
      </li>
    ))}
  </ul>
)


export const ErrorList = ({
  error = [],
  useTitleCase = false,
}) => {
  if (_.isNull(error) || _.isEmpty(error)) {
    return null
  }

  if (typeof error === 'string') {
    return <RawError error={error} />
  }

  const errors = error.errors

  if (_.isArray(errors)) {
    return <GenericErrors errors={errors || []} />
  }

  return <ValidationErrors errors={errors || {}} useTitleCase={useTitleCase} />
}

const FormErrorList = ({ error = null }) => {
  const formListRef = React.createRef()

  useEffect(() => {
    window.scrollTo(0, formListRef.current.offsetHeight)
  }, [])

  if (_.isNull(error)) {
    return null
  }

  return (
    <div ref={formListRef} className='FormErrorList p-3 my-4'>
      <div>{I18N('error')}</div>
      <ErrorList error={error} />
    </div>
  )
}

export default FormErrorList
