import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import FormErrorList from 'components/errors/formErrorList'
import currentTimezone from 'utils/currentTimezone'
import TimezoneForm from 'components/admin/timezoneForm'
import timezoneSlice from 'redux/slices/timezones'

const I18N = i18nPath('views.admin.timezones')

const CreateTimezonePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [timezone, setTimezone] = useState({
    id: null,
    location: null,
    timezone: currentTimezone(),
  })

  const { isSaving, error } = useSelector(timezoneSlice.selectors.getMetaData())
  const disableSaveButton = !timezone.location || !timezone.timezone

  const saveTimezone = () => {
    dispatch(timezoneSlice.asyncActions.admin.createTimezone(timezone, history))
  }

  return (
    <div className='container mb-4'>
      <div className='row mt-4'>
        <div className='col-md-6 col-bg-6 col-sm-12'>
          <h1>{I18N('create_timezone')}</h1>
          {error && <FormErrorList error={error} />}
        </div>
      </div>
      <div className='row mt-4'>
        <TimezoneForm timezone={timezone} setTimezone={setTimezone} />

        <div className='form-group col-12'>
          <Button disabled={disableSaveButton} onClick={saveTimezone} showLoadingSpinner={isSaving}>
            {I18N('save_timezone')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateTimezonePage
