import API from 'services/api'

import { showToastMessage } from 'redux/slices/toasts'
import coverImageSlice from 'redux/slices/common/coverImage'
import entitySlice from 'redux/slices/entities'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.cover_image')

const eventCoverImageSlice = coverImageSlice({
  sliceName: 'eventCoverImage',
  API: API.admin.qna.events,
  // slice is a reference of the coverImageSlice instance, created for the eventCoverImage slice.
  customAsyncActions: slice => ({
    removeTemplateImage: eventData => async (dispatch) => {
      try {
        dispatch(slice.actions.isRemoving(true))
        const response = await API.admin.qna.events.update(eventData)
        dispatch(entitySlice.actions.add({ data: response.data }))

        dispatch(showToastMessage({ message: I18N('cover_image_removed'), type: 'success' }))
      } catch (e) {
        console.error({ e })
        dispatch(showToastMessage({ message: I18N('removal_error_message'), type: 'warn' }))
      } finally {
        dispatch(slice.actions.isRemoving(false))
      }
    },
  }),
})

export default eventCoverImageSlice
