import React, {
  Component, useEffect, useRef, useState
} from 'react'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import CancelButton from 'components/common/cancelButton'
import {
  ButtonSmallNarrow
} from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import { ToolbarItem } from 'components/common/tiptap/toolbar/toolbar'
import useClickOutside from 'components/common/hooks/useClickOutside'

const pencilIconPath = '/images/pencilIcon.svg'
const I18NCommon = i18nPath('views.common')

interface Props {
  canEdit?: boolean
  className?: string
  displayElement: any
  saveContent?: (value: string | undefined) => Promise<void>
  setCurrentValue?: (value: string | undefined) => void
  inputElement: any
  valueToEdit?: string
  setIsEditing?: (isEditing: boolean) => void
  actionsInToolbar?: boolean
  toolbarItems?: ToolbarItem[]
  onlyCloseAction?: boolean
  enableClickOutside?: boolean
}

const appendToolbarItemsWithActions = (toolbarItems, actions, inputProps) => {
  const mappedActions = actions.map(action => ({
    name: action.key,
    Component: action,
  }))

  inputProps.toolbarItems = toolbarItems.concat(...mappedActions)
}

const EditableRichTextContent: React.FC<Props> = ({
  canEdit = false,
  className = '',
  displayElement,
  saveContent,
  inputElement,
  valueToEdit,
  setIsEditing: setIsEditingProp,
  actionsInToolbar = false,
  toolbarItems,
  setCurrentValue: setCurrentValueProp,
  onlyCloseAction = false,
  enableClickOutside = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentValue, setCurrentValue] = useState<any>(null)
  const [isSaving, setIsSaving] = useState(false)
  const editorRef = useRef<any>(null)
  const isEmpty = !currentValue || !valueToEdit

  useClickOutside({
    ref: editorRef,
    onClickOutside: () => {
      setIsEditing(false)
    },
    enableClickOutside,
  })

  const onClickDisplayElement = () => {
    if (canEdit) {
      setIsEditing(true)
    }
  }

  const save = async () => {
    setIsSaving(true)
    if (saveContent) await saveContent(currentValue)
    setIsSaving(false)
    setIsEditing(false)
  }

  const cancel = () => {
    setCurrentValue(valueToEdit)
    setIsEditing(false)
  }

  const onChange = (value) => {
    setCurrentValue(value)
    if (setCurrentValueProp) setCurrentValueProp(value)
  }

  const close = () => {
    setIsEditing(false)
  }

  useEffect(() => {
    setCurrentValue(valueToEdit)
  }, [valueToEdit])

  useEffect(() => {
    if (setIsEditingProp) setIsEditingProp(isEditing)
  }, [isEditing])

  if (!isEditing) {
    return (
      <span
        className={classNames('EditableContent-Display', { canEdit, isEmpty })}
        onClick={onClickDisplayElement}
      >
        {displayElement}
        <CdnSvg src={pencilIconPath} className='pencilIcon ml-1' />
      </span>
    )
  }

  const saveButton = (
    <ButtonSmallNarrow key='custom-save-button' className='ml-2' onClick={save} showLoadingSpinner={isSaving}>
      {I18NCommon('save')}
    </ButtonSmallNarrow>
  )

  const cancelButton = (
    <CancelButton
      key='custom-cancel-button'
      onClick={cancel}
      className={actionsInToolbar ? 'ml-auto' : 'ml-2'}
    />
  )

  const closeButton = (
    <ButtonSmallNarrow key='custom-close-button' className='ml-auto' onClick={close}>
      {I18NCommon('close')}
    </ButtonSmallNarrow>
  )

  const actions = onlyCloseAction ? [
    closeButton,
  ] : [
    cancelButton,
    saveButton,
  ]

  const inputProps = {
    focusOnInitialize: true,
    html: currentValue,
    onChange,
  }

  if (actionsInToolbar && toolbarItems) {
    appendToolbarItemsWithActions(toolbarItems, actions, inputProps)
  }

  return (
    <>
      <div ref={editorRef} className={classNames('EditableContent', className)}>
        {React.cloneElement(inputElement, inputProps)}

        {!actionsInToolbar && (
          <div className='controls d-flex align-items-center justify-content-end mt-2'>
            {actions}
          </div>
        )}
      </div>
    </>
  )
}

export default EditableRichTextContent
