import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import { Banner } from 'components/banners/banners'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.preboarding_preview_banner')

const PreboardingPreviewBanner = () => {
  const currentUser = useCurrentUser()
  const [stopPreview] = useApi(API.admin.preboarding.previews.stop, {
    onSuccess: () => {
      window.location.pathname = '/admin/preboarding_experience'
    },
  })

  if (currentUser.preboarding) return null

  return (
    <Banner
      banner={{
        text: I18N('text'),
      }}
      handleDecline={stopPreview}
    />
  )
}

export default PreboardingPreviewBanner
