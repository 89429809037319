import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import pickWithTargetingRules from 'redux/slices/utils/pickWithTargetingRules'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { PaginatedDataType } from 'types/analytics/helpers'
import {
  InactiveOwnerPageType,
  PageUsageDataType,
  PopularPageType,
  ReadTimeDataType,
  StalePageType,
  UniquePageViewsDataType
} from 'types/analytics/pages'

const buildPageAnalyticsPayload = pickWithTargetingRules([
  'page',
  'perPage',
  'order',
  'periodStart',
  'periodEnd',
  'groupBy',
  'type',
])

const defaultAnalyticsPageParams = {
  data: [],
  page: 1,
  totalPages: 1,
  totalItems: 0,
}

const defaultPageUsage = {
  audienceCount: 0,
  audienceReach: 0,
  pageViews: [],
  pageViewsCount: 0,
  uniquePageViews: [],
  uniquePageViewsCount: 0,
}

export interface PageAnalyticsState {
  uniquePageViews: UniquePageViewsDataType,
  popularPages: {
    most: PaginatedDataType<PopularPageType>
    least: PaginatedDataType<PopularPageType>
  },
  stalePages: PaginatedDataType<StalePageType>,
  inactiveOwnerPages: PaginatedDataType<InactiveOwnerPageType>,
  readTime: ReadTimeDataType
  pageUsage: PageUsageDataType
  meta: {
    uniquePageViews: DefaultMetaType
    popularPages: {
      most: DefaultMetaType,
      least: DefaultMetaType
    }
    stalePages: DefaultMetaType,
    inactiveOwnerPages: DefaultMetaType,
    readTime: DefaultMetaType
    pageUsage: DefaultMetaType
  }
}

export const initialState: PageAnalyticsState = {
  uniquePageViews: {
    data: [],
    totalUniquePageViews: 0,
  },
  popularPages: {
    most: defaultAnalyticsPageParams,
    least: defaultAnalyticsPageParams,
  },
  stalePages: defaultAnalyticsPageParams,
  inactiveOwnerPages: defaultAnalyticsPageParams,
  readTime: {},
  pageUsage: {},
  meta: {
    uniquePageViews: defaultMeta,
    popularPages: {
      most: defaultMeta,
      least: defaultMeta,
    },
    stalePages: defaultMeta,
    inactiveOwnerPages: defaultMeta,
    readTime: defaultMeta,
    pageUsage: defaultMeta,
  },
}

const pageAnalyticsSlice = createSlice({
  name: 'pageAnalytics',
  initialState,
  reducers: {
    setData(state, action) {
      const { type, data } = action.payload
      state[type] = data
    },

    setIsLoading(state, action) {
      const { type, isLoading } = action.payload
      state.meta[type].isLoading = isLoading
    },

    setError(state, action) {
      const { type, error } = action.payload
      state.meta[type].error = error
    },

    setPopularPages(state, action) {
      const { order, data } = action.payload
      state.popularPages[order] = data
    },

    isLoadingPopularPages(state, action) {
      const { order, isLoading } = action.payload
      state.meta.popularPages[order].isLoading = isLoading
    },

    setPopularPagesError(state, action) {
      const { order, error } = action.payload
      state.meta.popularPages[order].error = error
    },

    setReadTime(state, action) {
      const { pageId, data } = action.payload
      state.readTime[pageId] = data
    },

    setPageUsage(state, action) {
      const { pageId, data } = action.payload
      state.pageUsage[pageId] = data
    },
  },
})

const asyncActions = {
  admin: {
    fetchUniquePageViews: (params = {}) => async (dispatch) => {
      try {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'uniquePageViews', isLoading: true }))

        const response = await API.admin.analytics.pages.uniquePageViews(buildPageAnalyticsPayload(params))

        const data = { data: response.data.uniquePageViews, totalUniquePageViews: response.data.totalUniquePageViews }
        dispatch(pageAnalyticsSlice.actions.setData({ type: 'uniquePageViews', data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(pageAnalyticsSlice.actions.setError({ type: 'uniquePageViews', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'uniquePageViews', isLoading: false }))
      }
    },

    fetchPopularPages: (order: string, params = {}) => async (dispatch) => {
      try {
        dispatch(pageAnalyticsSlice.actions.isLoadingPopularPages({ order, isLoading: true }))

        const response = await API.admin.analytics.pages.popularPages(buildPageAnalyticsPayload(params))

        dispatch(pageAnalyticsSlice.actions.setPopularPages({ order, data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(pageAnalyticsSlice.actions.setPopularPagesError({ order, error: getResponseOrThrow(error) }))
      } finally {
        dispatch(pageAnalyticsSlice.actions.isLoadingPopularPages({ order, isLoading: false }))
      }
    },

    fetchStalePages: (params = {}) => async (dispatch) => {
      try {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'stalePages', isLoading: true }))

        const response = await API.admin.analytics.pages.stalePages(buildPageAnalyticsPayload(params))

        dispatch(pageAnalyticsSlice.actions.setData({ type: 'stalePages', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(pageAnalyticsSlice.actions.setError({ type: 'stalePages', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'stalePages', isLoading: false }))
      }
    },

    fetchInactiveOwnerPages: (params = {}, skipLoading = false) => async (dispatch) => {
      try {
        if (!skipLoading) dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'inactiveOwnerPages', isLoading: true }))

        const response = await API.admin.analytics.pages.inactiveOwnerPages(buildPageAnalyticsPayload(params))

        dispatch(pageAnalyticsSlice.actions.setData({ type: 'inactiveOwnerPages', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(pageAnalyticsSlice.actions.setError({ type: 'inactiveOwnerPages', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'inactiveOwnerPages', isLoading: false }))
      }
    },

    fetchReadTime: (pageId: string, params = {}) => async (dispatch) => {
      try {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'readTime', isLoading: true }))

        const response = await API.admin.analytics.pages.readTime(pageId, buildPageAnalyticsPayload(params))

        dispatch(pageAnalyticsSlice.actions.setReadTime({ pageId, data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(pageAnalyticsSlice.actions.setError({ type: 'readTime', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'readTime', isLoading: false }))
      }
    },

    fetchPageUsage: (pageId: string, params = {}) => async (dispatch) => {
      try {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'pageUsage', isLoading: true }))

        const response = await API.admin.analytics.pages.pageUsage(pageId, buildPageAnalyticsPayload(params))

        dispatch(pageAnalyticsSlice.actions.setPageUsage({ pageId, data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(pageAnalyticsSlice.actions.setError({ type: 'pageUsage', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(pageAnalyticsSlice.actions.setIsLoading({ type: 'pageUsage', isLoading: false }))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.pageAnalytics.meta,

  getUniquePageViews: () => (state: ReduxState) => state.pageAnalytics.uniquePageViews,

  getPopularPages: (order: string) => (state: ReduxState) => state.pageAnalytics.popularPages[order],

  getStalePages: () => (state: ReduxState) => state.pageAnalytics.stalePages,

  getInactiveOwnerPages: () => (state: ReduxState) => state.pageAnalytics.inactiveOwnerPages,

  getReadTime: (pageId: string) => (state: ReduxState) => state.pageAnalytics.readTime[pageId] || {},

  getPageUsage: (pageId: string) => (state: ReduxState) => state.pageAnalytics.pageUsage[pageId] || defaultPageUsage,
}

export default {
  ...pageAnalyticsSlice,
  selectors,
  asyncActions,
}
