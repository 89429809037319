import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import EmployeeSearch from 'components/form_fields/employeeSearch'
import { ButtonSmall } from 'components/common/buttons'
import userAccessManagementSlice from 'redux/slices/userAccessManagement'
import userSlice from 'redux/slices/users'
import { i18nPath, i18nFormat } from 'utils/i18nHelpers'
import PaginationContainer from 'components/common/tables/paginationContainer'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.user_external_alerts')

const PAGE_SIZE = 50

const UserExternalAlertsPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userAccessManagementSlice.asyncActions.fetchUsersApprovedForExternalAlerts({
      page: 1, perPage: PAGE_SIZE,
    }))
  }, [])

  const currentCompany = useCurrentCompany()
  const usersApprovedForAlerts = useSelector(userAccessManagementSlice.selectors.getUsersApprovedForExternalAlerts())
  const { isLoading, queryParams } = useSelector(userAccessManagementSlice.selectors.getUserExternalAlertsPageData())
  const {
    page, totalPages, total, perPage,
  } = queryParams

  const approveUserForExternalAlerts = (user) => {
    dispatch(userSlice.asyncActions.admin.approveExternalAlerts(user))
  }

  const unapproveUserForExternalAlerts = (user) => {
    dispatch(userSlice.asyncActions.admin.unapproveExternalAlerts(user))
  }

  const handlePaginationClick = (page) => {
    dispatch(userAccessManagementSlice.asyncActions.fetchUsersApprovedForExternalAlerts({ page, perPage: PAGE_SIZE }))
  }

  return (
    <div className='container my-4'>
      <Link to='/admin/cleary_admin'>{I18N('back_to_cleary_admin')}</Link>
      <h1>{I18N('title')}</h1>
      <div className='row'>
        <div className='col-md-6'>
          <h5 className='mb-4'>{I18N('subtitle')}</h5>
          <p>{I18N('description')}</p>
          <p>{i18nFormat(I18N('company_external_alerts_setting'), currentCompany.name, currentCompany.settings.externalAlerts.enabled ? I18N('on') : I18N('off'))}</p>
          <div className='mb-4'>
            <EmployeeSearch onChange={approveUserForExternalAlerts} placeholder={I18N('search_placeholder')} selectedEmployee={null} />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-9'>
          {!isLoading && _.isEmpty(usersApprovedForAlerts) && <div className='mb-2'>{I18N('zero_state')}</div>}


          {usersApprovedForAlerts.map(user => (
            <div className='row mt-2' key={user.id}>
              <div className='col-md-6'><Link to={`/admin/users/${user.id}`}>{user.preferredFullName}</Link></div>
              <div className='col-md-6'>
                <ButtonSmall className='btn-secondary-danger-sm' variant='' onClick={() => unapproveUserForExternalAlerts(user)}>{I18N('unapprove')}</ButtonSmall>
              </div>
            </div>
          ))}

          <PaginationContainer
            key='pagination'
            handleClick={handlePaginationClick}
            page={page}
            numPages={totalPages}
            totalCount={total}
            perPage={perPage}
          />
        </div>
      </div>
    </div>
  )
}

export default UserExternalAlertsPage
