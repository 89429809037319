import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import shoutoutsSlice from 'redux/slices/shoutouts'

import ShoutoutDetail from 'components/shoutouts/shoutoutDetail'
import { trackEvent } from 'services/tracker'

const ShoutoutPage = () => {
  const dispatch = useDispatch()
  const { shoutoutId } = useParams()

  const shoutout = useSelector(shoutoutsSlice.selectors.getShoutout(shoutoutId))
  const { isLoading } = useSelector(shoutoutsSlice.selectors.getMetaData())

  const [showShoutoutsModal, setShowShoutoutsModal] = useState(false)

  useEffect(() => {
    dispatch(shoutoutsSlice.asyncActions.fetchShoutout(shoutoutId))
    trackEvent('shoutout:view', { shoutout_id: shoutoutId })

    // Need to reset shoutouts state so the infinite scroller works correctly
    return () => dispatch(shoutoutsSlice.asyncActions.reset)
  }, [shoutoutId])

  return (
    <div className='ShoutoutPage mb-5'>
      <ShoutoutDetail
        isLoading={isLoading}
        shoutout={shoutout}
        showShoutoutsModal={showShoutoutsModal}
        setShowShoutoutsModal={setShowShoutoutsModal}
      />
    </div>
  )
}

export default ShoutoutPage
