import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { ButtonSmallNarrow } from 'components/common/buttons'
import GoLinkAliasEditable from 'components/links/goLinkAliasEditable'
import { LoadingContainer } from 'components/common/loadingContainer'
import NameTakenErrorMessage from 'components/links/nameTakenErrorMessage'
import classNames from 'classnames'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.golinks_editor')
const I18NCommon = i18nPath('views.common')

interface Alias {
  id: string
  name: string
  user: any
}

interface AliasSectionProps {
  goLinkId: string
}

const AliasSection: React.FC<AliasSectionProps> = ({ goLinkId }) => {
  const [aliases, setAliases] = useState<Alias[]>([])
  const [newAlias, setNewAlias] = useState('')
  const [showAliasForm, setShowAliasForm] = useState(false)
  const [nameTaken, setNameTaken] = useState(false)
  const newAliasInputRef = useRef<HTMLInputElement>(null)
  const { createAlias: createAliasQueryParam } = useQueryParams()

  const [fetchAliases, { isLoading: isFetchingAliases }] = useApi(API.golinks.aliases.fetchAll, {
    onSuccess: (aliases) => {
      setAliases(aliases)
      scrollToNewAlias()
    },
  })

  useEffect(() => {
    if (goLinkId) {
      fetchAliases({ goLinkId })
    }
  }, [goLinkId])

  const scrollToNewAlias = () => {
    if (createAliasQueryParam === 'true') {
      setShowAliasForm(true)
      setNewAlias('')
      setTimeout(() => {
        if (newAliasInputRef.current) {
          const offset = 300
          const elementPosition = newAliasInputRef.current.getBoundingClientRect().top + window.scrollY
          const offsetPosition = elementPosition - offset

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          })

          newAliasInputRef.current.focus()
        }
      }, 200)
    }
  }

  const [createAlias] = useApi(API.golinks.aliases.create, {
    toastSuccessMessage: I18N('alias_created'),
    onSuccess: (alias) => {
      setAliases([...aliases, alias])
      setNewAlias('')
      setShowAliasForm(false)
      setNameTaken(false)
    },
    onError: () => setNameTaken(true),
  })

  const handleNewAliasChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAlias(e.target.value.trim())
  }

  const handleAddNewAliasLink = () => {
    setShowAliasForm(true)
    setNewAlias('')
  }

  const handleCreateAlias = () => {
    if (newAlias) {
      createAlias({ goLinkId, name: newAlias })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleCreateAlias()
    }
  }

  return (
    <div className='row AliasSection'>
      <div className='form-group col-md-6'>
        <LoadingContainer isLoading={isFetchingAliases} useCirclesLoadingIndicator>
          <>
            {aliases.map(alias => (
              <GoLinkAliasEditable key={alias.id} goLinkId={goLinkId} alias={alias} setAliases={setAliases} />
            ))}
            {showAliasForm && (
              <div>
                <div className='input-group mb-2'>
                  <CdnSvg className='mr-2' src='/images/upward-corner-arrow.svg' />
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>
                      go/
                    </span>
                  </div>
                  <input
                    ref={newAliasInputRef}
                    name='newAlias'
                    className={classNames('form-control', nameTaken && 'is-invalid')}
                    value={newAlias}
                    onChange={handleNewAliasChange}
                    onKeyDown={handleKeyDown}
                    autoFocus
                  />
                </div>
                {nameTaken && <NameTakenErrorMessage />}
              </div>
            )}
            <div className='d-flex justify-content-between mt-2'>
              <Link to='#' onClick={handleAddNewAliasLink} className='btn btn-link mr-2 AliasSection_newAlias'>
                {I18N('new_alias')}
              </Link>
              {showAliasForm && (
                <div>
                  <ButtonSmallNarrow
                    className='mr-2 AliasSection_shrinkButton'
                    variant='secondary'
                    onClick={() => setShowAliasForm(false)}
                  >
                    {I18NCommon('cancel')}
                  </ButtonSmallNarrow>
                  <ButtonSmallNarrow onClick={handleCreateAlias} className='AliasSection_shrinkButton'>
                    {I18NCommon('save')}
                  </ButtonSmallNarrow>
                </div>
              )}
            </div>
          </>
        </LoadingContainer>
      </div>
    </div>
  )
}

export default AliasSection
