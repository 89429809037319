import React from 'react'
import { SurveyQuestionType, QuestionTypeEnum } from 'types/surveys/question'
import { AnswerTypeEnum } from 'types/surveys/answer'

interface MultipleOptionAnswerContentProps {
  question: SurveyQuestionType,
  answer?: any,
  updateAnswer?: (changes: any) => void,
  isDisabled?: boolean,
}

const MultipleOptionAnswerContent = ({
  answer,
  question,
  updateAnswer = (changes: any) => { },
  isDisabled = false,
} : MultipleOptionAnswerContentProps) => {
  const options = question?.options || []
  const answerOptionSelections = answer?.optionSelections || []
  const optionSelectionIds = answerOptionSelections.map(option => option?.questionOptionId)

  const isMultipleChoice = question?.type === QuestionTypeEnum.MultipleChoice

  const updateMultipleChoiceAnswer = (option) => {
    updateAnswer({
      optionSelections: [
        {
          questionOptionId: option.id,
        },
      ],
      type: AnswerTypeEnum.optionSelection,
    })
  }

  const updateCheckboxAnswer = (option) => {
    let newAnswerOptionSelections = answerOptionSelections

    if (optionSelectionIds.includes(option?.id)) {
      // eslint-disable-next-line max-len
      newAnswerOptionSelections = answerOptionSelections.filter(o => o.questionOptionId !== option?.id)
    } else {
      newAnswerOptionSelections = [...answerOptionSelections, { questionOptionId: option?.id }]
    }

    updateAnswer({ optionSelections: newAnswerOptionSelections, type: AnswerTypeEnum.optionSelection })
  }

  return (
    <div className='MultipleOptionAnswerContent mt-4'>
      {options.map(option => (
        <div
          className='d-flex align-items-center mb-2 pointer'
          onClick={() => (isMultipleChoice ? updateMultipleChoiceAnswer(option) : updateCheckboxAnswer(option))}
        >
          <input
            className='mr-2'
            type={isMultipleChoice ? 'radio' : 'checkbox'}
            checked={optionSelectionIds.includes(option?.id || '')}
            disabled={isDisabled}
          />
          <div className='mr-2'>{option?.value}</div>
        </div>
      ))}
    </div>
  )
}

export default MultipleOptionAnswerContent
