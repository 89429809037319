import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const BlueprintLink = ({ journeyBlueprint, className }) => (
  <div className={classNames('BlueprintLink', className)}>
    {journeyBlueprint?.name && (
      <Link to={`/admin/journeys/${journeyBlueprint?.id}`}>{journeyBlueprint?.name}</Link>
    )}
  </div>
)

export default BlueprintLink
