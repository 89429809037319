import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popover } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import appSlice from 'redux/slices/app_launcher/apps'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import settingSlice from 'redux/slices/settings'
import classNames from 'classnames'
import AppLauncherLayout from 'components/app_launcher/appLauncherLayout'
import CdnSvg from 'components/common/cdnSvg'

const appLauncherIconPath = '/images/app_launcher/appLauncherIcon.svg'
const listIconPath = '/images/app_launcher/listIcon.svg'
const gridIconPath = '/images/app_launcher/gridIcon.svg'

const APP_LAUNCHER_VIEW = 'app_launcher.view'

const I18N = i18nPath('views.app_launcher')

const AppLauncher = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const appLauncherToggleRef = useRef<any>(null)
  const appLauncherContentRef = useRef<any>(null)

  const [showAppLauncher, setShowAppLauncher] = useState(false)

  const apps = useSelector(appSlice.selectors.getUserApps())
  const { isLoadingUserApps } = useSelector(appSlice.selectors.getMetaData())
  const areAppsLoaded = useIsLoaded(isLoadingUserApps)

  const setting = useSelector(settingSlice.selectors.getSetting(APP_LAUNCHER_VIEW))
  const { isLoadingUserSetting } = useSelector(settingSlice.selectors.getMetaData())
  const showGridView = setting?.value?.grid

  const isLoading = isLoadingUserApps || isLoadingUserSetting

  const handleClickOutside = (e) => {
    const toggleRef = appLauncherToggleRef.current
    const contentRef = appLauncherContentRef.current

    const isOutsideClick = toggleRef && contentRef && !toggleRef.contains(e.target) && !contentRef.contains(e.target)

    if (isOutsideClick) {
      setShowAppLauncher(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [])

  const toggleAppLauncher = () => {
    if (showAppLauncher) {
      setShowAppLauncher(false)
      return
    }

    if (!areAppsLoaded) {
      dispatch(settingSlice.asyncActions.fetch(APP_LAUNCHER_VIEW))
      dispatch(appSlice.asyncActions.fetchAll())
    }

    setShowAppLauncher(true)
  }


  const setGridView = (value) => {
    dispatch(settingSlice.asyncActions.update(APP_LAUNCHER_VIEW, { grid: value }))
  }

  return (
    <div className='AppLauncher Navbar-navItemContainer' onClick={toggleAppLauncher} ref={appLauncherToggleRef}>
      <div className='AppLauncherIcon' id='app-launcher-icon'>
        <CdnSvg src={appLauncherIconPath} />
      </div>

      <Popover
        placement='bottom'
        isOpen={showAppLauncher}
        target='app-launcher-icon'
        className='AppLauncher-popover'
      >
        <div ref={appLauncherContentRef}>
          <header className='p-3 d-flex justify-content-between'>
            <h5 className='m-0'>{I18N('header')}</h5>
            {!isLoadingUserApps && (
              <div className='d-flex'>
                <div
                  onClick={() => setGridView(false)}
                  className={classNames('ListIcon', 'pointer', { Selected: !showGridView })}
                >
                  <CdnSvg src={listIconPath} />
                </div>
                <div
                  onClick={() => setGridView(true)}
                  className={classNames('GridIcon', 'pointer', { Selected: showGridView })}
                >
                  <CdnSvg src={gridIconPath} />
                </div>
              </div>
            )}
          </header>
          {isLoading ? (
            <CirclesLoadingIndicator />
          ) : (
            <AppLauncherLayout apps={apps} history={history} dispatch={dispatch} showGridView={showGridView} />
          )}
        </div>
      </Popover>
    </div>
  )
}

export default AppLauncher
