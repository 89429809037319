import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import PreviewTemplateModal from 'components/admin/articles/templates/previewTemplateModal'
import CdnSvg from 'components/common/cdnSvg'
import Article from 'components/articles/article'
import { useDispatch } from 'react-redux'
import navigationBlockerSlice from 'redux/slices/navigationBlocker'
import Tooltip from 'components/common/clearyTooltip'
import classNames from 'classnames'
import { showToastMessage } from 'redux/slices/toasts'
import FeatureAnnouncementWidget, { FeatureAnnouncementTrigger } from 'components/common/featureAnnouncementBadgeWithPopover'

const I18N = i18nPath('views.admin.article_editor.templates_sidebar')

type Props = {
  template: {
    id: string
    title: string
    aiTemplate?: boolean
  }
  gridView?: boolean
}

const TemplateItem = ({ template, gridView = false }: Props) => {
  const { articleId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const [showPreviewModal, setShowPreviewModal] = useState(false)

  const [createArticle, { isLoading: isCreating }] = useApi(API.admin.articles.create, {
    toastSuccessMessage: I18N('create_article_success_message'),
    onError: (error) => {
      const errorMessage = error === 'no_new_employees_for_article' ? I18N('no_new_employees_for_article') : I18N('create_article_error_message')

      dispatch(showToastMessage({ message: errorMessage, type: 'error' }))
    },
    onSuccess: (article) => {
      dispatch(navigationBlockerSlice.asyncActions.performWithoutBlocking(() => {
        history.push(`/admin/articles/${article.id}`)
      }))
    },
  })

  const [applyTemplate, { isLoading: isApplyingTemplate }] = useApi(API.admin.articles.applyTemplate, {
    toastSuccessMessage: I18N('apply_template_success_message'),
    onError: (error) => {
      const errorMessage = error === 'no_new_employees_for_article' ? I18N('no_new_employees_for_article') : I18N('apply_template_error_message')

      dispatch(showToastMessage({ message: errorMessage, type: 'error' }))
    },
    onSuccess: () => {
      dispatch(navigationBlockerSlice.asyncActions.performWithoutBlocking(() => {
        window.location.reload()
      }))
    },
  })

  const isLoading = isCreating || isApplyingTemplate

  const onApplyTemplate = () => {
    if (isLoading) return

    if (articleId === 'new') {
      createArticle({ templateId: template.id })
    } else {
      applyTemplate(articleId, template.id)
    }
  }

  return (
    <div
      key={template.id}
      className='TemplateItem'
    >
      {gridView ? (
        <CardTemplateItem
          template={template}
          isLoading={isLoading}
          openPreviewModal={() => setShowPreviewModal(true)}
          onApplyTemplate={onApplyTemplate}
        />
      ) : (
        <TextTemplateItem
          template={template}
          isLoading={isLoading}
          openPreviewModal={() => setShowPreviewModal(true)}
          onApplyTemplate={onApplyTemplate}
        />
      )}
      {showPreviewModal && (
        <PreviewTemplateModal
          templateId={template.id}
          onClose={() => setShowPreviewModal(false)}
          applyTemplate={onApplyTemplate}
          isApplyingTemplate={isLoading}
        />
      )}
    </div>
  )
}

const AiTooltip = ({ className = '' }) => (
  <Tooltip
    content={(
      <span>
        <CdnSvg
          className={classNames('aiIcon', className)}
          src='/images/tiptap/ai.svg'
        />
      </span>
    )}
    placement='bottom'
  >
    <span>{I18N('ai_template_tooltip')}</span>
  </Tooltip>
)

const PreviewAndApplyButtons = ({ isLoading, openPreviewModal, onApplyTemplate }) => (
  <div className='PreviewApply text-small pointer text-color-secondary'>
    {isLoading ? (
      <span>{I18NCommon('loading')}</span>
    ) : (
      <>
        <span onClick={openPreviewModal} className='link-color-on-hover'>{I18NCommon('preview')}</span>
        <span>&nbsp;|&nbsp;</span>
        <span className='link-color-on-hover' onClick={onApplyTemplate}>
          {I18NCommon('apply')}
        </span>
      </>
    )}
  </div>
)

const TextTemplateItem = ({
  template,
  isLoading,
  openPreviewModal,
  onApplyTemplate,
}) => (
  <div className='TextTemplateItem bg-white bordered text-small p-2 mb-2 d-flex justify-content-between align-items-center'>
    <div className='text-small'>
      {template.aiTemplate && <AiTooltip className='mr-1' />}
      {template.title}
    </div>
    {template.hasSocialShare && template.baseTemplate ? (
      <FeatureAnnouncementWidget
        popoverId={`template-popover-${template.id}`}
        title={I18N('employee_advocacy')}
        body={I18N('employee_advocacy_content')}
        placement='right'
      >
        <div className='PreviewAndApplyButtons ml-2'>
          <div className='NewBadgeWrapper'>
            <FeatureAnnouncementTrigger />
          </div>

          <PreviewAndApplyButtons
            isLoading={isLoading}
            openPreviewModal={openPreviewModal}
            onApplyTemplate={onApplyTemplate}
          />
        </div>
      </FeatureAnnouncementWidget>
    ) : (
      <div className='PreviewAndApplyButtons ml-2'>
        <PreviewAndApplyButtons
          isLoading={isLoading}
          openPreviewModal={openPreviewModal}
          onApplyTemplate={onApplyTemplate}
        />
      </div>
    )}
  </div>
)

const CardTemplateItem = ({
  template,
  isLoading,
  openPreviewModal,
  onApplyTemplate,
}) => (
  <div className='CardTemplateItem d-flex flex-column align-items-center p-2'>
    <div className='ArticlePreview position-relative mb-2'>
      <Article
        article={template}
        tracking={false}
      />
      {template.aiTemplate && (
        <div className='ai-tooltip-container d-flex align-items-center justify-content-center'>
          <AiTooltip />
        </div>
      )}
      {/* element is hidden by css, will only show up on hover */}
      <div className='PreviewAndApplyButtons position-absolute top-0 left-0 d-flex flex-column justify-content-around align-items-center p-2'>
        <div
          className='ApplyButton text-smallest pointer p-1'
          onClick={onApplyTemplate}
        >
          {isLoading ? (
            I18NCommon('loading')
          ) : (
            <>
              <CdnSvg src='/images/pencilIcon.svg' className='mr-1' />
              {I18NCommon('apply')}
            </>
          )}
        </div>
        <div
          className='PreviewButton text-smallest pointer p-1'
          onClick={openPreviewModal}
        >
          <CdnSvg src='/images/eyeOpenIcon.svg' className='mr-1' />
          {I18NCommon('preview')}
        </div>
      </div>
    </div>
    <div className='text-smallest text-center font-weight-300'>
      {template.title}
    </div>
  </div>
)

export default TemplateItem
