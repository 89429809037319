import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import slackWidgetSlice from 'redux/slices/slackWidgetSlice'

const useHideSlackWidget = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(slackWidgetSlice.actions.increment())

    return () => {
      dispatch(slackWidgetSlice.actions.decrement())
    }
  }, [])
}

export default useHideSlackWidget
