import React from 'react'
import ReactSelect from 'components/common/react_select'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.user_skills')

const UserSkillForm = ({ userSkill, userSkillTypes, setUserSkill }) => {
  const handleChange = (fieldName, value) => {
    setUserSkill({
      ...userSkill,
      [fieldName]: value,
    })
  }

  const generateChangeHandler = fieldName => (e) => {
    handleChange(fieldName, e.target.value)
  }

  const selectChangeHandler = (selectedValue) => {
    handleChange('userSkillTypeId', selectedValue.id)
  }

  return (
    <div className='w-100'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='form-group col-lg-7 col-md-12'>
              <label className='required-form-label' htmlFor='name'>{I18N('form.name')}</label>
              <input id='name' value={userSkill.name} onChange={generateChangeHandler('name')} />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row'>
            <div className='form-group col-lg-7 col-md-12'>
              <label className='required-form-label'>{I18N('form.type')}</label>
              <ReactSelect
                value={userSkillTypes.find(option => option.value === userSkill.userSkillTypeId)}
                onChange={selectChangeHandler}
                options={userSkillTypes}
                isSearchable
                isClearable={false}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSkillForm
