import React from 'react'

import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from './searchResultOption'

const confluenceLogoPath = '/images/confluence.svg'

const I18N = i18nPath('views.search.global_search')

const ConfluenceOption = ({ option }) => (
  <SearchResultOption
    image={<CdnSvg src={confluenceLogoPath} className='SearchResultOptionImg mr-1' />}
    title={option.title}
    secondaryTexts={[I18N('last_modified', { date: i18nMoment(option.updatedAt).fromNow() })]}
  />
)

export default ConfluenceOption
