import React from 'react'

import { i18nMoment, i18nPath, i18nFormat } from 'utils/i18nHelpers'

import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from './searchResultOption'
import QnaCreatorContent from './qnaCreatorContent'

const I18N = i18nPath('views.search.global_search')
const answerIconPath = '/images/answerIcon.svg'

const QnaAnswerOption = ({ option }) => {
  const { qnaAnswer, highlights } = option
  const { content, createdAt } = { ...qnaAnswer, ...highlights }

  const answerCreatedAt = i18nMoment(createdAt).fromNow()

  return (
    <SearchResultOption
      image={<CdnSvg src={answerIconPath} className='SearchResultOptionImg mr-1' />}
      title={content}
      secondaryTexts={[i18nFormat(I18N('answered_by'), <QnaCreatorContent questionOrAnswer={qnaAnswer} />, answerCreatedAt)]}
    />
  )
}

export default QnaAnswerOption
