import React from 'react'

import { Redirect, useHistory, useParams } from 'react-router-dom'

import useQueryParams from 'components/common/hooks/useQueryParams'
import PillTabSelector from 'components/common/pillTabSelector'

const HistoryChangingPillTabSelector = ({
  tabs: propsTabs,
  paramName = 'selectedTab',
  baseUrl = '',
  historyKeyToChange = 'search', // one of search or pathname, if using pathname you must specify the baseUrl
  ...props
}) => {
  const history = useHistory()

  const queryParams = useQueryParams()
  const routeParams = useParams()
  const param = historyKeyToChange === 'search' ? queryParams[paramName] : routeParams[paramName]

  const newHistoryUrl = (tab) => {
    const searchParams = new URLSearchParams(history.location.search)

    if (historyKeyToChange === 'search') {
      searchParams.set(paramName, tab.id)

      // let's build the url as string
      return `${history.location.pathname}?${searchParams.toString()}`
    }

    const searchString = searchParams.toString() ? `?${searchParams.toString()}` : ''

    return `${baseUrl}/${tab.id}${searchString}`
  }

  const tabs = propsTabs.map(tab => ({
    ...tab,
    url: newHistoryUrl(tab),
  }))


  const handleTabClick = (tab) => {
    history.push(tab.url)

    if (tab.onClick) {
      tab.onClick()
    }
  }

  if (!param) {
    const firstTab = tabs.find(tab => !tab.hidden)

    return <Redirect to={firstTab.url} />
  }

  return (
    <PillTabSelector
      defaultSelectedIndex={tabs.filter(tab => !tab.hidden).findIndex(tab => tab.id === param)}
      tabs={tabs.map(tab => ({
        ...tab,
        onClick: () => handleTabClick(tab),
      }))}
      {...props}
    />
  )
}

export default HistoryChangingPillTabSelector
