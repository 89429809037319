import React from 'react'

const PetsIcon = ({ fill = 'var(--text-color-primary)' }) => (
  <svg
    width='39px'
    height='35px'
    viewBox='0 0 46 43'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-36.000000, -20.000000)' fill={fill} fillRule='nonzero'>
        <g transform='translate(40.000000, 22.000000)'>
          <path d='M21.9938859,19.3103448 C21.6657609,19.3103448 21.3994565,19.5806897 21.3994565,19.9137931 C21.3994565,20.2468966 21.6657609,20.5172414 21.9938859,20.5172414 C22.3220109,20.5172414 22.5883152,20.2468966 22.5883152,19.9137931 C22.5883152,19.5806897 22.3220109,19.3103448 21.9938859,19.3103448 Z' />
          <path d='M28.5069973,23.1096115 C27.8167132,22.3927394 26.1426968,20.4524639 24.82207,17.3058477 C23.8400314,14.8575103 21.5564384,13.2758621 19.0025932,13.2758621 C16.4492635,13.2758621 14.1660386,14.8577399 13.1837792,17.3066128 C11.5269205,21.2552626 9.26343061,23.3448961 9.24170735,23.3646371 C9.23905638,23.3670091 9.23647904,23.3694576 9.2339017,23.3718295 C7.8988418,24.6139028 7.13315217,26.3931901 7.13315217,28.2534307 C7.13315217,30.5275426 8.1753531,32.5052342 9.9833173,33.7583257 C11.6678639,34.9261026 13.7557268,35.2762367 15.7111882,34.7191286 C16.6911649,34.4401537 17.8254135,34.2926322 18.9915475,34.2926322 C20.1773428,34.2926322 21.328381,34.4450507 22.3202134,34.7333605 C24.2182371,35.2854185 26.3015343,34.9827239 28.0213537,33.8020159 C29.8500838,32.546476 30.9103261,30.5501147 30.9103261,28.2534307 C30.9103261,26.2335027 30.0350629,24.3615552 28.5069973,23.1096115 Z M27.209272,32.5252047 C25.8820914,33.4363492 24.2447468,33.7039998 22.7169758,33.2596753 C21.5976021,32.9342556 20.3093761,32.7623257 18.9913266,32.7623257 C17.6955894,32.7623257 16.4267302,32.92867 15.3216423,33.243301 C13.7777446,33.6831876 12.1298698,33.4068908 10.8004064,32.4853402 C9.41232697,31.52316 8.60569453,30.0189453 8.60569453,28.2533542 C8.60569453,26.8290979 9.19155976,25.4665896 10.213142,24.5145859 C10.3702858,24.3714257 12.7599915,22.1483495 14.536144,17.9120786 C14.5377641,17.9080998 14.5394578,17.904121 14.5410042,17.9001422 C15.2927025,16.0206198 17.0438181,14.8061685 19.0022987,14.8061685 C20.9615156,14.8061685 22.7129994,16.0203137 23.4644768,17.899377 C23.4660232,17.9033558 23.4677169,17.9073346 23.4693369,17.9113134 C24.911614,21.3506772 26.7686212,23.4739775 27.4999955,24.2273474 C27.521056,24.2489247 27.5432947,24.2692778 27.5665644,24.2881005 C28.7555261,25.2479853 29.4373419,26.6932832 29.4373419,28.2533542 C29.4373419,30.0336362 28.6177491,31.5582806 27.209272,32.5252047 Z' />
          <path d='M23.7682711,22.0643769 C23.5747959,21.7179657 23.1771897,21.6198146 22.8800443,21.8455397 C22.5828988,22.0713396 22.4989355,22.5351427 22.6924106,22.8815539 C23.6692226,24.6306796 24.5953869,25.7956155 25.0606516,26.3343609 C25.3121565,26.6255948 25.7186212,26.6240226 25.9685212,26.3305426 C26.2182929,26.0371375 26.2168165,25.5630775 25.9652474,25.2716938 C25.5361871,24.7748741 24.6802489,23.697608 23.7682711,22.0643769 Z' />
          <path d='M15.8128004,10.0173368 C16.4909445,8.7853613 16.7736634,7.14906012 16.5884776,5.52845665 C16.2302447,2.37329359 14.1366714,-0.269860586 11.7976422,0.0220871732 C10.741434,0.151996591 9.80237832,0.872623422 9.15302831,2.05119745 C8.47530761,3.28221939 8.19258877,4.91852056 8.37735111,6.5402977 C8.96247891,11.6930289 13.5775148,14.0757775 15.8128004,10.0173368 Z M10.3780492,2.77996655 C10.7643705,2.07848503 11.3670006,1.46847559 12.143807,1.46847559 C13.532771,1.46847559 14.9263222,3.40640722 15.1868809,5.70091121 C15.3372032,7.01636327 15.1188477,8.32403983 14.58785,9.28864109 C12.9613345,12.2421548 10.1633636,9.75208982 9.77901837,6.36806319 C9.62904894,5.05158419 9.84747501,3.74376092 10.3780492,2.77996655 Z' />
          <path d='M9.43792628,18.3242975 C9.65171507,17.0251307 9.39191923,15.5883425 8.70646583,14.2785334 C8.02071705,12.9684327 6.98374909,11.9277434 5.78645801,11.3481006 C4.51487624,10.7324488 3.21264771,10.7006675 2.11970328,11.2585155 C1.02675884,11.815926 0.299950796,12.8825651 0.0729432834,14.2622054 C-0.14084551,15.5614451 0.118950336,16.9981604 0.804403731,18.3081153 C1.49022636,19.6178515 2.52719432,20.6583221 3.72448541,21.2379649 C4.91269324,21.8132342 6.2291005,21.9207509 7.39101859,21.3282061 C8.48411072,20.7707955 9.21106646,19.7039378 9.43792628,18.3242975 Z M6.7131715,20.0329755 C6.0463277,20.3732387 5.21598532,20.3362821 4.37493504,19.9291034 C3.45996331,19.4861344 2.65797841,18.6726517 2.11660168,17.6388144 C1.57552034,16.6048313 1.36741781,15.488771 1.53076869,14.4958994 C1.68090086,13.5834285 2.13092811,12.8937905 2.79784576,12.5536731 C2.79791961,12.5536002 2.79799346,12.5536002 2.7980673,12.5535274 C3.53550941,12.1771823 4.38963071,12.2955601 5.13608222,12.6569621 C6.0509801,13.0999311 6.852965,13.9134867 7.39434173,14.9476884 C7.93542308,15.9816715 8.1435256,17.0978776 7.98017472,18.0906763 C7.8301164,19.0031473 7.38008915,19.6928581 6.7131715,20.0329755 Z' />
          <path d='M25.2206641,12.0689655 C27.3602074,12.0689655 29.3132862,9.64058659 29.6669478,6.54036447 C30.0427706,3.23771629 28.4420831,0 25.8995448,0 C23.7596485,0 21.8067815,2.4284523 21.4539668,5.52860105 C21.0777206,8.83726551 22.6831368,12.0689655 25.2206641,12.0689655 Z M22.8557682,5.70094567 C23.1170445,3.40609896 24.5109413,1.46731374 25.8996859,1.46731374 C25.9650403,1.46731374 26.0213608,1.47061536 26.0718234,1.47743871 C26.8243865,1.5643814 27.4069295,2.24811042 27.7500751,2.94621984 C28.2168717,3.89569267 28.4046067,5.14282498 28.2652876,6.36772637 C27.9940599,8.74518697 26.478206,10.7843415 25.0485972,10.5914534 C25.0459859,10.59116 25.0433745,10.5907931 25.0407632,10.5904263 C24.3990061,10.5119211 23.790279,9.97705845 23.370769,9.12303915 C22.9041135,8.17349295 22.7164491,6.92621391 22.8557682,5.70094567 Z' />
          <path d='M37.9706577,14.2623653 C37.5036332,11.4225504 34.9036859,10.0649868 32.2569918,11.3473003 C29.7808902,12.5466347 28.1629943,15.6361164 28.6054269,18.3258687 C29.0779902,21.1992982 31.7038587,22.5072056 34.319019,21.2404962 C36.7958591,20.0409431 38.4130166,16.9518259 37.9706577,14.2623653 Z M33.6685521,19.9311305 C32.8275535,20.3383704 31.9971891,20.3751933 31.3302538,20.0350368 L31.3302538,20.0351097 C30.6633185,19.6948074 30.2132793,19.0047234 30.0632171,18.0920977 C29.7255769,16.0390543 31.0112976,13.5751251 32.9074587,12.6564473 C34.6451386,11.8147695 36.2021087,12.6072285 36.5127938,14.4960634 C36.8498431,16.5453151 35.5681841,19.011213 33.6685521,19.9311305 Z' />
        </g>
      </g>
    </g>
  </svg>
)

export default PetsIcon
