import React from 'react'
import { PropertyListValue } from 'components/admin/customStyles/utils'
import ColorPicker from './colorPicker'

interface Props {
  colorVariables: PropertyListValue[]
  handlePropertyChanged: (type: string, property: string, value: string) => void
}

const ColorItem = ({ colorVariables, handlePropertyChanged }: Props) => (
  <div className='row'>
    <div className='col-12'>
      <div className='d-flex flex-wrap justify-content-between'>
        {colorVariables.map(([variable, color]) => (
          <ColorPicker
            key={variable}
            variable={variable}
            color={color}
            onChange={handlePropertyChanged}
          />
        ))}
      </div>
    </div>
  </div>
)

export default ColorItem
