import React, { useEffect, useState } from 'react'

import HamburgerMenuIcon from 'components/icons/hamburgerMenuIcon'
import CompanyLogo from 'components/common/companyLogo'
import ProfileDropdown from 'components/navbar/profileDropdown'
import NotificationBell from 'components/notifications/notificationBell'
import MobileLeftNav from 'components/navbar/mobileLeftNav'
import CloseIcon from 'components/icons/closeIcon'
import { useDispatch, useSelector } from 'react-redux'
import newsSlice from 'redux/slices/news'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import classNames from 'classnames'

const MobileNav = ({
  navLinks,
  peopleLinks,
  inPreboardingExperience,
}) => {
  const dispatch = useDispatch()
  const { settings } = useCurrentCompany()
  const [isMobileLeftNavOpen, setIsMobileLeftNavOpen] = useState(false)

  const toggleMobileLeftNav = (e) => {
    e.stopPropagation()
    setIsMobileLeftNavOpen(!isMobileLeftNavOpen)
  }

  const articleTypes = useSelector(newsSlice.selectors.getArticleTypes())

  useEffect(() => {
    if (settings.news.enabled) dispatch(newsSlice.asyncActions.fetchArticleTypes())
  }, [])

  return (
    <>
      <div className='MobileNav d-flex flex-row justify-content-center align-items-center py-3 px-4'>
        <div className='left-container d-flex flex-row justify-content-start align-items-center'>
          <div className='Navbar-burger' onClick={toggleMobileLeftNav}>
            <HamburgerMenuIcon className={classNames({ open: isMobileLeftNavOpen })} height={20} width={20} />
          </div>
        </div>

        <CompanyLogo className='Navbar-companyImage' />

        <div className='right-container d-flex flex-row justify-content-end align-items-center'>
          {isMobileLeftNavOpen ? (
            <div className='position-static'>
              <CloseIcon className='CloseIcon' />
            </div>
          ) : (
            <>
              {!inPreboardingExperience && (<NotificationBell />)}
              <ProfileDropdown inPreboardingExperience={inPreboardingExperience} />
            </>
          )}
        </div>

      </div>
      {isMobileLeftNavOpen && (
        <MobileLeftNav
          navLinks={navLinks}
          peopleLinks={peopleLinks}
          articleTypes={articleTypes}
          setIsMobileLeftNavOpen={setIsMobileLeftNavOpen}
          inPreboardingExperience={inPreboardingExperience}
        />
      )}
    </>
  )
}

export default MobileNav
