import React from 'react'

import API from 'services/api'
import { AsyncSelect } from 'components/common/react_select'
import { I18NCommon } from 'utils/i18nHelpers'

const CalendarEventsAsyncSelect = ({
  onChange,
  externalEventId = '',
  externalEventName = '',
  calendarId = '',
  calendarType = 'google',
  ...props
}) => {
  const buildCalendarEventOption = (calendarEvent) => {
    if (!calendarEvent) return ''

    return {
      value: calendarEvent.id,
      label: calendarEvent.summary,
      externalCalendarId: calendarEvent.sourceCalendarId,
      externalEventUrl: calendarEvent.htmlLink,
    }
  }

  const buildCurrentValue = () => {
    if (!externalEventId) return ''
    return { value: externalEventId, label: externalEventName }
  }

  const loadCalendarEvents = (query = '', callback) => {
    API.admin.calendars.upcomingEvents({
      q: query, calendarId, organizerId: calendarId, calendarType,
    })
      .then((response) => {
        const events = response?.data?.events || []
        const options = events.map(buildCalendarEventOption)

        callback(options)
      })
  }

  return (
    <AsyncSelect
      id='cy_calendar_events_select'
      key={`calendar_select_${calendarId}`}
      aria-label='Type to search for calendar events'
      value={buildCurrentValue()}
      onChange={onChange}
      getOptions={loadCalendarEvents}
      minCharsToSearch={0}
      placeholder={I18NCommon('select_or_type_to_search')}
      defaultOptions
      isClearable
      {...props}
    />
  )
}

export default CalendarEventsAsyncSelect
