import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { Creatable } from 'components/common/react_select'
import FileSelectDropzone from 'components/common/file_select_dropzone'
import companyConfigurationSlice, { GOOGLE_DRIVE_BLOCK_LIST } from 'redux/slices/companyConfigurations'
import { Button } from 'components/common/buttons'
import { showToastMessage } from 'redux/slices/toasts'

const I18N = i18nPath('views.integrations_page.google_drive')

const GoogleDriveIntegrationPage = () => {
  const dispatch = useDispatch()
  const [blockedEmails, setBlockedEmails] = useState([])

  const { isSaving, isLoading } = useSelector(companyConfigurationSlice.selectors.getMetaData())
  const blockList = useSelector(companyConfigurationSlice.selectors.getCompanyConfiguration(GOOGLE_DRIVE_BLOCK_LIST))

  const onSaveSuccess = () => dispatch(showToastMessage({ message: I18N('block_list_saved'), type: 'success' }))
  const onSaveError = () => dispatch(showToastMessage({ message: I18N('failed_to_save_block_list'), type: 'error' }))

  const handleSaveChanges = () => {
    dispatch(companyConfigurationSlice.asyncActions.admin.update(
      GOOGLE_DRIVE_BLOCK_LIST,
      { userEmails: blockedEmails },
      {
        onSuccess: onSaveSuccess,
        onError: onSaveError,
      }
    ))
  }

  useEffect(() => {
    dispatch(companyConfigurationSlice.asyncActions.admin.fetch(GOOGLE_DRIVE_BLOCK_LIST))
  }, [])

  useEffect(() => {
    if (blockList?.value) {
      setBlockedEmails(blockList.value.userEmails)
    }
  }, [JSON.stringify(blockList?.value)])

  const onDrop = async (files) => {
    if (files.length === 0) {
      return
    }

    const newFile = files[0]
    const text = await newFile.text()

    setBlockedEmails(_.union(blockedEmails, text.trim().split('\n')).sort())
  }

  return (
    <div className='GoogleDriveIntegrationPage'>
      <header className='AdminHeader'>
        <h3 className='mb-1'>{I18N('header')}</h3>
      </header>
      <main className='AdminContent'>
        {isLoading ? (
          <CirclesLoadingIndicator />
        ) : (
          <>
            <h4>{I18N('block_list')}</h4>
            <div>{I18N('block_list_description')}</div>
            <div className='mt-2 text-secondary'>{I18N('select_csv_description')}</div>
            <FileSelectDropzone
              data-testid='cy_dropzone_file_selector'
              onDrop={onDrop}
              accept='.csv'
              disabled={isLoading}
              className='CsvSelector-dropzone mb-1'
            >
              <span
                className='text-secondary'
                data-cy='upload-file-text'
                dangerouslySetInnerHTML={{ __html: I18n.t('views.admin.includes.audience_selector.csv_list_import.drop_files_here') }}
              />
            </FileSelectDropzone>
            <Creatable
              isMulti
              value={blockedEmails.map(v => ({ label: v, value: v }))}
              onChange={selectedOptions => setBlockedEmails(selectedOptions.map(so => so.value))}
              placeholder={I18N('block_list_placeholder')}
            />
            <div className='d-flex justify-content-end mt-5'>
              <Button
                onClick={handleSaveChanges}
                showLoadingSpinner={isSaving}
              >
                {I18NCommon('save_changes')}
              </Button>
            </div>
          </>
        )}
      </main>
    </div>
  )
}

export default GoogleDriveIntegrationPage
