import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import PageType from 'types/page'
import PageWorkspaceType from 'types/pageWorkspace'
import { i18nPath } from 'utils/i18nHelpers'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import useCreateDraftPage from 'components/pages/hooks/useCreateDraftPage'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CdnSvg from 'components/common/cdnSvg'
import { usePagesNav } from 'components/pages/pagesNav/context'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import PagesNavComplexTree from './pagesNavComplexTree'

const chevronDownIconPath = '/images/chevronDownOutline.svg'
const rightArrowLogOutIconPath = '/images/rightArrowLogOutIcon.svg'
const plusIconPath = '/images/plusIcon.svg'

const I18N = i18nPath('views.pages.workspaces')

interface WorkspaceProps {
  index: number
  currentPage: PageType
  workspacePages: PageType[]
  workspace: PageWorkspaceType
  showPages?: boolean
  toggleShowPages?: () => void
}

const Workspace = ({
  index,
  currentPage,
  workspacePages,
  showPages = false,
  toggleShowPages = () => { },
  workspace,
}: WorkspaceProps) => {
  const { workspaceSlug, pageSlug } = useParams()
  const history = useHistory()
  const canDrag = useCurrentUser()?.permissions.pageWorkspaceManager
  const createPage = useCreateDraftPage({ pageWorkspaceId: workspace?.id })
  const { expandResource, showExpandingState } = usePagesNav()
  const isCurrentPageInWorkspace = currentPage?.pageWorkspaceId === (workspace?.id || '')
  const canCreatePages = workspace?.permittedActions?.canCreateAndMovePagesInWorkspace || false
  // If the page is in the workspace, we'll maintain the page when going to the workspace focused view
  // Otherwise, we'll just go to the workspace and get the first page
  const focusedWorkspaceUrl = `${workspace.displayPath}/${!!pageSlug && isCurrentPageInWorkspace ? pageSlug : ''}`

  const openWorkspace = () => {
    expandResource('workspace', workspace.id)

    history.push(focusedWorkspaceUrl)
  }

  const showExpandingIndicator = () => showExpandingState('workspace', workspace.id as string)
    && _.isEmpty(workspacePages)

  const showNothingHere = () => !showExpandingState('workspace', workspace.id as string)
    && _.isEmpty(workspacePages)

  return (
    <Draggable draggableId={`workspace-${workspace.id}`} index={index} isDragDisabled={!canDrag}>
      {provided => (
        <div className='Workspace mb-3' {...provided.draggableProps} ref={provided.innerRef}>
          <div className='WorkspaceHeader d-flex justify-content-between p-0' {...provided.dragHandleProps}>
            <div className='d-flex align-items-center pointer' onClick={toggleShowPages}>
              <div className={classNames('ToggleButton ml-0', { showPages })}>
                <CdnSvg src={chevronDownIconPath} className='ChevronDownIcon' />
              </div>
              <EmojiOrPlaceholder selectedEmoji={workspace.emojiCode} className='mr-2' />
              <span className='WorkspaceName truncate-text-at-1-lines'>{workspace.title}</span>
            </div>
            <div className='WorkspaceOwnerActions mr-3 mt-1'>
              {!workspaceSlug && (
                <IconButtonWithPopover
                  id='open-workspace-button'
                  text={I18N('open_workspace')}
                  icon={<CdnSvg src={rightArrowLogOutIconPath} className='RightArrowLogOutIcon' />}
                  onClick={openWorkspace}
                  className='OpenWorkspaceButton'
                  popoverClassName='OpenWorkspacePopover p-2'
                  textClassName='text-white text-smallest'
                />
              )}
              {canCreatePages && (
                <IconButtonWithPopover
                  id='create-page-button'
                  text={I18N('create_page')}
                  icon={<CdnSvg src={plusIconPath} className='PlusIcon d-flex align-items-center' />}
                  onClick={createPage}
                  className='CreatePageButton'
                  popoverClassName='CreatePagePopover p-2'
                  textClassName='text-white text-smallest'
                />
              )}
            </div>
          </div>

          {showPages && showNothingHere() && (
            <div className='text-secondary text-small py-2 px-3 ml-4'>{I18N('nothing_here')}</div>
          )}

          {showPages && showExpandingIndicator() && (
            <LoadingIndicator />
          )}

          {showPages && (
            <PagesNavComplexTree
              pages={workspacePages}
              workspace={workspace}
              treeId={`workspace-${workspace.id}`}
            />
          )}
        </div>
      )}
    </Draggable>
  )
}

export default Workspace
