import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import IntegrationCard from 'components/admin/integrations/integrationCard'
import { ButtonSmallNarrow } from 'components/common/buttons'
import itAdminSlice from 'redux/slices/itAdmin'
import WorkosInstallationComponent from 'components/admin/integrations/workosInstallationComponent'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.integrations_page.workos_integration_card')

const WorkosIntegrationCard = () => {
  const { workosConfiguration, domain } = useSelector(itAdminSlice.selectors.getData())

  const [isMakingChangesToWorkos, setIsMakingChangesToWorkos] = useState(false)

  return (
    <IntegrationCard
      id='workos'
      logo={<CdnSvg src='/images/integrations/workosLogo.svg' />}
      isInstalled={!!workosConfiguration?.authType && !isMakingChangesToWorkos}
      installationComponent={<WorkosInstallationComponent savedDomain={domain} />}
      uninstallationComponent={(
        <div className='d-flex justify-content-end'>
          <ButtonSmallNarrow onClick={() => setIsMakingChangesToWorkos(!isMakingChangesToWorkos)} id='workos-make-changes-btn'>
            {I18N('make_changes')}
          </ButtonSmallNarrow>
        </div>
      )}
      customInstallationMessage={I18N('configured_sso_with', { authType: workosConfiguration?.authType })}
    />
  )
}

export default WorkosIntegrationCard
