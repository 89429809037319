import React from 'react'
import classNames from 'classnames'

import CdnSvg from 'components/common/cdnSvg'

const ToggleLeftNavButton = ({
  onClick,
  className = '',
  isOpen = true,
}) => (
  <button
    className={classNames('ToggleLeftNavButton', { isOpen }, className)}
    onClick={onClick}
  >
    <CdnSvg src='/images/doubleArrowheadLeftIcon.svg' />
  </button>
)

export default ToggleLeftNavButton
