import React from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import FileSelectDropzone from 'components/common/file_select_dropzone'

const UploadsPage = () => (
  <main className='Styleguide UploadsPage container mt-4'>
    <h1><StyleguideBaseLink /> / Uploads </h1>
    <section className='mt-4'>
      <h3>client/components/common/file_select_dropzone</h3>
      <table className='table table-hover table-responsive-sm'>
        <thead>
          <tr>
            <th>Component Name</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>FileSelectDropzone</td>
            <td>Upload files/images with Dropzone component and file size limits set.</td>
            <td>
              <FileSelectDropzone onDrop={() => null}>
                <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
                  <div className='font-weight-700'>Drop image</div>
                  <div className='text-secondary'>Or click</div>
                </div>
              </FileSelectDropzone>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
)

export default UploadsPage
