import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useApi from 'components/common/hooks/useApi'
import AppModal from 'components/common/modal'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'

const AddWorkflowModal = ({ onCancel }) => {
  const history = useHistory()
  const [workflow, setWorkflow] = useState<any>({ name: '', description: '' })
  const [createWorkflow, { isLoading: isSaving }] = useApi(
    API.admin.ai.workflows.create,
    { onSuccess: data => history.push(data.displayPath) }
  )

  const connect = (field: string) => ({
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setWorkflow({ ...workflow, [field]: e.target.value }),
    value: workflow[field],
  })

  const handleSave = () => {
    createWorkflow(workflow)
  }

  return (
    <AppModal
      visible
      modalContentClassName='container d-flex flex-column w-100'
    >
      <>
        <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
          <h3 className='mb-0'>Create workflow</h3>
        </div>
        <section className='mb-5'>
          <label className='text-smallest text-secondary'>Name</label>
          <input type='text' className='w-100' {...connect('name')} />
        </section>

        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={onCancel} />
          <ButtonNarrow
            onClick={handleSave}
            disabled={isSaving}
            showLoadingSpinner={isSaving}
          >
            {I18NCommon('save')}
          </ButtonNarrow>
        </div>
      </>
    </AppModal>
  )
}

export default AddWorkflowModal
