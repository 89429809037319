
import React from 'react'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string
  className?: string
}


const RadioButton = ({
  label, className = 'd-flex align-items-center mr-3', ...props
}: Props) => (
  <div className={className}>
    <input
      type='radio'
      className='pointer'
      {...props}
    />
    <label className='ml-2 mb-0 pointer' htmlFor={props.id}>{label}</label>
  </div>
)


export default RadioButton
