const buildComplexTree = (
  modelsArray,
  includeArchive = false,
  orderColumnName = 'ancestryOrderPosition',
  modelHasTargeting = false
) => {
  const modelsHashById = _.keyBy(_.cloneDeep(modelsArray), 'index')
  modelsHashById.root = {
    index: 'root',
    data: 'root',
    isFolder: true,
  }

  if (includeArchive) {
    modelsHashById.archive = {
      index: 'archive',
      data: 'archive',
      isFolder: false,
    }
  }

  const tree = Object.values(modelsHashById)

  // populate parent for each model
  tree.forEach((model) => {
    const parent = modelsHashById[model.parentId]


    if (modelHasTargeting) {
      modelsHashById[model.index].isFolder = model.childrenCountForCurrentUser > 0 || model.index === 'root'
    }

    // if no parent found, nothing to populate
    if (!parent) return

    if (!parent.children) {
      parent.children = []
    }

    parent.children.push(model)
    parent.isFolder = true
  })

  // populate root children
  const topLevelModels = tree.filter(model => !model.parentId).filter(model => model.index !== 'root')
  modelsHashById.root.children = topLevelModels

  // once children are arranged, sort them by order position and convert them to ids
  tree.forEach((model) => {
    const node = modelsHashById[model.index]

    if (node.children) {
      node.children = _.sortBy(node.children, orderColumnName).map(child => child.index)
    }
  })

  return modelsHashById
}

export const findParentIdsFromComplexTree = (treeData, slugOrId) => {
  if (slugOrId === 'root') return []

  // find all the parent IDs for the node with the given slug
  const parentIds : string[] = []
  let node = _.find(treeData, node => node.slug === slugOrId || node.index === slugOrId)

  while (node) {
    parentIds.push(node.index)
    node = treeData[node.parentId]
  }

  return parentIds
}

export default buildComplexTree
