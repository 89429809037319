import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import Card from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import EmailContent from 'components/admin/journeys/communicationTemplates/emailContent'
import NewsFeedContent from 'components/admin/journeys/communicationTemplates/newsFeedContent'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SmsContent from 'components/admin/journeys/communicationTemplates/smsContent'
import { i18nPath } from 'utils/i18nHelpers'
import AddChannelLink from 'components/admin/journeys/communicationTemplates/addChannelLink'
import useQueryParams from 'components/common/hooks/useQueryParams'
import removeQueryParam from 'utils/removeQueryParam'
import useScrollToElement from 'components/common/hooks/useScrollToElement'

const I18N = i18nPath('views.admin.journeys.communication_templates.content')

export const EMAIL = 'email'
export const NEWS_FEED = 'newsFeed'
export const SMS = 'sms'

export const CHANNEL_TAB_MAPPING = {
  [EMAIL]: {
    setting: 'sendToEmail',
    titleField: 'subject',
    contentField: 'content',
    label: I18N('channel_tabs.email'),
    iconSrc: '/images/envelopeOutlineIcon.svg',
  },
  [NEWS_FEED]: {
    setting: 'createFeedCard',
    titleField: 'feedTitle',
    contentField: 'feedContent',
    label: I18N('channel_tabs.news_feed'),
    iconSrc: '/images/newspaperIcon.svg',
  },
  [SMS]: {
    setting: 'sendSms',
    titleField: null,
    contentField: 'smsContent',
    label: I18N('channel_tabs.sms'),
    iconSrc: '/images/mobilePhoneIcon.svg',
  },
}

const ChannelTab = ({
  channel,
  isSelected,
  setSelectedChannel,
  canRemoveChannel,
  removeChannel,
}) => {
  const { label, iconSrc } = CHANNEL_TAB_MAPPING[channel]

  return (
    <div className={classNames('ChannelTab mr-2', { isSelected })} onClick={() => setSelectedChannel(channel)}>
      <CdnSvg className='ChannelTab__icon mr-2' src={iconSrc} />
      <span className='mr-2'>{label}</span>

      {canRemoveChannel && (
        <div className='XIcon pointer' onClick={e => removeChannel(e, channel)}>
          <CdnSvg src='/images/xIcon.svg' />
        </div>
      )}
    </div>
  )
}

const CommunicationContent = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
  journeyBlueprint,
  isStepTemplate,
}) => {
  const history = useHistory()
  const { selectedChannelTab, scrollTo } = useQueryParams()
  const { settings } = useCurrentCompany()
  const isSmsEnabled = settings.journeys.smsNotifications.enabled

  const contentRef = useRef(null)

  useScrollToElement('.CommunicationContent', {
    enabled: scrollTo === 'CommunicationContent',
    dependencies: [scrollTo],
  })

  const allChannelOptions = [EMAIL, NEWS_FEED, ...(isSmsEnabled ? [SMS] : [])]

  const enabledChannels = allChannelOptions.filter((channel) => {
    const { setting } = CHANNEL_TAB_MAPPING[channel]
    return workingCopy[setting]
  })
  const [selectedChannel, setSelectedChannel] = useState(enabledChannels[0])

  useEffect(() => {
    if (selectedChannelTab) {
      setSelectedChannel(selectedChannelTab)

      removeQueryParam(history, 'selectedChannelTab')
      removeQueryParam(history, 'scrollTo')
    }
  }, [selectedChannelTab])

  const enabledChannelsCount = enabledChannels.length
  const areAllChannelsEnabled = enabledChannelsCount === allChannelOptions.length

  const removeChannel = (e, channel) => {
    e.stopPropagation()
    const { setting, titleField, contentField } = CHANNEL_TAB_MAPPING[channel]
    const updatedFields = { [setting]: false, [contentField]: '' }

    if (titleField) {
      updatedFields[titleField] = ''
    }

    updateWorkingCopy(updatedFields)

    if (selectedChannel === channel) {
      const updatedEnabledChannels = enabledChannels.filter(ch => ch !== channel)
      setSelectedChannel(updatedEnabledChannels[0])
    }
  }

  return (
    <div ref={contentRef} className='CommunicationContent mb-4'>
      <h6 className='text-secondary mb-2'>{I18N('title')}</h6>
      <Card>
        <p>{I18N('subtitle')}</p>
        <div className='d-flex mb-4'>
          {enabledChannels.map(channel => (
            <ChannelTab
              key={channel}
              channel={channel}
              isSelected={channel === selectedChannel}
              setSelectedChannel={setSelectedChannel}
              canRemoveChannel={enabledChannelsCount > 1}
              removeChannel={removeChannel}
            />
          ))}

          {!areAllChannelsEnabled && (
            <AddChannelLink
              channelOptions={allChannelOptions}
              enabledChannelsCount={enabledChannelsCount}
              workingCopy={workingCopy}
              updateWorkingCopy={updateWorkingCopy}
            />
          )}
        </div>

        {selectedChannel === EMAIL && (
          <EmailContent
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
            isDisabled={isDisabled}
            journeyBlueprint={journeyBlueprint}
            isStepTemplate={isStepTemplate}
          />
        )}

        {selectedChannel === NEWS_FEED && (
          <NewsFeedContent
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
            isDisabled={isDisabled}
            journeyBlueprint={journeyBlueprint}
          />
        )}

        {selectedChannel === SMS && (
          <SmsContent
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
            isDisabled={isDisabled}
            journeyBlueprint={journeyBlueprint}
            isStepTemplate={isStepTemplate}
          />
        )}
      </Card>
    </div>
  )
}

export default CommunicationContent
