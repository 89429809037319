import EditAutoResizable from 'components/admin/layout/layout_collection_sidebar/common/editAutoResizable'
import React from 'react'
import widgetsMap from 'utils/layout/widgetsMap'

const AutoResizableSettingsSidebar = ({ widget, updateWidget }) => {
  const sidebarTitle = widgetsMap[widget.type].label

  return (
    <div className='AutoResizableSettingsSidebar'>
      <h5>{sidebarTitle}</h5>

      <EditAutoResizable widget={widget} updateWidget={updateWidget} />
    </div>
  )
}

export default AutoResizableSettingsSidebar
