/* eslint-disable import/prefer-default-export */

import { AvailableExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.integrations_page.external_knowledge_section')


export const getHelpText = (type: AvailableExternalSyncSource['type']) => {
  switch (type) {
  case 'ExternalSync::GoogleDrive::Source':
    return I18N('google_drive_help_text')
  default:
    return ''
  }
}
