import React from 'react'

const HamburgerMenuIcon = ({
  height = 18, width = 18, className, fillColor = 'var(--subnav-text-color)',
}) => (
  <svg
    height={height}
    width={width}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    fill={fillColor}
    viewBox='0 0 24 24'
  >
    <rect x='3' y='11' width='18' height='2' rx='.95' ry='.95' />
    <rect x='3' y='16' width='18' height='2' rx='.95' ry='.95' />
    <rect x='3' y='6' width='18' height='2' rx='.95' ry='.95' />
  </svg>
)

export default HamburgerMenuIcon
