import { createContext, useContext } from 'react'

interface CustomStylesContextType {
  handlePropertyChanged: any
  workingCopy: any
}

const CustomStyles = createContext<CustomStylesContextType>({ handlePropertyChanged: null, workingCopy: null })
const useCustomStyles = () => useContext(CustomStyles)

const useVariables = (variable: string | string[]) => {
  const { workingCopy } = useCustomStyles()

  if (variable instanceof Array) {
    const variables = {}
    variable.forEach(v => variables[v] = workingCopy.variables?.[v] || {})

    return variables
  } else {
    return workingCopy.variables?.[variable] || {}
  }
}

export default CustomStyles
export { useCustomStyles, useVariables }
