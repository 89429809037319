import React, { useState, useEffect } from 'react'

import DatePicker from 'components/form_fields/datePicker'

import {
  i18nLabelWithTimeZone, i18nMoment, i18nPath
} from 'utils/i18nHelpers'
import currentTimezone from 'utils/currentTimezone'

import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import Modal from 'components/common/modal'

const I18N = i18nPath('views.admin.article_editor.schedule_modal')

const SchedulePublishModal = ({
  showSchedulePublishModal,
  setShowSchedulePublishModal,
  workingCopy,
  scheduleArticle,
  isSaving,
  description = I18N('description'),
}) => {
  const { publishedAt } = workingCopy
  const [selectedDateTime, setSelectedDateTime] = useState(publishedAt)

  useEffect(() => {
    setSelectedDateTime(publishedAt)
  }, [publishedAt])

  const toggleModal = () => {
    setShowSchedulePublishModal(!showSchedulePublishModal)
  }

  const onClickSave = () => {
    const changes = { draft: false, publishedAt: selectedDateTime.format() }
    scheduleArticle(changes)
  }

  return (
    <Modal visible={showSchedulePublishModal} toggle={toggleModal}>
      <div className='px-3'>
        <h3 className='mb-1'>{I18N('title')}</h3>
        <p className='text-secondary mb-5'>{description}</p>
        <label className='mb-2'>{i18nLabelWithTimeZone(I18N('label'), currentTimezone())}</label>
        <div className='mb-5'>
          <DatePicker
            id='cy_post_editor_published_at' // react-datepicker does not support data attributes
            className='DateTimePicker ArticleEditor-publishedAtPicker'
            selected={selectedDateTime && i18nMoment(selectedDateTime)}
            onChange={datetime => setSelectedDateTime(datetime)}
            showTimeSelect
            isClearable
            dateFormat='LLL'
          />
        </div>
        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={toggleModal} />
          <Button
            onClick={onClickSave}
            id='cy_schedule_post_button'
            disabled={isSaving || !selectedDateTime}
            showLoadingSpinner={isSaving}
          >
            {I18N('cta')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SchedulePublishModal
