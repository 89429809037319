import React, { Dispatch, useState } from 'react'

import AppType from 'types/app'
import { Button } from 'components/common/buttons'
import appPrefenceSlice from 'redux/slices/app_launcher/appPreferences'
import CdnSvg from 'components/common/cdnSvg'

const starIconPath = '/images/app_launcher/starIcon.svg'
const favoriteStarIconPath = '/images/app_launcher/favoriteStarIcon.svg'

interface FavoriteButtonProps {
  app: AppType,
  dispatch: Dispatch<any>
  searchQuery?: string
}

const FavoriteButton = ({ app, dispatch, searchQuery = '' }: FavoriteButtonProps) => {
  const [isStarHovered, setIsStarHovered] = useState(false)

  let shouldShowFavorite = app.favorite

  if (isStarHovered) shouldShowFavorite = !shouldShowFavorite

  const iconPath = shouldShowFavorite ? favoriteStarIconPath : starIconPath

  const handleClick = (e) => {
    e.preventDefault()

    if (app.favorite) {
      dispatch(appPrefenceSlice.asyncActions.destroy(app.appPreferenceId!, searchQuery))
    } else {
      dispatch(appPrefenceSlice.asyncActions.create({ appId: app.id, favorite: true, q: searchQuery }))
    }
  }

  return (
    <Button
      className='FavoriteButton'
      onClick={handleClick}
      onMouseEnter={() => setIsStarHovered(true)}
      onMouseLeave={() => setIsStarHovered(false)}
    >
      <CdnSvg src={iconPath} className={app.favorite ? 'Favorite' : 'NotFavorite'} />
    </Button>
  )
}

export default FavoriteButton
