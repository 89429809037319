import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import linkSlice from 'redux/slices/links'
import LinkForm from 'components/links/linkForm'
import { Button } from 'components/common/buttons'
import BackButton from 'components/common/backButton'
import { i18nPath, i18nFormat } from 'utils/i18nHelpers'
import { LoadingContainer } from 'components/common/loadingContainer'
import VersionsTable from 'components/common/versionsTable'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.golinks_editor')

const EditGoLinkPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { goLinkId } = useParams()

  const currentUser = useCurrentUser()

  const goLink = useSelector(linkSlice.selectors.getGoLink())
  const {
    isLoading, isNotFound, isClaimed,
  } = useSelector(linkSlice.selectors.getMetaData())

  const versions = goLink.versions || []
  const aliasesVersions = goLink.aliasesVersions || []
  const allVersions = versions.concat(aliasesVersions)

  const [workingCopy, setWorkingCopy] = useState({})

  useEffect(() => {
    dispatch(linkSlice.asyncActions.fetchGoLink(goLinkId))

    return function cleanup() {
      dispatch(linkSlice.actions.clear())
    }
  }, [])

  useEffect(() => {
    setWorkingCopy(goLink)
  }, [goLink?.user?.id])

  const claimGolink = () => {
    if (window.confirm(I18N('current_golink_owner_confirm', { currentOwner: workingCopy.user.preferredFullName }))) {
      dispatch(linkSlice.asyncActions.claimGoLink(goLink))
    }
  }

  const saveGolink = () => {
    dispatch(linkSlice.asyncActions.updateGoLink(workingCopy, history))
  }

  const deleteGolink = () => {
    dispatch(linkSlice.asyncActions.deleteGoLink(goLink, history))
  }

  const isCurrentUserOwner = () => goLink.user && goLink.user.id === currentUser.id

  const disableInput = !isCurrentUserOwner()

  const renderClaimButton = () => (
    goLink.user
      && goLink.user.id !== currentUser.id && (
      <Button onClick={claimGolink}>
        {I18N('claim_golink')}
      </Button>
    )
  )

  const renderSaveDeleteButtons = () => {
    const changesPresent = !_.isEqual(goLink, workingCopy)

    return (
      (isCurrentUserOwner() || goLinkId === 'new') && (
        <>
          <Button disabled={!changesPresent} onClick={saveGolink}>
            {I18N('save_golink')}
          </Button>
          <Button variant='danger' className='ml-3' onClick={deleteGolink}>
            {I18N('delete_golink')}
          </Button>
        </>
      )
    )
  }

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='GoLinkEditor container my-4'>
        <div className='row mt-5'>
          <div className='col d-flex align-items-center mb-2'>
            <BackButton />
          </div>
        </div>

        <h1 className='hover-highlight font-weight-bold mb-3'>
          <a
            className='unstyled-link'
            href={workingCopy.url ? workingCopy.url : null}
            target='_blank'
            rel='noopener noreferrer'
          >
          go/
            {goLink.name}
          </a>
        </h1>

        <LinkForm link={workingCopy} setLink={setWorkingCopy} disableInput={disableInput} />

        <div className='row'>
          <div className='col-12'>
            {renderSaveDeleteButtons()}
            {renderClaimButton()}
          </div>
        </div>

        <div className='row'>
          <div className='col-12 mt-2'>
            {goLinkId !== 'new' && i18nFormat(I18N('total_visits'), workingCopy.numVisits)}
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            {allVersions && <VersionsTable versions={allVersions} ignoredFields={['id', 'goLinkId']} />}
          </div>
        </div>
      </div>
    </LoadingContainer>
  )
}

export default EditGoLinkPage
