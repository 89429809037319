import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { i18nPath } from 'utils/i18nHelpers'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'

const I18N = i18nPath('views.admin.auth_tokens')

export const initialState = {
  authTokenIds: [],
  createdAuthTokenId: null,
  meta: {
    ...defaultMeta,
    tokenBeingDestroyed: null,
  },
}

const authTokenSlice = createSlice({
  name: 'authTokens',
  initialState,
  reducers: {
    ...defaultActions,
    setAuthTokenIds(state, action) {
      state.authTokenIds = action.payload
    },

    addAuthTokenId(state, action) {
      state.authTokenIds = [...state.authTokenIds, action.payload]
    },

    removeAuthTokenId(state, action) {
      state.authTokenIds = state.authTokenIds.filter(id => id !== action.payload)
    },

    clearAuthTokenIds(state) {
      state.authTokenIds = []
    },

    setCreatedAuthTokenId(state, action) {
      state.createdAuthTokenId = action.payload
    },

    clearCreatedAuthTokenId(state) {
      state.createdAuthTokenId = null
    },
  },
})

_.assign(authTokenSlice, {
  asyncActions: {
    admin: {
      fetchUserAuthTokens: userId => async (dispatch) => {
        dispatch(authTokenSlice.actions.isLoading(true))

        try {
          const response = await API.admin.authTokens.fetchUserAuthTokens(userId)
          const authTokenIds = response.data.data.map(record => record.id)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(authTokenSlice.actions.setAuthTokenIds(authTokenIds))
        } catch (error) {
          appSignal.sendErrorUnlessClearyBackendError(error)
        } finally {
          dispatch(authTokenSlice.actions.isLoading(false))
        }
      },

      createAuthToken: userId => async (dispatch) => {
        dispatch(authTokenSlice.actions.isLoading(true))

        try {
          const response = await API.admin.authTokens.createAuthToken(userId)
          const authTokenId = response.data.data.id

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(authTokenSlice.actions.addAuthTokenId(authTokenId))
          dispatch(authTokenSlice.actions.setCreatedAuthTokenId(authTokenId))
        } catch (error) {
          appSignal.sendErrorUnlessClearyBackendError(error)
          dispatch(showToastMessage({ message: I18N('error_creating_auth_token'), type: 'error' }))
        } finally {
          dispatch(authTokenSlice.actions.isLoading(false))
        }
      },

      destroyAuthToken: (userId, authTokenId) => async (dispatch) => {
        dispatch(authTokenSlice.actions.isLoading(true))

        try {
          await API.admin.authTokens.destroyAuthToken(userId, authTokenId)

          dispatch(entitySlice.actions.remove({ id: authTokenId, type: 'authToken' }))
          dispatch(authTokenSlice.actions.removeAuthTokenId(authTokenId))
        } catch (error) {
          appSignal.sendErrorUnlessClearyBackendError(error)
          dispatch(showToastMessage({ message: I18N('error_destroying_alert'), type: 'error' }))
        } finally {
          dispatch(authTokenSlice.actions.isLoading(false))
        }
      },
    },
  },
})

_.assign(authTokenSlice, {
  selectors: {
    getAuthTokens: () => state => state.authTokens.authTokenIds.map(id => build(state.entities, 'authToken', id)).filter(Boolean),

    getCreatedAuthToken: () => state => state.authTokens.createdAuthTokenId && build(state.entities, 'authToken', state.authTokens.createdAuthTokenId),

    getMetaData: () => state => state.authTokens.meta,
  },
})

export default authTokenSlice
