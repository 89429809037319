import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { Link } from 'react-router-dom'

import Slider from 'components/common/slider'
import Card from 'components/common/card'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { ButtonSecondarySmall } from 'components/common/buttons'

const I18N = i18nPath('views.shoutouts')

const ShoutoutsHorizontalLayout = ({ shoutoutSuggestions, setSelectedSuggestion, width }) => {
  const calculateSlidesToShow = () => {
    if (width < 4) {
      return 1
    } else if (width < 6) {
      return Math.min(2, shoutoutSuggestions.length)
    } else if (width < 8) {
      return Math.min(3, shoutoutSuggestions.length)
    } else {
      return Math.min(4, shoutoutSuggestions.length)
    }
  }
  const slidesToShow = calculateSlidesToShow()

  return (
    <Slider
      settings={{
        slidesToShow,
        slidesToScroll: slidesToShow,
      }}
      className='px-4'
    >
      {shoutoutSuggestions.map(shoutoutSuggestion => (
        <div className='px-2 py-3 h-100' key={shoutoutSuggestion.id}>
          <Card
            className='h-100'
            cardBodyClassName='d-flex flex-column align-items-center justify-content-center'
          >
            <EmployeeThumbnailPhoto
              employee={shoutoutSuggestion.suggestedUser}
              className='mb-3'
              linkToProfile
            />
            <Link
              className='text-primary font-weight-600 mb-3 text-center truncate-text-at-2-lines'
              to={`/profile/${shoutoutSuggestion.suggestedUser.username}`}
            >
              {shoutoutSuggestion.suggestedUser.preferredFullName}
            </Link>
            <ButtonSecondarySmall onClick={() => setSelectedSuggestion(shoutoutSuggestion)}>
              {I18N('shoutout')}
            </ButtonSecondarySmall>
          </Card>
        </div>
      ))}
    </Slider>
  )
}

export default ShoutoutsHorizontalLayout
