import classNames from 'classnames'
import { Button } from 'components/common/buttons'
import React, { useState } from 'react'
import Modal from 'components/common/modal'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { showToastMessage } from 'redux/slices/toasts'
import { useDispatch } from 'react-redux'

const I18N = i18nPath('views.platform_analytics.export_xlsx')


interface ExportXlsxButtonProps {
  className?: string
  onSubmit: (email: string) => Promise<any>
}

const ExportXlsxButton = ({ className, onSubmit: propOnSubmit }: ExportXlsxButtonProps) => {
  const [openModal, setOpenModal] = useState(false)
  const { permissions: { clearyAdmin } } = useCurrentUser()
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isSubmitDisabled = isSubmitting || !email
  const dispatch = useDispatch()

  const onSubmit = async (e?: any) => {
    e.stopPropagation()
    e.preventDefault()
    setIsSubmitting(true)

    try {
      await propOnSubmit(email)
      dispatch(showToastMessage({ message: I18N('submit_success_message'), type: 'success', timeout: 5000 }))
      setOpenModal(false)
    } catch (e) {
      dispatch(showToastMessage({ message: I18N('submit_error_message'), type: 'error' }))
    } finally {
      setIsSubmitting(false)
    }
  }

  const onClickExportButton = (e) => {
    if (clearyAdmin) {
      setOpenModal(true)
      return
    }

    onSubmit(e)
  }

  return (
    <>
      <Button
        variant='link'
        className={classNames('link-color font-weight-500 p-2', className)}
        onClick={onClickExportButton}
        showLoadingSpinner={isSubmitting && !clearyAdmin}
      >
        {I18N('button.title')}
      </Button>

      <Modal visible={openModal} toggle={() => setOpenModal(false)} title={I18N('modal.title')}>
        <form onSubmit={onSubmit}>
          <div className='mt-4'>
            <label className='required-form-label' htmlFor='email'>{I18N('email')}</label>
            <p className='text-secondary text-small font-weight-500'>{I18N('email_helper')}</p>

            <div className='w-100 w-lg-75'>
              <input type='email' id='email' value={email} className='w-100' onChange={e => setEmail(e.target.value)} />
            </div>
          </div>

          <div className='mt-4 w-50'>
            <Button className='ml-auto mr-0' type='submit' disabled={isSubmitDisabled} showLoadingSpinner={isSubmitting}>
              {I18N('submit')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ExportXlsxButton
