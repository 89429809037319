import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import TaskForm from 'components/admin/journeys/taskTemplates/taskForm'
import { TASK_TEMPLATE_ATTRIBUTES } from 'redux/slices/journeys/taskTemplates'
import useQueryParams from 'components/common/hooks/useQueryParams'

import {
  RESULTS_TAB,
  QUESTIONS_TAB,
  INDIVIDUAL_RESPONSES_TAB
} from 'components/admin/surveys/formHeader'
import QuestionStatsCard from 'components/admin/surveys/questions/questionStatsCard'
import FormResponsesTabsAndTable from 'components/admin/surveys/responses/formResponsesTabsAndTable'
import FormResponsesStatsBanner from 'components/admin/surveys/responses/formResponsesStatsBanner'

import QuestionsEditSection, { defaultQuestion } from 'components/admin/surveys/questionsEditSection'
import useStepTemplateManagement from 'components/admin/journeys/hooks/useStepTemplateManagement'
import FormTaskHeader, { TASK_TAB } from 'components/admin/journeys/taskTemplates/surveys/formTaskHeader'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import surveyFormSlice from 'redux/slices/surveys/forms'
import usePrevious from 'components/common/hooks/usePrevious'
import getDiff from 'utils/getDiff'
import API from 'services/api'

const TASK_TEMPLATE_ATTRIBUTES_WITHOUT_TASKABLE = _.reject(TASK_TEMPLATE_ATTRIBUTES, at => at === 'taskable')

const EditSurveyTaskTemplatePage = ({
  setIsDesktopNavOpen,
}) => {
  const { id } = useParams()
  const history = useHistory()

  const { selectedTab } = useQueryParams()

  const {
    workingCopy,
    updateWorkingCopy,
    journeyBlueprint,
    handleNameSave,
    handleSave,
    hasChanges,
    isFetching,
    isSaving,
    stepTemplate,
    entityTemplate: taskTemplate,
  } = useStepTemplateManagement({
    entityTemplateAttributes: TASK_TEMPLATE_ATTRIBUTES_WITHOUT_TASKABLE,
    updateApiPath: API.admin.taskTemplates.update,
    fetchApiPath: API.admin.taskTemplates.fetch,
    interpolatedFields: ['name', 'instructions'],
  })

  useEffect(() => {
    setIsDesktopNavOpen(false)

    return () => {
      if (!history.location.pathname.includes('survey')) {
        setIsDesktopNavOpen(true)
      }
    }
  }, [id])

  const updateFormWorkingCopy = (changes: any) => {
    updateWorkingCopy({ taskable: { ...workingCopy.taskable, ...changes } })
  }

  const form = useSelector(surveyFormSlice.selectors.getForm(taskTemplate?.taskable?.id))
  const previousForm = usePrevious(form)
  const formWorkingCopy = workingCopy.taskable || {}

  useEffect(() => {
    if (form?.id) {
      updateFormWorkingCopy(form)
    }
  }, [form?.id])

  useEffect(() => {
    if (!isFetching) {
      const diff = getDiff(previousForm, form)

      if (formWorkingCopy.questions?.length === 0) {
        diff.questions = [defaultQuestion]
      }

      updateFormWorkingCopy(diff)
    }

    // The working copy of the form is nested inside the working copy of the task template
    // So if we makes changes only to the form, we need to update the working copy of the task template
    // to avoid keeping the old values of the form and the "hasChanges" flag to be true all the time
  }, [form?.updatedAt, JSON.stringify(form?.questions)])

  if (isFetching || !form || workingCopy.triggerMilestone === undefined) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='EditSurveyTaskTemplatePage EditFormPage d-flex align-items-center flex-column justify-content-center w-100'>
      <FormTaskHeader
        taskTemplate={taskTemplate}
        workingCopy={workingCopy}
        form={form}
        formWorkingCopy={formWorkingCopy}
        journeyBlueprint={journeyBlueprint}
        handleNameSave={handleNameSave}
        handleSave={handleSave}
        hasChanges={hasChanges}
      />
      <main className='AdminContent w-100'>
        {selectedTab === QUESTIONS_TAB && (
          <QuestionsEditSection
            form={form}
            workingCopy={formWorkingCopy}
            updateWorkingCopy={updateFormWorkingCopy}
          />
        )}
        {selectedTab === TASK_TAB && workingCopy && (
          <TaskForm
            updateWorkingCopy={updateWorkingCopy}
            workingCopy={workingCopy}
            currentState={stepTemplate?.state}
            journeyBlueprint={journeyBlueprint}
            isSaving={isSaving}
            showButtons={false}
          />
        )}
        {selectedTab === RESULTS_TAB && (
          <div>
            <FormResponsesStatsBanner
              form={form}
            />
            {(form.questions || []).map((question, index) => (
              <QuestionStatsCard
                className='mb-3'
                key={question.id}
                index={index}
                question={question}
              />
            ))}
          </div>
        )}
        {selectedTab === INDIVIDUAL_RESPONSES_TAB && (
          <FormResponsesTabsAndTable form={form} />
        )}
      </main>
    </div>
  )
}

export default EditSurveyTaskTemplatePage
