import React from 'react'
import { useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import SmartTable from 'components/common/tables/smartTable'
import taskableStepSlice from 'redux/slices/journeys/taskableSteps'
import DueAtTextComponent from 'components/journeys/tasks/dueAtTextComponent'
import TaskableDetailsButtonAndModal from 'components/journeys/modals/taskableDetailsButtonAndModal'
import useCompleteTaskables from 'components/admin/journeys/hooks/useCompleteTaskables'

const I18N = i18nPath('views.journeys.my_tasks')

const MyTasksTable = ({
  isCompletedTabSelected = false,
  showDirectReportColumns = false,
  showCompleteCheckbox = false,
  fetchTaskableSteps = () => null,
  emptyTableMessage = '',
  searchQuery = '',
}) => {
  const steps = useSelector(taskableStepSlice.selectors.getTaskableSteps())

  const {
    queryParams,
    isLoading,
  } = useSelector(taskableStepSlice.selectors.getMetaData())

  const {
    page, totalPages, perPage, total,
  } = queryParams

  const columns = [
    {
      header: I18N('table.name'),
      accessor: d => (
        <TaskableDetailsButtonAndModal
          showCompleteCheckbox={showCompleteCheckbox}
          step={d}
        />
      ),
    },
    !showDirectReportColumns && {
      header: I18N('table.for'),
      accessor: d => <EmployeeOrCorrespondentLink user={d?.forUser} linkToAdmin={false} />,
    },
    showDirectReportColumns && {
      header: I18N('table.to'),
      accessor: d => <EmployeeOrCorrespondentLink user={d?.toUserOrToCorrespondentUser} linkToAdmin={false} />,
    },
    {
      header: I18N('table.from'),
      accessor: d => (
        <EmployeeOrCorrespondentLink
          user={d?.fromUserOrFromCorrespondentUser}
          correspondent={d?.fromCorrespondent}
          linkToAdmin={false}
        />
      ),
    },
    {
      header: I18N('table.due_on'),
      accessor: d => <DueAtTextComponent task={d?.entity} className='mb-0' hasDuePrefix={false} />,
    },
  ].filter(Boolean)

  const handlePaginationClick = (params) => {
    fetchTaskableSteps({ page: params.page })
  }

  const {
    filteredSteps,
    rowClassName,
  } = useCompleteTaskables({
    onParamsChange: fetchTaskableSteps,
    isCompletedTaskTabSelected: isCompletedTabSelected,
  })

  if (isLoading) {
    return (
      <section className='tasks-table'>
        <CirclesLoadingIndicator />
      </section>
    )
  }

  if (steps.length === 0 && total === 0 && searchQuery === '') {
    return (
      <section className='tasks-table text-secondary font-weight-500 mt-5 text-center'>
        {emptyTableMessage}
      </section>
    )
  }

  return (
    <section className='tasks-table mb-3'>
      <SmartTable
        className='mt-3 white-bg-table MyTasksTable'
        data={filteredSteps}
        page={page}
        pages={totalPages}
        perPage={perPage}
        totalCount={total}
        showPagination={true}
        columns={columns}
        onPaginationClick={handlePaginationClick}
        rowClassName={rowClassName}
      />
    </section>
  )
}

export default MyTasksTable
