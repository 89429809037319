import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { present } from 'components/common/utils'
import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'


const I18N = i18nPath('views.settings.default_user_setting.external_alert_type')

interface ExternalAlertsInputValue {
  feed: boolean
  notifications: boolean
  externalAlerts: boolean
}

interface ExternalAlertsInputProps {
  value: ExternalAlertsInputValue
  setValue: (callback: (value: ExternalAlertsInputValue) => ExternalAlertsInputValue) => void
  name: string
}

const ExternalAlertsInput = ({ value, setValue, name }: ExternalAlertsInputProps) => {
  const { slackEnabled, msTeamEnabled, smsEnabled } = useCurrentCompany()

  const availableTypes = [
    { id: 'email', text: 'Email' },
    slackEnabled && { id: 'slack', text: 'Slack' },
    msTeamEnabled && { id: 'msTeam', text: 'MS Teams' },
    { id: 'pushNotification', text: I18N('push_notification') },
    smsEnabled && { id: 'sms', text: 'SMS' },
  ].filter(present)

  return (
    <div className='mt-1 d-flex'>
      {availableTypes.map(type => (
        <div key={type.id} className='form-check mr-3'>
          <input
            id={`${name}-${type.id}-checkbox`}
            type='checkbox'
            checked={!!value[type.id as keyof ExternalAlertsInputValue]}
            onChange={(e) => {
              setValue(prevValue => ({
                ...prevValue,
                [type.id]: e.target.checked,
              }))
            }}
          />
          <label htmlFor={`${name}-${type.id}-checkbox`} className='font-weight-normal'>
            {type.text}
          </label>
        </div>
      ))}
    </div>
  )
}

export default ExternalAlertsInput
