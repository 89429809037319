import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { SurveyQuestionType } from 'types/surveys/question'

const I18N = i18nPath('views.admin.surveys')

const ScaleQuestionEditForm = ({
  question,
  updateQuestion = (changes: Partial<SurveyQuestionType>) => { },
  isDisabled = false,
}) => {
  const config = question?.config || {}

  const minValue = _.get(config, 'minValue', 1)
  const maxValue = _.get(config, 'maxValue', 5)
  const step = config?.step || 1
  const labels = config?.labels || {}

  const updateConfig = (changes) => {
    updateQuestion({ config: { ...config, ...changes } })
  }

  const scaleSteps = [..._.range(Math.max(minValue, 0), Math.min(maxValue, 10) + 1, step)]

  return (
    <div className='ScaleQuestionEditForm'>
      <div>
        <div>
          <label className='required-form-label'>
            {I18N('range')}
          </label>
          <div className='d-flex align-items-center'>
            <input
              className='min-value'
              type='number'
              min={0}
              max={maxValue - 1}
              value={minValue}
              disabled={isDisabled}
              onChange={({ target }) => updateConfig({ minValue: parseInt(target.value) })}
            />
            <span className='mx-2'>{I18N('to')}</span>
            <input
              className='max-value'
              type='number'
              min={minValue}
              max={10}
              value={maxValue}
              disabled={isDisabled}
              onChange={({ target }) => updateConfig({ maxValue: parseInt(target.value) })}
            />
          </div>
        </div>
        <div className='my-3'>
          <label className='mb-0'>
            {I18N('labels')}
          </label>
          {scaleSteps.map(stepValue => (
            <div key={stepValue} className='d-flex my-2 align-items-center justify-content-flex-center'>
              <div className='mr-2'>{stepValue}</div>
              <input
                type='text'
                disabled={isDisabled}
                value={labels[stepValue]}
                onChange={({ target }) => updateConfig({
                  labels: { ...labels, [stepValue]: target.value },
                })}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ScaleQuestionEditForm
