import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import CardWithTable from 'components/analytics/common/cardWithTable'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import cultureAnalyticsSlice from 'redux/slices/analytics/culture'
import { CultureShoutoutUserType } from 'types/analytics/culture'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import TargetingRulesType from 'types/audience/targetingRules'
import { TAB_HEADER_QUERY_PARAMS } from 'components/analytics/helpers/useTabHeader'
import usePathReusingQueryParams from 'components/common/hooks/usePathReusingQueryParams'

const manWithTabletPath = '/images/analytics/manWithTabletIcon.svg'
const womanWithComputerIcon = '/images/analytics/womanWithComputerIcon.svg'

interface CultureShoutoutActivityProps {
  periodStart: string
  periodEnd?: string
  type: 'sent' | 'received'
  areShoutoutsEnabled: boolean
  targetingRules?: TargetingRulesType
}

const CultureShoutoutActivity = ({
  periodStart,
  periodEnd,
  type,
  areShoutoutsEnabled,
  targetingRules,
}: CultureShoutoutActivityProps) => {
  const I18N = i18nPath(`views.platform_analytics.culture.shoutouts.${type}`)
  const disabledText = i18nPath('views.platform_analytics.culture.shoutouts')('disabled')

  // The word 'type' is used in the backend to determine if it is 'sent' or 'received'.
  // That is why I am using 'reduxType' to refer to the name used in the slice.
  const reduxType = type === 'sent' ? 'mostShoutoutsSent' : 'mostShoutoutsReceived'

  const dispatch = useDispatch()

  const { data, totalPages, totalItems } = useSelector(cultureAnalyticsSlice.selectors.getShoutoutActivity(reduxType))
  const { [reduxType]: { isLoading } } = useSelector(cultureAnalyticsSlice.selectors.getMetaData())

  const areShoutoutsEmpty = totalItems === 0 && !isLoading

  useEffect(() => {
    if (!areShoutoutsEnabled) return

    dispatch(cultureAnalyticsSlice.asyncActions.admin.fetchShoutoutActivity(
      reduxType,
      {
        periodStart, periodEnd, page: 1, perPage: 10, type, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, targetingRules])

  const columns = [
    {
      header: I18NCommon('user'),
      accessor: (user: CultureShoutoutUserType) => (
        <div className='d-flex flex-row align-items-center'>
          <EmployeeThumbnailPhoto employee={user} size='25px' />
          <Link to={`/profile/${user.username}`} className='text-truncate w-100 d-block ml-1' target='_blank'>{user.preferredFullName}</Link>
        </div>
      ),
    },
    {
      header: type === 'sent' ? I18N('sent') : I18N('received'),
      style: { width: '15%' },
      accessor: (user: CultureShoutoutUserType) => user.count,
    },
  ]

  const path = usePathReusingQueryParams({
    path: '/platform_analytics/culture/shoutout_activity',
    paramsToReuse: TAB_HEADER_QUERY_PARAMS,
    otherQueryParams: { type },
  })

  if (!areShoutoutsEnabled || areShoutoutsEmpty) {
    return (
      <EmptyStateCard
        title={I18N('title')}
        text={areShoutoutsEmpty ? I18N('empty') : disabledText}
        iconPath={areShoutoutsEmpty ? womanWithComputerIcon : manWithTabletPath}
        classname='CultureTables'
      />
    )
  }

  return (
    <CardWithTable
      title={I18N('title')}
      tableColumns={columns}
      tableData={data}
      classname='CultureTables'
      isLoading={isLoading}
      showSeeMoreLink={totalPages > 1}
      seeMorePath={path}
    />
  )
}

export default CultureShoutoutActivity
