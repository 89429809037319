const celebrationEmojis = {
  birthday: '🎂',
  work_anniversary: '💼',
  new_employee_welcome: '🎉',
  farewell: '👋',
  achievement: '🏆',
  new_parent: '👶',
  thank_you: '🙌',
  get_well_soon: '🤒',
  custom: '✉️️',
}

export default celebrationEmojis
