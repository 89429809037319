import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import PillTabSelector from 'components/common/pillTabSelector'
import CommentsWidget from 'components/comments/commentsWidget'

const I18N = i18nPath('views.journeys.taskable_detail')

const ACTIVITY_TAB = 'activity'
const HISTORY_TAB = 'history'

const TaskableDetailTabsSection = ({
  taskable,
  taskableType,
  isPreview,
}) => {
  const [selectedTab, setSelectedTab] = useState(ACTIVITY_TAB)
  const [commentsCount, setCommentsCount] = useState(taskable?.commentsCount || 0)

  return (
    <>
      <div className='w-100 line-break mt-4' />
      <PillTabSelector
        pillClasses='text-normal mb-3 mt-4'
        tabs={[
          {
            text: I18N(`tabs.${ACTIVITY_TAB}`),
            onClick: () => {
              setSelectedTab(ACTIVITY_TAB)
            },
          },
          {
            text: I18N(`tabs.${HISTORY_TAB}`),
            onClick: () => {
              setSelectedTab(HISTORY_TAB)
            },
            hidden: true,
          },
        ]}
      />
      {
        selectedTab === ACTIVITY_TAB ? (
          <CommentsWidget
            commentable={taskable}
            type={taskableType}
            key={taskable.id}
            commentsCount={commentsCount}
            setCommentsCount={setCommentsCount}
            showCommentsOnInit
            isReadOnly={isPreview}
          />
        )
          : (
            null
          )
      }
    </>
  )
}


export default TaskableDetailTabsSection
