import React from 'react'

import ChatUserGroup from 'components/common/chatUserGroup'
import { AsyncSelect } from 'components/common/react_select'
import { components } from 'react-select'
import API from 'services/api'
import { ChatUserGroupEnum, ChatUserGroupType } from 'types/chat/userGroup'
import { I18NCommon } from 'utils/i18nHelpers'


const wrapChatUserGroupWithSelectComponent = Component => props => (
  <Component {...props}>
    <ChatUserGroup userGroup={props.data} />
  </Component>
)

const Option = wrapChatUserGroupWithSelectComponent(components.Option)
const SingleValue = wrapChatUserGroupWithSelectComponent(components.SingleValue)
const MultiValue = wrapChatUserGroupWithSelectComponent(components.MultiValue)

type Value = ChatUserGroupType[] | ChatUserGroupType

interface Props<T extends Value> {
  value?: T
  types: ChatUserGroupEnum[]
  onChange: (value: T) => void
  [key: string]: any
}

function ChatUserGroupsAsyncSelect<T extends Value>({
  value, onChange, types, ...props
}: Props<T>) {
  const isMulti = Array.isArray(value)

  let loadUserGroups = (query, callback) => {
    API.admin.chat.userGroups.fetchAll({ q: query, types: types.join(',') })
      .then(response => callback(response.data.data.map(({ attributes }) => attributes)))
  }

  loadUserGroups = _.debounce(loadUserGroups, 300)

  return (
    <AsyncSelect
      id='cy_chat_user_group_select'
      aria-label='Type to search for user groups'
      getOptionValue={option => option.id}
      value={value}
      onChange={onChange}
      loadOptions={loadUserGroups}
      backspaceRemovesValue
      isMulti={isMulti}
      defaultOptions
      placeholder={I18NCommon('select_or_type_to_search')}
      components={{
        Option,
        SingleValue,
        MultiValue,
      }}
      {...props}
    />
  )
}

export default ChatUserGroupsAsyncSelect
