import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'

type Props = {
  isLoading: boolean
  asyncAction: any
  variant?: string
}

const ExportButton = ({
  isLoading,
  asyncAction,
  variant = 'primary',
} : Props) => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(asyncAction)
  }

  return (
    <Button
      className='mr-2'
      disabled={isLoading}
      showLoadingSpinner={isLoading}
      onClick={onClick}
      variant={variant}
    >
      {I18NCommon('export_csv')}
    </Button>
  )
}

export default ExportButton
