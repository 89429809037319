import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import BackIcon from 'components/icons/backIcon'
import { Link } from 'react-router-dom'
import EditableContent from 'components/common/editable_content'
import { audienceSummaryText, targetingRulesPresent } from 'utils/audience'
import StepTemplateActionsDropdown from 'components/admin/journeys/journeyBlueprints/stepTemplateActionsDropdown'
import isStepTemplateValid from 'utils/admin/journeys/isStepTemplateValid'
import SaveTemplateButton, { SaveDraftTemplateButton } from 'pages/admin/journeys/step_templates/saveTemplateButton'
import TargetingRulesType from 'types/audience/targetingRules'
import JourneyBlueprintType from 'types/journeys/journeyBlueprint'
import StepTemplateType from 'types/journeys/stepTemplate'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.journeys.journey_blueprints')

const BackToJourneyBlueprintButton = ({
  className,
  journeyBlueprint,
}) => (
  <div className={classNames('BackToJourneyBlueprintButton', className)}>
    <Link to={`/admin/journeys/${journeyBlueprint?.id}/edit`}>
      <BackIcon />
      <span className='ml-2'>{I18N('back_to_journey', { journeyBlueprintName: journeyBlueprint?.name })}</span>
    </Link>
  </div>
)

const AppliesToText = ({
  targetingRules,
}) => {
  const targetingOptions = useTargetingOptions()

  return (
    <div className='text-small font-weight-400 mt-1'>
      <span>{I18N('applies_to')} </span>
      <span>{audienceSummaryText(targetingRules, targetingOptions)}</span>
    </div>
  )
}

interface StepTemplateHeaderProps {
  journeyBlueprint: JourneyBlueprintType
  stepTemplate?: StepTemplateType
  workingCopy: any
  name: string
  handleNameSave?: (name: string) => void
  targetingRules?: TargetingRulesType
  onSave: (...args: any) => void
  isSaving: boolean
  areChangesPresent?: boolean
  nonRequiredFields?: string[]
  postTriggerNames?: string[]
}

const StepTemplateHeader = ({
  journeyBlueprint,
  stepTemplate,
  workingCopy,
  name,
  handleNameSave,
  targetingRules,
  onSave,
  isSaving = false,
  areChangesPresent,
  nonRequiredFields = [],
  postTriggerNames = [],
}: StepTemplateHeaderProps) => {
  // If it is null, it is also a draft. This is the case when creating a new step template
  const isDraft = workingCopy?.state === 'draft' || !workingCopy?.state
  const { isValid, invalidFields } = isStepTemplateValid({
    workingCopy,
    nonRequiredFields,
    isStepTemplate: true,
    postTriggerNames,
  })

  return (
    <header className='AdminHeader StepTemplateHeader d-flex flex-column'>
      <BackToJourneyBlueprintButton className='mb-3' journeyBlueprint={journeyBlueprint} />
      <div className='d-flex justify-content-between'>
        <EditableContent
          displayElement={<h3 className='mb-0'>{name}</h3>}
          inputElement={<input />}
          valueToEdit={name}
          saveContent={handleNameSave}
          canEdit={!!handleNameSave}
        />
        <div className='d-flex align-items-center'>
          {isDraft && (
            <SaveDraftTemplateButton
              onSave={onSave}
              isValid={isValid}
              isDraft={isDraft}
              areChangesPresent={areChangesPresent}
              isSaving={isSaving}
              invalidFields={invalidFields}
            />
          )}
          <SaveTemplateButton
            onSave={onSave}
            isDraft={isDraft}
            areChangesPresent={areChangesPresent}
            isSaving={isSaving}
            isValid={isValid}
            invalidFields={invalidFields}
          />

          {stepTemplate && (
            <StepTemplateActionsDropdown
              stepTemplate={stepTemplate}
              workingCopy={workingCopy}
              journeyBlueprintType={journeyBlueprint?.type}
              onRevertToDraft={onSave}
              isSaving={isSaving}
              redirectOnSuccess
            />
          )}
        </div>
      </div>
      {targetingRulesPresent(targetingRules) && (
        <AppliesToText targetingRules={targetingRules} />
      )}
    </header>
  )
}


export default StepTemplateHeader
