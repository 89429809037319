import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import itAdminSlice from 'redux/slices/itAdmin'
import FormEditableField from 'components/form_fields/formEditableField'

const I18N = i18nPath('views.integrations_page.integration_card.adp')

interface AdpInstallationComponentInterface {
  isOtherInstalled: boolean,
}

interface FormState {
  clientId: string
  clientSecret: string
  certificatePem: string
  certificateKey: string
  certificatePassword: string
}

const AdpInstallationComponent = ({ isOtherInstalled }: AdpInstallationComponentInterface) => {
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)

  const [form, setForm] = useState<FormState>({
    clientId: '',
    clientSecret: '',
    certificatePem: '',
    certificateKey: '',
    certificatePassword: '',
  })

  const connect = (fieldName: string) => ({
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm(oldValues => ({
        ...oldValues,
        [fieldName]: e.target.value,
      }))
    },
    currentValue: form[fieldName],
  })

  const { isSaving } = useSelector(itAdminSlice.selectors.getMetaData())

  const installAdp = () => {
    dispatch(itAdminSlice.asyncActions.admin.installAdp({
      clientId: form.clientId,
      clientSecret: form.clientSecret,
      certificatePem: form.certificatePem,
      certificateKey: form.certificateKey,
      certificatePassword: form.certificatePassword,
    }))
  }

  const isDisabled = isSaving
    || _.isEmpty(form.clientId)
    || _.isEmpty(form.clientSecret)
    || _.isEmpty(form.certificatePem)
    || _.isEmpty(form.certificateKey)

  if (isOtherInstalled) {
    return (
      <div className='text-secondary text-small'>
        {I18n.t('views.integrations_page.integration_card.only_one_hris_integration')}
      </div>
    )
  }

  if (!showForm) {
    return (
      <div className='mb-2'>
        <ButtonSmallNarrow
          onClick={() => setShowForm(true)}
        >
          {I18N('configure')}
        </ButtonSmallNarrow>
      </div>
    )
  }

  return (
    <>
      <div className='mb-2'>
        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          fieldName='clientId'
          label={I18N('client_id')}
          {...connect('clientId')}
          isRequired
        />

        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          {...connect('clientSecret')}
          fieldName='clientSecret'
          label={I18N('client_secret')}
          isRequired
        />

        <FormEditableField
          textarea={true}
          className='d-block w-100 mb-2'
          {...connect('certificatePem')}
          fieldName='certificatePem'
          label={I18N('certificate_pem')}
          isRequired
        />

        <FormEditableField
          textarea={true}
          className='d-block w-100 mb-2'
          {...connect('certificateKey')}
          fieldName='certificateKey'
          label={I18N('certificate_key')}
          isRequired
        />

        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          {...connect('certificatePassword')}
          fieldName='certificatePassword'
          label={I18N('certificate_password')}
        />

        <ButtonSmallNarrow
          onClick={installAdp}
          disabled={isDisabled}
          showLoadingSpinner={isSaving}
          id='save-adp-btn'
        >
          {I18n.t('views.integrations_page.integration_card.save')}
        </ButtonSmallNarrow>
      </div>

    </>
  )
}

export default AdpInstallationComponent
