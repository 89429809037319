import entitySlice from 'redux/slices/entities'
import build from 'redux-object'
import API from 'services/api'
import appSignal from 'services/appSignal'
import { i18nPath } from 'utils/i18nHelpers'
import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { getResponseOrThrow } from 'utils/errorHandling'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { ReduxState } from 'redux/redux'
import { showToastMessage } from 'redux/slices/toasts'
import queryParamsFromHeaders, { defaultPaginationParams, PaginationParams } from 'utils/queryParamsFromHeaders'
import pageTemplateCategorySlice from 'redux/slices/pageTemplateCategories'

export interface SelectedTemplate {
  id?: string
  templateCategoryId?: string
}

const defaultQueryParams = { ...defaultPaginationParams, perPage: 6 }

const I18N = i18nPath('views.pages')

export const buildPageTemplatePayload = pageTemplate => _.pick(pageTemplate, [
  'id',
  'title',
  'content',
  'ownerId',
  'templateCategoryId',
  'pageId',
  'showFullWidth',
])

export interface pageTemplatesState {
  meta: DefaultMetaType & {
    queryParams: PaginationParams
    isDeleting: boolean
    isLoadingTemplate: boolean
  }
}

export const initialState = {
  selectedPageTemplateId: '',
  meta: {
    ...defaultMeta,
    isDeleting: false,
    isLoadingTemplate: false,
    queryParams: defaultQueryParams,
  },
}

const pageTemplateSlice = createSlice({
  name: 'pageTemplates',
  initialState,
  reducers: {
    ...defaultActions,

    setSelectedPageTemplateId(state, action) {
      state.selectedPageTemplateId = action.payload
    },

    isDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },

    isLoadingTemplate(state, action) {
      state.meta.isLoadingTemplate = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },
  },
})

const asyncActions = {
  fetchAll: (templateCategoryId: string, queryParams) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(pageTemplateSlice.actions.isLoading(true))

      const response = await API.pageTemplates.fetchAll(templateCategoryId, queryParams)

      const newQueryParams = queryParamsFromHeaders(response)

      dispatch(entitySlice.actions.add({
        data: response.data,
        reverseRelationships: [
          {
            entityId: templateCategoryId,
            entityType: 'pageTemplateCategory',
            relationshipName: 'latestTemplates',
          },
        ],
      }))

      dispatch(pageTemplateSlice.actions.setQueryParams(newQueryParams))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(pageTemplateSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(pageTemplateSlice.actions.isLoading(false))
    }
  },

  fetch: (params: SelectedTemplate) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(pageTemplateSlice.actions.isLoadingTemplate(true))
      const response = await API.pageTemplates.fetch(params)
      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(pageTemplateSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(pageTemplateSlice.actions.isLoadingTemplate(false))
    }
  },

  admin: {
    create: (
      pageTemplate,
      onSuccess = (templateCategoryId: string, templateId: string) => {}
    ) => async (dispatch: Dispatch<any>) => {
      dispatch(pageTemplateSlice.actions.isSaving(true))

      try {
        const response = await API.admin.pageTemplates.create(buildPageTemplatePayload(pageTemplate))
        dispatch(entitySlice.actions.add({
          data: response.data,
          reverseRelationships: [
            {
              entityId: pageTemplate.templateCategoryId,
              entityType: 'pageTemplateCategory',
              relationshipName: 'latestTemplates',
            },
          ],
        }))
        dispatch(showToastMessage({ message: I18N('template_created'), type: 'success' }))
        onSuccess(pageTemplate.templateCategoryId, response.data.data.id)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(pageTemplateSlice.actions.setError(I18N('template_create_failed')))
        dispatch(showToastMessage({ message: I18N('template_create_failed'), type: 'error' }))
      } finally {
        dispatch(pageTemplateSlice.actions.isSaving(false))
      }
    },

    createBaseTemplate: (
      pageTemplate,
      onSuccess = (templateCategoryId: string, templateId: string) => {}
    ) => async (dispatch: Dispatch<any>) => {
      dispatch(pageTemplateSlice.actions.isSaving(true))

      try {
        const response = await API.admin.pageTemplates.createBaseTemplate(buildPageTemplatePayload(pageTemplate))
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('base_template_created'), type: 'success' }))
        onSuccess(pageTemplate.templateCategoryId, response.data.data.id)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(pageTemplateSlice.actions.setError(I18N('base_template_create_failed')))
        dispatch(showToastMessage({ message: I18N('base_template_create_failed'), type: 'error' }))
      } finally {
        dispatch(pageTemplateSlice.actions.isSaving(false))
      }
    },

    update: pageTemplate => async (dispatch: Dispatch<any>) => {
      dispatch(pageTemplateSlice.actions.isSaving(true))
      try {
        const response = await API.admin.pageTemplates.update(buildPageTemplatePayload(pageTemplate))
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('template_updated'), type: 'success' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(pageTemplateSlice.actions.setError(I18N('template_update_failed')))
        dispatch(showToastMessage({ message: I18N('template_update_failed'), type: 'error' }))
      } finally {
        dispatch(pageTemplateSlice.actions.isSaving(false))
      }
    },

    destroy: (params, onSuccess: () => void) => async (dispatch: Dispatch<any>) => {
      dispatch(pageTemplateSlice.actions.isDeleting(true))
      try {
        const response = await API.admin.pageTemplates.destroy(params)
        dispatch(entitySlice.actions.remove({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('template_deleted'), type: 'success' }))

        // The template category needs to be updated by removing this template. If we set this to true,
        // the template categoryies will be reloaded if the user opens the list again.
        dispatch(pageTemplateCategorySlice.actions.hasLoadedAllTemplateCategories(false))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(pageTemplateSlice.actions.setError(I18N('template_delete_failed')))
        dispatch(showToastMessage({ message: I18N('template_delete_failed'), type: 'error' }))
      } finally {
        dispatch(pageTemplateSlice.actions.isDeleting(false))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.pageTemplates.meta,

  getPageTemplate: pageTemplateId => state => build(state.entities, 'pageTemplate', pageTemplateId) ?? {},
}

export default {
  ...pageTemplateSlice,
  selectors,
  asyncActions,
}
