import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import LinkAnchor from 'components/common/linkAnchor'
import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import PillTabSelector from 'components/common/pillTabSelector'
import { ButtonSmallNarrow } from 'components/common/buttons'

import groupSlice from 'redux/slices/groups'

import GroupType from 'types/group'
import { SimpleUserType } from 'types/user'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.includes.audience_selector')

const personsIconPath = '/images/personsIcon.svg'

const GroupList = ({ groups }: { groups: GroupType[] }) => {
  const dispatch = useDispatch()
  // we need to fix this, but some groups may reach here without the activeMembersCount
  // if that's the case, we fetch the group to get that value

  const [fetchedGroupIds, setFetchedGroupIds] = useState<string[]>([])
  const groupIdsWithoutActiveMembersCount = groups.filter(g => (
    isNaN(g.activeMembersCount) && !fetchedGroupIds.includes(g.id)
  )).map(g => g.id)

  useEffect(() => {
    if (groupIdsWithoutActiveMembersCount.length > 0) {
      setFetchedGroupIds([...fetchedGroupIds, ...groupIdsWithoutActiveMembersCount])
      groupIdsWithoutActiveMembersCount.forEach(id => dispatch(groupSlice.asyncActions.fetch(id)))
    }
  }, [groupIdsWithoutActiveMembersCount])

  return (
    <div className='GroupList d-flex flex-column'>
      {groups.length === 0 && (<span className='text-center mt-4 text-secondary'>{I18N('no_groups')}</span>)}
      {groups.map(group => (
        <LinkAnchor
          key={`group-${group.id}`}
          link={group.displayPath}
        >
          <div className='GroupItem p-2 mb-1 d-flex'>
            <img
              className='GroupMiniCardPhoto mr-2'
              src={group.primaryPhotoThumbnailUrl || group.primaryPhotoUrl}
            />
            <div className='d-flex flex-column'>
              <span className='text-primary font-weight-500'>{group.name}</span>
              {!isNaN(group.activeMembersCount) && (
                <span className='text-secondary text-small'>
                  {I18N('group_members_count', { count: group.activeMembersCount })}
                </span>
              )}
            </div>
          </div>
        </LinkAnchor>
      ))}
    </div>
  )
}

const UserList = ({ users }: { users: SimpleUserType[] }) => (
  <div className='UserList d-flex flex-column'>
    {users.length === 0 && (<span className='text-center mt-4 text-secondary'>{I18N('no_users')}</span>)}
    {users.map(user => (
      <EmployeeMiniCardLink key={`user-${user.id}`} employee={user} />
    ))}
  </div>
)

interface GroupPostAudienceModalProps {
  groupId: string,
  allTargetedUsers: SimpleUserType[],
  specificallyTargetedGroups: GroupType[],
  specificallyTargetedUsers: SimpleUserType[],
  onClose: () => void,
}

const FILTER_EVERYONE = 'everyone'
const FILTER_GROUPS = 'groups'
const FILTER_SPECIFIC_PEOPLE = 'specific_people'

const GroupPostAudienceModal = ({
  groupId,
  specificallyTargetedGroups,
  specificallyTargetedUsers,
  allTargetedUsers,
  onClose,
}: GroupPostAudienceModalProps) => {
  const dispatch = useDispatch()
  const [query, setQuery] = useState('')
  const [filter, setFilter] = useState(FILTER_EVERYONE)
  const group = useSelector(groupSlice.selectors.getTeam(groupId))
  const { activeGroupFollowers = [], activeUserFollowerIds = [] } = group
  const groupsToShow = [group, ...activeGroupFollowers, ...specificallyTargetedGroups]
  const userFollowers = allTargetedUsers.filter(user => activeUserFollowerIds.includes(user.id))
  const specificUsers = [...specificallyTargetedUsers, ...userFollowers]
  const filteredAllUsers = query === '' ? allTargetedUsers : allTargetedUsers.filter(user => user.preferredFullName.toLowerCase().includes(query.toLowerCase()))
  const filteredGroups = query === '' ? groupsToShow : groupsToShow.filter(group => group.name.toLowerCase().includes(query.toLowerCase()))
  const filteredSpecificUsers = query === '' ? specificUsers : specificUsers.filter(user => user.preferredFullName.toLowerCase().includes(query.toLowerCase()))

  useEffect(() => {
    dispatch(groupSlice.asyncActions.fetch(groupId))
  }, [groupId])

  const handleFilterSelection = (filter: string) => {
    setFilter(filter)
    setQuery('')
  }

  return (
    <Modal
      visible
      toggle={onClose}
      className='GroupPostAudienceModal'
      modalTitleClassName='h4 font-weight-600'
    >
      <div className='d-flex align-items-center mb-3 mt-n3'>
        <h4 className='mb-0 font-weight-600'>{I18N('audience')}</h4>
        <ButtonSmallNarrow className='AudienceCount ml-3 text-left' variant='tag'>
          <CdnSvg src={personsIconPath} className='mr-2' />
          {I18N('people', { count: allTargetedUsers.length })}
        </ButtonSmallNarrow>
      </div>
      <input
        type='text'
        placeholder={I18N('group_post_search_placeholder')}
        value={query}
        onChange={e => setQuery(e.target.value)}
        className='w-100 mb-3'
      />
      <PillTabSelector
        pillClasses='text-normal'
        className='mb-3'
        tabs={[
          {
            text: I18N(FILTER_EVERYONE),
            onClick: () => handleFilterSelection(FILTER_EVERYONE),
          },
          {
            text: I18N(FILTER_GROUPS),
            onClick: () => handleFilterSelection(FILTER_GROUPS),
          },
          {
            text: I18N(FILTER_SPECIFIC_PEOPLE),
            onClick: () => handleFilterSelection(FILTER_SPECIFIC_PEOPLE),
          },
        ]}
      />
      <div className='ItemList'>
        {filter === FILTER_GROUPS && <GroupList groups={filteredGroups} />}
        {filter === FILTER_EVERYONE && <UserList users={filteredAllUsers} />}
        {filter === FILTER_SPECIFIC_PEOPLE && <UserList users={filteredSpecificUsers} />}
      </div>
    </Modal>
  )
}

export default GroupPostAudienceModal
