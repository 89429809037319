import React from 'react'
import moment from 'moment'

import CelebrationCard from 'components/celebrations/celebrationCard'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const CelebrationCardBackgroundColor = ({ type, upcoming }) => {
  const currentCompany = useCurrentCompany()

  return (
    <CelebrationCard
      celebration={{
        type,
        typeWithPrefix: upcoming ? `upcoming_${type}` : type,
        upcoming,
        eventDate: upcoming ? moment().add(3, 'days') : moment(),
        user: { id: 'fakeId', preferredFullName: 'Michael Scott', active: true },
        workAnniversaryYears: 3,
      }}
      currentUser={{}}
      companyName={currentCompany.name}
      blockClicks
    />
  )
}

export default CelebrationCardBackgroundColor
