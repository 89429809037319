import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import { ReduxState } from 'redux/redux'
import API from 'services/api'
import appSignal from 'services/appSignal'
import entitySlice from 'redux/slices/entities'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { getResponseOrThrow } from 'utils/errorHandling'
import { PageSnapshotState } from 'types/page/snapshot'
import queryParamsFromHeaders, { PaginationParams, defaultPaginationParams } from 'utils/queryParamsFromHeaders'

export const initialState: PageSnapshotState = {
  snapshotIds: [],
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams, perPage: 5,
    },
  },
}

const snapshotSlice = createSlice({
  name: 'pageSnapshots',
  initialState,
  reducers: {
    ...defaultActions,

    addSnapshotIds(state, action) {
      state.snapshotIds = _.uniq([...state.snapshotIds, ...action.payload])
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    reset: () => initialState,
  },
})

const asyncActions = {
  fetchAll: (pageId: string, params: PaginationParams = {}) => async (dispatch) => {
    try {
      dispatch(snapshotSlice.actions.isLoading(true))
      const response = await API.pages.snapshots.fetchAll(pageId, params)
      const snapshotIds = response.data.data.map(snapshot => snapshot.id)
      const newQueryParams = queryParamsFromHeaders(response)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(snapshotSlice.actions.addSnapshotIds(snapshotIds))
      dispatch(snapshotSlice.actions.setQueryParams(newQueryParams))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(snapshotSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(snapshotSlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.pageSnapshots.meta,

  getSnapshots: () => (state: ReduxState) => state.pageSnapshots.snapshotIds.map(id => build(state.entities, 'pageSnapshot', id)).filter(Boolean),
}

const pageSnapshotSlice = {
  ...snapshotSlice,
  asyncActions,
  selectors,
}

export default pageSnapshotSlice
