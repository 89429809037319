import React from 'react'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ReactionsAndComments from 'components/common/reactions/reactionsAndComments'

const ShoutoutSocial = ({ shoutout, showCommentsOnInit = true }) => {
  const { settings } = useCurrentCompany()
  const commentsEnabled = settings.comments?.shoutouts?.enabled

  const useReactions = settings.reactions?.type === 'full_emoji_library'

  return (
    <ReactionsAndComments
      record={shoutout}
      type='shoutout'
      disableComments={!commentsEnabled}
      showCommentsOnInit={showCommentsOnInit}
      onlyLike={!useReactions}
    />
  )
}

export default ShoutoutSocial
