import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.integrations_page')

const UninstallOtherIntegration = ({ integrationName, installedIntegration }) => (
  <div className='text-secondary text-small'>
    {I18N('uninstall_other_integration', { integrationName, installedIntegration })}
  </div>
)

export default UninstallOtherIntegration
