import React from 'react'
import { getPropertyListWithValues } from 'components/admin/customStyles/utils'
import { useVariables, useCustomStyles } from 'pages/admin/custom_styles/context'
import BorderRadiusControl from './borderRadiusControl'

interface Props {
  borderTypes: string[]
}

const BorderRadius = ({ borderTypes }: Props) => {
  const { handlePropertyChanged } = useCustomStyles()
  const border = useVariables('border')
  const borderRadiusVariables = getPropertyListWithValues({ variables: border, type: 'border' }).filter((elem) => {
    const [variable, _] = elem

    return borderTypes.includes(variable)
  })

  const valueRanges = {
    profileImageBorderRadius: { min: 0, max: 100 },
    buttonBorderRadius: { min: 0, max: 24 },
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <div>
          {borderRadiusVariables.map(([variable, value]) => (
            <BorderRadiusControl
              key={variable}
              variable={variable}
              value={value}
              valueRange={valueRanges[variable] || {}}
              onChange={handlePropertyChanged}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BorderRadius
