import React, { useEffect } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import taskableStepSlice from 'redux/slices/journeys/taskableSteps'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonSecondary } from 'components/common/buttons'
import LoadingSpinner from 'components/common/loadingSpinner'
import CdnSvg from 'components/common/cdnSvg'

const personsIconPath = '/images/personsIcon.svg'

const I18N = i18nPath('views.journeys.my_tasks')

const DIRECT_REPORT_TAB = 'direct_report_tasks'

const AllOnTrackTile = () => (
  <div
    className='MyTasksDirectReportsHeaderTile onTrack p-5 d-flex flex-column align-items-start justify-content-center'
  >
    <h4 className='text-white'>{I18N('header_text.direct_reports.all_on_track_text')}</h4>
  </div>
)

const NoOpenTasksTile = () => (
  <div className='MyTasksDirectReportsHeaderTile noOpenTasks p-5 d-flex flex-column align-items-start justify-content-center'>
    <CdnSvg src={personsIconPath} className='personsIcon mb-1' />
    <h4 className='text-white'>{I18N('header_text.direct_reports.no_open_tasks_text')}</h4>
  </div>
)

const OverdueTasksTile = ({ overdueCount = 0, onViewDetailsClick }) => (
  <div className='MyTasksDirectReportsHeaderTile overdueTasks p-5 d-flex flex-column align-items-start justify-content-center'>
    <h1 className='text-white mb-0'>{overdueCount}</h1>
    <h4 className='text-left text-white'>{I18N('header_text.direct_reports.overdue_tasks_text')}</h4>
    <ButtonSecondary
      className='view-details-button text-white'
      onClick={() => onViewDetailsClick(DIRECT_REPORT_TAB)}
    >
      {I18N('header_text.direct_reports.view_details')}
    </ButtonSecondary>
  </div>
)

const LoadingTile = () => (
  <div className='MyTasksDirectReportsHeaderTile loading p-5 d-flex flex-column align-items-start justify-content-center'>
    <LoadingSpinner />
  </div>
)

const MyTasksDirectReportsHeaderTile = ({ onViewDetailsClick }) => {
  const dispatch = useDispatch()

  const {
    directReportsOverdueTaskableStepsCount,
    directReportsOpenTaskableStepsCount,
    isLoadingTasksCount,
  } = useSelector(taskableStepSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(taskableStepSlice.asyncActions.getDirectReportsCountByTaskableStepStatus())
  }, [])

  if (isLoadingTasksCount) {
    return <LoadingTile />
  }

  if (_.isNull(directReportsOpenTaskableStepsCount) || _.isNull(directReportsOverdueTaskableStepsCount)) {
    return null
  }

  if (directReportsOpenTaskableStepsCount === 0) {
    return <NoOpenTasksTile />
  }

  if (directReportsOverdueTaskableStepsCount === 0 && directReportsOpenTaskableStepsCount > 0) {
    return <AllOnTrackTile />
  }

  return (
    <OverdueTasksTile onViewDetailsClick={onViewDetailsClick} overdueCount={directReportsOverdueTaskableStepsCount} />
  )
}


export default MyTasksDirectReportsHeaderTile
