import React from 'react'
import classNames from 'classnames'

import { DownGrayChevronIcon, UpGrayChevronIcon } from 'components/icons/chevron'

const QuestionVotingSectionMobile = ({
  isVotingEnabled,
  hasUpvoteFromCurrentUser,
  hasDownvoteFromCurrentUser,
  upvoteHandler,
  downvoteHandler,
  unvoteHandler,
  voteScoreText,
}) => (
  <div className='QuestionVotingSectionMobile d-inline-flex align-items-center'>
    <div
      onClick={hasUpvoteFromCurrentUser ? unvoteHandler : upvoteHandler}
      className={classNames('Cleary-chevron Cleary-chevron-up px-3', {
        'Cleary-chevron--hover pointer': isVotingEnabled,
        'Cleary-chevron--active pointer': hasUpvoteFromCurrentUser,
      })}
    >
      <UpGrayChevronIcon />
    </div>
    <h5 className='m-0 p-0'>{voteScoreText}</h5>
    <div
      onClick={hasDownvoteFromCurrentUser ? unvoteHandler : downvoteHandler}
      className={classNames('Cleary-chevron Cleary-chevron-down px-3', {
        'Cleary-chevron--hover pointer': isVotingEnabled,
        'Cleary-chevron--active pointer': hasDownvoteFromCurrentUser,
      })}
    >
      <DownGrayChevronIcon />
    </div>
  </div>
)

export default QuestionVotingSectionMobile
