import React, { SetStateAction, useEffect, useState } from 'react'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import { ChatChannelTypeEnum } from 'types/chat/channel'
import AppModal from 'components/common/modal'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'

const I18N = i18nPath('views.ai_assistant.dashboard.left_sidebar.add_channels_modal')

interface AddChannelsModalProps {
  visible: boolean
  setVisible: React.Dispatch<SetStateAction<boolean>>
  setActiveChannels: React.Dispatch<SetStateAction<any[]>>
  suggestedChannels: any[]
  isLoadingSuggestedChannels: boolean
}

const AddChannelsModal = ({
  visible, setVisible, setActiveChannels, suggestedChannels, isLoadingSuggestedChannels,
}: AddChannelsModalProps) => {
  const [selectedChannels, setSelectedChannels] = useState<any[]>([])

  const [
    createKnowledgeIngestion,
    { isLoading: isSaving },
  ] = useApi(API.ai.assistant.knowledgeIngestions.create)

  const handleSave = () => {
    createKnowledgeIngestion({
      ingestionMetadata: {
        channels: selectedChannels.map(({ externalId, name }) => ({
          id: externalId,
          name: `#${name}`,
        })),
      },
      ingestionType: 'slack_channel',
    }, 'dashboard')

    setActiveChannels(activeChannels => [
      ...activeChannels,
      ...selectedChannels.map(({ id, name, externalId }) => ({ id, name, externalId })),
    ])

    setVisible(false)
  }

  useEffect(() => {
    setSelectedChannels(suggestedChannels)
  }, [suggestedChannels])

  return (
    <AppModal
      visible={visible}
      toggle={() => setVisible(val => !val)}
      modalContentClassName='container d-flex flex-column w-100'
    >
      <>
        <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
          <h3 className='mb-0'>{I18N('title')}</h3>
        </div>
        <section className='mb-5'>
          <label className='text-smallest text-secondary'>{I18N('select_label')}</label>
          <ChatChannelsAsyncSelect
            value={selectedChannels}
            onChange={value => setSelectedChannels(value)}
            isLoadingExternal={isLoadingSuggestedChannels}
            types={[ChatChannelTypeEnum.SlackChannel]}
            creatable={false}
          />
        </section>

        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={() => setVisible(false)} />
          <ButtonNarrow
            onClick={handleSave}
            disabled={isLoadingSuggestedChannels || isSaving}
            showLoadingSpinner={isLoadingSuggestedChannels || isSaving}
          >
            {I18NCommon('save')}
          </ButtonNarrow>
        </div>
      </>
    </AppModal>
  )
}

export default AddChannelsModal
