import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import arrayMove from 'array-move'

import API from 'services/api'
import { sendErrorToAppSignalAndShowToast, checkForError } from 'utils/errorHandling'
import { i18nPath } from 'utils/i18nHelpers'
import entitySlice from './entities'

const I18N = i18nPath('views.admin.company_value_list.errors')

export const newCompanyValue = {
  id: null,
  name: '',
  description: '',
  orderPosition: '',
  imageUrl: null,
  image: null,
  active: true,
}

export const initialState = {
  companyValueIds: [],
  companyValueId: null,
  workingCopy: {},
  meta: {
    isLoading: false,
    isSaving: false,
    isNotFound: false,
    error: null,
  },
}

const companyValueSlice = createSlice({
  name: 'companyValues',
  initialState,
  reducers: {
    isLoading(state, action) {
      state.meta.isLoading = action.payload
    },
    isSaving(state, action) {
      state.meta.isSaving = action.payload
    },
    isNotFound(state, action) {
      state.meta.isNotFound = action.payload
    },
    setError(state, action) {
      state.meta.error = action.payload
    },
    setCompanyValueId(state, action) {
      state.companyValueId = action.payload
    },
    setNewCompanyValue(state, action) {
      state.companyValueId = 'new'
      state.workingCopy = newCompanyValue
    },
    updateWorkingCopy(state, action) {
      state.workingCopy = action.payload
    },
    setCompanyValueIds(state, action) {
      state.companyValueIds = action.payload
    },
    clearCompanyValue(state, action) {
      state.companyValueId = null
      state.workingCopy = {}
      state.meta = initialState.meta
    },
    clearCompanyValueIds(state, action) {
      state.companyValueIds = []
      state.meta = initialState.meta
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(companyValueSlice, {
  asyncActions: {
    admin: {
      fetchCompanyValue: companyValueId => async (dispatch, getState) => {
        try {
          dispatch(companyValueSlice.actions.isLoading(true))
          const response = await API.admin.companyValues.fetch(companyValueId)
          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(companyValueSlice.actions.setCompanyValueId(companyValueId))
          dispatch(companyValueSlice.actions.updateWorkingCopy(build(getState().entities, 'companyValue', companyValueId)))
        } catch (e) {
          dispatch(companyValueSlice.actions.isNotFound(true))
        } finally {
          dispatch(companyValueSlice.actions.isLoading(false))
        }
      },

      updateCompanyValue: companyValue => async (dispatch, getState) => {
        try {
          dispatch(companyValueSlice.actions.isSaving(true))
          const response = await API.admin.companyValues.update(companyValue)
          dispatch(entitySlice.actions.update({ data: response.data }))
          dispatch(companyValueSlice.actions.updateWorkingCopy(build(getState().entities, 'companyValue', companyValue.id)))
          dispatch(companyValueSlice.actions.setError(null))
        } catch (e) {
          const { error } = checkForError(e.response)
          dispatch(companyValueSlice.actions.setError(error))
        } finally {
          dispatch(companyValueSlice.actions.isSaving(false))
        }
      },

      createCompanyValue: (companyValue, history) => async (dispatch, getState) => {
        try {
          dispatch(companyValueSlice.actions.isSaving(true))
          const response = await API.admin.companyValues.create(companyValue)
          const companyValueId = response.data.data.id
          dispatch(companyValueSlice.actions.setCompanyValueId(companyValueId))
          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(companyValueSlice.actions.updateWorkingCopy(build(getState().entities, 'companyValue', companyValueId)))
          dispatch(companyValueSlice.actions.setError(null))
          history.push(`/admin/company_values/${companyValueId}`)
        } catch (e) {
          const { error } = checkForError(e.response)
          dispatch(companyValueSlice.actions.setError(error))
        } finally {
          dispatch(companyValueSlice.actions.isSaving(false))
        }
      },

      deleteCompanyValue: (companyValue, history) => async (dispatch) => {
        try {
          dispatch(companyValueSlice.actions.isSaving(true))
          await API.admin.companyValues.destroy(companyValue)
          dispatch(entitySlice.actions.remove({ id: companyValue.id, type: 'companyValue' }))
          history.push('/admin/company_values')
        } catch (e) {
          const { error } = checkForError(e.response)
          dispatch(companyValueSlice.actions.setError(error))
        } finally {
          dispatch(companyValueSlice.actions.isSaving(false))
        }
      },

      fetchCompanyValues: (options = { showLoading: true }) => async (dispatch) => {
        try {
          dispatch(companyValueSlice.actions.isLoading(options.showLoading))
          const response = await API.admin.companyValues.fetchAll()
          const companyValueIds = response.data.data.map(companyValue => companyValue.id)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(companyValueSlice.actions.setCompanyValueIds(companyValueIds))
        } catch (e) {
          sendErrorToAppSignalAndShowToast(e, dispatch, I18N('fetch_all'))
        } finally {
          dispatch(companyValueSlice.actions.isLoading(false))
        }
      },

      setOrder: ({ oldIndex, newIndex }) => async (dispatch, getState) => {
        dispatch(companyValueSlice.actions.isSaving(true))
        const state = getState()
        const companyValueIds = state.companyValues.companyValueIds
        const oldCompanyValue = build(state.entities, 'companyValue', companyValueIds[oldIndex])
        const newCompanyValue = { ...oldCompanyValue, orderPosition: newIndex }
        dispatch(companyValueSlice.actions.setCompanyValueIds(arrayMove(companyValueIds, oldIndex, newIndex)))

        try {
          await API.admin.companyValues.update(newCompanyValue)
          dispatch(companyValueSlice.asyncActions.admin.fetchCompanyValues({ showLoading: false }))
        } catch (e) {
          dispatch(companyValueSlice.actions.setCompanyValueIds(companyValueIds))
          sendErrorToAppSignalAndShowToast(e, dispatch, I18N('set_order'))
        } finally {
          dispatch(companyValueSlice.actions.isSaving(false))
        }
      },
    },
  },
})

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
_.assign(companyValueSlice, {
  selectors: {
    admin: {
      getMetaData: () => state => state.companyValues.meta,

      getCompanyValue: () => state => build(state.entities, 'companyValue', state.companyValues.companyValueId),

      getWorkingCopy: () => state => state.companyValues.workingCopy,

      getCompanyValues: () => state => state.companyValues.companyValueIds.map(id => build(state.entities, 'companyValue', id)),
    },
  },
})

export default companyValueSlice
