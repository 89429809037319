import React from 'react'
import classNames from 'classnames'
import moment from 'moment'

import currentTimezone from 'utils/currentTimezone'
import { I18NCommon, i18nLabelWithTimeZone, i18nMoment } from 'utils/i18nHelpers'
import DatePicker from 'components/form_fields/datePicker'
import StepTemplateTriggerSelector from 'components/admin/journeys/stepTemplateTriggerSelector'
import FormTimezoneSelectField from 'components/form_fields/formTimezoneSelectField'

const TriggerOrDateSelect = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
  useTriggerSelect,
  journeyBlueprint,
  triggers = [],
  showTriggerAfterCompletionSelection = true,
  showTimezoneDropdown = true,
}) => {
  const { triggerTimezone } = workingCopy
  const timezone = triggerTimezone || currentTimezone()

  const selectedTriggerTime = (triggerName) => {
    const time = moment(workingCopy[`${triggerName}At`])

    return moment.utc(time).tz(timezone)
  }

  return (
    <div className='TriggerOrDateSelect form-group mb-4 task-schedule-grid'>
      {useTriggerSelect ? (
        <StepTemplateTriggerSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          isDisabled={isDisabled}
          triggers={triggers}
          journeyBlueprint={journeyBlueprint}
          showTriggerAfterCompletionSelection={showTriggerAfterCompletionSelection}
        />
      ) : (
        <div className='StepTrigger task-schedule-grid-body'>
          {triggers.map(({ name: triggerName, label: triggerLabel }, index) => (
            <React.Fragment key={`trigger-select-${triggerName}`}>
              {triggerLabel && (
                i18nLabelWithTimeZone(
                  <label
                    className={classNames('required-form-label', index > 0 && 'mt-3')}
                  >
                    {triggerLabel}
                  </label>,
                  timezone
                )
              )}
              <DatePicker
                id={`select${_.capitalize(triggerName)}At`}
                className={classNames({ isDisabled })}
                selected={workingCopy[`${triggerName}At`] && selectedTriggerTime(triggerName)}
                onChange={newTriggerAt => updateWorkingCopy({ [`${triggerName}At`]: newTriggerAt.format() })}
                showTimeSelect
                isClearable={false}
                readOnly={isDisabled}
              >
                {triggerName === 'trigger' && (
                  <div
                    className='react-datepicker-children text-center p-2 link-color pointer'
                    onMouseDown={() => updateWorkingCopy({ triggerAt: moment().format() })}
                  >
                    {I18n.t('views.common.immediately')}
                  </div>
                )}
              </DatePicker>
            </React.Fragment>
          ))}
        </div>
      )}

      {showTimezoneDropdown && (
        <div className={classNames('task-schedule-grid-sidebar', { 'pt-3': useTriggerSelect })}>
          <FormTimezoneSelectField
            className='TimezoneSelect ml-5'
            label={I18NCommon('timezone')}
            currentValue={workingCopy.triggerTimezone}
            onChange={timezone => updateWorkingCopy({ triggerTimezone: timezone?.timezone })}
            isDisabled={isDisabled}
            isClearable={false}
          />
        </div>
      )}
    </div>
  )
}

export default TriggerOrDateSelect
