import { trackAmplitudeEvent } from 'services/tracker'

const trackQuestionCreation = ({ question_type, event, anonymous = false }) => trackAmplitudeEvent('question_submitted', {
  eventId: event.id,
  title: event.title,
  anonymous,
  question_count: event.questionsCount,
  participant_count: event.participantsCount,
  question_type,
})

const trackAmplitudeEventView = (event) => {
  if (event) {
    trackAmplitudeEvent('event_viewed', {
      eventId: event.id,
      title: event.title,
      question_count: event.questionsCount,
      answer_count: event.answersCount,
      participant_count: event.participantsCount,
    })
  }
}

export { trackAmplitudeEventView, trackQuestionCreation }
