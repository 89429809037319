import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import userSlice from 'redux/slices/users'
import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'

import BasicInformationSection from 'components/admin/users/userEditor/basicInformationSection'
import RoleDetailsSection from 'components/admin/users/userEditor/roleDetailsSection'
import LocationAndTeamsSection from 'components/admin/users/userEditor/locationAndTeamsSection'
import PersonalInformationSection from 'components/admin/users/userEditor/personalInformationSection'
import ContactInformationSection from 'components/admin/users/userEditor/contactInformationSection'
import UserSettingsSection from 'components/admin/users/userEditor/userSettingsSection'

import UserFormErrorBanner from 'components/admin/users/userEditor/userFormErrorBanner'
import UserEditorTabs from 'components/admin/users/userEditor/userEditorTabs'
import useScrollToOnError from 'components/common/hooks/useScrollToOnError'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.users')

const CreateUserPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => function cleanup() {
    dispatch(userSlice.actions.clear())
  }, [])

  useEffect(() => {
    dispatch(userSlice.asyncActions.admin.fetchSelectableOptionValues())
  }, [])

  const [user, setUser] = useState({
    id: null,
    first_name: '',
    last_name: '',
    primaryEmail: '',
    slack: '',
    active: false,
    hidden: false,
    assistedUsers: [],
  })

  const currentCompany = useCurrentCompany()
  const { isSaving, error } = useSelector(userSlice.selectors.getAdminUserMetaData())

  const isMissingEmail = user.preboarding ? !user.personalEmail : !user.primaryEmail
  const disableCreateButton = !user.firstName || !user.lastName || isMissingEmail
  const renderHrisSyncAlert = currentCompany.hasHrisIntegration
  const headerRef = useScrollToOnError(error)

  const saveUser = () => {
    dispatch(userSlice.asyncActions.admin.createUser(user, history))
  }

  return (
    <div className='CreateUserPage'>
      <header className='AdminHeader' ref={headerRef}>
        <h3>{I18N('create_new_user')}</h3>
      </header>
      {error && <UserFormErrorBanner error={error} title={I18N('errors_found')} />}
      <main className='AdminContent'>
        <div className='d-flex justify-content-between mb-3'>
          <UserEditorTabs isNewUser />
          <Button disabled={disableCreateButton} onClick={saveUser} showLoadingSpinner={isSaving}>
            {I18N('create_new_user')}
          </Button>
        </div>
        {renderHrisSyncAlert && (
          <div className='alert alert-warning'>
            <div>{I18N('banner_hris_sync_alert', { hris: currentCompany.hrisIntegrationName })}</div>
          </div>
        )}
        <BasicInformationSection user={user} setUser={setUser} isNewUser />
        <RoleDetailsSection user={user} setUser={setUser} />
        <LocationAndTeamsSection user={user} setUser={setUser} />
        <PersonalInformationSection user={user} setUser={setUser} />
        <ContactInformationSection user={user} setUser={setUser} />
        <UserSettingsSection user={user} setUser={setUser} />
        <div className='mt-4 mb-5 d-flex justify-content-end'>
          <Button disabled={disableCreateButton} onClick={saveUser} showLoadingSpinner={isSaving}>
            {I18N('create_new_user')}
          </Button>
        </div>
      </main>
    </div>
  )
}

export default CreateUserPage
