import classNames from 'classnames'
import InfoTooltip from 'components/common/infoTooltip'
import React from 'react'

type Props = {
  id?: string
  className?: string
  checked: boolean
  onChange: (checked: boolean) => void
  label: string | React.ReactNode
  infoTooltip?: string
  disabled?: boolean
  helpText?: string
  helpTextClassName?: string
}

const CheckBox = ({
  id, checked, onChange, label, infoTooltip, className, disabled, helpText, helpTextClassName,
}: Props) => (
  <>
    <div className={classNames('CheckBox d-flex align-items-center', className)}>
      <input
        id={id}
        type='checkbox'
        disabled={disabled}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />
      <label className='ml-2 mb-0' htmlFor={id}>{label}</label>
      {infoTooltip && (
        <InfoTooltip
          text={infoTooltip}
        />
      )}
    </div>
    {helpText && <span className={classNames(helpTextClassName, 'text-small text-secondary mt-1')}>{helpText}</span>}
  </>
)

export default CheckBox
