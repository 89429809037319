import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import FormErrorList from 'components/errors/formErrorList'
import { LoadingContainer } from 'components/common/loadingContainer'
import ImageSelector from 'components/common/imageSelector'
import { Button } from 'components/common/buttons'

import companyValueSlice from 'redux/slices/companyValues'
import { buildCompanyValuePayload } from 'services/api/admin/companyValues'

const I18N = i18nPath('views.admin.company_value_editor')
const I18NCommon = i18nPath('views.common')

const CompanyValueForm = ({ isNew }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { error, isSaving } = useSelector(companyValueSlice.selectors.admin.getMetaData())
  const companyValue = useSelector(companyValueSlice.selectors.admin.getCompanyValue())
  const workingCopy = useSelector(companyValueSlice.selectors.admin.getWorkingCopy())

  const changesPresent = !_.isEqual(buildCompanyValuePayload(companyValue), buildCompanyValuePayload(workingCopy))

  const handleImageInputChange = (image) => {
    const changedCompanyValue = { ...workingCopy, image }
    dispatch(companyValueSlice.actions.updateWorkingCopy(changedCompanyValue))
  }

  const handleActiveChange = (event) => {
    const changedActive = { ...workingCopy, active: event.target.checked }
    dispatch(companyValueSlice.actions.updateWorkingCopy(changedActive))
  }

  const generateChangeHandler = fieldName => (e) => {
    const changedCompanyValue = { ...workingCopy, [fieldName]: e.target.value }
    dispatch(companyValueSlice.actions.updateWorkingCopy(changedCompanyValue))
  }

  const saveCompanyValue = () => {
    if (isNew) {
      dispatch(companyValueSlice.asyncActions.admin.createCompanyValue(workingCopy, history))
    } else {
      dispatch(companyValueSlice.asyncActions.admin.updateCompanyValue(workingCopy))
    }
  }

  const deleteCompanyValue = () => {
    if (confirm(I18N('delete_confirm'))) {
      dispatch(companyValueSlice.asyncActions.admin.deleteCompanyValue(companyValue, history))
    }
  }

  return (
    <div>
      <header className='AdminHeader'>
        <h3>{I18N(isNew ? 'new_title' : 'edit_title')}</h3>
      </header>

      <main className='AdminContent'>
        {error && <FormErrorList error={error} />}
        <div className='row'>
          <div className='col-md-7'>
            <div className='row'>
              <div className='form-group col'>
                <label className='required-form-label'>{I18N('name')}</label>
                <input value={workingCopy.name || ''} onChange={generateChangeHandler('name')} />
              </div>
            </div>

            <div className='row'>
              <div className='form-group col'>
                <label className='required-form-label'>{I18N('description')}</label>
                <input value={workingCopy.description || ''} onChange={generateChangeHandler('description')} />
              </div>
            </div>

            <div className='row'>
              <div className='form-group col'>
                <label className='form-label'>{I18N('position')}</label>
                <input value={workingCopy.orderPosition || ''} onChange={generateChangeHandler('orderPosition')} />
                <div className='text-small text-secondary mt-1'>{I18N('position_helper_text')}</div>
              </div>
            </div>

            <div className='row'>
              <div className='form-group col'>
                <label className='required-form-label'>{I18N('image')}</label>
                <ImageSelector
                  placeholderUrl={companyValue?.imageUrl}
                  onImageAdded={image => handleImageInputChange(image)}
                  accept={['.svg']}
                />
                <div className='text-small text-secondary mt-1'>{I18N('image_guidance')}</div>
              </div>
            </div>

            <div className='row'>
              <div className='form-group col'>
                <div className='form-check'>
                  <input type='checkbox' id='company-value-active-checkbox' checked={workingCopy.active || ''} onChange={handleActiveChange} />
                  <label htmlFor='company-value-active-checkbox' className='checkbox-label form-inline-check'>{I18NCommon('active')}</label>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='form-group col'>
                <Button disabled={!changesPresent} onClick={saveCompanyValue} showLoadingSpinner={isSaving}>
                  {I18N('save_company_value')}
                </Button>
                {!isNew && (
                  <Button variant='danger' className='ml-2' onClick={deleteCompanyValue}>
                    {I18N('delete')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

    </div>
  )
}

const CompanyValueEditorPage = () => {
  const { companyValueId } = useParams()
  const dispatch = useDispatch()
  const isNew = companyValueId === 'new'

  useEffect(() => {
    if (isNew) {
      dispatch(companyValueSlice.actions.setNewCompanyValue())
    } else {
      dispatch(companyValueSlice.asyncActions.admin.fetchCompanyValue(companyValueId))
    }

    return () => dispatch(companyValueSlice.actions.clearCompanyValue())
  }, [])

  const { isLoading, isNotFound } = useSelector(companyValueSlice.selectors.admin.getMetaData())

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <CompanyValueForm
        isNew={isNew}
      />
    </LoadingContainer>
  )
}


export default CompanyValueEditorPage
