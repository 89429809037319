import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'

const I18N = i18nPath('views.common')

const CustomProfileField = ({
  fieldName, currentCompany, userAnswer, updateField,
}) => {
  const { settings: companySettings } = currentCompany
  const profileFields = companySettings.profile.fields

  const label = currentCompany[`${fieldName}Label`]
  const editingPlaceholder = currentCompany[`${fieldName}EditingPlaceholder`]
  const inputPlaceholder = currentCompany[`${fieldName}InputPlaceholder`]
  const fallbackPlaceholder = I18N('custom_field_placeholder')

  return (
    <div>
      {profileFields[fieldName] && (
        <div className='form-group mt-4'>
          <label className='required-form-label label w-100'>{label}</label>
          <TiptapEditor
            className='RoundedTiptapEditor w-100 mb-5'
            toolbarItems={SMALL_TOOLBAR}
            configuration={{
              ...SIMPLE_EDITOR_CONFIGURATION,
              placeholder: editingPlaceholder || inputPlaceholder || fallbackPlaceholder,
            }}
            html={userAnswer || ''}
            onChange={updateField(fieldName)}
          />
        </div>
      )}
    </div>
  )
}

export default CustomProfileField
