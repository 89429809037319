import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import ReactSelect from 'components/common/react_select'
import { SurveyQuestionType, QuestionTypeEnum } from 'types/surveys/question'
import SurveyFormType from 'types/surveys/form'

const I18N = i18nPath('views.admin.surveys')

const PROFILE_FIELD = 'profile_field'

const QUESTION_TYPE_OPTIONS = [
  QuestionTypeEnum.ShortText,
  QuestionTypeEnum.Paragraph,
  QuestionTypeEnum.Checkbox,
  QuestionTypeEnum.MultipleChoice,
  QuestionTypeEnum.Scale,
  PROFILE_FIELD,
].map(type => ({ value: type, label: I18N(`questions.types.${type}`) }))

export const defaultScaleConfig = {
  minValue: 1,
  maxValue: 5,
  step: 1,
  labels: {
    1: I18N('strongly_disagree'),
    2: I18N('disagree'),
    3: I18N('neutral'),
    4: I18N('agree'),
    5: I18N('strongly_agree'),
  },
}

interface QuestionTitleWithIndexBubbleProps {
  form?: SurveyFormType | null,
  question: SurveyQuestionType,
  questionNumber: number | undefined,
  isEditing?: boolean,
  updateQuestion?: (changes: Partial<SurveyQuestionType>) => void,
  isDisabled?: boolean,
  hasIndexBubble?: boolean,
}

const QuestionTitleWithIndexBubble = ({
  form = null,
  question,
  questionNumber = 1,
  isEditing = false,
  updateQuestion = (changes: Partial<SurveyQuestionType>) => {},
  isDisabled = true,
  hasIndexBubble = true,
} : QuestionTitleWithIndexBubbleProps) => {
  const availableProfileFieldQuestionsHash = form?.availableProfileFieldQuestionsHash || []
  const isProfileFieldQuestion = !!question?.profileField

  const onQuestionTypeChange = (newType) => {
    const config = newType === QuestionTypeEnum.Scale ? defaultScaleConfig : {}
    const isChoiceType = [QuestionTypeEnum.Checkbox, QuestionTypeEnum.MultipleChoice].includes(newType)
    const options = isChoiceType ? [{ value: '' }] : []
    let profileField: string | null = null
    let title = question?.title

    if (newType === PROFILE_FIELD) {
      if (availableProfileFieldQuestionsHash.length > 0) {
        const profileFieldInfo = availableProfileFieldQuestionsHash[0]
        profileField = profileFieldInfo?.profileField
        newType = profileFieldInfo?.type
        title = profileFieldInfo?.label
      } else {
        newType = QuestionTypeEnum.ShortText
      }
    }

    updateQuestion({
      type: newType, config, options, profileField, title,
    })
  }

  return (
    <div className='QuestionTitleWithIndexBubble d-flex align-items-center'>
      {hasIndexBubble && (
        <div className='QuestionIndexCirlce mr-3 d-flex justify-content-center align-items-center'>
          <span>{questionNumber}</span>
        </div>
      )}
      {isEditing ? (
        <ReactSelect
          name='button_style'
          value={QUESTION_TYPE_OPTIONS.find(option => (isProfileFieldQuestion
            ? option.value === PROFILE_FIELD
            : option.value === question.type)
          )}
          options={QUESTION_TYPE_OPTIONS}
          onChange={option => onQuestionTypeChange(option.value)}
          isDisabled={isDisabled}
        />
      ) : (
        <h5 className={classNames('mb-0', (question.required && 'required-form-label'))}>{question?.title}</h5>
      )}
    </div>
  )
}

export default QuestionTitleWithIndexBubble
