import React, { useState, useEffect } from 'react'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import classNames from 'classnames'

import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import FormEditableField from 'components/form_fields/formEditableField'

const I18N = i18nPath('views.groups.group_page')

const AssignRoleModal = ({
  className,
  onSave,
  showModal,
  closeModal,
  isSaving = false,
  role = '',
}) => {
  const [workingCopyRole, setWorkingCopyRole] = useState(role)

  useEffect(() => {
    setWorkingCopyRole(role)
  }, [showModal])

  return (
    <Modal size='md' className={classNames('AssignRoleModal', className)} visible={showModal} toggle={closeModal}>
      <div className='d-flex flex-column p-2'>
        <h4>{I18N('assign_role')}</h4>
        <div className='text-secondary'>{I18N('assign_role_secondary')}</div>
        <FormEditableField
          className='mt-5'
          placeholder={I18N('role_placeholder')}
          isRequired={false}
          label={I18N('role_title')}
          onChange={e => setWorkingCopyRole(e.target.value)}
          currentValue={workingCopyRole}
        />
        <div className='d-flex align-self-end mt-4'>
          <Button className='mr-2' disabled={isSaving} variant='secondary' onClick={closeModal}>
            {I18NCommon('cancel')}
          </Button>
          <Button onClick={() => onSave(workingCopyRole)} disabled={isSaving} showLoadingSpinner={isSaving}>
            {I18NCommon('save')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AssignRoleModal
