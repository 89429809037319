import { Button, ButtonSecondarySmall } from 'components/common/buttons'
import CheckBox from 'components/common/buttons/checkBox'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import ReactSelect from 'components/common/react_select'
import { RootSidebar } from 'components/common/sidebar'
import StatusLabel from 'components/common/status_label'
import SmartTable from 'components/common/tables/smartTable'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import React, { useState } from 'react'
import API from 'services/api'
import { i18nPath, I18NCommon, i18nFormat } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.cleary_ai_settings.tabs.slack_app')

const columns = (setSelectedChannelConfiguration: (channelConfiguration: any) => void) => [
  {
    header: I18N('table.headers.channel_name'),
    col: 'col-name',
    accessor: channelConfiguration => (
      <a
        href={channelConfiguration.channel.externalUrl}
        target='_blank'
        rel='noopener noreferrer'
      >
        #{channelConfiguration.channel.name}
      </a>
    ),
  },
  {
    header: I18N('table.headers.answer_questions'),
    col: 'col-answer-questions',
    accessor: channelConfiguration => (
      <StatusLabel color={channelConfiguration.enabled ? 'green' : 'red'}>
        {channelConfiguration.enabled ? I18NCommon('enabled') : I18NCommon('disabled')}
      </StatusLabel>
    ),
  },
  {
    header: I18N('table.headers.answers_source'),
    col: 'col-answers-source',
    accessor: (channelConfiguration) => {
      if (channelConfiguration.workspaces.length > 0) {
        return I18N('table.public_content_workspaces')
      }

      return I18N('table.public_content')
    },
  },
  {
    header: I18N('table.headers.faq_creation'),
    col: 'col-faq-creation',
    accessor: channelConfiguration => (
      <StatusLabel color={channelConfiguration.faqCreationEnabled ? 'green' : 'red'}>
        {channelConfiguration.faqCreationEnabled ? I18NCommon('enabled') : I18NCommon('disabled')}
      </StatusLabel>
    ),
  },
  {
    header: I18NCommon('actions'),
    col: 'col-actions',
    accessor: channelConfiguration => (
      <ButtonSecondarySmall onClick={() => setSelectedChannelConfiguration(channelConfiguration)}>
        {I18NCommon('edit')}
      </ButtonSecondarySmall>
    ),
  },
]

const SlackAppTab = () => {
  const [selectedChannelConfiguration, setSelectedChannelConfiguration] = useState<any>(null)
  const [isWorkspacesSelectOpened, setIsWorkspacesSelectOpened] = useState(false)

  const {
    data: channelConfigurations,
    isLoading,
    paginationData,
    callApi: fetchChannelConfigurations,
  } = useFetch(API.ai.assistant.channelConfigurations.fetchAll)

  const { data: workspaces } = useFetch(API.pageWorkspaces.fetchAll)

  const [update, { isLoading: isUpdating }] = useApi(
    API.ai.assistant.channelConfigurations.update,
    {
      onSuccess: () => {
        setSelectedChannelConfiguration(null)
        fetchChannelConfigurations()
      },
    }
  )

  const fetch = (params) => {
    fetchChannelConfigurations(params)
  }

  return (
    <>
      <section className='SlackAppTab'>
        <div className='white-card p-3'>
          <div className='d-flex align-items-center mb-2'>
            <h6 className='mb-0'>{I18N('title')}</h6>
          </div>
          <p className='text-small text-secondary mb-3'>
            {I18N('description')}
          </p>

          <TableLoadingContainer isLoading={isLoading}>
            <SmartTable
              className='ChannelConfigurationsTable white-bg-table'
              data={channelConfigurations}
              columns={columns(setSelectedChannelConfiguration)}
              totalCount={paginationData.total}
              perPage={paginationData.perPage}
              page={paginationData.page}
              pages={paginationData.totalPages}
              onPaginationClick={fetch}
            />
          </TableLoadingContainer>
        </div>
      </section>

      {selectedChannelConfiguration && (
        <RootSidebar
          onClose={() => setSelectedChannelConfiguration(null)}
          title={(
            <span className='text-smallest text-secondary weight-normal'>
              <CdnSvg src='/images/slackLogoIcon.svg' className='slack-icon' />
              {I18N('form.title')}
            </span>
          )}
          isOpen={!!selectedChannelConfiguration}
          className='SlackAppTab__RootSidebar'
        >
          <h1 className='text-xl bold'>#{selectedChannelConfiguration.channel.name}</h1>
          <h2 className='text-normal bold mt-4'>{I18N('form.what_can_slack_do')}</h2>
          <div className='d-flex flex-column gap-2'>
            <CheckBox
              id='answer-questions-checkbox'
              onChange={checked => setSelectedChannelConfiguration({
                ...selectedChannelConfiguration,
                enabled: checked,
              })}
              checked={selectedChannelConfiguration.enabled}
              label={I18N('form.answer_questions')}
            />

            <CheckBox
              id='automatically-generate-faqs-checkbox'
              onChange={checked => setSelectedChannelConfiguration({
                ...selectedChannelConfiguration,
                faqCreationEnabled: checked,
              })}
              checked={selectedChannelConfiguration.faqCreationEnabled}
              label={I18N('form.automatically_generate_faqs')}
            />

            {selectedChannelConfiguration.faqCreationEnabled && (
              <div className='d-flex flex-column gap-2 ml-3'>
                <div className='d-flex align-items-center gap-2'>
                  <input
                    type='radio'
                    id='faq-creation-mode-everyone'
                    checked={selectedChannelConfiguration.faqCreationMode === 'everyone'}
                    onChange={() => setSelectedChannelConfiguration({
                      ...selectedChannelConfiguration,
                      faqCreationMode: 'everyone',
                    })}
                  />
                  <label className='m-0' htmlFor='faq-creation-mode-everyone'>{I18N('form.faqs_from_anyone')}</label>
                </div>

                <div className='d-flex align-items-center gap-2'>
                  <input
                    type='radio'
                    id='faq-creation-mode-specific-users'
                    checked={selectedChannelConfiguration.faqCreationMode === 'specific_users'}
                    onChange={() => setSelectedChannelConfiguration({
                      ...selectedChannelConfiguration,
                      faqCreationMode: 'specific_users',
                    })}
                  />
                  <label className='m-0' htmlFor='faq-creation-mode-specific-users'>{I18N('form.faqs_from_users')}</label>
                </div>

                {selectedChannelConfiguration.faqCreationMode === 'specific_users' && (
                  <EmployeeSearch
                    selectedEmployees={selectedChannelConfiguration.users}
                    onChange={users => setSelectedChannelConfiguration({
                      ...selectedChannelConfiguration,
                      users,
                    })}
                    isMulti
                  />
                )}
              </div>
            )}
          </div>

          <h2 className='text-normal bold mt-4'>{I18N('form.answers_sources')}</h2>
          <p className='text-small mb-3'>
            {i18nFormat(
              I18N('form.answers_sources_description'),
              [<b>{I18N('form.answers_sources_public')}</b>]
            )}
          </p>

          {selectedChannelConfiguration.workspaces.length === 0 && !isWorkspacesSelectOpened ? (
            <Button
              variant='tag'
              className='mb-3 d-flex align-items-center gap-1'
              onClick={() => setIsWorkspacesSelectOpened(true)}
            >
              <CdnSvg src='/images/plusIcon.svg' className='plus-icon' />
              <span className='text-small'>{I18N('form.add_specific_workspace')}</span>
            </Button>
          ) : (
            <div className='d-flex align-items-center gap-2'>
              <div className='react-select-wrapper'>
                <ReactSelect
                  getOptionValue={option => option.id}
                  getOptionLabel={option => (
                    [option.emojiCode, option.title].filter(Boolean).join(' ')
                  )}
                  options={workspaces}
                  isMulti
                  value={selectedChannelConfiguration.workspaces}
                  onChange={(value) => {
                    setSelectedChannelConfiguration({
                      ...selectedChannelConfiguration,
                      workspaces: value,
                    })
                  }}
                  menuPlacement='auto'
                />
              </div>

              <div
                className='pointer'
                onClick={() => {
                  setSelectedChannelConfiguration({
                    ...selectedChannelConfiguration,
                    workspaces: [],
                  })

                  setIsWorkspacesSelectOpened(false)
                }}
              >
                <CdnSvg src='/images/trashIcon.svg' className='trash-icon' />
              </div>
            </div>
          )}

          <Button
            onClick={() => update(selectedChannelConfiguration.id, selectedChannelConfiguration)}
            className='mt-4'
            showLoadingSpinner={isUpdating}
          >
            {I18NCommon('save')}
          </Button>
        </RootSidebar>
      )}
    </>
  )
}

export default SlackAppTab
