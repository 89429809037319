import React, { useState } from 'react'
import Modal from 'components/common/modal'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import RoundedAvatars from 'components/common/roundedAvatars'
import { Button } from 'components/common/buttons'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useApi from 'components/common/hooks/useApi'
import { UserType } from 'types/user'

const I18N = i18nPath('views.admin.users.bulk_update_managers_modal')

const BulkUpdateManagersModal = ({ isOpen, toggle, user }) => {
  const { data: directReports } = useFetch(() => API.users.simpleFetchAll(user.directReportIds))

  const [bulkUpdate, { isLoading: isBulkUpdating }] = useApi(API.admin.users.bulkUpdate, {
    toastSuccessMessage: I18N('success_message'),
    toastErrorMessage: I18N('error_message'),
    onSuccess: toggle,
  })

  const [newManager, setNewManager] = useState<UserType | null>(null)

  const handleBulkUpdate = () => {
    bulkUpdate({ ids: user.directReportIds, managerEmployeeId: newManager?.employeeId })
  }

  return (
    <Modal visible={isOpen} toggle={toggle} size='lg'>
      <h4 className='mb-4'>
        {I18N(user.active ? 'end_date_added_title' : 'inactive_title', { name: user.preferredFullName, endDate: i18nMoment(user.endDate).format('LL') })}
      </h4>
      <RoundedAvatars
        avatarSize='50px'
        users={directReports}
        shownAvatars={10}
      />
      <div className='mt-3 text-large font-weight-bold'>
        {I18N('select_new_manager')}
      </div>
      <EmployeeSearch
        selectedEmployee={newManager}
        onChange={setNewManager}
      />
      <div className='d-flex align-items-center justify-content-end mt-4'>
        <div role='button' tabIndex={0} onClick={toggle} className='mr-3 text-muted-dark'>
          {I18NCommon('cancel')}
        </div>
        <Button
          showLoadingSpinner={isBulkUpdating}
          onClick={handleBulkUpdate}
          disabled={!newManager || isBulkUpdating}
        >
          {I18NCommon('save')}
        </Button>
      </div>
    </Modal>
  )
}

export default BulkUpdateManagersModal
