import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import { ButtonSmallNarrow } from 'components/common/buttons'
import UserLink from 'components/common/userLink'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import userSlice from 'redux/slices/users'

const I18N = i18nPath('views.admin.users.merge_users_modal')

const UserField = ({ field, value }) => (
  <div className='UserField'>
    <>
      <div className='font-weight-500'>{I18N(field)}</div>
      <div>{value}</div>
    </>
  </div>
)

const UserDetails = ({ user }) => {
  if (!user) {
    return <section className='UserDetails h-100' />
  }

  return (
    <section className='UserDetails p-3'>
      <UserField field='title' value={user.title} />
      <UserField field='city' value={user.city} />
      <UserField field='manager' value={user.parent?.preferredFullName} />
      <UserField field='start_date' value={user.startDate} />
      <UserField field='primary_email' value={user.primaryEmail} />
      <UserField field='personal_email' value={user.personalEmail} />
    </section>
  )
}

const MergeUsersModal = ({
  isOpen, toggle, sourceUser, fetchUsers,
}) => {
  const dispatch = useDispatch()
  const [destinationUser, setDestinationUser] = useState()

  const { isMergingUsers } = useSelector(userSlice.selectors.getMetaData())

  const merge = () => {
    const onSuccess = () => {
      fetchUsers()
      toggle()
    }


    dispatch(userSlice.asyncActions.admin.mergePreboardingUsers({
      sourceUserId: sourceUser.id, destinationUserId: destinationUser.id,
    }, onSuccess))
  }

  return (
    <Modal className='MergeUsersModal' visible={isOpen} toggle={toggle} size='lg'>
      <h4 className='mb-4'>{I18N('merge_user')}</h4>
      <main>
        {i18nFormat(I18N('merge_with'), <UserLink user={sourceUser} customClassName='text-normal text-primary font-weight-500' avatarSise='32px' />)}
        <EmployeeSearch
          selectedEmployee={destinationUser}
          onChange={setDestinationUser}
        />
        <UserDetails user={sourceUser} />
        <UserDetails user={destinationUser} />
      </main>
      <div className='d-flex justify-content-end align-items-center mt-4'>
        <a className='text-muted-dark mr-4' onClick={toggle}>{I18n.t('views.common.cancel')}</a>
        <ButtonSmallNarrow
          disabled={!destinationUser}
          onClick={merge}
          showLoadingSpinner={isMergingUsers}
        >
          {I18N('merge')}
        </ButtonSmallNarrow>
      </div>
    </Modal>
  )
}

export default MergeUsersModal
