import React from 'react'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { secondsToLabel } from './utils'
import DataItem from './HeaderDataItem'

const I18N = i18nPath('views.admin.article_stats')

interface Props {
  name: string
  duration: number
  uploadedAt: string
  thumbnailUrl: string
}

const Header = ({
  name, duration, uploadedAt, thumbnailUrl,
}: Props) => (
  <div className='mb-0 ml-1 header'>
    <div>
      <h5>{I18N('videos.card_title')}</h5>
      <p className='m-0'>{name}</p>
    </div>

    <div className='header-right-side'>
      <div className='data-items'>
        <DataItem
          icon='/images/timerIcon.svg'
          label={I18N('videos.duration_label')}
          value={secondsToLabel(duration)}
        />

        <DataItem
          icon='/images/uploadedIcon.svg'
          label={I18N('videos.uploaded_label')}
          value={i18nMoment(uploadedAt).format('MM/DD/YYYY')}
        />
      </div>

      <img src={thumbnailUrl} />
    </div>
  </div>
)

export default Header
