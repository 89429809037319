import React, { useState } from 'react'

import classNames from 'classnames'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import SetExpirationModal from 'components/pages/actions_dropdown/setExpirationModal'
import PageType from 'types/page'

const I18N = i18nPath('views.pages.expiration_text')

const PAGE_EXPIRATION_MODAL = 'page-expiration-modal'

interface Props {
  page: PageType
  isEditing?: boolean
}

const PageExpirationTextAndModal = ({
  page,
  isEditing,
} : Props) => {
  const [openModal, setOpenModal] = useState<null | string>(null)

  if (!page?.archivedAt) {
    return null
  }

  const archivedAt = i18nMoment(page.archivedAt)

  // show only if the archived date is in the future
  if (archivedAt.isBefore(i18nMoment())) {
    return null
  }

  const isExpiringSoon = archivedAt.isBefore(i18nMoment().add(1, 'month'))
  const canArchivePage = page?.permittedActions?.canArchivePage

  if (!canArchivePage && !isExpiringSoon) {
    return null
  }

  const onClick = () => {
    if (canArchivePage) {
      setOpenModal(PAGE_EXPIRATION_MODAL)
    }
  }

  return (
    <>
      <HoverAndClickPopover
        id={`page-expire-text-${page.id}`}
        target={(
          <span
            className={classNames('PageExpirationText text-small mr-2', {
              isExpiringSoon,
              isEditing,
            })}
          >
            {' • '}
            {I18N(isExpiringSoon ? 'expires' : 'expires_on')}
            {` ${isExpiringSoon ? archivedAt.fromNow() : archivedAt.format('ll')}`}
          </span>
        )}
        popoverClassName='IconButtonPopover p-2'
        onClick={onClick}
      >
        <div className='text-white'>
          {I18N('expires_on_description')}
        </div>
      </HoverAndClickPopover>
      {openModal === PAGE_EXPIRATION_MODAL && (
        <SetExpirationModal
          page={page}
          visible={openModal === PAGE_EXPIRATION_MODAL}
          toggle={() => setOpenModal(null)}
        />
      )}
    </>
  )
}

export default PageExpirationTextAndModal
