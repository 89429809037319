import React from 'react'

import { i18nMoment } from 'utils/i18nHelpers'
import CalendarIcon from 'components/icons/calendarIcon'
import SearchResultOption from './searchResultOption'

const QnaEventOption = ({ option }) => {
  const { qnaEvent, highlights } = option
  const { title, eventTime } = { ...qnaEvent, ...highlights }
  // if there is no description in highlights we can't use the one in qnaEvent because it's not a string
  const description = highlights?.description

  return (
    <SearchResultOption
      image={<CalendarIcon width={22} height={22} className='SearchResultOptionImg mr-2' fillColor='var(--text-color-secondary)' />}
      title={title}
      secondaryTexts={[i18nMoment(eventTime).format('ll h:mm a'), description]}
    />
  )
}

export default QnaEventOption
