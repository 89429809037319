import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import ToggleButtonGroup from './toggleGroup'

const I18N = i18nPath('views.qna.events.settings_modal.qna')

export const QUESTIONS_ANSWERABLE_BY_OPTIONS = [{ label: I18N('anyone'), value: 'anyone' }, { label: I18N('moderators'), value: 'moderators' }]

const QuestionsAnswerableBy = ({ event, setEvent }) => {
  const handleQuestionAnswerableBySettings = (value) => {
    if (value === 'moderators') {
      // if user changes questions to be answered by moderators only, also change to verified answers instead of accepted
      setEvent({ ...event, questionsAnswerableBy: value, settings: { ...event.settings, answerApproval: 'verified' } })
    } else {
      setEvent({ ...event, questionsAnswerableBy: value })
    }
  }

  return (
    <div className='QuestionsAnswerableBy'>
      <ToggleButtonGroup
        label={I18N('questions_answerable_by')}
        name='questions_answerable_by'
        currentValue={event.questionsAnswerableBy}
        onChange={handleQuestionAnswerableBySettings}
        options={QUESTIONS_ANSWERABLE_BY_OPTIONS}
      />
    </div>
  )
}

export default QuestionsAnswerableBy
