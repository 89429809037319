import AsyncSearchInput from 'components/common/asyncSearchInput'
import CdnSvg from 'components/common/cdnSvg'
import useFetch from 'components/common/hooks/useFetch'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import DatePicker from 'components/form_fields/datePicker'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import React, { useState } from 'react'
import API from 'services/api'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.impersonation_history')

const defaultPaginationParams = { sortBy: 'created_at', sortDir: 'desc', page: 1 }

const ImpersonationHistoryTable = () => {
  const [paginationParams, setPaginationParams] = useState(defaultPaginationParams)
  const [impersonations, setImpersonations] = useState<any[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null)
  const [userId, setUserId] = useState<string | null>(null)
  const [selectedStartDate, setSelectedStartDate] = useState<any>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<any>(null)

  const {
    isLoading, paginationData: { page, totalPages }, isNotFound,
  } = useFetch(() => API.admin.company.impersonationHistory.fetchAll({
    userId,
    startDate: selectedStartDate?.toISOString(),
    endDate: selectedEndDate?.toISOString(),
    ...paginationParams,
  }), [paginationParams, userId, selectedStartDate, selectedEndDate], {
    onSuccess: setImpersonations,
  })

  const columns = [
    {
      header: I18N('impersonated_user'),
      col: 'col-impersonated-user',
      accessor: d => d.user.preferredFullName,
    },
    {
      header: I18N('impersonator'),
      col: 'col-impersonator',
      accessor: d => d.impersonator.preferredFullName,
    },
    {
      header: I18N('logged_in_at'),
      col: 'col-logged-in-at',
      accessor: d => (d.usedAt ? i18nMoment(d.usedAt).format('LLL') : ''),
    },
  ]

  const onEmployeeChange = (employee: any) => {
    setSelectedEmployee(employee)
    setUserId(employee?.id)
  }

  return (
    <div className='ImpersonationHistoryTable'>
      <div className='d-flex justify-content-start mb-2'>
        <EmployeeSearch
          onChange={onEmployeeChange}
          selectedEmployee={selectedEmployee}
          className='w-50'
          includeHiddenUsers
          includeInactiveUsers
        />
        <label className='ml-2 mr-2 align-self-end'>{I18N('login_at_time_range')}:</label>
        <DatePicker
          className='DateTimePicker ArticleEditor-publishedAtPicker'
          selected={selectedStartDate && i18nMoment(selectedStartDate)}
          onChange={datetime => setSelectedStartDate(datetime)}
          showTimeSelect
          isClearable
          dateFormat='LLL'
          placeholderText={I18N('start_time')}
        />
        <DatePicker
          className='DateTimePicker ArticleEditor-publishedAtPicker'
          selected={selectedEndDate && i18nMoment(selectedEndDate)}
          onChange={datetime => setSelectedEndDate(datetime)}
          showTimeSelect
          isClearable
          dateFormat='LLL'
          placeholderText={I18N('end_time')}
        />
      </div>
      <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
        <div className='overflow-x-auto'>
          <SmartTable
            data={impersonations}
            sortKey='created_at'
            sortedAsc={false}
            showPagination={page !== null && totalPages !== null}
            columns={columns}
            page={page}
            pages={totalPages}
            onPaginationClick={setPaginationParams}
            onSortChange={setPaginationParams}
            className='white-bg-table'
          />
        </div>
      </TableLoadingContainer>
    </div>
  )
}

export default ImpersonationHistoryTable
