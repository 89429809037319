import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useMemo } from 'react'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'

const newWorkingCopy = ({
  authors, commentable, targetEntireCompany, restrictAccess,
}) => ({
  id: null,
  title: '',
  articleType: null,
  content: null,
  cardContent: null,
  draft: true,
  tags: [],
  authors,
  notificationChannels: [],
  notificationChannelOverrides: ['email'],
  notificationPreference: 'email_audience',
  alwaysShowInFeed: true,
  commentable,
  targetingRules: { ...TARGET_ENTIRE_COMPANY, targetEntireCompany },
  requireAcknowledgement: false,
  restrictAccess,
})

const useNewArticleTemplateWorkingCopy = () => {
  const currentUser = useCurrentUser()
  const { settings } = useCurrentCompany()

  const { enabled: commentsEnabled, default: commentsDefault } = settings.comments.articles

  const restrictAccess = settings.news.restrictArticlesByDefault

  const workingCopy = useMemo(() => newWorkingCopy({
    authors: [_.pick(currentUser, ['id', 'preferredFullName', 'primaryPhotoThumbnailUrl', 'title'])],
    commentable: commentsEnabled && commentsDefault,
    targetEntireCompany: restrictAccess ? false : currentUser.permissions.articleManager,
    restrictAccess,
  }), [])

  return workingCopy
}

export default useNewArticleTemplateWorkingCopy
