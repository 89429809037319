import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice from 'redux/slices/surveys/forms'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Card from 'components/common/card'
import CreateFormModal from 'components/admin/surveys/modals/createFormModal'
import { useHistory } from 'react-router-dom'
import CloseIcon from 'components/icons/closeIcon'
import ProgressiveImage from 'components/common/images/progressiveImage'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.surveys')

const plusIconPath = '/images/plusIcon.svg'

interface FormTemplateType {
  id: string
  name: string
}

const FormTemplate = ({
  template,
  onClick,
  isCreating,
  isDisabled,
}) => (
  <Card
    key={template.id}
    onClick={() => onClick(template)}
    isDisabled={isDisabled}
  >
    <div className='TemplateItem d-flex flex-column'>
      <ProgressiveImage className='w-100' src={template.imageUrl} />
      {isCreating ? (
        <CirclesLoadingIndicator />
      ) : (
        <div className='p-3'>
          <div className='text-primary font-weight-500'>{template.name}</div>
          <span className='text-secondary mb-0'>{template.description}</span>
        </div>
      )}
    </div>
  </Card>
)

const CreateFormPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isLoadingTemplates, isSaving } = useSelector(surveyFormSlice.selectors.getMetaData())

  const availableTemplates = useSelector(surveyFormSlice.selectors.getAvailableTemplates())

  const [selectedTemplate, setSelectedTemplate] = useState<FormTemplateType |null>(null)
  const [isCreateFormModalOpen, setIsCreateFormModalOpen] = useState(false)

  const onClose = () => {
    history.push('/admin/surveys')
  }

  useEffect(() => {
    dispatch(surveyFormSlice.asyncActions.admin.fetchAvailableTemplates())
  }, [])

  const onTemplateClick = (template) => {
    const onSuccess = (id: string) => {
      history.push(`/admin/surveys/${id}`)
    }

    setSelectedTemplate(template)
    dispatch(surveyFormSlice.asyncActions.admin.create({ templateId: template.id }, onSuccess))
  }

  if (isLoadingTemplates) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='CreateFormPage d-flex align-items-center flex-column justify-content-center w-100 p-5'>
      <header className='w-100 d-flex align-items-center justify-content-between'>
        <h1>{I18N('create_survey')}</h1>
        <div className='pointer' onClick={onClose}>
          <CloseIcon className='CloseSurveyFormTemplates' />
        </div>
      </header>
      <main className='AdminContent w-100'>
        <h5>{I18N('select_survey_template')}</h5>
        <div className='FormTemplateList'>
          {availableTemplates.map(template => (
            <FormTemplate
              key={template.id}
              template={template}
              onClick={onTemplateClick}
              isCreating={isSaving && selectedTemplate?.id === template.id}
              isDisabled={isSaving}
            />
          ))}
          <div
            className='TemplateItem CreateFromScratch d-flex flex-column align-items-center justify-content-center pointer'
            onClick={() => setIsCreateFormModalOpen(true)}
          >
            <div className='PlusIconCircleWrapper d-flex align-items-center justify-content-center'>
              <CdnSvg className='PlusIcon' src={plusIconPath} />
            </div>
            <div className='text-primary font-weight-500 mt-4'>{I18N('create_from_scratch')}</div>
          </div>
        </div>
      </main>
      <CreateFormModal
        visible={isCreateFormModalOpen}
        toggle={() => setIsCreateFormModalOpen(false)}
      />
    </div>
  )
}

export default CreateFormPage
