import React, {
  useEffect,
  useState,
  useRef,
  useMemo
} from 'react'
import { Redirect, useParams, Link } from 'react-router-dom'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

import buildComplexTree from 'utils/buildComplexTree'
import {
  InteractionMode,
  StaticTreeDataProvider,
  Tree,
  TreeEnvironmentRef,
  TreeItem,
  TreeItemIndex,
  UncontrolledTreeEnvironment
} from 'react-complex-tree'

import TabSelector from 'components/common/tabSelector'
import { TableLoadingContainer } from 'components/common/loadingContainer'

import { useDispatch, useSelector } from 'react-redux'
import groupTypeSlice from 'redux/slices/groupTypes'
import groupSlice from 'redux/slices/groups'
import { trackEvent } from 'services/tracker'
import { i18nPath } from 'utils/i18nHelpers'

const TOGGLE_STATE_EXPAND = 'expand_all'
const TOGGLE_STATE_COLLAPSE = 'collapse_all'

const I18N = i18nPath('views.groups')
const chevronDownIconPath = '/images/chevronDownOutline.svg'

const GroupsListPage = () => {
  const dispatch = useDispatch()
  const { groupTypeName } = useParams()
  const treeEnvRef = useRef(null)

  const { isLoading: isLoadingGroupTypes } = useSelector(groupTypeSlice.selectors.getMetaData())
  const { isLoadingAllTeams: isLoadingGroups } = useSelector(groupSlice.selectors.getMetaData())
  const isLoading = isLoadingGroupTypes || isLoadingGroups

  const groupTypes = useSelector(groupTypeSlice.selectors.getTeamTypes())

  // Stringify is necessary because useEffect will check if the reference changes
  // Reference is changed every render, so useEffect loops infinitely with arrays and objects
  const groupTypeIds = JSON.stringify(groupTypes.map(groupType => groupType.id))
  const [groupTypesTabNames, setGroupTypesTabNames] = useState([])
  const [isGroupTypeCollapsed, setIsGroupTypeCollapsed] = useState(true)
  const [toggleExpandAllState, setToggleExpandAllState] = useState(TOGGLE_STATE_EXPAND)

  const groups = useSelector(groupSlice.selectors.getTeams())
  const groupsTree = useMemo(() => (
    buildComplexTree(
      groups.map(group => ({ ...group, index: group.id, data: group.name })),
      false,
      'orderPosition'
    )
  ), [groups])

  const key = JSON.stringify(Object.keys(groupsTree))
  const viewState = isGroupTypeCollapsed ? {} : { [groupTypeName]: { expandedItems: Object.keys(groupsTree) } }

  useEffect(() => {
    dispatch(groupTypeSlice.asyncActions.fetchAll())
    trackEvent('group:view_all')

    return () => {
      dispatch(groupSlice.actions.clearTeamIds())
      dispatch(groupTypeSlice.actions.clearTeamTypeIds())
    }
  }, [])

  useEffect(() => {
    const groupTypesTabNames = groupTypes.map(groupType => ({
      linkName: groupType.name,
      linkPath: groupType.name.toLowerCase(),
    }))
    setGroupTypesTabNames(groupTypesTabNames)
  }, [groupTypeIds])

  useEffect(() => {
    const newGroupType = groupTypes.find(groupType => groupType.name.toLowerCase() === groupTypeName)
    if (newGroupType) {
      setIsGroupTypeCollapsed(newGroupType.collapsed)
      setToggleExpandAllState(newGroupType.collapsed ? TOGGLE_STATE_EXPAND : TOGGLE_STATE_COLLAPSE)
      dispatch(groupSlice.asyncActions.fetchAll({ groupTypeId: newGroupType.id }))
    }
  }, [groupTypeIds, groupTypeName])

  if (!groupTypeName && groupTypes.length > 0) {
    return <Redirect exact from='/people/teams/' to={`/people/teams/type/${groupTypes[0].name.toLowerCase()}`} />
  }

  const toggleExpandState = () => {
    if (toggleExpandAllState === TOGGLE_STATE_EXPAND) {
      treeEnvRef.current?.expandAll(groupTypeName)
      setToggleExpandAllState(TOGGLE_STATE_COLLAPSE)
    } else {
      treeEnvRef.current?.collapseAll(groupTypeName)
      setToggleExpandAllState(TOGGLE_STATE_EXPAND)
    }
  }

  return (
    <TableLoadingContainer isLoading={isLoading} useCirclesLoadingIndicator={true}>
      <div className='GroupsPage container my-4'>
        <TabSelector baseUrl='/people/teams/type/' tabs={groupTypesTabNames}>
          <div className='GroupsTree'>
            <span className='pointer text-small text-secondary mb-3' onClick={toggleExpandState}>
              {I18N(toggleExpandAllState)}
            </span>
            <UncontrolledTreeEnvironment
              key={key}
              dataProvider={new StaticTreeDataProvider(groupsTree)}
              getItemTitle={item => item.data}
              viewState={viewState}
              ref={treeEnvRef}
              renderItemArrow={({ item, context }) => {
                if (item.isFolder) {
                  return (
                    <span className={classNames('ItemArrow', { isExpanded: context.isExpanded })} {...context.arrowProps}>
                      <CdnSvg src={chevronDownIconPath} className='ChevronDownIcon' cacheGetRequests />
                    </span>
                  )
                }
                return <span className='ItemArrowPlaceholder' />
              }}
              renderItemTitle={({ item, title }) => <Link to={item.displayPath}>{title}</Link>}
              renderItem={({ children, title, arrow }) => (
                <li>
                  <div className='d-flex'>
                    <span className='mr-1'>{arrow}</span>
                    {title}
                  </div>
                  {children}
                </li>
              )}
            >
              <Tree
                treeId={groupTypeName}
                rootItem='root'
              />
            </UncontrolledTreeEnvironment>
          </div>
        </TabSelector>
      </div>
    </TableLoadingContainer>
  )
}

export default GroupsListPage
