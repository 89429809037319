import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'components/common/buttons'
import { isOptionsQuestion } from 'utils/surveys/isValidQuestion'
import { QuestionTypeEnum } from 'types/surveys/question'
import RichTextView from 'components/common/richTextView'

const MultipleChoiceAnswerPill = ({ selectedOption }) => {
  const history = useHistory()
  const value = selectedOption?.value

  const updateFilter = () => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('selectedValue', selectedOption?.id)
    history.push({
      search: queryParams.toString(),
    })
  }

  return (
    <Button
      className='MultipleChoiceAnswerPill mr-2 mb-2 font-weight-400'
      variant='tag'
      onClick={updateFilter}
    >
      {value}
    </Button>
  )
}

const AnswerValue = ({ answer, question }) => {
  const isMultipleChoice = isOptionsQuestion(question)
  const isHtml = question?.type === QuestionTypeEnum.Html

  if (isHtml) {
    return (
      <RichTextView
        record={answer}
        recordType='answer'
        richTextKey='string'
      />
    )
  }

  if (!isMultipleChoice) {
    return <span className='font-weight-400 ws-pre-wrap'>{answer?.formattedValue}</span>
  }

  const optionSelections = answer?.optionSelections || []
  const questionOptions = question?.options || []

  const selectedIds = optionSelections.map(s => s.questionOptionId)
  const selectedOptions = questionOptions.filter(option => selectedIds.includes(option.id))

  return (
    <div className='AnswerValue d-flex flex-wrap'>
      {selectedOptions.map(selectedOption => (
        <MultipleChoiceAnswerPill
          key={selectedOption.id}
          selectedOption={selectedOption}
        />
      ))}
    </div>
  )
}

export default AnswerValue
