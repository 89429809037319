import React from 'react'

import GearIcon from 'components/icons/gearIcon'
import { I18NCommon } from 'utils/i18nHelpers'


const SettingsLinkButton = ({ onClick }) => (
  <div className='Toolbar-link d-flex align-items-center pointer' onClick={onClick}>
    <GearIcon className='mr-2' fillColor='var(--link-color)' />
    <span className='link-color font-weight-600'>
      {I18NCommon('settings')}
    </span>
  </div>
)

export default SettingsLinkButton
