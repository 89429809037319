import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import itAdminSlice from 'redux/slices/itAdmin'
import FormEditableField from 'components/form_fields/formEditableField'

const I18N = i18nPath('views.integrations_page.integration_card.workday')

interface WorkdayInstallationComponentInterface {
  isOtherInstalled: boolean
}

interface FormState {
  usersUrl: string
  username: string
  password: string
  basePhotoUrl?: string
}

const WorkdayInstallationComponent = ({ isOtherInstalled }: WorkdayInstallationComponentInterface) => {
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)

  const [form, setForm] = useState<FormState>({
    usersUrl: '',
    username: '',
    password: '',
    basePhotoUrl: '',
  })

  const connect = (fieldName: string) => ({
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm(oldValues => ({
        ...oldValues,
        [fieldName]: e.target.value,
      }))
    },
    currentValue: form[fieldName],
  })

  const { isSaving } = useSelector(itAdminSlice.selectors.getMetaData())

  const installWorkday = () => {
    dispatch(itAdminSlice.asyncActions.admin.installWorkday(form))
  }

  const isDisabled = isSaving
    || _.isEmpty(form.usersUrl)
    || _.isEmpty(form.username)
    || _.isEmpty(form.password)

  if (isOtherInstalled) {
    return (
      <div className='text-secondary text-small'>
        {I18n.t('views.integrations_page.integration_card.only_one_hris_integration')}
      </div>
    )
  }

  if (!showForm) {
    return (
      <div className='mb-2'>
        <ButtonSmallNarrow
          onClick={() => setShowForm(true)}
        >
          {I18N('configure')}
        </ButtonSmallNarrow>
      </div>
    )
  }

  return (
    <>
      <div className='mb-2'>
        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          fieldName='usersUrl'
          label={I18N('users_url')}
          {...connect('usersUrl')}
          isRequired
        />

        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          {...connect('username')}
          fieldName='username'
          label={I18N('username')}
          isRequired
        />

        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          {...connect('password')}
          fieldName='password'
          label={I18N('password')}
          isRequired
        />

        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          {...connect('basePhotoUrl')}
          fieldName='basePhotoUrl'
          label={I18N('photos_url')}
        />

        <ButtonSmallNarrow
          onClick={installWorkday}
          disabled={isDisabled}
          showLoadingSpinner={isSaving}
          id='save-workday-btn'
        >
          {I18n.t('views.integrations_page.integration_card.save')}
        </ButtonSmallNarrow>
      </div>
    </>
  )
}

export default WorkdayInstallationComponent
