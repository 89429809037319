import React, { ReactElement } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import PasswordLoginForm from 'components/authentication/passwordLoginForm'

const I18N = i18nPath('views.authentication.login_form')

const containerStyle = { height: '70vh' }
const cardStyle = { width: '320px' }

const PasswordLoginPage = (): ReactElement => (
  <div className='container d-flex justify-content-center align-items-center' style={containerStyle}>
    <div className='row'>
      <div className='card m-4 p-4' style={cardStyle}>
        <h1 className='d-flex justify-content-center'>{I18N('login_header')}</h1>

        <PasswordLoginForm />
      </div>
    </div>
  </div>
)

export default PasswordLoginPage
