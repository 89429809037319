import React from 'react'

import classNames from 'classnames'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'

interface FormTiptapEditorFieldProps {
  fieldName: string
  label?: string
  isRequired?: boolean
  footNote?: string
  currentValue?: string
  onChange?: (value: string) => void
  className?: string
  editorClassName?: string
  toolbarItems?: any[]
  editorConfiguration?: any
}

const FormTiptapEditorField = ({
  fieldName,
  label,
  isRequired = false,
  footNote,
  currentValue,
  onChange = (value) => { },
  className = '',
  editorClassName = '',
  toolbarItems = SMALL_TOOLBAR,
  editorConfiguration = {},
}: FormTiptapEditorFieldProps) => (
  <LabeledFormFieldContainer
    isRequired={isRequired}
    footNote={footNote}
    className={classNames('FormTiptapEditorField', className)}
    label={label || fieldName}
    hasTiptapEditor
  >
    <TiptapEditor
      className={classNames('RoundedTiptapEditor m-0 w-100', editorClassName)}
      toolbarItems={toolbarItems}
      onChange={onChange}
      html={currentValue}
      configuration={editorConfiguration}
    />
  </LabeledFormFieldContainer>
)

export default FormTiptapEditorField
