import React, { ChangeEvent, ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import customStylesSlice from 'redux/slices/customStyles'
import { useCustomStyles } from 'pages/admin/custom_styles/context'

const I18N = i18nPath('views.custom_styles')

const FaviconUrlField = (): ReactElement => {
  const dispatch = useDispatch()
  const { workingCopy, workingCopy: { faviconUrl } } = useCustomStyles()

  const handleTextChange = (event: ChangeEvent<{ value: string, name: string }>) => {
    const { value, name } = event.target
    const newWorkingCopy = {
      ...workingCopy,
      [name]: value,
    }

    dispatch(customStylesSlice.actions.updateWorkingCopy(newWorkingCopy))
  }

  return (
    <>
      <div className='row my-3'>
        <div className='col-md-12'>
          <h3>{I18N('favicon_url_label')}</h3>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='Styles--FaviconUrl'>
            <div className='FaviconUrl'>
              <input
                className='form-control'
                name='faviconUrl'
                value={faviconUrl}
                placeholder={I18N('favicon_url_placeholder')}
                pattern='^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
                onChange={ev => handleTextChange(ev)}
              />
              <small className='form-text text-secondary mb-4'>{I18N('favicon_helper_text')}</small>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaviconUrlField
