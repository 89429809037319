import useApi from 'components/common/hooks/useApi'
import PromptCollectionForm from 'pages/admin/cleary_ai_settings/promptCollectionPage/promptCollectionForm'
import React from 'react'
import { useHistory } from 'react-router-dom'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.cleary_ai_settings.prompt_collection_page')


const CreatePromptCollectionPage = () => {
  const history = useHistory()

  const [createPromptCollection, { isLoading: isSaving }] = useApi(
    API.admin.ai.chat.promptCollections.create,
    {
      toastSuccessMessage: I18N('prompt_collection_created'),
      toastErrorMessage: I18NCommon('generic_error'),
      onSuccess: (data) => {
        history.push(`/admin/cleary_ai_settings/ask_ai/prompt_collections/${data.id}`)
      },
    }
  )

  return (
    <div className='CreatePromptCollectionPage'>
      <header className='AdminHeader'>
        <h3>{I18N('create_prompt_collection')}</h3>
      </header>

      <PromptCollectionForm onSubmit={createPromptCollection} isSaving={isSaving} />
    </div>
  )
}

export default CreatePromptCollectionPage
