import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import pageSlice from 'redux/slices/pages'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import useWindowResize from 'components/common/hooks/useWindowResize'

const useWorkspaceUrls = () => {
  const { workspaceSlug } = useParams()
  const history = useHistory()
  const { isSmallScreen } = useWindowResize()
  let { pages: allPages } = useSelector(pageSlice.selectors.getAllPages())
  const workspaces = useSelector(pageWorkspaceSlice.selectors.getPageWorkspaces())

  const isWorkspaceFocused = !!workspaceSlug
  // We won't show drafts on small screens
  allPages = isSmallScreen ? allPages.filter(page => !page.draft) : allPages
  const currentWorkspace = workspaceSlug ? workspaces.find(workspace => workspace.slug === workspaceSlug) : null
  const showPagesOnlyFromWorkspace = isWorkspaceFocused && !!currentWorkspace
  const workspacePages = allPages.filter(page => page.pageWorkspaceId === currentWorkspace?.id)
  const filteredPages = showPagesOnlyFromWorkspace ? workspacePages : allPages

  const redirectToFirstPage = () => {
    let rootPages = filteredPages.filter(page => page.parentId === null)
    let firstPage = null

    if (_.isEmpty(workspaces) || _.isEmpty(rootPages)) {
      return
    }

    if (workspaceSlug) {
      // If we do have a focused workspace, we get the first page from that workspace
      rootPages = rootPages.filter(page => page.pageWorkspaceId === currentWorkspace?.id)

      if (rootPages.length > 0) {
        rootPages = _.orderBy(rootPages, ['ancestryOrderPosition'], ['asc'])
        firstPage = rootPages[0]
      }
    } else {
      // If we are in the L1 level of the page tree, we get the first page from the first workspace
      rootPages = _.orderBy(rootPages, [
        (page) => {
          const workspace = workspaces.find(workspace => workspace.id === page.pageWorkspaceId)
          return workspace ? workspace.orderPosition : Number.MAX_SAFE_INTEGER
        },
        'ancestryOrderPosition',
      ], ['asc', 'asc'])

      firstPage = rootPages[0]
    }

    if (firstPage) {
      history.replace(generatePathToPage(firstPage))
    }
  }

  // action can be 'view', 'edit' or 'history'
  const generatePathToPage = (page, action = 'view') => {
    if (!page) {
      return '/pages/'
    }

    let path = isWorkspaceFocused ? '/workspaces/' + workspaceSlug : '/pages'

    if (action === 'view') {
      path += '/' + page.slug
    } else {
      path += '/' + page.id + '/' + action
    }

    return path
  }

  const generateAllPagePaths = page => [
    generatePathToPage(page),
    generatePathToPage(page, 'edit'),
    generatePathToPage(page, 'history'),
  ]

  return {
    generatePathToPage,
    generateAllPagePaths,
    redirectToFirstPage,
    filteredPages,
    allPages,
    workspacePages,
    workspaces,
    currentWorkspace,
  }
}

export default useWorkspaceUrls
