import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ToggleLeftNavButton from 'components/common/toggleLeftNavButton'
import PagesNav from 'components/pages/pagesNav'
import EditTemplateContent from 'components/pages/editTemplateContent'
import pageTemplateSlice from 'redux/slices/pageTemplates'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import navbarHeight from 'components/navbar/utils/navbarHeight'
import useHideSlackWidget from 'components/common/hooks/useHideSlackWidget'


const EditPageTemplatePage = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [isDesktopNavOpen, setIsDesktopNavOpen] = useState(false)
  useHideSlackWidget()

  const { pageTemplateId, templateCategoryId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pageTemplateSlice.asyncActions.fetch({ id: pageTemplateId, templateCategoryId }))
  }, [])

  const pageTemplate = useSelector(pageTemplateSlice.selectors.getPageTemplate(pageTemplateId))

  const [workingCopy, setWorkingCopy] = useState(pageTemplate)

  const { isLoadingTemplate: isLoading, isSaving } = useSelector(pageTemplateSlice.selectors.getMetaData())

  useEffect(() => {
    const headerEl = document.querySelector('header.Navbar') as HTMLElement

    setIsDesktopNavOpen(false)
    headerEl!.style.setProperty('position', 'absolute')
    headerEl!.style.setProperty('z-index', '-1')

    return () => {
      headerEl!.style.removeProperty('position')
      headerEl!.style.removeProperty('z-index')
    }
  }, [])

  if (isLoading) return <CirclesLoadingIndicator />

  return (
    <div
      className='container-fluid position-relative w-100'
      style={{ height: `calc(100vh - ${navbarHeight()}px)` }}
    >
      <HelmetProvider>
        <Helmet>
          <title>{pageTemplate?.title}</title>
        </Helmet>
      </HelmetProvider>
      <ToggleLeftNavButton
        onClick={() => setIsDesktopNavOpen(!isDesktopNavOpen)}
        isOpen={isDesktopNavOpen}
        className='mt-5'
      />

      <div className='row flex-nowrap h-100'>
        <PagesNav
          isMobileNavOpen={isMobileNavOpen}
          isDesktopNavOpen={isDesktopNavOpen}
        />
        <EditTemplateContent
          isDesktopNavOpen={isDesktopNavOpen}
          onClickOpenMobileNav={() => setIsMobileNavOpen(true)}
          pageTemplate={pageTemplate}
          isSaving={isSaving}
          workingCopy={workingCopy}
          setWorkingCopy={setWorkingCopy}
        />
      </div>
    </div>
  )
}

export default EditPageTemplatePage
