import React, { useState } from 'react'
import WidgetsCategory from 'components/admin/layout/widgetsCategory'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'
import widgetsMap from 'utils/layout/widgetsMap'
import useAvailableWidgets from 'components/admin/layout/hooks/useAvailableWidgets'

const I18N = i18nPath('views.admin.home_editor.sidebar')

const AddWidgetSidebar = ({ setSelectedNewWidget }) => {
  const [query, setQuery] = useState('')

  const availableWidgets = useAvailableWidgets()
  const widgets = Object.values(widgetsMap)
  const filteredWidgets = widgets.filter(widget => (
    availableWidgets.includes(widget.type) && widget.label.toLowerCase().includes(query.toLowerCase())
  ))
  const groupedWidgets = _.groupBy(filteredWidgets, 'category')

  return (
    <div className='AddWidgetSidebar'>
      <h5>{I18N('widgets')}</h5>
      <div className='text-secondary text-small mb-3'>
        {I18N('instructions')}
      </div>

      <AsyncSearchInput
        placeholder={I18N('search')}
        className='text-small w-100'
        onKeyUp={setQuery}
        icon={<CdnSvg src='/images/searchIcon.svg' />}
        minCharsToSearch={1}
        inputGroupClassName='flex-grow-1 mr-2'
      />

      <div className='mt-4'>
        {Object.entries(groupedWidgets).map(([category, widgets]) => (
          <WidgetsCategory
            key={category}
            label={category}
            widgets={widgets}
            setSelectedNewWidget={setSelectedNewWidget}
          />
        ))}
      </div>
    </div>
  )
}


export default AddWidgetSidebar
