import { useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'

import useApi from 'components/common/hooks/useApi'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import API from 'services/api'
import { buildCelebrationPayload } from 'services/api/celebrations'
import isEqualWithSets from 'utils/isEqualWithSets'
import celebrationSlice from 'redux/slices/celebrations'
import navigationBlockerSlice from 'redux/slices/navigationBlocker'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useFetch from 'components/common/hooks/useFetch'
import generateWorkingCopyFromTemplate from 'pages/people/celebration_editor_page/generateWorkingCopyFromTemplate'
import { showToastMessage } from 'redux/slices/toasts'

const I18N = i18nPath('views.celebrations.greeting_card.editor')

const DEFAULT_EFFECT = {
  birthday: 'ballon',
  work_anniversary: 'confetti',
  new_employee_welcome: 'confetti',
  thank_you: 'thank_you',
  farewell: 'thank_you',
  achievement: 'trophy',
  new_parent: 'ballon',
  get_well_soon: 'flowers',
}

export const useCelebrationForm = ({ celebration, templates, isNew }) => {
  const [workingCopy, setWorkingCopy] = useState<any>(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const company = useCurrentCompany()

  const [updateCelebration] = useApi(
    API.celebrations.update, {
      updateEntitySlice: true,
    }
  )

  const [createCelebration, { isLoading: isCreating }] = useApi(
    API.celebrations.create, {
      updateEntitySlice: true,
      toastSuccessMessage: I18N('card_created_successfully'),
      onSuccess: (celebration) => {
        dispatch(navigationBlockerSlice.asyncActions.performWithoutBlocking(() => {
          history.push(`/people/celebrations/${celebration.id}/edit`)
        }))
      },
      onError: (error) => {
        let message

        // error example:
        //   {
        //     "error": {
        //         "message": "Validation failed: Event date Cards can only be created for events up to 60 days in the future",
        //         "type": "record_invalid",
        //         "status": "unprocessable_entity",
        //         "errors": {
        //             "event_date": [
        //                 "Cards can only be created for events up to 60 days in the future"
        //             ]
        //         },
        //         "validation_messages": [
        //             "Event date Cards can only be created for events up to 60 days in the future"
        //         ]
        //     }
        // }

        if (error?.errors?.eventDate) {
          // in order to not show "Event date" in the message
          message = error.errors.eventDate[0]
        } else if (error?.validationMessages) {
          message = error.validationMessages.join('. ')
        } else {
          message = I18N('create_card_error_message')
        }
        dispatch(showToastMessage({ message, type: 'error' }))
      },
    }
  )

  const effectDeps = [
    'coverImageUrl', 'customTitle', 'effect', 'coverImageCropppedArea',
    'eventDate', 'userId', 'type',
  ].map(key => (celebration || {})[key])

  useEffect(() => {
    if (!isNew || !workingCopy?.type) return

    setWorkingCopy(currentWorkingCopy => ({
      ...currentWorkingCopy,
      effect: DEFAULT_EFFECT[workingCopy.type] || null,
    }))
  }, [workingCopy?.type])

  useEffect(() => {
    if (!celebration) return

    setWorkingCopy({
      ...celebration,
      // if we change the coverImageUrl from the server, it'll reload the image
      // giving a blink effect. So let's reuse the current working copy coverImageUrl
      coverImageUrl: workingCopy?.coverImageUrl || celebration.coverImageUrl,
    })
  }, effectDeps)

  const areChangesPresent = useMemo(() => !isEqualWithSets(
    buildCelebrationPayload(celebration),
    buildCelebrationPayload(workingCopy)
  ), [workingCopy, celebration])

  const debouncedWorkingCopy = useDebounce(workingCopy, 2000)

  useEffect(() => {
    if (!debouncedWorkingCopy || !areChangesPresent || !celebration.id) return

    updateCelebration(buildCelebrationPayload(debouncedWorkingCopy))
  }, [debouncedWorkingCopy])

  const onPeopleInvited = (users) => {
    setWorkingCopy(currentWorkingCopy => ({
      ...currentWorkingCopy,
      invitees: _.uniqBy([
        ...currentWorkingCopy.invitees, ...users,
      ], 'id'),
    }))
  }

  const create = () => {
    let workingCopyToSave = workingCopy

    const template = templates.find(t => t.templateType === workingCopy.type)
    if (template) {
      const workingCopyWithTemplate = generateWorkingCopyFromTemplate(template, company, currentUser, workingCopy)
      workingCopyToSave = workingCopyWithTemplate
    }

    createCelebration(buildCelebrationPayload(workingCopyToSave))
  }

  const isValid = useMemo(() => {
    if (!workingCopy) return false

    const requiredFields = ['user', 'eventDate', 'type']

    if (requiredFields.some(field => !workingCopy[field])) return false

    // we cant create events for the same user
    return workingCopy.user?.id !== currentUser.id
  }, [workingCopy])

  return {
    workingCopy,
    setWorkingCopy,
    isCreating,
    create,
    areChangesPresent,
    isValid,
    onPeopleInvited,
  }
}

export const defaultWorkingCopy = {
  coverImageUrl: '/images/celebrations/custom_card_background.jpg',
  originalCoverImageUrl: '/images/celebrations/custom_card_background.jpg',
  customTitle: `<div><h2><span style="color: #ffffff;">${I18N('default_title')}</span></h2><p><span style="color: #ffffff;">${I18N('default_description')}</span></p></div>`,
  customCelebration: true,
  useDefaultImages: true,
}

export const useLoadCelebration = () => {
  const { celebrationId } = useParams()
  const { type } = useQueryParams()

  const newWorkingCopy = useMemo(() => ({
    ...defaultWorkingCopy,
    type,
  }), [type])

  const [fetchCelebration, { isLoading, isNotFound }] = useApi(API.celebrations.fetch, {
    updateEntitySlice: true,
  })

  const isNew = !celebrationId

  useEffect(() => {
    if (!isNew) {
      fetchCelebration(celebrationId)
    }
  }, [])

  // The idea behind using celebration from redux is that
  // there are other useApi hooks or redux actions that might update the celebration
  // in redux. They just need to use the `updateEntitySlice` option
  // check useCelebrationForm and CelebratedCard
  const celebration = useSelector((state) => {
    if (isNew) return newWorkingCopy

    return celebrationSlice.selectors.getCelebration(celebrationId)(state)
  })

  const manualCelebrationsEnabled = useCurrentCompany().settings.celebrations.manualCelebrations.enabled

  const showLoadingIndicator = manualCelebrationsEnabled && (isLoading || !celebration)

  const { data: templates = [], isLoading: isLoadingTemplates } = useFetch(API.celebrations.templates.fetch)

  const showNotFound = () => {
    if (!manualCelebrationsEnabled) return true

    if (isNew) return false

    return isNotFound
  }

  return {
    showLoadingIndicator,
    showNotFound: showNotFound(),
    celebration,
    isNew,
    templates,
    isLoadingTemplates,
  }
}


export const useDeleteCelebration = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [deleteCelebration, { isLoading: isDeleting }] = useApi(API.celebrations.destroy, {
    toastSuccessMessage: I18N('delete_celebration_success_message'),
    toastErrorMessage: I18N('delete_celebration_error_message'),
    onSuccess: () => {
      dispatch(navigationBlockerSlice.asyncActions.performWithoutBlocking(() => {
        history.push('/people/celebrations?selectedTab=cards_to_sign')
      }))
    },
  })

  return {
    deleteCelebration,
    isDeleting,
  }
}
