import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import PageEditorNavbar from 'components/pages/editor/pageEditorNavbar'
import pageSlice from 'redux/slices/pages'
import PageContentEditor from 'components/pages/editor/pageContentEditor'
import useHideNavbar from 'components/navbar/hooks/useHideNavbar'
import PageFeedbackNav from 'components/pages/feedback/pageFeedbackNav'
import TemplatesNavContainer from 'components/pages/templatesNavContainer'
import NavigationBlocker from 'components/common/navigationBlocker'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'

export const FEEDBACK_NAV = 'feedbackNav'
export const TEMPLATES_NAV = 'templatesNav'

const PageEditorPage = () => {
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const page = useSelector(pageSlice.selectors.getPage(pageId))
  const { isLoading, isNotFound } = useSelector(pageSlice.selectors.getPagesMetadata())
  const isLoaded = useIsLoaded(isLoading) && !_.isEmpty(page) && !_.isEmpty(page.permittedActions)

  const canEditPage = page?.permittedActions?.edit

  const [currentRightNav, setCurrentRightNav] = useState('')
  const closeRightNav = () => setCurrentRightNav('')

  const areChangesPresent = useSelector(collaborativeEditorSlice.selectors.getAreChangesPresent())
  const usersCurrentlyEditing = useSelector(collaborativeEditorSlice.selectors.getUsersCurrentlyEditing())
  const shouldBlockNavigation = areChangesPresent && usersCurrentlyEditing.length === 1

  useEffect(() => {
    dispatch(pageSlice.asyncActions.fetch(pageId))
  }, [pageId])

  useHideNavbar()

  if (isNotFound) return <Redirect to='/pages' />
  if (isLoaded && !canEditPage) return <Redirect to={page.displayPath} />
  if (page?.archived) return <Redirect to={page.displayPath} />

  return (
    <>
      {isLoaded ? (
        <>
          <PageEditorNavbar page={page} setCurrentRightNav={setCurrentRightNav} />
          <NavigationBlocker isBlocked={shouldBlockNavigation} />
          <div className='d-flex h-100'>
            <PageContentEditor page={page} />
            {currentRightNav === FEEDBACK_NAV && (
              <PageFeedbackNav page={page} onClose={closeRightNav} />
            )}
            {currentRightNav === TEMPLATES_NAV && (
              <TemplatesNavContainer page={page} onClose={closeRightNav} />
            )}
          </div>
        </>
      ) : (
        <CirclesLoadingIndicator />
      )}
    </>
  )
}

export default PageEditorPage
