import React from 'react'
import { Link } from 'react-router-dom'

import Card from 'components/common/card'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.shoutouts')

const SendShoutoutButton = ({ onClick }) => (
  <HoverAndClickPopover
    target={(
      <div className='SendShoutoutButton' onClick={onClick}>
        <CdnSvg src='/images/megaphoneIcon.svg' />
      </div>
    )}
    popoverClassName='SendShoutoutButtonPopover'
    placement='bottom'
  >
    <div className='p-2 text-center'>
      <span className='text-small text-white font-weight-400'>{I18N('send_a_shoutout')}</span>
    </div>
  </HoverAndClickPopover>
)

const ShoutoutSuggestionListItem = ({ shoutoutSuggestion, width, setSelectedSuggestion }) => {
  const { suggestedUser } = shoutoutSuggestion
  const employeeThumbnailSize = width > 3 ? '60px' : '40px'

  return (
    <Card className='ShoutoutSuggestionListItem'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <EmployeeThumbnailPhoto
            employee={suggestedUser}
            size={employeeThumbnailSize}
            linkToProfile
          />

          <div className='ml-3'>
            <Link className='text-primary-link' to={`/profile/${suggestedUser.username}`}>{suggestedUser.preferredFullName}</Link>
            <div className='text-secondary text-smallest'>{suggestedUser.title}</div>
          </div>
        </div>

        <SendShoutoutButton onClick={() => setSelectedSuggestion(shoutoutSuggestion)} />
      </div>
    </Card>
  )
}

const ShoutoutsVerticalLayout = ({
  shoutoutSuggestions,
  setSelectedSuggestion,
  widget,
  resizeWidget,
  isLoaded,
}) => (
  <AutoResizableScrollableSection
    widget={widget}
    resizeWidget={resizeWidget}
    isLoaded={isLoaded}
    className='border-radius-bottom'
    scrollableContentClassName='ShoutoutVerticalLayout p-4 d-flex flex-column gap-3'
  >
    {shoutoutSuggestions.map(shoutoutSuggestion => (
      <ShoutoutSuggestionListItem
        key={`ShoutoutSuggestion-${shoutoutSuggestion.suggestedUser.id}`}
        shoutoutSuggestion={shoutoutSuggestion}
        width={widget.w}
        setSelectedSuggestion={setSelectedSuggestion}
      />
    ))}
  </AutoResizableScrollableSection>
)

export default ShoutoutsVerticalLayout
