import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import AllStepsTable from 'components/admin/journeys/employeesOnJourneys/allStepsTable'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import ReactSelect from 'components/common/react_select'
import { ONBOARDING, OFFBOARDING, CUSTOM } from 'utils/journeys/journeyBlueprintTypes'
import { Button } from 'components/common/buttons'
import SelectJourneyModal from 'components/admin/journeys/employeesOnJourneys/selectJourneyModal'

const I18N = i18nPath('views.admin.journeys.new_hire_detail.steps')

const ALL = 'all'
const STEP_TYPES = ['communications', 'tasks', 'calendarEvents', 'introductions', 'meetings', 'surveys']

const EmployeeStepsTable = ({
  journeys,
  onClickAssignJourney,
}) => {
  const { userId, selectedTab } = useParams()
  const currentCompany = useCurrentCompany()
  const [selectedStepTypeFilter, setSelectedStepTypeFilter] = useState(ALL)
  const [selectedJourneyFilter, setSelectedJourneyFilter] = useState(ALL)
  const [showSelectJourneyModal, setShowSelectJourneyModal] = useState(false)

  const settings = currentCompany?.settings
  const stepTypeSettings = settings?.journeys?.stepTypes
  const enabledStepTypes = STEP_TYPES.filter(stepType => stepTypeSettings?.[stepType]?.enabled)
  const stepTypeOptions = [ALL, ...enabledStepTypes].map(option => ({ value: option, label: I18N(`step_types.${option}`) }))
  // eslint-disable-next-line max-len
  const journeyOptionMapping = journeys.filter(journey => journey?.journeyBlueprint?.type === selectedTab).map(journey => ({ value: journey.id, label: journey?.journeyBlueprint?.name }))
  const journeyOptions = [{ value: ALL, label: I18N('all') }, ...journeyOptionMapping]

  return (
    <div className='EmployeeStepsTable w-100'>
      <div className='d-flex flex-column'>
        <HistoryChangingPillTabSelector
          baseUrl={`/admin/users/${userId}/journeys`}
          historyKeyToChange='pathname'
          tabs={[
            { id: ONBOARDING, text: I18N(`tabs.${ONBOARDING}`) },
            { id: OFFBOARDING, text: I18N(`tabs.${OFFBOARDING}`), hidden: !settings.journeys?.offboarding?.enabled },
            { id: CUSTOM, text: I18N(`tabs.${CUSTOM}`), hidden: !settings.journeys?.customJourneys?.enabled },
          ]}
          pillClasses='text-normal'
        />
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <div className='d-flex align-items-center'>
            <p className='text-secondary mb-0 mr-2'>{I18N('step_type')}</p>
            <ReactSelect
              className='step-type-select'
              options={stepTypeOptions}
              onChange={option => setSelectedStepTypeFilter(option.value)}
              value={stepTypeOptions.filter(o => o.value === selectedStepTypeFilter)}
            />
            <p className='text-secondary mb-0 mr-2 ml-2'>{I18N('journey')}</p>
            <ReactSelect
              className='journey-select'
              options={journeyOptions}
              onChange={option => setSelectedJourneyFilter(option.value)}
              value={journeyOptions.filter(o => o.value === selectedJourneyFilter)}
            />
          </div>
          <Button onClick={() => setShowSelectJourneyModal(true)}>{I18N('add_custom_step')}</Button>
        </div>
      </div>
      <AllStepsTable
        selectedJourneyFilter={selectedJourneyFilter}
        selectedStepTypeFilter={selectedStepTypeFilter}
        journeys={journeys}
        onClickAssignJourney={onClickAssignJourney}
        setSelectedStepTypeFilter={setSelectedStepTypeFilter}
        setSelectedJourneyFilter={setSelectedJourneyFilter}
      />

      {showSelectJourneyModal && (
        <SelectJourneyModal
          visible={showSelectJourneyModal}
          closeModal={() => setShowSelectJourneyModal(false)}
          journeys={journeys}
        />
      )}
    </div>
  )
}

export default EmployeeStepsTable
