import React from 'react'

import { i18nMoment } from 'utils/i18nHelpers'

const DateCell = ({ date }) => {
  if (!date) return null

  const momentDate = i18nMoment(date)

  return (
    <div className='DateCell'>
      <div className='text-small'>{momentDate.format('ll')}</div>
      <div className='text-small'>{momentDate.format('LT')}</div>
    </div>
  )
}

export default DateCell
