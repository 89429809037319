import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import EventCoverImage from 'components/qna/event_fields/eventCoverImage'
import Card from 'components/common/card'
import qnaEventSlice from 'redux/slices/qnaEvents'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import EventParticipants from 'components/qna/eventParticipants'
import AccessLevel from 'components/qna/access_level'
import { TopQuestionContent, TopQuestionLink } from 'components/qna/topQuestionMiniCard'
import AnswersCount from 'components/qna/answersCount'
import commentsCountText from 'components/comments/commentsCount'
import CdnSvg from 'components/common/cdnSvg'
import QuestionVotingSection from 'components/qna/questionVotingSection'
import { ButtonSecondarySmall } from 'components/common/buttons'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'

const I18N = i18nPath('views.home')

const FeaturedEventWidget = ({ widget, resizeWidget }) => {
  const dispatch = useDispatch()

  const { highlightedEvent = {}, topQuestion, meta } = useSelector(qnaEventSlice.selectors.getEventListData())
  const { isHighlightedEventLoading } = meta
  const isLoaded = useIsLoaded(isHighlightedEventLoading)


  useEffect(() => {
    dispatch(qnaEventSlice.asyncActions.fetchHighlightedEvent())
  }, [])

  return (
    <Card className='FeaturedEventWidget HomePageWidget'>
      <WidgetTitle title={I18N('featured_event')} />

      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        isLoaded={isLoaded}
        showScrollbarOutside
      >
        <FeaturedEventWidgetBody
          isLoading={isHighlightedEventLoading}
          highlightedEvent={highlightedEvent}
          topQuestion={topQuestion}
        />
      </AutoResizableScrollableSection>

      {!_.isEmpty(highlightedEvent) && (
        <div className='mt-2'>
          <TopQuestionLink question={topQuestion} event={highlightedEvent} />
        </div>
      )}
    </Card>
  )
}

const FeaturedEventWidgetBody = ({ isLoading, highlightedEvent, topQuestion }) => {
  const { permissions } = useCurrentUser()
  const isEventCreator = permissions.eventCreator

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (_.isEmpty(highlightedEvent)) {
    return (
      <div className='w-100 d-flex flex-column align-items-center'>
        <CdnSvg className='PeopleWithGiantCalendarImage' src='/images/illustrations/peopleWithGiantCalendar.svg' />
        <div className='text-center'>
          <span>{I18N('no_upcoming_events')}</span>
        </div>
        {isEventCreator && (
          <div className='p-2 text-center'>
            <Link to='/events/new'><ButtonSecondarySmall>{I18N('create_event')}</ButtonSecondarySmall></Link>
          </div>
        )}
      </div>
    )
  }

  const showCoverImage = highlightedEvent?.coverImageUrl || highlightedEvent?.templateName

  return (
    <>
      <EventCoverImage
        event={highlightedEvent}
        canEditEvent={false}
      />
      <div className={classNames(showCoverImage && 'mt-4')}>
        <div className={classNames('d-flex align-items-center justify-content-between')}>
          <p className='mb-1 text-secondary text-small text-sm-normal font-weight-500'>
            {i18nMoment(moment(highlightedEvent.eventTime)).format('MMM D, YYYY h:mmA')}
          </p>
          <AccessLevel event={highlightedEvent} />
        </div>
        <h4>
          <Link
            to={`/events/${highlightedEvent.slug}`}
            className='text-primary'
          >
            {highlightedEvent.title}
          </Link>
        </h4>
        {highlightedEvent?.participantsCount > 0 && (
          <div className='ParticipantsWrapper mt-4'>
            <EventParticipants event={highlightedEvent} />
          </div>
        )}
        <div className='TopQuestionContent mt-4 d-flex flex-column align-items-start justify-content-center p-3'>
          <TopQuestionContent question={topQuestion} event={highlightedEvent} linkToQuestion={true} />
          {topQuestion && !highlightedEvent.isLockedAndAcceptingAnswers && (
            <div className='mt-3 d-flex align-items-center flex-wrap gap-2'>
              {topQuestion && topQuestion.type !== 'icebreaker' && (<QuestionVotingSection question={topQuestion} event={highlightedEvent} isMobile={true} />)}
              <AnswersOrCommentsCount className={topQuestion.type !== 'icebreaker'} question={topQuestion} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const AnswersOrCommentsCount = ({ question, className }) => {
  const [answerCountHover, setAnswerCountHover] = useState(false)

  const showAnswerCountHover = () => setAnswerCountHover(true)
  const hideAnswerCountHover = () => setAnswerCountHover(false)

  const [commentCountHover, setCommentCountHover] = useState(false)

  const showCommentCountHover = () => setCommentCountHover(true)
  const hideCommentCountHover = () => setCommentCountHover(false)

  if (question.answersCount > 0) {
    return (
      <Link
        className={classNames('text-small pill-button', answerCountHover && 'pill-button-active', className)}
        onMouseEnter={showAnswerCountHover}
        onMouseLeave={hideAnswerCountHover}
        to={question.displayPath}
      >
        <AnswersCount answersCount={question.answersCount} showAnswers={false} />
      </Link>
    )
  }

  return (
    <Link
      className={classNames(
        'CommentsWidget--Toggle pill-button',
        commentCountHover && 'pill-button-active',
        className
      )}
      onMouseEnter={showCommentCountHover}
      onMouseLeave={hideCommentCountHover}
      to={question.displayPath}
    >
      <span className={classNames('text-small d-flex align-items-center', question.commentsCount === 0 && 'text-secondary')}>
        <CdnSvg src='/images/commentIcon.svg' className={classNames('mr-1 CommentIcon', question.commentsCount === 0 && 'svg-secondary')} />
        {commentsCountText(question.commentsCount, true)}
      </span>
    </Link>
  )
}

export default FeaturedEventWidget
