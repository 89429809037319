import React from 'react'
import { LoadingContainer } from 'components/common/loadingContainer'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import ClearySftpCard from 'components/admin/integrations/cleary_sftp/clearySftpCard'

const ClearySftpIntegrationCard = () => {
  const { isLoading: isLoadingConfigs, data: configs } = useFetch(API.admin.sftpSshPublicKeys.fetch)

  return (
    <LoadingContainer isLoading={isLoadingConfigs}>
      <ClearySftpCard configs={configs} />
    </LoadingContainer>
  )
}

export default ClearySftpIntegrationCard
