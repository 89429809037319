import React from 'react'

import { i18nPath, i18nMoment, i18nFormat } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'

import Modal from 'components/common/modal'

const I18N = i18nPath('views.qna.events.settings_modal.general_settings')

const ArchiveEventModal = ({
  event, showArchiveEventModal, closeArchiveEventModal, archiveEvent,
}) => (
  <Modal size='md' className='ArchiveEventModal' visible={showArchiveEventModal} toggle={closeArchiveEventModal}>
    <div className='p-2 text-center'>
      <h3>{I18N('archive_event')}</h3>
      <div className='text-secondary'>{i18nFormat(I18N('archive_now_text'), <span className='text-primary'>{i18nMoment(event?.archivedAt).format('LLL')}</span>)}</div>
      <div className='d-flex justify-content-center mt-4'>
        <Button className='mr-2' variant='secondary' onClick={closeArchiveEventModal}>
          {I18N('edit_schdule')}
        </Button>
        <Button onClick={archiveEvent}>{I18N('archive_now')}</Button>
      </div>
    </div>
  </Modal>
)

export default ArchiveEventModal
