import { createSlice } from '@reduxjs/toolkit'
import { ReduxState } from 'redux/redux'


export interface NavbarSliceState {
  showNavbar: boolean
}

export const initialState: NavbarSliceState = {
  showNavbar: true,
}

const slice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    showNavbar(state) {
      state.showNavbar = true
    },

    hideNavbar(state) {
      state.showNavbar = false
    },
  },
})

const selectors = {
  isNavbarHidden: () => (state: ReduxState) => !state.navbar.showNavbar,
}

const navbarSlice = {
  ...slice,
  selectors,
}


export default navbarSlice
