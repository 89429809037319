import React, { useEffect } from 'react'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import pageSlice from 'redux/slices/pages'
import { useDispatch, useSelector } from 'react-redux'
import SmartTable from 'components/common/tables/smartTable'
import { Link } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CheckIcon from 'components/icons/checkIcon'
import { ButtonLink } from 'components/common/buttons'
import PartyHatIcon from 'components/icons/partyHatIcon'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.pages.mark_pages_as_updated_page')

const LinkWithExternalIcon = ({ page }) => (
  <Link
    to={page.displayPath}
  >
    {page.title}
    <CdnSvg src='/images/externalLinkIcon.svg' className='svg-link ml-2' />
  </Link>
)

const columns = [
  {
    header: I18N('page_title'),
    accessor: d => <LinkWithExternalIcon page={d} />,
  },
  {
    header: I18N('status'),
    accessor: () => <span><CheckIcon fillColor='var(--success-color)' className='mr-2' />{I18N('up_to_date')}</span>,
  },
]

const MarkPagesAsUpdatedPage = () => {
  const { pageIds = [] } = useQueryParams()

  const dispatch = useDispatch()
  const updatedPages = useSelector(pageSlice.selectors.getPagesMarkedAsUpToDate())
  const { isSaving } = useSelector(pageSlice.selectors.getPagesMetadata())
  const onePageUpdated = updatedPages.length === 1

  useEffect(() => {
    if (pageIds.length > 0) {
      dispatch(pageSlice.asyncActions.markAsUpToDate({ pageIds: pageIds.split(',') }))
    }

    return () => {
      dispatch(pageSlice.actions.setUpToDatePageIds([]))
    }
  }, [JSON.stringify(pageIds)])


  if (isSaving) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div
      className='MarkPagesAsUpdatedPage container my-5 d-flex flex-column justify-content-center align-items-center'
    >
      <PartyHatIcon className='mt-2' />
      <h1 className='mt-3'>{I18N('you_are_all_set')}</h1>
      <div>{I18N('pages_marked_up_to_date', { count: updatedPages.length })}</div>
      <SmartTable
        data={updatedPages}
        columns={columns}
        className='white-bg-table mt-5'
        showPagination={false}
      />
      <div className='d-flex justify-content-end w-100'>
        <ButtonLink to={onePageUpdated ? updatedPages[0].displayPath : '/pages'}>{I18NCommon('close')}</ButtonLink>
      </div>
    </div>
  )
}

export default MarkPagesAsUpdatedPage
