import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.qna.answers')

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildQnaAnswerPayload = answer => _.pick(answer, [
  'questionId',
  'answer',
])

const qnaAnswerSlice = createSlice({
  name: 'qnaAnswers',
  initialState: {
    isAnswerFormVisible: false,
    pendingAnswerContent: '',
    newAnswerIds: [],
    currentQuestionId: null,
    meta: {
      isNotFound: false,
      isSaving: false,
      error: null,
      isLoading: false,
      isLoadingAnswers: false,
    },
  },
  reducers: {
    isAnswerFormVisible(state, action) {
      state.isAnswerFormVisible = action.payload
    },

    updatePendingAnswerContent(state, action) {
      state.pendingAnswerContent = action.payload
    },

    resetAnswerForm(state, action) {
      state.isAnswerFormVisible = false
      state.pendingAnswerContent = ''
    },

    pushNewAnswerId(state, action) {
      state.newAnswerIds.push(action.payload)
    },

    resetNewAnswerIds(state) {
      state.newAnswerIds = []
    },

    setCurrentQuestionId(state, action) {
      state.currentQuestionId = action.payload
    },

    isLoadingAnswers(state, action) {
      state.meta.isLoadingAnswers = action.payload
    },

    // This is where we want to expand our generic handling of errors from the Rails backend
    //   * Generic string error like 500's
    //   * Form based, return all fields that are populating a model.error and the standardized message per field, using this to create inline error messages
    setError(state, action) {
      state.meta.error = action.payload
    },

    // Example of a utility function probably going to be shared to rally an effort to refactor all loading patterns around
    // We can merge in these common, refactored, functions
    isLoading(state, action) {
      state.meta.isLoading = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(qnaAnswerSlice, {
  asyncActions: {
    createAnswer: (answerParams, question) => async (dispatch) => {
      dispatch(qnaAnswerSlice.actions.isLoading(true))

      try {
        const response = await API.qna.answers.create(buildQnaAnswerPayload(answerParams))

        dispatch(
          entitySlice.actions.add({
            data: response.data,
            reverseRelationships: [{ entityId: question.id, entityType: 'qnaQuestion', relationshipName: 'answers' }],
          })
        )

        dispatch(qnaAnswerSlice.actions.resetAnswerForm())
        dispatch(qnaAnswerSlice.actions.pushNewAnswerId(response.data.data.id))
        dispatch(showToastMessage({ message: I18N('successfully_created'), type: 'success' }))
      } catch (e) {
        if (e?.response?.data?.error?.message) {
          const message = e?.response?.data?.error?.message
          dispatch(showToastMessage({ message, type: 'error' }))
        } else {
          dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'error' }))
        }
      } finally {
        dispatch(qnaAnswerSlice.actions.isLoading(false))
      }
    },

    // Reusing exiting question endponi to get its answer list
    fetchAnswers: questionId => async (dispatch) => {
      dispatch(qnaAnswerSlice.actions.isLoadingAnswers(true))
      dispatch(qnaAnswerSlice.actions.setCurrentQuestionId(questionId))

      try {
        const response = await API.qna.questions.fetch(questionId)

        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        dispatch(qnaAnswerSlice.actions.setError('Failed to fetch question'))
      } finally {
        dispatch(qnaAnswerSlice.actions.isLoadingAnswers(false))
      }
    },

    deleteAnswer: answer => async (dispatch) => {
      try {
        await API.qna.answers.destroy(answer)
        dispatch(entitySlice.actions.remove({ type: 'qnaAnswer', id: answer.id }))

        const { question } = answer

        // The destroy response doesn't return data related to the parent question,
        // This structure is necessary in order to update the parent question's answer count.
        const updatedQuestionData = {
          data: {
            attributes: {
              answersCount: question.answersCount - 1,
            },
            id: question.id,
            type: 'qnaQuestion',
          },
        }

        dispatch(entitySlice.actions.update({ data: updatedQuestionData }))

        dispatch(showToastMessage({ message: I18N('successfully_deleted'), type: 'success' }))
      } catch (e) {
        dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'warn' }))
      }
    },

    acceptAnswer: answer => async (dispatch) => {
      try {
        const response = await API.qna.answers.accept(answer)
        dispatch(entitySlice.actions.add({ data: response.data }))

        dispatch(showToastMessage({ message: I18N('successfully_accepted'), type: 'success' }))
      } catch (e) {
        // checking for event validation error - question has already been accepted
        if (e.response?.data?.error?.errors?.event?.[0]) {
          dispatch(showToastMessage({ message: e.response.data.error.errors.event[0], type: 'warn' }))
        } else {
          dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'warn' }))
        }
      }
    },

    verifyAnswer: answer => async (dispatch) => {
      try {
        const response = await API.qna.answers.verify(answer)
        dispatch(entitySlice.actions.add({ data: response.data }))

        dispatch(showToastMessage({ message: I18N('successfully_verified'), type: 'success' }))
      } catch (e) {
        // checking for event validation error - question has already been verified
        if (e.response?.data?.error?.errors?.event?.[0]) {
          dispatch(showToastMessage({ message: e.response.data.error.errors.event[0], type: 'warn' }))
        } else {
          dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'warn' }))
        }
      }
    },
  },
})

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
_.assign(qnaAnswerSlice, {
  selectors: {
    getAnswerFormData: () => (state) => {
      const {
        pendingAnswerContent, isAnswerFormVisible, newAnswerIds, currentQuestionId, meta,
      } = state.qnaAnswers
      return {
        pendingAnswerContent,
        isAnswerFormVisible,
        newAnswerIds,
        currentQuestionId,
        meta,
      }
    },
    // eslint-disable-next-line arrow-parens, arrow-body-style
    getAnswer: id => {
      return state => build(state.entities, 'qnaAnswer', id)
    },
  },
})

export default qnaAnswerSlice
