import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import ProgressGuidanceCard from 'components/admin/journeys/journeyBlueprints/journeyCreationProgress'
import StepTemplatesTable from 'components/admin/journeys/journeyBlueprints/stepTemplatesTable'

import { SimpleJourneyType } from 'types/journeys/journey'
import JourneyBlueprintType from 'types/journeys/journeyBlueprint'
import type { SimpleUserType } from 'types/user'
import InactiveCorrespondentBanner from 'components/admin/journeys/common/inactiveCorrespondentBanner'

const I18N = i18nPath('views.admin.journeys.journey_blueprints')

const NEW_STEP_MODAL_OPEN = 'new_step_modal'
const SUCCESS_MODAL_OPEN = 'success_modal'

interface JourneyBlueprintEditModeContentProps {
  journeyBlueprint: JourneyBlueprintType,
  onRemoveJourney: (journey: SimpleJourneyType) => void,
  setOpenModal: (value: string) => void,
  currentUsers: SimpleUserType[],
}

const JourneyBlueprintEditModeContent = ({
  journeyBlueprint,
  onRemoveJourney,
  setOpenModal,
  currentUsers,
}: JourneyBlueprintEditModeContentProps) => {
  const showProgressGuidance = journeyBlueprint?.state === 'draft'

  return (
    <>
      {showProgressGuidance && (
        <ProgressGuidanceCard
          journeyBlueprint={journeyBlueprint}
          setIsSuccessModalOpen={() => setOpenModal(SUCCESS_MODAL_OPEN)}
          onRemoveJourney={onRemoveJourney}
          currentUsers={currentUsers}
        />
      )}
      <StepTemplatesTable journeyBlueprint={journeyBlueprint} />
      <Button className='mt-4' onClick={() => setOpenModal(NEW_STEP_MODAL_OPEN)}>{I18N('create_a_new_step')}</Button>
    </>
  )
}

export default JourneyBlueprintEditModeContent
