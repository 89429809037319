import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'

import PathnameContext from 'components/contexts/pathnameContext'
import BackIcon from 'components/icons/backIcon'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import classNames from 'classnames'
import messageMobileApp from 'utils/messageMobileApp'

type Props = {
  backToText?: string
  fallbackUrl?: string
  url?: string
  className?: string
}

const ExitButton = ({
  fallbackUrl, className, url,
}: Props) => {
  const { inMobileApp } = useCurrentUser()
  const { currentPathname, previousPathname } = useContext(PathnameContext)

  let backUrl

  if (url) {
    backUrl = url
  } else if (previousPathname && previousPathname !== currentPathname) {
    backUrl = previousPathname
  } else {
    backUrl = fallbackUrl
  }

  const handleClick = (e) => {
    // the mobile app uses different webviews for different modules, so the regular history.goBack() doesn't work
    // because it will keep the user in that current webview and maybe they should go to a different one
    if (inMobileApp) {
      e.preventDefault()
      messageMobileApp({ action: 'goBack' })
    }
  }

  if (!backUrl) { return null }

  return (
    <div className={classNames('ExitButton', className)}>
      <Link
        className='text-center btn-secondary btn'
        onClick={handleClick}
        to={backUrl}
      >
        <span className='ml-2'>{I18n.t('views.common.exit')}</span>
      </Link>
    </div>
  )
}

export default ExitButton
