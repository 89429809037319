import { CategoryItemWithLoadedTemplates } from 'components/admin/articles/templates/categoryItem'
import TemplatesSidebarEmptyState from 'components/admin/articles/templates/TemplatesSidebarEmptyState'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useApi from 'components/common/hooks/useApi'
import React, { useEffect } from 'react'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.article_editor.templates_sidebar')

const TemplatesSearch = ({ searchQuery, isCompanyTemplates, gridView }) => {
  const [fetchTemplates, { isLoading, data: templates },
  ] = useApi(API.admin.article.templates.fetchAll)

  useEffect(() => {
    if (searchQuery) {
      const templatesTypeProp = isCompanyTemplates ? 'companyTemplates' : 'baseTemplates'

      fetchTemplates({
        query: searchQuery, page: 1, perPage: 20, optionalRelationships: 'categories', [templatesTypeProp]: true,
      })
    }
  }, [searchQuery, isCompanyTemplates])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (_.isEmpty(templates)) {
    return <TemplatesSidebarEmptyState message={I18NCommon('no_results_found')} />
  }

  if (isCompanyTemplates) {
    return (
      <CategoryItemWithLoadedTemplates
        label={I18N('saved_templates')}
        templates={templates || []}
        startExpanded
        gridView={gridView}
      />
    )
  }

  const categories = _.uniqBy(_.flatMap(templates, 'categories'), 'id')

  return (
    <div>
      {categories?.map(category => (
        <CategoryItemWithLoadedTemplates
          key={category.id}
          label={category.name}
          templates={templates.filter(template => template.categories.some(c => c.id === category.id))}
          startExpanded
          gridView={gridView}
        />
      ))}
    </div>
  )
}

export default TemplatesSearch
