import React, { useRef, useState } from 'react'
import BackButton from 'components/common/backButton'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import DocumentsTable from 'pages/external_sync/documentsTable'
import useExternalFileImport from 'pages/external_sync/hooks/useExternalFileImport'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import pageVariables from 'utils/pageVariables'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { ExternalSyncDocument, ExternalSyncImportState, ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import Breadcrumb from 'pages/external_sync/breadcrumb'
import InitialEmptyState from 'pages/external_sync/initialEmptyState'
import ActionsAndSearch from 'pages/external_sync/actionsAndSearch'
import InitialSyncingState from 'pages/external_sync/initialSyncingState'
import useApi from 'components/common/hooks/useApi'
import ExternalFilePickerModal from 'components/admin/external_sync/externalFilePickerModal'

const I18N = i18nPath('views.integrations_page')

const SourcePage = () => {
  const { sourceId } = useParams()
  const {
    importedDocumentsCount, totalDocumentsCount, state, totalSyncedTimes, onResyncStarted, importDocuments,
  } = useExternalFileImport({ sourceId })

  const [destroyedDocumentsIds, setDestroyedDocumentsIds] = useState<string[]>([])
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<string[]>([])

  const queryParamRef = useRef<string>('')

  const [parentDocumentId, setParentDocumentId] = useQueryParamState<string | undefined>({
    param: 'parentDocumentId', initialValue: undefined, ref: queryParamRef,
  })

  const [searchQuery, setSearchQuery] = useQueryParamState<string | undefined>({
    param: 'searchQuery', initialValue: undefined, ref: queryParamRef,
  })

  const {
    data: source, isLoaded, isLoading,
  } = useFetch<ExternalSyncSource>(
    () => API.admin.externalSync.sources.fetch(sourceId),
    // deletedDocumentsCount is used to force a re-fetch when a document is deleted, to update the source documentsCount and foldersCount
    // totalSyncedTimes is used to force a re-fetch when a document is added, hence a sync happens, to update the source documentsCount and foldersCount
    [sourceId, destroyedDocumentsIds.length, totalSyncedTimes],
    {
      toastErrorMessage: I18N('external_knowledge_section.error_loading_source'),
    }
  )

  const {
    data: rawParentDocument,
  } = useFetch<ExternalSyncDocument>(API.admin.externalSync.documents.fetch, [parentDocumentId], {
    load: !!parentDocumentId,
  })

  const [bulkDestroy, { isLoading: isBulkDestroying }] = useApi(API.admin.externalSync.documents.bulkDestroy, {
    toastSuccessMessage: I18NCommon('deleted_successfully'),
    toastErrorMessage: I18NCommon('generic_error'),
    onSuccess: () => {
      setDestroyedDocumentsIds(destroyedDocumentsIds => [...destroyedDocumentsIds, ...selectedDocumentsIds])
      setSelectedDocumentsIds([])
    },
  })

  const handleBulkDestroy = () => {
    bulkDestroy(selectedDocumentsIds)
  }

  // necessary to only use the parentDocument that matches the parentDocumentId
  // to avoid stale state, ie, clicking in a different document and rendering the wrong parent document
  const parentDocument = rawParentDocument?.id === parentDocumentId ? rawParentDocument : undefined

  const [showExternalFilePickerModal, setShowExternalFilePickerModal] = useState(false)
  const handleOpenPicker = () => {
    if (source?.type === 'ExternalSync::GoogleDrive::Source') {
      const currentUrl = new URL(window.location.href)
      currentUrl.search = ''
      window.location.href = `${pageVariables.googleDrivePickerUrl}?redirect_uri=${encodeURIComponent(currentUrl.toString())}`
    } else if (source?.type === 'ExternalSync::Notion::Source') {
      window.location.href = `${window.location.origin}/auth/notion/user_install_url`
    } else {
      setShowExternalFilePickerModal(true)
    }
  }

  if (!isLoaded || !source) return <CirclesLoadingIndicator />

  const hasNothingImported = source?.documentsCount === 0 && source?.foldersCount === 0

  const showInitialEmptyState = hasNothingImported
    && isLoaded && !isLoading && state === ExternalSyncImportState.Idle

  const showInitialSyncingState = hasNothingImported
    && isLoaded && !isLoading && state === ExternalSyncImportState.Importing

  const showDocumentsTable = !showInitialEmptyState && !showInitialSyncingState

  return (
    <div className='SourcePage'>
      <div>
        <BackButton url='/admin/app_integrations' className='mt-4' backToText={I18N('app_integrations')} />
        <div className='d-flex flex-row align-items-center justify-content-between my-3'>
          <Breadcrumb
            document={parentDocument}
            source={source!}
            onParentClick={p => setParentDocumentId(p?.id)}
            importedDocumentsCount={importedDocumentsCount}
            totalDocumentsCount={totalDocumentsCount}
            state={state}
          />
        </div>

        {showInitialEmptyState && (
          <InitialEmptyState onAddDocuments={handleOpenPicker} source={source} />
        )}

        {showInitialSyncingState && <InitialSyncingState />}

        {showDocumentsTable && (
          <>
            <ActionsAndSearch
              source={source}
              isNavigatingToChildFolder={!!parentDocumentId}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onAddDocuments={handleOpenPicker}
              importState={state}
              onResyncStarted={onResyncStarted}
              selectedDocumentsIds={selectedDocumentsIds}
              onRemoveDocuments={handleBulkDestroy}
              isBulkDestroying={isBulkDestroying}
            />

            <DocumentsTable
              key={totalSyncedTimes} // rerender when sync happens
              source={source}
              parentDocumentId={parentDocumentId}
              searchQuery={searchQuery}
              onDocumentClick={d => setParentDocumentId(d.id)}
              selectedDocumentsIds={selectedDocumentsIds}
              setSelectedDocumentsIds={setSelectedDocumentsIds}
              destroyedDocumentsIds={destroyedDocumentsIds}
              setDestroyedDocumentsIds={setDestroyedDocumentsIds}
            />
          </>
        )}
      </div>
      {showExternalFilePickerModal && (
        <ExternalFilePickerModal
          isOpen={showExternalFilePickerModal}
          toggle={() => setShowExternalFilePickerModal(false)}
          source={source}
          importDocuments={importDocuments}
        />
      )}
    </div>
  )
}

export default SourcePage
