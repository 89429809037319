import React from 'react'

import Card from 'components/common/card'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { AwardedBadgeCardTitle } from 'components/home/awardedBadgeCard'
import AwardedBadgeSocial from 'components/badges/awardedBadgeSocial'
import ShowMoreText from 'react-show-more-text'
import { I18NCommon } from 'utils/i18nHelpers'

const AwardedBadgeItem = ({ awardedBadge, widgetWidth }) => {
  const { user, badge: { imageUrl, description } } = awardedBadge

  const currentUser = useCurrentUser()
  const isCurrentUser = currentUser.id === user.id

  const linesToShow = widgetWidth < 400 ? 3 : 2

  return (
    <Card className='AwardedBadgeItem'>
      <div className='d-flex flex-column align-items-center'>
        <img src={imageUrl} className='AwardedBadgeItem__image mb-2' />
        <AwardedBadgeCardTitle awardedBadge={awardedBadge} isCurrentUser={isCurrentUser} hideImage={false} />

        <ShowMoreText
          lines={linesToShow}
          more={I18NCommon('more')}
          less={I18NCommon('show_less')}
          className='w-100 text-small my-2'
          anchorClass='ShowMoreText__anchor'
        >
          <p className='w-100 text-small my-2'>{description}</p>
        </ShowMoreText>
      </div>

      <AwardedBadgeSocial awardedBadge={awardedBadge} showCommentsOnInit={false} />
    </Card>
  )
}

export default AwardedBadgeItem
