import React from 'react'
import { Link } from 'react-router-dom'
import { groupPath } from 'utils/routeHelpers'
import GroupType from 'types/group'

import GroupMiniCard from 'components/common/groupMiniCard'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const GroupLink = ({
  children,
  group,
  id,
}) => (
  <Link
    to={groupPath(group, group.groupType)}
    id={id}
  >
    {children}
  </Link>
)

interface Props {
  group: GroupType,
  id?: string,
  className?: string,
}

const GroupInfoPopover = ({
  group,
  id = `group-name-link-${group.id}`,
  className,
}: Props) => {
  const [fetchGroupInfo, { isLoading, data }] = useApi(API.groups.simple, {
    updateEntitySlice: true,
  })

  const onHover = () => {
    if (!data) {
      fetchGroupInfo(group.id)
    }
  }

  const target = (
    <GroupLink group={group} id={id}>
      <span className={className}>{group.name}</span>
    </GroupLink>
  )

  return (
    <HoverAndClickPopover
      target={target}
      id={id}
      popoverClassName='GroupInfoPopover customPopover'
      onOverlayEnter={onHover}
    >
      <GroupLink group={group} id={id}>
        <GroupMiniCard group={group} isLoading={isLoading} />
      </GroupLink>
    </HoverAndClickPopover>
  )
}

export default GroupInfoPopover
