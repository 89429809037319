import HorizontalStackedBarGraph from 'components/analytics/common/horizontalStackedBarGraph'
import LegendItem from 'components/analytics/common/legendItem'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import React from 'react'
import { useSelector } from 'react-redux'
import { transparent } from 'utils/color'
import { i18nPath } from 'utils/i18nHelpers'
import engagementAnalyticsSlice from 'redux/slices/analytics/engagement'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'
import TargetingRulesType from 'types/audience/targetingRules'
import API from 'services/api'

const I18N = i18nPath('views.platform_analytics.overview.adoption')

interface AdoptionProps {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}


const Adoption = ({ periodStart, periodEnd, targetingRules }: AdoptionProps) => {
  const currentCompany = useCurrentCompany()

  const {
    contributorsPercent,
    consumersPercent,
    noUsagePercent,
  } = useSelector(engagementAnalyticsSlice.selectors.getAdoption())

  const { isLoading } = useSelector(engagementAnalyticsSlice.selectors.getMeta('adoption'))


  const { highlightColor: contributorsColor } = currentCompany.customStyles.variables.colors
  const consumersColor = transparent(contributorsColor, 0.7)
  const noUsageColor = transparent(contributorsColor, 0.4)

  const contributorsValue = Math.round(contributorsPercent * 100)
  const consumersValue = Math.round(consumersPercent * 100)
  const noUsageValue = Math.round(noUsagePercent * 100)


  const labels = ['Contributors, Consumers, No Logins']
  const datasets = [
    {
      label: I18N('contributors'),
      data: [contributorsValue],
      backgroundColor: contributorsColor,
      hoverBackgroundColor: contributorsColor,
    },
    {
      label: I18N('consumers'),
      data: [consumersValue],
      backgroundColor: consumersColor,
      hoverBackgroundColor: consumersColor,
    },
    {
      label: I18N('no_usage'),
      data: [noUsageValue],
      backgroundColor: noUsageColor,
      hoverBackgroundColor: noUsageColor,
    },
  ]

  const onExport = (email: string) => {
    const exportXlsxParams = {
      periodStart,
      periodEnd,
      targetingRules,
      email,
    }

    return API.admin.analytics.engagement.exportAdoptionXlsx(exportXlsxParams)
  }


  return (
    <div className='Adoption analytics-card'>

      <header className='d-flex flex-row justify-content-between'>
        <h3>{I18N('title')}</h3>
        <div className='d-flex flex-row align-items-center'>
          <ExportXlsxButton
            className='ml-1'
            onSubmit={onExport}
          />
        </div>
      </header>

      {isLoading ? (
        <CirclesLoadingIndicator />
      ) : (
        <>
          <HorizontalStackedBarGraph
            labels={labels}
            datasets={datasets}
            classname='w-100'
            generateTooltipLabel={(value, category) => (
              I18N('graph_tooltip_text', { value: `${value}%`, category: category.toLowerCase() })
            )}
          />

          <div className='d-flex flex-row w-100'>
            <LegendItem
              value={`${contributorsValue}%`}
              category={I18N('contributors')}
              backgroundColor={contributorsColor}
              showDescription={false}
              showTooltip
              tooltipText={I18N('contributors_tooltip')}
              classname='w-20'
            />
            <LegendItem
              value={`${consumersValue}%`}
              category={I18N('consumers')}
              backgroundColor={consumersColor}
              showDescription={false}
              showTooltip
              tooltipText={I18N('consumers_tooltip')}
              classname='w-20'
            />
            <LegendItem
              value={`${noUsageValue}%`}
              category={I18N('no_usage')}
              backgroundColor={noUsageColor}
              showDescription={false}
              showTooltip
              tooltipText={I18N('no_usage_tooltip')}
              classname='w-20'
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Adoption
