import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { isAudienceValid } from 'utils/audience'
import { Button } from 'components/common/buttons'
import FormErrorList from 'components/errors/formErrorList'
import { buildCarouselPayload } from 'redux/slices/carouselCards'
import CarouselCardForm from 'components/admin/carousel_cards/carouselCardForm'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import audienceSlice from 'redux/slices/audiences'
import CarouselCard from 'components/carousel_cards/carouselCard'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'

const I18N = i18nPath('views.admin.carousel_cards')

const EditCarouselCardPage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [workingCopy, setWorkingCopy] = useState({})

  const { carouselCardId } = params

  const currentUser = useCurrentUser()

  const {
    data: originalCarouselCard, isLoading, error: fetchError,
  } = useFetch(() => API.admin.carouselCards.fetch(carouselCardId), [carouselCardId])

  const [updateCarouselCard, {
    data: updatedCarouselCard, isLoading: isSaving, error: updateError,
  }] = useApi(API.admin.carouselCards.update, {
    toastSuccessMessage: I18NCommon('updated_successfully'),
    onSuccess: data => setWorkingCopy(data),
  })

  const [deleteCard, { isLoading: isDeleting }] = useApi(API.admin.carouselCards.destroy, {
    onSuccess: () => history.push('/admin/carousel_cards'),
  })

  const carouselCard = updatedCarouselCard || originalCarouselCard
  const error = fetchError || updateError

  const isMissingRequiredFields = !workingCopy?.name || !workingCopy?.startTime || !workingCopy?.endTime
  const changesPresent = !_.isEqual(buildCarouselPayload(carouselCard), buildCarouselPayload(workingCopy))
  const showDeleteCarouselCardButton = currentUser.permissions.carouselManager
  const disableSaveButton = isMissingRequiredFields || !changesPresent || !isAudienceValid(workingCopy)

  useEffect(() => {
    if (carouselCard?.id) {
      setWorkingCopy(carouselCard)
    }
  }, [isLoading])


  const saveCarouselCard = () => {
    updateCarouselCard(workingCopy)
  }

  const deleteCarouselCard = () => {
    if (confirm(I18N('delete_confirm'))) {
      deleteCard(carouselCard)
    }
  }

  if (!carouselCard?.id || isLoading || _.isEmpty(workingCopy)) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='EditCarouselCardPage'>
      <header className='AdminHeader'>
        <h3>{I18N('edit_carousel_card_title')}</h3>
      </header>

      <main className='AdminContent'>
        <CarouselCard carouselCard={workingCopy} />

        <CarouselCardForm
          card={workingCopy}
          setCard={setWorkingCopy}
        />

        {error && <FormErrorList error={error} />}

        <div className='display-flex'>
          <Button disabled={disableSaveButton} onClick={saveCarouselCard} showLoadingSpinner={isSaving}>
            {I18N('update_carousel_card')}
          </Button>

          {showDeleteCarouselCardButton && (
            <Button variant='danger' className='ml-2' onClick={deleteCarouselCard} showLoadingSpinner={isDeleting} disabled={isDeleting}>
              {I18N('delete_carousel_card')}
            </Button>
          )}
        </div>
      </main>
    </div>
  )
}

export default EditCarouselCardPage
