import React from 'react'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import classNames from 'classnames'

interface SingleStatTileProps {
  value: string | number
  description: string
  isLoading?: boolean
  classname?: string
}

const SingleStatTile = ({
  value,
  description,
  isLoading,
  classname,
}: SingleStatTileProps) => (
  <div className={classNames('SingleStatTile analytics-card py-4', classname)}>
    {isLoading ? (
      <CirclesLoadingIndicator />
    ) : (
      <div className='d-flex flex-column'>
        <h3 className='mb-0'>{value}</h3>
        <p className='text-secondary mb-0'>{description}</p>
      </div>
    )}
  </div>
)


export default SingleStatTile
