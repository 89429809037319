/* eslint-disable no-script-url */
import React, { ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import customStylesSlice from 'redux/slices/customStyles'
import { DynamicStyles, ThemeStyles } from 'components/appColorFunctions'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useCustomStyles } from 'pages/admin/custom_styles/context'
import JsonEditor from './jsonEditor'

const I18N = i18nPath('views.custom_styles')

const JsonSection = (): ReactNode => {
  const dispatch = useDispatch()
  const [showEditor, setShowEditor] = useState(false)
  const currentUser = useCurrentUser()
  const { workingCopy } = useCustomStyles()
  const isClearyAdmin = !!currentUser.permissions.clearyAdmin

  const toggleEditor = () => {
    setShowEditor(oldValue => !oldValue)
  }

  const applyJsonData = (jsonData) => {
    setShowEditor(false)
    dispatch(customStylesSlice.actions.updateWorkingCopy(jsonData))
    ThemeStyles.init(jsonData.variables).then(DynamicStyles.init)
  }

  if (!isClearyAdmin) return null

  return (
    <div className='row'>
      <div className='col-12'>
        <a href='javascript:;' onClick={toggleEditor}>
          {' '}
          {showEditor ? I18N('hide_json') : I18N('see_json')} {I18N('json_data')}
        </a>
        {showEditor && (
          <div>
            <JsonEditor jsonData={workingCopy} applyJsonData={applyJsonData} />
          </div>
        )}
      </div>
    </div>
  )
}

export default JsonSection
