import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import RadioButton from 'components/form_fields/radioButton'
import EditAutoResizable from 'components/admin/layout/layout_collection_sidebar/common/editAutoResizable'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.celebrations')

const CelebrationsWidgetSettingsSidebar = ({ widget, updateWidget }) => {
  const updateSettings = (key, value) => {
    updateWidget({ ...widget, settings: { ...widget.settings, [key]: value } })
  }

  const { splitIntoFollowedAndOthers } = widget.settings

  return (
    <div className='CelebrationsWidgetSettingsSidebar'>
      <h5>{I18N('title')}</h5>

      <label className='text-secondary font-weight-500'>{I18N('widget_listing')}</label>
      <div className='w-100 d-flex flex-column gap-3'>
        <RadioButton
          id='split_view'
          checked={splitIntoFollowedAndOthers}
          onChange={() => updateSettings('splitIntoFollowedAndOthers', true)}
          label={I18N('split_view')}
        />
        <RadioButton
          id='single_view'
          checked={!splitIntoFollowedAndOthers}
          onChange={() => updateSettings('splitIntoFollowedAndOthers', false)}
          label={I18N('single_view')}
        />
      </div>

      <hr className='w-100' />

      <EditAutoResizable widget={widget} updateWidget={updateWidget} />
    </div>
  )
}

export default CelebrationsWidgetSettingsSidebar
