import React from 'react'
import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath } from 'utils/i18nHelpers'
import UserEditableField from 'components/admin/users/userEditor/userEditableField'
import FormSelectField from 'components/form_fields/formSelectField'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.users')

const SocialNetworkLabel = ({ networkName = '' }) => (
  <label><CdnSvg className='mr-1' src={`/images/${networkName}Icon.svg`} />{I18N(networkName)}</label>
)

const ContactInformationSection = ({
  user = null,
  setUser = () => { },
  initialIsOpen = false,
}) => {
  const userEmails = (user.emails || []).map(email => ({ label: email, value: email }))

  return (
    <CollapsibleCard
      title={I18N('sections.contact_information')}
      initialIsOpen={initialIsOpen}
      className='mt-3'
    >
      <p className='text-secondary'>{I18N('sections.contact_information_note')}</p>
      <div className='ContactInformationSection mt-3'>
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='phoneMobile'
          placeholder={I18N('enter_phone_number')}
        />
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='websiteLink'
          inputType='url'
          placeholder={I18N('enter_website_link')}
        />
        <FormSelectField
          className='additional-emails-field'
          placeholder={I18N('enter_additional_emails')}
          fieldName={I18N('additional_emails')}
          currentValue={userEmails}
          onChange={emails => setUser({ ...user, emails: emails.map(email => email.value) })}
          isMulti
          isCreatable
        />
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='github'
          label={<SocialNetworkLabel networkName='github' />}
          placeholder={I18N('enter_github')}
        />
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='linkedin'
          label={<SocialNetworkLabel networkName='linkedin' />}
          placeholder={I18N('enter_linkedin')}
        />
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='twitter'
          label={<SocialNetworkLabel networkName='twitter' />}
          placeholder={I18N('enter_twitter')}
        />
      </div>
    </CollapsibleCard>
  )
}

export default ContactInformationSection
