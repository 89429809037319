import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import AwardedBadgeCard from 'components/home/awardedBadgeCard'
import badgeIcon from 'assets/badgeIcon.png'

const I18N = i18nPath('views.custom_styles.preview_components')

const AwardedBadgeCardHeroColor = () => (
  <AwardedBadgeCard
    feedItem={{}}
    awardedBadge={{
      user: {},
      badge: {
        id: '2',
        createdAt: '2019-07-24T13:35:24Z',
        updatedAt: '2019-07-24T13:35:24Z',
        name: I18N('awarded_badge_name'),
        displayName: I18N('awarded_badge_display_name'),
        description: I18N('awarded_badge_description'),
        imageUrl: badgeIcon,
        awardedBadgeCount: 254,
        type: 'standard',
        numYears: null,
        sendNotifications: true,
        locations: null,
      },
    }}
    currentUser={{}}
  />
)

export default AwardedBadgeCardHeroColor
