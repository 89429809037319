import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'

import communicationSlice from 'redux/slices/journeys/communications'
import taskSlice from 'redux/slices/journeys/tasks'
import calendarEventSlice from 'redux/slices/journeys/calendarEvents'
import introductionSlice from 'redux/slices/journeys/introductions'
import meetingSlice from 'redux/slices/journeys/meetings'

const I18N = i18nPath('views.admin.journeys.steps.delete_modal')

const StepDeleteModal = ({
  stepType = '',
  forUser = null,
  visible,
  toggle,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const onSuccess = () => {
    if (forUser?.id) {
      history.push(`/admin/users/${forUser?.id}/journeys`)
    } else {
      history.push('/admin/journeys/')
    }
  }

  const onDelete = () => {
    switch (stepType) {
    case 'communication':
      dispatch(communicationSlice.asyncActions.admin.deleteCommunication(id, onSuccess))
      break
    case 'task':
      dispatch(taskSlice.asyncActions.admin.deleteTask(id, onSuccess))
      break
    case 'calendarEvent':
      dispatch(calendarEventSlice.asyncActions.admin.deleteCalendarEvent(id, onSuccess))
      break
    case 'introduction':
      dispatch(introductionSlice.asyncActions.admin.deleteIntroduction(id, onSuccess))
      break
    case 'meeting':
      dispatch(meetingSlice.asyncActions.admin.deleteMeeting(id, onSuccess))
      break
    default:
      break
    }
  }

  return (
    <Modal size='md' className='StepDeleteModal' visible={visible} toggle={toggle}>
      <div className='p-2 text-center'>
        <h3>{I18N('title')}</h3>
        <p className='text-secondary'>{I18N('description')}</p>
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <div role='button' tabIndex={0} onClick={toggle} className='mr-3 text-muted-dark'>
            {I18NCommon('cancel')}
          </div>
          <Button onClick={onDelete} variant='danger'>{I18N('delete_step')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default StepDeleteModal
