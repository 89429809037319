import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import entitySlice from 'redux/slices/entities'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'

export const initialState = {
  teamTypeIds: [],
  meta: {
    ...defaultMeta,
  },
}

const groupTypeSlice = createSlice({
  name: 'groupTypes',
  initialState,
  reducers: {
    ...defaultActions,

    setTeamTypeIds(state, action) {
      state.teamTypeIds = action.payload
    },

    clearTeamTypeIds(state) {
      state.teamTypeIds = []
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  fetchAll: () => async (dispatch) => {
    dispatch(groupTypeSlice.actions.isLoading(true))
    try {
      const response = await API.groupTypes.fetchAll()

      const teamTypeIds = response.data.data.map(teamType => teamType.id)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(groupTypeSlice.actions.setTeamTypeIds(teamTypeIds))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(getResponseOrThrow(e))

      dispatch(groupTypeSlice.actions.setError(error))
    } finally {
      dispatch(groupTypeSlice.actions.isLoading(false))
    }
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getMetaData: () => state => state.groupTypes.meta,

  getTeamTypes: () => state => state.groupTypes.teamTypeIds.map(id => build(state.entities, 'groupType', id)) || [],
}

export default {
  ...groupTypeSlice,
  selectors,
  asyncActions,
}
