import React, { ReactElement } from 'react'
import { useCustomStyles, useVariables } from 'pages/admin/custom_styles/context'
import { getPropertyListWithValues, sortArrayBy } from 'components/admin/customStyles/utils'
import DimensionControl from 'components/admin/customStyles/dimensionControl'

const LogoDimensionsSubsection = (): ReactElement => {
  const { handlePropertyChanged } = useCustomStyles()
  const dimensions = useVariables('dimensions')
  const dimensionVars = getPropertyListWithValues({ variables: dimensions, type: 'dimensions' })
  const logoDimensionVariables = ['logoWidth', 'logoHeight']

  dimensionVars.sort(sortArrayBy(logoDimensionVariables))

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='d-flex flex-column'>
          {dimensionVars.map(([variable, value]) => (
            <DimensionControl
              key={variable}
              variable={variable}
              value={value}
              onChange={handlePropertyChanged}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default LogoDimensionsSubsection
