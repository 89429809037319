import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Modal from 'components/common/modal'
import { i18nPath } from 'utils/i18nHelpers'
import profileSlice from 'redux/slices/profiles'
import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import CustomProfileField from 'components/common/customProfileField'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import getCdnUrl from 'utils/getCdnUrl'

const I18N = i18nPath('views.home')
const I18NCommon = i18nPath('views.common')

const CompleteYourProfileModal = ({ showModal, closeModal, user }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const currentCompany = useCurrentCompany()
  const [workingCopy, setWorkingCopy] = useState({})

  const cdnDomain = useSelector(state => state.cdnDomain)

  useEffect(() => {
    setWorkingCopy(user)
  }, [user?.updatedAt])

  const onSave = () => {
    dispatch(profileSlice.asyncActions.update(user, workingCopy)).then(() => {
      dispatch(profileSlice.actions.clearProfile())
      history.push(`/profile/${user.username}`)
    })
  }

  const generateRichTextHandler = fieldName => (value) => {
    setWorkingCopy({ ...workingCopy, [fieldName]: value })
  }

  const onCloseModal = () => {
    closeModal()
  }

  const modalTitle = (
    <>
      {I18N('complete_your_profile')}
      <div className='text-secondary'>{I18N('tell_us_more')}</div>
    </>
  )

  const { photos } = user

  const primaryPhoto = _.find(photos, { primary: true }) || { placeholder: true, permittedActions: [] }
  const primaryPhotoUrl = (primaryPhoto && primaryPhoto.imageUrl) || getCdnUrl(cdnDomain, '/images/profile_main-missing.png')

  return (
    <Modal
      visible={showModal}
      toggle={onCloseModal}
      title={modalTitle}
      size='lg'
      modalContentClassName='p-4'
    >
      <div className='row my-4'>
        <div className='col-3 pr-4'>
          <img className='bordered-thumbnail w-100' src={primaryPhotoUrl} />
        </div>
        <div className='col-9 pl-0'>
          <h2>{user.preferredFullName}</h2>
          <div className='text-secondary mb-3'>{user.title}</div>
          {[1, 2, 3].map((numberSuffix) => {
            const fieldName = `customField${numberSuffix}`
            return (
              <CustomProfileField
                key={fieldName}
                fieldName={fieldName}
                currentCompany={currentCompany}
                userAnswer={workingCopy[fieldName]}
                updateField={generateRichTextHandler}
              />
            )
          })}
        </div>
      </div>
      <div className='ModalActions d-flex align-items-center justify-content-end pt-3 mb-3 mb-3'>
        <CancelButton onClick={onCloseModal} />

        <ButtonNarrow className='ml-4' onClick={onSave}>
          {I18NCommon('save')}
        </ButtonNarrow>
      </div>
    </Modal>
  )
}

export default CompleteYourProfileModal
