import { createSlice } from '@reduxjs/toolkit'
import API from 'services/api'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import appSignal from 'services/appSignal'


const initialState = {
  initialValues: {
    variables: {
      colors: {}, fonts: {}, border: {}, fontWeight: {}, textTransform: {}, boxShadow: {},
    },
    css_overrides: '',
  },
  workingCopy: {
    variables: {
      colors: {}, fonts: {}, border: {}, fontWeight: {}, textTransform: {}, boxShadow: {},
    },
    css_overrides: '',
  },
  meta: defaultMeta,
}

const customStylesSlice = createSlice({
  name: 'customStyles',
  initialState,
  reducers: {
    ...defaultActions,

    loadCustomStatusSuccess: (state, action) => {
      state.meta.isLoading = false
      state.initialValues = {
        ...action.payload.customStyles,
      }
      state.workingCopy = action.payload.customStyles
    },
    updateWorkingCopy: (state, action) => {
      state.workingCopy = action.payload
    },
  },
})

const updateCustomStyles = company => async (dispatch) => {
  try {
    dispatch(customStylesSlice.actions.isLoading(true))
    window.scrollTo(0, 0)

    await API.admin.customStyles.update(company)
  } catch (e) {
    appSignal.sendErrorUnlessClearyBackendError(e)
    const { error } = checkForError(getResponseOrThrow(e))
    dispatch(customStylesSlice.actions.setError(error))
  } finally {
    dispatch(customStylesSlice.actions.isLoading(false))
  }
}

const loadCustomStyles = () => async (dispatch, getState) => {
  const { styles, currentCompany: { faviconUrl } } = getState() as ReduxState

  const customStyles = {
    customStyles: {
      ...styles,
      faviconUrl,
    },
  }

  dispatch(customStylesSlice.actions.loadCustomStatusSuccess(customStyles))
}


export default {
  ...customStylesSlice,
  asyncActions: {
    loadCustomStyles,
    updateCustomStyles,
  },
}
