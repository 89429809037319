import { UserMessageType } from 'components/ai/chat/ask_ai_modal/types'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import React from 'react'

type Props = {
  message: UserMessageType
}

const UserMessage = ({ message }: Props) => (
  <div className='UserMessage d-flex justify-content-end mb-4'>
    <div className='UserMessage-content border-radius p-3 text-white'>{message.content}</div>
    <div className='flex-shrink-0 mx-3'>
      <EmployeeThumbnailPhoto employee={message.user} size='32px' />
    </div>
  </div>
)

export default UserMessage
