import { ConversationType } from 'components/ai/chat/ask_ai_modal/types'
import useFetch from 'components/common/hooks/useFetch'
import { useState } from 'react'
import API from 'services/api'

const useConversations = ({
  fetchConversations,
}: {
  fetchConversations: boolean
}) => {
  const [conversations, setConversations] = useState<ConversationType[]>([])

  const {
    paginationData,
    isLoading,
    isLoaded,
    callApi: fetch,
  } = useFetch<ConversationType[]>(
    params => API.ai.chats.conversations.fetchAll({ ...params, perPage: 15 }),
    [],
    {
      load: fetchConversations,
      onSuccess: (data) => {
        setConversations(currentConversations => _.sortBy(_.uniqBy([...currentConversations, ...data], 'id'), 'createdAt').reverse())
      },
    }
  )

  const hasMore = !!(
    paginationData?.page
    && paginationData?.totalPages
    && paginationData.page < paginationData.totalPages
  )

  const onLoadMore = () => {
    fetch({ page: (paginationData?.page || 0) + 1 })
  }

  return {
    data: conversations,
    isLoading,
    isLoaded,
    hasMore,
    onLoadMore,
  }
}

export default useConversations
