import moment from 'moment'

const checkEventForErrors = (event, fieldName, momentDateObject, setFieldError, resetError) => {
  switch (fieldName) {
  case 'startedAt': {
    if (momentDateObject?.isAfter(moment(event.eventTime))) {
      setFieldError('startedAt')
    } else {
      resetError('startedAt')
    }
    break
  }
  case 'lockedAt': {
    if (momentDateObject?.isBefore(moment(event.startedAt))) {
      setFieldError('lockedAt')
    } else {
      resetError('lockedAt')
    }
    break
  }
  case 'archivedAt': {
    if (momentDateObject?.isBefore(moment(event.lockedAt))) {
      setFieldError('archivedAt')
    } else {
      resetError('archivedAt')
    }
    break
  }
  default:
    break
  }
}

export default checkEventForErrors
