import React, { ReactNode, useState } from 'react'
import InfoTooltip from 'components/common/infoTooltip'
import { I18NCommon, i18nFormat, i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.integrations_page.search_section')

interface BloomfireInstallationInterface {
  onSubmit: (bloomfireCredentials) => void,
  isSaving: boolean,
  docLink: string,
}

const BloomfireInstallationComponent = ({ onSubmit, isSaving, docLink }: BloomfireInstallationInterface) => {
  const [domainUrl, setDomainUrl] = useState('')
  const [apiKey, setApiKey] = useState('')

  const handleDomainUrlChange = (event) => {
    let input = event.target.value

    // Remove trailing slash ('/') if present
    if (input.endsWith('/')) {
      input = input.slice(0, -1)
    }

    // Check if the input starts with 'https://', if not, add 'https://'
    const normalizedValue = input.startsWith('https://')
      ? input
      : 'https://' + input

    setDomainUrl(normalizedValue)
  }


  const handleOnSubmit = () => {
    const payload = {
      domainUrl, apiKey,
    }
    onSubmit(payload)
  }

  const TooltipText: ReactNode = i18nFormat(
    I18N('bloomfire_integration_doc_tooltip'),
    <a href={docLink} rel='noopener noreferrer' target='_blank'>{I18N('here')}</a>
  )

  return (
    <form>
      <div className='form-group'>
        <label className='required-form-label'>
          {I18N('bloomfire_domain')}
          <InfoTooltip text={TooltipText} />
        </label>
        <input
          type='url'
          className='flex-grow-1 mr-2 form-control'
          value={domainUrl}
          onChange={handleDomainUrlChange}
        />
      </div>
      <div className='form-group'>
        <label className='required-form-label'>
          {I18N('api_key')}
        </label>
        <input
          className='flex-grow-1 mr-2 form-control'
          value={apiKey}
          onChange={({ target }) => setApiKey(target.value)}
        />
      </div>
      <div className='text-right'>
        <ButtonSmallNarrow
          className='my-1'
          onClick={handleOnSubmit}
          disabled={isSaving || !domainUrl || !apiKey}
          showLoadingSpinner={isSaving}
          id='bloomfire-install-form-btn'
        >
          {I18NCommon('install')}
        </ButtonSmallNarrow>
      </div>
    </form>
  )
}

export default BloomfireInstallationComponent
