import useApi from 'components/common/hooks/useApi'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { useEffect } from 'react'
import API from 'services/api'

const useSetItemFromQueryParams = (updateItem) => {
  const { articleId } = useQueryParams()
  const [fetchArticle] = useApi(API.admin.articles.fetch)


  useEffect(() => {
    const fetchArticleAndUpdateItem = async (articleId) => {
      if (articleId) {
        const response = await fetchArticle(articleId)

        if (response) {
          updateItem(response)
        }
      }
    }

    fetchArticleAndUpdateItem(articleId)
  }, [articleId])
}

export default useSetItemFromQueryParams
