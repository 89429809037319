import useCompletedTargetingRules from 'components/analytics/helpers/useCompletedTargetingRules'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import userSlice from 'redux/slices/users'
import TargetingRulesType from 'types/audience/targetingRules'
import { TARGET_NO_ONE } from 'utils/normalizeTargetingRules'

const useTaskFilters = () => {
  const dispatch = useDispatch()

  const [toUserFilter, setToUserFilter] = useQueryParamState({
    param: 'toUserFilter',
    initialValue: null,
  })

  const [forUserFilter, setForUserFilter] = useQueryParamState({
    param: 'forUserFilter',
    initialValue: null,
  })

  const [searchQuery, setSearchQuery] = useQueryParamState({
    param: 'searchQuery',
    initialValue: null,
  })

  const selectedToUserFilter = useSelector(userSlice.selectors.getSimpleUserByUsername(toUserFilter))
  const selectedForUserFilter = useSelector(userSlice.selectors.getSimpleUserByUsername(forUserFilter))

  const [toUserTargetingRules, setToUserTargetingRules] = useState<TargetingRulesType>(TARGET_NO_ONE)
  const completedToUserTargetingRules = useCompletedTargetingRules(toUserTargetingRules)

  const [forUserTargetingRules, setForUserTargetingRules] = useState<TargetingRulesType>(TARGET_NO_ONE)
  const completedForUserTargetingRules = useCompletedTargetingRules(forUserTargetingRules)


  useEffect(() => {
    const users = []
    if (toUserFilter && !selectedToUserFilter?.id) {
      users.push(toUserFilter)
    }

    if (forUserFilter && !selectedForUserFilter?.id) {
      users.push(forUserFilter)
    }

    if (users.length > 0) {
      dispatch(userSlice.asyncActions.simpleFetchAll(
        [], () => {},
        { includeInactiveUsers: true, includeHiddenUsers: true },
        users
      ))
    }
  }, [toUserFilter, forUserFilter])

  return {
    toUserFilter,
    setToUserFilter,
    selectedToUserFilter,
    forUserFilter,
    setForUserFilter,
    selectedForUserFilter,
    searchQuery,
    setSearchQuery,
    toUserTargetingRules,
    setToUserTargetingRules,
    completedToUserTargetingRules,
    forUserTargetingRules,
    setForUserTargetingRules,
    completedForUserTargetingRules,
  }
}

export default useTaskFilters
