import { PromptCollectionType } from 'components/ai/chat/ask_ai_modal/types'
import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import LoadingSpinner from 'components/common/loadingSpinner'
import PromptCollectionForm from 'pages/admin/cleary_ai_settings/promptCollectionPage/promptCollectionForm'
import React from 'react'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.cleary_ai_settings.prompt_collection_page')


const EditPromptCollectionPage = () => {
  const { id } = useParams()

  const [updatePromptCollection, { isLoading: isSaving }] = useApi(
    API.admin.ai.chat.promptCollections.update,
    {
      toastSuccessMessage: I18N('prompt_collection_updated'),
      toastErrorMessage: I18NCommon('generic_error'),
    }
  )

  const {
    data: promptCollection,
    isLoaded,
    isLoading,
  } = useFetch<PromptCollectionType>(API.admin.ai.chat.promptCollections.fetch, [id])

  return (
    <div className='EditPromptCollectionPage'>
      <header className='AdminHeader'>
        <h3>{I18N('edit_prompt_collection')}</h3>
      </header>

      {!isLoaded || isLoading ? (
        <LoadingSpinner />
      ) : (
        <PromptCollectionForm
          onSubmit={updatePromptCollection}
          isSaving={isSaving}
          initialWorkingCopy={promptCollection}
        />
      )}
    </div>
  )
}

export default EditPromptCollectionPage
