import React from 'react'

import SunIcon from 'components/icons/sunIcon'
import MoonIcon from 'components/icons/moonIcon'
import { i18nMoment } from 'utils/i18nHelpers'


const MoonOrSunIcon = ({ timezone, width = 24, height = 24 }) => {
  const businessBegins = 8
  const businessEnds = 20

  // Default to the hour of the current time
  let hour = parseInt(i18nMoment().format('k'))
  try {
    hour = parseInt(i18nMoment().tz(timezone).format('k'))
  } catch (e) {
    if (e instanceof TypeError) {
      // Don't show an icon if we can't determine the hour
      return null
    }
  }

  if (hour >= businessBegins && hour < businessEnds) {
    return <SunIcon width={width} height={height} />
  }

  return <MoonIcon width={width} height={height} />
}

export default MoonOrSunIcon
