import React from 'react'

import {
  RESULTS_TAB,
  QUESTIONS_TAB,
  INDIVIDUAL_RESPONSES_TAB
} from 'components/admin/surveys/formHeader'
import EditableContent from 'components/common/editable_content'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import { i18nPath } from 'utils/i18nHelpers'
import Breadcrumbs from 'components/admin/headers/breadcrumbs'
import FormTaskHeaderButtons from 'components/admin/journeys/taskTemplates/surveys/formTaskHeaderButtons'
import FormTaskSettingsDropdown from 'components/admin/journeys/taskTemplates/surveys/formTaskSettingsDropdown'
import isValidQuestion from 'utils/surveys/isValidQuestion'
import surveyFormSlice, { buildFormPayload } from 'redux/slices/surveys/forms'
import { useDispatch } from 'react-redux'

export const TASK_TAB = 'task'

const I18N = i18nPath('views.admin.surveys')

const FormTaskHeader = ({
  taskTemplate,
  workingCopy,
  journeyBlueprint,
  form,
  formWorkingCopy,
  handleNameSave,
  hasChanges,
  handleSave,
}) => {
  const dispatch = useDispatch()
  const stepTemplate = taskTemplate?.stepTemplate || {}
  const name = stepTemplate?.name
  const isDraft = stepTemplate?.state === 'draft'

  const saveDraftChanges = () => {
    if (!isDraft) return

    const questions = formWorkingCopy?.questions || []
    const hasValidQuestions = questions.length > 0 && questions?.every(q => isValidQuestion(q))
    const hasFormChanges = !_.isEqual(buildFormPayload(form), buildFormPayload(formWorkingCopy))

    if (hasChanges) {
      handleSave('draft')
    }

    if (hasValidQuestions && hasFormChanges) {
      dispatch(surveyFormSlice.asyncActions.admin.update(formWorkingCopy, form))
    }
  }

  return (
    <header className='AdminHeader FormTaskHeader d-flex flex-column pb-0 w-100 mb-0'>
      <div className='d-flex align-items-center justify-content-between'>
        <Breadcrumbs
          breadcrumbs={[
            { text: journeyBlueprint?.name, path: `${journeyBlueprint?.adminDisplayPath}/edit` },
            {
              text: (
                <div className='d-flex align-items-center'>
                  <EditableContent
                    canEdit
                    displayElement={<h6 className='mb-0 text-secondary font-weight-600'>{name}</h6>}
                    inputElement={<input />}
                    valueToEdit={name}
                    saveContent={handleNameSave}
                  />
                </div>
              ),
              path: null,
            },
          ]}
        />
        <div className='d-flex align-items-center justify-content-between'>
          <FormTaskHeaderButtons
            taskTemplate={taskTemplate}
            workingCopy={workingCopy}
            form={form}
            formWorkingCopy={formWorkingCopy}
            hasChanges={hasChanges}
            handleSave={handleSave}
          />
          <FormTaskSettingsDropdown
            form={form}
            taskTemplate={taskTemplate}
          />
        </div>
      </div>
      <HistoryChangingPillTabSelector
        className='mt-3 justify-content-center'
        tabs={[
          { id: QUESTIONS_TAB, text: I18N('tabs.questions'), onClick: saveDraftChanges },
          { id: TASK_TAB, text: I18N('tabs.task'), onClick: saveDraftChanges },
          { id: RESULTS_TAB, text: I18N('tabs.results'), hidden: isDraft },
          { id: INDIVIDUAL_RESPONSES_TAB, text: I18N('tabs.individual_responses'), hidden: isDraft },
        ]}
        pillClasses='text-normal'
      />
    </header>
  )
}

export default FormTaskHeader
