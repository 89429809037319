import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

import MiniLoadingSpinner from 'components/common/miniLoadingSpinner'
import { _ } from 'core-js'

const I18N = i18nPath('views.admin.user_import_detail')

const RelatedUsersFromFailedNamelyImportErrors = ({ rowData }) => {
  const [relatedUsers, setRelatedUsers] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const checkForRelatedUsersFromFailedNamelyImportRecord = (rowData) => {
    const recordEmail = _.get(rowData, 'record.email')
    const recordEmployeeId = _.get(rowData, 'record.employeeId')

    setIsLoading(true)
    setRelatedUsers(null)

    API.admin.userImports.relatedUsersFromFailedNamelyImportRecord(recordEmail, recordEmployeeId).then((response) => {
      const users = denormalizedJsonApiResponse(response, 'user')

      setIsLoading(false)
      setRelatedUsers(users)
    })
  }

  useEffect(() => {
    if (!rowData) return

    checkForRelatedUsersFromFailedNamelyImportRecord(rowData)
  }, [rowData])


  if (isLoading) {
    return (
      <div>
        {I18N('user_previously_imported_loading_message')}
        <MiniLoadingSpinner />
      </div>
    )
  }

  if (!relatedUsers || _.isEmpty(relatedUsers)) {
    return null
  }

  return (
    <>
      <p>{I18N('user_previously_imported_error_summary')}:</p>
      <ul>
        {relatedUsers.map(user => (
          <li key={user.employeeId}>
            <strong>
              <Link to={`/admin/users/${user.id}`} target='_blank'>
                {user.preferredFullName}
              </Link>
            </strong>
            <dl className='row'>
              <dt className='col-3'>{I18N('user_email')}</dt>
              <dd className='col-9 mb-0'>{user.email}</dd>

              <dt className='col-3'>{I18N('user_employee_id')}</dt>
              <dd className='col-9 mb-0'>{user.employeeId}</dd>

              <dt className='col-3'>{I18N('exists_in_namely')}</dt>
              <dd className='col-9 mb-0'>{user.virtualFields?.existsInNamely}</dd>
            </dl>
          </li>
        ))}
      </ul>
      <p>{I18N('user_previously_imported_error_action_text')}</p>
    </>
  )
}

export default RelatedUsersFromFailedNamelyImportErrors
