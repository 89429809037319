import React from 'react'

import { I18NCommon, i18nMoment } from 'utils/i18nHelpers'
import Tooltip from 'components/common/clearyTooltip'

interface AutosaveTagProps {
  lastUpdatedAt: string
  areChangesPresent: boolean
}

const AutosaveTag = ({
  lastUpdatedAt,
  areChangesPresent,
}: AutosaveTagProps) => {
  if (areChangesPresent) {
    return <p className='text-secondary text-small mb-0 font-italic'>{I18NCommon('saving')}</p>
  }

  return (
    <Tooltip
      content={
        <p className='text-secondary text-small mb-0 font-italic'>{I18NCommon('changes_saved')}</p>
      }
      placement='bottom'
    >
      <span className='text-smallest'>{`${I18NCommon('changes_saved')} ${i18nMoment(lastUpdatedAt).fromNow()}`}</span>
    </Tooltip>
  )
}

export default AutosaveTag
