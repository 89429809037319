import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NumberPercentageToggle, { NUMBER, PERCENTAGE } from 'components/analytics/common/numberPercentageToggle'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import MultilineGraph from 'components/analytics/common/multilineGraph'
import searchAnalyticsSlice from 'redux/slices/analytics/search'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { dateLabels } from 'components/analytics/helpers/dateLabelFormat'
import TargetingRulesType from 'types/audience/targetingRules'
import API from 'services/api'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'

const I18N = i18nPath('views.platform_analytics.search.usage')

const EXPORT_EVENT_NAMES = [
  'search:clicked_result',
  'search:clicked_see_all',
  'search:results',
  'go_link:view_all',
  'go_link:visit',
].join(',')

interface SearchUsageProps {
  periodStart: string
  periodEnd?: string
  groupBy: string
  targetingRules?: TargetingRulesType
}

const SearchUsage = ({
  periodStart,
  periodEnd,
  groupBy,
  targetingRules,
}: SearchUsageProps) => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()
  const [selectedType, setSelectedType] = useState(NUMBER)

  const { data: allUsageData } = useSelector(searchAnalyticsSlice.selectors.getAllUsage())
  const { data: usageByEventTypeData } = useSelector(searchAnalyticsSlice.selectors.getUsageByEventType())
  const { highlightColor } = currentCompany.customStyles.variables.colors

  const {
    allUsage: { isLoading: isLoadingAllUsage },
    usageByEventType: { isLoading: isLoadingUsageByEventType },
  } = useSelector(searchAnalyticsSlice.selectors.getMetaData())

  const isLoading = isLoadingAllUsage || isLoadingUsageByEventType

  const colors = {
    search: '#EE4D76',
    go_link: '#611f69',
    all: highlightColor,
  }

  useEffect(() => {
    dispatch(searchAnalyticsSlice.asyncActions.admin.fetchUsage(
      'usageByEventType',
      {
        periodStart, periodEnd, groupBy, groupByEventType: true, type: selectedType, targetingRules,
      }
    ))

    dispatch(searchAnalyticsSlice.asyncActions.admin.fetchUsage(
      'allUsage',
      {
        periodStart, periodEnd, groupBy, type: selectedType, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, groupBy, selectedType, targetingRules])

  const data = [
    ...allUsageData.map(d => ({ ...d, eventType: 'all' })),
    ...usageByEventTypeData,
  ]

  const dataGrouped = Object.values(_.groupBy(data, 'eventType'))

  const xlabels = dataGrouped.length > 0 ? dateLabels(dataGrouped[0], groupBy) : []

  const lines = dataGrouped.map(data => ({
    label: I18N(`labels.${data[0].eventType}`),
    color: colors[data[0].eventType || 'all'],
    values: data.map(({ value }) => value),
    tooltipLabel: count => `${I18N(`labels.${data[0].eventType}`)}: ${I18NCommon('unique_users_count', { count: selectedType === PERCENTAGE ? count + '%' : count })}`,
  }))

  const yAxisLabelKey = [
    'y_axis_label',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const onExport = (email: string) => {
    const exportXlsxParams = {
      periodStart,
      periodEnd,
      targetingRules,
      groupBy,
      eventNames: EXPORT_EVENT_NAMES,
      email,
      title: I18N('xlsx_title'),
    }

    return API.admin.analytics.engagement.exportXlsx(exportXlsxParams)
  }

  return (
    <div className='analytics-card grid-span-12'>
      <header className='d-flex flex-row justify-content-between pb-1'>
        <h3>{I18N('title')}</h3>
        <div className='d-flex flex-row align-items-center'>
          <NumberPercentageToggle selectedType={selectedType} setSelectedType={setSelectedType} />

          <ExportXlsxButton
            className='ml-1'
            onSubmit={onExport}
          />
        </div>
      </header>

      <MultilineGraph
        xlabels={xlabels}
        lines={lines}
        yAxisLabel={I18N(yAxisLabelKey)}
        isLoading={isLoading}
        asPercentage={selectedType === PERCENTAGE}
        height='250px'
      />
    </div>
  )
}

export default SearchUsage
