import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'
import Setting from 'pages/admin/user_settings/setting'
import FeedAndExternalAlertsInput from 'pages/admin/user_settings/inputs/feedAndExternalAlertsInput'


const CELEBRATION_SETTINGS = [
  'badges.received_by_other_user',
  'shoutouts.received_by_other_user',
  'celebrations.other_user_birthday',
  'celebrations.other_user_work_anniversary',
  'celebrations.other_user_new_employee_welcome',
  'celebration.other_celebrations_other_user',
]


const UserSettingsCelebrationsTab = () => (
  <>
    <h3>{I18NCommon('celebrations')}</h3>

    {CELEBRATION_SETTINGS.map(setting => (
      <Setting
        key={setting}
        name={setting}
        inputComponent={FeedAndExternalAlertsInput}
      />
    ))}
  </>
)

export default UserSettingsCelebrationsTab
