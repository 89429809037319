import React from 'react'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import EmployeeSearch from 'components/form_fields/employeeSearch'

const FormEmployeesField = ({
  fieldName,
  label,
  currentValue,
  isRequired,
  footNote = null,
  onChange = () => { },
  className = '',
  placeholder = '',
  disabled = false,
  isMulti = false,
}) => {
  const employeeProps = isMulti ? { selectedEmployees: currentValue } : { selectedEmployee: currentValue }

  return (
    <LabeledFormFieldContainer
      label={label || fieldName}
      isRequired={isRequired}
      footNote={footNote}
      className={className}
    >
      <EmployeeSearch
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        isMulti={isMulti}
        {...employeeProps}
      />
    </LabeledFormFieldContainer>
  )
}

export default FormEmployeesField
