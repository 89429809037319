import React, { useState } from 'react'

import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'

import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import useWindowResize from 'components/common/hooks/useWindowResize'

const I18N = i18nPath('views.article')

const ArticleDropdownMenu = (props) => {
  const [isSubscribed, setIsSubscribed] = useState(props.article.isSubscribed)
  // eslint-disable-next-line max-len
  const [isUserExcludedFromCommentNotifications, setIsUserExcludedFromCommentNotifications] = useState(props.article.isUserExcludedFromCommentNotifications)

  const { isSmallScreen } = useWindowResize()

  const handleSubscribeToArticleClick = () => {
    API.articles.subscribe(props.article)
    setIsSubscribed(true)
  }

  const handleUnsubscribeToArticleClick = () => {
    API.articles.unsubscribe(props.article)
    setIsSubscribed(false)
  }

  const handleTurnOnNotificationsClick = () => {
    API.articles
      .includeInCommentNotifications(props.article)
      .then(() => setIsUserExcludedFromCommentNotifications(false))
  }

  const handleTurnOffNotificationsClick = () => {
    API.articles
      .excludeFromCommentNotifications(props.article)
      .then(() => setIsUserExcludedFromCommentNotifications(true))
  }

  const renderNotificationsOption = (primaryText, onClickFunction) => (
    <CardDropdownMenuItem key={3} primaryText={primaryText} onClick={onClickFunction} />
  )

  const {
    article, handleExcludeArticleFromFeed,
  } = props
  const shouldShowTurnOnNotificationsOption = isUserExcludedFromCommentNotifications

  const tagNames = (article.tags || []).map(tag => tag.name).join(', ')
  const dropdownItems = []

  // exclude from feed action only appears when article card is in the feed
  if (handleExcludeArticleFromFeed) {
    dropdownItems.push(
      <CardDropdownMenuItem
        key={1}
        onClick={handleExcludeArticleFromFeed}
        primaryText={I18N('dropdown_menu.exclude_title')}
        subtext={I18N('dropdown_menu.remove_subtext', { tagNames })}
      />
    )
  }

  // subscribe actions always appear whether viewing in feed or via article page
  if (isSubscribed) {
    dropdownItems.push(
      <CardDropdownMenuItem
        key={2}
        onClick={handleUnsubscribeToArticleClick}
        primaryText={I18N('dropdown_menu.unsubscribe_title')}
        subtext={I18N('dropdown_menu.unsubscribe_subtext', { tagNames })}
      />
    )
  } else {
    dropdownItems.push(
      <CardDropdownMenuItem
        key={2}
        onClick={handleSubscribeToArticleClick}
        primaryText={I18N('dropdown_menu.subscribe_title')}
        subtext={I18N('dropdown_menu.subscribe_subtext', { tagNames })}
      />
    )
  }

  if (shouldShowTurnOnNotificationsOption) {
    dropdownItems.push(
      renderNotificationsOption(
        I18N('dropdown_menu.turn_on_notifications'),
        handleTurnOnNotificationsClick
      )
    )
  } else {
    dropdownItems.push(
      renderNotificationsOption(
        I18N('dropdown_menu.turn_off_notifications'),
        handleTurnOffNotificationsClick
      )
    )
  }

  // This is true if the user is an article manager or can edit
  // group articles. See app/serializers/article_serializer.rb
  if (article?.permittedActions?.edit && !isSmallScreen) {
    let link = ''
    const articleEditorLink = `/admin/articles/${article.id}`
    const teamPostEditorLink = `/posts/${article.id}`
    const isTeamPost = article.groupId

    if (isTeamPost) {
      link = teamPostEditorLink
    } else {
      link = articleEditorLink
    }

    dropdownItems.push(
      <CardDropdownMenuItem
        key={4}
        primaryText={I18N('dropdown_menu.view_as_admin')}
        link={link}
        toggleContent='•••'
      />
    )
  }

  // dont render the dropdown menu if no items present
  if (dropdownItems.length === 0) {
    return null
  }

  return (
    <div className='position-absolute top-0 right-0'>
      <DropdownMenuContainer>{dropdownItems}</DropdownMenuContainer>
    </div>
  )
}

export default ArticleDropdownMenu
