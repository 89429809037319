import React, { useState } from 'react'

import CreateStepTypeSelectModal from 'components/admin/journeys/modals/createStepTypeSelectModal'
import { Button } from 'components/common/buttons'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.journeys.table.add_custom_step')

export const AddCustomStepHeader = () => (
  <div className='AddCustomStepHeader'>
    <div className='header' />
    <div className='title bordered-bottom'>
      <span className='text-secondary text-small'>{I18N('create')}</span>
    </div>
    <div className='empty-container' />
  </div>
)

const AddCustomStepButton = ({ journey, journeyBlueprint }) => {
  const [showSelectStepTypeModal, setShowSelectStepTypeModal] = useState(false)
  const { preferredFullName } = journey?.user

  return (
    <>
      <HoverAndClickPopover
        target={(
          <div className='d-flex justify-content-center align-items-center'>
            <Button
              className='AddCustomStepButton'
              onClick={() => setShowSelectStepTypeModal(true)}
              variant='secondary'
            >
              <span>+</span>
            </Button>
          </div>
        )}
        popoverClassName='AddCustomStepPopover'
        id={journey?.id}
        placement='bottom'
      >
        <div className='p-2 text-center'>
          <span className='text-white text-smallest'>{I18N('popover', { userName: preferredFullName })}</span>
        </div>
      </HoverAndClickPopover>

      {showSelectStepTypeModal && (
        <CreateStepTypeSelectModal
          visible={showSelectStepTypeModal}
          toggle={() => setShowSelectStepTypeModal(false)}
          journeyBlueprintId={journeyBlueprint?.id}
          journeyBlueprintType={journeyBlueprint?.type}
          isCustomStep
          journeyId={journey?.id}
        />
      )}
    </>
  )
}

export default AddCustomStepButton
