import LayoutCollectionAudienceSelectorModal from 'components/admin/layout/layoutCollectionAudienceSelectorModal'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import useApi from 'components/common/hooks/useApi'
import DeleteModal from 'components/common/modals/deleteModal'
import React, { useState } from 'react'
import API from 'services/api'
import { LayoutCollectionType, PageTypeEnum } from 'types/layout/layoutCollection'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

type Props = {
  layoutCollection: LayoutCollectionType | null
  onDelete: () => void
  updateLayoutCollection: (layoutCollection: LayoutCollectionType) => void
  isListPage?: boolean
}

const DELETE_MODAL = 'delete_modal'
const AUDIENCE_MODAL = 'audience_modal'

const I18N = i18nPath('views.admin.layout_collections_dropdown')

const LayoutCollectionDropdown = ({
  layoutCollection,
  onDelete,
  isListPage = false,
  updateLayoutCollection,
}: Props
) => {
  const [currentOpenModal, setCurrentOpenModal] = useState<string | null>(null)
  const closeModal = () => setCurrentOpenModal(null)
  const [startPreview, { isLoading: isStartingPreview }] = useApi(API.admin.preboarding.previews.start, {
    onSuccess: () => window.open('/', '_blank'),
  })

  const [deleteLayoutCollection, { isLoading: isDeleting }] = useApi(
    () => API.admin.layout.layoutCollections.destroy(layoutCollection),
    {
      toastSuccessMessage: I18N('delete_modal.layout_collection_deleted'),
      toastErrorMessage: I18N('delete_modal.error_deleting_layout_collection'),
      onSuccess: () => {
        closeModal()
        onDelete()
      },
    }
  )

  const isPreboardingLayoutCollection = layoutCollection?.pageType === PageTypeEnum.Preboarding

  if (_.isEmpty(layoutCollection)) return (
    <DropdownMenuContainer
      menuType='kebab'
      className='LayoutCollectionDropdown default-dropdown'
    />
  )

  return (
    <>
      <DropdownMenuContainer
        menuType='kebab'
        className='LayoutCollectionDropdown default-dropdown'
      >
        <section>
          {isPreboardingLayoutCollection ? (
            <CardDropdownMenuItem
              onClick={startPreview}
              primaryText={I18NCommon('preview')}
              icon={<CdnSvg className='mr-2' src='/images/eyeOpenIcon.svg' />}
              disabled={isStartingPreview}
            />
          ) : (
            <CardDropdownMenuItem
              link={`/home_preview/${layoutCollection?.id}`}
              primaryText={I18NCommon('preview')}
              icon={<CdnSvg className='mr-2' src='/images/eyeOpenIcon.svg' />}
            />
          )}
          {isListPage && (
            <CardDropdownMenuItem
              className='Card-dropdownMenuItem'
              link={layoutCollection?.adminDisplayPath}
              primaryText={I18NCommon('edit')}
              icon={<CdnSvg src='/images/pencilIcon.svg' className='mr-2' />}
            />
          )}
          <CardDropdownMenuItem
            className='Card-dropdownMenuItem'
            onClick={() => setCurrentOpenModal(AUDIENCE_MODAL)}
            primaryText={I18N('manage_audience')}
            icon={<CdnSvg src='/images/personsIcon.svg' className='mr-2' />}
            disabled={layoutCollection?.default}
            tooltipText={layoutCollection?.default ? I18N('default_layout_collection_audience_cannot_be_updated') : undefined}
          />
        </section>

        <section>
          <CardDropdownMenuItem
            className='Card-dropdownMenuItem--danger'
            onClick={() => setCurrentOpenModal(DELETE_MODAL)}
            primaryText={I18NCommon('delete')}
            icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
            disabled={layoutCollection?.default}
            tooltipText={layoutCollection?.default ? I18N('default_layout_collection_cannot_be_deleted') : undefined}
          />
        </section>
      </DropdownMenuContainer>

      {currentOpenModal === AUDIENCE_MODAL && (
        <LayoutCollectionAudienceSelectorModal
          layoutCollection={layoutCollection}
          isOpen
          onClose={closeModal}
          onSave={updateLayoutCollection}
        />
      )}
      {currentOpenModal === DELETE_MODAL && (
        <DeleteModal
          deleteText={I18N('delete_modal.delete_text')}
          deleteSecondaryText={I18N('delete_modal.delete_secondary_text')}
          deleteConfirm={deleteLayoutCollection}
          showDeleteModal
          closeDeleteModal={closeModal}
          isDeleting={isDeleting}
        />
      )}
    </>
  )
}

export default LayoutCollectionDropdown
