import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

const CharsRemaining = ({ maxLength, text }) => (
  <div>
    <div className='CharsRemaining text-muted text-small text-right'>
      {I18NCommon('characters_remaining', { numCharactersRemaining: maxLength - text.length })}
    </div>
  </div>
)

export default CharsRemaining
