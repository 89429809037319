import React, { useState } from 'react'

import ShoutoutCompanyValue from 'components/shoutouts/shoutoutCompanyValue'

const ShoutoutCompanyValues = ({
  companyValues,
  shoutoutId,
  group = false,
  shownCompanyValues = 4,
}) => {
  const [isExpanded, setIsExpanded] = useState(!group)
  if (!companyValues?.length) return <div className='mb-2' />

  const companyValuesToShow = isExpanded ? companyValues : companyValues.slice(0, shownCompanyValues)
  const companyValuesNotDisplayed = Math.max(companyValues.length - shownCompanyValues, 0)

  return (
    <div className='ShoutoutCompanyValues d-flex flex-wrap align-items-center my-4 cursor-default max-width-max-content mr-auto'>
      {companyValuesToShow.map(companyValue => (
        <ShoutoutCompanyValue companyValue={companyValue} shoutoutId={shoutoutId} key={companyValue.id} />
      ))}

      {companyValuesNotDisplayed > 0 && !isExpanded && (
        <div onClick={() => setIsExpanded(true)} className='ShowMore'>
          <span className='text-smallest text-secondary'>
            +{companyValuesNotDisplayed}
          </span>
        </div>
      )}
    </div>
  )
}

export default ShoutoutCompanyValues
