import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import ArticleThumbnailImage from 'components/admin/articles/articleThumbnailImage'
import AcknowledgementBanner from 'components/articles/acknowledgementBanner'
import ArticleByLine from 'components/articles/articleByLine'
import ArticleType from 'components/articles/articleType'
import SocialShareButton from 'components/common/social_share/socialShareButton'

const extractSocialShareData = (htmlContent) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlContent, 'text/html')

  const socialShares = doc.querySelectorAll('div.social-share')

  if (socialShares.length === 0) return { url: '', suggestedText: '' }

  // Theoretically there could be more than one social share
  // It is unlikely and in those cases we will get the first one for the widget.
  const firstSocialShare = socialShares[0]
  const url = firstSocialShare.getAttribute('data-url') || ''
  const suggestedText = firstSocialShare.getAttribute('data-suggested-text') || ''

  return { url, suggestedText }
}

const getSocialShareData = (article) => {
  const { hasSocialShare, cardContent, content } = article
  if (!hasSocialShare) return { url: '', suggestedText: '' }

  const { url: cardContentUrl, suggestedText: cardContentSuggestedText } = extractSocialShareData(cardContent)
  const { url: contentUrl, suggestedText: contentSuggestedText } = extractSocialShareData(content)

  return { url: cardContentUrl || contentUrl, suggestedText: cardContentSuggestedText || contentSuggestedText }
}

const ArticleItem = ({ article, widgetWidth = 850 }) => {
  const { hasSocialShare, articleType, authors } = article

  const isMustRead = !!article.acknowledgementDueAt
  const showAcknowledgementBanner = isMustRead && article.acknowledgementInfo?.status !== 'acknowledged'

  const { url, suggestedText } = useMemo(() => getSocialShareData(article), [article.id])
  const authorsCount = authors?.length || 0
  const showMentionedUsers = (widgetWidth > 650) || (widgetWidth > 550 && authorsCount > 2)
  const hideByLine = widgetWidth < 650 && hasSocialShare

  const alwaysShowAuthorCount = widgetWidth <= 550 && authorsCount > 1

  return (
    <Link key={article.id} to={article.displayPath} className='ArticleItem p-2'>
      <ArticleThumbnailImage article={article} />
      {showAcknowledgementBanner ? (
        <AcknowledgementBanner article={article} />
      ) : (
        <ArticleType articleType={articleType} className='text-small' />
      )}
      <div className='ArticleItem__content'>
        <h4 className='ArticleItem__title truncate-text-at-2-lines font-weight-700'>{article.title}</h4>
        {!hideByLine && (
          <ArticleByLine
            article={article}
            isCard
            alwaysShowMentionedUsersCount
            alwaysShowAuthorCount={alwaysShowAuthorCount}
            showMentionedUsers={showMentionedUsers}
          />
        )}
      </div>

      {hasSocialShare && (
        <SocialShareButton
          articleId={article.id}
          url={url}
          suggestedText={suggestedText}
          variant='secondary'
        />
      )}
    </Link>
  )
}

export default ArticleItem
