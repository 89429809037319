import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import searchSlice from 'redux/slices/search'
import DangerousHTML from 'components/common/dangerousHTML'
import { extractHighlights, extractTagTextFromString } from 'components/common/utils'
import snakeCaseKeys from 'utils/snakeCaseKeys'
import qs from 'qs'

const SearchResultTemplate = ({
  displayPath,
  image,
  pretitle = undefined,
  title,
  subtitle,
  description = undefined,
  descriptionExtraMargin = false,
  openNewTab = false,
  result,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(searchSlice.asyncActions.trackResultClicked('pageAll', result))

    const searchHighlights = extractHighlights([title, subtitle, description])

    const path = `${displayPath}?${qs.stringify(snakeCaseKeys(searchHighlights))}`

    if (openNewTab) {
      window.open(path)
    } else {
      history.push(path)
    }
  }

  return (
    <div className='SearchResultTemplate d-flex' onClick={handleClick}>
      <div className='ImgContainer d-flex'>
        {image}
      </div>
      <div className='d-flex flex-column'>
        <div className='text-small text-secondary truncate-text-at-1-lines'>{pretitle}</div>
        <DangerousHTML
          className='truncate-text-at-1-lines link-color'
          options={{ ALLOWED_TAGS: ['em'] }}
        >
          {title}
        </DangerousHTML>
        {
          typeof subtitle === 'string' ? (
            <DangerousHTML
              className='text-small text-secondary truncate-text-at-1-lines'
              options={{ ALLOWED_TAGS: ['em'] }}
            >
              {subtitle}
            </DangerousHTML>
          ) : (
            subtitle
          )
        }
        {
          typeof description === 'string' ? (
            <DangerousHTML
              className={classNames('text-small text-secondary truncate-text-at-1-lines', descriptionExtraMargin ? 'mt-2' : '')}
              options={{ ALLOWED_TAGS: ['em'] }}
            >
              {description}
            </DangerousHTML>
          ) : (
            description
          )
        }
      </div>
    </div>
  )
}

export default SearchResultTemplate
