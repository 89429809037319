import React from 'react'
import { Link } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import BaseLayout from 'components/layout/baseLayout'
import TicketListPageSidebar from 'pages/admin/tickets/ticketListPage/ticketListPageSidebar'

const I18N = i18nPath('views.admin.tickets')

type Ticket = {
  id: string
  title: string
  status: string
  createdAt: string
}

const TicketListPageContent = ({
  isLoading,
  tickets,
  toggleSidebarComponent,
  isSidebarOpen,
}: {
  isLoading: boolean
  tickets: Ticket[]
  toggleSidebarComponent?: React.ReactNode
  isSidebarOpen?: boolean
}) => (
  <div className='TicketListPage'>
    <header className='AdminHeader d-flex justify-content-between'>

      <div>
        <h3 className='mb-0'>
          {!isSidebarOpen && toggleSidebarComponent}
          {I18N('tickets')}
        </h3>
      </div>
      <Link to='/admin/tickets/new'>
        <Button>
          {I18N('create_new_ticket')}
        </Button>
      </Link>
    </header>
    <main className='AdminContent'>
      <LoadingContainer isLoading={isLoading}>
        <table className='white-bg-table'>
          <thead>
            <tr>
              <th>{I18N('title')}</th>
              <th>{I18N('status')}</th>
              <th>{I18N('created')}</th>
            </tr>
          </thead>
          <tbody>
            {(tickets || []).map(ticket => (
              <tr key={`ticket-${ticket.id}`}>
                <td>
                  <Link to={`/admin/tickets/${ticket.id}`}>
                    <span>{ticket.title}</span>
                  </Link>
                </td>
                <td>{ticket.status}</td>
                <td>{ticket.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </LoadingContainer>
    </main>
  </div>
)

const TicketListPage = () => {
  // const { isLoading, data: tickets } = useFetch(API.admin.tickets.fetchAll)

  const isLoading = false
  const tickets: Ticket[] = Array.from({ length: 100 }, (_, index) => ({
    id: `${index + 1}`,
    title: `Ticket ${index + 1}`,
    status: index % 2 === 0 ? 'open' : 'closed',
    createdAt: `2024-01-${String(index + 1).padStart(2, '0')}`,
  }))

  return (
    <BaseLayout sidebar={<TicketListPageSidebar />}>
      <TicketListPageContent isLoading={isLoading} tickets={tickets} />
    </BaseLayout>
  )
}

export default TicketListPage
