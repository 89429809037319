import React, { useMemo } from 'react'

import Card from 'components/common/card'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import DangerousHTML from 'components/common/dangerousHTML'
import calendarEventDisplayTime from 'utils/calendarEventDisplayTime'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import moment from 'moment'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'


const I18N = i18nPath('views.home')

const CalendarWidget = ({ widget, resizeWidget }) => {
  const { data: events, isLoading, isLoaded } = useFetch(() => API.calendars.fetchUpcomingEvents(25))

  return (
    <Card className='CalendarWidget HomePageWidget'>
      <WidgetTitle title={I18N('company_calendar')} />

      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        isLoaded={isLoaded}
        showScrollbarOutside
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <EventsByMonth events={events} />
        )}
      </AutoResizableScrollableSection>
    </Card>
  )
}

const EventsByMonth = ({ events }) => {
  // break down events into months and weeks, end result looks something like:
  // {
  //   'May 2022': [ ... events in this month ] },
  //   'June 2022': [ ... events in this month ] },
  // }
  const eventsByMonth = useMemo(() => (
    _.groupBy(events, event => i18nMoment(event.start).format('MMMM YYYY'))
  ), [events])
  const eventsByMonthEntries = Object.entries(eventsByMonth)

  if (eventsByMonthEntries.length === 0) {
    return (
      <div className='text-center'>
        <span className='text-secondary'>{I18N('no_upcoming_events')}</span>
      </div>
    )
  }

  return (
    <div className='d-flex flex-column'>
      {eventsByMonthEntries.map(([name, events], i) => (
        <React.Fragment key={`month-${name}`}>
          <div className='font-weight-600 text-secondary mb-1'>{name}</div>
          <div className='d-flex flex-column gap-3 mb-3'>
            {events.map((event, i) => <CalendarEvent key={i} event={event} />)}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

const CalendarEvent = ({ event }) => {
  const {
    calendarName, summary, start, end, htmlLink, backgroundColor, isAllDay,
  } = event

  return (
    <div
      className='CalendarEvent p-2 d-flex align-items-center gap-3'
      style={{ backgroundColor }}
    >
      <div className='DayBlock flex-shrink-0 bg-white d-flex flex-column justify-content-center align-items-center'>
        <h4 className='mb-0'>{moment(start).format('DD')}</h4>
        <div>{moment(start).format('ddd').toUpperCase()}</div>
      </div>
      <div>
        <a
          href={htmlLink}
          className='calendar-link text-primary-link truncate-text-at-2-lines mb-1'
          target='_blank'
          rel='noopener noreferrer'
        >
          <DangerousHTML>{summary}</DangerousHTML>
        </a>
        <div className='text-small text-secondary truncate-text-at-2-lines'>
          {calendarEventDisplayTime(start, end, isAllDay)}
          {calendarName && ` • ${calendarName}`}
        </div>
      </div>
    </div>
  )
}

export default CalendarWidget
