import React from 'react'

import classNames from 'classnames'

import IcebreakerTemplateList from 'components/qna/icebreakerTemplateList'
import GetToKnowYouIcon from 'components/icons/qna/getToKnowYouIcon'
import HobbiesIcon from 'components/icons/qna/hobbiesIcon'
import TravelIcon from 'components/icons/qna/travelIcon'
import FoodIcon from 'components/icons/qna/foodIcon'
import FamilyIcon from 'components/icons/qna/familyIcon'
import PetsIcon from 'components/icons/qna/petsIcon'
import EntertainmentIcon from 'components/icons/qna/entertainmentIcon'
import SportsGamesIcon from 'components/icons/qna/sportsGamesIcon'
import WorkIcon from 'components/icons/qna/workIcon'
import IfYouCouldIcon from 'components/icons/qna/ifYouCouldIcon'
import HolidaysIcon from 'components/icons/qna/holidaysIcon'
import SpringIcon from 'components/icons/qna/springIcon'
import useWindowResize, { WINDOW_SIZE_MD, WINDOW_SIZE_SM } from 'components/common/hooks/useWindowResize'

const questionTypeIcons = {
  get_to_know_you: <GetToKnowYouIcon />,
  hobbies: <HobbiesIcon />,
  travel: <TravelIcon />,
  food: <FoodIcon />,
  family: <FamilyIcon />,
  pets: <PetsIcon />,
  entertainment: <EntertainmentIcon />,
  sports_games: <SportsGamesIcon />,
  work: <WorkIcon />,
  if_you_could: <IfYouCouldIcon />,
  for_the_holidays: <HolidaysIcon />,
  spring: <SpringIcon />,
}

/**
 *  This component allows for the question templates to display underneath
 *  the row of the selected type by chunking into groups of 2, 4 or 5. For example:
 * __________  __________  __________  __________  __________
 * | (Active)| |         | |         | |         | |         |
 * | Type 1  | | Type 2  | | Type 3  | | Type 4  | | Type 5  |
 * |_________| |_________| |_________| |_________| |_________|
 *
 * - Question template 1 (Type 1)
 * - Question template 2 (Type 1)
 * - Question template 3 (Type 1)
 * __________  __________  __________  __________  __________
 * |         | |         | |         | |         | |         |
 * | Type 6  | | Type 7  | | Type 8  | | Type 9  | | Type 10 |
 * |_________| |_________| |_________| |_________| |_________|
 */

const calculateItemsPerRow = (width) => {
  if (!width || width > WINDOW_SIZE_MD) {
    return 5
  }

  if (width > WINDOW_SIZE_SM) {
    return 4
  }

  return 2
}

const IcebreakerTypesList = ({
  types,
  selectedType,
  handleTypeSelection,
  questionsOfSelectedType,
  handleTemplateSelection,
}) => {
  const { width } = useWindowResize()

  const itemsPerRow = calculateItemsPerRow(width)

  return (
    <div className='d-flex flex-column align-items-center'>
      {_.chunk(types, itemsPerRow).map(typesRow => ( // Split into groups of itemsPerRow
        <React.Fragment key={typesRow.toString()}>
          <div
            className='d-flex mb-3 gap-3 justify-content-around'
            style={{ width: `${(100 * typesRow.length) / itemsPerRow}%` }}
          >
            {typesRow.map((type, i) => (
              <div
                key={`icebreaker-type-${i}`}
                className={classNames({
                  'Icebreaker-template-type pointer bordered d-flex flex-column align-items-center justify-content-center': true,
                  'Icebreaker-template-type--selected': type === selectedType,
                  'Icebreaker-template-type--not-selected': selectedType && type !== selectedType,
                })}
                onClick={() => handleTypeSelection(type)}
              >
                <span style={{ minHeight: '2.5rem' }}>{questionTypeIcons[_.snakeCase(type)]}</span>
                <span className='mt-3 text-capitalize text-small'>{type}</span>
              </div>
            ))}
          </div>
          {typesRow.includes(selectedType) && (
            <IcebreakerTemplateList
              selectedType={selectedType}
              questionsOfSelectedType={questionsOfSelectedType}
              handleTemplateSelection={handleTemplateSelection}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default IcebreakerTypesList
