import React from 'react'
import classNames from 'classnames'
import SmartTable from 'components/common/tables/smartTable'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

interface CardWithTableProps {
  title: string
  tableColumns: any[]
  tableData: any[]
  classname?: string
  isLoading?: boolean
  showPagination: boolean
  page: number
  totalPages: number
  onPaginationClick: (value: any) => void
  onSortChange?: (value: any) => void
  sortKey?: string
  sortedAsc?: boolean
  headerActions?: React.ReactNode
  children?: React.ReactNode
}

const CardWithPaginatedTable = ({
  title,
  tableColumns,
  tableData,
  classname,
  isLoading,
  showPagination = false,
  page,
  totalPages,
  onPaginationClick,
  onSortChange,
  sortKey,
  sortedAsc,
  headerActions,
  children,
}: CardWithTableProps) => (
  <div className={classNames('analytics-card', classname)}>
    <header className='d-flex flex-row justify-content-between pb-1'>
      <h3>{title}</h3>
      {headerActions}
    </header>
    {isLoading ? (
      <CirclesLoadingIndicator className='w-100' />
    ) : (
      <>
        {children}

        <SmartTable
          columns={tableColumns}
          data={tableData}
          className='analytics-table'
          showPagination={showPagination}
          page={page}
          pages={totalPages}
          onPaginationClick={onPaginationClick}
          onSortChange={onSortChange}
          sortKey={sortKey}
          sortedAsc={sortedAsc}
        />
      </>
    )}
  </div>
)

export default CardWithPaginatedTable
