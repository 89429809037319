import React from 'react'

import classNames from 'classnames'
import moment from 'moment'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

const I18N = i18nPath('views.qna.events')

const EventTimeStatus = ({ className, event }) => {
  const {
    isActive,
    isArchived,
    isLockedAndAcceptingAnswers,
    isUpcoming,
  } = event

  return (
    <div className={classNames('EventTimeStatus w-100', className)}>
      {isActive && (
        <div>
          <p className='mb-0 text-small text-sm-normal'>{I18N('accepting_questions_until')}</p>
          <p className='mb-0 link-color'>{i18nMoment(moment(event.lockedAt)).format('llll')}</p>
        </div>
      )}

      {isUpcoming && (
        <div>
          <p className='mb-0 text-small text-sm-normal'>{I18N('open_for_questions_starting')}</p>
          <p className='mb-0 link-color'>{i18nMoment(moment(event.startedAt)).format('llll')}</p>
        </div>
      )}

      {isLockedAndAcceptingAnswers && (
        <div>
          <p className='mb-0 text-small text-sm-normal'>{I18N('accepting_answers_and_comments_until')}</p>
          <p className='mb-0 link-color'>{i18nMoment(moment(event.archivedAt)).format('llll')}</p>
        </div>
      )}

      {isArchived && (
        <div>
          <p className='mb-0 text-small text-sm-normal'>{I18N('archived_on')}</p>
          <p className='mb-0 link-color'>{i18nMoment(moment(event.archivedAt)).format('llll')}</p>
        </div>
      )}
    </div>
  )
}

export default EventTimeStatus
