import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import { isAudienceValid } from 'utils/audience'

import ArticleTypeForm from 'components/admin/articleTypes/articleTypeForm'
import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { buildArticleTypePayload } from 'redux/slices/articleTypes'
import useFetch from 'components/common/hooks/useFetch'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.article_type_editor')

const EditArticleTypePage = () => {
  const history = useHistory()
  const { articleTypeId } = useParams()

  const targetingOptions = useTargetingOptions()

  const { data: fetchedArticleType, isLoading } = useFetch(API.admin.articleTypes.fetch, [articleTypeId], {
    onSuccess: (response) => { setArticleTypeForm(response) },
  })

  const [updateArticleType, {
    isLoading: isSaving, error, isNotFound, data: updatedArticleType,
  }] = useApi(API.admin.articleTypes.update, {
    updateEntitySlice: true,
    toastSuccessMessage: I18N('article_type_updated'),
  })

  const articleType = updatedArticleType || fetchedArticleType

  const [deleteArticleType] = useApi(API.admin.articleTypes.destroy, {
    onSuccess: () => history.push('/admin/article_types'),
  })

  const [articleTypeForm, setArticleTypeForm] = useState({
    ...articleType,
  })

  const changesPresent = !_.isEqual(buildArticleTypePayload(articleType), buildArticleTypePayload(articleTypeForm))
  const isSaveButtonDisabled = !changesPresent || isSaving || !isAudienceValid(articleTypeForm)

  const onUpdate = () => updateArticleType(buildArticleTypePayload(articleTypeForm))

  const onDelete = () => {
    if (confirm(I18N('delete_confirm'))) {
      deleteArticleType(articleType)
    }
  }

  return (
    <div className='EditArticleTypePage'>
      <header className='AdminHeader'>
        <h3>{I18N('edit_title')}</h3>
      </header>

      <main className='AdminContent'>
        <LoadingContainer isLoading={isLoading} isNotFound={isNotFound} useCirclesLoadingIndicator>
          <>
            <div className='row'>
              <ArticleTypeForm
                error={error}
                setArticleType={setArticleTypeForm}
                articleType={articleTypeForm}
                targetingOptions={targetingOptions}
              />
            </div>

            <div className='row'>
              <div className='form-group col'>
                <Button disabled={isSaveButtonDisabled} onClick={onUpdate} showLoadingSpinner={isSaving}>
                  {I18N('update_article_type')}
                </Button>
                <Button variant='danger' className='ml-2' onClick={onDelete}>
                  {I18N('delete')}
                </Button>
              </div>
            </div>
          </>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default EditArticleTypePage
