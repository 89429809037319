import React from 'react'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.journeys.journey_blueprints')

const JourneyBlueprintEmptyList = ({ onClickCreateNewJourney }) => (
  <div className='blueprint-empty-list-container d-flex flex-row w-100 p-5 mb-3 justify-content-center align-items-center background-color-white'>
    <CdnSvg src='/images/illustrations/backpacker.svg' className='BackpackerIcon mr-5' />
    <div className='d-flex flex-column'>
      <h4>{I18N('empty_title')}</h4>
      <span className='description font-weight-400 text-secondary'>
        {I18N('empty_description')}
      </span>
      <Button className='mt-4' onClick={onClickCreateNewJourney}>{I18N('create_new_journey')}</Button>
    </div>
  </div>
)

export default JourneyBlueprintEmptyList
