import React, { useEffect } from 'react'

import MultilineGraph from 'components/analytics/common/multilineGraph'
import { dateLabels } from 'components/analytics/helpers/dateLabelFormat'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { useDispatch, useSelector } from 'react-redux'
import moduleAnalyticsSlice from 'redux/slices/analytics/modules'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import TargetingRulesType from 'types/audience/targetingRules'

const I18N = i18nPath('views.platform_analytics.overview.module_usage')

const MODULE_TO_COLOR_MAP = {
  org_chart: '#5246D7',
  profile: '#FFA25E',
  news: '#EE4D76',
  page: '#4F79F0',
  go_link: '#221B69',
  event: '#33B766',
  shoutout: '#90AAF9',
  celebration: '#F7B731',
  search: '#E66767',
  team: '#706FD3',
  badge: '#45AAF2',
}

interface ModuleUsageProps {
  groupBy: string
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const ModuleUsage = ({
  groupBy, periodStart, periodEnd, targetingRules,
}: ModuleUsageProps) => {
  const currentCompany = useCurrentCompany()

  const { data } = useSelector(moduleAnalyticsSlice.selectors.getUsage())
  const { usage: { isLoading } } = useSelector(moduleAnalyticsSlice.selectors.getMetaData())
  const { highlightColor } = currentCompany.customStyles.variables.colors

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(moduleAnalyticsSlice.asyncActions.admin.fetchUsage({
      groupBy,
      periodStart,
      periodEnd,
      targetingRules,
    }))
  }, [groupBy, periodStart, periodEnd, targetingRules])


  const moduleColor = appModule => (appModule === 'all_modules' ? highlightColor : MODULE_TO_COLOR_MAP[appModule])

  const xlabels = data.length > 0 ? dateLabels(data[0].data, groupBy) : []

  const lines = data.map(usageModule => ({
    label: usageModule.appModule === 'team' ? currentCompany.pluralGroupDisplayName : I18N(`labels.${usageModule.appModule}`),
    color: moduleColor(usageModule.appModule),
    values: usageModule.data.map(({ value }) => value),
    tooltipLabel: count => `${I18N(`labels.${usageModule.appModule}`)}: ${I18NCommon('unique_users_count', { count })}`,
  }))

  const yAxisLabelKey = [
    'y_axis_label',
    targetingRules ? 'audience' : 'overall',
  ].join('.')


  return (
    <div className='ModuleUsage analytics-card grid-span-12'>
      <header>
        <h3>{I18N('title')}</h3>
      </header>

      <MultilineGraph
        xlabels={xlabels}
        lines={lines}
        yAxisLabel={I18N(yAxisLabelKey)}
        isLoading={isLoading}
        height='330px'
      />
    </div>
  )
}

export default ModuleUsage
