import { AssistantAnswerType } from 'types/ai/assistantAnswer'
import TargetingRulesType from 'types/audience/targetingRules'
import { SimpleUserType } from 'types/user'

export interface AiMessageType {
  id: string
  content: string
  role: 'assistant'
  aiAssistantAnswer?: AssistantAnswerType
  createdAt: string
}

export interface GeneratingAiMessageType {
  id: string
  message?: string
  role: 'assistant'
}

export interface UserMessageType {
  id: string
  content: string
  role: 'user'
  user: SimpleUserType
  createdAt: string
}

export enum AskAiTab {
  CHAT = 'chat',
  HISTORY = 'history',
}

export type ConversationType = {
  id: string
  title: string
  createdAt: string
  user?: SimpleUserType
}

export type PromptCollectionType = {
  id: string
  name: string
  prompts: string[]
  targetingRules: TargetingRulesType
}

export type MessageType = AiMessageType | GeneratingAiMessageType | UserMessageType
