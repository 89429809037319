import { useEffect, useRef } from 'react'
import { SSE } from 'sse.js'
import { useForwardObjectToLatestCallback } from 'components/common/hooks/useForwardToLatestCallback'

type Props = {
  listeners?: Record<string, (event: MessageEvent) => void>,
  options?: Record<string, any>,
  listen?: boolean,
  dependencies?: any[],
}

const useSSE = (endpoint: string, {
  listeners = {},
  options = {},
  listen = true,
  dependencies = [],
}: Props) => {
  const eventListeners = useForwardObjectToLatestCallback(listeners)
  const eventSource = useRef<SSE | null>(null)

  useEffect(() => {
    if (!listen) { return () => {} }

    eventSource.current?.close()
    eventSource.current = new SSE(endpoint, options)

    eventSource.current.addEventListener('error', (event) => {
      eventListeners.error?.(event)

      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.current!.close()
      }
    })

    Object.keys(eventListeners).forEach((event) => {
      eventSource.current!.addEventListener(event, eventListeners[event])
    })

    return () => {
      eventSource.current?.close()
      eventSource.current = null
    }
  }, [listen, ...dependencies])

  return {
    eventSource,
  }
}

export default useSSE
