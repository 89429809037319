import { ExternalSyncImportState } from 'components/admin/integrations/external_sync/types'
import useActionCable from 'components/common/hooks/useActionCable'
import useApi from 'components/common/hooks/useApi'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import API from 'services/api'

type UseExternalFileImportProps = {
  sourceId: string
}

type NotificationProgressData = {
  imported_documents_count: number
  total_documents_count: number
}

type NotificationCompletedData = {
  completed: boolean
}

type NotificationData = NotificationProgressData | NotificationCompletedData

const isNotificationProgressData = (data: NotificationData): data is NotificationProgressData => (
  'imported_documents_count' in data && 'total_documents_count' in data
)

type ReturnType = {
  importedDocumentsCount: number
  totalDocumentsCount: number
  state: ExternalSyncImportState
  totalSyncedTimes: number
  onResyncStarted: () => void
  importDocuments: (sourceId: string, externalFileIds: string[]) => void
}

const useExternalFileImport = ({ sourceId }: UseExternalFileImportProps): ReturnType => {
  const { externalFileIds } = useQueryParams()
  const [importedDocumentsCount, setImportedDocumentsCount] = useState(0)
  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0)
  const [state, setState] = useState<ExternalSyncImportState>(ExternalSyncImportState.Idle)
  const [totalSyncedTimes, setTotalSyncedTimes] = useState(0)

  const [importDocuments] = useApi(API.admin.externalSync.documents.import, {
    onSuccess: () => {
      setState(ExternalSyncImportState.Importing)
    },
  })
  const history = useHistory()

  useActionCable({
    channel: 'ExternalSync::SourceChannel',
    recordId: sourceId,
    recordType: 'ExternalSync::Source',
    isActive: true,
    onDataReceived: (data: NotificationData) => {
      if (isNotificationProgressData(data)) {
        setTotalDocumentsCount(data.total_documents_count)
        setImportedDocumentsCount(data.imported_documents_count)
        setState(ExternalSyncImportState.Importing)
      } else {
        // timeout to avoid race conditions
        setTimeout(() => {
          setState(ExternalSyncImportState.Imported)
          setTotalSyncedTimes(totalSyncedTimes => totalSyncedTimes + 1)

          // Hide the confirmation message after 5 seconds
          setTimeout(() => {
            setState(ExternalSyncImportState.Idle)
          }, 5000)
        }, 1000)
      }
    },
  })

  useEffect(() => {
    if (externalFileIds && externalFileIds.length > 0) {
      setState(ExternalSyncImportState.Importing)
      importDocuments(sourceId, externalFileIds)
      // Clear the query param after importing
      history.replace(history.location.pathname)
    }
  }, [JSON.stringify(externalFileIds)])

  const onResyncStarted = () => {
    setTotalDocumentsCount(0)
    setImportedDocumentsCount(0)
    setState(ExternalSyncImportState.Importing)
  }

  return {
    importedDocumentsCount,
    totalDocumentsCount,
    state,
    totalSyncedTimes,
    onResyncStarted,
    importDocuments,
  }
}

export default useExternalFileImport
