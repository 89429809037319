import { createSlice } from '@reduxjs/toolkit'
import pageVariables from 'utils/pageVariables'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

const { currentCompany } = pageVariables
const denormalizedCurrentCompany = currentCompany && denormalizedJsonApiResponse({ data: currentCompany }, 'company')

const initialState = denormalizedCurrentCompany
  ? { ...denormalizedCurrentCompany }
  : {}

const currentCompanySlice = createSlice({
  name: 'currentCompany',
  initialState,
  reducers: {
    updateCurrentCompanySetting: (state, action) => {
      const { key, value } = action.payload
      _.set(state.settings, key, value)
    },
  },
})

export default currentCompanySlice

