import React from 'react'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

type Props = {
  color?: 'red' | 'green' | 'yellow' | 'orange' | 'blue'
  children: React.ReactNode,
  className?: string,
  onClose?: () => void
}

const AdminBanner = ({
  color = 'green', // one of: red, green, yellow, orange, blue.
  children,
  className = 'p-3 w-100 d-inline-flex align-items-center justify-content-center',
  onClose,
}: Props) => (
  <span className={classNames(`AdminBanner position-relative banner-${color}`, className)}>
    {children}
    {onClose && (
      <div
        className='banner-close position-absolute top-0 bottom-0 right-0 mr-2 pointer h-100'
        onClick={onClose}
      >
        <CdnSvg className='d-flex align-items-center h-100' src='/images/xIcon.svg' />
      </div>
    )}
  </span>
)

export default AdminBanner
