import React, { useState } from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'

import classNames from 'classnames'

import FormDatePickerField from 'components/form_fields/formDatePickerField'
import FormEditableField from 'components/form_fields/formEditableField'
import FormTimezoneSelectField from 'components/form_fields/formTimezoneSelectField'
import FormSelectField from 'components/form_fields/formSelectField'
import FormEmployeesField from 'components/form_fields/formEmployeesField'
import FormTiptapEditorField from 'components/form_fields/formTiptapEditorField'
import GroupSelectField from 'components/form_fields/groupSelectField'
import moment from 'moment'

const options = [1, 2, 3].map(value => ({ value, label: `Option ${value}` }))

const FormFieldsPage = () => {
  const [textField, setTextField] = useState('')
  const [html, setHtml] = useState('')
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [selectedDate, setSelectedDate] = useState(moment('2020-02-20T23:30:00Z'))
  const [selectedTimezone, setSelectedTimezone] = useState('America/Los_Angeles')


  return (
    <main className='Styleguide FormFieldsPage container mt-4'>
      <h1><StyleguideBaseLink /> / Form Fields</h1>
      <section className='mt-4'>
        <table className='table'>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Component Name</th>
              <th>Description</th>
              <th style={{ width: '40%' }}>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                FormEditableField
              </td>
              <td>
                With placeholder, fieldName, isRequired and footNote
              </td>
              <td>
                <FormEditableField
                  currentValue={textField}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  footNote='footNote'
                  isRequired
                  onChange={e => setTextField(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                FormEditableField
              </td>
              <td>
                When textarea true
              </td>
              <td>
                <FormEditableField
                  currentValue={textField}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  footNote='footNote'
                  isRequired
                  textarea
                  onChange={e => setTextField(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                FormEditableField
              </td>
              <td>
                When disabled
              </td>
              <td>
                <FormEditableField
                  currentValue={textField}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  footNote='footNote'
                  isRequired
                  disabled
                  onChange={e => setTextField(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                FormSelectField
              </td>
              <td>
                When its a select field
              </td>
              <td>
                <FormSelectField
                  currentValue={selectedOption}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  footNote='footNote'
                  options={options}
                  onChange={option => setSelectedOption(option)}
                />
              </td>
            </tr>
            <tr>
              <td>
                FormSelectField
              </td>
              <td>
                When its a creatable field and not clearable
              </td>
              <td>
                <FormSelectField
                  currentValue={selectedOption}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  footNote='footNote'
                  options={options}
                  onChange={option => setSelectedOption(option)}
                  isCreatable
                  isClearable={false}
                />
              </td>
            </tr>
            <tr>
              <td>
              FormEmployeesField
              </td>
              <td>
                When isMulti is false
              </td>
              <td>
                <FormEmployeesField
                  currentValue={selectedEmployee}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  onChange={setSelectedEmployee}
                  footNote='footNote'
                />
              </td>
            </tr>
            <tr>
              <td>
              FormEmployeesField
              </td>
              <td>
                When isMulti is true
              </td>
              <td>
                <FormEmployeesField
                  currentValue={selectedEmployees}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  onChange={setSelectedEmployees}
                  footNote='footNote'
                  isMulti
                />
              </td>
            </tr>
            <tr>
              <td>
              FormTiptapEditorField
              </td>
              <td>
                With default toolbar
              </td>
              <td>
                <FormTiptapEditorField
                  currentValue={html}
                  fieldName='fieldName'
                  onChange={setHtml}
                  footNote='footNote'
                  editorConfiguration={{ placeholder: 'placeholder' }}
                />
              </td>
            </tr>
            <tr>
              <td>
                FormDatePickerField
              </td>
              <td>
                With placeholder, fieldName and isRequired false
              </td>
              <td>
                <FormDatePickerField
                  currentValue={selectedDate}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  onChange={setSelectedDate}
                  footNote='footNote'
                />
              </td>
            </tr>
            <tr>
              <td>
              FormTimezoneSelectField
              </td>
              <td>
                With placeholder, fieldName
              </td>
              <td>
                <FormTimezoneSelectField
                  currentValue={selectedTimezone}
                  fieldName='fieldName'
                  placeholder='placeholder'
                  onChange={value => setSelectedTimezone(value.timezone)}
                  footNote='footNote'
                />
              </td>
            </tr>
            <tr>
              <td>
                GroupSelectField
              </td>
              <td />
              <td>
                <GroupSelectField />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default FormFieldsPage
