import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import Modal from 'components/common/modal'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import { useSelector } from 'react-redux'
import ReactSelect from 'components/common/react_select'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.pages.actions_dropdown')

const ChangePageWorkspaceModal = ({
  className = '',
  onClose,
  visible,
  page,
}) => {
  const currentWorkspaceId = page?.pageWorkspaceId
  const workspaces = useSelector(pageWorkspaceSlice.selectors.getPageWorkspaces())
  const pageEditorWorkspaces = workspaces.filter(
    workspace => workspace?.permittedActions?.canCreateAndMovePagesInWorkspace
      && workspace.id !== currentWorkspaceId
  )

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(pageEditorWorkspaces[0]?.id)

  const [updatePage, { isLoading }] = useApi(API.pages.update, {
    updateEntitySlice: true,
    onSuccess: onClose,
    toastSuccessMessage: I18N('page_updated'),
  })

  const updatePageWorkspace = () => {
    updatePage({
      id: page.id,
      pageWorkspaceId: selectedWorkspaceId,
      parentId: null,
    })
  }

  return (
    <Modal size='lg' className={classNames('ChangePageWorkspaceModal', className)} visible={visible} toggle={onClose}>
      <div className='px-4 mb-4'>
        <h4 className='text-left font-weight-600'>{I18N('move_page')}</h4>
        <span className='text-secondary'>{I18N('move_to_another_workspace_text')}</span>
        <div className='my-3'>
          <label className='font-weight-500'>{I18N('select_workspace')}</label>
          <ReactSelect
            className=''
            value={pageEditorWorkspaces.find(option => option.id === selectedWorkspaceId)}
            onChange={option => setSelectedWorkspaceId(option?.id)}
            options={pageEditorWorkspaces}
            isSearchable
            isClearable={false}
            getOptionLabel={option => option.title}
            getOptionValue={option => option.id}
          />
        </div>
        <div className='d-flex justify-content-end align-items-center mt-5'>
          <CancelButton onClick={onClose} className='mr-3 text-secondary font-weight-600'>
            {I18N('cancel')}
          </CancelButton>
          <ButtonNarrow className='copySaveButton' onClick={updatePageWorkspace} showLoadingSpinner={isLoading} disabled={isLoading}>
            {I18N('save')}
          </ButtonNarrow>
        </div>
      </div>
    </Modal>
  )
}

export default ChangePageWorkspaceModal
