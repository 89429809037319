import useFetch from 'components/common/hooks/useFetch'
import useCanSignCelebration from 'components/celebrations/hooks/useCanSignCelebration'
import API from 'services/api'


const useLoadPendingUsers = (celebration) => {
  const canSignCelebration = useCanSignCelebration(celebration)
  const { hasCommented } = celebration

  const shouldLoad = canSignCelebration && hasCommented

  const {
    data: pendingUsers,
    removeItems: removePendingUsers,
  } = useFetch(API.celebrations.fetchUsersWithPendingSignatures, [celebration.id], { load: shouldLoad })

  const showSendRemindersButton = shouldLoad && !_.isEmpty(pendingUsers)

  return {
    showSendRemindersButton,
    removePendingUsers,
    pendingUsers,
  }
}

export default useLoadPendingUsers
