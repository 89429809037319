import React from 'react'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'

const I18N = i18nPath('views.journeys.taskable_detail')

const AssignedByLine = ({
  fromUser,
  fromCorrespondent,
  triggerAt,
}) => (
  <span className='text-small text-secondary mt-3 d-inline-flex align-items-center'>
    {I18N('assigned_by')}
    <EmployeeOrCorrespondentLink
      className='ml-2 mr-2'
      user={fromUser}
      correspondent={fromCorrespondent}
      textClassName='text-small'
      linkToAdmin={false}
    />
    {I18N('on_date', { date: i18nMoment(triggerAt).format('ll') })}
  </span>
)

export default AssignedByLine
