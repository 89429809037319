import React, { useState, useEffect } from 'react'

import { Button } from 'components/common/buttons'
import CalendarEventsAsyncSelect from 'components/common/calendarEventsAsyncSelect'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import FormSelectField from 'components/form_fields/formSelectField'

import {
  i18nPath,
  I18NCommon,
  i18nFormat
} from 'utils/i18nHelpers'

import { URL_PATTERN_REGEX } from 'utils/constants'
import API from 'services/api'
import appSignal from 'services/appSignal'
import InfoTooltip from 'components/common/infoTooltip'

const I18N = i18nPath('views.admin.journeys.calendar_event_templates')

const calendarIdTutorialUrl = 'https://docs.google.com/document/d/1gDo3cfs6u56JmHGkCvJQhnKsZHTeSFVbv91jIWvEUds/'
const googleEventUrlTutorialUrl = 'https://docs.google.com/document/d/10BEBuIDlc541OVwbtzAjG15miWueUFMFM0Z-TpxfTQw/'

const CalendarIdLabel = () => (
  <>
    <label className='required-form-label'>
      {I18N('calendar_id')}
    </label>
    <InfoTooltip
      text={i18nFormat(I18N('calendar_id_tooltip'),
        <a target='_blank' rel='noopener noreferrer' href={calendarIdTutorialUrl}>{I18N('here')}</a>
      )}
      tooltipClassName='WhiteToolTip'
    />
  </>
)

const ExternalEventEditUrlLabel = () => (
  <>
    <label className='form-label'>
      {I18N('google_calendar_event_url')}
    </label>
    <InfoTooltip
      text={i18nFormat(I18N('google_calendar_event_url_tooltip'),
        <a target='_blank' rel='noopener noreferrer' href={googleEventUrlTutorialUrl}>{I18N('here')}</a>
      )}
      tooltipClassName='WhiteToolTip'
    />
  </>
)

const GoogleCalendarEventSection = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled = false,
}) => {
  const [isParsingCalendarUrl, setIsParsingCalendarUrl] = useState(false)
  const [externalCalendarEventUrl, setExternalEventEditUrl] = useState(workingCopy.externalCalendarEventUrl)

  const hasExternalEventUrlChanged = externalCalendarEventUrl !== workingCopy?.externalCalendarEventUrl

  const externalCalendarId = workingCopy.externalCalendarId || ''

  const currentCalendarIdValue = externalCalendarId ? { label: externalCalendarId, value: externalCalendarId } : null

  const onExternalCalendarIdChange = (option) => {
    updateWorkingCopy({
      externalCalendarId: option?.value || '',
      externalEventId: '',
      externalEventName: '',
      externalCalendarEventUrl: '',
    })
  }

  const onExternalEventChange = (option) => {
    updateWorkingCopy({
      externalEventId: option?.value || '',
      externalEventName: option?.label || '',
      externalCalendarId: option?.externalCalendarId || '',
      externalCalendarEventUrl: option?.externalEventUrl || '',
    })
  }

  const onParseCalendarUrl = async () => {
    setIsParsingCalendarUrl(true)

    try {
      const response = await API.admin.calendars.fetchEvent(encodeURIComponent(externalCalendarEventUrl))
      const { data } = response
      const {
        calendarId, eventId, summary, eventUrl,
      } = data

      if (calendarId && eventId && summary) {
        updateWorkingCopy({
          externalCalendarId: calendarId,
          externalEventId: eventId,
          externalEventName: summary,
          externalCalendarEventUrl: eventUrl,
        })
      }
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
    } finally {
      setIsParsingCalendarUrl(false)
    }
  }

  useEffect(() => {
    setExternalEventEditUrl(workingCopy?.externalCalendarEventUrl)
  }, [workingCopy?.externalCalendarEventUrl])

  return (
    <div className='GoogleCalendarEventSection'>
      <LabeledFormFieldContainer
        label={<ExternalEventEditUrlLabel />}
      >
        <div className='d-flex'>
          <input
            className='w-100'
            disabled={isDisabled}
            value={externalCalendarEventUrl}
            onChange={e => setExternalEventEditUrl(e.target.value)}
            pattern={URL_PATTERN_REGEX}
          />
          <Button className='ml-2' disabled={!hasExternalEventUrlChanged} onClick={onParseCalendarUrl} showLoadingSpinner={isParsingCalendarUrl}>
            {I18NCommon('submit')}
          </Button>
        </div>
      </LabeledFormFieldContainer>

      {externalCalendarId && (
        <div className='external-calendars-grid'>
          <FormSelectField
            label={<CalendarIdLabel />}
            isRequired
            isDisabled={isDisabled}
            currentValue={currentCalendarIdValue}
            onChange={onExternalCalendarIdChange}
            formatCreateLabel={value => value}
            noOptionsMessage={() => I18N('type_calendar_id')}
            placeholder={I18N('enter_calendar_id')}
            isCreatable
          />
          <LabeledFormFieldContainer
            label={I18N('event_name')}
            isRequired
          >
            <CalendarEventsAsyncSelect
              calendarId={workingCopy.externalCalendarId}
              externalEventId={workingCopy.externalEventId}
              externalEventName={workingCopy.externalEventName}
              onChange={onExternalEventChange}
              isDisabled={isDisabled}
              noOptionsMessage={() => I18N('external_event_placeholder')}
            />
          </LabeledFormFieldContainer>
        </div>
      )}
    </div>
  )
}

export default GoogleCalendarEventSection
