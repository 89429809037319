import React from 'react'

import ViewInOrgchart from 'components/profiles/viewInOrgchart'
import SendShoutoutButton from 'components/shoutouts/sendShoutoutButton'

const ViewOrgChartAndSendShoutout = ({
  user, settings,
}) => {
  const displayViewInOrgChart = settings.orgChart.enabled && user.visibleInOrgChart

  return (
    <div className='ViewOrgChartAndSendShoutout mb-2'>
      {displayViewInOrgChart && <ViewInOrgchart username={user.username} />}

      <SendShoutoutButton user={user} />
    </div>
  )
}

export default ViewOrgChartAndSendShoutout
