import React from 'react'
import { useSelector } from 'react-redux'

import searchSlice from 'redux/slices/search'
import ReactSelect from 'components/common/react_select'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.search.search_results.filters')
const googleDriveIconPath = '/images/googleDriveIcon.svg'
const confluenceIconPath = '/images/confluence.svg'
const notionIconPath = '/images/notionIcon.svg'
const bloomfireIconPath = '/images/bloomfire.svg'

const getOption = (key, count, iconPath, labelText = null, hasMore = false) => {
  // dont render options with 0 count, except for all
  if (!count && key !== 'all') return null

  const label = (
    <div className='d-flex align-items-center'>
      {iconPath && <CdnSvg src={iconPath} className='mr-2 d-flex flex-1 align-items-center' />}
      <span>{I18N(labelText || key)} ({count}{hasMore ? '+' : ''})</span>
    </div>
  )

  return { value: key, label }
}

const MobileFilter = ({ onChange, selectedFilters }) => {
  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const { totalResultsByType, hasMoreByType } = useSelector(searchSlice.selectors.getMetaData('pageAll'))
  const topCount = totalResultsByType.top

  const clearyOptions = [
    getOption('all', topCount, null, I18N('top_results')),
    getOption('people', totalResultsByType.user),
    settings.pages.enabled && getOption('pages', totalResultsByType.page),
    settings.golinks.enabled && getOption('links', totalResultsByType.goLink),
    settings.news.enabled && getOption('articles', totalResultsByType.article),
    settings.profile.fields.userSkills && getOption('user_skills', totalResultsByType.userSkill),
    settings.groups.enabled && getOption('groups', totalResultsByType.group),
    settings.qna.enabled && getOption('qna', totalResultsByType.qnaEvent),
    settings.appLauncher?.enabled && getOption('apps', totalResultsByType.appLauncherApp),
    settings.globalSearch.attachedFileContents.enabled && getOption('attached_file_contents', totalResultsByType.attachedFileContent),
    settings.pages.enabled && settings.pages.faqs.enabled && getOption('page_faqs', totalResultsByType.pageFaq),
  ].filter(Boolean)

  const integrationOptions = [
    settings.googleDriveSearch.enabled && getOption('google_drive', totalResultsByType.googleDrive, googleDriveIconPath, null, hasMoreByType?.googleDrive),
    settings.confluenceSearch.enabled && getOption('confluence', totalResultsByType.confluence, confluenceIconPath),
    settings.notionSearch.enabled && getOption('notion', totalResultsByType.notion, notionIconPath),
    settings.bloomfireSearch.enabled && getOption(
      'bloomfire',
      totalResultsByType.bloomfire,
      settings.bloomfireSearch?.integrationIconPath || bloomfireIconPath,
      settings.bloomfireSearch?.integrationName || 'bloomfire'
    ),
  ].filter(Boolean)

  const allOptions = [...clearyOptions, ...integrationOptions]

  // selectedType refers to one of [all, people, links, pages, articles, ...]
  const selectedType = allOptions.find(option => option.value === selectedFilters?.type)

  return (
    <div className='MobileFilter'>
      <hr />
      <ReactSelect
        value={selectedType}
        onChange={({ value }) => onChange({ type: value })}
        options={[
          { label: I18N('search_filters'), options: clearyOptions },
          { label: I18N('integrations'), options: integrationOptions },
        ]}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--dynamic-color-primary-color-lightest--highlight-color)',
            primary25: null,
          },
        })}
      />
    </div>
  )
}

export default MobileFilter
