import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import GroupInfoPopover from 'components/common/groupInfoPopover'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.profile')

const ProfileGroups = ({ groupType }) => (
  <>
    <h6>{groupType.name}</h6>
    <span>
      {groupType.groups.map((group, i) => {
        const isNotLast = i !== groupType.groups.length - 1

        return (
          <div className='d-inline-block mt-1' key={`group-${group.id}`}>
            <GroupInfoPopover
              group={group}
            />
            {isNotLast && <span>,&nbsp;</span>}
          </div>
        )
      })}
    </span>
  </>
)

const I18NNumber = Intl.NumberFormat('en', { notation: 'compact' })

const cappedCountText = count => (count > 999 ? I18NNumber.format(count) : count)

const TeamSize = ({ count }) => {
  if (!count || count === 0) {
    return null
  }

  return <span className='TeamSize text-small mx-1 font-weight-600'>{cappedCountText(count)}</span>
}


const DirectReports = ({ user }) => {
  const currentCompany = useCurrentCompany()

  const showTeamSize = currentCompany.settings.profile.fields.teamSize

  return (
    <section className='PeopleList'>
      <h6>
        {I18N('direct_reports')}
        {showTeamSize && (
          <span className='ml-2 text-secondary font-weight-400'>
            {I18N('team_size')}
            <TeamSize count={user.descendantsCount} />
          </span>
        )}
      </h6>
      <span>
        {user.directReports.map((child, i) => {
          const isNotLast = i !== user.directReports.length - 1

          return (
            <div className='DirectReport d-inline-block mt-1' key={`user-directReport-${child.id}`}>
              <EmployeeLinkWithPopover user={child} id={`user-directReport-${child.id}`} hideImage />
              {showTeamSize && <TeamSize count={child.descendantsCount} />}
              {isNotLast && <span>,&nbsp;</span>}
            </div>
          )
        })}
      </span>
    </section>
  )
}

const EmployeeLinkWithPopoverList = ({
  users = [],
  label,
}) => (
  <section className='PeopleList'>
    <h6>{label}</h6>
    <span>
      {users.map((child, i) => {
        const isNotLast = i !== users.length - 1

        return (
          <div className='d-inline-block mt-1' key={`user-${_.kebabCase(label)}-${child.id}`}>
            <EmployeeLinkWithPopover user={child} id={`user-${_.kebabCase(label)}-${child.id}`} hideImage />
            {isNotLast && <span>,&nbsp;</span>}
          </div>
        )
      })}
    </span>
  </section>
)

const Groups = ({ user }) => {
  const { activeGroups } = user

  // Gets all groupTypes sorted by the order they should appear on the profile
  const sortedGroupTypes = _(activeGroups)
    .map(viewableGroup => ({ ...viewableGroup.groupType, groups: [] }))
    .uniqBy('id')
    .sortBy('orderPosition')
    .value()

  // Fill up all groupTypes with their respective groups
  sortedGroupTypes.forEach((groupType) => {
    groupType.groups = activeGroups.filter(group => group.groupType.id === groupType.id)
  })

  return (
    <>
      {sortedGroupTypes.map(groupType => (
        <section className='PeopleList' key={groupType.id}>
          <ProfileGroups groupType={groupType} />
        </section>
      ))}
    </>
  )
}

const TeamsAndRelationships = ({
  user,
  fieldSettings,
}) => {
  const shouldShowDirectReports = fieldSettings.directReports && !_.isEmpty(user.directReports)
  const shouldShowAssistants = !_.isEmpty(user.activeAssistants)
  const shouldShowAssistedUsers = !_.isEmpty(user.activeAssistedUsers)
  const { settings } = useCurrentCompany()

  return (
    <section className='ProfileTeamsAndRelationships'>
      {fieldSettings.manager && user.parent && (
        <section className='PeopleList' id='cy-profile-manager'>
          <h6>{I18N('manager')}</h6>
          <EmployeeLinkWithPopover user={user.parent} hideImage className='mt-1' />
        </section>
      )}
      {shouldShowDirectReports && (
        <DirectReports user={user} />
      )}
      {shouldShowAssistants && (
        <EmployeeLinkWithPopoverList
          users={user.activeAssistants}
          label={I18N('assisted_by')}
        />
      )}
      {shouldShowAssistedUsers && (
        <EmployeeLinkWithPopoverList
          users={user.activeAssistedUsers}
          label={I18N('assists')}
        />
      )}

      {settings.groups.enabled && <Groups user={user} />}
    </section>
  )
}

export default TeamsAndRelationships
