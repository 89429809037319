import React, { Dispatch } from 'react'

import FavoriteButton from 'components/app_launcher/favoriteButton'
import LinkAnchor from 'components/common/linkAnchor'
import SuggestedIcon from 'components/common/suggestedIcon'
import ForwardArrowIcon from 'components/icons/forwardArrowIcon'
import { trackAmplitudeEvent } from 'services/tracker'
import AppType from 'types/app'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.app_launcher')

interface AppItemProps {
  app: AppType
  dispatch: Dispatch<any>
  onClick: (app: AppType) => void
}

const AppLauncherListItem = ({ app, dispatch, onClick }: AppItemProps) => (
  <a
    href={app.url}
    target='_blank'
    rel='noopener noreferrer'
    className='AppListItem pointer d-flex justify-content-between p-3 align-items-center'
    onClick={() => onClick(app)}
  >
    <div className='d-flex flex-row align-items-center'>
      <SuggestedIcon name={app.name} url={app.url} iconUrl={app.iconUrl} height={24} width={24} />
      <p className='mb-0 truncate-text-at-1-lines'>{app.name}</p>
    </div>
    <FavoriteButton app={app} dispatch={dispatch} />
  </a>
)

const AppLauncherGridItem = ({ app, dispatch, onClick }: AppItemProps) => (
  <a
    href={app.url}
    target='_blank'
    rel='noopener noreferrer'
    className='AppGridItem pointer d-flex flex-column px-3 py-2 align-items-center'
    onClick={() => onClick(app)}
  >
    <FavoriteButton app={app} dispatch={dispatch} />
    <SuggestedIcon name={app.name} url={app.url} iconUrl={app.iconUrl} height={24} width={24} className='mb-2' />
    <p className='text-smallest text-secondary truncate-text-at-1-lines mb-0 text-center'>{app.name}</p>
  </a>
)

interface AppLauncherLayoutProps {
  apps: AppType[]
  history: any
  dispatch: Dispatch<any>
  showGridView: boolean
}

const AppLauncherLayout = ({
  apps,
  history,
  dispatch,
  showGridView,
}: AppLauncherLayoutProps) => {
  const onAppClick = (app: AppType) => {
    trackAmplitudeEvent('app_launched', {
      app_name: app.name,
      app_url: app.url,
    })
  }


  if (_.isEmpty(apps)) return (<p className='text-secondary d-flex justify-content-center m-3'>{I18N('empty')}</p>)

  const Component = showGridView ? AppLauncherGridItem : AppLauncherListItem

  return (
    <>
      <div className={showGridView ? 'AppGrid' : 'AppList'}>
        {apps.map(app => (<Component app={app} key={`app-${app.id}`} dispatch={dispatch} onClick={onAppClick} />))}
      </div>
      <LinkAnchor link='/apps' history={history} className='footer d-flex justify-content-center align-items-center p-3'>
        {I18N('footer')} <ForwardArrowIcon className='ml-2' fillColor='var(--link-color)' />
      </LinkAnchor>
    </>
  )
}

export default AppLauncherLayout
