import React, { useState } from 'react'
import PagesNav from 'components/pages/pagesNav'
import {
  Switch, Route, useLocation, useParams
} from 'react-router-dom'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import ToggleLeftNavButton from 'components/common/toggleLeftNavButton'
import classNames from 'classnames'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import navbarHeight from 'components/navbar/utils/navbarHeight'
import PageEditorPage from 'pages/pages/pageEditorPage'
import PageHistoryPage from 'pages/pages/pageHistoryPage'
import ArchivedPagesPage from 'pages/pages/archivedPagesPage'
import PagePage from 'pages/pages/pagePage'
import useCloseDesktopNavWhenViewingPageHistory from 'components/pages/hooks/useCloseDesktopNavWhenViewingPageHistory'
import PageMobileToolbar from 'components/pages/pageMobileToolbar'
import PageRedirectorPage from 'pages/pages/pageRedirectorPage'
import useHideSlackWidget from 'components/common/hooks/useHideSlackWidget'

const PATHS_TO_PAGES_MAP = {
  '/pages/:pageId/edit': PageEditorPage,
  '/pages/:pageId/history': PageHistoryPage,
  '/pages/:pageSlug': PagePage,
  '/archived_pages': ArchivedPagesPage,
  '/workspaces/:workspaceSlug/archived_pages': ArchivedPagesPage,
  '/workspaces/:workspaceSlug/:pageId/edit': PageEditorPage,
  '/workspaces/:workspaceSlug/:pageId/history': PageHistoryPage,
  '/workspaces/:workspaceSlug/:pageSlug': PagePage,
  '/pages': PageRedirectorPage,
  '/workspaces/:workspaceSlug': PageRedirectorPage,
}

export const PAGES_PATHS = Object.keys(PATHS_TO_PAGES_MAP)

const PageNavAndRoutes = () => {
  const [isDesktopNavOpen, setIsDesktopNavOpen] = useState(true)
  const currentUser = useCurrentUser()
  const { workspaceSlug } = useParams()

  useHideSlackWidget()
  useCloseDesktopNavWhenViewingPageHistory(isDesktopNavOpen, setIsDesktopNavOpen)
  const location = useLocation()
  const pathsWithMobileNav = ['/pages', '/pages/', `/workspaces/${workspaceSlug}`, `/workspaces/${workspaceSlug}/`]
  const isMobileNavOpen = pathsWithMobileNav.includes(location.pathname)

  const [navigateToRoot] = useApi(API.pagesNavigation.root, {
    addEntitySlice: true,
  })

  // We want the page to take up the entire screen minus the header, the header has a dynamic height due to possible banners
  // If the user is in the mobile app, we want the page to take up the entire screen, since the header and banners are handled natively
  const height = currentUser.inMobileApp ? '100vh' : `calc(100vh - ${navbarHeight()}px)`

  return (
    <div
      className='PageNavAndRoutes'
      style={{ height }}
    >
      <ToggleLeftNavButton
        onClick={() => setIsDesktopNavOpen(!isDesktopNavOpen)}
        isOpen={isDesktopNavOpen}
      />
      <PagesNav
        navigateToRoot={navigateToRoot}
        isDesktopNavOpen={isDesktopNavOpen}
        isMobileNavOpen={isMobileNavOpen}
      />
      <div className={classNames('WrappedContent', { isDesktopNavOpen })}>
        <PageMobileToolbar />
        <Switch>
          {Object.entries(PATHS_TO_PAGES_MAP).map(([path, PageComponent]) => (
            <Route key={path} path={path} component={PageComponent} />
          ))}
        </Switch>
      </div>
    </div>
  )
}

export default PageNavAndRoutes
