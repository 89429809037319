import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'
import TargetingRulesType from 'types/audience/targetingRules'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import BackButton from 'components/common/backButton'

const I18N = i18nPath('views.admin.cleary_ai_settings.prompt_collection_page')

interface WorkingCopy {
  targetingRules: TargetingRulesType
  name: string
  prompts: string[]
}

interface PromptCollectionFormProps {
  onSubmit: (values: WorkingCopy) => void
  isSaving: boolean
  initialWorkingCopy?: WorkingCopy
}

const defaultWorkingCopy: WorkingCopy = {
  targetingRules: TARGET_ENTIRE_COMPANY,
  name: '',
  prompts: [''],
}

const PromptCollectionForm = ({ onSubmit, isSaving, initialWorkingCopy }: PromptCollectionFormProps) => {
  const [workingCopy, _setWorkingCopy, updateWorkingCopy] = useUpdatableState<WorkingCopy>(
    initialWorkingCopy || defaultWorkingCopy
  )

  const addPrompt = () => {
    updateWorkingCopy({
      prompts: [...workingCopy.prompts, ''],
    })
  }

  const updatePrompt = (index: number, text: string) => {
    updateWorkingCopy({
      prompts: workingCopy.prompts.map((prompt, idx) => (idx === index ? text : prompt)),
    })
  }

  const removePrompt = (index: number) => {
    updateWorkingCopy({
      prompts: workingCopy.prompts.filter((_, idx) => idx !== index),
    })
  }

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(workingCopy)
  }

  const isValid = workingCopy.prompts.every(prompt => prompt.trim().length > 0)
    && workingCopy.name.trim().length > 0
    && workingCopy.prompts.length > 0

  return (
    <form className='AdminContent' onSubmit={handleSave}>
      <BackButton url='/admin/cleary_ai_settings/ask_ai' className='mb-4' />

      <div className='white-card p-3 mb-4'>
        <h5 className='card-title mb-3'>{I18N('add_a_name')}</h5>
        <input
          className='form-control'
          value={workingCopy.name}
          onChange={e => updateWorkingCopy({ name: e.target.value })}
          placeholder={I18N('prompt_name_placeholder')}
        />

        <h5 className='card-title mt-5'>{I18N('select_audience')}</h5>
        <AudienceToggleSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          translationsPath='views.admin.layout_collections_audience_toggle_selector'
          showLabel={false}
          moduleName={null}
        />

        <h5 className='card-title mt-5'>{I18N('set_suggested_prompts')}</h5>
        <p className='text-secondary'>{I18N('set_suggested_prompts_description')}</p>

        <div className='prompts-container'>
          {workingCopy.prompts.map((prompt, index) => (
            <div key={index} className='mb-3 d-flex align-items-start'>
              <input
                className='form-control mr-2'
                value={prompt}
                onChange={e => updatePrompt(index, e.target.value)}
                placeholder={I18N('prompt_placeholder')}
              />
              {workingCopy.prompts.length > 1 && (
                <Button
                  variant='link'
                  onClick={() => removePrompt(index)}
                >
                  <CdnSvg src='/images/trashIcon.svg' />
                </Button>
              )}
            </div>
          ))}

          <Button
            variant='secondary'
            onClick={addPrompt}
            size='sm'
          >
            {I18N('add_prompt')}
          </Button>
        </div>

      </div>

      <div className='mt-4 text-right'>
        <Button
          disabled={!isValid}
          showLoadingSpinner={isSaving}
          type='submit'
        >
          {I18NCommon('save')}
        </Button>
      </div>
    </form>
  )
}

export default PromptCollectionForm
