import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import API from 'services/api'
import { i18nPath, i18nFormat } from 'utils/i18nHelpers'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import PeopleSettingsCardMenuDropdown from 'components/home/peopleSettingsCardMenuDropdown'
import Card from 'components/common/card'
import ClickWrapper from 'components/common/clickWrapper'
import AwardedBadgeSocial from 'components/badges/awardedBadgeSocial'

const I18N = i18nPath('views.awarded_badge')
const I18NDropdown = i18nPath('views.feed.dropdown_menu')

const ExcludedCardConfirmation = () => {
  const settingsLink = <Link to='/settings/people'>{I18NDropdown('settings')}</Link>

  return (
    <Card>
      <div>{i18nFormat(I18NDropdown('excluded_confirmation_settings_link'), settingsLink)}</div>
    </Card>
  )
}

export const AwardedBadgeCardTitle = ({ isCurrentUser, awardedBadge, hideImage = true }) => {
  const popoverId = `awarded-badge-${awardedBadge.id}`
  if (isCurrentUser) {
    return <div className='AwardedBadgeTitle'>{I18N('you_got_badge', { badge_name: awardedBadge.badge.displayName })}</div>
  } else {
    const text = I18N('peer_got_badge', { badge_name: awardedBadge.badge.displayName })
    const employeeLinkWithPopover = (
      <EmployeeLinkWithPopover user={awardedBadge.user} id={popoverId} key={popoverId} hideImage={hideImage} />
    )
    return <div className='AwardedBadgeTitle'>{i18nFormat(text, [employeeLinkWithPopover])}</div>
  }
}

const AwardedBadgeCard = ({ awardedBadge, currentUser, feedItem }) => {
  const awardedBadgeCardElement = useRef(null)
  const [showExcludedConfirmation, setShowExcludedConfirmation] = useState(false)
  const isCurrentUser = currentUser.id === awardedBadge.user.id
  const showKebabMenuDropdown = !isCurrentUser && feedItem

  const handleExcludeFromFeed = () => {
    API.home.excludeFromFeed(feedItem)
    setShowExcludedConfirmation(true)
  }

  if (showExcludedConfirmation) {
    return <div ref={awardedBadgeCardElement}> <ExcludedCardConfirmation /> </div>
  }

  return (
    <div ref={awardedBadgeCardElement}>
      <ClickWrapper to={`/people/badges/${awardedBadge.badge.id}/awarded_badges/${awardedBadge.id}`}>
        <Card className='AwardedBadgeCard' cardBodyClassName='ClickWrapperTargetContainer p-0'>
          {showKebabMenuDropdown && (
            <div className='position-absolute Card-dropdownMenu'>
              <PeopleSettingsCardMenuDropdown
                feedItem={feedItem}
                handleExcludeFromFeed={feedItem && handleExcludeFromFeed}
              />
            </div>
          )}
          <div className='ClickWrapperTargetContainer d-flex align-items-center justify-content-center Card-hero AwardedBadgeCard-hero confetti-background position-relative'>
            <div className='AwardedBadgeCard-image d-flex justify-content-center align-items-center ClickWrapperTargetContainer'>
              <img src={awardedBadge.badge.imageUrl} className='ClickWrapperTargetContainer' />
            </div>
          </div>
          <div className='ClickWrapperTargetContainer p-3'>
            <h5 className='mb-3 text-center ClickWrapperTargetContainer'>
              <AwardedBadgeCardTitle isCurrentUser={isCurrentUser} awardedBadge={awardedBadge} />
            </h5>

            <div className='mb-3 text-center ClickWrapperTargetContainer'>{awardedBadge.badge.description}</div>

            <div className='mt-2'>
              <AwardedBadgeSocial awardedBadge={awardedBadge} />
            </div>
          </div>
        </Card>
      </ClickWrapper>
    </div>
  )
}

export default AwardedBadgeCard
