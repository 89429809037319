import React from 'react'

import Card from 'components/common/card'
import FormCheck from 'components/form_fields/formCheck'
import AdvancedSettings from 'components/admin/journeys/advancedSettings'

import GoogleCalendarEventSection from 'components/admin/journeys/calendarEventTemplates/googleCalendarEventSection'
import MicrosoftCalendarEventSection from 'components/admin/journeys/calendarEventTemplates/microsoftCalendarEventSection'

import { Radio, RadioGroup } from 'react-radio-group'

import {
  i18nPath
} from 'utils/i18nHelpers'

import classNames from 'classnames'
import InfoTooltip from 'components/common/infoTooltip'
import StepTemplateFormButtonsContainer from 'components/admin/journeys/common/stepTemplateFormButtonsContainer'
import TriggerScheduleSection from 'components/admin/journeys/common/triggerScheduleSection'

const I18N = i18nPath('views.admin.journeys.calendar_event_templates')
const I18NStepTemplates = i18nPath('views.admin.journeys.step_templates')

export const nonRequiredFields = [
  'addToAllInstances',
]

const CalendarEventForm = ({
  onSave,
  workingCopy,
  updateWorkingCopy,
  currentState = 'draft',
  isStepTemplate = true,
  areChangesPresent = true,
  isDisabled = false,
  forUser,
  journeyBlueprint,
  isSaving = false,
}) => {
  const externalCalendarEventType = workingCopy?.externalCalendarEventType
  const isMicrosoftEvent = externalCalendarEventType === 'microsoft'
  const isGoogleEvent = externalCalendarEventType === 'google'
  const triggerOnStepCompletion = workingCopy?.triggerMilestone === 'step_completion'

  const journeyBlueprintType = journeyBlueprint?.type

  return (
    <div className='CalendarEventForm'>
      <div className='font-weight-600 text-secondary mb-2'>{I18N(`${journeyBlueprintType}.calendar_event_content`)}</div>
      <Card className='mb-4'>
        {isMicrosoftEvent
          && (
            <MicrosoftCalendarEventSection
              workingCopy={workingCopy}
              updateWorkingCopy={updateWorkingCopy}
              isDisabled={isDisabled}
            />
          )}
        {isGoogleEvent && (
          <GoogleCalendarEventSection
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
            isDisabled={isDisabled}
          />
        )}
        <label className='required-form-label'>{I18N(`${journeyBlueprintType}.add_employee_to`)}</label>
        <RadioGroup
          className={classNames('d-flex align-items-center justify-content-start UserRadioButtonField')}
          name='add_to_all_instances'
          selectedValue={workingCopy.addToAllInstances}
          onChange={value => updateWorkingCopy({ addToAllInstances: value })}
        >
          <Radio className='mr-1' value={false} disabled={isDisabled} />
          {I18N(`${journeyBlueprintType}.one_instance`)}
          <InfoTooltip text={I18N(`${journeyBlueprintType}.one_instance_tooltip`)} />
          <Radio className='mr-1 ml-5' value={true} disabled={isDisabled} />
          {I18N(`${journeyBlueprintType}.all_instances`)}
          <InfoTooltip text={I18N(`${journeyBlueprintType}.all_instances_tooltip`)} />
        </RadioGroup>
      </Card>

      <TriggerScheduleSection
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isDisabled={isDisabled}
        isStepTemplate={isStepTemplate}
        journeyBlueprint={journeyBlueprint}
        sectionLabel={I18N(`${journeyBlueprintType}.calendar_event_trigger`)}
      />

      {isStepTemplate && journeyBlueprintType && !triggerOnStepCompletion && (
        <AdvancedSettings
          className='mt-4'
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          isStepTemplate={isStepTemplate}
          journeyBlueprintType={journeyBlueprint?.type}
        >
          <label className='text-secondary font-weight-600 mb-3'>{I18NStepTemplates('error_handling')}</label>
          <FormCheck
            name='create_after_trigger_period'
            label={I18N(`${journeyBlueprintType}.advanced_settings.create_after_trigger_period`)}
            checked={workingCopy.triggerImmediatelyIfPastTrigger}
            onChange={({ target }) => updateWorkingCopy({ triggerImmediatelyIfPastTrigger: target.checked })}
            id='create_after_trigger_period'
            className='mb-3'
          />
        </AdvancedSettings>
      )}
      <StepTemplateFormButtonsContainer
        onSave={onSave}
        workingCopy={workingCopy}
        currentState={currentState}
        isStepTemplate={isStepTemplate}
        areChangesPresent={areChangesPresent}
        isDisabled={isDisabled}
        forUser={forUser}
        nonRequiredFields={nonRequiredFields}
        stepType='calendarEvent'
        isSaving={isSaving}
      />
    </div>
  )
}

export default CalendarEventForm
