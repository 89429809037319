import React, { useState } from 'react'

const ImageWithFallback = ({ src, fallbackSrc, ...props }) => {
  const [source, setSource] = useState(src || fallbackSrc)

  return (
    <img
      src={source}
      onError={() => {
        if (source !== fallbackSrc) {
          setSource(fallbackSrc)
        }
      }}
      {...props}
    />
  )
}

export default ImageWithFallback
