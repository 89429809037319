import React, { createContext, useContext } from 'react'

interface PagesNavContextType {
  isLoading: boolean
  expandResource: (resourceType, resourceyId) => void
  fetchNavigation: (pageIrOrSlug?: string) => void
  showExpandingState: (type: string, id: string) => boolean
  isDraggingRef: React.MutableRefObject<boolean>
}

const PagesNavContext = createContext<PagesNavContextType>({
  isLoading: false,
  expandResource: () => {},
  fetchNavigation: () => {},
  showExpandingState: () => false,
  isDraggingRef: { current: false },
})

const usePagesNav = () => useContext(PagesNavContext)

export default PagesNavContext
export { usePagesNav }
