import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import { ReduxState } from 'redux/redux'
import API from 'services/api'
import appSignal from 'services/appSignal'
import entitySlice from 'redux/slices/entities'
import awardedBadgeSlice from 'redux/slices/awardedBadges'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { getResponseOrThrow } from 'utils/errorHandling'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { RequestedBadgeState } from 'types/requestedBadge'

const I18N = i18nPath('views.badges.request')

export const initialState: RequestedBadgeState = {
  requestedBadgeIds: [],
  meta: {
    ...defaultMeta,
  },
}

const requestedBadgeSlice = createSlice({
  name: 'requestedBadges',
  initialState,
  reducers: {
    ...defaultActions,

    mergeRequestedBadgeIds(state, action) {
      state.requestedBadgeIds = _.uniq([
        ...state.requestedBadgeIds,
        ...action.payload,
      ])
    },

    clearRequestedBadgeIds(state) {
      state.requestedBadgeIds = []
    },

    removeRequestedBadgeId(state, action) {
      const index = state.requestedBadgeIds.indexOf(action.payload)
      state.requestedBadgeIds.splice(index, 1)
    },
  },
})

const asyncActions = {
  fetchAll: (badgeId: string) => async (dispatch) => {
    try {
      dispatch(requestedBadgeSlice.actions.isLoading(true))
      const response = await API.requestedBadges.fetchAll(badgeId)
      const requestedBadgeIds = response.data.data.map(requestedBadge => requestedBadge.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(requestedBadgeSlice.actions.mergeRequestedBadgeIds(requestedBadgeIds))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(requestedBadgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(requestedBadgeSlice.actions.isLoading(false))
    }
  },

  create: (params, onSuccess = () => {}) => async (dispatch) => {
    try {
      dispatch(requestedBadgeSlice.actions.isSaving(true))
      const response = await API.requestedBadges.create(params)

      dispatch(entitySlice.actions.add({ data: response.data }))
      // We have to update the badge entity because the current user has now requested the badge
      dispatch(entitySlice.actions.update({
        data: {
          data: {
            id: params.badgeId,
            type: 'badge',
            attributes: {
              currentUserHasRequested: true,
            },
          },
        },
      }))
      dispatch(requestedBadgeSlice.actions.mergeRequestedBadgeIds([response.data.data.id]))
      dispatch(showToastMessage({ message: I18N('request_successful'), type: 'success' }))
      onSuccess()
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(requestedBadgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(requestedBadgeSlice.actions.isSaving(false))
    }
  },

  update: (params, onSuccess = () => {}) => async (dispatch) => {
    try {
      dispatch(requestedBadgeSlice.actions.isSaving(true))
      const response = await API.requestedBadges.update(params)

      dispatch(entitySlice.actions.update({ data: response.data }))
      const awardedBadgeId = response.data?.included?.find(i => i.type === 'awarded_badge')?.id
      if (awardedBadgeId) { dispatch(awardedBadgeSlice.actions.mergeAwardedBadgesIds([awardedBadgeId])) }
      dispatch(requestedBadgeSlice.actions.removeRequestedBadgeId(params.id.toString()))
      dispatch(showToastMessage({ message: I18N('resolve_successful'), type: 'success' }))
      onSuccess()
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(requestedBadgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(requestedBadgeSlice.actions.isSaving(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.requestedBadges.meta,

  getRequestedBadges: () => (state: ReduxState) => state.requestedBadges.requestedBadgeIds.map(id => build(state.entities, 'requestedBadge', id)) || [],
}

export default {
  ...requestedBadgeSlice,
  asyncActions,
  selectors,
}
