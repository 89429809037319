import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { articlePath } from 'utils/routeHelpers'
import { i18nPath } from 'utils/i18nHelpers'

import RichTextView from 'components/common/richTextView'
import { OVERDUE, PENDING } from 'types/acknowledgement'

const I18N = i18nPath('views.article')

const IMG_WITH_DATA_IMAGE_ID_REGEX = /<img[^>]*(data-image-id|data-file-id)[^>]*>/g
const ANCHOR_WITH_DATA_FILE_ID_REGEX = /<a[^>]*(data-file-id)[^>]*>/g
const IFRAME_WITH_DATA_FILE_ID_REGEX = /<iframe[^>]*(data-file-id)[^>]*>/g
const VIDEO_WITH_DATA_VIDEO_ID_REGEX = /<video[^>]*(data-video-id)[^>]*>/g
const SRC_AND_DATA_IMAGE_ID_REGEX = /(?:src="([^"]*)")|(?:data-image-id="(\d+)")/g
const HREF_AND_DATA_FILE_ID_REGEX = /(?:href="([^"]*)")|(?:data-file-id="(\d+)")/g
const SRC_AND_DATA_FILE_ID_REGEX = /(?:src="([^"]*)")|(?:data-file-id="(\d+)")/g
const DATA_VIDEO_ID_REGEX = /data-video-id="([a-zA-Z0-9_]+)"/g

const replaceDataIdsAndUrls = (html) => {
  html = html.replace(IMG_WITH_DATA_IMAGE_ID_REGEX, match => match.replace(SRC_AND_DATA_IMAGE_ID_REGEX, ''))
  html = html.replace(ANCHOR_WITH_DATA_FILE_ID_REGEX, match => match.replace(HREF_AND_DATA_FILE_ID_REGEX, ''))
  html = html.replace(IFRAME_WITH_DATA_FILE_ID_REGEX, match => match.replace(SRC_AND_DATA_FILE_ID_REGEX, ''))
  html = html.replace(VIDEO_WITH_DATA_VIDEO_ID_REGEX, match => match.replace(DATA_VIDEO_ID_REGEX, ''))
  html = html.replaceAll('\n', '')

  return html
}

export const areHtmlContentsEqual = (html1, html2) => {
  // if both are empty, they are equal, if only one is empty, they are not equal
  if (!html1 && !html2) return true
  if (!html1 || !html2) return false

  // Remove data-image-id and data-file-id data-video-id attributes from img, a and iframe tags
  // since they will be different attachments, but the content is probably same

  html1 = replaceDataIdsAndUrls(html1)
  html2 = replaceDataIdsAndUrls(html2)

  return html1 === html2
}

const ArticleContent = ({
  article,
  richTextKey = 'content',
  includeReadMoreLink = false,
  isPreviewing = false,
}) => {
  const {
    acknowledgementInfo, hasBodyHtml, cardContent, content,
  } = article
  const acknowledgementPending = [PENDING, OVERDUE].includes(acknowledgementInfo?.status)

  const hasMoreContent = useMemo(() => {
    if (!hasBodyHtml) return false

    return !areHtmlContentsEqual(content, cardContent)
  }, [content, cardContent])

  const appendReadMoreLink = includeReadMoreLink && (hasMoreContent || acknowledgementPending)
  const fallbackToCardContent = richTextKey === 'content' && _.isEmpty(content)

  const LinkOrDiv = isPreviewing ? 'div' : Link

  return (
    <div className='overflow-x-auto overflow-y-hidden'>
      <RichTextView
        record={article}
        recordType='article'
        richTextKey={fallbackToCardContent ? 'cardContent' : richTextKey}
      />
      {appendReadMoreLink && (
        <LinkOrDiv
          className='link mt-1 d-block'
          to={articlePath(article)}
        >
          {acknowledgementPending ? I18N('read_more_and_acknowledge') : I18N('read_more')}
        </LinkOrDiv>
      )}
    </div>
  )
}

export default ArticleContent
