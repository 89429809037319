export enum ChatChannelTypeEnum {
  SlackChannel = 'Chat::SlackChannel',
  MsTeamChannel = 'Chat::MsTeamChannel'
}

export interface ChatChannelType {
  id: string
  name: string
  workspaceDomain: string
  type: ChatChannelTypeEnum
  externalUrl: string
}
