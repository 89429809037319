import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'

import Card from 'components/common/card'
import qnaEventSlice from 'redux/slices/qnaEvents'
import RichTextView from 'components/common/richTextView'
import TruncatedFadedContent from 'components/common/truncatedFadedContent'

import CdnSvg from 'components/common/cdnSvg'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { MEDIUM_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.qna.events')
const I18NTemplateList = i18nPath('views.qna.events.template_list')

const EventDescriptionView = ({ editDescription, event }) => (
  <div className='my-4 position-relative hover-background-color' onClick={() => editDescription()}>
    <TruncatedFadedContent>
      <RichTextView record={event} recordType='event' richTextKey='description' />
    </TruncatedFadedContent>
  </div>
)

const AddDescription = ({ editDescription }) => (
  <div className='mt-4'>
    <a className='link-color' onClick={() => editDescription()}>
      <CdnSvg src='/images/addPencilIcon.svg' className='EventDescription-addPencilIcon' />
      <span className='font-weight-500 align-middle'>{I18N('add_description')}</span>
    </a>
  </div>
)

const EditDescription = (props) => {
  const dispatch = useDispatch()
  const { event } = props

  const [isEditing, setIsEditing] = useState(false)
  const [description, setDescription] = useState(null)
  const [updatedEvent, setUpdatedEvent] = useState(null)

  const { meta } = useSelector(qnaEventSlice.selectors.getEditEventPageData(event.id))

  const { isUpdatingField } = meta

  let templateDescriptionPlaceholder = null
  if (event.templateName) {
    templateDescriptionPlaceholder = I18NTemplateList(`${event.templateName}_placeholder`)
  }

  useEffect(() => {
    setUpdatedEvent({ ...updatedEvent, description })
  }, [description])

  const editDescription = () => {
    setDescription(event.description)
    setUpdatedEvent(event)
    setIsEditing(true)
  }

  const handleDescriptionChange = (content) => {
    let description = content
    if (content.match('^<p><br></p>$')) {
      description = null
    }

    setDescription(description)
  }

  const cancelEdit = () => {
    setIsEditing(false)
  }

  const handleSave = () => {
    dispatch(qnaEventSlice.asyncActions.admin.update(updatedEvent, 'description'))
    setIsEditing(false)
  }

  const showDescription = !isUpdatingField.description && !isEditing && event.description
  const showAddDescription = !isUpdatingField.description && !isEditing && !event.description

  return (
    <>
      {/* event description is updating */}
      {isUpdatingField.description && (<LoadingIndicator />)}
      {/* edit event description. creating a separate component for this introduces a bug */}
      {!isUpdatingField.description && isEditing && (
        <div className='my-4'>
          <Card cardBodyClassName='p-0'>
            <TiptapEditor
              onChange={handleDescriptionChange}
              html={description}
              configuration={{ placeholder: templateDescriptionPlaceholder, focusOnInitialize: true }}
              toolbarItems={MEDIUM_TOOLBAR}
              editorContentClassName='p-3'
              className='TipTapEditor'
            />
          </Card>
          <div className='d-flex justify-content-end align-items-center mt-4'>
            <a className='text-muted-dark mr-4' onClick={() => cancelEdit()}>{I18N('cancel')}</a>
            <button type='submit' className='btn btn-primary rounded-pill' onClick={() => handleSave()}>{I18N('save_changes')}</button>
          </div>
        </div>
      )}
      {/* show description */}
      {showDescription && <EventDescriptionView editDescription={editDescription} event={event} />}
      {/* show add description if no description exists */}
      {showAddDescription && (<AddDescription editDescription={editDescription} />)}
    </>
  )
}

const EventDescription = ({ event, canEditEvent }) => (

  <>
    {canEditEvent && (
      <EditDescription event={event} />
    )}

    {!canEditEvent && event.description && (
      <div className='my-4 position-relative'>
        <TruncatedFadedContent>
          <RichTextView record={event} recordType='event' richTextKey='description' />
        </TruncatedFadedContent>
      </div>
    )}
  </>

)

export default EventDescription
