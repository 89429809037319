import React, { useState } from 'react'

import IntegrationCard from 'components/admin/integrations/integrationCard'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import AuthorizeZoomIntegrationModal from 'components/settings/integrations/authorizeZoomIntegrationModal'

const I18N = i18nPath('views.settings.integrations_section')

interface Props {
  isInstalled: boolean,
  afterUninstalled: () => void
}

const ZoomIntegrationCard = ({ isInstalled, afterUninstalled }: Props) => {
  const [onUninstall, { isLoading: isUninstalling }] = useApi(API.userIntegrations.uninstallZoomIntegration, {
    onSuccess: afterUninstalled,
  })

  const onUninstallClick = () => {
    setAuthorizedThroughModal(false)
    onUninstall()
  }

  const [isAuthorizeModalVisible, setIsAuthorizeModalVisible] = useState(false)
  const [isAuthorizedThroughModal, setAuthorizedThroughModal] = useState(false)

  return (
    <>
      <IntegrationCard
        id='zoom'
        logo={<CdnSvg src='/images/zoomIcon.svg' />}
        isInstalled={isInstalled || isAuthorizedThroughModal}
        docLink='https://gocleary.zendesk.com/hc/en-us/articles/20715592173211-Zoom'
        docTitle={I18N('zoom_doc_title')}
        installationComponent={(
          <Button onClick={() => setIsAuthorizeModalVisible(true)}>
            {I18NCommon('authorize')}
          </Button>
        )}
        onUninstall={onUninstallClick}
        isUninstalling={isUninstalling}
      />
      {isAuthorizeModalVisible && (
        <AuthorizeZoomIntegrationModal
          visible={isAuthorizeModalVisible}
          closeModal={() => setIsAuthorizeModalVisible(false)}
          authorizedThroughModal={isAuthorizedThroughModal}
          setAuthorizedThroughModal={setAuthorizedThroughModal}
        />
      )}
    </>
  )
}

export default ZoomIntegrationCard
