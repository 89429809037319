import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import API from 'services/api'
import FeedItem from 'components/home/feedItem'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useApi from 'components/common/hooks/useApi'
import Card from 'components/common/card'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.home.preboarding_feed_widget')

const PreboardingJourneyCommunicationItem = () => (
  <Card className='mb-4' cardBodyClassName='PreboardingJourneyCommunicationItem d-flex justify-content-between confetti-background'>
    <div>
      <h6 className='text-secondary font-weight-400 mt-2 mb-3'>{I18N('journeys')}</h6>
      <p className='m-0'>{I18N('empty_state_description')}</p>
    </div>
    <CdnSvg src='/images/illustrations/manWithOkHand.svg' className='ManWithOkHand' />
  </Card>
)

const PreboardingFeedWidget = ({ widget, isEditing }) => {
  const { preboarding } = useCurrentUser()
  const inPreboardingPreview = !preboarding
  const { title } = widget
  const ref = useRef<HTMLDivElement>(null)

  const [fetchedFeedItems, setFetchedFeedItems] = useState<any[]>([])
  const [fetch, { paginationData = {}, isLoading }] = useApi(
    API.journey.preboarding.home.fetchFeed,
    { onSuccess: data => setFetchedFeedItems([...fetchedFeedItems, ...data]) }
  )

  const editingFeedItems = useMemo(
    () => widget.widgetRecordAssociations?.map(wra => ({ id: `f-${wra.id}`, article: wra.record, type: 'article' })),
    [widget.widgetRecordAssociations]
  )

  const feedItems = isEditing ? editingFeedItems : fetchedFeedItems

  const {
    page = 0,
    total = 0,
    totalPages = 1,
  } = paginationData

  useEffect(() => {
    fetch({ widgetId: widget.id, page: 1 })
  }, [])

  const next = () => {
    if (isLoading) return

    fetch({ widgetId: widget.id, page: page + 1 })
  }

  // subtracting 1 to the height otherwise an additional scrollbar appears in some screens
  const infiniteScrollHeight = (ref.current?.clientHeight || 600) - 1

  return (
    <Card className='PreboardingFeedWidget HomePageWidget'>
      <WidgetTitle title={title} />

      <div ref={ref} className='HomePageWidget--scrollable-section hasOutsideScrollbar'>
        <InfiniteScroll
          dataLength={total || 0}
          next={next}
          hasMore={page < totalPages}
          loader={<CirclesLoadingIndicator />}
          height={infiniteScrollHeight}
          className='p-1'
        >
          {inPreboardingPreview && <PreboardingJourneyCommunicationItem />}
          {feedItems?.map(feedItem => (
            <FeedItem
              key={`feed-item-${feedItem.id}`}
              feedItem={feedItem}
            />
          ))}
        </InfiniteScroll>
      </div>
    </Card>
  )
}

export default PreboardingFeedWidget
