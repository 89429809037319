import React from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'

import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import EditableRichTextContent from 'components/common/editable_content/editableRichTextContent'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'

import profileSlice from 'redux/slices/profiles'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.profile')
const plusIconPath = '/images/plusIcon.svg'

const Bio = ({ user, canEdit, currentCompany }) => {
  const isBioFieldEnabled = currentCompany.settings?.profile?.fields?.bio || false
  const bio = user.bio
  const dispatch = useDispatch()

  const updateBio = (value) => {
    if (user?.bio === value) {
      return new Promise(resolve => resolve(true))
    } else {
      return dispatch(profileSlice.asyncActions.update(user, { bio: value }))
    }
  }

  // hide this whole component if:
  // 1) it's disabled in the company settings, or:
  // 2) the current user is looking at someone's profile AND the bio is empty
  if (!isBioFieldEnabled || (!canEdit && _.isEmpty(bio))) {
    return null
  }

  return (
    <div className='ProfileBio w-100'>
      <h6>{I18N('bio')}</h6>
      <EditableRichTextContent
        className='p-1'
        canEdit={canEdit}
        showCharCount
        displayElement={
          isHtmlStringEmpty(bio) ? (
            <span className='font-weight-500 d-flex'>
              <CdnSvg className='PlusIcon mr-1' src={plusIconPath} />
              {I18N('add_bio')}
            </span>
          ) : (
            <TipTapRichTextView html={bio} />
          )
        }
        inputElement={(
          <TiptapEditor
            className='RoundedTiptapEditor w-100'
            configuration={{
              ...SIMPLE_EDITOR_CONFIGURATION,
              placeholder: I18N('describe_who_you_are'),
            }}
            toolbarItems={SMALL_TOOLBAR}
          />
        )}
        valueToEdit={bio ?? ''}
        saveContent={updateBio}
      />
    </div>
  )
}

export default Bio
