import classNames from 'classnames'
import { ButtonSmallNarrow } from 'components/common/buttons'
import useBeforeUnload from 'components/common/hooks/useBeforeUnload'
import Modal from 'components/common/modal'
import PageCollaborators from 'components/pages/pageCollaborators'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import pageSlice from 'redux/slices/pages'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import userSlice from 'redux/slices/users'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.pages.actions_dropdown')

const EditableByModal = ({
  visible,
  onCloseModal,
  page,
  editorsCount = 0,
}) => {
  const dispatch = useDispatch()
  const editorIdsByType = page?.editorIdsByType || {}
  const data = editorIdsByType?.data || []
  const {
    collaboratorIds = [],
    workspaceOwnerIds = [],
    pageWorkspaceEditorIds = [],
  } = data

  const { isSaving } = useSelector(pageSlice.selectors.getPagesMetadata())
  const collaborators = useSelector(userSlice.selectors.getSimpleUsersByIds(collaboratorIds))
  const pageWorkspaceEditors = useSelector(userSlice.selectors.getSimpleUsersByIds(pageWorkspaceEditorIds))
  const workspaceOwners = useSelector(userSlice.selectors.getSimpleUsersByIds(workspaceOwnerIds))
  const { isLoading: isLoadingUsers } = useSelector(userSlice.selectors.getMetaData())

  const [updatedCollaborators, setUpdatedCollaborators] = useState<any>([])

  const areChangesPresent = !_.isEqual(
    collaborators?.map(({ id }) => id).sort(),
    updatedCollaborators.map(({ id }) => id).sort()
  )

  const addCollaborator = (user) => {
    // We won't allow adding the same user twice as collaborator
    // Also, if they have already another role, then they won't be removable
    // So we need to exclude them from the list of users that can be added
    const excludedUserIds = [
      page.owner?.id,
      ...collaboratorIds,
      ...pageWorkspaceEditorIds,
      ...workspaceOwnerIds,
    ]

    if (!excludedUserIds.includes(user.id)) {
      setUpdatedCollaborators(_.uniqBy([...updatedCollaborators, user], 'id'))
    }
  }

  const removeCollaborator = (user) => {
    setUpdatedCollaborators(updatedCollaborators.filter(({ id }) => id !== user.id))
  }

  useEffect(() => {
    setUpdatedCollaborators(collaborators || [])
  }, [JSON.stringify(collaborators)])


  useEffect(() => {
    if (visible) {
      const currentUserIds = [
        ...collaborators.map(({ id }) => id),
        ...pageWorkspaceEditors.map(({ id }) => id),
        ...workspaceOwners.map(({ id }) => id),
      ]

      const userIdsToFetch = [
        ...collaboratorIds,
        ...pageWorkspaceEditorIds,
        ...workspaceOwnerIds,
      ].filter(id => !currentUserIds.includes(id))

      dispatch(userSlice.asyncActions.simpleFetchAll(userIdsToFetch))
    }
  }, [visible])

  // Prevents user from reloading page if changes are pending
  useBeforeUnload((e) => {
    if (areChangesPresent) {
      e.preventDefault()
      e.returnValue = ''
    }
  })

  const onCloseModalWithConfirmation = () => {
    if (areChangesPresent) {
      if (confirm(I18N('blocked_navigation_prompt_message'))) {
        onCloseModal()
      }
    } else {
      onCloseModal()
    }
  }

  const onSave = () => {
    const updatedPage = {
      id: page.id,
      collaboratorIds: updatedCollaborators.map(user => user.id),
    }

    dispatch(pageSlice.asyncActions.update(updatedPage, onCloseModal))
  }

  return (
    <Modal
      className={classNames('EditableByModal')}
      visible={visible}
      toggle={onCloseModalWithConfirmation}
    >
      {isLoadingUsers ? (
        <CirclesLoadingIndicator />
      ) : (
        <>
          <PageCollaborators
            collaborators={updatedCollaborators}
            addCollaborator={addCollaborator}
            removeCollaborator={removeCollaborator}
            owner={page.owner}
            workspaceEditors={pageWorkspaceEditors}
            workspaceOwners={workspaceOwners}
            editorsCount={editorsCount}
          />

          <footer className='pb-4 px-4 d-flex justify-content-end align-items-center'>
            <a onClick={onCloseModalWithConfirmation} className='mr-3 text-muted-dark'>
              {I18NCommon('cancel')}
            </a>

            <ButtonSmallNarrow
              onClick={onSave}
              disabled={!areChangesPresent}
              showLoadingSpinner={isSaving}
            >
              {I18NCommon('save_changes')}
            </ButtonSmallNarrow>
          </footer>
        </>
      )}
    </Modal>
  )
}

export default EditableByModal
