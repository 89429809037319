import React from 'react'
import { Link } from 'react-router-dom'

const StepLink = ({ step }) => {
  const path = step?.surveyResponseDisplayPath || step?.adminDisplayPath
  const name = step?.entity?.name || step?.name

  return (
    <div className='StepLink d-flex justify-content-between align-items-center w-100'>
      <Link to={path}>{name}</Link>
    </div>
  )
}

export default StepLink
