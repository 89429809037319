import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.users')

const CreateNewUserButton = ({ className = '' }) => (
  <Link className={className} to='/admin/users/new'>
    <Button>
      {I18N('create_new_user')}
    </Button>
  </Link>
)

export default CreateNewUserButton
