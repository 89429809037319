import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import Modal from 'components/common/modal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import EmployeeSearch from 'components/form_fields/employeeSearch'

const I18N = i18nPath('views.pages.actions_dropdown')

interface TransferPageOwnershipModalProps {
  page: any
  isOpen: boolean
  onClose: () => void
  className?: string
  onSuccess?: () => void
}

const TransferPageOwnershipModal = ({
  page,
  isOpen,
  onClose,
  className,
  onSuccess = () => {},
}: TransferPageOwnershipModalProps) => {
  const [owner, setOwner] = useState(page?.owner)

  const [updatePage, { isLoading }] = useApi(API.pages.update, {
    updateEntitySlice: true,
    onSuccess: () => {
      onSuccess()
      onClose()
    },
    toastSuccessMessage: I18N('page_updated'),
  })

  const updateOwner = () => {
    updatePage({
      id: page.id,
      ownerId: owner?.id,
    })
  }

  return (
    <Modal size='lg' className={classNames('TransferPageOwnershipModal', className)} visible={isOpen} toggle={onClose}>
      <div className='px-4 mb-4'>
        <h4 className='text-left font-weight-600'>{I18N('transfer_ownership')}</h4>
        <span className='text-secondary'>{I18N('owner_description')}</span>
        <div className='d-block text-left mt-3'>
          <label className='d-block font-weight-500' htmlFor='pageOwnerDropdown'>{I18N('owner')}</label>
          <EmployeeSearch
            className='mb-3'
            onChange={setOwner}
            selectedEmployee={owner}
            isClearable={false}
          />
        </div>
        <div className='d-flex justify-content-end align-items-center mt-5'>
          <CancelButton onClick={onClose} className='mr-3 text-secondary font-weight-600'>
            {I18N('cancel')}
          </CancelButton>
          <ButtonNarrow className='copySaveButton' onClick={updateOwner} showLoadingSpinner={isLoading} disabled={isLoading}>
            {I18N('save')}
          </ButtonNarrow>
        </div>
      </div>
    </Modal>
  )
}

export default TransferPageOwnershipModal
