import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'

// This will get refactored into our overall error handling setup via redux
import { getResponseOrThrow, checkForError } from 'utils/errorHandling'
import { i18nPath } from 'utils/i18nHelpers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

const I18N = i18nPath('views.qna.questions')

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildQuestionPayload = questionParams => ({
  eventId: questionParams.eventId,
  question: _.pick(questionParams.question, [
    'id',
    'content',
    'anonymous',
    'type',
  ]),
})

const initialState = {
  isQuestionFormVisible: false,
  pendingQuestionContent: '',
  newlyAddedQuestionId: null,
  newlyPendingQuestionId: null,
  icebreakerTemplates: [],
  recentQuestionIds: [],
  selectedQuestionIdFromHighlightedEvent: false,
  meta: {
    error: null,
    isLoading: false,
    isLoadingIcebreakerTemplates: false,
    isLoadingRecentQuestions: false,
    isNotFound: false,
    isSaving: false,
    isUnauthorized: false,
    queryParams: defaultPaginationParams,
  },
}

const qnaQuestionSlice = createSlice({
  name: 'qnaQuestions',
  initialState,
  reducers: {
    clearEventsListPageData(state, action) {
      state.recentQuestionIds = []
    },

    setRecentQuestionIds(state, action) {
      state.recentQuestionIds = action.payload
    },

    isLoadingRecentQuestions(state, action) {
      state.meta.isLoadingRecentQuestions = action.payload
    },

    isQuestionFormVisible(state, action) {
      state.isQuestionFormVisible = action.payload
    },

    isUnauthorized(state, action) {
      state.meta.isUnauthorized = action.payload
    },

    updatePendingQuestionContent(state, action) {
      state.pendingQuestionContent = action.payload
    },

    resetQuestionForm(state, action) {
      state.isQuestionFormVisible = false
      state.pendingQuestionContent = ''
    },

    setNewlyAddedQuestionId(state, action) {
      state.newlyAddedQuestionId = action.payload
    },

    setNewlyPendingQuestionId(state, action) {
      state.newlyPendingQuestionId = action.payload
    },

    setIcebreakerTemplates(state, action) {
      state.icebreakerTemplates = action.payload
    },

    resetIcebreakerState(state, action) {
      state.meta.isLoadingIcebreakerTemplates = false
      state.icebreakerTemplates = []
    },

    setSelectedQuestionIdFromHighlightedEvent(state, action) {
      state.selectedQuestionIdFromHighlightedEvent = action.payload
    },

    // This is where we want to expand our generic handling of errors from the Rails backend
    //   * Generic string error like 500's
    //   * Form based, return all fields that are populating a model.error and the standardized message per field, using this to create inline error messages
    setError(state, action) {
      state.meta.error = action.payload
    },

    // Example of a utility function probably going to be shared to rally an effort to refactor all loading patterns around
    // We can merge in these common, refactored, functions
    isLoading(state, action) {
      state.meta.isLoading = action.payload
    },

    setIceBreakerTemplatesLoading(state, action) {
      state.meta.isLoadingIcebreakerTemplates = action.payload
    },

    isSaving(state, action) {
      state.meta.isSaving = action.payload
    },

    isNotFound(state, action) {
      state.meta.isNotFound = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------
const asyncActions = {
  admin: {
    fetchAll: (eventId, queryParams) => async (dispatch) => {
      dispatch(qnaQuestionSlice.actions.isLoading(true))

      try {
        const response = await API.admin.qna.questions.fetchAll(eventId, { ...queryParams })
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        dispatch(qnaQuestionSlice.actions.setError('Failed to fetch questions'))
      } finally {
        dispatch(qnaQuestionSlice.actions.isLoading(false))
      }
    },

    approve: question => async (dispatch) => {
      try {
        const { event } = question
        const response = await API.admin.qna.questions.approve(event.id, question.id)

        const updatedEventData = {
          data: {
            attributes: {
              pendingQuestionsCount: event.pendingQuestionsCount - 1,
            },
            id: event.id,
            type: 'qnaEvent',
          },
        }

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('question_approved'), type: 'success' }))
        // we need to update the event with the correct pending questions count
        dispatch(entitySlice.actions.update({ data: updatedEventData }))
      } catch (e) {
        dispatch(qnaQuestionSlice.actions.setError('Failed to approve question'))
      }
    },

    reject: (question, rejectionReason) => async (dispatch) => {
      try {
        const { event } = question
        const response = await API.admin.qna.questions.reject(event.id, question.id, rejectionReason)

        const updatedEventData = {
          data: {
            attributes: {
              pendingQuestionsCount: event.pendingQuestionsCount - 1,
            },
            id: event.id,
            type: 'qnaEvent',
          },
        }

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(entitySlice.actions.update({ data: updatedEventData }))
        dispatch(showToastMessage({ message: I18N('question_rejected'), type: 'success' }))
      } catch (e) {
        dispatch(qnaQuestionSlice.actions.setError('Failed to reject question'))
      }
    },
  },
  fetchAll: (eventId, queryParams) => async (dispatch) => {
    dispatch(qnaQuestionSlice.actions.isLoading(true))

    try {
      const response = await API.qna.questions.fetchAll({ eventId, ...queryParams })
      const newQueryParams = queryParamsFromHeaders(response)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(qnaQuestionSlice.actions.setQueryParams(newQueryParams))
    } catch (e) {
      dispatch(qnaQuestionSlice.actions.setError('Failed to fetch questions'))
    } finally {
      dispatch(qnaQuestionSlice.actions.isLoading(false))
    }
  },

  resetQuestions: () => async (dispatch) => {
    dispatch(entitySlice.actions.reset('qnaQuestion'))

    // when 'handleFetchQuestions' gets the next page, it increments the page count.
    // since we're looking to fetch the first page after the reset here, we need to set
    // the page to 0 since it will be incremented by 1 before fetching.
    dispatch(qnaQuestionSlice.actions.setQueryParams({ ...defaultPaginationParams, page: 0 }))
  },

  fetch: (questionId, isLoading, mergeMethod = 'merge') => async (dispatch) => {
    dispatch(qnaQuestionSlice.actions.isLoading(isLoading))

    try {
      const response = await API.qna.questions.fetch(questionId)

      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      if (error.type === 'unauthorized') {
        dispatch(qnaQuestionSlice.actions.isUnauthorized(true))
        dispatch(qnaQuestionSlice.actions.setError('Question is unauthorized'))
      } else {
        dispatch(qnaQuestionSlice.actions.isNotFound(true))
        dispatch(qnaQuestionSlice.actions.setError('Failed to fetch question'))
      }
    } finally {
      dispatch(qnaQuestionSlice.actions.isLoading(false))
    }
  },

  fetchRecentQuestions: () => async (dispatch) => {
    dispatch(qnaQuestionSlice.actions.isLoadingRecentQuestions(true))

    try {
      const response = await API.qna.questions.fetchRecentQuestions()
      const recentQuestionIds = response.data.data.map(question => question.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(qnaQuestionSlice.actions.setRecentQuestionIds(recentQuestionIds))
    } catch (e) {
      dispatch(qnaQuestionSlice.actions.setError('Failed to fetch recent questions'))
    } finally {
      dispatch(qnaQuestionSlice.actions.isLoadingRecentQuestions(false))
    }
  },

  fetchIcebreakerTemplates: () => async (dispatch) => {
    dispatch(qnaQuestionSlice.actions.setIceBreakerTemplatesLoading(true))

    try {
      const response = await API.qna.icebreakerQuestions.fetchAll()

      dispatch(qnaQuestionSlice.actions.setIcebreakerTemplates(response.data))
    } catch (e) {
      dispatch(showToastMessage({ message: e, type: 'warning' }))
    } finally {
      dispatch(qnaQuestionSlice.actions.setIceBreakerTemplatesLoading(false))
    }
  },

  createQuestion: (questionParams, event) => async (dispatch) => {
    dispatch(qnaQuestionSlice.actions.isLoading(true))
    // dispatch(qnaQuestionSlice.actions.isSaving(true))

    try {
      const response = await API.qna.questions.create(buildQuestionPayload(questionParams))

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(qnaQuestionSlice.actions.resetQuestionForm())
      dispatch(showToastMessage({ message: I18N('successfully_created'), type: 'success' }))
      // we do not want to add pending questions to existing questions
      if (response.data.data.attributes.isApproved) {
        dispatch(qnaQuestionSlice.actions.setNewlyAddedQuestionId(response.data.data.id))
      } else {
        dispatch(qnaQuestionSlice.actions.setNewlyPendingQuestionId(response.data.data.id))
      }
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      if (e?.response?.data?.error?.message) {
        const message = e?.response?.data?.error?.message
        dispatch(showToastMessage({ message, type: 'error' }))
      } else {
        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      }
    } finally {
      dispatch(qnaQuestionSlice.actions.isLoading(false))
      // dispatch(qnaQuestionSlice.actions.isSaving(false))
    }
  },

  upvoteQuestion: question => async (dispatch) => {
    try {
      const response = await API.qna.questions.upvote(question)
      dispatch(entitySlice.actions.add({ data: response.data }))

      dispatch(showToastMessage({ message: I18N('vote_up'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      dispatch(showToastMessage({ message: error.message, type: 'warn' }))
    }
  },

  downvoteQuestion: question => async (dispatch) => {
    try {
      const response = await API.qna.questions.downvote(question)
      dispatch(entitySlice.actions.add({ data: response.data }))

      dispatch(showToastMessage({ message: I18N('vote_down'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      dispatch(showToastMessage({ message: error.message, type: 'warn' }))
    }
  },

  deleteQuestion: question => async (dispatch) => {
    try {
      await API.qna.questions.destroy(question)

      dispatch(entitySlice.actions.remove({ type: 'qnaQuestion', id: question.id }))
      dispatch(showToastMessage({ message: I18N('successfully_deleted'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      dispatch(showToastMessage({ message: error.message, type: 'warn' }))
    }
  },

  unvoteQuestion: question => async (dispatch) => {
    try {
      const response = await API.qna.questions.unvote(question)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('successfully_unvoted'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))

      dispatch(showToastMessage({ message: error.message, type: 'warn' }))
    }
  },
}

_.assign(qnaQuestionSlice, { asyncActions })

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
_.assign(qnaQuestionSlice, {
  selectors: {
    getQuestionPageData: questionId => (state) => {
      const { meta } = state.qnaQuestions

      const question = build(state.entities, 'qnaQuestion', questionId)

      return { question, meta, ...state.qnaQuestions }
    },

    getQuestionFormData: () => (state) => {
      const { isQuestionFormVisible, pendingQuestionContent, meta } = state.qnaQuestions

      return { isQuestionFormVisible, pendingQuestionContent, meta }
    },

    getQuestionsByEventSlugOrId: eventSlug => (state) => {
      const { meta } = state.qnaQuestions
      const questionIds = Object.keys(state.entities.qnaQuestion || {})

      const questionsMapping = questionIds.map(id => build(state.entities, 'qnaQuestion', id))
      let questions

      // if we get an id we can then use id instead of slug
      if (!isNaN(eventSlug)) {
        questions = questionsMapping.filter(q => q.event?.id === eventSlug)
      } else {
        questions = questionsMapping.filter(q => q.event?.slug === eventSlug)
      }

      return { questions, meta }
    },

    getEventsListPageData: () => state => state.qnaQuestions.meta.isLoadingRecentQuestions,

    getRecentQuestions: () => state => state.qnaQuestions.recentQuestionIds.map(id => build(state.entities, 'qnaQuestion', id)),

    getAcceptedAnswer: questionId => (state) => {
      const question = build(state.entities, 'qnaQuestion', questionId)

      const acceptedAnswer = _.find(question?.answers, { isAccepted: true })

      return { acceptedAnswer }
    },

    getVerifiedAnswer: questionId => (state) => {
      const question = build(state.entities, 'qnaQuestion', questionId)

      const verifiedAnswer = _.find(question?.answers, { isVerified: true })

      return { verifiedAnswer }
    },

    getIcebreakerTemplatesData: () => (state) => {
      const { icebreakerTemplates, meta } = state.qnaQuestions

      return { icebreakerTemplates, meta }
    },
  },
})

export { initialState }
export default qnaQuestionSlice
