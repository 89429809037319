import React, { useEffect, useState } from 'react'

import CloseIcon from 'components/icons/closeIcon'
import ToggleSwitch from 'components/common/toggleSwitch'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import feedbackSlice from 'redux/slices/feedback'
import pageSlice from 'redux/slices/pages'

const I18N = i18nPath('views.page_feedback')

const FeedbackItem = ({
  feedback: {
    user,
    comment,
    incomplete,
    difficultToUnderstand,
    inaccurateOrIrrelevant,
    missingOrBrokenLinks,
    createdAt,
  },
}) => (
  <div className='d-flex flex-column bordered-bottom py-2 mb-2 w-100'>
    <div className='d-flex flex-row align-items-center mb-1 flex-wrap'>
      <EmployeeLinkWithPopover user={user} />
      <div className='mx-2 text-secondary text-small'>
        {' • '}
        {i18nMoment(createdAt).format('MMM D, YYYY')}
      </div>
    </div>

    <ul>
      {incomplete && <li className='text-small'>{I18N('incomplete')}</li>}
      {difficultToUnderstand && <li className='text-small'>{I18N('difficult_to_understand')}</li>}
      {inaccurateOrIrrelevant && <li className='text-small'>{I18N('inaccurate_or_irrelevant')}</li>}
      {missingOrBrokenLinks && <li className='text-small'>{I18N('missing_or_broken_links')}</li>}
    </ul>

    <p className='text-small'>{comment}</p>
  </div>
)

const PageFeedbackNav = ({
  page,
  onClose,
}) => {
  const dispatch = useDispatch()

  const { feedbackEnabled, showFeedbackRatings } = page

  const { queryParams } = useSelector(feedbackSlice.selectors.getMetaData())
  const totalFeedback = queryParams.total || 0
  const feedbacks = useSelector(feedbackSlice.selectors.getFeedbacks())
  const hasMoreFeedback = totalFeedback > feedbacks.length

  const fetchData = (currentPage = 1) => {
    dispatch(feedbackSlice.asyncActions.fetchAll({
      feedbackableId: page.id,
      feedbackableType: 'pages',
      queryParams: { ...queryParams, page: currentPage },
    }))
  }

  useEffect(() => {
    fetchData()

    return () => {
      dispatch(feedbackSlice.actions.clearFeedbackIds())
    }
  }, [])

  const handleSeeMore = () => {
    // Only fetch more feedback if there is more feedback to fetch
    if (hasMoreFeedback) fetchData(queryParams.page! + 1)
  }

  const toggleFeedback = () => {
    dispatch(pageSlice.asyncActions.update({
      id: page.id,
      feedbackEnabled: !feedbackEnabled,
    }))
  }

  const toggleShowRatings = () => {
    dispatch(pageSlice.asyncActions.update({
      id: page.id,
      showFeedbackRatings: !showFeedbackRatings,
    }))
  }

  return (
    <div className='PageFeedbackNav d-flex flex-column flex-shrink-0 align-items-start p-4'>
      <div>
        <h5>{I18N('title')}</h5>
        <span className='top-0 right-0' onClick={onClose}>
          <CloseIcon />
        </span>
      </div>

      <p className='text-secondary text-small'>{I18N('description')}</p>

      <ToggleSwitch
        checked={feedbackEnabled}
        onChange={toggleFeedback}
        text={I18N('enable_feedback')}
        textPosition='after'
      />
      {feedbackEnabled && (
        <ToggleSwitch
          checked={showFeedbackRatings}
          onChange={toggleShowRatings}
          text={I18N('show_counter')}
          textPosition='after'
        />
      )}

      <p className='text-secondary text-small mt-4 font-weight-500'>{I18N('feedback_title', { count: totalFeedback })}</p>

      {totalFeedback === 0 ? (
        <p className='text-secondary text-small'>{I18N('empty_state')}</p>
      ) : (
        <div className='d-flex flex-column w-100'>
          {feedbacks.map(feedback => (
            <FeedbackItem feedback={feedback} key={`feedback-${feedback.id}`} />
          ))}
        </div>
      )}

      {hasMoreFeedback && (
        <p className='pointer link-color text-small font-weight-600' onClick={handleSeeMore}>{I18NCommon('see_more')}</p>
      )}
    </div>
  )
}

export default PageFeedbackNav
