import React from 'react'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const pencilIconPath = '/images/pencilIcon.svg'

const EditableContentTrigger = ({
  canEdit,
  isEmpty,
  onClick,
  displayElement,
  className = '',
}) => (
  <span
    className={classNames('EditableContent-Display', {
      canEdit, isEmpty, isTrigger: true, isTriggerable: !!onClick,
    }, className)}
    onClick={onClick}
  >
    {displayElement}
    <CdnSvg src={pencilIconPath} className='pencilIcon ml-1' />
  </span>
)

export default EditableContentTrigger
