import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import { randomReactKey } from 'components/common/utils'
import React, { useState } from 'react'

const WidgetsCategory = ({
  label,
  widgets,
  setSelectedNewWidget,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  return (
    <div className='WidgetsCategory mb-4'>
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        className='pointer d-flex justify-content-between align-items-center mb-3'
      >
        <div className='text-secondary'>{label}</div>
        <CdnSvg className={classNames('TriangleIcon', isCollapsed && 'TriangleIcon-right')} src='/images/triangleDown.svg' />
      </div>
      {!isCollapsed && (
        <div className='d-flex flex-wrap gap-3'>
          {widgets.map((widget, index) => (
            <div
              key={index}
              className='WidgetOption droppable-element'
              draggable
              unselectable='on'
              // this is a hack for firefox
              // Firefox requires some kind of initialization
              // which we can do by adding this attribute
              // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
              onDragStart={(e) => {
                e.dataTransfer?.setData('text/plain', '')
                const id = `new-${randomReactKey()}`
                setSelectedNewWidget({ ...widget, id, i: id })
              }}
            >
              <CdnSvg className='mb-1' src={widget.iconPath} />
              <div className='text-smallest text-center'>{widget.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default WidgetsCategory
