import OnboardingBuddiesWidget from 'components/home/widgets/onboarding_buddies_widget/onboardingBuddiesWidget'
import ProfileWidget from 'components/home/widgets/profileWidget'
import TimezonesWidget from 'components/home/widgets/timezonesWidget'
import LinksWidget from 'components/home/widgets/links_widget/linksWidget'
import FeaturedEventWidget from 'components/home/widgets/featuredEventWidget'
import CelebrationsWidget from 'components/home/widgets/celebrationsWidget'
import CalendarWidget from 'components/home/widgets/calendarWidget'
import GroupsWidget from 'components/home/widgets/groupsWidget'
import TasksWidget from 'components/home/widgets/tasksWidget'
import { i18nPath } from 'utils/i18nHelpers'
import FeedWidget from 'components/home/widgets/feedWidget'
import SurveysWidget from 'components/home/widgets/surveysWidget'
import ShoutoutSuggestionsWidget from 'components/home/widgets/shoutoutSuggestionsWidget'
import ButtonWidget from 'components/home/widgets/buttonWidget'
import CarouselCardsWidget from 'components/home/widgets/carousel_cards/carouselCardsWidget'
import MustReadsWidget from 'components/home/widgets/mustReadsWidget'
import SocialShareWidget from 'components/home/widgets/socialShareWidget'
import ArticleTypeWidgetsSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/articleTypeWidgetsSettingsSidebar'
import ButtonWidgetSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/buttonWidgetSettingsSidebar'
import CelebrationsWidgetSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/celebrationsWidgetSettingsSidebar'
import FeedWidgetSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/feedWidgetSettingsSidebar'
import LinksWidgetSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/links_widget_settings/linksWidgetSettingsSidebar'
import AutoResizableSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/autoResizableSettingsSidebar'
import ShoutoutSuggestionsWidgetSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/shoutoutSuggestionsWidgetSettingsSidebar'
import RecentNewsWidget from 'components/home/widgets/recentNewsWidget'
import RecentNewsSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/recentNewsSettingsSidebar'
import CarouselCardsWidgetSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/carouselCardsWidgetSettingsSidebar'
import RecognitionWidget from 'components/home/widgets/recognition_widget/recognitionWidget'
import RecognitionWidgetSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/recognitionWidgetSettingsSidebar'
import PreboardingCarouselCardsWidget from 'components/home/widgets/carousel_cards/preboardingCarouselCardsWidget'
import PreboardingFeedWidget from 'components/home/widgets/preboardingFeedWidget'
import PreboardingFeedWidgetSettingsSidebar from 'components/admin/layout/layout_collection_sidebar/preboardingFeedWidgetSettingsSidebar'

const I18NWidgets = i18nPath('views.admin.home_editor.widgets')
const I18NCategories = i18nPath('views.admin.home_editor.widget_categories')

export const BUTTON_WIDGET_TYPE = 'Layout::Widgets::ButtonWidget'
export const CALENDAR_WIDGET_TYPE = 'Layout::Widgets::CalendarWidget'
export const CAROUSEL_CARDS_WIDGET_TYPE = 'Layout::Widgets::CarouselCardsWidget'
export const CELEBRATIONS_WIDGET_TYPE = 'Layout::Widgets::CelebrationsWidget'
export const FEATURED_EVENT_WIDGET_TYPE = 'Layout::Widgets::FeaturedEventWidget'
export const FEED_WIDGET_TYPE = 'Layout::Widgets::FeedWidget'
export const GROUPS_WIDGET_TYPE = 'Layout::Widgets::GroupsWidget'
export const LINKS_WIDGET_TYPE = 'Layout::Widgets::LinksWidget'
export const MUST_READS_WIDGET_TYPE = 'Layout::Widgets::MustReadsWidget'
export const ONBOARDING_BUDDIES_WIDGET_TYPE = 'Layout::Widgets::OnboardingBuddiesWidget'
export const PREBOARDING_CAROUSEL_CARDS_WIDGET_TYPE = 'Layout::Widgets::PreboardingCarouselCardsWidget'
export const PREBOARDING_FEED_WIDGET_TYPE = 'Layout::Widgets::PreboardingFeedWidget'
export const PROFILE_WIDGET_TYPE = 'Layout::Widgets::ProfileWidget'
export const RECENT_NEWS_WIDGET_TYPE = 'Layout::Widgets::RecentNewsWidget'
export const RECOGNITION_WIDGET_TYPE = 'Layout::Widgets::RecognitionWidget'
export const SHOUTOUT_SUGGESTIONS_WIDGET_TYPE = 'Layout::Widgets::ShoutoutSuggestionsWidget'
export const SOCIAL_SHARE_WIDGET_TYPE = 'Layout::Widgets::SocialShareWidget'
export const SURVEYS_WIDGET_TYPE = 'Layout::Widgets::SurveysWidget'
export const TASKS_WIDGET_TYPE = 'Layout::Widgets::TasksWidget'
export const PREBOARDING_TASKS_WIDGET_TYPE = 'Layout::Widgets::PreboardingTasksWidget'
export const TIMEZONES_WIDGET_TYPE = 'Layout::Widgets::TimezonesWidget'
export const PREBOARDING_LINKS_WIDGET_TYPE = 'Layout::Widgets::PreboardingLinksWidget'

const widgetsMap = {
  [BUTTON_WIDGET_TYPE]: {
    type: BUTTON_WIDGET_TYPE,
    defaultSettings: {
      text: I18NWidgets('button_defaults.text'),
      secondaryText: I18NWidgets('button_defaults.secondary_text'),
      textColor: '#ffffff',
      icon: null,
      backgroundColor: 'var(--btn-primary-color)',
      linkUrl: null,
    },
    Component: ButtonWidget,
    SettingsSidebarComponent: ButtonWidgetSettingsSidebar,
    label: I18NWidgets('button'),
    iconPath: '/images/linkIcon.svg',
    category: I18NCategories('content'),
    small: {
      minW: 2,
      minH: 4,
    },
    medium: {
      minW: 2,
      minH: 4,
    },
    large: {
      minW: 2,
      minH: 4,
    },
    autoResizable: false,
  },
  [CALENDAR_WIDGET_TYPE]: {
    type: CALENDAR_WIDGET_TYPE,
    Component: CalendarWidget,
    SettingsSidebarComponent: AutoResizableSettingsSidebar,
    label: I18NWidgets('calendar'),
    iconPath: '/images/calendarIcon.svg',
    category: I18NCategories('calendar_and_events'),
    small: {
      minW: 4,
      minH: 11,
    },
    medium: {
      minW: 3,
      minH: 11,
    },
    large: {
      minW: 3,
      minH: 11,
    },
    autoResizable: true,
  },
  [CAROUSEL_CARDS_WIDGET_TYPE]: {
    type: CAROUSEL_CARDS_WIDGET_TYPE,
    Component: CarouselCardsWidget,
    SettingsSidebarComponent: CarouselCardsWidgetSettingsSidebar,
    label: I18NWidgets('carousel_cards'),
    iconPath: '/images/tiptap/slash_commands/twoColumns.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 5,
      maxW: 4,
      maxH: 22,
    },
    medium: {
      minW: 3,
      minH: 5,
      maxW: 6,
      maxH: 22,
    },
    large: {
      minW: 6,
      minH: 5,
      maxW: 12,
      maxH: 22,
    },
    autoResizable: false,
  },
  [PREBOARDING_CAROUSEL_CARDS_WIDGET_TYPE]: {
    type: PREBOARDING_CAROUSEL_CARDS_WIDGET_TYPE,
    Component: PreboardingCarouselCardsWidget,
    SettingsSidebarComponent: CarouselCardsWidgetSettingsSidebar,
    label: I18NWidgets('carousel_cards'),
    iconPath: '/images/tiptap/slash_commands/twoColumns.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 5,
      maxW: 4,
      maxH: 22,
    },
    medium: {
      minW: 3,
      minH: 5,
      maxW: 6,
      maxH: 22,
    },
    large: {
      minW: 6,
      minH: 5,
      maxW: 12,
      maxH: 22,
    },
    autoResizable: false,
  },
  [CELEBRATIONS_WIDGET_TYPE]: {
    type: CELEBRATIONS_WIDGET_TYPE,
    Component: CelebrationsWidget,
    SettingsSidebarComponent: CelebrationsWidgetSettingsSidebar,
    label: I18NWidgets('celebrations'),
    iconPath: '/images/giftIcon.svg',
    category: I18NCategories('culture'),
    small: {
      minW: 4,
      minH: 11,
    },
    medium: {
      minW: 3,
      minH: 11,
    },
    large: {
      minW: 3,
      minH: 11,
    },
    autoResizable: true,
    defaultSettings: {
      splitIntoFollowedAndOthers: true,
    },
  },
  [FEATURED_EVENT_WIDGET_TYPE]: {
    type: FEATURED_EVENT_WIDGET_TYPE,
    Component: FeaturedEventWidget,
    SettingsSidebarComponent: AutoResizableSettingsSidebar,
    label: I18NWidgets('featured_event'),
    iconPath: '/images/app_launcher/starIcon.svg',
    category: I18NCategories('calendar_and_events'),
    small: {
      minW: 4,
      minH: 22,
    },
    medium: {
      minW: 3,
      minH: 22,
    },
    large: {
      minW: 3,
      minH: 22,
    },
    autoResizable: true,
  },
  [FEED_WIDGET_TYPE]: {
    type: FEED_WIDGET_TYPE,
    title: I18NWidgets('feed_defaults.title'),
    defaultSettings: {
      feedItems: {
        article: true,
        awardedBadge: true,
        celebration: true,
        journeyCommunication: true,
        shoutout: true,
      },
    },
    Component: FeedWidget,
    SettingsSidebarComponent: FeedWidgetSettingsSidebar,
    label: I18NWidgets('feed'),
    iconPath: '/images/newspaperIcon.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 35,
    },
    medium: {
      minW: 3,
      minH: 35,
    },
    large: {
      minW: 4,
      minH: 35,
    },
    autoResizable: false,
  },
  [PREBOARDING_FEED_WIDGET_TYPE]: {
    type: PREBOARDING_FEED_WIDGET_TYPE,
    title: '',
    Component: PreboardingFeedWidget,
    SettingsSidebarComponent: PreboardingFeedWidgetSettingsSidebar,
    label: I18NWidgets('feed'),
    iconPath: '/images/newspaperIcon.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 35,
    },
    medium: {
      minW: 3,
      minH: 35,
    },
    large: {
      minW: 4,
      minH: 35,
    },
    autoResizable: false,
  },
  [GROUPS_WIDGET_TYPE]: {
    type: GROUPS_WIDGET_TYPE,
    Component: GroupsWidget,
    SettingsSidebarComponent: AutoResizableSettingsSidebar,
    label: I18NWidgets('groups'),
    iconPath: '/images/personsIcon.svg',
    category: I18NCategories('user_widgets'),
    small: {
      minW: 4,
      minH: 11,
    },
    medium: {
      minW: 3,
      minH: 11,
    },
    large: {
      minW: 3,
      minH: 11,
    },
    autoResizable: true,
  },
  [LINKS_WIDGET_TYPE]: {
    type: LINKS_WIDGET_TYPE,
    title: I18NWidgets('links_defaults.title'),
    Component: LinksWidget,
    SettingsSidebarComponent: LinksWidgetSettingsSidebar,
    label: I18NWidgets('links'),
    iconPath: '/images/linkHorizontalIcon.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 11,
    },
    medium: {
      minW: 3,
      minH: 11,
    },
    large: {
      minW: 3,
      minH: 11,
    },
    autoResizable: true,
  },
  [PREBOARDING_LINKS_WIDGET_TYPE]: {
    type: PREBOARDING_LINKS_WIDGET_TYPE,
    title: I18NWidgets('links_defaults.title'),
    Component: LinksWidget,
    SettingsSidebarComponent: LinksWidgetSettingsSidebar,
    label: I18NWidgets('links'),
    iconPath: '/images/linkHorizontalIcon.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 11,
    },
    medium: {
      minW: 3,
      minH: 11,
    },
    large: {
      minW: 3,
      minH: 11,
    },
    autoResizable: true,
  },
  [MUST_READS_WIDGET_TYPE]: {
    type: MUST_READS_WIDGET_TYPE,
    title: I18NWidgets('must_reads_defaults.title'),
    Component: MustReadsWidget,
    SettingsSidebarComponent: ArticleTypeWidgetsSettingsSidebar,
    label: I18NWidgets('must_reads'),
    iconPath: '/images/alertOutlineIcon.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 10,
    },
    medium: {
      minW: 3,
      minH: 10,
    },
    large: {
      minW: 3,
      minH: 10,
      maxW: 8,
    },
    autoResizable: true,
  },
  [ONBOARDING_BUDDIES_WIDGET_TYPE]: {
    type: ONBOARDING_BUDDIES_WIDGET_TYPE,
    Component: OnboardingBuddiesWidget,
    SettingsSidebarComponent: AutoResizableSettingsSidebar,
    label: I18NWidgets('onboarding_buddies'),
    iconPath: '/images/presenterIcon.svg',
    category: I18NCategories('user_widgets'),
    small: {
      minW: 4,
      minH: 8,
      maxW: 4,
      maxH: 27,
    },
    medium: {
      minW: 3,
      minH: 8,
      maxW: 4,
      maxH: 27,
    },
    large: {
      minW: 3,
      minH: 8,
      maxW: 4,
      maxH: 27,
    },
    autoResizable: true,
  },
  [PROFILE_WIDGET_TYPE]: {
    type: PROFILE_WIDGET_TYPE,
    Component: ProfileWidget,
    label: I18NWidgets('profile'),
    iconPath: '/images/personIcon.svg',
    category: I18NCategories('user_widgets'),
    small: {
      minW: 4,
      minH: 4,
      maxH: 10,
    },
    medium: {
      minW: 3,
      minH: 4,
      maxH: 10,
    },
    large: {
      minW: 3,
      minH: 4,
      maxH: 10,
    },
    autoResizable: false,
  },
  [RECENT_NEWS_WIDGET_TYPE]: {
    type: RECENT_NEWS_WIDGET_TYPE,
    title: I18NWidgets('recent_news_defaults.title'),
    defaultSettings: {
      allArticles: true,
      articleTypeIds: [],
    },
    Component: RecentNewsWidget,
    SettingsSidebarComponent: RecentNewsSettingsSidebar,
    label: I18NWidgets('recent_news'),
    iconPath: '/images/recentNewsIcon.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 10,
    },
    medium: {
      minW: 3,
      minH: 10,
    },
    large: {
      minW: 3,
      minH: 10,
      maxW: 8,
    },
    autoResizable: true,
  },
  [RECOGNITION_WIDGET_TYPE]: {
    type: RECOGNITION_WIDGET_TYPE,
    title: I18NWidgets('recognition_defaults.title'),
    Component: RecognitionWidget,
    SettingsSidebarComponent: RecognitionWidgetSettingsSidebar,
    label: I18NWidgets('recognition'),
    iconPath: '/images/recognitionIcon.svg',
    category: I18NCategories('culture'),
    small: {
      minW: 4,
      minH: 10,
    },
    medium: {
      minW: 3,
      minH: 10,
    },
    large: {
      minW: 3,
      minH: 10,
      maxW: 8,
    },
  },
  [SHOUTOUT_SUGGESTIONS_WIDGET_TYPE]: {
    type: SHOUTOUT_SUGGESTIONS_WIDGET_TYPE,
    Component: ShoutoutSuggestionsWidget,
    SettingsSidebarComponent: ShoutoutSuggestionsWidgetSettingsSidebar,
    label: I18NWidgets('shoutout_suggestions'),
    iconPath: '/images/facePlusIcon.svg',
    category: I18NCategories('culture'),
    small: {
      minW: 4,
      minH: 15,
    },
    medium: {
      minW: 3,
      minH: 15,
    },
    large: {
      minW: 3,
      minH: 15,
    },
    autoResizable: false,
    defaultSettings: {
      orientation: 'horizontal',
    },
  },
  [SOCIAL_SHARE_WIDGET_TYPE]: {
    type: SOCIAL_SHARE_WIDGET_TYPE,
    title: I18NWidgets('social_share_defaults.title'),
    Component: SocialShareWidget,
    SettingsSidebarComponent: ArticleTypeWidgetsSettingsSidebar,
    label: I18NWidgets('social_share'),
    iconPath: '/images/tiptap/socialShare.svg',
    category: I18NCategories('content'),
    small: {
      minW: 4,
      minH: 10,
    },
    medium: {
      minW: 3,
      minH: 10,
    },
    large: {
      minW: 3,
      minH: 10,
      maxW: 8,
    },
    autoResizable: true,
  },
  [SURVEYS_WIDGET_TYPE]: {
    type: SURVEYS_WIDGET_TYPE,
    Component: SurveysWidget,
    SettingsSidebarComponent: AutoResizableSettingsSidebar,
    label: I18NWidgets('surveys'),
    iconPath: '/images/checkedClipboardIcon.svg',
    category: I18NCategories('content'),
    small: {
      minW: 3,
      minH: 11,
    },
    medium: {
      minW: 3,
      minH: 11,
    },
    large: {
      minW: 3,
      minH: 11,
    },
    autoResizable: true,
  },
  [TASKS_WIDGET_TYPE]: {
    type: TASKS_WIDGET_TYPE,
    Component: TasksWidget,
    SettingsSidebarComponent: AutoResizableSettingsSidebar,
    label: I18NWidgets('tasks'),
    iconPath: '/images/checkListIcon.svg',
    category: I18NCategories('user_widgets'),
    small: {
      minW: 4,
      minH: 11,
    },
    medium: {
      minW: 3,
      minH: 11,
    },
    large: {
      minW: 3,
      minH: 11,
    },
    autoResizable: true,
  },
  [PREBOARDING_TASKS_WIDGET_TYPE]: {
    type: PREBOARDING_TASKS_WIDGET_TYPE,
    Component: TasksWidget,
    SettingsSidebarComponent: AutoResizableSettingsSidebar,
    label: I18NWidgets('tasks'),
    iconPath: '/images/checkListIcon.svg',
    category: I18NCategories('user_widgets'),
    small: {
      minW: 4,
      minH: 11,
    },
    medium: {
      minW: 3,
      minH: 11,
    },
    large: {
      minW: 3,
      minH: 11,
    },
    autoResizable: true,
  },
  [TIMEZONES_WIDGET_TYPE]: {
    type: TIMEZONES_WIDGET_TYPE,
    Component: TimezonesWidget,
    SettingsSidebarComponent: AutoResizableSettingsSidebar,
    label: I18NWidgets('timezones'),
    iconPath: '/images/timerIcon.svg',
    category: I18NCategories('calendar_and_events'),
    small: {
      minW: 3,
      minH: 5,
    },
    medium: {
      minW: 2,
      minH: 5,
    },
    large: {
      minW: 3,
      minH: 5,
    },
    autoResizable: true,
  },
}

export default widgetsMap
