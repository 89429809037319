import React from 'react'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

const I18N = i18nPath('views.static.404')

const Error404 = () => (
  <div className='container'>
    <div className='mt-4'>
      <h1>{I18N('title')}</h1>
      <p>
        {i18nFormat(I18N('description'), <a onClick={() => (window.location = '/')}>{I18N('click_here')}</a>)}
        {' '}
      </p>
    </div>
  </div>
)

export default Error404
