import React from 'react'

const OrgChartIcon = () => (
  <svg className='OrgChartIcon' xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5 3.875C8.01777 3.875 8.4375 3.45527 8.4375 2.9375C8.4375 2.41973 8.01777 2 7.5 2C6.98223 2 6.5625 2.41973 6.5625 2.9375C6.5625 3.45527 6.98223 3.875 7.5 3.875ZM8.125 5.03441C9.02859 4.76549 9.6875 3.92844 9.6875 2.9375C9.6875 1.72938 8.70812 0.75 7.5 0.75C6.29188 0.75 5.3125 1.72938 5.3125 2.9375C5.3125 3.92844 5.97141 4.76549 6.875 5.03441V5.75H3.125C2.77982 5.75 2.5 6.02982 2.5 6.375V8.34059C1.59641 8.60951 0.9375 9.44656 0.9375 10.4375C0.9375 11.6456 1.91688 12.625 3.125 12.625C4.33312 12.625 5.3125 11.6456 5.3125 10.4375C5.3125 9.44656 4.65359 8.60951 3.75 8.34059V7H11.25V8.34059C10.3464 8.60951 9.6875 9.44656 9.6875 10.4375C9.6875 11.6456 10.6669 12.625 11.875 12.625C13.0831 12.625 14.0625 11.6456 14.0625 10.4375C14.0625 9.44656 13.4036 8.60951 12.5 8.34059V6.375C12.5 6.02982 12.2202 5.75 11.875 5.75H8.125V5.03441ZM3.125 11.375C3.64277 11.375 4.0625 10.9553 4.0625 10.4375C4.0625 9.91973 3.64277 9.5 3.125 9.5C2.60723 9.5 2.1875 9.91973 2.1875 10.4375C2.1875 10.9553 2.60723 11.375 3.125 11.375ZM10.9375 10.4375C10.9375 9.91973 11.3572 9.5 11.875 9.5C12.3928 9.5 12.8125 9.91973 12.8125 10.4375C12.8125 10.9553 12.3928 11.375 11.875 11.375C11.3572 11.375 10.9375 10.9553 10.9375 10.4375Z'
      fill='var(--link-color)'
    />
  </svg>
)

export default OrgChartIcon
