import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/common/modal'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import acknowledgementSlice from 'redux/slices/acknowledgements'
import RoundedAvatars from 'components/common/roundedAvatars'
import { Button, ButtonSecondary } from 'components/common/buttons'
import BellActive from 'components/icons/bellActive'

const I18N = i18nPath('views.admin.acknowledgements')

type Props = {
  acknowledgeableId: string
  type: 'article'
  buttonType?: 'link' | 'button'
}

const AcknowledgementRemindersButtonAndModal = ({ acknowledgeableId, type, buttonType = 'link' }: Props) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const acknowledgements = useSelector(acknowledgementSlice.selectors.getNotAcknowledged())
  const { isSendingReminders } = useSelector(acknowledgementSlice.selectors.getMetaData())

  const sendReminders = () => {
    dispatch(acknowledgementSlice.asyncActions.admin.sendReminders(acknowledgeableId, type, () => setIsOpen(false)))
  }

  useEffect(() => {
    if (isOpen) {
      dispatch(acknowledgementSlice.asyncActions.admin.fetchNotAcknowledged(acknowledgeableId, type))
    }
  }, [isOpen])

  return (
    <>
      {buttonType === 'button' ? (
        <Button onClick={() => setIsOpen(true)} className='AcknowledgementRemindersButton'>
          <>
            {I18N('send_reminders')}
            <BellActive className='ml-2' />
          </>
        </Button>
      ) : (
        <div className='link-color font-weight-600 pointer' onClick={() => setIsOpen(true)}>
          {I18N('send_reminders')}
        </div>
      )}
      {isOpen && (
        <Modal
          size='lg'
          visible={isOpen}
          className='AcknowledgementRemindersModal'
          toggle={() => setIsOpen(!isOpen)}
        >
          <h4>{I18N(`remind_pending_users.${type}`)}</h4>
          <div className='text-secondary mb-5'>{I18N('notified_by_preferred_channel')}</div>

          <div className='PendingUsers d-flex align-items-center px-4 py-3 mb-5'>
            <RoundedAvatars
              users={acknowledgements.map(a => a.user)}
              avatarSize='30px'
              className='mr-2'
            />
            <div>
              {I18N('will_be_reminded')}
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <ButtonSecondary onClick={() => setIsOpen(false)}>
              {I18NCommon('cancel')}
            </ButtonSecondary>
            <Button
              className='AcknowledgementRemindersButton ml-2'
              onClick={sendReminders}
              showLoadingSpinner={isSendingReminders}
              disabled={isSendingReminders}
            >
              <>
                {I18N('remind')}
                <BellActive className='ml-2' />
              </>
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default AcknowledgementRemindersButtonAndModal
