import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BarGraph from 'components/analytics/common/barGraph'
import { i18nPath } from 'utils/i18nHelpers'
import moduleAnalyticsSlice from 'redux/slices/analytics/modules'
import TargetingRulesType from 'types/audience/targetingRules'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.platform_analytics.overview.most_popular_modules')

interface MostPopularModulesType {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const MostPopularModules = ({
  periodStart,
  periodEnd,
  targetingRules,
}: MostPopularModulesType) => {
  const dispatch = useDispatch()
  const { pluralGroupDisplayName } = useCurrentCompany()

  const { data } = useSelector(moduleAnalyticsSlice.selectors.getMostPopular())
  const { mostPopular: { isLoading } } = useSelector(moduleAnalyticsSlice.selectors.getMetaData())

  const dataValues = data.map(({ value }) => Math.round(value * 100))
  const labels = data.map(({ appModule }) => (appModule === 'team' ? pluralGroupDisplayName : I18N(`labels.${appModule}`)))

  useEffect(() => {
    dispatch(moduleAnalyticsSlice.asyncActions.admin.fetchMostPopular({
      periodStart,
      periodEnd,
      targetingRules,
    }))
  }, [periodStart, periodEnd, targetingRules])

  const yAxisLabelKey = [
    'y_axis_label',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const tooltipLabelKey = [
    'tooltip_label',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  return (
    <div className='analytics-card grid-span-12'>
      <header className='d-flex flex-row justify-content-between'>
        <h3>{I18N('title')}</h3>
      </header>

      <BarGraph
        labels={labels}
        dataValues={dataValues}
        classname='w-100'
        displayYAxisLabel
        yAxisLabel={I18N(yAxisLabelKey)}
        tooltipLabel={I18N(tooltipLabelKey)}
        isLoading={isLoading}
      />
    </div>
  )
}

export default MostPopularModules
