import React from 'react'

import IntegrationCard from 'components/admin/integrations/integrationCard'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

interface Props {
  isInstalled: boolean,
  afterUninstalled: () => void
}

const SlackIntegrationCard = ({ isInstalled, afterUninstalled }: Props) => {
  const [onUninstall, { isLoading: isUninstalling }] = useApi(API.userIntegrations.uninstallSlackIntegration, {
    onSuccess: afterUninstalled,
  })

  return (
    <IntegrationCard
      id='slack_as_user'
      logo={<CdnSvg src='/images/slackLogoIcon.svg' />}
      isInstalled={isInstalled}
      docLink=''
      docTitle=''
      installationComponent={(
        <a className='btn btn-primary btn-sm btn-narrow' href='/auth/slackoauth/user_redirect_url'>
          {I18NCommon('authorize')}
        </a>
      )}
      onUninstall={onUninstall}
      isUninstalling={isUninstalling}
    />
  )
}

export default SlackIntegrationCard
