import React, { useEffect, useState } from 'react'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import CdnSvg from 'components/common/cdnSvg'
import { useDebounce } from 'usehooks-ts'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import classNames from 'classnames'
import NameTakenErrorMessage from 'components/links/nameTakenErrorMessage'

const I18N = i18nPath('views.golinks_editor')

interface Alias {
  id: string
  name: string
  user: {
    id: string
  }
}

interface GoLinkAliasEditableProps {
  goLinkId: string
  alias: Alias
  setAliases: React.Dispatch<React.SetStateAction<Alias[]>>
}

const GoLinkAliasEditable: React.FC<GoLinkAliasEditableProps> = ({ goLinkId, alias, setAliases }) => {
  const currentUser = useCurrentUser()

  const [name, setName] = useState(alias.name)
  const [nameTaken, setNameTaken] = useState(false)
  const debouncedName = useDebounce(name, 500)

  const [updateAlias] = useApi(API.golinks.aliases.update, {
    toastSuccessMessage: I18N('alias_updated'),
    onSuccess: (updatedAlias: Alias) => {
      setAliases(prevAliases => prevAliases.map((a) => {
        if (a.id === updatedAlias.id) {
          return updatedAlias
        }
        return a
      }))
      setNameTaken(false)
    },
    onError: () => setNameTaken(true),
  })

  const [deleteAlias] = useApi(API.golinks.aliases.destroy, {
    toastSuccessMessage: I18N('alias_deleted'),
    onSuccess: () => {
      setAliases(prevAliases => prevAliases.filter(a => a.id !== alias.id))
    },
  })

  const handleDelete = () => {
    if (window.confirm(I18N('confirm_delete_alias'))) {
      deleteAlias({ goLinkId, aliasId: alias.id })
    }
  }

  const canManageAlias = alias.user.id === currentUser.id || currentUser.permissions.superAdmin

  useEffect(() => {
    if (!debouncedName || alias.name === debouncedName) return

    updateAlias({ goLinkId, aliasId: alias.id, name: debouncedName })
  }, [debouncedName])

  return (
    <div className='mb-3'>
      <div key={alias.id} className='input-group'>
        <CdnSvg className='mr-2' src='/images/upward-corner-arrow.svg' />
        <div className='input-group-prepend'>
          <span className='input-group-text'>
            go/
          </span>
        </div>
        <input
          className={classNames('form-control', nameTaken && 'is-invalid')}
          value={name}
          onChange={e => setName(e.target.value)}
          readOnly={!canManageAlias}
        />
        <div className='ml-2 pt-1'>
          <EmployeeLinkWithPopover user={alias.user} hideName />
        </div>
        {canManageAlias && (
          <IconButtonWithPopover
            id='delete'
            text={I18NCommon('delete')}
            icon={<CdnSvg src='/images/trashIcon.svg' />}
            onClick={handleDelete}
            className='ml-2 pt-1'
            popoverPlacement='top'
          />
        )}
      </div>
      {nameTaken && <NameTakenErrorMessage />}
    </div>
  )
}

export default GoLinkAliasEditable
