import { createSlice } from '@reduxjs/toolkit'
import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import build from 'redux-object'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import appSignal from 'services/appSignal'
import { checkForError } from 'utils/errorHandling'

export const initialState = {
  meta: {
    ...defaultMeta,
  },
}

const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

const asyncActions = {
  admin: {
    fetchTags: () => async (dispatch) => {
      dispatch(tagSlice.actions.isLoading(true))

      try {
        const response = await API.admin.tags.fetchAll()
        dispatch(entitySlice.actions.add({
          data: response.data,
        }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(tagSlice.actions.isLoading(false))
      }
    },

    createTag: (tag, onSuccess = () => {}) => async (dispatch) => {
      dispatch(tagSlice.actions.setError(null))
      dispatch(tagSlice.actions.isSaving(true))

      try {
        const response = await API.admin.tags.create(tag)
        dispatch(entitySlice.actions.add({ data: response.data }))

        onSuccess()
      } catch (e) {
        const { error } = checkForError(e.response)
        dispatch(tagSlice.actions.setError(error))

        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(tagSlice.actions.isSaving(false))
      }
    },

    updateTag: tag => async (dispatch) => {
      dispatch(tagSlice.actions.isSaving(true))

      try {
        const response = await API.admin.tags.update(tag)
        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(tagSlice.actions.isSaving(false))
      }
    },

    deleteTag: tag => async (dispatch) => {
      dispatch(tagSlice.actions.isSaving(true))

      try {
        await API.admin.tags.destroy(tag)
        dispatch(entitySlice.actions.remove({ id: tag.id, type: 'tag' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(tagSlice.actions.isSaving(false))
      }
    },
  },

  fetchTags: () => async (dispatch) => {
    dispatch(tagSlice.actions.isLoading(true))

    try {
      const response = await API.tags.fetchAll()
      dispatch(entitySlice.actions.add({
        data: response.data,
      }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(tagSlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => state => state.tags.meta,
  getTags: () => state => build(state.entities, 'tag') || [],
}

export default {
  ...tagSlice,
  asyncActions,
  selectors,
}
