import React from 'react'

import TabHeader from 'components/analytics/helpers/tabHeader'
import ContentCreated from 'components/analytics/news/contentCreated'
import MostEngagingArticles from 'components/analytics/news/mostEngagingArticles'
import MostEngagingAuthors from 'components/analytics/news/mostEngagingAuthors'
import NewsEngagement from 'components/analytics/news/newsEngagement'
import NewsSummary from 'components/analytics/news/newsSummary'
import useTabHeader from 'components/analytics/helpers/useTabHeader'

const NewsTab = () => {
  const {
    targetingRules,
    setTargetingRules,
    completedTargetingRules,
    setGroupBy,
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    groupBy,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  return (
    <main className='NewsTab'>
      <TabHeader
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        setGroupBy={setGroupBy}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      <NewsSummary
        periodStart={periodStart}
        periodEnd={periodEnd}
      />

      <NewsEngagement
        periodStart={periodStart}
        periodEnd={periodEnd}
        groupBy={groupBy}
        targetingRules={completedTargetingRules}
      />

      <MostEngagingArticles
        periodStart={periodStart}
        periodEnd={periodEnd}
      />

      <MostEngagingAuthors
        periodStart={periodStart}
        periodEnd={periodEnd}
      />

      <ContentCreated
        periodStart={periodStart}
        periodEnd={periodEnd}
        groupBy={groupBy}
      />
    </main>
  )
}

export default NewsTab
