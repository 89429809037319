import React from 'react'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import FileSelectDropzone from 'components/common/file_select_dropzone'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.cover_image')
const ImageIconPath = '/images/imageIcon.svg'


const AddCoverButton = ({
  isUploading,
  handleUploadCoverImage,
  hasUploaded = false,
  className,
}) => (
  <FileSelectDropzone
    data-testid='cy_dropzone_file_selector'
    disabled={isUploading}
    onDrop={handleUploadCoverImage}
    className={classNames('AddCover btn btn-secondary d-inline-flex mb-3', className)}
  >
    <span className='mr-2 d-flex align-items-center'>
      <CdnSvg className='ImageIcon d-inline-flex' src={ImageIconPath} />
    </span>
    {hasUploaded && <span className='text-normal'>{I18N('update_cover')}</span>}
    {!hasUploaded && <span className='text-normal'>{I18N('add_cover')}</span>}
    {isUploading && <CirclesLoadingIndicator className='LoadingIndicator' />}
  </FileSelectDropzone>
)

export default AddCoverButton
