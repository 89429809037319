import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import getCdnUrl from 'utils/getCdnUrl'
import eventCoverImageSlice from 'redux/slices/eventCoverImage'
import { TEMPLATES } from 'components/qna/event_creation_steps/eventTemplateList'

import CoverImageWidget from 'components/common/images/coverImageWidget'

const EventCoverImage = (
  {
    event: {
      id,
      templateName,
      coverImageUrl,
    },
    canEditEvent,
  }) => {
  const dispatch = useDispatch()
  const cdnDomain = useSelector(({ cdnDomain }) => cdnDomain)
  const templateCoverImage = getCdnUrl(
    cdnDomain, TEMPLATES.find(template => template.id === templateName)?.image_url_wide
  )

  const getCoverImageUrl = () => {
    if (!coverImageUrl && templateName) return templateCoverImage

    return coverImageUrl
  }

  const { isUploading, isRemoving } = useSelector(eventCoverImageSlice.selectors.getCoverImageMetaData())

  const handleRemoveCoverImage = () => {
    if (!coverImageUrl && templateName) {
      dispatch(eventCoverImageSlice.asyncActions.removeTemplateImage({ id, templateName: null }))
    } else {
      dispatch(eventCoverImageSlice.asyncActions.removeCoverImage(id))
    }
  }

  return (
    <CoverImageWidget
      showActions={canEditEvent}
      className='EventCoverImage position-relative d-flex flex-column'
      isRemoving={isRemoving}
      isUploading={isUploading}
      handleRemoveCoverImage={handleRemoveCoverImage}
      coverImageUrl={getCoverImageUrl()}
      slice={eventCoverImageSlice}
      id={id}
    />
  )
}

export default EventCoverImage
