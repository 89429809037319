import {
  WINDOW_SIZE_LG, WINDOW_SIZE_MD, WINDOW_SIZE_SM, WINDOW_SIZE_XL
} from 'components/common/hooks/useWindowResize'

// values from _mixins.scss default-page-grid
const MAX_MD_WIDTH = 752
const MAX_LG_WIDTH = 984
const MAX_XL_WIDTH = 1290

// value from customHomePage.scss
const CUSTOM_HOME_PAGE_PADDING = 10

export const ROW_HEIGHT = 1
export const MARGIN_X = 30
export const MARGIN_Y = 22
export const MARGINS = [MARGIN_X, MARGIN_Y] as [number, number]
export const CONTAINER_PADDING = [0, 10] as [number, number]

const useLayoutSize = (width) => {
  if (width < WINDOW_SIZE_SM) {
    return {
      size: 'small',
      cols: 4,
      width: width - 2 * CUSTOM_HOME_PAGE_PADDING,
    }
  } else if (width < WINDOW_SIZE_MD) {
    return {
      size: 'medium',
      cols: 6,
      width: width - 2 * CUSTOM_HOME_PAGE_PADDING,
    }
  } else if (width < WINDOW_SIZE_LG + 2 * CUSTOM_HOME_PAGE_PADDING) {
    return {
      size: 'medium',
      cols: 6,
      width: MAX_MD_WIDTH,
    }
  } else if (width < WINDOW_SIZE_XL + 2 * CUSTOM_HOME_PAGE_PADDING) {
    return {
      size: 'large',
      cols: 12,
      width: MAX_LG_WIDTH,
    }
  } else {
    return {
      size: 'large',
      cols: 12,
      width: MAX_XL_WIDTH,
    }
  }
}

export default useLayoutSize
