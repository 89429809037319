import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useQueryParams from 'components/common/hooks/useQueryParams'

const useShowPublishedSuccessModal = () => {
  const { showPublishedSuccessModal } = useQueryParams()
  const history = useHistory()
  const [isPublishedSuccessModalOpen, setIsPublishedSuccessModalOpen] = useState(showPublishedSuccessModal)

  useEffect(() => {
    if (showPublishedSuccessModal) {
      history.replace({
        pathname: history.location.pathname,
        search: '',
      })
    }
  }, [])

  return {
    isPublishedSuccessModalOpen,
    closePublishedSuccessModal: () => setIsPublishedSuccessModalOpen(false),
  }
}

export default useShowPublishedSuccessModal
