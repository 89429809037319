import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import FormEditableField from 'components/form_fields/formEditableField'
import { SurveyQuestionType, QuestionTypeEnum } from 'types/surveys/question'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'

const I18N = i18nPath('views.admin.surveys')

interface TextAnswerContentProps {
  question: SurveyQuestionType,
  answer?: any,
  updateAnswer?: (changes: any) => void,
  isDisabled?: boolean,
}

const TextAnswerContent = ({
  answer,
  question,
  updateAnswer = (changes: any) => { },
  isDisabled = false,
}: TextAnswerContentProps) => {
  const questionType = question?.type
  const isHtml = questionType === QuestionTypeEnum.Html

  if (isDisabled) {
    return (
      <div className='TextAnswerContent'>
        {isHtml ? (
          <TipTapRichTextView
            html={answer?.string || ''}
          />
        ) : (
          <span className='ws-pre-wrap'>{answer?.string}</span>
        )}
      </div>
    )
  }

  if (isHtml) {
    return (
      <div className='TextAnswerContent'>
        <TiptapEditor
          className='RoundedTiptapEditor w-100 mt-4'
          html={answer?.string || ''}
          key={question.id}
          toolbarItems={SMALL_TOOLBAR}
          configuration={{ placeholder: I18N('answers.your_answer_placeholder') }}
          onChange={value => updateAnswer({ string: value, type: 'string' })}
        />
      </div>
    )
  }

  return (
    <div className='TextAnswerContent'>
      <FormEditableField
        className='mt-4'
        label={undefined} // Hide label
        currentValue={answer?.string || ''}
        onChange={({ target }) => updateAnswer({ string: target?.value, type: 'string' })}
        isRequired
        placeholder={I18N('answers.your_answer_placeholder')}
        textarea={question?.type === QuestionTypeEnum.Paragraph}
        disabled={isDisabled}
      />
    </div>
  )
}

export default TextAnswerContent
