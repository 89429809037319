import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import userSlice from 'redux/slices/users'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import { showToastMessage } from 'redux/slices/toasts'
import MergeUsersModal from 'components/admin/users/mergeUsersModal'

const I18N = i18nPath('views.admin.users.dropdown_menu')

const ActionsDropdown = ({ user, fetchUsers }) => {
  const dispatch = useDispatch()
  const [isInactivatingUser, setIsInactivatingUser] = useState()

  const [showMergeUsersModal, setShowMergeUsersModal] = useState()

  const inactivateUser = async () => {
    setIsInactivatingUser(true)

    const onSuccess = () => {
      setIsInactivatingUser(false)
      dispatch(showToastMessage({ message: I18N('user_inactivated'), type: 'success' }))
      fetchUsers()
    }
    // removing preboarding as well so inactive user does not show up in preboarding tab
    dispatch(userSlice.asyncActions.admin.updateUser({ id: user.id, preboarding: false, active: false }, {}, onSuccess))
  }

  return (
    <>
      <DropdownMenuContainer menuType='kebab'>
        <CardDropdownMenuItem
          link={`/admin/users/${user.id}`}
          primaryText={I18N('edit')}
        />
        <CardDropdownMenuItem
          onClick={() => setShowMergeUsersModal(!showMergeUsersModal)}
          primaryText={I18N('merge')}
        />
        <CardDropdownMenuItem
          onClick={inactivateUser}
          primaryText={isInactivatingUser ? I18N('loading') : I18N('inactivate')}
        />
      </DropdownMenuContainer>
      {showMergeUsersModal && (
        <MergeUsersModal
          isOpen={showMergeUsersModal}
          toggle={() => setShowMergeUsersModal(!showMergeUsersModal)}
          sourceUser={user}
          fetchUsers={fetchUsers}
        />
      )}
    </>
  )
}

export default ActionsDropdown
