import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import CopyPageModal from 'components/pages/actions_dropdown/copyPageModal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import UnpublishPageModal from 'components/pages/actions_dropdown/unpublishPageModal'
import TransferPageOwnershipModal from 'components/pages/actions_dropdown/transferPageOwnershipModal'
import ChangePageWorkspaceModal from 'components/pages/actions_dropdown/changePageWorkspaceModal'
import EditPageURLModal from 'components/pages/actions_dropdown/editPageURLModal'
import ArchivePageModal from 'components/pages/actions_dropdown/archivePageModal'
import SetExpirationModal from 'components/pages/actions_dropdown/setExpirationModal'
import PageDeleteModal from 'components/pages/pageDeleteModal'
import { FEEDBACK_NAV, TEMPLATES_NAV } from 'pages/pages/pageEditorPage'
import SaveAsTemplateModal from 'components/pages/actions_dropdown/saveAsTemplateModal'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const copyIconPath = '/images/duplicateIcon.svg'
const swapPeopleIconPath = '/images/swapPeopleIcon.svg'
const eyeOpenIconPath = '/images/eyeOpenIcon.svg'
const eyeStrikethroughIconPath = '/images/eyeStrikethroughIcon.svg'
const linkHorizontalIconPath = '/images/linkHorizontalIcon.svg'
const listIconPath = '/images/app_launcher/listIcon.svg'
const diskIconPath = '/images/diskIcon.svg'
const pageIconPath = '/images/pageIcon.svg'
const archiveBoxIconPath = '/images/archiveBoxIcon.svg'
const timerIconPath = '/images/timerIcon.svg'
const barChartIconPath = '/images/analytics/barChartIcon.svg'
const feedbackIconPath = '/images/answerIcon.svg'
const moveSquareIconPath = '/images/moveSquareIcon.svg'
const trashIconPath = '/images/trashIcon.svg'
const clockGoingBackwardsIconPath = '/images/clockGoingBackwardsIcon.svg'

const TRANSFER_OWNERSHIP_MODAL = 'transfer_ownership_modal'
const UNPUBLISH_PAGE_MODAL = 'unpublish_page_modal'
const EDIT_URL_MODAL = 'edit_url_modal'
const COPY_PAGE_MODAL = 'copy_page_modal'
const SAVE_AS_TEMPLATE_MODAL = 'save_as_template_modal'
const DELETE_PAGE_MODAL = 'delete_page_modal'
const ARCHIVE_PAGE_MODAL = 'archive_page_modal'
const SET_EXPIRATION_MODAL = 'set_expiration_modal'
const CHANGE_WORKSPACE_MODAL = 'change_workspace_modal'

const I18N = i18nPath('views.pages.actions_dropdown')

const PageDropdown = ({ page, setCurrentRightNav }) => {
  const history = useHistory()
  const {
    permittedActions,
    draftTitle,
    draft: isDraft,
  } = page
  const { copy: canCopy, changePageOwner: canChangePageOwner, canArchivePage } = permittedActions

  const historyPagePath = useWorkspaceUrls().generatePathToPage(page, 'history')

  const [currentOpenModal, setCurrentOpenModal] = useState<null | string>(null)
  const closeModal = () => setCurrentOpenModal(null)

  const [copyPage, { isLoading: isCopying }] = useApi(API.pages.copy, {
    onSuccess: (newPage) => {
      closeModal()
      history.push(`/pages/${newPage.id}/edit`)
    },
  })

  const [revertToDraft, { isLoading: isRevertingToDraft }] = useApi(() => API.pages.revertToDraft(page.id), {
    updateEntitySlice: true,
    onSuccess: closeModal,
    toastSuccessMessage: I18N('page_updated'),
  })

  const [addTableOfContents] = useApi(
    () => API.pages.update({ id: page.id, showTableOfContents: true }),
    {
      updateEntitySlice: true,
      onSuccess: closeModal,
      broadcastRecordChanges: true,
    }
  )

  const [toggleFaqs] = useApi(
    () => API.pages.toggleFAQs(page.id),
    {
      updateEntitySlice: true,
      broadcastRecordChanges: true,
      toastSuccessMessage: I18N(page.showFaqs ? 'faqs_hidden_success' : 'faqs_shown_success'),
      toastErrorMessage: I18N('faqs_update_error'),
    }
  )

  const { settings } = useCurrentCompany()

  return (
    <div className='PageDropdown'>
      <DropdownMenuContainer iconWidth='20px' iconHeight='5px' closeMenuOnItemClick>
        {canCopy && (
          <CardDropdownMenuItem
            onClick={() => setCurrentOpenModal(COPY_PAGE_MODAL)}
            primaryText={<span className='mx-2'>{I18N('copy_page')}</span>}
            className='px-3'
            icon={<CdnSvg src={copyIconPath} className='CopyIcon' />}
            width='240px'
          />
        )}
        {!isDraft && (
          <>
            <CardDropdownMenuItem
              onClick={() => window.open(`${window.location.origin}${page.displayPath}`, '_blank', 'noopener, noreferrer')}
              primaryText={<span className='mx-3'>{I18N('preview_page')}</span>}
              className='px-3'
              icon={<CdnSvg src={eyeOpenIconPath} className='PreviewPageIcon' />}
              width='240px'
            />
            <CardDropdownMenuItem
              onClick={() => setCurrentOpenModal(UNPUBLISH_PAGE_MODAL)}
              primaryText={<span className='mx-3'>{I18N('unpublish_page')}</span>}
              className='px-3'
              icon={<CdnSvg src={eyeStrikethroughIconPath} className='UnpublishPageIcon' />}
              width='240px'
            />
          </>
        )}

        {canChangePageOwner && (
          <CardDropdownMenuItem
            onClick={() => setCurrentOpenModal(TRANSFER_OWNERSHIP_MODAL)}
            primaryText={<span className='mx-3'>{I18N('transfer_ownership')}</span>}
            className='px-3'
            icon={<CdnSvg src={swapPeopleIconPath} className='TransferOwnershipIcon' />}
            width='240px'
          />
        )}

        <CardDropdownMenuItem
          onClick={() => setCurrentOpenModal(CHANGE_WORKSPACE_MODAL)}
          primaryText={<span className='mx-3'>{I18N('move_page')}</span>}
          className='px-3'
          icon={<CdnSvg src={moveSquareIconPath} className='ChangeWorkspaceIcon' />}
          width='240px'
        />

        <CardDropdownMenuItem
          onClick={() => setCurrentOpenModal(EDIT_URL_MODAL)}
          primaryText={<span className='mx-3'>{I18N('edit_page_url')}</span>}
          className='px-3'
          icon={<CdnSvg src={linkHorizontalIconPath} className='EditPageURLIcon' />}
          width='240px'
        />

        <div className='w-100 line-break' />

        {!page.showTableOfContents && (
          <CardDropdownMenuItem
            onClick={addTableOfContents}
            primaryText={<span className='mx-3'>{I18N('table_of_contents')}</span>}
            className='px-3'
            icon={<CdnSvg src={listIconPath} className='TableOfContentsIcon' />}
            width='240px'
          />
        )}

        {settings.pages.faqs.enabled && (
          <CardDropdownMenuItem
            onClick={toggleFaqs}
            primaryText={<span className='mx-3'>{I18N(page.showFaqs ? 'hide_faqs' : 'show_faqs')}</span>}
            className='px-3'
            icon={<CdnSvg src='/images/questionIcon.svg' />}
            width='240px'
          />
        )}

        <CardDropdownMenuItem
          onClick={() => setCurrentOpenModal(SAVE_AS_TEMPLATE_MODAL)}
          primaryText={<span className='mx-3'>{I18N('save_as_template')}</span>}
          className='px-3'
          icon={<CdnSvg src={diskIconPath} className='SaveTemplateIcon' />}
          width='240px'
        />

        <CardDropdownMenuItem
          onClick={() => setCurrentRightNav(TEMPLATES_NAV)}
          primaryText={<span className='mx-3'>{I18N('browse_templates')}</span>}
          className='px-3'
          icon={<CdnSvg src={pageIconPath} className='BrowseTemplatesIcon' />}
          width='240px'
        />

        <div className='w-100 line-break' />
        {canArchivePage && (
          <>
            {!isDraft && (
              <CardDropdownMenuItem
                onClick={() => setCurrentOpenModal(SET_EXPIRATION_MODAL)}
                primaryText={<span className='mx-3'>{I18N('set_expiration')}</span>}
                className='px-3'
                icon={<CdnSvg src={timerIconPath} className='SetExpirationIcon' />}
                width='240px'
              />
            )}
            <CardDropdownMenuItem
              onClick={() => setCurrentOpenModal(ARCHIVE_PAGE_MODAL)}
              primaryText={<span className='mx-3'>{I18N('archive_page')}</span>}
              className='px-3'
              icon={<CdnSvg src={archiveBoxIconPath} className='ArchivePageIcon' />}
              width='240px'
            />
          </>
        )}

        <div className='w-100 line-break' />
        <CardDropdownMenuItem
          link={`/platform_analytics/pages/${page.id}`}
          primaryText={<span className='ml-3 '>{I18N('view_analytics')}</span>}
          className='px-3'
          icon={<CdnSvg src={barChartIconPath} className='ArchivePageIcon' />}
          width='240px'
        />
        <CardDropdownMenuItem
          onClick={() => setCurrentRightNav(FEEDBACK_NAV)}
          primaryText={<span className='mx-3'>{I18N('page_feedback')}</span>}
          className='px-3'
          icon={<CdnSvg src={feedbackIconPath} className='FeedbackIconPath' />}
          width='240px'
        />
        <CardDropdownMenuItem
          link={historyPagePath}
          primaryText={<span className='mx-3'>{I18N('version_history.title')}</span>}
          className='px-3'
          icon={<CdnSvg src={clockGoingBackwardsIconPath} className='ClockGoingBackwardsIcon' />}
          width='240px'
        />
        <div className='w-100 line-break' />
        <CardDropdownMenuItem
          onClick={() => setCurrentOpenModal(DELETE_PAGE_MODAL)}
          primaryText={<span className='text-danger ml-3 pl-1 '>{I18N('delete_page')}</span>}
          className='px-3'
          icon={<CdnSvg src={trashIconPath} className='DeletePageIcon' />}
          width='240px'
        />
      </DropdownMenuContainer>

      {currentOpenModal === COPY_PAGE_MODAL && (
        <CopyPageModal
          className='align-items-center'
          closeCopyPageModal={closeModal}
          handleCopyPage={title => copyPage({ id: page.id, title })}
          pageTitle={draftTitle}
          showCopyPageModal
          isCopying={isCopying}
        />
      )}

      {currentOpenModal === UNPUBLISH_PAGE_MODAL && (
        <UnpublishPageModal
          showUnpublishPageModal
          closeUnpublishPageModal={closeModal}
          revertToDraft={revertToDraft}
          isLoading={isRevertingToDraft}
        />
      )}

      {currentOpenModal === TRANSFER_OWNERSHIP_MODAL && (
        <TransferPageOwnershipModal
          page={page}
          isOpen
          onClose={closeModal}
        />
      )}

      {currentOpenModal === CHANGE_WORKSPACE_MODAL && (
        <ChangePageWorkspaceModal
          onClose={closeModal}
          visible
          page={page}
        />
      )}

      {currentOpenModal === EDIT_URL_MODAL && (
        <EditPageURLModal
          page={page}
          isOpen
          onClose={closeModal}
        />
      )}

      {currentOpenModal === SAVE_AS_TEMPLATE_MODAL && (
        <SaveAsTemplateModal
          page={page}
          isOpen
          onClose={closeModal}
        />
      )}

      {currentOpenModal === ARCHIVE_PAGE_MODAL && (
        <ArchivePageModal
          visible
          toggle={closeModal}
          page={page}
        />
      )}

      {currentOpenModal === SET_EXPIRATION_MODAL && (
        <SetExpirationModal
          visible
          toggle={closeModal}
          page={page}
        />
      )}

      {currentOpenModal === DELETE_PAGE_MODAL && (
        <PageDeleteModal
          visible
          onClose={closeModal}
          page={page}
        />
      )}
    </div>
  )
}

export default PageDropdown
