import React from 'react'
import { connect } from 'react-redux'

import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'

const ProfileImageBorderRadius = ({ currentUser }) => (
  <div className='ProfileImageBorderRadius__Preview mt-2'>
    <EmployeeThumbnailPhoto employee={currentUser} />
  </div>
)

const mapStateToProps = state => ({
  currentUser: state.currentUser,
})

export default connect(mapStateToProps)(ProfileImageBorderRadius)
