import React from 'react'
import 'moment-timezone'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import MoonOrSunIcon from 'components/common/moonOrSunIcon'

const I18N = i18nPath('views.profile')

const CurrentTimeForUser = ({ user }) => {
  const isEnglish = i18nMoment().locale() === 'en'
  const timezoneMoment = i18nMoment().tz(user.currentTimezone)
  const time = isEnglish ? timezoneMoment.format('LT [on] dddd') : timezoneMoment.format('lll')

  return (
    <div className='CurrentTime d-flex align-items-center'>
      <span className='ml-1'>
        <MoonOrSunIcon timezone={user.currentTimezone} width={16} height={16} />
      </span>
      <span className='ml-2 text-primary'>
        {I18N('where_to_find_me.current_time_for_other', {
          time,
          firstName: user.preferredName,
        })}
      </span>
    </div>
  )
}

export default CurrentTimeForUser
