import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'

import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import BackButton from 'components/common/backButton'
import useQueryParams from 'components/common/hooks/useQueryParams'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import { PopularPageType } from 'types/analytics/pages'

const I18N = i18nPath('views.platform_analytics.pages')

const backToText = i18nPath('views.platform_analytics')('title')

const columns = [
  {
    header: I18N('page'),
    col: 'col-title',
    style: { width: '70%' },
    accessor: (page: PopularPageType) => <a href={page.url}>{page.title}</a>,
  },
  {
    header: I18N('total_views'),
    col: 'col-views',
    style: { width: '20%' },
    accessor: (page: PopularPageType) => page.viewCount,
  },
]

const PopularPagesPage = () => {
  const dispatch = useDispatch()

  // If the order query param is not present, we will default to most popular
  const { order = 'most' } = useQueryParams()

  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    targetingRules,
    setTargetingRules,
    completedTargetingRules,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const {
    data, page, totalPages,
  } = useSelector(pageAnalyticsSlice.selectors.getPopularPages(order))

  const { popularPages: popularPagesMeta } = useSelector(pageAnalyticsSlice.selectors.getMetaData())

  const fetchData = (page: number) => {
    const params = {
      periodStart,
      periodEnd,
      page,
      order,
      targetingRules: completedTargetingRules,
    }

    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchPopularPages(order, params))
  }

  useEffect(() => {
    fetchData(1)
  }, [periodStart, periodEnd, completedTargetingRules])

  const handlePaginationClick = ({ page }) => fetchData(page)

  return (
    <main className='PopularPagesPage include-default-grid mb-5'>
      <BackButton
        backToText={backToText}
        url='/platform_analytics/search'
        className='grid-span-12 my-4'
      />

      <TabHeader
        className='mb-4'
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      <CardWithPaginatedTable
        title={order === 'least' ? I18N('least_popular_pages') : I18N('most_popular_pages')}
        tableColumns={columns}
        tableData={data}
        classname='grid-span-12'
        isLoading={popularPagesMeta[order].isLoading}
        showPagination={totalPages > 1}
        page={page}
        totalPages={totalPages}
        onPaginationClick={handlePaginationClick}
      />
    </main>
  )
}

export default PopularPagesPage
