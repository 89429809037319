import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'

import DuplicateModal from 'components/common/modals/duplicateModal'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.duplicate_modal')

const DuplicateBlueprintModal = ({
  visible,
  closeMainModal,
  toggle,
  journeyBlueprint,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isCopying } = useSelector(journeyBlueprintSlice.selectors.getMetaData())

  const onSuccess = (newJourneyBlueprintId) => {
    closeMainModal()
    history.push(`/admin/journeys/${newJourneyBlueprintId}`)
  }

  const onDuplicate = (name) => {
    dispatch(journeyBlueprintSlice.asyncActions.admin.copy(journeyBlueprint.id, { name }, onSuccess))
  }

  return (
    <DuplicateModal
      className='BlueprintCopierModal'
      title={I18N('title')}
      label={I18N('name_your_journey')}
      initialName={I18N('copy_of', { journeyName: journeyBlueprint?.name })}
      visible={visible}
      isCopying={isCopying}
      onDuplicate={onDuplicate}
      toggle={toggle}
    />

  )
}

export default DuplicateBlueprintModal
