import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import InfiniteScroller from 'components/common/infiniteScroller'
import { i18nPath } from 'utils/i18nHelpers'

const EmployeesGrid = ({
  className,
  dispatchFetchEmployees,
  i18nPathStr,
  additionalText = null,
  filter = 'this_week',
  employees = [],
  fetchMeta = () => {},
}) => {
  const I18N = i18nPath(i18nPathStr)

  const { isLoading, queryParams } = useSelector(fetchMeta())

  const hasMoreEmployees = () => {
    const { page, totalPages } = queryParams
    return page < totalPages
  }

  const handleEmployeesFetch = () => {
    dispatchFetchEmployees(filter, { page: queryParams.page + 1, perPage: 12 })
  }

  if (!isLoading && _.isEmpty(employees)) {
    return (
      <div className='row mt-4 ZeroState'>
        <div className='col-md-12'>
          <div className='h-100'>
            <div className='text-normal text-secondary text-center d-flex h-100 align-items-center justify-content-center'>
              {I18N(`empty_${filter}`)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <InfiniteScroller isFetching={isLoading} hasMoreContent={hasMoreEmployees} onFetch={handleEmployeesFetch}>
      <div className={classNames('d-flex flex-wrap', className)}>
        {employees.map(employee => (
          <div className='mb-2 col-md-4' key={employee.id}>
            <EmployeeMiniCardLink
              employee={employee}
              additionalContent={additionalText && (
                <div className='text-secondary text-small'>
                  {additionalText(employee)}
                </div>
              )}
            />
          </div>
        ))}
      </div>
    </InfiniteScroller>
  )
}

export default EmployeesGrid
