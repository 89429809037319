import React from 'react'

import classNames from 'classnames'

import Card from 'components/common/card'
import ShoutoutCompanyValues from 'components/shoutouts/shoutoutCompanyValues'
import ShoutoutUsers from 'components/shoutouts/shoutoutUsers'
import ShoutoutFrom from 'components/shoutouts/shoutoutFrom'
import ShoutoutText from 'components/shoutouts/shoutoutText'
import ShoutoutSocial from 'components/shoutouts/shoutoutSocial'
import ClickWrapper from 'components/common/clickWrapper'
import ShoutoutCardDropdownMenu from 'components/shoutouts/shoutoutCardDropdownMenu'
import ShoutoutGif from 'components/shoutouts/shoutoutGif'


const ShoutoutsListCard = ({ shoutout, className }) => {
  const {
    users, sender, createdAt, text, companyValues, id, editedAt,
  } = shoutout

  return (
    <ClickWrapper className={classNames('ShoutoutsListCard', className)} to={`/people/shoutouts/${shoutout.id}`}>
      {/*
        When clicking on this component, the target is the Card's component div.card-body,
        thus the ClickWrapperTargetContainer cardBodyClassName to restrict clicks to the actual Card
        and not other components rendered further down the tree.
      */}
      <Card className='mb-4' cardBodyClassName='ClickWrapperTargetContainer d-flex flex-column'>
        <ShoutoutCardDropdownMenu shoutout={shoutout} />

        <ShoutoutUsers users={users} createdAt={createdAt} />

        <ShoutoutFrom sender={sender} createdAt={createdAt} edited={!!editedAt} />

        <ShoutoutText text={text} />

        <ShoutoutGif shoutout={shoutout} />

        <ShoutoutCompanyValues companyValues={companyValues} shoutoutId={id} />

        <ShoutoutSocial shoutout={shoutout} />
      </Card>
    </ClickWrapper>
  )
}

export default ShoutoutsListCard
