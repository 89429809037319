import RadioButton from 'components/form_fields/radioButton'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'


const I18N = i18nPath('views.settings.default_user_setting.inputs.notify')

interface NotifyInputValue {
  notify: 'when_submitted' | 'daily_summary' | 'never'
}

interface NotifyInputProps {
  value: NotifyInputValue
  setValue: (callback: (value: NotifyInputValue) => NotifyInputValue) => void
  name: string
}

const NotifyInput = ({ value, setValue, name }: NotifyInputProps) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(prevValue => ({
      ...prevValue,
      notify: e.target.value as NotifyInputValue['notify'],
    }))
  }

  return (
    <div className='mt-1'>
      {['when_submitted', 'daily_summary', 'never'].map(notify => (
        <RadioButton
          id={`${name}-${notify}`}
          name={`${name}-${notify}`}
          value={notify}
          className='custom-radio custom-control-inline mr-5 align-items-center'
          checked={value.notify === notify}
          onChange={onChangeHandler}
          label={I18N(notify)}
        />
      ))}
    </div>
  )
}

export default NotifyInput
