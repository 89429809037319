import React, { useEffect, useState } from 'react'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import JourneyStatusLabel from 'components/admin/journeys/journeyStatusLabel'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'

import { ONBOARDING, OFFBOARDING, CUSTOM } from 'utils/journeys/journeyBlueprintTypes'
import journeySlice from 'redux/slices/journeys'
import { useDispatch, useSelector } from 'react-redux'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import TableWithFixedColumns from 'components/common/tables/tableWithFixedColumns'
import StepCompletionStatusIconAndTooltip from 'components/admin/journeys/steps/stepCompletionStatusIconAndTooltip'
import useQueryParams from 'components/common/hooks/useQueryParams'
import StepTemplateType from 'types/journeys/stepTemplate'
import JourneyBlueprintType from 'types/journeys/journeyBlueprint'
import JourneyType from 'types/journeys/journey'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'
import stepSlice from 'redux/slices/journeys/steps'
import StepTemplateTableHeader from 'components/admin/journeys/journeys/stepTemplateTableHeader'
import { present } from 'components/common/utils'
import AddCustomStepButton, { AddCustomStepHeader } from 'components/admin/journeys/journeys/addCustomStepButton'

const I18N = i18nPath('views.admin.journeys.journeys.table')

export const COMPLETE_TAB = 'completed'
export const IN_PROGRESS_TAB = 'active'

interface JourneysTableWithStepsProps {
  journeyBlueprint: JourneyBlueprintType,
  searchQuery?: string,
  selectedDateFilterRange: [string?, string?],
  showCustomSteps?: boolean
}

interface JourneyCompletionLabelProps {
  journey: JourneyType,
  isLoading: boolean
}

const getFilteredStepTemplates = (templates: StepTemplateType[]) => {
  const stepTemplates = templates.filter(template => template?.state === 'active')

  return stepTemplates
}


const dateFilterByType = (journeyBlueprintType: string) => {
  switch (journeyBlueprintType) {
  case ONBOARDING:
    return 'user_start_date'
  case OFFBOARDING:
    return 'user_end_date'
  case CUSTOM:
    return 'created_at'
  default:
    return null
  }
}

const JourneyCompletionLabel = ({
  journey,
  isLoading,
}: JourneyCompletionLabelProps) => {
  const steps = journey?.steps || []
  const journeyState = journey?.state
  const overdueSteps = steps.filter(step => step.completionStatus === 'overdue')

  const getStatus = () => {
    if (['completed', 'removed'].includes(journeyState)) {
      return journeyState
    } else if (overdueSteps.length > 0) {
      return 'off_track'
    } else if (journeyState === 'active') {
      return 'on_track'
    } else {
      return journeyState
    }
  }

  const params = {
    completionStatus: getStatus(),
  }

  if (isLoading) {
    return null
  }

  return (<JourneyStatusLabel journey={params} />)
}

const JourneysTableWithSteps = ({
  journeyBlueprint,
  searchQuery,
  selectedDateFilterRange = [undefined, undefined],
}: JourneysTableWithStepsProps) => {
  const dispatch = useDispatch()
  const { selectedTab } = useQueryParams()
  const journeyBlueprintType = journeyBlueprint?.type
  const dateFilterType = dateFilterByType(journeyBlueprintType)
  const inProgressTabSelected = selectedTab === IN_PROGRESS_TAB

  const journeys = useSelector(journeySlice.selectors.getJourneys())
  const { isLoading, queryParams } = useSelector(journeySlice.selectors.getMetaData())
  const { isLoadingSimpleJourneys } = useSelector(journeyBlueprintSlice.selectors.getMetaData())
  const simpleJourneys = useSelector(journeyBlueprintSlice.selectors.getSimpleJourneys(journeyBlueprint?.id))
  const journeyIds = journeys.map(j => j.id)
  const { isLoading: isLoadingSteps } = useSelector(stepSlice.selectors.getMetaData())

  const [dateFilterAfter, dateFilterBefore] = selectedDateFilterRange

  const type = journeyBlueprint?.type
  const isOnboardingJourney = type === ONBOARDING
  const stepTemplates = getFilteredStepTemplates(journeyBlueprint?.stepTemplates || [])

  const {
    page, totalPages, perPage, total,
  } = queryParams
  const [sortParams, setSortParams] = useState<any>(null)

  const fetchJourneys = (newParams = {}) => {
    const params = {
      journeyBlueprintId: journeyBlueprint?.id,
      state: inProgressTabSelected ? 'active' : ['completed', 'removed'],
      perPage: 10,
      page: 1,
      includeSteps: true,
      q: searchQuery,
      sortBy: sortParams?.sortBy || dateFilterType!.replace('user_', 'users.') || 'created_at',
      sortDir: sortParams?.sortDir || 'desc',
      [`${dateFilterType}_after`]: dateFilterAfter,
      [`${dateFilterType}_before`]: dateFilterBefore || dateFilterAfter,
      includeLastVisitDate: isOnboardingJourney,
      ...newParams,
    }

    dispatch(journeySlice.asyncActions.admin.fetchAll(params))
  }

  useEffect(() => {
    if (journeyBlueprint?.id && !isLoadingSimpleJourneys && !!selectedTab) {
      fetchJourneys(sortParams)
    }
  // eslint-disable-next-line max-len
  }, [journeyBlueprint?.id, selectedTab, searchQuery, dateFilterBefore, dateFilterAfter, simpleJourneys.length, sortParams])


  useEffect(() => {
    if (journeyIds.length > 0) {
      const params = {
        journeyId: journeyIds,
        useSimpleSteps: true,
        paginate: false,
      }

      dispatch(stepSlice.asyncActions.admin.fetchAll(params))
    }
  }, [JSON.stringify(journeyIds)])

  const fixedColumns = [
    {
      header: I18N('name'),
      accessor: (d : JourneyType) => <EmployeeOrCorrespondentLink user={d?.user} linkToAdmin />,
      id: 'users.first_name',
    },
    {
      header: I18N('status'),
      accessor: (d : JourneyType) => <JourneyCompletionLabel journey={d} isLoading={isLoadingSteps} />,
    },
    isOnboardingJourney && {
      header: I18N('start_date'),
      accessor: (d : JourneyType) => <span>{i18nMoment(d.user?.startDate).format('ll')}</span>,
      id: 'users.start_date',
    },
    inProgressTabSelected && isOnboardingJourney && {
      header: I18N('last_seen'),
      accessor: (d : JourneyType) => <span>{d?.user?.lastVisitDate && i18nMoment(d.user.lastVisitDate).format('ll')}</span>,
    },
    type === OFFBOARDING && {
      header: I18N('end_date'),
      accessor: (d : JourneyType) => <span>{i18nMoment(d.user?.endDate).format('ll')}</span>,
      id: 'users.end_date',
    },
    type === CUSTOM && {
      header: I18N('journey_created_at'),
      accessor: (d : JourneyType) => <span>{i18nMoment(d?.createdAt).format('ll')}</span>,
      id: 'created_at',
    },
    selectedTab === COMPLETE_TAB && {
      header: I18N('completed_date'),
      accessor: (d : JourneyType) => <span>{i18nMoment(d?.completedAt).format('ll')}</span>,
      id: 'completed_at',
    },
  ].filter(present)

  const stepTemplateColumns = [
    ...stepTemplates.map(stepTemplate => ({
      header: <StepTemplateTableHeader stepOrStepTemplate={stepTemplate} stepTemplates={stepTemplates} />,
      headerKey: stepTemplate?.id,
      accessor: (d : JourneyType) => (
        <StepCompletionStatusIconAndTooltip
          step={d?.steps?.find(step => step?.stepTemplateId === stepTemplate?.id)}
          stepTemplate={stepTemplate}
          journey={d}
        />
      ),
    })),
  ]

  const customStepColumns = journeys.map(journey => journey?.steps?.map((step) => {
    if (!step.custom) return null

    return {
      header: <StepTemplateTableHeader stepOrStepTemplate={step} isCustom />,
      accessor: (d: JourneyType) => {
        const customStep = d?.steps?.find(s => s?.id === step?.id)

        return (
          <div className='CustomStep'>
            {customStep ? (
              <StepCompletionStatusIconAndTooltip
                step={customStep}
                stepTemplate={step}
                journey={d}
              />
            ) : (
              null
            )}
          </div>
        )
      },
    }
  })).flat().filter(present)

  const movingColumns = [...stepTemplateColumns, ...customStepColumns]

  inProgressTabSelected && movingColumns.push({
    header: <AddCustomStepHeader />,
    accessor: (d: JourneyType) => (
      <AddCustomStepButton journey={d} journeyBlueprint={journeyBlueprint} />
    ),
  })

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (journeys.length === 0) {
    return (
      <div className='JourneysTableWithSteps text-secondary font-weight-500 mt-5 text-center'>
        {I18N('empty_message')}
      </div>
    )
  }

  return (
    <TableWithFixedColumns
      className='JourneysTableWithSteps'
      data={journeys}
      fixedColumns={fixedColumns}
      movingColumns={movingColumns}
      onPaginationClick={newPage => fetchJourneys({ page: newPage })}
      perPage={perPage}
      page={page}
      totalPages={totalPages}
      total={total}
      isLoadingMovingSection={isLoadingSteps}
      onSortChange={newParams => setSortParams(newParams)}
      sortKey={sortParams?.sortBy}
      sortedAsc={sortParams?.sortDir === 'asc'}
    />
  )
}

export default JourneysTableWithSteps
