import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import { showToastMessage } from 'redux/slices/toasts'
import appSignal from 'services/appSignal'

const I18N = i18nPath('views.admin.timezones')

export const buildTimezonePayload = timezone => _.pick(timezone, [
  'id',
  'location',
  'timezone',
])

export const initialState = {
  timezoneIds: [],
  meta: {
    isSaving: false,
    error: null,
    isLoading: false,
    isLoadingAdmin: false,
  },
}

const timezoneSlice = createSlice({
  name: 'timezones',
  initialState,
  reducers: {
    setIsSaving(state, action) {
      state.meta.isSaving = action.payload
    },
    setError(state, action) {
      state.meta.error = action.payload
    },
    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },
    setIsLoadingAdmin(state, action) {
      state.meta.isLoadingAdmin = action.payload
    },
    setTimezoneIds(state, action) {
      state.timezoneIds = action.payload
    },
    removeTimezoneId(state, action) {
      state.timezoneIds = state.timezoneIds.filter(timezoneId => timezoneId !== action.payload)
    },
  },
})

_.assign(timezoneSlice, {
  asyncActions: {
    admin: {
      createTimezone: (timezone, history) => async (dispatch) => {
        dispatch(timezoneSlice.actions.setError(null))
        dispatch(timezoneSlice.actions.setIsSaving(true))

        try {
          const response = await API.admin.timezones.create(buildTimezonePayload(timezone))
          dispatch(entitySlice.actions.add({ data: response.data }))

          history.push('/admin/timezones/')
        } catch (e) {
          const { error } = checkForError(getResponseOrThrow(e))

          dispatch(timezoneSlice.actions.setError(error))
        } finally {
          dispatch(timezoneSlice.actions.setIsSaving(false))
        }
      },

      updateTimezone: timezone => async (dispatch) => {
        dispatch(timezoneSlice.actions.setError(null))
        dispatch(timezoneSlice.actions.setIsSaving(true))

        try {
          const response = await API.admin.timezones.update(buildTimezonePayload(timezone))
          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
        } catch (e) {
          const { error } = checkForError(getResponseOrThrow(e))

          dispatch(timezoneSlice.actions.setError(error))
        } finally {
          dispatch(timezoneSlice.actions.setIsSaving(false))
        }
      },

      deleteTimezone: timezone => async (dispatch) => {
        try {
          await API.admin.timezones.destroy(timezone)
          dispatch(timezoneSlice.actions.removeTimezoneId(timezone.id))
          dispatch(entitySlice.actions.remove({ type: 'timezone', id: timezone.id }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        }
      },

      fetchTimezone: timezoneId => async (dispatch) => {
        dispatch(timezoneSlice.actions.setIsLoadingAdmin(true))

        try {
          const response = await API.admin.timezones.fetch(timezoneId)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(timezoneSlice.actions.setIsLoadingAdmin(false))
        }
      },
    },
    fetchTimezones: () => async (dispatch) => {
      dispatch(timezoneSlice.actions.setIsLoading(true))

      try {
        const response = await API.timezones.fetchAll()
        const timezoneIds = response.data.data.map(timezone => timezone.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(timezoneSlice.actions.setTimezoneIds(timezoneIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(timezoneSlice.actions.setIsLoading(false))
      }
    },
  },
})

_.assign(timezoneSlice, {
  selectors: {
    getTimezones: () => state => state.timezones.timezoneIds.map(id => build(state.entities, 'timezone', id)) || [],

    getTimezone: timezoneId => state => build(state.entities, 'timezone', timezoneId) || {},

    getMetaData: () => state => state.timezones.meta,
  },
})

export default timezoneSlice
