import React from 'react'

import Card from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import ProgressiveImage from 'components/common/images/progressiveImage'

const I18N = i18nPath('views.admin.layout_collections_new')

const LayoutCollectionTemplates = ({
  templates,
  updateWorkingCopy,
  setStep,
}) => {
  const onClick = (templateName) => {
    updateWorkingCopy({ templateName })
    setStep(2)
  }

  return (
    <div className='LayoutCollectionTemplates'>
      {templates.map(template => (
        <LayoutCollectionTemplate
          key={template.name}
          template={template}
          onClick={() => onClick(template.name)}
        />
      ))}
    </div>
  )
}

const LayoutCollectionTemplate = ({
  template, onClick,
}) => (
  <div>
    <Card
      className={classNames('LayoutCollectionTemplate pointer')}
      onClick={onClick}
    >
      <ProgressiveImage src={`/images/layout/templates/${template.name}.jpg`} />
    </Card>
    <div className='text-center p-3'>
      <h4>{I18N(`template_names.${template.name}`)}</h4>
    </div>
  </div>
)

export default LayoutCollectionTemplates
