import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import acknowledgementSlice from 'redux/slices/acknowledgements'
import classNames from 'classnames'
import { ACKNOWLEDGED, AcknowledgementType } from 'types/acknowledgement'

const I18N = i18nPath('views.admin.acknowledgements')

type Props = {
  type: 'article'
  acknowledgement: AcknowledgementType
}

const AcknowledgementSpecificReminderButton = ({
  acknowledgement, type,
}: Props) => {
  const dispatch = useDispatch()
  const { acknowledgeableId, user, lastNotificationSentAt } = acknowledgement
  const [isSendingReminder, setIsSendingReminder] = useState(false)
  const [reminderSent, setReminderSent] = useState(false)
  const isDisabled = reminderSent || isSendingReminder

  const sendReminder = () => {
    if (isDisabled) return

    setIsSendingReminder(true)

    const onSuccess = () => {
      setIsSendingReminder(false)
      setReminderSent(true)
    }

    dispatch(acknowledgementSlice.asyncActions.admin.sendReminder(
      acknowledgeableId, type, user.id, onSuccess
    ))
  }

  if (acknowledgement.status === ACKNOWLEDGED) {
    return null
  }

  const lastSentText = () => {
    if (isSendingReminder) {
      return I18N('sending')
    }

    if (reminderSent) {
      return I18N('last_sent_right_now')
    }

    if (lastNotificationSentAt) {
      return I18N('last_sent_on', { date: i18nMoment(lastNotificationSentAt).format('LLL') })
    }

    return null
  }

  return (
    <>
      <div
        className={classNames('font-weight-600 link-color', isDisabled ? 'disabled-link' : 'pointer')}
        onClick={sendReminder}
      >
        {I18N('send_reminder')}
      </div>
      <div className='text-secondary text-smallest'>
        {lastSentText()}
      </div>
    </>
  )
}

export default AcknowledgementSpecificReminderButton
