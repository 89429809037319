import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import moment from 'moment'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError, getValidationErrors } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { defaultStepTemplateAttributes, defaultTaskableTemplateAttributes } from 'utils/journeys/templatesDefaultHelpers'
import { buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'

const I18N = i18nPath('views.admin.journeys.task_templates')

export const TASK_TEMPLATE_ATTRIBUTES = [
  'allowSilenceNotifications',
  'dueAfterNumDays',
  'dueMilestone',
  'dueTime',
  'instructions',
  'name',
  'notifyViaEmail',
  'notifyViaMsTeams',
  'notifyViaSlack',
  'notifyViaSms',
  'replyToSetting',
  'taskable',
  'taskableId',
  'taskableType',
  'taskType',
  'useRecipientNotificationPreferences',
]

export const TASK_TEMPLATE_INTERPOLATED_FIELDS = ['instructions', 'name']

const buildTaskTemplatePayload = (taskTemplate) => {
  const taskTemplateAttrs = _.pick(taskTemplate, [...TASK_TEMPLATE_ATTRIBUTES, 'id', 'prefilledTemplateId'])

  if (taskTemplate.stepTemplateAttributes) {
    taskTemplateAttrs.stepTemplateAttributes = buildStepTemplatePayload(
      taskTemplate.stepTemplateAttributes
    )
  }

  return taskTemplateAttrs
}

export const defaultWorkingCopy = journeyBlueprintType => ({
  ...defaultStepTemplateAttributes(journeyBlueprintType),
  ...defaultTaskableTemplateAttributes(journeyBlueprintType),
})

const BUDDY_ASSIGNMENT_TASK_TEMPLATE_TYPE = 'buddy_assignment'
export const buddyAssignmentDefaultWorkingCopy = journeyBlueprintType => ({
  ...defaultStepTemplateAttributes(journeyBlueprintType),
  ...defaultTaskableTemplateAttributes(journeyBlueprintType),
  taskType: BUDDY_ASSIGNMENT_TASK_TEMPLATE_TYPE,
  name: I18N('buddy_assignment.default_name'),
  instructions: I18N('buddy_assignment.default_instructions'),
  toCorrespondentAttributes: { role: 'manager' },
})

export const initialState = {
  meta: {
    ...defaultMeta,
  },
}

const taskTemplateSlice = createSlice({
  name: 'taskTemplates',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

const asyncActions = {
  admin: {
    fetchTaskTemplate: id => async (dispatch) => {
      dispatch(taskTemplateSlice.actions.isLoading(true))
      dispatch(taskTemplateSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.taskTemplates.fetch(id)
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(taskTemplateSlice.actions.isNotFound(true))
      } finally {
        dispatch(taskTemplateSlice.actions.isLoading(false))
      }
    },

    createTaskTemplate: (taskTemplate, onSuccess = () => {}) => async (dispatch) => {
      dispatch(taskTemplateSlice.actions.isSaving(true))

      try {
        const response = await API.admin.taskTemplates.create(buildTaskTemplatePayload(taskTemplate))
        dispatch(entitySlice.actions.add({ data: response.data }))
        const taskTemplateId = response.data.data.id

        if (taskTemplate?.stepTemplateAttributes?.state === 'draft') {
          dispatch(showToastMessage({ message: I18N('draft_saved'), type: 'success' }))
        } else {
          dispatch(showToastMessage({ message: I18N('successfully_created'), type: 'success' }))
        }

        onSuccess(taskTemplateId)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(taskTemplateSlice.actions.setError(error))
        dispatch(showToastMessage({ message: getValidationErrors(error), type: 'error' }))
      } finally {
        dispatch(taskTemplateSlice.actions.isSaving(false))
      }
    },

    updateTaskTemplate: (workingCopy, isPublishing = false) => async (dispatch) => {
      dispatch(taskTemplateSlice.actions.isSaving(true))

      try {
        const response = await API.admin.taskTemplates.update(buildTaskTemplatePayload(workingCopy))
        dispatch(entitySlice.actions.update({ data: response.data }))
        if (isPublishing) {
          dispatch(showToastMessage({ message: I18N('successfully_published'), type: 'success' }))
        }
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(taskTemplateSlice.actions.setError(error))
        dispatch(showToastMessage({ message: getValidationErrors(error), type: 'error' }))
      } finally {
        dispatch(taskTemplateSlice.actions.isSaving(false))
      }
    },

    copyTaskTemplate: (id, name, onSuccess = (id) => {}) => async (dispatch) => {
      try {
        const response = await API.admin.taskTemplates.copy(id, name)
        dispatch(entitySlice.actions.add({ data: response.data }))
        const taskTemplateId = response.data.data.id
        onSuccess(taskTemplateId)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(taskTemplateSlice.actions.setError(error))
      }
    },
  },
}

const selectors = {
  getMetaData: () => state => state.taskTemplates.meta,

  getTaskTemplate: id => state => build(state.entities, 'taskTemplate', id),
}

export default {
  ...taskTemplateSlice,
  selectors,
  asyncActions,
}
