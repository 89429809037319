import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useParams } from 'react-router-dom'
import qnaEventSlice from 'redux/slices/qnaEvents'
import EventModeratorView from 'components/qna/eventModeratorView'
import EventParticipantView from 'components/qna/eventParticipantView'
import Error404 from 'components/errors/404'

const EventPageContainer = () => {
  const { eventId } = useParams()
  const dispatch = useDispatch()
  const {
    event,
    meta,
    meta: { isUnauthorized, isNotFound },
  } = useSelector(qnaEventSlice.selectors.getEventPageData(eventId))
  const isLoading = meta.isLoading || !event

  useEffect(() => {
    dispatch(qnaEventSlice.asyncActions.fetch(eventId))
  }, [eventId])

  const canManageEvent = event?.permittedActions?.manage || false

  if (isNotFound) {
    return <Error404 />
  }

  return (
    <Switch>
      <Route exact path='/events/:eventId'>
        {canManageEvent ? (
          <EventModeratorView
            isLoading={isLoading}
            event={event}
          />
        ) : (
          <EventParticipantView
            isUnauthorized={isUnauthorized}
            isLoading={isLoading}
            event={event}
            eventId={eventId}
            showModeratorNavbar={false}
          />
        )}
      </Route>
      <Route exact path='/events/:eventId/preview'>
        <EventParticipantView
          isLoading={isLoading}
          event={event}
          showModeratorNavbar={true}
        />
      </Route>
    </Switch>
  )
}

export default EventPageContainer
