import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.text_transform')

interface Props {
  variable: string
  value: string
  onChange: (type: string, name: string, value: string) => void
  navTextTransform?: string
  header?: string
}

const TextTransformControl = ({
  variable, value, onChange, navTextTransform, header,
}: Props) => {
  const handleChange = (ev) => {
    const { value } = ev.target
    onChange('textTransform', variable, value)
  }
  return (
    <div className='TextTransformControl d-flex justify-content-between align-content-start'>
      <span>
        <label className='label'>{header || I18N('header')}</label>
        {' '}
        {navTextTransform}
        <div>
          <div className='Navbar-item ml-0'>
            <a aria-current='false' href=''>
              {I18N('user_imports')}
            </a>
          </div>
        </div>
      </span>
      <span className='control'>
        <select className='form-control' onChange={ev => handleChange(ev)} value={value}>
          <option value='capitalize'>{I18N('capitalize')}</option>
          <option value='lowercase'>{I18N('lowercase')}</option>
          <option value='uppercase'>{I18N('uppercase')}</option>
          <option value='none'>{I18N('none')}</option>
        </select>
      </span>
    </div>
  )
}

export default TextTransformControl
