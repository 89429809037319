import { useEffect, useRef } from 'react'

const useScrollToOnError = (error) => {
  const scrollToRef = useRef()

  useEffect(() => {
    if (error && scrollToRef?.current) {
      scrollToRef.current.scrollIntoView()
    }
  }, [JSON.stringify(error)])

  return scrollToRef
}

export default useScrollToOnError
