import React, { useEffect, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import AsyncSearchInput from 'components/common/asyncSearchInput'
import { useDispatch } from 'react-redux'
import pageSlice from 'redux/slices/pages'
import ArchivedPagesTable from 'components/pages/archivedPagesTable'
import { useParams } from 'react-router-dom'
import CdnSvg from 'components/common/cdnSvg'

const searchIconPath = '/images/searchIcon.svg'

const I18N = i18nPath('views.pages')

const PageArchiveContent = () => {
  const dispatch = useDispatch()
  const [query, setQuery] = useState('')
  const { workspaceSlug } = useParams()

  const fetchArchivedPages = (params = {}) => {
    const queryParams = {
      perPage: 20,
      q: query,
      ...params,
      sortBy: 'archived_at',
      sortDir: 'desc',
      workspaceSlug,
    }

    dispatch(pageSlice.asyncActions.fetchArchivedPages(queryParams))
  }

  useEffect(() => {
    fetchArchivedPages({ page: 1 })
  }, [workspaceSlug])

  const onQueryChange = (value: string) => {
    setQuery(value)
    fetchArchivedPages({ page: 1, q: value })
  }

  return (
    <div className='PageArchiveContent overflow-y-auto flex-grow-1 p-5'>
      <header className='mb-3'>
        <h1 className='text-2xl font-bold'>{I18N('Archive')}</h1>
      </header>
      <AsyncSearchInput
        className='SearchBarInput mb-3'
        defaultValue={query}
        onKeyUp={onQueryChange}
        placeholder={I18N('filter_by_page_title')}
        icon={<CdnSvg src={searchIconPath} />}
      />
      <ArchivedPagesTable onPaginationClick={fetchArchivedPages} />
    </div>
  )
}

export default PageArchiveContent
