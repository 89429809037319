import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import groupTypeSlice from 'redux/slices/groupTypes'
import ReactSelect from 'components/common/react_select'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.search.search_results.filters')

const TeamTypeFilter = ({ updateHistory }) => {
  const dispatch = useDispatch()
  const { filters = '{}' } = useQueryParams()
  const { group_type_id: groupTypeId } = JSON.parse(filters)
  const groupTypes = useSelector(groupTypeSlice.selectors.getTeamTypes())

  const handleChange = (option) => {
    const filters = option
      ? {
        group_type_id: option.id,
      }
      : null

    updateHistory(filters)
  }

  useEffect(() => {
    dispatch(groupTypeSlice.asyncActions.fetchAll())
  }, [])

  return (
    <div className='TeamTypeFilter px-3'>
      <div className='text-small text-secondary mb-2 font-weight-500'>
        {I18N('team_type_filter')}
      </div>
      <ReactSelect
        isClearable
        value={groupTypes.filter(({ id }) => id === groupTypeId)}
        placeholder={
          <div className='text-secondary'>{I18n.t('views.common.react_select.select_or_type')}</div>
        }
        onChange={handleChange}
        options={groupTypes}
        getOptionValue={groupType => groupType.id}
        getOptionLabel={groupType => groupType.name}
      />
    </div>
  )
}

export default TeamTypeFilter
