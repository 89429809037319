import React from 'react'

import ManagedGroupChannelSelector from 'components/admin/managed_groups/managedGroupChannelSelector'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.managed_groups.form')

const ManagedGroupChannelSection = ({
  workingCopy, setWorkingCopy,
}) => {
  const currentManagedGroupChannels = workingCopy.managedGroupChannels || []

  return (
    <div className='ManagedGroupChannelSection mb-4'>
      <h6 className='text-secondary'>{I18N('select_groups')}</h6>
      <div className='background-color-white p-4 channels-container'>
        <LabeledFormFieldContainer
          label={I18N('select_the_group_type_you_want_manage')}
          isRequired
        />
        <div className='managed-group-channels-container'>
          {currentManagedGroupChannels.map((managedGroupChannel, index) => (
            <ManagedGroupChannelSelector
              key={managedGroupChannel.id || index}
              managedGroup={workingCopy}
              setManagedGroup={setWorkingCopy}
              currentManagedGroupChannel={managedGroupChannel}
              currentManagedGroupChannelIndex={index}
            />
          ))}
          <ManagedGroupChannelSelector
            managedGroup={workingCopy}
            setManagedGroup={setWorkingCopy}
          />
        </div>
      </div>
    </div>
  )
}

export default ManagedGroupChannelSection
