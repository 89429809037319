import React, { useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'

import ScrollToModal from 'components/common/scrollToModal'
import GeneralSettings from 'components/admin/journeys/modals/journeyBlueprintSettingsModal/blueprintGeneralSettings'
import AudienceSectionSettings from 'components/admin/journeys/modals/journeyBlueprintSettingsModal/audienceSectionSettings'
import DuplicateBlueprintModal from 'components/admin/journeys/modals/journeyBlueprintSettingsModal/duplicateBlueprintModal'
import BlueprintDeletionModal from './blueprintDeletionModal'
import RemoveFromJourneyConfirmationModal from './removeFromJourneyConfirmationModal'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.settings_modal')

const MAIN_MODAL = 'main'
const DELETE_MODAL = 'delete'
const DUPLICATE_MODAL = 'duplicate'
const REMOVE_CONFIRMATION_MODAL = 'remove_confirmation'

const JourneyBlueprintSettingsModal = ({
  onClose,
  journeyBlueprint,
}) => {
  const dispatch = useDispatch()
  const [currentlyOpenModal, setCurrentlyOpenModal] = useState(MAIN_MODAL)
  const [journeyToRemove, setJourneyToRemove] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const [workingCopy, setWorkingCopy] = useState(journeyBlueprint)
  const updateWorkingCopy = newWorkingCopy => setWorkingCopy({ ...workingCopy, ...newWorkingCopy })

  const journeyBlueprintType = journeyBlueprint?.type

  const simpleJourneys = useSelector(journeyBlueprintSlice.selectors.getSimpleJourneys(journeyBlueprint?.id))

  const onRemoveJourney = (journey) => {
    setJourneyToRemove(journey)
    setCurrentlyOpenModal(REMOVE_CONFIRMATION_MODAL)
  }

  // Called when user clicks 'x' or blurs modal
  const handleClose = () => {
    setIsSaving(false)
    setCurrentlyOpenModal(null)
    onClose()
  }

  const onSave = (e) => {
    setIsSaving(true)

    dispatch(journeyBlueprintSlice.asyncActions.admin.update(workingCopy)).then(() => {
      onClose()
    }).catch(() => {
      setIsSaving(false)
    })
  }

  const sections = [
    {
      id: 'general',
      header: I18NCommon('general'),
      content: <GeneralSettings
        id={journeyBlueprint?.id}
        creator={journeyBlueprint?.creator}
        journeyAdmin={journeyBlueprint?.journeyAdmin}
        workingCopy={workingCopy}
        openDeleteBlueprintModal={() => setCurrentlyOpenModal(DELETE_MODAL)}
        openDuplicateBlueprintModal={() => setCurrentlyOpenModal(DUPLICATE_MODAL)}
        updateWorkingCopy={updateWorkingCopy}
      />,
    },
    simpleJourneys.length > 0 && {
      id: 'audience',
      header: I18N(`${journeyBlueprintType}.audience_section_title`),
      content: <AudienceSectionSettings
        journeyBlueprint={journeyBlueprint}
        onRemoveJourney={onRemoveJourney}
      />,
    },
  ].filter(Boolean)

  return (
    <>
      {currentlyOpenModal === MAIN_MODAL && (
        <ScrollToModal
          className='JourneyBlueprintSettingsModal'
          visible={currentlyOpenModal === MAIN_MODAL}
          sections={sections}
          onSave={onSave}
          onClose={handleClose}
          isSaving={isSaving}
          isSaveButtonDisabled={isSaving}
        />
      )}
      <DuplicateBlueprintModal
        visible={currentlyOpenModal === DUPLICATE_MODAL}
        toggle={() => setCurrentlyOpenModal(MAIN_MODAL)}
        closeMainModal={handleClose}
        journeyBlueprint={journeyBlueprint}
      />
      <BlueprintDeletionModal
        visible={currentlyOpenModal === DELETE_MODAL}
        toggle={() => setCurrentlyOpenModal(MAIN_MODAL)}
        closeMainModal={handleClose}
      />
      <RemoveFromJourneyConfirmationModal
        visible={currentlyOpenModal === REMOVE_CONFIRMATION_MODAL}
        toggle={() => setCurrentlyOpenModal(MAIN_MODAL)}
        journeyToRemove={journeyToRemove}
      />
    </>
  )
}

export default JourneyBlueprintSettingsModal
