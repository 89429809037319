import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import FormCheck from 'components/form_fields/formCheck'

const I18N = i18nPath('views.qna.events.settings_modal.qna')

const EventDetails = ({ event, setEvent }) => {
  const handleIncludeAgendaSettings = (e) => {
    setEvent({ ...event, settings: { ...event.settings, agendaItems: { enabled: e.target.checked } } })
  }

  const handleIncludeSpeakersSettings = (e) => {
    setEvent({ ...event, settings: { ...event.settings, speakers: { enabled: e.target.checked } } })
  }

  return (
    <div className='EventDetails mt-5'>
      <label className='d-block'>{I18N('event_details')}</label>
      <FormCheck
        name='include_agenda'
        label={I18N('include_agenda')}
        checked={event.settings.agendaItems.enabled}
        onChange={handleIncludeAgendaSettings}
        id='include_agenda'
      />

      <FormCheck
        className='mt-3'
        name='include_speakers'
        label={I18N('include_speakers')}
        checked={event.settings.speakers.enabled}
        onChange={handleIncludeSpeakersSettings}
        id='include_speakers'
      />
    </div>
  )
}

export default EventDetails
