import React from 'react'
import ShowMoreText from 'react-show-more-text'

import Card from 'components/common/card'
import ShoutoutFrom from 'components/shoutouts/shoutoutFrom'
import ShoutoutText from 'components/shoutouts/shoutoutText'
import ShoutoutCompanyValues from 'components/shoutouts/shoutoutCompanyValues'
import ShoutoutSocial from 'components/shoutouts/shoutoutSocial'
import ShoutoutUsers from 'components/home/widgets/recognition_widget/shoutoutUsers'
import { I18NCommon } from 'utils/i18nHelpers'


const ShoutoutItem = ({ shoutout, widgetWidth }) => {
  const {
    id,
    companyValues,
    createdAt,
    editedAt,
    sender,
    text,
    users,
  } = shoutout

  const userCount = users.length

  const alwaysShowCount = () => {
    if (widgetWidth < 400) return userCount > 1
    if (widgetWidth < 550) return userCount > 2
    if (widgetWidth < 650) return userCount > 3
    return false
  }

  const linesToShow = widgetWidth < 400 ? 3 : 2
  const shownCompanyValues = () => {
    if (widgetWidth < 400) return 1
    if (widgetWidth < 550) return 2
    if (widgetWidth < 650) return 3
    return 4
  }

  return (
    <Card className='ShoutoutItem'>
      <ShoutoutUsers shoutout={shoutout} alwaysShowCount={alwaysShowCount()} />

      <ShoutoutFrom sender={sender} createdAt={createdAt} edited={!!editedAt} />

      <ShowMoreText
        lines={linesToShow}
        more={I18NCommon('more')}
        less={I18NCommon('show_less')}
        className='text-small'
        anchorClass='ShowMoreText__anchor'
      >
        <ShoutoutText text={text} className='text-small' />
      </ShowMoreText>

      <ShoutoutCompanyValues
        companyValues={companyValues}
        shoutoutId={id}
        group
        shownCompanyValues={shownCompanyValues()}
      />

      <ShoutoutSocial shoutout={shoutout} showCommentsOnInit={false} />
    </Card>
  )
}

export default ShoutoutItem
