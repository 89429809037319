import React from 'react'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SettingRadioButtons from 'components/settings/common/settingRadioButtons'
import { i18nPath } from 'utils/i18nHelpers'

const SHOUTOUT_RECIPIENTS_COMMENT_NOTIFICATIONS = 'shoutouts.comment_notifications.recipients'
const SHOUTOUT_SENDER_COMMENT_NOTIFICATIONS = 'shoutouts.comment_notifications.sender'
const BIRTHDAY_COMMENT_NOTIFICATIONS = 'birthday.comment_notifications'
const WORK_ANNIVERSARY_COMMENT_NOTIFICATIONS = 'work_anniversary.comment_notifications'
const BADGE_RECIPIENTS_COMMENT_NOTIFICATIONS = 'badges.comment_notifications.recipients'
const CELEBRATION_ACTIVITY_IN_GREETING_CARDS = 'celebrations.activity_in_greeting_cards'

const WHEN_SUBMITTED = 'when_submitted'
const DAILY_SUMMARY = 'daily_summary'
const NEVER = 'never'

const I18N = i18nPath('views.settings.celebrations_section')

const options = [
  { id: WHEN_SUBMITTED, value: { notify: WHEN_SUBMITTED }, text: I18N(WHEN_SUBMITTED) },
  { id: DAILY_SUMMARY, value: { notify: DAILY_SUMMARY }, text: I18N(DAILY_SUMMARY) },
  { id: NEVER, value: { notify: NEVER }, text: I18N(NEVER) },
]

const CelebrationsSettingsSection = () => {
  const { settings } = useCurrentCompany()

  const showCelebrationActivityInGreetingCardsSetting = (
    settings.celebrations.birthdays?.enabled
    || settings.celebrations.workAnniversaries?.enabled
    || settings.celebrations.newEmployees?.enabled
  )

  return (
    <div className='CelebrationsSettingsSection'>

      {settings.celebrations.workAnniversaries?.enabled && (
        <div className='mb-4'>
          <div className='font-weight-500'>{I18N('work_anniversary_notifications')}</div>
          <SettingRadioButtons
            name={WORK_ANNIVERSARY_COMMENT_NOTIFICATIONS}
            options={options}
          />
        </div>
      )}

      {settings.celebrations.birthdays?.enabled && (
        <div className='mb-4'>
          <div className='font-weight-500'>{I18N('birthday_notifications')}</div>
          <SettingRadioButtons
            name={BIRTHDAY_COMMENT_NOTIFICATIONS}
            options={options}
          />
        </div>
      )}

      {settings.shoutouts?.enabled && (
        <>
          <div className='mb-4'>
            <div className='font-weight-500'>{I18N('shoutout_notifications')}</div>
            <SettingRadioButtons
              name={SHOUTOUT_RECIPIENTS_COMMENT_NOTIFICATIONS}
              options={options}
            />
          </div>
          <div className='mb-4'>
            <div className='font-weight-500'>{I18N('shoutout_sender_notifications')}</div>
            <SettingRadioButtons
              name={SHOUTOUT_SENDER_COMMENT_NOTIFICATIONS}
              options={options}
            />
          </div>
        </>
      )}

      {settings.badges?.enabled && (
        <div className='mb-4'>
          <div className='font-weight-500'>{I18N('badge_notifications')}</div>
          <SettingRadioButtons
            name={BADGE_RECIPIENTS_COMMENT_NOTIFICATIONS}
            options={options}
          />
        </div>
      )}

      {showCelebrationActivityInGreetingCardsSetting && (
        <>
          <div className='font-weight-500'>{I18N('activity_in_greeting_cards')}</div>
          <SettingRadioButtons
            name={CELEBRATION_ACTIVITY_IN_GREETING_CARDS}
            options={options}
          />
        </>
      )}
    </div>
  )
}

export default CelebrationsSettingsSection
