import React from 'react'

import Card from 'components/common/card'
import classNames from 'classnames'

interface StepInfoType {
  title: string,
  icon: React.ReactNode,
  body: React.ReactNode,
}

interface ProgressGuidanceCardProps {
  currentStepIndex?: number
  steps?: StepInfoType[]
}

const ProgressGuidanceCard = ({
  currentStepIndex = 0,
  steps = [],
} : ProgressGuidanceCardProps) => {
  const currentStep = steps[currentStepIndex] || null

  const getClassByCurrentStep = (stepIndex: number) => {
    if (currentStepIndex === stepIndex) {
      return 'active'
    }

    if (currentStepIndex > stepIndex) {
      return 'completed'
    }

    return ''
  }

  if (!steps.length || !currentStep) {
    return null
  }

  return (
    <Card className='ProgressGuidanceCard mb-5'>
      <div className='progress-steps'>
        {steps.map((step, index) => (
          <span className={classNames('step-container pl-2', getClassByCurrentStep(index))}>
            {step.icon}
            <span className='step-title'>{step.title}</span>
          </span>
        ))}
      </div>
      <div className='guidance'>
        {currentStep.body}
      </div>
    </Card>
  )
}

export default ProgressGuidanceCard
