const getCompanyCalendarType = (currentCompany) => {
  const ismicrosoftAzureAppCalendarsEnabled = currentCompany?.microsoftAzureAppCalendarsEnabled
  const areGoogleServicesEnabled = currentCompany?.googleServicesEnabled

  if (ismicrosoftAzureAppCalendarsEnabled) {
    return 'microsoft'
  }

  if (areGoogleServicesEnabled) {
    return 'google'
  }

  return ''
}

export default getCompanyCalendarType
