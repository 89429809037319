import React from 'react'
import { useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import RecentQuestionCard from 'components/qna/recentQuestionCard'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'

const I18N = i18nPath('views.qna.events')

const RecentQuestions = () => {
  const recentQuestions = useSelector(qnaQuestionSlice.selectors.getRecentQuestions())
  const isLoadingRecentQuestions = useSelector(qnaQuestionSlice.selectors.getEventsListPageData())

  if (isLoadingRecentQuestions) {
    return <LoadingIndicator />
  }

  if (_.isEmpty(recentQuestions)) {
    return null
  }

  return (
    <div className='mt-4 mt-md-0'>
      <h4>{I18N('recent_questions')}</h4>
      <div className='row mt-4'>
        <div className='col-md-12 recent-question-cards'>
          {recentQuestions.map(question => <RecentQuestionCard key={`question-${question.id}`} question={question} />)}
        </div>
      </div>
    </div>
  )
}

export default RecentQuestions
