import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import countCharactersInHtmlString from 'utils/countCharactersInHtmlString'

import API from 'services/api'
import adminArticleSlice from 'redux/slices/admin/articles'

import useApi from 'components/common/hooks/useApi'
import Card from 'components/common/card'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SLACK_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import InfoTooltip from 'components/common/infoTooltip'
import CdnSvg from 'components/common/cdnSvg'
import SendPreviewChatMessageButtonAndModal from 'components/admin/articles/sendPreviewChatMessageButtonAndModal'

import SlackSenderAsSelector from 'components/admin/articles/audience_and_settings_editor/slackSenderAsSelector'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useAreChangesPresentInArticle from 'components/common/hooks/useAreChangesPresentInArticle'
import ClearyTooltip from 'components/common/clearyTooltip'

const CHAT_NOTIFICATION_CHAR_LIMIT = 2000

const I18N = i18nPath('views.admin.article_editor.audience_and_settings_editor')

const slackIconPath = '/images/slackLogoIcon.svg'

const CustomizeChatNotificationsCard = ({
  article,
  workingCopy,
  updateWorkingCopy,
}) => {
  const dispatch = useDispatch()
  const charCount = countCharactersInHtmlString(workingCopy.chatNotificationContent)
  const { isSendingPreviewChatMessage } = useSelector(adminArticleSlice.selectors.getMetaData())
  const isArticleSaved = workingCopy.id
  const { settings } = useCurrentCompany()
  const showSlackSenderAsSelector = !_.isEmpty(workingCopy.notificationChannels) && settings.slackApp.userConsentEnabled

  const areChangesPresent = useAreChangesPresentInArticle(article, workingCopy)
  const [editorKey, setEditorKey] = useState(0)

  const [generateSummary, { isLoading: isGeneratingSummary }] = useApi(
    API.admin.htmlSummaries.createForNotifications,
    {
      onSuccess: (data) => {
        updateWorkingCopy({ chatNotificationContent: data.summary })
        setEditorKey(editorKey => editorKey + 1)
      },
    }
  )

  const regenerateSummary = () => {
    generateSummary(
      'article',
      workingCopy.cardContent,
      { clearyRichTextId: workingCopy.cardContentId, groupId: workingCopy.groupId }
    )
  }

  useEffect(() => {
    if (workingCopy.draft && !workingCopy.chatNotificationContent && workingCopy.cardContent) {
      regenerateSummary()
    }
  }, [])

  const onSendPreviewChatMessage = (userIds, onSuccess) => {
    dispatch(adminArticleSlice.asyncActions.sendPreviewChatMessage(workingCopy?.id, userIds, onSuccess))
  }

  if (isGeneratingSummary) {
    return (
      <CirclesLoadingIndicator />
    )
  }

  return (
    <Card className='CustomizeChatNotificationsCard mb-4'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex align-items-center pb-3'>
          <CdnSvg src={slackIconPath} className='mr-2' />
          <h5 className='mb-0'>{I18N('edit_slack_message')}</h5>
          <InfoTooltip className='ml-1' text={I18N('edit_slack_message_tooltip')} />
        </div>

        <ClearyTooltip
          placement='bottom'
          content={(
            <div className='pointer' onClick={regenerateSummary}>
              <CdnSvg className='PreviewIcon' src='/images/syncIcon.svg' />
              <span className='ml-1 link-color text-small'>{I18N('regenerate')}</span>
            </div>
          )}
        >
          {I18N('regenerate_tooltip')}
        </ClearyTooltip>
      </div>

      {showSlackSenderAsSelector && (
        <SlackSenderAsSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
        />
      )}

      <TiptapEditor
        key={editorKey}
        editorContentClassName='p-3'
        className='TiptapEditor straight-borders'
        onChange={html => updateWorkingCopy({ chatNotificationContent: html })}
        html={workingCopy.chatNotificationContent}
        configuration={{
          ...SIMPLE_EDITOR_CONFIGURATION,
          imagesEnabled: true,
          userMentionsEnabled: true,
          placeholder: I18NCommon('default_placeholder_text'),
        }}
        toolbarItems={SLACK_TOOLBAR}
      />
      <div className='d-flex align-items-center justify-content-between mt-2'>
        <SendPreviewChatMessageButtonAndModal
          areChangesPresent={!isArticleSaved || areChangesPresent}
          isSending={isSendingPreviewChatMessage}
          onSend={onSendPreviewChatMessage}
          objectName={I18N('article')}
        />
        <div className='CharsRemaining text-muted text-small text-right'>
          {I18NCommon('characters_remaining', { numCharactersRemaining: CHAT_NOTIFICATION_CHAR_LIMIT - charCount })}
        </div>
      </div>
    </Card>
  )
}

export default CustomizeChatNotificationsCard
