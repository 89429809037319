import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import appSignal from 'services/appSignal'
import entitySlice from 'redux/slices/entities'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

export const initialState = {
  userImportIds: [],
  meta: {
    ...defaultMeta,
    queryParams: { ...defaultPaginationParams, sortBy: 'created_at', sortDir: 'desc' },
    isSyncSaving: false,
  },
}

const userImportSlice = createSlice({
  name: 'userImports',
  initialState,
  reducers: {
    ...defaultActions,

    setUserImportIds(state, action) {
      state.userImportIds = action.payload
    },

    setIsSyncSaving(state, action) {
      state.meta.isSyncSaving = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = { ...state.meta.queryParams, ...action.payload }
    },
  },
})

_.assign(userImportSlice, {
  asyncActions: {
    admin: {
      fetchAll: (queryParams = {}) => async (dispatch) => {
        dispatch(userImportSlice.actions.isLoading(true))

        try {
          const response = await API.admin.userImports.fetchAll(queryParams)
          const userImportIds = response.data.data.map(userImport => userImport.id)
          const newQueryParams = queryParamsFromHeaders(response)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(userImportSlice.actions.setUserImportIds(userImportIds))
          dispatch(userImportSlice.actions.setQueryParams(newQueryParams))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(userImportSlice.actions.isLoading(false))
        }
      },

      fetchUserImport: id => async (dispatch) => {
        dispatch(userImportSlice.actions.isLoading(true))

        try {
          const response = await API.admin.userImports.fetch(id)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(userImportSlice.actions.isLoading(false))
        }
      },

      createOrFetchUserImport: (type, options = {}) => async (dispatch, getState) => {
        dispatch(userImportSlice.actions.setIsSyncSaving(true))

        try {
          let response
          switch (type) {
          case 'hris':
            response = await API.admin.userImports.createOrFetchHRISUserImport(options.isOnlyModified)
            break
          case 'ats':
            response = await API.admin.userImports.createOrFetchATSUserImport()
            break
          case 'csv':
            response = await API.admin.userImports.createOrFetchCsvUserImport(
              options.file, { preboarding: options.preboarding, dateFormat: options.dateFormat }
            )
            break
          default:
            response = await API.admin.userImports.createOrFetchHRISUserImport(options.isOnlyModified)
            break
          }

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(userImportSlice.actions.setUserImportIds(
            _.uniq([response?.data?.data?.id, ...getState().userImports.userImportIds])
          ))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(userImportSlice.actions.setIsSyncSaving(false))
        }
      },
    },
  },
})

_.assign(userImportSlice, {
  selectors: {
    getUserImports: () => state => state.userImports.userImportIds
      .map(id => build(state.entities, 'userImport', id))
      .filter(Boolean) || [],

    getUserImport: id => state => build(state.entities, 'userImport', id),

    getMetaData: () => state => state.userImports.meta,
  },
})

export default userImportSlice
