import React from 'react'
import { ButtonNarrow } from 'components/common/buttons'

const Controls = ({
  handleConfirm,
  handleCancel = () => {},
  isLoading,
  cancelLabel,
  confirmLabel,
  cancelDisabled = false,
  confirmDisabled = false,
}) => (
  <div className='AiAnswersWizardModal__controls'>
    {cancelLabel && (
      <ButtonNarrow
        variant='secondary'
        onClick={handleCancel}
        disabled={cancelDisabled}
        className='mr-2'
      >
        {cancelLabel}
      </ButtonNarrow>
    )}

    <ButtonNarrow
      variant='primary'
      onClick={handleConfirm}
      disabled={confirmDisabled}
      showLoadingSpinner={isLoading}
    >
      {confirmLabel}
    </ButtonNarrow>
  </div>
)

export default Controls
