import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import GridLayout from 'react-grid-layout'
import LayoutCollectionNavbar from 'components/admin/layout/layoutCollectionNavbar'
import useHideNavbar from 'components/navbar/hooks/useHideNavbar'
import useLayoutSize, { CONTAINER_PADDING, MARGINS, ROW_HEIGHT } from 'hooks/layout/useLayoutSize'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { WidgetType } from 'types/layout/widget'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { i18nPath } from 'utils/i18nHelpers'
import buildLayoutPayload, { buildWidgetPayload } from 'utils/layout/buildLayoutPayload'
import EditableWidget from 'components/admin/layout/editableWidget'
import { SizeEnum } from 'types/layout/layout'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import NavigationBlocker from 'components/common/navigationBlocker'
import widgetsMap from 'utils/layout/widgetsMap'
import classNames from 'classnames'
import { present } from 'components/common/utils'
import LayoutCollectionSidebar, { ADD_WIDGET_SIDEBAR, WIDGET_SETTINGS_SIDEBAR } from 'components/admin/layout/layout_collection_sidebar/layoutCollectionSidebar'

const I18N = i18nPath('views.admin.home_editor')

const DEFAULT_WIDTHS = {
  [SizeEnum.Large]: 1400,
  [SizeEnum.Medium]: 800,
  [SizeEnum.Small]: 400,
}

const CLOSED_SIDEBAR = { name: '', widgetId: null, isOpen: false }

const EditLayoutCollectionPage = () => {
  useHideNavbar()
  const { layoutCollectionId } = useParams()

  const [selectedSize, setSelectedSize] = useQueryParamState({ param: 'size', initialValue: SizeEnum.Large })
  const { size, cols, width } = useLayoutSize(DEFAULT_WIDTHS[selectedSize])
  const [widgets, setWidgets] = useState<WidgetType[]>([])
  const [deletedWidgets, setDeletedWidgets] = useState<WidgetType[]>([])

  const [currentlyOpenSidebar, setCurrentlyOpenSidebar] = useState(CLOSED_SIDEBAR)
  const closeSidebar = () => setCurrentlyOpenSidebar(CLOSED_SIDEBAR)
  const isSidebarOpen = currentlyOpenSidebar.isOpen
  const selectedWidgetId = currentlyOpenSidebar.widgetId
  const selectedWidget = selectedWidgetId ? widgets.find(w => w.id === selectedWidgetId) : null

  const [selectedNewWidget, setSelectedNewWidget] = useState<any>({ i: 'noSelectedWidget' })

  const onFetchOrSaveSuccess = (data) => {
    setWidgets(data.widgets)
    setDeletedWidgets([])

    if (selectedWidgetId && !data.widgets.find(w => w.id === selectedWidgetId) && !_.isEmpty(data.widgets)) {
      closeSidebar()
    }

    if (_.isEmpty(data.widgets)) {
      openAddWidgetSidebar()
    }
  }

  const { data: originalLayout, isLoading } = useFetch(
    () => API.admin.layout.layouts.fetch(layoutCollectionId, size),
    [size],
    { onSuccess: onFetchOrSaveSuccess }
  )

  const { data: fetchedLayoutCollection } = useFetch(() => API.admin.layout.layoutCollections.fetch(layoutCollectionId))

  const [updateLayoutCollection, { data: updatedLayoutCollection }] = useApi(
    API.admin.layout.layoutCollections.update,
    {
      toastSuccessMessage: I18N('layout_collection_updated'),
      toastErrorMessage: I18N('error_updating_layout_collection'),
    }
  )

  const layoutCollection = updatedLayoutCollection || fetchedLayoutCollection

  const [saveLayout, { isLoading: isSaving, data: updatedLayout }] = useApi(API.admin.layout.layouts.update, {
    toastSuccessMessage: I18N('update_success'),
    toastErrorMessage: I18N('update_error'),
    onSuccess: onFetchOrSaveSuccess,
  })

  const layout = updatedLayout?.size === size ? updatedLayout : originalLayout
  const savedWidgets = layout?.widgets || []
  const areChangesPresent = useMemo(() => (
    !_.isEqual(savedWidgets.map(buildWidgetPayload), widgets.map(buildWidgetPayload))
  ), [savedWidgets, widgets])

  const handleSave = () => {
    saveLayout(layoutCollectionId, buildLayoutPayload({
      id: layout.id,
      widgets: [...widgets, ...deletedWidgets.map(w => ({ ...w, _destroy: true }))],
    }))
  }

  const droppingItem = {
    ...selectedNewWidget,
    w: selectedNewWidget?.[size]?.minW || 4,
    h: selectedNewWidget?.[size]?.minH || 4,
    settings: selectedNewWidget?.defaultSettings || {},
  }

  const handleLayoutChange = (newLayout, { isDropping = false } = {}) => {
    const newWidgets = newLayout.map((item) => {
      const widget = widgets.find(w => w.id === item.i)

      if (widget) return { ...widget, ...item }

      if (item.i === 'noSelectedWidget') return null

      if (!isDropping) return null

      return { ...droppingItem, ...item }
    })

    setWidgets(newWidgets.filter(present))
  }

  const handleDeleteWidget = (widget: WidgetType) => {
    if (selectedWidgetId === widget.id) {
      closeSidebar()
    }
    if (!widget.id.startsWith('new-')) {
      setDeletedWidgets([...deletedWidgets, widget])
    }
    setWidgets(widgets.filter(w => w.id !== widget.id))
  }

  const updateWidget = (widget: WidgetType) => {
    setWidgets(widgets.map(w => (w.id === widget.id ? widget : w)))
  }

  const handleOpenWidgetSettingsSidebar = (widgetId) => {
    setCurrentlyOpenSidebar({ name: WIDGET_SETTINGS_SIDEBAR, widgetId, isOpen: true })
  }

  const buildWidgetLayout = (widget: WidgetType) => {
    const mappedWidget = widgetsMap[widget.type]

    const minAndMaxSizes = mappedWidget[size] || {}

    if (widget.settings?.fullWidth) {
      minAndMaxSizes.minW = cols
    }

    return {
      i: widget.id,
      x: widget.x,
      y: widget.y,
      w: widget.w,
      h: widget.h,
      ...minAndMaxSizes,
    }
  }

  const openAddWidgetSidebar = () => {
    setCurrentlyOpenSidebar({ name: ADD_WIDGET_SIDEBAR, widgetId: null, isOpen: true })
  }

  return (
    <div className='EditLayoutCollectionPage'>
      <NavigationBlocker isBlocked={areChangesPresent} />
      <LayoutCollectionNavbar
        layoutCollection={layoutCollection}
        updateLayoutCollection={updateLayoutCollection}
        onSave={handleSave}
        isSaving={isSaving}
        areChangesPresent={areChangesPresent}
        setSelectedSize={setSelectedSize}
        selectedSize={selectedSize}
        openAddWidgetSidebar={openAddWidgetSidebar}
      />

      {isSidebarOpen && (
        <LayoutCollectionSidebar
          closeSidebar={closeSidebar}
          setSelectedNewWidget={setSelectedNewWidget}
          currentlyOpenSidebarName={currentlyOpenSidebar.name}
          widget={selectedWidget}
          updateWidget={updateWidget}
        />
      )}
      <div className={classNames('EditableGridContainer', { isSidebarOpen })}>
        {!layout || isLoading ? (
          <div className='EditableGridLayout' style={{ width }}>
            <CirclesLoadingIndicator />
          </div>
        ) : (
          <div className={`EditableGridLayout EditableGridLayout--${size}`}>
            <GridLayout
              cols={cols}
              rowHeight={ROW_HEIGHT}
              width={width}
              layout={widgets.map(buildWidgetLayout)}
              containerPadding={CONTAINER_PADDING}
              margin={MARGINS}
              style={{ width }}
              onLayoutChange={handleLayoutChange}
              onDrop={newLayout => handleLayoutChange(newLayout, { isDropping: true })}
              resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
              draggableCancel='.HoverTopMenu'
              isDroppable
              droppingItem={droppingItem}
            >
              {widgets.map(widget => (
                <div key={widget.id} className={classNames(widget.settings?.fullWidth && 'fullWidth')}>
                  <EditableWidget
                    key={widget.forceRefreshAt || widget.id}
                    widget={widget}
                    onDelete={() => handleDeleteWidget(widget)}
                    onSettingsClick={() => handleOpenWidgetSettingsSidebar(widget.id)}
                  />
                </div>
              ))}
            </GridLayout>
          </div>
        )}
      </div>
    </div>
  )
}

export default EditLayoutCollectionPage
