import React, { useRef } from 'react'

import Card from 'components/common/card'
import useFetch from 'components/common/hooks/useFetch'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import API from 'services/api'
import ArticleItem from 'components/home/widgets/common/articles/articleItem'

const MustReadsWidget = ({ EmptyState = null, widget, resizeWidget }) => {
  const cardRef = useRef<HTMLDivElement>(null)
  const { data: articles, isLoaded } = useFetch(() => API.articles.pendingAcknowledgement())

  if (_.isEmpty(articles)) {
    return EmptyState
  }

  return (
    <Card className='MustReadsWidget HomePageWidget ArticleWidgetType' cardRef={cardRef}>
      <WidgetTitle title={widget.title} />

      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        isLoaded={isLoaded && widget.h > 0}
        showScrollbarOutside
      >
        <div className='d-flex flex-column gap-3'>
          {articles.map(article => (
            <ArticleItem key={article.id} article={article} widgetWidth={cardRef.current?.clientWidth} />
          ))}
        </div>
      </AutoResizableScrollableSection>
    </Card>
  )
}

export default MustReadsWidget
