import React, { useState } from 'react'

import { I18NCommon } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import classNames from 'classnames'

const DuplicateModal = ({
  className = '',
  title = '',
  label = '',
  initialName,
  visible,
  isCopying,
  onDuplicate,
  toggle,
}) => {
  const [name, setName] = useState(initialName)

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  return (
    <Modal size='md' className={classNames('DuplicateModal', className)} visible={visible} toggle={toggle}>
      <div className='pl-4 pr-4'>
        <h5>{title}</h5>
        <label className='mt-4'>{label}</label>
        <input className='w-100' value={name} onChange={onNameChange} />
        <div className='d-flex align-items-center justify-content-end mt-4 mb-2'>
          <div role='button' tabIndex={0} onClick={toggle} className='mr-3 text-muted-dark'>
            {I18NCommon('cancel')}
          </div>
          <Button showLoadingSpinner={isCopying} onClick={() => onDuplicate(name)}>{I18NCommon('create')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default DuplicateModal
