import React from 'react'

import EditTitleSetting from 'components/admin/layout/layout_collection_sidebar/common/editTitleSetting'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import CheckBox from 'components/common/buttons/checkBox'
import RadioButton from 'components/form_fields/radioButton'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import EditAutoResizable from 'components/admin/layout/layout_collection_sidebar/common/editAutoResizable'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.recent_news')

const RecentNewsSettingsSidebar = ({ widget, updateWidget }) => {
  const { settings } = widget
  const { data: articleTypes } = useGlobalStateBucket(API.articleTypes.fetchAll, 'articleTypes')

  const updateSettings = (key, value) => {
    updateWidget({ ...widget, settings: { ...widget.settings, [key]: value } })
  }

  const updateArticleTypeSettings = (checked, articleTypeId) => {
    if (checked) {
      updateSettings('articleTypeIds', [...settings.articleTypeIds, articleTypeId])
    } else {
      updateSettings('articleTypeIds', settings.articleTypeIds.filter(id => id !== articleTypeId))
    }
  }

  return (
    <div className='RecentNewsSettingsSidebar'>
      <h5>{I18N('title')}</h5>
      <p className='text-small text-secondary'>{I18N('subtitle')}</p>
      <EditTitleSetting widget={widget} updateWidget={updateWidget} />

      <hr className='w-100' />

      <label className='text-small text-secondary font-weight-500'>{I18N('display_content_from')}</label>
      <RadioButton
        id='all_articles'
        checked={settings.allArticles}
        label={I18N('all_articles')}
        onChange={() => updateSettings('allArticles', true)}
      />
      <RadioButton
        id='selected_article_Types'
        checked={!settings.allArticles}
        label={I18N('selected_article_types')}
        onChange={() => updateSettings('allArticles', false)}
      />

      {!settings.allArticles && (
        <div className='mt-2'>
          <label className='text-small text-secondary font-weight-500'>{I18N('select_article_types')}</label>
          {articleTypes?.map(articleType => (
            <CheckBox
              id={`select-article-type-${articleType.id}`}
              key={`select-article-type-${articleType.id}`}
              onChange={checked => updateArticleTypeSettings(checked, articleType.id)}
              checked={settings.articleTypeIds?.includes(articleType.id)}
              label={articleType.name}
              className='text-small'
            />
          ))}
        </div>
      )}

      <hr className='w-100' />

      <EditAutoResizable widget={widget} updateWidget={updateWidget} />

    </div>
  )
}

export default RecentNewsSettingsSidebar
