import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import searchSlice from 'redux/slices/search'
import { i18nPath } from 'utils/i18nHelpers'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.search.search_results.filters')
const googleDriveIconPath = '/images/googleDriveIcon.svg'
const confluenceIconPath = '/images/confluence.svg'
const notionIconPath = '/images/notionIcon.svg'
const bloomfireIconPath = '/images/bloomfire.svg'
const oneDriveLogoPath = '/images/integrations/oneDriveLogo.svg'

const TopLevelFilterLink = ({
  type,
  label,
  count,
  iconPath = null,
  customLabel = null,
  hasMore = false,
}) => {
  const { filter } = useQueryParams()
  const searchResultsFilter = filter || 'all'
  const location = useLocation()
  const { isLoading } = useSelector(searchSlice.selectors.getMetaData('pageAll'))
  const isActive = searchResultsFilter === type

  const setNewQueryParams = (type) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('filter', type)
    return queryParams.toString()
  }

  return (
    <div className={classNames('TopLevelFilterLink py-2 px-3', { isActive })}>
      <Link
        className={classNames('text-primary-link d-flex justify-content-between align-items-center', { noResults: isLoading || !count })}
        to={`/search?${setNewQueryParams(type)}`}
      >
        <div className='d-flex align-items-center'>
          {iconPath && <CdnSvg src={iconPath} className='mr-2 d-flex flex-1 align-items-center' />}
          <span>{customLabel || I18N(label)}</span>
        </div>
        <span className={classNames('text-small', isActive ? '' : 'text-secondary')}>{isLoading ? '' : count}{hasMore ? '+' : ''}</span>
      </Link>
    </div>
  )
}

const TopLevelFilter = () => {
  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const {
    totalResults,
    totalResultsByType,
    hasMoreByType,
  } = useSelector(searchSlice.selectors.getMetaData('pageAll'))
  const topCount = totalResultsByType.top

  const pageDisplayName = currentCompany.pageDisplayName || I18N('pages')
  const eventDisplayName = currentCompany.eventDisplayName || I18N('qna')
  const groupDisplayName = currentCompany.pluralGroupDisplayName || I18N('groups')

  const clearyResults = [
    {
      key: 'all',
      count: topCount,
      customLabel: I18N('top_results'),
      iconPath: '/images/app_launcher/starIcon.svg',
    },
    { key: 'people', count: totalResultsByType.user, iconPath: '/images/peopleIcon.svg' },
    settings.pages.enabled && settings.globalSearch.pages.enabled && {
      key: 'pages', count: totalResultsByType.page, customLabel: pageDisplayName, iconPath: '/images/bookOpenIcon.svg',
    },
    settings.golinks.enabled && settings.globalSearch.golinks.enabled && { key: 'links', count: totalResultsByType.goLink, iconPath: '/images/linkHorizontalIcon.svg' },
    settings.news.enabled && settings.globalSearch.news.enabled && { key: 'articles', count: totalResultsByType.article, iconPath: '/images/newspaperIcon.svg' },
    settings.pages.enabled && settings.pages.faqs.enabled && settings.globalSearch.pageFaqs.enabled && { key: 'page_faqs', count: totalResultsByType.pageFaq, iconPath: '/images/questionIcon.svg' },
    settings.profile.fields.userSkills && settings.globalSearch.userSkills.enabled && { key: 'user_skills', count: totalResultsByType.userSkill, iconPath: '/images/lightBulbIcon.svg' },
    settings.groups.enabled && settings.globalSearch.groups.enabled && {
      key: 'groups', count: totalResultsByType.group, customLabel: groupDisplayName, iconPath: '/images/teamsIcon.svg',
    },
    settings.qna.enabled && settings.globalSearch.qna.enabled && {
      key: 'qna', count: totalResultsByType.qnaEvent, customLabel: eventDisplayName, iconPath: '/images/calendarIcon.svg',
    },
    settings.appLauncher?.enabled && settings.globalSearch.apps.enabled && { key: 'apps', count: totalResultsByType.appLauncherApp, iconPath: '/images/app_launcher/appLauncherIcon.svg' },
    settings.globalSearch.attachedFileContents.enabled && { key: 'attached_file_contents', count: totalResultsByType.attachedFileContent, iconPath: '/images/blankPageIcon.svg' },
  ].filter(Boolean)

  const integrationsResults = [
    settings.googleDriveSearch.enabled && {
      key: 'google_drive', count: totalResultsByType.googleDrive, iconPath: googleDriveIconPath, hasMore: hasMoreByType?.googleDrive,
    },
    settings.confluenceSearch.enabled && { key: 'confluence', count: totalResultsByType.confluence, iconPath: confluenceIconPath },
    settings.notionSearch.enabled && { key: 'notion', count: totalResultsByType.notion, iconPath: notionIconPath },
    settings.bloomfireSearch.enabled && {
      key: 'bloomfire',
      label: settings.bloomfireSearch?.integrationName || 'bloomfire',
      count: totalResultsByType.bloomfire,
      iconPath: settings.bloomfireSearch?.integrationIconPath || bloomfireIconPath,
    },
    settings.oneDrive.searchEnabled && { key: 'one_drive', count: totalResultsByType.oneDrive, iconPath: oneDriveLogoPath },
  ].filter(Boolean)

  return (
    <div className='TopLevelFilter'>
      <div className='text-small text-secondary px-3 mb-2'>{I18N('results_found', { count: totalResults })}</div>
      {clearyResults.map(({
        key, count, customLabel, iconPath,
      }) => (
        <TopLevelFilterLink
          key={key}
          type={key}
          label={key}
          count={count}
          customLabel={customLabel}
          iconPath={iconPath}
        />
      ))}
      {integrationsResults.length > 0 && (
        <>
          <hr className='m-4' />
          <div className='text-small text-dark-muted px-3 mb-3'>{I18N('integrations')}</div>
          {integrationsResults.map(({
            key, count, label, iconPath, hasMore = false,
          }) => (
            <TopLevelFilterLink
              key={key}
              label={label || key}
              count={count}
              type={key}
              iconPath={iconPath}
              hasMore={hasMore}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default TopLevelFilter
