import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Button } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useIsCelebrationCustomized from 'components/celebrations/hooks/useIsCelebrationCustomized'
import { useSelector } from 'react-redux'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CelebrationSocialComments from 'components/celebrations/celebrationSocialComments'

const I18N = i18nPath('views.celebrations.card')

const CelebrationSocial = ({ celebration, isCurrentUser }) => {
  const disableNewComments = moment().isAfter(moment(celebration.eventDate).add(7, 'days'))
  const i18nPrefix = `${celebration.type}.greeting_card`
  const customized = useIsCelebrationCustomized(celebration)
  const mustOpenCard = isCurrentUser && !celebration.openedAt
  const [commentIds, setCommentIds] = useState([])
  const comments = useSelector(entitySlice.selectors.getEntities('comment', commentIds))

  const [fetchComments, { isLoading }] = useApi(API.comments.fetchAll, {
    addEntitySlice: true,
    onSuccess: (data) => {
      setCommentIds(prev => _.uniq([...prev, ...data.map(d => d.id)]))
    },
  })

  useEffect(() => {
    setCommentIds([])
    fetchComments({ page: 1, perPage: 3 }, celebration, 'celebration')
  }, [])

  if (celebration.upcoming) {
    if (isCurrentUser) {
      return null
    }

    if (celebration.hasCommented) {
      return (
        <div className='ClickWrapperTargetContainer d-flex flex-column align-items-center my-3'>
          <div className='mb-3 text-center text-small ClickWrapperTargetContainer'>
            <Link to={`/people/celebrations/${celebration.id}?source=feed`}>
              <Button>
                {I18N('celebration_feed_item.cta.view_all_messages')}
              </Button>
            </Link>
          </div>
        </div>
      )
    }

    return (
      <div className='ClickWrapperTargetContainer d-flex flex-column align-items-center mb-3'>
        <div className='mb-3 text-center text-small ClickWrapperTargetContainer'>
          {I18N(`${i18nPrefix}.upcoming.description`)}
        </div>
        <Link
          to={
            !customized
              ? `/people/celebrations/${celebration.id}/edit?source=feed`
              : `/people/celebrations/${celebration.id}?source=feed`
          }
        >
          <Button>
            {I18N(
              !customized
                ? 'celebration_feed_item.cta.customize_and_sign_card'
                : 'celebration_feed_item.cta.sign_card'
            )}
          </Button>
        </Link>
      </div>
    )
  }

  // don't link to greeting card if there are no greetings for the user :(
  if (isCurrentUser && celebration.commentsCount === 0) {
    return null
  }

  const showSignCardCta = !isCurrentUser && !celebration.hasCommented && !disableNewComments

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='ClickWrapperTargetContainer d-flex flex-column align-items-center mb-3'>
      <CelebrationSocialComments celebration={celebration} isCurrentUser={isCurrentUser} comments={comments} />
      <Link
        className='mt-3'
        to={`/people/celebrations/${celebration.id}?source=feed`}
      >
        <Button>
          {showSignCardCta && !mustOpenCard && I18N('celebration_feed_item.cta.sign_card')}
          {!showSignCardCta && !mustOpenCard && I18N('celebration_feed_item.cta.view_all_messages')}
          {mustOpenCard && I18NCommon('open_card')}
        </Button>
      </Link>
    </div>
  )
}

export default CelebrationSocial
