import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SingleStatTile from 'components/analytics/common/singleStatTile'
import searchAnalyticsSlice from 'redux/slices/analytics/search'
import engagementSlice from 'redux/slices/analytics/engagement'
import { i18nPath } from 'utils/i18nHelpers'
import { toPercentage } from 'utils/analytics'
import TargetingRulesType from 'types/audience/targetingRules'
import getTargetedDescriptionKey from 'components/analytics/helpers/getTargetedDescriptionKey'

const APP_MODULES = [
  'search',
  'go_link',
]

const BUCKET = 'search'

const I18N = i18nPath('views.platform_analytics.search')

interface SearchSummaryProps {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const SearchSummary = ({ periodStart, periodEnd, targetingRules }: SearchSummaryProps) => {
  const dispatch = useDispatch()

  const summary = useSelector(searchAnalyticsSlice.selectors.getSummary())
  const { summary: { isLoading } } = useSelector(searchAnalyticsSlice.selectors.getMetaData())
  const activityCount = useSelector(engagementSlice.selectors.getActivityCount(BUCKET))
  const { isLoading: isLoadingActivityCount } = useSelector(engagementSlice.selectors.getActivityCountMeta(BUCKET))

  const avgTimeToSelectResult = `${(summary.avgTimeToSelectResult / 1000).toFixed(1)} ${I18N('seconds')}`
  const userClickedResultPercent = toPercentage(summary.userClickedResultPercent)


  useEffect(() => {
    dispatch(searchAnalyticsSlice.asyncActions.admin.fetchSummary(
      { periodStart, periodEnd, targetingRules }
    ))
    dispatch(engagementSlice.asyncActions.admin.fetchActivityCount(
      'search',
      {
        periodStart,
        periodEnd,
        unique: true,
        appModules: APP_MODULES.join(','),
        targetingRules,
      }
    ))
  }, [periodStart, periodEnd, targetingRules])

  const hasTargetingSelected = !!targetingRules

  const uniqueUsersDescriptionKey = getTargetedDescriptionKey('unique_users', hasTargetingSelected)
  const totalSearchesKey = getTargetedDescriptionKey('total_searches', hasTargetingSelected)
  const averageSelectTimeKey = getTargetedDescriptionKey('average_select_time', hasTargetingSelected)
  const userClickPercentageKey = getTargetedDescriptionKey('user_click_percentage', hasTargetingSelected)

  return (
    <>
      <SingleStatTile
        value={activityCount}
        description={I18N(uniqueUsersDescriptionKey)}
        isLoading={isLoadingActivityCount}
      />

      <SingleStatTile
        value={summary.searchesCount}
        description={I18N(totalSearchesKey)}
        isLoading={isLoading}
      />
      <SingleStatTile
        value={avgTimeToSelectResult}
        description={I18N(averageSelectTimeKey)}
        isLoading={isLoading}
      />
      <SingleStatTile
        value={userClickedResultPercent}
        description={I18N(userClickPercentageKey)}
        isLoading={isLoading}
      />
    </>
  )
}

export default SearchSummary
