import React from 'react'
import Card from 'components/common/card'

const CardBoxShadow = () => (
  <Card title='Lorem' subtitle='ipsum'>
    Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
  </Card>
)

export default CardBoxShadow
