import React, { useRef, useEffect, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { SurveyQuestionType } from 'types/surveys/question'
import QuestionOptionsList from 'components/admin/surveys/questionOptionsList'
import arrayMove from 'array-move'
import { SurveyQuestionOptionType } from 'types/surveys/questionOption'

const I18N = i18nPath('views.admin.surveys')

interface QuestionOptionsEditFormProps {
  index?: number,
  question: SurveyQuestionType,
  updateQuestion?: (changes: Partial<SurveyQuestionType>) => void,
  isDisabled?: boolean,
}

const QuestionOptionsEditForm = ({
  index = 0,
  question,
  updateQuestion = (changes: Partial<SurveyQuestionType>) => {},
  isDisabled = false,
} : QuestionOptionsEditFormProps) => {
  const options: any = question?.options || []
  const latestInputRef = useRef<HTMLInputElement | null>(null)
  const [shouldUpdateFocus, setShouldUpdateFocus] = useState(false)

  const addOption = () => {
    updateQuestion({ options: [...options, { value: '' }] })
    setShouldUpdateFocus(true)
  }

  useEffect(() => {
    if (latestInputRef.current && shouldUpdateFocus) {
      latestInputRef.current.focus()
      setShouldUpdateFocus(false)
    }
  }, [options.length])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOptions: SurveyQuestionOptionType[] = [
        ...arrayMove(options, oldIndex, newIndex) as SurveyQuestionOptionType[],
      ]

      newOptions.forEach((option, index) => {
        option.orderPosition = index
      })

      updateQuestion({ options: newOptions })
    }
  }

  return (
    <div className='QuestionOptionsEditForm' id={`question-edit-form-${index}`}>
      <QuestionOptionsList
        options={options}
        question={question}
        updateQuestion={updateQuestion}
        isDisabled={isDisabled}
        addOption={addOption}
        onSortEnd={onSortEnd}
        latestInputRef={latestInputRef}
        useDragHandle
        lockAxis='y'
        helperClass='isDraggingQuestionOption'
      />
      {!isDisabled && <a className='link-color' onClick={addOption}>+ {I18N('questions.add_option')}</a>}
    </div>
  )
}

export default QuestionOptionsEditForm
