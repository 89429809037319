import AsyncSearchInput from 'components/common/asyncSearchInput'
import { ButtonLink } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import DateCell from 'components/common/tables/dateCell'
import SmartTable from 'components/common/tables/smartTable'
import CompanyStatusSelector from 'pages/admin/companies/companyStatusSelector'
import CompanyTypeSelector from 'pages/admin/companies/companyTypeSelector'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.companies')

const defaultPaginationParams = { sortBy: 'created_at', sortDir: 'desc', page: 1 }

const CompanyListPage = () => {
  const [paginationParams, setPaginationParams] = useState(defaultPaginationParams)
  const [query, setQuery] = useQueryParamState({ param: 'q', initialValue: '' })
  const [type, setType] = useQueryParamState<null | string>({ param: 'type', initialValue: null })
  const [status, setStatus] = useQueryParamState<null | string>({ param: 'status', initialValue: null })
  const [companies, setCompanies] = useState<any[]>([])

  const {
    isLoading, paginationData: { page, totalPages }, isNotFound,
  } = useFetch(() => API.admin.company.fetchAll({
    q: query,
    type,
    status,
    ...paginationParams,
  }), [query, paginationParams, type, status], {
    onSuccess: setCompanies,
  })

  const updateCompanyState = (id, data) => {
    const newCompanies = companies.map((c) => {
      if (c.id === id) {
        return { ...c, ...data }
      }
      return c
    })

    setCompanies(newCompanies)
  }

  const [updateCompanyApi] = useApi(API.admin.company.update, {
    toastSuccessMessage: I18NCommon('updated_successfully'),
  })

  const updateCompany = (id, data) => {
    updateCompanyApi({ ...data, id })
    updateCompanyState(id, data)
  }

  const columns = [
    {
      header: I18N('name'),
      col: 'col-name',
      id: 'name',
      accessor: d => (
        <Link to={`/admin/companies/${d.id}`}>
          {d.name}
        </Link>
      ),
    },
    {
      header: I18N('log_in'),
      col: 'col-log-in',
      accessor: d => (
        <span>
          <a href={`/api/admin/clearyadmin_login?company_id=${d.id}`} target='_blank' rel='noopener noreferrer'>
            {I18N('log_in')}
          </a>
        </span>
      ),
    },
    {
      header: I18N('app_name'),
      col: 'col-appName',
      id: 'app_name',
      accessor: d => d.appName,
    },
    {
      header: I18N('status'),
      id: 'status',
      col: 'col-status',
      accessor: d => (
        <CompanyStatusSelector value={d.status} onChange={(status) => { updateCompany(d.id, { status }) }} />
      ),
    },
    {
      header: I18NCommon('type'),
      id: 'type',
      col: 'col-type',
      accessor: d => (
        <CompanyTypeSelector value={d.type} onChange={(type) => { updateCompany(d.id, { type }) }} />
      ),
    },
    {
      header: I18NCommon('created_at'),
      col: 'col-created_at',
      id: 'created_at',
      accessor: d => <DateCell date={d.createdAt} />,
    },
  ]

  return (
    <div className='CompanyListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('companies')}</h3>
        <div className='AdminButtonContainer'>
          <ButtonLink to='/admin/companies/new'>
            {I18N('create_new_company')}
          </ButtonLink>
        </div>
      </header>

      <main className='AdminContent'>
        <div className='CompaniesTable row mb-4'>
          <div className='col'>
            <div className='d-flex justify-content-start mb-3'>
              <AsyncSearchInput
                placeholder={I18N('search_placeholder')}
                icon={<CdnSvg src='/images/searchIcon.svg' />}
                onKeyUp={setQuery}
                className='mb-0 w-100'
                inputGroupClassName='w-lg-25 w-100'
                debounceWait={300}
                defaultValue={query}
              />

              <CompanyStatusSelector
                value={status}
                onChange={setStatus}
                isClearable
                className='pos-relative z-index-2 ml-3'
                placeholder={I18N('filter_by_status')}
              />

              <CompanyTypeSelector
                value={type}
                onChange={setType}
                isClearable
                className='pos-relative z-index-2 ml-3'
                placeholder={I18N('filter_by_type')}
              />
            </div>

            <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
              <div className='overflow-x-auto'>
                <SmartTable
                  data={companies}
                  sortKey='created_at'
                  sortedAsc={false}
                  showPagination={page !== null && totalPages !== null}
                  columns={columns}
                  page={page}
                  pages={totalPages}
                  onPaginationClick={setPaginationParams}
                  onSortChange={setPaginationParams}
                  className='white-bg-table'
                />
              </div>
            </TableLoadingContainer>
          </div>
        </div>
      </main>
    </div>
  )
}

export default CompanyListPage
