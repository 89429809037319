import CdnSvg from 'components/common/cdnSvg'
import HoverTopMenu from 'components/common/hoverTopMenu'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

type Props = {
  onDelete: () => void
  onSettingsClick?: () => void
}

const WidgetMenu = ({ onDelete, onSettingsClick }: Props) => (
  <HoverTopMenu>
    {onSettingsClick && (
      <IconButtonWithPopover
        id='settings'
        text={I18NCommon('settings')}
        icon={<CdnSvg src='/images/gearIcon.svg' />}
        onClick={onSettingsClick}
        className='mr-2'
        popoverPlacement='top'
      />
    )}
    <IconButtonWithPopover
      id='delete'
      text={I18NCommon('delete')}
      icon={<CdnSvg src='/images/trashIcon.svg' />}
      onClick={onDelete}
      className='mr-0'
      popoverPlacement='top'
    />
  </HoverTopMenu>
)

export default WidgetMenu
