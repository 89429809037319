import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ClearyInternalSearchResultsTemplate from 'components/search/search_page_results/clearyInternalSearchResultsTemplate'
import SearchResultTemplate from 'components/search/search_page_results/searchResultTemplate'
import { Link } from 'react-router-dom'
import { I18NCommon } from 'utils/i18nHelpers'
import FAQLastUpdater from 'components/pages/faqs/faqLastUpdater'

export const PageFAQResult = ({ result }) => {
  const { pageFaq, highlights } = result
  const {
    question = '', answer = '', page,
  } = { ...pageFaq, ...highlights }

  return (
    <SearchResultTemplate
      displayPath={result.displayPath}
      image={<CdnSvg src='/images/questionIcon.svg' />}
      pretitle='FAQ'
      title={question}
      subtitle={answer}
      result={result}
      description={(
        <div className='d-flex flex-wrap align-items-center gap-2 text-small text-secondary mt-2'>
          <FAQLastUpdater faq={pageFaq} />
          <span>•</span>
          <span>
            {I18NCommon('in')}&nbsp;
            <Link to={page.displayPath} className='text-primary' onClick={e => e.stopPropagation()}>
              {page.title}
            </Link>
          </span>
        </div>
      )}
    />
  )
}

const PageFAQResults = ({ showLabel = true }) => (
  <ClearyInternalSearchResultsTemplate
    showLabel={showLabel}
    label='page_faqs'
    type='pageFaq'
    queryType='Page::FAQ'
    searchResultToTemplateMap={result => <PageFAQResult result={result} />}
  />
)

export default PageFAQResults
