import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import { ReduxState } from 'redux/redux'
import API from 'services/api'
import appSignal from 'services/appSignal'
import entitySlice from 'redux/slices/entities'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { getResponseOrThrow } from 'utils/errorHandling'
import { AwardedBadgeState } from 'types/awardedBadge'


const defaultQueryParams = { ...defaultPaginationParams, perPage: 18 }

export const initialState: AwardedBadgeState = {
  awardedBadgesIds: [],
  meta: {
    ...defaultMeta,
    queryParams: defaultQueryParams,
  },
}

const awardedBadgeSlice = createSlice({
  name: 'awardedBadges',
  initialState,
  reducers: {
    ...defaultActions,

    mergeAwardedBadgesIds(state, action) {
      state.awardedBadgesIds = _.uniq([
        ...state.awardedBadgesIds,
        ...action.payload,
      ])
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    clearAwardedBadgesIds(state) {
      state.awardedBadgesIds = []
    },

    removeAwardedBadgeId(state, action) {
      const index = state.awardedBadgesIds.indexOf(action.payload)
      state.awardedBadgesIds.splice(index, 1)
    },
  },
})

const asyncActions = {
  fetchAll: queryParams => async (dispatch) => {
    try {
      dispatch(awardedBadgeSlice.actions.isLoading(true))
      const response = await API.awardedBadges.fetchAll(queryParams)
      const awardedBadgesIds = response.data.data.map(awardedBadge => awardedBadge.id)
      const newQueryParams = queryParamsFromHeaders(response)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(awardedBadgeSlice.actions.mergeAwardedBadgesIds(awardedBadgesIds))
      dispatch(awardedBadgeSlice.actions.setQueryParams(newQueryParams))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(awardedBadgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(awardedBadgeSlice.actions.isLoading(false))
    }
  },

  fetch: (badgeId: string, awardedBadgeId: string) => async (dispatch) => {
    try {
      dispatch(awardedBadgeSlice.actions.isLoading(true))
      const response = await API.awardedBadges.fetch(badgeId, awardedBadgeId)
      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(awardedBadgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(awardedBadgeSlice.actions.isLoading(false))
    }
  },

  create: (badgeId: string, recipientIds: string[], onSuccess: () => null) => async (dispatch) => {
    try {
      dispatch(awardedBadgeSlice.actions.isLoading(true))
      const response = await API.awardedBadges.create(badgeId, recipientIds)
      const awardedBadgesIds = response.data.data.map(awardedBadge => awardedBadge.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(awardedBadgeSlice.actions.mergeAwardedBadgesIds(awardedBadgesIds))
      onSuccess()
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(awardedBadgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(awardedBadgeSlice.actions.isLoading(false))
    }
  },

  destroy: (id: string, badgeId: string) => async (dispatch) => {
    try {
      dispatch(awardedBadgeSlice.actions.isLoading(true))
      await API.awardedBadges.destroy(id, badgeId)

      dispatch(awardedBadgeSlice.actions.removeAwardedBadgeId(id))
      dispatch(entitySlice.actions.remove({ id, type: 'awardedBadge' }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(awardedBadgeSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(awardedBadgeSlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.awardedBadges.meta,

  getAwardedBadges: () => (state: ReduxState) => state.awardedBadges.awardedBadgesIds.map(id => build(state.entities, 'awardedBadge', id)).filter(Boolean),

  getAwardedBadge: (awardedBadgeId: string) => (state: ReduxState) => build(state.entities, 'awardedBadge', awardedBadgeId) || {},
}

export default {
  ...awardedBadgeSlice,
  asyncActions,
  selectors,
}
