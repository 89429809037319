import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import linkSlice from 'redux/slices/links'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import Card from 'components/common/card'
import { Button } from 'components/common/buttons'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import SuggestedIcon from 'components/common/suggestedIcon'
import AddGoLinkModal from 'components/groups/key_links/addGoLinkModal'
import EditGoLinkModal from 'components/groups/key_links/editGoLinkModal'
import RemoveGoLinkModal from 'components/groups/key_links/removeGoLinkModal'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.groups.group_page')
const manWithTabletImagePath = '/images/manWithTablet.svg'
const pencilIconPath = '/images/pencilIcon.svg'
const plusIconPath = '/images/plusIcon.svg'
const xIconPath = '/images/xIcon.svg'

const EmptyState = ({ onAddLinkClick, canEditGroupFields }) => (
  <div className='d-flex flex-column align-items-center gap-3'>
    <CdnSvg src={manWithTabletImagePath} />

    <div className='text-secondary text-center w-100'>
      {canEditGroupFields ? I18N('key_links_empty_state_admin') : I18N('key_links_empty_state')}
    </div>

    {canEditGroupFields && (
      <Button className='text-center' variant='secondary' onClick={onAddLinkClick}>
        <CdnSvg src={plusIconPath} className='Icon mr-1' />
        {I18N('add_link')}
      </Button>
    )}
  </div>
)

const ListItem = ({
  goLink,
  onEditLinkClick,
  onRemoveLinkClick,
  useGoLinksPrefix = false,
  canEditGroupFields = false,
}) => {
  const currentUser = useCurrentUser()
  const canEditLink = goLink.user.id === currentUser.id
  const canDeleteLink = canEditLink || canEditGroupFields
  const goName = useGoLinksPrefix ? `go/${goLink.name}` : goLink.name

  return (
    <div className='link-item py-2 d-flex align-items-center'>
      <a className='d-flex align-items-center w-100' href={goLink.displayUrl} target='_blank' rel='noopener noreferrer'>
        <SuggestedIcon name={goLink.displayName} url={goLink.url} iconUrl={goLink.iconUrl} />
        <span className='link-name truncate-text-at-1-lines'>{goLink.displayName || goName}</span>
      </a>
      <div className='d-flex ml-2'>
        {canEditLink && (
          <div onClick={() => onEditLinkClick(goLink)} className='clickable-icon visible-on-hover' data-cy='edit-icon'>
            <CdnSvg src={pencilIconPath} className='Icon' />
          </div>
        )}
        {canDeleteLink && (
          <div onClick={() => onRemoveLinkClick(goLink)} className='clickable-icon visible-on-hover' data-cy='remove-icon'>
            <CdnSvg src={xIconPath} className='Icon' />
          </div>
        )}
      </div>
    </div>
  )
}

const LinksList = ({
  goLinks = [],
  onAddLinkClick,
  onEditLinkClick,
  onRemoveLinkClick,
  useGoLinksPrefix,
  canEditGroupFields,
}) => (
  <>
    <div className='links-list mb-3 d-flex flex-column'>
      {goLinks.map(goLink => (
        <ListItem
          key={`go-link-${goLink.id}`}
          goLink={goLink}
          useGoLinksPrefix={useGoLinksPrefix}
          onEditLinkClick={onEditLinkClick}
          onRemoveLinkClick={onRemoveLinkClick}
          canEditGroupFields={canEditGroupFields}
        />
      ))}
    </div>
    {canEditGroupFields && (
      <span onClick={onAddLinkClick} className='add-link d-flex align-items-center font-weight-600'>
        <CdnSvg src={plusIconPath} className='Icon empty-state d-flex mr-1' />
        {I18N('add_link')}
      </span>
    )}
  </>
)

const KeyLinks = ({ group }) => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()
  const useGoLinksPrefix = currentCompany.settings.golinks.enabled

  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [goLinkToEdit, setGoLinkToEdit] = useState()
  const [goLinkToRemove, setGoLinkToRemove] = useState()

  const { isLoading } = useSelector(linkSlice.selectors.getMetaData())
  const collectionId = useSelector(linkSlice.selectors.getCollectionId())
  const goLinks = useSelector(linkSlice.selectors.getGroupGoLinks(group.id))
  const goLinkIds = goLinks.map(({ id }) => id)
  const isEmpty = goLinks.length === 0
  const showLinksList = !isLoading && !isEmpty
  const canEditGroupFields = group.permittedActions?.editFields || false

  useEffect(() => {
    dispatch(linkSlice.asyncActions.fetchGroupGoLinks(group.id))
  }, [group.id])

  return (
    <section className={classNames('KeyLinks mb-4', { 'list-visible': showLinksList })}>
      <Card>
        <h6 className='title'>{I18N('key_links')}</h6>

        {isLoading && <CirclesLoadingIndicator />}

        {!isLoading && isEmpty && (
          <EmptyState
            onAddLinkClick={() => setIsAddModalOpen(true)}
            canEditGroupFields={canEditGroupFields}
          />
        )}

        {showLinksList && (
          <LinksList
            goLinks={goLinks}
            onAddLinkClick={() => setIsAddModalOpen(true)}
            onEditLinkClick={setGoLinkToEdit}
            onRemoveLinkClick={setGoLinkToRemove}
            useGoLinksPrefix={useGoLinksPrefix}
            canEditGroupFields={canEditGroupFields}
          />
        )}

        {goLinkToEdit && (
          <EditGoLinkModal
            goLink={goLinkToEdit}
            groupId={group.id}
            onClose={() => setGoLinkToEdit(undefined)}
          />
        )}

        {goLinkToRemove && (
          <RemoveGoLinkModal
            goLink={goLinkToRemove}
            groupId={group.id}
            collectionId={collectionId}
            onClose={() => setGoLinkToRemove(undefined)}
          />
        )}

        {isAddModalOpen && (
          <AddGoLinkModal
            groupId={group.id}
            collectionId={collectionId}
            blocklistIds={goLinkIds}
            onClose={() => setIsAddModalOpen(false)}
          />
        )}
      </Card>
    </section>
  )
}

export default KeyLinks
