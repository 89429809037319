import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import CdnSvg from 'components/common/cdnSvg'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

const archiveBoxIconPath = '/images/archiveBoxIcon.svg'

const I18N = i18nPath('views.pages')

const PageArchiveLink = ({
  workspaceSlug = null,
  className,
}) => {
  const location = useLocation()
  const pathName = location.pathname
  const workspaceArchivePath = `/workspaces/${workspaceSlug}/archived_pages`
  const isActive = pathName === workspaceArchivePath
  const toUrl = workspaceSlug ? workspaceArchivePath : '/archived_pages'

  return (
    <Link
      to={toUrl}
      className={classNames('PageArchiveLink', className, { isActive })}
    >
      <CdnSvg src={archiveBoxIconPath} className='ArchiveBoxIcon' />
      <span className='mb-0 font-weight-500'> {I18N('archive')}</span>
    </Link>
  )
}

export default PageArchiveLink
