import React from 'react'

const CLICKABLE_TAGS = ['A', 'BUTTON', 'INPUT', 'SELECT', 'TEXTAREA']
const CLICKABLE_ROLES = ['button', 'link', 'checkbox', 'radio', 'menuitem', 'option', 'switch']

const isFirstClickableElement = (e: React.MouseEvent, elementId: string) => {
  let currentElement = e.target as HTMLElement
  while (currentElement) {
    if (currentElement.id === elementId) return true

    if (CLICKABLE_TAGS.includes(currentElement.tagName)) return false
    if (CLICKABLE_ROLES.includes(currentElement.getAttribute('role') || '')) return false
    if (currentElement.onclick) return false

    currentElement = currentElement.parentElement as HTMLElement
  }

  return true
}

export default isFirstClickableElement
