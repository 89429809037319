import React, { useState, useEffect } from 'react'

import classNames from 'classnames'
import HelpTooltip from 'components/common/tooltip'

const PillTabSelector = ({
  tabs,
  pillClasses = '',
  defaultSelectedIndex = 0,
  className = '',
  variant = 'PillTab', // or PageTab
  hideIfOnlyOneTab = false,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultSelectedIndex)

  tabs = tabs.filter(tab => !tab.hidden)

  useEffect(() => {
    setSelectedTabIndex(defaultSelectedIndex)
  }, [defaultSelectedIndex])

  const handleClick = (e, tabIndex) => {
    const tab = tabs[tabIndex]
    if (tab.disabled) {
      e.preventDefault()
      return
    }

    if (tab.url && (e.ctrlKey || e.metaKey)) {
      // if tabs has url
      // and is clicking with ctrl or cmd
      // let just open in a new tab
      // without preventing the default
      return
    }

    e.preventDefault()
    setSelectedTabIndex(tabIndex)
    tab.onClick()
  }

  if (tabs.length === 1 && hideIfOnlyOneTab) {
    return null
  }

  return (
    <div className={classNames('PillTabSelector d-flex align-items-center', className)}>
      {tabs.map((tabData, i) => (
        <a
          href={tabData.url}
          className={
            classNames(
              'Tab mb-0 pointer',
              variant,
              tabData.className,
              { [`${variant}--selected`]: i === selectedTabIndex },
              pillClasses
            )
          }
          onClick={e => handleClick(e, i)}
          key={`pill-tab-${i}`}
        >
          {tabData.text}
          {tabData.showTooltip && (
            <HelpTooltip
              iconStyle={{ width: '16px', height: '16px' }}
              placement='bottom'
              className='ml-2'
              tooltipClassName={tabData.tooltipClassName}
              tooltipInnerClassName={tabData.tooltipInnerClassName}
            >
              {tabData.tooltipText}
            </HelpTooltip>
          )}
        </a>
      ))}
    </div>
  )
}

export default PillTabSelector
