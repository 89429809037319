import React from 'react'
import classNames from 'classnames'
import { useLocation, useParams } from 'react-router-dom'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import useCreateDraftPage from 'components/pages/hooks/useCreateDraftPage'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import CdnSvg from 'components/common/cdnSvg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { i18nPath } from 'utils/i18nHelpers'
import pageSlice from 'redux/slices/pages'
import PageArchiveLink from 'components/pages/pageArchiveLink'

const MAX_TREE_ITEM_INDENTING_DEPTH = 6

const I18N = i18nPath('views.pages')
const plusIconPath = '/images/plusIcon.svg'

const PagesNavComplexTreeItem = ({
  item,
  title,
  children,
  context,
  arrow,
  depth,
}) => {
  const { pageSlug, workspaceSlug } = useParams()
  const isArchiveItem = !!workspaceSlug && item.index === 'archive'
  const workspaceArchivePath = `/workspaces/${workspaceSlug}/archived_pages`
  const possiblePaths = useWorkspaceUrls().generateAllPagePaths(item)
  const location = useLocation()

  const isCurrentNodeActive = isArchiveItem
    ? workspaceArchivePath === location.pathname
    : possiblePaths.includes(location.pathname)
  const minDepth = Math.min(depth, MAX_TREE_ITEM_INDENTING_DEPTH)
  const { page } = useSelector(pageSlice.selectors.getPageData(pageSlug))
  const createSubPage = useCreateDraftPage({
    parentPageId: page.id,
    parentArchivedAt: page.archivedAt,
    pageWorkspaceId: page.pageWorkspaceId,
  })
  const canCreateChildPages = page?.permittedActions?.canCreateChildPages

  if (isArchiveItem) {
    return (
      <div className={classNames('Item', { highlighted: isCurrentNodeActive, isDraggedOver: context.isDraggingOver })} {...context.itemContainerWithChildrenProps}>
        <div className='ItemTitle-row' {...context.itemContainerWithoutChildrenProps} {...context.interactiveElementProps}>
          <div className='ItemTitle-content' data-depth={0}>
            {arrow}
            <PageArchiveLink
              workspaceSlug={workspaceSlug}
              className={classNames('ItemTitle', { 'text-primary-link': !isCurrentNodeActive })}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classNames('Item', { highlighted: isCurrentNodeActive, isDraggedOver: context.isDraggingOver })} {...context.itemContainerWithChildrenProps}>
      <div className='ItemTitle-row' {...context.itemContainerWithoutChildrenProps}>
        <div className='ItemTitle-content' data-depth={minDepth}>
          {arrow}
          {title}
          {canCreateChildPages && isCurrentNodeActive && (
            <IconButtonWithPopover
              id='create-page-button'
              text={I18N('add_subpage_tooltip')}
              icon={<CdnSvg src={plusIconPath} className='PlusIcon d-flex align-items-center' />}
              onClick={createSubPage}
              className='AddSubpageButton mr-2'
              popoverClassName='AddSubpageTooltip p-2'
              textClassName='text-white text-smallest'
            />
          )}
        </div>
      </div>
      {children}
    </div>
  )
}

export default PagesNavComplexTreeItem
