import classNames from 'classnames'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.journeys.my_tasks')
const womanTypingImagePath = '/images/illustrations/womanTypingImage.svg'

const MyTasksHeaderTile = ({
  name,
  hasDirectReports = false,
  openTasksCount = 0,
}) => (
  <div className={classNames('MyTasksHeaderTile', { hasDirectReports })}>
    <h4>{I18N('header_text', { name, count: openTasksCount })}</h4>
    <CdnSvg className='header-image' src={womanTypingImagePath} />
  </div>
)

export default MyTasksHeaderTile
