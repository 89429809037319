import React from 'react'
import { Link } from 'react-router-dom'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import StatusLabel from 'components/common/status_label/'
import getJourneyBlueprintStatusColor from 'utils/admin/journeys/statusColors'
import SmartTable from 'components/common/tables/smartTable'
import stepTemplateTriggerText from 'utils/journeys/stepTemplateTriggerText'
import StepTemplateType from 'types/journeys/stepTemplate'
import CdnSvg from 'components/common/cdnSvg'
import StepTemplateActionsDropdown from 'components/admin/journeys/journeyBlueprints/stepTemplateActionsDropdown'

const CorrespondentIcon = '/images/correspondentIcon.svg'

const I18N = i18nPath('views.admin.journeys.journey_blueprints')

const StepTemplateLinkWithStatusLabel = ({ stepTemplate }) => {
  const state = stepTemplate?.state
  const level = stepTemplate?.depth || 0

  return (
    <div
      className='StepLinkWithStatusLabel d-flex justify-content-start align-items-center'
      style={{ marginLeft: `${level * 15}px` }}
    >
      {level > 0 && <div className='l-vector mr-2' />}
      <Link to={stepTemplate?.adminDisplayPath}>{stepTemplate?.name}</Link>
      {state === 'draft' && (
        <StatusLabel
          className='ml-2 font-weight-500 text-normal'
          color={getJourneyBlueprintStatusColor(state)}
        >
          {I18NCommon(state)}
        </StatusLabel>
      )}
    </div>
  )
}

const CorrespondentLink = ({ correspondent }) => {
  if (!_.isEmpty(correspondent?.user)) {
    return (
      <div className='d-flex align-items-center'>
        {correspondent.user.active ? (
          <EmployeeThumbnailPhoto employee={correspondent.user} size='32px' className='mr-2 flex-shrink-0' />
        ) : (
          <CdnSvg src='/images/alertOutlineIcon.svg' className='AlertOutlineIcon mr-2' />
        )}
        <span className='font-weight-500'>{correspondent.user?.preferredFullName}</span>
      </div>
    )
  }

  if (!_.isEmpty(correspondent?.group)) {
    return (
      <div className='d-flex align-items-center'>
        <span className='font-weight-500'>{correspondent.group.name}</span>
      </div>
    )
  }

  return (
    <div className='d-flex align-items-center'>
      <CdnSvg className='CorrespondentIcon mr-2' src={CorrespondentIcon} />
      <span className='font-weight-500'>{correspondent?.roleText}</span>
    </div>
  )
}

const StepTemplatesTable = ({ journeyBlueprint }) => {
  // We filter only the valid step templates, to avoid errors when the step template is deleted and still in the journey blueprint relationships
  // object in the redux store. This is due to a problem with the entity slice add action, which doesn't update the relationships
  // for the journe blueprint object.
  const stepTemplates = _.uniqBy(journeyBlueprint?.stepTemplates?.filter(stepTemplate => stepTemplate?.templateId), 'id') || []

  if (_.isEmpty(stepTemplates)) {
    return null
  }

  const columns = [
    {
      header: I18N('steps.list.name'),
      accessor: (d: StepTemplateType) => <StepTemplateLinkWithStatusLabel stepTemplate={d} />,
    },
    {
      header: I18N('steps.list.type'),
      accessor: (d: StepTemplateType) => d?.typeText,
    },
    {
      header: I18N('steps.list.from'),
      accessor: (d: StepTemplateType) => <CorrespondentLink correspondent={d.fromCorrespondent} />,
    },
    {
      header: I18N('steps.list.to'),
      accessor: (d: StepTemplateType) => <CorrespondentLink correspondent={d.toCorrespondent} />,
    },
    {
      header: I18N('steps.list.trigger'),
      accessor: (d: StepTemplateType) => stepTemplateTriggerText(d, stepTemplates),
    },
    {
      accessor: (d: StepTemplateType) => <StepTemplateActionsDropdown stepTemplate={d} onlyTableActions />,
    },
  ]

  return (
    <div className='StepTemplatesTable'>
      <h6 className='text-secondary align-self-start'>{I18N('steps.title')}</h6>
      <SmartTable
        className='white-bg-table'
        data={stepTemplates}
        showPagination={false}
        columns={columns}
      />
    </div>
  )
}

export default StepTemplatesTable
