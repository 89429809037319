import React, { useState } from 'react'
import { Popover } from 'reactstrap'

import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import useUpdatableState from 'components/common/hooks/useUpdatableState'

const defaultWorkingCopy = checkboxOptions => ({
  comment: '',
  ...checkboxOptions.reduce((acc, option) => ({ ...acc, [option]: false }), {}),
})

interface FeedbackCheckboxProps {
  id: string
  label: string
  checked: boolean
  onChange: (e: any) => void
}

const FeedbackCheckbox = ({
  id,
  label,
  checked,
  onChange,
}: FeedbackCheckboxProps) => (
  <label htmlFor={id} className='form-check'>
    <input id={id} type='checkbox' checked={checked} onChange={onChange} />
    {label}
  </label>
)

interface AdditionalInformationModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  target: string
  updateFeedback: (params: any) => void
  checkboxOptions?: string[]
  i18nPrefix?: string
}

const AdditionalInformationModal = ({
  isOpen,
  setIsOpen,
  target,
  updateFeedback,
  checkboxOptions = ['incomplete', 'difficultToUnderstand', 'inaccurateOrIrrelevant', 'missingOrBrokenLinks'],
  i18nPrefix = 'pages',
}: AdditionalInformationModalProps) => {
  const I18N = i18nPath(`views.additional_information_modal.${i18nPrefix}`)

  const [workingCopy, _setWorkingCopy, updateWorkingCopy] = useUpdatableState(defaultWorkingCopy(checkboxOptions))

  const onSubmit = (e) => {
    e.preventDefault()
    setIsOpen(false)
    updateFeedback(workingCopy)
  }

  return (
    <Popover
      className='AdditionalInformationModal p-4'
      isOpen={isOpen}
      target={target}
      placement='bottom-start'
      toggle={() => setIsOpen(!isOpen)}
    >
      <form onSubmit={onSubmit} className='FeedbackForm d-flex flex-column align-items-start w-100'>
        <h6>{I18N('title')}</h6>
        <div className='FeedbackCheckboxes'>
          {checkboxOptions.map(option => (
            <FeedbackCheckbox
              key={option}
              id={option}
              label={I18N(_.snakeCase(option))}
              checked={workingCopy[option]}
              onChange={e => updateWorkingCopy({ [option]: e.target.checked })}
            />
          ))}
        </div>

        <label className='w-100'>
          {I18N('comment')}
          <textarea
            className='Comments w-100 mt-1'
            value={workingCopy.comment || ''}
            placeholder={I18N('comment_placeholder')}
            onChange={e => updateWorkingCopy({ comment: e.target.value })}
          />
        </label>

        <div className='d-flex flex-row justify-content-end w-100 align-items-center'>
          <CancelButton onClick={() => setIsOpen(false)} />
          <ButtonNarrow className='ml-4' type='submit'>{I18N('send')}</ButtonNarrow>
        </div>
      </form>
    </Popover>
  )
}

export default AdditionalInformationModal
