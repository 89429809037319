
import React from 'react'

import CloseIcon from 'components/icons/closeIcon'

const BannerTextColor = () => (
  <div className='Banner-container d-flex align-items-center position-relative'>
    <div className='container'>
      <div className='row'>
        <div className='col d-flex justify-content-center'>
          <a href='https://www.google.com' target='_blank' rel='noopener noreferrer'>
            <div className='Banner-text'>Paragraphs Lorem ipsum dolor sit adipiscing elit. </div>
          </a>
        </div>
      </div>
    </div>
    <div className='Banner-close position-absolute d-flex'>
      <CloseIcon />
    </div>
  </div>
)

export default BannerTextColor
