import React from 'react'

const HelpIcon = ({ style }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='23' height='28' viewBox='0 0 23 28' fill='none' style={style}>
    <g clipPath='url(#clip0)'>
      <path
        d='M11.5 1.792C13.4106 1.792 15.2782 2.34377 16.8668 3.37753C18.4554 4.4113 19.6935 5.88063 20.4247 7.59971C21.1558 9.3188 21.3471 11.2104 20.9744 13.0354C20.6017 14.8604 19.6816 16.5367 18.3307 17.8525C16.9797 19.1682 15.2584 20.0642 13.3846 20.4272C11.5107 20.7902 9.56842 20.6039 7.80328 19.8919C6.03815 19.1798 4.52946 17.9739 3.46801 16.4268C2.40655 14.8797 1.84001 13.0607 1.84001 11.2C1.84001 8.70484 2.85775 6.31188 4.66935 4.54754C6.48096 2.7832 8.93801 1.792 11.5 1.792ZM11.5 0C9.22552 0 7.00211 0.656868 5.11095 1.88754C3.21978 3.11821 1.7458 4.86741 0.875391 6.91394C0.00498275 8.96048 -0.222756 11.2124 0.220974 13.385C0.664705 15.5576 1.75997 17.5532 3.36828 19.1196C4.97658 20.6859 7.02569 21.7526 9.25647 22.1848C11.4872 22.6169 13.7995 22.3951 15.9009 21.5474C18.0022 20.6997 19.7983 19.2642 21.0619 17.4224C22.3255 15.5806 23 13.4151 23 11.2C23 8.22957 21.7884 5.38081 19.6317 3.2804C17.4751 1.18 14.55 0 11.5 0V0Z'
        fill='#C4C4C4'
      />
      <path
        d='M11.6059 5.26401L11.8543 5.28641C12.4694 5.3242 13.0713 5.47627 13.6276 5.73441C14.1246 5.95722 14.5482 6.31006 14.8512 6.75361C15.0821 7.10297 15.2247 7.50058 15.2675 7.91393C15.3156 8.27138 15.2858 8.63468 15.1801 8.98017C15.0489 9.39241 14.8204 9.76904 14.5131 10.08C14.1616 10.4227 13.7764 10.7311 13.3631 11.0007C13.1331 11.1552 12.9284 11.3165 12.7122 11.4778C12.5166 11.6352 12.3694 11.8422 12.2867 12.0759C12.2407 12.1968 12.1878 12.3178 12.1395 12.4387C12.0802 12.5996 11.9717 12.7389 11.8286 12.8379C11.6855 12.9368 11.5146 12.9906 11.3391 12.992C11.1404 13.0132 10.9395 12.9823 10.7572 12.9024C10.6192 12.8367 10.4996 12.7395 10.4087 12.6189C10.3178 12.4984 10.2582 12.3581 10.2351 12.2103C10.1988 12.0044 10.2045 11.7936 10.2519 11.5899C10.2992 11.3862 10.3873 11.1936 10.5111 11.0231C10.7102 10.7397 10.9539 10.4887 11.2333 10.2794C11.5346 10.0397 11.8451 9.81121 12.1533 9.57153C12.4091 9.38088 12.6233 9.14254 12.7835 8.87041C12.908 8.63953 12.9591 8.37782 12.9304 8.11856C12.9018 7.85931 12.7946 7.6142 12.6225 7.41441C12.4401 7.22076 12.1979 7.09023 11.9325 7.04257C11.5792 6.96225 11.211 6.9684 10.8607 7.06049C10.6811 7.10828 10.514 7.19297 10.3708 7.30889C10.2276 7.4248 10.1115 7.56924 10.0304 7.73249C9.92233 7.92961 9.82803 8.13121 9.72683 8.33057C9.6526 8.48133 9.55223 8.61849 9.43013 8.73601C9.32428 8.82657 9.20045 8.89497 9.06632 8.93697C8.93219 8.97897 8.79064 8.99368 8.65043 8.98017C8.47806 8.9758 8.30928 8.93125 8.15823 8.85025C8.01915 8.78018 7.90261 8.6741 7.82145 8.54368C7.74029 8.41326 7.69765 8.26356 7.69823 8.11105C7.68646 7.61636 7.84878 7.13263 8.15823 6.74017C8.51599 6.26918 8.99088 5.89456 9.53823 5.65153C9.95109 5.4607 10.3931 5.33662 10.8469 5.28417L11.1045 5.25953L11.6059 5.26401Z'
        fill='#C4C4C4'
      />
      <path
        d='M11.1481 17.136C11.0791 17.1226 11.0101 17.1114 10.9434 17.0934C10.6398 17.016 10.3761 16.8322 10.203 16.5772C10.03 16.3222 9.95963 16.0139 10.0055 15.7115C10.0514 15.4091 10.2103 15.1338 10.4517 14.9384C10.6931 14.7431 11.0001 14.6414 11.3138 14.6528C11.6274 14.6643 11.9257 14.7881 12.1514 15.0005C12.3772 15.2129 12.5144 15.499 12.5369 15.8039C12.5594 16.1088 12.4656 16.4111 12.2734 16.6528C12.0812 16.8945 11.8042 17.0586 11.4954 17.1136L11.3827 17.136H11.1481Z'
        fill='#C4C4C4'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='23' height='28' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default HelpIcon
