import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { buildStepPayload } from 'redux/slices/journeys/steps'
import { defaultStepAttributes } from 'utils/journeys/templatesDefaultHelpers'


const I18N = i18nPath('views.admin.journeys.communications')

export const COMMUNICATION_ATTRIBUTES = [
  'content',
  'contentId',
  'subject',
  'sendToEmail',
  'createFeedCard',
  'replyToSetting',
  'sendToPersonalEmail',
  'sendSms',
  'feedTitle',
  'feedContent',
  'smsContent',
]
export const COMMUNICATION_INTERPOLATED_FIELDS = ['subject', 'content', 'feedTitle', 'feedContent', 'smsContent']

const buildCommunicationPayload = (communication) => {
  const newCommunication = _.pick(communication, [...COMMUNICATION_ATTRIBUTES, 'id'])

  if (communication.stepAttributes) {
    newCommunication.stepAttributes = buildStepPayload(communication.stepAttributes)
  }

  return newCommunication
}

export const defaultWorkingCopy = () => ({
  ...defaultStepAttributes,
  subject: '',
  content: '',
  feedTitle: '',
  feedContent: '',
  sendToEmail: true,
  createFeedCard: true,
  sendToPersonalEmail: false,
  sendSms: false,
  smsContent: '',
})

export const initialState = {
  communicationIds: [],
  meta: {
    ...defaultMeta,
    isSendingPreviewEmail: false,
    isSendingPreviewSms: false,
    queryParams: {
      ...defaultPaginationParams, perPage: 10, sortBy: 'trigger_at', sortDir: 'asc',
    },
  },
}

const slice = createSlice({
  name: 'communications',
  initialState,
  reducers: {
    ...defaultActions,

    isSendingPreviewEmail(state, action) {
      state.meta.isSendingPreviewEmail = action.payload
    },

    isSendingPreviewSms(state, action) {
      state.meta.isSendingPreviewSms = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    setCommunicationIds(state, action) {
      state.communicationIds = action.payload
    },

    clearCommunicationIds(state) {
      state.communicationIds = []
    },
  },
})

const asyncActions = {
  admin: {
    fetchCommunication: id => async (dispatch) => {
      dispatch(communicationSlice.actions.isLoading(true))
      dispatch(communicationSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.communications.fetch(id)
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(communicationSlice.actions.isNotFound(true))
      } finally {
        dispatch(communicationSlice.actions.isLoading(false))
      }
    },

    updateCommunication: (workingCopy, onSuccess = () => {}) => async (dispatch) => {
      dispatch(communicationSlice.actions.isSaving(true))

      try {
        const response = await API.admin.communications.update(buildCommunicationPayload(workingCopy))
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(communicationSlice.actions.setError(error))
      } finally {
        dispatch(communicationSlice.actions.isSaving(false))
      }
    },

    deleteCommunication: (id, onSuccess = () => {}) => async (dispatch, getState) => {
      try {
        await API.admin.communications.destroy({ id })
        const communication = build(getState().entities, 'communication', id)
        const stepId = communication?.step?.id
        dispatch(entitySlice.actions.remove({ type: 'communication', id }))
        dispatch(entitySlice.actions.remove({ type: 'step', id: stepId }))
        dispatch(showToastMessage({ message: I18N('successfully_deleted'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(communicationSlice.actions.setError(error))
      }
    },

    sendPreviewEmail: (id, userIds, onSuccess = () => {}) => async (dispatch) => {
      dispatch(communicationSlice.actions.isSendingPreviewEmail(true))

      try {
        await API.admin.communications.sendPreviewEmail(id, userIds)
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(communicationSlice.actions.setError(error))
      } finally {
        dispatch(communicationSlice.actions.isSendingPreviewEmail(false))
      }
    },

    sendPreviewSms: (id, userIds, onSuccess = () => {}) => async (dispatch) => {
      dispatch(communicationSlice.actions.isSendingPreviewSms(true))

      try {
        await API.admin.communications.sendPreviewSms(id, userIds)
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(communicationSlice.actions.setError(error))
      } finally {
        dispatch(communicationSlice.actions.isSendingPreviewSms(false))
      }
    },
  },
}

const selectors = {
  getMetaData: () => state => state.communications.meta,

  getCommunication: id => state => build(state.entities, 'communication', id),

  getCommunications: () => state => state.communications.communicationIds.map(id => build(state.entities, 'communication', id)) || [],
}

const communicationSlice = {
  ...slice,
  asyncActions,
  selectors,
}

export default communicationSlice
