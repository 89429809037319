import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import navbarSlice from 'redux/slices/navbarSlice'

const useHideNavbar = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(navbarSlice.actions.hideNavbar())

    return () => {
      dispatch(navbarSlice.actions.showNavbar())
    }
  }, [])
}

export default useHideNavbar
