import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import { Button } from 'components/common/buttons'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import AddWorkflowModal from 'pages/admin/ai/workflows/workflowListPage/addWorkflowModal'

const columns = [
  {
    header: 'Name',
    id: 'name',
    accessor: d => d.name,
  },
  {
    header: 'Created at',
    id: 'created_at',
    accessor: d => d.created_at,
  },
  {
    header: 'Actions',
    id: 'actions',
    accessor: d => (
      <div className='d-flex'>
        <Link to={`/admin/ai/workflows/${d.id}`}>
          Edit
        </Link>
      </div>
    ),
  },
]

const WorkflowListPage = () => {
  const [isCreating, setIsCreating] = useState(false)

  const {
    data,
    isLoading,
    isLoaded,
    callApi: fetchWorkflows,
    paginationData: { page, totalPages },
  } = useFetch(API.admin.ai.workflows.fetchAll)

  return (
    <div className='WorkflowListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>Workflows</h3>
        <div className='AdminButtonContainer'>
          <Button onClick={() => setIsCreating(true)}>
            Create new workflow
          </Button>
        </div>
      </header>

      <main className='AdminContent'>
        <TableLoadingContainer isLoading={isLoading || !isLoaded}>
          <SmartTable
            data={data}
            showPagination={page !== null && totalPages !== null}
            columns={columns}
            page={page}
            pages={totalPages}
            onPaginationClick={fetchWorkflows}
            className='white-bg-table'
          />
        </TableLoadingContainer>

        {isCreating && <AddWorkflowModal onCancel={() => setIsCreating(false)} />}
      </main>
    </div>
  )
}

export default WorkflowListPage
