import React from 'react'
import Card from 'components/common/card'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import Checkbox from 'components/common/buttons/checkBox'
import NotificationChannelsSelector from 'components/admin/articles/audience_and_settings_editor/notificationChannelsSelector'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ReactSelect from 'components/common/react_select'
import Tag from 'components/common/tag'
import { present } from 'components/common/utils'
import CdnSvg from 'components/common/cdnSvg'
import useIsGroupPost from 'components/admin/articles/hooks/useIsGroupPost'
import EstimatedAudiencePreferredChannelAvatars from 'components/common/audience/estimatedAudiencePreferredChannelAvatars'
import { getArticleAllMentionedUserIds } from 'components/admin/articles/hooks/usePreviewableArticle'

const I18N = i18nPath('views.admin.article_editor.audience_and_settings_editor')

const USER_PREFERENCE = 'user_preference'
const CUSTOM = 'custom'
const DONT_NOTIFY = 'dont_notify'
const EMAIL_AUDIENCE = 'email_audience'

const NEWS_MODULE = 'news'

const CHANNEL_OVERRIDES_ICON_PATHS = {
  email: '/images/envelopeOutlineIcon.svg',
  slack: '/images/slackLogoIcon.svg',
  ms_teams: '/images/microsoftTeamsIcon.svg',
  push_notification: '/images/mobilePhoneIcon.svg',
}


const NotificationsCard = ({ workingCopy, updateWorkingCopy }) => {
  const { slackEnabled, msTeamEnabled } = useCurrentCompany()
  const isGroupPost = useIsGroupPost(workingCopy)

  const { notificationChannelOverrides, tags, notificationPreference } = workingCopy

  const availableChannels = ['email', slackEnabled && 'slack', msTeamEnabled && 'ms_teams', 'push_notification'].filter(present)
  const showChannelOverrides = notificationPreference === CUSTOM

  const notificationPreferenceOptions = [
    {
      label: I18N('email_audience'),
      value: EMAIL_AUDIENCE,
    },
    {
      label: I18N('based_on_user_preferences'),
      value: USER_PREFERENCE,
    },
    {
      label: I18N('custom'),
      value: CUSTOM,
    },
    {
      label: I18N('dont_notify'),
      value: DONT_NOTIFY,
    },
  ]


  const onNotificationPreferenceChange = ({ value: notificationPreference }) => {
    let notificationChannelOverrides: any = []

    if (notificationPreference === CUSTOM) {
      notificationChannelOverrides = ['email', 'push_notification']
    } else if (notificationPreference === EMAIL_AUDIENCE) {
      notificationChannelOverrides = ['email']
    }

    updateWorkingCopy({ notificationPreference, notificationChannelOverrides })
  }

  const hasMentionedUsers = getArticleAllMentionedUserIds(workingCopy).length > 0

  const handleChannelOverrideToggle = (key, value) => {
    const newOverrides = notificationChannelOverrides.filter(o => o !== key)
    if (value) {
      newOverrides.push(key)
    }

    updateWorkingCopy({ notificationChannelOverrides: newOverrides })
  }

  const isCustomWithNoChannels = notificationPreference === CUSTOM && notificationChannelOverrides.length === 0
  const isCustomWithChannels = notificationPreference === CUSTOM && notificationChannelOverrides.length > 0

  const showEstimatedAvatars = [USER_PREFERENCE, EMAIL_AUDIENCE].includes(notificationPreference)
    || isCustomWithChannels

  if (isGroupPost && !slackEnabled && !msTeamEnabled) {
    return null
  }

  return (
    <Card className='NotificationsCard mb-4'>
      <h5>{I18N('notifications')}</h5>
      {!isGroupPost && (
        <>
          <ReactSelect
            value={notificationPreferenceOptions.find(o => o.value === notificationPreference)}
            options={notificationPreferenceOptions}
            onChange={onNotificationPreferenceChange}
          />

          {showChannelOverrides && (
            <>
              <p className='mt-3 mb-0 font-weight-400'>{I18N('select_channels_helper_text')}</p>
              <div className='d-flex'>
                {availableChannels.map(channel => (
                  <Checkbox
                    id={`notification_channel_overrides-${channel}`}
                    key={`notification_channel_overrides-${channel}`}
                    checked={notificationChannelOverrides.includes(channel)}
                    onChange={value => handleChannelOverrideToggle(channel, value)}
                    label={(
                      <div className='d-flex align-items-center mr-3'>
                        <CdnSvg src={CHANNEL_OVERRIDES_ICON_PATHS[channel]} className='ChannelOverrideIcon mr-2' />
                        {I18N(channel)}
                      </div>
                    )}
                  />
                ))}
              </div>
            </>
          )}

          {notificationPreference === USER_PREFERENCE && (
            <p className='mt-2 text-muted text-small'>{i18nFormat(I18N('user_preference_helper_text'), <SelectedTags tags={tags} />)}</p>
          )}

          {showEstimatedAvatars && (
            <div className='EstimatedAudienceAvatars p-3 d-flex justify-content-start align-items-center mt-2'>
              <EstimatedAudiencePreferredChannelAvatars
                targetingRules={workingCopy.targetingRules}
                translationsPath={`views.admin.article_editor.audience_and_settings_editor.audience_notifications_on_${notificationPreference}`}
                channelOverrides={notificationChannelOverrides}
                includePreferredChannels={[USER_PREFERENCE, EMAIL_AUDIENCE].includes(notificationPreference)}
                articleTagIds={notificationPreference === USER_PREFERENCE ? workingCopy.tags.map(tag => tag.id) : null}
                hasTooltip={notificationPreference === EMAIL_AUDIENCE}
                moduleName={NEWS_MODULE}
              />
            </div>
          )}

          {isCustomWithNoChannels && (
            <p className='mt-2 text-muted text-small'>{I18N(hasMentionedUsers ? 'only_mentioned_users_will_receive_notification' : 'no_one_will_receive_notification')}</p>
          )}

          <Checkbox
            id='always_show_in_feed-checkbox'
            className='mt-3'
            checked={workingCopy.alwaysShowInFeed}
            onChange={value => updateWorkingCopy({ alwaysShowInFeed: value })}
            label={i18nFormat(I18N('always_show_in_feed'), <SelectedTags tags={tags} />)}
          />
        </>
      )}

      <NotificationChannelsSelector
        channels={workingCopy.notificationChannels || []}
        onChange={notificationChannels => updateWorkingCopy({ notificationChannels })}
      />
    </Card>
  )
}

const SelectedTags = ({ tags }) => {
  if (_.isEmpty(tags)) {
    return I18N('selected_tags')
  }

  return tags.map(tag => (
    <Tag key={tag.id} text={tag.name} />
  ))
}

export default NotificationsCard
