import React from 'react'
import ClearyCard from 'components/common/card'
import CelebrationCardHeader from 'components/celebrations/celebrationCardHeader'
import celebrationEmojis from 'components/celebrations/utils/emojis'
import CelebrationCTA, { useShowCelebrationCTA } from 'pages/people/celebrations_list_page/celebrationCTA'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import moment from 'moment'
import CelebrationSigners from 'pages/people/celebrations_list_page/celebrationSigners'
import InviteOthersButton from 'components/celebrations/greeting_card_actions/inviteOthersButton'
import useShowInviteOthersButton from 'components/celebrations/hooks/useShowInviteOthersButton'
import CelebrationModalContext, { useCelebrationModalValue } from 'components/celebrations/greeting_card_actions/celebrationModalContext'
import { Link } from 'react-router-dom'

const I18N = i18nPath('views.celebrations.celebration_card')


const CelebrationCard = ({ celebration }) => {
  const showCelebrationCTA = useShowCelebrationCTA(celebration)
  const showInviteOthersButton = useShowInviteOthersButton(celebration)
  const celebrationModalValue = useCelebrationModalValue()


  return (
    <ClearyCard cardBodyClassName='p-0' className='CelebrationCard'>
      <div className='position-relative'>
        <CelebrationCardHeader
          isCurrentUser={false}
          celebration={celebration}
        />

        <div className='CelebratedPersonThumb'>
          <div className='img-padding'>
            <img
              src={celebration.user.primaryPhotoUrl}
              alt={celebration.user.preferredFullName}
            />
          </div>

          {celebrationEmojis[celebration.type] && (
            <span className='EmojiWrapper d-flex justify-content-center align-items-center position-absolute'>
              {celebrationEmojis[celebration.type]}
            </span>
          )}
        </div>
      </div>

      <div className='p-3'>
        <h2 className='title'>
          <Link to={`/people/celebrations/${celebration.id}`}>
            {I18N('title', {
              celebration_type_capitalized: celebration.celebrationTypeCapitalized,
              person_first_name: celebration.user.preferredName,
            })}
          </Link>
        </h2>

        <p className='sign-by-label'>
          <span className='text-dark-muted'>
            {I18NCommon(celebration.currentUserSignatureDate ? 'signed_on' : 'sign_by')}
          </span>
          <br />
          <span>
            {moment(celebration.currentUserSignatureDate || celebration.eventDate).format('LL')}
          </span>
        </p>

        <CelebrationSigners celebration={celebration} />

        <div className='d-flex gap-3'>
          {showCelebrationCTA && (
            <div className='w-50'>
              <CelebrationCTA celebration={celebration} />
            </div>
          )}

          {showInviteOthersButton && (
            <div className='w-50'>
              <CelebrationModalContext.Provider value={celebrationModalValue}>
                <InviteOthersButton
                  celebration={celebration}
                  buttonClassName='w-100'
                  buttonText={I18N('invite_to_sign')}
                  variant='secondary'
                  small
                />
              </CelebrationModalContext.Provider>
            </div>
          )}
        </div>
      </div>
    </ClearyCard>
  )
}

export default CelebrationCard
