import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/common/buttons/'
import PartyHatIcon from 'components/icons/partyHatIcon'
import AudienceDropdownSelector from 'components/common/audience_dropdown_selector'

import qnaEventSlice from 'redux/slices/qnaEvents'
import { trackAmplitudeEvent } from 'services/tracker'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import userSlice from 'redux/slices/users'
import { LoadingContainer } from 'components/common/loadingContainer'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.qna.events')

const EventLinkSharer = ({ onBack }) => (
  <>
    <div className='d-flex flex-column mb-4'>
      <span className='mb-2'>
        {I18N('share_event_link')}
      </span>
      <input
        className='w-100'
        readOnly
        value={window.location.href}
      />
    </div>
    <a className='text-secondary-link' onClick={onBack}>
      {I18N('back')}
    </a>
  </>
)

const AudienceInviter = ({
  accessLevel,
  onAudienceChange,
  onSkip,
  onSave,
  colleaguesCount,
  targetingRules,
}) => {
  const targetingOptions = useTargetingOptions()
  const [isInviteButtonDisabled, setIsInviteButtonDisabled] = useState(false)

  const showAudienceSelector = colleaguesCount > 0

  return (
    <>
      {showAudienceSelector && (
        <AudienceDropdownSelector
          targetingRules={targetingRules}
          colleaguesCount={colleaguesCount}
          updateWorkingCopy={onAudienceChange}
          targetingOptions={targetingOptions}
          setDisableButton={isButtonDisabled => setIsInviteButtonDisabled(isButtonDisabled)}
          accessLevel={accessLevel}
        />
      )}
      <div className='d-flex justify-content-end align-items-center mt-3'>
        <a className='text-secondary-link mr-2' onClick={onSkip}>
          {I18NCommon('skip')}
        </a>

        <Button dataTestId='cy_publish_event_save' onClick={onSave} disabled={isInviteButtonDisabled}>
          {I18NCommon('save')}
        </Button>
      </div>
    </>
  )
}

const PublishEventModal = ({
  showModal = false,
  onCloseModal,
  event,
}) => {
  const [showShareLink, setShowShareLink] = useState(false)
  const [targetingRules, setTargetingRules] = useState(event.targetingRules)
  const dispatch = useDispatch()

  const activeUsersCount = useSelector(userSlice.selectors.getActiveUsersCount())
  const { meta: { isLoadingActiveUsersCount } } = useSelector(userSlice.selectors.getMeta())
  const { isSaving: isSavingEvent } = useSelector(qnaEventSlice.selectors.getEventMetaData())

  // minus the current user
  const colleaguesCount = activeUsersCount - 1

  useEffect(() => {
    dispatch(userSlice.asyncActions.fetchActiveUsersCount())
  }, [])

  // if the event from props changes, update the local audience state
  useEffect(() => {
    setTargetingRules(event.targetingRules)
  }, [event.targetingRules])

  const onClose = () => {
    setShowShareLink(false)
    onCloseModal()
  }

  const handleAudienceChange = (values) => {
    setTargetingRules(values.targetingRules)
  }

  const onSave = () => {
    const updatedEvent = {
      ...event,
      targetingRules,
      settings: {
        ...event.settings,
        notifications: { notifyWhenOpenForQuestions: true },
      },
    }
    dispatch(qnaEventSlice.asyncActions.admin.update(updatedEvent, null, onClose))

    trackAmplitudeEvent('published_event_invite_clicked', {
      audience_count: updatedEvent.audienceCount,
      eventId: updatedEvent.id,
      title: updatedEvent.title,
      answerable_by: updatedEvent.questionsAnswerableBy,
      anonymous: updatedEvent.allowAnonymousQuestions,
    })
  }

  return (
    <Modal
      className='PublishEventModal'
      visible={showModal}
      toggle={onClose}
    >
      <header className='d-flex align-items-center flex-column pb-4 pt-1 px-2 text-center'>
        <PartyHatIcon className='mb-4' />
        <h2 className='mb-2'>{I18N('event_is_published')}</h2>
        <span>{I18N('notify_and_invite_colleagues')}</span>
      </header>
      <main className='p-4'>
        {
          showShareLink ? (
            <EventLinkSharer onBack={() => setShowShareLink(false)} />
          ) : (
            <LoadingContainer isLoading={isLoadingActiveUsersCount || isSavingEvent}>
              <AudienceInviter
                onSkip={() => setShowShareLink(true)}
                targetingRules={targetingRules}
                onAudienceChange={handleAudienceChange}
                onSave={onSave}
                accessLevel={event.accessLevel}
                colleaguesCount={colleaguesCount}
              />
            </LoadingContainer>
          )
        }
      </main>
    </Modal>
  )
}

export default PublishEventModal
