import ArticleOrTemplateEditor from 'components/admin/articles/articleOrTemplateEditor'
import TemplateEditorNavbar from 'components/admin/articles/templates/templateEditorNavbar'
import AdminBanner from 'components/common/adminBanner'
import useNewArticleTemplateWorkingCopy from 'components/common/hooks/useNewArticleTemplateWorkingCopy'
import useApi from 'components/common/hooks/useApi'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import { trackAmplitudeEvent } from 'services/tracker'

export const CONTENT = 'content'
export const SUMMARY = 'summary'
export const SETTINGS = 'settings'

const I18N = i18nPath('views.admin.article_editor')


const TemplateEditorPage = () => {
  const [workingCopy, setWorkingCopy, updateWorkingCopy] = useUpdatableState({})
  const { templateId } = useParams()
  const isNewTemplate = templateId === 'new'

  const [fetch, {
    data: fetchedTemplate, isLoading,
  }] = useApi(API.admin.article.templates.fetch)

  const [update, {
    isLoading: isUpdating,
    data: updatedTemplate,
  }] = useApi(API.admin.article.templates.update, {
    toastSuccessMessage: I18N('template_successfully_updated'),
    toastErrorMessage: I18N('error_updating_template'),
  })

  const template = updatedTemplate || fetchedTemplate
  const isLoaded = useIsLoaded(isLoading)
  const newWorkingCopy = useNewArticleTemplateWorkingCopy()

  useEffect(() => {
    if (isNewTemplate) {
      setWorkingCopy(newWorkingCopy)
      trackAmplitudeEvent('create_new_template_page_view')
    } else {
      fetch(templateId)
    }
  }, [templateId])

  useEffect(() => {
    if (template && isLoaded) {
      setWorkingCopy(template)
    }
  }, [template?.updatedAt, isLoading, isLoaded, isUpdating])

  return (
    <div className='ArticleTemplateEditorPage'>
      <TemplateEditorNavbar
        isLoading={isLoading}
        workingCopy={workingCopy}
        template={template}
        isNewTemplate={isNewTemplate}
        update={update}
        isUpdating={isUpdating}
      />
      <ArticleOrTemplateEditor
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isLoading={isLoading}
        banners={[
          <AdminBanner color='blue' key='blue'>
            <span className='text-small font-weight-600'>
              {isNewTemplate && I18N('creating_new_template')}

              {!isNewTemplate && isLoaded && (
                <>
                  {I18N('editing_template', { name: template?.title })}
                </>
              )}
            </span>
          </AdminBanner>,
        ]}
        isEditingTemplate
      />
    </div>
  )
}

export default TemplateEditorPage
