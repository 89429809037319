import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies')

export const initialState = {
  onboardingBuddyId: null,
  newHireBuddyIds: [],
  onboardingBuddyIds: [],
  suggestedOnboardingBuddyIds: [],
  excludedUserIds: [],
  newHireId: null,
  meta: {
    ...defaultMeta,
    isLoadingOptions: true,
    isLoadingExcludedUsers: true,
    isLoadingSuggestedOnboardingBuddies: true,
    isLoadingStatus: true,
    isSendingOptInEmails: false,
    isSavingExcludedUsers: false,
    totalPossibleBuddies: null,
    queryParams: {
      ...defaultPaginationParams,
      perPage: 10,
    },
    status: null,
  },
}

const onboardingBuddySlice = createSlice({
  name: 'onboardingBuddies',
  initialState,
  reducers: {
    ...defaultActions,

    setOnboardingBuddyId: (state, action) => {
      state.onboardingBuddyId = action.payload
    },

    setNewHireBuddyIds: (state, action) => {
      state.newHireBuddyIds = action.payload
    },

    setStatus: (state, action) => {
      state.meta.status = action.payload
    },

    isLoadingOptions: (state, action) => {
      state.meta.isLoadingOptions = action.payload
    },

    isLoadingExcludedUsers: (state, action) => {
      state.meta.isLoadingExcludedUsers = action.payload
    },

    isLoadingSuggestedOnboardingBuddies: (state, action) => {
      state.meta.isLoadingSuggestedOnboardingBuddies = action.payload
    },

    isLoadingStatus: (state, action) => {
      state.meta.isLoadingStatus = action.payload
    },

    isSendingOptInEmails: (state, action) => {
      state.meta.isSendingOptInEmails = action.payload
    },

    isSavingExcludedUsers: (state, action) => {
      state.meta.isSavingExcludedUsers = action.payload
    },

    setOnboardingBuddyIds(state, action) {
      state.onboardingBuddyIds = action.payload
    },

    removeOnboardingBuddyIds(state, action) {
      const index = state.onboardingBuddyIds.indexOf(action.payload)
      state.onboardingBuddyIds.splice(index, 1)
    },

    setSuggestedOnboardingBuddyIds(state, action) {
      state.suggestedOnboardingBuddyIds = action.payload
    },

    setExcludedUserIds(state, action) {
      state.excludedUserIds = action.payload
    },

    addExcludedUserIds(state, action) {
      state.excludedUserIds = [...state.excludedUserIds, ...action.payload]
    },

    removeExcludedUserIds(state, action) {
      state.excludedUserIds = state.excludedUserIds.filter(id => !action.payload.includes(id))
    },

    setNewHireId(state, action) {
      state.newHireId = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    setTotalPossibleBuddies(state, action) {
      state.meta.totalPossibleBuddies = action.payload
    },
  },
})

const asyncActions = {
  assignedBuddy: () => async (dispatch) => {
    dispatch(onboardingBuddySlice.actions.isLoadingStatus(true))

    try {
      const response = await API.journey.onboardingBuddies.assignedBuddy()

      const {
        onboardingBuddy, newHireBuddies, status,
      } = response.data

      dispatch(entitySlice.actions.add({
        data: {
          data: [
            onboardingBuddy?.data,
            ...newHireBuddies?.data?.map(buddy => buddy),
          ].filter(Boolean),
        },
      }))

      // Forcing a null if no id to rewrite the value, undefined doesn't rewrite
      dispatch(onboardingBuddySlice.actions.setOnboardingBuddyId(onboardingBuddy?.data?.id || null))
      dispatch(onboardingBuddySlice.actions.setNewHireBuddyIds(newHireBuddies.data.map(nhb => nhb.id)))
      dispatch(onboardingBuddySlice.actions.setStatus(status))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(onboardingBuddySlice.actions.isLoadingStatus(false))
    }
  },

  optIn: () => async (dispatch) => {
    dispatch(onboardingBuddySlice.actions.isLoading(true))

    try {
      await API.journey.onboardingBuddies.optIn()
    } catch (e) {
      dispatch(onboardingBuddySlice.actions.setError(true))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(onboardingBuddySlice.actions.isLoading(false))
    }
  },

  optOut: () => async (dispatch) => {
    dispatch(onboardingBuddySlice.actions.isLoading(true))

    try {
      await API.journey.onboardingBuddies.optOut()
    } catch (e) {
      dispatch(onboardingBuddySlice.actions.setError(true))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(onboardingBuddySlice.actions.isLoading(false))
    }
  },

  doNotWantOnboardingBuddy: () => async (dispatch) => {
    dispatch(onboardingBuddySlice.actions.isLoading(true))

    try {
      await API.journey.onboardingBuddies.doNotWantOnboardingBuddy()
    } catch (e) {
      dispatch(onboardingBuddySlice.actions.setError(true))
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(onboardingBuddySlice.actions.isLoading(false))
    }
  },

  fetchSuggested: (params = {}) => async (dispatch) => {
    dispatch(onboardingBuddySlice.actions.isLoadingSuggestedOnboardingBuddies(true))

    try {
      const response = await API.journey.onboardingBuddies.fetchAll(params)

      const onboardingBuddyIds = response.data.data.map(user => user.id)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(onboardingBuddySlice.actions.setSuggestedOnboardingBuddyIds(onboardingBuddyIds))

      // only setting the total because other query params should be obtained from fetchAll
      const { total } = queryParamsFromHeaders(response)
      dispatch(onboardingBuddySlice.actions.setTotalPossibleBuddies(total))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(onboardingBuddySlice.actions.isLoadingSuggestedOnboardingBuddies(false))
    }
  },

  fetchAll: (params = {}) => async (dispatch) => {
    dispatch(onboardingBuddySlice.actions.isLoading(true))

    try {
      const response = await API.journey.onboardingBuddies.fetchAll(params)

      const newQueryParams = queryParamsFromHeaders(response)
      const onboardingBuddyIds = response.data.data.map(user => user.id)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(onboardingBuddySlice.actions.setOnboardingBuddyIds(onboardingBuddyIds))
      dispatch(onboardingBuddySlice.actions.setQueryParams(newQueryParams))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(onboardingBuddySlice.actions.isLoading(false))
    }
  },

  create: (onboardingBuddyId, onSuccess = () => {}) => async (dispatch) => {
    dispatch(onboardingBuddySlice.actions.isSaving(true))

    try {
      const response = await API.journey.onboardingBuddies.create(onboardingBuddyId)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(onboardingBuddySlice.actions.setOnboardingBuddyId(onboardingBuddyId))
      dispatch(showToastMessage({ message: I18N('onboarding_buddy_selected'), type: 'success' }))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(onboardingBuddySlice.actions.isSaving(false))
    }
  },

  admin: {
    fetchAll: (params = {}) => async (dispatch, getState) => {
      dispatch(onboardingBuddySlice.actions.isLoading(true))
      dispatch(onboardingBuddySlice.actions.setNewHireId(params.newHireId))

      try {
        const response = await API.admin.onboardingBuddies.fetchAll(params)

        // If the admin is scrolling through the new hires we might be setting the wrong buddies due to a
        // slow request. By checking this, we make sure we are setting the correct buddies.
        if (getState().onboardingBuddies.newHireId === params.newHireId) {
          const newQueryParams = queryParamsFromHeaders(response)
          const onboardingBuddyIds = response.data.data.map(user => user.id)
          dispatch(entitySlice.actions.update({ data: response.data }))
          dispatch(onboardingBuddySlice.actions.setOnboardingBuddyIds(onboardingBuddyIds))
          dispatch(onboardingBuddySlice.actions.setQueryParams(newQueryParams))
          dispatch(onboardingBuddySlice.actions.isLoading(false))
        }
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(onboardingBuddySlice.actions.isLoading(false))
      }
    },

    fetchExcludedUsers: () => async (dispatch) => {
      dispatch(onboardingBuddySlice.actions.isLoadingExcludedUsers(true))

      try {
        const response = await API.admin.onboardingBuddies.fetchAll({ status: 'excluded' })

        const excludedUserIds = response.data.data.map(user => user.id)
        dispatch(entitySlice.actions.add({ data: response.data }))

        dispatch(onboardingBuddySlice.actions.setExcludedUserIds(excludedUserIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(onboardingBuddySlice.actions.isLoadingExcludedUsers(false))
      }
    },

    assign: (params, onSuccess = () => {}, onFinally = () => {}) => async (dispatch) => {
      try {
        await API.admin.onboardingBuddies.create(params)
        dispatch(showToastMessage({ message: I18N('buddy_assigned'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: I18N('error_assigning_buddy'), type: 'error' }))
      } finally {
        onFinally()
      }
    },

    reassign: (params, onSuccess = () => {}, onFinally = () => {}) => async (dispatch) => {
      try {
        await API.admin.onboardingBuddies.reassign(params)
        dispatch(showToastMessage({ message: I18N('buddy_reassigned'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: I18N('error_reassigning_buddy'), type: 'error' }))
      } finally {
        onFinally()
      }
    },

    unassign: (onboardingBuddyId, onSuccess = () => {}, onFinally = () => {}) => async (dispatch) => {
      try {
        await API.admin.onboardingBuddies.unassign(onboardingBuddyId)
        dispatch(showToastMessage({ message: I18N('buddy_unassigned'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: I18N('error_unassigning_buddy'), type: 'error' }))
      } finally {
        onFinally()
      }
    },

    askToOptIn: (params, onSuccess = () => {}, onFinally = () => {}) => async (dispatch) => {
      try {
        const response = await API.admin.onboardingBuddies.askToOptIn(params)
        dispatch(showToastMessage({ message: I18N('buddy_asked_to_opt_in'), type: 'success' }))
        dispatch(entitySlice.actions.update({ data: response.data }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: I18N('error_asking_buddy_to_opt_in'), type: 'error' }))
      } finally {
        onFinally()
      }
    },

    askAllToOptIn: (onSuccess = () => {}) => async (dispatch) => {
      dispatch(onboardingBuddySlice.actions.isSendingOptInEmails(true))

      try {
        await API.admin.onboardingBuddies.askAllToOptIn()
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: I18N('error_asking_users_to_opt_in'), type: 'error' }))
      } finally {
        dispatch(onboardingBuddySlice.actions.isSendingOptInEmails(false))
      }
    },

    excludeUsers: userIds => async (dispatch) => {
      dispatch(onboardingBuddySlice.actions.isSavingExcludedUsers(true))

      try {
        const response = await API.admin.onboardingBuddies.excludeUsers(userIds)
        dispatch(entitySlice.actions.add(response))
        dispatch(onboardingBuddySlice.actions.addExcludedUserIds(userIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: e.response?.data?.error?.message, type: 'error' }))
      } finally {
        dispatch(onboardingBuddySlice.actions.isSavingExcludedUsers(false))
      }
    },

    reincludeUsers: userIds => async (dispatch) => {
      dispatch(onboardingBuddySlice.actions.isSavingExcludedUsers(true))

      try {
        await API.admin.onboardingBuddies.reincludeUsers(userIds)
        dispatch(onboardingBuddySlice.actions.removeExcludedUserIds(userIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(showToastMessage({ message: e.response?.data?.error?.message, type: 'error' }))
      } finally {
        dispatch(onboardingBuddySlice.actions.isSavingExcludedUsers(false))
      }
    },
  },
}

const selectors = {
  getOnboardingBuddy: () => state => (state.onboardingBuddies.onboardingBuddyId ? build(state.entities, 'simpleUser', state.onboardingBuddies.onboardingBuddyId) : null),

  getNewHireBuddies: () => state => state.onboardingBuddies.newHireBuddyIds.map(id => build(state.entities, 'simpleUser', id)).filter(Boolean) || [],

  getOnboardingBuddies: () => state => state.onboardingBuddies.onboardingBuddyIds.map(id => build(state.entities, 'onboardingBuddy', id)).filter(Boolean) || [],

  getSuggestedOnboardingBuddies: () => state => state.onboardingBuddies.suggestedOnboardingBuddyIds.map(id => build(state.entities, 'onboardingBuddy', id)).filter(Boolean) || [],

  getExcludedUsers: () => state => state.onboardingBuddies.excludedUserIds.map(id => build(state.entities, 'simpleUser', id)).filter(Boolean) || [],

  getMetaData: () => state => state.onboardingBuddies.meta,
}


export default {
  ...onboardingBuddySlice,
  asyncActions,
  selectors,
}
