import CopyArticleModal from 'components/admin/articles/copyArticleModal'
import useApi from 'components/common/hooks/useApi'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.template_editor.copy_modal')

interface Props {
  closeModal: () => void
  template: any
}

const CopyTemplateDropdownModal = ({
  closeModal, template,
} : Props) => {
  const history = useHistory()

  const [copyTemplate, { isLoading }] = useApi(API.admin.article.templates.copy, {
    toastSuccessMessage: I18N('copy_template_success_message'),
    toastErrorMessage: I18N('copy_template_error_message'),
    onSuccess: (data) => {
      // allow any state to be executed since it'll unmount this component and we'll have a
      // warning in console of type "Can't perform a React state on an unmounted component"
      setTimeout(() => {
        history.push(`/admin/articles/templates/${data.id}`)
      })
    },
    onFinally: closeModal,
  })

  const copy = (title) => {
    copyTemplate(template.id, title)
  }

  return (
    <CopyArticleModal
      articleTitle={template.title}
      closeCopyArticleModal={closeModal}
      handleCopyArticle={copy}
      isCopying={isLoading}
      showCopyArticleModal
      I18N={I18N}
    />
  )
}

export default CopyTemplateDropdownModal
