import React from 'react'

import { i18nFormat, i18nMoment, i18nPath } from 'utils/i18nHelpers'

import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'

const I18N = i18nPath('views.qna.answers')


const AnswerByLine = (props) => {
  const { answer, className } = props
  const { createdAt, creator } = answer

  const answerCreatorPopover = <EmployeeLinkWithPopover user={creator} id={`answer-${answer.id}-creator-${creator?.id}`} />
  const answerCreatedAt = i18nMoment(createdAt).fromNow()


  return (
    <div className={className || 'text-secondary'}>
      {i18nFormat(I18N('answer_byline'), answerCreatorPopover, answerCreatedAt)}
    </div>
  )
}

export default AnswerByLine
