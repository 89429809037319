import React from 'react'

import useApi from 'components/common/hooks/useApi'
import { AsyncCreatable, AsyncSelect } from 'components/common/react_select'
import { I18NCommon } from 'utils/i18nHelpers'

interface Props<T> {
  value?: T
  onChange: (value: T) => void
  fetchAll: (...args: any) => any
  valueProp?: string
  labelProp?: string
  isMulti?: boolean
  isCreatable?: boolean
  [key: string]: any
}

function ApiAsyncSelector<T>({
  value,
  onChange,
  fetchAll,
  valueProp = 'id',
  labelProp = 'name',
  isMulti,
  isCreatable,
  ...props
}: Props<T>) {
  const [apiFetchAll] = useApi(fetchAll)

  let loadOptions = (query, callback) => {
    apiFetchAll({ query }).then(callback)
  }

  loadOptions = _.debounce(loadOptions, 300)

  const Component = isCreatable ? AsyncCreatable : AsyncSelect

  return (
    <Component
      aria-label='Type to search'
      value={value}
      getOptionValue={option => option[valueProp]}
      getOptionLabel={option => option[labelProp]}
      onChange={onChange}
      loadOptions={loadOptions}
      backspaceRemovesValue
      isClearable
      isMulti={isMulti}
      defaultOptions
      placeholder={I18NCommon('select_or_type_to_search')}
      getNewOptionData={(inputValue, optionLabel) => ({
        [labelProp]: optionLabel,
        [valueProp]: `new-${inputValue}`,
        __isNew__: true,
      })}
      {...props}
    />
  )
}

export default ApiAsyncSelector
