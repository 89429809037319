import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import useQueryParams from 'components/common/hooks/useQueryParams'
import { STEP_TEMPLATE_ATTRIBUTES, buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'
import { transformCcCorrespondentsForBackend } from 'utils/journeys/journeyVariablesHelpers'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'
import { I18NCommon } from 'utils/i18nHelpers'
import useTransformInterpolatedFields from 'components/admin/journeys/hooks/useTransformInterpolatedFields'


const useStepTemplateCreation = ({
  defaultWorkingCopy,
  entityTemplateAttributes,
  createApiPath,
  interpolatedFields = [],
}) => {
  const history = useHistory()
  const { name } = useQueryParams()
  const { journeyBlueprintId } = useParams()
  const { interpolateParamsForBackend } = useTransformInterpolatedFields()

  const onCreateSuccess = (entityTemplate) => {
    if (entityTemplate.stepTemplate.state === 'draft') {
      history.replace(entityTemplate.stepTemplate.adminDisplayPath)
    } else {
      history.push(`/admin/journeys/${journeyBlueprintId}/edit`)
    }
  }

  const [createEntityTemplate, { isLoading: isSaving }] = useApi(createApiPath, {
    onSuccess: onCreateSuccess,
    toastSuccessMessage: I18NCommon('created_successfully'),
  })

  const { data: journeyBlueprint } = useFetch(
    () => API.admin.journey.journeyBlueprints.fetch(journeyBlueprintId, { includeBlueprintVariables: true }),
    [journeyBlueprintId]
  )

  const journeyBlueprintType = journeyBlueprint?.type
  const questionVariables = journeyBlueprint?.questionVariables || []

  const [workingCopy, setWorkingCopy, updateWorkingCopy] = useUpdatableState({})

  const handleSave = (state) => {
    const params = {
      ..._.pick(workingCopy, entityTemplateAttributes),
      stepTemplateAttributes: buildStepTemplatePayload({
        state,
        name: workingCopy.stepTemplateName || name,
        journeyBlueprintId,
        ..._.pick(workingCopy, STEP_TEMPLATE_ATTRIBUTES),
        fromCorrespondentAttributes: {
          role: workingCopy.fromCorrespondentAttributes.role, userId: workingCopy.fromCorrespondentAttributes.user?.id,
        },
        toCorrespondentAttributes: {
          role: workingCopy.toCorrespondentAttributes.role,
          userId: workingCopy.toCorrespondentAttributes.user?.id,
          groupId: workingCopy.toCorrespondentAttributes.group?.id,
        },
        ccCorrespondentsAttributes: transformCcCorrespondentsForBackend(workingCopy?.ccCorrespondentsAttributes),
      }),
    }

    const interpolatedParams = interpolateParamsForBackend(interpolatedFields, params, questionVariables)

    createEntityTemplate(interpolatedParams)
  }

  useEffect(() => {
    if (journeyBlueprintType) {
      updateWorkingCopy(defaultWorkingCopy(journeyBlueprintType))
    }
  }, [journeyBlueprintType])


  return {
    name: workingCopy.stepTemplateName || name,
    workingCopy,
    updateWorkingCopy,
    handleSave,
    journeyBlueprint,
    isSaving,
  }
}

export default useStepTemplateCreation
