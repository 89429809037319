import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.errors.preboarding_unauthorized')
const womanWithLensPath = '/images/illustrations/womanWithLens.svg'

const PreboardingUnauthorized = () => {
  const { name } = useCurrentCompany()

  return (
    <div className='PreboardingUnauthorized container d-flex flex-column align-items-center justify-content-center my-3 mx-auto'>
      <CdnSvg src={womanWithLensPath} />
      <h4 className='text-center mt-4 mb-2'>{I18N('you_do_not_have_access')}</h4>
      <p className='text-center text-secondary mb-4'>{I18N('you_can_view_on_day_1', { companyName: name })}</p>
      <Link to='/'>
        <Button>{I18N('back_to_home')}</Button>
      </Link>
    </div>
  )
}

export default PreboardingUnauthorized
