import useFetch from 'components/common/hooks/useFetch'
import React from 'react'
import API from 'services/api'
import CarouselCards from 'components/home/widgets/carousel_cards/carouselCards'

const CarouselCardsWidget = ({ widget, EmptyState = null, isEditing }) => {
  const { data: carouselCards } = useFetch(API.carouselCards.fetchAll, [])

  const { settings: { fullWidth } } = widget

  return (
    <CarouselCards
      fullWidth={fullWidth}
      EmptyState={EmptyState}
      isEditing={isEditing}
      carouselCards={carouselCards}
    />
  )
}

export default CarouselCardsWidget
