import classNames from 'classnames'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import React from 'react'

import { Bar } from 'react-chartjs-2'
import { transparent } from 'utils/color'

interface BarGraphProps {
  labels: string[]
  dataValues: number[]
  displayYAxisLabel?: boolean
  yAxisLabel?: string
  tooltipLabel: string
  classname?: string
  isLoading?: boolean
}

const BarGraph = ({
  labels,
  dataValues,
  displayYAxisLabel = false,
  yAxisLabel,
  tooltipLabel,
  classname,
  isLoading = false,
}: BarGraphProps) => {
  const currentCompany = useCurrentCompany()

  const { highlightColor, textColorSecondary } = currentCompany.customStyles.variables.colors
  const hoverColor = transparent(highlightColor, 0.7)

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          color: textColorSecondary,
        },
      },
      y: {
        min: 0,
        max: Math.max(100, ...dataValues),
        ticks: {
          color: textColorSecondary,
          callback(value) {
            return value + '%'
          },
        },
        grid: { display: false },
        title: {
          display: displayYAxisLabel,
          text: yAxisLabel,
          color: textColorSecondary,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        titleFont: { size: 13, weight: 'light' },
        bodyFont: { size: 15 },
        titleAlign: 'center' as const,
        yAlign: 'bottom' as const,
        displayColors: false,
        backgroundColor: highlightColor,
        padding: 10,
        callbacks: {
          title(context) { // Customizes the tooltip title
            return context[0].label
          },
          label(context) { // Customizes the tooltip body text
            return context.parsed.y + '% ' + tooltipLabel
          },
        },
      },
    },
  }

  const data = {
    labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: highlightColor,
        hoverBackgroundColor: hoverColor,
      },
    ],
  }

  return (
    <div className={classNames('analytics-bar-graph', classname)}>
      {isLoading ? (
        <CirclesLoadingIndicator className='w-100 d-flex align-items-center h-100' />
      ) : (
        <Bar data={data} options={options} />
      )}
    </div>
  )
}

export default BarGraph
