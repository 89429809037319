import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { I18NCommon } from 'utils/i18nHelpers'
import IntegrationCard from 'components/admin/integrations/integrationCard'
import externalEmailAuthenticationSlice from 'redux/slices/externalEmailAuthentications'
import CdnSvg from 'components/common/cdnSvg'

const gmailLogo = '/images/integrations/gmailLogo.svg'

const GmailInstallationComponent = ({ installUrl }) => (
  <a className='btn btn-primary btn-sm btn-narrow my-1' href={installUrl} data-testid='gmail-install-link'>
    {I18NCommon('install')}
  </a>
)

const GmailIntegrationCard = ({ id, externalEmailAuthenticationId, installUrl }) => {
  const dispatch = useDispatch()
  const { isDeleting } = useSelector(externalEmailAuthenticationSlice.selectors.getMetaData())

  const uninstallGmailIntegration = () => {
    dispatch(externalEmailAuthenticationSlice.asyncActions.admin.destroy(externalEmailAuthenticationId))
  }

  return (
    <IntegrationCard
      id={id}
      logo={<CdnSvg src={gmailLogo} />}
      isInstalled={!!externalEmailAuthenticationId}
      installationComponent={<GmailInstallationComponent installUrl={installUrl} />}
      onUninstall={uninstallGmailIntegration}
      isUninstalling={isDeleting[id]}
    />
  )
}

export default GmailIntegrationCard
