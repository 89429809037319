import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'

export const initialState = {
  questionIds: [],
  meta: {
    ...defaultMeta,
  },
}

const surveyQuestionSlice = createSlice({
  name: 'surveyQuestions',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

const asyncActions = {
  admin: {
    fetch: (formId: string, id: string, queryParams = {}) => async (dispatch) => {
      dispatch(surveyQuestionSlice.actions.isLoading(true))

      try {
        const response = await API.admin.surveyQuestions.fetch(formId, id, queryParams)

        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(surveyQuestionSlice.actions.setError(error))
      } finally {
        dispatch(surveyQuestionSlice.actions.isLoading(false))
      }
    },
  },
}

const selectors = {
  getQuestion: (id: string) => state => build(state.entities, 'surveyQuestion', id) || {},
  getMetaData: () => state => state.surveyQuestions.meta,
}

export default {
  ...surveyQuestionSlice,
  selectors,
  asyncActions,
}

