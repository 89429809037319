import useApi from 'components/common/hooks/useApi'
import { useEffect } from 'react'
import API from 'services/api'
import { useParams } from 'react-router-dom'

const useAvailableWidgets = () => {
  const { layoutCollectionId } = useParams()
  const [fetch, { data: availableWidgets }] = useApi(
    () => API.admin.layout.availableWidgets.fetchAll(layoutCollectionId),
    { globalStateBucket: `availableWidgets-${layoutCollectionId}` }
  )

  useEffect(() => {
    if (_.isEmpty(availableWidgets)) {
      fetch()
    }
  }, [])

  return availableWidgets || []
}

export default useAvailableWidgets
