import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useQueryParams from 'components/common/hooks/useQueryParams'

import searchSlice, { PAGE_SIZES } from 'redux/slices/search'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { useHistory, useLocation } from 'react-router-dom'
import CdnSvg from 'components/common/cdnSvg'
import SearchResultsTemplate from './searchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const I18N = i18nPath('views.search.search_results.search_results')

const Author = ({ result: { createdBy } }) => {
  if (!createdBy) {
    return null
  }

  return (
    <div className='text-primary text-small font-weight-500'>
      {createdBy}
    </div>
  )
}


const OneDriveResults = ({ showLabel = true }) => {
  const location = useLocation()
  const history = useHistory()
  const queryParams = useQueryParams()
  const searchResultsFilter = queryParams.filter || 'all'
  const page = parseInt(queryParams.page) || 1
  const selected = searchResultsFilter === 'one_drive'
  const pageSize = selected ? PAGE_SIZES.pageFiltered : PAGE_SIZES.pageAll
  const searchLocation = selected ? 'pageFiltered' : 'pageAll'

  const { totalResultsByType, isLoadingOneDrive } = useSelector(searchSlice.selectors.getMetaData(searchLocation))
  const oneDriveSearchResults = useSelector(searchSlice.selectors.getOneDriveSearchResults(searchLocation))
  const [slicedOneDriveSearchResults, setSlicedOneDriveSearchResults] = useState([])

  const setPage = (page) => {
    const newParams = new URLSearchParams(location.search)
    newParams.set('page', page)
    history.push(`${location.pathname}?${newParams.toString()}`)
  }

  useEffect(() => {
    if (selected) {
      setSlicedOneDriveSearchResults(oneDriveSearchResults.slice((page - 1) * pageSize, page * pageSize))
    } else {
      setPage(1)
      setSlicedOneDriveSearchResults(oneDriveSearchResults.slice(0, pageSize))
    }
  }, [page, selected, oneDriveSearchResults])

  return (
    <SearchResultsTemplate
      showLabel={showLabel}
      label='one_drive'
      isLoading={isLoadingOneDrive}
      count={totalResultsByType.oneDrive}
      page={page}
      setPage={setPage}
      selected={selected}
      results={slicedOneDriveSearchResults.map(result => (
        <SearchResultTemplate
          key={result.value}
          displayPath={result.contentUrl}
          image={<CdnSvg src={result.icon} style={{ height: '29px', width: '29px' }} />}
          title={result.name}
          subtitle={<Author result={result} />}
          description={I18N('edited', { date: i18nMoment(result.lastModifiedDateTime).fromNow() })}
          result={result}
          openNewTab
        />
      ))}
    />
  )
}

export default OneDriveResults
