import React, { useState } from 'react'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import pageTemplateSlice, { SelectedTemplate } from 'redux/slices/pageTemplates'
import { useDispatch, useSelector } from 'react-redux'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.pages')
const triangleDownPath = '/images/triangleDown.svg'
const triangleUpPath = '/images/triangleUp.svg'

interface Template {
  id: string
  title: string
  content?: string | undefined
}

interface TemplateListItemProps {
  template: Template
  position: number
  onClick: () => void
  isSelected: boolean
}

const TemplateListItem = ({
  template, position, onClick, isSelected,
} : TemplateListItemProps) => (
  <div
    className={classNames(
      'TemplateListItem d-flex flex-row justify-content-between pointer mb-2 w-100 px-3 py-2 font-weight-500 align-items-center text-small',
      { Selected: isSelected }
    )}
    key={position}
    onClick={onClick}
  >
    <span className='truncate-text-at-1-lines'>{template.title}</span>
    <span className='PreviewText text-small text-secondary font-weight-400 ml-1'>{I18N('preview')}</span>
  </div>
)

interface TemplateListProps {
  category: {
    id: string,
    name: string
    latestTemplates: Template[]
    templatesCount: number
  }
  firstInList: boolean
  selectedTemplate: SelectedTemplate
  setSelectedTemplate: (template: SelectedTemplate) => void
}

const TemplateList = ({
  category,
  firstInList,
  selectedTemplate,
  setSelectedTemplate,
}: TemplateListProps) => {
  const dispatch = useDispatch()

  const {
    id: templateCategoryId,
    name: categoryName,
    latestTemplates: templates,
    templatesCount,
  } = category
  const collapsedCategoryState = (!firstInList && _.isEmpty(selectedTemplate)) || (
    selectedTemplate.id && !templates.filter(({ id }) => id === selectedTemplate.id).length
  )

  const [isCategoryCollapsed, setIsCategoryCollapsed] = useState(collapsedCategoryState)

  const toggleCollapseCategory = () => setIsCategoryCollapsed(!isCategoryCollapsed)

  const { queryParams } = useSelector(pageTemplateSlice.selectors.getMetaData())

  const pageToFetch = Math.ceil(templates.length / queryParams.perPage!) + 1

  const handleSeeMore = () => {
    // Only fetch more templates if there are more templates to fetch
    if (templatesCount > templates.length) {
      dispatch(pageTemplateSlice.asyncActions.fetchAll(
        templateCategoryId,
        { ...queryParams, page: pageToFetch }
      ))
    }
  }

  if (templatesCount === 0) {
    return null
  }

  return (
    <div className='TemplateList px-4 py-2 position-relative flex-grow-0 flex-shrink-0 d-flex flex-column justify-content-between'>
      <div className='d-flex flex-row justify-content-between mx-1 align-items-start mb-0'>
        <label className='text-secondary text-small font-weight-500 mt-1'>{categoryName}</label>
        <span onClick={toggleCollapseCategory} data-testid='cy_toggle_template_category'><CdnSvg className='ml-1' src={isCategoryCollapsed ? triangleDownPath : triangleUpPath} /></span>
      </div>
      <div className='d-flex flex-column align-items-center'>
        {
          !isCategoryCollapsed && templates.map((template, i) => (
            <TemplateListItem
              key={template.id}
              template={template}
              position={i}
              onClick={() => setSelectedTemplate({ id: template.id, templateCategoryId })}
              isSelected={template.id === selectedTemplate?.id}
            />
          ))
        }
      </div>
      {
        !isCategoryCollapsed && templatesCount > templates.length && (
          <p
            className='SeeMoreTemplates pointer d-flex flex-row justify-content-between ml-2 align-items-start font-weight-600 text-small'
            onClick={handleSeeMore}
          >
            {I18N('see_more')}
          </p>
        )
      }
    </div>
  )
}

export default TemplateList
