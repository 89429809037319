import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import difference from 'utils/deepDiff'
import transformToCssVariable from 'utils/transformToCssVariable'
import customStylesSlice from 'redux/slices/customStyles'
import { ThemeStyles, DynamicStyles } from 'components/appColorFunctions'
import { present } from 'components/common/utils'
import LoadingSpinner from 'components/common/loadingSpinner'
import TabSelector from 'components/common/tabSelector'
import { Button } from 'components/common/buttons'
import CustomStyles from 'pages/admin/custom_styles/context'
import NavigationBlocker from 'components/common/navigationBlocker'
import NavigationSection from 'components/admin/customStyles/navigationSection'
import ColorsSection from 'components/admin/customStyles/colorsSection'
import TypographySection from 'components/admin/customStyles/typographySection'
import FeedSection from 'components/admin/customStyles/feedSection'
import ComponentsSection from 'components/admin/customStyles/componentsSection'
import CustomCssSection from 'components/admin/customStyles/customCssSection'
import JsonSection from 'components/admin/customStyles/jsonSection'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import navigationBlockerSlice from 'redux/slices/navigationBlocker'

const I18N = i18nPath('views.custom_styles')

const CustomStylesPage = () => {
  const dispatch = useDispatch()
  const styles = useSelector(state => state.styles)
  const customStyles = useSelector(state => state.customStyles)
  const isLoading = customStyles.meta.isLoading
  const { section } = useParams()
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const rootElement = document.documentElement

  const wereChangesMade = () => {
    const { initialValues, workingCopy } = customStyles
    const updatedFields = difference(workingCopy, initialValues)

    return !_.isNull(initialValues)
      && !_.isNull(workingCopy)
      && Object.keys(updatedFields).length > 0
  }

  useEffect(() => {
    dispatch(customStylesSlice.asyncActions.loadCustomStyles())

    return resetWorkingCopy
  }, [])

  useEffect(() => {
    if (wereChangesMade()) resetWorkingCopy()
  }, [section])

  const resetWorkingCopy = () => {
    const { cssOverrides } = styles
    const { initialValues } = customStyles

    const defaultWorkingCopy = {
      variables: initialValues.variables,
      cssOverrides,
    }

    ThemeStyles.init(defaultWorkingCopy.variables).then(DynamicStyles.init)
    dispatch(customStylesSlice.actions.updateWorkingCopy(defaultWorkingCopy))
  }

  const handleRootElPropertyChange = (propertyType, propertyName, value) => {
    const {
      workingCopy: { cssOverrides, variables = {} },
    } = customStyles

    const currentValues = variables[propertyType]

    const newWorkingCopy = {
      variables: {
        ...variables,
        [propertyType]: {
          ...currentValues,
          [propertyName]: value,
        },
      },
      cssOverrides,
    }

    rootElement.style.setProperty(transformToCssVariable(propertyName), value)
    dispatch(customStylesSlice.actions.updateWorkingCopy(newWorkingCopy))
    DynamicStyles.init()
  }

  const handleOnClick = () => {
    const { workingCopy } = customStyles
    const { faviconUrl, ...newCustomStyles } = workingCopy
    const company = { ...currentCompany, customStyles: newCustomStyles, faviconUrl }

    dispatch(customStylesSlice.asyncActions.updateCustomStyles(company)).then(() => {
      dispatch(navigationBlockerSlice.asyncActions.performWithoutBlocking(() => {
        window.location.reload()
      }))
    })
  }

  const isClearyAdmin = !!currentUser.permissions.clearyAdmin

  const tabs = [
    { linkPath: 'navigation' },
    { linkPath: 'colors' },
    { linkPath: 'typography' },
    { linkPath: 'feed' },
    { linkPath: 'components' },
    isClearyAdmin && { linkPath: 'custom_css' },
    isClearyAdmin && { linkPath: 'json_data' },
  ].filter(present)

  const customStylesValue = {
    workingCopy: customStyles.workingCopy,
    handlePropertyChanged: handleRootElPropertyChange,
  }

  const renderSection = () => {
    switch (section) {
    case 'navigation':
      return <NavigationSection />
    case 'colors':
      return <ColorsSection />
    case 'typography':
      return <TypographySection />
    case 'feed':
      return <FeedSection />
    case 'components':
      return <ComponentsSection />
    case 'custom_css':
      return <CustomCssSection />
    case 'json_data':
      return <JsonSection />
    default:
      return null
    }
  }

  return (
    <CustomStyles.Provider value={customStylesValue}>
      <NavigationBlocker isBlocked={wereChangesMade()} />

      {isLoading && <LoadingSpinner />}

      <div className='container CustomStyles my-4'>
        <div className='row'>
          <div className='col-12'>
            {wereChangesMade() && (
              <div className='alert alert-warning text-center'>{I18N('unsaved_changes_warning')}</div>
            )}
          </div>
        </div>

        <TabSelector
          tabs={tabs}
          baseUrl='/admin/custom_styles/'
          keyPrefix='custom_styles_'
          translationsPath='views.custom_styles'
        >
          {renderSection()}

          <div className='row my-4'>
            <div className='col-12'>
              <Button
                className='float-right'
                onClick={handleOnClick}
                disabled={isLoading || !wereChangesMade()}
              >
                {I18N('save')}
              </Button>
            </div>
          </div>
        </TabSelector>
      </div>
    </CustomStyles.Provider>
  )
}

export default CustomStylesPage
