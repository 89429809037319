import React from 'react'

const RemoveXIcon = () => (
  <svg width='8px' height='8px' viewBox='0 0 8 8' version='1.1'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1256.000000, -331.000000)'>
        <g transform='translate(1252.000000, 327.000000)'>
          <rect id='Rectangle' fill='#000000' fillRule='nonzero' opacity='0' transform='translate(8.000000, 8.000000) rotate(180.000000) translate(-8.000000, -8.000000) ' x='-1.18423789e-15' y='1.18423789e-15' width='16' height='16' />
          <path d='M8.94,8 L11.8066667,5.14 C12.0680814,4.87858522 12.0680814,4.45474811 11.8066667,4.19333333 C11.5452519,3.93191855 11.1214148,3.93191855 10.86,4.19333333 L8,7.06 L5.14,4.19333333 C4.87858522,3.93191856 4.45474812,3.93191856 4.19333334,4.19333334 C3.93191856,4.45474812 3.93191856,4.87858522 4.19333333,5.14 L7.06,8 L4.19333333,10.86 C4.0671249,10.9851777 3.99613445,11.155575 3.99613445,11.3333333 C3.99613445,11.5110917 4.0671249,11.6814889 4.19333333,11.8066667 C4.31851107,11.9328751 4.48890831,12.0038655 4.66666667,12.0038655 C4.84442502,12.0038655 5.01482227,11.9328751 5.14,11.8066667 L8,8.94 L10.86,11.8066667 C10.9851777,11.9328751 11.155575,12.0038655 11.3333333,12.0038655 C11.5110917,12.0038655 11.6814889,11.9328751 11.8066667,11.8066667 C11.9328751,11.6814889 12.0038655,11.5110917 12.0038655,11.3333333 C12.0038655,11.155575 11.9328751,10.9851777 11.8066667,10.86 L8.94,8 Z' id='Path' fill='#C6C6C7' />
        </g>
      </g>
    </g>
  </svg>
)

export default RemoveXIcon
