import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from './searchResultOption'

const I18N = i18nPath('views.search.global_search')
const lightBulbIconPath = '/images/lightBulbIcon.svg'

const getCountLabel = (count) => {
  if (count === 0) return null

  const countLabelKey = `user_skill_count_${count === 1 ? 'singular' : 'plural'}`

  return I18N(countLabelKey, { count })
}

const UserSkillOption = ({ option }) => {
  const { userSkill, highlights } = option
  const { name, activeUsersCount } = { ...userSkill, ...highlights }

  return (
    <SearchResultOption
      image={<CdnSvg src={lightBulbIconPath} className='SearchResultOptionImg mr-1' />}
      title={name}
      secondaryTexts={[getCountLabel(activeUsersCount)]}
    />
  )
}

export default UserSkillOption
