import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import EventDateTimePicker from 'components/qna/settings_modal/event_schedule/eventDateTimePicker'
import AccessLevelToggleGroup from 'components/common/access_level_toggle_group'
import BackIcon from 'components/icons/backIcon'
import { ButtonLarge } from 'components/common/buttons'
import VideoConferenceRecapToggle from 'components/qna/event_fields/videoConferenceRecapToggle'

const I18N = i18nPath('views.qna.events')

const VideoConferenceLinkField = ({
  url,
  setUrl,
  generateRecapArticle,
  setGenerateArticleRecap,
}) => (
  <div className='row'>
    <div className='col-md-6 form-group'>
      <label className='form-label'>{I18N('video_meeting_url')}</label>
      <input
        className='Input'
        value={url}
        onChange={e => setUrl(e.target.value)}
        placeholder={I18N('video_meeting_url_placeholder')}
      />
      <VideoConferenceRecapToggle
        className='mt-2'
        url={url}
        checked={generateRecapArticle}
        onChange={setGenerateArticleRecap}
        defaultChecked
      />
    </div>
  </div>
)

const CreateEventFields = ({
  event,
  setEvent,
  saveEvent,
  isButtonDisabled,
  setEventTimeToNow,
  eventTimeChangeHandler,
  setTemplatesAsActiveStep,
  showVideoConferenceLink,
}) => (
  <div className='container'>
    <div className='row'>
      <div className='col'>
        <div className='d-flex flex-column align-items-start justify-content-between mb-4 pb-2'>
          <h1 className='m-0'>{I18N('new_event_title')}</h1>
          <a className='BackToTemplates text-secondary m-0 mt-4 pbt-2 d-flex align-items-baseline font-weight-normal' onClick={setTemplatesAsActiveStep}>
            <BackIcon />
            <span className='ml-2 text-large'>{I18N('back_to_templates')}</span>
          </a>
        </div>
      </div>
    </div>
    <div className='row'>
      <div className='col-md-6 form-group'>
        <label className='required-form-label'>{I18N('title')}</label>
        <input className='Input' value={event.title} onChange={e => setEvent({ ...event, title: e.target.value })} placeholder={I18N('event_title_placeholder')} />
      </div>
    </div>
    <EventDateTimePicker
      id='cy_event_editor_event_time'
      className='Input'
      dateTime={event.eventTime}
      label={I18N('event_time_label')}
      onChange={eventTimeChangeHandler}
      startImmediatelyHandler={setEventTimeToNow}
    />
    {showVideoConferenceLink && (
      <VideoConferenceLinkField
        url={event.videoConferenceLink}
        setUrl={url => setEvent({ ...event, videoConferenceLink: url })}
        generateRecapArticle={event.generateRecapArticle}
        setGenerateArticleRecap={generateRecapArticle => setEvent({ ...event, generateRecapArticle })}
      />
    )}
    <AccessLevelToggleGroup
      onChange={level => setEvent({ ...event, accessLevel: level })}
      selectedValue={event.accessLevel}
      publicDescription={I18N('access_level_toggle.everyone_description')}
      privateDescription={I18N('access_level_toggle.invite_only_description')}
      guidanceText={I18N('access_level_toggle.who_can_participate')}
    />
    <ButtonLarge className='mt-3' onClick={saveEvent} disabled={isButtonDisabled}>
      {I18N('create_event')}
    </ButtonLarge>{' '}
  </div>
)

export default CreateEventFields
