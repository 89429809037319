import React, { useEffect, useState } from 'react'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { CategoryItemWithFetch } from 'components/admin/articles/templates/categoryItem'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import PillTabSelector from 'components/common/pillTabSelector'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import TemplatesSearch from 'components/admin/articles/templates/templatesSearch'
import classNames from 'classnames'
import useFetch from 'components/common/hooks/useFetch'
import Sidebar from 'components/common/sidebar'

const I18N = i18nPath('views.admin.article_editor.templates_sidebar')

const CLEARY_TEMPLATES = 'cleary_templates'
const COMPANY_TEMPLATES = 'company_templates'
const SEARCH = 'search'

const ArticleTemplatesSidebar = ({ closeTemplatesSidebar }) => {
  const { name: companyName, settings } = useCurrentCompany()
  const {
    data: categories,
    isLoaded: areCategoriesLoaded,
  } = useFetch(API.admin.article.templateCategories.fetchAll)

  const tabs = [{
    id: CLEARY_TEMPLATES,
    text: I18N('cleary_templates'),
    onClick: () => setSelectedTab(CLEARY_TEMPLATES),
  }, {
    id: COMPANY_TEMPLATES,
    text: I18N('company_templates', { companyName }),
    onClick: () => setSelectedTab(COMPANY_TEMPLATES),
  }]

  const defaultDisplay = settings.news.templates.defaultDisplay
  const defaultSelectedIndex = tabs.findIndex(tab => tab.id === defaultDisplay)

  const [selectedTab, setSelectedTab] = useState(defaultDisplay)
  const [searchQuery, setSearchQuery] = useState('')

  const selectedArticleTemplatesView = localStorage.getItem('selectedArticleTemplatesView')
  const [gridView, setGridView] = useState(selectedArticleTemplatesView === 'grid')

  useEffect(() => {
    localStorage.setItem('selectedArticleTemplatesView', gridView ? 'grid' : 'list')
  }, [gridView])

  const visibleSection = searchQuery ? SEARCH : selectedTab

  return (
    <div className='ArticleTemplatesSidebar'>
      <Sidebar title={I18N('templates')} onClose={closeTemplatesSidebar} visible>
        <PillTabSelector
          tabs={tabs}
          pillClasses='text-small'
          className='mb-3'
          defaultSelectedIndex={defaultSelectedIndex}
        />
        <div className='d-flex align-items-center mb-3'>
          <AsyncSearchInput
            placeholder={I18N('search_for_template')}
            className='text-small w-100'
            onKeyUp={setSearchQuery}
            icon={<CdnSvg src='/images/searchIcon.svg' />}
            minCharsToSearch={3}
            inputGroupClassName='flex-grow-1 mr-2'
          />
          <div
            onClick={() => setGridView(false)}
            className={classNames('ListIcon pointer d-flex align-items-center justify-content-center mr-1', { isSelected: !gridView })}
          >
            <CdnSvg className='d-flex align-items-center justify-content-center' src='/images/app_launcher/listIcon.svg' />
          </div>
          <div
            onClick={() => setGridView(true)}
            className={classNames('GridIcon pointer d-flex align-items-center justify-content-center', { isSelected: gridView })}
          >
            <CdnSvg className='d-flex align-items-center justify-content-center' src='/images/app_launcher/gridIcon.svg' />
          </div>
        </div>
        {!areCategoriesLoaded ? (
          <CirclesLoadingIndicator />
        ) : (
          <>
            <div className={visibleSection === SEARCH ? '' : 'd-none'}>
              <TemplatesSearch
                searchQuery={searchQuery}
                isCompanyTemplates={selectedTab === COMPANY_TEMPLATES}
                gridView={gridView}
              />
            </div>
            <div className={visibleSection === CLEARY_TEMPLATES ? '' : 'd-none'}>
              {categories?.map(category => (
                <CategoryItemWithFetch
                  key={category.id}
                  label={category.name}
                  fetchParams={{ categoryId: category.id, baseTemplates: true, sortBy: 'title' }}
                  startExpanded={!category.collapsed}
                  gridView={gridView}
                />
              ))}
            </div>
            <div className={visibleSection === COMPANY_TEMPLATES ? '' : 'd-none'}>
              <CategoryItemWithFetch
                label={I18N('saved_templates')}
                fetchParams={{ companyTemplates: true, perPage: 12, sortBy: 'title' }}
                startExpanded
                gridView={gridView}
                renderEmptyStateIfEmpty
              />
            </div>
          </>
        )}
      </Sidebar>
    </div>
  )
}

export default ArticleTemplatesSidebar
