import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import classNames from 'classnames'

import Modal from 'components/common/modal'
import FormCheck from 'components/form_fields/formCheck'
import TaskDetail from 'components/journeys/tasks/taskDetail'
import IntroductionDetail from 'components/journeys/introductions/introductionDetail'
import taskSlice from 'redux/slices/journeys/tasks'
import introductionSlice from 'redux/slices/journeys/introductions'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import { StepType } from 'types/journeys/step'
import CdnSvg from 'components/common/cdnSvg'
import BuddyAssignmentDetail from 'components/journeys/tasks/buddyAssignmentDetail'
import DueAtTextComponent from 'components/journeys/tasks/dueAtTextComponent'
import Tooltip from 'components/common/clearyTooltip'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'


const I18N = i18nPath('views.journeys.taskable_detail')

const speechBubblesIconPath = '/images/speechBubbles.svg'

const introductionType = 'Journey::Introduction'
const taskType = 'Journey::Task'

const DETAIL_MODAL_OPEN = 'detail_modal'
const COMPLETE_TASK_MODAL_OPEN = 'complete_task_modal'
const COMPLETE_GROUP_TASK_MODAL = 'complete_group_task_modal'

interface TaskableDetailsButtonAndModalProps {
  showCompleteCheckbox?: boolean,
  showTaskableName?: boolean,
  allowReopening?: boolean,
  className?: string,
  step?: StepType,
  fetchTaskableOnClick?: boolean
  displayMode?: 'checkbox' | 'icon'
}

const TaskableDetailsButtonAndModal = ({
  step,
  showCompleteCheckbox = false,
  showTaskableName = true,
  allowReopening = false,
  className = '',
  fetchTaskableOnClick = false,
  displayMode = 'checkbox',
}: TaskableDetailsButtonAndModalProps) => {
  const [openModal, setOpenModal] = useState('')
  const dispatch = useDispatch()

  const taskable = step?.entity
  const taskableType = step?.entityType

  const currentUser = useCurrentUser()
  const { groupDisplayName } = useCurrentCompany()
  const isIntroduction = taskableType === introductionType
  const isTask = taskableType === taskType
  const isRegularTask = isTask && !taskable?.surveyTask && !taskable?.buddyAssignmentTask
  const isSurveyTask = taskable?.surveyTask || false
  const isBuddyAssignmentTask = taskable?.buddyAssignmentTask || false

  const commentsCount = taskable?.commentsCount || 0
  const checked = taskable?.completionStatus === 'completed'
  const { isSaving } = useSelector(taskSlice.selectors.getMetaData())
  const toCorrespondentUser = step?.toUser
  const preferredFullName = toCorrespondentUser?.preferredFullName
  const isCurrentUserAssigned = toCorrespondentUser?.id === currentUser?.id
  const isUnassignedGroupTask = step?.toCorrespondent?.role === 'specific_group' && !toCorrespondentUser

  const closeModal = () => {
    setOpenModal('')
  }

  const completeTask = () => {
    dispatch(taskSlice.asyncActions.completeTask(taskable.id, closeModal))
  }

  const reopenTaskable = () => {
    if (isRegularTask) {
      dispatch(taskSlice.asyncActions.reopenTask(taskable.id, closeModal))
    } else if (isIntroduction) {
      dispatch(introductionSlice.asyncActions.reopenIntroduction(taskable.id, closeModal))
    }
  }

  const fetchTaskable = () => {
    if (isSurveyTask) {
      dispatch(taskSlice.asyncActions.fetchTask(taskable.id))
    } else if (isIntroduction) {
      dispatch(introductionSlice.asyncActions.fetchIntroduction(taskable.id))
    }
  }

  const onCheckboxClick = (e) => {
    e.stopPropagation()

    if (checked && allowReopening) {
      reopenTaskable()
    }

    if (!checked && (isIntroduction || isSurveyTask || isBuddyAssignmentTask)) {
      if (fetchTaskableOnClick) {
        fetchTaskable()
      }

      setOpenModal(DETAIL_MODAL_OPEN)
    }

    if (!checked && isRegularTask) {
      if (isUnassignedGroupTask) {
        setOpenModal(COMPLETE_GROUP_TASK_MODAL)
      } else if (!!toCorrespondentUser && !isCurrentUserAssigned) {
        setOpenModal(COMPLETE_TASK_MODAL_OPEN)
      } else {
        completeTask()
      }
    }
  }

  return (
    <>
      {displayMode === 'checkbox' ? (
        <CheckboxDisplay
          taskable={taskable}
          showCompleteCheckbox={showCompleteCheckbox}
          showTaskableName={showTaskableName}
          onCheckboxClick={onCheckboxClick}
          setOpenModal={setOpenModal}
          checked={checked}
          commentsCount={commentsCount}
        />
      ) : (
        <IconDisplay
          taskable={taskable}
          onCheckboxClick={onCheckboxClick}
          setOpenModal={setOpenModal}
          checked={checked}
        />
      )}

      {openModal === DETAIL_MODAL_OPEN && (
        <Modal size='lg' className={classNames('TaskableDetailsModal', className)} visible={openModal === DETAIL_MODAL_OPEN} toggle={closeModal}>
          {(isRegularTask || isSurveyTask) && (
            <TaskDetail
              className='px-3'
              task={taskable}
              hasTitle={true}
              onCompleteOrReopen={closeModal}
            />
          )}
          {isIntroduction && (
            <IntroductionDetail
              className='px-3'
              introduction={taskable}
              hasTitle={true}
              onCompleteOrReopen={closeModal}
            />
          )}
          {isBuddyAssignmentTask && (
            <BuddyAssignmentDetail
              className='px-3'
              task={taskable}
              hasTitle
              onComplete={closeModal}
            />
          )}
        </Modal>
      )}
      {openModal === COMPLETE_TASK_MODAL_OPEN && (
        <ConfirmationModal
          visible={openModal === COMPLETE_TASK_MODAL_OPEN}
          title={I18N('complete_task_modal.title')}
          description={I18N('complete_task_modal.description', { name: preferredFullName })}
          confirmText={I18N('complete_task_modal.mark_complete')}
          toggle={closeModal}
          isSaving={isSaving}
          onConfirmClick={completeTask}
          className='CompleteTaskConfirmationModal'
        />
      )}
      {openModal === COMPLETE_GROUP_TASK_MODAL && (
        <ConfirmationModal
          visible
          title={I18N('complete_group_task_modal.title', { groupName: step?.toCorrespondent?.group?.name, groupDisplayName })}
          description={I18N('complete_group_task_modal.description')}
          confirmText={I18N('complete_group_task_modal.mark_complete')}
          toggle={closeModal}
          isSaving={isSaving}
          onConfirmClick={completeTask}
          className='CompleteGroupTaskConfirmationModal'
        />
      )}
    </>
  )
}

const CheckboxDisplay = ({
  taskable,
  showCompleteCheckbox,
  showTaskableName,
  onCheckboxClick,
  setOpenModal,
  checked,
  commentsCount,
}) => (
  <div className='TaskableDetailsButtonAndModal d-flex align-items-center'>
    {showCompleteCheckbox && (
      <FormCheck
        onChange={onCheckboxClick}
        id={`taskable-${taskable?.id}`}
        checked={checked}
      />
    )}
    {showTaskableName && <a className='link-color' onClick={() => setOpenModal(DETAIL_MODAL_OPEN)}>{taskable?.name}</a>}
    {showTaskableName && commentsCount > 0 && (
      <div className='ml-2 d-flex justify-content-center'>
        <CdnSvg className='CommentsIcon' src={speechBubblesIconPath} />
        <p className='ml-1 mt-1 mb-0 link-color text-smallest'>{commentsCount}</p>
      </div>
    )}
  </div>
)

const IconDisplay = ({
  taskable,
  onCheckboxClick,
  setOpenModal,
  checked,
}) => (
  <div
    className='TaskableDetailsButtonAndModal IconDisplay d-flex gap-2 pointer p-3 pr-4 bordered position-relative'
    key={`task-${taskable.id}`}
    onClick={() => setOpenModal(DETAIL_MODAL_OPEN)}
  >
    <Tooltip
      placement='top'
      hide={0}
      content={(
        <div
          className='CheckMarkIconContainer d-flex align-items-center justify-content-center'
          onClick={onCheckboxClick}
          id={`taskable-${taskable.id}`}
        >
          <CdnSvg
            className={classNames('CheckMarkIcon', { checked })}
            src='/images/checkMarkIcon.svg'
          />
        </div>
      )}
    >
      <span className='text-small'>{I18N('mark_as_completed')}</span>
    </Tooltip>

    <div className='NameAndDueAt'>
      <div className='font-weight-600 truncate-text-at-2-lines'>
        {taskable.name}
      </div>
      <DueAtTextComponent
        className='text-small'
        task={taskable}
        hasDuePrefix
      />
    </div>
  </div>
)

export default TaskableDetailsButtonAndModal
