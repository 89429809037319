import { HocuspocusProvider, onAwarenessUpdateParameters } from '@hocuspocus/provider'
import {
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react'

interface UseCollaborativeIsLeaderParams {
  provider?: HocuspocusProvider
}

const useCollaborativeIsLeader = ({ provider }: UseCollaborativeIsLeaderParams) => {
  const [isLeader, setIsLeader] = useState(false)
  const isMounted = useRef(true) // Ref to track if the component is mounted

  useEffect(() => {
    isMounted.current = true // Set mounted to true when component mounts
    return () => {
      isMounted.current = false // Set mounted to false when component unmounts
    }
  }, [])

  // useCallback to memoize the awarenessUpdate function
  const awarenessUpdate = useCallback(({ states }: onAwarenessUpdateParameters) => {
    const firstClientId = states.map(a => a.clientId).sort((a, b) => a - b)[0]

    // Using useCallback helps to memoize the function that updates the isLeader state,
    // ensuring that it does not change on every render, which can prevent unnecessary re-renders.

    // Using useRef allows us to store the isLeader state without causing re-renders.
    // This prevents the warning about updating a component while rendering another,
    // as we can update the ref value without triggering a state update in the component.

    if (isMounted.current) { // Check if the component is still mounted
      setTimeout(() => {
        if (provider) { // Check if provider is defined
          setIsLeader(firstClientId === provider.awareness?.clientID) // Update isLeader state
        }
      }, 0) // Defer the state update to avoid updating during render
    }
  }, [provider]) // Dependency on provider

  useEffect(() => {
    if (!provider) { return () => {} } // Early return if provider is not available

    provider.on('awarenessUpdate', awarenessUpdate) // Subscribe to awareness updates

    return () => {
      provider.off('awarenessUpdate', awarenessUpdate) // Cleanup subscription on unmount
    }
  }, [provider, awarenessUpdate]) // Dependencies for the effect

  return isLeader // Return the current leader status
}

export default useCollaborativeIsLeader
