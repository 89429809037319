import React from 'react'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import classNames from 'classnames'
import { ChatUserGroupType } from 'types/chat/userGroup'
import CdnSvg from 'components/common/cdnSvg'

const slackIconPath = '/images/slackLogoIcon.svg'

interface Props {
  userGroup: ChatUserGroupType
  iconClass?: string
  className?: string
}

const ChatUserGroup = ({
  userGroup,
  className,
  iconClass = 'w-16',
}: Props) => {
  const { canSeeMultipleSlackWorkspaces } = useCurrentUser()
  const name = `@${userGroup.handle}`

  return (
    <span className={className}>
      <CdnSvg src={slackIconPath} className={classNames('d-inline-block mr-1', iconClass)} />

      <span className='text-color-primary font-weight-600'>
        {name}
      </span>
      {canSeeMultipleSlackWorkspaces && (
        <span className='text-secondary font-weight-500 ml-1'>- {userGroup.workspaceDomain}</span>
      )}
    </span>
  )
}

export default ChatUserGroup
