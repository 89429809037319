import usePreviousDiff from 'components/common/hooks/usePreviousDiff'

/**
 * returns true when the previous value is true and the current is false.
 * otherwise returns false.
 *
 * This is usefull since all loading states in slices started as false. So
 * we can't rely only in the value to determine it. We need to know if has transitioned from
 * true (loading) to false (loaded)
 */
function useIsLoaded(value) {
  const previousValue = usePreviousDiff(value)

  return previousValue === true && value === false
}

export default useIsLoaded
