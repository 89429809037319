import { createSlice } from '@reduxjs/toolkit'

import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'

const I18N = i18nPath('views.cover_image')

/**
 *
 * @param sliceName String to use as slice name and redux store name
 * @param API Reference to API base object to make requests to
 * @param customAsyncActions Function that returns an object containing custom actions
 *                           and receives a reference to the slice instance.
 *
 * Example usage:
 *
 * coverImageSlice({
 *    sliceName: 'eventCoverImage',
 *    API: API.admin.qna.events,
 *    customAsyncActions: slice => ({
 *      removeTemplateImage: someData => async dispatch => {
 *        // async logic
 *      },
 *      anotherAction: otherData => async dispatch => {
 *        // do something with dispatch
 *      }
 *    }),
 * })
 *
 * @returns Instance of a slice
 */
const coverImageSlice = ({ sliceName, API, customAsyncActions = () => ({}) }) => {
  const slice = createSlice({
    name: sliceName,
    initialState: {
      meta: {
        isUpdating: false,
        isUploading: false,
        isRemoving: false,
      },
    },
    reducers: {
      isUploading(state, action) {
        state.meta.isUploading = action.payload
      },
      isUpdating(state, action) {
        state.meta.isUpdating = action.payload
      },
      isRemoving(state, action) {
        state.meta.isRemoving = action.payload
      },
    },
  })

  //------------------------------------------------------------
  // ASYNC ACTIONS
  //------------------------------------------------------------

  _.assign(slice, {
    asyncActions: {
      uploadCoverImage: ({
        modelId, file, croppedAreaPixels, options = {},
      }) => async (dispatch) => {
        try {
          dispatch(slice.actions.isUploading(true))
          const response = await API.uploadCoverImage(modelId, file, croppedAreaPixels, options)

          dispatch(entitySlice.actions.update({ data: response.data }))

          dispatch(showToastMessage({ message: I18N('cover_image_updated'), type: 'success' }))

          dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response.data || {}))
        } catch (e) {
          console.error({ e })
          const { error } = checkForError(getResponseOrThrow(e))
          dispatch(showToastMessage({ message: error.message, type: 'error' }))
        } finally {
          dispatch(slice.actions.isUploading(false))
        }
      },

      removeCoverImage: (modelId, options = {}) => async (dispatch) => {
        try {
          dispatch(slice.actions.isRemoving(true))
          const response = await API.removeCoverImage(modelId, options)

          dispatch(entitySlice.actions.update(response))

          dispatch(showToastMessage({ message: I18N('cover_image_removed'), type: 'success' }))

          dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response.data || {}))
        } catch (e) {
          console.error({ e })
          dispatch(showToastMessage({ message: I18N('removal_error_message'), type: 'warn' }))
        } finally {
          dispatch(slice.actions.isRemoving(false))
        }
      },

      repositionCoverImage: (modelId, croppedAreaPixels, options = {}) => async (dispatch) => {
        try {
          dispatch(slice.actions.isUpdating(true))
          const response = await API.repositionCoverImage(modelId, croppedAreaPixels, options)
          dispatch(entitySlice.actions.update(response))

          dispatch(showToastMessage({ message: I18N('cover_image_repositioned'), type: 'success' }))

          dispatch(collaborativeEditorSlice.asyncActions.broadcastRecordChanges(response.data || {}))
        } catch (e) {
          console.error({ e })
          dispatch(showToastMessage({ message: I18N('reposition_error_message'), type: 'warn' }))
        } finally {
          dispatch(slice.actions.isUpdating(true))
        }
      },

      // Pass down instance fo the coverImageSlice
      ...customAsyncActions(slice),
    },
  })

  //------------------------------------------------------------
  // SELECTORS
  //------------------------------------------------------------
  _.assign(slice, {
    selectors: {
      getCoverImageMetaData: () => state => state[sliceName].meta,
    },
  })

  return slice
}
export default coverImageSlice
