import React from 'react'

import PopularPages from 'components/analytics/pages/popularPages'
import StalePages from 'components/analytics/pages/stalePages'
import InactiveOwners from 'components/analytics/pages/inactiveOwners'
import UniquePageViews from 'components/analytics/pages/uniquePageViews'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import TabHeader from 'components/analytics/helpers/tabHeader'

const PagesTab = () => {
  const {
    targetingRules,
    completedTargetingRules,
    setTargetingRules,
    setGroupBy,
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    groupBy,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  return (
    <main className='PagesTab'>
      <TabHeader
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        setGroupBy={setGroupBy}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      <UniquePageViews
        periodStart={periodStart}
        periodEnd={periodEnd}
        groupBy={groupBy}
        targetingRules={completedTargetingRules}
      />

      <PopularPages
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
        order='most'
      />

      <PopularPages
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
        order='least'
      />

      <StalePages />

      <InactiveOwners
        targetingRules={completedTargetingRules}
      />
    </main>
  )
}

export default PagesTab
