import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'

const PreboardingPageLink = ({
  page,
  isCurrentPage,
}) => (
  <div className={classNames('PreboardingPageLinkRow d-flex align-items-center', { isCurrentPage })}>
    <NavLink
      to={`/pages/${page.slug}`}
      className={classNames('PreboardingPageLink px-3', { 'text-primary-link': !isCurrentPage })}
    >
      {page.title}
    </NavLink>
  </div>
)

const PreboardingPagesNav = ({
  currentPage = {},
  isDesktopNavOpen = true,
}) => {
  const currentCompany = useCurrentCompany()
  const { data: pages = [] } = useFetch(API.journey.preboarding.pages.fetchAll, [])

  return (
    <nav
      className={
        classNames(
          'LeftNav PreboardingPagesNav position-relative flex-grow-0 flex-shrink-0 d-flex flex-column',
          { isDesktopNavOpen }
        )
      }
    >
      <h6 className='text-smallest text-uppercase text-secondary font-weight-500 pt-3 pb-2 mb-0 mx-0 px-3'>
        {currentCompany.pageDisplayName || I18n.t('views.navbar.pages')}
      </h6>

      <div className='pages-list-container'>
        {pages.map(page => (
          <PreboardingPageLink
            key={page.id}
            page={page}
            isCurrentPage={page.id === currentPage.id}
          />
        ))}
      </div>
    </nav>
  )
}

export default PreboardingPagesNav
