import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import userSlice from 'redux/slices/users'
import DateBasedCelebrationsGrid from 'components/people/dateBasedCelebrationsGrid'
import { i18nDisplayDateWithoutYear } from 'utils/i18nHelpers'
import { trackEvent } from 'services/tracker'

const BirthdaysPage = () => {
  const dispatch = useDispatch()

  const dispatchFetchEmployees = (filter, paginationParams = { page: 1, perPage: 12 }) => {
    dispatch(userSlice.asyncActions.fetchBirthdays(filter, paginationParams))
  }

  useEffect(() => {
    dispatchFetchEmployees()
    trackEvent('birthday:view_all')
  }, [])

  const birthdaysNextWeek = useSelector(userSlice.selectors.getNextWeeksBirthdays())
  const birthdaysThisWeek = useSelector(userSlice.selectors.getThisWeeksBirthdays())
  const birthdaysLastWeek = useSelector(userSlice.selectors.getLastWeeksBirthdays())

  const employeesSelector = (filter) => {
    if (filter === 'next_week') return birthdaysNextWeek
    if (filter === 'last_week') return birthdaysLastWeek
    return birthdaysThisWeek
  }

  const renderBirthdayAdditionalText = employee => i18nDisplayDateWithoutYear(employee.birthday)

  return (
    <DateBasedCelebrationsGrid
      employeesSelector={employeesSelector}
      dispatchFetchEmployees={dispatchFetchEmployees}
      fetchMeta={userSlice.selectors.getBirthdaysMetaData}
      i18nPathStr='views.birthdays'
      additionalText={renderBirthdayAdditionalText}
      reportType='birthdays'
    />
  )
}

export default BirthdaysPage
