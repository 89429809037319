import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import appSignal from 'services/appSignal'

import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { trackAmplitudeEvent } from 'services/tracker'

const I18N = i18nPath('views.golinks_list')

const MY_HOMEPAGE = 'my_homepage'
const DEFAULT_HOMEPAGE = 'default_homepage'
const GROUP = 'group'

export const defaultWorkingCopy = {
  id: null,
  name: '',
  displayName: '',
  url: 'https://',
  parameterizedUrl: '',
  description: '',
}

const initialState = {
  goLinkId: null,
  goLinkIds: [],
  featuredGoLinkIds: [],
  homepageGoLinkIds: [],
  personalHomepageGoLinkIds: [],
  groupGoLinkIds: [],
  groupId: null,
  collectionId: null,
  meta: {
    isNotFound: false,
    isSaveComplete: false,
    isSaving: false,
    isLoading: false,
    isLoadingFeaturedGoLinks: false,
    isDeleting: false,
    isClaimed: false,
    error: null,
    queryParams: {
      ...defaultPaginationParams,
      perPage: 50,
      sortBy: 'num_visits',
      sortDir: 'desc',
      userId: null,
    },
  },
}

const linkSlice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    clear(state, action) {
      state.goLinkId = null
    },
    setGoLinkId(state, action) {
      state.goLinkId = action.payload
    },
    setGoLinkIds(state, action) {
      state.goLinkIds = action.payload
    },
    setIsClaimed(state, action) {
      state.meta.isClaimed = action.payload
    },
    setFeaturedGoLinkIds(state, action) {
      state.featuredGoLinkIds = action.payload
    },
    addFeaturedGoLinkId(state, action) {
      state.featuredGoLinkIds = Array.from(new Set([...state.featuredGoLinkIds, action.payload]))
    },
    removeFeaturedGoLinkId(state, action) {
      state.featuredGoLinkIds = state.featuredGoLinkIds.filter(goLinkId => goLinkId !== action.payload)
    },
    setIsLoadingFeaturedGoLinks(state, action) {
      state.meta.isLoadingFeaturedGoLinks = action.payload
    },
    setPersonalHomepageGoLinkIds(state, action) {
      state.personalHomepageGoLinkIds = action.payload
    },
    setHomepageGoLinkIds(state, action) {
      state.homepageGoLinkIds = action.payload
    },
    setGroupGoLinkIds(state, action) {
      state.groupGoLinkIds = action.payload
    },
    setGroupId(state, action) {
      state.groupId = action.payload
    },
    setCollectionId(state, action) {
      state.collectionId = action.payload
    },
    removeHomepageGoLinkIds(state, action) {
      state.homepageGoLinkIds = state.homepageGoLinkIds.filter(goLinkId => goLinkId !== action.payload)
    },
    removePersonalHomepageGoLinkIds(state, action) {
      state.personalHomepageGoLinkIds = state.personalHomepageGoLinkIds.filter(goLinkId => goLinkId !== action.payload)
    },
    removeGroupGoLinkIds(state, action) {
      state.groupGoLinkIds = state.groupGoLinkIds.filter(goLinkId => goLinkId !== action.payload)
    },
    setIsNotFound(state, action) {
      state.meta.isNotFound = action.payload
    },
    setIsSaveComplete(state, action) {
      state.meta.setIsSaveComplete = action.payload
    },
    setIsSaving(state, action) {
      state.meta.isSaving = action.payload
    },
    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },
    setIsDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },
    setError(state, action) {
      state.meta.error = action.payload
    },
    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },
  },
})

_.assign(linkSlice, {
  asyncActions: {
    claimGoLink: goLink => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaveComplete(false))
      dispatch(linkSlice.actions.setIsSaving(true))

      try {
        const response = await API.golinks.claim(goLink)

        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(linkSlice.actions.setIsClaimed(true))
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      } finally {
        dispatch(linkSlice.actions.setIsSaveComplete(true))
        dispatch(linkSlice.actions.setIsSaving(false))
      }
    },
    createGoLink: (goLink, history) => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaveComplete(false))
      dispatch(linkSlice.actions.setIsSaving(true))

      try {
        const response = await API.golinks.create(goLink)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(linkSlice.actions.setGoLinkId(response.data.data.id))

        history.push(`/golinks/${goLink.name}`)
      } catch (e) {
        const { hasError, error } = checkForError(getResponseOrThrow(e))

        dispatch(linkSlice.actions.setIsNotFound(hasError))
        dispatch(linkSlice.actions.setError(error))
      } finally {
        dispatch(linkSlice.actions.setIsSaveComplete(true))
        dispatch(linkSlice.actions.setIsSaving(false))
      }
    },
    removeLinkFromCollection: (link, collection) => async (dispatch) => {
      dispatch(linkSlice.actions.setIsDeleting(true))
      try {
        await API.golinks.removeLinkFromCollection(link.id, collection.id)

        if (collection.type === 'group') {
          dispatch(linkSlice.actions.removeGroupGoLinkIds(link.id))
        } else if (collection.type === 'my_homepage') {
          dispatch(linkSlice.actions.removePersonalHomepageGoLinkIds(link.id))
        } else {
          dispatch(linkSlice.actions.removeHomepageGoLinkIds(link.id))
        }
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(linkSlice.actions.setError(error))
      } finally {
        dispatch(linkSlice.actions.setIsDeleting(false))
      }
    },
    addLinkToCollection: (linkId, collection) => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaving(true))
      try {
        await API.golinks.addLinkToCollection(linkId, collection.id)
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(linkSlice.actions.setError(error))
      } finally {
        dispatch(linkSlice.actions.setIsSaving(false))
      }
    },
    createAndAddLinkToCollection: (link, collection, history, useGoBranding = true) => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaveComplete(false))
      dispatch(linkSlice.actions.setIsSaving(true))
      dispatch(linkSlice.actions.setError(null))

      try {
        const createResponse = await API.golinks.create(link)
        const linkId = createResponse.data.data.id

        const response = await API.golinks.addLinkToCollection(linkId, collection.id)

        if (history) {
          history.push(`/${useGoBranding ? 'golinks' : 'links'}/filter/${collection.type}`)
        }

        if (collection.type === MY_HOMEPAGE) {
          trackAmplitudeEvent('my_homepage_link_added', { golink_name: link.name })
        } else if (collection.type === DEFAULT_HOMEPAGE) {
          trackAmplitudeEvent('default_homepage_link_added', { golink_name: link.name })
        } else if (collection.type === GROUP) {
          trackAmplitudeEvent('group_link_added', { golink_name: link.name })
        }

        return response
      } catch (e) {
        const { hasError, error } = checkForError(getResponseOrThrow(e))

        dispatch(linkSlice.actions.setIsNotFound(hasError))
        dispatch(linkSlice.actions.setError(error))
      } finally {
        dispatch(linkSlice.actions.setIsSaveComplete(true))
        dispatch(linkSlice.actions.setIsSaving(false))
      }
      return undefined
    },
    updateGoLink: (goLink, history) => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaveComplete(false))
      dispatch(linkSlice.actions.setIsSaving(true))
      dispatch(linkSlice.actions.setError(null))

      try {
        const response = await API.golinks.update(goLink)

        dispatch(entitySlice.actions.update({ data: response.data }))

        if (history) history.push(`/golinks/${goLink.name}`)
      } catch (e) {
        const { hasError, error } = checkForError(getResponseOrThrow(e))

        dispatch(linkSlice.actions.setIsNotFound(hasError))
        dispatch(linkSlice.actions.setError(error))
      } finally {
        dispatch(linkSlice.actions.setIsClaimed(false))
        dispatch(linkSlice.actions.setIsSaveComplete(true))
        dispatch(linkSlice.actions.setIsSaving(false))
      }
    },
    deleteGoLink: (goLink, history) => async (dispatch) => {
      try {
        await API.golinks.destroy(goLink)

        history.push('/golinks')
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      }
    },
    removeFeaturedGoLink: goLink => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaveComplete(false))
      dispatch(linkSlice.actions.setIsSaving(true))

      try {
        const response = await API.golinks.removeFeatured(goLink)
        const goLinkId = response.data.data.id

        dispatch(linkSlice.actions.removeFeaturedGoLinkId(goLinkId))
        dispatch(entitySlice.actions.add({ data: response.data }))

        dispatch(showToastMessage({ message: I18N('remove_featured_go_link_complete'), type: 'success' }))
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      } finally {
        dispatch(linkSlice.actions.setIsSaveComplete(true))
        dispatch(linkSlice.actions.setIsSaving(false))
      }
    },
    setFeaturedGoLink: goLinkName => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaveComplete(false))
      dispatch(linkSlice.actions.setIsSaving(true))

      try {
        const response = await API.golinks.addFeatured(goLinkName)
        const goLinkId = response.data.data.id

        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(linkSlice.actions.addFeaturedGoLinkId(goLinkId))

        dispatch(showToastMessage({ message: I18N('set_featured_go_link_complete'), type: 'success' }))
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      } finally {
        dispatch(linkSlice.actions.setIsSaveComplete(true))
        dispatch(linkSlice.actions.setIsSaving(false))
      }
    },
    setFeaturedGoLinkOrder: (goLinks, oldIndex, newIndex) => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaveComplete(false))
      dispatch(linkSlice.actions.setIsSaving(true))

      try {
        const response = await API.golinks.changeFeaturedGolinkOrderPosition(goLinks[oldIndex], newIndex)
        const goLinkIds = response.data.data.map(goLink => goLink.id)

        dispatch(linkSlice.actions.setFeaturedGoLinkIds(goLinkIds))
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(showToastMessage({ message: error.message, type: 'error' }))
      } finally {
        dispatch(linkSlice.actions.setIsSaveComplete(true))
        dispatch(linkSlice.actions.setIsSaving(false))
      }
    },
    fetchGoLink: goLinkId => async (dispatch) => {
      dispatch(linkSlice.actions.setIsNotFound(false))
      dispatch(linkSlice.actions.setIsLoading(true))
      dispatch(linkSlice.actions.setGoLinkId(null))

      try {
        const response = await API.golinks.fetch(goLinkId)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(linkSlice.actions.setGoLinkId(response.data.data.id))
      } catch (e) {
        const { hasError, error } = checkForError(getResponseOrThrow(e))

        dispatch(linkSlice.actions.setIsNotFound(hasError))
        dispatch(linkSlice.actions.setError(error))
      } finally {
        dispatch(linkSlice.actions.setIsLoading(false))
      }
    },
    fetchGoLinks: queryParams => async (dispatch) => {
      dispatch(linkSlice.actions.setIsLoading(true))
      dispatch(linkSlice.actions.setIsNotFound(false))

      try {
        const response = await API.golinks.fetchAll(queryParams)
        const newQueryParams = queryParamsFromHeaders(response)
        const goLinkIds = response.data.data.map(goLink => goLink.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(linkSlice.actions.setQueryParams(newQueryParams))
        dispatch(linkSlice.actions.setGoLinkIds(goLinkIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(linkSlice.actions.setIsClaimed(false))
        dispatch(linkSlice.actions.setIsLoading(false))
      }
    },
    fetchGroupGoLinks: groupId => async (dispatch) => {
      dispatch(linkSlice.actions.setIsLoading(true))

      try {
        const response = await API.golinks.fetchGroupGoLinks(groupId)
        const { id, goLinks } = response.data
        const goLinkIds = goLinks.data.map(item => item.id)

        dispatch(entitySlice.actions.add({ data: goLinks }))
        dispatch(linkSlice.actions.setGroupGoLinkIds(goLinkIds))
        dispatch(linkSlice.actions.setCollectionId(id))
        dispatch(linkSlice.actions.setGroupId(groupId))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(linkSlice.actions.setIsLoading(false))
      }
    },
    fetchFeaturedGoLinks: () => async (dispatch) => {
      dispatch(linkSlice.actions.setIsLoadingFeaturedGoLinks(true))

      try {
        const response = await API.golinks.fetchAll({ featured: true })
        const featuredGoLinkIds = response.data.data.map(goLink => goLink.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(linkSlice.actions.setFeaturedGoLinkIds(featuredGoLinkIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(linkSlice.actions.setIsLoadingFeaturedGoLinks(false))
      }
    },
    setLinkOrder: (links, oldIndex, newIndex, collection) => async (dispatch) => {
      dispatch(linkSlice.actions.setIsSaving(true))

      try {
        await API.golinks.updateLinkInCollection(links[oldIndex].id, collection.id, newIndex)
      } finally {
        dispatch(linkSlice.actions.setIsSaving(false))
      }
    },
  },
})

_.assign(linkSlice, {
  selectors: {
    getMetaData: () => state => state.links.meta,

    getGoLink: () => (state) => {
      if (state.links.goLinkId == null) {
        return {}
      }

      return build(state.entities, 'goLink', state.links.goLinkId) || {}
    },

    getGoLinks: () => state => state.links.goLinkIds.map(id => build(state.entities, 'goLink', id)) || [],

    getFeaturedGoLinks: () => state => state.links.featuredGoLinkIds.map(id => build(state.entities, 'goLink', id)) || [],

    getGroupGoLinks: groupId => (state) => {
      if (groupId !== state.links.groupId) return []
      return state.links.groupGoLinkIds.map(id => build(state.entities, 'goLink', id)) || []
    },

    getCollectionId: () => state => state.links.collectionId,
  },
})

export default linkSlice
