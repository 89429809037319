import classNames from 'classnames'
import Table from 'components/common/tables/table'
import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'

interface InnerSortableTableProps {
  data: any[],
  columns: any[],
  rowClassName?: (row: any) => string,
  className?: string,
  noResultsText?: string,
  hideHeaders?: boolean,
  isSaving?: boolean,
}

const InnerSortableTable = SortableContainer<InnerSortableTableProps>(({
  data,
  columns,
  className,
  isSaving,
  rowClassName,
  noResultsText,
  hideHeaders,
}) => (
  <Table
    data={data}
    columns={columns}
    className={classNames('white-bg-table', 'Sortable', 'cursorGrab', { isSaving }, className)}
    isSortable
    rowClassName={rowClassName}
    noResultsText={noResultsText}
    hideHeaders={hideHeaders}
  />
))

interface SortableTableProps extends InnerSortableTableProps {
  onSortEnd: (data: any) => void,
}

const SortableTable = ({ onSortEnd, ...props }: SortableTableProps) => (
  <InnerSortableTable
    onSortEnd={onSortEnd}
    axis='y'
    lockAxis='y'
    helperClass='Sortable-isDragging'
    distance={5}
    {...props}
  />
)

export default SortableTable
