import React, { useEffect, useRef } from 'react'
import Card from 'components/common/card'
import { useHistory } from 'react-router-dom'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import ToggleSwitch from 'components/common/toggleSwitch'
import ArticleTypeSelect from 'components/admin/articles/content_editor/articleTypeSelect'
import TagsSelect from 'components/common/tagsSelect'
import ArticleByLineEditor, { GroupArticleByLineEditor } from 'components/admin/articles/content_editor/articleByLineEditor'
import CdnSvg from 'components/common/cdnSvg'
import Article from 'components/articles/article'
import usePreviewableArticle from 'components/admin/articles/hooks/usePreviewableArticle'
import useQueryParams from 'components/common/hooks/useQueryParams'
import removeQueryParam from 'utils/removeQueryParam'
import { ARTICLES_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import classNames from 'classnames'
import useIsGroupPost from 'components/admin/articles/hooks/useIsGroupPost'
import ArticleTagsSelect from 'components/admin/articles/content_editor/articleTagsSelect'

const I18N = i18nPath('views.admin.article_editor')

const ArticleContentEditor = ({
  workingCopy,
  updateWorkingCopy,
  isEditingTemplate = false,
  isVisible,
}) => {
  const {
    id,
    articleType,
    commentable,
    content,
    tags,
    title,
  } = workingCopy
  const { settings } = useCurrentCompany()
  const { scrollTo } = useQueryParams()

  const { facebook, twitter, linkedin } = settings.news.socialSharing
  const socialShareEnabled = facebook || twitter || linkedin

  const [previewableArticle, { isPreviewing, togglePreview }] = usePreviewableArticle(workingCopy, isVisible)
  const isGroupPost = useIsGroupPost(workingCopy)

  // This component is never unmounted when switching tabs
  // so we need to determine when user switched tab by if it's visible or not
  // When it is in the preview mode and become invisible,
  // set to editing mode
  useEffect(() => {
    if (!isVisible && isPreviewing) {
      togglePreview()
    }
  }, [isVisible])

  const titleInputRef = useRef<any>()
  const tagsSelectRef = useRef()
  const articleTypeSelectRef = useRef()

  const scrollAndFocus = (ref) => {
    if (ref.current) {
      // react selects use input refs, other fields use regular refs
      const inputRef = ref.current.inputRef || ref.current
      inputRef.scrollIntoView({ behavior: 'smooth' })
      inputRef.focus()
      inputRef.click()
    }
  }

  const history = useHistory()
  useEffect(() => {
    if (!scrollTo) return

    if (scrollTo === 'title') {
      scrollAndFocus(titleInputRef)
    } else if (scrollTo === 'tags') {
      scrollAndFocus(tagsSelectRef)
    } else if (scrollTo === 'article_type') {
      scrollAndFocus(articleTypeSelectRef)
    }

    removeQueryParam(history, 'scrollTo')
  }, [scrollTo])

  const previewDisabled = !workingCopy.title && isHtmlStringEmpty(workingCopy.content)

  return (
    <div className='ArticleContentEditor'>
      {isPreviewing ? (
        <Article
          article={previewableArticle}
          tracking={false}
          onTogglePreview={togglePreview}
          isPreviewing
        />
      ) : (
        <Card className='mb-4' cardBodyClassName='pb-0'>
          <div className='position-relative mb-2'>
            <ArticleTypeSelect
              value={articleType}
              updateWorkingCopy={updateWorkingCopy}
              disabled={isGroupPost}
              ref={articleTypeSelectRef}
            />

            <div className={classNames('position-absolute top-0 right-0 d-flex align-items-center pointer', { 'disabled-link': previewDisabled })} onClick={togglePreview}>
              <CdnSvg className='PreviewIcon' src='/images/eyeOpenIcon.svg' />
              <span className='ml-1 link-color text-small'>
                {I18NCommon('preview')}
              </span>
            </div>
          </div>

          <input
            ref={titleInputRef}
            className='ArticleTitleInput mb-3 p-0 border-0'
            value={title}
            onChange={e => updateWorkingCopy({ title: e.target.value })}
            placeholder={I18N('add_a_title')}
            autoFocus
          />

          {isGroupPost ? (
            <GroupArticleByLineEditor workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} />
          ) : (
            <ArticleByLineEditor workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} />
          )}

          <TiptapEditor
            key={id}
            onChange={html => updateWorkingCopy({ content: html })}
            html={content}
            configuration={{
              stickyToolbar: true,
              videosEnabled: true,
              isZoomEnabled: settings.zoom?.enabled,
              aiEnabled: settings.aiTextGeneration?.enabled,
              socialShareEnabled,
              isEditingTemplate,
            }}
            richTextId={workingCopy?.contentId}
            toolbarItems={ARTICLES_TOOLBAR}
            className='mb-3'
          />

          <div
            className='w-100 d-flex justify-content-between align-items-start mb-3'
          >
            <ArticleTagsSelect ref={tagsSelectRef} value={tags} onChange={tags => updateWorkingCopy({ tags })} />

            {settings.comments.articles.enabled && (
              <ToggleSwitch
                textPosition='after'
                checked={commentable}
                onChange={() => updateWorkingCopy({ commentable: !commentable })}
                text={I18N('enable_comments')}
              />
            )}
          </div>
        </Card>
      )}
    </div>
  )
}

export default ArticleContentEditor
