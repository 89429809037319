import React from 'react'
import { useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice from 'redux/slices/surveys/forms'
import { Link } from 'react-router-dom'
import SmartTable from 'components/common/tables/smartTable'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import SurveyFormType from 'types/surveys/form'
import FormStatusLabel from 'components/admin/surveys/formStatusLabel'

const I18N = i18nPath('views.admin.surveys')

const FormsTable = ({
  handlePaginationClick,
}) => {
  const forms = useSelector(surveyFormSlice.selectors.getForms())
  const { queryParams } = useSelector(surveyFormSlice.selectors.getMetaData())

  const {
    page, totalPages, total, perPage,
  } = queryParams

  const columns = [
    {
      header: I18N('list.name'),
      accessor: (d: SurveyFormType) => <Link to={d?.adminDisplayPath}>{d?.name}</Link>,
    },
    {
      header: I18N('list.owner'),
      accessor: (d: SurveyFormType) => <EmployeeLinkWithPopover user={d?.owner} userLinkClassName='link-color text-primary' />,
    },
    {
      header: I18N('list.status'),
      accessor: (d: SurveyFormType) => <FormStatusLabel status={d?.status} />,
    },
  ]

  return (
    <SmartTable
      className='white-bg-table FormsTable'
      data={forms}
      columns={columns}
      showPagination={true}
      page={page}
      pages={totalPages}
      totalCount={total}
      perPage={perPage}
      onPaginationClick={handlePaginationClick}
    />
  )
}

export default FormsTable
