import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import surveyFormSlice from 'redux/slices/surveys/forms'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import { LoadingContainer } from 'components/common/loadingContainer'
import FormsTable from 'components/admin/surveys/formsTable'
import { ButtonLink } from 'components/common/buttons'
import BetaTag from 'components/common/tags/betaTag'
import CdnSvg from 'components/common/cdnSvg'

const searchIconPath = '/images/searchIcon.svg'
const I18N = i18nPath('views.admin.surveys')

const FormListPage = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(surveyFormSlice.selectors.getMetaData())

  const fetchForms = (paginationParams = {}) => {
    dispatch(surveyFormSlice.asyncActions.admin.fetchAll({
      page: 1,
      perPage: 10,
      q: '',
      ...paginationParams,
      sortBy: 'created_at',
      sortDir: 'desc',
    }))
  }

  const handlePaginationClick = (paginationParams) => {
    fetchForms(paginationParams)
  }

  const handleSearchQueryChange = (newQuery: string) => {
    fetchForms({ q: newQuery })
  }

  useEffect(() => {
    fetchForms()
  }, [])

  return (
    <div className='FormListPage'>
      <header className='AdminHeader d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <h2 className='mb-0'>{I18N('surveys')}</h2>
          <ButtonLink
            to='/admin/surveys/new'
          >
            {I18N('create_new_survey')}
          </ButtonLink>
        </div>
      </header>
      <main className='AdminContent'>
        <AsyncSearchInput
          placeholder={I18NCommon('search_by_name')}
          onKeyUp={handleSearchQueryChange}
          className='w-40 mb-4'
          icon={<CdnSvg src={searchIconPath} />}
        />
        <LoadingContainer isLoading={isLoading} useCirclesLoadingIndicator>
          <FormsTable
            handlePaginationClick={handlePaginationClick}
          />
        </LoadingContainer>
      </main>
    </div>
  )
}

export default FormListPage
