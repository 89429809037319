import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import messageMobileApp from 'utils/messageMobileApp'
import { ReduxState } from 'redux/redux'

const useUpdateMobileLastLoggedData = () => {
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const imageUrls = useSelector((state: ReduxState) => state.imageUrls)

  useEffect(() => {
    // Be REALLY careful in changing this. Make sure mobile will continue working
    // Plus, if you really want to change this, DONT just remove the field.
    // Instead, add the new field and keep the old one, since its really hard
    // to guarantee mobile apps are updated to the lastest version.
    // Add a comment that the field is deprecated and should not be used anymore.
    const value = {
      user: _.pick(currentUser, ['id', 'preferredName', 'email', 'primaryPhotoUrl']),
      company: {
        ..._.pick(currentCompany, ['id', 'appName']),
        backgroundColor: currentCompany?.customStyles?.variables?.colors?.backgroundColor,
        logoUrl: imageUrls.companyLogo,
      },
    }

    messageMobileApp({ action: 'lastLoggedDataUpdated', value })
  }, [])
}

export default useUpdateMobileLastLoggedData
