import React, { useEffect } from 'react'

import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import Adoption from 'components/analytics/overview/adoption'
import ModuleUsage from 'components/analytics/overview/moduleUsage'
import MostPopularModules from 'components/analytics/overview/mostPopularModules'
import OverviewEngagement from 'components/analytics/overview/overviewEngagement'
import OverviewSummary from 'components/analytics/overview/OverviewSummary'
import { useDispatch } from 'react-redux'
import engagementAnalyticsSlice from 'redux/slices/analytics/engagement'
import { RootSidebar } from 'components/common/sidebar'


const OverviewTab = () => {
  const {
    targetingRules,
    completedTargetingRules,
    setTargetingRules,
    setGroupBy,
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    groupBy,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(engagementAnalyticsSlice.asyncActions.admin.fetchAdoption(
      { periodStart, periodEnd, targetingRules: completedTargetingRules }
    ))
  }, [periodStart, periodEnd, completedTargetingRules])

  return (
    <main className='OverviewTab'>
      <TabHeader
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        setGroupBy={setGroupBy}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      <OverviewSummary
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />

      <OverviewEngagement
        groupBy={groupBy}
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />

      <ModuleUsage
        groupBy={groupBy}
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />

      <MostPopularModules
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />

      <Adoption
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />
    </main>
  )
}

export default OverviewTab
