import React from 'react'
import { useParams } from 'react-router-dom'

import stepTemplateMap from 'utils/journeys/stepTemplateMap'
import useStepTemplateManagement from 'components/admin/journeys/hooks/useStepTemplateManagement'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import StepTemplateHeader from 'pages/admin/journeys/step_templates/stepTemplateHeader'
import { i18nPath } from 'utils/i18nHelpers'
import InactiveCorrespondentBanner from 'components/admin/journeys/common/inactiveCorrespondentBanner'

const I18N = i18nPath('views.admin.journeys.step_templates.edit_page')

const EditStepTemplatePage = () => {
  const { stepTemplateType } = useParams()
  const stepTemplateConfig = stepTemplateMap[stepTemplateType]

  const {
    FormComponent,
    entityTemplateAttributes,
    fetchApiPath,
    updateApiPath,
    interpolatedFields,
    nonRequiredFields,
    postTriggerNames = [],
    HelpCard,
  } = stepTemplateConfig


  const {
    workingCopy,
    updateWorkingCopy,
    handleSave,
    handleNameSave,
    hasChanges,
    journeyBlueprint,
    isFetching,
    isSaving,
    stepTemplate,
  } = useStepTemplateManagement({
    entityTemplateAttributes,
    updateApiPath,
    fetchApiPath,
    interpolatedFields,
  })

  const templatePostTriggerNames = _.isArray(postTriggerNames) ? postTriggerNames : postTriggerNames(workingCopy)

  if (isFetching || workingCopy.triggerMilestone === undefined) {
    return <CirclesLoadingIndicator />
  }

  const isToCorrespondentInactive = workingCopy?.toCorrespondentAttributes?.user?.active === false
  const isFromCorrespondentInactive = workingCopy?.fromCorrespondentAttributes?.user?.active === false
  const hasInactiveCorrespondent = isToCorrespondentInactive || isFromCorrespondentInactive

  const getBannerText = () => {
    const toCorrespondentName = workingCopy?.toCorrespondentAttributes?.user?.preferredFullName
    const fromCorrespondentName = workingCopy?.fromCorrespondentAttributes?.user?.preferredFullName
    const isSamePerson = toCorrespondentName === fromCorrespondentName

    if (isToCorrespondentInactive && isFromCorrespondentInactive) {
      if (isSamePerson) {
        return I18N('inactive_correspondent', { correspondentName: toCorrespondentName })
      }
      return I18N('inactive_correspondents', { toCorrespondentName, fromCorrespondentName })
    } else if (isToCorrespondentInactive) {
      return I18N('inactive_to_correspondent', { toCorrespondentName })
    } else if (isFromCorrespondentInactive) {
      return I18N('inactive_from_correspondent', { fromCorrespondentName })
    } else {
      return ''
    }
  }

  return (
    <div className='EditStepTemplatePage'>
      <StepTemplateHeader
        journeyBlueprint={journeyBlueprint}
        stepTemplate={stepTemplate}
        workingCopy={workingCopy}
        name={stepTemplate?.name}
        handleNameSave={handleNameSave}
        targetingRules={workingCopy?.targetingRules || {}}
        onSave={handleSave}
        isSaving={isSaving}
        areChangesPresent={hasChanges}
        nonRequiredFields={nonRequiredFields}
        postTriggerNames={templatePostTriggerNames}
      />

      <main className='AdminContent'>
        {hasInactiveCorrespondent && (
          <InactiveCorrespondentBanner text={getBannerText()} />
        )}
        <FormComponent
          onSave={handleSave}
          updateWorkingCopy={updateWorkingCopy}
          workingCopy={workingCopy}
          currentState={stepTemplate?.state}
          journeyBlueprint={journeyBlueprint}
          isSaving={isSaving}
          areChangesPresent={hasChanges}
          helpCard={HelpCard ? <HelpCard journeyBlueprintType={journeyBlueprint?.type} /> : null}
        />
      </main>
    </div>
  )
}

export default EditStepTemplatePage
