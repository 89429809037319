import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import acknowledgementSlice from 'redux/slices/acknowledgements'
import { Button } from 'components/common/buttons'
import { ACKNOWLEDGED } from 'types/acknowledgement'

const I18N = i18nPath('views.article.acknowledgement')

const AcknowledgementSection = ({ article }) => {
  const dispatch = useDispatch()

  const { isSaving } = useSelector(acknowledgementSlice.selectors.getMetaData())
  const { acknowledgementInfo: { status, acknowledgedAt } } = article
  const isAcknowledged = status === ACKNOWLEDGED
  const formattedAcknowledgedAt = acknowledgedAt ? i18nMoment(acknowledgedAt).format('LL') : ''

  const handleAcknowledgement = () => {
    dispatch(acknowledgementSlice.asyncActions.acknowledge(article.id, 'article'))
  }

  return (
    <div className={classNames('AcknowledgementSection d-flex flex-column align-items-center w-100 py-4 my-3', status)}>
      <div className='text-secondary text-small mb-2'>{I18N('article_requires_acknowledgement')}</div>
      <Button
        disabled={isAcknowledged || isSaving}
        showLoadingSpinner={isSaving}
        onClick={handleAcknowledgement}
      >
        {isAcknowledged ? I18N('acknowledged_on', { acknowledgedAt: formattedAcknowledgedAt }) : I18N('i_read_this_article')}
      </Button>
    </div>
  )
}

export default AcknowledgementSection
