import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import stepSlice from 'redux/slices/journeys/steps'
import { useDispatch, useSelector } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import { StepType } from 'types/journeys/step'
import classNames from 'classnames'
import JourneyType from 'types/journeys/journey'
import StepTemplateType from 'types/journeys/stepTemplate'

const I18N = i18nPath('views.admin.journeys.steps.create_link')

interface StepCreateLinkProps {
  className?: string,
  journey?: JourneyType,
  stepTemplate?: StepTemplateType,
}

const StepCreateLink = ({
  className = '',
  journey,
  stepTemplate,
} : StepCreateLinkProps) => {
  const dispatch = useDispatch()
  const stepTemplateId = stepTemplate?.id
  const journeyId = journey?.id
  const [createRequested, setCreateRequested] = useState(false)

  const { isSaving } = useSelector(stepSlice.selectors.getMetaData())

  const onSuccess = () => {
    dispatch(showToastMessage({ message: I18N('succesfully_created'), type: 'success' }))
    setCreateRequested(true)
  }

  const onClick = () => {
    const params = {
      journeyId,
      stepTemplateId,
    }

    dispatch(stepSlice.asyncActions.admin.create(params, onSuccess))
  }

  if (createRequested) {
    return null
  }

  return (
    <span
      className={classNames('StepCreateLink pointer font-weight-600', className)}
      onClick={onClick}
    >
      {isSaving ? '...' : I18N('create_step')}
    </span>
  )
}

export default StepCreateLink
