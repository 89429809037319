import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import CdnSvg from 'components/common/cdnSvg'

const CorrespondentIconPath = '/images/correspondentIcon.svg'

interface EmployeeOrCorrespondentLinkProps {
  user?: any
  correspondent?: any
  className?: string
  linkToAdmin?: boolean
  textClassName?: string
  avatarClassName?: string
}

const EmployeeOrCorrespondentLink = ({
  user,
  correspondent,
  className,
  linkToAdmin = false,
  textClassName,
  avatarClassName = 'mr-2',
}: EmployeeOrCorrespondentLinkProps) => {
  // If there's no user defined it means that we should show the correspondent
  if (!user) {
    return (
      <div className={classNames('EmployeeOrCorrespondentLink d-flex align-items-center', className)}>
        <CdnSvg className={classNames('CorrespondentIcon', avatarClassName)} src={CorrespondentIconPath} />
        <span className={classNames('font-weight-500', textClassName)}>{correspondent && correspondent.roleText}</span>
      </div>
    )
  }

  const link = linkToAdmin ? `/admin/users/${user?.id}/journeys` : `/profile/${user?.username}`

  return (
    <div className={classNames('EmployeeOrCorrespondentLink d-flex align-items-center', className)}>
      <div>
        <EmployeeThumbnailPhoto employee={user} className={classNames(avatarClassName)} />
      </div>
      <Link
        to={link}
        className={classNames('font-weight-500 text-primary-link', textClassName)}
      >
        {user?.preferredFullName}
      </Link>
    </div>
  )
}

export default EmployeeOrCorrespondentLink
