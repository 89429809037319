import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import React from 'react'
import { Radio, RadioGroup } from 'react-radio-group'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.article_settings.template_display_order_settings')

const TemplateDisplayOrderSettings = ({ connect }) => {
  const { name: companyName } = useCurrentCompany()
  const radioSettings = connect('templates.defaultDisplay')

  return (
    <div>
      <h6>{I18N('title')}</h6>
      <p className='text-secondary text-small'>{I18N('helper_text')}</p>

      <RadioGroup
        className='TemplatesDefaultDisplayRadio d-flex align-items-center justify-content-start'
        name='templates_default_display'
        selectedValue={radioSettings.value}
        onChange={radioSettings.onChange}
      >
        <Radio className='mr-2' value='cleary_templates' /> {I18N('cleary_templates')}
        <Radio className='mr-2 ml-3' value='company_templates' /> {I18N('company_templates', { companyName })}
      </RadioGroup>
    </div>
  )
}

export default TemplateDisplayOrderSettings
