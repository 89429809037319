import React from 'react'

import classNames from 'classnames'

import Tooltip from 'components/common/clearyTooltip'

const DatePickerDecorator = ({
  label, helpText, type, children, className, tooltip, errors,
}) => {
  const hasError = Boolean(errors?.[type])

  return (
    <div className={classNames(`DatePickerDecorator DatePicker--${type} d-flex align-items-start justify-content-center flex-column mb-5`, { hasError }, className)}>
      <span className='d-flex'>
        <label className='d-flex align-items-center justify-content-center'>
          <span className='DatePicker-circle' />
          <span>{label}</span>
        </label>
        {tooltip && (
          <Tooltip placement='bottom' className='ml-2 mb-1 DateTooltip'>
            <span className='text-small'>{tooltip}</span>
          </Tooltip>
        )}
      </span>
      {children}
      {!hasError && helpText && <span className='HelpText'>{helpText}</span>}
      {hasError && <span className='text-error'>{errors[type]}</span>}
    </div>
  )
}

export default DatePickerDecorator
