import React from 'react'

import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import moment from 'moment'

const I18N = i18nPath('views.profile.schedule')

const getTimeBlock = (start, end, isAllDay) => {
  const startDate = i18nMoment(start)
  const day = startDate.format('ddd')
  const startingHour = startDate.format('h:mmA')
  const endingHour = moment(end).format('h:mmA')


  if (isAllDay) {
    return `${day} - ${I18N('all_day')}`
  }

  if (day && startingHour && endingHour) {
    return `${day} ${startingHour} - ${endingHour}`
  }

  return ''
}

const CalendarEvent = ({ event }) => {
  const {
    htmlLink, summary = I18N('busy'), end, start, isAllDay,
  } = event

  let summaryToDisplay = summary

  // if a calendar event summary is coming back from backend as 'cleary_busy'
  // replace it with a translation for 'busy'
  if (summary === 'cleary_busy') {
    summaryToDisplay = I18N('busy')
  }

  return (
    <div className='CalendarEvent border-radius p-3 mb-2'>
      <div>
        <a href={htmlLink} className='text-primary-link font-weight-normal' target='_blank' rel='noopener noreferrer'>
          {summaryToDisplay}
        </a>
      </div>
      <div className='text-small text-secondary'>{getTimeBlock(start, end, isAllDay)}</div>
    </div>
  )
}

export default CalendarEvent
