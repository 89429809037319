import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import pickWithTargetingRules from 'redux/slices/utils/pickWithTargetingRules'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { AdoptionType, EngagementByPeriodType } from 'types/analytics/engagement'


const buildEngagementPayload = pickWithTargetingRules([
  'appModules',
  'eventNames',
  'groupBy',
  'periodEnd',
  'periodStart',
  'trackableId',
  'trackableType',
  'type',
  'unique',
])

const buildAdoptionPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
])

const buildActivityCountPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
  'type',
  'eventNames',
  'trackableId',
  'trackableType',
  'unique',
  'appModules',
])

const defaultAdoption = {
  contributorsCount: 0,
  consumersCount: 0,
  noUsageCount: 0,
  totalUsersCount: 0,
  usersReadArticleEmailCount: 0,
  weeklyActivityAverageUsersCount: 0,
  contributorsPercent: 0,
  consumersPercent: 0,
  noUsagePercent: 0,
  usersReadArticleEmailPercent: 0,
  weeklyActivityAverageUsersPercent: 0,
}

export type EngagementAnalyticsState = {
  meta: {
    engagement: {
      [bucket: string]: DefaultMetaType
    },
    activityCount: {
      [bucket: string]: DefaultMetaType
    },
    adoption: DefaultMetaType
  },
  data: {
    engagement: {
      [bucket: string]: {
        byPeriod: EngagementByPeriodType[]
      }
    },
    activityCount: {
      [bucket: string]: { data: number }
    }
    adoption: AdoptionType
  },
}

export const initialState: EngagementAnalyticsState = {
  meta: {
    engagement: {},
    activityCount: {},
    adoption: defaultMeta,
  },
  data: {
    engagement: {},
    activityCount: {},
    adoption: defaultAdoption,
  },
}

const engagementAnalyticsSlice = createSlice({
  name: 'engagementAnalytics',
  initialState,
  reducers: {
    setDataWithBucket(state, action) {
      const { type, bucket, value } = action.payload
      _.set(state, ['data', type, bucket], value)
    },

    setMetaWithBucket(state, action) {
      const {
        type, bucket, key, value,
      } = action.payload

      const newMeta = {
        ...(_.get(state, ['meta', type, bucket]) || defaultMeta),
        [key]: value,
      }

      _.set(state, ['meta', type, bucket], newMeta)
    },

    setData(state, action) {
      const { type, value } = action.payload
      _.set(state, ['data', type], value)
    },

    setMeta(state, action) {
      const { type, key, value } = action.payload

      const newMeta = {
        ...(_.get(state, ['meta', type]) || defaultMeta),
        [key]: value,
      }

      _.set(state, ['meta', type], newMeta)
    },
  },
})

const asyncActions = {
  admin: {
    fetchEngagement: (bucket: string, params = {}) => async (dispatch) => {
      const type = 'engagement'

      try {
        dispatch(engagementAnalyticsSlice.actions.setMetaWithBucket({
          type, bucket, key: 'isLoading', value: true,
        }))

        const response = await API.admin.analytics.engagement.fetchEngagement(
          buildEngagementPayload(params))

        dispatch(engagementAnalyticsSlice.actions.setDataWithBucket({
          type, bucket, value: { byPeriod: response.data.data },
        }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(engagementAnalyticsSlice.actions.setMetaWithBucket({
          type, bucket, key: 'error', value: getResponseOrThrow(error),
        }))
      } finally {
        dispatch(engagementAnalyticsSlice.actions.setMetaWithBucket({
          type, bucket, key: 'isLoading', value: false,
        }))
      }
    },

    fetchActivityCount: (bucket: string, params = {}) => async (dispatch) => {
      const type = 'activityCount'
      try {
        dispatch(engagementAnalyticsSlice.actions.setMetaWithBucket({
          type, bucket, key: 'isLoading', value: true,
        }))

        const response = await API.admin.analytics.engagement.fetchActivityCount(buildActivityCountPayload(params))

        dispatch(engagementAnalyticsSlice.actions.setDataWithBucket({
          type, bucket, value: response.data,
        }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(engagementAnalyticsSlice.actions.setMetaWithBucket({
          type, bucket, key: 'error', value: getResponseOrThrow(error),
        }))
      } finally {
        dispatch(engagementAnalyticsSlice.actions.setMetaWithBucket({
          type, bucket, key: 'isLoading', value: false,
        }))
      }
    },

    fetchAdoption: (params = {}) => async (dispatch) => {
      const type = 'adoption'

      try {
        dispatch(engagementAnalyticsSlice.actions.setMeta({
          type, key: 'isLoading', value: true,
        }))

        const response = await API.admin.analytics.engagement.fetchAdoption(
          buildAdoptionPayload(params))

        dispatch(engagementAnalyticsSlice.actions.setData({
          type, value: response.data,
        }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(engagementAnalyticsSlice.actions.setMeta({
          type, key: 'error', value: getResponseOrThrow(error),
        }))
      } finally {
        dispatch(engagementAnalyticsSlice.actions.setMeta({
          type, key: 'isLoading', value: false,
        }))
      }
    },
  },
}

const selectors = {
  getEngagementByPeriod: (bucket: string) => (state: ReduxState): EngagementByPeriodType[] => (
    _.get(state, ['engagementAnalytics', 'data', 'engagement', bucket, 'byPeriod']) || []
  ),

  getEngagementMeta: (bucket: string) => (state: ReduxState): DefaultMetaType => _.get(state, ['engagementAnalytics', 'meta', 'engagement', bucket]) || defaultMeta,

  getActivityCount: (bucket: string) => (state: ReduxState): number => _.get(state, ['engagementAnalytics', 'data', 'activityCount', bucket, 'data']) || 0,

  getActivityCountMeta: (bucket: string) => (state: ReduxState): DefaultMetaType => _.get(state, ['engagementAnalytics', 'meta', 'activityCount', bucket]) || defaultMeta,

  getAdoption: () => (state: ReduxState) => _.get(state, ['engagementAnalytics', 'data', 'adoption']),

  getMeta: (type: string) => (state: ReduxState): DefaultMetaType => _.get(state, ['engagementAnalytics', 'meta', type]) || defaultMeta,
}

export default {
  ...engagementAnalyticsSlice,
  selectors,
  asyncActions,
}
