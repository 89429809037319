import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import pickWithTargetingRules from 'redux/slices/utils/pickWithTargetingRules'
import API from 'services/api'
import appSignal from 'services/appSignal'
import { MostPopularModulesType, UsageModulesType } from 'types/analytics/modules'

const buildModulePayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
  'groupBy',
])

export interface ModuleAnalyticsState {
  mostPopular: MostPopularModulesType
  usage: UsageModulesType
  meta: {
    mostPopular: DefaultMetaType
    usage: DefaultMetaType
  }
}

export const initialState: ModuleAnalyticsState = {
  mostPopular: {
    data: [],
  },
  usage: {
    data: [],
  },
  meta: {
    mostPopular: defaultMeta,
    usage: defaultMeta,
  },
}

const moduleAnalyticsSlice = createSlice({
  name: 'moduleAnalytics',
  initialState,
  reducers: {
    setData(state, action) {
      const { type, data } = action.payload
      state[type] = data
    },

    setIsLoading(state, action) {
      const { type, isLoading } = action.payload
      state.meta[type].isLoading = isLoading
    },

    setError(state, action) {
      const { type, error } = action.payload
      state.meta[type].error = error
    },
  },
})

const asyncActions = {
  admin: {
    fetchMostPopular: (params = {}) => async (dispatch) => {
      try {
        dispatch(moduleAnalyticsSlice.actions.setIsLoading({ type: 'mostPopular', isLoading: true }))

        const response = await API.admin.analytics.modules.mostPopular(buildModulePayload(params))

        dispatch(moduleAnalyticsSlice.actions.setData({ type: 'mostPopular', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(moduleAnalyticsSlice.actions.setError({ type: 'mostPopular', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(moduleAnalyticsSlice.actions.setIsLoading({ type: 'mostPopular', isLoading: false }))
      }
    },
    fetchUsage: (params = {}) => async (dispatch) => {
      try {
        dispatch(moduleAnalyticsSlice.actions.setIsLoading({ type: 'usage', isLoading: true }))

        const response = await API.admin.analytics.modules.usage(buildModulePayload(params))

        dispatch(moduleAnalyticsSlice.actions.setData({ type: 'usage', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(moduleAnalyticsSlice.actions.setError({ type: 'usage', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(moduleAnalyticsSlice.actions.setIsLoading({ type: 'usage', isLoading: false }))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.moduleAnalytics.meta,

  getMostPopular: () => (state: ReduxState) => state.moduleAnalytics.mostPopular,

  getUsage: () => (state: ReduxState) => state.moduleAnalytics.usage,
}

export default {
  ...moduleAnalyticsSlice,
  asyncActions,
  selectors,
}
