import React from 'react'
import classNames from 'classnames'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import CheckIcon from 'components/icons/checkIcon'
import { ButtonSmallNarrow } from 'components/common/buttons'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.integrations_page.integration_card')

const IntegrationInstalledComponent = ({
  onUninstall,
  isUninstalling,
  uninstallationComponent,
  installationMessage,
  uninstallationButtonText = I18NCommon('uninstall'),
}) => (
  <>
    <div className='d-flex align-items-center mb-1'>
      <CheckIcon fillColor='var(--success-color)' className='CheckIcon mr-1' width='10px' height='10px' />
      <div className='text-success text-small'>{installationMessage}</div>
    </div>
    {uninstallationComponent}
    {onUninstall && (
      <div className='d-flex justify-content-end'>
        <ButtonSmallNarrow
          variant='danger'
          onClick={onUninstall}
          disabled={isUninstalling}
          showLoadingSpinner={isUninstalling}
        >
          {uninstallationButtonText}
        </ButtonSmallNarrow>
      </div>
    )}
    {!uninstallationComponent && !onUninstall && (
      <div className='text-secondary text-small'>{I18N('make_changes')}</div>
    )}
  </>
)

interface IntegrationCardProps {
  id: string
  logo: React.ReactNode
  isInstalled: boolean
  installationComponent?: React.ReactNode
  uninstallationComponent?: React.ReactNode
  onUninstall?: () => void
  isUninstalling?: boolean
  customInstallationMessage?: string
  docLink?: string
  docTitle?: string
  blockLink?: React.ReactNode
  uninstallationButtonText?: string
  bodyComponent?: React.ReactNode
  alwaysShowInstallationComponent?: boolean
}

const IntegrationCard = ({
  id,
  logo,
  isInstalled,
  installationComponent,
  uninstallationComponent,
  onUninstall,
  isUninstalling,
  customInstallationMessage,
  docLink,
  docTitle,
  blockLink,
  uninstallationButtonText,
  bodyComponent,
  alwaysShowInstallationComponent,
}: IntegrationCardProps) => {
  const { name, appName } = useCurrentCompany()

  const integrationName = I18N(`${id}.name`)

  const showInstallationComponent = alwaysShowInstallationComponent || !isInstalled

  return (
    <Card
      className={classNames('IntegrationCard', { isInstalled })}
      title={(
        <div className='d-flex align-items-center'>
          <div className='IntegrationLogo mr-2'>{logo}</div>
          <h5 className='mb-0'>{integrationName}</h5>
        </div>
      )}
    >
      <div>{I18N(`${id}.description`, { appName, companyName: name })}</div>
      {docLink && docTitle && (
        <div className='mt-2'>
          <a
            className='text-primary-link'
            href={docLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            {docTitle}
          </a>
        </div>
      )}
      <hr />

      {bodyComponent}

      {isInstalled && (
        <>
          <IntegrationInstalledComponent
            uninstallationComponent={uninstallationComponent}
            onUninstall={onUninstall}
            uninstallationButtonText={uninstallationButtonText}
            isUninstalling={isUninstalling}
            installationMessage={customInstallationMessage || I18N(`${id}.successfully_installed`)}
          />
          {blockLink}
        </>
      )}
      {showInstallationComponent && (
        installationComponent || <div className='text-secondary text-small'>{I18N('enable_integration', { integrationName })}</div>
      )}
    </Card>
  )
}

export default IntegrationCard
