import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'

import API from 'services/api'
import { i18nPath, i18nFormat, i18nDisplayDateWithoutYear } from 'utils/i18nHelpers'

import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import Card from 'components/common/card'
import CelebrationSocial from 'components/celebrations/celebrationSocial'
import ClickWrapper from 'components/common/clickWrapper'
import CelebrationCardHeader from 'components/celebrations/celebrationCardHeader'

const I18N = i18nPath('views.celebrations.card')
const I18NDropdown = i18nPath('views.feed.dropdown_menu')

const timeKey = (celebration) => {
  if (celebration.upcoming) {
    return 'upcoming'
  }

  return moment().isAfter(celebration.eventDate, 'day') ? 'past' : 'today'
}

const getI18nPrefix = (celebration, isCurrentUser) => {
  const userKey = isCurrentUser ? 'mine' : 'coworker'

  return `${celebration.type}.${timeKey(celebration)}.${userKey}`
}

const Instructions = ({ celebration, isCurrentUser }) => {
  const i18nKey = `${getI18nPrefix(celebration, isCurrentUser)}.instructions`
  const text = I18N(i18nKey)

  return text ? <div className='mb-1 text-center ClickWrapperTargetContainer'>{text}</div> : null
}

const Title = ({ celebration, isCurrentUser, companyName }) => {
  const userPopover = <EmployeeLinkWithPopover user={celebration.user} hideImage />
  const i18nKey = `${getI18nPrefix(celebration, isCurrentUser)}.title`

  if (isCurrentUser) {
    return I18N(i18nKey, {
      name: celebration.user?.preferredName,
      companyName,
      count: celebration.workAnniversaryYears || 1,
    })
  }

  return i18nFormat(
    I18N(i18nKey),
    userPopover,
    i18nDisplayDateWithoutYear(celebration.eventDate),
    celebration.workAnniversaryYears,
    companyName
  )
}

const Description = ({ celebration, companyName, isCurrentUser }) => {
  const data = {
    companyName,
    name: celebration.user?.preferredName,
    count: celebration.workAnniversaryYears || 1,
    date: i18nDisplayDateWithoutYear(celebration.eventDate),
  }

  const i18nKey = `${getI18nPrefix(celebration, isCurrentUser)}.description`
  const text = I18N(i18nKey, data)

  return text ? <div className='mb-1 text-center text-small ClickWrapperTargetContainer'>{text}</div> : null
}

const ExcludedCardConfirmation = () => {
  const settingsLink = <Link to='/settings/people'>{I18NDropdown('settings')}</Link>

  return <Card>{i18nFormat(I18NDropdown('excluded_confirmation_settings_link'), settingsLink)}</Card>
}

const CelebrationCard = ({
  celebration,
  feedItem,
  currentUser,
  companyName,
  blockClicks = false,
}) => {
  const [showExcludedConfirmation, setShowExcludedConfirmation] = useState(false)
  const { user, typeWithPrefix } = celebration
  const isCurrentUser = currentUser.id === user.id

  const handleExcludeFromFeed = () => {
    API.home.excludeFromFeed(feedItem)
    setShowExcludedConfirmation(true)
  }

  if (isCurrentUser && ['upcoming_birthday', 'upcoming_new_employee_welcome'].includes(typeWithPrefix)) {
    return null
  }

  if (!user.active) {
    return null
  }

  if (showExcludedConfirmation) {
    return <ExcludedCardConfirmation />
  }

  return (
    <ClickWrapper to={`/people/celebrations/${celebration.id}?source=feed`}>
      <Card className={classNames('CelebrationCard', { blockClicks })} cardBodyClassName='ClickWrapperTargetContainer p-0'>
        <CelebrationCardHeader
          celebration={celebration}
          feedItem={feedItem}
          isCurrentUser={isCurrentUser}
          onExcludeFromFeed={handleExcludeFromFeed}
        />

        <div className='p-3 ClickWrapperTargetContainer'>
          <Instructions celebration={celebration} isCurrentUser={isCurrentUser} />
          <h5 className='mb-1 text-center ClickWrapperTargetContainer'>
            <Title celebration={celebration} isCurrentUser={isCurrentUser} companyName={companyName} />
          </h5>
          <Description celebration={celebration} isCurrentUser={isCurrentUser} companyName={companyName} />

          <CelebrationSocial celebration={celebration} isCurrentUser={isCurrentUser} />
        </div>
      </Card>
    </ClickWrapper>
  )
}

export default CelebrationCard
