import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import moment from 'moment'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError, getValidationErrors } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import {
  DEFAULT_INTRODUCTION_TASK_CALENDAR_EVENT_TITLE,
  DEFAULT_INTRODUCTION_TASK_INSTRUCTIONS,
  DEFAULT_INTRODUCTION_TASK_NAME,
  defaultStepTemplateAttributes, defaultTaskableTemplateAttributes,
  getIntroductionCalendarEventDescriptionText,
  getIntroductionDefaultStepName
} from 'utils/journeys/templatesDefaultHelpers'
import { buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'

const I18N = i18nPath('views.admin.journeys.introduction_templates')

export const INTRODUCTION_TEMPLATE_ATTRIBUTES = [
  'allowSilenceNotifications',
  'automaticallyScheduleCalendarInvites',
  'calendarEventDescription',
  'calendarEventTitle',
  'createVideoConference',
  'dueAfterNumDays',
  'dueMilestone',
  'dueTime',
  'fallbackTimezone',
  'hourWindowEnd',
  'hourWindowStart',
  'instructions',
  'maxInvitesPerDay',
  'meetingDuration',
  'name',
  'notifyViaEmail',
  'notifyViaMsTeams',
  'notifyViaSlack',
  'notifyViaSms',
  'useRecipientNotificationPreferences',
]

export const INTRODUCTION_TEMPLATE_INTERPOLATED_FIELDS = ['instructions', 'name', 'calendarEventTitle', 'calendarEventDescription']

export const defaultWorkingCopy = journeyBlueprintType => ({
  ...defaultStepTemplateAttributes(journeyBlueprintType),
  ...defaultTaskableTemplateAttributes(journeyBlueprintType),
  name: DEFAULT_INTRODUCTION_TASK_NAME[journeyBlueprintType],
  instructions: DEFAULT_INTRODUCTION_TASK_INSTRUCTIONS[journeyBlueprintType],
  fromCorrespondentAttributes: { role: 'journey_admin' },
  toCorrespondentAttributes: { role: 'manager' },
  meetingDuration: 30,
  calendarEventTitle: DEFAULT_INTRODUCTION_TASK_CALENDAR_EVENT_TITLE[journeyBlueprintType],
  calendarEventDescription: getIntroductionCalendarEventDescriptionText(journeyBlueprintType),
  hourWindowStart: moment.utc('01-01-2020 9:00', 'MM-DD-YYYY h:mm').format(),
  hourWindowEnd: moment.utc('01-01-2020 17:00', 'MM-DD-YYYY h:mm').format(),
  fallbackTimezone: 'America/Los_Angeles',
  automaticallyScheduleCalendarInvites: true,
  maxInvitesPerDay: 2,
  createVideoConference: true,
  stepTemplateName: getIntroductionDefaultStepName(journeyBlueprintType),
})

export const initialState = {
  meta: {
    ...defaultMeta,
  },
}

const introductionTemplateSlice = createSlice({
  name: 'introductionTemplates',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

const buildIntroductionTemplatePayload = (introductionTemplate) => {
  const introductionTemplateAttrs = _.pick(introductionTemplate, [...INTRODUCTION_TEMPLATE_ATTRIBUTES, 'id'])

  if (introductionTemplate.stepTemplateAttributes) {
    introductionTemplateAttrs.stepTemplateAttributes = buildStepTemplatePayload(
      introductionTemplate.stepTemplateAttributes
    )
  }

  return introductionTemplateAttrs
}

_.assign(introductionTemplateSlice, {
  asyncActions: {
    admin: {
      createIntroductionTemplate: (introductionTemplate, onSuccess = () => null) => async (dispatch) => {
        dispatch(introductionTemplateSlice.actions.isSaving(true))

        try {
          const response = await API.admin.introductionTemplates.create(
            buildIntroductionTemplatePayload(introductionTemplate)
          )
          dispatch(entitySlice.actions.add({ data: response.data }))
          const introductionTemplateId = response.data.data.id

          if (introductionTemplate?.stepTemplateAttributes?.state === 'draft') {
            dispatch(showToastMessage({ message: I18N('draft_saved'), type: 'success' }))
          } else {
            dispatch(showToastMessage({ message: I18N('successfully_created'), type: 'success' }))
          }

          onSuccess(introductionTemplateId)
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(introductionTemplateSlice.actions.setError(error))
          dispatch(showToastMessage({ message: getValidationErrors(error), type: 'error' }))
        } finally {
          dispatch(introductionTemplateSlice.actions.isSaving(false))
        }
      },

      fetchIntroductionTemplate: id => async (dispatch) => {
        dispatch(introductionTemplateSlice.actions.isLoading(true))
        dispatch(introductionTemplateSlice.actions.isNotFound(false))

        try {
          const response = await API.admin.introductionTemplates.fetch(id)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(introductionTemplateSlice.actions.isNotFound(true))
        } finally {
          dispatch(introductionTemplateSlice.actions.isLoading(false))
        }
      },

      updateIntroductionTemplate: (workingCopy, isPublishing = false) => async (dispatch) => {
        dispatch(introductionTemplateSlice.actions.isSaving(true))

        try {
          const response = await API.admin.introductionTemplates.update(
            buildIntroductionTemplatePayload(workingCopy)
          )
          dispatch(entitySlice.actions.update({ data: response.data }))

          if (isPublishing) {
            dispatch(showToastMessage({ message: I18N('successfully_published'), type: 'success' }))
          }
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(introductionTemplateSlice.actions.setError(error))
          dispatch(showToastMessage({ message: getValidationErrors(error), type: 'error' }))
        } finally {
          dispatch(introductionTemplateSlice.actions.isSaving(false))
        }
      },
    },
  },
})

_.assign(introductionTemplateSlice, {
  selectors: {
    getMetaData: () => state => state.introductionTemplates.meta,

    getIntroductionTemplate: id => state => build(state.entities, 'introductionTemplate', id),
  },
})

export default introductionTemplateSlice
