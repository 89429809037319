import React from 'react'
import ReactSelect from 'components/common/react_select'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.step_templates')

const PriorStepSelector = ({
  journeyBlueprint,
  workingCopy,
  updateWorkingCopy,
  isDisabled,
}) => {
  const stepTemplateId = workingCopy?.stepTemplateId
  const parentId = workingCopy?.parentId
  const stepTemplates = journeyBlueprint?.stepTemplates || []

  const stepTemplate = stepTemplates.find(stepTemplate => stepTemplate.id === stepTemplateId)
  const notAllowedStepTemplateIds = [
    stepTemplateId,
    ...stepTemplate?.descendantIds || [],
  ]

  // eslint-disable-next-line max-len
  const selectedStepTemplate = stepTemplates.find(stepTemplate => stepTemplate.id === parentId)

  const onParentChange = (stepTemplate) => {
    updateWorkingCopy({
      parentId: stepTemplate?.id,
    })
  }

  return (
    <div className='PriorStepSelector my-3'>
      <label className='required-form-label'>
        {I18N('choose_step')}
      </label>
      <ReactSelect
        value={selectedStepTemplate}
        onChange={onParentChange}
        options={stepTemplates.filter(stepTemplate => !notAllowedStepTemplateIds.includes(stepTemplate.id))}
        isSearchable
        isClearable={false}
        isDisabled={isDisabled}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
      />
    </div>
  )
}

export default PriorStepSelector
