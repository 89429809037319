import classNames from 'classnames'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import ReactSelect from 'components/common/react_select'
import React, { useMemo } from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.companies')

interface Props {
  value: string | null
  onChange: (value: string) => void
  isDisabled?: boolean
  placeholder?: string
  isClearable?: boolean
  className?: string
}


// make sure to change Company::status enum if you're changing this
const allOptions = [{
  value: 'active',
  label: I18N('status_selector.active'),
}, {
  value: 'trial',
  label: I18N('status_selector.trial'),
}, {
  value: 'churn',
  label: I18N('status_selector.churn'),
}, {
  value: 'trial_expired',
  label: I18N('status_selector.trial_expired'),
}]

const CompanyStatusSelector = ({
  onChange, value, className, ...props
}: Props) => {
  const { permissions: { clearyAdmin, trialCompaniesAdmin } } = useCurrentUser()

  const trialOnly = !clearyAdmin && trialCompaniesAdmin

  const options = useMemo(() => (
    trialOnly ? allOptions.filter(option => ['trial', 'trial_expired'].includes(option.value)) : allOptions
  ), [])


  return (
    <ReactSelect
      className={classNames('CompanyStatusSelector', className)}
      options={options}
      value={options.find(option => option.value === value)}
      placeholder={I18NCommon('select_a_type')}
      onChange={option => onChange(option?.value)}
      openMenuOnFocus
      {...props}
    />
  )
}

export default CompanyStatusSelector
