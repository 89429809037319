import { useDispatch, useSelector } from 'react-redux'
import pageSlice from 'redux/slices/pages'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'

const useCollaborativeDraftContentSave = (page) => {
  const dispatch = useDispatch()
  const provider = useSelector(collaborativeEditorSlice.selectors.getProvider())

  const collaborativeEditorDraftContentSave = (html) => {
    const states = Array.from(provider?.awareness?.getStates().values() || {})
    // eslint-disable-next-line max-len
    const contributors = states.filter((state: any) => page.draftContentLastUpdatedAt < state?.lastContributedAt)
    const contributorIds = contributors.map((state: any) => state?.user?.id).filter(Boolean)

    dispatch(pageSlice.asyncActions.autosave({
      id: page.id,
      draftContent: html,
      contributorIds,
    }))
  }

  return collaborativeEditorDraftContentSave
}

export default useCollaborativeDraftContentSave
