import React, { useEffect, useState } from 'react'

import { Popover } from 'reactstrap'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import FormCheck from 'components/form_fields/formCheck'
import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'

interface FilterButtonAndModalProps {
  showSelectedUserAsRoot: boolean,
  setShowSelectedUserAsRoot: (foo: boolean) => void,
  resetView: () => void,
}

const I18N = i18nPath('views.orgchart')
const funnelIconPath = '/images/funnelIcon.svg'
const chevronPath = '/images/chevronDownOutline.svg'

const FilterButtonAndModal = ({
  showSelectedUserAsRoot = false,
  setShowSelectedUserAsRoot,
  resetView,
}: FilterButtonAndModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showSelectedUserAsRootWorkingCopy, setShowSelectedUserAsRootWorkingCopy] = useState(showSelectedUserAsRoot)

  const onUpdate = () => {
    setShowSelectedUserAsRoot(showSelectedUserAsRootWorkingCopy)
    setIsModalOpen(false)
  }

  const onClickResetView = () => {
    setIsModalOpen(false)
    setShowSelectedUserAsRoot(false)
    resetView()
  }

  useEffect(() => {
    setShowSelectedUserAsRootWorkingCopy(showSelectedUserAsRoot)
  }, [showSelectedUserAsRoot])

  return (
    <>
      <div
        id='FilterButtonAndModal'
        className='d-flex p-2'
        onClick={() => setIsModalOpen(!isModalOpen)}
        data-testid='FilterButtonAndModal'
      >
        <CdnSvg className='FunnelIcon' src={funnelIconPath} />
        <CdnSvg className='ChevronIcon' src={chevronPath} />
      </div>
      <Popover className='OrgChartFilterPopover p-4' placement='bottom-start' isOpen={isModalOpen} target='FilterButtonAndModal'>
        <div className='content'>
          <h4 className='mb-3'>{I18N('configure_your_view')}</h4>
          <p className='font-weight-500 bordered-bottom w-100 mb-4'>{I18N('selected_user_display')}</p>
          <FormCheck
            id='SelectedUserAsRootFormCheck'
            checked={showSelectedUserAsRootWorkingCopy}
            onChange={e => setShowSelectedUserAsRootWorkingCopy(!showSelectedUserAsRootWorkingCopy)}
            label={I18N('selected_user_root_node')}
          />
          <div className='actions align-self-end mt-5 d-flex align-items-center'>
            <span onClick={() => onClickResetView()} className='link text-small mr-3'>{I18N('reset_view')}</span>
            <Button onClick={() => onUpdate()}>{I18NCommon('update')}</Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default FilterButtonAndModal
