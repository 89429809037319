import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import linkSlice from 'redux/slices/links'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import GoLinkForm from 'components/groups/key_links/goLinkForm'

const I18N = i18nPath('views.groups.edit_go_link_modal')

const EditGoLinkModal = ({ goLink, groupId, onClose }) => {
  const dispatch = useDispatch()
  const [workingCopy, setWorkingCopy] = useState({ ...goLink })
  const { isSaving, error } = useSelector(linkSlice.selectors.getMetaData())
  const areFieldsEqual = _.isEqual(goLink, workingCopy)
  const areFieldsMissing = !workingCopy.url
  const isSaveDisabled = isSaving || areFieldsEqual || areFieldsMissing

  const handleChange = (field, value) => {
    setWorkingCopy({ ...workingCopy, [field]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    await dispatch(linkSlice.asyncActions.updateGoLink(workingCopy))
    await dispatch(linkSlice.asyncActions.fetchGroupGoLinks(groupId))
    dispatch(showToastMessage({ message: I18N('link_updated'), type: 'success' }))
    onClose()
  }

  return (
    <Modal visible className='EditGoLinkModal' title={I18N('title')} toggle={onClose}>
      <GoLinkForm
        workingCopy={workingCopy}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onCancel={onClose}
        isSaving={isSaving}
        isSaveDisabled={isSaveDisabled}
        isNameInputDisabled
        error={error}
      />
    </Modal>
  )
}

export default EditGoLinkModal
