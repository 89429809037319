import React from 'react'
import { useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice, { buildFormPayload } from 'redux/slices/surveys/forms'
import useQueryParams from 'components/common/hooks/useQueryParams'

import ExportButton from 'components/common/buttons/exportButton'
import isValidQuestion from 'utils/surveys/isValidQuestion'
import { isAudienceValid } from 'utils/audience'
import SurveyFormType from 'types/surveys/form'
import {
  QUESTIONS_TAB, RESULTS_TAB, INDIVIDUAL_RESPONSES_TAB, RECIPIENTS_TAB, SCHEDULING_TAB
} from 'components/admin/surveys/formHeader'
import SaveFormChangesButton from 'components/admin/surveys/saveFormChangesButton'
import NavigationBlocker from 'components/common/navigationBlocker'

const I18N = i18nPath('views.admin.surveys')

interface FormHeaderPrimaryButtonProps {
  form: SurveyFormType
  workingCopy: Partial<SurveyFormType>
}

const FormHeaderPrimaryButton = ({
  form,
  workingCopy,
} : FormHeaderPrimaryButtonProps) => {
  const { selectedTab } = useQueryParams()

  const hasChanges = !_.isEqual(buildFormPayload(form), buildFormPayload(workingCopy))
  const formStatus = form?.status
  const isDraft = formStatus === 'draft'
  const hasInstructions = !_.isEmpty(workingCopy?.subject) && !_.isEmpty(workingCopy?.instructions)
  const hasAudienceAndInstructions = isAudienceValid(workingCopy) && hasInstructions
  const questions = workingCopy?.questions || []
  const hasValidQuestions = questions.length > 0 && workingCopy?.questions?.every(question => isValidQuestion(question))
  // eslint-disable-next-line max-len
  const areTimesValid = !!workingCopy.startTime && !!workingCopy.endTime && workingCopy?.startTime < workingCopy?.endTime
  const { isExportingCsv } = useSelector(surveyFormSlice.selectors.getMetaData())

  if ([RESULTS_TAB, INDIVIDUAL_RESPONSES_TAB].includes(selectedTab)) {
    return (
      <ExportButton
        isLoading={isExportingCsv}
        asyncAction={surveyFormSlice.asyncActions.admin.exportResponses(form.id)}
      />
    )
  }

  if (!isDraft) {
    return (
      <>
        {selectedTab === QUESTIONS_TAB && <NavigationBlocker isBlocked={hasChanges} />}
        <SaveFormChangesButton
          form={form}
          workingCopy={workingCopy}
          disabled={!hasChanges || !areTimesValid}
          formState='published'
          text={I18NCommon('save_changes')}
        />
      </>
    )
  }

  if (selectedTab === SCHEDULING_TAB) {
    return (
      <SaveFormChangesButton
        form={form}
        workingCopy={workingCopy}
        formState='published'
        disabled={!hasAudienceAndInstructions || !hasValidQuestions || !areTimesValid}
        text={I18N('publish_survey')}
        redirectPath={`/admin/surveys/${form?.id}?selectedTab=${RESULTS_TAB}`}
      />
    )
  }

  if (selectedTab === RECIPIENTS_TAB) {
    return (
      <SaveFormChangesButton
        form={form}
        workingCopy={workingCopy}
        disabled={!hasAudienceAndInstructions}
        text={I18N('schedule_survey')}
        redirectPath={`/admin/surveys/${form?.id}?selectedTab=${SCHEDULING_TAB}`}
      />
    )
  }

  if (selectedTab === QUESTIONS_TAB) {
    return (
      <>
        <NavigationBlocker isBlocked={hasChanges} />
        <SaveFormChangesButton
          form={form}
          workingCopy={workingCopy}
          disabled={!hasValidQuestions}
          text={I18N('select_recipients')}
          redirectPath={`/admin/surveys/${form?.id}?selectedTab=${RECIPIENTS_TAB}`}
        />
      </>
    )
  }

  return null
}

export default FormHeaderPrimaryButton
