import React from 'react'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ReactionsAndComments from 'components/common/reactions/reactionsAndComments'

const ArticleSocial = ({ article, className = '', disableActions }) => {
  const currentUser = useCurrentUser()
  const { settings } = useCurrentCompany()
  const commentsEnabled = settings.comments.articles.enabled && article.commentable

  const useReactions = settings.reactions?.type === 'full_emoji_library'

  if (currentUser.inPreboardingExperience) {
    return null
  }

  return (
    <ReactionsAndComments
      record={article}
      type='article'
      disableComments={!commentsEnabled}
      disableActions={disableActions}
      showCommentsOnInit
      onlyLike={!useReactions}
    />
  )
}

export default ArticleSocial
