import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { recommendChangingLanguage, convertLanguageToFourLetterCode, firstLanguageToSuggest } from 'utils/languageHandler'

import { Banner } from 'components/banners/banners'

import LocaleCode from 'locale-code'

const I18N = i18nPath('views.language_setting_banner')

const LanguageCheckBanner = ({ currentUser, currentCompany }) => {
  const [showBanner, setShowBanner] = useState(false)
  const [bannerDisplayLanguage, setBannerDisplayLanguage] = useState('')

  const browserLanguages = navigator.languages
  const companyLanguages = currentCompany.availableLocales
  const userLanguage = currentUser.locale
  const appName = currentCompany.appName

  function shouldShowBanner() {
    // If the user has not previously closed the banner then proceed
    if (localStorage.getItem('dontShowLanguageBanner') === null) {
      return recommendChangingLanguage(browserLanguages, userLanguage, companyLanguages)
    }
    return false
  }

  function languageToDisplay() {
    return LocaleCode.getLanguageName(convertLanguageToFourLetterCode(
      firstLanguageToSuggest(browserLanguages, companyLanguages)
    ))
  }

  useEffect(() => {
    if (shouldShowBanner()) {
      setShowBanner(true)
      setBannerDisplayLanguage(languageToDisplay())
    }
  }, [showBanner])

  function handleClose() {
    // If dontShowLanguageBanner is set to true the language recommendation banner wont be shown again
    setShowBanner(false)
    localStorage.setItem('dontShowLanguageBanner', true)
  }

  return (
    showBanner
    && (
      <Banner
        banner={{
          text: (
            I18N('text', { bannerDisplayLanguage, appName })
          ),
          link: '/settings/general',
        }}
        handleDecline={() => handleClose()}
      />
    )
  )
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  currentCompany: state.currentCompany,
})

export default connect(
  mapStateToProps,
  null
)(LanguageCheckBanner)
