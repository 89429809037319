import React from 'react'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import Card from 'components/common/card'
import CalendarIcon from 'components/icons/calendarIcon'
import isCompanyOnboardingOnly from 'utils/isCompanyOnboardingOnly'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.celebrations.welcome_card')

const coworkersWelcomePath = 'images/coworkersWelcomeImage.svg'
const personIconPath = '/images/personIcon.svg'
const personsIconPath = '/images/personsIcon.svg'
const newsPaperIconPath = '/images/newspaperIcon.svg'
const megaphoneIconPath = '/images/megaphoneIcon.svg'
const pagesIconPath = '/images/pagesIcon.svg'
const linkIconPath = '/images/linkIcon.svg'
const hierarchyIconPath = '/images/hierarchyIcon.svg'

export const MODULES_TO_SHOWCASE = {
  pages: {
    link: '/pages',
    icon: <CdnSvg src={pagesIconPath} className='PagesIcon mr-3' />,
    semiCircleColor: 'gray',
  },
  orgChart: {
    link: '/people/orgchart',
    icon: <CdnSvg src={hierarchyIconPath} className='HierarchyIcon mr-3' />,
    semiCircleColor: 'red',
  },
  news: {
    link: '/news',
    icon: <CdnSvg src={newsPaperIconPath} className='NewsPaperIcon mr-3' />,
    semiCircleColor: 'yellow',
  },
  groups: {
    link: '/people/teams',
    icon: <CdnSvg src={personsIconPath} className='PersonsIcon mr-3' />,
    semiCircleColor: 'blue',
  },
  shoutouts: {
    link: '/people/shoutouts',
    icon: <CdnSvg src={megaphoneIconPath} className='MegaphoneIcon mr-3' />,
    semiCircleColor: 'gray',
  },
  golinks: {
    link: '/golinks',
    icon: <CdnSvg src={linkIconPath} className='LinkIcon mr-3' />,
    semiCircleColor: 'red',
  },
  qna: {
    link: '/events',
    icon: <CalendarIcon className='CalendarIcon mr-3' fillColor='var(--text-color-primary)' />,
    semiCircleColor: 'yellow',
  },
}

const ModuleLink = ({
  link, icon, optionName, semiCircleColor = 'gray',
}) => (
  <Link to={link} className='ModuleLink'>
    <div className='module-link-container d-flex py-3 px-3 flex-colum align-items-center justify-content-start'>
      <div className={`semi-circle semi-circle-${semiCircleColor}`} />
      {icon}
      {optionName}
    </div>
  </Link>
)

const ModuleOption = ({ moduleName, pagesDisplayName }) => {
  const module = MODULES_TO_SHOWCASE[moduleName]
  const { icon, link, semiCircleColor } = module

  const optionName = moduleName === 'pages' && pagesDisplayName ? pagesDisplayName : I18N(moduleName)

  return (
    <ModuleLink link={link} icon={icon} optionName={optionName} semiCircleColor={semiCircleColor} />
  )
}

const ProfileOption = ({ username }) => {
  const optionName = I18N('profile')
  const icon = <CdnSvg src={personIconPath} className='PersonIcon mr-3' />

  return (
    <ModuleLink link={`/profile/${username}`} icon={icon} optionName={optionName} semiCircleColor='blue' />
  )
}

const WelcomeCard = () => {
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const {
    appName, name: companyName, settings, pageDisplayName,
  } = currentCompany
  const { preferredName, username } = currentUser
  const enabledModules = Object.keys(MODULES_TO_SHOWCASE).filter(moduleName => settings[moduleName]?.enabled)

  if (isCompanyOnboardingOnly(currentCompany)) {
    return null
  }

  return (
    <Card className='WelcomeCard confetti-background' cardBodyClassName='p-4 d-flex flex-column justify-content-center align-items-center'>
      <CdnSvg src={coworkersWelcomePath} className='mb-4' />
      <h3>{I18N('title', { appName, preferredName })}</h3>
      <p>{I18N('description', { appName, companyName })}</p>
      <div className='modules-grid w-100'>
        <ProfileOption username={username} />
        {enabledModules.map(module => (
          <ModuleOption moduleName={module} pagesDisplayName={pageDisplayName} />
        ))}
      </div>
    </Card>
  )
}

export default WelcomeCard
