import React, { useEffect, useState } from 'react'

import RichTextView from 'components/common/richTextView'
import TaskableDetailTabsSection from 'components/journeys/taskables/taskableDetailTabsSection'
import classNames from 'classnames'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import TaskableTitle from 'components/journeys/taskables/taskableTitle'
import TaskableAssignedByLine from 'components/journeys/taskables/taskableAssignedByLine'
import TaskableDueDateAndForLine from 'components/journeys/taskables/taskableDueDateAndForLine'
import SelectColleaguesSection from 'components/journeys/introductions/selectColleaguesSection'
import UpdateIntroductionCompletionStatusButton from 'components/journeys/introductions/updateIntroductionCompletionStatusButton'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import introductionSlice from 'redux/slices/journeys/introductions'
import { useSelector } from 'react-redux'
import ToggleNotificationsButton from 'components/journeys/tasks/toggleNotificationsButton'

const I18N = i18nPath('views.journeys.taskable_detail')

const IntroductionDetail = ({
  introduction,
  className = '',
  hasTitle = false,
  onCompleteOrReopen = () => {},
  isPreview = false,
}) => {
  const introductionInvitations = introduction?.introductionInvitations || []
  const [invitations, setInvitations] = useState(introductionInvitations)

  const currentUser = useCurrentUser()
  const step = introduction?.step
  const triggerAt = step?.triggerAt
  const forUser = step?.forUser
  const toUser = step?.toUserOrToCorrespondentUser
  const fromUser = step?.fromUserOrFromCorrespondentUser
  const fromCorrespondent = step?.fromCorrespondent
  const { isLoading } = useSelector(introductionSlice.selectors.getMetaData())

  useEffect(() => {
    setInvitations(introductionInvitations)
  }, [JSON.stringify(introductionInvitations)])

  if (isLoading) {
    return <CirclesLoadingIndicator className='p-5' />
  }

  if (_.isEmpty(introduction)) {
    return null
  }

  return (
    <div className={classNames('IntroductionDetail d-flex flex-column', className)}>
      {isPreview && <h1>{I18N('preview.task_preview')}</h1>}
      {hasTitle && <TaskableTitle taskable={introduction} />}
      <TaskableDueDateAndForLine taskable={introduction} toUser={toUser} currentUser={currentUser} forUser={forUser} />
      <RichTextView className='instructions-rich-view' record={introduction} recordType='introduction' richTextKey='instructions' />
      <TaskableAssignedByLine fromUser={fromUser} triggerAt={triggerAt} fromCorrespondent={fromCorrespondent} />
      <div className='w-100 line-break mt-4' />
      <SelectColleaguesSection introduction={introduction} invitations={invitations} setInvitations={setInvitations} className='mt-4' />
      <TaskableDetailTabsSection
        taskable={introduction}
        taskableType='introduction'
        isPreview={isPreview}
      />
      <div className='d-flex justify-content-end mt-3'>
        <ToggleNotificationsButton taskable={introduction} isIntroduction />
        <UpdateIntroductionCompletionStatusButton
          introduction={introduction}
          onCompleteOrReopen={onCompleteOrReopen}
          invitations={invitations}
          isPreview={isPreview}
        />
      </div>
    </div>
  )
}

export default IntroductionDetail
