import IntegrationCard from 'components/admin/integrations/integrationCard'
import IntegrationInstalledMessage from 'components/admin/integrations/IntegrationInstalledMessage'
import { ButtonSmallNarrow } from 'components/common/buttons'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import itAdminSlice from 'redux/slices/itAdmin'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import API from 'services/api'
import ApiAsyncSelector from 'components/common/apiAsyncSelector'

const microsoftTeamsPath = '/images/microsoftTeamsIcon.svg'
const I18N = i18nPath('views.integrations_page.integration_card')
const MS_TEAMS_BOT_GUIDE_URL = 'https://docs.google.com/document/d/132Kd-VtQQg7MjAeK8YBeIXKQ-9BNGs22emRYPu0-Q7k/edit?usp=sharing'

const ConfirmTeamInstall = ({ workspace }) => {
  const dispatch = useDispatch()

  const [selectedTeam, setSelectedTeam] = useState(null)

  const { confirmingTeamsSdkInstalled } = useSelector(itAdminSlice.selectors.getMetaData())

  const uninstallTeams = (id) => {
    dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration('microsoft_teams', { id }))
  }

  const confirmTeamsBotSdkInstalled = () => {
    dispatch(itAdminSlice.asyncActions.admin.confirmTeamsBotSdkInstalled(workspace.id, selectedTeam.id))
  }

  return (
    <>
      <p>
        {I18N('ms_configure_bot_instructions')}
        <a href={MS_TEAMS_BOT_GUIDE_URL} rel='noopener noreferrer' target='_blank' className='ml-1'>{I18NCommon('here')}</a>
      </p>

      <ApiAsyncSelector
        fetchAll={({ query }) => API.admin.msTeamWorkspaces.listTeams(workspace.id, { query })}
        onChange={setSelectedTeam}
        value={selectedTeam}
        className='mb-4 TeamsAsyncSelector'
      />

      <p>
        <ButtonSmallNarrow
          onClick={confirmTeamsBotSdkInstalled}
          className='mr-2'
          showLoadingSpinner={confirmingTeamsSdkInstalled[workspace.id]}
          disabled={!selectedTeam}
        >
          {I18NCommon('confirm_the_instalation')}
        </ButtonSmallNarrow>

        {!confirmingTeamsSdkInstalled[workspace.id] && (
          <ButtonSmallNarrow
            variant='danger'
            onClick={() => uninstallTeams(workspace.id)}
          >
            {I18NCommon('uninstall')}
          </ButtonSmallNarrow>
        )}
      </p>
    </>
  )
}

const MultipleMsTeamBody = () => {
  const dispatch = useDispatch()
  const { teams = [] } = useSelector(itAdminSlice.selectors.getData())

  const uninstallTeam = (id) => {
    if (confirm(I18N('ms_team_workspace_removal_confirmation'))) {
      dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration('microsoft_teams', { id }))
    }
  }


  if (teams.length === 0) {
    return null
  }

  return (
    <div className='mb-3'>
      {teams.filter(t => t.status === 'pending').map(team => (
        <div key={team.id}>
          <ConfirmTeamInstall workspace={team} />
          <hr />
        </div>
      ))}

      {teams.filter(t => t.status === 'installed').map(team => (
        <IntegrationInstalledMessage key={team.id} workspace={team} onUninstall={uninstallTeam} />
      ))}
    </div>
  )
}

const MultipleMsTeamIntegrationCard = () => {
  const { teams = [] } = useSelector(itAdminSlice.selectors.getData())

  return (
    <IntegrationCard
      id='microsoft_teams'
      logo={<CdnSvg src={microsoftTeamsPath} />}
      bodyComponent={<MultipleMsTeamBody />}
      installationComponent={(
        <p>
          <a className='btn btn-primary btn-sm btn-narrow' href='/auth/microsoft_azure_app/teams_authorize_url' rel='noopener noreferrer' target='_blank'>
            {teams.length === 0 ? I18NCommon('install') : I18N('add_more_teams')}
          </a>
        </p>
      )}
    />
  )
}

export default MultipleMsTeamIntegrationCard
