import React, { useState, useRef, ReactElement } from 'react'
import { Tooltip } from 'reactstrap'

interface Props {
  children: string
  tooltipSuffix?: string
}

const TruncatedContent = ({ children, tooltipSuffix = '' }: Props): ReactElement => {
  const spanRef = useRef<HTMLElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const toggleTooltip = () => {
    if (isEllipsisActive()) {
      setShowTooltip(oldValue => !oldValue)
    }
  }

  const isEllipsisActive = () => spanRef.current!.offsetWidth < spanRef.current!.scrollWidth

  const tooltipId = (children + tooltipSuffix)
    .replace(/ /g, '')
    .replace(/[^\w-]/g, string => String(string.charCodeAt(0)))

  return (
    <span id={`TruncatedContent-${tooltipId}`} className='text-truncate' ref={spanRef}>
      {children}
      <Tooltip
        placement='bottom'
        isOpen={showTooltip}
        toggle={toggleTooltip}
        target={`TruncatedContent-${tooltipId}`}
      >
        {children}
      </Tooltip>
    </span>
  )
}

export default TruncatedContent
