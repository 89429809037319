import { SurveyQuestionOptionType } from 'types/surveys/questionOption'

export enum QuestionTypeEnum {
  ShortText = 'short_text',
  Paragraph = 'paragraph',
  MultipleChoice = 'multiple_choice',
  Checkbox = 'checkbox',
  Scale = 'scale',
  Html = 'html',
}

export interface SurveyQuestionType {
  id?: string,
  title: string,
  // one of the question types
  type: QuestionTypeEnum,
  required: boolean,
  allowAdditionalText: boolean,
  config?: any,
  options?: SurveyQuestionOptionType[],
  isEditing?: boolean,
  orderPosition?: number,
  profileField?: string | null,
}

export interface AddQuestionFunctionType {
  (newQuestion: SurveyQuestionType, questionIndex?: number): void;
}
