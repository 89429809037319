import classNames from 'classnames'
import ReactSelect from 'components/common/react_select'
import celebrationEmojis from 'components/celebrations/utils/emojis'
import React, { useMemo } from 'react'
import { components } from 'react-select'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.celebrations')

interface Props {
  value: string
  onChange: (value: string) => void
  isDisabled?: boolean
  className?: string
}

const CelebrationType = ({ type }) => (
  <>
    {celebrationEmojis[type] && (
      <span className='d-inline-block mr-2'>{celebrationEmojis[type]}</span>
    )}

    {I18N(`labels.${type}.title`)}
  </>
)

// make sure to change Celebration::VALID_TYPES if you're changing this
const allOptions = [{
  value: 'birthday',
}, {
  value: 'work_anniversary',
}, {
  value: 'new_employee_welcome',
}, {
  value: 'farewell',
}, {
  value: 'achievement',
}, {
  value: 'new_parent',
}, {
  value: 'thank_you',
}, {
  value: 'get_well_soon',
}, {
  value: 'custom',
}]


const wrapCelebrationTypeComponent = Component => props => (
  <Component {...props}>
    <CelebrationType type={props.data.value} />
  </Component>
)

const Option = wrapCelebrationTypeComponent(components.Option)
const SingleValue = wrapCelebrationTypeComponent(components.SingleValue)

const CelebrationTypeSelector = ({
  onChange, value, className, ...props
}: Props) => {
  const { settings } = useCurrentCompany()

  const options = useMemo(() => allOptions.filter((option) => {
    if (option.value === 'birthday') return settings.celebrations.birthdays.enabled
    if (option.value === 'work_anniversary') return settings.celebrations.workAnniversaries.enabled
    if (option.value === 'new_employee_welcome') return settings.celebrations.newEmployees.enabled
    return true
  }), [])

  return (
    <ReactSelect
      aria-label='Type to search for celebration type'
      className={classNames('CelebrationTypeSelector', className)}
      getOptionValue={option => option.value}
      options={options}
      value={options.find(option => option.value === value)}
      placeholder={I18NCommon('select_a_type')}
      components={{
        Option,
        SingleValue,
      }}
      onChange={option => onChange(option.value)}
      autoFocus
      openMenuOnFocus
      {...props}
    />
  )
}

export default CelebrationTypeSelector
