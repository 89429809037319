import React, { SetStateAction, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import ClearyCard from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useApi from 'components/common/hooks/useApi'
import MultipleEmployeesName from 'components/common/multipleEmployeesName'
import useActionCable from 'components/common/hooks/useActionCable'
import { buildActivity, normalizeActivity } from './normalizeActivity'

const I18N = i18nPath('views.ai_assistant.dashboard.main_content.activities_card')

const ClearyLogo = ({ showName = true }) => (
  <>
    <CdnSvg src='/images/logos/clearyLogoNoText.svg' />
    {showName && <span>Cleary AI</span>}
  </>
)

const KnowledgeIngestionActivity = ({ activity }) => (
  <div className='d-flex gap-1 text-small'>
    <ClearyLogo />
    <span className='bold'>
      {I18N(`activities.knowledge_ingestion.${activity.action}`)}
    </span>
    <span>{activity.actionableOnCreatedAt.slackChannelNames.join(', ')}</span>
    {activity.action === 'update' && (
      <span>
        <Link to={activity.actionableOnCreatedAt.displayPath}>
          {I18N('activities.knowledge_ingestion.view_insights')}
        </Link>
      </span>
    )}
  </div>
)

const AnswerActivity = ({ activity }) => (
  <div className='d-flex gap-1 text-small'>
    <ClearyLogo />
    <span className='bold'>{I18N('activities.answer.create')}</span>
    <EmployeeThumbnailPhoto employee={activity.actionableOnCreatedAt.user} size='1.5rem' />
    <span>{activity.actionableOnCreatedAt.user.preferredFullName}</span>
    {activity.actionableOnCreatedAt.originalMessageUrl && (
      <a href={activity.actionableOnCreatedAt.originalMessageUrl} target='_blank' rel='noopener noreferrer'>
        View message
      </a>
    )}
  </div>
)

const FaqActivity = ({ activity }) => (
  <div className='FAQActivity d-flex gap-1'>
    {activity.actor ? (
      <span className='FAQActivity__item flex text-small'>
        <EmployeeThumbnailPhoto employee={activity.actor} size='1.5rem' />
        <span className='ml-1'>{activity.actor.preferredFullName}</span>
      </span>
    ) : (
      <div className='FAQActivity__item text-small'>
        <ClearyLogo showName={false} />
      </div>
    )}

    <span className='FAQActivity__item bold text-small'>{I18N(`activities.faq.${activity.action}`)}</span>
    <span className='FAQActivity__question text-small'>
      <a
        href={`${activity.actionableOnCreatedAt.page.displayPath}?highlighted_faq_id=${activity.actionableOnCreatedAt.id}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        &quot;{activity.actionableOnCreatedAt.question}&quot;
      </a>
    </span>

    {!activity.actor && (
      <div className='FAQActivity__item d-flex gap-1 text-small'>
        <span>{I18N(`activities.faq.${activity.action}_from_bot`)}</span>
        <MultipleEmployeesName
          employees={activity.actionableOnCreatedAt.faqSources?.flatMap(source => source.user)}
        />
      </div>
    )}
  </div>
)

const componentMap = {
  'Ai::Assistant::KnowledgeIngestion': KnowledgeIngestionActivity,
  'Ai::Assistant::Answer': AnswerActivity,
  'Page::FAQ': FaqActivity,
}

interface ActivitiesProps {
  setCompletedIngestionId: React.Dispatch<SetStateAction<string>>
  fetchKnowledgeIngestions: () => void
}

const validActivities = (activity: any) => {
  if (activity.actionableType === 'Ai::Assistant::Answer' && !activity.actionableOnCreatedAt.answered) {
    return false
  }

  return componentMap[activity.actionableType]
}

const Activities = ({ setCompletedIngestionId, fetchKnowledgeIngestions }: ActivitiesProps) => {
  const [activities, setActivities] = useState<any[]>([])
  const [hasMore, setHasMore] = useState(false)

  const [fetchActivities, { isLoading }] = useApi(
    () => API.activities.fetchAll({ lastId: activities[activities.length - 1]?.id }),
    {
      onSuccess: (data, response) => {
        setActivities(prev => [...prev, ...data.map(activity => normalizeActivity(activity))])
        setHasMore(response.headers['x-has-more'] === 'true')
      },
    }
  )

  const onDataReceived = (serializedActivity) => {
    setActivities(activities => activities.map(activity => ({ ...activity, animated: false })))

    const activity = buildActivity(serializedActivity)
    activity.animated = true

    setActivities(activities => [normalizeActivity(activity), ...activities])

    if (activity.actionableType === 'Ai::Assistant::KnowledgeIngestion') {
      if (activity.action === 'update') {
        setCompletedIngestionId(activity.actionableOnCreatedAt.data.id)
      }

      fetchKnowledgeIngestions()
    }
  }

  useActionCable({
    channel: 'ActivityChannel',
    isActive: true,
    onDataReceived,
  })

  useEffect(() => {
    fetchActivities()
  }, [])

  return (
    <ClearyCard title={I18N('title')}>
      {activities?.length > 0 && (
        <table className='Activities'>
          <tbody>
            {activities?.filter(validActivities).map(activity => (
              <tr className='Activities__row' key={activity.id}>
                <td className='w-80'>
                  <div className={classNames({ Activities__animated: activity.animated })}>
                    {componentMap[activity.actionableType]({ activity })}
                  </div>
                </td>
                <td className='w-20 text-right text-secondary text-small'>
                  {i18nMoment(activity.createdAt).fromNow()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {!activities?.length && !isLoading && (
        <div className='text-center text-secondary text-small'>{I18N('no_activities')}</div>
      )}

      {isLoading && <CirclesLoadingIndicator />}

      {hasMore && (
        <div onClick={fetchActivities} className='text-small link mt-2'>
          {I18NCommon('load_more')}
        </div>
      )}
    </ClearyCard>
  )
}

export default Activities
