import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import groupMembershipSlice from 'redux/slices/groupMemberships'
import groupSlice from 'redux/slices/groups'
import { i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import { Button, ButtonNarrow } from 'components/common/buttons'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.groups')
const I18NCommon = i18nPath('views.common')

const LeaveGroupButtonAndModal = ({ group, isLoading = false }) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonHovered, setIsButtonHovered] = useState(false)

  const currentUser = useCurrentUser()

  const handleClose = () => setIsModalOpen(false)
  const handleLeaveButtonClick = () => setIsModalOpen(true)

  const handleLeaveConfirmation = async () => {
    await dispatch(groupMembershipSlice.asyncActions.removePerson(group, currentUser))
    dispatch(groupSlice.asyncActions.fetch(group.id))
  }

  const memberLeaveVariant = isButtonHovered ? 'danger' : 'primary'

  return (
    <>
      <ButtonNarrow
        onClick={handleLeaveButtonClick}
        variant={memberLeaveVariant}
        showLoadingSpinner={isLoading}
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
      >
        {isButtonHovered ? I18N('group_page.leave') : I18N('group_page.is_member')}
      </ButtonNarrow>

      <Modal className='LeaveGroupConfirmationModal' size='md' visible={isModalOpen} toggle={handleClose}>
        <div className='p-2 text-center'>
          <h3>{I18N('leave_group_modal.leave_team')}</h3>
          <p className='text-secondary mb-0'>{I18N('leave_group_modal.are_you_sure', { groupName: group.name })}</p>
          <p className='text-secondary'>{I18N('leave_group_modal.no_more_updates')}</p>

          <div className='d-flex align-items-center justify-content-center mt-5 mb-4'>
            <div role='button' tabIndex={0} className='mr-3 text-muted-dark font-weight-600' onClick={handleClose}>
              {I18NCommon('cancel')}
            </div>
            <Button variant='danger' onClick={handleLeaveConfirmation}>
              {I18N('leave_group_modal.leave_group')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LeaveGroupButtonAndModal
