import React, { useRef, useState } from 'react'

import { Tooltip } from 'reactstrap'
import classNames from 'classnames'

type BarProps = {
  tooltipText: string
  width: string
  className?: string
}

export const Bar = ({ tooltipText, width, className }: BarProps) => {
  const barRef = useRef(null)
  const [isTooltipOpened, setIsTooltipOpened] = useState(false)

  return (
    <div
      ref={barRef}
      className={className}
      style={{ width }}
      onMouseMove={() => !isTooltipOpened && setIsTooltipOpened(true)}
      onMouseLeave={() => setIsTooltipOpened(false)}
    >
      {barRef.current && (
        <Tooltip
          placement='bottom'
          target={barRef.current}
          isOpen={isTooltipOpened}
        >
          {tooltipText}
        </Tooltip>
      )}
    </div>
  )
}

type StackedBarChartProps = {
  data: { value: number, className?: string, tooltipText: string }[]
  className: string
}

const StackedBarChart = ({ data, className }: StackedBarChartProps) => {
  const total = data.reduce((acc, bar) => acc + bar.value, 0)

  return (
    <div className={classNames('StackedBarChart d-flex', className)}>
      {data.map((bar, index) => {
        const width = `${(bar.value / total) * 100}%`

        return (
          <Bar
            key={index}
            className={bar.className || ''}
            tooltipText={bar.tooltipText}
            width={width}
          />
        )
      })}
    </div>
  )
}

export default StackedBarChart
