import React from 'react'

import classNames from 'classnames'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import CdnSvg from 'components/common/cdnSvg'
import FilterButtonAndModal from './filterButtonAndModal'

interface ToolbarProps {
  isOrientationVertical: boolean,
  setIsOrientationVertical: (boolean) => void,
  setShowSelectedUserAsRoot: (boolean) => void,
  showSelectedUserAsRoot: boolean,
  resetView: () => void,
}

const hierarchyIconPath = '/images/hierarchyIcon.svg'

const Toolbar = ({
  isOrientationVertical,
  setIsOrientationVertical,
  showSelectedUserAsRoot,
  setShowSelectedUserAsRoot,
  resetView,
}: ToolbarProps) => {
  const onSelectEmployee = (employee) => {
    // TODO: figure out how to do this without triggering a refresh
    // difficult because of all the logic we do when rendering a single node
    location.assign(`/people/orgchart/${employee.username}`)
  }

  return (
    <nav className='OrgChartToolbar'>
      <div className='orientation-buttons px-3'>
        <div onClick={() => setIsOrientationVertical(true)}>
          <CdnSvg
            src={hierarchyIconPath}
            className={classNames('mr-3 vertical', isOrientationVertical ? 'active' : '')}
          />
        </div>
        <div onClick={() => setIsOrientationVertical(false)}>
          <CdnSvg
            src={hierarchyIconPath}
            className={classNames('horizontal', isOrientationVertical ? '' : 'active')}
          />
        </div>
      </div>
      <EmployeeSearch
        onChange={onSelectEmployee}
        selectedEmployee={null}
        isClearable={false}
        className='OrgChartSearch'
        excludeUsersHiddenInOrgChart
      />
      <FilterButtonAndModal
        showSelectedUserAsRoot={showSelectedUserAsRoot}
        setShowSelectedUserAsRoot={setShowSelectedUserAsRoot}
        resetView={resetView}
      />
    </nav>
  )
}

export default Toolbar
