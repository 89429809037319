import React from 'react'
import { Link } from 'react-router-dom'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.admin.external_calendar_list')

const ExternalCalendarListPage = () => {
  const { data: externalCalendars, isLoading, isNotFound } = useFetch(API.admin.externalCalendars.fetchAll)

  return (
    <div className='ExternalCalendarListPage'>
      <header className='AdminHeader d-flex justify-content-between align-items-center'>
        <h3>{I18N('external_calendars')}</h3>
        <div className='AdminButtonContainer col-6'>
          <Link to='/admin/external_calendars/new'>
            <Button>
              {I18N('create_new_external_calendar')}
            </Button>
          </Link>
        </div>
      </header>

      <main className='AdminContent'>
        <LoadingContainer isLoading={isLoading} isNotFound={isNotFound} useCirclesLoadingIndicator>
          <table className='white-bg-table'>
            <thead>
              <tr>
                <th>{I18N('internal_name')}</th>
                <th>{I18N('display_name')}</th>
                <th>{I18N('created')}</th>
              </tr>
            </thead>
            <tbody>
              {(externalCalendars || []).map(externalCalendar => (
                <tr key={externalCalendar.id}>
                  <td>
                    <Link to={`/admin/external_calendars/${externalCalendar.id}`}>
                      <span>{externalCalendar.internalName}</span>
                    </Link>
                  </td>
                  <td>{externalCalendar.displayName}</td>
                  <td>{i18nMoment(externalCalendar.createdAt).format('LLL')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default ExternalCalendarListPage
