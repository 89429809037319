/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState } from 'react'

import API from 'services/api'
import { AsyncCreatable, AsyncSelect } from 'components/common/react_select'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { WorkspaceTypeEnum } from 'types/chat/workspace'
import { useDispatch, useSelector } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import SelectWorkspaceModal from 'components/common/selectWorkspaceModal'
import workspaceSlice from 'redux/slices/chats/workspaces'
import { components } from 'react-select'
import ChatChannel from 'components/common/chatChannel'
import { ChatChannelType, ChatChannelTypeEnum } from 'types/chat/channel'

const I18N = i18nPath('components.chat_channels_select')


const wrapChatChannelWithSelectComponent = Component => (props) => {
  if (props.data.__isNew__) {
    return <Component {...props} />
  }

  return (
    <Component {...props}>
      <ChatChannel channel={props.data} linkEnabled={false} />
    </Component>
  )
}

const Option = wrapChatChannelWithSelectComponent(components.Option)
const SingleValue = wrapChatChannelWithSelectComponent(components.SingleValue)
const MultiValue = wrapChatChannelWithSelectComponent(components.MultiValue)

type Value = ChatChannelType[] | ChatChannelType

interface Props<T extends Value> {
  id?: string
  value?: T
  types: ChatChannelTypeEnum[]
  onChange: (value: T) => void
  creatable?: boolean
  isLoadingExternal?: boolean
  [key: string]: any
}

function ChatChannelsAsyncSelect<T extends Value>({
  value, onChange, types, id = 'cy_chat_channel_select', creatable = true, isLoadingExternal = false, ...props
}: Props<T>) {
  const dispatch = useDispatch()
  const [creatingChannelName, setCreatingChannelName] = useState(null)
  const [isLoading, setIsLoading] = useState(isLoadingExternal)

  useEffect(() => {
    setIsLoading(isLoadingExternal)
  }, [isLoadingExternal])

  const creatableWorkspacesTypes: WorkspaceTypeEnum[] = []

  // We don't allow ms team to be creatable since we cant publish to private channels
  if (types.includes(ChatChannelTypeEnum.SlackChannel) && creatable) {
    creatableWorkspacesTypes.push(WorkspaceTypeEnum.SlackWorkspace)
  }

  const isCreatable = creatableWorkspacesTypes.length > 0

  const workspaces = useSelector(workspaceSlice.selectors.getWorkspaces())

  useEffect(() => {
    dispatch(workspaceSlice.asyncActions.fetchAll())
  }, [])

  const visibleWorkspaces = workspaces.filter(wp => creatableWorkspacesTypes.includes(wp.type))

  const isMulti = Array.isArray(value)

  const buildChatChannelOption = ({ attributes }) => attributes

  let loadChannels = (query, callback) => {
    API.chat.channels.fetchAll({ q: query, types: types.join(',') })
      .then(response => callback(response.data.data.map(buildChatChannelOption)))
  }

  loadChannels = _.debounce(loadChannels, 300)

  const innerOnChange = (newValue) => {
    const addedValue = isMulti ? newValue[newValue.length - 1] : newValue

    if (addedValue?.__isNew__) {
      const channelName = addedValue.label.replace('#', '')

      if (visibleWorkspaces.length === 1) {
        createChannel(channelName, visibleWorkspaces[0])
      } else {
        setCreatingChannelName(channelName)
      }
      return
    }

    onChange(newValue)
  }

  const createChannel = async (channelName, workspace) => {
    setIsLoading(true)

    try {
      const response = await API.chat.channels.create({
        workspaceId: workspace.id,
        name: channelName,
        externalId: channelName,
      })

      const { attributes } = response.data.data

      // trigger the onChange with the created channel
      let newValue = attributes
      if (isMulti) {
        newValue = _.uniqBy([...value, attributes], channel => `${channel.name}${channel.workspaceDomain}`)
      }

      onChange(newValue)
    } catch (e) {
      dispatch(showToastMessage({ message: I18NCommon('failed_to_add_channel'), type: 'error' }))
    }

    setCreatingChannelName(null)
    setIsLoading(false)
  }

  let placeholder = I18NCommon('select_or_type_to_search')

  if (types.length === 1 && types.includes(ChatChannelTypeEnum.SlackChannel)) {
    placeholder = I18NCommon('select_or_type_to_search_slack')
  }

  if (types.length === 1 && types.includes(ChatChannelTypeEnum.MsTeamChannel)) {
    placeholder = I18NCommon('select_or_type_to_search_ms_team')
  }

  const Component = isCreatable ? AsyncCreatable : AsyncSelect

  return (
    <>
      <Component
        id={id}
        aria-label='Type to search for channels'
        getOptionValue={option => option.id || option.value}
        isDisabled={isLoading}
        isLoading={isLoading}
        value={value}
        onChange={innerOnChange}
        loadOptions={loadChannels}
        backspaceRemovesValue
        isClearable
        isMulti={isMulti}
        defaultOptions
        placeholder={placeholder}
        components={{
          Option,
          SingleValue,
          MultiValue,
        }}
        {...props}
      />

      {creatingChannelName && (
        <SelectWorkspaceModal
          title={I18N('what_workspace_channel_belongs', { name: `#${creatingChannelName}` })}
          onCancel={() => setCreatingChannelName(null)}
          onWorkspaceSelected={workspace => createChannel(creatingChannelName, workspace)}
          visibleWorkspaces={visibleWorkspaces}
        />
      )}
    </>
  )
}

export default ChatChannelsAsyncSelect
