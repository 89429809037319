import React, { useRef, useState } from 'react'
import BlockColorPicker from 'components/common/blockColorPicker'

interface Props {
  color: any
  onChange: (newColor: string) => void
  onRemove: () => void
}

const ColorSelect = ({
  color, onChange, onRemove,
}: Props) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const buttonRef = useRef<any>()

  return (
    <div className='ColorSelect position-relative' ref={buttonRef}>
      <div
        className='ColorSelectPreview rounded bordered pointer'
        style={{ backgroundColor: color }}
        onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
      />
      <BlockColorPicker
        buttonRef={buttonRef}
        isOpen={isColorPickerOpen}
        onClose={() => setIsColorPickerOpen(false)}
        color={color}
        onChange={onChange}
        onRemove={onRemove}
      />
    </div>
  )
}

export default ColorSelect
