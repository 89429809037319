import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import previewComponents from 'components/admin/customStyles/previewComponents/previewComponentsList'

const I18N = i18nPath('views.custom_styles.border_radius.preview_elements')

interface Props {
  variable: string
  value: string
  valueRange: { min: number, max: number }
  onChange: (type: string, variable: string, value: string) => void
}

const BorderRadiusControl = ({
  variable, value, valueRange, onChange,
}: Props) => {
  const unitValue = value.match(/[0-9]+/)?.at(0)
  const { min = 0, max = 16 } = valueRange
  const units = {
    squareBorderRadius: 'px',
    buttonBorderRadius: 'px',
    profileImageBorderRadius: '%',
  }

  const handleChange = (variable: string) => (ev) => {
    const { value } = ev.target

    onChange('border', variable, `${value}${units[variable]}`)
  }

  return (
    <div className='BorderRadiusControl d-flex justify-content-between align-content-start mb-5'>
      <span>
        <label className='label'> {I18N(_.snakeCase(variable))}</label> {unitValue}
        {previewComponents[variable]}
      </span>
      <span className='control d-flex align-items-center justify-content-center'>
        <span className='mr-1'>{min}</span>
        <input
          className='p-0'
          type='range'
          name='square-border-radius'
          defaultValue={unitValue}
          min={min}
          max={max}
          onChange={handleChange(variable)}
        />
        <span className='ml-1'>{max}</span>
      </span>
    </div>
  )
}

export default BorderRadiusControl
