import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import RichTextView from 'components/common/richTextView'
import VersionHistoryToolbar from 'components/pages/versions/versionHistoryToolbar'
import VersionHistoryNav from 'components/pages/versions/versionHistoryNav'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'

const VersionHistoryContent = ({ version }) => (
  <div className='VersionHistoryNavContent d-flex flex-column align-items-start justify-content-start p-5 overflow-y-auto'>
    <>
      <h1 className='version-title font-weight-700 mb-5'>{version.title}</h1>
      <div className='w-100'>
        <RichTextView
          record={version}
          recordType='pageSnapshot'
          richTextKey='content'
        />
      </div>
    </>
  </div>
)

const PageVersionHistory = ({ page, closeVersionHistoryView }) => {
  const usersCurrentlyEditing = useSelector(collaborativeEditorSlice.selectors.getUsersCurrentlyEditing())

  const currentVersion = {
    title: page.draftTitle,
    updatedAt: page.updatedAt,
    content: page.draftContent,
    contributors: usersCurrentlyEditing || [],
    published: page.content === page.draftContent,
  }

  const [selectedSnapshot, setSelectedSnapshot] = useState(null)

  return (
    <>
      <VersionHistoryToolbar
        page={page}
        snapshot={selectedSnapshot}
        closeVersionHistoryView={closeVersionHistoryView}
      />
      <VersionHistoryContent
        version={selectedSnapshot || currentVersion}
      />
      <VersionHistoryNav
        pageId={page.id}
        currentVersion={currentVersion}
        selectedSnapshot={selectedSnapshot}
        setSelectedSnapshot={setSelectedSnapshot}
      />
    </>
  )
}

export default PageVersionHistory
