import React from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'

import profileSlice from 'redux/slices/profiles'

import EditableContent from 'components/common/editable_content'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import cleanUrl from 'utils/cleanUrl'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.profile')

const phoneOutlineIconPath = '/images/phoneOutlineIcon.svg'
const twitterIconPath = '/images/twitterIcon.svg'
const githubIconPath = '/images/githubIcon.svg'
const linkedInIconPath = '/images/linkedinIcon.svg'
const globeIconPath = '/images/globeIcon.svg'

const LinkedText = ({ externalSocialMediaPath, text }) => (
  <a
    href={externalSocialMediaPath}
    rel='noopener noreferrer'
    target='_blank'
  >
    {cleanUrl(text)}
  </a>
)

const PlainText = ({ text }) => (
  <>
    {text}
  </>
)

const FieldDisplayText = ({
  text,
  iconPath,
  externalSocialMediaPath = '',
}) => (
  <span className='FieldDisplayText d-inline-flex align-items-center font-weight-500'>
    <CdnSvg className='mr-2' src={iconPath} />
    {externalSocialMediaPath ? (
      <LinkedText externalSocialMediaPath={externalSocialMediaPath} text={text} />
    ) : (
      <PlainText text={text} />
    )}
  </span>
)

const EditableContactFields = ({
  canEdit,
  fieldSettings,
  user,
}) => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()

  const updateField = (fieldName, value) => {
    if (user[fieldName] === value) {
      // we will return a Promise here so we can chain it
      // in the same way we will if the value changes
      return new Promise(resolve => resolve(true))
    } else {
      // If content has change, submit to the backend.
      const change = {}
      change[fieldName] = value
      return dispatch(profileSlice.asyncActions.update(user, change))
    }
  }

  const canEditField = field => ('canEdit' in field ? field.canEdit : canEdit)

  const fields = [
    {
      iconPath: phoneOutlineIconPath,
      className: 'PhoneIcon',
      fieldName: 'phoneMobile',
      value: user.phoneMobile,
      editValue: user.phoneMobile,
      url: user.phoneMobile && `tel: ${user.phoneMobile}`,
      placeHolderText: I18N('contact_info.add_phone_mobile_placeholder'),
      inputPlaceholder: '+1 (555) 123-4567',
      enabled: user.privacySetting.phoneMobile && fieldSettings.phone,
      canEdit: canEdit && !currentCompany.hasHrisIntegration,
    },
    {
      iconPath: globeIconPath,
      fieldName: 'websiteLink',
      className: 'WebsiteLink',
      value: user.websiteLink,
      editValue: user.websiteLink,
      url: user.websiteLink,
      placeHolderText: I18N('contact_info.add_website_link_placeholder'),
      inputPlaceholder: 'https://www.xyz.com',
      enabled: fieldSettings.websiteLink,
    },
    {
      iconPath: twitterIconPath,
      fieldName: 'twitter',
      value: user.twitter && `@${user.twitter}`,
      editValue: user.twitter,
      url: user.twitter && `https://www.twitter.com/${user.twitter}`,
      placeHolderText: I18N('contact_info.add_twitter_placeholder'),
      enabled: fieldSettings.twitter,
    },
    {
      iconPath: linkedInIconPath,
      fieldName: 'linkedin',
      value: user.linkedin,
      editValue: user.linkedin,
      url: user.linkedin && `https://www.linkedin.com/in/${user.linkedin}`,
      placeHolderText: I18N('contact_info.add_linkedin_placeholder'),
      enabled: fieldSettings.linkedin,
    },
    {
      iconPath: githubIconPath,
      fieldName: 'github',
      value: user.github,
      editValue: user.github,
      url: user.github && `https://www.github.com/${user.github}`,
      placeHolderText: I18N('contact_info.add_github_placeholder'),
      enabled: fieldSettings.github,
    },
  ].filter(field => (
    // only show the fields that are:
    // 1) enabled in admin settings, and:
    // 2a) the user can edit them, or:
    // 2b) the user cannot edit them but there is a value there.
    // We want to avoid showing a call to action like "Add Twitter" to
    // those who cannot edit
    field.enabled && (canEditField(field) || field.value)
  ))

  return fields.map(field => (
    <EditableContent
      className={field.className}
      key={field.fieldName}
      canEdit={canEditField(field)}
      displayElement={(
        <FieldDisplayText
          text={field.value || field.placeHolderText}
          iconPath={field.iconPath}
          externalSocialMediaPath={field.url}
        />
      )}
      inputElement={(
        <input
          className='font-weight-400 p-2'
          type='text'
          maxLength='280'
          placeholder={field.inputPlaceholder || field.placeHolderText}
        />
      )}
      valueToEdit={field.editValue}
      saveContent={value => updateField(field.fieldName, value)}
    />
  ))
}

export default EditableContactFields
