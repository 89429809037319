import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import ToggleSwitch from 'components/common/toggleSwitch'
import { PREBOARDING_CAROUSEL_CARDS_WIDGET_TYPE } from 'utils/layout/widgetsMap'
import PreboardingCarouselCardsWidgetSettingsSection from 'components/admin/layout/layout_collection_sidebar/preboardingCarouselCardsSettingsSection'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.carousel_cards')

const CarouselCardsWidgetSettingsSidebar = ({ widget, updateWidget }) => {
  const { maxW, settings } = widget
  const { fullWidth } = settings

  const updateFullWidth = () => {
    if (fullWidth) {
      updateWidget({ ...widget, settings: { ...settings, fullWidth: false } })
    } else {
      updateWidget({ ...widget, w: maxW, settings: { ...settings, fullWidth: true } })
    }
  }

  return (
    <div className='CarouselCardsWidgetSettingsSidebar'>
      <h5>{I18N('title')}</h5>

      <label className='text-secondary font-weight-500'>{I18N('layout')}</label>
      <div className='w-100 d-flex flex-column gap-3'>
        <ToggleSwitch
          checked={fullWidth}
          text={I18N('full_width')}
          onChange={updateFullWidth}
          textPosition='after'
        />
      </div>

      {widget.type === PREBOARDING_CAROUSEL_CARDS_WIDGET_TYPE && (
        <PreboardingCarouselCardsWidgetSettingsSection
          widget={widget}
          updateWidget={updateWidget}
        />
      )}
    </div>
  )
}

export default CarouselCardsWidgetSettingsSidebar
