import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import FormErrorList from 'components/errors/formErrorList'
import { i18nPath } from 'utils/i18nHelpers'
import userSkillTypeSlice from 'redux/slices/userSkillTypes'
import userSkillSlice from 'redux/slices/userSkills'
import UserSkillForm from 'components/admin/userSkillForm'
import UserSkillTaggingsEditor from 'components/admin/userSkillTaggingsEditor'

const I18N = i18nPath('views.admin.user_skills')

const EditUserSkillPage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [workingCopy, setWorkingCopy] = useState({})

  const { userSkillId } = params

  const userSkill = useSelector(userSkillSlice.selectors.getUserSkill(userSkillId))
  const { isLoadingAdmin, isSaving, error } = useSelector(userSkillSlice.selectors.getMetaData())
  const userSkillTypes = useSelector(userSkillTypeSlice.selectors.getUserSkillTypes())

  const isMissingRequiredFields = !workingCopy.name || !workingCopy.userSkillTypeId
  const changesPresent = !_.isEqual(userSkill, workingCopy)
  const disableSaveButton = isMissingRequiredFields || !changesPresent

  useEffect(() => {
    dispatch(userSkillSlice.asyncActions.admin.fetchUserSkill(userSkillId))
    dispatch(userSkillTypeSlice.asyncActions.admin.fetchUserSkillTypes())
  }, [userSkillId])

  useEffect(() => {
    if (userSkill?.id) {
      setWorkingCopy({ ...userSkill, userSkillTypeId: userSkill.userSkillType.id })
    }
  }, [userSkill?.id])

  useEffect(() => {
    if (!_.isEmpty(userSkill)) {
      setWorkingCopy({ ...userSkill, userSkillTypeId: userSkill.userSkillType.id })
    }
  }, [userSkill?.updatedAt])

  const saveUserSkill = () => {
    dispatch(userSkillSlice.asyncActions.admin.updateUserSkill(workingCopy))
  }

  const deleteUserSkill = () => {
    if (confirm(I18N('delete_confirm'))) {
      dispatch(userSkillSlice.asyncActions.admin.deleteUserSkill(userSkill)).then(() => {
        history.push('/admin/user_skills')
      })
    }
  }

  if (!userSkill?.id || isLoadingAdmin) {
    return null
  }

  return (
    <div className='EditUserSkillPage'>
      <header className='AdminHeader'>
        <h3>{I18N('edit_user_skill')}</h3>
      </header>

      <main className='AdminContent'>
        <UserSkillForm userSkill={workingCopy} userSkillTypes={userSkillTypes} setUserSkill={setWorkingCopy} />

        {error && <FormErrorList error={error} />}

        <div className='d-flex mt-3'>
          <Button disabled={disableSaveButton} onClick={saveUserSkill} showLoadingSpinner={isSaving}>
            {I18N('update')}
          </Button>
          <Button variant='danger' className='ml-2' onClick={deleteUserSkill}>
            {I18N('delete')}
          </Button>
        </div>

        <UserSkillTaggingsEditor userSkill={userSkill} users={userSkill.userSkillTaggings.map(ust => ust.user)} />
      </main>
    </div>
  )
}

export default EditUserSkillPage
