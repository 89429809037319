import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import arrayMove from 'array-move'

import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import carouselCardBackgroundImageSlice from 'redux/slices/carouselCardBackgroundImage'
import { showToastMessage } from 'redux/slices/toasts'
import appSignal from 'services/appSignal'

import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.admin.carousel_cards')

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildCarouselPayload = (carousel) => {
  const data = _.pick(carousel, [
    'id',
    'name',
    'content',
    'buttonColor',
    'buttonText',
    'buttonUrl',
    'buttonStyle',
    'backgroundColor',
    'backgroundUrl',
    'contentAlignment',
    'startTime',
    'endTime',
    'orderPosition',
    'title',
    'subtitle',
    'coverImage',
    'coverImageCroppedArea',
  ])

  if (carousel?.targetingRules) {
    data.targetingRules = normalizeTargetingRules(carousel.targetingRules)
  }

  if (carousel?.item) {
    data.itemId = carousel.item.id
    data.itemType = carousel.item.type
  }

  return data
}

export const initialState = {
  carouselCardIds: [],
  adminCarouselCardIds: [],
  meta: {
    isLoading: false,
    isLoadingAdmin: false,
    isSaving: false,
    error: null,
    queryParams: {
      ...defaultPaginationParams,
    },
  },
}

const carouselCardSlice = createSlice({
  name: 'carouselCards',
  initialState,
  reducers: {
    clear(state, action) {
      state.meta = initialState.meta
    },
    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },
    setCarouselCardIds(state, action) {
      state.carouselCardIds = action.payload
    },
    setIsLoadingAdmin(state, action) {
      state.meta.isLoadingAdmin = action.payload
    },
    setAdminCarouselCardIds(state, action) {
      state.adminCarouselCardIds = action.payload
    },
    setIsSaving(state, action) {
      state.meta.isSaving = action.payload
    },
    setError(state, action) {
      state.meta.error = action.payload
    },
    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },
  },
})

_.assign(carouselCardSlice, {
  asyncActions: {
    admin: {
      createCarouselCard: (carouselCard, history) => async (dispatch) => {
        dispatch(carouselCardSlice.actions.setError(null))
        dispatch(carouselCardSlice.actions.setIsSaving(true))

        try {
          const response = await API.admin.carouselCards.create(buildCarouselPayload(carouselCard))
          dispatch(entitySlice.actions.add({ data: response.data }))
          const cardId = response.data.data.id

          history.push(`/admin/carousel_cards/${cardId}`)
        } catch (e) {
          const { error } = checkForError(getResponseOrThrow(e))

          dispatch(carouselCardSlice.actions.setError(error))
        } finally {
          dispatch(carouselCardSlice.actions.setIsSaving(false))
        }
      },

      updateCarouselCard: carouselCard => async (dispatch) => {
        dispatch(carouselCardSlice.actions.setError(null))
        dispatch(carouselCardSlice.actions.setIsSaving(true))

        try {
          const response = await API.admin.carouselCards.update(buildCarouselPayload(carouselCard))
          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
        } catch (e) {
          const { error } = checkForError(getResponseOrThrow(e))

          dispatch(carouselCardSlice.actions.setError(error))
        } finally {
          dispatch(carouselCardSlice.actions.setIsSaving(false))
        }
      },

      deleteCarouselCard: carouselCard => async _ => API.admin.carouselCards.destroy(carouselCard),

      fetchCarouselCard: carouselCardId => async (dispatch) => {
        dispatch(carouselCardSlice.actions.setIsLoadingAdmin(true))

        try {
          const response = await API.admin.carouselCards.fetch(carouselCardId)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(carouselCardSlice.actions.setIsLoadingAdmin(false))
        }
      },

      fetchCarouselCards: queryParams => async (dispatch) => {
        dispatch(carouselCardSlice.actions.setIsLoadingAdmin(true))

        try {
          const response = await API.admin.carouselCards.fetchAll(queryParams)
          const carouselCardIds = response.data.data.map(carouselCard => carouselCard.id)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(carouselCardSlice.actions.setAdminCarouselCardIds(carouselCardIds))

          if (queryParams.filter !== 'active_or_upcoming') {
            const newQueryParams = queryParamsFromHeaders(response)
            dispatch(carouselCardSlice.actions.setQueryParams(newQueryParams))
          }
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(carouselCardSlice.actions.setIsLoadingAdmin(false))
        }
      },

      reorderCarouselCards: (carouselCards, newIndex, oldIndex) => async (dispatch) => {
        const carouselCard = carouselCards[oldIndex]
        const newCarouselCardAdminIdsOrder = arrayMove(carouselCards.map(c => c.id), oldIndex, newIndex)

        dispatch(carouselCardSlice.actions.setError(null))
        dispatch(carouselCardSlice.actions.setIsSaving(true))

        // optimistically reorder client-side before response returns for better UI perf
        dispatch(carouselCardSlice.actions.setAdminCarouselCardIds(newCarouselCardAdminIdsOrder))

        try {
          const params = {
            id: carouselCard.id,
            orderPosition: newIndex,
          }

          await API.admin.carouselCards.update(params)

          dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))

          // reload the carousel cards to get the correct order
          dispatch(carouselCardSlice.asyncActions.admin.fetchCarouselCards('active_or_upcoming'))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(carouselCardSlice.actions.setIsSaving(false))
        }
      },
    },
    fetchCarouselCards: () => async (dispatch, getState) => {
      const inPreboardingExperience = getState().currentUser.inPreboardingExperience
      dispatch(carouselCardSlice.actions.setIsLoading(true))

      try {
        let response
        if (inPreboardingExperience) {
          response = await API.journey.preboarding.carouselCards.fetchAll()
        } else {
          response = await API.carouselCards.fetchAll()
        }

        const carouselCardIds = response.data.data.map(carouselCard => carouselCard.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(carouselCardSlice.actions.setCarouselCardIds(carouselCardIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(carouselCardSlice.actions.setIsLoading(false))
      }
    },
  },
})

_.assign(carouselCardSlice, {
  selectors: {
    getCarouselCards: () => state => state.carouselCards.carouselCardIds.map(id => build(state.entities, 'carouselCard', id)) || [],

    getCarouselCard: carouselCardId => state => build(state.entities, 'carouselCard', carouselCardId) || {},

    getAdminCarouselCards: () => state => state.carouselCards.adminCarouselCardIds.map(id => build(state.entities, 'carouselCard', id)) || [],

    getAdminMetaData: () => (state) => {
      const {
        isLoadingAdmin: isLoading,
        isSaving,
        error,
        queryParams,
      } = state.carouselCards.meta

      return {
        isLoading,
        isSaving,
        error,
        queryParams,
      }
    },
  },
})

export default carouselCardSlice
