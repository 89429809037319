
// from https://gist.github.com/diachedelic/0d60233dab3dcae3215da8a4dfdcd434?permalink_comment_id=4740506#gistcomment-4740506
function openDeepLink(url: string, options?: Partial<{ onOpen: () => void; onFail: () => void; waitTime: number }>) {
  let timeout
  let interval
  let visible = 'visible'

  const handleOpen = () => {
    window.removeEventListener('visibilitychange', () => true)
    options?.onOpen?.()
  }

  const handleResponse = () => {
    if (visible === 'visible') return options?.onFail?.()
    clearInterval(interval)
    handleOpen()
    return null
  }

  try {
    window.addEventListener('visibilitychange', e => (visible = (e.target as Document)?.visibilityState))
    timeout = setTimeout(handleResponse, options?.waitTime || 5000)

    interval = setInterval(() => {
      if (visible === 'hidden') {
        clearTimeout(timeout)
        handleResponse()
      }
    }, options?.waitTime || 5000)

    window.location.href = url
  } catch (error) {
    options?.onFail?.()
  }
}

export default openDeepLink
