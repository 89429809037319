import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import Modal from 'components/common/modal'
import SetExpirationModal from 'components/pages/actions_dropdown/setExpirationModal'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.pages.published_modal')

const womanWritingPath = '/images/illustrations/womanWritingWithCalendarBackground.svg'

const PagePublishedModal = ({
  className = '',
  toggle,
  page,
  visible,
}) => {
  const [openExpirationModal, setOpenExpirationModal] = useState(false)

  return (
    <>
      <Modal
        className={classNames('PagePublishedModal', className)}
        visible={visible && !openExpirationModal}
        toggle={toggle}
      >
        <div className='px-4 text-center mb-2'>
          <CdnSvg src={womanWritingPath} className='WomanWritingImage' />
          <h1 className='font-weight-700'>{I18N('your_page_is_published')}</h1>
          <div className='pb-3'>{I18N('pro_tip')}</div>
          <div className='d-flex justify-content-center align-items-center my-4'>
            <CancelButton onClick={toggle} className='mr-3 text-secondary font-weight-600'>
              {I18N('skip_for_now')}
            </CancelButton>
            <ButtonNarrow
              className='mr-3'
              onClick={() => setOpenExpirationModal(true)}
            >
              {I18N('set_expiration')}
            </ButtonNarrow>
          </div>

        </div>
      </Modal>
      {openExpirationModal && (
        <SetExpirationModal
          page={page}
          toggle={toggle}
          visible={visible}
        />
      )}
    </>
  )
}

export default PagePublishedModal
