import React, { useState } from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const chevronDownIconPath = '/images/chevronDownOutline.svg'
const chevronUpIconPath = '/images/chevronUpOutline.svg'

const CollapsibleCard = ({
  title,
  initialIsOpen = false,
  className = '',
  children,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  return (
    <div className={classNames('CollapsibleCard p-4', className)}>
      <div className='d-flex justify-content-between align-items-start'>
        <h6>{title}</h6>
        <div className='d-flex justify-content-start align-items-start'>
          <a className='ml-1 link-color' onClick={() => setIsOpen(!isOpen)}>
            {I18NCommon(isOpen ? 'hide' : 'show')}
            <CdnSvg className='ml-1 chevronIcon' src={isOpen ? chevronUpIconPath : chevronDownIconPath} />
          </a>
        </div>
      </div>

      {isOpen && children}
    </div>
  )
}

export default CollapsibleCard
