import React from 'react'

import { i18nMoment } from 'utils/i18nHelpers'

import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from './searchResultOption'

const newspaperIconPath = '/images/newspaperIcon.svg'

const ArticleOption = ({ option }) => {
  const { article, highlights } = option
  const { title, publishedAt } = { ...article, ...highlights }

  const content = highlights?.content || highlights?.cardContent

  return (
    <SearchResultOption
      image={<CdnSvg src={newspaperIconPath} className='SearchResultOptionImg mr-1' />}
      title={title}
      secondaryTexts={[i18nMoment(publishedAt).format('ll'), content]}
    />
  )
}

export default ArticleOption
