import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SettingCheckbox from 'components/settings/common/settingCheckbox'
import ReactSelect from 'components/common/react_select'
import { i18nPath, i18nLocalesSelectOptions } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import currentUserSlice from 'redux/slices/currentUser'
import SettingsGroupedCheckboxes from 'components/settings/common/settingsGroupedCheckboxes'
import settingSlice from 'redux/slices/settings'
import messageMobileApp from 'utils/messageMobileApp'

const I18N = i18nPath('views.settings.general_section')

const GeneralSettingsSection = () => {
  const dispatch = useDispatch()

  const {
    settings, slackEnabled, msTeamEnabled, smsEnabled, defaultLocale, availableLocales,
  } = useCurrentCompany()
  const currentUser = useCurrentUser()

  const { pushNotificationsEnabled } = useSelector(settingSlice.selectors.getMetaData())

  const currentLocale = currentUser.locale || defaultLocale
  const publicRecognitionSettings = [
    settings.celebrations.birthdays?.enabled && 'notifications_about_me.birthday',
    settings.shoutouts?.enabled && 'notifications_about_me.shoutout',
  ].filter(Boolean)

  const externalAlertCheckboxOptions = [
    'email',
    slackEnabled && 'slack',
    msTeamEnabled && 'msTeam',
    pushNotificationsEnabled && 'pushNotification',
    smsEnabled && 'sms',
  ].filter(Boolean)

  const privacyPreferencesCheckboxOptions = [
    'city',
    'phoneMobile',
  ].filter(Boolean)

  const localesSelectOptions = i18nLocalesSelectOptions(availableLocales)

  const handleLocaleChange = (locale) => {
    dispatch(currentUserSlice.asyncActions.update(currentUser, { locale: locale.value })).then(() => {
      messageMobileApp({ action: 'languageUpdate', value: locale.value })
      window.location.reload()
    })
  }

  return (
    <div className='GeneralSettingsSection'>
      <div className='mb-4'>
        <h5>{I18N('language')}</h5>
        <div className='LocaleSelect col-md-4 mb-4 pl-0'>
          <ReactSelect
            value={localesSelectOptions.find(option => option.value === currentLocale)}
            onChange={handleLocaleChange}
            options={localesSelectOptions}
          />
        </div>
      </div>

      {publicRecognitionSettings.length > 0 && (
        <div className='mb-4'>
          <h5>{I18N('public_recognition')}</h5>
          {publicRecognitionSettings.map(name => (
            <SettingCheckbox name={name} key={name} />
          ))}
        </div>
      )}

      <div className='mb-4'>
        <h5>{I18N('notification_channel')}</h5>
        <div className='text-secondary'>{I18N('send_me_alerts_via')}</div>
        <SettingsGroupedCheckboxes
          name='external_alert_type'
          options={externalAlertCheckboxOptions}
          allowUnmarkAll={false}
        />
      </div>

      <div className='mb-4'>
        <h5>{I18N('privacy_preferences')}</h5>
        <div className='text-secondary'>{I18N('privacy_preferences_label')}</div>
        <SettingsGroupedCheckboxes
          name='privacy'
          options={privacyPreferencesCheckboxOptions}
        />
      </div>

      {settings.comments?.articles?.enabled && (
        <div>
          <h5>{I18N('activity_involving_me')}</h5>
          <SettingCheckbox name='article_comment_notifications.creator' />
          <SettingCheckbox name='article_comment_notifications.author' />
          <SettingCheckbox name='article_comment_notifications.associated_user' />
          <SettingCheckbox name='article_comment_notifications.commentator' />
          <SettingCheckbox name='comments.mention_notifications' />
          <SettingCheckbox name='articles.mention_notifications' />
        </div>
      )}
    </div>
  )
}

export default GeneralSettingsSection
