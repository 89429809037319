import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'

import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import QuestionContent from 'components/qna/questionContent'
import { ButtonSmall } from 'components/common/buttons/buttonSmall'

const I18N = i18nPath('views.qna.events.question_moderation')

const QuestionModerationCard = (props) => {
  const { question } = props
  const [showRejectionForm, setShowRejectionForm] = useState(false)
  const [rejectionReason, setRejectionReason] = useState(null)
  const dispatch = useDispatch()

  const approveQuestion = () => {
    dispatch(qnaQuestionSlice.asyncActions.admin.approve(question))
  }

  const rejectQuestion = () => {
    dispatch(qnaQuestionSlice.asyncActions.admin.reject(question, rejectionReason))
    setShowRejectionForm(false)
  }

  return (
    <>
      <QuestionContent question={question} />
      {showRejectionForm && (
        <>
          <textarea
            placeholder={I18N('rejection_placeholder')}
            className='RejectionTextArea mt-3'
            onChange={e => setRejectionReason(e.target.value)}
          />
          <div className='row mt-3 d-flex px-4 justify-content-end'>
            <a className='text-secondary mr-3 align-self-end' onClick={() => setShowRejectionForm(false)}>{I18N('cancel')}</a>
            <ButtonSmall onClick={() => rejectQuestion()} disabled={false}>
              {I18N('reject_question')}
            </ButtonSmall>
          </div>
        </>
      )}

      {question.isPending && !showRejectionForm && (
        <div className='row mt-2 ml-1'>
          <ButtonSmall onClick={() => approveQuestion()} disabled={false}>
            {I18N('approve')}
          </ButtonSmall>
          <ButtonSmall onClick={() => setShowRejectionForm(true)} className='ml-2' variant='secondary' disabled={false}>
            {I18N('reject')}
          </ButtonSmall>
        </div>
      )}
    </>
  )
}

export default QuestionModerationCard
