import classNames from 'classnames'
import React from 'react'

import CdnSvg from 'components/common/cdnSvg'

interface EmptyStateCardProps {
  title: string
  iconPath: string
  text: string | React.ReactNode
  classname?: string
}

const EmptyStateCard = ({
  title,
  iconPath,
  text,
  classname,
}: EmptyStateCardProps) => (
  <div className={classNames('analytics-card', classname)}>
    <header>
      <h3>{title}</h3>
    </header>
    <div className='EmptyStateBody d-flex flex-column justify-content-center align-items-center h-100 px-2 py-5 w-100'>
      <CdnSvg src={iconPath} className='mb-4' />
      {typeof text === 'string' ? (
        <h6 className='text-center'>{text}</h6>
      ) : (
        <div className='text-center'>{text}</div>
      )}
    </div>

  </div>
)

export default EmptyStateCard
