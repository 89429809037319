import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import classNames from 'classnames'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import adminGroupTypeSlice from 'redux/slices/admin/groupTypes'

import { TableLoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SortableTable from 'components/common/tables/sortableTable'

const I18N = i18nPath('views.admin.group_type_list')

const columns = [
  {
    header: I18N('table_header.name'),
    accessor: groupType => <Link to={`/admin/team_types/${groupType.id}`}>{groupType.name}</Link>,
    style: { width: '30%' },
  },
  {
    header: I18N('table_header.targetable'),
    accessor: groupType => (groupType.targetable ? I18N('is_active_true') : I18N('is_active_false')),
    style: { width: '15%' },
  },
  {
    header: I18N('table_header.viewable'),
    accessor: groupType => (groupType.viewable ? I18N('is_active_true') : I18N('is_active_false')),
    style: { width: '15%' },
  },
  {
    header: I18N('table_header.followable'),
    accessor: groupType => (groupType.followable ? I18N('is_active_true') : I18N('is_active_false')),
    style: { width: '15%' },
  },
  {
    header: I18N('table_header.created_at'),
    accessor: groupType => i18nMoment(groupType.createdAt).format('LLL'),
    style: { width: '25%' },
  },
]


const GroupTypeListPage = () => {
  const dispatch = useDispatch()
  const { groupDisplayName } = useCurrentCompany()

  const groupTypes = useSelector(state => adminGroupTypeSlice.selectors.getGroupTypes()(state))
  const { isLoading, isSaving, isNotFound } = useSelector(
    state => adminGroupTypeSlice.selectors.getMetaData()(state)
  )

  useEffect(() => {
    dispatch(adminGroupTypeSlice.asyncActions.fetchGroupTypes())
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(adminGroupTypeSlice.asyncActions.setGroupTypeOrder(groupTypes, oldIndex, newIndex))
  }

  return (
    <div className='GroupTypeListPage'>
      <header className='AdminHeader d-flex justify-content-between flex-row'>
        <h2 className='mb-0'>{I18N('group_types', { groupDisplayName })}</h2>
        <div className='AdminButtonContainer'>
          <Link to='/admin/team_types/new'>
            <Button>
              {I18N('create_new_group_type', { groupDisplayName })}
            </Button>
          </Link>
        </div>
      </header>

      <TableLoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
        <main className='AdminContent'>
          <div className='col-12'>
            <SortableTable
              data={groupTypes}
              onSortEnd={onSortEnd}
              isSaving={isSaving}
              columns={columns}
            />
          </div>
        </main>
      </TableLoadingContainer>
    </div>
  )
}

export default GroupTypeListPage
