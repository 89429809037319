import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CollapsibleCard from 'components/common/collapsibleCard'
import IntegrationCard from 'components/admin/integrations/integrationCard'
import itAdminSlice from 'redux/slices/itAdmin'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AtlassianUninstallationComponent from 'components/admin/integrations/atlassianUninstallationComponent'
import ReactSelect from 'components/common/react_select'
import { ButtonSmallNarrow } from 'components/common/buttons'
import BloomfireInstallationComponent from 'components/admin/integrations/bloomfireInstallationComponent'
import CdnSvg from 'components/common/cdnSvg'
import { Link } from 'react-router-dom'

const I18N = i18nPath('views.integrations_page.search_section')

const googleDriveLogoPath = '/images/googleDriveIcon.svg'
const notionLogoPath = '/images/notionIcon.svg'
const bloomfireLogoPath = '/images/bloomfire.svg'
const atlassianLogoPath = '/images/integrations/atlassian.svg'
const oneDriveLogoPath = '/images/integrations/oneDriveLogo.svg'

const BLOOMFIRE_INTEGRATION_DOC_URL = 'https://gocleary.zendesk.com/hc/en-us/articles/17618025464987'

const InstallationComponent = ({ installUrl, testId }) => (
  <a className='btn btn-primary btn-sm btn-narrow my-1' href={installUrl} data-testid={testId}>
    {I18NCommon('install')}
  </a>
)

const SearchSection = () => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()

  const { settings } = currentCompany
  const isConfluenceSearchEnabled = settings.confluenceSearch?.enabled
  const isOneDriveAdminConsentEnabled = settings.oneDrive?.adminConsentEnabled

  const {
    atlassianEnabled,
    oneDriveAdminConsented,
    atlassianInstallUrl,
    googleDriveEnabled,
    bloomfireSearchEnabled,
    notionEnabled,
    notionInstallUrl,
    searchRestrictedUserColumns,
    searchRestrictionUserOptions,
  } = useSelector(itAdminSlice.selectors.getData())

  const { isSaving } = useSelector(itAdminSlice.selectors.getMetaData())

  const [selectedUserSearchRestrictionOptions, setSelectedUserSearchRestrictionOptions] = useState([])

  const uninstallIntegration = (name) => {
    dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration(name))
  }

  useEffect(() => {
    if (isConfluenceSearchEnabled && atlassianEnabled) {
      dispatch(itAdminSlice.asyncActions.admin.fetchConfluenceSpaces())
    }
  }, [])

  useEffect(() => {
    if (searchRestrictedUserColumns) {
      setSelectedUserSearchRestrictionOptions(
        searchRestrictionUserOptions.filter(option => searchRestrictedUserColumns.includes(option.value))
      )
    }
  }, [JSON.stringify(searchRestrictedUserColumns)])

  const updateSearchRestrictedUserColumns = () => {
    const selectedValues = selectedUserSearchRestrictionOptions.map(o => o.value)
    dispatch(itAdminSlice.asyncActions.admin.updateSearchRestrictedUserColumns(selectedValues))
  }

  const handleBloomfireInstallationSubmit = (bloomfireCredentials) => {
    dispatch(itAdminSlice.asyncActions.admin.installBloomfire(bloomfireCredentials))
  }

  return (
    <CollapsibleCard title={I18N('search')} className='mb-3'>
      <section className='SearchSection mb-3'>
        <IntegrationCard
          id='google_drive'
          logo={<CdnSvg src={googleDriveLogoPath} />}
          isInstalled={googleDriveEnabled}
          blockLink={(
            <Link
              className='mt-3 d-inline-block'
              to='/admin/app_integrations/google_drive'
            >
              {I18N('block_users_link_title')}
            </Link>
          )}
        />

        {isConfluenceSearchEnabled && (
          <IntegrationCard
            id='atlassian'
            installationComponent={<InstallationComponent installUrl={atlassianInstallUrl} testId='atlassian-link' />}
            uninstallationComponent={<AtlassianUninstallationComponent />}
            onUninstall={() => uninstallIntegration('atlassian')}
            logo={<CdnSvg src={atlassianLogoPath} />}
            isInstalled={atlassianEnabled}
          />
        )}

        <IntegrationCard
          id='notion'
          logo={<CdnSvg src={notionLogoPath} />}
          installationComponent={<InstallationComponent installUrl={notionInstallUrl} testId='notion-link' />}
          onUninstall={() => uninstallIntegration('notion')}
          isInstalled={notionEnabled}
        />

        <IntegrationCard
          id='bloomfire'
          logo={<CdnSvg src={bloomfireLogoPath} />}
          installationComponent={(
            <BloomfireInstallationComponent
              onSubmit={handleBloomfireInstallationSubmit}
              isSaving={isSaving}
              docLink={BLOOMFIRE_INTEGRATION_DOC_URL}
            />
          )}
          docLink={BLOOMFIRE_INTEGRATION_DOC_URL}
          docTitle={I18N('bloomfire_doc_title')}
          onUninstall={() => uninstallIntegration('bloomfire')}
          isInstalled={bloomfireSearchEnabled}
        />

        {isOneDriveAdminConsentEnabled && (
          <IntegrationCard
            id='one_drive_admin_consent'
            installationComponent={(
              <InstallationComponent
                installUrl='/auth/microsoft_azure_app/one_drive_admin_consent_authorize_url'
                testId='one-drive-link'
              />
            )}
            onUninstall={() => uninstallIntegration('one_drive')}
            logo={<CdnSvg src={oneDriveLogoPath} />}
            isInstalled={oneDriveAdminConsented}
          />
        )}

        <div className='full-width'>
          <div className='mb-1'>{I18N('search_restrictions_user_columns')}</div>
          <ReactSelect
            value={selectedUserSearchRestrictionOptions}
            isMulti
            onChange={selectedOptions => setSelectedUserSearchRestrictionOptions(selectedOptions)}
            options={searchRestrictionUserOptions}
            placeholder={I18N('search_restrictions_user_columns_placeholder')}
          />
          <div className='text-small text-secondary mt-1'>{I18N('search_restrictions_user_columns_footer')}</div>
          <div className='text-small text-danger mb-2'>{I18N('search_restrictions_warn')}</div>
          <ButtonSmallNarrow
            onClick={updateSearchRestrictedUserColumns}
            disabled={isSaving}
            showLoadingSpinner={isSaving}
          >
            {I18N('save_search_restrictions')}
          </ButtonSmallNarrow>
        </div>
      </section>
    </CollapsibleCard>
  )
}

export default SearchSection
