import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import FormErrorList from 'components/errors/formErrorList'
import { i18nPath } from 'utils/i18nHelpers'
import userSkillTypeSlice from 'redux/slices/userSkillTypes'
import UserSkillTypeForm from 'components/admin/userSkillTypeForm'

const I18N = i18nPath('views.admin.user_skill_types')

const EditUserSkillTypePage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [workingCopy, setWorkingCopy] = useState({})

  const { userSkillTypeId } = params

  const userSkillType = useSelector(userSkillTypeSlice.selectors.getUserSkillType(userSkillTypeId))
  const { isLoadingAdmin, isSaving, error } = useSelector(userSkillTypeSlice.selectors.getMetaData())

  const isMissingRequiredFields = !workingCopy.name || !workingCopy.slug
  const changesPresent = !_.isEqual(userSkillType, workingCopy)
  const disableSaveButton = isMissingRequiredFields || !changesPresent

  useEffect(() => {
    dispatch(userSkillTypeSlice.asyncActions.admin.fetchUserSkillType(userSkillTypeId))
  }, [userSkillTypeId])

  useEffect(() => {
    if (userSkillType?.id) {
      setWorkingCopy(userSkillType)
    }
  }, [userSkillType?.id])

  useEffect(() => {
    if (!_.isEmpty(userSkillType)) {
      setWorkingCopy(userSkillType)
    }
  }, [userSkillType?.updatedAt])

  const saveUserSkillType = () => {
    dispatch(userSkillTypeSlice.asyncActions.admin.updateUserSkillType(workingCopy))
  }

  const deleteUserSkillType = () => {
    if (confirm(I18N('delete_confirm'))) {
      dispatch(userSkillTypeSlice.asyncActions.admin.deleteUserSkillType(userSkillType)).then(() => {
        history.push('/admin/user_skill_types')
      })
    }
  }

  if (!userSkillType?.id || isLoadingAdmin) {
    return null
  }

  return (
    <div className='EditUserSkillTypePage'>
      <header className='AdminHeader'>
        <h3>{I18N('edit_user_skill_type')}</h3>
      </header>

      <main className='AdminContent'>
        <UserSkillTypeForm userSkillType={workingCopy} setUserSkillType={setWorkingCopy} />

        {error && <FormErrorList error={error} />}

        <div className='d-flex mt-3'>
          <Button disabled={disableSaveButton} onClick={saveUserSkillType} showLoadingSpinner={isSaving}>
            {I18N('update')}
          </Button>
          <Button variant='danger' className='ml-2' onClick={deleteUserSkillType}>
            {I18N('delete')}
          </Button>
        </div>
      </main>
    </div>
  )
}

export default EditUserSkillTypePage
