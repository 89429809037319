import React from 'react'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import ProgressBar from 'components/common/progressBar'
import SmartTable from 'components/common/tables/smartTable'
import BlueprintLink from 'components/admin/journeys/blueprintLink'
import JourneyStatusLabel from 'components/admin/journeys/journeyStatusLabel'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'

import OnboardingPhaseText from 'components/admin/journeys/onboardingPhaseText'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import { ONBOARDING, OFFBOARDING, CUSTOM } from 'utils/journeys/journeyBlueprintTypes'

const I18N = i18nPath('views.admin.journeys.journeys.table')

export const COMPLETE = 'complete'

const JourneysTable = ({
  journeys = [],
  queryParams = {},
  onParamsChange,
  type,
  onSortChange,
  isLoading,
}) => {
  const {
    page, totalPages, perPage, total,
  } = queryParams

  const columns = [
    {
      header: I18N('name'),
      accessor: d => <EmployeeOrCorrespondentLink user={d.user} linkToAdmin />,
    },
    {
      header: I18N('journey'),
      accessor: d => <BlueprintLink className='d-flex justify-content-between align-items-center' journeyBlueprint={d.journeyBlueprint} />,
    },
    type === ONBOARDING && {
      header: I18N('start_date'),
      id: 'users.start_date',
      accessor: d => <span>{i18nMoment(d.user?.startDate).format('ll')}</span>,
    },
    type === OFFBOARDING && {
      header: I18N('end_date'),
      id: 'users.end_date',
      accessor: d => <span>{i18nMoment(d.user?.endDate).format('ll')}</span>,
    },
    [COMPLETE, CUSTOM].includes(type) && {
      header: I18N('journey_created_at'),
      id: 'created_at',
      accessor: d => <span>{i18nMoment(d.createdAt).format('ll')}</span>,
    },
    type === ONBOARDING && {
      header: I18N('phase'),
      accessor: d => <OnboardingPhaseText onboardingPhase={d.user?.onboardingPhase} />,
    },
    type === COMPLETE && {
      header: I18N('completed_date'),
      accessor: d => <span>{i18nMoment(d?.completedAt).format('ll')}</span>,
    },
    {
      header: I18N('progress'),
      accessor: d => <ProgressBar completedCount={d.completedStepsCount} totalCount={d.totalStepsCount} />,
    },
    {
      header: I18N('status'),
      accessor: d => <JourneyStatusLabel journey={d} />,
    },
  ].filter(Boolean)


  return (
    <TableLoadingContainer
      isLoading={isLoading}
      loaderClassName='w-100 justify-content-center d-flex'
      useCirclesLoadingIndicator
    >
      <div className='JourneyTable'>
        <SmartTable
          className='white-bg-table'
          data={journeys}
          columns={columns}
          page={page}
          pages={totalPages}
          totalCount={total}
          perPage={perPage}
          showPagination={true}
          onPaginationClick={onParamsChange}
          onSortChange={onSortChange}
          hideWhenEmpty={true}
        />
      </div>
    </TableLoadingContainer>
  )
}

export default JourneysTable
