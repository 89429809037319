import React from 'react'
import AdminBanner from 'components/common/adminBanner'
import { ErrorList } from 'components/errors/formErrorList'

const UserFormErrorBanner = ({
  error = null,
  title = null,
}) => {
  if (_.isNull(error)) {
    return null
  }

  return (
    <AdminBanner color='red' className='w-100 py-3 d-inline-flex align-items-center justify-content-start'>
      <div className='UserFormErrorBanner'>
        {title && (
          <div className='title text-white mb-1'>
            {title}
          </div>
        )}
        <div className='errors-list-body'>
          <ErrorList error={error} useTitleCase />
        </div>
      </div>
    </AdminBanner>
  )
}

export default UserFormErrorBanner
