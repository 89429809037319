/* eslint-disable import/prefer-default-export */
export const getStatsValue = (stat, key) => (stat && stat[key] ? stat[key] : 0)

export const safePercentage = (value, total, precision = 2) => I18n.toPercentage(
  total === 0 ? 0 : (value / total) * 100,
  { precision }
)

export const removeSideZerosValues = (data, field) => {
  const copy = [...data]

  while (copy.length > 0 && copy[0][field] === 0) {
    copy.shift()
  }
  while (copy.length > 0 && copy[copy.length - 1][field] === 0) {
    copy.pop()
  }

  return copy
}
