import { createSlice } from '@reduxjs/toolkit'
import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import build from 'redux-object'
import { defaultActions } from 'redux/slices/utils/commonReducers'
import { showToastMessage } from 'redux/slices/toasts'
import { checkForError } from 'utils/errorHandling'
import appSignal from 'services/appSignal'
import queryParamsFromHeaders, { defaultPaginationParams, PaginationParams } from 'utils/queryParamsFromHeaders'
import { ReduxState } from 'redux/redux'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { notifySlackError } from 'utils/slack'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

export const buildArticleWorkingCopy = article => ({
  ...article,
  sendSlackNotificationAs: article.chatNotificationSender ? 'user' : 'bot',
})

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildArticlePayload = (article) => {
  // TODO: this should be ArticleType, but we don't have one
  // pushing with any to fix the issue ASAP
  const articleAttributes = _.pick<any, any>(article, [
    'id',
    'title',
    'video',
    'cardContent',
    'content',
    'chatNotificationContent',
    'draft',
    'slug',
    'commentable',
    'publishedAt',
    'articleTypeId',
    'restrictAccess',
    'displayAuthorText',
    'authorIds',
    'tagIds',
    'tagsAttributes',
    'notificationChannelOverrides',
    'acknowledgementDueAt',
    'alwaysShowInFeed',
    'distributionLists',
    'groupId',
    'notificationPreference',
    'thumbnailImage',
    'thumbnailImageCroppedArea',
  ])

  if (article.articleType) {
    articleAttributes.articleTypeId = article.articleType.id
  }

  const notificationChannels = article.notificationChannels || []

  if (article.sendSlackNotificationAs) {
    if (article.sendSlackNotificationAs === 'user' && notificationChannels.length > 0) {
      articleAttributes.chatNotificationSenderId = article.chatNotificationSender?.id
    } else {
      articleAttributes.chatNotificationSenderId = null
    }
  }

  if (article.tags) {
    // eslint-disable-next-line no-underscore-dangle
    articleAttributes.tagIds = article.tags.filter(tag => !tag.__isNew__).map(tag => tag.id)
    // eslint-disable-next-line no-underscore-dangle
    articleAttributes.tagsAttributes = article.tags.filter(tag => tag.__isNew__).map(tag => ({
      name: tag.name,
    }))
  }

  if (article.authors) {
    articleAttributes.authorIds = article.authors.map(author => author.id)
  }

  if (article.targetingRules) {
    articleAttributes.targetingRules = normalizeTargetingRules(article.targetingRules)
  }

  if (article.notificationChannels) {
    articleAttributes.notificationChannelIds = article.notificationChannels.map(c => c.id)
  }

  return articleAttributes
}

export type AdminArticleState = {
  articleIds: string[],
  articleId?: string,
  editableGroupIds: string[],
  meta: DefaultMetaType & {
    queryParams: PaginationParams,
    isCreatingArticle: boolean,
    isCopying: boolean,
    isSendingPreviewEmail: boolean,
    isSendingPreviewChatMessage: boolean,
    isResendingNotifications: boolean,
    isResendingEmail: boolean,
    isDeleting: boolean,
  },
}

export const initialState: AdminArticleState = {
  articleIds: [],
  articleId: undefined,
  editableGroupIds: [],
  meta: {
    queryParams: defaultPaginationParams,
    isNotFound: false,
    isLoading: false,
    isCreatingArticle: false,
    error: null,
    isCopying: false,
    isSendingPreviewEmail: false,
    isSendingPreviewChatMessage: false,
    isResendingNotifications: false,
    isResendingEmail: false,
    isSaving: false,
    isDeleting: false,
  },
}

const adminArticleSlice = createSlice({
  name: 'adminArticles',
  initialState,
  reducers: {
    ...defaultActions,

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    isCreatingArticle(state, action) {
      state.meta.isCreatingArticle = action.payload
    },

    isCopying(state, action) {
      state.meta.isCopying = action.payload
    },

    isSendingPreviewEmail(state, action) {
      state.meta.isSendingPreviewEmail = action.payload
    },

    isSendingPreviewChatMessage(state, action) {
      state.meta.isSendingPreviewChatMessage = action.payload
    },

    isResendingNotifications(state, action) {
      state.meta.isResendingNotifications = action.payload
    },

    isResendingEmail(state, action) {
      state.meta.isResendingEmail = action.payload
    },

    isSaving(state, action) {
      state.meta.isSaving = action.payload
    },

    isDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },

    setArticleIds(state, action) {
      state.articleIds = action.payload
    },

    removeFromArticleIds(state, action) {
      state.articleIds = state.articleIds.filter(id => id !== action.payload)
    },

    setArticleId(state, action) {
      state.articleId = action.payload
    },

    clearArticleId(state) {
      state.articleId = undefined
    },

    setEditableGroupIds(state, action) {
      state.editableGroupIds = action.payload
    },
  },
})

const asyncActions = {
  fetchArticles: params => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isLoading(true))

    try {
      const response = await API.admin.articles.fetchAll(params)
      dispatch(entitySlice.actions.add({ data: response.data }))
      const articleIds = response.data.data.map(article => article.id)
      dispatch(adminArticleSlice.actions.setArticleIds(articleIds))
      dispatch(adminArticleSlice.actions.setQueryParams(queryParamsFromHeaders(response)))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.isLoading(false))
    }
  },

  fetchArticle: (articleId, params = {}) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isLoading(true))

    try {
      const response = await API.admin.articles.fetch(articleId, params)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(adminArticleSlice.actions.setArticleId(articleId))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.isLoading(false))
    }
  },

  copyArticle: workingCopy => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isCopying(true))

    try {
      const response = await API.admin.articles.copyArticle(_.pick(workingCopy, ['id', 'title']))
      dispatch(entitySlice.actions.add({ data: response.data }))
      const articleId = response.data.data.id
      dispatch(adminArticleSlice.actions.setArticleId(articleId))
      window.location.href = `/admin/articles/${articleId}`
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.isCopying(false))
    }
  },

  resendEmail: (articleId, userId) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isResendingEmail(true))

    try {
      await API.admin.articles.resendEmail(articleId, userId)
      dispatch(showToastMessage({ message: I18n.t('views.admin.article_stats.email_resent_to_user'), type: 'success' }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))

      const reason = e.response.data?.reason

      if (['user_has_external_alerts_disabled', 'user_cant_view_article'].includes(reason)) {
        dispatch(showToastMessage({ message: I18n.t(`views.admin.article_stats.errors.${reason}`), type: 'error' }))
      }
    } finally {
      dispatch(adminArticleSlice.actions.isResendingEmail(false))
    }
  },

  sendPreviewEmail: (articleId, userIds, onSuccess = () => {}) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isSendingPreviewEmail(true))

    try {
      await API.admin.articles.sendPreviewEmail(articleId, userIds)
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.isSendingPreviewEmail(false))
    }
  },

  sendPreviewChatMessage: (articleId, userIds, onSuccess = () => {}) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isSendingPreviewChatMessage(true))

    try {
      await API.admin.articles.sendPreviewChatMessage(articleId, userIds)
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.isSendingPreviewChatMessage(false))
    }
  },

  resendNotifications: articleId => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isResendingNotifications(true))

    try {
      await API.admin.articles.resendNotifications(articleId)
      dispatch(showToastMessage({ message: I18n.t('views.admin.article_editor.notifications_sent'), type: 'success' }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(showToastMessage({ message: I18n.t('views.admin.article_editor.failed_to_resend_notifications'), type: 'error' }))
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.isResendingNotifications(false))
    }
  },

  createArticle: (workingCopy, onSuccess) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isSaving(true))
    dispatch(adminArticleSlice.actions.setError(null))

    try {
      const response = await API.admin.articles.create(workingCopy)
      dispatch(entitySlice.actions.add({ data: response.data }))
      const articleId = response.data.data.id
      dispatch(adminArticleSlice.actions.setArticleId(articleId))
      onSuccess(articleId)
    } catch (e) {
      if (e.response?.status !== 423 || e.response?.data?.error !== 'article_not_ready_to_publish') {
        notifySlackError({
          action: 'create',
          exception: e,
          modelType: 'Article',
        })
        appSignal.sendErrorUnlessClearyBackendError(e)
      }
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
      dispatch(showToastMessage({ message: I18n.t('views.admin.article_editor.error_creating_article'), type: 'error' }))
    } finally {
      dispatch(adminArticleSlice.actions.isSaving(false))
    }
  },

  updateArticle: (workingCopy, onSuccess = () => {}) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isSaving(true))
    dispatch(adminArticleSlice.actions.setError(null))

    try {
      const response = await API.admin.articles.update(workingCopy)
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(adminArticleSlice.actions.setError(null))
      onSuccess()
    } catch (e) {
      if (e.response?.status !== 423 || e.response?.data?.error !== 'article_not_ready_to_publish') {
        notifySlackError({
          action: 'update',
          exception: e,
          modelType: 'Article',
          modelId: workingCopy.id,
        })
        appSignal.sendErrorUnlessClearyBackendError(e)
      }
      const { error } = checkForError(e.response)
      const message = error?.message || I18n.t('views.admin.article_editor.error_updating_article')
      dispatch(adminArticleSlice.actions.setError(error))
      dispatch(showToastMessage({ message, type: 'error' }))
    } finally {
      dispatch(adminArticleSlice.actions.isSaving(false))
    }
  },

  deleteArticle: (article, unblockAndSetRedirectRoute) => async (dispatch) => {
    dispatch(adminArticleSlice.actions.isDeleting(true))

    try {
      await API.admin.articles.destroy(article)
      dispatch(entitySlice.actions.remove({ id: article.id, type: 'article' }))
      unblockAndSetRedirectRoute('/admin/articles')
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    } finally {
      dispatch(adminArticleSlice.actions.isDeleting(false))
    }
  },

  fetchEditableGroups: () => async (dispatch) => {
    try {
      const response = await API.groups.fetchCurrentUserEditableGroups()
      const editableGroupIds = response.data.data.map(editableGroup => editableGroup.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(adminArticleSlice.actions.setEditableGroupIds(editableGroupIds))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(adminArticleSlice.actions.setError(error))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.adminArticles.meta,

  getArticles: () => (state: ReduxState) => state.adminArticles.articleIds.map(id => build(state.entities, 'article', id)).filter(Boolean),

  getArticle: () => (state: ReduxState) => (state.adminArticles.articleId ? build(state.entities, 'article', state.adminArticles.articleId) : null),

  getArticleById: articleId => (state: ReduxState) => build(state.entities, 'article', articleId),

  getEditableGroups: () => (state: ReduxState) => state.adminArticles.editableGroupIds?.map(id => build(state.entities, 'group', id)) || [],
}

export default {
  ...adminArticleSlice,
  selectors,
  asyncActions,
}
