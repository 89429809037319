import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import adminAlertSlice from 'redux/slices/adminAlerts'
import { Link } from 'react-router-dom'

const I18N = i18nPath('views.admin.admin_alerts')

const columns = [
  {
    header: I18N('name'),
    col: 'col-name',
    id: 'displayName',
    accessor: adminAlert => adminAlert.displayName,
  },
  {
    header: I18N('users_count'),
    col: 'col-users-count',
    id: 'usersCount',
    accessor: adminAlert => <Link to={`/admin/admin_alerts/${adminAlert.name}`}>{adminAlert.usersCount}</Link>,
  },
]

const AdminAlertsTable = () => {
  const dispatch = useDispatch()

  const { isLoading } = useSelector(adminAlertSlice.selectors.getMetaData())
  const alertsCountByName = useSelector(adminAlertSlice.selectors.getCountByName())

  const [adminAlertsData, setAdminAlertsData] = useState([])

  const handleSortChange = ({ sortBy, sortDir }) => {
    setAdminAlertsData(_.orderBy(adminAlertsData, [sortBy], [sortDir]))
  }

  useEffect(() => {
    const mappedAlerts = Object.entries(alertsCountByName).map(([key, value]) => ({
      name: _.snakeCase(key),
      displayName: I18N(_.snakeCase(key)),
      usersCount: value,
    }))

    setAdminAlertsData(mappedAlerts)
  }, [JSON.stringify(alertsCountByName)])

  useEffect(() => {
    dispatch(adminAlertSlice.asyncActions.admin.getCountByName())
  }, [])

  return (
    <TableLoadingContainer isLoading={isLoading}>
      <SmartTable
        className='AdminAlertsTable white-bg-table'
        data={adminAlertsData}
        columns={columns}
        onSortChange={handleSortChange}
      />
    </TableLoadingContainer>
  )
}

export default AdminAlertsTable
