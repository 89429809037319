import React from 'react'
import { Bar } from 'react-chartjs-2'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { Tooltip, TooltipPosition } from 'chart.js'

interface HorizontalStackedBarGraphProps {
  labels: string[]
  datasets: {
    label?: string
    data: number[]
  }[]
  classname?: string
  showDatalabels?: boolean
  generateTooltipLabel?: (data: number, category: string) => string
}

const HorizontalStackedBarGraph = ({
  labels,
  datasets,
  classname,
  showDatalabels = false,
  generateTooltipLabel = undefined,
}: HorizontalStackedBarGraphProps) => {
  const currentCompany = useCurrentCompany()
  const { textColorSecondary } = currentCompany.customStyles.variables.colors

  const max = datasets.flatMap(d => d.data).reduce((a, b) => a + b, 0)

  const options = {
    indexAxis: 'y' as const,
    scales: {
      x: {
        stacked: true,
        display: false,
        max,
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    layout: {
      padding: {
        top: showDatalabels ? 20 : 0,
      },
    },
    animation: { duration: 0 }, // Disable animations
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: !!generateTooltipLabel,
        displayColors: false,
        callbacks: {
          label: (context) => {
            if (!generateTooltipLabel) return ''

            return generateTooltipLabel(context.parsed.x, context.dataset.label)
          },
          title: () => '',
        },
        padding: 10,
        position: 'centerPositioner' as const,
        yAlign: 'top' as const,
      },
      datalabels: {
        display: showDatalabels,
        color: textColorSecondary,
        anchor: 'center' as const,
        align: 'top' as const,
        offset: 15,
        formatter: (value) => {
          if (value > 0) return `${value}%`
          return ''
        },
      },
    },
  }

  const data = {
    labels,
    datasets,
  }

  return (
    <div className={classname}>
      <Bar data={data} options={options} height={showDatalabels ? 40 : 20} />
    </div>
  )
}

export default HorizontalStackedBarGraph

declare module 'chart.js' {
  interface TooltipPositionerMap {
      centerPositioner: (elements: any[], eventPosition: { x: number, y: number }) => TooltipPosition;
  }
}

Tooltip.positioners.centerPositioner = function (elements, eventPosition) {
  const chart = this.chart
  const width = chart.width
  const values = chart.data.datasets.map(dataset => dataset.data[0])
  const total = values.reduce((acc, value) => acc + value, 0)
  const barBreakpoints = values.reduce((acc, value, index) => {
    const valueWidth = (value * width) / total

    return [...acc, acc[index] + valueWidth]
  }, [0])

  const barIndex = barBreakpoints.findIndex(breakpoint => eventPosition.x <= breakpoint)

  if (barIndex === -1) {
    return { x: 0, y: 0 }
  } else {
    const barWidth = barBreakpoints[barIndex] - barBreakpoints[barIndex - 1]
    const barCenter = barBreakpoints[barIndex - 1] + barWidth / 2

    return {
      x: barCenter,
      y: chart.height / 2,
    }
  }
}
