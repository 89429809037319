import React from 'react'
import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath } from 'utils/i18nHelpers'
import UserEditableField from 'components/admin/users/userEditor/userEditableField'
import UserDatePickerField from 'components/admin/users/userEditor/userDatePickerField'
import FormTiptapEditorField from 'components/form_fields/formTiptapEditorField'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'

const I18N = i18nPath('views.admin.users')

const UserTiptapField = ({
  user,
  setUser = () => { },
  fieldName,
  className = '',
  placeholder = '',
  label = '',
}) => {
  const currentValue = user[fieldName] || ''

  const onChange = (value) => {
    setUser({ ...user, [fieldName]: value })
  }

  return (
    <FormTiptapEditorField
      label={label}
      className={className}
      currentValue={currentValue}
      fieldName={fieldName}
      toolbarItems={SMALL_TOOLBAR}
      editorConfiguration={{
        ...SIMPLE_EDITOR_CONFIGURATION,
        placeholder,
      }}
      onChange={onChange}
    />
  )
}

const PersonalInformationSection = ({
  user = null,
  setUser = () => { },
  initialIsOpen = false,
}) => {
  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const profileFields = settings.profile.fields
  const companyUsesWorkday = currentCompany.hrisIntegrationName === 'Workday'

  return (
    <CollapsibleCard
      title={I18N('sections.personal_information')}
      initialIsOpen={initialIsOpen}
      className='mt-3'
    >
      <p className='text-secondary'>{I18N('sections.personal_information_note')}</p>
      <div className='PersonalInformationSection mt-4'>
        <UserEditableField
          user={user}
          setUser={setUser}
          label={I18N('preferred_pronouns')}
          fieldName='displayPronoun'
          placeholder={I18N('enter_preferred_pronouns')}
        />
        {companyUsesWorkday && (
          <UserEditableField
            user={user}
            label={I18N('synced_pronoun')}
            fieldName='syncedPronoun'
            disabled
          />
        )}
        <UserDatePickerField
          className='birthday-field OnlyDate'
          fieldName='birthday'
          user={user}
          setWorkingCopy={setUser}
          footnote={user.wantsBirthdayNotificationsAboutMe ? I18N('birthday_recognition_enabled_footnote') : I18N('birthday_recognition_disabled_footnote')}
        />
        <UserTiptapField
          className='bio-field'
          label={I18N('bio')}
          user={user}
          setUser={setUser}
          fieldName='bio'
          placeholder={I18N('enter_bio')}
        />
        {
          [1, 2, 3].map(numberSuffix => (
            <React.Fragment key={`customField${numberSuffix}`}>
              {profileFields[`customField${numberSuffix}`] && (
                <UserTiptapField
                  className='custom-field'
                  user={user}
                  setUser={setUser}
                  label={currentCompany[`customField${numberSuffix}Label`]}
                  placeholder={currentCompany[`customField${numberSuffix}InputPlaceholder`]}
                  fieldName={`customField${numberSuffix}`}
                />
              )}
            </React.Fragment>
          )
          )
        }
      </div>
    </CollapsibleCard>
  )
}

export default PersonalInformationSection
