import React from 'react'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const peopleRoundedSquareIconPath = '/images/peopleRoundedSquareIcon.svg'

interface EmojiOrPlaceholderProps {
  className?: string
  selectedEmoji?: string
  placeholderSrc?: string
}

const EmojiOrPlaceholder = ({
  className = '',
  selectedEmoji,
  placeholderSrc = peopleRoundedSquareIconPath,
}: EmojiOrPlaceholderProps) => (
  <div className={classNames('EmojiOrPlaceholder d-flex align-items-center', className)}>
    {selectedEmoji || <CdnSvg src={placeholderSrc} className='emoji-placeholder' />}
  </div>
)

export default EmojiOrPlaceholder
