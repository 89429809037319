import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import { Bar } from 'react-chartjs-2'
import { getStatsValue } from 'pages/admin/articles/stats/helpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { transparent } from 'utils/color'
import { numberPresent } from 'components/common/utils'

const I18N = i18nPath('views.admin.article_stats')

const options = {
  maintainAspectRatio: false,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      precision: 0,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}

const EngagementByType = ({ articleStat }) => {
  const currentCompany = useCurrentCompany()
  const {
    slackEnabled,
    customStyles: {
      variables: {
        colors: {
          highlightColor,
        },
      },
    },
  } = currentCompany

  const articleViewsUniqueCount = getStatsValue(articleStat, 'articleViewsUniqueCount')
  const cardImpressionsUniqueCount = getStatsValue(articleStat, 'cardImpressionsUniqueCount')
  const emailUniqueOpened = getStatsValue(articleStat, 'emailUniqueOpened')
  const slackLinksVisitedUniqueCount = getStatsValue(articleStat, 'slackLinksVisitedUniqueCount')

  const labels = [
    I18N('stat.article_views'),
    I18N('stat.feed_impressions'),
    I18N('stat.email_opens'),
    slackEnabled && I18N('stat.slack_links_clicked'),
  ].filter(Boolean)

  const chartData = [
    articleViewsUniqueCount,
    cardImpressionsUniqueCount,
    emailUniqueOpened,
    slackEnabled && slackLinksVisitedUniqueCount,
  ].filter(numberPresent)

  const opacityDiffPerBar = 1 / labels.length

  const colors = labels.map((_l, i) => transparent(highlightColor, 1 - (opacityDiffPerBar * i)))

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        borderColor: colors,
        backgroundColor: colors,
      },
    ],
  }


  return (
    <Card cardBodyClassName='EngagementByType'>
      <h5 className='mb-0 ml-1'>{I18N('engagement_by_type')}</h5>
      <hr />

      <div style={{ position: 'relative', height: '200px' }}>
        <Bar options={options} data={data} />
      </div>
    </Card>
  )
}

export default EngagementByType
