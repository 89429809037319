import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { I18NCommon } from 'utils/i18nHelpers'

const DEFAULT_TOPICS_TO_SHOW = 3

const AiAnswersInsightsTheme = ({ theme }) => {
  const [showAllTopics, setShowAllTopics] = useState(false)
  const topics = showAllTopics ? theme.topics : theme.topics.slice(0, DEFAULT_TOPICS_TO_SHOW)
  const showHasMoreTopics = theme.topics.length > DEFAULT_TOPICS_TO_SHOW && !showAllTopics

  return (
    <div className='w-100'>
      <h5 className='mb-0'>{theme.title} ({theme.answersCount})</h5>
      <div className='mb-3'>{theme.description}</div>
      <div className='d-flex gap-2 align-items-center flex-wrap'>
        {topics.map(topic => (
          <Topic key={topic.id} topic={topic} />
        ))}
        {showHasMoreTopics && (
          <div
            className='text-smallest link-color pointer'
            onClick={() => setShowAllTopics(true)}
          >
            +{theme.topics.length - DEFAULT_TOPICS_TO_SHOW} {I18NCommon('more')}
          </div>
        )}
      </div>
    </div>
  )
}

const Topic = ({ topic }) => (
  <Link
    className='AiAnswersTopic bg-highlight-color-tint-90 px-2 py-1 d-flex justify-content-between align-items-center rounded gap-2'
    to={`/platform_analytics/ai_answers/topics/${topic.id}?topic_title=${topic.title}`}
  >
    <div className='AiAnswersTopicTitle text-smallest text-primary'>{topic.title}</div>
    <div className='text-smallest bg-white rounded px-2 py-1'>{topic.answersCount}</div>
  </Link>
)

export default AiAnswersInsightsTheme
