import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonSecondary, ButtonSmallNarrow } from 'components/common/buttons'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { isAudienceValid } from 'utils/audience'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import ScrollToModal from 'components/common/scrollToModal'
import FormCheck from 'components/form_fields/formCheck'
import DaysSelect from 'components/common/daysSelect'
import TriggerSelect from 'components/admin/journeys/triggerSelect'
import MatchingCriteriaSelector from 'components/admin/journeys/onboardingBuddies/matchingCriteriaSelector'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AudienceSelector from 'components/common/audience/audienceSelector'
import companyConfigurationSlice, { ONBOARDING_BUDDY_SETTINGS } from 'redux/slices/companyConfigurations'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.settings')

const SettingsModal = ({ isModalOpen, onClose }) => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const buddySettings = settings.journeys.onboardingBuddies
  const targetingOptions = useTargetingOptions()
  const excludedUsers = useSelector(onboardingBuddySlice.selectors.getExcludedUsers())
  const { isLoadingExcludedUsers } = useSelector(onboardingBuddySlice.selectors.getMetaData())
  const buddyConfig = useSelector(
    companyConfigurationSlice.selectors.getCompanyConfiguration(ONBOARDING_BUDDY_SETTINGS)
  )
  const { isLoading: isLoadingConfig } = useSelector(companyConfigurationSlice.selectors.getMetaData())
  const { isSaving, isSendingOptInEmails } = useSelector(onboardingBuddySlice.selectors.getMetaData())

  const isLoading = isLoadingExcludedUsers || isLoadingConfig

  const [workingCopy, setWorkingCopy, updateWorkingCopy] = useUpdatableState(settings.onboardingBuddySettings)

  useEffect(() => {
    if (buddyConfig?.updatedAt) {
      setWorkingCopy(buddyConfig.value)
    }
  }, [buddyConfig?.updatedAt])

  const buddiesAudience = {
    targetingRules: workingCopy.eligibleBuddiesTargetingRules,
  }
  const updateBuddiesAudience = ({ targetingRules }) => {
    updateWorkingCopy({ eligibleBuddiesTargetingRules: targetingRules })
  }
  const isBuddiesAudienceValid = isAudienceValid(buddiesAudience)

  const newHiresAudience = {
    targetingRules: workingCopy.eligibleNewHiresTargetingRules,
  }
  const updateNewHiresAudience = ({ targetingRules }) => {
    updateWorkingCopy({ eligibleNewHiresTargetingRules: targetingRules })
  }
  const isNewHiresAudienceValid = isAudienceValid(newHiresAudience)

  const isSaveButtonDisabled = isSaving || !isBuddiesAudienceValid || !isNewHiresAudienceValid

  const [optInEmailLastSentAt, setOptInEmailLastSentAt] = useState(buddySettings.optInEmailLastSentAt)

  const sendOptInEmails = () => {
    const onSuccess = () => setOptInEmailLastSentAt(Date.now())

    dispatch(onboardingBuddySlice.asyncActions.admin.askAllToOptIn(onSuccess))
  }

  useEffect(() => {
    dispatch(onboardingBuddySlice.asyncActions.admin.fetchExcludedUsers())
  }, [])

  const handleExcludedUsersChange = (users: { id: string }[]) => {
    const addedUsers = _.difference(users, excludedUsers)
    const removedUsers = _.difference(excludedUsers, users)

    if (!_.isEmpty(addedUsers)) {
      dispatch(onboardingBuddySlice.asyncActions.admin.excludeUsers(addedUsers.map(user => user.id)))
    }
    if (!_.isEmpty(removedUsers)) {
      dispatch(onboardingBuddySlice.asyncActions.admin.reincludeUsers(removedUsers.map(user => user.id)))
    }
  }

  const sections = [
    {
      id: 'employee_eligibility',
      header: I18N('employee_eligibility'),
      isLoading,
      content: (
        <>
          <div className='mb-2 font-weight-500'>{I18N('employee_eligilility_explanation')}</div>
          <DaysSelect
            className='mb-3'
            days={workingCopy.daysUntilEmployeeEligibleToBeBuddy}
            setDays={daysUntilEmployeeEligibleToBeBuddy => updateWorkingCopy({ daysUntilEmployeeEligibleToBeBuddy })}
            label={I18N('after_start_date')}
          />

          <FormCheck
            className='mb-5'
            name='automatically_opt_in_employees'
            label={(
              <>
                <div>{I18N('automatically_opt_in_employees')}</div>
                <div className='text-secondary text-small'>{I18N('automatically_opt_in_employees_warning')}</div>
              </>
            )}
            checked={workingCopy.automaticallyOptInEmployees}
            onChange={({ target }) => updateWorkingCopy({ automaticallyOptInEmployees: target.checked })}
            id='automatically_opt_in_employees'
          />

          {!workingCopy.automaticallyOptInEmployees && (
            <>
              <div className='font-weight-500'>{I18N('send_opt_in_emails')}</div>
              <div className='mb-3 text-small text-secondary'>{I18N('send_opt_in_emails_explanation')}</div>
              <div className='d-flex align-items-center mb-5'>
                <ButtonSmallNarrow
                  onClick={sendOptInEmails}
                  disabled={isSendingOptInEmails}
                  showLoadingSpinner={isSendingOptInEmails}
                >
                  {I18N('send_email')}
                </ButtonSmallNarrow>
                {optInEmailLastSentAt && (
                  <span className='ml-3 text-small text-secondary'>
                    {I18N('last_sent_at', { date: i18nMoment(optInEmailLastSentAt).format('ll') })}
                  </span>
                )}
              </div>
            </>
          )}

          <div className='font-weight-500'>{I18N('buddy_duration')}</div>
          <div className='mb-2 text-small text-secondary'>{I18N('buddy_duration_explanation')}</div>
          <DaysSelect
            className='mb-5'
            days={workingCopy.buddyDurationInDays}
            setDays={buddyDurationInDays => updateWorkingCopy({ buddyDurationInDays })}
            label={I18N('after_last_buddy')}
          />

          <div className='font-weight-500'>{I18N('excluded_from_onboarding_buddies')}</div>
          <div className='text-secondary text-small mb-2'>{I18N('excluded_from_onboarding_buddies_explanation')}</div>
          <EmployeeSearch
            selectedEmployees={excludedUsers}
            onChange={handleExcludedUsersChange}
            isMulti
          />
        </>
      ),
    },
    {
      id: 'matching_criteria',
      header: I18N('matching_criteria'),
      isLoading,
      content: (
        <>
          <div className='text-secondary text-small mb-3'>{I18N('matching_criteria_explanation')}</div>
          <MatchingCriteriaSelector
            targetingOptions={targetingOptions}
            selectedCriteria={workingCopy.matchingCriteria || []}
            setSelectedCriteria={matchingCriteria => updateWorkingCopy({ matchingCriteria })}
          />
        </>
      ),
    },
    {
      id: 'additional_options',
      header: I18N('additional_options'),
      isLoading,
      content: (
        <>
          <div className='text-secondary text-small mb-3'>{I18N('self_service_module_explanation')}</div>
          <FormCheck
            name='self_service_module'
            label={I18N('self_service_module')}
            checked={workingCopy.marketplace?.enabled}
            onChange={({ target }) => updateWorkingCopy({ marketplace: { enabled: target.checked } })}
            id='self_service_module'
          />

          <FormCheck
            name='allow_multiple_new_hires'
            label={I18N('allow_multiple_new_hires')}
            checked={workingCopy.allowMultipleNewHires}
            onChange={({ target }) => updateWorkingCopy({ allowMultipleNewHires: target.checked })}
            id='allow_multiple_new_hires'
            className='mt-2'
          />
        </>
      ),
    },
    {
      id: 'advanced_settings',
      header: I18N('advanced_settings'),
      isLoading,
      content: (
        <>
          <div>
            <div className='mb-2 font-weight-500'>{I18N('days_until_new_hire_needs_a_buddy')}</div>
            <TriggerSelect
              className='mb-3'
              time={null}
              updateTime={() => null}
              numDays={workingCopy.daysUntilNewHireNeedsABuddy}
              updateNumDays={daysUntilNewHireNeedsABuddy => updateWorkingCopy({ daysUntilNewHireNeedsABuddy })}
              milestone='new_hire_start_date'
              setMilestone={() => null}
              onlyDays
              type='onboarding'
              allowedMilestones={['new_hire_start_date']}
            />
            <div className='mb-2 font-weight-500'>{I18N('groups_of_employees_explanation')}</div>
            <AudienceSelector
              workingCopy={buddiesAudience}
              updateWorkingCopy={updateBuddiesAudience}
              targetingOptions={targetingOptions}
              canTargetSpecificUsers={false}
              isLoading={false}
              className='mb-2'
              showEstimatedAudience={false}
              moduleName='onboarding_buddies'
            />
          </div>

          <div>
            <div className='font-weight-500 mb-2'>{I18N('new_hire_eligilility_explanation')}</div>
            <AudienceSelector
              workingCopy={newHiresAudience}
              updateWorkingCopy={updateNewHiresAudience}
              targetingOptions={targetingOptions}
              canTargetSpecificUsers={false}
              isLoading={false}
              className='mb-2'
              showEstimatedAudience={false}
              moduleName='onboarding_buddies'
            />
          </div>
        </>
      ),
    },
  ]

  const onSave = async () => {
    dispatch(companyConfigurationSlice.asyncActions.admin.update(
      ONBOARDING_BUDDY_SETTINGS,
      workingCopy,
      { onSuccess: () => window.location.reload() }
    ))
  }

  return (
    <ScrollToModal
      className='OnboardingBuddiesSettingsModal'
      visible={isModalOpen}
      sections={sections}
      onSave={onSave}
      onClose={onClose}
      isSaving={isSaving}
      isSaveButtonDisabled={isSaveButtonDisabled}
    />
  )
}

const SettingsButtonAndModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <ButtonSecondary
        onClick={() => setIsModalOpen(true)}
      >
        {I18NCommon('settings')}
      </ButtonSecondary>
      {isModalOpen && (
        <SettingsModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default SettingsButtonAndModal
