const MENTION_REGEX = /data-type="mention"(.*?)data-id="([a-zA-Z0-9_]+)"/g

const getMentionedUserIds = (htmlString) => {
  if (!htmlString) return []

  const ids: string[] = []

  const matches = htmlString.match(MENTION_REGEX)
  if (matches) {
    matches.forEach((match) => {
      const id = match.replace(/data-type="mention"(.*?)data-id="/, '').replace('"', '')
      if (id && !ids.includes(id)) {
        ids.push(id)
      }
    })
  }

  return _.uniq(ids)
}

export default getMentionedUserIds
