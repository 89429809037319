import React from 'react'
import Card from 'components/common/card'

const ButtonWidget = ({ widget }) => {
  const {
    backgroundColor,
    text,
    secondaryText,
    textColor,
    icon,
    linkUrl,
  } = widget.settings

  return (
    <Card
      className='HomePageWidget ButtonWidget'
      cardBodyClassName='p-0'
      style={{ backgroundColor }}
    >
      <a
        href={linkUrl}
        target='_blank'
        rel='noopener noreferrer'
        className='ButtonWidget--link'
      >
        <div className='ButtonWidget--icon'>{icon}</div>
        <div
          className='ButtonWidget--text'
          style={{ color: textColor }}
        >
          {text}
        </div>
        <div
          className='ButtonWidget--secondaryText'
          style={{ color: textColor }}
        >
          {secondaryText}
        </div>
      </a>
    </Card>
  )
}

export default ButtonWidget
