import useApi from 'components/common/hooks/useApi'
import DeleteModal from 'components/common/modals/deleteModal'
import React from 'react'
import { useHistory } from 'react-router-dom'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.article_list.item_dropdown.template')

interface Props {
  isOpen: boolean
  closeModal: () => void
  template: any
  inTemplateEditor: boolean
  onTemplateDestroyed?: (template: any) => void
}

const DeleteTemplateDropdownModal = ({
  isOpen, closeModal, template, inTemplateEditor, onTemplateDestroyed,
} : Props) => {
  const history = useHistory()

  const [deleteTemplate, { isLoading }] = useApi(API.admin.article.templates.destroy, {
    toastSuccessMessage: I18N('delete_template_success_message'),
    toastErrorMessage: I18N('delete_template_error_message'),
    onSuccess: () => {
      if (onTemplateDestroyed) { onTemplateDestroyed(template) }

      if (inTemplateEditor) {
        // allow any state to be executed since it'll unmount this component and we'll have a
        // warning in console of type "Can't perform a React state on an unmounted component"
        setTimeout(() => {
          history.push('/admin/articles?selectedTab=templates')
        })
      }
    },
    onFinally: closeModal,
  })

  return (
    <DeleteModal
      deleteText={I18N('delete_template')}
      deleteSecondaryText={I18N('delete_template_description')}
      deleteConfirm={() => deleteTemplate({ id: template.id })}
      showDeleteModal={isOpen}
      closeDeleteModal={closeModal}
      isDeleting={isLoading}
    />
  )
}

export default DeleteTemplateDropdownModal
