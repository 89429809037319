import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import pageSlice from 'redux/slices/pages'
import { i18nPath } from 'utils/i18nHelpers'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.pages')

const DEFAULT_PAGE_TITLE = I18N('default_page_title')

// 1) don't pass parentPageId if you want to create at the root level
// 2) parentArchivedAt should be provided for any sub pages because
//    the value can be set to a future date for page expiration
//    (eg. if the parent expires (auto-archives) in 1 year, the children should too)
const useCreateDraftPage = ({
  parentPageId = undefined,
  parentArchivedAt = undefined,
  pageWorkspaceId,
} = {}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { pages } = useSelector(pageSlice.selectors.getAllPages())

  const { generatePathToPage } = useWorkspaceUrls()

  const onCreatePage = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    const onSuccess = (slug) => {
      history.push(generatePathToPage({ slug }))
    }

    const childPages = pages.filter(p => p.parentId?.toString() === parentPageId?.toString())

    const newPageParams = {
      title: DEFAULT_PAGE_TITLE,
      draftTitle: DEFAULT_PAGE_TITLE,
      targetingRules: TARGET_ENTIRE_COMPANY,
      restrictAccess: false,
      draft: true,
      parentId: parentPageId,
      ancestryOrderPosition: childPages.length, // insert it at the bottom of the parent page
      archivedAt: parentArchivedAt,
      pageWorkspaceId,
    }

    dispatch(pageSlice.asyncActions.create(newPageParams, onSuccess))
  }

  return onCreatePage
}

export default useCreateDraftPage
