import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import itAdminSlice from 'redux/slices/itAdmin'
import { Creatable } from 'components/common/react_select'
import FormEditableField from 'components/form_fields/formEditableField'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'

const CERTIFICATE_PLACEHOLDER = `-----BEGIN CERTIFICATE-----
XXXXXXXXXXXXXXXXXXXXXXXXXXX
-----END CERTIFICATE-----
`

const I18N = i18nPath('views.integrations_page.saml_integration_card')
const PROVIDER_OPTIONS = ['Okta', 'Google']

const SAMLInstallationComponent = () => {
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)
  const [url, setUrl] = useState('')
  const [certificate, setCertificate] = useState('')
  const [provider, setProvider] = useState('')
  const { isConfiguringSAML } = useSelector(itAdminSlice.selectors.getMetaData())
  const { authType } = useSelector(itAdminSlice.selectors.getData())

  const installSAML = () => {
    dispatch(itAdminSlice.asyncActions.admin.installSAML({ url, certificate, provider }))
  }

  const isDisabled = isConfiguringSAML || _.isEmpty(url) || _.isEmpty(certificate) || _.isEmpty(provider)

  if (['google', 'microsoft'].includes(authType)) {
    return (
      <div className='text-secondary text-small'>
        {I18N('uninstall_other_integration', { installedIntegration: I18N(`other_integration.${authType}`) })}
      </div>
    )
  }

  if (!showForm) {
    return (
      <div className='mb-2'>
        <ButtonSmallNarrow
          onClick={() => setShowForm(true)}
        >
          {I18N('configure_saml')}
        </ButtonSmallNarrow>
      </div>
    )
  }

  return (
    <>
      <div className='mb-2'>
        <LabeledFormFieldContainer
          isRequired
          label={I18N('provider')}
        >
          <Creatable
            name='provider'
            value={{ label: provider, value: provider }}
            options={PROVIDER_OPTIONS.map(provider => ({ label: provider, value: provider }))}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
            onChange={newProvider => setProvider(newProvider.value)}
          />
        </LabeledFormFieldContainer>

        <FormEditableField
          className='d-block w-100 mb-2 mt-2'
          currentValue={url}
          fieldName='url'
          label={I18N('url')}
          footNote={I18N('saml_sso_url_footnote')}
          onChange={({ target }) => setUrl(target.value)}
          isRequired
        />

        <FormEditableField
          className='d-block w-100 mb-2'
          currentValue={certificate}
          fieldName='certificate'
          label={I18N('certificate')}
          footNote={I18N('saml_sso_certificate_footnote')}
          onChange={({ target }) => setCertificate(target.value)}
          placeholder={CERTIFICATE_PLACEHOLDER}
          textarea
          isRequired
        />

        <ButtonSmallNarrow
          onClick={installSAML}
          disabled={isDisabled}
          showLoadingSpinner={isConfiguringSAML}
          id='configure-saml-btn'
        >
          {I18N('install_saml')}
        </ButtonSmallNarrow>
      </div>

    </>
  )
}

export default SAMLInstallationComponent
