import { createSlice } from '@reduxjs/toolkit'
import API from 'services/api'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import queryParamsFromHeaders from 'utils/queryParamsFromHeaders'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

export interface CompaniesState {
  companies: any[]
  company: any
  workingCopy: any
  queryParams: any
  isLoading: boolean
  isNotFound: boolean
  isSaving: boolean
  isSaveComplete: boolean
  isDeleting: boolean
  error: any
}

const initialState: CompaniesState = {
  companies: [],
  company: {},
  workingCopy: {},
  queryParams: {},
  isLoading: false,
  isNotFound: false,
  isSaving: false,
  isSaveComplete: false,
  isDeleting: false,
  error: null,
}

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    clearCompany: (state) => {
      state.company = {}
    },
    fetchStart: (state) => {
      state.isLoading = true
    },
    receiveQueryParams: (state, action) => {
      state.queryParams = action.payload.queryParams
    },
    receiveCompanies: (state, action) => {
      state.companies = action.payload.companies
      state.isLoading = false
    },
    receiveCompany: (state, action) => {
      state.company = action.payload.company
      state.workingCopy = action.payload.company
      state.isLoading = false
      state.isSaving = false
      state.error = null
    },
    setNewCompany: (state, action) => {
      const newCompany = {
        id: null,
        name: '',
        appName: 'Cleary',
        subdomain: '',
        type: 'full_platform',
        ...action.payload,
      }

      state.company = { ...newCompany }
      state.workingCopy = { ...newCompany }
      state.isLoading = false
      state.error = null
    },
    submitStart: (state) => {
      state.isSaveComplete = false
      state.isSaving = true
    },
    submitComplete: (state, action) => {
      state.isSaveComplete = true
      state.isSaving = false
      state.error = null

      if (action.payload.company) {
        state.company = action.payload.company
        state.workingCopy = action.payload.company
      }
    },
    updateCompanyWorkingCopy: (state, action) => {
      state.workingCopy = action.payload
    },
    destroyCompanyStart: (state) => {
      state.isDeleting = true
    },
    destroyCompanyComplete: (state) => {
      state.isDeleting = false
    },
    withError: (state, action) => {
      const { hasError, error } = checkForError(action.payload)

      state.isNotFound = hasError
      state.isSaveComplete = false
      state.isSaving = false
      state.error = error
    },
  },
})

const fetchCompanies = params => (dispatch) => {
  dispatch(companiesSlice.actions.fetchStart())

  return API.admin.company
    .fetchAll(params)
    .then((response) => {
      const companies = denormalizedJsonApiResponse(response, 'adminCompany')
      const queryParams = queryParamsFromHeaders(response)

      dispatch(companiesSlice.actions.receiveCompanies({ companies }))
      dispatch(companiesSlice.actions.receiveQueryParams({ queryParams }))
    })
    .catch((error) => {
      dispatch(companiesSlice.actions.withError(getResponseOrThrow(error)))
    })
}

const fetchCompany = companyId => (dispatch) => {
  dispatch(companiesSlice.actions.fetchStart())

  return API.admin.company
    .fetch(companyId)
    .then((response) => {
      const company = denormalizedJsonApiResponse(response, 'adminCompany')

      dispatch(companiesSlice.actions.receiveCompany({ company }))
    })
    .catch((e) => {
      dispatch(companiesSlice.actions.withError(getResponseOrThrow(e)))
    })
}

const createCompany = company => (dispatch) => {
  dispatch(companiesSlice.actions.submitStart())

  return API.admin.company
    .create(company)
    .then(() => {
      dispatch(companiesSlice.actions.submitComplete({}))

      window.location.href = '/admin/companies'
    })
    .catch((e) => {
      dispatch(companiesSlice.actions.withError(getResponseOrThrow(e)))
    })
}

const updateCompany = (company, options = { redirect: true }) => (dispatch) => {
  dispatch(companiesSlice.actions.submitStart())

  return API.admin.company
    .update(company)
    .then((response) => {
      const company = denormalizedJsonApiResponse(response, 'adminCompany')

      dispatch(companiesSlice.actions.submitComplete({ company }))

      if (options.redirect) window.location.href = '/admin/companies'
    })
    .catch((e) => {
      dispatch(companiesSlice.actions.withError(getResponseOrThrow(e)))
    })
}

const destroyCompany = company => (dispatch) => {
  dispatch(companiesSlice.actions.destroyCompanyStart())

  return API.admin.company
    .destroy(company)
    .then(() => {
      dispatch(companiesSlice.actions.destroyCompanyComplete())

      window.location.href = '/admin/companies'
    })
    .catch((e) => {
      dispatch(companiesSlice.actions.withError(getResponseOrThrow(e)))
    })
}

export default {
  ...companiesSlice,
  asyncActions: {
    fetchCompanies,
    fetchCompany,
    createCompany,
    updateCompany,
    destroyCompany,
  },
}
