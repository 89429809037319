import React from 'react'
import SVG from 'react-inlinesvg'
import { i18nMoment } from 'utils/i18nHelpers'
import classNames from 'classnames'

import LinkAnchor from 'components/common/linkAnchor'

const NotificationContent = ({ notification }) => {
  const {
    imageUrl, viewableAt, text, type,
  } = notification

  const isSvg = imageUrl?.endsWith('.svg')
  const isCelebration = type.includes('celebrations')

  return (
    <div className='d-flex p-3'>
      <div
        className={classNames(
          'Notification-listItemIcon d-flex align-items-center justify-content-center mr-3',
          type.replace('.', '-')
        )}
      >
        {isSvg ? (
          <SVG src={imageUrl} cacheGetRequests />
        ) : (
          <img src={imageUrl} className={classNames(isCelebration && 'bordered-thumbnail')} />
        )}
      </div>
      <div className='d-flex flex-column justify-content-center pr-3'>
        <div className='text-secondary text-small'>{i18nMoment(viewableAt).fromNow()}</div>
        <div style={{ lineHeight: '1.2' }}>{text}</div>
      </div>
    </div>
  )
}

const NotificationListItem = (props) => {
  const { notification, history } = props

  const { link, viewedAt } = notification
  const isUnread = !viewedAt

  return (
    <div className={`Notification ${isUnread && 'Notification--unread'} pointer`}>
      {link ? (
        <LinkAnchor link={link} history={history}>
          <NotificationContent notification={notification} />
        </LinkAnchor>
      ) : (
        <NotificationContent notification={notification} />
      )}
    </div>
  )
}

export default NotificationListItem
