import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import StepVariablesDropdown from 'components/admin/journeys/stepVariablesDropdown'
import RichTextView from 'components/common/richTextView'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { i18nPath } from 'utils/i18nHelpers'
import SendPreviewEmailButtonAndModal from 'components/admin/sendPreviewEmailButtonAndModal'
import communicationSlice from 'redux/slices/journeys/communications'
import communicationTemplateSlice from 'redux/slices/journeys/communicationTemplates'

const I18N = i18nPath('views.admin.journeys.communication_templates.content.email')

const EmailContent = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
  journeyBlueprint,
  isStepTemplate,
}) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const {
    isSendingPreviewEmail: isSendingCommTemplatePreviewEmail,
  } = useSelector(communicationTemplateSlice.selectors.getMetaData())

  const {
    isSendingPreviewEmail: isSendingCommPreviewEmail,
  } = useSelector(communicationSlice.selectors.getMetaData())

  const isSendingPreviewEmail = isSendingCommTemplatePreviewEmail || isSendingCommPreviewEmail

  const onSendPreviewEmail = (userIds, onSuccess) => {
    if (isStepTemplate) {
      dispatch(communicationTemplateSlice.asyncActions.admin.sendPreviewEmail(id, userIds, onSuccess))
    } else {
      dispatch(communicationSlice.asyncActions.admin.sendPreviewEmail(id, userIds, onSuccess))
    }
  }

  return (
    <div>
      <div className='form-group'>
        <div className='d-flex justify-content-between align-items-end'>
          <label className='required-form-label'>{I18N('subject')}</label>
          <SendPreviewEmailButtonAndModal
            areChangesPresent={false}
            isSending={isSendingPreviewEmail}
            onSend={onSendPreviewEmail}
            buttonSecondary
            objectName=''
            className='mb-2'
          />
        </div>
        <input
          name='subject'
          placeholder={I18N('subject')}
          value={workingCopy.subject}
          disabled={isDisabled}
          onChange={({ target }) => updateWorkingCopy({ subject: target.value })}
        />
      </div>

      <div>
        <label className='required-form-label'>{I18N('message')}</label>
        {isDisabled ? (
          <RichTextView className='content-rich-view' record={workingCopy} recordType='communication' richTextKey='content' />
        ) : (
          <>
            <TiptapEditor
              className='TiptapEditor straight-borders'
              onChange={value => updateWorkingCopy({ content: value })}
              html={workingCopy.content}
              configuration={{
                placeholder: I18N('message_placeholder'),
                userMentionsEnabled: false,
                videosEnabled: true,
              }}
              editorContentClassName='p-3'
            />
            <StepVariablesDropdown journeyBlueprint={journeyBlueprint} />
          </>
        )}

      </div>
    </div>
  )
}

export default EmailContent
