import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import Stat from 'pages/admin/articles/stats/Stat'
import { getStatsValue, removeSideZerosValues } from 'pages/admin/articles/stats/helpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import LineGraph from 'components/analytics/common/lineGraph'

const I18N = i18nPath('views.admin.article_stats')


const PageViews = ({ articleStat }) => {
  const currentCompany = useCurrentCompany()
  const { birthdayCardBackgroundColor } = currentCompany.customStyles.variables.colors
  const articleViewsCount = getStatsValue(articleStat, 'articleViewsCount')
  const articleViewsUniqueCount = getStatsValue(articleStat, 'articleViewsUniqueCount')
  const averageSessionTime = getStatsValue(articleStat, 'averageSessionTime')
  const audienceArticleViewsUniqueCount = getStatsValue(articleStat, 'audienceArticleViewsUniqueCount')
  const audienceViewArticleReach = I18n.toPercentage(
    getStatsValue(articleStat, 'audienceViewArticleReach') * 100,
    { precision: 1 }
  )

  const engagementByDate = removeSideZerosValues(articleStat.engagementByDate || [], 'articleViewsCount')
  const labels = engagementByDate.map(counts => counts.date)
  const data = engagementByDate.map(counts => counts.articleViewsCount)

  return (
    <Card className='h-100'>
      <h5 className='mb-0 ml-1'>{I18N('page_views.title')}</h5>

      <hr />

      <div className='row'>
        <div className='col-4 mb-4'>
          <Stat
            value={articleViewsCount}
            label={I18N('stat.total_page_views')}
            tooltip={I18N('stat.total_page_views_tooltip')}
          />
        </div>
        <div className='col-4 mb-4'>
          <Stat
            value={articleViewsUniqueCount}
            label={I18N('stat.unique_page_views')}
            tooltip={I18N('stat.unique_page_views_tooltip')}
          />
        </div>
        <div className='col-4 mb-4'>
          <Stat
            value={averageSessionTime}
            label={I18N('stat.avg_time_spent_on_page')}
            tooltip={I18N('stat.avg_time_spent_on_page_tooltip')}
          />
        </div>
        <div className='col-4 mb-4'>
          <Stat
            value={audienceArticleViewsUniqueCount}
            label={I18N('stat.unique_audience_views')}
            tooltip={I18N('stat.unique_audience_views_tooltip')}
          />
        </div>
        <div className='col-4 mb-4'>
          <Stat
            value={audienceViewArticleReach}
            label={I18N('stat.audience_reach')}
            tooltip={I18N('stat.audience_view_article_reach_tooltip')}
          />
        </div>
      </div>

      <h6 className='mt-2 mb-2'>{I18N('stat.total_page_views')}</h6>

      <div style={{ position: 'relative', height: '130px' }} className='relative w-100'>
        <LineGraph
          labels={labels}
          dataValues={data}
          tooltipLabel={I18N('stat.total_page_views')}
          classname='w-100'
          color={birthdayCardBackgroundColor}
        />
      </div>
    </Card>
  )
}

export default PageViews
