import React from 'react'

const LinkIcon = ({ height = 18, width = 18, className = '' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.0'
    width={width}
    height={height}
    viewBox='0 0 914.000000 914.000000'
    preserveAspectRatio='xMidYMid meet'
    className={className}
  >
    <g transform='translate(0.000000,914.000000) scale(0.100000,-0.100000)' fill='#C2C7CC' stroke='none'>
      <path d='M6405 9125 c-445 -58 -879 -243 -1220 -521 -49 -40 -405 -389 -790 -775 l-700 -701 60 6 c532 52 927 13 1373 -136 l123 -42 352 350 c361 359 424 412 575 488 322 162 714 167 1041 15 243 -113 453 -316 572 -552 206 -406 161 -906 -114 -1269 -31 -40 -476 -492 -989 -1004 -974 -972 -987 -984 -1153 -1067 -74 -37 -213 -84 -307 -104 -113 -24 -343 -24 -456 0 -428 91 -758 375 -902 777 -13 36 -25 67 -26 69 -2 2 -27 -11 -56 -27 -36 -21 -185 -163 -467 -446 l-414 -414 53 -84 c169 -267 342 -456 585 -639 351 -263 781 -428 1229 -470 156 -14 447 -7 591 16 433 68 850 254 1185 530 135 110 1960 1938 2053 2055 290 365 462 770 522 1223 20 155 20 469 0 624 -57 428 -220 822 -488 1178 -78 103 -329 354 -432 432 -359 270 -759 435 -1185 488 -151 18 -472 18 -615 0z' />
      <path d='M3940 6564 c-163 -20 -217 -28 -298 -44 -353 -73 -681 -224 -987 -453 -93 -70 -1985 -1951 -2103 -2092 -281 -334 -478 -787 -537 -1238 -18 -137 -20 -441 -4 -585 36 -335 166 -715 343 -1000 134 -216 348 -461 531 -609 352 -284 783 -469 1228 -528 155 -20 469 -20 624 0 454 60 860 233 1223 523 52 41 409 388 793 771 548 546 694 697 677 699 -11 2 -60 -1 -108 -7 -414 -52 -887 -1 -1310 141 l-123 42 -357 -355 c-379 -377 -420 -412 -594 -494 -589 -279 -1294 -36 -1588 548 -199 395 -168 855 83 1227 38 56 286 310 976 1003 509 510 958 953 996 982 144 111 301 184 490 227 121 28 352 30 475 5 423 -90 761 -381 901 -777 12 -36 24 -67 25 -68 1 -2 25 8 51 23 34 18 183 160 468 445 l418 418 -47 76 c-248 398 -572 687 -1001 892 -218 104 -438 171 -685 209 -100 16 -481 28 -560 19z' />
    </g>
  </svg>
)

export default LinkIcon
