/**
 * This hook is used to update the print styles for the org chart.
 *
 * It assumes that the print resolution is 96 DPI and that the page size is letter.
 *
 * The hook will create a <style/> element in the <head/> of the document with the ID
 * 'org-chart-print-styles'. If the element already exists, it will be used instead.
 * The function that the hook returns will update the created <style/> element with
 * the appropriate CSS to center the org chart on the page and scale it to fit.
 *
 * It centers the chart based on the orientation, if the orientation is horizontal it
 * will center the chart vertically and if the orientation is vertical it will center
 * the chart horizontally.
 *
 * The origin of the chart is the first <g/> element inside the `targetClass` element.
 * Due to that, we can't just use 50% for centering the chart, but we need to account for the
 * difference between the origin and the top left corner of the chart.
 *
 * @param nodeSizeHorizontal The horizontal node size of the org chart.
 * @param nodeSizeVertical The vertical node size of the org chart.
 * @param targetClass The first group (<g/>) class name of the org chart.
 * @returns A function that updates the print styles for the org chart.
 */

import { useEffect, useRef } from 'react'

const DPI = 96
const LETTER_WIDTH = 8.5 * DPI
const LETTER_HEIGHT = 11 * DPI
const HORIZONTAL_ORIENTATION = 'horizontal'

const usePrintableOrgChart = (nodeSizeHorizontal, nodeSizeVertical, targetClass = 'rd3t-g') => {
  const printStyleElementRef = useRef<HTMLStyleElement>()

  useEffect(() => {
    printStyleElementRef.current = document.createElement('style')
    document.head.appendChild(printStyleElementRef.current)

    return () => {
      printStyleElementRef.current?.remove()
    }
  }, [])

  const updatePrintStyle = (orientation = 'horizontal') => {
    const targetNode = document.querySelector<SVGAElement>(`.${targetClass}`)
    const rect = targetNode?.getBBox()

    let left
    let top
    let scale = 1

    if (!rect) return

    if (LETTER_WIDTH < rect.width || LETTER_HEIGHT < rect.height) {
      scale = Math.min(LETTER_WIDTH / rect.width, LETTER_HEIGHT / rect.height)
    }

    if (orientation === HORIZONTAL_ORIENTATION) {
      left = `${nodeSizeHorizontal.x / 2}px`
      top = `calc(50% - ${((rect.height / 2) - Math.abs(rect.y)) / 2}px)`
    } else {
      left = `calc(50% - ${((rect.width / 2) - Math.abs(rect.x)) / 2}px)`
      top = `${nodeSizeVertical.y / 2}px`
    }

    printStyleElementRef.current!.textContent = `
      @media print {
        .${targetClass} {
          transform: translate(${left}, ${top}) scale(${scale}) !important;
        }
      }
    `
  }

  return updatePrintStyle
}

export default usePrintableOrgChart
