import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { buildStepPayload } from 'redux/slices/journeys/steps'
import { defaultStepAttributes, defaultTaskableAttributes } from 'utils/journeys/templatesDefaultHelpers'

const I18N = i18nPath('views.admin.journeys.tasks')

export const TASK_ATTRIBUTES = [
  'allowSilenceNotifications',
  'dueAt',
  'instructions',
  'name',
  'notifyViaEmail',
  'notifyViaMsTeams',
  'notifyViaSlack',
  'notifyViaSms',
  'replyToSetting',
  'taskType',
  'useRecipientNotificationPreferences',
]

export const TASK_INTERPOLATED_FIELDS = ['instructions', 'name']

const buildTaskPayload = (task) => {
  const newTask = _.pick(task, [...TASK_ATTRIBUTES, 'id'])

  if (task.stepAttributes) {
    newTask.stepAttributes = buildStepPayload(task.stepAttributes)
  }

  return newTask
}

export const defaultWorkingCopy = () => ({
  ...defaultStepAttributes,
  ...defaultTaskableAttributes,
})

export const buddyAssignmentDefaultWorkingCopy = () => ({
  ...defaultStepAttributes,
  ...defaultTaskableAttributes,
  taskType: 'buddy_assignment',
  name: I18N('buddy_assignment.default_name'),
  instructions: I18N('buddy_assignment.default_instructions'),
  toCorrespondentAttributes: { role: 'manager' },
})

export const initialState = {
  taskIds: [],
  meta: {
    ...defaultMeta,
    openTasksCount: null,
    isLoadingTasksCount: false,
    queryParams: {
      ...defaultPaginationParams, perPage: 10, sortBy: 'trigger_at', sortDir: 'asc',
    },
  },
}

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    ...defaultActions,

    setOpenTasksCount(state, action) {
      state.meta.openTasksCount = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    setTaskIds(state, action) {
      state.taskIds = action.payload
    },

    clearTaskIds(state) {
      state.taskIds = []
    },

    setIsLoadingTasksCount(state, action) {
      state.meta.isLoadingTasksCount = action.payload
    },
  },
})

const asyncActions = {
  admin: {
    fetchTask: id => async (dispatch) => {
      dispatch(taskSlice.actions.isLoading(true))
      dispatch(taskSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.tasks.fetch(id)
        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(taskSlice.actions.isNotFound(true))
      } finally {
        dispatch(taskSlice.actions.isLoading(false))
      }
    },

    updateTask: (workingCopy, onSuccess = () => { }) => async (dispatch) => {
      dispatch(taskSlice.actions.isSaving(true))

      try {
        const response = await API.admin.tasks.update(buildTaskPayload(workingCopy))
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(taskSlice.actions.setError(error))
      } finally {
        dispatch(taskSlice.actions.isSaving(false))
      }
    },

    deleteTask: (id, onSuccess = () => { }) => async (dispatch, getState) => {
      try {
        await API.admin.tasks.destroy({ id })
        const task = build(getState().entities, 'task', id)
        const stepId = task?.step?.id
        dispatch(entitySlice.actions.remove({ type: 'task', id }))
        dispatch(entitySlice.actions.remove({ type: 'step', id: stepId }))
        dispatch(showToastMessage({ message: I18N('successfully_deleted'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(taskSlice.actions.setError(error))
      }
    },
  },

  fetchTask: id => async (dispatch) => {
    dispatch(taskSlice.actions.isLoading(true))
    dispatch(taskSlice.actions.isNotFound(false))

    try {
      const response = await API.journey.tasks.fetch(id)
      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(taskSlice.actions.isNotFound(true))
    } finally {
      dispatch(taskSlice.actions.isLoading(false))
    }
  },

  completeTask: (id, onSuccess = () => { }) => async (dispatch) => {
    dispatch(taskSlice.actions.isSaving(true))

    try {
      const response = await API.journey.tasks.complete(id)
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('successfully_completed'), type: 'success' }))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(taskSlice.actions.setError(error))
    } finally {
      dispatch(taskSlice.actions.isSaving(false))
    }
  },

  reopenTask: (id, onSuccess = () => { }) => async (dispatch) => {
    dispatch(taskSlice.actions.isSaving(true))

    try {
      const response = await API.journey.tasks.reopen(id)
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('succesfully_reopened'), type: 'success' }))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(taskSlice.actions.setError(error))
    } finally {
      dispatch(taskSlice.actions.isSaving(false))
    }
  },
}

const selectors = {
  getMetaData: () => state => state.tasks.meta,

  getTask: id => state => build(state.entities, 'task', id),

  getTasks: () => state => state.tasks.taskIds.map(id => build(state.entities, 'task', id)) || [],
}

export default {
  ...taskSlice,
  selectors,
  asyncActions,
}
