import { createSlice } from '@reduxjs/toolkit'
import { ReduxState } from 'redux/redux'


export interface NavigationBlockerState {
  skipBlocking: boolean
}

export const initialState: NavigationBlockerState = {
  skipBlocking: false,
}

const navigationBlockerSlice = createSlice({
  name: 'navigationBlocker',
  initialState,
  reducers: {
    setSkipBlocking(state, action) {
      state.skipBlocking = action.payload
    },
  },
})


const asyncActions = {
  performWithoutBlocking: (callback: () => void) => async (dispatch) => {
    dispatch(navigationBlockerSlice.actions.setSkipBlocking(true))

    // allow NavigationBlocker to render and unblock if it's blocked
    setTimeout(() => {
      callback()
      dispatch(navigationBlockerSlice.actions.setSkipBlocking(false))
    })
  },
}

const selectors = {
  skipBlocking: () => (state: ReduxState) => state.navigationBlocker.skipBlocking,
}


export default {
  ...navigationBlockerSlice,
  asyncActions,
  selectors,
}

