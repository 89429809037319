import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import Article from 'components/articles/article'
import { LoadingContainer } from 'components/common/loadingContainer'
import BackButton from 'components/common/backButton'
import LegalNoticesFooter from 'components/common/legalNoticesFooter'

import newsSlice from 'redux/slices/news'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useContentTracking from 'components/common/hooks/useContentTracking'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import entitySlice from 'redux/slices/entities'
import useIsLoaded from 'components/common/hooks/useIsLoaded'

const ArticlePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { settings } = useCurrentCompany()
  const { articleId } = useParams()
  const { inPreboardingExperience } = useCurrentUser()

  const fetchArticle = inPreboardingExperience ? API.journey.preboarding.articles.fetch : API.articles.fetch

  const article = useSelector(entitySlice.selectors.getEntityBySlug('article', articleId))
  const { isLoading, isNotFound } = useFetch(fetchArticle, [articleId], {
    addEntitySlice: true,
  })

  const isLoaded = useIsLoaded(isLoading)

  useContentTracking('article', article?.id)

  const handleTagClick = (tag) => {
    const tagId = tag ? tag.id : null

    // tag name must be encoded by encodeURIComponent so ampersand becomes %26
    // For example, a tag named 'Analytics & Reporting' if not encoded would be interpreted
    // as 2 different query parameters
    const queryParam = tagId ? `?tagId=${tagId}&tagName=${encodeURIComponent(tag.name)}` : ''

    history.push(`/news/all${queryParam}`)
    dispatch(newsSlice.actions.clearArticles())
    dispatch(newsSlice.actions.clearQueryParams())
    dispatch(newsSlice.asyncActions.fetchArticles('all', { tagId }))
  }

  return (
    <LoadingContainer isLoading={!isLoaded} isNotFound={isNotFound} useCirclesLoadingIndicator>
      <div className='ArticlePage'>
        {article && (
          <div className='ArticlePage-content'>
            <BackButton className='mb-4' />

            <Article article={article} onTagClick={handleTagClick} />

            {settings.legalNotices.contentFooter.enabled && <LegalNoticesFooter />}
          </div>
        )}
      </div>
    </LoadingContainer>
  )
}

export default ArticlePage
