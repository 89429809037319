import { ButtonSecondary } from 'components/common/buttons'
import CelebrationModalContext from 'components/celebrations/greeting_card_actions/celebrationModalContext'
import SendRemindersModal from 'components/celebrations/sendRemindersModal'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.greeting_card')
export const SEND_REMINDERS_MODAL = 'send_reminders'

const SendRemindersButton = ({
  celebration, pendingUsers, onInviteSuccess,
}) => {
  const { setCurrentOpenModal, currentOpenModal, closeModal } = React.useContext(CelebrationModalContext)

  return (
    <>
      <ButtonSecondary
        onClick={() => setCurrentOpenModal(SEND_REMINDERS_MODAL)}
        className='GreetingCardActionButton'
      >
        {I18N('send_reminders')}
      </ButtonSecondary>

      {currentOpenModal === SEND_REMINDERS_MODAL && (
        <SendRemindersModal
          pendingUsers={pendingUsers}
          celebration={celebration}
          onClose={closeModal}
          onInviteSuccess={onInviteSuccess}
        />
      )}
    </>
  )
}

export default SendRemindersButton
