import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import API from 'services/api'
import { showToastMessage } from 'redux/slices/toasts'
import AudienceSelector from 'components/common/audience/audienceSelector'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'
import companyConfigurationSlice from 'redux/slices/companyConfigurations'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.soft_launch_users.platform_access')

const SoftLaunchPlatformAccessAudiencePage = () => {
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()
  const [workingCopy, setWorkingCopy] = useState(_.cloneDeep(currentCompany))
  const [isSaving, setIsSaving] = useState(false)

  const targetingOptions = useTargetingOptions()

  const saveCompanyAudience = () => {
    setIsSaving(true)

    const payload = {
      id: currentCompany.id,
      targetingRules: normalizeTargetingRules(workingCopy.targetingRules),
      settings: workingCopy.settings,
    }

    API.admin.company.update(payload)
      .then(response => handleAudienceChange(response))
      .catch(({ response: { data } }) => dispatch(showToastMessage({ message: data?.error?.message, type: 'error' })))
      .finally(() => setIsSaving(false))
  }

  const handleAudienceChange = (values = {}) => {
    setWorkingCopy({ ...workingCopy, ...values })
  }

  const handleAutoSoftLaunchAccessGrantingChange = (e) => {
    const softLaunchWhitelistValue = currentCompany.settings.softLaunchWhitelist
    softLaunchWhitelistValue.autoSoftLaunchAccessGranting = e.target.checked

    dispatch(companyConfigurationSlice.asyncActions.admin.update('soft_launch_whitelist', softLaunchWhitelistValue))

    const changedSettings = {
      ...workingCopy.settings,
      softLaunchWhitelist: {
        ...workingCopy.settings.softLaunchWhitelist,
        autoSoftLaunchAccessGranting: e.target.checked,
      },
    }

    setWorkingCopy({ ...workingCopy, settings: changedSettings })
  }

  return (
    <div className='container my-4'>
      <Link to='/admin/cleary_admin'>{I18N('back_to_cleary_admin')}</Link>
      <h1>{I18N('title')}</h1>
      <div className='row'>
        <div className='col'>
          <div>
            <label className='checkbox-label form-inline-check'>
              <input
                style={{ height: '1rem' }}
                type='checkbox'
                checked={workingCopy.settings.softLaunchWhitelist.autoSoftLaunchAccessGranting}
                onChange={handleAutoSoftLaunchAccessGrantingChange}
              />
              {I18N('enable_auto_soft_launch')}
            </label>
          </div>
          {workingCopy.settings.softLaunchWhitelist.autoSoftLaunchAccessGranting && (
            <div className='p-4'>
              <p>{I18N('description')}</p>
              <AudienceSelector
                workingCopy={workingCopy}
                updateWorkingCopy={handleAudienceChange}
                targetingOptions={targetingOptions}
                canTargetSpecificUsers={false}
                moduleName={null}
                isLoading={false}
              />
            </div>
          )}
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <Button onClick={saveCompanyAudience} showLoadingSpinner={isSaving}>
            {I18N('save_audience')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SoftLaunchPlatformAccessAudiencePage
