import React from 'react'
import Card from 'components/common/card'
import { Button } from 'react-bootstrap'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.preview_components')

const CardBoxShadow = () => (
  <Card className='d-flex align-items-center'>
    <Button style={{ backgroundColor: 'var(--success-color' }}>{I18N('goto_qa')}</Button>
  </Card>
)

export default CardBoxShadow
