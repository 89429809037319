import React from 'react'
import { Link } from 'react-router-dom'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import API from 'services/api'
import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.admin.banner_list')

const BannerListPage = () => {
  const { isLoading, data: banners } = useFetch(API.admin.banners.fetchAll)

  return (
    <div className='BannerListPage'>
      <header className='AdminHeader d-flex justify-content-between align-items-center'>
        <h3 className='mb-0'>{I18N('banners')}</h3>
        <Link to='/admin/banners/new'>
          <Button>
            {I18N('create_new_banner')}
          </Button>
        </Link>
      </header>
      <main className='AdminContent'>
        <LoadingContainer isLoading={isLoading}>
          <table className='white-bg-table'>
            <thead>
              <tr>
                <th>{I18N('title')}</th>
                <th style={{ width: '40%' }}>{I18N('active_period')}</th>
                <th>{I18N('created')}</th>
              </tr>
            </thead>
            <tbody>
              {(banners || []).map(banner => (
                <tr key={`banner-${banner.id}`}>
                  <td>
                    <Link to={`/admin/banners/${banner.id}`}>
                      <span>{banner.title}</span>
                    </Link>
                  </td>
                  <td>
                    {i18nMoment(banner.startTime).format('LLL')} - {i18nMoment(banner.endTime).format('LLL')}
                  </td>
                  <td>{i18nMoment(banner.createdAt).format('LLL')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default BannerListPage
