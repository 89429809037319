import React, { useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.custom_styles.json_editor')

const JsonEditor = ({ jsonData, applyJsonData }) => {
  const jsonStringify = JSON.stringify(jsonData, null, 1) || ''
  const contentEditableRef = React.createRef<HTMLElement>()
  const [jsonString, setJson] = useState(jsonStringify)
  const [error, setError] = useState(null)

  const onApplyClick = () => {
    const { innerText } = contentEditableRef.current as HTMLElement

    try {
      const toJson = JSON.parse(innerText)

      setError(null)
      setJson(JSON.stringify(toJson, null, 1))
      applyJsonData(toJson)
    } catch (e) {
      setJson(innerText)
      setError(e)
    }
  }

  return (
    <div className='mt-5'>
      {error && (
        <span className='text-muted text-smallest'>
          {I18N('json_invalid')}
          {' '}
          <a href='https://jsonlint.com' target='blank'>
            {I18N('json_validation_link')}
          </a>
        </span>
      )}
      <ContentEditable
        className='bordered bg-white monospaced ws-pre-wrap break-word p-1'
        innerRef={contentEditableRef}
        html={jsonString} // Additional parameters to format json see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#Parameters
        disabled={false}
        onChange={() => {}}
      />
      <Button id='applyButton' className='float-right mt-5' onClick={onApplyClick}>
        {I18N('apply')}
      </Button>
    </div>
  )
}

export default JsonEditor
