import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import microsoftLogo from 'assets/microsoftLogo.svg'
import GoogleInstallationComponent from 'components/admin/integrations/googleInstallationComponent'
import IntegrationCard from 'components/admin/integrations/integrationCard'
import UninstallOtherIntegration from 'components/admin/integrations/uninstallOtherIntegration'
import CollapsibleCard from 'components/common/collapsibleCard'
import itAdminSlice from 'redux/slices/itAdmin'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.integrations_page.calendars_section')

const googleCalendarLogoPath = '/images/integrations/googleCalendarLogo.svg'

const GOOGLE_INTEGRATION_DOC_URL = 'https://docs.google.com/document/d/1GrK1V6DpVeYEaUhu90oDvQPS45o8tGp9qvpW8SBapTw/edit?usp=sharing'

const CalendarsSection = () => {
  const dispatch = useDispatch()

  const { googleCalendarEnabled, microsoftAzureAppCalendarsEnabled } = useSelector(itAdminSlice.selectors.getData())
  const { isUninstalling, isSaving } = useSelector(itAdminSlice.selectors.getMetaData())

  const handleGoogleInstallationSubmit = (googleCredentials) => {
    dispatch(itAdminSlice.asyncActions.admin.installGoogle(googleCredentials))
  }

  const uninstallIntegration = (name) => {
    dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration(name))
  }

  const getInstallationComponent = (integrationName) => {
    switch (integrationName) {
    case 'Google':
      if (microsoftAzureAppCalendarsEnabled) {
        return <UninstallOtherIntegration integrationName='Google Calendar' installedIntegration='Microsoft Outlook' />
      }

      return (
        <GoogleInstallationComponent
          onSubmit={handleGoogleInstallationSubmit}
          isSaving={isSaving}
          docLink={GOOGLE_INTEGRATION_DOC_URL}
        />
      )
    case 'Microsoft':
      if (googleCalendarEnabled) {
        return <UninstallOtherIntegration integrationName='Microsoft Outlook' installedIntegration='Google Calendar' />
      }

      return (
        <a className='btn btn-primary btn-sm btn-narrow' href='/auth/microsoft_azure_app/calendars_authorize_url'>
          {I18NCommon('install')}
        </a>
      )
    default:
      return null
    }
  }

  return (
    <CollapsibleCard title={I18N('calendars')} className='mb-3'>
      <section className='CalendarsSection mb-3'>
        <IntegrationCard
          id='google_calendar'
          logo={<CdnSvg src={googleCalendarLogoPath} />}
          isInstalled={googleCalendarEnabled}
          docLink={GOOGLE_INTEGRATION_DOC_URL}
          docTitle={I18N('google_doc_title')}
          onUninstall={() => uninstallIntegration('google')}
          installationComponent={getInstallationComponent('Google')}
        />
        <IntegrationCard
          id='microsoft_calendar'
          logo={<img src={microsoftLogo} alt='Logo' width='21' />}
          isInstalled={microsoftAzureAppCalendarsEnabled}
          installationComponent={getInstallationComponent('Microsoft')}
          onUninstall={() => uninstallIntegration('microsoft_calendar')}
          isUninstalling={isUninstalling.microsoftCalendar}
        />
      </section>
    </CollapsibleCard>
  )
}

export default CalendarsSection
