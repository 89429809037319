import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.search.search_results')

const NoResultsFound = () => (
  <div className='NoResultsFound d-flex flex-column align-items-center my-5 px-5'>
    <div className='text-large font-weight-600 mb-3'>
      {I18N('no_results_found')}
    </div>
    <div className='text-secondary text-center'>
      {I18N('no_results_helper_text')}
    </div>
  </div>
)

export default NoResultsFound
