import React from 'react'
import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath } from 'utils/i18nHelpers'
import UserSelectField from 'components/admin/users/userEditor/userSelectField'
import UserEmployeeSeachField from 'components/admin/users/userEditor/userEmployeeSeachField'
import UserDatePickerField from 'components/admin/users/userEditor/userDatePickerField'
import UserRadioButtonInput from 'components/admin/users/userEditor/userRadioButtonInput'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import UserEditableField from 'components/admin/users/userEditor/userEditableField'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.users')

const IsManagerField = ({
  user,
  setUser,
  defaultValue = false,
}) => (
  <LabeledFormFieldContainer
    label={I18N('are_they_a_manager')}
    footNote={I18N('are_they_a_manager_footnote')}
  >
    <UserRadioButtonInput
      user={user}
      setUser={setUser}
      fieldName='manager'
      defaultValue={defaultValue}
    />
  </LabeledFormFieldContainer>
)

const RoleDetailsSection = ({
  user = null,
  setUser = () => { },
  initialIsOpen = false,
}) => {
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()

  const showJobLevelField = currentCompany.settings.profile.fields.jobLevel && currentUser.manager

  return (
    <CollapsibleCard
      title={I18N('sections.role_details')}
      initialIsOpen={initialIsOpen}
      className='mt-3'
    >
      <div className='RoleDetailsSection mt-3'>
        <UserSelectField
          user={user}
          setUser={setUser}
          fieldName='workerType'
          placeholder={I18N('select_employee_type')}
          footNote={I18N('worker_type_footnote')}
          isCreatable
        />
        <IsManagerField
          user={user}
          setUser={setUser}
        />
        {showJobLevelField && (
          <UserEditableField
            user={user}
            setUser={setUser}
            fieldName='jobLevel'
            placeholder={I18N('enter_job_level')}
          />
        )}
        <UserEmployeeSeachField
          className='assisted-users-field'
          user={user}
          setUser={setUser}
          fieldName='assistedUsers'
          label={I18N('assists_label')}
          footNote={I18N('assists_footnote')}
          isMulti
        />
        <UserDatePickerField
          className='end-date-field OnlyDate'
          fieldName='endDate'
          user={user}
          setWorkingCopy={setUser}
        />
      </div>
    </CollapsibleCard>
  )
}

export default RoleDetailsSection
