import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import audienceSlice from 'redux/slices/audiences'
import RoundedAvatars from 'components/common/roundedAvatars'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import { ButtonSecondarySmall } from 'components/common/buttons'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.home.onboarding_buddies')

const OptInOrOutCard = ({
  setIsLoaded,
  EmptyState,
}) => {
  const dispatch = useDispatch()
  const { isLoadingEstimatedAudience } = useSelector(audienceSlice.selectors.getMetaData())
  const isLoaded = useIsLoaded(isLoadingEstimatedAudience)
  const newHires = useSelector(audienceSlice.selectors.getUsers())

  const {
    settings: {
      journeys: { newHiresWindowDaysLength },
      onboardingBuddySettings: { eligibleNewHiresTargetingRules },
    },
  } = useCurrentCompany()

  useEffect(() => {
    dispatch(audienceSlice.asyncActions.fetchJourneysEstimatedAudience({
      targetingRules: eligibleNewHiresTargetingRules,
      journeyBlueprintType: 'onboarding',
      daysAfterMilestone: newHiresWindowDaysLength,
    }))
  }, [])

  useEffect(() => {
    setIsLoaded(isLoaded)
  }, [isLoaded])

  if (isLoadingEstimatedAudience) {
    return EmptyState
  }

  return (
    <div className='OptInOrOutCard d-flex flex-column align-items-center'>
      <div className='font-weight-600 mb-2 text-center'>
        {I18N('new_hires_joined_recently', { count: newHires.length })}
      </div>
      <RoundedAvatars
        users={newHires}
        avatarSize='30px'
        className='my-3'
        shownAvatars={4}
      />
      <Link to='/onboarding_buddies/opt_in' className='OptInLink text-center mb-2'>
        <ButtonSecondarySmall>
          {I18N('become_an_onboarding_buddy')}
        </ButtonSecondarySmall>
      </Link>
      <Link to='/onboarding_buddies/opt_out' className='OptOutLink text-small text-center text-secondary'>
        {I18N('opt_out_of_onboarding_buddies')}
      </Link>
    </div>
  )
}

export default OptInOrOutCard
