import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import NewHireCarousel from 'components/admin/journeys/onboardingBuddies/newHireCarousel'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import newHireSlice, { NEW_HIRES_PER_PAGE } from 'redux/slices/journeys/newHires'
import SettingsButtonAndModal from 'components/admin/journeys/onboardingBuddies/settingsButtonAndModal'
import BuddiesTable from 'components/admin/journeys/onboardingBuddies/buddiesTable/buddiesTable'
import EmptyNewHires from 'components/admin/journeys/onboardingBuddies/emptyNewHires'
import ReactSelect from 'components/common/react_select'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies')

const AssignOnboardingBuddiesPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedNewHire, setSelectedNewHire] = useState()
  const [selectedNewHireIndex, setSelectedNewHireIndex] = useState()
  const [newHiresWithGroupsFetched, setNewHiresWithGroupsFetched] = useState([])

  const newHires = useSelector(newHireSlice.selectors.getNewHires())
  const { isLoading } = useSelector(newHireSlice.selectors.getMetaData())

  const { newHireId: newHireIdQueryParam } = useQueryParams()

  useEffect(() => {
    dispatch(newHireSlice.asyncActions.admin.fetchNewHires({ perPage: NEW_HIRES_PER_PAGE }))
  }, [])

  useEffect(() => {
    if (_.isEmpty(newHires)) {
      setSelectedNewHire(null)
      return
    }

    if (!selectedNewHire) {
      if (newHireIdQueryParam) {
        const newHire = newHires.find(newHire => newHire.id === newHireIdQueryParam)
        if (newHire) {
          setSelectedNewHire(newHire)
        } else {
          setSelectedNewHire(newHires[0])
        }
        history.replace({ search: null })
      } else {
        setSelectedNewHire(newHires[0])
      }

      return
    }

    const index = _.findIndex(newHires, ['id', selectedNewHire.id])

    if (index === -1) {
      // when the last user is removed react-slick automatically updates the slide
      if (selectedNewHireIndex < newHires.length) {
        setSelectedNewHire(newHires[selectedNewHireIndex])
      }
    }
  }, [newHires?.length])

  useEffect(() => {
    if (selectedNewHire) {
      const index = _.findIndex(newHires, ['id', selectedNewHire?.id])
      if (index !== -1) {
        setSelectedNewHireIndex(index)

        // Only fetch the groups for the selected new hire if we haven't fetched them already
        if (!newHiresWithGroupsFetched.includes(selectedNewHire.id)) {
          setNewHiresWithGroupsFetched([...newHiresWithGroupsFetched, selectedNewHire.id])

          dispatch(newHireSlice.asyncActions.admin.fetchNewHire(selectedNewHire.id, { includeGroups: true }))
        }
      }
    }
  }, [selectedNewHire?.id])

  if (isLoading) {
    return <CirclesLoadingIndicator className='mt-4' />
  }

  return (
    <div className='AssignOnboardingBuddiesPage'>
      <header className='AdminHeader'>
        <div className='d-flex justify-content-between align-items-end'>
          <div>
            <h3 className='mb-1'>{I18N('title')}</h3>
            <div className='text-secondary mb-1'>
              {I18N('new_hires_needing_buddy', { count: newHires.length })}
            </div>
            {newHires.length > 0 && (
              <div className='d-flex align-items-center'>
                <span className='text-secondary mr-2'>{I18N('new_hire')}</span>
                <ReactSelect
                  value={selectedNewHire}
                  getOptionValue={user => user.id}
                  getOptionLabel={user => user.preferredFullName}
                  options={newHires}
                  onChange={setSelectedNewHire}
                />
              </div>
            )}
          </div>
          <SettingsButtonAndModal />
        </div>
      </header>
      <main className='AdminContent'>
        {newHires.length > 0 ? (
          <NewHireCarousel
            className='mb-4'
            newHires={newHires}
            setSelectedNewHire={setSelectedNewHire}
            selectedNewHire={selectedNewHire}
          />
        ) : (
          <EmptyNewHires />
        )}
        <BuddiesTable
          selectedNewHire={selectedNewHire}
        />
      </main>
    </div>
  )
}

export default AssignOnboardingBuddiesPage
