import React from 'react'

import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import SearchQueryResults from 'components/analytics/search/searchQueryResults'
import SearchSummary from 'components/analytics/search/searchSummary'
import SearchUsage from 'components/analytics/search/searchUsage'
import SelectedResultTypes from 'components/analytics/search/selectedResultTypes'
import TopSelectedResults from 'components/analytics/search/topSelectedResults'

const SearchTab = () => {
  const {
    targetingRules,
    completedTargetingRules,
    setTargetingRules,
    setGroupBy,
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    groupBy,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  return (
    <main className='SearchTab'>
      <TabHeader
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        setGroupBy={setGroupBy}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      <SearchSummary
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />

      <SearchUsage
        periodStart={periodStart}
        periodEnd={periodEnd}
        groupBy={groupBy}
        targetingRules={completedTargetingRules}
      />

      <SearchQueryResults
        periodStart={periodStart}
        periodEnd={periodEnd}
        type='top'
        targetingRules={completedTargetingRules}
      />

      <SearchQueryResults
        periodStart={periodStart}
        periodEnd={periodEnd}
        type='zero'
        targetingRules={completedTargetingRules}
      />

      <SelectedResultTypes
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />

      <TopSelectedResults
        periodStart={periodStart}
        periodEnd={periodEnd}
        targetingRules={completedTargetingRules}
      />
    </main>
  )
}

export default SearchTab
