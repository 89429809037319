import React from 'react'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import { Button } from 'components/common/buttons'
import userSlice from 'redux/slices/users'
import { useSelector } from 'react-redux'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.add_new_hires_manually_modal')

const EmployeesOnJourney = ({
  alreadyAssignedText = I18N('already_assigned'),
  hasRemoveButton = true,
  onRemoveJourney = (journey) => {},
  journeyBlueprint,
}) => {
  const simpleJourneys = useSelector(journeyBlueprintSlice.selectors.getSimpleJourneys(journeyBlueprint?.id))

  const userIdsWithJourneys = simpleJourneys.map(item => item.userId)
  const currentUsers = useSelector(userSlice.selectors.getSimpleUsersByIds(userIdsWithJourneys))

  const onClickRemoveUserFromJourney = (e, user) => {
    e.preventDefault()
    e.stopPropagation()

    const journeyId = simpleJourneys.find(j => j.userId === user.id)?.id

    const journey = {
      id: journeyId,
      user,
    }

    onRemoveJourney(journey)
  }

  return (
    <div className='EmployeesOnJourney'>
      {currentUsers.length > 0 && (
        <>
          <div className='mb-1 mt-3 text-secondary font-weight-500'>{alreadyAssignedText}</div>
          <div className='mt-3 multi-select-users-container pr-4 pl-4'>
            {currentUsers.map(user => (
              <EmployeeMiniCardLink
                showEmployeeTitle={false}
                employee={user}
                key={user.id}
                className='mb-2'
                link={`/admin/users/${user.id}/journeys`}
                additionalContent={hasRemoveButton && (
                  <Button
                    variant='secondary-danger'
                    className='ml-2'
                    onClick={e => onClickRemoveUserFromJourney(e, user)}
                  >
                    {I18NCommon('remove')}
                  </Button>
                )}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default EmployeesOnJourney
