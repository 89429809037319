import React from 'react'
import classNames from 'classnames'
import Tooltip from 'components/common/clearyTooltip'

const StatusLabel = ({
  color = 'gray', // one of: gray, red, green, yellow, blue.
  children,
  className = 'text-small font-weight-500',
  style = {},
}) => (
  <span style={style} className={classNames(`StatusLabel d-inline-flex align-items-center justify-content-center status-${color}`, className)}>
    {children}
  </span>
)

export const StatusLabelWithTooltip = ({
  color = 'gray',
  children,
  statusLabelClassName = 'text-small font-weight-500',
  tooltipClassName = '',
  text = '',
  placement = 'bottom',
}) => (
  <Tooltip
    className={tooltipClassName}
    placement={placement}
    content={(
      <StatusLabel color={color} className={statusLabelClassName}>
        {children}
      </StatusLabel>
    )}
  >
    {text}
  </Tooltip>
)

export default StatusLabel
