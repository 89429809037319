import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.preview_components')

const CalendarLinkColor = () => (
  <div>
    <a
      href='https://www.google.com/calendar/event?eid=MnNlcnBkN3Yzc2txbmVycmF0ODA2aHByZWwgZ29jbGVhcnkuY29tXzl0ZjV2ZHVzNzNqNnBhcnB2dDM4ZHQ3aXA4QGc'
      className='calendar-link text-primary-link'
      target='_blank'
      rel='noopener noreferrer'
    >
      {I18N('calendar_link_text')}
    </a>
  </div>
)

export default CalendarLinkColor
