import { Button } from 'components/common/buttons'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import companyConfigurationSlice from 'redux/slices/companyConfigurations'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Radio, RadioGroup } from 'react-radio-group'
import LikeIconFilled from 'components/icons/likeIconFilled'

const I18N = i18nPath('views.admin.other_settings.reactions_tab')


const EmojiLibraryExample = () => (
  <div className='EmojiLibrary d-flex flex-column p-3'>
    <span className='m-0 text-large' role='img' aria-label='emoji library'>😀 😃 😄 😆 😅 😁 🤣 😂 🙂</span>
    <span className='m-0 text-large' role='img' aria-label='emoji library'>😉 😜 😋 😇 🥰 😍 😈 😊 😎</span>
  </div>
)

const ReactionsTab = () => {
  const dispatch = useDispatch()
  const { customStyles } = useCurrentCompany()
  const likeHighlightColor = customStyles?.variables?.colors?.likeHighlightColor

  const { isSaving, isLoading } = useSelector(companyConfigurationSlice.selectors.getMetaData())
  const reactionCompanyConfiguration = useSelector(companyConfigurationSlice.selectors.getCompanyConfiguration('reactions'))
  const savedReactionType = reactionCompanyConfiguration?.value?.type

  const [reactionType, setReactionType] = useState(savedReactionType)

  const hasReactionTypeChanged = reactionType !== savedReactionType

  useEffect(() => {
    dispatch(companyConfigurationSlice.asyncActions.admin.fetch('reactions'))
  }, [])

  const handleSaveChanges = () => {
    if (hasReactionTypeChanged) {
      dispatch(companyConfigurationSlice.asyncActions.admin.update(
        'reactions',
        { type: reactionType },
        { onSuccess: () => window.location.reload() }
      ))
    }
  }

  useEffect(() => {
    if (savedReactionType && savedReactionType !== reactionType) {
      setReactionType(savedReactionType)
    }
  }, [savedReactionType])

  return (
    <section className='ReactionsTab'>
      {isLoading ? (
        <CirclesLoadingIndicator />
      ) : (
        <div className='white-card p-3'>
          <h6>{I18N('select_reaction_library')}</h6>
          <p className='text-small text-secondary'>{I18N('reaction_description')}</p>

          <RadioGroup
            name='reactionType'
            selectedValue={reactionType}
            onChange={setReactionType}
          >
            <div className='d-flex flex-column align-items-start'>
              <label className='d-flex flex-row align-items-center m-0'>
                <Radio value='full_emoji_library' />
                <span className='ml-2'>{I18N('full_emoji_library')}</span>
              </label>
              <EmojiLibraryExample />
            </div>

            <div className='d-flex flex-column mt-3'>
              <div className='d-flex flex-row align-items-center'>
                <label className='d-flex flex-row align-items-center m-0'>
                  <Radio value='only_likes' />
                  <span className='ml-2'>{I18N('allow_only_likes')}</span>
                </label>
              </div>

              <div className='d-flex flex-row align-items-center'>
                <div className='LikeIcon'>
                  <LikeIconFilled fill={likeHighlightColor} />
                </div>
              </div>
            </div>
          </RadioGroup>
        </div>
      )}


      <div className='d-flex justify-content-end mt-5'>
        <Button
          onClick={handleSaveChanges}
          showLoadingSpinner={isSaving}
          disabled={!hasReactionTypeChanged}
        >
          {I18NCommon('save_changes')}
        </Button>
      </div>
    </section>
  )
}

export default ReactionsTab
