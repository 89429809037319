import React from 'react'

const IfYouCouldIcon = ({ fill = 'var(--text-color-primary' }) => (
  <svg
    width='39px'
    height='35px'
    viewBox='0 0 39 35'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-40.000000, -22.000000)' fill={fill} fillRule='nonzero'>
        <g transform='translate(40.000000, 22.000000)'>
          <path
            d='M36.7911585,12.962963 C36.3486122,12.962963 35.9898313,13.3244346 35.9898313,13.7705403 C35.9898313,15.8361079 34.3224296,17.516515 32.2727414,17.516515 C31.8832964,17.516515 31.4953472,17.453847 31.1195782,17.3302338 C30.8792868,17.2513065 30.6159173,17.2910394 30.4092817,17.4378031 C30.2026461,17.5845668 30.0767843,17.8211331 30.0699463,18.0758969 C30.0143877,20.1183139 28.3843812,21.7181786 26.3589466,21.7181786 C25.2792917,21.7181786 24.253486,21.2427847 23.5445785,20.4137796 C23.3923264,20.2357895 23.1707327,20.1333887 22.9375999,20.1333887 C22.7044671,20.1333887 22.4828734,20.2356818 22.3306212,20.4137796 C21.6217137,21.2427847 20.5959081,21.7181786 19.5162532,21.7181786 C18.3703553,21.7181786 17.3050174,21.194007 16.5932252,20.2800448 C16.4412935,20.0849341 16.2089086,19.9711195 15.9628478,19.9711195 C15.7167869,19.9711195 15.484402,20.0850417 15.3324703,20.2800448 C14.6206781,21.194007 13.5552334,21.7181786 12.4094423,21.7181786 C11.6616438,21.7181786 10.941304,21.4953949 10.3260984,21.0737318 C9.96015898,20.8227368 9.46173345,20.9184616 9.21289463,21.2872553 C8.96394898,21.6560489 9.05893296,22.1583621 9.42487239,22.4091418 C10.3068666,23.013748 11.338976,23.3333333 12.4094423,23.3333333 C13.7397524,23.3333333 14.9936692,22.8410342 15.9628478,21.9585136 C16.9320263,22.8409265 18.1859431,23.3333333 19.5162532,23.3333333 C20.7764738,23.3333333 21.9848752,22.8818437 22.9375999,22.0731896 C23.8903245,22.8818437 25.098726,23.3333333 26.3589466,23.3333333 C27.7540038,23.3333333 29.0718132,22.7926872 30.0697327,21.8107808 C30.832703,21.0599492 31.3452319,20.1145452 31.5599876,19.082892 C31.7962189,19.1154105 32.0342665,19.1316697 32.2728483,19.1316697 C35.2061333,19.1316697 37.5925926,16.7267043 37.5925926,13.7705403 C37.5925926,13.3244346 37.2337048,12.962963 36.7911585,12.962963 Z'
            transform='translate(23.333333, 18.148148) rotate(4.000000) translate(-23.333333, -18.148148) '
          />
          <path
            d='M9.67299862,21.7831225 C10.1004039,21.6509759 10.3336426,21.2158993 10.1941769,20.811239 C10.0546027,20.4066815 9.59496297,20.1858549 9.16777474,20.317796 C8.7860621,20.435762 8.39197662,20.495567 7.99637167,20.495567 C5.91437175,20.495567 4.22048796,18.8919285 4.22048796,16.9207253 C4.22048796,15.9026008 4.68533734,14.9284567 5.49597475,14.2480972 C5.67017116,14.1017701 5.76991354,13.8918358 5.76991354,13.6714203 C5.76991354,13.4510048 5.67017116,13.2410706 5.49597475,13.0948462 C4.68544587,12.4142812 4.22048796,11.4401371 4.22048796,10.4221154 C4.22048796,8.45080937 5.91426322,6.84717087 7.99637167,6.84717087 C8.39175955,6.84717087 8.78584503,6.90697592 9.16788328,7.02494189 C9.41208386,7.10036613 9.6794021,7.06224298 9.88930607,6.92218407 C10.09921,6.78212517 10.2269541,6.55636624 10.2340088,6.313344 C10.2905548,4.36443923 11.9463433,2.83766357 14.0038146,2.83766357 C15.1678374,2.83766357 16.2501344,3.33788862 16.9730766,4.20999423 C17.1274114,4.39629415 17.363472,4.50490916 17.6134248,4.50490916 C17.8633777,4.50490916 18.0995468,4.39608863 18.253773,4.20999423 C18.9767153,3.33788862 20.0589038,2.83766357 21.2230351,2.83766357 C21.6725812,2.83766357 22.037037,2.49270557 22.037037,2.06697993 C22.037037,1.64125429 21.6725812,1.2962963 21.2230351,1.2962963 C19.8715748,1.2962963 18.5979331,1.76610504 17.6134248,2.60820536 C16.6288081,1.76610504 15.3552749,1.2962963 14.0038146,1.2962963 C12.5866915,1.2962963 11.2480381,1.8122433 10.2344429,2.74929184 C9.45940451,3.46571935 8.93876886,4.36803575 8.72061634,5.35245565 C8.48064856,5.32142278 8.23883572,5.30590635 7.9964802,5.30590635 C5.01679893,5.30590635 2.59259259,7.60110498 2.59259259,10.4222181 C2.59259259,11.6128729 3.03540965,12.7607805 3.82846462,13.6715231 C3.03540965,14.5821629 2.59259259,15.7300704 2.59259259,16.920828 C2.59259259,19.7419412 5.0166904,22.037037 7.9964802,22.037037 C8.5637853,22.037037 9.12794291,21.9516453 9.67299862,21.7831225 Z'
            transform='translate(12.314815, 11.666667) rotate(8.000000) translate(-12.314815, -11.666667) '
          />
          <path d='M23.6133149,4.4059341 C23.9507434,4.70732342 24.4600045,4.66808931 24.750765,4.31843757 C25.4641176,3.46030282 26.4965111,2.9682045 27.5830996,2.9682045 C29.6216493,2.9682045 31.2621237,4.62417385 31.3179317,6.73835751 C31.3248136,7.00196171 31.4514837,7.24684053 31.6594463,7.39876125 C31.8674089,7.55068198 32.1322547,7.59203384 32.3741968,7.5102218 C32.7527016,7.38226509 33.1432498,7.31739505 33.5348733,7.31739505 C35.5976172,7.31739505 37.2758346,9.05684835 37.2758346,11.1951075 C37.2758346,12.2993471 36.815177,13.3559931 36.0121445,14.0941963 C35.6788022,14.4004899 35.6482637,14.9288129 35.9437556,15.2742291 C36.1031147,15.4605912 36.3247336,15.5555556 36.5475353,15.5555556 C36.7377555,15.5555556 36.9287284,15.4862271 37.0822809,15.3450066 C38.2303765,14.2898096 38.8888889,12.77729 38.8888889,11.1951075 C38.8888889,8.13506963 36.4872111,5.64548685 33.5349808,5.64548685 C33.2948668,5.64548685 33.055398,5.66231739 32.8175421,5.69597847 C32.6014072,4.62818643 32.0855868,3.64945137 31.3177166,2.87234843 C30.3134959,1.8559397 28.987116,1.2962963 27.5830996,1.2962963 C26.0266059,1.2962963 24.5489316,1.99994673 23.5287965,3.22690443 C23.2381435,3.57677909 23.275994,4.10454478 23.6133149,4.4059341 Z' />
          <path d='M15.5555556,24.6296296 C13.3747839,24.6296296 11.6666667,25.7684093 11.6666667,27.2222222 C11.6666667,28.6760351 13.37489,29.8148148 15.5555556,29.8148148 C17.7362211,29.8148148 19.4444444,28.6760351 19.4444444,27.2222222 C19.4444444,25.7684093 17.7362211,24.6296296 15.5555556,24.6296296 Z M15.5555556,28.2333228 C14.175069,28.2333228 13.2591683,27.6247647 13.2591683,27.2222222 C13.2591683,26.8196798 14.175069,26.2111217 15.5555556,26.2111217 C16.9360421,26.2111217 17.8519429,26.8196798 17.8519429,27.2222222 C17.8519429,27.6247647 16.9360421,28.2333228 15.5555556,28.2333228 Z' />
          <path d='M12.2118178,31.6057108 C11.7969853,31.6057108 11.4606726,31.9645741 11.4606726,32.4074609 C11.4606726,32.8742932 10.7172392,33.3964998 9.72222222,33.3964998 C8.72720525,33.3964998 7.98377183,32.8742932 7.98377183,32.4074609 C7.98377183,31.9405216 8.72720525,31.418315 9.72222222,31.418315 C10.1370547,31.418315 10.4733674,31.0594517 10.4733674,30.6165649 C10.4733674,30.1736782 10.1370547,29.8148148 9.72222222,29.8148148 C7.90495166,29.8148148 6.48148148,30.9536207 6.48148148,32.4074609 C6.48148148,33.861301 7.90505182,35 9.72222222,35 C11.5393926,35 12.962963,33.861301 12.962963,32.4074609 C12.962963,31.964681 12.6266502,31.6057108 12.2118178,31.6057108 Z' />
        </g>
      </g>
    </g>
  </svg>
)

export default IfYouCouldIcon
