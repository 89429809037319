import React, { useState } from 'react'
import classNames from 'classnames'
import PillTabSelector from 'components/common/pillTabSelector'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

const I18N = i18nPath('views.journeys.my_tasks')

const MY_TASKS = 'my_tasks'
const DIRECT_REPORT = 'direct_report_tasks'

const PersonTabs = ({
  currentOwnerTab,
  setCurrentOwnerTab,
  showDirectReportTab = false,
}) => (
  <section className='person-tabs mt-4'>
    <PillTabSelector
      defaultSelectedIndex={[MY_TASKS, DIRECT_REPORT].indexOf(currentOwnerTab)}
      pillClasses='text-normal'
      variant='PageTab'
      tabs={[
        {
          text: I18N('my_tasks'),
          onClick: () => {
            setCurrentOwnerTab(MY_TASKS)
          },
        },
        {
          text: I18N('direct_report_tasks'),
          onClick: () => {
            setCurrentOwnerTab(DIRECT_REPORT)
          },
          hidden: !showDirectReportTab,
        },
      ]}
    />
  </section>
)

export default PersonTabs
