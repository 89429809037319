import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { showToastMessage } from 'redux/slices/toasts'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'

export const NEW_HIRES_PER_PAGE = 200

export const initialState = {
  newHireIds: [],
  meta: {
    ...defaultMeta,
    queryParams: defaultPaginationParams,
    isLoadingGroups: false,
  },
}

const slice = createSlice({
  name: 'newHires',
  initialState,
  reducers: {
    ...defaultActions,

    setNewHireIds(state, action) {
      state.newHireIds = action.payload
    },

    removeNewHireId(state, action) {
      state.newHireIds = state.newHireIds.filter(id => id !== action.payload)
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    isLoadingGroups(state, action) {
      state.meta.isLoadingGroups = action.payload
    },
  },
})


//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------
const asyncActions = {
  admin: {
    fetchNewHire: (userId, params = {}) => async (dispatch) => {
      if (params.includeGroups) {
        dispatch(newHireSlice.actions.isLoadingGroups(true))
      } else {
        dispatch(newHireSlice.actions.isLoading(true))
      }

      await API.admin.newHires.fetch(userId, params).then((response) => {
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(newHireSlice.actions.isNotFound(false))
      }).catch((e) => {
        dispatch(newHireSlice.actions.isNotFound(true))
      }).finally(() => {
        if (params.includeGroups) {
          dispatch(newHireSlice.actions.isLoadingGroups(false))
        } else {
          dispatch(newHireSlice.actions.isLoading(false))
        }
      })
    },

    fetchNewHires: queryParams => async (dispatch) => {
      dispatch(newHireSlice.actions.isLoading(true))

      try {
        const response = await API.admin.newHires.fetchAll(queryParams)
        const newQueryParams = queryParamsFromHeaders(response)
        const userIds = response.data.data.map(user => user.id)

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(newHireSlice.actions.setNewHireIds(userIds))
        dispatch(newHireSlice.actions.setQueryParams({ ...queryParams, ...newQueryParams }))
      } finally {
        dispatch(newHireSlice.actions.isLoading(false))
      }
    },

    removeFromOnboardingBuddies: (id, onSuccess = () => {}, successToastMessage = '') => async (dispatch) => {
      dispatch(newHireSlice.actions.isSaving(true))

      try {
        await API.admin.newHires.removeFromOnboardingBuddies(id)
        if (successToastMessage) {
          dispatch(showToastMessage({ message: successToastMessage, type: 'success' }))
        }
        onSuccess()
      } finally {
        dispatch(newHireSlice.actions.isSaving(false))
      }
    },

    updateOnboardingBuddyAvailability: (id, params, onSuccess = () => {}, successToastMessage = '') => async (dispatch) => {
      dispatch(newHireSlice.actions.isSaving(true))

      try {
        await API.admin.newHires.updateOnboardingBuddyAvailability(id, params)
        if (successToastMessage) {
          dispatch(showToastMessage({ message: successToastMessage, type: 'success' }))
        }
        onSuccess()
      } finally {
        dispatch(newHireSlice.actions.isSaving(false))
      }
    },
  },
}
//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getMetaData: () => state => state.newHires.meta,

  getNewHire: userId => state => build(state.entities, 'user', userId) || {},

  getNewHires: () => state => state.newHires.newHireIds.map(id => build(state.entities, 'user', id)),
}

const newHireSlice = {
  ...slice,
  asyncActions,
  selectors,
}

export default newHireSlice
