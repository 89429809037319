import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import authTokenSlice from 'redux/slices/authTokens'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.auth_tokens')

const RemoveAuthTokenModal = ({ authToken, setAuthToken, user }) => {
  const dispatch = useDispatch()
  const closeRemoveModal = () => setAuthToken(null)
  const { isLoading } = useSelector(authTokenSlice.selectors.getMetaData())

  const removeToken = (authToken) => {
    dispatch(authTokenSlice.asyncActions.admin.destroyAuthToken(user.id, authToken.id))
  }

  if (!authToken) { return null }

  return (
    <Modal size='md' visible toggle={closeRemoveModal}>
      <div className='p-2 text-center'>
        <h3 className='text-left'>{I18N('confirm_remove_auth_token')}</h3>
        <div className='text-left mt-5'>
          <p>{I18N('confirm_remove_auth_token_message', { value: authToken.redactedToken })}</p>
        </div>

        <div className='d-flex justify-content-end mt-4'>
          <Button
            className='mr-2'
            variant='outline'
            onClick={closeRemoveModal}
          >
            {I18N('close')}
          </Button>

          <Button
            className='mr-2'
            onClick={() => removeToken(authToken)}
            showLoadingSpinner={isLoading}
            disabled={isLoading}
          >
            {I18N('confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RemoveAuthTokenModal
