import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import pageSlice from 'redux/slices/pages'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { useCallback } from 'react'

const I18N = i18nPath('views.pages')

const useCollaborativePageRecord = () => {
  const dispatch = useDispatch()
  const { workspaceSlug } = useParams()

  const meta = useSelector(pageSlice.selectors.getPagesMetadata())
  const { isPublishing } = meta

  const onCollaborativePageRecordChange = useCallback((data) => {
    // If this user is publishing, we don't want to update the page record, since that (and the corresponding onSuccess flow)
    // will be done in the publishing flow in the slice. This way we distinguish between the flow of publishing and the flow for other users

    if (isPublishing) { return }

    dispatch(entitySlice.actions.update({ data }))

    const isPublishingFromDraft = data?.meta?.publishingFromDraft

    if (isPublishingFromDraft) {
      const lastPublishedById = data?.data?.relationships?.lastUpdater?.data?.id
      const lastPublisherName = data?.included?.find(i => i.id === lastPublishedById && i.type === 'user')?.attributes?.preferredFullName

      if (lastPublisherName) {
        dispatch(showToastMessage({ message: I18N('published_by', { lastPublisherName }), type: 'success', timeout: 5000 }))
      }
    }
  }, [isPublishing, workspaceSlug])

  return {
    onCollaborativePageRecordChange,
  }
}

export default useCollaborativePageRecord
