import React, { useEffect } from 'react'

import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

import FormCheck from 'components/form_fields/formCheck'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import qnaEventSlice from 'redux/slices/qnaEvents'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import BetaTag from 'components/common/tags/betaTag'
import InfoTooltip from 'components/common/infoTooltip'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.qna.events')

const VideoConferenceRecapToggle = ({
  className = '',
  url,
  checked,
  onChange,
  showHelpText = true,
  defaultChecked = false,
}) => {
  const meetingId = url?.match(/(?<=zoom.us\/j\/)(\d+)/)?.[0]
  const { isSaving } = useSelector(qnaEventSlice.selectors.getEventMetaData())
  const appName = useCurrentCompany()?.appName

  useEffect(() => {
    if (!!meetingId && defaultChecked && !checked) {
      onChange(true)
    }
  }, [meetingId])

  if (!meetingId) return null

  return (
    <div className={classNames('VideoConferenceRecapToggle', className)}>
      {isSaving ? (
        <CirclesLoadingIndicator height={22} />
      ) : (
        <FormCheck
          checked={checked}
          onChange={() => onChange(!checked)}
          name='video_conference_recap'
          label={(
            <div className='d-flex align-items-center'>
              {I18N('video_conference_recap_toggle')}
              <InfoTooltip
                className='ml-1'
                placement='top'
                textClassName='text-small'
                text={
                  i18nFormat(
                    I18N('video_conference_recap_toggle_tooltip'),
                    <a
                      href='/settings/integrations'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {I18N('app_name_settings', { appName })}
                    </a>
                  )
                }
              />
              <BetaTag className='ml-2' />
            </div>
          )}
        />
      )}
      {showHelpText && (
        <span className='text-secondary text-small'>{I18N('video_conference_recap_toggle_help_text')}</span>
      )}
    </div>
  )
}

export default VideoConferenceRecapToggle
