import React, { useEffect, useState } from 'react'
import ReactSelect from 'components/common/react_select'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import AudienceRulesSelector from 'components/common/audience/audienceRulesSelector'
import { targetingRulesPresent } from 'utils/audience'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.includes.audience_selector')
const PUBLIC_ACCESS = 'public_access'

const AudienceDropdownSelector = ({
  targetingRules = {},
  updateWorkingCopy,
  setDisableButton = (isDisabled => null),
  targetingOptions,
  accessLevel = PUBLIC_ACCESS,
  colleaguesCount,
}) => {
  const entireCompanyOptionLabel = Number.isInteger(colleaguesCount) ? I18N('all_count_colleagues', { count: colleaguesCount }) : I18N('entire_company')
  const ENTIRE_COMPANY_OPTION = { value: 'entireCompany', label: entireCompanyOptionLabel }
  const SPECIFIC_AUDIENCE_OPTION = { value: 'specificAudience', label: I18N('specific_audience').toLowerCase() }
  const audienceTypes = [ENTIRE_COMPANY_OPTION, SPECIFIC_AUDIENCE_OPTION]
  const isAudienceEmpty = !targetingRulesPresent(targetingRules)

  const currentUser = useCurrentUser()

  const defaultDropdownOption = () => {
    // determine if audience is empty and populate default selection from dropdown
    if (isAudienceEmpty && currentUser.permissions.qnaManager) {
      return ENTIRE_COMPANY_OPTION
    } else {
      return SPECIFIC_AUDIENCE_OPTION
    }
  }

  const [selectedAudienceType, setSelectedAudienceType] = useState(defaultDropdownOption())
  const isSpecificAudience = selectedAudienceType.value === 'specificAudience'

  const updateDisableButtonStatus = () => {
    setDisableButton(isSpecificAudience ? isAudienceEmpty : false)
  }

  useEffect(() => {
    updateDisableButtonStatus()
  }, [targetingRules])


  const handleTargetingRulesChange = (newTargetingRules) => {
    updateWorkingCopy({
      targetingRules: newTargetingRules,
    })
  }

  const handleAudienceTypeChange = (selectedOption) => {
    setSelectedAudienceType(selectedOption)
    const specificAudience = selectedOption.value === 'specificAudience'

    updateWorkingCopy({
      targetingRules: {
        targetEntireCompany: !specificAudience,
        rules: [],
        users: [],
      },
    })
  }

  const iconSrc = accessLevel === PUBLIC_ACCESS ? '/images/globeIcon.svg' : '/images/lockIcon.svg'

  return (
    <div className='AudienceDropdownSelector'>
      {currentUser.permissions.qnaManager ? (
        <>
          <div className='d-flex flex-row align-items-center dropdown'>
            <CdnSvg src={iconSrc} className='Icon mr-2' />
            <label className='form-label mr-2 mb-0'>{I18N('invite')}</label>
            <ReactSelect
              className='AudienceTypeSelect'
              value={selectedAudienceType}
              onChange={handleAudienceTypeChange}
              options={audienceTypes}
              isSearchable={false}
            />
          </div>
          {selectedAudienceType.value === 'specificAudience' && (
            <AudienceRulesSelector
              targetingRules={targetingRules}
              className='mb-5'
              onChange={handleTargetingRulesChange}
              targetingOptions={targetingOptions}
            />
          )}
        </>
      ) : (
        <>
          <div className='d-flex flex-row align-items-center dropdown'>
            <CdnSvg src={iconSrc} className='Icon mr-2' />
            <label className='form-label mr-2 mb-0'>{I18N('invite_your_audience')}</label>
          </div>
          {selectedAudienceType.value === 'specificAudience' && (
            <AudienceRulesSelector
              targetingRules={targetingRules}
              className='mb-5'
              onChange={handleTargetingRulesChange}
              targetingOptions={targetingOptions}
            />
          )}
        </>
      )}

    </div>
  )
}

export default AudienceDropdownSelector
