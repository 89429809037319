import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'
import { groupPath } from 'utils/routeHelpers'

import Card from 'components/common/card'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.home')

const GroupsWidget = ({ widget, resizeWidget }) => {
  const currentCompany = useCurrentCompany()

  const {
    data, isLoading, error, isLoaded,
  } = useFetch(API.groups.fetchCurrentUserGroups)

  const groups = data?.activeGroups || []
  const followedGroups = data?.activeFollowedGroups || []

  const hasGroups = !_.isEmpty(groups)
  const hasFollowedGroups = !_.isEmpty(followedGroups)
  const hasNoGroups = !hasGroups && !hasFollowedGroups

  return (
    <Card
      className='HomePageWidget GroupsWidget'
    >
      <WidgetTitle title={currentCompany.pluralGroupDisplayName} />

      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        isLoaded={isLoaded}
        scrollableContentClassName='GroupsGrid'
        showScrollbarOutside
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {error && (
              <div className='text-secondary'>{I18N('error_loading_teams')}</div>
            )}

            {hasNoGroups && (
              <div className='d-flex flex-column align-items-center'>
                <CdnSvg className='PeopleWithFlag' src='/images/illustrations/peopleWithFlag.svg' />
                <div className='text-center mb-2'>{I18N('you_are_not_part_of_any_teams')}</div>
                <Link to='/people/groups' className='text-small'>
                  {I18N('view_all_teams')}
                </Link>
              </div>
            )}

            {hasGroups && (
              <div>
                <div className='text-secondary font-weight-500'>
                  {I18N('my_teams')}
                </div>
                {groups.map(group => <GroupListItem key={`group-link-${group.id}`} group={group} />)}
              </div>
            )}

            {hasFollowedGroups && (
              <div>
                <div
                  className={classNames('text-secondary font-weight-500')}
                >
                  {I18N('teams_i_follow')}
                </div>
                {followedGroups.map(group => <GroupListItem key={`group-link-${group.id}`} group={group} />)}
              </div>
            )}
          </>
        )}
      </AutoResizableScrollableSection>
    </Card>
  )
}

const GroupListItem = ({ group }) => (
  <Link to={groupPath(group, group.groupType)} className='GroupListItem d-flex align-items-center p-1'>
    <img
      alt={group.name}
      className='bordered-thumbnail mr-3'
      style={{ height: '30px', width: '30px' }}
      src={group.primaryPhotoThumbnailUrl}
    />
    <span className='text-primary-link my-1 d-flex'>
      {group.name}
    </span>
  </Link>
)

export default GroupsWidget
