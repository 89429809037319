import React from 'react'

import CultureActiveSigners from 'components/analytics/culture/cultureActiveSigners'
import CultureEngagement from 'components/analytics/culture/cultureEngagement'
import CultureShoutoutActivity from 'components/analytics/culture/cultureShoutoutActivity'
import CultureSummary from 'components/analytics/culture/cultureSummary'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'

const CultureTab = ({
  areGreetingCardEnabled,
  areShoutoutsEnabled,
}) => {
  const {
    targetingRules,
    completedTargetingRules,
    setTargetingRules,
    setGroupBy,
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    groupBy,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  return (
    <main className='CultureTab'>
      <TabHeader
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        setGroupBy={setGroupBy}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      <CultureSummary
        periodStart={periodStart}
        periodEnd={periodEnd}
        areGreetingCardsEnabled={areGreetingCardEnabled}
        areShoutoutsEnabled={areShoutoutsEnabled}
        targetingRules={completedTargetingRules}
      />

      <CultureEngagement
        periodStart={periodStart}
        periodEnd={periodEnd}
        groupBy={groupBy}
        targetingRules={completedTargetingRules}
      />

      <CultureActiveSigners
        periodStart={periodStart}
        periodEnd={periodEnd}
        order='most'
        areGreetingCardsEnabled={areGreetingCardEnabled}
        targetingRules={completedTargetingRules}
      />

      <CultureActiveSigners
        periodStart={periodStart}
        periodEnd={periodEnd}
        order='least'
        areGreetingCardsEnabled={areGreetingCardEnabled}
        targetingRules={completedTargetingRules}
      />

      <CultureShoutoutActivity
        periodStart={periodStart}
        periodEnd={periodEnd}
        type='sent'
        areShoutoutsEnabled={areShoutoutsEnabled}
        targetingRules={completedTargetingRules}
      />

      <CultureShoutoutActivity
        periodStart={periodStart}
        periodEnd={periodEnd}
        type='received'
        areShoutoutsEnabled={areShoutoutsEnabled}
        targetingRules={completedTargetingRules}
      />
    </main>
  )
}

export default CultureTab
