import React, { useState } from 'react'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import classNames from 'classnames'
import TrashIcon from 'components/icons/trashIcon'
import FormEditableField from 'components/form_fields/formEditableField'
import { AnswerContent } from 'components/admin/surveys/answers/answerCard'
import { SurveyQuestionType, QuestionTypeEnum, AddQuestionFunctionType } from 'types/surveys/question'
import isValidQuestion, { isOptionsQuestion, isTextQuestion } from 'utils/surveys/isValidQuestion'
import QuestionOptionsEditForm from 'components/admin/surveys/questionOptionsEditForm'
import FormCheck from 'components/form_fields/formCheck'
import QuestionCardAdvancedSettings from 'components/admin/surveys/questionCardAdvancedSettings'
import ScaleQuestionEditForm from 'components/admin/surveys/scaleQuestionEditForm'
import { SortableHandle, SortableElement } from 'react-sortable-hoc'
import QuestionTitleWithIndexBubble from 'components/admin/surveys/questions/questionTitleWithIndexBubble'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import SurveyFormType from 'types/surveys/form'
import ProfileFieldQuestionEditForm from 'components/admin/surveys/profileFieldQuestionEditForm'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.surveys')

const pencilIconPath = '/images/pencilIcon.svg'
const duplicateIconPath = '/images/duplicateIcon.svg'
const eyeOpenIconPath = '/images/eyeOpenIcon.svg'

interface QuestionCardActionsProps {
  form: SurveyFormType,
  isEditing: boolean,
  setIsEditing: (value: boolean) => void,
  index: number,
  question: SurveyQuestionType,
  addQuestion: AddQuestionFunctionType,
  updateQuestion: (changes: Partial<SurveyQuestionType>) => void,
  deleteQuestion: () => void,
  isDisabled?: boolean,
}

export interface QuestionCardProps {
  form: SurveyFormType,
  question: SurveyQuestionType,
  questionIndex: number,
  addQuestion: AddQuestionFunctionType,
  updateQuestion: (changes: Partial<SurveyQuestionType>) => void,
  deleteQuestion: () => void,
  className?: string,
  isDisabled?: boolean,
}

const DragHandle = SortableHandle(() => <div className='drag-handle p-1'>:::</div>)

const QuestionCardActions = ({
  form,
  question,
  index = 0,
  isEditing = false,
  setIsEditing = (value: boolean) => {},
  addQuestion = (questionData: any, questionIndex?: number) => {},
  updateQuestion = (changes: Partial<SurveyQuestionType>) => {},
  deleteQuestion = () => {},
  isDisabled = true,
} : QuestionCardActionsProps) => {
  const onDuplicateClick = () => {
    const newOptions = (question?.options || []).map(option => _.omit(option, 'id'))
    addQuestion({ ..._.omit(question, ['id']), options: newOptions, isEditing }, index + 1)
  }

  return (
    <div className='QuestionCardActions d-flex justify-content-between align-items-center px-3'>
      <QuestionTitleWithIndexBubble
        form={form}
        questionNumber={index + 1}
        question={question}
        isEditing={isEditing}
        isDisabled={isDisabled}
        updateQuestion={updateQuestion}
      />
      <div className='QuestionActions d-flex align-items-center justify-content-center'>
        {!isEditing ? (
          <IconButtonWithPopover
            id={question?.id}
            text={I18NCommon('edit')}
            icon={<CdnSvg src={pencilIconPath} />}
            onClick={() => setIsEditing(true)}
          />
        ) : (
          <>
            {isValidQuestion(question) && (
              <IconButtonWithPopover
                id={question?.id}
                text={I18NCommon('preview')}
                icon={<CdnSvg src={eyeOpenIconPath} />}
                onClick={() => setIsEditing(false)}
              />
            )}
          </>
        )}
        <IconButtonWithPopover
          id={question?.id}
          text={I18NCommon('duplicate')}
          icon={<CdnSvg src={duplicateIconPath} className='CopyIcon' />}
          onClick={onDuplicateClick}
        />
        <IconButtonWithPopover
          id={question?.id}
          text={I18NCommon('delete')}
          icon={<TrashIcon className='TrashIcon' />}
          onClick={deleteQuestion}
        />
      </div>
    </div>
  )
}

const QuestionCard = SortableElement<QuestionCardProps>(({
  form,
  className = '',
  question,
  addQuestion = (questionData: any, questionIndex?: number) => {},
  updateQuestion = (changes: Partial<SurveyQuestionType>) => {},
  deleteQuestion = () => {},
  isDisabled = true,
  questionIndex = 0,
} : QuestionCardProps) => {
  const [isEditing, setIsEditing] = useState(!isValidQuestion(question) || _.get(question, 'isEditing', false))
  const isText = isTextQuestion(question)
  const optionsQuestion = isOptionsQuestion(question)
  const isProfileFieldQuestion = !!question?.profileField

  return (
    <Card
      className={classNames('QuestionCard', className)}
      cardBodyClassName={isEditing ? 'p-0' : 'px-3 pb-3 pt-0'}
      title={(
        <div className={classNames('d-flex flex-column', { 'px-3': isEditing })}>
          <div className='w-100 d-flex justify-content-center'>
            <DragHandle />
          </div>
          <QuestionCardActions
            isDisabled={isDisabled}
            question={question}
            index={questionIndex}
            addQuestion={addQuestion}
            updateQuestion={updateQuestion}
            deleteQuestion={deleteQuestion}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            form={form}
          />
        </div>
      )}
    >
      {isEditing ? (
        <div className='EditQuestionContent px-3 py-2 mt-4 mb-2'>
          <div className='px-3'>
            <FormEditableField
              className='mt-3'
              label={<label className='required-form-label font-weight-500'>{I18N('questions.title')}</label>}
              currentValue={question?.title}
              onChange={({ target }) => updateQuestion({ title: target?.value })}
              isRequired
              placeholder={I18N('questions.title_placeholder')}
            />
            {optionsQuestion && (
              <QuestionOptionsEditForm
                index={questionIndex}
                question={question}
                updateQuestion={updateQuestion}
                isDisabled={isDisabled}
              />
            )}
            {question?.type === QuestionTypeEnum.Scale && (
              <ScaleQuestionEditForm question={question} updateQuestion={updateQuestion} isDisabled={isDisabled} />
            )}
            {isProfileFieldQuestion && (
              <ProfileFieldQuestionEditForm
                form={form}
                question={question}
                updateQuestion={updateQuestion}
                isDisabled={isDisabled}
              />
            )}
            {
              !isText && (
                <FormCheck
                  className='mt-3'
                  name='allow_additional_text'
                  label={I18N('questions.allow_additional_text')}
                  checked={question?.allowAdditionalText}
                  onChange={({ target }) => updateQuestion({ allowAdditionalText: target.checked })}
                />
              )
            }
            <QuestionCardAdvancedSettings
              question={question}
              updateQuestion={updateQuestion}
            />
          </div>
        </div>
      ) : (
        <AnswerContent question={question} isEditing className='px-3 pb-2' />
      )}
    </Card>
  )
})

export default QuestionCard
