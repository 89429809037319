import React from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

const ClickWrapper = ({
  className,
  to,
  children,
}) => {
  const history = useHistory()

  const handleOnClick = (event) => {
    const { target } = event
    // Only clicking on the card, not its children, should trigger a route change.
    // We check the type of the className is string, because it could be a object when cliking the LikeWidget
    if (typeof target?.className === 'string' && target?.className?.includes('ClickWrapperTargetContainer')) {
      history.push(to)
    }
  }
  return (
    <div className={classNames('ClickWrapper', className)} onClick={handleOnClick}>
      {children}
    </div>
  )
}

export default ClickWrapper
