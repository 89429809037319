import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import qnaAnswerSlice from 'redux/slices/qnaAnswers'
import moment from 'moment'
import classNames from 'classnames'

import { LoadingContainer } from 'components/common/loadingContainer'
import AnswerList from 'components/qna/answerList'
import AnswerForm from 'components/qna/answerForm'
import RichTextView from 'components/common/richTextView'
import TruncatedFadedContent from 'components/common/truncatedFadedContent'
import BackButton from 'components/common/backButton'
import CalendarIcon from 'components/icons/calendarIcon'
import Card from 'components/common/card'
import CommentsWidget from 'components/comments/commentsWidget'
import QuestionContent from 'components/qna/questionContent'
import DeleteQuestionDropdown from 'components/qna/deleteQuestionDropdown'
import QuestionVotingSection from 'components/qna/questionVotingSection'
import UnauthorizedEvent from 'components/qna/unauthorizedEvent'
import { Button } from 'components/common/buttons/button'
import { trackEvent } from 'services/tracker'

const I18N = i18nPath('views.qna.questions.question_page')
const EventI18N = i18nPath('views.qna.events')

const QuestionPage = (props) => {
  const { questionId } = useParams()
  const dispatch = useDispatch()

  const {
    question,
    meta: { isLoading, isNotFound, isUnauthorized },
  } = useSelector(qnaQuestionSlice.selectors.getQuestionPageData(questionId))
  const answerSliceData = useSelector(qnaAnswerSlice.selectors.getAnswerFormData())

  const showAnswerFormHandler = () => dispatch(qnaAnswerSlice.actions.isAnswerFormVisible(true))

  // Second argument as an empty array ensures this only run after the component mounted (same as componentDidMount)
  //
  // See:
  // - https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
  // - https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
  useEffect(() => {
    dispatch(qnaQuestionSlice.asyncActions.fetch(questionId, true))
  }, [questionId])

  useEffect(() => {
    if (question?.id) {
      trackEvent('qna_question:view', { qna_question_id: question.id })
    }
  }, [question?.id])

  const QuestionPageContent = () => {
    const [commentsCount, setCommentsCount] = useState(question?.commentsCount)

    // TODO: need to figure out a better pattern for this, this should be handled by isLoading
    // On the first load, questionSliceData.meta.isLoading is initially false, so everything works fine
    // However, if you click to another question, and it does not exist in the redux entity slice, isLoading will initially
    // be 'false', so this 'QuestionPageContent' will be called and the method will fail since 'question' is null
    // when we dispatch qnaQuestionSlice.asyncActions.fetch(questionId, true), it sets isLoading to false, but it's too late by that point.
    // Maybe react-suspense can solve this?
    if (!question) {
      return <LoadingContainer isLoading />
    }

    const { isAnswerFormVisible } = answerSliceData
    const { answers, event } = question

    const sortedAnswers = _.sortBy(answers, ['isAccepted', 'createdAt']).reverse()

    const { permittedActions } = question

    const answersCount = sortedAnswers.length
    const showAddAnswerButton = permittedActions.answer && !isAnswerFormVisible
    const showVotingSection = question.type !== 'icebreaker'

    return (
      <div className='row'>
        <div className='col-md-8 offset-md-2'>
          <Card>
            <div className='d-flex align-items-center justify-content-between'>
              <h1>
                <Link className='text-primary-link font-weight-700' to={`/events/${event.slug}`}>
                  {event.title}
                </Link>
              </h1>
            </div>

            {event.eventTime && (
              <div className='text-secondary d-flex align-items-center'>
                <CalendarIcon className='mr-2' />
                {EventI18N('hosted_on', { hostedDate: moment(event.eventTime).format('LL') })}
              </div>
            )}

            <div className='my-4 position-relative'>
              <TruncatedFadedContent>
                <RichTextView record={event} recordType='event' richTextKey='description' />
              </TruncatedFadedContent>
            </div>
          </Card>
        </div>

        <div className='col-md-8 offset-md-2 mt-3'>
          <BackButton className='mb-4' backToText={EventI18N('event_page')} url={`/events/${event.slug}`} />

          <Card>
            <div>
              <DeleteQuestionDropdown question={question} />
              <div className='d-flex'>
                {showVotingSection && (
                  <div className='mt-3 mr-3'>
                    <QuestionVotingSection
                      event={event}
                      question={question}
                    />
                  </div>
                )}
                <div className='mr-3' style={{ width: '100%' }}>
                  <QuestionContent question={question} />
                </div>
              </div>
            </div>
            <div className='pl-2 mt-2'>
              <CommentsWidget
                commentable={question}
                type='question'
                key={`comments-question-${question.id}`}
                commentsCount={commentsCount}
                setCommentsCount={setCommentsCount}
                showEditor={true}
                showCommentsOnInit={question.acceptedAnswer == null}
                commentsOnQuestions
              />
              <hr />
            </div>
            <div className='mt-4 d-flex align-items-center justify-content-between ml-4 pl-3'>
              <h5 className={classNames('font-weight-500 text-large', answersCount === 0 ? 'mb-3' : 'mb-0')}>
                {I18N('answers_count', { answersCount })}
              </h5>
              {showAddAnswerButton && answersCount > 0 && (
                <Button onClick={showAnswerFormHandler}>{I18N('add_answer')}</Button>
              )}
            </div>

            {showAddAnswerButton && answersCount === 0 && (
              <div className='mt-4 text-center'>
                <div>{I18N('know_the_answer')}</div>
                <div className='mt-3'>
                  <Button onClick={showAnswerFormHandler}>{I18N('add_answer')}</Button>
                </div>
              </div>
            )}

            {isAnswerFormVisible && <AnswerForm question={question} className='mt-3' />}

            <AnswerList answers={sortedAnswers} className='ml-4 pl-3' />
          </Card>
        </div>
      </div>
    )
  }

  return (
    <div className='container mt-4'>
      {isUnauthorized ? <UnauthorizedEvent /> : (
        <LoadingContainer isLoading={isLoading} isNotFound={isNotFound} useCirclesLoadingIndicator>
          {QuestionPageContent}
        </LoadingContainer>
      )}
    </div>
  )
}

export default QuestionPage
