import React from 'react'
import Card from 'components/common/card'
import ProgressiveImage from 'components/common/images/progressiveImage'
import { Button } from 'components/common/buttons'
import classNames from 'classnames'
import { i18nMoment } from 'utils/i18nHelpers'

export const CarouselCard = ({ carouselCard }) => {
  const {
    coverImageUrl,
    backgroundUrl,
    backgroundColor,
    title,
    subtitle,
    displayDate,
    buttonText,
    buttonUrl,
    buttonStyle,
    buttonColor,
  } = carouselCard

  let style = {}
  let variant = ''
  if (buttonStyle === 'outline') {
    variant = 'secondary'
    style = {
      border: `1px solid ${buttonColor}`,
      color: buttonColor,
      backgroundColor: 'transparent',
    }
  } else {
    variant = 'primary'
    style = { backgroundColor: buttonColor }
  }

  return (
    <Card
      className={classNames('CarouselCard', backgroundUrl && 'pointer')}
      cardBodyClassName='p-0'
      style={{ backgroundColor }}
      onClick={() => {
        if (!backgroundUrl) return

        window.open(backgroundUrl, '_blank')
      }}
    >
      <ProgressiveImage src={coverImageUrl} />
      {(title || subtitle || buttonText) && (
        <div className='TextContainer'>
          {displayDate && (
            <div className='text-white text-small'>{i18nMoment(displayDate).format('LL')}</div>
          )}
          <h2 className='text-white mb-1 truncate-text-at-2-lines'>{title}</h2>
          <div className='text-white truncate-text-at-2-lines'>{subtitle}</div>
          {buttonText && (
            <a
              href={buttonUrl}
              target='_blank'
              rel='noopener noreferrer'
              onClick={e => e.stopPropagation()}
            >
              <Button variant={variant} style={style} className='mt-3'>
                {buttonText}
              </Button>
            </a>
          )}
        </div>
      )}
    </Card>
  )
}

export default CarouselCard
