import React, { useState, useEffect, ReactElement } from 'react'
import ReactDOM from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  ToastContainer, Flip, toast, ToastPosition
} from 'react-toastify'

import isElementVisible from 'utils/isElementVisible'
import CloseIcon from 'components/icons/closeIcon'
import toastSlice from 'redux/slices/toasts'
import getMessage from 'components/common/toastMessage/getMessage'

const CloseButton = ({ closeToast }): ReactElement => {
  const dispatch = useDispatch()

  const handleClick = () => {
    closeToast()
    dispatch(toastSlice.actions.clearToastMessage({}))
  }

  return (
    <div className='Toastr--CloseIcon' onClick={handleClick} data-testid='toast-close-icon'>
      <CloseIcon />
    </div>
  )
}

export const DEFAULT_TOAST_BODY_CLASSNAME = 'text-dark d-flex align-items-center justify-content-center p-2 word-break-word'

interface Props {
  bodyClassName?: string
  position?: ToastPosition
  autoClose?: number
}

const ToastMessage = ({
  bodyClassName = 'text-white d-flex align-items-center justify-content-center p-2 break-word',
  position = 'top-right',
  autoClose = 2000,
}: Props): ReactElement => {
  const toastMessages = useSelector(toastSlice.selectors.getToasts)
  const [toastYPosition, setToastYPosition] = useState(0)

  const triggerToast = (message: any, type: string, timeout: number) => {
    const parsedMessage = getMessage(message)
    const toastConfig = { icon: false }
    const toastParam = {
      success: { ...toastConfig, autoClose: timeout || autoClose },
      warn: { ...toastConfig, autoClose: timeout || 10000 },
      info: toastConfig,
      error: { ...toastConfig, autoClose: timeout || 10000 },
      default: { ...toastConfig, bodyClassName: DEFAULT_TOAST_BODY_CLASSNAME },
    }

    if (!toastParam[type]) {
      toast(parsedMessage, toastParam.default)

      return
    }

    toast[type](parsedMessage, toastParam[type])
  }

  useEffect(() => {
    const navbarElement = document.querySelector('header')

    if (isElementVisible(navbarElement)) {
      const { golinksBannerRef } = window
      const navbarHeight = navbarElement!.clientHeight
      const currentRef = golinksBannerRef?.current ?? { clientHeight: 0 }
      const golinksBannerHeight = currentRef.clientHeight

      setToastYPosition(navbarHeight + golinksBannerHeight + 20)
    } else {
      setToastYPosition(0)
    }
  }, [])

  useEffect(() => {
    const { toastMessage, toastType, timeout } = toastMessages

    if (!toastMessage) return

    triggerToast(toastMessage, toastType, timeout)
  }, [toastMessages])

  return ReactDOM.createPortal(
    <ToastContainer
      style={{ marginTop: toastYPosition }}
      position={position}
      autoClose={autoClose}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      bodyClassName={bodyClassName}
      pauseOnFocusLoss
      closeButton={CloseButton}
      draggable={false}
      pauseOnHover
      transition={Flip}
    />,
    document.body
  )
}

export default ToastMessage
