import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import appSignal from 'services/appSignal'
import entitySlice from 'redux/slices/entities'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import normalizeTargetingRules, { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'


const buildManagedGroupChannelPayload = managedGroupChannel => ({
  ..._.pick(managedGroupChannel, [
    'id',
    'channelType',
  ]),
  channelId: managedGroupChannel.channel.id,
})

export const buildManagedGroupPayload = (managedGroup, previousManagedGroup) => {
  if (!managedGroup) {
    return null
  }

  const payload = _.pick(managedGroup, [
    'id',
    'name',
    'targetingRules',
  ])

  if (payload.targetingRules) {
    payload.targetingRules = normalizeTargetingRules(payload.targetingRules)
  }

  let managedGroupChannels = managedGroup.managedGroupChannels.filter(mgc => mgc.channel)
  managedGroupChannels = _.uniqBy(managedGroupChannels, mgc => `${mgc.channel.id}-${mgc.channelType}`)

  payload.managedGroupChannelsAttributes = managedGroupChannels.map(buildManagedGroupChannelPayload)

  if (previousManagedGroup) {
    const deletedManagedGroupChannels = _.differenceBy(
      previousManagedGroup.managedGroupChannels, managedGroupChannels, 'id')

    const deletedManagedGroupChannelsAttributes = _.map(
      deletedManagedGroupChannels, channel => ({ id: channel.id, _destroy: true }))

    payload.managedGroupChannelsAttributes = [
      ...payload.managedGroupChannelsAttributes,
      ...deletedManagedGroupChannelsAttributes,
    ]
  }

  return payload
}

export const defaultWorkingCopy = {
  name: '',
  managedGroupChannels: [],
  targetingRules: TARGET_ENTIRE_COMPANY,
}

export const initialState = {
  managedGroupIds: [],
  meta: {
    ...defaultMeta,
    isSyncing: false,
    queryParams: defaultPaginationParams,
  },
}

const managedGroupSlice = createSlice({
  name: 'managedGroups',
  initialState,
  reducers: {
    ...defaultActions,

    setManagedGroupIds(state, action) {
      state.managedGroupIds = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = { ...state.meta.queryParams, ...action.payload }
    },

    deleteManagedGroupId(state, action) {
      state.managedGroupIds = state.managedGroupIds.filter(id => id !== action.payload)
    },

    setIsSyncing(state, action) {
      state.meta.isSyncing = action.payload
    },
  },
})

_.assign(managedGroupSlice, {
  asyncActions: {
    admin: {
      fetchAll: (queryParams = {}) => async (dispatch) => {
        dispatch(managedGroupSlice.actions.isLoading(true))

        try {
          const response = await API.admin.managedGroups.fetchAll(queryParams)

          const newQueryParams = queryParamsFromHeaders(response)
          const managedGroupIds = response.data.data.map(managedGroup => managedGroup.id)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(managedGroupSlice.actions.setManagedGroupIds(managedGroupIds))
          dispatch(managedGroupSlice.actions.setQueryParams(newQueryParams))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(managedGroupSlice.actions.isLoading(false))
        }
      },

      fetchManagedGroup: id => async (dispatch) => {
        dispatch(managedGroupSlice.actions.isLoading(true))
        dispatch(managedGroupSlice.actions.isNotFound(false))

        try {
          const response = await API.admin.managedGroups.fetch(id)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(managedGroupSlice.actions.isNotFound(true))
        } finally {
          dispatch(managedGroupSlice.actions.isLoading(false))
        }
      },

      createManagedGroup: (managedGroup, onSuccess = () => { }) => async (dispatch) => {
        dispatch(managedGroupSlice.actions.isSaving(true))

        try {
          const response = await API.admin.managedGroups.create(buildManagedGroupPayload(managedGroup))
          dispatch(entitySlice.actions.add({ data: response.data }))

          onSuccess(response.data)
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(managedGroupSlice.actions.isSaving(false))
        }
      },

      updateManagedGroup: (managedGroup, previousManagedGroup, onSuccess = () => { }) => async (dispatch) => {
        dispatch(managedGroupSlice.actions.isSaving(true))

        try {
          const response = await API.admin.managedGroups.update(
            buildManagedGroupPayload(managedGroup, previousManagedGroup)
          )
          dispatch(entitySlice.actions.update({ data: response.data }))
          onSuccess()
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(managedGroupSlice.actions.isSaving(false))
        }
      },

      deleteManagedGroup: (managedGroup, onSuccess = () => { }) => async (dispatch) => {
        dispatch(managedGroupSlice.actions.isSaving(true))

        try {
          await API.admin.managedGroups.destroy(managedGroup)
          dispatch(managedGroupSlice.actions.deleteManagedGroupId(managedGroup.id))
          dispatch(entitySlice.actions.remove({ id: managedGroup.id, type: 'managedGroup' }))

          onSuccess()
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(managedGroupSlice.actions.isSaving(false))
        }
      },

      syncManagedGroups: (onSuccess = () => { }) => async (dispatch) => {
        dispatch(managedGroupSlice.actions.setIsSyncing(true))

        try {
          await API.admin.managedGroups.sync()
          onSuccess()
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(managedGroupSlice.actions.setIsSyncing(false))
        }
      },
    },
  },
})

_.assign(managedGroupSlice, {
  selectors: {
    getManagedGroups: () => state => state.managedGroups.managedGroupIds.map(id => build(state.entities, 'managedGroup', id)).filter(Boolean) || [],

    getManagedGroup: id => state => build(state.entities, 'managedGroup', id),

    getMetaData: () => state => state.managedGroups.meta,
  },
})

export default managedGroupSlice
