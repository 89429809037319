import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { articlePath } from 'utils/routeHelpers'
import adminArticleSlice from 'redux/slices/admin/articles'
import { LoadingContainer } from 'components/common/loadingContainer'
import { Button, ButtonSecondary } from 'components/common/buttons'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CdnSvg from 'components/common/cdnSvg'
import Reach from 'pages/admin/articles/stats/Reach'
import EmailSummary from 'pages/admin/articles/stats/EmailSummary'
import EmailClicks from 'pages/admin/articles/stats/EmailClicks'
import EngagementByType from 'pages/admin/articles/stats/EngagementByType'
import FeedImpressions from 'pages/admin/articles/stats/FeedImpressions'
import EmailTimeline from 'pages/admin/articles/stats/EmailTimeline'
import PageViews from 'pages/admin/articles/stats/PageViews'
import AcknowledgementsCard from 'pages/admin/articles/stats/AcknowledgementsCard'
import VideoAnalytics from 'pages/admin/articles/stats/videoAnalytics'
import SocialShare from 'pages/admin/articles/stats/SocialShare'

const I18N = i18nPath('views.admin.article_stats')

const ArticleStatsPage = ({ isGroupPost = false }) => {
  const dispatch = useDispatch()
  const { isLoading, isNotFound } = useSelector(adminArticleSlice.selectors.getMetaData())
  const article = useSelector(adminArticleSlice.selectors.getArticle())
  const { articleId } = useParams()

  useEffect(() => {
    dispatch(adminArticleSlice.asyncActions.fetchArticle(articleId, { computeStats: true }))
    return () => {
      dispatch(adminArticleSlice.actions.clearArticleId())
    }
  }, [])

  const articleStat = article?.articleStat || {}
  const articleRoute = articlePath(article)

  const {
    permissions: {
      articleCreator,
      articleManager,
    },
  } = useCurrentUser()

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='ArticleStatsPage'>
        <header className='AdminHeader'>
          <div className='width-container'>

            <div className='d-flex justify-content-between'>
              <h4 className='mb-0'>
                {I18N(isGroupPost ? 'post_stats_header' : 'article_stats_header', { articleTitle: article?.title })}
                <Link to={articleRoute} target='_blank'>
                  <Button variant='link'>
                    <CdnSvg src='/images/externalLinkIcon.svg' />
                  </Button>
                </Link>
              </h4>

              {(articleCreator || articleManager) && (
                <div className='AdminButtonContainer'>
                  <Link to={`${isGroupPost ? '/posts/' : '/admin/articles/'}${article?.id}`}>
                    <ButtonSecondary>
                      {isGroupPost ? I18N('post_editor') : I18N('article_editor')}
                    </ButtonSecondary>
                  </Link>
                </div>
              )}
            </div>

            <p className='text-secondary'>
              {I18N('last_updated', { lastUpdated: i18nMoment(articleStat.updatedAt).format('LLL') })}
            </p>
          </div>
        </header>

        <main className='AdminContent'>
          <div className='width-container'>
            {!_.isEmpty(articleStat) && (
              <>
                <Reach articleStat={articleStat} />

                {article.videoIds?.map(videoId => (
                  <div key={videoId} className='mt-4'>
                    <VideoAnalytics
                      parentId={article.id}
                      parentType='article'
                      videoId={videoId}
                    />
                  </div>
                ))}

                {article?.requireAcknowledgement && (
                  <div className='mt-4'>
                    <AcknowledgementsCard article={article} />
                  </div>
                )}

                <div className='d-flex mt-4 ArticleStatRow'>
                  {article.hasSocialShare && <SocialShare articleStat={articleStat} />}

                  <EmailSummary articleStat={articleStat} articleId={articleId} isGroupPost={isGroupPost} />
                  <EmailClicks articleStat={articleStat} articleId={articleId} isGroupPost={isGroupPost} />
                </div>

                <div className='mt-4'>
                  <EmailTimeline articleStat={articleStat} />
                </div>

                <div className='d-flex align-items-stretch mt-4'>
                  <div className='mr-3 flex-grow-1 w-0'>
                    <PageViews articleStat={articleStat} />
                  </div>

                  <div className='ml-3 flex-grow-1 w-0'>
                    <FeedImpressions articleStat={articleStat} />
                  </div>
                </div>

                <div className='mt-4'>
                  <EngagementByType articleStat={articleStat} />
                </div>
              </>
            )}
          </div>
        </main>
      </div>
    </LoadingContainer>
  )
}

export default ArticleStatsPage
