import React from 'react'

import AdminBanner from 'components/common/adminBanner'
import CdnSvg from 'components/common/cdnSvg'

const InactiveCorrespondentBanner = ({ text }) => (
  <AdminBanner color='orange' className='InactiveCorrespondentBanner'>
    <CdnSvg src='/images/alertOutlineIcon.svg' className='AlertOutlineIcon mr-2' />
    <span>{text}</span>
  </AdminBanner>
)

export default InactiveCorrespondentBanner
