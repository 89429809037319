import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ReactSelect from 'components/common/react_select'

import classNames from 'classnames'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'

import journeySlice from 'redux/slices/journeys'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'

import Modal from 'components/common/modal'
import { Button } from 'components/common/buttons'
import JourneyProgressWithStatus from 'components/admin/journeys/journeys/journeyProgressWithStatus'


const I18N = i18nPath('views.admin.journeys.new_hire_detail.manage_journeys_modal')


const ManageEmployeeJourneysModal = ({
  className = '',
  user = null,
  onRemoveJourney = () => null,
  onUserJourneyChanges = () => null,
  visible,
  toggle,
}) => {
  const dispatch = useDispatch()

  const preferredFullName = user?.preferredFullName
  const firstName = user?.preferredName
  const [selectedJourneyBlueprintId, setSelectedJourneyBlueprintId] = useState(null)
  const [hasUserJourneysChanged, setHasUserJourneysChanged] = useState(false)
  const journeyBlueprints = useSelector(journeyBlueprintSlice.selectors.getJourneyBlueprints()) || []

  const journeys = useSelector(journeySlice.selectors.getJourneys())

  // Get only the possible journey blueprints to add as the select options
  const currentJourneyBlueprintIds = journeys.filter(j => ['active', 'draft', 'completed'].includes(j.state)).map(j => j.journeyBlueprint?.id)
  const possibleBlueprintsToAdd = journeyBlueprints.filter(jb => !currentJourneyBlueprintIds.includes(jb.id))
  const blueprintOptions = possibleBlueprintsToAdd.map(jb => ({ value: jb?.id, label: jb.name }))

  const { isSaving } = useSelector(journeySlice.selectors.getMetaData())

  useEffect(() => {
    const params = {
      state: 'active',
    }

    dispatch(journeyBlueprintSlice.asyncActions.admin.fetchAll(params))
  }, [])

  const resetSelectedJourneyBlueprint = () => {
    setSelectedJourneyBlueprintId(null)
  }

  const onAddJourneySuccess = () => {
    setHasUserJourneysChanged(true)
    resetSelectedJourneyBlueprint()
  }

  const closeModal = () => {
    resetSelectedJourneyBlueprint()

    // Reload the users in the NoJourney tab if the user was added to a journey
    if (hasUserJourneysChanged) {
      onUserJourneyChanges()
    }

    toggle()
  }

  const onAddClick = () => {
    const journeyBlueprintId = selectedJourneyBlueprintId
    const journeyBlueprint = journeyBlueprints.find(jb => jb.id === journeyBlueprintId)

    const params = {
      userId: user?.id,
      journeyBlueprintId,
      state: journeyBlueprint?.state,
    }

    dispatch(journeySlice.asyncActions.admin.create(params, onAddJourneySuccess))
  }

  return (
    <Modal size='lg' modalContentClassName='pl-4 pr-4' className={classNames('ManageEmployeeJourneysModal', className)} visible={visible} toggle={closeModal}>
      <h3 className='mb-1'>{I18N('title', { firstName })}</h3>
      {
        journeys.length > 0 ? (
          <>
            <p className='text-secondary mb-3 font-weight-600'>{I18N('description', { preferredFullName })}</p>
            <div className='journeys-container'>
              {
                journeys.map(journey => (
                  <div key={`journey-${journey.id}`} className='d-flex justify-content-between p-3 journey-container w-100 align-items-center'>
                    <JourneyProgressWithStatus className='flex-column w-80' journey={journey} />
                    {
                        journey?.state !== 'removed' && (
                        <Button disabled={isSaving} onClick={() => onRemoveJourney(journey)} variant='secondary-danger'>{I18NCommon('remove')}</Button>
                      )
                    }
                  </div>
                ))
              }
            </div>
          </>
        ) : (
          <p className='text-secondary mb-4 font-weight-600'>{I18N('no_journeys_for', { firstName })}</p>
        )
      }
      <p className='mt-3 mb-3 font-weight-500'>{I18N('assign_new_journey', { firstName })}</p>
      <div className='d-flex justify-content-between assign-journey-container'>
        <ReactSelect
          className='mb-3 mr-3 w-100'
          disabled={isSaving}
          value={blueprintOptions.filter(option => selectedJourneyBlueprintId === option.value)}
          options={blueprintOptions}
          onChange={selectedOption => setSelectedJourneyBlueprintId(selectedOption.value)}
        />
        <Button disabled={isSaving || !selectedJourneyBlueprintId} onClick={onAddClick} variant='secondary'>{I18NCommon('add')}</Button>
      </div>
    </Modal>
  )
}

export default ManageEmployeeJourneysModal
