import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ReactSelect from 'components/common/react_select'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { i18nPath } from 'utils/i18nHelpers'
import { isEmpty } from 'lodash'
import articleTypeSlice from 'redux/slices/articleTypes'
import tagSlice from 'redux/slices/tags'
import useUserFilter from 'components/search/filters/refined_filters/hooks/useUserFilter'

const I18N = i18nPath('views.search.search_results.filters')

const ArticleFilter = ({ updateHistory }) => {
  const dispatch = useDispatch()
  const { filters: rawFilters = '{}' } = useQueryParams()
  const filters = JSON.parse(rawFilters)

  const { article_type_id: articleTypeIds = [], tag_ids: articleTags = [] } = filters
  const { isLoading: isLoadingTypes } = useSelector(articleTypeSlice.selectors.getMetaData())
  const articleTypes = useSelector(articleTypeSlice.selectors.getArticleTypes())
  const { isLoading: isLoadingTags } = useSelector(tagSlice.selectors.getMetaData())
  const tags = useSelector(tagSlice.selectors.getTags())
  const { user: author, setUser: setAuthor } = useUserFilter()

  const handleSearch = (newFilters, type) => {
    const updatedFilters = { ...filters, ...newFilters }

    if (!newFilters) {
      delete updatedFilters[type]
    }

    updateHistory(updatedFilters)
  }

  const handleAuthorChange = (option) => {
    setAuthor(option)

    const filters = option
      ? {
        author_ids: option.id,
      }
      : null
    handleSearch(filters, 'author_ids')
  }

  const handleTypeChange = (options) => {
    const filters = !isEmpty(options)
      ? {
        article_type_id: options.map(opt => opt.id),
      }
      : null
    handleSearch(filters, 'article_type_id')
  }

  const handleTagChange = (options) => {
    const filters = !isEmpty(options)
      ? {
        tag_ids: options.map(opt => opt.id),
      }
      : null
    handleSearch(filters, 'tag_ids')
  }

  useEffect(() => {
    dispatch(tagSlice.asyncActions.fetchTags())
  }, [])

  useEffect(() => {
    dispatch(articleTypeSlice.asyncActions.fetchArticleTypes())
  }, [])

  return (
    <div className='ArticleFilter MultipleRefinedFilters px-3'>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('article_author_filter')}
        </div>
        <EmployeeSearch
          onChange={handleAuthorChange}
          selectedEmployee={author}
        />
      </div>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('article_type_filter')}
        </div>
        <ReactSelect
          isClearable
          isLoading={isLoadingTypes}
          isMulti
          value={articleTypes.filter(({ id }) => articleTypeIds.includes(id))}
          placeholder={(
            <div className='text-secondary'>
              {I18n.t('views.common.react_select.select_or_type')}
            </div>
          )}
          onChange={handleTypeChange}
          options={articleTypes}
          getOptionValue={type => type.id}
          getOptionLabel={type => type.name}
        />
      </div>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('article_tag_filter')}
        </div>
        <ReactSelect
          isClearable
          isLoading={isLoadingTags}
          isMulti
          value={tags.filter(({ id }) => articleTags.includes(id))}
          placeholder={(
            <div className='text-secondary'>
              {I18n.t('views.common.react_select.select_or_type')}
            </div>
          )}
          onChange={handleTagChange}
          options={tags}
          getOptionValue={tag => tag.id}
          getOptionLabel={tag => tag.name}
        />
      </div>
    </div>
  )
}

export default ArticleFilter
