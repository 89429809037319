import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.profile.where_to_find_me.info_modal')
const locationPinIconPath = '/images/locationPinIcon.svg'
const clockIconPath = '/images/clockIcon.svg'
const officeBuildingIconPath = '/images/officeBuildingIcon.svg'

const ModalHeader = () => (
  <div className='mb-4'>
    <h4 className='d-flex align-items-center'>{I18N('title')}</h4>
    <div className='text-secondary'>{I18N('explanation')}</div>
  </div>
)

const InfoModal = ({
  isOpen,
  toggle,
}) => (
  <Modal className='WhereToFindMeInfoModal' visible={isOpen} toggle={toggle}>
    <ModalHeader />
    <div className='field-grid'>
      <div className='item'>
        <header>
          <CdnSvg className='LocationPinIcon mr-2' src={locationPinIconPath} />
          <span className='font-weight-500'>{I18N('location.title')}</span>
        </header>
        <ul>
          <li>{I18N('location.bullet_1')}</li>
          <li>{I18N('location.bullet_2')}</li>
          <li>{I18N('location.bullet_3')}</li>
        </ul>
      </div>
      <div className='item'>
        <header>
          <CdnSvg className='ClockIcon mr-2' src={clockIconPath} />
          <span className='font-weight-500'>{I18N('hours.title')}</span>
        </header>
        <ul>
          <li>{I18N('hours.bullet_1')}</li>
        </ul>
      </div>
      <div className='item'>
        <header>
          <CdnSvg className='OfficeBuildingIcon mr-2' src={officeBuildingIconPath} />
          <span className='font-weight-500'>{I18N('schedule.title')}</span>
        </header>
        <ul>
          <li>{I18N('schedule.bullet_1')}</li>
        </ul>
      </div>
    </div>
  </Modal>
)

export default InfoModal
