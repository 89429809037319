import { useEffect, useState } from 'react'

const useForceRerenderAtInterval = (seconds = 10) => {
  const [, setDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date())
    }, seconds * 1000)
    return () => clearInterval(interval)
  }, [])
}

export default useForceRerenderAtInterval
