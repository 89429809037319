import WidgetMenu from 'components/admin/layout/widgetMenu'
import React from 'react'
import widgetsMap from 'utils/layout/widgetsMap'
import Card from 'components/common/card'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.home_editor.empty_widgets')

const DefaultEmptyState = ({ type }) => {
  const name = _.snakeCase(type.split('::').pop())

  return (
    <Card className='EmptyState h-100 overflow-hidden'>
      <h5>{I18N(`${name}.title`)}</h5>
      <hr className='w-100' />
      <p>{I18N(`${name}.description`)}</p>
      <p>{I18N('widget_will_be_hidden')}</p>
    </Card>
  )
}

const EditableWidget = ({
  widget,
  onDelete,
  onSettingsClick,
}) => {
  const { Component, SettingsSidebarComponent } = widgetsMap[widget.type]

  const hasSettings = !!SettingsSidebarComponent

  return (
    <div className='EditableWidget'>
      <WidgetMenu
        onDelete={onDelete}
        onSettingsClick={hasSettings ? () => onSettingsClick() : undefined}
      />
      <div className='ComponentContainer'>
        <Component
          EmptyState={<DefaultEmptyState type={widget.type} />}
          widget={widget}
          resizeWidget={_.noop}
          isEditing
        />
      </div>
    </div>
  )
}

export default EditableWidget
