import React, { useEffect, useState } from 'react'
import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.auth_tokens')

const CreatedAuthTokenModal = ({ createdAuthToken, onClose = () => {} }) => {
  const [visible, setVisible] = useState(!!createdAuthToken)

  const handleClose = () => {
    setVisible(false)
    onClose()
  }

  useEffect(() => {
    setVisible(!!createdAuthToken)
  }, [createdAuthToken])

  if (!createdAuthToken) {
    return null
  }

  return (
    <Modal size='md' visible={visible} closeButton={false}>
      <div className='p-2 text-center'>
        <h3 className='text-left'>{I18N('new_token_generated')}</h3>
        <div className='text-left mt-5'>
          <p>{I18N('new_token_generated_message')}</p>

          <div className='text-center alert alert-dark' style={{ fontSize: '20px' }}>
            {createdAuthToken.token}
          </div>
        </div>

        <div className='d-flex justify-content-end mt-4'>
          <Button
            className='mr-2'
            variant='danger'
            onClick={handleClose}
          >
            {I18N('close')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CreatedAuthTokenModal
