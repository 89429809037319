import React from 'react'

import CheckBox from 'components/common/buttons/checkBox'
import { i18nPath } from 'utils/i18nHelpers'
import EditTitleSetting from 'components/admin/layout/layout_collection_sidebar/common/editTitleSetting'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.feed')

const FeedWidgetSettingsSidebar = ({ widget, updateWidget }) => {
  const updateSettings = (key, value) => {
    updateWidget({ ...widget, settings: { ...widget.settings, [key]: value } })
  }

  const updateFeedItemSettings = (key, value) => {
    updateSettings('feedItems', { ...widget.settings.feedItems, [key]: value })
  }

  const feedItemSettings = Object.entries(widget.settings.feedItems).sort(([a], [b]) => a.localeCompare(b))

  return (
    <div className='FeedWidgetSettingsSidebar'>
      <h5>{I18N('title')}</h5>

      <EditTitleSetting widget={widget} updateWidget={updateWidget} />

      <div className='w-100 mb-3'>
        <div>{I18N('feed_content')}</div>
        {feedItemSettings.map((([key, value]) => (
          <CheckBox
            id={`feedItem-${key}`}
            key={key}
            label={I18N(_.snakeCase(key))}
            checked={value as boolean}
            onChange={v => updateFeedItemSettings(key, v)}
          />
        )))}
      </div>
    </div>
  )
}

export default FeedWidgetSettingsSidebar
