import React from 'react'

import { I18NCommon } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'


const UpdateBuddyAssignmentTaskStatusButton = ({
  task,
  onComplete,
  selectedBuddy,
  isPreview = false,
}) => {
  const permittedActions = task?.permittedActions
  const itTaskCompleted = task?.completed
  const isBuddySelected = !!selectedBuddy
  const showSubmitButton = permittedActions?.complete && !itTaskCompleted

  const [completeTask, { isLoading: isSaving }] = useApi(API.journey.tasks.complete, {
    updateEntitySlice: true,
    onSuccess: onComplete,
  })

  const onCompleteClick = () => {
    if (isPreview) {
      return
    }

    completeTask(task.id, { onboardingBuddyId: selectedBuddy.id })
  }

  if (!showSubmitButton) return null

  return (
    <Button
      disabled={isSaving || !isBuddySelected}
      onClick={onCompleteClick}
      showLoadingSpinner={isSaving}
    >
      {I18NCommon('submit')}
    </Button>
  )
}

export default UpdateBuddyAssignmentTaskStatusButton
