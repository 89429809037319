import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.list_page.empty_state')


interface Props {
  filter: string
}

const CelebrationsListEmptyState = ({ filter = 'cards_to_sign' }: Props) => {
  let imagePath = '/images/illustrations/woman-empty-state.svg'
  if (filter === 'cards_to_sign') imagePath = '/images/illustrations/ballons-empty-state.svg'

  return (
    <div className='CelebrationsListEmptyState px-2 text-center'>
      <img src={imagePath} />
      <p className='mt-4'>{I18N(filter)}</p>
    </div>
  )
}

export default CelebrationsListEmptyState
