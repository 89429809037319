import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import { useDispatch, useSelector } from 'react-redux'
import appSlice from 'redux/slices/app_launcher/apps'
import { LoadingContainer } from 'components/common/loadingContainer'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DeleteModal from 'components/common/modals/deleteModal'
import arrayMove from 'array-move'
import SuggestedIcon from 'components/common/suggestedIcon'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import CdnSvg from 'components/common/cdnSvg'
import SortableTable from 'components/common/tables/sortableTable'

const I18N = i18nPath('views.admin.app_list')

const womanWithLensPath = '/images/illustrations/womanWithLens.svg'
const searchIconPath = '/images/searchIcon.svg'

const CreateAppButton = ({ className = '' }) => {
  const history = useHistory()

  return (
    <Button
      className={className}
      onClick={() => history.push('/admin/apps/new')}
    >
      {I18N('add_app')}
    </Button>
  )
}

const AppListPageEmptyState = () => (
  <div className='EmptyState d-flex flex-row align-items-start'>
    <CdnSvg src={womanWithLensPath} />
    <div>
      <h4>{I18N('empty.title')}</h4>
      <p className='text-secondary'>{I18N('empty.description')}</p>
      <CreateAppButton className='mt-4' />
    </div>
  </div>
)

const AppListPage = () => {
  const dispatch = useDispatch()
  const [searchQuery, setSearchQuery] = useState('')

  const apps = useSelector(appSlice.selectors.getApps())

  const {
    isLoading, isNotFound, isDeleting, isSaving,
  } = useSelector(appSlice.selectors.getMetaData())

  const noAppsCreated = _.isEmpty(apps) && !isLoading && searchQuery === ''
  const noAppsFound = _.isEmpty(apps) && !isLoading && searchQuery !== ''

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedAppId, setSelectedAppId] = useState<string | null>(null)

  useEffect(() => {
    dispatch(appSlice.asyncActions.admin.fetchAll())

    return () => {
      dispatch(appSlice.actions.clearAppIds())
    }
  }, [])

  useEffect(() => {
    dispatch(appSlice.asyncActions.admin.fetchAll({ q: searchQuery }))
  }, [searchQuery])

  const onDelete = () => {
    dispatch(appSlice.asyncActions.admin.destroy(selectedAppId!, () => setShowDeleteModal(false)))
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const app = apps[oldIndex]
    const newAppIdsOrder = arrayMove(apps.map(app => app.id), oldIndex, newIndex)
    dispatch(appSlice.actions.setAppIds(newAppIdsOrder))
    dispatch(appSlice.asyncActions.admin.update({ ...app, orderPosition: newIndex }))
  }

  const columns = [
    {
      header: I18N('icon'),
      accessor: app => <SuggestedIcon name={app.name} url={app.url} iconUrl={app.iconUrl} height={24} width={24} />,
    },
    {
      header: I18N('name'),
      accessor: app => app.name,
    },
    {
      header: I18N('url'),
      accessor: app => <a href={app.url} target='_blank' rel='noopener noreferrer' className='truncate-text-at-1-lines'>{app.url}</a>,
    },
    {
      header: I18N('actions'),
      style: { width: '10%' },
      accessor: app => (
        <DropdownMenuContainer menuType='kebab' dropdownHorizontalOffsetPx={30}>
          <CardDropdownMenuItem
            link={`/admin/apps/${app.id}`}
            primaryText={I18NCommon('edit')}
            width='190px'
            className='p-3'
          />
          <CardDropdownMenuItem
            primaryText={I18NCommon('delete')}
            width='190px'
            className='p-3'
            onClick={() => {
              setSelectedAppId(app.id)
              setShowDeleteModal(true)
            }}
          />
        </DropdownMenuContainer>
      ),
    },
  ]

  return (
    <div className='AppListPage'>
      <header className='AdminHeader d-flex flex-row justify-content-between'>
        <h3>{I18N('app_launcher')}</h3>
        <CreateAppButton />
      </header>
      <main className='AdminContent'>
        <AsyncSearchInput
          placeholder={I18NCommon('search_by_name')}
          icon={<CdnSvg src={searchIconPath} />}
          onKeyUp={setSearchQuery}
          className='w-40 mb-3'
        />
        <LoadingContainer isLoading={isLoading} useCirclesLoadingIndicator isNotFound={isNotFound}>
          <>
            {noAppsCreated && <AppListPageEmptyState />}
            {noAppsFound && (<p className='text-secondary text-center mt-5'>{I18N('no_apps_found')}</p>)}
            {!_.isEmpty(apps) && (
              <SortableTable
                data={apps}
                columns={columns}
                onSortEnd={onSortEnd}
                isSaving={isSaving}
              />
            )}
          </>
        </LoadingContainer>
      </main>

      <DeleteModal
        deleteText={I18N('delete.app')}
        deleteSecondaryText={I18N('delete.description')}
        deleteConfirm={onDelete}
        showDeleteModal={showDeleteModal}
        closeDeleteModal={() => setShowDeleteModal(false)}
        isDeleting={isDeleting}
        deleteConfirmText={I18N('delete.app')}
      />
    </div>
  )
}

export default AppListPage
