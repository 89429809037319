import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Card from 'components/common/card'
import { i18nPath } from 'utils/i18nHelpers'
import acknowledgementSlice from 'redux/slices/acknowledgements'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import StackedBarChart from 'components/common/charts/stackedBarChart'
import Stat from 'pages/admin/articles/stats/Stat'
import AcknowledgementRemindersButtonAndModal from 'components/admin/acknowledgements/acknowledgementRemindersButtonAndModal'

const I18N = i18nPath('views.admin.article_stats.acknowledgements')

const AcknowledgementsCard = ({ article }) => {
  const dispatch = useDispatch()

  const {
    pendingCount,
    acknowledgedCount,
    overdueCount,
    isLoading,
  } = useSelector(acknowledgementSlice.selectors.getMetaData())

  useEffect(() => {
    // fetching only 1 because we are interested in the meta
    dispatch(acknowledgementSlice.asyncActions.admin.fetchAll(article.id, 'article', { perPage: 1 }))
  }, [])

  return (
    <Card className='ArticleStatsAcknowledgementCard'>
      <header className='d-flex align-items-center justify-content-between'>
        <h3 className='mb-0 ml-1'>{I18N('title')}</h3>
        <AcknowledgementRemindersButtonAndModal
          acknowledgeableId={article.id}
          type='article'
        />
      </header>
      <hr />
      {isLoading ? (
        <CirclesLoadingIndicator />
      ) : (
        <>
          <StackedBarChart
            className='mt-4'
            data={[
              {
                tooltipText: I18N('acknowledged_tooltip', { count: acknowledgedCount }),
                className: '',
                value: acknowledgedCount,
              },
              {
                tooltipText: I18N('pending_tooltip', { count: pendingCount }),
                className: 'pending-opacity',
                value: pendingCount,
              },
              {
                tooltipText: I18N('overdue_tooltip', { count: overdueCount }),
                className: 'overdue-opacity',
                value: overdueCount,
              },
            ].filter(Boolean)}
          />

          <div className='d-flex email-stats mt-4 mb-4'>
            <Stat
              className='mr-5'
              value={acknowledgedCount}
              label={I18N('acknowledged')}
              leftPart={<span className='legend-square' />}
            />
            <Stat
              className='mr-5'
              value={pendingCount}
              label={I18N('pending')}
              leftPart={<span className='legend-square pending-opacity' />}
            />
            <Stat
              className='mr-5'
              value={overdueCount}
              label={I18N('overdue')}
              leftPart={<span className='legend-square overdue-opacity' />}
            />
          </div>

          <Link to={`/admin/articles/${article.id}/acknowledgements`}>
            {I18N('view_details')}
          </Link>
        </>
      )}
    </Card>
  )
}

export default AcknowledgementsCard
