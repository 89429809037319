import { useRef } from 'react'

/**
 * Looks like usePrevious.
 * The big difference between this component and usePrevious is that this component will
 * return the previous different value, where usePrevious will return the previous value in
 * the previous render. With this component, the value returned will always be different than
 * the current value.
 * See https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
function usePreviousDiff(value) {
  const ref = useRef([])

  if (ref.current.size === 0 || ref.current[ref.current.length - 1] !== value) {
    ref.current.push(value)

    if (ref.current.length === 3) {
      ref.current.shift()
    }
  }

  if (ref.current.length === 2) {
    return ref.current[0]
  }

  return null
}

export default usePreviousDiff
