import React from 'react'
import { Link } from 'react-router-dom'
import CdnSvg from 'components/common/cdnSvg'
import { I18NCommon } from 'utils/i18nHelpers'

const ViewAllLink = ({ to }) => (
  <Link className='ViewAllLink' to={to}>
    {I18NCommon('view_all')}<CdnSvg src='/images/forwardArrowIcon.svg' />
  </Link>
)

export default ViewAllLink
