import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'

export enum WorkspaceTypeEnum {
  SlackWorkspace = 'Chat::SlackWorkspace',
  MsTeamWorkspace = 'Chat::MsTeamWorkspace'
}
export interface WorkspaceType {
  id: string
  createdAt: string
  updatedAt: string
  type: WorkspaceTypeEnum
  domain: string
  status: string
}

export interface WorkspaceSliceState {
  workspaceIds: string[],
  meta: DefaultMetaType & {
    loaded: boolean
  }
}
