import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

import { ButtonLink } from 'components/common/buttons'
import classNames from 'classnames'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { UserType } from 'types/user'
import CdnSvg from 'components/common/cdnSvg'

interface UserHeaderProps {
  user: UserType,
  className?: string,
  firstSectionChildren?: React.ReactNode,
  secondSectionChildren?: React.ReactNode,
}

const UserHeader = ({
  user,
  className = '',
  firstSectionChildren = null,
  secondSectionChildren = null,
} : UserHeaderProps) => {
  const {
    id,
    username,
    primaryPhotoUrl,
    preferredFullName,
    parent: manager,
  } = user

  return (
    <div className={classNames('d-flex p-4 w-100', className)}>
      <div className='mr-3'>
        {primaryPhotoUrl && (
          <img className='profile-image bordered-thumbnail' src={primaryPhotoUrl} />
        )}
      </div>
      <div className='d-flex flex-column w-100'>
        <div className='d-flex justify-content-between'>
          <h2>{preferredFullName}</h2>
          <div className='d-flex buttons-container'>
            <ButtonLink className='mr-2 mb-1' variant='secondary' to={`/profile/${username}`}>
              <CdnSvg src='/images/personIcon.svg' className='personIcon ml-1' />
              <span>{I18NCommon('view_profile')}</span>
            </ButtonLink>
            <ButtonLink className='mr-2 mb-1' variant='secondary' to={`/admin/users/${id}`}>
              <CdnSvg src='/images/pencilIcon.svg' className='pencilIcon ml-1' />
              <span>{I18NCommon('edit_user')}</span>
            </ButtonLink>
          </div>
        </div>
        <div className='d-flex justify-content-start'>
          <div className='first-section mr-4'>
            <h6 className='d-inline mr-1'>{user.title}</h6>
            {user?.city && (
              <>
                <span className='text-secondary'>{I18NCommon('in')}</span>
                <h6 className='d-inline ml-1'>{user.city}</h6>
              </>
            )}
            {manager && (
              <div className='d-flex align-items-center mt-3'>
                <strong className='font-weight-600 mr-1'>{I18NCommon('manager')}:</strong>
                <EmployeeThumbnailPhoto employee={manager} size='24px' className='mr-2' />
                <a href={`/profile/${manager?.username}`} className='font-weight-500 text-primary-link'>{manager?.preferredFullName}</a>
              </div>
            )}
            {firstSectionChildren}
          </div>
          <div className='second-section'>
            {secondSectionChildren}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserHeader
