import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import groupMembershipSlice, { defaultQueryParams } from 'redux/slices/groupMemberships'

import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import MemberCard from 'components/groups/memberCard'
import ManageMembers from 'components/groups/manageMembers'
import Card from 'components/common/card'
import { ButtonSecondary } from 'components/common/buttons'
import ReactSelect from 'components/common/react_select'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.groups.group_page')
const coworkersImagePath = '/images/coworkersImage.svg'
const plusIconPath = '/images/plusIcon.svg'
const personAddIconPath = '/images/personAddIcon.svg'

const EmptyFeedCard = ({ canEditMembers, onClickAddMembers }) => (
  <Card className='EmptyFeedCard'>
    <div className='d-flex justify-content-between align-items-center gap-3 mx-4 my-2 '>
      <CdnSvg src={coworkersImagePath} />

      <div className={classNames('text-secondary', { 'text-center w-100': !canEditMembers })}>
        {canEditMembers ? I18N('members_empty_state_cta') : I18N('members_empty_state')}
      </div>

      {canEditMembers && (
        <ButtonSecondary variant='secondary' onClick={onClickAddMembers}>
          <CdnSvg src={plusIconPath} className='PlusIcon mr-2' />
          {I18N('add_members')}
        </ButtonSecondary>
      )}
    </div>
  </Card>
)

const Members = ({
  group,
}) => {
  const dispatch = useDispatch()
  const labels = group.groupType?.groupTypeLabels || []
  const filterOptions = [{ id: null, name: I18N('all') }, ...labels]
  const [currentFilterGroupTypeLabel, setCurrentFilterGroupTypeLabel] = useState(filterOptions[0])
  const { queryParams, isLoading } = useSelector(groupMembershipSlice.selectors.getMetaData())
  const showFilterByLabel = group.groupType?.useLabelsToCategorizeMembers || false
  const showSeeMoreLink = queryParams.totalPages > queryParams.page
  const canEditMembers = group.permittedActions.editMembers
  const [showManageMembers, setShowManageMembers] = useState(false)

  const activeMemberships = useSelector(groupMembershipSlice.selectors.getMemberships())

  if (group.activeMembersCount === 0) {
    return (
      <section className='GroupMembers mb-5'>
        <header className='title d-flex align-items-baseline'>
          <h5 className='text-secondary mb-0'>{I18N('team_members_label')}</h5>
          {canEditMembers && (
            <div className='ManageLink ml-3 link-color font-weight-600 d-flex' onClick={() => setShowManageMembers(true)}>
              <CdnSvg src={personAddIconPath} className='mr-1 PersonAddIcon' />
              {I18N('add_members')}
            </div>
          )}
        </header>
        {showManageMembers && (
          <ManageMembers group={group} closeManageMembers={() => setShowManageMembers(false)} />
        )}
        {isLoading ? (
          <CirclesLoadingIndicator className='SeeMoreMembersLoading' />
        ) : (
          <EmptyFeedCard canEditMembers={canEditMembers} onClickAddMembers={() => setShowManageMembers(true)} />
        )}
      </section>
    )
  }

  const onFilterChange = (labelObj) => {
    dispatch(groupMembershipSlice.actions.clear())
    setCurrentFilterGroupTypeLabel(labelObj)
    const newQueryParams = { ...defaultQueryParams, groupTypeLabelId: labelObj.id }
    dispatch(groupMembershipSlice.actions.setQueryParams(newQueryParams))
    dispatch(groupMembershipSlice.asyncActions.fetchAll(group.id, newQueryParams))
  }

  const onClickSeeMore = () => {
    const newQueryParams = { ...queryParams, page: queryParams.page + 1 }
    dispatch(groupMembershipSlice.asyncActions.fetchAll(group.id, newQueryParams))
  }

  return (
    <section className='GroupMembers mb-5'>
      <header className='title d-flex align-items-baseline'>
        <h5 className='text-secondary mb-0'>
          {I18N('group_members_capitalized', { count: group.activeMembersCount })}
        </h5>
        {canEditMembers && (
          <div className='ManageLink ml-3 link-color font-weight-600 d-flex' onClick={() => setShowManageMembers(true)}>
            <CdnSvg src={personAddIconPath} className='mr-1 PersonAddIcon' />
            {I18N('add_members')}
          </div>
        )}
      </header>
      {showManageMembers && (
        <ManageMembers group={group} closeManageMembers={() => setShowManageMembers(false)} />
      )}
      {showFilterByLabel && (
        <div className='FilterByLabel mb-2 d-flex align-items-center'>
          <span className='text-secondary mr-2'>{I18N('filter_by_team')}</span>
          <ReactSelect
            id='cy_group_type_label_select'
            value={currentFilterGroupTypeLabel}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            onChange={onFilterChange}
            options={filterOptions}
          />
        </div>
      )}
      {activeMemberships.map(membership => (
        <MemberCard
          key={membership.id}
          membership={membership}
          group={group}
        />
      ))}
      {showSeeMoreLink && !isLoading && (
        <div className='SeeMoreLink link' onClick={onClickSeeMore}>
          {I18N('see_more')}
        </div>
      )}
      {isLoading && (
        <CirclesLoadingIndicator className='SeeMoreMembersLoading' />
      )}
    </section>
  )
}

export default Members
