import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { useHistory } from 'react-router-dom'
import useQueryParams from 'components/common/hooks/useQueryParams'
import ReactSelect from 'components/common/react_select'

const I18N = i18nPath('views.admin.surveys.questions.detail')

const ALL = 'all'

const allOption = { label: I18NCommon('all'), value: ALL }

const QuestionOptionFilter = ({
  question,
}) => {
  const history = useHistory()

  const { selectedValue = ALL } = useQueryParams()

  const updateFilter = (newFilter) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('selectedValue', newFilter)
    history.push({
      search: queryParams.toString(),
    })
  }

  const options = question.filterOptions || []

  return (
    <div className='d-flex align-items-center question-options'>
      <span className='mr-2 text-secondary'>{I18N('answer')}</span>
      <ReactSelect
        className='question-option-filter'
        value={options.find(option => option.value.toString() === selectedValue) || allOption}
        options={[
          allOption,
          ...options,
        ]}
        onChange={newValue => updateFilter(newValue.value)}
      />
    </div>
  )
}

export default QuestionOptionFilter
