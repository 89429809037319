import React, { ReactNode, useState } from 'react'
import InfoTooltip from 'components/common/infoTooltip'
import { I18NCommon, i18nFormat, i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.integrations_page.calendars_section')

interface GoogleInstallationInterface {
  onSubmit: (googleCredentials) => void,
  isSaving: boolean,
  docLink: string,
}

const GoogleInstallationComponent = ({ onSubmit, isSaving, docLink }: GoogleInstallationInterface) => {
  const [credentialsJson, setCredentialsJson] = useState('')
  const [domain, setDomain] = useState('')
  const [usersDomain, setUsersDomain] = useState('')
  const [ownerEmail, setOwnerEmail] = useState('')

  const handleOnSubmit = () => {
    const payload = {
      credentialsJson, domain, usersDomain, ownerEmail,
    }
    onSubmit(payload)
  }

  const TooltipText: ReactNode = i18nFormat(
    I18N('google_integration_doc_tooltip'),
    <a href={docLink} rel='noopener noreferrer' target='_blank'>{I18N('here')}</a>
  )

  return (
    <form>
      <div className='form-group'>
        <label className='required-form-label'>
          {I18N('credentials_json')}
          <InfoTooltip text={TooltipText} />
        </label>
        <textarea
          className='flex-grow-1 mr-2 form-control'
          value={credentialsJson}
          onChange={({ target }) => setCredentialsJson(target.value)}
          placeholder={I18N('credentials_json_placeholder')}
        />
      </div>
      <div className='form-group'>
        <label className='required-form-label'>
          {I18N('owner_email')}
        </label>
        <input
          className='flex-grow-1 mr-2 form-control'
          value={ownerEmail}
          onChange={({ target }) => setOwnerEmail(target.value)}
          placeholder={I18N('owner_email_placeholder')}
        />
      </div>
      <div className='form-group'>
        <label className='required-form-label'>
          {I18N('domain')}
          <InfoTooltip text={I18N('domain_tooltip')} />
        </label>
        <input
          className='flex-grow-1 mr-2 form-control'
          value={domain}
          onChange={({ target }) => setDomain(target.value)}
          placeholder={I18N('domain_placeholder')}
        />
      </div>
      <div className='form-group'>
        <label>
          {I18N('users_domain')}
          <InfoTooltip text={I18N('users_domain_tooltip')} />
        </label>
        <input
          className='flex-grow-1 mr-2 form-control'
          value={usersDomain}
          onChange={({ target }) => setUsersDomain(target.value)}
          placeholder={I18N('users_domain_placeholder')}
        />
      </div>
      <div className='text-right'>
        <ButtonSmallNarrow
          className='my-1'
          onClick={handleOnSubmit}
          disabled={isSaving || !credentialsJson || !domain || !ownerEmail}
          showLoadingSpinner={isSaving}
          id='google-install-form-btn'
        >
          {I18NCommon('install')}
        </ButtonSmallNarrow>
      </div>
    </form>
  )
}

export default GoogleInstallationComponent
