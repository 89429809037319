import React, { useEffect, useRef, useState } from 'react'
import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap'
import classNames from 'classnames'

type RoundedAnimatedButtonProps = ButtonProps & {
  roundedSize: number
  icon: any
  text: string
  initialOpenStateTimeout?: number
}

const RoundedAnimatedButton = ({
  roundedSize,
  text,
  icon,
  initialOpenStateTimeout,
  ...props
}: RoundedAnimatedButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [state, setState] = useState(initialOpenStateTimeout ? 'open' : 'closed')
  const [textWidth, setTextWidth] = useState(0)

  useEffect(() => {
    const button = buttonRef.current!
    const textContainer = button.querySelector('.text-container') as HTMLSpanElement

    if (state === 'closed') {
      button.style.width = `${roundedSize}px`
      textContainer.style.width = '0'
    } else {
      button.style.width = `${textWidth + roundedSize}px`
      textContainer.style.width = `${textWidth}px`
    }
  }, [state, textWidth])

  useEffect(() => {
    const button = buttonRef.current!
    let closeStateTimeout

    if (initialOpenStateTimeout) {
      closeStateTimeout = setTimeout(() => {
        setState('closed')
      }, initialOpenStateTimeout)
    }

    const textContainerInner = button.querySelector('.text-container-inner') as HTMLSpanElement

    const updateTextWidth = () => {
      setTextWidth(textContainerInner.offsetWidth)
    }

    const resizeObserver = new ResizeObserver(updateTextWidth)
    const mutationObserver = new MutationObserver(updateTextWidth)

    resizeObserver.observe(textContainerInner)
    mutationObserver.observe(textContainerInner, { childList: true })

    const applyClosed = () => {
      setState('closed')
    }

    const applyOpen = () => {
      clearTimeout(closeStateTimeout)
      setState('open')
    }

    button.addEventListener('mouseleave', applyClosed)
    button.addEventListener('mouseenter', applyOpen)

    return () => {
      resizeObserver.disconnect()
      mutationObserver.disconnect()

      button.removeEventListener('mouseenter', applyOpen)
      button.removeEventListener('mouseleave', applyClosed)
    }
  }, [])

  return (
    <BootstrapButton
      {...props}
      className={classNames('RoundedAnimatedButton', props.className)}
      ref={buttonRef}
      style={{
        ...(props.style || {}),
        width: `${roundedSize}px`,
        height: `${roundedSize}px`,
      }}
    >
      <span className='text-container'>
        <span className='text-container-inner'>
          {text}
        </span>
      </span>

      {icon}
    </BootstrapButton>
  )
}

export default RoundedAnimatedButton
