import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import { TreeRenderProps } from 'react-complex-tree'
import { usePagesNav } from 'components/pages/pagesNav/context'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import PagesNavComplexTreeItemTitle from './pagesNavComplexTreeItemTitle'
import PagesNavComplexTreeItem from './pagesNavComplexTreeItem'

const chevronDownIconPath = '/images/chevronDownOutline.svg'

const RenderItem = ({
  arrow,
  children,
  context,
  depth,
  item,
  title,
}) => {
  const { showExpandingState } = usePagesNav()

  const showLoadingIndicator = () => showExpandingState('page', item.id)

  return (
    <>
      <PagesNavComplexTreeItem
        item={item}
        title={title}
        context={context}
        arrow={arrow}
        depth={depth}
      >
        {children}
      </PagesNavComplexTreeItem>

      {showLoadingIndicator() && <LoadingIndicator />}
    </>
  )
}

const PAGES_NAV_COMPLEX_TREE_RENDERERS : TreeRenderProps = {
  renderItemArrow: ({ item, context }) => {
    if (item.isFolder) {
      return (
        <span className={classNames('ItemArrow', { isExpanded: context.isExpanded })} {...context.arrowProps}>
          <CdnSvg src={chevronDownIconPath} className='ChevronDownIcon' />
        </span>
      )
    }
    return <span className='ItemArrowPlaceholder' />
  },

  renderTreeContainer: ({ children, containerProps, info }) => (
    <div className='TreeContainer'>
      <div
        className='root'
        {...containerProps}
      >
        {children}
      </div>
    </div>
  ),

  renderItemsContainer: ({
    children,
    containerProps,
    depth,
    info,
    parentId,
  }) => (
    <div className='ItemsContainer' {...containerProps}>
      {children}
    </div>
  ),

  renderItem: RenderItem,

  renderItemTitle: ({
    context,
    info,
    item,
    title,
  }) => (
    <PagesNavComplexTreeItemTitle item={item} title={title} context={context} />
  ),

  renderDepthOffset: 1,

  renderDragBetweenLine: ({ draggingPosition, lineProps }) => (
    <div className='DragBetweenLine' data-depth={draggingPosition.depth} {...lineProps} />
  ),
}

export default PAGES_NAV_COMPLEX_TREE_RENDERERS
