import React from 'react'

import LinkIcon from 'components/icons/linkIcon'

import pageVariables from 'utils/pageVariables'

const { faviconUrl } = pageVariables

const validatedURL = (url: string) => {
  try {
    return new URL(url)
  } catch (e) {
    return null
  }
}

interface SuggestedIconProps {
  name: string
  url: string
  className?: string
  iconUrl?: string
  ignoreCustom?: boolean
  height?: number
  width?: number
}

const SuggestedIcon = ({
  name,
  url,
  iconUrl,
  ignoreCustom = false,
  height = 16,
  width = 16,
  className = 'mr-3 d-inline-block',
}: SuggestedIconProps) => {
  // The ignoreCustom flag is only used on the editor page so that we can
  // show the Google-provided icon.

  const linkUrl = validatedURL(url)

  // Use our backup link icon if we fail to resolve the URL for reasons other than a TypeError
  if (!linkUrl) {
    return (
      <LinkIcon height={height} width={width} className={className} />
    )
  }

  const getIconSrc = () => {
    if (!ignoreCustom && iconUrl) {
      return iconUrl
    }

    if (linkUrl.origin === window.location.origin) {
      return faviconUrl
    }

    return `https://www.google.com/s2/favicons?domain=${linkUrl.origin}&sz=${height}`
  }

  return (
    <img
      alt={name}
      className={className}
      style={{ height, width }}
      src={getIconSrc()}
      loading='lazy'
    />
  )
}

export default SuggestedIcon
