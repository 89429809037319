import React from 'react'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { Link } from 'react-router-dom'
import ClearyInternalSearchResultsTemplate from 'components/search/search_page_results/clearyInternalSearchResultsTemplate'
import SearchResultTemplate from 'components/search/search_page_results/searchResultTemplate'

const I18N = i18nPath('views.search.search_results.attached_file_contents')

export const AttachedFileContentResult = ({ result }) => {
  const { attachedFileContent, highlights, displayPath } = result
  const {
    filename, embeddingContent, displayIcon, type,
  } = { ...attachedFileContent, ...highlights }

  const normalizedType = _.snakeCase(type.split('::').pop())

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={<CdnSvg src={displayIcon} />}
      title={filename}
      subtitle={<Subtitle attachedFileContent={attachedFileContent} />}
      pretitle={I18N(`pretitle.${normalizedType}`)}
      description={embeddingContent}
      result={result}
      descriptionExtraMargin
      openNewTab
    />
  )
}

const Subtitle = ({ attachedFileContent }) => {
  const { type, title, displayPath } = attachedFileContent.associatedRecord

  return (
    <div className='text-small'>
      <span className=' text-secondary'>{I18N('in', { type })}:&nbsp;</span>
      <Link
        className=' text-primary'
        to={displayPath}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {title}
      </Link>
      <span className='mx-1'>•</span>
      <span className='text-secondary'>{I18N('uploaded', { date: i18nMoment(attachedFileContent.uploadedAt).format('LL') })}</span>
    </div>
  )
}

const AttachedFileContentResults = ({ showLabel = true }) => (
  <ClearyInternalSearchResultsTemplate
    showLabel={showLabel}
    label='attached_file_contents'
    type='attachedFileContent'
    searchResultToTemplateMap={result => <AttachedFileContentResult result={result} />}
  />
)

export default AttachedFileContentResults
