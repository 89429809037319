import React from 'react'
import Card from 'components/common/card'

const BorderRadius = () => (
  <span>
    <Card>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.</Card>
  </span>
)

export default BorderRadius
