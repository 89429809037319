import React from 'react'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { ChatUserType } from 'types/chat/user'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const slackIconPath = '/images/slackLogoIcon.svg'
const msTeamIconPath = '/images/microsoftTeamsIcon.svg'
interface Props {
  chatUser: ChatUserType
  iconClass?: string
  className?: string
}

const ChatUsername = ({ chatUser, className, iconClass = 'w-16' }: Props) => {
  const { canSeeMultipleSlackWorkspaces, canSeeMultipleMsTeamWorkspaces } = useCurrentUser()
  let iconPath, canSeeMultipleWorkspace

  if (chatUser.type === 'Chat::SlackUser') {
    iconPath = slackIconPath
    canSeeMultipleWorkspace = canSeeMultipleSlackWorkspaces
  }

  if (chatUser.type === 'Chat::MsTeamUser') {
    iconPath = msTeamIconPath
    canSeeMultipleWorkspace = canSeeMultipleMsTeamWorkspaces
  }

  const child = (
    <>
      <CdnSvg src={iconPath} className={classNames('d-inline-block mr-1', iconClass)} />

      {chatUser.username}
      {canSeeMultipleWorkspace && (
        <span className='text-secondary font-weight-500 ml-1'>- {chatUser.workspaceDomain}</span>
      )}
    </>
  )

  if (!chatUser.externalUrl) {
    return <span className={className}>{child}</span>
  }

  return (
    <a
      href={chatUser.externalUrl}
      target='_blank'
      rel='noopener noreferrer'
      title={chatUser.username}
      className={className}
    >
      {child}
    </a>
  )
}

export default ChatUsername
