import FeedWidget from 'components/home/widgets/feedWidget'
import React from 'react'

// this page is only meant to be viewed from the mobile app
const FeedPage = () => (
  <div className='FeedPage'>
    <FeedWidget widget={{ settings: {} }} />
  </div>
)

export default FeedPage
