import React from 'react'
import {
  i18nFormat, i18nMoment, i18nPath
} from 'utils/i18nHelpers'
import { PageFAQType } from 'types/page/faq'
import CdnSvg from 'components/common/cdnSvg'
import UserLink from 'components/common/userLink'

const I18N = i18nPath('views.pages.faqs.faq_item')

type Props = {
  faq: PageFAQType
}

const FAQSources: React.FC<Props> = ({
  faq,
}) => {
  if (!faq.automaticallyGenerated || !faq.faqSources?.length) return null

  return (
    <>
      <hr />
      <details className='mt-3'>
        <summary>
          <span className='text-small'>
            {i18nFormat(I18N('generated_from_messages'), <CdnSvg className='SlackLogo' src='/images/slackLogoIcon.svg' />)}
          </span>
        </summary>
        {_.orderBy(faq.faqSources, ['createdAt'], ['desc']).map(source => (
          <div key={source.id} className='mt-2 d-flex align-items-center flex-wrap text-small text-secondary gap-2'>
            <span>{I18N('message_sent_by')}</span>
            <span>
              <UserLink user={source.user} customClassName='text-small text-secondary font-weight-400' />
            </span>
            <span>•</span>
            <span>{i18nMoment(source.createdAt).format('MMM D, YYYY h:mm A')}</span>
            <span>•</span>
            <a
              href={source.slackMessageUrl}
              className='font-weight-400'
              target='_blank'
              rel='noopener noreferrer'
            >
              {I18N('view_in_slack')}
            </a>
          </div>
        ))}
      </details>
    </>
  )
}

export default FAQSources

