import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nav } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import moment from 'moment'
import { i18nPath } from 'utils/i18nHelpers'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import qnaEventSlice from 'redux/slices/qnaEvents'
import classNames from 'classnames'
import isMobile from 'utils/isMobile'
import usePrevious from 'components/common/hooks/usePrevious'

import { LoadingContainer } from 'components/common/loadingContainer'
import InfiniteScroller from 'components/common/infiniteScroller'
import PillTabSelector from 'components/common/pillTabSelector'
import IcebreakerAddSection from 'components/qna/icebreakerAddSection'
import QuestionForm from 'components/qna/questionForm'
import QuestionModerationCard from 'components/qna/questionModerationCard'
import EventPageQuestion from 'components/qna/eventPageQuestion'
import EventPageSidebar from 'components/qna/eventPageSidebar'
import CalendarIcon from 'components/icons/calendarIcon'
import Card from 'components/common/card'
import { ButtonLarge } from 'components/common/buttons/buttonLarge'
import EventDescription from 'components/qna/event_fields/eventDescription'
import EventCoverImage from 'components/qna/event_fields/eventCoverImage'
import EditableTitle from 'components/common/editable_title'
import EventChatChannels from 'components/qna/event_fields/eventChatChannels'
import EventVideoConferenceLink from 'components/qna/event_fields/eventVideoConferenceLink'
import AccessLevel from 'components/qna/access_level'
import { trackAmplitudeEventView } from 'components/qna/common'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { trackEvent } from 'services/tracker'

const I18N = i18nPath('views.qna.events')

const EventPage = ({
  canManageEvent,
  event,
  isLoading,
  isPreview = false,
  onClickPublish,
}) => {
  const { eventId } = useParams()
  const dispatch = useDispatch()
  const currentCompany = useCurrentCompany()
  const hasCoverImage = event?.coverImageUrl || event?.templateName
  const { isQuestionFormVisible } = useSelector(qnaQuestionSlice.selectors.getQuestionFormData())
  const { questions, meta: qnaQuestionsMeta } = useSelector(
    qnaQuestionSlice.selectors.getQuestionsByEventSlugOrId(eventId))
  const { newlyAddedQuestionId } = useSelector(state => state.qnaQuestions)
  const { newlyPendingQuestionId } = useSelector(state => state.qnaQuestions)
  const [filter, setFilter] = useState('top')
  const [animateNewlyAddedQuestionBackground, setAnimateNewlyAddedQuestionBackground] = useState(true)
  const previousQuestion = usePrevious(newlyAddedQuestionId)
  const [isAddIcebreakerSectionDismissed, setIsAddIcebreakerSectionDismissed] = useState(
    localStorage.getItem(`addIcebreakerDismissed-event-${eventId}`)
  )
  const [currentTab, setCurrentTab] = useState('activity')
  const isActivityTabSelected = currentTab === 'activity'
  const isForReviewTabSelected = currentTab === 'for_review'
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const isFetchingQuestions = qnaQuestionsMeta.isLoading

  useEffect(() => {
    // set the first filter to the top one by default
    // otherwise it may reset to the last tab the user was on
    setFilter('top')
    setSelectedTabIndex(0)

    trackAmplitudeEventView(event)

    // this ensures that the event page is loaded from the top
    // otherwise there is an issue with the event page loading from
    // the same location as the event list page location you scrolled to
    window.scrollTo(0, 0)

    return () => {
      dispatch(qnaQuestionSlice.actions.setNewlyAddedQuestionId(null))
      dispatch(qnaQuestionSlice.actions.setNewlyPendingQuestionId(null))
      dispatch(qnaQuestionSlice.actions.setSelectedQuestionIdFromHighlightedEvent(false))
    }
  }, [eventId])

  useEffect(() => {
    if (event?.id) {
      trackEvent('qna_event:view', { qna_event_id: event.id })
    }
  }, [event?.id])

  // New question bg color animation class toggle
  useEffect(() => {
    // reset animation class after question has been submitted
    if (newlyAddedQuestionId && !isQuestionFormVisible) {
      setAnimateNewlyAddedQuestionBackground(false)
    }

    if (newlyAddedQuestionId !== previousQuestion && newlyAddedQuestionId) {
      setAnimateNewlyAddedQuestionBackground(true)
    }
  }, [newlyAddedQuestionId, isQuestionFormVisible])

  useEffect(() => {
    // we need this because the pending question will only show up in my questions
    // we set the filter and change the tab to the my questions
    if (newlyPendingQuestionId) {
      dispatch(qnaQuestionSlice.asyncActions.resetQuestions())
      dispatch(qnaQuestionSlice.actions.setNewlyAddedQuestionId(null))
      dispatch(qnaQuestionSlice.asyncActions.fetchAll(eventId, { page: 1, perPage: 10, filter: 'my_questions' }))

      setFilter('my_questions')
      setSelectedTabIndex(3)
    }
  }, [newlyPendingQuestionId])

  const handleFilterSelection = (filter) => {
    setFilter(filter)

    dispatch(qnaQuestionSlice.asyncActions.resetQuestions())
    dispatch(qnaQuestionSlice.actions.setNewlyAddedQuestionId(null))
    dispatch(qnaQuestionSlice.actions.setNewlyPendingQuestionId(null))
    dispatch(qnaQuestionSlice.asyncActions.fetchAll(eventId, { page: 1, perPage: 10, filter }))
  }

  const handleReviewFilter = (filter) => {
    setFilter(filter)
    dispatch(qnaQuestionSlice.asyncActions.resetQuestions())
    dispatch(qnaQuestionSlice.asyncActions.admin.fetchAll(eventId, { filter }))
  }

  const handleActivityClick = () => {
    setCurrentTab('activity')
    handleFilterSelection('top')
    setSelectedTabIndex(0)
  }

  const handleForReviewClick = () => {
    setCurrentTab('for_review')
    handleReviewFilter('pending')
  }

  const handleSaveTitle = (changedFields) => {
    dispatch(qnaEventSlice.asyncActions.admin.update({ ...event, ...changedFields }, 'title'))
  }

  const hasMoreQuestions = () => {
    // In theory we could probably just get away with only 'return questions.length < event.questionsCount'
    // However it feels a bit dirty not to use pagination though as this would rely on questionsCount (cached column on event)
    // always being 100% accurate

    if (qnaQuestionsMeta.queryParams.totalPages === null) {
      return questions.length < event.questionsCount
    } else {
      const { page, totalPages } = qnaQuestionsMeta.queryParams

      return page < totalPages
    }
  }

  const handleFetchQuestions = () => {
    const { queryParams } = qnaQuestionsMeta
    dispatch(qnaQuestionSlice.asyncActions.fetchAll(eventId, { page: queryParams.page + 1, perPage: 10, filter }))
  }

  const showQuestionFormHandler = () => {
    dispatch(qnaQuestionSlice.actions.isQuestionFormVisible(true))
  }

  // We store the state of whether the icebreaker question section has been dismissed or not locally for now.
  // This might be refactored later to persist to the backend but we'll look into it when we
  // rebuild the entire event creation / management flow. This is *likely* a temporary solution that will allow
  // admins to dismiss this section if they are presenting their screen.
  const dismissAddIcebreakerSection = (e) => {
    localStorage.setItem(`addIcebreakerDismissed-event-${event.id}`, true)
    setIsAddIcebreakerSectionDismissed(true)
  }


  const EventContent = () => {
    const {
      isActive, isArchived, isLockedAndAcceptingAnswers,
    } = event

    const canEditEvent = canManageEvent

    const icebreakerQuestions = questions.filter(q => q.type === 'icebreaker' && filter !== 'my_questions')
    const myPendingQuestions = questions.filter(q => q.isPending && filter === 'my_questions')
    const pendingQuestions = questions.filter(q => q.isPending)
    const rejectedQuestions = questions.filter(q => q.isRejected)
    // standard question are just normal questions
    // icebreaker questions are a special type of question added by the moderator
    const standardQuestions = questions.filter(q => q.type === 'standard' && q.isApproved)
    const sortedStandardQuestions = _.orderBy(standardQuestions, ['voteScore', 'createdAt'], ['desc', 'asc'])

    // if the user is on my_questions we need to add the myPendingQuestions
    let sortedQuestions = filter === 'my_questions' ? [...myPendingQuestions, ...sortedStandardQuestions] : [...icebreakerQuestions, ...sortedStandardQuestions]
    const moderationQuestions = filter === 'pending' ? pendingQuestions : rejectedQuestions

    // In recent tab display questions in order of creation, icebreaker does
    // not show on top of the question list.
    if (filter === 'recent') {
      sortedQuestions = _.sortBy(sortedQuestions, 'createdAt').reverse()
    }

    const showAddIcebreakerSection = filter === 'top'
                                     && canEditEvent
                                     && !isAddIcebreakerSectionDismissed
                                     && icebreakerQuestions.length === 0
                                     && !isArchived
                                     && !isLockedAndAcceptingAnswers

    // add a newly added question to the front of the list
    const newlyAddedQuestion = sortedQuestions.find(question => question.id === newlyAddedQuestionId)
    if (newlyAddedQuestion) {
      sortedQuestions = [newlyAddedQuestion, ...sortedQuestions.filter(q => q.id !== newlyAddedQuestionId)]
    }

    const showEmptyStateQuestionButtonSection = isActive
      && event.questionsCount === 0 && !isQuestionFormVisible && !canEditEvent

    const showTopRightQuestionButton = (isActive && event.questionsCount > 0) || (isActive && canEditEvent)

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>{event?.title}</title>
          </Helmet>
        </HelmetProvider>
        <div className='EventPage row'>
          <div className='col-lg-8'>
            <span className='d-flex d-lg-inline flex-column'>
              {/**
               * Using flex order, in mobile, event card and sidebar items
               * should stack and have the following order:
               *
               * order: 1
               * +------------------------+
               * |  notification widget   |
               * +------------------------+
               * order: 2
               * +------------------------+
               * |   event detail card    |
               * +------------------------+
               * order: 3
               * +------------------------+
               * |      agenda items      |
               * +------------------------+
               * order: 4
               * +------------------------+
               * |   event participant    |
               * +------------------------+
               */}
              <span className='order-2'>
                <EventCoverImage event={event} canEditEvent={canEditEvent} />
                <Card className={classNames('EventDetails', { hasCoverImage })}>
                  <div className='d-flex flex-column'>
                    <EditableTitle
                      record={event}
                      canEdit={canEditEvent}
                      onSave={handleSaveTitle}
                    />
                  </div>

                  <div className='EventPageFields text-secondary d-flex align-items-start flex-column flex-md-row'>
                    {event.eventTime && (
                      <div className='flex-shrink-0'>
                        <CalendarIcon className='mr-2 mb-1' />
                        <span style={{ lineHeight: '1rem' }}>{moment(event.eventTime).format('LL')}</span>
                      </div>
                    )}
                    <AccessLevel showLabel event={event} className='ml-0 ml-md-4 flex-shrink-0' fillColor='var(--kebab-menu-icon-color)' />
                    <EventVideoConferenceLink event={event} canEditEvent={canEditEvent} className='flex-shrink-0' />
                    {(currentCompany.slackEnabled || currentCompany.msTeamEnabled) && (
                      <div className='EventChatChannels flex-grow-1 ml-sm-0 ml-md-4'>
                        <EventChatChannels event={event} canEditEvent={canEditEvent} />
                      </div>
                    )}
                  </div>
                  <EventDescription event={event} canEditEvent={canEditEvent} />
                </Card>
              </span>
              {isMobile() && (
                <EventPageSidebar
                  event={event}
                  canManageEvent={canManageEvent}
                  onClickPublish={onClickPublish}
                />
              )}
            </span>

            <div className='row mt-4'>
              <div className='col-md-12 EventPageTabs'>
                <Nav variant='tabs'>
                  <Nav.Item>
                    <a
                      className={classNames('nav-link py-0 px-4 border-0 d-lg-block', { 'border-bottom-highlight': isActivityTabSelected })}
                      onClick={() => handleActivityClick()}
                    >
                      <h4 className={classNames('font-weight-500', { 'text-color-highlight': isActivityTabSelected, 'text-secondary': !isActivityTabSelected })}>{I18N('activity')}</h4>
                    </a>
                  </Nav.Item>
                  {canEditEvent && event.settings.requireQuestionApproval.enabled && (
                    <Nav.Item>
                      <a
                        className={classNames('nav-link py-0 px-4 border-0 d-lg-block', { 'border-bottom-highlight': isForReviewTabSelected })}
                        onClick={() => handleForReviewClick()}
                      >
                        <h4 className={classNames('font-weight-500', { 'text-color-highlight': isForReviewTabSelected, 'text-secondary': !isForReviewTabSelected })}>
                          {I18N('for_review')}
                          {event.pendingQuestionsCount > 0 && <span className='badge rounded-circle text-small badge-review ml-2'>{event.pendingQuestionsCount}</span>}
                        </h4>
                      </a>
                    </Nav.Item>
                  )}
                </Nav>
              </div>
            </div>

            {!isQuestionFormVisible && isActivityTabSelected && (
              <div className='EventPageFilters d-flex align-items-end justify-content-between my-2'>
                <PillTabSelector
                  defaultSelectedIndex={selectedTabIndex}
                  tabs={[
                    {
                      text: I18N('question_filters.top'),
                      onClick: () => {
                        handleFilterSelection('top')
                      },
                    },
                    {
                      className: 'd-none d-md-block',
                      text: I18N('question_filters.recent'),
                      onClick: () => {
                        handleFilterSelection('recent')
                      },
                    },
                    {
                      className: 'd-none d-md-block',
                      text: I18N('question_filters.unanswered'),
                      onClick: () => {
                        handleFilterSelection('unanswered')
                      },
                    },
                    {
                      text: I18N('question_filters.my_questions'),
                      onClick: () => {
                        handleFilterSelection('my_questions')
                      },
                    },
                  ]}
                />

                {showTopRightQuestionButton && (
                  <ButtonLarge onClick={showQuestionFormHandler} disabled={isQuestionFormVisible}>
                    {I18N('ask_a_question')}
                  </ButtonLarge>
                )}
              </div>
            )}

            {isForReviewTabSelected && (
              <div className='d-flex align-items-end justify-content-between my-3'>
                <PillTabSelector
                  tabs={[
                    {
                      text: I18N('question_filters.pending'),
                      onClick: () => {
                        handleReviewFilter('pending')
                      },
                    },
                    {
                      text: I18N('question_filters.rejected'),
                      onClick: () => {
                        handleReviewFilter('rejected')
                      },
                    },
                  ]}
                />
              </div>
            )}

            {showEmptyStateQuestionButtonSection && !isForReviewTabSelected && (
              <div className='text-center mt-5 pt-5'>
                <p className='text-secondary mb-4'>{I18N('submit_a_question')}</p>
                <ButtonLarge onClick={showQuestionFormHandler} disabled={isQuestionFormVisible}>
                  {I18N('ask_a_question')}
                </ButtonLarge>
              </div>
            )}

            {isQuestionFormVisible && <QuestionForm event={event} />}

            {isActivityTabSelected && (
              <div className='mt-3'>
                <InfiniteScroller
                  isFetching={isFetchingQuestions}
                  onFetch={handleFetchQuestions}
                  hasMoreContent={hasMoreQuestions}
                  usePlaceholderSpace
                >
                  {/* Prevents flashing IcebreakerAddSection when it shouldn't show  */}
                  {
                    showAddIcebreakerSection
                    && !isLoading
                    && !isFetchingQuestions
                    && <IcebreakerAddSection onDismiss={dismissAddIcebreakerSection} event={event} />
                  }

                  {sortedQuestions.map(question => (
                    <Card
                      key={question.id}
                      className={classNames('EventPage-questionListItem mb-3', {
                        AnimateNewlyAddedQuestionBackground: animateNewlyAddedQuestionBackground && question.id === (
                          newlyAddedQuestionId || newlyPendingQuestionId),
                      })}
                    >
                      <EventPageQuestion key={`question-${question.id}-list-item`} event={event} question={question} isPreview={isPreview} />
                    </Card>
                  ))}
                </InfiniteScroller>
              </div>
            )}

            {isForReviewTabSelected && !isLoading && !isFetchingQuestions && (
              <>
                {moderationQuestions.length > 0 ? (
                  <div className='mt-3'>
                    {moderationQuestions
                      && moderationQuestions.map(question => (
                        <Card className='EventPage-questionListItem mb-3'>
                          <QuestionModerationCard question={question} />
                        </Card>
                      ))}
                  </div>
                ) : (
                  <>{!isLoading && <div className='d-flex justify-content-center text-secondary'>{I18N('no_moderation_questions')}</div>}</>
                )}
              </>
            )}
          </div>
          {!isMobile() && (
            <EventPageSidebar
              event={event}
              canManageEvent={canManageEvent}
              onClickPublish={onClickPublish}
            />
          )}
        </div>
      </>
    )
  }

  return (
    <div className='container mt-4'>
      <LoadingContainer isLoading={isLoading}>{EventContent}</LoadingContainer>
    </div>
  )
}

export default EventPage
