export function secondsToLabel(second: number): string {
  const hours = Math.floor(second / 3600)
  const minutes = Math.floor((second % 3600) / 60)
  const seconds = second % 60
  const label: string[] = []

  if (hours > 0) {
    label.push(hours.toString().padStart(hours > 9 ? 2 : 1, '0'))
    label.push(minutes.toString().padStart(2, '0'))
  } else {
    label.push(minutes.toString().padStart(minutes > 9 ? 2 : 1, '0'))
  }

  label.push(seconds.toString().padStart(2, '0'))

  return label.join(':')
}

export function generateLabels(duration: number, scalingFactor: number): string[] {
  const labels: string[] = []

  for (let second = 0; second < duration; second += scalingFactor) {
    labels.push(secondsToLabel(second))
  }


  return labels
}
