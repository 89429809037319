import React, { useEffect, useMemo, useState } from 'react'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { CompletedIngestionBanner, ScanningChannelsBanner } from './mainContent/dashboardBanner'
import Activities from './mainContent/activities'
import Dashboard from './mainContent/dashboard'

const MainContent = ({
  knowledgeIngestions,
  fetchKnowledgeIngestions,
}) => {
  const [completedIngestionId, setCompletedIngestionId] = useState<string>()
  const { permissions: { superAdmin } } = useCurrentUser()

  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    timeRange,
    setTimeRange,
    setGroupBy,
    groupBy,
  } = useTabHeader()

  const { data: completedIngestionData } = useFetch(
    API.ai.assistant.dashboardData.fetch,
    [],
    { load: !!completedIngestionId }
  )

  const {
    data: dashboardData,
    isLoaded: isLoadedDashboardData,
  } = useFetch(
    () => API.ai.assistant.dashboardData.fetch({ period: { periodStart, periodEnd }, groupBy }),
    [periodStart, periodEnd, groupBy]
  )

  const inProgressIngestion = useMemo(
    () => knowledgeIngestions?.find(ingestion => ingestion.status === 'in_progress' || ingestion.status === 'pending'),
    []
  )

  const hasCompletedIngestion = useMemo(
    () => knowledgeIngestions?.some(ingestion => ingestion.status === 'completed'),
    []
  )

  const isFirstIngestionInProgress = inProgressIngestion && !hasCompletedIngestion

  return (
    <div className='d-flex flex-column gap-4'>
      {isFirstIngestionInProgress ? (
        <>
          {completedIngestionId ? (
            <CompletedIngestionBanner
              channels={inProgressIngestion?.slackChannelNames}
              faqsCount={completedIngestionData?.faqsCreated}
              ingestionId={completedIngestionId}
            />
          ) : (
            <ScanningChannelsBanner channels={inProgressIngestion?.slackChannelNames} />
          )}
        </>
      ) : (
        <>
          {superAdmin && (
            <Dashboard
              knowledgeIngestions={knowledgeIngestions}
              dashboardData={dashboardData}
              hasDashboardData={dashboardData?.hasDashboardData}
              isLoadedDashboardData={isLoadedDashboardData}
              periodStart={periodStart}
              setPeriodStart={setPeriodStart}
              periodEnd={periodEnd}
              setPeriodEnd={setPeriodEnd}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              setGroupBy={setGroupBy}
            />
          )}
        </>
      )}

      <Activities
        setCompletedIngestionId={setCompletedIngestionId}
        fetchKnowledgeIngestions={fetchKnowledgeIngestions}
      />
    </div>
  )
}

export default MainContent
