import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'

const I18N = i18nPath('views.qna.events.settings_modal.general_settings')

const GeneralSettings = ({ event, openArchiveEventModal, openDeleteEventModal }) => (
  <div className='GeneralSettings mb-5 pb-2'>
    <div className='w-100 w-lg-25 mb-5'>
      <div className='mb-2'>{I18N('created_by')}</div>
      <div className='bordered'>
        {event?.creator && (
          <EmployeeMiniCardLink employee={event.creator} padding={5} targetBlank />
        )}
      </div>
    </div>

    {!event?.isDraft && (
      <div className='mb-5 pb-1'>
        <div className='mb-1'>{I18N('archive_event')}</div>
        <div className='text-secondary text-small mb-3'>{I18N('archive_event_help_text')}</div>
        <Button variant='secondary' onClick={openArchiveEventModal}>
          {I18N('archive_event')}
        </Button>
      </div>
    )}

    <div className='mb-5'>
      <div className='mb-1'>{I18N('delete_event')}</div>
      <div className='text-secondary text-small mb-3'>{I18N('delete_event_help_text')}</div>
      <Button variant='secondary-danger' onClick={openDeleteEventModal}>
        {I18N('delete_event')}
      </Button>
    </div>
  </div>
)

export default GeneralSettings
