import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CardWithTable from 'components/analytics/common/cardWithTable'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { Link } from 'react-router-dom'
import cultureAnalyticsSlice from 'redux/slices/analytics/culture'
import { ActiveSignerType } from 'types/analytics/culture'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import TargetingRulesType from 'types/audience/targetingRules'
import usePathReusingQueryParams from 'components/common/hooks/usePathReusingQueryParams'
import { TAB_HEADER_QUERY_PARAMS } from 'components/analytics/helpers/useTabHeader'

const manWithTabletPath = '/images/analytics/manWithTabletIcon.svg'

interface CultureActiveSignersProps {
  periodStart: string
  periodEnd?: string
  order: 'most' | 'least'
  areGreetingCardsEnabled: boolean
  targetingRules?: TargetingRulesType
}

const CultureActiveSigners = ({
  periodStart,
  periodEnd,
  order,
  areGreetingCardsEnabled,
  targetingRules,
}: CultureActiveSignersProps) => {
  const I18N = i18nPath(`views.platform_analytics.culture.${order}`)
  const disabledText = i18nPath('views.platform_analytics.culture')('greeting_cards_disabled')

  const type = order === 'most' ? 'mostActiveSigners' : 'leastActiveSigners'

  const dispatch = useDispatch()

  const { data, totalPages } = useSelector(cultureAnalyticsSlice.selectors.getActiveSigners(type))
  const { [type]: { isLoading } } = useSelector(cultureAnalyticsSlice.selectors.getMetaData())

  useEffect(() => {
    if (!areGreetingCardsEnabled) return

    dispatch(cultureAnalyticsSlice.asyncActions.admin.fetchActiveSigners(
      type,
      {
        periodStart, periodEnd, page: 1, perPage: 10, order, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, targetingRules])

  const columns = [
    {
      header: I18NCommon('user'),
      accessor: (author: ActiveSignerType) => (
        <div className='d-flex flex-row align-items-center'>
          <EmployeeThumbnailPhoto employee={author} size='25px' />
          <Link to={`/profile/${author.username}`} className='text-truncate w-100 d-block ml-1' target='_blank'>{author.preferredFullName}</Link>
        </div>
      ),
    },
    {
      header: I18N('signed'),
      style: { width: '15%' },
      accessor: (author: ActiveSignerType) => (
        author.cardSignedCount
      ),
    },
  ]

  const otherQueryParams: Record<string, string> = order === 'most' ? {} : { order: 'least' }
  const path = usePathReusingQueryParams({
    path: '/platform_analytics/culture/active_signers',
    paramsToReuse: TAB_HEADER_QUERY_PARAMS,
    otherQueryParams,
  })

  if (!areGreetingCardsEnabled) {
    return (
      <EmptyStateCard
        title={I18N('title')}
        text={disabledText}
        iconPath={manWithTabletPath}
        classname='CultureTables'
      />
    )
  }

  return (
    <CardWithTable
      title={I18N('title')}
      tableColumns={columns}
      tableData={data}
      classname='CultureTables'
      isLoading={isLoading}
      showSeeMoreLink={totalPages > 1}
      seeMorePath={path}
    />
  )
}

export default CultureActiveSigners
