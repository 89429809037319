import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingContainer } from 'components/common/loadingContainer'
import { useHistory } from 'react-router-dom'
import pageTemplateSlice from 'redux/slices/pageTemplates'
import { i18nPath } from 'utils/i18nHelpers'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DeleteModal from 'components/common/modals/deleteModal'
import useBeforeUnload from 'components/common/hooks/useBeforeUnload'
import TrashIcon from 'components/icons/trashIcon'

const I18N = i18nPath('views.pages.actions_dropdown')

interface EditTemplateDropdownMenuProps {
  className?: string
  areChangesPresent: boolean
  workingCopy: { [key: string]: any }
}

const EditTemplateDropdownMenu = ({ workingCopy, areChangesPresent }: EditTemplateDropdownMenuProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false)

  const { isDeleting, isLoading } = useSelector(pageTemplateSlice.selectors.getMetaData())

  const showLoader = !workingCopy || isDeleting

  // Prevents user from reloading page if changes are pending
  useBeforeUnload((e) => {
    if (areChangesPresent) {
      e.preventDefault()
      e.returnValue = ''
    }
  })

  const deleteTemplate = () => {
    const params = { id: workingCopy.id, templateCategoryId: workingCopy.templateCategory.id }
    dispatch(pageTemplateSlice.asyncActions.admin.destroy(params, () => {
      history.push('/pages')
    }))
  }

  return (
    <LoadingContainer isLoading={showLoader && !isLoading}>
      <>
        <DropdownMenuContainer iconWidth='20px' iconHeight='5px' closeMenuOnItemClick>
          <CardDropdownMenuItem
            onClick={() => setShowDeleteTemplateModal(true)}
            primaryText={<span className='text-danger ml-3 pl-1 '>{I18N('delete_template')}</span>}
            className='p-3 border-top'
            icon={<TrashIcon className='DeleteTemplateIcon ml-1' />}
            width='240px'
          />
        </DropdownMenuContainer>
        {showDeleteTemplateModal && (
          <DeleteModal
            deleteText={I18N('delete_template')}
            cancelText={I18N('cancel')}
            deleteSecondaryText={workingCopy.baseTemplate ? I18N('base_template_delete_message') : ''}
            deleteConfirmText={I18N('confirm_delete_template')}
            showDeleteModal={showDeleteTemplateModal}
            closeDeleteModal={() => setShowDeleteTemplateModal(false)}
            deleteConfirm={deleteTemplate}
          />
        )}
      </>
    </LoadingContainer>
  )
}

export default EditTemplateDropdownMenu
