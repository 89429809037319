import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { Popover } from 'reactstrap'
import Card from 'components/common/card'

const I18N = i18nPath('views.profile')

const AwardedBadge = ({ awardedBadge }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const targetId = `badge-${awardedBadge.id}`

  return (
    <>
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        id={targetId}
        className='AwardedBadge'
      >
        <Link to={`/people/badges/${awardedBadge.badge.id}`}>
          <img src={awardedBadge.badge.imageUrl} alt={awardedBadge.badge.displayName} />
        </Link>
      </div>
      <Popover
        placement='bottom'
        isOpen={showTooltip}
        target={targetId}
      >
        <div className='p-3 text-small BadgePopoverContent'>{awardedBadge.badge.description}</div>
      </Popover>
    </>
  )
}

const Badges = ({ user, areBadgesEnabled }) => {
  const [showAllBadges, setShowAllBadges] = useState(false)

  if (!areBadgesEnabled) {
    return null
  }

  if (_.isEmpty(user.awardedBadges)) {
    return (
      <section className='ProfileBadges'>
        <Card>
          <h6>{I18N('badges')}</h6>
          <span className='d-inline-block text-center text-secondary mx-4 my-4'>
            {I18N('badges_empty')}
          </span>
        </Card>
      </section>
    )
  }

  const initialBadgeCountToShow = 9
  const initialBadges = user.awardedBadges.slice(0, initialBadgeCountToShow)
  const areThereMoreBadges = user.awardedBadges.length > initialBadgeCountToShow
  const badges = showAllBadges ? user.awardedBadges : initialBadges

  return (
    <section className='ProfileBadges'>
      <Card>
        <h6>{I18N('badges')}</h6>
        <div className='badge-grid mt-3'>
          {badges.map((awardedBadge, i) => (
            <AwardedBadge key={`awardedBadge-${i}`} awardedBadge={awardedBadge} />
          ))}
        </div>
        {areThereMoreBadges && !showAllBadges && (
          <div
            className='ShowAllBadgesLink mt-4 text-center'
            onClick={() => setShowAllBadges(true)}
          >
            {I18N('show_more_badges')}
          </div>
        )}
      </Card>
    </section>
  )
}

export default Badges
