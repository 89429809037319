import React from 'react'
import CheckIcon from 'components/icons/checkIcon'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CancelButton from 'components/common/cancelButton'
import { WorkspaceType, WorkspaceTypeEnum } from 'types/chat/workspace'

const I18N = i18nPath('views.integrations_page.integration_card')

interface Props {
  onUninstall: (id: string) => void
  workspace: WorkspaceType
}

const getSuccessfullyInstalledMessage = (workspace: WorkspaceType) => {
  if (workspace.type === WorkspaceTypeEnum.MsTeamWorkspace) {
    return I18N('microsoft_teams.successfully_installed')
  }

  if (workspace.type === WorkspaceTypeEnum.SlackWorkspace) {
    return I18N('slack.successfully_installed')
  }

  return null
}

const IntegrationInstalledMessage = ({ workspace, onUninstall }: Props) => (
  <div className='d-flex align-items-center mb-1'>
    <CheckIcon fillColor='var(--success-color)' className='CheckIcon mr-1' width='10px' height='10px' />
    <div className='text-success text-small'>
      <span>
        <span className='mr-1 font-weight-bold'>{workspace.domain}</span>
        {getSuccessfullyInstalledMessage(workspace)}
      </span>

      <CancelButton
        className='ml-2 mr-3'
        onClick={() => onUninstall(workspace.id)}
      >
        {I18NCommon('uninstall')}
      </CancelButton>
    </div>
  </div>
)

export default IntegrationInstalledMessage
