const ellipsis = (text, limit = 20) => {
  // if text is null or undefined, just return
  if (!text) return text

  const shortened = text.slice(0, limit)

  if (shortened.length === text.length) {
    return text
  } else {
    return shortened.concat('...')
  }
}

export default ellipsis
