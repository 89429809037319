import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Card from 'components/common/card'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import CompleteYourProfileButton from 'components/home/completeYourProfileButton'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.home.person_card')

const DaysUntilStart = ({ currentUser }) => {
  if (!currentUser.preboarding || !currentUser.startDate) {
    return null
  }

  const daysUntilStart = moment(currentUser.startDate).diff(moment(), 'days') + 1

  if (daysUntilStart < 1) {
    return null
  }

  return <div className='mt-3'>{I18N('days_until_start', { count: daysUntilStart })}</div>
}

const ProfileWidget = () => {
  const currentUser = useCurrentUser()

  const { data: user } = useFetch(() => API.profile.fetch(currentUser.id), [currentUser.id])

  return (
    <Card
      className='ProfileWidget HomePageWidget'
      cardBodyClassName='ProfileWidgetCardBody'
    >
      <div className='d-flex align-items-center'>
        <EmployeeThumbnailPhoto
          size='52px'
          employee={currentUser}
          linkToProfile
        />
        <div className='d-flex flex-column align-items-start ml-2'>
          <h5 className='m-0'>
            <Link className='text-primary-link' to={`/profile/${currentUser.username}`}>{currentUser.preferredFullName}</Link>
          </h5>
          <p className='text-secondary m-0'>{currentUser.title}</p>
        </div>
      </div>

      {currentUser.inPreboardingExperience ? (
        <DaysUntilStart currentUser={currentUser} />
      ) : (
        user && <CompleteYourProfileButton user={user} buttonText={I18N('complete_profile')} />
      )}
    </Card>
  )
}

export default ProfileWidget
