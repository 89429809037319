import React, { useState, useEffect } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'

const I18NCommon = i18nPath('views.common')

const CopyArticleModal = ({
  articleTitle,
  className = '',
  closeCopyArticleModal,
  handleCopyArticle,
  isCopying,
  showCopyArticleModal,
  I18N = i18nPath('views.admin.article_editor.copy_modal'),
}) => {
  const [copyArticleTitle, setCopyArticleTitle] = useState(`${I18N('copy_of')} ${articleTitle}`)

  useEffect(() => {
    setCopyArticleTitle(`${I18N('copy_of')} ${articleTitle}`)
  }, [articleTitle])

  return (
    <Modal size='md' className={classNames('CopyArticleModal', className)} visible={showCopyArticleModal} toggle={closeCopyArticleModal}>
      <div className='p-2 text-center'>
        <h3 className='text-left'>{I18N('modal_title')}</h3>
        <div className='text-left mt-5'>
          <label htmlFor='copy_article_name_field'>{I18N('title')}</label>
          <input id='copy_article_name_field' className='w-100' value={copyArticleTitle} onChange={ev => setCopyArticleTitle(ev.target.value)} disabled={isCopying} />
        </div>

        <div className='d-flex justify-content-end mt-4'>
          <Button className='mr-2' variant='secondary' onClick={closeCopyArticleModal} disabled={isCopying}>
            {I18NCommon('cancel')}
          </Button>
          <Button
            onClick={() => handleCopyArticle(copyArticleTitle)}
            showLoadingSpinner={isCopying}
            disabled={isCopying}
          >
            {I18NCommon('save')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CopyArticleModal
