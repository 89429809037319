import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import carouselCardSlice from 'redux/slices/carouselCards'
import CarouselCardForm from 'components/admin/carousel_cards/carouselCardForm'
import FormErrorList from 'components/errors/formErrorList'
import { isAudienceValid } from 'utils/audience'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import CarouselCard from 'components/carousel_cards/carouselCard'

const I18N = i18nPath('views.admin.carousel_cards')

const CreateCarouselCardPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [carouselCard, setCarouselCard] = useState({
    id: null,
    name: I18N('untitled_name'),
    title: '',
    subtitle: '',
    content: `<h3>${I18N('form.content_placeholder')}</h3>`,
    contentAlignment: 'middle',
    buttonColor: '',
    buttonText: '',
    buttonUrl: '',
    buttonStyle: 'fill',
    startTime: moment().format(),
    endTime: moment().add(7, 'd').format(),
    targetingRules: TARGET_ENTIRE_COMPANY,
  })

  const { isSaving, error } = useSelector(carouselCardSlice.selectors.getAdminMetaData())
  const isMissingRequiredFields = !carouselCard.name || !carouselCard.startTime || !carouselCard.endTime
  const isSaveButtonDisabled = isMissingRequiredFields || !isAudienceValid(carouselCard)

  const saveCarouselCard = () => {
    dispatch(carouselCardSlice.asyncActions.admin.createCarouselCard(carouselCard, history))
  }

  return (
    <div className='CreateCarouselCardPage'>
      <header className='AdminHeader'>
        <h3>{I18N('create_new_carousel_card')}</h3>
      </header>

      <main className='AdminContent'>
        <CarouselCard carouselCard={carouselCard} />

        <CarouselCardForm
          card={carouselCard}
          setCard={setCarouselCard}
        />

        {error && <FormErrorList error={error} />}

        <Button disabled={isSaveButtonDisabled} onClick={saveCarouselCard} showLoadingSpinner={isSaving}>
          {I18N('update_carousel_card')}
        </Button>
      </main>
    </div>
  )
}

export default CreateCarouselCardPage
