import React, { useEffect, useState } from 'react'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import TemplateItem from 'components/admin/articles/templates/templateItem'
import { ButtonSecondarySmall } from 'components/common/buttons'
import TemplatesSidebarEmptyState from 'components/admin/articles/templates/TemplatesSidebarEmptyState'
import CollapsableItem from 'components/common/collapsableItem'


const I18N = i18nPath('views.admin.article_editor.templates_sidebar')

type CategoryItemWithLoadedTemplatesProps = {
  label: string
  templates: {
    id: string
    title: string
  }[]
  startExpanded?: boolean
  gridView?: boolean
}

export const CategoryItemWithLoadedTemplates = ({
  label,
  templates,
  startExpanded,
  gridView = false,
}: CategoryItemWithLoadedTemplatesProps) => (
  <CategoryItem
    label={label}
    templates={templates}
    isCollapsed={!startExpanded}
    toggleCategory={() => {}}
    gridView={gridView}
  />
)

type CategoryItemWithFetchProps = {
  label: string
  renderEmptyStateIfEmpty?: boolean
  fetchParams: {
    categoryId?: string
    baseTemplates?: boolean
    companyTemplates?: boolean
    perPage?: number
    sortBy?: string
  }
  startExpanded?: boolean
  gridView?: boolean
}

export const CategoryItemWithFetch = ({
  label,
  fetchParams,
  startExpanded = false,
  gridView = false,
  renderEmptyStateIfEmpty = false,
}: CategoryItemWithFetchProps) => {
  const [templates, setTemplates] = useState<any>([])
  const [
    fetchTemplates,
    { isLoading, paginationData: { page = 0, totalPages = 0 } },
  ] = useApi(API.admin.article.templates.fetchAll, {
    onSuccess: newTemplates => setTemplates([...templates, ...newTemplates]),
  })

  const hasMore = page < totalPages

  const fetchCategoryTemplates = () => {
    fetchTemplates({ page: page + 1, perPage: 6, ...fetchParams })
  }

  const toggleCategory = () => {
    if (!templates.length) {
      fetchCategoryTemplates()
    }
  }

  useEffect(() => {
    if (startExpanded) {
      toggleCategory()
    }
  }, [])

  const emptyStateVisible = renderEmptyStateIfEmpty && !isLoading && templates.length === 0

  if (emptyStateVisible) {
    return <TemplatesSidebarEmptyState message={I18N('you_have_no_templates_yet')} />
  }

  return (
    <CategoryItem
      label={label}
      templates={templates}
      isCollapsed={!startExpanded}
      toggleCategory={toggleCategory}
      isLoading={isLoading}
      hasMore={hasMore}
      fetchCategoryTemplates={fetchCategoryTemplates}
      gridView={gridView}
    />
  )
}

const CategoryItem = ({
  label,
  templates,
  isCollapsed,
  toggleCategory,
  isLoading = false,
  hasMore = false,
  fetchCategoryTemplates = () => { },
  gridView = false,
}) => (
  <CollapsableItem label={label} defaultIsCollapsed={isCollapsed} onClick={toggleCategory}>
    <div className={classNames('mb-4', gridView && 'd-flex flex-wrap')}>
      {templates.map(template => (
        <TemplateItem key={template.id} template={template} gridView={gridView} />
      ))}
      {isLoading && <CirclesLoadingIndicator />}
      {hasMore && (
        <>
          {gridView ? (
            <div className='w-100 mt-3 d-flex justify-content-center'>
              <ButtonSecondarySmall onClick={fetchCategoryTemplates} className='text-smallest'>
                {I18N('load_more_templates')}
              </ButtonSecondarySmall>
            </div>
          ) : (
            <div onClick={fetchCategoryTemplates} className='link-color font-weight-500 text-smallest pointer'>
              {I18NCommon('see_more')}
            </div>
          )}
        </>
      )}
    </div>
  </CollapsableItem>
)
