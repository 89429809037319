import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import qnaEventSlice from 'redux/slices/qnaEvents'
import audienceSlice from 'redux/slices/audiences'

import EventPage from 'pages/qna/eventPage'
import ModeratorViewNavbar from 'components/qna/moderatorViewNavbar'
import PublishEventModal from 'components/qna/publishEventModal'
import EventSettingsModal from 'components/qna/settings_modal'

const EventModeratorView = ({
  event,
  isLoading,
}) => {
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)

  const dispatch = useDispatch()
  const onClickSettingsIcon = () => {
    setShowSettingsModal(true)
  }

  const onClickPublishButton = () => {
    const onSuccess = () => setShowPublishModal(true)
    dispatch(qnaEventSlice.asyncActions.admin.publish(event.id, onSuccess))
  }

  return (
    <>
      {!isLoading && (
        <ModeratorViewNavbar
          isModerator={true}
          view='moderator'
          toggleViewURL={`/events/${event.slug}/preview`}
          onClickSettings={onClickSettingsIcon}
          onClickPublish={onClickPublishButton}
          showPublishButton={event.isDraft}
        />
      )}
      <EventPage
        event={event}
        isPreview={false}
        isLoading={isLoading}
        canManageEvent={true}
        onClickPublish={onClickPublishButton}
      />

      <PublishEventModal
        showModal={showPublishModal}
        onCloseModal={() => setShowPublishModal(false)}
        event={event}
      />

      <EventSettingsModal
        showSettingsModal={showSettingsModal}
        setShowSettingsModal={setShowSettingsModal}
        event={event}
      />

    </>
  )
}

export default EventModeratorView
