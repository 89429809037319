import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import PillTabSelector from 'components/common/pillTabSelector'
import Stat from 'pages/admin/articles/stats/Stat'
import LineGraph from 'components/analytics/common/lineGraph'

const I18N = i18nPath('views.admin.article_stats')

const AUDIENCE_TAB_INDEX = 0
const OVERALL_TAB_INDEX = 1

const Reach = ({ articleStat }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const audienceSize = articleStat.audienceUserIds?.length || 0
  const audienceUniqueEngagement = parseInt(articleStat.audienceUniqueEngagement, 10)
  const uniqueEngagement = parseInt(articleStat.uniqueEngagement, 10)
  const engagement = parseInt(articleStat.engagement, 10)
  const audienceUniqueReach = I18n.toPercentage(
    articleStat.audienceUniqueReach * 100,
    { precision: 1 }
  )
  const overallReach = I18n.toPercentage(
    articleStat.overallReach * 100,
    { precision: 1 }
  )

  const audienceEngagementByDate = articleStat.audienceEngagementByDate || []
  const dateLabels = audienceEngagementByDate.map(counts => counts.date)
  const totalInteractionsValues = audienceEngagementByDate.map(counts => counts.total)

  const engagementByDate = articleStat.engagementByDate || []
  const overallDateLabels = engagementByDate.map(counts => counts.date)
  const overallTotalInteractionsValues = engagementByDate.map(counts => counts.total)

  return (
    <Card>
      <PillTabSelector
        defaultSelectedIndex={selectedIndex}
        pillClasses='text-normal'
        tabs={[
          {
            text: I18N('tabs.audience'),
            onClick: () => {
              setSelectedIndex(AUDIENCE_TAB_INDEX)
            },
            hidden: false,
          },
          {
            text: I18N('tabs.overall'),
            onClick: () => {
              setSelectedIndex(OVERALL_TAB_INDEX)
            },
            hidden: false,
          },
        ]}
      />

      <hr />

      {selectedIndex === AUDIENCE_TAB_INDEX && (
        <div className='d-flex align-items-center reach-tab-content'>
          <div className='counters-wrapper'>
            <Stat
              value={audienceUniqueReach}
              label={I18N('stat.audience_reach')}
              tooltip={I18N('stat.audience_reach_tooltip')}
            />

            <Stat
              value={audienceUniqueEngagement}
              label={I18N('stat.unique_engagement')}
              tooltip={I18N('stat.unique_engagement_tooltip')}
            />

            <Stat
              value={audienceSize}
              label={I18N('stat.audience_size')}
              tooltip={I18N('stat.audience_size_tooltip')}
            />
          </div>
          <div className='chart-wrapper align-self-stretch ml-4'>
            <h5 className='font-weight-600'>{I18N('stat.audience_reach')}</h5>
            <div className='chart-content'>
              <div>
                <LineGraph
                  labels={dateLabels}
                  dataValues={totalInteractionsValues}
                  tooltipLabel={I18N('total_interactions')}
                  classname='w-100'
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedIndex === OVERALL_TAB_INDEX && (
        <div className='d-flex align-items-center reach-tab-content'>
          <div className='counters-part'>
            <Stat
              value={overallReach}
              label={I18N('stat.overall_engagement_rate')}
              tooltip={I18N('stat.overall_engagement_rate_tooltip')}
            />

            <Stat
              value={engagement}
              label={I18N('stat.total_engagement')}
              tooltip={I18N('stat.total_engagement_tooltip')}
            />

            <Stat
              value={uniqueEngagement}
              label={I18N('stat.unique_engagement')}
              tooltip={I18N('stat.unique_engagement_tooltip')}
            />
          </div>
          <div className='chart-wrapper align-self-stretch ml-4'>
            <h5 className='font-weight-600'>{I18N('stat.overall_reach')}</h5>
            <div className='chart-content'>
              <div>

                <LineGraph
                  labels={overallDateLabels}
                  dataValues={overallTotalInteractionsValues}
                  tooltipLabel={I18N('total_interactions')}
                  classname='w-100'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}

export default Reach
