import React from 'react'

import Card from 'components/common/card'
import RichTextView from 'components/common/richTextView'
import StepVariablesDropdown from 'components/admin/journeys/stepVariablesDropdown'
import {
  i18nPath
} from 'utils/i18nHelpers'
import FromAndToCorrespondentSelector from 'components/admin/journeys/common/fromAndToCorrespondentSelector'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'

const I18N = i18nPath('views.admin.journeys.task_templates')

const TaskableFormSharedFieldsSection = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled = false,
  stepType = 'task',
  journeyBlueprint,
}) => {
  const additionalUserVariables = [
    'task_assignee',
    stepType === 'introduction' && 'colleague',
  ].filter(Boolean)

  return (
    <>
      <div className='font-weight-600 text-secondary mb-2'>{I18N('task_details')}</div>
      <Card className='mb-4'>
        <FromAndToCorrespondentSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          isDisabled={isDisabled}
          journeyBlueprint={journeyBlueprint}
        />
        <div className='form-group'>
          <label className='required-form-label'>{I18N('task_name')}</label>
          <input
            name='task_name'
            value={workingCopy.name}
            disabled={isDisabled}
            onChange={({ target }) => updateWorkingCopy({ name: target.value })}
          />
        </div>

        <div>
          <label className='required-form-label'>{I18N('instructions')}</label>
          {isDisabled ? (
            <RichTextView
              className='content-rich-view'
              record={workingCopy}
              recordType={stepType}
              richTextKey='instructions'
            />
          ) : (
            <>
              <TiptapEditor
                className='TiptapEditor straight-borders'
                onChange={value => updateWorkingCopy({ instructions: value })}
                html={workingCopy.instructions}
                configuration={{
                  placeholder: I18N('instructions_placeholder'),
                  userMentionsEnabled: false,
                  videosEnabled: true,
                }}
                editorContentClassName='p-3'
              />
              <StepVariablesDropdown
                additionalRoles={additionalUserVariables}
                journeyBlueprint={journeyBlueprint}
                isTask
              />
            </>
          )}
        </div>
      </Card>
    </>
  )
}

export default TaskableFormSharedFieldsSection
