import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import EmployeeMultiLinkWithPopover from 'components/common/employeeMultiLinkWithPopover'
import GroupInfoPopover from 'components/common/groupInfoPopover'
import MentionedUsers from 'components/common/mentionedUsers'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

const I18N = i18nPath('views.article')

const AuthorName = ({
  authors, index, authorNameOverride, articleId,
}) => {
  let author = authors[index]

  const lastAuthorSeparator = authors.length === 2 ? I18N('sentence_last_name_separator_for_two') : I18N('sentence_last_name_separator')
  const isNotLast = index !== authors.length - 1
  const penultimate = index !== authors.length - 2
  const separator = penultimate ? I18N('sentence_name_separator') : lastAuthorSeparator
  const authorPopoverId = `article-${articleId}-author-${author.id}`

  if (authorNameOverride) {
    author = authorNameOverride(author)
  }

  return (
    <span className='text-small text-primary'>
      <EmployeeLinkWithPopover user={author} id={authorPopoverId} />
      {isNotLast && <span className='ws-pre-wrap'>{separator}</span>}
    </span>
  )
}

const AuthorsMultiCount = ({ authors, articleId }) => {
  const history = useHistory()

  return (
    <span>
      <EmployeeMultiLinkWithPopover users={authors} id={`multiple-authors-article-${articleId}`} history={history}>
        <span className='font-weight-500'>{I18N('multi_authors', { numUsers: authors.length })}</span>
      </EmployeeMultiLinkWithPopover>
    </span>
  )
}

const AuthorNames = ({
  article,
  isCard = false,
  alwaysShowCount = false,
}) => {
  const { authors, groupInformation, group: groupInfo } = article
  const group = groupInformation?.group || groupInfo
  const isGroupPost = article?.groupId || location.href.includes('/posts/')

  if (alwaysShowCount) {
    return (
      <AuthorsMultiCount authors={authors} articleId={article.id} />
    )
  }

  if (authors?.length > 0) {
    if (!isCard || authors.length === 1) {
      return (
        authors.map((author, index) => (
          <React.Fragment key={index}>
            <AuthorName
              key={author.id + article.id}
              authors={authors}
              index={index}
              articleId={article.id}
            />
            {isGroupPost && (
              <>
                <span className='text-small text-secondary'>&nbsp;{I18N('to')}&nbsp;</span>
                <GroupInfoPopover
                  key={`${group.id}-${article.id}`}
                  group={group}
                  id={`group-post-${article.id}`}
                  className='text-primary text-small'
                />
              </>
            )}
          </React.Fragment>
        ))
      )
    } else if (authors.length === 2) {
      // Suppress the last name of the author to show just the first char
      const authorNameOverride = (author) => {
        const authorWithLastNameSuppressed = _.clone(author)
        const newPreferredFullName = authorWithLastNameSuppressed.preferredName + ' ' + authorWithLastNameSuppressed.preferredLastName?.charAt(0)
        authorWithLastNameSuppressed.preferredFullName = newPreferredFullName

        return authorWithLastNameSuppressed
      }

      return authors.map((author, index) => (
        <AuthorName
          key={author.id + article.id}
          authors={authors}
          index={index}
          articleId={article.id}
          authorNameOverride={authorNameOverride}
        />
      ))
    } else {
      return (
        <AuthorsMultiCount authors={authors} articleId={article.id} />
      )
    }
  }

  return null
}

const ArticleByLine = ({
  article,
  isCard = false,
  className = '',
  alwaysShowAuthorCount = false,
  showMentionedUsers = true,
  alwaysShowMentionedUsersCount = false,
}) => {
  const {
    authors, publishedAt, displayAuthorText,
  } = article

  const publishedAtText = publishedAt ? i18nMoment(publishedAt).fromNow() : I18N('missing_publish_date')

  const hasAuthor = displayAuthorText || !_.isEmpty(authors)

  return (
    <div className={classNames('ArticleByLine d-flex flex-wrap align-items-baseline text-small', className)}>
      {/* AUTHORS */}
      {hasAuthor && (
        <>
          <span className='text-small text-secondary mr-1'>{I18N('by')}</span>
          {displayAuthorText ? (
            <span className='text-primary font-weight-600'>{displayAuthorText}</span>
          ) : (
            <AuthorNames article={article} isCard={isCard} alwaysShowCount={alwaysShowAuthorCount} />
          )}
        </>
      )}

      {showMentionedUsers && (
        <MentionedUsers
          article={article}
          alwaysShowCount={alwaysShowMentionedUsersCount}
        />
      )}

      {publishedAt && <span className='PublishedAt text-secondary bullet-separator-secondary'>{publishedAtText}</span>}
    </div>
  )
}

export default ArticleByLine
