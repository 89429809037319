import classNames from 'classnames'
import React, { useRef } from 'react'

interface ToggleSwitchProps {
  className?: string
  checked: boolean
  text?: string | null
  onChange?: () => void
  textPosition?: string
}

const ToggleSwitch = ({
  className = '',
  checked,
  text = null,
  onChange = () => {},
  textPosition = 'before',
} : ToggleSwitchProps) => {
  const checkboxRef = useRef(null)

  const handleClick = (e) => {
    // If the target of the click event is the checkbox itself, do nothing.
    if (e.target === checkboxRef.current) return

    // Otherwise, toggle the checkbox.
    onChange()
  }

  return (
    <div
      className={classNames('ToggleSwitch d-flex align-items-center pointer', className)}
      onClick={handleClick}
    >
      {textPosition === 'before' && text && (<div>{text}</div>)}
      <div className='switch-container'>
        <label className='mb-0'>
          <input
            ref={checkboxRef}
            type='checkbox'
            checked={checked}
            onChange={e => e.stopPropagation()}
          />
          <span className='switch-slider' />
        </label>
      </div>
      {textPosition === 'after' && text && (<div className='ml-2'>{text}</div>)}
    </div>
  )
}

export default ToggleSwitch
