import React from 'react'
import classNames from 'classnames'
import getDueAtText from 'utils/journeys/getDueAtText'

const DueAtTextComponent = ({ task, className = '', hasDuePrefix = true }) => {
  const completionStatus = task?.completionStatus
  const dueAtDate = task?.dueAt
  const isOverdue = completionStatus === 'overdue'

  return (
    <span className={classNames('DueAtTextComponent', { isOverdue }, className)}>{getDueAtText(completionStatus, dueAtDate, hasDuePrefix)}</span>
  )
}

export default DueAtTextComponent
