import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice from 'redux/slices/surveys/forms'

import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import SurveyFormType from 'types/surveys/form'
import DeleteModal from 'components/common/modals/deleteModal'
import DuplicateModal from 'components/common/modals/duplicateModal'
import { useHistory } from 'react-router-dom'
import ToggleSwitch from 'components/common/toggleSwitch'
import TrashIcon from 'components/icons/trashIcon'
import LinkIcon from 'components/icons/linkIcon'
import taskTemplateSlice from 'redux/slices/journeys/taskTemplates'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.admin.surveys')

const DELETE_MODAL = 'delete'
const DUPLICATE_MODAL = 'duplicate'

const eyeStrikethroughIconPath = '/images/eyeStrikethroughIcon.svg'
const duplicateIconPath = '/images/duplicateIcon.svg'
const personIconPath = '/images/personIcon.svg'
const eyeOpenIconPath = '/images/eyeOpenIcon.svg'

const FormTaskSettingsDropdown = ({
  form,
  taskTemplate,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [openModal, setOpenModal] = useState('')

  const { isSaving: isSavingForm } = useSelector(surveyFormSlice.selectors.getMetaData())

  const anonymousResponses = form?.anonymousResponses
  const stepTemplate = taskTemplate?.stepTemplate
  const journeyBlueprintRoute = stepTemplate?.journeyBlueprint?.adminDisplayPath
  const state = stepTemplate?.state
  const isDraft = state === 'draft'
  const isActive = state === 'active'

  const questions = form?.questions || []
  const hasQuestions = !_.isEmpty(questions)
  const hasInstructions = !_.isEmpty(taskTemplate?.name) && !_.isEmpty(taskTemplate?.instructions)

  const updateForm = (changes: Partial<SurveyFormType>) => {
    dispatch(surveyFormSlice.asyncActions.admin.update(changes))
  }

  const [deleteStepTemplate] = useApi(API.admin.stepTemplates.destroy, {
    onSuccess: () => history.push(`${journeyBlueprintRoute}/edit`),
  })

  const duplicateFormTask = (name: string) => {
    const onSuccess = (newId) => {
      setOpenModal('')

      history.push(`${journeyBlueprintRoute}/survey_templates/${newId}`)
    }

    dispatch(taskTemplateSlice.asyncActions.admin.copyTaskTemplate(taskTemplate.id, name, onSuccess))
  }

  const revertToDraft = () => {
    const params = {
      id: taskTemplate.id,
      stepTemplateAttributes: {
        id: stepTemplate.id,
        state: 'draft',
      },
    }

    dispatch(taskTemplateSlice.asyncActions.admin.updateTaskTemplate(params))

    history.push(`${taskTemplate.adminDisplayPath}?selectedTab=questions`)
  }

  return (
    <>
      <DropdownMenuContainer className='form-settings-dropdown-container'>
        <CardDropdownMenuItem
          onClick={() => {}}
          primaryText={(
            <ToggleSwitch
              className='mx-2 w-100 justify-content-between'
              checked={anonymousResponses}
              text={I18N('anonymous_results')}
              onChange={() => updateForm({ id: form.id, anonymousResponses: !anonymousResponses })}
            />
          )}
          className='rounded-top-lg w-100 py-3'
          width='240px'
          icon={<CdnSvg src={personIconPath} className='PersonIcon' />}
        />
        {hasQuestions && hasInstructions && (
          <CardDropdownMenuItem
            primaryText={<span className='mx-2'>{I18N('preview_survey')}</span>}
            icon={<CdnSvg src={eyeOpenIconPath} className='EyeIcon' />}
            className='rounded-top-lg py-3'
            width='240px'
            onClick={() => history.push(`/admin/surveys/${form.id}/preview`)}
          />
        )}
        {!isDraft && (
          <CardDropdownMenuItem
            onClick={revertToDraft}
            primaryText={<span className='mx-2'>{I18NCommon('revert_to_draft')}</span>}
            className='rounded-top-lg py-3'
            width='240px'
            icon={<CdnSvg src={eyeStrikethroughIconPath} className='EyeStrikethroughIcon' />}
          />
        )}
        {isActive && (
          <CardDropdownMenuItem
            // copy the form url
            onClick={() => navigator.clipboard.writeText(form.displayUrl)}
            primaryText={<span className='mx-2'>{I18N('copy_form_url')}</span>}
            className='rounded-top-lg py-3'
            width='240px'
            icon={<LinkIcon />}
          />
        )}
        <CardDropdownMenuItem
          onClick={() => setOpenModal(DUPLICATE_MODAL)}
          primaryText={<span className='mx-2'>{I18N('duplicate_survey_modal.duplicate_survey')}</span>}
          className='rounded-top-lg py-3'
          width='240px'
          icon={<CdnSvg src={duplicateIconPath} className='DuplicateIcon' />}
        />
        <CardDropdownMenuItem
          onClick={() => setOpenModal(DELETE_MODAL)}
          primaryText={<span className='mx-2 text-danger'>{I18N('delete_suvey_modal.delete_survey')}</span>}
          className='delete-dropdown-item rounded-top-lg py-3'
          width='240px'
          icon={<TrashIcon className='TrashIcon' />}
        />
      </DropdownMenuContainer>
      {openModal === DELETE_MODAL && (
        <DeleteModal
          deleteText={I18N('delete_suvey_modal.title')}
          deleteSecondaryText={I18N('delete_suvey_modal.body')}
          deleteConfirmText={I18N('delete_suvey_modal.delete_confirm')}
          deleteConfirm={() => deleteStepTemplate(stepTemplate.id)}
          showDeleteModal={openModal === DELETE_MODAL}
          closeDeleteModal={() => setOpenModal('')}
          isDeleting={isSavingForm}
        />
      )}
      {openModal === DUPLICATE_MODAL && (
        <DuplicateModal
          title={I18N('duplicate_survey_modal.duplicate_survey')}
          label={I18N('duplicate_survey_modal.label')}
          initialName={I18N('duplicate_survey_modal.copy_of', { formName: stepTemplate.name })}
          visible={openModal === DUPLICATE_MODAL}
          isCopying={isSavingForm}
          onDuplicate={duplicateFormTask}
          toggle={() => setOpenModal('')}
        />
      )}
    </>
  )
}


export default FormTaskSettingsDropdown
