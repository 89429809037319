import React, { useState } from 'react'
import { Radio, RadioGroup } from 'react-radio-group'

import { i18nPath } from 'utils/i18nHelpers'

import previewComponents from 'components/admin/customStyles/previewComponents/previewComponentsList'

const I18N = i18nPath('views.custom_styles.border_width')

interface Props {
  variable: string
  value: string
  onChange: (type: string, variable: string, value: string) => void
  defaultValues: { [key: string]: string }
  friendlyLabels: { [key: string]: string }
}

const BorderWidthControl = ({
  variable, value, onChange, defaultValues, friendlyLabels,
}: Props) => {
  const initialValue = value === '0px' ? 'none' : 'visible'
  const [selectedValue, setOption] = useState(initialValue)

  const handleChange = (value) => {
    const newValue = value === 'none' ? '0px' : defaultValues[variable]
    setOption(value)
    onChange('border', variable, newValue)
  }

  return (
    <div className='BorderWidthControl d-flex justify-content-between align-content-start'>
      <span style={{ width: '100%' }}>
        <label className='label'>{I18N(friendlyLabels[variable])}</label>
        <div className='position-relative mt-3 mb-2 p-2'>{previewComponents[variable]}</div>
      </span>
      <span className='control'>
        <RadioGroup
          className='d-flex align-items-center justify-content-center'
          name={variable}
          selectedValue={selectedValue}
          onChange={handleChange}
        >
          <Radio className='m-2' value='none' />
          {I18N('none')}
          <Radio className='m-2' value='visible' />
          {I18N('visible')}
        </RadioGroup>
      </span>
    </div>
  )
}

export default BorderWidthControl
