import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

const permissionSlice = createSlice({
  name: 'adminPermissions',
  initialState: {
    permissions: {},
    meta: {
      isLoading: false,
      isNotFound: false,
      queryParams: defaultPaginationParams,
    },
  },
  reducers: {
    // This is where we want to expand our generic handling of errors from the Rails backend
    //   * Generic string error like 500's
    //   * Form based, return all fields that are populating a model.error and the standardized message per field, using this to create inline error messages
    setError(state, action) {
      state.meta.error = action.message
    },

    isLoading(state, action) {
      state.meta.isLoading = action.payload
    },

    loadPermissions: (state, action) => {
      action.payload.forEach(({ name, count }) => {
        state.permissions[name] = { count }
      })
    },

    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },

    setUsersIdsForPermission: (state, action) => {
      const { permission, userIds } = action.payload

      state.permissions[permission] = state.permissions[permission] || {}
      state.permissions[permission].userIds = userIds
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(permissionSlice, {
  asyncActions: {
    admin: {
      fetchAll: () => async (dispatch) => {
        dispatch(permissionSlice.actions.isLoading(true))

        try {
          const result = await API.admin.users.getUsersCountByPermission()

          dispatch(permissionSlice.actions.loadPermissions(result.data.permissions))
        } catch (e) {
          dispatch(permissionSlice.actions.setError({ message: 'Failed to fetch Permissions' }))
        } finally {
          dispatch(permissionSlice.actions.isLoading(false))
        }
      },

      fetchUsers: queryParams => async (dispatch) => {
        const { permission } = queryParams

        dispatch(permissionSlice.actions.isLoading(true))

        try {
          const response = await API.admin.users.fetchAll(queryParams)
          const newQueryParams = queryParamsFromHeaders(response)
          const userIds = response.data.data.map(user => user.id)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(permissionSlice.actions.setQueryParams(newQueryParams))
          dispatch(permissionSlice.actions.setUsersIdsForPermission({ permission, userIds }))
        } catch (e) {
          dispatch(permissionSlice.actions.setError({ message: 'Failed to fetch Users with Permission' }))
        } finally {
          dispatch(permissionSlice.actions.isLoading(false))
        }
      },
    },
  },
})

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
_.assign(permissionSlice, {
  selectors: {
    getPermissions: (state) => {
      const permissionsKeys = Object.keys(state.adminPermissions.permissions)
      return permissionsKeys.map(permissionKey => ({
        name: permissionKey,
        count: state.adminPermissions.permissions[permissionKey]?.count,
      }))
    },
    getMetaData: state => state.adminPermissions.meta,
    getUsersByPermission: permission => (state) => {
      if (state.adminPermissions.permissions[permission]) {
        const { userIds = [] } = state.adminPermissions.permissions[permission]
        return userIds.map(id => build(state.entities, 'user', id))
      } else {
        return []
      }
    },
  },
})

export default permissionSlice
