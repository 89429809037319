import React from 'react'
import HamburgerMenuIcon from 'components/icons/hamburgerMenuIcon'
import classNames from 'classnames'

const MobileViewToolbar = ({
  onClickHamburgerNav,
  children,
  className = '',
}) => (
  <div className={classNames('MobileViewToolbar px-0 d-flex justify-content-between', className)}>
    <div className='d-flex d-md-none align-items-center justify-content-start h-100'>
      <div className='Toolbar-link d-flex align-items-center mr-3' onClick={onClickHamburgerNav}>
        <HamburgerMenuIcon className='ml-3' />
      </div>
      {children}
    </div>
  </div>
)

export default MobileViewToolbar
