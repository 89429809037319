import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import BackButton from 'components/common/backButton'
import useQueryParams from 'components/common/hooks/useQueryParams'
import searchAnalyticsSlice from 'redux/slices/analytics/search'
import { SearchQueryResultType } from 'types/analytics/search'
import { i18nPath } from 'utils/i18nHelpers'

const backToText = i18nPath('views.platform_analytics')('title')
const queriesI18N = i18nPath('views.platform_analytics.search.queries')

const womanWithComputerPath = '/images/analytics/womanWithComputerIcon.svg'
const womanCelebratingPath = '/images/analytics/womanCelebratingIcon.svg'

const SearchQueryResultsPage = () => {
  const dispatch = useDispatch()

  const { type = 'top' } = useQueryParams()

  const reduxType = type === 'top' ? 'topQueries' : 'queriesWithZeroResults'
  const I18N = (path: string) => queriesI18N(`${type}.${path}`)

  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    targetingRules,
    setTargetingRules,
    completedTargetingRules,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const {
    data, totalPages, page, totalItems,
  } = useSelector(searchAnalyticsSlice.selectors.getQueries(reduxType))
  const { [reduxType]: { isLoading } } = useSelector(searchAnalyticsSlice.selectors.getMetaData())
  const isDataEmpty = totalItems === 0 && !isLoading

  const fetchData = ({ page }) => {
    const action = type === 'top' ? 'fetchTopQueries' : 'fetchQueriesWithZeroResults'
    dispatch(searchAnalyticsSlice.asyncActions.admin[action](
      {
        periodStart, periodEnd, page, targetingRules: completedTargetingRules,
      }
    ))
  }

  useEffect(() => {
    fetchData({ page: 1 })
  }, [periodStart, periodEnd, completedTargetingRules])

  const columns = [
    {
      header: queriesI18N('query'),
      accessor: (search: SearchQueryResultType) => <Link to={search.path} className='text-truncate w-100 d-block ml-1' target='_blank'>{search.query}</Link>,
    },
    {
      header: queriesI18N('total_searches'),
      style: { width: '25%' },
      accessor: (search: SearchQueryResultType) => search.count,
    },
  ]

  return (
    <main className='include-default-grid mb-5'>
      <BackButton
        backToText={backToText}
        url='/platform_analytics/search'
        className='grid-span-12 my-4'
      />

      <TabHeader
        className='mb-4'
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      {isDataEmpty ? (
        <EmptyStateCard
          title={I18N('title')}
          iconPath={type === 'top' ? womanWithComputerPath : womanCelebratingPath}
          text={I18N('empty')}
          classname='grid-span-12'
        />
      ) : (
        <CardWithPaginatedTable
          title={I18N('title')}
          tableColumns={columns}
          tableData={data}
          classname='grid-span-12'
          isLoading={isLoading}
          showPagination={totalPages > 1}
          page={page}
          totalPages={totalPages}
          onPaginationClick={fetchData}
        />
      )}

    </main>
  )
}

export default SearchQueryResultsPage
