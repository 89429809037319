import React, { useEffect } from 'react'

import { i18nPath, i18nLabelWithTimeZone, i18nMoment } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import classNames from 'classnames'
import currentTimezone from 'utils/currentTimezone'
import DatePicker from 'components/form_fields/datePicker'
import moment from 'moment'

const I18N = i18nPath('views.admin.surveys.scheduling_section')

const SchedulingSection = ({
  className = '',
  workingCopy,
  updateWorkingCopy,
}) => {
  const { startTime, endTime } = workingCopy

  useEffect(() => {
    if (workingCopy && !startTime && !endTime) {
      updateWorkingCopy({
        startTime: moment().format(),
        endTime: moment().add(7, 'days').hour(9).minute(0)
          .second(0)
          .format(),
      })
    }
  }, [!!workingCopy])

  return (
    <Card
      className={classNames('SchedulingSection', className)}
      cardBodyClassName='p-4'
    >
      {i18nLabelWithTimeZone(<label className='required-form-label font-weight-500'>{I18N('start_time_label')}</label>, currentTimezone())}
      <DatePicker
        id='selectStartTime'
        className='w-25 mb-4'
        selected={startTime && moment(startTime)}
        onChange={newStartTime => updateWorkingCopy({ startTime: newStartTime.format() })}
        showTimeSelect
        isClearable={false}
        minDate={moment()}
      />
      {i18nLabelWithTimeZone(<label className='required-form-label font-weight-500 mb-0'>{I18N('end_time_label')}</label>, currentTimezone())}
      <DatePicker
        id='selectEndTime'
        className='w-25 mt-2'
        selected={endTime && moment(endTime)}
        onChange={newEndTime => updateWorkingCopy({ endTime: newEndTime.format() })}
        showTimeSelect
        isClearable={false}
        minDate={startTime && moment(startTime)}
      />
    </Card>
  )
}

export default SchedulingSection
