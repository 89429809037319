import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import RichTextView from 'components/common/richTextView'

const I18N = i18nPath('views.admin.ai.playground')

const copyIconPath = '/images/copyIcon.svg'

const ResultTabContent = ({
  functionCalls = [],
  errorReason,
  result,
  setResult,
  isGenerating,
}) => {
  const isFunctionCallActive = functionCalls.some(({ isActive }) => isActive)
  const isResultEmpty = !result || result.trim() === ''
  const showResult = !isFunctionCallActive && (!isGenerating || !isResultEmpty) && !errorReason

  return (
    <div className='mt-4'>
      {isGenerating && <div className='text-secondary mb-2'>AI is thinking...</div>}
      {functionCalls.map(({
        name, isActive, args = [], simple_output = {},
      }) => (
        <div key={name} className='function-call-container mb-2 p-2'>
          <details>
            <summary>
              {isActive ? 'Executing' : 'Executed'} {name} {isActive && '...'}
            </summary>
            <div className='ml-4'>
              {Object.entries(args).map(([key, value] : any) => (
                <div key={key}>
                  <strong>{key}</strong>: {value?.toString()}
                </div>
              ))}
              {!_.isEmpty(simple_output) && (
                <div><strong>simple_output</strong>: {JSON.stringify(simple_output)}</div>
              )}
            </div>
          </details>
        </div>
      ))}
      {showResult && (
        <>
          <h5>
            {I18N('text_generation_result')}
            <span className='pointer' onClick={() => navigator.clipboard.writeText(result)}>
              <CdnSvg src={copyIconPath} className='CopyIcon ml-2' />
            </span>
          </h5>
          <div className='mt-2 mb-4'>
            <textarea
              className='form-control'
              rows={10}
              value={result}
              readOnly={isGenerating}
              onChange={e => setResult(e.target.value)}
            />
          </div>
          <h5>{I18N('text_generation_rich_text')}</h5>
          <RichTextView
            recordType='text'
            richTextKey='content'
            record={{ content: result }}
          />
        </>
      )}
      {errorReason && (
        <div className='mt-4'>
          <h5>Error generating text</h5>
          <div className='mt-2'>
            <textarea
              className='form-control'
              rows={10}
              value={errorReason}
              readOnly
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ResultTabContent
