import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'components/common/react_select'
import { Link, useHistory, useParams } from 'react-router-dom'

import { groupPath } from 'utils/routeHelpers'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import BackButton from 'components/common/backButton'
import FormErrorList from 'components/errors/formErrorList'
import { LoadingContainer } from 'components/common/loadingContainer'
import ImageSelector from 'components/common/imageSelector'
import MiniLoadingSpinner from 'components/common/miniLoadingSpinner'
import GroupMembershipsEditor from 'components/admin/groupMembershipsEditor'
import { Button } from 'components/common/buttons'
import { showToastMessage } from 'redux/slices/toasts'
import adminGroupSlice, { buildGroupPayload } from 'redux/slices/admin/groups'
import adminGroupTypeSlice from 'redux/slices/admin/groupTypes'
import Card from 'components/common/card'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import VersionsTable from 'components/common/versionsTable'
import SlugInput, { validSlug } from 'components/common/slugInput'
import FormTiptapEditorField from 'components/form_fields/formTiptapEditorField'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import countCharactersInHtmlString from 'utils/countCharactersInHtmlString'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { ChatChannelTypeEnum } from 'types/chat/channel'
import AudienceSelector from 'components/common/audience/audienceSelector'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'
import normalizeTargetingRules, { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.admin.group_editor')

export const MAX_TEXT_LENGTH = 1000

const defaultGroup = {
  id: null,
  name: '',
  description: '',
  parentId: null,
  groupTypeId: null,
  active: true,
  email: '',
  slug: '',
  classification: false,
  source: '',
  external: false,
  slackChannel: null,
  coverImageUrl: '',
  targetingRules: TARGET_ENTIRE_COMPANY,
  autoPopulate: false,
}

const GroupEditorPage = () => {
  const dispatch = useDispatch()
  const { groupDisplayName, pluralGroupDisplayName } = useCurrentCompany()
  const history = useHistory()
  const { groupId } = useParams()

  const targetingOptions = useTargetingOptions()

  const { isLoading, isNotFound, error } = useSelector(adminGroupSlice.selectors.getMetaData())
  const { isLoadingGroupParentOptions } = useSelector(adminGroupSlice.selectors.getGroupParentOptionsMetaData())

  const group = useSelector(adminGroupSlice.selectors.getGroup())
  const groupTypes = useSelector(adminGroupTypeSlice.selectors.getGroupTypes())
  const groupParentOptions = useSelector(adminGroupSlice.selectors.getGroupParentOptions())

  const [workingCopy, setWorkingCopy] = useState(defaultGroup)
  const updateWorkingCopy = change => setWorkingCopy({ ...workingCopy, ...change })
  const changesPresent = !_.isEqual(buildGroupPayload(group), buildGroupPayload(workingCopy))
  const charCount = countCharactersInHtmlString(workingCopy.description)
  const isSaveDisabled = !changesPresent || (groupId !== 'new' && !validSlug(workingCopy.slug)) || charCount > MAX_TEXT_LENGTH
  const isExistingGroup = !!workingCopy.id
  const { source, external: isExternal } = workingCopy
  const showGroupMembershipsEditor = isExistingGroup && !group.classification
  const versions = group.versions || []

  // only allow groups of the same type that are not the same as this group to be selected as parent options
  const parentOptions = groupParentOptions.filter(g => g.id !== workingCopy.id)


  useEffect(() => {
    dispatch(adminGroupTypeSlice.asyncActions.fetchGroupTypes())

    if (groupId === 'new') {
      dispatch(adminGroupSlice.actions.isLoading(false))
    } else {
      dispatch(adminGroupSlice.asyncActions.fetchGroup(groupId))
    }

    return () => { dispatch(adminGroupSlice.actions.clear()) }
  }, [])

  useEffect(() => {
    setWorkingCopy({ ...defaultGroup, ...group })
  }, [group.id, group.updatedAt])

  useEffect(() => {
    dispatch(adminGroupSlice.asyncActions.fetchGroupParentOptions({ groupTypeId: group.groupTypeId }))
  }, [group.id])

  const handleGroupTypeChange = (selectedGroupType) => {
    const groupTypeId = selectedGroupType.id

    dispatch(adminGroupSlice.asyncActions.fetchGroupParentOptions({ groupTypeId }))

    const changedGroup = {
      ...workingCopy,
      groupTypeId,
      groupType: { ...selectedGroupType },
      parentId: null,
    }
    setWorkingCopy(changedGroup)
  }

  const handleParentGroupChange = (selectedParentGroup) => {
    const changedGroup = selectedParentGroup
      ? { ...workingCopy, parentId: selectedParentGroup.id, parent: { ...selectedParentGroup } }
      : { ...workingCopy, parentId: null, parent: null }
    setWorkingCopy(changedGroup)
  }

  const handleActiveChange = (event) => {
    const changedGroup = { ...workingCopy, active: event.target.checked }
    setWorkingCopy(changedGroup)
  }

  const handleClassificationChange = (event) => {
    const changedGroup = { ...workingCopy, classification: event.target.checked }
    setWorkingCopy(changedGroup)
  }

  const generateChangeHandler = fieldName => (e) => {
    const changedGroup = { ...workingCopy, [fieldName]: e.target.value }
    setWorkingCopy(changedGroup)
  }

  const generateTiptapChangeHandler = fieldName => (e) => {
    const changedGroup = { ...workingCopy, [fieldName]: e }
    setWorkingCopy(changedGroup)
  }

  const updateSlackChannel = (slackChannel) => {
    setWorkingCopy({
      ...workingCopy,
      slackChannel,
    })
  }

  const generateImageChangeHandler = fieldName => (image) => {
    const changedGroup = { ...workingCopy, [fieldName]: image }
    setWorkingCopy(changedGroup)
  }

  const saveGroup = () => {
    const haveTargetingRulesChanged = !_.isEqual(
      normalizeTargetingRules(group.targetingRules),
      normalizeTargetingRules(workingCopy.targetingRules)
    )
    const onSuccess = (id) => {
      if (id) {
        history.push(`/admin/teams/${id}`)
      }
      const message = I18N(workingCopy.autoPopulate && haveTargetingRulesChanged ? 'group_updated_with_auto_populate' : 'group_updated', { groupDisplayName })
      dispatch(showToastMessage({ message, type: 'success' }))
    }

    if (workingCopy.id) {
      dispatch(adminGroupSlice.asyncActions.updateGroup(workingCopy, onSuccess))
    } else {
      dispatch(adminGroupSlice.asyncActions.createGroup(workingCopy, onSuccess))
    }
  }

  return (
    <div className='GroupTypeEditorPage'>
      <header className='AdminHeader d-flex justify-content-between flex-row'>
        <h2 className='mb-0'>{I18N('group_editor', { groupDisplayName })}</h2>
        {group && group.id && (
          <div className='AdminButtonContainer'>
            <Link className='mr-3' to={groupPath(group, group.groupType)} target='_blank'>
              <Button>
                {I18N('view_group', { groupDisplayName })}
              </Button>
            </Link>
          </div>
        )}
      </header>

      <LoadingContainer isLoading={isLoading} isNotFound={isNotFound} useCirclesLoadingIndicator>
        <main className='AdminContent'>
          {error && <FormErrorList error={error} />}

          <h6 className='text-secondary align-self-start'>{I18N('group_details', { groupDisplayName })}</h6>
          <div className='row'>
            <div className='col-md-7'>
              <div className='form-group'>
                <div>
                  <label className='required-form-label'>{I18N('name')}</label>
                  <input
                    value={workingCopy.name}
                    onChange={generateChangeHandler('name')}
                    disabled={!!workingCopy.source}
                    data-testid='groupName'
                  />
                </div>
                {isExternal && (
                  <div className='col-md-12 text-secondary text-small mt-1'>{I18N('external_source_helper', { externalSource: source })}</div>
                )}
              </div>

              <FormTiptapEditorField
                label={I18N('description')}
                currentValue={workingCopy.description || ''}
                fieldName='description'
                toolbarItems={SMALL_TOOLBAR}
                editorConfiguration={{
                  ...SIMPLE_EDITOR_CONFIGURATION,
                  characterLimit: MAX_TEXT_LENGTH,
                  placeholder: I18N('placeholder_text', { maxCharacters: MAX_TEXT_LENGTH }),
                }}
                onChange={generateTiptapChangeHandler('description')}
              />
              <div className='CharsRemaining text-muted text-small text-right mt-2'>
                {I18NCommon('characters_remaining', { numCharactersRemaining: MAX_TEXT_LENGTH - charCount })}
              </div>

              {/* TODO: replace or remove this section with group photos when cover image is editable on groupsPage */}
              <div className='form-group'>
                <label>{I18N('cover_image')}</label>
                <ImageSelector placeholderUrl={workingCopy.coverImageUrl} onImageAdded={generateImageChangeHandler('coverImage')} />
                <div className='text-small text-secondary mt-1'>{I18N('cover_image_guidance')}</div>
              </div>
              <div className='w-100' />

              {isExistingGroup && (
                <div className='form-group'>
                  <SlugInput
                    value={workingCopy.slug}
                    onChange={value => updateWorkingCopy({ slug: value })}
                    label={<label>{I18N('slug')}</label>}
                  />
                </div>
              )}

              <div className='form-group'>
                <label>{I18N('email')}</label>
                <input value={workingCopy.email || ''} onChange={generateChangeHandler('email')} />
              </div>

              <div className='form-group'>
                <label>{I18N('slack_channel')}</label>
                <ChatChannelsAsyncSelect
                  types={[ChatChannelTypeEnum.SlackChannel]}
                  value={workingCopy.slackChannel}
                  onChange={updateSlackChannel}
                />
              </div>

              <div className='form-group'>
                <div data-testid='groupTypeWrapper'>
                  <label className='required-form-label'>{I18N('group_type', { groupDisplayName })}</label>
                  <ReactSelect
                    value={groupTypes.find(option => option.id === workingCopy.groupTypeId)}
                    onChange={handleGroupTypeChange}
                    options={groupTypes}
                    isSearchable
                    isClearable={false}
                    getOptionLabel={groupType => groupType.name}
                    getOptionValue={groupType => groupType.id}
                    isDisabled={isExistingGroup}
                  />
                </div>
                <div className='text-secondary text-small mt-1'>{I18N('group_type_cannot_change', { groupDisplayName })}</div>
              </div>

              <div className='form-group'>
                <div>
                  <label>{I18N('parent_group', { groupDisplayName })}</label>

                  {isLoadingGroupParentOptions ? <MiniLoadingSpinner className='d-block ml-5' /> : (
                    <ReactSelect
                      key={`groupParentType-${workingCopy.groupTypeId}`}
                      value={parentOptions.find(option => option.id === workingCopy.parentId)}
                      onChange={handleParentGroupChange}
                      options={parentOptions}
                      isSearchable
                      isClearable
                      getOptionLabel={group => group.nameWithInactiveLabel}
                      getOptionValue={group => group.id}
                      isDisabled={!workingCopy.groupTypeId}
                    />
                  )}
                </div>
                {isExternal && (
                  <div className='text-secondary text-small mt-1'>{I18N('external_source_helper', { externalSource: source })}</div>
                )}
              </div>

              <div className='form-check'>
                <input id='groups-active-checkbox' type='checkbox' checked={workingCopy.active} onChange={handleActiveChange} />
                <label className='checkbox-label form-inline-check' htmlFor='groups-active-checkbox'>{I18N('active')}</label>
              </div>

              <div className='mt-2'>
                <div className='form-group'>
                  <label className='checkbox-label form-check font-weight-bold' htmlFor='groups-classification-checkbox'>
                    <input id='groups-classification-checkbox' type='checkbox' checked={workingCopy.classification} onChange={handleClassificationChange} />
                    {I18N('classification')}
                  </label>
                  <div className='text-secondary text-small'>{I18N('classification_teams', { groupDisplayName: pluralGroupDisplayName.toLowerCase() })}</div>
                </div>
              </div>

              <div className='mt-3'>
                <div className='form-group'>
                  <Button disabled={isSaveDisabled} onClick={saveGroup}>
                    {I18N('save_group', { groupDisplayName })}
                  </Button>
                </div>
              </div>
            </div>
            <div className='col-md-5'>
              <div>
                <div className='form-check'>
                  <input id='auto-populate-checkbox' type='checkbox' checked={workingCopy.autoPopulate} onChange={e => updateWorkingCopy({ autoPopulate: e.target.checked })} />
                  <label className='checkbox-label form-inline-check' htmlFor='auto-populate-checkbox'>{I18N('auto_populate', { groupDisplayName })}</label>
                </div>
                <div className='text-secondary text-small mb-3'>{I18N('auto_populate_helper', { groupDisplayName })}</div>
                {workingCopy.autoPopulate && (
                  <AudienceSelector
                    workingCopy={workingCopy}
                    updateWorkingCopy={updateWorkingCopy}
                    targetingOptions={targetingOptions}
                    isLoading={isLoading}
                    moduleName={null}
                  />
                )}
              </div>
            </div>
          </div>
          {showGroupMembershipsEditor && <GroupMembershipsEditor group={group} />}
          {versions && <VersionsTable versions={versions} />}
        </main>
      </LoadingContainer>
    </div>
  )
}

export default GroupEditorPage
