import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const pickWithTargetingRules = (keys: string[], targetingRulesKey = 'targetingRules') => (params) => {
  const result = _.pick<any, any>(params, keys)

  if (params && params[targetingRulesKey]) {
    result[targetingRulesKey] = normalizeTargetingRules(params[targetingRulesKey])
  }

  return result
}


export default pickWithTargetingRules
