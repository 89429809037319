import React from 'react'

const TextColorMuted = () => (
  <div className='text-muted'>
    Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu.
    Cras consequat.
  </div>
)

export default TextColorMuted
