import LinkForm from 'components/home/widgets/links_widget/linkForm'
import CdnSvg from 'components/common/cdnSvg'
import SuggestedIcon from 'components/common/suggestedIcon'
import React, { useState } from 'react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.links')

type Props = {
  link: any
  onSave: (link: any) => void
  onDelete: (linkId: string) => void
}

const DragHandle = SortableHandle(() => <div className='drag-handle px-1'>⋮⋮</div>)

const LinkListItem = SortableElement<Props>(({
  link, onSave, onDelete,
}: Props) => {
  const isNew = link.id.startsWith('new-')
  const [isExpanded, setIsExpanded] = useState(isNew)

  const handleCancel = () => {
    if (isNew && !link.displayName && !link.displayUrl) {
      onDelete(link.id)
    } else {
      setIsExpanded(false)
    }
  }

  const handleSave = async (link) => {
    await onSave(link)
    setIsExpanded(false)
  }

  return (
    <div className='bg-white p-3 w-100 d-flex gap-2'>
      <DragHandle />
      <div className='flex-grow-1'>
        <div className='LinkPreview d-flex align-items-center'>
          <SuggestedIcon name={link.displayName} url={link.displayUrl} />
          <span className='truncate-text-at-1-lines font-weight-600'>
            {isNew && !link.displayName ? I18N('new_link') : link.displayName}
          </span>
          <div className='PencilIcon pointer ml-auto' onClick={() => setIsExpanded(true)}>
            <CdnSvg src='/images/pencilIcon.svg' />
          </div>
          <div className='XIcon pointer' onClick={() => onDelete(link.id)}>
            <CdnSvg src='/images/xIcon.svg' />
          </div>
        </div>

        {(isExpanded) && (
          <LinkForm
            link={link}
            onSave={handleSave}
            onCancel={handleCancel}
            labelClassName='text-smallest text-secondary'
            saveButtonVariant='secondary'
          />
        )}
      </div>
    </div>
  )
})

export default LinkListItem
