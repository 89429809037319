import React from 'react'
import { i18nPath, i18nFormat, i18nMomentWithTimezone } from 'utils/i18nHelpers'
import AdminBanner from 'components/common/adminBanner'
import { StepType } from 'types/journeys/step'
import StepTriggerNowLink from 'components/admin/journeys/stepTriggerNowLink'
import moment from 'moment'
import currentTimezone from 'utils/currentTimezone'

const I18N = i18nPath('views.admin.journeys.steps.admin_banner')

interface StepAdminBannerProps {
  step: StepType,
  missingVariablesText?: string,
  triggeredText: string,
  errorText: string
}

const StepAdminBanner = ({
  step,
  missingVariablesText = '',
  triggeredText,
  errorText,
} : StepAdminBannerProps) => {
  const isCompleted = step?.completed
  const shouldResetData = step?.shouldResetData
  const isUpcoming = step?.completionStatus === 'upcoming'
  const hasErrorStatus = step?.error
  const triggerAt = step?.triggerAt
  const state = step?.state
  const isScheduledInThePast = state === 'scheduled' && triggerAt && moment(triggerAt).isBefore(moment())

  const timezone = step?.triggerTimezone || currentTimezone()

  const getText = () => {
    if (isCompleted) {
      return shouldResetData ? `${I18N('recipient_changed')} {0}` : triggeredText
    } else if (isScheduledInThePast || state === 'triggering') {
      return `${I18N('triggering_in_a_few_minutes')}. {0}`
    } else if (isUpcoming) {
      return I18N('upcoming', {
        hour: `${i18nMomentWithTimezone({ date: triggerAt, timezone, format: 'hh:mm A' })} (${timezone})`,
        date: i18nMomentWithTimezone({ date: triggerAt, timezone, format: 'll' }),
      })
    } else if (!_.isEmpty(step?.missingVariables || [])) {
      return missingVariablesText
    } else {
      return errorText
    }
  }

  const getColor = () => {
    if (isCompleted) {
      return shouldResetData ? 'orange' : 'green'
    } else if (isUpcoming) {
      return 'yellow'
    } else {
      return 'red'
    }
  }

  const toUserFullName = step?.toUser?.preferredFullName

  const linkText = () => {
    if (isCompleted) {
      return shouldResetData ? I18N('trigger_for_new_recipient') : I18N('trigger_again_for', { toUserFullName })
    }

    return I18N('trigger_now')
  }


  if (isCompleted || hasErrorStatus || isUpcoming) {
    return (
      <AdminBanner
        className='StepAdminBanner py-3 w-100 d-inline-flex align-items-center justify-content-center'
        color={getColor()}
      >
        {i18nFormat(getText(),
          <StepTriggerNowLink
            step={step}
            linkText={linkText()}
            shouldResetData={shouldResetData}
          />
        )}
      </AdminBanner>
    )
  }

  return null
}


export default StepAdminBanner
