import { Button } from 'components/common/buttons'
import CelebrationModalContext from 'components/celebrations/greeting_card_actions/celebrationModalContext'
import useCanSignCelebration from 'components/celebrations/hooks/useCanSignCelebration'
import useTrackGreetingCardEvent from 'components/celebrations/hooks/useTrackGreetingCardEvent'
import SignGreetingCardModal from 'components/celebrations/signGreetingCardModal'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.celebrations.greeting_card')
export const SIGN_CARD_MODAL = 'sign_card'

const SignCardButton = ({
  celebration, legacy = true,
}) => {
  const {
    setCurrentOpenModal, currentOpenModal, onCardSigned, closeModal,
  } = React.useContext(CelebrationModalContext)

  const canSignCelebration = useCanSignCelebration(celebration)
  const showButton = canSignCelebration && !celebration.hasCommented
  const name = celebration.user.preferredFullName

  const trackGreetingCardEvent = useTrackGreetingCardEvent()

  const onClick = () => {
    trackGreetingCardEvent('greeting_card_sign_card_click', celebration)
    setCurrentOpenModal(SIGN_CARD_MODAL)
  }

  if (!showButton) return null

  return (
    <>
      <Button
        onClick={onClick}
        className='GreetingCardActionButton'
      >
        {legacy ? (
          I18N(`${celebration.type}.sign_the_card`, { name })
        ) : (
          I18N('sign_the_card')
        )}
      </Button>

      {currentOpenModal === SIGN_CARD_MODAL && (
        <SignGreetingCardModal
          celebration={celebration}
          visible
          onCommentCreated={onCardSigned}
          onClose={closeModal}
        />
      )}
    </>
  )
}

export default SignCardButton
