import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { LoadingContainer } from 'components/common/loadingContainer'
import FormErrorList from 'components/errors/formErrorList'
import { Button } from 'components/common/buttons'
import companiesSlice from 'redux/slices/companies'
import CreatableMultiSelect from 'components/form_fields/creatableMultiSelect'
import { useHistory, useParams } from 'react-router-dom'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CompanyStatusSelector from 'pages/admin/companies/companyStatusSelector'
import CompanyTypeSelector from 'pages/admin/companies/companyTypeSelector'

const I18N = i18nPath('views.admin.companies')

const CompanyEditorPage = () => {
  const dispatch = useDispatch()
  const { companyId } = useParams()
  const history = useHistory()
  const { permissions: { clearyAdmin } } = useCurrentUser()

  const {
    workingCopy,
    company,
    isDeleting,
    error,
    isSaving,
    isLoading,
    isNotFound,
  } = useSelector(state => state.companies)

  const environment = useSelector(state => state.environment)

  const isNewCompany = companyId === 'new'

  useEffect(() => {
    if (companyId === 'new') {
      dispatch(companiesSlice.actions.setNewCompany({
        status: !clearyAdmin ? 'trial' : 'active',
      }))
    } else {
      dispatch(companiesSlice.asyncActions.fetchCompany(companyId))
    }

    return () => dispatch(companiesSlice.actions.clearCompany())
  }, [])

  const generateChangeHandler = fieldName => (e) => {
    const changedCompany = { ...workingCopy, [fieldName]: e.target.value }
    dispatch(companiesSlice.actions.updateCompanyWorkingCopy(changedCompany))
  }

  const generateCheckboxChangeHandler = fieldName => (e) => {
    const changedCompany = { ...workingCopy, [fieldName]: e.target.checked }
    dispatch(companiesSlice.actions.updateCompanyWorkingCopy(changedCompany))
  }
  const directHandler = fieldName => (value) => {
    const changedCompany = { ...workingCopy, [fieldName]: value }
    dispatch(companiesSlice.actions.updateCompanyWorkingCopy(changedCompany))
  }

  const saveCompany = () => {
    if (workingCopy.id) {
      dispatch(companiesSlice.asyncActions.updateCompany(workingCopy))
    } else {
      dispatch(companiesSlice.asyncActions.createCompany(workingCopy, history))
    }
  }

  const deleteCompany = () => {
    if (confirm(I18N('delete_confirm'))) {
      dispatch(companiesSlice.asyncActions.destroyCompany(company))
    }
  }

  const renderCompanyDeleteForm = () => {
    const shouldRenderDelete = environment !== 'production'

    return (
      <div className='container my-4'>
        <div className='row'>
          <div className='col'>
            <h1>
              {' '}
              <a
                className='text-primary-link'
                href={`${company.rootUrl}/admin/cleary_admin`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {' '}
                {company.name} {I18N('company')}
              </a>
            </h1>
          </div>
        </div>

        <hr />

        <div className='row'>
          <div className='col-12'>
            <div className='my-3'>
              {shouldRenderDelete && (
                <Button variant='danger' disabled={isDeleting} onClick={deleteCompany} showLoadingSpinner={isDeleting}>
                  {' '}
                  {I18N('delete')}{' '}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderEditableField = (fieldName, isRequired) => (
    <div className='form-group col-md-12'>
      <label className={classNames(isRequired && 'required-form-label')}>{I18N(fieldName)}</label>
      <input
        value={workingCopy[_.camelCase(fieldName)]}
        onChange={generateChangeHandler(_.camelCase(fieldName))}
      />
      <small className='form-text text-muted'>{I18N(`${fieldName}_label`)}</small>
    </div>
  )

  const generateDemoPasswordValue = (subdomain) => {
    const suggestedPasswordBase = `${subdomain}&cleary`
    const randomSuffix = Math.random().toString(36).substr(2, 3)

    return `${suggestedPasswordBase}&${randomSuffix}`
  }

  const generateSubdomainChangeHandler = (e) => {
    const subdomain = e.target.value

    const changedCompany = {
      ...workingCopy,
      subdomain,
      demoPassword: generateDemoPasswordValue(subdomain),
    }

    dispatch(companiesSlice.actions.updateCompanyWorkingCopy(changedCompany))
  }

  const renderCompanyForm = () => {
    const changesPresent = !_.isEqual(company, workingCopy)
    // eslint-disable-next-line max-len
    const isMissingRequiredFields = !workingCopy.name || !workingCopy.appName || !workingCopy.subdomain || !workingCopy.emailLoginDomains
    const shouldDisableCreateButton = isMissingRequiredFields || !changesPresent || isSaving

    return (
      <div className='container my-4'>
        <div className='row'>
          <div className='col'>
            <h1>{I18N('company_editor')}</h1>
            {error && <FormErrorList error={error} />}
          </div>
        </div>

        <hr />

        <div className='row'>
          <div className='col-md-7'>
            {renderEditableField('name', true)}

            {renderEditableField('app_name', true)}

            <div className='form-group col-md-12'>
              <label className='required-form-label'>{I18N('subdomain')}</label>
              <input
                pattern='[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?'
                value={workingCopy.subdomain}
                onChange={generateSubdomainChangeHandler}
              />
              <small className='form-text text-muted'>{I18N('subdomain_label')}</small>
            </div>

            <div className='form-group col-md-12'>
              <label className='required-form-label'>{I18N('email_login_domains')}</label>
              <CreatableMultiSelect
                id='cy_email_login_domains'
                name='emailLoginDomains'
                values={workingCopy.emailLoginDomains}
                onChange={generateChangeHandler('emailLoginDomains')}
                placeholder={I18N('email_domains_placeholder')}
              />
              <div className='text-small text-secondary'>{I18N('email_login_domains_helper')}</div>
            </div>

            {workingCopy.subdomain && (
              <div className='form-group col-md-12'>
                <label className='required-form-label'>{I18N('demo_password')}</label>
                <input
                  value={workingCopy.demoPassword}
                  onChange={generateChangeHandler('demoPassword')}
                />
              </div>
            )}

            {isNewCompany && (
              <div className='form-group col-md-12 my-4'>
                <div className='form-check'>
                  <input
                    id='create_onboarding_templates_checkbox'
                    type='checkbox'
                    name='create_onboarding_templates'
                    onChange={generateCheckboxChangeHandler('createOnboardingTemplates')}
                  />
                  <label htmlFor='create_onboarding_templates_checkbox' className='checkbox-label form-inline-check'>
                    {I18N('create_onboarding_templates')}
                  </label>
                </div>
                <div className='text-small text-secondary'>{I18N('create_onboarding_templates_helper_text')}</div>
              </div>
            )}

            <div className='form-group col-md-12 my-4'>
              <CompanyStatusSelector
                value={workingCopy.status}
                onChange={directHandler('status')}
              />

              <p className='text-small text-secondary mb-0'>
                {I18N('trial_helper')}
              </p>

              {isNewCompany && !clearyAdmin && (
                <p className='text-small text-secondary'>
                  {I18N('trial_creation_helper')}
                </p>
              )}
            </div>

            <div className='form-group col-md-12'>
              <label className='required-form-label'>{I18NCommon('type')}</label>

              <CompanyTypeSelector
                value={workingCopy.type}
                onChange={directHandler('type')}
              />
            </div>

            <div className='w-100' />

            <div className='col-12'>
              <Button disabled={shouldDisableCreateButton} onClick={saveCompany}>
                {' '}
                {I18N('create_company')}{' '}
              </Button>
            </div>
          </div>

          <div className='col-md-4'>
            <h2 className='mt-4'>Default Login Credentials</h2>
            <div>
              <h4>Cleary Admin Login</h4>
              <div>
                <b>username:</b> clearyadmin@
                {workingCopy.subdomain}
                .com
              </div>
            </div>
          </div>

          <div className='w-100' />
        </div>
      </div>
    )
  }

  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      {isNewCompany ? renderCompanyForm : renderCompanyDeleteForm}
    </LoadingContainer>
  )
}

export default CompanyEditorPage
