/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import LoadingSpinner from 'components/common/loadingSpinner'
import Picture from 'components/common/images/picture'
import CoreFields from 'components/profiles/coreFields'

import profileSlice from 'redux/slices/profiles'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import PictureList from 'components/common/images/pictureList'
import PhotosSlider from 'components/common/images/photosSlider'
import ContactInfo from 'components/profiles/contact_info'
import Tenure from 'components/profiles/tenure'
import AboutMe from 'components/profiles/aboutMe'
import useCurrentUser from 'components/common/hooks/useCurrentUser'


const ProfilePage = () => {
  const dispatch = useDispatch()
  const { username } = useParams()
  const currentCompany = useCurrentCompany()
  const currentUser = useCurrentUser()

  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState(false)
  const [modalPhotos, setModalPhotos] = useState([])

  const { isLoading, isNotFound } = useSelector(profileSlice.selectors.getMetaData())
  const user = useSelector(profileSlice.selectors.getCurrentProfileUser()) ?? {}
  const isCurrentUser = currentUser.id === user.id
  const { defaultPhotoUrl } = user

  const canEdit = user.permittedActions?.edit
  const primaryPicture = _.find(user.photos, { primary: true })
  const otherPictures = _.filter(user.photos, { primary: false })
  const preboardingFieldSettings = {
    ...currentCompany.settings.profile.fields,
    email: false,
    calendar: false,
  }

  useEffect(() => {
    dispatch(profileSlice.asyncActions.fetchProfile(username))

    return function cleanup() {
      dispatch(profileSlice.actions.clearProfile())
    }
  }, [username])

  if (isLoading || isNotFound || _.isEmpty(user)) {
    return <LoadingSpinner />
  }

  const openModal = (currentPhoto, photos) => {
    // Prevent the modal to open if currentPhoto is not a real photo object
    if (!currentPhoto?.id) return

    const modalPhotos = [currentPhoto, ...photos.filter(photo => photo.id !== currentPhoto.id)]

    setModalPhotos(modalPhotos)
    setIsPhotosModalOpen(true)
  }

  return (
    <main className='NewProfilePage PreboardingProfilePage'>
      <section className='user-info-grid'>
        <Picture
          owner={user}
          picture={primaryPicture}
          defaultPhotoUrl={defaultPhotoUrl}
          className='PrimaryPhoto'
          onClick={() => openModal(primaryPicture, otherPictures)}
        />

        <PictureList owner={user} pictures={otherPictures} onClick={openModal} />

        <CoreFields
          canEdit={canEdit}
          fieldSettings={preboardingFieldSettings}
          user={user}
        />

        {isCurrentUser && (
          <ContactInfo
            canEdit={canEdit}
            fieldSettings={preboardingFieldSettings}
            user={user}
          />
        )}
      </section>

      {isCurrentUser && (
        <section className='card-grid'>
          <Tenure
            user={user}
            fieldSettings={preboardingFieldSettings}
          />

          <AboutMe
            user={user}
            currentCompany={currentCompany}
            canEdit={canEdit}
            showUserSkills={false}
          />
        </section>
      )}

      {isPhotosModalOpen && (
        <PhotosSlider
          owner={user}
          closeModal={() => setIsPhotosModalOpen(false)}
          modalPhotos={modalPhotos}
        />
      )}
    </main>
  )
}

export default ProfilePage
