import React, { useState } from 'react'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const pencilIconPath = '/images/pencilIcon.svg'

const EditableSelectContent = ({
  canEdit = false,
  displayElement,
  saveContent,
  inputElement,
  valueToEdit = null,
  className = '',
  saveAfterChange = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentValue, setCurrentValue] = useState(valueToEdit)
  const isEmpty = !currentValue || !valueToEdit

  const onClickDisplayElement = () => {
    if (canEdit) {
      setIsEditing(true)
    }
  }

  const save = () => {
    saveContent(currentValue).then(() => setIsEditing(false))
  }

  const onBlur = () => {
    save()
  }

  const onKeyUp = (e) => {
    if (e.key === 'Escape') {
      setIsEditing(false)
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      save()
    }
  }

  const onChange = (e) => {
    setCurrentValue(e)

    if (saveAfterChange) {
      saveContent(e).then(() => setIsEditing(false))
    }
  }

  if (!isEditing) {
    return (
      <span
        className={classNames('EditableContent-Display', { canEdit, isEmpty }, className)}
        onClick={onClickDisplayElement}
      >
        {displayElement}
        <CdnSvg src={pencilIconPath} className='pencilIcon ml-1' />
      </span>
    )
  }

  return (
    <>
      <span className={classNames('EditableContent', className)}>
        {React.cloneElement(inputElement, {
          autoFocus: true,
          value: currentValue,
          onBlur,
          onKeyUp,
          onKeyPress,
          onChange,
        })}
      </span>
    </>
  )
}

export default EditableSelectContent
