import { useMemo } from 'react'
import TargetingRulesType from 'types/audience/targetingRules'

// This will remove any targetingRules that doesnt have value set
// plus returning undefined if there is no rule set and no users selected
// to avoid api calls with blank or missing values in the targeting rules
const useCompletedTargetingRules = (targetingRules: TargetingRulesType) => {
  const validRules = targetingRules.rules.filter(r => (r.values?.length || 0) > 0)
  const hasUsers = (targetingRules.users?.length || 0) > 0

  const validRulesJson = JSON.stringify(validRules)
  const usersJson = JSON.stringify(targetingRules.users)

  return useMemo(() => {
    if (validRules.length === 0 && !hasUsers) {
      return undefined
    }
    return targetingRules
  }, [validRulesJson, usersJson])
}

export default useCompletedTargetingRules
