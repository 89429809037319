import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import EditableContactFields from 'components/profiles/editableContactFields'
import WhereToFindMe from 'components/profiles/where_to_find_me'
import ChatUsername from 'components/common/chatUsername'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.profile')

const envelopeCircleIconPath = '/images/envelopeCircleIcon.svg'
const googleCalendarCircleIconPath = '/images/googleCalendarCircleIcon.svg'
const envelopeOutlineIconPath = '/images/envelopeOutlineIcon.svg'

const ContactInfo = ({
  canEdit,
  fieldSettings,
  user,
}) => {
  const {
    email, calendarEventUrl, primaryChatUsers,
  } = user

  const visibleChatUsers = _.sortBy(primaryChatUsers, 'type').filter((u) => {
    if (u.type === 'Chat::SlackUser' && fieldSettings.slack) {
      return true
    }
    if (u.type === 'Chat::MsTeamUser' && fieldSettings.msTeam) {
      return true
    }

    return false
  })

  return (
    <section className='ProfileContactInfo'>
      <div className='ActionButtons'>
        {fieldSettings.email && email && (
          <a
            href={`mailto:${email}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <CdnSvg src={envelopeCircleIconPath} />
          </a>
        )}
        {fieldSettings.calendar && calendarEventUrl && (
          <a
            href={calendarEventUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <CdnSvg src={googleCalendarCircleIconPath} />
          </a>
        )}
      </div>
      <h6 className='m-0'>{I18N('contact_info.header')}</h6>
      {fieldSettings.email && email && (
        <div className='mt-3 d-flex'>
          <CdnSvg src={envelopeOutlineIconPath} className='EmailIcon mr-2' />
          {email}
        </div>
      )}
      {visibleChatUsers.map(u => (
        <div className='mt-3 d-flex' key={u.id}>
          <ChatUsername chatUser={u} iconClass='w-18 mr-2' />
        </div>
      ))}
      <EditableContactFields
        user={user}
        fieldSettings={fieldSettings}
        canEdit={canEdit}
      />
      <WhereToFindMe
        canEdit={user.permittedActions?.edit}
        fieldSettings={fieldSettings}
        user={user}
      />
    </section>
  )
}

export default ContactInfo
