import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Modal from 'components/common/modal'
import ReactSelect from 'components/common/react_select'
import CancelButton from 'components/common/cancelButton'
import { Button } from 'components/common/buttons'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import { isCorrespondentValid } from 'utils/admin/journeys/isStepTemplateValid'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { present } from 'components/common/utils'
import CorrespondentType from 'types/journeys/correspondent'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.settings.delete_correspondent_modal')

interface DeleteCorrespondentModalProps {
  visible: boolean
  closeModal: () => void
  correspondent: CorrespondentType
  deleteCorrespondent: (...args: any[]) => void
  journeyCorrespondents: CorrespondentType[]
  isDeleting: boolean
}

const DeleteCorrespondentModal = ({
  visible,
  closeModal,
  correspondent,
  deleteCorrespondent,
  journeyCorrespondents,
  isDeleting,
}: DeleteCorrespondentModalProps) => {
  const { groupDisplayName } = useCurrentCompany()
  const { id, stepTemplatesCount } = correspondent
  const [selectedCorrespondent, setSelectedCorrespondent] = useState<any>({ role: null, user: null, group: null })
  const isNewCorrespondentValid = isCorrespondentValid(selectedCorrespondent)

  const { data: stepTemplates = [], isLoading } = useFetch(
    () => API.admin.stepTemplates.fetchAll({ toCorrespondentId: id }),
    [id]
  )

  const roleOptions = [
    { value: 'journey_admin', label: I18N('journey_admin') },
    { value: 'manager', label: I18N('manager') },
    { value: 'specific_employee', label: I18N('specific_employee') },
    journeyCorrespondents.length > 1 && { value: 'specific_group', label: groupDisplayName },
  ].filter(present)

  const groupOptions = journeyCorrespondents.filter(jc => jc.id !== correspondent.id).map(jc => jc.group)

  const onDeleteClick = () => {
    const { role, user, group } = selectedCorrespondent
    const params = { role, userId: user?.id, groupId: group?.id }
    deleteCorrespondent(id, params)
  }

  return (
    <Modal
      visible={visible}
      toggle={closeModal}
    >
      <div className='d-flex flex-column align-items-center'>
        <h4>{I18N('title', { groupDisplayName })}</h4>

        <p className='text-secondary text-small text-center'>{I18N('subtitle', { groupDisplayName, count: stepTemplatesCount })}</p>
        {isLoading && <CirclesLoadingIndicator />}
        {stepTemplates.map(stepTemplate => (
          <Link to={stepTemplate.adminDisplayPath} key={stepTemplate.id}>
            {stepTemplate.name}
          </Link>
        ))}

        <div className='w-50 my-3'>
          <label className='text-secondary text-smallest'>{I18N('replace_with')}</label>
          <ReactSelect
            options={roleOptions}
            onChange={option => setSelectedCorrespondent({ role: option.value, user: null, group: null })}
            value={roleOptions.find(option => option.value === selectedCorrespondent.role)}
          />

          {selectedCorrespondent.role === 'specific_employee' && (
            <EmployeeSearch
              className='mt-2'
              selectedEmployee={selectedCorrespondent.user}
              onChange={user => setSelectedCorrespondent({ ...selectedCorrespondent, user })}
            />
          )}

          {selectedCorrespondent.role === 'specific_group' && (
            <ReactSelect
              options={groupOptions}
              getOptionValue={group => group.id}
              getOptionLabel={group => group.name}
              className='mt-2'
              onChange={group => setSelectedCorrespondent({ ...selectedCorrespondent, group })}
              value={selectedCorrespondent.group}
            />
          )}
        </div>

        <div className='d-flex align-items-center'>
          <CancelButton onClick={closeModal} />
          <Button
            variant='danger'
            onClick={onDeleteClick}
            className='ml-2'
            disabled={!isNewCorrespondentValid || isDeleting}
            showLoadingSpinner={isDeleting}
          >
            {I18N('delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteCorrespondentModal
