import React, { useEffect, useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'

import { Button } from 'components/common/buttons'
import ProgressiveImage from 'components/common/images/progressiveImage'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import DeleteModal from 'components/common/modals/deleteModal'
import AddCoverButton from 'components/common/addCoverButton'
import readFile from 'utils/readFile'
import { useDispatch, useSelector } from 'react-redux'
import InlineImageCrop from 'components/common/image_crop/inlineImageCrop'
import FileSelectDropzone from 'components/common/file_select_dropzone'

const I18N = i18nPath('views.cover_image')

const useReposition = ({
  slice, id, coverImageCroppedArea, uploadCoverImage,
  coverImageFileProp, setCoverImageFileProp, options,
}) => {
  const dispatch = useDispatch()
  const [tempCoverImageSrc, setTempCoverImageSrc] = useState(null)
  const [isRepositioning, setIsRepositioning] = useState(false)
  const [defaultFile, defaultSetFile] = useState(null)

  // if coverImageFileProp and setCoverImageFileProp are not provided,
  // we will use the defaultFile and defaultSetFile to store the file
  const file = setCoverImageFileProp ? coverImageFileProp : defaultFile
  const setFile = setCoverImageFileProp || defaultSetFile

  const handleFileSelection = async (files) => {
    if (files && files.length > 0) {
      const file = files[0]
      setFile(file)
    }
  }

  useEffect(() => {
    const loadFile = async () => {
      if (file) {
        const imageDataUrl = await readFile(file)
        setTempCoverImageSrc(imageDataUrl)
      } else {
        setTempCoverImageSrc(null)
      }
    }

    loadFile()
  }, [file])

  const onCancel = () => {
    setFile(null)
  }

  const onCropSave = async ({ croppedAreaPixels }) => {
    await uploadCoverImage(file, { croppedAreaPixels })

    setFile(null)
  }

  // display the cover image fully responsive when its cropped
  // legacy cover images should work with fixed height
  // since they can be in any proportion
  const isResponsiveCoverImage = !_.isEmpty(coverImageCroppedArea)
  const onRepositionSave = async ({ croppedAreaPixels }) => {
    await dispatch(slice.asyncActions.repositionCoverImage(id, croppedAreaPixels, options))

    setIsRepositioning(false)
  }

  return {
    tempCoverImageSrc,
    isRepositioning,
    setIsRepositioning,
    onCancel,
    onCropSave,
    handleFileSelection,
    onRepositionSave,
    isResponsiveCoverImage,
  }
}


const CoverImageWidget = ({
  className,
  coverImageUrl,
  showActions = true,
  coverImageGuidanceText = null,
  withReposition = false,
  slice,
  id,
  coverImageCroppedArea,
  originalCoverImageUrl,
  defaultCropParams,
  showAddCoverImageButton = true,
  handleRemoveCoverImage: handleRemoveCoverImageProp,
  coverImageFile: coverImageFileProp,
  setCoverImageFile: setCoverImageFileProp,
  addCoverButtonClassName,
  revealOnHover = false,
  showFullWidth = false,
  options = {},
}) => {
  const dispatch = useDispatch()

  const uploadCoverImage = async (file, attrs = {}) => {
    await dispatch(slice.asyncActions.uploadCoverImage({
      modelId: id,
      file,
      ...attrs,
      options,
    }))
  }

  const { isUploading, isRemoving } = useSelector(slice.selectors.getCoverImageMetaData())

  const {
    tempCoverImageSrc,
    isRepositioning,
    setIsRepositioning,
    onCancel,
    onCropSave,
    handleFileSelection,
    onRepositionSave,
    isResponsiveCoverImage,
  } = useReposition({
    slice, id, coverImageCroppedArea, uploadCoverImage, coverImageFileProp, setCoverImageFileProp, options,
  })

  const defaultHandleRemoveCoverImage = () => {
    dispatch(slice.asyncActions.removeCoverImage(id, options))
  }

  const handleRemoveCoverImage = handleRemoveCoverImageProp || defaultHandleRemoveCoverImage

  const [showRemoveCoverImageModal, setShowRemoveCoverImageModal] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [fileDialogOpen, setFileDialogOpen] = useState(false)

  const editText = coverImageUrl ? I18N('edit_cover_image') : I18N('upload_cover_image')
  const deleteText = I18N('delete_cover_image')

  const showActionsOnHoverOrDialogOpen = isHovered || fileDialogOpen || isUploading

  const handleUploadCoverImage = (files) => {
    if (withReposition) {
      handleFileSelection(files)
    } else {
      uploadCoverImage(files[0])
    }
  }

  const handleConfirmRemoveCoverImage = () => {
    handleRemoveCoverImage()
    setShowRemoveCoverImageModal(false)
  }

  if (tempCoverImageSrc) {
    return (
      <InlineImageCrop
        {...defaultCropParams}
        imageSrc={tempCoverImageSrc}
        onCancel={onCancel}
        onSave={onCropSave}
      />
    )
  }

  if (isRepositioning) {
    return (
      <InlineImageCrop
        {...defaultCropParams}
        imageSrc={originalCoverImageUrl}
        initialCroppedArea={coverImageCroppedArea}
        onCancel={() => setIsRepositioning(false)}
        onSave={onRepositionSave}
      />
    )
  }

  const setIsHoveredThrottled = _.throttle(setIsHovered, 1000, { trailing: false })

  const showCoverImageWidget = !coverImageUrl && showAddCoverImageButton && showActions

  return (
    <div
      className={classNames('CoverImageWidget position-relative', {
        isUploading, hasCoverImage: coverImageUrl, isResponsiveCoverImage, showFullWidth,
      }, className)}
      onMouseMove={() => setIsHoveredThrottled(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {showCoverImageWidget && (
        <div className={!showActionsOnHoverOrDialogOpen && revealOnHover ? 'invisible my-1' : revealOnHover && 'my-1'}>
          <AddCoverButton
            className={classNames(addCoverButtonClassName, showFullWidth)}
            isUploading={isUploading}
            handleUploadCoverImage={handleUploadCoverImage}
          />
          {!revealOnHover && coverImageGuidanceText}
        </div>
      )}

      <ProgressiveImage src={coverImageUrl} />

      {coverImageUrl && showActionsOnHoverOrDialogOpen && (
        <>
          <div className={classNames('actions position-absolute p-4 d-flex justify-content-between d-md-block')}>
            {showActions && (
              <>
                <FileSelectDropzone
                  data-testid='cy_dropzone_file_selector'
                  disabled={isUploading}
                  onDrop={handleUploadCoverImage}
                  className='btn btn-primary mr-3'
                  onFileDialogCancel={() => setFileDialogOpen(false)}
                  onClick={() => setFileDialogOpen(true)}
                >
                  {!isUploading && editText}
                  {isUploading && <CirclesLoadingIndicator />}
                </FileSelectDropzone>
                {withReposition && (
                  <Button className='mr-3' onClick={() => setIsRepositioning(true)}>{I18N('reposition_image')}</Button>
                )}
                <Button onClick={() => setShowRemoveCoverImageModal(true)} disabled={isRemoving}>
                  {!isRemoving && deleteText}
                  {isRemoving && <CirclesLoadingIndicator />}
                </Button>
              </>
            )}
          </div>
        </>
      )}

      <DeleteModal
        className='RemoveCoverImageModal'
        deleteText={I18N('remove_cover_image')}
        cancelText={I18N('cancel')}
        deleteSecondaryText={I18N('confirm_remove_text')}
        deleteConfirmText={I18N('confirm')}
        showDeleteModal={showRemoveCoverImageModal}
        closeDeleteModal={() => setShowRemoveCoverImageModal(false)}
        deleteConfirm={handleConfirmRemoveCoverImage}
      />
    </div>
  )
}

export default CoverImageWidget
