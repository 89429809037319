import SingleStatTile from 'components/analytics/common/singleStatTile'
import getTargetedDescriptionKey from 'components/analytics/helpers/getTargetedDescriptionKey'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import engagementAnalyticsSlice from 'redux/slices/analytics/engagement'
import TargetingRulesType from 'types/audience/targetingRules'
import { toPercentage } from 'utils/analytics'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.overview')

const BUCKET = 'overview'

interface OverviewSummaryProps {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const OverviewSummary = ({
  periodStart,
  periodEnd,
  targetingRules,
}: OverviewSummaryProps) => {
  const dispatch = useDispatch()
  const adoption = useSelector(engagementAnalyticsSlice.selectors.getAdoption())
  const { isLoading } = useSelector(engagementAnalyticsSlice.selectors.getMeta('adoption'))

  const activityCount = useSelector(engagementAnalyticsSlice.selectors.getActivityCount(BUCKET))
  const {
    isLoading: isLoadingActivityCount,
  } = useSelector(engagementAnalyticsSlice.selectors.getActivityCountMeta(BUCKET))

  useEffect(() => {
    dispatch(engagementAnalyticsSlice.asyncActions.admin.fetchActivityCount(
      'overview',
      { periodStart, periodEnd, targetingRules }
    ))
  }, [periodStart, periodEnd, targetingRules])


  const loginPercentage = toPercentage(
    adoption.consumersCount + adoption.contributorsCount === 0 ? 0 : (1 - adoption.noUsagePercent)
  )
  const articleEmailReadPercentage = toPercentage(adoption.usersReadArticleEmailPercent)
  const weeklyActiveUsagePercentage = toPercentage(adoption.weeklyActivityAverageUsersPercent)

  const hasTargetingSelected = !!targetingRules

  const uniqueUsersDescriptionKey = getTargetedDescriptionKey('unique_users', hasTargetingSelected)
  const loginDescriptionKey = getTargetedDescriptionKey('login_description', hasTargetingSelected)
  const emailReadDescriptionKey = getTargetedDescriptionKey('email_read_description', hasTargetingSelected)
  const weeklyUsageDescriptionKey = getTargetedDescriptionKey('weekly_usage_description', hasTargetingSelected)

  return (
    <>
      <SingleStatTile
        value={activityCount}
        description={I18N(uniqueUsersDescriptionKey)}
        isLoading={isLoadingActivityCount}
      />
      <SingleStatTile
        value={loginPercentage}
        description={I18N(loginDescriptionKey)}
        isLoading={isLoading}
      />

      <SingleStatTile
        value={articleEmailReadPercentage}
        description={I18N(emailReadDescriptionKey)}
        isLoading={isLoading}
      />
      <SingleStatTile
        value={weeklyActiveUsagePercentage}
        description={I18N(weeklyUsageDescriptionKey)}
        isLoading={isLoading}
      />
    </>
  )
}


export default OverviewSummary
