import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { i18nPath } from 'utils/i18nHelpers'

import userImportSlice from 'redux/slices/userImports'
import { Button } from 'components/common/buttons'
import LoadingSpinnerText from 'components/common/loadingSpinnerText'
import Tooltip from 'components/common/clearyTooltip'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import FileSelectDropzone from 'components/common/file_select_dropzone'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.user_imports_list')

const infoIconPath = '/images/infoIcon.svg'

const CsvImportButton = ({
  hasPendingOrRunningImport, isSyncSaving, className, isPreboardingImport,
}) => {
  const dispatch = useDispatch()
  const isDisabled = hasPendingOrRunningImport || isSyncSaving
  const [dateFormat, setDateFormat] = useState('%Y-%m-%d')

  const handleCSVDrop = (file) => {
    const reader = new FileReader()
    reader.onload = () => {
      dispatch(userImportSlice.asyncActions.admin.createOrFetchUserImport('csv', { file: file[0], preboarding: isPreboardingImport, dateFormat }))
    }

    reader.readAsDataURL(file[0])
  }

  const buttonText = () => {
    if (hasPendingOrRunningImport) {
      return I18N('user_import_import_active')
    }

    return isPreboardingImport ? I18N('upload_preboarding_csv') : I18N('upload_csv')
  }

  const dateFormatOptions = [
    { value: '%Y-%m-%d', label: 'YYYY-MM-DD' },
    { value: '%Y%m%d', label: 'YYYYMMDD' },
    { value: '%d-%m-%Y', label: 'DD-MM-YYYY' },
    { value: '%d/%m/%Y', label: 'DD/MM/YYYY' },
    { value: '%m-%d-%Y', label: 'MM-DD-YYYY' },
    { value: '%m/%d/%Y', label: 'MM/DD/YYYY' },
  ]

  return (
    <>
      <div className='d-flex DateFormatPicker'>
        <Tooltip
          placement='bottom'
          className='SelectDateTooltipContainer'
          tooltipClassName='mt-2'
          content={(
            <span><CdnSvg className='InfoIcon mr-1' src={infoIconPath} /></span>
          )}
        >
          <span className='ml-1'>{I18N('date_format_tooltip')}</span>
        </Tooltip>
        <label htmlFor='dateFormat' className='text-nowrap mr-2'>
          {I18N('date_format')}
        </label>
      </div>
      <select id='dateFormat' className='form-control mr-2' onChange={e => setDateFormat(e.target.value)}>
        {dateFormatOptions.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
      <FileSelectDropzone
        data-testid='cy_user_import_dropzone'
        onDrop={handleCSVDrop}
        maxSizeInMB={100}
        disabled={isDisabled}
        className={classNames('btn btn-primary', isDisabled && 'btn-primary-disabled', className)}
        accept='.csv'
      >
        <LoadingSpinnerText showLoadingSpinner={isSyncSaving}>
          {buttonText()}
        </LoadingSpinnerText>
      </FileSelectDropzone>
    </>
  )
}

const IntegrationSyncButton = ({
  hasPendingOrRunningImport,
  isSyncSaving,
  isPreboardingEnabled,
  integrationName,
  type = 'hris',
  className = '',
  includeOnlyModifiedOption = false,
}) => {
  const dispatch = useDispatch()
  const isDisabled = hasPendingOrRunningImport || isSyncSaving

  const [isOnlyModified, setIsOnlyModified] = useState(true)

  const syncUsers = () => {
    dispatch(userImportSlice.asyncActions.admin.createOrFetchUserImport(type, { isOnlyModified }))
  }

  const buttonText = () => {
    if (hasPendingOrRunningImport) {
      return I18N('user_import_sync_active')
    }

    return isPreboardingEnabled ? I18N('user_import_sync_integration', { integrationName }) : I18N('user_import_sync')
  }

  return (
    <>
      {includeOnlyModifiedOption && (
        <div className='form-check mr-2'>
          <label className='mr-1' htmlFor='only-modified-checkbox'>{I18N('only_modified')}</label>
          <input
            id='only-modified-checkbox'
            type='checkbox'
            checked={isOnlyModified}
            onChange={() => setIsOnlyModified(!isOnlyModified)}
          />
        </div>
      )}
      <Button
        onClick={syncUsers}
        disabled={isDisabled}
        showLoadingSpinner={isSyncSaving}
        className={classNames('IntegrationSyncButton', className)}
      >
        {buttonText()}
      </Button>
    </>
  )
}

const CreateUserImportButtons = () => {
  const currentCompany = useCurrentCompany()
  const userImports = useSelector(userImportSlice.selectors.getUserImports())
  const { isSyncSaving } = useSelector(userImportSlice.selectors.getMetaData())
  const {
    hasHrisIntegration,
    hrisIntegrationName,
    hasAtsIntegration,
    atsIntegrationName,
    hrisIncludeOnlyModifiedOption,
  } = currentCompany
  const hasPendingOrRunningImport = userImports?.some(
    userImport => userImport.status === 'pending' || userImport.status === 'running'
  )
  const isPreboardingEnabled = currentCompany.settings?.journeys?.preboarding?.enabled

  const HrisSyncButton = () => (
    <IntegrationSyncButton
      hasPendingOrRunningImport={hasPendingOrRunningImport}
      isSyncSaving={isSyncSaving}
      isPreboardingEnabled={isPreboardingEnabled}
      integrationName={hrisIntegrationName}
      includeOnlyModifiedOption={hrisIncludeOnlyModifiedOption}
      type='hris'
    />
  )

  const AtsSyncButton = ({ className }) => (
    <IntegrationSyncButton
      hasPendingOrRunningImport={hasPendingOrRunningImport}
      isSyncSaving={isSyncSaving}
      isPreboardingEnabled={isPreboardingEnabled}
      integrationName={atsIntegrationName}
      type='ats'
      className={className}
    />
  )

  const CsvUploadButton = ({ className }) => (
    <CsvImportButton
      hasPendingOrRunningImport={hasPendingOrRunningImport}
      isSyncSaving={isSyncSaving}
      className={className}
    />
  )

  const PreboardingCsvUploadButton = ({ className }) => (
    <CsvImportButton
      hasPendingOrRunningImport={hasPendingOrRunningImport}
      isSyncSaving={isSyncSaving}
      className={classNames('PreboardingCsvUploadButton', className)}
      isPreboardingImport
    />
  )

  if (!isPreboardingEnabled) {
    return hasHrisIntegration ? <HrisSyncButton /> : <CsvUploadButton />
  }

  if (hasHrisIntegration) {
    if (hasAtsIntegration) {
      return (
        <>
          <AtsSyncButton className='mr-2' />
          <HrisSyncButton />
        </>
      )
    } else {
      return (
        <>
          <PreboardingCsvUploadButton className='mr-2' />
          <HrisSyncButton />
        </>
      )
    }
  }

  if (hasAtsIntegration) {
    return (
      <>
        <CsvUploadButton className='mr-2' />
        <AtsSyncButton />
      </>
    )
  }

  return <CsvUploadButton />
}

export default CreateUserImportButtons
