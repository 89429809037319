import React from 'react'

const TeamsIcon = ({
  width = 19, height = 24, fill = '#7C7C7C', viewBox = '0 0 19 24', className = '',
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.3784 8.59108C16.3183 8.59108 17.8589 10.1478 17.8589 12.1082V14.4145C16.6607 14.8181 15.4625 15.0487 14.2643 15.0487V12.9731C14.2643 11.4163 13.5796 10.0325 12.4384 9.16766C13.009 8.76406 13.6937 8.59108 14.3784 8.59108ZM4.62162 8.59108C5.30631 8.59108 5.99099 8.76406 6.56156 9.16766C5.47748 10.0325 4.73574 11.4163 4.73574 12.9731V15.0487C3.53754 15.0487 2.33934 14.8758 1.14114 14.4145V12.1082C1.14114 10.1478 2.73874 8.59108 4.62162 8.59108ZM9.52853 8.64874C11.8679 8.64874 13.7508 10.6091 13.7508 12.9731V15.7983C10.7838 16.8361 7.98799 16.8361 5.24925 15.7983V12.9731C5.24925 10.6091 7.18919 8.64874 9.52853 8.64874ZM9.52853 2.65234C11.0691 2.65234 12.3814 3.92081 12.3814 5.53523C12.3814 7.14964 11.0691 8.41811 9.52853 8.41811C7.93093 8.41811 6.67568 7.14964 6.67568 5.53523C6.67568 3.92081 7.93093 2.65234 9.52853 2.65234ZM4.62162 3.63252C5.30631 3.63252 5.87688 3.92081 6.33334 4.38207C5.99099 5.3046 6.10511 6.40009 6.61862 7.26496C6.16217 7.95685 5.47748 8.36045 4.62162 8.36045C3.30931 8.36045 2.28228 7.32261 2.28228 5.99649C2.28228 4.72802 3.30931 3.63252 4.62162 3.63252ZM14.3784 3.63252C15.6907 3.63252 16.7177 4.72802 16.7177 5.99649C16.7177 7.32261 15.6907 8.36045 14.3784 8.36045C13.5796 8.36045 12.8378 7.95685 12.4384 7.26496C12.952 6.40009 13.0661 5.3046 12.7237 4.38207C13.1231 3.92081 13.7508 3.63252 14.3784 3.63252Z'
      fill={fill}
    />
  </svg>
)

export default TeamsIcon
