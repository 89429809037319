import classNames from 'classnames'
import React from 'react'

export const NUMBER = 'number'
export const PERCENTAGE = 'percentage'

const ToggleOption = ({
  text,
  isSelected,
  onClick,
}) => (
  <div
    className={classNames('ToggleOption pointer d-flex align-items-center justify-content-center', { isSelected })}
    onClick={onClick}
  >
    {text}
  </div>
)

const NumberPercentageToggle = ({
  setSelectedType,
  selectedType,
}) => (
  <div className='NumberPercentageToggle d-flex flex-row ml-2'>
    <ToggleOption
      text='#'
      isSelected={selectedType === NUMBER}
      onClick={() => setSelectedType(NUMBER)}
    />
    <ToggleOption
      text='%'
      isSelected={selectedType === PERCENTAGE}
      onClick={() => setSelectedType(PERCENTAGE)}
    />
  </div>
)

export default NumberPercentageToggle
