import React from 'react'
import { useSelector } from 'react-redux'

import groupMembershipSlice from 'redux/slices/groupMemberships'
import { i18nPath } from 'utils/i18nHelpers'

import EditableContent from 'components/common/editable_content'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.groups.group_page')

const MainInfo = ({ group, updateGroup }) => {
  const currentUser = useCurrentUser()
  const { isLoading } = useSelector(groupMembershipSlice.selectors.getMetaData())

  const { name, groupType } = group
  const membersLabel = I18N('group_members', { count: group.activeMembersCount })

  return (
    <section className='GroupMainInfo mb-3'>
      <EditableContent
        canEdit={currentUser.permissions.groupManager}
        valueToEdit={name}
        inputElement={<input type='text' className='group-name w-100 p-0 pl-1' data-cy='group-name' />}
        displayElement={<h2 className='mb-0'>{name}</h2>}
        saveContent={name => updateGroup({ name })}
      />
      <div className='secondary-text'>
        <span>{groupType.name}</span>
        {!isLoading && (
          <>
            <span className='px-1'>&bull;</span>
            <span>{membersLabel}</span>
          </>
        )}
      </div>
    </section>
  )
}

export default MainInfo
