import React, { useEffect, useMemo, useRef } from 'react'
import { ExternalSyncDocument, ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import Document from 'pages/external_sync/document'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'

import SmartTable from 'components/common/tables/smartTable'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import TableDropdown from 'pages/external_sync/tableDropdown'
import useApi from 'components/common/hooks/useApi'
import { Link } from 'react-router-dom'

const I18N = i18nPath('views.external_sync.source_page')

type Props = {
  source: ExternalSyncSource
  parentDocumentId?: string
  searchQuery?: string
  onDocumentClick: (document: ExternalSyncDocument) => void
  selectedDocumentsIds: string[]
  setSelectedDocumentsIds: React.Dispatch<React.SetStateAction<string[]>>
  destroyedDocumentsIds: string[]
  setDestroyedDocumentsIds: React.Dispatch<React.SetStateAction<string[]>>
}

const DocumentsTable: React.FC<Props> = ({
  source,
  onDocumentClick,
  parentDocumentId,
  searchQuery,
  selectedDocumentsIds,
  setSelectedDocumentsIds,
  destroyedDocumentsIds,
  setDestroyedDocumentsIds,
}) => {
  const [page, setPage] = useQueryParamState({ param: 'page', initialValue: 1 })

  const {
    data: documents,
    isLoaded,
    isLoading,
    paginationData,
    removeItems,
  } = useFetch<ExternalSyncDocument[]>(
    () => (
      API.admin.externalSync.documents.fetchAll(source.id, {
        perPage: 20,
        page,
        parentDocumentId,
        q: searchQuery,
      })
    ),
    [source.id, page, parentDocumentId, searchQuery]
  )

  useEffect(() => {
    setSelectedDocumentsIds([])
  }, [documents])

  useEffect(() => {
    removeItems(destroyedDocumentsIds)
  }, [destroyedDocumentsIds])

  const documentIdToDeleteRef = useRef<string | undefined>(undefined)

  const [deleteDocument] = useApi(API.admin.externalSync.documents.destroy, {
    toastSuccessMessage: I18NCommon('deleted_successfully'),
    toastErrorMessage: I18NCommon('generic_error'),
    onSuccess: () => {
      setDestroyedDocumentsIds(destroyedDocumentsIds => [...destroyedDocumentsIds, documentIdToDeleteRef.current!])
    },
  })

  const innerOnDeleteDocument = (documentId: string) => {
    documentIdToDeleteRef.current = documentId
    deleteDocument(documentId)
  }

  const handlePageChange = (params) => {
    setPage(params.page)
  }

  const allDocumentIds = (documents || [])?.map(d => d.id)
  const isAllDocumentsSelected = useMemo(
    () => _.isEqual(_.orderBy(selectedDocumentsIds), _.orderBy(allDocumentIds)),
    [selectedDocumentsIds, allDocumentIds]
  )

  const handleCheckboxChange = (documentId: string) => {
    setSelectedDocumentsIds((prev) => {
      if (documentId === 'all') {
        return isAllDocumentsSelected ? [] : allDocumentIds
      } else {
        if (prev.includes(documentId)) {
          return prev.filter(id => id !== documentId)
        }
        return [...prev, documentId]
      }
    })
  }


  if (!isLoaded || isLoading) return <CirclesLoadingIndicator />

  const columns = [
    {
      header: () => (
        <input
          type='checkbox'
          style={{ height: '15px' }}
          checked={isAllDocumentsSelected}
          onChange={() => handleCheckboxChange('all')}
        />
      ),
      accessor: (d: ExternalSyncDocument) => (
        <input
          type='checkbox'
          style={{ height: '15px' }}
          checked={selectedDocumentsIds.includes(d.id)}
          onChange={() => handleCheckboxChange(d.id)}
        />
      ),
      className: 'checkbox-column',
      headerColumnClassName: 'checkbox-column',
    },
    {
      header: I18NCommon('document'),
      accessor: (d: ExternalSyncDocument) => (
        <div className='d-flex align-content-center'>
          <Document
            document={d}
            onClick={() => onDocumentClick(d)}
          />
        </div>
      ),
      className: 'document-column',
      headerColumnClassName: 'document-column',
    },
    {
      header: I18N('sub_documents'),
      accessor: (d: ExternalSyncDocument) => d.descendantsCount > 0 && (
        <Link to={`/external_sync/sources/${source.id}?parentDocumentId=${d.id}`}>{d.descendantsCount}</Link>
      ),
    },
    {
      header: I18NCommon('last_modified'),
      accessor: (d: ExternalSyncDocument) => (d.modifiedAt ? i18nMoment(d.modifiedAt).format('LLL') : null),
      style: { width: '320px' },
    },
    {
      accessor: (d: ExternalSyncDocument) => (
        <TableDropdown
          key={d.id}
          document={d}
          onDelete={innerOnDeleteDocument}
        />
      ),
      style: { width: '50px' },
    },
  ]

  const noResultsText = !searchQuery && page === 1 ? I18NCommon('empty_folder') : undefined

  return (
    <div className='DocumentsTable'>
      <SmartTable
        columns={columns}
        data={documents}
        className='white-bg-table'
        perPage={paginationData.perPage}
        page={paginationData.page}
        pages={paginationData.totalPages}
        totalCount={paginationData.total}
        onPaginationClick={handlePageChange}
        noResultsText={noResultsText}
      />
    </div>
  )
}

export default DocumentsTable
