import classNames from 'classnames'
import React from 'react'

import CdnSvg from 'components/common/cdnSvg'

const infoIconPath = '/images/infoIcon.svg'

interface InfoTextContainerProps {
  text: string | React.ReactNode
  className?: string
}

const InfoTextContainer = ({
  text = '',
  className = '',
} : InfoTextContainerProps) => (
  <div
    className={classNames('InfoTextContainer p-2 rounded d-flex align-items-center', className)}
  >
    <CdnSvg className='InfoIcon mr-2' src={infoIconPath} />
    <div className='text-secondary text-left'>{text}</div>
  </div>
)

export default InfoTextContainer
