import React from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import TopQuestionMiniCard from 'components/qna/topQuestionMiniCard'
import Card from 'components/common/card'
import EventCoverImage from 'components/qna/event_fields/eventCoverImage'
import EventTimeStatus from 'components/qna/eventTimeStatus'
import EventParticipants from 'components/qna/eventParticipants'
import EventDraftIndicatorOrQuestionCount from 'components/qna/eventDraftIndicatorOrQuestionCount'
import EventTimeWithAccessLevel from 'components/qna/access_level/eventTimeWithAccessLevel'

const HighlightedEvent = ({ event, topQuestion }) => {
  const hasCoverImage = event?.coverImageUrl || event?.templateName

  return (
    <Card className={classNames('HighlightedEvent', { hasCoverImage })}>
      <EventCoverImage event={event} canEditEvent={false} />
      <div className='row'>
        <div className='col-md-7 pr-md-0'>
          <EventTimeWithAccessLevel event={event} />
          <h3 className='mb-0'>
            <Link
              className='text-primary-link font-weight-700'
              to={`/events/${event.slug}`}
            >
              {event.title}
            </Link>
          </h3>
          <EventDraftIndicatorOrQuestionCount event={event} />
          <div className='mt-5 d-flex justify-content-between'>
            <EventTimeStatus event={event} />
            <div className='ParticipantsWrapper col-md-6 d-flex flex-column align-items-end'>
              <EventParticipants event={event} />
            </div>
          </div>
        </div>
        <div className='col-md-5 d-block d-md-flex'>
          {
            // display: none for mobile
            // display: inline-block for medium devices and up
          }
          <div className='highlighted-event-vertical-rule d-none d-md-inline-block mr-4' />
          <hr className='MobileHR d-block d-md-none my-4' />
          <TopQuestionMiniCard question={topQuestion} event={event} />
        </div>
      </div>
    </Card>
  )
}

export default HighlightedEvent
