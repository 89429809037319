import React, { useEffect, useState } from 'react'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/common/buttons'
import Modal from 'components/common/modal'
import classNames from 'classnames'
import journeySlice from 'redux/slices/journeys'
import ManuallyAddedEmployeesSelector, { useManuallyAddEmployees } from 'components/admin/journeys/audience/manuallyAddedEmployeesSelector'
import AdminBanner from 'components/common/adminBanner'
import EmployeesOnJourney from 'components/admin/journeys/audience/employeesOnJourney'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.add_new_hires_manually_modal')

interface UsersWithErrorsBannerProps {
  usersWithErrors: {
    id: string
    preferredFullName: string
  }[]
  journeyBlueprintType: string
}

const UsersWithErrorsBanner = ({
  usersWithErrors,
  journeyBlueprintType,
}: UsersWithErrorsBannerProps) => (
  <AdminBanner color='red' className='w-100 border-radius mb-3 py-3 d-inline-flex align-items-center justify-content-start'>
    <div className='px-3'>
      <p className='text-white'>{I18N(`${journeyBlueprintType}.error_creating_journeys`)}</p>
      <ul>
        {usersWithErrors.map(user => (
          <li className='text-white'>
            <a
              href={`/admin/users/${user.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {user.preferredFullName}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </AdminBanner>
)


interface AddEmployeesManuallyModalProps {
  className?: string,
  journeyBlueprint?: any,
  onRemoveJourney?: (journey: any) => void,
  visible?: boolean,
  toggle: () => void,
}

const AddEmployeesManuallyModal = ({
  className,
  journeyBlueprint,
  onRemoveJourney,
  visible,
  toggle,
}: AddEmployeesManuallyModalProps) => {
  const dispatch = useDispatch()
  const journeyBlueprintType = journeyBlueprint?.type
  const { isSaving, usersWithErrors = [] } = useSelector(journeySlice.selectors.getMetaData())

  const {
    selectedEmployees, setSelectedEmployees, onAddEmployees, ...manuallyAddEmployeesProps
  } = useManuallyAddEmployees(journeyBlueprint?.id)

  const closeModal = () => {
    setSelectedEmployees([])
    toggle()
    dispatch(journeySlice.actions.clearUsersWithErrors())
  }

  const onSuccess = (hasErrors) => {
    if (!hasErrors) {
      closeModal()
    }
  }

  const onSaveClick = () => {
    onAddEmployees({ onSuccess })
  }

  useEffect(() => {
    setSelectedEmployees(selectedEmployees.filter(user => usersWithErrors.map(u => u.id).includes(user.id)))
  }, [usersWithErrors?.length])

  return (
    <Modal size='lg' className={classNames('AddEmployeesManuallyModal', className)} visible={visible} toggle={closeModal}>
      <h3 className='mb-1'>{I18N(`${journeyBlueprintType}.title`)}</h3>
      <p className='text-secondary mb-3'>{I18N(`${journeyBlueprintType}.description`)}</p>
      {usersWithErrors.length > 0 && (
        <UsersWithErrorsBanner
          usersWithErrors={usersWithErrors}
          journeyBlueprintType={journeyBlueprintType}
        />
      )}
      <ManuallyAddedEmployeesSelector
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
        journeyBlueprint={journeyBlueprint}
        {...manuallyAddEmployeesProps}
      />
      <EmployeesOnJourney
        journeyBlueprint={journeyBlueprint}
        onRemoveJourney={onRemoveJourney}
      />
      <div className='d-flex justify-content-end align-items-center'>
        <div role='button' tabIndex={0} onClick={closeModal} className='mr-3 text-muted-dark'>
          {I18NCommon('cancel')}
        </div>
        <Button
          className='my-4'
          onClick={onSaveClick}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('save')}
        </Button>
      </div>
    </Modal>
  )
}

export default AddEmployeesManuallyModal
