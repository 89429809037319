import React from 'react'

import CheckBox from 'components/common/buttons/checkBox'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.other_settings.people_tab.shoutouts')

const ShoutoutsSection = ({
  connect,
  shoutoutsSettings,
}) => {
  const { enabled: shoutoutsEnabled, maxSelectableCompanyValues, minSelectableCompanyValues } = shoutoutsSettings
  return (
    <div>
      <h3 className='mt-5'>{I18N('shoutouts')}</h3>

      <CheckBox
        id='shoutouts'
        label={I18N('shoutouts')}
        {...connect('shoutouts.enabled')}
      />

      {shoutoutsEnabled && (
        <>
          <CheckBox
            id='commentsShoutouts'
            label={I18N('enable_comments_in_shoutouts')}
            {...connect('comments.shoutouts.enabled')}
          />

          <CheckBox
            id='shoutoutSuggestions'
            label={I18N('shoutout_suggestions')}
            {...connect('shoutouts.suggestions.enabled')}
          />

          <CheckBox
            id='shoutoutsAutoFollow'
            label={I18N('users_auto_follow')}
            helpText={I18N('users_auto_follow_help_text')}
            helpTextClassName='mb-3 d-block'
            {...connect('shoutouts.autoFollow')}
          />

          <span>{I18N('selectable_company_values')}</span>
          <div className='d-flex align-items-center mt-2'>
            <label className='mr-2 my-0'>{I18N('min')}</label>
            <input
              name='minSelectableCompanyValues'
              type='number'
              value={minSelectableCompanyValues}
              min={0}
              max={maxSelectableCompanyValues}
              onChange={e => connect('shoutouts.minSelectableCompanyValues').onChange(e.target.value)}
              disabled={!shoutoutsEnabled}
            />

            <label className='mx-2 my-0'>{I18N('max')}</label>
            <input
              name='maxSelectableCompanyValues'
              className='max-value'
              type='number'
              value={maxSelectableCompanyValues}
              min={minSelectableCompanyValues}
              max={99}
              onChange={e => connect('shoutouts.maxSelectableCompanyValues').onChange(e.target.value)}
              disabled={!shoutoutsEnabled}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ShoutoutsSection
