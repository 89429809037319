import React from 'react'

import DangerousHTML from 'components/common/dangerousHTML'
import classNames from 'classnames'

const SearchResultOption = ({
  className = undefined,
  image,
  title,
  secondaryTexts = [],
}) => {
  const filteredSecondaryTexts = secondaryTexts.filter(Boolean)

  return (
    <div className={classNames(className, 'px-2 d-flex align-items-center')}>
      <div className='MainContent truncate-text-at-1-lines d-flex d-md-block align-items-center'>
        {image}
        <span className='d-flex d-md-inline flex-column'>
          <span>
            <DangerousHTML
              className='Title'
              options={{ ALLOWED_TAGS: ['em'] }}
            >
              {title}
            </DangerousHTML>
            {!_.isEmpty(filteredSecondaryTexts) && <span className='mx-1 EmDash'>&#8212;</span>}
          </span>
          <span className='SecondaryTextContainer text-secondary small d-flex d-md-inline'>
            {filteredSecondaryTexts.map((text, index) => {
              const identifier = `default-option-secondary-text-${index}`
              return (
                <span key={identifier} className={classNames(identifier, 'SecondaryTextGroup')}>
                  {index > 0 && <span className='mx-1 Bullet'>&#8226;</span>}
                  {typeof text === 'string' ? (
                    <DangerousHTML
                      className='SecondaryText'
                      options={{ ALLOWED_TAGS: ['em'] }}
                    >
                      {text}
                    </DangerousHTML>
                  ) : (
                    text
                  )}
                </span>
              )
            })}
          </span>
        </span>
      </div>
    </div>
  )
}

export default SearchResultOption
