import React from 'react'

import classNames from 'classnames'

import AccessLevel from 'components/qna/access_level'
import EventTime from 'components/qna/eventTime'

const EventTimeWithAccessLevel = ({ event }) => {
  const isPrivate = event.accessLevel === 'private_access'

  return (
    <>
      <div className={classNames(isPrivate && 'EventTimeWithAccessLevel d-flex align-items-center justify-content-between')}>
        <EventTime event={event} className='text-small text-sm-normal font-weight-500' />
        <AccessLevel event={event} />
      </div>
    </>
  )
}

export default EventTimeWithAccessLevel
