import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import classNames from 'classnames'
import profileSlice from 'redux/slices/profiles'

import CloseIcon from 'components/icons/closeIcon'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import ProgressiveImage from 'components/common/images/progressiveImage'
import PromotePhotoToPrimary from 'components/common/images/promotePhotoToPrimary'
import ProfilePhotoReposition from 'components/common/profilePhotoReposition'

const Picture = ({
  picture,
  owner,
  defaultPhotoUrl,
  className,
  onClick,
  showRemoveIcon = true,
  slice = profileSlice,
  isManagingPhotos = false,
}) => {
  const [isDestroying, setIsDestroying] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [reposition, setReposition] = useState(false)

  const ref = useRef()
  const dispatch = useDispatch()
  const src = picture?.imageUrl || defaultPhotoUrl

  const handleOnMouseEnter = () => setIsHovered(true)

  const handleOnMouseLeave = () => setIsHovered(false)

  const canManageProfilePic = picture?.permittedActions?.manage

  const handleImageClick = (e) => {
    if (!reposition) {
      onClick(e)
    }
  }

  const handleOnProfilePhotoDestroy = async (ev) => {
    ev.stopPropagation()

    setIsDestroying(true)

    await dispatch(slice.asyncActions.destroyPhoto(picture, owner))

    // to prevent memory leaks, we only want to change
    // state if the component is still mounted
    if (ref.current) setIsDestroying(false)
  }

  return (
    <div className={classNames('Picture position-relative d-flex justify-content-center', className)} ref={ref}>
      <div className='picture-inner-container position-relative' onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} onClick={handleImageClick}>
        {isDestroying && <CirclesLoadingIndicator className='center-v-and-h' />}
        <div className='Image'>
          <ProgressiveImage className='pointer bordered-thumbnail cursor-default' src={src} alt='Profile image' imgClassName='rounded pointer' />
        </div>

        {(isHovered || isManagingPhotos) && canManageProfilePic && !isDestroying && showRemoveIcon && (
          <div>
            <span data-testid='remove-profile-picture' onClick={handleOnProfilePhotoDestroy} className='position-absolute pointer right-0 top-0'>
              <CloseIcon className='RemoveProfilePicture' />
            </span>

            <PromotePhotoToPrimary owner={owner} picture={picture} slice={slice} />

            {picture.primary && (
              <ProfilePhotoReposition owner={owner} photo={picture} slice={slice} className='d-flex' onRepositionChange={setReposition} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Picture
