import React, { useState } from 'react'
import CompleteYourProfileModal from 'components/home/completeYourProfileModal'
import { ButtonSmall } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.home')

const CompleteYourProfileButton = ({ user, buttonText = I18N('complete_your_profile') }) => {
  const currentCompany = useCurrentCompany()
  const [showCompleteYourProfileModal, setShowCompleteYourProfileModal] = useState(false)

  const showCompleteYourProfileButton = () => [1, 2, 3].filter((number) => {
    const fieldName = 'customField'.concat(number.toString())
    const fieldEnabled = currentCompany.settings.profile.fields[fieldName]
    const fieldEmpty = isHtmlStringEmpty(user[fieldName])
    return fieldEnabled && fieldEmpty
  }).length > 0

  if (!user.id) {
    return null
  }

  if (!showCompleteYourProfileButton()) {
    return null
  }

  return (
    <div>
      <CompleteYourProfileModal
        showModal={showCompleteYourProfileModal}
        closeModal={() => setShowCompleteYourProfileModal(false)}
        user={user}
      />
      <div className='CompleteYourProfile my-2'>
        <ButtonSmall onClick={() => setShowCompleteYourProfileModal(true)}>
          <CdnSvg src='/images/alertOutlineIcon.svg' className='AlertOutlineIcon' />
          <div className='ButtonText text-white text-small'>{buttonText}</div>
        </ButtonSmall>
      </div>
    </div>
  )
}

export default CompleteYourProfileButton
