import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AsyncSelect } from 'components/common/react_select'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useApi from 'components/common/hooks/useApi'
import AppModal from 'components/common/modal'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.pages.faqs.faq_item.move_faq_modal')

interface FAQMoveModalProps {
  faq: any
  onCancel: () => void
}

const FAQMoveModal = ({ faq, onCancel }: FAQMoveModalProps) => {
  const [selectedPage, setSelectedPage] = useState(faq.page)
  const [inputValue, setInputValue] = useState(faq.page.title)
  const getOptions = useGetOptions()
  const history = useHistory()
  const location = useLocation()

  const [updateFAQ, { isLoading: isSaving }] = useApi(updatedFAQ => API.pagesFAQs.update(faq.page.id, updatedFAQ), {
    onSuccess: (data) => {
      onCancel()

      if (location.pathname.includes('/faqs/')) {
        history.push(data.displayPath)
      } else {
        history.push(`${data.page.displayPath}?highlightedFaqId=${faq.id}`)
      }
    },
  })

  const handleSave = () => {
    updateFAQ({ id: faq.id, pageId: selectedPage.id })
  }

  return (
    <AppModal
      visible
      toggle={onCancel}
      modalContentClassName='container d-flex flex-column w-100'
    >
      <>
        <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
          <h3 className='mb-0'>{I18N('title')}</h3>
        </div>
        <section className='mb-5'>
          <label className='text-smallest text-secondary'>{I18N('select_label')}</label>
          <AsyncSelect
            value={{ value: selectedPage.id, label: selectedPage.title }}
            inputValue={inputValue}
            onInputChange={setInputValue}
            onChange={(val) => {
              setSelectedPage(val.value)
            }}
            getOptions={getOptions}
            autoFocus
          />
        </section>

        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={onCancel} />
          <ButtonNarrow
            onClick={handleSave}
            disabled={isSaving}
            showLoadingSpinner={isSaving}
          >
            {I18NCommon('save')}
          </ButtonNarrow>
        </div>
      </>
    </AppModal>
  )
}

const useGetOptions = () => {
  const [search] = useApi(query => API.globalSearch(query, { type: 'page' }))

  const getOptions = useCallback(
    _.debounce(async (inputValue, callback) => {
      const results = await search(inputValue)

      const pages = results.map(r => ({
        value: {
          id: r.page.id,
          title: r.page.title,
        },
        label: (
          <>
            {r.page.title}
            <span className='text-smallest text-secondary ml-2'>{r.page.workspace.title}</span>
          </>
        ),
      }))

      callback([{ label: '', options: pages }])
    }, 300), [])

  return getOptions
}

export default FAQMoveModal
