import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import pickWithTargetingRules from 'redux/slices/utils/pickWithTargetingRules'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { PaginatedDataType } from 'types/analytics/helpers'
import {
  SearchQueryResultType,
  SearchSummaryDataType,
  SearchUsageDataType,
  SelectedResultType,
  TopSelectedResultType
} from 'types/analytics/search'

const buildSummaryPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
])

const buildUsagePayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
  'groupBy',
  'type',
  'groupByEventType',
])

const buildQueriesPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
  'page',
  'perPage',
])

const defaultSummary = {
  searchesCount: 0,
  avgTimeToSelectResult: 0,
  userClickedResultPercent: 0,
}

const defaultEmptyArrayData = {
  data: [],
}

const defaultPaginatedData = {
  data: [],
  page: 0,
  totalItems: 0,
  totalPages: 0,
}

export interface SearchAnalyticsState {
  summary: SearchSummaryDataType
  allUsage: { data: SearchUsageDataType[] }
  usageByEventType: { data: SearchUsageDataType[] }
  topQueries: PaginatedDataType<SearchQueryResultType>
  queriesWithZeroResults: PaginatedDataType<SearchQueryResultType>
  selectedResultTypes: PaginatedDataType<SelectedResultType>
  topSelectedResults: PaginatedDataType<TopSelectedResultType>
  meta: {
    summary: DefaultMetaType
    allUsage: DefaultMetaType
    usageByEventType: DefaultMetaType
    topQueries: DefaultMetaType
    queriesWithZeroResults: DefaultMetaType
    selectedResultTypes: DefaultMetaType
    topSelectedResults: DefaultMetaType
  }
}

export const initialState: SearchAnalyticsState = {
  summary: defaultSummary,
  allUsage: defaultEmptyArrayData,
  usageByEventType: defaultEmptyArrayData,
  topQueries: defaultPaginatedData,
  queriesWithZeroResults: defaultPaginatedData,
  selectedResultTypes: defaultPaginatedData,
  topSelectedResults: defaultPaginatedData,
  meta: {
    summary: defaultMeta,
    allUsage: defaultMeta,
    usageByEventType: defaultMeta,
    topQueries: defaultMeta,
    queriesWithZeroResults: defaultMeta,
    selectedResultTypes: defaultMeta,
    topSelectedResults: defaultMeta,
  },
}

const searchAnalyticsSlice = createSlice({
  name: 'searchAnalytics',
  initialState,
  reducers: {
    setData(state, action) {
      const { type, data } = action.payload
      state[type] = data
    },

    setIsLoading(state, action) {
      const { type, isLoading } = action.payload
      state.meta[type].isLoading = isLoading
    },

    setError(state, action) {
      const { type, error } = action.payload
      state.meta[type].error = error
    },
  },
})

const asyncActions = {
  admin: {
    fetchSummary: (params = {}) => async (dispatch) => {
      try {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'summary', isLoading: true }))

        const response = await API.admin.analytics.search.summary(buildSummaryPayload(params))

        dispatch(searchAnalyticsSlice.actions.setData({ type: 'summary', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(searchAnalyticsSlice.actions.setError({ type: 'summary', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'summary', isLoading: false }))
      }
    },

    fetchUsage: (type: 'allUsage' | 'usageByEventType', params: any = {}) => async (dispatch) => {
      try {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type, isLoading: true }))

        const response = await API.admin.analytics.search.usage(buildUsagePayload(params))

        dispatch(searchAnalyticsSlice.actions.setData({ type, data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(searchAnalyticsSlice.actions.setError({ type, error: getResponseOrThrow(error) }))
      } finally {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type, isLoading: false }))
      }
    },

    fetchTopQueries: (params = {}) => async (dispatch) => {
      try {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'topQueries', isLoading: true }))

        const response = await API.admin.analytics.search.topQueries(buildQueriesPayload(params))

        dispatch(searchAnalyticsSlice.actions.setData({ type: 'topQueries', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(searchAnalyticsSlice.actions.setError({ type: 'topQueries', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'topQueries', isLoading: false }))
      }
    },

    fetchQueriesWithZeroResults: (params = {}) => async (dispatch) => {
      try {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'queriesWithZeroResults', isLoading: true }))

        const response = await API.admin.analytics.search.queriesWithZeroResults(buildQueriesPayload(params))

        dispatch(searchAnalyticsSlice.actions.setData({ type: 'queriesWithZeroResults', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(searchAnalyticsSlice.actions.setError({ type: 'queriesWithZeroResults', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'queriesWithZeroResults', isLoading: false }))
      }
    },

    fetchSelectedResultTypes: (params = {}) => async (dispatch) => {
      try {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'selectedResultTypes', isLoading: true }))

        const response = await API.admin.analytics.search.selectedResultTypes(buildQueriesPayload(params))

        dispatch(searchAnalyticsSlice.actions.setData({ type: 'selectedResultTypes', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(searchAnalyticsSlice.actions.setError({ type: 'selectedResultTypes', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'selectedResultTypes', isLoading: false }))
      }
    },

    fetchTopSelectedResults: (params = {}) => async (dispatch) => {
      try {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'topSelectedResults', isLoading: true }))

        const response = await API.admin.analytics.search.topSelectedResults(buildQueriesPayload(params))

        dispatch(searchAnalyticsSlice.actions.setData({ type: 'topSelectedResults', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(searchAnalyticsSlice.actions.setError({ type: 'topSelectedResults', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(searchAnalyticsSlice.actions.setIsLoading({ type: 'topSelectedResults', isLoading: false }))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.searchAnalytics.meta,

  getSummary: () => (state: ReduxState) => state.searchAnalytics.summary,

  getAllUsage: () => (state: ReduxState) => state.searchAnalytics.allUsage,

  getUsageByEventType: () => (state: ReduxState) => state.searchAnalytics.usageByEventType,

  getQueries: (type: 'topQueries' | 'queriesWithZeroResults') => (state: ReduxState) => state.searchAnalytics[type],

  getSelectedResultTypes: () => (state: ReduxState) => state.searchAnalytics.selectedResultTypes,

  getTopSelectedResults: () => (state: ReduxState) => state.searchAnalytics.topSelectedResults,
}

export default {
  ...searchAnalyticsSlice,
  selectors,
  asyncActions,
}
