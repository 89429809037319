
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentUserIntegrations from 'components/common/hooks/useCurrentUserIntegrations'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import UserLink from 'components/common/userLink'
import RadioButton from 'components/form_fields/radioButton'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'


const I18N = i18nPath('views.admin.article_editor.audience_and_settings_editor')

const slackIconPath = '/images/slackLogoIcon.svg'

const SlackSenderAsSelector = ({ workingCopy, updateWorkingCopy }) => {
  const currentUser = useCurrentUser()
  const userIntegrations = useCurrentUserIntegrations()
  const [showSlackIntegrationModal, setShowSlackIntegrationModal] = useState(false)
  const { pathname, search } = useLocation()

  const setSlackPublisherAsCurrentUser = () => {
    if (!userIntegrations?.slackInstalled) {
      setShowSlackIntegrationModal(true)
      return
    }

    updateWorkingCopy({ chatNotificationSender: currentUser })
  }

  const setSlackPublisherRadioAsUser = () => {
    // when user has not installed slack
    if (!userIntegrations?.slackInstalled) {
      // when someone else has already been set as slack publisher
      if (workingCopy.chatNotificationSender) {
        // just switch the tab
        updateWorkingCopy({
          sendSlackNotificationAs: 'user',
        })
      } else {
        // otherwise, show the authorization modal
        setShowSlackIntegrationModal(true)
      }
      return
    }

    // when user has installed slack
    // and someone else has already been set as slack publisher
    // just switch the tab
    // otherwise, set the current user as slack publisher
    updateWorkingCopy({
      sendSlackNotificationAs: 'user',
      chatNotificationSender: workingCopy.chatNotificationSender || currentUser,
    })
  }

  const setSlackPublisherRadioAsBot = () => {
    updateWorkingCopy({ sendSlackNotificationAs: 'bot' })
  }

  const cancelSlackIntegration = () => {
    if (!workingCopy.chatNotificationSender) updateWorkingCopy({ sendSlackNotificationAs: 'bot' })

    setShowSlackIntegrationModal(false)
  }

  return (
    <>
      {showSlackIntegrationModal && (
        <ConfirmationModal
          visible
          toggle={cancelSlackIntegration}
          title={(
            <div className='text-center mb-2'>
              <div>
                <CdnSvg src={slackIconPath} className='mr-2' />
              </div>
              <h4 className='mb-0'>{I18N('authorization_required')}</h4>
            </div>
          )}
          description={I18N('slack_integration_description')}
          confirmText={I18NCommon('authorize')}
          cancelText={I18NCommon('cancel')}
          onConfirmClick={() => {
            window.location.href = `/auth/slackoauth/user_redirect_url?redirect_path=${encodeURIComponent(pathname + search)}`
          }}
          onCancelClick={cancelSlackIntegration}
        />
      )}

      <div className='d-flex align-items-center'>
        <RadioButton
          id='send_as_bot'
          checked={workingCopy.sendSlackNotificationAs === 'bot'}
          label={I18N('publish_as_slack_bot')}
          onChange={setSlackPublisherRadioAsBot}
        />
        <RadioButton
          id='send_as_me'
          checked={workingCopy.sendSlackNotificationAs === 'user'}
          label={I18N('publish_as_myself')}
          onChange={setSlackPublisherRadioAsUser}
        />
      </div>

      <hr />

      <div className='mb-4'>
        <span className='muted'>
          <span className='mr-4'>{I18N('sending_as')}:</span>

          {workingCopy.sendSlackNotificationAs === 'bot' && (
            <>
              <CdnSvg src={slackIconPath} /> {I18N('slack_bot')}
            </>
          )}

          {workingCopy.sendSlackNotificationAs === 'user' && workingCopy.chatNotificationSender && (
            <>
              <UserLink
                user={workingCopy.chatNotificationSender}
              />

              {workingCopy.chatNotificationSender.id !== currentUser.id && (
                <IconButtonWithPopover
                  id='update_user_to_current_editor'
                  text={I18N('update_user_to_current_editor')}
                  icon={<CdnSvg src='/images/syncIcon.svg' />}
                  onClick={setSlackPublisherAsCurrentUser}
                  className='mx-2 d-inline-block'
                  popoverPlacement='bottom'
                />
              )}
            </>
          )}
        </span>
      </div>
    </>
  )
}

export default SlackSenderAsSelector
