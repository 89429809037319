import React, { useEffect, useState } from 'react'
import widgetsMap from 'utils/layout/widgetsMap'
import ErrorBoundaries from 'components/errorBoundaries'

const EmptyState = ({ setIsChildNull }) => {
  useEffect(() => {
    setIsChildNull(true)

    return () => {
      setIsChildNull(false)
    }
  }, [])

  return null
}

const BaseWidget = ({
  widget, resizeWidget,
}) => {
  const [isChildNull, setIsChildNull] = useState(false)
  const { Component } = widgetsMap[widget.type]

  useEffect(() => {
    if (isChildNull) {
      resizeWidget(widget.id, 0)
    } else {
      if (widget.h !== widget.originalH) {
        resizeWidget(widget.id, widget.originalH)
      }
    }
  }, [isChildNull])

  return (
    <ErrorBoundaries showDefault={false}>
      <Component
        widget={widget}
        EmptyState={<EmptyState setIsChildNull={setIsChildNull} />}
        resizeWidget={resizeWidget}
      />
    </ErrorBoundaries>
  )
}

export default BaseWidget
