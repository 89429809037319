import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/common/card'
import StatusLabel from 'components/common/status_label'
import MemberCardMenuItems from 'components/groups/memberCardMenuItems'
import constructMemberLabel from 'utils/groups/constructMemberLabel'

import classNames from 'classnames'

const I18N = i18nPath('views.groups.group_page')

const MemberCard = ({
  className,
  membership,
  group,
}) => {
  const dispatch = useDispatch()
  const user = membership.user
  const label = constructMemberLabel(membership, group.groupType?.useLabelsToCategorizeMembers)

  return (
    <div className={classNames('MemberCard d-flex', className)}>
      <Card className='h-100 w-100' cardBodyClassName='h-100 d-flex p-2'>
        <Link to={`/profile/${user.username}`} className='d-flex'>
          <img src={user.primaryPhotoThumbnailUrl} className='MemberProfilePhoto mt-1 ml-2 mr-2' />
          <div className='PersonInfo d-flex flex-column align-items-start'>
            <div className='font-weight-500'>{user.preferredFullName}</div>
            <div className={classNames('text-small text-secondary', label ? 'truncate-text-at-1-lines' : 'truncate-text-at-2-lines')}>{user.title}</div>
            {label && <StatusLabel className='mt-1 text-smallest font-weight-500'>{label}</StatusLabel>}
          </div>
        </Link>
        <MemberCardMenuItems
          membership={membership}
          group={group}
        />
      </Card>
    </div>
  )
}

export default MemberCard
