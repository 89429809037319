import React from 'react'

import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { ChatChannelType, ChatChannelTypeEnum } from 'types/chat/channel'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const slackIconPath = '/images/slackLogoIcon.svg'
const msTeamIconPath = '/images/microsoftTeamsIcon.svg'

interface Props {
  channel: ChatChannelType
  iconClass?: string
  className?: string
  linkEnabled?: boolean
}

const ChatChannel = ({
  channel,
  className,
  iconClass = 'w-16',
  linkEnabled = true,
}: Props) => {
  const { canSeeMultipleSlackWorkspaces, canSeeMultipleMsTeamWorkspaces } = useCurrentUser()
  let name, canSeeMultipleWorkspace, iconPath

  if (channel.type === ChatChannelTypeEnum.SlackChannel) {
    iconPath = slackIconPath
    name = `#${channel.name}`
    canSeeMultipleWorkspace = canSeeMultipleSlackWorkspaces
  }

  if (channel.type === ChatChannelTypeEnum.MsTeamChannel) {
    iconPath = msTeamIconPath
    name = channel.name
    canSeeMultipleWorkspace = canSeeMultipleMsTeamWorkspaces
  }

  const child = (
    <>
      <CdnSvg src={iconPath} className={classNames('d-inline-block mr-1', iconClass)} />

      <span>
        <span className={classNames(linkEnabled ? 'link-color' : 'text-color-primary', 'font-weight-600')}>
          {name}
        </span>
        {canSeeMultipleWorkspace && (
          <span className='text-secondary font-weight-500 ml-1'>- {channel.workspaceDomain}</span>
        )}
      </span>
    </>
  )

  if (linkEnabled) {
    return (
      <a
        href={channel.externalUrl}
        target='_blank'
        rel='noopener noreferrer'
        title={channel.name}
        className={className}
      >
        {child}
      </a>
    )
  }

  return (
    <span className={className}>
      {child}
    </span>
  )
}

export default ChatChannel
