import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { i18nPath, i18nMomentWithTimezone } from 'utils/i18nHelpers'
import taskSlice, { TASK_ATTRIBUTES } from 'redux/slices/journeys/tasks'
import { TASK_TEMPLATE_INTERPOLATED_FIELDS } from 'redux/slices/journeys/taskTemplates'

import TaskForm from 'components/admin/journeys/taskTemplates/taskForm'
import StepAdminBanner from 'components/admin/journeys/stepAdminBanner'

import { LoadingContainer } from 'components/common/loadingContainer'
import useStepManagement from 'components/admin/journeys/hooks/useStepManagement'
import EditEntityHeader from 'components/admin/journeys/common/editEntityHeader'
import currentTimezone from 'utils/currentTimezone'

const I18N = i18nPath('views.admin.journeys.tasks.edit_task_page')

const EditTaskPage = () => {
  const { id } = useParams()

  const task = useSelector(taskSlice.selectors.getTask(id))
  const taskTemplate = task?.taskTemplate
  const {
    isLoading, isNotFound, isSaving,
  } = useSelector(taskSlice.selectors.getMetaData())

  const {
    workingCopy,
    updateWorkingCopy,
    hasChanges,
    onSave,
  } = useStepManagement({
    id,
    entity: task,
    entityTemplate: taskTemplate,
    entityAttributes: TASK_ATTRIBUTES,
    fetchEntity: taskSlice.asyncActions.admin.fetchTask,
    updateEntity: taskSlice.asyncActions.admin.updateTask,
    interpolatedFields: TASK_TEMPLATE_INTERPOLATED_FIELDS,
  })

  const step = task?.step
  const missingVariables = step?.missingVariables
  const completedAt = task?.completedAt
  const journeyBlueprint = step?.journey?.journeyBlueprint

  const timezone = step?.triggerTimezone || currentTimezone()


  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='EditTaskPage'>
        <EditEntityHeader
          type='task'
          entity={task}
          entityTemplate={taskTemplate}
          entityI18n={I18N}
        />
        <StepAdminBanner
          step={step}
          triggeredText={I18N('task_completed_banner_description',
            {
              completedHour: `${i18nMomentWithTimezone({ date: completedAt, timezone, format: 'hh:mm A' })} (${timezone})`,
              completedDate: i18nMomentWithTimezone({ date: completedAt, timezone, format: 'll' }),
              completedBy: task?.completedBy?.preferredFullName,
            })}
          missingVariablesText={I18N('task_not_assigned_banner_description', { missingVariables })}
          errorText={I18N('task_error_banner_description')}
        />
        <main className='AdminContent'>
          {
            task && (
              <TaskForm
                onSave={onSave}
                updateWorkingCopy={updateWorkingCopy}
                workingCopy={workingCopy}
                currentState='active'
                isStepTemplate={false}
                areChangesPresent={hasChanges}
                forUser={step?.forUser}
                isDisabled={task?.completed}
                journeyBlueprint={journeyBlueprint}
                isSaving={isSaving}
              />
            )
          }
        </main>
      </div>
    </LoadingContainer>
  )
}

export default EditTaskPage
