import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import PasswordLoginForm from 'components/authentication/passwordLoginForm'
import AuthTypeLookupByEmailForm from 'components/authentication/authTypeLookupByEmailForm'
import MagicLinkLoginForm from 'components/authentication/magicLinkLoginForm'
import API from 'services/api'

import OmniauthLoginButton from 'components/authentication/omniauthLoginButton'
import useQueryParams from 'components/common/hooks/useQueryParams'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.authentication.login_form')

const LoginForm = ({
  subdomain, email, state, redirectUri,
}) => {
  const { isLoading, data } = useFetch(
    () => API.auth.getAuthType(subdomain, { email, state, mobileAppRedirectUri: redirectUri })
  )

  const {
    authType,
    name: companyName,
    rootDomain,
    samlProvider,
  } = data || {}

  if (isLoading || !authType) {
    return <CirclesLoadingIndicator />
  }

  if (authType === 'workos') {
    return <AuthTypeLookupByEmailForm />
  }

  const omniauthTypes = ['saml', 'microsoft', 'google', 'developer']

  if (authType) {
    return (
      <>
        <h2 className='d-flex justify-content-center'>
          {companyName ? I18N('login_header_company_name', { companyName }) : I18N('login_header')}
        </h2>
        <div className='text-secondary text-center mb-2'>{rootDomain}</div>
        {authType === 'password' && <PasswordLoginForm />}
        {authType === 'magic_link' && <MagicLinkLoginForm companyRootDomain={rootDomain} />}
        {omniauthTypes.includes(authType) && (
          <OmniauthLoginButton
            authType={authType}
            companySubdomain={subdomain}
            samlProvider={samlProvider}
          />
        )}
      </>
    )
  }

  return null
}

const MobileAppLoginPage = () => {
  const { state, redirectUri, clientId } = useQueryParams()
  const [subdomain, email] = decodeURIComponent(clientId).split('-')

  return (
    <div className='container d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
      <div className='row'>
        <div className='card m-4 p-4' style={{ width: '320px' }}>
          <LoginForm
            subdomain={subdomain}
            email={email}
            state={state}
            redirectUri={redirectUri}
          />
        </div>
      </div>
    </div>
  )
}

export default MobileAppLoginPage
