import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'
import { Radio, RadioGroup } from 'react-radio-group'
import classNames from 'classnames'


const UserRadioButtonField = ({
  className = '',
  user = null,
  setUser = () => { },
  fieldName = '',
  defaultValue = false,
  isValueInverted = false, // When the opposite of the value is what the user should see as true, then we should invert the boolean value
  disabled = false,
}) => {
  const currentValue = user[fieldName] || defaultValue

  const onChange = (value) => {
    setUser({ ...user, [fieldName]: isValueInverted ? !value : value })
  }

  return (
    <RadioGroup
      className={classNames('d-flex align-items-center justify-content-start UserRadioButtonField', className)}
      name={fieldName}
      selectedValue={isValueInverted ? !currentValue : currentValue}
      onChange={onChange}
    >
      <label className='d-flex align-items-center'>
        <Radio className='mr-1' value={true} disabled={disabled} />
        <span>
          {I18NCommon('yes')}
        </span>
      </label>
      <label className='d-flex align-items-center'>
        <Radio className='mr-1 ml-5' value={false} disabled={disabled} />
        <span>
          {I18NCommon('no')}
        </span>
      </label>
    </RadioGroup>
  )
}


export default UserRadioButtonField
