import React from 'react'

import { i18nFormat, i18nMoment, i18nPath } from 'utils/i18nHelpers'

import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'

const I18N = i18nPath('views.qna.questions')

const QuestionByLine = (props) => {
  const { question, className = 'text-secondary' } = props

  const { creator, createdAt, isAnonymous } = question

  const questionCreatedAt = i18nMoment(createdAt).fromNow()

  let questionCreatorContent
  if (isAnonymous) {
    questionCreatorContent = (
      <span className='text-small text-primary mr-1'>
        {I18N('anonymous')}
      </span>
    )
  } else {
    questionCreatorContent = (
      <span className='mr-1'>
        <EmployeeLinkWithPopover user={creator} id={`question-${question.id}-creator-${creator?.id}`} />
      </span>
    )
  }

  return (
    <div className={className}>
      {i18nFormat(I18N('question_byline'), questionCreatorContent, questionCreatedAt)}
      {question.isPending && (
        <span className='ml-4 p-2 ApprovalBadge'>{I18N('waiting_for_approval')}</span>
      )}
    </div>
  )
}

export default QuestionByLine
