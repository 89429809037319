import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import entitySlice from 'redux/slices/entities'
import API from 'services/api'
import { sortByDate } from 'utils/sortByDate'
import { showToastMessage } from 'redux/slices/toasts'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { i18nPath } from 'utils/i18nHelpers'
import appSignal from 'services/appSignal'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

const I18N = i18nPath('views.shoutouts_feed')

export const defaultWorkingCopy = {
  text: '',
  users: [],
  companyValues: [],
}

const initialState = {
  shoutoutSuggestions: [],
  newShoutoutIds: [],
  companyValueIds: [],
  meta: {
    isSaving: false,
    isSending: false,
    isLoading: false,
    isLoadingCompanyValues: false,
    isLoadingShoutoutSuggestions: false,
    queryParams: defaultPaginationParams,
  },
}

const shoutoutsSlice = createSlice({
  name: 'shoutouts',
  initialState,
  reducers: {
    isSaving(state, action) {
      state.meta.isSaving = action.payload
    },
    isSending(state, action) {
      state.meta.isSending = action.payload
    },
    isLoading(state, action) {
      state.meta.isLoading = action.payload
    },
    isLoadingCompanyValues(state, action) {
      state.meta.isLoadingCompanyValues = action.payload
    },
    isLoadingShoutoutSuggestions(state, action) {
      state.meta.isLoadingShoutoutSuggestions = action.payload
    },
    setCompanyValueIds(state, action) {
      state.companyValueIds = action.payload
    },
    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },
    setShoutoutSuggestions(state, action) {
      state.shoutoutSuggestions = action.payload
    },
    prependNewShoutoutId(state, action) {
      state.newShoutoutIds = [action.payload].concat(state.newShoutoutIds)
    },
    removeNewShoutoutId(state, action) {
      const shoutoutId = action.payload.toString()
      state.newShoutoutIds = _.reject(state.newShoutoutIds, id => id === shoutoutId)
    },
    clear() {
      return initialState
    },
  },
})

const asyncActions = {
  reset: dispatch => dispatch(entitySlice.actions.reset('shoutout')),
  fetchCompanyValues: () => async (dispatch) => {
    dispatch(shoutoutsSlice.actions.isLoadingCompanyValues(true))

    try {
      const response = await API.companyValues.fetchAll()
      const companyValueIds = response.data.data.map(companyValue => companyValue.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(shoutoutsSlice.actions.setCompanyValueIds(companyValueIds))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(shoutoutsSlice.actions.isLoadingCompanyValues(false))
    }
  },
  fetchShoutoutSuggestions: () => async (dispatch) => {
    dispatch(shoutoutsSlice.actions.isLoadingShoutoutSuggestions(true))

    try {
      const response = await API.shoutouts.fetchSuggestions()

      dispatch(entitySlice.actions.add({ data: response.data }))

      const suggestedUsers = denormalizedJsonApiResponse(response, 'shoutoutSuggestion').map(suggestion => ({
        suggestionId: suggestion.id,
        numMeetings: suggestion.numMeetings,
        ...suggestion.suggestedUser,
      }))

      dispatch(shoutoutsSlice.actions.setShoutoutSuggestions(suggestedUsers))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(shoutoutsSlice.actions.isLoadingShoutoutSuggestions(false))
    }
  },
  fetchShoutouts: queryParams => async (dispatch) => {
    dispatch(shoutoutsSlice.actions.isLoading(true))

    try {
      const response = await API.shoutouts.fetchAll({ ...queryParams })
      const newQueryParams = queryParamsFromHeaders(response)

      dispatch(shoutoutsSlice.actions.setQueryParams(newQueryParams))
      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(showToastMessage({ message: I18N('error_fetching'), type: 'error' }))
    } finally {
      dispatch(shoutoutsSlice.actions.isLoading(false))
    }
  },
  fetchShoutout: shoutoutId => async (dispatch) => {
    dispatch(entitySlice.actions.remove({ id: shoutoutId, type: 'shoutout' }))
    dispatch(shoutoutsSlice.actions.isLoading(true))

    try {
      const response = await API.shoutouts.fetch(shoutoutId)
      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      dispatch(showToastMessage({ message: I18N('error_fetching_detail'), type: 'error' }))
    } finally {
      dispatch(shoutoutsSlice.actions.isLoading(false))
    }
  },
  deleteShoutout: shoutout => async (dispatch) => {
    dispatch(shoutoutsSlice.actions.isSaving(true))

    try {
      await API.shoutouts.destroy(shoutout)

      dispatch(shoutoutsSlice.actions.removeNewShoutoutId(shoutout.id))
      dispatch(entitySlice.actions.remove({ id: shoutout.id, type: 'shoutout' }))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(shoutoutsSlice.actions.isSaving(false))
    }
  },
  clearShoutoutSuggestions: () => async (dispatch) => {
    try {
      await API.shoutouts.clearSuggestions()

      dispatch(shoutoutsSlice.actions.setShoutoutSuggestions([]))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    }
  },
}

const selectors = {
  getMetaData: () => state => state.shoutouts.meta,
  getShoutouts: () => state => sortByDate(build(state.entities, 'shoutout') ?? [], 'createdAt'),
  getShoutout: shoutoutId => state => build(state.entities, 'shoutout', shoutoutId) ?? {},
  getNewShoutouts: () => state => state.shoutouts.newShoutoutIds.map(id => build(state.entities, 'shoutout', id)).filter(Boolean),
  getCompanyValues: () => state => state.shoutouts.companyValueIds.map(id => build(state.entities, 'companyValue', id)).filter(Boolean),
  getShoutoutSuggestions: () => state => state.shoutouts.shoutoutSuggestions,
}

export default {
  ...shoutoutsSlice,
  selectors,
  asyncActions,
}
