import React from 'react'
import { useSelector } from 'react-redux'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useWindowResize from 'components/common/hooks/useWindowResize'
import useRedirectToFirstPage from 'components/pages/hooks/useRedirectToFirstPage'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import EmptyPage from 'components/pages/emptyPage'
import pageSlice from 'redux/slices/pages'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'

const PageRedirectorPage = () => {
  const { inMobileApp } = useCurrentUser()
  const { isSmallScreen } = useWindowResize()
  useRedirectToFirstPage(!inMobileApp && !isSmallScreen)
  const { filteredPages, workspaces } = useWorkspaceUrls()
  const arePagesOrWorkspacesEmpty = _.isEmpty(filteredPages) || _.isEmpty(workspaces)
  const { isLoadingAllPages } = useSelector(pageSlice.selectors.getPagesMetadata())
  const { isLoadingAllWorkspaces } = useSelector(pageWorkspaceSlice.selectors.getMetaData())

  if (isLoadingAllPages || isLoadingAllWorkspaces) {
    return <></>
  }

  if (arePagesOrWorkspacesEmpty) {
    return <EmptyPage />
  }

  return <></>
}

export default PageRedirectorPage
