import React, { useEffect } from 'react'

import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import { StalePageType } from 'types/analytics/pages'
import BackButton from 'components/common/backButton'
import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'

const I18N = i18nPath('views.platform_analytics.pages.stale')

const backToText = i18nPath('views.platform_analytics')('title')

const womanCelebratingPath = '/images/analytics/womanCelebratingIcon.svg'

const columns = [
  {
    header: I18N('page'),
    col: 'col-title',
    style: { width: '70%' },
    accessor: (page: StalePageType) => <a href={page.url}>{page.title}</a>,
  },
  {
    header: I18N('last_updated'),
    col: 'col-last-updated',
    style: { width: '30%' },
    accessor: (page: StalePageType) => i18nMoment(page.lastPublishedAt).format('MMMM D, YYYY'),
  },
]

const StalePagesPage = () => {
  const dispatch = useDispatch()

  const {
    data, totalItems, page, totalPages,
  } = useSelector(pageAnalyticsSlice.selectors.getStalePages())

  const { stalePages: { isLoading } } = useSelector(pageAnalyticsSlice.selectors.getMetaData())

  const isStalePagesEmpty = totalItems === 0 && !isLoading

  const fetchData = (page: number) => {
    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchStalePages({ page }))
  }

  useEffect(() => {
    fetchData(1)
  }, [])

  const handlePaginationClick = ({ page }) => fetchData(page)

  return (
    <main className='include-default-grid mb-5'>
      <header className='grid-span-12'>
        <BackButton
          backToText={backToText}
          url='/platform_analytics/pages'
          className='my-5'
        />
      </header>

      {isStalePagesEmpty ? (
        <EmptyStateCard
          title={I18N('title', { count: 0 })}
          iconPath={womanCelebratingPath}
          text={I18N('empty_state_text')}
          classname='grid-span-12'
        />
      ) : (
        <CardWithPaginatedTable
          title={I18N('title', { count: totalItems })}
          tableColumns={columns}
          tableData={data}
          classname='grid-span-12'
          isLoading={isLoading}
          showPagination={totalPages > 1}
          page={page}
          totalPages={totalPages}
          onPaginationClick={handlePaginationClick}
        />
      )}
    </main>
  )
}

export default StalePagesPage
