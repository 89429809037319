import React from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import ReactSelect from 'components/common/react_select'

import EditableContent from 'components/common/editable_content'
import EditableRichTextContent from 'components/common/editable_content/editableRichTextContent'
import EditableContentTrigger from 'components/common/editable_content/editableContentTrigger'
import EditableSelectContent from 'components/common/editable_content/editableSelectContent'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import UserSkillTag from 'components/profiles/userSkillTag'

const Tags = () => (
  <div className='d-flex flex-wrap'>
    <UserSkillTag text='foo' />
    <UserSkillTag text='bar' />
    <UserSkillTag text='blort' />
    <UserSkillTag text='jackdaws' />
    <UserSkillTag text='lorema' />
    <UserSkillTag text='ipsum' />
    <UserSkillTag text='fool' />
    <UserSkillTag text='ba' />
    <UserSkillTag text='blorts' />
    <UserSkillTag text='lorem' />
    <UserSkillTag text='ipsum' />
    <UserSkillTag text='jackdaws' />
  </div>
)

const tiptapToolbar = [
  'bold',
  'italic',
  'underline',
  'emoji',
  'undo',
  'redo',
]

const onSave = (value) => {
  alert(`value to save: ${value}`)
  return new Promise(resolve => resolve(true))
}

const EditableContentPage = () => (
  <main className='Styleguide EditableContentPage container mt-4'>
    <h1><StyleguideBaseLink /> / Editable Content</h1>
    <section className='mt-4'>
      <table className='table'>
        <thead>
          <tr>
            <th>Component Name</th>
            <th>Description</th>
            <th>Can Edit?</th>
            <th style={{ width: '40%' }}>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              EditableContent
            </td>
            <td>
              empty state, no content
            </td>
            <td>
              yes
            </td>
            <td>
              <EditableContent
                canEdit
                displayElement={<span className='font-weight-500'>+ Add Content</span>}
                inputElement={<input />}
                valueToEdit=''
                saveContent={onSave}
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableContent
            </td>
            <td>
              existing plain text
            </td>
            <td>
              yes
            </td>
            <td>
              <EditableContent
                canEdit
                displayElement={<span>display text</span>}
                inputElement={<input />}
                valueToEdit='display text'
                saveContent={onSave}
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableContent
            </td>
            <td>
              with plain text
            </td>
            <td>
              no
            </td>
            <td>
              <EditableContent
                displayElement={<span>display text</span>}
                inputElement={<input />}
                valueToEdit='display text'
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableRichTextContent
            </td>
            <td>
              using Tiptap editor, with linked text
            </td>
            <td>
              yes
            </td>
            <td>
              <EditableRichTextContent
                canEdit
                displayElement={(
                  <span>
                    The quick <a href='http://google.com'>brown fox jumps over</a> the lazy dog. Something something jackdaws.
                  </span>
                )}
                inputElement={<TiptapEditor toolbarItems={tiptapToolbar} />}
                saveContent={onSave}
                valueToEdit={(
                  <span>
                    The quick <a href='http://google.com'>brown fox jumps over</a> the lazy dog. Something something jackdaws.
                  </span>
                )}
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableRichTextContent
            </td>
            <td>
              using Tiptap editor, with linked text
            </td>
            <td>
              no
            </td>
            <td>
              <EditableRichTextContent
                displayElement={(
                  <span>
                    The quick <a href='http://google.com'>brown fox jumps over</a> the lazy dog. Something something jackdaws.
                  </span>
                )}
                inputElement={<TiptapEditor toolbarItems={tiptapToolbar} />}
                saveContent={onSave}
                valueToEdit={(
                  <span>
                    The quick <a href='http://google.com'>brown fox jumps over</a> the lazy dog. Something something jackdaws.
                  </span>
                )}
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableContent
            </td>
            <td>
              with tags
            </td>
            <td>
              yes
            </td>
            <td>
              <EditableContent
                canEdit
                displayElement={<Tags />}
                inputElement={<input />}
                valueToEdit='tag editing to come'
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableContent
            </td>
            <td>
              with tags
            </td>
            <td>
              no
            </td>
            <td>
              <EditableContent
                displayElement={<Tags />}
                inputElement={<input />}
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableContent
            </td>
            <td>
              with characters remaining
            </td>
            <td>
              yes
            </td>
            <td>
              <EditableContent
                canEdit
                showCharCount
                valueToEdit='Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper.'
                displayElement={(
                  <div>
                    Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
                    Suspendisse urna nibh, viverra non, semper.
                  </div>
                )}
                inputElement={<textarea className='w-100' />}
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableSelectContent
            </td>
            <td>
              editable select
            </td>
            <td>
              yes
            </td>
            <td>
              <EditableSelectContent
                canEdit
                displayElement={<div>Westeros</div>}
                inputElement={<ReactSelect />}
                valueToEdit='Westeros'
                saveContent={item => new Promise(resolve => resolve())}
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableContentTrigger
            </td>
            <td>
              triggers an action on click
            </td>
            <td>
              yes
            </td>
            <td>
              <EditableContentTrigger
                canEdit
                onClick={() => alert('Triggered!')}
                displayElement={<span className='font-weight-500'>Edit with trigger</span>}
              />
            </td>
          </tr>
          <tr>
            <td>
              EditableContentTrigger
            </td>
            <td>
              triggers an action on click
            </td>
            <td>
              no
            </td>
            <td>
              <EditableContentTrigger
                onClick={() => alert('Triggered!')}
                displayElement={<span className='font-weight-500'>Trigger</span>}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
)

export default EditableContentPage
