import React from 'react'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import IntroductionInvitationSelector from 'components/journeys/introductions/introductionInvitationSelector'
import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.journeys.introduction_detail')

const AddMoreButton = ({
  onAddInvitation,
}) => (
  <div className='AddMoreButtonContainer mb-2'>
    <Button
      className='pl-0'
      variant='link link-color text-decoration-none'
      onClick={onAddInvitation}
    >
      + {I18N('add_more')}
    </Button>
  </div>
)

const SelectColleaguesSection = ({
  introduction,
  invitations,
  setInvitations,
  className = '',
}) => {
  const {
    completed: isCompleted,
    permittedActions,
  } = introduction

  const isAllowedToAddMore = !isCompleted && permittedActions?.complete
  const currentInviteeIds = invitations?.map(invitation => invitation.invitee?.id).filter(Boolean) || []
  const forUserId = introduction?.step?.forUser?.id
  const journeyBlueprintType = introduction?.step?.journey?.journeyBlueprint?.type || 'onboarding'

  const onAddInvitation = () => {
    setInvitations([...invitations, {}])
  }

  const onUpdateInvitation = (currentIndex, invitee) => {
    const newInvitee = currentInviteeIds.includes(invitee?.id) || invitee?.id === forUserId ? null : invitee

    const updatedInvitation = {
      ...invitations[currentIndex],
      invitee: newInvitee,
    }

    const newInvitations = [...invitations]

    newInvitations.splice(currentIndex, 1, updatedInvitation)

    setInvitations(newInvitations)
  }

  const onRemoveInvitation = (currentIndex) => {
    if (!_.isNull(currentIndex)) {
      const newInvitations = invitations
      delete newInvitations[currentIndex]

      setInvitations(newInvitations.filter(Boolean))
    }
  }

  const descriptionText = () => {
    const alreadyCreatedInvitations = invitations?.filter(invitation => invitation?.id).length > 0
    const automaticallyScheduleCalendarInvites = _.get(introduction, 'automaticallyScheduleCalendarInvites', true)

    return I18N(`${journeyBlueprintType}.select_colleagues_description.${automaticallyScheduleCalendarInvites ? 'automatic' : 'manual'}.${alreadyCreatedInvitations ? 'sent' : 'not_sent'}`)
  }

  return (
    <div className={classNames('SelectColleaguesSection', className)}>
      <h6 className='mb-1'>{I18N('select_colleagues')}</h6>
      <p className='text-secondary'>{descriptionText()}</p>
      {invitations?.map((invitation, index) => (
        <IntroductionInvitationSelector
          key={index}
          currentIndex={index}
          invitation={invitation}
          introduction={introduction}
          onUpdateInvitation={onUpdateInvitation}
          onRemoveInvitation={onRemoveInvitation}
        />
      ))}
      {isAllowedToAddMore && (
        <AddMoreButton
          onAddInvitation={onAddInvitation}
        />
      )}
    </div>
  )
}

export default SelectColleaguesSection
