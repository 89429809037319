import React, { useEffect, useState } from 'react'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import managedGroupSlice, { buildManagedGroupPayload } from 'redux/slices/managedGroups'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Error404 from 'components/errors/404'
import { Button } from 'components/common/buttons'
import { isAudienceValid } from 'utils/audience'
import ManagedGroupForm from 'components/admin/managed_groups/managedGroupForm'
import DeleteManagedGroupButtonAndModal from 'components/admin/managed_groups/deleteManagedGroupButtonAndModal'

const I18N = i18nPath('views.admin.managed_groups')

const EditManagedGroupPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { managedGroupId } = useParams()
  const [workingCopy, setWorkingCopy] = useState(null)
  const managedGroup = useSelector(managedGroupSlice.selectors.getManagedGroup(managedGroupId))

  const {
    isLoading, isNotFound, isSaving,
  } = useSelector(managedGroupSlice.selectors.getMetaData())

  const hasChanges = !_.isEqual(
    buildManagedGroupPayload(managedGroup),
    buildManagedGroupPayload(workingCopy, managedGroup)
  )

  const isNameEmpty = _.isEmpty(workingCopy?.name)
  const isChannelsEmpty = workingCopy?.managedGroupChannel?.length === 0
  const areNeededFieldsPresent = !isNameEmpty && !isChannelsEmpty && isAudienceValid(workingCopy)
  const isSaveButtonDisabled = !hasChanges || isLoading || !areNeededFieldsPresent

  const onSuccess = () => {
    history.push('/admin/managed_groups')
  }

  const onSaveClick = () => {
    dispatch(managedGroupSlice.asyncActions.admin.updateManagedGroup(workingCopy, managedGroup, onSuccess))
  }

  useEffect(() => {
    if (managedGroup) {
      setWorkingCopy(managedGroup)
    }
  }, [managedGroup?.id])

  useEffect(() => {
    dispatch(managedGroupSlice.asyncActions.admin.fetchManagedGroup(managedGroupId))
  }, [managedGroupId])

  if (isNotFound) {
    return <Error404 />
  }

  if (_.isNull(workingCopy) || isLoading) {
    return <CirclesLoadingIndicator className='mt-4' />
  }

  return (
    <div className='EditManagedGroupPage'>
      <header className='AdminHeader d-flex flex-row justify-content-between'>
        <h3>{I18N('edit_managed_group')}</h3>
      </header>
      <main className='AdminContent'>
        <ManagedGroupForm workingCopy={workingCopy} setWorkingCopy={setWorkingCopy} />
        <div className='d-flex justify-content-between mt-3'>
          <DeleteManagedGroupButtonAndModal managedGroup={managedGroup} />
          <Button
            disabled={isSaveButtonDisabled}
            onClick={onSaveClick}
            showLoadingSpinner={isSaving}
          >
            {I18NCommon('save')}
          </Button>
        </div>
      </main>
    </div>
  )
}

export default EditManagedGroupPage
