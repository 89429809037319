import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import StatisticsCard from './statisticsCard'

const I18N = i18nPath('views.ai_assistant.dashboard.main_content.dashboard')

const Statistics = ({ dashboardData }) => {
  const timeSavedInHours = Math.floor((dashboardData?.timeSavedInSeconds || 0) / 3600)
  const questionsAnswered = dashboardData?.questionsAnswered || 0

  return (
    <div className='Dashboard'>
      <div className='Dashboard__statistics d-flex gap-5'>
        <StatisticsCard
          icon='/images/chatCheckIcon.svg'
          statistic={questionsAnswered}
          description={I18N('statistics.questions_answered.description')}
          tooltip={I18N('statistics.questions_answered.tooltip')}
          isEmptyState={questionsAnswered === 0}
        />

        <StatisticsCard
          icon='/images/clockIcon.svg'
          statistic={I18N('hour', { count: timeSavedInHours })}
          description={I18N('statistics.time_saved.description')}
          tooltip={I18N('statistics.time_saved.tooltip')}
          isEmptyState={timeSavedInHours === 0}
        />

        <StatisticsCard
          icon='/images/chatQuestionIcon.svg'
          isEmptyState={false}
          statistic={dashboardData?.faqsCreated}
          description={I18N('statistics.faqs_created.description')}
          tooltip={I18N('statistics.faqs_created.tooltip')}
        />
      </div>
    </div>
  )
}

export default Statistics
