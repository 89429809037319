import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import Modal from 'components/common/modal'
import { ButtonSmallNarrow } from 'components/common/buttons'
import VideoRecorder from 'components/common/videoRecorder'

const I18N = i18nPath('views.profile.video_introduction_modal')
const I18NCommon = i18nPath('views.common')

const ModalHeader = () => (
  <div className='mb-4'>
    <h3 className='d-flex align-items-center'>{I18N('title')}</h3>
    <div className='text-secondary'>{I18N('explanation')}</div>
  </div>
)

const ModalFooter = ({
  toggle,
  save,
  isSaving,
  isSaveButtonDisabled,
}) => (
  <div className='d-flex justify-content-end align-items-center mt-4'>
    <a className='text-muted-dark mr-4' onClick={toggle}>{I18NCommon('cancel')}</a>
    <ButtonSmallNarrow
      onClick={save}
      showLoadingSpinner={isSaving}
      disabled={isSaveButtonDisabled}
    >
      {I18NCommon('save')}
    </ButtonSmallNarrow>
  </div>
)

const VideoIntroductionModal = ({
  isOpen,
  toggle,
  recordingUrl,
  update,
  canEdit,
}) => {
  const [videoFile, setVideoFile] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const [willDeleteRecording, setWillDeleteRecording] = useState(false)

  const save = async () => {
    setIsSaving(true)
    const changes = {}
    if (videoFile) {
      changes.videoIntroductionRecording = videoFile
    }
    if (willDeleteRecording) {
      changes.videoIntroductionRecording = null
    }
    if (!_.isEmpty(changes)) {
      await update(changes)
    }

    toggle()
    setIsSaving(false)
  }


  return (
    <Modal className='VideoIntroductionModal' visible={isOpen} toggle={toggle}>
      {canEdit && (
        <>
          <ModalHeader />
          <VideoRecorder
            setVideoFile={setVideoFile}
            recordingUrl={recordingUrl}
            willDeleteRecording={willDeleteRecording}
            setWillDeleteRecording={setWillDeleteRecording}
            setIsSaveButtonDisabled={setIsSaveButtonDisabled}
          />
          <ModalFooter
            toggle={toggle}
            save={save}
            isSaving={isSaving}
            isSaveButtonDisabled={isSaveButtonDisabled}
          />
        </>
      )}
      {!canEdit && <video controls src={recordingUrl} />}
    </Modal>
  )
}

export default VideoIntroductionModal
