import StatusLabel, { StatusLabelWithTooltip } from 'components/common/status_label'
import React from 'react'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'

const STATUS_COLORS_MAP = {
  draft: 'gray',
  pending: 'yellow',
  scheduled: 'yellow',
  published: 'green',
}

const I18N = i18nPath('views.admin.article_list')

const ArticleStatusLabel = ({ article }) => {
  const { status, publishedAt } = article
  const color = STATUS_COLORS_MAP[status]

  switch (status) {
  case 'draft':
    return <StatusLabel color={color}>{I18N('status.draft')}</StatusLabel>
  case 'pending':
    return (
      <StatusLabelWithTooltip
        color={color}
        text={I18N('pending_tooltip')}
      >
        {I18N('status.pending')}
      </StatusLabelWithTooltip>
    )
  case 'published':
    return (
      <StatusLabelWithTooltip
        color={color}
        text={I18N('published_at', { date: i18nMoment(publishedAt).format('lll') })}
      >
        {I18N('status.published')}
      </StatusLabelWithTooltip>
    )
  case 'scheduled':
    return (
      <StatusLabelWithTooltip
        color={color}
        text={I18N('scheduled_at', { date: i18nMoment(publishedAt).format('lll') })}
      >
        {I18N('status.scheduled')}
      </StatusLabelWithTooltip>
    )
  default:
    // defaults to draft for new articles
    return <StatusLabel color={color}>{I18N('status.draft')}</StatusLabel>
  }
}

export default ArticleStatusLabel
