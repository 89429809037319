import usePreviewableArticle from 'components/admin/articles/hooks/usePreviewableArticle'
import ArticleCard from 'components/articles/articleCard'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useApi from 'components/common/hooks/useApi'
import React, { useCallback, useEffect, useState } from 'react'
import API from 'services/api'

const ArticleSummaryEditor = ({
  workingCopy,
  updateWorkingCopy,
  isEditingTemplate = false,
}) => {
  const [previewableArticle, { isPreviewing, togglePreview }] = usePreviewableArticle(workingCopy)
  const [editorKey, setEditorKey] = useState(0)

  const [generateSummary, { isLoading: isGeneratingSummary }] = useApi(
    API.admin.htmlSummaries.create,
    {
      onSuccess: (data) => {
        updateWorkingCopy({ cardContent: data.summary })

        // For some reason, changing the cardContent doesn't trigger a correct update in the editor
        // So let's mount a new editor to force the update.
        setEditorKey(editorKey => editorKey + 1)
      },
    }
  )

  const regenerateSummary = useCallback(() => {
    generateSummary(
      'article',
      workingCopy.content,
      { clearyRichTextId: workingCopy.contentId, groupId: workingCopy.groupId }
    )
  }, [workingCopy.content, workingCopy.contentId, workingCopy.groupId])


  useEffect(() => {
    if (workingCopy.draft && workingCopy.content && !workingCopy.cardContent) {
      regenerateSummary()
    }
  }, [])

  return (
    <div className='ArticleSummaryEditor'>
      {isGeneratingSummary ? (
        <CirclesLoadingIndicator />
      ) : (
        <ArticleCard
          key={editorKey}
          tracking={false}
          article={previewableArticle}
          onChangeCard={html => updateWorkingCopy({ cardContent: html })}
          readOnly={false}
          togglePreview={togglePreview}
          isPreviewing={isPreviewing}
          isEditingTemplate={isEditingTemplate}
          regenerateSummary={regenerateSummary}
        />
      )}
    </div>
  )
}

export default ArticleSummaryEditor
