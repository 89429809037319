import CelebrationInputs from 'components/celebrations/celebrationInputs'
import GreetingCardBannerNewCelebrationDisplay from 'components/celebrations/greetingCardBannerNewCelebrationDisplay'
import { Button } from 'components/common/buttons'
import useDebouncedDependencies from 'components/common/hooks/useDebouncedDependencies'
import useFetch from 'components/common/hooks/useFetch'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import CdnSvg from 'components/common/cdnSvg'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import API from 'services/api'
import { I18NCommon, i18nDisplayDateWithoutYear, i18nPath } from 'utils/i18nHelpers'
import usePreviousDiff from 'components/common/hooks/usePreviousDiff'

const I18N = i18nPath('views.celebrations.greeting_card.editor')


const nextEventDate = (pastDate) => {
  let newEventDate = moment(pastDate)
  newEventDate = newEventDate.set('year', moment().year())
  if (newEventDate.isBefore(moment())) newEventDate = newEventDate.add(1, 'year')
  return newEventDate.format('YYYY-MM-DD')
}

const useCta = ({
  isRecentCelebrationInTheFuture,
  isRecentCelebrationInThePast,
  recentCelebration,
  create,
}) => {
  const history = useHistory()
  const ctaKey = isRecentCelebrationInTheFuture ? 'customize_and_sign_card' : 'create_and_customize_card'

  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false)

  const onClick = () => {
    if (isRecentCelebrationInTheFuture) {
      history.push(`/people/celebrations/${recentCelebration.id}/edit`)
    } else if (isRecentCelebrationInThePast) {
      setShowCreateConfirmation(true)
    } else {
      create()
    }
  }

  return {
    onClick,
    showCreateConfirmation,
    setShowCreateConfirmation,
    ctaKey,
  }
}

const useRecentCelebration = ({ userId, celebrationType }) => {
  const { data: rawRecentCelebration, isLoading: isLoadingRecentCelebration } = useFetch(
    API.users.recentCelebration,
    [userId, celebrationType],
    { load: !!userId && !!celebrationType }
  )

  const recentCelebration = (
    rawRecentCelebration?.user?.id === userId
    && !isLoadingRecentCelebration
  ) ? rawRecentCelebration : null

  const isRecentCelebrationInTheFuture = useMemo(() => {
    if (!recentCelebration) return false


    const momentEventDate = moment(recentCelebration.eventDate)
    return momentEventDate.isAfter(moment())
  }, [recentCelebration])

  const isRecentCelebrationInThePast = !!recentCelebration && !isRecentCelebrationInTheFuture
  const recentCelebrationDateFormatted = recentCelebration?.eventDate ? moment(recentCelebration.eventDate).format('LL') : null


  return {
    recentCelebration,
    isRecentCelebrationInTheFuture,
    isRecentCelebrationInThePast,
    recentCelebrationDateFormatted,
    isLoadingRecentCelebration,
  }
}

const useCelebratedUser = ({ userId }) => {
  const { data: rawCelebratedUser, isLoading: isLoadingCelebratedUser } = useFetch(
    API.getUserProfile,
    [userId],
    { load: !!userId }
  )

  const celebratedUser = rawCelebratedUser?.id === userId && !isLoadingCelebratedUser ? rawCelebratedUser : null

  return {
    celebratedUser,
    isLoadingCelebratedUser,
  }
}

const isEventDateDisabled = ({ isRecentCelebrationInTheFuture, celebratedUser, workingCopy }) => {
  if (isRecentCelebrationInTheFuture) return true
  if (celebratedUser?.birthday && workingCopy.type === 'birthday') return true
  if (celebratedUser?.startDate && workingCopy.type === 'work_anniversary') return true
  return false
}

const NewCelebrationEditor = ({
  workingCopy,
  setWorkingCopy,
  create,
  isCreating,
  isValid,
  isLoadingTemplates,
}) => {
  const dateFormatted = workingCopy.eventDate ? i18nDisplayDateWithoutYear(workingCopy.eventDate) : null

  const userId = workingCopy.user?.id
  const celebrationType = workingCopy.type

  const {
    recentCelebration,
    isRecentCelebrationInTheFuture,
    isRecentCelebrationInThePast,
    recentCelebrationDateFormatted,
    isLoadingRecentCelebration,
  } = useRecentCelebration({ userId, celebrationType })

  const {
    celebratedUser,
    isLoadingCelebratedUser,
  } = useCelebratedUser({ userId })

  const eventDateDisabled = isEventDateDisabled({ isRecentCelebrationInTheFuture, celebratedUser, workingCopy })

  const {
    onClick,
    showCreateConfirmation,
    setShowCreateConfirmation,
    ctaKey,
  } = useCta({
    isRecentCelebrationInTheFuture,
    isRecentCelebrationInThePast,
    recentCelebration,
    create,
  })

  // Debouncing the deps to avoid multiple effects being triggered
  const debouncedDeps = useDebouncedDependencies([
    recentCelebration?.id,
    celebratedUser?.id,
    workingCopy.type,
  ])

  useEffect(() => {
    if (isRecentCelebrationInTheFuture) {
      setWorkingCopy(currentWorkingCopy => ({
        ...currentWorkingCopy,
        eventDate: recentCelebration.eventDate,
      }))
    } else if (workingCopy.type === 'birthday' && celebratedUser?.birthday) {
      setWorkingCopy(currentWorkingCopy => ({
        ...currentWorkingCopy,
        eventDate: nextEventDate(celebratedUser.birthday),
      }))
    } else if (workingCopy.type === 'work_anniversary' && celebratedUser?.startDate) {
      setWorkingCopy(currentWorkingCopy => ({
        ...currentWorkingCopy,
        eventDate: nextEventDate(celebratedUser.startDate),
      }))
    } else if (workingCopy.eventDate) {
      setWorkingCopy(currentWorkingCopy => ({
        ...currentWorkingCopy,
        eventDate: null,
      }))
    }
  }, debouncedDeps)

  const previousEventType = usePreviousDiff(workingCopy.type)

  useEffect(() => {
    if (previousEventType === 'new_employee_welcome' && celebratedUser?.hidden) {
      setWorkingCopy(currentWorkingCopy => ({
        ...currentWorkingCopy,
        user: null,
      }))
    }
  }, [previousEventType, celebratedUser?.id])

  return (
    <>
      <GreetingCardBannerNewCelebrationDisplay />
      <CelebrationInputs
        celebration={workingCopy}
        setWorkingCopy={setWorkingCopy}
        eventDateDisabled={eventDateDisabled}
      />
      <div className='text-center mt-4'>
        <Button
          onClick={onClick}
          disabled={!isValid || isLoadingTemplates || isLoadingRecentCelebration || isLoadingCelebratedUser}
          showLoadingSpinner={isCreating && !showCreateConfirmation}
        >
          {I18N(ctaKey)}
        </Button>

        {showCreateConfirmation && (
          <ConfirmationModal
            visible
            toggle={() => setShowCreateConfirmation(false)}
            title={I18N('create_card_confirmation_modal.title', { celebration_type: recentCelebration.humanType, user: workingCopy.user.preferredName })}
            onConfirmClick={create}
            isSaving={isCreating}
            confirmText={I18NCommon('create')}
          >
            <p className='text-secondary'>
              {I18N('create_card_confirmation_modal.description', {
                celebration_type: recentCelebration.humanType,
                user: workingCopy.user.preferredName,
                date: recentCelebrationDateFormatted,
              })}

              <br />

              <Link to={`/people/celebrations/${recentCelebration.id}`} className='font-weight-400'>
                <span className='mr-2'>
                  {I18N('create_card_confirmation_modal.click_here_to_view_card')}
                </span>
                <CdnSvg src='/images/externalLinkIcon.svg' className='svg-link' />
              </Link>
            </p>
          </ConfirmationModal>
        )}

        {workingCopy.user && dateFormatted && (
          <p className='text-center text-muted mt-3 px-2'>
            {I18N('helper_text', { user: workingCopy.user.preferredFullName, date: dateFormatted })}
          </p>
        )}
      </div>
    </>
  )
}

export default NewCelebrationEditor
