import { Button } from 'components/common/buttons'
import React from 'react'
import { Link } from 'react-router-dom'
import managedGroupSlice from 'redux/slices/managedGroups'
import { useDispatch, useSelector } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import InfoTooltip from 'components/common/infoTooltip'

import { i18nPath } from 'utils/i18nHelpers'
import ManagedGroupsTable from 'components/admin/managed_groups/managedGroupsTable'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.managed_groups')

const CreateNewGroupButton = () => (
  <Link className='CreateNewButton' to='/admin/managed_groups/new'>
    <Button>
      {I18N('manage_group_membership')}
    </Button>
  </Link>
)

const SyncManagedGroupsButton = () => {
  const dispatch = useDispatch()
  const { appName } = useCurrentCompany()
  const { isSyncing } = useSelector(managedGroupSlice.selectors.getMetaData())

  const onSuccess = () => {
    dispatch(showToastMessage({ message: I18N('sync_sucess_message', { appName }), type: 'success' }))
  }

  const onSyncClick = () => {
    dispatch(managedGroupSlice.asyncActions.admin.syncManagedGroups(onSuccess))
  }

  return (
    <Button
      className='mr-2'
      variant='secondary'
      onClick={onSyncClick}
      showLoadingSpinner={isSyncing}
    >
      {I18N('sync_now')}
      <InfoTooltip
        text={I18N('sync_now_tooltip', { appName })}
        tooltipClassName='mt-2'
      />
    </Button>
  )
}

const ManagedGroupListPage = () => (
  <div className='ManagedGroupListPage'>
    <header className='AdminHeader d-flex flex-row justify-content-between'>
      <h3>{I18N('group_membership')}</h3>
      <div>
        <SyncManagedGroupsButton />
        <CreateNewGroupButton />
      </div>
    </header>
    <main className='AdminContent'>
      <ManagedGroupsTable />
    </main>
  </div>
)

export default ManagedGroupListPage
