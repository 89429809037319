import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import pageSlice from 'redux/slices/pages'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import PageVersionHistory from 'components/pages/versions/pageVersionHistory'
import useHideNavbar from 'components/navbar/hooks/useHideNavbar'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'

const PageHistoryPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pageId } = useParams()
  const page = useSelector(pageSlice.selectors.getPage(pageId))
  const { isLoading } = useSelector(pageSlice.selectors.getPagesMetadata())
  const isPageLoaded = !_.isEmpty(page) && !_.isEmpty(page.permittedActions)
  const editPagePath = useWorkspaceUrls().generatePathToPage(page, 'edit')

  useHideNavbar()

  useEffect(() => {
    if (!isPageLoaded) {
      dispatch(pageSlice.asyncActions.fetch(pageId))
    }
  }, [pageId])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='PageHistoryPage position-relative'>
      <PageVersionHistory
        page={page}
        closeVersionHistoryView={() => history.push(editPagePath)}
      />
    </div>
  )
}

export default PageHistoryPage
