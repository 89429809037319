import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import moment from 'moment'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { buildStepPayload } from 'redux/slices/journeys/steps'
import {
  DEFAULT_TO_CORRESPONDENT_ROLE,
  defaultStepAttributes,
  getMeetingCalendarEventDescriptionText,
  getMeetingCalendarEventTitle
} from 'utils/journeys/templatesDefaultHelpers'

const I18N = i18nPath('views.admin.journeys.meetings')

export const MEETING_ATTRIBUTES = [
  'rangeStartAt',
  'rangeEndAt',
  'scheduleSpecificallyAt',
  'meetingDuration',
  'calendarEventTitle',
  'calendarEventDescription',
  'hourWindowStart',
  'hourWindowEnd',
  'fallbackTimezone',
  'scheduleWhenAttendeesAvailable',
  'createVideoConference',
]

export const MEETING_INTERPOLATED_FIELDS = ['calendarEventTitle', 'calendarEventDescription']


const buildMeetingPayload = (meeting) => {
  const newMeeting = _.pick(meeting, [...MEETING_ATTRIBUTES, 'id'])

  if (meeting.stepAttributes) {
    newMeeting.stepAttributes = buildStepPayload(meeting.stepAttributes)
  }

  return newMeeting
}

export const defaultWorkingCopy = journeyBlueprintType => ({
  ...defaultStepAttributes,
  rangeStartAt: moment().add(1, 'days').set({ hour: 9, minute: 0, second: 0 }).format(),
  rangeEndAt: moment().add(8, 'days').set({ hour: 17, minute: 0, second: 0 }).format(),
  scheduleSpecificallyAt: moment().add(1, 'days').set({ hour: 9, minute: 0, second: 0 }).format(),
  fromCorrespondentAttributes: {},
  toCorrespondentAttributes: { role: DEFAULT_TO_CORRESPONDENT_ROLE[journeyBlueprintType] },
  meetingDuration: 30,
  calendarEventTitle: getMeetingCalendarEventTitle(journeyBlueprintType),
  calendarEventDescription: getMeetingCalendarEventDescriptionText(journeyBlueprintType),
  hourWindowStart: moment.utc('01-01-2020 9:00', 'MM-DD-YYYY h:mm').format(),
  hourWindowEnd: moment.utc('01-01-2020 17:00', 'MM-DD-YYYY h:mm').format(),
  fallbackTimezone: 'America/Los_Angeles',
  scheduleWhenAttendeesAvailable: true,
  createVideoConference: true,
})

export const initialState = {
  meetingIds: [],
  meta: {
    ...defaultMeta,
  },
}

const meetingSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

_.assign(meetingSlice, {
  asyncActions: {
    admin: {
      fetchMeeting: id => async (dispatch) => {
        dispatch(meetingSlice.actions.isLoading(true))
        dispatch(meetingSlice.actions.isNotFound(false))

        try {
          const response = await API.admin.meetings.fetch(id)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(meetingSlice.actions.isNotFound(true))
        } finally {
          dispatch(meetingSlice.actions.isLoading(false))
        }
      },

      updateMeeting: (workingCopy, onSuccess = () => {}) => async (dispatch) => {
        dispatch(meetingSlice.actions.isSaving(true))

        try {
          const response = await API.admin.meetings.update(buildMeetingPayload(workingCopy))
          dispatch(entitySlice.actions.update({ data: response.data }))
          dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
          onSuccess()
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(meetingSlice.actions.setError(error))
        } finally {
          dispatch(meetingSlice.actions.isSaving(false))
        }
      },

      deleteMeeting: (id, onSuccess = () => {}) => async (dispatch, getState) => {
        try {
          await API.admin.meetings.destroy({ id })
          const meeting = build(getState().entities, 'meeting', id)
          const stepId = meeting?.step?.id
          dispatch(entitySlice.actions.remove({ type: 'meeting', id }))
          dispatch(entitySlice.actions.remove({ type: 'step', id: stepId }))
          dispatch(showToastMessage({ message: I18N('successfully_deleted'), type: 'success' }))
          onSuccess()
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(meetingSlice.actions.setError(error))
        }
      },
    },
  },
})

_.assign(meetingSlice, {
  selectors: {
    getMetaData: () => state => state.meetings.meta,

    getMeeting: id => state => build(state.entities, 'meeting', id),
  },
})

export default meetingSlice
