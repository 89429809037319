/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import { LoadingContainer, TableLoadingContainer } from 'components/common/loadingContainer'

import adminArticleSlice from 'redux/slices/admin/articles'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import articleStatsSlice from 'redux/slices/articleStats'
import SmartTable from 'components/common/tables/smartTable'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import ReactSelect from 'components/common/react_select'
import DetailUserActionsModal from 'pages/admin/articles/emails/detailUserActionsModal'
import BackButton from 'components/common/backButton'
import CdnSvg from 'components/common/cdnSvg'

const searchIconPath = '/images/searchIcon.svg'

const I18N = i18nPath('views.admin.article_stats')

const statusOptions = ['all', 'clicked', 'opened', 'delivered', 'not_delivered'].map(status => ({
  value: status,
  label: I18N(`email_status.${status}`),
}))

const ArticleStatsEmailEngagementPage = ({ isGroupPost = false }) => {
  const dispatch = useDispatch()
  const [searchBy, setSearchBy] = useState('')
  const [statusFilter, setStatusFilter] = useState('all')
  const [detailedUserModal, setDetailedUserModal] = useState(null)
  const { isLoading, isNotFound } = useSelector(adminArticleSlice.selectors.getMetaData())
  const {
    isLoading: isLoadingUsers,
    queryParams, isLoadingGeneratingCsv,
  } = useSelector(articleStatsSlice.selectors.getMetaData())
  const users = useSelector(articleStatsSlice.selectors.getUsers())
  const article = useSelector(adminArticleSlice.selectors.getArticle())
  const { articleId } = useParams()

  const {
    page, totalPages, total,
  } = queryParams

  useEffect(() => {
    dispatch(adminArticleSlice.asyncActions.fetchArticle(articleId))
    return () => {
      dispatch(adminArticleSlice.actions.clearArticleId())
    }
  }, [])

  useEffect(() => {
    dispatch(articleStatsSlice.asyncActions.fetchArticleEmailEngagementUsers(
      articleId, { q: searchBy, statusFilter }))
  }, [searchBy, statusFilter])

  const handlePaginationClick = ({ page, sortBy, sortDir }) => {
    dispatch(articleStatsSlice.asyncActions.fetchArticleEmailEngagementUsers(
      articleId, {
        page, sortBy, sortDir, q: searchBy, statusFilter,
      }))
  }

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status.value)
  }

  const resendEmail = (userId) => {
    dispatch(adminArticleSlice.asyncActions.resendEmail(articleId, userId))
  }

  const exportCsv = () => {
    dispatch(articleStatsSlice.asyncActions.exportEmailEngagementUsersCsv(articleId))
  }

  const columns = [{
    header: I18N('person'),
    accessor: d => <EmployeeMiniCard employee={d} showEmployeeTitle={false} photoSize='30px' padding='0' />,
  }, {
    header: I18N('status'),
    accessor: d => I18N(`email_status.${d.emailStatus}`),
  }, {
    header: I18N('email_clicks'),
    id: 'email_clicked_count',
    accessor: d => d.emailClickedCount,
  }, {
    headerKey: 'actions',
    accessor: d => (
      <DropdownMenuContainer closeMenuOnItemClick>
        <CardDropdownMenuItem
          className='py-2'
          width='150px'
          primaryText={I18N('view_details')}
          onClick={() => setDetailedUserModal(d)}
        />
        <CardDropdownMenuItem
          className='py-2'
          width='150px'
          primaryText={I18N('resend_email')}
          onClick={() => resendEmail(d.id)}
        />
      </DropdownMenuContainer>
    ),
  }]


  return (
    <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
      <div className='ArticleStatsEmailEngagementPage'>
        <header className='AdminHeader pt-4 pb-4'>
          <div className='width-container'>

            <BackButton
              backToText={I18N('article_stats')}
              url={`${isGroupPost ? '/posts/' : '/admin/articles/'}${article?.id}/stats`}
              className='link-color bold mb-4'
            />

            <div className='d-flex justify-content-between'>
              <h4 className='mb-0'>
                {I18N('article_stats_email_engagement_header', { articleTitle: article?.title })}
              </h4>

              <div className='AdminButtonContainer'>
                <Button onClick={exportCsv} showLoadingSpinner={isLoadingGeneratingCsv}>
                  {I18N('export_csv')}
                </Button>
              </div>
            </div>
          </div>
        </header>

        <main className='AdminContent'>
          <div className='width-container'>
            <div className='mb-4 d-flex'>
              <AsyncSearchInput
                placeholder={I18N('users_search_placeholder')}
                icon={<CdnSvg src={searchIconPath} />}
                onKeyUp={setSearchBy}
                className='mb-0 mr-2'
              />

              <div className='d-flex justify-content-center align-items-center'>
                <p className='text-secondary mb-0 mr-2'>{I18N('email_status.label')}</p>
                <ReactSelect
                  className='email-status-select'
                  options={statusOptions}
                  onChange={handleStatusFilterChange}
                  value={statusOptions.filter(o => o.value === statusFilter)}
                />
              </div>
            </div>

            <TableLoadingContainer isLoading={isLoadingUsers}>
              <SmartTable
                className='ArticleEmailsTable white-bg-table'
                data={users}
                columns={columns}
                page={page}
                pages={totalPages}
                totalCount={total}
                perPage={20}
                showPagination
                onPaginationClick={handlePaginationClick}
                onSortChange={handlePaginationClick}
              />
            </TableLoadingContainer>

            {detailedUserModal && (
              <DetailUserActionsModal
                user={detailedUserModal}
                onClose={() => setDetailedUserModal(null)}
                articleId={articleId}
              />
            )}
          </div>
        </main>
      </div>
    </LoadingContainer>
  )
}

export default ArticleStatsEmailEngagementPage
