import React from 'react'

const CalendarIcon = ({
  className, width = 18, height = 16, fillColor = 'var(--kebab-menu-icon-color)',
}) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    version='1.1'
  >
    <title>Icons/calendar-outline</title>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-538.000000, -2370.000000)'>
        <g transform='translate(535.000000, 2368.000000)'>
          <rect fill='#000000' fillRule='nonzero' opacity='0' x='0' y='0' width='24' height='24' />
          <path
            d='M16,2 C16.5522847,2 17,2.44771525 17,3 L17,3 L17,4 L18,4 C19.6568542,4 21,5.34314575 21,7 L21,7 L21,19 C21,20.6568542 19.6568542,22 18,22 L18,22 L6,22 C4.34314575,22 3,20.6568542 3,19 L3,19 L3,7 C3,5.34314575 4.34314575,4 6,4 L6,4 L7,4 L7,3 C7,2.44771525 7.44771525,2 8,2 C8.55228475,2 9,2.44771525 9,3 L9,3 L9,4 L15,4 L15,3 C15,2.44771525 15.4477153,2 16,2 Z M19,13 L5,13 L5,19 C5,19.5522847 5.44771525,20 6,20 L6,20 L18,20 C18.5522847,20 19,19.5522847 19,19 L19,19 L19,13 Z M8,15 C8.55228475,15 9,15.4477153 9,16 C9,16.5522847 8.55228475,17 8,17 C7.44771525,17 7,16.5522847 7,16 C7,15.4477153 7.44771525,15 8,15 Z M16,15 C16.5522847,15 17,15.4477153 17,16 C17,16.5522847 16.5522847,17 16,17 L16,17 L12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 L12,15 Z M7,6 L6,6 C5.44771525,6 5,6.44771525 5,7 L5,7 L5,11 L19,11 L19,7 C19,6.44771525 18.5522847,6 18,6 L18,6 L17,6 L17,7 C17,7.55228475 16.5522847,8 16,8 C15.4477153,8 15,7.55228475 15,7 L15,7 L15,6 L9,6 L9,7 C9,7.55228475 8.55228475,8 8,8 C7.44771525,8 7,7.55228475 7,7 L7,7 L7,6 Z'
            id='Combined-Shape'
            fill={fillColor}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default CalendarIcon
