import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import CardWithPaginatedTable from 'components/analytics/common/cardWithPaginatedTable'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import TabHeader from 'components/analytics/helpers/tabHeader'
import useTabHeader from 'components/analytics/helpers/useTabHeader'
import BackButton from 'components/common/backButton'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import useQueryParams from 'components/common/hooks/useQueryParams'
import cultureAnalyticsSlice from 'redux/slices/analytics/culture'
import { CultureShoutoutUserType } from 'types/analytics/culture'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const backToText = i18nPath('views.platform_analytics')('title')
const womanWithComputerIcon = '/images/analytics/womanWithComputerIcon.svg'

const ShoutoutActivityPage = () => {
  const dispatch = useDispatch()

  const { type = 'sent' } = useQueryParams()

  // The word 'type' is used in the backend to determine if it is 'sent' or 'received'.
  // That is why I am using 'reduxType' to refer to the name used in the slice.
  const reduxType = type === 'sent' ? 'mostShoutoutsSent' : 'mostShoutoutsReceived'
  const I18N = i18nPath(`views.platform_analytics.culture.shoutouts.${type}`)

  const {
    periodStart,
    setPeriodStart,
    periodEnd,
    setPeriodEnd,
    targetingRules,
    setTargetingRules,
    completedTargetingRules,
    timeRange,
    setTimeRange,
  } = useTabHeader()

  const {
    data, totalPages, page, totalItems,
  } = useSelector(cultureAnalyticsSlice.selectors.getShoutoutActivity(reduxType))
  const { [reduxType]: { isLoading } } = useSelector(cultureAnalyticsSlice.selectors.getMetaData())

  const fetchData = ({ page }) => {
    dispatch(cultureAnalyticsSlice.asyncActions.admin.fetchShoutoutActivity(
      reduxType,
      {
        periodStart, periodEnd, page, type, targetingRules: completedTargetingRules,
      }
    ))
  }

  useEffect(() => {
    fetchData({ page: 1 })
  }, [periodStart, periodEnd, completedTargetingRules])

  const areShoutoutsEmpty = totalItems === 0 && !isLoading

  const columns = [
    {
      header: I18NCommon('user'),
      accessor: (user: CultureShoutoutUserType) => (
        <div className='d-flex flex-row align-items-center'>
          <EmployeeThumbnailPhoto employee={user} size='25px' />
          <Link to={`/profile/${user.username}`} className='text-truncate w-100 d-block ml-1' target='_blank'>{user.preferredFullName}</Link>
        </div>
      ),
    },
    {
      header: type === 'sent' ? I18N('sent') : I18N('received'),
      style: { width: '15%' },
      accessor: (user: CultureShoutoutUserType) => user.count,
    },
  ]

  return (
    <main className='include-default-grid mb-5'>
      <BackButton
        backToText={backToText}
        url='/platform_analytics/culture'
        className='grid-span-12 my-4'
      />

      <TabHeader
        className='mb-4'
        targetingRules={targetingRules}
        setTargetingRules={setTargetingRules}
        periodStart={periodStart}
        setPeriodStart={setPeriodStart}
        periodEnd={periodEnd}
        setPeriodEnd={setPeriodEnd}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />

      {areShoutoutsEmpty ? (
        <EmptyStateCard
          title={I18N('title')}
          text={I18N('empty')}
          iconPath={womanWithComputerIcon}
          classname='grid-span-12'
        />
      ) : (
        <CardWithPaginatedTable
          title={I18N('title')}
          tableColumns={columns}
          tableData={data}
          classname='grid-span-12'
          isLoading={isLoading}
          showPagination={totalPages > 1}
          page={page}
          totalPages={totalPages}
          onPaginationClick={fetchData}
        />
      )}

    </main>
  )
}

export default ShoutoutActivityPage
