import React, { useEffect } from 'react'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import Card from 'components/common/card'
import API from 'services/api'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import useApi from 'components/common/hooks/useApi'
import AddLinkButton from 'components/home/widgets/links_widget/addLinkButton'
import LinkListItem from 'components/home/widgets/links_widget/linkListItem'
import { PREBOARDING_LINKS_WIDGET_TYPE } from 'utils/layout/widgetsMap'

const LinksWidget = ({ widget, resizeWidget, isEditing }) => {
  const [fetchLinks, { data: fetchedLinks, isLoading, isLoaded }] = useApi(() => (API.layout.links.fetchAll(widget.id)))

  useEffect(() => {
    if (!isEditing) {
      fetchLinks()
    }
  }, [])

  const links = isEditing ? widget.commonLinks : fetchedLinks

  const showAddLinkButton = widget.type !== PREBOARDING_LINKS_WIDGET_TYPE

  return (
    <Card
      className='HomePageWidget LinksWidget'
    >
      <WidgetTitle
        title={widget.title}
        rightContent={(
          showAddLinkButton && <AddLinkButton widgetId={widget.id} onLinkAdded={fetchLinks} />
        )}
      />

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <AutoResizableScrollableSection
          widget={widget}
          resizeWidget={resizeWidget}
          isLoaded={isLoaded}
          scrollableContentClassName='LinksGrid'
          showScrollbarOutside
        >
          {links?.map(link => (
            <LinkListItem
              key={`LinkListItem-${link.id}`}
              link={link}
              widgetId={widget.id}
              fetchLinks={fetchLinks}
            />
          ))}
        </AutoResizableScrollableSection>
      )}
    </Card>
  )
}

export default LinksWidget
