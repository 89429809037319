import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import ClearyCard from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import InfoTooltip from 'components/common/infoTooltip'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.ai_assistant.dashboard.main_content.dashboard.statistics')

interface StatisticsCardProps {
  icon: string
  isEmptyState: boolean
  statistic: string | number
  description: string
  tooltip: string
}

const StatisticsCard = ({
  icon,
  isEmptyState,
  statistic,
  description,
  tooltip,
}: StatisticsCardProps) => (
  <ClearyCard className='pt-4 pb-4'>
    <div className='tooltip-icon position-absolute top-2 right-2'>
      <InfoTooltip text={tooltip} hide={300} />
    </div>

    <div className='d-flex gap-2'>
      <div>
        <div className='icon'>
          <CdnSvg src={icon} />
        </div>
      </div>
      <div className='d-flex flex-column gap-3'>
        {isEmptyState ? (
          <div>
            <p className='bold text-small m-0'>{I18N('empty_state')}</p>
          </div>
        ) : (
          <div>
            <p className='bold text-3xl m-0'>{statistic}</p>
          </div>
        )}

        <p className='text-normal text-secondary m-0'>{description}</p>
      </div>
    </div>
  </ClearyCard>
)

export default StatisticsCard
