import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import ClearyInternalSearchResultsTemplate from './clearyInternalSearchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const I18N = i18nPath('views.search.search_results.search_results')
const lightBulbIconPath = '/images/lightBulbIcon.svg'

const getCountLabel = (count) => {
  if (count === 0) return null

  const countLabelKey = `user_skill_count_${count === 1 ? 'singular' : 'plural'}`

  return I18N(countLabelKey, { count })
}

const UserSkillResults = ({ showLabel = true }) => {
  const mapUserSkillToSearchResultTemplate = (result) => {
    const { userSkill, highlights, displayPath } = result
    const { name, activeUsersCount } = { ...userSkill, ...highlights }

    return (
      <SearchResultTemplate
        displayPath={displayPath}
        image={<CdnSvg src={lightBulbIconPath} />}
        title={name}
        subtitle={getCountLabel(activeUsersCount)}
        result={result}
      />
    )
  }

  return (
    <ClearyInternalSearchResultsTemplate
      showLabel={showLabel}
      label='user_skills'
      type='userSkill'
      searchResultToTemplateMap={mapUserSkillToSearchResultTemplate}
    />
  )
}

export default UserSkillResults
