import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import MegaphoneIcon from 'components/icons/megaphoneIcon'
import SendShoutoutButton from 'components/shoutouts/sendShoutoutButton'
import classNames from 'classnames'

const I18N = i18nPath('views.shoutouts_feed')

const ShoutoutsEmpty = ({ className }) => (
  <div className={classNames('ShoutoutsEmpty text-center mt-5', className)}>
    <div className='mt-4'>
      <div className='mb-1'>
        <MegaphoneIcon />
      </div>

      <h3 className='mt-4'>{I18N('send_a_shoutout')}</h3>

      <div className='text-color-secondary mb-5'>{I18N('no_recent_shoutouts')}</div>

      <SendShoutoutButton />
    </div>
  </div>
)


export default ShoutoutsEmpty
