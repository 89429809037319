import React from 'react'

import ImageSelector from 'components/common/imageSelector'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CdnSvg from 'components/common/cdnSvg'

const thinPlusIconPath = '/images/thinPlusIcon.svg'

const AddProfilePicture = ({
  isLoading,
  isVisible,
  onImageAdded,
}) => (
  <div className='AddProfilePicture position-relative'>
    {isVisible && (
      <div className='pointer d-flex align-items-center justify-content-center'>
        <ImageSelector
          onImageAdded={onImageAdded}
          imageText={<CdnSvg className='PlusIcon' src={thinPlusIconPath} />}
          cropImage
        />
      </div>
    )}
    {isLoading && <CirclesLoadingIndicator className='center-v-and-h' />}
  </div>
)

export default AddProfilePicture
