import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import settingSlice from 'redux/slices/settings'

const I18N = i18nPath('views.settings.checkboxes')

const SettingCheckbox = ({ name }) => {
  const dispatch = useDispatch()
  const setting = useSelector(settingSlice.selectors.getSetting(name))

  const onChange = (e) => {
    dispatch(settingSlice.asyncActions.update(name, { enabled: e.target.checked }))
  }

  const id = `${name}-checkbox`

  return (
    <div className='d-flex align-items-center'>
      <input
        id={id}
        type='checkbox'
        checked={!!setting?.value?.enabled}
        onChange={onChange}
      />
      <label htmlFor={id} className='ml-2 mb-0'>{I18N(name)}</label>
    </div>
  )
}

export default SettingCheckbox
