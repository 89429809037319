import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import userSlice from 'redux/slices/users'
import DateBasedCelebrationsGrid from 'components/people/dateBasedCelebrationsGrid'
import { trackEvent } from 'services/tracker'

const NewEmployeesPage = () => {
  const dispatch = useDispatch()

  const dispatchFetchEmployees = (filter, paginationParams = { page: 1, perPage: 12 }) => {
    dispatch(userSlice.asyncActions.fetchNewEmployees(filter, paginationParams))
  }

  useEffect(() => {
    dispatchFetchEmployees()
    trackEvent('new_employee:view_all')
  }, [])

  const thisWeeksNewEmployees = useSelector(userSlice.selectors.getThisWeeksNewEmployees())
  const lastWeeksNewEmployees = useSelector(userSlice.selectors.getLastWeeksNewEmployees())

  const employeesSelector = (filter) => {
    if (filter === 'last_week') return lastWeeksNewEmployees
    return thisWeeksNewEmployees
  }

  return (
    <DateBasedCelebrationsGrid
      employeesSelector={employeesSelector}
      i18nPathStr='views.employees'
      dispatchFetchEmployees={dispatchFetchEmployees}
      fetchMeta={userSlice.selectors.getNewEmployeesMetaData}
      hideNextWeek={true}
      reportType='new_employees'
    />
  )
}

export default NewEmployeesPage
