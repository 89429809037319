import React from 'react'
import classNames from 'classnames'

import PeopleSettingsCardMenuDropdown from 'components/home/peopleSettingsCardMenuDropdown'
import CelebrationImage from 'components/celebrations/celebrationImage'

const CelebrationCardHeader = ({
  celebration,
  isCurrentUser,
  feedItem = null,
  onExcludeFromFeed = () => { },
}) => {
  const imageUrl = celebration.feedImageUrl || celebration.coverImageUrl

  return (
    <>
      {!isCurrentUser && feedItem && (
        <div className='position-absolute Card-dropdownMenu ClickWrapperTargetContainer'>
          <PeopleSettingsCardMenuDropdown
            feedItem={feedItem}
            handleExcludeFromFeed={onExcludeFromFeed}
          />
        </div>
      )}
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center Card-hero CelebrationCard-hero ClickWrapperTargetContainer position-relative overflow-hidden',
          celebration.typeWithPrefix,
          { 'confetti-background': !imageUrl }
        )}
      >
        {imageUrl && (!isCurrentUser || celebration.openedAt) ? (
          <img src={imageUrl} className='w-100' />
        ) : (
          <CelebrationImage
            type={celebration.typeWithPrefix}
            isCustomCelebration={celebration.customCelebration}
          />
        )}
      </div>
    </>
  )
}

export default CelebrationCardHeader
