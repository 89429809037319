import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import LineGraph from 'components/analytics/common/lineGraph'
import { PERCENTAGE } from 'components/analytics/common/numberPercentageToggle'
import { safePercentage } from 'pages/admin/articles/stats/helpers'
import { generateLabels, secondsToLabel } from './utils'

const I18N = i18nPath('views.admin.article_stats')

const options = {
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 4,
        maxRotation: 0,
      },
    },
    y: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 4,
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        title() {
          return ''
        },
        label(context) {
          const value = `${context.parsed.y}%`

          return `${context.label} ${value} ${I18N('videos.watched')}`
        },
      },
    },
  },
}

interface Props {
  series: { [key: number]: number }
  duration: number
  averageWatched: number
}

const Graph = ({ series, duration, averageWatched }: Props) => {
  const data = Object.values(series)
  const scalingFactor = duration <= data.length ? 1 : Math.ceil(duration / data.length)

  return (
    <>
      <h5 className='inner-title'>
        {I18N('videos.drop_off')}
        <span>
          {I18N('videos.drop_off_description', {
            avgWatched: `${safePercentage(averageWatched, duration, 0)} (${secondsToLabel(averageWatched)})`,
          })}
        </span>
      </h5>

      <LineGraph
        labels={generateLabels(duration, scalingFactor)}
        dataValues={data}
        classname='w-100'
        type={PERCENTAGE}
        chartOptions={options}
        dataValuesOptions={{ tension: 0.1 }}
      />
    </>
  )
}

export default Graph
