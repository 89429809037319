import React, { useRef } from 'react'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'
import { usePagesNav } from 'components/pages/pagesNav/context'

const PagesNavComplexTreeItemTitle = ({
  item,
  title,
  context,
}) => {
  const possiblePagePaths = useWorkspaceUrls().generateAllPagePaths(item)
  const path = possiblePagePaths[0]
  const location = useLocation()
  const isCurrentNodeActive = possiblePagePaths.includes(location.pathname)
  const { isDraggingRef } = usePagesNav()
  const displayTitle = item.draft ? item.draftTitle : title

  return (
    <Link
      {...context.interactiveElementProps}
      to={path}
      className={classNames('ItemTitle', { isCurrentNodeActive })}
      onDragStart={(e) => {
        isDraggingRef.current = true
        context.interactiveElementProps.onDragStart(e)
      }}
      title={displayTitle}
      onDragEnd={(e) => {
        isDraggingRef.current = false
        // The version of react-complex-tree we are using does not call onDragEnd
        // But we don't want to break the drag and drop functionality if the library is updated
        // and it starts calling onDragEnd
        if (context.interactiveElementProps.onDragEnd) {
          context.interactiveElementProps.onDragEnd(e)
        }
      }}
    >
      {item.draft && <span className='text-small text-secondary pr-2 font-weight-normal'>{I18n.t('views.common.draft')}</span>}
      {displayTitle}
    </Link>
  )
}

export default PagesNavComplexTreeItemTitle
