const sortByDate = (array, dateAttribute) => array.slice().sort(
  (a, b) => new Date(b[dateAttribute]) - new Date(a[dateAttribute])
)

const sortByDateAsc = (array, dateAttribute) => array.slice().sort(
  (a, b) => new Date(a[dateAttribute]) - new Date(b[dateAttribute])
)

const sortByNearestDate = (array, dateAttribute) => {
  const now = new Date()

  return array.slice().sort((a, b) => {
    const distanceA = Math.abs(now - new Date(a[dateAttribute]))
    const distanceB = Math.abs(now - new Date(b[dateAttribute]))
    return distanceA - distanceB
  })
}

export { sortByDate, sortByDateAsc, sortByNearestDate }
