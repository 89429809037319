import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { checkForError } from 'utils/errorHandling'
import { SurveyAnswerType } from 'types/surveys/answer'
import queryParamsFromHeaders, { defaultPaginationParams, PaginationParams } from 'utils/queryParamsFromHeaders'

export const initialState = {
  responseIds: [],
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams,
      perPage: 10,
    },
  },
}

export const defaultWorkingCopy = {
  state: '',
  form: null,
  answers: [] as SurveyAnswerType[],
}

export interface SurveyResponsesState {
  meta: DefaultMetaType & {
    queryParams: PaginationParams
  }
}

const surveyResponseSlice = createSlice({
  name: 'surveyResponses',
  initialState,
  reducers: {
    ...defaultActions,

    setResponseIds(state, action) {
      state.responseIds = action.payload
    },

    clearResponseIds(state) {
      state.responseIds = []
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },
  },
})

const asyncActions = {
  admin: {
    fetch: (formId, responseId) => async (dispatch) => {
      dispatch(surveyResponseSlice.actions.isLoading(true))

      try {
        const response = await API.admin.surveyResponses.fetch(formId, responseId)

        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(surveyResponseSlice.actions.setError(error))
      } finally {
        dispatch(surveyResponseSlice.actions.isLoading(false))
      }
    },

    fetchAll: (formId, queryParams = {}, onSuccess = () => {}) => async (dispatch) => {
      dispatch(surveyResponseSlice.actions.clearResponseIds())
      dispatch(surveyResponseSlice.actions.isLoading(true))

      try {
        const response = await API.admin.surveyResponses.fetchAll(formId, queryParams)

        const newQueryParams = queryParamsFromHeaders(response)
        const responseIds = response.data.data.map(surveyResponse => surveyResponse.id)
        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(surveyResponseSlice.actions.setResponseIds(responseIds))
        dispatch(surveyResponseSlice.actions.setQueryParams(newQueryParams))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(surveyResponseSlice.actions.isLoading(false))
      }
    },
  },

  create: (formId : string, onSuccess = (id: string) => {}, params = {}) => async (dispatch) => {
    dispatch(surveyResponseSlice.actions.isLoading(true))

    try {
      const response = await API.surveyResponses.create(formId, params)

      dispatch(entitySlice.actions.add({ data: response.data }))
      onSuccess(response.data.data.id)
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(surveyResponseSlice.actions.setError(error))
    } finally {
      dispatch(surveyResponseSlice.actions.isLoading(false))
    }
  },

  submit: (formId: string, responseId: string | null, onSuccess = () => {}) => async (dispatch) => {
    dispatch(surveyResponseSlice.actions.isSaving(true))

    try {
      const response = await API.surveyResponses.submit(formId, responseId)

      dispatch(entitySlice.actions.update({ data: response.data }))
      onSuccess()
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(e.response)
      dispatch(surveyResponseSlice.actions.setError(error))
    } finally {
      dispatch(surveyResponseSlice.actions.isSaving(false))
    }
  },
}

const selectors = {
  getResponse: (responseId: string | null) => state => build(state.entities, 'surveyResponse', responseId) || {},
  getResponses: () => state => state.surveyResponses.responseIds.map(responseId => build(state.entities, 'surveyResponse', responseId)),
  getMetaData: () => state => state.surveyResponses.meta,
}

export default {
  ...surveyResponseSlice,
  selectors,
  asyncActions,
}
