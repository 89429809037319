import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import useQueryParams from 'components/common/hooks/useQueryParams'
import classNames from 'classnames'
import { randomReactKey } from 'components/common/utils'

const I18N = i18nPath('views.search.search_results.filters')

const PageFilter = ({ updateHistory }) => {
  const { filters = '{}' } = useQueryParams()
  const { type = 'all' } = JSON.parse(filters)

  const filterOptions = [
    'all',
    'page',
    'page_workspace',
  ]

  const handleSearch = (selectedFilter) => {
    const filters = selectedFilter === 'all' ? null : { type: selectedFilter }

    updateHistory(filters)
  }

  return (
    <div className='PageFilter'>
      <div className='pb-2'>
        {filterOptions.map(filterOption => (
          <div
            className={classNames('PageFilterLink py-2 px-3', {
              isActive: filterOption === type,
            })}
            onClick={() => handleSearch(filterOption)}
            key={randomReactKey()}
          >
            {I18N(`pages_${filterOption}_filter`)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default PageFilter
