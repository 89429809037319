import React, { useState } from 'react'

import AudiencePreferredChannelModal from 'components/common/audience/audiencePreferredChannelModal'
import useAudienceUserIds from 'components/common/hooks/useAudienceUserIds'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import { I18NCommon, i18nFormat, i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import InfoTooltip from 'components/common/infoTooltip'
import classNames from 'classnames'

const EstimatedAudiencePreferredChannelAvatars = ({
  targetingRules,
  translationsPath,
  channelOverrides = [],
  articleTagIds,
  includePreferredChannels = false,
  hasTooltip = false,
  moduleName = '',
}) => {
  const I18N = i18nPath(translationsPath)
  const {
    isLoading,
    audienceUserIds,
  } = useAudienceUserIds({
    targetingRules,
    allowLoadEntireCompany: true,
    newsSubscribedTagIds: articleTagIds,
    moduleName,
    endpoint: API.audiences.estimateWithNewsSubscriptions,
  })
  const [showAudienceModal, setShowAudienceModal] = useState(false)

  if (isLoading) {
    return (
      <div className='text-left text-normal text-secondary ml-3 mb-0'>{I18NCommon('loading')}</div>
    )
  }

  if (_.isEmpty(audienceUserIds)) {
    return (
      <div className='text-left text-normal text-secondary ml-3 mb-0'>{I18N('no_users_targeted')}</div>
    )
  }

  return (
    <>
      <RoundedAvatarsWithAsyncUserFetching
        avatarSize='30px'
        userIds={audienceUserIds}
        popOverPlacement='auto'
        onMoreUsersClick={() => setShowAudienceModal(true)}
      />
      <div
        onClick={() => setShowAudienceModal(true)}
        className={classNames('text-left text-normal text-secondary ml-3 mb-0 pointer', { 'flex-grow-1': !hasTooltip })}
      >
        {I18N('will_be_included', { count: audienceUserIds.length })}
      </div>
      {hasTooltip && (
        <div className='flex-grow-1 pointer' onClick={() => setShowAudienceModal(true)}>
          <InfoTooltip
            text={I18N('tooltip_text')}
            className='svg-secondary'
          />
        </div>
      )}
      {showAudienceModal && (
        <AudiencePreferredChannelModal
          userIds={audienceUserIds}
          showAudienceModal={showAudienceModal}
          closeModal={() => setShowAudienceModal(false)}
          channelOverrides={channelOverrides}
          summary={I18N('audience_modal_description')}
          includePreferredChannels={includePreferredChannels}
        />
      )}
    </>
  )
}

export default EstimatedAudiencePreferredChannelAvatars
