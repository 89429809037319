import { ButtonSmallNarrow } from 'components/common/buttons'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import React from 'react'


// the save action is called on onBlur
const ChatChannelsAsyncSelectForEditableContent = ({ onBlur = () => null, ...props }) => (
  <div>
    <ChatChannelsAsyncSelect {...props} autoFocus />

    <ButtonSmallNarrow
      onClick={onBlur}
      className='mt-2'
    >
      {I18n.t('views.common.save')}
    </ButtonSmallNarrow>
  </div>
)

export default ChatChannelsAsyncSelectForEditableContent
