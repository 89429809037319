import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'

const I18N = i18nPath('views.custom_styles.preview_components')

const BtnPrimaryColor = () => (
  <Button href='/admin/articles/new'>
    {I18N('create_article')}
  </Button>
)

export default BtnPrimaryColor
