import React from 'react'

import Card from 'components/common/card'
import TriggerOrDateSelect from 'components/admin/journeys/triggerOrDateSelect'
import { i18nPath } from 'utils/i18nHelpers'
import WeekendTriggerOptions from 'components/admin/journeys/common/weekendTriggerOptions'

const I18N = i18nPath('views.admin.journeys.task_templates')

const TaskableFormScheduleSection = ({
  workingCopy,
  updateWorkingCopy,
  isStepTemplate = true,
  isDisabled = false,
  journeyBlueprint,
}) => (
  <>
    <div className='font-weight-600 text-secondary mb-2'>{I18N('task_schedule')}</div>
    <Card>
      <TriggerOrDateSelect
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        label={I18N('task_assigned')}
        isDisabled={isDisabled}
        useTriggerSelect={isStepTemplate}
        journeyBlueprint={journeyBlueprint}
        triggers={[
          { name: 'trigger', label: I18N('task_assigned') },
          { name: 'due', label: I18N('task_due') },
        ]}
      />

      {isStepTemplate && <WeekendTriggerOptions workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} />}
    </Card>
  </>
)

export default TaskableFormScheduleSection
