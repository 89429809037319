import React, { useRef, useState } from 'react'
import { Popover } from 'reactstrap'

import CdnSvg from 'components/common/cdnSvg'
import useClickOutside from 'components/common/hooks/useClickOutside'
import { CHANNEL_TAB_MAPPING } from 'components/admin/journeys/communicationTemplates/communicationContent'
import CheckBox from 'components/common/buttons/checkBox'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.communication_templates.content')

const AddChannelLink = ({
  channelOptions,
  enabledChannelsCount,
  workingCopy,
  updateWorkingCopy,
}) => {
  const dropdownRef = useRef<any>(null)
  const [showDropdown, setShowDropdown] = useState(false)

  useClickOutside({
    ref: dropdownRef,
    onClickOutside: () => setShowDropdown(false),
    enableClickOutside: showDropdown,
  })

  return (
    <>
      <div
        className='d-flex align-items-center hover-highlight pointer text-secondary'
        onClick={() => setShowDropdown(!showDropdown)}
        id='add-channel-link'
      >
        {I18N('add_channel')}
      </div>

      <Popover
        placement='bottom'
        isOpen={showDropdown}
        target='add-channel-link'
        className='AddChannel-popover p-3'
      >
        <div ref={dropdownRef}>
          {channelOptions.map((channel) => {
            const { setting, label, iconSrc } = CHANNEL_TAB_MAPPING[channel]
            const isDisabled = workingCopy[setting] && enabledChannelsCount === 1
            return (
              <CheckBox
                key={channel}
                id={channel}
                checked={workingCopy[setting]}
                onChange={() => updateWorkingCopy({ [setting]: !workingCopy[setting] })}
                disabled={isDisabled}
                label={(
                  <>
                    <CdnSvg className='mr-2' src={iconSrc} />
                    {label}
                  </>
                )}
              />
            )
          })}
        </div>
      </Popover>
    </>
  )
}

export default AddChannelLink
