import React from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import BadgeCard from 'components/badges/badgeCard'
import { i18nPath } from 'utils/i18nHelpers'
import badgeIcon from 'assets/badgeIcon.png'

const I18N = i18nPath('views.custom_styles.preview_components')

const BadgesPage = () => {
  const badge1 = {
    id: '1',
    name: I18N('awarded_badge_name'),
    displayName: I18N('awarded_badge_display_name'),
    description: I18N('awarded_badge_description'),
    imageUrl: badgeIcon,
    type: 'standard',
  }

  const badge2 = {
    id: '2',
    name: I18N('awarded_badge_name'),
    displayName: I18N('awarded_badge_display_name'),
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada urna ac est sagittis rutrum. Aliquam ut eros quis justo lobortis condimentum vel quis ex. Ut vulputate vel sapien ut sagittis. Sed commodo fringilla cursus. Etiam vitae urna eu risus sagittis suscipit vel ut eros. Sed eu egestas orci. Nullam sodales, enim nec tempus eleifend, enim felis ullamcorper orci, at ultricies ante ex sit amet odio. Sed congue non eros quis vehicula.',
    imageUrl: badgeIcon,
    type: 'standard',
  }

  return (
    <main className='Styleguide BadgesPage container mt-4'>
      <h1><StyleguideBaseLink /> / Badges </h1>
      <section className='mt-4'>
        <h3>components/badges</h3>
        <table className='table table-hover table-responsive-sm'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>BadgeCard</td>
              <td>Card for showing badges in the list page.</td>
              <td>
                <BadgeCard badge={badge1} />
              </td>
            </tr>
            <tr>
              <td>BadgeCard</td>
              <td>Case when the description is very long: it truncates at 2 lines and adds three dots.</td>
              <td>
                <BadgeCard badge={badge2} />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default BadgesPage
