import { useState } from 'react'

const useGetLatLong = ({ onSuccess, onError }) => {
  const [isLoading, setIsLoading] = useState(false)

  const getLatLong = () => {
    setIsLoading(true)
    navigator.geolocation.getCurrentPosition(
      (position) => {
        onSuccess(position?.coords?.latitude, position?.coords?.longitude)
        setIsLoading(false)
      },
      (_error) => {
        setIsLoading(false)
        onError()
      }
    )
  }

  return { getLatLong, isLoading }
}

export default useGetLatLong
