import React from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import Tag from 'components/common/tag'
import UserSkillTag from 'components/profiles/userSkillTag'
import StatusLabel from 'components/common/status_label/'

import classNames from 'classnames'

const TagsPage = () => (
  <main className='Styleguide TagsPage container mt-4'>
    <h1><StyleguideBaseLink /> / Tags</h1>
    <section className='mt-4'>
      <h3>components/common/tag</h3>
      <table className='table'>
        <thead>
          <tr>
            <th>Component Name</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Tag
            </td>
            <td>
              Standard Tag
            </td>
            <td>
              <Tag text='My Sample Tag' />
            </td>
          </tr>
          <tr>
            <td>
              Tag
            </td>
            <td>
              Standard Tag with delete
            </td>
            <td>
              <Tag text='My Sample Tag' deleteIcon />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section className='mt-4'>
      <h3>components/profiles/userSkillTag</h3>
      <table className='table'>
        <thead>
          <tr>
            <th>Component Name</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              UserSkillTag
            </td>
            <td>
              User skill Tag
            </td>
            <td>
              <UserSkillTag text='My Sample Tag' />
            </td>
          </tr>
          <tr>
            <td>
              UserSkillTag
            </td>
            <td>
              User skill Tag
            </td>
            <td>
              <UserSkillTag text='My Sample Tag' showRemoveButton />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section className='mt-4'>
      <h3>components/common/status_label/</h3>
      <table className='table'>
        <thead>
          <tr>
            <th>Component Name</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              StatusLabel
            </td>
            <td>
              Gray Status label
            </td>
            <td>
              <StatusLabel color='gray'>Draft</StatusLabel>
            </td>
          </tr>
          <tr>
            <td>
              StatusLabel
            </td>
            <td>
              Red Status label
            </td>
            <td>
              <StatusLabel color='red'>Inactive</StatusLabel>
            </td>
          </tr>
          <tr>
            <td>
              StatusLabel
            </td>
            <td>
              Green Status label
            </td>
            <td>
              <StatusLabel color='green'>Active</StatusLabel>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
)

export default TagsPage
