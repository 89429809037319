import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import IntegrationCard from 'components/admin/integrations/integrationCard'
import itAdminSlice from 'redux/slices/itAdmin'
import { ButtonSmallNarrow } from 'components/common/buttons'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { showToastMessage } from 'redux/slices/toasts'
import UninstallOtherIntegration from 'components/admin/integrations/uninstallOtherIntegration'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AshbyInstallationComponent from 'components/admin/integrations/ashbyInstallationComponent'
import CdnSvg from 'components/common/cdnSvg'
import getCdnUrl from 'utils/getCdnUrl'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.integrations_page.people_section')

const greenhouseLogoPath = '/images/integrations/greenhouseLogo.svg'
const jobviteLogoPath = '/images/integrations/jobviteLogo.png'
const leverLogoPath = '/images/integrations/leverLogo.svg'
const ashbyLogoPath = '/images/integrations/ashbyLogo.svg'

const GreenhouseInstallationComponent = () => {
  const [greenhouseApiKey, setGreenhouseApiKey] = useState()

  const [installGreenhouse, { isLoading: isSaving }] = useApi(API.admin.itAdmin.installGreenhouse, {
    onSuccess: () => window.location.reload(true),

  })

  return (
    <div className='d-flex align-items-center'>
      <input
        className='flex-grow-1 mr-2'
        value={greenhouseApiKey}
        onChange={({ target }) => setGreenhouseApiKey(target.value)}
        placeholder={I18N('input_api_key')}
        data-testid='greenhouse-api-key-input'
      />
      <ButtonSmallNarrow
        className='my-1'
        onClick={() => installGreenhouse({ apiKey: greenhouseApiKey })}
        disabled={isSaving || !greenhouseApiKey}
        showLoadingSpinner={isSaving}
        id='greenhouse-submit-btn'
      >
        {I18NCommon('submit')}
      </ButtonSmallNarrow>
    </div>
  )
}

const LeverInstallationComponent = () => {
  const { leverInstallUrl } = useSelector(itAdminSlice.selectors.getData())

  return (
    <a className='btn btn-primary btn-sm btn-narrow my-1' href={leverInstallUrl} data-testid='lever-link'>
      {I18NCommon('install')}
    </a>
  )
}

const JobviteInstallationComponent = () => {
  const dispatch = useDispatch()

  const [jobviteApiKey, setJobviteApiKey] = useState()
  const [jobviteApiSecret, setJobviteApiSecret] = useState()
  const { isSaving } = useSelector(itAdminSlice.selectors.getMetaData())

  const updateData = () => {
    dispatch(itAdminSlice.asyncActions.admin.installJobvite({
      apiKey: jobviteApiKey,
      apiSecret: jobviteApiSecret,
    }))
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='flex-grow-1 mr-2'>
        <input
          className='mb-1 w-100'
          value={jobviteApiKey}
          onChange={({ target }) => setJobviteApiKey(target.value)}
          placeholder={I18N('input_api_key')}
          data-testid='jobvite-api-key-input'
        />
        <input
          className='mt-1 w-100'
          value={jobviteApiSecret}
          onChange={({ target }) => setJobviteApiSecret(target.value)}
          placeholder={I18N('input_api_secret')}
          data-testid='jobvite-api-secret-input'
        />
      </div>
      <ButtonSmallNarrow
        className='my-1'
        onClick={updateData}
        disabled={isSaving || !jobviteApiKey || !jobviteApiSecret}
        showLoadingSpinner={isSaving}
        id='jobvite-submit-btn'
      >
        {I18NCommon('submit')}
      </ButtonSmallNarrow>
    </div>
  )
}

const AtsSubsection = () => {
  const dispatch = useDispatch()
  const cdnDomain = useSelector(state => state.cdnDomain)

  const { atsIntegrationName, hasAtsIntegration, settings } = useCurrentCompany()
  const { isUninstalling } = useSelector(itAdminSlice.selectors.getMetaData())
  const { leverError } = useQueryParams()

  useEffect(() => {
    if (leverError) {
      dispatch(showToastMessage({ message: I18N('lever_error', { error: leverError }), type: 'error' }))
    }
  }, [leverError])


  const uninstallIntegration = (name) => {
    dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration(name))
  }

  const getInstallationComponent = (integrationName) => {
    if (!settings.journeys?.preboarding?.allowedToConnectAts || atsIntegrationName === integrationName) {
      return null
    }

    if (hasAtsIntegration) {
      return <UninstallOtherIntegration integrationName={integrationName} installedIntegration={atsIntegrationName} />
    }

    switch (integrationName) {
    case 'Lever':
      return <LeverInstallationComponent />
    case 'Greenhouse':
      return <GreenhouseInstallationComponent />
    case 'Jobvite':
      return <JobviteInstallationComponent />
    case 'Ashby':
      return <AshbyInstallationComponent />
    default:
      return null
    }
  }

  return (
    <>
      <div className='full-width'>{I18N('ats')}</div>
      <IntegrationCard
        id='greenhouse'
        logo={<CdnSvg src={greenhouseLogoPath} />}
        isInstalled={atsIntegrationName === 'Greenhouse'}
        installationComponent={getInstallationComponent('Greenhouse')}
        onUninstall={() => uninstallIntegration('greenhouse')}
        isUninstalling={isUninstalling.greenhouse}
      />
      <IntegrationCard
        id='jobvite'
        logo={<img src={getCdnUrl(cdnDomain, jobviteLogoPath)} alt='jobvite' />}
        isInstalled={atsIntegrationName === 'Jobvite'}
        installationComponent={getInstallationComponent('Jobvite')}
        onUninstall={() => uninstallIntegration('jobvite')}
        isUninstalling={isUninstalling.jobvite}
      />
      <IntegrationCard
        id='lever'
        logo={<CdnSvg src={leverLogoPath} />}
        isInstalled={atsIntegrationName === 'Lever'}
        installationComponent={getInstallationComponent('Lever')}
        onUninstall={() => uninstallIntegration('lever')}
        isUninstalling={isUninstalling.lever}
      />
      <IntegrationCard
        id='ashby'
        logo={<CdnSvg src={ashbyLogoPath} />}
        isInstalled={atsIntegrationName === 'Ashby'}
        installationComponent={getInstallationComponent('Ashby')}
        onUninstall={() => uninstallIntegration('ashby')}
        isUninstalling={isUninstalling.ashby}
        docLink='https://gocleary.zendesk.com/hc/en-us/articles/18157674126235-Ashby'
        docTitle={I18N('ashby_document')}
      />
    </>
  )
}

export default AtsSubsection
