import React, { useEffect, useState } from 'react'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import OneDriveIntegrationCard from 'components/settings/integrations/oneDriveIntegrationCard'
import ZoomIntegrationCard from 'components/settings/integrations/zoomIntegrationCard'
import API from 'services/api'
import SlackIntegrationCard from 'components/settings/integrations/slackIntegrationCard'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useFetch from 'components/common/hooks/useFetch'
import GoogleDriveIntegrationCard from 'components/settings/integrations/googleDriveIntegrationCard'

const IntegrationsSettingsSection = () => {
  const { isLoading, data } = useFetch(API.userIntegrations.fetchIntegrations)
  const [tempIntegrationsUninstalleds, setTempIntegrationsUninstalleds] = useState<Record<string, boolean>>({})

  const { settings } = useCurrentCompany()

  const showZoomIntegrationCard = settings.zoom?.enabled
  const { installed } = useQueryParams()

  useEffect(() => {
    // If the window was opened by another window, we let the other window know that the zoom integration was installed
    // For the zoom integration, we open a popup window to authorize the integration, and then we close it automatically after the user authorizes the integration
    if (installed === 'zoom' && window.opener) {
      window.opener.postMessage('zoomInstalled', '*')
    }
  }, [])

  if (isLoading) {
    return <CirclesLoadingIndicator className='flex-grow-1 d-flex justify-content-center' />
  }

  return (
    <div className='IntegrationsSettingsSection'>
      {showZoomIntegrationCard && (
        <ZoomIntegrationCard
          isInstalled={!tempIntegrationsUninstalleds.zoom && data?.zoomInstalled}
          afterUninstalled={() => setTempIntegrationsUninstalleds(itegrations => ({ ...itegrations, zoom: true }))}
        />
      )}

      {settings.oneDrive?.userConsentEnabled && (
        <OneDriveIntegrationCard
          isInstalled={!tempIntegrationsUninstalleds.oneDrive && data?.oneDriveInstalled}
          afterUninstalled={() => setTempIntegrationsUninstalleds(itegrations => ({ ...itegrations, oneDrive: true }))}
        />
      )}

      {settings.slackApp?.userConsentEnabled && (
        <SlackIntegrationCard
          isInstalled={!tempIntegrationsUninstalleds.slack && data?.slackInstalled}
          afterUninstalled={() => setTempIntegrationsUninstalleds(itegrations => ({ ...itegrations, slack: true }))}
        />
      )}

      {/* <GoogleDriveIntegrationCard
        isInstalled={!tempIntegrationsUninstalleds.googleDrive && data?.googleDriveInstalled}
      /> */}
    </div>
  )
}

export default IntegrationsSettingsSection
