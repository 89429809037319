import React from 'react'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'

import BackIcon from 'components/icons/backIcon'

const I18N = i18nPath('views.journeys.taskable_detail')

const BackToMyTasksButton = () => (
  <Link className='BackToMyTasksButton mb-3 text-secondary d-flex align-items-baseline font-weight-normal' to='/my_tasks'>
    <BackIcon />
    <span className='ml-2'>{I18N('back_to_my_tasks')}</span>
  </Link>
)

export default BackToMyTasksButton
