import RadioButton from 'components/form_fields/radioButton'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.auto_resizable')

const EditAutoResizable = ({ widget, updateWidget }) => {
  const updateAutoResizable = (value) => {
    updateWidget({ ...widget, autoResizable: value })
  }

  return (
    <div className='w-100'>
      <RadioButton
        id='dynamic_height'
        checked={widget.autoResizable}
        onChange={() => updateAutoResizable(true)}
        label={I18N('dynamic_height')}
      />
      <RadioButton
        id='fixed_height'
        checked={!widget.autoResizable}
        onChange={() => updateAutoResizable(false)}
        label={I18N('fixed_height')}
      />
    </div>
  )
}

export default EditAutoResizable
