import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import Modal from 'components/common/modal'
import Table from 'components/common/tables/table'
import CorrespondentSelect from 'components/admin/journeys/correspondentSelect'
import CancelButton from 'components/common/cancelButton'
import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'
import { isCorrespondentValid } from 'utils/admin/journeys/isStepTemplateValid'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.update_inactive_correspondents_modal')


const StepTemplateCorrespondentSelect = ({
  stepTemplateId,
  type,
  attributes,
  journeyBlueprintType,
  updateStepTemplate,
}) => {
  const updateWorkingCopy = updatedAttributes => updateStepTemplate(stepTemplateId, updatedAttributes)

  return (
    <CorrespondentSelect
      type={type}
      updateWorkingCopy={updateWorkingCopy}
      attributes={attributes}
      journeyBlueprintType={journeyBlueprintType}
    />
  )
}

const UpdateInactiveCorrespondentsModal = ({
  visible,
  closeModal,
  journeyBlueprintType,
  stepTemplatesWithInactiveCorrespondents,
}) => {
  const [batchUpdate, { isLoading: isUpdating }] = useApi(API.admin.stepTemplates.batchUpdate, {
    updateEntitySlice: true,
    onSuccess: closeModal,
    toastSuccessMessage: I18N('update_success'),
  })

  const [workingCopy, setWorkingCopy] = useState(
    stepTemplatesWithInactiveCorrespondents.map(st => ({
      id: st.id,
      name: st.name,
      adminDisplayPath: st.adminDisplayPath,
      fromCorrespondentAttributes: st.fromCorrespondentAttributes || {
        user: st.fromCorrespondent.user,
        role: st.fromCorrespondent.role,
      },
      toCorrespondentAttributes: st.toCorrespondentAttributes || {
        user: st.toCorrespondent.user,
        role: st.toCorrespondent.role,
        group: st.toCorrespondent.group,
      },
    }))
  )

  const areAllValid = workingCopy.every(st => isCorrespondentValid(st.fromCorrespondentAttributes)
                                              && isCorrespondentValid(st.toCorrespondentAttributes))

  const updateStepTemplate = (stepId, updatedAttributes) => {
    setWorkingCopy(prev => prev.map(step => (step.id === stepId ? { ...step, ...updatedAttributes } : step)))
  }

  const onUpdate = () => {
    const params = workingCopy.map(st => buildStepTemplatePayload(st))
    batchUpdate({ stepTemplates: params })
  }

  const columns = [
    {
      header: I18N('journey_step'),
      accessor: st => (
        <Link to={st?.adminDisplayPath}>{st?.name}</Link>
      ),
    },
    {
      header: I18N('from'),
      accessor: st => (
        <StepTemplateCorrespondentSelect
          stepTemplateId={st.id}
          type='from'
          attributes={st.fromCorrespondentAttributes}
          journeyBlueprintType={journeyBlueprintType}
          updateStepTemplate={updateStepTemplate}
        />
      ),
    },
    {
      header: I18N('to'),
      accessor: st => (
        <StepTemplateCorrespondentSelect
          stepTemplateId={st.id}
          type='to'
          attributes={st.toCorrespondentAttributes}
          journeyBlueprintType={journeyBlueprintType}
          updateStepTemplate={updateStepTemplate}
        />
      ),
    },
  ]
  return (
    <Modal size='xl' visible={visible} toggle={closeModal} className='UpdateInactiveCorrespondentsModal'>
      <h4>{I18N('title')}</h4>
      <p className='text-secondary mb-3'>{I18N('description')}</p>

      <div className='scrollable-container bordered mb-4'>
        <Table
          data={workingCopy}
          columns={columns}
        />
      </div>

      <div className='d-flex justify-content-end align-items-center'>
        <CancelButton onClick={closeModal} />
        <Button
          onClick={onUpdate}
          disabled={isUpdating || !areAllValid}
          showLoadingSpinner={isUpdating}
        >
          {I18N('update_steps')}
        </Button>
      </div>
    </Modal>
  )
}

export default UpdateInactiveCorrespondentsModal
