import React from 'react'

import { ButtonSecondarySmall } from 'components/common/buttons'
import Card from 'components/common/card'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import MoonOrSunIcon from 'components/common/moonOrSunIcon'
import currentTimezone from 'utils/currentTimezone'

const I18N = i18nPath('views.home')

const TimezonesCard = () => (
  <Card className='TimezonesCard' cardBodyClassName='p-0'>
    <div className='YourTime p-4'>
      <div className='w-100 d-flex justify-content-between align-items-center'>
        <div>
          <h6 className='mb-0 font-weight-500'>{I18N('your_time')}</h6>
        </div>
        <div>
          <div className='text-right'>
            <h4><MoonOrSunIcon timezone={currentTimezone()} /> {i18nMoment().format('LT')}</h4>
          </div>
          <div className='text-secondary text-small text-right'>
            {i18nMoment().locale() === 'en' && i18nMoment().format('dddd, MMMM D')}
            {i18nMoment().locale() !== 'en' && i18nMoment().format('ll')}
          </div>
        </div>
      </div>

    </div>
    <div className='p-4 text-center'>
      <ButtonSecondarySmall>{I18N('configure_timezones')}</ButtonSecondarySmall>
    </div>
  </Card>
)

export default TimezonesCard
