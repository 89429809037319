import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { getJourneyStepStatusColor } from 'utils/admin/journeys/statusColors'
import StatusLabel from 'components/common/status_label/'

const I18N = i18nPath('views.admin.journeys.steps.status')

const StepStatusLabel = ({ step }) => (
  <StatusLabel
    className='font-weight-500 text-normal'
    color={getJourneyStepStatusColor(step?.completionStatus)}
  >
    {I18N(step?.completionStatus)}
  </StatusLabel>
)

export default StepStatusLabel
