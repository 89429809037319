const getJourneyBlueprintStatusColor = (status) => {
  if (status === 'active') {
    return 'green'
  }

  return 'gray'
}

export const getJourneyCompletionStatusColor = (status) => {
  if (status === 'on_track' || status === 'completed') {
    return 'green'
  }

  if (status === 'off_track' || status === 'removed') {
    return 'red'
  }

  if (status === 'items_due_today') {
    return 'yellow'
  }

  return 'gray'
}

export const getJourneyStepStatusColor = (status) => {
  if (['completed', 'delivered', 'opened', 'invited', 'sent'].includes(status)) {
    return 'green'
  }

  if (status === 'overdue' || status === 'not_sent' || status === 'not_assigned') {
    return 'red'
  }

  if (status === 'pending' || status === 'due_today') {
    return 'yellow'
  }

  return 'gray'
}


export default getJourneyBlueprintStatusColor
