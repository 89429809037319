import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.preview_components')

const CardBackgroundColor = () => (
  <div className='DropdownMenu-content position-relative' style={{ top: '8px', width: '300px', zIndex: 1 }}>
    <div className='Card-dropdownMenuItem pointer px-2 py-1'>
      <div>
        <div>{I18N('exclude_posts_like_this')}</div>
        <div className='text-secondary' style={{ fontSize: '0.9em', lineHeight: '1.1' }}>
          {I18N('dont_show_posts_like_this')}
        </div>
      </div>
    </div>
    <div className='Card-dropdownMenuItem pointer px-2 py-1'>
      <div>
        <div>{I18N('stop_alerting_me')}</div>
        <div className='text-secondary' style={{ fontSize: '0.9em', lineHeight: '1.1' }}>
          {I18N('stop_sending_emails')}
        </div>
      </div>
    </div>
  </div>
)

export default CardBackgroundColor
