import useViewDurationTracker from 'components/common/hooks/useViewDurationTracker'
import { useEffect, useState } from 'react'
import { trackEvent } from 'services/tracker'

const useContentTracking = (contentType: string, contentId: string) => {
  const [ahoyEventId, setAhoyEventId] = useState(undefined)

  useEffect(() => {
    if (contentId) {
      const track = async () => {
        const { data: { id: eventId } } = await trackEvent(`${contentType}:view`, { [`${contentType}_id`]: contentId })
        setAhoyEventId(eventId)
      }

      track()
    }
  }, [contentId])

  useViewDurationTracker(ahoyEventId)
}

export default useContentTracking
