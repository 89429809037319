import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import appSignal from 'services/appSignal'
import { ReduxState } from 'redux/redux'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import API from 'services/api'
import appSlice from 'redux/slices/app_launcher/apps'

export interface AppPreferenceState {
  meta: DefaultMetaType,
}

export const initialState: AppPreferenceState = {
  meta: {
    ...defaultMeta,
  },
}

interface CreateParams {
  appId: string
  favorite: boolean
  q?: string
}

const buildAppPreferencePayload = appPreference => _.pick(appPreference, [
  'id',
  'favorite',
  'appId',
  'orderPosition',
])

const appPreferenceSlice = createSlice({
  name: 'appPreferences',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

const asyncActions = {
  create: (params: CreateParams) => async (dispatch) => {
    try {
      dispatch(appPreferenceSlice.actions.isLoading(true))

      await API.appLauncher.appPreferences.create(buildAppPreferencePayload(params))

      dispatch(appSlice.asyncActions.fetchAll({ showLoading: false, q: params.q }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(appPreferenceSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(appPreferenceSlice.actions.isLoading(false))
    }
  },

  update: (params = {}) => async (dispatch) => {
    try {
      dispatch(appPreferenceSlice.actions.isSaving(true))

      await API.appLauncher.appPreferences.update(buildAppPreferencePayload(params))

      dispatch(appSlice.asyncActions.fetchAll({ showLoading: false }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(appPreferenceSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(appPreferenceSlice.actions.isSaving(false))
    }
  },

  destroy: (id: string, q: string) => async (dispatch) => {
    try {
      dispatch(appPreferenceSlice.actions.isLoading(true))

      await API.appLauncher.appPreferences.destroy(id)

      dispatch(appSlice.asyncActions.fetchAll({ showLoading: false, q }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(appPreferenceSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(appPreferenceSlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.appPreferences.meta,
}

export default {
  ...appPreferenceSlice,
  asyncActions,
  selectors,
}
