export default function getPeopleTabNames(companySettings) {
  const tabNames = []

  if (companySettings.orgChart.enabled) { tabNames.push('orgchart') }
  if (companySettings.groups.enabled) { tabNames.push('teams') }
  if (companySettings.shoutouts.enabled) { tabNames.push('shoutouts') }
  if (companySettings.celebrations.enabled) { tabNames.push('celebrations') }
  if (companySettings.celebrations.newEmployees.enabled) { tabNames.push('new_employees') }
  if (companySettings.celebrations.workAnniversaries.enabled) { tabNames.push('work_anniversaries') }
  if (companySettings.celebrations.birthdays.enabled) { tabNames.push('birthdays') }
  if (companySettings.badges.enabled) { tabNames.push('badges') }

  return tabNames
}
