import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { askAiI18n } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import { AskAiTab } from 'components/ai/chat/ask_ai_modal/types'
import BackButton from 'components/common/backButton'
import LoadingSpinner from 'components/common/loadingSpinner'
import { PathnameContextProvider } from 'components/contexts/pathnameContext'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'

const ConversationList = () => {
  const {
    setTab,
    conversations,
    isLoadingConversations,
    setCurrentConversationId,
  } = useAskAiContext()

  const handleConversationClick = (conversationId: string) => {
    setCurrentConversationId(conversationId)
    setTab(AskAiTab.CHAT)
  }

  if (isLoadingConversations && conversations.length === 0) {
    return <LoadingSpinner />
  }

  if (conversations.length === 0) {
    return <p className='text-center text-secondary'>{askAiI18n('no_history')}</p>
  }

  return (
    <>
      {conversations.map((conversation, index) => (
        <React.Fragment key={conversation.id}>
          <a
            className='AskAiModal-conversation d-flex justify-content-between font-weight-600'
            onClick={() => handleConversationClick(conversation.id)}
          >
            <span>
              {conversation.title}
            </span>

            <span className='text-muted font-weight-normal text-small'>
              {moment(conversation.createdAt).format('MMM D h:mma')}
            </span>
          </a>

          {index !== conversations.length - 1 && (
            <hr />
          )}
        </React.Fragment>
      ))}

      {isLoadingConversations && (
        <div className='text-center mt-3'>
          <LoadingSpinner />
        </div>
      )}
    </>
  )
}

const HistoryTab = () => {
  const {
    setTab,
    hasMoreConversations,
    onLoadMoreConversations,
    isLoadingConversations,
  } = useAskAiContext()

  const bodyRef = useRef<HTMLDivElement>(null)

  // Infinite scroll
  const handleScroll = () => {
    if (!bodyRef.current) return

    const { scrollTop, scrollHeight, clientHeight } = bodyRef.current
    const scrollThreshold = 100 // px from bottom

    if (
      !isLoadingConversations
      && hasMoreConversations
      && scrollHeight - scrollTop - clientHeight < scrollThreshold
    ) {
      onLoadMoreConversations()
    }
  }

  // Trigger a scroll event after new conversations are loaded if we didn't reach the bottom of the scroll
  useEffect(() => {
    if (!bodyRef.current) return () => {}

    const observer = new MutationObserver(handleScroll)
    observer.observe(bodyRef.current, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [handleScroll])

  return (
    <PathnameContextProvider>
      <div
        className='AskAiModal-body history-tab flex-grow-1 p-4'
        onScroll={handleScroll}
        ref={bodyRef}
      >
        <BackButton onClick={() => setTab(AskAiTab.CHAT)} className='mb-3' />
        <ConversationList />
      </div>
    </PathnameContextProvider>
  )
}

export default HistoryTab
