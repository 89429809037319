import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button, ButtonLink } from 'components/common/buttons'
import Modal from 'components/common/modal'
import RichTextView from 'components/common/richTextView'
import pageTemplateSlice, { SelectedTemplate } from 'redux/slices/pageTemplates'
import pageSlice from 'redux/slices/pages'
import EditableTitle from 'components/common/editable_title'
import TemplatesNav from 'components/pages/templatesNav'
import CdnSvg from 'components/common/cdnSvg'
import PageCoverImage from 'components/pages/pageCoverImage'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CancelButton from 'components/common/cancelButton'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'

const I18N = i18nPath('views.pages')

interface TemplatePreviewModalProps {
  visible: boolean
  selectedTemplate: SelectedTemplate
  setSelectedTemplate: (template: SelectedTemplate) => void
  applyTemplate: () => void
  page: any
}

const TemplatePreviewModal = ({
  visible = false,
  selectedTemplate,
  setSelectedTemplate,
  applyTemplate,
  page,
}: TemplatePreviewModalProps) => {
  const { isSaving } = useSelector(pageSlice.selectors.getPagesMetadata())
  const { isLoadingTemplate } = useSelector(pageTemplateSlice.selectors.getMetaData())
  const scrollableContentRef = useRef<HTMLDivElement>(null)

  const selectedPageTemplate = useSelector(pageTemplateSlice.selectors.getPageTemplate(selectedTemplate.id))
  const draftContent = page.draftContent

  const onClose = () => setSelectedTemplate({})

  const handleApplyTemplate = () => {
    if (!isHtmlStringEmpty(draftContent)) {
      if (window.confirm(I18N('block_template_apply'))) {
        applyTemplate()
      }
    } else {
      applyTemplate()
    }
  }

  const canEditTemplate = selectedPageTemplate.permittedActions?.edit

  useEffect(() => {
    if (scrollableContentRef.current) {
      scrollableContentRef.current.scrollTop = 0
    }
  }, [selectedTemplate.id])

  return (
    <Modal
      size='xl'
      className='TemplatePreviewModal d-block'
      visible={visible}
      closeButton={false}
      toggle={onClose}
    >
      <div className='d-flex flex-row text-left h-100'>
        <div className='TemplateContent d-block overflow-auto mh-100 w-100' ref={scrollableContentRef}>
          <PageCoverImage page={selectedPageTemplate} showActions={false} type='pageTemplate' />
          <div className='mx-2 my-0 px-5 pt-5 h-100'>
            {isLoadingTemplate ? (
              <CirclesLoadingIndicator className='pt-4' />
            ) : (
              <>
                <EditableTitle
                  canEdit={false}
                  className='flex-grow-1'
                  editModeOnly={false}
                  record={selectedPageTemplate}
                  showSaveCancelButtons={false}
                />
                <hr className='d-none d-lg-block w-100 my-4' />
                <RichTextView
                  record={selectedPageTemplate}
                  recordType='pageTemplate'
                  richTextKey='content'
                />
              </>
            )}
          </div>
        </div>

        <div className='ModalActions d-flex align-items-center justify-content-between px-5 py-4'>
          <CancelButton onClick={onClose}>
            {I18NCommon('close')}
          </CancelButton>
          <div>
            {canEditTemplate && (
              <ButtonLink
                to={`/pages/template_categories/${selectedPageTemplate?.templateCategory?.id}/templates/${selectedPageTemplate.id}/edit`}
                className='mr-2'
                variant='secondary'
              >
                <CdnSvg src='/images/addPencilIcon.svg' className='mr-2 TemplatePreviewModal-addPencilIcon' />
                {I18N('edit_template')}
              </ButtonLink>
            )}
            <Button
              className='ml-2'
              onClick={handleApplyTemplate}
              showLoadingSpinner={isSaving}
              disabled={isSaving}
            >
              {I18N('apply_template')}
            </Button>
          </div>

        </div>
        <div className='position-sticky bottom-0 right-0 top-0 h-100'>
          <TemplatesNav
            selectedTemplate={selectedTemplate}
            onClose={onClose}
            setSelectedTemplate={setSelectedTemplate}
            modalView
          />
        </div>
      </div>
    </Modal>
  )
}

export default TemplatePreviewModal
