import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api'
import { getResponseOrThrow } from 'utils/errorHandling'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'

const bannerSlice = createSlice({
  name: 'banners',
  initialState: {
    banners: [],
  },
  reducers: {
    setBanners(state, action) {
      state.banners = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

_.assign(bannerSlice, {
  asyncActions: {
    fetchAll: () => async (dispatch) => {
      try {
        const response = await API.banners.fetchAll()
        const banners = denormalizedJsonApiResponse(response, 'banner')
        dispatch(bannerSlice.actions.setBanners(banners))
      } catch (e) {
        console.error(e)
      }
    },
  },
})

_.assign(bannerSlice, {
  selectors: {
    getBanners: () => state => state.banners.banners,
  },
})

export default bannerSlice
