import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import JourneyProgressWithStatus from 'components/admin/journeys/journeys/journeyProgressWithStatus'
import LoadingSpinner from 'components/common/loadingSpinner'

const I18N = i18nPath('views.admin.journeys.new_hire_detail')

const NewHireJourneysProgress = ({ journeys = [], isLoading = false }) => {
  if (isLoading) {
    return <LoadingSpinner />
  }

  // The empty link is there by design, but it's actually the container that has an onClick event that open's the modal
  return (
    <div className='NewHireJourneysProgress d-flex flex-column justify-content-center w-100'>
      <h6>{I18N('journeys')}</h6>
      {journeys.length > 0 ? (journeys.map(journey => (
        <JourneyProgressWithStatus key={`journey-progress-${journey.id}`} journey={journey} className='mb-2 align-items-center' />
      ))) : (
        <a href='#' onClick={() => {}}>{I18N('assign')}</a>
      )}
    </div>
  )
}

export default NewHireJourneysProgress
