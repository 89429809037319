import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import stepSlice from 'redux/slices/journeys/steps'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import StepLink from 'components/admin/journeys/stepLink'
import BlueprintLink from 'components/admin/journeys/blueprintLink'
import StepStatusLabel from 'components/admin/journeys/stepStatusLabel'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'
import EmployeeStepsEmptyTableState from './emptyTableState'

const I18N = i18nPath('views.admin.journeys.new_hire_detail')

const ALL = 'all'

const stepTypeRailsFormat = (stepTypeFilter) => {
  switch (stepTypeFilter) {
  case 'communications':
    return 'Journey::Communication'
  case 'tasks':
  case 'surveys':
    return 'Journey::Task'
  case 'calendarEvents':
    return 'Journey::CalendarEvent'
  case 'introductions':
    return 'Journey::Introduction'
  case 'meetings':
    return 'Journey::Meeting'
  default:
    return ''
  }
}

const AllStepsTable = ({
  journeys,
  onClickAssignJourney,
  selectedStepTypeFilter,
  selectedJourneyFilter,
  setSelectedStepTypeFilter,
  setSelectedJourneyFilter,
}) => {
  const dispatch = useDispatch()
  const { userId, selectedTab } = useParams()

  const journeyStates = journeys.map(j => j?.state)

  const steps = useSelector(stepSlice.selectors.getSteps())
  const { queryParams, isLoading, isNotFound } = useSelector(stepSlice.selectors.getMetaData())

  const {
    page, totalPages, perPage, total,
  } = queryParams

  const fetchSteps = ({
    page = 1,
    journeyId = selectedJourneyFilter,
    stepType = selectedStepTypeFilter,
  } = {}) => {
    const params = {
      ...queryParams,
      userId,
      page,
      journeyBlueprintType: selectedTab,
    }

    if (stepType !== ALL) {
      params.entityType = stepTypeRailsFormat(stepType)
      if (stepType === 'surveys') params.surveyTasks = true
    }

    if (journeyId !== ALL) {
      params.journeyId = journeyId
    }

    dispatch(stepSlice.asyncActions.admin.fetchAll(params))
  }

  useEffect(() => {
    setSelectedStepTypeFilter(ALL)
    setSelectedJourneyFilter(ALL)
    fetchSteps({ page: 1, journeyId: ALL, stepType: ALL })
  }, [userId, selectedTab])


  useEffect(() => {
    fetchSteps()
  }, [JSON.stringify(journeyStates), selectedStepTypeFilter, selectedJourneyFilter])


  const handlePaginationClick = (params) => {
    fetchSteps(params)
  }

  const columns = [
    {
      header: I18N('steps.list.name'),
      accessor: d => <StepLink step={d} />,
    },
    {
      header: I18N('steps.list.journey'),
      accessor: d => <BlueprintLink className='w-100 d-flex justify-content-between align-items-center' journeyBlueprint={d?.journey?.journeyBlueprint} />,
    },
    {
      header: I18N('steps.list.type'),
      accessor: d => d?.typeText,
    },
    {
      header: I18N('steps.list.to'),
      accessor: d => (
        <EmployeeOrCorrespondentLink
          user={d?.toUserOrToCorrespondentUser}
          correspondent={d?.toCorrespondent}
          linkToAdmin
        />
      ),
    },
    {
      header: I18N('steps.list.scheduled'),
      accessor: d => <p className='mb-0'>{i18nMoment(d?.triggerAt).format('lll')}</p>,
    },
    {
      header: I18N('steps.list.status'),
      accessor: d => <StepStatusLabel step={d} />,
    },
  ]

  if (!isLoading && _.isEmpty(steps)) {
    return <EmployeeStepsEmptyTableState onClickAssignJourney={onClickAssignJourney} />
  }

  return (
    <TableLoadingContainer
      isLoading={isLoading}
      isNotFound={isNotFound}
      loaderClassName='w-100 justify-content-center d-flex'
      useCirclesLoadingIndicator
    >
      <SmartTable
        className='mt-3 white-bg-table AllStepsTable'
        data={isLoading ? [] : steps}
        page={page}
        pages={totalPages}
        perPage={perPage}
        totalCount={total}
        showPagination={true}
        columns={columns}
        onPaginationClick={handlePaginationClick}
        hideWhenEmpty={true}
      />
    </TableLoadingContainer>
  )
}

export default AllStepsTable
