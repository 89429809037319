function splitIntoColumns<T>(elements: T[], numberOfColumns: number) {
  const columns: T[][] = []
  let remainingElements = elements
  for (let i = 0; i < numberOfColumns; i += 1) {
    const chunkSize = Math.ceil(remainingElements.length / (numberOfColumns - i))
    columns.push(remainingElements.slice(0, chunkSize))
    remainingElements = remainingElements.slice(chunkSize)
  }

  return columns
}

export default splitIntoColumns
