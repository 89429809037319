import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import surveyFormSlice from 'redux/slices/surveys/forms'
import surveyResponseSlice, { defaultWorkingCopy } from 'redux/slices/surveys/responses'
import surveyAnswerSlice, { buildAnswerPayload } from 'redux/slices/surveys/answers'
import { useParams } from 'react-router-dom'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { SurveyAnswerType } from 'types/surveys/answer'
import RespondSurveyComponent from 'components/admin/surveys/respondSurveyComponent'
import useQueryParams from 'components/common/hooks/useQueryParams'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'

const womanWithSpyglassPath = '/images/illustrations/womanWithSpyglass.svg'

const RespondSurveyPage = () => {
  const dispatch = useDispatch()
  const { formId } = useParams()
  const [responseId, setResponseId] = useState(null)
  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)
  const { taskId, disableNotifications } = useQueryParams()

  const [toggleNotifications] = useApi(API.journey.tasks.toggleNotifications, {
    updateEntitySlice: true,
    toastSuccessMessage: I18NCommon('task_notifications_updated'),
  })

  const response = useSelector(surveyResponseSlice.selectors.getResponse(responseId))
  const form = useSelector(surveyFormSlice.selectors.getForm(formId))

  const isCompleted = response?.state === 'completed'
  const questions = form?.questions || []
  const { isLoading, isSaving: isSavingResponse, error } = useSelector(surveyResponseSlice.selectors.getMetaData())
  const { isSaving: isSavingAnswer } = useSelector(surveyAnswerSlice.selectors.getMetaData())

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const currentQuestion = questions[currentQuestionIndex]
  const currentQuestionId = currentQuestion?.id
  const answers: Partial<SurveyAnswerType>[] = workingCopy?.answers || []
  // eslint-disable-next-line max-len
  const currentAnswer = answers?.find((answer: SurveyAnswerType) => (answer?.questionId || '') === currentQuestionId)
  const isLastQuestion = currentQuestionIndex === questions?.length - 1

  useEffect(() => {
    const onSuccess = (responseId) => {
      setResponseId(responseId)
    }

    dispatch(surveyResponseSlice.asyncActions.create(formId, onSuccess, { taskId }))

    if (disableNotifications === 'true') {
      toggleNotifications(taskId, { enableNotifications: false })
    }
  }, [formId])

  useEffect(() => {
    if (!_.isEmpty(response)) {
      setWorkingCopy(response)

      const answeredQuestions = response?.answers?.map(answer => answer?.questionId) || []
      const startingQuestionIndex = questions.findIndex(question => !answeredQuestions.includes(question?.id))

      setCurrentQuestionIndex(startingQuestionIndex)
    }
  }, [response?.updatedAt])

  const updateAnswer = (newAnswer: Partial<SurveyAnswerType> | null) => {
    const newAnswers: Partial<SurveyAnswerType>[] = [...workingCopy?.answers || []]

    // eslint-disable-next-line max-len
    const oldAnswerIndex = newAnswers.findIndex((oldAnswer: Partial<SurveyAnswerType>) => (oldAnswer?.questionId || '') === currentQuestionId)

    if (oldAnswerIndex > -1) {
      newAnswers[oldAnswerIndex] = { ...newAnswers[oldAnswerIndex], ...newAnswer }
    } else {
      newAnswers.push({ ...newAnswer, questionId: currentQuestionId })
    }

    setWorkingCopy({
      ...workingCopy,
      answers: newAnswers,
    })
  }

  const submitResponse = () => {
    dispatch(surveyResponseSlice.asyncActions.submit(formId, responseId))
  }

  const onSaveAnswerSuccess = () => {
    if (isLastQuestion) {
      submitResponse()
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const onClickNext = () => {
    // eslint-disable-next-line max-len
    const previousAnswer = response?.answers?.find(answer => answer?.questionId === currentQuestionId)

    if (!_.isEqual(buildAnswerPayload(previousAnswer), buildAnswerPayload(currentAnswer))) {
      dispatch(surveyAnswerSlice.asyncActions.create(
        formId,
        responseId,
        currentAnswer,
        previousAnswer,
        onSaveAnswerSuccess
      ))
    } else {
      onSaveAnswerSuccess()
    }
  }

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (error) {
    return (
      <div
        className='RespondSurveyPage container mt-5 text-secondary text-center'
      >
        {error?.type === 'survey_closed' && (
          <div className='mb-3'>
            <CdnSvg src={womanWithSpyglassPath} />
          </div>
        )}
        {error.message}
      </div>
    )
  }

  return (
    <div className='RespondSurveyPage container d-flex flex-column mt-5'>
      <RespondSurveyComponent
        form={form}
        subject={response?.subject || ''}
        instructions={response?.instructions || ''}
        currentQuestionIndex={currentQuestionIndex}
        currentAnswer={currentAnswer}
        updateAnswer={updateAnswer}
        onClickBack={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
        onClickNext={onClickNext}
        isSaving={isSavingResponse || isSavingAnswer}
        showResponseRecordedBanner={isCompleted}
      />
    </div>
  )
}

export default RespondSurveyPage
