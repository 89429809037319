import React from 'react'
import { NavLink } from 'react-router-dom'

const NavbarLink = ({
  exact = undefined,
  to,
  onClick = () => {},
  target = undefined,
  children,
}) => (
  <div className='Navbar-item'>
    <NavLink onClick={onClick} exact={exact} to={to} target={target}>
      {children}
    </NavLink>
  </div>
)

export default NavbarLink
