import React from 'react'

const LockedEventIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' fill='none'>
    <circle cx='50' cy='50' r='50' fill='white' />
    <rect x='63.9644' y='33.9287' width='1.64286' height='1.64286' fill='#1461DD' />
    <rect x='51.6431' y='33.9287' width='1.64286' height='1.64286' fill='#1461DD' />
    <rect x='39.3213' y='33.9287' width='1.64286' height='1.64286' fill='#2486FC' />
    <path
      d='M68.8929 33.9287H31.1071C28.8388 33.9287 27 35.7675 27 38.0359V65.9644C27 68.2327 28.8388 70.0716 31.1071 70.0716H68.8929C71.1612 70.0716 73 68.2327 73 65.9644V38.0359C73 35.7675 71.1612 33.9287 68.8929 33.9287Z'
      fill='#EEF4FF'
    />
    <path d='M73 38.0359V65.9644C72.9992 68.2324 71.1608 70.0708 68.8929 70.0716H50V33.9287H68.8929C71.1608 33.9295 72.9992 35.7679 73 38.0359Z' fill='#D1DBED' />
    <path d='M73 38.0359V42.143H27V38.0359C27.0008 35.7679 28.8392 33.9295 31.1071 33.9287H68.8929C71.1608 33.9295 72.9992 35.7679 73 38.0359Z' fill='#53A6F9' />
    <path d='M73 38.0359V42.143H50V33.9287H68.8929C71.1608 33.9295 72.9992 35.7679 73 38.0359Z' fill='#2486FC' />
    <path
      d='M63.9642 33.9286V32.2857C63.9642 31.3784 63.2287 30.6429 62.3214 30.6429C61.414 30.6429 60.6785 31.3784 60.6785 32.2857V37.2143C60.6785 38.1216 61.414 38.8571 62.3214 38.8571C63.2287 38.8571 63.9642 38.1216 63.9642 37.2143H65.6071C65.6071 39.0289 64.136 40.5 62.3214 40.5C60.5067 40.5 59.0356 39.0289 59.0356 37.2143V32.2857C59.0356 30.4711 60.5067 29 62.3214 29C64.136 29 65.6071 30.4711 65.6071 32.2857V33.9286H63.9642Z'
      fill='#1461DD'
    />
    <path
      d='M51.6429 33.9286V32.2857C51.6429 31.3784 50.9074 30.6429 50.0001 30.6429C49.0927 30.6429 48.3572 31.3784 48.3572 32.2857V37.2143C48.3572 38.1216 49.0927 38.8571 50.0001 38.8571C50.9074 38.8571 51.6429 38.1216 51.6429 37.2143H53.2858C53.2858 39.0289 51.8147 40.5 50.0001 40.5C48.1854 40.5 46.7144 39.0289 46.7144 37.2143V32.2857C46.7144 30.4711 48.1854 29 50.0001 29C51.8147 29 53.2858 30.4711 53.2858 32.2857V33.9286H51.6429Z'
      fill='#2486FC'
    />
    <path
      d='M39.3216 33.9286V32.2857C39.3216 31.3784 38.5861 30.6429 37.6788 30.6429C36.7715 30.6429 36.0359 31.3784 36.0359 32.2857V37.2143C36.0359 38.1216 36.7715 38.8571 37.6788 38.8571C38.5861 38.8571 39.3216 38.1216 39.3216 37.2143H40.9645C40.9645 39.0289 39.4934 40.5 37.6788 40.5C35.8641 40.5 34.3931 39.0289 34.3931 37.2143V32.2857C34.3931 30.4711 35.8641 29 37.6788 29C39.4934 29 40.9645 30.4711 40.9645 32.2857V33.9286H39.3216Z'
      fill='#2486FC'
    />
    <path
      d='M53.2857 32.2857V33.9286H51.6429V32.2857C51.6402 31.3795 50.9062 30.6455 50 30.6429V29C50.8715 28.9998 51.7074 29.3459 52.3236 29.9621C52.9399 30.5783 53.286 31.4142 53.2857 32.2857Z'
      fill='#1461DD'
    />
    <path
      d='M53.2857 37.2144C53.286 38.0859 52.9399 38.9217 52.3236 39.538C51.7074 40.1542 50.8715 40.5003 50 40.5001V38.8572C50.9062 38.8546 51.6402 38.1206 51.6429 37.2144H53.2857Z'
      fill='#1461DD'
    />
    <rect x='48.3569' y='54.4644' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='55.75' y='54.4644' width='3.28571' height='3.28571' fill='#2486FC' />
    <rect x='48.3569' y='61.0356' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='48.3569' y='47.8931' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='40.9644' y='54.4644' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='40.9644' y='61.0356' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='40.9644' y='47.8931' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='33.5713' y='54.4644' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='33.5713' y='61.0356' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='33.5713' y='47.8931' width='3.28571' height='3.28571' fill='#53A6F9' />
    <rect x='59.0356' y='51.1787' width='3.28571' height='3.28571' transform='rotate(-180 59.0356 51.1787)' fill='#2486FC' />
    <rect x='66.4287' y='51.1787' width='3.28571' height='3.28571' transform='rotate(-180 66.4287 51.1787)' fill='#2486FC' />
    <rect x='50' y='47.8931' width='1.64286' height='3.28571' fill='#2486FC' />
    <rect x='50' y='54.4644' width='1.64286' height='3.28571' fill='#2486FC' />
    <rect x='50' y='61.0356' width='1.64286' height='3.28571' fill='#2486FC' />
    <rect x='64' y='64' width='8' height='8' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.125 61.5H72.5V59C72.5 56.2425 70.2575 54 67.5 54C64.7425 54 62.5 56.2425 62.5 59V61.5H61.875C60.8417 61.5 60 62.3408 60 63.375V72.125C60 73.1592 60.8417 74 61.875 74H73.125C74.1583 74 75 73.1592 75 72.125V63.375C75 62.3408 74.1583 61.5 73.125 61.5ZM64.1667 59C64.1667 57.1617 65.6617 55.6667 67.5 55.6667C69.3383 55.6667 70.8333 57.1617 70.8333 59V61.5H64.1667V59ZM68.3333 69.8333V67.935C68.8292 67.6458 69.1667 67.1142 69.1667 66.5C69.1667 65.5808 68.4192 64.8333 67.5 64.8333C66.5808 64.8333 65.8333 65.5808 65.8333 66.5C65.8333 67.1142 66.1708 67.6458 66.6667 67.935V69.8333C66.6667 70.2933 67.0392 70.6667 67.5 70.6667C67.9608 70.6667 68.3333 70.2933 68.3333 69.8333Z'
      fill='#2486FC'
    />
  </svg>
)

export default LockedEventIcon
