import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import entitySlice from 'redux/slices/entities'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

export const buildArticleTypePayload = (articleType) => {
  const articleTypeAttrs = _.pick(articleType, [
    'id',
    'name',
    'description',
    'image',
    'active',
    'orderPosition',
    'groupArticleType',
    'targetingRules',
  ])

  if (articleType?.targetingRules) {
    articleTypeAttrs.targetingRules = normalizeTargetingRules(articleType.targetingRules)
  }

  return articleTypeAttrs
}

export const initialState = {
  articleTypeIds: [],
  groupArticleTypeId: null,
  meta: {
    ...defaultMeta,
    isLoadingGroupArticleType: false,
  },
}

const slice = createSlice({
  name: 'articleTypes',
  initialState,
  reducers: {
    ...defaultActions,

    setArticleTypeIds(state, action) {
      state.articleTypeIds = action.payload
    },

    setGroupArticleTypeId(state, action) {
      state.groupArticleTypeId = action.payload
    },

    setIsLoadingGroupArticleType(state, action) {
      state.meta.isLoadingGroupArticleType = action.payload
    },
  },
})

//------------------------------------------------------------
// ASYNC ACTIONS
//------------------------------------------------------------

const asyncActions = {
  fetchArticleTypes: () => async (dispatch) => {
    dispatch(slice.actions.isLoading(true))
    try {
      const response = await API.articleTypes.fetchAll()

      const articleTypeIds = response.data.data.map(articleType => articleType.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(slice.actions.setArticleTypeIds(articleTypeIds))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
      const { error } = checkForError(getResponseOrThrow(e))

      dispatch(slice.actions.setError(error))
    } finally {
      dispatch(slice.actions.isLoading(false))
    }
  },

  fetchGroupArticleType: () => async (dispatch, getState) => {
    const { meta: { isLoadingGroupArticleType }, groupArticleTypeId } = getState().articleTypes
    if (isLoadingGroupArticleType || groupArticleTypeId) {
      return
    }

    dispatch(slice.actions.setIsLoadingGroupArticleType(true))
    try {
      const response = await API.articleTypes.fetchGroupArticleType()

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(slice.actions.setGroupArticleTypeId(response.data.data.id))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(slice.actions.setIsLoadingGroupArticleType(false))
    }
  },

  admin: {
    fetchAll: () => async (dispatch) => {
      dispatch(slice.actions.isLoading(true))
      try {
        const response = await API.admin.articleTypes.fetchAll({ active: 'all' })

        dispatch(entitySlice.actions.add({ data: response.data }))
        dispatch(slice.actions.setArticleTypeIds(response.data.data.map(articleType => articleType.id)))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(getResponseOrThrow(e))

        dispatch(slice.actions.setError(error))
      } finally {
        dispatch(slice.actions.isLoading(false))
      }
    },
  },
}

//------------------------------------------------------------
// SELECTORS
//------------------------------------------------------------
const selectors = {
  getMetaData: () => state => state.articleTypes.meta,

  getArticleTypes: () => state => state.articleTypes.articleTypeIds.map(id => build(state.entities, 'articleType', id)),

  getGroupArticleType: () => state => build(state.entities, 'articleType', state.articleTypes.groupArticleTypeId),
}

const articleTypeSlice = {
  ...slice,
  asyncActions,
  selectors,
}

export default articleTypeSlice
