import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'

import { I18NCommon } from 'utils/i18nHelpers'
import userSlice from 'redux/slices/users'
import { useSelector } from 'react-redux'

import classNames from 'classnames'

interface EmployeeNodeProps {
  childrenCount: number,
  employeeId: string,
  isCollapsed: boolean,
  isHighlighted: boolean,
  isOrientationVertical: boolean,
  isSelected: boolean,
  onNodeClick: (e: any) => void,
  toggleNode: () => void,
  nodeRef?: React.LegacyRef<HTMLDivElement>,
}

const EmployeeNode = ({
  childrenCount = 0,
  isCollapsed = true,
  isHighlighted = false,
  isOrientationVertical = false,
  isSelected = false,
  employeeId,
  onNodeClick,
  toggleNode,
  nodeRef,
}: EmployeeNodeProps) => {
  const users = useSelector(userSlice.selectors.getSimpleUsersByIds([employeeId]))
  const user = users[0]
  const imageSource = user?.primaryPhotoThumbnailUrl || '/images/profile_small-missing.png'
  const showChildrenCircle = childrenCount > 0

  return (
    <div
      className={classNames('EmployeeNode', { isOrientationVertical })}
    >
      <div ref={nodeRef} className={classNames('node-card', { isHighlighted, isSelected })} onClick={e => onNodeClick(e)}>
        <img
          className='EmployeeThumbnailPhoto bordered-thumbnail'
          src={imageSource}
          loading='lazy'
        />
        <div className='user-info'>
          <span className='text-primary font-weight-500 text-small truncate-text-at-1-lines'>
            {user?.preferredFullName}
          </span>
          <span className='text-secondary text-smallest truncate-text-at-2-lines'>
            {user?.title}
          </span>
        </div>
        {showChildrenCircle && (
          // we need this container otherwise the position of this element breaks in Safari
          <div className='children-circle-container'>
            <div className={classNames('children-circle', isCollapsed ? 'collapsed' : 'open')} onClick={toggleNode}>
              {isCollapsed && <span>+{childrenCount}</span>}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EmployeeNode
