import BackButton from 'components/common/backButton'
import React from 'react'

import SocialShareSettings from 'pages/admin/articles/settings/socialShareSettings'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCompanyConfigurationForm from 'components/common/hooks/useCompanyConfigurationForm'
import Card from 'components/common/card'
import { Button } from 'components/common/buttons'
import TemplateDisplayOrderSettings from 'pages/admin/articles/settings/templateDisplayOrderSettings'
import RestrictedArticlesDefaultSetting from 'pages/admin/articles/settings/restrictedArticlesDefaultSetting'


const I18N = i18nPath('views.admin.article_settings')

const ArticleSettingsPage = () => {
  const {
    connect, onSubmit, isLoading, isSaving,
  } = useCompanyConfigurationForm('news')

  if (isLoading) {
    return <section><CirclesLoadingIndicator /></section>
  }

  return (
    <div className='ArticleSettingsPage'>
      <header className='AdminHeader'>
        <h3 className='mb-0'>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <BackButton
          url='/admin/articles/'
          className='link-color bold mb-4'
        />

        <form onSubmit={onSubmit}>
          <Card className='SocialShareSettings'>
            <RestrictedArticlesDefaultSetting connect={connect} />

            <hr />

            <SocialShareSettings connect={connect} />

            <hr />

            <TemplateDisplayOrderSettings connect={connect} />
          </Card>

          <div className='d-flex justify-content-end mt-5'>
            <Button
              type='submit'
              disabled={isSaving}
              showLoadingSpinner={isSaving}
            >
              {I18NCommon('save_changes')}
            </Button>
          </div>
        </form>

      </main>
    </div>
  )
}

export default ArticleSettingsPage
