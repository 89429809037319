import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { i18nPath } from 'utils/i18nHelpers'
import groupSlice from 'redux/slices/groups'
import groupMembershipSlice from 'redux/slices/groupMemberships'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import GroupInfo from 'components/groups/groupInfo'
import CoverImage from 'components/groups/groupCoverImage'
import Contact from 'components/groups/contact'
import Followers from 'components/groups/followers'
import KeyLinks from 'components/groups/key_links'
import Posts from 'components/groups/posts'
import Members from 'components/groups/members'
import Picture from 'components/common/images/picture'
import PictureList from 'components/common/images/pictureList'
import PhotosSlider from 'components/common/images/photosSlider'
import { ButtonSecondary } from 'components/common/buttons'
import { trackEvent } from 'services/tracker'
import FileSelectDropzone from 'components/common/file_select_dropzone'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.groups.group_page')

const GroupPage = () => {
  const { groupId } = useParams()
  const dispatch = useDispatch()
  const { settings } = useCurrentCompany()
  const [coverImageFile, setCoverImageFile] = useState(null)
  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState(false)
  const [modalPhotos, setModalPhotos] = useState([])

  const group = useSelector(groupSlice.selectors.getTeam(groupId))
  const { isLoading } = useSelector(groupSlice.selectors.getMetaData())
  const { queryParams } = useSelector(groupMembershipSlice.selectors.getMetaData())

  const primaryPicture = _.find(group.photos, { primary: true })
  const otherPictures = _.filter(group.photos, { primary: false })
  const { defaultPhotoUrl } = group
  const showLoadingIndicator = isLoading || _.isEmpty(group.permittedActions)

  useEffect(() => {
    dispatch(groupSlice.asyncActions.fetch(groupId))
    dispatch(groupMembershipSlice.asyncActions.fetchAll(groupId, queryParams))

    return (() => {
      dispatch(groupSlice.actions.resetNewsFeedIds())
      dispatch(groupMembershipSlice.actions.clear())
    })
  }, [groupId])

  useEffect(() => {
    if (group?.id) {
      trackEvent('group:view', { group_id: group.id })
    }
  }, [group?.id])

  const showAddCoverImageLink = () => group.permittedActions?.managePhotos && !group.coverImageUrl

  const handleUploadCoverImage = async (files) => {
    if (files && files.length > 0) {
      const file = files[0]
      setCoverImageFile(file)
    }
  }

  const openPhotosModal = (currentPhoto, photos) => {
    // Prevent the modal to open if currentPhoto is not a real photo object
    if (!currentPhoto?.id) return

    const modalPhotos = [currentPhoto, ...photos.filter(photo => photo.id !== currentPhoto.id)]

    setModalPhotos(modalPhotos)
    setIsPhotosModalOpen(true)
  }

  const closePhotosModal = () => setIsPhotosModalOpen(false)

  if (showLoadingIndicator) {
    return <CirclesLoadingIndicator />
  }

  return (
    <main className='GroupPage'>
      <HelmetProvider>
        <Helmet>
          <title>{group.name}</title>
        </Helmet>
      </HelmetProvider>
      <CoverImage
        group={group}
        coverImageFile={coverImageFile}
        setCoverImageFile={setCoverImageFile}
      />
      <section className='group-info-grid'>
        <Picture
          className='GroupPrimaryPhoto mb-3'
          defaultPhotoUrl={defaultPhotoUrl}
          onClick={() => openPhotosModal(primaryPicture, otherPictures)}
          owner={group}
          picture={primaryPicture}
          slice={groupSlice}
        />
        {showAddCoverImageLink() && (
          <FileSelectDropzone onDrop={handleUploadCoverImage} className='AddCoverImageDropzone'>
            <ButtonSecondary className='AddCoverImageButton'>
              <CdnSvg className='PlusIcon d-flex mr-1' src='/images/plusIcon.svg' />
              {I18N('add_cover_image')}
            </ButtonSecondary>
          </FileSelectDropzone>
        )}
        <PictureList owner={group} pictures={otherPictures} onClick={openPhotosModal} slice={groupSlice} />
        <GroupInfo group={group} />
      </section>
      <section className='group-card-grid'>
        <Members group={group} />
        <section className='ContactFollowersAndKeyLinks'>
          <Contact group={group} />
          <Followers group={group} />
          {settings.golinks.enabled && <KeyLinks group={group} />}
        </section>
        <Posts group={group} />
      </section>
      {isPhotosModalOpen && (
        <PhotosSlider
          owner={group}
          closeModal={closePhotosModal}
          modalPhotos={modalPhotos}
        />
      )}
    </main>
  )
}

export default GroupPage
