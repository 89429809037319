import React, { useEffect, useState } from 'react'
import {
  Redirect, Route, Switch, useLocation
} from 'react-router-dom'
import classNames from 'classnames'

import ArticleListPage from 'pages/admin/articles/articleListPage'
import ArticleStatsPage from 'pages/admin/articles/articleStatsPage'
import ArticleStatsEmailEngagementPage from 'pages/admin/articles/articleStatsEmailEngagementPage'
import ArticleStatsEmailClicksPage from 'pages/admin/articles/articleStatsEmailClicksPage'
import ArticleTypeListPage from 'pages/admin/article_types/articleTypeListPage'
import CreateArticleTypePage from 'pages/admin/article_types/createArticleTypePage'
import EditArticleTypePage from 'pages/admin/article_types/editArticleTypePage'
import BannerListPage from 'pages/admin/banners/bannerListPage'
import BannerEditorPage from 'pages/admin/banners/bannerEditorPage'
import ExternalCalendarListPage from 'pages/admin/external_calendars/externalCalendarListPage'
import ExternalCalendarEditorPage from 'pages/admin/external_calendars/externalCalendarEditorPage'
import ClearyAdminPage from 'pages/admin/cleary_admin/clearyAdminPage'
import CompanyValueListPage from 'pages/admin/company_values/companyValueListPage'
import CompanyValueEditorPage from 'pages/admin/company_values/companyValueEditorPage'
import LogoEditorPage from 'pages/admin/logos/logoEditorPage'
import LogoListPage from 'pages/admin/logos/logoListPage'
import TagListPage from 'pages/admin/tags/tagListPage'
import GroupListPage from 'pages/admin/groups/groupListPage'
import GroupEditorPage from 'pages/admin/groups/groupEditorPage'
import GroupTypeListPage from 'pages/admin/group_types/groupTypeListPage'
import GroupTypeEditorPage from 'pages/admin/group_types/groupTypeEditorPage'
import SoftLaunchAccessPage from 'pages/admin/softLaunchAccessPage'
import CustomStylesPage from 'pages/admin/custom_styles/customStylesPage'
import UserListPage from 'pages/admin/users/userListPage'
import CreateUserPage from 'pages/admin/users/createUserPage'
import EditUserPage from 'pages/admin/users/editUserPage'
import AdminAlertDetailPage from 'pages/admin/admin_alerts/adminAlertDetailPage'
import UserWithPermissionListPage from 'pages/admin/users/userWithPermissionListPage'
import CompanyListPage from 'pages/admin/companies/companyListPage'
import CompanyEditorPage from 'pages/admin/companies/companyEditorPage'
import UserImportsListPage from 'pages/admin/user_imports/userImportsListPage'
import UserImportDetailPage from 'pages/admin/user_imports/userImportDetailPage'
import IntegrationsPage from 'pages/admin/integrations/integrationsPage'
import UserExternalAlertsPage from 'pages/admin/cleary_admin/userExternalAlertsPage'
import DefaultPermissionPage from 'pages/admin/users/permissions/defaultPermissionPage'
import CarouselCardListPage from 'pages/admin/carousel_cards/carouselCardListPage'
import CreateCarouselCardPage from 'pages/admin/carousel_cards/createCarouselCardPage'
import EditCarouselCardPage from 'pages/admin/carousel_cards/editCarouselCardPage'
import StyleguideRouter from 'components/admin/styleguideRouter'
import TimezoneListPage from 'pages/admin/timezones/timezoneListPage'
import CreateTimezonePage from 'pages/admin/timezones/createTimezonePage'
import EditTimezonePage from 'pages/admin/timezones/editTimezonePage'
import UserSkillTypeListPage from 'pages/admin/user_skill_types/userSkillTypeListPage'
import CreateUserSkillTypePage from 'pages/admin/user_skill_types/createUserSkillTypePage'
import EditUserSkillTypePage from 'pages/admin/user_skill_types/editUserSkillTypePage'
import UserSkillListPage from 'pages/admin/user_skills/userSkillListPage'
import CreateUserSkillPage from 'pages/admin/user_skills/createUserSkillPage'
import EditUserSkillPage from 'pages/admin/user_skills/editUserSkillPage'
import JourneyBlueprintListPage from 'pages/admin/journeys/journey_blueprints/journeyBlueprintListPage'
import JourneyBlueprintEditorPage from 'pages/admin/journeys/journey_blueprints/journeyBlueprintEditorPage'
import EmployeeJourneysPage from 'pages/admin/journeys/users/employeeJourneysPage'
import EditCommunicationPage from 'pages/admin/journeys/communications/editCommunicationPage'
import EditTaskPage from 'pages/admin/journeys/tasks/editTaskPage'
import TaskListPage from 'pages/admin/journeys/tasks/taskListPage'
import JourneysDashboardPage from 'pages/admin/journeys/dashboards/journeysDashboardPage'
import AssignOnboardingBuddiesPage from 'pages/admin/journeys/onboarding_buddies/assignOnboardingBuddiesPage'
import MetricsPage from 'pages/admin/metrics/metricsPage'
import ManagedGroupListPage from 'pages/admin/managed_groups/managedGroupListPage'
import CreateManagedGroupPage from 'pages/admin/managed_groups/createManagedGroupPage'
import EditManagedGroupPage from 'pages/admin/managed_groups/editManagedGroupPage'
import EditCalendarEventPage from 'pages/admin/journeys/calendar_events/editCalendarEventPage'
import EditIntroductionPage from 'pages/admin/journeys/introductions/editIntroductionPage'
import PermissionsAndAlertsPage from 'pages/admin/users/permissionsAndAlertsPage'
import EditMeetingPage from 'pages/admin/journeys/meetings/editMeetingPage'
import ExternalEmailAuthenticationsPage from 'pages/admin/external_email_authentications/externalEmailAuthenticationsPage'
import AppListPage from 'pages/admin/apps/appListPage'
import AppEditorPage from 'pages/admin/apps/appEditorPage'

import ErrorBoundary from 'components/errorBoundaries'
import Error404 from 'components/errors/404'

import AdminNavbar from 'components/admin/adminNavbar'
import MobileViewToolbar from 'components/common/mobileViewToolbar'
import ToggleLeftNavButton from 'components/common/toggleLeftNavButton'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import SoftLaunchPlatformAccessAudiencePage from 'pages/admin/cleary_admin/softLaunchPlatformAccessAudiencePage'
import FormListPage from 'pages/admin/surveys/formListPage'
import CreateFormPage from 'pages/admin/surveys/createFormPage'
import EditFormPage from 'pages/admin/surveys/editFormPage'
import FormPreviewPage from 'pages/admin/surveys/formPreviewPage'
import ResponseDetailPage from 'pages/admin/surveys/responseDetailPage'
import QuestionDetailPage from 'pages/admin/surveys/questionDetailPage'
import EditSurveyTaskTemplatePage from 'pages/admin/journeys/task_templates/survey/editSurveyTaskTemplatePage'
import ArticleAcknowledgementsPage from 'pages/admin/articles/articleAcknowledgementsPage'
import OtherSettingsPage from 'pages/admin/other_settings/otherSettingsPage'
import AiPlaygroundPage from 'pages/admin/ai/aiPlaygroundPage'
import RestrictedAudienceConfigurationsPage from 'pages/admin/restricted_audience_configurations/restrictedAudienceConfigurationsPage'
import EditRestrictedAudienceConfigurationPage from 'pages/admin/restricted_audience_configurations/editRestrictedAudienceConfigurationPage'
import ArticleEditorPage from 'pages/admin/articles/articleEditorPage'
import FocusedPageWrapper from 'pages/admin/focusedPageWrapper'
import TemplateEditorPage from 'pages/admin/articles/templates/templateEditorPage'
import TemplateCategoriesListPage from 'pages/admin/articles/templates/templateCategoriesListPage'
import useFirstAdminPath from 'components/common/hooks/useFirstAdminPath'
import useIsCleary from 'components/common/hooks/useIsCleary'
import navbarHeight from 'components/navbar/utils/navbarHeight'
import EditLayoutCollectionPage from 'pages/admin/layout/editLayoutCollectionPage'
import CreateLayoutCollectionPage from 'pages/admin/layout/createLayoutCollectionPage'
import CreateStepPage from 'pages/admin/journeys/steps/createStepPage'
import ArticleSettingsPage from 'pages/admin/articles/articleSettingsPage'
import LayoutCollectionsListPage from 'pages/admin/layout/layoutCollectionsListPage'
import CreateStepTemplatePage from 'pages/admin/journeys/step_templates/createStepTemplatePage'
import EditStepTemplatePage from 'pages/admin/journeys/step_templates/editStepTemplatePage'
import GoogleDriveIntegrationPage from 'pages/admin/integrations/google_drive/googleDriveIntegrationPage'
import JourneyBlueprintSettingsPage from 'pages/admin/journeys/journey_blueprints/journeyBlueprintSettingsPage'
import UserSettingsPage from 'pages/admin/user_settings/userSettingsPage'
import ClearyAiSettingsPage from 'pages/admin/cleary_ai_settings/clearyAiSettingsPage'
import CreatePromptCollectionPage from 'pages/admin/cleary_ai_settings/promptCollectionPage/createPromptCollectionPage'
import EditPromptCollectionPage from 'pages/admin/cleary_ai_settings/promptCollectionPage/editPromptCollectionPage'
import TicketListPage from 'pages/admin/tickets/ticketListPage/ticketListPage'

import WorkflowListPage from 'pages/admin/ai/workflows/workflowListPage'
import WorkflowPage from 'pages/admin/ai/workflows/workflowPage'

const PATHS_WITH_NEW_HEADER = [
  'admin_alerts',
  'app_integrations',
  'apps',
  'article_types',
  'article',
  'articles',
  'banners',
  'carousel_cards',
  'cleary_ai_settings',
  'companies',
  'company_values',
  'external_calendars',
  'external_email_authentications',
  'journeys',
  'journey_blueprints',
  'homepage_design',
  'logos',
  'managed_groups',
  'metrics',
  'new_hires',
  'user_settings',
  'onboarding',
  'other_settings',
  'permissions_and_alerts',
  'preboarding_experience',
  'restricted_audiences',
  'soft_launch_access',
  'surveys',
  'tags',
  'tasks',
  'team_types',
  'teams',
  'timezones',
  'user_imports',
  'user_skills',
  'user_skill_types',
  'users',
  'tickets',
]

const PATHS_WITH_NO_PADDING_BOTTOM = [
  'tickets',
]

const AdminAppRouter = (props) => {
  const location = useLocation()

  const currentUser = useCurrentUser()
  const isCleary = useIsCleary()

  const firstAdminPath = useFirstAdminPath()

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [showToggleLeftNavButton, setShowToggleLeftNavButton] = useState(true)
  const [isDesktopNavOpen, setIsDesktopNavOpen] = useState(true)

  useEffect(() => {
    setIsMobileNavOpen(false)
  }, [location])

  let adminPageClass = 'AdminPageContent px-4 background-color-white'
  const usesNewHeaderStyle = _.some(PATHS_WITH_NEW_HEADER, path => location.pathname.startsWith(`/admin/${path}`))
  const hasNoPaddingBottom = _.some(PATHS_WITH_NO_PADDING_BOTTOM, path => location.pathname.startsWith(`/admin/${path}`))

  if (usesNewHeaderStyle) {
    adminPageClass = 'AdminPageContent background-color'
  }

  if (hasNoPaddingBottom) {
    adminPageClass += ' pb-0'
  }

  const {
    permissions: {
      articleCreator,
      articleManager,
      bannerManager,
      carouselManager,
      clearyAdmin,
      trialCompaniesAdmin,
      companyValueManager,
      externalCalendarManager,
      groupManager,
      itManager,
      journeyManager,
      journeyCreator,
      journeyCollaborator,
      logoManager,
      stylesManager,
      surveyCreator,
      superAdmin,
      userManager,
      analyticsAdmin,
    },
  } = currentUser

  return (
    <div className='container-fluid position-relative' style={{ height: `calc(100vh - ${navbarHeight()}px)` }}>
      {showToggleLeftNavButton && (
        <ToggleLeftNavButton
          onClick={() => setIsDesktopNavOpen(!isDesktopNavOpen)}
          isOpen={isDesktopNavOpen}
        />
      )}

      <div className='row flex-nowrap h-100'>
        <AdminNavbar isMobileNavOpen={isMobileNavOpen} isDesktopNavOpen={isDesktopNavOpen} />

        <div className={classNames(`${adminPageClass} overflow-y-auto flex-grow-1`, { isDesktopNavOpen })}>
          <MobileViewToolbar onClickHamburgerNav={setIsMobileNavOpen} />

          <ErrorBoundary {...props}>
            <Switch>
              {(articleCreator || articleManager || analyticsAdmin) && [
                <Route exact key='article-stats' path='/admin/articles/:articleId/stats' component={ArticleStatsPage} />,
                <Route exact key='article-stats-email-engagement-page' path='/admin/articles/:articleId/stats/email_engagement' component={ArticleStatsEmailEngagementPage} />,
                <Route exact key='article-stats-email-clicks-page' path='/admin/articles/:articleId/stats/email_clicks' component={ArticleStatsEmailClicksPage} />,
              ]}

              {(articleCreator || articleManager) && [
                articleManager && (
                  <Route exact key='article-settings' path='/admin/articles/settings' component={ArticleSettingsPage} />
                ),
                <Route exact key='article-list' path='/admin/articles' component={ArticleListPage} />,
                <Route exact key='article-editor' path='/admin/articles/:articleId'>
                  <FocusedPageWrapper
                    setIsDesktopNavOpen={setIsDesktopNavOpen}
                    isDesktopNavOpen={isDesktopNavOpen}
                    setShowToggleLeftNavButton={setShowToggleLeftNavButton}
                  >
                    <ArticleEditorPage />
                  </FocusedPageWrapper>
                </Route>,
                <Route exact key='template-editor' path='/admin/articles/templates/:templateId'>
                  <FocusedPageWrapper
                    setIsDesktopNavOpen={setIsDesktopNavOpen}
                    isDesktopNavOpen={isDesktopNavOpen}
                    setShowToggleLeftNavButton={setShowToggleLeftNavButton}
                  >
                    <TemplateEditorPage />
                  </FocusedPageWrapper>
                </Route>,
                <Route exact key='article-acknowledgements' path='/admin/articles/:articleId/acknowledgements' component={ArticleAcknowledgementsPage} />,
                <Route
                  exact
                  key='article-status-list'
                  path='/admin/articles/status/:publishStatus'
                  component={ArticleListPage}
                />,
                <Route exact key='article-type-list' path='/admin/article_types' component={ArticleTypeListPage} />,
                <Route exact key='article-type-new' path='/admin/article_types/new' component={CreateArticleTypePage} />,
                <Route exact key='article-type-edit' path='/admin/article_types/:articleTypeId' component={EditArticleTypePage} />,
              ]}

              {articleManager && (
                <Route exact key='tag-list' path='/admin/tags' component={TagListPage} />
              )}

              {bannerManager && [
                <Route exact key='banner-list' path='/admin/banners' component={BannerListPage} />,
                <Route exact key='banner-editor' path='/admin/banners/:bannerId' component={BannerEditorPage} />,
              ]}

              {externalCalendarManager && [
                <Route
                  exact
                  key='external-calendar-list'
                  path='/admin/external_calendars'
                  component={ExternalCalendarListPage}
                />,
                <Route
                  exact
                  key='external-calendar-editor'
                  path='/admin/external_calendars/:externalCalendarId'
                  component={ExternalCalendarEditorPage}
                />,
              ]}

              {userManager && [
                <Route exact key='user-imports-list' path='/admin/user_imports' component={UserImportsListPage} />,
                <Route
                  exact
                  key='user-imports-detail'
                  path={['/admin/user_imports/:userImportId', '/admin/user_imports/:userImportId/:userImportResultType']}
                  component={UserImportDetailPage}
                />,
                <Route exact key='user-list' path='/admin/users' component={UserListPage} />,
                <Route exact key='create-user' path='/admin/users/new' component={CreateUserPage} />,
                <Route exact key='edit-user' path='/admin/users/:userId' component={EditUserPage} />,
              ]}

              {carouselManager && [
                <Redirect exact key='carousel-card-list-redirect' from='/admin/carousel_cards/' to='/admin/carousel_cards/filter/active_or_upcoming' />,
                <Route exact key='carousel-card-list' path='/admin/carousel_cards/filter/:filter' component={CarouselCardListPage} />,
                <Route exact key='create-carousel-card' path='/admin/carousel_cards/new' component={CreateCarouselCardPage} />,
                <Route exact key='edit-carousel-card' path='/admin/carousel_cards/:carouselCardId' component={EditCarouselCardPage} />,
              ]}

              {superAdmin && [
                <Route exact key='timezones-list' path='/admin/timezones' component={TimezoneListPage} />,
                <Route exact key='create-timezone' path='/admin/timezones/new' component={CreateTimezonePage} />,
                <Route exact key='edit-timezone' path='/admin/timezones/:timezoneId' component={EditTimezonePage} />,
                <Route exact key='userSkillTypes-list' path='/admin/user_skill_types' component={UserSkillTypeListPage} />,
                <Route exact key='create-userSkillType' path='/admin/user_skill_types/new' component={CreateUserSkillTypePage} />,
                <Route exact key='edit-userSkillType' path='/admin/user_skill_types/:userSkillTypeId' component={EditUserSkillTypePage} />,
                <Route exact key='userSkills-list' path='/admin/user_skills' component={UserSkillListPage} />,
                <Route exact key='create-userSkill' path='/admin/user_skills/new' component={CreateUserSkillPage} />,
                <Route exact key='edit-userSkill' path='/admin/user_skills/:userSkillId' component={EditUserSkillPage} />,
                <Route exact key='default-permissions' path='/admin/permissions/manage/:permission' component={DefaultPermissionPage} />,
                <Route exact key='user-with-permission-list' path='/admin/permissions/:permissionName' component={UserWithPermissionListPage} />,
                <Route exact key='permissions-and-alerts' path='/admin/permissions_and_alerts' component={PermissionsAndAlertsPage} />,
                <Route exact key='alerts-detail' path='/admin/admin_alerts/:adminAlertName' component={AdminAlertDetailPage} />,
                <Route exact key='default-user-settings' path='/admin/user_settings' component={UserSettingsPage} />,
                <Route exact key='metrics' path='/admin/metrics' component={MetricsPage} />,
                <Route exact key='external-email-authentications' path='/admin/external_email_authentications' component={ExternalEmailAuthenticationsPage} />,
                <Route exact key='soft-launch-user-list' path='/admin/soft_launch_access' component={SoftLaunchAccessPage} />,
                <Route exact key='other-settings' path='/admin/other_settings/:selectedTab?' component={OtherSettingsPage} />,
                <Route exact key='cleary-ai-settings' path='/admin/cleary_ai_settings/:selectedTab?' component={ClearyAiSettingsPage} />,
                <Route exact key='create-prompt-collection-page' path='/admin/cleary_ai_settings/ask_ai/prompt_collections/new' component={CreatePromptCollectionPage} />,
                <Route exact key='edit-prompt-collection-page' path='/admin/cleary_ai_settings/ask_ai/prompt_collections/:id' component={EditPromptCollectionPage} />,
                <Route exact key='restricted-audience' path='/admin/restricted_audiences/' component={RestrictedAudienceConfigurationsPage} />,
                <Route
                  exact
                  key='update-restricted-audience'
                  path='/admin/restricted_audiences/:id/'
                  component={EditRestrictedAudienceConfigurationPage}
                />,
                <Route exact key='layout-collections' path='/admin/homepage_design' component={LayoutCollectionsListPage} />,
                <Route exact key='create-layout-collection' path='/admin/homepage_design/new' component={CreateLayoutCollectionPage} />,
                <Route exact key='edit-layout-collection' path='/admin/homepage_design/:layoutCollectionId'>
                  <FocusedPageWrapper
                    setIsDesktopNavOpen={setIsDesktopNavOpen}
                    isDesktopNavOpen={isDesktopNavOpen}
                    setShowToggleLeftNavButton={setShowToggleLeftNavButton}
                  >
                    <EditLayoutCollectionPage />
                  </FocusedPageWrapper>
                </Route>,
                <Route exact key='admin-ticket-list' path='/admin/tickets'>
                  <FocusedPageWrapper
                    setIsDesktopNavOpen={setIsDesktopNavOpen}
                    isDesktopNavOpen={isDesktopNavOpen}
                    setShowToggleLeftNavButton={setShowToggleLeftNavButton}
                  >
                    <TicketListPage />
                  </FocusedPageWrapper>
                </Route>,
                <Route exact key='workflows' path='/admin/ai/workflows' component={WorkflowListPage} />,
                <Route exact key='workflow' path='/admin/ai/workflows/:workflowId' component={WorkflowPage} />,
              ]}

              {journeyManager && [
                <Route exact key='journey-blueprint-settings' path='/admin/journeys/settings' component={JourneyBlueprintSettingsPage} />,
                <Route exact key='onboarding-buddies' path='/admin/onboarding_buddies' component={AssignOnboardingBuddiesPage} />,
                <Route exact key='preboarding-experience' path='/admin/preboarding_experience'>
                  <LayoutCollectionsListPage isPreboarding />
                </Route>,
                <Route exact key='create-layout-collection' path='/admin/preboarding_experience/new'>
                  <CreateLayoutCollectionPage isPreboarding />
                </Route>,
                <Route exact key='edit-preboarding-layout-collection' path='/admin/preboarding_experience/:layoutCollectionId'>
                  <FocusedPageWrapper
                    setIsDesktopNavOpen={setIsDesktopNavOpen}
                    isDesktopNavOpen={isDesktopNavOpen}
                    setShowToggleLeftNavButton={setShowToggleLeftNavButton}
                  >
                    <EditLayoutCollectionPage />
                  </FocusedPageWrapper>
                </Route>,
              ]}

              {(journeyManager || journeyCreator || journeyCollaborator) && [
                <Route exact key='journeyBlueprints-list' path='/admin/journeys' component={JourneyBlueprintListPage} />,
                <Route exact key='user-journeys' path='/admin/users/:userId/journeys/:selectedTab?' component={EmployeeJourneysPage} />,
                <Route exact key='journey-dashboard' path='/admin/journeys_dashboard/:selectedTab?' component={JourneysDashboardPage} />,
                <Route
                  exact
                  key='create-step-page'
                  path='/admin/journey_blueprints/:journeyBlueprintId/journeys/:journeyId/:stepType/new'
                  component={CreateStepPage}
                />,

                <Route
                  exact
                  key='create-step-template-page'
                  path='/admin/journeys/:journeyBlueprintId/:stepTemplateType/new'
                  component={CreateStepTemplatePage}
                />,
                <Route
                  exact
                  key='update-communication'
                  path='/admin/journeys/communications/:id'
                  component={EditCommunicationPage}
                />,
                <Route
                  exact
                  key='update-task'
                  path='/admin/journeys/tasks/:id'
                  component={EditTaskPage}
                />,
                <Route
                  exact
                  key='list-tasks'
                  path='/admin/tasks'
                  component={TaskListPage}
                />,
                <Route
                  exact
                  key='list-tasks'
                  path='/admin/tasks/:selectedTab'
                  component={TaskListPage}
                />,
                <Route
                  exact
                  key='update-calendar-event'
                  path='/admin/journeys/calendar_events/:id'
                  component={EditCalendarEventPage}
                />,
                <Route
                  exact
                  key='update-introduction'
                  path='/admin/journeys/introductions/:id'
                  component={EditIntroductionPage}
                />,
                <Route
                  exact
                  key='update-meeting'
                  path='/admin/journeys/meetings/:id'
                  component={EditMeetingPage}
                />,
                <Route
                  exact
                  key='update-survey-task-template'
                  path='/admin/journeys/:journeyBlueprintId/survey_templates/:id'
                  render={routeProps => (
                    <EditSurveyTaskTemplatePage
                      setIsDesktopNavOpen={setIsDesktopNavOpen}
                      {...routeProps}
                    />
                  )}
                />,
                <Route exact key='edit-journeyBlueprint' path='/admin/journeys/:journeyBlueprintId/:viewMode?' component={JourneyBlueprintEditorPage} />,

                <Route
                  exact
                  key='edit-step-template'
                  path='/admin/journeys/:journeyBlueprintId/:stepTemplateType/:id'
                  component={EditStepTemplatePage}
                />,
              ]}

              {logoManager && [
                <Route exact key='logo-list' path='/admin/logos' component={LogoListPage} />,
                <Route exact key='logo-editor' path='/admin/logos/:logoId' component={LogoEditorPage} />,
              ]}

              {companyValueManager && [
                <Route exact key='companyValues-list' path='/admin/company_values' component={CompanyValueListPage} />,
                <Route exact key='companyValues-editor' path='/admin/company_values/:companyValueId' component={CompanyValueEditorPage} />,
              ]}

              {groupManager && [
                <Route exact key='group-list' path='/admin/teams' component={GroupListPage} />,
                <Route exact key='group-editor' path='/admin/teams/:groupId' component={GroupEditorPage} />,
                <Route exact key='group-type-list' path='/admin/team_types' component={GroupTypeListPage} />,
                <Route exact key='group-type-editor' path='/admin/team_types/:groupTypeId' component={GroupTypeEditorPage} />,
              ]}

              {stylesManager && [
                <Redirect exact key='styles-redirect' from='/admin/custom_styles/' to='/admin/custom_styles/navigation' />,
                <Route exact key='custom_styles' path='/admin/custom_styles/:section?' component={CustomStylesPage} />,
              ]}

              {((superAdmin && isCleary) || clearyAdmin) && (
                <Route key='styleguide' path='/admin/styleguide/' component={StyleguideRouter} />
              )}

              {clearyAdmin && [
                <Route exact key='cleary-admin' path='/admin/cleary_admin/' component={ClearyAdminPage} />,
                <Route
                  exact
                  key='user-external-alerts'
                  path='/admin/user_external_alerts'
                  component={UserExternalAlertsPage}
                />,
                <Route
                  exact
                  key='platform-access-audience'
                  path='/admin/soft_launch/platform_access_audience'
                  component={SoftLaunchPlatformAccessAudiencePage}
                />,
                <Route key='ai-playground' path='/admin/ai/playground/' component={AiPlaygroundPage} />,
                <Route
                  key='article-template-categories-list'
                  path='/admin/article/template_categories'
                  component={TemplateCategoriesListPage}
                />,
              ]}

              {(clearyAdmin || trialCompaniesAdmin) && [
                <Route exact key='companies' path='/admin/companies' component={CompanyListPage} />,
                <Route exact key='company-editor' path='/admin/companies/:companyId' component={CompanyEditorPage} />,
              ]}

              {itManager && [
                <Route exact key='integrations-page' path='/admin/app_integrations' component={IntegrationsPage} />,
                <Route exact key='integrations-page' path='/admin/app_integrations/google_drive' component={GoogleDriveIntegrationPage} />,
                <Route exact key='managed-groups' path='/admin/managed_groups' component={ManagedGroupListPage} />,
                <Route exact key='create-managed-group' path='/admin/managed_groups/new' component={CreateManagedGroupPage} />,
                <Route exact key='edit-managed-group' path='/admin/managed_groups/:managedGroupId' component={EditManagedGroupPage} />,
                <Route exact key='app-launcher-apps' path='/admin/apps' component={AppListPage} />,
                <Route exact key='app-launcher-apps-editor' path='/admin/apps/:appId' component={AppEditorPage} />,
              ]}


              {surveyCreator && [
                <Route exact key='survey-list' path='/admin/surveys' component={FormListPage} />,
                <Route exact key='survey-preview' path='/admin/surveys/:formId/preview' component={FormPreviewPage} />,
                <Route exact key='question-detail' path='/admin/surveys/:formId/questions/:questionId/answers' component={QuestionDetailPage} />,
                <Route exact key='survey-create' path='/admin/surveys/new' component={CreateFormPage} />,
                <Route exact key='survey-editor' path='/admin/surveys/:formId' render={routeProps => <EditFormPage setIsDesktopNavOpen={setIsDesktopNavOpen} {...routeProps} />} />,
              ]}

              {(surveyCreator || journeyCreator || journeyCollaborator) && (
                <Route exact key='survey-editor' path='/admin/surveys/:formId/responses/:responseId' component={ResponseDetailPage} />
              )}

              {/*
                This redirect sends the user to the first admin path they can access should they nav to /admin.
                Else, the user may hit a blank page on a route they can't access.
                We wrap it in an AdminRoute so it isn't excuted on each mount, sending a user there right away.
              */}
              {!!firstAdminPath && (
                <Redirect exact key='redirect-first-path' from='/admin' to={`/admin/${firstAdminPath}`} />
              )}

              {
                !firstAdminPath && !analyticsAdmin && (
                  <Route component={Error404} />
                )
              }
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default AdminAppRouter
