import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from 'components/search/option_renderers/searchResultOption'

interface AttachedFileContentType {
  filename: string
  embeddingContent: string
  displayIcon: string
}

interface AttachedFileContentOptionProps {
  option: {
    attachedFileContent: AttachedFileContentType
    highlights: Partial<AttachedFileContentType>
  }
}

const AttachedFileContentOption: React.FC<AttachedFileContentOptionProps> = ({ option }) => {
  const { attachedFileContent, highlights } = option
  const { filename, embeddingContent, displayIcon } = { ...attachedFileContent, ...highlights }

  return (
    <SearchResultOption
      image={<CdnSvg src={displayIcon} className='SearchResultOptionImg mr-1' />}
      title={filename}
      secondaryTexts={[
        embeddingContent,
      ]}
    />
  )
}

export default AttachedFileContentOption
