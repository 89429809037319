import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import { ButtonSecondarySmall } from 'components/common/buttons'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.buddies_table')

const AskToOptInButton = ({ user }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const askToOptIn = () => {
    setIsLoading(true)
    const onSuccess = () => {}
    const onFinally = () => {
      setIsLoading(false)
    }

    const params = { userId: user.id }

    dispatch(onboardingBuddySlice.asyncActions.admin.askToOptIn(params, onSuccess, onFinally))
  }

  return (
    <ButtonSecondarySmall onClick={() => askToOptIn()} showLoadingSpinner={isLoading}>
      {I18N('ask_to_opt_in')}
    </ButtonSecondarySmall>
  )
}

export default AskToOptInButton
