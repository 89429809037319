import React from 'react'
import SlackFeedback from 'react-slack-feedback'

import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import { useSelector } from 'react-redux'
import slackWidgetSlice from 'redux/slices/slackWidgetSlice'

const I18N = i18nPath('views.feedback')

const SlackFeedbackWidget = ({ channelName }) => {
  const hideWidget = useSelector(slackWidgetSlice.selectors.hideSlackWidget())

  const feedbackTypes = [
    { value: I18N('bug'), label: I18N('bug') },
    { value: I18N('suggestion'), label: I18N('suggestion') },
  ]

  const handleSubmit = (payload, success, error) => {
    const [{ title, text }] = payload.attachments

    API.sendFeedback(title, text, document.location.href)
      .then(success)
      .catch(error)
  }

  if (hideWidget) {
    return null
  }

  return (
    <div id='SlackFeedback'>
      <SlackFeedback
        channel={`#${channelName}`}
        feedbackTypes={feedbackTypes}
        onSubmit={handleSubmit}
        translations={{
          'trigger.text': I18N('send'),
          'submit.send': I18N('send'),
          'submit.sent': I18N('sent'),
          'submit.sending': I18N('sending'),
          'header.title': I18N('header'),
          'label.type': I18N('type'),
          'label.message': I18N('message_header'),
          'placeholder': I18N('message'),
        }}
        theme={{
          trigger: {
            color: '#5d606c',
            hoverColor: '#5d606c',
            backgroundColor: '#ffffff',
            hoverBackgroundColor: '#ffffff',
            border: '1px solid #b1becd',
            borderRadius: '30px',
          },
        }}
      />
    </div>
  )
}

export default SlackFeedbackWidget
