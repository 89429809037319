import React from 'react'

const SunIcon = ({ height = 15, width = 15, fillColor = 'var(--timezone-sun-color)' }) => (
  <svg width={width} height={height} viewBox='0 0 24 25' fill={fillColor} xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M4.58427 12.3612C4.58427 11.9779 4.28225 11.6667 3.91011 11.6667H0.674157C0.302022 11.6667 0 11.9779 0 12.3612C0 12.7445 0.302022 13.0556 0.674157 13.0556H3.91011C4.28225 13.0556 4.58427 12.7445 4.58427 12.3612Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M23.3256 11.6667H20.0897C19.7176 11.6667 19.4155 11.9779 19.4155 12.3612C19.4155 12.7445 19.7176 13.0556 20.0897 13.0556H23.3256C23.6978 13.0556 23.9998 12.7445 23.9998 12.3612C23.9998 11.9779 23.6978 11.6667 23.3256 11.6667Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M11.9998 20C11.6277 20 11.3257 20.3111 11.3257 20.6944V24.3056C11.3257 24.6889 11.6277 25 11.9998 25C12.372 25 12.674 24.6889 12.674 24.3056V20.6944C12.674 20.3111 12.372 20 11.9998 20Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M11.9998 4.72222C12.372 4.72222 12.674 4.41111 12.674 4.02778V0.694445C12.674 0.311111 12.372 0 11.9998 0C11.6277 0 11.3257 0.311111 11.3257 0.694445V4.02778C11.3257 4.41111 11.6277 4.72222 11.9998 4.72222Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M18.1616 17.7444C17.8973 17.4722 17.4713 17.4722 17.207 17.7444C16.9427 18.0167 16.9427 18.4556 17.207 18.7278L19.5126 21.1028C19.6447 21.2389 19.8173 21.3056 19.9899 21.3056C20.1625 21.3056 20.3351 21.2389 20.4672 21.1028C20.7315 20.8306 20.7315 20.3917 20.4672 20.1194L18.1616 17.7444Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M5.67911 6.8501C5.81125 6.98621 5.98383 7.05288 6.15642 7.05288C6.329 7.05288 6.50158 6.98621 6.63372 6.8501C6.89799 6.57788 6.89799 6.13899 6.63372 5.86677L4.3281 3.49176C4.06383 3.21954 3.63776 3.21954 3.3735 3.49176C3.10923 3.76399 3.10923 4.20288 3.3735 4.4751L5.67911 6.8501Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M5.67911 17.7444L3.3735 20.1194C3.10923 20.3917 3.10923 20.8306 3.3735 21.1028C3.50563 21.2389 3.67821 21.3056 3.8508 21.3056C4.02338 21.3056 4.19597 21.2389 4.3281 21.1028L6.63372 18.7278C6.89799 18.4556 6.89799 18.0167 6.63372 17.7444C6.36945 17.4722 5.94338 17.4722 5.67911 17.7444Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M17.6843 7.05556C17.8569 7.05556 18.0295 6.98889 18.1616 6.85278L20.4672 4.47778C20.7315 4.20556 20.7315 3.76667 20.4672 3.49445C20.2029 3.22223 19.7769 3.22223 19.5126 3.49445L17.207 5.86945C16.9427 6.14167 16.9427 6.58056 17.207 6.85278C17.3391 6.98612 17.5117 7.05556 17.6843 7.05556Z' />
    <path d='M13.6861 17.4871C16.4686 16.4816 17.933 13.343 16.957 10.4768C15.9809 7.61053 12.934 6.10204 10.1515 7.10748C7.36895 8.11291 5.90454 11.2515 6.8806 14.1178C7.85666 16.984 10.9036 18.4925 13.6861 17.4871Z' />
  </svg>

)

export default SunIcon
