import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from 'redux/store'
import denormalizedJsonApiResponse from 'utils/denormalizedJsonApiResponse'
import _ from 'lodash'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import ErrorBoundary from 'components/errorBoundaries'

import API from 'services/api'
import { initTracking } from 'services/tracker'

import ReactGA from 'react-ga'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler
} from 'chart.js'
import pageVariables from 'utils/pageVariables'
import App from 'components/app'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
  ChartDataLabels
)

// datalabels is on by default, so we set it to default false here.
ChartJS.defaults.plugins.datalabels.display = false

// TODO we need to remove this and make sure it doesn't break elsewhere
global._ = _

const csrfToken = document.querySelector('[name="csrf-token"]').content

API.init(csrfToken)

const root = document.getElementById('cleary-app-container')

const {
  currentUser,
  lastLoggedUser,
  currentCompany,
  logo,
  imageUrls,
  environment,
  availableLocales,
  amplitudeApiKey,
  cdnDomain,
} = pageVariables

// create default values for variables and css_overrides
const variables = _.get(pageVariables, 'styles.variables', {})
const cssOverrides = _.get(pageVariables, 'styles.cssOverrides', '')
const styles = {
  variables,
  cssOverrides,
}

const denormalizedCurrentUser = currentUser && denormalizedJsonApiResponse({ data: currentUser }, 'user')
const denormalizedCurrentCompany = currentCompany && denormalizedJsonApiResponse({ data: currentCompany }, 'company')
const logoData = logo.data ? logo.data.attributes : {}

// Set the user's preferred skin tone in local storage
window.localStorage.setItem('emoji-mart.skin', denormalizedCurrentUser?.preferredSkinTone || 1)


ReactGA.initialize('UA-123803888-1', {
  debug: false,
  titleCase: false,
  gaOptions: {
    userId: _.get(denormalizedCurrentUser, 'id'),
  },
})

initTracking(amplitudeApiKey, denormalizedCurrentUser, denormalizedCurrentCompany)

const store = configureStore({
  currentCompany: denormalizedCurrentCompany,
  lastLoggedUser,
  environment,
  logo: logoData,
  imageUrls,
  styles,
  availableLocales,
  cdnDomain,
})

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  root
)
