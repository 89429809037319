import React from 'react'
import CdnSvg from 'components/common/cdnSvg'

const circledCheckIconPath = '/images/circledCheckIcon.svg'

const TaskableTitle = ({
  taskable,
}) => (
  <h4 className='mb-3'>
    <CdnSvg src={circledCheckIconPath} className='mr-2' />
    {taskable?.name}
  </h4>
)

export default TaskableTitle
