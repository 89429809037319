import React from 'react'

import classNames from 'classnames'

const DesktopSectionHeaders = ({
  headers, handleOnChange, currentSection, onMouseEnter,
}) => (
  <div className='DesktopSectionHeaders px-2 pt-4 h-100' onMouseEnter={onMouseEnter}>
    <ul className='list-unstyled h-100 position-relative px-4'>
      {headers.map(({ header, id }) => (
        <li className={classNames('pointer mb-4', { SectionActive: id === currentSection })} key={id} onClick={() => handleOnChange(id)}>
          {header}
        </li>
      ))}
    </ul>
  </div>
)

export default DesktopSectionHeaders
