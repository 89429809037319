import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import CardWithTable from 'components/analytics/common/cardWithTable'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import TransferPageOwnershipModal from 'components/pages/actions_dropdown/transferPageOwnershipModal'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import { InactiveOwnerPageType } from 'types/analytics/pages'
import { i18nPath } from 'utils/i18nHelpers'
import TargetingRulesType from 'types/audience/targetingRules'

const I18N = i18nPath('views.platform_analytics.pages.inactive_owners')

const womanWithComputerPath = '/images/analytics/womanWithComputerIcon.svg'


interface InactiveOwnersProps {
  targetingRules?: TargetingRulesType
}

const InactiveOwners = ({ targetingRules }: InactiveOwnersProps) => {
  const dispatch = useDispatch()

  const [pageToReassign, setPageToReassign] = useState<null | InactiveOwnerPageType>(null)

  const { data, totalItems, totalPages } = useSelector(pageAnalyticsSlice.selectors.getInactiveOwnerPages())

  const { inactiveOwnerPages: { isLoading } } = useSelector(pageAnalyticsSlice.selectors.getMetaData())

  const isInactiveOwnerPagesEmpty = totalItems === 0 && !isLoading

  const fetchData = (skipLoading = false) => {
    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchInactiveOwnerPages({
      page: 1,
      perPage: 10,
      targetingRules,
    }, skipLoading))
  }

  useEffect(() => {
    fetchData()
  }, [targetingRules])

  const columns = [
    {
      header: I18N('page'),
      col: 'col-title',
      accessor: (page: InactiveOwnerPageType) => <Link to={`/platform_analytics/pages/${page.id}`} className='text-truncate w-100 d-block'>{page.title}</Link>,
    },
    {
      header: I18N('owner'),
      col: 'col-owner',
      accessor: (page: InactiveOwnerPageType) => (
        <div className='d-flex flex-row align-items-center'>
          <EmployeeThumbnailPhoto employee={page.owner} size='1.5rem' />
          <span className='font-weight-500 ml-1'>{page.owner.preferredFullName}</span>
        </div>
      ),
    },
    {
      header: I18N('action'),
      col: 'col-action',
      style: { width: '25%' },
      accessor: page => (
        page.permittedActions?.canChangePageOwner ? (
          <span
            className='pointer link-color font-weight-600'
            onClick={() => setPageToReassign(page)}
          >
            {I18N('reassign')}
          </span>
        ) : (
          <span />
        )
      ),
    },
  ]

  return (
    <>
      {isInactiveOwnerPagesEmpty ? (
        <EmptyStateCard
          title={I18N('title', { count: 0 })}
          iconPath={womanWithComputerPath}
          text={I18N('empty_state_text')}
          classname='PagesTables'
        />
      ) : (
        <>
          <CardWithTable
            title={I18N('title', { count: totalItems })}
            tableColumns={columns}
            tableData={data}
            classname='PagesTables'
            isLoading={isLoading}
            showSeeMoreLink={totalPages > 1}
            seeMorePath='/platform_analytics/pages_analytics/inactive_owner_pages'
          />
          <TransferPageOwnershipModal
            key={`selected-page-${pageToReassign?.id}`}
            page={pageToReassign}
            isOpen={!_.isEmpty(pageToReassign)}
            onClose={() => setPageToReassign(null)}
            onSuccess={() => fetchData(true)}
          />
        </>
      )}
    </>
  )
}

export default InactiveOwners
