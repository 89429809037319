import React from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import {
  Button,
  ButtonLarge,
  ButtonLink,
  ButtonNarrow,
  ButtonSecondary,
  ButtonSecondarySmall,
  ButtonSmall,
  ButtonSmallNarrow
} from 'components/common/buttons'
import { DropdownButton, DropdownItem } from 'components/common/dropdownButton'
import CheckboxToggleButtonGroup from 'components/common/buttons/checkboxToggleButtonGroup'
import BackButton from 'components/common/backButton'
import CancelButton from 'components/common/cancelButton'
import SettingsLinkButton from 'components/common/buttons/settingsLinkButton'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import EmojiOrPlaceholder from 'components/common/emojiOrPlaceholder'
import EmojiPicker from 'components/common/emojiPicker'
import CdnSvg from 'components/common/cdnSvg'

const ButtonsPage = () => (
  <main className='Styleguide ButtonsPage container mt-4'>
    <h1>
      <StyleguideBaseLink /> / Buttons
    </h1>
    <section className='mt-4'>
      <h3>components/common/buttons</h3>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th>Component Name</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Button</td>
            <td>Standard Button</td>
            <td>
              <Button>My Sample Button</Button>
            </td>
          </tr>
          <tr>
            <td>Button</td>
            <td>Disabled Standard Button</td>
            <td>
              <Button disabled>My Disabled Button</Button>
            </td>
          </tr>
          <tr>
            <td>Button</td>
            <td>Loading indicator on Standard Button</td>
            <td>
              <Button showLoadingSpinner>My Loading Button</Button>
            </td>
          </tr>
          <tr>
            <td>Button</td>
            <td>Loading indicator on Disabled Standard Button</td>
            <td>
              <Button showLoadingSpinner disabled>
                My Loading Button
              </Button>
            </td>
          </tr>
          <tr>
            <td>Button Variants</td>
            <td>Danger Button</td>
            <td>
              <Button variant='danger'>Danger Button</Button>
            </td>
          </tr>
          <tr>
            <td>Button Variants</td>
            <td>Secondary Danger Button</td>
            <td>
              <Button variant='secondary-danger'>Secondary Danger</Button>
            </td>
          </tr>
          <tr>
            <td>Button Variants</td>
            <td>Tag Button</td>
            <td>
              <Button variant='tag'>Tag Button</Button>
            </td>
          </tr>
          <tr>
            <td>ButtonNarrow</td>
            <td>Button Narrow</td>
            <td>
              <ButtonNarrow>Narrow</ButtonNarrow>
            </td>
          </tr>
          <tr>
            <td>ButtonNarrow</td>
            <td>Button Narrow with Loading Indicator</td>
            <td>
              <ButtonNarrow showLoadingSpinner>My Narrow Button</ButtonNarrow>
            </td>
          </tr>
          <tr>
            <td>ButtonNarrow</td>
            <td>Disabled Button Narrow</td>
            <td>
              <ButtonNarrow disabled>Disabled</ButtonNarrow>
            </td>
          </tr>
          <tr>
            <td>ButtonSecondary</td>
            <td>Secondary Button</td>
            <td>
              <ButtonSecondary>Secondary</ButtonSecondary>
            </td>
          </tr>
          <tr>
            <td>ButtonSecondary</td>
            <td>Disabled Secondary Button</td>
            <td>
              <ButtonSecondary disabled>Disabled</ButtonSecondary>
            </td>
          </tr>
          <tr>
            <td>ButtonSecondary</td>
            <td>Secondary Button with Loading</td>
            <td>
              <ButtonSecondary showLoadingSpinner>Secondary</ButtonSecondary>
            </td>
          </tr>
          <tr>
            <td>ButtonLink</td>
            <td>Button Link</td>
            <td>
              <ButtonLink to='/admin/styleguide'>Link</ButtonLink>
            </td>
          </tr>
          <tr>
            <td>ButtonLarge</td>
            <td>Button Large</td>
            <td>
              <ButtonLarge>Large</ButtonLarge>
            </td>
          </tr>
          <tr>
            <td>ButtonSmall</td>
            <td>Button Small</td>
            <td>
              <ButtonSmall>Small</ButtonSmall>
            </td>
          </tr>
          <tr>
            <td>ButtonSecondarySmall</td>
            <td>Button Secondary Small</td>
            <td>
              <ButtonSecondarySmall>Secondary Small</ButtonSecondarySmall>
            </td>
          </tr>
          <tr>
            <td>ButtonSmallNarrow</td>
            <td>Button Small Narrow</td>
            <td>
              <ButtonSmallNarrow>Small</ButtonSmallNarrow>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section className='mt-5'>
      <h3>other buttons</h3>
      <table className='table table-hover'>
        <thead>
          <tr>
            <th>filename</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>DropdownButton</td>
            <td>Dropdown Button</td>
            <td>
              <DropdownButton title='Dropdown'>
                <DropdownItem eventKey='1'>Action</DropdownItem>
                <DropdownItem eventKey='2'>Another action</DropdownItem>
                <DropdownItem eventKey='3'>Another item</DropdownItem>
                <DropdownItem eventKey='4'>Yet another action</DropdownItem>
                <DropdownItem eventKey='5'>Wait there&apos;s more</DropdownItem>
                <DropdownItem eventKey='6'>Now you can see me</DropdownItem>
                <DropdownItem eventKey='7'>Nothing to see here</DropdownItem>
              </DropdownButton>
            </td>
          </tr>
          <tr>
            <td>backButton</td>
            <td>Back Button</td>
            <td>
              <BackButton url='/admin/styleguide'>My Sample Button</BackButton>
            </td>
          </tr>
          <tr>
            <td>backButton</td>
            <td>Back Button with Custom text</td>
            <td>
              <BackButton url='/admin/styleguide' backToText='the awesome styleguide'>
                My Sample Button
              </BackButton>
            </td>
          </tr>
          <tr>
            <td>cancelButton</td>
            <td>Cancel Button</td>
            <td>
              <CancelButton />
            </td>
          </tr>
          <tr>
            <td>checkboxToggleButtonGroup</td>
            <td>Checkbox Toggle Button Group</td>
            <td>
              <CheckboxToggleButtonGroup
                options={[
                  { label: 'foo', value: 0 },
                  { label: 'bar', value: 1 },
                ]}
                currentValue={[0]}
                label='My Checkbox Group'
              />
            </td>
          </tr>
          <tr>
            <td>SettingsLinkButton</td>
            <td>button for settings with gear icon</td>
            <td>
              <SettingsLinkButton />
            </td>
          </tr>
          <tr>
            <td>IconButtonWithPopover</td>
            <td>button for icons with popover</td>
            <td>
              <IconButtonWithPopover
                id='1'
                text='text'
                icon={<CdnSvg src='/images/duplicateIcon.svg' />}
              />
            </td>
          </tr>
          <tr>
            <td>EmojiPicker</td>
            <td>button for choosing emojis with popover, you can add any trigger</td>
            <td>
              <EmojiPicker trigger={<EmojiOrPlaceholder />} />
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
)

export default ButtonsPage
