import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useQueryParams from 'components/common/hooks/useQueryParams'

import searchSlice, { PAGE_SIZES } from 'redux/slices/search'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { useHistory, useLocation } from 'react-router-dom'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SearchResultsTemplate from './searchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const I18N = i18nPath('views.search.search_results.search_results')
const bloomfireIconPath = '/images/bloomfire.svg'

const Author = ({ result: { avatar, author } }) => {
  if (!avatar || !author) {
    return null
  }

  return (
    <div className='d-flex flex-row align-items-center'>
      <div>
        <img src={avatar}
          className='bordered-thumbnail mr-1'
          style={{ height: '22px', width: '22px' }}
        />
      </div>
      <div className='text-primary text-small font-weight-500'>
        {author}
      </div>
    </div>
  )
}


const BloomfireResults = ({ showLabel = true }) => {
  const location = useLocation()
  const history = useHistory()
  const queryParams = useQueryParams()
  const currentCompany = useCurrentCompany()
  const label = currentCompany.settings.bloomfireSearch?.integrationName || 'bloomfire'
  const imagePath = currentCompany.settings.bloomfireSearch?.integrationIconPath || bloomfireIconPath
  const searchResultsFilter = queryParams.filter || 'all'
  const page = parseInt(queryParams.page) || 1
  const selected = searchResultsFilter === 'bloomfire'
  const pageSize = selected ? PAGE_SIZES.pageFiltered : PAGE_SIZES.pageAll
  const searchLocation = selected ? 'pageFiltered' : 'pageAll'

  const { totalResultsByType, isLoadingBloomfire } = useSelector(searchSlice.selectors.getMetaData(searchLocation))
  const bloomfireSearchResults = useSelector(searchSlice.selectors.getBloomfireSearchResults(searchLocation))
  const [slicedBloomfireSearchResults, setSlicedBloomfireSearchResults] = useState([])

  const count = totalResultsByType.bloomfire
  const seeAllText = I18N(label === 'bloomfire' ? 'see_all_bloomfire' : 'see_all', { count })

  const setPage = (page) => {
    const newParams = new URLSearchParams(location.search)
    newParams.set('page', page)
    history.push(`${location.pathname}?${newParams.toString()}`)
  }

  useEffect(() => {
    if (selected) {
      setSlicedBloomfireSearchResults(bloomfireSearchResults.slice((page - 1) * pageSize, page * pageSize))
    } else {
      setPage(1)
      setSlicedBloomfireSearchResults(bloomfireSearchResults.slice(0, pageSize))
    }
  }, [page, selected, bloomfireSearchResults])

  return (
    <SearchResultsTemplate
      showLabel={showLabel}
      label={label}
      isLoading={isLoadingBloomfire}
      count={totalResultsByType.bloomfire}
      page={page}
      setPage={setPage}
      selected={selected}
      seeAllText={seeAllText}
      seeAllFilter='bloomfire'
      results={slicedBloomfireSearchResults.map(result => (
        <SearchResultTemplate
          key={result.value}
          displayPath={result.url}
          image={<CdnSvg src={imagePath} style={{ height: '29px', width: '29px' }} />}
          title={result.title}
          subtitle={<Author result={result} />}
          description={I18N('edited', { date: i18nMoment(result.updatedAt).fromNow() })}
          result={result}
          openNewTab
        />
      ))}
    />
  )
}

export default BloomfireResults
