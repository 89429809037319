import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import ForwardArrowIcon from 'components/icons/forwardArrowIcon'
import RoundedAvatars from 'components/common/roundedAvatars'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.home.onboarding_buddies')

const SelectBuddyCard = ({ setIsLoaded, EmptyState }) => {
  const dispatch = useDispatch()

  const onboardingBuddies = useSelector(onboardingBuddySlice.selectors.getSuggestedOnboardingBuddies())
  const { isSaving } = useSelector(onboardingBuddySlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(onboardingBuddySlice.asyncActions.fetchSuggested({ perPage: 4 }))
  }, [])

  const selectBuddy = (buddy) => {
    const onSuccess = () => {
      dispatch(onboardingBuddySlice.asyncActions.assignedBuddy())
    }
    dispatch(onboardingBuddySlice.asyncActions.create(buddy.id, onSuccess))
  }

  useEffect(() => {
    if (onboardingBuddies.length > 0) {
      setIsLoaded(true)
    }
  }, [onboardingBuddies.length])

  if (onboardingBuddies.length === 0) {
    return EmptyState
  }

  return (
    <div className='SelectBuddyCard d-flex flex-column'>
      <div className='mb-2'>
        {I18N('choose_buddy_title')}
      </div>
      {isSaving ? (
        <CirclesLoadingIndicator />
      ) : (
        <RoundedAvatars
          users={onboardingBuddies}
          avatarSize='40px'
          className='mb-4'
          onUserClick={selectBuddy}
        />
      )}
      <Link
        to='/onboarding_buddies'
        className='mb-2'
      >
        {I18N('view_all_buddies')} <ForwardArrowIcon className='mb-1 ml-1' fillColor='var(--link-color)' />
      </Link>
      <Link
        to='/onboarding_buddies/do_not_want_onboarding_buddy'
        className='text-small text-secondary'
      >
        {I18N('i_dont_want_a_buddy')}
      </Link>
    </div>
  )
}

export default SelectBuddyCard
