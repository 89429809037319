import React from 'react'

import EditTitleSetting from 'components/admin/layout/layout_collection_sidebar/common/editTitleSetting'
import widgetsMap from 'utils/layout/widgetsMap'
import EditAutoResizable from 'components/admin/layout/layout_collection_sidebar/common/editAutoResizable'

const ArticleTypeWidgetsSettingsSidebar = ({ widget, updateWidget }) => {
  const sidebarTitle = widgetsMap[widget.type].label

  return (
    <div className='ArticleTypeWidgetsSettingsSidebar'>
      <h5>{sidebarTitle}</h5>

      <EditTitleSetting widget={widget} updateWidget={updateWidget} />

      <hr className='w-100' />

      <EditAutoResizable widget={widget} updateWidget={updateWidget} />
    </div>
  )
}

export default ArticleTypeWidgetsSettingsSidebar
