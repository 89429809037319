import React from 'react'
import { useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'

import Card from 'components/common/card'
import ProgressiveImage from 'components/common/images/progressiveImage'
import getCdnUrl from 'utils/getCdnUrl'
import CdnSvg from 'components/common/cdnSvg'

const plusIconPath = '/images/plusIcon.svg'
const I18N = i18nPath('views.qna.events.template_list')

export const TEMPLATES = [
  {
    id: 'all_hands_meeting',
    title: 'all_hands_meeting_title',
    placeholder: 'all_hands_meeting_placeholder',
    image_url: '/images/event_templates/all_hands_meeting.png',
    image_url_wide: '/images/event_templates/all_hands_meeting_wide.png',
  },
  {
    id: 'weekly_team_meeting',
    title: 'weekly_team_meeting_title',
    placeholder: 'weekly_team_meeting_placeholder',
    image_url: '/images/event_templates/weekly_team_meeting.png',
    image_url_wide: '/images/event_templates/weekly_team_meeting_wide.png',
  },
  {
    id: 'ama',
    title: 'ama_title',
    placeholder: 'ama_placeholder',
    image_url: '/images/event_templates/ama.png',
    image_url_wide: '/images/event_templates/ama_wide.png',
  },
  {
    id: 'anonymous_ama',
    title: 'anonymous_ama_title',
    placeholder: 'anonymous_ama_placeholder',
    image_url: '/images/event_templates/anonymous_ama.png',
    image_url_wide: '/images/event_templates/anonymous_ama_wide.png',

    customSettings: {
      allowAnonymousQuestions: true,
      settings: {
        anonymousQuestions: { anonymousByDefault: true },
      },
    },
  },
  {
    id: 'orientation',
    title: 'orientation_title',
    placeholder: 'orientation_placeholder',
    image_url: '/images/event_templates/orientation.png',
    image_url_wide: '/images/event_templates/orientation_wide.png',
  },
  {
    id: 'employee_training',
    title: 'employee_training_title',
    placeholder: 'employee_training_placeholder',
    image_url: '/images/event_templates/employee_training.png',
    image_url_wide: '/images/event_templates/employee_training_wide.png',
  },
  {
    id: 'team_retrospective',
    title: 'team_retrospective_title',
    placeholder: I18N('team_retrospective_placeholder'),
    image_url: '/images/event_templates/team_retrospective.png',
    image_url_wide: '/images/event_templates/team_retrospective_wide.png',
  },
  {
    id: 'contest',
    title: 'contest_title',
    placeholder: 'contest_placeholder',
    image_url: '/images/event_templates/contest.png',
    image_url_wide: '/images/event_templates/contest_wide.png',
  },
  {
    id: 'team_brainstorm',
    title: 'team_brainstorm_title',
    placeholder: 'team_brainstorm_placeholder',
    image_url: '/images/event_templates/team_brainstorm.png',
    image_url_wide: '/images/event_templates/team_brainstorm_wide.png',
  },
  {
    id: 'lunch_and_learn',
    title: 'lunch_and_learn_title',
    placeholder: 'lunch_and_learn_placeholder',
    image_url: '/images/event_templates/lunch_and_learn.png',
    image_url_wide: '/images/event_templates/lunch_and_learn_wide.png',
  },
  {
    id: 'tech_talk',
    title: 'tech_talk_title',
    placeholder: 'tech_talk_placeholder',
    image_url: '/images/event_templates/tech_talk.png',
    image_url_wide: '/images/event_templates/tech_talk_wide.png',
  },
]

const EventTemplateList = ({ onTemplateClick }) => {
  const cdnDomain = useSelector(state => state.cdnDomain)

  return (
    <div className='EventTemplateList'>
      {TEMPLATES.map(template => (
        <Card key={template.id} onClick={() => onTemplateClick(template)}>
          <div className='TemplateItem d-flex flex-column'>
            <ProgressiveImage src={getCdnUrl(cdnDomain, template.image_url)} />
            <div className='p-3'>
              <div className='text-primary font-weight-500 mt-3'>{I18N(template.title)}</div>
              <div className='text-secondary text-small mt-2'>{I18N(template.placeholder)}</div>
            </div>
          </div>
        </Card>
      ))}
      <div className='TemplateItem CreateFromScratch d-flex flex-column align-items-center justify-content-center pointer' onClick={() => onTemplateClick()}>
        <div className='PlusIconCircleWrapper d-flex align-items-center justify-content-center'>
          <CdnSvg className='PlusIcon' src={plusIconPath} />
        </div>
        <div className='text-primary font-weight-500 mt-4'>{I18N('create_from_scratch')}</div>
      </div>
    </div>
  )
}
export default EventTemplateList
