import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import pageTemplateCategorySlice from 'redux/slices/pageTemplateCategories'
import TemplatesNav from 'components/pages/templatesNav'
import TemplatePreviewModal from 'components/pages/templatePreviewModal'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import pageTemplateSlice, { SelectedTemplate } from 'redux/slices/pageTemplates'
import pageSlice from 'redux/slices/pages'

const TemplatesNavContainer = ({
  onClose,
  page,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>({})
  const isTemplateSelected = !_.isEmpty(selectedTemplate)

  const dispatch = useDispatch()

  const {
    isLoading, hasLoadedAllTemplateCategories,
  } = useSelector(pageTemplateCategorySlice.selectors.getMetaData())

  const applyTemplate = () => {
    dispatch(pageSlice.asyncActions.applyTemplate({
      pageId: page.id,
      templateId: selectedTemplate.id,
      templateCategoryId: selectedTemplate.templateCategoryId,
    }, () => setSelectedTemplate({})))
  }

  useEffect(() => {
    if (!hasLoadedAllTemplateCategories) {
      dispatch(pageTemplateCategorySlice.asyncActions.fetchAll())
    }
  }, [])

  useEffect(() => {
    if (isTemplateSelected) {
      dispatch(pageTemplateSlice.asyncActions.fetch(selectedTemplate))
    }
  }, [selectedTemplate.id])


  if (isLoading || !hasLoadedAllTemplateCategories) {
    return <CirclesLoadingIndicator className='p-5' />
  }

  return (
    <>
      <TemplatesNav
        selectedTemplate={selectedTemplate}
        onClose={onClose}
        setSelectedTemplate={setSelectedTemplate}
      />
      {isTemplateSelected && (
        <TemplatePreviewModal
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          visible={isTemplateSelected}
          applyTemplate={applyTemplate}
          page={page}
        />
      )}
    </>
  )
}

export default TemplatesNavContainer
