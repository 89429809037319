import React, { useState } from 'react'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import Card from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import { ButtonSmallNarrow } from 'components/common/buttons'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import {
  USER_IMPORTS_CSV_SEPARATOR_OPTIONS,
  USER_IMPORTS_DATE_FORMAT_OPTIONS
} from 'pages/admin/cleary_admin/clearyAdminPage'
import PublicSshKeys from 'components/admin/integrations/cleary_sftp/publicSshKeys'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { showToastMessage } from 'redux/slices/toasts'
import { useDispatch } from 'react-redux'

interface PublicKey {
  sshPublicKeyId: string
  sshPublicKeyBody: string
}

const I18N = i18nPath('views.integrations_page.integration_card.cleary_sftp')
const docLink = 'https://support.gocleary.com/hc/en-us/articles/11407768157083-Automate-Employee-Sync-via-SFTP'

const ClearySftpCard = ({ configs }) => {
  const dispatch = useDispatch()

  const [sshPublicKeyBody, setSshPublicKeyBody] = useState('')
  const [enabled, setEnabled] = useState(configs?.syncFromSftp?.enabled)
  const [dateFormat, setDateFormat] = useState(configs?.dateFormat)
  const [csvSeparator, setCsvSeparator] = useState(configs?.csvSeparator)
  const [inferManager, setInferManager] = useState(configs?.inferManager)
  const [publicSshKeys, setPublicSshKeys] = useState<PublicKey[]>(configs?.publicSshKeys || [])
  const addPublicKey = (id) => {
    const key = {
      sshPublicKeyId: id,
      sshPublicKeyBody,
    }

    setPublicSshKeys([...publicSshKeys, key])
    setSshPublicKeyBody('')
  }
  const removePublicKey = (id) => {
    setPublicSshKeys(publicSshKeys.filter(item => item.sshPublicKeyId !== id))
  }
  const [updateConfig] = useApi(API.admin.companyConfigurations.update)
  const { subdomain: userName } = useCurrentCompany()
  const onError = error => dispatch(showToastMessage({ message: error, type: 'error' }))
  const [installKey, { isLoading: isInstallingKey }] = useApi(
    API.admin.sftpSshPublicKeys.installKey,
    {
      onSuccess: data => addPublicKey(data.keyId),
      onError,
    }
  )

  const handleOnInstallKey = () => {
    installKey(sshPublicKeyBody)
  }

  const handleConfigUpdate = (value) => {
    updateConfig({
      id: 'user_imports',
      value,
    })

    return true
  }

  return (
    <Card
      className='IntegrationCard ClearySftpIntegrationCard'
      title={(
        <div className='d-flex align-items-center'>
          <div className='IntegrationLogo mr-1'><CdnSvg src='/images/csv.svg' /></div>
          <h5 className='mb-0'>{I18N('title')}</h5>
        </div>
      )}
    >
      <div className='mb-2'>
        {i18nFormat(
          I18N('read_doc'),
          <a
            className='text-primary-link'
            href={docLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            {I18N('doc_title')}
          </a>
        )}
      </div>
      <div>
        <label className='checkbox-label form-inline-check'>
          <input
            style={{ height: '1rem' }}
            type='checkbox'
            checked={enabled}
            onChange={() => handleConfigUpdate({ syncFromSftp: { enabled: !enabled } }) && setEnabled(!enabled)}
          />
          {I18N('enable')}
          <small className='form-text text-secondary'>{I18N('enable_description')}</small>
        </label>
      </div>
      {enabled && (
        <>
          <div className='form-group'>
            <label className='form-label'>{I18N('date_format')}</label>
            <ReactSelect
              options={USER_IMPORTS_DATE_FORMAT_OPTIONS}
              value={USER_IMPORTS_DATE_FORMAT_OPTIONS.find(option => option.value === dateFormat)}
              onChange={s => handleConfigUpdate({ dateFormat: s.value }) && setDateFormat(s.value)}
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>{I18N('csv_separator')}</label>
            <ReactSelect
              options={USER_IMPORTS_CSV_SEPARATOR_OPTIONS}
              value={USER_IMPORTS_CSV_SEPARATOR_OPTIONS.find(option => option.value === csvSeparator)}
              onChange={s => handleConfigUpdate({ csvSeparator: s.value }) && setCsvSeparator(s.value)}
            />
          </div>
          <div>
            <label className='checkbox-label form-inline-check'>
              <input
                style={{ height: '1rem' }}
                type='checkbox'
                checked={inferManager}
                onChange={() => handleConfigUpdate({ inferManager: !inferManager }) && setInferManager(!inferManager)}
              />
              {I18N('infer_manager')}
              <small className='form-text text-secondary'>{I18N('infer_manager_description')}</small>
            </label>
          </div>
          <div><b>SFTP Host:</b> sftp.gocleary.com</div>
          <div><b>Port:</b> 22</div>
          <div><b>User:</b> {userName}</div>
          <div className='mt-2'>{I18N('connect_with_user_and_private_key')}</div>
          <hr />
          <form>
            <div className='form-group'>
              <label className='required-form-label'>
                {I18N('public_ssh')}
              </label>
              <textarea
                className='flex-grow-1 mr-2 form-control'
                value={sshPublicKeyBody}
                onChange={({ target }) => setSshPublicKeyBody(target.value)}
              />
            </div>
            <div className='text-right'>
              <ButtonSmallNarrow
                className='my-1'
                onClick={handleOnInstallKey}
                disabled={isInstallingKey || !sshPublicKeyBody}
                showLoadingSpinner={isInstallingKey}
              >
                {I18N('install_key')}
              </ButtonSmallNarrow>
            </div>
          </form>
          <hr />
          <PublicSshKeys keys={publicSshKeys} removePublicKey={removePublicKey} />
        </>
      )}
    </Card>
  )
}

export default ClearySftpCard
