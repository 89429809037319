import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import { getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import entitySlice from 'redux/slices/entities'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import API from 'services/api'
import appSignal from 'services/appSignal'
import { WorkspaceSliceState, WorkspaceType } from 'types/chat/workspace'

export const initialState: WorkspaceSliceState = {
  workspaceIds: [],
  meta: {
    ...defaultMeta,
    loaded: false,
  },
}

const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    ...defaultActions,

    setWorkspaceIds: (state, action) => {
      state.workspaceIds = action.payload
    },
  },
})

const asyncActions = {
  fetchAll: () => async (dispatch, getState: () => ReduxState) => {
    const state = getState().workspaces

    // load it only once
    if (state.meta.isLoading || state.meta.loaded) {
      return
    }

    try {
      dispatch(workspaceSlice.actions.isLoading(true))
      const response = await API.chat.workspaces.fetchAll()
      const workspacesIds = response.data.data.map(workspace => workspace.id)
      dispatch(entitySlice.actions.add(response))
      dispatch(workspaceSlice.actions.setWorkspaceIds(workspacesIds))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(workspaceSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(workspaceSlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.workspaces.meta,

  getWorkspaces: () => (state: ReduxState): WorkspaceType[] => state.workspaces.workspaceIds.map(id => build(state.entities, 'workspace', id)) || [],
}

export default {
  ...workspaceSlice,
  asyncActions,
  selectors,
}
