import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import pageSlice from 'redux/slices/pages'

const useCurrentPage = () => {
  const { pageSlug, pageId } = useParams()
  const pageIdOrSlug = pageId || pageSlug
  const currentPage = useSelector(pageIdOrSlug ? pageSlice.selectors.getPage(pageIdOrSlug) : () => ({}))

  return currentPage
}

export default useCurrentPage
