import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import CollapsibleCard from 'components/common/collapsibleCard'
import classNames from 'classnames'
import StepTemplateTargetingSettings from 'components/admin/journeys/stepTemplateTargetingSettings'

const I18N = i18nPath('views.admin.journeys.step_templates')

const AdvancedSettings = ({
  children,
  className,
  isStepTemplate = true,
  workingCopy,
  updateWorkingCopy,
  journeyBlueprintType = '',
}) => (
  <CollapsibleCard title={I18N('advanced_settings')} className={classNames('mb-4', className)}>
    <div className='StepTemplatesAdvancedSettings mt-3'>
      {children}
      {isStepTemplate && (
        <StepTemplateTargetingSettings className='mt-4'
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          journeyBlueprintType={journeyBlueprintType}
        />
      )}
    </div>
  </CollapsibleCard>
)

export default AdvancedSettings
