import React from 'react'
import API from 'services/api'
import { LoadingContainer } from 'components/common/loadingContainer'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import useApi from 'components/common/hooks/useApi'
import { Button, ButtonSmallNarrow } from 'components/common/buttons'
import CreatedAuthTokenModal from 'components/admin/users/userEditor/authTokenTabComponents/createdAuthTokenModal'
import Table from 'components/common/tables/table'
import useFetch from 'components/common/hooks/useFetch'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.settings.auth_tokens_section')

const columns = (isDeleting, deleteToken) => ([
  {
    header: I18N('created'),
    id: 'created',
    accessor: authToken => i18nMoment(authToken.createdAt).format('LLL'),
  },
  {
    header: I18N('token'),
    id: 'redactedToken',
    accessor: authToken => authToken.redactedToken,
  },
  {
    header: '',
    id: 'actions',
    className: 'text-right',
    accessor: authToken => (
      <ButtonSmallNarrow
        disabled={isDeleting}
        variant='danger'
        className='ml-3'
        onClick={() => deleteToken(authToken.id)}
        confirm={{
          description: I18N('delete_token_confirmation', { token: authToken.redactedToken }),
          variant: 'danger',
        }}
      >
        {I18N('delete')}
      </ButtonSmallNarrow>
    ),
  },
])

const ApiTokensSection = () => {
  const { callApi: fetchTokens, data: authTokens, isLoaded } = useFetch(API.authTokens.fetchAll)

  const [createToken, { data: createdToken, isLoading: isCreating }] = useApi(API.authTokens.create, {
    onSuccess: fetchTokens,
  })

  const [deleteToken, {
    isLoading: isDeleting,
  }] = useApi(API.authTokens.delete, {
    onSuccess: fetchTokens,
  })

  const isAtTokenLimit = authTokens?.length > 1

  return (
    <div className='ApiTokensSection'>
      <div className='d-flex justify-content-between align-items-center mb-1'>
        <p className='m-0 text-secondary'>{isAtTokenLimit && I18N('help_text')}</p>

        <Button
          disabled={isAtTokenLimit || isCreating}
          onClick={createToken}
          className='ml-2'
        >
          {I18N('create_token')}
        </Button>
      </div>

      <LoadingContainer isLoading={!isLoaded}>
        <div>
          <Table
            data={authTokens}
            columns={columns(isDeleting, deleteToken)}
            isSortable={false}
          />
        </div>
      </LoadingContainer>
      <a className='mt-2' href='https://cleary.readme.io' target='_blank' rel='noopener noreferrer'>
        {I18N('view_api_docs')} <CdnSvg src='/images/externalLinkIcon.svg' />
      </a>
      <CreatedAuthTokenModal createdAuthToken={createdToken} />
    </div>
  )
}

export default ApiTokensSection
