import { GROUP_BY_MONTH, GROUP_BY_WEEK } from 'components/analytics/common/groupByTabs'
import { i18nMoment } from 'utils/i18nHelpers'

export const getWeeklyLabel = (date: string) => {
  const startOfWeek = i18nMoment(date).startOf('isoWeek')
  const endOfWeek = i18nMoment(date).endOf('isoWeek')
  return `${startOfWeek.format('DD MMM')} - ${endOfWeek.format('DD MMM')}`
}

export const dateLabels = (data, groupBy) => data.map(({ date }) => {
  if (groupBy === GROUP_BY_WEEK) {
    return getWeeklyLabel(date)
  }

  const format = groupBy === GROUP_BY_MONTH ? 'MMM' : 'DD MMM'
  return i18nMoment(date).format(format)
})
