import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import ClearyCard from 'components/common/card'
import LineGraph from 'components/analytics/common/lineGraph'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.ai_assistant.dashboard.main_content.dashboard.charts')

const options = {
  elements: {
    point: {
      radius: 2.5,
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
    },
    y: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 4,
        callback(value) {
          if (Number.isInteger(value)) {
            return value
          }

          return null
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true, // makes the legend markers circular
        pointStyle: 'circle', // explicitly sets the point style to circles
      },
    },
    tooltip: {
      callbacks: {
        label(context) {
          if (context.label === I18N('answers_over_time.label')) {
            return context.parsed.y || '0'
          }

          return `${I18N(context.dataset.label)}: ${context.parsed.y || '0'}`
        },
      },
    },
  },
}

const EmptyState = () => (
  <div className='d-flex flex-column align-items-center gap-4 px-5 py-4'>
    <CdnSvg src='/images/illustrations/engines.svg' />
    <p className='text-center text-muted text-small'>{I18N('empty_state')}</p>
  </div>
)

const Charts = ({ dashboardData }) => {
  const currentCompany = useCurrentCompany()
  const { highlightColor, awardedBadgeCardHeroColor } = currentCompany.customStyles.variables.colors

  const firstChartOptions = _.cloneDeep(options)
  firstChartOptions.plugins.legend.display = false

  const isAnswersOverTimeEmpty = Object.values(dashboardData.answersOverTime).filter(Boolean).length === 0
  const isFaqsCreatedOverTimeEmpty = Object.values(dashboardData.faqsCreatedOverTime).filter(Boolean).length === 0

  return (
    <div className='Dashboard__charts d-flex gap-4'>
      <div>
        <ClearyCard title={I18N('answers_over_time.title')}>
          {isAnswersOverTimeEmpty ? (
            <EmptyState />
          ) : (
            <LineGraph
              dataValues={Object.values(dashboardData.answersOverTime) as number[]}
              labels={Array(Object.values(dashboardData.answersOverTime).length).fill(I18N('answers_over_time.label'))}
              classname='w-100'
              chartOptions={firstChartOptions}
              dataValuesOptions={{ tension: 0.3 }}
            />
          )}
        </ClearyCard>
      </div>

      <div>
        <ClearyCard title={I18N('faqs_created_or_updated.title')}>
          {isFaqsCreatedOverTimeEmpty ? (
            <EmptyState />
          ) : (
            <LineGraph
              dataValues={[
                Object.values(dashboardData.faqsCreatedOverTime),
                Object.values(dashboardData.faqsUpdatedOverTime),
              ]}
              dataValueLabels={[I18N('faqs_created.label'), I18N('faqs_updated.label')]}
              labels={Array(Object.values(dashboardData.answersOverTime).length).fill('')}
              classname='w-100'
              chartOptions={options}
              color={[highlightColor, awardedBadgeCardHeroColor]}
            />
          )}
        </ClearyCard>
      </div>
    </div>
  )
}

export default Charts
