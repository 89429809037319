import AsyncSearchInput from 'components/common/asyncSearchInput'
import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { ExternalSyncImportState, ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import ClearyTooltip from 'components/common/clearyTooltip'
import moment from 'moment'

const I18N = i18nPath('views.integrations_page.external_knowledge_section')

type Props = {
  source: ExternalSyncSource
  searchQuery?: string
  setSearchQuery: (query: string) => void
  onAddDocuments: () => void
  isNavigatingToChildFolder: boolean
  importState?: ExternalSyncImportState
  onResyncStarted: () => void
  selectedDocumentsIds: string[]
  onRemoveDocuments: () => void
  isBulkDestroying: boolean
}

const ActionsAndSearch: React.FC<Props> = ({
  source,
  searchQuery,
  setSearchQuery,
  onAddDocuments,
  isNavigatingToChildFolder,
  importState = ExternalSyncImportState.Idle,
  onResyncStarted,
  selectedDocumentsIds,
  onRemoveDocuments,
  isBulkDestroying,
}) => {
  const [resync, { isLoading }] = useApi(API.admin.externalSync.sources.resync, {
    toastSuccessMessage: I18N('resync_successfully_started'),
  })

  const currentUser = useCurrentUser()

  const isSyncScheduled = !!source.syncedAt && moment(source.syncedAt).isAfter()

  return (
    <div className='d-flex justify-content-between align-items-center mb-3'>
      <AsyncSearchInput
        placeholder={I18N('search_by_title')}
        onKeyUp={setSearchQuery}
        className='mb-0 w-100'
        inputGroupClassName='w-sm-40 w-100'
        defaultValue={searchQuery}
      />

      {selectedDocumentsIds.length > 0 ? (
        <div>
          <span className='mr-2'>{I18NCommon('selected_items_count', { count: selectedDocumentsIds.length })}</span>
          <Button variant='danger' onClick={onRemoveDocuments} confirm showLoadingSpinner={isBulkDestroying}>
            {I18NCommon('remove_items')}
          </Button>
        </div>
      ) : !isNavigatingToChildFolder && (
        <div>
          {source.syncedAt && moment(source.syncedAt).isBefore() && (
            <span className='mr-2 text-muted'>
              {I18N('last_synced_at', { date: moment(source.syncedAt).fromNow() })}
            </span>
          )}
          {isSyncScheduled && (
            <span className='mr-2 text-muted'>
              {I18N('sync_will_start_at', { time: moment(source.syncedAt).format('h:mm A') })}
            </span>
          )}
          <Button
            onClick={() => {
              onResyncStarted()
              resync(source.id)
            }}
            variant='secondary'
            className='ml-2'
            showLoadingSpinner={isLoading}
            disabled={importState === ExternalSyncImportState.Importing || isSyncScheduled}
          >
            {I18NCommon('resync_all')}

            <ClearyTooltip
              placement='bottom'
              className='resync-tooltip'
            >
              <span>{I18N('resync_all_tooltip')}</span>
            </ClearyTooltip>
          </Button>

          {source.user.id === currentUser.id && (
            <Button
              className='ml-2'
              onClick={onAddDocuments}
              disabled={importState === ExternalSyncImportState.Importing}
            >
              {I18NCommon('add_more_documents')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default ActionsAndSearch
