import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSmallNarrow } from 'components/common/buttons'
import itAdminSlice from 'redux/slices/itAdmin'

const I18N = i18nPath('views.integrations_page.workos_integration_card')

const WorkosInstallationComponent = ({ savedDomain }) => {
  const dispatch = useDispatch()

  const [domain, setDomain] = useState('')
  const { isConfiguringSSO } = useSelector(itAdminSlice.selectors.getMetaData())

  const configureSSO = () => {
    dispatch(itAdminSlice.asyncActions.admin.configureSSO({ domain }))
  }

  useEffect(() => {
    if (savedDomain) {
      setDomain(savedDomain)
    }
  }, [savedDomain])

  return (
    <>
      <div className='d-flex align-items-center mb-2'>
        <input
          className='flex-grow-1 mr-2'
          value={domain}
          onChange={({ target }) => setDomain(target.value)}
          placeholder={I18N('domain_placeholder')}
          data-testid='workos-domain-input'
        />
        <ButtonSmallNarrow
          onClick={configureSSO}
          disabled={isConfiguringSSO}
          showLoadingSpinner={isConfiguringSSO}
          id='workos-configure-sso-btn'
        >
          {I18N('configure_sso')}
        </ButtonSmallNarrow>
      </div>

    </>
  )
}

export default WorkosInstallationComponent
