import useFetch from 'components/common/hooks/useFetch'
import usePreviousDiff from 'components/common/hooks/usePreviousDiff'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import LoadingSpinner from 'components/common/loadingSpinner'
import PaginationContainer from 'components/common/tables/paginationContainer'
import CelebrationCard from 'pages/people/celebrations_list_page/celebrationCard'
import CelebrationsListEmptyState from 'pages/people/celebrations_list_page/celebrationsListEmptyState'
import React, { useEffect } from 'react'
import API from 'services/api'


interface Props {
  filter: string
}

const CelebrationsList = ({ filter = 'cards_to_sign' }: Props) => {
  const [page, setPage] = useQueryParamState({ param: 'page', initialValue: 1 })

  const {
    data = [], isLoading, paginationData,
  } = useFetch(() => API.celebrations.fetchAll({ filter, page, perPage: 9 }), [filter, page])

  const prevFilter = usePreviousDiff(filter)

  useEffect(() => {
    // make sure to only switch page when switching tab
    // this will avoid resetting the page when the user goes to the url directly with
    // a page query param
    if (prevFilter) {
      setPage(1)
    }
  }, [filter])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (data.length === 0) {
    return <CelebrationsListEmptyState filter={filter} />
  }

  return (
    <>
      <div className='CelebrationsCards my-4'>
        {data.map(celebration => (
          <CelebrationCard celebration={celebration} key={celebration.id} />
        ))}
      </div>

      <PaginationContainer
        page={paginationData.page}
        numPages={paginationData.totalPages}
        perPage={paginationData.totalPages}
        totalCount={paginationData.total}
        handleClick={setPage}
        i18nPrefix='views.celebrations.list_page'
      />
    </>
  )
}

export default CelebrationsList
