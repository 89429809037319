import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { ButtonSmall } from 'components/common/buttons'
import celebrationSlice from 'redux/slices/celebrations'
import { i18nDisplayDateWithoutYear, i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { ProfileUserType } from 'types/user'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.profile.celebration_banner')

const blueBalloonPath = '/images/blueBalloon.svg'
const yellowBalloonPath = '/images/yellowBalloon.svg'
const pinkBalloonPath = '/images/pinkBalloon.svg'

interface CelebrationBannerProps {
  user: ProfileUserType,
}

const CelebrationBanner = ({ user }: CelebrationBannerProps) => {
  const dispatch = useDispatch()
  const celebration = useSelector(celebrationSlice.selectors.getCurrentCelebration())
  const currentCompany = useCurrentCompany()

  useEffect(() => {
    dispatch(celebrationSlice.asyncActions.fetchCurrentCelebration(user.id))

    return () => {
      dispatch(celebrationSlice.actions.clearCurrentCelebration())
    }
  }, [])

  if (!celebration) {
    return null
  }

  const timeKey = celebration.upcoming ? 'upcoming' : 'today'
  const i18nKey = `${celebration.type}.${timeKey}`
  const commentedKey = celebration.hasCommented ? 'commented' : 'not_commented'
  const variables = {
    name: user.preferredName,
    companyName: currentCompany.name,
    date: i18nDisplayDateWithoutYear(celebration.eventDate),
    count: celebration.workAnniversaryYears,
  }


  return (
    <div className={classNames('CelebrationBanner p-4 confetti-background', celebration.type)}>
      <div className='small-balloon balloon-1'><CdnSvg src={blueBalloonPath} /></div>
      <div className='small-balloon balloon-2'><CdnSvg src={yellowBalloonPath} /></div>
      <div className='small-balloon balloon-3 mirror'><CdnSvg src={blueBalloonPath} /></div>
      <div className='small-balloon balloon-4 mirror'><CdnSvg src={yellowBalloonPath} /></div>
      <h4 className='text-white text-center mt-0 mb-2 mr-md-4 mb-md-0'>
        {I18N(`${i18nKey}.title`, variables)}
      </h4>
      <Link to={`${celebration.displayPath}?source=profile_banner`}>
        <ButtonSmall>
          {I18N(`${i18nKey}.cta.${commentedKey}`, variables)}
        </ButtonSmall>
      </Link>
      <div className='large-balloon balloon-5'><CdnSvg src={blueBalloonPath} /></div>
      <div className='large-balloon balloon-6'><CdnSvg src={yellowBalloonPath} /></div>
      <div className='large-balloon balloon-7'><CdnSvg src={pinkBalloonPath} /></div>
      <div className='large-balloon balloon-8'><CdnSvg src={blueBalloonPath} /></div>
      <div className='large-balloon balloon-9'><CdnSvg src={yellowBalloonPath} /></div>
      <div className='large-balloon balloon-10'><CdnSvg src={pinkBalloonPath} /></div>
      <div className='large-balloon balloon-11'><CdnSvg src={blueBalloonPath} /></div>
      <div className='large-balloon balloon-12'><CdnSvg src={pinkBalloonPath} /></div>
    </div>
  )
}

export default CelebrationBanner
