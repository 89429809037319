import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import PillTabSelector from 'components/common/pillTabSelector'
import AboutMe from 'components/profiles/aboutMe'
import Shoutouts from 'components/profiles/shoutouts'
import Articles from 'components/profiles/articles'
import Celebrations from 'components/profiles/celebrations'

const I18N = i18nPath('views.profile')
const AboutMeTab = 'about_me'
const ShoutoutsTab = 'shoutouts'
const RecentArticlesTab = 'recent_articles'
const CelebrationsTab = 'celebrations'

const TabbedContent = ({
  canEdit,
  currentCompany,
  areShoutoutsEnabled,
  areNewsEnabled,
  user,
}) => {
  const { settings } = currentCompany
  const [selectedTab, setSelectedTab] = useState(AboutMeTab)
  const handleTabClick = (value) => {
    setSelectedTab(value)
  }

  const celebrationsVisible = settings?.profile?.fields?.celebrations && (
    settings?.celebrations?.workAnniversaries?.enabled
    || settings?.celebrations?.birthdays?.enabled
    || settings?.celebrations?.newEmployees?.enabled
  )

  return (
    <section className='ProfileTabbedContent mt-3 mt-sm-0'>
      <PillTabSelector
        defaultSelectedIndex={0}
        pillClasses='text-normal'
        tabs={[
          {
            text: I18N(AboutMeTab),
            onClick: () => {
              handleTabClick(AboutMeTab)
            },
            visible: true,
          },
          {
            text: I18N(ShoutoutsTab),
            onClick: () => {
              handleTabClick(ShoutoutsTab)
            },
            visible: areShoutoutsEnabled,
          },
          {
            text: I18N(RecentArticlesTab),
            onClick: () => {
              handleTabClick(RecentArticlesTab)
            },
            visible: areNewsEnabled,
          },
          {
            text: I18N(CelebrationsTab),
            onClick: () => {
              handleTabClick(CelebrationsTab)
            },
            visible: celebrationsVisible,
          },
        ].filter(section => section.visible)}
      />
      {selectedTab === AboutMeTab && (
        <AboutMe
          user={user}
          currentCompany={currentCompany}
          canEdit={canEdit}
        />
      )}
      {selectedTab === ShoutoutsTab && areShoutoutsEnabled && (
        <Shoutouts user={user} />
      )}
      {selectedTab === RecentArticlesTab && (
        <Articles user={user} />
      )}
      {selectedTab === CelebrationsTab && (
        <Celebrations user={user} />
      )}
    </section>
  )
}

export default TabbedContent
