export type ExternalSyncSource = {
  id: string
  type: string
  iconPath: string
  humanName: string
  documentsCount: number
  foldersCount: number
  displayPath: string
  user: any
  syncedAt?: string
}

export type AvailableExternalSyncSource = {
  type: string
  iconPath: string
  humanName: string
  installationUrl: string
}

export enum ExternalSyncDocumentType {
  Folder = 'ExternalSync::GoogleDrive::Folder',
  GoogleDoc = 'ExternalSync::GoogleDrive::GoogleDoc',
  GooglePresentation = 'ExternalSync::GoogleDrive::GooglePresentation',
}

export type ExternalSyncDocument = {
  id: string
  name: string
  modifiedAt?: string
  iconPath: string
  title: string
  displayPath: string
  descendantsCount: number
  externalUrl: string
  type: ExternalSyncDocumentType
  parents?: ExternalSyncDocument[]
}

export enum ExternalSyncImportState {
  Idle = 'idle',
  Importing = 'importing',
  Imported = 'imported',
}
