import React from 'react'
import { useDispatch } from 'react-redux'
import pageSlice from 'redux/slices/pages'
import PageCoverImage from 'components/pages/pageCoverImage'
import FeedbackCounter from 'components/feedback/feedbackCounter'
import UpdaterAndTimestamp from 'components/pages/updaterAndTimestamp'
import TableOfContents from 'components/pages/tableOfContents'
import classNames from 'classnames'
import CollaborativeEditor from 'components/common/tiptap/collaborativeEditor'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCollaborativeDraftContentSave from 'components/pages/hooks/useCollaborativeDraftContentSave'
import useCollaborativePageRecord from 'components/pages/hooks/useCollaborativePageRecord'
import { i18nPath } from 'utils/i18nHelpers'
import TogglePageWidth from 'components/pages/actions_dropdown/togglePageWidth'
import EditableTitle from 'components/common/editable_title'
import FAQsList from 'components/pages/faqs/faqsList'

const I18N = i18nPath('views.pages')

const PageContentEditor = ({ page }) => {
  const dispatch = useDispatch()
  const {
    feedbackEnabled, showTableOfContents, hasUnpublishedChanges,
    draftContentId, draftContent, draftShowFullWidth, draftTitle,
  } = page
  const { settings } = useCurrentCompany()

  const savePage = (changes) => {
    dispatch(pageSlice.asyncActions.autosave({ id: page.id, ...changes }))
  }

  const saveDraftContent = useCollaborativeDraftContentSave(page)
  const { onCollaborativePageRecordChange } = useCollaborativePageRecord()

  const isMissingTitle = !draftTitle || draftTitle === I18N('default_page_title')

  return (
    <div className='PageContentEditor flex-grow-1 overflow-y-auto bg-white'>
      <PageCoverImage
        page={page}
        showActions
        showDraftColumns
        showFullWidth={draftShowFullWidth}
      />
      <div className='d-flex justify-content-center px-4 pt-3 pb-4'>
        {feedbackEnabled && (
          <FeedbackCounter
            feedbackable={page}
            feedbackableType='pages'
            classname='mr-2'
          />
        )}
        <div
          className={classNames(
            'TitleAndHtml flex-grow-1 position-relative',
            draftShowFullWidth ? 'mw-100' : 'mw-page-narrow',
            { feedbackEnabled }
          )}
        >
          <TogglePageWidth
            showFullWidth={draftShowFullWidth}
            onClick={() => savePage({ draftShowFullWidth: !draftShowFullWidth })}
          />

          <div className='d-flex'>
            <EditableTitle
              canEdit
              editModeOnly
              onSave={savePage}
              record={page}
              fieldName='draftTitle'
              showCharacterCount
              showSaveCancelButtons={false}
              focus={isMissingTitle}
              className='flex-grow-1'
            />
          </div>

          <UpdaterAndTimestamp
            page={page}
            canEditPage
            isEditing
            areDraftChangesUnpublished={hasUnpublishedChanges}
          />

          {showTableOfContents && (
            <TableOfContents
              page={page}
              removeTableOfContents={() => savePage({ showTableOfContents: false })}
              isEditing
            />
          )}

          <CollaborativeEditor
            recordId={page.id}
            recordType='Page'
            richTextId={draftContentId}
            onSave={saveDraftContent}
            savedHtml={draftContent}
            configuration={{
              stickyToolbar: true,
              focusOnInitialize: !isMissingTitle,
              videosEnabled: true,
              isZoomEnabled: settings.zoom?.enabled,
              aiEnabled: settings.aiTextGeneration.enabled && settings.pages.aiEnabled,
            }}
            onRecordAttributesChange={onCollaborativePageRecordChange}
          />
          {page.showFaqs && <FAQsList page={page} />}
        </div>
      </div>
    </div>
  )
}

export default PageContentEditor
