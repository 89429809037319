import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { getJourneyCompletionStatusColor } from 'utils/admin/journeys/statusColors'
import StatusLabel from 'components/common/status_label/'
import classNames from 'classnames'

const I18N = i18nPath('views.admin.journeys.journeys.status')

const JourneyStatusLabel = ({ journey, className = '' }) => (
  <StatusLabel
    className={classNames('font-weight-500 text-normal', className)}
    color={getJourneyCompletionStatusColor(journey?.completionStatus)}
  >
    {I18N(journey?.completionStatus, { count: journey?.dueTodayStepsCount })}
  </StatusLabel>
)

export default JourneyStatusLabel
