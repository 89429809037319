import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import { I18NCommon } from 'utils/i18nHelpers'
import { AvailableExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import { getHelpText } from 'components/admin/integrations/external_sync/helpers'

type Props = {
  className?: string
  availableSource: AvailableExternalSyncSource
}

const AddExternalSource: React.FC<Props> = ({
  className, availableSource,
}) => (
  <div className={classNames('bordered px-4 py-2 rounded d-flex justify-content-between align-items-center w-100', className)}>
    <div className='d-flex align-items-center'>
      <CdnSvg src={availableSource.iconPath} />

      <span className='ml-3 bold'>{availableSource.humanName}</span>
      <span className='ml-3 text-primary text-small'>{getHelpText(availableSource.type)}</span>
    </div>

    <a href={availableSource.installationUrl} rel='noopener noreferrer' className='btn btn-primary'>
      {I18NCommon('connect')}
    </a>
  </div>
)

export default AddExternalSource
