import React from 'react'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ContentVariablesDropdown from 'components/common/tiptap/contentVariablesDropdown'

const StepVariablesDropdown = ({
  journeyBlueprint,
  additionalRoles = [],
  isTask = false,
}) => {
  const type = journeyBlueprint?.type || 'onboarding'
  const questionVariables = journeyBlueprint?.questionVariables || []
  const currentCompany = useCurrentCompany()
  const settings = currentCompany?.settings

  const isOnboardingBuddiesEnabled = settings?.journeys?.onboardingBuddies?.enabled
  const isPreboardingEnabled = settings?.journeys?.preboarding?.enabled
  const isOnboardingJourney = type === 'onboarding'
  const isOffboardingJourney = type === 'offboarding'
  const isCustomJourney = type === 'custom'

  const roles = [
    isOnboardingJourney && 'new_hire',
    isOffboardingJourney && 'offboarding_employee',
    isCustomJourney && 'employee_on_journey',
    'manager',
    'journey_admin',
    !isOffboardingJourney && isOnboardingBuddiesEnabled && 'onboarding_buddy',
    ...additionalRoles,
  ].filter(Boolean)

  const additionalVariables = [
    isOnboardingJourney && isPreboardingEnabled && { text: 'preboarding_login_link' },
    isTask && { text: 'task_due_date' },
    ...questionVariables,
  ].filter(Boolean)

  return (
    <ContentVariablesDropdown
      roles={roles}
      additionalVariables={additionalVariables}
      type={type}
    />
  )
}

export default StepVariablesDropdown
