import React from 'react'

import EmployeeMultiLinkWithPopover from 'components/common/employeeMultiLinkWithPopover'
import RoundedAvatars from 'components/common/roundedAvatars'
import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.shoutouts_feed')

const CountWithRoundedAvatars = ({ users, shoutoutId }) => (
  <div className='d-flex flex-column w-100 align-items-center'>
    <RoundedAvatars users={users} />
    <div className='d-flex flex-row flex-wrap justify-content-center'>
      <EmployeeMultiLinkWithPopover
        users={users}
        id={`shoutout-${shoutoutId}-recipients`}
      >
        <span className='font-weight-500 link-color'>{I18N('recipient_count', { count: users.length })}</span>
      </EmployeeMultiLinkWithPopover>
      <span className='ml-1 font-weight-600'>{I18N('received_shoutout')}</span>
    </div>
  </div>
)

const ShoutoutRecipient = ({ user, index, userCount }) => {
  const isNotLast = index !== userCount - 1
  const penultimate = index === userCount - 2
  const separator = penultimate ? I18NCommon('and') : ','

  return (
    <div>
      <EmployeeLinkWithPopover user={user} userLinkClassName='link-color font-weight-500' />
      {isNotLast && <span className='mr-1'>{separator}</span>}
    </div>
  )
}

const ShoutoutUsers = ({ shoutout, alwaysShowCount = false }) => {
  const { id, users } = shoutout
  const userCount = users.length
  const showCount = userCount > 4 || alwaysShowCount

  return (
    <div className='ShoutoutUsers w-100 align-items-center'>
      {showCount ? (
        <CountWithRoundedAvatars users={users} shoutoutId={id} />
      ) : (
        <div className='d-flex flex-row flex-wrap justify-content-center'>
          {users.map((user, index) => (
            <ShoutoutRecipient key={user.id} user={user} index={index} userCount={userCount} />
          ))}
          <span className='ml-1 font-weight-600'>{I18N('received_shoutout')}</span>
        </div>
      )}
    </div>
  )
}

export default ShoutoutUsers
