import React, { useState } from 'react'
import { Popover } from 'reactstrap'
import classNames from 'classnames'

import { DownGrayChevronIcon, UpGrayChevronIcon } from 'components/icons/chevron'

const QuestionVotingSectionDesktop = ({
  question,
  isVotingEnabled,
  hasUpvoteFromCurrentUser,
  hasDownvoteFromCurrentUser,
  upvoteHandler,
  downvoteHandler,
  unvoteHandler,
  voteScoreText,
}) => {
  const [showVotesTooltip, setShowVotesTooltip] = useState(false)

  const voteCounterId = `question-${question.id}-vote-counter`

  return (
    <div
      id={voteCounterId}
      className='d-flex flex-column align-items-center justify-content-start'
      onMouseEnter={() => setShowVotesTooltip(true)}
      onMouseLeave={() => setShowVotesTooltip(false)}
      data-testid={`question-${question.id}-voter-wrapper`}
    >
      <div
        onClick={hasUpvoteFromCurrentUser ? unvoteHandler : upvoteHandler}
        className={classNames('Cleary-chevron px-2', {
          'Cleary-chevron--hover pointer': isVotingEnabled,
          'Cleary-chevron--active pointer': hasUpvoteFromCurrentUser,
        })}
      >
        <UpGrayChevronIcon className='mb-2' />
      </div>
      <h5 className='m-0 p-0'>{voteScoreText}</h5>
      <div
        onClick={hasDownvoteFromCurrentUser ? unvoteHandler : downvoteHandler}
        className={classNames('Cleary-chevron px-2', {
          'Cleary-chevron--hover pointer': isVotingEnabled,
          'Cleary-chevron--active pointer': hasDownvoteFromCurrentUser,
        })}
      >
        <DownGrayChevronIcon className='mt-2' />
      </div>

      <Popover placement='right' isOpen={showVotesTooltip && question.isApproved} target={voteCounterId}>
        <div className='py-3 px-4'>
          <div className='d-flex'>
            <div>
              <UpGrayChevronIcon className='mb-2' />
            </div>
            <strong className='ml-3'>{question.upvotesCount}</strong>
          </div>
          <div className='d-flex'>
            <div>
              <DownGrayChevronIcon />
            </div>
            <strong className='ml-3'>{question.downvotesCount}</strong>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default QuestionVotingSectionDesktop
