import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.golinks_editor')

const NameTakenErrorMessage = ({ className = 'text-small mt-2 ml-4 text-danger' }) => (
  <div className={className}>
    {I18N('name_taken')}
  </div>
)

export default NameTakenErrorMessage
