import React from 'react'

import FormCheck from 'components/form_fields/formCheck'
import AdvancedSettings from 'components/admin/journeys/advancedSettings'
import { i18nPath } from 'utils/i18nHelpers'
import ReplyToSetting from 'components/admin/journeys/replyToSetting'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useIsCleary from 'components/common/hooks/useIsCleary'

const I18N = i18nPath('views.admin.journeys.task_templates')
const I18NStepTemplates = i18nPath('views.admin.journeys.step_templates')

const TaskableFormAdvancedSettingsSection = ({
  workingCopy,
  updateWorkingCopy,
  isStepTemplate = true,
  isDisabled = false,
  stepType,
  journeyBlueprint,
}) => {
  const isCleary = useIsCleary()
  const journeyBlueprintType = journeyBlueprint?.type
  const triggerAfterNumDays = workingCopy.triggerAfterNumDays || 0
  const triggerOnStepCompletion = workingCopy?.triggerMilestone === 'step_completion'
  const currentCompany = useCurrentCompany()
  const { slackEnabled, msTeamEnabled, settings } = currentCompany
  const isSmsEnabled = settings.journeys.smsNotifications.enabled
  const areNotificationChannelsDisabled = isDisabled || workingCopy.useRecipientNotificationPreferences

  return (
    <AdvancedSettings
      className='mt-4'
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
      isStepTemplate={isStepTemplate}
      journeyBlueprintType={journeyBlueprint?.type}
    >
      <label className='text-secondary font-weight-600 mb-3'>{I18NStepTemplates('notification_channel')}</label>
      {isCleary && (
        <FormCheck
          name='use_recipient_notification_preferences'
          label={I18N('advanced_settings.use_recipient_notification_preferences')}
          checked={workingCopy.useRecipientNotificationPreferences}
          onChange={({ target }) => updateWorkingCopy({ useRecipientNotificationPreferences: target.checked })}
          id='use_recipient_notification_preferences'
          className='mb-3'
          disabled={isDisabled}
        />
      )}

      {slackEnabled && (
        <FormCheck
          name='notify_via_slack'
          label={I18N('advanced_settings.notify_via_slack')}
          checked={workingCopy.notifyViaSlack}
          onChange={({ target }) => updateWorkingCopy({ notifyViaSlack: target.checked })}
          id='notify_via_slack'
          className='mb-3'
          disabled={areNotificationChannelsDisabled}
        />
      )}
      {msTeamEnabled && (
        <FormCheck
          name='notify_via_ms_teams'
          label={I18N('advanced_settings.notify_via_ms_teams')}
          checked={workingCopy.notifyViaMsTeams}
          onChange={({ target }) => updateWorkingCopy({ notifyViaMsTeams: target.checked })}
          id='notify_via_ms_teams'
          className='mb-3'
          disabled={areNotificationChannelsDisabled}
        />
      )}
      <FormCheck
        name='notify_via_email'
        label={I18N('advanced_settings.notify_via_email')}
        checked={workingCopy.notifyViaEmail}
        onChange={({ target }) => updateWorkingCopy({ notifyViaEmail: target.checked })}
        id='notify_via_email'
        className='mb-3'
        disabled={areNotificationChannelsDisabled}
      />

      {isSmsEnabled && (
        <FormCheck
          name='notify_via_sms'
          label={I18N('advanced_settings.notify_via_sms')}
          checked={workingCopy.notifyViaSms}
          onChange={({ target }) => updateWorkingCopy({ notifyViaSms: target.checked })}
          id='notify_via_sms'
          className='mb-3'
          disabled={areNotificationChannelsDisabled}
        />
      )}

      <FormCheck
        name='allow_silence_notifications'
        label={I18N('advanced_settings.allow_silence_notifications')}
        checked={workingCopy.allowSilenceNotifications}
        onChange={({ target }) => updateWorkingCopy({ allowSilenceNotifications: target.checked })}
        id='allow_silence_notifications'
        className='mb-3'
        disabled={isDisabled}
      />
      {stepType === 'task' && workingCopy.notifyViaEmail && triggerAfterNumDays <= 0 && (
        <ReplyToSetting workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} isDisabled={isDisabled} />
      )}
      <label className='text-secondary font-weight-600 mt-4 mb-3'>{I18NStepTemplates('error_handling')}</label>
      {isStepTemplate && journeyBlueprintType && !triggerOnStepCompletion && (
        <FormCheck
          name='create_after_trigger_period'
          label={I18N(`advanced_settings.${journeyBlueprintType}.create_after_trigger_period`)}
          checked={workingCopy.triggerImmediatelyIfPastTrigger}
          onChange={({ target }) => updateWorkingCopy({ triggerImmediatelyIfPastTrigger: target.checked })}
          id='create_after_trigger_period'
          className='mb-3'
        />
      )}
      {/* the variable is positive and the label is negative, so the value is negated for this checkbox */}
      <FormCheck
        name='variables_missing'
        label={I18N('advanced_settings.variables_missing')}
        checked={!workingCopy.triggerIfVariablesMissing}
        onChange={({ target }) => updateWorkingCopy({ triggerIfVariablesMissing: !target.checked })}
        id='variables_missing'
        className='mb-3'
        disabled={isDisabled}
      />
    </AdvancedSettings>
  )
}

export default TaskableFormAdvancedSettingsSection
