import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import customStylesSlice from 'redux/slices/customStyles'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useCustomStyles } from 'pages/admin/custom_styles/context'
import StyleSheetEditor from './stylesheetEditor'

const I18N = i18nPath('views.custom_styles')

const CustomCssSection = (): ReactNode => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()

  const {
    workingCopy,
    workingCopy: { cssOverrides },
  } = useCustomStyles()

  const isClearyAdmin = !!currentUser.permissions.clearyAdmin

  const handleChange = (cssOverrides) => {
    const newWorkingCopy = {
      ...workingCopy,
      cssOverrides,
    }

    dispatch(customStylesSlice.actions.updateWorkingCopy(newWorkingCopy))
  }

  if (!isClearyAdmin) return null

  return (
    <div className='row'>
      <div className='col-12'>
        <StyleSheetEditor cssOverrides={cssOverrides} onChange={handleChange} />
      </div>
      <div className='col-12'>
        <span className='text-muted'>{I18N('css_overrides_helper_text')}</span>
      </div>
    </div>
  )
}

export default CustomCssSection
