import React from 'react'

import ArticleByLine from 'components/articles/articleByLine'
import CdnSvg from 'components/common/cdnSvg'
import ClearyInternalSearchResultsTemplate from './clearyInternalSearchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const newspaperIconPath = '/images/newspaperIcon.svg'

export const ArticleResult = ({ result }) => {
  const { article, highlights, displayPath } = result
  const { title, articleType } = { ...article, ...highlights }

  const content = highlights?.content || highlights?.cardContent

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={<CdnSvg src={newspaperIconPath} />}
      pretitle={articleType.name}
      title={title}
      subtitle={<ArticleByLine article={article} className='mt-1' />}
      description={content}
      result={result}
      descriptionExtraMargin
    />
  )
}

const ArticleResults = ({ showLabel = true }) => (
  <ClearyInternalSearchResultsTemplate
    showLabel={showLabel}
    label='articles'
    type='article'
    searchResultToTemplateMap={result => <ArticleResult result={result} />}
  />
)

export default ArticleResults
