import React from 'react'

import { ButtonSecondarySmall } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import { useDispatch } from 'react-redux'
import API from 'services/api'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.buddies_table')

const OptInButton = ({ userId }) => {
  const dispatch = useDispatch()

  const [optIn, { isLoading }] = useApi(API.admin.onboardingBuddies.optIn, {
    onSuccess: () => dispatch(onboardingBuddySlice.actions.removeOnboardingBuddyIds(userId)),
  })

  return (
    <ButtonSecondarySmall
      onClick={() => optIn(userId)}
      showLoadingSpinner={isLoading}
      disabled={isLoading}
    >
      {I18N('opt_in')}
    </ButtonSecondarySmall>
  )
}

export default OptInButton
