import React, { useState } from 'react'
import classNames from 'classnames'
import CharsRemaining from 'components/common/charsRemaining'
import CdnSvg from 'components/common/cdnSvg'

const pencilIconPath = '/images/pencilIcon.svg'

const EditableContent = ({
  canEdit = false,
  displayElement,
  saveContent,
  inputElement,
  valueToEdit,
  className = '',
  showCharCount = false,
  maxLength = 280,
  // pass this flag if you want to control any value instead of text input
  // see client/components/groups/extraInfo.js how to use slack search input
  // with EditableContent
  valueIsObject = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  // null/undefined values for inputs through a warning
  const defaultValue = valueIsObject ? null : ''
  const [currentValue, setCurrentValue] = useState(valueToEdit ?? defaultValue)
  const isEmpty = !currentValue || !valueToEdit

  const onClickDisplayElement = () => {
    if (canEdit) {
      setIsEditing(true)
    }
  }

  const save = () => {
    saveContent(currentValue).then(() => setIsEditing(false))
  }

  const onBlur = () => {
    save()
  }

  const onKeyUp = (e) => {
    if (e.key === 'Escape') {
      setIsEditing(false)
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      save()
    }
  }

  const onChange = (e) => {
    if (valueIsObject) {
      setCurrentValue(e)
      return
    }

    const { value } = e.target

    if (showCharCount && value.length > maxLength) {
      return
    }

    setCurrentValue(value)
  }

  if (!isEditing) {
    return (
      <span
        className={classNames('EditableContent-Display', { canEdit, isEmpty }, className)}
        onClick={onClickDisplayElement}
      >
        {displayElement}
        <CdnSvg src={pencilIconPath} className='pencilIcon ml-1' />
      </span>
    )
  }

  return (
    <>
      <span className={classNames('EditableContent', className)}>
        {React.cloneElement(inputElement, {
          autoFocus: true,
          value: currentValue,
          onBlur,
          onKeyUp,
          onKeyPress,
          onChange,
        })}
        {showCharCount && <CharsRemaining maxLength={maxLength} text={currentValue} />}
      </span>
    </>
  )
}

export default EditableContent
