import React, { useState } from 'react'

const DimensionControl = ({ variable, value, onChange }) => {
  // ES6 array destructuring
  // and regex to bisect string into numbers and letters
  // [, emUnitNumberValue] emUnitNumberValue would be the second group in the regex expression
  const [, emUnitNumberValue] = value.match(/([0-9.]+)?([a-z]+)/)
  const [emUnitValue, setPixelValue] = useState(emUnitNumberValue)
  const min = 0
  const max = 10

  const handleChange = (ev) => {
    const { value } = ev.target

    // prevent user from typing values outside of min and max
    if (parseFloat(value) < min || parseFloat(value) > max) {
      return
    }

    // If we're clearing out the field, we don't want to store just an 'em'
    if (value.length === 0) {
      setPixelValue('')
      onChange('dimensions', variable, '')
      return
    }

    setPixelValue(value)
    onChange('dimensions', variable, `${value}em`)
  }

  return (
    <div className='DimensionControl mt-2 d-flex justify-content-between align-content-start'>
      <label className='label'>{_.startCase(variable)}</label>

      <div className='d-flex align-items-center'>
        <span className='control d-flex text-small'>
          <input
            className='mx-2 form-control'
            type='number'
            name={`dimension-${variable}`}
            min={min}
            max={max}
            step={0.01}
            value={emUnitValue}
            placeholder={variable === 'logoWidth' ? 'auto' : '3em'}
            onChange={ev => handleChange(ev)}
          />
        </span>
      </div>
    </div>
  )
}

export default DimensionControl
