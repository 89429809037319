import React from 'react'

import { ButtonLink } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import JourneysDashboardTable from 'components/admin/journeys/dashboard/journeysDashboardTable'
import useCurrentUser from 'components/common/hooks/useCurrentUser'


const I18N = i18nPath('views.admin.journeys.dashboards.journeys_dashboard')

const JourneysDashboardPage = () => {
  const { permissions } = useCurrentUser()
  const showAddNewHireButton = permissions.userManager

  return (
    <div className='JourneysDashboardPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title')}</h3>
        {showAddNewHireButton && <ButtonLink to='/admin/users/new'>{I18N('create_new_hire')}</ButtonLink>}
      </header>
      <main className='AdminContent d-flex flex-column justify-content-center'>
        <JourneysDashboardTable />
      </main>
    </div>
  )
}

export default JourneysDashboardPage
