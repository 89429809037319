import React from 'react'
import { useParams } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import BackButton from 'components/common/backButton'
import Answers from 'components/analytics/ai_answers/aiAnswers'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.platform_analytics.ai_answers.answers_by_topic')

const AnswersByTopicPage = () => {
  const { topicId } = useParams()
  const { topicTitle } = useQueryParams()

  return (
    <main className='AiAnswersTab mb-5'>
      <div className='grid-span-12 my-4'>
        <BackButton
          url='/platform_analytics/ai_answers'
        />
      </div>

      <Answers
        params={{
          topicId,
        }}
        showTabs={false}
        title={topicTitle ? I18N('title', { topicTitle }) : I18N('title_no_topic_title')}
      />
    </main>
  )
}

export default AnswersByTopicPage
