import { HocuspocusProvider } from '@hocuspocus/provider'
import {
  useEffect, useState
} from 'react'
import { useDispatch } from 'react-redux'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'
import API from 'services/api'

interface UseHocuspocusProviderProps {
  enabled: boolean
  recordId: string | number
  recordType: string
}

const useHocuspocusProvider = ({ enabled, recordId, recordType }: UseHocuspocusProviderProps) => {
  const [hocuspocusProvider, setHocuspocusProvider] = useState<HocuspocusProvider | undefined>()
  const [synced, setSynced] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (enabled && !hocuspocusProvider) {
      const rebuildHocuspocusProvider = async () => {
        let isFirstTimeLoading = true

        const {
          data: {
            serverUrl: url,
            token: firstToken,
            documentName: name,
          },
        } = await API.tiptapEditor.fetchCollaborationToken({
          recordId,
          recordType,
        })

        const provider = new HocuspocusProvider({
          url,
          name,
          token: async () => {
            if (isFirstTimeLoading) {
              isFirstTimeLoading = false
              return firstToken
            }

            const {
              data: { token },
            } = await API.tiptapEditor.fetchCollaborationToken({
              recordId,
              recordType,
            })

            return token
          },
          onSynced: ({ state }) => {
            setSynced(state)
          },
        })

        setHocuspocusProvider(provider)
        dispatch(collaborativeEditorSlice.actions.setProvider(provider))
      }

      rebuildHocuspocusProvider()
    }
  }, [enabled])

  useEffect(() => () => {
    if (hocuspocusProvider) {
      hocuspocusProvider.destroy()
      dispatch(collaborativeEditorSlice.actions.setProvider(null))
    }
  }, [hocuspocusProvider])

  return {
    provider: hocuspocusProvider,
    synced,
  }
}

export default useHocuspocusProvider
