import {
  LINKS_WIDGET_TYPE,
  PREBOARDING_CAROUSEL_CARDS_WIDGET_TYPE,
  PREBOARDING_FEED_WIDGET_TYPE,
  PREBOARDING_LINKS_WIDGET_TYPE
} from 'utils/layout/widgetsMap'

const WIDGET_ATTRS = [
  'title',
  'type',
  'settings',
  'x',
  'y',
  'w',
  'h',
  'autoResizable',
  '_destroy',
]

const LINK_ATTRS = [
  'name',
  'url',
  'recordId',
  'recordType',
  'orderPosition',
]

const WIDGET_RECORD_ASSOCIATION_ATTRS = [
  'recordId',
  'recordType',
]

const buildLayoutPayload = layout => ({
  id: layout.id,
  widgetsAttributes: layout.widgets.map(buildWidgetPayload),
})

export const buildWidgetPayload = (widget) => {
  const widgetAttributes: any = pickRemovingNewId(widget, WIDGET_ATTRS)

  if ([LINKS_WIDGET_TYPE, PREBOARDING_LINKS_WIDGET_TYPE].includes(widget.type) && widget.commonLinks) {
    widgetAttributes.linksAttributes = [
      ...widget.commonLinks.map(link => pickRemovingNewId(link, LINK_ATTRS)),
      ...widget.deletedLinkIds ? widget.deletedLinkIds.map(id => ({ id, _destroy: true })) : [],
    ]
  }

  if (
    [PREBOARDING_CAROUSEL_CARDS_WIDGET_TYPE, PREBOARDING_FEED_WIDGET_TYPE].includes(widget.type)
    && widget.widgetRecordAssociations
  ) {
    widgetAttributes.widgetRecordAssociationsAttributes = [
      ...widget.widgetRecordAssociations.map(wra => pickRemovingNewId(wra, WIDGET_RECORD_ASSOCIATION_ATTRS)),
      ...widget.deletedAssociationIds ? widget.deletedAssociationIds.map(id => ({ id, _destroy: true })) : [],
    ]
  }

  return widgetAttributes
}

const pickRemovingNewId = (obj, attrs) => ({
  ..._.pick(obj, attrs),
  id: obj.id?.startsWith('new-') ? undefined : obj.id,
})


export default buildLayoutPayload
