import React from 'react'
import { useSelector } from 'react-redux'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { i18nPath } from 'utils/i18nHelpers'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import RoundedAvatars from 'components/common/roundedAvatars'
import UserCelebrationRow from 'components/settings/common/userCelebrationRow'
import settingSlice from 'redux/slices/settings'


const I18N = i18nPath('views.settings.people_section')
const IDS_WITH_NEW_EMPLOYEES_SETTING = ['followed_org_section_user', 'coworker', 'direct']

const UserCelebrationsSettings = ({
  id,
  users,
  userIds,
  userSelector = null,
}) => {
  const { settings, name } = useCurrentCompany()

  const externalAlertSetting = useSelector(settingSlice.selectors.getSetting('external_alert_type'))
  const externalAlertName = () => {
    if (externalAlertSetting?.value?.email && externalAlertSetting?.value?.slack) {
      return I18N('email_and_slack')
    }

    if (externalAlertSetting?.value?.slack) {
      return I18N('slack')
    }

    return I18N('email')
  }

  const userSettings = [
    settings.celebrations?.birthdays?.enabled && { id: 'birthdays', name: `celebrations.${id}_birthday` },
    settings.celebrations?.workAnniversaries?.enabled && { id: 'work_anniversaries', name: `celebrations.${id}_work_anniversary` },
    settings.shoutouts?.enabled && { id: 'shoutouts', name: `shoutouts.received_by_${id}` },
    settings.badges?.enabled && { id: 'badges', name: `badges.received_by_${id}` },
    settings.celebrations?.manualCelebrations?.enabled && { id: 'other_celebrations', name: `celebration.other_celebrations_${id}` },
    settings.celebrations?.newEmployees?.enabled && IDS_WITH_NEW_EMPLOYEES_SETTING.includes(id) && { id: 'new_employee', name: `celebrations.${id}_new_employee_welcome` },
  ].filter(Boolean)

  return (
    <div className='UserCelebrationsSettings d-md-flex'>
      <div className='mr-md-4 mb-3 mb-md-0'>
        <div className='UserCelebrationsSettings-title mb-3'>{I18N(id, { companyName: name })}</div>
        {userSelector}
        {users && <RoundedAvatars users={users} avatarSize='30px' shownAvatars={10} />}
        {userIds && <RoundedAvatarsWithAsyncUserFetching userIds={userIds} avatarSize='30px' shownAvatars={10} />}
      </div>
      <table>
        <thead>
          <tr>
            <th className='font-weight-400 text-secondary'>{I18N('get_notified_on')}</th>
            <th className='font-weight-400 text-secondary text-center'>{externalAlertName()}</th>
            <th className='font-weight-400 text-secondary text-center'>{I18N('feed')}</th>
          </tr>
        </thead>
        <tbody>
          {userSettings.map(setting => (
            <UserCelebrationRow id={setting.id} key={setting.id} name={setting.name} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default UserCelebrationsSettings
