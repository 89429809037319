import React, { useEffect, useState } from 'react'

import LineGraph from 'components/analytics/common/lineGraph'
import { dateLabels } from 'components/analytics/helpers/dateLabelFormat'
import PillTabSelector from 'components/common/pillTabSelector'
import { useDispatch, useSelector } from 'react-redux'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import TargetingRulesType from 'types/audience/targetingRules'
import { i18nPath } from 'utils/i18nHelpers'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'
import API from 'services/api'

const UNIQUE = 'unique'
const TOTAL = 'total'

const I18N = i18nPath('views.platform_analytics.pages.page_usage')

const DataStat = ({ value, label }) => (
  <>
    <h3>{value}</h3>
    <p className='text-secondary font-weight-400 text-small'>{label}</p>
  </>
)

interface PageUsageProps {
  pageId: string
  groupBy: string
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const PageUsage = ({
  pageId,
  groupBy,
  periodStart,
  periodEnd,
  targetingRules,
}: PageUsageProps) => {
  const {
    audienceCount,
    audienceReach,
    pageViews,
    pageViewsCount,
    uniquePageViews,
    uniquePageViewsCount,
  } = useSelector(pageAnalyticsSlice.selectors.getPageUsage(pageId))

  const dispatch = useDispatch()

  const { pageUsage: { isLoading } } = useSelector(pageAnalyticsSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchPageUsage(pageId, {
      periodStart, periodEnd, groupBy, targetingRules,
    }))
  }, [periodStart, periodEnd, groupBy, targetingRules])


  const [selectedTab, setSelectedTab] = useState(UNIQUE)

  const tabs = [
    { text: I18N('unique'), onClick: () => setSelectedTab(UNIQUE) },
    { text: I18N('total'), onClick: () => setSelectedTab(TOTAL) },
  ]

  const percentageAudienceReach = Math.round(audienceReach * 100) + '%'

  const uniqueDataValues = uniquePageViews.map(({ value }) => value)
  const totalDataValues = pageViews.map(({ value }) => value)

  const labels = dateLabels(selectedTab === UNIQUE ? uniquePageViews : pageViews, groupBy)

  const onExport = (email: string) => {
    const exportXlsxParams = {
      periodStart,
      periodEnd,
      targetingRules,
      groupBy,
      eventNames: 'page:view',
      appModules: 'page',
      trackableType: 'Page',
      trackableId: pageId,
      email,
      includeTotalInteractions: true,
      title: I18N('xlsx_title'),
    }

    return API.admin.analytics.engagement.exportXlsx(exportXlsxParams)
  }


  return (
    <div className='grid-span-12 analytics-card'>
      <header className='pb-3'>
        <h3 className='mb-3'>{I18N('title')}</h3>

        <div className='d-flex flex-row justify-content-between'>
          <PillTabSelector
            tabs={tabs}
            pillClasses='text-normal'
          />

          <ExportXlsxButton
            className='ml-1'
            onSubmit={onExport}
          />
        </div>
      </header>

      <div className='d-flex flex-row align-items-start w-100'>
        {!isLoading && (
          <div className='d-flex flex-column align-items-start w-15'>
            {selectedTab === UNIQUE && (
              <>
                <DataStat value={percentageAudienceReach} label={I18N('audience_reach')} />
                <DataStat value={uniquePageViewsCount} label={I18N('unique_viewers')} />
                <DataStat value={audienceCount} label={I18N('audience_size')} />
              </>
            )}
            {selectedTab === TOTAL && (
              <>
                <DataStat value={pageViewsCount} label={I18N('total_views')} />
              </>
            )}
          </div>
        )}

        <div className='d-flex flex-column flex-grow-1'>
          {!isLoading && (
            <h5 className='mb-2'>{selectedTab === UNIQUE ? I18N('unique_viewers') : I18N('total_views')}</h5>
          )}
          <LineGraph
            isLoading={isLoading}
            labels={labels}
            dataValues={selectedTab === UNIQUE ? uniqueDataValues : totalDataValues}
            tooltipLabel={selectedTab === UNIQUE ? I18N('unique_viewers').toLowerCase() : I18N('total_views').toLowerCase()}
          />
        </div>
      </div>
    </div>
  )
}

export default PageUsage
