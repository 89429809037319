import React, { useEffect, useState } from 'react'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

import { useDispatch, useSelector } from 'react-redux'
import pageSlice from 'redux/slices/pages'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import SmartTable from 'components/common/tables/smartTable'
import { Link } from 'react-router-dom'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import TrashIcon from 'components/icons/trashIcon'
import PageDeleteModal from 'components/pages/pageDeleteModal'
import CdnSvg from 'components/common/cdnSvg'

const syncIconPath = '/images/syncIcon.svg'

const I18N = i18nPath('views.pages')

const UnarchivePageButton = ({
  page,
}) => {
  const dispatch = useDispatch()
  const [isUnarchiving, setIsUnarchiving] = useState(false)

  const onUnarchive = () => {
    const onSuccess = () => {
      setIsUnarchiving(false)
      return null
    }

    setIsUnarchiving(true)
    dispatch(pageSlice.asyncActions.unarchive(page, onSuccess))
  }

  return (
    <div onClick={onUnarchive} className='UnarchivePageButton pointer'>
      {isUnarchiving ? (
        <CirclesLoadingIndicator height={30} width={30} />
      ) : (
        <IconButtonWithPopover
          id={`restore-page-${page.id}`}
          icon={<CdnSvg src={syncIconPath} />}
          text={I18N('restore_page')}
        />
      )}
    </div>
  )
}

const ArchivedPagesTable = ({
  onPaginationClick,
}) => {
  const {
    isLoadingArchivedPages: isLoading, archivedPagesQueryParams: queryParams,
  } = useSelector(pageSlice.selectors.getPagesMetadata())

  const archivedPages = useSelector(pageSlice.selectors.getArchivedPages())
  const [pageToDelete, setPageToDelete] = useState<any>(null)

  const {
    page, totalPages, perPage, total,
  } = queryParams

  const columns = [
    {
      header: I18N('title'),
      style: { minWidth: '50%' },
      accessor: d => (
        <Link
          to={d.displayPath}
          className='truncate-text-at-2-lines text-primary-link'
        >
          {d.title}
        </Link>
      ),
    },
    {
      header: I18N('archived_at'),
      accessor: d => i18nMoment(d.archivedAt).format('lll'),
    },
    {
      header: I18N('restore'),
      accessor: d => <UnarchivePageButton page={d} />,
    },
    {
      header: I18N('delete'),
      accessor: d => (
        <div onClick={() => setPageToDelete(d)} className='DeletePageButton pointer'>
          <IconButtonWithPopover
            id={`delete-page-${d.id}`}
            icon={<TrashIcon className='TrashIcon' />}
            text={I18N('delete_page')}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    // check if there's no pages left on the current page and there's more pages
    if (archivedPages.length === 0 && totalPages >= page) {
      onPaginationClick({ page: Math.max(page - 1, 1) })
    }
  }, [archivedPages.length])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <>
      <SmartTable
        data={archivedPages}
        showPagination={true}
        columns={columns}
        page={page}
        pages={totalPages}
        totalCount={total}
        perPage={perPage}
        onPaginationClick={onPaginationClick}
        className='white-bg-table'
      />
      {!!pageToDelete && (
        <PageDeleteModal
          page={pageToDelete}
          onClose={() => setPageToDelete(null)}
          visible={!!pageToDelete}
        />
      )}
    </>
  )
}

export default ArchivedPagesTable
