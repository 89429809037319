import useApi from 'components/common/hooks/useApi'
import { AsyncSelect } from 'components/common/react_select'
import React from 'react'
import API from 'services/api'

const ArticleSelect = ({ article, onChange }) => {
  const [fetchArticles] = useApi(API.admin.articles.fetchAll)
  const getOptions = async (inputValue, callback) => {
    const results = await fetchArticles({ q: inputValue, includeTargetingRules: true })

    callback(results)
  }

  return (
    <div className='ArticleSelect row'>
      <div className='form-group col-md-12'>
        <AsyncSelect
          getOptions={getOptions}
          filterOption={() => true}
          getOptionLabel={option => option.title}
          getOptionValue={option => option.id}
          value={article}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default ArticleSelect
