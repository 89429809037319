import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, Redirect } from 'react-router-dom'

import Error404 from 'components/errors/404'

import { i18nPath } from 'utils/i18nHelpers'
import newHireSlice from 'redux/slices/journeys/newHires'
import journeySlice from 'redux/slices/journeys'
import stepSlice from 'redux/slices/journeys/steps'
import communicationSlice from 'redux/slices/journeys/communications'
import taskSlice from 'redux/slices/journeys/tasks'
import Picture from 'components/common/images/picture'
import { createTenureString } from 'components/profiles/tenure'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'

import EditableContentTrigger from 'components/common/editable_content/editableContentTrigger'
import NewHireJourneysProgress from 'components/admin/journeys/new_hire/newHireJourneysProgress'

import OnboardingPhaseText from 'components/admin/journeys/onboardingPhaseText'
import ManageEmployeeJourneysModal from 'components/admin/journeys/modals/manageEmployeeJourneysModal'
import RemoveFromJourneyConfirmationModal from 'components/admin/journeys/modals/journeyBlueprintSettingsModal/removeFromJourneyConfirmationModal'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

import { ButtonLink } from 'components/common/buttons'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import EmployeeStepsTable from 'components/admin/journeys/employeesOnJourneys/employeeStepsTable'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const pencilIconPath = '/images/pencilIcon.svg'
const personIconPath = '/images/personIcon.svg'

const DISABLED_STATUS = 'disabled'
const INELIGIBLE_STATUS = 'ineligible'

const I18N = i18nPath('views.admin.journeys.new_hire_detail')

const OnboardingBuddyOrAssignLink = ({ onboardingBuddy, onAssignBuddyClick }) => (
  <p className='mt-3 mb-0'>
    <strong className='font-weight-600'>{I18N('onboarding_buddy')}:&nbsp;</strong>
    {onboardingBuddy ? (
      <>
        <EmployeeThumbnailPhoto employee={onboardingBuddy} size='24px' className='mr-2' />
        <a href={`/profile/${onboardingBuddy.username}`} className='font-weight-500 text-primary-link'>{onboardingBuddy.preferredFullName}</a>
      </>
    ) : (
      <span onClick={onAssignBuddyClick} className='font-weight-500 text-primary-link pointer'>{I18N('assign_onboarding_buddy')}</span>
    )}
  </p>
)

const EmployeeJourneysPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { userId } = useParams()
  const currentCompany = useCurrentCompany()
  const { permissions } = useCurrentUser()
  const settings = currentCompany?.settings
  const isTenureEnabled = settings?.profile?.fields?.timeSinceIStarted
  const { isLoading, isNotFound } = useSelector(newHireSlice.selectors.getMetaData())

  const user = useSelector(newHireSlice.selectors.getNewHire(userId))

  const primaryPicture = _.find(user.photos, { primary: true })
  const {
    username,
    defaultPhotoUrl,
    preferredFullName,
    onboardingPhase,
    parent: manager,
    completedOnboardingBuddy,
    onboardingBuddy,
    onboardingBuddyStatusAsANewHire,
    startDate,
  } = user

  // eslint-disable-next-line max-len
  const showOnboardingBuddyOrAssignLink = settings?.journeys?.onboardingBuddies?.enabled && ![DISABLED_STATUS, INELIGIBLE_STATUS].includes(onboardingBuddyStatusAsANewHire) && permissions.journeyManager
  const showEditUserButton = permissions.userManager

  const journeys = useSelector(journeySlice.selectors.getJourneys())

  const { isLoading: isLoadingJourneys } = useSelector(journeySlice.selectors.getMetaData())

  const [isManageJourneysModalOpen, setIsManageJourneysModalOpen] = useState(false)
  const [isRemoveJourneyModalOpen, setIsRemoveJourneyModalOpen] = useState(false)
  const [journeyToRemove, setJourneyToRemove] = useState(null)

  useEffect(() => {
    dispatch(journeySlice.actions.clearJourneyIds())
    dispatch(stepSlice.actions.clearStepIds())
    dispatch(communicationSlice.actions.clearCommunicationIds())
    dispatch(taskSlice.actions.clearTaskIds())
    dispatch(newHireSlice.asyncActions.admin.fetchNewHire(userId))

    const params = {
      userId,
    }

    dispatch(journeySlice.asyncActions.admin.fetchAll(params))
  }, [userId])

  const handleAssignABuddyClick = () => {
    const onSuccess = () => {
      history.push(`/admin/onboarding_buddies?new_hire_id=${userId}`)
    }

    dispatch(newHireSlice.asyncActions.admin.updateOnboardingBuddyAvailability(
      userId,
      { onboardingBuddyAvailability: { needsOnboardingBuddy: true } },
      onSuccess
    ))
  }

  const onRemoveJourney = (journey) => {
    setJourneyToRemove(journey)
    setIsManageJourneysModalOpen(false)
    setIsRemoveJourneyModalOpen(true)
  }

  const onCancelRemoveJourney = () => {
    setJourneyToRemove(null)
    setIsManageJourneysModalOpen(true)
    setIsRemoveJourneyModalOpen(false)
  }

  if (isLoading || _.isEmpty(user)) {
    return <CirclesLoadingIndicator className='mt-4' />
  }

  if (isNotFound) {
    return <Error404 />
  }

  return (
    <div className='EmployeeJourneysPage'>
      <header className='d-flex p-4 w-100 AdminHeader'>
        <Picture
          owner={user}
          picture={primaryPicture}
          defaultPhotoUrl={defaultPhotoUrl}
          showRemoveIcon={false}
          className='mr-5 mb-2 PrimaryPhoto'
        />
        <div className='d-flex flex-column w-100'>
          <div className='d-flex justify-content-between mb-4'>
            <h2>{preferredFullName}</h2>
            <div className='d-flex buttons-container'>
              <ButtonLink className='mr-2 mb-1' variant='secondary' to={`/profile/${username}`}>
                <CdnSvg src={personIconPath} className='personIcon ml-1' />
                <span>{I18N('view_profile')}</span>
              </ButtonLink>
              {showEditUserButton && (
                <ButtonLink className='mr-2 mb-1' variant='secondary' to={`/admin/users/${userId}`}>
                  <CdnSvg src={pencilIconPath} className='pencilIcon ml-1' />
                  <span>{I18N('edit_user')}</span>
                </ButtonLink>
              )}
            </div>
          </div>
          <div className='d-flex justify-content-center info-and-journeys-container'>
            <div className='user-information-container mr-2'>
              <h6 className='d-inline mr-1'>{user.title}</h6>
              {user?.city && (
                <>
                  <span className='text-secondary'>{I18N('in')}</span>
                  <h6 className='d-inline ml-1'>{user.city}</h6>
                </>
              )}
              {manager && (
                <div className='d-flex align-items-center mt-3'>
                  <strong className='font-weight-600 mr-1'>{I18N('manager')}:</strong>
                  <EmployeeThumbnailPhoto employee={manager} size='24px' className='mr-2' />
                  <a href={`/profile/${manager?.username}`} className='font-weight-500 text-primary-link'>{manager?.preferredFullName}</a>
                </div>
              )}
              {showOnboardingBuddyOrAssignLink && (
                <OnboardingBuddyOrAssignLink
                  onboardingBuddy={onboardingBuddy || completedOnboardingBuddy}
                  onAssignBuddyClick={handleAssignABuddyClick}
                />
              )}
              {isTenureEnabled && startDate && (
                <p className='mt-3 mb-0'><strong className='font-weight-600'>{I18N('tenure')}:</strong> {createTenureString(user)}</p>
              )}
              {startDate && <p className='mt-3 mb-0'><strong className='font-weight-600'>{I18N('phase')}:</strong> <OnboardingPhaseText onboardingPhase={onboardingPhase} /></p>}
            </div>
            <div className='journeys-editable-container'>
              <EditableContentTrigger
                canEdit={permissions.journeyCreator}
                className='w-100 d-flex align-items-center pt-0'
                onClick={permissions.journeyCreator ? () => setIsManageJourneysModalOpen(true) : () => {}}
                displayElement={
                  <NewHireJourneysProgress journeys={journeys} isLoading={isLoadingJourneys} />
                }
              />
            </div>
          </div>
        </div>
      </header>
      <main className='AdminContent d-flex'>
        <EmployeeStepsTable
          journeys={journeys}
          onClickAssignJourney={() => setIsManageJourneysModalOpen(true)}
        />
      </main>
      <ManageEmployeeJourneysModal
        user={user}
        visible={isManageJourneysModalOpen}
        onRemoveJourney={onRemoveJourney}
        toggle={() => setIsManageJourneysModalOpen(false)}
      />
      <RemoveFromJourneyConfirmationModal
        visible={isRemoveJourneyModalOpen}
        toggle={onCancelRemoveJourney}
        journeyToRemove={journeyToRemove}
      />
    </div>
  )
}

export default EmployeeJourneysPage
