import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Card from 'components/common/card'
import RemoveXIcon from 'components/icons/removeXIcon'
import TrashIcon from 'components/icons/trashIcon'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import EmployeeSearch from 'components/form_fields/employeeSearch'

import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import qnaEventSlice from 'redux/slices/qnaEvents'

const I18N = i18nPath('views.qna.events')

const SpeakerEmployeeMiniCard = (props) => {
  const { onDelete, speaker, canDelete } = props
  const [showTrashIcon, setShowTrashIcon] = useState(false)
  const MiniCard = canDelete ? EmployeeMiniCard : EmployeeMiniCardLink

  return (
    <MiniCard
      employee={speaker}
      onMouseEnter={() => setShowTrashIcon(true)}
      onMouseLeave={() => setShowTrashIcon(false)}
      additionalContent={canDelete && (
        <span
          className='pointer position-absolute top-0 right-0'
          onClick={onDelete}
        >
          {showTrashIcon && <TrashIcon fill='var(--text-color-primary)' />}
        </span>
      )}
    />
  )
}

const SpeakerWidget = ({
  event,
  enableEditing,
}) => {
  const dispatch = useDispatch()
  const { speakers } = event

  const [showEmployeeSearch, setShowEmployeeSearch] = useState(false)
  const [speakerToAdd, setSpeakerToAdd] = useState(null)

  const disableSpeakers = () => {
    const eventData = { ...event, settings: { ...event.settings, speakers: { enabled: false } } }
    dispatch(qnaEventSlice.asyncActions.admin.update(eventData))
  }

  const addSpeaker = (speaker) => {
    if (speaker.id) {
      // temporarily put speaker we're adding into local state
      // so UX appears snappy to user and doesn't wait for response
      setSpeakerToAdd(speaker)
      dispatch(qnaEventSlice.asyncActions.admin.speakers.create(event.id, speaker.id))
    }
    setShowEmployeeSearch(false)
  }

  const removeSpeaker = (speaker) => {
    dispatch(qnaEventSlice.asyncActions.admin.speakers.destroy(event.id, speaker.id))
  }

  // if the speakers props get refreshed (because we have new data from the server),
  // remove the temporary "speakerToAdd"
  useEffect(() => {
    setSpeakerToAdd(null)
  }, [speakers])

  let speakersToShow = speakers
  if (speakerToAdd) {
    speakersToShow = _.uniqBy([...speakers, speakerToAdd], 'id')
  }

  return (
    <Card className='mb-3'>
      <div className='d-flex mb-2 justify-content-between'>
        <span className='font-weight-500'>{I18N('speakers')}</span>
        {enableEditing && (
          <span className='mr-2 pointer' onClick={() => disableSpeakers()}>
            <RemoveXIcon />
          </span>
        )}
      </div>
      <div>
        {speakers.length === 0 && !enableEditing && (
          <div className='my-4 text-center'>
            <span className='text-muted'>{I18N('speakers_to_be_announced')}</span>
          </div>
        )}
        {speakersToShow.map((speaker, index) => (
          <SpeakerEmployeeMiniCard
            speaker={speaker}
            key={speaker.id}
            canDelete={enableEditing}
            onDelete={() => removeSpeaker(speaker)}
          />
        ))}
      </div>
      {enableEditing && !showEmployeeSearch && (
        <div className='mt-3'>
          <span onClick={() => setShowEmployeeSearch(true)} className='hover-text-color-primary pointer text-secondary'>
            + {I18N('add_a_speaker')}
          </span>
        </div>
      )}
      {enableEditing && showEmployeeSearch && (
        <div className='mt-3'>
          <EmployeeSearch onChange={addSpeaker} autoFocus selectedEmployee={null} />
        </div>
      )}
    </Card>
  )
}

export default SpeakerWidget
