import React from 'react'

import classNames from 'classnames'

import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'

const CheckboxToggleButtonGroup = ({
  className,
  optionsClassName,
  currentValue,
  disabled,
  helpText,
  label,
  name,
  onChange,
  options,
  type = 'checkbox',
}) => (
  <div className={classNames('CheckboxToggleButtonGroup', className, { isDisabled: disabled, isRadio: type === 'radio', isCheckbox: type === 'checkbox' })}>
    {label && <label className='d-block'>{label}</label>}
    <div className={type === 'checkbox' ? 'w-100 w-lg-25' : ''}>
      <ToggleButtonGroup name={name} type={type} value={currentValue} onChange={onChange}>
        {options.map(option => (
          <ToggleButton key={option.label} variant='toggle' type='checkbox' value={option.value} className={optionsClassName}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
    {helpText && <span className='text-small text-secondary mt-1'>{helpText}</span>}
  </div>
)

export default CheckboxToggleButtonGroup
