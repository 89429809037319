import ClearyCard from 'components/common/card'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_assistant.dashboard.left_sidebar.book_review')

const BookReview = () => (
  <ClearyCard
    className='BookReview'
    cardBodyClassName='d-flex flex-column gap-5'
  >
    <div className='d-flex flex-column gap-2'>
      <h1>{I18N('title')}</h1>
      <p className='text-primary'>{I18N('description')}</p>
    </div>

    <div className='d-flex flex-column gap-3'>
      <div className='d-flex gap-2'>
        <div>
          <img src='/images/thomas.png' alt='Thomas' />
        </div>
        <div className='d-flex flex-column align-items-start'>
          <h3>Thomas Kunjappu</h3>
          <p className='text-secondary text-small'>{I18N('ceo')}</p>
        </div>
      </div>

      <div>
        <a
          href='https://landing.gocleary.com/meetings/thomask-cleary/cleary-ai-people-ops-pilot-intro-'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-primary'
        >
          {I18N('cta')}
        </a>
      </div>
    </div>
  </ClearyCard>
)

export default BookReview
