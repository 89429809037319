import React from 'react'
import PageArchiveContent from 'components/pages/pageArchiveContent'

const ArchivedPagesPage = () => (
  <div className='h-100 d-flex flex-column'>
    <PageArchiveContent />
  </div>
)

export default ArchivedPagesPage
