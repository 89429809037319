import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import userSlice from 'redux/slices/users'
import { Button, ButtonSmall } from 'components/common/buttons'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.users')

const SoftLaunchAccessSection = ({
  user = null,
  initialIsOpen = false,
}) => {
  const dispatch = useDispatch()

  const { appName } = useCurrentCompany()

  const [isSaving, setIsSaving] = useState(false)
  const accessRequestedAt = user.softLaunchAccessRequestedAt ? i18nMoment(user.softLaunchAccessRequestedAt).format('LLL') : ''
  const accessGrantedAt = user.softLaunchAccessGrantedAt ? i18nMoment(user.softLaunchAccessGrantedAt).format('LLL') : ''

  const grantSoftLaunchAccess = () => {
    setIsSaving(true)
    dispatch(userSlice.asyncActions.admin.grantSoftLaunchAccess(user, () => setIsSaving(false)))
  }

  const revokeSoftLaunchAccess = () => {
    setIsSaving(true)
    dispatch(userSlice.asyncActions.admin.revokeSoftLaunchAccess(user, () => setIsSaving(false)))
  }

  const GrantButton = () => (
    <Button
      className='grant-button'
      onClick={() => grantSoftLaunchAccess(user)}
      showLoadingSpinner={isSaving}
    >
      {I18N('grant')}
    </Button>
  )

  const RevokeButton = () => (
    <ButtonSmall
      className='revoke-button btn-secondary-danger-sm'
      variant=''
      onClick={() => revokeSoftLaunchAccess(user)}
      showLoadingSpinner={isSaving}
    >
      {I18N('revoke')}
    </ButtonSmall>
  )

  return (
    <CollapsibleCard
      title={I18N('sections.manage_soft_launch_access')}
      initialIsOpen={initialIsOpen}
      className='mt-3'
    >
      <p className='text-secondary'>{I18N('sections.manage_soft_launch_access_note', { appName })}</p>
      <div className='SoftLaunchAccessSection mt-3'>
        <div className='form-group'>
          <label>{I18N('soft_launch_access_requested_at')}</label>
          <input
            disabled
            value={accessRequestedAt}
          />
        </div>
        <div className='form-group'>
          <label>{I18N('soft_launch_access_granted_at')}</label>
          <input
            disabled
            value={accessGrantedAt}
          />
        </div>
        {user.softLaunchAccessGrantedAt ? <RevokeButton /> : <GrantButton />}
      </div>
    </CollapsibleCard>
  )
}

export default SoftLaunchAccessSection
