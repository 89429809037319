
const buildNestedAttributesPayload = (associations, previousAssociations = [], buildAssociationAttributes, uniqBy = 'id') => {
  if (!associations) {
    return []
  }

  const updatedAssociations = associations.map((association) => {
    const previousAssociation = previousAssociations?.find(pa => pa[uniqBy] === association[uniqBy])

    return buildAssociationAttributes({ id: previousAssociation?.id, ...association }, previousAssociation)
  })

  const deletedAssociations = _.differenceBy(previousAssociations || [], updatedAssociations, 'id')

  const deletedAssociationsAttributes = deletedAssociations.map(da => ({ id: da.id, _destroy: true }))

  return [
    ...updatedAssociations,
    ...deletedAssociationsAttributes,
  ]
}

export default buildNestedAttributesPayload
