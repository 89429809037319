import React, { useState, useEffect } from 'react'

import { Button } from 'components/common/buttons'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import companyConfigurationSlice from 'redux/slices/companyConfigurations'
import { useDispatch, useSelector } from 'react-redux'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CheckBox from 'components/common/buttons/checkBox'

const I18N = i18nPath('views.admin.other_settings.user_privacy_tab')

const UserPrivacyTab = () => {
  const dispatch = useDispatch()

  const { isSaving, isLoading } = useSelector(companyConfigurationSlice.selectors.getMetaData())
  const defaultUserPrivacy = useSelector(companyConfigurationSlice.selectors.getCompanyConfiguration('default_user_privacy'))

  const [workingCopy, setWorkingCopy] = useState({})

  const handleSaveChanges = () => {
    dispatch(companyConfigurationSlice.asyncActions.admin.update(
      'default_user_privacy',
      workingCopy,
      { onSuccess: () => window.location.reload() }
    ))
  }

  useEffect(() => {
    dispatch(companyConfigurationSlice.asyncActions.admin.fetch('default_user_privacy'))
  }, [])

  useEffect(() => {
    if (defaultUserPrivacy?.value) {
      setWorkingCopy(defaultUserPrivacy.value)
    }
  }, [JSON.stringify(defaultUserPrivacy?.value)])

  const connect = (fieldName: string) => ({
    onChange: (value) => {
      setWorkingCopy(oldValues => ({
        ...oldValues,
        [fieldName]: value,
      }))
    },
    checked: workingCopy[fieldName],
  })

  if (isLoading) {
    return <section className='UserPrivacyTab'><CirclesLoadingIndicator /></section>
  }

  return (
    <section className='UserPrivacyTab'>
      <div className='white-card p-3'>
        <div className='align-items-center mb-2'>
          <h6>{I18N('title')}</h6>
          <p>{I18N('description')}</p>
          <p>{I18N('sub_title')}</p>
        </div>

        <CheckBox
          {...connect('phoneMobile')}
          id='phone_mobile'
          label={I18N('phone_mobile')}
        />

        <CheckBox
          {...connect('city')}
          id='city'
          label={I18N('city')}
        />
      </div>

      <div className='d-flex justify-content-end mt-5'>
        <Button
          onClick={handleSaveChanges}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('save_changes')}
        </Button>
      </div>
    </section>
  )
}

export default UserPrivacyTab
