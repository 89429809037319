import entitySlice from 'redux/slices/entities'
import build from 'redux-object'
import API from 'services/api'
import appSignal from 'services/appSignal'
import { i18nPath } from 'utils/i18nHelpers'
import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { getResponseOrThrow } from 'utils/errorHandling'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { ReduxState } from 'redux/redux'
import { showToastMessage } from 'redux/slices/toasts'
import normalizeTargetingRules, { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import PageWorkspaceType from 'types/pageWorkspace'

const I18N = i18nPath('views.pages')

export const buildWorkspacePayload = (workspace) => {
  const payload = _.pick<PageWorkspaceType>(workspace, [
    'id',
    'title',
    'restrictAccess',
    'emojiCode',
    'ownerIds',
    'orderPosition',
  ])

  if (workspace?.viewersTargetingRuleSet && workspace?.viewersTargetingRuleSet?.targetingRules) {
    payload.viewersTargetingRuleSetAttributes = {
      id: workspace?.viewersTargetingRuleSet?.id,
      targetingRules: normalizeTargetingRules(workspace?.viewersTargetingRuleSet?.targetingRules),
    }
  }

  if (workspace?.pageEditorsTargetingRuleSet && workspace?.pageEditorsTargetingRuleSet?.targetingRules) {
    payload.pageEditorsTargetingRuleSetAttributes = {
      id: workspace?.pageEditorsTargetingRuleSet?.id,
      targetingRules: normalizeTargetingRules(workspace?.pageEditorsTargetingRuleSet?.targetingRules),
    }
  }

  return payload
}


export interface PageWorkspacesState {
  meta: DefaultMetaType & {
    isDeleting: boolean,
    isLoadingAllWorkspaces: boolean,
  }
}

export const initialState = {
  workspaceIds: [],
  meta: {
    ...defaultMeta,
    isDeleting: false,
    isLoadingAllWorkspaces: false,
  },
}

export const defaultWorkingCopy = {
  title: '',
  restrictAccess: false,
  viewersTargetingRuleSet: {
    targetingRules: TARGET_ENTIRE_COMPANY,
  },
  emojiCode: '',
}

const pageWorkspaceSlice = createSlice({
  name: 'pageWorkspaces',
  initialState,
  reducers: {
    ...defaultActions,

    isDeleting(state, action) {
      state.meta.isDeleting = action.payload
    },

    isLoadingAllWorkspaces(state, action) {
      state.meta.isLoadingAllWorkspaces = action.payload
    },

    setWorkspaceIds: (state, action) => {
      state.workspaceIds = action.payload
    },

    addWorkspaceId: (state, action) => {
      state.workspaceIds = <any>[...state.workspaceIds, action.payload]
    },
  },
})

const asyncActions = {
  fetchAll: () => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(pageWorkspaceSlice.actions.isLoadingAllWorkspaces(true))

      const response = await API.pageWorkspaces.fetchAll()
      const workspaceIds = response.data?.data?.map(ws => ws.id)

      dispatch(entitySlice.actions.add({
        data: response.data,
      }))
      dispatch(pageWorkspaceSlice.actions.setWorkspaceIds(workspaceIds))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(pageWorkspaceSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(pageWorkspaceSlice.actions.isLoadingAllWorkspaces(false))
    }
  },

  fetchWorkspace: (id: string) => async (dispatch: Dispatch<any>) => {
    dispatch(pageWorkspaceSlice.actions.isLoading(true))

    try {
      const response = await API.pageWorkspaces.fetch(id)

      dispatch(entitySlice.actions.add({ data: response.data }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(pageWorkspaceSlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(pageWorkspaceSlice.actions.isLoading(false))
    }
  },

  create: (pageWorkspace: PageWorkspaceType, onSuccess = (response) => {}) => async (dispatch: Dispatch<any>) => {
    dispatch(pageWorkspaceSlice.actions.isSaving(true))

    try {
      const response = await API.pageWorkspaces.create(buildWorkspacePayload(pageWorkspace))
      const workspaceId = response.data?.data?.id

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(pageWorkspaceSlice.actions.addWorkspaceId(workspaceId))
      onSuccess(response)
      dispatch(showToastMessage({ message: I18N('workspace_created'), type: 'success' }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(pageWorkspaceSlice.actions.setError(getResponseOrThrow(error)))
      dispatch(showToastMessage({ message: I18N('error_creating_workspace'), type: 'error' }))
    } finally {
      dispatch(pageWorkspaceSlice.actions.isSaving(false))
    }
  },

  // eslint-disable-next-line max-len
  update: (pageWorkspace: Partial<PageWorkspaceType>, onSuccess = (response) => {}) => async (dispatch: Dispatch<any>) => {
    dispatch(pageWorkspaceSlice.actions.isSaving(true))

    try {
      const response = await API.pageWorkspaces.update(buildWorkspacePayload(pageWorkspace))

      dispatch(entitySlice.actions.update({ data: response.data }))
      onSuccess(response)
      dispatch(showToastMessage({ message: I18N('workspace_updated'), type: 'success' }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(pageWorkspaceSlice.actions.setError(getResponseOrThrow(error)))
      dispatch(showToastMessage({ message: I18N('error_updating_workspace'), type: 'error' }))
    } finally {
      dispatch(pageWorkspaceSlice.actions.isSaving(false))
    }
  },

  destroy: (pageWorkspace: PageWorkspaceType, onSuccess = () => {}) => async (dispatch: Dispatch<any>) => {
    dispatch(pageWorkspaceSlice.actions.isDeleting(true))

    try {
      await API.pageWorkspaces.destroy(buildWorkspacePayload(pageWorkspace))

      dispatch(entitySlice.actions.remove({ id: pageWorkspace.id, type: 'pageWorkspace' }))
      onSuccess()
      dispatch(showToastMessage({ message: I18N('workspace_deleted'), type: 'success' }))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(pageWorkspaceSlice.actions.setError(getResponseOrThrow(error)))
      dispatch(showToastMessage({ message: I18N('error_deleting_workspace'), type: 'error' }))
    } finally {
      dispatch(pageWorkspaceSlice.actions.isDeleting(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.pageWorkspaces.meta,

  getPageWorkspaces: () => state => state.pageWorkspaces.workspaceIds.map(id => build(state.entities, 'pageWorkspace', id)).filter(Boolean) || [],

  getWorkspace: id => state => build(state.entities, 'pageWorkspace', id),
}

export default {
  ...pageWorkspaceSlice,
  selectors,
  asyncActions,
}
