import React from 'react'

import ReactSelect from 'components/common/react_select'
import { I18NCommon } from 'utils/i18nHelpers'

const SortBy = ({
  value,
  options,
  onChange,
}) => (
  <div className='SortBy'>
    <label>{I18NCommon('sort_by')}</label>

    <ReactSelect
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={option => option.text}
    />
  </div>
)

export default SortBy
