import { MARGIN_Y, ROW_HEIGHT } from 'hooks/layout/useLayoutSize'
import { RefObject, useEffect, useState } from 'react'
import { WidgetType } from 'types/layout/widget'

type Props = {
  widget: WidgetType
  resizeWidget: (id: string, height: number) => void
  scrollableSectionRef: RefObject<HTMLDivElement>
  scrollableContentRef: RefObject<HTMLDivElement>
  isLoaded: boolean
}

const useAutoResizeWidget = ({
  widget,
  resizeWidget,
  scrollableSectionRef,
  scrollableContentRef,
  isLoaded,
}: Props) => {
  const [hasScrollbar, setHasScrollbar] = useState(false)

  useEffect(() => {
    if (!isLoaded) return

    setTimeout(() => {
      if (!scrollableSectionRef.current || !scrollableContentRef.current) return

      setHasScrollbar(false)
      const widgetHeightInPx = widget.h * (ROW_HEIGHT + MARGIN_Y) - MARGIN_Y
      const nonScrollableHeightInPx = widgetHeightInPx - scrollableSectionRef.current.clientHeight
      // adding 1 because clientHeight removes decimal points, so it may be 1px off
      const calculatedHeightInPx = nonScrollableHeightInPx + scrollableContentRef.current.clientHeight + 1
      const newHeight = Math.ceil((calculatedHeightInPx + MARGIN_Y) / (ROW_HEIGHT + MARGIN_Y))

      if (!widget.autoResizable) {
        if (newHeight > widget.originalH) setHasScrollbar(true)
        return
      }

      if (newHeight <= widget.originalH) {
        resizeWidget(widget.id, newHeight)
        return
      }

      if (!widget.canGrow) {
        resizeWidget(widget.id, widget.originalH)
        setHasScrollbar(true)
        return
      }

      const maxHeightInPx = nonScrollableHeightInPx + widget.maxScrollableHInPx
      const maxHeight = Math.max(widget.originalH, Math.ceil((maxHeightInPx + MARGIN_Y) / (ROW_HEIGHT + MARGIN_Y)))

      if (newHeight <= maxHeight) {
        resizeWidget(widget.id, newHeight)
        return
      }

      resizeWidget(widget.id, maxHeight)
      setHasScrollbar(true)
    }, 100)
  }, [isLoaded])

  return { hasScrollbar }
}

export default useAutoResizeWidget
