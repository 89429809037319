import React from 'react'
import { useDispatch } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import EditableRichTextContent from 'components/common/editable_content/editableRichTextContent'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import UserSkills from 'components/profiles/userSkills'
import profileSlice from 'redux/slices/profiles'
import Bio from 'components/profiles/core_fields/bio'
import CdnSvg from 'components/common/cdnSvg'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'

const I18N = i18nPath('views.profile')
const plusIconPath = '/images/plusIcon.svg'

const EmptyCustomField = ({ canEdit, placeholder }) => {
  if (!canEdit) {
    return (
      <span className='d-inline-flex align-items-center text-primary'>
        {placeholder}
      </span>
    )
  }

  return (
    <span className='d-inline-flex align-items-center font-weight-500'>
      <CdnSvg className='PlusIcon mr-1' src={plusIconPath} />
      {I18N('add_answer')}
    </span>
  )
}

const CustomProfileField = ({
  currentCompany,
  user,
  canEdit,
  numberSuffix,
}) => {
  const dispatch = useDispatch()
  const { settings: companySettings } = currentCompany
  const profileFields = companySettings?.profile?.fields || {}
  const userAnswerToCustomField = user[`customField${numberSuffix}`]
  const editingPlaceholder = currentCompany[`customField${numberSuffix}EditingPlaceholder`]
  const inputPlaceholder = currentCompany[`customField${numberSuffix}InputPlaceholder`]
  const fallbackPlaceholder = I18N('custom_field_placeholder')
  const fieldName = `customField${numberSuffix}`

  const updateCustomField = (value) => {
    const change = {}
    change[fieldName] = value
    return dispatch(profileSlice.asyncActions.update(user, change))
  }

  // hide the field if it's disabled in cleary admin
  if (!profileFields[`customField${numberSuffix}`]) {
    return null
  }

  return (
    <div key={numberSuffix} className='CustomField'>
      <h6 className=''>{currentCompany[`customField${numberSuffix}Label`]}</h6>
      <EditableRichTextContent
        className='EditableRichTextContent'
        canEdit={canEdit}
        fieldName={fieldName}
        inputElement={(
          <TiptapEditor
            className='RoundedTiptapEditor w-100'
            placeholder={editingPlaceholder}
            toolbarItems={SMALL_TOOLBAR}
            configuration={{
              ...SIMPLE_EDITOR_CONFIGURATION,
              placeholder: editingPlaceholder,
            }}
          />
        )}
        displayElement={
          isHtmlStringEmpty(userAnswerToCustomField) ? (
            <EmptyCustomField canEdit={canEdit} placeholder={inputPlaceholder || fallbackPlaceholder} />
          ) : (
            <TipTapRichTextView html={userAnswerToCustomField} />
          )
        }
        valueToEdit={userAnswerToCustomField}
        saveContent={updateCustomField}
      />
    </div>
  )
}

const AboutMe = ({
  canEdit,
  currentCompany,
  user,
  showUserSkills = true,
}) => (
  <section className='ProfileAboutMe'>
    <Bio
      user={user}
      currentCompany={currentCompany}
      canEdit={canEdit}
    />
    {showUserSkills && (
      <UserSkills
        currentCompany={currentCompany}
        user={user}
        canEdit={canEdit}
      />
    )}
    {[1, 2, 3].map(number => (
      <CustomProfileField
        currentCompany={currentCompany}
        user={user}
        canEdit={canEdit}
        numberSuffix={number}
        key={number}
      />
    ))}
  </section>
)

export default AboutMe
