import CdnSvg from 'components/common/cdnSvg'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.search.search_results')

const NoQueryEntered = () => {
  const { appName } = useCurrentCompany()

  return (
    <div className='NoQueryEntered d-flex flex-column align-items-center px-5'>
      <CdnSvg src='/images/illustrations/womanWithLens.svg' className='mb-4' />
      <div className='text-large font-weight-600 mb-3'>
        {I18N('search_app_name', { appName })}
      </div>
      <div className='text-secondary text-center'>
        {I18N('search_for_people_news_resources_and_more')}
      </div>
    </div>
  )
}

export default NoQueryEntered
