import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import CdnSvg from 'components/common/cdnSvg'
import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import API from 'services/api'

const I18N = i18nPath('views.tiptap.variables')

const chevronDownIconPath = '/images/chevronDownOutline.svg'
const chevronUpIconPath = '/images/chevronUpOutline.svg'
const copyIconPath = '/images/copyIcon.svg'

interface ContentVariablesDropdownProps {
  roles?: string[]
  additionalVariables?: { role: string, attribute: string, text: string }[]
  type?: string
}

export const getVariablesForRole = (
  type,
  role,
  currentCompany,
  groupTypes
) => {
  const {
    settings,
    hasHrisIntegration,
    hrisIntegrationName,
    hasAtsIntegration,
    atsIntegrationName,
    googleServicesEnabled: areGoogleServicesEnabled,
  } = currentCompany
  const isPreboardingEnabled = settings?.journeys?.preboarding?.enabled

  const translatedRole = I18N(`${type}_roles.${role}`)
  const capitalizedRole = capitalizeFirstLetter(translatedRole)

  const baseVariables = [
    'full_name',
    'first_name',
    'start_date',
    'end_date',
    'title',
    'city',
    'office_location',
    'profile_url',
    'primary_email',
    isPreboardingEnabled && 'personal_email',
    'timezone',
    'phone',
    areGoogleServicesEnabled && 'calendar_url',
  ].filter(Boolean).map(attribute => ({
    text: `${role}_${attribute}`,
    description: I18N(`${attribute}_description`, { role: translatedRole }),
  }))

  const extraUserFields = (settings?.extraUserFields?.fields || []).filter(field => field.editable || field.targetable)
  const extraFieldVariables = extraUserFields.map(field => ({
    text: `${role}_custom_field_${field.fieldName}`,
    description: `${capitalizedRole} ${field.displayName}`,
  }))

  const integrationVariables = [
    hasHrisIntegration && { text: `${role}_${_.snakeCase(hrisIntegrationName)}_id`, description: `${capitalizedRole} ${hrisIntegrationName} ID` },
    hasAtsIntegration && { text: `${role}_${_.snakeCase(atsIntegrationName)}_id`, description: `${capitalizedRole} ${atsIntegrationName} ID` },
  ].filter(Boolean)

  const groupTypeVariables = (groupTypes || []).map(groupType => ({
    text: `${role}_${groupType.name}`,
    description: I18N('group_type_description', { group_type_name: groupType.name, role: translatedRole }),
  }))

  return [...baseVariables, ...extraFieldVariables, ...integrationVariables, ...groupTypeVariables]
}


const ContentVariablesDropdown = ({
  roles = [],
  additionalVariables = [],
  type = '',
} : ContentVariablesDropdownProps) => {
  const currentCompany = useCurrentCompany()
  const { data: groupTypes } = useGlobalStateBucket(
    API.groupTypes.fetchAll,
    'groupTypes',
    currentCompany.settings?.groups?.enabled
  )

  const variables = roles.map(role => getVariablesForRole(type, role, currentCompany, groupTypes)).flat()

  const [isOpen, setIsOpen] = useState(false)

  const copyTextToClipboard = (text) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text)
    }

    return null
  }

  const allVariables = [...variables, ...additionalVariables]

  return (
    <aside className='ContentVariablesDropdown mt-3 w-100 d-flex flex-column'>
      <header>
        <span>{I18N('title')}</span>
        <span
          className='link d-flex align-items-center'
          onClick={() => setIsOpen(!isOpen)}
        >
          {I18N('view_all_cta')}
          <CdnSvg className='ml-1' src={isOpen ? chevronUpIconPath : chevronDownIconPath} />
        </span>
      </header>
      {isOpen && (
        <dl className='d-flex flex-wrap'>
          {allVariables.map(variable => (
            <React.Fragment key={variable.text}>
              <dt className='text-small'>
                &#123;{variable.text}&#125;
                <span className='ml-2 pointer' onClick={async () => copyTextToClipboard(`{${variable.text}}`)}>
                  <CdnSvg src={copyIconPath} />
                </span>
              </dt>
              <dd className='text-small text-secondary'>{variable.description || I18N(variable.text)}</dd>
            </React.Fragment>
          ))}
        </dl>
      )}
    </aside>
  )
}

export default ContentVariablesDropdown
