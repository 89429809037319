import React from 'react'

import AudienceRulesSelector from 'components/common/audience/audienceRulesSelector'
import AudienceSummary from 'components/common/audience/audienceSummary'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { Button } from 'components/common/buttons'
import classNames from 'classnames'
import TargetingOptionsType from 'types/audience/targetingOptions'
import TargetingRulesType from 'types/audience/targetingRules'

const I18N = i18nPath('views.admin.includes.audience_selector')

interface AudienceSelectorProps {
  workingCopy: any
  updateWorkingCopy: (any) => void
  targetingOptions: TargetingOptionsType
  isLoading: boolean
  canTargetSpecificUsers?: boolean
  showEstimatedAudience?: boolean
  className?: string
  canTargetEntireCompany?: boolean
  isManagersSelectDisabled?: boolean
  targetEntireCompanyText?: string
  everyoneIsTargetedText?: string
  title?: string
  moduleName: string | null
}

const AudienceSelector = ({
  workingCopy,
  updateWorkingCopy,
  targetingOptions,
  isLoading = false,
  canTargetSpecificUsers = true,
  showEstimatedAudience = true,
  className = '',
  canTargetEntireCompany = true,
  isManagersSelectDisabled = false,
  targetEntireCompanyText = I18N('target_entire_company'),
  everyoneIsTargetedText = I18N('everyone_is_targeted'),
  title = I18N('audience'),
  moduleName,
} : AudienceSelectorProps) => {
  const { targetingRules } = workingCopy
  const isEntireCompanyTargeted = targetingRules?.targetEntireCompany

  const handleTargetingRulesChange = (newTargetingRules: TargetingRulesType) => {
    updateWorkingCopy({
      targetingRules: newTargetingRules,
    })
  }

  const clickTargetEntireCompany = () => {
    updateWorkingCopy({
      targetingRules: {
        rules: [],
        users: [],
        targetEntireCompany: true,
      },
    })
  }

  const clickTargetSpecificAudience = () => {
    updateWorkingCopy({
      targetingRules: {
        rules: [],
        users: [],
        targetEntireCompany: false,
      },
    })
  }

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className={classNames('AudienceSelector', className)}>
      {showEstimatedAudience && (
        <div className='d-flex'>
          <h4 className='pb-2 mr-2'>{title}</h4>
          <AudienceSummary targetingRules={targetingRules} moduleName={moduleName} />
        </div>
      )}
      {isEntireCompanyTargeted && (
        <>
          <div className='text-secondary mb-3'>{everyoneIsTargetedText}</div>
          <Button variant='tag' onClick={clickTargetSpecificAudience}>
            + {I18N('select_an_audience')}
          </Button>
        </>
      )}
      {!isEntireCompanyTargeted && (
        <>
          {canTargetEntireCompany && !isManagersSelectDisabled ? (
            <div className='mb-3 text-secondary'>
              {I18N('select_an_audience_or')}&nbsp;
              <a
                className='link-color font-weight-500'
                onClick={clickTargetEntireCompany}
                data-testid='cy-target-entire-company'
              >
                {targetEntireCompanyText}.
              </a>
            </div>
          ) : (
            <div className='mb-3 text-secondary'>
              {I18N('select_an_audience')}.
            </div>
          )}
          <AudienceRulesSelector
            targetingRules={targetingRules}
            onChange={handleTargetingRulesChange}
            targetingOptions={targetingOptions}
            canTargetSpecificUsers={canTargetSpecificUsers}
            isManagersSelectDisabled={isManagersSelectDisabled}
            className='mb-3 compact'
          />
        </>
      )}
    </div>
  )
}

export default AudienceSelector
