import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import ClearyInternalSearchResultsTemplate from './clearyInternalSearchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const I18N = i18nPath('views.search.search_results.search_results')

const getCountLabel = (activeMembersCount) => {
  const memberCount = activeMembersCount || 0
  if (memberCount === 0) return ''

  const countLabelKey = `member_count_${memberCount === 1 ? 'singular' : 'plural'}`

  return I18N(countLabelKey, { count: memberCount })
}

export const TeamResult = ({ result }) => {
  const { group, highlights, displayPath } = result
  // groupTypeName includes highlights, groupType.name doesn't
  const {
    activeMembersCount,
    groupType,
    description,
    name,
    groupTypeName,
  } = { ...group, ...highlights }

  return (
    <SearchResultTemplate
      displayPath={displayPath}
      image={(
        <img
          alt={group.name}
          style={{ height: '40px', width: '40px' }}
          src={group.primaryPhotoThumbnailUrl}
          className='bordered-thumbnail'
        />
      )}
      title={name}
      subtitle={`${groupTypeName || groupType.name} ${getCountLabel(activeMembersCount)}`}
      description={description}
      result={result}
      descriptionExtraMargin
    />
  )
}

const TeamResults = ({ showLabel = true }) => (
  <ClearyInternalSearchResultsTemplate
    showLabel={showLabel}
    label='groups'
    type='group'
    searchResultToTemplateMap={result => <TeamResult result={result} />}
  />
)

export default TeamResults
