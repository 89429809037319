import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.search.global_search.see_more')

const SeeMoreOption = ({ option }) => (
  <p className='SeeMore m-0 d-flex text-small'>
    {I18N(option.label)}
  </p>
)

export default SeeMoreOption
