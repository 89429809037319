import React, { useEffect } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSecondarySmall } from 'components/common/buttons'
import UserCard from 'components/home/widgets/onboarding_buddies_widget/userCard'

const I18N = i18nPath('views.home.onboarding_buddies')

const NewHireBuddies = ({ buddies, setIsLoaded }) => {
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <>
      <div className='mb-2'>
        {I18N('new_hire_buddy_help_text', { count: buddies.length })}
      </div>
      <div className='d-flex flex-column gap-3'>
        {buddies.map(buddy => (
          <UserCard key={buddy.id} user={buddy} cardBodyClassName='p-2'>
            {buddy.calendarEventUrl && (
              <div className='d-flex justify-content-center mt-2'>
                <ButtonSecondarySmall onClick={() => window.open(buddy.calendarEventUrl)}>
                  {I18N('schedule_a_chat')}
                </ButtonSecondarySmall>
              </div>
            )}
          </UserCard>
        ))}
      </div>
    </>
  )
}

export default NewHireBuddies
