import React, { useEffect, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import shoutoutsSlice from 'redux/slices/shoutouts'
import ShoutoutsList from 'components/shoutouts/shoutoutsList'
import PathnameContext from 'components/contexts/pathnameContext'
import { trackEvent } from 'services/tracker'

const ShoutoutsListPage = () => {
  const dispatch = useDispatch()
  const [currentFilter, setCurrentFilter] = useState('all')
  const { currentPathname, previousPathname } = useContext(PathnameContext)

  const shoutouts = useSelector(shoutoutsSlice.selectors.getShoutouts())
  const {
    isLoading,
    queryParams: { page, totalPages, perPage },
  } = useSelector(shoutoutsSlice.selectors.getMetaData())

  const handleFetchShoutouts = () => {
    // abort getting next page if request in flight
    if (isLoading) return
    dispatch(shoutoutsSlice.asyncActions.fetchShoutouts({ page: page + 1, perPage, filter: currentFilter }))
  }

  const handleFilterSelection = (filter) => {
    setCurrentFilter(filter)
    dispatch(shoutoutsSlice.actions.clear())
    dispatch(shoutoutsSlice.asyncActions.reset)
    dispatch(shoutoutsSlice.asyncActions.fetchShoutouts({ page: 1, perPage, filter }))
  }

  const hasMoreContent = () => page < totalPages

  const cleanup = () => {
    dispatch(shoutoutsSlice.asyncActions.reset)
    dispatch(shoutoutsSlice.actions.clear())
  }

  useEffect(() => {
    cleanup() // prevent shoutouts loaded on a different page to show first on the shoutout feed
    dispatch(shoutoutsSlice.asyncActions.fetchShoutouts({ page, perPage, filter: 'all' }))
    trackEvent('shoutout:view_all')

    // Need to reset shoutouts state so the infinite scroller works correctly
    return cleanup
  }, [])

  useEffect(() => {
    // Trigger fetch if the previous path corresponds to the shoutout detail page
    if (previousPathname?.match(/\/people\/shoutouts\/[a-zA-Z0-9_]+$/) && currentPathname !== previousPathname) {
      dispatch(shoutoutsSlice.asyncActions.fetchShoutouts({ page, perPage, filter: 'all' }))
    }
  }, [previousPathname])

  return (
    <div className='ShoutoutsListPage pt-3'>
      <ShoutoutsList
        shoutouts={shoutouts}
        isLoading={isLoading}
        hasMoreContent={hasMoreContent}
        handleFetchShoutouts={handleFetchShoutouts}
        handleFilterSelection={handleFilterSelection}
      />
    </div>
  )
}

export default ShoutoutsListPage
