import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import CollapsibleCard from 'components/common/collapsibleCard'
import IntegrationCard from 'components/admin/integrations/integrationCard'
import { ButtonSmallNarrow } from 'components/common/buttons'
import { Creatable } from 'components/common/react_select'
import itAdminSlice from 'redux/slices/itAdmin'

import microsoftLogo from 'assets/microsoftLogo.svg'
import samlLogo from 'assets/samlLogo.svg'
import WorkosIntegrationCard from 'components/admin/integrations/workosIntegrationCard'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SAMLInstallationComponent from 'components/admin/integrations/samlInstallationComponent'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.integrations_page.authentication_section')
const I18NIntegrationCard = i18nPath('views.integrations_page.integration_card')

const googleLogoPath = '/images/integrations/googleLogo.svg'


const SessionInput = ({
  session, setSession, field, placeholder, footerNote, label,
}) => (
  <div className='col-6'>
    <div className='form-group'>
      <label className='form-label'>{label}</label>
      <input
        type='number'
        value={session[field]}
        onChange={e => setSession({ ...session, [field]: e.target.value })}
        placeholder={placeholder}
        name={field}
      />
      <small className='form-text text-secondary'>{footerNote}</small>
    </div>
  </div>
)

const AuthenticationSection = () => {
  const dispatch = useDispatch()

  const { permissions: { superAdmin, clearyAdmin } } = useCurrentUser()
  const { appName, settings: companySettings } = useCurrentCompany()
  const canUserManageSessionConfiguration = (superAdmin && companySettings.session?.managedByCustomer) || clearyAdmin
  const { isSaving, isSavingSessionConfiguration } = useSelector(itAdminSlice.selectors.getMetaData())
  const {
    emailLoginDomains: savedEmailLoginDomains,
    samlProvider, authType,
  } = useSelector(itAdminSlice.selectors.getData())

  const [emailLoginDomains, setEmailLoginDomains] = useState([])
  const [session, setSession] = useState(companySettings.session)

  const updateEmailLoginDomains = () => {
    dispatch(itAdminSlice.asyncActions.admin.updateData({ emailLoginDomains }))
  }

  const updateSessionConfiguration = () => {
    dispatch(itAdminSlice.asyncActions.admin.updateSessionConfiguration(session))
  }

  const uninstallConfirming = (integration) => {
    if (confirm(I18NCommon('confirm_cant_undo'))) {
      dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration(integration))
    }
  }

  useEffect(() => {
    if (savedEmailLoginDomains) {
      setEmailLoginDomains(savedEmailLoginDomains)
    }
  }, [JSON.stringify(savedEmailLoginDomains)])

  const simpleAuthenticationIntegrations = [
    {
      id: 'google',
      logo: <CdnSvg src={googleLogoPath} />,
      onUninstall: () => uninstallConfirming('google_auth'),
      uninstallationButtonText: I18NCommon('disconnect'),
    },
    {
      id: 'microsoft',
      logo: <img src={microsoftLogo} alt='Logo' width='21' />,
      onUninstall: () => uninstallConfirming('microsoft_auth'),
      uninstallationButtonText: I18NCommon('disconnect'),
    },
    {
      id: 'saml',
      logo: <img src={samlLogo} alt='Logo' width='21' />,
      installationComponent: <SAMLInstallationComponent />,
      customInstallationMessage: I18NIntegrationCard('saml.successfully_installed', { provider: samlProvider }),
      onUninstall: () => uninstallConfirming('saml'),
      docLink: 'https://docs.google.com/document/d/1OCml3IX90wYWrnhE0D5TMLGg589dZAlNRDrDBWcolJg/edit?usp=sharing',
      docTitle: I18N('saml_doc_title'),
    },
  ]

  return (
    <CollapsibleCard title={I18N('authentication')} className='mb-3'>
      <section className='AuthenticationSection'>
        <div className='full-width'>{I18N('sso')}</div>
        {simpleAuthenticationIntegrations.map(integration => (
          <IntegrationCard
            key={integration.id}
            id={integration.id}
            logo={integration.logo}
            docLink={integration.docLink}
            docTitle={integration.docTitle}
            installationComponent={integration.installationComponent}
            customInstallationMessage={integration.customInstallationMessage}
            onUninstall={integration.onUninstall}
            uninstallationButtonText={integration.uninstallationButtonText}
            isInstalled={authType === integration.id || integration.authTypes?.includes(authType)}
          />
        ))}
        {authType === 'workos' && <WorkosIntegrationCard />}
        <div className='full-width'>
          <div className='mb-1'>{I18N('email_login_domains')}</div>
          <Creatable
            isMulti
            value={emailLoginDomains.map(v => ({ label: v, value: v }))}
            onChange={selectedOptions => setEmailLoginDomains(selectedOptions.map(so => so.value))}
            placeholder={I18N('email_login_domains_placeholder')}
          />
          <div className='text-small text-secondary mt-1 mb-2'>{I18N('email_login_domains_explanation', { appName })}</div>
          <ButtonSmallNarrow
            onClick={updateEmailLoginDomains}
            disabled={isSaving}
            showLoadingSpinner={isSaving}
          >
            {I18N('save_email_login_domains')}
          </ButtonSmallNarrow>

          {canUserManageSessionConfiguration && (
            <>
              <h5 className='mt-3'>{I18N('session_configuration')}</h5>

              <h6 className='mt-3'>{I18NCommon('web')}</h6>
              <div className='row'>
                <SessionInput
                  session={session}
                  setSession={setSession}
                  field='expireAfterInHours'
                  placeholder={I18N('expire_after_n_hours_placeholder')}
                  label={I18N('expire_after_n_hours')}
                  footerNote={I18N('expire_after_n_hours_footer')}
                />

                <SessionInput
                  session={session}
                  setSession={setSession}
                  field='maxAgeInHours'
                  placeholder={I18N('maximum_age_in_hours_placeholder')}
                  label={I18N('maximum_age_in_hours')}
                  footerNote={I18N('maximum_age_in_hours_footer')}
                />
              </div>

              <h6 className='mt-3'>{I18NCommon('mobile')}</h6>
              <div className='row'>
                <SessionInput
                  session={session}
                  setSession={setSession}
                  field='mobileExpireAfterInHours'
                  placeholder={I18N('expire_after_n_hours_placeholder')}
                  label={I18N('expire_after_n_hours')}
                  footerNote={I18N('expire_after_n_hours_footer')}
                />

                <SessionInput
                  session={session}
                  setSession={setSession}
                  field='mobileMaxAgeInHours'
                  placeholder={I18N('maximum_age_in_hours_placeholder')}
                  label={I18N('maximum_age_in_hours')}
                  footerNote={I18N('maximum_age_in_hours_footer')}
                />
              </div>

              <ButtonSmallNarrow
                onClick={updateSessionConfiguration}
                disabled={isSavingSessionConfiguration}
                showLoadingSpinner={isSavingSessionConfiguration}
              >
                {I18N('save_session_configuration')}
              </ButtonSmallNarrow>
            </>
          )}
        </div>
      </section>
    </CollapsibleCard>
  )
}

export default AuthenticationSection
