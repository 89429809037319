import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import UserSkillTag from 'components/profiles/userSkillTag'

const I18N = i18nPath('views.custom_styles.preview_components')

const SkillTagHoverBackgroundColor = () => (
  <UserSkillTag text={I18N('user_skill_name')} />
)

export default SkillTagHoverBackgroundColor
