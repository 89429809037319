import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import useQueryParams from 'components/common/hooks/useQueryParams'

import { PAGE_SIZES } from 'redux/slices/search'
import PaginationContainer from 'components/common/tables/paginationContainer'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { i18nPath } from 'utils/i18nHelpers'
import NoResultsFound from './noResultsFound'

const I18N = i18nPath('views.search.search_results.search_results')

const SearchResultsTemplate = ({
  label, count, results, page, setPage, isLoading, selected, showLabel = true, seeAllFilter = null,
}) => {
  const { golinkNotFound } = useQueryParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  searchParams.set('filter', seeAllFilter || label)

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (selected && count === 0 && !golinkNotFound) {
    return <NoResultsFound />
  }

  if (_.isEmpty(results)) return null

  return (
    <div>
      {showLabel && (
        <div className='font-weight-600 text-secondary mb-2'>{I18N(label)}</div>
      )}
      <div className='mb-5'>
        {results.map((result, index) => (
          <div key={`results-${label}-${index}`}>
            {index > 0 && <hr />}
            {result}
          </div>
        ))}
      </div>
      {selected && (
        <>
          {count > PAGE_SIZES.pageFiltered && <hr />}
          <PaginationContainer
            page={page}
            numPages={Math.ceil(count / PAGE_SIZES.pageFiltered)}
            perPage={PAGE_SIZES.pageFiltered}
            totalCount={count}
            handleClick={setPage}
          />
        </>
      )}
    </div>
  )
}

export default SearchResultsTemplate
