import React from 'react'

import TaskableFormSharedFieldsSection from 'components/admin/journeys/taskables/taskableFormSharedFieldsSection'
import TaskableFormScheduleSection from 'components/admin/journeys/taskables/taskableFormScheduleSection'
import StepTemplateFormButtonsContainer from 'components/admin/journeys/common/stepTemplateFormButtonsContainer'
import TaskableFormAdvancedSettingsSection from 'components/admin/journeys/taskables/taskableFormAdvancedSettingsSection'

export const nonRequiredFields = [
  'allowSilenceNotifications',
  'automaticallyScheduleCalendarInvites',
  'dueAfterNumDays',
  'notifyViaEmail',
  'notifyViaMsTeams',
  'notifyViaSlack',
  'notifyViaSms',
  'taskable',
  'taskableId',
  'taskableType',
  'taskType',
  'useRecipientNotificationPreferences',
]

const TaskForm = ({
  onSave = () => {},
  workingCopy,
  updateWorkingCopy,
  currentState = 'draft',
  areChangesPresent = true,
  forUser = null,
  isStepTemplate = true,
  isDisabled = false,
  journeyBlueprint,
  isSaving = false,
  showButtons = true,
  helpCard = <></>,
}) => (
  <div className='TaskForm'>
    {helpCard}
    <TaskableFormSharedFieldsSection
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
      isDisabled={isDisabled}
      stepType='task'
      journeyBlueprint={journeyBlueprint}
    />
    <TaskableFormScheduleSection
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
      isDisabled={isDisabled}
      isStepTemplate={isStepTemplate}
      journeyBlueprint={journeyBlueprint}
    />
    <TaskableFormAdvancedSettingsSection
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
      isDisabled={isDisabled}
      isStepTemplate={isStepTemplate}
      stepType='task'
      journeyBlueprint={journeyBlueprint}
    />
    {showButtons && (
      <StepTemplateFormButtonsContainer
        onSave={onSave}
        workingCopy={workingCopy}
        currentState={currentState}
        areChangesPresent={areChangesPresent}
        forUser={forUser}
        isStepTemplate={isStepTemplate}
        isDisabled={isDisabled}
        isSaving={isSaving}
        stepType='task'
        nonRequiredFields={nonRequiredFields}
        postTriggerNames={['due']}
      />
    )}
  </div>
)

export default TaskForm
