import React from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'

import classNames from 'classnames'

const TypographyPage = () => {
  const sampleText = 'The quick brown fox jumps over the lazy dog. How vexingly quick daft zebras jump! Jackdaws love my big sphinx of quartz.'

  return (
    <main className='Styleguide TypographyPage container mt-4'>
      <h1><StyleguideBaseLink /> / Typography</h1>
      <section className='mt-6'>
        <h3>Headings</h3>
        <dl>
          <dt>
            <h5 className='text-secondary text-uppercase'>Heading 1</h5>
          </dt>
          <dd>
            <h1>This is an h1 heading</h1>
            <h1 className='text-secondary'>{sampleText}</h1>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Heading 2</h5>
          </dt>
          <dd>
            <h2>This is an h2 heading</h2>
            <h2 className='text-secondary'>{sampleText}</h2>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Heading 3</h5>
          </dt>
          <dd>
            <h3>This is an h3 heading</h3>
            <h3 className='text-secondary'>{sampleText}</h3>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Heading 4</h5>
          </dt>
          <dd>
            <h4>This is an h4 heading</h4>
            <h4 className='text-secondary'>{sampleText}</h4>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Heading 5</h5>
          </dt>
          <dd>
            <h5>This is an h5 heading</h5>
            <h5 className='text-secondary'>{sampleText}</h5>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Heading 6</h5>
          </dt>
          <dd>
            <h6>This is an h6 heading</h6>
            <h6 className='text-secondary'>{sampleText}</h6>
          </dd>
        </dl>
      </section>
      <section className='mt-6'>
        <h3>Body</h3>
        <dl>
          <dt>
            <h5 className='text-secondary text-uppercase'>Body</h5>
          </dt>
          <dd>
            <p>This is an body text</p>
            <p className='text-secondary'>{sampleText}</p>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Body Heavy</h5>
          </dt>
          <dd>
            <p className='font-weight-500'>This is an body heavy text</p>
            <p className='text-secondary font-weight-500'>{sampleText}</p>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Body Link</h5>
          </dt>
          <dd>
            <a href=''>This is an body link text</a>
            <a href='' className='d-block text-secondary'>{sampleText}</a>
          </dd>
        </dl>
      </section>
      <section className='mt-6'>
        <h3>Small Text</h3>
        <dl>
          <dt>
            <h5 className='text-secondary text-uppercase'>Small Text</h5>
          </dt>
          <dd>
            <p className='text-small'>This is an small text</p>
            <p className='text-secondary text-small'>{sampleText}</p>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Small Text Heavy</h5>
          </dt>
          <dd>
            <p className='text-small font-weight-500'>This is a small heavy text</p>
            <p className='text-secondary text-small font-weight-500'>{sampleText}</p>
          </dd>
          <dt>
            <h5 className='text-secondary text-uppercase'>Small Text Link</h5>
          </dt>
          <dd>
            <a href='' className='text-small'>This is small text link</a>
            <a href='' className='d-block text-secondary text-small'>{sampleText}</a>
          </dd>
        </dl>
      </section>
    </main>
  )
}

export default TypographyPage
