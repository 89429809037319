import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import audienceSlice from 'redux/slices/audiences'

import { ButtonSecondary } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import JourneyBlueprintTargetingModal from 'components/admin/journeys/modals/journeyBlueprintTargetingModal'
import AddEmployeesManuallyModal from 'components/admin/journeys/modals/addEmployeesManuallyModal'

import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import ProgressGuidanceCard from 'components/common/progressGuidanceCard'
import CdnSvg from 'components/common/cdnSvg'

const JourneyCreationProgressStepBody = ({
  currentStep,
  journeyBlueprintType,
  usersStartingJourney,
  onLaunchJourney,
}) => (
  <>
    <span className='text-small font-weight-500 text-secondary mb-2 d-inline-block'>{I18N(`${journeyBlueprintType}.subtitle`)}</span>
    {currentStep === 0 && (
      <h5>{I18N(`${journeyBlueprintType}.step_1.description`)}</h5>
    )}
    {currentStep === 1 && (
      <div className='d-flex justify-content-between step2-container'>
        <h5>{I18N(`${journeyBlueprintType}.step_2.description`)}</h5>
        <ButtonSecondary onClick={onLaunchJourney}>{I18N(`${journeyBlueprintType}.step_2.button_cta`)}</ButtonSecondary>
      </div>
    )}
    <div className='d-flex justify-content-start current-participants-container'>
      {usersStartingJourney.length > 0 && (
        <>
          <RoundedAvatarsWithAsyncUserFetching className='mr-2' userIds={usersStartingJourney} linkTo='journeys' />
          <span className='mt-2 mb-2 text-smallest text-secondary font-weight-500'>
            {I18N(`${journeyBlueprintType}.step_2.audience_summary`, { count: usersStartingJourney.length })}
          </span>
        </>
      )}
    </div>
  </>
)

const I18N = i18nPath('views.admin.journeys.journey_blueprints.progress_guidance')

const JourneyCreationProgress = ({
  journeyBlueprint,
  setIsSuccessModalOpen,
  onRemoveJourney,
  currentUsers = [],
}) => {
  const getUsersStartingJourney = (draftStateUserIds, targetedUserIds) => {
    if (['manually', 'automatically_when_targeting_changes'].includes(journeyBlueprint?.howUsersAreAdded)) {
      return draftStateUserIds
    }

    return _.uniq(targetedUserIds?.concat(draftStateUserIds))
  }

  const dispatch = useDispatch()
  const currentStep = _.isEmpty(journeyBlueprint?.stepTemplates) ? 0 : 1
  const [isAudienceModalOpen, setIsAudienceModalOpen] = useState(false)
  const [isAddNewHiresModalOpen, setIsAddNewHiresModalOpen] = useState(false)

  const draftStateSimpleJourneys = useSelector(journeyBlueprintSlice.selectors.getSimpleJourneys(journeyBlueprint?.id))
  const draftStateUserIds = draftStateSimpleJourneys?.map(j => j.userId)
  const targetingRules = journeyBlueprint?.targetingRules
  const targetedUsers = useSelector(audienceSlice.selectors.getUsers())
  const targetedUserIds = targetedUsers.map(user => user?.id)
  const usersStartingJourney = getUsersStartingJourney(draftStateUserIds, targetedUserIds)

  const automaticallyAddUsersDaysAfterMilestone = journeyBlueprint?.automaticallyAddUsersDaysAfterMilestone
  const journeyBlueprintType = journeyBlueprint?.type
  const howUsersAreAdded = journeyBlueprint?.howUsersAreAdded

  useEffect(() => {
    dispatch(audienceSlice.asyncActions.fetchJourneysEstimatedAudience({
      targetingRules,
      journeyBlueprintType,
      daysAfterMilestone: automaticallyAddUsersDaysAfterMilestone,
      includeHiddenUsers: true,
    }))
  }, [journeyBlueprint?.id, targetingRules, howUsersAreAdded])

  const onLaunchJourney = () => {
    dispatch(journeyBlueprintSlice.asyncActions.admin.launch(journeyBlueprint, setIsSuccessModalOpen))
  }

  const onRemoveClick = (journey) => {
    setIsAddNewHiresModalOpen(false)
    onRemoveJourney(journey)
  }

  return (
    <div className='JourneyCreationProgress w-100 mb-5'>
      <ProgressGuidanceCard
        currentStepIndex={currentStep}
        steps={[
          {
            title: I18N(`${journeyBlueprintType}.step_1.title`),
            icon: <CdnSvg className='StepsIcon' src='/images/stepsIcon.svg' />,
            body: <JourneyCreationProgressStepBody
              currentStep={currentStep}
              journeyBlueprintType={journeyBlueprintType}
              usersStartingJourney={usersStartingJourney}
              onLaunchJourney={onLaunchJourney}
            />,
          }, {
            title: I18N(`${journeyBlueprintType}.step_2.title`),
            icon: <CdnSvg className='RocketIcon' src='/images/rocketIcon.svg' />,
            body: <JourneyCreationProgressStepBody
              currentStep={currentStep}
              journeyBlueprintType={journeyBlueprintType}
              usersStartingJourney={usersStartingJourney}
              onLaunchJourney={onLaunchJourney}
            />,
          },
        ]}
      />

      {isAudienceModalOpen && (
        <JourneyBlueprintTargetingModal
          journeyBlueprint={journeyBlueprint}
          toggle={() => setIsAudienceModalOpen(false)}
          visible={isAudienceModalOpen}
        />
      )}

      <AddEmployeesManuallyModal
        currentUsers={currentUsers}
        journeyBlueprint={journeyBlueprint}
        toggle={() => setIsAddNewHiresModalOpen(false)}
        visible={isAddNewHiresModalOpen}
        onRemoveJourney={onRemoveClick}
      />
    </div>
  )
}

export default JourneyCreationProgress
