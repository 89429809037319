import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { UserType } from 'types/user'

const I18N = i18nPath('views.home.onboarding_buddies')

interface UserCardProps {
  user: UserType
  includeStartDate?: boolean
  className?: string
  children?: React.ReactNode
  cardBodyClassName?: string
}

const UserCard = ({
  user,
  includeStartDate = false,
  className = '',
  children,
  cardBodyClassName,
}: UserCardProps) => (
  <Card className={classNames('UserCard', className)} cardBodyClassName={cardBodyClassName}>
    <div className='UserPhotoAndInfo d-flex'>
      <EmployeeThumbnailPhoto employee={user} className='mr-2' linkToProfile />
      <div className='UserInfo'>
        <Link to={`/profile/${user.username}`} className='font-weight-600 truncate-text-at-2-lines'>{user.preferredFullName}</Link>
        <div className='text-small font-weight-400 truncate-text-at-1-lines'>{user.title}</div>
        <div className='text-small font-weight-400 text-secondary truncate-text-at-1-lines'>{user.city}</div>
        {includeStartDate && user.startDate && (
          <div className='text-smallest font-weight-400 mt-2'>
            {I18N('employee_since', { date: i18nMoment(user.startDate).format('MMM D, YYYY') })}
          </div>
        )}
      </div>
    </div>
    {children}
  </Card>
)

export default UserCard
