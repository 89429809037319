import React, { forwardRef } from 'react'
import Slider from 'react-slick'

import { LeftBlueChevronIcon } from 'components/icons/chevron'

import isMobile from 'utils/isMobile'

type Props = {
  className?: string
  settings?: any
  children: any
  hideArrowsOnMobile?: boolean
  afterChange?: () => void
  beforeChange?: () => void
  innerRef?: any
}

const ClearySlider = ({
  className = '',
  settings = {},
  children,
  hideArrowsOnMobile = false,
  afterChange = undefined,
  beforeChange = undefined,
  innerRef = null,
}: Props) => {
  const hideArrows = hideArrowsOnMobile && isMobile() ? null : <CarouselButton />

  const defaultSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: hideArrows,
    nextArrow: hideArrows,
    dots: true,
    dotsClass: 'pagination slick-dots slick-thumb',
    customPaging: () => <div className='default-dot' />,
  }

  const carouselSettings = {
    ...defaultSettings,
    ...settings,
  }

  return (
    <Slider
      ref={innerRef}
      className={className}
      {...carouselSettings}
      afterChange={afterChange}
      beforeChange={beforeChange}
    >
      {children}
    </Slider>
  )
}

type CarouselButtonProps = {
  className?: string
  onClick?: () => void
}

const CarouselButton = ({ className, onClick }: CarouselButtonProps) => (
  <a className={className} onClick={onClick}>
    <LeftBlueChevronIcon />
  </a>
)

export default forwardRef((props: Props, ref) => <ClearySlider innerRef={ref} {...props} />)
