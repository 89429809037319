import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import celebrationSlice from 'redux/slices/celebrations'

import InfiniteScroller from 'components/common/infiniteScroller'
import CelebrationCard from 'components/celebrations/celebrationCard'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const Celebrations = ({ user }) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const celebrations = useSelector(celebrationSlice.selectors.getCelebrations())
  const { isLoading, queryParams } = useSelector(celebrationSlice.selectors.getMetaData())
  const { page, totalPages } = queryParams

  const hasMoreCelebrations = () => page < totalPages

  const fetchCelebrations = ({ page = 1, perPage = 5 } = {}) => {
    dispatch(celebrationSlice.asyncActions.fetchCelebrations({ userId: user.id, page, perPage }))
  }

  useEffect(() => {
    fetchCelebrations()
  }, [])

  return (
    <InfiniteScroller
      isFetching={isLoading}
      onFetch={() => fetchCelebrations({ page: page + 1 })}
      hasMoreContent={hasMoreCelebrations}
      className='d-flex flex-column align-items-center w-100'
    >
      {celebrations.map(celebration => (
        <div className='mt-3 w-100' key={`celebration-${celebration.id}`}>
          <CelebrationCard
            celebration={celebration}
            currentUser={currentUser}
            companyName={currentCompany.name}
          />
        </div>
      ))}
    </InfiniteScroller>
  )
}

export default Celebrations
