import React, { useState } from 'react'
import { I18NCommon } from 'utils/i18nHelpers'
import ApiAsyncSelector from 'components/common/apiAsyncSelector'
import { ButtonSmallNarrow } from 'components/common/buttons'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CdnSvg from 'components/common/cdnSvg'

type Props = {
  items: any[]
  isLoading: boolean
  isSaving: boolean
  onCreate: (id: string) => void
  onDelete: (id: string) => void
  labelProp: string
  search: (query: string) => any
  addButtonText: string
}

const ApiListMultiSelect = ({
  items,
  isLoading,
  onCreate,
  onDelete,
  labelProp,
  search,
  addButtonText,
}: Props) => {
  const [showForm, setShowForm] = useState(false)

  const handleCreate = (item) => {
    onCreate(item)
    setShowForm(false)
  }

  return (
    <div className='ApiListMultiSelect d-flex flex-column align-items-center gap-3'>
      {isLoading && <CirclesLoadingIndicator />}
      {items.map(item => (
        <Item
          key={item.id}
          label={item[labelProp]}
          onDelete={() => onDelete(item.id)}
        />
      ))}
      {showForm ? (
        <NewItemForm
          onCreate={handleCreate}
          labelProp={labelProp}
          search={search}
        />
      ) : (
        <ButtonSmallNarrow
          onClick={() => setShowForm(true)}
          variant='secondary'
        >
          {addButtonText}
        </ButtonSmallNarrow>
      )}
    </div>
  )
}

const Item = ({ label, onDelete }) => (
  <div className='bg-white p-3 w-100'>
    <div className='d-flex align-items-center justify-content-between'>
      <span className='truncate-text-at-1-lines font-weight-600'>
        {label}
      </span>
      <div className='XIcon pointer' onClick={onDelete}>
        <CdnSvg src='/images/xIcon.svg' />
      </div>
    </div>
  </div>
)

const NewItemForm = ({
  onCreate, labelProp, search,
}) => {
  const [selectedItem, setSelectedItem] = useState<any>(null)

  return (
    <div className='bg-white p-3 w-100'>
      <div className='d-flex flex-column align-items-center gap-3'>
        <ApiAsyncSelector
          className='w-100'
          value={selectedItem}
          onChange={item => setSelectedItem(item)}
          fetchAll={({ query }) => search(query)}
          valueProp='id'
          labelProp={labelProp}
        />
        <ButtonSmallNarrow
          variant='secondary'
          disabled={!selectedItem}
          onClick={() => onCreate(selectedItem)}
        >
          {I18NCommon('save')}
        </ButtonSmallNarrow>
      </div>
    </div>
  )
}

export default ApiListMultiSelect
