import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import API from 'services/api'
import FeedItem from 'components/home/feedItem'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useApi from 'components/common/hooks/useApi'
import { i18nPath } from 'utils/i18nHelpers'
import Tag from 'components/common/tag'
import Card from 'components/common/card'
import WidgetTitle from 'components/home/widgets/common/widgetTitle'
import { useSelector } from 'react-redux'
import entitySlice from 'redux/slices/entities'

const I18N = i18nPath('views.home')

const FeedWidget = ({ widget }) => {
  const { settings, title } = widget
  const ref = useRef<HTMLDivElement>(null)
  const tagFilterRef = useRef<HTMLDivElement>(null)
  const [feedItemIds, setFeedItemIds] = useState<string[]>([])
  const feedItems = useSelector(entitySlice.selectors.getEntities('feedItem', feedItemIds))

  const feedItemTypes = useMemo(() => (
    Object.entries(settings.feedItems || {}).filter(([_, value]) => value).map(([key]) => _.snakeCase(key))
  ), [settings.feedItems])

  const [fetch, { isLoading, paginationData }] = useApi(API.home.fetchFeed, {
    addEntitySlice: true,
    onSuccess: (data) => {
      setFeedItemIds(prev => _.uniq([...prev, ...data.map(d => d.id)]))
    },
  })

  const {
    page = 0,
    total = 0,
    totalPages = 1,
  } = paginationData || {}

  const { name: companyName } = useCurrentCompany()
  const currentUser = useCurrentUser()

  const [selectedTag, setSelectedTag] = useState<any>(null)

  useEffect(() => {
    setFeedItemIds([])
    fetch({ tagId: selectedTag?.id, perPage: 10, feedItemTypes })
  }, [selectedTag?.id, feedItemTypes])

  const next = () => {
    if (isLoading) return

    fetch({
      page: page + 1, perPage: 10, tagId: selectedTag?.id, feedItemTypes,
    })
  }

  // subtracting 1 to the height otherwise an additional scrollbar appears in some screens
  const infiniteScrollHeight = (ref.current?.clientHeight || 600) - 1

  return (
    <Card className='FeedWidget HomePageWidget'>
      <WidgetTitle title={title} />

      {selectedTag && (
        <div ref={tagFilterRef} className='pb-2'>
          <span className='label mr-2'>{I18N('filter_by')}</span>
          <Tag text={selectedTag.name} key={selectedTag.id} deleteIcon onClick={() => setSelectedTag(null)} />
        </div>
      )}
      <div ref={ref} className='HomePageWidget--scrollable-section hasOutsideScrollbar'>
        <InfiniteScroll
          dataLength={total || 0}
          next={next}
          hasMore={page < totalPages}
          loader={<CirclesLoadingIndicator />}
          height={infiniteScrollHeight}
          className='p-1'
        >
          {feedItems?.map(feedItem => (
            <FeedItem
              key={`feed-item-${feedItem.id}`}
              feedItem={feedItem}
              companyName={companyName}
              currentUser={currentUser}
              onTagClick={setSelectedTag}
            />
          ))}
        </InfiniteScroll>
      </div>
    </Card>
  )
}

export default FeedWidget
