import React from 'react'

import classNames from 'classnames'

const ShoutoutText = ({ text, className = '' }) => (
  <div className={classNames('ShoutoutText text-primary cursor-default ws-pre-wrap', className)}>
    {text}
  </div>
)

export default ShoutoutText
