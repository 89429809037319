import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import classNames from 'classnames'
import OptInOrOutCard from 'components/home/widgets/onboarding_buddies_widget/optInOrOutCard'
import SelectBuddyCard from 'components/home/widgets/onboarding_buddies_widget/selectBuddyCard'
import Card from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'
import OnboardingBuddy from 'components/home/widgets/onboarding_buddies_widget/onboardingBuddy'
import NewHireBuddies from 'components/home/widgets/onboarding_buddies_widget/newHireBuddies'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const ONBOARDING_BUDDY = 'onboarding_buddy'
const NEW_HIRE_BUDDIES = 'new_hire_buddies'
const BUDDY_MARKETPLACE = 'buddy_marketplace'
const OPT_IN_OR_OUT = 'opt_in_or_out'

const NEEDS_A_BUDDY_STATUS = 'needs_a_buddy'
const ELIGIBLE_STATUS = 'eligible'

const I18N = i18nPath('views.home.onboarding_buddies')

const OnboardingBuddiesWidget = ({
  EmptyState = null,
  className = 'h-100',
  widget,
  resizeWidget,
}) => {
  const dispatch = useDispatch()

  const { isLoadingStatus, status } = useSelector(onboardingBuddySlice.selectors.getMetaData())
  const [isLoaded, setIsLoaded] = useState(false)
  const onboardingBuddy = useSelector(onboardingBuddySlice.selectors.getOnboardingBuddy())
  const newHireBuddies = useSelector(onboardingBuddySlice.selectors.getNewHireBuddies())
  const {
    settings: {
      onboardingBuddySettings: { marketplace: { enabled: buddyMarketplaceEnabled } },
    },
  } = useCurrentCompany()
  const { inPreboardingExperience } = useCurrentUser()

  useEffect(() => {
    dispatch(onboardingBuddySlice.asyncActions.assignedBuddy())
  }, [])

  // don't show loading indicator because we might not show this card at all
  if (isLoadingStatus) {
    return EmptyState
  }

  const getComponentToShow = () => {
    if (onboardingBuddy) {
      return ONBOARDING_BUDDY
    }

    if (inPreboardingExperience) {
      return null
    }

    if (!_.isEmpty(newHireBuddies)) {
      return NEW_HIRE_BUDDIES
    }
    if (buddyMarketplaceEnabled && status?.asANewHire === NEEDS_A_BUDDY_STATUS) {
      return BUDDY_MARKETPLACE
    }
    if (status?.toBecomeABuddy === ELIGIBLE_STATUS) {
      return OPT_IN_OR_OUT
    }

    return null
  }
  const componentToShow = getComponentToShow()

  if (!componentToShow) {
    return EmptyState
  }

  return (
    <Card className={classNames('HomePageWidget OnboardingBuddiesWidget', className)} cardBodyClassName='p-0'>
      <div className='OnboardingBuddiesWidget--Title'>
        <h5>{I18N('title')}</h5>
        <CdnSvg
          className='PeoplePreparingToCheer'
          src='/images/illustrations/peoplePreparingToCheer.svg'
        />
      </div>
      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        isLoaded={isLoaded && widget.h > 0}
        scrollableContentClassName='p-3'
      >
        {componentToShow === ONBOARDING_BUDDY && (
          <OnboardingBuddy
            buddy={onboardingBuddy}
            setIsLoaded={setIsLoaded}
          />
        )}
        {componentToShow === NEW_HIRE_BUDDIES && (
          <NewHireBuddies
            buddies={newHireBuddies}
            setIsLoaded={setIsLoaded}
          />
        )}
        {componentToShow === BUDDY_MARKETPLACE && (
          <SelectBuddyCard
            setIsLoaded={setIsLoaded}
            EmptyState={EmptyState}
          />
        )}
        {componentToShow === OPT_IN_OR_OUT && (
          <OptInOrOutCard
            setIsLoaded={setIsLoaded}
            EmptyState={EmptyState}
          />
        )}
      </AutoResizableScrollableSection>
    </Card>
  )
}

export default OnboardingBuddiesWidget
