import React from 'react'
import Card from 'components/common/card'
import { i18nPath } from 'utils/i18nHelpers'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { isManagersOnly } from 'utils/audience'
import GroupArticleAudienceToggleSelector from 'components/common/audience/groupArticleAudienceToggleSelector'
import RadioButton from 'components/form_fields/radioButton'
import useIsGroupPost from 'components/admin/articles/hooks/useIsGroupPost'

const NEWS_MODULE = 'news'

const I18N = i18nPath('views.admin.article_editor.audience_and_settings_editor')

const SelectAudienceCard = ({ workingCopy, updateWorkingCopy }) => {
  const { permissions } = useCurrentUser()
  const { articleType } = workingCopy
  const isGroupPost = useIsGroupPost(workingCopy)
  const isManagersSelectDisabled = isManagersOnly(articleType?.targetingRules)

  return (
    <Card className='mb-4'>
      <h5>{I18N('select_audience')}</h5>
      {isGroupPost ? (
        <GroupArticleAudienceToggleSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          translationsPath='views.admin.article_editor.audience_and_settings_editor.group_article_audience_toggle_selector'
        />
      ) : (
        <AudienceToggleSelector
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          translationsPath='views.admin.article_editor.audience_and_settings_editor.audience_toggle_selector'
          showFootnote={false}
          showAllTargetedDescription={false}
          canTargetSpecificUsers
          canTargetEntireCompany={permissions.articleManager}
          showLabel={false}
          isManagersSelectDisabled={isManagersSelectDisabled}
          moduleName={NEWS_MODULE}
        />
      )}
      {isManagersSelectDisabled && (
        <div className='alert alert-info pb-1 mt-3'>
          <label>{I18N('targeting_has_to_include_managers', { articleTypeName: articleType?.name })}</label>
        </div>
      )}
      <h6 className='mt-3'>{I18N('access')}</h6>
      <RadioButton
        id='allow-access-checkbox'
        checked={!workingCopy.restrictAccess}
        onChange={() => updateWorkingCopy({ restrictAccess: false })}
        label={I18N('allow_any_user')}
      />
      <RadioButton
        id='restrict-access-checkbox'
        checked={!!workingCopy.restrictAccess}
        onChange={() => updateWorkingCopy({ restrictAccess: true })}
        label={I18N('restrict_access')}
      />
    </Card>
  )
}

export default SelectAudienceCard
