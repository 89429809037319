import { ButtonSecondary } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import BellActive from 'components/icons/bellActive'
import BellInactive from 'components/icons/bellInactive'
import React from 'react'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.journeys.taskable_detail.toggle_notifications_button')

const ToggleNotificationsButton = ({
  taskable,
  isIntroduction = false,
}) => {
  const apiFunction = isIntroduction
    ? API.journey.introductions.toggleNotifications
    : API.journey.tasks.toggleNotifications

  const [toggleNotifications, { isLoading }] = useApi(apiFunction, {
    updateEntitySlice: true,
    toastSuccessMessage: I18N('update_success'),
  })

  const {
    allowSilenceNotifications,
    completionStatus,
    notifyViaEmail,
    notifyViaMsTeams,
    notifyViaSlack,
  } = taskable

  if (!allowSilenceNotifications || completionStatus !== 'overdue') {
    return null
  }

  const areNotificationsEnabled = notifyViaEmail || notifyViaMsTeams || notifyViaSlack

  const onToggleClick = () => {
    const enableNotifications = !areNotificationsEnabled
    toggleNotifications(taskable.id, { enableNotifications })
  }

  return (
    <ButtonSecondary
      className='ToggleNotificationsButton mr-2'
      onClick={onToggleClick}
      disabled={isLoading}
      showLoadingSpinner={isLoading}
    >
      {areNotificationsEnabled ? (
        <>
          <BellInactive className='' />
          <span>{I18N('disable')}</span>
        </>
      ) : (
        <>
          <BellActive className='' />
          <span>{I18N('enable')}</span>
        </>
      )}
    </ButtonSecondary>
  )
}

export default ToggleNotificationsButton
