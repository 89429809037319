import React from 'react'
import { useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import Stat from 'pages/admin/articles/stats/Stat'
import getCdnUrl from 'utils/getCdnUrl'
import { Bar } from 'components/common/charts/stackedBarChart'
import { safePercentage } from 'pages/admin/articles/stats/helpers'

const I18N = i18nPath('views.admin.article_stats.article_shares')

const NETWORKS = [
  {
    network: 'facebook',
    image: '/images/facebookSquare32.png',
  },
  {
    network: 'twitter',
    image: '/images/twitterSquare32.png',
  },
  {
    network: 'linkedin',
    image: '/images/linkedinSquare32.png',
  },
  {
    network: 'copy',
    image: '/images/copySquare32.png',
  },
]

const SocialShare = ({ articleStat }) => {
  const cdnDomain = useSelector(state => (state as { cdnDomain: string }).cdnDomain)
  const { socialShareCountByNetwork: shareStats } = articleStat
  const totalShares: number = Object
    .values(shareStats)
    .reduce((acc: number, val: number) => acc + val, 0) as number

  return (
    <Card cardBodyClassName='ArticleStatSocialShare' className='flex-grow-1'>
      <h5 className='mb-0 ml-1'>{I18N('title')}</h5>
      <hr />
      <div className='d-flex'>
        <div className='stats-part mr-4'>
          <Stat
            value={totalShares}
            label={I18N('total_shares', { count: totalShares })}
          />
        </div>

        <div className='chart-part'>
          {NETWORKS.map(({ network, image }) => (
            <div className='bar d-flex mb-2'>
              <img src={getCdnUrl(cdnDomain, image)} />

              <div>
                <Bar
                  tooltipText={
                    I18N(`network_shares_tooltip.${network}`, { count: shareStats[network] || 0 })
                  }
                  width={safePercentage(shareStats[network] || 0, totalShares, 0)}
                  className={network}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  )
}

export default SocialShare
