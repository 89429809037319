import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { SurveyQuestionType } from 'types/surveys/question'
import ReactSelect from 'components/common/react_select'

const I18N = i18nPath('views.admin.surveys.questions')

const ProfileFieldQuestionEditForm = ({
  form,
  question,
  updateQuestion = (changes: Partial<SurveyQuestionType>) => { },
  isDisabled = false,
}) => {
  const availableProfileFieldQuestionsHash = form?.availableProfileFieldQuestionsHash || []
  const profileField = question?.profileField

  const selectedProfileField = availableProfileFieldQuestionsHash.find(option => profileField === option.profileField)

  const onChange = (selectedOption) => {
    const newProfileField = selectedOption?.profileField
    const type = selectedOption?.type
    const title = selectedOption?.label

    updateQuestion({ profileField: newProfileField, type, title })
  }

  return (
    <div className='ProfileFieldQuestionEditForm'>
      <label className='form-label'>{I18N('select_profile_field')}</label>
      <ReactSelect
        value={selectedProfileField}
        getOptionValue={option => option.profileField}
        options={availableProfileFieldQuestionsHash}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </div>
  )
}

export default ProfileFieldQuestionEditForm
