class CookieManager {
  constructor({ name }) {
    this.name = name
    return this
  }

  /**
   * Creates a cookie with an optional value,
   * and optional days for expiration
   *
   * @param value Default to now
   * @param expireIn
   */
  createCookie({ expireIn = '' } = {}) {
    const { name } = this
    const date = new Date()
    let expires = ''
    if (expireIn) {
      date.setTime(date.getTime() + expireIn * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toString()}`
    } else {
      expires = ''
    }

    document.cookie = `${name}=${date.toString()}${expires}; path=/`
  }

  readCookie() {
    const { name } = this
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)

    if (parts.length === 2) return parts.pop().split(';').shift()

    return null
  }

  deleteCookie() {
    const { name } = this
    document.cookie = `${name}=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

export default CookieManager
