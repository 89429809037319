import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.surveys.questions.stats')

const chevronDownIconPath = '/images/chevronDownOutline.svg'
const chevronUpIconPath = '/images/chevronUpOutline.svg'

const AdditionalFeedbackSection = ({
  question,
  goToDetailPage,
}) => {
  const {
    stats = {}, allowAdditionalText = false,
  } = question
  const { additionalFeedback = [], totalAnswersWithAdditionalText = 0 } = stats

  const [isExpanded, setIsExpanded] = useState(false)

  if (!allowAdditionalText || !additionalFeedback.length) {
    return null
  }

  return (
    <div className='AdditionalFeedbackSection'>
      <span
        className='link d-flex align-items-center mt-3'
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {I18N('additional_feedback')}
        <CdnSvg className='ml-1' src={isExpanded ? chevronUpIconPath : chevronDownIconPath} />
      </span>
      {isExpanded && (
        <div>
          <SmartTable
            className=' white-bg-table'
            data={additionalFeedback}
            columns={[
              {
                header: I18N('name'),
                accessor: d => (
                  <UserLink
                    user={d?.user}
                    customClassName='font-weight-500 text-primary-link'
                    unknownUserText={I18NCommon('anonymous_user')}
                  />
                ),
                style: { width: '20%' },
              },
              {
                header: I18N('answer'),
                accessor: d => <span className='ws-pre-wrap'>{d?.formattedValue}</span>,
              },
              {
                header: I18N('additional_feedback'),
                accessor: d => <span className='ws-pre-wrap'>{d?.additionalText}</span>,
              },
            ]}
          />
          {totalAnswersWithAdditionalText > additionalFeedback.length && (
            <Link
              onClick={() => goToDetailPage()}
              className='mt-2'
            >
              {I18N('see_more')}
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default AdditionalFeedbackSection
