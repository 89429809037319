import { useLocation } from 'react-router-dom'


interface UsePathReusingQueryParamsProps {
  path: string
  paramsToReuse: string[],
  otherQueryParams?: Record<string, string>
}

const usePathReusingQueryParams = ({ path, paramsToReuse, otherQueryParams = {} }: UsePathReusingQueryParamsProps) => {
  const location = useLocation()

  const urlSearchParams = new URLSearchParams(location.search)
  const newSearchParams = new URLSearchParams()

  paramsToReuse.forEach((key) => {
    const val = urlSearchParams.get(key)

    if (val) {
      newSearchParams.set(key, val)
    }
  })

  Object.keys(otherQueryParams).forEach((key) => {
    newSearchParams.set(key, otherQueryParams[key])
  })

  return `${path}?${newSearchParams.toString()}`
}

export default usePathReusingQueryParams
