import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import useQueryParams from 'components/common/hooks/useQueryParams'
import ReactSelect from 'components/common/react_select'
import { isEmpty } from 'lodash'
import searchSlice from 'redux/slices/search'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.search.search_results.filters')

const OneDriveFilter = ({ updateHistory }) => {
  const dispatch = useDispatch()
  const { query, filters: rawFilters = '{}' } = useQueryParams()
  const filters = useMemo(() => JSON.parse(rawFilters), [rawFilters])
  const { types = '' } = filters

  const oneDriveTypesOptions = [
    { label: I18N('type_word'), value: ['doc*'] },
    { label: I18N('type_excel'), value: ['xls*'] },
    { label: I18N('type_powerpoint'), value: ['ppt*'] },
    { label: I18N('type_pdf'), value: ['pdf'] },
    { label: I18N('type_photo'), value: ['gif', 'jpg', 'jpeg', 'png', 'svg', 'webp', 'ico', 'bmp', 'heic', 'heif'] },
    { label: I18N('type_one_note'), value: ['onetoc*'] },
    { label: I18N('type_vision_drawing'), value: ['vsd*'] },
  ]

  useEffect(() => {
    dispatch(searchSlice.actions.reset({ searchLocation: 'pageFiltered' }))

    dispatch(searchSlice.asyncActions.searchOneDrive({
      query,
      searchOneDrive: true,
      searchLocation: 'pageFiltered',
      filters,
      track: true,
    }))
  }, [query, filters])

  const handleSearch = (newFilters, type) => {
    const updatedFilters = { ...filters, ...newFilters }

    if (!newFilters) {
      delete updatedFilters[type]
    }

    updateHistory(updatedFilters)
  }

  const handleFileTypeChange = (option) => {
    const filters = !isEmpty(option)
      ? {
        types: option.value,
      }
      : null
    handleSearch(filters, 'types')
  }

  return (
    <div className='OneDriveFilter MultipleRefinedFilters px-3'>
      <div className='pb-5'>
        <div className='text-small text-secondary mb-2 font-weight-500'>
          {I18N('drive_file_type_filter')}
        </div>
        <ReactSelect
          isClearable
          value={oneDriveTypesOptions.filter(({ value }) => _.isEqual(value, types))}
          placeholder={(
            <div className='text-secondary'>
              {I18n.t('views.common.react_select.select_or_type')}
            </div>
          )}
          onChange={handleFileTypeChange}
          options={oneDriveTypesOptions}
        />
      </div>
    </div>
  )
}

export default OneDriveFilter
