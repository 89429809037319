import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'
import arrayMove from 'array-move'
import SortableTable from 'components/common/tables/sortableTable'

const I18N = i18nPath('views.admin.article_type_list')
const I18NCommon = i18nPath('views.common')

const columns = [
  {
    header: I18N('name'),
    accessor: articleType => (
      <Link to={`/admin/article_types/${articleType.id}`}>
        <span>{articleType.name}</span>
      </Link>
    ),
    style: { width: '25%' },
  },
  {
    header: I18NCommon('active'),
    accessor: articleType => I18NCommon(articleType.active ? 'yes' : 'no'),
    style: { width: '15%' },
  },
  {
    header: I18N('group_article_type'),
    accessor: articleType => I18NCommon(articleType.groupArticleType ? 'yes' : 'no'),
    style: { width: '15%' },
  },
  {
    header: I18N('description'),
    accessor: articleType => articleType.description,
    style: { width: '20%' },
  },
  {
    header: I18N('image'),
    accessor: articleType => <img style={{ height: '45px' }} src={articleType.imageUrl} />,
    style: { width: '10%' },
  },
  {
    header: I18N('created'),
    accessor: articleType => i18nMoment(articleType.createdAt).format('ll'),
    style: { width: '15%' },
  },
]

// One nice feature of a functional component is that it becomes a lot easier to unit test
const ArticleTypeListPage = () => {
  const [articleTypes, setArticleTypes] = useState([])
  const { isLoading, isNotFound } = useFetch(() => API.admin.articleTypes.fetchAll({ active: 'all' }), [], {
    onSuccess: response => setArticleTypes(response),
  })

  const [updateArticleType, { isLoading: isSaving }] = useApi(API.admin.articleTypes.update)
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setArticleTypes(arrayMove(articleTypes, oldIndex, newIndex))

    const newArticleType = { ...articleTypes[oldIndex], orderPosition: newIndex }
    updateArticleType(newArticleType)
  }

  return (
    <div className='ArticleTypeListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3>{I18N('article_types')}</h3>
        <div className='AdminButtonContainer'>
          <Link to='/admin/article_types/new'>
            <Button>
              {I18N('create_new_article_type')}
            </Button>
          </Link>
        </div>
      </header>

      <main className='AdminContent'>
        <LoadingContainer isLoading={isLoading} isNotFound={isNotFound} useCirclesLoadingIndicator>
          <div className='white-bg-table row mt-4 mb-4'>
            <SortableTable
              data={articleTypes}
              columns={columns}
              onSortEnd={onSortEnd}
              isSaving={isSaving}
            />
          </div>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default ArticleTypeListPage
