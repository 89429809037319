import useFetch from 'components/common/hooks/useFetch'
import React, { useMemo } from 'react'
import API from 'services/api'
import CarouselCards from 'components/home/widgets/carousel_cards/carouselCards'

const PreboardingCarouselCardsWidget = ({ widget, EmptyState = null, isEditing }) => {
  const { data: fetchedCarouselCards } = useFetch(
    () => API.journey.preboarding.carouselCards.fetchAll(widget.id),
    [widget.forceRefreshAt])

  const editingCarouselCards = useMemo(
    () => widget.widgetRecordAssociations?.map(({ record }) => record),
    [widget.widgetRecordAssociations]
  )

  const carouselCards = isEditing ? editingCarouselCards : fetchedCarouselCards
  const { settings: { fullWidth } } = widget

  return (
    <CarouselCards
      fullWidth={fullWidth}
      EmptyState={EmptyState}
      isEditing={isEditing}
      carouselCards={carouselCards}
    />
  )
}

export default PreboardingCarouselCardsWidget
