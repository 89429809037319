import React, { useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import useQueryParams from 'components/common/hooks/useQueryParams'

import TaskDetail from 'components/journeys/tasks/taskDetail'
import taskSlice from 'redux/slices/journeys/tasks'
import Error404 from 'components/errors/404'
import LoadingSpinner from 'components/common/loadingSpinner'

import BackToMyTasksButton from 'components/journeys/taskables/backToMyTasksButton'
import CdnSvg from 'components/common/cdnSvg'
import BuddyAssignmentDetail from 'components/journeys/tasks/buddyAssignmentDetail'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'
import removeQueryParam from 'utils/removeQueryParam'

const circledCheckIconPath = '/images/circledCheckIcon.svg'

const TaskDetailPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { taskId } = useParams()

  const { markAsCompleted, disableNotifications } = useQueryParams()

  const { isLoading, isNotFound } = useSelector(taskSlice.selectors.getMetaData())
  const task = useSelector(taskSlice.selectors.getTask(taskId))
  const isBuddyAssignmentTask = task?.buddyAssignmentTask

  const permittedActions = task?.permittedActions

  const [toggleNotifications] = useApi(API.journey.tasks.toggleNotifications, {
    updateEntitySlice: true,
    toastSuccessMessage: I18NCommon('task_notifications_updated'),
    onSuccess: () => removeQueryParam(history, 'disable_notifications'),
  })

  useEffect(() => {
    dispatch(taskSlice.asyncActions.fetchTask(taskId))
  }, [])

  useEffect(() => {
    // If the task is loaded
    if (task?.id === taskId) {
      // If the task is not completed, send a request to complete it
      if (permittedActions?.complete && markAsCompleted === 'true' && !task?.completed) {
        if (task.surveyTask) {
          history.push(task.completionPath)
        } else {
          dispatch(taskSlice.asyncActions.completeTask(taskId))
          history.replace({ pathname: location.pathname, search: '' })
        }
      }

      if (disableNotifications === 'true') {
        toggleNotifications(taskId, { enableNotifications: false })
      }
    }
  }, [task?.id])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isNotFound) {
    return <Error404 />
  }

  return (
    <main className='TaskDetailPage'>
      <div className='col-12 offset-md-2 col-md-8 mt-4'>
        <BackToMyTasksButton />
        <h2 className='mb-3'>
          <CdnSvg src={circledCheckIconPath} className='mr-2' />
          {task?.name}
        </h2>
        {isBuddyAssignmentTask ? (
          <BuddyAssignmentDetail task={task} className='p-4 mb-4' />
        ) : (
          <TaskDetail task={task} className='p-4 mb-4' />
        )}
      </div>
    </main>
  )
}

export default TaskDetailPage
