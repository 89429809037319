import React, { useEffect } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import ShoutoutCardProfile from 'components/shoutouts/shoutoutCardProfile'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroller from 'components/common/infiniteScroller'
import shoutoutsSlice from 'redux/slices/shoutouts'
import SendShoutoutButton from 'components/shoutouts/sendShoutoutButton'
import classNames from 'classnames'


const I18N = i18nPath('views.profile')

const EmptyShoutouts = ({
  className,
  user,
}) => {
  const currentUser = useCurrentUser()
  const isCurrentUser = user.id === currentUser.id
  const preferredName = user.preferredName

  if (isCurrentUser) {
    return <div className=' w-100 text-center text-small text-secondary mt-6 pl-2 '>{I18N('you_dont_have_shoutouts')}</div>
  }

  return (
    <div className={classNames('w-100 mt-6 pl-2text-center', className)}>
      <div className='text-small text-secondary text-center mb-3'>{I18N('shoutouts_empty', { preferredName })}</div>
      <div className='text-center'>
        <SendShoutoutButton user={user} />
      </div>
    </div>
  )
}

const Shoutouts = ({ user }) => {
  const dispatch = useDispatch()
  const shoutouts = useSelector(shoutoutsSlice.selectors.getShoutouts())
  const { isLoading, queryParams } = useSelector(shoutoutsSlice.selectors.getMetaData())
  const { page, totalPages } = queryParams
  const areShoutoutsEmpty = !isLoading && _.isEmpty(shoutouts)

  const hasMoreShoutouts = () => page < totalPages

  const fetchShoutouts = (params = {
    page: 1, perPage: 5, filter: 'received', userId: user.id,
  }) => {
    dispatch(shoutoutsSlice.asyncActions.fetchShoutouts(params))
  }

  const fetchMoreShoutouts = () => {
    fetchShoutouts({
      page: queryParams.page + 1, perPage: 5, filter: 'received', userId: user.id,
    })
  }

  useEffect(() => {
    dispatch(shoutoutsSlice.asyncActions.reset)
    dispatch(shoutoutsSlice.actions.clear())
    fetchShoutouts()

    return () => {
      dispatch(shoutoutsSlice.asyncActions.reset)
      dispatch(shoutoutsSlice.actions.clear())
    }
  }, [])

  return (
    <>
      <InfiniteScroller
        isFetching={isLoading}
        onFetch={fetchMoreShoutouts}
        hasMoreContent={hasMoreShoutouts}
        className='d-flex flex-column align-items-center w-100'
      >
        {shoutouts.map(shoutout => (
          <div className='mt-3 w-100' key={`shoutout-${shoutout.id}`}>
            <ShoutoutCardProfile
              key={`shoutout-${shoutout.id}`}
              shoutout={shoutout}
              user={user}
            />
          </div>
        ))}
      </InfiniteScroller>

      <EmptyShoutouts
        className={classNames(!areShoutoutsEmpty && 'd-none')}
        user={user}
      />
    </>
  )
}

export default Shoutouts
