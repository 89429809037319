import React, { useRef } from 'react'

import Card from 'components/common/card'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import MoonOrSunIcon from 'components/common/moonOrSunIcon'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import currentTimezone from 'utils/currentTimezone'
import useForceRerenderAtInterval from 'components/common/hooks/useForceRerenderAtInterval'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import classNames from 'classnames'
import Slider from 'components/common/slider'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'

const I18N = i18nPath('views.home')

const MIN_TIMEZONE_ITEM_WIDTH = 220
const MAX_SLIDER_HEIGHT = 110
const MAX_TIMEZONES_TO_SHOW = 4

const TimezonesWidget = ({ widget, resizeWidget }) => {
  const cardRef = useRef<HTMLDivElement>(null)
  const { data: timezones, isLoading, isLoaded } = useFetch(API.timezones.fetchAll)

  useForceRerenderAtInterval()

  const sortedTimezones = timezones?.sort((first, second) => {
    const firstOffset = i18nMoment().tz(first.timezone).utcOffset()
    const secondOffset = i18nMoment().tz(second.timezone).utcOffset()

    return secondOffset - firstOffset
  }) || []

  // setting default widths and heights to avoid TS complaining about missing ref.current
  // ref.current should always be defined
  const [width, height] = [cardRef.current?.clientWidth || 600, cardRef.current?.clientHeight || 600]
  const showSlider = height < MAX_SLIDER_HEIGHT

  const timezonesToShow = Math.min(
    Math.floor(width / MIN_TIMEZONE_ITEM_WIDTH),
    isLoading ? MAX_TIMEZONES_TO_SHOW : sortedTimezones.length + 1,
    MAX_TIMEZONES_TO_SHOW
  )

  return (
    <Card
      className='TimezonesWidget HomePageWidget'
      cardBodyClassName='p-0'
      cardRef={cardRef}
    >
      <div className={classNames(showSlider ? 'flex-grow-1 d-flex' : 'h-100 d-flex flex-column')}>
        <TimezoneListItem
          timezone={currentTimezone()}
          location={I18N('your_time')}
          isMine
          className={showSlider ? 'border-radius-left h-100' : 'border-radius-top py-3'}
          style={showSlider ? { width: `${100 / timezonesToShow}%` } : {}}
        />
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Timezones
            timezones={sortedTimezones}
            showSlider={showSlider}
            timezonesToShow={timezonesToShow}
            widget={widget}
            resizeWidget={resizeWidget}
            isLoaded={isLoaded}
          />
        )}
      </div>
    </Card>
  )
}

const Timezones = ({
  timezones, showSlider, timezonesToShow, widget, resizeWidget, isLoaded,
}) => {
  if (!showSlider) {
    return (
      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        isLoaded={isLoaded}
      >
        {timezones.map((tz, ind) => (
          <TimezoneListItem
            key={`timezone-item-${tz.id}`}
            timezone={tz.timezone}
            location={tz.location}
            showDivider={ind !== (timezones.length - 1)}
            className='py-3'
          />
        ))}
      </AutoResizableScrollableSection>
    )
  }

  const slidesToShow = timezonesToShow - 1

  if (slidesToShow <= 0) return null

  return (
    <div style={{ width: `${slidesToShow * (100 / timezonesToShow)}%` }} className='h-100 py-2'>
      <Slider
        settings={{
          slidesToShow,
          dots: false,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 1000,
          lazyLoad: 'progressive',
          pauseOnFocus: true,
        }}
      >
        {timezones.map(tz => (
          <TimezoneListItem
            key={`timezone-item-${tz.id}`}
            timezone={tz.timezone}
            location={tz.location}
            className='bordered-right h-100 my-3'
          />
        ))}
      </Slider>
    </div>
  )
}

const TimezoneListItem = ({
  location,
  timezone,
  isMine = false,
  showDivider = false,
  className = '',
  style = {},
}) => {
  const HTime = isMine ? 'h4' : 'h5'
  const dateFormat = i18nMoment().locale() === 'en' ? 'ddd, MMM D' : 'll'

  return (
    <>
      <div
        className={
          classNames(
            'px-3 d-flex flex-column justify-content-center',
            isMine && 'bg-timezone-highlight',
            className
          )
        }
        style={style}
      >
        <div className='w-100 d-flex justify-content-between align-items-center'>
          <h6 className='mb-0 break-word truncate-text-at-2-lines'>{location}</h6>
          <div className='flex-shrink-0'>
            <HTime className={classNames('mb-0 text-right', !isMine && 'font-weight-500')}>
              <MoonOrSunIcon timezone={timezone} />
              &nbsp;{i18nMoment().tz(timezone).format('LT')}
            </HTime>
            <div className='text-secondary text-small text-right'>
              {i18nMoment().tz(timezone).format(dateFormat)}
            </div>
          </div>
        </div>
      </div>
      {showDivider && <hr className='my-0 mx-3' />}
    </>
  )
}

export default TimezonesWidget
