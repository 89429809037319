import build from 'redux-object'
import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api'
import appSignal from 'services/appSignal'
import entitySlice from 'redux/slices/entities'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

export const initialState = {
  groupId: null,
  followerIds: [],
  estimatedCount: 0,
  meta: {
    ...defaultMeta,
    queryParams: defaultPaginationParams,
  },
}

const groupFollowersSlice = createSlice({
  name: 'groupFollowers',
  initialState,
  reducers: {
    ...defaultActions,
    setGroupId(state, action) {
      state.groupId = action.payload
    },
    setFollowerIds(state, action) {
      state.followerIds = action.payload
    },
    addFollowerIds(state, action) {
      state.followerIds = [...new Set([...state.followerIds, ...action.payload])]
    },
    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },
    clear() {
      return initialState
    },
    setEstimatedCount(state, action) {
      state.estimatedCount = action.payload
    },
  },
})

const asyncActions = {
  fetchAll: (groupId, params = {}) => async (dispatch) => {
    dispatch(groupFollowersSlice.actions.isLoading(true))

    try {
      const response = await API.groups.searchFollowers(groupId, params)
      const followerIds = response.data.data.map(follower => follower.id)

      dispatch(entitySlice.actions.add({ data: response.data }))

      if (params.page > 1) {
        dispatch(groupFollowersSlice.actions.addFollowerIds(followerIds))
      } else {
        dispatch(groupFollowersSlice.actions.setFollowerIds(followerIds))
      }

      dispatch(groupFollowersSlice.actions.setGroupId(groupId))
      dispatch(groupFollowersSlice.actions.setQueryParams(queryParamsFromHeaders(response)))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    } finally {
      dispatch(groupFollowersSlice.actions.isLoading(false))
    }
  },
  estimateCount: (groupId, userIds, groupIds) => async (dispatch) => {
    try {
      const response = await API.groups.follows.estimateCount(groupId, userIds, groupIds)
      dispatch(groupFollowersSlice.actions.setEstimatedCount(response.data.count))
    } catch (e) {
      appSignal.sendErrorUnlessClearyBackendError(e)
    }
  },
}

const selectors = {
  getMetaData: () => state => state.groupFollowers.meta,

  getEstimatedCount: () => state => state.groupFollowers.estimatedCount || 0,

  getFollowers: groupId => (state) => {
    if (groupId !== state.groupFollowers.groupId) return []
    return state.groupFollowers.followerIds.map(id => build(state.entities, 'simpleUser', id)).filter(Boolean)
  },
}

export default {
  ...groupFollowersSlice,
  asyncActions,
  selectors,
}
