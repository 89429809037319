import React from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import classNames from 'classnames'
import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import Modal from 'components/common/modal'
import pageSlice from 'redux/slices/pages'
import { useDispatch, useSelector } from 'react-redux'
import PageType from 'types/page'

const I18N = i18nPath('views.pages.actions_dropdown')

interface ArchivePageModalProps {
  className?: string
  visible: boolean
  toggle: () => void
  page: PageType
}

const ArchivePageModal = ({
  className,
  toggle,
  page,
  visible,
}: ArchivePageModalProps) => {
  const dispatch = useDispatch()

  const { isSaving } = useSelector(pageSlice.selectors.getPagesMetadata())

  const onArchivePage = () => {
    const params = {
      id: page.id,
      archivedAt: new Date().toISOString(),
    }

    const onSuccess = () => {
      toggle()
    }

    dispatch(pageSlice.asyncActions.setExpiration(params, onSuccess, true))
  }

  return (
    <Modal className={classNames('ArchivePageModal', className)} visible={visible} toggle={toggle}>
      <div className='px-4 text-center mb-2'>
        <h4 className='text-center font-weight-600'>{I18N('archive_page')}</h4>
        <span className='text-secondary'>{I18N('archive_page_subtext')}</span>
        <div className='d-flex justify-content-center align-items-center mt-4'>
          <CancelButton onClick={toggle} className='mr-3 text-secondary font-weight-600'>
            {I18NCommon('cancel')}
          </CancelButton>
          <ButtonNarrow
            className='ArchiveButton'
            onClick={onArchivePage}
            showLoadingSpinner={isSaving}
            disabled={isSaving}
          >
            {I18N('archive_page')}
          </ButtonNarrow>
        </div>
      </div>
    </Modal>
  )
}

export default ArchivePageModal
