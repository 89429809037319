import entitySlice from 'redux/slices/entities'
import build from 'redux-object'
import API from 'services/api'
import appSignal from 'services/appSignal'

import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { getResponseOrThrow } from 'utils/errorHandling'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { ReduxState } from 'redux/redux'

interface PageTemplateCategoryMetaType {
  hasLoadedAllTemplateCategories: boolean
}

export interface pageTemplateCategoriesState {
  pageTemplateCategoryIds: string[]
  meta: DefaultMetaType & PageTemplateCategoryMetaType
}

export const initialState = {
  pageTemplateCategoryIds: [],
  isEditing: false,
  meta: {
    ...defaultMeta,
    hasLoadedAllTemplateCategories: false,
  },
}

const pageTemplateCategorySlice = createSlice({
  name: 'pageTemplateCategories',
  initialState,
  reducers: {
    ...defaultActions,

    hasLoadedAllTemplateCategories(state, action) {
      state.meta.hasLoadedAllTemplateCategories = action.payload
    },

    setPageTemplateCategoryIds(state, action) {
      state.pageTemplateCategoryIds = action.payload
    },

    isEditing(state, action) {
      state.isEditing = action.payload
    },
  },
})

const asyncActions = {
  fetchAll: () => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(pageTemplateCategorySlice.actions.isLoading(true))
      dispatch(pageTemplateCategorySlice.actions.hasLoadedAllTemplateCategories(false))

      const response = await API.pageTemplateCategories.fetchAll()
      const pageTemplateCategoryIds = response.data.data.map(pageTemplateCategory => pageTemplateCategory.id)

      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(pageTemplateCategorySlice.actions.setPageTemplateCategoryIds(pageTemplateCategoryIds))
      dispatch(pageTemplateCategorySlice.actions.hasLoadedAllTemplateCategories(true))
    } catch (error) {
      appSignal.sendErrorUnlessClearyBackendError(error)
      dispatch(pageTemplateCategorySlice.actions.setError(getResponseOrThrow(error)))
    } finally {
      dispatch(pageTemplateCategorySlice.actions.isLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.pageTemplateCategories.meta,
  getPageTemplateCategories: () => (state: ReduxState) => state.pageTemplateCategories.pageTemplateCategoryIds.map(
    id => build(state.entities, 'pageTemplateCategory', id)
  ) || [],
}

export default {
  ...pageTemplateCategorySlice,
  selectors,
  asyncActions,
}
