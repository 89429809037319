import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import AdminAlertsTable from 'components/admin/admin_alerts/adminAlertsTable'
import PermissionsTable from 'components/admin/users/permissionsTable'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.admin.permissions_and_alerts')

const PermissionsAndAlertsPage = () => {
  const { selectedTab } = useQueryParams()

  return (
    <>
      <header className='AdminHeader'>
        <h3 className='mb-1'>{I18N('permissions_and_alerts')}</h3>
      </header>
      <main className='AdminContent'>
        <HistoryChangingPillTabSelector
          className='mb-3'
          tabs={[
            {
              id: 'permissions',
              text: I18N('permissions'),
            },
            {
              id: 'alerts',
              text: I18N('alerts'),
            },
          ]}
        />
        {selectedTab === 'permissions' && <PermissionsTable />}
        {selectedTab === 'alerts' && <AdminAlertsTable />}
      </main>
    </>
  )
}

export default PermissionsAndAlertsPage
