import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import EditTitleSetting from 'components/admin/layout/layout_collection_sidebar/common/editTitleSetting'
import API from 'services/api'
import ApiListMultiSelect from 'components/common/apiListMultiSelect'
import useWidgetRecordAssociations from 'hooks/layout/useWidgetRecordAssociations'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.preboarding_feed')

const PreboardingFeedWidgetSettingsSidebar = ({ widget, updateWidget }) => {
  const {
    records: articles,
    onCreate,
    onDelete,
  } = useWidgetRecordAssociations({
    widget,
    updateWidget,
    recordType: 'Article',
  })

  return (
    <div className='PreboardingFeedWidgetSettingsSidebar'>
      <h5>{I18N('title')}</h5>

      <EditTitleSetting widget={widget} updateWidget={updateWidget} />

      <hr />

      <ApiListMultiSelect
        items={articles}
        isLoading={false}
        isSaving={false}
        onCreate={onCreate}
        onDelete={onDelete}
        labelProp='title'
        search={query => API.admin.articles.fetchAll({ q: query })}
        addButtonText={I18N('add_article')}
      />
    </div>
  )
}

export default PreboardingFeedWidgetSettingsSidebar
