import React from 'react'
import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.users')

const LegalAcknowledgementsSection = ({
  user = null,
  initialIsOpen = false,
}) => {
  const legalNoticesAcknowledgedAt = user.legalNoticesAcknowledgedAt ? i18nMoment(user.legalNoticesAcknowledgedAt).format('LLL') : ''

  return (
    <CollapsibleCard
      title={I18N('sections.legal_acknowledgements')}
      initialIsOpen={initialIsOpen}
      className='mt-3'
    >
      <div className='LegalAcknowledgementsSection mt-3'>
        <div className='form-group mt-4'>
          <label>{I18N('legal_notices_acknowledged_at')}</label>
          <input
            disabled
            value={legalNoticesAcknowledgedAt}
          />
        </div>
      </div>
    </CollapsibleCard>
  )
}

export default LegalAcknowledgementsSection
