import React from 'react'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import ReactSelect, { Creatable } from 'components/common/react_select'

const FormSelectField = ({
  fieldName = undefined,
  label = null,
  isRequired = undefined,
  footNote = null,
  currentValue = undefined,
  onChange = (option) => { },
  className = '',
  placeholder = '',
  options = [],
  getOptionValue = item => item.value,
  getOptionLabel = item => item.label,
  isCreatable = false,
  isClearable = true,
  isMulti = false,
  ...props
}) => {
  const Component = isCreatable ? Creatable : ReactSelect

  return (
    <LabeledFormFieldContainer
      isRequired={isRequired}
      footNote={footNote}
      className={className}
      label={label || fieldName}
    >
      <Component
        name={fieldName}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        value={currentValue}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isClearable={isClearable}
        isMulti={isMulti}
        {...props}
      />
    </LabeledFormFieldContainer>
  )
}


export default FormSelectField
