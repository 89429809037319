import React from 'react'
import { useSelector } from 'react-redux'
import pageSlice from 'redux/slices/pages'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Error404 from 'components/errors/404'
import PageCoverImage from 'components/pages/pageCoverImage'
import FeedbackCounter from 'components/feedback/feedbackCounter'
import UpdaterAndTimestamp from 'components/pages/updaterAndTimestamp'
import TableOfContents from 'components/pages/tableOfContents'
import RichTextView from 'components/common/richTextView'
import classNames from 'classnames'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import LegalNoticesFooter from 'components/common/legalNoticesFooter'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { encloseInSearchHighlights } from 'components/common/utils'
import DangerousHTML from 'components/common/dangerousHTML'
import useScrollToElement from 'components/common/hooks/useScrollToElement'
import FAQsList from 'components/pages/faqs/faqsList'

const PageContent = ({ page }) => {
  const { isPreviewing, searchHighlights } = useQueryParams()
  const { isLoading, isLoadingAllPages, isNotFound } = useSelector(pageSlice.selectors.getPagesMetadata())
  const { isLoadingAllWorkspaces } = useSelector(pageWorkspaceSlice.selectors.getMetaData())
  const canEditPage = page?.permittedActions?.edit
  const { settings } = useCurrentCompany()
  const showFullWidth = isPreviewing ? page.draftShowFullWidth : page.showFullWidth
  const title = isPreviewing ? page.draftTitle : page.title
  const contentRichTextKey = isPreviewing ? 'draftContent' : 'content'

  useScrollToElement('.searchHighlight', { dependencies: [page] })

  if (isLoading || isLoadingAllPages || isLoadingAllWorkspaces) {
    return <CirclesLoadingIndicator className='pt-4' />
  }

  if (isNotFound) return <Error404 />

  if (!isPreviewing) {
    page.content = encloseInSearchHighlights(page.content, searchHighlights)
  }

  return (
    <div className='PageContent flex-grow-1 overflow-y-auto bg-white'>
      <PageCoverImage page={page} showDraftColumns={isPreviewing} />
      <div className='d-flex justify-content-center px-4 pt-3 pb-4'>
        {page.feedbackEnabled && (
          <FeedbackCounter
            feedbackable={page}
            feedbackableType='pages'
            classname='mr-2'
          />
        )}
        <div className={classNames('flex-grow-1', showFullWidth ? 'mw-100' : 'mw-page-narrow')}>
          <h1>
            <DangerousHTML options={{ ALLOWED_TAGS: ['span'] }}>
              {encloseInSearchHighlights(title, searchHighlights)}
            </DangerousHTML>
          </h1>
          <UpdaterAndTimestamp
            page={page}
            canEditPage={canEditPage}
            isEditing={false}
            areDraftChangesUnpublished={page.hasUnpublishedChanges}
          />
          {page.showTableOfContents && (
            <TableOfContents
              page={page}
            />
          )}
          <RichTextView
            record={page}
            recordType='page'
            richTextKey={contentRichTextKey}
          />

          {page.showFaqs && <FAQsList page={page} />}
          {settings.legalNotices.contentFooter.enabled && <LegalNoticesFooter />}
        </div>
      </div>
    </div>
  )
}

export default PageContent
