import React, { useState } from 'react'

import { Button, ButtonSecondary } from 'components/common/buttons'
import Modal from 'components/common/modal'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { Creatable } from 'components/common/react_select'

const I18N = i18nPath('components.admin.send_preview_email')

const SendPreviewEmailButtonAndModal = ({
  areChangesPresent, isSending, onSend, objectName, className, buttonSecondary = false, buttonLabel = I18N('send'),
}) => {
  const [showPreviewEmailModal, setShowPreviewEmailModal] = useState(false)

  return (
    <>
      {buttonSecondary ? (
        <ButtonSecondary className={className} onClick={() => setShowPreviewEmailModal(!showPreviewEmailModal)}>
          {buttonLabel}
        </ButtonSecondary>
      ) : (
        <Button className={className} onClick={() => setShowPreviewEmailModal(!showPreviewEmailModal)}>
          {buttonLabel}
        </Button>
      )}
      <SendPreviewEmailModal
        visible={showPreviewEmailModal}
        closeModal={() => setShowPreviewEmailModal(false)}
        onSend={onSend}
        isSending={isSending}
        areChangesPresent={areChangesPresent}
        objectName={objectName}
        buttonLabel={buttonLabel}
      />
    </>
  )
}

export default SendPreviewEmailButtonAndModal

export const SendPreviewEmailModal = ({
  visible,
  closeModal,
  onSend,
  isSending,
  areChangesPresent,
  objectName,
  buttonLabel = I18N('send'),
}) => {
  const currentUser = useCurrentUser()
  const isClearyAdmin = currentUser.permissions.clearyAdmin

  const [selectedEmployees, setSelectedEmployees] = useState([currentUser])
  const [isSelectingEmails, setIsSelectingEmails] = useState(false)
  const [selectedEmails, setSelectedEmails] = useState([])

  const sendPreviewEmail = () => {
    const userIds = selectedEmployees.map(user => user.id)
    onSend(userIds, closeModal)
  }

  const sendPreviewEmailToEmails = () => {
    onSend(selectedEmails, closeModal)
  }

  return (
    <Modal
      visible={visible}
      toggle={closeModal}
    >
      <div>
        <h3>{buttonLabel}</h3>

        {areChangesPresent && (
          <div className='alert alert-danger'>
            {I18N('unsaved_changes_warning', { object: objectName })}
            <br />
            {I18N('unsaved_changes_action')}
          </div>
        )}

        <p>{I18N('to_users_text')}</p>

        {isSelectingEmails ? (
          <>
            <Creatable
              className=''
              value={selectedEmails.map(email => ({ label: email, value: email }))}
              onChange={options => setSelectedEmails(options.map(option => option.value))}
              isMulti
              isClearable
            />
            <div className='link-color pointer mb-3' onClick={() => setIsSelectingEmails(false)}>
              {I18N('select_users')}
            </div>
          </>
        ) : (
          <>
            <EmployeeSearch
              className={isClearyAdmin ? 'mb-0' : 'mb-3'}
              onChange={setSelectedEmployees}
              isMulti
              selectedEmployees={selectedEmployees}
            />
            {isClearyAdmin && (
              <div className='link-color pointer mb-3' onClick={() => setIsSelectingEmails(true)}>
                {I18N('select_emails')}
              </div>
            )}
          </>
        )}
        <Button
          className='d-block mx-auto'
          onClick={isSelectingEmails ? sendPreviewEmailToEmails : sendPreviewEmail}
          disabled={isSending}
          dataTestId='send-preview-email-submit-button'
        >
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  )
}

