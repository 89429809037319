import { createSlice } from '@reduxjs/toolkit'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { ReduxState } from 'redux/redux'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import { WorkspaceType } from 'types/chat/workspace'

const I18N = i18nPath('views.integrations_page')

export type ItAdminState = {
  data: {
    teams?: WorkspaceType[]
    slacks?: WorkspaceType[]
    slackInstallUrl?: string
    [key: string]: any
  }
  mergedevLinkToken?: string
  confluenceSpaces: {
    value: string
    label: string
  }[]
  meta: DefaultMetaType & {
    isSavingSessionConfiguration: boolean
    isConfiguringSSO: boolean
    isConfiguringSAML: boolean
    isFetchingMergedevLinkToken: boolean
    isFetchingConfluenceSpaces: boolean
    confirmingTeamsSdkInstalled: Record<string, boolean>
    isUninstalling: {
      greenhouse: boolean
      jobvite: boolean
      lever: boolean
      slack: boolean
      microsoftGraph: boolean
      google_auth: boolean
      microsoft_auth: boolean
      saml_auth: boolean
    }
  },
}

export const initialState: ItAdminState = {
  data: {},
  mergedevLinkToken: undefined,
  confluenceSpaces: [],
  meta: {
    ...defaultMeta,
    isSavingSessionConfiguration: false,
    confirmingTeamsSdkInstalled: {},
    isUninstalling: {
      greenhouse: false,
      jobvite: false,
      lever: false,
      slack: false,
      microsoftGraph: false,
      google_auth: false,
      microsoft_auth: false,
      saml_auth: false,
    },
    isConfiguringSSO: false,
    isConfiguringSAML: false,
    isFetchingMergedevLinkToken: false,
    isFetchingConfluenceSpaces: false,
  },
}

const itAdminSlice = createSlice({
  name: 'itAdmin',
  initialState,
  reducers: {
    ...defaultActions,

    setData(state, action) {
      state.data = action.payload
    },

    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },

    setMergedevLinkToken(state, action) {
      state.mergedevLinkToken = action.payload
    },

    setConfluenceSpaces(state, action) {
      state.confluenceSpaces = action.payload
    },

    isFetchingMergedevLinkToken(state, action) {
      state.meta.isFetchingMergedevLinkToken = action.payload
    },

    isFetchingConfluenceSpaces(state, action) {
      state.meta.isFetchingConfluenceSpaces = action.payload
    },

    isUninstalling(state, action) {
      state.meta.isUninstalling[_.camelCase(action.payload.integration)] = action.payload.value
    },

    isSavingSessionConfiguration(state, action) {
      state.meta.isSavingSessionConfiguration = action.payload
    },

    isConfiguringSSO(state, action) {
      state.meta.isConfiguringSSO = action.payload
    },

    isConfiguringSAML(state, action) {
      state.meta.isConfiguringSAML = action.payload
    },

    confirmingTeamsSdkInstalled(state, action) {
      state.meta.confirmingTeamsSdkInstalled = {
        ...state.meta.confirmingTeamsSdkInstalled,
        ...action.payload,
      }
    },
  },
})

const asyncActions = {
  admin: {
    fetchData: () => async (dispatch) => {
      dispatch(itAdminSlice.actions.isLoading(true))

      try {
        const response = await API.admin.itAdmin.fetch()
        dispatch(itAdminSlice.actions.setData(response.data))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(itAdminSlice.actions.isLoading(false))
      }
    },

    updateData: (params, onSuccess = () => null) => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.update(params)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    uninstallIntegration: (integration, params = {}) => async (dispatch) => {
      dispatch(itAdminSlice.actions.isLoading(true))
      dispatch(itAdminSlice.actions.isUninstalling({ integration, value: true, params }))

      try {
        await API.admin.itAdmin.uninstall(integration, params)
        const integrationName = I18N(`integration_card.${integration}.name`)
        dispatch(showToastMessage({ message: I18N('successfully_uninstalled', { integrationName }), type: 'success' }))

        setTimeout(() => {
          // since this is done in a background job, let's just wait 5 seconds to allow finish it.
          // in worst case, this would take more than 5 seconds, but user can refresh the page later
          // and see the changes

          // we must reload when uninstalling because the values are stored in the page variables
          window.location.reload()
        }, 5000)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'error' }))
        dispatch(itAdminSlice.actions.isUninstalling({ integration, value: false }))
      }
    },

    configureSSO: params => async (dispatch) => {
      dispatch(itAdminSlice.actions.isConfiguringSSO(true))

      try {
        const response = await API.admin.itAdmin.configureSSO(params)

        window.location.assign(response.data.portalLink)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'error' }))
      }
    },

    installSAML: params => async (dispatch) => {
      dispatch(itAdminSlice.actions.isConfiguringSAML(true))

      try {
        const response = await API.admin.itAdmin.installSAML({
          samlCredentials: params,
        })
        dispatch(itAdminSlice.actions.updateData(response.data))
        dispatch(showToastMessage({ message: I18N('succesfully_configured_saml'), type: 'success' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isConfiguringSAML(false))
      }
    },

    fetchMergedevLinkToken: () => async (dispatch) => {
      dispatch(itAdminSlice.actions.isFetchingMergedevLinkToken(true))

      try {
        const response = await API.admin.itAdmin.fetchMergedevLinkToken()
        dispatch(itAdminSlice.actions.setMergedevLinkToken(response.data.mergedevLinkToken))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError((e))
        dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isFetchingMergedevLinkToken(false))
      }
    },

    setupMergedev: publicToken => async (dispatch) => {
      dispatch(itAdminSlice.actions.isLoading(true))

      try {
        dispatch(asyncActions.admin.fetchMergedevLinkToken())
        const response = await API.admin.itAdmin.setupMergedev(publicToken)
        dispatch(itAdminSlice.actions.setData(response.data))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError((e))
        dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isLoading(false))
      }
    },

    fetchConfluenceSpaces: () => async (dispatch) => {
      dispatch(itAdminSlice.actions.isFetchingConfluenceSpaces(true))

      try {
        const response = await API.admin.itAdmin.fetchConfluenceSpaces()
        dispatch(itAdminSlice.actions.setConfluenceSpaces(response.data.data))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError((e))
        dispatch(showToastMessage({ message: I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isFetchingConfluenceSpaces(false))
      }
    },

    confirmTeamsBotSdkInstalled: (workspaceId, teamId) => async (dispatch) => {
      dispatch(itAdminSlice.actions.confirmingTeamsSdkInstalled({ [workspaceId]: true }))

      try {
        const response = await API.admin.itAdmin.confirmTeamsBotSdkInstalled(workspaceId, teamId)
        dispatch(itAdminSlice.actions.updateData(response.data))
      } catch (e) {
        const message = e?.response?.data?.error?.message || I18N('generic_error_message')
        appSignal.sendErrorUnlessClearyBackendError((e))
        dispatch(showToastMessage({ message, type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.confirmingTeamsSdkInstalled({ [workspaceId]: false }))
      }
    },

    updateConfluenceSpaces: (confluenceSpaces, onSuccess = () => null) => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.updateConfluenceSpaces(confluenceSpaces)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    updateSessionConfiguration: (session, onSuccess = () => null) => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSavingSessionConfiguration(true))

      try {
        const response = await API.admin.itAdmin.updateSessionConfiguration(session)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSavingSessionConfiguration(false))
      }
    },

    updateSearchRestrictedUserColumns: (searchRestrictedUserColumns, onSuccess = () => null) => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.updateSearchRestrictedUserColumns(searchRestrictedUserColumns)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    installGoogle: googleCredentials => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.installGoogle(googleCredentials)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    installBloomfire: bloomfireCredentials => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.installBloomfire(bloomfireCredentials)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    installBambooHR: bambooHRCredentials => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.installBambooHR(bambooHRCredentials)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))

        // we must reload so customers can go to User Imports and click sync right away
        // instead of asking us "I set BambooHR credentials but the app doesn't show me any sync button"
        window.location.reload()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    installAshby: ashbyCredentials => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.installAshby(ashbyCredentials)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))

        // we must reload so customers can go to User Imports and click sync right away
        // instead of asking us "I set ashby credentials but the app doesn't show me any sync button"
        window.location.reload()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    installAdp: adpCredentials => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.installAdp(adpCredentials)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))

        // we must reload so customers can go to User Imports and click sync right away
        window.location.reload()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    installJobvite: jobviteCredentials => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.installJobvite(jobviteCredentials)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))

        // Reloading so new company settings saved as page variables are shown
        window.location.reload()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },

    installWorkday: params => async (dispatch) => {
      dispatch(itAdminSlice.actions.isSaving(true))

      try {
        const response = await API.admin.itAdmin.installWorkday(params)
        dispatch(itAdminSlice.actions.updateData(response.data))

        dispatch(showToastMessage({ message: I18N('succesfully_updated'), type: 'success' }))

        window.location.reload()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)

        dispatch(showToastMessage({ message: e?.response?.data?.error?.message || I18N('generic_error_message'), type: 'error' }))
      } finally {
        dispatch(itAdminSlice.actions.isSaving(false))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.itAdmin.meta,

  getData: () => (state: ReduxState) => state.itAdmin.data,

  getMergedevLinkToken: () => (state: ReduxState) => state.itAdmin.mergedevLinkToken,

  getConfluenceSpaces: () => (state: ReduxState) => state.itAdmin.confluenceSpaces,
}

export default {
  ...itAdminSlice,
  selectors,
  asyncActions,
}

