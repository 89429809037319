import React, { useEffect } from 'react'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import classNames from 'classnames'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CdnSvg from 'components/common/cdnSvg'
import StatusLabel from 'components/common/status_label'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { useDispatch, useSelector } from 'react-redux'
import pageSnapshotSlice from 'redux/slices/pages/snapshots'
import { PageSnapshotType } from 'types/page/snapshot'
import ClearyTooltip from 'components/common/clearyTooltip'

const I18N = i18nPath('views.pages.actions_dropdown.version_history')

const clockGoingBackwardsIconPath = '/images/clockGoingBackwardsIcon.svg'

type SnapshotItemProps = {
  snapshot: PageSnapshotType
  isSelected: boolean
  onClick: () => void
}

const SnapshotItem = ({
  snapshot,
  isSelected,
  onClick,
}: SnapshotItemProps) => (
  <div
    className={classNames('SnapshotItem pointer px-4 py-3 mt-2 mr-3', { isSelected })}
    onClick={onClick}
  >
    {snapshot.published && (
      <StatusLabel color='green' className='mb-2 text-small'>{I18N('published')}</StatusLabel>
    )}
    {snapshot.restoredFromSnapshotAt && (
      <ClearyTooltip
        placement='bottom'
        tooltipClassName='RestoredStatusLabelTooltip'
        content={(
          <StatusLabel
            color='blue'
            className={classNames('mb-2 text-small', snapshot.published && 'ml-2')}
          >
            {I18N('restored')}
          </StatusLabel>
        )}
      >
        {I18N('restored_from_version', { restoredAt: i18nMoment(snapshot.restoredFromSnapshotAt).format('MMM D, hh:mm A') })}
      </ClearyTooltip>
    )}
    <div className='font-weight-400 mb-2'>
      {i18nMoment(snapshot.updatedAt).format('MMM D YYYY, hh:mm A')}
    </div>
    {snapshot.contributors.map(contributor => (
      <span key={contributor.id} className='pr-1 pb-1 text-nowrap'>
        <EmployeeThumbnailPhoto
          employee={contributor}
          className='mr-1'
          size='16px'
        />
        <span className='text-secondary text-small'>{contributor.preferredFullName}</span>
      </span>
    ))}
  </div>
)

const VersionHistoryNav = ({
  pageId,
  currentVersion,
  selectedSnapshot,
  setSelectedSnapshot,
}) => {
  const dispatch = useDispatch()
  const snapshots = useSelector(pageSnapshotSlice.selectors.getSnapshots())
  const { isLoading, queryParams } = useSelector(pageSnapshotSlice.selectors.getMetaData())
  const { page, totalPages } = queryParams

  const hasMore = page && totalPages && page < totalPages

  const fetchSnapshots = async (params) => {
    dispatch(pageSnapshotSlice.asyncActions.fetchAll(pageId, { ...queryParams, ...params }))
  }

  useEffect(() => {
    fetchSnapshots({ page: 1 })

    return () => {
      dispatch(pageSnapshotSlice.actions.reset())
    }
  }, [])

  return (
    <div className='VersionHistoryNav py-4'>
      <div className='d-flex px-4'>
        <CdnSvg src={clockGoingBackwardsIconPath} className='ClockGoingBackwardsIcon mr-3' />
        <h5>{I18N('title')}</h5>
      </div>
      <span className='text-small font-weight-400 px-4'>{I18N('subtitle')}</span>
      <div className='text-secondary px-4 mt-4'>{I18N('current_version')}</div>
      <SnapshotItem
        snapshot={currentVersion}
        isSelected={!selectedSnapshot}
        onClick={() => setSelectedSnapshot(null)}
      />
      <div className='text-secondary px-4 mt-4'>{I18N('previous_versions')}</div>
      <div className='SnapshotsList my-3'>
        {snapshots.map(snapshot => (
          <SnapshotItem
            snapshot={snapshot}
            isSelected={snapshot.id === selectedSnapshot?.id}
            onClick={() => setSelectedSnapshot(snapshot)}
            key={snapshot.id}
          />
        ))}
      </div>
      {isLoading && <CirclesLoadingIndicator />}
      {hasMore && (
        <div
          onClick={() => fetchSnapshots({ page: page + 1 })}
          className='mt-2 px-4 font-weight-600 link-color pointer'
        >
          {I18N('load_more')}
        </div>
      )}
    </div>
  )
}

export default VersionHistoryNav
