import React, { useState } from 'react'

import Modal from 'components/common/modal'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import TrashIcon from 'components/icons/trashIcon'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.includes.audience_selector.manage_specific_users_modal')

const ManageSpecificUsersModal = ({
  isOpen,
  toggle,
  selectedUsers,
  onUsersChange,
}) => {
  const [query, setQuery] = useState('')
  const filteredUsers = query === '' ? selectedUsers : selectedUsers.filter(user => user.preferredFullName.toLowerCase().includes(query.toLowerCase()))

  return (
    <Modal visible={isOpen} toggle={toggle} className='ManageSpecificUsersModal'>
      <h3>{I18N('title')}</h3>

      <input
        type='text'
        placeholder={I18N('search_placeholder')}
        value={query}
        onChange={e => setQuery(e.target.value)}
        className='w-100 my-3'
      />
      <div className='overflow-auto' style={{ height: '550px' }}>
        {filteredUsers.map(user => (
          <div key={user.id} className='d-flex align-items-center bordered my-2'>
            <EmployeeMiniCard
              employee={user}
              className='flex-grow-1'
            />
            <div
              className='DeleteRuleButton pointer py-2 px-1 mr-2'
              onClick={() => onUsersChange(selectedUsers.filter(u => u.id !== user.id))}
            >
              <TrashIcon className='TrashIcon' />
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default ManageSpecificUsersModal
