import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { ButtonLink, ButtonSmallNarrow } from 'components/common/buttons'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import adminArticleSlice from 'redux/slices/admin/articles'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { DropdownButton, DropdownItem } from 'components/common/dropdownButton'
import SchedulePublishModal from 'components/admin/articles/schedulePublishModal'
import CdnSvg from 'components/common/cdnSvg'
import ArticleDropdown from 'components/admin/articles/articleDropdown'
import ArticleStatusLabel from 'components/admin/articles/articleStatusLabel'
import NavigationBlocker from 'components/common/navigationBlocker'
import ArticleMissingFieldsPopover from 'components/admin/articles/articleMissingFieldsPopover'
import { CONTENT, SUMMARY, SETTINGS } from 'components/admin/articles/articleOrTemplateEditor'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import useArticlesBasePath from 'components/admin/articles/hooks/useArticlesBasePath'
import useIsGroupPost from 'components/admin/articles/hooks/useIsGroupPost'
import useAreChangesPresentInArticle from 'components/common/hooks/useAreChangesPresentInArticle'
import useHideNavbar from 'components/navbar/hooks/useHideNavbar'
import ForwardArrowIcon from 'components/icons/forwardArrowIcon'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { present } from 'components/common/utils'
import PrivateLabel from 'components/admin/articles/privateLabel'

const I18N = i18nPath('views.admin.article_editor')

const validateSocialShare = (content) => {
  const parser = new DOMParser()

  const doc = parser.parseFromString(content, 'text/html')
  const editModeSocialShares = doc.querySelectorAll('.social-share[data-edit-mode="true"]')

  return editModeSocialShares?.length === 0
}

const ArticleEditorNavbar = ({
  isNewArticle,
  article,
  workingCopy,
  showScheduleModal,
  setShowScheduleModal,
  showTemplatesSidebar,
  setShowTemplatesSidebar,
  setCreatedTemplateId,
}) => {
  useHideNavbar()
  const history = useHistory()
  const dispatch = useDispatch()
  const basePath = useArticlesBasePath()
  const isGroupPost = useIsGroupPost(workingCopy)
  const { permissions: { carouselManager } } = useCurrentUser()

  const { selectedTab } = useQueryParams()
  const { isLoading, isSaving, error } = useSelector(adminArticleSlice.selectors.getMetaData())

  const [showVideoProcessingModal, setShowVideoProcessingModal] = useState(false)
  useEffect(() => {
    if (error === 'article_not_ready_to_publish') {
      setShowVideoProcessingModal(true)
    }
  }, [error])

  const areChangesPresent = useAreChangesPresentInArticle(article, workingCopy)

  const saveArticle = (opts = {}, redirectPath = '') => {
    const params = { ...workingCopy, ...opts }

    if (isNewArticle) {
      dispatch(adminArticleSlice.asyncActions.createArticle(params, (newId) => {
        setShowScheduleModal(false)
        setShowVideoProcessingModal(false)

        history.replace(redirectPath || `${basePath}/${newId}?selectedTab=${selectedTab}`)
      }))
    } else {
      dispatch(adminArticleSlice.asyncActions.updateArticle(params, () => {
        setShowScheduleModal(false)
        setShowVideoProcessingModal(false)

        if (redirectPath) {
          history.push(redirectPath)
        }
      }))
    }
  }

  const {
    title,
    tags,
    articleType,
    groupId,
    groupInformation,
    content,
    cardContent,
    requireAcknowledgement,
    acknowledgementDueAt,
  } = workingCopy

  const missingDraftFields = [
    !title && 'title',
  ].filter(Boolean)

  const missingPublishFields = [
    ...missingDraftFields,
    ...(groupId ? [] : [!articleType && 'article_type', _.isEmpty(tags) && 'tags']),
    ...(content ? [!validateSocialShare(content) && 'content_social_share'] : []),
    ...(cardContent ? [!validateSocialShare(cardContent) && 'summary_social_share'] : []),
  ].filter(Boolean)

  const isMissingDraftFields = !_.isEmpty(missingDraftFields)
  const isMissingPublishFields = !_.isEmpty(missingPublishFields)

  const acknowledgementDueAtError = requireAcknowledgement && i18nMoment(acknowledgementDueAt) < i18nMoment()
  const publishErrorFields = [
    acknowledgementDueAtError ? 'acknowledgement_due_at' : null,
  ].filter(present)

  const hasPublishErrors = !_.isEmpty(publishErrorFields)

  return (
    <div className='ArticleEditorNavbar replace-navbar'>
      <NavigationBlocker isBlocked={areChangesPresent && !isSaving} allowChangingQueryParams />
      <div className='BackAndStatusButtons'>
        <ButtonLink to={basePath} variant='secondary' className='ExitButton' size='sm'>
          {I18NCommon('exit')}
        </ButtonLink>
        {article && <ArticleStatusLabel article={article} />}
        {article?.privateEditAccess && <PrivateLabel />}
      </div>
      {!isLoading && (
        <>
          <h6 className='ArticleTitle mb-0'>{isNewArticle ? I18N(isGroupPost ? 'new_group_post' : 'new_article') : article?.title}</h6>
          <div className='ActionButtons'>
            {(isNewArticle || article?.draft) ? (
              <>
                <ArticleMissingFieldsPopover missingFields={missingDraftFields}>
                  <ButtonSmallNarrow
                    variant='secondary'
                    onClick={() => saveArticle()}
                    disabled={isSaving || !areChangesPresent || isMissingDraftFields}
                    showLoadingSpinner={isSaving}
                  >
                    {I18N('save_draft')}
                  </ButtonSmallNarrow>
                </ArticleMissingFieldsPopover>
                {selectedTab === CONTENT && (
                  <ButtonSmallNarrow
                    variant='primary'
                    onClick={() => history.push({
                      pathname: history.location.pathname,
                      search: `?selectedTab=${SUMMARY}`,
                    })}
                  >
                    {I18N('summary_card')} <ForwardArrowIcon className='ml-2' fillColor='var(--white-pure)' />
                  </ButtonSmallNarrow>
                )}
                {selectedTab === SUMMARY && (
                  <ButtonSmallNarrow
                    variant='primary'
                    onClick={() => history.push({
                      pathname: history.location.pathname,
                      search: `?selectedTab=${SETTINGS}`,
                    })}
                  >
                    {I18N('audience_and_settings')} <ForwardArrowIcon className='ml-2' fillColor='var(--white-pure)' />
                  </ButtonSmallNarrow>
                )}
                {selectedTab === SETTINGS && (
                  <ArticleMissingFieldsPopover missingFields={missingPublishFields} errorFields={publishErrorFields}>
                    <DropdownButton
                      title={I18N('publish')}
                      size='sm'
                      isLoading={isSaving}
                      disabled={isMissingPublishFields || hasPublishErrors}
                      alignRight
                    >
                      <DropdownItem
                        eventKey='1'
                        onClick={() => saveArticle(
                          { draft: false, publishedAt: i18nMoment().format() },
                          isGroupPost ? `${groupInformation.group.groupType.displayPath}/team/${groupId}` : ''
                        )}
                      >
                        <CdnSvg src='/images/circledSimpleCheck.svg' />
                        {I18N('publish_now')}
                      </DropdownItem>
                      <DropdownItem
                        eventKey='2'
                        onClick={() => setShowScheduleModal(true)}
                      >
                        <CdnSvg src='/images/calendar.svg' />
                        {I18N('schedule')}
                      </DropdownItem>
                    </DropdownButton>
                  </ArticleMissingFieldsPopover>
                )}
              </>
            ) : (
              <>
                {carouselManager && (
                  <Link to={`/admin/carousel_cards/new?articleId=${article?.id}`}>
                    <ButtonSmallNarrow
                      variant='secondary'
                    >
                      {I18N('add_to_carousel')}
                      <CdnSvg src='/images/circledPlusIcon.svg' className='CircledPlusIcon ml-2' />
                    </ButtonSmallNarrow>
                  </Link>
                )}
                <ArticleMissingFieldsPopover missingFields={missingPublishFields}>
                  <ButtonSmallNarrow
                    variant='primary'
                    onClick={() => saveArticle()}
                    disabled={isSaving || !areChangesPresent || isMissingPublishFields}
                    showLoadingSpinner={isSaving}
                  >
                    {I18NCommon('save_updates')}
                  </ButtonSmallNarrow>
                </ArticleMissingFieldsPopover>
              </>
            )}
            <ArticleDropdown
              article={article}
              location='editor'
              showTemplatesSidebar={showTemplatesSidebar}
              setShowTemplatesSidebar={setShowTemplatesSidebar}
              workingCopy={workingCopy}
              setCreatedTemplateId={setCreatedTemplateId}
            />
          </div>
        </>
      )}
      <SchedulePublishModal
        showSchedulePublishModal={showScheduleModal}
        setShowSchedulePublishModal={setShowScheduleModal}
        workingCopy={workingCopy}
        scheduleArticle={saveArticle}
        isSaving={isSaving}
      />
      {showVideoProcessingModal && (
        <ConfirmationModal
          title={(
            <div>
              <CdnSvg className='AlertTriangleIcon mb-2' src='/images/alertTriangleIcon.svg' />
              <h4>{I18N('video_processing_modal.title')}</h4>
            </div>
          )}
          description={I18N('video_processing_modal.description')}
          visible
          toggle={() => setShowVideoProcessingModal(false)}
          isSaving={isSaving}
          cancelText={I18N('video_processing_modal.back')}
          confirmText={I18N('video_processing_modal.publish')}
          onConfirmClick={() => saveArticle({ draft: false, publishedAt: null })}
        />
      )}
    </div>
  )
}

export default ArticleEditorNavbar
