import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const womanWithLensPath = '/images/illustrations/womanWithLens.svg'

const ActionNeededCard = () => {
  const {
    hasHrisIntegration, hrisIntegrationName, name, appName,
  } = useCurrentCompany()
  const I18N = i18nPath(`views.admin.users.action_needed_card.${hasHrisIntegration ? 'hris_only' : 'no_integrations'}`)

  return (
    <Card className='ActionNeededCard mb-4' cardBodyClassName='d-flex align-items-center'>
      <CdnSvg src={womanWithLensPath} />
      <div className='ml-3'>
        <h6 className='mb-0'>{I18N('warning', { companyName: name, hris: hrisIntegrationName })}</h6>
        <p className='text-secondary text-small mb-0'>{I18N('possible_actions', { appName, hris: hrisIntegrationName })}</p>
      </div>
    </Card>
  )
}

export default ActionNeededCard
