import React from 'react'

import SmartTable from 'components/common/tables/smartTable'
import PaginationContainer from 'components/common/tables/paginationContainer'
import classNames from 'classnames'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const TableWithFixedColumns = ({
  className = '',
  data,
  fixedColumns,
  movingColumns,
  onPaginationClick,
  page,
  perPage,
  totalPages,
  total,
  isLoadingMovingSection = false,
  onSortChange = (any) => {},
  sortKey = '',
  sortedAsc = false,
}) => {
  const tableParams = {
    data,
    showPagination: false,
  }

  return (
    <div className={classNames('TableWithFixedColumns d-flex flex-column w-100', className)}>
      <div className='d-flex w-100'>
        <div>
          <SmartTable
            className='white-bg-table mb-0'
            columns={fixedColumns}
            onSortChange={onSortChange}
            sortKey={sortKey}
            sortedAsc={sortedAsc}
            {...tableParams}
          />
        </div>
        <div className='d-flex overflow-x-auto w-100 background-color-white'>
          {isLoadingMovingSection ? (
            <CirclesLoadingIndicator className='d-flex w-100 h-100 justify-content-center align-items-center' />
          ) : (
            <>
              {movingColumns.length > 0 && (
                <SmartTable
                  className='white-bg-table scrollable-table mb-0'
                  columns={movingColumns}
                  {...tableParams}
                />
              )}
            </>
          )}
        </div>
      </div>
      <PaginationContainer
        key='pagination'
        handleClick={onPaginationClick}
        perPage={perPage}
        page={page}
        numPages={totalPages}
        totalCount={total}
      />
    </div>
  )
}

export default TableWithFixedColumns
