import { useDispatch, useSelector } from 'react-redux'
import itAdminSlice from 'redux/slices/itAdmin'
import React, { useState } from 'react'
import { Creatable } from 'components/common/react_select'
import { ButtonSmallNarrow } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.integrations_page.search_section')

const AtlassianUninstallationComponent = () => {
  const dispatch = useDispatch()

  const {
    atlassianEnabled, confluenceSpaces,
  } = useSelector(itAdminSlice.selectors.getData())

  const confluenceSpacesOptions = useSelector(itAdminSlice.selectors.getConfluenceSpaces())
  const { isSaving, isFetchingConfluenceSpaces } = useSelector(itAdminSlice.selectors.getMetaData())

  const [selectedConfluenceSpaces, setSelectedConfluenceSpaces] = useState(confluenceSpaces || [])

  const updateConfluenceSpaces = () => {
    dispatch(itAdminSlice.asyncActions.admin.updateConfluenceSpaces(selectedConfluenceSpaces))
  }

  if (!atlassianEnabled || isFetchingConfluenceSpaces) {
    return null
  }

  return (
    <div className='full-width'>
      <div className='mb-1'>{I18N('confluence_spaces_to_search_in')}</div>
      <Creatable
        isMulti
        value={selectedConfluenceSpaces}
        onChange={selectedOptions => setSelectedConfluenceSpaces(selectedOptions)}
        options={confluenceSpacesOptions}
        placeholder={I18N('confluence_spaces_placeholder')}
      />
      <div className='text-small text-secondary mt-1 mb-2'>{I18N('confluence_spaces_explanation')}</div>
      <ButtonSmallNarrow
        onClick={updateConfluenceSpaces}
        disabled={isSaving}
        showLoadingSpinner={isSaving}
      >
        {I18NCommon('submit')}
      </ButtonSmallNarrow>
    </div>
  )
}

export default AtlassianUninstallationComponent
