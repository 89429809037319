import { createSlice } from '@reduxjs/toolkit'
import { ReduxState } from 'redux/redux'


export interface SlackWidgetState {
  slackWidgetHideCount: number
}

export const initialState: SlackWidgetState = {
  slackWidgetHideCount: 0,
}

const slackWidgetSlice = createSlice({
  name: 'slackWidget',
  initialState,
  reducers: {
    increment(state) {
      state.slackWidgetHideCount += 1
    },

    decrement(state) {
      state.slackWidgetHideCount -= 1
    },
  },
})


const selectors = {
  hideSlackWidget: () => (state: ReduxState) => state.slackWidget.slackWidgetHideCount > 0,
}

export default {
  ...slackWidgetSlice,
  selectors,
}

