import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import AudienceSelector from 'components/common/audience/audienceSelector'
import ImageSelector from 'components/common/imageSelector'
import appSlice, { defaultWorkingCopy } from 'redux/slices/app_launcher/apps'
import { showToastMessage } from 'redux/slices/toasts'
import { LoadingContainer } from 'components/common/loadingContainer'
import { isAudienceValid } from 'utils/audience'
import SuggestedIcon from 'components/common/suggestedIcon'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.admin.app_editor')


const AppEditorPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { appId } = useParams()
  const isNewApp = appId === 'new'

  const app = useSelector(appSlice.selectors.getApp(appId))
  const { isLoading, isNotFound, isSaving } = useSelector(appSlice.selectors.getMetaData())
  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)

  const targetingOptions = useTargetingOptions()

  const isMissingRequiredFields = !workingCopy?.name || !workingCopy.url

  const areChangesPresent = !_.isEqual(_.omit(app, 'appPreferenceId', 'favorite') || defaultWorkingCopy, workingCopy)

  const enableSubmit = () => {
    if (isMissingRequiredFields) return false

    if (!isAudienceValid(workingCopy)) return false

    if (!areChangesPresent) return false

    return true
  }

  useEffect(() => {
    if (!isNewApp) dispatch(appSlice.asyncActions.admin.fetch(appId))

    return () => {
      dispatch(appSlice.actions.clearAppId())
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(app)) {
      setWorkingCopy(app)
    }
  }, [app?.updatedAt])

  const updateWorkingCopy = (newValues = {}) => {
    setWorkingCopy({ ...workingCopy, ...newValues })
  }

  const generateChangeHandler = (fieldName: string) => (e) => {
    updateWorkingCopy({ [fieldName]: e.target.value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (isNewApp) {
      dispatch(appSlice.asyncActions.admin.create(workingCopy, onCreateSucces))
    } else {
      dispatch(appSlice.asyncActions.admin.update(workingCopy))
    }
  }

  const onCreateSucces = () => {
    history.push('/admin/apps')
    dispatch(showToastMessage({ message: I18N('create_success'), type: 'success' }))
  }

  return (
    <div className='AppEditorPage'>
      <header className='AdminHeader'>
        <h3>{isNewApp ? I18N('add_app') : I18N('edit_app')}</h3>
      </header>
      <LoadingContainer isLoading={isLoading} useCirclesLoadingIndicator isNotFound={isNotFound}>
        <main className='AdminContent'>
          <h6 className='text-secondary'>{I18N('app_details')}</h6>
          <form onSubmit={onSubmit}>
            <div className='AppCard d-flex flex-column'>
              <div className='form-group'>
                <label className='required-form-label'>{I18N('name')}</label>
                <input value={workingCopy.name} placeholder={I18N('name_placeholder')} onChange={generateChangeHandler('name')} />
              </div>

              <div className='form-group'>
                <label className='required-form-label'>{I18N('url')}</label>
                <input type='url' value={workingCopy.url} placeholder={I18N('url_placeholder')} onChange={generateChangeHandler('url')} />
              </div>

              <div className='form-group'>
                <label className='required-form-label'>{I18N('icon')}</label>
                <ImageSelector
                  onImageAdded={icon => updateWorkingCopy({ icon })}
                  placeholderUrl={workingCopy.iconUrl}
                />
                <p className='text-secondary mt-2 mr-2 mb-0'>{I18N('icon_suggestion')}&nbsp;
                  <SuggestedIcon
                    name={workingCopy.name}
                    url={workingCopy.url}
                    iconUrl={workingCopy.iconUrl}
                    ignoreCustom
                  />
                </p>
              </div>
              <AudienceSelector
                workingCopy={workingCopy}
                updateWorkingCopy={updateWorkingCopy}
                targetingOptions={targetingOptions}
                isLoading={false}
                title={I18N('app_visibility')}
                targetEntireCompanyText={I18N('target_entire_company_text')}
                everyoneIsTargetedText={I18N('everyone_is_targeted_text')}
                moduleName='app_launcher'
              />
            </div>

            <div className='d-flex flex-row mt-4'>
              {!isNewApp && (
                <Button variant='secondary-danger'>{I18NCommon('delete')}</Button>
              )}
              <div className='ml-auto'>
                <Button variant='secondary'>{I18NCommon('cancel')}</Button>
                <Button
                  className='ml-2'
                  type='submit'
                  disabled={!enableSubmit()}
                  showLoadingSpinner={isSaving}
                >
                  {isNewApp ? I18NCommon('add') : I18NCommon('save')}
                </Button>
              </div>
            </div>
          </form>
        </main>
      </LoadingContainer>
    </div>
  )
}

export default AppEditorPage
