import API from 'services/api'

import CalendarEventForm from 'components/admin/journeys/calendarEventTemplates/calendarEventForm'
import { CALENDAR_EVENT_ATTRIBUTES, defaultWorkingCopy as calendarEventDefaultWorkingCopy } from 'redux/slices/journeys/calendarEvents'

import CommunicationForm from 'components/admin/journeys/communicationTemplates/communicationForm'
import { COMMUNICATION_ATTRIBUTES, COMMUNICATION_INTERPOLATED_FIELDS, defaultWorkingCopy as communicationDefaultWorkingCopy } from 'redux/slices/journeys/communications'

import IntroductionForm from 'components/admin/journeys/introductionTemplates/introductionForm'
import { INTRODUCTION_ATTRIBUTES, INTRODUCTION_INTERPOLATED_FIELDS, defaultWorkingCopy as introductionDefaultWorkingCopy } from 'redux/slices/journeys/introductions'

import MeetingForm from 'components/admin/journeys/meetingTemplates/meetingForm'
import { MEETING_ATTRIBUTES, MEETING_INTERPOLATED_FIELDS, defaultWorkingCopy as meetingDefaultWorkingCopy } from 'redux/slices/journeys/meetings'

import TaskForm from 'components/admin/journeys/taskTemplates/taskForm'
import {
  TASK_ATTRIBUTES,
  TASK_INTERPOLATED_FIELDS,
  defaultWorkingCopy as taskDefaultWorkingCopy,
  buddyAssignmentDefaultWorkingCopy
} from 'redux/slices/journeys/tasks'
import BuddyAssignmentHelpCard from 'components/admin/journeys/taskTemplates/buddyAssignmentHelpCard'


const createStepMap = {
  calendar_events: {
    FormComponent: CalendarEventForm,
    generateDefaultWorkingCopy: calendarEventDefaultWorkingCopy,
    entityAttributes: CALENDAR_EVENT_ATTRIBUTES,
    createApiPath: API.admin.calendarEvents.create,
    interpolatedFields: [],
  },
  communications: {
    FormComponent: CommunicationForm,
    generateDefaultWorkingCopy: communicationDefaultWorkingCopy,
    entityAttributes: COMMUNICATION_ATTRIBUTES,
    createApiPath: API.admin.communications.create,
    interpolatedFields: COMMUNICATION_INTERPOLATED_FIELDS,
  },
  introductions: {
    FormComponent: IntroductionForm,
    generateDefaultWorkingCopy: introductionDefaultWorkingCopy,
    entityAttributes: INTRODUCTION_ATTRIBUTES,
    createApiPath: API.admin.introductions.create,
    interpolatedFields: INTRODUCTION_INTERPOLATED_FIELDS,
  },
  meetings: {
    FormComponent: MeetingForm,
    generateDefaultWorkingCopy: meetingDefaultWorkingCopy,
    entityAttributes: MEETING_ATTRIBUTES,
    createApiPath: API.admin.meetings.create,
    interpolatedFields: MEETING_INTERPOLATED_FIELDS,
  },
  tasks: {
    FormComponent: TaskForm,
    generateDefaultWorkingCopy: taskDefaultWorkingCopy,
    entityAttributes: TASK_ATTRIBUTES,
    createApiPath: API.admin.tasks.create,
    interpolatedFields: TASK_INTERPOLATED_FIELDS,
  },
  buddy_assignments: {
    FormComponent: TaskForm,
    generateDefaultWorkingCopy: buddyAssignmentDefaultWorkingCopy,
    entityAttributes: TASK_ATTRIBUTES,
    createApiPath: API.admin.tasks.create,
    interpolatedFields: TASK_INTERPOLATED_FIELDS,
    HelpCard: BuddyAssignmentHelpCard,
  },
}

export default createStepMap
