import React from 'react'
import { useSelector } from 'react-redux'
import Tooltip from 'components/common/clearyTooltip'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import classNames from 'classnames'

const MAX_USERS_SHOWN = 6

const UserCurrentlyEditing = ({
  className = '',
  user,
  showFullName = false,
}) => (
  <div className={classNames('UserCurrentlyEditing d-flex align-items-center', className)}>
    <img
      style={{ borderColor: user.color }}
      src={user.primaryPhotoThumbnailUrl}
      loading='lazy'
    />
    {showFullName && <span className='mx-1 text-small'>{user.preferredFullName}</span>}
  </div>
)

const UsersCurrentlyEditing = () => {
  const usersCurrentlyEditing = useSelector(collaborativeEditorSlice.selectors.getUsersCurrentlyEditing())
  const shownUsers = usersCurrentlyEditing.slice(0, MAX_USERS_SHOWN)
  const dropdownUsers = usersCurrentlyEditing.slice(MAX_USERS_SHOWN)

  return (
    <div className='UsersCurrentlyEditing d-none d-lg-flex align-items-center'>
      {shownUsers.map(user => (
        <Tooltip
          placement='bottom'
          show={0}
          hide={0}
          key={user.clientId.toString()}
          content={(
            <UserCurrentlyEditing user={user} />
          )}
        >
          <span className='text-small'>{user.preferredFullName}</span>
        </Tooltip>
      ))}
      {dropdownUsers.length > 0 && (
        <HoverAndClickPopover
          target={(
            <div className='plusUsersContainer rounded-circle pointer text-small'>
              <span className='plusUsers'>+{dropdownUsers.length}</span>
            </div>
          )}
          popoverClassName='UsersCurrentlyEditingPopover customPopover'
        >
          {dropdownUsers.map(user => <UserCurrentlyEditing user={user} className='p-2' showFullName />)}
        </HoverAndClickPopover>
      )}
    </div>
  )
}

export default UsersCurrentlyEditing
