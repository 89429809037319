import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import Card from 'components/common/card'
import GeneralSettingsSection from 'components/settings/generalSettingsSection'
import CelebrationsSettingsSection from 'components/settings/celebrationsSettingsSection'
import PeopleSettingsSection from 'components/settings/peopleSettingsSection'
import EventsSettingsSection from 'components/settings/eventsSettingsSection'
import NewsSettingsSection from 'components/settings/newsSettingsSection'
import settingSlice from 'redux/slices/settings'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import IntegrationsSettingsSection from 'components/settings/integrationsSettingsSection'
import { isDemoEnv } from 'utils/env'
import useIsCleary from 'components/common/hooks/useIsCleary'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import ApiTokensSection from 'components/settings/apiTokensSection'

const I18N = i18nPath('views.settings')

const SettingsPage = () => {
  const dispatch = useDispatch()
  const { settings } = useCurrentCompany()
  const { selectedTab } = useParams()
  const isCleary = useIsCleary()
  const user = useCurrentUser()

  const showSettigsTab = isDemoEnv
    || isCleary || settings.oneDrive?.userConsentEnabled || settings.slackApp?.userConsentEnabled

  const { isLoading } = useSelector(settingSlice.selectors.getMetaData())

  const tabs = [
    {
      id: 'general',
      text: I18N('tabs.general'),
    },
    settings.news?.enabled && {
      id: 'news',
      text: I18N('tabs.news'),
    },
    settings.people?.enabled && {
      id: 'people',
      text: I18N('tabs.people'),
    },
    settings.qna?.enabled && {
      id: 'events',
      text: I18N('tabs.events'),
    },
    (
      settings.shoutouts?.enabled
      || settings.celebrations?.birthdays?.enabled
      || settings.celebrations?.workAnniversaries?.enabled
    ) && {
      id: 'celebrations',
      text: I18N('tabs.celebrations'),
    },
    showSettigsTab && {
      id: 'integrations',
      text: I18N('tabs.integrations'),
    },
    {
      id: 'api_tokens',
      text: I18N('tabs.api_tokens'),
    },
  ].filter(Boolean)

  useEffect(() => {
    dispatch(settingSlice.asyncActions.fetchAll())
  }, [])

  return (
    <main className='SettingsPage my-5'>
      <div className='SettingsPageContainer'>
        <h2 className='mb-4'>{I18N('header')}</h2>
        <HistoryChangingPillTabSelector
          baseUrl='/settings'
          historyKeyToChange='pathname'
          tabs={tabs}
          pillClasses='text-normal'
        />
        {isLoading ? (
          <CirclesLoadingIndicator />
        ) : (
          <Card className='mt-3'>
            {selectedTab === 'general' && <GeneralSettingsSection />}
            {selectedTab === 'news' && <NewsSettingsSection />}
            {selectedTab === 'people' && <PeopleSettingsSection />}
            {selectedTab === 'events' && <EventsSettingsSection />}
            {selectedTab === 'celebrations' && <CelebrationsSettingsSection />}
            {selectedTab === 'integrations' && <IntegrationsSettingsSection />}
            {selectedTab === 'api_tokens' && <ApiTokensSection user={user} />}
          </Card>
        )}
      </div>
    </main>
  )
}

export default SettingsPage
