import React from 'react'

const GetToKnowYouIcon = ({ fill = 'var(--text-color-primary)' }) => (
  <svg
    width='35px'
    height='35px'
    viewBox='0 0 35 35'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-40.000000, -21.000000)' fill={fill} fillRule='nonzero'>
        <g transform='translate(40.000000, 21.000000)'>
          <path d='M17.5,0 C7.85068662,0 0,7.85068662 0,17.5 C0,27.1493134 7.85068662,35 17.5,35 C27.1493134,35 35,27.1493134 35,17.5 C35,7.85068662 27.1493847,0 17.5,0 Z M17.5,33.4782826 C8.68911846,33.4782826 1.52171742,26.3108815 1.52171742,17.5 C1.52171742,8.68911846 8.68911846,1.52171742 17.5,1.52171742 C26.3108815,1.52171742 33.4782826,8.68911846 33.4782826,17.5 C33.4782826,26.3108815 26.3108815,33.4782826 17.5,33.4782826 Z' />
          <path d='M17,17 C11.4861431,17 7,21.5822653 7,27.2142647 C7,27.647977 7.34463859,28 7.76925296,28 C8.19386732,28 8.53850592,27.6479034 8.53850592,27.214191 C8.53850592,22.4480694 12.3338574,18.571397 17,18.571397 C21.6661426,18.571397 25.4614941,22.4480694 25.4614941,27.214191 C25.4614941,27.6479034 25.8061327,28 26.230747,28 C26.6553614,28 27,27.6479034 27,27.214191 C27,21.5822653 22.5138569,17 17,17 Z' />
          <path d='M17.5,5 C14.467181,5 12,7.46710729 12,10.5 C12,13.5328927 14.4671073,16 17.5,16 C20.5328927,16 23,13.532819 23,10.5 C23,7.46718095 20.5328927,5 17.5,5 Z M17.5,14.4285293 C15.3346056,14.4285293 13.5714707,12.6653944 13.5714707,10.5 C13.5714707,8.33460565 15.3346056,6.57147066 17.5,6.57147066 C19.6653944,6.57147066 21.4285293,8.33460565 21.4285293,10.5 C21.4285293,12.6653944 19.665468,14.4285293 17.5,14.4285293 Z' />
        </g>
      </g>
    </g>
  </svg>
)

export default GetToKnowYouIcon
