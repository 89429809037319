import React from 'react'
import CdnSvg from 'components/common/cdnSvg'

const GroupTypeLabelForm = ({
  groupType,
  groupTypeLabelsAttribute,
  index,
  setGroupType,
}) => {
  const updateCurrentLabel = (newLabel) => {
    const newGroupTypeLabelsAttributes = [...groupType.groupTypeLabelsAttributes]

    newGroupTypeLabelsAttributes[index] = {
      id: groupTypeLabelsAttribute.id,
      name: newLabel?.target?.value,
    }

    setGroupType({
      ...groupType,
      groupTypeLabelsAttributes: newGroupTypeLabelsAttributes,
    })
  }

  const onRemove = () => {
    const newGroupTypeLabelsAttributes = [...groupType.groupTypeLabelsAttributes]

    newGroupTypeLabelsAttributes.splice(index, 1)

    setGroupType({
      ...groupType,
      groupTypeLabelsAttributes: newGroupTypeLabelsAttributes,
    })
  }

  return (
    <div className='GroupTypeLabelForm d-flex align-items-center mb-1'>
      <input value={groupTypeLabelsAttribute.name} onChange={updateCurrentLabel} />
      <span onClick={onRemove}>
        <CdnSvg className='ml-1' src='/images/xIcon.svg' />
      </span>
    </div>
  )
}

export default GroupTypeLabelForm
