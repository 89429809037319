import React from 'react'
import CorrespondentSelect from 'components/admin/journeys/correspondentSelect'

import {
  i18nPath
} from 'utils/i18nHelpers'

const I18NStepTemplates = i18nPath('views.admin.journeys.step_templates')

const FromAndToCorrespondentSelector = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled = false,
  journeyBlueprint,
  showCompanyCorrespondents = true,
  fromCorrespondentLabel = I18NStepTemplates('from'),
  toCorrespondentLabel = I18NStepTemplates('to'),
}) => {
  const isTriggered = workingCopy?.state === 'triggered'
  const { fromCorrespondentAttributes, toCorrespondentAttributes } = workingCopy
  const journeyBlueprintType = journeyBlueprint?.type

  return (
    <>
      <div className='form-group'>
        <label className='required-form-label'>{fromCorrespondentLabel}</label>
        <CorrespondentSelect
          type='from'
          updateWorkingCopy={updateWorkingCopy}
          attributes={fromCorrespondentAttributes}
          isDisabled={isDisabled}
          journeyBlueprintType={journeyBlueprintType}
          showCompanyCorrespondents={showCompanyCorrespondents}
          isCustomStep={workingCopy?.isCustom}
          forUser={workingCopy?.forUser}
        />
      </div>

      <div className='form-group'>
        <label className='required-form-label'>{toCorrespondentLabel}</label>
        <CorrespondentSelect
          type='to'
          showOnlySpecificEmployee={isTriggered}
          updateWorkingCopy={updateWorkingCopy}
          attributes={toCorrespondentAttributes}
          isDisabled={isDisabled}
          journeyBlueprintType={journeyBlueprintType}
          showCompanyCorrespondents={showCompanyCorrespondents}
          isCustomStep={workingCopy?.isCustom}
          forUser={workingCopy?.forUser}
          isTaskable={workingCopy?.isTaskable}
        />
      </div>
    </>
  )
}

export default FromAndToCorrespondentSelector
