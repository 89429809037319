const eventsList = [
  {
    id: 1,
    end: '2021-07-07T15:15:00.000-05:00',
    htmlLink: 'https://www.google.com/calendar/event?eid=NnAxcWg0bmwzdjJhNmtsa2E2cnIxbzFtN25fMjAyMTA3MDdUMjAwMDAwWiBhbmRyZXNAZ29jbGVhcnkuY29t',
    start: '2021-07-07T15:00:00.000-05:00',
    summary: 'Profiles sync',
  },
  {
    id: 2,
    end: '2021-07-07T18:15:00.000-05:00',
    htmlLink: 'https://www.google.com/calendar/event?eid=NnAxcWg0bmwzdjJhNmtsa2E2cnIxbzFtN25fMjAyMTA3MDdUMjAwMDAwWiBhbmRyZXNAZ29jbGVhcnkuY29t',
    start: '2021-07-07T17:00:00.000-05:00',
    summary: 'Dev Monthly',
  },
]

const event = eventsList[0]

export { event, eventsList }
