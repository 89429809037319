import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

import userImportSlice from 'redux/slices/userImports'
import TabSelector from 'components/common/tabSelector'
import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import RecordsTable from 'components/admin/userImports/recordsTable'

const I18N = i18nPath('views.admin.user_import_detail')
const I18NCommon = i18nPath('views.common')

const ResultsCount = ({ resultTypeArray = [] }) => {
  const count = resultTypeArray.length || 0

  return <span> ({count}) </span>
}

const DownloadButton = ({ url, text }) => (
  <div>
    <a href={url} target='_blank' rel='noopener noreferrer'>
      <Button>
        {text}
      </Button>
    </a>
    <div className='text-small text-secondary'>
      {I18N('not_available_after_period')}
    </div>
  </div>
)

const UserImportHeader = ({ userImport }) => {
  const isCsvImport = ['UserImports::CsvPreboardingImport', 'UserImports::CsvUserImport'].includes(userImport.type)
  const csvUrl = isCsvImport && userImport?.csvPresignedUrl
  const jsonUrl = !isCsvImport && userImport?.jsonPresignedUrl

  return (
    <header className='AdminHeader'>
      <div className='d-flex justify-content-between align-items-end'>
        <h3 className='mb-1'>{I18N('user_import')}</h3>
        <div>
          {csvUrl && <DownloadButton url={csvUrl} text={I18N('download_csv')} />}
          {jsonUrl && <DownloadButton url={jsonUrl} text={I18N('download_json')} />}
        </div>
      </div>
    </header>
  )
}
const UserImportAttributes = ({ userImport }) => (
  <div className='row'>
    <div className='col-12'>
      <label htmlFor='' className='label my-2 mr-2'>
        {I18N('import_type')}
      </label>
      {userImport.displayType}
    </div>
    <div className='col-12'>
      <label htmlFor='' className='label my-2 mr-2'>
        {I18N('import_status')}
      </label>
      <span className='d-inline-block' data-testid='cy-user-import-status'>{I18N(userImport.status)}</span>
      {userImport.results.error && <span> ({userImport.results.error})</span>}
    </div>
    <div className='col-12'>
      <label htmlFor='' className='label my-2 mr-2'>
        {I18N('import_date')}
      </label>
      {i18nMoment(userImport.createdAt).format('LLLL')}
    </div>
  </div>
)

const UserImportTabsAndTable = ({ userImport, userImportResultType }) => {
  const [searchValue, setSearchValue] = useState('')
  const setLowerCaseSearchValue = val => setSearchValue(val.toLowerCase())

  // We need to return early  for first render if type doesn't match up
  if (!userImport.type.match(/::(.*)/)) return <></>

  const { results } = userImport

  // create a tab for each key in the results object of the user import
  const tabs = Object.keys(results).map(key => ({
    linkPath: _.snakeCase(key),
    additionalContent: <ResultsCount resultTypeArray={results[key]} />,
    tabClassname: _.isEmpty(results[key]) ? 'NoResults' : '',
  }))

  return (
    <div className='UserImportResults'>
      <AsyncSearchInput
        placeholder={I18NCommon('search_placeholder')}
        minCharsToSearch={1}
        onKeyUp={setLowerCaseSearchValue}
      />
      <TabSelector
        tabs={tabs}
        keyPrefix='show_'
        baseUrl={`/admin/user_imports/${userImport.id}/`}
        translationsPath='views.admin.user_import_detail'
      >
        <RecordsTable
          data={results[_.camelCase(userImportResultType)] || []}
          userImportResultType={userImportResultType}
          userImport={userImport}
          searchValue={searchValue}
        />
      </TabSelector>
    </div>
  )
}

const UserImportDetailPage = (props) => {
  const dispatch = useDispatch()
  const { userImportResultType, userImportId } = props.match.params

  const { isLoading, isNotFound } = useSelector(userImportSlice.selectors.getMetaData())
  const userImport = useSelector(userImportSlice.selectors.getUserImport(userImportId))
  const { results = [] } = userImport || {}
  // if the user is coming from the userImportsListPage (most common scenario)
  // they will have the userImport set and isLoading false on the first render,
  // but we need to wait until the fetch with results is complete, executedFirstFetch is used for that
  const [executedFirstFetch, setExecutedFirstFetch] = useState(false)

  useEffect(() => {
    dispatch(userImportSlice.asyncActions.admin.fetchUserImport(userImportId))
    setExecutedFirstFetch(true)
  }, [userImportId])

  if (!userImportResultType) {
    const { url } = props.match

    if (!userImport) return null
    if (isLoading || !executedFirstFetch) return <LoadingContainer isLoading />

    const resultEntries = Object.entries(results)

    const nonEmptyEntry = resultEntries.length && resultEntries.find(([key, values]) => values.length > 0)

    // We want to set the first result type (new_records, failed_imports, changed_data) with data as the active tab
    const nonEmptyTab = nonEmptyEntry ? _.snakeCase(nonEmptyEntry[0]) : 'new_records'

    return <Redirect exact from={url} to={`${url}/${nonEmptyTab}`} />
  }

  // We need to return early for first render if type doesn't match up
  if (!userImport?.type?.match(/::(.*)/)) return <></>

  return (
    <>
      <UserImportHeader userImport={userImport} />
      <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
        <main className='AdminContent UserImportDetail'>
          <UserImportAttributes userImport={userImport} />
          {userImport.status === 'complete' && (
            <UserImportTabsAndTable
              userImport={userImport}
              userImportResultType={userImportResultType}
            />
          )}
        </main>
      </LoadingContainer>
    </>
  )
}

export default UserImportDetailPage
