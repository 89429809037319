import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import TrashIcon from 'components/icons/trashIcon'
import awardedBadgeSlice from 'redux/slices/awardedBadges'

const I18N = i18nPath('views.badges')

const AwardeeCard = ({ awardedBadge, canRemove = false }) => {
  const {
    primaryPhotoThumbnailUrl, preferredFullName, title, username,
  } = awardedBadge.user

  const dispatch = useDispatch()

  const onDelete = (e) => {
    e.preventDefault()
    dispatch(awardedBadgeSlice.asyncActions.destroy(awardedBadge.id, awardedBadge.badge.id))
  }

  return (
    <div className={classNames('AwardeeCard', 'pointer', { RemoveAwardee: canRemove })}>
      <Link to={`/profile/${username}`}>
        <Card cardBodyClassName='AwardeeCardBody'>
          <img src={primaryPhotoThumbnailUrl} />
          <div className='PersonInfo'>
            <span>{preferredFullName}</span>
            <span className='text-secondary text-small font-weight-500'>{title}</span>
            <span className='text-secondary text-small font-weight-400'>{I18N('awarded_on', { awardedDate: i18nMoment(awardedBadge.createdAt).format('LL') })}</span>
          </div>
          <div className='ml-auto mr-0'>
            <div className='TrashIcon' onClick={onDelete}>
              <TrashIcon fill='var(--text-color-primary)' />
            </div>
          </div>
        </Card>
      </Link>
    </div>
  )
}

export default AwardeeCard
