import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import API from 'services/api'
import { CelebratedPerson } from 'components/celebrations/celebratedCard'
import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import CelebrationImage from 'components/celebrations/celebrationImage'
import classNames from 'classnames'
import useIsCelebrationForCurrentUser from 'components/celebrations/hooks/useIsCelebrationForCurrentUser'
import { useLoadCelebration } from 'pages/people/celebration_editor_page/celebrationEditorHooks'

const I18N = i18nPath('views.celebrations.greeting_card.open_card')

const CelebrationOpenCardPage = () => {
  const history = useHistory()

  const { celebration } = useLoadCelebration()

  const isCurrentUser = useIsCelebrationForCurrentUser(celebration)
  const withCustomImage = !!celebration?.coverImageUrl

  const backgroundStyles = {
    backgroundImage: withCustomImage ? `url(${celebration.coverImageUrl})` : undefined,
  }

  const openCard = async () => {
    await API.celebrations.openCard(celebration.id)

    history.push(`/celebrations/${celebration.id}/slide_show`)
  }

  useEffect(() => {
    if (!celebration) return
    if (isCurrentUser && !celebration.openedAt && !celebration.upcoming) return

    history.replace(`/celebrations/${celebration.id}/slide_show`)
  }, [celebration])

  if (!celebration || !isCurrentUser || celebration.openedAt || celebration.upcoming) return null

  return (
    <div className='CelebrationOpenCardPage'>
      <div
        className={classNames('CelebrationPage__background', celebration.type)}
        style={backgroundStyles}
      >
        {!withCustomImage && (
          <CelebrationImage
            type={celebration.type}
            isCustomCelebration={celebration.customCelebration}
          />
        )}
      </div>

      <div className='CelebrationOpenCardPage__card pointer' onClick={openCard}>
        <div className='CelebrationOpenCardPage__card__wrapper'>
          <CelebratedPerson celebration={celebration} />
          <h1>{I18N(celebration.type, { name: celebration.user.preferredName })}</h1>
          <Button variant='link'>{I18N('open_card')}</Button>
        </div>
      </div>
    </div>
  )
}

export default CelebrationOpenCardPage
