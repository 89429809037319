import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { buildStepPayload } from 'redux/slices/journeys/steps'
import { DEFAULT_TO_CORRESPONDENT_ROLE, defaultStepAttributes } from 'utils/journeys/templatesDefaultHelpers'
import getCompanyCalendarType from 'utils/getCompanyCalendarType'

export const CALENDAR_EVENT_ATTRIBUTES = ['addToAllInstances', 'externalCalendarId', 'externalEventId', 'externalEventName', 'externalCalendarEventUrl', 'externalCalendarEventType']

const I18N = i18nPath('views.admin.journeys.calendar_events')

export const defaultWorkingCopy = (journeyBlueprintType, currentCompany) => {
  const externalCalendarEventType = getCompanyCalendarType(currentCompany)

  return {
    ...defaultStepAttributes,
    externalCalendarId: '',
    externalEventId: '',
    externalCalendarEventUrl: '',
    externalCalendarEventType,
    addToAllInstances: false,
    toCorrespondentAttributes: { role: DEFAULT_TO_CORRESPONDENT_ROLE[journeyBlueprintType] },
  }
}

export const initialState = {
  calendarEventIds: [],
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams, perPage: 10, sortBy: 'trigger_at', sortDir: 'asc',
    },
  },
}

const buildCalendarEventPayload = (calendarEvent) => {
  const newCalendarEvent = _.pick(calendarEvent, [...CALENDAR_EVENT_ATTRIBUTES, 'id'])

  if (calendarEvent.stepAttributes) {
    newCalendarEvent.stepAttributes = buildStepPayload(calendarEvent.stepAttributes)
  }

  return newCalendarEvent
}

const calendarEventSlice = createSlice({
  name: 'calendarEvents',
  initialState,
  reducers: {
    ...defaultActions,

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    setCalendarEventIds(state, action) {
      state.calendarEventIds = action.payload
    },

    clearCalendarEventIds(state) {
      state.calendarEventIds = []
    },
  },
})

_.assign(calendarEventSlice, {
  asyncActions: {
    admin: {
      fetchCalendarEvent: id => async (dispatch) => {
        dispatch(calendarEventSlice.actions.isLoading(true))
        dispatch(calendarEventSlice.actions.isNotFound(false))

        try {
          const response = await API.admin.calendarEvents.fetch(id)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(calendarEventSlice.actions.isNotFound(true))
        } finally {
          dispatch(calendarEventSlice.actions.isLoading(false))
        }
      },

      updateCalendarEvent: (workingCopy, onSuccess = () => {}) => async (dispatch) => {
        dispatch(calendarEventSlice.actions.isSaving(true))

        try {
          const response = await API.admin.calendarEvents.update(buildCalendarEventPayload(workingCopy))
          dispatch(entitySlice.actions.update({ data: response.data }))
          dispatch(showToastMessage({ message: I18N('successfully_updated'), type: 'success' }))
          onSuccess()
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(calendarEventSlice.actions.setError(error))
        } finally {
          dispatch(calendarEventSlice.actions.isSaving(false))
        }
      },

      deleteCalendarEvent: (id, onSuccess = () => {}) => async (dispatch, getState) => {
        try {
          await API.admin.calendarEvents.destroy({ id })
          const calendarEvent = build(getState().entities, 'calendarEvent', id)
          const stepId = calendarEvent?.step?.id
          dispatch(entitySlice.actions.remove({ type: 'calendarEvent', id }))
          dispatch(entitySlice.actions.remove({ type: 'step', id: stepId }))
          dispatch(showToastMessage({ message: I18N('successfully_deleted'), type: 'success' }))
          onSuccess()
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(calendarEventSlice.actions.setError(error))
        }
      },
    },
  },
})

_.assign(calendarEventSlice, {
  selectors: {
    getMetaData: () => state => state.calendarEvents.meta,

    getCalendarEvent: id => state => build(state.entities, 'calendarEvent', id),

    getCalendarEvents: () => state => state.calendarEvents.calendarEventIds.map(id => build(state.entities, 'calendarEvent', id)) || [],
  },
})

export default calendarEventSlice
