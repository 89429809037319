import React, { useState } from 'react'

import moment from 'moment'
import { i18nPath } from 'utils/i18nHelpers'
import checkEventForErrors from 'components/qna/settings_modal/event_schedule/helpers/checkForEventErrors'

import EventDatePickerDecorator from 'components/qna/settings_modal/event_schedule/datePickerDecorator'
import EventDateTimePicker from 'components/qna/settings_modal/event_schedule/eventDateTimePicker'
import EventTimeline from 'components/qna/settings_modal/event_schedule/eventTimeline'

const I18N = i18nPath('views.qna.events.settings_modal.event_schedule')

const DEFAULT_STARTED_AT_HOURS_FROM_NOW = 1
const DEFAULT_LOCKED_AT_HOURS_BEFORE_START = 2
const DEFAULT_ARCHIVED_AT_DAYS_AFTER_START = 7

// defaultDateTimeValues is false in editEventPage
const EventSchedule = ({ event = {}, setEvent, defaultDateTimeValues = false }) => {
  const [errors, setErrors] = useState({})
  const [showDefaultDatesExplanationText, setShowDefaultDatesExplanationText] = useState(false)

  const eventTimeChangeHandler = (eventTimeMomentObject) => {
    const newTimes = { eventTime: eventTimeMomentObject && eventTimeMomentObject.format() }

    if (defaultDateTimeValues) {
      newTimes.startedAt = moment()
                            .add(DEFAULT_STARTED_AT_HOURS_FROM_NOW, 'hour')
                            .format()
      newTimes.lockedAt = eventTimeMomentObject.subtract(DEFAULT_LOCKED_AT_HOURS_BEFORE_START, 'hours').format()
      newTimes.archivedAt = eventTimeMomentObject.add(DEFAULT_ARCHIVED_AT_DAYS_AFTER_START, 'days').format()

      setShowDefaultDatesExplanationText(true)
    }

    setEvent({ ...event, ...newTimes })
  }

  const setEventTimeToNow = (e) => {
    e.preventDefault()
    setEvent({ ...event, eventTime: moment().format() })
  }

  const resetError = (field) => {
    setErrors(errors => ({ ...errors, [field]: null }))
  }

  const setFieldError = (field) => {
    const I18NString = `event_${_.snakeCase(field)}_error`
    setErrors(errors => ({ ...errors, [field]: I18N(I18NString) }))
  }

  const generateTimeChangeHandler = fieldName => (momentDateObject) => {
    setEvent({ ...event, [fieldName]: momentDateObject && momentDateObject.format() })
    checkEventForErrors(event, fieldName, momentDateObject, setFieldError, resetError)
  }

  const setStartedAtToNow = (e) => {
    e.preventDefault()
    setEvent({ ...event, startedAt: moment().format() })
  }

  const lockedAtHelpText = () => {
    const lockedAt = moment(event.lockedAt)
    const eventTime = moment(event.eventTime)

    const isLockedAtAfterEventTime = lockedAt.isAfter(eventTime)
    const hasFourHourDifference = lockedAt.diff(eventTime, 'hours') === 4

    return isLockedAtAfterEventTime && hasFourHourDifference ? I18N('event_end_four_hours_help_text') : I18N('event_end_help_text')
  }

  return (
    <div className='EventSchedule'>
      <div>
        <EventDatePickerDecorator type='eventTime' label={I18N('event_live_label')}>
          <EventDateTimePicker id='cy_event_editor_event_time' dateTime={event.eventTime} onChange={eventTimeChangeHandler} startImmediatelyHandler={setEventTimeToNow} />
        </EventDatePickerDecorator>

        <div className='d-block d-lg-flex align-items-start'>
          <EventDatePickerDecorator
            className='mr-5'
            type='startedAt'
            label={I18N('event_start_label')}
            helpText={I18N('event_start_help_text')}
            tooltip={I18N('event_start_tooltip')}
            errors={errors}
          >
            <EventDateTimePicker
              id='cy_event_editor_start_time'
              dateTime={event.startedAt}
              onChange={generateTimeChangeHandler('startedAt')}
              startImmediatelyHandler={setStartedAtToNow}
              showDefaultExplanationText={showDefaultDatesExplanationText}
              defaultExplanationText={I18N('started_at_default_explanation_text', {
                numHours: DEFAULT_STARTED_AT_HOURS_FROM_NOW,
              })}
            />
          </EventDatePickerDecorator>

          <EventDatePickerDecorator type='lockedAt' label={I18N('event_end_label')} helpText={lockedAtHelpText()} tooltip={I18N('event_end_tooltip')} errors={errors}>
            <EventDateTimePicker
              id='cy_event_editor_lock_time'
              dateTime={event.lockedAt}
              onChange={generateTimeChangeHandler('lockedAt')}
              showDefaultExplanationText={showDefaultDatesExplanationText}
              defaultExplanationText={I18N('locked_at_default_explanation_text', {
                numHours: DEFAULT_LOCKED_AT_HOURS_BEFORE_START,
              })}
            />
          </EventDatePickerDecorator>
        </div>

        <EventDatePickerDecorator
          type='archivedAt'
          label={I18N('event_archived_label')}
          helpText={I18N('event_achived_help_text')}
          tooltip={I18N('event_archived_tooltip')}
          errors={errors}
        >
          <EventDateTimePicker
            id='cy_event_editor_archive_time'
            dateTime={event.archivedAt}
            onChange={generateTimeChangeHandler('archivedAt')}
            showDefaultExplanationText={showDefaultDatesExplanationText}
            defaultExplanationText={I18N('archived_at_default_explanation_text', {
              numDays: DEFAULT_ARCHIVED_AT_DAYS_AFTER_START,
            })}
          />
        </EventDatePickerDecorator>
      </div>

      <EventTimeline
        eventDates={[
          { name: 'createdAt', date: event.createdAt },
          { name: 'startedAt', date: event.startedAt },
          { name: 'eventTime', date: event.eventTime },
          { name: 'lockedAt', date: event.lockedAt },
          { name: 'archivedAt', date: event.archivedAt },
        ]}
      />
    </div>
  )
}

export default EventSchedule
