import React from 'react'

import TriggerOrDateSelect from 'components/admin/journeys/triggerOrDateSelect'
import Card from 'components/common/card'
import { i18nPath } from 'utils/i18nHelpers'
import WeekendTriggerOptions from 'components/admin/journeys/common/weekendTriggerOptions'

const I18N = i18nPath('views.admin.journeys.step_templates')

const TriggerScheduleSection = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
  isStepTemplate,
  journeyBlueprint,
  sectionLabel,
}) => (
  <>
    <div className='font-weight-600 text-secondary mb-2'>{sectionLabel || I18N('trigger')}</div>
    <Card>
      <TriggerOrDateSelect
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isDisabled={isDisabled}
        useTriggerSelect={isStepTemplate}
        journeyBlueprint={journeyBlueprint}
        triggers={[
          { name: 'trigger', label: I18N('trigger') },
        ]}
      />

      {isStepTemplate && <WeekendTriggerOptions workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} />}
    </Card>
  </>
)

export default TriggerScheduleSection
