import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import userSkillTypeSlice from 'redux/slices/userSkillTypes'
import { LoadingContainer } from 'components/common/loadingContainer'

const I18N = i18nPath('views.admin.user_skill_types')

const UserSkillTypeListPage = () => {
  const dispatch = useDispatch()

  const userSkillTypes = useSelector(userSkillTypeSlice.selectors.getUserSkillTypes())
  const { isLoading } = useSelector(userSkillTypeSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(userSkillTypeSlice.asyncActions.admin.fetchUserSkillTypes())
  }, [])

  return (
    <div className='UserSkillTypeListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3>{I18N('user_skill_types')}</h3>

        <div className='AdminButtonContainer'>
          <Link className='ml-2' to='/admin/user_skill_types/new'>
            <Button>
              {I18N('create_user_skill_type')}
            </Button>
          </Link>
        </div>
      </header>

      <main className='AdminContent'>
        <LoadingContainer isLoading={isLoading}>
          <table className='white-bg-table'>
            <thead>
              <tr>
                <th>{I18N('list.name')}</th>
                <th>{I18N('list.slug')}</th>
              </tr>
            </thead>
            <tbody>
              {userSkillTypes.map(ust => (
                <tr key={`user-skill-type-${ust?.id}`}>
                  <td><Link to={`/admin/user_skill_types/${ust?.id}`}>{ust?.name}</Link></td>
                  <td>{ust?.slug}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default UserSkillTypeListPage
