import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal from 'components/common/modal'
import { ButtonNarrow } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import { RequestedBadgeType } from 'types/requestedBadge'
import requestedBadgeSlice from 'redux/slices/requestedBadges'

const I18N = i18nPath('views.badges.request')

interface PendingRequestModalProps {
  visible: boolean,
  toggle: () => void,
  requestedBadges: RequestedBadgeType[],
  badgeId: string,
}

const PendingRequestsModal = ({
  visible = false,
  toggle,
  requestedBadges,
  badgeId,
}: PendingRequestModalProps) => {
  const dispatch = useDispatch()
  const { isSaving } = useSelector(requestedBadgeSlice.selectors.getMetaData())
  const [selectedAction, setSelectedAction] = useState<{id: string, status: string} | null>(null)

  const resolve = (id: string, status: string) => {
    setSelectedAction({ id, status })
    dispatch(requestedBadgeSlice.asyncActions.update({ id, badgeId, status }, onSuccess))
  }

  const onSuccess = () => {
    toggle()
    setSelectedAction(null)
  }

  const showLoading = (id: string, status: string) => id === selectedAction?.id
                                                      && status === selectedAction?.status && isSaving
  return (
    <Modal
      size='lg'
      className='PendingRequestsModal'
      visible={visible}
      toggle={toggle}
    >
      <div className='Content'>
        <h4>{I18N('pending_requests_title', { count: requestedBadges.length })}</h4>
        {requestedBadges.map(request => (
          <div className='PendingRequest' key={`${request.id}-requested-badge`}>
            <div className='User'>
              <img src={request.user.primaryPhotoThumbnailUrl} />
              <span>{request.user.preferredFullName}</span>
            </div>
            <span className='Justification'>{request.justification}</span>
            <div className='ActionButtons'>
              <ButtonNarrow style={{ width: '75px' }} onClick={() => resolve(request.id, 'approved')} showLoadingSpinner={showLoading(request.id, 'approved')}>{I18N('award')}</ButtonNarrow>
              <ButtonNarrow variant='secondary' style={{ width: '75px' }} onClick={() => resolve(request.id, 'declined')} showLoadingSpinner={showLoading(request.id, 'declined')}>{I18N('decline')}</ButtonNarrow>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default PendingRequestsModal
