const arraysToSets = (obj) => {
  if (_.isPlainObject(obj)) {
    return _.mapValues(obj, arraysToSets)
  } else if (_.isArray(obj)) {
    return new Set(obj)
  }

  return obj
}

const isEqualWithSets = (obj1, obj2) => _.isEqual(arraysToSets(obj1), arraysToSets(obj2))

export default isEqualWithSets
