import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { SimpleUserType } from 'types/user'
import InfoTooltip from 'components/common/infoTooltip'
import CSVAudienceModal from 'components/common/csv/csvAudienceModal'
import TrashIcon from 'components/icons/trashIcon'
import ManageSpecificUsersModal from 'components/common/audience/manageSpecificUsersModal'

const I18N = i18nPath('views.admin.includes.audience_selector')

interface SpecificUsersSelectProps {
  selectedUsers: SimpleUserType[]
  onUsersChange: (users: SimpleUserType[]) => void
  onRemoveSpecificUsers: () => void
  csvTooltipText?: string
}

const SpecificUsersSelect = ({
  selectedUsers,
  onUsersChange,
  onRemoveSpecificUsers,
  csvTooltipText = I18N('upload_a_list_instead_tooltip'),
} : SpecificUsersSelectProps) => {
  const [isCsvImportModalOpen, setIsCsvImportModalOpen] = useState(false)
  const [isManageSpecificUsersModalOpen, setIsManageSpecificUsersModalOpen] = useState(false)

  const addUsersFromCSVImport = (users) => {
    const newUsers = _.uniqBy([...users, ...selectedUsers], user => user.id)

    onUsersChange(newUsers)
  }

  const getSpecificUsersText = () => {
    const count = selectedUsers.length

    if ([1, 2, 3].includes(count)) {
      const userNames = selectedUsers.slice(0, 3).reduce((acc, user, index) => {
        acc[`user${index + 1}`] = user?.preferredFullName
        return acc
      }, {})
      const countWord = ['one', 'two', 'three'][count - 1]
      return I18N(`specific_users_targeted.${countWord}`, userNames)
    } else {
      return I18N('specific_users_targeted', { count })
    }
  }

  return (
    <div className='my-3'>
      <label className='text-secondary'>
        {I18N('add_specific_people_or')}&nbsp;
        <span>
          <a className='font-weight-500 link-color' onClick={() => setIsCsvImportModalOpen(true)} data-testid='cy_upload_specific_users'>
            {I18N('upload_a_list_label')}.
            <InfoTooltip
              text={csvTooltipText}
              tooltipClassName='mt-2'
              placement='bottom'
            />
          </a>
        </span>
      </label>

      <div className='d-flex w-100 align-items-center'>
        <EmployeeSearch
          onChange={user => onUsersChange([...selectedUsers, user])}
          selectedEmployee={null}
          className='mb-2 flex-grow-1 mr-1'
        />
        <div className='DeleteRuleButton pointer py-2 px-1' onClick={onRemoveSpecificUsers}>
          <TrashIcon className='TrashIcon mr-1' />
        </div>
      </div>

      <div className='SpecificUsersText link-color pointer' onClick={() => setIsManageSpecificUsersModalOpen(true)}>{getSpecificUsersText()}</div>

      <CSVAudienceModal
        isOpen={isCsvImportModalOpen}
        toggle={() => setIsCsvImportModalOpen(false)}
        onChange={addUsersFromCSVImport}
        description={csvTooltipText}
      />
      <ManageSpecificUsersModal
        isOpen={isManageSpecificUsersModalOpen}
        toggle={() => setIsManageSpecificUsersModalOpen(false)}
        selectedUsers={selectedUsers}
        onUsersChange={onUsersChange}
      />
    </div>
  )
}

export default SpecificUsersSelect
