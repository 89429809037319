import { useParams } from 'react-router-dom'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

const useGetFilterFromUrl = () => {
  const { filter, filters: appliedFilter = '{}' } = useQueryParams()

  const filters = useMemo(() => {
    const searchResultsFilter = filter || 'all'

    const filters = {}
    switch (searchResultsFilter) {
    case 'groups':
      {
        const { group_type_id: groupTypeId } = JSON.parse(appliedFilter)
        filters.group_type_id = groupTypeId
      }
      break
    case 'articles':
      {
        // authorId singular since we're storing singular id in author_ids
        const { article_type_id: articleTypeIds = [], tag_ids: articleTags = [], author_ids: authorId } = JSON.parse(
          appliedFilter
        )

        filters.author_ids = authorId

        if (!isEmpty(articleTypeIds)) filters.article_type_id = articleTypeIds

        if (!isEmpty(articleTags)) filters.tag_ids = articleTags
      }
      break
    case 'people':
      const { city, user_skills: userSkills } = JSON.parse(appliedFilter)
      filters.city = city
      filters.user_skills = userSkills
      break
    case 'qna':
      const { type } = JSON.parse(appliedFilter)
      filters.type = type
      break
    case 'google_drive':
      const { mime_type, owner_id } = JSON.parse(appliedFilter)
      filters.mime_type = mime_type
      filters.owner_id = owner_id
      break
    case 'pages':
      filters.type = JSON.parse(appliedFilter)?.type
      break
    default:
      break
    }

    return filters
  }, [filter, appliedFilter])

  return { filters }
}

export default useGetFilterFromUrl
