import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/common/buttons'
import AudienceSelectorModal, { AUDIENCE_MODAL_PUBLISH } from 'components/pages/audienceSelectorModal'
import { I18NCommon } from 'utils/i18nHelpers'
import pageSlice from 'redux/slices/pages'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'
import PageExpirationWarningModal from 'components/pages/actions_dropdown/pageExpirationWarningModal'

const PublishFromDraftButton = ({ page }) => {
  const [isAudienceModalOpen, setIsAudienceModalOpen] = useState(false)
  const areChangesPresent = useSelector(collaborativeEditorSlice.selectors.getAreChangesPresent())

  return (
    <>
      <Button
        onClick={() => setIsAudienceModalOpen(true)}
        disabled={areChangesPresent}
      >
        {I18NCommon('publish')}
      </Button>

      {isAudienceModalOpen && (
        <AudienceSelectorModal
          modalMode={isAudienceModalOpen && AUDIENCE_MODAL_PUBLISH}
          onCloseModal={() => setIsAudienceModalOpen(false)}
          page={page}
        />
      )}
    </>
  )
}

const PublishChangesButton = ({ page }) => {
  const dispatch = useDispatch()
  const [isExpirationModalOpen, setIsExpirationModalOpen] = useState(false)
  const { isPublishing } = useSelector(pageSlice.selectors.getPagesMetadata())
  const areChangesPresent = useSelector(collaborativeEditorSlice.selectors.getAreChangesPresent())

  const savePage = () => {
    const onSuccess = () => {
      const dismissedWarningPageIds = JSON.parse(localStorage.getItem('pageExpirationWarningModalDissmissedIds') || '[]')

      const pageHasExpiration = !!page.archivedAt
      const canArchivePage = page.permittedActions.canArchivePage

      if (pageHasExpiration && canArchivePage && !dismissedWarningPageIds.includes(page.id)) {
        setIsExpirationModalOpen(true)
      }
    }

    dispatch(pageSlice.asyncActions.publish({ id: page.id }, onSuccess))
  }

  return (
    <>
      <Button
        onClick={savePage}
        disabled={isPublishing || areChangesPresent || !page.hasUnpublishedChanges}
        showLoadingSpinner={isPublishing}
      >
        {I18NCommon('publish_changes')}
      </Button>

      {isExpirationModalOpen && (
        <PageExpirationWarningModal
          visible={isExpirationModalOpen}
          page={page}
          toggle={() => setIsExpirationModalOpen(false)}
        />
      )}
    </>
  )
}

const PublishPageButton = ({ page }) => {
  const { draft: isDraft } = page

  if (isDraft) {
    return <PublishFromDraftButton page={page} />
  } else {
    return <PublishChangesButton page={page} />
  }
}

export default PublishPageButton
