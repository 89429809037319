import React from 'react'
import { Dropdown } from 'react-bootstrap'

import { DownGrayChevronIcon } from 'components/icons/chevron'

const MobileSectionHeaders = ({ currentSection, headers, handleOnChange }) => {
  const { header: currentHeader } = headers.find(h => h.id === currentSection)
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as='div'>
          <span className='text-large text-capitalize font-weight-bold'>{currentHeader}</span>
          <DownGrayChevronIcon className='ml-2 mb-1' />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {headers.map(({ header, id }) => (
            <Dropdown.Item key={id} onClick={() => handleOnChange(id)}>
              {header}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <hr className='w-100' />
    </>
  )
}

export default MobileSectionHeaders
