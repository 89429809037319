import React, { useEffect, useRef } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import RichTextView from 'components/common/richTextView'
import StepVariablesDropdown from 'components/admin/journeys/stepVariablesDropdown'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { TEXT_ONLY_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import { getIntroductionCalendarEventDescriptionText } from 'utils/journeys/templatesDefaultHelpers'

const I18N = i18nPath('views.admin.journeys.introduction_templates.calendar_meeting_details_section')


const CalendarEventDetailsTitleAndDescriptionSection = ({
  workingCopy,
  updateWorkingCopy,
  journeyBlueprint,
  isDisabled,
  scheduleMeetingsAutomatically = true,
  isIntroductionTemplate = true,
}) => {
  const tiptapEditorRef = useRef()
  const titleTranslation = scheduleMeetingsAutomatically ? I18N('calendar_event_name') : I18N('notification_subject_line')
  const descriptionTranslation = scheduleMeetingsAutomatically ? I18N('calendar_event_description') : I18N('notification_description')
  const additionalUserVariables = isIntroductionTemplate ? ['task_assignee', 'colleague'] : ['organizer', 'recipient']

  useEffect(() => {
    if (!isIntroductionTemplate) return

    const newDescription = getIntroductionCalendarEventDescriptionText(
      journeyBlueprint.type, scheduleMeetingsAutomatically
    )
    updateWorkingCopy({ calendarEventDescription: newDescription })
    tiptapEditorRef.current?.commands?.setContent(newDescription)
  }, [scheduleMeetingsAutomatically])

  return (
    <>
      <div className='form-group'>
        <label className='required-form-label'>{titleTranslation}</label>
        <input
          name='calendar_event_title'
          value={workingCopy.calendarEventTitle}
          disabled={isDisabled}
          onChange={({ target }) => updateWorkingCopy({ calendarEventTitle: target.value })}
        />
      </div>

      <div>
        <label className='required-form-label'>{descriptionTranslation}</label>
        {isDisabled ? (
          <RichTextView
            className='content-rich-view'
            record={workingCopy}
            recordType={isIntroductionTemplate ? 'introduction' : 'meeting'}
            richTextKey='calendarEventDescription'
          />
        ) : (
          <>
            <TiptapEditor
              className='TiptapEditor straight-borders'
              onChange={value => updateWorkingCopy({ calendarEventDescription: value })}
              html={workingCopy.calendarEventDescription}
              configuration={SIMPLE_EDITOR_CONFIGURATION}
              toolbarItems={TEXT_ONLY_TOOLBAR}
              editorContentClassName='p-3'
              editorRef={tiptapEditorRef}
            />
            <StepVariablesDropdown
              additionalRoles={additionalUserVariables}
              journeyBlueprint={journeyBlueprint}
            />
          </>
        )}
      </div>
    </>
  )
}

export default CalendarEventDetailsTitleAndDescriptionSection
