import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import profileSlice from 'redux/slices/profiles'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { Banner } from 'components/banners/banners'
import { Button } from 'components/common/buttons'
import CustomProfileField from 'components/common/customProfileField'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.soft_launch_access_form')

const SoftLaunchAccessFormPage = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const imageUrls = useSelector(state => state.imageUrls)

  const [fields, setFields] = useState({
    customField1: currentUser?.customField1,
    customField2: currentUser?.customField2,
    customField3: currentUser?.customField3,
  })
  const [softLaunchAccessRequestedAt, setSoftLaunchAccessRequestedAt] = useState(
    currentUser?.softLaunchAccessRequestedAt
  )

  const { settings: companySettings } = currentCompany
  const companyProfileFields = companySettings.profile.fields
  const defaultLogoUrl = imageUrls.companyLogo

  const shouldDisableSubmitButton = (companyProfileFields.customField1 && !fields.customField1)
    || (companyProfileFields.customField2 && !fields.customField2)
    || (companyProfileFields.customField3 && !fields.customField3)

  const handleUpdateField = fieldName => (value) => {
    setFields({ ...fields, [fieldName]: value })
  }

  const requestAccess = () => {
    const softLaunchAccessRequestedAt = i18nMoment().format()
    const onSuccess = () => setSoftLaunchAccessRequestedAt(softLaunchAccessRequestedAt)

    dispatch(profileSlice.asyncActions.update(currentUser, { ...fields, softLaunchAccessRequestedAt }, onSuccess))
  }

  return (
    <div>
      <div className='SoftLaunchAccessForm-hero pt-5 pb-4'>
        <div className='d-flex justify-content-center'>
          <img src={defaultLogoUrl} alt='Navbar' id='logo-image' style={{ maxHeight: '60px' }} />
        </div>

        <h1 className='d-flex justify-content-center mt-3'>{I18N('title', { appName: currentCompany.appName })}</h1>

        <p className='d-flex justify-content-center my-3'>{I18N('access_request_prompt')}</p>
      </div>

      {softLaunchAccessRequestedAt && (
        <Banner
          showDecline={false}
          banner={{
            text: I18N('access_requested', { accessRequestedAt: i18nMoment(softLaunchAccessRequestedAt).format('LLL') }),
          }}
        />
      )}

      <div className='container mt-4'>
        <div className='row'>
          <div className='col-md-4 offset-1 d-flex justify-content-center align-items-center mb-3'>
            <img
              className='bordered-thumbnail'
              src={currentUser.primaryPhotoUrl}
              style={{ width: '220px', height: '220px' }}
            />
          </div>
          <div className='col-md-6'>
            <h2>{currentUser.preferredFullName}</h2>

            <div className='text-secondary'>
              {currentUser.title} • {currentUser.city}
            </div>

            {[1, 2, 3].map((numberSuffix) => {
              const fieldName = `customField${numberSuffix}`
              return (
                <CustomProfileField
                  key={fieldName}
                  fieldName={fieldName}
                  currentCompany={currentCompany}
                  userAnswer={fields[fieldName]}
                  updateField={handleUpdateField}
                />
              )
            })}

            <div className='form-group'>
              <Button disabled={shouldDisableSubmitButton} onClick={requestAccess}>
                {softLaunchAccessRequestedAt ? I18N('update_profile') : I18N('request_early_access')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SoftLaunchAccessFormPage
