import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { i18nPath } from 'utils/i18nHelpers'
import profileSlice from 'redux/slices/profiles'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.profile.photos')

const PromotePhotoToPrimary = ({
  picture,
  slice = profileSlice,
  owner,
}) => {
  const dispatch = useDispatch()
  const { isPromoting } = useSelector(slice.selectors.getMetaData())

  const handleClick = (ev) => {
    ev.stopPropagation()
    dispatch(slice.asyncActions.promotePhoto(picture, owner))
  }

  if (picture.primary) return null

  return (
    <span className='d-flex position-relative'>
      <div className='ProfilePhotos-promote d-block' onClick={handleClick}>
        {I18N('make_primary')}
        {isPromoting && <CirclesLoadingIndicator className='center-v-and-h' />}
      </div>
    </span>
  )
}

export default PromotePhotoToPrimary
