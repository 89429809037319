import React from 'react'

import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import FormEditableField from 'components/form_fields/formEditableField'
import classNames from 'classnames'

const I18N = i18nPath('views.admin.users')

const UserEditableExtraField = ({
  className,
  onChange,
  field,
}) => (
  <FormEditableField
    className={classNames('UserEditableExtraField', className)}
    label={field?.displayName}
    onChange={e => onChange(e.target.value)}
    currentValue={field?.value}
  />
)

const ExtraUserFieldsSection = ({
  user = null,
  setUser = () => { },
  initialIsOpen = false,
}) => {
  const { settings } = useCurrentCompany()

  const extraUserFieldsSetting = settings.extraUserFields.fields || []

  const editableFields = extraUserFieldsSetting.filter(field => field.editable)

  const userExtraFields = user?.extraUserFields || []

  const editableFieldsWithCurrentValues = editableFields.map((field) => {
    const value = userExtraFields.find(extraField => extraField.name === field.fieldName)?.value || ''
    return { ...field, value }
  })

  const onChange = (field, value) => {
    const userExtraFields = user?.extraUserFields || []

    const newExtraUserFields = [...userExtraFields]

    const index = newExtraUserFields.findIndex(extraField => extraField.name === field?.fieldName)

    if (index !== -1) {
      newExtraUserFields[index].value = value
    } else {
      newExtraUserFields.push({
        name: field?.fieldName,
        value,
      })
    }

    setUser({ ...user, extraUserFields: newExtraUserFields })
  }

  return (
    <CollapsibleCard
      title={I18N('sections.custom_fields')}
      initialIsOpen={initialIsOpen}
      className='mt-3'
    >
      <div className='ExtraUserFieldsSection mt-3'>
        {editableFieldsWithCurrentValues.map(field => (
          <UserEditableExtraField
            key={field.fieldName}
            field={field}
            onChange={value => onChange(field, value)}
          />
        ))}
      </div>
    </CollapsibleCard>
  )
}

export default ExtraUserFieldsSection
