import React, { useState, useEffect } from 'react'

import ReactSelect, { Creatable } from 'components/common/react_select'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.journeys.step_templates.cc_correspondents')

interface CcCorrespondentProps {
  onChange: (newCc: any) => void
  ccCorrespondent: any
  isDisabled?: boolean
  journeyBlueprintType?: string
  selectedRoles?: string[]
}

const CcCorrespondentSelect = ({
  onChange,
  ccCorrespondent,
  isDisabled = false,
  journeyBlueprintType = 'onboarding',
  selectedRoles = [],
}: CcCorrespondentProps) => {
  const currentCompany = useCurrentCompany()
  const isOnboardingBuddiesEnabled = currentCompany?.settings?.journeys?.onboardingBuddies?.enabled
  const isOnboardingJourney = journeyBlueprintType === 'onboarding'
  const isOffboardingJourney = journeyBlueprintType === 'offboarding'
  const isCustomJourney = journeyBlueprintType === 'custom'

  const options = [
    isOnboardingJourney && { value: 'new_hire', label: I18N('new_hire') },
    isOffboardingJourney && { value: 'offboarding_employee', label: I18N('offboarding_employee') },
    isCustomJourney && { value: 'employee_on_journey', label: I18N('employee_on_journey') },
    { value: 'manager', label: I18N('manager') },
    { value: 'specific_employee', label: I18N('specific_employees') },
    !isOffboardingJourney && isOnboardingBuddiesEnabled && { value: 'onboarding_buddy', label: I18N('onboarding_buddy') },
    { value: 'journey_admin', label: I18N('journey_admin') },
    { value: 'email_address', label: I18N('email_addresses') },
  ].filter(Boolean)

  const filteredOptions = options.filter(option => !selectedRoles.includes(option.value))

  const selectedRole = ccCorrespondent?.role
  const [users, setUsers] = useState(ccCorrespondent?.users || [])
  const userIds = users?.map(user => user?.id)

  const [selectedEmails, setSelectedEmails] = useState(ccCorrespondent?.emails || [])
  const showSpecificEmployee = (isDisabled || selectedRole === 'specific_employee') && selectedRole !== 'email_address'

  useEffect(() => {
    onCorrespondentChange(selectedRole)
  }, [JSON.stringify(userIds), JSON.stringify(selectedEmails)])

  const onCorrespondentChange = (newRole) => {
    if (!newRole) return

    if (newRole === 'specific_employee') {
      onChange({ role: newRole, users, emails: [] })
    } else if (newRole === 'email_address') {
      onChange({ role: newRole, emails: selectedEmails, users: [] })
    } else {
      onChange({ role: newRole, users: [], emails: [] })
    }
  }

  return (
    <div className='CcSelect'>
      <ReactSelect
        options={filteredOptions}
        onChange={selectedOption => onCorrespondentChange(selectedOption?.value)}
        value={options.find(option => option.value === selectedRole) || null}
        isDisabled={isDisabled}
      />

      {showSpecificEmployee && (
        <EmployeeSearch
          onChange={setUsers}
          selectedEmployees={users}
          isDisabled={isDisabled}
          isClearable
          isMulti
        />
      )}

      {selectedRole === 'email_address' && (
        <Creatable
          className='EmailAdressCreatable'
          value={selectedEmails.map(email => ({ label: email, value: email }))}
          onChange={options => setSelectedEmails(options.map(option => option.value))}
          isMulti
          isClearable
          isDisabled={isDisabled}
        />
      )}
    </div>
  )
}

export default CcCorrespondentSelect
