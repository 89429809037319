import React, { useState } from 'react'
import moment from 'moment'

import CollapsibleCard from 'components/common/collapsibleCard'
import { i18nPath } from 'utils/i18nHelpers'
import UserEditableField from 'components/admin/users/userEditor/userEditableField'
import UserDatePickerField from 'components/admin/users/userEditor/userDatePickerField'
import FormTimezoneSelectField from 'components/form_fields/formTimezoneSelectField'
import FormEmployeesField from 'components/form_fields/formEmployeesField'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.users')

const EmployeeIdField = ({
  user,
  setUser,
}) => {
  const [isEditUsernameEnabled, setIsEditUsernameEnabled] = useState(false)

  const FootNote = () => (
    <span>
      {I18N('employee_id_footnote')}
      <a
        className='ml-1 link-color'
        onClick={() => setIsEditUsernameEnabled(!isEditUsernameEnabled)}
      >
        {I18N('click_to_edit')}
      </a>
    </span>
  )

  return (
    <UserEditableField
      user={user}
      setUser={setUser}
      fieldName='employeeId'
      className='employee-id-field'
      footNote={<FootNote />}
      disabled={!isEditUsernameEnabled}
      label={I18N('employee_id')}
    />
  )
}

const BasicInformationSection = ({
  user = null,
  setUser = () => { },
  initialIsOpen = true,
  isNewUser = false,
}) => {
  const { settings } = useCurrentCompany()
  const isPreboardingEnabled = settings.journeys?.preboarding?.enabled

  const setStartDate = (newWorkingCopy) => {
    if (!newWorkingCopy.startDate || !isPreboardingEnabled) {
      setUser(newWorkingCopy)

      return
    }

    const isStartDateInFuture = moment(newWorkingCopy.startDate).isAfter(moment(), 'day')

    setUser({
      ...newWorkingCopy,
      preboarding: isStartDateInFuture,
      hidden: isStartDateInFuture,
      visibleInOrgChart: !isStartDateInFuture,
    })
  }

  return (
    <CollapsibleCard
      title={I18N('sections.basic_information')}
      initialIsOpen={initialIsOpen}
    >
      <div className='BasicInformationSection mt-3'>
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='firstName'
          placeholder={I18N('enter_first_name')}
          isRequired
        />
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='lastName'
          placeholder={I18N('enter_last_name')}
          isRequired
        />
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='preferredName'
          footNote={I18N('preferred_name_footnote')}
          placeholder={I18N('enter_preferred_first_name')}
        />
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='preferredLastName'
          footNote={I18N('preferred_last_name_footnote')}
          placeholder={I18N('enter_preferred_last_name')}
        />
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='primaryEmail'
          isRequired={!user.preboarding}
          inputType='email'
          footNote={isPreboardingEnabled ? I18N('primary_email_footnote') : null}
          placeholder={I18N('enter_primary_email')}
        />
        {isPreboardingEnabled && (
          <UserEditableField
            user={user}
            setUser={setUser}
            fieldName='personalEmail'
            isRequired={user.preboarding}
            inputType='email'
            footNote={I18N('personal_email_footnote')}
            placeholder={I18N('enter_personal_email')}
          />
        )}
        <UserEditableField
          user={user}
          setUser={setUser}
          fieldName='title'
          className='title-field'
          placeholder={I18N('enter_job_title')}
        />
        <FormEmployeesField
          placeholder={I18N('select_manager')}
          currentValue={user.parent}
          onChange={parent => setUser({ ...user, parent })}
          label={I18N('manager')}
          clearable
        />
        <UserDatePickerField
          fieldName='startDate'
          user={user}
          setWorkingCopy={setStartDate}
          className='OnlyDate'
        />
        <FormTimezoneSelectField
          label={I18N(_.snakeCase('currentTimezone'))}
          id='user_editor_timezone_select'
          placeholder={I18N('select_timezone')}
          currentValue={user.currentTimezone}
          onChange={timezone => setUser({ ...user, currentTimezone: timezone?.timezone })}
        />
        {
          !isNewUser && (
            <>
              <EmployeeIdField user={user} setUser={setUser} />
              <UserEditableField
                user={user}
                setUser={setUser}
                fieldName='username'
                footNote={I18N('username_footnote')}
                placeholder={I18N('enter_username')}
                isRequired
              />
            </>
          )
        }
      </div>
    </CollapsibleCard>
  )
}

export default BasicInformationSection
