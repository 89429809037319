import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import { i18nPath } from 'utils/i18nHelpers'
import { DEFAULT_TO_CORRESPONDENT_ROLE, defaultStepTemplateAttributes } from 'utils/journeys/templatesDefaultHelpers'
import { buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'
import getCompanyCalendarType from 'utils/getCompanyCalendarType'

const I18N = i18nPath('views.admin.journeys.calendar_event_templates')

export const CALENDAR_EVENT_TEMPLATE_ATTRIBUTES = [
  'addToAllInstances',
  'externalCalendarId',
  'externalEventId',
  'externalEventName',
  'externalCalendarEventType',
  'externalCalendarEventUrl',
]

export const defaultWorkingCopy = (journeyBlueprintType, currentCompany) => {
  const externalCalendarEventType = getCompanyCalendarType(currentCompany)

  return {
    ...defaultStepTemplateAttributes(journeyBlueprintType),
    externalEventName: '',
    externalCalendarId: '',
    externalEventId: '',
    externalCalendarEventUrl: '',
    externalCalendarEventType,
    addToAllInstances: false,
    toCorrespondentAttributes: { role: DEFAULT_TO_CORRESPONDENT_ROLE[journeyBlueprintType] },
  }
}

export const initialState = {
  meta: {
    ...defaultMeta,
  },
}

const buildCalendarEventTemplatePayload = (calendarEventTemplate) => {
  const calendarEventTemplateAttrs = _.pick(calendarEventTemplate, [...CALENDAR_EVENT_TEMPLATE_ATTRIBUTES, 'id'])

  if (calendarEventTemplate.stepTemplateAttributes) {
    calendarEventTemplateAttrs.stepTemplateAttributes = buildStepTemplatePayload(
      calendarEventTemplate.stepTemplateAttributes
    )
  }

  return calendarEventTemplateAttrs
}

const calendarEventTemplateSlice = createSlice({
  name: 'calendarEventTemplates',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

_.assign(calendarEventTemplateSlice, {
  asyncActions: {
    admin: {
      createCalendarEventTemplate: (calendarEventTemplate, onSuccess = () => {}) => async (dispatch) => {
        dispatch(calendarEventTemplateSlice.actions.isSaving(true))

        try {
          const response = await API.admin.calendarEventTemplates.create(
            buildCalendarEventTemplatePayload(calendarEventTemplate)
          )
          dispatch(entitySlice.actions.add({ data: response.data }))
          const calendarEventTemplateId = response.data.data.id

          if (calendarEventTemplate?.stepTemplateAttributes?.state === 'draft') {
            dispatch(showToastMessage({ message: I18N('draft_saved'), type: 'success' }))
          } else {
            dispatch(showToastMessage({ message: I18N('successfully_created'), type: 'success' }))
          }

          onSuccess(calendarEventTemplateId)
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(calendarEventTemplateSlice.actions.setError(error))
        } finally {
          dispatch(calendarEventTemplateSlice.actions.isSaving(false))
        }
      },

      fetchCalendarEventTemplate: id => async (dispatch) => {
        dispatch(calendarEventTemplateSlice.actions.isLoading(true))
        dispatch(calendarEventTemplateSlice.actions.isNotFound(false))

        try {
          const response = await API.admin.calendarEventTemplates.fetch(id)
          dispatch(entitySlice.actions.add({ data: response.data }))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          dispatch(calendarEventTemplateSlice.actions.isNotFound(true))
        } finally {
          dispatch(calendarEventTemplateSlice.actions.isLoading(false))
        }
      },

      updateCalendarEventTemplate: (workingCopy, isPublishing = false) => async (dispatch) => {
        dispatch(calendarEventTemplateSlice.actions.isSaving(true))

        try {
          const response = await API.admin.calendarEventTemplates.update(
            buildCalendarEventTemplatePayload(workingCopy)
          )
          dispatch(entitySlice.actions.update({ data: response.data }))

          if (isPublishing) {
            dispatch(showToastMessage({ message: I18N('successfully_published'), type: 'success' }))
          }
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
          const { error } = checkForError(e.response)
          dispatch(calendarEventTemplateSlice.actions.setError(error))
        } finally {
          dispatch(calendarEventTemplateSlice.actions.isSaving(false))
        }
      },
    },
  },
})

_.assign(calendarEventTemplateSlice, {
  selectors: {
    getMetaData: () => state => state.calendarEventTemplates.meta,

    getCalendarEventTemplate: id => state => build(state.entities, 'calendarEventTemplate', id),
  },
})

export default calendarEventTemplateSlice
