import React from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'

import PersonCard from 'components/common/people/personCard'
import MemberCard from 'components/groups/memberCard'
import AwardeeCard from 'components/badges/awardeeCard'

import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CreatedBy from 'components/common/createdBy'

const PeoplePage = () => {
  const currentUser = useCurrentUser()

  const awardedBadge = {
    id: '1',
    user: currentUser,
    createdAt: new Date(),
  }

  return (
    <main className='Styleguide PeoplePage container mt-4'>
      <h1>
        <StyleguideBaseLink /> / People
      </h1>
      <section className='mt-4'>
        <h3>components/common/people</h3>
        <table className='table table-hover table-responsive-sm'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Props</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PersonCard</td>
              <td>Standard</td>
              <td>person</td>
              <td>
                <PersonCard person={currentUser} />
              </td>
            </tr>
            <tr>
              <td>PersonCard</td>
              <td>Wide Container Default</td>
              <td>person</td>
              <td>
                <div style={{ width: '300px' }}>
                  <PersonCard person={currentUser} />
                </div>
              </td>
            </tr>
            <tr>
              <td>PersonCard</td>
              <td>Long Title</td>
              <td>person</td>
              <td>
                <PersonCard person={{ ...currentUser, title: currentUser.title + ' Unicorn' }} />
              </td>
            </tr>
            <tr>
              <td>PersonCard</td>
              <td>Wide Container Long Title</td>
              <td>person</td>
              <td>
                <div style={{ width: '300px' }}>
                  <PersonCard person={{ ...currentUser, title: currentUser.title + ' Unicorn' }} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/common/roundedAvatarsWithAsyncUserFetching</h3>
        <table className='table table-hover table-responsive-sm'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Props</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>RoundedAvatarsWithAsyncUserFetching</td>
              <td>Users enough to show scrollable list</td>
              <td>userIds, shownAvatars (default: 5), avatarSize (default: 25px)</td>
              <td>
                <RoundedAvatarsWithAsyncUserFetching userIds={[...Array(10).keys()]} />
              </td>
            </tr>
            <tr>
              <td>RoundedAvatarsWithAsyncUserFetching</td>
              <td>Only a few users not shown</td>
              <td>userIds, shownAvatars (default: 5), avatarSize (default: 25px)</td>
              <td>
                <RoundedAvatarsWithAsyncUserFetching userIds={[...Array(6).keys()]} />
              </td>
            </tr>
            <tr>
              <td>RoundedAvatarsWithAsyncUserFetching</td>
              <td>Just a few users and bigger avatars</td>
              <td>userIds, shownAvatars (default: 5), avatarSize: 40px</td>
              <td>
                <RoundedAvatarsWithAsyncUserFetching userIds={[...Array(5).keys()]} avatarSize='40px' />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/groups/memberCard</h3>
        <table className='table table-hover table-responsive-sm'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th style={{ width: '10%' }}>Props</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Team Member Card</td>
              <td>an actionable card with no labels, no kebab</td>
              <td />
              <td>
                <MemberCard
                  membership={{ user: currentUser }}
                  group={{
                    groupType: { useLabelsToCategorizeMembers: false },
                    permittedActions: { editMembers: false },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Team Member Card</td>
              <td>an actionable card with kebab</td>
              <td />
              <td>
                <MemberCard
                  membership={{ user: currentUser }}
                  group={{
                    groupType: { useLabelsToCategorizeMembers: false },
                    permittedActions: { editMembers: true },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Team Member Card</td>
              <td>an actionable card with group type label lead</td>
              <td />
              <td>
                <MemberCard
                  membership={{ user: currentUser, lead: true, groupTypeLabel: { name: 'Marketing' } }}
                  group={{
                    groupType: { useLabelsToCategorizeMembers: true },
                    permittedActions: { editMembers: false },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Team Member Card</td>
              <td>an actionable card with group type label but not lead</td>
              <td />
              <td>
                <MemberCard
                  membership={{
                    user: { ...currentUser, title: 'Vice President of Product Marketing, Internal Comms Lead' },
                    lead: false,
                    groupTypeLabel: { name: 'Marketing' },
                  }}
                  group={{
                    groupType: { useLabelsToCategorizeMembers: true },
                    permittedActions: { editMembers: false },
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Team Member Card</td>
              <td>an actionable card with long title</td>
              <td />
              <td>
                <MemberCard
                  membership={{
                    user: { ...currentUser, title: 'Vice President of Product Marketing, Internal Comms Lead' },
                    lead: true,
                    groupTypeLabel: { name: 'Marketing' },
                  }}
                  group={{
                    groupType: { useLabelsToCategorizeMembers: true },
                    permittedActions: { editMembers: true },
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/badges</h3>
        <table className='table table-hover table-responsive-sm'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th style={{ width: '10%' }}>Props</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>AwardeeCard</td>
              <td>Card to show awardees of a badge</td>
              <td />
              <td>
                <AwardeeCard awardedBadge={awardedBadge} />
              </td>
            </tr>
            <tr>
              <td>AwardeeCard</td>
              <td>When people can revoke the badge a trashIcon appears when you hover over the card</td>
              <td />
              <td>
                <AwardeeCard awardedBadge={awardedBadge} canRemove={true} />
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className='mt-4'>
        <h3>components/common/createdBy</h3>
        <table className='table table-hover table-responsive-sm'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th style={{ width: '10%' }}>Props</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CreatedBy</td>
              <td>Shows creator of an entity</td>
              <td>creator</td>
              <td>
                <CreatedBy creator={currentUser} />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default PeoplePage
