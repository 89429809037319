import React, { useState } from 'react'

import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import PasswordLoginForm from 'components/authentication/passwordLoginForm'
import AuthTypeLookupByEmailForm from 'components/authentication/authTypeLookupByEmailForm'
import MagicLinkLoginForm from 'components/authentication/magicLinkLoginForm'
import API from 'services/api'

import LoadingSpinner from 'components/common/loadingSpinner'
import OmniauthLoginButton from 'components/authentication/omniauthLoginButton'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { useDispatch, useSelector } from 'react-redux'
import CdnSvg from 'components/common/cdnSvg'
import stylesSlice from 'redux/slices/styles'
import useFetch from 'components/common/hooks/useFetch'
import classNames from 'classnames'
import OpenInMobileAppButton from 'pages/authentication/openInMobileAppButton'

const I18N = i18nPath('views.authentication.login_form')

const useLoginVariables = () => {
  const isPreboardingLogin = window.location.pathname === '/preboarding_login'
  const { company: companyNameFromQueryParam, error: errorFromQueryParam } = useQueryParams()
  const currentSubdomain = window.location.host.split('.')[0]
  const companyNameFromUrl = companyNameFromQueryParam || currentSubdomain
  const isCompanyPresent = companyNameFromUrl !== 'login'

  return {
    isPreboardingLogin,
    errorFromQueryParam,
    currentSubdomain,
    companyNameFromUrl,
    isCompanyPresent,
  }
}


const LoginForm = ({
  isLoading,
  isPreboardingLogin,
  companyRootDomain,
  authType,
  showUserAuthTypeLookupForm,
  companySubdomain,
  samlProvider,
}) => {
  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isPreboardingLogin) {
    return (
      <MagicLinkLoginForm companyRootDomain={companyRootDomain} isUserPreboarding />
    )
  }

  if (authType === 'workos' || showUserAuthTypeLookupForm) {
    return <AuthTypeLookupByEmailForm />
  }

  if (authType === 'password') return <PasswordLoginForm />
  if (authType === 'magic_link') return <MagicLinkLoginForm companyRootDomain={companyRootDomain} />

  const omniauthTypes = ['saml', 'microsoft', 'google', 'developer']
  if (omniauthTypes.includes(authType)) {
    return (
      <OmniauthLoginButton
        authType={authType}
        companySubdomain={companySubdomain}
        samlProvider={samlProvider}
      />
    )
  }

  return null
}

const LoginFormPage = () => {
  const {
    isPreboardingLogin,
    companyNameFromUrl,
    errorFromQueryParam,
    isCompanyPresent,
  } = useLoginVariables()

  const [showUserAuthTypeLookupForm, setShowUserAuthTypeLookupForm] = useState(companyNameFromUrl === 'login')
  const lastLoggedUser = useSelector(state => state.lastLoggedUser)
  const dispatch = useDispatch()

  const { data = {}, isLoading } = useFetch(API.auth.getAuthType, [companyNameFromUrl], {
    load: isCompanyPresent,
    onSuccess: (response) => {
      const {
        subdomain,
        preboardingEnabled,
        styles,
      } = response

      if (isPreboardingLogin && !preboardingEnabled) {
        window.location = `/login?company=${subdomain}`
      }

      dispatch(stylesSlice.actions.update(styles))
    },
    onError: () => {
      setShowUserAuthTypeLookupForm(true)
    },
  })

  const {
    authType,
    samlProvider,
    name: companyName,
    rootDomain: companyRootDomain,
    subdomain: companySubdomain,
    imageUrl,
  } = data


  const renderError = () => {
    const message = errorFromQueryParam.includes(' ') ? errorFromQueryParam : I18N(`error_messages.${errorFromQueryParam}`)

    return <div className='text-error mb-2'>{message}</div>
  }

  let loginHeader = I18N('login_header')
  if (lastLoggedUser?.preferredName) loginHeader = I18N('login_header_with_user', { user: lastLoggedUser.preferredName })

  return (
    <div className={classNames('LoginPage', { withBackground: companyNameFromUrl === 'login' })}>
      <OpenInMobileAppButton />
      <div className='text-center'>
        {isCompanyPresent ? (
          <img src={imageUrl} className='company-image' />
        ) : (
          <CdnSvg src='/images/logos/clearyLogo.svg' className='cleary-logo-above-form' />
        )}
      </div>

      <div className='container login-container d-flex justify-content-center align-items-center'>
        <div className='row'>
          <div className='card position-relative login-card border-radius'>
            {lastLoggedUser && (
              <img src={lastLoggedUser.primaryPhotoUrl} className='login-avatar' />
            )}

            <h2 className='d-flex justify-content-center'>{loginHeader}</h2>

            {companyName && (
              <h4 className='text-center text-secondary mb-2'>
                {I18N('login_header_company_name', { companyName })}
              </h4>
            )}

            <div className='text-secondary text-center mb-4'>{companyRootDomain}</div>

            {errorFromQueryParam && renderError()}

            <LoginForm
              isLoading={isLoading}
              isPreboardingLogin={isPreboardingLogin}
              companyRootDomain={companyRootDomain}
              authType={authType}
              showUserAuthTypeLookupForm={showUserAuthTypeLookupForm}
              companySubdomain={companySubdomain}
              samlProvider={samlProvider}
            />
          </div>
        </div>
      </div>

      {isCompanyPresent && (
        <div className='powered-by text-center'>
          <span className='text-secondary'>{I18NCommon('powered_by')}</span>
          <CdnSvg src='/images/logos/clearyLogo.svg' className='ml-2 powered-logo' />
        </div>
      )}
    </div>
  )
}

const LoginFormPageWithRedirect = (props) => {
  const {
    isPreboardingLogin,
    currentSubdomain,
  } = useLoginVariables()

  const currentUser = useCurrentUser()

  // user is already logged in
  if (!_.isEmpty(currentUser)) {
    window.location = '/'
    return null
  }

  // Necessary for omniauth to set proper session cookie for the 'login' subdomain
  // In <ruby_path>/gems/omniauth-oauth2-1.6.0/lib/omniauth/strategies/oauth2.rb:70
  // it compares "request.params["state"] != session.delete("omniauth.state")"
  // so the omniauth.state should be present on the login subdomain to compare
  // with the 'state params when it comes back from the omniauth provider
  if (currentSubdomain !== 'login') {
    if (isPreboardingLogin) {
      window.location = `${window.location.origin.replace(currentSubdomain, 'login')}/preboarding_login?company=${currentSubdomain}`
    } else {
      window.location = `${window.location.origin.replace(currentSubdomain, 'login')}/login?company=${currentSubdomain}`
    }

    return null
  }

  return <LoginFormPage {...props} />
}

export default LoginFormPageWithRedirect
