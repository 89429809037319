import React from 'react'

import API from 'services/api'
import { AsyncCreatable, AsyncSelect } from 'components/common/react_select'
import { I18NCommon } from 'utils/i18nHelpers'

const GoogleGroupsAsyncSelect = ({
  value,
  onChange,
  placeholder = I18NCommon('select_or_type_to_search'),
  isCreatable = false,
  ...props
}) => {
  const isMulti = Array.isArray(value)

  const buildGoogleGroupOption = (group = '') => {
    if (!group) return ''
    return { value: group?.email, label: group?.email, ...group }
  }

  const buildCurrentValue = (value = '') => {
    if (!value) return ''

    if (isMulti) return value

    return { value, label: value }
  }

  const handleChange = (option) => {
    onChange(option)
  }

  const loadGoogleGroups = (query = '') => API.admin.googleGroups.fetchAll({ q: query })
      .then(response => (response?.data?.groups || []).map(buildGoogleGroupOption))

  const Component = isCreatable ? AsyncCreatable : AsyncSelect

  return (
    <Component
      id='cy_google_groups_select'
      aria-label='Type to search for google groups'
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label}
      value={buildCurrentValue(value)}
      onChange={handleChange}
      loadOptions={loadGoogleGroups}
      placeholder={placeholder}
      backspaceRemovesValue
      isClearable
      defaultOptions
      isMulti={isMulti}
      {...props}
    />
  )
}

export default GoogleGroupsAsyncSelect
