import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import isMobile from 'utils/isMobile'
import qnaAnswerSlice from 'redux/slices/qnaAnswers'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import AnswerContent from 'components/qna/answerContent'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DeleteWithIcon from 'components/common/deleteWithIcon'
import CheckIcon from 'components/icons/checkIcon'
import { ButtonSmall } from 'components/common/buttons/buttonSmall'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ReactionsAndComments from 'components/common/reactions/reactionsAndComments'

const I18N = i18nPath('views.qna.answers')

const Answer = ({ answer, isPreview = false }) => {
  const dispatch = useDispatch()
  const { settings } = useCurrentCompany()
  const useReactions = settings.reactions?.type === 'full_emoji_library'

  const { permittedActions, isCommentable, question } = answer
  const { event } = question

  const [answerIsHovered, setAnswerIsHovered] = useState(false)

  const { acceptedAnswer: questionHasAcceptedAnswer } = useSelector(
    qnaQuestionSlice.selectors.getAcceptedAnswer(answer.question.id)
  )

  const { verifiedAnswer: questionHasVerifiedAnswer } = useSelector(
    qnaQuestionSlice.selectors.getVerifiedAnswer(answer.question.id)
  )

  const acceptAnswerHandler = (e) => {
    e.preventDefault()
    dispatch(qnaAnswerSlice.asyncActions.acceptAnswer(answer))
  }

  const verifyAnswerHandler = (e) => {
    e.preventDefault()
    dispatch(qnaAnswerSlice.asyncActions.verifyAnswer(answer))
  }

  const deleteAnswerHandler = () => {
    const confirmDelete = confirm(I18NCommon('delete_confirm'))

    if (confirmDelete) {
      dispatch(qnaAnswerSlice.asyncActions.deleteAnswer(answer))
    }
  }

  const useVerifiedApproval = event.settings.answerApproval === 'verified'
  const isAnswerUnapproved = !answer.isAccepted && !answer.isVerified

  // show Accept Answer button when:
  // 1) the event's approval setting is NOT 'verified'
  // 2) the answer isn't already accepted or verified
  // 3) the question doesn't already have an accepted answer
  // 4) the user is permitted to accept
  // eslint-disable-next-line max-len
  const showAcceptAnswerButton = !useVerifiedApproval && isAnswerUnapproved && !questionHasAcceptedAnswer && permittedActions.accept

  // show Verify Answer button when:
  // 1) the event's approval setting is 'verified'
  // 2) the answer isn't already accepted or verified
  // 3) the question doesn't already have a verified answer
  // 4) the user is permitted to verify
  // 5) a moderator is not previewing the event page
  // eslint-disable-next-line max-len
  const showVerifyAnswerButton = useVerifiedApproval && isAnswerUnapproved && !questionHasVerifiedAnswer && permittedActions.verify && !isPreview

  // This is necessary to make sure 'delete kebab' always shows on mobile
  useEffect(() => {
    if (isMobile()) {
      setAnswerIsHovered(true)
    }
  }, [isMobile(), answerIsHovered])

  // show delete button if a moderator is not previewing and they are allowed to delete
  const showDeleteButton = !isPreview && permittedActions.delete

  return (
    <div onMouseEnter={() => setAnswerIsHovered(true)} onMouseLeave={() => setAnswerIsHovered(false)}>
      <div className='mt-4 position-relative'>
        <AnswerContent answer={answer}>
          <div className='mt-2'>
            <span>
              {showAcceptAnswerButton && (
                <ButtonSmall className='mr-3 btn-muted' onClick={acceptAnswerHandler}>
                  {I18N('accept_answer')} <CheckIcon className='ml-2' />
                </ButtonSmall>
              )}
              {showVerifyAnswerButton && (
                <ButtonSmall className='mr-3 btn-muted' onClick={verifyAnswerHandler}>
                  {I18N('verify_answer')} <CheckIcon className='ml-2' />
                </ButtonSmall>
              )}
            </span>

            <ReactionsAndComments
              record={answer}
              type='answer'
              showEditor={isCommentable}
              hideDeleteOption={isPreview}
              onlyLike={!useReactions}
            />
          </div>
        </AnswerContent>

        {showDeleteButton && (
          <DropdownMenuContainer
            style={{ top: '-0.5rem' }}
            className={classNames('position-absolute right-0', {
              visible: answerIsHovered,
              invisible: !answerIsHovered,
            })}
            // position relative in mediumn devices and up
            toggleWrapperClassName={isMobile() ? 'position-absolute' : 'position-md-relative'}
          >
            <CardDropdownMenuItem
              className='p-2'
              width='150px'
              primaryText={<DeleteWithIcon />}
              onClick={deleteAnswerHandler}
            />
          </DropdownMenuContainer>
        )}
      </div>
    </div>
  )
}

export default Answer
