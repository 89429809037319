import React from 'react'
import { useSelector } from 'react-redux'
import ArticleType from 'components/articles/articleType'

import articleTypeSlice from 'redux/slices/articleTypes'
import ReactSelect from 'components/common/react_select'
import { components } from 'react-select'
import { i18nPath } from 'utils/i18nHelpers'
import { targetingRulesPresent } from 'utils/audience'

const I18N = i18nPath('views.admin.article_editor')

const ArticleTypeSelect = ({ value, updateWorkingCopy, disabled }, ref) => {
  const articleTypes = useSelector(articleTypeSlice.selectors.getArticleTypes())

  const handleArticleTypeChange = (articleType) => {
    if (targetingRulesPresent(articleType.targetingRules)) {
      updateWorkingCopy({
        articleType,
        targetingRules: articleType.targetingRules,
      })
    } else {
      updateWorkingCopy({ articleType })
    }
  }

  return (
    <ReactSelect
      ref={ref}
      className='ArticleTypeSelect mb-1'
      value={value}
      getOptionValue={option => option.id}
      formatOptionLabel={option => (
        <span className='d-flex align-items-center'>
          <ArticleType articleType={option} />
        </span>
      )}
      getOptionLabel={option => option.name}
      onChange={option => handleArticleTypeChange(option)}
      options={articleTypes}
      isOptionDisabled={option => !option.active}
      placeholder={I18N('select_article_type')}
      components={{
        Option: props => (
          <components.Option {...props}>
            <ArticleType articleType={props.data} />
          </components.Option>
        ),
      }}
      openMenuOnFocus
      isDisabled={disabled}
    />
  )
}

export default React.forwardRef(ArticleTypeSelect)
