import useQueryParams from 'components/common/hooks/useQueryParams'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  onNewMessage: (message: string, options: { forceNewConversation: boolean }) => void
  setShowAskAiModal: (show: boolean) => void
}

const useCreateConversationFromQueryParam = ({ onNewMessage, setShowAskAiModal }: Props) => {
  const history = useHistory()
  const { newChatConversationMessage } = useQueryParams()

  useEffect(() => {
    if (newChatConversationMessage) {
      onNewMessage(newChatConversationMessage, { forceNewConversation: true })
      setShowAskAiModal(true)

      const searchParams = new URLSearchParams(window.location.search)
      searchParams.delete('newChatConversationMessage')
      history.replace({ search: searchParams.toString() })
    }
  }, [newChatConversationMessage])
}

export default useCreateConversationFromQueryParam
