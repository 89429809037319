import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import CelebrationTypeSelector from 'components/celebrations/celebrationTypeSelector'
import DatePicker from 'components/form_fields/datePicker'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { mergeTemplateTitle } from 'pages/people/celebration_editor_page/generateWorkingCopyFromTemplate'
import React from 'react'
import { I18NCommon, i18nMoment } from 'utils/i18nHelpers'

const FLAGS_PER_TYPE_MAP = {
  birthday: ['celebrations.birthdays.enabled'],
  new_employee_welcome: ['celebrations.new_employees.enabled'],
  work_anniversary: ['celebrations.work_anniversaries.enabled'],
}

interface Props {
  celebration: any
  setWorkingCopy?: (workingCopy: any) => void
  eventDateDisabled?: boolean
}

const CelebrationInputs = ({
  celebration,
  setWorkingCopy = () => { },
  eventDateDisabled = false,
}: Props) => {
  const type = celebration.type
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const requiredUserFlags = [
    'celebrations.manual_celebrations.enabled',
    ...(FLAGS_PER_TYPE_MAP[type] || []),
  ]
  return (
    <div className='CelebrationInputs px-2'>
      <div className='CelebrationInputs__FlexWrapper d-md-flex justify-content-center'>
        <div className='CelebrationInputs__Label mt-2 mt-md-0 mx-md-2 w-100 d-flex align-items-center'>
          <CelebrationTypeSelector
            value={type}
            onChange={type => setWorkingCopy({ ...celebration, type, user: null })}
            className='w-100 h-100'
          />
        </div>

        <div className='CelebrationInputs__Label mt-2 mt-md-0 mx-md-2 w-100 d-flex align-items-center'>
          <EmployeeSearch
            selectedEmployee={celebration.user}
            includeHiddenUsers={celebration.type === 'new_employee_welcome'}
            requiredUserFlags={requiredUserFlags}
            onChange={(user) => {
              setWorkingCopy((oldWorkingCopy) => {
                const newWorkingCopy = {
                  ...oldWorkingCopy,
                  workAnniversaryYears: user.workYears,
                  user,
                }

                const customTitle = celebration.templateTitle
                  ? mergeTemplateTitle(newWorkingCopy, celebration.templateTitle, currentUser, currentCompany)
                  : oldWorkingCopy.customTitle

                return { ...newWorkingCopy, customTitle }
              })
            }}
            isClearable={false}
            className='w-100 h-100'
            excludedUserIds={[currentUser.id]}
          />
        </div>

        <div className='CelebrationInputs__Label DatePickerLabel mt-2 mt-md-0 mx-md-2 w-100 d-flex align-items-center'>
          <CdnSvg src='/images/calendarIcon.svg' className={classNames('CalendarIcon', { hasValue: !!celebration.eventDate })} />
          <DatePicker
            className='w-100 h-100'
            selected={celebration.eventDate && i18nMoment(celebration.eventDate)}
            onChange={
              momentDate => setWorkingCopy({ ...celebration, eventDate: momentDate ? momentDate.format() : null })
            }
            showTimeSelect={false}
            dateFormat='L'
            placeholderText={I18NCommon('delivery_date')}
            isClearable={false}
            isDisabled={eventDateDisabled}
            minDate={i18nMoment()}
            maxDate={i18nMoment().add(60, 'days')}
          />
        </div>
      </div>
    </div>
  )
}

export default CelebrationInputs
