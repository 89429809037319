import { createSlice } from '@reduxjs/toolkit'
import { getResponseOrThrow } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import { defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import pickWithTargetingRules from 'redux/slices/utils/pickWithTargetingRules'

import API from 'services/api'
import appSignal from 'services/appSignal'
import {
  ActiveSignerType, CultureEngagementType, CultureShoutoutUserType, CultureSummaryDataType
} from 'types/analytics/culture'
import { PaginatedDataType } from 'types/analytics/helpers'


const buildSummaryPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
])

const buildActiveSignersPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
  'page',
  'perPage',
  'order',
])

const buildShoutoutsPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
  'page',
  'perPage',
  'type',
])

const buildEngagementPayload = pickWithTargetingRules([
  'periodStart',
  'periodEnd',
  'groupBy',
  'type',
  'groupByEventType',
])


const defaultSummary = {
  celebrationsCardSentCount: 0,
  averageSignaturesAtTime: 0,
  shoutoutsReceivedCount: 0,
  badgesReceivedCount: 0,
}

const defaultPaginatedData = {
  data: [],
  page: 0,
  totalItems: 0,
  totalPages: 0,
}

const defaultEngagementType = {
  data: [],
}

export interface CultureAnalyticsState {
  summary: CultureSummaryDataType
  mostActiveSigners: PaginatedDataType<ActiveSignerType>
  leastActiveSigners: PaginatedDataType<ActiveSignerType>
  mostShoutoutsSent: PaginatedDataType<CultureShoutoutUserType>
  mostShoutoutsReceived: PaginatedDataType<CultureShoutoutUserType>
  allEngagement: { data: CultureEngagementType[] }
  engagementByEventType: { data: CultureEngagementType[] }
  meta: {
    summary: DefaultMetaType
    mostActiveSigners: DefaultMetaType
    leastActiveSigners: DefaultMetaType
    mostShoutoutsSent: DefaultMetaType
    mostShoutoutsReceived: DefaultMetaType
    allEngagement: DefaultMetaType
    engagementByEventType: DefaultMetaType
  }
}

export const initialState: CultureAnalyticsState = {
  summary: defaultSummary,
  mostActiveSigners: defaultPaginatedData,
  leastActiveSigners: defaultPaginatedData,
  mostShoutoutsSent: defaultPaginatedData,
  mostShoutoutsReceived: defaultPaginatedData,
  allEngagement: defaultEngagementType,
  engagementByEventType: defaultEngagementType,
  meta: {
    summary: defaultMeta,
    mostActiveSigners: defaultMeta,
    leastActiveSigners: defaultMeta,
    mostShoutoutsSent: defaultMeta,
    mostShoutoutsReceived: defaultMeta,
    allEngagement: defaultMeta,
    engagementByEventType: defaultMeta,
  },
}

const cultureAnalyticsSlice = createSlice({
  name: 'cultureAnalytics',
  initialState,
  reducers: {
    setData(state, action) {
      const { type, data } = action.payload
      state[type] = data
    },

    setIsLoading(state, action) {
      const { type, isLoading } = action.payload
      state.meta[type].isLoading = isLoading
    },

    setError(state, action) {
      const { type, error } = action.payload
      state.meta[type].error = error
    },
  },
})

const asyncActions = {
  admin: {
    fetchSummary: (params = {}) => async (dispatch) => {
      try {
        dispatch(cultureAnalyticsSlice.actions.setIsLoading({ type: 'summary', isLoading: true }))

        const response = await API.admin.analytics.culture.summary(buildSummaryPayload(params))

        dispatch(cultureAnalyticsSlice.actions.setData({ type: 'summary', data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(cultureAnalyticsSlice.actions.setError({ type: 'summary', error: getResponseOrThrow(error) }))
      } finally {
        dispatch(cultureAnalyticsSlice.actions.setIsLoading({ type: 'summary', isLoading: false }))
      }
    },

    fetchEngagement: (type: 'allEngagement' | 'engagementByEventType', params: any = {}) => async (dispatch) => {
      try {
        dispatch(cultureAnalyticsSlice.actions.setIsLoading({ type, isLoading: true }))

        const response = await API.admin.analytics.culture.engagement(buildEngagementPayload(params))

        dispatch(cultureAnalyticsSlice.actions.setData({ type, data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(cultureAnalyticsSlice.actions.setError({ type, error: getResponseOrThrow(error) }))
      } finally {
        dispatch(cultureAnalyticsSlice.actions.setIsLoading({ type, isLoading: false }))
      }
    },

    fetchActiveSigners: (type: 'mostActiveSigners' | 'leastActiveSigners' = 'mostActiveSigners', params = {}) => async (dispatch) => {
      try {
        dispatch(cultureAnalyticsSlice.actions.setIsLoading({ type, isLoading: true }))

        const response = await API.admin.analytics.culture.activeSigners(buildActiveSignersPayload(params))

        dispatch(cultureAnalyticsSlice.actions.setData({ type, data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(cultureAnalyticsSlice.actions.setError({ type, error: getResponseOrThrow(error) }))
      } finally {
        dispatch(cultureAnalyticsSlice.actions.setIsLoading({ type, isLoading: false }))
      }
    },

    fetchShoutoutActivity: (type: 'mostShoutoutsSent' | 'mostShoutoutsReceived' = 'mostShoutoutsSent', params = {}) => async (dispatch) => {
      try {
        dispatch(cultureAnalyticsSlice.actions.setIsLoading({ type, isLoading: true }))

        const response = await API.admin.analytics.culture.shoutoutActivity(buildShoutoutsPayload(params))

        dispatch(cultureAnalyticsSlice.actions.setData({ type, data: response.data }))
      } catch (error) {
        appSignal.sendErrorUnlessClearyBackendError(error)
        dispatch(cultureAnalyticsSlice.actions.setError({ type, error: getResponseOrThrow(error) }))
      } finally {
        dispatch(cultureAnalyticsSlice.actions.setIsLoading({ type, isLoading: false }))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.cultureAnalytics.meta,

  getSummary: () => (state: ReduxState) => state.cultureAnalytics.summary,

  getActiveSigners: (type: 'mostActiveSigners' | 'leastActiveSigners') => (state: ReduxState) => state.cultureAnalytics[type],

  getShoutoutActivity: (type: 'mostShoutoutsSent' | 'mostShoutoutsReceived') => (state: ReduxState) => state.cultureAnalytics[type],

  getAllEngagement: () => (state: ReduxState) => state.cultureAnalytics.allEngagement,

  getEngagementByEventType: () => (state: ReduxState) => state.cultureAnalytics.engagementByEventType,
}

export default {
  ...cultureAnalyticsSlice,
  selectors,
  asyncActions,
}
