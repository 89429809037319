import React, { useState } from 'react'

import { Button } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import Modal from 'components/common/modal'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.common.export_xlsx_link')

const ExportXlsxLink = ({
  endpoint,
  exportParams,
}) => {
  const [email, setEmail] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [exportXlsx, { isLoading }] = useApi(endpoint, {
    onSuccess: () => setIsModalOpen(false),
    toastSuccessMessage: I18N('submit_success_message'),
    toastErrorMessage: I18N('submit_error_message'),
  })

  const isSubmitDisabled = isLoading || !email

  const onSubmit = (e) => {
    e.preventDefault()
    exportXlsx({ ...exportParams, email })
  }

  return (
    <>
      <Button
        variant='link'
        onClick={() => setIsModalOpen(true)}
        className='font-weight-500'
      >
        {I18N('export_xlsx')}
      </Button>

      <Modal visible={isModalOpen} toggle={() => setIsModalOpen(false)} title={I18N('export_xlsx')}>
        <form onSubmit={onSubmit}>
          <div className='mt-4'>
            <label className='required-form-label' htmlFor='email'>{I18N('email')}</label>
            <p className='text-secondary text-small font-weight-500'>{I18N('email_helper')}</p>

            <div className='w-100 w-lg-75'>
              <input type='email' id='email' value={email} className='w-100' onChange={e => setEmail(e.target.value)} />
            </div>
          </div>

          <div className='mt-4 w-50'>
            <Button
              className='ml-auto mr-0'
              type='submit'
              disabled={isSubmitDisabled}
              showLoadingSpinner={isLoading}
            >
              {I18NCommon('submit')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ExportXlsxLink
