import { useEffect, useState } from 'react'
import useQueryParams from 'components/common/hooks/useQueryParams'
import userSlice from 'redux/slices/users'
import { useDispatch, useSelector } from 'react-redux'

// Use this hook to load the user from filters query param
// used in search refinement filters
const useUserFilter = ({ filterParam = 'author_ids' } = {}) => {
  const [user, setUser] = useState(null)
  const { filters: serializedFilters = '{}' } = useQueryParams()
  const filters = JSON.parse(serializedFilters)
  const userId = filters[filterParam]
  const dispatch = useDispatch()
  let reduxUser = useSelector(state => userId && userSlice.selectors.getUser(userId)(state))

  // set reduxUser to null if is blank prevents effect from running again
  // since the userSlice.selectors.getUser will return different blank object in every call
  if (_.isEmpty(reduxUser)) { reduxUser = null }

  useEffect(() => {
    // if user is present, and is the same as param, we should not load it again
    if (user?.id === userId) {
      return
    }

    // if redux user is the same as param, we should set user and not load it again
    if (reduxUser?.id === userId) {
      setUser(reduxUser)
      return
    }

    // now we need to dispatch a request to load the user.
    // code will reach here by having the userId in the filters
    // for the first time or when doing history navigation to a
    // url with a userId that wasn't loaded yet.
    dispatch(userSlice.asyncActions.fetchUser(userId))
  }, [reduxUser, user, userId])

  return {
    user,
    setUser,
  }
}

export default useUserFilter
