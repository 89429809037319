import React, { useState } from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'
import ProgressBar from 'components/common/progressBar'
import AdminBanner from 'components/common/adminBanner'
import CollapsibleCard from 'components/common/collapsibleCard'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import ChatUserGroupsAsyncSelect from 'components/common/chatUserGroupsAsyncSelect'
import SortBy from 'components/common/sortBy'
import InfoTextContainer from 'components/common/infoTextContainer'
import FeedbackCounter from 'components/feedback/feedbackCounter'

const OtherComponentsPage = () => {
  const [allUserGroupsValue, setAllUserGroupsValue] = useState([])
  const [singleUserGroup, setSingleUserGroup] = useState(null)
  const [prefilledUserGroup, setPrefilledUserGroup] = useState({
    handle: 'engineering',
    workspaceDomain: 'gocleary',
    type: 'Chat::SlackChannel',
    id: 1,
  })
  const [prefilledUserGroups, setPrefilledUserGroups] = useState([{
    handle: 'engineering',
    workspaceDomain: 'gocleary',
    type: 'Chat::SlackChannel',
    id: 1,
  }, {
    handle: 'sales',
    workspaceDomain: 'gocleary',
    type: 'Chat::SlackChannel',
    id: 2,
  }])

  const [allChatChannelsValue, setAllChatChannelsValue] = useState([])
  const [singleChannel, setSingleChannel] = useState(null)
  const [prefilledChannel, setPrefilledChannel] = useState({
    name: 'general',
    workspaceDomain: 'gocleary',
    type: 'Chat::SlackChannel',
    id: 1,
  })

  const [prefilledChannels, setPrefilledChannels] = useState([{
    name: 'foo',
    workspaceDomain: 'gocleary',
    type: 'Chat::SlackChannel',
    id: 1,
  }, {
    name: 'bar',
    workspaceDomain: 'gocleary',
    type: 'Chat::SlackChannel',
    id: 2,
  }])
  const [prefilledMsTeamChannels, setPrefilledMsTeamChannels] = useState([{
    name: 'foo',
    workspaceDomain: 'gocleary',
    type: 'Chat::MsTeamChannel',
    id: 1,
  }, {
    name: 'bar',
    workspaceDomain: 'gocleary',
    type: 'Chat::MsTeamChannel',
    id: 2,
  }])

  const [sortBy, setSortBy] = useState(null)

  return (
    <main className='Styleguide OtherComponentsPage container mt-4'>
      <h1><StyleguideBaseLink /> / Other Components</h1>
      <section className='mt-4'>
        <h3>components/common/progressBar</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                ProgressBar
              </td>
              <td>
                Empty ProgressBar
              </td>
              <td>
                <ProgressBar />
              </td>
            </tr>
            <tr>
              <td>
              ProgressBar
              </td>
              <td>
                ProgressBar with completed percentage
              </td>
              <td>
                <ProgressBar completedCount={61} totalCount={83} />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/common/adminBanner</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                AdminBanner
              </td>
              <td>
                Green AdminBanner
              </td>
              <td>
                <AdminBanner>
                  Green AdminBanner
                </AdminBanner>
              </td>
            </tr>
            <tr>
              <td>
              AdminBanner
              </td>
              <td>
                Red AdminBanner
              </td>
              <td>
                <AdminBanner color='red'>
                  Red AdminBanner
                </AdminBanner>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/common/collapsibleCard</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                CollapsibleCard
              </td>
              <td>
                CollapsibleCard with title and children
              </td>
              <td>
                <CollapsibleCard title='Title' className='background-color'>
                  <p>children</p>
                </CollapsibleCard>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/common/chatChannelsAsyncSelect</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                ChatChannelsAsyncSelect
              </td>
              <td>
                Multi channel
              </td>
              <td>
                <ChatChannelsAsyncSelect types={['Chat::SlackChannel']} value={allChatChannelsValue} onChange={setAllChatChannelsValue} />
              </td>
            </tr>
            <tr>
              <td>
                ChatChannelsAsyncSelect
              </td>
              <td>
                Multi with value
              </td>
              <td>
                <ChatChannelsAsyncSelect types={['Chat::SlackChannel']} value={prefilledChannels} onChange={setPrefilledChannels} />
              </td>
            </tr>
            <tr>
              <td>
                ChatChannelsAsyncSelect
              </td>
              <td>
                Single channel
              </td>
              <td>
                <ChatChannelsAsyncSelect types={['Chat::SlackChannel']} value={singleChannel} onChange={setSingleChannel} />
              </td>
            </tr>
            <tr>
              <td>
                ChatChannelsAsyncSelect
              </td>
              <td>
                Single with value
              </td>
              <td>
                <ChatChannelsAsyncSelect types={['Chat::SlackChannel']} value={prefilledChannel} onChange={setPrefilledChannel} />
              </td>
            </tr>
            <tr>
              <td>
                ChatChannelsAsyncSelect
              </td>
              <td>
                Ms Team Multi with value
              </td>
              <td>
                <ChatChannelsAsyncSelect types={['Chat::MsTeamChannel']} value={prefilledMsTeamChannels} onChange={setPrefilledMsTeamChannels} />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/common/chatUserGroupsAsyncSelect</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                ChatUserGroupsAsyncSelect
              </td>
              <td>
                Multi user groups
              </td>
              <td>
                <ChatUserGroupsAsyncSelect types={['Chat::SlackUserGroup']} value={allUserGroupsValue} onChange={setAllUserGroupsValue} />
              </td>
            </tr>
            <tr>
              <td>
                ChatUserGroupsAsyncSelect
              </td>
              <td>
                Multi with value
              </td>
              <td>
                <ChatUserGroupsAsyncSelect types={['Chat::SlackUserGroup']} value={prefilledUserGroups} onChange={setPrefilledUserGroups} />
              </td>
            </tr>
            <tr>
              <td>
                ChatUserGroupsAsyncSelect
              </td>
              <td>
                Single user group
              </td>
              <td>
                <ChatUserGroupsAsyncSelect types={['Chat::SlackUserGroup']} value={singleUserGroup} onChange={setSingleUserGroup} />
              </td>
            </tr>
            <tr>
              <td>
                ChatUserGroupsAsyncSelect
              </td>
              <td>
                Single with value
              </td>
              <td>
                <ChatUserGroupsAsyncSelect types={['Chat::SlackUserGroup']} value={prefilledUserGroup} onChange={setPrefilledUserGroup} />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/common/sortBy</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                SortBy
              </td>
              <td>
                React select with Sort By label
              </td>
              <td>
                <SortBy
                  options={[{ text: 'option 1', value: 1 }, { text: 'option 2', value: 2 }, { text: 'option 3', value: 3 }]}
                  value={sortBy}
                  onChange={setSortBy}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className='mt-4'>
        <h3>components/common/infoTextContainer</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                InfoTextContainer
              </td>
              <td>
                Shows an info icon with a text to the right of it, contained in a gray box
              </td>
              <td>
                <InfoTextContainer
                  text='This is a text'
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className='mt-4'>
        <h3>components/feedback/feedbackCounter</h3>
        <table className='table'>
          <thead>
            <tr>
              <th>Component Name</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                FeedbackCounter
              </td>
              <td>
                Show count for feedback, with thumbs up and down icons
              </td>
              <td>
                <FeedbackCounter
                  feedbackable={{
                    id: 1,
                    helpfulFeedbackCount: 25,
                    notHelpfulFeedbackCount: 4,
                    showFeedbackRatings: true,
                    currentUserFeedbackId: null,
                  }}
                  feedbackableType='pages'
                  updateWorkingCopy={() => {}}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default OtherComponentsPage
