import React, { useState } from 'react'
import moment from 'moment'
import DatePicker from 'components/form_fields/datePicker'
import { i18nLabelWithTimeZone, i18nPath } from 'utils/i18nHelpers'
import currentTimezone from 'utils/currentTimezone'
import { Row, Col } from 'react-bootstrap'

const EventDateTimePicker = ({
  className,
  dateTime,
  defaultExplanationText,
  helperText = null,
  id,
  label,
  required = true,
  onChange,
  showDefaultExplanationText = false,
  startImmediatelyHandler = null,
}) => {
  const [hasChanged, setHasChanged] = useState(false)

  const onChangeHandler = (momentDateObj) => {
    setHasChanged(true)
    onChange(momentDateObj)
  }

  const labelClass = required ? 'required-form-label' : 'form-label'

  return (
    <Row className='EventDateTimePicker'>
      <div className='col form-group'>
        <label className={labelClass}>{i18nLabelWithTimeZone(label, currentTimezone())}</label>
        <Row>
          <Col md={6}>
            <DatePicker
              className={className}
              id={id}
              selected={dateTime && moment(dateTime)}
              onChange={onChangeHandler}
              showTimeSelect
              isClearable
              dateFormat='LLL'
            >
              {startImmediatelyHandler && (
                <div className='react-datepicker-children text-center p-2'>
                  <a href='#' onMouseDown={startImmediatelyHandler}>
                    {I18n.t('views.common.start_immediately')}
                  </a>
                </div>
              )}
            </DatePicker>
            {helperText && (
              <div className='text-secondary text-small mt-1'>{helperText}</div>
            )}
          </Col>
          {/* showDefaultExplanationText is set to false in editEventPage */}
          {showDefaultExplanationText && !hasChanged && (
            <Col md={6} className='pt-2'>
              <span className='text-secondary'>{defaultExplanationText}</span>
            </Col>
          )}
        </Row>
      </div>
    </Row>
  )
}

export default EventDateTimePicker
