import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import qnaEventSlice, { buildEventPayload } from 'redux/slices/qnaEvents'
import useBeforeUnload from 'components/common/hooks/useBeforeUnload'
import usePrevious from 'components/common/hooks/usePrevious'

import GeneralSettings from 'components/qna/settings_modal/general_settings'
import ArchiveEventModal from 'components/qna/settings_modal/general_settings/archiveEventModal'
import DeleteModal from 'components/common/modals/deleteModal'
import EventSchedule from 'components/qna/settings_modal/event_schedule'
import QNASettings from 'components/qna/settings_modal/qna_settings'
import AudienceSettings from 'components/qna/settings_modal/audience_settings'
import ScrollToModal from 'components/common/scrollToModal'
import { LoadingContainer } from 'components/common/loadingContainer'
import userSlice from 'redux/slices/users'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'

const I18N = i18nPath('views.qna.events.settings_modal')
const I18NGeneralSettings = i18nPath('views.qna.events.settings_modal.general_settings')

const EventSettingsModal = ({ showSettingsModal, setShowSettingsModal, event: initialEvent }) => {
  const [showArchiveEventModal, setShowArchiveEventModal] = useState()
  const prevShowArchiveEventModal = usePrevious(showArchiveEventModal)
  const [showDeleteEventModal, setShowDeleteEventModal] = useState()
  const prevShowDeleteEventModal = usePrevious(showDeleteEventModal)
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const [event, setEvent] = useState()

  const dispatch = useDispatch()
  const history = useHistory()
  const { eventId } = useParams()
  const { meta } = useSelector(qnaEventSlice.selectors.getEditEventPageData(eventId))
  const targetingOptions = useTargetingOptions()
  const activeUsersCount = useSelector(userSlice.selectors.getActiveUsersCount())

  // minus the current user
  const colleaguesCount = activeUsersCount - 1

  const {
    isSaving, isLoading: isLoadingEvent, isLoadingSettings, isDeleting, isArchiving,
  } = meta

  const showLoader = isLoadingSettings || !event || isDeleting || isArchiving

  const wereChangesMade = !_.isEqual(buildEventPayload(event), buildEventPayload(initialEvent))

  // Prevents user from reloading page if changes are pending
  useBeforeUnload((e) => {
    if (shouldBlockNavigation) {
      e.preventDefault()
      e.returnValue = ''
    }
  })


  const openArchiveEventModal = () => {
    setShowSettingsModal(false)
    setShowArchiveEventModal(true)
  }

  const openDeleteEventModal = () => {
    setShowSettingsModal(false)
    setShowDeleteEventModal(true)
  }

  const closeArchiveEventModal = () => {
    setShowArchiveEventModal(false)
    setShowSettingsModal(true)
  }

  const closeDeleteEventModal = () => {
    setShowDeleteEventModal(false)
    setShowSettingsModal(true)
  }

  // Called when user clicks 'x' or blurs modal
  const handleEventSettingsModalClose = () => {
    if (wereChangesMade) {
      if (confirm(I18N('blocked_navigation_prompt_message'))) {
        setShouldBlockNavigation(false)
        setShowSettingsModal(false)
        history.push(`/events/${event.slug}`)
      } else {
        setShouldBlockNavigation(true)
        return
      }
    }

    setShowSettingsModal(false)
    history.push(`/events/${event.slug}`)
  }

  const saveEvent = (e) => {
    dispatch(
      qnaEventSlice.asyncActions.admin.update(event, null, () => {
        setShouldBlockNavigation(false)
        setShowSettingsModal(false)
        history.push(`/events/${event.slug}`)
      })
    )
  }

  const deleteEvent = (e) => {
    dispatch(qnaEventSlice.asyncActions.admin.destroy(event, history))
  }

  const archiveEvent = (e) => {
    dispatch(qnaEventSlice.asyncActions.admin.archive(event, history))
  }

  // Prevents data fetching when interacting with
  // the delete event and archieve event modals.
  useEffect(() => {
    if (showSettingsModal && !prevShowArchiveEventModal && !prevShowDeleteEventModal) {
      dispatch(qnaEventSlice.asyncActions.admin.fetch(eventId))
      dispatch(userSlice.asyncActions.fetchActiveUsersCount())
    }
  }, [eventId, showSettingsModal])

  // Initialize event form data
  useEffect(() => {
    setEvent(initialEvent)
  }, [isLoadingSettings, initialEvent?.id])

  useEffect(() => {
    if (_.isEmpty(initialEvent) || isLoadingSettings) return

    setShouldBlockNavigation(wereChangesMade)
  }, [event])

  const sections = [
    {
      id: 'general',
      header: I18N('general'),
      content: (
        <GeneralSettings
          event={event}
          openArchiveEventModal={openArchiveEventModal}
          openDeleteEventModal={openDeleteEventModal}
        />
      ),
    },
    {
      id: 'audience',
      header: I18N('audience'),
      content: (
        <AudienceSettings
          workingCopy={event}
          setWorkingCopy={setEvent}
          targetingOptions={targetingOptions}
          setIsSaveButtonDisabled={setIsSaveButtonDisabled}
          colleaguesCount={colleaguesCount}
        />
      ),
    },
    {
      id: 'schedule',
      header: I18N('schedule'),
      content: <EventSchedule event={event} setEvent={setEvent} defaultDateTimeValues={false} />,
    },
    {
      id: 'q&a',
      header: I18N('q&a'),
      content: event?.id && <QNASettings event={event} setEvent={setEvent} />,
    },
  ]

  return (
    // The !isLoadingEvent prevents a doupble spinner from showing
    // When loading the event detail page
    <LoadingContainer isLoading={showLoader && !isLoadingEvent}>
      <div className='EventSettingsModal'>
        {showSettingsModal && (
          <ScrollToModal
            visible={showSettingsModal}
            sections={sections}
            onSave={saveEvent}
            onClose={handleEventSettingsModalClose}
            isSaving={isSaving}
            isSaveButtonDisabled={isSaving || isSaveButtonDisabled}
          />
        )}
        <ArchiveEventModal
          event={event}
          showArchiveEventModal={showArchiveEventModal}
          closeArchiveEventModal={closeArchiveEventModal}
          archiveEvent={archiveEvent}
        />
        <DeleteModal
          deleteText={I18NGeneralSettings('delete_event')}
          cancelText={I18NGeneralSettings('cancel')}
          deleteSecondaryText={I18NGeneralSettings('delete_now_text')}
          deleteConfirmText={I18NGeneralSettings('confirm_delete')}
          showDeleteModal={showDeleteEventModal}
          closeDeleteModal={closeDeleteEventModal}
          deleteConfirm={deleteEvent}
        />
        {/* Triggered before users changes location */}
      </div>
    </LoadingContainer>
  )
}

export default EventSettingsModal
