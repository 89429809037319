import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import GreetingCardComment from 'components/celebrations/greetingCardComment'
import useWindowResize from 'components/common/hooks/useWindowResize'


const CelebrationSocialComments = ({
  celebration,
  isCurrentUser,
  comments,
}) => {
  const history = useHistory()
  const { isUpToMediumScreen } = useWindowResize()
  const mustOpenCard = isCurrentUser && !celebration.openedAt

  // we have up to 3 comments loaded, we will show 2 of them in large screens and 1 in small screens
  // to reduce alignment issues, we will attempt to either show 2 with gifs or 2 without gifs
  const maximumCommentsToShow = isUpToMediumScreen ? 1 : 2

  const getCommentsToShow = () => {
    if (_.isEmpty(comments)) {
      return []
    }

    if (comments.length <= maximumCommentsToShow) {
      return comments
    }

    const groupedComments = _.groupBy(comments, comment => (comment.gifUrl ? 'withGifs' : 'withoutGifs'))

    if (groupedComments.withGifs && groupedComments.withGifs.length >= maximumCommentsToShow) {
      return groupedComments.withGifs.slice(0, maximumCommentsToShow)
    } else {
      return groupedComments.withoutGifs.slice(0, maximumCommentsToShow)
    }
  }

  const commentsToShow = getCommentsToShow()

  return (
    <div className='CelebrationSocialComments d-flex w-100 mt-3'>
      {(!mustOpenCard && commentsToShow.map(comment => (
        <GreetingCardComment
          className={classNames('mx-2', commentsToShow.length === 2 ? 'w-50' : 'w-100')}
          comment={comment}
          key={`comment-${comment.id}`}
          showCommentsOnInit={false}
          onToggleComments={() => history.push(`/people/celebrations/${celebration.id}?source=feed`)}
          hideEditedTag
        />
      )))}
    </div>
  )
}

export default CelebrationSocialComments
