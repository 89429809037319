import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.font_weight_control')

interface Props {
  variable: string
  value: string
  onChange: (type: string, property: string, value: string) => void
  header?: string
}

const FontWeightControl = ({
  variable, value, onChange, header = '',
}) => {
  const handleChange = (ev) => {
    const { value } = ev.target
    onChange('fontWeight', variable, value)
  }

  return (
    <div className='FontWeightControl d-flex justify-content-between align-content-start'>
      <span>
        <label className='label'>{header || I18N('header')}</label>
        {' '}
        {value}
        <p>
          <a href=''>{I18N('link')}</a>
        </p>
      </span>
      <span className='control'>
        <select className='form-control' onChange={ev => handleChange(ev)} value={value}>
          <option>100</option>
          <option>200</option>
          <option>300</option>
          <option>400</option>
          <option>500</option>
          <option>600</option>
          <option>700</option>
          <option>800</option>
          <option>900</option>
          <option value='bold'>{I18N('bold')}</option>
          <option value='bolder'>{I18N('bolder')}</option>
          <option value='lighter'>{I18N('lighter')}</option>
          <option value='normal'>{I18N('normal')}</option>
        </select>
      </span>
    </div>
  )
}

export default FontWeightControl
