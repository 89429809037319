import CdnSvg from 'components/common/cdnSvg'
import PersonalLinkModal from 'components/home/widgets/links_widget/personalLinkModal'
import React, { useState } from 'react'
import Tooltip from 'components/common/clearyTooltip'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.home.links_widget')

const AddLinkButton = ({ widgetId, onLinkAdded }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Tooltip
        placement='bottom'
        content={(
          <div className='AddLinkButton pointer' onClick={() => setIsModalOpen(true)}>
            <CdnSvg src='/images/plusIcon.svg' />
          </div>
        )}
      >
        {I18N('add_personal_link')}
      </Tooltip>
      {isModalOpen && (
        <PersonalLinkModal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(false)}
          link={{
            name: '', url: '', recordId: null, recordType: null,
          }}
          widgetId={widgetId}
          onSave={onLinkAdded}
        />
      )}
    </>
  )
}

export default AddLinkButton
