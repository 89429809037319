import React, { useEffect, useState } from 'react'
import ModalImageCrop from 'components/common/image_crop/modalImageCrop'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

const ProfilePhotoReposition = ({
  photo, slice, owner, className, onRepositionChange = () => {},
}) => {
  const [reposition, setReposition] = useState(false)

  useEffect(() => {
    onRepositionChange(reposition)
  }, [reposition])

  const dispatch = useDispatch()

  const showReposition = (e) => {
    e.stopPropagation()
    e.preventDefault()

    setReposition(true)
  }

  const onCropSave = async ({ croppedAreaPixels }) => {
    await dispatch(slice.asyncActions.updatePhoto({
      ...photo,
      imageCroppedArea: croppedAreaPixels,
    }, owner))

    setReposition(false)
  }

  return (
    <>
      <ModalImageCrop
        visible={reposition}
        imageSrc={photo.originalImageUrl}
        onCancel={() => { setReposition(false) }}
        onSave={onCropSave}
        aspect={1}
        initialCroppedArea={photo.imageCroppedArea}
        cropShape='round'
      />

      <div className={classNames('ProfilePhotos-reposition', 'p-2', className)} onClick={showReposition}>
        {I18n.t('views.profile.photos.reposition')}
      </div>
    </>
  )
}

export default ProfilePhotoReposition
