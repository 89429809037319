import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CardWithTable from 'components/analytics/common/cardWithTable'
import { Link, useLocation } from 'react-router-dom'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import { PopularPageType } from 'types/analytics/pages'
import TargetingRulesType from 'types/audience/targetingRules'
import { i18nPath } from 'utils/i18nHelpers'
import usePathReusingQueryParams from 'components/common/hooks/usePathReusingQueryParams'
import { TAB_HEADER_QUERY_PARAMS } from 'components/analytics/helpers/useTabHeader'

const I18N = i18nPath('views.platform_analytics.pages')

const columns = [
  {
    header: I18N('page'),
    col: 'col-title',
    style: { width: '70%' },
    accessor: (page: PopularPageType) => <Link to={`/platform_analytics/pages/${page.id}`} className='text-truncate w-100 d-block'>{page.title}</Link>,
  },
  {
    header: I18N('total_views'),
    col: 'col-views',
    style: { width: '30%' },
    accessor: (page: PopularPageType) => page.viewCount,
  },
]

interface PopularPagesProps {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
  order: string
}


const PopularPages = ({
  order, periodStart, periodEnd, targetingRules,
}: PopularPagesProps) => {
  const dispatch = useDispatch()

  const { data, totalPages } = useSelector(pageAnalyticsSlice.selectors.getPopularPages(order))

  const { popularPages: popularPagesMeta } = useSelector(pageAnalyticsSlice.selectors.getMetaData())

  useEffect(() => {
    const params = {
      periodStart,
      periodEnd,
      targetingRules,
      perPage: 10,
      page: 1,
      order,
    }

    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchPopularPages(order, params))
  }, [periodStart, order, periodEnd, targetingRules])

  const path = usePathReusingQueryParams({
    path: '/platform_analytics/pages_analytics/popular_pages',
    paramsToReuse: TAB_HEADER_QUERY_PARAMS,
    otherQueryParams: { order },
  })

  return (
    <CardWithTable
      title={order === 'least' ? I18N('least_popular_pages') : I18N('most_popular_pages')}
      tableColumns={columns}
      tableData={data}
      classname='PagesTables'
      isLoading={popularPagesMeta[order].isLoading}
      showSeeMoreLink={totalPages > 1}
      seeMorePath={path}
    />
  )
}

export default PopularPages
