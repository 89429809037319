import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import Picture from 'components/common/images/picture'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import GroupInfoPopover from 'components/common/groupInfoPopover'
import newHireSlice from 'redux/slices/journeys/newHires'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import RemoveNewHireButtonAndModal from 'components/admin/journeys/onboardingBuddies/removeNewHireButtonAndModal'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.new_hire_carousel')

const GroupTypeSection = ({ groupType, groups }) => (
  <div className='mb-3'>
    <div className='font-weight-600'>{groupType.name}</div>
    {groups.map((group, index) => (
      <React.Fragment key={`group-${group.id}`}>
        <GroupInfoPopover group={group} />
        {index !== groups.length - 1 && <span>,&nbsp;</span>}
      </React.Fragment>
    ))}
  </div>
)

const GroupsSection = ({ groups, isLoading }) => {
  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  if (!groups) {
    return null
  }

  const activeVisibleGroups = groups.filter(group => group.active && group.groupType.viewable)
  const groupTypes = _.sortBy(_.uniq(activeVisibleGroups.map(group => group.groupType)), 'orderPosition')
  const groupedGroups = _.groupBy(activeVisibleGroups, group => group.groupType.id)

  return (
    <>
      {groupTypes.map(groupType => (
        <GroupTypeSection key={`groupType-${groupType.id}`} groupType={groupType} groups={groupedGroups[groupType.id]} />
      ))}
    </>
  )
}

const NewHireCarouselCard = ({ user }) => {
  const {
    id,
    defaultPhotoUrl,
    preferredFullName,
    startDate,
    parent: manager,
    groups,
    photos,
    title,
    city,
  } = user
  const primaryPicture = _.find(photos, { primary: true })
  const {
    settings: {
      onboardingBuddySettings: { marketplace: { enabled: isMarketplaceEnabled } },
    },
  } = useCurrentCompany()

  const { isLoadingGroups } = useSelector(newHireSlice.selectors.getMetaData())

  return (
    <Card
      className='NewHireCarouselCard'
      hasBoxShadow={false}
    >
      <div className='d-flex align-items-center position-absolute top-0 right-0 mr-2'>
        {isMarketplaceEnabled && (
          <Link
            to={`/onboarding_buddies?new_hire_id=${id}`}
            className='mr-2'
          >
            <CdnSvg src='/images/eyeOpenIcon.svg' className='mr-2' />
            {I18N('view_buddy_selection_page')}
          </Link>
        )}
        <RemoveNewHireButtonAndModal user={user} />
      </div>
      <div className='d-flex my-2'>
        <Picture
          owner={user}
          picture={primaryPicture}
          defaultPhotoUrl={defaultPhotoUrl}
          showRemoveIcon={false}
          className='mr-5 NewHireCarouselCardPicture'
        />
        <div className='w-100'>
          <h2>{preferredFullName}</h2>
          <div className='d-flex w-100'>
            <div className='w-50'>
              <h6 className='d-inline mr-1'>{title}</h6>
              {city && (
                <>
                  <span className='text-secondary'>{I18NCommon('in')}</span>
                  <h6 className='d-inline ml-1'>{city}</h6>
                </>
              )}
              {manager && (
                <div className='d-flex align-items-center mt-3'>
                  <strong className='font-weight-600 mr-1'>{I18NCommon('manager')}:</strong>
                  <EmployeeThumbnailPhoto employee={manager} size='24px' className='mr-2' />
                  <a href={`/profile/${manager?.username}`} className='font-weight-500 text-primary-link'>{manager?.preferredFullName}</a>
                </div>
              )}
              <p className='mt-3'><strong className='font-weight-600'>{I18NCommon('start_date')}:</strong> {i18nMoment(startDate).format('LL')}</p>
            </div>
            <div className='w-50'>
              <GroupsSection groups={groups} isLoading={isLoadingGroups} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default NewHireCarouselCard
