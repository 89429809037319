import CcCorrespondentSelect from 'components/admin/journeys/ccCorrespondentSelect'
import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import { present } from 'components/common/utils'
import TrashIcon from 'components/icons/trashIcon'
import React, { useEffect } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.journeys.step_templates.cc_correspondents')
const emptyCcCorrespondent = { role: null, users: [], emails: [] }

const CcButtons = ({
  index,
  count,
  removeCcCorrespondent,
  addCcCorrespondent,
}) => (
  <>
    <div className='pointer' onClick={() => removeCcCorrespondent(index)}>
      <TrashIcon className='TrashIcon ml-2 mr-1' />
    </div>

    {index === count - 1 && count < 6 && (
      <Button onClick={addCcCorrespondent} variant='link'>
        <CdnSvg className='PlusIcon' src='/images/thinPlusIcon.svg' />
      </Button>
    )}
  </>
)

const CcCorrespondentSelector = ({
  journeyBlueprintType,
  setShowCCSelector,
  workingCopy,
  updateWorkingCopy,
  isDisabled = false,
}) => {
  const { ccCorrespondentsAttributes = [emptyCcCorrespondent] } = workingCopy
  const correspondentsCount = ccCorrespondentsAttributes.length
  const selectedRoles = ccCorrespondentsAttributes.map(cc => cc.role).filter(present)

  const updateCcCorrespondent = (newCc, index) => {
    const newCcCorrespondents = [...ccCorrespondentsAttributes]
    newCcCorrespondents[index] = newCc
    updateWorkingCopy({ ccCorrespondentsAttributes: newCcCorrespondents })
  }

  const addCcCorrespondent = () => {
    updateWorkingCopy({ ccCorrespondentsAttributes: [...ccCorrespondentsAttributes, emptyCcCorrespondent] })
  }

  const removeCcCorrespondent = (index: number) => {
    if (correspondentsCount === 1) {
      updateWorkingCopy({ ccCorrespondentsAttributes: [] })
      setShowCCSelector(false)
      return
    }

    const newCcCorrespondents = [...ccCorrespondentsAttributes]
    newCcCorrespondents.splice(index, 1)
    updateWorkingCopy({ ccCorrespondentsAttributes: newCcCorrespondents })
  }

  useEffect(() => {
    if (!ccCorrespondentsAttributes || _.isEmpty(ccCorrespondentsAttributes)) {
      addCcCorrespondent()
    }
  }, [])

  return (
    <div className='CcCorrespondentSelector mb-3'>
      <label>{I18N('cc')}</label>
      {ccCorrespondentsAttributes.map((ccCorrespondent, index) => (
        <div className='d-flex flex-row align-items-center mb-2' key={`cc-correspondent-${ccCorrespondent.role}-${index}`}>
          <CcCorrespondentSelect
            onChange={newCC => updateCcCorrespondent(newCC, index)}
            ccCorrespondent={ccCorrespondent}
            journeyBlueprintType={journeyBlueprintType}
            selectedRoles={selectedRoles}
            isDisabled={isDisabled}
          />

          {!isDisabled && (
            <CcButtons
              index={index}
              count={correspondentsCount}
              removeCcCorrespondent={removeCcCorrespondent}
              addCcCorrespondent={addCcCorrespondent}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default CcCorrespondentSelector
