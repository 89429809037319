import React from 'react'
import { Bar } from 'react-chartjs-2'

interface DataPoint {
  label: string
  count: number
}

interface MonthlyChartProps {
  data: DataPoint[]
}

const MonthlyChart: React.FC<MonthlyChartProps> = ({ data }) => (
  <Bar
    width={390}
    height={250}
    options={{
      scales: {
        y: {
          ticks: {
            maxTicksLimit: 3,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    }}
    data={{
      labels: data.map(d => d.label),
      datasets: [
        {
          data: data.map(d => d.count),
          label: 'Questions',
          borderRadius: 10,
          barThickness: 10,
          backgroundColor: '#0062FF',
        },
      ],
    }}
  />
)

export default MonthlyChart
