import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars')

const EditTitleSetting = ({ widget, updateWidget }) => {
  const updateTitle = (value) => {
    updateWidget({ ...widget, title: value })
  }

  return (
    <div className='w-100 mb-3'>
      <label>{I18N('widget_title')}</label>
      <input
        className='w-100'
        value={widget.title || ''}
        onChange={e => updateTitle(e.target.value)}
      />
    </div>
  )
}

export default EditTitleSetting
