import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'

import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.search.global_search')

const GlobalSearch = () => {
  const currentUser = useCurrentUser()

  return (
    <div className='GlobalSearch'>
      <ReactSelect
        name='form-field-name'
        getOptionValue={user => user.username}
        getOptionLabel={user => (
          <span className='d-flex align-items-center'>
            <EmployeeThumbnailPhoto employee={user} size='22px' className='mr-1' />

            <span className='d-flex align-items-center h-100 text-normal'>{user.preferredFullName}</span>
          </span>
        )}
        options={[
          currentUser,
        ]}
        placeholder={I18N('search_placeholder')}
      />
    </div>
  )
}

export default GlobalSearch
