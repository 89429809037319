import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import pageCoverImageSlice, { pageTemplateCoverImageSlice } from 'redux/slices/pageCoverImage'

import CoverImageWidget from 'components/common/images/coverImageWidget'

const I18N = i18nPath('views.pages')

const PageCoverImage = ({
  page: {
    id,
    coverImageUrl,
    originalCoverImageUrl,
    coverImageCroppedArea,
    draftCoverImageUrl,
    originalDraftCoverImageUrl,
    draftCoverImageCroppedArea,
  },
  showActions = false,
  showFullWidth = false,
  type = 'page',
  showDraftColumns = false,
  options = {},
}) => (
  <CoverImageWidget
    withReposition
    className='PageCoverImage position-relative d-flex flex-column'
    showActions={showActions}
    coverImageUrl={showDraftColumns ? draftCoverImageUrl : coverImageUrl}
    id={id}
    showFullWidth={showFullWidth}
    originalCoverImageUrl={showDraftColumns ? originalDraftCoverImageUrl : originalCoverImageUrl}
    coverImageCroppedArea={showDraftColumns ? draftCoverImageCroppedArea : coverImageCroppedArea}
    addCoverButtonClassName='ml-5'
    coverImageGuidanceText={<div className='ml-5 mt-2 text-secondary text-small'>{I18N('cover_image_guidance')}</div>}
    slice={type === 'page' ? pageCoverImageSlice : pageTemplateCoverImageSlice}
    defaultCropParams={{
      aspect: 1579 / 275,
      objectFit: 'horizontal-cover',
    }}
    options={options}
  />
)

export default PageCoverImage
