import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies')

const EmptyNewHires = () => (
  <div className='d-flex flex-column w-100 my-5 justify-content-center align-items-center'>
    <CdnSvg src='/images/illustrations/womanWithLens.svg' />
    <span className='text-center mt-4 font-weight-400 text-secondary'>
      {I18N('no_new_hires_need_buddies')}
    </span>
  </div>
)

export default EmptyNewHires
