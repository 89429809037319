import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TrashIcon from 'components/icons/trashIcon'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import groupSlice from 'redux/slices/groups'
import groupFollowersSlice from 'redux/slices/groupFollowers'
import userSlice from 'redux/slices/users'
import Modal from 'components/common/modal'
import { Button, ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import SpecificUsersSelect from 'components/common/audience/specificUsersSelect'
import GroupSelectField from 'components/form_fields/groupSelectField'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const personsIconPath = '/images/personsIcon.svg'

const I18NModal = i18nPath('views.groups.edit_followers_modal')


const GroupFollowerCount = ({ count }) => (
  <div className='GroupFollowerCount'>
    <CdnSvg src={personsIconPath} className='mr-2' />
    {I18NModal('people', { count })}
  </div>
)

const GroupFollowersSelector = ({
  workingCopy,
  setWorkingCopy,
  onClose,
}) => {
  const onChangeGroups = (values) => {
    const newWorkingCopy = { ...workingCopy, activeGroupFollowers: [...values] }
    setWorkingCopy(newWorkingCopy)
  }

  const onRemoveGroups = () => {
    onChangeGroups([])
    onClose()
  }

  return (
    <div className='d-flex align-items-center gap-3 mt-3'>
      <GroupSelectField
        onChange={onChangeGroups}
        value={workingCopy.activeGroupFollowers}
      />
      <div className='pointer py-2 px-1' onClick={onRemoveGroups}>
        <TrashIcon className='TrashIcon mr-1' />
      </div>
    </div>
  )
}


const EditFollowersModal = ({ groupId, onClose }) => {
  const dispatch = useDispatch()

  const group = useSelector(groupSlice.selectors.getTeam(groupId))
  const { activeGroupFollowers = [], activeUserFollowerIds = [] } = group

  const activeUserFollowers = useSelector(userSlice.selectors.getSimpleUsersByIds(activeUserFollowerIds))
  const estimatedCount = useSelector(groupFollowersSlice.selectors.getEstimatedCount())

  const [showSpecificUsersSelect, setShowSpecificUsersSelect] = useState(!_.isEmpty(activeUserFollowerIds))
  const [showGroupsSelect, setShowGroupsSelect] = useState(!_.isEmpty(activeGroupFollowers))
  const [workingCopy, setWorkingCopy] = useState({ activeGroupFollowers, activeUserFollowers })

  const [updateFollowers, { isLoading: isUpdatingFollowers }] = useApi(
    API.groups.follows.bulkUpdate,
    { updateEntitySlice: true, onSuccess: onClose }
  )

  const missingUserIds = useMemo(() => {
    const existingUserIds = new Set(activeUserFollowers.map(user => user.id))
    return activeUserFollowerIds.filter(id => !existingUserIds.has(id))
  }, [activeUserFollowerIds, activeUserFollowers])

  useEffect(() => {
    if (missingUserIds.length > 0) {
      dispatch(userSlice.asyncActions.simpleFetchAll(
        missingUserIds,
        () => {},
        { includeInactiveUsers: false, includeHiddenUsers: false }
      ))
    }
  }, [activeUserFollowerIds])

  useEffect(() => {
    const userIds = workingCopy.activeUserFollowers.map(user => user.id)
    const groupIds = workingCopy.activeGroupFollowers.map(group => group.id)
    dispatch(groupFollowersSlice.asyncActions.estimateCount(groupId, userIds, groupIds))
  }, [workingCopy])

  const updateUsers = (users) => {
    setWorkingCopy({ ...workingCopy, activeUserFollowers: users })
  }

  const removeSpecificUsers = () => {
    updateUsers([])
    setShowSpecificUsersSelect(false)
  }

  const onSave = () => {
    const userIds = workingCopy.activeUserFollowers.map(user => user.id)
    const groupIds = workingCopy.activeGroupFollowers.map(group => group.id)
    updateFollowers(group.id, userIds, groupIds)
  }

  return (
    <Modal
      visible
      size='lg'
      toggle={onClose}
      className='EditFollowersModal'
      modalContentClassName='p-3'
    >
      <header className='d-flex align-items-center mb-2'>
        <h4 className='mb-0 mr-2'>{I18NModal('title')}</h4>
        <GroupFollowerCount count={estimatedCount} />
      </header>
      <span className='text-secondary font-weight-400'>{I18NModal('sub_title')}</span>
      {showGroupsSelect && (
        <GroupFollowersSelector
          workingCopy={workingCopy}
          setWorkingCopy={setWorkingCopy}
          onClose={() => setShowGroupsSelect(false)}
        />
      )}
      <div className='d-flex align-items-center gap-3 mt-3'>
        {!showGroupsSelect && (
          <Button variant='tag' onClick={() => setShowGroupsSelect(true)}>
            + {I18NModal('add_a_team')}
          </Button>
        )}
        {!showSpecificUsersSelect && (
          <Button variant='tag' onClick={() => setShowSpecificUsersSelect(true)}>
            + {I18NModal('add_specific_people')}
          </Button>
        )}
      </div>
      {showSpecificUsersSelect && (
        <SpecificUsersSelect
          selectedUsers={workingCopy?.activeUserFollowers}
          onUsersChange={updateUsers}
          onRemoveSpecificUsers={removeSpecificUsers}
          csvTooltipText={I18NModal('csv_tooltip_text')}
        />
      )}
      <div className='d-flex justify-content-end align-items-center'>
        <CancelButton onClick={onClose} />
        <ButtonNarrow
          onClick={onSave}
          disabled={isUpdatingFollowers}
          showLoadingSpinner={isUpdatingFollowers}
        >
          {I18NCommon('save')}
        </ButtonNarrow>
      </div>
    </Modal>
  )
}


export default EditFollowersModal
