import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'
import DueAtTextComponent from 'components/journeys/tasks/dueAtTextComponent'
import AssignTaskSelect from 'components/admin/journeys/tasks/assignTaskSelect'

const I18N = i18nPath('views.journeys.taskable_detail')

const DueDateAndForLine = ({
  taskable,
  toUser,
  currentUser,
  forUser,
}) => {
  const journeyBlueprintType = taskable?.step?.journey?.journeyBlueprint?.type
  const showJourneyTypeText = journeyBlueprintType && journeyBlueprintType !== 'custom'
  const isUnassignedGroupTask = taskable?.step?.toCorrespondent?.role === 'specific_group' && !toUser

  return (
    <div>
      <div className='text-small mb-3'>
        <DueAtTextComponent task={taskable} className='text-small link-color' />
        {' • '}
        {showJourneyTypeText && (
          <>
            <span className='text-small'>{I18N(`journey_type_text.${journeyBlueprintType}`)}</span>
            {' • '}
          </>
        )}
        <span className='text-small text-secondary d-inline-flex align-items-center'>
          {I18N('for')}
          <EmployeeOrCorrespondentLink
            className='ml-1'
            user={forUser}
            textClassName='text-small'
            avatarClassName='mr-1'
            linkToAdmin={false}
          />
        </span>
        {
          !isUnassignedGroupTask && (currentUser?.id !== toUser?.id) && (
            <>
              {' • '}
              <span className='text-small text-secondary d-inline-flex align-items-center'>
                {I18N('assigned_to')}
                <EmployeeOrCorrespondentLink
                  className='ml-1'
                  user={toUser}
                  textClassName='text-small'
                  avatarClassName='mr-1'
                  linkToAdmin={false}
                />
              </span>
            </>
          )
        }
      </div>
      {isUnassignedGroupTask && (
        <div className='d-flex align-items-center'>
          <span className='text-small text-secondary mr-1'>{I18N('assigned_to')}</span>
          <AssignTaskSelect step={taskable.step} toCorrespondent={taskable.step?.toCorrespondent} />
        </div>
      )}
    </div>
  )
}

export default DueDateAndForLine
