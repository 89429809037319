import React from 'react'
import { useHistory } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import CompanyLogo from 'components/common/companyLogo'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import pageTemplateSlice from 'redux/slices/pageTemplates'
import HamburgerMenuIcon from 'components/icons/hamburgerMenuIcon'

import useSetCSSVarOnMount from 'components/common/hooks/useSetCSSVarOnMount'
import EditTemplateDropdownMenu from 'components/pages/editTemplateDropdownMenu'
import { useSelector } from 'react-redux'

const I18N = i18nPath('views.pages')
const I18NCommon = i18nPath('views.common')

interface EditTemplateToolbarProps {
  areChangesPresent?: boolean
  onClickSave: () => void
  onClickHamburgerNav: () => void
  resetWorkingCopy?: () => void
  workingCopy: any
  setWorkingCopy: (fields) => void
}

const EditTemplateToolbar = ({
  areChangesPresent = false,
  onClickSave,
  onClickHamburgerNav,
  resetWorkingCopy = () => {},
  workingCopy,
  setWorkingCopy,
}: EditTemplateToolbarProps) => {
  const history = useHistory()
  const currentUser = useCurrentUser()

  const { isSaving } = useSelector(pageTemplateSlice.selectors.getMetaData())


  useSetCSSVarOnMount('--nav-box-shadow', 'none')

  const handleClose: () => void = () => {
    if (!areChangesPresent) {
      history.push('/pages')
    } else if (areChangesPresent && window.confirm(I18N('blocked_navigation_prompt_message'))) {
      history.push('/pages')
      resetWorkingCopy()
    }
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    if (workingCopy.baseTemplate) {
      if (currentUser.permissions?.clearyAdmin && window.confirm(I18N('base_template_update_message'))) {
        onClickSave()
      }
    } else {
      onClickSave()
    }
  }

  return (
    <div className='EditTemplateToolbar px-0 d-flex flex-row-reverse justify-content-between w-100'>
      <div className='pr-2 pr-sm-3 gap-1 gap-sm-3 d-flex align-items-center justify-content-end h-100'>
        <Button
          onClick={handleUpdate}
          disabled={!areChangesPresent || isSaving}
          showLoadingSpinner={isSaving}
        >
          {I18N('update_template')}
        </Button>
        <CancelButton onClick={handleClose}>
          {I18NCommon('close')}
        </CancelButton>
        <EditTemplateDropdownMenu
          workingCopy={workingCopy}
          areChangesPresent={areChangesPresent}
        />
      </div>
      <div className='d-flex d-md-none align-items-center justify-content-start h-100'>
        <div className='Toolbar-link d-flex align-items-center' onClick={onClickHamburgerNav}>
          <HamburgerMenuIcon className='ml-3 mr-2' />
        </div>
      </div>
      <CompanyLogo className='Navbar-companyImage px-3 pb-2 pt-3' />
    </div>
  )
}

export default EditTemplateToolbar
