import React from 'react'

import ReactSelect from 'components/common/react_select'
import { i18nPath } from 'utils/i18nHelpers'
import EditAutoResizable from 'components/admin/layout/layout_collection_sidebar/common/editAutoResizable'

const I18N = i18nPath('views.admin.home_editor.widget_sidebars.shoutout_suggestions')

const WIDGET_ORIENTATION_OPTIONS = [
  { value: 'horizontal', label: I18N('horizontal') },
  { value: 'vertical', label: I18N('vertical') },

]

const ShoutoutSuggestionsWidgetSettingsSidebar = ({ widget, updateWidget }) => {
  const updateSettings = (key, value) => {
    updateWidget({ ...widget, settings: { ...widget.settings, [key]: value } })
  }

  return (
    <div className='ShoutoutSuggestionsWidgetSettingsSidebar'>
      <h5>{I18N('title')}</h5>

      <div className='w-100'>
        <label>{I18N('orientation')}</label>
        <ReactSelect
          options={WIDGET_ORIENTATION_OPTIONS}
          onChange={option => updateSettings('orientation', option.value)}
          value={WIDGET_ORIENTATION_OPTIONS.find(orientation => orientation.value === widget.settings.orientation)}
        />
      </div>

      {widget.settings.orientation === 'vertical' && (
        <>
          <hr className='w-100' />

          <EditAutoResizable widget={widget} updateWidget={updateWidget} />
        </>
      )}
    </div>
  )
}

export default ShoutoutSuggestionsWidgetSettingsSidebar
