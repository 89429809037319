import React from 'react'
import { useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import surveyFormSlice, { buildFormPayload } from 'redux/slices/surveys/forms'
import useQueryParams from 'components/common/hooks/useQueryParams'

import ExportButton from 'components/common/buttons/exportButton'
import isValidQuestion from 'utils/surveys/isValidQuestion'
import SurveyFormType from 'types/surveys/form'
import {
  QUESTIONS_TAB, RESULTS_TAB, INDIVIDUAL_RESPONSES_TAB
} from 'components/admin/surveys/formHeader'
import SaveFormChangesButton from 'components/admin/surveys/saveFormChangesButton'
import taskTemplateSlice from 'redux/slices/journeys/taskTemplates'
import { Button } from 'components/common/buttons'
import { TASK_TAB } from 'components/admin/journeys/taskTemplates/surveys/formTaskHeader'
import isStepTemplateValid from 'utils/admin/journeys/isStepTemplateValid'
import { nonRequiredFields as taskTemplateNotRequiredFields } from 'components/admin/journeys/taskTemplates/taskForm'

const I18N = i18nPath('views.admin.surveys')

interface FormTaskHeaderButtonsProps {
  form: SurveyFormType
  formWorkingCopy: Partial<SurveyFormType>
  taskTemplate: any
  workingCopy: any
  hasChanges: boolean
  handleSave: (state: string) => void
}

const FormTaskHeaderButtons = ({
  form,
  formWorkingCopy,
  taskTemplate,
  workingCopy,
  hasChanges,
  handleSave,
} : FormTaskHeaderButtonsProps) => {
  const { selectedTab } = useQueryParams()

  const hasFormChanges = !_.isEqual(buildFormPayload(form), buildFormPayload(formWorkingCopy))
  const isDraft = taskTemplate?.stepTemplate?.state === 'draft'

  const questions = formWorkingCopy?.questions || []
  const hasValidQuestions = questions.length > 0 && questions?.every(question => isValidQuestion(question))
  const { isExportingCsv } = useSelector(surveyFormSlice.selectors.getMetaData())
  const { isSaving: isSavingTaskTemplate } = useSelector(taskTemplateSlice.selectors.getMetaData())

  const { isValid: taskTemplateValid } = isStepTemplateValid({
    workingCopy: _.omit(workingCopy, ['taskable']),
    nonRequiredFields: taskTemplateNotRequiredFields,
  })

  if ([RESULTS_TAB, INDIVIDUAL_RESPONSES_TAB].includes(selectedTab)) {
    return (
      <ExportButton
        isLoading={isExportingCsv}
        asyncAction={surveyFormSlice.asyncActions.admin.exportResponses(form.id)}
      />
    )
  }

  if (!isDraft && selectedTab === QUESTIONS_TAB) {
    return (
      <SaveFormChangesButton
        form={form}
        workingCopy={formWorkingCopy}
        disabled={!hasFormChanges || !hasValidQuestions}
        formState='published'
        text={I18NCommon('save_changes')}
      />
    )
  }

  if (selectedTab === TASK_TAB) {
    return (
      <div className='d-flex align-items-end'>
        <Button
          className='mr-2'
          variant={isDraft ? 'secondary' : 'primary'}
          disabled={isSavingTaskTemplate || !hasChanges || !taskTemplateValid}
          showLoadingSpinner={isSavingTaskTemplate}
          onClick={() => handleSave(isDraft ? 'draft' : 'active')}
        >
          {I18NCommon(isDraft ? 'save_as_draft' : 'save_changes')}
        </Button>
        {isDraft && (
          <Button
            disabled={isSavingTaskTemplate || !taskTemplateValid || !hasValidQuestions}
            showLoadingSpinner={isSavingTaskTemplate}
            onClick={() => handleSave('active')}
          >
            {I18N('publish_survey')}
          </Button>
        )}
      </div>
    )
  }

  if (selectedTab === QUESTIONS_TAB) {
    return (
      <SaveFormChangesButton
        form={form}
        workingCopy={formWorkingCopy}
        disabled={!hasValidQuestions}
        text={I18N('customize_task')}
        redirectPath={`${taskTemplate?.adminDisplayPath}?selectedTab=${TASK_TAB}`}
      />
    )
  }

  return null
}

export default FormTaskHeaderButtons
