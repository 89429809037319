import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import Error404 from 'components/errors/404'

import PreboardingPageContent from 'components/preboarding/preboardingPageContent'
import ToggleLeftNavButton from 'components/common/toggleLeftNavButton'
import { trackAmplitudeEvent } from 'services/tracker'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import PreboardingPagesNav from 'components/preboarding/preboardingPagesNav'
import navbarHeight from 'components/navbar/utils/navbarHeight'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const PreboardingPagePage = () => {
  const { pageSlug } = useParams()

  const { data: page, isLoading, isNotFound } = useFetch(
    () => API.journey.preboarding.pages.fetch(pageSlug), [pageSlug]
  )

  const [isDesktopNavOpen, setIsDesktopNavOpen] = useState(true)
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (page?.title && currentUser.preboarding) {
      trackAmplitudeEvent('preboarding_dashboard_page_viewed', { page_title: page.title })
    }
  }, [page?.title])

  const height = currentUser.inMobileApp ? '100vh' : `calc(100vh - ${navbarHeight()}px)`

  return (
    <div
      className='PageNavAndRoutes'
      style={{ height }}
    >
      <HelmetProvider>
        <Helmet>
          <title>{page?.title}</title>
        </Helmet>
      </HelmetProvider>
      <ToggleLeftNavButton
        onClick={() => setIsDesktopNavOpen(!isDesktopNavOpen)}
        isOpen={isDesktopNavOpen}
      />
      <PreboardingPagesNav
        currentPage={page}
        isDesktopNavOpen={isDesktopNavOpen}
      />
      <div className={classNames('WrappedContent', { isDesktopNavOpen })}>
        {isLoading && <CirclesLoadingIndicator />}
        {isNotFound && <Error404 />}
        {page && <PreboardingPageContent page={page} />}
      </div>
    </div>
  )
}

export default PreboardingPagePage
