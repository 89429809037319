import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { i18nPath } from 'utils/i18nHelpers'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { showToastMessage } from 'redux/slices/toasts'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'

const I18N = i18nPath('views.admin.admin_alerts')

export const initialState = {
  adminAlertIds: [],
  countByName: {},
  meta: {
    ...defaultMeta,
    queryParams: {
      ...defaultPaginationParams,
    },
  },
}

const adminAlertSlice = createSlice({
  name: 'adminAlerts',
  initialState,
  reducers: {
    ...defaultActions,
    setAdminAlertIds(state, action) {
      state.adminAlertIds = action.payload
    },

    addAdminAlertId(state, action) {
      state.adminAlertIds = [...state.adminAlertIds, action.payload]
    },

    removeAdminAlertId(state, action) {
      state.adminAlertIds = state.adminAlertIds.filter(id => id !== action.payload)
    },

    clearAdminAlertIds(state) {
      state.adminAlertIds = []
    },

    setCountByName(state, action) {
      state.countByName = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },
  },
})

_.assign(adminAlertSlice, {
  asyncActions: {
    admin: {
      fetchAll: params => async (dispatch) => {
        dispatch(adminAlertSlice.actions.isLoading(true))

        try {
          const response = await API.admin.adminAlerts.fetchAll(params)
          const adminAlertIds = response.data.data.map(record => record.id)
          const newQueryParams = queryParamsFromHeaders(response)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(adminAlertSlice.actions.setAdminAlertIds(adminAlertIds))
          dispatch(adminAlertSlice.actions.setQueryParams(newQueryParams))
        } catch (error) {
          appSignal.sendErrorUnlessClearyBackendError(error)
        } finally {
          dispatch(adminAlertSlice.actions.isLoading(false))
        }
      },

      fetchUserAdminAlerts: userId => async (dispatch) => {
        dispatch(adminAlertSlice.actions.isLoading(true))

        try {
          const response = await API.admin.adminAlerts.fetchUserAdminAlerts(userId)
          const adminAlertIds = response.data.data.map(record => record.id)

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(adminAlertSlice.actions.setAdminAlertIds(adminAlertIds))
        } catch (error) {
          appSignal.sendErrorUnlessClearyBackendError(error)
        } finally {
          dispatch(adminAlertSlice.actions.isLoading(false))
        }
      },

      createAdminAlert: (userId, alertName) => async (dispatch) => {
        dispatch(adminAlertSlice.actions.isLoading(true))

        try {
          const response = await API.admin.adminAlerts.createAdminAlert(userId, alertName)
          const adminAlertId = response.data.data.id

          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(adminAlertSlice.actions.addAdminAlertId(adminAlertId))
          dispatch(showToastMessage({ message: I18N('success_creating_alert'), type: 'success' }))
        } catch (error) {
          appSignal.sendErrorUnlessClearyBackendError(error)
          dispatch(showToastMessage({ message: I18N('error_creating_alert'), type: 'error' }))
        } finally {
          dispatch(adminAlertSlice.actions.isLoading(false))
        }
      },

      destroyAdminAlert: (userId, adminAlertId) => async (dispatch) => {
        dispatch(adminAlertSlice.actions.isLoading(true))

        try {
          await API.admin.adminAlerts.destroyAdminAlert(userId, adminAlertId)

          dispatch(entitySlice.actions.remove({ id: adminAlertId, type: 'adminAlert' }))
          dispatch(adminAlertSlice.actions.removeAdminAlertId(adminAlertId))
          dispatch(showToastMessage({ message: I18N('success_destroying_alert'), type: 'success' }))
        } catch (error) {
          appSignal.sendErrorUnlessClearyBackendError(error)
          dispatch(showToastMessage({ message: I18N('error_destroying_alert'), type: 'error' }))
        } finally {
          dispatch(adminAlertSlice.actions.isLoading(false))
        }
      },

      getCountByName: () => async (dispatch) => {
        dispatch(adminAlertSlice.actions.isLoading(true))

        try {
          const response = await API.admin.adminAlerts.getCountByName()

          dispatch(adminAlertSlice.actions.setCountByName(response.data))
        } catch (error) {
          appSignal.sendErrorUnlessClearyBackendError(error)
        } finally {
          dispatch(adminAlertSlice.actions.isLoading(false))
        }
      },
    },
  },
})

_.assign(adminAlertSlice, {
  selectors: {
    getAdminAlerts: () => state => state.adminAlerts.adminAlertIds.map(id => build(state.entities, 'adminAlert', id)).filter(Boolean),

    getCountByName: () => state => state.adminAlerts.countByName,

    getMetaData: () => state => state.adminAlerts.meta,
  },
})

export default adminAlertSlice
