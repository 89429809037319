import React from 'react'
import { i18nMoment } from 'utils/i18nHelpers'
import classNames from 'classnames'
import DatePicker from 'components/form_fields/datePicker'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'

const FormDatePickerField = ({
  currentValue,
  onChange = () => { },
  fieldName,
  label = null,
  placeholder = '',
  isRequired,
  footNote = null,
  className = '',
}) => (
  <LabeledFormFieldContainer
    isRequired={isRequired}
    footNote={footNote}
    className={className}
    label={label || fieldName}
  >
    <DatePicker
      id={`${_.snakeCase(fieldName)}_date_picker`}
      className={classNames('FormDatePickerField', className)}
      selected={currentValue && i18nMoment(currentValue)}
      onChange={onChange}
      isClearable
      showTimeSelect={false}
      dateFormat='LL'
      placeholderText={placeholder}
      inputReadOnly={false}
    />
  </LabeledFormFieldContainer>
)

export default FormDatePickerField
