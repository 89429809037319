import React from 'react'
import SVG from 'react-inlinesvg'

import classNames from 'classnames'

const ArticleType = (props) => {
  const { articleType, displayName = true, className } = props

  return (
    <div className='Article-Type d-inline-flex align-items-center'>
      <SVG src={articleType?.imageUrl} cacheGetRequests />
      {displayName && (<span className={classNames('pl-2 font-weight-500', className)}>{articleType?.name}</span>)}
    </div>
  )
}

export default ArticleType
