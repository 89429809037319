import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import Modal from 'components/common/modal'
import SlugInput, { validSlug } from 'components/common/slugInput'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'

const I18N = i18nPath('views.pages.actions_dropdown')

interface EditPageURLModalProps {
  page: any
  isOpen: boolean
  onClose: () => void
}

const EditPageURLModal = ({
  page,
  isOpen,
  onClose,
}: EditPageURLModalProps) => {
  const [slug, setSlug] = useState(page.slug)

  const [updateSlug, { isLoading }] = useApi(() => API.pages.update({ id: page.id, slug }), {
    updateEntitySlice: true,
    onSuccess: onClose,
    toastSuccessMessage: I18N('page_updated'),
    broadcastRecordChanges: true,
  })

  // This regex ensures the slug starts with one or more lowercase letters or numbers (/^[a-z0-9]+/),
  // and optionally allows for a sequence of one or more alphanumeric characters preceded by a single hyphen (/-[a-z0-9]+/).
  const disabled = !validSlug(slug) || isLoading


  return (
    <Modal size='lg' className='EditPageURLModal rounded-lg' visible={isOpen} toggle={onClose}>
      <div className='px-4 mb-4'>
        <h4 className='font-weight-600 mb-5'>{I18N('edit_page_url')}</h4>
        <SlugInput
          className='mb-5'
          value={slug}
          onChange={(value: string) => setSlug(value)}
          label={<label className='font-weight-500'>{I18N('page_url_help_text')}</label>}
        />
        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={onClose} className='mr-3 text-secondary font-weight-600'>
            {I18N('cancel')}
          </CancelButton>
          <ButtonNarrow onClick={updateSlug} showLoadingSpinner={isLoading} disabled={disabled}>
            {I18N('save')}
          </ButtonNarrow>
        </div>
      </div>
    </Modal>
  )
}

export default EditPageURLModal
