import React, { useState } from 'react'
import classNames from 'classnames'
import { Dropdown, DropdownButton as BootstrapDropdownButton } from 'react-bootstrap'

import CheckIcon from 'components/icons/checkIcon'
import LoadingSpinnerText from 'components/common/loadingSpinnerText'

const DropdownItem = ({
  children, className = '', showIconOnHover = false, ...props
}) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <Dropdown.Item
      type='button'
      className={classNames('DropdownItem', className)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...props}
    >
      {children}
      {showIconOnHover && (
        <span
          className={classNames('ml-3', { invisible: !props.active && !isHovering })}
          data-testid='dropdown-item-check-icon'
        >
          <CheckIcon width='12px' height='12px' fillColor='var(--link-color)' />
        </span>
      )}
    </Dropdown.Item>
  )
}

const DropdownButton = ({
  children,
  isLoading = false,
  title = '',
  ...props
}) => (
  <BootstrapDropdownButton
    className='DropdownButton'
    title={(
      <LoadingSpinnerText showLoadingSpinner={isLoading}>
        {title}
      </LoadingSpinnerText>
    )}
    {...props}
  >
    {children}
  </BootstrapDropdownButton>
)

export { DropdownButton, DropdownItem }
