import React from 'react'

import { ButtonLink } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.admin.journeys.dashboards.journeys_dashboard')

const JourneysDashboardEmptyList = ({ selectedTab }) => {
  const { permissions } = useCurrentUser()
  const showAddNewHireButton = permissions.userManager

  return (
    <div className='empty-content d-flex flex-column w-100 my-5 justify-content-center align-items-center'>
      <CdnSvg src='/images/illustrations/womanWithLens.svg' />
      <span className='description text-center mt-4 font-weight-400 text-secondary'>
        {I18N(`${selectedTab}_empty_list_description`)}
      </span>
      {selectedTab === 'onboarding' && showAddNewHireButton && (
        <ButtonLink className='mt-4' to='/admin/users/new'>{I18N('create_new_hire')}</ButtonLink>
      )}
    </div>
  )
}

export default JourneysDashboardEmptyList
