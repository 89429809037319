import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import FormEmployeesField from 'components/form_fields/formEmployeesField'

const I18N = i18nPath('views.admin.users')

const UserEmployeeSeachField = ({
  user,
  fieldName,
  label,
  isRequired,
  footNote = null,
  setUser = () => { },
  className = '',
  placeholder = '',
  disabled = false,
  isMulti = false,
}) => {
  const currentValue = user[fieldName] || ''

  const onChange = (users) => {
    setUser({ ...user, [fieldName]: users })
  }

  return (
    <FormEmployeesField
      fieldName={fieldName}
      label={label || I18N(_.snakeCase(fieldName))}
      currentValue={currentValue}
      isRequired={isRequired}
      footNote={footNote}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      isMulti={isMulti}
    />
  )
}

export default UserEmployeeSeachField
