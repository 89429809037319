export const ONBOARDING_ONLY_DISABLED_MODULES = [
  'news',
  'qna',
  'people',
  'pages',
  'golinks',
  'shoutouts',
  'badges',
  'banners',
  'carousel',
  'logos',
  'groups',
  'orgChart',
]

const isCompanyOnboardingOnly = (company) => {
  const { settings } = company

  return ONBOARDING_ONLY_DISABLED_MODULES.map(feature => settings[feature]?.enabled).every(v => v === false)
}

export default isCompanyOnboardingOnly
