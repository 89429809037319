import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import StatusLabel from 'components/common/status_label'
import { present } from 'components/common/utils'
import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.metrics')

const USER_COLUMNS = [
  { value: 'active_journeys', label: I18N('columns.users.active_journeys') },
  { value: 'active', label: I18N('columns.users.active') },
  { value: 'audio_name_pronunciation', label: I18N('columns.users.audio_name_pronunciation') },
  { value: 'city', label: I18N('columns.users.city') },
  { value: 'company_name', label: I18N('columns.users.company_name') },
  { value: 'completed_journeys', label: I18N('columns.users.completed_journeys') },
  { value: 'country', label: I18N('columns.users.country') },
  { value: 'created_at', label: I18N('columns.users.created_at') },
  { value: 'current_location', label: I18N('columns.users.current_location') },
  { value: 'email', label: I18N('columns.users.email') },
  { value: 'end_date', label: I18N('columns.users.end_date') },
  { value: 'first_name', label: I18N('columns.users.first_name') },
  { value: 'full_name', label: I18N('columns.users.full_name') },
  { value: 'github', label: I18N('columns.users.github') },
  { value: 'id', label: I18N('columns.users.id') },
  { value: 'is_manager', label: I18N('columns.users.is_manager') },
  { value: 'last_name', label: I18N('columns.users.last_name') },
  { value: 'legal_notices_acknowledged_at', label: I18N('columns.users.legal_notices_acknowledged_at') },
  { value: 'linkedin', label: I18N('columns.users.linkedin') },
  { value: 'manager_email', label: I18N('columns.users.manager_email') },
  { value: 'manager_name', label: I18N('columns.users.manager_name') },
  { value: 'num_articles_mentioned', label: I18N('columns.users.num_articles_mentioned') },
  { value: 'num_badges', label: I18N('columns.users.num_badges') },
  { value: 'num_profile_photos', label: I18N('columns.users.num_profile_photos') },
  { value: 'num_shoutouts_received', label: I18N('columns.users.num_shoutouts_received') },
  { value: 'office_location', label: I18N('columns.users.office_location') },
  { value: 'personal_email', label: I18N('columns.users.personal_email') },
  { value: 'phone_mobile', label: I18N('columns.users.phone_mobile') },
  { value: 'preferred_first_name', label: I18N('columns.users.preferred_first_name') },
  { value: 'preferred_full_name', label: I18N('columns.users.preferred_full_name') },
  { value: 'preferred_last_name', label: I18N('columns.users.preferred_last_name') },
  { value: 'profile_url', label: I18N('columns.users.profile_url') },
  { value: 'region', label: I18N('columns.users.region') },
  { value: 'skills', label: I18N('columns.users.skills') },
  { value: 'soft_launch_access_granted_at', label: I18N('columns.users.soft_launch_access_granted_at') },
  { value: 'start_date', label: I18N('columns.users.start_date') },
  { value: 'teams', label: I18N('columns.users.teams') },
  { value: 'title', label: I18N('columns.users.title') },
  { value: 'twitter', label: I18N('columns.users.twitter') },
  { value: 'video_introduction', label: I18N('columns.users.video_introduction') },
  { value: 'worker_type', label: I18N('columns.users.worker_type') },
  { value: 'working_days_in_office', label: I18N('columns.users.working_days_in_office') },
  { value: 'working_days_remote', label: I18N('columns.users.working_days_remote') },
  { value: 'working_hours_end_time', label: I18N('columns.users.working_hours_end_time') },
  { value: 'working_hours_start_time', label: I18N('columns.users.working_hours_start_time') },
  { value: 'written_name_pronunciation', label: I18N('columns.users.written_name_pronunciation') },
  { value: 'user_permissions', label: I18N('columns.users.user_permissions') },
  { value: 'alert_type', label: I18N('columns.users.alert_type') },
  { value: 'subscribed_tags', label: I18N('columns.users.subscribed_tags') },
  { value: 'excluded_tags', label: I18N('columns.users.excluded_tags') },
  { value: 'teams_followed', label: I18N('columns.users.teams_followed') },
  { value: 'teams_notifications', label: I18N('columns.users.teams_notifications') },
]

const companyColumns = (currentCompany) => {
  const {
    customField1Label,
    customField2Label,
    customField3Label,
    hasHrisIntegration,
    hrisIntegrationName,
    hasAtsIntegration,
    atsIntegrationName,
    settings: { extraUserFields, profile: { fields: profileFields } },
  } = currentCompany

  const extraUserFieldColumns = extraUserFields.fields.map(euf => ({
    value: `extra_user_field.${euf.fieldName}`,
    label: euf.displayName,
  }))

  const customFieldColumns = [
    profileFields.customField1 && { value: 'custom_field_1', label: customField1Label || 'custom_field_1' },
    profileFields.customField2 && { value: 'custom_field_2', label: customField2Label || 'custom_field_2' },
    profileFields.customField3 && { value: 'custom_field_3', label: customField3Label || 'custom_field_3' },
  ].filter(present)

  const externalSyncColumns = [
    hasHrisIntegration && { value: 'external_hris_id', label: `${hrisIntegrationName} ID` },
    hasAtsIntegration && { value: 'external_ats_id', label: `${atsIntegrationName} ID` },
  ].filter(present)

  return [
    ...USER_COLUMNS,
    ...extraUserFieldColumns,
    ...customFieldColumns,
    ...externalSyncColumns,
  ].sort((a, b) => a.label.localeCompare(b.label))
}

interface Props {
  selectedColumns: string[]
  setSelectedColumns: (columns: string[]) => void
}

interface ColumnType {
  value: string
  label: string
}

const ColumnsSelector = ({ selectedColumns, setSelectedColumns }: Props) => {
  const currentCompany = useCurrentCompany()

  const addColumn = (col: ColumnType) => {
    setSelectedColumns([...selectedColumns, col.value])
  }

  const removeColumn = (col: ColumnType) => {
    setSelectedColumns(selectedColumns.filter(c => c !== col.value))
  }

  const availableColumns = companyColumns(currentCompany)
  const selectedCount = selectedColumns.filter(c => availableColumns.find(ac => ac.value === c)).length

  return (
    <div className='ColumnsSelector mt-4 w-100 w-lg-75'>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <label>{I18N('select_columns')}</label>
        <StatusLabel>
          {I18N('selected_columns_count', { selectedCount, totalCount: availableColumns.length })}
        </StatusLabel>
      </div>
      <div className='Checkboxes'>
        {availableColumns.map(col => (
          <div key={col.value} className='form-check pl-0'>
            <input
              id={`column-${col.value}`}
              type='checkbox'
              checked={selectedColumns.includes(col.value)}
              onChange={({ target: { checked } }) => (checked ? addColumn(col) : removeColumn(col))}
            />
            <label className='font-weight-400' htmlFor={`column-${col.value}`}>
              {col.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ColumnsSelector
