import React from 'react'

import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import SearchResultOption from './searchResultOption'

const I18N = i18nPath('views.search.global_search')

const GoogleDriveOption = ({ option }) => (
  <SearchResultOption
    image={(
      <img
        alt={option.name}
        className='SearchResultOptionImg mr-2'
        style={{ height: '22px', width: '22px' }}
        src={option.icon}
      />
    )}
    title={option.name}
    secondaryTexts={[I18N('last_modified', { date: i18nMoment(option.modifiedTime).fromNow() })]}
  />
)

export default GoogleDriveOption
