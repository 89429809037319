import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import taskableStepSlice from 'redux/slices/journeys/taskableSteps'

const useCompleteTaskables = ({
  onParamsChange,
  isCompletedTaskTabSelected,
}) => {
  const steps = useSelector(taskableStepSlice.selectors.getTaskableSteps())
  const completedSteps = steps.filter(step => step?.completionStatus === 'completed')
  const notCompletedSteps = steps.filter(step => step?.completionStatus !== 'completed')
  const filteredSteps = isCompletedTaskTabSelected ? completedSteps : notCompletedSteps
  const { queryParams } = useSelector(taskableStepSlice.selectors.getMetaData())

  const {
    page, totalPages,
  } = queryParams

  const completedAllStepsInPage = steps.length > 0 && filteredSteps.length === 0
  const pageWithEmptySteps = page !== 1 && steps.length === 0

  useEffect(() => {
    if (completedAllStepsInPage) {
      onParamsChange({ page: Math.max(page - 1, 1) })
    } else if (pageWithEmptySteps) {
      onParamsChange({ page: Math.min(totalPages, page) })
    }
  }, [completedAllStepsInPage, pageWithEmptySteps])


  const rowClassName = (row) => {
    if (!isCompletedTaskTabSelected && row?.completionStatus === 'completed') {
      return 'hide-row-transition'
    }

    if (isCompletedTaskTabSelected && row?.completionStatus !== 'completed') {
      return 'hide-row-transition'
    }

    return ''
  }

  return {
    filteredSteps: steps,
    rowClassName,
  }
}

export default useCompleteTaskables
