import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import AppType from 'types/app'
import { useDispatch, useSelector } from 'react-redux'
import arrayMove from 'array-move'
import appSlice from 'redux/slices/app_launcher/apps'
import appPreferenceSlice from 'redux/slices/app_launcher/appPreferences'
import SortableTable from 'components/common/tables/sortableTable'
import useWindowResize from 'components/common/hooks/useWindowResize'
import { appListColumns } from 'pages/app_launcher/myAppsListPage'

const I18N = i18nPath('views.app_launcher.my_apps_list_page')

interface SortableFavoritesTableProps {
  apps: AppType[],
  searchQuery: string,
}

const SortableFavoritesTable = ({
  apps,
  searchQuery,
} : SortableFavoritesTableProps) => {
  const dispatch = useDispatch()
  const { isSaving } = useSelector(appPreferenceSlice.selectors.getMetaData())

  const favoriteApps = apps.filter(app => app.favorite)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const app = apps[oldIndex]
    const newAppIdsOrder = arrayMove(apps.map(app => app.id), oldIndex, newIndex)
    dispatch(appSlice.actions.setUserAppIds(newAppIdsOrder))
    dispatch(appPreferenceSlice.asyncActions.update({ id: app.appPreferenceId, orderPosition: newIndex }))
  }

  const { isUpToMediumScreen } = useWindowResize()

  return (
    <SortableTable
      className='AppsTable'
      data={favoriteApps}
      onSortEnd={onSortEnd}
      columns={appListColumns({ isUpToMediumScreen, dispatch, searchQuery })}
      isSaving={isSaving}
    />
  )
}

export default SortableFavoritesTable
