import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import badgeSlice from 'redux/slices/badges'
import awardedBadgeSlice from 'redux/slices/awardedBadges'
import requestedBadgeSlice from 'redux/slices/requestedBadges'
import { i18nPath } from 'utils/i18nHelpers'

import InfiniteScroller from 'components/common/infiniteScroller'
import { LoadingContainer } from 'components/common/loadingContainer'
import BackButton from 'components/common/backButton'
import AwardeeCard from 'components/badges/awardeeCard'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import SettingsLinkButton from 'components/common/buttons/settingsLinkButton'
import BadgeSettingsModal from 'components/badges/modals/badgeSettingsModal'
import BadgeDetailCard from 'components/badges/badgeDetailCard'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import { Button } from 'components/common/buttons'
import classNames from 'classnames'
import HelpTooltip from 'components/common/tooltip'
import CSVAudienceModal from 'components/common/csv/csvAudienceModal'
import PendingRequestsModal from 'components/badges/modals/pendingRequestsModal'
import useQueryParams from 'components/common/hooks/useQueryParams'
import { trackEvent } from 'services/tracker'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.badges')

const STANDARD = 'standard'
const SETTINGS_MODAL = 'settings'
const UPLOAD_CSV_MODAL = 'upload_csv'
const PENDING_REQUESTS_MODAL = 'pending_requests'

const BADGES_MODULE = 'badges'

const BadgePageEmptyState = () => (
  <div className='EmptyState'>
    <CdnSvg src='/images/badges/badgeIcon.svg' />
    <span>{I18N('no_awardees')}</span>
  </div>
)

const BadgePage = () => {
  const { badgeId } = useParams()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()

  const {
    isLoading, isNotFound,
  } = useSelector(badgeSlice.selectors.getMetaData())

  const badge = useSelector(badgeSlice.selectors.getBadge())
  const isStandardBadge = badge.type === STANDARD
  const showCreatedBy = !_.isEmpty(badge.owner) && badge.owner?.username !== 'clearyadmin'

  const isBadgeManager = currentUser.permissions.badgeManager

  const canManageBadge = currentUser.id === badge.owner?.id || isBadgeManager

  const [showAwardOptions, setShowAwardOptions] = useState(false)
  const [selectedRecipients, setSelectedRecipients] = useState([])

  const awardedBadges = useSelector(awardedBadgeSlice.selectors.getAwardedBadges())
  const { isLoading: isFetchingAwardedBadges, queryParams } = useSelector(awardedBadgeSlice.selectors.getMetaData())
  const awardeesCount = queryParams.total
  const hasNoAwardees = awardeesCount === 0 && !isLoading && !isFetchingAwardedBadges

  const requestedBadges = useSelector(requestedBadgeSlice.selectors.getRequestedBadges())
  const { isLoading: isloadingRequestedBadges } = useSelector(requestedBadgeSlice.selectors.getMetaData())
  const loadedRequestedBadges = useIsLoaded(isloadingRequestedBadges)
  const isLoadingBadgeCard = isLoading || isloadingRequestedBadges

  const { resolveRequest, requestedBadgeId } = useQueryParams()
  const showBadgeRequests = canManageBadge && badge.type === STANDARD && !isloadingRequestedBadges

  const currentUserHasAwardedBadge = awardedBadges.find(awardedBadge => awardedBadge.user.id === currentUser.id)

  const [currentlyOpenModal, setCurrentlyOpenModal] = useState(null)

  useEffect(() => {
    dispatch(badgeSlice.asyncActions.fetchBadge(badgeId))
    dispatch(awardedBadgeSlice.asyncActions.fetchAll({ ...queryParams, badgeId }))
    trackEvent('badge:view', { badge_id: badgeId })

    return () => {
      dispatch(badgeSlice.actions.clearBadge())
      dispatch(awardedBadgeSlice.actions.clearAwardedBadgesIds())
      dispatch(requestedBadgeSlice.actions.clearRequestedBadgeIds())
    }
  }, [])

  useEffect(() => {
    if (canManageBadge) dispatch(requestedBadgeSlice.asyncActions.fetchAll(badgeId))
  }, [canManageBadge])

  useEffect(() => {
    const requestedBadgeIds = requestedBadges.map(rb => rb.id.toString())

    if (loadedRequestedBadges && ['approved', 'declined'].includes(resolveRequest) && requestedBadgeIds.includes(requestedBadgeId)) {
      dispatch(requestedBadgeSlice.asyncActions.update({ id: requestedBadgeId, badgeId, status: resolveRequest }))
    }
  }, [resolveRequest, loadedRequestedBadges])

  const hasMoreContent = () => {
    const { page, totalPages } = queryParams
    return page < totalPages
  }

  const handleFetch = () => {
    dispatch(awardedBadgeSlice.asyncActions.fetchAll({ ...queryParams, page: queryParams.page + 1, badgeId }))
  }

  const handleAward = () => {
    const recipientIds = selectedRecipients.map(recipient => recipient.id)
    dispatch(awardedBadgeSlice.asyncActions.create(badgeId, recipientIds, onAwardSuccess))
  }

  const onAwardSuccess = () => {
    setSelectedRecipients([])
    setShowAwardOptions(false)
  }

  return (
    <LoadingContainer isLoading={isLoadingBadgeCard} isNotFound={isNotFound} useCirclesLoadingIndicator={true}>
      <main className='BadgePage'>
        <section className='BadgeInformation'>
          <header>
            <BackButton backToText={I18N('header')} url='/people/badges' />
            {canManageBadge && <SettingsLinkButton onClick={() => setCurrentlyOpenModal(SETTINGS_MODAL)} />}
          </header>

          <BadgeDetailCard
            badge={badge}
            canManageBadge={canManageBadge}
            showCreatedBy={showCreatedBy}
            currentUserHasAwardedBadge={currentUserHasAwardedBadge}
          />

          <div className={classNames('AwardInfo', { NoAwardees: hasNoAwardees })}>
            <div className='RecipientsAndAward'>
              <h5 className='text-secondary m-0'>{awardeesCount} {I18N('recipients')}</h5>
              {canManageBadge && (
                <div className='AwardLink'>
                  <div className={classNames('pointer', { disabled: !isStandardBadge })} onClick={() => setShowAwardOptions(!showAwardOptions)}>
                    <span className='font-weight-500'>{I18N('award_this_badge')}</span>
                  </div>
                  {!isStandardBadge && <HelpTooltip iconStyle={{ width: '22px', height: '22px' }}>{I18N('award_tooltip_message')}</HelpTooltip>}
                </div>
              )}
            </div>
            {showBadgeRequests && (
              <span
                className={classNames('pointer', 'font-weight-500', { disabled: requestedBadges.length === 0 })}
                onClick={() => setCurrentlyOpenModal(PENDING_REQUESTS_MODAL)}
              >
                {I18N('request.view', { count: requestedBadges.length })}
              </span>
            )}
          </div>
          {showAwardOptions && (
            <div className='AwardBadge'>
              <div className='AwardOptions'>
                <label className='font-weight-500'>{I18N('select_recipients_or')}&nbsp;</label>
                <span className='UploadCSV pointer' onClick={() => setCurrentlyOpenModal(UPLOAD_CSV_MODAL)}>{I18N('upload_a_list')}</span>
              </div>
              <div className='SelectRecipients'>
                <EmployeeSearch
                  onChange={recipients => setSelectedRecipients(recipients)}
                  selectedEmployees={selectedRecipients}
                  requiredUserFlags={['badges.enabled']}
                  isMulti
                />
                <Button
                  className='AwardButton'
                  disabled={_.isEmpty(selectedRecipients) || isFetchingAwardedBadges}
                  showLoadingSpinner={isFetchingAwardedBadges}
                  onClick={handleAward}
                >
                  {I18N('award')}
                </Button>
              </div>
            </div>
          )}
        </section>

        <InfiniteScroller
          isFetching={isFetchingAwardedBadges}
          onFetch={handleFetch}
          hasMoreContent={hasMoreContent}
          className='BadgeAwardees'
        >
          {awardedBadges && awardedBadges.map(awardedBadge => (
            <AwardeeCard key={`${awardedBadge.id}-awardee-card`} awardedBadge={awardedBadge} canRemove={canManageBadge} />
          ))}
          {hasNoAwardees && <BadgePageEmptyState />}
        </InfiniteScroller>

        {currentlyOpenModal === SETTINGS_MODAL && (
          <BadgeSettingsModal
            badge={badge}
            onCloseModal={() => setCurrentlyOpenModal(null)}
            isBadgeManager={isBadgeManager}
          />
        )}

        <CSVAudienceModal
          isOpen={currentlyOpenModal === UPLOAD_CSV_MODAL}
          toggle={() => setCurrentlyOpenModal(null)}
          description={I18N('upload_csv_description')}
          onChange={users => setSelectedRecipients(users)}
          moduleName={BADGES_MODULE}
        />

        <PendingRequestsModal
          visible={currentlyOpenModal === PENDING_REQUESTS_MODAL}
          toggle={() => setCurrentlyOpenModal(null)}
          requestedBadges={requestedBadges}
          badgeId={badgeId}
        />
      </main>
    </LoadingContainer>
  )
}

export default BadgePage
