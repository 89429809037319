import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { askAiI18n } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import { ButtonSecondary } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'

const AskAiNavbarButton = () => {
  const { setShowAskAiModal } = useAskAiContext()

  return (
    <ButtonSecondary
      onClick={() => setShowAskAiModal(true)}
      className='AskAiNavbarButton ml-3 flex-shrink-0'
    >
      <CdnSvg className='mr-1' src='/images/aiIcon.svg' />
      {askAiI18n('ask_ai')}
    </ButtonSecondary>
  )
}

export default AskAiNavbarButton
