import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import badgeSlice from 'redux/slices/badges'
import { i18nPath } from 'utils/i18nHelpers'

import ConfigurationSettings from 'components/badges/modals/badgeConfigurationSettings'
import ScrollToModal from 'components/common/scrollToModal'
import DeleteModal from 'components/common/modals/deleteModal'

const I18N = i18nPath('views.badges.settings')

const MAIN_MODAL = 'main'
const DELETE_MODAL = 'delete'

const BadgeSettingsModal = ({
  onCloseModal,
  badge,
  isBadgeManager,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [workingCopy, setWorkingCopy] = useState(badge)
  const [currentlyOpenModal, setCurrentlyOpenModal] = useState(MAIN_MODAL)

  const { error, isSaving } = useSelector(badgeSlice.selectors.getMetaData())
  const areChangesPresent = !_.isEqual(badge, workingCopy)

  const onSave = () => {
    dispatch(badgeSlice.asyncActions.update(workingCopy, onCloseModal))
  }

  const onDelete = () => {
    dispatch(badgeSlice.asyncActions.destroy(badge.id, onDeleteSuccess))
  }

  const onDeleteSuccess = () => {
    history.push('/people/badges')
  }

  const sections = [
    {
      id: 'configurations',
      header: I18N('configurations'),
      content: <ConfigurationSettings
        workingCopy={workingCopy}
        setWorkingCopy={setWorkingCopy}
        isBadgeManager={isBadgeManager}
        error={error}
        onDelete={() => setCurrentlyOpenModal(DELETE_MODAL)}
      />,
    },
  ]

  return (
    <>
      {currentlyOpenModal === MAIN_MODAL && (
        <ScrollToModal
          visible={currentlyOpenModal === MAIN_MODAL}
          sections={sections}
          onSave={onSave}
          onClose={onCloseModal}
          isSaving={isSaving}
          isSaveButtonDisabled={!areChangesPresent}
        />
      )}
      <DeleteModal
        deleteText={I18N('delete_badge')}
        deleteSecondaryText={I18N('delete_warning')}
        deleteConfirm={onDelete}
        showDeleteModal={currentlyOpenModal === DELETE_MODAL}
        closeDeleteModal={() => setCurrentlyOpenModal(MAIN_MODAL)}
      />
    </>
  )
}

export default BadgeSettingsModal
