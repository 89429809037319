import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import ReactSelect from 'components/common/react_select'
import { I18NCommon } from 'utils/i18nHelpers'


const DaysSelect = ({
  days,
  setDays,
  label,
  className,
}) => {
  const periods = [
    { value: 'days', label: I18NCommon('day(s)') },
    { value: 'weeks', label: I18NCommon('week(s)') },
    { value: 'months', label: I18NCommon('month(s)') },
  ]

  const getInitialNumberAndPeriod = (days) => {
    if (days === 0) return [0, periods[0]]

    if (days % 30 === 0) return [days / 30, periods[2]]
    if (days % 7 === 0) return [days / 7, periods[1]]

    return [days, periods[0]]
  }

  const [initialNumber, initialPeriod] = getInitialNumberAndPeriod(days)
  const [number, setNumber] = useState(initialNumber)
  const [period, setPeriod] = useState(initialPeriod)

  useEffect(() => {
    if (period.value === 'days') {
      setDays(number)
    } else if (period.value === 'weeks') {
      setDays(number * 7)
    } else {
      setDays(number * 30)
    }
  }, [number, period])

  return (
    <div className={classNames(className, 'DaysSelect d-md-flex align-items-center')}>
      <input
        className='NumberInput mr-md-2 mb-md-0 mb-sm-1'
        name='number-input'
        type='number'
        value={number}
        onChange={({ target }) => setNumber(parseInt(target.value, 10))}
      />
      <ReactSelect
        className='mr-md-2 mb-md-0 mb-sm-1'
        options={periods}
        onChange={setPeriod}
        value={period}
      />
      <span className='mr-md-2 mb-md-0 mb-sm-1'>{label}</span>
    </div>
  )
}

export default DaysSelect
