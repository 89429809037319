import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.common.smart_table')

interface TableProps {
  data: any[],
  columns: any[],
  handleSortClick?: (columnId: any, event: any) => void,
  sortedAsc?: boolean,
  rowClassName?: (row: any) => string,
  sortKey?: string,
  className?: string,
  noResultsText?: string,
  hideHeaders?: boolean,
  isSortable?: boolean,
}

const ColGroup = ({ columns }) => (
  <colgroup>
    {columns.map((column, index) => <col key={index} id={column.col} />)}
  </colgroup>
)

const HeaderCell = ({
  id, style, header, handleSortClick, sorted, sortDirection, className,
}) => (
  <th
    onClick={e => handleSortClick(id, e)}
    className={className}
    data-sort={id}
    data-sorted={sorted ? sortDirection : undefined}
    style={style}
  >
    {header}
  </th>
)

const Headers = ({
  columns, handleSortClick, sortKey, sortedAsc, hideHeaders,
}) => (
  <thead>
    <tr>
      {columns.map((column, index) => {
        if (column.header instanceof Function) {
          return <th key={index} className={column.headerColumnClassName}>{column.header()}</th>
        }

        return (
          <HeaderCell
            key={index}
            id={column.id}
            header={column.header}
            className={column.headerColumnClassName}
            style={hideHeaders ? { ...column.style, height: '0px', padding: '0px' } : column.style}
            handleSortClick={handleSortClick}
            sorted={sortKey === column.id}
            sortDirection={sortedAsc ? 'asc' : 'desc'}
          />
        )
      })}
    </tr>
  </thead>
)

const Row = ({ className, row, columns }) => (
  <tr className={className}>
    {columns.map((column, index) => <td key={index} className={column.className}>{column.accessor(row)}</td>)}
  </tr>
)

const SortableRow = SortableElement(({ className, row, columns }) => (
  <tr className={className}>
    {columns.map((column, index) => <td key={index}>{column.accessor(row)}</td>)}
  </tr>
))

const Body = ({
  data, columns, rowClassName, noResultsText, isSortable,
}) => {
  const RowComponent = isSortable ? SortableRow : Row

  return (
    <tbody>
      {data.length === 0 && (
        <tr>
          <td colSpan={columns.length} className='message'>
            {noResultsText || I18N('no_results')}
          </td>
        </tr>
      )}
      {data.map((row, index) => (
        <RowComponent
          key={index}
          index={index}
          className={rowClassName?.(row) || undefined}
          row={row}
          columns={columns}
        />
      ))}
    </tbody>
  )
}

const Table = ({
  data,
  columns,
  sortedAsc = false,
  sortKey = '',
  rowClassName = undefined,
  handleSortClick,
  hideHeaders = false,
  noResultsText = undefined,
  className = undefined,
  isSortable = false,
}: TableProps) => data && (
  <table className={className}>
    {columns[0].col && <ColGroup columns={columns} />}
    <Headers
      columns={columns}
      handleSortClick={handleSortClick}
      sortKey={sortKey}
      sortedAsc={sortedAsc}
      hideHeaders={hideHeaders}
    />
    <Body
      data={data}
      columns={columns}
      rowClassName={rowClassName}
      noResultsText={noResultsText}
      isSortable={isSortable}
    />
  </table>
)

export default Table
