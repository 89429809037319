import CdnSvg from 'components/common/cdnSvg'
import useIsAiAssistantAnswersEnabled from 'hooks/ai/useIsAssistantAnswersEnabled'
import React from 'react'
import { useSelector } from 'react-redux'

import searchSlice from 'redux/slices/search'
import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

const I18N = i18nPath('views.search.global_search')

const ShowAllResults = () => {
  const searchLocation = 'dropdown'
  const { totalResults } = useSelector(searchSlice.selectors.getMetaData(searchLocation))
  const searchTerm = useSelector(searchSlice.selectors.getSearchTerm(searchLocation))
  const isAiAssistantAnswersEnabled = useIsAiAssistantAnswersEnabled()

  if (totalResults === 0) return null

  return (
    <p className='ShowAllResults m-0 py-2 d-flex'>
      {isAiAssistantAnswersEnabled && <CdnSvg src='/images/aiIcon.svg' className='mr-2' />}

      {totalResults === 1 ? (
        i18nFormat(I18N('view_one_result_for'), <em>{searchTerm}</em>)
      ) : (
        i18nFormat(I18N('view_all_results_for'), totalResults, <em>{searchTerm}</em>)
      )}
    </p>
  )
}

export default ShowAllResults
