import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ArticleEditorNavbar from 'components/admin/articles/articleEditorNavbar'
import adminArticleSlice, { buildArticleWorkingCopy } from 'redux/slices/admin/articles'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import useIsLoaded from 'components/common/hooks/useIsLoaded'
import articleAdminBanners from 'components/admin/articles/articleAdminBanners'
import ArticleOrTemplateEditor from 'components/admin/articles/articleOrTemplateEditor'
import useNewArticleWorkingCopy from 'components/common/hooks/useNewArticleWorkingCopy'
import useQueryParams from 'components/common/hooks/useQueryParams'
import articleTypeSlice from 'redux/slices/articleTypes'
import useIsGroupPost from 'components/admin/articles/hooks/useIsGroupPost'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import navigationBlockerSlice from 'redux/slices/navigationBlocker'

const I18N = i18nPath('views.admin.article_editor')

const ArticleEditorPage = () => {
  const dispatch = useDispatch()
  const [workingCopy, setWorkingCopy, updateWorkingCopy] = useUpdatableState({})
  const { articleId } = useParams()
  const { groupId } = useQueryParams()
  const isNewArticle = articleId === 'new'
  const article = useSelector(adminArticleSlice.selectors.getArticleById(articleId))
  const isGroupPost = useIsGroupPost(article)
  const { isLoading } = useSelector(adminArticleSlice.selectors.getMetaData())
  const [waitForGroupArticle, setWaitForGroupArticle] = useState(true)
  const isLoaded = useIsLoaded(isLoading)
  const history = useHistory()

  // modal is rendered in the ArticleEditorNavbar - where all logic regarding saving lives
  // needed to move this out to be able to open the modal from the AdminBanner
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showTemplatesSidebar, setShowTemplatesSidebar] = useState(isNewArticle && !isGroupPost)
  const [createdTemplateId, setCreatedTemplateId] = useState(null)

  const { newWorkingCopy, isLoaded: isNewWorkingCopyLoaded } = useNewArticleWorkingCopy({ groupId })

  const [createArticle] = useApi(API.admin.articles.create, {
    toastErrorMessage: I18N('error_creating_article'),
    onSuccess: (article) => {
      dispatch(navigationBlockerSlice.asyncActions.performWithoutBlocking(() => {
        history.push(`/posts/${article.id}`)

        setWaitForGroupArticle(false)
      }))
    },
  })

  useEffect(() => {
    if (groupId) {
      setWorkingCopy(newWorkingCopy)
    }
  }, [groupId])

  useEffect(() => {
    if (!isNewArticle) {
      setWaitForGroupArticle(false)

      return
    }

    if (isNewWorkingCopyLoaded) {
      setWorkingCopy(newWorkingCopy)

      const { groupInformation } = newWorkingCopy

      if (groupInformation?.group?.defaultArticleTemplateId) {
        createArticle({
          templateId: groupInformation?.group.defaultArticleTemplateId,
          groupId: newWorkingCopy.groupId,
        })
      } else {
        setWaitForGroupArticle(false)
      }
    }
  }, [isNewWorkingCopyLoaded])

  useEffect(() => {
    if (!isNewArticle) {
      setWorkingCopy({})
      dispatch(adminArticleSlice.asyncActions.fetchArticle(articleId))
      // closing sidebar if the user was redirected here (for example when applying a template)
      setShowTemplatesSidebar(false)
    }

    if (isGroupPost) {
      dispatch(articleTypeSlice.asyncActions.fetchGroupArticleType())
      dispatch(adminArticleSlice.asyncActions.fetchEditableGroups())
    }
  }, [articleId])

  useEffect(() => {
    if (article && isLoaded) {
      setWorkingCopy(buildArticleWorkingCopy(article))
    }
  }, [article?.updatedAt, isLoading, isLoaded])

  return (
    <div className='ArticleEditorPage'>
      <ArticleEditorNavbar
        workingCopy={workingCopy}
        article={article}
        isNewArticle={isNewArticle}
        showScheduleModal={showScheduleModal}
        setShowScheduleModal={setShowScheduleModal}
        showTemplatesSidebar={showTemplatesSidebar}
        setShowTemplatesSidebar={setShowTemplatesSidebar}
        setCreatedTemplateId={setCreatedTemplateId}
      />
      <ArticleOrTemplateEditor
        article={article}
        workingCopy={workingCopy}
        updateWorkingCopy={updateWorkingCopy}
        isLoading={isLoading || waitForGroupArticle}
        showTemplatesSidebar={showTemplatesSidebar}
        closeTemplatesSidebar={() => setShowTemplatesSidebar(false)}
        banners={articleAdminBanners({
          article,
          openScheduleModal: () => setShowScheduleModal(true),
          createdTemplateId,
          closeEditTemplateBanner: () => setCreatedTemplateId(null),
        })}
      />
    </div>
  )
}

export default ArticleEditorPage
