import React from 'react'
import { ButtonLarge } from 'components/common/buttons/buttonLarge'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.preview_components')

const ButtonBorderRadius = () => <ButtonLarge className='d-block'>{I18N('ask_a_question')}</ButtonLarge>

export default ButtonBorderRadius
