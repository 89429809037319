import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import { i18nPath } from 'utils/i18nHelpers'
import ForwardArrowIcon from 'components/icons/forwardArrowIcon'
import QuestionBubbleChatIcon from 'components/icons/qna/questionBubbleChatIcon'
import { ButtonSmall } from 'components/common/buttons'
import DangerousHTML from 'components/common/dangerousHTML'

const I18N = i18nPath('views.qna.events')

export const TopQuestionContent = ({ question, event, linkToQuestion = false }) => {
  const dispatch = useDispatch()

  if (!event) return null

  if (event.isUpcoming) return <span>{I18N('mark_your_calendars')}</span>

  if (event.isLockedAndAcceptingAnswers) return (
    <>
      <QuestionBubbleChatIcon fill='var(--text-color-secondary)' className='mb-3' />
      <span>{I18N('participate_in_discussions')}</span>
    </>
  )

  if (_.isEmpty(question)) return (
    <>
      <QuestionBubbleChatIcon fill='var(--text-color-secondary)' className='mb-3' />
      <span>{I18N('be_the_first')}</span>
    </>
  )

  return (
    <>
      <span className='text-secondary text-small'>
        {I18N(question.type === 'icebreaker' ? 'icebreaker_label' : 'top_question')}
      </span>
      <div className='mt-2 truncate-text-at-4-lines break-word'>
        {linkToQuestion ? (
          <Link
            className='font-weight-500 text-primary'
            to={`/events/${event.slug}`}
            onClick={() => {
              dispatch(qnaQuestionSlice.actions.setSelectedQuestionIdFromHighlightedEvent(question.id))
            }}
          >
            <DangerousHTML>{question.plainTextContent}</DangerousHTML>
          </Link>
        ) : (
          <DangerousHTML>{question.plainTextContent}</DangerousHTML>
        )}
      </div>
    </>
  )
}


const TopQuestionButton = ({ question, event }) => {
  const dispatch = useDispatch()

  if (!event) return null

  if (event.isUpcoming) return null

  if (event.isLockedAndAcceptingAnswers) return (
    <Link to={`/events/${event.slug}`}>
      <ButtonSmall className='text-small font-weight-normal px-1'>{I18N('view_activity')}</ButtonSmall>
    </Link>
  )

  if (_.isEmpty(question)) return (
    <Link
      to={`/events/${event.slug}`}
      onClick={() => dispatch(qnaQuestionSlice.actions.isQuestionFormVisible(true))}
    >
      <ButtonSmall className='text-small font-weight-normal px-1'>{I18N('ask_a_question')}</ButtonSmall>
    </Link>
  )

  if (question.type === 'icebreaker') return (
    <Link
      to={`/events/${event.slug}`}
      onClick={() => {
        dispatch(qnaQuestionSlice.actions.setSelectedQuestionIdFromHighlightedEvent(question.id))
      }}
    >
      <ButtonSmall className='text-small font-weight-normal px-3 mt-1'>{I18N('answer_this_question')}</ButtonSmall>
    </Link>
  )

  if (question.type === 'standard') return (
    <Link to={`/events/${event.slug}`} onClick={() => dispatch(qnaQuestionSlice.actions.isQuestionFormVisible(true))}>
      <ButtonSmall className='text-small font-weight-normal px-1'>{I18N('ask_a_question')}</ButtonSmall>
    </Link>
  )

  return null
}


export const TopQuestionLink = ({ question, event }) => {
  const dispatch = useDispatch()

  if (!event) return null

  if (event.isLockedAndAcceptingAnswers) return (
    <Link to={`/events/${event.slug}`}>
      {I18N('view_activity')} <ForwardArrowIcon className='mb-1 ml-1' fillColor='var(--link-color)' />
    </Link>
  )

  if (_.isEmpty(question)) return (
    <Link
      to={`/events/${event.slug}`}
      onClick={() => dispatch(qnaQuestionSlice.actions.isQuestionFormVisible(true))}
    >
      {I18N('ask_a_question')} <ForwardArrowIcon className='mb-1 ml-1' fillColor='var(--link-color)' />
    </Link>
  )

  if (question.type === 'icebreaker') return (
    <Link
      to={`/events/${event.slug}`}
      onClick={() => {
        dispatch(qnaQuestionSlice.actions.setSelectedQuestionIdFromHighlightedEvent(question.id))
      }}
    >
      {I18N('answer_this_question')} <ForwardArrowIcon className='mb-1 ml-1' fillColor='var(--link-color)' />
    </Link>
  )

  if (question.type === 'standard') return (
    <Link to={`/events/${event.slug}`} onClick={() => dispatch(qnaQuestionSlice.actions.isQuestionFormVisible(true))}>
      {I18N('ask_a_question')} <ForwardArrowIcon className='mb-1 ml-1' fillColor='var(--link-color)' />
    </Link>
  )

  return null
}


const TopQuestionMiniCard = ({ question, event }) => (
  <div className='d-flex flex-column align-items-start justify-content-center'>
    <div className='mb-4 d-flex flex-column'>
      <TopQuestionContent question={question} event={event} />
    </div>
    <TopQuestionButton question={question} event={event} />
  </div>
)

export default TopQuestionMiniCard
