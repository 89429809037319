import React from 'react'

import LikeIconFilled from 'components/icons/likeIconFilled'

const LikeHighlightColor = () => (
  <span className='LikeWidget-iconContainer d-inline-flex align-items-center'>
    <span
      className='d-flex'
      data-testid='cy_like_button'
    >
      <LikeIconFilled />
    </span>
  </span>
)

export default LikeHighlightColor
