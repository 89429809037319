import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import Card from 'components/common/card'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import { i18nPath, i18nMoment, i18nFormat } from 'utils/i18nHelpers'
import AutoResizableScrollableSection from 'components/home/widgets/common/autoResizableScrollableSection'
import CdnSvg from 'components/common/cdnSvg'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import numeral from 'numeral'
import celebrationEmojis from 'components/celebrations/utils/emojis'
import useApi from 'components/common/hooks/useApi'

const I18N = i18nPath('views.home.celebrations')


const CelebrationSignStatus = ({ celebration }) => {
  const iconSrc = celebration.hasCommented ? '/images/checkIcon.svg' : '/images/signingPenIcon.svg'

  return (
    <div className='CelebrationSignStatus px-2 d-flex align-items-center'>
      <CdnSvg src={iconSrc} />
      <p className='SignCardText text-white text-smallest mb-0 ml-2'>
        {celebration.hasCommented ? I18N('view_card') : I18N('sign_card')}
      </p>
    </div>
  )
}


export const CelebrationListItem = ({ celebration, width }) => {
  const { user, eventDate, type } = celebration
  const currentUser = useCurrentUser()
  const showSignStatus = celebration.displayPath && celebration.user.id !== currentUser.id

  const celebrationEmoji = celebrationEmojis[type] || null
  const employeeThumbnailSize = width > 3 ? '57px' : '32px'
  const labelClassName = celebration.customCelebration ? 'custom_celebration' : type

  const celebrationTypeText = () => {
    if (type === 'work_anniversary') {
      return `${numeral(celebration.workAnniversaryYears).format('0o')} ${I18N('work_anniversary')}`
    } else {
      return I18N(_.snakeCase(type))
    }
  }

  return (
    <Link to={celebration.displayPath}>
      <Card
        className='CelebrationListItem'
        cardBodyClassName='px-2 py-4'
      >
        {showSignStatus && <CelebrationSignStatus celebration={celebration} />}

        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <div className='position-relative'>
              <EmployeeThumbnailPhoto
                employee={user}
                size={employeeThumbnailSize}
              />

              <div className='CelebrationEmoji'>{celebrationEmoji}</div>
            </div>

            <div className='d-flex flex-column ml-3 UserInformation'>
              <h6 className='mb-0'>{user.preferredFullName}</h6>
              <div className='text-secondary text-smallest'>{user.title}</div>
              <div className='CelebrationText text-secondary text-smallest font-weight-600'>
                {i18nFormat(
                  I18N(`${_.snakeCase(type)}_celebration_text`),
                  <span className={labelClassName}>{celebrationTypeText()}</span>,
                  <span>{i18nMoment(eventDate).format('MMM Do')}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  )
}

const CelebrationsList = ({
  label, celebrations, width,
}) => (
  <div>
    <div className='text-secondary font-weight-600 my-3'>{label}</div>
    <div className='d-flex flex-column gap-3'>
      {celebrations.map(celebration => (
        <CelebrationListItem
          key={`${celebration.type}-${celebration.user.id}-${celebration.eventDate}`}
          celebration={celebration}
          width={width}
        />
      ))}
    </div>
  </div>
)

const CelebrationsWidget = ({ widget, resizeWidget }) => {
  const currentUser = useCurrentUser()

  const { splitIntoFollowedAndOthers } = widget.settings

  const [fetchFollowedCelebrations, {
    data: fetchedFollowedUsersCelebrations = [],
    isLoaded: isLoadedFollowedUsers,
  }] = useApi(
    () => API.celebrations.fetchAll({ followedUsers: true })
  )

  // needed to display the changes when the user changes the settings
  const followedUsersCelebrations = splitIntoFollowedAndOthers ? fetchedFollowedUsersCelebrations : []

  useEffect(() => {
    if (!isLoadedFollowedUsers && splitIntoFollowedAndOthers) {
      fetchFollowedCelebrations()
    }
  }, [splitIntoFollowedAndOthers])

  const {
    data: allCelebrations = [],
    isLoaded: isLoadedAll,
  } = useFetch(API.celebrations.fetchAll)

  const isLoaded = (isLoadedFollowedUsers || !splitIntoFollowedAndOthers) && isLoadedAll

  const followedUsersCelebrationsIds = followedUsersCelebrations.map(c => c.id)
  const followedUsersCelebrationsCount = followedUsersCelebrations.length || 0
  const otherUsersCelebrations = allCelebrations.filter(
    celebration => !followedUsersCelebrationsIds.includes(celebration.id)
  ).slice(0, 20 - followedUsersCelebrationsCount)

  return (
    <Card
      className='CelebrationsWidget HomePageWidget'
      cardBodyClassName='p-0'
    >
      <div className='CelebrationsWidget-Title w-100 position-relative overflow-hidden border-radius-top bg-highlight-color-tint-90'>
        <h5 className='m-0'>{I18N('celebrations')}</h5>

        <CdnSvg
          className='CelebrationWithCakeIcon position-absolute overflow-hidden top-0 right-0'
          src='/images/illustrations/celebrationWithCake.svg'
        />
      </div>

      <AutoResizableScrollableSection
        widget={widget}
        resizeWidget={resizeWidget}
        isLoaded={isLoaded}
        scrollableContentClassName='px-4 d-flex flex-column pb-4'
      >
        {!isLoaded && <LoadingIndicator />}
        {isLoaded && _.isEmpty(followedUsersCelebrations) && _.isEmpty(otherUsersCelebrations) && (
          <div className='d-flex flex-column align-items-center gap-2 py-4'>
            <CdnSvg className='BalloonsWithGiftImage' src='/images/illustrations/balloonsWithGift.svg' />
            <div className='text-center'>{I18N('no_more_celebrations')}</div>
          </div>
        )}
        {isLoaded && !_.isEmpty(followedUsersCelebrations) && (
          <CelebrationsList
            label={_.isEmpty(otherUsersCelebrations) ? '' : I18N('people_i_follow')}
            celebrations={followedUsersCelebrations}
            width={widget.w}
          />
        )}
        {isLoaded && !_.isEmpty(otherUsersCelebrations) && (
          <CelebrationsList
            label={_.isEmpty(followedUsersCelebrations) ? '' : I18N('other_celebrations')}
            celebrations={otherUsersCelebrations}
            width={widget.w}
          />
        )}
      </AutoResizableScrollableSection>
    </Card>
  )
}

export default CelebrationsWidget
