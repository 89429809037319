import React from 'react'
import { useParams } from 'react-router-dom'

import useStepCreation from 'components/admin/journeys/hooks/useStepCreation'
import createStepMap from 'utils/journeys/createStepMap'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import BlueprintLink from 'components/admin/journeys/blueprintLink'

const I18N = i18nPath('views.admin.journeys.steps.create_step_page')

const CreateStepPage = () => {
  const { stepType } = useParams()
  const stepConfig = createStepMap[stepType]
  const currentCompany = useCurrentCompany()

  const {
    FormComponent,
    generateDefaultWorkingCopy,
    entityAttributes,
    createApiPath,
    interpolatedFields,
    HelpCard,
  } = stepConfig

  const defaultWorkingCopy = (type: string) => generateDefaultWorkingCopy(type, currentCompany)

  const {
    name,
    workingCopy,
    updateWorkingCopy,
    handleSave,
    journeyBlueprint,
    isSaving,
  } = useStepCreation({
    defaultWorkingCopy,
    entityAttributes,
    createApiPath,
    interpolatedFields,
  })

  if (_.isEmpty(workingCopy)) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='CreateStepPage'>
      <header className='AdminHeader'>
        <h3 className='mb-0'>{name}</h3>

        <span className='text-secondary'>
          {i18nFormat(I18N(`create_${stepType}_description`),
            <p className='d-inline text-secondary'>{workingCopy.forUser?.preferredFullName}</p>,
            <BlueprintLink className='d-inline' journeyBlueprint={journeyBlueprint} />
          )}
        </span>
      </header>

      <main className='AdminContent'>
        <FormComponent
          workingCopy={workingCopy}
          updateWorkingCopy={updateWorkingCopy}
          onSave={handleSave}
          journeyBlueprint={journeyBlueprint}
          isSaving={isSaving}
          isStepTemplate={false}
          helpCard={HelpCard ? <HelpCard /> : null}
        />
      </main>
    </div>
  )
}

export default CreateStepPage
