import CdnSvg from 'components/common/cdnSvg'
import React, { useEffect, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.pages.faqs')

const FAQS_LIST_SELECTOR = '.FAQsList'

const ScrollToFAQsLink = ({ page }) => {
  const [faqsListVisible, setFaqsListVisible] = useState(false)

  useEffect(() => {
    if (!page.showFaqs) return undefined

    let timeoutId
    const startTime = Date.now()

    const checkForFAQsList = () => {
      const isFAQsListPresent = !!document.querySelector(FAQS_LIST_SELECTOR)
      setFaqsListVisible(isFAQsListPresent)

      if (!isFAQsListPresent && Date.now() - startTime < 2000) {
        timeoutId = setTimeout(checkForFAQsList, 500)
      }
    }

    if (page.showFaqs) {
      checkForFAQsList()
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [page.showFaqs])

  if (!faqsListVisible) {
    return null
  }

  const onClick = (e) => {
    e.preventDefault()
    document.querySelector(FAQS_LIST_SELECTOR)?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <a className='ScrollToFAQsLink' href='#FAQsList' onClick={onClick}>
      <CdnSvg src='/images/questionIcon.svg' className='QuestionIcon' />
      {I18N('faq')}
    </a>
  )
}

export default ScrollToFAQsLink
