import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useHistory, Redirect, useLocation
} from 'react-router-dom'
import searchSlice from 'redux/slices/search'
import useQueryParams from 'components/common/hooks/useQueryParams'
import TopLevelFilter from 'components/search/filters/topLevelFilter'
import SearchResults from 'components/search/search_page_results/searchResults'
import MobileFilter from 'components/search/filters/mobileFilter'
import MobileFilterButton from 'components/search/filters/mobileFilterButton'
import SearchBar from 'components/search/searchBar'
import GolinkNotFound from 'components/search/golinkNotFound'
import Card from 'components/common/card'
import RefinedFilters from 'components/search/filters/refined_filters'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AiOverview from 'components/search/ai/aiOverview'
import useIsAiAssistantAnswersEnabled from 'hooks/ai/useIsAssistantAnswersEnabled'

const SearchResultsPage = () => {
  const {
    query, type, golinkNotFound, filter,
  } = useQueryParams()
  const currentCompany = useCurrentCompany()
  const dispatch = useDispatch()
  const history = useHistory()
  const { settings } = currentCompany
  const isGoogleDriveSearchEnabled = settings.googleDriveSearch.enabled
  const isConfluenceSearchEnabled = settings.confluenceSearch?.enabled
  const isNotionSearchEnabled = settings.notionSearch?.enabled
  const isOneDriveEnabled = settings.oneDrive?.searchEnabled
  const isBloomfireSearchEnabled = settings.bloomfireSearch?.enabled
  const isAiAssistantAnswersEnabled = useIsAiAssistantAnswersEnabled()

  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)
  const searchResultsFilter = filter || 'all'
  const [selectedFilters, setSelectedFilters] = useState({ type: searchResultsFilter })
  const { search } = useLocation()
  const searchSessionId = useSelector(searchSlice.selectors.getSessionId)

  useEffect(() => {
    setSelectedFilters({ type: searchResultsFilter })
  }, [searchResultsFilter])

  const onMobileFilterChange = (newSelectedFilters) => {
    setSelectedFilters(newSelectedFilters)
    const params = new URLSearchParams(window.location.search)
    params.set('filter', newSelectedFilters.type)
    params.set('page', 1)
    history.push(`/search?${params.toString()}`)

    setIsMobileFilterOpen(false)
  }

  useEffect(() => {
    // searchLocation refers to the component that is doing the search, it can either be 'dropdown', 'pageAll' or 'pageFiltered'
    // searchOrigin is used for Amplitude tracking, and it reflects what the user was doing that triggered the search
    // it can be: 'dropdown', 'results_page', or 'golink_not_found'
    const params = {
      query,
      type: type || 'global',
      searchGoogleDrive: isGoogleDriveSearchEnabled && (!type || type === 'global' || type === 'google_drive'),
      searchConfluence: isConfluenceSearchEnabled && (!type || type === 'global' || type === 'confluence'),
      searchNotion: isNotionSearchEnabled && (!type || type === 'global' || type === 'notion'),
      searchBloomfire: isBloomfireSearchEnabled && (!type || type === 'global' || type === 'bloomfire'),
      searchOneDrive: isOneDriveEnabled && (!type || type === 'global' || type === 'one_drive'),
      searchLocation: 'pageAll',
      searchOrigin: golinkNotFound ? 'golink_not_found' : 'results_page',
      sessionId: searchSessionId,
    }

    dispatch(searchSlice.asyncActions.fetchResults(params))
  }, [query, type])

  if (!filter) {
    const newParams = new URLSearchParams(search)
    newParams.set('filter', 'all')
    return <Redirect exact from='/search' to={`/search?${newParams.toString()}`} />
  }

  if (searchResultsFilter === 'similar_links') {
    return (
      <Redirect
        exact
        from='/search/?filter=similar_links'
        to={{ pathname: '/search', search: `?filter=links&type=go_link&query=${query}` }}
      />
    )
  }

  return (
    <main className='SearchResultsPage'>
      <Card className='SearchResultsPage__card p-4' cardBodyClassName='SearchResultsPage__card-body'>
        <section className='SearchResultsPage__main'>
          <div className='SearchBar'>
            <div className='SearchBar__inner'>
              <SearchBar />
              <MobileFilterButton
                onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
              />
            </div>
          </div>

          <div className='NewSearchResults'>
            {isMobileFilterOpen ? (
              <MobileFilter onChange={onMobileFilterChange} selectedFilters={selectedFilters} />
            ) : (
              <>
                {isAiAssistantAnswersEnabled && filter === 'all' && !golinkNotFound && (
                  <AiOverview />
                )}
                {golinkNotFound && <GolinkNotFound query={query} />}
                <SearchResults />
              </>
            )}
          </div>
        </section>
        <section className='NewSearchFilters'>
          <TopLevelFilter />
          <RefinedFilters />
        </section>
      </Card>
    </main>
  )
}

export default SearchResultsPage
