import React from 'react'

import ReactSelect from 'components/common/react_select'
import moment from 'moment-timezone'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'

const allTimezones = moment.tz.names().map(name => ({ timezone: name }))

const sortedTimezones = allTimezones.sort((first, second) => {
  const firstOffset = moment().tz(first.timezone).utcOffset()
  const secondOffset = moment().tz(second.timezone).utcOffset()

  return secondOffset - firstOffset
})

const FormTimezoneSelectField = ({
  id = 'timezone_select',
  placeholder = '',
  currentValue = '',
  onChange = (timezone) => { },
  fieldName = 'timezone',
  label = '',
  isRequired = false,
  className = '',
  footNote = null,
  isClearable = true,
  isDisabled = false,
}) => {
  const selectedTimezone = allTimezones.find(timezone => timezone.timezone === currentValue) || null

  return (
    <LabeledFormFieldContainer
      isRequired={isRequired}
      footNote={footNote}
      className={className}
      label={label || fieldName}
    >
      <ReactSelect
        id={id}
        name='timezone'
        getOptionValue={timezone => timezone.timezone}
        getOptionLabel={timezone => timezone.timezone}
        value={selectedTimezone}
        options={sortedTimezones}
        onChange={onChange}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
    </LabeledFormFieldContainer>
  )
}

export default FormTimezoneSelectField
