import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import pageSlice from 'redux/slices/pages'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useWorkspaceUrls from 'components/pages/hooks/useWorkspaceUrls'

const useRedirectToFirstPage = (shouldRedirect: boolean) => {
  const { isLoadingAllPages } = useSelector(pageSlice.selectors.getPagesMetadata())
  const { isLoadingAllWorkspaces } = useSelector(pageWorkspaceSlice.selectors.getMetaData())

  const {
    redirectToFirstPage, filteredPages,
  } = useWorkspaceUrls()

  useEffect(() => {
    if (shouldRedirect && !isLoadingAllPages && !isLoadingAllWorkspaces && filteredPages.length > 0) {
      redirectToFirstPage()
    }
  }, [shouldRedirect, isLoadingAllPages, isLoadingAllWorkspaces, filteredPages.length])
}

export default useRedirectToFirstPage
