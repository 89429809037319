import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/common/buttons'
import DeleteModal from 'components/common/modals/deleteModal'
import userSlice from 'redux/slices/users'
import { useHistory } from 'react-router-dom'

const I18N = i18nPath('views.admin.users')

const DeleteUserButtonAndModal = ({
  user = null,
  className = '',
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isDeleting } = useSelector(userSlice.selectors.getAdminUserMetaData())

  const onSuccess = () => {
    history.push('/admin/users')
  }

  const onFailure = () => {
    history.push(`/admin/users/${user.id}`)
  }

  const onDelete = () => {
    dispatch(userSlice.asyncActions.admin.deleteUser(user, onSuccess, onFailure))
  }

  return (
    <div className={className}>
      <Button
        variant='secondary-danger'
        onClick={() => setIsModalOpen(true)}
      >
        {I18N('delete_user')}
      </Button>
      <DeleteModal
        deleteText={I18N('delete_user')}
        deleteSecondaryText={I18N('delete_confirm')}
        showDeleteModal={isModalOpen}
        closeDeleteModal={() => setIsModalOpen(false)}
        deleteConfirm={onDelete}
        isDeleting={isDeleting}
      />
    </div>
  )
}

export default DeleteUserButtonAndModal
