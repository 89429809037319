import React from 'react'
import { useParams } from 'react-router-dom'

import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import { i18nPath } from 'utils/i18nHelpers'
import PagesTab from 'components/analytics/pages/pagesTab'
import OverviewTab from 'components/analytics/overview/overviewTab'
import NewsTab from 'components/analytics/news/newsTab'
import CultureTab from 'components/analytics/culture/cultureTab'
import SearchTab from 'components/analytics/search/searchTab'
import AiAnswersTab from 'components/analytics/ai_answers/aiAnswersTab'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.platform_analytics')

const OVERVIEW = 'overview'
const NEWS = 'news'
const PAGES = 'pages'
const PEOPLE = 'people'
const CULTURE = 'culture'
const SEARCH = 'search'
const AI_ANSWERS = 'ai_answers'
const EVENTS = 'events'

const PlatformAnalyticsPage = () => {
  const { selectedTab } = useParams()
  const { settings, type } = useCurrentCompany()

  const isAiPlatform = type === 'ai_platform'
  const isNewsEnabled = settings?.news?.enabled
  const arePagesEnabled = settings?.pages?.enabled
  const areEventsEnabled = settings?.qna?.enabled
  const isAiAssistantAnswersEnabled = settings?.aiAssistantAnswers?.enabled

  const birthdaysEnabled = settings?.celebrations?.birthdays?.enabled
  const workAnniversaryEnabled = settings?.celebrations?.workAnniversaries?.enabled
  const newEmployeeWelcomeEnabled = settings?.celebrations?.newEmployees?.enabled
  // Necessary for the culture tab, if any of these are enabled we show the tab otherwise show as disabled
  // Badges does not count because there is not enough information in the culture tab
  const areGreetingCardEnabled = birthdaysEnabled || workAnniversaryEnabled || newEmployeeWelcomeEnabled
  const areShoutoutsEnabled = settings?.shoutouts?.enabled

  const isCultureEnabled = areShoutoutsEnabled || areGreetingCardEnabled

  const tabs = [
    {
      id: OVERVIEW,
      text: I18N('tabs.overview'),
    },
    {
      id: NEWS,
      text: I18N('tabs.news'),
    },
    {
      id: PAGES,
      text: I18N('tabs.pages'),
    },
    {
      id: PEOPLE,
      text: I18N('tabs.people'),
      hidden: true,
    },
    {
      id: CULTURE,
      text: I18N('tabs.culture'),
    },
    {
      id: SEARCH,
      text: I18N('tabs.search'),
    },
    {
      id: AI_ANSWERS,
      text: I18N('tabs.ai_answers'),
    },
    {
      id: EVENTS,
      text: I18N('tabs.events'),
      hidden: !areEventsEnabled,
    },
  ]

  return (
    <main className='PlatformAnalyticsPage'>
      <header className='my-4'>
        <div className='d-flex align-items-center mb-2'>
          <h2 className='mb-0'>
            {I18N('title')}
          </h2>
        </div>

        {!isAiPlatform && (
          <HistoryChangingPillTabSelector
            tabs={tabs}
            baseUrl='/platform_analytics'
            historyKeyToChange='pathname'
            variant='PageTab'
            className='ModuleTabs'
            pillClasses='text-normal'
          />
        )}
      </header>

      {isAiPlatform ? (
        <AiAnswersTab />
      ) : (
        <>
          {selectedTab === OVERVIEW && <OverviewTab />}
          {selectedTab === PAGES && (arePagesEnabled ? <PagesTab /> : <DisabledModuleTab moduleName={I18N('tabs.pages')} />)}
          {selectedTab === NEWS && (isNewsEnabled ? <NewsTab /> : <DisabledModuleTab moduleName={I18N('tabs.news')} />)}
          {selectedTab === CULTURE && (
            isCultureEnabled ? (
              <CultureTab areGreetingCardEnabled={areGreetingCardEnabled} areShoutoutsEnabled={areShoutoutsEnabled} />
            ) : (
              <DisabledModuleTab moduleName={I18N('tabs.culture')} plural />
            ))}
          {selectedTab === SEARCH && <SearchTab />}
          {selectedTab === AI_ANSWERS && (isAiAssistantAnswersEnabled ? <AiAnswersTab /> : <DisabledModuleTab moduleName={I18N('tabs.ai_answers')} />)}
          {selectedTab === EVENTS && <ComingSoonTab />}
        </>
      )}
    </main>
  )
}

const manWithBalloonPath = '/images/analytics/manWithBalloonIcon.svg'
const manWithTabletPath = '/images/analytics/manWithTabletIcon.svg'

const ComingSoonTab = () => (
  <div className='grid-span-12 d-flex flex-column align-items-center justify-content-center mt-6'>
    <CdnSvg src={manWithBalloonPath} />
    <h3 className='mt-3'>{I18N('coming_soon')}</h3>
  </div>
)

const DisabledModuleTab = ({ moduleName, plural = false }) => (
  <div className='DisabledModule grid-span-12 d-flex flex-column align-items-center justify-content-center mt-4 p-5'>
    <CdnSvg src={manWithTabletPath} />
    <h6 className='mt-3 text-center'>{plural ? I18N('disabled_modules', { module_name: moduleName }) : I18N('disabled_module', { module_name: moduleName })}</h6>
  </div>
)

export default PlatformAnalyticsPage
