import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.shoutouts')

const CompanyValuesInstructionsText = ({ minSelectableCompanyValues, maxSelectableCompanyValues }) => {
  const minCompanyValues = parseInt(minSelectableCompanyValues) || 0
  const maxCompanyValues = parseInt(maxSelectableCompanyValues) || 3

  let message = ''
  if (minCompanyValues === 1 && maxCompanyValues === 1) {
    message = I18N('company_values_exhibited_select_one')
  } else if (minCompanyValues === maxCompanyValues) {
    message = I18N('company_values_exhibited_select_n', { minSelectableCompanyValues: minCompanyValues })
  } else if (minCompanyValues === 0) {
    message = I18N('company_values_exhibited_select_upto_n', { maxSelectableCompanyValues: maxCompanyValues })
  } else {
    message = I18N('company_values_exhibited_select_range', {
      maxSelectableCompanyValues: maxCompanyValues,
      minSelectableCompanyValues: minCompanyValues,
    })
  }

  return (
    <div className='my-4'>
      <span className='font-weight-bold'>{I18N('values_exhibited')}</span>{' '}
      <span className='text-secondary'>
        {message}
      </span>
    </div>
  )
}

export default CompanyValuesInstructionsText
