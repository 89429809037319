import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import PillTabSelector from 'components/common/pillTabSelector'
import { i18nPath, i18nMoment, I18NCommon } from 'utils/i18nHelpers'
import onboardingBuddySlice from 'redux/slices/journeys/onboardingBuddies'
import newHireSlice from 'redux/slices/journeys/newHires'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'
import AssignButton from 'components/admin/journeys/onboardingBuddies/buddiesTable/assignButton'
import AskToOptInButton from 'components/admin/journeys/onboardingBuddies/buddiesTable/askToOptInButton'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import companyConfigurationSlice, { ONBOARDING_BUDDY_SETTINGS } from 'redux/slices/companyConfigurations'
import CriteriaFilters from 'components/admin/journeys/onboardingBuddies/buddiesTable/criteriaFilters'
import AssignedBuddiesActions from 'components/admin/journeys/onboardingBuddies/buddiesTable/assignedBuddiesActions'
import OptInButton from 'components/admin/journeys/onboardingBuddies/buddiesTable/optInButton'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.buddies_table')

const AVAILABLE = 'available'
const ASSIGNED = 'assigned'
const ELIGIBLE = 'eligible'
const INACTIVE = 'inactive'

const getCriteriaColumns = matchingCriteria => matchingCriteria.map((criteria) => {
  if (criteria.type === 'extra_user_field') {
    return {
      header: criteria.label,
      accessor: d => d.extraUserFields?.find(field => field.name === criteria.key)?.value,
    }
  }

  if (criteria.type === 'group_type') {
    return {
      header: criteria.label,
      accessor: d => d.groups?.filter(group => group.groupTypeId === criteria.key)?.map(group => group.name)?.join(', '),
    }
  }

  return {
    header: criteria.label,
    accessor: d => d[_.camelCase(criteria.attribute)],
  }
})

const BuddiesTable = ({ selectedNewHire }) => {
  const dispatch = useDispatch()
  const { settings } = useCurrentCompany()
  const allowMultipleNewHires = settings?.onboardingBuddySettings?.allowMultipleNewHires

  const [selectedStatus, setSelectedStatus] = useState(AVAILABLE)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)
  const [filters, setFilters] = useState({})

  const {
    onboardingBuddySettings: { automaticallyOptInEmployees, matchingCriteria = [] },
  } = settings

  const onboardingBuddies = useSelector(onboardingBuddySlice.selectors.getOnboardingBuddies())
  const { isLoading, queryParams } = useSelector(onboardingBuddySlice.selectors.getMetaData())
  const { isLoading: areNewHiresLoading } = useSelector(newHireSlice.selectors.getMetaData())
  const { isLoading: isLoadingConfig } = useSelector(companyConfigurationSlice.selectors.getMetaData())
  const newHires = useSelector(newHireSlice.selectors.getNewHires())

  const {
    page, totalPages, perPage, total,
  } = queryParams

  const statusFilters = [
    AVAILABLE,
    ASSIGNED,
    !automaticallyOptInEmployees && ELIGIBLE,
    INACTIVE,
  ].filter(Boolean)

  const tabs = statusFilters.map(filter => ({
    text: I18N(filter),
    onClick: () => setSelectedStatus(filter),
  }))

  const columns = [
    {
      header: I18N('name'),
      accessor: d => <UserLink user={d} customClassName='text-normal font-weight-500 text-primary-link' />,
    },
    ...getCriteriaColumns(matchingCriteria),
    selectedStatus === AVAILABLE && {
      header: I18N('count'),
      id: 'count',
      accessor: d => d.onboardingBuddiesCount,
    },
    selectedStatus === AVAILABLE && {
      header: I18N('last_assignment'),
      accessor: ({ lastOnboardingBuddyAt }) => (lastOnboardingBuddyAt ? i18nMoment(lastOnboardingBuddyAt).fromNow() : '-'),
    },
    selectedStatus === ASSIGNED && {
      header: allowMultipleNewHires ? I18N('assigned_new_hires') : I18N('assigned_new_hire'),
      accessor: ({ newHireBuddies }) => newHireBuddies.map(newHireBuddy => (
        <EmployeeOrCorrespondentLink key={`new-hire-buddy-${newHireBuddy.id}`} user={newHireBuddy} linkToAdmin className='mb-2' />
      )),
    },
    selectedStatus === ELIGIBLE && {
      header: I18N('asked_to_opt_in'),
      accessor: ({ onboardingBuddyAvailability }) => (
        onboardingBuddyAvailability?.askedToOptInAt && (
          <div className='text-secondary'>
            {i18nMoment(onboardingBuddyAvailability.askedToOptInAt).format('lll')}
          </div>
        )
      ),
    },
    selectedStatus === AVAILABLE && {
      header: I18N('actions'),
      accessor: d => <AssignButton onboardingBuddy={d} newHire={selectedNewHire} />,
    },
    selectedStatus === ASSIGNED && {
      header: I18N('actions'),
      accessor: d => <AssignedBuddiesActions onboardingBuddy={d} newHire={selectedNewHire} />,
    },
    selectedStatus === INACTIVE && {
      header: I18N('actions'),
      accessor: d => <OptInButton userId={d.id} />,
    },
    selectedStatus === ELIGIBLE && {
      header: I18N('actions'),
      accessor: d => <AskToOptInButton user={d} />,
    },
  ].filter(Boolean)

  const getParams = (newParams = {}) => ({
    ...queryParams,
    status: selectedStatus,
    q: searchQuery,
    newHireId: selectedNewHire?.id,
    sortBy,
    sortDir: sortDirection,
    filters,
    ...newParams,
  })

  const fetchOnboardingBuddies = params => dispatch(onboardingBuddySlice.asyncActions.admin.fetchAll(params))

  const handlePaginationClick = (newPaginationParams) => {
    fetchOnboardingBuddies(getParams(newPaginationParams))
  }

  const onSortChange = (newSortParams) => {
    setSortBy(newSortParams.sortBy)
    setSortDirection(newSortParams.sortDir)
  }

  useEffect(() => {
    if (!areNewHiresLoading && (selectedNewHire || newHires.length === 0)) {
      fetchOnboardingBuddies(getParams({ page: 1 }))
    }
  // eslint-disable-next-line max-len
  }, [selectedStatus, sortDirection, sortBy, searchQuery, selectedNewHire?.id, JSON.stringify(filters), areNewHiresLoading])

  const tableHiddenClass = isLoading ? 'd-none' : ''

  return (
    <div className='BuddiesTable'>
      <PillTabSelector
        defaultSelectedIndex={0}
        pillClasses='text-normal'
        tabs={tabs}
      />
      <div className='d-flex justify-content-between mb-0 mt-3'>
        <AsyncSearchInput
          className='mb-2 mr-2'
          placeholder={I18NCommon('search_by_name')}
          onKeyUp={setSearchQuery}
        />
        <CriteriaFilters
          matchingCriteria={matchingCriteria}
          setFilters={setFilters}
        />
      </div>
      <div>
        {(isLoading || isLoadingConfig) && <CirclesLoadingIndicator />}
        <SmartTable
          className={classNames('white-bg-table', `white-bg-table-${selectedStatus}`, tableHiddenClass)}
          data={onboardingBuddies}
          columns={columns}
          page={page}
          pages={totalPages}
          totalCount={total}
          perPage={perPage}
          showPagination={true}
          onPaginationClick={handlePaginationClick}
          onSortChange={onSortChange}
        />
      </div>
    </div>
  )
}

export default BuddiesTable
