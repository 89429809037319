import useQueryParams from 'components/common/hooks/useQueryParams'
import React, { useEffect, useState } from 'react'
import useDrivePicker from 'react-google-drive-picker'
import pageVariables from 'utils/pageVariables'
import Card from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import GoogleSignInImage from 'pages/external_sync/googleSignInImage'
import ConfirmationModal from 'components/common/modals/confirmationModal'

const I18N = i18nPath('views.integrations_page.google_drive_picker')

const PickerDialogHelper = ({ style }: { style: React.CSSProperties }) => (
  <div className='PickerDialogHelper p-2' style={style}>
    <div className='PickerDialogHelper-content d-flex gap-2 pt-2'>
      <CdnSvg src='/images/infoIcon.svg' />

      <p>
        {I18N('google_drive_sync_info')}
      </p>
    </div>
  </div>
)

const GoogleDrivePickerPage = () => {
  const { redirectUri } = useQueryParams()

  const [openPicker] = useDrivePicker()
  const [hasPickerDialog, setHasPickerDialog] = useState(false)
  const [style, setStyle] = useState<React.CSSProperties>({})
  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([])
  const [hasPickedFolder, setHasPickedFolder] = useState(false)

  // This effects is a hack to position a PickerHelper right below the PickerDialog
  // Waits for the picker dialog to load, then gets its position and set the PickerHelper's position
  useEffect(() => {
    let observer: ResizeObserver | null = null
    let updateInterval: any = null

    const clearyAppContainer = document.querySelector('#cleary-app-container')

    clearyAppContainer?.classList.add('position-initial')

    const interval = setInterval(() => {
      const pickerDialog = document.querySelector('.picker-dialog')
      if (pickerDialog) {
        clearInterval(interval)
        setHasPickerDialog(true)

        const updateStyle = () => {
          const pickerTopPosition = pickerDialog.getBoundingClientRect().y
          const pickerLeftPosition = pickerDialog.getBoundingClientRect().x
          const pickerWidth = pickerDialog.getBoundingClientRect().width
          const pickerHeight = pickerDialog.getBoundingClientRect().height

          setStyle({
            top: pickerTopPosition + pickerHeight,
            left: pickerLeftPosition,
            width: pickerWidth,
          })
        }

        updateStyle()

        // listen for picker content changes in width and position
        // this doesn't work very well when we're resizing the window
        // so we need to also have the interval
        observer = new ResizeObserver(updateStyle)

        observer.observe(pickerDialog)
        updateInterval = setInterval(updateStyle, 2000)
      }
    }, 100)

    return () => {
      clearyAppContainer?.classList.remove('position-initial')
      clearInterval(interval)
      observer?.disconnect()
      clearInterval(updateInterval)
    }
  }, [])

  const redirect = (fileIds?: string[]) => {
    if (fileIds) {
      window.location.href = `${redirectUri}?external_file_ids[]=${fileIds.join('&external_file_ids[]=')}`
    } else {
      window.location.href = redirectUri
    }
  }

  const handleOpenPicker = () => {
    openPicker({
      clientId: pageVariables.googleDriveApiClientId,
      developerKey: pageVariables.googleDriveApiDeveloperKey,
      viewId: 'DOCS',
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: false,
      multiselect: true,
      setSelectFolderEnabled: true,
      setIncludeFolders: true,
      appId: pageVariables.googleDriveApiAppId,
      customScopes: ['https://www.googleapis.com/auth/drive.appfolder', 'https://www.googleapis.com/auth/drive.file'],
      callbackFunction: (data) => {
        if (data.action === 'cancel' || data.action === 'picked') {
          setHasPickerDialog(false)
        }

        if (data.action === 'picked') {
          const ids = data.docs.filter(doc => doc.mimeType !== 'application/vnd.google-apps.folder').map(doc => doc.id)
          setSelectedFileIds(ids)

          const hasPickedFolder = data.docs.some(doc => doc.mimeType === 'application/vnd.google-apps.folder')
          if (hasPickedFolder) {
            setHasPickedFolder(true)
          } else {
            redirect(ids)
          }
        } else if (data.action === 'cancel') {
          redirect()
        }
      },
    })
  }

  // This ensures that the user is redirected properly if they close the Google Sign-In
  // window without completing the process, providing a smoother user experience.
  useEffect(() => {
    const originalOpen = window.open
    window.open = function (url, ...rest) {
      const newWindow = originalOpen(url, ...rest)
      if ((url as string | undefined)?.indexOf('https://accounts.google.com/') === 0) {
        const interval = setInterval(() => {
          if (newWindow?.closed) {
            clearInterval(interval)
            setTimeout(() => {
              if (!document.querySelector('.picker')) {
                window.location.href = redirectUri
              }
            }, 1000)
          }
        }, 1000)
      }
      return newWindow
    }
  }, [])

  return (
    <div className='GoogleDrivePickerPage pt-5'>
      {hasPickerDialog && <PickerDialogHelper style={style} />}

      {hasPickedFolder && (
        <ConfirmationModal
          visible
          toggle={() => setHasPickedFolder(false)}
          onConfirmClick={(e) => {
            setHasPickedFolder(false)

            if (selectedFileIds.length > 0) {
              redirect(selectedFileIds)
            }
          }}
          title={I18N('folder_picked')}
          description={selectedFileIds.length > 0 ? I18N('folder_picked_description') : I18N('no_files_picked_description')}
          confirmText={selectedFileIds.length > 0 ? I18NCommon('confirm') : I18NCommon('ok')}
        />
      )}

      <Card className='mt-5 text-center px-2 py-5'>
        <h2>
          <CdnSvg src='/images/googleDriveIcon.svg' className='d-inline-block mr-2' />
          Google Drive
        </h2>

        <p className='text-xl bold my-4'>
          {I18N('sign_in_to_google')}
        </p>

        <button onClick={handleOpenPicker} className='p-0 border-0 bg-transparent'>
          <GoogleSignInImage />
        </button>
      </Card>
    </div>
  )
}

export default GoogleDrivePickerPage
