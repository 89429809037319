import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Card from 'components/common/card'
import RemoveXIcon from 'components/icons/removeXIcon'

import AgendaItem from 'components/qna/agendaItem'

import { i18nFormat, i18nPath } from 'utils/i18nHelpers'
import qnaEventSlice from 'redux/slices/qnaEvents'

const I18N = i18nPath('views.qna.events.agenda_items')

const AgendaItemWidget = ({
  event,
  enableEditing,
}) => {
  const dispatch = useDispatch()

  const { agendaItems } = event

  const canManageItems = enableEditing

  const [showDeleteIcon, setShowDeleteIcon] = useState(false)
  const [showAgendaItemForm, setShowAgendaItemForm] = useState(false)
  const [newAgendaItem, setNewAgendaItem] = useState('')
  const [textRow, setTextRow] = useState(1)
  const [editTextRowCount, setEditTextRowCount] = useState(null)
  const [editedAgendaItem, setEditedAgendaItem] = useState('')
  const [showAnotherForm, setShowAnotherForm] = useState(false)
  const [agendaItemsToDisplay, setAgendaItemsToDisplay] = useState(agendaItems)

  useEffect(() => {
    setTextRow((Math.floor(newAgendaItem.length / 40)) + 1)
  }, [newAgendaItem])

  useEffect(() => {
    setEditTextRowCount((Math.floor(editedAgendaItem.length / 40)) + 1)
  }, [editedAgendaItem])

  useEffect(() => {
    if (showAgendaItemForm) {
      document.getElementById('add-new-agenda-form').focus()
    }
  }, [showAgendaItemForm])

  useEffect(() => {
    // do not update if both are same
    if (!_.isEqual(agendaItems, agendaItemsToDisplay)) {
      const eventData = { ...event, agendaItems: agendaItemsToDisplay }
      dispatch(qnaEventSlice.asyncActions.admin.updateAgendaItems(eventData))
    }
  }, [agendaItemsToDisplay])

  const cancelAgendaItem = () => {
    const eventData = { ...event, settings: { ...event.settings, agendaItems: { enabled: false } } }
    dispatch(qnaEventSlice.asyncActions.admin.update(eventData))
  }

  const saveItem = (item) => {
    if (newAgendaItem.length > 0) {
      setAgendaItemsToDisplay(agendaItemsToDisplay.concat(item))
      setNewAgendaItem('')
      setShowAnotherForm(true)
    }
    setShowAgendaItemForm(false)
  }

  const updateItem = (index) => {
    if (editedAgendaItem.length > 0) {
      const items = [...agendaItemsToDisplay]
      items[index] = editedAgendaItem
      setAgendaItemsToDisplay(items)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      saveItem(newAgendaItem)
    }
  }

  const handleBlur = (e) => {
    saveItem(e.target.value)
    setShowAnotherForm(false)
  }

  const deleteItem = (index) => {
    const items = [...agendaItemsToDisplay]
    items.splice(index, 1)
    setAgendaItemsToDisplay(items)
  }

  return (
    <Card className='mb-3 AgendaItemWidget'>
      <div className='d-flex mb-2 justify-content-between'>
        <span className='font-weight-500'>{I18N('agenda')}</span>
        {canManageItems && (
          <span className='mr-2 pointer' id='cancel-agenda-item' onClick={() => cancelAgendaItem()}>
            <RemoveXIcon />
          </span>
        )}
      </div>
      {!canManageItems && !agendaItemsToDisplay.length && (
        <div className='text-muted d-flex justify-content-center'>
          {I18N('agenda_coming_soon')}
        </div>
      )}
      <div>
        <ul className='pl-1 ml-3'>
          {agendaItemsToDisplay.map((item, index) => (
            <AgendaItem
              item={item}
              canManageItems={canManageItems}
              deleteItem={() => deleteItem(index)}
              updateItem={() => updateItem(index)}
              setEditedAgendaItem={setEditedAgendaItem}
              editTextRowCount={editTextRowCount}
              key={`agenda-item-${index}`}
            />
          ))}
          {((canManageItems && showAgendaItemForm) || showAnotherForm) && (
            <li className='mr-1 AddAgendaItemForm'>
              <textarea
                rows={textRow}
                className='AgendaItemInput'
                onChange={e => setNewAgendaItem(e.target.value)}
                onKeyPress={e => handleKeyPress(e)}
                onBlur={e => handleBlur(e)}
                id='add-new-agenda-form'
                value={newAgendaItem}
              />
            </li>
          )}
        </ul>
        <div className='mt-1'>
          {canManageItems && !showAgendaItemForm && !showAnotherForm && (
            <span onClick={() => setShowAgendaItemForm(true)} className='hover-text-color-primary pointer text-secondary'>
            + {I18N('add_an_agenda_item')}
            </span>
          )}
        </div>
      </div>
    </Card>
  )
}

export default AgendaItemWidget
