import React, {
  useRef, useState, useEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import qnaAnswerSlice from 'redux/slices/qnaAnswers'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import useMouseLeave from 'use-mouse-leave'
import isMobile from 'utils/isMobile'

import QuestionContent from 'components/qna/questionContent'
import Answer from 'components/qna/answer'
import DeleteQuestionDropdown from 'components/qna/deleteQuestionDropdown'
import QuestionVotingSection from 'components/qna/questionVotingSection'
import AnswerForm from 'components/qna/answerForm'
import AnswerList from 'components/qna/answerList'
import CommentsWidget from 'components/comments/commentsWidget'
import LoadingIndicator from 'components/common/circlesLoadingIndicator'
import { Button } from 'components/common/buttons/button'
import AnswersCount from 'components/qna/answersCount'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.qna.events')

const EventPageQuestion = ({ event, question, isPreview = false }) => {
  const dispatch = useDispatch()
  const [showAnswers, setShowAnswers] = useState(false)
  const [answersFetched, setAnswersFetched] = useState(false)
  const [answerCountHover, setAnswerCountHover] = useState(false)
  const [commentsCount, setCommentsCount] = useState(question?.commentsCount)

  const questionContainerRef = useRef()
  const answerSliceData = useSelector(qnaAnswerSlice.selectors.getAnswerFormData())
  const currentCompany = useCurrentCompany()
  const [mouseLeft, answerCountButtonRef] = useMouseLeave()

  const { permittedActions } = question
  const { isAnswerFormVisible, newAnswerIds } = answerSliceData
  const answersCount = question.answersCount
  const questionSliceData = useSelector(qnaQuestionSlice.selectors.getQuestionPageData(question.id))
  const {
    meta: { isLoadingAnswers },
    currentQuestionId,
  } = answerSliceData
  const answers = questionSliceData.question.answers ?? []

  const useVerifiedApproval = event.settings.answerApproval === 'verified'
  const isAnswerApproved = (answer) => {
    if (useVerifiedApproval) {
      return answer.isVerified
    } else {
      return answer.isAccepted
    }
  }

  // when previewing the event as a participant, moderators should be prevented from answering a question when
  // participants are not allowed to answer. (ie. the event is set to "questions answerable by moderators")
  const questionsAnswerableByAnyone = event.questionsAnswerableBy === 'anyone'
  const isIcebreaker = question.type === 'icebreaker'
  const canAnswerWhenPreviewing = isPreview && (questionsAnswerableByAnyone || isIcebreaker)
  const canAnswerQuestion = canAnswerWhenPreviewing || (!isPreview && permittedActions.answer)

  const newAnswersWithoutApproved = answers?.filter(answer => !isAnswerApproved(answer))
                                            .filter(answer => newAnswerIds.includes(answer.id))

  const answersWithoutApprovedAndNewlyAdded = answers
    .filter(answer => !isAnswerApproved(answer))
    .filter(answer => !newAnswersWithoutApproved.map(newAnswer => newAnswer.id).includes(answer.id))

  const approvedAnswer = useVerifiedApproval ? question.verifiedAnswer : question.acceptedAnswer

  const showHorizonalRule = approvedAnswer || showAnswers || !_.isEmpty(newAnswersWithoutApproved)
  const showVotingSection = !isIcebreaker
  const isQuestionBeingAnswered = question.id === currentQuestionId
  const shouldDisplayAnswerCountHoverBackground = answerCountHover && question.answersCount > 0
  const showAnswerQuestionButtonAndCount = (
    !isAnswerFormVisible && isQuestionBeingAnswered && question.isApproved
  ) || (!isQuestionBeingAnswered && question.isApproved)

  const fetchAndToggleAnswers = (questionId) => {
    if (answersCount === 0) return

    if (answersFetched) {
      setShowAnswers(!showAnswers)
      return
    }

    dispatch(qnaAnswerSlice.actions.resetNewAnswerIds())
    dispatch(qnaAnswerSlice.actions.resetAnswerForm())

    dispatch(qnaAnswerSlice.asyncActions.fetchAnswers(questionId)).then(() => {
      setShowAnswers(true)
      setAnswersFetched(true)
    })
  }

  const showAnswerForm = (questionId) => {
    dispatch(qnaAnswerSlice.actions.resetAnswerForm())
    dispatch(qnaAnswerSlice.actions.isAnswerFormVisible(true))
    dispatch(qnaAnswerSlice.actions.setCurrentQuestionId(questionId))
  }

  const showAnswerFormHandler = (ev, questionId) => {
    ev.stopPropagation()

    showAnswerForm(questionId)
  }

  useEffect(
    () => () => {
      dispatch(qnaAnswerSlice.actions.isAnswerFormVisible(false))
      dispatch(qnaAnswerSlice.actions.resetNewAnswerIds())
      dispatch(qnaAnswerSlice.actions.setCurrentQuestionId(null))
    },
    []
  )

  // Necessary to open icebreaker answer form after user
  // clicks on 'Answer this Question' in highlighted event
  useEffect(() => {
    const { selectedQuestionIdFromHighlightedEvent } = questionSliceData
    if (selectedQuestionIdFromHighlightedEvent) {
      showAnswerForm(selectedQuestionIdFromHighlightedEvent)
    }
  }, [qnaQuestionSlice.selectedQuestionIdFromHighlightedEvent])

  // onMouseLeave is unreliable due to a browser issue,
  // is necessary to use a workaround to make onMouseLeave behave
  // consistently, use-mouse-leave solves this problem.
  // see https://github.com/mjsarfatti/use-mouse-leave/#but-why for
  // more context. Given the way the library solves the issue (tracking mouse moves down to pixels),
  // there could be some performance issues, but maybe something we can omit for now.
  useEffect(() => {
    if (mouseLeft) {
      setAnswerCountHover(false)
    }
  }, [mouseLeft])

  const AnswerThisQuestionButtonAndAnswerCount = () => (
    <>
      {
      // flex in mobile
      // inline-block medium devices and up
      }
      <span className='d-flex d-md-inline-block justify-content-between mt-3 mb-2'>
        {canAnswerQuestion && (
          <Button
            className='mr-0 mr-md-3 btn-muted'
            onClick={ev => showAnswerFormHandler(ev, question.id)}
            disabled={isAnswerFormVisible && isQuestionBeingAnswered}
          >
            {I18N('answer_this_question')}
          </Button>
        )}

        {showVotingSection && isMobile() && (
          <QuestionVotingSection event={event} question={question} isMobile={isMobile()} />
        )}
      </span>
      <span
        className={classNames('text-small pill-button', shouldDisplayAnswerCountHoverBackground && 'pill-button-active', answersCount === 0 && 'cursor-default')}
        onClick={() => fetchAndToggleAnswers(question.id)}
        // The combination of `ref` and `onMouseEnter` causes a bug in mobile where you have to click twice
        // in order to trigger the `onClick` handler. We need to exclude these props from being set in in mobile view.
        {...{
          ...(!isMobile() && { ref: answerCountButtonRef, onMouseEnter: () => setAnswerCountHover(true) }),
        }}
      >
        <AnswersCount answersCount={answersCount} showAnswers={showAnswers} />
      </span>
    </>
  )

  return (
    <div ref={questionContainerRef}>
      <DeleteQuestionDropdown question={question} hideDeleteOption={isPreview} />
      <div className='d-flex'>
        {showVotingSection && !isMobile() && (
          <div className='mt-3 mr-3'>
            <QuestionVotingSection event={event} question={question} />
          </div>
        )}

        <div className='mr-3 w-90'>
          <div className='overflow-x-auto overflow-y-hidden'>
            <QuestionContent question={question} />
          </div>
          <div>
            {showAnswerQuestionButtonAndCount && <AnswerThisQuestionButtonAndAnswerCount />}

            {currentCompany.settings.qna.commentsOnQuestions.enabled && question.isApproved && (
              <CommentsWidget
                commentable={question}
                type='question'
                key={`comments-question-${question.id}`}
                commentsCount={commentsCount}
                setCommentsCount={setCommentsCount}
                showCommentsOnInit={approvedAnswer == null}
                commentsOnQuestions
                style={{ top: '1px' }}
                className='position-relative ml-2 EventPageQuestion-commentsWidget'
                disableComments={isAnswerFormVisible && isQuestionBeingAnswered}
                disableNewComments={event.isArchived}
                hideDeleteOption={isPreview}
              />
            )}
          </div>

          {isAnswerFormVisible && isQuestionBeingAnswered && <AnswerForm question={question} className='mt-3' />}

          {showHorizonalRule && <hr className='my-3' />}

          {!_.isEmpty(newAnswersWithoutApproved) && newAnswersWithoutApproved.map(answer => (
            <Answer key={answer.id} answer={answer} />
          ))}

          {approvedAnswer && <Answer answer={approvedAnswer} />}

          {isLoadingAnswers && question.id === currentQuestionId && <LoadingIndicator />}

          {showAnswers && <AnswerList answers={answersWithoutApprovedAndNewlyAdded} isPreview={isPreview} />}
        </div>
      </div>
    </div>
  )
}

export default EventPageQuestion
