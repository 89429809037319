import React, {
  useEffect, useMemo
} from 'react'

import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import pageTemplateSlice, { buildPageTemplatePayload } from 'redux/slices/pageTemplates'
import EditableTitle from 'components/common/editable_title'
import LegalNoticesFooter from 'components/common/legalNoticesFooter'
import PageCoverImage from 'components/pages/pageCoverImage'
import EditTemplateToolbar from 'components/pages/editTemplateToolbar'
import useBeforeUnload from 'components/common/hooks/useBeforeUnload'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import UpdaterAndTimestamp from 'components/pages/updaterAndTimestamp'
import TogglePageWidth from 'components/pages/actions_dropdown/togglePageWidth'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'


interface EditTemplateContentProps {
  isSaving: boolean
  isDesktopNavOpen: boolean
  onClickOpenMobileNav: () => void
  pageTemplate: any
  workingCopy: any
  setWorkingCopy: (values) => void
}

const EditTemplateContent = ({
  pageTemplate,
  isDesktopNavOpen,
  isSaving,
  workingCopy,
  setWorkingCopy,
  onClickOpenMobileNav,
}: EditTemplateContentProps) => {
  const dispatch = useDispatch()

  const currentCompany = useCurrentCompany()
  const { settings } = currentCompany
  const isContentEmpty = _.isEmpty(workingCopy.content)
  const isMissingTitle = !workingCopy.title
  const showFullWidth = workingCopy.showFullWidth

  const areChangesPresent = useMemo(() => !_.isEqual(
    buildPageTemplatePayload(pageTemplate),
    buildPageTemplatePayload(workingCopy)
  ), [pageTemplate, workingCopy])

  const shouldBlockNavigation = areChangesPresent && !isSaving

  // Prevents user from reloading page if changes are pending
  useBeforeUnload((e) => {
    if (shouldBlockNavigation && !isSaving) {
      e.preventDefault()
      e.returnValue = ''
    }
  })

  useEffect(() => {
    // if workingCopy is empty, always set it to be the page
    if (_.isEmpty(workingCopy)) {
      setWorkingCopy(_.cloneDeep(pageTemplate))
      return
    }
    const changedKeys = Object.keys(pageTemplate).filter(key => !_.isEqual(pageTemplate[key], workingCopy[key]))

    // update only cover images attributes if they were changed
    if (['coverImageUrl', 'coverImageCroppedArea', 'originalCoverImageUrl'].some(key => changedKeys.includes(key))) {
      setWorkingCopy(latestWorkingCopy => ({
        ...latestWorkingCopy,
        coverImageUrl: pageTemplate.coverImageUrl,
        coverImageCroppedArea: pageTemplate.coverImageCroppedArea,
        originalCoverImageUrl: pageTemplate.originalCoverImageUrl,
        updatedAt: pageTemplate.updatedAt,
        lastUpdatedAt: pageTemplate.lastUpdatedAt,
      }))
      return
    }

    // reset working copy after update
    setWorkingCopy(_.cloneDeep(pageTemplate))
  }, [pageTemplate?.updatedAt])


  const updateWorkingCopy = (fields) => {
    setWorkingCopy(latestWorkingCopy => ({ ...latestWorkingCopy, ...fields }))
    return null
  }

  const updatePageTemplate = () => {
    const {
      coverImageUrl,
      coverImageCroppedArea,
      templateCategory,
      ...pageAttrsToUpdate
    } = workingCopy

    pageAttrsToUpdate.templateCategoryId = templateCategory?.id

    dispatch(pageTemplateSlice.asyncActions.admin.update(pageAttrsToUpdate))
  }

  // Necessary to make the cover image endpoints go to the correct endpoint
  const customBaseUrl = `/api/admin/page/template_categories/${pageTemplate?.templateCategory?.id}/templates/${pageTemplate.id}`

  const mainClassname = 'EditTemplateContent'

  return (
    <div className={classNames(mainClassname, 'overflow-y-auto w-100 isEditing', { isDesktopNavOpen })}>
      <EditTemplateToolbar
        areChangesPresent={areChangesPresent}
        onClickSave={updatePageTemplate}
        onClickHamburgerNav={onClickOpenMobileNav}
        resetWorkingCopy={() => setWorkingCopy(_.cloneDeep(pageTemplate))}
        setWorkingCopy={setWorkingCopy}
        workingCopy={workingCopy}
      />
      <PageCoverImage
        page={pageTemplate}
        showActions={true}
        type='pageTemplate'
        options={{ customBaseUrl }}
      />
      <div className={classNames('EditTemplateTiptapWrapper isEditing', { showFullWidth })}>
        <TogglePageWidth
          showFullWidth={workingCopy.showFullWidth}
          onClick={() => updateWorkingCopy({ showFullWidth: !workingCopy.showFullWidth })}
        />

        <div className='d-flex flex-column-reverse flex-md-row justify-content-between'>
          <EditableTitle
            canEdit={true}
            className='flex-grow-1'
            editModeOnly={true}
            onChange={updateWorkingCopy}
            onSave={updateWorkingCopy}
            record={workingCopy}
            fieldName='title'
            showCharacterCount={true}
            showSaveCancelButtons={false}
            focus={isContentEmpty && isMissingTitle}
          />
        </div>
        <UpdaterAndTimestamp
          isEditing={true}
          areDraftChangesUnpublished={false}
          page={{
            ...pageTemplate,
            draft: false,
          }}
        />
        <div className='flex-grow-1'>
          {!_.isEmpty(workingCopy) && (
            <TiptapEditor
              onChange={content => updateWorkingCopy({ content })}
              html={workingCopy.content}
            />
          )}
        </div>
        {settings.legalNotices.contentFooter.enabled && <LegalNoticesFooter />}
      </div>
    </div>
  )
}

export default EditTemplateContent
