import React from 'react'
import VideoIcon from 'components/icons/videoIcon'
import ForwardArrowIcon from 'components/icons/forwardArrowIcon'
import { i18nPath } from 'utils/i18nHelpers'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import qnaEventSlice from 'redux/slices/qnaEvents'
import { useDispatch } from 'react-redux'
import VideoConferenceRecapToggle from 'components/qna/event_fields/videoConferenceRecapToggle'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.qna.events')

const ZoomSettingsPopover = ({ url, generateRecap, onGenerateRecapChange }) => (
  <div className='p-2'>
    <div className='font-weight-500 mb-2'>{I18N('zoom_settings')}</div>
    <VideoConferenceRecapToggle
      url={url}
      checked={generateRecap}
      onChange={onGenerateRecapChange}
      showHelpText={false}
    />
  </div>
)

const VideoConferenceLinkDisplay = ({
  eventId,
  link,
  button = false,
  generateRecapArticle = false,
  showZoomSettings = false,
}) => {
  const dispatch = useDispatch()
  const isZoomLink = link.match(/zoom/)
  const { settings } = useCurrentCompany()

  const videoConferenceLinkText = () => {
    if (isZoomLink) {
      return 'Zoom'
    } else if (link.match(/bluejeans/)) {
      return 'BlueJeans'
    } else if (link.match(/google/)) {
      return 'Google Meet'
    } else {
      return I18N('video_conference_link')
    }
  }

  const onGenerateRecapChange = (value) => {
    const updatedEvent = { id: eventId, generateRecapArticle: value }

    dispatch(qnaEventSlice.asyncActions.admin.update(updatedEvent))
  }

  return (
    button ? (
      <div>
        <a className='btn btn-primary btn-video-link p-4' href={link} target='_blank' rel='noopener noreferrer'>
          <span className='VideoConferenceLinkDisplay-button-text mr-1'>Go to {videoConferenceLinkText()}</span>
          <ForwardArrowIcon fillColor='var(--success-color)' />
        </a>
      </div>
    ) : (
      <>
        {settings.zoom?.enabled && isZoomLink && showZoomSettings ? (
          <HoverAndClickPopover
            target={(
              <>
                <VideoIcon className='mb-1' />
                <a className='ml-1 mr-1' href={link} target='_blank' rel='noopener noreferrer'>{videoConferenceLinkText()}</a>
              </>
            )}
            id='zoomPopover'
            placement='bottom'
            popoverClassName='ZoomSettingsPopover'
          >
            <ZoomSettingsPopover
              url={link}
              generateRecap={generateRecapArticle}
              onGenerateRecapChange={onGenerateRecapChange}
            />
          </HoverAndClickPopover>
        ) : (
          <a className='ml-1 mr-1' href={link} target='_blank' rel='noopener noreferrer'>{videoConferenceLinkText()}</a>
        )}

        <ForwardArrowIcon fillColor='var(--link-color)' />
      </>
    )
  )
}

export default VideoConferenceLinkDisplay
