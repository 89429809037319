import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { useParams } from 'react-router-dom'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import { useSelector } from 'react-redux'
import { CreateWorkspaceModal } from 'components/pages/workspaces/createWorkspaceButtonAndModal'
import useCreateDraftPage from 'components/pages/hooks/useCreateDraftPage'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.pages')
const womanWithLaptopAndCoffeeImagePath = '/images/illustrations/womanWithLaptopAndCoffee.svg'

const EmptyPage = () => {
  const currentUser = useCurrentUser()
  const { workspaceSlug } = useParams()

  const workspaces = useSelector(pageWorkspaceSlice.selectors.getPageWorkspaces())
  const emptyWorkspaces = _.isEmpty(workspaces)
  const currentWorkspace = workspaces.find(workspace => workspace.slug === workspaceSlug)

  const workspaceForPageCreation = currentWorkspace || workspaces[0]
  const permittedActions = workspaceForPageCreation?.permittedActions
  const canCreateWorkspacePages = workspaces.length > 0 && permittedActions?.canCreateAndMovePagesInWorkspace
  const isPageWorkspaceManager = currentUser.permissions.pageWorkspaceManager
  const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState(false)

  const canCreatePage = canCreateWorkspacePages
  const createDraftPage = useCreateDraftPage({ pageWorkspaceId: workspaceForPageCreation?.id })

  const headerText = () => {
    if (emptyWorkspaces) {
      return isPageWorkspaceManager ? I18N('share_knowledge') : I18N('stay_tuned')
    } else {
      return canCreatePage ? I18N('share_knowledge') : I18N('stay_tuned')
    }
  }

  const secondaryText = () => {
    if (emptyWorkspaces) {
      return isPageWorkspaceManager ? I18N('create_a_workspace_to_start') : I18N('company_resources_coming_soon')
    } else {
      return canCreatePage ? I18N('organize_knowledge') : I18N('coming_soon')
    }
  }

  return (
    <div className='EmptyPage w-100 mt-5'>
      <div className='pt-5 d-flex align-items-center flex-column'>
        <CdnSvg src={womanWithLaptopAndCoffeeImagePath} />
        <h2 className='mt-4'>
          {headerText()}
        </h2>
        <div className='text-secondary mb-4'>
          {secondaryText()}
        </div>
        {emptyWorkspaces && isPageWorkspaceManager && (
          <Button onClick={() => setCreateWorkspaceModalOpen(true)}>{I18N('create_workspace')}</Button>
        )}
        {(!emptyWorkspaces) && canCreatePage && (
          <Button onClick={createDraftPage}>{I18N('create_your_first_page')}</Button>
        )}
        {createWorkspaceModalOpen && (
          <CreateWorkspaceModal
            visible={createWorkspaceModalOpen}
            toggle={() => setCreateWorkspaceModalOpen(false)}
          />
        )}
      </div>
    </div>
  )
}

export default EmptyPage
