import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { askAiI18n } from 'components/ai/chat/ask_ai_modal/askAiUtils'
import ChatTab from 'components/ai/chat/ask_ai_modal/chatTab'
import HistoryTab from 'components/ai/chat/ask_ai_modal/historyTab'
import { AskAiTab } from 'components/ai/chat/ask_ai_modal/types'
import CdnSvg from 'components/common/cdnSvg'
import useClickOutside from 'components/common/hooks/useClickOutside'
import CloseIcon from 'components/icons/closeIcon'
import React, { useRef } from 'react'
import { createPortal } from 'react-dom'

const AskAiModal = () => {
  const {
    showAskAiModal,
    setShowAskAiModal,
    tab,
    setTab,
    onNewConversation,
    isIgnoringOutsideClick,
  } = useAskAiContext()
  const modalRef = useRef<HTMLDivElement>(null)

  useClickOutside({
    ref: modalRef,
    onClickOutside: () => setShowAskAiModal(false),
    enableClickOutside: showAskAiModal && !isIgnoringOutsideClick,
  })

  if (!showAskAiModal) return null

  return (
    <div className='AskAiModal' ref={modalRef}>
      <div className='AskAiModal-header d-flex justify-content-between align-items-center px-4 py-3'>
        <h4 className='mb-0 font-weight-normal'>
          <CdnSvg className='svg-white mr-1' src='/images/aiIcon.svg' />
          <span className='title-text text-white'>
            {askAiI18n('ask_ai')}
          </span>
        </h4>

        <div>
          <button
            onClick={() => {
              setTab(AskAiTab.CHAT)
              onNewConversation()
            }}
            data-testid='chat-tab-button'
          >
            <CdnSvg className='svg-white' src='/images/pencilSquareIcon.svg' />
          </button>
          <button
            onClick={() => setTab(AskAiTab.HISTORY)}
            data-testid='history-tab-button'
          >
            <CdnSvg className='svg-white clock-icon' src='/images/clockIcon.svg' />
          </button>
          <button onClick={() => setShowAskAiModal(false)}>
            <CloseIcon />
          </button>
        </div>
      </div>

      {tab === AskAiTab.CHAT && <ChatTab />}
      {tab === AskAiTab.HISTORY && <HistoryTab />}
    </div>
  )
}

const AskAiPortalRenderer = () => createPortal(<AskAiModal />, document.body)

export default AskAiPortalRenderer
