import React, { useState } from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import ToggleButtonGroup from './toggleGroup'

const I18N = i18nPath('views.qna.events.settings_modal.qna')

const ANSWER_APPROVAL_OPTIONS = [{ label: I18N('verified'), value: 'verified' }, { label: I18N('accepted'), value: 'accepted' }]

const VerifiedOrAcceptedAnswers = ({ event, setEvent }) => {
  const [helpText, setHelpText] = useState(`${ANSWER_APPROVAL_OPTIONS[0].value}_help_text`)
  const handleAnswerApprovalSettings = (value) => {
    setHelpText(`${value}_help_text`)
    setEvent({ ...event, settings: { ...event.settings, answerApproval: value } })
  }

  return (
    <div className='VerifiedOrAcceptedAnswers'>
      <ToggleButtonGroup
        label={I18N('verified_or_accepted_answers')}
        name='verified_or_accepted_answers'
        currentValue={event.settings.answerApproval}
        onChange={handleAnswerApprovalSettings}
        options={ANSWER_APPROVAL_OPTIONS}
        disabled={event.questionsAnswerableBy === 'moderators'}
        helpText={I18N(helpText)}
      />
    </div>
  )
}

export default VerifiedOrAcceptedAnswers
