import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'
import FormEditableField from 'components/form_fields/formEditableField'
import AudienceToggleSelector from 'components/common/audience/audienceToggleSelector'

const I18N = i18nPath('views.admin.managed_groups.form')

const ManagedGroupDetailsSection = ({
  workingCopy, setWorkingCopy,
}) => {
  const handleChange = (fieldName, value) => {
    setWorkingCopy({
      ...workingCopy,
      [fieldName]: value,
    })
  }

  return (
    <>
      <h6 className='text-secondary'>{I18N('define_group_membership')}</h6>
      <div className='background-color-white p-4 details-container'>
        <FormEditableField
          fieldName='name'
          label={I18N('group_name_label')}
          placeholder={I18N('group_name_placeholder')}
          onChange={e => handleChange('name', e?.target?.value || '')}
          currentValue={workingCopy.name}
          className='mb-4'
          isRequired
        />
        <AudienceToggleSelector
          workingCopy={workingCopy}
          updateWorkingCopy={changes => setWorkingCopy({ ...workingCopy, ...changes })}
          translationsPath='views.admin.managed_groups.form.audience_toggle_selector'
          showFootnote
          showAllTargetedDescription
        />
      </div>
    </>
  )
}

export default ManagedGroupDetailsSection
