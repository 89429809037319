import React from 'react'
import TrashIcon from 'components/icons/trashIcon'
import { SurveyQuestionType, QuestionTypeEnum } from 'types/surveys/question'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { SurveyQuestionOptionType } from 'types/surveys/questionOption'

interface QuestionOptionsListProps {
  options: SurveyQuestionOptionType[],
  question: SurveyQuestionType,
  updateQuestion?: (changes: Partial<SurveyQuestionType>) => void,
  isDisabled?: boolean,
  addOption?: () => void,
  latestInputRef?: any,
}

interface QuestionOptionProps {
  option: SurveyQuestionOptionType,
  question: SurveyQuestionType,
  optionIndex: number,
  updateOption?: (index: number, value: string) => void,
  removeOption?: (index: number) => void,
  isDisabled?: boolean,
  addOption?: () => void,
  latestInputRef?: any,
  isLastOption?: boolean,
}

const DragHandle = SortableHandle(() => <div className='drag-handle mx-1 p-1'>:::</div>)

const QuestionOption = SortableElement<QuestionOptionProps>(({
  option,
  question,
  optionIndex,
  updateOption = (index: number, value: string) => {},
  removeOption = (index: number) => {},
  isDisabled = false,
  addOption = () => {},
  latestInputRef = null,
  isLastOption = false,
}) => {
  const isMultipleOption = question?.type === QuestionTypeEnum.MultipleChoice

  const handleKeyPress = (event) => {
    if (isLastOption && event.key === 'Enter') {
      addOption()
    }
  }

  return (
    <div className='QuestionOption d-flex align-items-center mb-2'>
      <input
        className='mr-2'
        type={isMultipleOption ? 'radio' : 'checkbox'}
        checked={false}
        onChange={() => {}}
        disabled={isDisabled}
      />
      <input
        className='question-option-value-input'
        value={option?.value}
        type='text'
        onChange={({ target }) => updateOption(optionIndex, target.value)}
        onKeyDown={handleKeyPress}
        ref={isLastOption ? latestInputRef : null}
        disabled={isDisabled}
      />
      <DragHandle />
      {!isDisabled && (
        <div className='TrashIcon pointer' onClick={() => removeOption(optionIndex)}>
          <TrashIcon className='mr-2' />
        </div>
      )}
    </div>
  )
})


const QuestionOptionsList = SortableContainer<QuestionOptionsListProps>(({
  question,
  updateQuestion,
  options = [],
  isDisabled = false,
  addOption = () => {},
  latestInputRef = null,
}) => {
  const updateOption = (index: number, value: string) => {
    const newOptions: SurveyQuestionOptionType[] = [...options]
    newOptions[index] = { ...newOptions[index], value }
    updateQuestion({ options: newOptions })
  }

  const removeOption = (index: number) => {
    const newOptions = [...options]
    newOptions.splice(index, 1)
    updateQuestion({ options: newOptions })
  }

  return (
    <div className='mb-3'>
      {options.map((option, index) => (
        <QuestionOption
          question={question}
          key={`option-${index}`}
          option={option}
          index={index}
          optionIndex={index}
          updateOption={updateOption}
          removeOption={removeOption}
          isDisabled={isDisabled}
          latestInputRef={latestInputRef}
          addOption={addOption}
          isLastOption={index === options.length - 1}
        />
      ))}
    </div>
  )
})

export default QuestionOptionsList
