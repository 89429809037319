import React from 'react'

const GearIcon = ({
  height = 15, width = 15, className, fillColor = 'var(--subnav-text-color)',
}) => (
  <svg
    height={height}
    width={width}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    fill={fillColor}
  >
    <path
      d='M8.208.417c.658 0 1.19.532 1.19 1.19v.772a.24.24 0 00.156.205.27.27 0 00.29 0l.49-.567a1.183 1.183 0 011.678 0l.97.95c.227.222.354.525.355.843.007.322-.122.633-.354.857l-.525.524a.24.24 0 000 .262c.043.106.114.184.213.184h.772c.639.03 1.141.558 1.14 1.197v1.374a1.19 1.19 0 01-1.19 1.19h-.772a.24.24 0 00-.205.156.24.24 0 000 .262l.545.546a1.183 1.183 0 010 1.678l-.928.943c-.22.225-.52.353-.835.354a1.218 1.218 0 01-.843-.354l-.546-.525a.24.24 0 00-.262 0c-.106.043-.184.114-.184.213v.772a1.197 1.197 0 01-1.197 1.14H6.827a1.19 1.19 0 01-1.19-1.19v-.772a.24.24 0 00-.156-.205.27.27 0 00-.29 0l-.524.566a1.183 1.183 0 01-1.68 0l-.97-.949a1.176 1.176 0 01-.354-.843c0-.32.128-.629.355-.857l.524-.524a.24.24 0 000-.262c-.043-.106-.114-.184-.213-.184h-.722a1.197 1.197 0 01-1.19-1.197V6.827c0-.657.532-1.19 1.19-1.19h.772a.24.24 0 00.205-.156.27.27 0 000-.29l-.567-.524a1.183 1.183 0 010-1.68l.95-.97c.22-.225.52-.353.836-.354.323-.001.634.126.864.355l.524.524a.24.24 0 00.262 0c.106-.043.184-.114.184-.213v-.722A1.197 1.197 0 016.834.417h1.374zm-.233 1.416h-.921v.546A1.65 1.65 0 016.02 3.895a1.672 1.672 0 01-1.835-.333l-.382-.383-.624.624.383.39a1.693 1.693 0 01-1.183 2.832h-.546v.893h.546a1.65 1.65 0 011.516 1.034c.272.622.14 1.348-.333 1.835l-.383.382.624.623.39-.382a1.693 1.693 0 012.832 1.183l.029.574h.892v-.546a1.65 1.65 0 011.034-1.516 1.672 1.672 0 011.835.333l.383.383.623-.623-.383-.39a1.658 1.658 0 01-.34-1.813 1.65 1.65 0 011.516-1.027h.553v-.914h-.546a1.65 1.65 0 01-1.516-1.034 1.672 1.672 0 01.333-1.835l.383-.382-.623-.624-.39.383a1.693 1.693 0 01-2.833-1.183v-.546zm.474 3.377A2.48 2.48 0 116.55 9.79 2.48 2.48 0 018.45 5.21zM7.5 6.438a1.062 1.062 0 100 2.124 1.062 1.062 0 000-2.124z'
      fillRule='evenodd'
    />
  </svg>
)

export default GearIcon
