import React from 'react'

import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const celebrationIconPath = '/images/celebrationIcon.svg'

const CelebrationIconBanner = ({
  className = '',
  title = '',
}) => (
  <div className={classNames('CelebrationIconBanner d-flex flex-column align-items-center justify-content-center', className)}>
    <div className='circle-container'>
      <CdnSvg className='CelebrationIcon' src={celebrationIconPath} />
    </div>
    <h4 className='mt-3 text-left title'>{title}</h4>
  </div>
)

export default CelebrationIconBanner
