import React, { useRef, useState } from 'react'

import AsyncSearchInput from 'components/common/asyncSearchInput'
import { i18nPath } from 'utils/i18nHelpers'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'
import useClickOutside from 'components/common/hooks/useClickOutside'
import AudienceRulesSelector from 'components/common/audience/audienceRulesSelector'
import CdnSvg from 'components/common/cdnSvg'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import classNames from 'classnames'
import { TARGET_NO_ONE } from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.admin.journeys.tasks.task_list_page')

const TaskFilters = ({
  searchQuery,
  setSearchQuery,
  selectedToUserFilter,
  setToUserFilter,
  selectedForUserFilter,
  setForUserFilter,
  toUserTargetingRules,
  setToUserTargetingRules,
  completedToUserTargetingRules,
  forUserTargetingRules,
  setForUserTargetingRules,
  completedForUserTargetingRules,
  isMyTasksTabSelected,
}) => {
  const advancedFilterRef = useRef<HTMLDivElement>(null)
  const targetingOptions = useTargetingOptions()
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)

  useClickOutside({
    ref: advancedFilterRef,
    onClickOutside: () => setShowAdvancedFilter(false),
    enableClickOutside: showAdvancedFilter,
  })
  const areAdvancedFiltersInUse = completedToUserTargetingRules || completedForUserTargetingRules
  const onToUserFilterChange = (user) => {
    setToUserFilter(user?.username)
  }

  const onForUserFilterChange = (user) => {
    setForUserFilter(user?.username)
  }

  const onAdvancedFilterClick = () => {
    setShowAdvancedFilter(!showAdvancedFilter)

    // If we are opening the filter, we clear the selected users in simple filter
    if (!showAdvancedFilter) {
      if (selectedToUserFilter) setToUserFilter(null)
      if (selectedForUserFilter) setForUserFilter(null)
    }
  }

  return (
    <div className='TaskFilters d-flex justify-content-between mt-3 mb-3'>
      <AsyncSearchInput
        placeholder={I18N('search_by_task_name')}
        onKeyUp={setSearchQuery}
        className='mb-0'
        defaultValue={searchQuery}
      />
      <div className='d-flex justify-content-center align-items-center'>
        {!isMyTasksTabSelected && (
          <>
            <p className='text-secondary mb-0 mr-2'>{I18N('assigned_to')}</p>
            <EmployeeSearch
              key={`to-user-${selectedToUserFilter?.id}`}
              className='assigned-to-filter'
              placeholder={I18N('all_assignees')}
              selectedEmployee={selectedToUserFilter}
              onChange={onToUserFilterChange}
              includeInactiveUsers
              includeHiddenUsers
              isDisabled={areAdvancedFiltersInUse}
            />
          </>
        )}
        <p className='text-secondary mb-0 mx-2'>{I18N('for')}</p>
        <EmployeeSearch
          key={`for-user-${selectedForUserFilter?.id}`}
          className='for-filter'
          placeholder={I18N('all_assignees')}
          selectedEmployee={selectedForUserFilter}
          onChange={onForUserFilterChange}
          includeInactiveUsers
          includeHiddenUsers
          isDisabled={areAdvancedFiltersInUse}
        />

        <div className='AdvancedFilter' ref={advancedFilterRef}>
          <IconButtonWithPopover
            className={classNames('FunnelIcon ml-2', { isOpen: showAdvancedFilter, isInUse: areAdvancedFiltersInUse })}
            text={I18N('advanced_filter')}
            icon={<CdnSvg src='/images/funnelIcon.svg' />}
            onClick={onAdvancedFilterClick}
          />

          {showAdvancedFilter && (
            <div className='AdvancedFilter__popover'>
              {!isMyTasksTabSelected && (
                <div className='mb-4'>
                  <label className='text-secondary mb-2'>{I18N('assigned_to')}:</label>
                  <AudienceRulesSelector
                    targetingRules={toUserTargetingRules}
                    targetingOptions={targetingOptions}
                    onChange={setToUserTargetingRules}
                  />
                </div>
              )}
              <div className='mb-4'>
                <label className='text-secondary mb-2'>{I18N('for')}:</label>
                <AudienceRulesSelector
                  targetingRules={forUserTargetingRules}
                  targetingOptions={targetingOptions}
                  onChange={setForUserTargetingRules}
                />
              </div>
              <span
                className='text-small text-secondary pointer hover-highlight'
                onClick={() => {
                  setToUserTargetingRules(TARGET_NO_ONE)
                  setForUserTargetingRules(TARGET_NO_ONE)
                }}
              >
                {I18N('clear_filters')}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TaskFilters
