import normalize from 'json-api-normalizer'
import build from 'redux-object'

export const normalizeActivity = (activity) => {
  const normalizedActionable = normalize(activity.actionableOnCreatedAt)
  const actionable = build(
    normalizedActionable,
    _.camelCase(activity.actionableOnCreatedAt.data.type),
    activity.actionableOnCreatedAt.data.id
  )

  return {
    ...activity,
    actionableOnCreatedAt: actionable,
  }
}

export const buildActivity = (activity) => {
  const entity = normalize(activity)

  return build(entity, 'activity', activity.data.id)
}
