import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import qnaQuestionSlice from 'redux/slices/qnaQuestions'
import { trackQuestionCreation } from 'components/qna/common'

import Card from 'components/common/card'
import Tooltip from 'components/common/clearyTooltip'
import CancelButton from 'components/common/cancelButton'
import { Button } from 'components/common/buttons/button'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { MEDIUM_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'

const I18N = i18nPath('views.qna.events')

const QuestionForm = (props) => {
  const { event } = props
  const {
    pendingQuestionContent,
    meta: { isLoading },
  } = useSelector(qnaQuestionSlice.selectors.getQuestionFormData())
  const anonymousQuestionsDefault = event.allowAnonymousQuestions
    && event.settings.anonymousQuestions.anonymousByDefault
  const [isAnonymous, setIsAnonymous] = useState(anonymousQuestionsDefault || false)
  const dispatch = useDispatch()

  const hideQuestionFormHandler = () => dispatch(qnaQuestionSlice.actions.isQuestionFormVisible(false))
  const setQuestionContentHandler = content => dispatch(qnaQuestionSlice.actions.updatePendingQuestionContent(content))
  const createQuestionHandler = () => {
    trackQuestionCreation({ question_type: 'standard', event, anonymous: isAnonymous })

    const questionParams = {
      eventId: event.id,
      question: {
        content: pendingQuestionContent,
        anonymous: isAnonymous,
      },
    }

    dispatch(qnaQuestionSlice.asyncActions.createQuestion(questionParams, event))
  }

  const justifyContent = event.allowAnonymousQuestions ? 'justify-content-between' : 'justify-content-end'

  return (
    <Card className='QuestionForm my-4'>
      <p className='mb-3'>{I18N('ask_a_question')}</p>

      <Card cardBodyClassName='p-0' hasBoxShadow={false} hideBorder={false}>
        <TiptapEditor
          html={pendingQuestionContent}
          className='TipTapEditor'
          onChange={setQuestionContentHandler}
          toolbarItems={MEDIUM_TOOLBAR}
          configuration={{ focusOnInitialize: true }}
          editorContentClassName='p-3'
        />
      </Card>

      {event.settings.requireQuestionApproval.enabled && (
        <div className='d-flex justify-content-end'>
          <span className='text-small text-secondary'>{I18N('questions_will_be_reviewed')}</span>
        </div>
      )}

      {
        // flex column in mobile
        // flex in medium devices and up
      }
      <div className={`d-flex ${justifyContent} mt-3 alig-items-center flex-column flex-md-row`}>
        {event.allowAnonymousQuestions && (
          <div className='form-check form-group m-0'>
            <input
              id='qna-anonymous-question-checkbox'
              type='checkbox'
              checked={isAnonymous}
              onChange={e => setIsAnonymous(e.target.checked)}
              style={{ height: '1rem' }}
            />
            <label className='text-primary font-weight-normal' htmlFor='qna-anonymous-question-checkbox'>
              {I18N('ask_anonymously')}
            </label>
            <Tooltip placement='bottom' className='ml-2' key='ask_anonymously_tooltip'>
              <span className='text-small'>{I18N('ask_anonymously_tooltip')}</span>
            </Tooltip>
          </div>
        )}

        {
          // display: flex in mobile
          // display: block medium devices and up
        }
        <div className='form-group text-right m-0 d-flex d-md-block justify-content-between mt-3 mt-md-0'>
          <CancelButton
            className='position-relative'
            onClick={hideQuestionFormHandler}
          />

          <Button className='ml-3' onClick={createQuestionHandler} disabled={isLoading || !pendingQuestionContent}>
            {I18N('submit_question')}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default QuestionForm
