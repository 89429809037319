import React from 'react'
import classNames from 'classnames'
import { i18nPath, i18nLabelWithTimeZone } from 'utils/i18nHelpers'
import { Radio, RadioGroup } from 'react-radio-group'
import PriorStepSelector from 'components/admin/journeys/priorStepSelector'
import { defaultTriggerMilestone } from 'utils/journeys/templatesDefaultHelpers'
import TriggerSelect from 'components/admin/journeys/triggerSelect'
import currentTimezone from 'utils/currentTimezone'

const I18N = i18nPath('views.admin.journeys.step_templates')

export const STEP_COMPLETION = 'step_completion'

const StepTemplateTriggerSelector = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
  triggers = [],
  journeyBlueprint,
  showTriggerAfterCompletionSelection = true,
}) => {
  const journeyBlueprintType = journeyBlueprint?.type
  const triggerMilestone = workingCopy?.triggerMilestone
  const isTriggerAfterStepCompletion = triggerMilestone === STEP_COMPLETION
  const { triggerTimezone } = workingCopy
  const timezoneToShow = triggerTimezone || currentTimezone()

  const onIsTriggerAfterCompletionChange = (changingToStepCompletion) => {
    const params: any = {
      parentId: null,
    }

    triggers.forEach(({ name }) => {
      params[`${name}Milestone`] = !changingToStepCompletion ? defaultTriggerMilestone(journeyBlueprintType) : STEP_COMPLETION

      if (changingToStepCompletion) {
        // If it's the trigger milestone, then it should trigger the step immediately after completion of the prior step
        // But if it's another trigger, like due date, then it should trigger the step 1 day the step is triggered
        params[`${name}AfterNumDays`] = name === 'trigger' ? -1 : 1
      }
    })

    updateWorkingCopy(params)
  }

  const onTriggerMilestoneChange = (milestone) => {
    const params: any = {}

    triggers.forEach(({ name }) => {
      params[`${name}Milestone`] = milestone
    })

    updateWorkingCopy(params)
  }

  return (
    <>
      <div className='task-schedule-grid-header StepTemplateTriggerSelector'>
        {showTriggerAfterCompletionSelection && (
          <RadioGroup
            className={classNames('d-flex align-items-center justify-content-start TriggerAfterCompletionRadioGroup')}
            name='name'
            selectedValue={isTriggerAfterStepCompletion}
            onChange={onIsTriggerAfterCompletionChange}
          >
            <Radio className='mr-2' value={false} /> {I18N('at_specific_time')}
            <Radio className='mr-2 ml-3' value={true} />{I18N('after_step_completion')}
          </RadioGroup>
        )}
        {showTriggerAfterCompletionSelection && isTriggerAfterStepCompletion && (
          <PriorStepSelector
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
            isDisabled={isDisabled}
            journeyBlueprint={journeyBlueprint}
          />
        )}
      </div>
      <div className='task-schedule-grid-body'>
        {
          triggers.map(({ name, label }) => (
            <div className='mt-3' key={`trigger-${name}`}>
              {label && i18nLabelWithTimeZone(<label className='required-form-label mb-0'>{label}</label>, timezoneToShow)}
              <TriggerSelect
                className='mt-3'
                time={workingCopy[`${name}Time`]}
                updateTime={newTime => updateWorkingCopy({ [`${name}Time`]: newTime })}
                numDays={workingCopy[`${name}AfterNumDays`]}
                updateNumDays={days => updateWorkingCopy({ [`${name}AfterNumDays`]: days })}
                milestone={workingCopy[`${name}Milestone`]}
                setMilestone={onTriggerMilestoneChange}
                type={journeyBlueprintType}
                allowedMilestones={isTriggerAfterStepCompletion ? [STEP_COMPLETION] : []}
                excludedMilestones={isTriggerAfterStepCompletion ? [] : [STEP_COMPLETION]}
                triggerTimezone={workingCopy.triggerTimezone}
              />
            </div>
          ))
        }
      </div>
    </>
  )
}

export default StepTemplateTriggerSelector
