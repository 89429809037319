import { TypeEnum } from 'types/audience/targetingRules'
import { i18nPath } from 'utils/i18nHelpers'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

const I18N = i18nPath('views.admin.includes.audience_selector')
const IS = 'is'
const NOT = 'not'

// this determines if the Managers checkbox is checked in the target Audience
// (the other audience values are arrays and not booleans)
export const isManagersOnly = (targetingRules) => {
  const rules = targetingRules?.rules || []

  return rules.some((rule) => {
    if (rule.type !== 'attribute' || rule.key !== 'manager') {
      return false
    }

    return (rule.operator === 'is' && _.isEqual(rule.values, [true])) || (rule.operator === 'not' && _.isEqual(rule.values, [false]))
  })
}

export const targetingRulesPresent = (targetingRules = {}) => {
  const normalizedTargetingRules = normalizeTargetingRules(targetingRules)

  return !_.isEmpty(normalizedTargetingRules.rules) || !_.isEmpty(normalizedTargetingRules.users)
}

// for the audience to be considered valid, either:
// a) Entire Company is selected
// b) at least one rule or user is targeted
export const isAudienceValid = ({ targetingRules = {} }) => (
  targetingRules.targetEntireCompany || targetingRulesPresent(targetingRules)
)

const processRuleSentence = (rule, targetingOptions) => {
  if (rule.type === TypeEnum.Or || rule.type === TypeEnum.And) {
    const nestedSentences = rule.rules.map(r => processRuleSentence(r, targetingOptions))
    const conjunction = I18N(rule.type)
    return `(${nestedSentences.join(` ${conjunction} `)})`
  } else {
    const operator = rule.operator || 'is'
    const values = rule.values || []
    const fieldInfo = targetingOptions?.find(o => o.key === rule.key)

    if (!fieldInfo) return ''

    let valuesSentence = _.join(values, ` ${I18N('or')} `)

    if (fieldInfo?.key === 'manager') {
      const managerValues = values.map(val => (val ? I18N('manager') : I18N('non_manager')))
      valuesSentence = _.join(managerValues, ` ${I18N('or')} `)
    } else if (rule.type === TypeEnum.GroupType) {
      const groups = fieldInfo?.options?.filter(g => values.includes(g.id)) || []
      valuesSentence = _.join(groups.map(g => g.label), ` ${I18N('or')} `)
    } else if (rule.type === TypeEnum.Tenure) {
      valuesSentence = I18N('tenure_sentence', { count: values[0] })
    } else if (rule.type === TypeEnum.Reports) {
      valuesSentence = _.join(values.map(v => v.preferredFullName), ` ${I18N('or')} `)
    }

    return `${fieldInfo.label} ${I18N(operator)} ${valuesSentence}`
  }
}


export const audienceSummaryText = (
  targetingRules = {},
  targetingOptions,
  journeyBlueprintType = null
) => {
  if (targetingRules.targetEntireCompany) {
    if (journeyBlueprintType) {
      return I18N(`applies_to.all.${journeyBlueprintType}`)
    } else {
      return I18N('all_targeted')
    }
  }

  const sentences = []

  const rules = targetingRules?.rules || []
  rules.forEach((rule) => {
    const sentence = processRuleSentence(rule, targetingOptions)
    if (sentence) sentences.push(sentence)
  })

  // Remove ( and ) from the sentence if they are the first or last character
  return _.join(sentences, `, ${I18N('and')} `).replace(/^[(]|[)]$/g, '')
}


export const getAllOperators = audienceRules => audienceRules.map(rule => rule.operator).flat().filter(Boolean)

export const hasChangeOperator = targetingRules => !_.isEmpty(getAllOperators(targetingRules.rules).filter(op => ['changes_from', 'changes_to'].includes(op)))
