import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LineGraph from 'components/analytics/common/lineGraph'
import NumberPercentageToggle, { NUMBER } from 'components/analytics/common/numberPercentageToggle'
import { dateLabels } from 'components/analytics/helpers/dateLabelFormat'
import pageAnalyticsSlice from 'redux/slices/analytics/pages'
import TargetingRulesType from 'types/audience/targetingRules'
import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'


const I18N = i18nPath('views.platform_analytics.pages.usage')

interface UniquePageViewsProps {
  periodStart: string
  periodEnd?: string
  groupBy: string
  targetingRules?: TargetingRulesType
}


const UniquePageViews = ({
  periodStart,
  periodEnd,
  groupBy,
  targetingRules,
}: UniquePageViewsProps) => {
  const dispatch = useDispatch()

  const [selectedType, setSelectedType] = useState(NUMBER)

  const { data, totalUniquePageViews } = useSelector(pageAnalyticsSlice.selectors.getUniquePageViews())
  const { uniquePageViews: { isLoading } } = useSelector(pageAnalyticsSlice.selectors.getMetaData())

  const dataValues = data.map(({ value }) => {
    if (selectedType === NUMBER) return value

    return Math.round(value * 100)
  })

  const labels = dateLabels(data, groupBy)

  useEffect(() => {
    dispatch(pageAnalyticsSlice.asyncActions.admin.fetchUniquePageViews({
      periodStart,
      periodEnd,
      groupBy,
      targetingRules,
      type: selectedType,
    }))
  }, [periodStart, periodEnd, groupBy, selectedType, targetingRules])

  const descriptionKey = [
    'description',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const yAxisLabelKey = [
    'y_axis_label',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const tooltipLabelKey = [
    'tooltip_label',
    selectedType === 'percentage' ? 'percentage' : 'raw',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const onExport = (email: string) => {
    const exportXlsxParams = {
      periodStart,
      periodEnd,
      targetingRules,
      groupBy,
      eventNames: 'page:view',
      appModules: 'page',
      email,
      title: I18N('xlsx_title'),
    }

    return API.admin.analytics.engagement.exportXlsx(exportXlsxParams)
  }

  return (
    <div className='analytics-card grid-span-12'>
      <header className='d-flex flex-row justify-content-between pb-1'>
        <h3>{I18N('title')}</h3>
        <div className='d-flex flex-row align-items-center'>
          <NumberPercentageToggle selectedType={selectedType} setSelectedType={setSelectedType} />

          <ExportXlsxButton
            className='ml-1'
            onSubmit={onExport}
          />
        </div>
      </header>

      <div className=' d-flex flex-row align-items-start w-100'>
        {!isLoading && (
          <div className='d-flex flex-column align-items-start w-15'>
            <>
              <h3>{totalUniquePageViews}</h3>
              <span className='text-secondary font-weight-400'>{I18N(descriptionKey, { count: totalUniquePageViews })}</span>
            </>
          </div>
        )}

        <LineGraph
          classname='flex-grow-1'
          isLoading={isLoading}
          labels={labels}
          dataValues={dataValues}
          displayYAxisLabel={selectedType === NUMBER}
          yAxisLabel={I18N(yAxisLabelKey)}
          tooltipLabel={I18N(tooltipLabelKey)}
          type={selectedType}
        />
      </div>
    </div>
  )
}

export default UniquePageViews
