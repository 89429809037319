import React, { Component } from 'react'

import ArticleCard from 'components/articles/articleCard'
import AwardedBadgeCard from 'components/home/awardedBadgeCard'
import ShoutoutCardHomeFeed from 'components/shoutouts/shoutoutCardHomeFeed'
import CelebrationCard from 'components/celebrations/celebrationCard'
import JourneyCommunicationCard from 'components/home/journeyCommunicationCard'
import WelcomeCard from 'components/celebrations/welcomeCard'

const CardComponent = ({
  feedItem, currentUser, onTagClick, companyName,
}) => {
  switch (feedItem.type) {
  case 'article':
    return (
      <ArticleCard
        article={feedItem.article}
        tracking
        readOnly
        onTagClick={onTagClick}
        feedItem={feedItem}
      />
    )
  case 'awarded_badge':
    return <AwardedBadgeCard feedItem={feedItem} awardedBadge={feedItem.awardedBadge} currentUser={currentUser} />
  case 'shoutout':
    return <ShoutoutCardHomeFeed feedItem={feedItem} shoutout={feedItem.shoutout} user={currentUser} />
  case 'user_activated':
    return <WelcomeCard />
  case 'celebration':
    return (
      <CelebrationCard
        feedItem={feedItem}
        celebration={feedItem.celebration}
        currentUser={currentUser}
        companyName={companyName}
      />
    )
  case 'journey_communication':
    return (
      <JourneyCommunicationCard
        communication={feedItem.journeyCommunication}
      />
    )
  default:
    return null
  }
}

const FeedItem = ({ feedItem, ...props }) => (
  <div className='FeedItem mb-4' data-testid={`feed_item_${feedItem.id}`}>
    <CardComponent feedItem={feedItem} {...props} />
  </div>
)

export default FeedItem
