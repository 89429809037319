import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'


const I18N = i18nPath('views.settings.default_user_setting')

interface FeedAndExternalAlertsInputValue {
  feed: boolean
  notifications: boolean
  externalAlerts: boolean
}

interface FeedAndExternalAlertsInputProps {
  value: FeedAndExternalAlertsInputValue
  setValue: (callback: (value: FeedAndExternalAlertsInputValue) => FeedAndExternalAlertsInputValue) => void
  name: string
}

const FeedAndExternalAlertsInput = ({ value, setValue, name }: FeedAndExternalAlertsInputProps) => (
  <div className='mt-1 d-flex'>
    <div className='form-check mr-3'>
      <input
        id={`${name}-external-alerts-checkbox`}
        type='checkbox'
        checked={!!(value.externalAlerts && value.notifications)}
        onChange={(e) => {
          setValue(value => ({
            ...value,
            externalAlerts: e.target.checked,
            notifications: e.target.checked,
          }))
        }}
      />
      <label htmlFor={`${name}-external-alerts-checkbox`} className='font-weight-normal'>
        {I18N('external_alerts')}
      </label>
    </div>

    <div className='form-check mr-3'>
      <input
        id={`${name}-feed-checkbox`}
        type='checkbox'
        checked={!!value.feed}
        onChange={(e) => {
          setValue(value => ({
            ...value,
            feed: e.target.checked,
          }))
        }}
      />
      <label htmlFor={`${name}-feed-checkbox`} className='font-weight-normal'>
        {I18NCommon('feed')}
      </label>
    </div>
  </div>
)

export default FeedAndExternalAlertsInput
