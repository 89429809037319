import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import { ButtonSecondary } from 'components/common/buttons'
import SocialShareModal from 'components/celebrations/socialShareModal'
import ShareWidget from 'components/common/social_share/shareWidget'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import SocialSharePreviewCTA from 'components/celebrations/socialSharePreviewCTA'
import { trackAmplitudeEvent } from 'services/tracker'
import GreetingCardBanner from 'components/celebrations/greetingCardBanner'
import useIsCelebrationForCurrentUser from 'components/celebrations/hooks/useIsCelebrationForCurrentUser'
import useCanSignCelebration from 'components/celebrations/hooks/useCanSignCelebration'
import SayThanksButton from 'components/celebrations/sayThanksButton'
import useSocialShare from 'components/celebrations/hooks/useSocialShare'
import CelebrationModalContext, { SOCIAL_SHARE_MODAL } from 'components/celebrations/greeting_card_actions/celebrationModalContext'

const I18N = i18nPath('views.celebrations.greeting_card')

const GreetingCardHeader = ({
  celebration,
  canCustomize = false,
}) => {
  const [popoverTarget, setPopoverTarget] = useState(null)
  const { settings: { celebrations: { socialShare } } } = useCurrentCompany()

  const {
    currentOpenModal, closeModal,
  } = React.useContext(CelebrationModalContext)

  const isCurrentUser = useIsCelebrationForCurrentUser(celebration)
  const canSignCelebration = useCanSignCelebration(celebration)
  const {
    availableNetworks,
    handleShareClick,
    onSocialSharePreview,
    resolveUrl,
    title: socialShareTitle,
    suggestedText: socialShareSuggestedText,
  } = useSocialShare(celebration)

  const showThankYouButton = !_.isEmpty(celebration.missingSayThanksRecipientIds)

  return (
    <>
      <div>
        <GreetingCardBanner
          celebration={celebration}
          showBanner={canSignCelebration}
          showCustomizeButton={canCustomize}
        />
      </div>

      <div className='ShowAppreciation'>
        <div className='actions'>
          <Link to={`/celebrations/${celebration.id}/slide_show`} className='btn btn-secondary'>
            {I18N('slideshow_view')}
          </Link>

          {isCurrentUser && (showThankYouButton || socialShare)
            && (
              <>
                {socialShare && (
                  <ButtonSecondary
                    ref={el => setPopoverTarget(el)}
                    onClick={() => {
                      trackAmplitudeEvent('celebration_share_clicked', {
                        celebration_type: celebration.type,
                        signaturesCount: celebration.commentsCount,
                        milestone: 'say_thanks',
                      })
                    }}
                  >
                    <span>
                      {I18N('share')}
                    </span>
                  </ButtonSecondary>
                )}

                {showThankYouButton && <SayThanksButton celebration={celebration} />}

                {popoverTarget && (
                  <ShareWidget
                    title={socialShareTitle}
                    onClick={handleShareClick('share_button')}
                    availableNetworks={availableNetworks}
                    suggestedText={socialShareSuggestedText}
                    showSuggestedText={false}
                    url={resolveUrl}
                    target={popoverTarget}
                  >
                    <SocialSharePreviewCTA onPreview={onSocialSharePreview} />
                  </ShareWidget>
                )}
              </>
            )}
        </div>
      </div>

      {currentOpenModal === SOCIAL_SHARE_MODAL && (
        <SocialShareModal
          className='modal-enter'
          visible
          handleShareClick={handleShareClick('follow_up_modal')}
          resolveUrl={resolveUrl}
          availableNetworks={availableNetworks}
          onClose={closeModal}
          onPreview={onSocialSharePreview}
          suggestedText={socialShareSuggestedText}
        />
      )}
    </>
  )
}

export default GreetingCardHeader
