import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import userSkillSlice from 'redux/slices/userSkills'
import { ButtonSmallNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.admin.user_skills')

const UserSkillTaggingsEditor = ({
  userSkill,
  users,
}) => {
  const dispatch = useDispatch()

  const remove = (user) => {
    dispatch(userSkillSlice.asyncActions.admin.destroyTagging(userSkill, user))
  }

  const add = (user) => {
    dispatch(userSkillSlice.asyncActions.admin.createTagging(userSkill, user))
  }

  return (
    <div className='mt-4'>
      <div className='row'>
        <div className='col'>
          <h2>{I18N('users')}</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 mb-3'>
          <EmployeeSearch onChange={add} selectedEmployee={null} />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <table className='white-bg-table'>
            <thead>
              <tr>
                <th>{I18N('list.name')}</th>
                <th>{I18N('list.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {users?.map(u => (
                <tr>
                  <td>{u?.preferredFullName}</td>
                  <td>
                    <ButtonSmallNarrow
                      variant='danger'
                      onClick={() => remove(u)}
                    >
                      {I18N('list.remove')}
                    </ButtonSmallNarrow>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default UserSkillTaggingsEditor
