import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import React from 'react'

const LegalNoticesFooter = () => {
  const { legalNoticeHeader, legalNoticeMessage } = useCurrentCompany()

  return (
    <>
      <p className='text-small'>{legalNoticeHeader}</p>
      <p className='text-small'>{legalNoticeMessage}</p>
    </>
  )
}


export default LegalNoticesFooter
