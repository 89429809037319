import React, { useEffect, useState } from 'react'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import GoogleGroupsAsyncSelect from 'components/common/googleGroupsAsyncSelect'
import { Button } from 'components/common/buttons'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ChatChannelsAsyncSelect from 'components/common/chatChannelsAsyncSelect'
import ChatUserGroupsAsyncSelect from 'components/common/chatUserGroupsAsyncSelect'

const I18N = i18nPath('views.admin.managed_groups.form')

const CHAT_SLACK_CHANNEL_TYPE = 'Chat::SlackChannel'
const CHAT_SLACK_USER_GROUP_TYPE = 'Chat::SlackUserGroup'
const GOOGLE_GROUP_TYPE = 'GoogleGroup'

const POSSIBLE_CHANNEL_TYPE_OPTIONS = [
  CHAT_SLACK_CHANNEL_TYPE,
  CHAT_SLACK_USER_GROUP_TYPE,
  GOOGLE_GROUP_TYPE,
]

const ManagedGroupChannelSelector = ({
  managedGroup, setManagedGroup, currentManagedGroupChannel = {}, currentManagedGroupChannelIndex = -1,
}) => {
  const { settings } = useCurrentCompany()
  const channelTypesConfig = settings.managedGroups?.channelTypes
  const enabledChannelTypes = POSSIBLE_CHANNEL_TYPE_OPTIONS.filter((rawType) => {
    let type = rawType

    // For new workspaces types, lets match against the feature flag without the 'Chat::' prefix
    // Eg: Chat::SlackChannel will check if SlackChannel is enabled
    if (rawType.startsWith('Chat::')) {
      type = rawType.replace('Chat::', '')
    }

    const channelTypeConfig = channelTypesConfig[`${_.camelCase(type)}s`]
    return channelTypeConfig?.enabled
  })

  const [selectedChannelType, setSelectedChannelType] = useState(currentManagedGroupChannel.channelType)
  const [selectedChannel, setSelectedChannel] = useState(currentManagedGroupChannel.channel)

  const isNewChannel = currentManagedGroupChannelIndex === -1

  const options = enabledChannelTypes.map(channelType => ({
    value: channelType,
    label: I18N(_.snakeCase(channelType.replace('::', ''))),
  }))

  const updateCurrentManagedGroupChannel = (newChannelType, newChannel) => {
    const newManagedGroupChannels = [...managedGroup.managedGroupChannels]

    newManagedGroupChannels[currentManagedGroupChannelIndex] = {
      id: currentManagedGroupChannel.id,
      channelType: newChannelType,
      channel: newChannel,
    }

    setManagedGroup({
      ...managedGroup,
      managedGroupChannels: newManagedGroupChannels,
    })
  }

  const addManagedGroup = (newChannel) => {
    const params = {
      channelType: selectedChannelType,
      channel: newChannel,
    }

    setManagedGroup({
      ...managedGroup,
      managedGroupChannels: [
        ...managedGroup.managedGroupChannels || [],
        ...[params],
      ],
    })

    setSelectedChannelType(null)
    setSelectedChannel(null)
  }

  const onChannelTypeChange = (selectedOption) => {
    if (!isNewChannel) updateCurrentManagedGroupChannel(selectedOption.value, null)

    setSelectedChannel(null)
    setSelectedChannelType(selectedOption.value)
  }

  const onChannelChange = (data) => {
    setSelectedChannel(data)

    if (isNewChannel) {
      addManagedGroup(data)
    } else {
      updateCurrentManagedGroupChannel(selectedChannelType, data)
    }
  }

  const onRemove = () => {
    const newManagedGroupChannels = [...managedGroup.managedGroupChannels]

    newManagedGroupChannels.splice(currentManagedGroupChannelIndex, 1)

    setManagedGroup({
      ...managedGroup,
      managedGroupChannels: newManagedGroupChannels,
    })
  }

  useEffect(() => {
    setSelectedChannelType(currentManagedGroupChannel.channelType)
    setSelectedChannel(currentManagedGroupChannel.channel)
  }, [currentManagedGroupChannel.channel])

  return (
    <>
      <ReactSelect
        value={options.filter(option => selectedChannelType === option.value)}
        options={options}
        onChange={onChannelTypeChange}
        className='channel-type-select'
      />
      {
        selectedChannelType === CHAT_SLACK_CHANNEL_TYPE && (
          <ChatChannelsAsyncSelect
            value={selectedChannel}
            onChange={onChannelChange}
            types={['Chat::SlackChannel']}
          />
        )
      }
      {
        selectedChannelType === CHAT_SLACK_USER_GROUP_TYPE && (
          <ChatUserGroupsAsyncSelect
            value={selectedChannel}
            onChange={onChannelChange}
            types={['Chat::SlackUserGroup']}
          />
        )
      }
      {
        selectedChannelType === GOOGLE_GROUP_TYPE && (
          <GoogleGroupsAsyncSelect
            value={selectedChannel?.email}
            onChange={onChannelChange}
          />
        )
      }
      {/* This span is to keep the grid display correct when there's no channel type selected */}
      {isNewChannel && !selectedChannelType && <span />}
      {!isNewChannel && (
        <Button onClick={onRemove} variant='secondary-danger'>
          {I18NCommon('remove')}
        </Button>
      )}
    </>
  )
}

export default ManagedGroupChannelSelector
