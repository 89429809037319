import React from 'react'
import PillTabSelector from 'components/common/pillTabSelector'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'

const I18N = i18nPath('views.journeys.my_tasks')

const OPEN = 'pending'
const COMPLETED = 'completed'

const StateTabs = ({
  currentStateTab,
  setCurrentStateTab,
}) => (
  <PillTabSelector
    defaultSelectedIndex={[OPEN, COMPLETED].indexOf(currentStateTab)}
    pillClasses='text-normal'
    tabs={[
      {
        text: I18N('open'),
        onClick: () => {
          setCurrentStateTab(OPEN)
        },
      },
      {
        text: I18N('completed'),
        onClick: () => {
          setCurrentStateTab(COMPLETED)
        },
      },
    ]}
  />
)

export default StateTabs
