import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useQueryParams from 'components/common/hooks/useQueryParams'

import searchSlice, { PAGE_SIZES } from 'redux/slices/search'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { useHistory, useLocation } from 'react-router-dom'
import CdnSvg from 'components/common/cdnSvg'
import SearchResultsTemplate from './searchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

const notionLogoPath = '/images/notionIcon.svg'

const I18N = i18nPath('views.search.search_results.search_results')

const PageCreator = ({ result: { userAvatar, userName } }) => {
  if (!userAvatar || !userName) {
    return null
  }

  return (
    <div className='d-flex flex-row align-items-center'>
      <div>
        <img src={userAvatar}
          className='bordered-thumbnail mr-1'
          style={{ height: '22px', width: '22px' }}
        />
      </div>
      <div className='text-primary text-small font-weight-500'>
        {userName}
      </div>
    </div>
  )
}


const NotionResults = ({ showLabel = true }) => {
  const location = useLocation()
  const history = useHistory()
  const queryParams = useQueryParams()
  const searchResultsFilter = queryParams.filter || 'all'
  const page = parseInt(queryParams.page) || 1
  const selected = searchResultsFilter === 'notion'
  const pageSize = selected ? PAGE_SIZES.pageFiltered : PAGE_SIZES.pageAll
  const searchLocation = selected ? 'pageFiltered' : 'pageAll'

  const { totalResultsByType, isLoadingNotion } = useSelector(searchSlice.selectors.getMetaData(searchLocation))
  const notionSearchResults = useSelector(searchSlice.selectors.getNotionSearchResults(searchLocation))
  const [slicedNotionSearchResults, setSlicedNotionSearchResults] = useState([])

  const setPage = (page) => {
    const newParams = new URLSearchParams(location.search)
    newParams.set('page', page)
    history.push(`${location.pathname}?${newParams.toString()}`)
  }

  useEffect(() => {
    if (selected) {
      setSlicedNotionSearchResults(notionSearchResults.slice((page - 1) * pageSize, page * pageSize))
    } else {
      setPage(1)
      setSlicedNotionSearchResults(notionSearchResults.slice(0, pageSize))
    }
  }, [page, selected, notionSearchResults])

  return (
    <SearchResultsTemplate
      showLabel={showLabel}
      label='notion'
      isLoading={isLoadingNotion}
      count={totalResultsByType.notion}
      page={page}
      setPage={setPage}
      selected={selected}
      results={slicedNotionSearchResults.map(result => (
        <SearchResultTemplate
          key={result.value}
          displayPath={result.url}
          image={<CdnSvg src={notionLogoPath} style={{ height: '29px', width: '29px' }} />}
          pretitle={result.space}
          title={result.title}
          subtitle={<PageCreator result={result} />}
          description={I18N('edited', { date: i18nMoment(result.lastEditedTime).fromNow() })}
          result={result}
          openNewTab
        />
      ))}
    />
  )
}

export default NotionResults
