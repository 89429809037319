import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import SearchResultOption from 'components/search/option_renderers/searchResultOption'

const PageFAQOption = ({ option }) => {
  const { pageFaq, highlights } = option
  const question = highlights.question || pageFaq.question
  const answer = highlights.answer || pageFaq.answer

  return (
    <SearchResultOption
      image={<CdnSvg src='/images/questionIcon.svg' className='SearchResultOptionImg mr-1' />}
      title={question}
      secondaryTexts={[answer]}
    />
  )
}

export default PageFAQOption
