// Currently these constants are used in two places and have a high degree of overlap.
// These used to be replicated in two different chartVariables files in two different places.
// First step was bringing these together and make it more discoverable.

export const surveyQuestionChartOptions = {
  maintainAspectRatio: false,
  elements: { bar: { borderWidth: 0 } },
  responsive: true,
  plugins: {
    legend: { display: false },
    tooltip: {
      enabled: true,
    },
  },
}

export default surveyQuestionChartOptions
