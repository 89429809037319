import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { ButtonLink, ButtonSecondarySmall } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import timezoneSlice from 'redux/slices/timezones'

const I18N = i18nPath('views.admin.timezones')

const TimezoneListPage = () => {
  const dispatch = useDispatch()

  const timezones = useSelector(timezoneSlice.selectors.getTimezones())
  const { isLoading } = useSelector(timezoneSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(timezoneSlice.asyncActions.fetchTimezones())
  }, [])

  const deleteTimezone = (timezoneId) => {
    if (confirm(I18N('delete_confirm'))) {
      dispatch(timezoneSlice.asyncActions.admin.deleteTimezone({ id: timezoneId }))
    }
  }

  if (isLoading) {
    return null
  }

  const sortedTimezones = timezones.sort((first, second) => {
    const firstOffset = moment().tz(first.timezone).utcOffset()
    const secondOffset = moment().tz(second.timezone).utcOffset()

    return secondOffset - firstOffset
  })

  return (
    <div className='TimezoneListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('timezones')}</h3>
        <div className='AdminButtonContainer'>
          <ButtonLink to='/admin/timezones/new'>
            {I18N('create_timezone')}
          </ButtonLink>
        </div>
      </header>

      <main className='AdminContent'>
        <div className='row mb-4'>
          <div className='col'>
            <div className='overflow-x-auto'>
              <table className='white-bg-table'>
                <thead>
                  <tr>
                    <th>{I18N('list.location')}</th>
                    <th>{I18N('list.timezone')}</th>
                    <th>{I18N('list.delete')}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedTimezones.map(tz => (
                    <tr key={`timezone-${tz.id}`}>
                      <td><Link to={`/admin/timezones/${tz.id}`}>{tz.location}</Link></td>
                      <td>{tz.timezone}</td>
                      <td><ButtonSecondarySmall onClick={() => deleteTimezone(tz.id)}>{I18N('delete_timezone')}</ButtonSecondarySmall></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default TimezoneListPage
