import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MyTasksHeaderTile from 'components/journeys/tasks/myTasksHeaderTile'
import MyTasksDirectReportsHeaderTile from 'components/journeys/tasks/myTasksDirectReportsHeaderTile'
import MyTasksTable from 'components/journeys/tasks/myTasksTable'
import PersonTabs from 'components/journeys/tasks/personTabs'
import StateTabs from 'components/journeys/tasks/stateTabs'
import DirectReportsFilter from 'components/journeys/tasks/directReportsFilter'
import taskableStepSlice from 'redux/slices/journeys/taskableSteps'

import { i18nPath } from 'utils/i18nHelpers'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import AsyncSearchInput from 'components/common/asyncSearchInput'

const OPEN = 'pending'
const MY_TASKS = 'my_tasks'
const DIRECT_REPORT = 'direct_report_tasks'

const I18N = i18nPath('views.journeys.my_tasks')

const MyTasksPage = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const [currentStateTab, setCurrentStateTab] = useState(OPEN)
  const [currentOwnerTab, setCurrentOwnerTab] = useState(MY_TASKS)
  const [selectedDirectReport, setSelectedDirectReport] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const showDirectReportTab = currentUser.directReports?.length > 0
  const isDirectReportsTabSelected = currentOwnerTab === DIRECT_REPORT
  const isCompletedTabSelected = currentStateTab !== OPEN
  const showCompleteCheckbox = currentOwnerTab === MY_TASKS && currentStateTab === OPEN
  const emptyTableMessage = I18N(`empty_table.${currentOwnerTab}.${currentStateTab}`)

  const {
    openTaskableStepsCount,
  } = useSelector(taskableStepSlice.selectors.getMetaData())

  const fetchSelectedTaskables = (newParams = {}) => {
    const params = {
      status: currentStateTab,
      tasksOwner: currentOwnerTab,
      sortBy: 'due_at',
      sortDir: 'asc',
      ...newParams,
      q: searchQuery,
    }

    if (isDirectReportsTabSelected && selectedDirectReport?.id) {
      params.directReportId = selectedDirectReport?.id
    }

    dispatch(taskableStepSlice.asyncActions.fetchAll(params))
  }

  useEffect(() => {
    fetchSelectedTaskables()
  }, [currentStateTab, currentOwnerTab, selectedDirectReport, searchQuery])

  const changeOwnerTab = (tab) => {
    setCurrentStateTab(OPEN)
    setCurrentOwnerTab(tab)
    setSelectedDirectReport(null)
  }

  return (
    <main className='MyTasksPage'>
      <MyTasksHeaderTile
        hasDirectReports={showDirectReportTab}
        name={currentUser.preferredName}
        openTasksCount={openTaskableStepsCount || 0}
      />
      {showDirectReportTab && (
        <MyTasksDirectReportsHeaderTile
          onViewDetailsClick={changeOwnerTab}
        />
      )}
      <PersonTabs
        currentOwnerTab={currentOwnerTab}
        setCurrentOwnerTab={changeOwnerTab}
        showDirectReportTab={showDirectReportTab}
      />
      <section className='state-tabs mt-4'>
        <StateTabs
          currentStateTab={currentStateTab}
          setCurrentStateTab={setCurrentStateTab}
        />
      </section>
      <section className='search-and-filter mt-4'>
        <AsyncSearchInput
          placeholder={I18N('search_by_task_name')}
          onKeyUp={setSearchQuery}
          className='mb-0'
        />
        {isDirectReportsTabSelected && (
          <DirectReportsFilter
            selectedDirectReport={selectedDirectReport}
            onChange={setSelectedDirectReport}
          />
        )}
      </section>
      <MyTasksTable
        showDirectReportColumns={isDirectReportsTabSelected}
        isCompletedTabSelected={isCompletedTabSelected}
        showCompleteCheckbox={showCompleteCheckbox}
        fetchTaskableSteps={fetchSelectedTaskables}
        emptyTableMessage={emptyTableMessage}
        searchQuery={searchQuery}
      />
    </main>
  )
}

export default MyTasksPage
