import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'components/common/tables/smartTable'
import PaginationContainer from 'components/common/tables/paginationContainer'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import getNamesAndEmailFromFailedRecord from 'utils/admin/userImports/getNamesAndEmailFromFailedRecord'
import userSlice from 'redux/slices/users'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/common/buttons'
import ToggleUserActiveFlagButton from 'components/admin/userImports/toggleUserActiveFlagButton'
import entitySlice from 'redux/slices/entities'

import { present } from 'components/common/utils'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import ResultRecordModal from './resultRecordModal'

const I18N = i18nPath('views.admin.user_import_detail')

const PAGE_SIZE = 30

const RecordsTable = ({
  data, userImportResultType, userImport, searchValue,
}) => {
  const dispatch = useDispatch()
  const showingFailedRecords = ['failed_imports', 'failed_photo_imports'].includes(userImportResultType)
  const [pages, setPages] = useState(Math.ceil(data.length / PAGE_SIZE))
  const [page, setPage] = useState(1)
  const [filteredData, setFilteredData] = useState(data)
  const [pagedData, setPagedData] = useState(data.slice(0, PAGE_SIZE))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rowData, setRowData] = useState({})
  const userIds = userImportResultType === 'missing_users' ? pagedData.map(record => record.userId).filter(present) : []
  const users = useSelector(userSlice.selectors.getSimpleUsersByIds(userIds))
  const allUserIds = data.map(record => record.userId).filter(present)

  const [deactivateAll, { isLoading: isDeactivating }] = useApi(
    () => API.admin.users.deactivateAll(allUserIds), {
      toastSuccessMessage: I18N('deactivate_all_success'),
      onSuccess: () => {
        allUserIds.forEach((userId) => {
          dispatch(entitySlice.actions.update({
            data: {
              data: {
                id: userId.toString(),
                type: 'simpleUser',
                attributes: {
                  active: false,
                },
              },
            },
          }))
        })
      },
    })

  const toggleModal = (rowData) => {
    setIsModalOpen(!isModalOpen)
    setRowData(rowData)
  }

  useEffect(() => {
    setPage(1)
    setPages(Math.ceil(filteredData.length / PAGE_SIZE))
    setPagedData(filteredData.slice(0, PAGE_SIZE))
  }, [filteredData])

  useEffect(() => {
    const start = (page - 1) * PAGE_SIZE
    const end = page * PAGE_SIZE
    setPagedData(filteredData.slice(start, end))
  }, [page])

  useEffect(() => {
    if (userImportResultType === 'missing_users' && !_.isEmpty(userIds)) {
      dispatch(userSlice.asyncActions.simpleFetchAll(userIds, () => {}, { includeHiddenUsers: true }))
    }
  }, [JSON.stringify(userIds)])

  useEffect(() => {
    if (!searchValue) {
      setFilteredData(data)
      return
    }

    if (showingFailedRecords) {
      setFilteredData(data.filter((rowData) => {
        const { firstName, lastName, email } = getNamesAndEmailFromFailedRecord(rowData)
        const fields = [firstName, lastName, email].filter(field => typeof field === 'string')

        return fields.some(field => field.toLowerCase().startsWith(searchValue))
      }))
    } else {
      setFilteredData(data.filter(rowData => rowData.email?.toLowerCase()?.startsWith(searchValue)))
    }
  }, [searchValue, userImportResultType])

  const defaultColumns = [
    {
      header: I18N('id_column_label'),
      col: 'col-id',
      style: { width: 100 },
      accessor: rowData => <Link to={`/admin/users/${rowData.userId}`}>{rowData.userId}</Link>,
    },
    {
      header: I18N('employee_column_label'),
      col: 'col-email',
      style: { width: 300 },
      accessor: rowData => rowData.email,
    },
  ]

  const errorColumns = [
    {
      header: I18N('employee_column_label'),
      col: 'col-employee',
      style: { width: 400 },
      accessor: (rowData) => {
        const { firstName, lastName, email } = getNamesAndEmailFromFailedRecord(rowData)

        if (!firstName && !lastName && !email) {
          return I18N('unknown_record')
        }

        const fullName = `${firstName} ${lastName} `

        return fullName + (email ? `(${email})` : '')
      },
    },
    {
      header: I18N('errors_column_label'),
      col: 'col-errors',
      style: { width: 300 },
      accessor: rowData => rowData.errors?.message,
    },
  ]

  const titleColumn = {
    header: I18N('title_column_label'),
    col: 'col-title',
    style: { width: 300 },
    accessor: rowData => rowData.changes?.title,
  }

  const reasonColumn = {
    header: I18N('reason_column_label'),
    col: 'col-reason',
    style: { width: 300 },
    accessor: rowData => I18N(`ignored_user_reasons.${rowData.reason}`),
  }

  const actionColumn = {
    header: I18N('actions_column_label'),
    col: 'col-actions',
    style: { width: 100 },
    accessor: rowData => <a onClick={() => toggleModal(rowData)}>{I18N('view_record')}</a>,
  }

  const deactivateColumn = {
    header: I18N('actions_column_label'),
    col: 'col-actions',
    style: { width: 100 },
    accessor: rowData => (
      <ToggleUserActiveFlagButton
        active={users.find(userData => userData.id === rowData.userId)?.active}
        userId={rowData.userId}
      />
    ),
  }

  let columns = showingFailedRecords ? errorColumns : defaultColumns

  if (userImportResultType === 'new_records') {
    columns = [...columns, titleColumn]
  }

  if (userImportResultType === 'ignored_records') {
    columns = [...columns, reasonColumn]
  }

  if (!['unchanged_records', 'missing_users'].includes(userImportResultType)) {
    columns = [...columns, actionColumn]
  }

  if (userImportResultType === 'missing_users') {
    columns = [...columns, deactivateColumn]
  }

  const onDeactivateAll = () => {
    if (confirm(I18NCommon('confirm_cant_undo'))) {
      deactivateAll()
    }
  }

  if (_.isEmpty(data)) return null

  return (
    <>
      {userImportResultType === 'missing_users' && (
        <div className='d-flex justify-content-end mb-3'>
          <Button onClick={onDeactivateAll} showLoadingSpinner={isDeactivating} disabled={isDeactivating}>
            {I18N('deactivate_all')}
          </Button>
        </div>
      )}
      <Table
        className='white-bg-table'
        columns={columns}
        data={pagedData}
      />
      <PaginationContainer
        page={page}
        numPages={pages}
        perPage={PAGE_SIZE}
        totalCount={data.length}
        handleClick={setPage}
      />
      <ResultRecordModal
        visible={isModalOpen}
        toggle={toggleModal}
        rowData={rowData}
        userImport={userImport}
      />
    </>
  )
}

export default RecordsTable
