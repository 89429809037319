import React from 'react'
import classNames from 'classnames'
import { Line } from 'react-chartjs-2'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { transparent } from 'utils/color'
import { NUMBER, PERCENTAGE } from 'components/analytics/common/numberPercentageToggle'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

interface LineGraphProps {
  labels: string[]
  dataValues: number[] | number[][]
  dataValueLabels?: string[]
  displayYAxisLabel?: boolean
  yAxisLabel?: string
  tooltipLabel?: string
  classname?: string
  type?: string
  isLoading?: boolean
  color?: string | string[]
  chartOptions?: any
  dataValuesOptions?: any
}

const LineGraph = ({
  labels,
  dataValues,
  dataValueLabels = [],
  displayYAxisLabel = false,
  yAxisLabel,
  tooltipLabel,
  classname,
  type = NUMBER,
  isLoading = false,
  color,
  chartOptions = {},
  dataValuesOptions = {},
}: LineGraphProps) => {
  const currentCompany = useCurrentCompany()

  const { highlightColor, textColorSecondary } = currentCompany.customStyles.variables.colors
  const borderColor = color ? Array(color).flat() : [highlightColor]
  const backgroundColor = transparent(borderColor[0], 0.05)

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          color: textColorSecondary,
        },
      },
      y: {
        beginAtZero: true,
        grid: { display: false },
        min: type === PERCENTAGE ? 0 : undefined,
        max: type === PERCENTAGE ? Math.max(100, ...dataValues.flat()) : undefined,
        ticks: {
          color: textColorSecondary,
          callback: value => (type === PERCENTAGE ? `${value}%` : value),
        },
        title: {
          display: displayYAxisLabel,
          text: yAxisLabel,
          color: textColorSecondary,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        backgroundColor: borderColor[0],
        displayColors: false, // Disable the color box next to the tooltip text
        titleFont: {
          size: 13,
          weight: 'light',
        },
        bodyFont: {
          size: 15,
        },
        titleAlign: 'center' as const, // Center the tooltip title
        callbacks: {
          title(context) { // Customizes the tooltip title
            return context[0].label
          },
          label(context) { // Customizes the tooltip body text
            const value = type === PERCENTAGE ? `${context.parsed.y}%` : context.parsed.y
            return value + ' ' + tooltipLabel
          },
        },
        padding: {
          top: 7,
          bottom: 10,
          left: 16,
          right: 16,
        },
      },
    },
  }

  const data = {
    labels,
    datasets: [
      {
        data: dataValues,
        fill: true,
        borderColor: borderColor[0], // Sets the line color
        backgroundColor, // Sets the color for the filled area
        tension: 0.4,
        ...dataValuesOptions,
      },
    ],
  }

  if (Array.isArray(dataValues[0])) {
    data.datasets = dataValues.map((values, index) => ({
      data: values,
      fill: true,
      label: dataValueLabels[index],
      borderColor: borderColor[index],
      backgroundColor: transparent(borderColor[index], 0.05),
      tension: 0.4,
      ...dataValuesOptions,
    }))
  }

  return (
    <div className={classNames('analytics-graph', classname)}>
      {isLoading ? (
        <CirclesLoadingIndicator className='w-100 d-flex align-items-center h-100' />
      ) : (
        <Line options={_.merge(options, chartOptions)} data={data} />
      )}
    </div>
  )
}

export default LineGraph
