import React, { useState } from 'react'
import NumberPercentageToggle, { NUMBER } from 'components/analytics/common/numberPercentageToggle'
import { i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import HorizontalStackedBarGraph from 'components/analytics/common/horizontalStackedBarGraph'
import LegendItem from 'components/analytics/common/legendItem'
import useFetch from 'components/common/hooks/useFetch'

const I18N = i18nPath('views.platform_analytics.ai_answers.overview')

interface OverviewProps {
  periodStart: string
  periodEnd?: string
}

const colors = {
  answeredNoFeedback: '#CCE5F8',
  answeredCorrect: '#54C981',
  answeredIncorrect: '#FF6162',
  notAnswered: '#FFA25E',
}

const Overview = ({
  periodStart,
  periodEnd,
}: OverviewProps) => {
  const [selectedType, setSelectedType] = useState(NUMBER)
  const { data, isLoaded } = useFetch(
    () => API.admin.analytics.aiAnswers.fetchOverview({
      period: { periodStart, periodEnd },
    }),
    [periodStart, periodEnd]
  )

  const labels = ['Overview']

  const datasets = [
    {
      label: I18N('answered_no_feedback.label'),
      data: [data?.answeredNoFeedback],
      backgroundColor: colors.answeredNoFeedback,
      hoverBackgroundColor: colors.answeredNoFeedback,
      barThickness: 34,
    },
    {
      label: I18N('answered_correct.label'),
      data: [data?.answeredCorrect],
      backgroundColor: colors.answeredCorrect,
      hoverBackgroundColor: colors.answeredCorrect,
      barThickness: 34,
    },
    {
      label: I18N('answered_incorrect.label'),
      data: [data?.answeredIncorrect],
      backgroundColor: colors.answeredIncorrect,
      hoverBackgroundColor: colors.answeredIncorrect,
      barThickness: 34,
    },
    {
      label: I18N('not_answered.label'),
      data: [data?.notAnswered],
      backgroundColor: colors.notAnswered,
      hoverBackgroundColor: colors.notAnswered,
      barThickness: 34,
    },
  ]

  const getDataValue = (value) => {
    if (selectedType === NUMBER) {
      return value
    }

    const total = data.total

    return `${Math.round((value / total) * 100)}%`
  }

  const generateTooltipLabel = (value, category) => (
    I18N(`graph_tooltip_text.${selectedType}`, {
      total: data.total,
      value: getDataValue(value),
      category: category.toLowerCase(),
    })
  )

  return (
    <div className='analytics-card grid-span-12'>
      <header className='d-flex flex-row justify-content-between pb-1'>
        <h3>{I18N('title')}</h3>
        <div className='d-flex flex-row align-items-center'>
          <NumberPercentageToggle selectedType={selectedType} setSelectedType={setSelectedType} />

          <div className='d-flex flex-row ml-2 text-secondary text-normal bordered p-1'>
            {I18N('total')}: {data?.total}
          </div>
        </div>
      </header>

      {!isLoaded ? (
        <CirclesLoadingIndicator />
      ) : (
        <>
          <HorizontalStackedBarGraph
            labels={labels}
            datasets={datasets}
            classname='w-100'
            generateTooltipLabel={generateTooltipLabel}
          />

          <div className='d-flex flex-row w-100'>
            <LegendItem
              value={getDataValue(data?.answeredNoFeedback)}
              category={I18N('answered_no_feedback.label')}
              backgroundColor={colors.answeredNoFeedback}
              showDescription={false}
              showTooltip
              tooltipText={I18N('answered_no_feedback.tooltip')}
              classname='w-20'
            />

            <LegendItem
              value={getDataValue(data?.answeredCorrect)}
              category={I18N('answered_correct.label')}
              backgroundColor={colors.answeredCorrect}
              showDescription={false}
              showTooltip
              tooltipText={I18N('answered_correct.tooltip')}
              classname='w-20'
            />

            <LegendItem
              value={getDataValue(data?.answeredIncorrect)}
              category={I18N('answered_incorrect.label')}
              backgroundColor={colors.answeredIncorrect}
              showDescription={false}
              showTooltip
              tooltipText={I18N('answered_incorrect.tooltip')}
              classname='w-20'
            />

            <LegendItem
              value={getDataValue(data?.notAnswered)}
              category={I18N('not_answered.label')}
              backgroundColor={colors.notAnswered}
              showDescription={false}
              showTooltip
              tooltipText={I18N('not_answered.tooltip')}
              classname='w-20'
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Overview
