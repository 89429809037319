import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import userAccessManagementSlice from 'redux/slices/userAccessManagement'
import userSlice from 'redux/slices/users'
import SmartTable from 'components/common/tables/smartTable'
import { ButtonSmall, ButtonSecondarySmall } from 'components/common/buttons'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.soft_launch_users')

const DATE_FORMAT = 'YYYY-MM-DD  h:mm A'

const PER_PAGE = 50

const SoftLaunchAccessPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userAccessManagementSlice.asyncActions.fetchSoftLaunchUsers({ page: 1, perPage: PER_PAGE }))
    dispatch(userAccessManagementSlice.asyncActions.fetchSoftLaunchUsersCount())
  }, [])

  const softLaunchUsersCount = useSelector(userAccessManagementSlice.selectors.getSoftLaunchUsersCount())
  const softLaunchUsers = useSelector(userAccessManagementSlice.selectors.getSoftLaunchUsers())
  const { isLoading, queryParams } = useSelector(userAccessManagementSlice.selectors.getSoftLaunchAccessPageData())
  const {
    page, totalPages, perPage, total,
  } = queryParams

  const grantSoftLaunchAccess = (user) => {
    dispatch(userSlice.asyncActions.admin.grantSoftLaunchAccess(user)).then(() => {
      dispatch(userAccessManagementSlice.asyncActions.fetchSoftLaunchUsersCount())
    })
  }

  const revokeSoftLaunchAccess = (user) => {
    dispatch(userSlice.asyncActions.admin.revokeSoftLaunchAccess(user)).then(() => {
      dispatch(userAccessManagementSlice.asyncActions.fetchSoftLaunchUsersCount())
    })
  }

  const renderRevokeOrGrantButton = (user) => {
    if (!user.softLaunchAccessGrantedAt) {
      return <ButtonSecondarySmall onClick={() => grantSoftLaunchAccess(user)}>{I18N('grant')}</ButtonSecondarySmall>
    } else {
      return <ButtonSmall className='btn-secondary-danger-sm' variant='' onClick={() => revokeSoftLaunchAccess(user)}>{I18N('revoke')}</ButtonSmall>
    }
  }

  const handlePaginationClick = (sortParams) => {
    dispatch(userAccessManagementSlice.asyncActions.fetchSoftLaunchUsers({ perPage: PER_PAGE, ...sortParams }))
  }

  const handleUserSearch = (value) => {
    dispatch(userAccessManagementSlice.asyncActions.fetchSoftLaunchUsers({ page: 1, perPage: PER_PAGE, q: value }))
  }

  const handleSortChange = (sortParams) => {
    dispatch(userAccessManagementSlice.asyncActions.fetchSoftLaunchUsers({ page: 1, perPage: PER_PAGE, ...sortParams }))
  }

  const columns = [
    {
      header: I18N('name'),
      col: 'col-name',
      style: { width: '20%' },
      accessor: user => <Link to={`/admin/users/${user.id}`}><span>{user.preferredFullName}</span></Link>,
    },
    {
      header: I18N('email'),
      col: 'col-email',
      style: { width: '20%' },
      accessor: user => user.email,
    },
    {
      header: I18N('access_request_date'),
      col: 'col-soft_launch_access_requested_at',
      id: 'soft_launch_access_requested_at',
      style: { width: '20%' },
      accessor: user => user.softLaunchAccessRequestedAt && (
        <span>{i18nMoment(user.softLaunchAccessRequestedAt).format(DATE_FORMAT)}</span>
      ),
    },
    {
      header: I18N('access_grant_date'),
      col: 'col-soft_launch_access_granted_at',
      id: 'soft_launch_access_granted_at',
      style: { width: '20%' },
      accessor: user => user.softLaunchAccessGrantedAt && (
        <span>{i18nMoment(user.softLaunchAccessGrantedAt).format(DATE_FORMAT)}</span>
      ),
    },
    {
      header: I18N('soft_launch'),
      col: 'col-action',
      style: { width: '20%' },
      accessor: user => renderRevokeOrGrantButton(user),
    },
  ]

  return (
    <div className='SoftLaunchAccessPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <div className='d-flex justify-content-between mb-3'>
          <div className='w-lg-25'>
            <AsyncSearchInput
              placeholder={I18N('search_placeholder')}
              icon={<CdnSvg src='/images/searchIcon.svg' />}
              onKeyUp={handleUserSearch}
              className='mb-0 w-100'
              inputGroupClassName='w-100'
              debounceWait={300}
            />
          </div>
          <div className='d-flex flex-column'>
            <p className='m-0'>{I18N('active_users_granted_soft_launch_access', { activeCount: softLaunchUsersCount.activeCount })}</p>
            <p className='m-0'>{I18N('total_users_granted_soft_launch_access', { totalCount: softLaunchUsersCount.totalCount })}</p>
          </div>
        </div>

        <TableLoadingContainer isLoading={isLoading}>
          <div className='overflow-x-auto'>
            <SmartTable
              className='white-bg-table'
              data={softLaunchUsers}
              sortKey='soft_launch_access_requested_at'
              sortedAsc={false}
              showPagination={page !== null && totalPages !== null}
              columns={columns}
              page={page}
              pages={totalPages}
              totalCount={total}
              perPage={perPage}
              onSortChange={handleSortChange}
              onPaginationClick={handlePaginationClick}
            />
          </div>
        </TableLoadingContainer>
      </main>
    </div>
  )
}

export default SoftLaunchAccessPage
