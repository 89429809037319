import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import { present } from 'components/common/utils'

export const initialState = {
  taskableStepIds: [],
  meta: {
    ...defaultMeta,
    openTaskableStepsCount: null,
    directReportsOpenTaskableStepsCount: null,
    directReportsOverdueTaskableStepsCount: null,
    isLoadingTaskableStepsCount: false,
    queryParams: {
      ...defaultPaginationParams, perPage: 10, sortBy: 'trigger_at', sortDir: 'asc',
    },
  },
}

const taskableStepSlice = createSlice({
  name: 'taskableSteps',
  initialState,
  reducers: {
    ...defaultActions,

    setOpenTaskableStepsCount(state, action) {
      state.meta.openTaskableStepsCount = action.payload
    },

    setQueryParams(state, action) {
      state.meta.queryParams = {
        ...state.meta.queryParams,
        ...action.payload,
      }
    },

    setTaskableStepIds(state, action) {
      state.taskableStepIds = action.payload
    },

    clearTaskableStepIds(state) {
      state.taskableStepIds = []
    },

    setDirectReportsTaskableStepsCounts(state, action) {
      state.meta.directReportsOpenTaskableStepsCount = action.payload.openCount
      state.meta.directReportsOverdueTaskableStepsCount = action.payload.overdueCount
    },

    setIsLoadingTaskableStepsCount(state, action) {
      state.meta.isLoadingTaskableStepsCount = action.payload
    },
  },
})

_.assign(taskableStepSlice, {
  asyncActions: {
    admin: {
      fetchAll: (params = {}) => async (dispatch) => {
        dispatch(taskableStepSlice.actions.isLoading(true))

        try {
          const response = await API.admin.taskableSteps.fetchAll(params)

          const taskableStepIds = response.data.data.map(step => step.id)
          dispatch(entitySlice.actions.add({ data: response.data }))
          dispatch(taskableStepSlice.actions.setTaskableStepIds(taskableStepIds))
          dispatch(taskableStepSlice.actions.setQueryParams(queryParamsFromHeaders(response)))
        } catch (e) {
          appSignal.sendErrorUnlessClearyBackendError(e)
        } finally {
          dispatch(taskableStepSlice.actions.isLoading(false))
        }
      },
    },

    fetchAll: (params = {}) => async (dispatch) => {
      dispatch(taskableStepSlice.actions.isLoading(true))

      try {
        const response = await API.journey.taskableSteps.fetchAll(params)
        dispatch(entitySlice.actions.add({ data: response.data }))

        const queryParams = queryParamsFromHeaders(response)
        if (params?.status === 'pending' && params?.tasksOwner === 'my_tasks' && _.isEmpty(params?.q)) {
          dispatch(taskableStepSlice.actions.setOpenTaskableStepsCount(queryParams?.total))
        }

        dispatch(taskableStepSlice.actions.setQueryParams(queryParams))

        const taskableStepIds = response.data.data.map(taskableStep => taskableStep.id) || []
        dispatch(taskableStepSlice.actions.setTaskableStepIds(taskableStepIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(taskableStepSlice.actions.isLoading(false))
      }
    },

    getDirectReportsCountByTaskableStepStatus: () => async (dispatch) => {
      dispatch(taskableStepSlice.actions.setIsLoadingTaskableStepsCount(true))

      try {
        const response = await API.journey.taskableSteps.getDirectReportsCountByTaskStatus()

        dispatch(taskableStepSlice.actions.setDirectReportsTaskableStepsCounts(response.data))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(taskableStepSlice.actions.setIsLoadingTaskableStepsCount(false))
      }
    },
  },
})

_.assign(taskableStepSlice, {
  selectors: {
    getMetaData: () => state => state.taskableSteps.meta,

    getTaskableSteps: () => state => state.taskableSteps.taskableStepIds.map(id => build(state.entities, 'step', id)).filter(present),
  },
})

export default taskableStepSlice
