import React from 'react'
import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import Card from 'components/common/card'
import moment from 'moment'

const I18N = i18nPath('views.profile.tenure')

export const createTenureString = (user) => {
  const now = i18nMoment()
  const startDateMoment = i18nMoment(user.startDate)

  const yearsAgo = now.diff(startDateMoment, 'years')
  const monthsAgo = now.diff(startDateMoment.add(yearsAgo, 'years'), 'months')
  const daysAgo = now.diff(startDateMoment.add(monthsAgo, 'months'), 'days')

  const displayArray = []

  if (yearsAgo > 0) displayArray.push(I18N('years', { count: yearsAgo }))

  if (monthsAgo > 0) displayArray.push(I18N('months', { count: monthsAgo }))

  if (daysAgo > 0) displayArray.push(I18N('days', { count: daysAgo }))

  if (daysAgo <= 0 && monthsAgo <= 0 && yearsAgo <= 0) displayArray.push(I18N('days', { count: -daysAgo }))

  return displayArray.join(' ')
}

const Tenure = ({ user, fieldSettings }) => {
  const showTimeSinceStartDate = fieldSettings.timeSinceIStarted && user.startDate && user.active

  if (!showTimeSinceStartDate) {
    return null
  }

  const employeeSince = i18nMoment(user.startDate).format('LL')
  const hasNotStarted = moment().isBefore(user.startDate)

  return (
    <section className='ProfileTenure'>
      <Card>
        <h6>{I18N('title')}</h6>
        <div className='mt-3 d-flex flex-column'>
          <span className='text-secondary text-small'>{I18N(hasNotStarted ? 'time_until_start' : 'time_since_i_started')}</span>
          <span className='font-weight-500 mt-2'>{createTenureString(user)}</span>
        </div>
        <div className='mt-4 d-flex flex-column'>
          <span className='text-secondary text-small'>{I18N(hasNotStarted ? 'starting_on' : 'employee_since')}</span>
          <span className='font-weight-500 mt-2'>{employeeSince}</span>
        </div>
      </Card>
    </section>
  )
}

export default Tenure
