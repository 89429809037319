import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useIsCleary from 'components/common/hooks/useIsCleary'

const useNavItems = () => {
  const { permissions } = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const isCleary = useIsCleary()
  const { settings } = currentCompany

  const isAiPlatform = currentCompany.type === 'ai_platform'

  return (
    {
      'Content Management': {
        articles: settings.news.enabled && (permissions.articleManager || permissions.articleCreator),
        carousel_cards: settings.carousel.enabled && permissions.carouselManager,
        banners: settings.banners.enabled && permissions.bannerManager,
        tags: settings.news.enabled && permissions.articleManager,
        article_types: settings.news.enabled && permissions.clearyAdmin,
        surveys: settings.surveys.enabled && permissions.surveyCreator,
      },
      'User Management': {
        users: permissions.userManager,
        user_imports: permissions.userManager,
        teams: settings.groups.enabled && permissions.groupManager,
        soft_launch_access: permissions.superAdmin && settings.softLaunchWhitelist.enabled,
        restricted_audiences: permissions.clearyAdmin || (
          permissions.superAdmin && currentCompany.hasRestrictedAudienceConfiguration),
      },
      'Journeys': {
        journeys_dashboard: settings.journeys.enabled && permissions.journeyCollaborator,
        // eslint-disable-next-line max-len
        tasks: settings.journeys.enabled && settings.journeys.stepTypes.tasks.enabled && permissions.journeyCollaborator,
        journeys: settings.journeys.enabled && permissions.journeyCollaborator,
        onboarding_buddies: settings.journeys.onboardingBuddies.enabled && permissions.journeyManager,
        preboarding_experience: settings.journeys.preboarding.enabled && permissions.journeyManager,
      },
      'Recognition': {
        company_values: settings.shoutouts.enabled && permissions.companyValueManager,
      },
      'Integrations': {
        app_integrations: permissions.itManager,
        managed_groups: settings.managedGroups.enabled && permissions.itManager,
      },
      'App Configuration': {
        apps: permissions.itManager && settings.appLauncher?.enabled,
        cleary_ai_settings: permissions.superAdmin,
        homepage_design: permissions.superAdmin && !isAiPlatform,
        timezones: permissions.superAdmin && !isAiPlatform,
        user_skills: permissions.superAdmin && settings.profile.fields.userSkills && !isAiPlatform,
        custom_styles: permissions.stylesManager && !isAiPlatform,
        logos: settings.logos.enabled && permissions.logoManager,
        external_calendars: settings.feed.showCompanyCalendar && permissions.externalCalendarManager,
        other_settings: permissions.superAdmin && !isAiPlatform,
        user_settings: permissions.superAdmin && !isAiPlatform,
        styleguide: (permissions.superAdmin && isCleary) || permissions.clearyAdmin,
        metrics: permissions.superAdmin && !isAiPlatform,
      },
      'Cleary Admin': {
        cleary_admin: permissions.clearyAdmin,
        companies: (permissions.clearyAdmin || permissions.trialCompaniesAdmin) && isCleary,
      },
    }
  )
}

export default useNavItems
