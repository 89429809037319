import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'

import FormCheck from 'components/form_fields/formCheck'

import userSlice from 'redux/slices/users'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const CLEARY_ADMIN_PERMISSION = 'cleary_admin'
const TRIAL_COMPANIES_ADMIN = 'trial_companies_admin'
const SUPER_ADMIN_PERMISSION = 'super_admin'
const EVENT_CREATOR_PERMISSION = 'event_creator'
const QNA_MANAGER_PERMISSION = 'qna_manager'
const ARTICLE_MANAGER_PERMISSION = 'article_manager'
const ARTICLE_CREATOR_PERMISSION = 'article_creator'
const BADGE_MANAGER_PERMISSION = 'badge_manager'
const BADGE_CREATOR_PERMISSION = 'badge_creator'
const SURVEY_MANAGER_PERMISSION = 'survey_manager'
const SURVEY_CREATOR_PERMISSION = 'survey_creator'
const PAGE_MANAGER_PERMISSION = 'page_manager'
const JOURNEY_MANAGER_PERMISSION = 'journey_manager'
const JOURNEY_CREATOR_PERMISSION = 'journey_creator'
const PERMISSIONS_AVAILABLE_ONLY_TO_CLEARY_ADMIN = [CLEARY_ADMIN_PERMISSION, TRIAL_COMPANIES_ADMIN]
const PERMISSIONS_AVAILABLE_ONLY_ON_CLEARY = [TRIAL_COMPANIES_ADMIN]

const I18N = i18nPath('views.admin.permissions')

const UserPermissionsTab = ({
  user,
}) => {
  const dispatch = useDispatch()

  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const { appName } = currentCompany
  const permissions = user.virtualFields?.permissions || []

  // eslint-disable-next-line max-len
  const checkIfPermissionIsEnabled = permissionName => permissions.find(permission => permission.name === permissionName)?.value || false

  const isClearyAdminEnabled = checkIfPermissionIsEnabled(CLEARY_ADMIN_PERMISSION)
  const isSuperAdminEnabled = checkIfPermissionIsEnabled(SUPER_ADMIN_PERMISSION)
  const isQnaManagerEnabled = checkIfPermissionIsEnabled(QNA_MANAGER_PERMISSION)
  const isArticleManagerEnabled = checkIfPermissionIsEnabled(ARTICLE_MANAGER_PERMISSION)
  const isBadgeManagerEnabled = checkIfPermissionIsEnabled(BADGE_MANAGER_PERMISSION)
  const isSurveyManagerEnabled = checkIfPermissionIsEnabled(SURVEY_MANAGER_PERMISSION)
  const isJourneyManagerEnabled = checkIfPermissionIsEnabled(JOURNEY_MANAGER_PERMISSION)

  const filteredPermissions = () => {
    const isCurrentUserClearyAdmin = currentUser.permissions.clearyAdmin

    let filteredPermissions = permissions

    if (currentCompany.subdomain !== 'cleary') {
      filteredPermissions = filteredPermissions.filter(
        permission => !PERMISSIONS_AVAILABLE_ONLY_ON_CLEARY.includes(permission.name)
      )
    }

    filteredPermissions = filteredPermissions.filter(
      permission => permission.name !== PAGE_MANAGER_PERMISSION
    )

    if (isCurrentUserClearyAdmin) {
      return filteredPermissions
    }

    filteredPermissions = filteredPermissions.filter(
      permission => !PERMISSIONS_AVAILABLE_ONLY_TO_CLEARY_ADMIN.includes(permission.name)
    )

    return filteredPermissions
  }

  const togglePermission = (permission) => {
    if (permission.value) {
      dispatch(userSlice.asyncActions.admin.removeUserPermission(user.id, permission.name))
    } else {
      dispatch(userSlice.asyncActions.admin.addUserPermission(user.id, permission.name))
    }
  }

  const shouldDisablePermissionInput = (permission) => {
    if (isClearyAdminEnabled) {
      return permission !== CLEARY_ADMIN_PERMISSION
    }

    if (isSuperAdminEnabled) {
      return permission !== SUPER_ADMIN_PERMISSION
    }

    if (isQnaManagerEnabled && permission === EVENT_CREATOR_PERMISSION) {
      return true
    }

    if (isArticleManagerEnabled && permission === ARTICLE_CREATOR_PERMISSION) {
      return true
    }

    if (isBadgeManagerEnabled && permission === BADGE_CREATOR_PERMISSION) {
      return true
    }

    if (isSurveyManagerEnabled && permission === SURVEY_CREATOR_PERMISSION) {
      return true
    }

    if (isJourneyManagerEnabled && permission === JOURNEY_CREATOR_PERMISSION) {
      return true
    }

    return false
  }

  const { isLoading } = useSelector(userSlice.selectors.getPermissionsMetadata())

  return (
    <div className='UserPermissionsTab background-color-white p-4'>
      {filteredPermissions().map(permission => (
        <div key={permission.name} className='p-3 d-flex justify-content-start align-items-center'>
          <FormCheck
            onChange={() => togglePermission(permission)}
            id={`permission-${permission.name}`}
            checked={permission.value}
            disabled={isLoading || shouldDisablePermissionInput(permission.name)}
          />
          <div>
            <span>{I18N(permission.name)}</span>
            <p className='text-secondary mb-0'>{I18N(`${permission.name}_description`, { appName })}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default UserPermissionsTab
