import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import ShoutoutModal from 'components/shoutouts/shoutoutModal'
import Card from 'components/common/card'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import useApi from 'components/common/hooks/useApi'
import ShoutoutSuggestionType from 'types/shoutoutSuggestion'
import CdnSvg from 'components/common/cdnSvg'
import ShoutoutsHorizontalLayout from 'components/home/widgets/shoutoutsHorizontalLayout'
import ShoutoutsVerticalLayout from 'components/home/widgets/shoutoutsVerticalLayout'

const I18N = i18nPath('views.shoutouts')

const ShoutoutSuggestionsWidget = ({ EmptyState = null, widget, resizeWidget }) => {
  const [shoutoutSuggestions, setShoutoutSuggestions] = useState<ShoutoutSuggestionType[]>([])
  const [selectedSuggetion, setSelectedSuggestion] = useState<ShoutoutSuggestionType | null>(null)

  const useVerticalLayout = widget.settings.orientation === 'vertical'

  const { isLoaded } = useFetch(API.shoutouts.fetchSuggestions, [], {
    onSuccess: setShoutoutSuggestions,
  })
  const [clearSuggestions] = useApi(API.shoutouts.clearSuggestions, {
    onSuccess: () => setShoutoutSuggestions([]),
  })

  const onShoutoutCreated = () => {
    setShoutoutSuggestions(shoutoutSuggestions.filter(s => s.id !== selectedSuggetion?.id))
    setSelectedSuggestion(null)
  }

  if (_.isEmpty(shoutoutSuggestions)) {
    return EmptyState
  }

  return (
    <>
      <Card
        className='ShoutoutSuggestionsWidget HomePageWidget h-100 w-100'
        cardBodyClassName='p-0'
      >
        <div
          className='ShoutoutSuggestionsWidget-Title position-relative overflow-hidden bg-highlight-color border-radius-top'
        >
          <h5 className='text-white m-0'>{I18N('send_a_shoutout_title')}</h5>
          <div className='position-absolute right-0 top-0 mr-2 mt-2' onClick={clearSuggestions}>
            <CdnSvg className='XIcon' src='/images/xIcon.svg' />
          </div>

          <CdnSvg
            className='MegaphoneIcon position-absolute right-0 top-0 mr-5'
            src='/images/illustrations/manWithMegaphone.svg'
          />
        </div>

        {useVerticalLayout ? (
          <ShoutoutsVerticalLayout
            shoutoutSuggestions={shoutoutSuggestions}
            setSelectedSuggestion={setSelectedSuggestion}
            widget={widget}
            resizeWidget={resizeWidget}
            isLoaded={isLoaded}
          />
        ) : (
          <ShoutoutsHorizontalLayout
            shoutoutSuggestions={shoutoutSuggestions}
            setSelectedSuggestion={setSelectedSuggestion}
            width={widget.w}
          />
        )}
      </Card>

      {selectedSuggetion && (
        <ShoutoutModal
          visible={!!selectedSuggetion}
          closeModal={() => setSelectedSuggestion(null)}
          user={{ ...selectedSuggetion.suggestedUser, suggestionId: selectedSuggetion.id } as any}
          onCreated={onShoutoutCreated}
        />
      )}
    </>
  )
}

export default ShoutoutSuggestionsWidget
