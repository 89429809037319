import React from 'react'
import SuggestedIcon from 'components/common/suggestedIcon'
import cleanUrl from 'utils/cleanUrl'
import SearchResultOption from './searchResultOption'

const GoLinkOption = ({ option, className }) => {
  const { goLink, goLinkAlias, highlights } = option

  const record = goLinkAlias || goLink

  const {
    name,
    description,
    url,
    displayName,
    iconUrl,
  } = { ...record, ...highlights }

  return (
    <SearchResultOption
      image={(
        <SuggestedIcon
          name={displayName}
          url={url}
          iconUrl={iconUrl}
          height={22}
          width={22}
        />
      )}
      title={`go/${name}`}
      secondaryTexts={[description, cleanUrl(url)]}
      className={className}
    />
  )
}

export default GoLinkOption
