import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { ButtonNarrow } from 'components/common/buttons'
import Modal from 'components/common/modal'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import formSlice, { defaultWorkingCopy } from 'redux/slices/surveys/forms'

const I18N = i18nPath('views.admin.surveys.create_form_modal')

interface CreateFormModalProps {
  className?: string
  visible: boolean
  toggle: () => void
}

const CreateFormModal = ({
  className = '',
  visible,
  toggle,
}: CreateFormModalProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const { isSaving } = useSelector(formSlice.selectors.getMetaData())

  const disableCreateButton = !name || isSaving

  const onSuccess = (id: string) => {
    history.push(`/admin/surveys/${id}`)
  }

  const onClickCreate = () => {
    const params = defaultWorkingCopy(name)

    dispatch(formSlice.asyncActions.admin.create(params, onSuccess))
  }

  return (
    <Modal
      size='lg'
      className={classNames('CreateFormModal', className)}
      visible={visible}
      toggle={toggle}
    >
      <h4 className='mb-3'>{I18N('title')}</h4>
      <>
        <span className='d-inline-block text-normal font-weight-500 mt-3 mb-2'>{I18N('name_label')}</span>
        <input
          name='formName'
          value={name}
          className='form-control'
          onChange={e => setName(e.target.value)}
          placeholder={I18N('placeholder')}
        />
      </>
      <div className='d-flex justify-content-end align-items-center'>
        <div role='button' tabIndex={0} onClick={toggle} className='mr-3 text-muted-dark'>
          {I18NCommon('cancel')}
        </div>
        <ButtonNarrow
          className='my-4'
          disabled={disableCreateButton}
          onClick={onClickCreate}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('create')}
        </ButtonNarrow>
      </div>
    </Modal>
  )
}

export default CreateFormModal
