import React from 'react'
import ReactSelect from 'components/common/react_select'
import { ButtonSmallNarrow } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CheckboxToggleButtonGroup from 'components/common/buttons/checkboxToggleButtonGroup'

const I18N = i18nPath('views.admin.journeys.onboarding_buddies.settings')

const SingleCriteriaSelect = ({
  selectedCriteria, unselectedOptions, onChange, number,
}) => {
  const options = [selectedCriteria, ...unselectedOptions].filter(Boolean)

  const handleToggleChange = value => onChange({ ...selectedCriteria, similar: value })

  return (
    <div className='SingleCriteriaSelect'>
      <div>{I18N(number <= 10 ? `single_criteria_label_${number}` : 'single_criteria_label_other', { count: number })}</div>
      <div className='d-flex mb-3 align-items-center'>
        <ReactSelect
          value={selectedCriteria}
          options={options}
          getOptionValue={option => option.key + option.type}
          onChange={onChange}
        />
        {selectedCriteria && (
          <>
            <CheckboxToggleButtonGroup
              className='ml-2'
              optionsClassName='text-small'
              type='radio'
              name={`${selectedCriteria.type}-${selectedCriteria.key}`}
              currentValue={selectedCriteria.similar}
              onChange={handleToggleChange}
              options={[
                { label: I18N('similar'), value: true },
                { label: I18N('different'), value: false },
              ]}
            />
            <ButtonSmallNarrow
              variant='secondary-danger'
              className='ml-2'
              onClick={() => onChange(null)}
            >
              {I18NCommon('remove')}
            </ButtonSmallNarrow>
          </>
        )}
      </div>
    </div>
  )
}

const getKeyFromFilterSetting = (filter) => {
  if (['extra_user_field', 'group_type'].includes(filter.type)) {
    return filter.value
  }

  return filter.key
}

const MatchingCriteriaSelector = ({
  targetingOptions,
  selectedCriteria,
  setSelectedCriteria,
}) => {
  const areFilterAndCriteriaEqual = (filter, criteria) => (
    filter.type === criteria.type && getKeyFromFilterSetting(filter) === criteria.key
  )
  const isInSelectedCriteria = filter => !!selectedCriteria.find(criteria => (
    areFilterAndCriteriaEqual(filter, criteria)
  ))

  const unselectedOptions = targetingOptions?.filter(filter => !isInSelectedCriteria(filter)).map(filter => ({
    type: filter.type,
    key: getKeyFromFilterSetting(filter),
    attribute: filter.value,
    label: filter.label,
    similar: true,
  }))

  const handleCriteriaChange = (index, newCriteria) => {
    setSelectedCriteria([
      ...selectedCriteria.slice(0, index),
      newCriteria,
      ...selectedCriteria.slice(index + 1)].filter(Boolean)
    )
  }

  return (
    <div className='MatchingCriteriaSelector'>
      {selectedCriteria.map((criteria, index) => (
        <SingleCriteriaSelect
          key={`criteria-${index}`}
          selectedCriteria={criteria}
          unselectedOptions={unselectedOptions}
          onChange={newCriteria => handleCriteriaChange(index, newCriteria)}
          number={index + 1}
        />
      ))}
      {unselectedOptions.length > 0 && (
        <SingleCriteriaSelect
          selectedCriteria={null}
          unselectedOptions={unselectedOptions}
          onChange={newCriteria => handleCriteriaChange(selectedCriteria.length, newCriteria)}
          number={selectedCriteria.length + 1}
        />
      )}
    </div>
  )
}

export default MatchingCriteriaSelector
