import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import FormCheck from 'components/form_fields/formCheck'
import Tooltip from 'components/common/clearyTooltip'

const I18N = i18nPath('views.qna.events.settings_modal.qna')

const AdvancedSettings = ({ event, setEvent }) => {
  const handleAllowAnonymousQuestionsChange = (e) => {
    if (e.target.checked) {
      setEvent({ ...event, allowAnonymousQuestions: true })
    } else {
      // if unchecking, then also set the 'anonymous by default' setting to false
      setEvent({
        ...event,
        allowAnonymousQuestions: false,
        settings: {
          ...event.settings,
          anonymousQuestions: {
            anonymousByDefault: false,
          },
        },
      })
    }
  }

  const handleAnonymousByDefaultSettings = (e) => {
    setEvent({
      ...event,
      settings: { ...event.settings, anonymousQuestions: { anonymousByDefault: e.target.checked } },
    })
  }

  const handleRequireQuestionApprovalSettings = (e) => {
    setEvent({
      ...event,
      settings: { ...event.settings, requireQuestionApproval: { enabled: e.target.checked } },
    })
  }

  return (
    <div className='AdvancedSettings mt-5'>
      <label className='d-block'>{I18N('advanced_settings')}</label>
      <FormCheck
        name='allow_anonymous_questions'
        label={I18N('allow_anonymous_questions')}
        checked={event.allowAnonymousQuestions}
        onChange={handleAllowAnonymousQuestionsChange}
        id='allow_anonymous_questions'
      />
      {event.allowAnonymousQuestions && (
        <FormCheck
          className='mt-3'
          name='anonymous_by_default'
          label={I18N('anonymous_by_default')}
          checked={event.settings.anonymousQuestions.anonymousByDefault}
          onChange={handleAnonymousByDefaultSettings}
          id='anonymous_by_default'
        />
      )}
      <FormCheck
        className='mt-3'
        name='enable_question_moderation'
        label={(
          <span>
            {I18N('enable_question_moderation')}
            <span onClick={e => e.preventDefault()}>
              <Tooltip placement='bottom' className='ml-2 mb-1 EnanbleModerationTooltip'>
                <span className='text-small'>{I18N('enable_question_moderation_tooltip')}</span>
              </Tooltip>
            </span>
          </span>
        )}
        checked={event.settings.requireQuestionApproval.enabled}
        onChange={handleRequireQuestionApprovalSettings}
        id='enable_question_moderation'
      />
    </div>
  )
}

export default AdvancedSettings
