import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import FormErrorList from 'components/errors/formErrorList'
import UserSkillForm from 'components/admin/userSkillForm'
import userSkillSlice from 'redux/slices/userSkills'
import userSkillTypeSlice from 'redux/slices/userSkillTypes'

const I18N = i18nPath('views.admin.user_skills')

const CreateUserSkillPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [userSkill, setUserSkill] = useState({
    id: null,
    name: null,
    userSkillTypeId: null,
  })

  const { isSaving, error } = useSelector(userSkillSlice.selectors.getMetaData())
  const disableSaveButton = !userSkill.name || !userSkill.userSkillTypeId
  const userSkillTypes = useSelector(userSkillTypeSlice.selectors.getUserSkillTypes())

  useEffect(() => {
    dispatch(userSkillTypeSlice.asyncActions.admin.fetchUserSkillTypes())
  }, [])

  const saveUserSkill = () => {
    dispatch(userSkillSlice.asyncActions.admin.createUserSkill(userSkill, history))
  }

  return (
    <div className='CreateUserSkill'>
      <header className='AdminHeader'>
        <h3>{I18N('create_user_skill')}</h3>
      </header>

      <main className='AdminContent'>
        <UserSkillForm userSkill={userSkill} userSkillTypes={userSkillTypes} setUserSkill={setUserSkill} />

        {error && <FormErrorList error={error} />}

        <Button disabled={disableSaveButton} onClick={saveUserSkill} showLoadingSpinner={isSaving} className='mt-3'>
          {I18N('save')}
        </Button>
      </main>
    </div>
  )
}

export default CreateUserSkillPage
