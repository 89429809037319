import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { ButtonNarrow } from 'components/common/buttons'
import userSlice from 'redux/slices/users'
import { ProfileUserType } from 'types/user'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.profile')

interface InviteToClearyButtonProps {
  user: ProfileUserType,
}

const InviteToClearyButton = ({ user }: InviteToClearyButtonProps) => {
  const dispatch = useDispatch()
  const { appName } = useCurrentCompany()

  const { isSaving } = useSelector(userSlice.selectors.getMetaData())

  const inviteToCleary = () => {
    dispatch(userSlice.asyncActions.grantSoftLaunchAccess(user))
  }

  return (
    <ButtonNarrow
      className='mr-2'
      onClick={inviteToCleary}
      disabled={isSaving}
      showLoadingSpinner={isSaving}
    >
      {I18N('invite_to_cleary', { appName })}
    </ButtonNarrow>
  )
}

export default InviteToClearyButton
