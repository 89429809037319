import React from 'react'

import Card from 'components/common/card'
import { i18nPath } from 'utils/i18nHelpers'
import ProgressiveImage from 'components/common/images/progressiveImage'

const I18N = i18nPath('views.admin.layout_collections_new.template_preview')
const I18NWidgets = i18nPath('views.admin.home_editor.widgets')
const I18NTemplateNames = i18nPath('views.admin.layout_collections_new.template_names')

const LayoutCollectionTemplatePreview = ({ selectedTemplate }) => {
  const { name, usedWidgets = [] } = selectedTemplate

  return (
    <Card cardBodyClassName='LayoutCollectionTemplatePreview'>
      <div className='img-container'>
        <ProgressiveImage src={`/images/layout/templates/${name}.jpg`} />
      </div>
      <div>
        <h2 className='mb-2'>{I18NTemplateNames(name)}</h2>
        <span className='text-secondary font-weight-500'>{I18N('widgets_includes')}</span>
        <ul className='p-3'>
          {usedWidgets.map(widget => (
            <li key={widget}>{I18NWidgets(widget)}</li>
          ))}
        </ul>

        <span className='text-secondary font-weight-500'>{I18N('ideal_for')}</span>
        <p className='mt-2'>{I18N(`description.${name}`)}</p>
      </div>
    </Card>
  )
}

export default LayoutCollectionTemplatePreview
