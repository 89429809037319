import React from 'react'

import Card from 'components/common/card'
import EditThumbnailButtons from 'components/admin/articles/audience_and_settings_editor/editThumbnailButtons'
import { i18nPath } from 'utils/i18nHelpers'
import ArticleItem from 'components/home/widgets/common/articles/articleItem'

const I18N = i18nPath('views.admin.article_editor.audience_and_settings_editor.thumbnail_card')

const ThumbnailCard = ({ workingCopy, updateWorkingCopy }) => (
  <Card className='ThumbnailCard mb-4 ArticleWidgetType'>
    <h5>{I18N('title')}</h5>
    <p className='text-secondary'>{I18N('description')}</p>

    <ArticleItem article={{ ...workingCopy, acknowledgementInfo: { dueAt: workingCopy.acknowledgementDueAt } }} />

    <EditThumbnailButtons workingCopy={workingCopy} updateWorkingCopy={updateWorkingCopy} />
  </Card>
)

export default ThumbnailCard
