import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import ManageWorkspacePermissionsModal from 'components/pages/workspaces/manageWorkspacePermissionsModal'
import ChangeWorkspaceOwnersModal from 'components/pages/workspaces/changeWorkspaceOwnersModal'
import RenameWorkspaceModal from 'components/pages/workspaces/renameWorkspaceModal'
import DeleteWorkspaceModal from 'components/pages/workspaces/deleteWorkspaceModal'
import TrashIcon from 'components/icons/trashIcon'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.pages.workspaces')
const personsIconPath = '/images/personsIcon.svg'
const swapPeopleIconPath = '/images/swapPeopleIcon.svg'
const pencilIconPath = '/images/pencilIcon.svg'

const MANAGE_WORKSPACE_PERMISSIONS = 'MANAGE_WORKSPACE_PERMISSIONS'
const CHANGE_WORKSPACE_OWNERS = 'CHANGE_WORKSPACE_OWNERS'
const RENAME_WORKSPACE = 'RENAME_WORKSPACE'
const DELETE_WORKSPACE = 'DELETE_WORKSPACE'

const WorkspaceActionsDropdownMenu = ({ workspace }) => {
  const [openModal, setOpenModal] = useState<string | null>(null)

  return (
    <>
      <DropdownMenuContainer
        iconWidth='14px'
        iconHeight='5px'
        closeMenuOnItemClick
        className='WorkspaceActionsDropdownMenu'
      >
        <CardDropdownMenuItem
          onClick={() => setOpenModal(MANAGE_WORKSPACE_PERMISSIONS)}
          primaryText={<span className='font-weight-400 text-small mx-2'>{I18N('manage_permissions')}</span>}
          className='px-3 py-2 rounded-top-lg'
          icon={<CdnSvg src={personsIconPath} className='PersonsIcon' />}
          width='190px'
        />
        <CardDropdownMenuItem
          onClick={() => setOpenModal(CHANGE_WORKSPACE_OWNERS)}
          primaryText={<span className='font-weight-400 text-small mx-2'>{I18N('workspace_owners')}</span>}
          className='px-3 py-2 rounded-top-lg'
          icon={<CdnSvg src={swapPeopleIconPath} className='SwapPeopleIcon' />}
          width='190px'
        />
        <CardDropdownMenuItem
          onClick={() => setOpenModal(RENAME_WORKSPACE)}
          primaryText={<span className='font-weight-400 text-small mx-2'>{I18N('rename_workspace')}</span>}
          className='px-3 py-2 rounded-top-lg'
          icon={<CdnSvg src={pencilIconPath} className='PencilIcon' />}
          width='190px'
        />
        <CardDropdownMenuItem
          onClick={() => setOpenModal(DELETE_WORKSPACE)}
          primaryText={<span className='text-danger font-weight-400 text-small mx-2'>{I18N('delete_workspace')}</span>}
          icon={<TrashIcon className='DeletePageIcon' />}
          className='px-3 py-2 rounded-top-lg'
          width='190px'
        />
      </DropdownMenuContainer>
      {openModal === MANAGE_WORKSPACE_PERMISSIONS && (
        <ManageWorkspacePermissionsModal
          workspaceId={workspace.id}
          visible={openModal === MANAGE_WORKSPACE_PERMISSIONS}
          toggle={() => setOpenModal(null)}
        />
      )}
      {openModal === CHANGE_WORKSPACE_OWNERS && (
        <ChangeWorkspaceOwnersModal
          workspaceId={workspace.id}
          visible={openModal === CHANGE_WORKSPACE_OWNERS}
          toggle={() => setOpenModal(null)}
        />
      )}
      {openModal === RENAME_WORKSPACE && (
        <RenameWorkspaceModal
          visible={openModal === RENAME_WORKSPACE}
          toggle={() => setOpenModal(null)}
          workspace={workspace}
        />
      )}
      {openModal === DELETE_WORKSPACE && (
        <DeleteWorkspaceModal
          workspace={workspace}
          visible={openModal === DELETE_WORKSPACE}
          toggle={() => setOpenModal(null)}
        />
      )}
    </>
  )
}

export default WorkspaceActionsDropdownMenu
