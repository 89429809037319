import React from 'react'
import { useHistory } from 'react-router-dom'
import ClearyCard from 'components/common/card'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { Button } from 'components/common/buttons'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { ExternalSyncSource } from 'components/admin/integrations/external_sync/types'

const I18N = i18nPath('views.ai_assistant.dashboard.left_sidebar.knowledge_sources')

const TYPE_MAP = {
  googleDrive: {
    type: 'ExternalSync::GoogleDrive::Source',
    name: 'Google Drive',
    icon: '/images/googleDriveIcon.svg',
    available: true,
  },
  notion: {
    type: 'ExternalSync::Notion::Source',
    name: 'Notion',
    icon: '/images/notionIcon.svg',
    available: true,
  },
  confluence: {
    type: 'ExternalSync::Confluence::Source',
    name: 'Confluence',
    icon: '/images/confluence.svg',
    available: true,
  },
}

const SourcesList = ({ knowledgeSources }: { knowledgeSources: ExternalSyncSource[] }) => {
  const findSource = (type: string) => knowledgeSources?.find(source => source.type === type)
  const history = useHistory()

  return (
    <div className='d-flex flex-column gap-3'>
      {Object.values(TYPE_MAP).map(source => (
        <div className='d-flex justify-content-between'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center gap-2'>
              <CdnSvg src={source.icon} />
              <p className='text-normal bold m-0'>{source.name}</p>
            </div>
          </div>

          <Button
            variant='outline-primary'
            size='sm'
            disabled={!source.available}
            onClick={() => {
              if (!source.available) {
                return
              }

              const foundSource = findSource(source.type)

              if (foundSource) {
                history.push(foundSource.displayPath)
              } else {
                history.push('/admin/app_integrations?expandedTab=external_knowledge')
              }
            }}
          >
            {source.available && (
              findSource(source.type) ? I18NCommon('view') : I18NCommon('add')
            )}

            {!source.available && I18NCommon('comming_soon')}
          </Button>
        </div>
      ))}
    </div>
  )
}

const KnowledgeSources = () => {
  const currentUser = useCurrentUser()

  const { data: knowledgeSources, isLoading, isLoaded } = useFetch<ExternalSyncSource[]>(
    API.admin.externalSync.sources.fetchAll
  )

  const filteredKnowledgeSources: ExternalSyncSource[] = knowledgeSources
    ?.filter(source => source.user.id === currentUser.id) ?? []

  return (
    <ClearyCard title={I18N('title')} className='KnowledgeSources'>
      {isLoading || !isLoaded ? (
        <CirclesLoadingIndicator />
      ) : (
        <SourcesList knowledgeSources={filteredKnowledgeSources} />
      )}
    </ClearyCard>
  )
}

export default KnowledgeSources
