import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import groupSlice from 'redux/slices/groups'
import groupFollowersSlice from 'redux/slices/groupFollowers'
import Modal from 'components/common/modal'
import LinkAnchor from 'components/common/linkAnchor'
import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import InfiniteScroller from 'components/common/infiniteScroller'
import AsyncSearchInput from 'components/common/asyncSearchInput'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import CdnSvg from 'components/common/cdnSvg'

const searchIconPath = '/images/searchIcon.svg'

const I18N = i18nPath('views.groups.group_page')
const I18NModal = i18nPath('views.groups.followers_modal')

const GroupFollowers = ({ groupFollowers = [] }) => (
  <div className='GroupFollowers border-bottom'>
    {groupFollowers.map(groupFollower => (
      <LinkAnchor
        key={groupFollower.id}
        link={groupFollower.displayPath}
      >
        <div className='GroupFollower p-2 mb-1 d-flex'>
          <img className='GroupMiniCardPhoto mr-2' src={groupFollower.photo || groupFollower.primaryPhotoThumbnailUrl} />
          <div className='d-flex flex-column'>
            <span className='text-primary font-weight-500'>{groupFollower.name}</span>
            <span className='text-secondary text-small'>
              {I18N('members_are_subscribed', { count: groupFollower.activeMembersCount, subscriberCount: groupFollower.activeMembersCount })}
            </span>
          </div>
        </div>
      </LinkAnchor>
    ))}
  </div>
)

const FollowersModal = ({ groupId, onClose }) => {
  const dispatch = useDispatch()
  const [query, setQuery] = useState('')
  const group = useSelector(groupSlice.selectors.getTeam(groupId))
  const followers = useSelector(groupFollowersSlice.selectors.getFollowers(groupId))
  const { isLoading, queryParams } = useSelector(groupFollowersSlice.selectors.getMetaData())
  const { activeGroupFollowers = [], activeUserFollowersCount = 0 } = group
  const hasGroupFollowers = activeGroupFollowers.length > 0
  const hasUserFollowers = activeUserFollowersCount > 0

  const searchFollowers = (params = {}) => {
    dispatch(groupFollowersSlice.asyncActions.fetchAll(groupId, { ...queryParams, ...params }))
  }

  const handleLoadMore = () => {
    searchFollowers({ page: queryParams.page + 1, q: query })
  }

  const hasMoreContent = () => queryParams.page < queryParams.totalPages

  useEffect(() => {
    searchFollowers({ q: query, page: 1 })

    return () => dispatch(groupFollowersSlice.actions.clear())
  }, [query])

  const isQueryEmpty = query.length === 0

  return (
    <Modal
      visible
      toggle={onClose}
      className='FollowersModal'
      modalTitleClassName='h4 font-weight-600'
      title={I18NModal('title')}
    >
      <div>
        <AsyncSearchInput
          onKeyUp={setQuery}
          placeholder={I18NModal('search_followers')}
          icon={<CdnSvg src={searchIconPath} />}
          className='search-input mb-4 w-100'
          inputGroupClassName='search-input-group mx-2'
        />
      </div>

      {hasGroupFollowers && <GroupFollowers groupFollowers={activeGroupFollowers} />}

      {isLoading && !isQueryEmpty && <CirclesLoadingIndicator />}

      {!isLoading && !isQueryEmpty && followers.length === 0 && (
        <div className='text-secondary text-center mt-4'>{I18NModal('no_results')}</div>
      )}

      <div className='users-list mb-4'>
        <InfiniteScroller
          onFetch={handleLoadMore}
          isFetching={isLoading && isQueryEmpty}
          hasMoreContent={hasMoreContent}
          anchorSelector='.users-list'
        >
          {followers.map(user => <EmployeeMiniCardLink key={user.id} employee={user} />)}
        </InfiniteScroller>
      </div>
    </Modal>
  )
}

const FollowersLinkAndModal = ({ groupId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleLinkClick = (e) => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='FollowersLink'>
      <a href='' onClick={handleLinkClick} className='py-1'>
        {I18N('view_followers')}
      </a>

      {isModalOpen && <FollowersModal onClose={handleClose} groupId={groupId} />}
    </div>
  )
}

export default FollowersLinkAndModal
