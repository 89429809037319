import React, { useEffect, useState } from 'react'

import NumberPercentageToggle, { NUMBER } from 'components/analytics/common/numberPercentageToggle'
import PillTabSelector from 'components/common/pillTabSelector'
import LineGraph from 'components/analytics/common/lineGraph'
import { i18nPath } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import engagementSlice from 'redux/slices/analytics/engagement'
import { dateLabels } from 'components/analytics/helpers/dateLabelFormat'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import TargetingRulesType from 'types/audience/targetingRules'
import ExportXlsxButton from 'components/analytics/common/exportXlsxButton'
import API from 'services/api'

const ALL_ENGAGEMENT = 'all_engagement'
const PAGE_VIEWS_ONLY = 'page_views_only'
const BUCKET = 'overviewEngagement'

const I18N = i18nPath('views.platform_analytics.overview.engagement')

interface OverviewEngagementProps {
  groupBy: string
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const OverviewEngagement = ({
  groupBy, periodEnd, periodStart, targetingRules,
}: OverviewEngagementProps) => {
  const [selectedTab, setSelectedTab] = useState(ALL_ENGAGEMENT)
  const [selectedType, setSelectedType] = useState(NUMBER)

  const eventNames = selectedTab === PAGE_VIEWS_ONLY ? 'page_views' : undefined
  const { appName } = useCurrentCompany()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(engagementSlice.asyncActions.admin.fetchEngagement(BUCKET, {
      eventNames,
      groupBy,
      periodEnd,
      periodStart,
      type: selectedType,
      unique: true,
      targetingRules,
    }))
  }, [groupBy, periodStart, periodEnd, eventNames, selectedType, targetingRules])

  const data = useSelector(engagementSlice.selectors.getEngagementByPeriod(BUCKET))
  const { isLoading } = useSelector(engagementSlice.selectors.getEngagementMeta(BUCKET))

  const dataValues = data.map(({ value }) => (selectedType === NUMBER ? value : Math.round(value * 100)))

  const labels = dateLabels(data, groupBy)

  const tabs = [
    {
      text: I18N('all_engagement'),
      onClick: () => setSelectedTab(ALL_ENGAGEMENT),
      className: 'd-flex flex-row align-items-center',
      showTooltip: true,
      tooltipText: I18N('all_engagement_tooltip', { app_name: appName }),
      tooltipClassName: '',
      tooltipInnerClassName: 'text-small px-1 py-2',
    },
    { text: I18N('page_views_only'), onClick: () => setSelectedTab(PAGE_VIEWS_ONLY) },
  ]

  const yAxisLabelKey = [
    'y_axis_label',
    selectedType === 'percentage' ? 'percentage' : 'raw',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const tooltipLabelKey = [
    'tooltip_label',
    selectedType === 'percentage' ? 'percentage' : 'raw',
    targetingRules ? 'audience' : 'overall',
  ].join('.')

  const onExport = (email: string) => {
    const exportXlsxParams = {
      periodStart,
      periodEnd,
      targetingRules,
      groupBy,
      eventNames,
      email,
      title: I18N('xlsx_title'),
    }

    return API.admin.analytics.engagement.exportXlsx(exportXlsxParams)
  }

  return (
    <div className='analytics-card grid-span-12'>
      <header className='d-flex flex-row justify-content-between'>
        <h3>{I18N('title')}</h3>
        <div className='d-flex flex-row align-items-center'>
          <NumberPercentageToggle selectedType={selectedType} setSelectedType={setSelectedType} />
          <ExportXlsxButton
            className='ml-1'
            onSubmit={onExport}
          />
        </div>
      </header>

      <PillTabSelector
        tabs={tabs}
        pillClasses='text-normal'
      />

      <LineGraph
        labels={labels}
        dataValues={dataValues}
        displayYAxisLabel
        yAxisLabel={I18N(yAxisLabelKey)}
        type={selectedType}
        tooltipLabel={I18N(tooltipLabelKey)}
        classname='w-100'
        isLoading={isLoading}
      />
    </div>
  )
}

export default OverviewEngagement
