import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect
} from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  InteractionMode,
  Tree,
  TreeEnvironmentRef,
  TreeItem,
  TreeItemIndex,
  ControlledTreeEnvironment
} from 'react-complex-tree'
import buildComplexTree, { findParentIdsFromComplexTree } from 'utils/buildComplexTree'
import pageSlice from 'redux/slices/pages'
import { usePagesNav } from 'components/pages/pagesNav/context'
import PAGES_NAV_COMPLEX_TREE_RENDERERS from './pagesNavComplexTreeRenderers'

const DROP_ON_ITEM = 'item'
const DROP_BETWEEN_ITEMS = 'between-items'

const PagesNavComplexTree = ({
  pages,
  workspace,
  treeId,
}) => {
  const dispatch = useDispatch()
  const treeEnvRef = useRef<TreeEnvironmentRef>(null)
  const previousPageSlugRef = useRef<string>('')
  const { workspaceSlug, pageSlug, pageId } = useParams()

  const {
    expandResource, fetchNavigation, isDraggingRef,
  } = usePagesNav()

  const [treeData, setTreeData] = useState<Record<TreeItemIndex, TreeItem>>({})
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[]>([])
  const [renderKey, setRenderKey] = useState<string>(treeId)

  const canDrag = workspace?.permittedActions?.canCreateAndMovePagesInWorkspace
  const showArchivedPagesLink = workspace?.showArchivedPagesLink

  const showArchive = showArchivedPagesLink && !!workspaceSlug

  const refreshTree = () => {
    // Stop refresh the tree if we're dragging.
    // This is specifically to support cypress drag and drop tests
    // Since for some reason it detects that the element is not rendered anymore during the drag.
    // This is probably due to the fact that the tree is re-rendered during the drag.
    if (isDraggingRef.current) return

    const pagesTree = buildComplexTree(
      pages.map(page => ({ ...page, index: page.id, data: page.title })),
      showArchive,
      'ancestryOrderPosition',
      true
    )

    const pageIdsToExpandInTree = findParentIdsFromComplexTree(pagesTree, pageId || pageSlug)

    if (!_.isEqual(pagesTree, treeData)) {
      setTreeData(pagesTree)
      setExpandedItems(_.uniq([...expandedItems, ...pageIdsToExpandInTree]))
      setRenderKey(`${treeId}-${(Math.random() + 1).toString(36).substring(7)}`)
    }
  }

  const onExpandItem = (item: TreeItem, treeId: string) => {
    expandResource('page', item.index)
    setExpandedItems(_.uniq([...expandedItems, item.index]))
  }

  const onCollapseItem = (item: TreeItem, treeId: string) => {
    setExpandedItems(expandedItems.filter(id => id !== item.index))
  }

  const pagesUpdatedAt = pages.map(p => moment(p.updatedAt).valueOf())
  const lastUpdatedAt = pagesUpdatedAt.length > 0 ? Math.max(...pagesUpdatedAt) : 0

  useEffect(() => {
    refreshTree()
  }, [lastUpdatedAt, pages.length])

  // useLayoutEffect is used here to ensure that we avoid a race condition when rendering the tree
  // otherwise, we can expand a node before the data is loaded into the tree.
  // store the previous page slug, and if the page slug changes, expand the new page
  useLayoutEffect(() => {
    if (previousPageSlugRef.current && pageSlug) {
      const currentPageNode = _.find(treeData, (node: any) => node?.slug === pageSlug)

      if (treeEnvRef.current && currentPageNode?.isFolder) {
        treeEnvRef.current.expandItem(currentPageNode?.index, treeId)
      }
    }

    previousPageSlugRef.current = pageSlug
  }, [pageSlug])

  const movePage = (items, target) => {
    const treeItem = items[0]
    const movedPageId = treeItem.index

    if (target.targetType === DROP_ON_ITEM) {
      const newParentId = target.targetItem

      // we've just dropped onto an item, we need to manually expand it
      // otherwise, the item will remain collapsed and the dropped item will be hidden
      if (treeEnvRef.current) {
        treeEnvRef.current.expandItem(newParentId, treeId)
      }

      dispatch(pageSlice.asyncActions.movePage(movedPageId, 0, newParentId, { fetchNavigation }))
    } else if (target.targetType === DROP_BETWEEN_ITEMS) {
      const newParentItemIndex = target.parentItem
      const newParentItem = treeData[newParentItemIndex]
      const targetChildIndex = target.childIndex

      // in the newParentItem children, find the element before the movedPageId
      const children = newParentItem.children || []
      const moveAfterPageId = targetChildIndex === 0 ? '0' : children[targetChildIndex - 1]
      const newParentId = newParentItemIndex === 'root' ? null : newParentItemIndex

      dispatch(pageSlice.asyncActions.movePage(movedPageId, moveAfterPageId, newParentId, { fetchNavigation }))
    }
  }

  return (
    <div className='PagesNavComplexTree flex-grow-0 flex-shrink-0' key={renderKey}>
      <ControlledTreeEnvironment
        items={treeData}
        getItemTitle={item => item.data}
        canReorderItems
        canDragAndDrop={canDrag}
        canDropOnFolder
        canDropOnNonFolder
        onDrop={movePage}
        onExpandItem={onExpandItem}
        onCollapseItem={onCollapseItem}
        ref={treeEnvRef}
        viewState={{
          [treeId]: {
            expandedItems,
          },
        }}
        defaultInteractionMode={InteractionMode.ClickArrowToExpand}
        {...PAGES_NAV_COMPLEX_TREE_RENDERERS}
      >
        <Tree
          treeId={treeId}
          rootItem='root'
        />
      </ControlledTreeEnvironment>
    </div>
  )
}

export default PagesNavComplexTree
