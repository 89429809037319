import React from 'react'
import { Link } from 'react-router-dom'
import { i18nFormat } from 'utils/i18nHelpers'

import BlueprintLink from 'components/admin/journeys/blueprintLink'

const EditEntityHeader = ({
  type,
  entity,
  entityTemplate,
  entityI18n,
  actionButtons = null,
}) => {
  const step = entity?.step
  const forUser = step?.forUser
  const forUserFullName = forUser?.preferredFullName
  const journeyBlueprint = step?.journey?.journeyBlueprint
  const entityTemplateName = entityTemplate?.stepTemplate?.name || step?.name
  const isCustomStep = step?.custom

  return (
    <header className='AdminHeader mb-0'>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className='mb-1'>{entityI18n(`edit_${type}_title`, { entityTemplateName, forUserFullName })}</h3>
        {actionButtons}
      </div>
      <div className='text-secondary mb-0'>
        {isCustomStep ? (
          i18nFormat(entityI18n(`edit_custom_${type}_description`),
            <BlueprintLink className='d-inline' journeyBlueprint={journeyBlueprint} />
          )
        ) : (
          i18nFormat(entityI18n(`edit_${type}_description`),
            <Link
              className='template-link'
              to={`/admin/journeys/${journeyBlueprint?.id}/${type}_templates/${entityTemplate?.id}`}
            >
              {entityTemplateName}
            </Link>,
            <BlueprintLink className='d-inline' journeyBlueprint={journeyBlueprint} />
          )
        )}
      </div>
    </header>
  )
}

export default EditEntityHeader
