import React from 'react'

import RichTextView from 'components/common/richTextView'
import UpdateTaskCompletionStatusButton from 'components/journeys/tasks/updateTaskCompletionStatusButton'
import classNames from 'classnames'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import TaskableTitle from 'components/journeys/taskables/taskableTitle'
import TaskableAssignedByLine from 'components/journeys/taskables/taskableAssignedByLine'
import TaskableDueDateAndForLine from 'components/journeys/taskables/taskableDueDateAndForLine'
import TaskableDetailTabsSection from 'components/journeys/taskables/taskableDetailTabsSection'
import { i18nPath } from 'utils/i18nHelpers'
import ToggleNotificationsButton from 'components/journeys/tasks/toggleNotificationsButton'

const I18N = i18nPath('views.journeys.taskable_detail')

const TaskDetail = ({
  task,
  className = '',
  hasTitle = false,
  onCompleteOrReopen = () => {},
  isPreview = false,
}) => {
  const currentUser = useCurrentUser()
  const step = task?.step
  const triggerAt = step?.triggerAt
  const forUser = step?.forUser
  const toUser = step?.toUserOrToCorrespondentUser
  const fromUser = step?.fromUserOrFromCorrespondentUser
  const fromCorrespondent = step?.fromCorrespondent

  if (_.isEmpty(task)) {
    return null
  }

  return (
    <div className={classNames('TaskDetail d-flex flex-column', className)}>
      {isPreview && <h1>{I18N('preview.task_preview')}</h1>}
      {hasTitle && <TaskableTitle taskable={task} />}
      <TaskableDueDateAndForLine taskable={task} toUser={toUser} currentUser={currentUser} forUser={forUser} />
      <RichTextView className='instructions-rich-view' record={task} recordType='task' richTextKey='instructions' />
      <TaskableAssignedByLine fromUser={fromUser} triggerAt={triggerAt} fromCorrespondent={fromCorrespondent} />
      <TaskableDetailTabsSection
        taskable={task}
        taskableType='task'
        isPreview={isPreview}
      />
      <div className='d-flex justify-content-end mt-3'>
        <ToggleNotificationsButton taskable={task} />
        <UpdateTaskCompletionStatusButton task={task} onCompleteOrReopen={onCompleteOrReopen} isPreview={isPreview} />
      </div>
    </div>
  )
}

export default TaskDetail
