import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ButtonSmallNarrow } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { SizeEnum } from 'types/layout/layout'
import classNames from 'classnames'
import LayoutCollectionDropdown from 'components/admin/layout/layoutCollectionDropdown'
import EditableContent from 'components/common/editable_content'

const I18N = i18nPath('views.admin.home_editor')

const ViewModeButton = ({ isSelected, onClick, iconPath }) => (
  <div className={classNames('ViewModeButton', { isSelected })} onClick={onClick}>
    <CdnSvg src={iconPath} />
  </div>
)

const LayoutCollectionNavbar = ({
  layoutCollection,
  updateLayoutCollection,
  onSave,
  isSaving,
  areChangesPresent,
  selectedSize,
  setSelectedSize,
  openAddWidgetSidebar,
}) => {
  const history = useHistory()
  const location = useLocation()

  const isPreboardingHomepage = location.pathname.startsWith('/admin/preboarding_experience')
  const exitUrl = isPreboardingHomepage ? '/admin/preboarding_experience' : '/admin/homepage_design'

  return (
    <div className='LayoutCollectionNavbar replace-navbar d-flex align-items-center justify-content-between px-3'>
      <Link to={exitUrl}>
        <ButtonSmallNarrow variant='secondary'>
          {I18NCommon('exit')}
        </ButtonSmallNarrow>
      </Link>
      {layoutCollection && (
        <EditableContent
          canEdit
          displayElement={(
            <h6 className='mb-0'>
              {I18N('title', { name: layoutCollection.name })}
            </h6>
          )}
          inputElement={(
            <input />
          )}
          valueToEdit={layoutCollection.name}
          saveContent={name => updateLayoutCollection({ id: layoutCollection.id, name })}
        />
      )}
      <div className='d-flex align-items-center'>
        <div className='mr-2 text-secondary'>{I18NCommon('view')}:</div>
        <ViewModeButton
          isSelected={selectedSize === SizeEnum.Large}
          onClick={() => setSelectedSize(SizeEnum.Large)}
          iconPath='/images/computerIcon.svg'
        />
        <ViewModeButton
          isSelected={selectedSize === SizeEnum.Medium}
          onClick={() => setSelectedSize(SizeEnum.Medium)}
          iconPath='/images/tabletIcon.svg'
        />
        <ViewModeButton
          isSelected={selectedSize === SizeEnum.Small}
          onClick={() => setSelectedSize(SizeEnum.Small)}
          iconPath='/images/mobilePhoneIcon.svg'
        />
        <ButtonSmallNarrow
          variant='secondary'
          onClick={openAddWidgetSidebar}
        >
          + {I18N('widget')}
        </ButtonSmallNarrow>
        <ButtonSmallNarrow
          className='mx-2'
          onClick={onSave}
          disabled={isSaving || !areChangesPresent}
          showLoadingSpinner={isSaving}
        >
          {I18NCommon('update')}
        </ButtonSmallNarrow>
        <LayoutCollectionDropdown
          layoutCollection={layoutCollection}
          onDelete={() => history.push(exitUrl)}
          updateLayoutCollection={updateLayoutCollection}
        />
      </div>
    </div>
  )
}

export default LayoutCollectionNavbar
