import React, { useState, useEffect } from 'react'
import { useReactMediaRecorder } from 'react-media-recorder'
import SVG from 'react-inlinesvg'
import moment from 'moment'

import { i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import { ButtonSmall, ButtonSecondarySmall, ButtonSmallNarrow } from 'components/common/buttons'
import CheckboxToggleButtonGroup from 'components/common/buttons/checkboxToggleButtonGroup'

const I18N = i18nPath('views.profile.office_schedule.modal')
const I18NCommon = i18nPath('views.common')

const ModalHeader = () => (
  <div className='mb-4'>
    <h3 className='d-flex align-items-center'>{I18N('title')}</h3>
    <div className='text-secondary'>{I18N('explanation')}</div>
  </div>
)

const ModalFooter = ({ toggle, save, isSaving }) => (
  <div className='d-flex justify-content-end align-items-center mt-4'>
    <a className='text-muted-dark mr-4' onClick={toggle}>{I18NCommon('cancel')}</a>
    <ButtonSmallNarrow onClick={save} showLoadingSpinner={isSaving}>{I18NCommon('save')}</ButtonSmallNarrow>
  </div>
)

const OfficeScheduleModal = ({
  user,
  isOpen,
  toggle,
  update,
}) => {
  const [workingDaysInOffice, setWorkingDaysInOffice] = useState(user?.workingDaysInOffice || [])
  const [workingDaysRemote, setWorkingDaysRemote] = useState(user?.workingDaysRemote || [])
  const [isSaving, setIsSaving] = useState(false)

  const save = () => {
    // after calling update, we will use the Promise to
    // trigger the toggle function to close the modal
    update(
      workingDaysInOffice,
      workingDaysRemote
    ).then(
      () => toggle()
    )
  }

  const daysOfWeek = I18n.t('date.abbr_day_names')
  const options = daysOfWeek.map((day, index) => ({ label: day, value: index }))
  const onChangeOfficeDays = days => setWorkingDaysInOffice(days)
  const onChangeRemoteDays = days => setWorkingDaysRemote(days)

  return (
    <Modal className='OfficeScheduleModal' visible={isOpen} toggle={toggle}>
      <ModalHeader />
      <div className='d-flex flex-row justify-content-between' />
      <CheckboxToggleButtonGroup
        label={I18N('when_are_you_in_the_office')}
        options={options}
        currentValue={workingDaysInOffice}
        onChange={onChangeOfficeDays}
        className='mt-3'
      />
      <CheckboxToggleButtonGroup
        label={I18N('when_are_you_working_remotely')}
        options={options}
        currentValue={workingDaysRemote}
        onChange={onChangeRemoteDays}
        className='mt-5 mb-5'
      />
      <ModalFooter toggle={toggle} save={save} />
    </Modal>
  )
}

export default OfficeScheduleModal
