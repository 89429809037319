import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import appSignal from 'services/appSignal'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { checkForError } from 'utils/errorHandling'
import { showToastMessage } from 'redux/slices/toasts'

export const initialState = {
  journeyBlueprintIds: [],
  activeCount: 0,
  availableTemplates: [],
  simpleJourneyIds: [],
  meta: {
    ...defaultMeta,
    isCopying: false,
    isLoadingActiveCount: false,
    isLoadingAvailableTemplates: false,
    isLoadingSimpleJourneys: false,
  },
}

const journeyBlueprintSlice = createSlice({
  name: 'journeyBlueprints',
  initialState,
  reducers: {
    ...defaultActions,
    setJourneyBlueprintIds(state, action) {
      state.journeyBlueprintIds = action.payload
    },

    isCopying: (state, action) => {
      state.meta.isCopying = action.payload
    },

    isLoadingActiveCount: (state, action) => {
      state.meta.isLoadingActiveCount = action.payload
    },

    setActiveCount: (state, action) => {
      state.activeCount = action.payload
    },

    isLoadingAvailableTemplates: (state, action) => {
      state.meta.isLoadingAvailableTemplates = action.payload
    },

    setAvailableTemplates: (state, action) => {
      state.availableTemplates = action.payload
    },

    isLoadingSimpleJourneys: (state, action) => {
      state.meta.isLoadingSimpleJourneys = action.payload
    },
  },
})

const asyncActions = {
  admin: {
    fetchAll: (params = {}) => async (dispatch) => {
      dispatch(journeyBlueprintSlice.actions.isLoading(true))

      try {
        const response = await API.admin.journey.journeyBlueprints.fetchAll(params)

        const journeyBlueprintIds = response.data.data.map(journeyBlueprint => journeyBlueprint.id)
        dispatch(entitySlice.actions.update({ data: response.data }))
        dispatch(journeyBlueprintSlice.actions.setJourneyBlueprintIds(journeyBlueprintIds))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(journeyBlueprintSlice.actions.isLoading(false))
      }
    },

    fetch: (journeyBlueprintId, params = {}) => async (dispatch) => {
      dispatch(journeyBlueprintSlice.actions.isLoading(true))
      dispatch(journeyBlueprintSlice.actions.isNotFound(false))

      try {
        const response = await API.admin.journey.journeyBlueprints.fetch(journeyBlueprintId, params)

        dispatch(entitySlice.actions.add({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        dispatch(journeyBlueprintSlice.actions.isNotFound(true))
      } finally {
        dispatch(journeyBlueprintSlice.actions.isLoading(false))
      }
    },

    create: (journeyBlueprint, onSuccess = () => null) => async (dispatch) => {
      try {
        dispatch(journeyBlueprintSlice.actions.isSaving(true))

        const response = await API.admin.journey.journeyBlueprints.create(journeyBlueprint)

        dispatch(entitySlice.actions.add({ data: response.data }))
        const journeyBlueprintId = response.data.data.id
        onSuccess(journeyBlueprintId)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(journeyBlueprintSlice.actions.setError(error))
      } finally {
        dispatch(journeyBlueprintSlice.actions.isSaving(false))
      }
    },

    copy: (id, params = {}, onSuccess = () => null) => async (dispatch) => {
      dispatch(journeyBlueprintSlice.actions.isCopying(true))

      try {
        const response = await API.admin.journey.journeyBlueprints.copy(id, params)
        const journeyBlueprintId = response.data.data.id
        dispatch(entitySlice.actions.update({ data: response.data }))
        onSuccess(journeyBlueprintId)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(journeyBlueprintSlice.actions.setError(error))
      } finally {
        dispatch(journeyBlueprintSlice.actions.isCopying(false))
      }
    },

    update: (journeyBlueprint, onSuccess = () => null) => async (dispatch) => {
      try {
        dispatch(journeyBlueprintSlice.actions.isSaving(true))

        // eslint-disable-next-line max-len
        const response = await API.admin.journey.journeyBlueprints.update(journeyBlueprint)
        dispatch(entitySlice.actions.update({ data: response.data }))
        const journeyBlueprintId = response.data.data.id
        onSuccess(journeyBlueprintId)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(journeyBlueprintSlice.actions.setError(error))
        dispatch(showToastMessage({ message: error?.message, type: 'error' }))
      } finally {
        dispatch(journeyBlueprintSlice.actions.isSaving(false))
      }
    },
    delete: (journeyBlueprintId, onSuccess = () => null) => async (dispatch) => {
      try {
        const response = await API.admin.journey.journeyBlueprints.destroy({ id: journeyBlueprintId })
        dispatch(entitySlice.actions.remove({ data: response.data }))
        onSuccess()
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(journeyBlueprintSlice.actions.setError(error))
      }
    },
    launch: (journeyBlueprint, onSuccess = () => null) => async (dispatch) => {
      try {
        dispatch(journeyBlueprintSlice.actions.isSaving(true))
        const response = await API.admin.journey.journeyBlueprints.launch(journeyBlueprint.id)
        dispatch(entitySlice.actions.update({ data: response.data }))
        const journeyBlueprintId = response.data.data.id
        onSuccess(journeyBlueprintId)
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(journeyBlueprintSlice.actions.setError(error))
      } finally {
        dispatch(journeyBlueprintSlice.actions.isSaving(false))
      }
    },
    fetchActiveBlueprintsCount: () => async (dispatch) => {
      dispatch(journeyBlueprintSlice.actions.isLoadingActiveCount(true))

      try {
        const response = await API.admin.journey.journeyBlueprints.fetchActiveBlueprintsCount()

        dispatch(journeyBlueprintSlice.actions.setActiveCount(response.data.activeCount))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(journeyBlueprintSlice.actions.isLoadingActiveCount(false))
      }
    },
    fetchJourneyBlueprintAvailableTemplates: () => async (dispatch) => {
      dispatch(journeyBlueprintSlice.actions.isLoadingAvailableTemplates(true))

      try {
        const response = await API.admin.journey.journeyBlueprints.fetchJourneyBlueprintAvailableTemplates()

        dispatch(journeyBlueprintSlice.actions.setAvailableTemplates(response.data.availableTemplates))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(journeyBlueprintSlice.actions.isLoadingAvailableTemplates(false))
      }
    },
    fetchSimpleJourneys: journeyBlueprintId => async (dispatch) => {
      dispatch(journeyBlueprintSlice.actions.isLoadingSimpleJourneys(true))

      try {
        const response = await API.admin.journey.journeyBlueprints.fetchSimpleJourneys(journeyBlueprintId)

        dispatch(entitySlice.actions.update({ data: response.data }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
      } finally {
        dispatch(journeyBlueprintSlice.actions.isLoadingSimpleJourneys(false))
      }
    },
  },
}


const selectors = {
  getJourneyBlueprints: () => state => state.journeyBlueprints.journeyBlueprintIds.map(id => build(state.entities, 'journeyBlueprint', id)) || [],
  getJourneyBlueprintIds: () => state => state.journeyBlueprints.journeyBlueprintIds || [],
  getJourneyBlueprint: id => state => build(state.entities, 'journeyBlueprint', id),
  getMetaData: () => state => state.journeyBlueprints.meta,
  getActiveJourneyBlueprintsCount: () => state => state.journeyBlueprints.activeCount || 0,
  getAvailableTemplates: () => state => state.journeyBlueprints.availableTemplates || [],
  getSimpleJourneys: journeyBlueprintId => (state) => {
    const journeys = build(state.entities, 'simpleJourney') || []

    return journeys.filter(simpleJourney => simpleJourney?.journeyBlueprintId === journeyBlueprintId)
  },
}

export default {
  ...journeyBlueprintSlice,
  selectors,
  asyncActions,
}
