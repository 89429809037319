import React from 'react'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import CheckIcon from 'components/icons/checkIcon'
import HoverAndClickPopover from 'components/common/hoverAndClickPopover'
import TaskableDetailsButtonAndModal from 'components/journeys/modals/taskableDetailsButtonAndModal'
import EmployeeOrCorrespondentLink from 'components/journeys/employeeOrCorrespondentLink'
import classNames from 'classnames'
import { Button } from 'components/common/buttons'
import { Link } from 'react-router-dom'
import { StepType, TaskStepType } from 'types/journeys/step'
import StepTriggerNowLink from 'components/admin/journeys/stepTriggerNowLink'
import StepCreateLink from 'components/admin/journeys/stepCreateLink'
import JourneyType from 'types/journeys/journey'
import StepTemplateType from 'types/journeys/stepTemplate'
import moment from 'moment'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.journeys.steps.status_icon')

const TASK_TYPE = 'Journey::Task'
const INTRODUCTION_TYPE = 'Journey::Introduction'
const TASKABLES = [TASK_TYPE, INTRODUCTION_TYPE]
const UPCOMING = 'upcoming'
const COMPLETED = 'completed'
const PENDING = 'pending'
const OVERDUE = 'overdue'
const DUE_TODAY = 'due_today'

interface StepPopoverProps {
  step?: StepType,
  journey?: JourneyType,
  stepTemplate?: StepTemplateType
}

interface TaskablePopoverProps {
  step?: TaskStepType
}

interface StepCompletionStatusIconAndTooltipProps {
  step?: StepType,
  journey?: JourneyType,
  stepTemplate?: StepTemplateType
}


const StepPopover = ({
  step,
  journey,
  stepTemplate,
}: StepPopoverProps) => {
  const stepCreated = !!step
  const tooltipText = stepCreated ? I18N(`tooltip.${step.stepTypeName}.${step.completionStatus}`) : I18N('tooltip.not_created')
  const hasErrorState = step?.error
  const willTriggerInNextMinute = step?.triggerAt && moment(step?.triggerAt).isBefore(moment().add(1, 'minute'))
  const isUpcoming = step?.completionStatus === UPCOMING && !willTriggerInNextMinute

  return (
    <div className='StepPopover p-2 d-flex flex-column'>
      <div className='d-flex align-items-center'>
        <div>
          {hasErrorState && (<div className='text-error mb-1 text-small'>{I18N('tooltip.action_required')}</div>)}
          <div className='text-white text-small'>{tooltipText}</div>
        </div>

        {step && (
          <a href={step?.adminDisplayPath}
            target='_blank'
            rel='noopener noreferrer'
          >
            <CdnSvg src='/images/externalLinkIcon.svg' className='ml-2' />
          </a>
        )}
      </div>

      {(hasErrorState || isUpcoming) && (
        <StepTriggerNowLink
          className='text-white mt-2 text-small'
          step={step}
          linkText={I18N(hasErrorState ? 'trigger_again' : 'trigger_now')}
        />
      )}

      {!stepCreated && (
        <StepCreateLink
          className='text-white mt-2 text-small'
          stepTemplate={stepTemplate}
          journey={journey}
        />
      )}
    </div>
  )
}

const TaskablePopover = ({
  step,
}: TaskablePopoverProps) => {
  const entity = step?.entity
  const taskableName = entity?.name
  const dueAt = entity?.dueAt
  const toUser = step?.toUser
  const completedBy = entity?.completedBy
  const completedAt = entity?.completedAt
  const completed = step?.completed
  const overdue = step?.completionStatus === OVERDUE
  const isCompletedSurveyTask = entity?.surveyTask && completed
  const useSurveyResponsePath = isCompletedSurveyTask && step?.surveyResponseDisplayPath
  const displayPath = useSurveyResponsePath ? step?.surveyResponseDisplayPath : step?.adminDisplayPath

  return (
    <div className='TaskablePopover p-3 d-flex flex-column align-items-start justify-content-center'>
      <div className='d-flex justify-content-between w-100'>
        <div className='font-weight-500 truncate-text-at-2-lines'>{taskableName}</div>
        <a href={step?.adminDisplayPath}
          target='_blank'
          rel='noopener noreferrer'
        >
          <CdnSvg src='/images/externalLinkIcon.svg' className='ml-2' />
        </a>
      </div>
      <div className='taskable-information-grid mt-3 mb-3 align-items-center'>
        <span className='text-small text-secondary d-inline-flex align-items-center'>
          {I18N('taskable_tooltip.assigned_to')}
        </span>
        <span className='text-small text-secondary d-inline-flex align-items-center'>
          {I18N('taskable_tooltip.due_at')}
        </span>
        <EmployeeOrCorrespondentLink
          className='ml-1'
          user={toUser}
          textClassName='text-small'
          avatarClassName='mr-1'
          linkToAdmin={false}
        />
        <span className={classNames('text-small', { overdue })}>{i18nMoment(dueAt).format('ll')}</span>
        {completed && (
          <>
            <span className='text-small text-secondary d-inline-flex align-items-center'>
              {I18N('taskable_tooltip.completed_by')}
            </span>
            <span className='text-small text-secondary d-inline-flex align-items-center'>
              {I18N('taskable_tooltip.completed_at')}
            </span>
            <EmployeeOrCorrespondentLink
              className='ml-1'
              user={completedBy}
              textClassName='text-small'
              avatarClassName='mr-1'
              linkToAdmin={false}
            />
            <span className='text-small'>{i18nMoment(completedAt).format('ll')}</span>
          </>
        )}
      </div>
      <Link
        to={displayPath}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Button
          className='text-white'
        >
          {isCompletedSurveyTask ? I18N('taskable_tooltip.view_survey_results') : I18N('taskable_tooltip.view_details')}
        </Button>
      </Link>
    </div>
  )
}


const StepCompletionStatusIconAndTooltip = ({
  step,
  journey,
  stepTemplate,
}: StepCompletionStatusIconAndTooltipProps) => {
  const completionStatus = step?.completionStatus
  const entityType = step?.entityType
  const isTaskable = TASKABLES.includes(entityType!)

  const getIcon = () => {
    if (completionStatus === UPCOMING) {
      return <CdnSvg src='/images/clockIcon.svg' className='ClockIcon' />
    }

    if (step?.error) {
      return <span className='red-exclamation text-error'>!</span>
    }

    if (isTaskable) {
      return (
        <>
          <div className='d-flex flex-row'>
            <TaskableDetailsButtonAndModal
              showCompleteCheckbox={true}
              showTaskableName={false}
              className='justify-content-center'
              allowReopening
              fetchTaskableOnClick
              step={step}
            />
            {completionStatus === OVERDUE && <span className='ml-1 red-exclamation text-error'>!</span>}
          </div>
        </>
      )
    }

    if (step?.completed) {
      return (
        <CheckIcon className='CheckIcon' height='18px' width='18px' fillColor='#54c776' />
      )
    }

    return <span className='text-secondary'>-</span>
  }

  const target = <div className='StepCompletionStatusIconAndTooltip'>{getIcon()}</div>
  const useTaskablePopover = isTaskable && [PENDING, COMPLETED, OVERDUE, DUE_TODAY].includes(completionStatus!)

  return (
    <div className='StepCompletionStatusIconAndTooltip d-flex align-items-center justify-content-center' data-testid={`cy-step-completion-icon-${step?.id}`}>
      <HoverAndClickPopover target={target} id={step?.id} placement='bottom' popoverClassName={useTaskablePopover ? 'TaskablePopover' : 'StepPopover'}>
        {
          useTaskablePopover ? (
            <TaskablePopover step={step} />
          ) : (
            <StepPopover
              step={step}
              journey={journey}
              stepTemplate={stepTemplate}
            />
          )
        }
      </HoverAndClickPopover>
    </div>
  )
}

export default StepCompletionStatusIconAndTooltip
