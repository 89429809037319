import React from 'react'

import SuggestedIcon from 'components/common/suggestedIcon'
import cleanUrl from 'utils/cleanUrl'
import AppType from 'types/app'
import ClearyInternalSearchResultsTemplate from './clearyInternalSearchResultsTemplate'
import SearchResultTemplate from './searchResultTemplate'

export const AppResult = ({ result }) => {
  const { app, highlights } : { app: AppType, highlights: Partial<Pick<AppType, 'name' | 'url'>> } = result
  const { name, url } = { ...app, ...highlights }

  // display path must use the app.url to remove the <em> tags
  return (
    <SearchResultTemplate
      displayPath={app.url}
      image={(
        <SuggestedIcon
          name={app.name}
          url={app.url}
          iconUrl={app.iconUrl}
          height={32}
          width={32}
        />
      )}
      title={name}
      subtitle={cleanUrl(url)}
      result={result}
      openNewTab
    />
  )
}

const AppResults = ({ showLabel = true }) => (
  <ClearyInternalSearchResultsTemplate
    showLabel={showLabel}
    label='apps'
    type='appLauncherApp'
    queryType='AppLauncher::App'
    extraTypes={['app']}
    searchResultToTemplateMap={result => <AppResult result={result} />}
  />
)

export default AppResults
