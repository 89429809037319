import React from 'react'

import Event from 'components/common/calendar/event'

const CalendarEventsList = ({ events, emptyCalendarText }) => {
  if (events.length === 0) return <div className='text-secondary text-center'>{emptyCalendarText}</div>

  return (
    <div className='CalendarEventsList pr-2'>
      {events.map((event, i) => (
        <Event key={i} event={event} />
      ))}
    </div>
  )
}

export default CalendarEventsList
