import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.golinks_list.item_dropdown')

type Props = {
  goLink: {
    id: string
    name: string
  }
}

const GoLinkDropdown = ({ goLink }: Props) => (
  <DropdownMenuContainer className='GoLinkDropdown default-dropdown' menuType='kebab' closeMenuOnItemClick>
    <section>
      <CardDropdownMenuItem
        link={`/golinks/${goLink.name}`}
        primaryText={I18N('edit_golink')}
        icon={<CdnSvg className='mr-2' src='/images/pencilIcon.svg' />}
      />
      <CardDropdownMenuItem
        link={`/golinks/${goLink.name}?create_alias=true`}
        primaryText={I18N('create_alias')}
        icon={<CdnSvg className='mr-2 GoLinksList_CreateAlias' src='/images/hierarchyIcon.svg' />}
      />
    </section>
  </DropdownMenuContainer>
)

export default GoLinkDropdown
