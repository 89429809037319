import React from 'react'
import ContentEditable from 'react-contenteditable'

interface Props {
  cssOverrides: string
  onChange: (value: string) => void
}

const StyleSheetEditor = ({ cssOverrides, onChange }: Props) => {
  const handleChange = (ev) => {
    const { value } = ev.target

    onChange(value)
  }

  return (
    <ContentEditable
      className='bordered bg-white monospaced ws-pre-wrap break-word p-1'
      html={cssOverrides || ''}
      disabled={false}
      onChange={handleChange}
    />
  )
}

export default StyleSheetEditor
