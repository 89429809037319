import classNames from 'classnames'
import { ExternalSyncImportState } from 'components/admin/integrations/external_sync/types'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.integrations_page.external_knowledge_section.syncing_indicator')

type Props = {
  importedDocumentsCount: number
  totalDocumentsCount: number
  state: ExternalSyncImportState
}

const SyncingIndicator = ({ importedDocumentsCount, totalDocumentsCount, state }: Props) => {
  if (state === ExternalSyncImportState.Idle) return null

  const iconPath = state === ExternalSyncImportState.Importing ? '/images/syncIcon.svg' : '/images/checkIcon.svg'

  const getSyncingText = () => {
    if (state === ExternalSyncImportState.Imported) {
      return I18N('imported', { count: importedDocumentsCount! })
    }

    if (importedDocumentsCount === 0 || totalDocumentsCount === 0) {
      return I18N('syncing')
    }

    return I18N('syncing_with_count', {
      imported: importedDocumentsCount,
      count: totalDocumentsCount,
    })
  }

  return (
    <div className={classNames('d-flex flex-row align-items-center ml-4 mb-1 SyncingIndicator', {
      importing: state === ExternalSyncImportState.Importing,
      imported: state === ExternalSyncImportState.Imported,
    })}
    >
      <div className='mr-2'><CdnSvg src={iconPath} /></div>
      <div className='font-weight-300'>{getSyncingText()}</div>
    </div>
  )
}


export default SyncingIndicator
