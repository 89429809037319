import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import pageWorkspaceSlice from 'redux/slices/pageWorkspaces'
import PageWorkspaceType from 'types/pageWorkspace'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import EmployeeSearch from 'components/form_fields/employeeSearch'

const I18N = i18nPath('views.pages.workspaces.change_workspace_owners_modal')

interface Props {
  workspaceId: string
  visible: boolean
  toggle: () => void
}

const ChangeWorkspaceOwnersModal = ({
  workspaceId,
  visible = false,
  toggle = () => { },
}: Props) => {
  const dispatch = useDispatch()

  const { isSaving, isLoading } = useSelector(pageWorkspaceSlice.selectors.getMetaData())
  const workspace = useSelector(pageWorkspaceSlice.selectors.getWorkspace(workspaceId))
  const [workingCopy, setWorkingCopy] = useState<PageWorkspaceType>(workspace)

  const updateWorkingCopy = (changes: Partial<PageWorkspaceType>) => {
    setWorkingCopy({ ...workingCopy, ...changes })
  }

  const onEmployeeSearchChange = (employees: any) => {
    updateWorkingCopy({ owners: employees, ownerIds: employees.map((e: any) => e.id) })
  }

  const updateWorkspace = () => {
    dispatch(pageWorkspaceSlice.asyncActions.update(workingCopy, toggle))
  }

  useEffect(() => {
    if (visible && workspaceId) {
      dispatch(pageWorkspaceSlice.asyncActions.fetchWorkspace(workspaceId))
    }
  }, [visible, workspaceId])

  useEffect(() => {
    if (workspace) {
      setWorkingCopy(workspace)
    }
  }, [JSON.stringify(workspace?.owners)])

  return (
    <Modal
      className='ChangeWorkspaceOwnersModal'
      visible={visible}
      size='md'
      modalContentClassName='container d-flex flex-column w-100 h-100'
      toggle={toggle}
    >
      <>
        {isLoading || !workingCopy ? (
          <div className='h-100 d-flex align-items-center justify-content-center'>
            <CirclesLoadingIndicator />
          </div>
        ) : (
          <>
            <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
              <h3 className='mb-0'>{I18N('title', { workspaceName: workspace.title })}</h3>
              <p className='text-secondary m-0 mt-1'>{I18N('subtitle')}</p>
            </div>
            <EmployeeSearch
              className='mb-3'
              onChange={onEmployeeSearchChange}
              selectedEmployees={workingCopy.owners}
              isMulti
            />
            <div className='d-flex justify-content-end align-items-center'>
              <>
                <CancelButton
                  onClick={toggle}
                />
                <Button
                  onClick={updateWorkspace}
                  disabled={isSaving}
                  showLoadingSpinner={isSaving}
                >
                  {I18NCommon('save_changes')}
                </Button>
              </>
            </div>
          </>
        )}
      </>
    </Modal>
  )
}

export default ChangeWorkspaceOwnersModal
