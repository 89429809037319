import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import Stat from 'pages/admin/articles/stats/Stat'
import { safePercentage } from 'pages/admin/articles/stats/helpers'

const I18N = i18nPath('views.admin.article_stats')

interface Props {
  totalAudience: number
  videoAudience: number
  videoFinishedAudience: number
}

const Stats = ({
  totalAudience, videoAudience, videoFinishedAudience,
}: Props) => (
  <>
    <h5 className='inner-title'>{I18N('videos.engagement')}</h5>

    <Stat
      value={safePercentage(videoAudience, totalAudience, 0)}
      label={I18N('videos.started_video')}
      iconName='personsIcon'
      tooltip={I18N('videos.started_video_tooltip', { started: videoAudience, total: totalAudience })}
      border
      hoverable
    />

    <Stat
      value={safePercentage(videoFinishedAudience, videoAudience, 0)}
      label={I18N('videos.finished_video')}
      iconName='checkMarkIcon'
      tooltip={I18N('videos.finished_video_tooltip', { finished: videoFinishedAudience, total: videoAudience })}
      border
      hoverable
    />
  </>
)

export default Stats
