import useApi from 'components/common/hooks/useApi'
import { useEffect } from 'react'
import API from 'services/api'

const useMyPrompts = ({ showAskAiModal }: { showAskAiModal: boolean }) => {
  const [fetchMyPrompts, { data, isLoading, isLoaded }] = useApi(API.ai.chats.promptCollections.fetchMyPrompts)

  useEffect(() => {
    if (showAskAiModal && !isLoaded) {
      fetchMyPrompts()
    }
  }, [showAskAiModal])

  return {
    data,
    isLoading,
  }
}

export default useMyPrompts
