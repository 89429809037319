import React, { useEffect, useState } from 'react'

import StepVariablesDropdown from 'components/admin/journeys/stepVariablesDropdown'
import RichTextView from 'components/common/richTextView'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { i18nPath } from 'utils/i18nHelpers'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import isHtmlStringEmpty from 'utils/isHtmlStringEmpty'
import CdnSvg from 'components/common/cdnSvg'


const I18N = i18nPath('views.admin.journeys.communication_templates.content.news_feed')

const NewsFeedContent = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
  journeyBlueprint,
}) => {
  const [editorKey, setEditorKey] = useState(0)
  const showLoadEmailContent = workingCopy.sendToEmail && !isHtmlStringEmpty(workingCopy.content) && !isDisabled

  const [copyContent, { isLoading: isCopyingContent }] = useApi(API.admin.journey.communicationTemplates.copyContent, {
    onSuccess: ({ newContent }) => {
      updateWorkingCopy({ feedContent: newContent, feedTitle: workingCopy.subject })
      setEditorKey(editorKey => editorKey + 1)
    },
  })

  const copyContentFromEmail = () => copyContent(workingCopy.content, workingCopy.contentId)

  useEffect(() => {
    if (isHtmlStringEmpty(workingCopy.feedContent) && !workingCopy.feedTitle) {
      copyContentFromEmail()
    }
  }, [])

  return (
    <div className='NewsFeedContent'>
      <div className='form-group'>
        <div className='d-flex justify-content-between align-items-center'>
          <label className='required-form-label'>{I18N('title')}</label>
          {showLoadEmailContent && (
            <div className='d-flex align-items-center pointer link-color' onClick={copyContentFromEmail}>
              <CdnSvg src='/images/syncIcon.svg' className='SyncIcon' />
              <span className='ml-1 text-small'>{I18N('load_email_content')}</span>
            </div>
          )}
        </div>
        <input
          name='title'
          placeholder={I18N('title')}
          value={workingCopy.feedTitle}
          disabled={isDisabled || isCopyingContent}
          onChange={({ target }) => updateWorkingCopy({ feedTitle: target.value })}
        />
      </div>

      <div>
        <label className='required-form-label'>{I18N('body')}</label>
        {isDisabled || isCopyingContent ? (
          <RichTextView className='content-rich-view' record={workingCopy} recordType='communication' richTextKey='feedContent' />
        ) : (
          <>
            <TiptapEditor
              key={editorKey}
              className='TiptapEditor straight-borders'
              onChange={value => updateWorkingCopy({ feedContent: value })}
              html={workingCopy.feedContent}
              configuration={{
                placeholder: I18N('body_placeholder'),
                userMentionsEnabled: false,
                videosEnabled: true,
              }}
              editorContentClassName='p-3'
            />

            <StepVariablesDropdown journeyBlueprint={journeyBlueprint} />
          </>
        )}
      </div>
    </div>
  )
}

export default NewsFeedContent
