import CdnSvg from 'components/common/cdnSvg'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.integrations_page.external_knowledge_section.initial_syncing_state')


const InitialSyncingState: React.FC = () => (
  <div className='InitialSyncingState text-center my-5 py-5 white-card'>
    <CdnSvg src='/images/illustrations/manWaitingDocuments.svg' className='mt-5' />

    <h2 className='mt-4'>{I18N('title')}</h2>
    <p className='mb-4'>{I18N('description')}</p>
  </div>
)

export default InitialSyncingState
