import React, { useState } from 'react'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'components/common/buttons'
import DeleteModal from 'components/common/modals/deleteModal'
import managedGroupSlice from 'redux/slices/managedGroups'
import { useHistory } from 'react-router-dom'

const I18N = i18nPath('views.admin.managed_groups.delete_modal')

const DeleteManagedGroupButtonAndModal = ({
  managedGroup = null,
  className = '',
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const groupName = managedGroup?.name || ''

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isSaving } = useSelector(managedGroupSlice.selectors.getMetaData())

  const onSuccess = () => {
    history.push('/admin/managed_groups')
  }

  const onDelete = () => {
    dispatch(managedGroupSlice.asyncActions.admin.deleteManagedGroup(managedGroup, onSuccess))
  }

  return (
    <div className={className}>
      <Button
        variant='secondary-danger'
        onClick={() => setIsModalOpen(true)}
      >
        {I18NCommon('delete')}
      </Button>
      <DeleteModal
        deleteText={I18N('delete_managed_group_title', { groupName })}
        deleteSecondaryText={I18N('delete_secondary_text')}
        deleteConfirmText={I18N('delete_confirm')}
        showDeleteModal={isModalOpen}
        closeDeleteModal={() => setIsModalOpen(false)}
        deleteConfirm={onDelete}
        isDeleting={isSaving}
      />
    </div>
  )
}

export default DeleteManagedGroupButtonAndModal
