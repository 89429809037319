import { ExternalSyncDocument } from 'components/admin/integrations/external_sync/types'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import ConfirmationModal from 'components/common/modals/confirmationModal'
import React, { useState } from 'react'

import { I18NCommon } from 'utils/i18nHelpers'

type Props = {
  document: ExternalSyncDocument
  onDelete: (documentId: string) => void
}

const TableDropdown: React.FC<Props> = ({ document, onDelete }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  return (
    <>
      <DropdownMenuContainer className='default-dropdown'>
        <CardDropdownMenuItem
          primaryText={I18NCommon('delete')}
          onClick={() => setShowDeleteConfirmation(true)}
          className='Card-dropdownMenuItem--danger'
          icon={<CdnSvg src='/images/trashIcon.svg' className='mr-2' />}
        />
      </DropdownMenuContainer>

      {showDeleteConfirmation && (
        <ConfirmationModal
          visible={showDeleteConfirmation}
          toggle={() => setShowDeleteConfirmation(false)}
          onConfirmClick={() => {
            onDelete(document.id)
          }}
          title={I18NCommon('confirmation_modal.title')}
          description={I18NCommon('confirmation_modal.description')}
        />
      )}
    </>
  )
}

export default TableDropdown
