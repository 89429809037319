import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import BackButton from 'components/common/backButton'
import LinkForm from 'components/links/linkForm'
import { Button } from 'components/common/buttons'
import linkSlice, { defaultWorkingCopy } from 'redux/slices/links'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.golinks_editor')

const CreateGoLinkPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const location = useLocation()
  const { search } = location

  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy)

  useEffect(() => {
    let goLinkName = ''
    if (search && search.includes('name')) {
      const params = new URLSearchParams(search)
      goLinkName = params.get('name')
    }

    setWorkingCopy({
      ...workingCopy,
      name: goLinkName,
    })
  }, [])

  const saveGolink = () => {
    dispatch(linkSlice.asyncActions.createGoLink(workingCopy, history))
  }

  return (
    <div className='GoLinkEditor container my-4'>
      <div className='row mt-5'>
        <div className='col d-flex align-items-center mb-2'>
          <BackButton />
        </div>
      </div>

      <h1 className='font-weight-bold mb-3'>
        {I18N('create_golink')}
      </h1>

      <LinkForm link={workingCopy} setLink={setWorkingCopy} />

      <div className='row'>
        <div className='col-12'>
          <Button onClick={saveGolink}>
            {I18N('create_golink')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateGoLinkPage
