import { createSlice } from '@reduxjs/toolkit'

const stylesSlice = createSlice({
  name: 'style',
  initialState: {},
  reducers: {
    update(state, action) {
      Object.assign(state, action.payload)
    },
  },
})


export default stylesSlice
