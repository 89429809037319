import React from 'react'
import Modal from 'components/common/modal'

import { diffWords } from 'diff'
import { i18nPath } from 'utils/i18nHelpers'
import { _ } from 'core-js'
import RelatedUsersFromFailedNamelyImportErrors from './relatedUsersFromFailedNamelyImportErrors'

const I18N = i18nPath('views.admin.user_import_detail')

const RecordErrors = ({
  rowData, isNamelyImport, errorDetails, errorMessage,
}) => {
  const stringifyErrorMessages = (errorKey) => {
    const errorDetailsI18N = i18nPath('activerecord.errors.models.user.attributes')
    const errorKeyValues = errorDetails[errorKey]

    if (Array.isArray(errorKeyValues)) {
      const messages = errorKeyValues.map(value => errorDetailsI18N(`${_.snakeCase(errorKey)}.${value?.error}`))
      return messages.join(', ')
    } else {
      return JSON.stringify(errorKeyValues)
    }
  }

  const data = rowData.record || rowData.userAttributes

  if (!data) return null

  return (
    <div className='alert alert-danger'>
      <h4>{I18N('errors')}</h4>

      {errorDetails ? (
        <ul>
          {Object.keys(errorDetails).map((errorKey) => {
            const translatedErrorMessages = stringifyErrorMessages(errorKey)

            return (
              <li key={`form-error-${errorKey}`}>
                {errorKey}: {translatedErrorMessages}
              </li>
            )
          })}
        </ul>
      ) : (
        <span>{errorMessage}</span>
      )}
      {isNamelyImport && (
        <RelatedUsersFromFailedNamelyImportErrors
          rowData={rowData}
        />
      )}
    </div>
  )
}

const Diffs = ({ changes }) => {
  if (_.isEmpty(changes)) {
    return null
  }

  const diff = diffWords(...changes)

  return diff.map((part, index) => {
    if (part.added) {
      return <ins key={`diff-${index}`} className='mx-1 text-inserted'>{part.value}</ins>
    }

    if (part.removed) {
      return <del key={`diff-${index}`} className='mx-1 text-deleted'>{part.value}</del>
    }

    return <span key={`diff-${index}`}>{part.value}</span>
  })
}

const Record = ({ rowData, hasErrors }) => {
  const data = hasErrors ? rowData.record || rowData.userAttributes : rowData.changes

  if (!data) return null

  return (
    <div className='ResultRecord'>
      {Object.entries(data).map(([key, values]) => {
        let fieldValue = values

        // If data has changed it would come in an array with two items e.g ['before', 'after']
        if (Array.isArray(values) && values.length === 2) {
          // The diff package expects non-null values and only strings.
          // toString will cast booleans and numbers to strings.
          const changes = values.map(i => (_.isNull(i) ? 'empty' : i)).map(i => i.toString())
          fieldValue = <Diffs changes={changes} />
        }

        if (_.isBoolean(fieldValue)) {
          fieldValue = fieldValue.toString()
        }

        const labelKey = _.snakeCase(key)

        const fieldLabel = I18N(`user_${labelKey}`)

        return (
          <div key={key} className='RecordField d-flex'>
            <label className='ResultRecord_Label label m-0'>{fieldLabel}</label>
            <span className='ResultRecord_Value d-flex justify-content-start'>{fieldValue}</span>
          </div>
        )
      })}
    </div>
  )
}

const ResultRecordModal = ({
  visible, toggle, rowData, userImport,
}) => {
  // using _.get since the exact structure of the rowData can vary and may not include a 'record' or 'errors' key
  const errorDetails = _.get(rowData, 'errors.details')
  const errorMessage = _.get(rowData, 'errors.message')
  const hasErrors = !_.isEmpty(errorDetails) || errorMessage
  const isNamelyImport = userImport.type === 'UserImports::NamelyUserImport'

  if (!visible) return null

  return (
    <Modal
      className='ResultRecord-Modal'
      visible={visible}
      toggle={toggle}
      size='lg'
      header={rowData?.record?.email}
    >
      {hasErrors && (
        <RecordErrors
          rowData={rowData}
          errorDetails={errorDetails}
          errorMessage={errorMessage}
          isNamelyImport={isNamelyImport}
        />
      )}
      <Record
        rowData={rowData}
        hasErrors={hasErrors}
      />
    </Modal>
  )
}

export default ResultRecordModal
