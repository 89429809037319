import normalizeTargetingRules from 'utils/normalizeTargetingRules'

// eslint-disable-next-line import/prefer-default-export
export const buildArticleTemplatePayload = (workingCopy) => {
  const payload = _.pick(workingCopy, [
    'id',
    'title',
    'cardContent',
    'content',
    'draft',
    'commentable',
    'articleTypeId',
    'categoryIds',
    'categoriesAttributes',
    'restrictAccess',
    'displayAuthorText',
    'authorIds',
    'tagIds',
    'tagsAttributes',
    'notificationChannelOverrides',
    'requireAcknowledgement',
    'alwaysShowInFeed',
    'targetingRules',
    'notificationChannelIds',
    'copyAttachments',
    'articleId',
    'baseTemplate',
    'notificationPreference',
    'groupsUsingTemplateAsDefaultIds',
  ])

  if (workingCopy.articleType) {
    payload.articleTypeId = workingCopy.articleType.id
  }

  if (workingCopy.categories) {
    // eslint-disable-next-line no-underscore-dangle
    payload.categoryIds = workingCopy.categories.filter(category => !category.__isNew__).map(category => category.id)
    // eslint-disable-next-line no-underscore-dangle
    payload.categoriesAttributes = workingCopy.categories.filter(category => category.__isNew__).map(category => ({
      name: category.name,
    }))
  }

  if (workingCopy.groupsUsingTemplateAsDefault) {
    payload.groupsUsingTemplateAsDefaultIds = workingCopy.groupsUsingTemplateAsDefault.map(group => group.id)
  }

  if (workingCopy.tags) {
    // eslint-disable-next-line no-underscore-dangle
    payload.tagIds = workingCopy.tags.filter(tag => !tag.__isNew__).map(tag => tag.id)
    // eslint-disable-next-line no-underscore-dangle
    payload.tagsAttributes = workingCopy.tags.filter(tag => tag.__isNew__).map(tag => ({ name: tag.name }))
  }

  if (workingCopy.authors) {
    payload.authorIds = workingCopy.authors.map(author => author.id)
  }

  if (workingCopy.targetingRules) {
    payload.targetingRules = normalizeTargetingRules(workingCopy.targetingRules)
  }

  if (workingCopy.notificationChannels) {
    payload.notificationChannelIds = workingCopy.notificationChannels.map(c => c.id)
  }

  return payload
}
