import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useCollaborativeState from 'components/common/hooks/useCollaborativeState'
import useHocuspocusProvider from 'components/common/hooks/useHocuspocusProvider'
import useCollaborativeIsLeader from 'components/common/hooks/useCollaborativeIsLeader'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import collaborativeEditorSlice from 'redux/slices/collaborativeEditor'
import { useDebounce } from 'usehooks-ts'
import sha256 from 'utils/sha256'

interface Props {
  recordId: string
  recordType: string
  configuration: {[key: string]: any}
  savedHtml: string
  onSave: (html: string) => void
  className?: string
  onRecordAttributesChange?: (recordAttributes: any) => void
  richTextId?: string
}

const CollaborativeEditor = ({
  recordId,
  recordType,
  configuration = {},
  savedHtml,
  onSave,
  className = '',
  onRecordAttributesChange = () => {},
  richTextId,
}: Props) => {
  const { provider, synced } = useHocuspocusProvider({ enabled: true, recordId, recordType })
  const [currentHtml, setCurrentHtml] = useState(savedHtml)

  const isLeader = useCollaborativeIsLeader({ provider })

  const [contentSha, setContentSha] = useState(sha256(savedHtml))
  const { setValue: setAndDispatchContentSha } = useCollaborativeState({
    provider,
    param: 'contentSha',
    value: contentSha,
    setValueState: setContentSha,
  })

  useCollaborativeState({
    provider,
    param: `${recordType.toLowerCase()}-${recordId}`,
    mapName: 'recordAttributes',
    setValueState: onRecordAttributesChange,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    const sha = sha256(savedHtml)

    if (isLeader && savedHtml) {
      setAndDispatchContentSha(sha)
    }

    dispatch(collaborativeEditorSlice.actions.setAreChangesPresent((sha256(currentHtml) !== sha)))
  }, [savedHtml, isLeader])

  useEffect(() => () => {
    dispatch(collaborativeEditorSlice.actions.reset())
  }, [])

  const debouncedHtml = useDebounce(currentHtml, 1000)

  useEffect(() => {
    const currentContentSha = sha256(debouncedHtml)

    if (isLeader && contentSha !== currentContentSha) {
      onSave(debouncedHtml)
    }
  }, [debouncedHtml, isLeader])

  const handleChange = (html: string) => {
    setCurrentHtml(html)
    dispatch(collaborativeEditorSlice.actions.setAreChangesPresent((sha256(html) !== contentSha)))
  }

  if (!provider || !synced) {
    return <CirclesLoadingIndicator />
  }

  return (
    <TiptapEditor
      html={_.isEmpty(provider.document.store.clients) ? savedHtml : null} // only pass html if document is empty
      onChange={handleChange}
      provider={provider}
      configuration={configuration}
      className={className}
      richTextId={richTextId}
    />
  )
}

export default CollaborativeEditor
