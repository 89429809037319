import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CardWithTable from 'components/analytics/common/cardWithTable'
import searchAnalyticsSlice from 'redux/slices/analytics/search'
import { i18nPath } from 'utils/i18nHelpers'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import { toPercentage } from 'utils/analytics'
import { SelectedResultType } from 'types/analytics/search'
import TargetingRulesType from 'types/audience/targetingRules'
import usePathReusingQueryParams from 'components/common/hooks/usePathReusingQueryParams'
import { TAB_HEADER_QUERY_PARAMS } from 'components/analytics/helpers/useTabHeader'

const manWithTabletPath = '/images/analytics/manWithTabletIcon.svg'

interface SelectedResultTypesProps {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const I18N = i18nPath('views.platform_analytics.search.selected_types')

const SelectedResultTypes = ({ periodStart, periodEnd, targetingRules }: SelectedResultTypesProps) => {
  const dispatch = useDispatch()

  const { data, totalPages, totalItems } = useSelector(searchAnalyticsSlice.selectors.getSelectedResultTypes())
  const { selectedResultTypes: { isLoading } } = useSelector(searchAnalyticsSlice.selectors.getMetaData())
  const isDataEmpty = totalItems === 0 && !isLoading

  useEffect(() => {
    dispatch(searchAnalyticsSlice.asyncActions.admin.fetchSelectedResultTypes(
      {
        periodStart, periodEnd, page: 1, perPage: 10, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, targetingRules])

  const columns = [
    {
      header: I18N('result_type'),
      accessor: (result: SelectedResultType) => result.type,
    },
    {
      header: I18N('percentage_selected'),
      style: { width: '40%' },
      accessor: (result: SelectedResultType) => toPercentage(result.percentage),
    },
  ]

  const path = usePathReusingQueryParams({
    path: '/platform_analytics/search/selected_result_types',
    paramsToReuse: TAB_HEADER_QUERY_PARAMS,
  })

  if (isDataEmpty) {
    return (
      <EmptyStateCard
        title={I18N('title')}
        text={I18N('empty')}
        iconPath={manWithTabletPath}
        classname='SearchTables'
      />
    )
  }

  return (
    <CardWithTable
      title={I18N('title')}
      tableColumns={columns}
      tableData={data}
      classname='SearchTables'
      isLoading={isLoading}
      showSeeMoreLink={totalPages > 1}
      seeMorePath={path}
    />
  )
}

export default SelectedResultTypes
