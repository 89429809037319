import React, { useState, useEffect } from 'react'
import { useReactMediaRecorder } from 'react-media-recorder'
import SVG from 'react-inlinesvg'
import moment from 'moment'

import { i18nPath } from 'utils/i18nHelpers'

import Modal from 'components/common/modal'
import { ButtonSmall, ButtonSecondarySmall, ButtonSmallNarrow } from 'components/common/buttons'
import DatePicker from 'components/form_fields/datePicker'

const I18N = i18nPath('views.profile.working_hours.modal')
const I18NCommon = i18nPath('views.common')

const ModalHeader = () => (
  <div className='mb-4'>
    <h3 className='d-flex align-items-center'>{I18N('title')}</h3>
    <div className='text-secondary'>{I18N('explanation')}</div>
  </div>
)

const ModalFooter = ({ toggle, save, isSaving }) => (
  <div className='d-flex justify-content-end align-items-center mt-4'>
    <a className='text-muted-dark mr-4' onClick={toggle}>{I18NCommon('cancel')}</a>
    <ButtonSmallNarrow onClick={save} showLoadingSpinner={isSaving}>{I18NCommon('save')}</ButtonSmallNarrow>
  </div>
)

const WorkingHoursModal = ({
  user,
  isOpen,
  toggle,
  update,
}) => {
  const [startTime, setStartTime] = useState(user?.workingHoursStartTime)
  const [endTime, setEndTime] = useState(user?.workingHoursEndTime)
  const [isSaving, setIsSaving] = useState(false)

  const convertMomentObjectToTime = time => (typeof time === 'string' ? time : time.format('LT'))

  const save = () => {
    // after calling update, we will use the Promise to
    // trigger the toggle function to close the modal
    update(
      convertMomentObjectToTime(startTime),
      convertMomentObjectToTime(endTime)
    ).then(
      () => toggle()
    )
  }

  return (
    <Modal className='WorkingHoursModal' visible={isOpen} toggle={toggle}>
      <ModalHeader />
      <div className='d-flex flex-row justify-content-between'>
        <div className='d-flex flex-column w-100 mr-3'>
          <label>{I18N('start_time')}</label>
          <DatePicker
            id='selectStartTime'
            selected={startTime && moment(startTime, 'LT')}
            onChange={setStartTime}
            showTimeSelect
            showTimeSelectOnly
            isClearable
            dateFormat='h:mm a'
            placeholderText={I18N('select_time')}
          />
        </div>
        <div className='d-flex flex-column w-100'>
          <label>{I18N('end_time')}</label>
          <DatePicker
            id='selectEndTime'
            selected={endTime && moment(endTime, 'LT')}
            onChange={setEndTime}
            showTimeSelect
            showTimeSelectOnly
            isClearable
            dateFormat='h:mm a'
            placeholderText={I18N('select_time')}
          />
        </div>
      </div>
      <ModalFooter toggle={toggle} save={save} />
    </Modal>
  )
}

export default WorkingHoursModal
