import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.journeys.onboarding_buddies')

const ErrorSavingBuddyResponsePage = () => {
  const history = useHistory()

  const handleBackToHomeClick = () => {
    history.push('/')
  }

  return (
    <div className='ErrorSavingBuddyResponsePage d-flex align-items-center justify-content-center'>
      <div className='container d-flex flex-column align-items-center justify-content-center'>
        <h4 className='text-center my-4'>{I18N('error_saving_buddy_response')}</h4>
        <Button onClick={handleBackToHomeClick}>{I18N('back_to_home')}</Button>
      </div>
    </div>
  )
}

export default ErrorSavingBuddyResponsePage
