import ClearyCard from 'components/common/card'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import EffectPlayer from 'components/celebrations/effectPlayer'
import GreetingCardActions from 'components/celebrations/greeting_card_actions/greetingCardActions'
import celebrationEmojis from 'components/celebrations/utils/emojis'
import moment from 'moment'
import Tooltip from 'components/common/clearyTooltip'


const I18N = i18nPath('views.celebrations')

const titleKey = (celebration, inEditPage) => {
  const isFirstSigner = celebration.commentsCount === 0

  if (celebration.hasCommented) {
    return inEditPage ? 'celebrated_card.title.has_signed_in_edit_page' : 'celebrated_card.title.has_signed'
  } else if (isFirstSigner) {
    return 'celebrated_card.title.first_signer'
  } else {
    return 'celebrated_card.title.other_user_already_signed'
  }
}

const deliveryInfo = (celebration) => {
  const eventDate = moment(celebration.eventDate)

  const isFuture = eventDate.isAfter(moment())

  const key = isFuture ? 'celebrated_card.delivery_info.future' : 'celebrated_card.delivery_info.past'

  return I18N(key, {
    date: eventDate.format('LL'),
  })
}

export const CelebratedPerson = ({ celebration }) => (
  <div className='CelebratedPerson'>
    <div className='CelebratedPersonThumbWrapper position-absolute top-0 left-0 right-0 bottom-0 d-flex align-items-center justify-content-center'>
      <img
        src={celebration.user?.primaryPhotoUrl}
        alt={celebration.user?.preferredFullName}
        className='CelebratedPersonThumb'
      />

      {celebrationEmojis[celebration.type] && (
        <span className='EmojiWrapper d-flex justify-content-center align-items-center position-absolute'>
          <Tooltip
            content={celebrationEmojis[celebration.type]}
            placement='bottom'
          >
            <span className='text-small'>
              {I18N(`labels.${celebration.type}.title`)}
            </span>
          </Tooltip>
        </span>
      )}
    </div>

    <EffectPlayer
      effect='pulse'
      loop
      fixed={false}
    />
  </div>
)

const CelebratedCard = ({
  celebration, inEditPage = false, showCustomizeButton = false,
}) => (
  <ClearyCard className='CelebratedCard mb-3'>
    <CelebratedPerson celebration={celebration} />

    {!celebration.customNotSent && (
      <>
        <p className='Title text-center px-2'>
          {I18N(titleKey(celebration, inEditPage), { user: celebration.user.preferredName })}
        </p>

        <GreetingCardActions
          celebration={celebration}
          showCustomizeButton={showCustomizeButton}
          showInviteOthersButton={!inEditPage}
          legacy={false}
          short
        />

        <p className='text-center text-secondary'>{deliveryInfo(celebration)}</p>
      </>
    )}

  </ClearyCard>
)

export default CelebratedCard
