import React from 'react'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import { QuestionTypeEnum } from 'types/surveys/question'
import { Link } from 'react-router-dom'
import RichTextView from 'components/common/richTextView'

const I18N = i18nPath('views.admin.surveys.questions.stats')

const TextQuestionStats = ({ question, goToDetailPage }) => {
  const { stats = {}, type, adminDisplayPath } = question
  const isHtmlQuestion = type === QuestionTypeEnum.Html
  const totalAnswers = stats?.totalAnswers || 0

  return (
    <div
      className='TextQuestionStats'
      onClick={() => goToDetailPage()}
    >
      <SmartTable
        className=' white-bg-table'
        data={stats?.data || []}
        columns={[
          {
            style: { width: '40%' },
            header: I18N('name'),
            accessor: d => (
              <UserLink
                user={d?.user}
                customClassName='font-weight-500 text-primary-link'
                unknownUserText={I18NCommon('anonymous_user')}
              />
            ),
          },
          {
            style: { width: '60%' },
            header: I18N('answer'),
            accessor: d => (isHtmlQuestion ? (
              <RichTextView record={d} recordType='answer' richTextKey='value' />
            ) : (
              <span className='ws-pre-wrap'>{d?.value}</span>)
            ),
          },
        ]}
      />
      {totalAnswers > 5 && (
        <Link
          to={adminDisplayPath}
        >
          {I18NCommon('see_more')}
        </Link>
      )}
    </div>
  )
}

export default TextQuestionStats
