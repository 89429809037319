import React, { useEffect, useState } from 'react'

import { Button } from 'components/common/buttons'
import ImageSelector from 'components/common/imageSelector'
import CdnSvg from 'components/common/cdnSvg'
import ModalImageCrop from 'components/common/image_crop/modalImageCrop'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.article_editor.audience_and_settings_editor.thumbnail_card')

const IMG_SRC_REGEX = /<img\s+[^>]*src=["']([^"']+)["']/i
const SOCIAL_SHARE_URL_REGEX = /<div\s+[^>]*class=["'][^"']*?\bsocial-share\b[^"']*["'][^>]*data-image-url=["']([^"']+)["'][^>]*>/i

const EditThumbnailButtons = ({ workingCopy, updateWorkingCopy }) => {
  const [isRepositionModalOpen, setIsRepositionModalOpen] = useState(false)

  const onImageAdded = (thumbnailImage, {
    croppedAreaPixels,
    croppedImageUrl: thumbnailImageUrl,
    imageData: originalThumbnailImageUrl,
  }) => {
    updateWorkingCopy({
      thumbnailImageCroppedArea: croppedAreaPixels,
      thumbnailImageUrl,
      originalThumbnailImageUrl,
      thumbnailImage,
    })
  }

  const onRespositionSave = async ({ croppedAreaPixels, croppedImageUrl }) => {
    setIsRepositionModalOpen(false)
    updateWorkingCopy({
      thumbnailImageCroppedArea: croppedAreaPixels,
      thumbnailImageUrl: croppedImageUrl,
    })
  }

  useEffect(() => {
    const updateThumbnail = async () => {
      if (workingCopy.thumbnailImageUrl) return

      const socialShareThumbnailAdded = await addImageAsThumbnail(
        workingCopy,
        onImageAdded,
        SOCIAL_SHARE_URL_REGEX
      )

      if (socialShareThumbnailAdded) return

      await addImageAsThumbnail(
        workingCopy,
        onImageAdded,
        IMG_SRC_REGEX
      )
    }

    updateThumbnail()
  }, [])

  return (
    <div className='EditThumbnailButtons mt-3'>
      <ImageSelector
        accept={['image/*']}
        dropzoneClassName='dropzone-wrapper'
        cropImage
        cropShape='rect'
        onImageAdded={onImageAdded}
        clearAfterCropSave
        imageText={(
          <Button variant='secondary'>
            <span className='mr-2 d-flex align-items-center'>
              <CdnSvg className='ImageIcon d-inline-flex' src='/images/imageIcon.svg' />
            </span>
            <span className='text-normal'>{workingCopy.thumbnailImageUrl ? I18N('update_thumbnail') : I18N('change_thumbnail')}</span>
          </Button>
        )}
      />

      {workingCopy.thumbnailImageUrl && (
        <Button onClick={() => setIsRepositionModalOpen(true)} variant='secondary'>
          {I18NCommon('reposition')}
        </Button>
      )}

      <ModalImageCrop
        visible={isRepositionModalOpen}
        onCancel={() => setIsRepositionModalOpen(false)}
        imageSrc={workingCopy.originalThumbnailImageUrl}
        initialCroppedArea={workingCopy.thumbnailImageCroppedArea}
        onSave={onRespositionSave}
      />
    </div>
  )
}

export default EditThumbnailButtons

const addImageAsThumbnail = async (workingCopy, addImage, regex) => {
  try {
    const url = extractImageUrlFromHtml(workingCopy.content, regex)
                || extractImageUrlFromHtml(workingCopy.cardContent, regex)

    if (!url) return false

    const file = await urlToFile(url)

    addImage(file, {
      croppedAreaPixels: {},
      croppedImageUrl: url,
      imageData: url,
    })

    return true
  } catch (e) {
    // Ignore errors, if this fails the user will just have to add an image manually
    return false
  }
}

const extractImageUrlFromHtml = (html, regex) => {
  const match = regex.exec(html)
  return match ? match[1] : null
}

const urlToFile = async (url) => {
  const response = await fetch(url)
  if (!response.ok) throw new Error(`Failed to fetch image from ${url}`)

  const blob = await response.blob()
  const filename = url.split('/').pop()
  const mimeType = blob.type

  return new File([blob], filename, { type: mimeType })
}

