import React from 'react'

import Card from 'components/common/card'
import VideoConferenceLinkDisplay from 'components/qna/videoConferenceLinkDisplay'
import { Button } from 'components/common/buttons'
import { LONG_DATE_FORMAT } from 'utils/constants'
import EventRecapButton from 'components/qna/eventRecapButton'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.qna.events')

const DraftPublishAlert = ({ onClickPublish }) => (
  <div className='DraftPublishAlert my-2'>
    <CdnSvg src='/images/alertCircleIcon.svg' className='EventNotificationWidget-alertCircleIcon' />
    <div className='text-large font-weight-bold mt-2'>
      {I18N('draft_banner.event_not_live')}
    </div>
    <div className='px-4'>
      {I18N('draft_banner.publish_event')}
    </div>
    <Button className='mt-3' onClick={onClickPublish}>
      {I18N('draft_banner.publish_event_cta')}
    </Button>
  </div>
)

const StandardBanner = ({ firstRowText, secondRowText, event }) => (
  <>
    <div>
      <span>{firstRowText}</span>
    </div>
    <div className='mt-1'>
      <h5 className='font-weight-bold'>{secondRowText}</h5>
    </div>
    <EventRecapButton event={event} />
  </>
)

const LiveBanner = ({ event }) => {
  const { isActive } = event

  return (
    <>
      <div className='mb-3'>
        <h5>{I18N('we_are_now_live')}</h5>
      </div>
      <div className='mb-4'>
        <VideoConferenceLinkDisplay link={event.videoConferenceLink} button />
      </div>
      <hr className='mb-4' />
      {isActive ? (
        <>
          <div>{I18N('accepting_questions_until_card')}</div>
          <div>
            <h5>{i18nMoment(event.lockedAt).format(LONG_DATE_FORMAT)}</h5>
          </div>
        </>
      ) : (
        <div>{I18N('questions_closed_during_event')}</div>
      )}
    </>
  )
}

const ArchivedBanner = ({ event }) => (
  <>
    <div>
      <h5>{I18N('event_archived')}</h5>
    </div>
    <div className='mt-1'>
      <span>{I18N('no_further_questions_or_answers')}</span>
    </div>
    <EventRecapButton event={event} />
  </>
)

const EventNotificationWidget = ({ event, onClickPublish, onClickGenerateZoomArticle }) => {
  const {
    isDraft, isActive, isLockedAndAcceptingAnswers, isArchived, isLive, isNotYetLive,
  } = event

  const className = (isArchived || isDraft) ? 'bg-color-warn' : 'bg-color-success'

  const renderCorrectNotificationWidget = () => {
    if (isArchived) {
      return <ArchivedBanner event={event} />
    } else if (isDraft) {
      return (
        <DraftPublishAlert
          onClickPublish={onClickPublish}
        />
      )
    } else if (isLive) {
      if (event.videoConferenceLink) {
        return <LiveBanner event={event} />
      } else {
        if (isActive) {
          return (
            <StandardBanner
              firstRowText={I18N('accepting_questions_until_card')}
              secondRowText={i18nMoment(event.lockedAt).format(LONG_DATE_FORMAT)}
              event={event}
            />
          )
        } else {
          return (
            <StandardBanner
              firstRowText={I18N('can_still_answer_until')}
              secondRowText={i18nMoment(event.archivedAt).format(LONG_DATE_FORMAT)}
              event={event}
            />
          )
        }
      }
    } else if (isLockedAndAcceptingAnswers) {
      return (
        <StandardBanner
          firstRowText={I18N('can_still_answer_until')}
          secondRowText={i18nMoment(event.archivedAt).format(LONG_DATE_FORMAT)}
          event={event}
        />
      )
    } else if (isNotYetLive) {
      if (isActive) {
        return (
          <StandardBanner
            firstRowText={I18N('accepting_questions_until_card')}
            secondRowText={i18nMoment(event.lockedAt).format(LONG_DATE_FORMAT)}
            event={event}
          />
        )
      } else {
        return (
          <StandardBanner
            firstRowText={I18N('accepting_questions_starting')}
            secondRowText={i18nMoment(event.startedAt).format(LONG_DATE_FORMAT)}
            event={event}
          />
        )
      }
    } else {
      return (
        <StandardBanner
          firstRowText={I18N('accepting_questions_until_card')}
          secondRowText={i18nMoment(event.lockedAt).format(LONG_DATE_FORMAT)}
          event={event}
        />
      )
    }
  }

  return (
    <Card
      className={classNames(className, 'mb-3 p-2 text-center EventNotificationWidget', { isDraft })}
    >
      {renderCorrectNotificationWidget()}
    </Card>
  )
}

export default EventNotificationWidget
