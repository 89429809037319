import React from 'react'

import { i18nPath, i18nMoment } from 'utils/i18nHelpers'
import classNames from 'classnames'

import EmployeeLinkWithPopover from 'components/common/employeeLinkWithPopover'

const ShoutoutFrom = ({
  sender,
  createdAt,
  edited = false,
  className = 'text-center my-3 max-width-max-content mx-auto',
}) => {
  const I18N = i18nPath('views.shoutouts_feed')

  return (
    /*
      max-width-max-content mx-auto help reduce the non-clickable area
      that is necessary to respond to clicks in order to navigate users
      to the shouout detail page
     */
    <div className={classNames('ShoutoutFrom', className)}>
      <span className='mr-1 text-small text-secondary cursor-default'>{I18N('from')}</span>
      <EmployeeLinkWithPopover userLinkClassName='link-color text-small text-primary' user={sender} />
      <span className='ShoutoutSentAt'>
        {' • '}
        <span className='text-small text-secondary cursor-default'>{i18nMoment(createdAt).fromNow()}</span>
      </span>
      {edited && (
        <span>
          {' • '}
          <span className='text-small text-secondary cursor-default'>{I18N('edited')}</span>
        </span>
      )}
    </div>
  )
}

export default ShoutoutFrom
