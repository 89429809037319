import React, { useEffect, useMemo, useState } from 'react'
import GridLayout from 'react-grid-layout'
import API from 'services/api'
import useLayoutSize, { CONTAINER_PADDING, MARGINS, ROW_HEIGHT } from 'hooks/layout/useLayoutSize'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useWindowResize from 'components/common/hooks/useWindowResize'
import useFetch from 'components/common/hooks/useFetch'
import { WidgetType } from 'types/layout/widget'
import BaseWidget from 'components/home/widgets/baseWidget'
import { trackEvent } from 'services/tracker'
import { useParams } from 'react-router-dom'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { CAROUSEL_CARDS_WIDGET_TYPE } from 'utils/layout/widgetsMap'
import classNames from 'classnames'

const CustomHomePage = () => {
  const { layoutCollectionId } = useParams()
  const { permissions: { superAdmin }, inPreboardingExperience } = useCurrentUser()
  const { width: windowWidth } = useWindowResize()
  const { size, cols, width } = useLayoutSize(windowWidth)
  const [widgets, setWidgets] = useState<WidgetType[]>([])

  const apiFunction = superAdmin && layoutCollectionId
    ? () => API.admin.layout.layouts.fetch(layoutCollectionId, size)
    : () => API.layout.layouts.fetch(size, { pageType: inPreboardingExperience ? 'preboarding' : 'home' })

  const { isLoading } = useFetch(apiFunction, [size, layoutCollectionId], {
    onSuccess: data => setWidgets(data.widgets),
  })

  const resizeWidget = (id: string, h: number) => {
    setWidgets(prev => prev.map(widget => (widget.id === id ? { ...widget, h, minH: h } : widget)))
  }

  useEffect(() => {
    trackEvent('home:view')
  }, [])

  const hasHeroWidgetAtTop = useMemo(() => (
    widgets.some(widget => (
      widget.type === CAROUSEL_CARDS_WIDGET_TYPE && widget.y === 0 && widget.h > 0 && widget.settings?.fullWidth)
    )
  ), [widgets])

  if (isLoading) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className={classNames('CustomHomePage w-100 d-flex justify-content-center', { hasHeroWidgetAtTop })}>
      <GridLayout
        cols={cols}
        rowHeight={ROW_HEIGHT}
        width={width}
        layout={widgets.map(widget => ({ i: widget.id, ...widget }))}
        containerPadding={CONTAINER_PADDING}
        margin={MARGINS}
        style={{ width }}
        isResizable={false}
        isDraggable={false}
        isDroppable={false}
      >
        {widgets.map(widget => (
          <div key={widget.id}>
            <BaseWidget widget={widget} resizeWidget={resizeWidget} />
          </div>
        ))}
      </GridLayout>
    </div>
  )
}

export default CustomHomePage
