import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import { Button } from 'components/common/buttons'
import Card from 'components/common/card'
import authTokenSlice from 'redux/slices/authTokens'
import LoadingSpinner from 'components/common/loadingSpinner'
import AuthTokenCard from 'components/admin/users/userEditor/authTokenTabComponents/authTokenCard'
import RemoveAuthTokenModal from 'components/admin/users/userEditor/authTokenTabComponents/removeAuthTokenModal'
import CreatedAuthTokenModal from 'components/admin/users/userEditor/authTokenTabComponents/createdAuthTokenModal'
import BetaTag from 'components/common/tags/betaTag'

const I18N = i18nPath('views.admin.auth_tokens')
const MAX_ACTIVE_AUTH_TOKENS = 2

const AuthTokensTab = ({ user }) => {
  const dispatch = useDispatch()
  const [tokenToBeRemoved, setTokenToBeRemoved] = useState(null)
  const authTokens = useSelector(authTokenSlice.selectors.getAuthTokens())
  const { isLoading } = useSelector(authTokenSlice.selectors.getMetaData())
  const clearNewToken = () => dispatch(authTokenSlice.actions.clearCreatedAuthTokenId())
  const createdAuthToken = useSelector(authTokenSlice.selectors.getCreatedAuthToken())

  // everytime the active tokens length changes, we need to close the token to be removed modal
  useEffect(() => {
    setTokenToBeRemoved(null)
  }, [authTokens.length])

  useEffect(() => {
    if (user.id) {
      dispatch(authTokenSlice.asyncActions.admin.fetchUserAuthTokens(user.id))
    }

    return () => {
      dispatch(authTokenSlice.actions.clearAuthTokenIds())
    }
  }, [user.id])


  const createNewAuthToken = () => {
    dispatch(authTokenSlice.asyncActions.admin.createAuthToken(user.id))
  }

  const canCreateNewAuthTokens = authTokens.length < MAX_ACTIVE_AUTH_TOKENS

  const isCreatingAuthToken = isLoading && !tokenToBeRemoved

  if ((isLoading && authTokens.length === 0) || !user.id) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <div>
      <div className='mb-4 d-flex align-items-center'>
        <h2 className='mb-0'>{I18N('api_tokens')}</h2>
        <BetaTag className='mx-3' />
      </div>

      {authTokens.length === 0 && (
        <Card className='mb-4'>
          <p className='mt-2 mb-2'>{I18N('no_active_tokens_message')}</p>
        </Card>
      )}

      {authTokens.map(authToken => (
        <AuthTokenCard
          key={authToken.id}
          authToken={authToken}
          onRemove={setTokenToBeRemoved}
        />
      ))}

      <p>
        <Button
          onClick={createNewAuthToken}
          disabled={!canCreateNewAuthTokens || isCreatingAuthToken}
          className='mr-2'
          showLoadingSpinner={isCreatingAuthToken}
        >
          {I18N('create_new_auth_token')}
        </Button>

        {!canCreateNewAuthTokens && (
          <span>
            {I18N('max_active_auth_tokens', { max: MAX_ACTIVE_AUTH_TOKENS })}
          </span>
        )}
      </p>

      {authTokens.length > 0 && (
        <>
          <hr />

          <p>
            Use any auth token passing the header <mark>X-CLEARY-AUTHORIZATION</mark> as in this example:
          </p>

          <div className='alert alert-info' role='alert'>
            <pre>
              curl -H &quot;Content-Type: application/json&quot; -H
              &quot;X-CLEARY-AUTHORIZATION: TOKEN-VALUE-HERE&quot; {window.location.origin}/api/articles
            </pre>
          </div>
        </>
      )}

      <CreatedAuthTokenModal createdAuthToken={createdAuthToken} onClose={clearNewToken} />

      <RemoveAuthTokenModal
        user={user}
        authToken={tokenToBeRemoved}
        setAuthToken={setTokenToBeRemoved}
      />
    </div>
  )
}

export default AuthTokensTab
