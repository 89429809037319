import React from 'react'

import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'

import SearchResultOption from './searchResultOption'

const UserOption = ({ option }) => {
  const { user, highlights } = option
  const { preferredFullName, title } = { ...user, ...highlights }

  return (
    <SearchResultOption
      image={<EmployeeThumbnailPhoto employee={user} size='22px' className='mr-2 mb-1' />}
      title={preferredFullName}
      secondaryTexts={[title]}
    />
  )
}

export default UserOption
