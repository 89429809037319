import React from 'react'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import GroupType from 'types/group'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.groups.group_page')

interface GroupMiniCardProps {
  group: GroupType,
  isLoading: boolean,
}

const GroupMiniCard = ({ group, isLoading } : GroupMiniCardProps) => {
  const memberCount = group.activeMembersCount || 0
  const followerCount = group.activeFollowersCount || 0

  return (
    <div className='container px-3 pt-3 pb-1'>
      <div className='row'>
        {isLoading ? (
          <CirclesLoadingIndicator />
        ) : (
          <>
            <div className='col-md-3'>
              <img className='GroupMiniCardPhoto' src={group.photo || group.primaryPhotoThumbnailUrl} />
            </div>
            <div className='col-md ml-2 my-1 GroupMiniCard'>
              <div className='row text-primary font-weight-500'>{group.name}</div>
              <div className='row text-secondary text-small'>{group.groupType?.name}</div>
              <div className='row my-2'>
                <div className={classNames('text-small text-secondary mb-1')}>
                  <span className='font-weight-500 link-color'>
                    {I18N('group_members', { count: memberCount })}
                  </span>
                  {' • '}
                  <span className='font-weight-500 link-color'>
                    {I18N('followers_count', { count: followerCount })}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

    </div>
  )
}

export default GroupMiniCard
