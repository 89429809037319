import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.custom_styles.font_size')

const minMax = {
  h1: { min: 25, max: 60 },
  h2: { min: 20, max: 50 },
  h3: { min: 15, max: 30 },
  h4: { min: 13, max: 36 },
  h5: { min: 10, max: 30 },
  h6: { min: 8, max: 26 },
}

interface Props {
  variable: string
  value: string
  onChange: (section: string, variable: string, value: string) => void
}

const FontSizeControl = ({ variable, value, onChange }) => {
  const [pixelValue] = value.match(/([0-9]{1,2})/)
  const [Heading] = variable.match(/h[1-6]/)
  const { min, max } = minMax[Heading]

  const handleChange = (e) => {
    const { value } = e.target
    onChange('fonts', variable, `${value}px`)
  }

  return (
    <div className='FontSizeControl d-flex justify-content-between'>
      <Heading>{I18N('this_is_a_heading', { Heading })}</Heading>
      <div className='d-flex align-items-center'>
        <span className='control d-flex align-items-center text-small'>
          {min}
          <input
            className='mx-2'
            type='range'
            name={variable}
            value={pixelValue}
            min={min}
            max={max}
            onChange={e => handleChange(e)}
          />
          {max}
        </span>
        <span className='ml-3'>
          {pixelValue}
          px
        </span>
      </div>
    </div>
  )
}

export default FontSizeControl
