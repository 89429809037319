import React, { useState } from 'react'

import {
  I18NCommon,
  i18nFormat,
  i18nLabelWithTimeZone, i18nPath
} from 'utils/i18nHelpers'
import classNames from 'classnames'
import currentTimezone from 'utils/currentTimezone'

import { ButtonNarrow } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import Modal from 'components/common/modal'
import moment, { Moment } from 'moment'
import DatePicker from 'components/form_fields/datePicker'
import pageSlice from 'redux/slices/pages'
import { useDispatch, useSelector } from 'react-redux'
import InfoTextContainer from 'components/common/infoTextContainer'
import { Link } from 'react-router-dom'
import PageType from 'types/page'

const I18N = i18nPath('views.pages.actions_dropdown')

interface SetExpirationModalProps {
  className?: string
  visible: boolean
  page: PageType
  toggle: () => void
}

const SetExpirationModal = ({
  className,
  toggle,
  visible,
  page,
}: SetExpirationModalProps) => {
  const dispatch = useDispatch()

  const pageArchivedAt = page?.archivedAt
  const expirationRootPage = page?.expirationRootPage
  const expirationRootPageId = expirationRootPage?.id

  const hasExpirationRootPage = !_.isEmpty(expirationRootPage) && expirationRootPageId !== page.id

  const { isSaving } = useSelector(pageSlice.selectors.getPagesMetadata())

  // use current archived at or a year from now
  const [archivedAt, setArchivedAt] = useState<undefined | Moment>(pageArchivedAt ? moment(pageArchivedAt) : moment().add(1, 'year'))
  const hasChanges = !_.isEqual(pageArchivedAt, archivedAt?.toISOString())

  const onSaveClick = () => {
    const params = {
      id: page.id,
      archivedAt: archivedAt?.toISOString() || null,
    }

    const onSuccess = () => {
      toggle()
    }

    dispatch(pageSlice.asyncActions.setExpiration(params, onSuccess))
  }

  return (
    <Modal size='lg' className={classNames('SetExpirationModal rounded-lg', className)} visible={visible} toggle={toggle}>
      <div className='px-4 d-flex flex-column'>
        <h4 className='font-weight-600 mb-3'>{I18N('set_expiration')}</h4>
        <span className='text-secondary mb-3'>{I18N('expiration_guidance')}</span>
        {hasExpirationRootPage && (
          <InfoTextContainer
            className='mb-3'
            text={i18nFormat(I18N('expiration_root_page_info'), <Link to={expirationRootPage?.displayPath}>{expirationRootPage?.title}</Link>)}
          />
        )}
        <label className='required-form-label'>{i18nLabelWithTimeZone(I18N('when_should_page_expire'), currentTimezone())}</label>
        <DatePicker
          selected={archivedAt}
          onChange={e => setArchivedAt(e || undefined)}
          showTimeSelect
          isClearable={!hasExpirationRootPage}
          dateFormat='LLL'
          isDisabled={hasExpirationRootPage || isSaving}
          className='mb-3'
          inputReadOnly={false}
        />
        {!hasExpirationRootPage && (
          <div className='d-flex justify-content-end align-items-center mt-3'>
            <CancelButton onClick={toggle} className='mr-3 text-secondary font-weight-600'>
              {I18NCommon('cancel')}
            </CancelButton>
            <ButtonNarrow
              className='set-expiration-button'
              onClick={onSaveClick}
              showLoadingSpinner={isSaving}
              disabled={!hasChanges || isSaving}
            >
              {I18N('set_expiration')}
            </ButtonNarrow>
          </div>
        )}
      </div>
    </Modal>
  )
}


export default SetExpirationModal
