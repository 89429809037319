import React from 'react'

const TextColorSecondary = () => (
  <div className='text-secondary'>
    Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim
    pellentesque felis.
  </div>
)

export default TextColorSecondary
